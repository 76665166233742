import { Portal } from "@chakra-ui/react";
import React, { useCallback } from "react";

import { SeasonMapSoundEffect } from "links/lib/types";
import { useAudio } from "sharedComponents/contexts/audio";

import { FullscreenAnimationTakeover } from "../FullscreenAnimationTakeover";
import zone2UpgradeData from "./resource/zone-2-upgrade.json";
import zone3UpgradeData from "./resource/zone-3-upgrade.json";

export interface ISeason3MapZoneUnlock {
  show: boolean;
  zoneCount: number;
  handleComplete: () => void;
}

export const Season3MapZoneUnlock: React.FC<ISeason3MapZoneUnlock> = ({
  show,
  handleComplete,
  zoneCount,
}) => {
  const { play } = useAudio();

  const handlePlay = useCallback(() => {
    play(SeasonMapSoundEffect.ShipPassUpgrade);
  }, [play]);

  return (
    <Portal>
      <FullscreenAnimationTakeover
        handleEnd={handleComplete}
        handlePlay={handlePlay}
        show={show}
        bgColor="season-map.zone-unlock-bg"
        animationData={zoneCount === 2 ? zone2UpgradeData : zone3UpgradeData}
      />
    </Portal>
  );
};
