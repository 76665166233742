import { AxiosInstance } from "axios";
import { useMount } from "react-use";

import { localStoreAuthKeyName } from "../../constants";
import { useAsync } from "../../hooks/useAsync";
import { useAxios } from "../../hooks/useAxios";
import { useLocalStore } from "../../hooks/useLocalStore";
import { useSessionStore } from "../../hooks/useSessionStore";
import { ILocalStore, UserRole } from "../../types";
import { fetchCurrentUser } from "../users/useFetchCurrentUser";
import { useAuth } from "./";

export interface IInitAuthStatus {
  execute: () => void;
  isLoading: boolean;
  error?: Error;
}

/**
 * Initializes the user authentication for the app. When mounted, check local store
 * for JWT, and if present fetches the current user. This hook will update the authAtom
 * as well as the internal axios client with the result of the authorization initialization.
 * @returns
 */
export default function useInitAuth(): IInitAuthStatus {
  const { setAuth, isAuthLoading } = useAuth();
  const axios = useAxios();
  const localStore = useLocalStore();
  const sessionStore = useSessionStore();

  const initAuth = async () => {
    // search session storage for JWT
    let currentJWT = await sessionStore.getItem(localStoreAuthKeyName);
    if (!currentJWT) {
      // search local storage for JWT
      currentJWT = await localStore.getItem(localStoreAuthKeyName);
    }

    if (!currentJWT) {
      // this is not an error case
      // set user to unauthenticated and
      // finish loading
      setAuth({
        authRequiredAgreements: undefined,
        authFeatureFlags: undefined,
        authToken: undefined,
        authUser: undefined,
        authSharingSettings: undefined,
        authLicense: undefined,
        hasNoPremiumAccess: false,
        isGhostUser: false,
        isAuthLoading,
      });

      return;
    }

    // update axios client to use current JWT
    // for authorization header on all requests
    axios.defaults.headers.common["Authorization"] = currentJWT;

    const resData = await fetchCurrentUser(axios);

    if (resData.user.role !== UserRole.Student && !resData.user.is_guest) {
      // eslint-disable-next-line
      (window as any).pear?.identifyUser?.(resData.pear_token);
    }

    if (resData.should_renew_token) {
      renewJWT(axios, localStore);
    } else {
      checkRenewJWT(axios, localStore, currentJWT);
    }

    // update auth state
    setAuth({
      authRequiredAgreements: resData.required_agreements,
      authFeatureFlags: resData.features,
      authToken: currentJWT,
      authUser: resData.user,
      authSharingSettings: resData.sharing_settings,
      authLicense: resData.license,
      hasNoPremiumAccess: !resData.license.has_premium_access,
      isGhostUser: resData.user.ghost_user_email != "",
      isAuthLoading,
    });
  };

  const { isLoading, error, execute } = useAsync<void>(initAuth, {
    initState: { isLoading: true },
  });

  useMount(() => {
    execute();
  });

  return {
    execute,
    isLoading,
    error,
  };
}

export async function useInitStudentAuth(): Promise<void> {
  const axios = useAxios();
  const sessionStore = useSessionStore();

  return await axios
    .get("/v1/users/student-session")
    .then((r) => r.data as unknown as { token: string })
    .then((resData) => {
      axios.defaults.headers.common["Authorization"] = resData.token;

      sessionStore.setItem(localStoreAuthKeyName, resData.token);
    });
}

/**
 * Checks the current token. If the token was issued more than one day
 * previously, it attempts to renew the token. If token renewal is
 * successful, it updates axios headers and stores the new token.
 * @param axios
 * @param localStore
 * @param currentJWT
 * @returns
 */
const checkRenewJWT = async (
  axios: AxiosInstance,
  localStore: ILocalStore,
  currentJWT: string
) => {
  let parsedJWT;
  try {
    parsedJWT = JSON.parse(atob(currentJWT.split(".")[1]));
  } catch (e) {
    console.log("Error parsing token:", e);
    return;
  }

  const currSec = Math.floor(new Date().valueOf() / 1000);
  if (parsedJWT.iat > currSec - 60 * 60 * 24) {
    return;
  }

  renewJWT(axios, localStore);
};

const renewJWT = async (axios: AxiosInstance, localStore: ILocalStore) => {
  const response = await axios
    .get("/v1/oauth/renew")
    .then((r) => r.data as unknown as { token: string })
    .catch((e) => {
      console.warn("Error renewing token:", e);
    });

  if (!response || !response.token) return;

  const { token } = response;

  // update axios client to use current JWT
  // for authorization header on all requests
  axios.defaults.headers.common["Authorization"] = token;

  // save the auth token in local store
  await localStore.setItem(localStoreAuthKeyName, token);
};
