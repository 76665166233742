import { pxToRem } from "adminComponents/utils";

export const AdminStatCard = {
  baseStyle: {
    card: {
      transition: "color 250ms",
      _focusVisible: {
        color: "utility.focus",
        outline: "none",
      },
    },
    headerContainer: {
      width: "100%",
      justifyContent: "space-between",
      paddingRight: pxToRem(16),
      position: "absolute",
      top: pxToRem(3),
      left: pxToRem(3),
      minHeight: pxToRem(32),
    },
    body: {
      padding: pxToRem(30),
      gap: pxToRem(16),
      paddingTop: pxToRem(40),
      paddingBottom: pxToRem(40),
    },
    heading: {
      zIndex: 2,
    },
    content: {
      maxWidth: "100%",
      minWidth: 0,
      justify: "start",
      align: "start",
      alignItems: "start",
    },
  },
};
