import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  ButtonGroup,
  Heading,
  VStack,
  VisuallyHidden,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";
import { useMount, useSessionStorage } from "react-use";

import { Button } from "adminComponents/atoms/Button";
import { TemplateWithVerticalCenteredContent } from "adminComponents/templates/TemplateWithVerticalCenteredContent";
import { pxToRem } from "adminComponents/utils";
import { afterSignInPathSessionKey } from "lib/constants";
import { useAnalytics } from "lib/contexts/analytics";
import { useHubSpot } from "lib/hooks/useHubSpot";
import { config } from "links/lib/constants";
import { AnalyticsEvent } from "links/lib/types";

import useFetchAuthFromProvider from "./useFetchAuthFromProvider";

const SignInOAuthCallback: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const [handledError, setHandledError] = useState<Error>();
  const [redirectPath, setRedirectPath] = useState("");
  const { t, i18n } = useTranslation("admin", {
    keyPrefix: "signInOAuthCallbackContainer",
    useSuspense: false,
  });
  const query = new URLSearchParams(location.search);
  const sessionStorageRedirectPath = sessionStorage.getItem(
    afterSignInPathSessionKey
  );
  const sessionRedirectPathIsValid =
    sessionStorageRedirectPath &&
    sessionStorageRedirectPath !== '""' &&
    sessionStorageRedirectPath !== "undefined";
  const [sessionRedirectPath, setSessionRedirectPath] = useSessionStorage(
    afterSignInPathSessionKey,
    sessionRedirectPathIsValid ? sessionStorageRedirectPath : "/"
  );
  const { trackEvent } = useAnalytics();
  const { trackHubSpotEvent } = useHubSpot();

  const code = query.get("code") || "";
  const state = query.get("state") || "";
  const authorizationError = query.get("error") || "";

  const stateItems = state.split(":");
  const [providerString, , , , isPopup] = stateItems;
  const provider = providerString?.toLocaleLowerCase();

  const {
    isLoading,
    error: fetchProviderAuthError,
    data,
  } = useFetchAuthFromProvider({
    paramCode: code,
    paramState: state,
    enabled: !authorizationError,
    onBadRequest: (err) => {
      setHandledError(err);
    },
  });

  // store the intended redirect path in mounted variable
  // and then unset from session storage
  useMount(() => {
    setRedirectPath(sessionRedirectPath);
    setSessionRedirectPath("");

    // Prevent a user from signing into the application and
    // navigating back in their browser from attempting to reauthenticate
    // using the previously issued (and used) token. If they navigate
    // back, send them to the sign-in page instead.
    window.history.replaceState(null, window.document.title, "/sign-in");
  });

  // if authentication is successful, remove redirect
  // path from session storage and update UI language.
  // Optionally close the window if this is a popup sign in flow.
  useEffect(
    () => {
      if (!isLoading && !fetchProviderAuthError && data?.authUser) {
        if (data.authUser.language) i18n.changeLanguage(data.authUser.language);

        trackEvent(AnalyticsEvent.SignIn_OAuthCallback_SignedIn, {
          provider,
        });

        switch (provider) {
          case "google":
            trackHubSpotEvent(AnalyticsEvent.HubSpot_SignedInWithGoogle);
            break;
          case "microsoft":
            trackHubSpotEvent(AnalyticsEvent.HubSpot_SignedInWithMicrosoft);
            break;
          case "clever":
            trackHubSpotEvent(AnalyticsEvent.HubSpot_SignedInWithClever);
            break;
        }

        if (isPopup === "true") {
          window.addEventListener("message", (event) => {
            if (
              event.origin !== config.baseUrl ||
              !event.source ||
              event.data !== "ping"
            )
              return;
            event.source.postMessage(
              { popupSignIn: { success: true } },
              event.origin as WindowPostMessageOptions
            );
            window.close();
          });

          return;
        }

        history.push(redirectPath);
      }
    },
    // eslint-disable-next-line
    [data, isLoading, fetchProviderAuthError]
  );

  let providerError: Error | undefined;
  if (authorizationError) {
    switch (provider) {
      case "google_classroom":
        providerError = new Error(t("errorNotGrantedGoogleClassroom"));
        break;
      default:
        providerError = new Error(t("errorNotGranted"));
    }
  }

  const error = handledError || fetchProviderAuthError || providerError;

  return (
    <TemplateWithVerticalCenteredContent isLoading={isLoading}>
      <VStack spacing={10}>
        <VisuallyHidden>
          <Heading as="h1">{t("heading")}</Heading>
        </VisuallyHidden>
        {!isLoading && !!error && (
          <React.Fragment>
            <Alert status="error" flexDirection="column">
              <AlertIcon />
              <AlertTitle mr={2}>{t("error")}</AlertTitle>
              <AlertDescription mt={pxToRem(16)}>
                {error.message}
              </AlertDescription>
            </Alert>

            {providerError ? (
              <ButtonGroup>
                {redirectPath && (
                  <Button
                    variant="adminButtonFilled"
                    as={RouterLink}
                    to={redirectPath}
                  >
                    {t("backToSessionPath")}
                  </Button>
                )}

                <Button
                  variant="adminButtonOutlined"
                  as={RouterLink}
                  to="/sign-in"
                >
                  {t("backToSignInLink")}
                </Button>
              </ButtonGroup>
            ) : (
              <Button variant="adminButtonFilled" as={RouterLink} to="/sign-in">
                {t("backToSignInLink")}
              </Button>
            )}
          </React.Fragment>
        )}
      </VStack>
    </TemplateWithVerticalCenteredContent>
  );
};

export default SignInOAuthCallback;
