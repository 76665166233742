import { Box, BoxProps, VisuallyHidden } from "@chakra-ui/react";
import React from "react";

import { ColorScheme } from "adminComponents";

import { Icon, IconType } from "../Icon";
import { ReactComponent as SVGBackground } from "./resource/background.svg";

export interface IIconButtonProps extends BoxProps {
  colorScheme?: string;
  iconColorScheme?: ColorScheme;
  icon: IconType;
  boxSize: string;
  iconSize: string;
  isDisabled?: boolean;
  type?: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  ariaLabel?: string;
}

const IconButton: React.FC<IIconButtonProps> = ({
  colorScheme = "primary.golden",
  iconColorScheme = "primary.warm-black",
  icon,
  iconSize,
  boxSize,
  isDisabled,
  onClick,
  type,
  ariaLabel,
  ...props
}) => {
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    !isDisabled && onClick?.(event);
  };
  return (
    <Box
      as="button"
      aria-label={ariaLabel}
      {...props}
      width={boxSize}
      height={boxSize}
      opacity={isDisabled ? 0.4 : 1}
      color={colorScheme}
      position="relative"
      onClick={handleClick}
      cursor={isDisabled ? "auto" : "pointer"}
      type={type}
    >
      <VisuallyHidden>{icon}</VisuallyHidden>
      <SVGBackground width="100%" height="100%" />
      <Icon
        position="absolute"
        top="50%"
        left="50%"
        transform="translateX(-50%) translateY(-50%)"
        display="inline-flex"
        icon={icon}
        width={iconSize}
        height={iconSize}
        iconColor={iconColorScheme}
      />
    </Box>
  );
};

export { IconButton };
