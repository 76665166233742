import { useEffect, useState } from "react";

import { AvatarAnimations } from "links/lib/constants";
import { IAvatarAnimation } from "links/lib/types";

export interface IUserAvatarSpinAnimationParams {
  targetSpaceId: number;
  reachedTargetRow: boolean;
  atRest: boolean;
  boardOutcome: {
    isPositiveOutcome: boolean;
    isCarePackage: boolean;
  };
}

export const useAvatarSpinAnimations = ({
  targetSpaceId,
  reachedTargetRow,
  atRest,
  boardOutcome,
}: IUserAvatarSpinAnimationParams): Array<IAvatarAnimation> => {
  const [avatarAnimations, setAvatarAnimations] = useState<
    Array<IAvatarAnimation>
  >(AvatarAnimations.Idle);

  useEffect(() => {
    if (targetSpaceId) {
      const buzzerAnimation = reachedTargetRow
        ? AvatarAnimations.BuzzerInterstitialHorizontal
        : AvatarAnimations.BuzzerWait;
      setAvatarAnimations(buzzerAnimation);
    }
  }, [reachedTargetRow, targetSpaceId]);

  useEffect(() => {
    if (!atRest || !targetSpaceId) return;

    const animation =
      boardOutcome.isPositiveOutcome || boardOutcome.isCarePackage
        ? AvatarAnimations.Victory
        : AvatarAnimations.Defeat;
    setAvatarAnimations(animation);
  }, [atRest, boardOutcome, targetSpaceId]);

  return avatarAnimations;
};
