import { useLocalStorage } from "react-use";

import { useAuth } from "links/lib/features/auth";

const USE_NEW_LIBRARY_KEY = "useNewLibrary";

// This hook is meant to be a temporary way to make testing the new library/explore page easier
// add useNewLibrary with a value of true to localStorage and you can bypass the need for having the feature
// enabled for the user.  This helps in the case of auto creating guest users when you visit Pear Practice
// and expecting them to see the new explore page right away as a guest

// TODO remove this hook and its usages when feature is turned on for all
export const useShowNewLibrary = (): boolean => {
  const { isFeatureEnabled } = useAuth();
  const [showNewLibrary] = useLocalStorage(USE_NEW_LIBRARY_KEY);

  let featureEnabled = false;
  if (isFeatureEnabled) {
    featureEnabled = isFeatureEnabled(
      "playtime.teacher.enable_library_homepage"
    );
  }

  return !!showNewLibrary || featureEnabled;
};
