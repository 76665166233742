import {
  Box,
  Modal as ChakraModal,
  Flex,
  FormControl,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  SimpleGrid,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Avatar } from "adminComponents/atoms/Avatar";
import { Button } from "adminComponents/atoms/Button";
import { Dropdown, IOption } from "adminComponents/atoms/Dropdown";
import { FormLabel } from "adminComponents/atoms/FormLabel";
import { Heading } from "adminComponents/atoms/Heading";
import {
  IconCTAButtonWrapper as CloseButtonWrapper,
  IconCTAButton,
} from "adminComponents/atoms/IconCTAButton";
import { Text } from "adminComponents/atoms/Text";
import {
  generateTeacherName,
  hoverStyles,
  pxToRem,
} from "adminComponents/utils";
import { IUser, UsersGroupsRole } from "links/lib/types";

interface ITeacherDetailForm {
  usersGroupsRole: UsersGroupsRole;
}

interface EditTeacherDetailsInnerModalProps extends ModalProps {
  title: string;
  isLoading?: boolean;
}

const EditTeacherModalWrapper: React.FC<EditTeacherDetailsInnerModalProps> = ({
  children,
  variant = "adminModal",
  isLoading,
  title,
  ...rest
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  return (
    <ChakraModal
      {...rest}
      isCentered
      closeOnOverlayClick={!isLoading}
      size="2xl"
      variant={variant}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading as="h2" variant="adminH6">
            {title}
          </Heading>
        </ModalHeader>
        <ModalCloseButton
          as={CloseButtonWrapper}
          top={pxToRem(20)}
          right={pxToRem(20)}
        >
          <IconCTAButton ariaLabel={t("common.close")} />
        </ModalCloseButton>
        <ModalBody>{children}</ModalBody>
      </ModalContent>
    </ChakraModal>
  );
};

interface EditTeacherDetailsModalProps {
  isOpen: boolean;
  isLoading: boolean;
  user?: IUser;
  removing: boolean;
  defaultUsersGroupsRole: UsersGroupsRole;
  handleClose: () => void;
  handleRemove: (user: IUser) => void;
  handleSave: (user: IUser, usersGroupsRole: UsersGroupsRole) => void;
}

export const EditTeacherDetailsModal: React.FC<
  EditTeacherDetailsModalProps
> = ({
  isOpen,
  user,
  isLoading,
  defaultUsersGroupsRole,
  removing,
  handleRemove,
  handleSave,
  handleClose,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const formik = useFormik<ITeacherDetailForm>({
    validateOnMount: true,
    initialValues: {
      usersGroupsRole: defaultUsersGroupsRole,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      if (!user) return;

      handleSave(user, values.usersGroupsRole);
    },
  });

  const handleUsersGroupsRoleUpdate = (value: string) => {
    formik.setFieldValue("usersGroupsRole", value);
  };

  const handleCloseDeleteModal = () => {
    if (removing) return;
    setShowDeleteModal(false);
  };

  useEffect(() => {
    setShowDeleteModal(false);
  }, [isOpen]);

  const canEdit = user?.id !== "0";

  const roles = [UsersGroupsRole.Owner, UsersGroupsRole.Assistant];
  const roleOptions = roles.map((role) => {
    return { label: t(`common.usersGroupsRole${role}`), value: role };
  });

  const generatedName = user ? generateTeacherName(user) : undefined;
  const teacherName = generatedName?.secondary || generatedName?.primary || "";

  return (
    <>
      <EditTeacherModalWrapper
        title={t("editTeacherDetails.title")}
        isOpen={isOpen && !showDeleteModal}
        onClose={handleClose}
        variant="adminStudentEditModal"
        isLoading={isLoading}
      >
        {!!user && (
          <>
            {canEdit && (
              <form>
                <SimpleGrid
                  mb={[pxToRem(24), null, pxToRem(40)]}
                  gap={[pxToRem(24), null, pxToRem(32)]}
                >
                  <FormControl variant="adminFormControl">
                    <FormLabel variant="adminFormLabelDarkGray">
                      {t("editTeacherDetails.fullName")}
                    </FormLabel>
                    <Heading as="p" variant="adminH6">
                      {teacherName}
                    </Heading>
                  </FormControl>
                  <FormControl
                    isDisabled={isLoading}
                    variant="adminFormControl"
                  >
                    <FormLabel
                      variant="adminFormLabelDarkGray"
                      htmlFor="editTeacherDetails.selectUsersGroupsRole"
                    >
                      {t("editTeacherDetails.selectUsersGroupsRole")}
                    </FormLabel>
                    <Dropdown
                      id="editTeacherDetails.selectUsersGroupsRole"
                      options={roleOptions}
                      value={roleOptions.filter(
                        ({ value }) => value === formik.values.usersGroupsRole
                      )}
                      handleChange={(v) =>
                        handleUsersGroupsRoleUpdate(
                          (v as IOption).value.toString()
                        )
                      }
                    />
                  </FormControl>
                </SimpleGrid>
              </form>
            )}
            {!canEdit && (
              <Box mb={[pxToRem(24), null, pxToRem(40)]}>
                <Text variant="adminP1" color="primary.dark-gray">
                  {t("editTeacherDetails.cannotEditPendingTeacher")}
                </Text>
              </Box>
            )}

            <Flex
              alignItems={["flex-start", null, "center"]}
              gap={[pxToRem(24), null, pxToRem(12)]}
              flexDir={["column-reverse", null, "row"]}
            >
              <Flex gap={pxToRem(12)} w={["full", null, "auto"]}>
                {canEdit && (
                  <Button
                    size="lg"
                    variant="adminButtonFilled"
                    flex={[1, null, "auto"]}
                    isLoading={isLoading}
                    onClick={formik.submitForm}
                    disabled={!canEdit}
                  >
                    {t("common.save")}
                  </Button>
                )}

                <Button
                  size="lg"
                  variant="adminButtonOutlined"
                  onClick={handleClose}
                  flex={[1, null, "auto"]}
                  isLoading={isLoading}
                >
                  {t("common.cancel")}
                </Button>
              </Flex>
              <Button
                variant="adminTextButtonMedium"
                marginLeft={[pxToRem(0), null, "auto"]}
                color="utility.error"
                _hover={hoverStyles({ color: "utility.error-hover" })}
                _focusVisible={{ color: "utility.error-hover" }}
                isDisabled={isLoading}
                onClick={() => setShowDeleteModal(true)}
              >
                {t("editTeacherDetails.removeTeacher")}
              </Button>
            </Flex>
          </>
        )}
      </EditTeacherModalWrapper>

      <EditTeacherModalWrapper
        isOpen={showDeleteModal && isOpen}
        onClose={handleCloseDeleteModal}
        title={t("editTeacherDetails.deleteTitle")}
        variant="adminStudentDeleteConfirmation"
        isLoading={removing}
      >
        {!!user && (
          <>
            <Text variant="adminP1" color="primary.dark-gray">
              {t("editTeacherDetails.adminTeacherDeleteConfirmation")}
            </Text>
            <Flex
              mt={pxToRem(24)}
              mb={[pxToRem(24), null, pxToRem(40)]}
              p={pxToRem(24)}
              gap={pxToRem(20)}
              alignItems="center"
              borderRadius={pxToRem(20)}
              bg="primary.tan"
            >
              <Box>
                {user.profile_image_url && (
                  <Avatar
                    size="lg"
                    src={user.profile_image_url}
                    name={teacherName}
                    borderRadius="full"
                    objectFit="cover"
                  />
                )}
              </Box>
              <Box>
                <Text variant="adminP1" mb={pxToRem(5)}>
                  {teacherName}
                  {!user.given_name && !user.family_name && user.email}
                </Text>
              </Box>
            </Flex>
            <Flex gap={pxToRem(12)}>
              <Button
                variant="adminButtonFilled"
                size="lg"
                flex={[1, null, "unset"]}
                onClick={() => handleRemove(user)}
                isLoading={removing}
              >
                {t("editTeacherDetails.removeTeacher")}
              </Button>
              <Button
                variant="adminButtonOutlined"
                size="lg"
                onClick={() => setShowDeleteModal(false)}
                flex={[1, null, "unset"]}
                isLoading={removing}
              >
                {t("editTeacherDetails.goBack")}
              </Button>
            </Flex>
          </>
        )}
      </EditTeacherModalWrapper>
    </>
  );
};
