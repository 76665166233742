import { Box } from "@chakra-ui/react";
import React, { useMemo } from "react";

import { LoadingSpinner } from "adminComponents/atoms/LoadingSpinner";
import {
  UserCardProps,
  UsersTab,
} from "adminComponents/screens/ClassroomDetailScreen/components/UsersTab";
import { generateTeacherName, pxToRem } from "adminComponents/utils";
import { useErrorToast } from "adminComponents/utils/toast";
import { usePageTrack } from "lib/contexts/analytics";
import { useFetchClassroomUsers } from "links/lib/features/classrooms";
import { AnalyticsPage, IClassroom, UsersGroupsRole } from "links/lib/types";
import { sortAlpha } from "links/lib/util";
import { useEditTeacher } from "screens/TeacherClassrooms/shared/hooks/useEditTeacher";

import { useAddTeacherByEmail } from "./hooks/useAddTeacherByEmail";

export interface ITeachersTabProps {
  classroom: IClassroom;
}

export const TeachersTab: React.FC<ITeachersTabProps> = ({ classroom }) => {
  usePageTrack(AnalyticsPage.TeacherDashboard_ClassroomDetail_Teachers);

  const classroomTeachersFetch = useFetchClassroomUsers({
    classroom_id: classroom.id,
    group_roles: [UsersGroupsRole.Owner, UsersGroupsRole.Assistant],
  });
  useErrorToast(classroomTeachersFetch.error);

  const { editModal, handleEditIntent, handleRemove } = useEditTeacher({
    classroomId: classroom.id,
  });

  const { handleAddIntent: handleAddByEmailIntent, modal: addByEmailModal } =
    useAddTeacherByEmail({ classroomId: classroom.id });

  const teachersData = useMemo<Array<UserCardProps>>(() => {
    const data: Array<UserCardProps> = [];

    classroomTeachersFetch.data?.users.forEach((user) => {
      data.push({
        user,
        usersGroupsRole: classroomTeachersFetch.data?.users_groups.find(
          (ug) => ug.user_id === user.id
        )?.user_role,
      });
    });

    data.sort((a, b) => {
      const aName = generateTeacherName(a.user);
      const bName = generateTeacherName(b.user);

      return sortAlpha(aName.primary, bName.primary);
    });

    return data;
  }, [
    classroomTeachersFetch.data?.users,
    classroomTeachersFetch.data?.users_groups,
  ]);

  const isLoading = classroomTeachersFetch.isLoading;

  return (
    <>
      {isLoading ? (
        <Box mt={pxToRem(20)}>
          <LoadingSpinner />
        </Box>
      ) : (
        <UsersTab
          classroom={classroom}
          showTeachersHeading={true}
          usersData={teachersData}
          handleAddByEmail={
            classroom.users_groups_role === UsersGroupsRole.Owner
              ? handleAddByEmailIntent
              : undefined
          }
          handleEditTeacher={handleEditIntent}
          handleRemove={handleRemove}
          usersGroupsRole={classroom.users_groups_role}
        />
      )}

      {addByEmailModal}
      {editModal}
    </>
  );
};
