import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";

export interface IFetchTopOrganizationSessionCreatorsParams {
  days_ago: number;
  limit?: number;
}

interface TopOrganizationSessionCreator {
  user_id: string;
  sessions_count: string;
}

export interface IFetchTopOrganizationSessionCreatorsResponse {
  top_organization_class_session_creators: Array<TopOrganizationSessionCreator>;
}

export default function useFetchTopOrganizationSessionCreators({
  days_ago,
  limit = 9,
}: IFetchTopOrganizationSessionCreatorsParams): UseQueryResult<
  IFetchTopOrganizationSessionCreatorsResponse,
  unknown
> {
  const axios = useAxios();

  const query = useQuery<IFetchTopOrganizationSessionCreatorsResponse>(
    ["top-organization-session-creators", { days_ago, limit }],
    async (): Promise<IFetchTopOrganizationSessionCreatorsResponse> => {
      return await axios
        .get(
          `/v1/organizations/practice-sets/sessions/top-class-session-creators`,
          {
            params: {
              days_ago,
              limit,
            },
          }
        )
        .then((r) => {
          return r.data as IFetchTopOrganizationSessionCreatorsResponse;
        });
    }
  );

  return query;
}
