import React, { useState } from "react";
import { Route, Switch, useParams } from "react-router-dom";

import { redirectIfNaN } from "links/lib/util";
import { TeacherAssignmentGradebook } from "screens/TeacherAssignmentGradebook";

import { AssignmentDetail } from "./AssignmentDetail";
import { AssignmentStudentDetail } from "./AssignmentDetail/components/AssignmentStudentDetail";
import { StudentPerformanceProvider } from "./AssignmentDetail/hooks/useStudentPerformanceData";
import { ClassroomArchive } from "./ClassroomArchive";
import { ClassroomDetail } from "./ClassroomDetail";
import { ClassroomList } from "./ClassroomList";
import { ClassroomPracticeSetReport } from "./PracticeSetDetail";
import { StudentPracticeSetReport } from "./PracticeSetDetail/components/PracticeSetStudentDetail";
import { StudentDetail } from "./StudentDetail";

export const TeacherClassrooms: React.FC = () => {
  return (
    <Switch>
      <Route path="/t/classrooms/assignment-gradebook">
        <TeacherAssignmentGradebook />
      </Route>
      <Route path="/t/classrooms/archive">
        <ClassroomArchive />
      </Route>
      <Route path="/t/classrooms/create">
        <ClassroomList initialCreateOpen />
      </Route>
      <Route path="/t/classrooms" exact={true}>
        <ClassroomList />
      </Route>
      <Route path="/t/classrooms/:classroomId">
        <TeacherClassroomDetailRouter />
      </Route>
    </Switch>
  );
};

const TeacherClassroomDetailRouter: React.FC = () => {
  const { classroomId } = useParams<{ classroomId: string }>();

  redirectIfNaN(classroomId, "/t/classrooms");

  return (
    <Switch>
      <Route path="/t/classrooms/:classroomId/sets/:practiceSetId/students/:studentId">
        <StudentPracticeSetReport />
      </Route>
      <Route path="/t/classrooms/:classroomId/sets/:practiceSetId">
        <ClassroomPracticeSetReport />
      </Route>
      <Route path="/t/classrooms/:classroomId/assignment-gradebook">
        <TeacherAssignmentGradebook />
      </Route>
      <Route path="/t/classrooms/:classroomId/assignments/:assignmentId/students/:studentId">
        <AssignmentStudentDetail />
      </Route>
      <Route path="/t/classrooms/:classroomId/assignments/:assignmentId">
        <TeacherAssignmentDetailRouter />
      </Route>
      <Route path="/t/classrooms/:classroomId/students/:studentId">
        <StudentDetail />
      </Route>
      <Route path="/t/classrooms/:classroomId">
        <ClassroomDetail />
      </Route>
    </Switch>
  );
};

const TeacherAssignmentDetailRouter: React.FC = () => {
  const { classroomId, assignmentId } =
    useParams<{ classroomId: string; assignmentId: string }>();

  const [downloadedCsv, setDownloadedCsv] = useState(false);
  redirectIfNaN(assignmentId, "/t/classrooms");

  return (
    <Switch>
      <Route path="/t/classrooms/:classroomId/assignments/:assignmentId">
        <StudentPerformanceProvider
          assignmentId={assignmentId}
          classroomId={classroomId}
          handleStudentPerformanceSortChange={() => setDownloadedCsv(false)}
        >
          <AssignmentDetail
            downloadedCsv={downloadedCsv}
            setDownloadedCsv={setDownloadedCsv}
          />
        </StudentPerformanceProvider>
      </Route>
    </Switch>
  );
};
