import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { useMount, useSessionStorage } from "react-use";

import { SignInAsScreen } from "adminComponents/screens/SignInScreen";
import {
  afterSignInPathSessionKey,
  signInIsPopupSessionKey,
} from "lib/constants";
import { usePageTitle } from "lib/hooks/usePageTitle";
import { UserRole } from "links/lib/types";

interface ISignInState {
  // indicates the intended role of the sign in based on which button was clicked
  signInRole?: UserRole;
  isLoading: boolean;
}

type LocationState = {
  from?: {
    pathname?: string;
    search?: string;
  };
};

const SignIn: React.FC = () => {
  const [state, setState] = useState<ISignInState>({ isLoading: false });
  const location = useLocation();
  const history = useHistory();
  const [, setAfterSignInPath] = useSessionStorage(afterSignInPathSessionKey);
  const [, setIsPopup] = useSessionStorage(signInIsPopupSessionKey);
  const { t } = useTranslation("admin", { useSuspense: false });
  const search = new URLSearchParams(location.search || "");

  usePageTitle(t("signInContainer.rootPageTitle"));

  useMount(() => {
    // store the originally intended path in session storage
    // before redirect
    if (location?.state) {
      const { from } = location.state as LocationState;
      if (from && from.pathname) {
        setAfterSignInPath(`${from.pathname}${from.search}`);
      }
    }
    if (search.get("popup") !== null) {
      const isPopup = search.get("popup") === "true";
      setIsPopup(isPopup);
    }
  });

  const { isLoading } = state;

  async function onStudentSignIn() {
    setState({
      isLoading: true,
    });

    history.push("/sign-in/student");
  }

  async function onTeacherSignIn() {
    setState({
      isLoading: true,
    });

    history.push("/sign-in/teacher");
  }

  return (
    <SignInAsScreen
      isButtonsLoading={isLoading}
      handleSignInAsStudent={onStudentSignIn}
      handleSignInAsTeacher={onTeacherSignIn}
    />
  );
};

export default SignIn;
