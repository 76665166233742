import { Flex, Spinner } from "@chakra-ui/react";
import React from "react";

import { ColorScheme } from "adminComponents";
import { pxToRem } from "adminComponents/utils/pxToRem";

export interface ILoadingSpinnerProps {
  color?: ColorScheme;
  size?: string;
}

export const LoadingSpinner: React.FC<ILoadingSpinnerProps> = ({
  color,
  size = "xl",
}) => {
  return (
    <Flex align="center" justify="center" flex={1}>
      <Spinner
        color={color || "primary.dark-gray"}
        size={size}
        speed="1.5s"
        thickness={pxToRem(4)}
      />
    </Flex>
  );
};
