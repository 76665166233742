/*

Utility method for converting px to rem
Allows straightforward conversion of px based designs to rem
for accessibility and nice scaling.

*/

// Number of pixels to one rem
export const pxPerRemUnit = 16;

export const pxToRem = (px: number): string => {
  if (!px) return "0";
  return `${px / pxPerRemUnit}rem`;
};
