import { pxToRem } from "adminComponents/utils/pxToRem";

interface IProps {
  isEnd: boolean;
}

export const AdminMultiStepModal = {
  baseStyle: ({ isEnd }: IProps): Record<string, unknown> => ({
    container: {
      maxWidth: { base: pxToRem(345), md: pxToRem(634) },
      paddingTop: { base: pxToRem(24), md: pxToRem(48) },
    },
    title: {
      marginBottom: { base: pxToRem(8), md: pxToRem(24) },
    },
    subtitle: {
      marginBottom: pxToRem(8),
    },
    content: {
      flexDirection: "column",
      width: "100%",
    },
    body: {
      marginTop: { base: pxToRem(20), md: pxToRem(32) },
      borderRadius: { base: pxToRem(8), md: pxToRem(4) },
      overflow: "hidden",
    },
    actions: {
      gap: pxToRem(16),
      marginTop: { base: pxToRem(20), md: pxToRem(32) },
    },
    button: {
      width: { base: "100%", md: "auto" },
      flexGrow: 1,
    },
    next: {
      width: { base: "100%", md: "auto" },
      flexGrow: 1,
      display: isEnd ? "none" : "flex",
    },
    finish: {
      width: { base: "100%", md: "auto" },
      flexGrow: 1,
      display: isEnd ? "flex" : "none",
    },
  }),
};
