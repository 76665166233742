export const registerObserver = (targetSelector: string): void => {
  const options = {
    rootMargin: "0px",
    threshold: 0,
  };

  const observer = new IntersectionObserver(
    (entries: IntersectionObserverEntry[]) => {
      const first = entries[0];
      let show = false;
      const isWindowScrolledPastControlsHeader =
        first.boundingClientRect.top < 0;

      const isControlsHeaderVisible = first.isIntersecting;

      if (isControlsHeaderVisible) {
        show = false;
      } else if (isWindowScrolledPastControlsHeader) {
        show = true;
      } else {
        show = false;
      }

      const customEvent = new CustomEvent("question-list-controls-intersect", {
        detail: {
          showFixedFooter: show,
        },
      });
      window.dispatchEvent(customEvent);
    },
    options
  );

  const targets = document.querySelectorAll(targetSelector);
  targets.forEach((target) => observer.observe(target));
};

export default registerObserver;
