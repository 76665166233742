import { Box, CSSObject } from "@chakra-ui/react";
import React from "react";
import { FileRejection, useDropzone } from "react-dropzone";

import { hoverStyles, pxToRem } from "adminComponents/utils";

import { Button } from "../Button";

export interface IDropzoneProps {
  maxFiles: number;
  accept: string[];
  multiple: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  error?: string;
  sx?: CSSObject;
  handleDrop?: (acceptedFiles: File[]) => void;
  handleDropRejected?: (rejectedFiles: FileRejection[]) => void;
}

export const Dropzone: React.FC<IDropzoneProps> = ({
  maxFiles,
  accept,
  multiple,
  isLoading,
  isDisabled,
  error,
  handleDrop,
  handleDropRejected,
  sx,
  children,
}) => {
  const onDrop = async (acceptedFiles: File[]) => {
    handleDrop?.(acceptedFiles);
  };

  const onDropRejected = (rejectedFiles: FileRejection[]) => {
    handleDropRejected?.(rejectedFiles);
  };

  const { getRootProps, getInputProps, isDragActive, isFocused } = useDropzone({
    accept,
    disabled: isDisabled,
    maxFiles,
    multiple,
    onDrop,
    onDropRejected,
  });

  return (
    <Button
      as={Box}
      variant="adminDropZone"
      isLoading={isLoading}
      borderRadius={pxToRem(20)}
      sx={sx}
      borderColor={
        error
          ? "utility.error"
          : isDragActive || isFocused
          ? "primary.warm-black"
          : "primary.medium-gray"
      }
      _hover={hoverStyles({
        borderColor: error ? "utility.error" : "primary.warm-black",
      })}
      {...getRootProps()}
      whiteSpace="normal"
    >
      <input {...getInputProps()} />
      {children}
    </Button>
  );
};
