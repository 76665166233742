import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { ICustomSessionGroup } from "../../types";

export interface ICreateSessionGroupsArgs {
  classroom_id?: string;
  session_groups: Array<ICustomSessionGroup>;
}

export interface ICreateSessionGroupsResponse {
  session_groups: Array<ICustomSessionGroup>;
}

export interface IUseMutateAssignmentArgs {
  onSuccess?: (response: ICreateSessionGroupsResponse) => void;
  onError?: () => void;
  onSettled?: () => void;
}

export default function useCreateCustomSessionGroups(
  args: IUseMutateAssignmentArgs
): UseMutationResult<
  ICreateSessionGroupsResponse,
  unknown,
  ICreateSessionGroupsArgs
> {
  const axios = useAxios();
  const queryClient = useQueryClient();

  const createCustomSessionGroups = async (args: ICreateSessionGroupsArgs) => {
    const { classroom_id, session_groups } = args;

    const response = await axios({
      method: "post",
      url: `/v1/groups/${classroom_id}/session-groups`,
      data: {
        session_groups,
      },
    }).then((r) => r.data as ICreateSessionGroupsResponse);

    queryClient.invalidateQueries(["customSessionGroups", { classroom_id }]);
    queryClient.setQueryData(["customSessionGroups", { classroom_id }], {
      session_groups: response.session_groups,
    });

    return response;
  };

  const mutation = useMutation(createCustomSessionGroups, {
    onError: args.onError,
    onSuccess: args.onSuccess,
    onSettled: args.onSettled,
  });

  return mutation;
}
