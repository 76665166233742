import {
  FormControl,
  FormLabel,
  HStack,
  InputGroup,
  InputRightElement,
  ModalFooter,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useCopyToClipboard } from "react-use";

import { Button } from "adminComponents/atoms/Button";
import { Input } from "adminComponents/atoms/Input";
import { Modal } from "adminComponents/atoms/Modal";
import { SocialShareButton } from "adminComponents/molecules/ShareAvatarControls";
import FacebookLogo from "adminComponents/molecules/ShareAvatarControls/resource/facebook.png";
import LinkedInLogo from "adminComponents/molecules/ShareAvatarControls/resource/linkedin.png";
import XLogo from "adminComponents/molecules/ShareAvatarControls/resource/x.png";
import { pxToRem } from "adminComponents/utils";
import { useShowToast } from "adminComponents/utils/toast";
import { SocialMediaShareProvider } from "links/lib/types";

interface SocialMediaShareModalProps {
  title?: string;
  isOpen: boolean;
  shareUrl: string;
  handleClose: () => void;
  handleShare: (provider: SocialMediaShareProvider) => void;
}

export const SocialMediaShareModal: React.FC<SocialMediaShareModalProps> = ({
  title,
  isOpen,
  shareUrl,
  handleClose,
  handleShare,
}) => {
  const { t } = useTranslation("admin", {
    keyPrefix: "common",
    useSuspense: false,
  });

  const showToast = useShowToast();
  const [, copy] = useCopyToClipboard();

  const onCopyShareLink = () => {
    copy(shareUrl);
    showToast(t("linkCopied"));
  };

  return (
    <Modal
      title={title}
      isOpen={isOpen}
      onClose={handleClose}
      size="2xl"
      showBackButton={false}
    >
      <VStack spacing={pxToRem(36)}>
        <HStack w="full" justifyContent="space-evenly" mt={pxToRem(12)}>
          <SocialShareButton
            provider={SocialMediaShareProvider.X}
            imageUrl={XLogo}
            handleClick={handleShare}
            ariaLabel={t("shareOnX")}
          />
          <SocialShareButton
            provider={SocialMediaShareProvider.Facebook}
            imageUrl={FacebookLogo}
            handleClick={handleShare}
            ariaLabel={t("shareOnFacebook")}
          />
          <SocialShareButton
            provider={SocialMediaShareProvider.LinkedIn}
            imageUrl={LinkedInLogo}
            handleClick={handleShare}
            ariaLabel={t("shareOnLinkedIn")}
          />
        </HStack>

        <FormControl>
          <FormLabel htmlFor="shareUrl">{t("copyLink")}</FormLabel>
          <InputGroup>
            <Input
              name="shareUrl"
              value={shareUrl}
              contentEditable={false}
              paddingRight={{ base: pxToRem(10), md: pxToRem(160) }}
              textOverflow="ellipsis"
            />
            <InputRightElement
              width="auto"
              right={pxToRem(16)}
              top={pxToRem(4)}
              display={["none", null, "inline-flex"]}
            >
              <Button
                size="sm"
                variant="adminButtonFilled"
                onClick={onCopyShareLink}
              >
                {t("copyShareLink")}
              </Button>
            </InputRightElement>
          </InputGroup>
        </FormControl>
      </VStack>

      <ModalFooter justifyContent="flex-start" mt={pxToRem(24)} p={0}>
        <Button
          variant="adminButtonOutlined"
          size="md"
          w={["50%", null, "auto"]}
          onClick={handleClose}
          rounded="full"
        >
          {t("close")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
