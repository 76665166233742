import { Box, useMultiStyleConfig } from "@chakra-ui/react";
import React from "react";

import { ColorScheme } from "adminComponents";
import { Heading } from "adminComponents/atoms/Heading";
import { pxToRem } from "adminComponents/utils/pxToRem";

import { CertifiedCheckIcon } from "../CertifiedCheckIcon";
import { ReactComponent as CircleBullet } from "./resource/circle_bg.svg";

export interface IProps {
  circleColor?: ColorScheme;
  color?: ColorScheme;
  number: number;
  small?: boolean;
  certified?: boolean;
}

const CircledNumber: React.FC<IProps> = ({
  circleColor = "primary.tan",
  number,
  color = "primary.warm-black",
  small = false,
  certified = false,
}) => {
  const styles = useMultiStyleConfig("AdminCircleNumber", {
    circleColor,
    small,
  });
  return (
    <Box __css={styles.outer}>
      <Box __css={styles.iconContainer}>
        <CircleBullet
          width="100%"
          height="100%"
          aria-hidden
          focusable={false}
        />
      </Box>
      <Box __css={styles.numberContainer}>
        <Heading
          as="p"
          display="block"
          variant="adminH4"
          color={color}
          {...(small ? { fontSize: pxToRem(16) } : {})}
        >
          {number}
        </Heading>
      </Box>
      {certified && (
        <Box __css={styles.certifiedContainer}>
          <CertifiedCheckIcon
            boxSize={
              small
                ? [pxToRem(14), null, pxToRem(16)]
                : [pxToRem(24), null, pxToRem(28)]
            }
            inverted
          />
        </Box>
      )}
    </Box>
  );
};

export { CircledNumber };
