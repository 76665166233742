import { Box, Flex, useMultiStyleConfig } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { CalendarDate } from "adminComponents/atoms/CalendarDate";
import { CoverImageIcon } from "adminComponents/atoms/CoverImageIcon";
import { Heading } from "adminComponents/atoms/Heading";
import { Text } from "adminComponents/atoms/Text";
import { PieChartWithLabel } from "adminComponents/molecules/PieChartWithLabel";
import { IAssignment } from "links/lib/types";

export interface IProps {
  assignment: IAssignment;
  accuracy: number;
  completionDate?: string;
}

export const StudentCompletedAssignment: React.FC<IProps> = ({
  assignment,
  accuracy,
  completionDate = "",
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });

  const styles = useMultiStyleConfig("AdminStudentCompletedAssignment", {});

  const { required_score_percent } = assignment;
  const { title, cover_image_icon } = assignment.practice_set;

  return (
    <Flex>
      <Box sx={styles.iconContainer}>
        <CoverImageIcon
          sx={styles.icon}
          icon={cover_image_icon}
          iconSize={63}
          borderColor="primary.white"
          backgroundColor="primary.golden-light"
          variant="adminSolidBorder"
        />
      </Box>

      <Flex sx={styles.content}>
        <Box sx={styles.details}>
          <Heading sx={styles.title} as="p" variant="adminH4">
            {title}
          </Heading>
          <Flex sx={styles.completion}>
            <CalendarDate date={completionDate} dateType="complete" />
          </Flex>
        </Box>

        <Flex sx={styles.accuracy}>
          <PieChartWithLabel
            percentage={accuracy}
            text={t("common.accuracy")}
            textVariant="adminP2"
          />
          <Text sx={styles.goal} variant="adminP2">
            {t("accuracyGoals.goal", { number: required_score_percent })}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
