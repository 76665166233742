import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDebounce, usePrevious, useWindowSize } from "react-use";

import { useAnalytics } from "lib/contexts/analytics";
import { useAuth } from "links/lib/features/auth";
import useFetchHATStats from "links/lib/features/users/useFetchHATStats";
import { AnalyticsEvent, UserRole } from "links/lib/types";

const AppAnalytics: React.FC = () => {
  const { analytics, group, identify, trackEvent } = useAnalytics();
  const { authUser, authLicense, getFeatureFlags, hasNoPremiumAccess } =
    useAuth();
  const [hasIdentified, setHasIdentified] = useState(false);
  const windowSize = useWindowSize();

  const hatStatsResp = useFetchHATStats({
    userId: authUser?.id,
    disabled:
      !authUser || authUser.role === UserRole.Student || authUser.is_guest,
  });

  const prevRole = usePrevious(authUser?.role);
  const prevGuestStatus = usePrevious(authUser?.is_guest);

  // identify again if role changes
  useEffect(() => {
    if (hasIdentified && !!prevRole && prevRole !== authUser?.role) {
      setHasIdentified(false);
    }
  }, [hasIdentified, prevRole, authUser?.role]);

  // identify again if guest status changes
  useEffect(() => {
    if (
      hasIdentified &&
      prevGuestStatus !== undefined &&
      prevGuestStatus !== authUser?.is_guest
    ) {
      setHasIdentified(false);
    }
  }, [hasIdentified, prevGuestStatus, authUser?.is_guest]);

  const waitOnHatStats =
    !hatStatsResp.isFetched &&
    authUser?.role !== UserRole.Student &&
    !authUser?.is_guest;

  useEffect(() => {
    if (!analytics || !authUser) return;
    if (waitOnHatStats) return;

    if (!hasIdentified) {
      if (authUser.organization_id) {
        group(authUser.organization_id, {
          organization_id: authUser.organization_id,
          name: authUser.organization_name,
          group_type: "Organization",
          is_internal: !!authUser.organization_is_internal,
        });
      }

      const authProps: { [key: string]: unknown } = {
        role: authUser.true_role,
        country: authUser.country,
        language: authUser.language,
        timezone_locale: authUser.timezone_locale,
        is_internal: !!authUser.organization_is_internal,
        is_guest: !!authUser.is_guest,
        created_at: authUser.created_at,
      };

      // Add enabled feature flags as properties of user
      const featureFlags = getFeatureFlags();
      if (featureFlags) {
        Object.keys(featureFlags).forEach((featureName) => {
          const flagVal = featureFlags[featureName];

          if (flagVal) {
            authProps[`feature_${featureName.replaceAll(".", "_")}`] = true;
          }
        });
      }

      if (
        authUser.true_role === UserRole.Teacher ||
        authUser.true_role === UserRole.Admin
      ) {
        authProps.email = authUser.email;
        authProps.familyName = authUser.family_name;
        authProps.givenName = authUser.given_name;
        authProps.teacherPreferredName = authUser.teacher_preferred_name;
        authProps.teacherGradeLevels = authUser.teacher_grade_levels?.map(
          (g) => g.grade_level_id
        );
        authProps.teacherSubjects = authUser.teacher_subjects?.map(
          (s) => s.subject_id
        );
        authProps.isTrial = authLicense?.is_trial;
        authProps.hasPremiumAccess = !hasNoPremiumAccess;

        // Just in case we haven't set the locale yet
        moment.locale(authUser.language);
        if (
          authLicense?.is_trial &&
          authLicense?.end_date &&
          moment(authLicense?.end_date).isAfter(moment())
        ) {
          authProps.trialEndDate = authLicense?.end_date;
          authProps.trialEndDateFormatted = moment(
            authLicense?.end_date
          ).format("MMM Do YYYY");
          authProps.trialEndDateHubSpotFormatted = moment(
            authLicense?.end_date
          ).format("YYYY-MM-DD");
        }

        if (hatStatsResp.data?.hat_stats.session_count) {
          authProps.hatSessionCount =
            hatStatsResp.data?.hat_stats.session_count;
        }
        if (hatStatsResp.data?.hat_stats.assignment_count) {
          authProps.hatAssignmentCount =
            hatStatsResp.data?.hat_stats.assignment_count;
        }
        authProps.isHAT =
          !!hatStatsResp.data?.hat_stats.session_count ||
          !!hatStatsResp.data?.hat_stats.assignment_count;

        // user should should not be contacted as a HAT if user has current non trial license
        if (authLicense?.has_premium_access && !authLicense.is_trial) {
          authProps.isHAT = false;
        }

        // Only users with Teacher role are eligible to be a HAT.
        // HAT status is used for Marketing targeting and we don't target roles such as Admins.
        if (authUser.role !== UserRole.Teacher) {
          authProps.isHAT = false;
        }
      }

      identify(authUser.id, authProps);

      setHasIdentified(true);
    }
  }, [
    authUser,
    authLicense,
    analytics,
    hasIdentified,
    group,
    identify,
    getFeatureFlags,
    hasNoPremiumAccess,
    authLicense?.end_date,
    authLicense?.is_trial,
    hatStatsResp,
    waitOnHatStats,
  ]);

  useDebounce(
    () => {
      trackEvent(AnalyticsEvent.Common_WindowSizeChange, {
        width: windowSize.width,
        height: windowSize.height,
      });
    },
    10000,
    [windowSize]
  );

  return null;
};

export default AppAnalytics;
