import { Box, Flex, GridItem, Image, SimpleGrid } from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Modal } from "adminComponents/atoms/Modal";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { IUserPracticeSetItemResponses } from "links/lib/features/classrooms/useFetchClassroomRecentPracticeSetResponses";
import { IPracticeSetItem, IUser } from "links/lib/types";
import { RichTextRenderer } from "sharedComponents/atoms/RichTextRenderer";

import { StudentListItem } from "../StudentsModal";

interface IProps {
  isOpen: boolean;
  handleClose: () => void;
  detailStudent?: IUser;
  detailImageUrl?: string;
  students: Array<IUser>;
  practiceSetItemResponses: Array<IUserPracticeSetItemResponses>;
  practiceSetItem?: IPracticeSetItem;
  classroomId: string;
}

export const DrawingResultsModal: React.FC<IProps> = ({
  isOpen,
  handleClose,
  detailStudent,
  detailImageUrl,
  students,
  practiceSetItemResponses,
  practiceSetItem,
  classroomId,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  const [specificStudent, setSpecificStudent] = useState(detailStudent);
  const [specificImageUrl, setSpecificImageUrl] = useState(detailImageUrl);

  const studentsMap = useMemo(() => {
    const res: { [key: string]: IUser | undefined } = {};
    students?.forEach((s) => {
      res[s.id] = s;
    });
    return res;
  }, [students]);

  const respondedStudentsCount = useMemo(() => {
    const respondedStudents = new Set();
    practiceSetItemResponses.forEach((r) => {
      if (
        r.responses.some((r) => r.practice_set_item_id === practiceSetItem?.id)
      ) {
        respondedStudents.add(r.user_id);
      }
    });

    return respondedStudents.size;
  }, [practiceSetItem?.id, practiceSetItemResponses]);

  const title = specificStudent
    ? t("drawingResultsModal.singleStudentTitle")
    : t("drawingResultsModal.title", { count: respondedStudentsCount });
  let subtitle = specificStudent
    ? t("drawingResultsModal.singleStudentSubtitle")
    : `${t("drawingResultsModal.subtitle")}`;

  if (practiceSetItem?.draw?.prompt) {
    subtitle += ":";
  }

  const resetStateAndHandleClose = () => {
    setSpecificStudent(undefined);
    setSpecificImageUrl(undefined);
    handleClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={resetStateAndHandleClose}
      showBackButton={false}
      title={title}
      variant="adminStudentsModal"
      headingProps={{
        as: "h2",
        fontSize: [pxToRem(18), null, pxToRem(24)],
        variant: "adminH6",
      }}
    >
      <Text color="primary.dark-gray" variant="adminP2">
        {subtitle}
      </Text>
      {practiceSetItem?.draw?.prompt && (
        <Text as="div" variant="adminP2">
          <RichTextRenderer content={practiceSetItem?.draw?.prompt} />
        </Text>
      )}
      {!!specificStudent && !!specificImageUrl ? (
        <Flex flexDir="column" gap={pxToRem(24)} mt={pxToRem(24)}>
          <Box
            borderColor="primary.medium-gray"
            borderWidth={pxToRem(1)}
            borderStyle="solid"
            borderRadius="lg"
            w="fit-content"
            overflow="hidden"
          >
            <Image src={specificImageUrl} alt="" maxH={pxToRem(338)} />
          </Box>
          <StudentListItem
            student={specificStudent}
            responseText=""
            choiceImageUrl=""
            choiceImageAltText=""
            showResponse={true}
            classroomId={classroomId}
            questionUsesPlainTextAnswers={false}
            questionUsesImageAnswers={true}
            p="0"
          />
          <Button
            w={pxToRem(71)}
            variant="adminButtonOutlined"
            onClick={() => {
              setSpecificStudent(undefined);
              setSpecificImageUrl(undefined);
            }}
          >
            {t("common.back")}
          </Button>
        </Flex>
      ) : (
        <SimpleGrid
          minChildWidth={pxToRem(200)}
          spacing={pxToRem(16)}
          justifyItems="center"
          mt={pxToRem(24)}
          p={pxToRem(16)}
          borderColor="primary.medium-gray"
          borderWidth={pxToRem(1)}
          borderStyle="solid"
          borderRadius="lg"
        >
          {respondedStudentsCount === 0 && (
            <GridItem>
              <Text variant="adminP1Bold">
                {t("drawingResultsModal.noResponses")}
              </Text>
            </GridItem>
          )}
          {practiceSetItemResponses
            .flatMap((responses) =>
              responses.responses.filter(
                (r) => r.practice_set_item_id === practiceSetItem?.id
              )
            )
            .map((response) => {
              const student = studentsMap[response.user_id];
              if (!student) return;

              return (
                <GridItem
                  key={`${student.id}-${response.id}`}
                  w="full"
                  onClick={() => {
                    setSpecificStudent(student);
                    setSpecificImageUrl(response.drawing_image_url);
                  }}
                >
                  <Flex flexDir="column" gap={pxToRem(10)}>
                    <Box
                      borderColor="primary.medium-gray"
                      borderWidth={pxToRem(1)}
                      borderStyle="solid"
                      borderRadius="lg"
                      w="fit-content"
                      overflow="hidden"
                      cursor="zoom-in"
                    >
                      <Image
                        src={response.drawing_image_url}
                        alt=""
                        maxH={pxToRem(150)}
                      />
                    </Box>
                    <StudentListItem
                      student={student}
                      responseText={response.response_text}
                      choiceImageUrl={response.choice_image_url}
                      choiceImageAltText={response.choice_image_alt_text}
                      showResponse={true}
                      classroomId={classroomId}
                      questionUsesPlainTextAnswers={false}
                      questionUsesImageAnswers={true}
                      p="0"
                    />
                  </Flex>
                </GridItem>
              );
            })}
        </SimpleGrid>
      )}
    </Modal>
  );
};
