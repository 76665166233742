import { Box, FormControl } from "@chakra-ui/react";
import { useFormik } from "formik";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Checkbox } from "adminComponents/atoms/Checkbox";
import { Divider } from "adminComponents/atoms/Divider";
import { Dropdown, IOption } from "adminComponents/atoms/Dropdown";
import { FormErrorMessage } from "adminComponents/atoms/FormErrorMessage";
import { FormLabel } from "adminComponents/atoms/FormLabel";
import { IconTooltip } from "adminComponents/atoms/IconTooltip";
import { Input } from "adminComponents/atoms/Input";
import { Textarea } from "adminComponents/atoms/Textarea";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { PracticeSetAvailability, SmartSetType } from "links/lib/types";

export interface IForm {
  title: string;
  description: string;
  folder: string;
  subjects: string[];
  gradeLevels: string[];
  language: string;
  availability: PracticeSetAvailability;
  cncFamilyCode: string;
  cncCode: string;
  isCertified: boolean;
  isPremium: boolean;
  smartSetType: SmartSetType;
  classroomId?: string;
}
type FormFieldKey = keyof IForm;

interface IProps {
  initialValue: IForm;
  options: {
    folders: IOption[];
    subjects: IOption[];
    gradeLevels: IOption[];
    languages: IOption[];
  };
  isEdit?: boolean;
  loading?: boolean;
  showCncFields?: boolean;
  allowPublish?: boolean;
  handleSubmit: (value: IForm) => void;
}

export const PracticeSetDetailForm: React.FC<IProps> = ({
  initialValue,
  loading = false,
  options: { folders, subjects, gradeLevels, languages },
  handleSubmit,
  isEdit,
  showCncFields,
  allowPublish,
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });

  // These must be declared inside the component in order to
  // use the translation functionality.
  const publicOptions: IOption[] = [
    {
      label: t("practiceSetDetailForm.optionSharingPublic"),
      value: PracticeSetAvailability.Public,
      variant: "adminSolid",
    },
    {
      label: t("practiceSetDetailForm.optionSharingDomain"),
      value: PracticeSetAvailability.Domain,
      variant: "adminSolid",
    },
    {
      label: t("practiceSetDetailForm.optionSharingPrivate"),
      value: PracticeSetAvailability.Private,
      variant: "adminSolid",
    },
  ];

  const formik = useFormik<IForm>({
    validateOnMount: true,
    initialValues: initialValue,
    enableReinitialize: true,
    onSubmit: (values) => {
      const title = values.title.trim();
      const description = values.description.trim();
      const cncFamilyCode = values.cncFamilyCode.trim();
      const cncCode = values.cncCode.trim();

      handleSubmit({
        ...values,
        title,
        description,
        cncFamilyCode,
        cncCode,
      });
    },
    validate: (values) => {
      const errors = {} as Record<FormFieldKey, string>;

      if (!values.title.trim()) {
        errors.title = t("practiceSetDetailForm.errTitleRequired");
      }

      if (!values.language.length) {
        errors.language = t("practiceSetDetailForm.errLanguageRequired");
      }

      if (!values.availability.trim()) {
        errors.availability = t("practiceSetDetailForm.errSharingRequired");
      }

      return errors;
    },
  });

  const handleFieldChange = useCallback(
    (value: unknown, key: FormFieldKey) => {
      formik.setFieldTouched(key).finally(() => {
        formik.setFieldValue(key, value);
      });
    },
    [formik]
  );

  const handleFieldBlur = useCallback(
    (key: FormFieldKey) => {
      formik.setFieldTouched(key).finally(() => {
        formik.handleBlur(key);
      });
    },
    [formik]
  );

  const handleChangeSingleDropdown = useCallback(
    (newValue: IOption, key: FormFieldKey) => {
      handleFieldChange(newValue.value, key);
    },
    [handleFieldChange]
  );

  const handleChangeMultiDropdown = useCallback(
    (newValues: IOption[], key: FormFieldKey) => {
      handleFieldChange(
        newValues.map((val) => val.value),
        key
      );
    },
    [handleFieldChange]
  );

  const handleDropdownBlur = useCallback(
    (key: FormFieldKey) => handleFieldBlur(key),
    [handleFieldBlur]
  );

  const isSmartSet = initialValue.smartSetType !== SmartSetType.None;

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box
        gap={[pxToRem(32), null, pxToRem(40)]}
        display="flex"
        flexDirection="column"
      >
        <FormControl
          isInvalid={!!formik.errors.title && formik.touched.title}
          isDisabled={loading}
          isRequired
          variant="adminFormControl"
        >
          <FormLabel>{t("practiceSetDetailForm.labelTitle")}</FormLabel>
          <Input
            name="title"
            value={formik.values.title}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            placeholder={t("practiceSetDetailForm.placeholderTitle")}
          />
          <FormErrorMessage>{formik.errors.title}</FormErrorMessage>
        </FormControl>
        <FormControl
          isInvalid={!!formik.errors.description && formik.touched.description}
          isDisabled={loading}
        >
          <FormLabel>{t("practiceSetDetailForm.labelDescription")}</FormLabel>
          <Textarea
            name="description"
            value={formik.values.description}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            placeholder={t("practiceSetDetailForm.placeholderDescription")}
            rows={4}
          />
          <FormErrorMessage>{formik.errors.description}</FormErrorMessage>
        </FormControl>
        <FormControl
          isInvalid={!!formik.errors.folder && formik.touched.folder}
          isDisabled={loading}
        >
          <FormLabel id="practiceSetDetailForm.folderLabel">
            {t("practiceSetDetailForm.labelFolder")}
          </FormLabel>
          <Dropdown
            aria-labelled-by="practiceSetDetailForm.folderLabel"
            id="practiceSetDetailForm.labelFolder"
            options={folders}
            handleChange={(v) =>
              handleChangeSingleDropdown(v as IOption, "folder")
            }
            handleBlur={() => handleDropdownBlur("folder")}
            value={folders.filter((f) => f.value === formik.values.folder)}
          />
          <FormErrorMessage>{formik.errors.folder}</FormErrorMessage>
        </FormControl>
        <FormControl
          isInvalid={!!formik.errors.subjects && formik.touched.subjects}
          isDisabled={loading}
        >
          <FormLabel id="practiceSetDetailForm.subjectLabel">
            {t("practiceSetDetailForm.labelSubject")}
          </FormLabel>
          <Dropdown
            aria-labelled-by="practiceSetDetailForm.subjectLabel"
            id="practiceSetDetailForm.labelSubject"
            isMulti
            options={subjects}
            handleChange={(v) =>
              handleChangeMultiDropdown(v as IOption[], "subjects")
            }
            handleBlur={() => handleDropdownBlur("subjects")}
            value={subjects.filter((f) =>
              formik.values.subjects.includes(f.value.toString())
            )}
          />
          <FormErrorMessage>{formik.errors.subjects}</FormErrorMessage>
        </FormControl>
        <FormControl
          isInvalid={!!formik.errors.gradeLevels && formik.touched.gradeLevels}
          isDisabled={loading}
        >
          <FormLabel id="practiceSetDetailForm.gradeLevelLabel">
            {t("practiceSetDetailForm.labelGradeLevel")}
          </FormLabel>
          <Dropdown
            aria-labelled-by="practiceSetDetailForm.gradeLevelLabel"
            id="practiceSetDetailForm.labelGradeLevel"
            isMulti
            options={gradeLevels}
            handleChange={(v) =>
              handleChangeMultiDropdown(v as IOption[], "gradeLevels")
            }
            handleBlur={() => handleDropdownBlur("gradeLevels")}
            value={gradeLevels.filter((f) =>
              formik.values.gradeLevels.includes(f.value.toString())
            )}
          />
          <FormErrorMessage>{formik.errors.gradeLevels}</FormErrorMessage>
        </FormControl>
        <FormControl
          isInvalid={!!formik.errors.language && formik.touched.language}
          isDisabled={loading}
          variant="adminFormControl"
        >
          <FormLabel id="practiceSetDetailForm.languageLabel">
            {t("practiceSetDetailForm.labelLanguage")}
          </FormLabel>
          <Dropdown
            aria-labelled-by="practiceSetDetailForm.languageLabel"
            id="practiceSetDetailForm.labelLanguage"
            options={languages}
            handleChange={(v) =>
              handleChangeSingleDropdown(v as IOption, "language")
            }
            handleBlur={() => handleDropdownBlur("language")}
            value={languages.filter((f) => f.value === formik.values.language)}
          />
          <FormErrorMessage>{formik.errors.language}</FormErrorMessage>
        </FormControl>
        <FormControl
          isInvalid={
            !!formik.errors.availability && formik.touched.availability
          }
          isDisabled={loading || !allowPublish}
          variant="adminFormControl"
        >
          <FormLabel id="practiceSetDetailForm.sharingSettingsLabel">
            <Box display="flex" alignItems="center" gap={pxToRem(8)}>
              {t("practiceSetDetailForm.labelSharingSettings")}
              <IconTooltip placement="top-start">
                {isSmartSet
                  ? t(
                      "practiceSetDetailForm.tooltipSharingSettingsPublishNotAllowedSmartSet"
                    )
                  : allowPublish
                  ? t("practiceSetDetailForm.tooltipSharingSettings")
                  : t(
                      "practiceSetDetailForm.tooltipSharingSettingsPublishNotAllowed"
                    )}
              </IconTooltip>
            </Box>
          </FormLabel>
          <Dropdown
            aria-labelled-by="practiceSetDetailForm.sharingSettingsLabel"
            id="practiceSetDetailForm.labelSharingSettings"
            handleChange={(v) => {
              if (isSmartSet) return;
              handleChangeSingleDropdown(v as IOption, "availability");
            }}
            isDisabled={isSmartSet}
            handleBlur={() => handleDropdownBlur("availability")}
            options={publicOptions}
            value={publicOptions.filter(
              (f) => f.value === formik.values.availability
            )}
          />
          <FormErrorMessage>{formik.errors.availability}</FormErrorMessage>
        </FormControl>

        {showCncFields && (
          <>
            <Divider color="primary.tan" />
            <FormControl
              isInvalid={!!formik.errors.cncCode && formik.touched.cncCode}
              isDisabled={loading}
              variant="adminFormControl"
            >
              <FormLabel>{t("practiceSetDetailForm.labelCncCode")}</FormLabel>
              <Input
                name="cncCode"
                value={formik.values.cncCode}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              <FormErrorMessage>{formik.errors.cncCode}</FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={
                !!formik.errors.cncFamilyCode && formik.touched.cncFamilyCode
              }
              isDisabled={loading}
              variant="adminFormControl"
            >
              <FormLabel>
                {t("practiceSetDetailForm.labelCncFamilyCode")}
              </FormLabel>
              <Input
                name="cncFamilyCode"
                value={formik.values.cncFamilyCode}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              <FormErrorMessage>{formik.errors.cncFamilyCode}</FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={
                !!formik.errors.isCertified && formik.touched.isCertified
              }
              isDisabled={loading}
              variant="adminFormControl"
            >
              <FormLabel>
                {t("practiceSetDetailForm.labelIsCertified")}
              </FormLabel>
              <Checkbox
                name="isCertified"
                isChecked={formik.values.isCertified}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                {t("practiceSetDetailForm.checkboxIsCertified")}
              </Checkbox>
              <FormErrorMessage>{formik.errors.isCertified}</FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={!!formik.errors.isPremium && formik.touched.isPremium}
              isDisabled={loading}
              variant="adminFormControl"
            >
              <FormLabel>{t("practiceSetDetailForm.labelIsPremium")}</FormLabel>
              <Checkbox
                name="isPremium"
                isChecked={formik.values.isPremium}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                {t("practiceSetDetailForm.checkboxIsPremium")}
              </Checkbox>
              <FormErrorMessage>{formik.errors.isPremium}</FormErrorMessage>
            </FormControl>
          </>
        )}

        <FormControl>
          <Button
            disabled={!formik.isValid || loading}
            variant="adminButtonFilled"
            size="lg"
            h={pxToRem(56)}
            width={["full", null, "auto"]}
            type="submit"
            isLoading={loading}
          >
            {isEdit
              ? t("practiceSetDetailForm.buttonEditSet")
              : t("practiceSetDetailForm.buttonCreateSet")}
          </Button>
        </FormControl>
      </Box>
    </form>
  );
};
