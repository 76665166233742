import { Flex } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";

import { Heading } from "adminComponents/atoms/Heading";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils";

import PracticeVideoMP4 from "../resource/practice-video.mp4";

export const TeacherTourStep2: React.FC = () => {
  const { t } = useTranslation("admin");

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      gap={pxToRem(32)}
    >
      <ReactPlayer
        url={PracticeVideoMP4}
        controls={false}
        loop
        playing
        width="70%"
        height="70%"
      />
      <Heading as="h1" variant="adminH2">
        {t("teacherTour.step2.heading")}
      </Heading>
      <Text variant="adminP1" maxW="68%">
        {t("teacherTour.step2.description")}
      </Text>
    </Flex>
  );
};
