import { Badge } from "@chakra-ui/react";
import React from "react";

import {
  IconButton,
  IProps as IconButtonProps,
} from "adminComponents/atoms/IconButton";
import { Text } from "adminComponents/atoms/Text";

export interface IProps extends Omit<IconButtonProps, "icon" | "shape"> {
  assignmentCount?: number;
  disabled?: boolean;
}

export const AssignmentsIcon: React.FC<IProps> = ({
  ariaLabel,
  disabled,
  onClick,
  assignmentCount,
}) => {
  return (
    <IconButton
      ariaLabel={ariaLabel}
      colorScheme="primary.white"
      colorSchemeHover="primary.golden-hover"
      icon="list"
      shape="circle"
      variant="adminNavIconButton"
      onClick={onClick}
      disabled={disabled}
    >
      {!!assignmentCount && (
        <Badge variant="adminIconButton">
          <Text variant="adminP1">{assignmentCount}</Text>
        </Badge>
      )}
    </IconButton>
  );
};
