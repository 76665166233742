import { Box, useToken } from "@chakra-ui/react";
import React, { useMemo } from "react";

import { pxToRem } from "adminComponents/utils/pxToRem";

import { LocationInfo } from "..";
import { ISeasonMapLocationProps } from "./SeasonMapLocation";

export interface ISeasonMapZoneProps {
  offset: {
    top: number;
    left: number;
  };
  width: number;
  height: number;
  pathSVG: React.ReactElement<SVGElement>;
  pathColor: string;
  isLocked: boolean;
  // set by parent season map component
  scale?: number;
  children:
    | Array<React.ReactElement<ISeasonMapLocationProps>>
    | React.ReactElement<ISeasonMapLocationProps>;
  handleLocationFocus?: (
    location: LocationInfo,
    isKeyboardEvent?: boolean
  ) => void;
  handleLocationSelect?: (locationId: LocationInfo) => void;
}

const SeasonMapZone: React.FC<ISeasonMapZoneProps> = ({
  offset,
  width,
  height,
  pathSVG,
  pathColor,
  isLocked,
  children,
  scale = 1,
  handleLocationFocus,
  handleLocationSelect,
}) => {
  const widthRem = pxToRem(width * scale);
  const heightRem = pxToRem(height * scale);
  const leftRem = pxToRem(offset.left * scale);
  const topRem = pxToRem(offset.top * scale);
  const lockedGray = useToken("colors", "map.locked-dark-gray");

  // pass isLocked and path color props to locations
  const childrenWithZoneProps = useMemo(() => {
    return React.Children.map<
      React.ReactElement<ISeasonMapLocationProps>,
      React.ReactElement<ISeasonMapLocationProps>
    >(children, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {
          isLocked,
          pathColor,
          // set location offsets relative to zone
          relativeOffset: {
            top: child.props.offset.top - offset.top,
            left: child.props.offset.left - offset.left,
          },
          scale,
          handleLocationFocus,
          handleLocationSelect,
        });
      }
      return child;
    });
  }, [
    children,
    isLocked,
    pathColor,
    offset,
    scale,
    handleLocationFocus,
    handleLocationSelect,
  ]);

  const extendedSVG = useMemo(() => {
    return React.cloneElement(pathSVG, {
      style: {
        ...pathSVG.props.style,
        width: widthRem,
        height: heightRem,
      },
    });
  }, [pathSVG, widthRem, heightRem]);

  const pathStyle = useMemo(() => {
    const s = (isLocked ? lockedGray : pathColor) + " !important";

    return {
      line: {
        stroke: s,
      },
      path: {
        stroke: s,
      },
    };
  }, [isLocked, pathColor, lockedGray]);

  return (
    <Box
      position="absolute"
      top={topRem}
      left={leftRem}
      width={widthRem}
      height={heightRem}
    >
      <Box
        sx={pathStyle}
        position="absolute"
        top="0"
        left="0"
        w="full"
        height="full"
      >
        {extendedSVG}
      </Box>
      {childrenWithZoneProps}
    </Box>
  );
};

export { SeasonMapZone };
