import { colors } from "./colors";

/**
  Custom admin style overrides.

  Note that by prefixing the component names with 'Admin', it makes it safe
  to use `baseStyle` without worrying about accidentally overriding styles
  from components with the same name in other parts of the codebase.
 */
import { AdminAccordion } from "./components/Accordion";
import { AdminAddStudentsViaEmailsModal } from "./components/AddStudentsViaEmailsModal";
import { AdminAddStudentsViaLinkModal } from "./components/AddStudentsViaLinkModal";
import { AdminBlankStatCard } from "./components/AdminBlankStatCard";
import { AdminStatCard } from "./components/AdminStatCard";
import { AdminAssignmentCard } from "./components/AssignmentCard";
import { AdminAvatar } from "./components/Avatar";
import { AdminAvatarEditingCard } from "./components/AvatarEditingCard";
import { AdminAvatarPhotoBackgroundCard } from "./components/AvatarPhotoBackgroundCard";
import { AdminAvatarPreview } from "./components/AvatarPreview";
import { AdminAvatarStreak } from "./components/AvatarStreak";
import { AdminAvatarTypeSelector } from "./components/AvatarTypeSelector";
import { AdminAverageStudentAccuracy } from "./components/AverageStudentAccuracy";

/**
  Chakra Component Variants

  In cases where we use base Chakra components, it's not easily practical to
  prefix the component name with 'Admin'. Therefore, `baseStyle` should
  not be used in these theme files in order to avoid conflicts with other
  portions of the app.

  Instead, all theming should be handled in variant definitions prefixed
  with `admin`.
 */
import { Badge } from "./components/Badge";
import { AdminBanner } from "./components/Banner";
import { AdminBreadcrumb } from "./components/Breadcrumb";
import { AdminButton } from "./components/Button";
import { AdminButtonGroup } from "./components/ButtonGroup";
import { AdminCalendarDate } from "./components/CalendarDate";
import { AdminCard } from "./components/Card";
import { AdminCarousel } from "./components/Carousel";
import { AdminCertifiedPracticeSetBanner } from "./components/CertifiedPracticeSetBanner";
import { AdminCheckbox } from "./components/Checkbox";
import { AdminCircleNumber } from "./components/CircleNumber";
import { AdminClampedHeading } from "./components/ClampedHeading";
import { AdminClassroomCard } from "./components/ClassroomCard";
import { AdminColorButton } from "./components/ColorButton";
import { Container } from "./components/Container";
import { AdminCoverImageChoice } from "./components/CoverImageChoice";
import { AdminCoverImageColorSchemeChoice } from "./components/CoverImageColorSchemeChoice";
import { AdminCoverImageIcon } from "./components/CoverImageIcon";
import { Drawer } from "./components/Drawer";
import { AdminEditAvatarModal } from "./components/EditAvatarModal";
import { AdminEditAvatarOverlayLayout } from "./components/EditAvatarOverlayLayout";
import { AdminEditAvatarTabs } from "./components/EditAvatarTabs";
import { AdminEmptyCard } from "./components/EmptyCard";
import { AdminFilterPracticeSet } from "./components/FilterPracticeSet";
import { AdminFlyout } from "./components/Flyout";
import { Form } from "./components/Form";
import { AdminFormErrorMessage } from "./components/FormErrorMessage";
import { AdminFormLabel } from "./components/FormLabel";
import { AdminFullStudentAvatar } from "./components/FullStudentAvatar";
import { AdminGenerateItemForm } from "./components/GenerateItemForm";
import { AdminGoalsProgressBar } from "./components/GoalsProgressBar";
import { AdminGreatWorkCTA } from "./components/GreatWorkCTA";
import { AdminHeading } from "./components/Heading";
import { AdminHeroCard } from "./components/HeroCard";
import { AdminIcon } from "./components/Icon";
import { AdminIconButton } from "./components/IconButton";
import { AdminIconCTAButton } from "./components/IconCTAButton";
import { Input } from "./components/Input";
import { AdminLevelProgressBar } from "./components/LevelProgressBar";
import { AdminLibraryPracticeSetCard } from "./components/LibraryPracticeSetCard";
import { AdminLibraryPracticeSetFolder } from "./components/LibraryPracticeSetFolder";
import { Link } from "./components/Link";
import { AdminLiveClassroomBanner } from "./components/LiveClassroomBanner";
import { AdminLogInModule } from "./components/LogInModule";
import { Menu } from "./components/Menu";
import { Modal } from "./components/Modal";
import { AdminMultiStepModal } from "./components/MultiStepModal";
import { AdminMyStuffCard } from "./components/MyStuffCard";
import { AdminNavigationHeader } from "./components/NavigationHeader";
import { AdminNotFoundModule } from "./components/NotFoundModule";
import { AdminPagination } from "./components/Pagination";
import { AdminPieChartWithLabel } from "./components/PieChartWithLabel";
import { Popover } from "./components/Popover";
import { AdminPopoverTrigger } from "./components/PopoverTrigger";
import { AdminPracticeSetCard } from "./components/PracticeSetCard";
import { AdminPracticeSetInformationCardBody } from "./components/PracticeSetInformationCardBody";
import { AdminPracticeSetInformationCardPublicBody } from "./components/PracticeSetInformationCardPublicBody";
import { AdminPracticeSetQuestionListControlsFixedFooter } from "./components/PracticeSetQuestionListControlsFixedFooter";
import { AdminPremiumPracticeSetBanner } from "./components/PremiumPracticeSetBanner";
import { AdminProgress } from "./components/Progress";
import { AdminQuestionCard } from "./components/QuestionCard";
import { Radio } from "./components/Radio";
import { AdminRarityScale } from "./components/RarityScale";
import { AdminRemixItem } from "./components/RemixItem";
import { AdminRichTextEditor } from "./components/RichTextEditor";
import { AdminSeasonChallengeMapCard } from "./components/SeasonChallengeMapCard";
import { AdminSelectStandardsModal } from "./components/SelectStandardsModal";
import { AdminSelectStudentModal } from "./components/SelectStudentModal";
import { AdminSessionGroupsModal } from "./components/SessionGroupsModal";
import { AdminShareAvatarOverlayLayout } from "./components/ShareAvatarOverlayLayout";
import { Skeleton } from "./components/Skeleton";
import { AdminSliderInput } from "./components/SliderInput";
import { AdminSortableTable } from "./components/SortableTable";
import { AdminStudentAssignmentCard } from "./components/StudentAssignmentCard";
import { AdminStudentBanner } from "./components/StudentBanner";
import { AdminStudentCompletedAssignment } from "./components/StudentCompletedAssignment";
import { AdminStudentCompletedAssignmentsFlyout } from "./components/StudentCompletedAssignmentsFlyout";
import { AdminStudentNavigationHeader } from "./components/StudentNavigationHeader";
import { AdminStudentOnboardingModal } from "./components/StudentOnboardingModal";
import { AdminStudentPracticeSetCard } from "./components/StudentPracticeSetCard";
import { AdminStudentProfileMenu } from "./components/StudentProfileMenu";
import { AdminStudentTemplateWithTwoColumns } from "./components/StudentTemplateWithTwoColumns";
import { AdminSummarizedSubjectTags } from "./components/SummarizedSubjectTags";
import { Switch } from "./components/Switch";
import { Table } from "./components/Table";
import { AdminTabs } from "./components/Tabs";
import { Tag } from "./components/Tag";
import { AdminTeacherAccountInformationForm } from "./components/TeacherAccountInformationForm";
import { AdminTeacherAccountMenu } from "./components/TeacherAccountMenu";
import { AdminTeacherAccountScreen } from "./components/TeacherAccountScreen";
import { AdminTeacherAccountSetupForm } from "./components/TeacherAccountSetupForm";
import { AdminTeacherAgreementsScreen } from "./components/TeacherAgreementsScreen";
import { AdminTeacherCard } from "./components/TeacherCard";
import { AdminTeacherHomepage } from "./components/TeacherHomepage";
import { AdminTeacherLinkedAccountCard } from "./components/TeacherLinkedAccountCard";
import { AdminTeacherProfileCardBody } from "./components/TeacherProfileCardBody";
import { AdminTemplateWithCenteredContent } from "./components/TemplateWithCenteredContent";
import { AdminTemplateWithCenteredHeroOneColumn } from "./components/TemplateWithCenteredHeroOneColumn";
import { AdminTemplateWithHeaderAndTwoColumns } from "./components/TemplateWithHeaderAndTwoColumns";
import { AdminTemplateWithVerticalCenteredContent } from "./components/TemplateWithVerticalCenteredContent";
import { AdminText } from "./components/Text";
import { AdminTextLink } from "./components/TextLink";
import { Textarea } from "./components/Textarea";
import { AdminToast } from "./components/Toast";
import { AdminToggleButton } from "./components/ToggleButton";
import { Tooltip } from "./components/Tooltip";
import { AdminTopTeacherCard } from "./components/TopTeacherCard";
import { AdminUserCard } from "./components/UserCard";
import { AdminVideoPlayer } from "./components/VideoPlayer";
import { sizes } from "./sizes";
import { space } from "./spacing";
import { fontWeights, fonts, textStyles } from "./textStyles";

const adminTheme = {
  colors,
  space,
  sizes,
  textStyles,
  fontWeights,
  fonts,
  components: {
    // Custom admin style overrides. See comment with imports, above.
    AdminAccordion,
    AdminAddStudentsViaEmailsModal,
    AdminAddStudentsViaLinkModal,
    AdminAssignmentCard,
    AdminAvatar,
    AdminAvatarEditingCard,
    AdminAvatarPreview,
    AdminAvatarStreak,
    AdminAvatarTypeSelector,
    AdminAverageStudentAccuracy,
    AdminBanner,
    AdminBlankStatCard,
    AdminBreadcrumb,
    AdminButton,
    AdminButtonGroup,
    AdminCalendarDate,
    AdminCard,
    AdminCarousel,
    AdminCheckbox,
    AdminCircleNumber,
    AdminClassroomCard,
    AdminCertifiedPracticeSetBanner,
    AdminPremiumPracticeSetBanner,
    AdminClampedHeading,
    AdminColorButton,
    AdminCoverImageChoice,
    AdminCoverImageColorSchemeChoice,
    AdminCoverImageIcon,
    AdminEditAvatarModal,
    AdminEditAvatarOverlayLayout,
    AdminEditAvatarTabs,
    AdminEmptyCard,
    AdminFilterPracticeSet,
    AdminFlyout,
    AdminFormErrorMessage,
    AdminFormLabel,
    AdminFullStudentAvatar,
    AdminGenerateItemForm,
    AdminGoalsProgressBar,
    AdminGreatWorkCTA,
    AdminHeading,
    AdminHeroCard,
    AdminIcon,
    AdminIconButton,
    AdminIconCTAButton,
    AdminLevelProgressBar,
    AdminMyStuffCard,
    AdminLibraryPracticeSetCard,
    AdminLibraryPracticeSetFolder,
    AdminLogInModule,
    AdminNotFoundModule,
    AdminMultiStepModal,
    AdminNavigationHeader,
    AdminPagination,
    AdminAvatarPhotoBackgroundCard,
    AdminPieChartWithLabel,
    AdminPopoverTrigger,
    AdminPracticeSetCard,
    AdminPracticeSetInformationCardBody,
    AdminPracticeSetInformationCardPublicBody,
    AdminPracticeSetQuestionListControlsFixedFooter,
    AdminProgress,
    AdminQuestionCard,
    AdminRarityScale,
    AdminRemixItem,
    AdminRichTextEditor,
    AdminSeasonChallengeMapCard,
    AdminSelectStandardsModal,
    AdminSelectStudentModal,
    AdminSessionGroupsModal,
    AdminShareAvatarOverlayLayout,
    AdminSliderInput,
    AdminSortableTable,
    AdminStudentAssignmentCard,
    AdminStudentBanner,
    AdminUserCard,
    AdminTeacherCard,
    AdminStudentCompletedAssignment,
    AdminStudentCompletedAssignmentsFlyout,
    AdminLiveClassroomBanner,
    AdminStatCard,
    AdminStudentNavigationHeader,
    AdminStudentOnboardingModal,
    AdminStudentPracticeSetCard,
    AdminStudentProfileMenu,
    AdminStudentTemplateWithTwoColumns,
    AdminSummarizedSubjectTags,
    AdminTabs,
    AdminTeacherAccountInformationForm,
    AdminTeacherAccountMenu,
    AdminTeacherAccountScreen,
    AdminTeacherAccountSetupForm,
    AdminTeacherAgreementsScreen,
    AdminTeacherHomepage,
    AdminTeacherLinkedAccountCard,
    AdminTeacherProfileCardBody,
    AdminTemplateWithCenteredContent,
    AdminTemplateWithCenteredHeroOneColumn,
    AdminTemplateWithHeaderAndTwoColumns,
    AdminTemplateWithVerticalCenteredContent,
    AdminTopTeacherCard,
    AdminText,
    AdminTextLink,
    AdminToast,
    AdminToggleButton,
    AdminVideoPlayer,

    // Chakra component variants. See comment with imports, above.
    Badge,
    Container,
    Drawer,
    Form,
    Input,
    Link,
    Menu,
    Modal,
    Popover,
    Radio,
    Skeleton,
    Switch,
    Table,
    Tag,
    Textarea,
    Tooltip,
  },
};

export const ColorOptions = [
  "primary.warm-black",
  "primary.dark-gray",
  "primary.medium-gray",
  "primary.light-gray",
  "primary.white",
  "primary.warm-white",
  "primary.tan",
  "primary.golden-hover",
  "primary.golden",
  "primary.golden-medium",
  "primary.golden-light",
  "utility.link",
  "utility.link-hover",
  "utility.success",
  "utility.error",
  "utility.error-hover",
  "utility.focus",
  "utility.badge-blue",
  "utility.badge-purple",
  "utility.badge-green",
  "utility.badge-red",
  "utility.badge-grey",
  "utility.question-green",
  "utility.question-red",
  "utility.orange",
  "utility.light-orange",
];

export { IconOptions } from "adminComponents/atoms/Icon";
export { adminTheme };
