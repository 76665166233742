import {
  Box,
  Flex,
  Image,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Spacer,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Banner } from "adminComponents/atoms/Banner";
import { Button } from "adminComponents/atoms/Button";
import { Heading } from "adminComponents/atoms/Heading";
import { Icon } from "adminComponents/atoms/Icon";
import { IconButton } from "adminComponents/atoms/IconButton";
import { Tag } from "adminComponents/atoms/Tag";
import { Text } from "adminComponents/atoms/Text";
import { CompactLibraryPracticeSetCard } from "adminComponents/molecules/CompactLibraryPracticeSetCard";
import { InstantSetBanner } from "adminComponents/molecules/InstantSetBanner";
import {
  INavigationHeaderProps,
  NavigationHeader,
} from "adminComponents/molecules/NavigationHeader";
import { SearchInput } from "adminComponents/molecules/SearchInput";
import { StandardsAlignedTitle } from "adminComponents/molecules/StandardsAlignedTitle";
import { LibraryParentGradePickerGrid } from "adminComponents/organisms/LibraryGradePickerGrid";
import { LibraryParentSubjectPickerGrid } from "adminComponents/organisms/LibraryParentSubjectPickerGrid";
import { TemplateWithCenteredHeroOneColumn } from "adminComponents/templates/TemplateWithCenteredHeroOneColumn";
import { pxToRem } from "adminComponents/utils";
import { IHandleSetQueryArgs, ISearchQuery } from "lib/hooks/useLibraryFilter";
import { useAuth } from "links/lib/features/auth";
import {
  IPracticeSet,
  ISearchSuggestion,
  ISubjectCount,
  IUser,
} from "links/lib/types";
import { IGuestNavigationDataContext } from "screens/TeacherDashboard/contexts/GuestTeacherNavigationDataProvider";
import { INavigationData } from "screens/TeacherDashboard/contexts/TeacherNavigationDataProvider";
import { useShowOnboardingBanner } from "screens/TeacherDashboard/hooks/useShowOnboardingBanner";

import { RecommendedCreators } from "../TeacherHomepageImproved/components/RecommendedCreators";
import { useOnboardingModals } from "./hooks/useOnboardingModals";
import SearchConfettiSVG from "./resource/search-confetti.svg";

export enum OnboardingStep {
  Invalid = 0,
  CompleteProfile = 1,
  InviteStudents = 2,
  CompleteAvatar = 3,
}

export const dropdownWidth = 140;

export interface ITeacherHomepageLibraryProps {
  navigationData: IGuestNavigationDataContext | INavigationData;
  subjectCounts: ISubjectCount[];
  popularPracticeSets?: IPracticeSet[];
  recommendedPracticeSets?: IPracticeSet[];
  mostCopiedCreators?: IUser[];
  isLoading?: boolean;
  isSearching?: boolean;
  query: ISearchQuery;
  suggestions?: string[];
  searchFilterSuggestions?: ISearchSuggestion[];
  isGuest: boolean;
  handleSetSearchTerm: (searchTerm: string) => void;
  handleClearSearchInput: () => void;
  handleSearch?: (search: string) => void;
  handleSuggest?: (searchTerm: string) => void;
  handlePracticeSetPreview?: (practiceSet: IPracticeSet) => void;
  handleSelectSubjects: (subjectIds: string[]) => void;
  handleSelectGrades: (gradeLevelIds: string[]) => void;
  handleCreatePracticeSet?: () => void;
  handleGenerateSet: () => void;
  handleOpenFilterFlyout: () => void;
  handleSignUp?: () => void;
  handleUpdateQuery?: (args: IHandleSetQueryArgs) => void;
}

export const TeacherHomepageLibrary: React.FC<ITeacherHomepageLibraryProps> = ({
  navigationData,
  subjectCounts,
  popularPracticeSets = [],
  recommendedPracticeSets = [],
  mostCopiedCreators = [],
  isLoading,
  isSearching,
  query,
  suggestions,
  searchFilterSuggestions,
  isGuest,
  handleSetSearchTerm,
  handleSearch,
  handleSuggest,
  handleUpdateQuery,
  handlePracticeSetPreview,
  handleClearSearchInput,
  handleSelectSubjects,
  handleSelectGrades,
  handleCreatePracticeSet,
  handleGenerateSet,
  handleOpenFilterFlyout,
  handleSignUp,
}) => {
  const {
    authSharingSettings,
    authRequiredAgreements,
    hasNoPremiumAccess,
    authUser,
    isFeatureEnabled,
  } = useAuth();
  const { t } = useTranslation("admin", { useSuspense: false });
  const [recommendedPracticeSetsToShow, setRecommendedPracticeSetsToShow] =
    useState(6);
  const showRecommendedPracticeSets = useMemo(() => {
    return recommendedPracticeSets.slice(0, recommendedPracticeSetsToShow);
  }, [recommendedPracticeSets, recommendedPracticeSetsToShow]);

  const handleViewMoreRecommendedPracticeSets = useCallback(() => {
    setRecommendedPracticeSetsToShow(showRecommendedPracticeSets.length + 6);
  }, [showRecommendedPracticeSets.length]);

  const [popularPracticeSetsToShow, setPopularPracticeSetsToShow] = useState(6);
  const showPopularPracticeSets = useMemo(() => {
    return popularPracticeSets.slice(0, popularPracticeSetsToShow);
  }, [popularPracticeSets, popularPracticeSetsToShow]);

  const handleViewMorePopularPracticeSets = useCallback(() => {
    setPopularPracticeSetsToShow(showPopularPracticeSets.length + 6);
  }, [showPopularPracticeSets.length]);

  const useShortOnboardingButtonText = useBreakpointValue({
    base: true,
    lg: false,
  });

  const _handleSearch = (search: string) => {
    handleSetSearchTerm(search);
    handleSearch?.(search);
  };

  // Fan out parent subject selection into parent+children
  const handleSelectParentSubject = (parentSubjectId: string) => {
    const childSubjectIds = navigationData.subjects
      .filter((s) => s.parent_id === parentSubjectId)
      .map((s) => s.id);
    handleSelectSubjects([parentSubjectId, ...childSubjectIds]);
  };

  const loggedInTeacherNavigationData = !isGuest
    ? (navigationData as INavigationHeaderProps)
    : undefined;

  const OnboardingStepComponents = (onboardingStep: OnboardingStep) => {
    switch (onboardingStep) {
      case OnboardingStep.CompleteProfile:
        return (
          <Flex
            alignItems="center"
            w="full"
            flexDirection={{ base: "column", md: "row" }}
            gap={pxToRem(10)}
          >
            <Text variant="adminP1">
              {t("teacherHomepageLibrary.onboardingBannerCompleteProfile")}
            </Text>
            <Spacer />
            <Button variant="adminButtonOutlined" onClick={onOpenProfileModal}>
              {useShortOnboardingButtonText
                ? t(
                    "teacherHomepageLibrary.onboardingBannerCompleteProfileButtonShort"
                  )
                : t(
                    "teacherHomepageLibrary.onboardingBannerCompleteProfileButton"
                  )}
            </Button>
          </Flex>
        );
      case OnboardingStep.InviteStudents:
        return (
          <Flex
            alignItems="center"
            w="full"
            flexDirection={{ base: "column", md: "row" }}
            gap={pxToRem(10)}
          >
            <Text variant="adminP1">
              {t("teacherHomepageLibrary.onboardingBannerInviteStudents")}
            </Text>
            <Spacer />
            <Button
              variant="adminButtonOutlined"
              onClick={onOpenClassroomModal}
            >
              {t("teacherHomepageLibrary.onboardingBannerInviteStudentsButton")}
            </Button>
          </Flex>
        );
      case OnboardingStep.CompleteAvatar:
        return (
          <Flex
            alignItems="center"
            w="full"
            flexDirection={{ base: "column", md: "row" }}
            gap={pxToRem(10)}
          >
            <Text variant="adminP1">
              {t("teacherHomepageLibrary.onboardingBannerEditAvatar")}
            </Text>
            <Spacer />
            <Button
              variant="adminButtonOutlined"
              onClick={onOpenEditAvatarModal}
            >
              {t("teacherHomepageLibrary.onboardingBannerEditAvatarButton")}
            </Button>
          </Flex>
        );
    }
  };

  const onboardingStep = useMemo(() => {
    if (isGuest) return;

    const checklist = loggedInTeacherNavigationData?.teacherOnboardingChecklist;
    if (!checklist) return;
    if (!checklist.has_completed_profile) {
      return OnboardingStep.CompleteProfile;
    }
    if (!checklist.has_invited_students) {
      return OnboardingStep.InviteStudents;
    }
    if (!checklist.has_edited_avatar) {
      return OnboardingStep.CompleteAvatar;
    }
  }, [isGuest, loggedInTeacherNavigationData?.teacherOnboardingChecklist]);

  const {
    shouldHideOnboardingBanner,
    showCompletedOnboardingBanner,
    handleCloseOnboardingBanner,
    handleCloseCompletedOnboardingBanner,
  } = useShowOnboardingBanner({
    authUser: loggedInTeacherNavigationData?.user,
    onboardingStep,
  });

  const {
    onOpenProfileModal,
    profileModal,
    onOpenClassroomModal,
    classroomModal,
    onOpenEditAvatarModal,
    editAvatarModal,
  } = useOnboardingModals({
    authUser: loggedInTeacherNavigationData?.user,
    authSharingSettings: authSharingSettings,
    authRequiredAgreements: authRequiredAgreements,
    gradeLevels: navigationData.gradeLevels,
    parentSubjects: navigationData.parentSubjects,
  });

  return (
    <Box bgColor="primary.tan-light">
      <TemplateWithCenteredHeroOneColumn
        outerContainerStyle={{
          paddingBottom: 0,
        }}
        heroContainerStyle={{
          position: "relative",
          paddingBottom: pxToRem(26),
        }}
        isLoading={isLoading}
        nav={
          <NavigationHeader
            resourceLinks={loggedInTeacherNavigationData?.resourceLinks || []}
            accountLinks={loggedInTeacherNavigationData?.accountLinks || []}
            {...navigationData}
            handleCreatePracticeSet={
              isGuest ? handleGenerateSet : handleCreatePracticeSet
            }
            handleSelectGrades={handleSelectGrades}
            handleSelectSubjects={handleSelectSubjects}
            handleSignUp={handleSignUp}
            isGuest={isGuest}
          />
        }
        heroContent={
          <Flex
            w="full"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
            gap={pxToRem(30)}
          >
            <StandardsAlignedTitle />
            <Flex justifyContent="center" w="full">
              <Flex
                alignItems="center"
                w={{ base: "90%", md: "60%" }}
                position="relative"
                gap={pxToRem(13)}
                flexDirection={{ base: "column", md: "row" }}
              >
                <Image
                  position="absolute"
                  top={pxToRem(-94)}
                  left={pxToRem(-30)}
                  src={SearchConfettiSVG}
                  style={{
                    pointerEvents: "none",
                  }}
                />
                {isFeatureEnabled("playtime.enable_search_term_parsing") && (
                  <Popover trigger="hover" placement="top">
                    <PopoverContent
                      color="primary.white"
                      bg="primary.warm-black"
                      borderColor="transparent"
                      borderRadius="full"
                      paddingY={pxToRem(12)}
                      paddingX={pxToRem(24)}
                    >
                      <PopoverArrow bg="primary.warm-black" />
                      <Flex align="center" justify="center">
                        <Text color="primary.warm-white" textAlign="center">
                          {t("teacherHomepageLibrary.newSearch")}
                        </Text>
                      </Flex>
                    </PopoverContent>
                    <PopoverTrigger>
                      <Box w="fit-content" cursor="default" zIndex={1}>
                        <Tag
                          leftIcon="info_outlined"
                          colorScheme="utility.badge-blue"
                        >
                          {t("common.new")}
                        </Tag>
                      </Box>
                    </PopoverTrigger>
                  </Popover>
                )}
                <SearchInput
                  handleClear={handleClearSearchInput}
                  handleSearch={_handleSearch}
                  handleSuggest={handleSuggest}
                  initialSearch={query.term}
                  placeholder={t(
                    "teacherHomepageLibrary.searchInputPlaceholder"
                  )}
                  searching={isSearching}
                  suggestions={suggestions}
                  searchFilterSuggestions={searchFilterSuggestions}
                  handleUpdateQuery={handleUpdateQuery}
                  query={query}
                  hideSearchButton
                />
                <Button
                  w={pxToRem(250)}
                  variant="adminButtonWhite"
                  onClick={handleOpenFilterFlyout}
                  leftIcon={
                    <Icon
                      icon="slider"
                      color="primary.dark-gray"
                      cursor="pointer"
                    />
                  }
                >
                  {t("teacherHomepageLibrary.advancedSearch")}
                </Button>
              </Flex>
            </Flex>
          </Flex>
        }
      >
        <Flex flexDirection="column" w="full" h="full" gap={pxToRem(50)}>
          {(onboardingStep || showCompletedOnboardingBanner) &&
            !shouldHideOnboardingBanner && (
              <Box marginBottom={pxToRem(20)}>
                <Banner
                  backgroundColor={
                    showCompletedOnboardingBanner
                      ? "primary.light-gray"
                      : "primary.golden-light"
                  }
                >
                  <Flex
                    justifyContent="left"
                    alignItems="center"
                    gap={pxToRem(17)}
                  >
                    {showCompletedOnboardingBanner ? (
                      <IconButton
                        colorScheme="utility.question-green"
                        colorSchemeHover="utility.question-green"
                        cursor="default"
                        ariaLabel=""
                        icon="check"
                        shape="circle"
                      />
                    ) : (
                      <Icon icon="zap" />
                    )}
                    {onboardingStep && OnboardingStepComponents(onboardingStep)}
                    {showCompletedOnboardingBanner && (
                      <>
                        <Text variant="adminP1Bold">
                          {t(
                            "teacherHomepageLibrary.onboardingBannerCompletedOnboarding"
                          )}
                        </Text>
                        <Spacer />
                      </>
                    )}
                    <Icon
                      cursor="pointer"
                      icon="close"
                      onClick={
                        showCompletedOnboardingBanner
                          ? handleCloseCompletedOnboardingBanner
                          : handleCloseOnboardingBanner
                      }
                    />
                  </Flex>
                </Banner>
              </Box>
            )}
          {!isGuest && !!recommendedPracticeSets.length && (
            <Flex flexDirection="column" gap={pxToRem(30)} w="full">
              <Heading variant="adminH4" as="h2">
                {t("teacherHomepageLibrary.recommendedForYouHeading")}
              </Heading>
              <Flex gap={pxToRem(14)} wrap="wrap">
                {showRecommendedPracticeSets.map((practiceSet) => {
                  return (
                    <CompactLibraryPracticeSetCard
                      key={practiceSet.id}
                      practiceSet={practiceSet}
                      handleClick={() =>
                        handlePracticeSetPreview?.(practiceSet)
                      }
                    />
                  );
                })}
              </Flex>
              {recommendedPracticeSets.length >
                showRecommendedPracticeSets.length && (
                <Button
                  alignSelf="center"
                  onClick={handleViewMoreRecommendedPracticeSets}
                  rightIcon={
                    <Icon
                      color="currentColor"
                      height={pxToRem(7)}
                      icon="button_down_arrow"
                      ml={pxToRem(5)}
                      width={pxToRem(12)}
                    />
                  }
                  variant="adminTextButtonLarge"
                >
                  {t("common.viewMore")}
                </Button>
              )}
            </Flex>
          )}
          <Flex flexDirection="column" gap={pxToRem(30)} w="full">
            <Heading variant="adminH4" as="h2">
              {t("teacherHomepageLibrary.browseBySubjectHeading")}
            </Heading>
            <LibraryParentSubjectPickerGrid
              handleSelectSubject={handleSelectParentSubject}
              subjects={navigationData.parentSubjects}
              subjectCounts={subjectCounts}
              minChildWidth={pxToRem(250)}
            />
          </Flex>
          <Flex flexDirection="column" gap={pxToRem(30)} w="full">
            <Heading variant="adminH4" as="h2">
              {t("teacherHomepageLibrary.browseByGradeHeading")}
            </Heading>
            <LibraryParentGradePickerGrid
              handleSelectGradeLevel={(gradeLevelId: string) =>
                handleSelectGrades([gradeLevelId])
              }
              gradeLevels={navigationData.gradeLevels}
            />
          </Flex>
          {isGuest && popularPracticeSets.length && (
            <Flex flexDirection="column" gap={pxToRem(30)} w="full">
              <Heading variant="adminH4" as="h2">
                {t("teacherHomepageLibrary.popularPracticeSetsHeading")}
              </Heading>
              <Flex gap={pxToRem(14)} wrap="wrap">
                {showPopularPracticeSets.map((practiceSet) => {
                  return (
                    <CompactLibraryPracticeSetCard
                      key={practiceSet.id}
                      practiceSet={practiceSet}
                      handleClick={() =>
                        handlePracticeSetPreview?.(practiceSet)
                      }
                    />
                  );
                })}
              </Flex>
              {popularPracticeSets.length > showPopularPracticeSets.length && (
                <Button
                  alignSelf="center"
                  onClick={handleViewMorePopularPracticeSets}
                  rightIcon={
                    <Icon
                      color="currentColor"
                      height={pxToRem(7)}
                      icon="button_down_arrow"
                      ml={pxToRem(5)}
                      width={pxToRem(12)}
                    />
                  }
                  variant="adminTextButtonLarge"
                >
                  {t("common.viewMore")}
                </Button>
              )}
            </Flex>
          )}
          {!isGuest &&
            !!mostCopiedCreators.length &&
            loggedInTeacherNavigationData &&
            loggedInTeacherNavigationData.user && (
              <Flex flexDirection="column" gap={pxToRem(30)} w="full">
                <Heading variant="adminH4" as="h2">
                  {t(
                    "teacherHomepageLibrary.followPearPracticeTeachersHeading"
                  )}
                </Heading>
                <RecommendedCreators
                  hideText={true}
                  authUser={loggedInTeacherNavigationData?.user}
                  navigationData={loggedInTeacherNavigationData}
                  mostCopiedCreators={mostCopiedCreators}
                />
              </Flex>
            )}
        </Flex>
      </TemplateWithCenteredHeroOneColumn>
      <Flex mx="auto" maxW="container.xl" pb={pxToRem(20)}>
        <InstantSetBanner
          handleGenerateInstantSet={handleGenerateSet}
          enablePremiumTooltip={hasNoPremiumAccess && !authUser?.is_guest}
        />
      </Flex>
      {profileModal}
      {classroomModal}
      {editAvatarModal}
    </Box>
  );
};
