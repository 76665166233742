import { Flex, useMultiStyleConfig } from "@chakra-ui/react";
import React, { ReactNode, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { Card } from "adminComponents/atoms/Card";
import { AvatarSkeletonType } from "links/lib/types";

interface IProps {
  selected?: AvatarSkeletonType;
  standingAvatar: ReactNode;
  seatedAvatar: ReactNode;
  handleSelectAvatarType: (type: AvatarSkeletonType) => void;
}

export const AvatarTypeSelector: React.FC<IProps> = ({
  selected,
  standingAvatar,
  seatedAvatar,
  handleSelectAvatarType,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const { avatar, card, container } = useMultiStyleConfig(
    "AdminAvatarTypeSelector",
    {}
  );

  const renderAvatarType = useCallback(
    (avatarType: AvatarSkeletonType, node: ReactNode) => {
      const isSelected = selected === avatarType;

      return (
        <Card
          as="button"
          backgroundColor="primary.white"
          borderColor={isSelected ? "utility.link" : "primary.white"}
          onClick={() => {
            handleSelectAvatarType?.(avatarType);
          }}
          sx={card}
          variant="adminCardSelectable"
          isSelected={isSelected}
          noPadding
          aria-label={
            avatarType === AvatarSkeletonType.Standing
              ? t("avatarTypeSelector.selectAvatarTypeStanding")
              : t("avatarTypeSelector.selectAvatarTypeSeated")
          }
        >
          <Flex sx={avatar} w="full">
            {node}
          </Flex>
        </Card>
      );
    },
    [avatar, card, handleSelectAvatarType, selected, t]
  );

  return (
    <Flex sx={container}>
      {renderAvatarType(AvatarSkeletonType.Standing, standingAvatar)}
      {renderAvatarType(AvatarSkeletonType.Seated, seatedAvatar)}
    </Flex>
  );
};
