import { Box, Flex, VisuallyHidden } from "@chakra-ui/react";
import React from "react";

import { LoadingSpinner } from "adminComponents/atoms/LoadingSpinner";

const AppSpinner: React.FC = () => {
  return (
    <Box
      as="main"
      minH="100vh"
      role="main"
      aria-busy={true}
      bg="primary.warm-white"
    >
      <Flex minH="100vh" alignItems="center" justifyContent="center">
        <VisuallyHidden>
          {/* Screen Reader support. Must use english here as translations have not loaded yet */}
          <h1>Loading...</h1>
        </VisuallyHidden>
        <LoadingSpinner />
      </Flex>
    </Box>
  );
};

export default AppSpinner;
