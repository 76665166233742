import { useEffect, useRef, useState } from "react";

import { IAvatar, IAvatarItem } from "links/lib/types";
import { getAvatarItemPreviewDataURL } from "links/lib/util";

import { ISubSection } from "../config";

function getActiveColorsInSubSections(
  subSections: Array<ISubSection>,
  avatarItems: Array<IAvatarItem>
) {
  const activeSubSectionCategoryIds = subSections.map(
    (subSection) => subSection.categoryId
  );

  const activeColorsInSubSections = avatarItems
    .filter((item) =>
      activeSubSectionCategoryIds.includes(item.avatar_item_category_id)
    )
    .map((activeItem) => {
      return activeItem?.colors.find((cd) => cd.is_active);
    });

  return activeColorsInSubSections.map((cd) => cd?.hex_code).join(",");
}

export function useDataUrlsForSubSections(
  avatar: IAvatar,
  subSections: Array<ISubSection>
): {
  dataUrlsBySubSectionIndex: Array<Array<string>>;
  isSubSectionsLoading: boolean;
  setDataUrls: (dataUrls: Array<Array<string>>) => void;
} {
  const refAvatar = useRef<IAvatar>(avatar);
  refAvatar.current = avatar;
  const refActiveColorsInSubSections = useRef<string>("");

  const { hair_color_hex_code, skin_tone_hex_code } = avatar;
  const [isSubSectionsLoading, setIsSubSectionsLoading] =
    useState<boolean>(false);
  const [dataUrlsBySubSectionIndex, setDataUrls] = useState<Array<string[]>>(
    []
  );

  const colorsInSubSections = getActiveColorsInSubSections(
    subSections,
    avatar.items
  );

  useEffect(() => {
    const getDataUrls = async () => {
      setIsSubSectionsLoading(true);
      const subSectionPromises = subSections.map(
        ({ canDeequipCategory, categoryId }) => {
          const categoryItems = refAvatar.current.items
            .filter((item) => categoryId === item.avatar_item_category_id)
            .filter((item) => {
              // Default items with categories that permit deequip aren't visible
              return !canDeequipCategory || !item.is_category_default;
            });
          return categoryItems.map((categoryItem) =>
            getAvatarItemPreviewDataURL(categoryItem, {
              ...refAvatar.current,
              hair_color_hex_code,
              skin_tone_hex_code,
            })
          );
        }
      );

      const dataUrlsBySubSectionIndex = await Promise.all(
        subSectionPromises.map((item) => Promise.all(item))
      );
      setDataUrls(dataUrlsBySubSectionIndex);
      setIsSubSectionsLoading(false);
      refActiveColorsInSubSections.current = colorsInSubSections;
    };
    getDataUrls();
  }, [
    hair_color_hex_code,
    skin_tone_hex_code,
    subSections,
    colorsInSubSections,
  ]);

  return { dataUrlsBySubSectionIndex, isSubSectionsLoading, setDataUrls };
}
