import { Box, Image, Show, Spacer } from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { Redirect, useHistory, useLocation } from "react-router";
import { useMount, useWindowSize } from "react-use";

import { TemplateWithVerticalCenteredContent } from "adminComponents/templates/TemplateWithVerticalCenteredContent";
import { pxToRem } from "adminComponents/utils";
import { usePageTrack } from "lib/contexts/analytics";
import {
  AnalyticsPage,
  IUser,
  SessionWebSocketErrorCodes,
  WebSocketSession,
} from "links/lib/types";
import { JoinCode } from "sessionComponents/organisms/JoinCode";
import { SignInRequired } from "sessionComponents/organisms/SignInRequired";
import { HeaderWithLanguagePicker } from "sharedComponents/atoms/HeaderWithLanguagePicker";
import { useLanguageDropdown } from "sharedComponents/hooks/useLanguageDropdown";

import JoinCodeFooterSVG from "../screens/Session/shared/resource/join-code-footer.svg";

interface SessionJoinProps {
  authUser: IUser;
  disableUnloadWarning: () => void;
  error?: string;
  errorCode?: number;
  joinSession: (joinCode: string) => void;
  joinCode: string;
  session?: WebSocketSession;
}

export const SessionJoin: React.FC<SessionJoinProps> = ({
  authUser,
  disableUnloadWarning,
  error: wsError,
  errorCode: wsErrorCode,
  joinSession,
  joinCode: parentJoinCode,
  session,
}: SessionJoinProps) => {
  const history = useHistory();
  const location = useLocation() as {
    state?: { redirectToSeasonMap?: boolean; isIndividualSession?: boolean };
    search?: string;
  };
  const { height: windowHeight } = useWindowSize();
  let redirectToSeasonMap = location.state?.redirectToSeasonMap;
  let isIndividualSession = location.state?.isIndividualSession;
  const search = new URLSearchParams(location.search || "");
  if (search.get("redirectToSeasonMap") !== null) {
    redirectToSeasonMap = search.get("redirectToSeasonMap") === "true";
  }
  if (search.get("isIndividualSession") !== null) {
    isIndividualSession = search.get("isIndividualSession") === "true";
  }
  const [joinCode, setJoinCode] = useState(parentJoinCode || "");
  const [error, setError] = useState(wsError || "");
  const [errorCode, setErrorCode] = useState(wsErrorCode || 0);

  usePageTrack(AnalyticsPage.Session_Join);

  useMount(() => {
    if (parentJoinCode) joinSession(parentJoinCode);
    disableUnloadWarning();
  });

  const signInRequired = useMemo(() => {
    return (
      authUser.is_guest &&
      !!joinCode &&
      wsErrorCode ===
        SessionWebSocketErrorCodes.CodeClassroomGuestUserPermissionDenied
    );
  }, [authUser.is_guest, joinCode, wsErrorCode]);

  useEffect(() => {
    if (wsError !== undefined) setError(wsError);
    if (wsErrorCode !== undefined) setErrorCode(wsErrorCode);
  }, [wsError, wsErrorCode]);

  const langDropdown = useLanguageDropdown();

  const onJoinSession = (joinCode: string) => {
    setJoinCode(joinCode);
    joinSession(joinCode);
  };

  const onSignInRequiredGoBack = () => {
    setJoinCode("");
    setError("");
    setErrorCode(0);
    history.replace("/session/join");
  };
  const content = signInRequired ? (
    <SignInRequired joinCode={joinCode} goBack={onSignInRequiredGoBack} />
  ) : (
    <JoinCode
      onJoinSession={onJoinSession}
      joinCode={joinCode}
      error={
        errorCode !==
        SessionWebSocketErrorCodes.CodeClassroomGuestUserPermissionDenied
          ? error
          : undefined
      }
      isLoading={!!joinCode && !error}
    />
  );

  if (session) {
    return (
      <Redirect to={{ pathname: "/session", state: { redirectToSeasonMap } }} />
    );
  }

  return (
    <TemplateWithVerticalCenteredContent
      headerContent={
        isIndividualSession ? (
          <></>
        ) : (
          <HeaderWithLanguagePicker showSignOut={!authUser.is_guest} />
        )
      }
      bgColor="primary.tan-light"
    >
      <Spacer />
      {content}
      <Spacer />
      {windowHeight > 600 && !isIndividualSession && (
        <Image
          src={JoinCodeFooterSVG}
          w={{ base: pxToRem(330), md: pxToRem(600), xl: pxToRem(800) }}
        />
      )}
      <Show below="lg">
        <Box my={pxToRem(30)}>{langDropdown}</Box>
      </Show>
    </TemplateWithVerticalCenteredContent>
  );
};
