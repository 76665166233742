import { CSSObject, useMultiStyleConfig } from "@chakra-ui/react";
import React, { ReactElement, useEffect, useRef, useState } from "react";

import { Heading, IHeadingProps } from "adminComponents/atoms/Heading";
import { IconTooltip } from "adminComponents/atoms/IconTooltip";

interface IProps {
  noOfLines?: number;
  children: string | ReactElement;
  headingVariant: IHeadingProps["variant"];
  headingAs: IHeadingProps["as"];
  headingStyles?: CSSObject;
  triggerAriaLabel?: string;
}

export const ClampedHeading: React.FC<IProps> = ({
  noOfLines = 2,
  children,
  headingVariant,
  headingStyles,
  headingAs,
  triggerAriaLabel,
}) => {
  const headingRef = useRef<HTMLInputElement>(null);
  const [isClamped, setIsClamped] = useState(false);
  useEffect(() => {
    const clamped =
      (headingRef.current?.scrollHeight as number) >
      (headingRef.current?.clientHeight as number);
    setIsClamped(clamped);
  }, [headingRef]);

  const styles = useMultiStyleConfig("AdminClampedHeading", {
    noOfLines: `${noOfLines}`,
  });

  return (
    <IconTooltip
      isDisabled={!isClamped}
      popoverVariant="adminClampedHeading"
      triggerAriaLabel={
        triggerAriaLabel
          ? triggerAriaLabel
          : typeof children === "string"
          ? children
          : undefined
      }
      triggerEl={
        <Heading
          as={headingAs}
          headingRef={headingRef}
          sx={{ ...styles.heading, ...headingStyles }}
          variant={headingVariant}
        >
          {children}
        </Heading>
      }
    >
      {children}
    </IconTooltip>
  );
};
