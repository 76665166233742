import { Box, Image, Text, VStack } from "@chakra-ui/react";
import { camelCase } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { SessionGameType } from "links/lib/types";
import { Card, ICardProps } from "sessionComponents/atoms/Card";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { useDetectOrientation } from "sessionComponents/theme/hooks/useDetectOrientation";

import bigBoardIllustration2 from "./resource/big_board_illustration_2.png";
import bigBoardIllustration3 from "./resource/big_board_illustration_3.png";
import illustration1 from "./resource/illustration_1.png";
import quickPlayIllustration2 from "./resource/quick_play_illustration_2.png";
import quickPlayIllustration3 from "./resource/quick_play_illustration_3.png";

interface IInstructionCardProps extends ICardProps {
  outerGame?: SessionGameType;
  order: number;
  height?: string;
}

const bigBoardIllustrations = {
  1: { image: illustration1, altTextKey: "illustration1AltText" },
  2: {
    image: bigBoardIllustration2,
    altTextKey: "bigBoardIllustration2AltText",
  },
  3: {
    image: bigBoardIllustration3,
    altTextKey: "bigBoardIllustration3AltText",
  },
};

const quickPlayIllustrations = {
  1: { image: illustration1, altTextKey: "illustration1AltText" },
  2: {
    image: quickPlayIllustration2,
    altTextKey: "quickPlayIllustration2AltText",
  },
  3: {
    image: quickPlayIllustration3,
    altTextKey: "quickPlayIllustration3AltText",
  },
};

export const InstructionCard: React.FC<IInstructionCardProps> = ({
  outerGame = SessionGameType.TheBigBoard,
  order,
  height,
  ...cardProps
}) => {
  const {
    match: { fontSize },
    fontSizeHalf,
    size,
  } = useBreakpoints();
  const { isLandscape } = useDetectOrientation();
  const { t } = useTranslation("session", {
    keyPrefix: "gameInstructions",
    useSuspense: false,
  });

  let illustration =
    bigBoardIllustrations[order as keyof typeof bigBoardIllustrations];
  if (outerGame === SessionGameType.QuickPlay) {
    illustration =
      quickPlayIllustrations[order as keyof typeof quickPlayIllustrations];
  }

  const mainSplashTextKey = `${camelCase(outerGame)}MainSplashText${order}`;
  const flavorTextKey = `${camelCase(outerGame)}FlavorText${order}`;

  // Quick Play instructions are longer and need a reduced font size to avoid
  // overflowing, especially if the optional third instruction card is displayed.
  // Adjust according to landscape vs. portrait, since all cards are displayed
  // at once in landscape.
  let headingFontSize = fontSize * 1.3;
  let flavorTextFontSize = fontSize;
  if (outerGame === SessionGameType.QuickPlay) {
    if (isLandscape) {
      headingFontSize = fontSize * 0.7;
      flavorTextFontSize = fontSize * 0.5;
      if (size === "sm") {
        headingFontSize = fontSize * 0.7;
        flavorTextFontSize = fontSizeHalf;
      }
    } else {
      headingFontSize = fontSize * 1.1;
    }
  }

  const fontColor =
    outerGame === SessionGameType.QuickPlay
      ? "primary.warm-black"
      : "link.link-blue";
  return (
    <Card useThickBorder h={height || "auto"} {...cardProps}>
      <VStack
        h="full"
        justifyContent="space-between"
        overflowY="auto"
        overflowX="hidden"
      >
        <Box flex="1" alignContent="flex-start">
          <Text
            fontSize={pxToRem(headingFontSize)}
            color={fontColor}
            textStyle="gameDisplay"
            textAlign="center"
          >
            {t(mainSplashTextKey)}
          </Text>
        </Box>
        <Box flex="1" alignContent="center">
          <Text
            fontSize={pxToRem(flavorTextFontSize)}
            color={fontColor}
            textStyle="gameText"
            textAlign="center"
          >
            {t(flavorTextKey)}
          </Text>
        </Box>
        <Box flex="1" alignContent="flex-end">
          <Image src={illustration.image} alt={t(illustration.altTextKey)} />
        </Box>
      </VStack>
    </Card>
  );
};
