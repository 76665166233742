import { BoxProps, Flex, HStack } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { IconTooltip } from "adminComponents/atoms/IconTooltip";
import { Text } from "adminComponents/atoms/Text";
import { ReactComponent as Flame } from "adminComponents/molecules/AvatarStreak/resources/flame.svg";
import { pxToRem } from "adminComponents/utils/pxToRem";

export interface IProps extends BoxProps {
  weekStreak?: number;
  xpPointsTotal?: number;
}

export const SubHeading: React.FC<IProps> = ({
  weekStreak,
  xpPointsTotal = 0,
  ...props
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  let weekStreakLabel = "";
  if (typeof weekStreak !== "undefined") {
    weekStreakLabel =
      weekStreak > 0
        ? t("studentProfileMenu.weekStreak", { weekStreak })
        : t("studentProfileMenu.noActiveStreak");
  }
  return (
    <Flex {...props}>
      <HStack spacing={pxToRem(5)}>
        <Text textAlign="right" variant="adminP2">
          {t("studentProfileMenu.xpPointsTotal", { xpPointsTotal })}
        </Text>
        <IconTooltip>{t("studentProfileMenu.xpTooltip")}</IconTooltip>
      </HStack>
      <HStack spacing={pxToRem(7)}>
        <Text variant="adminP2">{weekStreakLabel}</Text>
        {typeof weekStreak !== "undefined" && weekStreak > 0 && <Flame />}
      </HStack>
    </Flex>
  );
};
