import { Flex, Spinner, Text } from "@chakra-ui/react";
import React from "react";

import { ColorScheme } from "adminComponents";
import { pxToRem } from "adminComponents/utils/pxToRem";

import { useBreakpoints } from "../../contexts/breakpoints";
import { Icon } from "../Icon";

interface IMarkerProps {
  containerHeight: string;
  containerWidth?: string;
  borderRightColor?: string;
  borderRightWidth?: string;
  loading?: boolean;
  iconHeight: string;
  iconColor: ColorScheme;
  number: string | number;
  fontSize?: string;
  spinnerSize?: string;
}

export const Marker: React.FC<IMarkerProps> = ({
  containerHeight,
  borderRightColor,
  borderRightWidth,
  loading,
  iconHeight,
  iconColor,
  number,
  containerWidth,
  fontSize,
  spinnerSize,
}) => {
  const {
    match: { borderWidth, iconSize, fontSize: defaultFontSize },
  } = useBreakpoints();

  return (
    <Flex
      height={containerHeight}
      alignItems="center"
      justifyContent="center"
      width={containerWidth}
      borderRightWidth={borderRightWidth}
      borderRightColor={borderRightColor}
      position="relative"
      pointerEvents="none"
    >
      {loading ? (
        <Spinner
          h={spinnerSize}
          w={spinnerSize}
          color="utility.link"
          emptyColor="primary.tan"
          thickness={pxToRem(borderWidth)}
        />
      ) : (
        <Icon
          icon="pin"
          width="auto"
          height={iconHeight}
          iconColor={iconColor}
          decorative
        />
      )}

      <Text
        position="absolute"
        zIndex={2}
        left="50%"
        top="50%"
        transform="translate(-50%, -50%)"
        marginTop={`-${pxToRem(iconSize / 5)}`}
        textStyle="gameDisplay"
        color="primary.white"
        fontSize={fontSize ?? pxToRem(defaultFontSize)}
      >
        {number}
      </Text>
    </Flex>
  );
};
