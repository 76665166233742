import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  Flex,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Heading } from "adminComponents/atoms/Heading";
import {
  IconCTAButtonWrapper as BackButtonWrapper,
  IconCTAButton,
} from "adminComponents/atoms/IconCTAButton";

export interface IProps {
  isOpen: boolean;
  leftColumnContent: React.ReactNode;
  rightColumnContent: React.ReactNode;
  handleBack: () => void;
  handleClose: () => void;
}

export const ShareAvatarOverlayLayout: React.FC<IProps> = ({
  isOpen,
  leftColumnContent,
  rightColumnContent,
  handleBack,
  handleClose,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const styles = useMultiStyleConfig("AdminShareAvatarOverlayLayout", {});

  return (
    <Drawer isOpen={isOpen} onClose={handleClose} size="full" isFullHeight>
      <DrawerContent sx={styles.content}>
        <DrawerBody sx={styles.body}>
          <Flex sx={styles.container}>
            <Box sx={styles.leftColumn}>
              <BackButtonWrapper sx={styles.iconWrapper}>
                <IconCTAButton
                  sx={styles.icon}
                  icon="keyboard_arrow_left"
                  boxSize={24}
                  iconSize={20}
                  ariaLabel={t("common.ariaBackToPreviousScreen")}
                  onClick={handleBack}
                />
                <Heading as="h1" variant="adminH6Bold">
                  {t("shareAvatarOverlayLayout.heading")}
                </Heading>
              </BackButtonWrapper>
              {leftColumnContent}
            </Box>
            <Box sx={styles.rightColumn}>{rightColumnContent}</Box>
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
