import {
  Box,
  Image,
  VisuallyHidden,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Card } from "adminComponents/atoms/Card";
import { GoalsProgressBar } from "adminComponents/molecules/GoalsProgressBar";
import { pxToRem } from "adminComponents/utils";
import { ReactComponent as TicketIconSVG } from "sharedComponents/organisms/Season1MapHeader/resource/ticket_icon.svg";

interface IProps {
  seasonName?: string;
  daysLeft?: number;
  currentLevel: number;
  currentXP: number;
  nextLevelXP: number;
  ticketCount?: number;
  imageSrc: string;
  imageAlt: string;
  headingLevel?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p";
  handleOpenMap: () => void;
}

export const SeasonChallengeMapCard: React.FC<IProps> = ({
  seasonName,
  daysLeft,
  currentLevel,
  currentXP,
  nextLevelXP,
  ticketCount,
  imageSrc,
  imageAlt,
  headingLevel,
  handleOpenMap,
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });
  const { t: tMap } = useTranslation("map", {
    useSuspense: false,
  });

  const styles = useMultiStyleConfig("AdminSeasonChallengeMapCard", {});
  const remainingXP = nextLevelXP - currentXP;

  const isSeasonComplete = remainingXP < 0;

  return (
    <Card
      borderColor="primary.tan"
      backgroundColor="primary.white"
      variant="adminCardMediumBorderMediumPadding"
    >
      <GoalsProgressBar
        variant="adminSeasonProgress"
        headingLevel={headingLevel}
        challenge={{
          title: seasonName || t("seasonChallengeCard.title"),
          description: t(
            isSeasonComplete
              ? "seasonChallengeCard.descriptionComplete"
              : "seasonChallengeCard.description",
            {
              currentLevel,
              remainingXP,
            }
          ),
          xp_threshold: nextLevelXP,
          awarded_xp: currentXP,
        }}
        isComplete={isSeasonComplete}
        remainingDays={daysLeft}
        hasIcon
      />
      <Box __css={styles.container}>
        <Button
          variant="adminButtonFilled"
          size="xs"
          onClick={handleOpenMap}
          __css={styles.openMapButton}
        >
          {t("seasonChallengeCard.openMapButton")}
        </Button>
        <Button
          __css={styles.ticketButton}
          leftIcon={
            <Box
              display="inline-flex"
              justifyContent="center"
              alignItems="center"
              boxSize={pxToRem(22)}
              pr={pxToRem(4)}
            >
              <TicketIconSVG />
            </Box>
          }
          cursor="default"
          variant="adminSeasonMapTicketCounter"
          size="xs"
        >
          {ticketCount || ""}
          <VisuallyHidden>
            {tMap("season1.header.availableTicketsLabel")}
          </VisuallyHidden>
        </Button>

        <Box __css={styles.imageContainer}>
          <Image src={imageSrc} alt={imageAlt} __css={styles.image} />
        </Box>
      </Box>
    </Card>
  );
};
