import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Show,
  Spacer,
  VStack,
  useBreakpointValue,
  useDisclosure,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import { debounce } from "lodash";
import moment, { Moment } from "moment";
import qs from "qs";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useHistory } from "react-router-dom";

import { NavigationLink } from "adminComponents";
import { AssignmentsIcon } from "adminComponents/atoms/AssignmentsIcon";
import { Avatar, IProps as AvatarProps } from "adminComponents/atoms/Avatar";
import {
  Breadcrumb,
  BreadcrumbNavigationLink,
} from "adminComponents/atoms/Breadcrumb";
import { Button } from "adminComponents/atoms/Button";
import { Divider } from "adminComponents/atoms/Divider";
import { Heading } from "adminComponents/atoms/Heading";
import { Icon } from "adminComponents/atoms/Icon";
import { IconCTAButton } from "adminComponents/atoms/IconCTAButton";
import { Logo } from "adminComponents/atoms/Logo";
import { Tag } from "adminComponents/atoms/Tag";
import { TeacherAccountMenu } from "adminComponents/molecules/TeacherAccountMenu";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { IHandleSetQueryArgs, ISearchQuery } from "lib/hooks/useLibraryFilter";
import { localStoreGracePeriodDeferralKeyName } from "links/lib/constants";
import { useAuth } from "links/lib/features/auth";
import { useSearchPracticeDataOnce } from "links/lib/features/search";
import {
  IGradeLevel,
  ILicense,
  ISearchSuggestion,
  ISubject,
  ITeacherOnboardingChecklist,
  IUser,
  PracticeSetAvailability,
  UserRole,
} from "links/lib/types";
import { usePremiumBannerInfo } from "sharedComponents/hooks/usePremiumBannerInfo";
import { useShowNewLibrary } from "sharedComponents/hooks/useShowNewLibrary";

import { DismissableBanner } from "../DismissableBanner";
import { TeacherNavigationHeader } from "../TeacherNavigationHeader";
import { Link } from "./components/Link";

export const NavigationHeader: React.FC<INavigationHeaderProps> = (props) => {
  const { hasNoPremiumAccess } = useAuth();
  const useNewHeader = useShowNewLibrary();

  const history = useHistory();
  const searchPracticeDataOnce = useSearchPracticeDataOnce({});
  const handleSuggestDebounced = debounce(
    (term: string) => {
      searchPracticeDataOnce.execute({
        include_shared: true,
        availability: props.user?.is_guest
          ? PracticeSetAvailability.Public
          : PracticeSetAvailability.ALL_SEARCH,
        term,
        include_suggestions: true,
        page: 1,
        per_page: 8,
        include_detailed_suggestions: true,
        exclude_subject_counts: true,
      });
    },
    250,
    {
      leading: false,
      trailing: true,
    }
  );

  const handleSearch = useCallback(
    (search: string, subjectIds?: string[], gradeLevelIds?: string[]) => {
      const params = qs.stringify(
        {
          term: search,
          subjectIds: subjectIds,
          gradeLevelIds: gradeLevelIds,
          page: 1,
        },
        { encodeValuesOnly: true }
      );

      history.push(`/t/explore?${params}`);
    },
    [history]
  );

  const defaultQuery = {
    term: "",
    filters: {
      standardIds: [],
      subjectIds: [],
      numQuestions: [],
      languages: [],
      gradeLevelIds: [],
      certifiedOnly: false,
      hidePremium: false,
    },
  };

  const handleUpdateQuery = (args: IHandleSetQueryArgs) => {
    handleSearch(args.term, args.subjectIds, args.gradeLevelIds);
  };

  return props.user?.role !== UserRole.Student && useNewHeader ? (
    <TeacherNavigationHeader
      hasNoPremiumAccess={hasNoPremiumAccess}
      navigationData={props}
      isGuest={props.user?.is_guest}
      {...props}
      handleClearSearchInput={() => {
        // no-op
      }}
      query={props.query ? props.query : defaultQuery}
      suggestions={
        props.suggestions
          ? props.suggestions
          : searchPracticeDataOnce.data?.suggestions
      }
      handleSearch={props.handleSearch ? props.handleSearch : handleSearch}
      handleSuggest={
        props.handleSuggest ? props.handleSuggest : handleSuggestDebounced
      }
      handleUpdateQuery={
        props.handleUpdateQuery ? props.handleUpdateQuery : handleUpdateQuery
      }
      searchFilterSuggestions={
        props.searchFilterSuggestions
          ? props.searchFilterSuggestions
          : searchPracticeDataOnce.data?.detailed_suggestions
      }
    />
  ) : (
    <OriginalNavigationHeader {...props} />
  );
};

export interface INavigationHeaderProps {
  avatarData?: AvatarProps;
  breadcrumbs?: BreadcrumbNavigationLink[];
  logoLink: NavigationLink;
  navigationLinks?: NavigationLink[];
  authActionLinks: NavigationLink[];
  resourceLinks: NavigationLink[];
  accountLinks: NavigationLink[];
  user?: IUser;
  userLicense?: ILicense;
  showGracePeriodBanner?: boolean;
  gracePeriodEndDate?: Moment;
  variant?: string;
  isLoading?: boolean;
  assignmentCount?: number;
  handleCreatePracticeSet?: () => void;
  handleOpenAssignmentsFlyout?: () => void;
  handleLogIn?: () => void;
  handleSignUp?: () => void;
  isGrayscale?: boolean;
  activeNavigationPath?: string;
  showPremiumMarker?: boolean;
  subjects: ISubject[];
  parentSubjects: ISubject[];
  gradeLevels: IGradeLevel[];
  hasNoPremiumAccess?: boolean;
  adminMode?: boolean;
  teacherOnboardingChecklist?: ITeacherOnboardingChecklist;
  // The following props are only used in the new teacher navigation header
  handleSelectSubjects?: (subjectIds: string[]) => void;
  handleSelectGrades?: (gradeLevelIds: string[]) => void;
  handleSearch?: (search: string) => void;
  handleClearSearchInput?: () => void;
  handleSuggest?: (searchTerm: string) => void;
  handleUpdateQuery?: (args: IHandleSetQueryArgs) => void;
  query?: ISearchQuery;
  isSearching?: boolean;
  suggestions?: string[];
  searchFilterSuggestions?: ISearchSuggestion[];
  isGuest?: boolean;
  disableDropdowns?: boolean;
}

// This version of the navigation header was used before the teacher
// homepage was redesigned to be more library-like. Once the "enable_library_homepage"
// feature flag is fully rolled out, it should be removed.
export const OriginalNavigationHeader: React.FC<INavigationHeaderProps> = ({
  avatarData,
  breadcrumbs,
  logoLink,
  navigationLinks,
  authActionLinks,
  resourceLinks,
  accountLinks,
  user,
  userLicense,
  showGracePeriodBanner = false,
  gracePeriodEndDate,
  variant = "default",
  isLoading = false,
  assignmentCount,
  handleCreatePracticeSet,
  handleOpenAssignmentsFlyout,
  handleLogIn,
  handleSignUp,
  isGrayscale = false,
  activeNavigationPath,
  showPremiumMarker = true,
  hasNoPremiumAccess = false,
  adminMode,
}) => {
  const [showMenu, toggleShowMenu] = useState(false);
  const [isNavTransparent, toggleIsNavTransparent] = useState(true);
  const [isBreadcrumbVisible, toggleIsBreadcrumbVisible] = useState(true);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const menuRef = useRef();
  const navTopRowRef = useRef<HTMLDivElement>(null);
  const navBottomRowRef = useRef<HTMLDivElement>(null);
  const isMobile = useBreakpointValue({ base: true, md: false });
  const isMediumBreakpoint = useBreakpointValue({
    base: false,
    md: true,
    lg: false,
  });
  const isMediumOrSmallerBreakpoint = useBreakpointValue({
    base: true,
    lg: false,
  });

  const styles = useMultiStyleConfig("AdminNavigationHeader", {
    showMenu,
    isNavTransparent,
    isBreadcrumbVisible,
    isGrayscale,
    variant,
    showTrialGracePeriodBanner: showGracePeriodBanner,
  });

  const isInOrganization =
    user?.organization_id && user.organization_id !== "0";

  const gracePeriodBannerInfo = usePremiumBannerInfo({
    gracePeriodEndDate: moment(gracePeriodEndDate),
    userRole: user?.role,
    isInOrganization: !!isInOrganization,
    isTrial: !!userLicense?.is_trial,
    licenseEndDate: moment(userLicense?.end_date),
  });

  const handleAvatarClick = () => {
    toggleShowMenu((prev) => !prev);
  };

  useEffect(() => {
    if (navTopRowRef.current) {
      const callback = (event: Event) => {
        const { detail } = event as CustomEvent;
        if (detail.shouldBeTransparent && detail.effectedElement === "topNav") {
          toggleIsNavTransparent(true);
        } else if (detail.effectedElement === "topNav") {
          toggleIsNavTransparent(false);
        }
      };

      window.addEventListener("nav-intersect", callback);

      return () => window.removeEventListener("nav-intersect", callback);
    }
  }, []);

  useEffect(() => {
    if (navBottomRowRef.current) {
      const callback = (event: Event) => {
        const { detail } = event as CustomEvent;
        if (
          detail.shouldBeTransparent &&
          detail.effectedElement === "breadcrumb"
        ) {
          toggleIsBreadcrumbVisible(true);
        } else if (detail.effectedElement === "breadcrumb") {
          toggleIsBreadcrumbVisible(false);
        }
      };

      window.addEventListener("nav-intersect", callback);

      return () => window.removeEventListener("nav-intersect", callback);
    }
  }, [isLoading]);

  const { t } = useTranslation("admin", { useSuspense: false });

  return (
    <Box
      __css={styles.outer}
      height={{
        base: "100%",
        md: "50%",
        xl: "25%",
      }}
    >
      <Box __css={styles.firstRowContainer}>
        <Box
          __css={styles.firstRow}
          ref={navTopRowRef}
          backgroundColor={adminMode ? "primary.golden-light" : undefined}
        >
          <Box __css={styles.navContainerMobile}>
            <Button
              ref={menuRef.current}
              onClick={onOpen}
              variant="adminInlineIconButton"
              aria-label={t("common.openMenu")}
            >
              <Icon icon="menu" />
            </Button>
            <Drawer
              size="full"
              isOpen={isOpen}
              placement="left"
              onClose={onClose}
              finalFocusRef={menuRef.current}
              variant="adminNavigation"
            >
              <DrawerOverlay />
              <DrawerContent>
                <DrawerCloseButton
                  as={IconCTAButton}
                  ariaLabel={t("common.close")}
                />
                <DrawerBody>
                  <Flex direction="column" h="calc(100% - 50px)">
                    {navigationLinks?.map((nl, index) => (
                      <Box key={index} __css={styles.navItemMobile}>
                        <Link as={RouterLink} to={nl.to}>
                          <Flex alignItems="center">
                            <Heading as="p" variant="adminH5">
                              {nl.label}
                            </Heading>
                            {nl.isExternal && (
                              <ExternalLinkIcon mx={pxToRem(8)} />
                            )}
                          </Flex>
                        </Link>
                        <Divider color="primary.tan" />
                      </Box>
                    ))}
                    {(user?.role === UserRole.Admin ||
                      user?.role === UserRole.Teacher ||
                      user?.role === UserRole.ContentSpecialist) &&
                      !!handleCreatePracticeSet && (
                        <>
                          <Spacer />
                          <Box pb={pxToRem(16)}>
                            <Button
                              onClick={handleCreatePracticeSet}
                              size="sm"
                              variant="adminButtonFilled"
                              w="full"
                            >
                              {t(
                                "adminNavigationHeader.navigationLinks.buttonCreatePracticeSet"
                              )}
                            </Button>
                          </Box>
                        </>
                      )}
                    {user?.role === UserRole.Admin && adminMode && (
                      <>
                        <Spacer />
                        <Button
                          size="sm"
                          as={RouterLink}
                          to="/t/home"
                          variant="adminButtonFilled"
                          textAlign="center"
                        >
                          {t(
                            "adminNavigationHeader.navigationLinks.buttonCloseAdminMode"
                          )}
                        </Button>
                      </>
                    )}

                    {!user && (
                      <VStack spacing={pxToRem(16)} w="full">
                        {handleLogIn && (
                          <Button
                            onClick={handleLogIn}
                            size="sm"
                            variant="adminButtonOutlined"
                            bgColor="white"
                            w="full"
                          >
                            {t(
                              "adminNavigationHeader.navigationLinks.buttonLogIn"
                            )}
                          </Button>
                        )}
                        {handleSignUp && (
                          <Button
                            onClick={handleSignUp}
                            size="sm"
                            variant="adminButtonFilled"
                            w="full"
                          >
                            {t(
                              "adminNavigationHeader.navigationLinks.buttonSignUp"
                            )}
                          </Button>
                        )}
                      </VStack>
                    )}
                  </Flex>
                </DrawerBody>
              </DrawerContent>
            </Drawer>
          </Box>
          <HStack>
            {user && !user.is_guest && (
              <Box display="inline" id="psi_launcher" />
            )}
            <Logo width={pxToRem(154)} navigationLink={logoLink} />
            {adminMode && !isMediumOrSmallerBreakpoint && (
              <Tag
                colorScheme="dark-gray"
                leftIcon="setting_outlined"
                color="primary.white"
              >
                {t("adminNavigationHeader.general.adminTools")}
              </Tag>
            )}
          </HStack>
          <Box __css={styles.navContainerDesktop}>
            {navigationLinks?.map((nl, index) => (
              <Link
                as={RouterLink}
                key={index}
                to={nl.to}
                variant="adminNavLink"
                aria-current={activeNavigationPath === nl.to}
                target={nl.isExternal ? "_blank" : undefined}
              >
                <Flex alignItems="center">
                  {nl.label}
                  {nl.isExternal && <ExternalLinkIcon mx={pxToRem(8)} />}
                </Flex>
              </Link>
            ))}
          </Box>
          <Box sx={styles.avatarNavContainer}>
            <Box __css={styles.avatarContainer}>
              <Flex alignItems="center" gap={pxToRem(10)}>
                {user?.role === UserRole.Student && (
                  <Box __css={styles.assignments}>
                    <AssignmentsIcon
                      assignmentCount={assignmentCount}
                      onClick={handleOpenAssignmentsFlyout}
                      ariaLabel={t("common.openMenu")}
                    />
                  </Box>
                )}
                {(user?.role === UserRole.Admin ||
                  user?.role === UserRole.Teacher ||
                  user?.role === UserRole.ContentSpecialist) &&
                  !!handleCreatePracticeSet && (
                    <Show above="md">
                      <Button
                        onClick={handleCreatePracticeSet}
                        size="sm"
                        variant="adminButtonFilled"
                        leftIcon={
                          isMediumBreakpoint ? <Icon icon="add" /> : <></>
                        }
                      >
                        {isMediumBreakpoint
                          ? t(
                              "adminNavigationHeader.navigationLinks.buttonPracticeSet"
                            )
                          : t(
                              "adminNavigationHeader.navigationLinks.buttonCreatePracticeSet"
                            )}
                      </Button>
                    </Show>
                  )}
                {user?.role === UserRole.Admin && adminMode && (
                  <Show above="md">
                    <Button
                      size="sm"
                      as={RouterLink}
                      to="/t/home"
                      variant="adminButtonFilled"
                      textAlign="center"
                    >
                      {t(
                        "adminNavigationHeader.navigationLinks.buttonCloseAdminMode"
                      )}
                    </Button>
                  </Show>
                )}
                {!user && (
                  <Show above="md">
                    {handleLogIn && (
                      <Button
                        onClick={handleLogIn}
                        size="sm"
                        variant="adminButtonOutlined"
                        bgColor="white"
                      >
                        {t("adminNavigationHeader.navigationLinks.buttonLogIn")}
                      </Button>
                    )}
                    {handleSignUp && (
                      <Button
                        onClick={handleSignUp}
                        size="sm"
                        variant="adminButtonFilled"
                      >
                        {t(
                          "adminNavigationHeader.navigationLinks.buttonSignUp"
                        )}
                      </Button>
                    )}
                  </Show>
                )}
                {(user?.role === UserRole.Student && !isMobile) ||
                user?.role === UserRole.Admin ||
                user?.role === UserRole.Teacher ||
                user?.role === UserRole.ContentSpecialist ? (
                  <Popover
                    variant="adminProfileMenu"
                    isOpen={!isMediumOrSmallerBreakpoint && showMenu}
                    onOpen={handleAvatarClick}
                    onClose={handleAvatarClick}
                    placement="bottom-end"
                  >
                    <PopoverTrigger>
                      <Box>
                        <Avatar
                          {...avatarData}
                          handleClick={() => {
                            /* do nothing, popover triggers opening of 
                          TeacherAccountMenu */
                          }}
                          size="sm"
                          variant="adminAvatar"
                          showPremiumMarker={
                            showPremiumMarker && !hasNoPremiumAccess
                          }
                        />
                      </Box>
                    </PopoverTrigger>
                    <PopoverContent>
                      <TeacherAccountMenu
                        user={user}
                        accountLinks={accountLinks}
                        handleClose={handleAvatarClick}
                        resourceLinks={resourceLinks}
                        authActionLinks={authActionLinks}
                        showPremiumMarker={showPremiumMarker}
                      />
                    </PopoverContent>
                  </Popover>
                ) : undefined}
              </Flex>
              <Box __css={styles.avatarMenu}>
                {(user?.role === UserRole.Admin ||
                  user?.role === UserRole.Teacher ||
                  user?.role === UserRole.ContentSpecialist) && (
                  <Drawer
                    size="full"
                    isOpen={!!isMediumOrSmallerBreakpoint && showMenu}
                    placement="left"
                    onClose={handleAvatarClick}
                    variant="adminNavigation"
                  >
                    <DrawerOverlay />
                    <DrawerContent>
                      <DrawerCloseButton
                        as={IconCTAButton}
                        ariaLabel={t("common.close")}
                      />
                      <DrawerBody>
                        <TeacherAccountMenu
                          user={user}
                          accountLinks={accountLinks}
                          handleClose={handleAvatarClick}
                          resourceLinks={resourceLinks}
                          authActionLinks={authActionLinks}
                          showPremiumMarker={showPremiumMarker}
                        />
                      </DrawerBody>
                    </DrawerContent>
                  </Drawer>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        {!isLoading && showGracePeriodBanner && (
          <Box __css={styles.gracePeriodBanner}>
            <DismissableBanner
              title={gracePeriodBannerInfo.title}
              subtitle={gracePeriodBannerInfo.subtitle}
              linkText={gracePeriodBannerInfo.linkText}
              linkTo={gracePeriodBannerInfo.linkTo}
              localStorageDismissedStateKey={
                localStoreGracePeriodDeferralKeyName
              }
            />
          </Box>
        )}
      </Box>

      {!isLoading && breadcrumbs && (
        <Box __css={styles.secondRow} ref={navBottomRowRef}>
          <Breadcrumb breadcrumbs={breadcrumbs} />
        </Box>
      )}
    </Box>
  );
};
