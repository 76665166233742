import { Box, Flex, useMultiStyleConfig } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Card } from "adminComponents/atoms/Card";
import { ClampedHeading } from "adminComponents/atoms/ClampedHeading";
import { CoverImageIcon } from "adminComponents/atoms/CoverImageIcon";
import { Icon } from "adminComponents/atoms/Icon";
import { IconButton } from "adminComponents/atoms/IconButton";
import { Text } from "adminComponents/atoms/Text";
import { SummarizedSubjectTags } from "adminComponents/molecules/SummarizedSubjectTags";
import { CoverImageBGColorSchemes } from "links/lib/constants";
import { IPracticeSet } from "links/lib/types";

export interface IProps {
  practiceSet: IPracticeSet;
  rewardXp: number;
  headingLevel?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p";
  isDisabled?: boolean;
  isLoading?: boolean;
  handlePlayButton: (practiceSet: IPracticeSet) => void;
}

export const StudentPracticeSetCard: React.FC<IProps> = ({
  practiceSet,
  rewardXp,
  headingLevel,
  handlePlayButton,
  isDisabled,
  isLoading,
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });

  const { cover_image_bg_color_scheme } = practiceSet;

  let rgbBackgroundColor;

  if (cover_image_bg_color_scheme) {
    const [backgroundRGB] =
      CoverImageBGColorSchemes[cover_image_bg_color_scheme];
    rgbBackgroundColor = "rgb(" + backgroundRGB + ")";
  }

  const styles = useMultiStyleConfig("AdminStudentPracticeSetCard", {
    rgbBackgroundColor,
  });

  const { title, cover_image_icon, subjects } = practiceSet;
  const handlePlay = () => handlePlayButton(practiceSet);

  return (
    <Card
      sx={styles.container}
      borderColor="primary.tan"
      variant="adminCardSelectable"
      backgroundColor="primary.white"
    >
      <Flex sx={styles.header}>
        <Flex sx={styles.iconContainer}>
          <CoverImageIcon
            sx={styles.icon}
            icon={cover_image_icon}
            borderColor="primary.white"
            rgbBackgroundColor={rgbBackgroundColor}
            variant="adminSolidBorder"
          />
        </Flex>
        <SummarizedSubjectTags subjects={subjects} />
      </Flex>

      <Box sx={styles.content}>
        <Flex sx={styles.title}>
          <ClampedHeading
            noOfLines={3}
            headingAs={headingLevel ? headingLevel : "p"}
            headingVariant="adminH4"
          >
            {title}
          </ClampedHeading>
          <IconButton
            sx={styles.playButton}
            variant="adminPlayButton"
            shape="type2"
            icon="play"
            ariaLabel={t("common.play")}
            onClick={handlePlay}
            disabled={isDisabled}
            isLoading={isLoading}
          />
        </Flex>

        <Flex alignItems="center">
          <Icon
            decorative
            sx={styles.xpIcon}
            icon="star_outlined"
            iconColor="primary.white"
            backgroundColor="utility.focus"
            variant="circleWithBackgroundColor"
          />
          <Text sx={styles.xp} variant="adminP2">
            <strong>{rewardXp}</strong> {t("common.xpReward")}
          </Text>
        </Flex>
      </Box>
    </Card>
  );
};
