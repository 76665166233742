import { Box, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { usePrevious, useTimeout, useUnmount } from "react-use";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { AnalyticsEvent, SessionGameType } from "links/lib/types";
import { Stage } from "sessionComponents/atoms/Stage";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { useSessionAnalytics } from "sharedComponents/contexts/sessionAnalytics";

export interface INoOutcomeProps {
  outerGame: SessionGameType;
}

export const NoOutcome: React.FC<INoOutcomeProps> = ({ outerGame }) => {
  const { track } = useSessionAnalytics();
  const { match: currentBreakpoints } = useBreakpoints();

  const { t } = useTranslation("session", {
    keyPrefix: "roundReview",
    useSuspense: false,
  });

  // prevent potentially flashing this component due to slow/out of order socket message state
  const [timerUp, cancel] = useTimeout(1000);
  useUnmount(() => cancel());

  const showComponent = timerUp();
  const prevShowComponent = usePrevious(showComponent);

  useEffect(() => {
    if (!prevShowComponent && showComponent) {
      track(AnalyticsEvent.Session_RoundReview_Student_NoOutcome, {});
    }
  }, [showComponent, prevShowComponent, track]);

  if (showComponent) {
    return (
      <Box h="full" position="relative" overflow="hidden">
        <Stage showCurtainOnly outerGame={outerGame}>
          <Box
            h="full"
            w="full"
            p={pxToRem(currentBreakpoints.padding)}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Text
              textStyle="gameText"
              textAlign="center"
              fontSize={pxToRem(currentBreakpoints.fontSize)}
              color={
                outerGame === SessionGameType.TheBigBoard
                  ? "overlay.white"
                  : "black"
              }
            >
              {t("noResults")}
            </Text>
          </Box>
        </Stage>
      </Box>
    );
  } else {
    return <></>;
  }
};
