// for multiple choice and multi select (checkbox and radio) questions
import React from "react";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { ISelectableOptionProps } from "sessionComponents/types";

import { GenericImageOption } from "./GenericImageOption";
import { GenericTextOption } from "./GenericTextOption";

export const SelectableOption: React.FC<ISelectableOptionProps> = (props) => {
  const { text, imageUrl, imageAltText } = props;
  const { match: currentBreakpoints } = useBreakpoints();
  if (text) return <GenericTextOption {...props} text={text} />;
  else if (imageUrl)
    return (
      <GenericImageOption
        {...props}
        imageUrl={imageUrl}
        imageAltText={imageAltText || ""}
        imageButtonBottomPosition={pxToRem(currentBreakpoints.padding / 4)}
        imageButtonRightPosition={pxToRem(currentBreakpoints.padding / 4)}
        imageButtonBoxSize={pxToRem(currentBreakpoints.iconSize)}
        imageButtonIconSize={pxToRem(currentBreakpoints.iconSize / 1.7)}
      />
    );
  else return <></>;
};
