import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import type { IStandard } from "../../types";

export interface IFetchStandardsOpts {
  limit: number;
  offset: number;
  country?: string;
  grade_level_id?: string;
  subject_id?: string;
  standard_collection_id?: string;
  region?: string;
  query?: string;
  ids?: Array<string>;
  enabled: boolean;
}

export interface IFetchStandardsResponse {
  standards: Array<IStandard>;
}

/**
 * Fetches a list of standards
 * @returns
 */
export default function useFetchStandards(
  opts: IFetchStandardsOpts
): UseQueryResult<IFetchStandardsResponse, unknown> {
  const { enabled, ...params } = opts;
  const axios = useAxios();

  const q = useQuery<IFetchStandardsResponse>(
    ["standards", { params }],
    async (): Promise<IFetchStandardsResponse> => {
      return await axios
        .get(`/v1/standards`, {
          params,
        })
        .then((r) => r.data as IFetchStandardsResponse);
    },
    { enabled }
  );

  return q;
}
