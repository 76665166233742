import React, { useMemo, useRef, useState } from "react";
import { useInterval } from "react-use";

import { IAvatarAnimation, ISessionUser } from "links/lib/types";
import { AvatarLayout } from "sessionComponents/molecules/AvatarLayout";

export interface IAvatarCarouselProps {
  students: Array<ISessionUser>;
  groupedStudents?: Array<Array<ISessionUser>>;
  selfId: string;
  candidateAnimations?: Array<IAvatarAnimation>;
  showNametags?: boolean;
  yPosition?: number;
  disableCycle?: boolean;
  // If set to true, groupedStudents must be provided -- it will be used
  // to determine the order of avatars on screen
  useGroupedStudents?: boolean;
}

const getRandomStudents = (
  cycle: number,
  students: Array<ISessionUser>,
  selfId: string
): Array<{
  cycle: number;
  student: ISessionUser;
  isSelf: boolean;
}> => {
  const selectedStudents: Array<{
    cycle: number;
    student: ISessionUser;
    isSelf: boolean;
  }> = [];

  let i = 0;

  while (i < Math.min(students.length, 3)) {
    const candidateStudents = students.filter(
      (s) => !selectedStudents.some((ss) => ss.student.id === s.id)
    );

    const selectedStudent =
      candidateStudents[Math.floor(Math.random() * candidateStudents.length)];

    selectedStudents.push({
      student: selectedStudent,
      cycle,
      isSelf: selfId === selectedStudent.id,
    });

    i++;
  }

  return selectedStudents;
};

const getGroupedStudents = (
  cycle: number,
  groupedStudents: Array<Array<ISessionUser>>,
  selfId: string
): Array<{
  cycle: number;
  student: ISessionUser;
  isSelf: boolean;
}> => {
  const groupIndex = cycle % groupedStudents.length;
  return groupedStudents[groupIndex].map((student) => ({
    student,
    cycle,
    isSelf: selfId === student.id,
  }));
};

export const AvatarCarousel: React.FC<IAvatarCarouselProps> = ({
  students,
  groupedStudents = [],
  selfId,
  candidateAnimations,
  showNametags = true,
  yPosition = -200,
  disableCycle,
  useGroupedStudents = false,
}) => {
  const [currentStudents, setCurrentStudents] = useState(
    useGroupedStudents
      ? getGroupedStudents(0, groupedStudents, selfId)
      : getRandomStudents(0, students, selfId)
  );
  const cycle = useRef(0);

  useInterval(() => {
    if (disableCycle) return;
    cycle.current += 1;
    setCurrentStudents(
      useGroupedStudents
        ? getGroupedStudents(cycle.current, groupedStudents, selfId)
        : getRandomStudents(cycle.current, students, selfId)
    );
  }, 5000);

  const currentStudentUsers = useMemo(() => {
    return currentStudents.map((student) => student.student);
  }, [currentStudents]);

  return (
    <AvatarLayout
      students={currentStudentUsers}
      candidateAnimations={candidateAnimations}
      showNametags={showNametags}
      yPosition={yPosition}
      selfId={selfId}
    />
  );
};
