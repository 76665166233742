import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { pxToRem } from "adminComponents/utils";
import { useSession } from "links/lib/contexts/session";
import { useFetchClassroomUsers } from "links/lib/features/classrooms";
import { ISessionUser, UserRole, UsersGroupsRole } from "links/lib/types";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { useSafeUserArray } from "sharedComponents/hooks/useSafeUserArray";

import { StudentList } from "../StudentList";

interface IStudentListModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const StudentListModal: React.FC<IStudentListModalProps> = ({
  isOpen,
  onClose,
}) => {
  const {
    match: { fontSize },
  } = useBreakpoints();

  const session = useSession();
  const groupId = session?.group_id || "0";
  const { data: classroomStudentsData } = useFetchClassroomUsers({
    classroom_id: groupId,
    group_roles: [UsersGroupsRole.Member],
  });

  const users = useSafeUserArray();
  const { t } = useTranslation("session", { useSuspense: false });

  const studentsThatConnected = users.filter(
    (user: ISessionUser) => user.role === UserRole.Student
  );

  const classroomStudents = classroomStudentsData?.users || [];

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minW="95%" w="95%">
        <ModalHeader>
          <Text fontSize={pxToRem(fontSize)} fontStyle="gameText">
            {t("teacherLobby.studentListHeading")}
          </Text>
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <StudentList
            onlineStudents={studentsThatConnected}
            classroomStudents={classroomStudents}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
