import { Box } from "@chakra-ui/react";
import React from "react";
import { PieChart as Chart } from "react-minimal-pie-chart";

import { colorMapping } from "adminComponents/theme/colors";
import { pxToRem } from "adminComponents/utils/pxToRem";

const sizes = {
  xs: 20,
  sm: 50,
  md: 100,
  lg: 150,
  xl: 200,
};

interface IProps {
  percentage?: number;
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  styles?: Record<string, unknown>;
}

export const PieChart: React.FC<IProps> = ({
  percentage,
  size = "xs",
  styles,
}) => {
  const notAvailable = !percentage || percentage === 0;

  return (
    <Box
      __css={styles}
      width={pxToRem(sizes[size])}
      aria-role="img"
      role="img"
      aria-label={`${percentage}%`}
    >
      <Chart
        data={
          notAvailable
            ? [
                {
                  value: 100,
                  color: colorMapping["utility.badge-grey"],
                },
              ]
            : [
                {
                  value: percentage,
                  color: colorMapping["primary.golden-hover"],
                },
                {
                  value: 100 - percentage,
                  color: colorMapping["utility.light-orange"],
                },
              ]
        }
        lineWidth={notAvailable ? 35 : 100}
        startAngle={-90}
      />
    </Box>
  );
};
