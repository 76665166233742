import {
  BoxProps,
  Button,
  ButtonProps,
  Flex,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import React from "react";

import { Icon, IconType } from "adminComponents/atoms/Icon";
import { pxToRem } from "adminComponents/utils/pxToRem";

interface IProps extends ButtonProps {
  boxSize?: number;
  icon?: IconType;
  iconSize?: number;
  ariaLabel: string;
  variant?: "dark" | "light";
}

type IWrapperProps = Omit<BoxProps, "onClick">;

export const IconCTAButton: React.FC<IProps> = ({
  boxSize = 32,
  icon = "close",
  iconSize = 24,
  variant = "light",
  ariaLabel,
  ...buttonProps
}) => {
  const styles = useMultiStyleConfig("AdminIconCTAButton", {
    variant,
    boxSize,
    iconSize,
  });
  return (
    <Button {...buttonProps} __css={styles.box} aria-label={ariaLabel}>
      <Icon decorative icon={icon} styles={styles.icon} />
    </Button>
  );
};

export const IconCTAButtonWrapper: React.FC<IWrapperProps> = ({
  children,
  ...props
}) => (
  <Flex
    {...props}
    display="flex"
    flexDirection="row"
    alignItems="center"
    justifyContent="center"
    position="absolute"
    zIndex={10}
    __css={{
      "--close-button-size": pxToRem(32),
      height: "var(--close-button-size)",
    }}
  >
    {children}
  </Flex>
);
