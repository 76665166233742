import React from "react";

import { colors } from "adminComponents/theme/colors";

export const CertifiedCheckInvertedSVG: React.FC = () => (
  <svg
    width="32"
    height="31"
    viewBox="0 0 32 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="15.9963" r="10" fill="white" />
    <path
      stroke="white"
      d="M32 15.9963L28.4509 12.0106L28.9455 6.7392L23.6945 5.56777L20.9455 0.996338L16 3.08205L11.0545 0.996338L8.30546 5.55348L3.05455 6.71062L3.54909 11.9963L0 15.9963L3.54909 19.9821L3.05455 25.2678L8.30546 26.4392L11.0545 30.9963L16 28.8963L20.9455 30.9821L23.6945 26.4249L28.9455 25.2535L28.4509 19.9821L32 15.9963ZM13.2218 22.7392L7.69455 17.2963L9.84727 15.1821L13.2218 18.5106L21.7309 10.1249L23.8836 12.2392L13.2218 22.7392Z"
      fill={colors.primary.lilac}
    />
  </svg>
);
