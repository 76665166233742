import { Box, BoxProps } from "@chakra-ui/react";
import React, { useMemo } from "react";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";

export interface ILightProps extends BoxProps {
  isLit?: boolean;
}

// the 2 following calculation functions are for aligning the *corners* of inner and outer circles

const getPaddingForSquareInsideCircle = (containingSquare: number) => {
  // pythagorean theorem to calculate diagonal of square
  const diagonalLength = Math.sqrt(2 * (containingSquare * containingSquare));

  // hypotenuse length of right triangle in corner of containing square to circle
  const distanceFromCornerToCircle = (diagonalLength - containingSquare) / 2;

  // pythagorean theorem to calculate sides of right triangle, or x and y pading
  const sides = Math.sqrt(
    (distanceFromCornerToCircle * distanceFromCornerToCircle) / 2
  );

  // if used for x and y padding, corner of inner square till touch edge of outer circle
  return sides;
};

const getPaddingForCircleInsideCircle = (
  containingSquare: number,
  innerSquare: number
) => {
  // if used for x and y padding, edge of inner circle will touch edge of outer circle
  return (
    getPaddingForSquareInsideCircle(containingSquare) -
    getPaddingForSquareInsideCircle(innerSquare)
  );
};

export const Light: React.FC<ILightProps> = ({ isLit = true }) => {
  const { fontSizeHalf } = useBreakpoints();
  const bigBoardLightSize = fontSizeHalf;
  const lightSize = pxToRem(bigBoardLightSize);
  // *corner* of outer and inner circles touching
  const lightPadding = useMemo(
    () =>
      pxToRem(
        getPaddingForCircleInsideCircle(
          bigBoardLightSize,
          bigBoardLightSize / 2
        )
      ),
    [bigBoardLightSize]
  );

  const outerGlareSize = pxToRem(bigBoardLightSize / 2);
  // corner of square containing inner circle touching *corner* of outer circle
  const outerGlarePadding = useMemo(
    () => pxToRem(getPaddingForSquareInsideCircle(bigBoardLightSize / 2)),
    [bigBoardLightSize]
  );

  const lightPrimaryColor = isLit ? "light.golden" : "light.mediumGray";
  const outerGlareColor = isLit ? "light.lightGolden" : "light.lightGray";

  const innerGlareSize = pxToRem(bigBoardLightSize / 4);

  return (
    <Box
      bg={lightPrimaryColor}
      borderRadius="full"
      boxSize={lightSize}
      paddingLeft={lightPadding}
      paddingTop={lightPadding}
    >
      <Box
        bg={outerGlareColor}
        borderRadius="full"
        boxSize={outerGlareSize}
        paddingLeft={outerGlarePadding}
        paddingTop={outerGlarePadding}
      >
        <Box bg="light.white" borderRadius="full" boxSize={innerGlareSize} />
      </Box>
    </Box>
  );
};
