import { Box, Flex, Text } from "@chakra-ui/react";
import React, { useMemo } from "react";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { useSessionActions } from "links/lib/contexts/sessionActions";
import {
  ISessionDraggableSortItem,
  ISessionDraggableSortItemZone,
} from "links/lib/types";
import { Button } from "sessionComponents/atoms/Button";
import { Card } from "sessionComponents/atoms/Card";
import { Icon } from "sessionComponents/atoms/Icon";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { useStudent } from "sessionComponents/contexts/student";
import { useRichTextToSpeakable } from "sessionComponents/hooks/useRichTextToSpeakable";
import { useSendThrottledDragCoords } from "sessionComponents/hooks/useSendThrottledDragCoords";
import { useTextReader } from "sessionComponents/hooks/useTextReader";
import { RichTextRenderer } from "sharedComponents/atoms/RichTextRenderer";

import { DiagramDropZone } from "../DiagramDropZone";

interface IDiagramQuestionBoxProps {
  prompt?: string;
  imageUrl: string;
  imageAlt?: string;
  dropzones: ISessionDraggableSortItemZone[];
  dragsortItems: ISessionDraggableSortItem[];
}

export const DiagramQuestionBox: React.FC<IDiagramQuestionBoxProps> = ({
  prompt,
  imageUrl,
  imageAlt,
  dropzones,
  dragsortItems,
}) => {
  const {
    match: { fontSize, iconSize, padding },
  } = useBreakpoints();
  const student = useStudent();
  const { setSortChoiceZone } = useSessionActions();

  const sendThrottledCoords = useSendThrottledDragCoords();

  const { handleSpeak, handleCancelSpeak, isSpeaking, isSpeechEnabled } =
    useTextReader();
  const { convert } = useRichTextToSpeakable();

  const onSpeechClick = () => {
    if (isSpeaking()) {
      handleCancelSpeak();
      return;
    }

    const speakablePrompt = prompt ? convert(prompt) : "";

    handleSpeak(speakablePrompt);
  };

  const userIsDraggingItem = useMemo(() => {
    return dragsortItems.some((item) => {
      return item.is_dragging && item.user_id === student.id;
    });
  }, [dragsortItems, student.id]);

  return (
    <Box overflow="auto" padding={pxToRem(padding)}>
      <Card useThickBorder width="full" height="fit-content">
        {prompt && (
          <Flex>
            <Text
              textStyle="gameText"
              fontSize={pxToRem(fontSize)}
              textAlign="center"
              as="div"
            >
              <RichTextRenderer content={prompt} />
            </Text>
            {isSpeechEnabled && (
              <Box
                marginLeft={pxToRem(8)}
                display="flex"
                justifyContent="flex-end"
                h={pxToRem(iconSize)}
              >
                <Button
                  onClick={onSpeechClick}
                  variant="buttonFilled"
                  borderRadius="full"
                  height="unset"
                >
                  <Icon boxSize={pxToRem(iconSize)} icon="sound_on" />
                </Button>
              </Box>
            )}
          </Flex>
        )}

        <DiagramDropZone
          imageUrl={imageUrl}
          imageAlt={imageAlt}
          zones={dropzones}
          dragsortItems={dragsortItems}
          onDrop={setSortChoiceZone}
          handleHover={sendThrottledCoords.current}
          userIsDraggingItem={userIsDraggingItem}
        />
      </Card>
    </Box>
  );
};
