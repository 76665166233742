import {
  Box,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuProps,
  Portal,
} from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { IconType } from "adminComponents";
import {
  IconButton,
  IProps as IconButtonProps,
} from "adminComponents/atoms/IconButton";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils";

export interface MenuItemDetails {
  label?: ReactNode;
  handleClick?: React.MouseEventHandler<HTMLButtonElement>;
}

interface IProps extends Omit<MenuProps, "children"> {
  buttonAriaLabel?: string;
  icon?: IconType;
  menuItems: MenuItemDetails[];
  iconColor?: IconButtonProps["iconColor"];
  iconVariant?: IconButtonProps["variant"];
  boxSize?: number;
  usePortal?: boolean;
}

export const IconButtonDropdown: React.FC<IProps> = ({
  buttonAriaLabel,
  icon = "more_horiz",
  menuItems,
  iconColor,
  iconVariant = "default",
  boxSize = 32,
  usePortal = false,
  ...rest
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  if (menuItems.length === 0) {
    return <></>;
  }

  const menuList = (
    <MenuList zIndex="dropdown">
      {menuItems.map((item, index) => (
        <MenuItem key={index} onClick={item.handleClick}>
          <Text variant="adminP2" w="full">
            {item.label}
          </Text>
        </MenuItem>
      ))}
    </MenuList>
  );

  return (
    <Box maxWidth={pxToRem(boxSize)} zIndex={10}>
      <Menu computePositionOnMount {...rest} variant="adminDropdown">
        <MenuButton
          as={IconButton}
          icon={icon}
          shape="type1"
          iconColor={iconColor}
          variant={iconVariant}
          ariaLabel={buttonAriaLabel ? buttonAriaLabel : t("common.openMenu")}
          zIndex="2"
        />
        {usePortal ? <Portal>{menuList}</Portal> : menuList}
      </Menu>
    </Box>
  );
};
