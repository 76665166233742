import { Box } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Card } from "adminComponents/atoms/Card";
import { EmptyCard } from "adminComponents/molecules/EmptyCard";
import {
  ITableData,
  ITableSort,
} from "adminComponents/molecules/SortableTable";
import { StudentPlacementTable } from "adminComponents/molecules/SortableTable/StudentPlacementTable";
import { pxToRem } from "adminComponents/utils/pxToRem";

interface StudentsPerformanceTabProps {
  studentsData: ITableData;
  handleStudentSortChange: (sortData: ITableSort) => void;
}

export const StudentsPerformanceTab: React.FC<StudentsPerformanceTabProps> = ({
  studentsData,
  handleStudentSortChange,
}) => {
  const { t } = useTranslation("admin", {
    keyPrefix: "classDetailEmptyCards",
    useSuspense: false,
  });

  return (
    <Box py={[pxToRem(32), null, pxToRem(48)]}>
      {studentsData.length < 1 ? (
        <EmptyCard
          text={t("studentPerformanceText")}
          title={t("studentPerformanceTitle")}
        />
      ) : (
        <Card borderColor="primary.tan" variant="adminCardTable">
          <StudentPlacementTable
            data={studentsData}
            handleChangeSort={handleStudentSortChange}
          />
        </Card>
      )}
    </Box>
  );
};
