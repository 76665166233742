import { Box } from "@chakra-ui/react";
import React from "react";

import { SessionGameType } from "links/lib/types";

import { useDetectOrientation } from "../../theme/hooks/useDetectOrientation";
import BackgroundFlames from "../QuickPlayLogo/resource/quick_play_bg_pattern_flames.svg";
import Curtain from "./resource/Curtain.svg";
import PortraitCurtain from "./resource/PortraitCurtain.svg";
import QuickPlayStageBg from "./resource/QuickPlayStage.png";
import StageBg from "./resource/Stage.png";

export interface IStageProps {
  showCurtainOnly?: boolean;
  outerGame?: SessionGameType;
}

export const Stage: React.FC<IStageProps> = ({
  showCurtainOnly = false,
  children,
  outerGame = SessionGameType.TheBigBoard,
}) => {
  const { isLandscape } = useDetectOrientation();
  const isQuickPlay = outerGame === SessionGameType.QuickPlay;
  let aboveStage = isLandscape ? Curtain : PortraitCurtain;
  if (isQuickPlay) {
    aboveStage = BackgroundFlames;
  }

  return (
    <Box w="full" h="full" position="relative">
      <Box
        w="full"
        h={isLandscape && !showCurtainOnly ? "60%" : "full"}
        left="0"
        top="0"
        position="absolute"
        bgImage={aboveStage}
        bgColor="stage.fallbackCurtain"
        bgPosition="center top"
        bgSize={isQuickPlay ? "auto" : "cover"}
        bgRepeat={isQuickPlay ? "repeat" : "repeat-x"}
      />
      {!showCurtainOnly && (
        <Box
          w="full"
          h="40%"
          bottom="0"
          left="0"
          position="absolute"
          bgImage={isQuickPlay ? QuickPlayStageBg : StageBg}
          bgColor="stage.fallbackStage"
          bgPosition="center top"
          bgSize="cover"
          bgRepeat="repeat-x"
        />
      )}

      <Box w="100%" h="100%" minH="100%" position="relative">
        {children}
      </Box>
    </Box>
  );
};
