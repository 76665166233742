export default {
  variants: {
    contentWrapper: {
      pl: { sm: 4, md: 10 },
      pr: { sm: 4, md: 10 },
      maxW: "container.xl",
      h: "full",
    },
    viewHeader: {
      h: "10em",
      minHeight: "10em",
      borderBottomColor: "gray.200",
      borderBottomWidth: "1px",
      borderBottomStyle: "solid",
      p: 0,
      w: "full",
      maxW: "full",
    },
  },
};
