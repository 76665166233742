import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSessionStorage } from "react-use";
import { string } from "yup";

import {
  AddStudentDirectionsModal,
  DescriptionCase,
} from "adminComponents/organisms/AddStudentDirectionsModal";
import { GoogleClassroomAuthorizeModal } from "adminComponents/organisms/GoogleClassroomAuthorizeModal";
import { GoogleClassroomLinkConfirmModal } from "adminComponents/organisms/GoogleClassroomLinkConfirmModal";
import { GoogleClassroomPickerModal } from "adminComponents/organisms/GoogleClassroomPickerModal";
import {
  SelectStudentModal,
  StudentItem,
} from "adminComponents/organisms/SelectStudentModal";
import { generateStudentName } from "adminComponents/utils";
import { useErrorToast, useShowToast } from "adminComponents/utils/toast";
import { afterSignInPathSessionKey } from "lib/constants";
import { useAnalytics } from "lib/contexts/analytics";
import { useHubSpot } from "lib/hooks/useHubSpot";
import {
  useFetchGoogleClassroomClassrooms,
  useMutateClassroom,
  useMutateClassroomUsers,
} from "links/lib/features/classrooms";
import {
  AnalyticsEvent,
  GoogleClassroomClassroom,
  IClassroom,
  IUser,
  ThirdParty,
  UserRole,
  UsersGroupsRole,
} from "links/lib/types";
import { sortAlpha } from "links/lib/util";
import useFetchProviderRedirectUrl from "screens/SignIn/useFetchProviderRedirectUrl";

export interface IArgs {
  classroom: IClassroom;
  classroomStudents?: IUser[];
}

export interface IResult {
  handleAddIntent: () => void;
  authorizeModal: React.ReactElement;
  classroomSelectModal: React.ReactElement;
  studentSelectModal: React.ReactElement;
  confirmLinkGoogleClassroomModal: React.ReactElement;
  addStudentDirectionsModal: React.ReactElement;
}

const emailSchema = string().email();

export const useAddStudentsByGClassroom = ({
  classroom,
  classroomStudents,
}: IArgs): IResult => {
  const [isAuthorizeModalOpen, setIsAuthorizeModalOpen] =
    useState<boolean>(false);
  const [isClassroomSelectModalOpen, setIsClassroomSelectModalOpen] =
    useState<boolean>(false);
  const [isStudentSelectModalOpen, setIsStudentSelectModalOpen] =
    useState<boolean>(false);
  const [
    isConfirmLinkGoogleClassroomModalOpen,
    setIsConfirmLinkGoogleClassroomModalOpen,
  ] = useState<boolean>(false);
  const [isAddStudentDirectionsModalOpen, setIsAddStudentDirectionsModalOpen] =
    useState<boolean>(false);
  const [googleClassroom, setGoogleClassroom] =
    useState<GoogleClassroomClassroom>();
  const [selectableStudents, setSelectableStudents] = useState<
    Array<{ email: string; family_name: string; given_name: string }>
  >([]);
  const showToast = useShowToast();
  const { t } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "classroomDetailContainer.studentsTab.addByGClassroom",
  });
  const { trackEvent } = useAnalytics();
  const { trackHubSpotEvent } = useHubSpot();
  const [disableNotifications, setDisableNotifications] =
    useState<boolean>(false);

  const [, setSessionRedirectPath] = useSessionStorage(
    afterSignInPathSessionKey,
    "/"
  );

  const handleStudentMutateSuccess = useCallback(() => {
    setIsStudentSelectModalOpen(false);
    showToast(t("successToast"));

    trackHubSpotEvent(AnalyticsEvent.HubSpot_AddedStudentsToClassroom, {});
  }, [showToast, t, trackHubSpotEvent]);

  const handleClassroomMutationSuccess = useCallback(() => {
    setIsConfirmLinkGoogleClassroomModalOpen(false);
    setIsAddStudentDirectionsModalOpen(true);
    showToast(t("connectSuccessToast"));

    // TODO Hubspot?
  }, [showToast, t, setIsConfirmLinkGoogleClassroomModalOpen]);

  const gclassroomFetch = useFetchGoogleClassroomClassrooms();
  const redirectFetch = useFetchProviderRedirectUrl();
  const studentsMutation = useMutateClassroomUsers({
    onSuccess: handleStudentMutateSuccess,
  });
  const groupMutation = useMutateClassroom({
    onSuccess: handleClassroomMutationSuccess,
  });

  useErrorToast(gclassroomFetch.error);
  useErrorToast(redirectFetch.error);
  useErrorToast(studentsMutation.error);

  const handleAddIntent = useCallback(() => {
    setIsClassroomSelectModalOpen(true);

    trackEvent(
      AnalyticsEvent.TeacherDashboard_ClassroomDetail_StudentsTab_AddByGClassroomIntent,
      {
        classroomId: classroom.id,
      }
    );
  }, [trackEvent, classroom]);

  const handleAuthorizeClose = useCallback(() => {
    setIsAuthorizeModalOpen(false);
  }, []);

  const handleGrantAccess = useCallback(() => {
    if (!redirectFetch.data) return;

    trackEvent(
      AnalyticsEvent.TeacherDashboard_ClassroomDetail_StudentsTab_AddByGClassroomAuthorize,
      {
        classroomId: classroom.id,
      }
    );

    setSessionRedirectPath(window.location.pathname + window.location.search);
    window.location.href = redirectFetch.data.redirectUrl;
  }, [redirectFetch, setSessionRedirectPath, classroom, trackEvent]);

  const handleClassroomSelectModalClose = useCallback(() => {
    setIsClassroomSelectModalOpen(false);
  }, []);

  const handleConfirmLinkGoogleClassroomModalSubmit = useCallback(() => {
    if (!googleClassroom) {
      return;
    }

    groupMutation.mutate({
      id: classroom.id,
      name: classroom.name,
      description: classroom.description,
      third_party: ThirdParty.GOOGLE_CLASSROOM,
      third_party_id: googleClassroom.id,
      group_grade_levels: classroom.group_grade_levels,
      group_subjects: classroom.group_subjects,
      assignment_notifications_disabled:
        classroom.assignment_notifications_disabled,
    });
  }, [googleClassroom, groupMutation, classroom]);

  const handlePickGoogleClassroom = useCallback(
    (gclassroom: GoogleClassroomClassroom) => {
      setIsClassroomSelectModalOpen(false);
      setSelectableStudents(gclassroom.students);
      setIsStudentSelectModalOpen(true);
      setGoogleClassroom(gclassroom);

      trackEvent(
        AnalyticsEvent.TeacherDashboard_ClassroomDetail_StudentsTab_AddByGClassroomSelectClassroom,
        {
          classroomId: classroom.id,
        }
      );
    },
    [trackEvent, classroom]
  );

  const handleStudentSelectModalClose = useCallback(() => {
    setIsStudentSelectModalOpen(false);
  }, []);

  const handleStudentSelectSubmit = useCallback(
    (emails: Array<string>) => {
      const users = emails.map((email) => ({
        id: "0",
        email: email,
        role: UserRole.Student,
        true_role: UserRole.Student,
        license_id: "0",
        timezone_locale: "",
        language: "",
        is_guest: false,
        country: "",
        region: "",
      }));

      studentsMutation.mutate({
        users,
        classroomId: classroom.id,
        disableNotifications,
        usersGroupsRole: UsersGroupsRole.Member,
      });

      trackEvent(
        AnalyticsEvent.TeacherDashboard_ClassroomDetail_StudentsTab_AddByGClassroomConfirmStudents,
        {
          classroomId: classroom.id,
        }
      );

      if (classroom.third_party === ThirdParty.NONE) {
        setIsConfirmLinkGoogleClassroomModalOpen(true);
      } else {
        setIsAddStudentDirectionsModalOpen(true);
      }
    },
    [classroom, studentsMutation, trackEvent, disableNotifications]
  );

  const handleConfirmLinkGoogleClassroomModalClose = useCallback(() => {
    setIsConfirmLinkGoogleClassroomModalOpen(false);
    setIsAddStudentDirectionsModalOpen(true);
  }, []);

  useEffect(() => {
    if (
      gclassroomFetch.data?.requires_authorization &&
      isClassroomSelectModalOpen
    ) {
      setIsClassroomSelectModalOpen(false);
      setIsAuthorizeModalOpen(true);
      redirectFetch.execute({
        provider: "google_classroom",
        role: UserRole.Teacher,
      });
    }
  }, [
    gclassroomFetch.data?.requires_authorization,
    isClassroomSelectModalOpen,
    redirectFetch,
  ]);

  const sortedClassrooms = useMemo(() => {
    return (
      gclassroomFetch.data?.classrooms.sort((a, b) =>
        sortAlpha(a.name, b.name)
      ) || []
    );
  }, [gclassroomFetch.data?.classrooms]);

  const authorizeModal = useMemo(() => {
    return (
      <GoogleClassroomAuthorizeModal
        isOpen={isAuthorizeModalOpen}
        isLoading={redirectFetch.isLoading}
        handleGrantAccess={handleGrantAccess}
        handleClose={handleAuthorizeClose}
      />
    );
  }, [
    isAuthorizeModalOpen,
    handleAuthorizeClose,
    handleGrantAccess,
    redirectFetch.isLoading,
  ]);

  const classroomSelectModal = useMemo(() => {
    return (
      <GoogleClassroomPickerModal
        handleClose={handleClassroomSelectModalClose}
        handlePickGoogleClassroom={handlePickGoogleClassroom}
        isOpen={isClassroomSelectModalOpen}
        isLoading={gclassroomFetch.isLoading}
        googleClassrooms={sortedClassrooms}
      />
    );
  }, [
    handleClassroomSelectModalClose,
    handlePickGoogleClassroom,
    isClassroomSelectModalOpen,
    gclassroomFetch.isLoading,
    sortedClassrooms,
  ]);

  const confirmLinkGoogleClassroomModal = useMemo(() => {
    return (
      <GoogleClassroomLinkConfirmModal
        isOpen={isConfirmLinkGoogleClassroomModalOpen}
        classroom={classroom}
        googleClassroom={googleClassroom}
        handleClose={handleConfirmLinkGoogleClassroomModalClose}
        handleConfirm={handleConfirmLinkGoogleClassroomModalSubmit}
      />
    );
  }, [
    isConfirmLinkGoogleClassroomModalOpen,
    classroom,
    googleClassroom,
    handleConfirmLinkGoogleClassroomModalClose,
    handleConfirmLinkGoogleClassroomModalSubmit,
  ]);

  const initialSelectedStudents = useMemo(() => [], []);

  const unselectableStudents = useMemo(() => {
    if (!classroomStudents) {
      return [];
    }
    const gClassroomStudentsMap = new Map<string, boolean>();
    selectableStudents.forEach((student) =>
      gClassroomStudentsMap.set(student.email, true)
    );

    const gsStudentsNotInGoogle: IUser[] = [];

    classroomStudents.forEach((student) => {
      if (!gClassroomStudentsMap.has(student.email)) {
        gsStudentsNotInGoogle.push(student);
      }
    });

    return gsStudentsNotInGoogle;
  }, [classroomStudents, selectableStudents]);

  const studentSelectModal = useMemo(() => {
    return (
      <SelectStudentModal
        classroom={classroom}
        isOpen={isStudentSelectModalOpen}
        isLoading={studentsMutation.isLoading}
        selectedStudentIds={initialSelectedStudents}
        handleClose={handleStudentSelectModalClose}
        handleSubmit={handleStudentSelectSubmit}
        unselectableStudents={unselectableStudents}
        disableNotifications={disableNotifications}
        setDisableNotifications={setDisableNotifications}
      >
        {selectableStudents.map((s, i) => {
          const name = generateStudentName({
            given_name: s.given_name,
            family_name: s.family_name,
          });

          const isInvalid = s.email === "" || !emailSchema.isValidSync(s.email);

          return (
            <StudentItem
              key={`student-${s.email}-${i}`}
              id={s.email}
              displayName={`${name.primary} (${s.email})`}
              isInvalid={isInvalid}
            />
          );
        })}
      </SelectStudentModal>
    );
  }, [
    handleStudentSelectModalClose,
    handleStudentSelectSubmit,
    classroom,
    isStudentSelectModalOpen,
    selectableStudents,
    studentsMutation.isLoading,
    initialSelectedStudents,
    unselectableStudents,
    disableNotifications,
  ]);

  const addStudentDirectionsModal = useMemo(
    () => (
      <AddStudentDirectionsModal
        isOpen={isAddStudentDirectionsModalOpen}
        handleClose={() => setIsAddStudentDirectionsModalOpen(false)}
        descriptionCase={
          disableNotifications
            ? DescriptionCase.GOOGLE_NO_NOTIFICATION
            : DescriptionCase.GOOGLE
        }
      />
    ),
    [isAddStudentDirectionsModalOpen, disableNotifications]
  );

  return useMemo(() => {
    return {
      handleAddIntent,
      authorizeModal,
      classroomSelectModal,
      studentSelectModal,
      confirmLinkGoogleClassroomModal,
      addStudentDirectionsModal,
    };
  }, [
    authorizeModal,
    handleAddIntent,
    classroomSelectModal,
    studentSelectModal,
    confirmLinkGoogleClassroomModal,
    addStudentDirectionsModal,
  ]);
};
