import {
  Box,
  Flex,
  FormControl,
  Image,
  InputGroup,
  InputRightElement,
  SimpleGrid,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";

import { Button } from "adminComponents/atoms/Button";
import { FormLabel } from "adminComponents/atoms/FormLabel";
import { Icon } from "adminComponents/atoms/Icon";
import { IconTooltip } from "adminComponents/atoms/IconTooltip";
import { Input } from "adminComponents/atoms/Input";
import { PremiumTooltipRich } from "adminComponents/atoms/PremiumTooltipRich";
import { Text } from "adminComponents/atoms/Text";
import { Textarea } from "adminComponents/atoms/Textarea";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { useAnalytics } from "lib/contexts/analytics";
import { MAX_ALT_TEXT_LENGTH } from "links/lib/constants";
import { useAuth } from "links/lib/features/auth";
import { AnalyticsEvent } from "links/lib/types";

interface UrlUploadProps {
  loading: boolean;
  uploading: boolean;
  uploadError: boolean;
  imageUrlToAdd: string;
  imageAltText?: string;
  videoUrlToAdd?: string;
  invalidUrl: boolean;
  handleUploadImageFromUrl: (
    link: string,
    imageOnly?: boolean
  ) => Promise<void>;
  handleInsertMedia: (
    imageUrl: string,
    altText: string,
    audioUrl?: string,
    videoUrl?: string
  ) => void;
  handleRemoveImage: () => void;
  imageOnly?: boolean;
}

export const UrlUpload: React.FC<UrlUploadProps> = ({
  imageUrlToAdd,
  imageAltText = "",
  videoUrlToAdd,
  loading,
  uploading,
  uploadError,
  invalidUrl,
  handleUploadImageFromUrl,
  handleInsertMedia,
  handleRemoveImage,
  imageOnly = false,
}) => {
  const { t: tCommon } = useTranslation("admin", {
    keyPrefix: "common",
    useSuspense: false,
  });
  const { t } = useTranslation("admin", {
    keyPrefix: "addMediaModal.urlUpload",
    useSuspense: false,
  });
  const [altText, setAltText] = useState(imageAltText);
  const [savedUrl, setSavedUrl] = useState("");
  const { trackEvent } = useAnalytics();
  const { hasNoPremiumAccess } = useAuth();

  const urlIsStaged = !!imageUrlToAdd || (!imageOnly && !!videoUrlToAdd);

  const handleSubmitUrl = () => {
    if (!savedUrl) return;

    trackEvent(
      AnalyticsEvent.TeacherDashboard_PracticeSetDetail_QuestionFlyout_AddMediaModal_UrlUploadIntent,
      {}
    );
    handleUploadImageFromUrl(savedUrl, imageOnly);
  };

  const handleUrlKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    if (ev.key === "Enter") {
      handleSubmitUrl();
    }
  };

  return (
    <SimpleGrid gap={pxToRem(24)}>
      <Flex
        rowGap={pxToRem(12)}
        columnGap={pxToRem(20)}
        alignItems="center"
        flexWrap={["wrap", null, "nowrap"]}
      >
        <Box flex={[1]}>
          <FormControl isInvalid={invalidUrl}>
            <InputGroup>
              <Input
                flex={1}
                value={savedUrl}
                placeholder={
                  imageOnly
                    ? t("pasteUrlPlaceholderImageOnly")
                    : t("pasteUrlPlaceholder")
                }
                onChange={(e) => setSavedUrl(e.target.value)}
                onKeyDown={handleUrlKeyDown}
                isDisabled={uploading || urlIsStaged || hasNoPremiumAccess}
              />
              <InputRightElement
                width="auto"
                right={pxToRem(16)}
                top={pxToRem(4)}
              >
                <Button
                  size="sm"
                  variant="adminButtonFilled"
                  onClick={handleSubmitUrl}
                  disabled={!savedUrl || urlIsStaged}
                >
                  {tCommon("fetch")}
                </Button>
              </InputRightElement>
            </InputGroup>
          </FormControl>
        </Box>
      </Flex>
      <Box>
        {/* Image/video is staged, render a preview */}
        {urlIsStaged && (
          <Flex
            backgroundColor="primary.white"
            borderColor="primary.medium-gray"
            width="full"
            height={[pxToRem(328), null, pxToRem(360)]}
            paddingX={pxToRem(15)}
            paddingY={[pxToRem(22), null, pxToRem(26)]}
            borderWidth={pxToRem(1)}
            justifyContent="center"
          >
            {imageUrlToAdd && <Image src={imageUrlToAdd} h="100%" w="auto" />}
            {!imageOnly && videoUrlToAdd && (
              <ReactPlayer height="100%" url={videoUrlToAdd} controls={true} />
            )}
          </Flex>
        )}
        {(invalidUrl || uploadError) && (
          <Text
            variant="adminP2"
            color="utility.error"
            mt={pxToRem(8)}
            textAlign="center"
          >
            {invalidUrl ? t("invalidUrl") : t("uploadError")}
          </Text>
        )}
        <Flex mt={pxToRem(12)} h={pxToRem(20)} justifyContent="flex-end">
          {urlIsStaged && (
            <Button
              variant="adminTextButtonMedium"
              onClick={handleRemoveImage}
              isDisabled={loading}
            >
              <Text variant="adminLinkMedium" color="utility.link">
                {imageUrlToAdd ? t("removeImage") : t("removeVideo")}
              </Text>
            </Button>
          )}
        </Flex>
      </Box>
      <Flex
        flexDir={["column", null, "row"]}
        alignItems={["center", null, "flex-end"]}
        gap={[pxToRem(32), null, pxToRem(40)]}
      >
        <FormControl isDisabled={!imageUrlToAdd || loading}>
          <FormLabel>
            <Flex as="span" gap={pxToRem(4)} alignItems="center">
              {t("altText")}
              <IconTooltip isDisabled={!imageUrlToAdd || loading}>
                {t("addAltTextDescription")}
              </IconTooltip>
            </Flex>
          </FormLabel>
          <Textarea
            value={altText}
            onChange={(e) => setAltText(e.target.value)}
            placeholder={t("addAltTextPlaceholder")}
            maxLength={MAX_ALT_TEXT_LENGTH}
          />
        </FormControl>
        <PremiumTooltipRich isDisabled={!hasNoPremiumAccess}>
          <Box>
            <Button
              w={[pxToRem(138), null, pxToRem(160)]}
              variant="adminButtonFilled"
              size="lg"
              isDisabled={hasNoPremiumAccess || !urlIsStaged}
              isLoading={loading}
              leftIcon={hasNoPremiumAccess ? <Icon icon="lock" /> : undefined}
              onClick={
                hasNoPremiumAccess
                  ? undefined
                  : () => {
                      trackEvent(
                        AnalyticsEvent.TeacherDashboard_PracticeSetDetail_QuestionFlyout_AddMediaModal_UrlUploadConfirm,
                        {}
                      );
                      handleInsertMedia(
                        imageUrlToAdd,
                        altText,
                        undefined,
                        videoUrlToAdd
                      );
                    }
              }
            >
              {t("addMedia")}
            </Button>
          </Box>
        </PremiumTooltipRich>
      </Flex>
    </SimpleGrid>
  );
};
