import { Box, VStack } from "@chakra-ui/react";
import EventEmitter from "events";
import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { animated, config, useTransition } from "react-spring";

import { usePageTrack } from "lib/contexts/analytics";
import { useSession } from "links/lib/contexts/session";
import { useSessionActions } from "links/lib/contexts/sessionActions";
import { useSessionConnectedUsers } from "links/lib/contexts/sessionConnectedUsers";
import { useSessionEvents } from "links/lib/contexts/sessionEvents";
import { useSessionGameState } from "links/lib/contexts/sessionGameState";
import { useSessionRoundState } from "links/lib/contexts/sessionRoundState";
import { SessionRoundThemeProvider } from "links/lib/contexts/sessionRoundTheme";
import { useSessionScene } from "links/lib/contexts/sessionScene";
import { useSessionUsers } from "links/lib/contexts/sessionUsers";
import { useGenerateSessionRoundTheme } from "links/lib/features/sessionRoundTheme";
import {
  AnalyticsPage,
  ISessionUser,
  ISessionUsers,
  IUser,
  SessionGameState,
  SessionGameType,
  SessionScene,
  SessionWebSocketEvent,
} from "links/lib/types";
import { RoundReviewProvider } from "sessionComponents/contexts/roundReview";
import { StudentProvider } from "sessionComponents/contexts/student";
import {
  StudentCursorsProvider,
  useStudentCursors,
} from "sessionComponents/contexts/studentCursors";
import { StudentReactionsProvider } from "sessionComponents/contexts/studentReactions";
import { StudentStickersProvider } from "sessionComponents/contexts/studentStickers";
import { useGroupUsers } from "sessionComponents/hooks/useGroupUsers";
import { useHeaderTitleText } from "sessionComponents/hooks/useHeaderTitleText";
import { StudentHeader } from "sessionComponents/organisms/StudentHeader";
import { BigBoardSpin } from "sessionComponents/scenes/BigBoardSpin";
import { BigBoardTokenPlacementScene } from "sessionComponents/scenes/BigBoardTokenPlacement";
import { GameInstructions } from "sessionComponents/scenes/GameInstructions";
import { LeaderBoard } from "sessionComponents/scenes/LeaderBoard";
import { Podium } from "sessionComponents/scenes/Podium";
import { PrizeRoundAwards } from "sessionComponents/scenes/PrizeRoundAwards";
import { StudentLobby } from "sessionComponents/scenes/StudentLobby";
import { StudentTeamUp } from "sessionComponents/scenes/StudentTeamUp";
import { fullScreenStyles } from "sessionComponents/theme";
import { getAreStickersDisabled } from "sessionComponents/utils/getAreStickersDisabled";
import { CarePackageProvider } from "sharedComponents/contexts/carePackage";
import { SessionAnalyticsProvider } from "sharedComponents/contexts/sessionAnalytics";
import {
  SessionAudioProvider,
  useSessionAudio,
} from "sharedComponents/contexts/sessionAudio";
import "sharedComponents/css/ios-full-height.css";
import StudentSessionAudioSpriteDetail from "sharedComponents/resource/audio/student/output.json";
import StudentSessionAudioSpriteSrcMP3 from "sharedComponents/resource/audio/student/output.mp3";
import StudentSessionAudioSpriteSrcWebM from "sharedComponents/resource/audio/student/output.webm";

import { StudentQuestionRound } from "../shared/StudentQuestionRound";
import { StudentRoundReviewWrapper } from "../shared/StudentRoundReviewWrapper";

interface ISessionStudentProps {
  authUser: IUser;
  refetchUser: () => void;
  enableUnloadWarning: () => void;
  disableUnloadWarning: () => void;
}

const AnimatedBox = animated(Box);

export const StudentView: React.FC<ISessionStudentProps> = (
  props: ISessionStudentProps
) => {
  const { authUser, refetchUser, enableUnloadWarning, disableUnloadWarning } =
    props;
  const scene = useSessionScene();
  const users = useSessionUsers();
  const connectedUsers = useSessionConnectedUsers();
  const gameState = useSessionGameState();
  const roundState = useSessionRoundState();
  const { starts_at, ends_at, round_number } = roundState;
  const events = useSessionEvents();
  const session = useSession();

  const student = users[authUser.id];

  usePageTrack(AnalyticsPage.Session_ClassSession_Student);

  const roundTheme = useGenerateSessionRoundTheme(
    round_number,
    gameState?.game_type
  );

  const isRound = scene === SessionScene.Round;
  const hasTimes = !!starts_at && !!ends_at;
  const showTimer = isRound && hasTimes && starts_at !== ends_at;

  const timer = showTimer
    ? { startsAt: starts_at, endsAt: ends_at }
    : undefined;

  if (!events) return null;
  return (
    <StudentProvider userId={authUser.id}>
      <SessionAnalyticsProvider
        student={student}
        session={session}
        round_state={roundState}
        scene={scene}
      >
        <SessionAudioProvider
          srcVolume={1}
          spriteSrc={[
            StudentSessionAudioSpriteSrcWebM,
            StudentSessionAudioSpriteSrcMP3,
          ]}
          sprite={StudentSessionAudioSpriteDetail.sprite}
        >
          <SessionRoundThemeProvider roundTheme={roundTheme}>
            <CarePackageProvider events={events}>
              <StudentReactionsProvider>
                <StudentStickersProvider student={student}>
                  <StudentCursorsProvider student={student}>
                    <RoundReviewProvider>
                      <StudentScene
                        enableUnloadWarning={enableUnloadWarning}
                        disableUnloadWarning={disableUnloadWarning}
                        scene={scene}
                        authUser={authUser}
                        refetchUser={refetchUser}
                        timer={timer}
                        student={student}
                        users={connectedUsers}
                        events={events}
                        gameState={gameState}
                        roundNumber={round_number}
                      />
                    </RoundReviewProvider>
                  </StudentCursorsProvider>
                </StudentStickersProvider>
              </StudentReactionsProvider>
            </CarePackageProvider>
          </SessionRoundThemeProvider>
        </SessionAudioProvider>
      </SessionAnalyticsProvider>
    </StudentProvider>
  );
};

const StudentScene = ({
  enableUnloadWarning,
  disableUnloadWarning,
  scene,
  authUser,
  refetchUser,
  timer,
  student,
  users,
  events,
  gameState,
  roundNumber,
}: {
  enableUnloadWarning: () => void;
  disableUnloadWarning: () => void;
  scene?: SessionScene;
  authUser: IUser;
  refetchUser: () => void;
  timer?: {
    startsAt: string;
    endsAt: string;
  };
  student?: ISessionUser;
  users: ISessionUsers;
  events: EventEmitter;
  gameState?: SessionGameState;
  roundNumber: number;
}) => {
  const history = useHistory();

  const headerTitleText = useHeaderTitleText({
    isTeacher: false,
    userId: authUser.id,
  });

  const {
    isCursorsVisible,
    setCursorsVisible,
    setCursorsEnabled,
    isCursorsEnabled,
  } = useStudentCursors();
  const { setVolume, volume } = useSessionAudio();
  const { exitGame } = useSessionActions();

  const sessionGroupId = student?.session_group_id || "0";
  const isQuickPlay = gameState?.game_type === SessionGameType.QuickPlay;
  const isQuickPlayCarePackageRound =
    isQuickPlay &&
    gameState.quick_play_state.care_package_rounds.includes(roundNumber);

  const groupUsers = useGroupUsers(sessionGroupId);

  // hide cursors if session hasn't started, or user is only member of group, or scene is not Round
  useEffect(() => {
    if (groupUsers.length <= 1 || scene !== SessionScene.Round) {
      if (isCursorsEnabled) setCursorsEnabled(false);
    } else {
      if (groupUsers.length > 1 && scene === SessionScene.Round) {
        if (!isCursorsEnabled) {
          setCursorsEnabled(true);
        }
      }
    }
  }, [
    groupUsers.length,
    student,
    users,
    isCursorsEnabled,
    setCursorsEnabled,
    scene,
  ]);

  const renderScene = (scene?: SessionScene): JSX.Element => {
    if (!scene) return <></>;
    switch (scene) {
      case SessionScene.Grouping:
        return <StudentTeamUp />;
      case SessionScene.LeaderBoard:
        return <LeaderBoard isTeacher={false} />;
      case SessionScene.Podium:
        return <Podium isTeacher={false} outerGame={gameState?.game_type} />;
      case SessionScene.OuterGameIntro:
        return <GameInstructions isTeacher={false} />;
      case SessionScene.OuterGame:
        return <BigBoardTokenPlacementScene isTeacher={false} />;
      case SessionScene.PrizeRound:
        return <BigBoardSpin isTeacher={false} />;
      case SessionScene.PrizeRoundAwards:
        return <PrizeRoundAwards isTeacher={false} />;
      case SessionScene.Round:
      case SessionScene.Voting:
        return (
          <StudentQuestionRound
            gameState={gameState}
            disableRoundIntro={!isQuickPlayCarePackageRound}
          />
        );
      case SessionScene.RoundReview:
      case SessionScene.VotingResults:
      case SessionScene.VotingAwards:
        return <StudentRoundReviewWrapper isClassSession />;
      case SessionScene.Lobby:
        return (
          <StudentLobby
            authUser={authUser}
            refetchUser={refetchUser}
            enableUnloadWarning={enableUnloadWarning}
            disableUnloadWarning={disableUnloadWarning}
          />
        );
    }

    return <>Scene not implemented: {scene}</>;
  };

  const sceneTransition = useTransition([scene], {
    from: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
    },
    leave: {
      opacity: 1,
    },
    exitBeforeEnter: false,
    config: config.slow,
  });

  const onToggleSound = (enabled: boolean) => {
    if (enabled) {
      setVolume(1);
    } else {
      setVolume(0);
    }
  };

  const handleExitGame = () => {
    exitGame();
  };

  const redirectToHome = useCallback(() => {
    history.push("/");
  }, [history]);

  useEffect(() => {
    events.on(SessionWebSocketEvent.ExitGameResponse, redirectToHome);

    return () => {
      events.off(SessionWebSocketEvent.ExitGameResponse, redirectToHome);
    };
  }, [redirectToHome, events]);

  const isEndOfGame = scene === SessionScene.Podium;

  return sceneTransition((style, s) => (
    <VStack {...fullScreenStyles} spacing="0">
      <StudentHeader
        headingText={headerTitleText}
        cursorsVisible={isCursorsVisible}
        onToggleCursors={setCursorsVisible}
        isSoundEnabled={!!volume}
        onToggleSound={onToggleSound}
        timer={timer}
        hideCursorButton={!isCursorsEnabled}
        hideStickers={getAreStickersDisabled(scene)}
        isEndOfGame={isEndOfGame}
        onExit={handleExitGame}
        showTeamButton={!!sessionGroupId && sessionGroupId !== "0"}
        hide={s === SessionScene.Lobby}
      />
      <AnimatedBox
        zIndex={s === scene ? "auto" : "-1"}
        w="full"
        h="full"
        overflow="hidden"
        style={style}
      >
        {renderScene(s)}
      </AnimatedBox>
    </VStack>
  ));
};
