import { UseQueryResult, useQuery } from "react-query";

import { IUser } from "links/lib/types";

import { useAxios } from "../../hooks/useAxios";

export interface IFetchTopIndividualPracticersCountResponse {
  top_individual_practicers: Array<{
    user: IUser;
    sessions_count: number;
  }>;
}

export interface IUseFetchTopIndividualPracticersCountArgs {
  period_days?: number;
  limit?: number;
  enabled?: boolean;
}

/**
 * Fetches an ordered list of students who have started the
 * most individual sessions over the last period_days.
 * @returns
 */
export default function useFetchTopIndividualPracticersCount(
  args: IUseFetchTopIndividualPracticersCountArgs
): UseQueryResult<IFetchTopIndividualPracticersCountResponse, unknown> {
  const { period_days = 30, limit = 10, enabled = true } = args;
  const params = { period_days, limit };

  const axios = useAxios();

  const query = useQuery<IFetchTopIndividualPracticersCountResponse>(
    ["topIndividualPracticers", params],
    async (): Promise<IFetchTopIndividualPracticersCountResponse> => {
      return await axios
        .get("/v1/practice-sets/sessions/individual/top", {
          params,
        })
        .then((r) => r.data as IFetchTopIndividualPracticersCountResponse);
    },
    {
      enabled,
    }
  );

  return query;
}
