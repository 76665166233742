import { Box, HStack, Stack, VStack } from "@chakra-ui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { animated, useSpring } from "react-spring";
import { usePrevious } from "react-use";

import { Button } from "adminComponents/atoms/Button";
import { Heading } from "adminComponents/atoms/Heading";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils";
import { UserRole } from "links/lib/types";

export interface ISignInAsModuleProps {
  isButtonsDisabled?: boolean;
  isButtonsLoading?: boolean;
  welcomeMessage?: string;
  handleSignInAsStudent: () => void;
  handleSignInAsTeacher: () => void;
}

export interface IRadioButtonProps {
  selected?: boolean;
  handleSelect?: () => void;
}

export const RadioButton: React.FC<IRadioButtonProps> = ({
  children,
  handleSelect,
  selected = false,
}) => {
  return (
    <Button
      px={pxToRem(28)}
      py={pxToRem(14)}
      backgroundColor="transparent"
      borderWidth={pxToRem(1)}
      borderColor={selected ? "transparent" : "primary.medium-gray"}
      boxShadow={
        selected
          ? `0 0 0 ${pxToRem(3)} inset var(--chakra-colors-primary-golden)`
          : undefined
      }
      borderRadius="full"
      h="auto"
      _hover={{
        backgroundColor: "transparent",
      }}
      _active={{
        backgroundColor: "transparent",
      }}
      _focusVisible={{
        bg: "transparent",
        boxShadow: `0 0 0 ${pxToRem(
          3
        )} inset var(--chakra-colors-utility-focus)`,
      }}
      _focus={{
        boxShadow: selected
          ? `0 0 0 ${pxToRem(3)} inset var(--chakra-colors-primary-golden)`
          : "none",
      }}
      onClick={handleSelect}
    >
      <HStack
        as="span"
        justifyContent="center"
        alignItems="center"
        gap={pxToRem(6)}
      >
        <Box
          as="span"
          display="inline-flex"
          alignItems="center"
          justifyContent="center"
          flexShrink={0}
          borderRadius="full"
          w={pxToRem(24)}
          h={pxToRem(24)}
          borderWidth={pxToRem(1)}
          borderStyle="solid"
          borderColor="primary.medium-gray"
          _before={{
            content: '""',
            display: "inline-block",
            position: "relative",
            width: pxToRem(16),
            height: pxToRem(16),
            borderRadius: "50%",
            background: selected ? "primary.golden" : "white",
            transition: "background 200ms ease-in-out",
          }}
        />
        <Text as="span" variant="adminP1Bold">
          {children}
        </Text>
      </HStack>
    </Button>
  );
};

const AnimatedHeading = animated(Heading);

export const SignInAsModule: React.FC<ISignInAsModuleProps> = ({
  handleSignInAsStudent,
  handleSignInAsTeacher,
  welcomeMessage,
  isButtonsDisabled,
  isButtonsLoading,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const [selectedRole, setSelectedRole] = useState<UserRole | undefined>(
    undefined
  );

  const handleSignInClick = () => {
    if (!selectedRole) return;

    if (selectedRole === UserRole.Student) handleSignInAsStudent();
    else handleSignInAsTeacher();
  };

  let buttonText = t("signInAsScreen.pendingButton");

  if (selectedRole === UserRole.Student) {
    buttonText = t("signInAsScreen.studentButton");
  } else if (selectedRole === UserRole.Teacher) {
    buttonText = t("signInAsScreen.teacherButton");
  }

  const description =
    !!selectedRole && selectedRole !== UserRole.Student
      ? t("signInAsScreen.descriptionEducator")
      : t("signInAsScreen.description");
  const prevDescription = usePrevious(description);

  const animatedStyle = useSpring({
    from: { opacity: 0, transform: "translate3d(100%,0,0)" },
    to: { opacity: 1, transform: "translate3d(0%,0,0)" },
    reset: description !== prevDescription,
  });

  return (
    <VStack w="full" gap={[pxToRem(16), null, pxToRem(32)]}>
      {welcomeMessage && (
        <Heading as="h2" variant="adminH4" textAlign="center">
          {welcomeMessage}
        </Heading>
      )}
      <AnimatedHeading
        style={animatedStyle}
        as="h1"
        variant="adminH3"
        textAlign="center"
      >
        {description}
      </AnimatedHeading>
      <Box
        borderColor="primary.tan"
        borderStyle="solid"
        borderWidth={pxToRem(3)}
        background="white"
        width={[null, null, pxToRem(500)]}
        borderRadius={pxToRem(20)}
        overflow="hidden"
        p={pxToRem(32)}
      >
        <VStack
          w="full"
          alignItems="center"
          justifyContent="center"
          gap={pxToRem(32)}
        >
          <Stack
            w="full"
            justifyContent="center"
            alignItems="center"
            gap={[pxToRem(12), null, pxToRem(24)]}
            direction={["column", null, "row"]}
          >
            <RadioButton
              handleSelect={() => setSelectedRole(UserRole.Student)}
              selected={selectedRole === UserRole.Student}
            >
              {t("signInAsScreen.studentRadio")}
            </RadioButton>
            <RadioButton
              handleSelect={() => setSelectedRole(UserRole.Teacher)}
              selected={selectedRole === UserRole.Teacher}
            >
              {t("signInAsScreen.teacherRadio")}
            </RadioButton>
          </Stack>
          <Button
            size="lg"
            variant="adminButtonFilled"
            disabled={!selectedRole || isButtonsDisabled}
            onClick={handleSignInClick}
            isLoading={isButtonsLoading}
          >
            {buttonText}
          </Button>
        </VStack>
      </Box>
    </VStack>
  );
};
