import { Box } from "@chakra-ui/react";
import { isEqual } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { AvatarAnimations } from "links/lib/constants";
import { IAvatarAnimation, ISessionUser } from "links/lib/types";
import { getNRandomArrayElements, getRandomArrayElement } from "links/lib/util";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { useBoundingRect } from "sessionComponents/hooks/useBoundingRect";
import { useDetectOrientation } from "sessionComponents/theme/hooks/useDetectOrientation";
import {
  AvatarStage,
  AvatarStageMember,
} from "sharedComponents/molecules/AvatarStage";

export interface IAvatarLayoutProps {
  students: Array<ISessionUser>;
  candidateAnimations?: Array<IAvatarAnimation>;
  selfId: string;
  showNametags?: boolean;
  yPosition?: number;
  upperBodyZoom?: boolean;
  additionalScaleFactor?: number;
  // true if the team got the care package question 100% correct
  earnedQuickPlayCarePackage?: boolean;
}

const stageStyle = {
  width: "100%",
  maxWidth: "100%",
  height: "100%",
  maxHeight: "100%",
};

export const AvatarLayout: React.FC<IAvatarLayoutProps> = ({
  students: initialStudents,
  selfId,
  candidateAnimations,
  showNametags = true,
  yPosition = -200,
  upperBodyZoom = false,
  additionalScaleFactor,
  earnedQuickPlayCarePackage = false,
}) => {
  if (initialStudents.length > 3) {
    console.warn("AvatarLayout should only be used with <= 3 students");
  }

  const [students, setStudents] = useState(initialStudents);

  useEffect(() => {
    if (!isEqual(initialStudents, students)) {
      setStudents(initialStudents);
    }
  }, [initialStudents, students]);

  const { isPortrait, isLandscape } = useDetectOrientation();

  const [
    stageContainerRef,
    {
      width: stageContainerWidth,
      height: stageContainerHeight,
      top: stageContainerTop,
    },
  ] = useBoundingRect<HTMLDivElement>();
  const [
    targetContainerRef,
    {
      width: targetContainerWidth,
      height: targetContainerHeight,
      top: targetContainerTop,
      left: targetContainerLeft,
    },
  ] = useBoundingRect<HTMLDivElement>();
  const { match: currentBreakpoints, size } = useBreakpoints();

  const lgScreenSize = isLandscape && ["lg", "xl"].includes(size);
  const smScreenSize = isPortrait && ["sm", "md"].includes(size);
  let avatarScale = lgScreenSize ? 0.6 : smScreenSize ? 0.4 : 0.5;

  if (upperBodyZoom) {
    avatarScale = smScreenSize ? 0.4 : lgScreenSize ? 0.8 : 0.6;
  } else if (additionalScaleFactor) {
    avatarScale *= additionalScaleFactor;
  }

  const camera = useMemo(() => {
    let y = 0;
    if (upperBodyZoom) {
      y = smScreenSize ? 75 : lgScreenSize ? 300 : 225;
    }

    return { x: 0, y, z: 0, zoom: 1 };
  }, [smScreenSize, lgScreenSize, upperBodyZoom]);

  const { t } = useTranslation("session", {
    keyPrefix: "avatarLayout",
    useSuspense: false,
  });

  const stageSize = useMemo(
    () => ({
      x: Math.floor(stageContainerWidth),
      y: Math.floor(stageContainerHeight),
    }),
    [stageContainerWidth, stageContainerHeight]
  );

  const studentAnimations = useMemo(() => {
    let remainingAnimations = [
      ...(candidateAnimations || AvatarAnimations.Idle),
    ];

    return students.map(() => {
      let animations = [];
      [animations, remainingAnimations] = getNRandomArrayElements(
        remainingAnimations,
        2
      );
      return animations;
    });
  }, [students, candidateAnimations]);

  const getAvatarPosition = useCallback(
    (studentIndex: number) => {
      // get the x offset by finding the target container offset relative to the stage container
      const xOffset =
        targetContainerLeft +
        targetContainerWidth / 2 -
        stageContainerWidth / 2;

      const targetYMid = targetContainerTop + targetContainerHeight / 2;
      const yOffset = stageContainerTop + stageContainerHeight / 2 - targetYMid;
      // shift y relative to target top
      const avatarYPos = yPosition + yOffset;

      // if only one student on screen, just position in the center of the screen
      if (students.length === 1) return { x: xOffset, y: avatarYPos };

      // get midpoint of each section
      const sectionMid = targetContainerWidth / (students.length + 1);

      // get the midpoint of the current section for given student index
      // all positions are relative to center of container, so need to calculate based on that
      const currentSectionMidpoint =
        sectionMid * (studentIndex + 1) - targetContainerWidth / 2;

      // shift x relative to target left and desired midpoint
      const avatarXPos = currentSectionMidpoint + xOffset;

      return {
        x: avatarXPos,
        y: avatarYPos,
      };
    },
    [
      students.length,
      stageContainerWidth,
      stageContainerHeight,
      yPosition,
      targetContainerHeight,
      targetContainerLeft,
      targetContainerTop,
      targetContainerWidth,
      stageContainerTop,
    ]
  );

  const getDisplayName = useCallback(
    (name: string) => {
      if (isPortrait) return getAvatarInitials(name);

      return name
        .split(" ")
        .map((namePiece, index, nameArray) => {
          if (index === nameArray.length - 1) {
            return namePiece.charAt(0).toUpperCase() + ".";
          } else {
            return namePiece;
          }
        })
        .join(" ");
    },
    [isPortrait]
  );

  const getAvatarInitials = (name: string) =>
    name
      .split(" ")
      .map((s) => s.trim())
      .filter((s) => s.length)
      .map((s) => s.charAt(0) + ".")
      .join("")
      .toUpperCase();

  const selectedAnimations = useMemo(() => {
    return students.map((s, i) => {
      return getRandomArrayElement(studentAnimations[i]);
    });
  }, [studentAnimations, students]);

  const [visibilityState, setVisibilityState] = useState(true);

  useEffect(() => {
    const visibilityHandler = () => {
      setVisibilityState(document.visibilityState !== "hidden");
    };

    document.addEventListener("visibilitychange", visibilityHandler);

    return () => {
      document.removeEventListener("visibilitychange", visibilityHandler);
    };
  }, []);

  const stage = useMemo(() => {
    let nametagY = isPortrait ? (smScreenSize ? 150 : 250) : 50;
    if (upperBodyZoom) {
      nametagY = smScreenSize ? 160 : lgScreenSize ? 50 : 125;
    }
    const nametagPaddingY = 12;
    const nametagFontSize = currentBreakpoints.fontSize * 0.85;
    const nametagOffsetY = nametagPaddingY * 2 + nametagFontSize;

    return (
      <AvatarStage
        camera={camera}
        style={stageStyle}
        stageSize={stageSize}
        // key to force rerender of stage when we go from 1 to 0 students
        key={`avatarLayout-${students.length}`}
      >
        {students.map((student, index) => {
          const isSelf = student.id === selfId;
          const avatarPosition = getAvatarPosition(index);
          const displayName = isSelf
            ? t("selfAvatarLabel", {
                guestNameSuffix: student.is_guest ? `, ${student.name}` : "",
              })
            : getDisplayName(student.name);

          return (
            <AvatarStageMember
              key={`avatar-${index}-${student.id}-showNametag-${showNametags}`}
              animation={selectedAnimations[index]}
              scale={avatarScale}
              x={avatarPosition.x}
              y={avatarPosition.y}
              atlasUrl={student.spine_atlas_url}
              skeletonUrl={student.spine_json_url}
              nametag={
                showNametags
                  ? {
                      fontSize: nametagFontSize,
                      paddingX: 24,
                      paddingY: nametagPaddingY,
                      textColor: "white",
                      yOffset:
                        index % 2 === 0 ? nametagY : nametagY - nametagOffsetY,
                      xOffset: 0,
                      name: displayName,
                      borderRadius: currentBreakpoints.borderRadius,
                    }
                  : undefined
              }
              carePackage={
                earnedQuickPlayCarePackage
                  ? {
                      yOffset:
                        index % 2 === 0
                          ? nametagY - 20
                          : nametagY - nametagOffsetY - 20,
                    }
                  : undefined
              }
            />
          );
        })}
      </AvatarStage>
    );
  }, [
    camera,
    stageSize,
    students,
    selfId,
    avatarScale,
    currentBreakpoints.fontSize,
    showNametags,
    isPortrait,
    t,
    selectedAnimations,
    getAvatarPosition,
    currentBreakpoints.borderRadius,
    smScreenSize,
    getDisplayName,
    upperBodyZoom,
    lgScreenSize,
    earnedQuickPlayCarePackage,
  ]);

  return (
    <Box
      h="100%"
      maxH="100%"
      w="100%"
      ref={targetContainerRef}
      position="relative"
    >
      <Box
        position="absolute"
        ref={stageContainerRef}
        // ensure the stage height reaches to the bottom of the desired position to the top of the view port
        // in portrait and to top of parent box in landscape
        h={
          Math.max(isPortrait ? targetContainerTop : 0, 0) +
          targetContainerHeight +
          "px"
        }
        bottom={0}
        // align stage with the left side of the screen and extend the full width
        left={-targetContainerLeft + "px"}
        w="100vw"
        style={{ pointerEvents: "none" }}
      >
        {visibilityState && stage}
      </Box>
    </Box>
  );
};
