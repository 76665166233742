import { Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { animated, config, useSpring } from "react-spring";

import { pxToRem } from "adminComponents/utils";
import { ISessionUser } from "links/lib/types";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";

interface IIntroProps {
  student: ISessionUser;
  handleComplete: () => void;
}

const AnimatedText = animated(Text);

export const Intro: React.FC<IIntroProps> = ({ handleComplete, student }) => {
  const { match: breakpoints } = useBreakpoints();
  const { t } = useTranslation("session", {
    useSuspense: false,
    keyPrefix: "podium.summary",
  });

  const introSpring = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    config: config.default,
    onRest: handleComplete,
  });

  return (
    <AnimatedText
      w="full"
      textAlign="center"
      style={introSpring}
      textStyle="gameDisplay"
      fontSize={pxToRem(breakpoints.fontSize)}
      fontWeight="700"
    >
      {t("intro", { studentName: student.name.split(" ")[0] })}
    </AnimatedText>
  );
};
