import {
  Box,
  CloseButton,
  Divider,
  Link,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

import { NavigationLink } from "adminComponents";
import { Divider as AdminDivider } from "adminComponents/atoms/Divider";
import { Heading } from "adminComponents/atoms/Heading";
import { Text } from "adminComponents/atoms/Text";
import { TextLink } from "adminComponents/atoms/TextLink";
import { AvatarStreak } from "adminComponents/molecules/AvatarStreak";
import { generateStudentName } from "adminComponents/utils/generateStudentName";
import { IUser } from "links/lib/types";

import { SeasonProgress } from "./components/SeasonProgress";
import { SubHeading } from "./components/SubHeading";

export interface StudentNavLink extends NavigationLink {
  hasNewContent?: boolean;
}

export interface IProps {
  daysLeft?: number;
  links: StudentNavLink[];
  season: string;
  user?: IUser;
  weekStreak?: number;
  currentWeekdayCount?: number;
  handleCloseDrawer: () => void;
  handleEditAvatar: () => void;
}

export const StudentProfileMenu: React.FC<IProps> = ({
  daysLeft,
  links = [],
  season,
  user,
  weekStreak,
  currentWeekdayCount,
  handleCloseDrawer,
  handleEditAvatar,
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });
  const nameDetails = generateStudentName(user || {});
  const name = nameDetails.primary || nameDetails.secondary;
  const styles = useMultiStyleConfig("AdminStudentProfileMenu", {});
  const hasAvatar = user?.profile_image_url;
  const currentLevelXP =
    (user?.student_season_total_xp ?? 0) -
    (user?.student_season_current_level_xp_threshold ?? 0);
  const nextLevelXP =
    (user?.student_season_next_level_xp_threshold ?? 0) -
    (user?.student_season_current_level_xp_threshold ?? 0);

  return (
    <Box __css={styles.container}>
      <Box __css={styles.topContainer}>
        <CloseButton
          __css={styles.closeButton}
          onClick={handleCloseDrawer}
          size="lg"
        />
        <AvatarStreak
          sx={styles.avatar}
          size="sm"
          activeSegment={currentWeekdayCount}
          name={name}
          src={user?.profile_image_url}
          showSilhouetteAsFallback
        />
        {!hasAvatar && (
          <Box __css={styles.editAvatar}>
            <TextLink handleClick={handleEditAvatar}>
              {t("common.editAvatar")}
            </TextLink>
          </Box>
        )}
        <Heading as="h4" variant="adminH4" sx={styles.heading}>
          {name}
        </Heading>
        <SubHeading
          sx={styles.subheading}
          weekStreak={weekStreak}
          xpPointsTotal={user?.student_season_total_xp}
        />
        <Divider sx={styles.divider} />
        <Box __css={styles.shiftedContentWrapper}>
          <SeasonProgress
            sx={styles.seasonProgress}
            rewardLevel={user?.student_season_level}
            season={season}
            daysLeft={daysLeft}
            xpPointsCurrent={currentLevelXP}
            xpPointsThreshold={nextLevelXP}
          />
        </Box>
      </Box>
      <Box w="full">
        {links.map(({ hasNewContent, label, ...linkProps }) => (
          <Fragment key={linkProps.to}>
            <Link sx={styles.link} as={RouterLink} {...linkProps}>
              <Text as="span" variant="adminP1">
                {label}
              </Text>
              {hasNewContent && <span role="presentation" />}
            </Link>
            <AdminDivider color="primary.tan-dark" />
          </Fragment>
        ))}
      </Box>
    </Box>
  );
};
