import { pxToRem } from "adminComponents/utils/pxToRem";

export const AdminHeroCard = {
  baseStyle: {
    header: {
      position: "absolute",
      top: "admin.cards.small",
      right: "admin.cards.small",
    },
    main: {
      flexDirection: ["column", null, "row"],
      justifyContent: "space-between",
      alignItems: ["center", null, "flex-start"],
      gap: ["admin.cards.xs", null, "admin.cards.small"],
    },
    image: {
      height: pxToRem(115),
      width: pxToRem(115),
    },
    studentDetails: {
      flex: 1,
      flexDirection: "column",
    },
    studentDetailsText: {
      paddingBottom: "admin.cards.small",
    },
    heading: {
      paddingBottom: pxToRem(8),
    },
    studentDetailsStats: {
      flexDirection: ["column", null, "row"],
      paddingTop: "admin.cards.small",
      gap: [pxToRem(12), null, "admin.cards.medium"],
    },
    flex: {
      display: "inline-flex",
    },
    icon: {
      marginRight: pxToRem(8),
    },
  },
  variants: {
    classroomAssignmentReport: {
      dueDate: {
        paddingBottom: pxToRem(8),
      },
      accuracy: {
        gap: pxToRem(6),
      },
      divider: {
        color: "primary.medium-gray",
      },
    },
    studentPracticeSetReport: {
      studentDetailsStats: {
        gap: pxToRem(10),
        alignItems: "center",
        flexDirection: "row",
      },
    },
  },
};
