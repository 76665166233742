import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Icon } from "adminComponents/atoms/Icon";
import { Tabs } from "adminComponents/atoms/Tabs";
import { HeroStudentDetailsCard } from "adminComponents/molecules/HeroStudentDetailsCard";
import {
  INavigationHeaderProps,
  NavigationHeader,
} from "adminComponents/molecules/NavigationHeader";
import { TemplateWithCenteredHeroOneColumn } from "adminComponents/templates/TemplateWithCenteredHeroOneColumn";
import { IUser } from "links/lib/types";
import { StudentDetailTab } from "screens/TeacherClassrooms/StudentDetail";

export interface IProps {
  accuracy: number;
  gradeLevel?: string;
  handleEdit?: (student: IUser) => void;
  isLoading?: boolean;
  navigationData: INavigationHeaderProps;
  practiceHours?: number;
  studentData?: IUser;
  handleTabChange: (tab: StudentDetailTab) => void;
  tabIndex?: number;
  showPremiumMarker?: boolean;
  handleDownloadCsv?: () => void;
  downloadedCsv?: boolean;
}

export const StudentDetailScreen: React.FC<IProps> = ({
  accuracy,
  gradeLevel,
  handleEdit,
  isLoading,
  navigationData,
  practiceHours,
  studentData,
  handleTabChange,
  tabIndex,
  children,
  showPremiumMarker = true,
  handleDownloadCsv,
  downloadedCsv,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  return (
    <TemplateWithCenteredHeroOneColumn
      nav={<NavigationHeader {...navigationData} isLoading={isLoading} />}
      heroContent={
        studentData && (
          <HeroStudentDetailsCard
            accuracy={accuracy}
            handleEdit={handleEdit ? () => handleEdit(studentData) : undefined}
            gradeLevel={gradeLevel}
            practiceHours={practiceHours}
            student={studentData}
          />
        )
      }
      isHeroShifted
      isLoading={isLoading}
      variant="default"
    >
      <Tabs
        handleChange={handleTabChange}
        tabIndex={tabIndex}
        tabData={[
          {
            content: <></>,
            label: t("studentDetailTabs.practiceSets"),
          },
          {
            content: <></>,
            label: t("studentDetailTabs.assignments"),
          },
          {
            content: <></>,
            label: t("studentDetailTabs.standards"),
            isPremium: showPremiumMarker,
          },
        ]}
        variant="adminScreenTabs"
        ctaButtonRight={
          tabIndex === StudentDetailTab.Assignments ? (
            <Button
              onClick={handleDownloadCsv}
              isDisabled={downloadedCsv}
              alignSelf="center"
              size="sm"
              variant="adminButtonFilled"
              rightIcon={
                downloadedCsv ? <Icon icon="check_outlined" /> : undefined
              }
            >
              {t("common.downloadCsv")}
            </Button>
          ) : undefined
        }
      />
      {children}
    </TemplateWithCenteredHeroOneColumn>
  );
};
