import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { ClassroomArchiveModal } from "adminComponents/organisms/ClassroomArchiveModal";
import { useErrorToast, useShowToast } from "adminComponents/utils/toast";
import { useArchiveClassroom as useArchiveClassroomMutation } from "links/lib/features/classrooms";
import { IClassroom } from "links/lib/types";

interface IResult {
  handleArchiveIntent: (classroom: IClassroom, accuracy?: number) => void;
  modal: React.ReactElement;
}

interface IArgs {
  onConfirm?: (classroom: IClassroom) => void;
  onSuccess?: () => void;
}

const useArchiveClassroom = ({
  onConfirm,
  onSuccess: _onSuccess,
}: IArgs): IResult => {
  const showToast = useShowToast();
  const { t } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "classroomCommon.classroomArchive",
  });

  const [state, setState] = useState<{
    isModalOpen: boolean;
  }>({ isModalOpen: false });
  const [currentClassroom, setCurrentClassroom] = useState<{
    classroom: IClassroom;
    accuracy?: number;
  } | null>(null);

  const onSuccess = useCallback(() => {
    showToast(t("successToast"));
    setState({ isModalOpen: false });
    _onSuccess?.();
  }, [showToast, t, _onSuccess]);

  const archiveClassroom = useArchiveClassroomMutation({ onSuccess });

  useErrorToast(archiveClassroom.error);

  const handleArchiveIntent = useCallback(
    (classroom: IClassroom, accuracy?: number) => {
      setCurrentClassroom({
        classroom,
        accuracy,
      });

      setState({
        isModalOpen: true,
      });
    },
    []
  );

  const handleModalClose = useCallback(
    (isConfirmed?: boolean) => {
      if (!isConfirmed || !currentClassroom) {
        setState({ isModalOpen: false });
        return;
      }

      archiveClassroom.mutate({ id: currentClassroom.classroom.id });
      onConfirm?.(currentClassroom.classroom);
    },
    [currentClassroom, archiveClassroom, onConfirm]
  );

  const modal = useMemo(() => {
    if (!currentClassroom) return <></>;

    return (
      <ClassroomArchiveModal
        handleClose={handleModalClose}
        isLoading={archiveClassroom.isLoading}
        isOpen={state.isModalOpen}
        classroom={currentClassroom?.classroom}
        accuracy={currentClassroom?.accuracy}
      />
    );
  }, [
    archiveClassroom.isLoading,
    state.isModalOpen,
    handleModalClose,
    currentClassroom,
  ]);

  return useMemo(
    () => ({
      handleArchiveIntent,
      modal,
    }),
    [handleArchiveIntent, modal]
  );
};

export { useArchiveClassroom };
