import { Box, Square, Tooltip } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Icon } from "adminComponents/atoms/Icon";
import { pxToRem } from "adminComponents/utils";

interface IPremiumIconrops {
  type?: "set" | "item" | "feature" | "account";
  toolTip?: boolean;
  size?: number;
}

const JustIcon = React.forwardRef<HTMLDivElement, { size?: number }>(
  ({ size = 22 }, ref) => {
    const iconSize = 0.6 * size;
    return (
      <Square
        margin={0}
        padding={0}
        size={pxToRem(size)}
        backgroundColor="utility.premium"
        borderRadius="full"
        ref={ref}
      >
        <Icon
          icon="crown"
          as="span"
          iconColor="primary.white"
          height={pxToRem(iconSize)}
          width={pxToRem(iconSize)}
        />
      </Square>
    );
  }
);

JustIcon.displayName = "JustPremiumIcon";

const WithTooltip: React.FC<IPremiumIconrops> = ({ type, size = 22 }) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });

  let toolTipText;

  if (type) {
    toolTipText = t(`premiumText.${type}Tooltip`);
  } else {
    toolTipText = t("common.premium");
  }

  return (
    <Tooltip
      label={toolTipText}
      aria-label={toolTipText}
      openDelay={500}
      variant="adminDark"
    >
      <Box>
        <JustIcon size={size} />
      </Box>
    </Tooltip>
  );
};

export const PremiumIcon: React.FC<IPremiumIconrops> = ({
  type,
  toolTip = false,
  size = 22,
}) => {
  return toolTip ? (
    <WithTooltip size={size} type={type} />
  ) : (
    <JustIcon size={size} />
  );
};
