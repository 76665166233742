import { Box, useMultiStyleConfig } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { PieChart } from "adminComponents/atoms/PieChart";
import { PremiumTooltipRich } from "adminComponents/atoms/PremiumTooltipRich";
import { Text } from "adminComponents/atoms/Text";

interface IProps {
  percentage?: number;
  text?: string;
  textVariant?: string;
  labelLinkTo?: string;
  hasNoPremiumAccess?: boolean;
}

export const PieChartWithLabel: React.FC<IProps> = ({
  percentage,
  text,
  textVariant,
  labelLinkTo,
  hasNoPremiumAccess,
}) => {
  const styles = useMultiStyleConfig("AdminPieChartWithLabel", {
    isBlurred: hasNoPremiumAccess,
  });
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });
  const history = useHistory();
  const notAvailable = typeof percentage === "undefined";
  const textStyle = textVariant
    ? textVariant
    : labelLinkTo
    ? "adminLinkLarge"
    : "adminP1";
  let displayPercentage =
    typeof percentage !== "undefined" ? Math.round(percentage) : undefined;

  if (hasNoPremiumAccess) {
    displayPercentage = 50;
  }
  const labelText = `${displayPercentage}%${text ? ` ${text}` : ""}`;

  return (
    <PremiumTooltipRich isDisabled={!hasNoPremiumAccess}>
      <Box __css={styles.container}>
        {notAvailable ? (
          <Text
            cursor={labelLinkTo ? "pointer" : undefined}
            variant={textStyle}
            onClick={() => {
              if (labelLinkTo) {
                history.push(labelLinkTo);
              }
            }}
          >
            {text && <>{text}: </>}
            {t("pieChartWithLabel.na")}
          </Text>
        ) : (
          <>
            <PieChart percentage={displayPercentage} styles={styles.chart} />
            <Text
              cursor={labelLinkTo ? "pointer" : undefined}
              variant={textStyle}
              onClick={() => {
                if (labelLinkTo) {
                  history.push(labelLinkTo);
                }
              }}
            >
              {labelText}
            </Text>
          </>
        )}
      </Box>
    </PremiumTooltipRich>
  );
};
