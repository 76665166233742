import { Box, HStack, VStack } from "@chakra-ui/react";
import React, { useState } from "react";
import { animated, config, useTransition } from "react-spring";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";

import { useSwipe } from "./hooks/useSwipe";

const AnimatedBox = animated(Box);

interface ICarouselProps {
  items: React.ReactElement[];
}

export const Carousel: React.FC<ICarouselProps> = ({ items }) => {
  const {
    match: { padding, borderWidth },
  } = useBreakpoints();
  const [visibleIndex, setVisibleIndex] = useState(0);

  const carouselTransition = useTransition(visibleIndex, {
    from: {
      opacity: 0,
      translateX: 200,
    },
    enter: {
      opacity: 1,
      translateX: 0,
    },
    leave: {
      opacity: 0,
      translateX: -200,
    },
    config: config.stiff,
    exitBeforeEnter: true,
  });

  useSwipe({
    left: () => {
      if (visibleIndex <= 0) {
        setVisibleIndex(items.length - 1);
      } else {
        setVisibleIndex(visibleIndex - 1);
      }
    },
    right: () => {
      if (visibleIndex >= items.length - 1) {
        setVisibleIndex(0);
      } else {
        setVisibleIndex(visibleIndex + 1);
      }
    },
  });

  return (
    <VStack spacing={pxToRem(padding)} h="full">
      {carouselTransition((style, visibleIndex) => {
        return (
          <AnimatedBox style={style} flex={1}>
            {items[visibleIndex]}
          </AnimatedBox>
        );
      })}
      <HStack spacing={pxToRem(padding * 2)} flex={0}>
        {items.map((_, index) => (
          <Box
            key={index}
            as="button"
            onClick={() => setVisibleIndex(index)}
            borderWidth={pxToRem(borderWidth)}
            borderColor="primary.white"
            backgroundColor={
              index === visibleIndex
                ? "link.link-blue"
                : "rgba(255, 255, 255, 0.4)"
            }
            h={pxToRem(padding)}
            w={pxToRem(padding)}
            borderRadius="100%"
          />
        ))}
      </HStack>
    </VStack>
  );
};
