import { Box } from "@chakra-ui/react";
import React, { useMemo } from "react";

import { pxToRem } from "adminComponents/utils/pxToRem";

import { SeasonMapLocation } from "./components/SeasonMapLocation";
import { SeasonMapPin } from "./components/SeasonMapPin";
import { ISeasonMapZoneProps, SeasonMapZone } from "./components/SeasonMapZone";

export type LocationInfo = {
  id: string;
  x: number;
  y: number;
};

export interface ISeasonMapProps {
  width: number;
  height: number;
  backdropImage?: string;
  landmarksImage: string;
  overlayImage?: string;
  scale?: number;
  children: Array<React.ReactElement<ISeasonMapZoneProps>>;
  handleLocationFocus?: (location: LocationInfo) => void;
  handleLocationSelect?: (locationId: LocationInfo) => void;
}

const SeasonMap: React.FC<ISeasonMapProps> = ({
  width,
  height,
  backdropImage,
  landmarksImage,
  overlayImage,
  scale = 1,
  children,
  handleLocationFocus,
  handleLocationSelect,
}) => {
  const widthRem = pxToRem(width * scale);
  const heightRem = pxToRem(height * scale);

  const childrenWithScale = useMemo(() => {
    return React.Children.map<
      React.ReactElement<ISeasonMapZoneProps>,
      React.ReactElement<ISeasonMapZoneProps>
    >(children, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {
          scale,
          handleLocationFocus,
          handleLocationSelect,
        });
      }
      return child;
    });
  }, [children, scale, handleLocationFocus, handleLocationSelect]);

  return (
    <Box
      width={widthRem}
      height={heightRem}
      bgImage={backdropImage}
      bgRepeat="no-repeat"
      bgSize={`${widthRem} ${heightRem}`}
      bgPosition="top left"
      position="relative"
    >
      {childrenWithScale}
      <Box
        w="full"
        h="full"
        top="0"
        left="0"
        position="absolute"
        bgImage={landmarksImage}
        bgRepeat="no-repeat"
        bgSize={`${widthRem} ${heightRem}`}
        bgPosition="top left"
      />
      {overlayImage && (
        <Box
          w="full"
          h="full"
          top="0"
          left="0"
          position="absolute"
          bgImage={overlayImage}
          bgRepeat="no-repeat"
          bgSize={`${widthRem} ${heightRem}`}
          bgPosition="top left"
        />
      )}
    </Box>
  );
};

export { SeasonMap, SeasonMapZone, SeasonMapLocation, SeasonMapPin };
