import { hoverStyles, pxToRem } from "adminComponents/utils";

export const AdminAccordion = {
  variants: {
    adminAccordion: {
      button: {
        boxShadow: "none",
        outline: "none",
        borderRadius: pxToRem(4),
        borderColor: "transparent",
        borderWidth: pxToRem(2),
        px: [pxToRem(12), null, pxToRem(12)],
        py: [pxToRem(8), null, pxToRem(8)],
        mx: pxToRem(-12),
        w: `calc(100% + ${pxToRem(24)})`,
        _focus: {
          boxShadow: "none",
        },
        _focusVisible: {
          borderColor: "utility.focus",
          borderStyle: "solid",
        },
        _hover: hoverStyles({
          bgColor: "primary.golden-light",
        }),
      },
      panel: {
        pb: [pxToRem(8), null, pxToRem(12)],
        pt: [pxToRem(4), null, pxToRem(12)],
        px: pxToRem(0),
      },
      container: {
        borderBottom: "none",
        borderColor: "primary.medium-gray",
        py: [pxToRem(12), null, pxToRem(8)],
        ".chakra-collapse": {
          overflow: "visible !important",
        },
      },
      textContainer: {
        flex: 1,
        textAlign: "left",
      },
      outerContainer: {
        px: pxToRem(12),
        overflow: "hidden",
      },
    },
    adminSubjectSidebarAccordion: {
      button: {
        boxShadow: "none",
        outline: "none",
        px: pxToRem(0),
        py: pxToRem(24),
        w: "100%",
        borderRadius: pxToRem(4),
        borderColor: "transparent",
        borderWidth: pxToRem(2),
        _focus: {
          boxShadow: "none",
        },
        _focusVisible: {
          borderColor: "utility.focus",
          borderStyle: "solid",
        },
        _hover: hoverStyles({
          bgColor: "primary.golden-light",
        }),
        p: {
          fontWeight: "600 !important",
          color: "primary.warm-black",
        },
      },
      panel: {
        pb: pxToRem(24),
        pt: pxToRem(0),
        px: pxToRem(0),
        "label > span:nth-of-type(2)": {
          color: "primary.warm-black",
        },
      },
      container: {
        borderTop: "none",
        borderColor: "primary.light-gray",
        borderBottomWidth: pxToRem(1),
      },
      textContainer: {
        flex: 1,
        textAlign: "left",
      },
      outerContainer: {
        px: pxToRem(0),
        overflow: "hidden",
      },
    },
    adminPracticeSetSubjectAccordion: {
      button: {
        boxShadow: "none",
        outline: "none",
        px: pxToRem(0),
        py: pxToRem(10),
        w: "100%",
        borderRadius: pxToRem(4),
        borderColor: "transparent",
        borderWidth: pxToRem(2),
        _focus: {
          boxShadow: "none",
        },
        _focusVisible: {
          borderColor: "utility.focus",
          borderStyle: "solid",
        },
        _hover: hoverStyles({
          bgColor: "primary.golden-light",
        }),
      },
      panel: {
        pb: pxToRem(24),
        pt: pxToRem(0),
        px: pxToRem(0),
        "label > span:nth-of-type(2)": {
          color: "primary.warm-black",
        },
      },
      container: {
        borderTop: "none",
        borderColor: "primary.light-gray",
        borderBottomWidth: pxToRem(1),
      },
      textContainer: {
        flex: 1,
        textAlign: "left",
      },
      outerContainer: {
        px: pxToRem(0),
        overflow: "hidden",
      },
    },
  },
};
