import {
  Box,
  Flex,
  UseCheckboxProps,
  chakra,
  useCheckbox,
  usePrefersReducedMotion,
} from "@chakra-ui/react";
import React from "react";
import { animated, config, useSpring } from "react-spring";

import { ColorScheme } from "../../../adminComponents";

import { AnimatedCheckIcon } from "../AnimatedCheckIcon";

interface ICheckboxProps extends UseCheckboxProps {
  size: string; //expects a pxToRem converted value from parent
  color?: ColorScheme;
  type: "checkbox" | "radio";
  isLoading?: boolean;
  isDisabled?: boolean;
  tabbable?: boolean;
}

export const Checkbox: React.FC<ICheckboxProps> = (props) => {
  const { size, color, type } = props;
  // delete isLoading from props to avoid HTML errors in console
  const checkboxProps = { ...props };
  delete checkboxProps.isLoading;
  const { state, getCheckboxProps, getInputProps, htmlProps } = useCheckbox({
    ...checkboxProps,
    isDisabled: props.isDisabled || props.isLoading,
  });

  const prefersReducedMotion = usePrefersReducedMotion();
  const checkStyles = useSpring({
    opacity: state.isChecked ? 1 : 0,
    scale: state.isChecked ? 1 : prefersReducedMotion ? 1 : 0,
    config: config.stiff,
  });

  const AnimatedBox = animated(Box);

  return (
    <chakra.label
      display="flex"
      flexDirection="row"
      alignItems="center"
      cursor="pointer"
      tabIndex={props.tabbable ? 1 : 0}
      {...htmlProps}
    >
      <input {...getInputProps()} style={{ opacity: 0, width: 0, height: 0 }} />
      <Flex
        alignItems="center"
        justifyContent="center"
        border="2px solid"
        transition="border var(--chakra-transition-duration-fast) var(--chakra-transition-easing-ease-in-out)" // todo copied over
        borderRadius={type === "radio" ? "100%" : undefined}
        borderColor={color}
        backgroundColor={
          (state.isChecked && type === "checkbox" && color) || "primary.white"
        }
        w={size}
        h={size}
        {...getCheckboxProps()}
      >
        {state.isChecked && type === "checkbox" && (
          <AnimatedCheckIcon
            maxWidth="95%"
            color="primary.white"
            checked={state.isChecked}
          />
        )}
        {state.isChecked && type === "radio" && (
          <AnimatedBox
            h="70%"
            w="70%"
            backgroundColor={color}
            borderRadius="100%"
            style={checkStyles}
          />
        )}
      </Flex>
    </chakra.label>
  );
};
