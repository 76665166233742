import {
  Box,
  LinkBox,
  LinkOverlay,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

import { Card } from "adminComponents/atoms/Card";
import { Divider } from "adminComponents/atoms/Divider";
import { Heading } from "adminComponents/atoms/Heading";
import {
  AccuracyType,
  ReportingAccuracyTag,
} from "adminComponents/atoms/ReportingAccuracyTag";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils";
import {
  TEACHER_REPORTING_PRACTICE_SET_HIGH_ACCURACY_THRESHOLD,
  TEACHER_REPORTING_PRACTICE_SET_LOW_ACCURACY_THRESHOLD,
} from "links/lib/constants";
import { IClassroom, IPracticeSet } from "links/lib/types";

import CoverImageBlob from "../CoverImageBlob";
import { PieChartWithLabel } from "../PieChartWithLabel";

export interface IProps {
  practiceSet: IPracticeSet;
  classroom?: IClassroom;
  accuracy?: number;
  accuracyType?: "class" | "student";
  showClassroomInfo?: boolean;
  showStandardsInfo?: boolean;
  showMostRecentInteractionInfo?: boolean;
  studentId?: string;
}

export const PracticeSetCard: React.FC<IProps> = ({
  practiceSet,
  classroom,
  accuracy,
  accuracyType,
  showClassroomInfo,
  showStandardsInfo,
  showMostRecentInteractionInfo,
  studentId,
}) => {
  const styles = useMultiStyleConfig("AdminPracticeSetCard", {});
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });
  const shouldDisplayAccuracy = typeof accuracy !== "undefined" && accuracyType;
  const shouldDisplayHighLowAccuracyTag =
    shouldDisplayAccuracy &&
    !!accuracyType &&
    (accuracy <= TEACHER_REPORTING_PRACTICE_SET_LOW_ACCURACY_THRESHOLD ||
      accuracy >= TEACHER_REPORTING_PRACTICE_SET_HIGH_ACCURACY_THRESHOLD);
  const accuracyTypeLabel =
    accuracyType === "class"
      ? t("practiceSetCard.classAccuracy")
      : t("practiceSetCard.studentAccuracy");

  const getClassroomUrl = () =>
    studentId
      ? `/t/classrooms/${classroom?.id}/sets/${practiceSet.id}/students/${studentId}`
      : `/t/classrooms/${classroom?.id}/sets/${practiceSet.id}`;

  const practiceSetUrl = classroom
    ? getClassroomUrl()
    : `/t/my-library/sets/${practiceSet.id}`;

  const { practice_set_item_standard_labels } = practiceSet;

  let practiceSetItemsStandardsLabelsString = "";
  if (practice_set_item_standard_labels?.length) {
    if (practice_set_item_standard_labels.length > 3) {
      practiceSetItemsStandardsLabelsString =
        practice_set_item_standard_labels.slice(0, 3).join(", ") +
        t("practiceSetCard.standardsSuffix", {
          count: practice_set_item_standard_labels.length - 3,
        });
    } else {
      practiceSetItemsStandardsLabelsString =
        practice_set_item_standard_labels.join(", ");
    }
  }

  let mostRecentInteractionType, mostRecentInteractionTime;
  const mostRecentLivePractice = new Date(
    practiceSet.most_recent_live_practice_timestamp
  );
  const mostRecentAssignment = new Date(
    practiceSet.most_recent_assignment_timestamp
  );
  if (mostRecentLivePractice > mostRecentAssignment) {
    mostRecentInteractionType = "livePractice";
    mostRecentInteractionTime = moment(mostRecentLivePractice).format(
      "MMMM Do YYYY; h:mm A"
    );
  } else if (mostRecentAssignment > mostRecentLivePractice) {
    mostRecentInteractionType = "assignment";
    mostRecentInteractionTime = moment(mostRecentAssignment).format(
      "MMMM Do YYYY; h:mm A"
    );
  }

  return (
    <LinkBox>
      <Card
        borderColor="primary.tan"
        backgroundColor="primary.white"
        variant="adminCardSelectable"
        py={shouldDisplayHighLowAccuracyTag ? pxToRem(47) : pxToRem(36)}
      >
        {shouldDisplayHighLowAccuracyTag && (
          <ReportingAccuracyTag
            accuracyType={
              accuracyType === "class"
                ? AccuracyType.Class
                : AccuracyType.Student
            }
            isHighAccuracy={
              accuracy >= TEACHER_REPORTING_PRACTICE_SET_HIGH_ACCURACY_THRESHOLD
            }
            position="absolute"
            top="0"
            left="0"
            borderTopLeftRadius={pxToRem(20)}
            zIndex="2"
          />
        )}
        <Box __css={styles.body}>
          <Box __css={styles.title}>
            {practiceSet.cover_image_bg_color_scheme &&
              practiceSet.cover_image_icon &&
              practiceSet.cover_image_bg_pattern && (
                <Box __css={styles.coverImage}>
                  <CoverImageBlob
                    backgroundColorScheme={
                      practiceSet.cover_image_bg_color_scheme
                    }
                    backgroundPattern={practiceSet.cover_image_bg_pattern}
                    icon={practiceSet.cover_image_icon}
                    boxSize={60}
                  />
                </Box>
              )}
            <Box>
              {showMostRecentInteractionInfo && mostRecentInteractionTime && (
                <Text textStyle={["adminP3", null, "adminP2"]}>
                  {t(`practiceSetCard.${mostRecentInteractionType}`)}{" "}
                  {mostRecentInteractionTime}
                </Text>
              )}
              {practiceSet.title && (
                <LinkOverlay
                  as={RouterLink}
                  to={practiceSetUrl}
                  sx={styles.linkOverlay}
                >
                  <Heading
                    as="p"
                    noOfLines={{ base: 4, md: 2 }}
                    variant="adminH6"
                    wordBreak="normal"
                    overflowWrap="anywhere"
                  >
                    {practiceSet.title}
                  </Heading>
                </LinkOverlay>
              )}
              {showClassroomInfo && (
                <Text textStyle="adminP1" color="primary.dark-gray">
                  {classroom?.name}{" "}
                  {classroom?.description ? `· ${classroom.description}` : ""}
                </Text>
              )}
              {showStandardsInfo && practiceSetItemsStandardsLabelsString && (
                <Text textStyle={["adminP3", null, "adminP2"]}>
                  {practiceSetItemsStandardsLabelsString}
                </Text>
              )}
            </Box>
          </Box>
          {shouldDisplayAccuracy && (
            <>
              <Divider __css={styles.divider} color="primary.tan" />
              <Box __css={styles.progress}>
                <PieChartWithLabel
                  percentage={accuracy}
                  text={accuracyTypeLabel}
                />
              </Box>
            </>
          )}
        </Box>
      </Card>
    </LinkBox>
  );
};
