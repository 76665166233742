import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useErrorToast, useShowToast } from "adminComponents/utils/toast";
import { useAnalytics } from "lib/contexts/analytics";
import { useRestoreClassroom } from "links/lib/features/classrooms";
import { AnalyticsEvent, IClassroom } from "links/lib/types";

interface IResult {
  handleRestoreClassroom: (classroom: IClassroom) => void;
  isLoading: boolean;
}

const useHandleRestoreClassroom = (): IResult => {
  const showToast = useShowToast();
  const { t } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "classroomArchiveContainer.restoreClassroom",
  });
  const { trackEvent } = useAnalytics();

  const onSuccess = useCallback(() => {
    showToast(t("successToast"));
  }, [showToast, t]);

  const { mutate, error, isLoading } = useRestoreClassroom({
    onSuccess,
  });

  useErrorToast(error);

  const handleRestoreClassroom = useCallback(
    (classroom: IClassroom) => {
      trackEvent(
        AnalyticsEvent.TeacherDashboard_ClassroomArchive_ClassroomCard_RestoreClassroom,
        {
          classroomId: classroom.id,
        }
      );

      mutate({ id: classroom.id });
    },
    [trackEvent, mutate]
  );

  return useMemo(
    () => ({
      handleRestoreClassroom,
      isLoading,
    }),
    [handleRestoreClassroom, isLoading]
  );
};

export { useHandleRestoreClassroom };
