import { Box, Flex, useCheckbox, useToken } from "@chakra-ui/react";
import {
  Alignment,
  Fit,
  Layout,
  useRive,
  useStateMachineInput,
} from "@rive-app/react-canvas";
import React from "react";
import { useFormContext } from "react-hook-form";

import { Icon } from "adminComponents/atoms/Icon";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils";
import { ISubject } from "links/lib/types";

import animSrc from "./resource/onboarding_icons_transparent.riv";

const subjectIdToDataMap: {
  [key: string]: {
    artboardName: string;
    stateMachineName: string;
    stateMachineInput: number;
  };
} = {
  "2": {
    artboardName: "math-icon-rive",
    stateMachineName: "Math State",
    stateMachineInput: 1,
  },
  "3": {
    artboardName: "science-icon-rive-01",
    stateMachineName: "Science State",
    stateMachineInput: 6,
  },
  "4": {
    artboardName: "worldhistory-icon-rive-01",
    stateMachineName: "History State",
    stateMachineInput: 4,
  },
  "5": {
    artboardName: "worldlang-icon-rive",
    stateMachineName: "World Languages State",
    stateMachineInput: 5,
  },
  "6": {
    artboardName: "other-icon-rive-01",
    stateMachineName: "Other State",
    stateMachineInput: 8,
  },
  "7": {
    artboardName: "ela-icon",
    stateMachineName: "ELA State",
    stateMachineInput: 3,
  },
  "8": {
    artboardName: "vpa-icon-rive",
    stateMachineName: "VPA State",
    stateMachineInput: 7,
  },
  "9": {
    artboardName: "electives-icon-rive",
    stateMachineName: "Electives State",
    stateMachineInput: 2,
  },
};

const stateMachineNames = [
  "Math State",
  "Science State",
  "History State",
  "Electives State",
  "Other State",
  "ELA State",
  "VPA State",
  "World Languages State",
];

const SubjectAnimationStateMachine: React.FC<{ subjectId: string }> = ({
  subjectId,
}) => {
  const { artboardName, stateMachineInput, stateMachineName } =
    subjectIdToDataMap[subjectId];
  const { rive, RiveComponent } = useRive({
    artboard: artboardName,
    layout: new Layout({
      fit: Fit.Contain,
      alignment: Alignment.Center,
    }),
    src: animSrc,
    stateMachines: stateMachineNames,
    autoplay: false,
  });

  const subjectValueInput = useStateMachineInput(
    rive,
    stateMachineName,
    "subject"
  );
  if (subjectValueInput) subjectValueInput.value = stateMachineInput;

  return <RiveComponent onMouseEnter={() => rive?.play()} />;
};

interface SubjectCheckboxProps {
  subject: ISubject;
  index: number;
}

export const SubjectCheckbox = React.forwardRef<
  HTMLDivElement,
  SubjectCheckboxProps
>((props, ref) => {
  const { subject, index } = props;
  // Necessary in order to use !important to override Chakra's default styles
  const utilityFocus = useToken("colors", "utility.focus");

  const { setValue, watch } = useFormContext();
  const selectedSubjectIndexes = watch("subjectIndexes");
  const selected = !!selectedSubjectIndexes?.[index];

  const { getInputProps } = useCheckbox({});

  return (
    <Flex
      cursor="pointer"
      ref={ref}
      flexDir="column"
      alignItems="center"
      textAlign="center"
      justifyContent="center"
      position="relative"
      p={pxToRem(6)}
      backgroundColor="transparent"
      borderWidth={selected ? pxToRem(5) : pxToRem(3)}
      borderColor={selected ? "utility.focus" : "primary.tan"}
      borderRadius={pxToRem(14)}
      h="auto"
      _hover={{
        borderColor: selected ? undefined : "primary.warm-black",
      }}
      _active={{
        backgroundColor: "transparent",
      }}
      _focusVisible={{
        bg: "transparent",
      }}
      onClick={() => setValue(`subjectIndexes.${index}`, !selected)}
      gap={pxToRem(8)}
      sx={{
        "&:hover": {
          ".checkbox-display": {
            borderColor: selected ? undefined : "primary.golden",
          },
        },
        ".checkbox-display": {
          borderColor: selected ? `${utilityFocus} !important` : undefined,
          borderWidth: selected ? `${pxToRem(2)} !important` : undefined,
        },
      }}
    >
      <input {...getInputProps()} style={{ opacity: 0, width: 0, height: 0 }} />
      <Box
        position="absolute"
        borderRadius={pxToRem(8)}
        p={pxToRem(3)}
        top={pxToRem(5)}
        right={pxToRem(5)}
        className="checkbox-display"
        display="flex"
        justifyContent="center"
        alignItems="center"
        borderWidth={pxToRem(1)}
      >
        {selected ? (
          <Icon
            icon="check_outlined"
            width={pxToRem(18)}
            height={pxToRem(18)}
            iconColor="primary.warm-black"
          />
        ) : (
          <Box boxSize={pxToRem(18)} />
        )}
      </Box>
      <Box
        display="inline-flex"
        position="relative"
        boxSize={pxToRem(68)}
        alignItems="center"
        justifyContent="center"
      >
        <SubjectAnimationStateMachine subjectId={subject.id} />
      </Box>
      <Text variant="adminP2">{subject.name}</Text>
    </Flex>
  );
});

SubjectCheckbox.displayName = "SubjectCheckbox";
