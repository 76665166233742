import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  Route,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import { Icon } from "adminComponents/atoms/Icon";
import { getPracticeSetUrl } from "adminComponents/molecules/LibraryPracticeSetCard";
import { TeacherPracticeSetScreen } from "adminComponents/screens/TeacherPracticeSetScreen";
import { useAnalytics, usePageTrack } from "lib/contexts/analytics";
import { useHubSpot } from "lib/hooks/useHubSpot";
import { usePageTitle } from "lib/hooks/usePageTitle";
import { regionMap } from "links/lib/constants";
import { useAuth } from "links/lib/features/auth";
import { langLabelMap, supportedLangs } from "links/lib/lang";
import {
  AnalyticsEvent,
  AnalyticsPage,
  PracticeSetAvailability,
  UserRole,
} from "links/lib/types";
import { useNavigationData } from "screens/TeacherDashboard/contexts/TeacherNavigationDataProvider";
import { useCreateSession } from "screens/TeacherMyLibrary/shared/hooks/useCreateSession";
import { usePracticeSetHandlers } from "screens/TeacherMyLibrary/shared/hooks/usePracticeSetHandlers";

import PracticeSetItemFlyout from "./PracticeSetItemFlyout";
import { useAddPracticeSetItem } from "./hooks/useAddPracticeSetItem";
import { useCreateAssignment } from "./hooks/useCreateAssignment";
import { useDeletePracticeSetItem } from "./hooks/useDeletePracticeSetItem";
import { useDuplicatePracticeSetItem } from "./hooks/useDuplicatePracticeSetItem";
import { useEditPracticeSet } from "./hooks/useEditPracticeSet";
import { usePracticeSetDetailData } from "./hooks/usePracticeSetDetailData";
import { usePracticeSetItemEdit } from "./hooks/usePracticeSetItemEdit";
import { useReorderPracticeSetItems } from "./hooks/useReorderPracticeSetItems";

const PracticeSetDetail: React.FC = () => {
  const { id: practiceSetId } = useParams<{ id: string }>();
  const { authUser, isFeatureEnabled, hasNoPremiumAccess } = useAuth();
  const location = useLocation();
  const { navigationData } = useNavigationData();
  const { trackHubSpotEvent } = useHubSpot();
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });
  const { trackEvent } = useAnalytics();
  const history = useHistory();
  usePageTrack(AnalyticsPage.TeacherDashboard_PracticeSetDetail);

  // handle fetching screen data
  const {
    practiceSet,
    collections,
    collection,
    gradeLevels,
    subjects,
    items,
    isPracticeSetAndItemsReady,
    isPracticeSetNotFound,
  } = usePracticeSetDetailData(practiceSetId);

  useEffect(() => {
    if (!isPracticeSetNotFound) return;
    history.push("/t/my-library");
  }, [isPracticeSetNotFound, history]);

  // Redirect according to user's relation to the practice set
  useEffect(() => {
    if (!practiceSet) return;
    if (!authUser) return;

    const practiceSetUrl = getPracticeSetUrl(practiceSet, authUser);
    if (!location.pathname.startsWith(practiceSetUrl)) {
      history.push(practiceSetUrl);
    }
  }, [practiceSet, authUser, history, location]);

  // handle edit practice set
  const {
    handleEdit: handlePracticeSetEdit,
    isDetailFlyoutOpen,
    setIsDetailFlyoutOpen,
    isLoading: isPracticeSetEditLoading,
    handleCoverImageChange,
  } = useEditPracticeSet({ practiceSet });

  const {
    handleDeleteIntent: handlePracticeSetItemDeleteIntent,
    modal: deletePracticeSetItemModal,
  } = useDeletePracticeSetItem({ practiceSet });

  // handle duplicate item
  const { handleDuplicate: handleDuplicatePracticeSetItem } =
    useDuplicatePracticeSetItem({ practiceSet });

  // returns functions for duplication and deletion
  const { components: practiceSetHandlerComponents, ...practiceSetHandlers } =
    usePracticeSetHandlers({
      collections: collection ? [collection] : [],
      onDeleteSuccess: () => history.push("/t/my-library"),
    });

  // handle reorder items
  const {
    handleReorder,
    handleCancelReorder,
    handleStartReorder,
    isLoading: isReorderLoading,
  } = useReorderPracticeSetItems({ practiceSet });

  const {
    component: createAssignmentFlyout,
    handleCreateIntent: handleCreateAssignment,
  } = useCreateAssignment({ practiceSet });

  // handle add item
  const {
    isAddQuestionsFlyoutOpen,
    isImportQuestionsFlyoutOpen,
    setIsAddQuestionsFlyoutOpen,
    setIsImportQuestionsFlyoutOpen,
    handleAdd: handleAddPracticeSetItem,
    handleSearch: handlePracticeSetItemSearch,
    handleAddSearchItem: handleAddPracticeSetItemFromSearch,
    searchResults,
    isSearchLoading,
  } = useAddPracticeSetItem({ practiceSet });

  // handle edit item
  const { handleEdit: handlePracticeSetItemEdit } = usePracticeSetItemEdit({
    practiceSet,
  });

  const subjectOptions = useMemo(() => {
    return subjects.map((s) => ({
      value: s.id,
      label: s.parent_name ? `${s.parent_name}: ${s.name}` : s.name,
    }));
  }, [subjects]);

  const gradeOptions = useMemo(() => {
    return gradeLevels.map((g) => ({
      value: g.id,
      label: g.grade_level,
    }));
  }, [gradeLevels]);

  const stateOptionsWithAny = useMemo(() => {
    const userRegionMap = regionMap[authUser?.country || "US"];

    const regions = Object.keys(userRegionMap).map((key) => ({
      label: userRegionMap[key],
      value: key,
    }));

    return [
      {
        label: t("common.searchFilterOptionAnyState"),
        value: "",
      },
    ].concat(regions);
  }, [authUser, t]);

  const subjectOptionsWithAny = useMemo(() => {
    return [
      {
        label: t("common.searchFilterOptionAnySubject"),
        value: "",
      },
    ].concat(subjectOptions);
  }, [subjectOptions, t]);

  const gradeOptionsWithAny = useMemo(() => {
    return [
      {
        label: t("common.searchFilterOptionAnyGrade"),
        value: "",
      },
    ].concat(gradeOptions);
  }, [gradeOptions, t]);

  const noOp = useCallback(() => {
    return;
  }, []);

  const match = useRouteMatch();

  const pageTitle = [practiceSet?.title];
  if (practiceSet?.collection_id !== "0") pageTitle.push(collection?.name);
  pageTitle.push(t("practiceSetDetailContainer.myLibraryBreadcrumb"));
  usePageTitle(pageTitle);

  const breadcrumbs = [
    {
      to: "/t/my-library",
      label: t("practiceSetDetailContainer.myLibraryBreadcrumb"),
    },
  ];

  if (practiceSet?.collection_id !== "0") {
    breadcrumbs.push({
      to: `/t/my-library/folders/${practiceSet?.collection_id}`,
      label: collection?.name || "",
    });
  }

  breadcrumbs.push({
    label: practiceSet?.title || "",
    to: "",
  });

  const isContentSpecialist = authUser?.role === UserRole.ContentSpecialist;

  let preventCertifiedItemAdd =
    practiceSet?.is_certified ||
    practiceSet?.availability !== PracticeSetAvailability.Private;
  let preventPremiumItemAdd =
    practiceSet?.is_premium ||
    practiceSet?.availability !== PracticeSetAvailability.Private;
  const allowPublishCertified =
    !practiceSet?.is_certified && !items.some((item) => item.is_certified);
  const allowPublishPremium =
    !practiceSet?.is_premium && !items.some((item) => item.is_premium);
  let allowPublish = allowPublishCertified && allowPublishPremium;
  let handleSaveOrder = practiceSet?.is_certified ? noOp : handleReorder;
  let handleStartOrder = practiceSet?.is_certified ? noOp : handleStartReorder;
  let handleCancelOrder = practiceSet?.is_certified
    ? noOp
    : handleCancelReorder;
  let handleEditQuestion = practiceSet?.is_certified
    ? noOp
    : handlePracticeSetItemEdit;
  let handleDuplicateQuestion = practiceSet?.is_certified
    ? noOp
    : handleDuplicatePracticeSetItem;
  let handleDeleteQuestion = practiceSet?.is_certified
    ? noOp
    : handlePracticeSetItemDeleteIntent;

  if (isContentSpecialist) {
    preventCertifiedItemAdd = false;
    preventPremiumItemAdd = false;
    allowPublish = true;
    handleSaveOrder = handleReorder;
    handleStartOrder = handleStartReorder;
    handleCancelOrder = handleCancelReorder;
    handleEditQuestion = handlePracticeSetItemEdit;
    handleDuplicateQuestion = handleDuplicatePracticeSetItem;
    handleDeleteQuestion = handlePracticeSetItemDeleteIntent;
  }

  const handleEditPracticeSet =
    practiceSet?.is_certified && !isContentSpecialist
      ? noOp
      : () => {
          setIsDetailFlyoutOpen(true);
          trackEvent(
            AnalyticsEvent.TeacherDashboard_PracticeSetDetail_InfoCard_EditSetIntent,
            {
              practiceSetId: practiceSetId,
            }
          );
        };
  const handleAddQuestion =
    practiceSet?.is_certified && !isContentSpecialist
      ? noOp
      : () => {
          setIsAddQuestionsFlyoutOpen(true);
        };

  const handleCreateSessionConfirm = () => {
    trackEvent(
      AnalyticsEvent.TeacherDashboard_PracticeSetDetail_InfoCard_StartSession,
      { practiceSetId, isCertified: practiceSet?.is_certified || false }
    );
    trackHubSpotEvent(AnalyticsEvent.HubSpot_MyLibraryStartLiveSession);
  };

  const {
    createSessionElement,
    handleCreateIntent: handleCreateSessionIntent,
  } = useCreateSession({
    practiceSetId: practiceSetId,
    practiceSetCreatedById: practiceSet?.created_by || "",
    onSubmit: handleCreateSessionConfirm,
  });

  const screenProps = {
    practiceSet,
    practiceSetQuestionsData: items,
    navigationData: {
      ...navigationData,
      breadcrumbs,
    },
    practiceSetAddQuestionsData: {
      searchLoading: isSearchLoading,
      isOpen: isAddQuestionsFlyoutOpen,
      subjects: subjectOptionsWithAny,
      grades: gradeOptionsWithAny,
      libraries: [
        {
          label: t("practiceSetDetailContainer.searchOptionPublicLibrary"),
          value: PracticeSetAvailability.Public,
        },
        {
          label: t("practiceSetDetailContainer.searchOptionDomainLibrary"),
          value: PracticeSetAvailability.Domain,
          isDisabled: hasNoPremiumAccess,
          leftIcon: hasNoPremiumAccess ? <Icon icon="lock" /> : undefined,
          tooltipText: hasNoPremiumAccess
            ? t("common.premiumTooltipFeature")
            : undefined,
        },
        {
          label: t("practiceSetDetailContainer.searchOptionPrivateLibrary"),
          value: PracticeSetAvailability.Private,
        },
      ],
      practiceSet,
      practiceSetItems: searchResults,
      states: stateOptionsWithAny,
      preventCertifiedItemAdd,
      preventPremiumItemAdd,
      handleChangeFilter: noOp,
      handleAddToSet: handleAddPracticeSetItemFromSearch,
      handleSearch: handlePracticeSetItemSearch,
      handleAddNew: handleAddPracticeSetItem,
      handleClose: () => {
        setIsAddQuestionsFlyoutOpen(false);
      },
      handleEditPracticeSet,
    },
    practiceSetImportQuestionsData: {
      practiceSet,
      isOpen: isImportQuestionsFlyoutOpen,
      handleClose: () => {
        setIsImportQuestionsFlyoutOpen(false);
      },
    },
    practiceSetDetailData: {
      practiceSet,
      isOpen: isDetailFlyoutOpen,
      showCncFields: authUser?.role === UserRole.ContentSpecialist,
      allowPublish,
      isLoading: isPracticeSetEditLoading,
      options: {
        folders: collections.map((c) => ({
          value: c.id,
          label: c.name,
        })),
        subjects: subjectOptions,
        gradeLevels: gradeOptions,
        languages: supportedLangs.map((l) => ({
          value: l,
          label: langLabelMap[l],
        })),
      },
      handleSubmit: handlePracticeSetEdit,
      handleCoverImageChange: handleCoverImageChange,
      handleClose: () => {
        setIsDetailFlyoutOpen(false);
      },
    },

    emptyPracticeSetData: {
      ctaClick: () => {
        setIsAddQuestionsFlyoutOpen(true);
      },
    },

    practiceSetQuestionOrderData: {
      handleSaveOrder,
      handleStartOrder,
      handleCancelOrder,
      isLoading: isReorderLoading,
    },

    handlePreviewPracticeSet: () => {
      history.push(`/t/my-library/sets/${practiceSetId}/preview`);
    },
    handleDuplicatePracticeSet: () =>
      practiceSet && practiceSetHandlers.handleDuplicate(practiceSet),
    handleDeletePracticeSet: () =>
      practiceSet && practiceSetHandlers.handleDelete(practiceSet),
    handleEditPracticeSet,
    handleAddQuestion,
    handleEditQuestion,
    handleDuplicateQuestion,
    handlePreviewQuestion: noOp,
    handleDeleteQuestion,
    handleLiveSession: () => {
      trackEvent(
        AnalyticsEvent.TeacherDashboard_PracticeSetDetail_CreateSessionFlyout_CreateCreateSessionIntent,
        { practiceSetId }
      );
      handleCreateSessionIntent();
    },
    handleCreateAssignment: handleCreateAssignment,
    handleImportQuestions: () => {
      setIsImportQuestionsFlyoutOpen(true);
    },
  };

  const showPremium = !isFeatureEnabled("playtime.teacher.hide_premium");

  return (
    <>
      <TeacherPracticeSetScreen
        {...screenProps}
        isLoading={!isPracticeSetAndItemsReady}
        showPremiumMarker={showPremium}
      />
      <Route path={`${match.path}/:questionType?/:itemId?`}>
        {!!practiceSet && (
          <PracticeSetItemFlyout
            practiceSet={practiceSet}
            items={items}
            showPremiumMarker={showPremium}
          />
        )}
      </Route>
      {deletePracticeSetItemModal}
      {practiceSetHandlerComponents}
      {createAssignmentFlyout}
      {createSessionElement}
    </>
  );
};

export default PracticeSetDetail;
