import { Flex, VStack, useDisclosure } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Heading } from "adminComponents/atoms/Heading";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils";

import { StudentPreviewModal } from "./StudentPreviewModal";

export const TeacherTourStep3Disabled: React.FC = () => {
  const { t } = useTranslation("admin");
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        gap={pxToRem(32)}
      >
        <Heading as="h1" variant="adminH2">
          {t("teacherTour.step3.heading")}
        </Heading>
        <VStack>
          <Text variant="adminP1">
            {t("teacherTour.step3.errorDescription")}
          </Text>
          <Text
            variant="adminLinkLarge"
            color="utility.link"
            cursor="pointer"
            onClick={onOpen}
          >
            {t("teacherTour.step3.studentPreviewLink")}
          </Text>
        </VStack>
      </Flex>
      <StudentPreviewModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};
