import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { useAxios } from "../../hooks/useAxios";

export interface IDeleteClassroomArgs {
  id: string;
}

export interface IUseDeleteClassroomArgs {
  onSuccess?: () => void;
  onError?: () => void;
  onSettled?: () => void;
}

/**
 * Delete a classroom
 * @returns
 */
export default function useDeleteClassroom({
  onSuccess,
  onError,
  onSettled,
}: IUseDeleteClassroomArgs): UseMutationResult<
  unknown,
  unknown,
  IDeleteClassroomArgs
> {
  const axios = useAxios();
  const queryClient = useQueryClient();

  const deleteClassroom = async (args: IDeleteClassroomArgs) => {
    const { id } = args;

    const response = await axios({
      method: "delete",
      url: `/v1/groups/${id}`,
    }).then((r) => r.data);

    queryClient.invalidateQueries("classrooms", { exact: false });

    return response;
  };

  const mutation = useMutation(deleteClassroom, {
    onSuccess,
    onError,
    onSettled,
  });

  return mutation;
}
