import { Box, Flex, Spinner, usePrefersReducedMotion } from "@chakra-ui/react";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { animated, config, useSpring } from "react-spring";
import { usePrevious, useTimeout } from "react-use";

import { Heading } from "adminComponents/atoms/Heading";
import { TipCard } from "adminComponents/molecules/TipCard";
import { TemplateWithVerticalCenteredContent } from "adminComponents/templates/TemplateWithVerticalCenteredContent";
import { pxToRem } from "adminComponents/utils";

interface IStudentLoadingScreenProps {
  handleComplete: () => void;
}

const MIN_LOADING_TIME = 1000;
const TIP_COUNT = 20;

const AnimatedBox = animated(Box);

export const StudentLoadingScreen: React.FC<IStudentLoadingScreenProps> = ({
  handleComplete,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const prefersReducedMotion = usePrefersReducedMotion();

  const tipContent = useMemo(() => {
    const tipIndex = Math.floor(Math.random() * TIP_COUNT);
    return t(`studentLoadingScreen.tip${tipIndex}`);
  }, [t]);

  // Wait for min loading time before indicating a completed load
  const [checkMinTime] = useTimeout(MIN_LOADING_TIME);
  const isLoading = !checkMinTime();
  const prevIsLoading = usePrevious(isLoading);
  useEffect(() => {
    if (prevIsLoading && !isLoading) {
      handleComplete();
    }
  }, [prevIsLoading, isLoading, handleComplete]);

  const tipSpring = useSpring({
    from: {
      bottom: -200,
    },
    to: {
      bottom: 0,
    },
    immediate: prefersReducedMotion,
    config: config.gentle,
  });

  return (
    <TemplateWithVerticalCenteredContent isLoading={false}>
      <Flex
        flexDirection={["column", null, "row"]}
        alignItems="center"
        justifyContent="center"
      >
        <Spinner
          color="utility.link"
          size="xl"
          speed="1.5s"
          thickness={pxToRem(4)}
        />
        <Box mt={[pxToRem(14), null, 0]} ml={[0, null, pxToRem(14)]}>
          <Heading variant="adminH5" as="h1">
            {t("studentLoadingScreen.loading")}
          </Heading>
        </Box>
      </Flex>
      <AnimatedBox
        maxW="container.lg"
        position="fixed"
        p={[pxToRem(8), pxToRem(16), pxToRem(24)]}
        style={tipSpring}
      >
        <TipCard heading={t("studentLoadingScreen.tipHeading")}>
          {tipContent}
        </TipCard>
      </AnimatedBox>
    </TemplateWithVerticalCenteredContent>
  );
};
