import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";

export interface IFetchOrganizationAssignmentCountParams {
  days_ago: number;
}

export interface IFetchOrganizationAssignmentCountResponse {
  assignment_count: string;
}

export default function useFetchOrganizationAssignmentCount({
  days_ago,
}: IFetchOrganizationAssignmentCountParams): UseQueryResult<
  IFetchOrganizationAssignmentCountResponse,
  unknown
> {
  const axios = useAxios();

  const query = useQuery<IFetchOrganizationAssignmentCountResponse>(
    ["organization-assignment-count", { days_ago }],
    async (): Promise<IFetchOrganizationAssignmentCountResponse> => {
      return await axios
        .get(`/v1/organizations/assignments/count`, {
          params: {
            days_ago,
          },
        })
        .then((r) => {
          return r.data as IFetchOrganizationAssignmentCountResponse;
        });
    }
  );

  return query;
}
