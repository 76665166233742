import { useMemo } from "react";

// Create a new randomized array
const useShuffle = <T>(a: Array<T>): Array<T> => {
  return useMemo(() => {
    return shuffleArray(a);
  }, [a]);
};

export const shuffleArray = <T>(a: Array<T>): Array<T> => {
  const array = a.concat();

  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }

  return array;
};

export default useShuffle;
