import { Box, useBreakpointValue } from "@chakra-ui/react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { usePrevious, useUnmount } from "react-use";

import { Text } from "adminComponents/atoms/Text";
import { ShareAvatarControls } from "adminComponents/molecules/ShareAvatarControls";
import { ShareAvatarOverlayLayout } from "adminComponents/molecules/ShareAvatarOverlayLayout";
import { ShareAvatarPreview } from "adminComponents/molecules/ShareAvatarPreview";
import { AvatarPhotoStage } from "adminComponents/organisms/AvatarPhotoStage";
import { pxToRem } from "adminComponents/utils";
import { AvatarAnimations } from "links/lib/constants";
import {
  CoverImageBGColorSchemeOptions,
  CoverImageBGColorSchemeType,
  CoverImageBGPatternType,
  IAvatarAnimation,
  SocialMediaShareProvider,
} from "links/lib/types";
import { SpineStage } from "sharedComponents/molecules/AvatarStage/lib/SpineStage";

import { AvatarPhotoControls } from "../AvatarPhotoControls";

export interface IShareAvatarModalProps {
  atlasUrl: string;
  skeletonUrl: string;
  handleClose: () => void;
  isOpen: boolean;
  showShareProviders: boolean;
  handleDownload: (blobUrl: string) => void;
  handleShare: (provider: SocialMediaShareProvider, blobUrl: string) => void;
}

const brandBackgroundOptions: Array<CoverImageBGPatternType> = [
  "PP_LOGO",
  "PP_OG",
];

const backgroundOptions: Readonly<Array<CoverImageBGPatternType>> = [
  ...brandBackgroundOptions,
  "OTHER_CULTURE_BG_PATTERN",
  "OTHER_FUN_PARTY",
  "ENGLISH_BOOKS_READING",
  "ENGLISH_PUNCTUATION_GRAMMAR",
  "HISTORY",
  "MATH_ABSTRACT",
  "MATH_OPERATION_SYMBOLS",
  "PERFORMATIVE_ARTS",
  "SCIENCE_BIOLOGY_BG_PATTERN",
  "SCIENCE_CHEMISTRY_PHYSICS",
  "SCIENCE_SPACE",
  "VISUAL_ARTS",
  "WORLD_LANGUAGE",
  "ELECTIVE_BUSINESS_FINANCE",
  "ELECTIVE_COOKING_BAKING",
  "ELECTIVE_HEALTH_PE",
  "ELECTIVE_WOODWORKING",
  "TRANSPARENT",
];

export const ShareAvatarModal: React.FC<IShareAvatarModalProps> = ({
  handleClose,
  atlasUrl,
  skeletonUrl,
  isOpen,
  showShareProviders,
  handleDownload,
  handleShare,
}) => {
  // blobUrl
  const [currentCapture, setCurrentCapture] = useState<string | undefined>(
    undefined
  );
  const [bgPattern, setBGPattern] =
    useState<CoverImageBGPatternType>("PP_LOGO");
  const [bgColorScheme, setBGColorScheme] =
    useState<CoverImageBGColorSchemeType>(CoverImageBGColorSchemeOptions[0]);
  const isMobile = useBreakpointValue({ base: true, lg: false }) ?? true;
  const avatarStageRef = useRef<SpineStage | undefined>(undefined);
  const { t } = useTranslation("admin", { useSuspense: false });

  const animations = useMemo(() => AvatarAnimations.Pose, []);

  // Simple label generator which uses the animation index for naming
  const animationLabelMap = useMemo(() => {
    const labelMap: { [key: string]: string } = {};

    AvatarAnimations.Pose.forEach((a) => {
      const name = a.name
        .split("/")[1]
        .split("_")
        .slice(0, -1)
        .map((part) => `${part[0].toUpperCase()}${part.slice(1)}`)
        .join("");
      const number = a.name.split("_").slice(-1);

      labelMap[a.name] = t(`shareAvatarModal.animationPose${name}`, {
        animationNumber: number,
      });
    });

    return labelMap;
  }, [t]);

  const [animation, setAnimation] = useState<IAvatarAnimation>(animations[0]);

  useUnmount(() => {
    avatarStageRef.current?.dispose();

    if (currentCapture) {
      URL.revokeObjectURL(currentCapture);
    }
  });

  const prevIsOpen = usePrevious(isOpen);
  useEffect(() => {
    if (prevIsOpen && !isOpen && currentCapture) {
      URL.revokeObjectURL(currentCapture);
      setCurrentCapture(undefined);
    }
  }, [prevIsOpen, isOpen, currentCapture]);

  const handleSnapRequest = () => {
    if (!avatarStageRef.current) return;

    avatarStageRef.current.onCapture((canvas: HTMLCanvasElement) => {
      canvas.toBlob((blob) => {
        if (!blob) return;
        const blobUrl = URL.createObjectURL(blob);
        setCurrentCapture(blobUrl);
      });
    });

    avatarStageRef.current.requestCapture();
  };

  const handleDownloadRequest = () => {
    if (!currentCapture) return;
    handleDownload?.(currentCapture);
  };

  const handleResetShare = () => {
    if (!currentCapture) return;

    URL.revokeObjectURL(currentCapture);
    setCurrentCapture(undefined);
  };

  const handleShareRequest = (provider: SocialMediaShareProvider) => {
    if (!currentCapture) return;
    handleShare?.(provider, currentCapture);
  };

  const leftColumnContent = (
    <Box maxW={{ base: "auto", lg: pxToRem(560) }} mx="auto">
      {currentCapture ? (
        <ShareAvatarPreview imageUrl={currentCapture} />
      ) : (
        <>
          <AvatarPhotoStage
            atlasUrl={atlasUrl}
            skeletonUrl={skeletonUrl}
            animation={animation}
            bgColorScheme={bgColorScheme}
            bgPattern={bgPattern}
            ref={avatarStageRef}
          />
          <Text variant="adminP2" textAlign="center" mt={pxToRem(26)}>
            {isMobile
              ? t("shareAvatarModal.panAndZoom_mobile")
              : t("shareAvatarModal.panAndZoom_desktop")}
          </Text>
        </>
      )}
    </Box>
  );

  const rightColumnContent = currentCapture ? (
    <ShareAvatarControls
      handleBack={handleResetShare}
      handleDownload={handleDownloadRequest}
      handleShare={handleShareRequest}
      showShareProviders={showShareProviders}
    />
  ) : (
    <AvatarPhotoControls
      bgPatterns={backgroundOptions}
      bgColors={CoverImageBGColorSchemeOptions}
      animations={animations.map((a) => ({
        label: animationLabelMap[a.name],
        value: a.name,
      }))}
      selectedBGColor={bgColorScheme}
      selectedBGPattern={bgPattern}
      selectedAnimation={{
        label: animationLabelMap[animation.name],
        value: animation.name,
      }}
      handleBGPatternChange={setBGPattern}
      handleBGColorChange={setBGColorScheme}
      handleAnimationChange={(animOption) => {
        const anim = animations.find((a) => a.name === animOption.value);
        if (anim) setAnimation(anim);
      }}
      handleSubmit={handleSnapRequest}
    />
  );

  return (
    <ShareAvatarOverlayLayout
      handleBack={handleClose}
      handleClose={handleClose}
      isOpen={isOpen}
      leftColumnContent={leftColumnContent}
      rightColumnContent={rightColumnContent}
    />
  );
};
