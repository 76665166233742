import { Flex } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Tabs } from "adminComponents/atoms/Tabs";
import {
  INavigationHeaderProps,
  NavigationHeader,
} from "adminComponents/molecules/NavigationHeader";
import { StudentPracticeSetBanner } from "adminComponents/molecules/StudentPracticeSetBanner";
import {
  StudentPracticeSetReportHeroCard,
  IProps as StudentPracticeSetReportHeroCardProps,
} from "adminComponents/molecules/StudentPracticeSetReportHeroCard";
import {
  ReportingPage,
  ReportingSpecificSessionPicker,
} from "adminComponents/organisms/ReportingSpecificSessionPicker";
import { TemplateWithCenteredHeroOneColumn } from "adminComponents/templates/TemplateWithCenteredHeroOneColumn";
import { pxToRem } from "adminComponents/utils";
import { IAssignment, IPracticeSetSession, IUser } from "links/lib/types";
import { StudentPracticeSetDetailTab } from "screens/TeacherClassrooms/PracticeSetDetail/components/PracticeSetStudentDetail";

export interface StudentPracticeSetReportScreenProps {
  heroCard?: StudentPracticeSetReportHeroCardProps;
  isLoading?: boolean;
  navigationData: INavigationHeaderProps;
  student?: IUser;
  handleTabChange: (tab: StudentPracticeSetDetailTab) => void;
  tabIndex: number;
  showPremiumMarker?: boolean;
  pastAssignments?: Array<IAssignment>;
  specificAssignment?: IAssignment;
  setSpecificAssignment?: (assignment?: IAssignment) => void;
  pastSessions?: Array<IPracticeSetSession>;
  specificSession?: IPracticeSetSession;
  setSpecificSession?: (session?: IPracticeSetSession) => void;
}

export const StudentPracticeSetReportScreen: React.FC<
  StudentPracticeSetReportScreenProps
> = ({
  isLoading,
  heroCard,
  navigationData,
  student,
  handleTabChange,
  tabIndex,
  children,
  showPremiumMarker = true,
  pastAssignments,
  specificAssignment,
  setSpecificAssignment,
  pastSessions,
  specificSession,
  setSpecificSession,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  return (
    <TemplateWithCenteredHeroOneColumn
      nav={<NavigationHeader {...navigationData} isLoading={isLoading} />}
      contentAboveShiftedHero={
        student && <StudentPracticeSetBanner student={student} />
      }
      heroContent={
        heroCard && <StudentPracticeSetReportHeroCard {...heroCard} />
      }
      isHeroShifted
      isLoading={isLoading}
      variant="default"
    >
      <Flex flexDir="column" gap={pxToRem(40)}>
        <ReportingSpecificSessionPicker
          pageType={ReportingPage.Student}
          pastAssignments={pastAssignments}
          specificAssignment={specificAssignment}
          setSpecificAssignment={setSpecificAssignment}
          pastSessions={pastSessions}
          specificSession={specificSession}
          setSpecificSession={setSpecificSession}
        />
        <Tabs
          tabIndex={tabIndex}
          handleChange={handleTabChange}
          tabData={[
            {
              content: <></>,
              label: t("studentPracticeReport.questions"),
              isPremium: showPremiumMarker,
            },
            {
              content: <></>,
              label: t("studentPracticeReport.standards"),
              isPremium: showPremiumMarker,
            },
          ]}
          variant="adminScreenTabs"
        />
        {children}
      </Flex>
    </TemplateWithCenteredHeroOneColumn>
  );
};
