import { Box } from "@chakra-ui/react";
import React from "react";

import { LoadingSpinner } from "adminComponents/atoms/LoadingSpinner";
import { AssignmentsTab as AdminAssignmentsTab } from "adminComponents/screens/StudentDetailScreen/components/AssignmentsTab";
import { pxToRem } from "adminComponents/utils";
import { usePageTrack } from "lib/contexts/analytics";
import { AnalyticsPage } from "links/lib/types";

import { useAssignmentData } from "../hooks/useAssignmentData";

interface IAssignmentsTabProps {
  classroomId: string;
  studentId: string;
}

export const AssignmentsTab: React.FC<IAssignmentsTabProps> = ({
  classroomId,
  studentId,
}) => {
  usePageTrack(
    AnalyticsPage.TeacherDashboard_ClassroomDetail_StudentDetail_Assignments
  );

  const {
    activeAssignmentData,
    pastAssignmentData,
    isLoading,
    showViewMoreAssignments,
    handleViewMoreAssignments,
  } = useAssignmentData({ classroomId, studentId });

  return (
    <>
      {isLoading ? (
        <Box mt={pxToRem(20)}>
          <LoadingSpinner />
        </Box>
      ) : (
        <>
          <AdminAssignmentsTab
            //dont think these handlers are used on this screen - need to investigate
            handleDeleteAssignmentCard={() => console.log("delete assignment")} //TODO
            handleEditAssignmentCard={() =>
              console.log("handle edit assignment")
            } //TODO
            handleViewMoreAssignments={handleViewMoreAssignments}
            currentAssignmentsData={activeAssignmentData}
            pastAssignmentsData={pastAssignmentData}
            showViewMoreAssignments={showViewMoreAssignments}
            studentId={studentId}
          />
        </>
      )}
    </>
  );
};
