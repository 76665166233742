import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { IClassroomPracticeSet } from "../../types";

export interface IFetchClassroomPracticeSetsParams {
  classroom_id: string;
  enabled?: boolean;
}

export interface IFetchClassroomPracticeSetsResponse {
  classroom_practice_sets: Array<IClassroomPracticeSet>;
}

export default function useFetchClassroomPracticeSets({
  classroom_id,
  enabled = true,
}: IFetchClassroomPracticeSetsParams): UseQueryResult<
  IFetchClassroomPracticeSetsResponse,
  unknown
> {
  const axios = useAxios();

  const query = useQuery<IFetchClassroomPracticeSetsResponse>(
    ["classrooms-practice-sets", { classroom_id }],
    async (): Promise<IFetchClassroomPracticeSetsResponse> => {
      return await axios
        .get(`/v1/group-practice-sets/${classroom_id}`)
        .then(
          (r) => r.data as { group_practice_sets: Array<IClassroomPracticeSet> }
        )
        .then((r) => ({
          classroom_practice_sets: r.group_practice_sets,
        }));
    },
    { enabled }
  );

  return query;
}
