import { Box } from "@chakra-ui/react";
import React, { useMemo } from "react";

import { LoadingSpinner } from "adminComponents/atoms/LoadingSpinner";
import {
  SessionsTab as AdminSessionsTab,
  ITopTeacher,
} from "adminComponents/screens/AdminHomepage/components/SessionsTab";
import { pxToRem } from "adminComponents/utils";
import { useErrorToast } from "adminComponents/utils/toast";
import { usePageTrack } from "lib/contexts/analytics";
import { useAuth } from "links/lib/features/auth";
import {
  useFetchOrganizationSessionCount,
  useFetchTopOrganizationSessionCreators,
} from "links/lib/features/sessions";
import { useFetchEducatorWithGroupDetailsBulk } from "links/lib/features/users";
import { IFetchEducatorWithGroupDetailsResponse } from "links/lib/features/users/useFetchEducatorWithGroupDetailsBulk";
import {
  AnalyticsPage,
  IGradeLevel,
  ISubject,
  SessionType,
} from "links/lib/types";

interface ISsessionsTabProps {
  gradeLevels: Array<IGradeLevel>;
  subjects: Array<ISubject>;
  isLoading?: boolean;
  sideCard?: JSX.Element;
}

export const SessionsTab: React.FC<ISsessionsTabProps> = ({
  gradeLevels,
  subjects,
  isLoading,
  sideCard,
}) => {
  const { hasNoPremiumAccess } = useAuth();
  usePageTrack(AnalyticsPage.AdminDashboard_Sessions);

  const teacherSessionsFetch = useFetchTopOrganizationSessionCreators({
    days_ago: 0,
    limit: 9,
  });
  const teachersWithGroupDetailsFetch = useFetchEducatorWithGroupDetailsBulk({
    userIds:
      teacherSessionsFetch.data?.top_organization_class_session_creators?.map(
        (teacher) => teacher.user_id
      ) || [],
    enabled:
      (teacherSessionsFetch.data?.top_organization_class_session_creators
        ?.length || 0) > 0,
  });

  const classSessionCountFetch = useFetchOrganizationSessionCount({
    session_type: SessionType.Class,
    days_ago: 0,
  });

  useErrorToast(teacherSessionsFetch.error);
  useErrorToast(teachersWithGroupDetailsFetch.error);
  useErrorToast(classSessionCountFetch.error);

  const teachGroupDetailsMap = useMemo(() => {
    const teacherGroupDetailsMap = new Map<
      string,
      IFetchEducatorWithGroupDetailsResponse
    >();

    if (
      teachersWithGroupDetailsFetch.data?.teachers &&
      teachersWithGroupDetailsFetch.data?.teachers.length > 0
    ) {
      teachersWithGroupDetailsFetch.data?.teachers.forEach((teacher) =>
        teacherGroupDetailsMap.set(teacher.user.id, teacher)
      );
    }

    return teacherGroupDetailsMap;
  }, [teachersWithGroupDetailsFetch.data?.teachers]);

  const teachers = useMemo(() => {
    const teachers: ITopTeacher[] = [];

    if (
      teachGroupDetailsMap.size > 0 &&
      teacherSessionsFetch.data?.top_organization_class_session_creators &&
      teacherSessionsFetch.data?.top_organization_class_session_creators
        .length > 0
    ) {
      teacherSessionsFetch.data?.top_organization_class_session_creators.forEach(
        (teacherSessions) => {
          const teacherGroup = teachGroupDetailsMap.get(
            teacherSessions.user_id
          );
          if (teacherGroup) {
            teachers.push({
              teacher: teacherGroup.user,
              group_count: teacherGroup.group_count,
              session_count: teacherSessions.sessions_count,
              student_count: teacherGroup.student_count,
            });
          }
          return teachers;
        }
      );
    }

    return teachers;
  }, [
    teachGroupDetailsMap,
    teacherSessionsFetch.data?.top_organization_class_session_creators,
  ]);

  const classSessionCount = parseInt(
    classSessionCountFetch.data?.session_count || "0"
  );

  const showLoading =
    isLoading ||
    teacherSessionsFetch.isLoading ||
    teachersWithGroupDetailsFetch.isLoading ||
    classSessionCountFetch.isLoading;

  return (
    <>
      {showLoading ? (
        <Box mt={pxToRem(60)} mb={pxToRem(120)}>
          <LoadingSpinner />
        </Box>
      ) : (
        <AdminSessionsTab
          topTeachers={teachers}
          gradeLevels={gradeLevels}
          subjects={subjects}
          classSessionCount={classSessionCount}
          hasNoPremiumAccess={hasNoPremiumAccess}
          key={teachers.length}
          sideCard={sideCard}
        />
      )}
    </>
  );
};
