import { useMemo } from "react";

import { ISessionGroup } from "../types";

export function useGroupShortName(group: ISessionGroup): string {
  const shortName = useMemo(() => {
    if (!group) return "";
    const { noun } = group;

    const name = noun[0].toUpperCase() + noun.slice(1);

    return name;
  }, [group]);

  return shortName;
}
