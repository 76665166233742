import { Box, Stack } from "@chakra-ui/react";
import React, { useState } from "react";
import { useInterval } from "react-use";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { Light } from "sessionComponents/atoms/BigBoardLight";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";

const getIsLit = (index: number, evensLit: boolean) => {
  const even = index % 2 === 0;

  return even === evensLit;
};

export interface IBorderLightsProps {
  allLit?: boolean;
}

export const BorderLights: React.FC<IBorderLightsProps> = ({
  allLit = false,
  children,
}) => {
  const [evensLit, setEvensLit] = useState(true);
  const { match: currentBreakpoints } = useBreakpoints();

  // only update evensLit if not allLit
  useInterval(() => setEvensLit(!evensLit), !allLit ? 700 : null);

  return (
    <Box
      borderRadius="5%"
      h="fit-content"
      w="fit-content"
      position="relative"
      backgroundColor="light.background"
      boxShadow={`0 0 10px 10px rgba(0,0,0,0.5)`}
    >
      <Box
        position="absolute"
        top={0}
        left={0}
        w="full"
        h={pxToRem(currentBreakpoints.padding)}
      >
        <Stack
          w="100%"
          h="100%"
          direction="row"
          alignContent="center"
          alignItems="center"
          justifyContent="space-evenly"
        >
          {Array.from({ length: 8 }, (_, index) => {
            return (
              <Light
                key={index}
                isLit={allLit ? true : getIsLit(index, evensLit)}
              />
            );
          })}
        </Stack>
      </Box>
      <Box
        position="absolute"
        bottom={0}
        left={0}
        w="full"
        h={pxToRem(currentBreakpoints.padding)}
      >
        <Stack
          w="100%"
          h="100%"
          direction="row-reverse"
          alignContent="center"
          alignItems="center"
          justifyContent="space-evenly"
        >
          {Array.from({ length: 8 }, (_, index) => {
            return (
              <Light
                key={index}
                isLit={allLit ? true : getIsLit(index, evensLit)}
              />
            );
          })}
        </Stack>
      </Box>
      <Box
        position="absolute"
        top={0}
        left={0}
        h="full"
        w={pxToRem(currentBreakpoints.padding)}
      >
        <Stack
          h="100%"
          direction="column-reverse"
          alignContent="center"
          alignItems="center"
          justifyContent="space-evenly"
        >
          {Array.from({ length: 8 }, (_, index) => {
            return (
              <Light
                key={index}
                isLit={allLit ? true : getIsLit(index, evensLit)}
              />
            );
          })}
        </Stack>
      </Box>

      <Box
        position="absolute"
        top={0}
        right={0}
        h="full"
        w={pxToRem(currentBreakpoints.padding)}
      >
        <Stack
          h="100%"
          direction="column"
          alignContent="center"
          alignItems="center"
          justifyContent="space-evenly"
        >
          {Array.from({ length: 8 }, (_, index) => {
            return (
              <Light
                key={index}
                isLit={allLit ? true : getIsLit(index, evensLit)}
              />
            );
          })}
        </Stack>
      </Box>

      <Box p={pxToRem(currentBreakpoints.padding)}>{children}</Box>
    </Box>
  );
};
