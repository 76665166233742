import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  Flex,
  ThemeTypings,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Heading } from "adminComponents/atoms/Heading";
import {
  IconCTAButtonWrapper as BackButtonWrapper,
  IconCTAButton,
} from "adminComponents/atoms/IconCTAButton";
import { Modal } from "adminComponents/atoms/Modal";

export interface IProps {
  useModal?: boolean;
  modalSize?: ThemeTypings["components"]["Modal"]["sizes"];
  isOpen: boolean;
  leftColumnContent: React.ReactNode;
  rightColumnContent: React.ReactNode;
  handleBack: () => void;
  handleClose: () => void;
}

export const EditAvatarOverlayLayout: React.FC<IProps> = ({
  useModal = false,
  modalSize = "full",
  isOpen,
  leftColumnContent,
  rightColumnContent,
  handleBack,
  handleClose,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const styles = useMultiStyleConfig("AdminEditAvatarOverlayLayout", {});

  // This component was originally designed as a Drawer, but passing useModal: true
  // will override it to be a Modal instead.
  if (useModal) {
    return (
      <Modal
        isOpen={isOpen}
        onClose={handleClose}
        size={modalSize}
        showBackButton={false}
        modalBodyProps={{ padding: "0 !important" }}
      >
        <Flex sx={styles.container}>
          <Box sx={styles.leftColumn} display="flex" alignItems="center">
            {leftColumnContent}
          </Box>
          <Box sx={styles.rightColumn}>{rightColumnContent}</Box>
        </Flex>
      </Modal>
    );
  }

  return (
    <Drawer isOpen={isOpen} onClose={handleClose} size={modalSize} isFullHeight>
      <DrawerContent sx={styles.content}>
        <DrawerBody sx={styles.body}>
          <Flex sx={styles.container}>
            <Box sx={styles.leftColumn}>
              <BackButtonWrapper sx={styles.iconWrapper}>
                <IconCTAButton
                  sx={styles.icon}
                  icon="keyboard_arrow_left"
                  boxSize={24}
                  iconSize={20}
                  ariaLabel={t("common.ariaBackToPreviousScreen")}
                  onClick={handleBack}
                />
                <Heading as="h1" variant="adminH6Bold">
                  {t("editAvatar.edit")}
                </Heading>
              </BackButtonWrapper>
              {leftColumnContent}
            </Box>
            <Box sx={styles.rightColumn}>{rightColumnContent}</Box>
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
