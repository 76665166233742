import React, { useEffect, useRef, useState } from "react";
import { usePrevious, useUnmount } from "react-use";

import { useAuth } from "links/lib/features/auth";

import {
  ISpineAvatarAnimation,
  SpineAvatar,
  SpineAvatarAnimationListener,
  TransitionType,
} from "../lib/SpineAvatar";
import { SpineStage } from "../lib/SpineStage";
import { StageCarePackage } from "../lib/StageCarePackage";
import { StageNametag } from "../lib/StageNametag";
import { StageOverlay } from "../lib/StageOverlay";
import QuickPlayCarePackage from "../lib/quick_play_care_package.svg";
import { NametagArrowPosition } from "../types";

export interface IAvatarStageMemberProps {
  // set by parent
  spineStage?: SpineStage;
  // set by parent
  stageOverlay?: StageOverlay;
  skeletonUrl: string;
  atlasUrl: string;
  x: number;
  y: number;
  scale: number;
  drawOrder?: number;
  animation: ISpineAvatarAnimation | null;
  nametag?: {
    fontSize: number;
    paddingX: number;
    paddingY: number;
    textColor: string;
    yOffset: number;
    xOffset: number;
    name: string;
    borderRadius?: number;
    arrowPosition?: NametagArrowPosition;
    arrowSize?: number;
  };
  onAnimationEnd?: (animationName: string) => void;
  onAnimationComplete?: (animationName: string) => void;
  carePackage?: {
    yOffset: number;
  };
}

const AvatarStageMember: React.FC<IAvatarStageMemberProps> = ({
  skeletonUrl,
  atlasUrl,
  spineStage,
  stageOverlay,
  x,
  y,
  scale,
  animation,
  drawOrder,
  nametag,
  carePackage,
  onAnimationEnd,
  onAnimationComplete,
}) => {
  const spineAvatarRef = useRef<SpineAvatar | null>(null);
  const nametagRef = useRef<StageNametag | null>(null);
  const carePackageRef = useRef<StageCarePackage | null>(null);

  const [carePackageImageLoaded, setCarePackageImageLoaded] = useState(false);
  const hideNametagRef = useRef<boolean>(true);

  const prevAtlasUrl = usePrevious(atlasUrl);
  const prevSkeletonUrl = usePrevious(skeletonUrl);

  useEffect(() => {
    if (
      spineAvatarRef.current &&
      spineStage &&
      (prevAtlasUrl !== atlasUrl || prevSkeletonUrl !== skeletonUrl)
    ) {
      spineStage?.unregisterAsset(spineAvatarRef.current);
      spineAvatarRef.current.dispose();

      try {
        const animationStateListener = new SpineAvatarAnimationListener({
          onEnd: onAnimationEnd,
          onComplete: onAnimationComplete,
        });

        spineAvatarRef.current = new SpineAvatar({
          skeletonUrl,
          atlasUrl,
          x,
          y,
          scale,
          animationStateListener,
          animation,
          drawOrder,
          onInitializedHandler: () => {
            // Once this handler has been called,
            // permit name tag to be shown immediately for the case that it is initialized later
            hideNametagRef.current = false;
            if (nametagRef.current) {
              nametagRef.current.setIsHidden(hideNametagRef.current);
            }
          },
        });
        spineStage.registerAsset(spineAvatarRef.current);
      } catch (err) {
        console.error("Could not create Spine Avatar: " + err);
      }
    }
  }, [
    animation,
    atlasUrl,
    drawOrder,
    onAnimationComplete,
    onAnimationEnd,
    prevAtlasUrl,
    prevSkeletonUrl,
    scale,
    skeletonUrl,
    spineStage,
    x,
    y,
  ]);

  const { isFeatureEnabled } = useAuth();

  useEffect(() => {
    if (
      !spineStage ||
      !stageOverlay ||
      nametagRef.current ||
      carePackageRef.current ||
      spineAvatarRef.current
    ) {
      return;
    }

    try {
      const animationStateListener = new SpineAvatarAnimationListener({
        onEnd: onAnimationEnd,
        onComplete: onAnimationComplete,
      });

      const transitionType = isFeatureEnabled(
        "playtime.enable_avatar_transitions"
      )
        ? TransitionType.SCALE
        : TransitionType.NONE;

      spineAvatarRef.current = new SpineAvatar({
        skeletonUrl,
        atlasUrl,
        x,
        y,
        scale,
        animationStateListener,
        animation,
        drawOrder,
        onInitializedHandler: () => {
          // Once this handler has been called,
          // permit name tag to be shown immediately for the case that it is initialized later
          hideNametagRef.current = false;
          if (nametagRef.current) {
            nametagRef.current.setIsHidden(hideNametagRef.current);
          }
        },
        transitionType,
      });
      spineStage.registerAsset(spineAvatarRef.current);

      if (nametag) {
        nametagRef.current = new StageNametag({
          x: x + nametag.xOffset,
          y: y + nametag.yOffset,
          name: nametag.name,
          fontSize: nametag.fontSize,
          paddingX: nametag.paddingX,
          paddingY: nametag.paddingY,
          textColor: nametag.textColor,
          borderRadius: nametag.borderRadius || 5,
          arrowPosition: nametag.arrowPosition || NametagArrowPosition.Top,
          arrowSize: 10,
          isHidden: hideNametagRef.current,
        });

        stageOverlay.registerAsset(nametagRef.current);
      }

      if (carePackage) {
        const img = new Image();
        img.src = QuickPlayCarePackage;
        img.onload = () => {
          setCarePackageImageLoaded(true);
          carePackageRef.current = new StageCarePackage({
            image: img,
            x,
            y: y + carePackage.yOffset,
          });

          stageOverlay.registerAsset(carePackageRef.current);
        };
      }
    } catch (err) {
      console.error("Could not create Spine Avatar: " + err);
    }
  }, [
    isFeatureEnabled,
    spineStage,
    stageOverlay,
    animation,
    atlasUrl,
    drawOrder,
    nametag,
    onAnimationComplete,
    onAnimationEnd,
    scale,
    skeletonUrl,
    x,
    y,
    carePackage,
  ]);

  useEffect(() => {
    if (!animation) return;
    if (!spineAvatarRef.current) return;

    spineAvatarRef.current.setAnimation(animation);
  }, [animation]);

  useEffect(() => {
    if (!spineAvatarRef.current || !stageOverlay) return;

    spineAvatarRef.current.setTransform(x, y, scale);

    if (nametag && nametagRef.current) {
      nametagRef.current.setTransform(x + nametag.xOffset, y + nametag.yOffset);
    }

    if (carePackage && carePackageRef.current) {
      carePackageRef.current.setTransform(x, y + carePackage.yOffset);
    }
  }, [x, y, scale, nametag, stageOverlay, carePackage, carePackageImageLoaded]);

  useEffect(() => {
    if (nametag && nametagRef.current) {
      const {
        name,
        fontSize,
        textColor,
        paddingX,
        paddingY,
        borderRadius,
        arrowPosition,
        arrowSize,
      } = nametag;

      nametagRef.current.setProps({
        name,
        fontSize,
        textColor,
        paddingX,
        paddingY,
        borderRadius: borderRadius || 5,
        arrowPosition: arrowPosition || NametagArrowPosition.Top,
        arrowSize: arrowSize || 10,
      });
    }
  }, [nametag]);

  useUnmount(() => {
    if (spineAvatarRef.current) {
      spineAvatarRef.current.transitionOut(spineStage);
    }
    if (nametagRef.current) {
      stageOverlay?.unregisterAsset(nametagRef.current);
    }
    if (carePackageRef.current) {
      stageOverlay?.unregisterAsset(carePackageRef.current);
    }
  });

  return null;
};

export { AvatarStageMember };
