import {
  Box,
  HStack,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
} from "@chakra-ui/react";
import React, { useMemo } from "react";

import { ISkill } from "links/lib/types";

export interface ISelectedSkillsDisplayProps {
  selectedSkills: Array<ISkill>;
  onSkillSelected: (skill: ISkill, isSelected: boolean) => void;
  content: {
    placeholder: string;
  };
}

const SelectedSkillsDisplay: React.FC<ISelectedSkillsDisplayProps> = ({
  selectedSkills,
  onSkillSelected,
  content,
}) => {
  const sortedSelectedSkills = useMemo(() => {
    return selectedSkills.sort(
      (a, b) => parseInt(a.id, 10) - parseInt(b.id, 10)
    );
  }, [selectedSkills]);

  const onRemoveSkill = (skill: ISkill) => {
    onSkillSelected(skill, false);
  };

  return (
    <Box height={14} borderRadius="md" w="full" bg="gray.100" p={4}>
      {sortedSelectedSkills.length === 0 && (
        <Text fontSize="sm" color="gray.700">
          {content.placeholder}
        </Text>
      )}
      {sortedSelectedSkills.length > 0 && (
        <HStack spacing={1}>
          {sortedSelectedSkills.map((skill) => (
            <Tag
              size="md"
              key={skill.id}
              borderRadius="full"
              variant="solid"
              colorScheme="blue"
            >
              <TagLabel>{skill.code}</TagLabel>
              <TagCloseButton onClick={() => onRemoveSkill(skill)} />
            </Tag>
          ))}
        </HStack>
      )}
    </Box>
  );
};

export default SelectedSkillsDisplay;
