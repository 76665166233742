// ws.state.round_groups_state
// For Teachers only. Key is the ID of the group
import React, { createContext, useContext, useMemo } from "react";

import { ISessionRoundGroupsState } from "../types";

export const SessionRoundGroupsStateContext =
  createContext<ISessionRoundGroupsState>({});

export interface ISessionRoundGroupsStateProviderProps {
  wsRoundGroupsState: ISessionRoundGroupsState;
}

export const SessionRoundGroupsStateProvider: React.FC<
  ISessionRoundGroupsStateProviderProps
> = ({ children, wsRoundGroupsState }) => {
  const value = useMemo(() => wsRoundGroupsState, [wsRoundGroupsState]);
  return (
    <SessionRoundGroupsStateContext.Provider value={value}>
      {children}
    </SessionRoundGroupsStateContext.Provider>
  );
};

export const useSessionRoundGroupsState = (): ISessionRoundGroupsState => {
  return useContext(SessionRoundGroupsStateContext);
};
