import { Box, useMultiStyleConfig } from "@chakra-ui/react";
import isHotkey from "is-hotkey";
import React, {
  KeyboardEventHandler,
  MutableRefObject,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Descendant, Editor, createEditor } from "slate";
import { withHistory } from "slate-history";
import { Editable, Slate, withReact } from "slate-react";

import { Icon } from "adminComponents/atoms/Icon";
import { IconTooltip } from "adminComponents/atoms/IconTooltip";
import { convertToSlate } from "lib/slateUtils";
import { RichValue } from "links/lib/types";

import CustomEditor from "./CustomEditor";
import DefaultElement from "./components/DefaultElement";
import LatexElement from "./components/LatexElement";
import LatexModal from "./components/LatexModal";
import Leaf from "./components/Leaf";
import Toolbar from "./components/Toolbar";
import { withEnforcedInline, withInlines, withVoids } from "./plugins";
import {
  createSyntheticChangeEvent,
  createSyntheticFocusEvent,
  getTotalCharCount,
} from "./util";

export interface IRichTextEditorProps {
  id?: string;
  name?: string;
  value?: string | RichValue;
  disabled?: boolean;
  placeholder?: string;
  handleBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  handleChange?: (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    charCount?: number
  ) => void;
  handleChangeRaw?: (value: RichValue) => void;
  includeCharCountInChangeHandler?: boolean;
  inline?: boolean;
  limit?: number;
  hasError?: boolean;
  editorRef?: MutableRefObject<Editor | undefined>;
}

export interface ILatexEditState {
  isModalOpen: boolean;
  intent: {
    entityKey: null | string;
    content: string;
  };
}

const HOTKEYS = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
};

export const RichTextEditor = React.forwardRef<
  HTMLTextAreaElement,
  IRichTextEditorProps
>((p, ref) => {
  const {
    id,
    name,
    value,
    disabled,
    placeholder,
    inline,
    handleBlur,
    handleChange,
    handleChangeRaw,
    includeCharCountInChangeHandler = false,
    hasError = false,
    editorRef,
  } = p;

  // Create a Slate editor object that won't change across renders.
  const editor = useMemo(
    () =>
      withVoids(
        withEnforcedInline(
          withInlines(withReact(withHistory(createEditor()))),
          !!inline
        )
      ),
    [inline]
  );

  if (editorRef) {
    editorRef.current = editor;
  }

  useImperativeHandle(ref, () => textAreaRef.current as HTMLTextAreaElement);

  // Parse initial value
  // eslint-disable-next-line
  const initialValue = useRef(
    (typeof value === "string"
      ? (convertToSlate(value) as RichValue)
      : value) ?? []
  );

  const [isFocused, setIsFocused] = useState(false);
  const [showToolbar, setShowToolbar] = useState(inline ? false : true);
  const [latexEditState, setLatexEditState] = useState<ILatexEditState>({
    isModalOpen: false,
    intent: { entityKey: null, content: "" },
  });
  const { t } = useTranslation("translation", {
    keyPrefix: "richTextEditor",
    useSuspense: false,
  });

  const styles = useMultiStyleConfig("AdminRichTextEditor", {
    hasError,
    isFocused,
  });

  const toolbarContent = {
    boldButton: t("toolbar.boldButton"),
    italicButton: t("toolbar.italicButton"),
    underlineButton: t("toolbar.underlineButton"),
    subscriptButton: t("toolbar.subscriptButton"),
    superscriptButton: t("toolbar.superscriptButton"),
    latexModalButton: t("toolbar.latexModalButton"),
    undoButton: t("toolbar.undoButton"),
    redoButton: t("toolbar.redoButton"),
    strikethroughButton: t("toolbar.strikethroughButton"),
  };

  const latexModalContent = {
    heading: t("latexModal.heading"),
    saveButton: t("latexModal.saveButton"),
    closeButton: t("latexModal.closeButton"),
    mathTab: t("latexModal.mathTab"),
    greekTab: t("latexModal.greekTab"),
  };

  // Define a leaf rendering function that is memoized with `useCallback`.
  const renderLeaf = useCallback((props) => {
    return <Leaf {...props} />;
  }, []);

  const renderElement = useCallback((p) => {
    switch (p.element.type) {
      case "latex":
        return <LatexElement {...p} handleEditIntent={handleEditLatexIntent} />;
      default:
        return <DefaultElement {...p} />;
    }
  }, []);

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const onKeyDown: KeyboardEventHandler = (
    event: React.KeyboardEvent<HTMLDivElement>
  ) => {
    for (const hotkey in HOTKEYS) {
      if (isHotkey(hotkey, event)) {
        event.preventDefault();
        const mark = HOTKEYS[hotkey as keyof typeof HOTKEYS];
        CustomEditor.toggleStyleMark(editor, mark);
      }
    }
  };

  const handleChangeSlate = (value: Descendant[]) => {
    const isAstChange = editor.operations.some(
      (op) => "set_selection" !== op.type
    );

    if (isAstChange && handleChange && textAreaRef.current) {
      const content = JSON.stringify(value);

      textAreaRef.current.value = content;
      const event = new Event("change");
      textAreaRef.current.dispatchEvent(event);

      const syntheticEvent = createSyntheticChangeEvent<
        HTMLTextAreaElement,
        Event
      >(event);
      if (!syntheticEvent) return;
      handleChange(
        syntheticEvent,
        includeCharCountInChangeHandler
          ? getTotalCharCount(editor.children as RichValue)
          : undefined
      );
    }

    if (isAstChange && handleChangeRaw) {
      handleChangeRaw(value as RichValue);
    }
  };

  const handleFocus = (e: React.FocusEvent) => {
    setIsFocused(true);
    e.preventDefault();
  };

  const handleBlurEvent: React.FocusEventHandler = () => {
    setIsFocused(false);
    const event = new FocusEvent("blur");
    textAreaRef.current?.dispatchEvent(event);

    const syntheticEvent = createSyntheticFocusEvent<
      HTMLTextAreaElement,
      FocusEvent
    >(event);
    if (!syntheticEvent) return;
    handleBlur && handleBlur(syntheticEvent);
  };

  const handleUndo = () => {
    editor.undo();
  };

  const handleRedo = () => {
    editor.redo();
  };

  const handleAddLatexIntent = () => {
    setLatexEditState({
      isModalOpen: true,
      intent: { entityKey: null, content: "" },
    });
  };

  const handleLatexModalClose = () => {
    setLatexEditState({
      isModalOpen: false,
      intent: { entityKey: null, content: "" },
    });
  };

  const handleToggleToolbar = () => {
    setShowToolbar(!showToolbar);
  };

  const updateLatexEntity = (key: string | null, latexContent: string) => {
    CustomEditor.updateLatex(editor, key, latexContent);

    setLatexEditState({
      isModalOpen: false,
      intent: {
        entityKey: null,
        content: "",
      },
    });
  };

  const handleEditLatexIntent = (entityKey: string, content: string) => {
    setLatexEditState({
      isModalOpen: true,
      intent: {
        entityKey,
        content,
      },
    });
  };

  const handleMark = (mark: string) => {
    CustomEditor.toggleStyleMark(editor, mark);
  };

  // Render the Slate context.
  return (
    <Slate
      editor={editor}
      value={initialValue.current}
      onChange={handleChangeSlate}
    >
      <Box sx={styles.container} w="full" borderRadius="md">
        <Box sx={styles.topContainer}>
          <Box flexGrow={1} minW={0} textStyle="adminP2">
            <Editable
              spellCheck
              onFocus={handleFocus}
              onBlur={handleBlurEvent}
              placeholder={placeholder}
              disabled={disabled}
              readOnly={disabled}
              renderLeaf={renderLeaf}
              renderElement={renderElement}
              onKeyDown={onKeyDown}
            />
            <textarea
              style={{ display: "none" }}
              id={id}
              name={name}
              ref={textAreaRef}
              disabled={disabled}
            />
          </Box>
          {inline && (
            <>
              <IconTooltip
                popoverVariant="adminRichTextToolbar"
                triggerEl={
                  <Icon
                    variant="toolbarToggleIcon"
                    icon="format_size_outlined"
                    iconColor="primary.warm-black"
                    onClick={handleToggleToolbar}
                    display="inline-flex"
                    backgroundColor={
                      showToolbar ? "primary.golden" : "primary.white"
                    }
                  >
                    Aa
                  </Icon>
                }
              >
                {t("showToolbarButton")}
              </IconTooltip>
            </>
          )}
        </Box>

        {showToolbar && (
          <Toolbar
            isFocused={isFocused}
            onMark={handleMark}
            onAddLatex={handleAddLatexIntent}
            handleRedo={handleRedo}
            handleUndo={handleUndo}
            content={toolbarContent}
          />
        )}

        {!disabled && (
          <LatexModal
            entityKey={latexEditState.intent.entityKey}
            value={latexEditState.intent.content}
            content={latexModalContent}
            isOpen={latexEditState.isModalOpen}
            handleClose={handleLatexModalClose}
            handleChange={updateLatexEntity}
          />
        )}
      </Box>
    </Slate>
  );
});

RichTextEditor.displayName = "RichTextEditor";
