import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { TeacherAgreementsScreen } from "adminComponents/screens/TeacherAgreementsScreen";
import { generateTeacherName } from "adminComponents/utils";
import { usePageTrack } from "lib/contexts/analytics";
import { usePageTitle } from "lib/hooks/usePageTitle";
import {
  useDeferAgreement,
  useSignAgreement,
} from "links/lib/features/agreements";
import { useAuth } from "links/lib/features/auth";
import { AnalyticsEvent } from "links/lib/types";
import { useShowNewLibrary } from "sharedComponents/hooks/useShowNewLibrary";

export interface ITeacherAgreementProps {
  redirect?: string;
}

export const TeacherAgreements: React.FC<ITeacherAgreementProps> = ({
  redirect,
}) => {
  const { authUser, authRequiredAgreements, refetchUser } = useAuth();
  const newLibraryFeatureEnabled = useShowNewLibrary();
  const history = useHistory();
  const deferAgreement = useDeferAgreement({
    onSuccess: () => {
      refetchUser();
    },
  });
  const signAgreement = useSignAgreement({
    onSuccess: () => {
      refetchUser();
    },
  });
  const { t } = useTranslation("admin", {
    keyPrefix: "teacherAgreementsContainer",
  });

  const redirectUrl = redirect
    ? redirect
    : newLibraryFeatureEnabled
    ? "/t/explore"
    : "/t/home";

  usePageTrack(AnalyticsEvent.TeacherDashboard_Agreements_Begin);
  usePageTitle(t("pageTitle"));

  const handleDeferAgreement = (agreementId: string) => {
    deferAgreement.mutate({ agreement_id: agreementId });
  };

  const handleSignAgreement = (agreementId: string) => {
    signAgreement.mutate({ agreement_id: agreementId });
  };

  const handleSignOut = () => {
    history.push("/sign-out");
  };

  const agreements = useMemo(
    () => authRequiredAgreements || [],
    [authRequiredAgreements]
  );

  useEffect(() => {
    if (!agreements.length) {
      history.push(redirectUrl);
    }
  }, [agreements, history, redirectUrl]);

  return (
    <TeacherAgreementsScreen
      teacherName={generateTeacherName(authUser || {}).primary}
      agreements={agreements}
      handleDeferAgreement={handleDeferAgreement}
      handleSignAgreement={handleSignAgreement}
      handleSignOut={handleSignOut}
    />
  );
};
