import { AxiosError } from "axios";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { string } from "yup";

import { AddTeacherViaEmailModal } from "adminComponents/organisms/AddTeacherViaEmailModal";
import { useShowToast } from "adminComponents/utils/toast";
import { useAnalytics } from "lib/contexts/analytics";
import { useMutateClassroomUsers } from "links/lib/features/classrooms";
import {
  AnalyticsEvent,
  IUser,
  UserRole,
  UsersGroupsRole,
} from "links/lib/types";

export interface IArgs {
  classroomId: string;
}
export interface IResult {
  modal: React.ReactElement;
  handleAddIntent: () => void;
}

const emailSchema = string().email();

export const useAddTeacherByEmail = ({ classroomId }: IArgs): IResult => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [errorString, setErrorString] = useState<string | undefined>();
  const { trackEvent } = useAnalytics();
  const showToast = useShowToast();
  const { t } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "classroomDetailContainer.teachersTab.addByEmail",
  });

  const onSuccess = useCallback(() => {
    showToast(t("successToast"));
    setIsOpen(false);
  }, [showToast, t]);

  const usersMutation = useMutateClassroomUsers({
    onSuccess,
    onBadRequest: (e: Error) => {
      setErrorString(`${t("errorPrefix")}: ${e.message}`);
    },
    onError: (e: AxiosError) => {
      setErrorString(
        `${t("errorPrefix")}: ${
          (e.response?.data as { message: string }).message
        }`
      );
    },
  });

  const handleAddIntent = useCallback(() => {
    setIsOpen(true);
    setErrorString(undefined);
    trackEvent(
      AnalyticsEvent.TeacherDashboard_ClassroomDetail_TeachersTab_AddByEmailIntent,
      {}
    );
  }, [trackEvent]);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleSubmit = useCallback(
    (emailAddress: string, usersGroupsRole: UsersGroupsRole) => {
      if (!emailAddress.trim()) {
        setErrorString(t("errorEmailsInvalid"));
        return;
      }

      const emails = emailAddress
        .trim()
        .replaceAll("\n", ",")
        .split(",")
        .map((e) => e.trim())
        .filter((e) => e !== "");

      const isInvalid = emails.some((e) => !emailSchema.isValidSync(e));

      if (isInvalid) {
        setErrorString(t("errorEmailsInvalid"));
        return;
      }

      // passed validation checks - input is valid
      setErrorString(undefined);

      const users = emails.map((email): IUser => {
        return {
          id: "0",
          email: email,
          role: UserRole.Teacher,
          true_role: UserRole.Teacher,
          license_id: "0",
          timezone_locale: "",
          language: "",
          is_guest: false,
          country: "",
          region: "",
        };
      });

      usersMutation.mutate({
        classroomId: classroomId,
        users,
        usersGroupsRole,
      });

      trackEvent(
        AnalyticsEvent.TeacherDashboard_ClassroomDetail_TeachersTab_AddByEmailConfirm,
        {}
      );
    },
    [usersMutation, classroomId, trackEvent, t]
  );

  return useMemo(() => {
    return {
      modal: (
        <AddTeacherViaEmailModal
          isOpen={isOpen}
          isLoading={usersMutation.isLoading}
          errorString={errorString}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
        />
      ),
      handleAddIntent,
    };
  }, [
    isOpen,
    handleClose,
    handleSubmit,
    handleAddIntent,
    errorString,
    usersMutation.isLoading,
  ]);
};
