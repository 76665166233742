import { Flex, useMultiStyleConfig } from "@chakra-ui/react";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";

import { Avatar } from "adminComponents/atoms/Avatar";
import { Text } from "adminComponents/atoms/Text";
import { generateStudentName } from "adminComponents/utils/generateStudentName";
import { IUser } from "links/lib/types";

export interface IProps {
  student: IUser;
  lastAttempt: string;
}

const formatDate = (date: string) => {
  return moment(date).format("MMMM D, YYYY; h:mm A");
};

export const StudentAssignmentBanner: React.FC<IProps> = ({
  student,
  lastAttempt,
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });
  const styles = useMultiStyleConfig("AdminStudentBanner", {
    variant: "assignmentBanner",
  });
  const studentName = generateStudentName(student).primary;

  return (
    <Flex sx={styles.banner}>
      <Avatar src={student.profile_image_url} />
      <Flex sx={styles.textContainer}>
        <Text variant="adminP1" sx={styles.mainText}>
          {t("studentBanner.assignmentReport", { studentName })}
        </Text>
        <Text variant="adminP1" color="primary.warm-black">
          {t("studentBanner.lastAttempt", {
            lastAttempt: lastAttempt ? formatDate(lastAttempt) : t("common.na"),
          })}
        </Text>
      </Flex>
    </Flex>
  );
};
