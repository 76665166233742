import { Flex } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";

import { Heading } from "adminComponents/atoms/Heading";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils";

import WelcomeToPearPracticeMP4 from "../resource/welcome.mp4";

export const TeacherTourStep1: React.FC = () => {
  const { t } = useTranslation("admin");

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      gap={pxToRem(11)}
    >
      <ReactPlayer
        url={WelcomeToPearPracticeMP4}
        controls={false}
        playing
        loop
        width="70%"
        height="70%"
        // Hides the video's border
        style={{
          clipPath: "inset(1px 2px)",
        }}
      />
      <Heading as="h1" variant="adminH2">
        {t("teacherTour.step1.heading")}
      </Heading>
      <Text variant="adminP1" maxW="60%">
        {t("teacherTour.step1.description")}
      </Text>
    </Flex>
  );
};
