import React from "react";

import { SessionTheBigBoardSpaceType } from "links/lib/types";

import { SpecialBoardCell } from "./SpecialBoardCell";
import { ITokenBoardCellProps, TokenBoardCell } from "./TokenBoardCell";

interface IBoardCellProps
  extends Omit<ITokenBoardCellProps, "enableDrop" | "onPlaceToken"> {
  placementDisabled: boolean;
  spaceType: SessionTheBigBoardSpaceType;
  showSpecialSpaces: boolean;
  spaceId: number;
  onPlaceToken: (spaceId: number) => void;
  cellSize: number;
  hideEmptyPlacements: boolean;
  prizeRoundPoints?: number;
}

export const BoardCell: React.FC<IBoardCellProps> = ({
  placements,
  onPlaceToken,
  placementDisabled,
  spaceType,
  showSpecialSpaces,
  spaceId,
  cellSize,
  hideEmptyPlacements,
  prizeRoundPoints,
}) => {
  if (placementDisabled) {
    return (
      <SpecialBoardCell
        spaceType={spaceType}
        prizeRoundPoints={prizeRoundPoints}
        disabled={!showSpecialSpaces}
      />
    );
  } else {
    return (
      <TokenBoardCell
        hideEmptyPlacements={hideEmptyPlacements}
        cellSize={cellSize}
        placements={placements}
        enableDrop={!placementDisabled}
        onPlaceToken={() => onPlaceToken && onPlaceToken(spaceId)}
      />
    );
  }
};
