import { Box, Tooltip as ChakraTooltip, Text } from "@chakra-ui/react";
import React from "react";

export interface IProps {
  isDisabled?: boolean;
  hasArrow?: boolean;
  label?: string;
  clampLines?: number;
}

export const ClampedTooltip: React.FC<IProps> = ({
  isDisabled = false,
  hasArrow,
  label,
  children,
  clampLines = 20,
  ...rest
}) => {
  return (
    <ChakraTooltip
      hasArrow={hasArrow}
      isDisabled={isDisabled}
      placement="auto"
      label={
        <Box
          {...rest}
          sx={{
            textOverflow: "ellipsis",
            maxHeight: "75vh",
            height: "fit-content",
            overflow: "hidden",
          }}
        >
          <Text
            color="primary.white"
            sx={{
              display: "-webkit-box",
              WebkitLineClamp: `${clampLines}`,
              wordBreak: "break-word",
              WebkitBoxOrient: "vertical",
            }}
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {label}
          </Text>
        </Box>
      }
    >
      {children}
    </ChakraTooltip>
  );
};
