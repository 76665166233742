import { Box } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

export interface IBonusProps {
  prizeRoundPoints: number;
}

export const Bonus: React.FC<IBonusProps> = ({ prizeRoundPoints }) => {
  const { t } = useTranslation("session", {
    keyPrefix: "bigBoard",
    useSuspense: false,
  });

  const bonusText = (
    <svg height="25%" width="100%" viewBox="0 0 60 20">
      <text x="0" y="14" fill="#986357" fontFamily={`"Gooper", Georgia, serif`}>
        {t("bonus").toUpperCase()}
      </text>
    </svg>
  );

  return (
    <Box
      boxSize="full"
      display="flex"
      flexDir="column"
      alignItems="center"
      justifyContent="center"
    >
      {bonusText}
      {prizeRoundPoints > 0 && (
        <svg height="40%" width="100%" viewBox="0 0 60 20">
          <text
            x="9"
            y="14"
            fill="#D1522A"
            fontFamily={`"Gooper", Georgia, serif`}
          >
            +{prizeRoundPoints}
          </text>
        </svg>
      )}
      {bonusText}
    </Box>
  );
};
