import { Box, Portal } from "@chakra-ui/react";
import { DebouncedFunc, throttle } from "lodash";
import React, { MutableRefObject, useCallback, useMemo, useRef } from "react";
import { useEvent, useThrottle, useUnmount } from "react-use";

import { useSessionActions } from "links/lib/contexts/sessionActions";
import { useSessionRoundGroupState } from "links/lib/contexts/sessionRoundGroupState";
import { useSessionRoundState } from "links/lib/contexts/sessionRoundState";
import {
  ISessionDraggableSortItem,
  ISessionUser,
  QuestionType,
  RichText,
} from "links/lib/types";
import { ClassificationCursorLabel } from "sessionComponents/atoms/ClassificationCursorLabel";
import { Cursor } from "sessionComponents/atoms/Cursor";
import { Marker } from "sessionComponents/atoms/Marker";
import { useGroupUsers } from "sessionComponents/hooks/useGroupUsers";
import { getAssetColorScheme } from "sessionComponents/utils/getAssetColorScheme";

export interface IStudentCursorsProps {
  student: ISessionUser;
  target?: MutableRefObject<HTMLDivElement | null> | null;
  isVisible: boolean;
}

type LabeledUser = ISessionUser & {
  labelId?: string;
  labelText?: RichText;
  labelImageUrl?: string;
};

export const StudentCursors: React.FC<IStudentCursorsProps> = ({
  student,
  target,
  isVisible,
}) => {
  const { sendCursor } = useSessionActions();
  const { dragsort } = useSessionRoundGroupState();
  const { practice_set_session_item } = useSessionRoundState();
  const groupUsers = useGroupUsers(student.session_group_id);

  const dragsortItems = useMemo(() => dragsort?.items || [], [dragsort]);

  // set up a ref for the throttled mouse move listener
  const onMouseMove = useRef<DebouncedFunc<(ev: MouseEvent) => void>>(
    throttle(
      (ev: MouseEvent) => {
        const pos = toTargetPosition(ev.pageX, ev.pageY);
        sendCursor(pos.x, pos.y);
      },
      500,
      { leading: true, trailing: true }
    )
  );

  // set up a ref for the throttled touch move and end listener
  const onTouchMove = useRef<DebouncedFunc<(ev: TouchEvent) => void>>(
    throttle(
      (ev: TouchEvent) => {
        if (!ev.touches.length) return;
        const pos = toTargetPosition(ev.touches[0].pageX, ev.touches[0].pageY);
        sendCursor(pos.x, pos.y);
      },
      500,
      { leading: true, trailing: true }
    )
  );

  const onDrag = useRef<DebouncedFunc<(ev: DragEvent) => void>>(
    throttle(
      (ev: DragEvent) => {
        const pos = toTargetPosition(ev.pageX, ev.pageY);
        sendCursor(pos.x, pos.y);
      },
      500,
      { leading: true, trailing: true }
    )
  );

  // prevent updates quicker than 100ms
  const throttledGroupUsers = useThrottle(groupUsers, 100);
  // adds any label the user is currently dragging
  const getUsersWithLabels = (dragsortItems: ISessionDraggableSortItem[]) => {
    return throttledGroupUsers.map((user) => {
      const labelIndex = dragsortItems.findIndex(
        (item) => item.user_id === user.id && item.is_dragging
      );
      if (labelIndex === -1) {
        return user as LabeledUser;
      }

      return {
        ...user,
        labelId: `${labelIndex + 1}`,
        labelText: dragsortItems[labelIndex].text,
        labelImageUrl: dragsortItems[labelIndex].image_url,
      } as LabeledUser;
    });
  };
  // todo this doesn't work correctly with portrait mode
  const toTargetPosition = useCallback(
    (x: number, y: number) => {
      if (!target?.current) {
        const bodyBox = document.body.getBoundingClientRect();

        return {
          x: x / bodyBox.width,
          y: y / bodyBox.height,
        };
      }

      const {
        x: targetX,
        y: targetY,
        width: targetWidth,
        height: targetHeight,
      } = target.current.getBoundingClientRect();

      const targetCenter = {
        x: targetX + targetWidth / 2,
        y: targetY + targetHeight / 2,
      };

      // the position relative to target position
      const targetCenterOffset = {
        x: x - targetCenter.x,
        y: y - targetCenter.y,
      };

      // return values scaled to target scale
      return {
        x: targetCenterOffset.x / targetWidth,
        y: targetCenterOffset.y / targetHeight,
      };
    },
    [target]
  );

  // todo this doesn't work correctly with portrait mode
  const fromTargetPosition = useCallback(
    (x: number, y: number) => {
      if (!target?.current) {
        const bodyBox = document.body.getBoundingClientRect();

        return {
          x: x * bodyBox.width,
          y: y * bodyBox.height,
        };
      }

      const {
        x: targetX,
        y: targetY,
        width: targetWidth,
        height: targetHeight,
      } = target.current.getBoundingClientRect();

      const targetCenter = {
        x: targetX + targetWidth / 2,
        y: targetY + targetHeight / 2,
      };

      const unscaledPos = {
        x: x * targetWidth,
        y: y * targetHeight,
      };

      // the position relative to page position
      const pagePos = {
        x: targetCenter.x + unscaledPos.x,
        y: targetCenter.y + unscaledPos.y,
      };

      return {
        x: pagePos.x,
        y: pagePos.y,
      };
    },
    [target]
  );

  useEvent("mousemove", onMouseMove.current);
  useEvent("touchmove", onTouchMove.current);
  useEvent("touchend", onTouchMove.current);
  useEvent("drag", onDrag.current);

  // cancel any outstanding throttled move events
  useUnmount(() => {
    onMouseMove.current.cancel();
    onTouchMove.current.cancel();
    onDrag.current.cancel();
  });

  const visibleGroupUsers = dragsortItems
    ? getUsersWithLabels(dragsortItems)
    : (throttledGroupUsers as LabeledUser[]);

  const getLabelComponent = (u: LabeledUser) => {
    const labelId = u.labelId && u.labelId !== "0" ? u.labelId : undefined;
    if (!labelId) return undefined;
    // for diagram options, show a marker
    if (practice_set_session_item.question_type === QuestionType.Diagram)
      return (
        <Box position="absolute" right="-50px">
          <Marker
            iconHeight="35px"
            containerHeight="35px"
            iconColor="utility.link"
            number={labelId}
            fontSize="1rem"
          />
        </Box>
      );

    // for classification options, show a small version of the option w/text or image
    return (
      <Box position="absolute" right="-110px">
        <ClassificationCursorLabel
          text={u.labelText}
          imageUrl={u.labelImageUrl}
          cursorColor={getAssetColorScheme(u.color_scheme).secondaryColor}
        />
      </Box>
    );
  };

  return (
    <Portal>
      <Box
        height="1px"
        width="1px"
        position="fixed"
        top="0"
        left="0"
        overflow="visible"
        pointerEvents="none"
        zIndex="3"
      >
        {isVisible &&
          visibleGroupUsers
            .filter((u) => u.id !== student.id && !!u.cursor)
            .map(
              (u) =>
                u.cursor && (
                  <Cursor
                    key={u.id}
                    name={u.name}
                    position={fromTargetPosition(u.cursor.x, u.cursor.y)}
                    lastActiveTyping={u.last_active_typing}
                    primaryColor={
                      getAssetColorScheme(u.color_scheme).primaryColor
                    }
                    labelComponent={getLabelComponent(u)}
                  />
                )
            )}
      </Box>
    </Portal>
  );
};
