import { pxToRem } from "adminComponents/utils/pxToRem";

export const AdminAddStudentsViaLinkModal = {
  parts: [
    "copyLinkButton",
    "linkContainer",
    "link",
    "warning",
    "text",
    "button",
  ],

  baseStyle: ({
    isLoading,
  }: {
    isLoading: boolean;
  }): Record<string, unknown> => ({
    warning: {
      alignItems: "center",
      gap: pxToRem(6),
      marginBottom: pxToRem(34),
    },
    linkContainer: {
      marginTop: pxToRem(10),
      marginBottom: pxToRem(12),
      padding: pxToRem(24),
      borderColor: "primary.tan",
      borderWidth: pxToRem(3),
      borderRadius: pxToRem(8),
      justifyContent: isLoading ? "center" : "space-between",
      alignItems: "center",
      width: [pxToRem(345), null, null],
      minWidth: { base: "0px", md: pxToRem(518) },
    },
    link: {
      color: "utility.link",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
    linkButton: {
      minWidth: "auto",
    },
    labelText: {
      color: "primary.dark-gray",
      marginTop: pxToRem(22),
    },
    button: {
      width: { base: "100%", md: "auto" },
    },
  }),
};
