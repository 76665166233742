import { useMount } from "react-use";

import { useAuth } from ".";
import { localStoreAuthKeyName } from "../../constants";
import { useAsync } from "../../hooks/useAsync";
import { useLocalStore } from "../../hooks/useLocalStore";
import { useSessionStore } from "../../hooks/useSessionStore";

export interface IRemoveAuthStatus {
  isLoading: boolean;
  error?: Error;
}

/**
 * Removes user authentication for app. When mounted, removes auth token
 * from local store and unsets auth in recoil state.
 * @param localStore
 * @returns
 */
export default function useRemoveAuth(): IRemoveAuthStatus {
  const localStore = useLocalStore();
  const sessionStore = useSessionStore();
  const { setAuth, isAuthLoading } = useAuth();

  const removeAuth = async () => {
    await localStore.deleteItem(localStoreAuthKeyName);
    await sessionStore.deleteItem(localStoreAuthKeyName);

    setAuth({
      authFeatureFlags: undefined,
      authRequiredAgreements: undefined,
      authToken: undefined,
      authUser: undefined,
      authSharingSettings: undefined,
      authLicense: undefined,
      hasNoPremiumAccess: false,
      isGhostUser: false,
      isAuthLoading,
    });
  };

  const { isLoading, error, execute } = useAsync<void>(removeAuth, {
    initState: { isLoading: true },
  });

  useMount(() => {
    execute();
  });

  return {
    isLoading,
    error,
  };
}
