import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { IClassroomPendingUser } from "../../types";

export interface IFetchPendingClassroomStudentsArgs {
  classroom_id?: string;
  refetchInterval?: number;
}

export interface IFetchPendingClassroomStudentsResponse {
  pending_users: Array<IClassroomPendingUser>;
}

/**
 * Fetches a list of pending users for the classroom
 * @returns
 */
export default function useFetchClassroomUsers(
  params: IFetchPendingClassroomStudentsArgs
): UseQueryResult<IFetchPendingClassroomStudentsResponse, unknown> {
  const axios = useAxios();

  const { classroom_id, refetchInterval } = params;

  const query = useQuery<IFetchPendingClassroomStudentsResponse>(
    ["pending-classroom-students", { classroom_id }],
    async (): Promise<IFetchPendingClassroomStudentsResponse> => {
      return await axios
        .get(`/v1/groups/${classroom_id}/users/pending`)
        .then((r) => {
          return r.data as IFetchPendingClassroomStudentsResponse;
        });
    },
    { enabled: !!classroom_id, refetchInterval }
  );

  return query;
}
