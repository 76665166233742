import {
  Box,
  Modal as ChakraModal,
  Flex,
  FormControl,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  SimpleGrid,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Avatar } from "adminComponents/atoms/Avatar";
import { Button } from "adminComponents/atoms/Button";
import { Dropdown, IOption } from "adminComponents/atoms/Dropdown";
import { FormLabel } from "adminComponents/atoms/FormLabel";
import { Heading } from "adminComponents/atoms/Heading";
import {
  IconCTAButtonWrapper as CloseButtonWrapper,
  IconCTAButton,
} from "adminComponents/atoms/IconCTAButton";
import { IconTooltip } from "adminComponents/atoms/IconTooltip";
import { Input } from "adminComponents/atoms/Input";
import { Text } from "adminComponents/atoms/Text";
import { hoverStyles, pxToRem } from "adminComponents/utils";
import { IGradeLevel, IUser } from "links/lib/types";

interface IStudentDetailForm {
  preferredName: string;
  gradeLevelId: string;
}

interface EditStudentDetailsInnerModalProps extends ModalProps {
  title: string;
  isLoading?: boolean;
}

const EditStudentModalWrapper: React.FC<EditStudentDetailsInnerModalProps> = ({
  children,
  variant = "adminModal",
  isLoading,
  title,
  ...rest
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  return (
    <ChakraModal
      {...rest}
      isCentered
      closeOnOverlayClick={!isLoading}
      size="2xl"
      variant={variant}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading as="h2" variant="adminH6">
            {title}
          </Heading>
        </ModalHeader>
        <ModalCloseButton
          as={CloseButtonWrapper}
          top={pxToRem(20)}
          right={pxToRem(20)}
        >
          <IconCTAButton ariaLabel={t("common.close")} />
        </ModalCloseButton>
        <ModalBody>{children}</ModalBody>
      </ModalContent>
    </ChakraModal>
  );
};

interface EditStudentDetailsModalProps {
  isOpen: boolean;
  isLoading: boolean;
  student?: IUser;
  gradeLevels: IGradeLevel[];
  defaultGradeLevelId: string;
  removing: boolean;
  handleClose: () => void;
  handleRemoveStudent: (student: IUser) => void;
  handleSave: (student: IUser, gradeLevelId: string) => void;
}

export const EditStudentDetailsModal: React.FC<
  EditStudentDetailsModalProps
> = ({
  isOpen,
  student,
  gradeLevels,
  isLoading,
  defaultGradeLevelId,
  removing,
  handleRemoveStudent,
  handleSave,
  handleClose,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const formik = useFormik<IStudentDetailForm>({
    validateOnMount: true,
    initialValues: {
      preferredName: student?.student_nickname ?? "",
      gradeLevelId: defaultGradeLevelId,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      if (!student) return;

      const preferredName = values.preferredName.trim();

      handleSave(
        { ...student, student_nickname: preferredName },
        values.gradeLevelId
      );
    },
  });

  const handleGradeLevelUpdate = (value: string) => {
    formik.setFieldValue("gradeLevelId", value);
  };

  const handleCloseDeleteModal = () => {
    if (removing) return;
    setShowDeleteModal(false);
  };

  useEffect(() => {
    setShowDeleteModal(false);
  }, [isOpen]);

  const mappedGradeLevelOptions: IOption[] = gradeLevels.map(
    ({ grade_level, id }) => ({
      label: grade_level,
      value: id,
    })
  );

  const canEdit = student?.id !== "0";

  return (
    <>
      <EditStudentModalWrapper
        title={t("editStudentDetails.title")}
        isOpen={isOpen && !showDeleteModal}
        onClose={handleClose}
        variant="adminStudentEditModal"
        isLoading={isLoading}
      >
        {!!student && (
          <>
            {canEdit && (
              <form>
                <SimpleGrid
                  mb={[pxToRem(24), null, pxToRem(40)]}
                  gap={[pxToRem(24), null, pxToRem(32)]}
                >
                  <FormControl variant="adminFormControl">
                    <FormLabel variant="adminFormLabelDarkGray">
                      {t("editStudentDetails.fullName")}
                    </FormLabel>
                    <Heading as="p" variant="adminH6">
                      {student.given_name + " " + student.family_name}
                    </Heading>
                  </FormControl>
                  <FormControl
                    isDisabled={isLoading}
                    variant="adminFormControl"
                  >
                    <FormLabel
                      variant="adminFormLabelDarkGray"
                      htmlFor="editStudentDetails.preferredName"
                    >
                      <Flex alignItems="center" gap={pxToRem(4)}>
                        {t("editStudentDetails.preferredName")}
                        <IconTooltip placement="top-start">
                          {t("editStudentDetails.preferredNameTooltip")}
                        </IconTooltip>
                      </Flex>
                    </FormLabel>
                    <Input
                      id="editStudentDetails.preferredName"
                      name="preferredName"
                      value={formik.values.preferredName}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      placeholder={t("editStudentDetails.preferredName")}
                    />
                  </FormControl>
                  <FormControl
                    isDisabled={isLoading}
                    variant="adminFormControl"
                  >
                    <FormLabel
                      variant="adminFormLabelDarkGray"
                      htmlFor="editStudentDetails.selectGradeLevel"
                    >
                      {t("editStudentDetails.selectGradeLevel")}
                    </FormLabel>
                    <Dropdown
                      id="editStudentDetails.selectGradeLevel"
                      options={mappedGradeLevelOptions}
                      value={mappedGradeLevelOptions.filter(
                        ({ value }) => value === formik.values.gradeLevelId
                      )}
                      handleChange={(v) =>
                        handleGradeLevelUpdate((v as IOption).value.toString())
                      }
                    />
                  </FormControl>
                </SimpleGrid>
              </form>
            )}
            {!canEdit && (
              <Box mb={[pxToRem(24), null, pxToRem(40)]}>
                <Text variant="adminP1" color="primary.dark-gray">
                  {t("editStudentDetails.cannotEditPendingStudent")}
                </Text>
              </Box>
            )}

            <Flex
              alignItems={["flex-start", null, "center"]}
              gap={[pxToRem(24), null, pxToRem(12)]}
              flexDir={["column-reverse", null, "row"]}
            >
              <Flex gap={pxToRem(12)} w={["full", null, "auto"]}>
                {canEdit && (
                  <Button
                    size="lg"
                    variant="adminButtonFilled"
                    flex={[1, null, "auto"]}
                    isLoading={isLoading}
                    onClick={formik.submitForm}
                    disabled={!canEdit}
                  >
                    {t("common.save")}
                  </Button>
                )}

                <Button
                  size="lg"
                  variant="adminButtonOutlined"
                  onClick={handleClose}
                  flex={[1, null, "auto"]}
                  isLoading={isLoading}
                >
                  {t("common.cancel")}
                </Button>
              </Flex>
              <Button
                variant="adminTextButtonMedium"
                marginLeft={[pxToRem(0), null, "auto"]}
                color="utility.error"
                _hover={hoverStyles({ color: "utility.error-hover" })}
                _focusVisible={{ color: "utility.error-hover" }}
                isDisabled={isLoading}
                onClick={() => setShowDeleteModal(true)}
              >
                {t("editStudentDetails.removeStudent")}
              </Button>
            </Flex>
          </>
        )}
      </EditStudentModalWrapper>
      <EditStudentModalWrapper
        isOpen={showDeleteModal && isOpen}
        onClose={handleCloseDeleteModal}
        title={t("editStudentDetails.deleteTitle")}
        variant="adminStudentDeleteConfirmation"
        isLoading={removing}
      >
        {!!student && (
          <>
            <Text variant="adminP1" color="primary.dark-gray">
              {t("editStudentDetails.adminStudentDeleteConfirmation")}
            </Text>
            <Flex
              mt={pxToRem(24)}
              mb={[pxToRem(24), null, pxToRem(40)]}
              p={pxToRem(24)}
              gap={pxToRem(20)}
              alignItems="center"
              borderRadius={pxToRem(20)}
              bg="primary.tan"
            >
              <Box>
                {student.profile_image_url && (
                  <Avatar
                    size="lg"
                    src={student.profile_image_url}
                    name={student.given_name + " " + student.family_name}
                    borderRadius="full"
                    objectFit="cover"
                  />
                )}
              </Box>
              <Box>
                <Text variant="adminP1" mb={pxToRem(5)}>
                  {student.given_name + " " + student.family_name}
                  {!student.given_name && !student.family_name && student.email}
                </Text>
                <Text variant="adminP2" color="primary.warm-black">
                  {
                    gradeLevels.find(
                      ({ id }) => id === formik.values.gradeLevelId
                    )?.grade_level
                  }
                </Text>
              </Box>
            </Flex>
            <Flex gap={pxToRem(12)}>
              <Button
                variant="adminButtonFilled"
                size="lg"
                flex={[1, null, "unset"]}
                onClick={() => handleRemoveStudent(student)}
                isLoading={removing}
              >
                {t("editStudentDetails.removeStudent")}
              </Button>
              <Button
                variant="adminButtonOutlined"
                size="lg"
                onClick={() => setShowDeleteModal(false)}
                flex={[1, null, "unset"]}
                isLoading={removing}
              >
                {t("editStudentDetails.goBack")}
              </Button>
            </Flex>
          </>
        )}
      </EditStudentModalWrapper>
    </>
  );
};
