import { Box, Flex, useMultiStyleConfig } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Avatar } from "adminComponents/atoms/Avatar";
import { Card } from "adminComponents/atoms/Card";
import { Divider } from "adminComponents/atoms/Divider";
import { Heading } from "adminComponents/atoms/Heading";
import { Icon } from "adminComponents/atoms/Icon";
import { IconButtonWithTooltip } from "adminComponents/atoms/IconButtonWithTooltip";
import { Text } from "adminComponents/atoms/Text";
import { PieChartWithLabel } from "adminComponents/molecules/PieChartWithLabel";
import { generateStudentName } from "adminComponents/utils/generateStudentName";
import { IUser } from "links/lib/types";

type student = Partial<IUser> &
  Pick<
    IUser,
    | "id"
    | "role"
    | "student_nickname"
    | "given_name"
    | "family_name"
    | "email"
    | "profile_image_url"
  >;

export interface IProps {
  student: student;
  accuracy: number;
  practiceHours?: number;
  gradeLevel?: string;
  handleEdit?: (user: student) => void;
}

export const HeroStudentDetailsCard: React.FC<IProps> = ({
  student,
  accuracy,
  gradeLevel,
  practiceHours,
  handleEdit,
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });

  const styles = useMultiStyleConfig("AdminHeroCard", {});

  const {
    student_nickname,
    given_name,
    family_name,
    email,
    profile_image_url: image,
  } = student;

  const studentName = generateStudentName({
    student_nickname,
    given_name,
    family_name,
  });

  return (
    <Card
      borderColor="primary.tan"
      backgroundColor="primary.white"
      variant="adminCardThickBorderTall"
    >
      <Flex sx={styles.header}>
        {handleEdit && (
          <IconButtonWithTooltip
            shape="type2"
            icon="edit_outlined"
            sx={styles.iconButton}
            onClick={() => handleEdit(student)}
            ariaLabel={t("common.edit")}
            tooltipProps={{ popoverVariant: "default" }}
          />
        )}
      </Flex>
      <Flex sx={styles.main}>
        <Box sx={styles.image}>
          <Avatar size="full" src={image} />
        </Box>
        <Flex sx={styles.studentDetails}>
          <Box sx={styles.studentDetailsText}>
            <Heading as="h1" variant="adminH3" sx={styles.heading}>
              {studentName.primary}
            </Heading>
            <Text size="md" variant="adminP1" sx={styles.text}>
              {studentName.secondary ? `${studentName.secondary}  ·  ` : ""}
              {`${gradeLevel ? `${gradeLevel}  ·` : ""}  ${email}`}
            </Text>
          </Box>
          <Divider color="primary.light-gray" />
          <Flex sx={styles.studentDetailsStats}>
            <Flex>
              <PieChartWithLabel
                percentage={accuracy}
                text={t("heroStudentDetailsCard.accuracy")}
              />
            </Flex>
            {!!practiceHours && (
              <Flex>
                <Icon icon="timer_outlined" styles={styles.icon} />
                <Text size="md" variant="adminP1">{`${practiceHours} ${t(
                  "heroStudentDetailsCard.practiceHours"
                )}`}</Text>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};
