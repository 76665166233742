export default {
  variants: {
    menuItem: {
      bg: "transparent",
      color: "gray.900",
      _hover: {
        bg: "gray.200",
        color: "gray.900",
      },
      _active: {
        bg: "gray.300",
        color: "gray.900",
      },
    },
    richTextControl: {
      bg: "transparent",
      fontWeight: "normal",
      color: "gray.900",
      borderRadius: "none",
      fontSize: "14px",
      _hover: {
        bg: "gray.100",
        color: "gray.900",
      },
      _active: {
        bg: "gray.100",
        color: "monaco.base",
      },
      _first: {
        borderBottomLeftRadius: "md",
      },
    },
    gamePrimary: (props: {
      [key: string]: string;
    }): Record<string, unknown> => ({
      borderRadius: "none",
      bg: () => {
        switch (props.colorScheme) {
          case "tangerine":
            return "tangerine.03";
          case "monaco":
            return "monaco.02";
          case "capri":
            return "capri.02";
          case "anjou":
            return "anjou.base";
          default:
            return "gray.100";
        }
      },
      color: "white",
      boxShadow: () => {
        switch (props.colorScheme) {
          case "tangerine":
            return "4px 4px var(--chakra-colors-tangerine-04)";
          case "monaco":
            return "4px 4px var(--chakra-colors-monaco-03)";
          case "capri":
            return "4px 4px var(--chakra-colors-capri-03)";
          case "anjou":
            return "4px 4px var(--chakra-colors-anjou-base-shadow-02)";
          default:
            return "4px 4px gray.300";
        }
      },
      borderStyle: "solid",
      borderColor: () => {
        switch (props.colorScheme) {
          case "tangerine":
            return "tangerine.04";
          case "monaco":
            return "monaco.03";
          case "capri":
            return "capri.03";
          case "anjou":
            return "anjou.base-shadow-02";
          default:
            return "gray.300";
        }
      },
      borderWidth: props.size === "lg" ? "3px" : "2px",
      textStyle: "gameLabel",
      fontWeight: "normal",
      fontSize: () => {
        switch (props.size) {
          case "sm":
            return "12px";
          case "md":
            return "16px";
          case "lg":
            return "20px";
        }
      },
      padding: () => {
        switch (props.size) {
          case "sm":
            return "4px 12px";
          case "md":
            return "8px 16px";
          case "lg":
            return "8px 24px";
        }
      },
      lineHeight: () => {
        switch (props.size) {
          case "sm":
            return "16px";
          case "md":
            return "16px";
          case "lg":
            return "32px";
        }
      },
      _hover: {
        bg: () => {
          switch (props.colorScheme) {
            case "tangerine":
              return "#f25238";
            case "monaco":
              return "#009dd6";
            case "capri":
              return "#2ca89d";
            case "anjou":
              return "#aab038";
            default:
              return "gray.50";
          }
        },
      },
      _active: {
        boxShadow: "none",
        bg: () => {
          switch (props.colorScheme) {
            case "tangerine":
              return "#eb4f36";
            case "monaco":
              return "#0094c9";
            case "capri":
              return "#2a9c91";
            case "anjou":
              return "anjou.base-shadow-01";
            default:
              return "gray.50";
          }
        },
      },
      ":disabled:hover": {
        bg: () => {
          switch (props.colorScheme) {
            case "tangerine":
              return "tangerine.03";
            case "monaco":
              return "monaco.02";
            case "capri":
              return "capri.02";
            case "anjou":
              return "anjou.base";
            default:
              return "gray.100";
          }
        },
      },
      ":focus:not(:focus-visible):not(:active)": {
        boxShadow: () => {
          switch (props.colorScheme) {
            case "tangerine":
              return "4px 4px var(--chakra-colors-tangerine-04)";
            case "monaco":
              return "4px 4px var(--chakra-colors-monaco-03)";
            case "capri":
              return "4px 4px var(--chakra-colors-capri-03)";
            case "anjou":
              return "4px 4px var(--chakra-colors-anjou-base-shadow-02)";
            default:
              return "4px 4px gray.300";
          }
        },
      },
    }),
    gameSecondary: (props: {
      [key: string]: string;
    }): Record<string, unknown> => ({
      borderRadius: "none",
      bg: "white",
      color: () => {
        switch (props.colorScheme) {
          case "tangerine":
            return "tangerine.03";
          case "monaco":
            return "monaco.02";
          case "capri":
            return "capri.02";
          case "anjou":
            return "anjou.base";
          default:
            return "gray.100";
        }
      },
      boxShadow: () => {
        switch (props.colorScheme) {
          case "tangerine":
            return "4px 4px var(--chakra-colors-tangerine-03)";
          case "monaco":
            return "4px 4px var(--chakra-colors-monaco-02)";
          case "capri":
            return "4px 4px var(--chakra-colors-capri-02)";
          case "anjou":
            return "4px 4px var(--chakra-colors-anjou-base)";
          default:
            return "4px 4px gray.300";
        }
      },
      borderStyle: "solid",
      borderColor: () => {
        switch (props.colorScheme) {
          case "tangerine":
            return "tangerine.03";
          case "monaco":
            return "monaco.02";
          case "capri":
            return "capri.02";
          case "anjou":
            return "anjou.base";
          default:
            return "gray.300";
        }
      },
      borderWidth: props.size === "lg" ? "3px" : "2px",
      textStyle: "gameLabel",
      fontWeight: "normal",
      fontSize: () => {
        switch (props.size) {
          case "sm":
            return "12px";
          case "md":
            return "16px";
          case "lg":
            return "20px";
        }
      },
      padding: () => {
        switch (props.size) {
          case "sm":
            return "4px 12px";
          case "md":
            return "8px 16px";
          case "lg":
            return "8px 24px";
        }
      },
      lineHeight: () => {
        switch (props.size) {
          case "sm":
            return "16px";
          case "md":
            return "16px";
          case "lg":
            return "32px";
        }
      },
      _hover: {
        bg: () => {
          switch (props.colorScheme) {
            case "tangerine":
              return "#fff0ed";
            case "monaco":
              return "#f2fcff";
            case "capri":
              return "#ebfffd";
            case "anjou":
              return "anjou.light";
            default:
              return "gray.50";
          }
        },
      },
      _active: {
        boxShadow: "none",
        bg: () => {
          switch (props.colorScheme) {
            case "tangerine":
              return "#fce7e3";
            case "monaco":
              return "#e6f6fc";
            case "capri":
              return "#e0fffc";
            case "anjou":
              return "anjou.light-shadow-01";
            default:
              return "gray.50";
          }
        },
      },
      ":focus:not(:focus-visible):not(:active)": {
        boxShadow: () => {
          switch (props.colorScheme) {
            case "tangerine":
              return "4px 4px var(--chakra-colors-tangerine-03)";
            case "monaco":
              return "4px 4px var(--chakra-colors-monaco-02)";
            case "capri":
              return "4px 4px var(--chakra-colors-capri-02)";
            case "anjou":
              return "4px 4px var(--chakra-colors-anjou-base)";
            default:
              return "4px 4px gray.300";
          }
        },
      },
    }),
    gameTertiary: (props: {
      [key: string]: string;
    }): Record<string, unknown> => ({
      borderRadius: "none",
      bg: "white",
      color: () => {
        switch (props.colorScheme) {
          case "tangerine":
            return "tangerine.03";
          case "monaco":
            return "monaco.02";
          case "capri":
            return "capri.02";
          case "anjou":
            return "anjou.base";
          default:
            return "gray.100";
        }
      },
      boxShadow: "none",
      borderStyle: "none",
      textStyle: "gameLabel",
      fontWeight: "normal",
      fontSize: () => {
        switch (props.size) {
          case "sm":
            return "12px";
          case "md":
            return "16px";
          case "lg":
            return "20px";
        }
      },
      padding: () => {
        switch (props.size) {
          case "sm":
            return "4px 12px";
          case "md":
            return "8px 16px";
          case "lg":
            return "8px 24px";
        }
      },
      lineHeight: () => {
        switch (props.size) {
          case "sm":
            return "16px";
          case "md":
            return "16px";
          case "lg":
            return "32px";
        }
      },
      _hover: {
        bg: () => {
          switch (props.colorScheme) {
            case "tangerine":
              return "#fff0ed";
            case "monaco":
              return "#f2fcff";
            case "capri":
              return "#ebfffd";
            case "anjou":
              return "anjou.light";
            default:
              return "gray.50";
          }
        },
      },
      _active: {
        bg: () => {
          switch (props.colorScheme) {
            case "tangerine":
              return "#fce7e3";
            case "monaco":
              return "#e6f6fc";
            case "capri":
              return "#e0fffc";
            case "anjou":
              return "anjou.light-shadow-01";
            default:
              return "gray.50";
          }
        },
      },
    }),
  },
};
