import React, { createContext, useContext, useMemo } from "react";

import { ISessionUsers } from "../types";

export const SessionUsersContext = createContext<ISessionUsers>({});

export interface ISessionUsersProviderProps {
  wsStateUsers: ISessionUsers;
}

export const SessionUsersProvider: React.FC<ISessionUsersProviderProps> = ({
  children,
  wsStateUsers,
}) => {
  const value = useMemo(() => wsStateUsers, [wsStateUsers]);
  return (
    <SessionUsersContext.Provider value={value}>
      {children}
    </SessionUsersContext.Provider>
  );
};

export const useSessionUsers = (): ISessionUsers => {
  return useContext(SessionUsersContext);
};
