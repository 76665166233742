import {
  Box,
  Flex,
  useBreakpointValue,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Card } from "adminComponents/atoms/Card";
import { ClampedHeading } from "adminComponents/atoms/ClampedHeading";
import { CoverImageIcon } from "adminComponents/atoms/CoverImageIcon";
import { Divider } from "adminComponents/atoms/Divider";
import { IconButton } from "adminComponents/atoms/IconButton";
import { AssignmentDueDate } from "adminComponents/molecules/StudentAssignmentCard/components/AssignmentDueDate";
import { AssignmentProgress } from "adminComponents/molecules/StudentAssignmentCard/components/AssignmentProgress";
import { SummarizedSubjectTags } from "adminComponents/molecules/SummarizedSubjectTags";
import { pxToRem } from "adminComponents/utils";
import { CoverImageBGColorSchemes } from "links/lib/constants";
import { IAssignment } from "links/lib/types";

export interface IProps {
  assignment: IAssignment;
  accuracy: number;
  isDueToday: boolean;
  rewardXp: number;
  size?: "sm" | "lg";
  headingLevel?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p";
  handlePlayButton: (assignment: IAssignment) => void;
  isDisabled?: boolean;
  isLoading?: boolean;
}

export const StudentAssignmentCard: React.FC<IProps> = ({
  assignment,
  accuracy,
  isDueToday,
  rewardXp,
  size = "sm",
  headingLevel,
  handlePlayButton,
  isDisabled,
  isLoading,
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });

  const { ends_at, required_score_percent } = assignment;
  const { title, cover_image_icon, subjects, cover_image_bg_color_scheme } =
    assignment.practice_set;

  const handlePlay = () => handlePlayButton(assignment);

  let rgbBackgroundColor;

  if (cover_image_bg_color_scheme) {
    const [backgroundRGB] =
      CoverImageBGColorSchemes[cover_image_bg_color_scheme];
    rgbBackgroundColor = "rgb(" + backgroundRGB + ")";
  }

  const isMobile = useBreakpointValue({ base: true, md: false });
  const isSmall = size === "sm" || isMobile;

  const styles = useMultiStyleConfig("AdminStudentAssignmentCard", {
    rgbBackgroundColor,
    isSmall,
  });

  return (
    <Box>
      <Card
        sx={styles.container}
        borderColor="primary.tan"
        variant="adminCardSelectable"
        backgroundColor="primary.white"
      >
        <Flex sx={styles.header}>
          {isSmall && (
            <Flex sx={styles.iconContainer}>
              <CoverImageIcon
                sx={styles.icon}
                icon={cover_image_icon}
                borderColor="primary.white"
                rgbBackgroundColor={rgbBackgroundColor}
                variant="adminSolidBorder"
              />
            </Flex>
          )}
          <SummarizedSubjectTags
            pl={isSmall ? pxToRem(80) : 0}
            maxW="full"
            subjects={subjects}
          />
        </Flex>

        <Box sx={styles.content}>
          <Flex sx={styles.contentTop}>
            {!isSmall && (
              <CoverImageIcon
                sx={styles.icon}
                icon={cover_image_icon}
                iconSize={80}
                borderColor="primary.white"
                rgbBackgroundColor={rgbBackgroundColor}
                variant="adminSolidBorder"
              />
            )}
            <Box sx={styles.title}>
              <ClampedHeading
                noOfLines={3}
                headingAs={headingLevel ? headingLevel : "p"}
                headingVariant="adminH4"
              >
                {title}
              </ClampedHeading>
              <AssignmentDueDate date={ends_at} isDueToday={isDueToday} />
            </Box>

            <Flex sx={styles.contentBottom}>
              {!isSmall && (
                <AssignmentProgress
                  accuracy={accuracy}
                  accuracyGoal={required_score_percent}
                  rewardXp={rewardXp}
                />
              )}

              <IconButton
                sx={styles.playButton}
                variant="adminPlayButton"
                shape="type2"
                icon="play"
                ariaLabel={t("common.play")}
                onClick={handlePlay}
                disabled={isDisabled}
                isLoading={isLoading}
              />
            </Flex>
          </Flex>

          {isSmall && (
            <Divider sx={styles.divider} color="primary.light-gray" />
          )}

          {isSmall && (
            <AssignmentProgress
              accuracy={accuracy}
              accuracyGoal={required_score_percent}
              rewardXp={rewardXp}
            />
          )}
        </Box>
      </Card>
    </Box>
  );
};
