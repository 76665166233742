import desktopImage from "adminComponents/assets/background-images/illustrated-background-desktop.svg";
import mobileImage from "adminComponents/assets/background-images/illustrated-background-mobile.svg";
import { pxToRem } from "adminComponents/utils/pxToRem";

export const AdminTemplateWithCenteredContent = {
  baseStyle: {
    outerContainer: {
      backgroundColor: "primary.warm-white",
      minHeight: "100vh",
      paddingBottom: pxToRem(50),
      width: "100%",
    },
    backgroundContainer: {
      backgroundImage: { base: mobileImage, md: desktopImage },
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundAttachment: "fixed",
      backgroundBlendMode: "saturation",
    },
    nav: {
      backgroundImage: { base: mobileImage, md: desktopImage },
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundAttachment: "fixed",
      backgroundBlendMode: "saturation",
    },
    headingContainer: {
      textAlign: "center",
      paddingTop: pxToRem(72),
    },
    centeredContentContainer: {
      marginBottom: pxToRem(20),
      paddingTop: pxToRem(56),
      position: "relative",
      zIndex: "0",
      _after: {
        content: "''",
        position: "absolute",
        top: "calc(50% + 28px)",
        left: "0",
        right: "0",
        bottom: "0",
        backgroundColor: "primary.warm-white",
        zIndex: "-1",
      },
    },
    loadingSpinner: {
      marginTop: pxToRem(100),
    },
    banner: {
      paddingBottom: pxToRem(40),
    },
  },
};
