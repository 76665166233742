import {
  Box,
  Container,
  Flex,
  Show,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import React, { MouseEventHandler, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Icon } from "adminComponents/atoms/Icon";
import { Tag } from "adminComponents/atoms/Tag";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { IPracticeSet } from "links/lib/types";

export interface IProps {
  handleAddQuestion: MouseEventHandler;
  handleImportQuestions?: MouseEventHandler;
  handleReorderQuestions: MouseEventHandler;
  handleSaveOrder: MouseEventHandler;
  handleCancelOrder: MouseEventHandler;
  questionCount: IPracticeSet["item_count"];
  standardsCount: number;
  feedbackCount: number;
  showReorderControls?: boolean;
  isReorderLoading?: boolean;
  hideControls?: boolean;
}

export const PracticeSetQuestionListControlsFixedFooter: React.FC<IProps> = ({
  handleAddQuestion,
  handleImportQuestions,
  handleReorderQuestions,
  handleSaveOrder,
  handleCancelOrder,
  questionCount,
  standardsCount,
  feedbackCount,
  showReorderControls,
  isReorderLoading,
  hideControls,
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });

  const fixedFooterRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (fixedFooterRef.current) {
      const fixedFooter = fixedFooterRef.current;
      const callback = (event: Event) => {
        const { detail } = event as CustomEvent;
        if (detail.showFixedFooter) {
          fixedFooter.style.transform = "translateY(0%)";
        } else {
          fixedFooter.style.transform = "translateY(100%)";
        }
      };
      window.addEventListener("question-list-controls-intersect", callback);

      return () =>
        window.removeEventListener(
          "question-list-controls-intersect",
          callback
        );
    }
  }, [fixedFooterRef]);

  const styles = useMultiStyleConfig(
    "AdminPracticeSetQuestionListControlsFixedFooter",
    {}
  );

  return (
    <Box ref={fixedFooterRef} __css={styles.outer}>
      <Container variant="adminContentWrapper">
        <Flex
          align="center"
          width="100%"
          justify="space-between"
          gap={pxToRem(16)}
        >
          {!hideControls && (
            <Box __css={styles.buttonsContainer}>
              {showReorderControls && (
                <Button
                  variant="adminButtonFilled"
                  aria-label={t("common.save")}
                  onClick={handleSaveOrder}
                >
                  {t("common.save")}
                </Button>
              )}
              {!showReorderControls && (
                <>
                  <Button
                    variant="adminButtonFilled"
                    aria-label={t("practiceSet.ariaLabelAdd")}
                    onClick={handleAddQuestion}
                    leftIcon={<Icon icon="add" />}
                  >
                    {t("practiceSet.labelAdd")}
                  </Button>

                  <Button
                    variant="adminButtonFilled"
                    aria-label={t("practiceSet.ariaLabelImportQuestions")}
                    onClick={handleImportQuestions}
                    leftIcon={<Icon icon="upload" />}
                  >
                    {t("practiceSet.labelImportQuestions")}
                  </Button>
                </>
              )}
              <Button
                variant="adminButtonOutlined"
                isLoading={isReorderLoading}
                aria-label={
                  showReorderControls
                    ? t("common.cancel")
                    : t("practiceSet.ariaLabelReorder")
                }
                onClick={
                  showReorderControls
                    ? handleCancelOrder
                    : handleReorderQuestions
                }
                leftIcon={
                  showReorderControls ? undefined : <Icon icon="reorder" />
                }
              >
                {showReorderControls
                  ? t("common.cancel")
                  : t("practiceSet.labelReorder")}
              </Button>
            </Box>
          )}
          <Show above="md">
            <Flex align="center">
              <Text
                variant="adminMeta"
                color="primary.dark-gray"
                marginRight={pxToRem(16)}
              >
                {t("practiceSet.labelIncludes")}:
              </Text>
              <Tag
                tooltip={t("practiceSet.tooltipFeedback")}
                variant="adminOutline"
                colorScheme="tan"
                marginRight={pxToRem(8)}
              >
                <>
                  {feedbackCount}/{questionCount} {t("common.feedback")}
                </>
              </Tag>
              <Tag
                tooltip={t("practiceSet.tooltipStandards")}
                variant="adminOutline"
                colorScheme="tan"
              >
                <>
                  {standardsCount}/{questionCount} {t("common.standards")}
                </>
              </Tag>
            </Flex>
          </Show>
        </Flex>
      </Container>
    </Box>
  );
};
