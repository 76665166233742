import { IconType } from "adminComponents/atoms/Icon";
import { AvatarItemCategoryId } from "links/lib/types";

export { hairColorHexCodes, skinToneHexCodes } from "links/lib/constants";

export interface SectionIcon {
  name?: IconType;
  sectionId: string;
}

export type IconsByTab = { [key: string]: Array<SectionIcon> };

export type ISectionConfig = {
  sectionKey: string;
  config: {
    icon?: IconType;
    showHairColorSelection?: boolean;
    showSkinToneSelection?: boolean;
    subSections: Array<ISubSection>;
  };
};

export type ISubSection = {
  labelKey: string;
  canDeequipCategory?: boolean;
  categoryId: string;
};

export const sections: {
  [key: string]: ISectionConfig;
} = {
  body: {
    sectionKey: "body",
    config: {
      showSkinToneSelection: true,
      subSections: [],
    },
  },
  head: {
    sectionKey: "head",
    config: {
      icon: "head",
      subSections: [
        {
          labelKey: "labelHeadShape",
          categoryId: AvatarItemCategoryId.Head,
        },
        {
          labelKey: "labelForehead",
          canDeequipCategory: true,
          categoryId: AvatarItemCategoryId.Forehead,
        },
      ],
    },
  },
  eyes: {
    sectionKey: "head",
    config: {
      icon: "eye",
      subSections: [
        {
          labelKey: "labelEyeShape",
          categoryId: "6",
        },
      ],
    },
  },
  eyewear: {
    sectionKey: "head",
    config: {
      icon: "eye_glasses",
      subSections: [
        {
          labelKey: "labelEyewear",
          canDeequipCategory: true,
          categoryId: AvatarItemCategoryId.Glasses,
        },
      ],
    },
  },
  ear: {
    sectionKey: "head",
    config: {
      icon: "ear",
      subSections: [
        {
          labelKey: "labelEars",
          categoryId: AvatarItemCategoryId.Ears,
        },
      ],
    },
  },
  hair: {
    sectionKey: "head",
    config: {
      icon: "comb_scissors",
      showHairColorSelection: true,
      subSections: [
        {
          labelKey: "",
          categoryId: AvatarItemCategoryId.Hair,
        },
      ],
    },
  },
  facial_hair: {
    sectionKey: "head",
    config: {
      icon: "moustache",
      showHairColorSelection: true,
      subSections: [
        {
          labelKey: "labelFacialHair",
          canDeequipCategory: true,
          categoryId: AvatarItemCategoryId.Facial_Hair,
        },
      ],
    },
  },
  nose: {
    sectionKey: "head",
    config: {
      icon: "nose",
      subSections: [
        {
          labelKey: "labelNoseShape",
          categoryId: AvatarItemCategoryId.Nose,
        },
      ],
    },
  },
  mouth: {
    sectionKey: "head",
    config: {
      icon: "lips",
      subSections: [
        {
          labelKey: "labelMouthShape",
          categoryId: AvatarItemCategoryId.Mouth,
        },
      ],
    },
  },
  skin: {
    sectionKey: "head",
    config: {
      icon: "freckles",
      subSections: [
        {
          labelKey: "labelCheeks",
          canDeequipCategory: true,
          categoryId: AvatarItemCategoryId.Cheeks,
        },
        {
          labelKey: "labelFeatures",
          canDeequipCategory: true,
          categoryId: AvatarItemCategoryId.Facial_Detail,
        },
      ],
    },
  },
  tops: {
    sectionKey: "clothes",
    config: {
      icon: "shirt",
      subSections: [
        {
          labelKey: "labelShirts",
          categoryId: AvatarItemCategoryId.Top,
        },
      ],
    },
  },
  bottoms: {
    sectionKey: "clothes",
    config: {
      icon: "shorts",
      subSections: [
        {
          labelKey: "labelPants",
          categoryId: AvatarItemCategoryId.Bottom,
        },
        {
          labelKey: "labelWaist",
          canDeequipCategory: true,
          categoryId: AvatarItemCategoryId.Waist,
        },
      ],
    },
  },
  footwear: {
    sectionKey: "clothes",
    config: {
      icon: "sock",
      subSections: [
        {
          labelKey: "labelShoes",
          canDeequipCategory: true,
          categoryId: AvatarItemCategoryId.Shoes,
        },
        {
          labelKey: "labelSocks",
          canDeequipCategory: true,
          categoryId: AvatarItemCategoryId.Socks,
        },
      ],
    },
  },
  cape: {
    sectionKey: "clothes",
    config: {
      icon: "dress",
      subSections: [
        {
          labelKey: "labelCapes",
          canDeequipCategory: true,
          categoryId: AvatarItemCategoryId.Cape,
        },
      ],
    },
  },
  headware: {
    sectionKey: "accessories",
    config: {
      icon: "hat",
      subSections: [
        {
          labelKey: "labelHeadwear",
          canDeequipCategory: true,
          categoryId: AvatarItemCategoryId.Hat,
        },
      ],
    },
  },
  earwear: {
    sectionKey: "accessories",
    config: {
      icon: "earrings",
      subSections: [
        {
          labelKey: "labelEarAccessories",
          canDeequipCategory: true,
          categoryId: AvatarItemCategoryId.Ear_Accessories,
        },
      ],
    },
  },
  lower_face: {
    sectionKey: "accessories",
    config: {
      icon: "lower_face",
      subSections: [
        {
          labelKey: "labelLowerFace",
          canDeequipCategory: true,
          categoryId: AvatarItemCategoryId.Lower_Face,
        },
      ],
    },
  },
  neckwear: {
    sectionKey: "accessories",
    config: {
      icon: "necklace",
      subSections: [
        {
          labelKey: "labelNeckwear",
          canDeequipCategory: true,
          categoryId: AvatarItemCategoryId.Neck,
        },
      ],
    },
  },
  back: {
    sectionKey: "accessories",
    config: {
      icon: "backpack",
      subSections: [
        {
          labelKey: "labelBack",
          canDeequipCategory: true,
          categoryId: AvatarItemCategoryId.Back,
        },
      ],
    },
  },
  hands: {
    sectionKey: "accessories",
    config: {
      icon: "hand",
      // Labels and categories are swapped intentionally.
      // Creative refers to the left hand as the left side of the avatar,
      // where as users think of the left hand as the one on the right side.
      subSections: [
        {
          labelKey: "labelRightHandItem",
          canDeequipCategory: true,
          categoryId: AvatarItemCategoryId.Left_Hand,
        },
        {
          labelKey: "labelLeftHandItem",
          canDeequipCategory: true,
          categoryId: AvatarItemCategoryId.Right_Hand,
        },
        {
          labelKey: "labelRightWristItem",
          canDeequipCategory: true,
          categoryId: AvatarItemCategoryId.Left_Wrist,
        },
        {
          labelKey: "labelLeftWristItem",
          canDeequipCategory: true,
          categoryId: AvatarItemCategoryId.Right_Wrist,
        },
      ],
    },
  },
  chair_styles: {
    sectionKey: "wheelchair",
    config: {
      icon: "wheelchair_chairs",
      subSections: [
        {
          labelKey: "labelWheelchairChairs",
          canDeequipCategory: false,
          categoryId: AvatarItemCategoryId.Chair_Style,
        },
      ],
    },
  },
  head_attachments: {
    sectionKey: "wheelchair",
    config: {
      icon: "wheelchair_head_attachments",
      subSections: [
        {
          labelKey: "labelWheelchairHeadAttachments",
          canDeequipCategory: false,
          categoryId: AvatarItemCategoryId.Head_Attachments,
        },
      ],
    },
  },
  wheels: {
    sectionKey: "wheelchair",
    config: {
      icon: "wheelchair_wheels",
      subSections: [
        {
          labelKey: "labelWheelchairWheels",
          canDeequipCategory: false,
          categoryId: AvatarItemCategoryId.Wheel_Style,
        },
      ],
    },
  },
  spoke_guards: {
    sectionKey: "wheelchair",
    config: {
      icon: "wheelchair_spoke_guards",
      subSections: [
        {
          labelKey: "labelWheelchairSpokeGuards",
          canDeequipCategory: true,
          categoryId: AvatarItemCategoryId.Spoke_Guards,
        },
      ],
    },
  },
  frame_stickers: {
    sectionKey: "wheelchair",
    config: {
      icon: "wheelchair_frame_stickers",
      subSections: [
        {
          labelKey: "labelWheelchairFrameStickers",
          canDeequipCategory: true,
          categoryId: AvatarItemCategoryId.Frame_Stickers,
        },
      ],
    },
  },
  armrests: {
    sectionKey: "wheelchair",
    config: {
      icon: "wheelchair_armrests",
      subSections: [
        {
          labelKey: "labelWheelchairArmrests",
          canDeequipCategory: true,
          categoryId: AvatarItemCategoryId.Armrest_Style,
        },
      ],
    },
  },
};
