import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Divider } from "adminComponents/atoms/Divider";
import { Heading } from "adminComponents/atoms/Heading";
import { Icon } from "adminComponents/atoms/Icon";
import { LoadingSpinner } from "adminComponents/atoms/LoadingSpinner";
import { ClassDetailAssignments } from "adminComponents/molecules/ClassDetailAssignments";
import { EmptyCard } from "adminComponents/molecules/EmptyCard";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { IAssignment, IClassroom } from "links/lib/types";

export interface AssignmentCardProps {
  accuracy?: number;
  assignment: IAssignment;
  completedStudentCount?: number;
  totalStudentCount?: number;
}

interface IProps {
  activeAssignmentData?: Array<AssignmentCardProps>;
  classroom: IClassroom;
  handleCreateAssignment: (classroom: IClassroom) => void;
  handleDeleteAssignmentCard: (assignment: IAssignment) => void;
  handleEditAssignmentCard: (assignment: IAssignment) => void;
  handleViewMoreAssignments: () => void;
  pastAssignmentData?: Array<AssignmentCardProps>;
  showViewMoreAssignments?: boolean;
  upcomingAssignmentData?: Array<AssignmentCardProps>;
  isLoading?: boolean;
}

export const AssignmentsTab: React.FC<IProps> = ({
  activeAssignmentData,
  classroom,
  handleCreateAssignment,
  handleDeleteAssignmentCard,
  handleEditAssignmentCard,
  handleViewMoreAssignments,
  pastAssignmentData,
  showViewMoreAssignments,
  upcomingAssignmentData,
  isLoading,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  const hasAssignments =
    activeAssignmentData?.length ||
    upcomingAssignmentData?.length ||
    pastAssignmentData?.length;

  return (
    <Box mt={pxToRem(40)}>
      {isLoading && <LoadingSpinner />}
      {!isLoading && (
        <>
          {hasAssignments ? (
            <>
              <Flex
                alignItems="center"
                justifyContent="space-between"
                mb={pxToRem(32)}
              >
                <Heading as="h2" variant="adminH3">
                  {t("classDetailCards.assignmentsCurrent")}
                </Heading>
                <Button
                  onClick={() => handleCreateAssignment(classroom)}
                  variant="adminButtonFilled"
                >
                  {t("classDetailCards.assignmentsCta")}
                </Button>
              </Flex>
              <Box>
                <Heading as="h3" mb={pxToRem(12)} variant="adminH6">
                  {t("common.active")}
                </Heading>
                {activeAssignmentData?.length ? (
                  <ClassDetailAssignments
                    assignmentData={activeAssignmentData}
                    handleEdit={handleEditAssignmentCard}
                    handleDelete={handleDeleteAssignmentCard}
                  />
                ) : (
                  <EmptyCard
                    title={t("classDetailEmptyCards.activeAssignmentTitle")}
                  />
                )}
              </Box>
              {!!upcomingAssignmentData?.length && (
                <Box mt={pxToRem(32)}>
                  <Heading as="h3" mb={pxToRem(12)} variant="adminH6">
                    {t("common.upcoming")}
                  </Heading>
                  <ClassDetailAssignments
                    assignmentData={upcomingAssignmentData}
                    handleDelete={handleDeleteAssignmentCard}
                    handleEdit={handleEditAssignmentCard}
                    isUpcoming
                  />
                </Box>
              )}
              {!!pastAssignmentData?.length && (
                <>
                  <Divider
                    color="primary.tan"
                    mb={pxToRem(56)}
                    mt={pxToRem(56)}
                  />
                  <Box>
                    <Heading as="h2" mb={pxToRem(32)} variant="adminH3">
                      {t("classDetailCards.assignmentsPast")}
                    </Heading>
                    <ClassDetailAssignments
                      assignmentData={pastAssignmentData}
                      handleDelete={handleDeleteAssignmentCard}
                      handleEdit={handleEditAssignmentCard}
                    />
                  </Box>
                </>
              )}
              {showViewMoreAssignments && (
                <Flex mb={pxToRem(32)} mt={pxToRem(32)} justifyContent="center">
                  <Button
                    onClick={handleViewMoreAssignments}
                    rightIcon={
                      <Icon
                        height={pxToRem(7)}
                        icon="button_down_arrow"
                        color="currentColor"
                        ml={pxToRem(11)}
                        width={pxToRem(12)}
                      />
                    }
                    variant="adminTextButtonLarge"
                  >
                    {t("common.viewMore")}
                  </Button>
                </Flex>
              )}
            </>
          ) : (
            <EmptyCard
              actions={[
                {
                  onClick: () => handleCreateAssignment(classroom),
                  size: "lg",
                  text: t("classDetailTabs.ctaAssignment"),
                  variant: "adminButtonFilled",
                },
              ]}
              text={t("classDetailEmptyCards.assignmentsText")}
              title={t("classDetailEmptyCards.assignmentsTitle")}
            />
          )}
        </>
      )}
    </Box>
  );
};
