import { Box, Text, usePrefersReducedMotion } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { animated, config, useSprings } from "react-spring";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { ISessionUser } from "links/lib/types";
import { Card } from "sessionComponents/atoms/Card";
import { GroupToken } from "sessionComponents/atoms/GroupToken";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { useDetectOrientation } from "sessionComponents/theme/hooks/useDetectOrientation";
import { getAssetColorScheme } from "sessionComponents/utils/getAssetColorScheme";
import { getFormattedGroupName } from "sessionComponents/utils/getFormattedGroupName";
import { useSafeConnectedUserArray } from "sharedComponents/hooks/useSafeConnectedUserArray";
import { useSafeGroupsArray } from "sharedComponents/hooks/useSafeGroupsArray";

const AnimatedBox = animated(Box);

interface IGroupScoresProps {
  onAnimationEnd?: () => void;
}

export const GroupScores: React.FC<IGroupScoresProps> = ({
  onAnimationEnd,
}) => {
  const { match: currentBreakpoints } = useBreakpoints();
  const { isPortrait } = useDetectOrientation();
  const prefersReducedMotion = usePrefersReducedMotion();

  const groups = useSafeGroupsArray();
  const connectedUsers = useSafeConnectedUserArray();

  const sortedGroups = useMemo(() => {
    return groups.sort(
      (a, b) => b.total_awarded_points - a.total_awarded_points
    );
  }, [groups]);

  const top3Groups = sortedGroups.slice(0, 3);

  const remainingGroups = sortedGroups.slice(3, sortedGroups.length);

  const [top3Transitions] = useSprings(top3Groups.length, (i) => {
    return {
      from: { opacity: 0, scale: prefersReducedMotion ? 1 : 0 },
      to: { opacity: 1, scale: 1 },
      config: config.default,
      delay: 750 * (i + 1),
      onRest: () => {
        if (i === top3Groups.length - 1) {
          onAnimationEnd?.();
        }
      },
    };
  });

  const landscapeStyleProps = {
    borderRadius: "0px",
    borderBottomWidth: 0,
    borderTopWidth: 0,
    margin: 0,
    useThickBorder: false,
  };

  const portraitStyleProps = {
    useThickBorder: true,
    margin: pxToRem(currentBreakpoints.margin),
  };

  const styleProps = isPortrait ? portraitStyleProps : landscapeStyleProps;

  const getGroupMembers = (groupId: string) =>
    connectedUsers.filter((user) => user.session_group_id === groupId);

  return (
    <Card
      bgColor="white"
      w="auto"
      h="full"
      zIndex="2"
      overflowY="auto"
      {...styleProps}
    >
      <Box
        w="full"
        flexGrow={1}
        display="flex"
        flexDir="column"
        gap={pxToRem(currentBreakpoints.margin)}
      >
        {top3Groups.map((group, i) => (
          <AnimatedBox
            w="full"
            flexDir="row"
            display="flex"
            gap={pxToRem(currentBreakpoints.margin / 2)}
            justifyContent="center"
            alignItems="center"
            key={group.id}
            style={top3Transitions[top3Transitions.length - 1 - i]}
          >
            <GroupListItem
              groupMembers={getGroupMembers(group.id)}
              groupName={getFormattedGroupName(group)}
              iconUrl={group.icon_url}
              totalAwardedPoints={group.total_awarded_points}
              colorScheme={group.color_scheme}
            />
          </AnimatedBox>
        ))}

        <Box
          w="full"
          flexGrow={1}
          display="flex"
          flexDir="column"
          gap={pxToRem(currentBreakpoints.margin)}
        >
          {remainingGroups.map((group) => (
            <Box
              w="full"
              flexDir="row"
              display="flex"
              gap={pxToRem(currentBreakpoints.margin / 2)}
              justifyContent="center"
              alignItems="center"
              key={group.id}
            >
              <GroupListItem
                groupName={getFormattedGroupName(group)}
                totalAwardedPoints={group.total_awarded_points}
                colorScheme={group.color_scheme}
                groupMembers={getGroupMembers(group.id)}
                iconUrl={group.icon_url}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Card>
  );
};

const GroupListItem = ({
  groupName,
  colorScheme,

  iconUrl,
  groupMembers,
  totalAwardedPoints,
}: {
  groupName: string;
  colorScheme: string;
  iconUrl: string;
  groupMembers: ISessionUser[];
  totalAwardedPoints: number;
}) => {
  const { match: currentBreakpoints } = useBreakpoints();
  return (
    <>
      <GroupToken
        tooltipPlacement="top"
        name={groupName}
        colorScheme={getAssetColorScheme(colorScheme)}
        imageUrl={iconUrl}
        groupMembers={groupMembers}
      />
      <Text
        fontSize={pxToRem(currentBreakpoints.fontSize)}
        textStyle="gameTextWeighted"
        flexGrow={1}
      >
        {groupName}
      </Text>
      <Box textAlign="center">
        <Text
          fontSize={pxToRem(currentBreakpoints.fontSize)}
          textStyle="gameDisplayInline"
        >
          {totalAwardedPoints}
        </Text>
        {/* {!!group.round_awarded_points && (
        <Text
          color="tomato.base"
          fontSize={pxToRem(currentBreakpoints.fontSize)}
          textStyle="gameText"
        >
          +{group.round_awarded_points}
        </Text>
      )} */}
      </Box>
    </>
  );
};
