import React from "react";
import { useMount, useUnmount } from "react-use";

import { useAnalytics } from "lib/contexts/analytics";
import { useSession } from "links/lib/contexts/session";
import { useSessionActions } from "links/lib/contexts/sessionActions";
import { useSessionGameState } from "links/lib/contexts/sessionGameState";
import {
  AnalyticsEvent,
  IUser,
  SessionGameType,
  WebSocketSession,
} from "links/lib/types";
import { useStudent } from "sessionComponents/contexts/student";
import { StudentLobby as TheStudentLobby } from "sessionComponents/organisms/StudentLobby";
import { useEditAvatar } from "sharedComponents/hooks/useEditAvatar";

export const StudentLobby: React.FC<{
  authUser: IUser;
  refetchUser: () => void;
  enableUnloadWarning: () => void;
  disableUnloadWarning: () => void;
}> = ({ authUser, refetchUser, enableUnloadWarning, disableUnloadWarning }) => {
  const session = useSession() as WebSocketSession;
  const { avatarUpdated, exitGame, shuffleGuestName } = useSessionActions();
  const student = useStudent();
  const gameState = useSessionGameState();
  const { trackEvent } = useAnalytics();

  useMount(() => {
    disableUnloadWarning();
  });

  useUnmount(() => {
    enableUnloadWarning();
  });

  const {
    modal: editAvatarModal,
    profileImageCapture,
    onOpen: onOpenEditAvatarModal,
  } = useEditAvatar({
    useModal: true,
    modalSize: "6xl",
    refetchAvatarOnWindowFocus: true,
    onClose: () => avatarUpdated(),
    onDone: () => avatarUpdated(),
    onEquipAvatarItem: (avatarItemId, categoryId, colorHex) => {
      trackEvent(AnalyticsEvent.Session_StudentLobby_EditAvatar_EquipItem, {
        itemId: avatarItemId,
        itemCategoryId: categoryId,
        itemColorHexCode: colorHex,
      });
    },
    onDequipAvatarItem: (avatarItemId, categoryId) => {
      if (!categoryId) return;

      trackEvent(AnalyticsEvent.Session_StudentLobby_EditAvatar_UnsetCategory, {
        itemCategoryId: categoryId,
      });
    },
    onSetSkeletonType: (skeletonType) => {
      trackEvent(
        AnalyticsEvent.Session_StudentLobby_EditAvatar_SetSkeletonType,
        {
          skeletonType: skeletonType,
        }
      );
    },
    onSetHairColor: (hairColorHexCode) => {
      trackEvent(AnalyticsEvent.Session_StudentLobby_EditAvatar_SetHairColor, {
        colorHexCode: hairColorHexCode,
      });
    },
    onSetSkinTone: (skinToneHexCode) => {
      trackEvent(AnalyticsEvent.Session_StudentLobby_EditAvatar_SetSkinTone, {
        toneHexCode: skinToneHexCode,
      });
    },
  });

  return (
    <>
      <TheStudentLobby
        authUser={authUser}
        outerGame={gameState?.game_type || SessionGameType.QuickPlay}
        joinCode={session.code}
        student={student}
        onOpenEditAvatarModal={onOpenEditAvatarModal}
        onShuffleGuestName={shuffleGuestName}
        onSignInSuccess={refetchUser}
        onSignOut={exitGame}
        sessionCreatorName={session.creator_name}
      />
      {editAvatarModal}
      {profileImageCapture}
    </>
  );
};
