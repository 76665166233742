import { Box, usePrefersReducedMotion } from "@chakra-ui/react";
import lottie, { AnimationItem } from "lottie-web/build/player/lottie_light";
import React, { useEffect, useRef, useState } from "react";
import { animated, config, useSpring } from "react-spring";

import { IAvatarItem } from "links/lib/types";
import { StudentSessionSoundEffect } from "sessionComponents/types";
import { UnlockedItem } from "sharedComponents/atoms/UnlockedItem";
import { useSessionAudio } from "sharedComponents/contexts/sessionAudio";

import animationData from "./resource/delivery.json";

const animationDataString = JSON.stringify(animationData);

interface ICarePackageDeliveryProps {
  show: boolean;
  items: Array<IAvatarItem>;
  handleComplete?: () => void;
}

const AnimatedBox = animated(Box);

export const CarePackageDelivery: React.FC<ICarePackageDeliveryProps> = ({
  show,
  items,
  handleComplete,
}) => {
  const playerRef = useRef<HTMLDivElement | null>(null);
  const animRef = useRef<AnimationItem | null>(null);
  const { play: playAudio } = useSessionAudio();
  const [showCard, setShowCard] = useState(false);
  const reduceMotion = usePrefersReducedMotion();
  const [currentItemIndex, setCurrentItemIndex] = useState<number>(0);
  const [splashAtRest, setSplashAtRest] = useState(true);

  const splashSpring = useSpring({
    from: {
      opacity: 0,
      scale: reduceMotion ? 1 : 0,
    },
    to: {
      opacity: show ? 1 : 0,
      scale: show ? 1 : 0,
    },
    config: {
      ...config.gentle,
      friction: 20,
    },
    onRest: () => setSplashAtRest(true),
  });

  useEffect(() => {
    setSplashAtRest(false);
  }, [show]);

  useEffect(() => {
    if (!show || !playerRef.current) {
      return;
    }

    animRef.current = lottie.loadAnimation({
      container: playerRef.current,
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: JSON.parse(animationDataString),
    });

    const play = () => {
      const playFullAnim = currentItemIndex === 0;
      animRef.current?.goToAndPlay(playFullAnim ? 0 : 160, true);
      playAudio(
        playFullAnim
          ? StudentSessionSoundEffect.CarePackageDeliveryFull
          : StudentSessionSoundEffect.CarePackageDeliveryShort
      );
      setShowCard(false);
    };

    animRef.current.addEventListener("DOMLoaded", play);

    let hasTriggeredCard = false;
    const enterFrame = ({ currentTime }: { currentTime: number }) => {
      if (!hasTriggeredCard && currentTime > 280) {
        setShowCard(true);
        hasTriggeredCard = true;
      }
    };

    animRef.current.addEventListener("enterFrame", enterFrame);

    return () => {
      animRef.current?.removeEventListener("enterFrame", enterFrame);
      animRef.current?.removeEventListener("DOMLoaded", play);
      animRef.current?.stop();
      animRef.current?.destroy();
      lottie.stop();
      lottie.destroy();
    };
  }, [show, currentItemIndex, playAudio]);

  const onCardAnimComplete = () => {
    if (currentItemIndex < items.length - 1) {
      setCurrentItemIndex((val) => val + 1);
    } else {
      handleComplete?.();
    }
  };

  return (
    <AnimatedBox
      position="fixed"
      top="0"
      left="0"
      right="0"
      bottom="0"
      bg="podium.carePackageBackground"
      style={splashSpring}
      zIndex="20"
      display={show || !splashAtRest ? "block" : "none"}
    >
      <Box
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        ref={playerRef}
      />

      {items.map((i, index) => (
        <Box
          key={i.id}
          position="absolute"
          top="0"
          bottom="0"
          left="0"
          right="0"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <UnlockedItem
            item={i}
            show={show && showCard && currentItemIndex === index}
            handleComplete={onCardAnimComplete}
          />
        </Box>
      ))}
    </AnimatedBox>
  );
};
