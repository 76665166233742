import { AxiosError } from "axios";
import { UseMutationResult, useMutation } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { IPracticeSetSession } from "../../types";

export interface IRestartSessionArgs {
  practiceSetSessionId: string;
}

export interface IUseRestartSessionArgs {
  onSuccess?: (response: { practice_set_session: IPracticeSetSession }) => void;
  onError?: (err: AxiosError) => void;
}

/**
 * Ends current session and creates a new one
 */
export default function useRestartSession({
  onSuccess,
  onError,
}: IUseRestartSessionArgs): UseMutationResult<
  { practice_set_session: IPracticeSetSession },
  unknown,
  IRestartSessionArgs
> {
  const axios = useAxios();

  const restartSession = async (args: IRestartSessionArgs) => {
    const { practiceSetSessionId } = args;

    const response = await axios
      .post(`/v1/practice-sets/sessions/${practiceSetSessionId}/restart`, {})
      .then((r) => r.data as { practice_set_session: IPracticeSetSession });

    return response;
  };

  const mutation = useMutation(restartSession, {
    onSuccess: (response: { practice_set_session: IPracticeSetSession }) => {
      // requerying sessions causes current session to rerender
      // which could flicker round number popups or interstitials again for an odd experience
      // queryClient.invalidateQueries(["sessions"]);
      onSuccess && onSuccess(response);
    },
    onError: (err: AxiosError) => {
      onError && onError(err);
    },
  });

  return mutation;
}
