import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";

import { useSessionRoundState } from "links/lib/contexts/sessionRoundState";
import { useSessionServerTimeDeltas } from "links/lib/contexts/sessionServerTimeDeltas";
import { getCalculatedServerTimeDelta } from "sessionComponents/utils/getCalculatedServerTimeDelta";

export interface ILazyRoundTimeContext {
  getSecondsLeft: () => number;
  getSecondsElapsed: () => number;
  getTotalRoundSeconds: () => number;
  hasRoundTime: boolean;
}

export const LazyRoundTimeContext = createContext<ILazyRoundTimeContext>({
  getSecondsLeft: () => 0,
  getSecondsElapsed: () => 0,
  getTotalRoundSeconds: () => 0,
  hasRoundTime: false,
});

const getTheSecondsLeft = ({
  roundEndsMillis,
  serverTimeDeltaCalcValue,
}: {
  roundEndsMillis: number;
  serverTimeDeltaCalcValue: React.RefObject<number>;
}): number => {
  const deltaValue = serverTimeDeltaCalcValue.current || 0;
  return Math.max(
    Math.floor((roundEndsMillis - Date.now() + deltaValue) / 1000),
    0
  );
};

const getTheSecondsElapsed = ({
  roundStartsMillis,
  serverTimeDeltaCalcValue,
}: {
  roundStartsMillis: number;
  serverTimeDeltaCalcValue: React.RefObject<number>;
}): number => {
  const deltaValue = serverTimeDeltaCalcValue.current || 0;

  const nowCorrected = Date.now() - deltaValue;

  return Math.max(Math.floor((nowCorrected - roundStartsMillis) / 1000), 0);
};

export const LazyRoundTimeProvider: React.FC = ({ children }) => {
  const serverTimeDeltas = useSessionServerTimeDeltas();
  const roundState = useSessionRoundState();

  const serverTimeDeltaCalcValue = useRef(0);
  // For differences in time on client devices, align time left with server time
  // If not mitigated, rounds could appear to incorrectly end early or go on too long
  useEffect(() => {
    const calculatedDelta = getCalculatedServerTimeDelta(serverTimeDeltas);

    // Only use servertime delta if it'll change time by > 2 seconds
    serverTimeDeltaCalcValue.current =
      Math.abs(calculatedDelta) > 2000 ? calculatedDelta : 0;
  }, [serverTimeDeltas]);

  const contextValue = useMemo(() => {
    return {
      getSecondsLeft: () =>
        getTheSecondsLeft({
          roundEndsMillis: new Date(roundState.ends_at).valueOf(),
          serverTimeDeltaCalcValue,
        }),
      getSecondsElapsed: () =>
        getTheSecondsElapsed({
          roundStartsMillis: new Date(roundState.starts_at).valueOf(),
          serverTimeDeltaCalcValue,
        }),
      getTotalRoundSeconds: () =>
        (new Date(roundState.ends_at).valueOf() -
          new Date(roundState.starts_at).valueOf()) /
        1000,
      hasRoundTime: roundState.ends_at !== roundState.starts_at,
    };
  }, [roundState.ends_at, roundState.starts_at]);

  return (
    <LazyRoundTimeContext.Provider value={contextValue}>
      {children}
    </LazyRoundTimeContext.Provider>
  );
};

export const useLazyRoundTime = (): ILazyRoundTimeContext => {
  return useContext(LazyRoundTimeContext);
};
