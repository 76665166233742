import { TFunction } from "react-i18next";
import * as Yup from "yup";

export const folderDetailsValidationSchema = (
  t: TFunction<"admin", undefined>
): unknown =>
  Yup.object({
    name: Yup.string()
      .trim()
      .required(t("folderDetailsModal.nameErrorMessage")),
  });
