import {
  Box,
  Tabs as ChakraTabs,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  TabProps,
} from "@chakra-ui/react";
import React, { useRef } from "react";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";

type tabData = {
  label: string;
  content: React.ReactElement;
};

interface IProps extends TabProps {
  tabData?: tabData[];
  onChangeIndex?: (index: number) => void;
  activeTab: number;
}

export const Tabs: React.FC<IProps> = ({
  tabData,
  activeTab,
  onChangeIndex,
}) => {
  const {
    match: { fontSize, borderWidth, padding },
  } = useBreakpoints();
  const tabListRef = useRef<HTMLDivElement | null>(null);

  const tabListHeight = tabListRef.current?.getBoundingClientRect().height || 0;

  return (
    <ChakraTabs
      onChange={onChangeIndex}
      index={activeTab}
      variant="gameTabs"
      fontSize={pxToRem(fontSize)}
      borderWidth={pxToRem(borderWidth)}
      h="full"
      w="full"
    >
      <TabList ref={tabListRef}>
        {tabData?.map((tab, index) => (
          <Tab key={index}>{tab.label}</Tab>
        ))}
      </TabList>
      <TabPanels h={`calc(100% - ${tabListHeight}px)`}>
        {tabData?.map((tab, index) => (
          <TabPanel key={index} h="full">
            <Box overflowY="auto" h="full" padding={pxToRem(padding)}>
              {tab.content}
            </Box>
          </TabPanel>
        ))}
      </TabPanels>
    </ChakraTabs>
  );
};
