import { useMemo } from "react";

import {
  GameBackgroundColorScheme,
  GameBackgroundStyle,
  ISessionRoundTheme,
  SessionGameType,
} from "../../types";

const bigBoardBasicColors = {
  boxOutlineColor: "golden.02",
  buttonColorScheme: "anjou",
  splashPrimaryColor: "white",
  splashSecondaryColor: "golden.02",
};

const quickPlayBasicColors = {
  boxOutlineColor: "tangerine.02",
  buttonColorScheme: "monaco",
  splashPrimaryColor: "white",
  splashSecondaryColor: "tangerine.02",
};

const generateSessionRoundTheme = (
  roundNumber: number,
  outerGame: SessionGameType
): ISessionRoundTheme => {
  const roundString = roundNumber.toString();
  const lastDigit = roundString.charAt(roundString.length - 1);

  const basicColors =
    outerGame === SessionGameType.QuickPlay
      ? quickPlayBasicColors
      : bigBoardBasicColors;

  switch (lastDigit) {
    case "0":
      return {
        backgroundStyle: GameBackgroundStyle.Arcs,
        backgroundColorScheme: GameBackgroundColorScheme.Amber,
        ...basicColors,
      };
    case "1":
      return {
        backgroundStyle: GameBackgroundStyle.Waves,
        backgroundColorScheme: GameBackgroundColorScheme.Anjou,
        ...basicColors,
      };
    case "2":
      return {
        backgroundStyle: GameBackgroundStyle.Tiles,
        backgroundColorScheme: GameBackgroundColorScheme.Capri,
        ...basicColors,
      };
    case "3":
      return {
        backgroundStyle: GameBackgroundStyle.Scratches,
        backgroundColorScheme: GameBackgroundColorScheme.Golden,
        ...basicColors,
      };
    case "4":
      return {
        backgroundStyle: GameBackgroundStyle.Rain,
        backgroundColorScheme: GameBackgroundColorScheme.Lilac,
        ...basicColors,
      };
    case "5":
      return {
        backgroundStyle: GameBackgroundStyle.Net,
        backgroundColorScheme: GameBackgroundColorScheme.Monaco,
        ...basicColors,
      };
    case "6":
      return {
        backgroundStyle: GameBackgroundStyle.Foam,
        backgroundColorScheme: GameBackgroundColorScheme.Pepto,
        ...basicColors,
      };
    case "7":
      return {
        backgroundStyle: GameBackgroundStyle.Dots,
        backgroundColorScheme: GameBackgroundColorScheme.Smoke,
        ...basicColors,
      };
    case "8":
      return {
        backgroundStyle: GameBackgroundStyle.Bubbles,
        backgroundColorScheme: GameBackgroundColorScheme.Tangerine,
        ...basicColors,
      };
    case "9":
      return {
        backgroundStyle: GameBackgroundStyle.Bricks,
        backgroundColorScheme: GameBackgroundColorScheme.Tomato,
        ...basicColors,
      };
  }

  return {
    backgroundStyle: GameBackgroundStyle.Arcs,
    backgroundColorScheme: GameBackgroundColorScheme.Tomato,
    ...basicColors,
  };
};

const useGenerateSessionRoundTheme = (
  roundNumber: number,
  outerGame: SessionGameType | undefined = SessionGameType.TheBigBoard
): ISessionRoundTheme => {
  return useMemo(
    () => generateSessionRoundTheme(roundNumber, outerGame),
    [roundNumber, outerGame]
  );
};

export default useGenerateSessionRoundTheme;
