import { ColorButtonSize } from "adminComponents/atoms/ColorButton";
import { hoverStyles, pxToRem } from "adminComponents/utils";

export const AdminColorButton = {
  baseStyle: ({
    isSelected,
    color,
    size,
  }: {
    isSelected?: boolean;
    color: string;
    size: ColorButtonSize;
  }): Record<string, unknown> => ({
    container: {
      borderRadius: "100%",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: size == "small" ? pxToRem(40) : pxToRem(72),
      minWidth: size == "small" ? pxToRem(40) : pxToRem(72),
      height: size == "small" ? pxToRem(40) : pxToRem(72),
      padding: size == "small" ? pxToRem(4) : pxToRem(6),
      borderWidth: pxToRem(3),
      borderColor: isSelected ? "utility.link" : "primary.tan",
      transition: "border-color 250ms",
      _focus: {
        boxShadow: "none",
        outline: "none",
      },
      _focusVisible: {
        borderColor: "utility.focus",
        boxShadow: "none",
        outline: "none",
      },
      _hover: hoverStyles({
        borderColor: isSelected ? undefined : "primary.golden-hover",
      }),
    },
    color: {
      height: "100%",
      width: "100%",
      borderRadius: "100%",
      backgroundColor: color,
    },
  }),
};
