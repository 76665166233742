import { AxiosError } from "axios";
import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { IPracticeSet } from "../../types";

export interface IFetchPracticeSetsFromFollowedResponse {
  practice_sets: Array<IPracticeSet>;
}

export interface IUseFetchPracticeSetsFromFollowedArgs {
  enabled?: boolean;
  period_days?: number;
  limit?: number;
}

/**
 * Fetches a list of practice sets that were created within the specified
 * period_days (defaults to 30) by creators that this user follows.
 * @returns
 */
export default function useFetchPracticeSetsFromFollowed(
  args: IUseFetchPracticeSetsFromFollowedArgs
): UseQueryResult<IFetchPracticeSetsFromFollowedResponse, AxiosError> {
  const { enabled = true, period_days = 30, limit = 12 } = args;
  const params = { period_days, limit };

  const axios = useAxios();

  const fetchPracticeSetsFromFollowedResponse = useQuery<
    IFetchPracticeSetsFromFollowedResponse,
    AxiosError
  >(
    ["practiceSetsFromFollowed", params],
    async (): Promise<IFetchPracticeSetsFromFollowedResponse> => {
      const response = await axios
        .get("/v1/practice-sets/followed", {
          params: {
            period_days,
            limit,
          },
        })
        .then((r) => r.data as { practice_sets: Array<IPracticeSet> });

      return {
        practice_sets: response.practice_sets,
      };
    },
    { enabled }
  );

  return fetchPracticeSetsFromFollowedResponse;
}
