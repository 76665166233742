import { Box, Text, usePrefersReducedMotion } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { animated, config, useSpring } from "react-spring";

import { pxToRem } from "adminComponents/utils";
import { ICardProps } from "sessionComponents/atoms/Card";
import { Tooltip } from "sessionComponents/atoms/Tooltip";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";

import FlameGif from "./resource/flame.gif";
import Flame from "./resource/flame.svg";

export interface IQuickPlayStreakIconProps extends ICardProps {
  streakCount: number;
  animationDelayMs?: number;
}

const AnimatedBox = animated(Box);

export const QuickPlayStreakIcon: React.FC<IQuickPlayStreakIconProps> = ({
  streakCount,
  animationDelayMs = 4500, // This long delay is to wait out the StudentRoundReviewInterstitial
  ...rest
}) => {
  const { t } = useTranslation("session", {
    keyPrefix: "quickPlay.streakIcon",
    useSuspense: false,
  });
  const { match: currentBreakpoints, fontSizeHalf } = useBreakpoints();
  const prefersReducedMotion = usePrefersReducedMotion();

  const flameSpring = useSpring({
    from: {
      opacity: 0,
      scale: prefersReducedMotion || streakCount === 0 ? 1 : 0,
    },
    to: {
      opacity: streakCount === 0 ? 0.5 : 1,
      scale: 1,
    },
    delay: animationDelayMs,
    config: prefersReducedMotion ? config.default : config.wobbly,
  });

  const tooltipContent = (
    <Box
      w={pxToRem(currentBreakpoints.padding * 6)}
      padding={pxToRem(currentBreakpoints.padding / 2)}
      textAlign="start"
    >
      <Text
        color="white"
        fontStyle="normal"
        textStyle="gameText"
        fontSize={pxToRem(fontSizeHalf)}
      >
        <b>{t("tooltipCurrentStreak", { streakCount })}</b>
        <br />
        {t("tooltipRemainingStreak", { count: 3 - streakCount })}
      </Text>
    </Box>
  );

  return (
    <Tooltip tooltipContent={tooltipContent} placement="bottom">
      <Box
        boxSize={pxToRem(currentBreakpoints.buttonHeight)}
        position="relative"
        {...rest}
      >
        <AnimatedBox
          w="90%"
          h="90%"
          filter={streakCount === 0 ? "grayscale(100%)" : undefined}
          opacity={streakCount === 0 ? "0.5" : undefined}
          bgImage={streakCount === 0 ? Flame : FlameGif}
          bgPosition="center top"
          bgSize="contain"
          bgRepeat="no-repeat"
          style={{
            opacity: flameSpring.opacity,
            transform: flameSpring.scale.to((s) => `scale(${s})`),
          }}
        />
        <Text
          bgColor="primary.white"
          borderRadius="full"
          boxShadow={`0 ${pxToRem(4)} ${pxToRem(4)} 0 #0000001A`}
          textStyle="gameDisplay"
          fontSize={pxToRem(fontSizeHalf)}
          px={pxToRem(currentBreakpoints.padding / 3)}
          py={pxToRem(currentBreakpoints.padding / 5)}
          position="absolute"
          right="0"
          bottom="0"
        >
          {streakCount}/3
        </Text>
      </Box>
    </Tooltip>
  );
};
