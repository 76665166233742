import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import { FocusableElement } from "@chakra-ui/utils";
import React, {
  ChangeEvent,
  FormEvent,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { useErrorToast } from "adminComponents/utils/toast";
import { useFetchGradeLevels } from "links/lib/features/gradeLevels";
import { useSearchStandardDataOnce } from "links/lib/features/search";
import { useFetchStandardCollectionsOnce } from "links/lib/features/standardCollections";
import { useFetchSubjects } from "links/lib/features/subjects";
import { getRegionsForCountry } from "links/lib/geo";
import { IStandard } from "links/lib/types";

import Footer from "./components/Footer";
import SearchForm from "./components/SearchForm";
import SelectedStandardsDisplay from "./components/SelectedStandardsDisplay";
import StandardsList from "./components/StandardsList";

export interface IStandardsModalProps {
  isOpen: boolean;
  onClose: () => void;
  // called when selected standards has been changed
  onChange: (standards: Array<IStandard>) => void;
  // where to create focus after modal close
  finalFocusRef?: RefObject<FocusableElement>;
  // the standards that are currently selected
  selectedStandards: Array<IStandard>;
  // the country to base the standards listing to
  country: string;
  // the initial values for the search on modal open
  initialSearchValues: {
    // region code
    region?: string;
    grade_level_id?: string;
    subject_id?: string;
  };
  onRegionChange?: (region: string) => void;
  onGradeLevelChange?: (gradeLevel: string) => void;
  onSubjectChange?: (subject: string) => void;
}

export interface IStandardsModalSearchState {
  // region code
  region: string;
  // search query text
  term: string;
  grade_level_ids?: Array<string>;
  subject_ids?: Array<string>;
  standard_collection_id?: string;
}

export interface IStandardsModalState {
  search: IStandardsModalSearchState;
}

export const StandardsModal: React.FC<IStandardsModalProps> = ({
  isOpen,
  onClose,
  country,
  finalFocusRef,
  initialSearchValues,
  selectedStandards,
  onChange,
  onRegionChange: upstreamOnRegionChange,
  onSubjectChange: upstreamOnSubjectChange,
  onGradeLevelChange: upstreamOnGradeLevelChange,
}) => {
  const pageLimit = 50;

  const { t } = useTranslation("translation", {
    keyPrefix: "teacherPracticeLibrary.standardsModal",
  });

  const content = {
    heading: t("heading"),
    searchForm: {
      searchQueryLabel: t("searchForm.searchQueryLabel"),
      searchQueryPlaceholder: t("searchForm.searchQueryPlaceholder"),
      searchRegionLabel: t("searchForm.searchRegionLabel"),
      searchRegionAnyOption: t("searchForm.searchRegionAnyOption"),
      searchGradeLevelLabel: t("searchForm.searchGradeLevelLabel"),
      searchGradeLevelAnyOption: t("searchForm.searchGradeLevelAnyOption"),
      searchSubjectLabel: t("searchForm.searchSubjectLabel"),
      searchSubjectAnyOption: t("searchForm.searchSubjectAnyOption"),
      searchStandardCollectionLabel: t(
        "searchForm.searchStandardCollectionLabel"
      ),
      searchStandardCollectionAnyOption: t(
        "searchForm.searchStandardCollectionAnyOption"
      ),
    },
    errors: {
      fetchStandards: t("errors.fetchStandards"),
      fetchGradeLevels: t("errors.fetchGradeLevels"),
      fetchSubjects: t("errors.fetchSubjects"),
    },
    standardsList: {
      noResultsMessage: t("standardsList.noResultsMessage"),
      requestStandardLink: t("standardsList.requestStandardLink"),
    },
    selectedStandardsDisplay: {
      placeholder: t("selectedStandardsDisplay.placeholder"),
    },
    footer: {
      finishButton: t("footer.finishButton"),
    },
  };

  const regions = getRegionsForCountry(country);

  const [state, setState] = useState<IStandardsModalState>({
    search: {
      region: initialSearchValues.region || "",
      term: "",
      grade_level_ids: initialSearchValues.grade_level_id
        ? [initialSearchValues.grade_level_id]
        : undefined,
      subject_ids: initialSearchValues.subject_id
        ? [initialSearchValues.subject_id]
        : undefined,
    },
  });
  const initialFocusRef = useRef(null);

  const fetchStandardCollections = useFetchStandardCollectionsOnce();
  const fetchStandards = useSearchStandardDataOnce();
  const fetchGradeLevels = useFetchGradeLevels({
    country,
    limit: 100,
  });
  const fetchSubjects = useFetchSubjects({
    country,
    limit: 100,
  });

  // on modal open, reset initial search values and then
  // run standard list fetch
  useEffect(
    () => {
      if (isOpen) {
        const search = {
          region: initialSearchValues.region || "",
          term: "",
          grade_level_ids: initialSearchValues.grade_level_id
            ? [initialSearchValues.grade_level_id]
            : undefined,
          subject_ids: initialSearchValues.subject_id
            ? [initialSearchValues.subject_id]
            : undefined,
        };

        setState({
          ...state,
          search,
        });

        executeSearch(search);
      }
    },
    // eslint-disable-next-line
    [isOpen]
  );

  // if grade levels fail to load, then reset search grade level to any
  useEffect(
    () => {
      if (fetchGradeLevels.error) {
        setState({
          ...state,
          search: {
            ...state.search,
            grade_level_ids: undefined,
          },
        });
      }
    },
    // eslint-disable-next-line
    [fetchGradeLevels.error]
  );

  // if subjects fail to load, then reset search subject to any
  useEffect(
    () => {
      if (fetchSubjects.error) {
        setState({
          ...state,
          search: {
            ...state.search,
            subject_ids: undefined,
          },
        });
      }
    },
    // eslint-disable-next-line
    [fetchSubjects.error]
  );

  useErrorToast(fetchStandards.error, content.errors.fetchStandards);
  useErrorToast(fetchGradeLevels.error, content.errors.fetchSubjects);
  useErrorToast(fetchSubjects.error, content.errors.fetchGradeLevels);

  const onSearchSubmit = (
    e: FormEvent<HTMLFormElement> & FormEvent<HTMLDivElement>
  ) => {
    e.preventDefault();
    e.stopPropagation();

    executeSearch(state.search);
  };

  const onRegionChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const region = e.target.value;

    const search = {
      ...state.search,
      standard_collection_id: "0",
      region,
    };

    setState({
      ...state,
      search,
    });

    executeSearch(search);

    if (upstreamOnRegionChange) upstreamOnRegionChange(region);
  };

  const onQueryChange = (e: ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value || "";

    setState({
      ...state,
      search: {
        ...state.search,
        term,
      },
    });
  };

  const onGradeLevelChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const grade_level_id = e.target.value;

    const search = {
      ...state.search,
      grade_level_ids: grade_level_id ? [grade_level_id] : undefined,
    };

    setState({
      ...state,
      search,
    });

    executeSearch(search);

    if (upstreamOnGradeLevelChange) upstreamOnGradeLevelChange(grade_level_id);
  };

  const onSubjectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const subject_id = e.target.value;

    const search = {
      ...state.search,
      subject_ids: subject_id ? [subject_id] : undefined,
    };

    setState({
      ...state,
      search,
    });

    executeSearch(search);

    if (upstreamOnSubjectChange) upstreamOnSubjectChange(subject_id);
  };

  const onStandardCollectionChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const standard_collection_id = e.target.value || "0";

    const search = {
      ...state.search,
      standard_collection_id,
    };

    setState({
      ...state,
      search,
    });

    executeSearch(search);
  };

  const executeSearch = (search: IStandardsModalSearchState) => {
    fetchStandards.execute({
      ...search,
      country,
      page: 1,
      per_page: pageLimit,
    });

    if (search.region) {
      fetchStandardCollections.execute({
        country: country,
        region: search.region,
        grade_level_id: search.grade_level_ids?.[0],
        subject_id: search.subject_ids?.[0],
        offset: 0,
        limit: pageLimit,
      });
    }
  };

  const onStandardSelect = (standard: IStandard, isSelected: boolean) => {
    // clean current standards list to ensure current standard is not present
    const newStandards = selectedStandards.filter((s) => s.id !== standard.id);

    // if selected, then add to new selected standards
    if (isSelected) {
      newStandards.push(standard);
    }

    onChange(newStandards);
  };

  return (
    <Modal
      size="xl"
      isOpen={isOpen}
      onClose={onClose}
      finalFocusRef={finalFocusRef}
      initialFocusRef={initialFocusRef}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{content.heading}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack w="full" spacing={6}>
            <SearchForm
              initialFocusRef={initialFocusRef}
              regions={regions}
              gradeLevels={fetchGradeLevels.data?.gradeLevels || []}
              subjects={fetchSubjects.data?.subjects || []}
              standardCollections={
                (state.search.region &&
                  fetchStandardCollections.data?.standard_collections) ||
                []
              }
              isGradeLevelsLoading={fetchGradeLevels.isLoading}
              isSubjectsLoading={fetchSubjects.isLoading}
              isStandardCollectionsLoading={fetchStandardCollections.isLoading}
              values={{
                region: state.search.region,
                query: state.search.term,
                grade_level_id: state.search.grade_level_ids?.[0] || "",
                subject_id: state.search.subject_ids?.[0] || "",
                standard_collection_id: state.search.standard_collection_id,
              }}
              onGradeLevelChange={onGradeLevelChange}
              onQueryChange={onQueryChange}
              onSubjectChange={onSubjectChange}
              onSearchSubmit={onSearchSubmit}
              onRegionChange={onRegionChange}
              onStandardCollectionChange={onStandardCollectionChange}
              content={content.searchForm}
            />
            <StandardsList
              onStandardSelect={onStandardSelect}
              isLoading={fetchStandards.isLoading}
              standards={fetchStandards.data?.standards || []}
              selectedStandards={selectedStandards}
              content={content.standardsList}
            />
            <SelectedStandardsDisplay
              onStandardSelected={onStandardSelect}
              selectedStandards={selectedStandards}
              content={content.selectedStandardsDisplay}
            />
            <Footer content={content.footer} onFinish={onClose} />
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
