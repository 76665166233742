import {
  Box,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Icon } from "adminComponents/atoms/Icon";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils";
import { IPracticeSetItem } from "links/lib/types";

export interface IAddToSetButtonProps {
  practiceSetItem: IPracticeSetItem;
  handleAddToSet: (item: IPracticeSetItem) => void;
  allowCopy?: boolean;
}

export const AddToSetButton: React.FC<IAddToSetButtonProps> = ({
  allowCopy,
  handleAddToSet,
  practiceSetItem,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  const buttonContent = (
    <Button
      variant="adminButtonOutlined"
      size="sm"
      onClick={() => handleAddToSet(practiceSetItem)}
      disabled={!allowCopy}
      leftIcon={<Icon icon="add_outlined" w={pxToRem(14)} h={pxToRem(14)} />}
    >
      {t("common.addToSet")}
    </Button>
  );

  return allowCopy ? (
    buttonContent
  ) : (
    <Popover
      computePositionOnMount
      arrowPadding={22}
      arrowShadowColor="transparent"
      placement="auto"
      trigger="hover"
      variant="adminSolid"
      aria-live="polite"
    >
      <PopoverTrigger>
        <Box>{buttonContent}</Box>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverBody>
          <Text as="p" variant="adminMeta" color="primary.white">
            {t("practiceSetItemAccordian.addToSetNotAllowed")}
          </Text>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
