import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { IUserTrialExtension } from "../../types";

export interface IMutateUserTrialExtensionParams {
  user_trial_extension: IUserTrialExtension;
}

export interface IMutateUserTrialExtensionResponse {
  user_trial_extension: IUserTrialExtension;
}

export function useMutateUserTrialExtension(): UseMutationResult<
  IMutateUserTrialExtensionResponse,
  unknown,
  IMutateUserTrialExtensionParams
> {
  const axios = useAxios();
  const queryClient = useQueryClient();

  const mutateUserTrialExtension = async (
    args: IMutateUserTrialExtensionParams
  ) => {
    const { user_trial_extension } = args;

    let url = `/v1/subscriptions/users/extensions`;
    if (user_trial_extension.id) {
      url += `/${user_trial_extension.id}`;
    }

    const response = await axios({
      method: user_trial_extension.id ? "put" : "post",
      url,
      data: {
        user_trial_extension,
      },
    }).then((r) => r.data as IMutateUserTrialExtensionResponse);

    queryClient.invalidateQueries(["user-trial-extensions"]);

    return response;
  };

  const mutation = useMutation(mutateUserTrialExtension);

  return mutation;
}
