import { Box, VStack } from "@chakra-ui/react";
import update from "immutability-helper";
import React, { useCallback, useEffect, useState } from "react";
import {
  DragDropContext,
  Draggable,
  DraggingStyle,
  DropResult,
  Droppable,
  NotDraggingStyle,
} from "react-beautiful-dnd";

import {
  PracticeSetQuestionCard,
  PracticeSetQuestionCardType,
} from "adminComponents/molecules/PracticeSetQuestionCard";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { IPracticeSetItem, IUser, UserRole } from "links/lib/types";

export interface IItemListProps {
  authUser?: IUser;
  items: Array<IPracticeSetItem>;
  setIsCertified?: boolean;
  setIsPremium?: boolean;
  addToSetProhibited?: boolean;
  isPublic?: boolean;
  reorder?: boolean;
  showPremiumMarker?: boolean;
  handleOrderChange: (data: Array<IPracticeSetItem>) => void;
  handleEditQuestion: (item: IPracticeSetItem) => void;
  handleDuplicateQuestion: (item: IPracticeSetItem) => void;
  handleDeleteQuestion: (item: IPracticeSetItem) => void;
  handlePreviewQuestion: (item: IPracticeSetItem) => void;
  handleAddQuestionToSet?: (item: IPracticeSetItem) => void;
}

interface IItemCardDraggableProps {
  isDraggable: boolean;
  item: IPracticeSetItem;
  index: number;
  moveCard: (result: DropResult) => void;
}
const getItemStyle = (
  draggableStyle: DraggingStyle | NotDraggingStyle | undefined
) => ({
  userSelect: "none",
  ...draggableStyle,
});

const getListStyle = () => ({
  width: "100%",
});

const ItemCardDraggable: React.FC<IItemCardDraggableProps> = ({
  isDraggable,
  item,
  index,
}) => {
  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided, snapshotDraggable) => (
        <Box
          ref={provided.innerRef}
          sx={{
            marginBottom: { base: pxToRem(16), md: pxToRem(32) },
            outline: "none",
            _focusVisible: {
              "> div": {
                color: "utility.focus",
              },
            },
          }}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={
            getItemStyle(provided.draggableProps.style) as React.CSSProperties
          }
        >
          <PracticeSetQuestionCard
            practiceSetItem={item}
            handleEdit={() => null}
            handleDuplicate={() => null}
            handleDelete={() => null}
            draggable={isDraggable}
            isSelected={snapshotDraggable.isDragging}
            questionNumber={index + 1}
            cardType={PracticeSetQuestionCardType.Answers}
          />
        </Box>
      )}
    </Draggable>
  );
};

const PracticeSetQuestionList: React.FC<IItemListProps> = ({
  authUser,
  items,
  isPublic,
  setIsCertified,
  setIsPremium,
  addToSetProhibited,
  showPremiumMarker = true,
  reorder: isDraggable,
  handleOrderChange,
  handleDeleteQuestion,
  handleDuplicateQuestion,
  handleEditQuestion,
  handlePreviewQuestion,
  handleAddQuestionToSet,
}) => {
  const isContentSpecialist = authUser?.role === UserRole.ContentSpecialist;
  const [cards, setCards] = useState(items);

  const noOp = useCallback(() => {
    return;
  }, []);

  useEffect(() => {
    setCards(items);
  }, [items]);

  const moveCard = useCallback(
    (result: DropResult) => {
      if (!isDraggable || !cards) {
        return;
      }

      const { source, destination } = result;
      if (!destination) {
        return;
      }

      const dragIndex = source.index;
      const hoverIndex = destination.index;

      const dragCard = cards[dragIndex];
      const updatedCards = update(cards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard],
        ],
      });
      const reorderedCards =
        updatedCards &&
        updatedCards.map((card, index) => {
          return {
            ...card,
            order: index + 1,
          };
        });

      setCards(reorderedCards);
      handleOrderChange(reorderedCards);
    },
    [cards, handleOrderChange, isDraggable]
  );

  const renderItem = (item: IPracticeSetItem, index: number) => {
    const itemKey = `item-${item.guid}`;

    const editNotAllowedCertified = setIsCertified || item.is_certified;
    const duplicateNotAllowedCertified = setIsCertified || item.is_certified;

    const editNotAllowedPremium = setIsPremium || item.is_premium;
    const duplicateNotAllowedPremium = setIsPremium || item.is_premium;

    let editNotAllowed = editNotAllowedCertified || editNotAllowedPremium;
    let duplicateNotAllowed =
      duplicateNotAllowedCertified || duplicateNotAllowedPremium;
    let deleteNotAllowed = setIsCertified || setIsPremium;

    if (isContentSpecialist) {
      editNotAllowed = false;
      duplicateNotAllowed = false;
      deleteNotAllowed = false;
    }

    if (isDraggable && cards) {
      return (
        <ItemCardDraggable
          key={itemKey}
          item={item}
          index={index}
          isDraggable={isDraggable}
          moveCard={moveCard}
        />
      );
    } else {
      return (
        <PracticeSetQuestionCard
          key={itemKey}
          authUser={authUser}
          isPublic={isPublic}
          isInPremiumSet={setIsPremium}
          practiceSetItem={item}
          handleEdit={editNotAllowed ? noOp : handleEditQuestion}
          allowEdit={!editNotAllowed}
          handleDuplicate={duplicateNotAllowed ? noOp : handleDuplicateQuestion}
          allowDuplicate={!duplicateNotAllowed}
          handleDelete={deleteNotAllowed ? noOp : handleDeleteQuestion}
          allowRemove={!deleteNotAllowed}
          handlePreview={handlePreviewQuestion}
          draggable={isDraggable}
          questionNumber={index + 1}
          cardType={PracticeSetQuestionCardType.Answers}
          handleAddToSet={
            addToSetProhibited ? undefined : handleAddQuestionToSet
          }
          showPremiumMarker={showPremiumMarker}
        />
      );
    }
  };

  if (isDraggable) {
    return (
      <VStack w="full" spacing={pxToRem(16)}>
        <DragDropContext onDragEnd={moveCard}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle()}
              >
                {cards && cards.map(renderItem)}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </VStack>
    );
  }

  return (
    <VStack w="full" spacing={{ base: pxToRem(16), md: pxToRem(32) }}>
      {cards && cards.map(renderItem)}
    </VStack>
  );
};

export default PracticeSetQuestionList;
