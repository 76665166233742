import { Flex, SimpleGrid } from "@chakra-ui/react";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Heading } from "adminComponents/atoms/Heading";
import { Icon } from "adminComponents/atoms/Icon";
import { Text } from "adminComponents/atoms/Text";
import { INavigationHeaderProps } from "adminComponents/molecules/NavigationHeader";
import { TeacherCard } from "adminComponents/molecules/TeacherCard";
import { pxToRem } from "adminComponents/utils";
import { IUser } from "links/lib/types";
import { HashScrollOnMount } from "sharedComponents/atoms/HashScrollOnMount";

export interface IProps {
  hideText?: boolean;
  authUser: IUser;
  navigationData: INavigationHeaderProps;
  mostCopiedCreators: Array<IUser>;
}

export const RecommendedCreators: React.FC<IProps> = ({
  hideText = false,
  authUser,
  navigationData,
  mostCopiedCreators,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const [creatorsToShow, setCreatorsToShow] = useState(4);
  const shownCreators = useMemo(() => {
    return mostCopiedCreators.slice(0, creatorsToShow);
  }, [creatorsToShow, mostCopiedCreators]);

  const handleViewMoreCreators = useCallback(() => {
    setCreatorsToShow(shownCreators.length + 4);
  }, [shownCreators.length]);

  return (
    <Flex flexDir="column" gap={pxToRem(20)}>
      {!hideText && (
        <>
          <Heading variant="adminH4" as="h1">
            {t("teacherHomepageImproved.recommendedCreators.heading")}
          </Heading>
          <Text>
            {t("teacherHomepageImproved.recommendedCreators.description")}
          </Text>
        </>
      )}
      <HashScrollOnMount id="recommended-creators">
        <SimpleGrid columns={[1, null, 4]} gap={pxToRem(20)}>
          {shownCreators.map((mc, i) => (
            <TeacherCard
              key={`${mc.id}-${i}`}
              authUser={authUser}
              user={mc}
              gradeLevels={navigationData.gradeLevels}
              subjects={navigationData.subjects}
            />
          ))}
        </SimpleGrid>
      </HashScrollOnMount>
      {mostCopiedCreators.length > shownCreators.length && (
        <Button
          alignSelf="center"
          onClick={handleViewMoreCreators}
          rightIcon={
            <Icon
              color="currentColor"
              height={pxToRem(7)}
              icon="button_down_arrow"
              ml={pxToRem(5)}
              width={pxToRem(12)}
            />
          }
          variant="adminTextButtonLarge"
        >
          {t("common.viewMore")}
        </Button>
      )}
    </Flex>
  );
};
