import { AxiosInstance } from "axios";
import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { IPracticeSetSessionResponse } from "../../types";

export interface IUseFetchClassroomRecentPracticeSetResponsesProps {
  classroom_id: string;
  practice_set_id: string;
  assignment_id?: string;
  practice_set_session_id?: string;
  user_id?: string;
  // Most uses of this hook expect the returned responses to all be
  // from the most recently completed session. However, there are select
  // views (the student and set specific report) where we wish to see the
  // most recent response for the item, regardless of the session
  include_all_sessions?: boolean;
}

export interface IUserPracticeSetItemResponses {
  user_id: string;
  responses: Array<IPracticeSetSessionResponse>;
}

export interface IUseFetchClassroomRecentPracticeSetResponsesResult {
  user_responses: Array<IUserPracticeSetItemResponses>;
}

const fetchClassroomRecentPracticeSetResponsesQuery = (
  axios: AxiosInstance,
  params: IUseFetchClassroomRecentPracticeSetResponsesProps
): (() => Promise<IUseFetchClassroomRecentPracticeSetResponsesResult>) => {
  const {
    classroom_id,
    practice_set_id,
    assignment_id,
    practice_set_session_id,
    user_id,
    include_all_sessions = false,
  } = params;

  return async (): Promise<IUseFetchClassroomRecentPracticeSetResponsesResult> => {
    return await axios
      .get(
        `/v1/groups/${classroom_id}/practice_sets/${practice_set_id}/recent-responses`,
        {
          params: {
            assignment_id,
            practice_set_session_id,
            user_id,
            include_all_sessions,
          },
        }
      )
      .then(
        (r) => r.data as IUseFetchClassroomRecentPracticeSetResponsesResult
      );
  };
};

export default function useFetchClassroomRecentPracticeSetResponses(
  params: IUseFetchClassroomRecentPracticeSetResponsesProps
): UseQueryResult<IUseFetchClassroomRecentPracticeSetResponsesResult, unknown> {
  const axios = useAxios();

  const query = useQuery<IUseFetchClassroomRecentPracticeSetResponsesResult>(
    ["practiceSetAccuracies", params],
    fetchClassroomRecentPracticeSetResponsesQuery(axios, params)
  );

  return query;
}
