import {
  Box,
  Button,
  FormLabel,
  HStack,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import React, { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Input } from "adminComponents/atoms/Input";
import { Text } from "adminComponents/atoms/Text";

export interface IProps {
  activeValue?: number;
  children?: ReactNode;
  customButtonLabel?: string;
  handleBlur?: (e: React.FocusEvent<unknown, Element>) => void;
  handleChange: (n: number | null) => void;
  values: number[];
  valueSuffixLabel: string;
  id: string;
  minCustomInputValue?: number;
  maxCustomInputValue?: number;
  maxCustomInputLength?: number;
  customInputWholeNumberOnly?: boolean;
}

export const SliderInput: React.FC<IProps> = ({
  activeValue,
  children,
  customButtonLabel,
  handleBlur,
  handleChange,
  values = [],
  valueSuffixLabel = "%",
  id,
  minCustomInputValue,
  maxCustomInputValue,
  maxCustomInputLength,
  customInputWholeNumberOnly,
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });
  const [showCustomInput, setShowCustomInput] = useState(false);
  const styles = useMultiStyleConfig("AdminSliderInput", {});

  // Show the custom input if there is an active value and it
  // is not within the value options set
  useEffect(() => {
    if (showCustomInput) return;
    if (!activeValue) return;
    setShowCustomInput(values.indexOf(activeValue) < 0);
  }, [showCustomInput, activeValue, values]);

  const handleButtonClick = (n: number) => {
    handleChange(n);
    setShowCustomInput(false);
  };

  const handleCustomButtonClick = () => {
    setShowCustomInput(true);
  };

  const handleInputBlur = (ev: React.FocusEvent<HTMLElement, Element>) => {
    handleBlur?.(ev);
  };

  const handleInput = (ev: React.KeyboardEvent) => {
    if (!customInputWholeNumberOnly) return;

    const code = ev.code ? ev.code : ev.key;
    switch (code) {
      case "Minus":
      case "Equal": // Plus
      case "Period": {
        ev.preventDefault();
        ev.stopPropagation();
        return;
      }
    }
  };

  const handleInputChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const targetValue = ev.target.value;

    const value = targetValue
      ? maxCustomInputLength
        ? Number(targetValue.slice(0, maxCustomInputLength)) // Don't let input exceed `maxCustomInputLength` characters
        : Number(targetValue)
      : null;

    handleChange(value);
  };

  return (
    <Box>
      <HStack sx={styles.values} spacing="0">
        <HStack sx={styles.inputValues}>
          {values.map((v) => (
            <Button
              key={v}
              sx={styles.button}
              isActive={!showCustomInput && v === activeValue}
              onClick={() => handleButtonClick(v)}
            >
              <Text
                variant="adminP2"
                color={
                  !showCustomInput && v === activeValue
                    ? "primary.warm-black"
                    : "primary.dark-gray"
                }
              >
                {v}
                {valueSuffixLabel}
              </Text>
            </Button>
          ))}
          <Button
            isActive={showCustomInput}
            sx={styles.button}
            onClick={handleCustomButtonClick}
          >
            <Text
              variant="adminP2"
              color={
                showCustomInput ? "primary.warm-black" : "primary.dark-gray"
              }
            >
              {customButtonLabel || t("sliderInput.custom")}
            </Text>
          </Button>
        </HStack>
        {showCustomInput && (
          <Box sx={styles.inputGroup}>
            <Input
              id={id}
              onBlur={handleInputBlur}
              onChange={handleInputChange}
              onKeyDown={handleInput}
              type="number"
              value={activeValue || ""}
              min={minCustomInputValue}
              max={maxCustomInputValue}
              maxLength={maxCustomInputLength}
            />
            <FormLabel>{valueSuffixLabel}</FormLabel>
          </Box>
        )}
      </HStack>
      {children}
    </Box>
  );
};
