import { SimpleGrid } from "@chakra-ui/react";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { usePrevious } from "react-use";
import * as Yup from "yup";

import {
  ConfirmModal,
  IConfirmModalProps,
} from "adminComponents/molecules/ConfirmModal";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { ICollection } from "links/lib/types";

import { FolderDetailsFormControls } from "./components/FolderDetailsFormControls";

interface IProps {
  folder?: ICollection;
  isLoading?: IConfirmModalProps["isLoading"];
  isOpen: IConfirmModalProps["isOpen"];
  handleClose: () => void;
  handleSave: (folder: ICollection) => void;
}

export const BASE_FOLDER: ICollection = {
  created_at: "",
  created_by: "",
  deleted_at: "",
  id: "",
  name: "",
  description: "",
  practice_set_count: 0,
  updated_at: "",
};

type IForm = ICollection & { init_at?: number };

export const FolderDetailsModal: React.FC<IProps> = ({
  folder,
  handleClose,
  handleSave,
  ...props
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  const [initialValues, setInitialValues] = useState<IForm>(
    folder ?? { ...BASE_FOLDER }
  );

  const prevIsOpen = usePrevious(props.isOpen);
  useEffect(() => {
    if (!prevIsOpen && props.isOpen) {
      const f = folder ?? { ...BASE_FOLDER };

      setInitialValues({
        ...f,
        // force form reload on open
        init_at: Date.now(),
      });
    }
  }, [props.isOpen, prevIsOpen, folder]);

  const formik = useFormik<IForm>({
    validateOnMount: true,
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: handleSave,
    validationSchema: Yup.object({
      name: Yup.string()
        .trim()
        .required(t("folderDetailsModal.nameErrorMessage")),
    }),
  });

  const handleConfirmModalClose = (confirm?: boolean) => {
    return confirm ? formik.submitForm() : handleClose();
  };

  const title = t(
    !initialValues.id
      ? "folderDetailsModal.createTitle"
      : "folderDetailsModal.editTitle"
  );

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    formik.handleSubmit();
  };

  return (
    <ConfirmModal
      {...props}
      buttonCancelLabel={t("folderDetailsModal.cancelButton")}
      buttonOKLabel={t("folderDetailsModal.saveButton")}
      handleClose={handleConfirmModalClose}
      isDisabled={!formik.isValid}
      size="2xl"
      title={title}
      variant="adminModalCompactMobile"
    >
      <form onSubmit={handleSubmit}>
        <SimpleGrid gap={[pxToRem(24), null, pxToRem(32)]}>
          <FolderDetailsFormControls
            formik={formik}
            isLoading={props.isLoading}
          />
        </SimpleGrid>
      </form>
    </ConfirmModal>
  );
};
