import { Flex } from "@chakra-ui/react";
import React from "react";

import { Divider } from "adminComponents/atoms/Divider";
import { Text } from "adminComponents/atoms/Text";
import { ClassroomAnswerResults } from "adminComponents/molecules/ClassroomAnswerResults";
import { IHandleViewStudentResponses } from "adminComponents/molecules/ClassroomAnswerResultsMetric";
import { pxToRem } from "adminComponents/utils/pxToRem";
import {
  IDiagramLabel,
  IPracticeSetItem,
  IPracticeSetSessionItemReport,
} from "links/lib/types";
import { RichTextRenderer } from "sharedComponents/atoms/RichTextRenderer";

export interface IProps {
  practiceSetItem: IPracticeSetItem;
  diagramLabels: IDiagramLabel[];
  report: IPracticeSetSessionItemReport;
  handleViewStudentResponses: IHandleViewStudentResponses;
}

export const ClassroomAnswerResultsList: React.FC<IProps> = ({
  practiceSetItem,
  diagramLabels,
  report,
  handleViewStudentResponses,
}) => {
  return (
    <Flex direction="column" gap={{ base: pxToRem(24), md: pxToRem(28) }}>
      {diagramLabels.map((label, index) => {
        const responsesForLabel = report.responses.filter(
          (r) => r.zone_id === label.id
        );
        const labelResults = {
          ...report,
          responses: responsesForLabel,
          total_responses: responsesForLabel.reduce(
            (prev, curr) => prev + curr.count,
            0
          ),
          correct_responses: responsesForLabel
            .filter((r) => r.is_correct)
            .reduce((prev, curr) => prev + curr.count, 0),
        };
        return (
          <>
            <Flex
              direction="column"
              key={label.id}
              gap={{ base: pxToRem(4), md: pxToRem(8) }}
            >
              <Flex direction="row" gap={pxToRem(4)}>
                <Text variant="adminP1Bold">{index + 1}.</Text>
                <Text as="div" variant="adminP2" marginTop={pxToRem(3)}>
                  <RichTextRenderer content={label.label} />
                </Text>
              </Flex>
              <ClassroomAnswerResults
                results={labelResults}
                key={index}
                practiceSetItem={practiceSetItem}
                handleViewStudentResponses={() =>
                  handleViewStudentResponses(
                    practiceSetItem,
                    responsesForLabel,
                    label.id
                  )
                }
              />
            </Flex>
            {report.responses.length > index + 1 && (
              <Divider color="primary.light-gray" />
            )}
          </>
        );
      })}
    </Flex>
  );
};
