import { IUseFetchClassroomRecentPracticeSetResponsesResult } from "links/lib/features/classrooms/useFetchClassroomRecentPracticeSetResponses";
import {
  IPracticeSetItem,
  IPracticeSetSessionResponse,
  ISessionItemResponse,
  QuestionType,
} from "links/lib/types";

interface IPracticeSetItemResponseStats {
  responses: ISessionItemResponse[];
  correctResponses: number;
  totalAnswers: number;
}

export const getPracticeSetItemResponseStatsMap = (
  classroomPracticeSetResponses: IUseFetchClassroomRecentPracticeSetResponsesResult
): Map<string, IPracticeSetItemResponseStats> => {
  const practiceSetAnswers = new Map<string, IPracticeSetItemResponseStats>();

  classroomPracticeSetResponses?.user_responses.forEach((userResponse) => {
    userResponse.responses.forEach((response: IPracticeSetSessionResponse) => {
      const itemResponseStats = practiceSetAnswers.get(
        response.practice_set_item_id
      );
      const mappedResponse = {
        id: response.id,
        choice_id: response.choice_id,
        zone_id: response.zone_id,
        count: 1,
        is_correct: response.is_correct,
        response_text: response.response_text,
        choice_text: response.choice_text,
        choice_image_url: response.choice_image_url,
        choice_image_alt_text: response.choice_image_alt_text,
        drawing_image_url: response.drawing_image_url,
      };

      const correctCount = response.is_correct ? 1 : 0;

      if (itemResponseStats) {
        itemResponseStats.responses.push(mappedResponse);
        itemResponseStats.correctResponses += correctCount;
        itemResponseStats.totalAnswers += 1;
        practiceSetAnswers.set(
          response.practice_set_item_id,
          itemResponseStats
        );
      } else {
        practiceSetAnswers.set(response.practice_set_item_id, {
          correctResponses: correctCount,
          totalAnswers: 1,
          responses: [mappedResponse],
        });
      }
    });
  });

  practiceSetAnswers.forEach((responseStats, practieSetItemId) => {
    practiceSetAnswers.set(practieSetItemId, {
      correctResponses: responseStats.correctResponses,
      totalAnswers: responseStats.totalAnswers,
      responses: reduceAnswers(responseStats),
    });
  });

  return practiceSetAnswers;
};

const reduceAnswers = (
  practiceSetAnswerStats: IPracticeSetItemResponseStats
): ISessionItemResponse[] => {
  const responseMap = new Map<string, ISessionItemResponse>();

  practiceSetAnswerStats.responses.forEach((response) => {
    const storedResponse = responseMap.get(getResponseKey(response));

    if (storedResponse) {
      storedResponse.count += 1;
      responseMap.set(getResponseKey(storedResponse), storedResponse);
    } else {
      responseMap.set(getResponseKey(response), response);
    }
  });

  return Array.from(responseMap.values());
};

const getResponseKey = (response: ISessionItemResponse) => {
  if (response.choice_id !== "0") {
    return `choice-${response.choice_id}-${response.is_correct}-${response.zone_id}`;
  } else if (response.drawing_image_url !== "") {
    return response.drawing_image_url;
  } else {
    return `text-${response.response_text}-${response.is_correct}`;
  }
};

interface IResponseData {
  id?: string;
  text?: string;
  image_url?: string;
  image_alt_text?: string;
}

// This needs major adjustments for image-choice items, diagram and classify
export const getCorrectResponseData = (
  item: IPracticeSetItem,
  returnMultiple?: boolean
): Array<IResponseData | undefined> => {
  const res = [];
  if (item.question_type === QuestionType.MultipleChoice) {
    const correctChoice = item.multiple_choice?.choices.find(
      (c) => c.is_correct
    );
    res.push({
      id: correctChoice?.id,
      text: correctChoice?.text,
      image_url: correctChoice?.image_url,
      image_alt_text: correctChoice?.image_alt_text,
    });
  } else if (item.question_type === QuestionType.MultipleSelect) {
    (item.multiple_select?.choices ?? [])
      .filter((c) => c.is_correct)
      .forEach((c) => {
        res.push({
          id: c.id,
          text: c.text,
          image_url: c.image_url,
          image_alt_text: c.image_alt_text,
        });
      });
  } else if (item.question_type === QuestionType.TextResponse) {
    res.push({
      text: item.text_response?.correct_response,
      id: item.text_response?.id,
    });
  } else if (item.question_type === QuestionType.NumberResponse) {
    res.push({
      text: item.number_response?.correct_response,
      id: item.number_response?.id,
    });
  } else if (item.question_type === QuestionType.Diagram) {
    res.push(undefined);
  } else if (item.question_type === QuestionType.Classify) {
    res.push(undefined);
  }

  // TODO: UI support for items with multiple correct response texts
  // Affected files: PracticeSetQuestionCardAnswer, useStudentsModal
  if (!returnMultiple) {
    if (res.length > 1) {
      console.warn(
        "More than one correct response found; unsupported. Truncating to first correct response."
      );
      return res.slice(0, 1);
    }
  }

  return res;
};

export const getPrompt = (
  practiceSetItem: IPracticeSetItem
): string | undefined => {
  switch (practiceSetItem.question_type) {
    case QuestionType.MultipleChoice:
      return practiceSetItem.multiple_choice?.prompt;
    case QuestionType.MultipleSelect:
      return practiceSetItem.multiple_select?.prompt;
    case QuestionType.Classify:
      return practiceSetItem.classify?.prompt;
    case QuestionType.Diagram:
      return practiceSetItem.diagram?.prompt;
    case QuestionType.Draw:
      return practiceSetItem.draw?.prompt;
    case QuestionType.NumberResponse:
      return practiceSetItem.number_response?.prompt;
    case QuestionType.TextResponse:
      return practiceSetItem.text_response?.prompt;
    default:
      console.warn("Unknown question type - attempting to get prompt");
      return undefined;
  }
};

export const getResponseData = (
  practiceSetItem: IPracticeSetItem,
  choice_id: string
): IResponseData | undefined => {
  if (practiceSetItem.question_type === QuestionType.MultipleChoice) {
    const choice = practiceSetItem.multiple_choice?.choices.find(
      (c) => c.id === choice_id
    );
    return {
      text: choice?.text,
      image_url: choice?.image_url,
      image_alt_text: choice?.image_alt_text,
    };
  } else if (practiceSetItem.question_type === QuestionType.MultipleSelect) {
    const choice = practiceSetItem.multiple_select?.choices.find(
      (c) => c.id === choice_id
    );
    return {
      text: choice?.text,
      image_url: choice?.image_url,
      image_alt_text: choice?.image_alt_text,
    };
  } else if (practiceSetItem.question_type === QuestionType.Classify) {
    return getClassifyResponseData(practiceSetItem, choice_id);
  } else if (practiceSetItem.question_type === QuestionType.Diagram) {
    const label = practiceSetItem.diagram_labels?.find(
      (label) => label.id === choice_id
    );
    return {
      text: label?.label,
    };
  } else if (
    practiceSetItem.question_type === QuestionType.NumberResponse ||
    practiceSetItem.question_type === QuestionType.TextResponse
  ) {
    return undefined; //number and text response will already have response text
  } else if (practiceSetItem.question_type === QuestionType.Draw) {
    console.warn("cant get choice text for draw choice");
    return undefined;
  } else {
    console.warn("Unknown question type - attempting to get choice text");
    return undefined;
  }
};

const getClassifyResponseData = (
  practiceSetItem: IPracticeSetItem,
  choice_id: string
): IResponseData | undefined => {
  let text, imageUrl, imageAltText;
  for (let i = 0; i < (practiceSetItem.classify?.categories.length || 0); i++) {
    const choice = practiceSetItem.classify?.categories[i].choices.find(
      (choice) => choice.id === choice_id
    );
    if (choice) {
      text = choice.text;
      imageUrl = choice.image_url;
      imageAltText = choice.image_alt_text;
      break;
    }
  }
  return {
    text,
    image_url: imageUrl,
    image_alt_text: imageAltText,
  };
};
