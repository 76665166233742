import { pxToRem } from "adminComponents/utils/pxToRem";

export const AdminAddStudentsViaEmailsModal = {
  parts: ["text", "textareaContainer", "button"],

  baseStyle: {
    text: {
      color: "primary.dark-gray",
    },
    textareaContainer: {
      marginTop: { base: pxToRem(12), md: pxToRem(20) },
      marginBottom: { base: pxToRem(12), md: pxToRem(25) },
      minWidth: { base: "0px", md: "520px" },
    },
    button: {
      width: "full",
    },
    list: {
      marginTop: { base: pxToRem(10), md: pxToRem(15) },
    },
  },
};
