import { Flex, HStack, VStack } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Heading } from "adminComponents/atoms/Heading";
import { Text } from "adminComponents/atoms/Text";
import { FullStudentAvatar } from "adminComponents/molecules/FullStudentAvatar";
import {
  INavigationHeaderProps,
  NavigationHeader,
} from "adminComponents/molecules/NavigationHeader";
import { TemplateWithCenteredHeroOneColumn } from "adminComponents/templates/TemplateWithCenteredHeroOneColumn";
import { pxToRem } from "adminComponents/utils";
import { IUser } from "links/lib/types";

export interface ITeacherMyAvatarScreenProps {
  avatarStage: React.ReactNode;
  isLoading: boolean;
  navData: INavigationHeaderProps;
  user: IUser;
  handleAvatarEdit: () => void;
  handleAvatarShare: () => void;
  handleAccountClick: () => void;
  handleProfileClick: () => void;
}

export const TeacherMyAvatarScreen: React.FC<ITeacherMyAvatarScreenProps> = ({
  isLoading,
  navData,
  user,
  handleAccountClick,
  handleAvatarEdit,
  handleAvatarShare,
  handleProfileClick,
  avatarStage,
}) => {
  const { t } = useTranslation("admin", {
    keyPrefix: "teacherMyAvatarScreen",
    useSuspense: false,
  });

  return (
    <TemplateWithCenteredHeroOneColumn
      nav={<NavigationHeader {...navData} />}
      isHeroShifted={false}
      isLoading={isLoading}
    >
      <VStack w="full">
        <Flex align="center" direction="column" w="full">
          <Flex
            maxWidth={{ base: pxToRem(255), md: "full" }}
            maxHeight={pxToRem(518)}
            width={["full", null, pxToRem(518)]}
          >
            <FullStudentAvatar
              handleEdit={handleAvatarEdit}
              handleShare={handleAvatarShare}
            >
              {avatarStage}
            </FullStudentAvatar>
          </Flex>
        </Flex>
        <Flex direction="column" align="center">
          <Heading
            variant="adminH4"
            as="h1"
            marginTop={{ base: pxToRem(24), md: pxToRem(32) }}
            marginBottom={{ base: pxToRem(2), md: pxToRem(8) }}
          >
            {user.teacher_preferred_name}
          </Heading>
          <HStack alignItems="center">
            <Text variant="adminP2">
              {user.given_name} {user.family_name}
            </Text>
            <Text variant="adminP1">&middot;</Text>
            <Button
              variant="adminTextButtonMedium"
              onClick={handleAccountClick}
            >
              {t("accountButton")}
            </Button>
            <Text variant="adminP1">&middot;</Text>
            <Button
              variant="adminTextButtonMedium"
              onClick={handleProfileClick}
            >
              {t("profileButton")}
            </Button>
          </HStack>
        </Flex>
      </VStack>
    </TemplateWithCenteredHeroOneColumn>
  );
};
