import { useSessionRoundState } from "links/lib/contexts/sessionRoundState";

import { useGroupConnectedUsers } from "./useGroupConnectedUsers";

interface IUseConfirmationStatusProps {
  studentId: string;
  groupId: string;
}

interface IUseConfirmationStatusResult {
  isConfirmed: boolean;
  isAllGroupConfirmed: boolean;
}

export const useConfirmationStatus = ({
  studentId,
  groupId,
}: IUseConfirmationStatusProps): IUseConfirmationStatusResult => {
  const roundState = useSessionRoundState();
  const groupUsers = useGroupConnectedUsers(groupId);

  const { confirmed_user_ids } = roundState;

  const isConfirmed = confirmed_user_ids.some((id) => id === studentId);
  const isAllGroupConfirmed = groupUsers.every((u) => {
    return confirmed_user_ids.indexOf(u.id) !== -1;
  });

  return { isConfirmed, isAllGroupConfirmed };
};
