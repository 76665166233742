import { SpineCanvas } from "@esotericsoftware/spine-webgl";
import React, { useEffect, useRef } from "react";
import { useMount, usePrevious, useUnmount } from "react-use";

import { AvatarSkeletonType } from "links/lib/types";
import {
  SpineAvatar,
  SpineAvatarAnimationListener,
} from "sharedComponents/molecules/AvatarStage/lib/SpineAvatar";
import { SpineStage } from "sharedComponents/molecules/AvatarStage/lib/SpineStage";

export interface IAvatarPhotoProps {
  atlasUrl: string;
  skeletonUrl: string;
  skeletonType: AvatarSkeletonType;
  // this property will only be applied at mount
  debugMode?: boolean;
  captureHandler?: (blob: Blob) => void;
}

const standingCamera = { x: -10, y: 100, z: 0, zoom: 0.3 };
const seatedCamera = { x: -20, y: 85, z: 0, zoom: 0.3 };

const getCamera = (skelType: AvatarSkeletonType) => {
  return skelType === AvatarSkeletonType.Seated ? seatedCamera : standingCamera;
};

export const AvatarPhoto: React.FC<IAvatarPhotoProps> = ({
  atlasUrl,
  skeletonUrl,
  skeletonType,
  debugMode,
  captureHandler,
}) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const spineStageRef = useRef<SpineStage | null>(null);
  const spineAppRef = useRef<SpineCanvas | null>(null);

  useMount(() => {
    if (!canvasRef.current) return;

    const canvas = canvasRef.current;

    canvas.width = 300;
    canvas.height = 300;

    // hide canvas offscreen
    if (!debugMode) {
      canvas.style.position = "fixed";
      canvas.style.top = "-1000px";
      canvas.style.left = "-1000px";
      canvas.style.width = "300px";
      canvas.style.height = "300px";
      canvas.style.zIndex = "-1";
    }

    const spineStage = (spineStageRef.current = new SpineStage({
      camera: getCamera(skeletonType),
    }));

    spineAppRef.current = new SpineCanvas(canvas, {
      app: spineStage,
      webglConfig: {},
    });
  });

  const prevAtlasUrl = usePrevious(atlasUrl);
  const prevSkeletonUrl = usePrevious(skeletonUrl);

  // Unregister old avatar from stage and register
  // new avatar whenever atlast/skeleton change. This will
  // trigger a capture on initialize
  useEffect(() => {
    if (!spineStageRef.current || !spineAppRef.current) return;

    const avatar = new SpineAvatar({
      skeletonUrl,
      atlasUrl,
      x: 0,
      y: -125,
      scale: 0.3,
      animationStateListener: new SpineAvatarAnimationListener({}),
      animation: null,
    });

    spineAppRef.current.clear(1, 1, 1, 0);

    spineStageRef.current.registerAsset(avatar);

    // Once the avatar is initialized, capture canvas
    avatar.onIntialized(() => {
      if (!spineStageRef.current) return;
      if (!prevAtlasUrl || !prevSkeletonUrl) return;
      if (prevAtlasUrl === atlasUrl && prevSkeletonUrl === skeletonUrl) return;
      spineStageRef.current.requestCapture();
    });

    return () => {
      if (!spineStageRef.current) return;

      spineStageRef.current.unregisterAsset(avatar);
    };
  }, [atlasUrl, skeletonUrl, prevAtlasUrl, prevSkeletonUrl]);

  useEffect(() => {
    spineStageRef.current?.setCamera(getCamera(skeletonType));
  }, [skeletonType]);

  // Register a new capture handler on the stage
  // whenever the capture handler prop changes
  useEffect(() => {
    spineStageRef.current?.onCapture((c) => {
      c.toBlob((blob) => {
        if (!blob) return;
        captureHandler?.(blob);
      });
    });
  }, [captureHandler]);

  useUnmount(() => {
    if (spineStageRef.current) {
      spineStageRef.current.dispose();
    }
  });

  return <canvas ref={canvasRef} />;
};
