import { Box } from "@chakra-ui/react";
import React from "react";

import { PracticeSetCard } from "adminComponents/molecules/PracticeSetCard";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { IClassroom, IPracticeSet } from "links/lib/types";

interface PracticeSetCardProps {
  practiceSet: IPracticeSet;
  accuracy?: number;
}

interface IProps {
  practiceSetData?: Array<PracticeSetCardProps>;
  classroom: IClassroom;
}

export const ClassDetailPracticeSets: React.FC<IProps> = ({
  practiceSetData,
  classroom,
}) => {
  return (
    <>
      {practiceSetData?.map((practiceSetCardData) => {
        const { practiceSet, accuracy } = practiceSetCardData;

        return (
          <Box key={practiceSet.id} mb={pxToRem(12)}>
            <PracticeSetCard
              classroom={classroom}
              practiceSet={practiceSet}
              accuracy={accuracy}
              accuracyType="class"
              showStandardsInfo
              showMostRecentInteractionInfo
            />
          </Box>
        );
      })}
    </>
  );
};
