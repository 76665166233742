import { SceneRenderer, SpineCanvas } from "@esotericsoftware/spine-webgl";

export interface ISpineStageAsset {
  isInitialized: boolean;
  drawOrder: number;
  loadAssets: (canvas: SpineCanvas) => Promise<void>;
  initialize: (canvas: SpineCanvas) => Promise<void>;
  dispose: () => void;
  update: (
    canvas: SpineCanvas,
    delta: number,
    stageToWorldPosition: { x: number; y: number }
  ) => void;
  render: (canvas: SpineCanvas, renderer: SceneRenderer) => void;
}

export interface IStageOverlayAsset {
  render: (
    ctx: CanvasRenderingContext2D,
    camera: IStageCamera,
    pixelRatio: number
  ) => void;
}

export interface IStageCamera {
  x: number;
  y: number;
  z: number;
  zoom: number;
}

export enum NametagArrowPosition {
  Top = "TOP",
  Bottom = "BOTTOM",
}
