import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  LocationInfo,
  SeasonMap,
  SeasonMapLocation,
  SeasonMapPin,
  SeasonMapZone,
} from "sharedComponents/molecules/SeasonMap";
import { ISeasonMapPannerChildProps } from "sharedComponents/molecules/SeasonMapPanner";

import BackdropImage from "./resource/background.svg";
import BuildingsImage from "./resource/buildings_only.svg";
import BamPowPreviewImage from "./resource/locations/location_preview_bam_pow.svg";
import CascadeFailurePreviewImage from "./resource/locations/location_preview_cascade_failure.svg";
import DeepDivePreviewImage from "./resource/locations/location_preview_deep_dive.svg";
import EerieEstatesPreviewImage from "./resource/locations/location_preview_eerie_estates.svg";
import GiftShopPreviewImage from "./resource/locations/location_preview_gift_shop.svg";
import MesozoicMayhemPreviewImage from "./resource/locations/location_preview_mesozoic_mayhem.svg";
import RiverOfRuinPreviewImage from "./resource/locations/location_preview_river_of_ruin.svg";
import RobotRodeoPreviewImage from "./resource/locations/location_preview_robot_rodeo.svg";
import SaucerSpinPreviewImage from "./resource/locations/location_preview_saucer_spin.svg";
import ShrinkRayPreviewImage from "./resource/locations/location_preview_shrink_ray.svg";
import { ReactComponent as Zone1SVG } from "./resource/zone_1.svg";
import { ReactComponent as Zone2SVG } from "./resource/zone_2.svg";
import { ReactComponent as Zone3SVG } from "./resource/zone_3.svg";

export interface ISeason2MapProps extends ISeasonMapPannerChildProps {
  // zone ids for unlocked zones
  unlockedZoneIds: Array<string>;
  // the id of the user's current location on map
  currentLocationId?: string;
  // the profile image of the user to show in the map pin
  userImg?: string;
  // user progression for each map location
  locationsProgress: Array<{
    totalSpaces: number;
    spacesCollected: number;
    locationId: string;
  }>;
  // handler for when a location is selected
  handleLocationSelect?: (info: LocationInfo) => void;
}

export interface IZoneData {
  zoneId: string;
  offset: { top: number; left: number };
  pathSVG: React.ReactElement<SVGElement>;
  pathColor: string;
  width: number;
  height: number;
  locations: Array<ILocationData>;
}

export interface ILocationData {
  offset: { top: number; left: number };
  locationId: string;
  previewImageUrl: string;
}

export const mapSize = {
  width: 3840,
  height: 2061,
};

export const mapData: Array<IZoneData> = [
  // Zone 2
  {
    zoneId: "5",
    offset: { top: 0, left: 0 },
    pathSVG: <Zone2SVG />,
    pathColor: "#00A3DE",
    width: 3840,
    height: 2061,
    locations: [
      {
        offset: { top: 235, left: 2260 },
        locationId: "19",
        previewImageUrl: CascadeFailurePreviewImage,
      },
      {
        offset: { top: 515, left: 3250 },
        locationId: "20",
        previewImageUrl: RobotRodeoPreviewImage,
      },
      {
        offset: { top: 1120, left: 3650 },
        locationId: "21",
        previewImageUrl: SaucerSpinPreviewImage,
      },
    ],
  },

  // Zone 3
  {
    zoneId: "6",
    offset: { top: 0, left: 0 },
    pathSVG: <Zone3SVG />,
    pathColor: "#E961B1",
    width: 3840,
    height: 2061,
    locations: [
      {
        offset: { top: 1882, left: 1850 },
        locationId: "22",
        previewImageUrl: BamPowPreviewImage,
      },
      {
        offset: { top: 1768, left: 2735 },
        locationId: "23",
        previewImageUrl: RiverOfRuinPreviewImage,
      },
      {
        offset: { top: 1634, left: 3595 },
        locationId: "24",
        previewImageUrl: EerieEstatesPreviewImage,
      },
    ],
  },

  // Zone 1
  {
    zoneId: "4",
    offset: { top: 0, left: 0 },
    pathSVG: <Zone1SVG />,
    pathColor: "#FA553A",
    width: 3840,
    height: 2061,
    locations: [
      {
        offset: { top: 1250, left: 2090 },
        locationId: "15",
        previewImageUrl: GiftShopPreviewImage,
      },
      {
        offset: { top: 430, left: 1126 },
        locationId: "16",
        previewImageUrl: MesozoicMayhemPreviewImage,
      },
      {
        offset: { top: 1010, left: 456 },
        locationId: "17",
        previewImageUrl: DeepDivePreviewImage,
      },
      {
        offset: { top: 1605, left: 740 },
        locationId: "18",
        previewImageUrl: ShrinkRayPreviewImage,
      },
    ],
  },
];

export const Season2Map: React.FC<ISeason2MapProps> = ({
  scale = 1,
  handleLocationFocus,
  handleLocationSelect,
  unlockedZoneIds,
  userImg,
  currentLocationId,
  locationsProgress,
}) => {
  const { t } = useTranslation("map", {
    keyPrefix: "season2.locations",
    useSuspense: false,
  });

  // merge map data with translations, location progress, and zone locks
  const combinedMapData = useMemo(() => {
    return mapData.map((zoneData) => {
      return {
        ...zoneData,
        isLocked: !unlockedZoneIds.some((z) => z === zoneData.zoneId),
        locations: zoneData.locations.map((l) => ({
          ...l,
          // translate name
          name: t(`loc${l.locationId}.name`),
          // translate description
          description: t(`loc${l.locationId}.description`),
          // update progress for location
          progress: locationsProgress.find(
            (lp) => lp.locationId === l.locationId
          ) ?? { totalSpaces: 1, spacesCollected: 0, locationId: l.locationId },
        })),
      };
    });
  }, [t, unlockedZoneIds, locationsProgress]);

  return (
    <SeasonMap
      width={mapSize.width}
      height={mapSize.height}
      backdropImage={BackdropImage}
      landmarksImage={BuildingsImage}
      scale={scale}
      handleLocationFocus={handleLocationFocus}
      handleLocationSelect={handleLocationSelect}
    >
      {combinedMapData.map((zoneData) => (
        <SeasonMapZone
          key={zoneData.zoneId}
          offset={zoneData.offset}
          pathSVG={zoneData.pathSVG}
          pathColor={zoneData.pathColor}
          width={zoneData.width}
          height={zoneData.height}
          isLocked={zoneData.isLocked}
        >
          {zoneData.locations.map((locationData) => {
            return (
              <SeasonMapLocation
                key={locationData.locationId}
                name={locationData.name}
                description={locationData.description}
                offset={locationData.offset}
                locationId={locationData.locationId}
                progress={locationData.progress}
                previewImageUrl={locationData.previewImageUrl}
              >
                {currentLocationId === locationData.locationId ? (
                  <SeasonMapPin userImg={userImg} />
                ) : (
                  <></>
                )}
              </SeasonMapLocation>
            );
          })}
        </SeasonMapZone>
      ))}
    </SeasonMap>
  );
};
