import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { useAxios } from "../../hooks/useAxios";

export interface IDeletePracticeSetItemArgs {
  id: string;
  practice_set_id: string;
}

export interface IUseDeletePracticeSetItemProps {
  onSuccess?: () => void;
  onError?: () => void;
  onSettled?: () => void;
}

export default function useDeletePracticeSetItem(
  props?: IUseDeletePracticeSetItemProps
): UseMutationResult<unknown, unknown, IDeletePracticeSetItemArgs> {
  const axios = useAxios();
  const queryClient = useQueryClient();

  const deletePracticeSetItem = async (args: IDeletePracticeSetItemArgs) => {
    const { id, practice_set_id } = args;

    const response = await axios({
      method: "delete",
      url: `/v1/practice-sets/items/${id}`,
    }).then((r) => r.data);

    queryClient.invalidateQueries(["practiceSets", { id: practice_set_id }]);
    queryClient.invalidateQueries(["practiceSetItems", { practice_set_id }]);
    queryClient.setQueryData(["practiceSetItems", { id }], undefined);

    return response;
  };

  const mutation = useMutation(deletePracticeSetItem, {
    onSuccess: props?.onSuccess,
    onError: props?.onError,
    onSettled: props?.onSettled,
  });

  return {
    ...mutation,
  };
}
