import { Box, Image, Text } from "@chakra-ui/react";
import React from "react";

import { pxToRem } from "adminComponents/utils";
import { RichText } from "links/lib/types";
import { RichTextRenderer } from "sharedComponents/atoms/RichTextRenderer";

import { Icon } from "../Icon";

interface ClassificationCursorLabelProps {
  text?: RichText;
  imageUrl?: string;
  cursorColor: string;
}

export const ClassificationCursorLabel: React.FC<
  ClassificationCursorLabelProps
> = ({ text, imageUrl, cursorColor }) => {
  return (
    <Box
      borderRadius="full"
      borderWidth={pxToRem(3)}
      borderColor={cursorColor}
      py={imageUrl ? "0" : pxToRem(2)}
      px={imageUrl ? "0" : pxToRem(16)}
      backgroundColor="primary.white"
      display="flex"
      alignItems="center"
      position="relative"
      overflow="hidden"
      zIndex={-1}
    >
      <Box
        boxSize={pxToRem(16)}
        position="absolute"
        left="2px"
        display="flex"
        padding="0 4px"
      >
        <Icon
          icon="drag"
          iconColor="utility.success"
          height="100%"
          width="100%"
        />
      </Box>

      {text && (
        <Text
          textStyle="gameText"
          color={
            [
              "smoke.base-shadow-02",
              "amber.base-shadow-02",
              "capri.base-shadow-02",
              "monaco.base-shadow-02",
              "black",
            ].includes(cursorColor)
              ? "primary.warm-white"
              : "primary.warm-black"
          }
          fontSize={pxToRem(16)}
          paddingLeft={pxToRem(2)}
        >
          <RichTextRenderer content={text} />
        </Text>
      )}
      {imageUrl && <Image src={imageUrl} objectFit="contain" />}
    </Box>
  );
};
