import { Box, SimpleGrid } from "@chakra-ui/react";
import React, { useRef } from "react";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { SessionGameType } from "links/lib/types";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { TabQuestionGroupView } from "sessionComponents/molecules/TabQuestionGroupView";
import { boxShadows } from "sessionComponents/theme/boxShadows";

import { GameBackground } from "../GameBackground";

interface IPortraitLayoutProps {
  outerGame?: SessionGameType;
  questionComponent: React.ReactElement;
  answerOptionsComponent: React.ReactElement;
  confirmAreaComponent?: React.ReactElement;
  groupStatusComponent?: React.ReactElement; //only on teacher view
  teamsLength?: number; //only on teacher view
}

export const PortraitLayout: React.FC<IPortraitLayoutProps> = ({
  outerGame = SessionGameType.TheBigBoard,
  questionComponent,
  answerOptionsComponent,
  confirmAreaComponent,
  groupStatusComponent,
  teamsLength,
}) => {
  const { match: currentBreakpoints } = useBreakpoints();
  const answerComponentWrapperRef = useRef<HTMLDivElement | null>(null);

  return (
    <>
      <SimpleGrid
        columns={1}
        spacing={0}
        width="full"
        height="full"
        borderTopWidth={pxToRem(currentBreakpoints.borderWidth)}
        borderTopColor="primary.tan"
        overflowY="auto"
      >
        <Box display="flex" h="full">
          <GameBackground outerGame={outerGame}>
            <Box
              h="full"
              w="full"
              display="flex"
              alignItems="flex-start"
              justifyContent="center"
              overflow="auto"
            >
              <Box h="fit-content" minW={0} w="full">
                {questionComponent}
              </Box>
            </Box>
          </GameBackground>
        </Box>
        <Box
          backgroundColor="primary.warm-white"
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          flexDir="column"
          width="100%"
          height="auto"
        >
          <Box
            ref={answerComponentWrapperRef}
            padding={pxToRem(currentBreakpoints.padding)}
            paddingLeft={groupStatusComponent ? 0 : undefined}
            paddingRight={groupStatusComponent ? 0 : undefined}
            h="100%"
            w="100%"
            paddingBottom={0}
            marginBottom={pxToRem(
              currentBreakpoints.buttonHeight + currentBreakpoints.padding * 2
            )}
          >
            <Box w="full" h="full">
              {groupStatusComponent ? (
                <TabQuestionGroupView
                  answerOptionsComponent={answerOptionsComponent}
                  groupStatusComponent={groupStatusComponent}
                  teamsLength={teamsLength || 0}
                />
              ) : (
                answerOptionsComponent
              )}
            </Box>
          </Box>
          {!!confirmAreaComponent && (
            <Box
              position="absolute"
              bottom="0"
              width="100%"
              borderTopWidth={pxToRem(currentBreakpoints.borderWidth)}
              borderTopColor="primary.tan"
              display="flex"
              justifyContent="center"
              alignItems="center"
              padding={pxToRem(currentBreakpoints.padding)}
              backgroundColor="primary.warm-white"
              boxShadow={boxShadows.questionLayout}
              zIndex={10}
            >
              {confirmAreaComponent}
            </Box>
          )}
        </Box>
      </SimpleGrid>
    </>
  );
};
