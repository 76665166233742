import {
  Box,
  Center,
  Circle,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import { History } from "history";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { Avatar } from "adminComponents/atoms/Avatar";
import { Button } from "adminComponents/atoms/Button";
import { Icon } from "adminComponents/atoms/Icon";
import { IconCTAButton } from "adminComponents/atoms/IconCTAButton";
import { SubmarkLogo } from "adminComponents/atoms/SubmarkLogo";
import { Tabs } from "adminComponents/atoms/Tabs";
import { Text } from "adminComponents/atoms/Text";
import { TextLink } from "adminComponents/atoms/TextLink";
import { colors } from "adminComponents/theme/colors";
import { generateTeacherName, pxToRem } from "adminComponents/utils";
import { useAnalytics } from "lib/contexts/analytics";
import { useAuth } from "links/lib/features/auth";
import {
  useFetchUserFollowersInfinite,
  useFetchUserFollowingInfinite,
  useFetchUserPublicProfile,
  useFollowUser,
  useUnfollowUser,
} from "links/lib/features/users";
import { IUserFollowUserParams } from "links/lib/features/users/useFollowUser";
import {
  AnalyticsEvent,
  IGradeLevel,
  ISubject,
  IUser,
  UserPublicProfileStatistics,
  UserRole,
} from "links/lib/types";
import { useNavigationData } from "screens/TeacherDashboard/contexts/TeacherNavigationDataProvider";

export enum TeacherFollowingModalTabIndex {
  Followers = 0,
  Following = 1,
}

export interface IProps {
  user: IUser;
  statistics?: UserPublicProfileStatistics;
  isOpen: boolean;
  initialTabIndex?: TeacherFollowingModalTabIndex;
  handleClose: () => void;
}

export const TeacherFollowingModal: React.FC<IProps> = ({
  user,
  statistics,
  isOpen,
  initialTabIndex = TeacherFollowingModalTabIndex.Followers,
  handleClose,
}: IProps) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const {
    navigationData: { subjects, gradeLevels },
  } = useNavigationData();

  const { authUser } = useAuth();
  const [activeTabIndex, setActiveTabIndex] = useState(initialTabIndex);
  const name = generateTeacherName(user).primary;

  const fetchFollowers = useFetchUserFollowersInfinite({ userId: user.id });
  const followerUserIds =
    fetchFollowers.data?.pages.flatMap((p) => p.user_ids) || [];
  const showViewMoreFollowers =
    followerUserIds.length < (statistics?.followers_count || 0);

  const fetchFollowing = useFetchUserFollowingInfinite({ userId: user.id });
  const followingUserIds =
    fetchFollowing.data?.pages.flatMap((p) => p.user_ids) || [];
  const showViewMoreFollowing =
    followingUserIds.length < (statistics?.following_count || 0);

  const tabData = [
    {
      label: t("teacherFollowingModal.labelTabFollowers", {
        count: statistics?.followers_count || 0,
        countFormatted: (statistics?.followers_count || 0).toLocaleString(
          authUser?.language
        ),
      }),
      isPremium: false,
      content: (
        <FollowingList
          authUser={authUser}
          userIds={followerUserIds}
          showViewMore={showViewMoreFollowers}
          subjects={subjects}
          gradeLevels={gradeLevels}
          handleClose={handleClose}
          handleViewMore={fetchFollowers.fetchNextPage}
          labelFollow={t("common.follow")}
          labelUnfollow={t("common.unfollow")}
          labelEmpty={t("teacherFollowingModal.labelFollowedByNone", { name })}
          labelViewMore={t("common.viewMore")}
        />
      ),
    },
    {
      label: t("teacherFollowingModal.labelTabFollowing", {
        count: statistics?.following_count || 0,
        countFormatted: (statistics?.following_count || 0).toLocaleString(
          authUser?.language
        ),
      }),
      isPremium: false,
      content: (
        <FollowingList
          authUser={authUser}
          userIds={followingUserIds}
          showViewMore={showViewMoreFollowing}
          subjects={subjects}
          gradeLevels={gradeLevels}
          handleClose={handleClose}
          handleViewMore={fetchFollowing.fetchNextPage}
          labelFollow={t("common.follow")}
          labelUnfollow={t("common.unfollow")}
          labelEmpty={t("teacherFollowingModal.labelFollowingNone", { name })}
          labelViewMore={t("common.viewMore")}
        />
      ),
    },
  ];

  return (
    <Modal
      blockScrollOnMount={true}
      isOpen={isOpen}
      onClose={handleClose}
      size="4xl"
      variant="adminModal"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{name}</ModalHeader>
        <ModalCloseButton top={pxToRem(20)} right={pxToRem(20)}>
          <IconCTAButton ariaLabel={t("common.close")} />
        </ModalCloseButton>
        <ModalBody>
          <Tabs
            variant="adminScreenTabs"
            tabIndex={activeTabIndex}
            handleChange={setActiveTabIndex}
            tabData={tabData}
          />
          <Flex gap={pxToRem(12)} mt={pxToRem(24)} justifyContent="flex-end">
            <Button
              variant="adminButtonOutlined"
              size="lg"
              w={["50%", null, "auto"]}
              onClick={handleClose}
              rounded="full"
            >
              {t("common.close")}
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

interface IFollowingListProps {
  authUser?: IUser;
  userIds: Array<string>;
  subjects: Array<ISubject>;
  gradeLevels: Array<IGradeLevel>;
  labelFollow: string;
  labelUnfollow: string;
  labelEmpty: string;
  labelViewMore: string;
  showViewMore?: boolean;
  handleClose: () => void;
  handleViewMore: () => void;
}

const FollowingList: React.FC<IFollowingListProps> = ({
  authUser,
  userIds,
  subjects,
  gradeLevels,
  labelFollow,
  labelUnfollow,
  labelEmpty,
  labelViewMore,
  showViewMore,
  handleClose,
  handleViewMore,
}: IFollowingListProps) => {
  const history = useHistory();

  return (
    <VStack
      p={pxToRem(16)}
      mt={pxToRem(24)}
      maxH="60vh"
      alignItems="left"
      borderRadius={pxToRem(16)}
      borderWidth={pxToRem(1)}
      borderColor="primary.medium-gray"
      overflow="auto"
      spacing={pxToRem(16)}
    >
      {userIds.length > 0 &&
        userIds.map((userId) => (
          <FollowingItem
            key={userId}
            userId={userId}
            authUserId={authUser?.id || "0"}
            showButton={authUser ? authUser.id !== userId : false}
            subjects={subjects}
            gradeLevels={gradeLevels}
            history={history}
            labelFollow={labelFollow}
            labelUnfollow={labelUnfollow}
            handleClose={handleClose}
          />
        ))}
      {userIds.length === 0 && (
        <Box textAlign="center" p={pxToRem(64)}>
          <Text variant="adminP1" color="primary.dark-gray">
            {labelEmpty}
          </Text>
        </Box>
      )}
      {showViewMore && (
        <Center>
          <Button variant="adminButtonFilled" onClick={handleViewMore}>
            {labelViewMore}
          </Button>
        </Center>
      )}
    </VStack>
  );
};

interface IFollowingItemProps {
  userId: string;
  authUserId: string;
  showButton: boolean;
  subjects: Array<ISubject>;
  gradeLevels: Array<IGradeLevel>;
  history: History;
  labelFollow: string;
  labelUnfollow: string;
  handleClose: () => void;
}

const FollowingItem: React.FC<IFollowingItemProps> = ({
  userId,
  authUserId,
  showButton,
  subjects,
  gradeLevels,
  history,
  labelFollow,
  labelUnfollow,
  handleClose,
}: IFollowingItemProps) => {
  const fetchProfile = useFetchUserPublicProfile({
    userId,
    includeStatistics: false,
  });

  const { trackEvent } = useAnalytics();

  const followUser = useFollowUser({
    onSuccess: (_, args: IUserFollowUserParams) => {
      trackEvent(
        AnalyticsEvent.TeacherDashboard_TeacherProfile_FollowingModal_FollowUser,
        { user_id: args.userId }
      );
    },
  });
  const onFollowUser = () => {
    followUser.mutate({ userId, authUserId });
  };

  const unfollowUser = useUnfollowUser({});
  const onUnfollowUser = () => {
    unfollowUser.mutate({ userId, authUserId });
  };

  if (!fetchProfile.data) return <></>;

  const { user, is_following, is_private } = fetchProfile.data;

  const isInternalContentSpecialist =
    user.organization_is_internal && user.role === UserRole.ContentSpecialist;

  const name = generateTeacherName(user).primary;

  const subjectsList = user.teacher_subjects
    ?.map((s) => {
      return subjects.find((subject) => subject.id === s.subject_id);
    })
    .filter((s) => !!s)
    .map((subject) => (subject && subject.name) || "") as Array<string>;

  const gradeLevelsList = user.teacher_grade_levels
    ?.map((g) => {
      return gradeLevels.find((gl) => gl.id === g.grade_level_id);
    })
    .filter((g) => !!g)
    .map(
      (gradeLevel) => (gradeLevel && gradeLevel.grade_level) || ""
    ) as Array<string>;

  const instructionList = [...subjectsList, ...gradeLevelsList].join(", ");

  const handleClickName = () => {
    history.push(`/t/profiles/${user.custom_url_code}`);
    handleClose();
  };

  return (
    <HStack w="full" spacing={pxToRem(16)}>
      {isInternalContentSpecialist && (
        <Circle size={pxToRem(48)}>
          <Circle
            bgColor={colors["pear-practice"]["blue-skies"]}
            size={pxToRem(44)}
            p={pxToRem(6)}
          >
            <SubmarkLogo />
          </Circle>
        </Circle>
      )}
      {!isInternalContentSpecialist && (
        <Avatar src={user.profile_image_url} name={name} />
      )}

      <VStack flex="1" alignItems="left" spacing={0} overflow="hidden">
        <Box>
          {is_private ? (
            <Text
              variant="adminP1"
              maxW="full"
              overflow="hidden"
              textOverflow="ellipsis"
              sx={{ textWrap: "nowrap" }}
            >
              {name}
            </Text>
          ) : (
            <TextLink
              handleClick={handleClickName}
              size="lg"
              overflowX="hidden"
              textOverflow="ellipsis"
              maxW="full"
              textSx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {name}
            </TextLink>
          )}
        </Box>
        <Box
          maxW="full"
          overflow="hidden"
          sx={{ textWrap: "nowrap" }}
          textOverflow="ellipsis"
        >
          {instructionList}
        </Box>
      </VStack>

      {showButton && !is_private && (
        <>
          {!is_following && onFollowUser && (
            <Button
              variant="adminButtonFilled"
              onClick={onFollowUser}
              leftIcon={<Icon icon="add" />}
              size="sm"
            >
              {labelFollow}
            </Button>
          )}
          {is_following && onUnfollowUser && (
            <Button
              variant="adminButtonOutlined"
              onClick={onUnfollowUser}
              leftIcon={<Icon icon="close" />}
              size="sm"
            >
              {labelUnfollow}
            </Button>
          )}
        </>
      )}
    </HStack>
  );
};
