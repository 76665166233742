import React, { useState } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import "swiper/modules/a11y/a11y.min.css";
import "swiper/modules/keyboard/keyboard.min.css";
import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/pagination/pagination.min.css";

import { StudentOnboardingModal } from "adminComponents/molecules/StudentOnboardingModal";
import { GuestSignInModal } from "adminComponents/organisms/GuestSignInModal";
import { StudentLoadingScreen } from "adminComponents/screens/StudentLoadingScreen";
import { WaitingScreen } from "adminComponents/screens/WaitingScreen";
import { useAuth } from "links/lib/features/auth";
import AppError from "screens/AppError";
import { StudentAccount } from "screens/StudentAccount";
import { StudentGettingStarted } from "screens/StudentGettingStarted";
import { StudentHome } from "screens/StudentHome";
import { StudentLocker } from "screens/StudentLocker";
import { StudentMyClasses } from "screens/StudentMyClasses";
import { StudentSeasonMap } from "screens/StudentSeasonMap";

import { PlaySessionProvider } from "./contexts/PlaySessionProvider";
import {
  StudentDashboardDataProvider,
  useStudentDashboardData,
} from "./contexts/StudentDashboardDataProvider";
import { StudentNavigationDataProvider } from "./contexts/StudentNavigationDataProvider";

export const StudentDashboard: React.FC = () => {
  const { authUser } = useAuth();

  if (authUser?.is_guest) {
    return <GuestSignInModal isOpen signInLinkTo="/sign-in/student" />;
  }

  return (
    <StudentDashboardDataProvider>
      <StudentDashboardContent />
    </StudentDashboardDataProvider>
  );
};

const StudentDashboardContent: React.FC = () => {
  const history = useHistory();
  const { authUser } = useAuth();
  const { classroomMap, isLoading } = useStudentDashboardData();
  const [loadingScreenComplete, setLoadingScreenComplete] = useState(false);

  const hasClassrooms = !!Object.values(classroomMap).length;

  if (isLoading || !loadingScreenComplete) {
    return (
      <StudentLoadingScreen
        handleComplete={() => setLoadingScreenComplete(true)}
      />
    );
  }

  if (!hasClassrooms) {
    return (
      <WaitingScreen
        email={authUser?.email}
        handleReturnToSignIn={() => {
          history.push("/sign-out");
        }}
      />
    );
  }

  return (
    <PlaySessionProvider>
      <StudentOnboardingModal />
      <StudentNavigationDataProvider>
        <Switch>
          <Route path="/s/account" exact>
            <StudentAccount />
          </Route>
          <Route path="/s/home" exact>
            <StudentHome />
          </Route>
          <Route path="/s/my-classes" exact>
            <StudentMyClasses />
          </Route>
          <Route path="/s/locker/:mode?">
            <StudentLocker />
          </Route>
          <Route path="/s/season-map" exact>
            <StudentSeasonMap />
          </Route>
          <Route path="/s/getting-started" exact>
            <StudentGettingStarted />
          </Route>
          <Route path="/s" exact>
            <Redirect to="/s/home" />
          </Route>
          <Route path="*">
            <AppError code={404} />
          </Route>
        </Switch>
      </StudentNavigationDataProvider>
    </PlaySessionProvider>
  );
};
