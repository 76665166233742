import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { useAxios } from "../../hooks/useAxios";

export interface IExtendUserIndividualTrialProps {
  extensionDays?: number;
  onSuccess?: () => void;
  onError?: () => void;
  onSettled?: () => void;
}

export default function useExtendUserIndividualTrial(
  props?: IExtendUserIndividualTrialProps
): UseMutationResult<unknown, unknown, void> {
  const axios = useAxios();
  const queryClient = useQueryClient();

  const ExtendUserIndividualTrial = async () => {
    await axios({
      method: "post",
      url: "/v1/subscriptions/individual/extension/extend",
      data: { extension_days: props?.extensionDays ?? 30 },
    });

    queryClient.invalidateQueries(["can-extend-individual-trial"]);
  };

  const mutation = useMutation(ExtendUserIndividualTrial, {
    onError: props?.onError,
    onSuccess: props?.onSuccess,
    onSettled: props?.onSettled,
  });

  return mutation;
}
