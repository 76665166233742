import {
  Box,
  Collapse,
  Flex,
  FormControl,
  HStack,
  Link,
  Square,
  SystemProps,
  VStack,
  useBreakpointValue,
  useDisclosure,
  usePrevious,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { find } from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

import { ColorScheme } from "adminComponents";
import { Button } from "adminComponents/atoms/Button";
import { Card } from "adminComponents/atoms/Card";
import { Checkbox } from "adminComponents/atoms/Checkbox";
import { Dropdown, IOption } from "adminComponents/atoms/Dropdown";
import { FormErrorMessage } from "adminComponents/atoms/FormErrorMessage";
import { FormLabel } from "adminComponents/atoms/FormLabel";
import { Icon } from "adminComponents/atoms/Icon";
import { IconTooltip } from "adminComponents/atoms/IconTooltip";
import { Modal } from "adminComponents/atoms/Modal";
import { PremiumTooltipRich } from "adminComponents/atoms/PremiumTooltipRich";
import { Radio } from "adminComponents/atoms/Radio";
import { Tag } from "adminComponents/atoms/Tag";
import { Text } from "adminComponents/atoms/Text";
import { TextLink } from "adminComponents/atoms/TextLink";
import { AdminFlyout } from "adminComponents/molecules/Flyout";
import { isPremiumItem } from "adminComponents/utils/isPremiumItem";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { useAuth } from "links/lib/features/auth";
import {
  IClassroom,
  ICustomSessionGroup,
  IPracticeSetItem,
  IUser,
  PracticeSessionItemVariantType,
  SessionOuterGame,
} from "links/lib/types";
import BigBoardLogo from "sessionComponents/atoms/BigBoardLogo/resource/big_board_logo.png";
import QuickPlayLogo from "sessionComponents/atoms/QuickPlayLogo/resource/quick_play_logo.png";
import { shuffleInPlace } from "sharedComponents/utils/shuffleInPlace";

import { SkipQuestionsModal } from "./components/SkipQuestionsModal";
import { UseCustomSessionGroupsModal } from "./components/UseCustomSessionGroupsModal";

const timesPerQuestion = [
  { value: "30", labelKey: "timePerQuestionOptions.thirtySeconds" },
  { value: "45", labelKey: "timePerQuestionOptions.fortyFiveSeconds" },
  { value: "60", labelKey: "timePerQuestionOptions.sixtySeconds" },
  { value: "90", labelKey: "timePerQuestionOptions.ninetySeconds" },
  { value: "120", labelKey: "timePerQuestionOptions.twoMinutes" },
  { value: "180", labelKey: "timePerQuestionOptions.threeMinutes" },
  { value: "240", labelKey: "timePerQuestionOptions.fourMinutes" },
  { value: "300", labelKey: "timePerQuestionOptions.fiveMinutes" },
  { value: "0", labelKey: "timePerQuestionOptions.noTimeLimit" },
];

const outerGamesMap = {
  [SessionOuterGame.BigBoard]: {
    imageAsset: BigBoardLogo,
    titleKey: "outerGameOptions.bigBoardTitle",
    descriptionKey: "outerGameOptions.bigBoardDescription",
    tagColorScheme: "utility.badge-blue",
    tagKey: "outerGameOptions.bigBoardTag",
    howToPlayLink:
      "https://support.giantsteps.app/s/article/Big-Board-Basics-and-Structure",
  },
  [SessionOuterGame.QuickPlay]: {
    imageAsset: QuickPlayLogo,
    titleKey: "outerGameOptions.quickPlayTitle",
    descriptionKey: "outerGameOptions.quickPlayDescription",
    tagColorScheme: "utility.badge-red",
    tagKey: "outerGameOptions.quickPlayTag",
    howToPlayLink:
      "https://support.giantsteps.app/s/article/Run-Rapid-Rounds-with-Quick-Play",
  },
  [SessionOuterGame.None]: {
    imageAsset: null,
    titleKey: "",
    descriptionKey: "",
    tagColorScheme: "",
    tagKey: "",
    howToPlayLink: "",
  },
};

export interface ISessionFormItem {
  practice_set_item: IPracticeSetItem;
  variant: PracticeSessionItemVariantType;
  is_skipped: boolean;
}

export interface IFormValues {
  allowAnonymousStudents: boolean;
  practiceSetId: string;
  classroomId: string;
  outerGame: SessionOuterGame;
  generateQuestionsFromTerms: boolean;
  allowStudentNames: boolean;
  secondsPerQuestion: number;
  enableCoopDraw: boolean;
  randomizeOrder: boolean;
  sessionItems: Array<ISessionFormItem>;
  useCustomSessionGroups: boolean;
}

export interface ITeacherSessionCreateProps {
  isOpen: boolean;
  classrooms?: Array<IClassroom>;
  practiceSetItems?: Array<IPracticeSetItem>;
  sessionPreviewItems?: Array<{
    practice_set_item: IPracticeSetItem;
    variant: PracticeSessionItemVariantType;
  }>;
  practiceSetId: string;
  practiceSetCreatedById: string;
  classroomId?: string | null;
  handleClose: () => void;
  handleSubmit?: (values: IFormValues) => void;
  isLoading?: boolean;
  customSessionGroups?: ICustomSessionGroup[];
  classroomUsers?: IUser[];
  setCurrentClassroomId: (classroomId: string) => void;
  // If true, display the form in a more compact, modal version.
  // All settings except for classroom selection will be collapsed
  // under "advanced options" in this case. If false, the original
  // UI of using a drawer/flyout will be used.
  useModal?: boolean;
}

export const TeacherSessionCreate: React.FC<ITeacherSessionCreateProps> = ({
  isOpen,
  classrooms = [],
  practiceSetItems = [],
  sessionPreviewItems = [],
  practiceSetId,
  practiceSetCreatedById,
  classroomId,
  handleClose,
  handleSubmit,
  isLoading = false,
  customSessionGroups,
  classroomUsers,
  setCurrentClassroomId,
  useModal = false,
}) => {
  const history = useHistory();
  const { authUser, hasNoPremiumAccess } = useAuth();
  const { t: tCommon } = useTranslation("admin", {
    keyPrefix: "common",
    useSuspense: false,
  });
  const { t } = useTranslation("admin", {
    keyPrefix: "startSession",
    useSuspense: false,
  });
  const {
    isOpen: isSkipQuestionsModalOpen,
    onOpen: onSkipQuestionsModalOpen,
    onClose: onSkipQuestionsModalClose,
  } = useDisclosure();

  const disallowStartAllPremiumItems =
    sessionPreviewItems.length === 0 &&
    practiceSetItems.every(isPremiumItem) &&
    hasNoPremiumAccess;

  const [canEditAllowAnonymousStudents, setCanEditAllowAnonymousStudents] =
    useState(true);

  const {
    isOpen: isCustomSessionGroupsModalOpen,
    onClose: onCloseCustomSessionGroupsModal,
    onOpen: onOpenCustomSessionGroupsModal,
  } = useDisclosure();

  const validCustomSessionGroups = useMemo(() => {
    if (!customSessionGroups) return false;

    return customSessionGroups?.some(
      (customSessionGroup) => customSessionGroup.user_ids.length > 1
    );
  }, [customSessionGroups]);

  const initialValues: IFormValues = {
    allowAnonymousStudents: true,
    practiceSetId,
    classroomId: "",
    outerGame: SessionOuterGame.QuickPlay,
    generateQuestionsFromTerms: true,
    allowStudentNames: true,
    secondsPerQuestion: 60,
    enableCoopDraw: true,
    randomizeOrder: false,
    sessionItems: [],
    useCustomSessionGroups: false,
  };

  const invalidSecondsError = t("errors.invalidSecondsPerQuestion", {
    minSeconds: 0,
    maxSeconds: 1800,
  });

  const formik = useFormik({
    initialValues,
    validateOnMount: false,
    validationSchema: Yup.object({
      practiceSetId: Yup.string()
        .trim()
        .required(t("errors.invalidPracticeSet")),
      classroomId: Yup.string().trim().required(t("errors.invalidClassroom")),
      secondsPerQuestion: Yup.number()
        .positive()
        .min(0, invalidSecondsError)
        .max(1800, invalidSecondsError)
        .required(invalidSecondsError),
      allowAnonymousStudents: Yup.boolean().when("classroomId", {
        is: (classroomId: string) =>
          (classrooms.find((classroom) => classroom.id === classroomId)
            ?.students_count || 0) < 1 || false,
        then: Yup.boolean().oneOf(
          [true],
          t("errors.invalidClassroomNoStudentsNoGuests")
        ),
      }),
      sessionItems: Yup.array().of(
        Yup.object().shape({
          practice_set_item: Yup.object().shape({
            id: Yup.string(),
            audio_url: Yup.string(),
            created_by: Yup.string(),
            diagram_labels: Yup.array().of(
              Yup.object() // <IDiagramLabel>
            ),
            feedback: Yup.string(),
            feedback_image_url: Yup.string(),
            feedback_image_alt_text: Yup.string(),
            feedback_reference_materials: Yup.array().of(
              Yup.object().shape({
                id: Yup.string(),
                reference_material: Yup.object(),
              })
            ),
            video_url: Yup.string(),
            image_url: Yup.string(),
            image_alt_text: Yup.string(),
            is_generated: Yup.boolean(),
            is_higher_order_thinking: Yup.boolean(),
            multiple_choice: Yup.object()
              .nullable()
              .shape({
                id: Yup.string(),
                practice_set_item_id: Yup.string(),
                prompt: Yup.string(),
                choices: Yup.array().of(
                  Yup.object().shape({
                    id: Yup.string().nullable(),
                    practice_set_item_id: Yup.string().nullable(),
                    text: Yup.string(),
                    is_correct: Yup.boolean(),
                    image_url: Yup.string().nullable(),
                    image_alt_text: Yup.string().nullable(),
                  })
                ), // <IMultipleChoiceChoice>;
              }),
            multiple_select: Yup.object()
              .nullable()
              .shape({
                id: Yup.string(),
                practice_set_item_id: Yup.string(),
                prompt: Yup.string(),
                choices: Yup.array().of(
                  Yup.object().shape({
                    id: Yup.string().nullable(),
                    practice_set_item_id: Yup.string().nullable(),
                    text: Yup.string(),
                    is_correct: Yup.boolean(),
                    image_url: Yup.string().nullable(),
                    image_alt_text: Yup.string().nullable(),
                  })
                ), // <IMultipleChoiceChoice>;
              }),
            open_ended: Yup.object().nullable().shape({
              id: Yup.string(),
              practice_set_item_id: Yup.string(),
              prompt: Yup.string(),
            }),
            order: Yup.number(),
            practice_set_id: Yup.string(),
            question_type: Yup.string(),
            standards: Yup.array().of(Yup.object()), // <IPracticeSetItemStandard>;
            tags: Yup.array().of(
              Yup.object().shape({
                id: Yup.string(),
                tag: Yup.object().shape({}),
              })
            ),
            term_definition: Yup.object().nullable().shape({
              id: Yup.string(),
              practice_set_item_id: Yup.string(),
              term: Yup.string(),
              definition: Yup.string(),
            }),
            text_response: Yup.object().nullable().shape({
              id: Yup.string(),
              practice_set_item_id: Yup.string(),
              prompt: Yup.string(),
              correct_response: Yup.string(),
              enable_partial_credit: Yup.boolean(),
            }),
            true_false: Yup.object().nullable().shape({
              id: Yup.string(),
              practice_set_item_id: Yup.string(),
              prompt: Yup.string(),
              correct_answer: Yup.boolean(),
            }),
            version: Yup.string(),
            alternate_text_responses: Yup.array().of(Yup.object()), // <IAlternateTextResponse>;
          }),
          variant: Yup.string().trim(),
          is_skipped: Yup.bool(),
        })
      ),
    }),
    onSubmit: (values) => {
      handleSubmit && handleSubmit(values);
    },
  });

  useEffect(() => {
    setCurrentClassroomId(formik.values.classroomId);
  }, [formik.values.classroomId, setCurrentClassroomId]);

  useEffect(() => {
    if (!customSessionGroups || customSessionGroups?.length < 1) {
      setFieldValue.current("useCustomSessionGroups", false);
    }
  }, [customSessionGroups]);

  const setFieldValue = useRef(formik.setFieldValue);

  // check if this was already open
  const lastIsOpen = usePrevious(isOpen);

  // updates form initial values on open/re-open
  useEffect(() => {
    if (!lastIsOpen && isOpen) {
      formik.handleReset(null);
      if (classroomId) {
        setFieldValue.current("classroomId", classroomId);
      } else if (classrooms.length === 1) {
        setFieldValue.current("classroomId", classrooms[0].id);
        if (classrooms[0].students_count === 0) {
          setFieldValue.current("allowAnonymousStudents", true);
          setCanEditAllowAnonymousStudents(false);
        }
      }
    }
  }, [
    formik,
    setFieldValue,
    isOpen,
    lastIsOpen,
    classroomId,
    classrooms,
    sessionPreviewItems,
  ]);

  useEffect(() => {
    if (
      sessionPreviewItems.length > 0 &&
      formik.values.sessionItems.length < 1
    ) {
      setFieldValue.current("sessionItems", sessionPreviewItems);
    }
  }, [sessionPreviewItems, formik.values.sessionItems.length]);

  const noClassroomsContent = (
    <AdminFlyout isOpen={isOpen} onClose={handleClose} title={t("heading")}>
      <VStack maxW="80%" m="0 auto">
        <Text textStyle="adminH5">{t("noClassroomsHeading")}</Text>
        <Text textStyle="adminP2">
          {t("noClassroomsDescription")}{" "}
          <TextLink
            textStyle="adminP1"
            navigationLink={{
              label: t("createClassroomLinkText"),
              to: "/t/classrooms",
            }}
          >
            {t("createClassroomLinkText")}
          </TextLink>
        </Text>
      </VStack>
    </AdminFlyout>
  );

  const handleToggleRandomizeOrder = (e: { target: { checked: boolean } }) => {
    if (e.target.checked) {
      shuffleInPlace(formik.values.sessionItems);
    } else {
      formik.values.sessionItems.sort((a, b) =>
        a.practice_set_item.order < b.practice_set_item.order ? -1 : 1
      );
    }
    formik.handleChange(e);
  };

  const handleToggleSkipItem = (item: IPracticeSetItem) => {
    const items = [...formik.values.sessionItems];
    items.forEach((i) => {
      if (i.practice_set_item.id === item.id) {
        i.is_skipped = !i.is_skipped;
      }
    });

    formik.setFieldValue("sessionItems", items);
  };

  const unskippedItems = useMemo(() => {
    return formik.values.sessionItems.filter((item) => !item.is_skipped);
  }, [formik.values.sessionItems]);

  const unskippedLengthTimeCalcValue = unskippedItems.length
    ? unskippedItems.length
    : 1;

  const bigBoardApproxTotalTime =
    formik.values.secondsPerQuestion > 0
      ? Math.ceil(
          ((unskippedLengthTimeCalcValue * formik.values.secondsPerQuestion) /
            60) *
            1.5
        )
      : 0;

  const quickPlayApproxTotalTime =
    formik.values.secondsPerQuestion > 0
      ? Math.ceil(
          (unskippedLengthTimeCalcValue * formik.values.secondsPerQuestion) / 60
        )
      : 0;

  const outerGameCardDirection: SystemProps["flexDirection"] =
    useBreakpointValue({
      base: "column",
      md: useModal ? "column" : "row",
    });
  const outerGameCardAlignItems: AlignSetting =
    useBreakpointValue({
      base: "center",
      md: useModal ? "center" : "start",
    }) || "center";

  const outerGameCard = (outerGame: SessionOuterGame) => {
    const {
      imageAsset,
      titleKey,
      descriptionKey,
      tagColorScheme,
      tagKey,
      howToPlayLink,
    } = outerGamesMap[outerGame];
    const approxTotalTime =
      outerGame === SessionOuterGame.BigBoard
        ? bigBoardApproxTotalTime
        : quickPlayApproxTotalTime;

    return (
      <Card
        key={outerGame.toString()}
        borderColor="primary.golden"
        p={pxToRem(20)}
        variant={
          formik.values.outerGame === outerGame
            ? "adminCardSmallBorder"
            : "adminCardSelectable"
        }
        noPadding
        onClick={() => handleOuterGameChange(outerGame)}
      >
        <Radio
          position="absolute"
          isChecked={formik.values.outerGame === outerGame}
        />
        <Tag
          colorScheme={tagColorScheme as ColorScheme}
          position="absolute"
          right={pxToRem(20)}
        >
          {t(tagKey)}
        </Tag>
        <Flex
          gap={pxToRem(20)}
          alignItems={outerGameCardAlignItems}
          flexDir={outerGameCardDirection}
        >
          <Box
            mt={pxToRem(20)}
            boxSize={pxToRem(150)}
            backgroundImage={imageAsset ?? ""}
            backgroundColor={imageAsset ? undefined : "primary.light-gray"}
            backgroundSize="contain"
            backgroundRepeat="no-repeat"
            backgroundPosition="center"
            flexShrink={0}
          />
          <Flex flexDir="column" gap={pxToRem(12)}>
            <Text display="flex">
              {t(titleKey)}
              {useModal && (
                <Box alignSelf="center" ml={pxToRem(8)}>
                  <IconTooltip>{t(descriptionKey)}</IconTooltip>
                </Box>
              )}
            </Text>
            {!useModal && <Text variant="adminP2">{t(descriptionKey)}</Text>}
            <Flex
              flexDir={outerGameCardDirection}
              alignItems={outerGameCardAlignItems}
              justifyContent="space-between"
            >
              <Tag
                borderColor="primary.tan"
                variant="adminOutline"
                tooltip={t("totalTimeTooltip")}
                w="fit-content"
              >
                {approxTotalTime > 0
                  ? t("approxTotalTime", {
                      count: approxTotalTime,
                    })
                  : t("noTimeLimit")}
              </Tag>
              {howToPlayLink && !useModal ? (
                <Link
                  href={howToPlayLink}
                  isExternal
                  color="utility.link"
                  style={{ textDecoration: "underline" }}
                  target="_blank"
                  fontSize={pxToRem(16)}
                >
                  {t("linkHowToPlay")}
                </Link>
              ) : (
                <></>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Card>
    );
  };

  const availableOuterGames = [
    SessionOuterGame.QuickPlay,
    SessionOuterGame.BigBoard,
  ];
  const formControlPaddingY = pxToRem(12);

  const handleClassroomChange = (option: IOption | unknown) => {
    const classroomId = (option as IOption).value;
    formik.setFieldValue("classroomId", classroomId);
    const classroom = classrooms.find((c) => c.id === classroomId);
    if (classroom && classroom.students_count === 0) {
      formik.setFieldValue("allowAnonymousStudents", true);
      setCanEditAllowAnonymousStudents(false);
    } else {
      setCanEditAllowAnonymousStudents(true);
    }
    // Ugly hack, but since we're using chakra-react-select and formik, the former
    // not providing an event to the Dropdown onChange callback and the latter
    // expecting one to work properly, we have to update the field and then LATER
    // trick formik into validating the form again.
    setTimeout(() => formik.setFieldTouched("classroomId"), 0);
  };

  const handleOuterGameChange = (outerGame: SessionOuterGame) => {
    formik.setFieldValue("outerGame", outerGame);
  };

  const handleSecondsPerQuestionChange = (option: IOption | unknown) => {
    const secondsPerQuestion = (option as IOption).value;
    formik.setFieldValue("secondsPerQuestion", parseInt(secondsPerQuestion));
  };

  const selectedClassroom = useMemo(() => {
    return classrooms.find((c) => c.id === formik.values.classroomId);
  }, [classrooms, formik.values.classroomId]);

  const [advancedSettingsExpanded, setAdvancedSettingsExpanded] =
    useState(false);

  if (!classrooms.length) {
    return noClassroomsContent;
  }

  const isSharedSet =
    !!practiceSetCreatedById && practiceSetCreatedById !== authUser?.id;

  const advancedOptions = (
    <>
      <Flex gap={pxToRem(10)} paddingTop={pxToRem(15)}>
        <Tag>
          {formik.values.useCustomSessionGroups
            ? t("useCustomSessionGroups.studentGroups")
            : t("useCustomSessionGroups.randomGroups")}
        </Tag>
        {validCustomSessionGroups && (
          <Square>
            <TextLink handleClick={onOpenCustomSessionGroupsModal}>
              {t("useCustomSessionGroups.editSetting")}
            </TextLink>
          </Square>
        )}
      </Flex>
      <FormControl
        py={formControlPaddingY}
        isInvalid={
          formik.values.classroomId !== "" &&
          !!formik.errors.allowAnonymousStudents
        }
      >
        <Checkbox
          id="allowAnonymousStudents"
          name="allowAnonymousStudents"
          isChecked={formik.values.allowAnonymousStudents}
          onChange={formik.handleChange}
          isDisabled={!canEditAllowAnonymousStudents}
        >
          <HStack>
            <FormLabel my="auto" mr="0" htmlFor="allowAnonymousStudents">
              {t("allowAnonymousStudentsLabel")}
            </FormLabel>
            <IconTooltip>
              {t("allowAnonymousStudentsTooltip1")}
              <br />
              <br />
              {t("allowAnonymousStudentsTooltip2")}
            </IconTooltip>
          </HStack>
        </Checkbox>
        {!canEditAllowAnonymousStudents && (
          <Text
            fontSize={pxToRem(16)}
            color="primary.dark-gray"
            ml={pxToRem(32)}
            lineHeight={pxToRem(21)}
          >
            <b>{t("forceAllowAnonymousStudentsDescription1")}</b>{" "}
            <TextLink
              navigationLink={{
                label: t("addStudents"),
                to: `/t/classrooms/${formik.values.classroomId}/students`,
                isExternal: false,
              }}
            >
              {t("addStudents")}
            </TextLink>{" "}
            {t("forceAllowAnonymousStudentsDescription2")}
          </Text>
        )}
      </FormControl>
      <FormControl
        py={formControlPaddingY}
        isInvalid={
          formik.touched.secondsPerQuestion &&
          !!formik.errors.secondsPerQuestion
        }
      >
        <FormLabel htmlFor="secondsPerQuestion" id="secondsPerQuestionLabel">
          <HStack>
            <Text>{t("secondsPerQuestionLabel")}</Text>
            <IconTooltip>{t("secondsPerQuestionTooltip")}</IconTooltip>
          </HStack>
        </FormLabel>
        <Dropdown
          aria-labelled-by="secondsPerQuestionLabel"
          id="secondsPerQuestion"
          value={[
            {
              label: t(
                find(timesPerQuestion, {
                  value: formik.values.secondsPerQuestion.toString(),
                })?.labelKey || ""
              ),
              value: formik.values.secondsPerQuestion.toString(),
            },
          ]}
          handleChange={handleSecondsPerQuestionChange}
          options={timesPerQuestion.map((time) => ({
            label: t(time.labelKey),
            value: time.value,
          }))}
        />
        <FormErrorMessage>
          {formik.touched.secondsPerQuestion &&
            formik.errors.secondsPerQuestion}
        </FormErrorMessage>
      </FormControl>
      <FormLabel
        paddingTop={{
          base: pxToRem(10),
          sm: pxToRem(15),
          md: pxToRem(18),
        }}
      >
        {t("additionalOptionsLabel")}
      </FormLabel>
      <FormControl py={formControlPaddingY}>
        <Checkbox
          id="randomizeOrder"
          name="randomizeOrder"
          isChecked={formik.values.randomizeOrder}
          onChange={handleToggleRandomizeOrder}
        >
          <FormLabel my="auto" htmlFor="randomizeOrder">
            {t("randomizeOrderLabel")}
          </FormLabel>
        </Checkbox>
      </FormControl>
      <FormControl py={formControlPaddingY}>
        <Checkbox
          id="skipQuestions"
          name="skipQuestions"
          isChecked={unskippedItems.length < sessionPreviewItems.length}
          onChange={onSkipQuestionsModalOpen}
        >
          <FormLabel my="auto" htmlFor="skipQuestions">
            <HStack>
              <Text
                color="utility.link"
                cursor="pointer"
                textDecoration="underline"
              >
                {t("skipLabel")}
              </Text>
              {unskippedItems.length < practiceSetItems.length && (
                <Text>
                  ({unskippedItems.length}/{practiceSetItems.length})
                </Text>
              )}
            </HStack>
          </FormLabel>
        </Checkbox>
      </FormControl>

      {isSharedSet && (
        <Tag mt={pxToRem(24)} p={pxToRem(24)} variant="adminSolid" w="full">
          {t("practiceSetWillBeCopiedWarning")}
        </Tag>
      )}
    </>
  );

  const formContent = (
    <Flex w="100%" px={useModal ? 0 : pxToRem(58)}>
      <form style={{ width: "100%" }} onSubmit={formik.handleSubmit}>
        <FormControl
          py={formControlPaddingY}
          isInvalid={formik.touched.outerGame && !!formik.errors.outerGame}
        >
          <FormLabel htmlFor="outerGame">
            <Text>{t("labelSelectGameMode")}</Text>
          </FormLabel>
          <Flex w="full" flexDir={useModal ? "row" : "column"} gap={pxToRem(8)}>
            {availableOuterGames.map(outerGameCard)}
          </Flex>
          <FormErrorMessage>
            {formik.touched.outerGame && formik.errors.outerGame}
          </FormErrorMessage>
        </FormControl>
        <FormControl
          py={formControlPaddingY}
          isInvalid={formik.touched.classroomId && !!formik.errors.classroomId}
        >
          <FormLabel htmlFor="classroomId" id="classroomsLabel">
            {t("classroomsLabel")}
            {tCommon("requiredAsterisk")}
          </FormLabel>
          <Box width="100%">
            <Dropdown
              aria-labelled-by="classroomsLabel"
              placeholder={t("selectClassroomPlaceholder")}
              id="classroomId"
              options={classrooms.map(({ name, id }) => ({
                label: name,
                value: id,
              }))}
              handleChange={handleClassroomChange}
              value={
                formik.values.classroomId
                  ? [
                      {
                        label:
                          find(classrooms, {
                            id: formik.values.classroomId,
                          })?.name || "",
                        value: formik.values.classroomId,
                      },
                    ]
                  : undefined
              }
            />
          </Box>
          <FormErrorMessage>
            {formik.touched.classroomId && formik.errors.classroomId}
          </FormErrorMessage>
          {selectedClassroom && selectedClassroom.students_count === 0 && (
            <Box color="utility.error" display="inline" fontSize={pxToRem(12)}>
              {t("errors.warningClassroomNoStudents1")}
              <Text
                color="utility.error"
                cursor="pointer"
                display="inline"
                textDecoration="underline"
                onClick={() =>
                  history.push(
                    `/t/classrooms/${selectedClassroom?.id}/students`
                  )
                }
              >
                {t("errors.warningClassroomNoStudents2")}
              </Text>
              {t("errors.warningClassroomNoStudents3")}
            </Box>
          )}
        </FormControl>
        {useModal && (
          <Flex flexDirection="column">
            <Flex
              borderRadius="md"
              cursor="pointer"
              justifyContent="start"
              py={pxToRem(6)}
              onClick={() =>
                setAdvancedSettingsExpanded(!advancedSettingsExpanded)
              }
            >
              <FormLabel cursor="pointer" my="auto">
                <Text as="span" color="utility.link">
                  {tCommon("advancedOptions")}
                </Text>
              </FormLabel>
              <Icon
                alignSelf="center"
                mr={pxToRem(6)}
                boxSize={pxToRem(18)}
                icon={
                  advancedSettingsExpanded
                    ? "keyboard_arrow_up"
                    : "keyboard_arrow_down"
                }
              />
            </Flex>
            <Collapse
              in={advancedSettingsExpanded}
              style={{
                overflow: "visible",
              }}
            >
              {advancedOptions}
            </Collapse>
          </Flex>
        )}
        {!useModal && advancedOptions}
        <FormControl py={formControlPaddingY} paddingTop={pxToRem(40)}>
          <PremiumTooltipRich
            isDisabled={!disallowStartAllPremiumItems}
            overrideText={t("premiumTooltipOverrideText")}
          >
            <Flex alignItems="center" w="full" gap={pxToRem(10)}>
              {useModal && (
                <Button
                  variant="adminButtonOutlined"
                  w="20%"
                  onClick={handleClose}
                >
                  {tCommon("cancel")}
                </Button>
              )}
              <Button
                isLoading={isLoading}
                disabled={
                  !formik.isValid ||
                  !unskippedItems.length ||
                  disallowStartAllPremiumItems
                }
                variant="adminButtonFilled"
                size="lg"
                width={useModal ? "75%" : "100%"}
                type="submit"
              >
                {t("submitButton")}
              </Button>
            </Flex>
          </PremiumTooltipRich>
        </FormControl>
      </form>
    </Flex>
  );

  return (
    <>
      {useModal ? (
        <Modal
          showBackButton={false}
          isOpen={isOpen}
          onClose={handleClose}
          title={t("heading")}
          headingProps={{
            variant: "adminH4",
            as: "h1",
          }}
        >
          {formContent}
        </Modal>
      ) : (
        <AdminFlyout isOpen={isOpen} onClose={handleClose} title={t("heading")}>
          {formContent}
        </AdminFlyout>
      )}
      <SkipQuestionsModal
        isOpen={isSkipQuestionsModalOpen}
        onClose={onSkipQuestionsModalClose}
        unskippedSessionItems={unskippedItems}
        sessionItems={practiceSetItems}
        toggleSkipItem={handleToggleSkipItem}
      />
      <UseCustomSessionGroupsModal
        isOpen={isCustomSessionGroupsModalOpen}
        onClose={onCloseCustomSessionGroupsModal}
        setUseSessionGroups={(useCustomSesssionGroups: boolean) =>
          formik.setFieldValue(
            "useCustomSessionGroups",
            useCustomSesssionGroups
          )
        }
        useSessionGroups={formik.values.useCustomSessionGroups}
        customSessionGroups={customSessionGroups}
        classroomUsers={classroomUsers}
      />
    </>
  );
};
