import { ColorScheme } from "adminComponents";
import { pxToRem } from "adminComponents/utils/pxToRem";

export const AdminProgress = {
  baseStyle: ({
    fill,
    hasIcon,
    iconBorderColor,
    iconBorderWidthPx,
  }: {
    fill?: Keyframe;
    hasIcon?: boolean;
    iconBorderColor?: ColorScheme;
    iconBorderWidthPx?: number;
  }): Record<string, unknown> => ({
    track: {
      width: "100%",
      bg: "primary.light-gray",
      borderLeftRadius: pxToRem(100),
      borderRightRadius: hasIcon ? "0" : pxToRem(100),
      div: {
        animation: `1s ${fill}`,
      },
    },
    iconContainer: {
      border: `${iconBorderWidthPx || 2}px solid`,
      borderColor: iconBorderColor || "primary.white",
      borderRadius: "100%",
      display: "flex",
      transform: "translateX(-5px)",
    },
  }),
  variants: {
    adminStudentGoal: {
      track: {
        div: {
          bg: "utility.focus",
        },
      },
    },
    adminStudentMonthlyChallenge: {
      track: {
        div: {
          bg: "utility.orange",
        },
      },
    },
    adminTeacherQuestionCorrect: {
      track: {
        div: {
          bg: "utility.question-green",
        },
      },
    },
    adminTeacherQuestionIncorrect: {
      track: {
        div: {
          bg: "primary.dark-gray",
        },
      },
    },
    adminLevelProgress: {
      track: {
        height: { base: pxToRem(32), md: pxToRem(40) },
        div: {
          bg: "utility.focus",
        },
      },
    },
    adminStriped: {
      track: {
        div: {
          bg: "utility.focus",
          backgroundImage: `linear-gradient(45deg, rgba(255, 255, 255, 0.3) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0.3) 75%, transparent 75%, transparent)`,
          backgroundSize: `var(--stripe-size) var(--stripe-size)`,
          "--stripe-size": "1rem",
          "--stripe-color": "rgba(255, 255, 255, 0.3)",
        },
      },
    },
  },
};
