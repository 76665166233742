import { Box, Flex } from "@chakra-ui/react";
import React from "react";

import { Divider } from "adminComponents/atoms/Divider";
import { Text } from "adminComponents/atoms/Text";
import { StudentAnswerResults } from "adminComponents/molecules/StudentAnswerResults";
import { pxToRem } from "adminComponents/utils/pxToRem";
import {
  IDiagramLabel,
  ISessionItemResponse,
  QuestionType,
} from "links/lib/types";

export interface IProps {
  studentName: string;
  resultsList: ISessionItemResponse[];
  correctResponses: IDiagramLabel[];
  questionType: QuestionType;
}

export const StudentDiagramAnswerResults: React.FC<IProps> = ({
  studentName,
  resultsList,
  correctResponses,
  questionType,
}) => {
  return (
    <Flex direction="column" gap={{ base: pxToRem(24), md: pxToRem(28) }}>
      {correctResponses.map((label, index) => {
        const studentAnswer = resultsList.find(
          (result) => result.zone_id === label.id
        );
        return (
          <Box key={label.id}>
            <Flex direction="column" gap={{ base: pxToRem(4), md: pxToRem(8) }}>
              <Text variant="adminP1Bold">{index + 1}.</Text>
              <StudentAnswerResults
                studentName={studentName}
                answers={studentAnswer ? [studentAnswer] : []}
                correctAnswers={[
                  {
                    text: label.label,
                    id: label.id || "",
                  },
                ]}
                questionType={questionType}
              />
            </Flex>
            {resultsList.length > index + 1 && (
              <Divider color="primary.light-gray" />
            )}
          </Box>
        );
      })}
    </Flex>
  );
};
