import { HStack, VStack } from "@chakra-ui/react";
import React from "react";

import { Card } from "adminComponents/atoms/Card";
import { Text } from "adminComponents/atoms/Text";
import { ISkill } from "links/lib/types";

export interface IProps {
  skill: ISkill;
  onClick: () => void;
}

export const SkillCard: React.FC<IProps> = ({ skill, onClick }) => {
  return (
    <Card
      borderColor="primary.tan"
      backgroundColor="primary.white"
      variant="adminCardMediumBorder"
      onClick={onClick}
      cursor="pointer"
    >
      <VStack>
        <HStack>
          <Text>ID:</Text>
          <Text>{skill.id}</Text>
        </HStack>
        <HStack>
          <Text>Code:</Text>
          <Text>{skill.code}</Text>
        </HStack>
        <HStack>
          <Text>Description:</Text>
          <Text>{skill.description}</Text>
        </HStack>
        <HStack>
          <Text>Skill Type:</Text>
          <Text>{skill.skill_type}</Text>
        </HStack>
        <HStack>
          <Text>Preceding Skills:</Text>
          <Text>
            {skill.preceding_skills
              ?.map((pSkill) => `ID: ${pSkill?.id} - ${pSkill?.code}`)
              .join(", ")}
          </Text>
        </HStack>
        <HStack>
          <Text>Standards:</Text>
          <Text>
            {skill.standards
              ?.map((standard) => `ID: ${standard?.id} - ${standard?.label}`)
              .join(", ")}
          </Text>
        </HStack>
      </VStack>
    </Card>
  );
};
