import { Box, Center, Select, Spinner } from "@chakra-ui/react";
import React, { useMemo, useState } from "react";

import { AvatarAnimations } from "links/lib/constants";
import { useFetchLiveAvatar } from "links/lib/features/avatars";
import { AvatarSkeletonType, IAvatarItem } from "links/lib/types";
import {
  AvatarStage,
  AvatarStageMember,
} from "sharedComponents/molecules/AvatarStage";

type AvatarPreviewProps = {
  item: IAvatarItem;
  spine_config_seated: string;
  spine_config_standing: string;
  skeleton_type: AvatarSkeletonType;
};

export const AvatarPreview: React.FC<AvatarPreviewProps> = ({
  item,
  spine_config_seated,
  spine_config_standing,
  skeleton_type,
}: AvatarPreviewProps) => {
  const [showAvatar, setShowAvatar] = useState(false);

  const fetchLiveAvatar = useFetchLiveAvatar({
    item,
    spine_config_seated,
    spine_config_standing,
    skeleton_type,
    onSuccess: () => setShowAvatar(true),
  });

  const [animation, setAnimation] = useState<string>("victory_05");

  const onAnimationChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setAnimation(e.target.value);
  };

  const animationOptions = useMemo(
    () =>
      Object.values(AvatarAnimations)
        .flatMap((v) => v)
        .sort((a, b) => (a.name < b.name ? -1 : 1)),
    []
  );

  return (
    <Box>
      <Box h="400px" w="400px">
        {showAvatar ? (
          <AvatarStage
            stageSize={{ x: 400, y: 400 }}
            camera={{ zoom: 2, x: 0, y: 0, z: 0 }}
          >
            <AvatarStageMember
              animation={{ name: animation, loop: true }}
              scale={0.6}
              x={0}
              y={-340}
              atlasUrl={fetchLiveAvatar.data?.spine_atlas_url || ""}
              skeletonUrl={fetchLiveAvatar.data?.spine_json_url || ""}
              drawOrder={2}
            />
          </AvatarStage>
        ) : (
          <Center>
            <Spinner mt="200px" />
          </Center>
        )}
      </Box>
      <Select value={animation} onChange={onAnimationChange}>
        {animationOptions.map((o) => (
          <option key={o.name} value={o.name}>
            {o.name}
          </option>
        ))}
      </Select>
    </Box>
  );
};
