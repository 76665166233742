import React, { createContext, useContext, useMemo, useState } from "react";

import { useSessionActions } from "links/lib/contexts/sessionActions";
import { useSessionEvents } from "links/lib/contexts/sessionEvents";
import { useSessionGameState } from "links/lib/contexts/sessionGameState";
import { useSessionUsers } from "links/lib/contexts/sessionUsers";
import {
  AnalyticsEvent,
  ISessionUser,
  SessionChatMessage,
} from "links/lib/types";
import { getAssetColorScheme } from "sessionComponents/utils/getAssetColorScheme";
import { useSessionAnalytics } from "sharedComponents/contexts/sessionAnalytics";

import { StickerSelectionModal } from "../molecules/StickerSelectionModal";
import { StudentStickerPopup } from "../molecules/StudentStickerPopup";

export interface IStudentStickersContext {
  showStickerSelectionModal: (show: boolean) => void;
}

export const StudentStickersContext = createContext<IStudentStickersContext>({
  showStickerSelectionModal: () => {
    //do nothing
  },
});

export const useStudentStickers = (): IStudentStickersContext => {
  return useContext(StudentStickersContext);
};

export const StudentStickersProvider: React.FC<{
  student?: ISessionUser;
  mLifetimeMS?: number;
}> = ({ student, children, mLifetimeMS }) => {
  const users = useSessionUsers();
  const { sendChatMessage } = useSessionActions();
  const events = useSessionEvents();
  const gameState = useSessionGameState();

  const [showStickerSelectionModal, setShowStickerSelectionModal] =
    useState(false);

  const value = useMemo(
    () => ({ showStickerSelectionModal: setShowStickerSelectionModal }),
    []
  );

  const { track } = useSessionAnalytics();

  const handleSendMsgWithAnalytics = (msg: SessionChatMessage) => {
    sendChatMessage(msg);
    track(AnalyticsEvent.Session_Common_SendChatMessage, {
      chat_message: msg,
    });
  };

  return (
    <StudentStickersContext.Provider value={value}>
      {children}
      {showStickerSelectionModal && student && (
        <StickerSelectionModal
          outerGame={gameState?.game_type}
          visible={showStickerSelectionModal}
          onClose={() => setShowStickerSelectionModal(false)}
          userColorScheme={getAssetColorScheme(student.color_scheme)}
          userName={student.name}
          userProfileImageUrl={student.profile_image_url}
          sendChatMessage={handleSendMsgWithAnalytics}
        />
      )}
      {events && (
        <StudentStickerPopup
          users={users}
          events={events}
          messageLifetime={mLifetimeMS}
        />
      )}
    </StudentStickersContext.Provider>
  );
};
