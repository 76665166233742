import { Box, Flex, Text, VStack } from "@chakra-ui/react";
import React from "react";

import { ISessionDraggableSortItem, ISessionUser } from "links/lib/types";
import { pxToRem } from "sessionComponents/../adminComponents/utils/pxToRem";
import { Button } from "sessionComponents/atoms/Button";
import { Icon } from "sessionComponents/atoms/Icon";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { useRichTextToSpeakable } from "sessionComponents/hooks/useRichTextToSpeakable";
import { useTextReader } from "sessionComponents/hooks/useTextReader";
import { RichTextRenderer } from "sharedComponents/atoms/RichTextRenderer";

import { ClassifyDropZone } from "../ClassifyDropZone";

export type Zone = {
  items: ISessionDraggableSortItem[];
  zoneId: string;
  title: string;
};

interface IClassificationQuestionGroupProps {
  prompt: string;
  zones: Zone[];
  onDragStop: (labelId: string, zoneId: string) => void;
  groupUsers: ISessionUser[];
  onLabelMove?: (labelId: string, zoneId: string) => void;
}

export const ClassificationQuestionGroup: React.FC<
  IClassificationQuestionGroupProps
> = ({ prompt, zones, onDragStop, groupUsers, onLabelMove }) => {
  const { match: currentBreakpoints } = useBreakpoints();

  const { handleSpeak, handleCancelSpeak, isSpeaking, isSpeechEnabled } =
    useTextReader();
  const { convert } = useRichTextToSpeakable();

  const onSpeechClick = () => {
    if (isSpeaking()) {
      handleCancelSpeak();
      return;
    }

    const speakablePrompt = prompt ? convert(prompt) : "";

    handleSpeak(speakablePrompt);
  };

  return (
    <VStack
      w="full"
      h="full"
      overflow="auto"
      padding={pxToRem(currentBreakpoints.padding)}
      spacing={pxToRem(currentBreakpoints.margin)}
      alignSelf="flex-start"
    >
      <Flex>
        <Text
          as="div"
          fontSize={pxToRem(currentBreakpoints.fontSize)}
          textStyle="gameText"
          textAlign="center"
          maxWidth="full"
        >
          {prompt && <RichTextRenderer content={prompt} />}
        </Text>
        {isSpeechEnabled && (
          <Box
            marginLeft={pxToRem(8)}
            display="flex"
            justifyContent="flex-end"
            h={pxToRem(currentBreakpoints.iconSize)}
          >
            <Button
              onClick={onSpeechClick}
              variant="buttonFilled"
              borderRadius="full"
              height="unset"
            >
              <Icon
                boxSize={pxToRem(currentBreakpoints.iconSize)}
                icon="sound_on"
              />
            </Button>
          </Box>
        )}
      </Flex>
      <VStack spacing={pxToRem(currentBreakpoints.margin)} w="full">
        {zones.map((zone) => (
          <ClassifyDropZone
            key={zone.zoneId}
            itemsInZone={zone.items}
            zoneId={zone.zoneId}
            onDragStop={onDragStop}
            title={zone.title}
            groupUsers={groupUsers}
            onLabelMove={onLabelMove}
          />
        ))}
      </VStack>
    </VStack>
  );
};
