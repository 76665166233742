import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { PodiumSummaryCard } from "sessionComponents/atoms/PodiumSummaryCard";
import { PodiumSummaryStat } from "sessionComponents/atoms/PodiumSummaryStat";
import { StudentSessionSoundEffect } from "sessionComponents/types";
import { useSessionAudio } from "sharedComponents/contexts/sessionAudio";

import bonusIcon from "../resource/bonus_icon.svg";

interface IBonusCardProps {
  show: boolean;
  handleSetTotalXP: React.Dispatch<React.SetStateAction<number>>;
  handleComplete: () => void;
  bonusXP: number;
}

export const BonusCard: React.FC<IBonusCardProps> = ({
  show,
  handleComplete,
  handleSetTotalXP,
  bonusXP,
}) => {
  const [isCardEntered, setIsCardEntered] = useState<boolean>(false);
  const { play: playAudio } = useSessionAudio();
  const { t } = useTranslation("session", {
    useSuspense: false,
    keyPrefix: "podium.summary",
  });

  const handleCardEnter = () => {
    handleSetTotalXP((val: number) => val + bonusXP);
    setIsCardEntered(true);
    playAudio(StudentSessionSoundEffect.BonusXP);
  };

  return (
    <PodiumSummaryCard
      show={show}
      handleComplete={handleCardEnter}
      iconUrl={bonusIcon}
    >
      <PodiumSummaryStat
        start={0}
        end={bonusXP}
        show={isCardEntered}
        handleComplete={handleComplete}
        label={t("bonusXP")}
      />
    </PodiumSummaryCard>
  );
};
