import {
  IPracticeSet,
  IPracticeSetGradeLevel,
  IPracticeSetSubject,
  PracticeSetAvailability,
  PracticeSetStatus,
  SmartSetType,
} from "./types";

export const emptyPracticeSet: IPracticeSet = {
  id: "0",
  title: "",
  description: "",
  language_code: "",
  country: "",
  region: "",
  created_by: "0",
  collection_id: "0",
  status: PracticeSetStatus.Active,
  availability: PracticeSetAvailability.Private,
  cnc_family_code: "",
  cnc_code: "",
  is_certified: false,
  is_digital_citizenship: false,
  is_premium: false,
  is_how_to_play: false,
  source_set_is_certified: false,
  source_set_is_premium: false,
  subjects: [],
  grade_levels: [],
  reference_materials: [],
  item_count: 0,
  is_active_in_unit: false,
  created_at: "",
  updated_at: "",
  deleted_at: "",
  units_practice_sets_id: "0",
  units_practice_sets_order: 0,
  cover_image_icon: "OTHER_TRIVIA",
  cover_image_bg_pattern: "OTHER_FUN_PARTY",
  cover_image_bg_color_scheme: "LIGHT_BLUE",
  group_practice_sets_created_at: "",
  most_recent_live_practice_timestamp: "",
  most_recent_assignment_timestamp: "",
  smart_set_type: SmartSetType.None,
};

export const emptyPracticeSetGradeLevel: IPracticeSetGradeLevel = {
  id: "0",
  created_at: "",
  updated_at: "",
  deleted_at: "",
  grade_level: {
    id: "0",
    created_at: "",
    updated_at: "",
    deleted_at: "",
    grade_level: "",
    country: "",
    region: "",
  },
};

export const emptyPracticeSetSubject: IPracticeSetSubject = {
  id: "0",
  created_at: "",
  updated_at: "",
  deleted_at: "",
  subject: {
    id: "0",
    created_at: "",
    updated_at: "",
    deleted_at: "",
    parent_id: "0",
    parent_name: "",
    name: "",
    country: "",
    region: "",
  },
};
