import { Box, HStack, ToastId } from "@chakra-ui/react";
import axios from "axios";
import React from "react";
import { TFunction } from "react-i18next";

import { Avatar } from "adminComponents/atoms/Avatar";
import { generateStudentName, pxToRem } from "adminComponents/utils";
import { SocialEventReaction, SocialEventType } from "links/lib/types";

export const showSocialEventReactionsToasts = (
  socialEventReactions: SocialEventReaction[],
  showToast: (
    children: React.ReactNode,
    duration?: number
  ) => ToastId | undefined,
  t: TFunction<"admin", "highFivesCard.social.reactions">
): void => {
  const getSocialEventReactionActionText = (
    socialEventReaction: SocialEventReaction,
    userName: string
  ): string => {
    let actionText = "";
    switch (socialEventReaction.event_type) {
      case SocialEventType.RareItemAcquired:
        actionText = t("eventTextRareItemAcquired", {
          itemName: socialEventReaction.avatar_item_name,
          userName,
        });
        break;
      case SocialEventType.CompletedDailyChallenge:
        actionText = t("eventTextCompletedDailyChallenge", {
          userName,
        });
        break;
      case SocialEventType.CompletedWeeklyChallenge:
        actionText = t("eventTextCompletedWeeklyChallenge", {
          userName,
        });
        break;
      case SocialEventType.CompletedMonthlyChallenge:
        actionText = t("eventTextCompletedMonthlyChallenge", {
          userName,
        });
        break;
      case SocialEventType.StudentAddedToClassroom:
        actionText = t("eventTextStudentAddedToClassroom", {
          classroomName: socialEventReaction.group_name,
          userName,
        });
        break;
      case SocialEventType.TeamWonClassroomSession:
        actionText = t("eventTextTeamWonClassroomSession", {
          classroomName: socialEventReaction.group_name,
          userName,
        });
        break;
      case SocialEventType.CompletedAllDailyRemixes:
        actionText = t("eventTextCompletedAllDailyRemixes", {
          userName,
        });
        break;
      case SocialEventType.LeveledUp:
        actionText = t("eventTextLeveledUp", {
          userName,
        });
        break;
      case SocialEventType.UnlockedAllSeasonLocationItems:
        actionText = t("eventTextUnlockedAllSeasonLocationItems", {
          seasonLocationName: socialEventReaction.season_location_name,
          userName,
        });
        break;
      case SocialEventType.UnlockedSeasonZone:
        actionText = t("eventTextUnlockedSeasonZone", {
          seasonZoneName: socialEventReaction.season_zone_name,
          userName,
        });
        break;
    }
    return actionText;
  };

  socialEventReactions?.map((socialEventReaction) => {
    const { family_name, given_name, student_nickname, profile_image_url } =
      socialEventReaction;
    const name = generateStudentName({
      given_name,
      family_name,
      student_nickname,
    }).primary;

    const [first, last] = name.split(" ");
    const abbreviatedName = `${first} ${last ? `${last[0]}.` : ""}`.trim();

    showToast(
      <SocialEventReactionsListItem
        key={socialEventReaction.id}
        profileImageUrl={profile_image_url}
        name={abbreviatedName}
        actionText={getSocialEventReactionActionText(
          socialEventReaction,
          abbreviatedName
        )}
      />
    );
  });

  const acknowledgeReaction = socialEventReactions?.pop();
  if (acknowledgeReaction) {
    axios.post(`/v1/social/reactions/${acknowledgeReaction.id}/acknowledge`);
  }
};

type ISocialEventReactionsListItemProps = {
  profileImageUrl: string;
  name: string;
  actionText: string;
};

export const SocialEventReactionsListItem: React.FC<
  ISocialEventReactionsListItemProps
> = ({
  profileImageUrl,
  name,
  actionText,
}: ISocialEventReactionsListItemProps) => {
  return (
    <HStack>
      <Avatar boxSize={pxToRem(48)} name={name} src={profileImageUrl} />
      <Box>{actionText}</Box>
    </HStack>
  );
};
