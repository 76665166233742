import { Box, BoxProps } from "@chakra-ui/react";
import React, { useRef } from "react";
import { useLocation } from "react-router-dom";
import { useMount } from "react-use";

interface HashScrollOptions extends ScrollIntoViewOptions {
  delay?: number;
}

interface IProps extends BoxProps {
  // append id to the url in browser as a hash to trigger scroll on mount
  id: string;
  options?: HashScrollOptions;
}

export const HashScrollOnMount: React.FC<IProps> = ({
  id,
  options = {
    behavior: "smooth",
    block: "center",
    delay: 500,
  },
  children,
  ...rest
}) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  useMount(() => {
    if (scrollRef.current && location.hash.includes(id)) {
      setTimeout(
        () => scrollRef.current?.scrollIntoView(options),
        options.delay
      );
    }
  });

  return (
    <Box id={id} {...rest} ref={scrollRef}>
      {children}
    </Box>
  );
};
