import { Box, Flex } from "@chakra-ui/react";
import React from "react";

import { Icon } from "adminComponents/atoms/Icon";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { isImageChoicePossibleQuestionType } from "adminComponents/utils/questionType";
import { QuestionType } from "links/lib/types";
import { RichTextRenderer } from "sharedComponents/atoms/RichTextRenderer";
import ZoomableImage from "sharedComponents/molecules/ZoomableImage";

export interface IAnswerProps {
  imageAltText?: string;
  imageUrl?: string;
  text?: string;
  isCorrect?: boolean;
  id: string;
}

interface IProps {
  answers: IAnswerProps[];
  answerType: "STUDENT_RESPONSES" | "EXPECTED_CORRECT_RESPONSES";
  questionType: QuestionType;
}

export const AnswerColumn: React.FC<IProps> = ({
  answers,
  answerType,
  questionType,
}) => {
  const questionUsesImageAnswer =
    isImageChoicePossibleQuestionType(questionType) &&
    answers.some((answer) => answer?.imageUrl);

  return (
    <>
      {answers.map((answer) => {
        return (
          <Flex
            key={`${answer.id}`}
            gap={pxToRem(12)}
            align="start"
            paddingY={pxToRem(6)}
          >
            {answerType === "STUDENT_RESPONSES" ? (
              <Icon
                flexShrink={0}
                variant="circleWithBackgroundColor"
                icon={answer?.isCorrect ? "check" : "close"}
                iconColor={
                  answer?.isCorrect ? "primary.warm-black" : "primary.white"
                }
                backgroundColor={
                  answer?.isCorrect
                    ? "utility.question-green"
                    : "utility.question-red"
                }
              />
            ) : (
              // answerType === "EXPECTED_CORRECT_RESPONSES"
              <Icon
                flexShrink={0}
                variant="circleWithBackgroundColor"
                icon="check"
                backgroundColor="primary.light-gray"
              />
            )}

            <Text as="div" variant="adminP2">
              {!questionUsesImageAnswer &&
                (answer?.text ? (
                  <RichTextRenderer content={answer?.text} />
                ) : (
                  "N/A"
                ))}
              {questionUsesImageAnswer && (
                <Box
                  borderRadius="lg"
                  h="100%"
                  w={{ base: pxToRem(72), lg: pxToRem(120) }}
                >
                  <ZoomableImage
                    src={answer?.imageUrl || ""}
                    alt={answer?.imageAltText || ""}
                    maxH="100%"
                  />
                </Box>
              )}
            </Text>
          </Flex>
        );
      })}
    </>
  );
};
