import { hoverStyles, pxToRem } from "adminComponents/utils";

export const AdminRemixItem = {
  baseStyle: ({
    isCompleted,
    isSelected,
    isDisabled,
    isLoading,
  }: {
    isCompleted?: boolean;
    isSelected?: boolean;
    isDisabled?: boolean;
    isLoading?: boolean;
  }): Record<string, unknown> => ({
    container: {
      flexDirection: "column",
      alignItems: "center",
      width: { base: `${pxToRem(90)}`, md: `${pxToRem(102)}` },
    },
    coverImageWrapper: {
      display: "inline-flex",
      borderColor: !isCompleted && isSelected ? "utility.link" : "primary.tan",
      borderWidth: pxToRem(3),
      borderStyle: "solid",
      borderRadius: "100%",
      justifyContent: "center",
      alignItems: "center",
      cursor: isCompleted || isLoading || isDisabled ? "auto" : "pointer",
      boxSize: { base: `${pxToRem(90)}`, md: `${pxToRem(102)}` },
      mb: pxToRem(12),
      bg: "primary.white",
      position: "relative",
      transition: "border-color 250ms",
      opacity: isDisabled ? 0.7 : 1,
      _focus: {
        boxShadow: "none",
        outline: "none",
      },
      _focusVisible: {
        borderColor:
          isSelected || isCompleted || isDisabled || isLoading
            ? undefined
            : "utility.focus",
        boxShadow: "none",
        outline: "none",
      },
      _hover: hoverStyles({
        borderColor:
          isSelected || isCompleted || isDisabled || isLoading
            ? undefined
            : "primary.golden-hover",
      }),
    },
    coverImageBackground: {
      width: "100%",
      height: "100%",
      borderRadius: "100%",
      opacity: isCompleted ? 0.7 : 1,
    },
    progress: {
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
      backgroundSize: "contain",
      position: "absolute",
      right: "0",
      bottom: "0",
      boxSize: pxToRem(24),
    },
    spinner: {
      position: "absolute",
      top: "50%",
      left: "50%",
      display: "flex",
      transform: "translateX(-50%) translateY(-50%)",
      width: "auto",
      height: "auto",
    },
  }),
};
