import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { useAxios } from "../../hooks/useAxios";

export interface IMutateClassroomStudentArgs {
  user_id: string;
  classroom_id: string;
  student_nickname?: string;
  grade_level_id?: string;
}

export interface IUseMutateClassroomStudentArgs {
  onSuccess?: () => void;
}

export default function useMutateClassroomStudent({
  onSuccess,
}: IUseMutateClassroomStudentArgs): UseMutationResult<
  unknown,
  unknown,
  IMutateClassroomStudentArgs
> {
  const axios = useAxios();
  const queryClient = useQueryClient();

  const update = async (args: IMutateClassroomStudentArgs) => {
    const { user_id, classroom_id, student_nickname, grade_level_id } = args;

    const response = await axios({
      method: "post",
      url: `/v1/groups/users/profiles/${user_id}`,
      data: {
        group_id: classroom_id,
        student_nickname,
        grade_level_id,
      },
    }).then((r) => {
      return r.data as unknown;
    });

    queryClient.invalidateQueries(["classrooms", { id: classroom_id }]);
    queryClient.invalidateQueries(["classroom-users", { id: classroom_id }], {
      exact: false,
    });

    return response;
  };

  const mutation = useMutation(update, { onSuccess });

  return mutation;
}
