import {
  Box,
  Center,
  GridItem,
  HStack,
  SimpleGrid,
  Square,
  Text,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCopyToClipboard, useMeasure } from "react-use";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { useShowToast } from "adminComponents/utils/toast";
import { config } from "links/lib/constants";
import { useSession } from "links/lib/contexts/session";
import { useSessionScene } from "links/lib/contexts/sessionScene";
import { AnalyticsEvent, SessionScene } from "links/lib/types";
import { Icon } from "sessionComponents/atoms/Icon";
import { IconButton } from "sessionComponents/atoms/IconButton";
import { SessionIndicator } from "sessionComponents/atoms/SessionIndicator";
import { Timer } from "sessionComponents/atoms/Timer";
import { Tooltip } from "sessionComponents/atoms/Tooltip";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { AllTeamsModal } from "sessionComponents/molecules/AllTeamsModal";
import { ControlSlider } from "sessionComponents/molecules/ControlSlider";
import { GameFlyout } from "sessionComponents/molecules/Flyout";
import { Header } from "sessionComponents/molecules/Header";
import {
  MenuList,
  MenuListControl,
  MenuListDivider,
  MenuListItem,
} from "sessionComponents/molecules/MenuList";
import { StudentListModal } from "sessionComponents/molecules/StudentListModal";
import { useDetectOrientation } from "sessionComponents/theme/hooks/useDetectOrientation";
import { useSessionAnalytics } from "sharedComponents/contexts/sessionAnalytics";

interface ITeacherHeaderProps {
  headingText: string;
  timer?: {
    startsAt: string;
    endsAt: string;
  };
  volume: number;
  onVolumeChange?: (volume: number) => void;
  onExit?: () => void;
  showVolumeControlButton: boolean;
  disableVolumeControlFlyout?: boolean;
  onExtendRoundTime?: () => void;
  showTeamsModalButton?: boolean;
}

const TeacherHeader: React.FC<ITeacherHeaderProps> = ({
  headingText,
  timer,
  volume,
  onVolumeChange,
  onExit,
  showVolumeControlButton,
  disableVolumeControlFlyout = false,
  onExtendRoundTime,
  showTeamsModalButton,
}) => {
  const [showTeamsModal, setShowTeamsModal] = useState(false);
  const [showStudentListModal, setShowStudentListModal] = useState(false);
  const {
    match: currentBreakpoints,
    fontSize2x,
    fontSizeHalf,
  } = useBreakpoints();
  const session = useSession();
  const sessionScene = useSessionScene();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [, copyToClipboard] = useCopyToClipboard();
  const { track } = useSessionAnalytics();
  const showToast = useShowToast();
  const { t } = useTranslation("session", {
    useSuspense: false,
    keyPrefix: "teacherHeader",
  });
  const { isLandscape } = useDetectOrientation();
  const isMediumOrSmallerScreensize = useBreakpointValue({
    base: true,
    lg: false,
  });

  const buttonSize = pxToRem(currentBreakpoints.buttonHeight);
  const iconSize = pxToRem(currentBreakpoints.iconSize);
  const fontSize = pxToRem(currentBreakpoints.fontSize);

  const content = {
    menuHeading: t("menuHeading"),
    menuButton: t("menuButton"),
    exitButton: t("exitButton"),
    volumeButton: t("volumeButton"),
    volumeControl: t("volumeControl"),
    teamsModalButton: t("teamsModalButton"),
    allStudentsButton: t("allStudentsButton"),
  };

  const handleExitGameClick = () => {
    onExit && onExit();
    onClose();
  };

  const [codeTextRef, { width: codeTextRefWidth }] =
    useMeasure<HTMLDivElement>();
  const cardContentRef = useRef<HTMLDivElement>(null);
  const cardContentRefWidth =
    cardContentRef.current?.getBoundingClientRect().width;

  const getDynamicScaleForCode = () => {
    if (!codeTextRefWidth || !cardContentRefWidth) return undefined;
    return codeTextRefWidth > cardContentRefWidth
      ? `scale(${cardContentRefWidth / codeTextRefWidth})`
      : undefined;
  };

  const joinUrl = `${config.joinBaseUrl}/${(
    session?.code || ""
  ).toLocaleLowerCase()}`;

  const onCopyLink = () => {
    copyToClipboard(joinUrl);
    showToast(t("copiedLink"));

    track(AnalyticsEvent.Session_Lobby_CopyJoinLink, {});
  };

  const displaySessionJoinInformation =
    session?.code &&
    sessionScene !== SessionScene.Lobby &&
    sessionScene !== SessionScene.Podium;
  const displaySessionJoinIndicatorInHeader =
    displaySessionJoinInformation && isLandscape;

  return (
    <>
      <Header headingText={headingText} noFormat>
        <SimpleGrid
          columns={displaySessionJoinIndicatorInHeader ? 8 : 4}
          w="full"
        >
          <GridItem colSpan={2}>
            <HStack alignItems="center" h="full" w="full">
              <Text fontSize={fontSize} as="h1" textStyle="gameDisplayInline">
                {headingText}
              </Text>
            </HStack>
          </GridItem>
          {displaySessionJoinIndicatorInHeader && (
            <GridItem colSpan={4}>
              <HStack justifyContent="center" overflow="visible">
                <SessionIndicator
                  sessionCode={session.code}
                  hideHost={isMediumOrSmallerScreensize}
                />
              </HStack>
            </GridItem>
          )}
          <GridItem colSpan={2}>
            <HStack h="full" justifyContent="right">
              {timer && (
                <Timer
                  startsAt={timer.startsAt}
                  endsAt={timer.endsAt}
                  fontSize={fontSize}
                  boxSize={buttonSize}
                  onExtendRoundTime={onExtendRoundTime}
                  showExtendPopover
                />
              )}
              {showVolumeControlButton && (
                <Tooltip
                  borderRadius={currentBreakpoints.lightBorderRadius}
                  tooltipContent={
                    <Box w={pxToRem(400)}>
                      <ControlSlider
                        variant="gameWhite"
                        value={volume}
                        onChange={onVolumeChange}
                      />
                    </Box>
                  }
                  placement="bottom-end"
                  trigger="click"
                >
                  {/* Box wrapper required for tooltip to get ref for positioning */}
                  <Box display="flex">
                    <IconButton
                      boxSize={buttonSize}
                      iconSize={iconSize}
                      icon={volume > 0 ? "sound_on" : "sound_off"}
                      aria-label={content.volumeButton}
                    />
                  </Box>
                </Tooltip>
              )}
              <IconButton
                boxSize={buttonSize}
                iconSize={iconSize}
                icon="menu"
                onClick={onOpen}
                aria-label={content.menuButton}
              />
            </HStack>
          </GridItem>
        </SimpleGrid>
        <GameFlyout
          title={content.menuHeading}
          isOpen={isOpen}
          onClose={onClose}
        >
          <MenuList>
            {!showVolumeControlButton && !disableVolumeControlFlyout && (
              <MenuListControl
                icon={volume > 0 ? "sound_on" : "sound_off"}
                label={content.volumeControl}
              >
                <ControlSlider
                  variant="gameBlue"
                  value={volume}
                  onChange={onVolumeChange}
                />
              </MenuListControl>
            )}
            <MenuListDivider />
            {showTeamsModalButton && (
              <MenuListItem icon="team" onClick={() => setShowTeamsModal(true)}>
                {content.teamsModalButton}
              </MenuListItem>
            )}
            <MenuListItem
              icon="team"
              onClick={() => setShowStudentListModal(true)}
            >
              {content.allStudentsButton}
            </MenuListItem>
            <MenuListDivider />
            <MenuListItem icon="exit" onClick={handleExitGameClick}>
              {content.exitButton}
            </MenuListItem>

            {displaySessionJoinInformation && (
              <MenuListItem onClick={onCopyLink}>
                <Square
                  ref={cardContentRef}
                  size="full"
                  border="4px solid"
                  borderColor="primary.tan"
                  borderRadius={pxToRem(12)}
                  pt={pxToRem(24)}
                  pb={pxToRem(24)}
                >
                  <SimpleGrid
                    columns={1}
                    spacing={pxToRem(16)}
                    p={pxToRem(16)}
                    w="full"
                  >
                    <GridItem>
                      <Text
                        textAlign="center"
                        fontSize={fontSize}
                        textStyle="gameText"
                      >
                        {t("joinCodeHeading")}
                      </Text>
                    </GridItem>

                    <GridItem>
                      <Center w="full">
                        <Text
                          ref={codeTextRef}
                          textStyle="gameDisplay"
                          fontSize={pxToRem(fontSize2x)}
                          transform={getDynamicScaleForCode()}
                        >
                          {session.code.toUpperCase()}
                        </Text>
                      </Center>
                    </GridItem>

                    <GridItem>
                      <Center mt={pxToRem(8)}>
                        <Icon
                          icon="clipboard"
                          iconColor="utility.link"
                          boxSize={pxToRem(currentBreakpoints.iconSize)}
                          marginRight={pxToRem(fontSizeHalf)}
                        />
                        <Text
                          textAlign="center"
                          fontSize={fontSize}
                          textStyle="gameText"
                          textColor="utility.link"
                        >
                          {t("copyJoinLink")}
                        </Text>
                      </Center>
                    </GridItem>
                  </SimpleGrid>
                </Square>
              </MenuListItem>
            )}
          </MenuList>
        </GameFlyout>
      </Header>
      {showTeamsModal && (
        <AllTeamsModal
          isOpen={showTeamsModal}
          onClose={() => setShowTeamsModal(false)}
        />
      )}
      {showStudentListModal && (
        <StudentListModal
          isOpen={showStudentListModal}
          onClose={() => setShowStudentListModal(false)}
        />
      )}
    </>
  );
};

export { TeacherHeader };
