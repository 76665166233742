import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import type { IUserTrialExtension } from "../../types";

export interface IListUserTrialExtensionsParams {
  guid: string;
}

export enum ExtendabilityReason {
  INVALID = "INVALID",
  CAN_EXTEND = "CAN_EXTEND",
  ALREADY_EXTENDED = "ALREADY_EXTENDED",
  LIMIT_REACHED = "LIMIT_REACHED",
  EXPIRED = "EXPIRED",
  STACKING_PREVENTED = "STACKING_PREVENTED",
  INCORRECT_DOMAIN = "INCORRECT_DOMAIN",
  INCORRECT_ORGANIZATION = "INCORRECT_ORGANIZATION",
  USER_TRIAL_ALREADY_PAST_EXTENSION_DATE = "USER_TRIAL_ALREADY_PAST_EXTENSION_DATE",
}

export interface IGetUserTrialExtensionWithStatsResponse {
  user_trial_extension: IUserTrialExtension;
  can_user_extend: boolean;
  reason: ExtendabilityReason;
  extension_days: number;
}

export function useFetchUserTrialExtensionWithStats({
  guid,
}: IListUserTrialExtensionsParams): UseQueryResult<
  IGetUserTrialExtensionWithStatsResponse,
  unknown
> {
  const axios = useAxios();

  return useQuery<IGetUserTrialExtensionWithStatsResponse>(
    ["user-trial-extension", guid],
    async (): Promise<IGetUserTrialExtensionWithStatsResponse> => {
      return await axios
        .get(`/v1/subscriptions/users/extensions/${guid}`)
        .then((r) => r.data as IGetUserTrialExtensionWithStatsResponse);
    }
  );
}
