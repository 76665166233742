import React from "react";

import { colors } from "adminComponents/theme/colors";

export const ExplainableLightningSVG: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="none">
    <circle cx="11.5" cy="11.5" r="11.5" fill={colors.utility.focus} />
    <path
      fill={colors.primary.white}
      fillRule="evenodd"
      d="M11.677 4.023a.744.744 0 0 1 .537.727v4.5h3.572c.252 0 .485.14.614.367.129.228.134.51.013.742l-4.286 8.25a.702.702 0 0 1-.804.368.744.744 0 0 1-.537-.727v-4.5H7.214a.708.708 0 0 1-.614-.367.783.783 0 0 1-.013-.742l4.286-8.25a.702.702 0 0 1 .804-.368Zm-3.26 8.227H11.5c.395 0 .714.336.714.75v2.309l2.368-4.559H11.5c-.395 0-.714-.336-.714-.75V7.691L8.418 12.25Z"
      clipRule="evenodd"
    />
  </svg>
);
