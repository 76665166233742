import { Box } from "@chakra-ui/react";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { animated, config, useTransition } from "react-spring";
import { useTimeout } from "react-use";

import { useSession } from "links/lib/contexts/session";
import { useSessionActions } from "links/lib/contexts/sessionActions";
import { useSessionGameState } from "links/lib/contexts/sessionGameState";
import { useSessionGroups } from "links/lib/contexts/sessionGroups";
import { useSessionRoundState } from "links/lib/contexts/sessionRoundState";
import { useSessionUsers } from "links/lib/contexts/sessionUsers";
import { BigBoardGameState } from "links/lib/types";
import { useStudent } from "sessionComponents/contexts/student";
import { usePrizeRoundAudio } from "sessionComponents/hooks/usePrizeRoundAudio";
import { TextInterstitial } from "sessionComponents/molecules/TextInterstitial";
import { BigBoardSpin as BBSpin } from "sessionComponents/organisms/BigBoardSpin";
import { getUserWithWarning } from "sessionComponents/utils/getUserWithWarning";

export interface IBigBoardSpinProps {
  isTeacher: boolean;
}

const AnimatedBox = animated(Box);

export const BigBoardSpin: React.FC<IBigBoardSpinProps> = ({ isTeacher }) => {
  const gameState = useSessionGameState() as BigBoardGameState;
  const groups = useSessionGroups();
  const session = useSession();
  const {
    initiatePrizeRoundAwards,
    initiatePrizeRound,
    stopTheBigBoardScanning,
  } = useSessionActions();
  const users = useSessionUsers();
  const roundState = useSessionRoundState();
  const [showContent] = useTimeout(4000);
  const { t } = useTranslation("session", {
    keyPrefix: "bigBoardSpin",
    useSuspense: false,
  });

  usePrizeRoundAudio(!isTeacher);

  const { splash_animation_name } = roundState;
  const student = useStudent();

  const nextScene = useCallback(
    (isCarePackage) => {
      if (isCarePackage) {
        // if care package, you spin again
        initiatePrizeRound();
      } else {
        initiatePrizeRoundAwards();
      }
    },
    [initiatePrizeRoundAwards, initiatePrizeRound]
  );

  const stopSpinning = useCallback(
    (rowId) => {
      stopTheBigBoardScanning(rowId);
    },
    [stopTheBigBoardScanning]
  );

  const contentTransition = useTransition(
    showContent() ? ["content"] : ["splash"],
    {
      from: { opacity: showContent() ? 0 : 1 },
      enter: { opacity: 1 },
      leave: { opacity: 0 },
      delay: showContent() ? 500 : 0,
      config: config.stiff,
    }
  );

  //TODO what should happen if this is undefined? We need some kind of error/crash page.
  const spinningUser = getUserWithWarning(
    users,
    gameState?.the_big_board_state?.prize_round_user_id
  );

  if (!spinningUser) return <></>;

  return (
    <>
      {contentTransition(({ opacity }, item) =>
        item === "splash" ? (
          <AnimatedBox w="full" h="full" style={{ opacity }}>
            <TextInterstitial
              mainText={t("introMainText")}
              secondaryText={t("introSecondaryText", {
                points: gameState?.the_big_board_state?.prize_round_points,
              })}
              splashAnimationName={splash_animation_name}
              containerStyle={{ opacity }}
              pattern={session?.practice_set_cover_image_bg_pattern}
            />
          </AnimatedBox>
        ) : (
          <AnimatedBox w="full" h="full" style={{ opacity }}>
            <BBSpin
              isTeacher={isTeacher}
              buttonAction={stopSpinning}
              gameState={gameState}
              groups={groups}
              nextAction={nextScene}
              spinningUser={spinningUser}
              student={student}
            />
          </AnimatedBox>
        )
      )}
    </>
  );
};
