import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Tabs } from "adminComponents/atoms/Tabs";

export enum GuestSearchResultsTab {
  All = 0,
  FromExperts = 1,
  FromCommunity = 2,
}

export enum SearchResultsTab {
  All = 0,
  FromExperts = 1,
  FromCommunity = 2,
  MySets = 3,
  MyDistrict = 4,
}

export interface IProps {
  tabIndex?: number;
  handleTabChange: (tab: number) => void;
  isGuest?: boolean;
  hideExpertTab?: boolean;
}

export const LibrarySearchResultsTabs: React.FC<IProps> = ({
  tabIndex,
  handleTabChange,
  isGuest = true,
  hideExpertTab = false,
  children,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  const _handleTabChange = (index: number) => {
    // Account for potential missing experts tab
    handleTabChange(hideExpertTab && index > 0 ? index + 1 : index);
  };

  const tabData = useMemo(() => {
    const tabData = [
      {
        label: t("librarySearchResults.allSets"),
        content: <></>,
      },
    ];

    if (!hideExpertTab) {
      tabData.push({
        label: t("librarySearchResults.fromExperts"),
        content: <></>,
      });
    }

    tabData.push({
      label: t("librarySearchResults.fromCommunity"),
      content: <></>,
    });

    if (!isGuest) {
      tabData.push({
        label: t("librarySearchResults.mySets"),
        content: <></>,
      });
      tabData.push({
        label: t("librarySearchResults.myDistrict"),
        content: <></>,
      });
    }

    return tabData;
  }, [isGuest, t, hideExpertTab]);

  const tab = tabIndex || 0;

  return (
    <>
      <Tabs
        // Account for potential missing experts tab
        tabIndex={hideExpertTab && tab > 1 ? tab - 1 : tab}
        handleChange={_handleTabChange}
        tabData={tabData}
        variant="adminScreenTabs"
      />
      {children}
    </>
  );
};
