import {
  Grid,
  GridItem,
  SimpleGrid,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { pxToRem } from "adminComponents/utils/pxToRem";
import {
  CoverImageBGColorSchemeOptions,
  CoverImageBGColorSchemeType,
  CoverImageBGPatternType,
  CoverImageIconType,
} from "links/lib/types";

import CoverImageChoice from "../CoverImageChoice";
import { CoverImageColorSchemeChoice } from "../CoverImageColorSchemeChoice";

export interface ICoverImageTemplate {
  icon: CoverImageIconType;
  bgPattern: CoverImageBGPatternType;
}

export interface ICoverImage extends ICoverImageTemplate {
  bgColorScheme: CoverImageBGColorSchemeType;
}

export interface ICoverImageSection {
  title: string;
  templates: Array<ICoverImageTemplate>;
}

interface IProps {
  selectedCoverImage: ICoverImage;
  isSmartSet: boolean;
  handleChange: (coverImage: ICoverImage) => void;
}

const templates: Record<
  | "english"
  | "math"
  | "science"
  | "history"
  | "worldLanguages"
  | "visualAndPerformingArts"
  | "other"
  | "electives",
  Array<ICoverImageTemplate>
> = {
  english: [
    { icon: "ENGLISH_READING", bgPattern: "ENGLISH_BOOKS_READING" },
    { icon: "ENGLISH_LITERATURE", bgPattern: "ENGLISH_BOOKS_READING" },
    { icon: "ENGLISH_SPELLING", bgPattern: "ENGLISH_BOOKS_READING" },
    { icon: "ENGLISH_WRITING", bgPattern: "ENGLISH_PUNCTUATION_GRAMMAR" },
    { icon: "ENGLISH_GRAMMAR", bgPattern: "ENGLISH_PUNCTUATION_GRAMMAR" },
    { icon: "ENGLISH_PHONICS", bgPattern: "ENGLISH_PUNCTUATION_GRAMMAR" },
  ],
  math: [
    {
      icon: "MATHEMATICS_NUMBERS_AND_OPERATIONS",
      bgPattern: "MATH_OPERATION_SYMBOLS",
    },
    {
      icon: "MATHEMATICS_EXPRESSIONS_AND_EQUATIONS",
      bgPattern: "MATH_OPERATION_SYMBOLS",
    },
    { icon: "MATHEMATICS_ALGEBRA", bgPattern: "MATH_OPERATION_SYMBOLS" },
    { icon: "MATHEMATICS_GEOMETRY", bgPattern: "MATH_ABSTRACT" },
    { icon: "MATHEMATICS_CALCULUS", bgPattern: "MATH_ABSTRACT" },
    {
      icon: "MATHEMATICS_STATISTICS_AND_PROBABILITY",
      bgPattern: "MATH_ABSTRACT",
    },
  ],
  science: [
    { icon: "SCIENCE_BIOLOGY", bgPattern: "SCIENCE_BIOLOGY_BG_PATTERN" },
    { icon: "SCIENCE_CHEMISTRY", bgPattern: "SCIENCE_CHEMISTRY_PHYSICS" },
    { icon: "SCIENCE_PHYSICS", bgPattern: "SCIENCE_CHEMISTRY_PHYSICS" },
    { icon: "SCIENCE_EARTH_AND_SPACE", bgPattern: "SCIENCE_SPACE" },
    {
      icon: "SCIENCE_TECHNOLOGY_AND_COMPUTER_SCIENCE",
      bgPattern: "MATH_ABSTRACT",
    },
    {
      icon: "SCIENCE_PSYCHOLOGY_AND_SOCIOLOGY",
      bgPattern: "OTHER_CULTURE_BG_PATTERN",
    },
  ],
  history: [
    { icon: "HISTORY_US_HISTORY", bgPattern: "HISTORY" },
    { icon: "HISTORY_WORLD_HISTORY", bgPattern: "HISTORY" },
    { icon: "HISTORY_GEOGRAPHY", bgPattern: "HISTORY" },
    { icon: "HISTORY_GOVERNMENT", bgPattern: "HISTORY" },
    { icon: "HISTORY_COMMUNITY", bgPattern: "OTHER_CULTURE_BG_PATTERN" },
    { icon: "HISTORY_ECONOMICS", bgPattern: "ELECTIVE_BUSINESS_FINANCE" },
  ],
  worldLanguages: [
    { icon: "WORLD_LANGUAGES_SPANISH", bgPattern: "WORLD_LANGUAGE" },
    { icon: "WORLD_LANGUAGES_FRENCH", bgPattern: "WORLD_LANGUAGE" },
    { icon: "WORLD_LANGUAGES_GERMAN", bgPattern: "WORLD_LANGUAGE" },
    { icon: "WORLD_LANGUAGES_MANDARIN_CHINESE", bgPattern: "WORLD_LANGUAGE" },
    { icon: "WORLD_LANGUAGES_JAPANESE", bgPattern: "WORLD_LANGUAGE" },
    { icon: "WORLD_LANGUAGES_SIGN_LANGUAGE", bgPattern: "WORLD_LANGUAGE" },
  ],
  visualAndPerformingArts: [
    {
      icon: "VISUAL_AND_PERFORMING_ARTS_DANCE",
      bgPattern: "PERFORMATIVE_ARTS",
    },
    {
      icon: "VISUAL_AND_PERFORMING_ARTS_MUSIC",
      bgPattern: "PERFORMATIVE_ARTS",
    },
    {
      icon: "VISUAL_AND_PERFORMING_ARTS_DRAMA_AND_THEATRE",
      bgPattern: "PERFORMATIVE_ARTS",
    },
    { icon: "VISUAL_AND_PERFORMING_ARTS_STUDIO_ART", bgPattern: "VISUAL_ARTS" },
    {
      icon: "VISUAL_AND_PERFORMING_ARTS_VIDEO_AND_PHOTOGRAPHY",
      bgPattern: "VISUAL_ARTS",
    },

    {
      icon: "VISUAL_AND_PERFORMING_ARTS_GRAPHIC_DESIGN",
      bgPattern: "VISUAL_ARTS",
    },
  ],
  electives: [
    { icon: "ELECTIVES_WOODWORKING", bgPattern: "ELECTIVE_WOODWORKING" },
    {
      icon: "ELECTIVES_COOKING_AND_BAKING",
      bgPattern: "ELECTIVE_COOKING_BAKING",
    },
    {
      icon: "ELECTIVES_BUSINESS_AND_FINANCE",
      bgPattern: "ELECTIVE_BUSINESS_FINANCE",
    },
    { icon: "ELECTIVES_HEALTH", bgPattern: "ELECTIVE_HEALTH_PE" },
    { icon: "ELECTIVES_PHYSICAL_EDUCATION", bgPattern: "ELECTIVE_HEALTH_PE" },
  ],
  other: [
    { icon: "OTHER_VOCABULARY", bgPattern: "ENGLISH_BOOKS_READING" },
    { icon: "OTHER_CULTURE", bgPattern: "OTHER_CULTURE_BG_PATTERN" },
    { icon: "OTHER_PARTY_FUN", bgPattern: "OTHER_FUN_PARTY" },
    { icon: "OTHER_TRIVIA", bgPattern: "OTHER_FUN_PARTY" },
    { icon: "OTHER_GENERAL_FUN", bgPattern: "OTHER_FUN_PARTY" },
  ],
};

export const PracticeSetCoverImagePicker: React.FC<IProps> = ({
  selectedCoverImage,
  isSmartSet,
  handleChange,
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "practiceSetDetailCoverImagePicker",
  });
  const choiceSize = useBreakpointValue({
    base: 120,
    sm: 150,
  });

  const sections: Array<ICoverImageSection> = useMemo(() => {
    return [
      {
        title: t("englishSection"),
        templates: templates["english"],
      },
      {
        title: t("mathSection"),
        templates: templates["math"],
      },
      {
        title: t("scienceSection"),
        templates: templates["science"],
      },
      {
        title: t("historySection"),
        templates: templates["history"],
      },
      {
        title: t("worldLanguagesSection"),
        templates: templates["worldLanguages"],
      },
      {
        title: t("visualAndPerformingArtsSection"),
        templates: templates["visualAndPerformingArts"],
      },
      {
        title: t("electivesSection"),
        templates: templates["electives"],
      },
      {
        title: t("otherSection"),
        templates: templates["other"],
      },
    ];
  }, [t]);

  return (
    <SimpleGrid column={1} gap={[pxToRem(32), null, pxToRem(40)]}>
      <Text textStyle="adminP1" color="primary.dark-gray">
        {t("description")}
      </Text>
      <SimpleGrid column={1} gap={pxToRem(20)}>
        <Text
          textStyle="adminH6"
          pb={[pxToRem(4), null, pxToRem(3)]}
          borderBottom="1px solid"
          borderBottomColor="primary.medium-gray"
        >
          {t("backgroundColorSection")}
        </Text>
        <SimpleGrid
          gap={[pxToRem(10), null, pxToRem(20)]}
          minChildWidth={pxToRem(60)}
        >
          {CoverImageBGColorSchemeOptions.map((colorScheme) => (
            <CoverImageColorSchemeChoice
              key={colorScheme}
              colorScheme={colorScheme}
              boxSize={60}
              isSelected={selectedCoverImage.bgColorScheme === colorScheme}
              handleSelect={(scheme) =>
                handleChange({
                  icon: selectedCoverImage.icon,
                  bgColorScheme: scheme,
                  bgPattern: selectedCoverImage.bgPattern,
                })
              }
            />
          ))}
        </SimpleGrid>
      </SimpleGrid>
      {sections.map(({ title, templates }) => (
        <SimpleGrid column={1} gap={pxToRem(20)} key={title}>
          <Text
            textStyle="adminH6"
            pb={[pxToRem(4), null, pxToRem(3)]}
            borderBottom="1px solid"
            borderBottomColor="primary.medium-gray"
          >
            {title}
          </Text>
          <Grid
            templateColumns={["repeat(2, 1fr)", "repeat(3, 1fr)"]}
            gap={[pxToRem(10), null, pxToRem(20)]}
          >
            {templates.map(({ icon, bgPattern }) => {
              bgPattern = isSmartSet ? "SMART_SET" : bgPattern;

              return (
                !!icon &&
                !!bgPattern && (
                  <GridItem key={icon} display="flex" justifyContent="center">
                    <CoverImageChoice
                      boxSize={choiceSize || 200}
                      icon={icon}
                      backgroundColorScheme={selectedCoverImage.bgColorScheme}
                      backgroundPattern={bgPattern}
                      isSelected={selectedCoverImage.icon === icon}
                      handleSelect={() =>
                        handleChange({
                          icon,
                          bgPattern,
                          bgColorScheme: selectedCoverImage.bgColorScheme,
                        })
                      }
                    />
                  </GridItem>
                )
              );
            })}
          </Grid>
        </SimpleGrid>
      ))}
    </SimpleGrid>
  );
};
