import { Box, VStack } from "@chakra-ui/react";
import React from "react";
import { useFormState } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Icon } from "adminComponents/atoms/Icon";
import { Text } from "adminComponents/atoms/Text";
import {
  DiagramEditor,
  IDiagramEditorPoint,
} from "adminComponents/molecules/DiagramEditorFormControl/DiagramEditor";
import { DiagramEditorLabelFields } from "adminComponents/molecules/DiagramEditorFormControl/DiagramEditorLabelFields";
import { pxToRem } from "adminComponents/utils/pxToRem";

import { CreateDiagramTabForm } from "../CreateQuestionDiagramTab";

export interface IDiagramEditorLabel extends IDiagramEditorPoint {
  label: string;
  id: string;
}

export interface IDiagramProps {
  disabled?: boolean;
  diagramImageUrl?: string;
  diagramImageAltText?: string;
  diagramLabels?: IDiagramEditorLabel[];
  handleAddPoint: (point: IDiagramEditorPoint) => void;
  handleDeleteLabel: (index: number) => void;
  handleAddImage: () => void;
  handleResetImage: () => void;
  handleMovePoint: (index: number, point: IDiagramEditorPoint) => void;
}

export const DiagramEditorFormControl: React.FC<IDiagramProps> = ({
  disabled = false,
  diagramImageUrl = "",
  diagramLabels = [],
  handleAddPoint,
  handleDeleteLabel,
  handleAddImage,
  handleResetImage,
  handleMovePoint,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const { errors, dirtyFields } = useFormState<CreateDiagramTabForm>();

  return (
    <Box width="full">
      <VStack mt={4} w="full" justifyContent="flex-start" spacing={6}>
        {!!diagramImageUrl && (
          <>
            <DiagramEditor
              handleAddPoint={handleAddPoint}
              imageSrc={diagramImageUrl}
              handleMovePoint={handleMovePoint}
              handleDeleteIntent={handleResetImage}
              points={diagramLabels.map((label) => ({
                x_offset_ratio: label.x_offset_ratio,
                y_offset_ratio: label.y_offset_ratio,
              }))}
            />
            <DiagramEditorLabelFields
              labels={diagramLabels}
              handleLabelRemove={handleDeleteLabel}
            />
            {!!diagramImageUrl &&
              !!dirtyFields.labels &&
              !!errors.labels?.root && (
                <Text mt={2} color="utility.error">
                  {errors.labels?.root.message}
                </Text>
              )}
          </>
        )}
        {!diagramImageUrl && (
          <Box
            display="flex"
            flexDirection={["column", null, "row"]}
            alignItems={["flex-start", null, "center"]}
            gap={[pxToRem(16)]}
            w="full"
          >
            <Box>
              <Text
                variant="adminP2"
                color="primary.warm-black"
                marginBottom={pxToRem(8)}
              >
                {t("diagramEditor.label")}
              </Text>
              <Text variant="adminP2" color="primary.dark-gray">
                {t("diagramEditor.description")}
              </Text>
            </Box>
            <Box marginLeft={[0, null, "auto"]}>
              <Button
                isDisabled={disabled}
                variant="adminButtonOutlined"
                onClick={disabled ? undefined : handleAddImage}
                size="sm"
                leftIcon={<Icon icon="add" color="primary.warm-black" />}
              >
                {t("diagramEditor.add")}
              </Button>
            </Box>
          </Box>
        )}
      </VStack>
    </Box>
  );
};
