import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { IPeriodicChallenges } from "../../types";

export interface IArgs {
  keepPreviousData?: boolean;
}

export type IFetchChallengesStatusResponse = {
  periodic_challenges: IPeriodicChallenges;
};

/**
 * Fetches the status of current challenges for the user
 * @returns
 */
export function useFetchChallengesStatus(
  args?: IArgs
): UseQueryResult<IFetchChallengesStatusResponse> {
  const axios = useAxios();

  const query = useQuery<IFetchChallengesStatusResponse>(
    ["challenges-status"],
    async (): Promise<IFetchChallengesStatusResponse> => {
      return await axios
        .get("/v1/challenges/status")
        .then((r) => r.data as IFetchChallengesStatusResponse);
    },
    {
      keepPreviousData: args?.keepPreviousData,
    }
  );

  return query;
}
