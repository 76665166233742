import { ColorScheme } from "../adminComponents";

import {
  IGameAssetColorScheme,
  ISessionUser,
  PracticeSessionItemVariantType,
  QuestionType,
  RichText,
} from "../links/lib/types";

export enum GameChoiceType {
  Radio = 0,
  MultipleSelect = 1,
}

/** gooperDesign types */
export type UserInResponse = {
  avatarUrl: string;
  id: string;
  name: string;
  primaryColor: string;
};

export interface IGenericResponseComponentProps {
  selected?: boolean;
  users?: UserInResponse[];
  showAccuracyBar?: boolean;
  accuracyPercentage?: number;
  loading?: boolean;
  disabled?: boolean;
  color?: ColorScheme;
  variant: PracticeSessionItemVariantType; //show different icon/checkbox/radio depending on variant
  choiceType?: GameChoiceType;
  iconColor?: ColorScheme;
  ariaLabel?: string;
  success?: boolean;
  error?: boolean;
  onClick?: (checked?: boolean) => void;
  showUserCount?: boolean;
  userCount?: number;
  showTextInputIcon?: boolean;
  backgroundColor?: ColorScheme;
  fontColor?: ColorScheme;
  maxWidth?: string;
  maxHeight?: string;
  borderRadius?: string;
  height?: string;
  width?: string;
  overflow?: string;
  questionType?: QuestionType;
  showAsSelectable?: boolean;
  showAsDefaultWrongAnswer?: boolean;
  accuracyBarFillColor?: ColorScheme;
  accuracyBarBackgroundColor?: ColorScheme;
  partiallyCorrect?: boolean;
  otherTeammatePartiallyCorrect?: boolean;
}

export type ISelectableOptionProps = IGenericResponseComponentProps & {
  text?: string;
  imageUrl?: string;
  imageAltText?: string;
  prefix?: RichText;
  suffix?: RichText;
};

export interface IGenericResponseComponentContentProps
  extends IGenericResponseComponentProps {
  showCheckbox: boolean;
  showRadio: boolean;
  primaryColor: ColorScheme;
}

export enum ScreenOrientation {
  Landscape = "landscape",
  Portrait = "portrait",
}

export type WinningTeam = {
  colorScheme: IGameAssetColorScheme;
  members: ISessionUser[];
  score: number;
  name: string;
  key: string;
};

export enum SessionMusic {
  Goldfish = "golfish",
  Lobsters = "lobsters",
  Quokkas = "quokkas",
  Bossa = "bossa",
  PrizeRound = "prize_round",
  PlaceYourToken = "place_your_token",
  TeamShuffleShuffle = "team_shuffle_shuffle",
}

export type SessionSoundEffect = StudentSessionSoundEffect &
  TeacherSessionSoundEffect;

export enum TeacherSessionSoundEffect {
  Hello1 = "Bonjour - Boy 1",
  Hello2 = "Hello - Boy 1",
  Hello3 = "Hello - Girl 1",
  Hello4 = "Hello - Girl 2",
  Hello5 = "Hey - Girl 1",
  Hello6 = "Hey - Girl 2",
  Hello7 = "Hey - Girl 3",
  Hello8 = "Hi - Boy 1",
  Hello9 = "Hi - Boy 2",
  Hello10 = "Hi - Girl 1",
  Hello11 = "Hi There - Girl 1",
  Hello12 = "Hola - Girl 1",
  Hello13 = "Lets Go - Girl 1",
  Hello14 = "OK - Girl 1",
  Hello15 = "Woohoo - Boy 1",
  Hello16 = "Woohoo - Girl 1",
  Hello17 = "Woohoo - Girl 2",
  Hello18 = "Yo - Boy 1",
  Hello19 = "Yo - Girl 1",
  Correct = "Correct Sound",
  Drumroll = "Drumroll",
  WinnerReveal = "And The Winner Is",
  BBTick = "Menu Button Snap",
  BBLose = "Sad Square",
  BBWin = "Smiley Square",
  Timer10 = "Timer Countdown 10 Seconds",
  Timer5 = "Timer Countdown 5 Seconds",
  GameStart = "Big Board Boom!",
  SplashIntro = "Whoosh 2",
  TeamTokenPlaced = "Vibraphone Notification",
  TeamResponseConfirmed = "Vibraphone Message 02",
  AllResponseConfirmed = "Vibraphone Message 04",
  ReviewSting = "Review Sting",
}

export enum StudentSessionSoundEffect {
  Correct = "Correct Sound",
  Incorrect = "Incorrect Sound",
  AccuracyXP = "KM Marimba Trill Accuracy - Short",
  BonusXP = "KM Marimba Trill Bonus - Short",
  PracticeXP = "KM Marimba Trill XP - Short",
  PracticeXPBoost = "xp boost base trill",
  EarnedXPBoost = "xp-boost-podium",
  CarePackageDeliveryFull = "care-package-delivery-gooper",
  CarePackageDeliveryShort = "care-package-open-only",
  QuickPlayCarePackageRound = "quick-play-care-package-round",
  CarePackageNotice = "care-package-received-AUDIO",
  DailyChallengeComplete = "daily-challenge-completed",
  WeeklyChallengeComplete = "weekly-challenge-completed",
  MonthlyChallengeComplete = "monthly-challenge-completed",
  LevelUp = "level-up-ticket-earned",
  WeekStreak1 = "week-streak-progress-1-postgame",
  WeekStreak2 = "week-streak-progress-2-postgame",
  WeekStreak3 = "week-streak-extended-postgame",
  ChoiceSelected = "MalletHit",
  Switch = "Switch 1",
  TeammateSelected = "Tap Click 2",
  TeammateConfirmed = "Vibraphone Ding Alert 01",
  Confirmed = "Vibraphone Ding",
  TokenPlaced = "Water Drop",
}
