import { Box, Center, Flex } from "@chakra-ui/react";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";

import { pxToRem } from "adminComponents/utils";
import { IPracticeSet } from "links/lib/types";

import { CompactLibraryPracticeSetCard } from "../CompactLibraryPracticeSetCard";
import { EmptyCard } from "../EmptyCard";
import { Pagination } from "../Pagination";

export interface IProps {
  practiceSets: IPracticeSet[];
  isLoading: boolean;
  page: number;
  perPage: number;
  totalCount: number;
  emptyStateTitle?: string;
  handleSetPage: (page: number) => void;
  onPracticeSetPreview: (set: IPracticeSet) => void;
}

export const PagedSearchResults: React.FC<IProps> = ({
  practiceSets,
  handleSetPage,
  page,
  perPage,
  totalCount,
  isLoading,
  emptyStateTitle,
  onPracticeSetPreview,
}) => {
  const { t: tSharedLibraries } = useTranslation("admin", {
    keyPrefix: "sharedLibraries",
    useSuspense: false,
  });
  const ref = useRef<HTMLDivElement>(null);

  return practiceSets.length ? (
    <Box ref={ref} w="full">
      <Flex gap={pxToRem(14)} wrap="wrap">
        {practiceSets.map((practiceSet, i) => (
          <CompactLibraryPracticeSetCard
            key={`${practiceSet.id}-${i}`}
            practiceSet={practiceSet}
            isLoading={isLoading}
            handleClick={() => {
              onPracticeSetPreview(practiceSet);
            }}
          />
        ))}
      </Flex>
      <Center paddingTop={pxToRem(20)}>
        <Pagination
          handleSetPage={(newPage: number) => {
            handleSetPage(newPage);
            // TODO using arrow buttons to get to first or last page does not scroll, unsure why
            if (newPage !== page) {
              ref.current?.scrollIntoView({
                behavior: "smooth",
              });
            }
          }}
          page={page}
          perPage={perPage}
          totalCount={totalCount}
        />
      </Center>
    </Box>
  ) : (
    <EmptyCard text={tSharedLibraries("emptyText")} title={emptyStateTitle} />
  );
};
