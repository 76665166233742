import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import {
  IAgreement,
  IFeatureFlags,
  ISharingSettings,
  IUser,
  SharingSettingsVisibility,
} from "../../types";
import { IMutateAuthUserArgs } from "../account/useMutateAuthUser";
import { useAuth } from "../auth";

export interface ICompleteTeacherOnboardingArgs {
  user: IMutateAuthUserArgs;
  acceptedAgreementIds: Array<string>;
  visibility: SharingSettingsVisibility;
}

export interface ICompleteTeacherOnboardingResponse {
  user: IUser;
  features: IFeatureFlags;
  required_agreements: Array<IAgreement>;
  sharing_settings: ISharingSettings;
}

export interface IUseCompleteTeacherOnboardingProps {
  onSuccess?: () => void;
  onError?: () => void;
  onSettled?: () => void;
}

export default function useCompleteTeacherOnboarding(
  props?: IUseCompleteTeacherOnboardingProps
): UseMutationResult<unknown, unknown, ICompleteTeacherOnboardingArgs> {
  const axios = useAxios();
  const queryClient = useQueryClient();

  const {
    authUser,
    setAuth,
    authToken,
    authLicense,
    hasNoPremiumAccess,
    isAuthLoading,
  } = useAuth();

  const mutateCompleteTeacherOnboarding = async (
    args: ICompleteTeacherOnboardingArgs
  ) => {
    if (!authUser) {
      throw new Error("Auth User not set");
    }

    const {
      teacher_preferred_name,
      teacher_title,
      teacher_school_or_district_name,
      teacher_school_or_district_postal_code,
      timezone_locale,
      language,
      country,
      region,
      grade_level_ids,
      subject_ids,
    } = args.user;

    const {
      given_name,
      student_nickname,
      family_name,
      role,
      profile_image_url,
      is_guest,
    } = authUser;

    const response = await axios
      .put(`/v1/users/self/onboarding`, {
        user: {
          given_name,
          family_name,
          student_nickname,
          teacher_preferred_name,
          teacher_title,
          teacher_school_or_district_name,
          teacher_school_or_district_postal_code,
          profile_image_url,
          role,
          timezone_locale,
          language,
          country,
          region,
          is_guest,
          teacher_grade_levels: grade_level_ids?.map((id) => {
            return { grade_level_id: id };
          }),
          teacher_subjects: subject_ids?.map((id) => {
            return { subject_id: id };
          }),
        },
        accepted_agreement_ids: args.acceptedAgreementIds,
        visibility: args.visibility,
      })
      .then((r) => r.data as ICompleteTeacherOnboardingResponse);

    queryClient.invalidateQueries("users", { exact: true });
    queryClient.invalidateQueries("onboarding-checklist");
    // completing onboarding can create a classroom for you if you have none
    queryClient.invalidateQueries("classrooms");

    // update app auth
    setAuth({
      authToken,
      authUser: response.user,
      authFeatureFlags: response.features,
      authRequiredAgreements: response.required_agreements,
      authSharingSettings: response.sharing_settings,
      authLicense,
      hasNoPremiumAccess,
      isGhostUser: response.user.ghost_user_email != "",
      isAuthLoading,
    });

    return response;
  };

  const mutation = useMutation(mutateCompleteTeacherOnboarding, {
    onError: props?.onError,
    onSuccess: props?.onSuccess,
    onSettled: props?.onSettled,
  });

  return mutation;
}
