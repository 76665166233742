import { pxToRem } from "adminComponents/utils";

export const AdminRichTextEditor = {
  baseStyle: ({
    hasError,
    isFocused,
  }: {
    hasError: boolean;
    isFocused: boolean;
  }): Record<string, unknown> => ({
    container: {
      border: isFocused ? `${pxToRem(2)} solid` : `${pxToRem(1)} solid`,
      borderColor: hasError
        ? "utility.error"
        : isFocused
        ? "utility.focus"
        : "primary.medium-gray",
      bgColor: "primary.white",
    },
    topContainer: {
      width: "full",
      paddingX: isFocused ? pxToRem(15) : pxToRem(16),
      paddingTop: isFocused ? pxToRem(13) : pxToRem(14),
      paddingBottom: pxToRem(14),
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
    },
  }),
};
