import { Box, Spacer } from "@chakra-ui/react";
import React from "react";
import { animated, config, useTransition } from "react-spring";

import { pxToRem } from "adminComponents/utils";

import BackgroundFlames from "../QuickPlayLogo/resource/quick_play_bg_pattern_flames.png";

const AnimatedBox = animated(Box);

export const QuickPlayBackground: React.FC = ({ children }) => {
  const childrenTransition = useTransition(true, {
    from: { opacity: 0, top: "-100%" },
    enter: { opacity: 1, top: "0%" },
    leave: { opacity: 0, top: "0%" },
    config: config.molasses,
    delay: 500,
  });

  return (
    <Box
      bgImage={BackgroundFlames}
      bgRepeat="repeat"
      bgSize={pxToRem(400)}
      h="-webkit-fill-available"
      w="100vw"
      zIndex={1002}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      position="fixed"
      top="0"
      bottom="0"
    >
      <Spacer />
      {childrenTransition(
        (styles, item) =>
          item && (
            <AnimatedBox style={styles} h="full" w="full" position="relative">
              {children}
            </AnimatedBox>
          )
      )}
      <Spacer />
    </Box>
  );
};
