import {
  Box,
  CSSObject,
  Collapse,
  Flex,
  FormControl,
  Link,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Checkbox } from "adminComponents/atoms/Checkbox";
import { Divider } from "adminComponents/atoms/Divider";
import { Dropdown, IOption } from "adminComponents/atoms/Dropdown";
import { FormErrorMessage } from "adminComponents/atoms/FormErrorMessage";
import { FormLabel } from "adminComponents/atoms/FormLabel";
import { Icon } from "adminComponents/atoms/Icon";
import { Input } from "adminComponents/atoms/Input";
import { pxToRem } from "adminComponents/utils";
import { countryMap, regionMap } from "links/lib/constants";
import { langLabelMap } from "links/lib/lang";
import {
  IAgreement,
  IGradeLevel,
  ISubject,
  SharingSettingsVisibility,
} from "links/lib/types";
import { getBrandConditionedAgreementUrl } from "links/lib/util";

import { Field, Formik } from "../../TeacherAccountInformationForm";
import { LocationForm } from "./LocationForm";
import { ProfileSettingsForm } from "./ProfileSettingsForm";
import {
  asyncSetFieldTouched,
  fields,
  isTeacherInfoFormValid,
} from "./formConfig";

interface IProps {
  form: Formik;
  agreements: Array<IAgreement>;
  setCanProceed?: (canProceed: boolean) => void;
  grades: Array<IGradeLevel>;
  subjects: Array<ISubject>;
  titles: Array<IOption>;
  fieldStyles: CSSObject;
  isOnboarding: boolean;
}

export const TeacherInfoForm: React.FC<IProps> = ({
  form,
  agreements,
  setCanProceed,
  grades: gradeOptions,
  subjects: subjectOptions,
  titles: titleOptions,
  fieldStyles,
  isOnboarding,
}) => {
  const { language, country, state, timezone, sharingVisibility } = form.values;

  const [locationFormExpanded, setLocationFormExpanded] = useState(
    !language || !country || !state || !timezone
  );
  const locationFormValuesString = useMemo(() => {
    const fullLanguage = langLabelMap[language];
    const fullCountry = countryMap[country];
    const fullState = regionMap[country]?.[state] || undefined;

    return [fullLanguage, fullCountry, fullState, timezone]
      .map((value) => value || "?")
      .join(", ");
  }, [language, country, state, timezone]);

  const { t, i18n } = useTranslation("admin", { useSuspense: false });

  const [profileSettingsFormExpanded, setProfileSettingsFormExpanded] =
    useState(false);
  const selectedSharingVisibility = useMemo(() => {
    switch (sharingVisibility) {
      case SharingSettingsVisibility.Private:
        return t("teacherProfileSettingsForm.visibilityPrivate");
      case SharingSettingsVisibility.GSOnly:
        return t("teacherProfileSettingsForm.visibilityGSOnly");
      case SharingSettingsVisibility.Public:
        return t("teacherProfileSettingsForm.visibilityPublic");
    }
  }, [sharingVisibility, t]);

  // Whether to show fields for district name, zip code, and teacher title. These are
  // not displayed during the onboarding flow, but should appear in the Account page.
  // This component is used for both.
  const showExtendedAccountInfoFields = !isOnboarding;

  useEffect(() => {
    if (!setCanProceed) return;
    if (isTeacherInfoFormValid(form.values, agreements.length)) {
      setCanProceed(true);
    } else {
      setCanProceed(false);
    }
  }, [form.values, setCanProceed, agreements.length]);

  const {
    nickname,
    grades,
    schoolOrDistrictName,
    schoolOrDistrictPostalCode,
    subjects,
    title,
  } = fields;

  const options = {
    [grades]: gradeOptions.map(({ id, grade_level }) => ({
      value: id,
      label: grade_level,
    })),
    [subjects]: subjectOptions.map(({ id, name }) => ({
      value: id,
      label: name,
    })),
  };

  const agreementsList = useMemo(() => {
    return new Intl.ListFormat(i18n.language, {
      style: "long",
      type: "conjunction",
    }).formatToParts(agreements.map((a) => a.id));
  }, [agreements, i18n.language]);

  const handleDropdownChange = (newValues: IOption[], field: Field) => {
    asyncSetFieldTouched(field, form).finally(() => {
      form.setFieldValue(
        field,
        newValues.map((val) => val.value)
      );
    });
  };

  const handleSingleDropdownChange = (newValue: IOption, field: Field) => {
    asyncSetFieldTouched(field, form).finally(() => {
      form.setFieldValue(field, newValue?.value);
    });
  };

  const handleBlur = (field: Field) => {
    asyncSetFieldTouched(field, form).finally(() => {
      form.handleBlur(field);
    });
  };

  const filterValues = (field: Field) => {
    const fieldOptions = options[field];
    const values = form.values[field] as string[];
    return fieldOptions.filter((option: IOption) =>
      values.includes(option.value)
    );
  };

  const handleAgreementChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const acceptedAgreementIds = e.target.checked
      ? agreements.map((a) => a.id)
      : [];

    form.setFieldValue("acceptedAgreementIds", acceptedAgreementIds);
  };

  return (
    <Flex sx={fieldStyles}>
      {/* Title Field */}
      {showExtendedAccountInfoFields && (
        <FormControl isInvalid={!!form.touched[title] && !!form.errors[title]}>
          <FormLabel htmlFor={title} id={`${title}-label`}>
            {t(`teacherAccountSetupForm.teacherInfoForm.${title}`)}
          </FormLabel>
          <Dropdown
            id={title}
            aria-labelled-by={`${title}-label`}
            options={titleOptions}
            value={
              titleOptions.find(
                (option) => option.value === form.values[title]
              ) || null
            }
            isDisabled={titleOptions.length === 0}
            handleBlur={() => handleBlur(title)}
            handleChange={(val) =>
              handleSingleDropdownChange(val as IOption, title)
            }
          />
          <FormErrorMessage>
            {t("teacherAccountSetupForm.errors.title")}
          </FormErrorMessage>
        </FormControl>
      )}
      {/* Nickname Field */}
      <FormControl
        isInvalid={
          (!!form.touched[nickname] ||
            !!form.values.acceptedAgreementIds.length) &&
          !!form.errors[nickname]
        }
      >
        <FormLabel htmlFor={nickname} id={`${nickname}-label`}>
          {t(`teacherAccountSetupForm.teacherInfoForm.${nickname}`)}
        </FormLabel>
        <Input
          id={nickname}
          name={nickname}
          aria-labelledby={`${nickname}-label`}
          value={form.values[nickname] as string}
          onBlur={() => handleBlur(nickname)}
          onChange={form.handleChange}
        />
        <FormErrorMessage>
          {t("teacherAccountSetupForm.errors.nickname")}
        </FormErrorMessage>
      </FormControl>
      {/* School Name Field */}
      {showExtendedAccountInfoFields && (
        <FormControl
          isInvalid={
            !!form.touched[schoolOrDistrictName] &&
            !!form.errors[schoolOrDistrictName]
          }
        >
          <FormLabel
            htmlFor={schoolOrDistrictName}
            id={`${schoolOrDistrictName}-label`}
          >
            {t(
              `teacherAccountSetupForm.teacherInfoForm.${schoolOrDistrictName}`
            )}
          </FormLabel>
          <Input
            id={schoolOrDistrictName}
            aria-labelledby={`${schoolOrDistrictName}-label`}
            name={schoolOrDistrictName}
            value={form.values[schoolOrDistrictName] as string}
            onBlur={() => handleBlur(schoolOrDistrictName)}
            onChange={form.handleChange}
          />
          <FormErrorMessage>
            {t("teacherAccountSetupForm.errors.schoolOrDistrictName")}
          </FormErrorMessage>
        </FormControl>
      )}
      {/* School Postal Code Field */}
      {showExtendedAccountInfoFields && (
        <FormControl
          isInvalid={
            !!form.touched[schoolOrDistrictPostalCode] &&
            !!form.errors[schoolOrDistrictPostalCode]
          }
        >
          <FormLabel
            htmlFor={schoolOrDistrictPostalCode}
            id={`${schoolOrDistrictPostalCode}-label`}
          >
            {t(
              `teacherAccountSetupForm.teacherInfoForm.${schoolOrDistrictPostalCode}`
            )}
          </FormLabel>
          <Input
            id={schoolOrDistrictPostalCode}
            aria-labelledby={`${schoolOrDistrictPostalCode}-label`}
            name={schoolOrDistrictPostalCode}
            value={form.values[schoolOrDistrictPostalCode] as string}
            onBlur={() => handleBlur(schoolOrDistrictPostalCode)}
            onChange={form.handleChange}
          />
          <FormErrorMessage>
            {t("teacherAccountSetupForm.errors.schoolOrDistrictPostalCode")}
          </FormErrorMessage>
        </FormControl>
      )}
      {/* Grades Field */}

      <FormControl>
        <FormLabel htmlFor={grades} id={`${grades}-label`}>
          {t(`teacherAccountSetupForm.teacherInfoForm.${grades}`)}
        </FormLabel>
        <Dropdown
          isMulti
          id={grades}
          aria-labelled-by={`${grades}-label`}
          options={options[grades]}
          value={filterValues(grades)}
          handleBlur={() => handleBlur(grades)}
          handleChange={(vals) =>
            handleDropdownChange(vals as IOption[], grades)
          }
        />
      </FormControl>

      {/* Subjects Field */}
      <FormControl>
        <FormLabel htmlFor={subjects} id={`${subjects}-label`}>
          {t(`teacherAccountSetupForm.teacherInfoForm.${subjects}`)}
        </FormLabel>
        <Dropdown
          isMulti
          id={subjects}
          aria-labelled-by={`${subjects}-label`}
          options={options[subjects]}
          value={filterValues(subjects)}
          handleBlur={() => handleBlur(subjects)}
          handleChange={(vals) =>
            handleDropdownChange(vals as IOption[], subjects)
          }
        />
      </FormControl>

      {isOnboarding && (
        <Flex flexDir="column" w="full" gap={pxToRem(12)}>
          <Flex
            borderRadius="md"
            cursor="pointer"
            justifyContent="space-between"
            py={pxToRem(6)}
            onClick={() => setLocationFormExpanded(!locationFormExpanded)}
          >
            <FormLabel cursor="pointer" my="auto">
              <Text as="span" color="utility.link">
                {t(
                  "teacherAccountSetupForm.teacherInfoForm.locationFormValues"
                )}
              </Text>
            </FormLabel>
            <Icon
              alignSelf="center"
              mr={pxToRem(6)}
              boxSize={pxToRem(18)}
              icon={
                locationFormExpanded
                  ? "keyboard_arrow_up"
                  : "keyboard_arrow_down"
              }
            />
          </Flex>
          {!locationFormExpanded && (
            <Text color="primary.warm-black">{locationFormValuesString}</Text>
          )}
          <Collapse
            style={{
              overflow: "initial",
            }}
            in={locationFormExpanded}
          >
            <Box w="full">
              <LocationForm form={form} fieldStyles={fieldStyles} />
            </Box>
          </Collapse>
          <Divider color="primary.light-gray" />
          <Flex
            borderRadius="md"
            cursor="pointer"
            justifyContent="space-between"
            py={pxToRem(6)}
            onClick={() =>
              setProfileSettingsFormExpanded(!profileSettingsFormExpanded)
            }
          >
            <FormLabel cursor="pointer" my="auto">
              <Text as="span" color="utility.link">
                {t("teacherAccountSetupForm.teacherInfoForm.profileSettings")}
              </Text>
            </FormLabel>
            <Icon
              alignSelf="center"
              mr={pxToRem(6)}
              boxSize={pxToRem(18)}
              icon={
                profileSettingsFormExpanded
                  ? "keyboard_arrow_up"
                  : "keyboard_arrow_down"
              }
            />
          </Flex>
          {!profileSettingsFormExpanded && (
            <Text color="primary.warm-black">{selectedSharingVisibility}</Text>
          )}
          <Collapse
            style={{
              overflow: "initial",
            }}
            in={profileSettingsFormExpanded}
          >
            <Box w="full">
              <ProfileSettingsForm form={form} fieldStyles={fieldStyles} />
            </Box>
          </Collapse>
          <Divider color="primary.light-gray" />
        </Flex>
      )}

      {/* Terms and Conditions Field */}
      {isOnboarding && !!agreements.length && (
        <FormControl>
          <Checkbox
            id="agreements"
            name="agreements"
            onChange={handleAgreementChange}
          >
            {t("teacherAccountSetupForm.teacherInfoForm.agreementsCheckbox")}
            &nbsp;
            {agreementsList.map((item) => {
              if (item.type === "literal") return item.value;

              const agreement = agreements.find((a) => a.id === item.value);

              if (!agreement) return;

              return (
                <Link
                  key={agreement.id}
                  color="utility.link"
                  href={getBrandConditionedAgreementUrl(
                    agreement.agreement_url
                  )}
                  target="_blank"
                  rel="noreferrer"
                >
                  {agreement.title}
                </Link>
              );
            })}
            *
          </Checkbox>
        </FormControl>
      )}
    </Flex>
  );
};
