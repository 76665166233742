import { Box } from "@chakra-ui/react";
import React from "react";

import { LoadingSpinner } from "adminComponents/atoms/LoadingSpinner";
import { PracticeSetsTab as AdminPracticeSetsTab } from "adminComponents/screens/StudentDetailScreen/components/PracticeSetsTab";
import { pxToRem } from "adminComponents/utils";
import { usePageTrack } from "lib/contexts/analytics";
import { AnalyticsPage, IClassroom } from "links/lib/types";

import { usePracticeSetsData } from "../hooks/usePracticeSetsData";

interface IPracticeSetsTabProps {
  classroom?: IClassroom;
  classroomLoading: boolean;
  classroomId: string;
  studentId: string;
}

export const PracticeSetsTab: React.FC<IPracticeSetsTabProps> = ({
  classroom,
  classroomLoading,
  classroomId,
  studentId,
}) => {
  usePageTrack(
    AnalyticsPage.TeacherDashboard_ClassroomDetail_StudentDetail_PracticeSets
  );

  const { practiceSetData, handleViewMore, isLoading } = usePracticeSetsData({
    classroomId,
    studentId,
  });

  return (
    <>
      {isLoading && classroomLoading ? (
        <Box mt={pxToRem(20)}>
          <LoadingSpinner />
        </Box>
      ) : (
        <>
          {classroom && (
            <AdminPracticeSetsTab
              classroom={classroom}
              handleViewMorePracticeSets={handleViewMore}
              practiceSetsData={practiceSetData}
              showViewMorePracticeSets={false}
              studentId={studentId}
            />
          )}
        </>
      )}
    </>
  );
};
