import React, { createContext, useContext, useMemo } from "react";

export const SessionServerTimeDeltasContext = createContext<
  number[] | undefined
>(undefined);

export interface ISessionServerTimeDeltasProviderProps {
  wsServerTimeDeltas?: number[];
}

export const SessionServerTimeDeltasProvider: React.FC<
  ISessionServerTimeDeltasProviderProps
> = ({ children, wsServerTimeDeltas }) => {
  const value = useMemo(() => wsServerTimeDeltas, [wsServerTimeDeltas]);

  return (
    <SessionServerTimeDeltasContext.Provider value={value}>
      {children}
    </SessionServerTimeDeltasContext.Provider>
  );
};

export const useSessionServerTimeDeltas = (): number[] | undefined => {
  return useContext(SessionServerTimeDeltasContext);
};
