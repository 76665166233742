import { HStack, useDisclosure } from "@chakra-ui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { useSessionGameState } from "links/lib/contexts/sessionGameState";
import { useSessionGroups } from "links/lib/contexts/sessionGroups";
import { useSessionScene } from "links/lib/contexts/sessionScene";
import { SessionGameType, SessionScene } from "links/lib/types";
import { IconButton } from "sessionComponents/atoms/IconButton";
import { Timer } from "sessionComponents/atoms/Timer";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { useStudent } from "sessionComponents/contexts/student";
import { GameFlyout } from "sessionComponents/molecules/Flyout";
import { Header } from "sessionComponents/molecules/Header";
import { MenuList, MenuListItem } from "sessionComponents/molecules/MenuList";
import { MyTeamModal } from "sessionComponents/molecules/MyTeamModal";
import { QuickPlayStreakIcon } from "sessionComponents/molecules/QuickPlayStreakIcon";
import { ScreenOrientation } from "sessionComponents/types";

import { useStudentStickers } from "../../contexts/studentStickers";

interface IStudentHeaderProps {
  headingText: string;
  timer?: {
    startsAt: string;
    endsAt: string;
  };
  cursorsVisible: boolean;
  isSoundEnabled: boolean;
  onToggleCursors?: (isEnabled: boolean) => void;
  onToggleSound?: (isEnabled: boolean) => void;
  onExit: () => void;
  hideStickers?: boolean;
  hideCursorButton?: boolean;
  isEndOfGame?: boolean;
  showTeamButton?: boolean;
  onRestart?: () => void;
  // value between 0 and 100
  sessionProgress?: number;
  hide?: boolean;
}

const StudentHeader: React.FC<IStudentHeaderProps> = ({
  headingText,
  timer,
  cursorsVisible,
  isSoundEnabled,
  onToggleCursors,
  onToggleSound,
  onExit,
  hideStickers,
  hideCursorButton,
  isEndOfGame,
  showTeamButton,
  onRestart,
  sessionProgress,
  hide,
}) => {
  const [showMyTeamModal, setShowMyTeamModal] = useState(false);
  const { showStickerSelectionModal } = useStudentStickers();
  const gameState = useSessionGameState();
  const student = useStudent();
  const sessionGroups = useSessionGroups();
  const scene = useSessionScene();
  const group = sessionGroups?.[student.session_group_id];
  const { orientation, match: currentBreakpoints } = useBreakpoints();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { t } = useTranslation("session", {
    useSuspense: false,
    keyPrefix: "studentHeader",
  });

  const isMobile = orientation === ScreenOrientation.Portrait;
  const buttonSize = pxToRem(currentBreakpoints.buttonHeight);
  const iconSize = pxToRem(currentBreakpoints.iconSize);
  const fontSize = pxToRem(currentBreakpoints.fontSize);

  const content = {
    menuHeading: t("menuHeading"),
    menuButton: t("menuButton"),
    exitButton: t("exitButton"),
    chooseStickerButton: t("chooseStickerButton"),
    turnSoundOffButton: t("turnSoundOffButton"),
    turnSoundOnButton: t("turnSoundOnButton"),
    turnCursorsOnButton: t("turnCursorsOnButton"),
    turnCursorsOffButton: t("turnCursorsOffButton"),
    backToHomeButton: t("backToHomeButton"),
    restartButton: t("restartButton"),
  };

  const onExitClick = () => {
    onClose();
    onExit();
  };

  const onCursorItemClick = () => {
    if (!onToggleCursors) return;

    onToggleCursors(!cursorsVisible);
  };

  const onSoundItemClick = () => {
    if (!onToggleSound) return;

    onToggleSound(!isSoundEnabled);
  };

  const onStickerClick = () => {
    if (orientation === ScreenOrientation.Portrait) {
      onClose();
    }

    showStickerSelectionModal(true);
  };

  const exitButtonText = isEndOfGame
    ? content.backToHomeButton
    : content.exitButton;

  let currentStreak = group?.question_streak || 0;
  if (currentStreak < 0 || currentStreak > 3) {
    console.warn(
      "Received Quick Play group question_streak outside of the valid range [0,3]",
      { question_streak: currentStreak }
    );
    currentStreak = 0;
  }

  if (hide) return <></>;

  return (
    <>
      <Header progress={sessionProgress} headingText={headingText}>
        <HStack h="full">
          {gameState?.game_type === SessionGameType.QuickPlay &&
            [SessionScene.Round, SessionScene.RoundReview].includes(scene) && (
              <QuickPlayStreakIcon
                streakCount={currentStreak}
                animationDelayMs={
                  scene !== SessionScene.RoundReview ? 0 : undefined
                }
              />
            )}
          {timer && (
            <Timer
              startsAt={timer.startsAt}
              endsAt={timer.endsAt}
              fontSize={fontSize}
              boxSize={buttonSize}
            />
          )}
          {!isMobile && !hideStickers && (
            <IconButton
              boxSize={buttonSize}
              iconSize={iconSize}
              icon="chat"
              onClick={() => showStickerSelectionModal(true)}
              aria-label={content.chooseStickerButton}
            />
          )}
          <IconButton
            boxSize={buttonSize}
            iconSize={iconSize}
            icon="menu"
            onClick={onOpen}
            aria-label={content.menuButton}
          />
        </HStack>
        <GameFlyout
          title={content.menuHeading}
          isOpen={isOpen}
          onClose={onClose}
        >
          <MenuList>
            {/* todo after MVP: user status  */}
            {/* <MenuListItem icon="circle_filled" iconColor="utility.success">
            Status: Ready
          </MenuListItem>
          */}
            {isMobile && !hideStickers && (
              // <MenuListDivider />
              <MenuListItem icon="chat" onClick={onStickerClick}>
                {content.chooseStickerButton}
              </MenuListItem>
              // todo after mvp: reinstate dividers
              //  <MenuListDivider />
            )}

            {!hideCursorButton && (
              <MenuListItem icon="pointer" onClick={onCursorItemClick}>
                {cursorsVisible
                  ? content.turnCursorsOffButton
                  : content.turnCursorsOnButton}
              </MenuListItem>
            )}

            <MenuListItem
              icon={isSoundEnabled ? "sound_on" : "sound_off"}
              onClick={onSoundItemClick}
            >
              {isSoundEnabled
                ? content.turnSoundOffButton
                : content.turnSoundOnButton}
            </MenuListItem>
            {showTeamButton && (
              <MenuListItem
                icon="team"
                onClick={() => setShowMyTeamModal(true)}
              >
                {t("showMyTeamButton")}
              </MenuListItem>
            )}
            {onRestart && scene !== SessionScene.Podium && (
              <MenuListItem
                icon="refresh"
                onClick={onRestart}
                confirmedWhenChanged={[]}
              >
                {content.restartButton}
              </MenuListItem>
            )}
            <MenuListItem icon="exit" onClick={onExitClick}>
              {exitButtonText}
            </MenuListItem>
          </MenuList>
        </GameFlyout>
      </Header>
      {showMyTeamModal && (
        <MyTeamModal
          isOpen={showMyTeamModal}
          onClose={() => setShowMyTeamModal(false)}
        />
      )}
    </>
  );
};

export { StudentHeader };
