import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useSessionStorage } from "react-use";
import { string } from "yup";

import { GoogleClassroomAuthorizeModal } from "adminComponents/organisms/GoogleClassroomAuthorizeModal";
import { GoogleClassroomImportModal } from "adminComponents/organisms/GoogleClassroomImportModal";
import {
  SelectStudentModal,
  StudentItem,
} from "adminComponents/organisms/SelectStudentModal";
import { generateStudentName } from "adminComponents/utils";
import { useErrorToast, useShowToast } from "adminComponents/utils/toast";
import { afterSignInPathSessionKey } from "lib/constants";
import { useAnalytics } from "lib/contexts/analytics";
import { useHubSpot } from "lib/hooks/useHubSpot";
import {
  useFetchGoogleClassroomClassrooms,
  useMutateClassroom,
} from "links/lib/features/classrooms";
import { IMutateClassroomResponse } from "links/lib/features/classrooms/useMutateClassroom";
import {
  AnalyticsEvent,
  GoogleClassroomClassroom,
  ThirdParty,
  UserRole,
} from "links/lib/types";
import { sortAlpha } from "links/lib/util";
import useFetchProviderRedirectUrl from "screens/SignIn/useFetchProviderRedirectUrl";

export interface IResult {
  handleAddIntent: () => void;
  authorizeModal: React.ReactElement;
  classroomSelectModal: React.ReactElement;
  studentSelectModal: React.ReactElement;
}

const emailSchema = string().email();

export const useImportClassroomByGClassroom = (): IResult => {
  const [isAuthorizeModalOpen, setIsAuthorizeModalOpen] =
    useState<boolean>(false);
  const [isClassroomSelectModalOpen, setIsClassroomSelectModalOpen] =
    useState<boolean>(false);
  const [isStudentSelectModalOpen, setIsStudentSelectModalOpen] =
    useState<boolean>(false);
  const [selectedGoogleClassroom, setSelectedGoogleClassroom] =
    useState<GoogleClassroomClassroom | null>(null);
  const showToast = useShowToast();
  const { t } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "classroomDetailContainer.studentsTab.importClassByGClassroom",
  });
  const { trackEvent } = useAnalytics();
  const { trackHubSpotEvent } = useHubSpot();

  const [disableNotifications, setDisableNotifications] =
    useState<boolean>(false);

  const [, setSessionRedirectPath] = useSessionStorage(
    afterSignInPathSessionKey,
    "/"
  );

  const history = useHistory();

  const handleClassroomMutationSuccess = useCallback(
    (classroomResponse: IMutateClassroomResponse) => {
      setIsStudentSelectModalOpen(false);
      setSelectedGoogleClassroom(null);
      showToast(t("successToast"));

      trackHubSpotEvent(AnalyticsEvent.HubSpot_ImportClassroom, {});

      history.push(`/t/classrooms/${classroomResponse.classroom.id}`, {
        addedStudent: true,
        disableNotifications,
      });
    },
    [
      showToast,
      t,
      trackHubSpotEvent,
      setIsStudentSelectModalOpen,
      history,
      disableNotifications,
    ]
  );

  const gclassroomFetch = useFetchGoogleClassroomClassrooms();
  const redirectFetch = useFetchProviderRedirectUrl();
  const groupMutation = useMutateClassroom({
    onSuccess: handleClassroomMutationSuccess,
  });

  useErrorToast(gclassroomFetch.error);
  useErrorToast(redirectFetch.error);
  useErrorToast(groupMutation.error);

  const handleAddIntent = useCallback(() => {
    setIsClassroomSelectModalOpen(true);
  }, []);

  const handleAuthorizeClose = useCallback(() => {
    setIsAuthorizeModalOpen(false);
  }, []);

  const handleGrantAccess = useCallback(() => {
    if (!redirectFetch.data) return;

    trackEvent(
      AnalyticsEvent.TeacherDashboard_ClassroomDetail_StudentsTab_AddByGClassroomAuthorize,
      {}
    );

    setSessionRedirectPath(window.location.pathname);
    window.location.href = redirectFetch.data.redirectUrl;
  }, [redirectFetch, setSessionRedirectPath, trackEvent]);

  const handleClassroomSelectModalClose = useCallback(() => {
    setIsClassroomSelectModalOpen(false);
  }, []);

  const handlePickGoogleClassroom = useCallback(
    (googleClassroom: GoogleClassroomClassroom) => {
      setIsClassroomSelectModalOpen(false);
      setSelectedGoogleClassroom(googleClassroom);
      setIsStudentSelectModalOpen(true);
    },
    []
  );

  const handleStudentSelectModalClose = useCallback(() => {
    setIsStudentSelectModalOpen(false);
  }, []);

  const handleStudentSelectSubmit = useCallback(
    (emails: Array<string>) => {
      if (!selectedGoogleClassroom) return;

      const users = emails.map((email) => ({
        id: "0",
        email: email,
        role: UserRole.Student,
        true_role: UserRole.Student,
        license_id: "0",
        timezone_locale: "",
        language: "",
        is_guest: false,
        country: "",
        region: "",
      }));

      groupMutation.mutate({
        name: selectedGoogleClassroom.name,
        description: selectedGoogleClassroom.description,
        users: users,
        third_party: ThirdParty.GOOGLE_CLASSROOM,
        third_party_id: selectedGoogleClassroom.id,
        assignment_notifications_disabled: false,
        disableNotifications,
      });
    },
    [groupMutation, selectedGoogleClassroom, disableNotifications]
  );

  useEffect(() => {
    if (
      gclassroomFetch.data?.requires_authorization &&
      isClassroomSelectModalOpen
    ) {
      setIsClassroomSelectModalOpen(false);
      setIsAuthorizeModalOpen(true);
      redirectFetch.execute({
        provider: "google_classroom",
        role: UserRole.Teacher,
      });
    }
  }, [
    gclassroomFetch.data?.requires_authorization,
    isClassroomSelectModalOpen,
    redirectFetch,
  ]);

  const sortedClassrooms = useMemo(() => {
    return (
      gclassroomFetch.data?.classrooms.sort((a, b) =>
        sortAlpha(a.name, b.name)
      ) || []
    );
  }, [gclassroomFetch.data?.classrooms]);

  const authorizeModal = useMemo(() => {
    return (
      <GoogleClassroomAuthorizeModal
        isOpen={isAuthorizeModalOpen}
        isLoading={redirectFetch.isLoading}
        handleGrantAccess={handleGrantAccess}
        handleClose={handleAuthorizeClose}
      />
    );
  }, [
    isAuthorizeModalOpen,
    handleAuthorizeClose,
    handleGrantAccess,
    redirectFetch.isLoading,
  ]);

  const classroomSelectModal = useMemo(() => {
    return (
      <GoogleClassroomImportModal
        handleClose={handleClassroomSelectModalClose}
        handlePickGoogleClassroom={handlePickGoogleClassroom}
        isOpen={isClassroomSelectModalOpen}
        isLoading={gclassroomFetch.isLoading}
        googleClassrooms={sortedClassrooms}
      />
    );
  }, [
    handleClassroomSelectModalClose,
    handlePickGoogleClassroom,
    isClassroomSelectModalOpen,
    gclassroomFetch.isLoading,
    sortedClassrooms,
  ]);

  const initialSelectedStudents = useMemo(() => [], []);

  const studentSelectModal = useMemo(() => {
    return (
      <SelectStudentModal
        isOpen={isStudentSelectModalOpen}
        isLoading={groupMutation.isLoading}
        selectedStudentIds={initialSelectedStudents}
        handleClose={handleStudentSelectModalClose}
        handleSubmit={handleStudentSelectSubmit}
        requireStudentSelection={false}
        disableNotifications={disableNotifications}
        setDisableNotifications={setDisableNotifications}
      >
        {selectedGoogleClassroom?.students.map((s, i) => {
          const name = generateStudentName({
            given_name: s.given_name,
            family_name: s.family_name,
          });

          const isInvalid = s.email === "" || !emailSchema.isValidSync(s.email);

          return (
            <StudentItem
              key={`student-${s.email}-${i}`}
              id={s.email}
              displayName={`${name.primary} (${s.email})`}
              isInvalid={isInvalid}
            />
          );
        })}
      </SelectStudentModal>
    );
  }, [
    handleStudentSelectModalClose,
    handleStudentSelectSubmit,
    isStudentSelectModalOpen,
    selectedGoogleClassroom,
    groupMutation.isLoading,
    initialSelectedStudents,
    disableNotifications,
  ]);

  return useMemo(() => {
    return {
      handleAddIntent,
      authorizeModal,
      classroomSelectModal,
      studentSelectModal,
    };
  }, [
    authorizeModal,
    handleAddIntent,
    classroomSelectModal,
    studentSelectModal,
  ]);
};
