import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { AvatarSkeletonType, IFetchAvatarResponse } from "../../types";

export interface IMutateUserAvatarSettingsArgs {
  user_id: string;
  hair_color_hex_code?: string;
  skin_tone_hex_code?: string;
  skeleton_type?: AvatarSkeletonType;
}

/**
 * Create or update a user's avatar settings
 * @returns
 */
export default function useMutateUserAvatarSettings(): UseMutationResult<
  IFetchAvatarResponse,
  unknown,
  IMutateUserAvatarSettingsArgs
> {
  const axios = useAxios();
  const queryClient = useQueryClient();

  const mutateUserAvatarSettings = async (
    args: IMutateUserAvatarSettingsArgs
  ) => {
    const { user_id, hair_color_hex_code, skin_tone_hex_code, skeleton_type } =
      args;

    const response = await axios({
      method: "post",
      url: `/v1/avatar/${user_id}/settings`,
      data: {
        hair_color_hex_code,
        skin_tone_hex_code,
        skeleton_type,
      },
    }).then((r) => r.data as IFetchAvatarResponse);

    queryClient.invalidateQueries("avatar", { exact: true });

    return response;
  };

  const mutation = useMutation(mutateUserAvatarSettings);

  return mutation;
}
