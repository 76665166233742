import { Box, Grid, VStack, useBreakpointValue } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { AvatarPhotoBackgroundCard } from "adminComponents/atoms/AvatarPhotoBackgroundCard";
import { Button } from "adminComponents/atoms/Button";
import { Dropdown, IOption } from "adminComponents/atoms/Dropdown";
import { Icon } from "adminComponents/atoms/Icon";
import { Text } from "adminComponents/atoms/Text";
import { Carousel } from "adminComponents/molecules/Carousel";
import { ColorPicker } from "adminComponents/molecules/ColorPicker";
import { pxToRem } from "adminComponents/utils";
import { CoverImageBGColorSchemes } from "links/lib/constants";
import {
  CoverImageBGColorSchemeType,
  CoverImageBGPatternType,
} from "links/lib/types";

export interface IAvatarPhotoControlsProps {
  selectedBGPattern?: CoverImageBGPatternType;
  selectedBGColor?: CoverImageBGColorSchemeType;
  selectedAnimation?: IOption;
  bgPatterns: Readonly<Array<CoverImageBGPatternType>>;
  bgColors: Readonly<Array<CoverImageBGColorSchemeType>>;
  animations: Array<IOption>;
  handleBGPatternChange?: (bg: CoverImageBGPatternType) => void;
  handleBGColorChange?: (color: CoverImageBGColorSchemeType) => void;
  handleAnimationChange?: (animation: IOption) => void;
  handleSubmit?: () => void;
}

const toRGBString = (rgb: [number, number, number]): string => {
  return `rgb(${rgb.join(",")})`;
};

export const AvatarPhotoControls: React.FC<IAvatarPhotoControlsProps> = ({
  bgColors,
  bgPatterns,
  animations,
  selectedBGPattern,
  selectedBGColor,
  selectedAnimation,
  handleBGColorChange,
  handleBGPatternChange,
  handleAnimationChange,
  handleSubmit,
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "avatarPhotoControls",
  });
  const isMobile = useBreakpointValue({ base: true, lg: false });

  // Transform BG color scheme type to single color strings
  const selectedColor = selectedBGColor
    ? toRGBString(CoverImageBGColorSchemes[selectedBGColor][0])
    : undefined;
  const colorOptions = bgColors.map((colorName) => {
    return toRGBString(CoverImageBGColorSchemes[colorName][0]);
  });

  const handleSelectColor = (color: string) => {
    const i = colorOptions.indexOf(color);
    handleBGColorChange?.(bgColors[i]);
  };

  const chunkSize = 6;
  const patternGroups: Array<Array<CoverImageBGPatternType>> = [];

  for (let i = 0; i < bgPatterns.length; i += chunkSize) {
    const chunk = bgPatterns.slice(i, i + chunkSize);
    patternGroups.push(chunk);
  }

  const renderPatternCards = (
    patterns: Readonly<Array<CoverImageBGPatternType>>
  ) =>
    patterns.map((pattern) => (
      <AvatarPhotoBackgroundCard
        handleClick={handleBGPatternChange}
        key={pattern}
        bgPattern={pattern}
        bgColorScheme={selectedBGColor ?? "LIGHT_BLUE"}
        isSelected={pattern === selectedBGPattern}
      />
    ));

  const mobileBgOptions = (
    <Box mt={pxToRem(16)} overflow="hidden" position="relative" width="100%">
      <Grid
        templateRows="1fr 1fr"
        gridAutoFlow="column"
        width="fit-content"
        overflowY="auto"
        maxWidth="100vw"
        rowGap={pxToRem(16)}
        columnGap={pxToRem(16)}
        mb={pxToRem(-16)}
        pb={pxToRem(16)}
        px={pxToRem(16)}
      >
        {renderPatternCards(bgPatterns)}
      </Grid>
      <Box
        bgGradient={`linear(to right, primary.warm-white, rgba(253, 250, 243, 0) 100%)`}
        sx={{
          display: "block",
          width: pxToRem(16),
          height: "100%",
          zIndex: 10,
          position: "absolute",
          left: 0,
          top: 0,
        }}
      />
      <Box
        bgGradient={`linear(to left, primary.warm-white, rgba(253, 250, 243, 0) 100%)`}
        sx={{
          display: "block",
          width: pxToRem(16),
          height: "100%",
          zIndex: 10,
          position: "absolute",
          right: 0,
          top: 0,
        }}
      />
    </Box>
  );

  const desktopBgOptions = (
    <Carousel
      id="background-carousel"
      variant="adminCarousel"
      slidesPerView={1}
      spaceBetween={24}
      showArrows
    >
      {patternGroups.map((patternGroup, i) => (
        <Grid
          key={`group-${i}`}
          templateColumns="1fr 1fr 1fr"
          gap={{ base: pxToRem(16), lg: pxToRem(24) }}
        >
          {renderPatternCards(patternGroup)}
        </Grid>
      ))}
    </Carousel>
  );

  return (
    <VStack
      w="full"
      alignItems={{ base: "flex-start", lg: "center" }}
      gap={pxToRem(24)}
    >
      <Box
        maxW={{
          base: "full",
          lg: pxToRem(590),
        }}
        sx={{
          ".swiper": {
            px: 0,
            py: 0,
          },
        }}
      >
        <Text
          variant="adminP1"
          mb={pxToRem(24)}
          px={{ base: pxToRem(16), lg: pxToRem(60) }}
        >
          {t("colorLabel")}
        </Text>
        <ColorPicker
          id="bg-colorpicker"
          colors={colorOptions}
          selectedColor={selectedColor}
          handleColorClick={handleSelectColor}
          display="carousel"
          translationKey="avatarPhotoControls.colorButton"
        />
      </Box>
      <Box
        maxW={{
          base: "full",
          lg: pxToRem(590),
        }}
        sx={{
          ".swiper": {
            px: 0,
            py: 0,
          },
        }}
      >
        <Text
          variant="adminP1"
          mb={pxToRem(24)}
          px={{ base: pxToRem(16), lg: pxToRem(60) }}
        >
          {t("backgroundLabel")}
        </Text>
        {isMobile ? mobileBgOptions : desktopBgOptions}
      </Box>
      <Box
        maxW={{
          base: "full",
          lg: pxToRem(590),
        }}
        w="full"
      >
        <Text
          variant="adminP1"
          mb={pxToRem(24)}
          px={{ base: pxToRem(16), lg: pxToRem(60) }}
        >
          {t("animationLabel")}
        </Text>
        <Box px={{ base: pxToRem(16), lg: pxToRem(60) }}>
          <Dropdown
            value={selectedAnimation}
            options={animations}
            isSearchable={false}
            handleChange={(val: unknown) =>
              handleAnimationChange?.(val as IOption)
            }
          />
        </Box>
      </Box>
      <Box
        w="full"
        display="flex"
        justifyContent="center"
        pb={{ base: pxToRem(24), lg: pxToRem(48) }}
      >
        <Button
          size="lg"
          leftIcon={<Icon icon="photo_camera" />}
          variant="adminButtonFilled"
          onClick={handleSubmit}
        >
          {t("submitButton")}
        </Button>
      </Box>
    </VStack>
  );
};
