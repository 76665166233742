import { UseQueryResult, useQuery } from "react-query";

import { OnDemandIntent } from "links/lib/types";

import { useAxios } from "../../hooks/useAxios";

export interface IFetchOnDemandSessionPreviewResponse {
  on_demand_session_previews: Array<{
    item_count: number;
    maximum_xp: number;
    subject_id: string;
    subject_name: string;
    session_id: string;
    practice_set_id: string;
    practice_set_title: string;
    new_session_permitted: boolean;
  }>;
}

export interface IFetchOnDemandSessionPreviewArgs {
  on_demand_intent: OnDemandIntent;
}

/**
 * Fetches the summary of an on demand session
 * @returns
 */
export default function useFetchOnDemandSessionPreview(
  args: IFetchOnDemandSessionPreviewArgs
): UseQueryResult<IFetchOnDemandSessionPreviewResponse, unknown> {
  const axios = useAxios();

  const query = useQuery<IFetchOnDemandSessionPreviewResponse>(
    ["on-demand-session-preview", args.on_demand_intent],
    async (): Promise<IFetchOnDemandSessionPreviewResponse> => {
      const response = await axios
        .get(`/v1/practice-sets/sessions/users/0/on-demand/previews`, {
          params: {
            on_demand_intent: args.on_demand_intent,
          },
        })
        .then((r) => r.data as IFetchOnDemandSessionPreviewResponse);

      return response;
    }
  );

  return query;
}
