import {
  PlacementWithLogical,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from "@chakra-ui/react";
import React from "react";

import { pxToRem } from "../../../adminComponents/utils/pxToRem";

import { useBreakpoints } from "../../contexts/breakpoints";

interface ITooltipProps {
  tooltipContent: string | React.ReactNode;
  trigger?: "hover" | "click";
  placement?: PlacementWithLogical;
  borderRadius?: number;
  isDisabled?: boolean;
}

export const Tooltip: React.FC<ITooltipProps> = ({
  tooltipContent,
  children,
  trigger = "hover",
  placement = "bottom",
  borderRadius,
  isDisabled,
}) => {
  const { match: currentBreakpoints } = useBreakpoints();
  const { padding, fontSize } = currentBreakpoints;

  const bRadius = borderRadius ?? currentBreakpoints.borderRadius;

  const fontArrowSize = fontSize * 0.75;

  if (isDisabled) return <>{children}</>;
  return (
    <Popover
      trigger={trigger}
      arrowSize={fontArrowSize}
      arrowPadding={fontArrowSize}
      offset={[fontArrowSize / 2, fontArrowSize]}
      arrowShadowColor="transparent"
      placement={placement}
    >
      <PopoverTrigger>{children}</PopoverTrigger>
      <Portal>
        <PopoverContent
          color="primary.white"
          bg="primary.warm-black"
          borderColor="transparent"
          borderRadius={pxToRem(bRadius)}
          paddingY={pxToRem(padding / 4)}
          paddingX={pxToRem(padding / 2)}
          width="fit-content"
        >
          <PopoverArrow bg="primary.warm-black" />
          <PopoverBody
            fontSize={pxToRem(fontArrowSize)}
            textAlign="center"
            fontWeight="600"
            padding="0px"
          >
            {tooltipContent}
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
