import { TextProps } from "@chakra-ui/react";
import React from "react";

import { Text } from "adminComponents/atoms/Text";

export const VerticalTextDivider: React.FC<TextProps> = ({
  variant = "adminP1",
  color = "primary.light-gray",
}) => (
  <Text variant={variant} color={color}>
    |
  </Text>
);
