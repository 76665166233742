import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";

export interface ISocialEventReactionsCountResponse {
  social_event_reactions_count: string;
  prev_window_social_event_reactions_count: string;
}

export interface IUseFetchSocialEventReactionsCountArgs {
  period_days?: number;
  enabled?: boolean;
}

export default function useFetchSocialEventReactionsCount(
  args: IUseFetchSocialEventReactionsCountArgs
): UseQueryResult<ISocialEventReactionsCountResponse> {
  const { enabled = true, period_days } = args;
  const axios = useAxios();

  const query = useQuery<ISocialEventReactionsCountResponse>(
    ["socialEventReactionsCount"],
    async (): Promise<ISocialEventReactionsCountResponse> => {
      return await axios
        .get("/v1/social/reactions/count", { params: { period_days } })
        .then((r) => {
          return r.data as ISocialEventReactionsCountResponse;
        });
    },
    { enabled }
  );

  return query;
}
