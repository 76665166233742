import { Box, SimpleGrid } from "@chakra-ui/react";
import React from "react";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { useSessionActions } from "links/lib/contexts/sessionActions";
import { useSessionGroups } from "links/lib/contexts/sessionGroups";
import {
  IPracticeSetSessionItemMultipleChoiceChoice,
  PracticeSessionItemVariantType,
} from "links/lib/types";
import { Tooltip } from "sessionComponents/atoms/Tooltip";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { QuestionResponse } from "sessionComponents/molecules/QuestionResponse";
import { getFormattedGroupName } from "sessionComponents/utils/getFormattedGroupName";

interface IDrawReviewOptionsProps {
  drawOptions: IPracticeSetSessionItemMultipleChoiceChoice[];
  isTeacher: boolean;
  optionsSelectable: boolean;
  selectedOptionId?: string;
  handleDrawOptionClick?: (optionId: string) => void;
  isTeacherVotingRound?: boolean;
}

export const DrawReviewOptions: React.FC<IDrawReviewOptionsProps> = ({
  drawOptions,
  isTeacher,
  optionsSelectable,
  selectedOptionId,
  handleDrawOptionClick,
  isTeacherVotingRound,
}) => {
  const { setDrawingHidden } = useSessionActions();
  const groups = useSessionGroups();
  const {
    match: { margin },
  } = useBreakpoints();

  const tooltipDisabled = !isTeacher || (isTeacher && isTeacherVotingRound);

  return (
    <SimpleGrid
      columns={drawOptions.length === 1 ? 1 : 2}
      spacing={pxToRem(margin / 2)}
    >
      {drawOptions.map((option) => {
        return (
          <Tooltip
            tooltipContent={getFormattedGroupName(groups[option.id])}
            placement="top"
            key={option.id}
            isDisabled={tooltipDisabled}
          >
            <Box display="flex" alignItems="center" justifyContent="center">
              <QuestionResponse
                variant={PracticeSessionItemVariantType.CoopDraw}
                imageUrl={option.image_url}
                imageAltText={option.image_alt_text}
                showAsSelectable={optionsSelectable}
                onDeleteButtonClick={() =>
                  isTeacher && setDrawingHidden(option.id, true)
                }
                allowDeleteImage={isTeacher}
                selected={selectedOptionId === option.id}
                onClick={() =>
                  handleDrawOptionClick && handleDrawOptionClick(option.id)
                }
              />
            </Box>
          </Tooltip>
        );
      })}
    </SimpleGrid>
  );
};
