import { Box } from "@chakra-ui/react";
import React from "react";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { ISessionGroup } from "links/lib/types";
import { GroupChoicePlaceholder } from "sessionComponents/atoms/GroupChoicePlaceholder";
import { getAssetColorScheme } from "sessionComponents/utils/getAssetColorScheme";

// implementing filling bigBoard spaces like designed in https://www.figma.com/file/jBWuV2IHGeoBmAK9RCNwNC/Gooper-Design-System?node-id=3497%3A141602
const layouts = {
  "0": [
    [0, 0, 0],
    [0, 0, 0],
    [0, 0, 0],
  ],
  "1": [
    [0, 0, 0],
    [0, 1, 0],
    [0, 0, 0],
  ],
  "2": [
    [1, 0, 0],
    [0, 0, 0],
    [0, 0, 1],
  ],
  "3": [
    [1, 0, 0],
    [0, 1, 0],
    [0, 0, 1],
  ],
  "4": [
    [1, 0, 1],
    [0, 0, 0],
    [1, 0, 1],
  ],
  "5": [
    [1, 0, 1],
    [0, 1, 0],
    [1, 0, 1],
  ],
  "6": [
    [1, 0, 1],
    [1, 0, 1],
    [1, 0, 1],
  ],
  "7": [
    [1, 0, 1],
    [1, 1, 1],
    [1, 0, 1],
  ],
  "8": [
    [1, 1, 1],
    [1, 0, 1],
    [1, 1, 1],
  ],
  "9": [
    [1, 1, 1],
    [1, 1, 1],
    [1, 1, 1],
  ],
};

interface Placement {
  round_number: number;
  group: ISessionGroup;
  showPlaceholder?: boolean;
}

interface IGetPlacementGraphProps {
  placements: Array<Placement>;
  placeholderSize: number;
  borderWidthRems: string;
  hideEmptyPlacements: boolean;
}

const getEmptySlot = ({
  placeholderSize,
  borderWidthRems,
  key,
  hideEmptyPlacements,
}: {
  placeholderSize: number;
  borderWidthRems: string;
  key: string;
  hideEmptyPlacements: boolean;
}): React.ReactNode => {
  const placeholderSizeRems = pxToRem(placeholderSize);

  return (
    <Box
      key={key}
      maxW={placeholderSizeRems}
      h={placeholderSizeRems}
      w={placeholderSizeRems}
      maxH={placeholderSizeRems}
      borderWidth={borderWidthRems}
      borderRadius="full"
      borderColor="primary.tan"
      style={hideEmptyPlacements ? { visibility: "hidden" } : {}}
    />
  );
};

const getTokenPlaceholder = ({
  placeholderSize,
  placement,
}: {
  placeholderSize: number;
  placement: Placement;
}): React.ReactNode => {
  const { group, round_number, showPlaceholder } = placement;
  const placeholderSizeRems = pxToRem(placeholderSize);

  return showPlaceholder ? (
    <Box
      key={`${group.id}-${round_number}`}
      maxW={placeholderSizeRems}
      h={placeholderSizeRems}
      w={placeholderSizeRems}
      maxH={placeholderSizeRems}
      borderRadius="full"
      bg="primary.tan"
    />
  ) : (
    <GroupChoicePlaceholder
      key={`${group.id}-${round_number}`}
      size={placeholderSize}
      colorScheme={getAssetColorScheme(group.color_scheme)}
    />
  );
};

const layoutToPlacements = ({
  layout,
  borderWidthRems,
  placeholderSize,
  placements,
  hideEmptyPlacements,
}: {
  layout: number[][];
  placements: Array<Placement>;
  placeholderSize: number;
  borderWidthRems: string;
  hideEmptyPlacements: boolean;
}) => {
  const placedTokens: React.ReactNode[][] = [];

  let currentPlacementIndex = 0;

  for (let rowIndex = 0; rowIndex < layout.length; rowIndex++) {
    const row = [];
    for (
      let columnIndex = 0;
      columnIndex < layout[rowIndex].length;
      columnIndex++
    ) {
      if (layout[rowIndex][columnIndex]) {
        row.push(
          getTokenPlaceholder({
            placeholderSize,
            placement: placements[currentPlacementIndex],
          })
        );
        currentPlacementIndex++;
      } else {
        row.push(
          getEmptySlot({
            borderWidthRems,
            placeholderSize,
            key: `bbCell-${rowIndex}-${columnIndex}`,
            hideEmptyPlacements,
          })
        );
      }
    }
    placedTokens.push(row);
  }

  return placedTokens;
};

export const getPlacementGraph = ({
  placements,
  placeholderSize,
  borderWidthRems,
  hideEmptyPlacements,
}: IGetPlacementGraphProps): React.ReactNode[][] => {
  // ensure we only show 9 placements max
  const slicedPlacements = placements.slice(0, 9);
  const lengthString = `${slicedPlacements.length}` as keyof typeof layouts;

  // since we will only have 9 placements max, safely fetch correct layout
  const layout = layouts[lengthString];

  return layoutToPlacements({
    layout,
    borderWidthRems,
    placeholderSize,
    placements,
    hideEmptyPlacements,
  });
};
