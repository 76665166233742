import { useMemo } from "react";

import { ISearchQuery } from "lib/hooks/useLibraryFilter";
import { ISubject } from "links/lib/types";

export const useIsSingleParentSubjectSelected = (
  query: ISearchQuery,
  subjects: ISubject[],
  parentSubjects: ISubject[]
): ISubject | undefined => {
  const singleSelectedParentSubject = useMemo(() => {
    const selectedSubjects = (query.filters.subjectIds || []).flatMap(
      (subjectId) => subjects.find((subject) => subject.id === subjectId) || []
    );

    const selectedSubjectsByParent: { [key: string]: boolean } = {};
    selectedSubjects.forEach((subject) => {
      if (subject.parent_id === "0") {
        selectedSubjectsByParent[subject.id] = true;
      }
    });

    const selectedParentSubjectIds = Object.keys(selectedSubjectsByParent);
    if (
      selectedParentSubjectIds.length === 1 &&
      selectedSubjects.filter((s) => {
        s.parent_id !== "0" && !selectedParentSubjectIds.includes(s.parent_id);
      }).length === 0
    ) {
      return parentSubjects.find(
        (parentSubject) => parentSubject.id === selectedParentSubjectIds[0]
      );
    }
  }, [parentSubjects, subjects, query.filters.subjectIds]);

  return singleSelectedParentSubject;
};
