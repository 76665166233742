import { pxToRem } from "adminComponents/utils";

export const AdminCalendarDate = {
  baseStyle: ({
    isToday,
    isComplete,
  }: {
    isToday: boolean;
    isComplete: boolean;
  }): Record<string, unknown> => ({
    icon: {
      color:
        isToday || isComplete ? "primary.warm-black" : "primary.warm-black",
      backgroundColor: isComplete && "utility.question-green",
      width: isComplete ? pxToRem(20) : pxToRem(24),
      height: isComplete ? pxToRem(20) : pxToRem(24),
    },

    date: {
      color: isToday ? "primary.warm-black" : "primary.warm-black",
    },
  }),
};
