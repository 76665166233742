import { datadogLogs } from "@datadog/browser-logs";
import React, { createContext, useContext, useMemo } from "react";
import { useMount } from "react-use";

import { config } from "links/lib/constants";
import { BrowserLogStatus } from "links/lib/types";

export interface ILoggingContext {
  datadogLogs?: typeof datadogLogs;
  log: (
    name: string,
    properties: Record<string, unknown>,
    status: BrowserLogStatus
  ) => void;
}

export const LoggingContext = createContext<ILoggingContext>({
  log: () => {
    throw new Error("Inop");
  },
});

// public datadog client token for browser logging
const CLIENT_TOKEN = "pub175b8f7ef6ea64641fb158b63f5b45a1";

export const LoggingProvider: React.FC = ({ children }) => {
  useMount(() => {
    datadogLogs.init({
      clientToken: CLIENT_TOKEN,
      site: "datadoghq.com",
      forwardErrorsToLogs: true,
      sampleRate: 100,
      service: "playtime_ui_web",
      env: config.nodeEnv,
      beforeSend: () => {
        // Don't log development errors
        if (config.nodeEnv === "development") return false;
      },
    });
  });

  const contextValue = useMemo(() => {
    return {
      datadogLogs,
      log: (
        message: string,
        properties: Record<string, unknown>,
        status: BrowserLogStatus
      ) => {
        datadogLogs?.logger.log(message, properties, status);
      },
    };
  }, []);

  return (
    <LoggingContext.Provider value={contextValue}>
      {children}
    </LoggingContext.Provider>
  );
};

export const useLogging = (): ILoggingContext => {
  return useContext(LoggingContext);
};
