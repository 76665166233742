import {
  CoopDragsortVariantData,
  ISessionDraggableSortItem,
  ISessionDraggableSortItemZone,
} from "links/lib/types";
import { Label } from "sessionComponents/molecules/ZoomableImage";

export const getDragsortImageLabels = ({
  dragsortVariantData,
  groupDragsortItems,
}: {
  dragsortVariantData: CoopDragsortVariantData;
  groupDragsortItems: ISessionDraggableSortItem[]; // groupItems are the "team's" concept of the items -- each has the zone the item was dragged to.
}): Label[] => {
  const zones = dragsortVariantData.coop_dragsort?.zones || [];
  // dragsortItems are the "round's" concept of the items -- they each have the correct zone_id attached.
  const dragsortItems = dragsortVariantData.coop_dragsort?.items || [];

  return dragsortItems.map((item, i) => {
    const correctZone = zones.find(
      (z) => item.zone_id === z.id
    ) as ISessionDraggableSortItemZone;
    const groupItem = groupDragsortItems.find(
      (groupItem) => groupItem.id === item.id
    );
    return {
      ...correctZone,
      isCorrect: groupItem?.is_correct || false,
      number: i + 1,
    };
  });
};
