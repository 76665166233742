import { AxiosInstance } from "axios";
import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { IAveragePracticeSetItemAccuracy } from "../../types";

export interface IUseFetchClassroomAveragePracticeSetItemAccuraciesProps {
  classroom_id: string;
  user_id?: string;
  practice_set_id: string;
  assignment_id?: string;
  practice_set_session_id?: string;
  enabled?: boolean;
}

export interface IUseFetchClassroomAveragePracticeSetItemAccuraciesResult {
  average_practice_set_item_accuracies: Array<IAveragePracticeSetItemAccuracy>;
}

export const fetchClassroomPracticeSetItemAccuraciesQuery = (
  axios: AxiosInstance,
  params: IUseFetchClassroomAveragePracticeSetItemAccuraciesProps
): (() => Promise<IUseFetchClassroomAveragePracticeSetItemAccuraciesResult>) => {
  const {
    classroom_id,
    user_id,
    assignment_id,
    practice_set_id,
    practice_set_session_id,
  } = params;

  return async (): Promise<IUseFetchClassroomAveragePracticeSetItemAccuraciesResult> => {
    return await axios
      .get(`/v1/accuracies/items`, {
        params: {
          group_id: classroom_id,
          user_id,
          practice_set_id,
          assignment_id,
          practice_set_session_id,
        },
      })
      .then(
        (r) =>
          r.data as IUseFetchClassroomAveragePracticeSetItemAccuraciesResult
      );
  };
};

export default function useFetchClassroomPracticeSetItemAccuracies(
  params: IUseFetchClassroomAveragePracticeSetItemAccuraciesProps
): UseQueryResult<
  IUseFetchClassroomAveragePracticeSetItemAccuraciesResult,
  unknown
> {
  const axios = useAxios();
  const { enabled = true } = params;

  const query =
    useQuery<IUseFetchClassroomAveragePracticeSetItemAccuraciesResult>(
      ["practice-set-item-accuracies", params],
      fetchClassroomPracticeSetItemAccuraciesQuery(axios, params),
      { enabled }
    );

  return query;
}
