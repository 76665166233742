import { Box, BoxProps, Flex, HStack } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { IconTooltip } from "adminComponents/atoms/IconTooltip";
import { Text } from "adminComponents/atoms/Text";
import { LevelProgressBar } from "adminComponents/molecules/LevelProgressBar";
import { pxToRem } from "adminComponents/utils/pxToRem";

export interface IProps extends BoxProps {
  season?: string;
  rewardLevel?: number;
  daysLeft?: number;
  xpPointsCurrent?: number;
  xpPointsThreshold?: number;
}

export const SeasonProgress: React.FC<IProps> = ({
  season = 0,
  rewardLevel = 0,
  daysLeft,
  xpPointsCurrent = 0,
  xpPointsThreshold = 0,
  ...props
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const valueProgressBar = (100 * xpPointsCurrent) / xpPointsThreshold;
  const isSeasonComplete = xpPointsThreshold - xpPointsCurrent < 0;
  return (
    <Box {...props}>
      <Flex alignItems="center" justifyContent="space-between">
        <HStack
          spacing={pxToRem(10)}
          sx={{
            ">p": {
              _first: { fontSize: pxToRem(20), fontWeight: pxToRem(500) },
            },
          }}
        >
          <Text variant="adminP2">{season}</Text>
          <IconTooltip>{t("studentProfileMenu.seasonTooltip")}</IconTooltip>
        </HStack>
        <Text
          color="utility.error"
          variant="adminP1"
          sx={{ fontSize: pxToRem(14), fontWeight: 600, mt: pxToRem(-6) }}
        >
          {typeof daysLeft !== "undefined" &&
            t("common.daysLeft", { count: daysLeft })}
        </Text>
      </Flex>
      <Box
        mt={{ base: pxToRem(6), md: pxToRem(4) }}
        mb={pxToRem(8)}
        sx={{ div: { height: { base: pxToRem(40), md: pxToRem(32) } } }}
      >
        <LevelProgressBar value={valueProgressBar} level={rewardLevel} />
      </Box>
      <Text
        variant="adminMeta"
        sx={{
          fontSize: { base: pxToRem(16), md: pxToRem(14) },
          mt: { base: pxToRem(4), md: pxToRem(2) },
          color: "primary.dark-gray",
        }}
      >
        {isSeasonComplete
          ? t("studentProfileMenu.seasonComplete")
          : t("studentProfileMenu.xpRatio", {
              xpPointsCurrent,
              xpPointsThreshold,
            })}
      </Text>
    </Box>
  );
};
