import {
  Box,
  Modal as ChakraModal,
  Flex,
  Image,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalProps,
  useBreakpointValue,
} from "@chakra-ui/react";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Heading } from "adminComponents/atoms/Heading";
import {
  IconCTAButtonWrapper as CloseButtonWrapper,
  IconCTAButton,
} from "adminComponents/atoms/IconCTAButton";
import { IconTooltip } from "adminComponents/atoms/IconTooltip";
import { Text } from "adminComponents/atoms/Text";
import { RarityScale } from "adminComponents/molecules/RarityScale";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { IAvatar, IAvatarItem } from "links/lib/types";
import { getAvatarItemPreviewDataURL } from "links/lib/util";

interface IProps {
  avatar: IAvatar;
  avatarItem: IAvatarItem;
  dateUnlocked?: string;
  handleAddToAvatar?: (avatarItem: IAvatarItem) => void;
  handleClose: () => void;
  handleRemoveFromAvatar?: (avatarItem: IAvatarItem) => void;
  isEquipped: boolean;
  isLoading?: boolean;
  isOpen: ModalProps["isOpen"];
  reasonUnlocked?: string;
}

const dateFormat = (date: string) => moment(date).format("MMMM D, YYYY");

const useAvatarImagePreviewData = (
  avatar: IAvatar,
  avatarItem: IAvatarItem
) => {
  const [imagePreviewData, setImagePreviewData] = useState<string>();

  useEffect(() => {
    getAvatarItemPreviewDataURL(avatarItem, avatar).then((result) => {
      setImagePreviewData(result);
    });
  }, [avatar, avatarItem]);

  return imagePreviewData;
};

export const StudentLockerModal: React.FC<IProps> = ({
  avatar,
  avatarItem,
  dateUnlocked,
  handleAddToAvatar,
  handleClose,
  handleRemoveFromAvatar,
  isEquipped,
  isLoading,
  isOpen,
  reasonUnlocked,
}) => {
  const imagePreviewData = useAvatarImagePreviewData(avatar, avatarItem);
  const { t } = useTranslation("admin", { useSuspense: false });

  const breakPointSize = useBreakpointValue({
    base: "sm",
    md: "2xl",
  });

  const handleCTAButtonClick = isEquipped
    ? () => handleRemoveFromAvatar?.(avatarItem)
    : () => handleAddToAvatar?.(avatarItem);

  return (
    <ChakraModal
      size={breakPointSize}
      variant="adminStudentLockerModal"
      onClose={handleClose}
      isOpen={isOpen}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <CloseButtonWrapper top={pxToRem(24)} right={pxToRem(24)} left="unset">
          <IconCTAButton ariaLabel={t("common.close")} onClick={handleClose} />
        </CloseButtonWrapper>
        <ModalBody>
          <Flex direction={["column", null, "row"]}>
            <Flex
              direction="column"
              align="center"
              justify="center"
              py={pxToRem(50)}
              bgColor="primary.tan"
              w={["auto", null, "45%"]}
              minH={pxToRem(278)}
            >
              {imagePreviewData && (
                <Image
                  src={imagePreviewData}
                  alt={avatarItem.name}
                  maxH={pxToRem(178)}
                />
              )}
            </Flex>
            <Flex
              align="center"
              direction="column"
              flex="1"
              px={[pxToRem(30), null, pxToRem(58)]}
              py={[pxToRem(32), null, pxToRem(64)]}
              w={["auto", null, "55%"]}
            >
              <Heading as="h2" variant="adminH4">
                {avatarItem.name}
              </Heading>
              <Text variant="adminP2" mt={pxToRem(5)}>
                {avatarItem.description}
              </Text>
              <Box my={pxToRem(20)} w="full">
                <RarityScale currentLevel={avatarItem.rarity} />
              </Box>
              <Flex direction="row" align="center">
                <Text variant="adminP1Bold">
                  {t("studentLockerModal.rarity")}:&nbsp;
                </Text>
                <Text variant="adminP1">
                  {t(`studentLockerModal.rarityLevel${avatarItem.rarity}`)}
                </Text>
                <Flex ml={pxToRem(8)}>
                  <IconTooltip>
                    {t("studentLockerModal.rarityTooltip")}
                  </IconTooltip>
                </Flex>
              </Flex>
              <Flex align="center" direction="column" mt={pxToRem(8)}>
                {reasonUnlocked && (
                  <Text variant="adminP2">{reasonUnlocked}</Text>
                )}
                {dateUnlocked && (
                  <Text variant="adminP2">{dateFormat(dateUnlocked)}</Text>
                )}
              </Flex>
              <Button
                isLoading={isLoading}
                onClick={handleCTAButtonClick}
                size="md"
                variant="adminButtonFilled"
                w="full"
                mt={pxToRem(28)}
              >
                {isEquipped
                  ? t("studentLockerModal.confirmRemoveButton")
                  : t("studentLockerModal.confirmAddButton")}
              </Button>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </ChakraModal>
  );
};
