import { Box, Text, VStack } from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { animated } from "react-spring";
import { useMeasure } from "react-use";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { quickPlayCarePackageRoundIntroDurationMillis } from "links/lib/constants";
import { useSessionActions } from "links/lib/contexts/sessionActions";
import { useSessionConnectedUsers } from "links/lib/contexts/sessionConnectedUsers";
import { useSessionGameState } from "links/lib/contexts/sessionGameState";
import { useSessionGroups } from "links/lib/contexts/sessionGroups";
import { useSessionRoundGroupState } from "links/lib/contexts/sessionRoundGroupState";
import { useSessionRoundState } from "links/lib/contexts/sessionRoundState";
import { useSessionScene } from "links/lib/contexts/sessionScene";
import usePendingSubmit from "links/lib/hooks/usePendingSubmit";
import {
  AnalyticsEvent,
  CoopDrawVariantData,
  PracticeSessionItemVariantType,
  SessionGameType,
  SessionScene,
  UserRole,
} from "links/lib/types";
import { Button } from "sessionComponents/atoms/Button";
import { ProgressBar } from "sessionComponents/atoms/ProgressBar";
import { QuestionLayout } from "sessionComponents/atoms/QuestionLayout";
import { RoundNumberSticker } from "sessionComponents/atoms/RoundNumberSticker";
import { Timer } from "sessionComponents/atoms/Timer";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { useLazyRoundTime } from "sessionComponents/contexts/roundTime";
import { useConvertedDrawOptions } from "sessionComponents/hooks/useConvertedDrawOptions";
import { useDelayedRoundIntro } from "sessionComponents/hooks/useDelayedRoundIntro";
import { useItemResponseOptions } from "sessionComponents/hooks/useItemResponseOptions";
import { useRoundContentTransition } from "sessionComponents/hooks/useRoundContentTransition";
import { useRoundMusic } from "sessionComponents/hooks/useRoundMusic";
import { GroupStatusesList } from "sessionComponents/molecules/GroupStatusesList";
import { QuestionResponse } from "sessionComponents/molecules/QuestionResponse";
import { DrawReviewOptions } from "sessionComponents/organisms/DrawReviewOptions";
import { QuestionBoxRenderer } from "sessionComponents/organisms/QuestionBoxRenderer";
import { SplashScene, SplashType } from "sessionComponents/scenes/SplashScene";
import { fullScreenStyles } from "sessionComponents/theme";
import { useDetectOrientation } from "sessionComponents/theme/hooks/useDetectOrientation";
import { getGameChoiceType } from "sessionComponents/utils/getGameChoiceType";
import { getItemInstructions } from "sessionComponents/utils/getItemInstructions";
import { getImageResponseStyleProps } from "sessionComponents/utils/layoutUtils";
import { useSessionAnalytics } from "sharedComponents/contexts/sessionAnalytics";
import { useSafeConnectedUserArray } from "sharedComponents/hooks/useSafeConnectedUserArray";

const AnimatedBox = animated(Box);

export const TeacherQuestionRound: React.FC = () => {
  const [selectedDrawOption, setSelectedDrawOption] = useState("");
  const { match } = useBreakpoints();
  const groups = useSessionGroups();
  const connectedUserMap = useSessionConnectedUsers();
  const connectedUsers = useSafeConnectedUserArray();
  const gameState = useSessionGameState();
  const roundState = useSessionRoundState();
  const roundGroupState = useSessionRoundGroupState();
  const scene = useSessionScene();
  const isStudentDrawVotingRound = scene === SessionScene.Voting;
  const isTeacherDrawVotingRound = scene === SessionScene.VotingResults;
  const {
    initiateRoundReview,
    initiateVotingResults,
    setVote,
    extendRoundTime,
  } = useSessionActions();
  const { track } = useSessionAnalytics();
  const [optionsContainerRef, { width: optionsContainerRefWidth }] =
    useMeasure<HTMLDivElement>();

  const isQuickPlay = gameState?.game_type === SessionGameType.QuickPlay;
  const isQuickPlayCarePackageRound =
    isQuickPlay &&
    gameState.quick_play_state.care_package_rounds.includes(
      roundState.round_number
    );

  const {
    confirmed_user_ids,
    variant,
    practice_set_session_item,
    starts_at,
    ends_at,
    variant_data,
    round_number,
  } = roundState;

  const { t } = useTranslation("session", { useSuspense: false });

  useRoundMusic({ roundNumber: round_number });

  const sceneChangeSubmit = usePendingSubmit([scene]);
  const { getSecondsLeft } = useLazyRoundTime();

  const showRoundIntro = useDelayedRoundIntro({
    disableRoundIntro: !isQuickPlayCarePackageRound,
    isQuickPlayCarePackageRound,
  });

  const roundContentTransition = useRoundContentTransition({
    showRoundIntro,
  });

  const instructionKey = getItemInstructions(practice_set_session_item);

  const instructions = instructionKey
    ? t(`practiceSets.instructions.${instructionKey}`)
    : "";

  const answerOptions = useItemResponseOptions(
    practice_set_session_item,
    roundGroupState,
    variant_data
  );

  const isImageResponse = useMemo(
    () =>
      answerOptions ? answerOptions.some((opt) => !!opt.image_url) : false,
    [answerOptions]
  );

  const onEndRoundEarly = () => {
    initiateRoundReview();
    track(AnalyticsEvent.Session_Round_Common_EndRoundEarly, {
      second_left: getSecondsLeft(),
    });
  };

  const onEndVotingEarly = () => {
    initiateVotingResults();
    track(AnalyticsEvent.Session_Round_Draw_EndStudentVote, {});
  };

  const handleEndEarlyClick = () => {
    if (isStudentDrawVotingRound) {
      onEndVotingEarly();
    } else {
      onEndRoundEarly();
    }

    sceneChangeSubmit.handleSubmit();
  };

  const handleCastTeacherVote = () => {
    track(AnalyticsEvent.Session_Round_Draw_ConfirmVote, {});

    setVote(selectedDrawOption);
    sceneChangeSubmit.handleSubmit();
  };

  const handleConfirmButtonClick = () => {
    if (isTeacherDrawVotingRound) {
      handleCastTeacherVote();
    } else {
      handleEndEarlyClick();
    }
  };

  const drawingVariantData = variant_data as CoopDrawVariantData;
  const drawingVotes = useMemo(
    () => drawingVariantData?.coop_draw?.votes || [],
    [drawingVariantData?.coop_draw?.votes]
  );
  const totalStudentCount = connectedUsers.filter(
    (u) => u.role === UserRole.Student
  ).length;

  const votingCompletionPercentage =
    (drawingVotes.length / totalStudentCount) * 100;

  // if there is only one group in the game,
  // or no options to vote for (ie all options have been hidden by teacher)
  // or all votes have been cast by students
  // move directly to Teacher Voting Round
  useEffect(() => {
    if (!isStudentDrawVotingRound || !answerOptions) return;

    const onlyOneGroupInGame = Object.keys(groups).length === 1;

    let maxNumberOfVotes = totalStudentCount;
    const validDrawOptions = answerOptions.filter((opt) => !!opt.image_url);

    // Special case for case when only one valid drawing is available:
    // The max number of votes possible in this case is equal to the total students
    // minus the number in the group that DID submit a drawing (since they have no
    // drawing to vote for)
    if (validDrawOptions.length === 1) {
      const numUsersInValidDrawingGroup = connectedUsers.filter(
        (u) => u.session_group_id === validDrawOptions[0].id
      ).length;
      maxNumberOfVotes -= numUsersInValidDrawingGroup;
    }

    const noValidDrawingsForStudentVote = !validDrawOptions.length;

    const allVotesCast = drawingVotes.length === maxNumberOfVotes;

    if (
      (onlyOneGroupInGame || noValidDrawingsForStudentVote || allVotesCast) &&
      !sceneChangeSubmit.isPending
    ) {
      initiateVotingResults();
      sceneChangeSubmit.handleSubmit();
    }
  }, [
    isStudentDrawVotingRound,
    connectedUsers,
    groups,
    initiateVotingResults,
    sceneChangeSubmit,
    answerOptions,
    drawingVotes.length,
    totalStudentCount,
  ]);

  // if there are no valid options to vote for (such as teacher has hidden all)
  // skip teacher voting
  useEffect(() => {
    if (
      isTeacherDrawVotingRound &&
      answerOptions &&
      !answerOptions.length &&
      !sceneChangeSubmit.isPending
    ) {
      setVote();
      sceneChangeSubmit.handleSubmit();
    }
  }, [isTeacherDrawVotingRound, answerOptions, setVote, sceneChangeSubmit]);

  // if there is only one group in the game,
  // or there is only one valid drawing
  // automatically cast Teacher vote for that group
  useEffect(() => {
    if (
      isTeacherDrawVotingRound &&
      answerOptions?.length === 1 &&
      !sceneChangeSubmit.isPending
    ) {
      answerOptions && setVote(answerOptions[0].id);
      sceneChangeSubmit.handleSubmit();
    }
  }, [isTeacherDrawVotingRound, answerOptions, setVote, sceneChangeSubmit]);

  const drawOptions = useConvertedDrawOptions(
    drawingVariantData,
    match.imageResponseSize
  );

  const { isPortrait } = useDetectOrientation();

  // "non-standard" components that take up the answer options portion of the layout
  // includes the progress bar for student draw voting, the teacher draw voting options,
  // and the full size timer and/or fallback message
  const alternateAnswerOptionsComponent = useMemo(() => {
    switch (scene) {
      case SessionScene.Voting:
        return (
          <ProgressBar
            percentValue={votingCompletionPercentage}
            label={t(
              "roundVariants.coopDrawVoting.studentVotingPercentageMsg",
              {
                votedCount: drawingVotes.length,
                total: totalStudentCount,
              }
            )}
          />
        );
      case SessionScene.VotingResults:
        return (
          <DrawReviewOptions
            isTeacher
            optionsSelectable
            selectedOptionId={selectedDrawOption}
            handleDrawOptionClick={setSelectedDrawOption}
            drawOptions={drawOptions}
            isTeacherVotingRound
          />
        );
      default:
        return (
          <VStack h="full" w="full" justifyContent="center">
            <TimerComponent
              startsAt={starts_at}
              endsAt={ends_at}
              boxSizePx={isPortrait ? 160 : 200}
              onExtendRoundTime={extendRoundTime}
            />
          </VStack>
        );
    }
  }, [
    drawingVotes.length,
    ends_at,
    starts_at,
    scene,
    t,
    totalStudentCount,
    votingCompletionPercentage,
    selectedDrawOption,
    extendRoundTime,
    drawOptions,
    isPortrait,
  ]);

  const getAnswerOptionsComponent = () => {
    switch (variant) {
      case PracticeSessionItemVariantType.CoopChecks:
      case PracticeSessionItemVariantType.CoopRadios:
      case PracticeSessionItemVariantType.CoopDragsort:
        return (
          <Box
            {...getImageResponseStyleProps(isImageResponse, match.padding)}
            ref={optionsContainerRef}
            paddingBottom={pxToRem(match.padding)}
          >
            {answerOptions?.map((option, index) => (
              <Box
                key={option.id}
                marginTop={
                  !isImageResponse ? pxToRem(match.margin / 2) : undefined
                }
              >
                <QuestionResponse
                  variant={variant}
                  choiceType={getGameChoiceType(variant)}
                  text={option.text}
                  imageUrl={option.image_url}
                  imageAltText={option.image_alt_text}
                  showAsSelectable={false}
                  number={index + 1}
                  questionType={practice_set_session_item.question_type}
                  maxWidth={
                    isImageResponse
                      ? pxToRem(optionsContainerRefWidth / 2)
                      : undefined
                  }
                  maxHeight={
                    isImageResponse
                      ? pxToRem(optionsContainerRefWidth / 2)
                      : undefined
                  }
                />
              </Box>
            ))}
          </Box>
        );
      case PracticeSessionItemVariantType.CoopTextMatch:
      case PracticeSessionItemVariantType.CoopTextMatchNumeric:
      case PracticeSessionItemVariantType.CoopDraw:
        return (
          <Box
            display="flex"
            justifyContent={isTeacherDrawVotingRound ? undefined : "center"}
            alignItems={isTeacherDrawVotingRound ? "flex-start" : "center"}
            h="full"
            w="full"
          >
            {alternateAnswerOptionsComponent}
          </Box>
        );

      default:
        return <></>;
    }
  };

  const getAnswerOptionsContainerHeight = () => {
    const showAlternateAnswerComponent = [
      PracticeSessionItemVariantType.CoopTextMatch,
      PracticeSessionItemVariantType.CoopTextMatchNumeric,
      PracticeSessionItemVariantType.CoopDraw,
    ].includes(variant);
    return showAlternateAnswerComponent && !isTeacherDrawVotingRound
      ? "100%"
      : undefined;
  };

  const getButtonText = () => {
    let prefix = "";
    switch (scene) {
      case SessionScene.Voting:
        prefix = "endVotingEarlyText";
        break;
      case SessionScene.VotingResults:
        prefix = "teacherSubmitVoteButton";
        break;
      default:
        prefix = "endEarlyText";
        break;
    }

    return t(`teacherConfirmButton.${prefix}`);
  };

  const isDragsortRound =
    variant === PracticeSessionItemVariantType.CoopDragsort;

  // don't show group status portion during draw voting rounds
  const hideGroupStatuses =
    isStudentDrawVotingRound || isTeacherDrawVotingRound;

  // Wait until the care package animation finishes before showing the round sticker
  const roundNumberStickerDelayMs =
    isQuickPlay &&
    gameState.quick_play_state.care_package_rounds.includes(
      roundState.round_number
    )
      ? quickPlayCarePackageRoundIntroDurationMillis
      : 0;

  return (
    <>
      {roundContentTransition(({ opacity }, item) =>
        item === "splash" ? (
          <AnimatedBox
            {...fullScreenStyles}
            style={{
              opacity,
            }}
          >
            <SplashScene
              splashType={SplashType.RoundStart}
              animationContainerStyle={{ opacity }}
            />
          </AnimatedBox>
        ) : (
          <AnimatedBox
            w="full"
            h="full"
            display="flex"
            flexDirection="column"
            style={{ opacity }}
          >
            <QuestionLayout
              outerGame={gameState?.game_type}
              isDragsortItem={isDragsortRound}
              isTeacher
              questionComponent={
                <QuestionBoxRenderer
                  variant={variant}
                  practice_set_session_item={practice_set_session_item}
                  variantData={variant_data}
                  instructions={instructions}
                  dragsortItems={[]}
                  groupUsers={[]}
                />
              }
              answerOptionsComponent={getAnswerOptionsComponent()}
              answerOptionsContainerHeight={getAnswerOptionsContainerHeight()}
              confirmAreaComponent={
                <Button
                  variant="buttonFilled"
                  height={pxToRem(match.buttonHeight)}
                  isLoading={sceneChangeSubmit.isPending}
                  onClick={handleConfirmButtonClick}
                  w="full"
                  disabled={isTeacherDrawVotingRound && !selectedDrawOption}
                  fontSize={pxToRem(match.fontSize)}
                >
                  {getButtonText()}
                </Button>
              }
              groupStatusComponent={
                hideGroupStatuses ? undefined : (
                  <GroupStatusesList
                    groups={groups}
                    confirmedUserIds={confirmed_user_ids}
                    users={connectedUserMap}
                  />
                )
              }
              teamsLength={Object.keys(groups).length}
            />
          </AnimatedBox>
        )
      )}
      {!isStudentDrawVotingRound && !isTeacherDrawVotingRound && (
        <RoundNumberSticker
          roundNumber={roundState.round_number}
          delayMs={roundNumberStickerDelayMs}
        />
      )}
    </>
  );
};

const TimerComponent = ({
  startsAt,
  endsAt,
  boxSizePx = 200,
  onExtendRoundTime,
}: {
  startsAt: string;
  endsAt: string;
  boxSizePx?: number;
  onExtendRoundTime: () => void;
}) => {
  const { t } = useTranslation("session", { useSuspense: false });
  const {
    match: { fontSize },
  } = useBreakpoints();
  const showTimer = startsAt !== endsAt;
  return (
    <>
      {showTimer ? (
        <Timer
          startsAt={startsAt}
          endsAt={endsAt}
          boxSize={pxToRem(boxSizePx)}
          fontSize={pxToRem(96)}
          showExtendPopover
          onExtendRoundTime={onExtendRoundTime}
        />
      ) : (
        <Text fontSize={pxToRem(fontSize)} textStyle="gameText">
          {t("round.noTimeLimitFallbackMessage")}
        </Text>
      )}
    </>
  );
};
