import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";

import {
  AssignmentsTab as AdminAssignmentsTab,
  AssignmentCardProps,
} from "adminComponents/screens/ClassroomDetailScreen/components/AssignmentsTab";
import { useAnalytics, usePageTrack } from "lib/contexts/analytics";
import { useFetchClassroomAssignments } from "links/lib/features/classrooms";
import {
  AnalyticsEvent,
  AnalyticsPage,
  IAssignment,
  IClassroom,
} from "links/lib/types";
import { useCreateAssignment } from "screens/TeacherClassrooms/shared/hooks/useCreateAssignment";
import { useDeleteAssignment } from "screens/TeacherClassrooms/shared/hooks/useDeleteAssignment";

export interface IAssignmentsTabProp {
  classroom: IClassroom;
}

const makeAssignmentData = (
  totalStudentsInClassroom: number,
  assignments?: Array<IAssignment>
): Array<AssignmentCardProps> => {
  return (
    assignments?.map<AssignmentCardProps>((assignment) => {
      const groupAccuracySum = assignment.best_user_attempts.reduce(
        (prev, curr) => prev + curr.accuracy,
        0
      );
      const averageGroupAccuracy = assignment.best_user_attempts.length
        ? Math.round(groupAccuracySum / assignment.best_user_attempts.length)
        : undefined;
      return {
        assignment,
        // the user_ids field will only be filled out if this assignment was limited to specific
        // students in the classroom, otherwise it was assigned to all classroom students
        totalStudentCount:
          assignment.user_ids.length || totalStudentsInClassroom,
        completedStudentCount: assignment.best_user_attempts.filter(
          (attempt) => {
            const aboveAccuracyThreshold =
              attempt.accuracy >= assignment.required_score_percent;
            // user_ids contains the specifically assigned user IDs or is empty if all
            // students were assigned
            if (assignment.user_ids.length) {
              return (
                assignment.user_ids.includes(attempt.user_id) &&
                aboveAccuracyThreshold
              );
            }
            return aboveAccuracyThreshold;
          }
        ).length,
        accuracy: averageGroupAccuracy,
      };
    }) || []
  );
};

export const AssignmentsTab: React.FC<IAssignmentsTabProp> = ({
  classroom,
}) => {
  const [pastLimit, setPastLimit] = useState(5);
  const [initialLoaded, setInitialLoaded] = useState(false);
  const { trackEvent } = useAnalytics();
  const [now, setNow] = useState(moment());

  usePageTrack(AnalyticsPage.TeacherDashboard_ClassroomDetail_Assignments);

  // Prevent refresh active every minute
  const dates = useMemo(() => {
    const dates: { [key: string]: Date } = {};

    dates.now = now.toDate();

    dates.upcomingStart = now.clone().add(1, "minute").toDate();
    dates.upcomingEnd = now.clone().add(1, "year").toDate();

    dates.pastStart = now.clone().subtract(1, "year").toDate();
    dates.pastEnd = now.clone().subtract(1, "minute").toDate();

    return dates;
  }, [now]);

  const activeAssignmentsFetch = useFetchClassroomAssignments({
    classroom_id: classroom.id,
    start_date: dates.now,
    end_date: dates.now,
    sort_desc: false,
    limit: 100, // attempt to show all active assignments
    keepPreviousData: true,
  });
  const upcomingAssignmentsFetch = useFetchClassroomAssignments({
    classroom_id: classroom.id,
    start_date: dates.upcomingStart,
    end_date: dates.upcomingEnd,
    sort_desc: false,
    limit: 100, // attempt to show all upcoming assignments
    keepPreviousData: true,
  });
  const pastAssignmentsFetch = useFetchClassroomAssignments({
    classroom_id: classroom.id,
    start_date: dates.pastStart,
    end_date: dates.pastEnd,
    sort_desc: true,
    limit: 100, // attempt to load all past assignments
    keepPreviousData: true,
  });

  const onMutateSuccess = () => {
    // On create of assignment change the date inputs for active, upcoming, past queries
    setNow(moment());
  };

  const onMutateSubmit = (assignment?: IAssignment) => {
    if (assignment) {
      trackEvent(
        AnalyticsEvent.TeacherDashboard_ClassroomDetail_AssignmentsTab_EditAssignmentConfirm,
        { classroomId: classroom.id, assignmentId: assignment.id }
      );
    } else {
      trackEvent(
        AnalyticsEvent.TeacherDashboard_ClassroomDetail_AssignmentsTab_CreateAssignmentConfirm,
        { classroomId: classroom.id }
      );
    }
  };

  const onDeleteConfirm = (assignment: IAssignment) => {
    trackEvent(
      AnalyticsEvent.TeacherDashboard_ClassroomDetail_AssignmentsTab_DeleteAssignmentConfirm,
      { classroomId: classroom.id, assignmentId: assignment.id }
    );
  };

  // no need to load all classrooms as assignments will
  // be limited to just the current classroom
  const classrooms = useMemo(() => {
    return [classroom];
  }, [classroom]);

  const {
    component: createAssignmentComponents,
    handleCreateIntent,
    handleEditIntent,
  } = useCreateAssignment({
    classrooms,
    onSubmit: onMutateSubmit,
    onSuccess: onMutateSuccess,
  });

  const { modal: deleteAssignmentModal, handleDeleteIntent } =
    useDeleteAssignment({
      classroom_id: classroom.id,
      onConfirm: onDeleteConfirm,
    });

  const handleCreateAssignment = () => {
    handleCreateIntent(classroom);

    trackEvent(
      AnalyticsEvent.TeacherDashboard_ClassroomDetail_AssignmentsTab_CreateAssignmentIntent,
      { classroomId: classroom.id }
    );
  };

  const handleDeleteAssignment = (assignment: IAssignment) => {
    handleDeleteIntent(assignment);

    trackEvent(
      AnalyticsEvent.TeacherDashboard_ClassroomDetail_AssignmentsTab_DeleteAssignmentIntent,
      { classroomId: classroom.id, assignmentId: assignment.id }
    );
  };

  const handleEditAssignment = (assignment: IAssignment) => {
    handleEditIntent(assignment, classroom);

    trackEvent(
      AnalyticsEvent.TeacherDashboard_ClassroomDetail_AssignmentsTab_EditAssignmentIntent,
      { classroomId: classroom.id, assignmentId: assignment.id }
    );
  };

  const handleViewMoreAssignments = () => {
    setPastLimit((val) => {
      return val + 5;
    });

    trackEvent(
      AnalyticsEvent.TeacherDashboard_ClassroomDetail_AssignmentsTab_ViewMoreAssignments,
      { classroomId: classroom.id }
    );
  };

  const activeAssignmentData: Array<AssignmentCardProps> = useMemo(() => {
    return makeAssignmentData(
      classroom.students_count,
      activeAssignmentsFetch.data?.assignments
    );
  }, [activeAssignmentsFetch.data, classroom.students_count]);

  const upcomingAssignmentData: Array<AssignmentCardProps> = useMemo(() => {
    return makeAssignmentData(
      classroom.students_count,
      upcomingAssignmentsFetch.data?.assignments
    ).filter((data) => {
      // remove any assignments that are currently active
      return moment(data.assignment.starts_at).isAfter(now);
    });
  }, [upcomingAssignmentsFetch.data, now, classroom.students_count]);

  const pastAssignments: Array<IAssignment> = useMemo(() => {
    return (
      pastAssignmentsFetch.data?.assignments.filter((assignment) => {
        // remove any assignments that are currently active
        return moment(assignment.ends_at).isBefore(now);
      }) || []
    );
  }, [pastAssignmentsFetch.data, now]);

  const pastAssignmentData: Array<AssignmentCardProps> = useMemo(() => {
    return makeAssignmentData(classroom.students_count, pastAssignments).slice(
      0,
      pastLimit
    );
  }, [pastAssignments, pastLimit, classroom.students_count]);

  // Because queries will fetch new results every minute,
  // we don't want to go back to a full page load each time
  // so only show spinner on initial loading state
  useEffect(() => {
    if (
      !pastAssignmentsFetch.isLoading &&
      !upcomingAssignmentsFetch.isLoading &&
      !initialLoaded
    ) {
      setInitialLoaded(true);
    }
  }, [
    pastAssignmentsFetch.isLoading,
    upcomingAssignmentsFetch.isLoading,
    initialLoaded,
  ]);

  return (
    <>
      <AdminAssignmentsTab
        classroom={classroom}
        handleCreateAssignment={handleCreateAssignment}
        handleDeleteAssignmentCard={handleDeleteAssignment}
        handleEditAssignmentCard={handleEditAssignment}
        handleViewMoreAssignments={handleViewMoreAssignments}
        showViewMoreAssignments={pastAssignments.length > pastLimit}
        activeAssignmentData={activeAssignmentData}
        pastAssignmentData={pastAssignmentData}
        upcomingAssignmentData={upcomingAssignmentData}
        isLoading={!initialLoaded}
      />
      {createAssignmentComponents}
      {deleteAssignmentModal}
    </>
  );
};
