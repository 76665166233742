import { useEffect, useRef, useState } from "react";

const constrainPos = (
  pos: {
    x: number;
    y: number;
  },
  stageSize: { x: number; y: number },
  zoom: number
): { x: number; y: number } => {
  // the size of the current viewing area
  const cameraFrame = {
    x: stageSize.x * zoom,
    y: stageSize.y * zoom,
  };

  // clamp x position within stage limits
  const x = Math.min(
    Math.max(pos.x, -(stageSize.x / 2) + cameraFrame.x / 2),
    stageSize.x / 2 - cameraFrame.x / 2
  );

  // clamp y position within stage limits
  const y = Math.min(
    Math.max(pos.y, -(stageSize.y / 2) + cameraFrame.y / 2),
    stageSize.y / 2 - cameraFrame.y / 2
  );

  return { x, y };
};

export const useCameraPosition = (
  initPos = { x: 0, y: 0 },
  targetRef: React.MutableRefObject<HTMLDivElement | null>,
  stageSize: { x: number; y: number },
  zoom: number
): { x: number; y: number; isGrabbing: boolean } => {
  const [pos, setPos] = useState(initPos);
  const [isGrabbing, setIsGrabbing] = useState(false);

  const stageSizeRef = useRef(stageSize);
  useEffect(() => {
    stageSizeRef.current = stageSize;
  }, [stageSize]);

  // stores reference to latest zoom for use inside positioning hook
  const zoomRef = useRef(zoom);
  useEffect(() => {
    zoomRef.current = zoom;

    // Reposition if necessary to keep stage within camera frame
    setPos((p) => constrainPos(p, stageSizeRef.current, zoom));
  }, [zoom]);

  const posRef = useRef(pos);
  useEffect(() => {
    posRef.current = pos;
  }, [pos]);

  useEffect(() => {
    if (!targetRef.current) return;

    const target = targetRef.current;

    let currentPos = posRef.current;
    let currentTouch: number | undefined;
    let posAtGrab: { x: number; y: number } = posRef.current;
    let currentGrab: { x: number; y: number } | undefined;

    const handleMouseDown = (e: MouseEvent) => {
      e.preventDefault();
      handleDown(e.clientX, e.clientY);
    };

    const handleTouchDown = (e: TouchEvent) => {
      e.preventDefault();

      const touch = e.touches[0];

      if (!touch || e.touches.length > 1) return;

      currentTouch = touch.identifier;
      handleDown(touch.clientX, touch.clientY);
    };

    const handleDown = (x: number, y: number) => {
      currentGrab = { x, y };
      posAtGrab = currentPos;
      setIsGrabbing(true);
    };

    const handleUp = () => {
      currentGrab = undefined;
      setIsGrabbing(false);
    };

    const handleMouseMove = (e: MouseEvent) => {
      e.preventDefault();
      handleMove(e.clientX, e.clientY);
    };

    const handleTouchMove = (e: TouchEvent) => {
      e.preventDefault();
      for (let i = 0; i < e.changedTouches.length; i++) {
        if (e.changedTouches[i].identifier === currentTouch) {
          handleMove(e.changedTouches[i].clientX, e.changedTouches[i].clientY);
          break;
        }
      }
    };

    const handleMove = (x: number, y: number) => {
      if (!currentGrab) return;

      window.requestAnimationFrame(() => {
        if (!currentGrab) return;

        const desiredPos = {
          x: posAtGrab.x + (currentGrab.x - x),
          y: posAtGrab.y + (y - currentGrab.y),
        };

        const finalPos = constrainPos(
          desiredPos,
          stageSizeRef.current,
          zoomRef.current
        );

        setPos(finalPos);
        currentPos = finalPos;
      });
    };

    target.addEventListener("mousedown", handleMouseDown);
    target.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleUp);

    target.addEventListener("touchstart", handleTouchDown);
    target.addEventListener("touchmove", handleTouchMove);
    window.addEventListener("touchend", handleUp);

    return () => {
      target.removeEventListener("mouseup", handleMouseDown);
      target.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleUp);

      target.removeEventListener("touchstart", handleTouchDown);
      target.removeEventListener("touchmove", handleTouchMove);
      window.removeEventListener("touchend", handleUp);
    };
  }, [targetRef]);

  return { ...pos, isGrabbing };
};
