import { pxToRem } from "adminComponents/utils";

export const AdminSummarizedSubjectTags = {
  baseStyle: {
    tag: {
      marginRight: pxToRem(8),

      "&:last-child": {
        marginRight: 0,
      },
    },

    tooltip: {
      zIndex: 1,

      button: {
        _focusVisible: {
          outlineColor: "utility.focus",
          borderRadius: pxToRem(8),
        },
      },
    },
  },
};
