import { Box, Flex, VStack } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Card } from "adminComponents/atoms/Card";
import { CoverImageIcon } from "adminComponents/atoms/CoverImageIcon";
import { Heading } from "adminComponents/atoms/Heading";
import { IconTooltip } from "adminComponents/atoms/IconTooltip";
import { ProgressBar } from "adminComponents/atoms/ProgressBar";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils";

interface IProps {
  topThird?: boolean;
}

export const TopOrgUsageCard: React.FC<IProps> = ({ topThird }) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "districtAdminHomepage.usage",
  });
  return (
    <Card
      variant="adminCardSmallBorder"
      borderColor="primary.tan"
      bgColor="white"
      h="100%"
    >
      <VStack w="full" h="100%" alignItems="center" justifyContent="center">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          backgroundColor="utility.top-green"
          borderRadius="full"
          boxSize={pxToRem(70)}
        >
          <CoverImageIcon boxSize={pxToRem(50)} icon="OTHER_ROCKET" />
        </Box>
        <Heading variant="adminH4" as="h3">
          {t("positiveUsage")}
        </Heading>
        <Text variant="adminP2">{t("teachersPositive")}</Text>
        <Box w="100%">
          <ProgressBar
            value={topThird ? 66 : 50}
            max={100}
            h={pxToRem(25)}
            size="lg"
            variant="adminStudentGoal"
          />
        </Box>
        <Flex gap={pxToRem(4)} alignItems="center">
          <Text variant="adminP2Bold">{t("usage")}</Text>
          <Text variant="adminP2">
            {topThird ? t("veryHighUsage") : t("highUsage")}
          </Text>
          <IconTooltip>
            {topThird ? t("tooltipTopThird") : t("tooltip")}
          </IconTooltip>
        </Flex>
      </VStack>
    </Card>
  );
};
