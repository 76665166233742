import React, { createContext, useContext, useMemo } from "react";

import { ISessionRoundGroupState } from "../types";

export const SessionRoundGroupStateContext =
  createContext<ISessionRoundGroupState>({});

export interface ISessionRoundGroupStateProviderProps {
  wsRoundGroupState: ISessionRoundGroupState;
}

export const SessionRoundGroupStateProvider: React.FC<
  ISessionRoundGroupStateProviderProps
> = ({ children, wsRoundGroupState }) => {
  const value = useMemo(() => wsRoundGroupState, [wsRoundGroupState]);
  return (
    <SessionRoundGroupStateContext.Provider value={value}>
      {children}
    </SessionRoundGroupStateContext.Provider>
  );
};

export const useSessionRoundGroupState = (): ISessionRoundGroupState => {
  return useContext(SessionRoundGroupStateContext);
};
