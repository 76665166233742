import { Portal } from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";

import { SeasonMapSoundEffect } from "links/lib/types";
import { getRandomArrayElement } from "links/lib/util";
import { useAudio } from "sharedComponents/contexts/audio";

import { FullscreenAnimationTakeover } from "../FullscreenAnimationTakeover";
import { default as busTransitionBankData } from "./resource/bus-transition-bank.json";
import { default as busTransitionJuiceData } from "./resource/bus-transition-juice.json";
import { default as busTransitionPearAirData } from "./resource/bus-transition-pearair.json";

export interface ISeason1MapTransitionProps {
  show: boolean;
  handleComplete: () => void;
}

const busAnimations = [
  busTransitionBankData,
  busTransitionJuiceData,
  busTransitionPearAirData,
];

export const Season1MapTransition: React.FC<ISeason1MapTransitionProps> = ({
  handleComplete,
  show,
}) => {
  const [currentBusAnim, setCurrentBusAnim] = useState<Record<string, unknown>>(
    busAnimations[0]
  );
  const { play } = useAudio();

  useEffect(() => {
    if (show) {
      setCurrentBusAnim(getRandomArrayElement(busAnimations));
    }
  }, [show]);

  const handlePlay = useCallback(() => {
    play(SeasonMapSoundEffect.BusTransition);
  }, [play]);

  return (
    <Portal>
      <FullscreenAnimationTakeover
        bgColor="season-map.transition-bg"
        show={show}
        animationData={currentBusAnim}
        handleEnd={handleComplete}
        handlePlay={handlePlay}
      />
    </Portal>
  );
};
