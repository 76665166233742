import { AxiosError } from "axios";
import { UseMutationResult, useMutation } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { IPracticeSetItem, ISmartSetSettings, SmartSetType } from "../../types";

interface IGenerateGroupSmartSetPreviewArgs {
  smart_set_type: SmartSetType;
  smart_set_settings: ISmartSetSettings;
}

export interface IGenerateGroupSmartSetPreviewResponse {
  practice_set_items: Array<IPracticeSetItem>;
}

export interface IUseGenerateGroupSmartSetPreviewProps {
  onSuccess?: (data: IGenerateGroupSmartSetPreviewResponse) => void;
  onError?: (error: AxiosError) => void;
  onSettled?: () => void;
}

/**
 * Fetches a list of practice set items for given Smart Set settings
 * @returns
 */
export default function useGenerateGroupSmartSetPreview(
  props?: IUseGenerateGroupSmartSetPreviewProps
): UseMutationResult<
  IGenerateGroupSmartSetPreviewResponse,
  unknown,
  IGenerateGroupSmartSetPreviewArgs
> {
  const axios = useAxios();

  const generatePreview = async (params: IGenerateGroupSmartSetPreviewArgs) => {
    return await axios
      .post("/v1/groups/smart-sets/preview", params)
      .then((r) => r.data as IGenerateGroupSmartSetPreviewResponse);
  };

  const mutation = useMutation(generatePreview, {
    onError: props?.onError,
    onSuccess: props?.onSuccess,
    onSettled: props?.onSettled,
  });

  return mutation;
}
