import { Flex } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils/pxToRem";

export const AnswerDraw: React.FC = () => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });
  return (
    <Flex direction="column" gap={{ base: pxToRem(4), md: pxToRem(12) }}>
      <Text variant="adminP2" color="primary.dark-gray" fontStyle="italic">
        {t("practiceSetQuestionCard.labelDrawAnswer")}
      </Text>
    </Flex>
  );
};
