import {
  Box,
  Center,
  Flex,
  Image,
  Link,
  useBreakpointValue,
} from "@chakra-ui/react";
import React from "react";

import { Card } from "adminComponents/atoms/Card";
import { Heading } from "adminComponents/atoms/Heading";
import { pxToRem } from "adminComponents/utils";
import { useGetPremiumLink } from "sharedComponents/hooks/useGetPremiumLink";

import { Button } from "../Button";
import PremiumCrownSparklesSVG from "../PremiumTooltipRich/resource/PremiumCrownSparkles.svg";
import { Text } from "../Text";

export interface IProps {
  label: string;
  upgradeText: string;
  cta: string;
}

export const UpgradeCallToActionFancy: React.FC<IProps> = ({
  label,
  upgradeText,
  cta,
}) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const premiumLink = useGetPremiumLink();

  return (
    <Box w="100%">
      <Center>
        <Card
          w="fit-content"
          borderColor="primary.tan"
          variant="adminCardSmallBorder"
          bg="white"
          padding={pxToRem(20)}
        >
          <Flex
            alignItems="center"
            gap={pxToRem(10)}
            flexDir={isMobile ? "column" : "row"}
          >
            <Flex alignItems="center" gap={pxToRem(10)}>
              <Image
                boxSize={[pxToRem(30), pxToRem(80)]}
                src={PremiumCrownSparklesSVG}
              />
              <Flex flexDirection="column">
                <Heading as="h3" variant="adminH6">
                  {label}
                </Heading>
                <Text variant="adminP1">{upgradeText}</Text>
              </Flex>
            </Flex>
            <Link
              style={{ textDecoration: "underline" }}
              color="utility.link"
              href={premiumLink}
              target="_blank"
            >
              <Button variant="adminButtonOutlined">{cta}</Button>
            </Link>
          </Flex>
        </Card>
      </Center>
    </Box>
  );
};
