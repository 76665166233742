import { AxiosError } from "axios";
import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { IGroupSmartSetCapability } from "../../types";

export interface IFetchGroupsSmartSetsCapabilitiesResponse {
  groups: Array<{
    group_id: string;
    capabilities: Array<IGroupSmartSetCapability>;
  }>;
}

export interface IFetchGroupsSmartSetsCapabilitiesParams {
  enabled?: boolean;
}

/**
 * Fetches a list of the per-group capabilities for Smart Sets
 * @returns
 */
export default function useFetchGroupsSmartSetsCapabilities(
  params?: IFetchGroupsSmartSetsCapabilitiesParams
): UseQueryResult<IFetchGroupsSmartSetsCapabilitiesResponse, AxiosError> {
  const axios = useAxios();

  const enabled = params?.enabled ?? true;

  const fetchGroupsSmartSetsCapabilities = useQuery<
    IFetchGroupsSmartSetsCapabilitiesResponse,
    AxiosError
  >(
    ["groups-smart-sets-capabilities"],
    async (): Promise<IFetchGroupsSmartSetsCapabilitiesResponse> => {
      return await axios
        .get("/v1/groups/smart-sets/capabilities")
        .then((r) => r.data as IFetchGroupsSmartSetsCapabilitiesResponse);
    },
    { enabled }
  );

  return fetchGroupsSmartSetsCapabilities;
}
