import { Tooltip } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

const PremiumTooltip: React.FC = ({ children }) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  return (
    <Tooltip
      label={t("common.premiumFeature")}
      aria-label={t("common.premiumFeature")}
      as="span"
      openDelay={500}
      variant="adminDark"
    >
      {children}
    </Tooltip>
  );
};

export { PremiumTooltip };
