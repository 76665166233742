import { IPracticeSetSessionItem, QuestionType } from "links/lib/types";

export const getQuestionPrompt = (
  qType: QuestionType,
  practiceSetSessionItem: IPracticeSetSessionItem
): string => {
  let prompt: string | undefined = "";
  switch (qType) {
    case QuestionType.MultipleChoice:
      prompt = practiceSetSessionItem.multiple_choice?.prompt;
      break;
    case QuestionType.MultipleSelect:
      prompt = practiceSetSessionItem.multiple_select?.prompt;
      break;
    case QuestionType.TextResponse:
      prompt = practiceSetSessionItem.text_response?.prompt;
      break;
    case QuestionType.NumberResponse:
      prompt = practiceSetSessionItem.number_response?.prompt;
      break;
    case QuestionType.Diagram:
      prompt = practiceSetSessionItem.diagram?.prompt;
      break;
    case QuestionType.OpenEnded:
      prompt = practiceSetSessionItem.open_ended?.prompt;
      break;
    case QuestionType.Draw:
      prompt = practiceSetSessionItem.draw?.prompt;
      break;
    case QuestionType.Classify:
      prompt = practiceSetSessionItem.classify?.prompt;
      break;
    default:
      return `Question type not implemented: ${qType}`;
  }

  return prompt || "";
};
