import { ISessionUser, ISessionUsers } from "links/lib/types";

export const getUserWithWarning = (
  users: ISessionUsers,
  userId: string
): ISessionUser | undefined => {
  const user = users[userId];

  if (!user) {
    console.error(`User with ID ${userId} not found`);
  }

  return user;
};
