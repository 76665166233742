import { HStack, useMultiStyleConfig } from "@chakra-ui/react";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";

import { Icon } from "adminComponents/atoms/Icon";
import { Text } from "adminComponents/atoms/Text";

interface IProps {
  date: string;
  dateType: "due" | "start" | "today" | "complete";
  textVariant?: "adminP1" | "adminP2";
}

const formatDate = (date: string) => {
  return moment(date).format("MMMM D, YYYY; h:mm A");
};

const formatDateWithoutTime = (date: string) => {
  return moment(date).format("MMMM D, YYYY");
};

const formatTime = (time: string) => {
  return moment(time).format("h:mm A");
};

export const CalendarDate: React.FC<IProps> = ({
  date,
  dateType,
  textVariant = "adminP2",
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const isToday = dateType === "today";
  const isComplete = dateType === "complete";

  const styles = useMultiStyleConfig("AdminCalendarDate", {
    isToday,
    isComplete,
  });

  const icon = isComplete ? "check" : "event_outlined";
  const iconVariant = isComplete ? "circleWithBackgroundColor" : undefined;

  return (
    <HStack>
      <Icon decorative sx={styles.icon} icon={icon} variant={iconVariant} />
      <Text sx={styles.date} variant={textVariant}>
        {!isToday && !isComplete && (
          <>
            {t(`dates.${dateType}`)} <time>{formatDate(date)}</time>
          </>
        )}
        {isToday && (
          <>
            {t("dates.dueToday")} <time>{formatTime(date)}</time>
          </>
        )}
        {isComplete && (
          <>
            {t(`dates.${dateType}`)} <time>{formatDateWithoutTime(date)}</time>
          </>
        )}
      </Text>
    </HStack>
  );
};
