import { Box, usePrefersReducedMotion } from "@chakra-ui/react";
import React, { useEffect, useMemo } from "react";
import { animated, config, useTransition } from "react-spring";
import { usePrevious } from "react-use";

import { pxToRem } from "adminComponents/utils/pxToRem";
import {
  ISessionGroup,
  ISessionGroups,
  ISessionUser,
  ISessionUsers,
} from "links/lib/types";
import { GroupToken } from "sessionComponents/atoms/GroupToken";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { useDetectOrientation } from "sessionComponents/theme/hooks/useDetectOrientation";
import { getAssetColorScheme } from "sessionComponents/utils/getAssetColorScheme";
import { getFormattedGroupName } from "sessionComponents/utils/getFormattedGroupName";
import { useSafeConnectedUserArray } from "sharedComponents/hooks/useSafeConnectedUserArray";
import { useSafeGroupsArray } from "sharedComponents/hooks/useSafeGroupsArray";

interface IGroupStatusesList {
  groups: ISessionGroups;
  confirmedUserIds: string[];
  users: ISessionUsers;
  playAudio?: (spriteName: string) => number;
}

// returns a map of each groupId and whether all its members confirmed an answer
const getGroupConfirmStatus = (
  groups: ISessionGroup[],
  confirmedUserIds: string[],
  users: ISessionUser[]
) => {
  return groups.reduce((confirmMap, group) => {
    const allGroupStudentsConfirmed = users
      .filter((user) => user.session_group_id === group.id)
      .every((user) => confirmedUserIds.includes(user.id));

    confirmMap[group.id] = allGroupStudentsConfirmed;

    return confirmMap;
  }, {} as { [key: string]: boolean | undefined });
};

const AnimatedBox = animated(Box);

export const GroupStatusesList: React.FC<IGroupStatusesList> = ({
  confirmedUserIds,
  playAudio,
}) => {
  const {
    match: { borderWidth, padding, margin },
  } = useBreakpoints();
  const { isPortrait } = useDetectOrientation();
  const prefersReducedMotion = usePrefersReducedMotion();
  const existingUsers = useSafeConnectedUserArray();
  const existingGroups = useSafeGroupsArray();

  const groupConfirmStatus = useMemo(() => {
    return getGroupConfirmStatus(
      existingGroups,
      confirmedUserIds,
      existingUsers
    );
  }, [confirmedUserIds, existingGroups, existingUsers]);

  const previousGroupConfirmStatus = usePrevious(groupConfirmStatus);
  const confirmedStatusLength = useMemo(
    () => Object.values(groupConfirmStatus).filter((s) => s).length,
    [groupConfirmStatus]
  );
  const previousConfirmedStatusLength = useMemo(
    () =>
      Object.values(previousGroupConfirmStatus || {}).filter((s) => s).length,
    [previousGroupConfirmStatus]
  );

  //play a sound effect when a group confirms all answers
  useEffect(() => {
    if (
      confirmedStatusLength === 0 ||
      confirmedStatusLength === previousConfirmedStatusLength
    )
      return;

    // don't play for last confirm as audio conflicts with
    // round review audio
    if (confirmedStatusLength < existingGroups.length) {
      playAudio?.("Vibraphone Message 02");
    }
  }, [
    groupConfirmStatus,
    existingGroups.length,
    playAudio,
    previousGroupConfirmStatus,
    confirmedStatusLength,
    previousConfirmedStatusLength,
  ]);

  const groupProgressTransitions = useTransition(existingGroups, {
    from: { opacity: 0, scale: 0 },
    enter: { opacity: 1, scale: 1 },
    leave: { opacity: 0, scale: 0 },
    trail: 200,
    keys: ({ id }) => id,
    config: prefersReducedMotion ? config.molasses : config.gentle,
  });

  return (
    <Box
      display="flex"
      flexDirection={isPortrait ? "row" : "column"}
      background="primary.warm-white"
      paddingY={isPortrait ? pxToRem(padding) : undefined}
      alignItems="center"
      h="full"
      overflowY="auto"
      overflowX="hidden"
      minWidth="fit-content"
      maxWidth="100%"
      flexWrap={isPortrait ? "wrap" : undefined}
    >
      {groupProgressTransitions((groupProgressStyle, group) => {
        const groupIndex = existingGroups.findIndex((g) => g.id === group.id);
        const groupMembers = existingUsers.filter(
          (user) => user.session_group_id === group.id
        );
        return (
          <AnimatedBox
            style={groupProgressStyle}
            borderTopWidth={groupIndex === 0 ? 0 : pxToRem(borderWidth)}
            borderTopColor={
              groupIndex === 0 || isPortrait ? "transparent" : "primary.tan"
            }
            padding={isPortrait ? undefined : pxToRem(8)}
            marginLeft={isPortrait ? pxToRem(margin) : 0}
          >
            <GroupToken
              name={getFormattedGroupName(group)}
              colorScheme={getAssetColorScheme(group.color_scheme)}
              imageUrl={group.icon_url}
              pending={!groupConfirmStatus[group.id]}
              confirmed={groupConfirmStatus[group.id]}
              groupMembers={groupMembers}
            />
          </AnimatedBox>
        );
      })}
    </Box>
  );
};
