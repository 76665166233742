import { Portal } from "@chakra-ui/react";
import React, { useCallback } from "react";

import { SeasonMapSoundEffect } from "links/lib/types";
import { useAudio } from "sharedComponents/contexts/audio";

import { FullscreenRiveTakeover } from "../FullscreenRiveTakover";
import GolfCartRiveSrc from "./resource/s4_golf_cart_transition.riv";

const animations = ["cart-movement", "cart-objects-motion"];
const stateMachines = "golf cart transition";

export interface ISeason4MapTransition {
  show: boolean;
  handleComplete: () => void;
}

export const Season4MapTransition: React.FC<ISeason4MapTransition> = ({
  show,
  handleComplete,
}) => {
  const { play } = useAudio();

  const handlePlay = useCallback(() => {
    play(SeasonMapSoundEffect.GolfCartTransition);
  }, [play]);

  return (
    <Portal>
      <FullscreenRiveTakeover
        handleEnd={handleComplete}
        handlePlay={handlePlay}
        show={show}
        bgColor="#6646BB"
        src={GolfCartRiveSrc}
        lengthInMillis={3500}
        stateMachines={stateMachines}
        animations={animations}
      />
    </Portal>
  );
};
