import React, { useMemo } from "react";

import { TextButtonDropdown } from "adminComponents/molecules/TextButtonDropdown";
import i18n from "lib/i18n";
import { langLabelMap, supportedLangs } from "links/lib/lang";

export const useLanguageDropdown = (): JSX.Element => {
  const activeLanguage = i18n.resolvedLanguage;
  const availableLanguages = useMemo(() => {
    return supportedLangs.filter((language) => language !== activeLanguage);
  }, [activeLanguage]);
  return availableLanguages?.length ? (
    <TextButtonDropdown
      menuButtonProps={{ fontFamily: "TTCommons" }}
      buttonText={langLabelMap[activeLanguage]}
      value={activeLanguage}
      menuItems={availableLanguages.map((language) => ({
        label: langLabelMap[language],
        value: language,
        handleClick: () => i18n.changeLanguage(language),
      }))}
    />
  ) : (
    <></>
  );
};
