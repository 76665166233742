import {
  Box,
  Flex,
  Spinner,
  useBreakpointValue,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { CoverImageBackground } from "adminComponents/atoms/CoverImageBackground";
import { CoverImageIcon } from "adminComponents/atoms/CoverImageIcon";
import { Heading } from "adminComponents/atoms/Heading";
import { SubmarkLogo } from "adminComponents/atoms/SubmarkLogo";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { IRemix, OnDemandIntent } from "links/lib/types";

import COMPLETED from "./resources/completed.svg";
import IN_PROGRESS from "./resources/in-progress.svg";

interface IRemixProps {
  remix: IRemix;
  isSelected?: boolean;
  handleClick?: (remix: IRemix) => void;
  isDisabled?: boolean;
  isLoading?: boolean;
}

const RemixItem: React.FC<IRemixProps> = ({
  remix,
  isSelected = false,
  isDisabled,
  isLoading,
  handleClick,
}) => {
  const {
    subject_name: title,
    cover_image_icon: icon,
    on_demand_intent,
    maximum_xp: xp,
    is_session_active: isActive,
  } = remix;
  const isCompleted = !remix.new_session_permitted && !remix.is_session_active;

  const iconSize = useBreakpointValue([56, null, 72]) ?? 50;
  const tileSize = useBreakpointValue([210, null, 240]) ?? 210;
  const { t } = useTranslation("admin", { useSuspense: false });
  const styles = useMultiStyleConfig("AdminRemixItem", {
    isCompleted,
    isSelected,
    isLoading,
    isDisabled,
  });

  let coverImageBackground: JSX.Element;
  switch (on_demand_intent) {
    case OnDemandIntent.HOW_TO_PLAY:
      coverImageBackground = (
        <CoverImageBackground
          display="inline-flex"
          position="relative"
          pattern="OTHER_FUN_PARTY"
          colorScheme="LIGHT_BLUE"
          tileSize={tileSize}
          sx={styles.coverImageBackground}
          p={pxToRem(10)}
        >
          <SubmarkLogo />
        </CoverImageBackground>
      );
      break;
    case OnDemandIntent.DIGITAL_CITIZENSHIP:
      coverImageBackground = (
        <CoverImageBackground
          display="inline-flex"
          position="relative"
          pattern="OTHER_CULTURE_BG_PATTERN"
          colorScheme="ORANGE"
          tileSize={tileSize}
          sx={styles.coverImageBackground}
          p={pxToRem(10)}
        >
          <CoverImageIcon
            as="span"
            display="block"
            boxSize={pxToRem(iconSize)}
            icon="DIGITAL_CITIZENSHIP"
          />
        </CoverImageBackground>
      );
      break;
    default:
      coverImageBackground = (
        <CoverImageBackground
          display="inline-flex"
          position="relative"
          pattern="REMIX"
          colorScheme={remix.cover_image_bg_color_scheme}
          tileSize={tileSize}
          sx={styles.coverImageBackground}
        >
          <CoverImageIcon
            as="span"
            display="block"
            boxSize={pxToRem(iconSize)}
            icon={icon}
            disabled={isCompleted}
          />
        </CoverImageBackground>
      );
      break;
  }

  return (
    <Flex sx={styles.container}>
      <Box
        sx={styles.coverImageWrapper}
        {...(!isCompleted && {
          as: "button",
          type: "button",
          "aria-label": `${t("remixItem.viewAriaLabel", { title })}`,
          onClick: () => {
            if (isDisabled) return;
            handleClick?.(remix);
          },
        })}
      >
        {coverImageBackground}
        {isLoading && (
          <Box sx={styles.spinner}>
            <Spinner size="md" />
          </Box>
        )}
        <Box
          backgroundImage={isCompleted ? COMPLETED : IN_PROGRESS}
          sx={styles.progress}
        />
      </Box>
      {title && (
        <Heading
          as="p"
          isTruncated
          maxWidth="100%"
          mb={`${pxToRem(4)} `}
          variant="adminH7"
        >
          {title}
        </Heading>
      )}
      {(xp || isActive || isCompleted) && (
        <Text variant="adminP3">
          {!!xp && `${xp} ${t("remixItem.xp")}`}
          {isCompleted && t("common.complete")}
          {isActive && t("remixItem.inProgressLabel")}
        </Text>
      )}
    </Flex>
  );
};

export default RemixItem;
