import {
  Box,
  Flex,
  FormLabel,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  useBreakpointValue,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Divider } from "adminComponents/atoms/Divider";
import { Heading } from "adminComponents/atoms/Heading";
import { Icon, IconType } from "adminComponents/atoms/Icon";
import { LoadingSpinner } from "adminComponents/atoms/LoadingSpinner";
import { Text } from "adminComponents/atoms/Text";
import { Carousel } from "adminComponents/molecules/Carousel";
import { EmptyCard } from "adminComponents/molecules/EmptyCard";
import { InstantSetBanner } from "adminComponents/molecules/InstantSetBanner";
import {
  IProps as ILibraryPracticeSetCard,
  LibraryPracticeSetCard,
} from "adminComponents/molecules/LibraryPracticeSetCard";
import {
  IProps as ILibraryPracticeSetFolder,
  LibraryPracticeSetFolder,
} from "adminComponents/molecules/LibraryPracticeSetFolder";
import {
  INavigationHeaderProps,
  NavigationHeader,
} from "adminComponents/molecules/NavigationHeader";
import {
  IProps as ILibrarySearchFiltersProps,
  LibrarySearchFilters,
} from "adminComponents/organisms/LibrarySearchFilters";
import { TemplateWithCenteredHeroOneColumn } from "adminComponents/templates/TemplateWithCenteredHeroOneColumn";
import { pxToRem } from "adminComponents/utils";
import {
  ICollection,
  IGradeLevel,
  IPracticeSet,
  ISubject,
  IUser,
} from "links/lib/types";
import { breakpoints } from "sessionComponents/theme/breakpoints";

interface IProps {
  authUser?: IUser;
  folderHandlers: {
    handleDelete: (folder: ICollection) => void;
    handleDuplicate: (folder: ICollection) => void;
    handleEdit: (folder: ICollection) => void;
  };
  folderTotalCount: number;
  folders?: Omit<
    ILibraryPracticeSetFolder,
    "handleDelete" | "handleDuplicate" | "handleEdit"
  >[];
  gradeLevels: IGradeLevel[];
  handleCreateFolder: () => void;
  handleCreatePracticeSet: () => void;
  handleViewMoreFolders?: () => void;
  handleViewMorePracticeSets?: () => void;
  isLoading: boolean;
  isSearchLoading?: boolean;
  navigationData: INavigationHeaderProps;
  practiceSetHandlers: {
    handleDelete: (practiceSet: IPracticeSet) => void;
    handleDuplicate: (practiceSet: IPracticeSet) => void;
    handleMoveToFolder: (practiceSet: IPracticeSet) => void;
  };
  practiceSetTotalCount: number;
  practiceSets?: Omit<
    ILibraryPracticeSetCard,
    "handleDelete" | "handleDuplicate" | "handleMoveToFolder"
  >[];
  recentlyAddedHandlers?: {
    handleDelete: (practiceSet: IPracticeSet) => void;
    handleDuplicate: (practiceSet: IPracticeSet) => void;
    handleMoveToFolder: (practiceSet: IPracticeSet) => void;
  };
  recentlyAdded?: Omit<
    ILibraryPracticeSetCard,
    "handleDelete" | "handleDuplicate" | "handleMoveToFolder"
  >[];
  search: Omit<
    ILibrarySearchFiltersProps,
    "gradeLevels" | "isLoading" | "subjects" | "totalCount"
  >;
  showViewMoreFolders?: boolean;
  showViewMorePracticeSets?: boolean;
  subjects: ISubject[];
  totalCount: number;
  isSearch?: boolean;
  showPremiumMarker?: boolean;
  handleCreateInstantSet: () => void;
  hasNoPremiumAccess?: boolean;
}

export const MyLibraryScreen: React.FC<IProps> = ({
  authUser,
  folderHandlers,
  folderTotalCount,
  folders,
  gradeLevels,
  handleCreateFolder,
  handleCreatePracticeSet,
  handleViewMoreFolders,
  handleViewMorePracticeSets,
  isLoading,
  isSearchLoading,
  navigationData,
  practiceSetHandlers,
  practiceSetTotalCount,
  practiceSets,
  recentlyAddedHandlers,
  recentlyAdded,
  search,
  showViewMoreFolders,
  showViewMorePracticeSets,
  subjects,
  totalCount,
  isSearch,
  showPremiumMarker = true,
  handleCreateInstantSet,
  hasNoPremiumAccess,
}) => {
  const { t } = useTranslation("admin", {
    keyPrefix: "myLibrary",
    useSuspense: false,
  });
  const { t: tCommon } = useTranslation("admin", {
    keyPrefix: "common",
    useSuspense: false,
  });
  const isMobile = useBreakpointValue({ base: true, lg: false });

  const recentlyAddedCards = recentlyAdded?.length
    ? recentlyAdded.map((recent) => {
        const { practiceSet } = recent;

        return (
          <LibraryPracticeSetCard
            {...recent}
            {...recentlyAddedHandlers}
            compact
            practiceSet={practiceSet}
            key={`recent-${practiceSet.id}`}
            showPremiumMarker={showPremiumMarker}
            authUser={authUser}
          />
        );
      })
    : null;

  const menuItems: Array<{
    label: string;
    icon: IconType;
    description: string;
    isDisabled: boolean;
    handleClick: React.MouseEventHandler<HTMLButtonElement>;
  }> = [
    {
      label: t("createPracticeSetButtonText"),
      description: t("createPracticeSetButtonText"),
      icon: "add_circle_outlined",
      isDisabled: false,
      handleClick: handleCreatePracticeSet,
    },
    {
      label: t("createFolderButtonText"),
      description: t("createFolderButtonText"),
      icon: "folder_outlined",
      isDisabled: false,
      handleClick: handleCreateFolder,
    },
  ];

  return (
    <TemplateWithCenteredHeroOneColumn
      heroContent={
        <Flex
          direction={{ base: "column", md: "row" }}
          justifyContent="space-between"
        >
          <Heading as="h1" mb={{ base: pxToRem(20), md: 0 }} variant="adminH2">
            {t("headerTitle")}
          </Heading>
          <Flex direction={{ base: "column", sm: "row" }}>
            <Menu computePositionOnMount variant="adminDropdown">
              <MenuButton>
                <Button
                  variant="adminButtonWhite"
                  width={{ base: "100%", sm: "initial" }}
                  leftIcon={<Icon icon="add" />}
                  rightIcon={<Icon icon="arrow_drop_down" />}
                >
                  {tCommon("create")}
                </Button>
              </MenuButton>
              <MenuList zIndex="dropdown">
                {menuItems.map((item, index) => (
                  <MenuItem key={index} onClick={item.handleClick}>
                    <HStack>
                      <Icon icon={item.icon} />
                      <Text variant="adminP2" w="full">
                        {item.label}
                      </Text>
                    </HStack>
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </Flex>
        </Flex>
      }
      isLoading={isLoading}
      nav={<NavigationHeader {...navigationData} isLoading={isLoading} />}
    >
      {!!isSearch || !!folderTotalCount || !!practiceSetTotalCount ? (
        <>
          {!!recentlyAdded?.length && (
            <>
              <Box>
                <Heading as="h2" mb={{ md: pxToRem(24) }} variant="adminH3">
                  {t("recentlyAdded")}
                </Heading>
                {isMobile ? (
                  <Box
                    ml={{
                      base: "-admin.mobileXPadding",
                      md: "-admin.desktopXPadding",
                      lg: undefined,
                    }}
                    sx={{
                      ".swiper": {
                        pl: {
                          base: "admin.mobileXPadding",
                          md: "admin.desktopXPadding",
                          lg: undefined,
                        },
                        pr: {
                          base: "admin.mobileXPadding",
                          md: "admin.desktopXPadding",
                          lg: undefined,
                        },
                      },
                    }}
                    width={{
                      base: "calc(100% + (var(--chakra-space-admin-mobileXPadding) * 2))",
                      md: "calc(100% + (var(--chakra-space-admin-desktopXPadding) * 2))",
                      lg: undefined,
                    }}
                  >
                    <Carousel
                      style={{ paddingBottom: "80px" }}
                      breakpoints={{
                        [breakpoints.portrait.lg.max]: {
                          slidesPerView: 2.25,
                        },
                      }}
                      showDots
                      slidesPerView={1.25}
                      // Disabling the a11y module of the Swiper Carousel
                      // is necessary for the drop down menus on these cards
                      // to work inside the carousel. Keyboard support
                      // appears to still work.
                      a11y={{ enabled: false }}
                    >
                      {recentlyAddedCards}
                    </Carousel>
                  </Box>
                ) : (
                  <SimpleGrid
                    columns={4}
                    spacing={pxToRem(10)}
                    sx={{ "> div > div": { height: "100%" } }}
                  >
                    {recentlyAddedCards}
                  </SimpleGrid>
                )}
              </Box>
              <Divider color="primary.tan" mb={pxToRem(40)} mt={pxToRem(40)} />
            </>
          )}
          <Heading as="h2" mb={pxToRem(24)} variant="adminH3">
            {t("practiceSets")}
          </Heading>
          <FormLabel htmlFor="practiceSetSearch" id="practiceSetSearchLabel">
            {t("searchPracticeSets")}
          </FormLabel>
          <LibrarySearchFilters
            {...search}
            gradeLevels={gradeLevels}
            isLoading={!!isSearchLoading}
            subjects={subjects}
            totalCount={totalCount}
            aria-labelled-by="practiceSetSearchLabel"
            inputId="practiceSetSearch"
          />
          {!!folders?.length && folderHandlers && !!folderTotalCount && (
            <Box mt={pxToRem(24)}>
              <Flex
                alignItems="center"
                mb={{ base: pxToRem(24), md: pxToRem(32) }}
                justifyContent="space-between"
              >
                <Heading as="h3" variant="adminH6">
                  {t("folders")}
                </Heading>
                <Text variant="adminP2" color="primary.warm-black">
                  {t("folderCount", { count: folderTotalCount })}
                </Text>
              </Flex>
              <SimpleGrid
                columns={{ base: 1, sm: 2, md: 3, lg: 4 }}
                mb={{ base: pxToRem(24), md: pxToRem(32) }}
                spacing={pxToRem(10)}
                sx={{ "> div > div": { height: "100%" } }}
              >
                {folders.map((folder) => {
                  const { collection } = folder;

                  return (
                    <LibraryPracticeSetFolder
                      {...folder}
                      {...folderHandlers}
                      collection={collection}
                      key={`folder-${collection.id}`}
                    />
                  );
                })}
              </SimpleGrid>
              {showViewMoreFolders && (
                <Flex
                  justifyContent="center"
                  mb={{ base: pxToRem(24), md: pxToRem(32) }}
                  mt={{ base: pxToRem(12), md: pxToRem(24) }}
                >
                  <Button
                    onClick={handleViewMoreFolders}
                    rightIcon={
                      <Icon
                        color="currentColor"
                        height={pxToRem(7)}
                        icon="button_down_arrow"
                        ml={pxToRem(5)}
                        width={pxToRem(12)}
                      />
                    }
                    variant="adminTextButtonMedium"
                  >
                    {t("viewMore")}
                  </Button>
                </Flex>
              )}
            </Box>
          )}
          {!!practiceSets?.length &&
            practiceSetHandlers &&
            !!practiceSetTotalCount && (
              <Box mt={pxToRem(24)}>
                <Flex
                  alignItems="center"
                  mb={{ base: pxToRem(24), md: pxToRem(32) }}
                  justifyContent="space-between"
                >
                  <Heading as="h3" variant="adminH6">
                    {t("practiceSets")}
                  </Heading>
                  <Text variant="adminP2" color="primary.warm-black">
                    {t("practiceSetCount", { count: practiceSetTotalCount })}
                  </Text>
                </Flex>
                <SimpleGrid
                  columns={{ base: 1, sm: 2, md: 3, lg: 4 }}
                  spacing={pxToRem(10)}
                >
                  {practiceSets.map((practice) => {
                    const { practiceSet } = practice;

                    return (
                      <LibraryPracticeSetCard
                        {...practice}
                        {...practiceSetHandlers}
                        key={`practiceSet-${practiceSet.id}`}
                        practiceSet={practiceSet}
                        showPremiumMarker={showPremiumMarker}
                        authUser={authUser}
                      />
                    );
                  })}
                </SimpleGrid>
                {showViewMorePracticeSets && (
                  <Flex
                    justifyContent="center"
                    mb={{ base: pxToRem(24), md: pxToRem(32) }}
                    mt={{ base: pxToRem(12), md: pxToRem(24) }}
                  >
                    <Button
                      onClick={handleViewMorePracticeSets}
                      rightIcon={
                        <Icon
                          color="currentColor"
                          height={pxToRem(7)}
                          icon="button_down_arrow"
                          ml={pxToRem(5)}
                          width={pxToRem(12)}
                        />
                      }
                      variant="adminTextButtonMedium"
                    >
                      {t("viewMore")}
                    </Button>
                  </Flex>
                )}
              </Box>
            )}

          {folderTotalCount === 0 &&
            practiceSetTotalCount === 0 &&
            !isSearchLoading && (
              <Box mt={pxToRem(24)}>
                <EmptyCard
                  actions={[
                    {
                      onClick: handleCreatePracticeSet,
                      text: t("createPracticeSetButtonText"),
                      variant: "adminButtonFilled",
                    },
                  ]}
                  text={t("emptySearchText")}
                  title={t("emptySearchTitle")}
                />
              </Box>
            )}

          {isSearchLoading && (
            <Box mt={pxToRem(24)}>
              <LoadingSpinner />
            </Box>
          )}
        </>
      ) : (
        <EmptyCard
          actions={[
            {
              onClick: handleCreatePracticeSet,
              text: t("createPracticeSetButtonText"),
              variant: "adminButtonFilled",
            },
          ]}
          text={t("emptyText")}
          title={t("emptyTitle")}
        />
      )}
      <Box marginTop={pxToRem(60)}>
        <InstantSetBanner
          isLoading={isLoading}
          title={t("instantSetsTitle")}
          description={t("instantSetsDescription")}
          handleGenerateInstantSet={handleCreateInstantSet}
          enablePremiumTooltip={hasNoPremiumAccess && !authUser?.is_guest}
        />
      </Box>
    </TemplateWithCenteredHeroOneColumn>
  );
};
