import { UseMutationResult, useMutation } from "react-query";

import { useAxios } from "../../hooks/useAxios";

interface LLMChatMessage {
  content: string;
}

export enum LLMModel {
  GPT4o = "gpt-4o",
  GPT4Turbo = "gpt-4-turbo",
  GPT4 = "gpt-4",
  GPT3Dot5Turbo = "gpt-3.5-turbo",
}

export interface IPerformLLMChatParams {
  messages: LLMChatMessage[];
  llm_model?: LLMModel;
}

export interface IPerformLLMChatResponse {
  response: LLMChatMessage;
}

export interface IUsePerformLLMChatParams {
  onSuccess?: (data: IPerformLLMChatResponse) => void;
  onError?: (error: unknown) => void;
  onSettled?: () => void;
}

export function usePerformLLMChat({
  onSettled,
  onError,
  onSuccess,
}: IUsePerformLLMChatParams): UseMutationResult<
  IPerformLLMChatResponse,
  unknown,
  IPerformLLMChatParams
> {
  const axios = useAxios();

  const performLLMChat = async (args: IPerformLLMChatParams) => {
    const { messages, llm_model } = args;

    const response = await axios({
      method: "post",
      url: `/v1/llm/chat`,
      data: {
        messages,
        llm_model,
      },
    }).then((r) => r.data as IPerformLLMChatResponse);

    return response;
  };

  const mutation = useMutation("perform-llm-chat", performLLMChat, {
    onSettled,
    onSuccess,
    onError,
  });

  return mutation;
}
