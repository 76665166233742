import {
  Box,
  Button,
  Center,
  HStack,
  Heading,
  Input,
  SimpleGrid,
  Tooltip,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import { useAuth } from "links/lib/features/auth";
import { useFetchSkillsOnce } from "links/lib/features/skills";
import { UserRole } from "links/lib/types";

const SkillSearch: React.FC = () => {
  const { authUser } = useAuth();

  const [skillQuery, setSkillQuery] = useState("");

  const fetchSkills = useFetchSkillsOnce();

  useEffect(() => {
    if (skillQuery !== "") {
      fetchSkills.execute({ query: skillQuery, limit: 25, offset: 0 });
    }
    // eslint-disable-next-line
  }, [skillQuery]);

  if (!authUser || authUser.role !== UserRole.ContentSpecialist) return null;

  return (
    <Box p="50px" minH="100vh" bgColor="gray.100">
      <Center w="full" h="full">
        <Heading>Skill Search</Heading>
      </Center>
      <HStack mt="30px">
        <Input
          placeholder="Search for a skill by code or description..."
          onChange={(e) => {
            setSkillQuery(e.currentTarget.value);
          }}
        />
        <Button colorScheme="blue">
          <RouterLink to="/ce95fe32-e800-42de-80e8-033897d829ae/skills/create">
            Create New Skill
          </RouterLink>
        </Button>
      </HStack>

      {fetchSkills.data && (
        <SimpleGrid columns={5} spacing="20px" my="20px">
          {fetchSkills.data.skills.map((skill) => (
            <Tooltip label={skill.description} key={skill.id}>
              <Button variant="solid" colorScheme="twitter">
                <RouterLink
                  to={`/ce95fe32-e800-42de-80e8-033897d829ae/skills/${skill.id}`}
                >
                  {skill.code}
                </RouterLink>
              </Button>
            </Tooltip>
          ))}
        </SimpleGrid>
      )}
    </Box>
  );
};

export default SkillSearch;
