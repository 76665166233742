import {
  Box,
  Flex,
  FlexProps,
  GridItem,
  Image,
  SimpleGrid,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { NavigationLink } from "adminComponents";
import { Avatar } from "adminComponents/atoms/Avatar";
import { Icon } from "adminComponents/atoms/Icon";
import { Modal } from "adminComponents/atoms/Modal";
import { Text } from "adminComponents/atoms/Text";
import { TextLink } from "adminComponents/atoms/TextLink";
import { generateStudentName } from "adminComponents/utils/generateStudentName";
import { pxToRem } from "adminComponents/utils/pxToRem";
import {
  isImageChoicePossibleQuestionType,
  isMultipleQuestionType,
  isPlainTextAnswerQuestionType,
  isTextOrNumberQuestionType,
} from "adminComponents/utils/questionType";
import {
  IPracticeSetItem,
  ISessionItemResponse,
  IUser,
  QuestionType,
} from "links/lib/types";
import { RichTextRenderer } from "sharedComponents/atoms/RichTextRenderer";
import ZoomableImage from "sharedComponents/molecules/ZoomableImage";

export interface IStudentsData {
  practiceSetItem?: IPracticeSetItem;
  choiceId?: string;
  question: {
    correctResponse?: string;
    correctImageUrl?: string;
    correctImageAltText?: string;
    prompt?: string;
    number?: number;
    questionType: QuestionType;
  };
  data: {
    response: ISessionItemResponse;
    student: IUser;
  }[];
}

interface IProps {
  data?: IStudentsData;
  isOpen: boolean;
  handleClose: () => void;
  classroomId: string;
}

export const StudentsModal: React.FC<IProps> = ({
  data: studentsData,
  isOpen,
  handleClose,
  classroomId,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  const {
    question,
    data: responses,
    practiceSetItem,
    choiceId,
  } = studentsData || {};

  const textVariant = useBreakpointValue({ md: "adminP1", sm: "adminP2" });

  const isDiagram = question?.questionType === QuestionType.Diagram;
  const isClassify = question?.questionType === QuestionType.Classify;
  const isDraw = question?.questionType === QuestionType.Draw;
  const isMultipleQuestion = isMultipleQuestionType(question?.questionType);
  const isTextOrNumber = isTextOrNumberQuestionType(question?.questionType);
  const questionUsesPlainTextAnswers = isPlainTextAnswerQuestionType(
    question?.questionType
  );
  const questionUsesImageAnswers =
    isImageChoicePossibleQuestionType(question?.questionType) &&
    (responses || []).some(
      (response) =>
        response.response.choice_image_url ||
        response.response.drawing_image_url
    );

  const students = useMemo(() => {
    const res: { [key: string]: IUser } = {};
    responses?.forEach((r) => {
      if (isClassify && choiceId && !r.response.is_correct) return;
      res[r.student.id] = r.student;
    });
    return res;
  }, [responses, isClassify, choiceId]);
  const studentsCount = Object.keys(students).length;
  const title = t("studentsModal.title", { count: studentsCount });

  // If a specific item is chosen in a classify answer list, the UI is very similar to other
  // question types. However, if "All Students" is selected, the UI must render all of the
  // placements for each student, which is handled by the ClassifyStudentFullResultsList component
  // below
  const shouldRenderFullClassifyResults = isClassify && !choiceId;

  const onlyCorrectResponsesShowing = responses?.every(
    (r) => r.response.is_correct
  );

  let subtitle = onlyCorrectResponsesShowing
    ? t("studentsModal.singleSubtitleCorrect")
    : t("studentsModal.singleSubtitleIncorrect");
  if (isMultipleQuestion) subtitle = t("studentsModal.multipleSubtitle");
  if (isDiagram) subtitle = t("studentsModal.diagramSubtitle");
  if (isDraw) subtitle = t("studentsModal.drawSubtitle");
  if (isClassify) {
    if (choiceId) {
      subtitle = t("studentsModal.classifySubtitleSingleItem");
    } else {
      subtitle = t("studentsModal.classifySubtitle");
    }
  }

  const showCorrectResponse =
    isTextOrNumber || (isClassify && choiceId) || isDiagram;

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      showBackButton={false}
      title={title}
      variant="adminStudentsModal"
      headingProps={{
        as: "h2",
        fontSize: [pxToRem(18), null, pxToRem(24)],
        variant: "adminH6",
      }}
    >
      {responses && question && (
        <>
          {question?.prompt && (
            <>
              <Text color="primary.dark-gray" variant={textVariant}>
                {subtitle}:
              </Text>
              <Text as="div" variant={textVariant}>
                <RichTextRenderer content={question.prompt} />
              </Text>
            </>
          )}
          {showCorrectResponse && (
            <Flex marginTop={pxToRem(8)}>
              {isDiagram && question.number && (
                <Text variant="adminP2Bold" marginRight={pxToRem(8)}>
                  {question.number}.
                </Text>
              )}
              {!choiceId && (
                <Text variant="adminP2Bold">
                  {t("studentsModal.correctResponse")}:&nbsp;
                </Text>
              )}
              {questionUsesImageAnswers ? (
                <Image
                  borderRadius="lg"
                  src={question.correctImageUrl}
                  alt={question.correctImageAltText}
                  w={pxToRem(120)}
                />
              ) : (
                <Text as="div" variant="adminP2">
                  {questionUsesPlainTextAnswers ? (
                    question.correctResponse
                  ) : (
                    <RichTextRenderer
                      content={question.correctResponse || ""}
                    />
                  )}
                </Text>
              )}
            </Flex>
          )}
          <Box
            borderColor="primary.medium-gray"
            backgroundColor="primary.white"
            padding={[pxToRem(8), null, pxToRem(18)]}
            borderWidth={pxToRem(1)}
            borderRadius={pxToRem(20)}
            marginTop={pxToRem(24)}
            flex={[1, null, null]}
            w="full"
          >
            {isDraw ? (
              <SimpleGrid
                minChildWidth={pxToRem(200)}
                spacing={pxToRem(16)}
                justifyItems="center"
              >
                {responses.map(({ student, response }) => {
                  return (
                    <GridItem
                      key={`${student.id}-${response.id}`}
                      w="fit-content"
                    >
                      <Flex flexDir="column" gap={pxToRem(10)}>
                        <Box
                          borderColor="primary.medium-gray"
                          borderWidth={pxToRem(1)}
                          borderStyle="solid"
                          borderRadius="lg"
                          w="fit-content"
                          overflow="hidden"
                        >
                          <ZoomableImage
                            src={response.drawing_image_url}
                            alt=""
                            maxH={pxToRem(150)}
                          />
                        </Box>
                        <StudentListItem
                          student={student}
                          responseText={response.response_text}
                          choiceImageUrl={response.choice_image_url}
                          choiceImageAltText={response.choice_image_alt_text}
                          showResponse={!!response}
                          classroomId={classroomId}
                          questionUsesPlainTextAnswers={
                            questionUsesPlainTextAnswers
                          }
                          questionUsesImageAnswers={questionUsesImageAnswers}
                          p="0"
                        />
                      </Flex>
                    </GridItem>
                  );
                })}
              </SimpleGrid>
            ) : (
              <Flex
                direction="column"
                maxHeight={[null, null, pxToRem(450)]}
                paddingY={[pxToRem(8), null, pxToRem(18)]}
                overflowY="auto"
              >
                {shouldRenderFullClassifyResults && (
                  <ClassifyStudentFullResultsList
                    students={students}
                    responses={responses}
                    practiceSetItem={practiceSetItem}
                    questionUsesImageAnswers={questionUsesImageAnswers}
                    classroomId={classroomId}
                  />
                )}
                {!shouldRenderFullClassifyResults &&
                  responses?.map(({ student, response }) => {
                    // The student modal shown for a specific classify choice shows
                    // only the students that placed that choice correctly
                    if (isClassify && !response.is_correct) return null;
                    return (
                      <StudentListItem
                        key={`${student.id}-${response.id}`}
                        student={student}
                        responseText={response.response_text}
                        choiceImageUrl={response.choice_image_url}
                        choiceImageAltText={response.choice_image_alt_text}
                        showResponse={!!response}
                        classroomId={classroomId}
                        questionUsesPlainTextAnswers={
                          questionUsesPlainTextAnswers
                        }
                        questionUsesImageAnswers={questionUsesImageAnswers}
                        alignItems="flex-start"
                      />
                    );
                  })}
              </Flex>
            )}
          </Box>
        </>
      )}
    </Modal>
  );
};

interface IClassifyStudentFullResultsListProps {
  students: { [key: string]: IUser };
  responses: {
    response: ISessionItemResponse;
    student: IUser;
  }[];
  practiceSetItem?: IPracticeSetItem;
  questionUsesImageAnswers: boolean;
  classroomId: string;
}

const ClassifyStudentFullResultsList: React.FC<
  IClassifyStudentFullResultsListProps
> = ({
  students,
  responses,
  practiceSetItem,
  questionUsesImageAnswers,
  classroomId,
}) => {
  return (
    <VStack>
      {Object.values(students).map((student) => {
        const studentResponses = responses.filter(
          (r) => r.student.id === student.id
        );
        const studentName = generateStudentName(student).primary;

        const navigationLink: NavigationLink = {
          to: `/t/classrooms/${classroomId}/students/${student.id}`,
          label: studentName,
          isExternal: false,
        };
        return (
          <Flex key={`student-${student.id}`} w="full" gap={pxToRem(15)}>
            <Avatar name={studentName} src={student.profile_image_url} />
            <Flex w="full" flexDir="column" alignItems="start">
              <TextLink navigationLink={navigationLink}>{studentName}</TextLink>
              <SimpleGrid
                w="full"
                columns={[1, null, 2]}
                gap={[pxToRem(10), null, 0]}
              >
                {practiceSetItem?.classify?.categories.map((category) => {
                  const studentResponsesInCategory = studentResponses.filter(
                    (r) => r.response.zone_id === category.id
                  );
                  return (
                    <Flex
                      key={`category-${category.id}`}
                      flexDir="column"
                      gap={pxToRem(4)}
                    >
                      <Text as="div" variant="adminP2Bold">
                        <RichTextRenderer content={category.text} />
                      </Text>
                      <SimpleGrid
                        columns={questionUsesImageAnswers ? 2 : 1}
                        gap={pxToRem(4)}
                      >
                        {studentResponsesInCategory.map((r) => {
                          return (
                            <Flex
                              key={r.response.id}
                              alignItems="center"
                              gap={pxToRem(8)}
                              position="relative"
                              w="fit-content"
                              boxSize={
                                questionUsesImageAnswers
                                  ? pxToRem(72)
                                  : undefined
                              }
                            >
                              <Icon
                                boxSize={pxToRem(20)}
                                variant="circleWithBackgroundColor"
                                icon={r.response.is_correct ? "check" : "close"}
                                backgroundColor={
                                  r.response.is_correct
                                    ? "utility.question-green"
                                    : "utility.question-red"
                                }
                                position={
                                  questionUsesImageAnswers
                                    ? "absolute"
                                    : undefined
                                }
                                top={
                                  questionUsesImageAnswers
                                    ? pxToRem(5)
                                    : undefined
                                }
                                left={
                                  questionUsesImageAnswers
                                    ? pxToRem(5)
                                    : undefined
                                }
                              />
                              {questionUsesImageAnswers ? (
                                <Image
                                  borderRadius="lg"
                                  src={r.response.choice_image_url}
                                  alt={r.response.choice_image_alt_text}
                                  maxH="full"
                                  maxW="full"
                                />
                              ) : (
                                <Text
                                  as="div"
                                  variant="adminP2"
                                  marginTop={pxToRem(3)}
                                >
                                  <RichTextRenderer
                                    content={r.response.choice_text}
                                  />
                                </Text>
                              )}
                            </Flex>
                          );
                        })}
                      </SimpleGrid>
                    </Flex>
                  );
                })}
              </SimpleGrid>
            </Flex>
          </Flex>
        );
      })}
    </VStack>
  );
};

interface IItemProps extends FlexProps {
  avatarSize?: string;
  responseText: string;
  choiceImageUrl?: string;
  choiceImageAltText?: string;
  student: IUser;
  showResponse: boolean;
  classroomId: string;
  questionUsesPlainTextAnswers: boolean;
  questionUsesImageAnswers: boolean;
  alignItems?: string;
}

export const StudentListItem: React.FC<IItemProps> = ({
  student,
  responseText,
  choiceImageUrl,
  choiceImageAltText,
  showResponse,
  classroomId,
  questionUsesPlainTextAnswers,
  questionUsesImageAnswers,
  alignItems = "center",
  ...rest
}) => {
  const { primary: studentName } = generateStudentName(student);

  const navigationLink: NavigationLink = {
    to: `/t/classrooms/${classroomId}/students/${student.id}`,
    label: generateStudentName(student).primary,
    isExternal: false,
  };

  return (
    <Flex alignItems="center" gap={pxToRem(12)} padding={pxToRem(8)} {...rest}>
      <Avatar name={studentName} src={student.profile_image_url || ""} />
      <Flex direction="column" alignItems={alignItems} wordBreak="break-all">
        <TextLink navigationLink={navigationLink}>{studentName}</TextLink>
        {showResponse && questionUsesImageAnswers ? (
          <ZoomableImage
            src={choiceImageUrl || ""}
            alt={choiceImageAltText || ""}
            maxH={pxToRem(72)}
          />
        ) : (
          <Text minWidth={0} as="div" variant="adminP3">
            {questionUsesPlainTextAnswers && responseText}
            {!questionUsesPlainTextAnswers && (
              <RichTextRenderer content={responseText} />
            )}
          </Text>
        )}
      </Flex>
    </Flex>
  );
};
