import { Box, Button, Select, SimpleGrid } from "@chakra-ui/react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { useCoverImageBackground } from "adminComponents/atoms/CoverImageBackground/hooks/useCoverImageBackground";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { AvatarAnimations } from "links/lib/constants";
import { useFetchAvatar } from "links/lib/features/avatars";
import {
  CoverImageBGColorSchemeOptions,
  CoverImageBGColorSchemeType,
  CoverImageBGPatternOptions,
  CoverImageBGPatternType,
} from "links/lib/types";
import {
  AvatarStage,
  AvatarStageItem,
  AvatarStageMember,
} from "sharedComponents/molecules/AvatarStage";
import { SpineStage } from "sharedComponents/molecules/AvatarStage/lib/SpineStage";

const PhotoBooth: React.FC = () => {
  const spineStageRef = useRef<SpineStage | undefined>(undefined);
  const [backgroundImage, setBackgroundImage] = useState<string | null>(null);

  const camera = useMemo(() => {
    return { x: 0, y: 0, z: 0, zoom: 1 };
  }, []);

  const fetchAvatar = useFetchAvatar({
    include_items: false,
  });

  const animationOptions = useMemo(
    () => Object.values(AvatarAnimations).flatMap((v) => v),
    []
  );

  const [bgColorScheme, setBGColorScheme] =
    useState<CoverImageBGColorSchemeType>("LIGHT_BLUE");
  const [bgPattern, setBGPattern] = useState<CoverImageBGPatternType | "NONE">(
    "OTHER_FUN_PARTY"
  );
  const [animation, setAnimation] = useState<string>("idle_01");

  const onBGPatternChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setBGPattern(e.target.value as CoverImageBGPatternType);
  };

  const onBGColorSchemeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setBGColorScheme(e.target.value as CoverImageBGColorSchemeType);
  };

  const onAnimationChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setAnimation(e.target.value);
  };

  const onRequestCapture = () => {
    spineStageRef.current?.requestCapture();
  };

  const onCapture = useCallback((canvas: HTMLCanvasElement) => {
    canvas.toBlob((blob) => {
      if (!blob) return;
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, "_blank");
    });
  }, []);

  const { tileImage } = useCoverImageBackground({
    pattern: bgPattern === "NONE" ? CoverImageBGPatternOptions[0] : bgPattern,
    colorScheme: bgColorScheme,
    tileSize: 200,
  });

  useEffect(() => {
    if (!tileImage) return;

    const tileCanvas = document.createElement("canvas");
    tileCanvas.width = 1000;
    tileCanvas.height = 800;
    const tileContext = tileCanvas.getContext("2d");

    if (!tileContext) return;

    const img = new Image();
    img.src = tileImage;

    img.addEventListener("load", () => {
      const ptrn = tileContext.createPattern(img, "repeat");

      if (!ptrn) return;

      tileContext.fillStyle = ptrn;
      tileContext.fillRect(0, 0, tileCanvas.width, tileCanvas.height);

      setBackgroundImage(tileCanvas.toDataURL());
    });
  }, [tileImage]);

  return (
    <Box maxW={pxToRem(1000)} mx="auto" my={pxToRem(100)}>
      <SimpleGrid minChildWidth={pxToRem(100)} gap={pxToRem(20)}>
        <Select value={bgColorScheme} onChange={onBGColorSchemeChange}>
          {CoverImageBGColorSchemeOptions.map((o) => (
            <option key={o} value={o}>
              {o}
            </option>
          ))}
        </Select>
        <Select value={bgPattern} onChange={onBGPatternChange}>
          {["NONE"].concat(CoverImageBGPatternOptions).map((o) => (
            <option key={o} value={o}>
              {o}
            </option>
          ))}
        </Select>
        <Select value={animation} onChange={onAnimationChange}>
          {animationOptions.map((o) => (
            <option key={o.name} value={o.name}>
              {o.name}
            </option>
          ))}
        </Select>
      </SimpleGrid>

      {!!fetchAvatar.data &&
        !!fetchAvatar.data.avatar &&
        !!fetchAvatar.data.spine_atlas_url &&
        !!fetchAvatar.data.spine_json_url &&
        !!fetchAvatar.data.spritesheet_url && (
          <AvatarStage
            camera={camera}
            alignX="center"
            alignY="end"
            style={{
              width: "100%",
              maxWidth: "1000px",
              height: "800px",
              maxHeight: "800px",
              background: "transparent",
            }}
            stageSize={{ x: 1000, y: 800 }}
          >
            {(spineStage, stageOverlay) => {
              if (
                !fetchAvatar.data.spine_atlas_url ||
                !fetchAvatar.data.spine_json_url
              )
                return <></>;

              if (spineStage !== spineStageRef.current) {
                spineStageRef.current = spineStage;
                spineStageRef.current?.onCapture(onCapture);
              }

              return (
                <>
                  {backgroundImage && bgPattern !== "NONE" && (
                    <AvatarStageItem
                      key={backgroundImage}
                      imageSrc={backgroundImage}
                      scale={1}
                      x={0}
                      y={0}
                      spineStage={spineStage}
                      drawOrder={1}
                    />
                  )}

                  <AvatarStageMember
                    animation={{ name: animation, loop: true }}
                    scale={0.6}
                    x={0}
                    y={-340}
                    atlasUrl={fetchAvatar.data.spine_atlas_url}
                    skeletonUrl={fetchAvatar.data.spine_json_url}
                    spineStage={spineStage}
                    stageOverlay={stageOverlay}
                    drawOrder={2}
                  />
                </>
              );
            }}
          </AvatarStage>
        )}
      <Box w="full" display="flex" justifyContent="center" mt={pxToRem(20)}>
        <Button onClick={onRequestCapture}>Snap!</Button>
      </Box>
    </Box>
  );
};

export { PhotoBooth };
