import { colorMapping } from "adminComponents/theme/colors";
import { pxToRem } from "adminComponents/utils/pxToRem";

export const AdminNavigationHeader = {
  baseStyle: ({
    showMenu,
    isNavTransparent,
    isBreadcrumbVisible,
    isGrayscale,
    showTrialGracePeriodBanner,
  }: {
    showMenu?: boolean;
    isNavTransparent: boolean;
    isBreadcrumbVisible: boolean;
    isGrayscale?: boolean;
    showTrialGracePeriodBanner?: boolean;
    isMobile?: boolean;
  }): Record<string, unknown> => ({
    outer: {
      width: "100%",
      paddingBottom: {
        base: pxToRem(110),
        sm: pxToRem(130),
        md: pxToRem(150),
        lg: pxToRem(160),
      },
    },
    navContainerMobile: {
      display: { md: "none" },
    },
    navContainerDesktop: {
      display: { base: "none", md: "flex" },
      justifyContent: "space-between",
      alignItems: "center",
      gap: pxToRem(6.5),
    },
    navItemMobile: {
      marginBottom: pxToRem(10),
      a: {
        span: {
          width: "fit-content",
        },
        _focus: {
          boxShadow: "none",
        },
        _focusVisible: {
          span: {
            outline: `${pxToRem(2)} solid ${colorMapping["utility.focus"]}`,
            borderRadius: pxToRem(4),
          },
        },
      },
      _notFirst: {
        marginTop: pxToRem(15),
      },
    },
    firstRowContainer: {
      transition: "background-color 250ms",
      justifyContent: "space-between",
      alignItems: "center",
      minHeight: {
        base: "admin.nav.mobileTopRowHeight",
        md: "admin.nav.desktopTopRowHeight",
      },
      zIndex: 11,
      position: "fixed",
      width: "100%",
    },
    firstRow: {
      display: "flex",
      backgroundColor: isNavTransparent
        ? "transparent"
        : isGrayscale
        ? "utility.navigation-bg-gray"
        : "utility.navigation-bg-blue",

      transition: "background-color 250ms",
      justifyContent: "space-between",
      alignItems: "center",
      height: {
        base: "admin.nav.mobileTopRowHeight",
        md: "admin.nav.desktopTopRowHeight",
      },
      paddingX: { base: "admin.mobileXPadding", md: "admin.desktopXPadding" },
      zIndex: 11,
      width: "100%",
    },
    gracePeriodBanner: {
      display: "flex",
      backgroundColor: "transparent",
      transition: "background-color 250ms",
      justifyContent: "space-between",
      alignItems: "center",
      paddingX: { base: "admin.mobileXPadding", md: "admin.desktopXPadding" },
      zIndex: 11,
      width: "100%",
    },
    secondRow: {
      display: "flex",
      opacity: isBreadcrumbVisible ? 1 : 0,
      paddingTop: showTrialGracePeriodBanner
        ? { base: pxToRem(94), md: pxToRem(126) }
        : { base: pxToRem(64), md: pxToRem(96) },
      paddingX: { base: "admin.mobileXPadding", md: "admin.desktopXPadding" },
      pointerEvents: isBreadcrumbVisible ? "auto" : "none",
      position: "fixed",
      left: 0,
      right: 0,
      transition: "opacity 250ms",
    },
    avatar: {
      position: "relative",
    },
    avatarMenu: {
      marginTop: pxToRem(42),
      display: showMenu
        ? { base: "block", md: "block" }
        : { base: "none", md: "block" },
      opacity: { md: showMenu ? 1 : 0 },
      transform: { md: showMenu ? "scale(1)" : "scale(0.5)" },
      transformOrigin: { md: "top right" },
      overflow: { md: "hidden" },
      position: "absolute",
      top: 0,
      right: 0,
      visibility: showMenu ? "visible" : "hidden",
      transition: {
        md: showMenu
          ? "transform 250ms, opacity 250ms 150ms"
          : "transform 250ms 150ms, opacity 250ms ",
      },
    },
    avatarContainer: {
      position: "relative",
    },
    avatarNavContainer: {
      width: ["auto", null, pxToRem(154)],
      display: "flex",
      justifyContent: "right",
    },
    assignments: {
      marginRight: { base: 0, md: pxToRem(32) },
    },
  }),
  variants: {
    teacherHomepageNav: {
      outer: {
        paddingBottom: [pxToRem(76), null, pxToRem(160)],
      },
    },
  },
};
