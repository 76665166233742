import { AxiosError } from "axios";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { usePrevious, useUnmount } from "react-use";

import { IInstantSetConfig } from "adminComponents/molecules/InstantSetConfiguration";
import { getPracticeSetUrl } from "adminComponents/molecules/LibraryPracticeSetCard";
import { ICoverImage } from "adminComponents/molecules/PracticeSetCoverImagePicker";
import { CreatePracticeSetFlyout } from "adminComponents/organisms/CreatePracticeSetFlyout";
import type { IGenerateState as IInstantSetGenerateState } from "adminComponents/organisms/InstantSetFlyout";
import { FormData } from "adminComponents/organisms/PracticeSetDetailFlyout";
import type { IGenerateState as ISmartSetGenerateState } from "adminComponents/organisms/SmartSetFlyout";
import { useErrorToast, useShowToast } from "adminComponents/utils/toast";
import { useHubSpot } from "lib/hooks/useHubSpot";
import { config } from "links/lib/constants";
import {
  emptyPracticeSet,
  emptyPracticeSetGradeLevel,
  emptyPracticeSetSubject,
} from "links/lib/empty";
import { useAuth } from "links/lib/features/auth";
import { useFetchClassrooms } from "links/lib/features/classrooms";
import { useUploadFile } from "links/lib/features/files";
import {
  useFetchGroupsSmartSetsCapabilities,
  useGenerateGroupSmartSetPreview,
  useMutatePracticeSet,
} from "links/lib/features/practiceSets";
import {
  IMutatePracticeSetArgs,
  IMutatePracticeSetResponse,
} from "links/lib/features/practiceSets/useMutatePracticeSet";
import { langLabelMap, supportedLangs } from "links/lib/lang";
import {
  AnalyticsEvent,
  CoverImageBGColorSchemeType,
  CoverImageBGPatternType,
  CoverImageIconType,
  GenerateInstantSetStatus,
  ICollection,
  IGradeLevel,
  IPracticeSet,
  IPracticeSetItem,
  IPracticeSetType,
  ISmartSetSettings,
  ISubject,
  PracticeSetStatus,
  SmartSetType,
  UserRole,
} from "links/lib/types";

export interface IArgs {
  collections: Array<ICollection>;
  subjects: Array<ISubject>;
  gradeLevels: Array<IGradeLevel>;
  practiceSetType?: IPracticeSetType;
  onOpen?: () => void;
  onSubmit?: (data: IMutatePracticeSetArgs) => void;
}

export interface IResult {
  handleCreate: (collection?: ICollection) => void;
  flyout: React.ReactElement;
}

interface ICustomSetState {
  coverImage?: ICoverImage;
}

interface IFlyoutState {
  isOpen: boolean;
  initialCollectionId?: string;
}

interface ISmartSetState {
  defaultValue?: {
    smartSetType?: SmartSetType;
    smartSetSettings?: ISmartSetSettings;
  };
  generateState: ISmartSetGenerateState;
}

interface IInstantSetState {
  generateState: IInstantSetGenerateState;
  defaultValue?: IInstantSetConfig;
}

const defaultCoverImage = {
  bgColorScheme: "LIGHT_BLUE" as CoverImageBGColorSchemeType,
  bgPattern: "OTHER_FUN_PARTY" as CoverImageBGPatternType,
  icon: "OTHER_TRIVIA" as CoverImageIconType,
};

export const useCreatePracticeSet = ({
  subjects,
  gradeLevels,
  collections,
  practiceSetType,
  onOpen,
  onSubmit,
}: IArgs): IResult => {
  const [flyoutState, setFlyoutState] = useState<IFlyoutState>({
    isOpen: false,
  });
  const [customSetState, setCustomSetState] = useState<ICustomSetState>({});
  const [smartSetState, setSmartSetState] = useState<ISmartSetState>({
    defaultValue: undefined,
    generateState: {
      isLoading: false,
      isError: false,
      isSuccess: false,
    },
  });
  const [instantSetState, setInstantSetState] = useState<IInstantSetState>({
    defaultValue: undefined,
    generateState: {
      isLoading: false,
      isError: false,
      isSuccess: false,
      status: GenerateInstantSetStatus.Unknown,
    },
  });
  const history = useHistory();
  const showToast = useShowToast();
  const { t } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "createPracticeSetFlyout",
  });
  const { authUser, authToken, hasNoPremiumAccess } = useAuth();
  const { trackHubSpotEvent } = useHubSpot();
  const fetchClassrooms = useFetchClassrooms({ disabled: authUser?.is_guest });
  const fetchSmartSetCapabilities = useFetchGroupsSmartSetsCapabilities({
    enabled: !practiceSetType || practiceSetType === IPracticeSetType.Smart,
  });
  const instantSetWS = useRef<WebSocket | null>(null);

  const onSuccess = (data: IMutatePracticeSetResponse) => {
    history.push(getPracticeSetUrl(data.practice_set, authUser));
    showToast(t("successToast"));
    handleClose();

    trackHubSpotEvent(AnalyticsEvent.HubSpot_CreatedPracticeSet, {});
  };

  const mutatePracticeSet = useMutatePracticeSet({ onSuccess });

  useErrorToast(mutatePracticeSet.error);

  const handleCreate = (collection?: ICollection) => {
    setFlyoutState({ isOpen: true, initialCollectionId: collection?.id });

    onOpen?.();
  };

  const handleClose = () => {
    setFlyoutState({ isOpen: false, initialCollectionId: undefined });
    setCustomSetState({});
    setSmartSetState({
      defaultValue: undefined,
      generateState: {
        isLoading: false,
        isError: false,
        isSuccess: false,
      },
    });
    setInstantSetState({
      defaultValue: undefined,
      generateState: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        status: GenerateInstantSetStatus.Unknown,
      },
    });
  };

  const handleSubmit = useCallback(
    (data: IMutatePracticeSetArgs) => {
      mutatePracticeSet.mutate(data);

      onSubmit?.(data);
    },
    [mutatePracticeSet, onSubmit]
  );

  const typeSelection = useMemo(() => {
    const canCreateSmartSet =
      fetchSmartSetCapabilities.data?.groups.some(
        (gc) =>
          gc.capabilities.filter((capability) => capability.is_capable).length >
          0
      ) ?? false;

    return {
      isLoadingCapabilities: fetchSmartSetCapabilities.isLoading,
      canCreateSmartSet,
      hasNoPremiumAccess: hasNoPremiumAccess,
    };
  }, [
    fetchSmartSetCapabilities.data,
    fetchSmartSetCapabilities.isLoading,
    hasNoPremiumAccess,
  ]);

  /** Custom Set Handlers & Data */

  const handleSaveCustomSet = useCallback(
    (data: FormData) => {
      const {
        title,
        description,
        folder,
        subjects,
        gradeLevels,
        language,
        availability,
        cncFamilyCode,
        cncCode,
        isCertified,
        isPremium,
        smartSetType,
      } = data.form;

      const { bgColorScheme, bgPattern, icon } =
        customSetState.coverImage ?? defaultCoverImage;

      handleSubmit({
        id: undefined,
        title,
        description,
        collection_id: folder,
        status: PracticeSetStatus.Active,
        availability,
        language_code: language,
        grade_levels: gradeLevels.map((id) => ({
          grade_level: { id },
        })),
        subjects: subjects.map((id) => ({ subject: { id } })),
        reference_materials: [],
        cnc_family_code: cncFamilyCode || "",
        cnc_code: cncCode || "",
        is_certified: isCertified || false,
        is_premium: isPremium || false,
        cover_image_bg_color_scheme: bgColorScheme,
        cover_image_bg_pattern: bgPattern,
        cover_image_icon: icon,
        smart_set_type: smartSetType,
      });
    },
    [handleSubmit, customSetState.coverImage]
  );

  const handleCoverImageChange = useCallback((coverImage: ICoverImage) => {
    setCustomSetState({ coverImage });
  }, []);

  const customSet = useMemo(() => {
    const customSetOptions = {
      subjects: subjects.map((s) => ({
        value: s.id,
        label: s.parent_id !== "0" ? `${s.parent_name}: ${s.name}` : s.name,
      })),
      gradeLevels: gradeLevels.map((g) => ({
        value: g.id,
        label: g.grade_level,
      })),
      languages: supportedLangs.map((l) => ({
        value: l,
        label: langLabelMap[l],
      })),
      folders: collections.map((c) => ({
        label: c.name,
        value: c.id,
      })),
    };

    return {
      isLoading: mutatePracticeSet.isLoading,
      handleCoverImageChange,
      handleSubmit: handleSaveCustomSet,
      options: customSetOptions,
      showCncFields: authUser?.role === UserRole.ContentSpecialist,
      allowPublish: true,
      initialCollectionId: flyoutState.initialCollectionId,
    };
  }, [
    mutatePracticeSet.isLoading,
    flyoutState.initialCollectionId,
    authUser?.role,
    subjects,
    gradeLevels,
    collections,
    handleSaveCustomSet,
    handleCoverImageChange,
  ]);

  /** Smart Set Handlers & Data */

  const generateGroupSmartSetPreview = useGenerateGroupSmartSetPreview({
    onSuccess: (data) => {
      setSmartSetState((s) => ({
        ...s,
        generateState: {
          isLoading: false,
          isError: false,
          isSuccess: true,
          items: data.practice_set_items,
          practiceSet: s.generateState.practiceSet,
        },
      }));
    },
    onError: (error: AxiosError) => {
      const status = error.response?.status || 0;

      let message: string | undefined;
      if (status >= 400 && status < 500) {
        message = (error.response?.data as { message?: string }).message;
      }

      setSmartSetState((s) => ({
        ...s,
        generateState: {
          isLoading: false,
          isError: true,
          isSuccess: false,
          errorMessage: message || t("errorGeneratingSmartSet"),
        },
      }));
    },
  });

  const handleSaveSmartSet = useCallback(() => {
    if (!smartSetState.generateState.practiceSet) return;

    const { grade_levels, subjects } = smartSetState.generateState.practiceSet;

    handleSubmit({
      ...smartSetState.generateState.practiceSet,
      id: undefined,
      grade_levels: grade_levels.map((g) => ({
        grade_level: { id: g.grade_level.id },
      })),
      subjects: subjects.map((s) => ({ subject: { id: s.subject.id } })),
      smart_set_settings: smartSetState.defaultValue?.smartSetSettings,
      smart_set_type: smartSetState.defaultValue?.smartSetType,
      practice_set_items: smartSetState.generateState.items,
    });
  }, [smartSetState.defaultValue, smartSetState.generateState, handleSubmit]);

  const handleGenerateSmartSet = useCallback(
    ({
      smartSetSettings,
      smartSetType,
    }: {
      smartSetType: SmartSetType;
      smartSetSettings: ISmartSetSettings;
    }) => {
      if (!smartSetSettings.group_id) return;

      const classroom = fetchClassrooms.data?.classrooms.find(
        (c) => c.id === smartSetSettings.group_id
      );

      if (!classroom) return;

      const gradeLevels = [
        ...(classroom?.group_grade_levels || []).map((gradeLevel) => {
          return {
            ...emptyPracticeSetGradeLevel,
            grade_level: {
              ...emptyPracticeSetGradeLevel.grade_level,
              id: gradeLevel.grade_level_id,
            },
          };
        }),
      ];

      const dateFormat = "MMMM D, YYYY";

      let practiceSet: IPracticeSet | undefined = undefined;

      switch (smartSetType) {
        case SmartSetType.Review: {
          const startDate = moment(smartSetSettings.start_time).format(
            dateFormat
          );
          const endDate = moment(smartSetSettings.end_time).format(dateFormat);
          practiceSet = {
            ...emptyPracticeSet,
            grade_levels: gradeLevels,
            title: t("defaultSmartSetReviewTitle", {
              classroom_name: classroom.name,
              start_date: startDate,
              end_date: endDate,
            }),
            description: t("defaultSmartSetReviewDescription", {
              classroom_name: classroom.name,
              start_date: startDate,
              end_date: endDate,
            }),
            smart_set_type: SmartSetType.Review,
            cover_image_bg_pattern: "SMART_SET",
            collection_id: flyoutState.initialCollectionId ?? "0",
            reference_materials: [],
            cnc_family_code: "",
            cnc_code: "",
            is_certified: false,
            is_premium: false,
            status: PracticeSetStatus.Active,
          };
          break;
        }
        case SmartSetType.StandardsIntervention: {
          const subjectIds = smartSetSettings.subject_ids;
          const subjectsList = subjectIds
            ?.map(
              (subjectId) =>
                subjects.find((subject) => subject.id === subjectId)?.name
            )
            .filter((a) => !!a)
            .join(", ");
          const currentDate = moment().format(dateFormat);

          practiceSet = {
            ...emptyPracticeSet,
            subjects: [
              ...(subjectIds || []).map((subjectId) => {
                return {
                  ...emptyPracticeSetSubject,
                  subject: {
                    ...emptyPracticeSetSubject.subject,
                    id: subjectId,
                  },
                };
              }),
            ],
            grade_levels: gradeLevels,
            title: t("defaultSmartSetStandardsInterventionTitle", {
              classroom_name: classroom.name,
              subjects: subjectsList,
            }),
            description: t("defaultSmartSetStandardsInterventionDescription", {
              classroom_name: classroom.name,
              subjects: subjectsList,
              current_date: currentDate,
            }),
            smart_set_type: SmartSetType.StandardsIntervention,
            cover_image_bg_pattern: "SMART_SET",
            collection_id: flyoutState.initialCollectionId ?? "0",
          };
          break;
        }
      }

      if (!practiceSet) return;

      setSmartSetState({
        defaultValue: {
          smartSetType,
          smartSetSettings,
        },
        generateState: {
          isLoading: true,
          isError: false,
          isSuccess: false,
          practiceSet,
        },
      });

      generateGroupSmartSetPreview.mutate({
        smart_set_settings: smartSetSettings,
        smart_set_type: smartSetType,
      });
    },
    [
      t,
      fetchClassrooms.data,
      subjects,
      flyoutState.initialCollectionId,
      generateGroupSmartSetPreview,
    ]
  );

  const handleRestartSmartSet = useCallback(() => {
    setSmartSetState((s) => ({
      defaultValue: s.defaultValue,
      generateState: {
        isLoading: false,
        isError: false,
        isSuccess: false,
      },
    }));
  }, []);

  const smartSet = useMemo(() => {
    return {
      classrooms: fetchClassrooms.data?.classrooms ?? [],
      isSaving: mutatePracticeSet.isLoading,
      groupSmartSetsCapabilities: fetchSmartSetCapabilities.data?.groups ?? [],
      subjects,
      handleSave: handleSaveSmartSet,
      handleGenerate: handleGenerateSmartSet,
      handleRestart: handleRestartSmartSet,
      generateState: smartSetState.generateState,
      defaultValue: smartSetState.defaultValue,
    };
  }, [
    fetchClassrooms.data,
    mutatePracticeSet.isLoading,
    smartSetState,
    subjects,
    handleSaveSmartSet,
    handleGenerateSmartSet,
    handleRestartSmartSet,
    fetchSmartSetCapabilities.data,
  ]);

  /** Instant Set Handlers & Data */

  const uploadFile = useUploadFile({
    onError: () => {
      setInstantSetState((s) => ({
        ...s,
        generateState: {
          isLoading: false,
          isError: true,
          isSuccess: false,
          status: GenerateInstantSetStatus.Unknown,
          lastUpdate: new Date(),
          errorMessage: t("errorUploadingFile"),
        },
      }));
    },
  });

  const handleSaveInstantSet = useCallback(() => {
    if (
      !instantSetState.generateState.practiceSet ||
      !instantSetState.defaultValue
    )
      return;

    const { subjects } = instantSetState.generateState.practiceSet;

    handleSubmit({
      ...instantSetState.generateState.practiceSet,
      id: undefined,
      collection_id: flyoutState.initialCollectionId ?? "0",
      grade_levels: [
        {
          grade_level: { id: instantSetState.defaultValue.gradeLevelId },
        },
      ],
      subjects: subjects.map((s) => ({ subject: { id: s.subject.id } })),
      practice_set_items: instantSetState.generateState.practiceSetItems,
    });
  }, [
    handleSubmit,
    instantSetState.generateState,
    instantSetState.defaultValue,
    flyoutState.initialCollectionId,
  ]);

  const clearInstantSetWebSocket = useCallback(() => {
    if (instantSetWS.current) {
      instantSetWS.current.onerror = null;
      instantSetWS.current.onclose = null;
      instantSetWS.current.onmessage = null;
      instantSetWS.current.close();
    }
  }, []);

  const generateInstantSet = useCallback(
    (
      conf: Pick<
        IInstantSetConfig,
        | "gradeLevelId"
        | "text"
        | "sourceUrl"
        | "questionTypes"
        | "questionCount"
        | "questionExtraction"
      >
    ) => {
      if (!authToken) return;

      const {
        gradeLevelId,
        sourceUrl,
        text,
        questionTypes,
        questionCount,
        questionExtraction,
      } = conf;

      setInstantSetState((s) => ({
        ...s,
        generateState: {
          ...s.generateState,
          startTime: new Date(),
        },
      }));

      const gradeLevel = gradeLevels.find((g) => g.id === gradeLevelId);

      const generateUrl = new URL(
        config.gatewayWebSocketOrigin + "/v1/practice-sets/generate"
      );

      if (instantSetWS.current) {
        clearInstantSetWebSocket();
      }

      const ws = (instantSetWS.current = new WebSocket(generateUrl, [
        "Bearer",
        authToken,
      ]));

      ws.onopen = () => {
        const payload = {
          grade_level: gradeLevel?.grade_level,
          topic: text ? text : undefined,
          source_url: text ? undefined : sourceUrl,
          question_types: questionTypes,
          min_question_count: questionCount,
          max_question_count: questionCount,
          extract_questions: questionExtraction,
        };

        ws.send(JSON.stringify(payload));
      };

      ws.onerror = () => {
        setInstantSetState((s) => ({
          ...s,
          generateState: {
            ...s.generateState,
            status: GenerateInstantSetStatus.Unknown,
            isLoading: false,
            isError: true,
            lastUpdate: new Date(),
            errorMessage: t("errorGeneratingInstantSet"),
          },
        }));
      };

      ws.onclose = () => {
        setInstantSetState((s) => {
          const isError = !s.generateState.isSuccess;

          return {
            ...s,
            generateState: {
              ...s.generateState,
              status: GenerateInstantSetStatus.Unknown,
              isLoading: false,
              isError,
              lastUpdate: new Date(),
              errorMessage: isError
                ? t("errorGeneratingInstantSet")
                : undefined,
            },
          };
        });
      };

      ws.onmessage = (ev) => {
        const message = JSON.parse(ev.data) as {
          result?: {
            status: GenerateInstantSetStatus;
            practice_set?: IPracticeSet;
            practice_set_items?: Array<IPracticeSetItem>;
          };
          error?: {
            message: string;
            http_code: number;
          };
        };

        const { result, error } = message;

        if (error) {
          setInstantSetState((s) => ({
            ...s,
            generateState: {
              ...s.generateState,
              status: GenerateInstantSetStatus.Unknown,
              isLoading: false,
              isError: true,
              errorMessage:
                error.http_code >= 400 && error.http_code < 500
                  ? error.message
                  : t("errorGeneratingInstantSet"),
              lastUpdate: new Date(),
            },
          }));

          clearInstantSetWebSocket();
          return;
        }

        if (!result) return;

        if (result.status !== GenerateInstantSetStatus.Complete) {
          setInstantSetState((s) => ({
            ...s,
            generateState: {
              ...s.generateState,
              status: result.status,
              lastUpdate: new Date(),
            },
          }));
          return;
        }

        if (result.status === GenerateInstantSetStatus.Complete) {
          setInstantSetState((s) => ({
            ...s,
            generateState: {
              ...s.generateState,
              isError: false,
              // Wait for complete status from component
              isLoading: true,
              isSuccess: true,
              status: GenerateInstantSetStatus.Complete,
              lastUpdate: new Date(),
              practiceSet: result.practice_set,
              practiceSetItems: result.practice_set_items,
            },
          }));

          clearInstantSetWebSocket();
        }
      };
    },
    [authToken, gradeLevels, clearInstantSetWebSocket, t]
  );

  // Make sure to close websocket on unmount
  useUnmount(() => {
    clearInstantSetWebSocket();
  });

  // Start generation once file is uploaded
  const prevFileData = usePrevious(uploadFile.data);
  useEffect(() => {
    if (
      !prevFileData &&
      uploadFile.data &&
      instantSetState.generateState.isLoading &&
      instantSetState.defaultValue
    ) {
      generateInstantSet({
        ...instantSetState.defaultValue,
        sourceUrl: uploadFile.data.url,
      });
    }
  }, [
    uploadFile.data,
    prevFileData,
    instantSetState.defaultValue,
    instantSetState.generateState.isLoading,
    generateInstantSet,
  ]);

  const handleGenerateInstantSetComplete = useCallback(() => {
    setInstantSetState((s) => ({
      ...s,
      generateState: {
        ...s.generateState,
        isLoading: false,
      },
    }));
  }, []);

  const handleGenerateInstantSet = useCallback(
    (conf: IInstantSetConfig) => {
      setInstantSetState({
        defaultValue: conf,
        generateState: {
          isLoading: true,
          isError: false,
          isSuccess: false,
          status: GenerateInstantSetStatus.Unknown,
        },
      });

      if (!conf.file) {
        generateInstantSet(conf);
      } else {
        uploadFile.execute({
          file: conf.file,
        });
      }
    },
    [generateInstantSet, uploadFile]
  );

  const handleRestartInstantSet = useCallback(() => {
    clearInstantSetWebSocket();

    setInstantSetState((s) => ({
      defaultValue: s.defaultValue,
      generateState: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        status: GenerateInstantSetStatus.Unknown,
      },
    }));
  }, [clearInstantSetWebSocket]);

  const handleRetryInstantSet = useCallback(() => {
    if (!instantSetState.defaultValue) return;
    handleGenerateInstantSet(instantSetState.defaultValue);
  }, [instantSetState.defaultValue, handleGenerateInstantSet]);

  const instantSet = useMemo(() => {
    return {
      generateState: instantSetState.generateState,
      gradeLevels,
      defaultValue: instantSetState.defaultValue,
      isSaving: mutatePracticeSet.isLoading,
      userGradeLevels: authUser?.teacher_grade_levels ?? [],
      handleGenerate: handleGenerateInstantSet,
      handleGenerateComplete: handleGenerateInstantSetComplete,
      handleRestart: handleRestartInstantSet,
      handleSave: handleSaveInstantSet,
      handleRetry: handleRetryInstantSet,
    };
  }, [
    instantSetState,
    mutatePracticeSet.isLoading,
    authUser,
    gradeLevels,
    handleGenerateInstantSet,
    handleRestartInstantSet,
    handleSaveInstantSet,
    handleRetryInstantSet,
    handleGenerateInstantSetComplete,
  ]);

  const flyout = (
    <CreatePracticeSetFlyout
      isOpen={flyoutState.isOpen}
      handleClose={handleClose}
      customSet={customSet}
      typeSelection={typeSelection}
      smartSet={smartSet}
      instantSet={instantSet}
      practiceSetType={practiceSetType}
    />
  );

  return {
    flyout,
    handleCreate,
  };
};
