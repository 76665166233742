import { Box } from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";

import { Avatar } from "adminComponents/atoms/Avatar";
import { pxToRem } from "adminComponents/utils";

interface IUseCircularProgressValuesArgs {
  profileImageUrl: string;
  startingPercentProgress: number;
  minPercentProgress: number;
}

interface IUseCircularProgressValuesResponse {
  onCircleRefChange: (instance: SVGCircleElement | null) => void;
  setPercentProgress: (percent: number) => void;
  circularProgressAvatar: JSX.Element;
}

export const useCircularProgressAvatar = (
  args: IUseCircularProgressValuesArgs
): IUseCircularProgressValuesResponse => {
  const { profileImageUrl, startingPercentProgress, minPercentProgress } = args;
  const [progressPercent, setProgressPercent] = useState(
    startingPercentProgress
  );
  const [circumference, setCircumference] = useState(0);
  const onCircleRefChange = useCallback((node) => {
    if (node) {
      setCircumference(node.r.baseVal.value * 2 * Math.PI);
    }
  }, []);
  useEffect(() => {
    if (circumference !== 0) {
      setProgressPercent(minPercentProgress);
    }
  }, [circumference, minPercentProgress]);

  const offset = circumference - (progressPercent / 100) * circumference;

  const _setPercentProgress = (percent: number) => {
    setProgressPercent(percent);
  };

  const circularProgressAvatar = (
    <Box p={pxToRem(20)} position="relative">
      <svg className="circular-progress" height="180" width="180">
        <circle
          ref={onCircleRefChange}
          strokeWidth="8"
          fill="transparent"
          r="66"
          cx="96"
          cy="84"
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={offset}
        />
      </svg>
      <Avatar size="2xl" src={profileImageUrl} />
    </Box>
  );

  return {
    onCircleRefChange,
    setPercentProgress: _setPercentProgress,
    circularProgressAvatar,
  };
};
