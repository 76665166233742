import { ISeasonLevel, ISessionUser } from "links/lib/types";

export interface ILevelUp {
  startXP: number;
  endXP: number;
  level?: ISeasonLevel;
  nextLevel?: ISeasonLevel;
  levelXPThreshold: number;
  nextLevelXPThreshold: number;
}

export const useLevelUps = (
  student: ISessionUser,
  seasonLevels: Array<ISeasonLevel>
): Array<ILevelUp> => {
  const levelUpItems: Array<ILevelUp> = [];
  const maxLevel = seasonLevels[seasonLevels.length - 1];

  const { session_start_xp, session_xp_total } = student;

  let currentXP = session_start_xp;
  let remainingGainedXP = session_xp_total;
  let currentLevel = seasonLevels.find((l, i) => {
    if (i === seasonLevels.length - 1) return true;

    return (
      l.xp_threshold <= currentXP &&
      seasonLevels[i + 1].xp_threshold > currentXP
    );
  });

  let nextLevel = seasonLevels.find(
    (level) => level.level === (currentLevel?.level || -1) + 1
  );

  if (session_xp_total === 0) {
    levelUpItems.push({
      startXP: session_start_xp,
      endXP: session_start_xp,
      level: currentLevel,
      levelXPThreshold: currentLevel?.xp_threshold || 0,
      nextLevel,
      nextLevelXPThreshold: nextLevel?.xp_threshold || 0,
    });

    return levelUpItems;
  }

  while (remainingGainedXP > 0) {
    // max level reached, no more items
    if (!currentLevel || currentLevel.level === maxLevel.level)
      return levelUpItems;

    const nextLevelThreshold = nextLevel?.xp_threshold || 0;
    const levelXPThreshold = currentLevel?.xp_threshold || 0;
    if (nextLevelThreshold <= currentXP + remainingGainedXP) {
      remainingGainedXP = currentXP + remainingGainedXP - nextLevelThreshold;

      levelUpItems.push({
        startXP: currentXP,
        endXP: nextLevelThreshold,
        level: currentLevel,
        nextLevel,
        nextLevelXPThreshold: nextLevelThreshold,
        levelXPThreshold,
      });

      currentXP = nextLevelThreshold;
      currentLevel = nextLevel;
      nextLevel = seasonLevels.find(
        (l) => l.level === (nextLevel?.level || -1) + 1
      );

      // Case when XP gained puts user on the border between two levels.
      // Consider the level gained
      if (remainingGainedXP === 0 && currentXP === nextLevelThreshold) {
        levelUpItems.push({
          startXP: currentXP,
          endXP: currentXP,
          level: currentLevel,
          nextLevel,
          nextLevelXPThreshold: nextLevel?.xp_threshold || 0,
          levelXPThreshold: currentXP,
        });
      }
    } else {
      levelUpItems.push({
        startXP: currentXP,
        endXP: currentXP + remainingGainedXP,
        level: currentLevel,
        nextLevel,
        nextLevelXPThreshold: nextLevelThreshold,
        levelXPThreshold,
      });

      remainingGainedXP = 0;
    }
  }

  return levelUpItems;
};
