import { Box, Portal, usePrefersReducedMotion } from "@chakra-ui/react";
import { throttle } from "lodash";
import React, { useCallback, useRef, useState } from "react";
import { animated, config, useTransition } from "react-spring";
import { useInterval, useLifecycles } from "react-use";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { useSessionEvents } from "links/lib/contexts/sessionEvents";
import { SessionReaction, SessionWebSocketEvent } from "links/lib/types";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";

import FireSrc from "./resource/fire.svg";
import HeartSrc from "./resource/heart.svg";
import SparklesSrc from "./resource/sparkles.svg";

interface IStudentReaction {
  id: number;
  user_id: string;
  reaction: SessionReaction;
  left: number;
  timestamp: number;
}

const AnimatedBox = animated(Box);

const REACTION_LIFETIME_MS = 1000;

const reactionSrcMap: { [key: string]: string } = {
  [SessionReaction.Clap]: SparklesSrc,
  [SessionReaction.Heart]: HeartSrc,
  [SessionReaction.Fire]: FireSrc,
};

export const StudentReactionsDisplay: React.FC = () => {
  const events = useSessionEvents();
  const reactionCountRef = useRef<number>(0);
  const [reactions, setReactions] = useState<Array<IStudentReaction>>([]);
  const prefersReducedMotion = usePrefersReducedMotion();
  const {
    match: { iconSize },
  } = useBreakpoints();

  const handleReaction = useRef(
    throttle(
      (sender_id: string, reaction: SessionReaction) => {
        if (!reaction) return;

        setReactions((oldReactions) => {
          reactionCountRef.current += 1;

          return oldReactions.concat({
            id: reactionCountRef.current,
            reaction: reaction,
            user_id: sender_id,
            timestamp: Date.now(),
            left: Math.max(10, Math.min(Math.random() * 100, 90)),
          });
        });
      },
      // only allow new reactions every 100 MS
      100,
      { leading: true, trailing: true }
    )
  );

  const onReaction = useCallback(
    (args: { sender_id: string; reaction?: SessionReaction }) => {
      if (!args.reaction) return;

      handleReaction.current(args.sender_id, args.reaction);
    },
    []
  );

  useLifecycles(
    () => {
      events?.on(SessionWebSocketEvent.Reaction, onReaction);
    },
    () => {
      events?.off(SessionWebSocketEvent.Reaction, onReaction);
    }
  );

  // remove expired reactions
  useInterval(
    () => {
      setReactions((oldReactions) => {
        const now = Date.now();

        return oldReactions.filter((r) => {
          return now - r.timestamp < REACTION_LIFETIME_MS;
        });
      });
    },
    reactions.length ? 500 : null
  );

  const reactionTransitions = useTransition(reactions, {
    keys: ({ id }) => id,
    from: {
      y: 0,
      opacity: 0,
    },
    // multi-stage transition
    enter: () => async (next) => {
      await next({
        opacity: 0.9,
        y: -100,
      });
      await next({
        opacity: 0,
        y: -200,
      });
    },
    leave: {
      y: -200,
      opacity: 0,
    },
    config: prefersReducedMotion ? config.slow : config.wobbly,
  });

  return (
    <Portal>
      <Box
        position="fixed"
        bottom="-1px"
        left="0"
        right="0"
        h="1px"
        bg="transparent"
        overflow="visible"
        pointerEvents="none"
      >
        {reactionTransitions((style, studentReaction) => (
          <AnimatedBox
            position="absolute"
            pointerEvents="none"
            left={studentReaction.left + "%"}
            style={style}
            boxSize={pxToRem(iconSize)}
            bgImage={reactionSrcMap[studentReaction.reaction]}
            bgRepeat="no-repeat"
            bgSize="contain"
          />
        ))}
      </Box>
    </Portal>
  );
};
