import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import { FocusableElement } from "@chakra-ui/utils";
import React, {
  ChangeEvent,
  FormEvent,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { useErrorToast } from "adminComponents/utils/toast";
import { useFetchSkillsOnce } from "links/lib/features/skills";
import { ISkill } from "links/lib/types";

import Footer from "./components/Footer";
import SearchForm from "./components/SearchForm";
import SelectedSkillsDisplay from "./components/SelectedSkillsDisplay";
import SkillsList from "./components/SkillsList";

export interface ISkillsModalProps {
  isOpen: boolean;
  onClose: () => void;
  // called when selected skills has been changed
  onChange: (skills: Array<ISkill>) => void;
  // where to create focus after modal close
  finalFocusRef?: RefObject<FocusableElement>;
  // the skills that are currently selected
  selectedSkills: Array<ISkill>;
}

export interface ISkillsModalSearchState {
  // search query text
  query: string;
}

export interface ISkillsModalState {
  search: ISkillsModalSearchState;
}

export const SkillsModal: React.FC<ISkillsModalProps> = ({
  isOpen,
  onClose,
  finalFocusRef,
  selectedSkills,
  onChange,
}) => {
  const pageLimit = 50;

  const { t } = useTranslation("translation", {
    keyPrefix: "teacherPracticeLibrary.skillsModal",
  });

  const content = {
    heading: t("heading"),
    searchForm: {
      searchQueryLabel: t("searchForm.searchQueryLabel"),
      searchQueryPlaceholder: t("searchForm.searchQueryPlaceholder"),
    },
    errors: {
      fetchSkills: t("errors.fetchSkills"),
    },
    skillsList: {
      noResultsMessage: t("skillsList.noResultsMessage"),
    },
    selectedSkillsDisplay: {
      placeholder: t("selectedSkillsDisplay.placeholder"),
    },
    footer: {
      finishButton: t("footer.finishButton"),
    },
  };

  const [state, setState] = useState<ISkillsModalState>({
    search: {
      query: "",
    },
  });
  const initialFocusRef = useRef(null);

  const fetchSkills = useFetchSkillsOnce();

  // on modal open, reset initial search values and then
  // run skill list fetch
  useEffect(
    () => {
      if (isOpen) {
        const search = {
          query: "",
        };

        setState({
          ...state,
          search,
        });

        executeSearch(search);
      }
    },
    // eslint-disable-next-line
    [isOpen]
  );

  useErrorToast(fetchSkills.error, content.errors.fetchSkills);

  const onSearchSubmit = (
    e: FormEvent<HTMLFormElement> & FormEvent<HTMLDivElement>
  ) => {
    e.preventDefault();
    e.stopPropagation();

    executeSearch(state.search);
  };

  const onQueryChange = (e: ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value || "";

    setState({
      ...state,
      search: {
        ...state.search,
        query,
      },
    });
  };

  const executeSearch = (search: ISkillsModalSearchState) => {
    fetchSkills.execute({
      ...search,
      limit: pageLimit,
      offset: 0,
    });
  };

  const onSkillSelect = (skill: ISkill, isSelected: boolean) => {
    // clean current skills list to ensure current skill is not present
    const newSkills = selectedSkills.filter((s) => s.id !== skill.id);

    // if selected, then add to new selected skills
    if (isSelected) {
      newSkills.push(skill);
    }

    onChange(newSkills);
  };

  return (
    <Modal
      size="xl"
      isOpen={isOpen}
      onClose={onClose}
      finalFocusRef={finalFocusRef}
      initialFocusRef={initialFocusRef}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{content.heading}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack w="full" spacing={6}>
            <SearchForm
              initialFocusRef={initialFocusRef}
              values={{
                query: state.search.query,
              }}
              onQueryChange={onQueryChange}
              onSearchSubmit={onSearchSubmit}
              content={content.searchForm}
            />
            <SkillsList
              onSkillSelect={onSkillSelect}
              isLoading={fetchSkills.isLoading}
              skills={fetchSkills.data?.skills || []}
              selectedSkills={selectedSkills}
              content={content.skillsList}
            />
            <SelectedSkillsDisplay
              onSkillSelected={onSkillSelect}
              selectedSkills={selectedSkills}
              content={content.selectedSkillsDisplay}
            />
            <Footer content={content.footer} onFinish={onClose} />
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
