import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  DrawerProps,
} from "@chakra-ui/react";
import React from "react";

export const StudentNavigationDrawer: React.FC<DrawerProps> = ({
  children,
  isOpen,
  onClose,
  ...props
}) => {
  return (
    <Drawer
      size="full"
      isOpen={isOpen}
      onClose={onClose}
      placement="left"
      variant="adminNavigation"
      {...props}
    >
      <DrawerOverlay />
      <DrawerContent h="full">
        <DrawerBody p="0">{children}</DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
