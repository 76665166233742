import { Box, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { animated, config, useSpring } from "react-spring";
import { useInterval } from "react-use";

import { pxToRem } from "adminComponents/utils";
import { isUserTypingInterval } from "links/lib/constants";
import { useInitials } from "links/lib/hooks/useInitials";
import { IPosition } from "links/lib/types";

import { ReactComponent as CursorSVG } from "./resource/cursor.svg";

const AnimatedBox = animated(Box);

export interface ICursorProps {
  name: string;
  lastActiveTyping?: Date;
  position: IPosition;
  primaryColor: string;
  labelComponent?: React.ReactElement;
}

const hasTypedWithinInterval = (lastActiveTyping: Date) =>
  Date.now() - lastActiveTyping.valueOf() < isUserTypingInterval;

export const Cursor: React.FC<ICursorProps> = ({
  primaryColor,
  name,
  lastActiveTyping,
  position,
  labelComponent,
}) => {
  const userInitials = useInitials(name);

  const [isUserTyping, setIsUserTyping] = useState<boolean>(false);

  useEffect(() => {
    if (lastActiveTyping) {
      setIsUserTyping(hasTypedWithinInterval(lastActiveTyping));
    }
  }, [lastActiveTyping]);

  useInterval(
    () => {
      if (lastActiveTyping) {
        setIsUserTyping(hasTypedWithinInterval(lastActiveTyping));
      }
    },
    // setting delay to null pauses interval
    isUserTyping ? isUserTypingInterval / 2 : null
  );

  const cursorMove = useSpring({
    top: position.y,
    left: position.x,
    config: config.default,
  });

  // Hide the cursor of actively typing students
  if (isUserTyping) {
    return <></>;
  }

  return (
    <AnimatedBox
      style={{
        top: cursorMove.top,
        left: cursorMove.left,
      }}
      position="absolute"
      pointerEvents="none"
    >
      <Box color={primaryColor}>
        <CursorSVG />
      </Box>
      <Box
        borderRadius="full"
        borderWidth="3px"
        borderColor="primary.white"
        py={pxToRem(2)}
        px={pxToRem(16)}
        bg={primaryColor}
        display="inline-block"
        position="absolute"
        left="8px"
        bottom="-18px"
      >
        <Text
          textStyle="gameText"
          color={
            primaryColor === "amber.base" || primaryColor === "black"
              ? "primary.warm-white"
              : "primary.warm-black"
          }
          fontSize={pxToRem(16)}
        >
          {userInitials}
        </Text>
      </Box>
      {labelComponent && labelComponent}
    </AnimatedBox>
  );
};
