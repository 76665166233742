import { GoogleClassroomClassroom, IUser } from "links/lib/types";

// Returns a list of students who are in GiantSteps Classroom and not GClassroom
export const getExcludedStudents = ({
  gClassroom,
  classroomStudents,
}: {
  gClassroom?: GoogleClassroomClassroom;
  classroomStudents?: IUser[];
}): IUser[] => {
  const gClassroomStudentsMap = new Map<string, boolean>();
  if (!gClassroom || !classroomStudents) return [];

  gClassroom.students.forEach((student) =>
    gClassroomStudentsMap.set(student.email, true)
  );

  const gsStudentsNotInGoogle: IUser[] = [];

  classroomStudents.forEach((student) => {
    if (!gClassroomStudentsMap.has(student.email)) {
      gsStudentsNotInGoogle.push(student);
    }
  });

  return gsStudentsNotInGoogle;
};
