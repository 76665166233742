import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { IFetchAvatarResponse } from "../../types";

export interface IDequipAvatarItemArgs {
  user_id: string;
  avatar_item_category_id: string;
}

/**
 * Dequips an avatar item
 * @returns
 */
export default function useDeequipAvatarItem(): UseMutationResult<
  IFetchAvatarResponse,
  unknown,
  IDequipAvatarItemArgs
> {
  const axios = useAxios();
  const queryClient = useQueryClient();

  const mutateAvatar = async (args: IDequipAvatarItemArgs) => {
    const { avatar_item_category_id, user_id } = args;

    const response = await axios({
      method: "post",
      url: `/v1/avatar/${user_id}/items/deequip`,
      data: {
        avatar_item_category_id,
      },
    }).then((r) => r.data as IFetchAvatarResponse);

    queryClient.invalidateQueries("avatar", { exact: true });

    return response;
  };

  const mutation = useMutation(mutateAvatar);

  return mutation;
}
