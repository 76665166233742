import { Box, Flex, FormControl, SimpleGrid } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { FormLabel } from "adminComponents/atoms/FormLabel";
import { Modal } from "adminComponents/atoms/Modal";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { GoogleClassroomClassroom } from "links/lib/types";

interface GoogleClassroomPickerModalProps {
  isOpen: boolean;
  isLoading: boolean;
  googleClassrooms: GoogleClassroomClassroom[];
  handlePickGoogleClassroom: (classroom: GoogleClassroomClassroom) => void;
  handleClose: () => void;
}

export const GoogleClassroomPickerModal: React.FC<
  GoogleClassroomPickerModalProps
> = ({
  isOpen,
  isLoading,
  googleClassrooms,
  handleClose,
  handlePickGoogleClassroom,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  return (
    <Modal
      title={t("addStudentsFromGoogleClassroomModal.title")}
      onClose={handleClose}
      isOpen={isOpen}
      size="2xl"
      showBackButton={false}
    >
      <Box mb={pxToRem(40)}>
        <FormControl>
          <FormLabel variant="adminFormLabelDarkGray">
            {t("addStudentsFromGoogleClassroomModal.classrooms")}
          </FormLabel>
          <SimpleGrid
            gap={[pxToRem(24), null, pxToRem(16)]}
            columns={[1, null, 2]}
          >
            {googleClassrooms.map((googleClassroom) => {
              const subheadingParts = [
                googleClassroom.section,
                googleClassroom.room,
                googleClassroom.description,
              ].filter((part) => !!part);

              return (
                <Button
                  key={googleClassroom.name}
                  variant="adminGoogleClassroomPickButton"
                  onClick={() => handlePickGoogleClassroom(googleClassroom)}
                >
                  <Text
                    variant="adminP1"
                    mb={pxToRem(4)}
                    overflow="hidden"
                    textOverflow="ellipsis"
                  >
                    {googleClassroom.name}
                  </Text>
                  <Text
                    variant="adminP2"
                    color="primary.dark-gray"
                    overflow="hidden"
                    textOverflow="ellipsis"
                  >
                    {subheadingParts.join(" • ")}
                  </Text>
                </Button>
              );
            })}
          </SimpleGrid>
        </FormControl>
      </Box>
      <Flex flexDir={["column", null, "row"]} gap={pxToRem(12)}>
        <Button
          size="lg"
          variant="adminButtonFilled"
          isDisabled
          isLoading={isLoading}
        >
          {t("addStudentsFromGoogleClassroomModal.addStudents")}
        </Button>
        <Button
          size="lg"
          variant="adminButtonOutlined"
          onClick={handleClose}
          isLoading={isLoading}
        >
          {t("common.cancel")}
        </Button>
      </Flex>
    </Modal>
  );
};
