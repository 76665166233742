import { Flex, SimpleGrid, VStack, useDisclosure } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Heading } from "adminComponents/atoms/Heading";
import { Icon } from "adminComponents/atoms/Icon";
import { EmptyCard } from "adminComponents/molecules/EmptyCard";
import { LibraryPracticeSetCard } from "adminComponents/molecules/LibraryPracticeSetCard";
import {
  INavigationHeaderProps,
  NavigationHeader,
} from "adminComponents/molecules/NavigationHeader";
import { SearchInput } from "adminComponents/molecules/SearchInput";
import { TeacherProfileCard } from "adminComponents/molecules/TeacherProfileCard";
import { SocialMediaShareModal } from "adminComponents/organisms/SocialMediaShareModal";
import { TemplateWithCenteredHeroOneColumn } from "adminComponents/templates/TemplateWithCenteredHeroOneColumn";
import { generateTeacherName, pxToRem } from "adminComponents/utils";
import { useAnalytics } from "lib/contexts/analytics";
import { usePageTitle } from "lib/hooks/usePageTitle";
import { shareToFacebook, shareToLinkedIn, shareToX } from "lib/shareUtils";
import { config } from "links/lib/constants";
import { useAuth } from "links/lib/features/auth";
import {
  AnalyticsEvent,
  IPracticeSet,
  IUser,
  SocialMediaShareProvider,
  UserPublicProfileStatistics,
} from "links/lib/types";

export interface ITeacherProfileScreenProps {
  user?: IUser;
  statistics?: UserPublicProfileStatistics;
  practiceSets?: Array<IPracticeSet>;
  practiceSetTotalCount: number;
  showViewMorePracticeSets: boolean;
  navigationData: INavigationHeaderProps;
  isLoading?: boolean;
  isFollowing?: boolean;
  isSearchLoading?: boolean;
  initialSearchTerm: string;
  hidePracticeSetCardMenus?: boolean;
  handleClearSearchInput: () => void;
  handleSearch: (term: string) => void;
  handleOpenFollowersModal: () => void;
  handleOpenFollowingModal: () => void;
  handleViewMorePracticeSets?: () => void;
  handleClickPracticeSetCard?: React.MouseEventHandler;
  handleFollowUser?: () => void;
  handleUnfollowUser?: () => void;
}

export const TeacherProfileScreen: React.FC<ITeacherProfileScreenProps> = ({
  user,
  statistics,
  practiceSets,
  practiceSetTotalCount,
  showViewMorePracticeSets,
  navigationData,
  initialSearchTerm,
  isLoading,
  isSearchLoading,
  isFollowing,
  children,
  hidePracticeSetCardMenus = false,
  handleClearSearchInput,
  handleSearch,
  handleViewMorePracticeSets,
  handleClickPracticeSetCard,
  handleOpenFollowersModal,
  handleOpenFollowingModal,
  handleFollowUser,
  handleUnfollowUser,
}) => {
  const { t } = useTranslation("admin", {
    keyPrefix: "teacherProfile",
    useSuspense: false,
  });
  const { t: tCommon } = useTranslation("admin", {
    keyPrefix: "common",
    useSuspense: false,
  });
  const { authUser } = useAuth();
  const { trackEvent } = useAnalytics();

  const name = generateTeacherName(user || {}).primary;

  usePageTitle(name);

  const shareUrl = `${config.baseUrl}/profiles/${user?.custom_url_code}`;

  const {
    isOpen: isSocialMediaShareModalOpen,
    onOpen: onOpenSocialMediaShareModal,
    onClose: onCloseSocialMediaShareModal,
  } = useDisclosure();

  const handleShare = (provider: SocialMediaShareProvider) => {
    if (provider === SocialMediaShareProvider.X) {
      shareToX(shareUrl, ["PlayPearPractice"]);
    } else if (provider === SocialMediaShareProvider.LinkedIn) {
      shareToLinkedIn(shareUrl);
    } else if (provider === SocialMediaShareProvider.Facebook) {
      shareToFacebook(shareUrl);
    }

    trackEvent(
      AnalyticsEvent.TeacherDashboard_TeacherProfile_ShareModal_Share,
      {
        provider,
      }
    );
  };

  const noPracticeSetsFound = practiceSetTotalCount === 0;
  const noSharedPracticeSets = initialSearchTerm === "" && noPracticeSetsFound;
  const noMatchingPracticeSets =
    initialSearchTerm !== "" && noPracticeSetsFound;

  return (
    <TemplateWithCenteredHeroOneColumn
      nav={<NavigationHeader {...navigationData} isLoading={isLoading} />}
      heroContent={
        user && (
          <TeacherProfileCard
            user={user}
            statistics={statistics}
            authUser={authUser}
            gradeLevels={navigationData.gradeLevels}
            subjects={navigationData.subjects}
            showSocialMediaShare={!!authUser}
            isFollowing={isFollowing}
            onOpenSocialMediaShareModal={onOpenSocialMediaShareModal}
            onOpenFollowersModal={handleOpenFollowersModal}
            onOpenFollowingModal={handleOpenFollowingModal}
            onFollowUser={handleFollowUser}
            onUnfollowUser={handleUnfollowUser}
          />
        )
      }
      isLoading={isLoading}
      variant="default"
    >
      <VStack spacing={pxToRem(24)} alignItems="start" w="full">
        {noSharedPracticeSets ? (
          <EmptyCard text={t("noSharedText")} title={t("noSharedTitle")} />
        ) : (
          <>
            <Heading as="h2" variant="adminH3">
              {t("practiceSets")}
            </Heading>

            <SearchInput
              handleClear={handleClearSearchInput}
              handleSearch={handleSearch}
              initialSearch={initialSearchTerm}
              searching={isSearchLoading}
            />

            {noMatchingPracticeSets ? (
              <EmptyCard
                text={t("noMatchingText")}
                title={t("noMatchingTitle")}
              />
            ) : (
              <Heading
                as="p"
                color="primary.warm-black"
                mb={pxToRem(24)}
                variant="adminH6"
              >
                {tCommon(initialSearchTerm ? "resultCountFor" : "resultCount", {
                  count: practiceSetTotalCount,
                  countFormatted: practiceSetTotalCount.toLocaleString(
                    authUser?.language
                  ),
                  searchTerm: initialSearchTerm,
                })}
              </Heading>
            )}
          </>
        )}

        <SimpleGrid
          columns={{ base: 1, sm: 2, md: 3, lg: 4 }}
          spacing={pxToRem(10)}
        >
          {practiceSets &&
            practiceSets.map((practiceSet) => {
              return (
                <LibraryPracticeSetCard
                  authorName={name}
                  key={`practiceSet-${practiceSet.id}`}
                  practiceSet={practiceSet}
                  showPremiumMarker={practiceSet.is_premium}
                  authUser={authUser}
                  handleClick={handleClickPracticeSetCard}
                  hideMenu={hidePracticeSetCardMenus}
                />
              );
            })}
        </SimpleGrid>
      </VStack>

      {showViewMorePracticeSets && (
        <Flex
          justifyContent="center"
          mb={{ base: pxToRem(24), md: pxToRem(32) }}
          mt={{ base: pxToRem(12), md: pxToRem(24) }}
        >
          <Button
            onClick={handleViewMorePracticeSets}
            rightIcon={
              <Icon
                color="currentColor"
                height={pxToRem(7)}
                icon="button_down_arrow"
                ml={pxToRem(5)}
                width={pxToRem(12)}
              />
            }
            variant="adminTextButtonMedium"
          >
            {t("viewMore")}
          </Button>
        </Flex>
      )}

      {children}

      <SocialMediaShareModal
        title={t("shareTeacherProfile")}
        isOpen={isSocialMediaShareModalOpen}
        shareUrl={shareUrl}
        handleClose={onCloseSocialMediaShareModal}
        handleShare={handleShare}
      />
    </TemplateWithCenteredHeroOneColumn>
  );
};
