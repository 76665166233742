import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { useAxios } from "../../hooks/useAxios";

export interface IRemoveOrganizationUserProps {
  onError?: () => void;
  onSettled?: () => void;
  onSuccess?: () => void;
}

export interface IRemoveOrganizationUserParams {
  user_id: string;
}

// eslint-disable-next-line
export interface IRemoveOrganizationUserResponse {}

export default function useRemoveOrganizationUser(
  props: IRemoveOrganizationUserProps
): UseMutationResult<
  IRemoveOrganizationUserResponse,
  unknown,
  IRemoveOrganizationUserParams
> {
  const axios = useAxios();
  const queryClient = useQueryClient();

  const RemoveOrganizationUser = async (
    args: IRemoveOrganizationUserParams
  ) => {
    const { user_id } = args;

    const response = await axios({
      method: "delete",
      url: `/v1/organizations/users/${user_id}`,
    }).then((r) => r.data as IRemoveOrganizationUserResponse);

    queryClient.invalidateQueries(["organizationUsers"]);
    queryClient.invalidateQueries([
      "top-organization-session-creators",
      { exact: false },
    ]);
    queryClient.invalidateQueries([
      "top-organization-assignment-creators",
      { exact: false },
    ]);
    queryClient.invalidateQueries([
      "educator-with-group-details-bulk",
      { exact: false },
    ]);

    return response;
  };

  const mutation = useMutation(RemoveOrganizationUser, {
    onError: props.onError,
    onSettled: props.onSettled,
    onSuccess: props.onSuccess,
  });

  return mutation;
}
