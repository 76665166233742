import { useCallback, useState } from "react";
import { useLifecycles } from "react-use";

export interface IResult {
  handleSpeak: (text: string) => void;
  handleCancelSpeak: () => void;
  isSpeaking: () => boolean;
  isSpeechEnabled: boolean;
}

const supportsSynthesis = "speechSynthesis" in window;

export const useTextReader = (): IResult => {
  const [hasVoices, setHasVoices] = useState(false);

  const isSpeechEnabled = supportsSynthesis && hasVoices;

  const isSpeaking = useCallback(() => {
    return supportsSynthesis && speechSynthesis.speaking;
  }, []);

  useLifecycles(
    () => {
      if (!supportsSynthesis) {
        return;
      }

      const voices = speechSynthesis.getVoices();
      setHasVoices(voices.length > 0);

      speechSynthesis.onvoiceschanged = () => {
        const voices = speechSynthesis.getVoices();
        setHasVoices(voices.length > 0);
      };
    },
    () => {
      if (!supportsSynthesis) {
        return;
      }

      speechSynthesis.cancel();
    }
  );

  const handleSpeak = useCallback(
    (text: string) => {
      if (!isSpeechEnabled) return;

      if (speechSynthesis.speaking) {
        speechSynthesis.cancel();
        return;
      }

      const utterance = new SpeechSynthesisUtterance(text);
      speechSynthesis.cancel();
      speechSynthesis.speak(utterance);
    },
    [isSpeechEnabled]
  );

  const handleCancelSpeak = useCallback(() => {
    if (!supportsSynthesis) return;

    speechSynthesis.cancel();
  }, []);

  return {
    handleCancelSpeak,
    handleSpeak,
    isSpeaking,
    isSpeechEnabled,
  };
};
