import {
  Modal as ChakraModal,
  Hide,
  ModalBody,
  ModalBodyProps,
  ModalContent,
  ModalContentProps,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Show,
  useBreakpointValue,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Heading, IHeadingProps } from "adminComponents/atoms/Heading";
import {
  IconCTAButtonWrapper as CloseButtonWrapper,
  IconCTAButton,
} from "adminComponents/atoms/IconCTAButton";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils/pxToRem";

interface IProps extends ModalProps {
  title?: string;
  hideClose?: boolean;
  showBackButton?: boolean;
  showBackButtonAllSizes?: boolean;
  headingProps?: IHeadingProps;
  modalContentProps?: ModalContentProps;
  modalBodyProps?: ModalBodyProps;
  isCentered?: boolean;
  onBack?: () => void;
}

export const Modal: React.FC<IProps> = ({
  title,
  children,
  variant = "adminModal",
  size = "xl",
  hideClose = false,
  showBackButton = true,
  showBackButtonAllSizes = false,
  headingProps = { variant: "adminH5", as: "h2" },
  modalContentProps,
  isCentered,
  isOpen,
  modalBodyProps,
  onClose,
  onBack,
}) => {
  const isCompactMobile = variant === "adminModalCompactMobile";
  const { t } = useTranslation("admin", { useSuspense: false });
  const breakPointSize = useBreakpointValue({
    base: isCompactMobile ? "sm" : "full",
    md: size,
  });

  const backButton = (
    <CloseButtonWrapper top={pxToRem(24)} left={pxToRem(15)}>
      <IconCTAButton
        boxSize={24}
        icon="keyboard_arrow_left"
        iconSize={20}
        marginRight={pxToRem(5)}
        onClick={onBack || onClose}
        ariaLabel={t("common.back")}
      />
      <Text variant="adminP3">{t("common.back")}</Text>
    </CloseButtonWrapper>
  );

  return (
    <ChakraModal
      size={breakPointSize}
      variant={variant}
      onClose={onClose}
      isOpen={isOpen}
      isCentered={isCentered}
    >
      <ModalOverlay />
      <ModalContent {...modalContentProps}>
        {title && (
          <ModalHeader>
            <Heading {...headingProps}>{title}</Heading>
          </ModalHeader>
        )}
        {!hideClose && (
          <Show above={showBackButton ? "md" : undefined}>
            <CloseButtonWrapper
              top={
                isCompactMobile ? pxToRem(24) : [pxToRem(20), null, pxToRem(24)]
              }
              right={
                isCompactMobile ? pxToRem(24) : [pxToRem(8), null, pxToRem(24)]
              }
              left="unset"
            >
              <IconCTAButton onClick={onClose} ariaLabel={t("common.close")} />
            </CloseButtonWrapper>
          </Show>
        )}
        {(showBackButton || showBackButtonAllSizes) && (
          <>
            {showBackButtonAllSizes ? (
              backButton
            ) : (
              <Hide below="md">{backButton}</Hide>
            )}
          </>
        )}
        <ModalBody {...modalBodyProps}>{children}</ModalBody>
      </ModalContent>
    </ChakraModal>
  );
};
