import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMount, useSessionStorage } from "react-use";

import { GoogleClassroomAuthorizeModal } from "adminComponents/organisms/GoogleClassroomAuthorizeModal";
import { afterSignInPathSessionKey } from "lib/constants";
import { useFetchThirdPartyAssignmentSubmission } from "links/lib/features/assignments";
import { useFetchSessions } from "links/lib/features/sessions";
import {
  IAssignment,
  IPendingSession,
  IPracticeSetSession,
  ThirdParty,
  UserRole,
} from "links/lib/types";
import useFetchProviderRedirectUrl from "screens/SignIn/useFetchProviderRedirectUrl";
import { usePlaySession } from "screens/StudentDashboard/contexts/PlaySessionProvider";

export interface IUseStartAssignmentArgs {
  assignments?: IAssignment[];
  handleGrantAccess?: () => void;
  handleStartAssignment?: (assignment: IAssignment) => void;
}

export interface IUseStartAssignmentResponse {
  startAssignment: (assignment: IAssignment) => void;
  authorizeModal: React.ReactElement;
  pendingAssignment?: IPendingSession;
}

const LIVE_SESSION_REFETCH_INTERVAL = 10000;

export const useStartAssignment = ({
  assignments,
  handleGrantAccess,
  handleStartAssignment,
}: IUseStartAssignmentArgs): IUseStartAssignmentResponse => {
  const [isAuthorizeModalOpen, setIsAuthorizeModalOpen] =
    useState<boolean>(false);
  const [checkedAuth, setCheckedAuth] = useState<boolean>(false);
  const { t } = useTranslation("admin", {
    keyPrefix: "assignment",
    useSuspense: false,
  });
  const [currentAssignment, setCurrentAssignment] = useState<IAssignment>();
  const { handlePlaySession, handleSetRedirectToSeasonMap, pendingSession } =
    usePlaySession();
  useMount(() => {
    handleSetRedirectToSeasonMap(false);
  });

  const redirectFetch = useFetchProviderRedirectUrl();
  const [, setSessionRedirectPath] = useSessionStorage(
    afterSignInPathSessionKey,
    "/"
  );

  useEffect(() => {
    if (
      assignments &&
      assignments.length > 0 &&
      assignments.some(
        (assignment) => assignment.third_party === ThirdParty.GOOGLE_CLASSROOM
      )
    ) {
      if (!redirectFetch.data && !redirectFetch.isLoading) {
        redirectFetch.execute({
          provider: "google_classroom",
          role: UserRole.Student,
        });
      }
    } else if (assignments && assignments.length > 0) {
      setCheckedAuth(true);
    }
  }, [assignments, redirectFetch]);

  // Just need a google classroom assignment to check if user is authed, so we can hit google classroom api
  // on their behalf when turning assignment in
  const aRandomGoogleClassroomAssignment = useMemo(() => {
    return assignments?.find(
      (assignment) =>
        assignment.third_party === ThirdParty.GOOGLE_CLASSROOM &&
        assignment.third_party_assignment_id &&
        assignment.third_party_group_id
    );
  }, [assignments]);

  const assignmentSubmisisonFetch = useFetchThirdPartyAssignmentSubmission({
    assignmentId: aRandomGoogleClassroomAssignment?.id || "",
    enabled: !!aRandomGoogleClassroomAssignment,
  });

  const activeSessionStartTime = useMemo(
    () => moment().startOf("minute").subtract(24, "h").toDate(),
    []
  );

  const fetchSessions = useFetchSessions({
    incomplete_sessions_only: true,
    start_time: activeSessionStartTime,
    group_id: "",
    keepPreviousData: true,
    refetchInterval: LIVE_SESSION_REFETCH_INTERVAL,
  });

  const liveSessionMap: { [key: string]: IPracticeSetSession } = useMemo(() => {
    const map: { [key: string]: IPracticeSetSession } = {};
    fetchSessions.data?.sessions.forEach((s) => {
      map[s.id] = s;
    });

    return map;
  }, [fetchSessions.data?.sessions]);

  const _handleGrantAccess = useCallback(() => {
    if (!redirectFetch.data) return;

    handleGrantAccess?.();

    setSessionRedirectPath(window.location.pathname);
    window.location.href = redirectFetch.data.redirectUrl;
  }, [redirectFetch, setSessionRedirectPath, handleGrantAccess]);

  const handleAuthorizeClose = useCallback(() => {
    setIsAuthorizeModalOpen(false);
    setCheckedAuth(true);
  }, []);

  const authorizeModal = useMemo(() => {
    return (
      <GoogleClassroomAuthorizeModal
        isOpen={isAuthorizeModalOpen}
        isLoading={redirectFetch.isLoading}
        handleGrantAccess={_handleGrantAccess}
        handleClose={handleAuthorizeClose}
        heading={t("authWithGClassroom")}
        explanation={t("authExplainer")}
        secondaryExplanation={t("authExplainerSecondary")}
      />
    );
  }, [
    isAuthorizeModalOpen,
    handleAuthorizeClose,
    _handleGrantAccess,
    redirectFetch.isLoading,
    t,
  ]);

  const startAssignment = useCallback((assignment: IAssignment) => {
    if (assignment.third_party !== ThirdParty.GOOGLE_CLASSROOM) {
      setCheckedAuth(true);
    } else if (
      assignment.third_party === ThirdParty.GOOGLE_CLASSROOM &&
      (!assignment.third_party_assignment_id ||
        !assignment.third_party_group_id)
    ) {
      // should not end up in this case but if we do, don't prevent assignment from starting
      setCheckedAuth(true);
    }

    setCurrentAssignment(assignment);
  }, []);

  const startSession = useCallback(() => {
    if (checkedAuth && currentAssignment && !pendingSession) {
      handlePlaySession({
        assignment: currentAssignment,
        practiceSetId: currentAssignment.practice_set_id,
        classroomId: currentAssignment.group_id,
        existingSessionJoinCode: Object.values(liveSessionMap).find(
          (session) => session.assignment_id === currentAssignment.id
        )?.join_code,
      });

      handleStartAssignment?.(currentAssignment);

      setCheckedAuth(false);
      setCurrentAssignment(undefined);
    }
  }, [
    checkedAuth,
    handlePlaySession,
    liveSessionMap,
    pendingSession,
    currentAssignment,
    handleStartAssignment,
  ]);

  useEffect(() => {
    if (!currentAssignment) {
      return;
    }

    if (
      !checkedAuth &&
      currentAssignment.third_party === ThirdParty.GOOGLE_CLASSROOM &&
      assignmentSubmisisonFetch.data &&
      assignmentSubmisisonFetch.data.requires_authorization
    ) {
      setIsAuthorizeModalOpen(true);
      return;
    } else if (
      !checkedAuth &&
      currentAssignment.third_party === ThirdParty.GOOGLE_CLASSROOM &&
      assignmentSubmisisonFetch.data &&
      !assignmentSubmisisonFetch.data.requires_authorization
    ) {
      setCheckedAuth(true);
    }

    if (checkedAuth) {
      startSession();
    }
  }, [currentAssignment, checkedAuth, assignmentSubmisisonFetch, startSession]);

  return useMemo(() => {
    return {
      startAssignment,
      authorizeModal,
      pendingAssignment: pendingSession,
    };
  }, [startAssignment, authorizeModal, pendingSession]);
};
