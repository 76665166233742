import { Box } from "@chakra-ui/react";
import React, { useMemo } from "react";

import { pxToRem } from "adminComponents/utils";
import { useSessionConnectedUsers } from "links/lib/contexts/sessionConnectedUsers";
import { useSessionRoundState } from "links/lib/contexts/sessionRoundState";
import { CoopTextMatchVariantData } from "links/lib/types";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { TeacherReviewOption } from "sessionComponents/molecules/TeacherReviewOption";
import { getCorrectChoiceIds } from "sessionComponents/utils/getCorrectChoiceIds";
import { useSafeRoundGroupStateArray } from "sharedComponents/hooks/useSafeRoundGroupStateArray";

interface ITeacherTextMatchReviewOptionsProps {
  studentTotal: number;
}

type TextCount = {
  text: string;
  count: number;
  isCorrect: boolean;
  isDefaultCorrectAnswer: boolean;
};

export const TeacherTextMatchReviewOptions: React.FC<
  ITeacherTextMatchReviewOptionsProps
> = ({ studentTotal }) => {
  const {
    match: { padding },
  } = useBreakpoints();
  const roundState = useSessionRoundState();
  const roundGroupsState = useSafeRoundGroupStateArray();
  const connectedUsers = useSessionConnectedUsers();
  const { variant, variant_data, practice_set_session_item } = roundState;
  const textMatchVariantData = variant_data as CoopTextMatchVariantData;
  const correctChoiceText = getCorrectChoiceIds(variant, variant_data);

  const textCounts: TextCount[] = useMemo(() => {
    const choiceTextCounts: { [key: string]: number | undefined } = {};

    const studentChoices: Array<{
      text: string;
      isCorrect: boolean;
      isPartiallyCorrect: boolean;
    }> =
      roundGroupsState.flatMap((roundGroupState) => {
        return (
          roundGroupState.text_match?.selected_choices
            .filter(
              (selectedChoice) => !!connectedUsers[selectedChoice.user_id]
            )
            .map((selectedChoice) => {
              const choice = roundGroupState.text_match?.choices.find(
                (choice) => choice.id === selectedChoice.choice_id
              );
              const choiceText = choice?.text.toLocaleLowerCase() || "";

              choiceTextCounts[choiceText] =
                (choiceTextCounts[choiceText] || 0) + 1;

              return {
                text: choiceText,
                isCorrect: selectedChoice.is_correct,
                isPartiallyCorrect: selectedChoice.is_partially_correct,
              };
            }) || []
        );
      }) || [];

    return Object.keys(choiceTextCounts).map((choiceText) => {
      return {
        text: choiceText,
        count: choiceTextCounts[choiceText] || 0,
        isCorrect:
          studentChoices.find(
            (studentChoice) =>
              studentChoice.text.localeCompare(choiceText) === 0
          )?.isCorrect || false,
        isDefaultCorrectAnswer:
          textMatchVariantData.coop_text_match.correct_text?.toLowerCase() ===
          choiceText,
      };
    });
  }, [
    connectedUsers,
    roundGroupsState,
    textMatchVariantData.coop_text_match.correct_text,
  ]);

  // sort textCounts to put correct choice first
  const sortedTextCounts = useMemo(() => {
    const hasCorrectDefaultAnswer = textCounts.some(
      (textCt) => textCt.isCorrect && textCt.isDefaultCorrectAnswer
    );
    if (!hasCorrectDefaultAnswer) {
      textCounts.push({
        text: correctChoiceText[0],
        count: 0,
        isCorrect: true,
        isDefaultCorrectAnswer: true,
      });
    }

    const correctTextCounts = textCounts
      .filter((textCt) => textCt.isCorrect)
      .sort((a, b) => {
        if (a.isDefaultCorrectAnswer) return -1;
        return a.count > b.count ? -1 : a.count > b.count ? 1 : 0;
      });

    const incorrectTextCounts = textCounts
      .filter((textCt) => !textCt.isCorrect)
      .sort((a, b) => {
        return a.count > b.count ? -1 : a.count > b.count ? 1 : 0;
      });

    return [...correctTextCounts, ...incorrectTextCounts];
  }, [textCounts, correctChoiceText]);

  const maxAnswerCount = useMemo(() => {
    return Math.max(...sortedTextCounts.map((a) => a.count));
  }, [sortedTextCounts]);

  return (
    <Box paddingBottom={pxToRem(padding)}>
      {sortedTextCounts.map((option, index) => {
        return (
          <TeacherReviewOption
            key={option.text}
            text={option.text}
            isCorrect={option.isCorrect}
            variant={variant}
            studentTotal={studentTotal}
            answerCount={option.count}
            maxAnswerCount={maxAnswerCount}
            prefix={practice_set_session_item.number_response?.prefix}
            suffix={practice_set_session_item.number_response?.suffix}
            showTopMargin={index > 0}
          />
        );
      })}
    </Box>
  );
};
