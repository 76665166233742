import { pxToRem } from "adminComponents/utils/pxToRem";

export const AdminAvatarTypeSelector = {
  baseStyle: {
    container: {
      height: "100%",
      gap: {
        base: pxToRem(12),
      },
      flexDirection: {
        base: "row",
      },
    },
    card: {
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      padding: {
        base: pxToRem(16),
      },
      position: "relative",
      width: {
        base: "100%",
      },
      height: {
        base: "initial",
      },
    },
    avatar: {
      maxWidth: "100%",
      maxHeight: "100%",
    },
  },
};
