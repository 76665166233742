import { Box, useMultiStyleConfig } from "@chakra-ui/react";
import React from "react";

import { CalendarDate } from "adminComponents/atoms/CalendarDate";
import { Tag } from "adminComponents/atoms/Tag";

export interface IProps {
  date: string;
  isDueToday: boolean;
}

export const AssignmentDueDate: React.FC<IProps> = ({ date, isDueToday }) => {
  const styles = useMultiStyleConfig("AdminStudentAssignmentCard", {});
  return (
    <Box sx={styles.date}>
      {!isDueToday ? (
        <CalendarDate date={date} dateType="due" />
      ) : (
        <Tag colorScheme="orange">
          <CalendarDate date={date} dateType="today" />
        </Tag>
      )}
    </Box>
  );
};
