import { useMemo } from "react";

import { useErrorToast } from "adminComponents/utils/toast";
import { useAuth } from "links/lib/features/auth";
import {
  useFetchCollection,
  useFetchCollections,
} from "links/lib/features/collections";
import { useFetchGradeLevels } from "links/lib/features/gradeLevels";
import { useFetchPracticeSetItems } from "links/lib/features/practiceSetItems";
import { useFetchPracticeSet } from "links/lib/features/practiceSets";
import { useFetchSubjects } from "links/lib/features/subjects";
import {
  ICollection,
  IGradeLevel,
  IPracticeSet,
  IPracticeSetItem,
  ISubject,
} from "links/lib/types";

interface IResult {
  practiceSet?: IPracticeSet;
  items: Array<IPracticeSetItem>;
  collections: Array<ICollection>;
  collection?: ICollection;
  subjects: Array<ISubject>;
  gradeLevels: Array<IGradeLevel>;
  isPracticeSetAndItemsReady: boolean;
  isPracticeSetNotFound: boolean;
}

const usePracticeSetDetailData = (practiceSetId: string): IResult => {
  const { authUser } = useAuth();

  // fetch practice set details
  const practiceSetFetch = useFetchPracticeSet({
    id: practiceSetId,
  });
  const practiceSetItemsFetch = useFetchPracticeSetItems({
    practice_set_id: practiceSetId,
  });
  const collectionsFetch = useFetchCollections();
  const subjectsFetch = useFetchSubjects({
    limit: 100,
    country: authUser?.country || "US",
  });
  const gradeLevelsFetch = useFetchGradeLevels({
    limit: 50,
    country: authUser?.country || "US",
  });

  const collectionId = practiceSetFetch.data?.practice_set.collection_id;

  // will only run once the practice set has been fetched successfully
  // and id param is truthy
  const collectionFetch = useFetchCollection({
    id: collectionId && collectionId !== "0" ? collectionId : undefined,
  });

  useErrorToast(practiceSetFetch.error);
  useErrorToast(collectionsFetch.error);
  useErrorToast(subjectsFetch.error);
  useErrorToast(gradeLevelsFetch.error);
  useErrorToast(practiceSetItemsFetch.error);
  useErrorToast(collectionFetch.error);

  const isPracticeSetNotFound =
    practiceSetFetch.error?.response?.status === 404;

  const isPracticeSetAndItemsReady =
    practiceSetFetch.isSuccess && practiceSetItemsFetch.isSuccess;

  return useMemo(() => {
    const practiceSet = practiceSetFetch.data?.practice_set;
    const items = practiceSetItemsFetch.data?.practice_set_items || [];
    const collections = collectionsFetch.data?.collections || [];
    const subjects = subjectsFetch.data?.subjects || [];
    const gradeLevels = gradeLevelsFetch.data?.gradeLevels || [];
    const collection = collectionFetch.data?.collection;

    return {
      practiceSet,
      items,
      collections,
      collection,
      subjects,
      gradeLevels,
      isPracticeSetAndItemsReady,
      isPracticeSetNotFound,
    };
  }, [
    practiceSetFetch.data?.practice_set,
    practiceSetItemsFetch.data?.practice_set_items,
    collectionFetch.data?.collection,
    collectionsFetch.data?.collections,
    subjectsFetch.data?.subjects,
    gradeLevelsFetch.data?.gradeLevels,
    isPracticeSetAndItemsReady,
    isPracticeSetNotFound,
  ]);
};

export { usePracticeSetDetailData };
