import { useStyleConfig } from "@chakra-ui/react";
import React from "react";

import { Button, IProps as IButtonProps } from "adminComponents/atoms/Button";
import { Icon } from "adminComponents/atoms/Icon";

export interface IProps {
  size?: "small" | "medium" | "large";
  handleToggle: () => void;
  isOpen: boolean;
  openLabel: string;
  closedLabel: string;
}

export const ToggleButton: React.FC<IProps> = ({
  handleToggle,
  isOpen,
  size = "medium",
  openLabel,
  closedLabel,
}) => {
  let variant: IButtonProps["variant"];

  if (size === "small") {
    variant = "adminTextButtonSmall";
  } else if (size === "medium") {
    variant = "adminTextButtonMedium";
  } else if (size === "large") {
    variant = "adminTextButtonLarge";
  }

  const styles = useStyleConfig("AdminToggleButton", { isOpen });

  return (
    <Button variant={variant} onClick={handleToggle}>
      {isOpen ? openLabel : closedLabel}
      <Icon styles={styles} icon="button_down_arrow" iconColor="inherit" />
    </Button>
  );
};
