import { useMultiStyleConfig } from "@chakra-ui/react";
import React from "react";

import { Card } from "adminComponents/atoms/Card";
import {
  CleverAccountButton,
  ExternalAccountButton,
  GoogleAccountButton,
  MicrosoftAccountButton,
} from "adminComponents/atoms/ExternalAccountButton";
import { Heading } from "adminComponents/atoms/Heading";
import { Text } from "adminComponents/atoms/Text";
import { generateTeacherName } from "adminComponents/utils";
import { IUser } from "links/lib/types";

export interface IProps {
  teacher: IUser;
  linkedAccount: "Clever" | "Google" | "Microsoft";
  externalAccountLink: string;
}

const AccountButtons: Record<string, ExternalAccountButton> = {
  Clever: CleverAccountButton,
  Google: GoogleAccountButton,
  Microsoft: MicrosoftAccountButton,
};

export const TeacherLinkedAccountCard: React.FC<IProps> = ({
  teacher,
  linkedAccount,
  externalAccountLink,
}) => {
  const styles = useMultiStyleConfig("AdminTeacherLinkedAccountCard", {});
  const AccountButton = AccountButtons[linkedAccount];

  return (
    <Card
      sx={styles.card}
      variant="adminCardThickBorder"
      borderColor="primary.tan"
    >
      <Heading sx={styles.heading} as="h2" variant="adminH6">
        {generateTeacherName(teacher)?.primary}
      </Heading>
      <Text variant="adminP1" color="primary.dark-gray">
        {teacher.email}
      </Text>
      {!!externalAccountLink && (
        <AccountButton sx={styles.button} href={externalAccountLink} />
      )}
    </Card>
  );
};
