import React from "react";
import { useMount } from "react-use";

import { useSessionActions } from "links/lib/contexts/sessionActions";
import { useSessionGameState } from "links/lib/contexts/sessionGameState";
import { useSessionScene } from "links/lib/contexts/sessionScene";
import { GameInstructions as TheGameInstructions } from "sessionComponents/organisms/GameInstructions";
import { useSessionAudio } from "sharedComponents/contexts/sessionAudio";

export interface IGameInstructionsProps {
  isTeacher: boolean;
}

export const GameInstructions: React.FC<IGameInstructionsProps> = ({
  isTeacher,
}) => {
  //todo tracking?
  const { initiateGame } = useSessionActions();
  const gameState = useSessionGameState();
  const sessionScene = useSessionScene();
  const { setBackgroundAudio } = useSessionAudio();

  useMount(() => {
    if (isTeacher) {
      setBackgroundAudio();
    }
  });

  return (
    <TheGameInstructions
      isTeacher={isTeacher}
      gameState={gameState}
      scene={sessionScene}
      handleStartGame={() => initiateGame()}
    />
  );
};
