import {
  CoopChecksVariantData,
  CoopDragsortVariantData,
  CoopDrawVariantData,
  CoopRadiosVariantData,
  CoopTextMatchVariantData,
  ISessionSelectedChoice,
  PracticeSessionItemVariantType,
} from "links/lib/types";
import { ResponseOption } from "sessionComponents/hooks/useItemResponseOptions";
import { getCorrectChoiceIds } from "sessionComponents/utils/getCorrectChoiceIds";

interface IGetSortedByCorrectOptionsParams {
  variant: PracticeSessionItemVariantType;
  variant_data:
    | CoopTextMatchVariantData
    | CoopRadiosVariantData
    | CoopChecksVariantData
    | CoopDragsortVariantData
    | CoopDrawVariantData
    | Record<string, never>;
  options: ResponseOption[];
  selectedChoices?: ISessionSelectedChoice[];
}

/**
 * This util function is for TextMatch variants only. Because students provide the choices here, correct answers are returned from the backend as text values, not IDs.
 * Hence, getCorrectChoiceIds returns text values to match against.
 * Additionally, we check to see if any of the options the students provided are correct, and if not,
 * we add in the correct answer at the beginning of the sorted array.
 * We can safely assume this is always the first value in correctChoiceIds,
 * because TextMatch types can only have one correct answer (we do not want to add in missing accepted alternate text, which would be the following values in the array).
 * Finally, we get partially correct answers if they exist,
 * And sort the data by correct, partially correct, then incorrect answers.
 */
export const getSortedByCorrectOptions = ({
  variant,
  variant_data,
  options,
  selectedChoices,
}: IGetSortedByCorrectOptionsParams): ResponseOption[] => {
  const correctChoiceIds = getCorrectChoiceIds(variant, variant_data);
  let correctOptions = options.filter((opt) =>
    correctChoiceIds.includes(opt.text)
  );

  // if no one on the team selected the correct answer, add it at the top of the options
  if (!correctOptions.length) {
    correctOptions = [
      {
        id: "-1", //these ids are automatically generated, trying to avoid a conflict
        text: correctChoiceIds[0],
        image_url: "",
        user_id: "",
        image_alt_text: "",
      },
    ];
  }

  const partiallyCorrectOptions = options.filter(
    (opt) =>
      !!selectedChoices?.find(
        (c) => c.choice_id === opt.id && c.is_partially_correct
      )
  );

  const incorrectOptions = options.filter(
    (opt) =>
      !correctChoiceIds.includes(opt.text) &&
      !partiallyCorrectOptions.find((o) => o.id === opt.id)
  );

  const sortedOptions = [
    ...correctOptions,
    ...partiallyCorrectOptions,
    ...incorrectOptions,
  ];

  return sortedOptions;
};
