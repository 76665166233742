import { Box } from "@chakra-ui/react";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";

import { useCoverImageBackground } from "adminComponents/atoms/CoverImageBackground/hooks/useCoverImageBackground";
import { pxToRem } from "adminComponents/utils";
import { CoverImageBGColorSchemes } from "links/lib/constants";
import {
  CoverImageBGColorSchemeType,
  CoverImageBGPatternType,
  IAvatarAnimation,
} from "links/lib/types";
import {
  AvatarStage,
  AvatarStageItem,
  AvatarStageMember,
} from "sharedComponents/molecules/AvatarStage";
import { SpineStage } from "sharedComponents/molecules/AvatarStage/lib/SpineStage";

import { useCameraPosition } from "./hooks/useCameraPosition";
import { useCameraZoom } from "./hooks/useCameraZoom";

export interface IAvatarPhotoStageProps {
  animation: IAvatarAnimation;
  atlasUrl: string;
  skeletonUrl: string;
  bgPattern: CoverImageBGPatternType;
  bgColorScheme: CoverImageBGColorSchemeType;
}

const stageSize = {
  x: 1000,
  y: 1000,
};

export const AvatarPhotoStage = forwardRef<
  undefined | SpineStage,
  IAvatarPhotoStageProps
>((p, ref) => {
  const { animation, atlasUrl, skeletonUrl, bgPattern, bgColorScheme } = p;

  const spineStageRef = useRef<SpineStage | undefined>(undefined);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [backgroundImage, setBackgroundImage] = useState<string | null>(null);

  useImperativeHandle(ref, () => spineStageRef.current);

  const stageAnimation = useMemo(
    () => ({ name: animation.name, loop: true }),
    [animation.name]
  );

  const cameraZoom = useCameraZoom(0.8, containerRef);
  const {
    x: cameraX,
    y: cameraY,
    isGrabbing,
  } = useCameraPosition({ x: 0, y: 0 }, containerRef, stageSize, cameraZoom);
  const camera = useMemo(() => {
    return { x: cameraX, y: cameraY, z: 0, zoom: cameraZoom };
  }, [cameraZoom, cameraX, cameraY]);

  const { tileImage } = useCoverImageBackground({
    pattern: bgPattern,
    colorScheme: bgColorScheme,
    tileSize: 200,
  });

  // Effect to generate an image of the repeating background
  // to cover the canvas
  useEffect(() => {
    if (!tileImage) return;

    const tileCanvas = document.createElement("canvas");
    tileCanvas.width = stageSize.x;
    tileCanvas.height = stageSize.y;
    const tileContext = tileCanvas.getContext("2d");

    if (!tileContext) return;

    const img = new Image();
    img.src = tileImage;

    img.addEventListener("load", () => {
      const ptrn = tileContext.createPattern(img, "repeat");

      if (!ptrn) return;

      tileContext.fillStyle = ptrn;
      tileContext.fillRect(0, 0, tileCanvas.width, tileCanvas.height);

      setBackgroundImage(tileCanvas.toDataURL());
    });

    return () => {
      tileCanvas.width = 0;
      tileCanvas.height = 0;
    };
  }, [tileImage]);

  const bgColorSchemeRGB = CoverImageBGColorSchemes[bgColorScheme];

  return (
    <Box
      borderWidth={{ base: pxToRem(4), lg: pxToRem(8) }}
      borderColor="utility.link"
      borderStyle="dashed"
      ref={containerRef}
      cursor={isGrabbing ? "grabbing" : "grab"}
    >
      <AvatarStage
        camera={camera}
        alignX="center"
        alignY="end"
        style={{
          aspectRatio: "1/1",
          width: "100%",
          background:
            bgPattern !== "TRANSPARENT"
              ? `rgb(${bgColorSchemeRGB[0].join(",")})`
              : "transparent",
        }}
        stageSize={stageSize}
      >
        {(spineStage, stageOverlay) => {
          if (spineStage !== spineStageRef.current) {
            spineStageRef.current = spineStage;
          }

          return (
            <>
              {/* Spine throws undefined error when given fully transparent PNG */}
              {bgPattern !== "TRANSPARENT" && backgroundImage && (
                <AvatarStageItem
                  key={backgroundImage}
                  imageSrc={backgroundImage}
                  scale={1}
                  x={0}
                  y={0}
                  spineStage={spineStage}
                  drawOrder={1}
                />
              )}

              <AvatarStageMember
                animation={stageAnimation}
                scale={0.6}
                x={0}
                y={-340}
                atlasUrl={atlasUrl}
                skeletonUrl={skeletonUrl}
                spineStage={spineStage}
                stageOverlay={stageOverlay}
                drawOrder={2}
                key={atlasUrl}
              />
            </>
          );
        }}
      </AvatarStage>
    </Box>
  );
});

AvatarPhotoStage.displayName = "AvatarPhotoStage";
