import { IUseAsyncReturn, useAsync } from "../../hooks/useAsync";
import { useAxios } from "../../hooks/useAxios";
import { ISubject } from "../../types";

export interface IFetchSubjectsOpts {
  limit: number;
  country: string;
  region?: string;
}

export interface IFetchSubjectsResponse {
  subjects: Array<ISubject>;
}

/**
 * Fetches subjects a single time using execution parameters without
 * caching results or invalidating subject queries
 * @returns
 */
export default function useFetchSubjectsOnce(): IUseAsyncReturn<
  IFetchSubjectsResponse,
  IFetchSubjectsOpts
> {
  const axios = useAxios();

  const fetchSubjects = async ({
    limit,
    country,
    region,
  }: IFetchSubjectsOpts) => {
    return await axios
      .get(
        `/v1/subjects?limit=${limit}&country=${country}&region=${region || ""}`
      )
      .then((r) => r.data as IFetchSubjectsResponse);
  };

  const { isLoading, error, data, execute, isSuccess } = useAsync<
    IFetchSubjectsResponse,
    IFetchSubjectsOpts
  >(fetchSubjects);

  return {
    isLoading,
    error,
    data,
    isSuccess,
    execute,
  };
}
