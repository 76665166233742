import { transparentize } from "@chakra-ui/theme-tools";

import { hoverStyles, pxToRem } from "adminComponents/utils";

export const Drawer = {
  variants: {
    adminFlyoutDefault: {
      overlay: {
        background: transparentize("primary.warm-black", 0.3),
      },
      header: {
        pt: pxToRem(60),
        pb: [pxToRem(32), null, pxToRem(40)],
        px: [
          "admin.flyout.mobileXPadding",
          null,
          "admin.flyout.desktopXPadding",
        ],
        textStyle: "adminH3",
        fontSize: null,
      },
      body: {
        paddingBottom: 0,
        paddingX: 0,
        paddingTop: 0,
        textStyle: "adminP1",
      },
      closeButton: {
        boxShadow: "none",
        borderRadius: "100%",
        _focusVisible: {
          boxShadow: "none",
          border: `${pxToRem(2)} solid`,
          borderColor: "primary.golden",
        },
        _hover: hoverStyles({
          border: `${pxToRem(2)} solid`,
          borderColor: "primary.golden",
        }),
        _active: {
          backgroundcolor: "transparent",
        },
      },
    },
    adminNavigation: {
      closeButton: {
        right: "unset",
        top: pxToRem(56),
        left: pxToRem(19),
      },
      body: {
        backgroundColor: "primary.warm-white",
        paddingTop: pxToRem(100),
      },
    },
  },
};
