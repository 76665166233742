import { Box } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Heading } from "adminComponents/atoms/Heading";
import { LoadingSpinner } from "adminComponents/atoms/LoadingSpinner";
import { Text } from "adminComponents/atoms/Text";
import { MyClassAssignments } from "adminComponents/screens/MyClassesScreen/components/MyClassAssignments";
import { MyClassPracticeSets } from "adminComponents/screens/MyClassesScreen/components/MyClassPracticeSets";
import { pxToRem } from "adminComponents/utils";
import { IAssignment, IPendingSession, IPracticeSet } from "links/lib/types";

import { IMyAssignment, IMyPracticeSet } from "..";

export interface IMyClassPracticeTabProps {
  myAssignments?: IMyAssignment[];
  myPracticeSets?: IMyPracticeSet[];
  isClassroomLoading: boolean;
  hasCompletedAssignments: boolean;
  handleViewCompletedAssignments: () => void;
  handlePlayPracticeSet: (practiceSet: IPracticeSet) => void;
  handlePlayAssignment: (assignment: IAssignment) => void;
  pendingSession?: IPendingSession;
}

export const MyClassPracticeTab: React.FC<IMyClassPracticeTabProps> = ({
  myAssignments,
  myPracticeSets,
  isClassroomLoading = false,
  hasCompletedAssignments,
  handlePlayAssignment,
  handlePlayPracticeSet,
  handleViewCompletedAssignments,
  pendingSession,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  return (
    <Box w="full" mb={pxToRem(0)}>
      <Box mb={pxToRem(32)}>
        <Heading as="h2" variant="adminH6Bold" my={pxToRem(24)}>
          {t("common.assignments")}
        </Heading>
        {!isClassroomLoading ? (
          <MyClassAssignments
            myAssignments={myAssignments}
            handlePlayAssignment={handlePlayAssignment}
            handleViewCompletedAssignments={handleViewCompletedAssignments}
            hasCompletedAssignments={hasCompletedAssignments}
            pendingSession={pendingSession}
          />
        ) : (
          <Box mt={pxToRem(40)}>
            <LoadingSpinner />
          </Box>
        )}
      </Box>

      <Box mb={pxToRem(24)}>
        <Heading variant="adminH6Bold" as="h2" mb={pxToRem(4)}>
          {t("studentPracticeSetGridCarousel.title")}
        </Heading>
        {myPracticeSets && isClassroomLoading && (
          <Text variant="adminP2">
            {t("studentPracticeSetGridCarousel.subtitle")}
          </Text>
        )}
      </Box>

      {!isClassroomLoading ? (
        <MyClassPracticeSets
          myPracticeSets={myPracticeSets}
          handlePlayPracticeSet={handlePlayPracticeSet}
          pendingSession={pendingSession}
        />
      ) : (
        <Box mt={pxToRem(40)}>
          <LoadingSpinner />
        </Box>
      )}
    </Box>
  );
};
