import {
  Box,
  Tabs as ChakraTabs,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  TabProps,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import React, { ReactElement, ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { Icon, IconType } from "adminComponents/atoms/Icon";
import { PremiumTooltip } from "adminComponents/atoms/PremiumTooltip";
import { pxToRem } from "adminComponents/utils";

import { PremiumTooltipRich } from "../PremiumTooltipRich";

export type TabData = {
  label: string;
  icon?: IconType;
  content: ReactNode;
  isDisabled?: boolean;
  checked?: boolean;
  isPremium?: boolean;
  disablePremium?: boolean;
};

interface IProps extends TabProps {
  tabData?: Array<TabData>;
  tabIndex?: number;
  variant: string;
  handleChange?: (index: number) => void;
  showCheck?: boolean;
  ctaButtonRight?: ReactElement;
  centerTabs?: boolean;
}

export const Tabs: React.FC<IProps> = ({
  tabData,
  variant,
  tabIndex,
  showCheck = false,
  sx,
  handleChange,
  ctaButtonRight,
  centerTabs = false,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const styles = useMultiStyleConfig("AdminTabs", { variant, centerTabs });

  const handleTabChange = (index: number) => {
    handleChange?.(index);
  };

  return (
    <ChakraTabs
      sx={sx}
      variant={variant}
      onChange={handleTabChange}
      index={tabIndex}
    >
      <Box sx={styles.tablistContainer}>
        <TabList sx={styles.tablist}>
          {tabData?.map((tab, index) => (
            <Tab key={index} isDisabled={tab.isDisabled} sx={styles.tab}>
              {showCheck && tab.checked && (
                <Box as="span" sx={styles.checkmarkBox}>
                  <Icon
                    icon="check"
                    iconColor="primary.white"
                    as="span"
                    styles={styles.checkmarkIcon}
                  />
                </Box>
              )}
              {tab.icon && (
                <Box display="flex" alignItems="center" as="span">
                  <Icon
                    icon={tab.icon}
                    iconColor="primary.warm-black"
                    as="span"
                    mr={pxToRem(10)}
                  />
                </Box>
              )}
              {tab.isPremium && tab.disablePremium && (
                <PremiumTooltipRich>
                  <Box display="flex" alignItems="center">
                    <Icon
                      icon="lock"
                      iconColor="primary.warm-black"
                      as="span"
                      mr={pxToRem(10)}
                    />
                  </Box>
                </PremiumTooltipRich>
              )}
              {tab.isPremium && !tab.disablePremium && (
                <PremiumTooltip>
                  <Box
                    as="span"
                    sx={styles.premiumBox}
                    aria-label={t("common.premiumFeature")}
                    aria-role="img"
                    role="img"
                  >
                    <Icon
                      icon="crown"
                      iconColor="primary.white"
                      as="span"
                      styles={styles.premiumIcon}
                    />
                  </Box>
                </PremiumTooltip>
              )}
              {tab.label}
            </Tab>
          ))}
          {ctaButtonRight && (
            <>
              <Spacer />
              {ctaButtonRight}
            </>
          )}
        </TabList>
      </Box>
      <TabPanels>
        {tabData?.map((tab, index) => (
          <TabPanel key={index} sx={styles.tabpanel}>
            {tab.content}
          </TabPanel>
        ))}
      </TabPanels>
    </ChakraTabs>
  );
};
