import { useMemo } from "react";

import { renderLatexString } from "lib/latexUtils";

/**
 * Render static latex with katex
 */
export const useStaticLatex = (
  latexContent: string,
  options: Record<string, unknown>
): { result: string } => {
  const result = useMemo(() => {
    return renderLatexString("\\sf " + latexContent, options);
  }, [latexContent, options]);

  return {
    result,
  };
};
