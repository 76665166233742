import { Box, Text, VStack } from "@chakra-ui/react";
import { Alignment, Fit, Layout } from "@rive-app/react-canvas";
import { useRive } from "@rive-app/react-canvas";
import moment from "moment";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { animated, config, useSpring } from "react-spring";
import { useMount } from "react-use";

import { pxToRem } from "adminComponents/utils";
import { AnalyticsEvent, UserXPMultiplier } from "links/lib/types";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { StudentSessionSoundEffect } from "sessionComponents/types";
import { useSessionAnalytics } from "sharedComponents/contexts/sessionAnalytics";
import { useSessionAudio } from "sharedComponents/contexts/sessionAudio";
import { useBoostTimer } from "sharedComponents/hooks/useBoostTimer";

import animationSrc from "./resource/xp_boost_icon.riv";

export interface IPodiumXPBoostProps {
  userXPMultiplier: UserXPMultiplier;
  handleComplete?: () => void;
}

const AnimatedVStack = animated(VStack);
const AnimatedBox = animated(Box);

export const PodiumXPBoost: React.FC<IPodiumXPBoostProps> = ({
  userXPMultiplier,
  handleComplete,
}) => {
  const { play: playAudio } = useSessionAudio();
  const { match: currentBreakpoints } = useBreakpoints();
  const [isComplete, setIsComplete] = React.useState(false);
  const { t } = useTranslation("session", {
    useSuspense: false,
    keyPrefix: "podium.xpBoost",
  });
  const { track } = useSessionAnalytics();

  useMount(() => {
    track(AnalyticsEvent.Session_Podium_ViewXPBoost, {});
  });

  const { RiveComponent } = useRive({
    src: animationSrc,
    layout: new Layout({
      fit: Fit.Contain,
      alignment: Alignment.Center,
    }),
    stateMachines: "XP Boost",
    animations: "main",
    autoplay: true,
    onLoad: () => {
      playAudio(StudentSessionSoundEffect.EarnedXPBoost);
    },
    onStateChange: ({ type, data }) => {
      if (
        type === "statechange" &&
        Array.isArray(data) &&
        data.some((s) => s === "float")
      ) {
        setIsComplete(true);
        handleComplete?.();
      }
    },
  });

  const timerSpring = useSpring({
    from: { opacity: 0, scale: 0 },
    to: isComplete ? { opacity: 1, scale: 1 } : { opacity: 0, scale: 0 },
    config: config.wobbly,
  });

  const textSpring = useSpring({
    from: { opacity: 0 },
    to: isComplete ? { opacity: 1 } : { opacity: 0 },
    config: config.slow,
    delay: 500,
  });

  const { secondsRemaining } = useBoostTimer({
    userXPMultiplier,
  });

  const minutesRemaining = Math.floor(secondsRemaining / 60);

  const timerSize = {
    x: currentBreakpoints.fontSize * 4,
    y: currentBreakpoints.fontSize * 1.4,
  };

  const boostAdjective =
    userXPMultiplier?.xp_multiplier == 2 ? t("double") : t("extra");

  return (
    <Box
      w="full"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      h="full"
    >
      <Box
        style={{ aspectRatio: "1/1" }}
        maxH="50%"
        w="full"
        h="full"
        position="relative"
      >
        <RiveComponent />
        <AnimatedBox
          position="absolute"
          bottom="10%"
          left="50%"
          marginLeft={pxToRem(-timerSize.x / 2)}
          background="xp-boost.timer-bg"
          borderRadius="full"
          h={pxToRem(timerSize.y)}
          w={pxToRem(timerSize.x)}
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={timerSpring}
        >
          <Text
            textAlign="center"
            fontSize={pxToRem(currentBreakpoints.fontSize * 1.2)}
            textStyle="gameLabel"
          >
            {moment.utc(secondsRemaining * 1000).format("m:ss")}
          </Text>
        </AnimatedBox>
      </Box>
      <AnimatedVStack spacing={pxToRem(12)} w="full" style={textSpring}>
        <Text
          textAlign="center"
          fontSize={pxToRem(currentBreakpoints.fontSize * 1.2)}
          textStyle="gameDisplay"
          fontWeight="700"
        >
          {t("heading", {
            adjective: boostAdjective,
          })}
        </Text>
        <Text
          textAlign="center"
          fontSize={pxToRem(currentBreakpoints.fontSize)}
          textStyle="gameParagraph"
        >
          <Trans
            i18nKey="description"
            t={t}
            values={{
              adjective: boostAdjective,
              minutes: minutesRemaining,
              count: minutesRemaining,
            }}
            components={{ bold: <strong /> }}
          />
        </Text>
      </AnimatedVStack>
    </Box>
  );
};
