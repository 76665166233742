import { Box, Button, HStack, useMultiStyleConfig } from "@chakra-ui/react";
import React, { Fragment } from "react";

import { IconButton } from "adminComponents/atoms/IconButton";
import { Text } from "adminComponents/atoms/Text";
import { colorMapping } from "adminComponents/theme/colors";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { IUser } from "links/lib/types";

import { ReactComponent as BackgroundIcon } from "./resource/type1.svg";

export type IProps = {
  handleSetPage: (page: number) => void;
  page: number;
  perPage: number;
  totalCount: number;
  authUser?: IUser;
};

export const Pagination: React.FC<IProps> = ({
  handleSetPage,
  page,
  perPage,
  totalCount,
  authUser,
}) => {
  const styles = useMultiStyleConfig("AdminPagination", {});

  const pageCount = Math.ceil(totalCount / perPage);

  if (pageCount < 1) {
    return <></>;
  }

  const visiblePages = [page];

  if (page === 1) {
    if (page < pageCount) {
      for (let i = 2; i < Math.min(pageCount, 4); i++) {
        visiblePages.push(i);
      }
      visiblePages.push(pageCount);
    }
  } else if (page > 1) {
    if (page - 1 > 1) {
      visiblePages.unshift(page - 1);
    }
    if (page + 1 < pageCount) {
      visiblePages.push(page + 1);
    }
    if (page < pageCount) {
      visiblePages.push(pageCount);
    }
    visiblePages.unshift(1);
  }

  return (
    <HStack>
      <IconButton
        ariaLabel="Previous Page"
        disabled={page <= 1}
        icon="keyboard_arrow_left_outlined"
        onClick={() => handleSetPage(page - 1)}
        shape="type1"
        variant="ghost"
      />
      <HStack>
        {visiblePages.map((visiblePage, i) => {
          const prevPage = visiblePages[i - 1];

          return (
            <Fragment key={i}>
              {prevPage > 0 && prevPage < visiblePage - 1 && (
                <Box as="span" mr={pxToRem(5)}>
                  …
                </Box>
              )}
              <Button
                __css={styles.button}
                onClick={() => handleSetPage(visiblePage)}
              >
                <BackgroundIcon
                  fill={
                    page === visiblePage
                      ? colorMapping["primary.golden"]
                      : "transparent"
                  }
                  stroke={
                    page === visiblePage
                      ? colorMapping["primary.golden"]
                      : "transparent"
                  }
                />
                <Text
                  sx={styles.buttonText}
                  variant={page === visiblePage ? "adminP1Bold" : "adminP1"}
                >
                  {visiblePage.toLocaleString(authUser?.language)}
                </Text>
              </Button>
            </Fragment>
          );
        })}
      </HStack>
      <IconButton
        ariaLabel="Next Page"
        disabled={page >= pageCount}
        icon="keyboard_arrow_right_outlined"
        onClick={() => handleSetPage(page + 1)}
        shape="type1"
        variant="ghost"
      />
    </HStack>
  );
};
