import { Box, Flex, useBreakpointValue } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import GreatWork from "adminComponents/assets/images/GreatWork.svg";
import { Button } from "adminComponents/atoms/Button";
import { Card } from "adminComponents/atoms/Card";
import { Divider } from "adminComponents/atoms/Divider";
import { Heading } from "adminComponents/atoms/Heading";
import { Icon } from "adminComponents/atoms/Icon";
import { Text } from "adminComponents/atoms/Text";
import { GreatWorkCTA } from "adminComponents/molecules/GreatWorkCTA";
import { HighFivesCard } from "adminComponents/molecules/HighFivesCard";
import {
  IProps as LiveBannerProps,
  LiveClassroomBanner,
} from "adminComponents/molecules/LiveClassroomBanner";
import MyDailyRemixes from "adminComponents/molecules/MyDailyRemixes";
import { SeasonChallengeMapCard } from "adminComponents/molecules/SeasonChallengeMapCard";
import { StudentAssignmentCard } from "adminComponents/molecules/StudentAssignmentCard";
import {
  IProps as INavigationProps,
  StudentNavigationHeader,
} from "adminComponents/molecules/StudentNavigationHeader";
import { StudentPracticeSetCard } from "adminComponents/molecules/StudentPracticeSetCard";
import { StudentXpSummaryCard } from "adminComponents/molecules/StudentXpSummaryCard";
import { WelcomeCard } from "adminComponents/molecules/WelcomeCard";
import { IMyAssignment } from "adminComponents/organisms/MyAssignmentsFlyout";
import { StudentTemplateWithTwoColumns } from "adminComponents/templates/StudentTemplateWithTwoColumns";
import { pxToRem } from "adminComponents/utils";
import {
  IAssignment,
  IPendingSession,
  IPeriodicChallenges,
  IPracticeSessionsStreak,
  IPracticeSet,
  IPracticeSetSession,
  IRemix,
  IUser,
  SocialEvent,
} from "links/lib/types";

export interface IProps {
  navigationData: INavigationProps;
  isLoading?: boolean;
  periodicChallenges?: IPeriodicChallenges;
  practiceSessionStreak?: IPracticeSessionsStreak;
  monthlyChallengeRemainingDays?: number;
  user?: IUser;
  seasonName?: string;
  seasonChallengeDaysLeft?: number;
  currentLevel: number;
  currentXP: number;
  nextLevelXP: number;
  ticketCount?: number;
  seasonChallengeImageSrc?: string;
  seasonChallengeImageAlt: string;
  myAssignments?: IMyAssignment[];
  practiceSets?: Array<{
    practiceSet: IPracticeSet;
    rewardXp: number;
    classroomId: string;
  }>;
  socialEvents: SocialEvent[];
  showWelcomeCard: boolean;
  dailyRemixes: IRemix[];
  liveBanners?: Array<Omit<LiveBannerProps, "handleJoinSession">>;
  pendingSession?: IPendingSession;
  handleEditAvatar: () => void;
  handleOpenMap: () => void;
  handleJoinSession: (session: IPracticeSetSession) => void;
  handleAssignmentsPlayButton: (assignment: IAssignment) => void;
  handlePracticeSetPlayButton: (
    practiceSet: IPracticeSet,
    classroomId: string
  ) => void;
  handleOpenWelcomeCardModal: () => void;
  handleDismissWelcomeCard: () => void;
  handleViewClassesButton: () => void;
  handleOpenAssignmentsFlyout: () => void;
  handleShufflePracticeSets: () => void;
  handleRemixClick: (remix: IRemix) => void;
  handleSocialEventClick: (socialEvent: SocialEvent) => void;
}

export const StudentHomepage: React.FC<IProps> = ({
  navigationData,
  isLoading,
  periodicChallenges,
  practiceSessionStreak,
  monthlyChallengeRemainingDays,
  user,
  seasonName,
  seasonChallengeDaysLeft,
  currentLevel,
  currentXP,
  nextLevelXP,
  ticketCount,
  seasonChallengeImageSrc,
  seasonChallengeImageAlt,
  myAssignments,
  practiceSets,
  socialEvents,
  showWelcomeCard,
  dailyRemixes,
  liveBanners,
  handleEditAvatar,
  handleOpenMap,
  handleAssignmentsPlayButton,
  handlePracticeSetPlayButton,
  handleOpenWelcomeCardModal,
  handleDismissWelcomeCard,
  handleViewClassesButton,
  handleOpenAssignmentsFlyout,
  handleShufflePracticeSets,
  handleRemixClick,
  handleJoinSession,
  pendingSession,
  handleSocialEventClick,
}) => {
  const { t } = useTranslation("admin", {
    keyPrefix: "studentHomepage",
    useSuspense: false,
  });
  const isMobile = useBreakpointValue({ base: true, md: false });
  const hasPracticeSets = practiceSets && practiceSets.length > 0;
  const hasRemixes = dailyRemixes && dailyRemixes.length > 0;

  let myAssignment: IMyAssignment | null = null;
  if (myAssignments && myAssignments.length > 0) {
    myAssignment = myAssignments[0];
  }

  const renderWelcomeCard = () => {
    if (showWelcomeCard) {
      return (
        // 67.5 matches 1/2 avatar height, so cards line up when New Here card is at top of right column
        <Box marginTop={!hasRemixes && !isMobile ? pxToRem(67.5) : 0}>
          <WelcomeCard
            handleDismiss={handleDismissWelcomeCard}
            handleOpenModal={handleOpenWelcomeCardModal}
          />
        </Box>
      );
    }
  };

  const renderDailyRemixes = () => {
    if (hasRemixes) {
      return (
        <Box>
          <MyDailyRemixes
            remixItems={dailyRemixes}
            handleClick={handleRemixClick}
            pendingSession={pendingSession}
          />
        </Box>
      );
    }
  };

  const renderTopContent = () => {
    if (
      !!liveBanners?.length ||
      (showWelcomeCard && isMobile) ||
      (hasRemixes && isMobile)
    ) {
      return (
        <Flex
          direction="column"
          paddingBottom={{ base: pxToRem(32), md: pxToRem(40) }}
          gap={pxToRem(32)}
        >
          {isMobile && renderDailyRemixes()}
          {!!liveBanners?.length &&
            liveBanners.map((l) => (
              <LiveClassroomBanner
                key={l.session.id}
                handleJoinSession={handleJoinSession}
                {...l}
              />
            ))}
          {isMobile && renderWelcomeCard()}
        </Flex>
      );
    }
  };

  const renderMobileShiftedLeftContent = () => {
    if (!practiceSessionStreak || !user || !periodicChallenges) return <></>;

    return (
      <StudentXpSummaryCard
        periodicChallenges={periodicChallenges}
        practiceSessionStreak={practiceSessionStreak}
        monthlyChallengeRemainingDays={monthlyChallengeRemainingDays}
        user={user}
        headingLevel="h2"
        subHeadingLevel="h3"
        handleEditAvatar={handleEditAvatar}
      />
    );
  };

  const renderLeftContent = () => {
    if (!seasonChallengeImageSrc) return <></>;

    return (
      <Flex
        direction="column"
        marginTop={{ base: pxToRem(22), md: pxToRem(40) }}
        gap={pxToRem(32)}
      >
        <SeasonChallengeMapCard
          seasonName={seasonName}
          daysLeft={seasonChallengeDaysLeft}
          currentLevel={currentLevel}
          currentXP={currentXP}
          nextLevelXP={nextLevelXP}
          ticketCount={ticketCount}
          imageSrc={seasonChallengeImageSrc}
          imageAlt={seasonChallengeImageAlt}
          handleOpenMap={handleOpenMap}
          headingLevel="h2"
        />
        <HighFivesCard
          socialEvents={socialEvents}
          handleSocialEventClick={handleSocialEventClick}
        />
      </Flex>
    );
  };

  const renderRightContent = () => {
    return (
      <Flex direction="column" gap={pxToRem(32)}>
        {!isMobile && renderDailyRemixes()}
        {!isMobile && renderWelcomeCard()}
        {(hasRemixes || showWelcomeCard) && !isMobile && (
          <Divider color="primary.tan" />
        )}
        <Flex direction="column" gap={pxToRem(24)}>
          <Flex justify="space-between">
            <Heading variant="adminH6Bold" as="h2">
              {t("assignmentsHeader")}
            </Heading>
            {myAssignment && (
              <Button
                variant="adminTextButtonMedium"
                onClick={handleOpenAssignmentsFlyout}
                leftIcon={
                  <Icon
                    icon="list"
                    color="currentcolor"
                    width={pxToRem(15)}
                    marginRight={pxToRem(7)}
                  />
                }
              >
                {t("viewAllButton")}
              </Button>
            )}
          </Flex>
          {myAssignment ? (
            <>
              <Text variant="adminP2">{t("assignmentsSubheader")}</Text>
              <StudentAssignmentCard
                {...myAssignment}
                isDisabled={!!pendingSession}
                isLoading={
                  pendingSession?.assignmentId === myAssignment.assignment.id
                }
                headingLevel="h3"
                handlePlayButton={handleAssignmentsPlayButton}
              />
            </>
          ) : (
            <Card variant="adminCardSmallBorder" borderColor="primary.tan">
              <Heading variant="adminH6" as="h3" textAlign="center">
                {t("assignmentsNull")}
              </Heading>
            </Card>
          )}
        </Flex>
        <Divider color="primary.tan" />
        <Flex direction="column" gap={pxToRem(24)}>
          <Flex justify="space-between" alignItems="start">
            <Flex direction="column" gap={pxToRem(9)}>
              <Heading variant="adminH6Bold" as="h2">
                {t("practiceSetsHeader")}
              </Heading>
              {hasPracticeSets && (
                <Text variant="adminP2">{t("practiceSetsSubheader")}</Text>
              )}
            </Flex>

            {hasPracticeSets && (
              <Button
                variant="adminTextButtonMedium"
                onClick={handleShufflePracticeSets}
                leftIcon={
                  <Icon
                    icon="refresh"
                    color="currentcolor"
                    width={pxToRem(15)}
                    marginRight={pxToRem(7)}
                  />
                }
              >
                {t("shuffleButton")}
              </Button>
            )}
          </Flex>
          {hasPracticeSets ? (
            <Flex
              direction="column"
              gap={{ base: pxToRem(16), md: pxToRem(24) }}
            >
              {practiceSets.map(({ practiceSet, rewardXp, classroomId }) => {
                return (
                  <StudentPracticeSetCard
                    key={practiceSet.id}
                    practiceSet={practiceSet}
                    rewardXp={rewardXp}
                    headingLevel="h3"
                    handlePlayButton={(ps) =>
                      handlePracticeSetPlayButton(ps, classroomId)
                    }
                    isDisabled={!!pendingSession}
                    isLoading={pendingSession?.practiceSetId === practiceSet.id}
                  />
                );
              })}
            </Flex>
          ) : (
            <Card variant="adminCardSmallBorder" borderColor="primary.tan">
              <Heading variant="adminH6" as="h3" textAlign="center">
                {t("practiceSetsNull")}
              </Heading>
            </Card>
          )}
        </Flex>
        <GreatWorkCTA
          title={t("greatWorkCtaTitle")}
          image={GreatWork}
          imageAltText={t("greatWorkCtaImageAltText")}
          handleViewClasses={handleViewClassesButton}
        />
      </Flex>
    );
  };

  return (
    <StudentTemplateWithTwoColumns
      isLoading={isLoading}
      nav={<StudentNavigationHeader {...navigationData} />}
      topContent={renderTopContent()}
      leftColumnShiftedContent={renderMobileShiftedLeftContent()}
      leftColumnContent={renderLeftContent()}
      rightColumnContent={renderRightContent()}
    />
  );
};
