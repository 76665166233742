import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  LocationInfo,
  SeasonMap,
  SeasonMapLocation,
  SeasonMapPin,
  SeasonMapZone,
} from "sharedComponents/molecules/SeasonMap";
import { ISeasonMapPannerChildProps } from "sharedComponents/molecules/SeasonMapPanner";

import BuildingsImage from "./resource/buildings_only.svg";
import FabledForestPreviewImage from "./resource/locations/location_preview_fabled_forest.svg";
import JeweledJunglePreviewImage from "./resource/locations/location_preview_jeweled_jungle.svg";
import PolarPlainsPreviewImage from "./resource/locations/location_preview_polar_plains.svg";
import SapphireSeaPreviewImage from "./resource/locations/location_preview_sapphire_sea.svg";
import SizzlingSandsPreviewImage from "./resource/locations/location_preview_sizzling_sands.svg";
import SunlitSwampPreviewImage from "./resource/locations/location_preview_sunlit_swamp.svg";
import { ReactComponent as ZoneLabelsSVG } from "./resource/zone_labels.svg";

export interface ISeason3MapProps extends ISeasonMapPannerChildProps {
  // zone ids for unlocked zones
  unlockedZoneIds: Array<string>;
  // the id of the user's current location on map
  currentLocationId?: string;
  // the profile image of the user to show in the map pin
  userImg?: string;
  // user progression for each map location
  locationsProgress: Array<{
    totalSpaces: number;
    spacesCollected: number;
    locationId: string;
  }>;
  // handler for when a location is selected
  handleLocationSelect?: (info: LocationInfo) => void;
}

export interface IZoneData {
  zoneId: string;
  offset: { top: number; left: number };
  pathSVG: React.ReactElement<SVGElement>;
  pathColor: string;
  width: number;
  height: number;
  locations: Array<ILocationData>;
}

export interface ILocationData {
  offset: { top: number; left: number };
  locationId: string;
  previewImageUrl: string;
}

export const mapSize = {
  width: 3840,
  height: 2061,
};

export const mapData: Array<IZoneData> = [
  // Zone 1
  {
    zoneId: "7",
    offset: { top: 0, left: 0 },
    pathSVG: <ZoneLabelsSVG />,
    pathColor: "#FA553A",
    width: 3840,
    height: 2061,
    locations: [
      {
        offset: { top: 1360, left: 870 },
        locationId: "25",
        previewImageUrl: FabledForestPreviewImage,
      },
      {
        offset: { top: 1900, left: 1550 },
        locationId: "29",
        previewImageUrl: PolarPlainsPreviewImage,
      },
    ],
  },

  // Zone 2
  {
    zoneId: "8",
    offset: { top: 0, left: 0 },
    pathSVG: <></>,
    pathColor: "#00A3DE",
    width: 3840,
    height: 2061,
    locations: [
      {
        offset: { top: 540, left: 1020 },
        locationId: "27",
        previewImageUrl: JeweledJunglePreviewImage,
      },
      {
        offset: { top: 180, left: 2030 },
        locationId: "28",
        previewImageUrl: SizzlingSandsPreviewImage,
      },
    ],
  },

  // Zone 3
  {
    zoneId: "9",
    offset: { top: 0, left: 0 },
    pathSVG: <></>,
    pathColor: "#E961B1",
    width: 3840,
    height: 2061,
    locations: [
      {
        offset: { top: 1780, left: 2590 },
        locationId: "26",
        previewImageUrl: SunlitSwampPreviewImage,
      },
      {
        offset: { top: 775, left: 2850 },
        locationId: "30",
        previewImageUrl: SapphireSeaPreviewImage,
      },
    ],
  },
];

export const Season3Map: React.FC<ISeason3MapProps> = ({
  scale = 1,
  handleLocationFocus,
  handleLocationSelect,
  unlockedZoneIds,
  userImg,
  currentLocationId,
  locationsProgress,
}) => {
  const { t } = useTranslation("map", {
    keyPrefix: "season3.locations",
    useSuspense: false,
  });

  // merge map data with translations, location progress, and zone locks
  const combinedMapData = useMemo(() => {
    return mapData.map((zoneData) => {
      return {
        ...zoneData,
        isLocked: !unlockedZoneIds.some((z) => z === zoneData.zoneId),
        locations: zoneData.locations.map((l) => ({
          ...l,
          // translate name
          name: t(`loc${l.locationId}.name`),
          // translate description
          description: t(`loc${l.locationId}.description`),
          // update progress for location
          progress: locationsProgress.find(
            (lp) => lp.locationId === l.locationId
          ) ?? { totalSpaces: 1, spacesCollected: 0, locationId: l.locationId },
        })),
      };
    });
  }, [t, unlockedZoneIds, locationsProgress]);

  return (
    <SeasonMap
      width={mapSize.width}
      height={mapSize.height}
      landmarksImage={BuildingsImage}
      scale={scale}
      handleLocationFocus={handleLocationFocus}
      handleLocationSelect={handleLocationSelect}
    >
      {combinedMapData.map((zoneData) => (
        <SeasonMapZone
          key={zoneData.zoneId}
          offset={zoneData.offset}
          pathSVG={zoneData.pathSVG}
          pathColor={zoneData.pathColor}
          width={zoneData.width}
          height={zoneData.height}
          isLocked={zoneData.isLocked}
        >
          {zoneData.locations.map((locationData) => {
            return (
              <SeasonMapLocation
                key={locationData.locationId}
                name={locationData.name}
                description={locationData.description}
                offset={locationData.offset}
                locationId={locationData.locationId}
                progress={locationData.progress}
                previewImageUrl={locationData.previewImageUrl}
              >
                {currentLocationId === locationData.locationId ? (
                  <SeasonMapPin userImg={userImg} />
                ) : (
                  <></>
                )}
              </SeasonMapLocation>
            );
          })}
        </SeasonMapZone>
      ))}
    </SeasonMap>
  );
};
