import { Flex, useMultiStyleConfig } from "@chakra-ui/react";
import React from "react";

import { INavigationHeaderProps } from "adminComponents/molecules/NavigationHeader";
import { TeacherProfileSettingsForm } from "adminComponents/organisms/TeacherProfileSettingsForm";
import { TeacherAccountTemplate } from "adminComponents/templates/TeacherAccountTemplate";
import { ISharingSettings } from "links/lib/types";

export interface IProps {
  navigationData: INavigationHeaderProps;
  isLoading: boolean;
  isFormLoading: boolean;
  settings?: ISharingSettings;
  handleSubmit: (settings: ISharingSettings, customUrlCode: string) => void;
}

export const TeacherProfileSettingsScreen: React.FC<IProps> = ({
  navigationData,
  settings,
  isLoading,
  isFormLoading,
  handleSubmit,
}) => {
  const styles = useMultiStyleConfig("AdminTeacherAccountScreen", {});

  return (
    <TeacherAccountTemplate
      navigationData={navigationData}
      isLoading={isLoading}
    >
      <Flex sx={styles.contentContainer}>
        {settings && (
          <TeacherProfileSettingsForm
            handleSubmit={handleSubmit}
            initialSettings={settings}
            isLoading={isFormLoading}
          />
        )}
      </Flex>
    </TeacherAccountTemplate>
  );
};
