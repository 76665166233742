import { useEffect, useState } from "react";

import {
  CoopDragsortVariantData,
  CoopDrawVariantData,
  IPracticeSetSessionItem,
  IPracticeSetSessionItemMultipleChoiceChoice,
  ISessionDraggableSortItem,
  ISessionGeneratedChoice,
  ISessionRoundGroupState,
  PracticeSessionItemVariantType,
  VariantData,
} from "links/lib/types";

import { getConvertedDrawOptions } from "./useConvertedDrawOptions";

export type ResponseOption =
  | IPracticeSetSessionItemMultipleChoiceChoice
  | ISessionDraggableSortItem
  | ISessionGeneratedChoice;

const getItemResponseOptions = async (
  practiceSetSessionItem: IPracticeSetSessionItem,
  roundGroupState?: ISessionRoundGroupState,
  variantData?: VariantData,
  drawOptionWidth?: number
): Promise<ResponseOption[] | undefined> => {
  const drawVariantData = variantData as CoopDrawVariantData;
  switch (practiceSetSessionItem.variant) {
    case PracticeSessionItemVariantType.CoopChecks:
      return practiceSetSessionItem.multiple_select?.choices;
    case PracticeSessionItemVariantType.CoopRadios:
      return practiceSetSessionItem.multiple_choice?.choices;
    case PracticeSessionItemVariantType.CoopDragsort:
      return (variantData as CoopDragsortVariantData).coop_dragsort.items;
    case PracticeSessionItemVariantType.CoopTextMatch:
    case PracticeSessionItemVariantType.CoopTextMatchNumeric:
      // return unique choices only
      return roundGroupState?.text_match?.choices.filter((c, i) => {
        const firstIndex = roundGroupState?.text_match?.choices.findIndex(
          (c2) => {
            return c.text === c2.text;
          }
        );
        return firstIndex === i;
      });
    case PracticeSessionItemVariantType.CoopDraw:
      return getConvertedDrawOptions(drawVariantData, drawOptionWidth || 0);

    default:
      return [];
  }
};

export const useItemResponseOptions = (
  practiceSetSessionItem: IPracticeSetSessionItem,
  roundGroupState?: ISessionRoundGroupState,
  variantData?: VariantData,
  drawOptionWidth?: number
): ResponseOption[] | undefined => {
  const [itemResponseOptions, setItemResponseOptions] =
    useState<ResponseOption[]>();

  useEffect(() => {
    (async () => {
      setItemResponseOptions(
        await getItemResponseOptions(
          practiceSetSessionItem,
          roundGroupState,
          variantData,
          drawOptionWidth
        )
      );
    })();
  }, [practiceSetSessionItem, roundGroupState, variantData, drawOptionWidth]);

  return itemResponseOptions;
};
