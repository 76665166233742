import { useQueryClient } from "react-query";

import { IUseAsyncReturn, useAsync } from "links/lib/hooks/useAsync";
import { useAxios } from "links/lib/hooks/useAxios";
import { IClassroomJoinLink } from "links/lib/types";

export interface IArgs {
  classroom_id: string;
}

export interface IResult {
  groups_join_link: IClassroomJoinLink;
}

export default function useCreateClassroomJoinLink(): IUseAsyncReturn<
  IResult,
  IArgs
> {
  const axios = useAxios();
  const queryClient = useQueryClient();

  const createLink = async (args: IArgs) => {
    const { classroom_id } = args;

    const response = await axios({
      method: "post",
      url: `/v1/groups-join-links`,
      data: {
        groups_join_link: {
          group_id: classroom_id,
        },
      },
    });

    queryClient.invalidateQueries("onboarding-checklist");
    return response.data as IResult;
  };

  const { isLoading, error, data, execute, isSuccess } = useAsync<
    IResult,
    IArgs
  >(createLink);

  return {
    isLoading,
    error,
    data,
    execute,
    isSuccess,
  };
}
