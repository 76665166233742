import { Grid, GridItem, Image } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { ColorScheme } from "adminComponents";
import { ClampedTooltip } from "adminComponents/atoms/ClampedTooltip";
import { Icon } from "adminComponents/atoms/Icon";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { IMultipleChoiceChoice } from "links/lib/types";
import { RichTextRenderer } from "sharedComponents/atoms/RichTextRenderer";

export interface IProps {
  choices: Array<IMultipleChoiceChoice>;
  forceOneColumn?: boolean;
  handleOpenImageModal?: (imageUrl: string, imageAltText: string) => void;
  color?: ColorScheme;
}

export const AnswerMultipleChoice: React.FC<IProps> = ({
  choices,
  forceOneColumn = false,
  handleOpenImageModal,
  color,
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });

  return (
    <Grid
      templateColumns={
        forceOneColumn
          ? "repeat(19, 1fr)"
          : { base: "repeat(19, 1fr)", md: "repeat(13,1fr)" }
      }
      rowGap={pxToRem(16)}
    >
      {choices.map((choice, index) => (
        <GridItem
          key={index}
          colStart={forceOneColumn ? 1 : { base: 1, md: index % 2 ? 8 : 1 }}
          colEnd={forceOneColumn ? 20 : { base: 20, md: index % 2 ? 14 : 7 }}
          display="flex"
          gap={pxToRem(12)}
        >
          <Icon
            flexShrink={0}
            variant="circleWithBackgroundColor"
            icon={choice.is_correct ? "check" : "close"}
            aria-label={
              choice.is_correct
                ? t("practiceSetQuestionCard.labelCorrect")
                : t("practiceSetQuestionCard.labelIncorrect")
            }
            backgroundColor={
              choice.is_correct
                ? "utility.question-green"
                : "primary.light-gray"
            }
          />
          {choice.image_url ? (
            <ClampedTooltip
              hasArrow
              isDisabled={!choice.image_alt_text}
              label={choice.image_alt_text}
            >
              <Image
                cursor={handleOpenImageModal ? "pointer" : undefined}
                maxH={pxToRem(150)}
                src={choice.image_url}
                alt={choice.image_alt_text}
                onClick={() => {
                  handleOpenImageModal?.(
                    choice.image_url || "",
                    choice.image_alt_text || ""
                  );
                }}
              />
            </ClampedTooltip>
          ) : (
            <Text variant="adminP2" as="div" minW={0} color={color}>
              <RichTextRenderer content={choice.text} />
            </Text>
          )}
        </GridItem>
      ))}
    </Grid>
  );
};
