import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Image,
  SimpleGrid,
} from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Card } from "adminComponents/atoms/Card";
import { Checkbox } from "adminComponents/atoms/Checkbox";
import { FormErrorMessage } from "adminComponents/atoms/FormErrorMessage";
import { Icon } from "adminComponents/atoms/Icon";
import { IconButtonWithTooltip } from "adminComponents/atoms/IconButtonWithTooltip";
import { IconCTAButton } from "adminComponents/atoms/IconCTAButton";
import { Radio } from "adminComponents/atoms/Radio";
import { Switch } from "adminComponents/atoms/Switch";
import { Text } from "adminComponents/atoms/Text";
import { useEditPracticeSetMediaModalContext } from "adminComponents/contexts/EditPracticeSetMediaModalContext";
import { AddMediaModal } from "adminComponents/organisms/AddMediaModal";
import { RichTextEditor } from "adminComponents/organisms/RichTextEditor";
import { isRichTextEmpty } from "adminComponents/organisms/RichTextEditor/util";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { QuestionType, RichValue } from "links/lib/types";
import { generateEmptySlateState } from "links/lib/util";

import { CreateMultipleChoiceTabForm } from "../CreateQuestionMultipleChoiceTab";

export type TResponse = {
  id?: string;
  value: RichValue;
  isCorrect: boolean;
  imageUrl?: string;
  imageAltText?: string;
};

interface IProps {
  isImageMode: boolean;
  label: string;
}

// Checks response choice limits while isolating watch (and re-renders)
// to another hook
export const useResponseLimits = (): {
  canAddResponse: boolean;
} => {
  const { control } = useFormContext<CreateMultipleChoiceTabForm>();
  const responses = useWatch({ control, name: "responses" });

  const canAddResponse = responses.length < 6;

  return useMemo(() => ({ canAddResponse }), [canAddResponse]);
};

export const MultipleResponses: React.FC<IProps> = ({ isImageMode, label }) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const mediaModalProps = useEditPracticeSetMediaModalContext();

  const [responseIndex, setResponseIndex] = useState(0);
  const [responseImageUrl, setResponseImageUrl] = useState("");
  const [responseImageAltText, setResponseImageAltText] = useState("");

  const {
    control,
    setValue,
    trigger,
    watch,
    formState: { errors },
  } = useFormContext<CreateMultipleChoiceTabForm>();

  const responseFields = watch("responses");
  const questionType = watch("questionType");
  const isMultipleAllowed = questionType === QuestionType.MultipleSelect;

  const { append: appendResponse, remove: removeResponse } = useFieldArray({
    control,
    name: "responses",
    rules: {
      validate: {
        minLength2: (v) =>
          v.length >= 2 || (t("createQuestion.errResponsesRequired") as string),
        mustHaveCorrect: (v) =>
          v.some((v) => v.isCorrect) ||
          (t("createQuestion.errNoCorrectResponse") as string),
        mustHaveImage: (v) =>
          !isImageMode ||
          v.every((v) => v.imageUrl) ||
          (t("createQuestion.errMissingImageUrl") as string),
      },
    },
  });

  const { canAddResponse } = useResponseLimits();

  const handleAddNewResponse = () => {
    appendResponse({
      isCorrect: false,
      value: generateEmptySlateState(),
    });
  };

  const handleRemoveResponse = (index: number) => {
    removeResponse(index);
  };

  const changeMultipleAllowed = (checked: boolean) => {
    setValue(
      "questionType",
      checked ? QuestionType.MultipleSelect : QuestionType.MultipleChoice
    );
    responseFields.forEach((_, i) => {
      setValue(`responses.${i}.isCorrect`, false);
    });
    trigger("responses");
  };

  const handleAddImageResponse = () => {
    setResponseIndex(responseFields.length);
    setResponseImageUrl("");
    setResponseImageAltText("");
    mediaModalProps.handleOpen();
  };

  const handleImageResponseClick = (index: number) => {
    setResponseIndex(index);
    setResponseImageUrl(responseFields[index].imageUrl || "");
    setResponseImageAltText(responseFields[index].imageAltText || "");
    mediaModalProps.handleOpen();
  };

  const _handleInsertMedia = (imageUrl: string, imageAltText: string) => {
    if (responseFields[responseIndex]) {
      setValue(`responses.${responseIndex}.imageUrl`, imageUrl);
      setValue(`responses.${responseIndex}.imageAltText`, imageAltText);
    } else {
      appendResponse({
        imageUrl,
        imageAltText,
        isCorrect: false,
        value: generateEmptySlateState(),
      });
    }

    mediaModalProps.handleInsertMedia(imageUrl, imageAltText);
    mediaModalProps.handleClose();
  };

  const handleRemoveResponseImage = () => {
    setResponseImageUrl("");
    setResponseImageAltText("");
    mediaModalProps.handleRemoveImage();
  };

  const handleAddMediaModalCancel = () => {
    handleRemoveResponseImage();
    setResponseImageAltText("");
    mediaModalProps.handleClose();
  };

  const isInvalid = !!errors.responses;

  return (
    <>
      <FormControl isRequired variant="adminFormControl" isInvalid={isInvalid}>
        <Flex
          flexDir={["column", null, "row"]}
          alignItems={["flex-start", null, "center"]}
          mb={[pxToRem(20), null, pxToRem(16)]}
        >
          <FormLabel
            hidden
            htmlFor="isMultipleAllowed"
            id="isMultipleAllowedLabel"
          >
            {t("createQuestion.enableMultipleCorrectResponses")}
          </FormLabel>
          <Text
            variant="adminP2"
            color={isInvalid ? "utility.error" : "primary.warm-black"}
          >
            {label}*
          </Text>
          <Flex ml={[pxToRem(0), null, "auto"]} width={["full", null, "auto"]}>
            <Text
              variant="adminP2"
              color="primary.dark-gray"
              mr={["auto", null, pxToRem(16)]}
            >
              {t("createQuestion.enableMultipleCorrectResponses")}
            </Text>
            <Switch
              aria-labelledby="isMultipleAllowedLabel"
              id="isMultipleAllowed"
              isChecked={isMultipleAllowed}
              onChange={(e) => changeMultipleAllowed(e.target.checked)}
            />
          </Flex>
        </Flex>
        <SimpleGrid gap={pxToRem(12)}>
          {responseFields.map((response, index) =>
            isImageMode ? (
              <ImageModeResponse
                key={response.id}
                index={index}
                isMultipleAllowed={isMultipleAllowed}
                handleImageResponseClick={handleImageResponseClick}
                handleRemoveResponse={handleRemoveResponse}
              />
            ) : (
              <TextModeResponse
                key={response.id}
                index={index}
                isMultipleAllowed={isMultipleAllowed}
                handleRemoveResponse={handleRemoveResponse}
              />
            )
          )}
        </SimpleGrid>
        {isImageMode && canAddResponse && (
          <Box marginLeft={[0, null, "auto"]} mt={pxToRem(20)}>
            <Button
              variant="adminButtonOutlined"
              onClick={handleAddImageResponse}
              size="sm"
              leftIcon={<Icon icon="add" color="primary.warm-black" />}
            >
              {t("createQuestion.addImageResponse")}
            </Button>
          </Box>
        )}
        {!isImageMode && canAddResponse && (
          <Box
            mt={pxToRem(12)}
            pl={[
              pxToRem(36),
              null,
              isMultipleAllowed ? pxToRem(36) : pxToRem(44),
            ]}
            pr={pxToRem(44)}
          >
            <Card
              variant="adminCardSmallBorderSmallPadding"
              backgroundColor="primary.white"
              borderColor="primary.tan"
              width="full"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Button
                variant="adminButtonOutlined"
                leftIcon={
                  <Icon iconColor="primary.warm-black" icon="add_outlined" />
                }
                onClick={handleAddNewResponse}
              >
                {t("createMultipleChoiceFlyout.addAnotherResponse")}
              </Button>
            </Card>
          </Box>
        )}
        <FormErrorMessage>{errors.responses?.root?.message}</FormErrorMessage>
      </FormControl>
      <AddMediaModal
        {...mediaModalProps}
        imageOnly
        imageUrlToAdd={responseImageUrl}
        imageAltText={responseImageAltText}
        handleInsertMedia={_handleInsertMedia}
        handleRemoveImage={handleRemoveResponseImage}
        handleClose={handleAddMediaModalCancel}
      />
    </>
  );
};

interface ITextModeResponseProps {
  index: number;
  isMultipleAllowed: boolean;
  handleRemoveResponse: (index: number) => void;
}

const TextModeResponse: React.FC<ITextModeResponseProps> = ({
  index,
  isMultipleAllowed,
  handleRemoveResponse,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const { control, getValues, setValue, trigger } =
    useFormContext<CreateMultipleChoiceTabForm>();

  const key = isMultipleAllowed
    ? "labelCheckboxAnswerChoice"
    : "labelRadioAnswerChoice";
  const label = t(`createQuestion.${key}`, { index: index + 1 });

  return (
    <Flex gap={pxToRem(12)} alignItems="center" minW={0} width="full">
      <Controller
        name={`responses.${index}.isCorrect`}
        control={control}
        render={({ field: { onChange, onBlur, value, ref } }) =>
          isMultipleAllowed ? (
            <Checkbox
              inputProps={{ "aria-label": label }}
              ref={ref}
              onChange={(e) => {
                onChange(e.target.checked);
                trigger("responses");
              }}
              onBlur={onBlur}
              isChecked={value}
            />
          ) : (
            <Radio
              inputProps={{ "aria-label": label }}
              ref={ref}
              onBlur={onBlur}
              isChecked={value}
              onChange={(e) => {
                onChange(e.target.checked);
                getValues().responses.forEach((_, i) => {
                  if (i !== index) {
                    setValue(`responses.${i}.isCorrect`, false);
                  }
                });
                trigger("responses");
              }}
            />
          )
        }
      />

      <Box flex="1" minW="0">
        <Controller
          control={control}
          name={`responses.${index}.value`}
          rules={{
            validate: {
              isNotEmpty: (v) =>
                !isRichTextEmpty(v) ||
                (t("createQuestion.errMissingResponseLabel") as string),
            },
          }}
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { isTouched, error },
          }) => (
            <RichTextEditor
              aria-label={t("createQuestion.enterAnswer")}
              inline
              name={name}
              value={value}
              ref={ref}
              handleChangeRaw={onChange}
              placeholder={t("createQuestion.enterAnswer")}
              handleBlur={onBlur}
              hasError={isTouched && !!error}
            />
          )}
        />
      </Box>
      <IconButtonWithTooltip
        icon="delete_outlined"
        shape="type1"
        onClick={() => handleRemoveResponse(index)}
        ariaLabel={t("common.delete")}
        tooltipProps={{ popoverVariant: "default" }}
      />
    </Flex>
  );
};

interface IImageModeResponseProps {
  index: number;
  isMultipleAllowed: boolean;
  handleImageResponseClick: (index: number) => void;
  handleRemoveResponse: (index: number) => void;
}

const ImageModeResponse: React.FC<IImageModeResponseProps> = ({
  index,
  isMultipleAllowed,
  handleImageResponseClick,
  handleRemoveResponse,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const { control, getValues, setValue, trigger, watch } =
    useFormContext<CreateMultipleChoiceTabForm>();

  const [imageUrl, imageAltText] = watch([
    `responses.${index}.imageUrl`,
    `responses.${index}.imageAltText`,
  ]);

  const key = isMultipleAllowed
    ? "labelCheckboxAnswerChoice"
    : "labelRadioAnswerChoice";
  const label = t(`createQuestion.${key}`, { index: index + 1 });

  return (
    <Flex gap={pxToRem(12)} alignItems="center" flexDir="column">
      <Flex w="full" gap={pxToRem(12)} alignItems="center">
        <Controller
          name={`responses.${index}.isCorrect`}
          control={control}
          render={({ field: { onChange, onBlur, value, ref } }) =>
            isMultipleAllowed ? (
              <Checkbox
                inputProps={{ "aria-label": label }}
                ref={ref}
                onChange={(e) => {
                  onChange(e.target.checked);
                  trigger("responses");
                }}
                onBlur={onBlur}
                isChecked={value}
              />
            ) : (
              <Radio
                inputProps={{ "aria-label": label }}
                ref={ref}
                onBlur={onBlur}
                isChecked={value}
                onChange={(e) => {
                  onChange(e.target.checked);
                  getValues().responses.forEach((_, i) => {
                    if (i !== index) {
                      setValue(`responses.${i}.isCorrect`, false);
                    }
                  });
                  trigger("responses");
                }}
              />
            )
          }
        />
        <Image
          w="86%"
          objectFit="contain"
          maxH="20vh"
          borderRadius="lg"
          src={imageUrl}
          alt={imageAltText}
          cursor="pointer"
          onClick={() => handleImageResponseClick(index)}
        />
        <IconCTAButton
          icon="close"
          ariaLabel={t("createQuestion.removeImage")}
          colorScheme="primary.white"
          onClick={() => handleRemoveResponse(index)}
          type="button"
        />
      </Flex>
    </Flex>
  );
};
