import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Modal } from "adminComponents/atoms/Modal";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { GoogleClassroomClassroom, IClassroom } from "links/lib/types";

interface GoogleClassroomImportModalProps {
  isOpen: boolean;
  googleClassroom?: GoogleClassroomClassroom;
  classroom: IClassroom;
  handleConfirm: () => void;
  handleClose: () => void;
  cancelText?: string;
}

export const GoogleClassroomLinkConfirmModal: React.FC<
  GoogleClassroomImportModalProps
> = ({
  isOpen,
  googleClassroom,
  classroom,
  handleConfirm,
  handleClose,
  cancelText,
}) => {
  const { t } = useTranslation("admin", {
    keyPrefix: "classroomLinkConfirm",
    useSuspense: false,
  });

  return (
    <Modal
      title={t("title")}
      onClose={handleClose}
      isOpen={isOpen}
      size="2xl"
      showBackButton={false}
    >
      <Box mb={pxToRem(40)}>
        <Text fontSize={pxToRem(18)}>{t("description")}</Text>
      </Box>
      <Flex
        w="100%"
        justifyContent={"center"}
        mb={pxToRem(40)}
        gap={pxToRem(12)}
      >
        <Box backgroundColor="primary.tan" w="40%" borderRadius="lg">
          <Flex flexDirection="column" m={[pxToRem(16), null, pxToRem(10)]}>
            <Box mb={pxToRem(10)}>{classroom.name}</Box>
            <Box>
              <Text variant="adminP2" color="primary.dark-gray">
                {t("gsClass")}
              </Text>
            </Box>
          </Flex>
        </Box>
        <Box backgroundColor="primary.tan" w="40%" borderRadius="lg">
          <Flex flexDirection="column" m={[pxToRem(16), null, pxToRem(10)]}>
            <Box mb={pxToRem(10)}>{googleClassroom?.name}</Box>
            <Box>
              <Text variant="adminP2" color="primary.dark-gray">
                {t("googleClass")}
              </Text>
            </Box>
          </Flex>
        </Box>
      </Flex>
      <Flex justifyContent={"center"} gap={pxToRem(12)}>
        <Button size="lg" variant="adminButtonFilled" onClick={handleConfirm}>
          {t("confirm")}
        </Button>
        <Button size="lg" variant="adminButtonOutlined" onClick={handleClose}>
          {cancelText ? cancelText : t("decline")}
        </Button>
      </Flex>
    </Modal>
  );
};
