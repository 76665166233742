import { Box, useBreakpointValue } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Icon } from "adminComponents/atoms/Icon";
import { IconButton } from "adminComponents/atoms/IconButton";
import { Tabs } from "adminComponents/atoms/Tabs";
import {
  ClassroomAssignmentReportHeroCard,
  IProps as ClassroomAssignmentReportHeroCardProps,
} from "adminComponents/molecules/ClassroomAssignmentReportHeroCard";
import {
  INavigationHeaderProps,
  NavigationHeader,
} from "adminComponents/molecules/NavigationHeader";
import { TemplateWithCenteredHeroOneColumn } from "adminComponents/templates/TemplateWithCenteredHeroOneColumn";
import { pxToRem } from "adminComponents/utils";
import { AssignmentDetailTab } from "screens/TeacherClassrooms/AssignmentDetail";

type ClassroomAssignmentReportHeroData = Omit<
  ClassroomAssignmentReportHeroCardProps,
  "reassignHandler" | "deleteHandler"
>;

export interface ClassroomAssignmentReportScreenProps {
  navigationData: INavigationHeaderProps;
  assignmentReportData: ClassroomAssignmentReportHeroData;
  isLoading?: boolean;
  handleTabChange: (tab: AssignmentDetailTab) => void;
  tabIndex?: number;
  showPremiumMarker?: boolean;
  handleDownloadCsv?: () => void;
  downloadedCsv?: boolean;
}

export const ClassroomAssignmentReportScreen: React.FC<
  ClassroomAssignmentReportScreenProps
> = ({
  navigationData,
  isLoading,
  assignmentReportData,
  children,
  handleTabChange,
  tabIndex,
  showPremiumMarker = true,
  handleDownloadCsv,
  downloadedCsv,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const nav = <NavigationHeader isLoading={isLoading} {...navigationData} />;
  const heroContent = (
    <ClassroomAssignmentReportHeroCard {...assignmentReportData} />
  );
  const useIconDownloadButton = useBreakpointValue({ base: true, md: false });

  return (
    <TemplateWithCenteredHeroOneColumn
      nav={nav}
      heroContent={heroContent}
      variant="default"
      isLoading={isLoading}
      isHeroShifted
    >
      <Tabs
        variant="adminScreenTabs"
        tabIndex={tabIndex}
        handleChange={handleTabChange}
        tabData={[
          {
            label: t("classroomAssignmentReport.questions"),
            content: <></>,
          },
          {
            label: t("classroomAssignmentReport.studentPerformance"),
            content: <></>,
          },
          {
            label: t("classroomAssignmentReport.standards"),
            isPremium: showPremiumMarker,
            content: <></>,
          },
        ]}
        ctaButtonRight={
          tabIndex === AssignmentDetailTab.StudentPerfomance &&
          handleDownloadCsv ? (
            useIconDownloadButton ? (
              <Box alignSelf="center" boxSize={pxToRem(24)} ml={pxToRem(20)}>
                <IconButton
                  onClick={handleDownloadCsv}
                  icon={downloadedCsv ? "check_outlined" : "download"}
                  ariaLabel={t("common.downloadCsv")}
                  shape="circle"
                />
              </Box>
            ) : (
              <Button
                onClick={handleDownloadCsv}
                alignSelf="center"
                size="sm"
                variant="adminButtonFilled"
                rightIcon={
                  downloadedCsv ? <Icon icon="check_outlined" /> : undefined
                }
              >
                {t("common.downloadCsv")}
              </Button>
            )
          ) : undefined
        }
      />
      {!isLoading && children}
    </TemplateWithCenteredHeroOneColumn>
  );
};
