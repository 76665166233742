import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { usePrevious } from "react-use";

import { TemplateWithVerticalCenteredContent } from "adminComponents/templates/TemplateWithVerticalCenteredContent";
import { useShowToast } from "adminComponents/utils/toast";
import { usePageTitle } from "lib/hooks/usePageTitle";
import { useRemoveAuth } from "links/lib/features/auth";

import { removeChatbox } from "../../lib/salesforceChat";

const SignOut: React.FC = () => {
  const { isLoading } = useRemoveAuth();
  const showToast = useShowToast();
  const { t } = useTranslation("admin", {
    keyPrefix: "signOutContainer",
    useSuspense: false,
  });
  usePageTitle(t("pageTitle"));

  const prevIsLoading = usePrevious(isLoading);

  removeChatbox();

  useEffect(() => {
    if (prevIsLoading && !isLoading) {
      showToast(t("successToast"));
    }
  }, [prevIsLoading, isLoading, showToast, t]);

  return (
    <TemplateWithVerticalCenteredContent isLoading={isLoading}>
      {!isLoading && <Redirect to="/sign-in" />}
    </TemplateWithVerticalCenteredContent>
  );
};

export default SignOut;
