import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { SocialEvent } from "../../types";

export interface ISocialEventsResponse {
  social_events: Array<SocialEvent>;
}

export default function useFetchSocialEvents(): UseQueryResult<ISocialEventsResponse> {
  const axios = useAxios();

  const query = useQuery<ISocialEventsResponse>(
    ["socialEvents"],
    async (): Promise<ISocialEventsResponse> => {
      return await axios.get("/v1/social/events").then((r) => {
        const data = r.data as ISocialEventsResponse;
        data.social_events.sort((a, b) => {
          return a.id < b.id ? -1 : a.id > b.id ? 1 : 0;
        });
        return data;
      });
    }
  );

  return query;
}
