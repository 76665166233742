import { Box, ToastId, useStyleConfig, useToast } from "@chakra-ui/react";
import React, { ReactNode, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { IconCTAButton } from "adminComponents/atoms/IconCTAButton";
import { Text } from "adminComponents/atoms/Text";

interface IProps {
  children: ReactNode;
  toastId?: ToastId;
}

const Toast: React.FC<IProps> = ({ children, toastId }) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });
  const toast = useToast();
  const styles = useStyleConfig("AdminToast", {});

  const close = useCallback(() => {
    if (toastId) {
      toast.close(toastId);
    }
  }, [toast, toastId]);

  return (
    <Box __css={styles}>
      <Text variant="adminP1" color="primary.white">
        {children}
      </Text>
      <IconCTAButton
        ariaLabel={t("toast.ariaLabel")}
        variant="dark"
        onClick={close}
      />
    </Box>
  );
};

export { Toast };
