import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";

export interface IFetchOrganizationPracticeMinutesParams {
  days_ago?: number;
  include_top_org_check?: boolean;
  refetchInterval?: number;
}

export interface IFetchOrganizationPracticeMinutesResponse {
  practice_minutes: number;
  top_half_of_all_organizations: boolean;
  top_third_of_all_organizations: boolean;
}

export default function useFetchOrganizationPracticeMinutes({
  days_ago = 30,
  include_top_org_check = false,
  refetchInterval,
}: IFetchOrganizationPracticeMinutesParams): UseQueryResult<
  IFetchOrganizationPracticeMinutesResponse,
  unknown
> {
  const axios = useAxios();

  const query = useQuery<IFetchOrganizationPracticeMinutesResponse>(
    ["org-practice-minutes", { days_ago, include_top_org_check }],
    async (): Promise<IFetchOrganizationPracticeMinutesResponse> => {
      return await axios
        .get(`/v1/organizations/practice-sets/sessions/practice-minutes`, {
          params: {
            days_ago,
            include_top_org_check,
          },
        })
        .then((r) => {
          return r.data as IFetchOrganizationPracticeMinutesResponse;
        });
    },
    { refetchInterval }
  );

  return query;
}
