import { IUseAsyncReturn, useAsync } from "../../hooks/useAsync";
import { useAxios } from "../../hooks/useAxios";
import { IStandard } from "../../types";

export interface ISearchStandardDataOnceParams {
  term?: string;
  country?: string;
  region?: string;
  subject_ids?: Array<string>;
  grade_level_ids?: Array<string>;
  standard_collection_id?: string;
  page?: number;
  per_page?: number;
}

export interface ISearchStandardDataOnceResponse {
  standards: Array<IStandard>;
  total_count: number;
  page: number;
  per_page: number;
}

export interface IUseSearchStandardDataOnce {
  onSuccess?: (data: ISearchStandardDataOnceResponse) => void;
  onError?: () => void;
}

export default function useSearchStandardDataOnce(
  props?: IUseSearchStandardDataOnce
): IUseAsyncReturn<
  ISearchStandardDataOnceResponse,
  ISearchStandardDataOnceParams
> {
  const axios = useAxios();

  const search = async (params: ISearchStandardDataOnceParams) => {
    return await axios
      .get(`/v1/search/standard-data`, {
        params: {
          ...params,
          standard_collection_id: params.standard_collection_id || "0",
        },
      })
      .then((r) => r.data as ISearchStandardDataOnceResponse);
  };

  const { isLoading, error, data, execute, isSuccess } = useAsync<
    ISearchStandardDataOnceResponse,
    ISearchStandardDataOnceParams
  >(search, {
    onSuccess: props?.onSuccess,
    onError: props?.onError,
  });

  return {
    isLoading,
    error,
    data,
    execute,
    isSuccess,
  };
}
