// for classification questions
import { Square } from "@chakra-ui/react";
import React from "react";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { ISelectableOptionProps } from "sessionComponents/types";

import { GenericImageOption } from "./GenericImageOption";
import { GenericTextOption } from "./GenericTextOption";

export const ClassificationOption: React.FC<ISelectableOptionProps> = (
  props
) => {
  const { text, imageUrl, imageAltText, disabled } = props;
  const { match: currentBreakpoints } = useBreakpoints();

  if (text)
    return (
      <GenericTextOption
        {...props}
        text={text}
        backgroundColor={disabled ? "primary.warm-white" : undefined}
        fontColor={disabled ? "primary.dark-gray" : undefined}
      />
    );
  else if (imageUrl)
    return (
      <Square
        size={pxToRem(currentBreakpoints.imageResponseSize)}
        maxWidth="100%"
        maxHeight="100%"
      >
        <GenericImageOption
          {...props}
          imageUrl={imageUrl}
          imageAltText={imageAltText || ""}
          imageButtonBottomPosition={pxToRem(currentBreakpoints.padding / 4)}
          imageButtonRightPosition={pxToRem(currentBreakpoints.padding / 4)}
          imageButtonBoxSize={pxToRem(currentBreakpoints.iconSize)}
          imageButtonIconSize={pxToRem(currentBreakpoints.iconSize / 1.7)}
          backgroundColor={disabled ? "primary.warm-white" : undefined}
          width="100%"
          height="100%"
        />
      </Square>
    );
  else return <></>;
};
