import React from "react";

import { AudioProvider, useAudio } from "./audio";

export interface ISessionAudioProviderProps {
  spriteSrc: Array<string>;
  srcVolume: number;
  sprite: {
    [key: string]: (number | boolean)[];
  };
  backgroundClips?: {
    [key: string]: Array<{ src: string; type: string }>;
  };
}

const SESSION_GLOBAL_VOLUME_KEY = "SESSION_GLOBAL_VOLUME";

export const SessionAudioProvider: React.FC<ISessionAudioProviderProps> = ({
  children,
  spriteSrc,
  sprite,
  srcVolume,
  backgroundClips,
}) => {
  return (
    <AudioProvider
      spriteSrc={spriteSrc}
      sprite={sprite}
      srcVolume={srcVolume}
      backgroundClips={backgroundClips}
      volumeStorageKey={SESSION_GLOBAL_VOLUME_KEY}
    >
      {children}
    </AudioProvider>
  );
};

export const useSessionAudio = useAudio;
