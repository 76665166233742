import { Box } from "@chakra-ui/react";
import React from "react";
import { animated, config, useSpring } from "react-spring";

import { useDetectOrientation } from "sessionComponents/theme/hooks/useDetectOrientation";

import Confetti from "./resource/confetti.svg";

const AnimatedBox = animated(Box);

export const AnimatedConfetti: React.FC = () => {
  const { isPortrait } = useDetectOrientation();

  const confettiSpring = useSpring({
    from: {
      backgroundPosition: "0 -100%",
    },
    to: {
      backgroundPosition: "0 100%",
    },
    config: config.molasses,
    loop: true,
  });

  return (
    <Box
      position="absolute"
      top="0"
      left="0"
      right="0"
      bottom="0"
      h="50%"
      w="full"
      zIndex={1}
    >
      <AnimatedBox
        style={confettiSpring}
        bgImage={Confetti}
        bgRepeat="repeat"
        bgSize={isPortrait ? "60%" : "40%"}
        h="full"
        w="full"
        sx={{
          WebkitMask: "linear-gradient(#000, #0000)",
        }}
        mask="linear-gradient(#000, #0000)"
      />
    </Box>
  );
};
