import { Flex, Text, useBreakpointValue } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { usePrevious } from "react-use";

import {
  CleverSignInButton,
  GoogleSignInButton,
  MicrosoftSignInButton,
} from "adminComponents/atoms/ExternalAccountButton";
import { Heading } from "adminComponents/atoms/Heading";
import { Modal } from "adminComponents/atoms/Modal";
import { pxToRem } from "adminComponents/utils";
import i18n from "lib/i18n";
import { localStoreAuthKeyName } from "links/lib/constants";
import { useAuth } from "links/lib/features/auth";
import { useAxios } from "links/lib/hooks/useAxios";
import { UserRole } from "links/lib/types";
import useFetchProviderRedirectUrl from "screens/SignIn/useFetchProviderRedirectUrl";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { openSignInPopup } from "sessionComponents/utils/openSignInPopup";

interface SignInProviderModalProps {
  joinCode: string;
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
}

const SignInProviderModal: React.FC<SignInProviderModalProps> = ({
  joinCode,
  isOpen,
  onClose,
  onSuccess,
}) => {
  const { setAuthToken } = useAuth();
  const axios = useAxios();
  const { match: currentBreakpoints } = useBreakpoints();
  const { t } = useTranslation("session", {
    useSuspense: false,
  });

  const padding = useBreakpointValue({ base: 24, sm: 24, md: 28 }) || 24;
  const { isLoading, error, data, execute } = useFetchProviderRedirectUrl();

  const prevRedirectUrl = usePrevious(data?.redirectUrl);

  useEffect(() => {
    if (error) console.error(error);
  }, [error]);

  useEffect(() => {
    if (data?.redirectUrl && prevRedirectUrl !== data.redirectUrl) {
      openSignInPopup({
        url: data.redirectUrl,
        onSignInSuccess: () => {
          const newToken = localStorage.getItem(localStoreAuthKeyName);
          if (!newToken) {
            console.error(
              "Successful sign in reported by popup, but new token not found in local storage."
            );
            return;
          }

          setAuthToken(newToken);

          // update axios client to use new JWT
          // for authorization header on all requests
          axios.defaults.headers.common["Authorization"] = newToken;

          onSuccess?.();
          onClose();
        },
      });
    }
  }, [
    axios.defaults.headers.common,
    data?.redirectUrl,
    prevRedirectUrl,
    onClose,
    onSuccess,
    setAuthToken,
  ]);

  const onSignIn = (provider: "Google" | "Clever" | "Microsoft") => {
    execute({
      provider,
      role: UserRole.Student,
      join_code: joinCode,
      language: i18n.language,
      popup: true,
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} showBackButtonAllSizes>
      <Flex
        alignItems="start"
        flexDirection="column"
        mt={[pxToRem(32), null, pxToRem(48)]}
        p={pxToRem(padding)}
        gap={pxToRem(padding / 2)}
      >
        <Heading as="h1" variant="adminH2">
          {t("common.signIn")}
        </Heading>
        <Text
          as="span"
          textStyle="gameText"
          fontSize={pxToRem(currentBreakpoints.fontSize / 1.5)}
        >
          {t("studentLobbyLight.signInModalDescription")}
        </Text>
        <Flex
          w="full"
          flexDirection="column"
          gap={pxToRem(padding / 2)}
          mt={pxToRem(padding)}
        >
          <GoogleSignInButton
            handleOnButtonClick={() => onSignIn("Google")}
            isLoading={isLoading}
          />
          <CleverSignInButton
            handleOnButtonClick={() => onSignIn("Clever")}
            isLoading={isLoading}
          />
          <MicrosoftSignInButton
            handleOnButtonClick={() => onSignIn("Microsoft")}
            isLoading={isLoading}
          />
        </Flex>
      </Flex>
    </Modal>
  );
};

export default SignInProviderModal;
