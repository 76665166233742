import { useMultiStyleConfig } from "@chakra-ui/react";
import React from "react";

import { Card } from "adminComponents/atoms/Card";
import {
  CoverImageBGColorSchemeType,
  CoverImageBGPatternType,
} from "links/lib/types";

import { useCoverImageBackground } from "../CoverImageBackground/hooks/useCoverImageBackground";

export interface IAvatarPhotoBackgroundCardProps {
  bgPattern: CoverImageBGPatternType;
  bgColorScheme: CoverImageBGColorSchemeType;
  isSelected?: boolean;
  handleClick?: (pg: CoverImageBGPatternType) => void;
}

export const AvatarPhotoBackgroundCard: React.FC<
  IAvatarPhotoBackgroundCardProps
> = ({ isSelected = false, handleClick, bgPattern, bgColorScheme }) => {
  const styles = useMultiStyleConfig("AdminAvatarPhotoBackgroundCard", {
    isSelected,
  });

  const { tileImage } = useCoverImageBackground({
    pattern: bgPattern,
    colorScheme: bgColorScheme,
    tileSize: 100,
  });

  return (
    <Card
      as="button"
      sx={styles.card}
      variant="adminCardSelectable"
      borderColor="primary.tan"
      backgroundColor="primary.white"
      isSelected={isSelected}
      onClick={() => handleClick?.(bgPattern)}
      aria-label={bgPattern}
      aria-pressed={isSelected}
      backgroundImage={tileImage ? `url(${tileImage})` : undefined}
      backgroundRepeat="repeat"
    />
  );
};
