import React, { createContext, useMemo } from "react";

import {
  GameBackgroundColorScheme,
  GameBackgroundStyle,
  ISessionRoundTheme,
} from "../types";

export interface ISessionRoundThemeContext {
  roundTheme: ISessionRoundTheme;
}

export const SessionRoundThemeContext =
  createContext<ISessionRoundThemeContext>({
    roundTheme: {
      boxOutlineColor: "red",
      splashPrimaryColor: "red",
      splashSecondaryColor: "red",
      buttonColorScheme: "red",
      backgroundColorScheme: GameBackgroundColorScheme.Amber,
      backgroundStyle: GameBackgroundStyle.Bricks,
    },
  });

export const SessionRoundThemeProvider: React.FC<{
  roundTheme: ISessionRoundTheme;
}> = ({ roundTheme, children }) => {
  const value = useMemo(() => ({ roundTheme }), [roundTheme]);

  return (
    <SessionRoundThemeContext.Provider value={value}>
      {children}
    </SessionRoundThemeContext.Provider>
  );
};
