import {
  Box,
  Flex,
  Grid,
  Image,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Card } from "adminComponents/atoms/Card";
import { Divider } from "adminComponents/atoms/Divider";
import { Heading } from "adminComponents/atoms/Heading";
import { IconTooltip } from "adminComponents/atoms/IconTooltip";
import { Modal } from "adminComponents/atoms/Modal";
import { ProgressBar } from "adminComponents/atoms/ProgressBar";
import { Text } from "adminComponents/atoms/Text";
import { AvatarStreak } from "adminComponents/molecules/AvatarStreak";
import { GoalsProgressBar } from "adminComponents/molecules/GoalsProgressBar";
import { generateStudentName } from "adminComponents/utils/generateStudentName";
import { pxToRem } from "adminComponents/utils/pxToRem";
import {
  IPeriodicChallenges,
  IPracticeSessionsStreak,
  IUser,
} from "links/lib/types";

interface IProps {
  periodicChallenges: IPeriodicChallenges;
  practiceSessionStreak: IPracticeSessionsStreak;
  monthlyChallengeRemainingDays?: number;
  user: IUser;
  headingLevel?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p";
  subHeadingLevel?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p";
  handleEditAvatar: () => void;
}

export const StudentXpSummaryCard: React.FC<IProps> = ({
  periodicChallenges,
  practiceSessionStreak,
  monthlyChallengeRemainingDays,
  user,
  headingLevel,
  subHeadingLevel,
  handleEditAvatar,
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });

  const {
    isOpen: isMonthlyChallengeModalOpen,
    onOpen: onMonthlyChallengeModalOpen,
    onClose: onMonthlyChallengeModalClose,
  } = useDisclosure();

  const {
    daily_challenge: dailyChallenge,
    weekly_challenge: weeklyChallenge,
    monthly_challenge: monthlyChallenge,
  } = periodicChallenges;
  const streakCount = practiceSessionStreak.week_count;
  const profileImage = user.profile_image_url;
  const name = generateStudentName(user).primary;
  const streakSegment = Math.min(
    Math.max(Math.floor(practiceSessionStreak.current_week_day_count), 0),
    3
  ) as 0 | 1 | 2 | 3;

  const avatarSize = useBreakpointValue({
    base: "sm",
    md: "md",
  });

  const month = useMemo(() => {
    const now = new Date();
    return now.toLocaleString("default", { month: "long" });
  }, []);

  return (
    <>
      <Card
        borderColor="primary.tan"
        backgroundColor="primary.white"
        variant="adminCardMediumBorderMediumPadding"
        marginTop={{ base: pxToRem(52.5), md: pxToRem(67.5) }}
        paddingTop={
          profileImage
            ? { base: pxToRem(78), md: pxToRem(90) }
            : { base: pxToRem(55), md: pxToRem(70) }
        }
      >
        <Box
          position="absolute"
          top="0"
          left="50%"
          transform="translateX(-50%) translateY(-50%)"
        >
          <AvatarStreak
            activeSegment={streakSegment}
            size={avatarSize}
            src={profileImage}
            name={name}
            showSilhouetteAsFallback
          />
        </Box>
        {!profileImage && (
          <Flex justify="center" marginBottom={pxToRem(18)}>
            <Button variant="adminTextButtonMedium" onClick={handleEditAvatar}>
              {t("common.editAvatar")}
            </Button>
          </Flex>
        )}
        <Heading
          as={headingLevel ? headingLevel : "p"}
          variant="adminH6Bold"
          marginBottom={pxToRem(24)}
          textAlign="center"
        >
          {streakCount < 1
            ? t("studentXpSummaryCard.streakNullHeading")
            : t("studentXpSummaryCard.streakHeading", { streakCount })}
        </Heading>
        <Divider color="primary.light-gray" marginBottom={pxToRem(24)} />
        <Flex marginBottom={pxToRem(8)} alignItems="center" gap={pxToRem(5)}>
          <Heading
            variant="adminH7Bold"
            as={subHeadingLevel ? subHeadingLevel : "p"}
          >
            {t("studentXpSummaryCard.challengesHeading")}
          </Heading>
          <IconTooltip>
            {t("studentXpSummaryCard.challengesHeadingTooltip")}
          </IconTooltip>
        </Flex>
        <Grid
          templateColumns="1fr 1fr"
          gap={pxToRem(24)}
          marginBottom={pxToRem(24)}
        >
          <GoalsProgressBar challenge={dailyChallenge} />
          <GoalsProgressBar challenge={weeklyChallenge} />
        </Grid>
        {!!monthlyChallenge && (
          <>
            <Divider color="primary.light-gray" marginBottom={pxToRem(24)} />
            <GoalsProgressBar
              challenge={monthlyChallenge}
              remainingDays={monthlyChallengeRemainingDays}
              handleMonthlyChallengeModalOpen={onMonthlyChallengeModalOpen}
              hasIcon
            />
          </>
        )}
      </Card>
      {!!monthlyChallenge && (
        <Modal
          showBackButton={false}
          isOpen={isMonthlyChallengeModalOpen}
          onClose={onMonthlyChallengeModalClose}
          modalContentProps={{
            borderRadius: "xl",
          }}
        >
          <Box
            paddingTop={{ base: "admin.cards.small", md: "admin.cards.medium" }}
          >
            <Heading as="p" variant="adminH7">
              {t("studentXpSummaryCard.monthChallenge", { month })}
            </Heading>
            <Heading as="h4" variant="adminH4">
              {monthlyChallenge.title}
            </Heading>
            <Image
              borderRadius="lg"
              src={monthlyChallenge.cover_image_url}
              marginY={pxToRem(15)}
            />
            <Text variant="adminP2">{monthlyChallenge.description}</Text>
            <Box marginTop={pxToRem(15)}>
              <ProgressBar
                h={pxToRem(25)}
                borderRightRadius={0}
                variant="adminStudentMonthlyChallenge"
                value={monthlyChallenge.awarded_xp}
                max={monthlyChallenge.xp_threshold}
                icon={{
                  boxSize: pxToRem(60),
                  iconName: "star_outlined",
                  iconSrc:
                    monthlyChallenge.reward_avatar_item
                      ?.preview_image_asset_url ||
                    monthlyChallenge.reward_avatar_item_group
                      ?.preview_image_asset_url,
                }}
                size="lg"
              />
              <Text variant="adminP2">
                {t("common.xpProgress", {
                  awarded: Math.min(
                    monthlyChallenge.awarded_xp,
                    monthlyChallenge.xp_threshold
                  ),
                  threshold: monthlyChallenge.xp_threshold,
                })}
                &nbsp;
                {t("common.earned")}
              </Text>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
};
