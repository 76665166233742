import { hoverStyles, pxToRem } from "adminComponents/utils";

export const AdminIcon = {
  baseStyle: (props: {
    iconColor: string;
    iconSrc?: string;
  }): Record<string, string> => ({
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    color: props.iconColor,
    padding: props.iconSrc ? pxToRem(3) : "",
  }),
  variants: {
    circleWithBackgroundColor: (props: {
      backgroundColor: string;
    }): Record<string, string> => ({
      borderRadius: "100%",
      height: pxToRem(24),
      padding: pxToRem(5),
      width: pxToRem(24),
      backgroundColor: props.backgroundColor,
    }),
    toolbarIcon: (props: {
      iconColor: string;
      backgroundColor: string;
    }): Record<string, unknown> => ({
      height: pxToRem(32),
      width: pxToRem(32),
      borderRadius: pxToRem(4),
      color: props.iconColor,
      bgColor: props.backgroundColor,
      svg: {
        height: pxToRem(24),
        width: pxToRem(24),
      },
      _hover: hoverStyles({
        bgColor: "primary.golden-light",
      }),
    }),
    toolbarToggleIcon: (props: {
      iconColor: string;
      backgroundColor: string;
    }): Record<string, unknown> => ({
      color: props.iconColor,
      bgColor: props.backgroundColor,
      borderRadius: pxToRem(4),
      p: pxToRem(4),
      _hover: hoverStyles({
        bgColor: "primary.golden-light",
      }),
    }),
    certifiedCheck: {
      height: pxToRem(32),
      width: pxToRem(32),
      svg: {
        height: "100%",
        width: "100%",
      },
    },
  },
};
