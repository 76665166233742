import { Grid, GridItem } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { ISessionItemResponse, QuestionType } from "links/lib/types";

import { AnswerColumn, IAnswerProps } from "./components/AnswerColumn";

export interface IProps {
  studentName: string;
  questionType: QuestionType;
  answers: ISessionItemResponse[];
  correctAnswers: IAnswerProps[];
}

export const StudentAnswerResults: React.FC<IProps> = ({
  studentName,
  questionType,
  answers,
  correctAnswers,
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });

  const studentAnswers = answers.map(
    (answer): IAnswerProps => ({
      id: answer.id,
      imageAltText: answer.choice_image_alt_text,
      imageUrl: answer.choice_image_url || answer.drawing_image_url,
      isCorrect: answer.is_correct,
      text: answer.response_text,
    })
  );

  return (
    <Grid templateColumns={{ base: "1fr", md: "1fr 1fr" }} gap={pxToRem(16)}>
      <GridItem>
        <Text
          variant="adminP2Bold"
          marginBottom={{ base: pxToRem(4), md: pxToRem(10) }}
        >
          {t("studentPracticeSetQuestionCardReponse.studentNameReponseLabel", {
            studentName: studentName,
          })}
        </Text>
        <AnswerColumn
          questionType={questionType}
          answerType="STUDENT_RESPONSES"
          answers={studentAnswers}
        />
      </GridItem>
      {questionType !== QuestionType.Draw && (
        <GridItem>
          <Text
            variant="adminP2Bold"
            marginBottom={{ base: pxToRem(4), md: pxToRem(10) }}
          >
            {t("studentPracticeSetQuestionCardReponse.correctResponseLabel")}
          </Text>
          <AnswerColumn
            answerType="EXPECTED_CORRECT_RESPONSES"
            questionType={questionType}
            answers={correctAnswers}
          />
        </GridItem>
      )}
    </Grid>
  );
};
