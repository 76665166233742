import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertStatus,
  Box,
  Accordion as ChakraAccordion,
  ExpandedIndex,
  HStack,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import React, { useState } from "react";

import { Icon } from "adminComponents/atoms/Icon";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils";

export type AccordionData = {
  alertLabel?: string;
  alertStatus?: AlertStatus;
  title: string;
  content: React.ReactNode;
};

interface AccordionProps {
  accordions: AccordionData[];
  variant?: string;
  initialActiveIndexes?: number[];
  permitOverflow?: boolean;
}

export const Accordion: React.FC<AccordionProps> = ({
  accordions,
  variant = "adminAccordion",
  initialActiveIndexes = [],
  permitOverflow = false,
}) => {
  const styles = useMultiStyleConfig("AdminAccordion", {
    variant,
  });
  const [activeIndexes, setActiveIndexes] =
    useState<ExpandedIndex>(initialActiveIndexes);
  return (
    <Box
      __css={styles.outerContainer}
      overflow={permitOverflow ? "visible" : undefined}
    >
      <ChakraAccordion
        allowMultiple
        defaultIndex={[0]}
        index={activeIndexes}
        onChange={setActiveIndexes}
      >
        {accordions.map((accordionData, index) => (
          <AccordionItem key={accordionData.title} sx={styles.container}>
            <AccordionButton sx={styles.button}>
              <Box sx={styles.textContainer}>
                <HStack>
                  {accordionData.alertLabel && (
                    <Alert
                      status={accordionData.alertStatus}
                      display="inline"
                      w="auto"
                      borderRadius={pxToRem(12)}
                      lineHeight={pxToRem(14)}
                    >
                      {accordionData.alertLabel}
                    </Alert>
                  )}
                  <Text variant="adminP2">{accordionData.title}</Text>
                </HStack>
              </Box>
              {!(activeIndexes as number[]).includes(index) ? (
                <Icon
                  icon="keyboard_arrow_down"
                  iconColor="primary.warm-black"
                />
              ) : (
                <Icon icon="keyboard_arrow_up" iconColor="primary.warm-black" />
              )}
            </AccordionButton>
            <AccordionPanel sx={styles.panel}>
              {accordionData.content}
            </AccordionPanel>
          </AccordionItem>
        ))}
      </ChakraAccordion>
    </Box>
  );
};
