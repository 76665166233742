import { Flex, Image, Link } from "@chakra-ui/react";
import React from "react";

import { Card } from "adminComponents/atoms/Card";
import { Heading } from "adminComponents/atoms/Heading";
import { pxToRem } from "adminComponents/utils";
import { useGetPremiumLink } from "sharedComponents/hooks/useGetPremiumLink";

import { Button } from "../Button";
import { Text } from "../Text";
import PremiumSVG from "./resource/premium_image.svg";

export interface IProps {
  label: string;
  upgradeText: string;
  cta: string;
}

export const UpgradeCallToActionCard: React.FC<IProps> = ({
  label,
  upgradeText,
  cta,
}) => {
  const premiumLink = useGetPremiumLink();

  return (
    <Card
      w="100%"
      h="100%"
      borderColor="primary.tan"
      variant="adminCardSmallBorder"
      bg="white"
      padding={pxToRem(20)}
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        gap={pxToRem(15)}
        flexDir="column"
        h="100%"
      >
        <Image boxSize={[pxToRem(150), pxToRem(200)]} src={PremiumSVG} />
        <Flex alignItems="center" flexDirection="column" gap={pxToRem(10)}>
          <Flex flexDirection="column" alignItems="center" gap={pxToRem(10)}>
            <Heading as="h3" variant="adminH5Gooper">
              {label}
            </Heading>
            <Text variant="adminP1">{upgradeText}</Text>
          </Flex>
        </Flex>
        <Link
          style={{ textDecoration: "underline" }}
          color="utility.link"
          href={premiumLink}
          target="_blank"
        >
          <Button variant="adminButtonOutlined">{cta}</Button>
        </Link>
      </Flex>
    </Card>
  );
};
