import { pxToRem } from "adminComponents/utils/pxToRem";

export const AdminAverageStudentAccuracy = {
  parts: ["container", "description", "options", "list"],
  baseStyle: {
    container: {
      display: "flex",
      flexDirection: "column",
    },
    description: {
      mt: pxToRem(32),
    },
    options: {
      backgroundColor: "white",
      marginY: [pxToRem(24), null, pxToRem(32)],
    },
    list: {
      display: "flex",
      flexDirection: "column",
      gap: pxToRem(20),
      mt: pxToRem(16),
      mb: pxToRem(22),
    },
    listItem: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: pxToRem(12),
    },
  },
};
