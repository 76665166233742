import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useHistory } from "react-router";

import { NavigationLink } from "adminComponents";
import {
  ITableData,
  ITableSort,
} from "adminComponents/molecules/SortableTable";
import {
  getStudentFamilyName,
  getStudentGivenName,
} from "adminComponents/utils";
import { useErrorToast } from "adminComponents/utils/toast";
import { useFetchAssignment } from "links/lib/features/assignments";
import {
  useFetchClassroom,
  useFetchClassroomUsers,
} from "links/lib/features/classrooms";
import { IUser, UsersGroupsRole } from "links/lib/types";
import { sortAlpha } from "links/lib/util";

import { StudentPerformanceSortBy } from "../components/StudentPerformanceTab";

interface IUseStudentPerformanceParams {
  assignmentId?: string;
  classroomId?: string;
  handleStudentPerformanceSortChange?: () => void;
}

export interface IUseStudentPerformanceContext {
  isLoading: boolean;
  sortedStudentsData: ITableData;
  handleStudentPerformanceSortChange: (sortData: ITableSort) => void;
}

const StudentPerformanceContext = createContext<IUseStudentPerformanceContext>({
  isLoading: false,
  sortedStudentsData: [],
  handleStudentPerformanceSortChange: () => {
    return;
  },
});

export const StudentPerformanceProvider: React.FC<
  IUseStudentPerformanceParams
> = ({
  assignmentId,
  classroomId,
  handleStudentPerformanceSortChange,
  children,
}) => {
  const history = useHistory();

  const classroomFetch = useFetchClassroom({
    id: classroomId || "",
    onPermissionDenied: () => {
      history.push("/t/classrooms");
    },
  });
  useErrorToast(classroomFetch.error);

  const assignmentFetch = useFetchAssignment({
    id: assignmentId || "",
    onError: (err) => {
      if (err.response?.status === 404) {
        history.push(`/t/classrooms/${classroomId}/assignments`);
      }
    },
  });
  useErrorToast(assignmentFetch.error);

  const classroom = classroomFetch.data?.classroom;
  const assignment = assignmentFetch.data?.assignment;

  const classroomStudentsFetch = useFetchClassroomUsers({
    classroom_id: classroom?.id,
    group_roles: [UsersGroupsRole.Member],
  });
  useErrorToast(classroomStudentsFetch.error);

  const [tableSortData, setTableSortData] = useState<{
    sortBy: StudentPerformanceSortBy;
    isDesc: boolean;
  }>({
    sortBy: StudentPerformanceSortBy.StudentGivenName,
    isDesc: false,
  });

  const studentsData = useMemo(() => {
    if (!classroomStudentsFetch.data) return [];

    return classroomStudentsFetch.data.users
      .filter((u) => u.id !== "0")
      .filter((u) => {
        // user_ids contains the specifically assigned user IDs or is empty if all
        // students were assigned
        if (assignment?.user_ids.length) {
          return assignment?.user_ids.includes(u.id);
        }
        return true;
      })
      .map((user) => {
        const maxAccuracyAttempt = (assignment?.best_user_attempts || []).find(
          (attempt) => attempt.user_id === user.id
        );
        const completedAssignment = assignment
          ? (maxAccuracyAttempt?.accuracy || 0) >=
            assignment.required_score_percent
          : false;
        const navigationLink: NavigationLink = {
          label: `${user.given_name} ${user.family_name}`,
          to: `/t/classrooms/${assignment?.group_id}/assignments/${assignment?.id}/students/${user.id}`,
        };

        return [
          { ...user, navigationLink },
          { ...user, navigationLink },
          maxAccuracyAttempt?.accuracy || 0,
          completedAssignment,
          {
            isComplete: completedAssignment,
            attemptedAssignment: !!maxAccuracyAttempt,
            assignmentStartTime: assignment?.starts_at,
            assignmentEndTime: assignment?.ends_at,
            outOfRangeAttempts:
              assignment?.out_of_range_practice_set_attempts.filter(
                (attempt) => attempt.user_id === user.id
              ) || [],
          },
        ];
      });
  }, [classroomStudentsFetch.data, assignment]);

  const sortedStudentsData = useMemo(() => {
    const { sortBy, isDesc } = tableSortData;
    return studentsData.sort((a, b) => {
      if (sortBy === StudentPerformanceSortBy.StudentGivenName) {
        const nameA = getStudentGivenName(a[0] as IUser) || "";
        const nameB = getStudentGivenName(b[0] as IUser) || "";

        return sortAlpha(nameA, nameB, isDesc);
      } else if (sortBy === StudentPerformanceSortBy.StudentFamilyName) {
        const studentA = a[0] as unknown as IUser;
        const studentB = b[0] as unknown as IUser;

        return sortAlpha(
          getStudentFamilyName(studentA) || "",
          getStudentFamilyName(studentB) || "",
          isDesc
        );
      } else {
        const accuracyA = a[2]; // TODO: Improve specific column index use
        const accuracyB = b[2];

        if (accuracyA > accuracyB) return isDesc ? -1 : 1;
        if (accuracyA < accuracyB) return isDesc ? 1 : -1;
        return 0;
      }
    });
  }, [studentsData, tableSortData]);

  const _handleStudentPerformanceSortChange = useCallback(
    (sortData: ITableSort) => {
      handleStudentPerformanceSortChange?.();
      let sortBy = StudentPerformanceSortBy.StudentAccuracy;
      if (sortData.columnIndex === 0) {
        sortBy = StudentPerformanceSortBy.StudentGivenName;
      }
      if (sortData.columnIndex === 1) {
        sortBy = StudentPerformanceSortBy.StudentFamilyName;
      }
      if (sortData.columnIndex === 2) {
        sortBy = StudentPerformanceSortBy.StudentAccuracy;
      }
      setTableSortData({
        sortBy,
        isDesc: !sortData.isAscending,
      });
    },
    [handleStudentPerformanceSortChange]
  );

  const isLoading = classroomStudentsFetch.isLoading;

  const value = useMemo(() => {
    return {
      isLoading,
      sortedStudentsData,
      handleStudentPerformanceSortChange: _handleStudentPerformanceSortChange,
    };
  }, [_handleStudentPerformanceSortChange, isLoading, sortedStudentsData]);

  return (
    <StudentPerformanceContext.Provider value={value}>
      {children}
    </StudentPerformanceContext.Provider>
  );
};

export const useStudentPerformanceContext =
  (): IUseStudentPerformanceContext => {
    return useContext(StudentPerformanceContext);
  };
