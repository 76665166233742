import { Box, useMultiStyleConfig } from "@chakra-ui/react";
import React from "react";

import { CoverImageBackground } from "adminComponents/atoms/CoverImageBackground";
import { CoverImageIcon } from "adminComponents/atoms/CoverImageIcon";
import {
  CoverImageBGColorSchemeType,
  CoverImageBGPatternType,
  CoverImageIconType,
} from "links/lib/types";

interface ICoverImageChoiceProps {
  boxSize: number;
  icon: CoverImageIconType;
  backgroundColorScheme: CoverImageBGColorSchemeType;
  backgroundPattern: CoverImageBGPatternType;
  isSelected: boolean;
  handleSelect?: (
    icon: CoverImageIconType,
    bgPattern: CoverImageBGPatternType,
    bcColorScheme: CoverImageBGColorSchemeType
  ) => void;
}

const CoverImageChoice: React.FC<ICoverImageChoiceProps> = ({
  boxSize,
  backgroundPattern,
  backgroundColorScheme,
  icon,
  isSelected,
  handleSelect,
}) => {
  const coverImageSize = boxSize * 0.9;
  // icon size should always be about 50% of blob size
  const iconSize = coverImageSize * 0.6;

  const styles = useMultiStyleConfig("AdminCoverImageChoice", {
    isSelected,
    boxSize,
    coverImageSize,
    iconSize,
  });

  return (
    <Box
      sx={styles.container}
      onClick={() => {
        handleSelect?.(icon, backgroundPattern, backgroundColorScheme);
      }}
      as="button"
      type="button"
    >
      <CoverImageBackground
        sx={styles.coverImage}
        pattern={backgroundPattern}
        colorScheme={backgroundColorScheme}
        // tile size should always be about twice the icon size
        tileSize={iconSize * 2}
        as="span"
      >
        <CoverImageIcon sx={styles.icon} as="span" icon={icon} />
      </CoverImageBackground>
    </Box>
  );
};

export default CoverImageChoice;
