import React from "react";
import { RenderLeafProps } from "slate-react";

const Leaf: React.FC<RenderLeafProps> = (props) => {
  const { leaf, attributes } = props;
  let { children } = props;

  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.strikethrough) {
    children = (
      <span style={{ textDecoration: "line-through" }}>{children}</span>
    );
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  if (leaf.strikethrough) {
    children = (
      <span style={{ textDecoration: "line-through" }}>{children}</span>
    );
  }

  if (leaf.superscript) {
    children = (
      <span
        style={{
          verticalAlign: "super",
          fontSize: "0.6em",
        }}
      >
        {children}
      </span>
    );
  }

  if (leaf.subscript) {
    children = (
      <span
        style={{
          verticalAlign: "sub",
          fontSize: "0.6em",
        }}
      >
        {children}
      </span>
    );
  }

  return <span {...attributes}>{children}</span>;
};

export default Leaf;
