import { ColorScheme } from "adminComponents";

const colorToColorSchemeMap: { [key: string]: ColorScheme } = {
  amber: "amber.light",
  tangerine: "tangerine.light",
  monaco: "monaco.light",
  smoke: "smoke.light",
  golden: "golden.light",
  capri: "capri.light",
  pepto: "pepto.light",
};

export const getUserColorScheme = (
  color:
    | "amber"
    | "tangerine"
    | "monaco"
    | "smoke"
    | "golden"
    | "capri"
    | "pepto"
): ColorScheme => {
  return colorToColorSchemeMap[color] || "golden.light";
};
