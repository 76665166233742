import { Box, Flex, FormLabel, SimpleGrid } from "@chakra-ui/react";
import { isEqual } from "lodash";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Checkbox } from "adminComponents/atoms/Checkbox";
import { Icon } from "adminComponents/atoms/Icon";
import { IconTooltip } from "adminComponents/atoms/IconTooltip";
import { Switch } from "adminComponents/atoms/Switch";
import { Tag } from "adminComponents/atoms/Tag";
import { Text } from "adminComponents/atoms/Text";
import { TextLink } from "adminComponents/atoms/TextLink";
import { AdminFlyout } from "adminComponents/molecules/Flyout";
import { LibrarySubjectPicker } from "adminComponents/organisms/LibrarySubjectPicker";
import { pxToRem } from "adminComponents/utils";
import { useFetchStandards } from "links/lib/features/standards";
import { IGradeLevel, ISubject, ISubjectCount } from "links/lib/types";
import { sortGradeLevelsTwoColumns } from "sharedComponents/utils/sortGradeLevelsTwoColumns";

export type IFilterPracticeSetState = {
  subjectIds: string[];
  standardIds: string[];
  gradeLevelIds: string[];
  numQuestions: INumberRange[];
  languages: string[];
  certifiedOnly: boolean;
  hidePremium: boolean;
};

const sortState = (state: IFilterPracticeSetState) => {
  state.gradeLevelIds.sort();
  state.languages.sort();
  state.numQuestions.sort();
  state.standardIds.sort();
  state.subjectIds.sort();
};

type ILanguageOption = {
  label: string;
  value: string;
};

type INumberRange = {
  from: number;
  to: number;
};

type INumberOfQuestions = {
  label: string;
  value: INumberRange;
};

interface FilterPracticeSetFlyoutProps {
  isOpen: boolean;
  isLoading: boolean;
  subjectOptions: ISubject[];
  subjectCounts: ISubjectCount[];
  gradeOptions: IGradeLevel[];
  languageOptions: ILanguageOption[];
  numQuestionOptions: INumberOfQuestions[];
  value?: IFilterPracticeSetState;
  showPremiumFilter?: boolean;
  showSubjectsCounts?: boolean;
  handleRemoveStandard: (standard: string) => void;
  handleSelectStandards: () => void;
  handleResetStandards: () => void;
  handleApplyFilters: (value: IFilterPracticeSetState) => void;
  handleChangeValue: (value: IFilterPracticeSetState) => void;
  handleClose: () => void;
}

export const FilterPracticeSetFlyout: React.FC<
  FilterPracticeSetFlyoutProps
> = ({
  isOpen,
  isLoading,
  subjectOptions,
  subjectCounts,
  gradeOptions,
  languageOptions,
  numQuestionOptions,
  value = {
    certifiedOnly: false,
    hidePremium: false,
    gradeLevelIds: [],
    languages: [],
    numQuestions: [],
    standardIds: [],
    subjectIds: [],
  },
  handleSelectStandards,
  handleRemoveStandard,
  handleResetStandards,
  handleApplyFilters,
  handleChangeValue,
  handleClose,
  showPremiumFilter = true,
  showSubjectsCounts = true,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  const [initialValue, setInitialValue] =
    useState<IFilterPracticeSetState>(value);

  const handleChangeGrade = (checked: boolean, gradeLevelId: string) => {
    if (checked) {
      handleChangeValue({
        ...value,
        gradeLevelIds: [...value.gradeLevelIds, gradeLevelId],
      });
    } else {
      handleChangeValue({
        ...value,
        gradeLevelIds: value.gradeLevelIds.filter((g) => g !== gradeLevelId),
      });
    }
  };

  const fetchStandards = useFetchStandards({
    ids: value.standardIds,
    offset: 0,
    limit: (value.standardIds || []).length,
    enabled: (value.standardIds || []).length > 0,
  });

  const handleChangeLanguages = (
    checked: boolean,
    language: ILanguageOption
  ) => {
    if (checked) {
      handleChangeValue({
        ...value,
        languages: [...value.languages, language.value],
      });
    } else {
      handleChangeValue({
        ...value,
        languages: value.languages.filter((l) => l !== language.value),
      });
    }
  };

  const _handleChangeNumberOfQuestions = (
    checked: boolean,
    numberRange: INumberRange
  ) => {
    if (checked) {
      handleChangeValue({
        ...value,
        numQuestions: [...value.numQuestions, numberRange],
      });
    } else {
      handleChangeValue({
        ...value,
        numQuestions: value.numQuestions.filter(
          (num) => num.from !== numberRange.from && num.to !== numberRange.to
        ),
      });
    }
  };

  const _handleChangeSelectedSubjects = (subjectIds: string[]) => {
    handleChangeValue({ ...value, subjectIds });
  };

  const _handleApplyFilters = () => {
    setInitialValue(value);
    handleApplyFilters(value);
  };

  const handleChangeCertifiedOnly = (checked: boolean) => {
    handleChangeValue({ ...value, certifiedOnly: checked });
  };

  const handleHidePremium = (checked: boolean) => {
    handleChangeValue({ ...value, hidePremium: checked });
  };

  const sortedGradeLevelOptions = useMemo(() => {
    return sortGradeLevelsTwoColumns(gradeOptions);
  }, [gradeOptions]);

  const filtersUnchanged = useMemo(() => {
    sortState(value);
    sortState(initialValue);

    return isEqual(value, initialValue);
  }, [value, initialValue]);

  return (
    <AdminFlyout
      isOpen={isOpen}
      onClose={handleClose}
      title={t("filterPracticeSetFlyout.title")}
    >
      <Box
        pb={[pxToRem(15), null, pxToRem(58)]}
        px={[pxToRem(15), null, pxToRem(58)]}
      >
        <SimpleGrid gap={[pxToRem(32), null, pxToRem(40)]}>
          <Box>
            <Text color="primary.warm-black" variant="adminP2">
              {t("filterPracticeSetFlyout.subjects")}
            </Text>
            <LibrarySubjectPicker
              subjectCounts={subjectCounts}
              subjects={subjectOptions}
              selectedSubjects={value.subjectIds}
              handleChangeSubjects={_handleChangeSelectedSubjects}
              variant="adminPracticeSetSubjectAccordion"
              showCounts={showSubjectsCounts}
            />
          </Box>
          <Box>
            <Flex justifyContent="space-between" alignItems="center">
              <Text color="primary.warm-black" variant="adminP2">
                {t("filterPracticeSetFlyout.standards")}
              </Text>
              <Button
                leftIcon={<Icon w={pxToRem(14)} h={pxToRem(14)} icon="add" />}
                variant="adminButtonOutlined"
                size="sm"
                onClick={handleSelectStandards}
              >
                {t("filterPracticeSetFlyout.selectStandards")}
              </Button>
            </Flex>
            {value.standardIds.length > 0 && (
              <Flex flexWrap="wrap" mt={pxToRem(8)} gap={pxToRem(12)}>
                {fetchStandards.data &&
                  value.standardIds.map((standardId) => {
                    const standard = fetchStandards.data.standards.find(
                      (s) => s.id === standardId
                    );
                    if (!standard) return;
                    return (
                      <Tag
                        rightIcon="close"
                        key={standardId}
                        onClick={() => handleRemoveStandard(standard.id)}
                      >
                        {standard.root_label || standard.label}
                      </Tag>
                    );
                  })}
                <Box
                  display={["flex", null, "inline-flex"]}
                  alignItems="center"
                  w={["100%", null, "auto"]}
                >
                  <TextLink handleClick={handleResetStandards}>
                    {t("common.clearAll")}
                  </TextLink>
                </Box>
              </Flex>
            )}
          </Box>
          <Box>
            <Text
              color="primary.warm-black"
              variant="adminP2"
              mb={[pxToRem(12), null, pxToRem(8)]}
            >
              {t("filterPracticeSetFlyout.grades")}
            </Text>
            <SimpleGrid gap={pxToRem(12)} gridTemplateColumns="1fr 1fr">
              {sortedGradeLevelOptions.map((gradeOption) => (
                <Checkbox
                  key={gradeOption.id}
                  onChange={(evt) =>
                    handleChangeGrade(evt.target.checked, gradeOption.id)
                  }
                  isChecked={
                    !!value.gradeLevelIds.find(
                      (gradeLevelId) => gradeLevelId === gradeOption.id
                    )
                  }
                >
                  {gradeOption.grade_level}
                </Checkbox>
              ))}
            </SimpleGrid>
          </Box>
          {numQuestionOptions.length > 0 && (
            <Box>
              <Text
                color="primary.warm-black"
                variant="adminP2"
                mb={[pxToRem(12), null, pxToRem(8)]}
              >
                {t("filterPracticeSetFlyout.numberOfQuestions")}
              </Text>
              <SimpleGrid gap={pxToRem(12)} gridTemplateColumns="1fr 1fr">
                {numQuestionOptions.map((numQuestionOption) => (
                  <Checkbox
                    key={numQuestionOption.label}
                    isChecked={
                      !!value.numQuestions.find(
                        (numberRange) =>
                          numberRange.from === numQuestionOption.value.from &&
                          numberRange.to === numQuestionOption.value.to
                      )
                    }
                    onChange={(evt) =>
                      _handleChangeNumberOfQuestions(
                        evt.target.checked,
                        numQuestionOption.value
                      )
                    }
                  >
                    {numQuestionOption.label}
                  </Checkbox>
                ))}
              </SimpleGrid>
            </Box>
          )}
          {languageOptions.length > 0 && (
            <Box>
              <Text
                color="primary.warm-black"
                variant="adminP2"
                mb={[pxToRem(12), null, pxToRem(8)]}
              >
                {t("filterPracticeSetFlyout.languages")}
              </Text>
              <SimpleGrid gap={pxToRem(12)} gridTemplateColumns="1fr 1fr">
                {languageOptions.map((languageOption) => (
                  <Checkbox
                    key={languageOption.value}
                    onChange={(evt) =>
                      handleChangeLanguages(evt.target.checked, languageOption)
                    }
                    isChecked={value.languages.includes(languageOption.value)}
                  >
                    {languageOption.label}
                  </Checkbox>
                ))}
              </SimpleGrid>
            </Box>
          )}
          <Box>
            <Flex alignItems="center" gap={pxToRem(4)}>
              <FormLabel hidden htmlFor="certifiedOnly">
                {t("filterPracticeSetFlyout.certifiedOnly")}
              </FormLabel>
              <Switch
                id="certifiedOnly"
                isChecked={value.certifiedOnly}
                onChange={(e) => handleChangeCertifiedOnly(e.target.checked)}
              />
              <Text
                variant="adminP2"
                color="primary.warm-black"
                ml={pxToRem(8)}
              >
                {t("filterPracticeSetFlyout.certifiedOnly")}
              </Text>
              <IconTooltip>
                {t("filterPracticeSetFlyout.certifiedOnlyTooltip")}
              </IconTooltip>
            </Flex>
            {showPremiumFilter && (
              <Flex alignItems="center" gap={pxToRem(4)} marginTop={pxToRem(8)}>
                <FormLabel hidden htmlFor="hidePremium">
                  {t("filterPracticeSetFlyout.hidePremium")}
                </FormLabel>
                <Switch
                  id="hidePremium"
                  isChecked={value.hidePremium}
                  onChange={(e) => handleHidePremium(e.target.checked)}
                />
                <Text
                  variant="adminP2"
                  color="primary.warm-black"
                  ml={pxToRem(8)}
                >
                  {t("filterPracticeSetFlyout.hidePremium")}
                </Text>
                <IconTooltip>
                  {t("filterPracticeSetFlyout.hidePremiumTooltip")}
                </IconTooltip>
              </Flex>
            )}
          </Box>
          <Box>
            <Button
              variant="adminButtonFilled"
              w={["100%", null, "auto"]}
              onClick={_handleApplyFilters}
              isLoading={isLoading}
              isDisabled={filtersUnchanged}
            >
              {t("filterPracticeSetFlyout.applyFilters")}
            </Button>
          </Box>
        </SimpleGrid>
      </Box>
    </AdminFlyout>
  );
};
