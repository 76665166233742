import { Box, Center, HStack, Spinner, Text, VStack } from "@chakra-ui/react";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link, Redirect } from "react-router-dom";
import { useSearchParam } from "react-use";

import { Button } from "adminComponents/atoms/Button";
import { Logo } from "adminComponents/atoms/Logo";
import { TemplateWithVerticalCenteredContent } from "adminComponents/templates/TemplateWithVerticalCenteredContent";
import { pxToRem } from "adminComponents/utils";
import { useAuth } from "links/lib/features/auth";
import { useFetchGeo } from "links/lib/features/geo";
import { useDisableUserRecurringNotifications } from "links/lib/features/users";

export const TeacherUnsubscribe: React.FC = () => {
  const { authUser } = useAuth();
  const fetchGeo = useFetchGeo();
  const token = useSearchParam("token");
  const disableUserRecurringNotifications =
    useDisableUserRecurringNotifications();
  const disableUserRecurringNotificationsRef = useRef(
    disableUserRecurringNotifications
  );
  const { t } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "teacherUnsubscribe",
  });
  const notRegionGated =
    fetchGeo.isSuccess && fetchGeo.data?.status_code !== 451;
  const canUserForm = authUser && notRegionGated;

  useEffect(() => {
    if (fetchGeo.isLoading) return;
    if (canUserForm) return;
    if (!token) return;
    if (!disableUserRecurringNotificationsRef.current) return;

    disableUserRecurringNotificationsRef.current.mutate({ token });
  }, [
    disableUserRecurringNotificationsRef,
    token,
    fetchGeo.isLoading,
    canUserForm,
  ]);

  const onClickTryAgain = () => {
    if (!token) return;
    disableUserRecurringNotifications.mutate({ token });
  };

  if (fetchGeo.isLoading) {
    return (
      <Center h="full">
        <Spinner />
      </Center>
    );
  }

  if (canUserForm) {
    return <Redirect to="/t/account/notifications/settings" />;
  }

  const header = (
    <HStack
      w="full"
      justifyContent={["center", null, "space-between"]}
      alignItems="center"
      mt={pxToRem(30)}
      mb={[pxToRem(30), null, pxToRem(0)]}
    >
      <Box w={pxToRem(154)}>
        <Logo w={pxToRem(154)} />
      </Box>
    </HStack>
  );

  const status = disableUserRecurringNotifications.isSuccess
    ? t("unsubscribed")
    : disableUserRecurringNotifications.isLoading
    ? t("unsubscribing")
    : disableUserRecurringNotifications.isError
    ? t("errorUnsubscribing")
    : !token
    ? t("invalidToken")
    : "";

  return (
    <TemplateWithVerticalCenteredContent
      isLoading={false}
      headerContent={header}
    >
      <Text mb={pxToRem(30)} size="lg">
        {status}
      </Text>
      {disableUserRecurringNotifications.isSuccess && (
        <VStack spacing={pxToRem(32)}>
          <Text>{t("unsubscribedDescription")}</Text>
          <Button
            variant="adminButtonFilled"
            as={Link}
            to="/t/account/notifications/settings"
          >
            {t("buttonSignInToUpdateSettings")}
          </Button>
        </VStack>
      )}
      {disableUserRecurringNotifications.isError && (
        <VStack spacing={pxToRem(32)}>
          <Text>{t("errorUnsubscribingDescription")}</Text>
          <HStack>
            <Button variant="adminButtonOutlined" onClick={onClickTryAgain}>
              {t("buttonTryAgain")}
            </Button>
            <Button
              variant="adminButtonFilled"
              as={Link}
              to="/t/account/notifications/settings"
            >
              {t("buttonSignInToUpdateSettings")}
            </Button>
          </HStack>
        </VStack>
      )}
      {!token && (
        <VStack spacing={pxToRem(32)}>
          <Text>{t("invalidTokenDescription")}</Text>
          <Button
            variant="adminButtonFilled"
            as={Link}
            to="/t/account/notifications/settings"
          >
            {t("buttonSignInToUpdateSettings")}
          </Button>
        </VStack>
      )}
    </TemplateWithVerticalCenteredContent>
  );
};
