import { Box, HStack, Show } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Logo } from "adminComponents/atoms/Logo";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils";
import { useLanguageDropdown } from "sharedComponents/hooks/useLanguageDropdown";

interface IProps {
  showSignOut?: boolean;
  onSignOut?: () => void;
}

/**
 * HeaderWithLanguagePicker is a component often used as the "header" prop
 * of the TemplateWithVerticalCenteredContent component. It displays a logo
 * and a language picker, though the picker is only displayed on larger screen
 * sizes. A common pattern is to put the language picker at the bottom of the
 * TemplateWithVerticalCenteredContent component on smaller screens.
 */
export const HeaderWithLanguagePicker: React.FC<IProps> = ({
  children,
  showSignOut = true,
  onSignOut,
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });

  const langDropdown = useLanguageDropdown();

  return (
    <HStack
      w="full"
      justifyContent={["space-around", null, "space-between"]}
      alignItems="center"
      mt={pxToRem(30)}
      mb={[pxToRem(30), null, pxToRem(0)]}
    >
      <Box w={pxToRem(154)}>
        <Logo w={pxToRem(154)} />
      </Box>
      {children}
      <HStack spacing={pxToRem(20)}>
        <Show above="lg">
          <Box>{langDropdown}</Box>
        </Show>
        {showSignOut && (
          <Link to="/sign-out" onClick={() => onSignOut?.()}>
            <Text
              fontSize={pxToRem(16)}
              color="utility.link"
              variant="adminLinkMedium"
            >
              {t("common.signOut")}
            </Text>
          </Link>
        )}
      </HStack>
    </HStack>
  );
};
