import { useDisclosure } from "@chakra-ui/react";
import React, { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useLibrarySearch } from "adminComponents/hooks/useLibrarySearch";
import {
  TeacherFollowingModal,
  TeacherFollowingModalTabIndex,
} from "adminComponents/organisms/TeacherFollowingModal";
import { TeacherProfileScreen } from "adminComponents/screens/TeacherProfileScreen";
import { TeacherProfileUnavailableScreen } from "adminComponents/screens/TeacherProfileUnavailableScreen";
import { generateTeacherName } from "adminComponents/utils";
import { useShowToast } from "adminComponents/utils/toast";
import { useAnalytics } from "lib/contexts/analytics";
import { useAuth } from "links/lib/features/auth";
import {
  useFetchUserPublicProfile,
  useFollowUser,
  useUnfollowUser,
} from "links/lib/features/users";
import { IUserFollowUserParams } from "links/lib/features/users/useFollowUser";
import { AnalyticsEvent, PracticeSetAvailability } from "links/lib/types";
import { redirectIfEmpty } from "links/lib/util";
import { useNavigationData } from "screens/TeacherDashboard/contexts/TeacherNavigationDataProvider";

export interface ITeacherProfileProps {
  children?: ReactNode;
}

export const TeacherProfile: React.FC<ITeacherProfileProps> = ({
  children,
}) => {
  const { navigationData } = useNavigationData();
  const { trackEvent } = useAnalytics();

  const { userCustomUrlCode } = useParams<{ userCustomUrlCode: string }>();

  redirectIfEmpty(userCustomUrlCode, "/t/public-library");

  const { authUser } = useAuth();
  const showToast = useShowToast();
  const { t } = useTranslation("admin", {
    keyPrefix: "common",
  });
  const [profileAvailable, setProfileAvailable] = useState(true);
  const fetchUser = useFetchUserPublicProfile({
    userId: "0",
    customUrlCode: userCustomUrlCode,
    includeStatistics: true,
    onForbidden: () => {
      setProfileAvailable(false);
    },
    onNotFound: () => {
      setProfileAvailable(false);
    },
  });

  const name = generateTeacherName(fetchUser.data?.user || {}).primary;

  useEffect(() => {
    if (authUser?.custom_url_code === userCustomUrlCode) return;
    if (fetchUser.data?.is_private) {
      setProfileAvailable(false);
    }
  }, [authUser, fetchUser.data, userCustomUrlCode]);

  const { mutate: followUser } = useFollowUser({
    onSuccess: (_, args: IUserFollowUserParams) => {
      showToast(t("followUserSuccess", { name }));
      trackEvent(AnalyticsEvent.TeacherDashboard_TeacherProfile_FollowUser, {
        user_id: args.userId,
      });
    },
  });
  const { mutate: unfollowUser } = useUnfollowUser({
    onSuccess: () => {
      showToast(t("unfollowUserSuccess", { name }));
    },
  });

  const handleFollowUser = () => {
    if (!fetchUser.data?.user) return;
    followUser({
      userId: fetchUser.data?.user.id,
      authUserId: authUser?.id || "0",
    });
  };

  const handleUnfollowUser = () => {
    if (!fetchUser.data?.user) return;
    unfollowUser({
      userId: fetchUser.data?.user.id,
      authUserId: authUser?.id || "0",
    });
  };

  const {
    isOpen: isOpenFollowingModal,
    onClose: onCloseFollowingModal,
    onOpen: onOpenFollowingModal,
  } = useDisclosure();

  const [initialFollowingModalTab, setInitialFollowingModalTab] = useState(
    TeacherFollowingModalTabIndex.Followers
  );

  const handleOpenFollowersModal = () => {
    setInitialFollowingModalTab(TeacherFollowingModalTabIndex.Followers);
    onOpenFollowingModal();
  };

  const handleOpenFollowingModal = () => {
    setInitialFollowingModalTab(TeacherFollowingModalTabIndex.Following);
    onOpenFollowingModal();
  };

  const {
    handleSetTerm,
    query,
    handleViewMorePracticeSets,
    practiceSets,
    totalCounts,
    isSearchLoading,
    showViewMorePracticeSets,
    handleClearSearchTerm,
  } = useLibrarySearch({
    userId: fetchUser.data?.user.id,
    gradeLevels: navigationData.gradeLevels,
    subjects: navigationData.subjects,
    region: fetchUser.data?.user.region,
    country: fetchUser.data?.user.country || "US",
    availability: PracticeSetAvailability.Public,
    ignoreCollections: true,
    disabled: !fetchUser.isSuccess,
  });

  return (
    <>
      {profileAvailable && (
        <>
          <TeacherProfileScreen
            user={fetchUser.data?.user}
            statistics={fetchUser.data?.statistics}
            practiceSets={practiceSets}
            navigationData={navigationData}
            isLoading={fetchUser.isLoading}
            isFollowing={fetchUser.data?.is_following}
            isSearchLoading={isSearchLoading}
            practiceSetTotalCount={totalCounts.practiceSets}
            showViewMorePracticeSets={showViewMorePracticeSets}
            handleViewMorePracticeSets={handleViewMorePracticeSets}
            initialSearchTerm={query.term || ""}
            handleSearch={(term: string) => {
              handleSetTerm(term);
            }}
            handleClearSearchInput={handleClearSearchTerm}
            handleOpenFollowersModal={handleOpenFollowersModal}
            handleOpenFollowingModal={handleOpenFollowingModal}
            handleFollowUser={handleFollowUser}
            handleUnfollowUser={handleUnfollowUser}
          >
            {children}
          </TeacherProfileScreen>
          {fetchUser.data && isOpenFollowingModal && (
            <TeacherFollowingModal
              statistics={fetchUser.data.statistics}
              user={fetchUser.data.user}
              isOpen={isOpenFollowingModal}
              initialTabIndex={initialFollowingModalTab}
              handleClose={onCloseFollowingModal}
            />
          )}
        </>
      )}
      {!profileAvailable && (
        <TeacherProfileUnavailableScreen navigationData={navigationData} />
      )}
    </>
  );
};
