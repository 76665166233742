import { IProps as ToggleButtonProps } from "adminComponents/atoms/ToggleButton";
import { pxToRem } from "adminComponents/utils/pxToRem";

export const AdminToggleButton = {
  baseStyle: ({ isOpen }: ToggleButtonProps): Record<string, unknown> => ({
    width: pxToRem(12),
    transform: `rotateZ(${isOpen ? "180deg" : "0deg"})`,
    transition: "transform .2s",
    marginLeft: pxToRem(4),
  }),
};
