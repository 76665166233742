import { Box, Flex, SimpleGrid, Spinner } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Card } from "adminComponents/atoms/Card";
import { Heading } from "adminComponents/atoms/Heading";
import { Text } from "adminComponents/atoms/Text";
import { TextLink } from "adminComponents/atoms/TextLink";
import {
  INavigationHeaderProps,
  NavigationHeader,
} from "adminComponents/molecules/NavigationHeader";
import { TemplateWithCenteredHeroOneColumn } from "adminComponents/templates/TemplateWithCenteredHeroOneColumn";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { IClassroom } from "links/lib/types";

export interface IProps {
  classrooms: IClassroom[];
  navigationData: INavigationHeaderProps;
  handleRestoreClassroom: (classroom: IClassroom) => void;
  isLoading?: boolean;
  isRestoring?: boolean;
}

export const ClassroomArchiveScreen: React.FC<IProps> = ({
  navigationData,
  classrooms,
  handleRestoreClassroom,
  isLoading,
  isRestoring,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const nav = (
    <NavigationHeader isGrayscale isLoading={isLoading} {...navigationData} />
  );
  const heroContent = (
    <Heading as="h1" variant="adminH2">
      {t("archivedClassroom.title")}
    </Heading>
  );

  return (
    <TemplateWithCenteredHeroOneColumn
      nav={nav}
      heroContent={heroContent}
      variant="default"
      isGrayscale
      isLoading={isLoading}
    >
      <Text variant="adminP1" mb={pxToRem(24)}>
        {t("archivedClassroom.subheading")}
      </Text>
      <SimpleGrid gap={pxToRem(24)}>
        {classrooms.length === 0 ? (
          <Card borderColor="primary.tan" variant="adminCardSmallBorder">
            <Heading as="p" variant="adminH6" w="full" textAlign="center">
              {t("archivedClassroom.noArchivedClassrooms")}
            </Heading>
          </Card>
        ) : (
          classrooms.map((classroom) => (
            <Card
              borderColor="primary.tan"
              variant="adminCardSmallBorder"
              key={classroom.id}
            >
              <Flex
                direction={["column", null, "row"]}
                alignItems={["flex-start", null, "center"]}
                gap={pxToRem(16)}
                justifyContent="space-between"
              >
                <Box>
                  <Heading as="p" variant="adminH6" mb={pxToRem(8)}>
                    {classroom.name}
                  </Heading>
                  <Text variant="adminP2" color="primary.warm-black">
                    {classroom.description}
                  </Text>
                </Box>
                {isRestoring ? (
                  <Spinner />
                ) : (
                  <TextLink
                    handleClick={() => handleRestoreClassroom(classroom)}
                  >
                    {t("archivedClassroom.restoreClassroom")}
                  </TextLink>
                )}
              </Flex>
            </Card>
          ))
        )}
      </SimpleGrid>
    </TemplateWithCenteredHeroOneColumn>
  );
};
