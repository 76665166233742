export const AdminFormLabel = {
  variants: {
    adminFormLabel: {
      color: "primary.warm-black",
      display: "inline-block",
      m: 0,
      _disabled: {
        opacity: 0.5,
      },
    },
    adminFormLabelDarkGray: {
      color: "primary.dark-gray",
      display: "inline-block",
      m: 0,
      _disabled: {
        opacity: 0.5,
      },
    },
  },
};
