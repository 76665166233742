import React from "react";
import { useTranslation } from "react-i18next";

import { ClassroomSummaryCard } from "adminComponents/molecules/ClassroomSummaryCard";
import {
  ConfirmModal,
  IConfirmModalProps,
} from "adminComponents/molecules/ConfirmModal";
import { IClassroom } from "links/lib/types";

interface IProps {
  accuracy?: number;
  classroom: IClassroom;
  isLoading?: IConfirmModalProps["isLoading"];
  isOpen: IConfirmModalProps["isOpen"];
  handleClose: IConfirmModalProps["handleClose"];
}

export const ClassroomArchiveModal: React.FC<IProps> = ({
  accuracy,
  classroom,
  ...props
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  return (
    <ConfirmModal
      {...props}
      buttonCancelLabel={t("classroomArchiveModal.cancelButton")}
      buttonOKLabel={t("classroomArchiveModal.confirmButton")}
      description={t("classroomArchiveModal.description")}
      size="2xl"
      title={t("classroomArchiveModal.title")}
      variant="adminModalCompactMobile"
    >
      <ClassroomSummaryCard accuracy={accuracy} classroom={classroom} />
    </ConfirmModal>
  );
};
