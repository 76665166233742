import { Box, Portal, usePrefersReducedMotion } from "@chakra-ui/react";
import lottie, { AnimationItem } from "lottie-web/build/player/lottie_light";
import React, { useEffect, useRef, useState } from "react";
import { animated, config, useSpring } from "react-spring";

import { IAvatarItem, SeasonMapSoundEffect } from "links/lib/types";
import { UnlockedItem } from "sharedComponents/atoms/UnlockedItem";
import { useAudio } from "sharedComponents/contexts/audio";

import animationData from "./resource/item-unlock-with-ticket.json";

const animationDataString = JSON.stringify(animationData);

interface IMapItemUnlockedProps {
  show: boolean;
  item?: IAvatarItem;
  handleComplete?: () => void;
  skinToneHexColor?: string;
  hairColorHexColor?: string;
}

const AnimatedBox = animated(Box);

export const SeasonMapItemUnlocked: React.FC<IMapItemUnlockedProps> = ({
  show,
  handleComplete,
  item,
  skinToneHexColor,
  hairColorHexColor,
}) => {
  const playerRef = useRef<HTMLDivElement | null>(null);
  const animRef = useRef<AnimationItem | null>(null);
  const [showCard, setShowCard] = useState(false);
  const reduceMotion = usePrefersReducedMotion();
  const [splashAtRest, setSplashAtRest] = useState(true);
  const { play: playAudio } = useAudio();

  const splashSpring = useSpring({
    from: {
      opacity: 0,
      scale: reduceMotion ? 1 : 0,
    },
    to: {
      opacity: show ? 1 : 0,
      scale: show ? 1 : 0,
    },
    config: {
      ...config.gentle,
      friction: 20,
    },
    onRest: () => setSplashAtRest(true),
  });

  useEffect(() => {
    setSplashAtRest(false);
  }, [show]);

  useEffect(() => {
    if (!show || !playerRef.current) {
      return;
    }

    animRef.current = lottie.loadAnimation({
      container: playerRef.current,
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: JSON.parse(animationDataString),
    });

    const play = () => {
      animRef.current?.goToAndPlay(0, true);
      playAudio(SeasonMapSoundEffect.ItemUnlock);
      setShowCard(false);
    };

    animRef.current.addEventListener("DOMLoaded", play);

    let hasTriggeredCard = false;
    const enterFrame = ({ currentTime }: { currentTime: number }) => {
      if (!hasTriggeredCard && currentTime > 120) {
        setShowCard(true);
        hasTriggeredCard = true;
      }
    };

    animRef.current.addEventListener("enterFrame", enterFrame);

    return () => {
      animRef.current?.removeEventListener("enterFrame", enterFrame);
      animRef.current?.removeEventListener("DOMLoaded", play);
      animRef.current?.stop();
      animRef.current?.destroy();
      lottie.stop();
      lottie.destroy();
    };
  }, [show, playAudio]);

  const onCardAnimComplete = () => {
    handleComplete?.();
  };

  return (
    <Portal>
      <AnimatedBox
        position="fixed"
        top="0"
        left="0"
        right="0"
        bottom="0"
        bg="#0b364b"
        style={splashSpring}
        zIndex="10000"
        display={show || !splashAtRest ? "block" : "none"}
      >
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          ref={playerRef}
        />
        <Box
          position="absolute"
          top="0"
          bottom="0"
          left="0"
          right="0"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {!!item && (
            <UnlockedItem
              item={item}
              show={show && showCard}
              handleComplete={onCardAnimComplete}
              skinToneHexColor={skinToneHexColor}
              hairColorHexColor={hairColorHexColor}
            />
          )}
        </Box>
      </AnimatedBox>
    </Portal>
  );
};
