import { Flex, Grid, Image } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Icon } from "adminComponents/atoms/Icon";
import { ProgressBar } from "adminComponents/atoms/ProgressBar";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils/pxToRem";
import {
  isImageChoicePossibleQuestionType,
  isPlainTextAnswerQuestionType,
} from "adminComponents/utils/questionType";
import { IPracticeSetItem, ISessionItemResponse } from "links/lib/types";
import { RichTextRenderer } from "sharedComponents/atoms/RichTextRenderer";

export type IHandleViewStudentResponses = (
  practiceSetItem: IPracticeSetItem,
  responses: ISessionItemResponse[],
  choiceId?: string // Optional arg indicating that we're only interested in responses for the given choice
) => void;

export interface IProps {
  isCorrect: boolean;
  choiceId?: string;
  responseText?: string;
  responseImageUrl?: string;
  responseImageAltText?: string;
  percentage?: number;
  studentsCount: number;
  isIncorrectGroup?: boolean;
  practiceSetItem: IPracticeSetItem;
  responses: ISessionItemResponse[];
  handleViewStudentResponses: IHandleViewStudentResponses;
}

export const ClassroomAnswerResultsMetric: React.FC<IProps> = ({
  choiceId,
  responseText,
  responseImageUrl,
  responseImageAltText,
  isCorrect,
  percentage = 0,
  studentsCount,
  isIncorrectGroup,
  practiceSetItem,
  responses,
  handleViewStudentResponses,
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });

  const showGreen = isCorrect && !isIncorrectGroup;
  const handleStudentsButton = () =>
    handleViewStudentResponses(practiceSetItem, responses, choiceId);

  const questionUsesPlainTextAnswers = isPlainTextAnswerQuestionType(
    practiceSetItem.question_type
  );

  const questionUsesImageAnswer =
    isImageChoicePossibleQuestionType(practiceSetItem.question_type) &&
    !!responseImageUrl;

  return (
    <Grid
      gap={{ base: pxToRem(8), md: pxToRem(32) }}
      templateColumns={{ base: "1fr", md: "60% 1fr" }}
    >
      <Flex
        gap={{ base: pxToRem(8), md: pxToRem(12) }}
        align="flex-start"
        alignItems="center"
      >
        {!isIncorrectGroup && (
          <>
            <Icon
              flexShrink={0}
              variant="circleWithBackgroundColor"
              icon={showGreen ? "check" : "close"}
              backgroundColor={
                showGreen ? "utility.question-green" : "primary.light-gray"
              }
            />
            {!questionUsesImageAnswer && responseText && (
              <Text as="div" variant="adminP2" marginTop={pxToRem(3)}>
                {questionUsesPlainTextAnswers && responseText}
                {!questionUsesPlainTextAnswers && (
                  <RichTextRenderer content={responseText} />
                )}
              </Text>
            )}
            {questionUsesImageAnswer && (
              <Image
                borderRadius="lg"
                h="100%"
                w={{ base: pxToRem(72), lg: pxToRem(120) }}
                src={responseImageUrl}
                alt={responseImageAltText}
              />
            )}
          </>
        )}
        {isIncorrectGroup && (
          <Text variant="adminP2Bold" marginTop={pxToRem(3)}>
            {t("classroomAssignmentReport.incorrectResponses")}
          </Text>
        )}
      </Flex>
      <Grid
        templateColumns="1fr minmax(75px, auto);"
        alignItems="center"
        gap={pxToRem(12)}
      >
        <ProgressBar
          value={percentage}
          variant={
            showGreen
              ? "adminTeacherQuestionCorrect"
              : "adminTeacherQuestionIncorrect"
          }
        />
        <Flex marginLeft="auto">
          {studentsCount > 0 ? (
            <Button
              variant="adminTextButtonMedium"
              onClick={handleStudentsButton}
              aria-label={
                studentsCount > 1
                  ? t(
                      "classroomAssignmentReport.studentsModalButtonAriaLabelPlural",
                      { studentsCount }
                    )
                  : t(
                      "classroomAssignmentReport.studentsModalButtonAriaLabelSingular"
                    )
              }
            >
              {studentsCount}{" "}
              {studentsCount > 1
                ? t("common.studentPlural")
                : t("common.studentSingular")}
            </Button>
          ) : (
            <Text
              variant="adminP2"
              color="primary.dark-gray"
              marginTop={pxToRem(3)}
            >
              0 {t("common.studentPlural")}
            </Text>
          )}
        </Flex>
      </Grid>
    </Grid>
  );
};
