export const textStyles = {
  gameDisplay: {
    fontFamily: `"Gooper", Georgia, serif`,
    lineHeight: "120%",
    letterSpacing: "0%",
  },
  gameDisplayInline: {
    fontFamily: `"Gooper", Georgia, serif`,
    lineHeight: "100%",
    letterSpacing: "0%",
  },
  gameText: {
    fontFamily: `"TTCommons", Arial, sans-serif`,
    fontWeight: 500,
    lineHeight: "120%",
  },
  gameTextWeighted: {
    fontFamily: `"TTCommons", Arial, sans-serif`,
    fontWeight: 600,
  },
  sessionCode: {
    fontFamily: `"Gooper", Georgia, serif`,
    fontWeight: 600,
    letterSpacing: "0.1rem",
  },
  inputHelperText: {
    fontFamily: `"TTCommons", Arial, sans-serif`,
    fontWeight: 500,
  },
};
