import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Select,
  Stack,
  VStack,
  VisuallyHidden,
} from "@chakra-ui/react";
import React, { ChangeEvent, FormEvent, LegacyRef, useMemo } from "react";

import { IGradeLevel, IStandardCollection, ISubject } from "links/lib/types";

export interface ISearchFormProps {
  onSearchSubmit: (
    e: FormEvent<HTMLFormElement> & FormEvent<HTMLDivElement>
  ) => void;
  initialFocusRef: LegacyRef<HTMLInputElement>;
  content: {
    searchQueryLabel: string;
    searchQueryPlaceholder: string;
    searchRegionLabel: string;
    searchRegionAnyOption: string;
    searchGradeLevelLabel: string;
    searchGradeLevelAnyOption: string;
    searchSubjectLabel: string;
    searchSubjectAnyOption: string;
    searchStandardCollectionLabel: string;
    searchStandardCollectionAnyOption: string;
  };
  values: {
    query: string;
    region: string;
    grade_level_id: string;
    subject_id: string;
    standard_collection_id?: string;
  };
  isGradeLevelsLoading: boolean;
  isSubjectsLoading: boolean;
  isStandardCollectionsLoading: boolean;
  gradeLevels: Array<IGradeLevel>;
  subjects: Array<ISubject>;
  standardCollections: Array<IStandardCollection>;
  regions: Array<{ code: string; name: string }>;
  onQueryChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onRegionChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  onGradeLevelChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  onSubjectChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  onStandardCollectionChange: (e: ChangeEvent<HTMLSelectElement>) => void;
}

const SearchForm: React.FC<ISearchFormProps> = ({
  content,
  onSearchSubmit,
  values,
  isGradeLevelsLoading,
  isSubjectsLoading,
  isStandardCollectionsLoading,
  onQueryChange,
  onRegionChange,
  onGradeLevelChange,
  onSubjectChange,
  onStandardCollectionChange,
  gradeLevels,
  subjects,
  standardCollections,
  regions,
  initialFocusRef,
}) => {
  const sortedStandardCollections = useMemo(() => {
    return standardCollections.sort((a, b) => {
      return a.region > b.region ? -1 : a.region < b.region ? 1 : 0;
    });
  }, [standardCollections]);

  return (
    <Box as="form" w="full" onSubmit={onSearchSubmit}>
      <VStack spacing={2}>
        {/* Search Query */}
        <FormControl>
          <VisuallyHidden>
            <FormLabel htmlFor="standardsSearchQuery">
              {content.searchQueryLabel}
            </FormLabel>
          </VisuallyHidden>
          <Input
            id="standardsSearchQuery"
            name="standardsSearchQuery"
            ref={initialFocusRef}
            placeholder={content.searchQueryPlaceholder}
            value={values.query}
            onChange={onQueryChange}
          />
        </FormControl>

        <Stack w="full" direction={{ base: "column", md: "row" }} spacing={2}>
          {/* Search Region */}
          {!!regions.length && (
            <FormControl>
              <VisuallyHidden>
                <FormLabel htmlFor="standardsSearchRegion">
                  {content.searchRegionLabel}
                </FormLabel>
              </VisuallyHidden>
              <Select
                id="standardsSearchRegion"
                name="standardsSearchRegion"
                value={values.region}
                onChange={onRegionChange}
              >
                <option value="">{content.searchRegionAnyOption}</option>
                {regions.map((r) => (
                  <option key={`region-${r.code}`} value={r.code}>
                    {r.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          )}

          {/* Search Grade Level */}
          <FormControl>
            <VisuallyHidden>
              <FormLabel htmlFor="standardsSearchGraveLevel">
                {content.searchGradeLevelLabel}
              </FormLabel>
            </VisuallyHidden>
            <Select
              disabled={isGradeLevelsLoading}
              id="standardsSearchGradeLevel"
              name="standardsSearchGradeLevel"
              value={values.grade_level_id}
              onChange={onGradeLevelChange}
            >
              <option value="">{content.searchGradeLevelAnyOption}</option>
              {gradeLevels.map((g) => (
                <option key={`grade-level-${g.id}`} value={g.id}>
                  {g.grade_level}
                </option>
              ))}
            </Select>
          </FormControl>

          {/* Search Subject */}
          <FormControl>
            <VisuallyHidden>
              <FormLabel htmlFor="standardsSearchSubject">
                {content.searchSubjectLabel}
              </FormLabel>
            </VisuallyHidden>
            <Select
              disabled={isSubjectsLoading}
              id="standardsSearchSubject"
              name="standardsSearchSubject"
              value={values.subject_id}
              onChange={onSubjectChange}
            >
              <option value="">{content.searchSubjectAnyOption}</option>
              {subjects.map((s) => (
                <option key={`subject-${s.id}`} value={s.id}>
                  {s.parent_name ? `${s.parent_name}: ${s.name}` : s.name}
                </option>
              ))}
            </Select>
          </FormControl>
        </Stack>

        {/* Search Standard Collection */}
        {values.region && (
          <FormControl>
            <VisuallyHidden>
              <FormLabel htmlFor="standardsSearchCollection">
                {content.searchStandardCollectionLabel}
              </FormLabel>
            </VisuallyHidden>
            <Select
              disabled={isStandardCollectionsLoading}
              id="standardsSearchCollection"
              name="standardsSearchCollection"
              value={values.standard_collection_id}
              onChange={onStandardCollectionChange}
            >
              <option value="">
                {content.searchStandardCollectionAnyOption}
              </option>
              {sortedStandardCollections.map((s) => {
                const sourceHostname = new URL(s.source_url).hostname.replace(
                  /^(www\.)/,
                  ""
                );
                return (
                  <option key={`standard-collection-${s.id}`} value={s.id}>
                    {s.name}
                    {s.region && " • "} {s.region}
                    {sourceHostname && ` (${sourceHostname})`}
                  </option>
                );
              })}
            </Select>
          </FormControl>
        )}
      </VStack>
    </Box>
  );
};

export default SearchForm;
