import { Box, HStack } from "@chakra-ui/react";
import React from "react";

import { ColorScheme } from "adminComponents";
import { pxToRem } from "adminComponents/utils/pxToRem";

interface IPaginationCirclesProps {
  index: number;
  total: number;
  colorScheme?: ColorScheme;
}
export const PaginationCircles: React.FC<IPaginationCirclesProps> = ({
  index,
  total,
  colorScheme,
}) => {
  return (
    <HStack>
      {Array.from(Array(total)).map((_, i) => (
        <Box
          key={i}
          borderRadius="full"
          boxSize={pxToRem(14)}
          bgColor={
            index === i ? colorScheme || "utility.focus" : "primary.light-gray"
          }
        />
      ))}
    </HStack>
  );
};
