import { useTranslation } from "react-i18next";

export const useStringifyList = (
  list: Array<string>,
  maxItems?: number
): string => {
  const { t } = useTranslation("admin", { useSuspense: false });

  if (!maxItems) return list.join(", ");

  const fullCount = list.length;
  const shortenedList = list.slice(0, maxItems);
  const moreCount = fullCount - shortenedList.length;

  let stringifiedList = shortenedList.join(", ");
  if (moreCount > 0) {
    stringifiedList = `${stringifiedList}, ${t("common.plusXMore", {
      moreCount,
    })}`;
  }
  return stringifiedList;
};

/*
Examples:
const listString = useStringifiedList(list, maxCount)

Ex 1.
  list = ["red", "blue", "yellow", "green"]
  maxCount = 3
  => listString = "red, blue, yellow, +1 More"

Ex 2.
  list = ["red", "blue", "yellow", "green"]
  maxCount = undefined
  => listString = "red, blue, yellow, green"

Ex 3.
  list = ["red"]
  maxCount = 2
  => listString = "red"
*/
