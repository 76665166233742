import { RichTextElementType, RichValue } from "links/lib/types";

const isNodeNonEmpty = (element: RichTextElementType): boolean => {
  if ("type" in element && element.type === "paragraph") {
    return element.children.some((c) => {
      return isNodeNonEmpty(c);
    });
  }

  if ("type" in element && element.type === "latex") {
    return !!element.content.trim();
  }

  if ("text" in element && !("type" in element)) {
    return !!element.text.trim();
  }

  return true;
};

export const isRichTextEmpty = (
  value?: string | RichTextElementType[]
): boolean => {
  if (!value) return true;

  try {
    const editorState =
      typeof value === "string" ? (JSON.parse(value) as RichValue) : value;
    return !editorState.some((e) => isNodeNonEmpty(e));
  } catch (err) {
    console.warn(err);
    return false;
  }
};

export const createSyntheticEvent = <T extends Element, E extends Event>(
  event: E
): React.SyntheticEvent<T, E> => {
  let isDefaultPrevented = false;
  let isPropagationStopped = false;
  const preventDefault = () => {
    isDefaultPrevented = true;
    event.preventDefault();
  };
  const stopPropagation = () => {
    isPropagationStopped = true;
    event.stopPropagation();
  };
  return {
    nativeEvent: event,
    currentTarget: event.currentTarget as EventTarget & T,
    target: event.target as EventTarget & T,
    bubbles: event.bubbles,
    cancelable: event.cancelable,
    defaultPrevented: event.defaultPrevented,
    eventPhase: event.eventPhase,
    isTrusted: event.isTrusted,
    preventDefault,
    isDefaultPrevented: () => isDefaultPrevented,
    stopPropagation,
    isPropagationStopped: () => isPropagationStopped,
    persist: () => {
      return;
    },
    timeStamp: event.timeStamp,
    type: event.type,
  };
};

export const createSyntheticFocusEvent = <
  T extends Element,
  E extends FocusEvent
>(
  event: E
): React.FocusEvent<T> | undefined => {
  if (!event.target) return;
  const synthEvent = createSyntheticEvent<T, E>(event);
  const focusEvent: React.FocusEvent<T> = {
    ...synthEvent,
    relatedTarget: null,
    target: synthEvent.target as EventTarget & T,
  };

  return focusEvent;
};

export const createSyntheticChangeEvent = <T extends Element, E extends Event>(
  event: E
): React.ChangeEvent<T> | undefined => {
  if (!event.target) return;
  const synthEvent = createSyntheticEvent<T, E>(event);
  const changeEvent: React.ChangeEvent<T> = {
    ...synthEvent,
    target: synthEvent.target as EventTarget & T,
  };

  return changeEvent;
};

const isNotAlphabetic = (char: string): boolean => !/[a-zA-Z]/.test(char);

const isEndingChar = (char: string): boolean => {
  let endsSymbol = false;
  switch (char) {
    case " ":
    case "_":
    case "$":
    case "%":
    case "{":
    case "}":
    case "^":
    case "(":
    case ")":
    case "|":
      endsSymbol = true;
  }
  return endsSymbol;
};

const getsCounted = (char: string): boolean => {
  let count = true;
  switch (char) {
    case "{":
    case "}":
    case "^":
    case "_":
    case "[":
    case "]":
      count = false;
  }
  return count;
};

const endsReservedWord = (char: string) => {
  if (isEndingChar(char)) {
    return true;
  }

  if (isNotAlphabetic(char) && char !== "[" && char !== "]") {
    return true;
  }

  return false;
};

export const countCharLatexString = (latexContent: string): number => {
  if (!latexContent) {
    return 0;
  }

  let charCount = 0;
  let onSymbol = false;

  for (const char of latexContent) {
    if (char === "\\") {
      charCount++;
      onSymbol = true;
    } else if (onSymbol) {
      if (endsReservedWord(char)) {
        onSymbol = false;
        if (isNotAlphabetic(char) && !isEndingChar(char)) {
          charCount++;
        }
      }
    } else {
      // dont count special chars that need escaped which would then get counted as a symbol
      if (getsCounted(char)) {
        charCount++;
      }
    }
  }

  // special case because { and } need escaped to use even in a symbol word
  // which is picked up as a symbol in the count
  if (latexContent.includes("\\{")) {
    charCount--;
  }
  if (latexContent.includes("\\}")) {
    charCount--;
  }
  return charCount;
};

const getLatexNodeCharCount = (node: { type?: string; content?: string }) => {
  let charCount = 0;

  if (node?.type === "latex") {
    charCount += countCharLatexString(node?.content || "");
  }

  return charCount;
};

const getTextNodeCharCount = (node: {
  type?: string;
  content?: string;
  text?: string;
}) => {
  if (node.text) {
    return node.text.length;
  } else {
    return 0;
  }
};

export const getTotalCharCount = (value: RichValue): number => {
  let charCount = 0;

  value.forEach((descendant) => {
    const node = descendant as {
      type?: string;
      content?: string;
      children?: { type?: string; content?: string }[];
    };

    if (node?.type === "paragraph") {
      if (node.children) {
        node.children.forEach((child) => {
          charCount += getLatexNodeCharCount(child);
          charCount += getTextNodeCharCount(child);
        });
      }
    }
  });

  return charCount;
};
