import {
  Box,
  HStack,
  useBreakpointValue,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils";
import { IPracticeSetSession } from "links/lib/types";

export interface IProps {
  classroomName: string;
  practiceSetName: string;
  session: IPracticeSetSession;
  handleJoinSession: (session: IPracticeSetSession) => void;
  showClassroomName?: boolean;
  alwaysShowPracticeSetName?: boolean;
  joinButtonTextPrefix?: string; //default is student "Join Class"
  isTransparent?: boolean;
  noXPadding?: boolean;
  centeredPracticeSetName?: boolean;
}

export const LiveClassroomBanner: React.FC<IProps> = ({
  classroomName,
  practiceSetName,
  session,
  handleJoinSession,
  showClassroomName = true,
  alwaysShowPracticeSetName = false,
  joinButtonTextPrefix = "joinClassButton",
  isTransparent,
  noXPadding,
  centeredPracticeSetName,
}) => {
  const isMobile = !!useBreakpointValue({ base: true, md: false });
  const styles = useMultiStyleConfig("AdminLiveClassroomBanner", {
    isTransparent,
    noXPadding,
    centeredPracticeSetName,
  });
  const { t } = useTranslation("admin", { useSuspense: false });

  const showPracticeSetName = useMemo(() => {
    if (!alwaysShowPracticeSetName && isMobile) return false;
    return true;
  }, [alwaysShowPracticeSetName, isMobile]);

  const joinButtonLabel = t(`liveClassroomBanner.${joinButtonTextPrefix}`);
  const joinButtonMinWidthPx = Math.max(joinButtonLabel.length * 10, 96);

  return (
    <HStack sx={styles.banner} spacing={pxToRem(16)} w="full" overflow="hidden">
      <HStack spacing={pxToRem(8)}>
        <Box sx={styles.liveIcon} />
        <Text variant="adminP3Bold">{t("liveClassroomBanner.live")}</Text>
      </HStack>
      <HStack spacing={pxToRem(16)} w="full" justifyContent="center">
        {showClassroomName && (
          <Box
            bgColor="primary.white"
            px={pxToRem(12)}
            py={pxToRem(7)}
            borderRadius={pxToRem(8)}
          >
            <Text
              variant="adminP2"
              wordBreak="break-all"
              textOverflow="ellipsis"
              noOfLines={1}
            >
              {classroomName}
            </Text>
          </Box>
        )}

        {showPracticeSetName && (
          <Text
            variant="adminP2"
            textAlign={centeredPracticeSetName ? "center" : undefined}
            wordBreak="break-all"
            noOfLines={2}
          >
            {practiceSetName}
          </Text>
        )}
      </HStack>
      <Box w={pxToRem(joinButtonMinWidthPx)}>
        <Button
          onClick={() => handleJoinSession(session)}
          variant="adminButtonFilled"
          w="full"
        >
          {joinButtonLabel}
        </Button>
      </Box>
    </HStack>
  );
};
