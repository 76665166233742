import { Square, useMultiStyleConfig } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { PremiumIcon } from "adminComponents/atoms/PremiumIcon";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils/pxToRem";

export interface IProps {
  withText?: boolean;
}

export const PremiumPracticeSetBanner: React.FC<IProps> = ({
  withText = false,
}) => {
  const styles = useMultiStyleConfig("AdminPremiumPracticeSetBanner", {});
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });

  return (
    <Square sx={styles.container}>
      {withText && (
        <Text marginLeft={pxToRem(6)} variant="adminP2" color="white">
          {t("common.premium")}
        </Text>
      )}
      <Square>
        <PremiumIcon size={30} type="set" />
      </Square>
    </Square>
  );
};
