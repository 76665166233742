import { CheckIcon } from "@chakra-ui/icons";
import { Box, Fade } from "@chakra-ui/react";
import React from "react";

export interface IMockCheckboxProps {
  isChecked: boolean;
  size: "sm" | "md" | "lg";
  checkedBGColor?: string;
  checkedColor?: string;
  uncheckedBGColor?: string;
  spacing?: number;
}

export const MockCheckbox: React.FC<IMockCheckboxProps> = ({
  isChecked,
  size,
  checkedBGColor,
  checkedColor,
  uncheckedBGColor,
  children,
  spacing,
}) => {
  checkedBGColor = checkedBGColor || "blue.500";
  checkedColor = checkedColor || "white";
  uncheckedBGColor = uncheckedBGColor || "gray.100";

  const whSizeMap = {
    sm: "16px",
    md: "20px",
    lg: "30px",
  };

  const wh = whSizeMap[size];

  const iconSizeMap = {
    sm: "10px",
    md: "14px",
    lg: "18px",
  };

  const borderRadiusMap = {
    sm: "2px",
    md: "4px",
    lg: "6px",
  };

  return (
    <Box display="flex" alignItems="center">
      <Box
        w={wh}
        h={wh}
        minW={wh}
        minH={wh}
        borderRadius={borderRadiusMap[size]}
        bg={isChecked ? checkedBGColor : uncheckedBGColor}
        transition="background ease-in-out .1s"
      >
        <Fade in={isChecked}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            w={wh}
            h={wh}
          >
            <CheckIcon
              bg="transparent"
              boxSize={iconSizeMap[size]}
              color={checkedColor}
            />
          </Box>
        </Fade>
      </Box>
      {!!children && (
        <Box fontSize={size} ml={spacing || 2}>
          {children}
        </Box>
      )}
    </Box>
  );
};
