import { extendTheme } from "@chakra-ui/react";

import { adminTheme } from "adminComponents/theme";
import { gooperTheme } from "sessionComponents/theme";

import colors from "./colors";
import components from "./components";
import textStyles from "./textStyles";

export const theme = extendTheme(
  {
    styles: {
      global: {
        "html, body, #root": {
          minHeight: "100vh",
          height: "100%",
        },
        // Prevent margin between the KaTeX math function name
        // and the arguments
        ".katex .mord.mathsf": {
          marginRight: "0 !important",
        },
      },
    },
    colors,
    textStyles,
    components,
  },
  adminTheme,
  gooperTheme
);
