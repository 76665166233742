import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";

export interface ICheckCustomUrlCodeParams {
  customUrlCode: string;
  disabled?: boolean;
}

export interface ICheckCustomUrlCodeResponse {
  custom_url_code: string;
  is_valid: boolean;
  is_available: boolean;
  error_message: string;
}

export default function useCheckCustomUrlCode(
  args: ICheckCustomUrlCodeParams
): UseQueryResult<ICheckCustomUrlCodeResponse> {
  const axios = useAxios();

  const { disabled, ...params } = args;

  const query = useQuery<ICheckCustomUrlCodeResponse>(
    ["checkCustomUrlCode", { customUrlCode: args.customUrlCode }, params],
    async (): Promise<ICheckCustomUrlCodeResponse> => {
      return await axios
        .get(`/v1/users-custom-url-code/check`, {
          params: {
            custom_url_code: args.customUrlCode,
          },
        })
        .then((r) => r.data as ICheckCustomUrlCodeResponse);
    },
    { enabled: !disabled }
  );

  return query;
}
