import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { IPracticeSet, PracticeDataDocumentType } from "../../types";

export interface IMutatePracticeSetCollectionArgs {
  practice_set_id: string;
  collection_id: string;
}

export interface IMutatePracticeSetCollectionResponse {
  practice_set: IPracticeSet;
}

export interface IUseMutatePracticeSetCollectionProps {
  onSuccess?: (data: IMutatePracticeSetCollectionResponse) => void;
  onError?: () => void;
  onSettled?: () => void;
}

export default function useMutatePracticeSetCollection(
  props?: IUseMutatePracticeSetCollectionProps
): UseMutationResult<
  IMutatePracticeSetCollectionResponse,
  unknown,
  IMutatePracticeSetCollectionArgs
> {
  const axios = useAxios();
  const queryClient = useQueryClient();

  const mutatePracticeSetCollection = async (
    args: IMutatePracticeSetCollectionArgs
  ) => {
    const { practice_set_id, collection_id } = args;

    const response = await axios({
      method: "put",
      url: `/v1/practice-sets/${practice_set_id}/collection`,
      data: {
        practice_set_id,
        collection_id,
      },
    }).then((r) => r.data as IMutatePracticeSetCollectionResponse);

    queryClient.invalidateQueries(["collections"], { exact: false });
    queryClient.invalidateQueries(["practiceSets"], { exact: false });

    const invalidateSearchPracticeData = () => {
      queryClient.invalidateQueries([
        "searchPracticeDataInfinite",
        {
          include_shared: false,
          document_type: PracticeDataDocumentType.PracticeSets,
        },
      ]);
      queryClient.invalidateQueries([
        "searchPracticeData",
        {
          include_shared: false,
          document_type: PracticeDataDocumentType.PracticeSets,
        },
      ]);
    };

    invalidateSearchPracticeData();

    // Provide some time for the search index to be updated post-move
    setTimeout(invalidateSearchPracticeData, 2000);
    setTimeout(invalidateSearchPracticeData, 5000);

    queryClient.setQueryData(
      ["practiceSets", { id: response.practice_set.id }],
      {
        practice_set: response.practice_set,
      }
    );

    return response;
  };

  const mutation = useMutation(mutatePracticeSetCollection, {
    onError: props?.onError,
    onSuccess: props?.onSuccess,
    onSettled: props?.onSettled,
  });

  return {
    ...mutation,
  };
}
