import {
  Box,
  Flex,
  SimpleGrid,
  Square,
  VStack,
  useBreakpointValue,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Avatar } from "adminComponents/atoms/Avatar";
import { Card } from "adminComponents/atoms/Card";
import { Icon } from "adminComponents/atoms/Icon";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils";
import { IGradeLevel, ISubject, IUser } from "links/lib/types";

export enum TopProperty {
  SESSIONS = "session",
  ASSIGNMENTS = "assignment",
}

interface IProps {
  teacher: IUser;
  gradeLevels: Array<IGradeLevel>;
  subjects: Array<ISubject>;
  groupCount: string;
  studentCount: string;
  topPropertyCount: string;
  topProperty: TopProperty;
  hasNoPremiumAccess: boolean;
}

export const TopTeacherCard: React.FC<IProps> = ({
  teacher,
  gradeLevels,
  subjects,
  groupCount,
  studentCount,
  topPropertyCount,
  topProperty,
  hasNoPremiumAccess,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  const user = teacher;
  const {
    email,
    family_name,
    given_name,
    profile_image_url,
    teacher_preferred_name,
  } = user;

  const fullName = given_name
    ? given_name + `${family_name ? " " + family_name : ""}`
    : undefined;

  const name = teacher_preferred_name
    ? teacher_preferred_name
    : fullName
    ? fullName
    : undefined;

  const styles = useMultiStyleConfig("AdminTopTeacherCard", {});

  const avatarSize = useBreakpointValue({
    base: "lg",
    md: "xl",
  });

  const teacherGradeLevels = useMemo(() => {
    const teacherGradeLevelObjects = teacher?.teacher_grade_levels
      ?.map((teacherGradeLevel) =>
        gradeLevels.find(
          (gradeLevel) => gradeLevel.id === teacherGradeLevel.grade_level_id
        )
      )
      .filter((grade) => !!grade);

    const teacherGradeLevelStrings = teacherGradeLevelObjects?.map(
      (teacherGradeLevel) => teacherGradeLevel?.grade_level
    );

    return teacherGradeLevelStrings?.join(", ");
  }, [gradeLevels, teacher?.teacher_grade_levels]);

  const teacherSubjects = useMemo(() => {
    const teacherSubjectObjects = teacher?.teacher_subjects
      ?.map((teacherSubject) =>
        subjects.find((subject) => subject.id === teacherSubject.subject_id)
      )
      .filter((subject) => !!subject);

    const teacherSubjectStrings = teacherSubjectObjects?.map(
      (teacherSubject) => teacherSubject?.name
    );

    return teacherSubjectStrings?.join(", ");
  }, [subjects, teacher?.teacher_subjects]);

  const gradesAndSubjects = useMemo(() => {
    return (
      <SimpleGrid columns={1}>
        <Flex gap={pxToRem(4)}>
          <Text
            variant="adminP3"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {teacherGradeLevels}
          </Text>
          {teacherGradeLevels !== "" && teacherSubjects !== "" && (
            <Text variant="adminP3">{"•"}</Text>
          )}
          <Text
            variant="adminP3"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {teacherSubjects}
          </Text>
        </Flex>
      </SimpleGrid>
    );
  }, [teacherGradeLevels, teacherSubjects]);

  const groupsAndStudents = useMemo(() => {
    const totalGroupCount = parseInt(groupCount);
    const totalStudentCount = parseInt(studentCount);
    const groupsAndStudentsArray = [];
    groupsAndStudentsArray.push(
      t("districtAdminHomepage.topTeachers.groupCount", {
        count: totalGroupCount,
        total: totalGroupCount.toLocaleString(),
      }),
      t("districtAdminHomepage.topTeachers.studentCount", {
        count: totalStudentCount,
        total: totalStudentCount.toLocaleString(),
      })
    );

    return groupsAndStudentsArray.join(" • ");
  }, [groupCount, studentCount, t]);

  const icon =
    topProperty === TopProperty.SESSIONS
      ? "person_outlined"
      : "calendar_today_outlined";

  return (
    <Card
      backgroundColor={"primary.white"}
      borderColor="primary.tan"
      sx={styles.container}
      variant={"adminCardSmallBorderSmallPadding"}
    >
      <Box __css={styles.left}>
        <Box __css={styles.avatar}>
          <Avatar
            name={name || ""}
            size={avatarSize}
            src={profile_image_url || ""}
          />
        </Box>
        <Box
          __css={styles.content}
          sx={{
            filter: hasNoPremiumAccess ? `blur(5px)` : undefined,
            WebkitFilter: hasNoPremiumAccess ? `blur(5px)` : undefined,
          }}
        >
          <VStack align="start">
            <Text variant="adminP1">{name || email}</Text>
            {gradesAndSubjects}
            <Text variant="adminP3">{groupsAndStudents}</Text>
          </VStack>
        </Box>
      </Box>
      <Box __css={styles.right}>
        <Box __css={styles.statContainer}>
          <Square
            borderRadius="full"
            backgroundColor="primary.light-gray"
            padding={pxToRem(8)}
          >
            <Square
              borderRadius="full"
              borderWidth={pxToRem(2)}
              borderColor={
                topProperty === TopProperty.SESSIONS ? "black" : undefined
              }
            >
              <Icon icon={icon} boxSize={pxToRem(24)} />
            </Square>
          </Square>
          <Box __css={styles.container}>
            <Text variant="adminP1">
              {t(`districtAdminHomepage.topTeachers.${topProperty}Count`, {
                count: parseInt(topPropertyCount),
                total: parseInt(topPropertyCount).toLocaleString(),
              })}
            </Text>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};
