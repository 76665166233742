import {
  Flex,
  HStack,
  Show,
  SimpleGrid,
  VStack,
  useBreakpointValue,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Avatar } from "adminComponents/atoms/Avatar";
import { Heading } from "adminComponents/atoms/Heading";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils";
import { generateTeacherName } from "adminComponents/utils/generateTeacherName";
import { IUser } from "links/lib/types";

import { QuickStat } from "./components/QuickStat";

export interface IProps {
  teacher: IUser;
  individualSessionCount?: number;
  prevWindowIndividualSessionCount?: number;
  socialEventReactionsCount?: number;
  prevWindowSocialEventReactionsCount?: number;
  handleTopIndividualPracticersModalOpen?: () => void;
  handleTopSocialEventReactorsModalOpen?: () => void;
}

const percentChange = (value: number, previousValue: number): number => {
  return ((value - previousValue) / previousValue) * 100;
};

export const TeacherHomepageHeader: React.FC<IProps> = ({
  teacher,
  individualSessionCount,
  prevWindowIndividualSessionCount,
  socialEventReactionsCount,
  prevWindowSocialEventReactionsCount,
  handleTopIndividualPracticersModalOpen,
  handleTopSocialEventReactorsModalOpen,
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });
  const styles = useMultiStyleConfig("AdminTeacherHomepage", {});
  const headingVariant = (useBreakpointValue({
    base: "adminH2",
    md: "adminH3",
    lg: "adminH2",
  }) ?? "adminH2") as "adminH3" | "adminH2";
  const avatarSize = useBreakpointValue({ base: "lg", lg: "xl" }) ?? "xl";

  const teacherName = generateTeacherName(teacher)?.primary;

  const showMonthlyStats =
    individualSessionCount !== undefined ||
    socialEventReactionsCount !== undefined;

  return (
    <Flex sx={styles.headerContainer}>
      <HStack gap={[pxToRem(4), null, null, pxToRem(16)]}>
        <Show above="md">
          <Flex sx={styles.headerAvatarContainer}>
            <Avatar
              size={avatarSize}
              src={teacher.profile_image_url}
              name={teacherName}
              backgroundColor="primary.golden-hover"
              showEditIcon={true}
              navigationLink={{
                to: "/t/my-avatar",
                label: t("teacherHomepage.myAvatar"),
              }}
            />
          </Flex>
        </Show>
        <VStack sx={styles.header}>
          <Heading variant={headingVariant} as="h1">
            {t("teacherHomepage.welcome", { teacherName })}
          </Heading>
          {showMonthlyStats && (
            <>
              <Text variant="adminP2Bold" as="h2">
                {t("teacherHomepageImproved.quickStats.headingMonthlyStats")}
              </Text>
              <SimpleGrid columns={[1, null, 2]} gap={pxToRem(12)} w="full">
                {individualSessionCount !== undefined && (
                  <QuickStat
                    title={t(
                      "teacherHomepageImproved.quickStats.individualSessionsTitle"
                    )}
                    icon="person_outlined"
                    value={individualSessionCount}
                    percentChange={
                      prevWindowIndividualSessionCount &&
                      percentChange(
                        individualSessionCount,
                        prevWindowIndividualSessionCount
                      )
                    }
                    handleClick={() => {
                      if (individualSessionCount > 0) {
                        handleTopIndividualPracticersModalOpen?.();
                      }
                    }}
                  />
                )}
                {socialEventReactionsCount !== undefined && (
                  <QuickStat
                    title={t(
                      "teacherHomepageImproved.quickStats.socialEventReactionsTitle"
                    )}
                    icon="high_five"
                    value={socialEventReactionsCount}
                    percentChange={
                      prevWindowSocialEventReactionsCount &&
                      percentChange(
                        socialEventReactionsCount,
                        prevWindowSocialEventReactionsCount
                      )
                    }
                    handleClick={() => {
                      if (socialEventReactionsCount > 0) {
                        handleTopSocialEventReactorsModalOpen?.();
                      }
                    }}
                  />
                )}
              </SimpleGrid>
            </>
          )}
        </VStack>
      </HStack>
    </Flex>
  );
};
