import { useEffect, useMemo } from "react";

import { emptyPracticeSet } from "links/lib/empty";
import { useParseLLMPracticeSetItems } from "links/lib/features/practiceSetItems";
import {
  ILLMPracticeSetResponse,
  IPracticeSet,
  IPracticeSetItem,
  PracticeSetAvailability,
} from "links/lib/types";

export interface IUseParseLLMPracticeSetResult {
  practiceSet: IPracticeSet;
  isLoading: boolean;
  practiceSetItems?: IPracticeSetItem[];
  errors: string[];
}

export const useParseLLMPracticeSet = (
  practiceSetString: string
): IUseParseLLMPracticeSetResult => {
  const errors: string[] = [];

  const llmSet: ILLMPracticeSetResponse | undefined = useMemo(() => {
    try {
      return JSON.parse(practiceSetString);
    } catch (error) {
      errors.push(JSON.stringify(error));
      return undefined;
    }
    // eslint-disable-next-line
  }, [practiceSetString]);

  const { isLoading, data, mutate, error } = useParseLLMPracticeSetItems({});

  useEffect(() => {
    if (llmSet) {
      mutate({ items: llmSet?.questions });
    }
  }, [mutate, llmSet]);

  if (error) {
    errors.push(`Error parsing practice set items: ${error}`);
  }
  if (!llmSet) {
    errors.push("Could not parse practice set. Check format.");
  } else {
    if (!llmSet?.title) {
      errors.push("Could not parse practice set title. Check format.");
    }
    if (!llmSet?.description) {
      errors.push("Could not parse practice set description. Check format.");
    }
    if (!llmSet?.questions || llmSet?.description?.length < 1) {
      errors.push("Could not parse practice set questions. Check format.");
    }
  }

  const parsedSet: IPracticeSet = {
    ...emptyPracticeSet,
    title: llmSet?.title || "",
    description: llmSet?.description || "",
    is_certified: true,
    is_premium: true,
    availability: PracticeSetAvailability.Private,
  };

  if (data?.practice_set_items?.length) {
    parsedSet.item_count = data?.practice_set_items?.length;
  }

  return {
    practiceSet: parsedSet,
    isLoading,
    practiceSetItems: data?.practice_set_items,
    errors,
  };
};
