import { Box } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { animated, config, useSpring } from "react-spring";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { SessionConnectionStatus } from "links/lib/types";
import { PenColorPicker } from "sessionComponents/molecules/PenColorPicker";

import { useBreakpoints } from "../../contexts/breakpoints";
import { AvatarIcon } from "../AvatarIcon";
import { Icon } from "../Icon";

export interface IUserDrawIconProps {
  isConfirmed: boolean;
  userId: string;
  penColor: string;
  avatarImgUrl: string;
  avatarIconSize?: string;
  userName: string;
  userConnectionStatus: SessionConnectionStatus;
  isSelf?: boolean;
  onSelect?: (color: string) => void;
}

const AnimatedBox = animated(Box);

export const UserDrawIcon: React.FC<IUserDrawIconProps> = ({
  userId,
  isConfirmed,
  penColor,
  avatarImgUrl,
  avatarIconSize,
  userName,
  userConnectionStatus,
  isSelf,
  onSelect,
}) => {
  const { match: currentBreakpoints } = useBreakpoints();
  const { t } = useTranslation("session", {
    keyPrefix: "round.answerConfirmation",
  });

  const confirmSpring = useSpring({
    from: {
      opacity: 0,
      scale: 0,
    },
    to: {
      opacity: isConfirmed ? 1 : 0,
      scale: isConfirmed ? 1 : 0,
    },
    config: config.wobbly,
  });

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
      key={userId}
      opacity={
        userConnectionStatus !== SessionConnectionStatus.Connected
          ? "0.7"
          : undefined
      }
      role="img"
      aria-label={
        isConfirmed
          ? t("hasConfirmedAnswer", { name: userName })
          : t("hasNotConfirmedAnswer", { name: userName })
      }
    >
      <Box marginRight={pxToRem(currentBreakpoints.margin / 4)}>
        <AvatarIcon
          avatarImgUrl={avatarImgUrl}
          avatarIconSize={avatarIconSize}
          name={userName}
          isSelf={isSelf}
        />
      </Box>
      {!isConfirmed && !!onSelect && (
        <PenColorPicker onSelect={(color) => onSelect?.(color)}>
          <Box
            borderColor="primary.light-gray"
            cursor="pointer"
            borderRadius="100%"
            _hover={{
              borderColor: "light.golden",
            }}
            borderWidth={pxToRem(currentBreakpoints.borderWidth)}
            p={pxToRem(5)}
          >
            <Box
              borderRadius="100%"
              boxSize={pxToRem(currentBreakpoints.fontSize - 5)}
              bg={penColor}
            />
          </Box>
        </PenColorPicker>
      )}
      {!isConfirmed && !onSelect && (
        <Box
          borderRadius="100%"
          boxSize={pxToRem(currentBreakpoints.fontSize)}
          bg={penColor}
        />
      )}

      {isConfirmed && (
        <AnimatedBox
          w={pxToRem(currentBreakpoints.iconSize)}
          h={pxToRem(currentBreakpoints.iconSize)}
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={confirmSpring}
        >
          <Icon
            icon="checkmark"
            iconColor="utility.link"
            width={pxToRem(currentBreakpoints.iconSize)}
          />
        </AnimatedBox>
      )}
    </Box>
  );
};
