import { pxToRem } from "adminComponents/utils/pxToRem";

export const AdminAssignmentCard = {
  baseStyle: {
    body: {
      display: "flex",
      flexDirection: { base: "column", md: "row" },
      alignItems: { base: "start", md: "center" },
      justifyContent: "space-between",
      gap: { base: pxToRem(16), md: pxToRem(40) },
    },
    progress: {
      flexShrink: 0,
    },
    title: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    coverImage: {
      marginRight: { base: pxToRem(16), md: pxToRem(20) },
      width: { base: pxToRem(60), md: pxToRem(100) },
      flexShrink: 0,
    },
    divider: {
      display: { md: "none" },
    },
    upcoming: {
      display: "flex",
      direction: "row",
      gap: [pxToRem(12), null, pxToRem(20)],
      marginLeft: "auto",
    },
    linkOverlay: {
      _focus: {
        boxShadow: "none",
        outline: "none",
      },
      _focusVisible: {
        outlineStyle: "solid",
        outlineWidth: pxToRem(2),
        outlineColor: "utility.focus",
      },
    },
  },
};
