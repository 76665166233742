import moment from "moment";
import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { INavigationHeaderProps } from "adminComponents/molecules/NavigationHeader";
import { generateTeacherName } from "adminComponents/utils";
import { useAnalytics } from "lib/contexts/analytics";
import { useAuth } from "links/lib/features/auth";
import { useFetchCollections } from "links/lib/features/collections";
import { useFetchGradeLevels } from "links/lib/features/gradeLevels";
import { useFetchSubjects } from "links/lib/features/subjects";
import { AnalyticsEvent, UserRole } from "links/lib/types";
import { useCreatePracticeSet } from "sharedComponents/hooks/useCreatePracticeSet";
import { useShowNewLibrary } from "sharedComponents/hooks/useShowNewLibrary";

import { useOnboardingChecklist } from "../hooks/useOnboardingChecklist";

export type INavigationData = INavigationHeaderProps;

export interface IProps {
  adminMode?: boolean;
}

export interface INavigationDataContext {
  navigationData: INavigationData;
}

export const NavigationDataContext = createContext<INavigationDataContext>({
  navigationData: {
    variant: "teacher",
    logoLink: {
      label: "",
      to: "",
    },
    avatarData: {
      src: "",
      name: "",
    },
    breadcrumbs: [],
    navigationLinks: [],
    accountLinks: [],
    resourceLinks: [],
    authActionLinks: [],
    user: {
      id: "",
      email: "",
      role: UserRole.Teacher,
      true_role: UserRole.Teacher,
      license_id: "",
      timezone_locale: "",
      language: "",
      country: "",
      region: "",
      is_guest: false,
    },
    showGracePeriodBanner: false,
    gracePeriodEndDate: undefined,
    subjects: [],
    parentSubjects: [],
    gradeLevels: [],
    showPremiumMarker: true,
    hasNoPremiumAccess: false,
    handleCreatePracticeSet: () => {
      return;
    },
    teacherOnboardingChecklist: undefined,
  },
});

export const useNavigationData = (): INavigationDataContext => {
  return useContext(NavigationDataContext);
};

const TeacherNavigationDataProvider: React.FC<PropsWithChildren<IProps>> = ({
  adminMode,
  children,
}) => {
  const { authUser, isFeatureEnabled, authLicense, hasNoPremiumAccess } =
    useAuth();
  const newLibraryFeatureEnabled = useShowNewLibrary();
  const { t } = useTranslation("admin", {
    keyPrefix: "adminNavigationHeader",
    useSuspense: false,
  });
  const { trackEvent } = useAnalytics();

  const location = useLocation();
  let topPathName = location.pathname.split("/").slice(0, 3).join("/");

  const gradeLevelFetch = useFetchGradeLevels({
    country: authUser?.country || "US",
    limit: 100,
  });
  const subjectsFetch = useFetchSubjects({
    country: authUser?.country || "US",
    limit: 100,
  });
  const fetchCollections = useFetchCollections();
  const allCollections = fetchCollections.data?.collections || [];

  const { teacherOnboardingChecklist, checklistElement } =
    useOnboardingChecklist({
      useBannerOnboarding: newLibraryFeatureEnabled,
    });

  const gradeLevels = useMemo(
    () => gradeLevelFetch.data?.gradeLevels || [],
    [gradeLevelFetch.data?.gradeLevels]
  );
  const [subjects, parentSubjects] = useMemo(() => {
    const subjects = subjectsFetch.data?.subjects || [];
    const parentSubjects = subjects.filter((s) => s.parent_id === "0");

    return [subjects, parentSubjects];
  }, [subjectsFetch.data?.subjects]);

  const {
    handleCreate: handleCreatePracticeSet,
    flyout: createPracticeSetFlyout,
  } = useCreatePracticeSet({
    subjects,
    gradeLevels,
    collections: allCollections,
    onOpen: () =>
      trackEvent(
        AnalyticsEvent.TeacherDashboard_Nav_PracticeSetFlyout_CreatePracticeSetIntent,
        {}
      ),
    onSubmit: (data) =>
      trackEvent(
        AnalyticsEvent.TeacherDashboard_Nav_PracticeSetFlyout_CreatePracticeSetConfirm,
        {
          availability: data.availability,
        }
      ),
  });

  // These two sections share the same top-navigation
  if (topPathName === "/t/my-school-library") {
    topPathName = "/t/public-library";
  }

  // is_in_grace_period is only set by the backend if the license is an organizational license
  const isInOrganization =
    authUser?.organization_id && authUser.organization_id !== "0";
  const organizationLicenseInGracePeriod =
    authLicense?.is_in_grace_period || false;
  const individualTrialExpiringSoon =
    !isInOrganization &&
    moment(authLicense?.end_date).diff(moment(), "days") <= 14 &&
    moment(authLicense?.end_date).diff(moment(), "days") > 0;
  const showGracePeriodBanner =
    (window.location.pathname.startsWith("/t/home") ||
      window.location.pathname.startsWith("/t/explore")) &&
    (organizationLicenseInGracePeriod || individualTrialExpiringSoon);

  const navigationData: INavigationDataContext = useMemo(() => {
    const userName = generateTeacherName(authUser || {}).primary;

    const resourceLinks = [];

    if (authUser?.role === UserRole.Admin) {
      resourceLinks.push({
        to: "/a/reporting",
        label: t("resourceLinks.adminTools"),
        isExternal: true,
      });
    }

    resourceLinks.push(
      ...[
        {
          to: "https://support.giantsteps.app",
          label: t("resourceLinks.support"),
          isExternal: true,
        },
        {
          to: "https://support.giantsteps.app/s/article/Getting-Started-with-Pear-Practice",
          label: t("resourceLinks.gettingStarted"),
          isExternal: true,
        },
        {
          to: "https://forms.gle/qLNHzRigr2TNT8GY6",
          label: t("resourceLinks.provideFeedback"),
          isExternal: true,
        },
      ]
    );

    const authActionLinks = [
      {
        to: "/t/student-session",
        label: t("resourceLinks.openAsStudent"),
        isExternal: true,
      },
      {
        to: "/sign-out",
        label: t("accountLinks.signOut"),
      },
    ];

    let navLinks = [
      newLibraryFeatureEnabled
        ? { to: "/t/explore", label: t("navigationLinks.explore") }
        : {
            to: "/t/home",
            label: t("navigationLinks.home"),
          },
      {
        to: "/t/classrooms",
        label: t("navigationLinks.classrooms"),
      },
      {
        to: "/t/my-library",
        label: t("navigationLinks.myLibrary"),
      },
      {
        to: "/t/public-library",
        label: t("navigationLinks.sharedLibraries"),
      },
      authUser?.role === UserRole.Admin
        ? {
            to: "/a/reporting",
            label: t("resourceLinks.adminTools"),
            isExternal: true,
          }
        : undefined,
    ];

    if (newLibraryFeatureEnabled) {
      navLinks = [
        { to: "/t/explore", label: t("navigationLinks.explore") },
        {
          to: "/t/my-library",
          label: t("navigationLinks.myLibrary"),
        },
        {
          to: "/t/classrooms",
          label: t("navigationLinks.classrooms"),
        },
        authUser?.role === UserRole.Admin
          ? {
              to: "/a/reporting",
              label: t("resourceLinks.adminTools"),
              isExternal: true,
            }
          : undefined,
      ];
    }

    if (adminMode) {
      navLinks = [
        {
          to: "/a/reporting",
          label: t("navigationLinks.reporting"),
        },
        {
          to: "/a/my-teachers",
          label: t("navigationLinks.myTeachers"),
        },
      ];
    }

    return {
      navigationData: {
        variant: "teacher",
        logoLink: {
          label: adminMode
            ? t("navigationLinks.reporting")
            : newLibraryFeatureEnabled
            ? t("navigationLinks.explore")
            : t("navigationLinks.home"),
          to: adminMode
            ? "/a/reporting"
            : newLibraryFeatureEnabled
            ? "/t/explore"
            : "/t/home",
        },
        avatarData: {
          src: authUser?.profile_image_url || "",
          name: userName,
          backgroundColor: adminMode ? "primary.light-gray" : undefined,
        },
        breadcrumbs: [],
        navigationLinks: navLinks.filter((a) => !!a) as Array<{
          to: string;
          label: string;
          isExternal?: boolean;
        }>,
        accountLinks: [
          {
            to: "/t/my-avatar",
            label: t("accountLinks.myAvatar"),
          },
          {
            to: `/t/profiles/${authUser?.custom_url_code}`,
            label: t("accountLinks.mySharingProfile"),
          },
          {
            to: "/t/account",
            label: t("accountLinks.account"),
          },
        ],
        resourceLinks,
        authActionLinks,
        activeNavigationPath: topPathName,
        subjects,
        parentSubjects,
        gradeLevels,
        user: authUser,
        userLicense: authLicense,
        showGracePeriodBanner,
        gracePeriodEndDate: moment(authLicense?.end_date),
        showPremiumMarker: !isFeatureEnabled("playtime.teacher.hide_premium"),
        hasNoPremiumAccess,
        handleCreatePracticeSet: adminMode
          ? undefined
          : handleCreatePracticeSet,
        teacherOnboardingChecklist,
      },
    };
  }, [
    authUser,
    isFeatureEnabled,
    newLibraryFeatureEnabled,
    t,
    adminMode,
    topPathName,
    subjects,
    parentSubjects,
    gradeLevels,
    authLicense,
    showGracePeriodBanner,
    hasNoPremiumAccess,
    handleCreatePracticeSet,
    teacherOnboardingChecklist,
  ]);

  return (
    <>
      <NavigationDataContext.Provider value={navigationData}>
        {children}
      </NavigationDataContext.Provider>

      {createPracticeSetFlyout}
      {!newLibraryFeatureEnabled && checklistElement}
    </>
  );
};

export { TeacherNavigationDataProvider };
