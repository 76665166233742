import { Box } from "@chakra-ui/react";
import React from "react";
import { RenderElementProps } from "slate-react";

import { hoverStyles } from "adminComponents/utils";
import { pxToRem } from "adminComponents/utils";
import { useStaticLatex } from "lib/hooks/useStaticLatex";

import InlineChromiumBugfix from "./InlineChromiumBugfix";

export interface ILatexElementProps {
  handleEditIntent: (key: string, content: string) => void;
  element: {
    key: string;
    type: string;
    content: string;
  };
}

const LatexElement: React.FC<ILatexElementProps & RenderElementProps> = (
  props
) => {
  const { content, key } = props.element;
  const { result: latexRenderResult } = useStaticLatex(
    props.element.content,
    {}
  );

  return (
    <Box
      as="span"
      {...props.attributes}
      _hover={hoverStyles({ background: "primary.golden-light" })}
      cursor="pointer"
      contentEditable={false}
      onClick={() => props.handleEditIntent(key, content)}
      overflowY="hidden"
      overflowX="auto"
      maxWidth="100%"
      display="inline-flex"
      verticalAlign="middle"
      padding={pxToRem(2)}
      sx={{
        "&::-webkit-scrollbar": {
          WebkitAppearance: "none",
          width: pxToRem(10),
          height: pxToRem(10),
          backgroundColor: "primary.light-gray",
        },

        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "rgba(0, 0, 0, .3)",
        },
      }}
    >
      <InlineChromiumBugfix />
      {props.children}
      <InlineChromiumBugfix />
      <span dangerouslySetInnerHTML={{ __html: latexRenderResult }} />
    </Box>
  );
};

export default LatexElement;
