import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { pxToRem } from "adminComponents/utils";
import { ISessionGroup, SessionConnectionStatus } from "links/lib/types";
import { GroupToken } from "sessionComponents/atoms/GroupToken";
import { Modal } from "sessionComponents/atoms/Modal";
import { OnlineStatusCircle } from "sessionComponents/atoms/OnlineStatusCircle";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { useGroupUsers } from "sessionComponents/hooks/useGroupUsers";
import { useDetectOrientation } from "sessionComponents/theme/hooks/useDetectOrientation";
import { getAssetColorScheme } from "sessionComponents/utils/getAssetColorScheme";
import { getFormattedGroupName } from "sessionComponents/utils/getFormattedGroupName";
import { getFormattedTeamNames } from "sessionComponents/utils/getFormattedTeamNames";
import { useSafeGroupsArray } from "sharedComponents/hooks/useSafeGroupsArray";

interface IAllTeamsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const AllTeamsModal: React.FC<IAllTeamsModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { match: currentBreakpoints } = useBreakpoints();
  const safeGroups = useSafeGroupsArray();

  const { t } = useTranslation("session", {
    keyPrefix: "teacherHeader",
    useSuspense: false,
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      landscapeSize="5xl"
      modalHeaderContent={
        <Box
          as="h2"
          fontSize={pxToRem(currentBreakpoints.fontSize)}
          textStyle="gameDisplayInline"
        >
          {t("teamsModalTitle")}
        </Box>
      }
    >
      <>
        {safeGroups.map((group) => (
          <Team key={group.id} group={group} />
        ))}
      </>
    </Modal>
  );
};

const Team = ({ group }: { group: ISessionGroup }) => {
  const {
    match: { fontSize, margin },
  } = useBreakpoints();
  const { isPortrait } = useDetectOrientation();
  const displayName = getFormattedGroupName(group);
  const groupUsers = useGroupUsers(group.id).map((user) => ({
    name: getFormattedTeamNames([user]),
    status: user.connection_status,
  }));

  return (
    <Flex alignItems="center" marginBottom={pxToRem(margin)}>
      <Box marginRight={pxToRem(margin)}>
        <GroupToken
          colorScheme={getAssetColorScheme(group.color_scheme)}
          imageUrl={group.icon_url}
          name={displayName}
          hideTooltip
        />
      </Box>
      <Flex flexDirection={isPortrait ? "column" : "row"}>
        <Box
          as="h2"
          fontSize={pxToRem(fontSize)}
          textStyle="gameDisplayInline"
          marginRight={isPortrait ? 0 : pxToRem(margin)}
        >
          {displayName}
        </Box>

        <Flex alignItems="center">
          {groupUsers.map((u, i) => {
            const isLastItem = i === groupUsers.length - 1;

            return u.status === SessionConnectionStatus.Connected ? (
              <TeamMemberName
                name={u.name}
                isLastItem={isLastItem}
                fontSize={fontSize}
              />
            ) : (
              <Flex alignItems="center" marginRight={pxToRem(5)}>
                <Box marginX={pxToRem(5)}>
                  <OnlineStatusCircle
                    status={u.status}
                    size={fontSize * 0.75}
                  />
                </Box>
                <TeamMemberName
                  isOffline
                  name={u.name}
                  isLastItem={isLastItem}
                  fontSize={fontSize}
                />
              </Flex>
            );
          })}
        </Flex>
      </Flex>
    </Flex>
  );
};

const TeamMemberName = ({
  isOffline,
  fontSize,
  name,
  isLastItem,
}: {
  isOffline?: boolean;
  fontSize: number;
  name: string;
  isLastItem: boolean;
}) => (
  <Text
    textStyle="gameText"
    fontWeight={400}
    color={isOffline ? "utility.error" : "primary.dark-gray"}
    fontSize={pxToRem(fontSize)}
    fontStyle={isOffline ? "italic" : undefined}
  >
    {name}
    {!isLastItem && ","}
  </Text>
);
