import React, { createContext, useContext, useMemo } from "react";

import {
  BigBoardGameState,
  QuickPlayGameState,
  SessionGameState,
  SessionGameType,
} from "../types";

export const SessionGameStateContext = createContext<
  SessionGameState | undefined
>(undefined);

export interface ISessionGameStateProviderProps {
  wsGameState: SessionGameState;
}

export const SessionGameStateProvider: React.FC<
  ISessionGameStateProviderProps
> = ({ children, wsGameState }) => {
  const value = useMemo(() => {
    return wsGameState.game_type === SessionGameType.QuickPlay
      ? (wsGameState as QuickPlayGameState)
      : (wsGameState as BigBoardGameState);
  }, [wsGameState]);
  return (
    <SessionGameStateContext.Provider value={value}>
      {children}
    </SessionGameStateContext.Provider>
  );
};

export const useSessionGameState = (): SessionGameState | undefined => {
  return useContext(SessionGameStateContext);
};
