import { VStack } from "@chakra-ui/react";
import React from "react";

import {
  PracticeSetQuestionCard,
  PracticeSetQuestionCardType,
} from "adminComponents/molecules/PracticeSetQuestionCard";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { IPracticeSetItemWithClassroomResults } from "links/lib/types";

import { PracticeSetPlaceholderCard } from "../PracticeSetPlaceholderCard";

export interface IItemListProps {
  items: Array<IPracticeSetItemWithClassroomResults>;
  cardType: PracticeSetQuestionCardType;
  hasNoPremiumAccess?: boolean;
  upgradeText?: string;
}

export const StudentReportQuestionList: React.FC<IItemListProps> = ({
  items,
  cardType,
  hasNoPremiumAccess = false,
  upgradeText,
}) => {
  if (hasNoPremiumAccess && upgradeText) {
    return <PracticeSetPlaceholderCard upgradeText={upgradeText} />;
  }

  return (
    <VStack w="full" spacing={{ base: pxToRem(16), md: pxToRem(32) }}>
      {items.map((item, index) => (
        <PracticeSetQuestionCard
          key={`item-${item.practiceSetItem.guid}`}
          practiceSetItem={item.practiceSetItem}
          questionNumber={index + 1}
          cardType={cardType}
          studentResultsReport={item.classroomResults}
        />
      ))}
    </VStack>
  );
};
