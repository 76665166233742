import { Box } from "@chakra-ui/react";
import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { useAuth } from "links/lib/features/auth";
import { UserRole } from "links/lib/types";

import SkillEditor from "./components/SkillEditor";
import SkillSearch from "./components/SkillSearch";

const Skills: React.FC = () => {
  const { authUser } = useAuth();

  const match = useRouteMatch();

  if (!authUser || authUser.role !== UserRole.ContentSpecialist) return null;

  return (
    <Box minH="100vh">
      <Switch>
        <Route path={`${match.path}`} exact={true} component={SkillSearch} />
        <Route path={`${match.path}/:skillId`} component={SkillEditor} />
      </Switch>
    </Box>
  );
};

export default Skills;
