import { Box, Flex, FormControl, Image, SimpleGrid } from "@chakra-ui/react";
import { ReactJSXIntrinsicAttributes } from "@emotion/react/types/jsx-namespace";
import React, { useState } from "react";
import { DropzoneInputProps, FileRejection, useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";

import { Button } from "adminComponents/atoms/Button";
import { FormLabel } from "adminComponents/atoms/FormLabel";
import { IconTooltip } from "adminComponents/atoms/IconTooltip";
import { ResponsiveHeading } from "adminComponents/atoms/ResponsiveHeading";
import { Text } from "adminComponents/atoms/Text";
import { Textarea } from "adminComponents/atoms/Textarea";
import { hoverStyles, pxToRem } from "adminComponents/utils";
import { checkInvalidSVG } from "adminComponents/utils/checkInvalidSVG";
import { checkResizeImageFile } from "adminComponents/utils/checkResizeImageFile";
import {
  MAX_ALT_TEXT_LENGTH,
  MAX_IMAGE_UPLOAD_SIZE,
} from "links/lib/constants";

interface ImageUploadProps {
  searching: boolean;
  loading: boolean;
  uploading: boolean;
  uploadError: boolean;
  imagesFromLibrary: string[];
  imageUrlToAdd: string;
  imageAltText?: string;
  videoUrlToAdd?: string;
  invalidFormat: boolean;
  invalidFormatErrorType: string;
  handleSearch: (search: string) => void;
  handleUploadImage: (image: File) => Promise<void>;
  handlePickImageFromLibrary: (imageUrl: string) => void;
  handleResetImagesFromLibrary: () => void;
  handleInsertMedia: (
    imageUrl: string,
    altText: string,
    audioUrl?: string,
    videoUrl?: string
  ) => void;
  handleRemoveImage: () => void;
  handleInvalidFormat: (errorType?: string) => void;
  imageOnly?: boolean;
}

export const ImageUpload: React.FC<ImageUploadProps> = ({
  //   searching,
  imageUrlToAdd,
  imageAltText = "",
  videoUrlToAdd,
  loading,
  uploading,
  uploadError,
  //   imagesFromLibrary,
  invalidFormat,
  invalidFormatErrorType,
  //   handleSearch,
  handleUploadImage,
  handleInsertMedia,
  handleRemoveImage,
  //   handlePickImageFromLibrary,
  //   handleResetImagesFromLibrary,
  handleInvalidFormat,
  imageOnly = false,
}) => {
  //   const { t: tCommon } = useTranslation("admin", {
  //     keyPrefix: "common",
  //     useSuspense: false,
  //   });
  const { t } = useTranslation("admin", {
    keyPrefix: "addMediaModal.imageUpload",
    useSuspense: false,
  });
  //   const [searchQuery, setSearchQuery] = useState("");
  const [altText, setAltText] = useState(imageAltText);

  const urlIsStaged = !!imageUrlToAdd || (!imageOnly && !!videoUrlToAdd);
  //   const searchResolved = !!searchQuery && !searching;
  //   const noResultsFromSearch =
  //     searchResolved && imagesFromLibrary.length === 0 && !urlIsStaged;
  //   const resultsFromSearch =
  //     searchResolved && imagesFromLibrary.length > 0 && !urlIsStaged;
  const shouldAllowUpload = !urlIsStaged; // && !searchResolved && !searching;

  const onDrop = async (acceptedFiles: File[]) => {
    if (acceptedFiles.length) {
      const imageFile = await checkResizeImageFile(acceptedFiles[0]);

      const isInvalidSVG = await checkInvalidSVG(imageFile);
      if (isInvalidSVG) {
        handleInvalidFormat("INVALID_SVG");
        return;
      }

      handleUploadImage(imageFile);
    }
  };

  const onDropRejected = (rejectedFiles: FileRejection[]) => {
    if (rejectedFiles.length) {
      let error = "";
      if (rejectedFiles[0]?.errors?.length) {
        error = rejectedFiles[0].errors[0].code;
      }
      handleInvalidFormat(error);
    }
  };

  const { getRootProps, getInputProps, isDragActive, isFocused } = useDropzone({
    accept: ["image/*"],
    maxFiles: 1,
    maxSize: MAX_IMAGE_UPLOAD_SIZE,
    multiple: false,
    onDrop,
    onDropRejected,
  });

  //   const handleKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
  //     if (ev.key === "Enter") {
  //       handleSearch(searchQuery);
  //     }
  //   };

  //   const handleChangeSearch = (s: string) => {
  //     if (!s) {
  //       handleResetImagesFromLibrary();
  //     }
  //     setSearchQuery(s);
  //   };

  //   const handleClickSearch = () => {
  //     setSavedUrl("");
  //     handleSearch(searchQuery);
  //   };

  return (
    <SimpleGrid gap={pxToRem(24)}>
      <Flex
        rowGap={pxToRem(12)}
        columnGap={pxToRem(20)}
        alignItems="center"
        flexWrap={["wrap", null, "nowrap"]}
      >
        {/* TODO: Re-add search when we have a public media library */}
        {/* <Box w="100%" flex={["auto", "auto", 1]}>
          <InputGroup>
            <InputLeftElement h={pxToRem(48)} pl={pxToRem(16)} pr={pxToRem(8)}>
              <Icon
                icon="search_outlined"
                width={pxToRem(18)}
                height={pxToRem(18)}
                color="primary.dark-gray"
              />
            </InputLeftElement>
            <Input
              //   isDisabled={searching || !!imageUrlToAdd}
              isDisabled // TODO: enable when Getty Images is supported
              onKeyDown={handleKeyDown}
              value={searchQuery}
              onChange={(e) => handleChangeSearch(e.target.value)}
              pl={pxToRem(42)}
              pr={pxToRem(106)}
            />
            <InputRightElement
              width="auto"
              right={pxToRem(16)}
              top={pxToRem(4)}
              display={["none", null, "inline-flex"]}
            >
              <Button
                size="sm"
                variant="adminButtonFilled"
                onClick={handleClickSearch}
                isLoading={searching}
                disabled={!searchQuery || searching || urlIsStaged}
              >
                {tCommon("search")}
              </Button>
            </InputRightElement>
          </InputGroup>
        </Box> */}
      </Flex>
      <Box>
        {/* Search is in progress */}
        {/* {searching && (
          <Flex
            backgroundColor="primary.white"
            borderColor="primary.medium-gray"
            width="full"
            height={[pxToRem(328), null, pxToRem(360)]}
            paddingX={pxToRem(15)}
            paddingY={[pxToRem(22), null, pxToRem(26)]}
            borderWidth={pxToRem(1)}
            justifyContent="center"
          >
            <Center>
              <Spinner />
            </Center>
          </Flex>
        )} */}
        {/* {noResultsFromSearch && (
          <Flex
            p={[pxToRem(16), null, pxToRem(20)]}
            borderColor="primary.medium-gray"
            borderWidth={pxToRem(1)}
            borderRadius={pxToRem(20)}
            h={[pxToRem(328), null, pxToRem(360)]}
            alignItems="center"
            justifyContent="center"
            overflowY="auto"
          >
            <Heading as="p" variant="adminH6" textAlign="center">
              {t("noImagesFromSearch")}
            </Heading>
          </Flex>
        )} */}
        {/* {resultsFromSearch && (
          <SimpleGrid
            templateColumns={["1fr 1fr", null, "1fr 1fr 1fr 1fr"]}
            gap={[pxToRem(14), null, pxToRem(16)]}
            p={[pxToRem(16), null, pxToRem(20)]}
            borderColor="primary.medium-gray"
            borderWidth={pxToRem(1)}
            borderRadius={pxToRem(20)}
            h={[pxToRem(328), null, pxToRem(360)]}
            overflowY="auto"
          >
            {imagesFromLibrary.map((imageUrl, index) => (
              <Button
                key={index}
                variant="adminLibraryImage"
                backgroundImage={imageUrl}
                onClick={() => handlePickImageFromLibrary(imageUrl)}
                disabled={loading}
                borderRadius={pxToRem(8)}
              />
            ))}
          </SimpleGrid>
        )} */}
        {/* Image/video is staged, render a preview */}
        {urlIsStaged && (
          <Flex
            backgroundColor="primary.white"
            borderColor="primary.medium-gray"
            width="full"
            height={[pxToRem(328), null, pxToRem(360)]}
            paddingX={pxToRem(15)}
            paddingY={[pxToRem(22), null, pxToRem(26)]}
            borderWidth={pxToRem(1)}
            justifyContent="center"
          >
            {imageUrlToAdd && <Image src={imageUrlToAdd} h="100%" w="auto" />}
            {!imageOnly && videoUrlToAdd && (
              <ReactPlayer height="100%" url={videoUrlToAdd} controls={true} />
            )}
          </Flex>
        )}
        {shouldAllowUpload && (
          <ImageUploadField
            {...{
              isFocused,
              isDragActive,
              uploading,
              invalidFormat,
              invalidFormatErrorType,
              uploadError,
              getRootProps,
              getInputProps,
            }}
          />
        )}
        <Flex mt={pxToRem(12)} h={pxToRem(20)} justifyContent="flex-end">
          {urlIsStaged && (
            <Button
              variant="adminTextButtonMedium"
              onClick={handleRemoveImage}
              isDisabled={loading}
            >
              <Text variant="adminLinkMedium" color="utility.link">
                {imageUrlToAdd ? t("removeImage") : t("removeVideo")}
              </Text>
            </Button>
          )}
        </Flex>
      </Box>
      <Flex
        flexDir={["column", null, "row"]}
        alignItems={["center", null, "flex-end"]}
        gap={[pxToRem(32), null, pxToRem(40)]}
      >
        <FormControl isDisabled={!imageUrlToAdd || loading}>
          <FormLabel>
            <Flex as="span" gap={pxToRem(4)} alignItems="center">
              {t("altText")}
              <IconTooltip isDisabled={!imageUrlToAdd || loading}>
                {t("addAltTextDescription")}
              </IconTooltip>
            </Flex>
          </FormLabel>
          <Textarea
            value={altText}
            onChange={(e) => setAltText(e.target.value)}
            placeholder={t("addAltTextPlaceholder")}
            maxLength={MAX_ALT_TEXT_LENGTH}
          />
        </FormControl>
        <Button
          variant="adminButtonFilled"
          size="lg"
          w={[pxToRem(138), null, pxToRem(160)]}
          isDisabled={!urlIsStaged}
          isLoading={loading}
          onClick={() =>
            handleInsertMedia(imageUrlToAdd, altText, undefined, videoUrlToAdd)
          }
        >
          {t("addImage")}
        </Button>
      </Flex>
    </SimpleGrid>
  );
};

interface ImageUploadFieldProps {
  isFocused: boolean;
  isDragActive: boolean;
  uploading: boolean;
  uploadError: boolean;
  invalidFormat: boolean;
  invalidFormatErrorType: string;
  getRootProps: () => ReactJSXIntrinsicAttributes;
  getInputProps: () => DropzoneInputProps;
}

const ImageUploadField: React.FC<ImageUploadFieldProps> = ({
  isFocused,
  isDragActive,
  uploading,
  uploadError,
  invalidFormat,
  invalidFormatErrorType,
  getRootProps,
  getInputProps,
}) => {
  const { t } = useTranslation("admin", {
    keyPrefix: "addMediaModal.imageUpload",
    useSuspense: false,
  });

  const getInvalidFormatErrorMessage = (errorType: string) => {
    switch (errorType) {
      case "file-invalid-type":
        return t("invalidFileType");
      case "file-too-large":
        return t("invalidFileSize");
      default:
        return t("invalidFiles");
    }
  };

  return (
    <Button
      as={Box}
      variant="adminDropZone"
      isLoading={uploading}
      borderRadius={pxToRem(20)}
      borderColor={
        invalidFormat
          ? "utility.error"
          : isDragActive || isFocused
          ? "primary.warm-black"
          : "primary.medium-gray"
      }
      _hover={hoverStyles({
        borderColor: invalidFormat ? "utility.error" : "primary.warm-black",
      })}
      {...getRootProps()}
      whiteSpace="normal"
    >
      <input {...getInputProps()} />
      <ResponsiveHeading
        as="p"
        variant="adminH6"
        sizeThreshold="lg"
        smallText={t("uploadImageMobile")}
        largeText={t("uploadImageDesktop")}
      />
      {(invalidFormat || uploadError) && (
        <Text
          variant="adminP2"
          color="utility.error"
          mt={pxToRem(8)}
          textAlign="center"
        >
          {invalidFormat
            ? getInvalidFormatErrorMessage(invalidFormatErrorType)
            : t("uploadError")}
        </Text>
      )}
    </Button>
  );
};
