import moment from "moment-timezone";
import { array, object, string } from "yup";

import { getRegionsForCountry } from "links/lib/geo";
import { SharingSettingsVisibility } from "links/lib/types";

import { Field, Formik, IForm } from "../../TeacherAccountInformationForm";

export const fields: Record<Field, Field> = {
  // location
  language: "language",
  country: "country",
  state: "state",
  timezone: "timezone",
  // teacher information
  title: "title",
  nickname: "nickname",
  grades: "grades",
  subjects: "subjects",
  schoolOrDistrictName: "schoolOrDistrictName",
  schoolOrDistrictPostalCode: "schoolOrDistrictPostalCode",
  // sharing settings
  sharingVisibility: "sharingVisibility",
  // agreements
  acceptedAgreementIds: "acceptedAgreementIds",
};

export const setInitialValues = (initialValues?: Partial<IForm>): IForm => {
  let region = initialValues?.state || "";
  // get_geo returns long form regions, like "Iowa". We store two letter codes, so attempt
  // a lookup and mutate region if necessary
  if (region.length > 2) {
    const regionObject = getRegionsForCountry(
      initialValues?.country || ""
    ).find((r) => r.name === region);
    if (regionObject) {
      region = regionObject.code;
    }
  }

  return {
    country: initialValues?.country ?? "",
    grades: initialValues?.grades ?? [],
    language: initialValues?.language ?? "",
    title: initialValues?.title ?? "",
    sharingVisibility:
      initialValues?.sharingVisibility ?? SharingSettingsVisibility.Public,
    nickname: initialValues?.nickname ?? "",
    state: region,
    subjects: initialValues?.subjects ?? [],
    timezone: initialValues?.timezone ?? moment.tz.guess(),
    schoolOrDistrictName: initialValues?.schoolOrDistrictName ?? "",
    schoolOrDistrictPostalCode: initialValues?.schoolOrDistrictPostalCode ?? "",
    acceptedAgreementIds: initialValues?.acceptedAgreementIds ?? [],
  };
};

export const getTimezonesForCountry = (country: string): Array<string> =>
  (moment.tz.zonesForCountry(country) || []).map((z) => z.replaceAll("_", " "));

export const asyncSetFieldTouched = (
  field: string,
  form: Formik
): Promise<void> =>
  new Promise<void>((res) => {
    form.setFieldTouched(field);
    res();
  });

export const validationSchema = object().shape({
  [fields.language]: string().required(),
  [fields.country]: string().required(),
  [fields.state]: string(),
  [fields.timezone]: string().required(),
  [fields.title]: string(),
  [fields.nickname]: string().trim().required(),
  [fields.schoolOrDistrictName]: string(),
  [fields.schoolOrDistrictPostalCode]: string(),
  [fields.acceptedAgreementIds]: array(string()),
});

export const isLocationFormValid = (values: IForm): boolean =>
  Boolean(
    values[fields.language] && values[fields.country] && values[fields.timezone]
  );

export const isTeacherInfoFormValid = (
  values: IForm,
  agreementsCount: number,
  isLocationFormBundled = false
): boolean => {
  const agreementsLength =
    (values[fields.acceptedAgreementIds] as string[]).length ?? 0;
  const nickname = values[fields.nickname];
  if (!nickname || Array.isArray(nickname)) {
    return false;
  }

  return (
    !!(nickname as string).trim() &&
    (isLocationFormBundled
      ? Boolean(
          values[fields.language] &&
            values[fields.country] &&
            values[fields.timezone]
        )
      : true) &&
    (!agreementsCount || agreementsLength === agreementsCount)
  );
};
