import { Box, Flex, Image, Spacer, useBreakpointValue } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import hourglassImage from "adminComponents/assets/images/Hourglass.svg";
import { Button } from "adminComponents/atoms/Button";
import { Logo } from "adminComponents/atoms/Logo";
import { Text } from "adminComponents/atoms/Text";
import { TemplateWithVerticalCenteredContent } from "adminComponents/templates/TemplateWithVerticalCenteredContent";
import { pxToRem } from "adminComponents/utils";

interface IProps {
  email?: string;
  isLoading?: boolean;
  handleReturnToSignIn: () => void;
}

export const WaitingScreen: React.FC<IProps> = ({
  email,
  isLoading = false,
  handleReturnToSignIn,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const useSmallerPortraitHeader = useBreakpointValue({
    base: true,
    sm: false,
  });
  const history = useHistory();

  return (
    <TemplateWithVerticalCenteredContent
      isLoading={isLoading}
      headerContent={
        <Box alignSelf="center" w={pxToRem(154)} marginTop={pxToRem(25)}>
          <Logo />
        </Box>
      }
    >
      {isMobile && (
        <Flex
          alignItems="center"
          direction="column"
          h="full"
          paddingBottom={pxToRem(40)}
          paddingX={[pxToRem(20), pxToRem(40)]}
        >
          <Spacer flex="0.4" />
          <Image
            src={hourglassImage}
            title={t("waitingScreen.hourglassAltText")}
            marginBottom={pxToRem(40)}
            h="16%"
          />
          <Text
            as="h3"
            align="center"
            variant={useSmallerPortraitHeader ? "adminH3" : "adminH2"}
          >
            {t("waitingScreen.title")}
          </Text>
          <Text
            variant="adminP1"
            align="center"
            marginTop={[pxToRem(10), pxToRem(40)]}
          >
            {t("waitingScreen.description1")}
            <b>{email || "?"}</b>
            {t("waitingScreen.description2")}
          </Text>
          <Spacer flex="0.5" />
          <Button
            w="full"
            marginBottom={pxToRem(20)}
            variant="adminButtonFilled"
            onClick={() => history.push("/session/join")}
          >
            {t("waitingScreen.buttonJoinSession")}
          </Button>
          <Button
            w="full"
            variant="adminButtonFilled"
            onClick={() => handleReturnToSignIn()}
          >
            {t("waitingScreen.buttonReturnToSignIn")}
          </Button>
        </Flex>
      )}
      {!isMobile && (
        <Flex
          alignItems="center"
          direction="row"
          w="90%"
          h="full"
          justifyContent="center"
        >
          <Image
            src={hourglassImage}
            title={t("waitingScreen.hourglassAltText")}
            h="40%"
          />
          <Spacer flex="0.5" />
          <Flex
            align="center"
            direction="column"
            justify="center"
            maxW={pxToRem(700)}
            h="full"
            paddingBottom={pxToRem(40)}
          >
            <Spacer flex="0.2" />
            <Flex direction="column" alignSelf="flex-start">
              <Text alignSelf="flex-start" align="left" variant="adminH2">
                {t("waitingScreen.title")}
              </Text>
              <Text variant="adminP1" align="left" marginTop={pxToRem(40)}>
                {t("waitingScreen.description1")}
                <b>{email || "?"}</b>
                {t("waitingScreen.description2")}
              </Text>
            </Flex>
            <Spacer flex="0.4" />
            <Button
              w="full"
              marginTop={pxToRem(40)}
              variant="adminButtonFilled"
              onClick={() => history.push("/session/join")}
            >
              {t("waitingScreen.buttonJoinSession")}
            </Button>
            <Button
              w="full"
              marginTop={pxToRem(40)}
              variant="adminButtonFilled"
              onClick={() => handleReturnToSignIn()}
            >
              {t("waitingScreen.buttonReturnToSignIn")}
            </Button>
          </Flex>
        </Flex>
      )}
    </TemplateWithVerticalCenteredContent>
  );
};
