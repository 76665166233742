import { Box, usePrefersReducedMotion } from "@chakra-ui/react";
import { useRive } from "@rive-app/react-canvas";
import React, { useEffect } from "react";
import { animated, config, useSpring } from "react-spring";

export interface IFullscreenAnimationTakeoverProps {
  show: boolean;
  handlePlay?: () => void;
  handleEnd: () => void;
  bgColor?: string;

  src: string;
  stateMachines?: string | string[];
  animations?: string | string[];
  lengthInMillis: number;
}

const AnimatedBox = animated(Box);

export const FullscreenRiveTakeover: React.FC<
  IFullscreenAnimationTakeoverProps
> = ({
  show,
  handleEnd,
  handlePlay,
  bgColor,
  src,
  stateMachines,
  animations,
  lengthInMillis,
}) => {
  const reduceMotion = usePrefersReducedMotion();

  const { rive, RiveComponent } = useRive({
    src,
    autoplay: false,
    stateMachines,
  });

  const backdropSpring = useSpring({
    from: {
      opacity: 0,
      scale: reduceMotion ? 1 : 0,
    },
    to: {
      opacity: show ? 1 : 0,
      scale: show ? 1 : 0,
    },
    config: config.stiff,
  });

  useEffect(() => {
    if (show && !rive?.isPlaying) {
      handlePlay?.();
      rive?.reset();
      rive?.play(animations);

      setTimeout(() => handleEnd(), lengthInMillis);
    } else {
      rive?.pause();
    }
  }, [show, rive, handlePlay, handleEnd, lengthInMillis, animations]);

  return (
    <AnimatedBox
      position="fixed"
      top="0"
      left="0"
      right="0"
      bottom="0"
      zIndex="10000"
      display={show ? "block" : "none"}
      bg={bgColor}
      style={backdropSpring}
    >
      <Box position="absolute" top="0" left="0" right="0" bottom="0">
        <RiveComponent width="full" height="full" />
      </Box>
    </AnimatedBox>
  );
};
