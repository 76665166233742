import { Flex, TextProps } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { IconTooltip } from "adminComponents/atoms/IconTooltip";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils";

import { PremiumIcon } from "../PremiumIcon";

interface IPremiumTextProps extends TextProps {
  type: "set" | "item";
}

export const PremiumText: React.FC<IPremiumTextProps> = ({ type, ...rest }) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });

  return (
    <Flex
      alignItems="center"
      justifyContent="flex-start"
      gap={[pxToRem(4), null, pxToRem(8)]}
    >
      <PremiumIcon type={type} />
      <Text variant="adminP1" color="primary.dark-gray" {...rest}>
        {t("common.premium")}
      </Text>
      <IconTooltip>{t(`premiumText.${type}Tooltip`)}</IconTooltip>
    </Flex>
  );
};
