import { Box, BoxProps, ResponsiveValue } from "@chakra-ui/react";
import React, { ReactNode, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDebounce, useOrientation, useWindowSize } from "react-use";

import { Button, IProps as IButtonProps } from "adminComponents/atoms/Button";
import { Text } from "adminComponents/atoms/Text";

interface IProps extends BoxProps {
  noOfLines?: number | ResponsiveValue<number>;
  children: ReactNode;
  ctaForShowMore?: ReactNode;
  ctaForShowLess?: ReactNode;
  textVariant: string;
  buttonVariant?: IButtonProps["variant"];
  buttonMarginTop?: string;
}

export const ExpandableText: React.FC<IProps> = ({
  children,
  noOfLines,
  ctaForShowMore,
  ctaForShowLess,
  textVariant,
  buttonVariant = "adminTextButtonLarge",
  buttonMarginTop,
  ...rest
}) => {
  const orientation = useOrientation();
  const windowSize = useWindowSize();

  // translations
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });
  if (!ctaForShowMore) {
    ctaForShowMore = t("common.readMore");
  }

  // 'read more' click handlers
  const [expandedCount, setExpandedCount] = useState<
    number | undefined | ResponsiveValue<number>
  >(noOfLines);
  const [isClicked, setIsClicked] = useState(false);
  const handleToggle = () => {
    setIsClicked(true);
    setExpandedCount(expandedCount ? undefined : noOfLines);
  };

  // determine whether text is clamped
  const inputRef = React.useRef<HTMLInputElement>(null);
  const clamped = useCallback(
    () =>
      (inputRef.current?.scrollHeight as number) >
        (inputRef.current?.clientHeight as number) || isClicked,
    [isClicked, inputRef]
  );

  const [isTextClamped, setIsTextClamped] = useState(() => clamped());

  const [,] = useDebounce(
    () => {
      setIsTextClamped(() => clamped());
      if (expandedCount) {
        setExpandedCount(noOfLines);
      }
    },
    500,
    [expandedCount, noOfLines, orientation, windowSize]
  );

  return (
    <Box display="flex" flexWrap="wrap" {...rest}>
      <Box ref={inputRef} noOfLines={expandedCount}>
        <Text variant={textVariant}>{children}</Text>
      </Box>
      {isTextClamped && (expandedCount || ctaForShowLess) && (
        <Button
          variant={buttonVariant}
          onClick={handleToggle}
          {...(buttonMarginTop ? { marginTop: buttonMarginTop } : {})}
        >
          {expandedCount ? ctaForShowMore : ctaForShowLess}
        </Button>
      )}
    </Box>
  );
};
