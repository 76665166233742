import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  ToastId,
  useErrorToast,
  usePendingToast,
  useShowToast,
} from "adminComponents/utils/toast";
import { useAnalytics } from "lib/contexts/analytics";
import { useCopyPracticeSetItem } from "links/lib/features/practiceSetItems";
import { useSearchPracticeDataOnce } from "links/lib/features/search";
import { ISearchPracticeDataOnceResponse } from "links/lib/features/search/useSearchPracticeDataOnce";
import {
  AnalyticsEvent,
  IPracticeSet,
  IPracticeSetItem,
  PracticeDataDocumentType,
  PracticeSetAvailability,
  QuestionType,
} from "links/lib/types";

interface IArgs {
  practiceSet?: IPracticeSet;
}

interface IResult {
  handleAdd: (
    type: QuestionType,
    subType?: "text" | "images",
    practiceSetItem?: IPracticeSetItem
  ) => void;
  handleSearch: (
    term: string,
    library: PracticeSetAvailability,
    filters: { state: string; subject: string; grade: string }
  ) => void;
  handleAddSearchItem: (item: IPracticeSetItem) => void;
  setIsAddQuestionsFlyoutOpen: (isOpen: boolean) => void;
  setIsImportQuestionsFlyoutOpen: (isOpen: boolean) => void;
  isAddQuestionsFlyoutOpen: boolean;
  isImportQuestionsFlyoutOpen: boolean;
  isSearchLoading: boolean;
  searchResults: Array<IPracticeSetItem>;
}

const useAddPracticeSetItem = ({ practiceSet }: IArgs): IResult => {
  const [isAddQuestionsFlyoutOpen, setIsAddQuestionsFlyoutOpen] =
    useState(false);
  const [isImportQuestionsFlyoutOpen, setIsImportQuestionsFlyoutOpen] =
    useState(false);
  const history = useHistory();
  const { trackEvent } = useAnalytics();

  const { showPendingToast, closePendingToast } = usePendingToast();
  const pendingItemAddToastRef = useRef<ToastId | undefined>();
  const showToast = useShowToast();
  const [searchResults, setSearchResults] = useState<Array<IPracticeSetItem>>(
    []
  );
  const { t } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "practiceSetDetailContainer.practiceSetItemAdd",
  });

  const onSearchSuccess = useCallback(
    (data: ISearchPracticeDataOnceResponse) => {
      setSearchResults(data.practice_set_items || []);
    },
    []
  );

  const onCopyItemSettled = useCallback(() => {
    const toastRef = pendingItemAddToastRef;

    if (toastRef.current) {
      closePendingToast(toastRef.current);
      toastRef.current = undefined;
    }
  }, [closePendingToast]);

  const onCopyItemSuccess = useCallback(() => {
    showToast(t("addFromSearchSuccessToast"));
  }, [showToast, t]);

  const searchPracticeData = useSearchPracticeDataOnce({
    onSuccess: onSearchSuccess,
  });
  const copyItemMutation = useCopyPracticeSetItem({
    onSuccess: onCopyItemSuccess,
    onSettled: onCopyItemSettled,
  });

  useErrorToast(searchPracticeData.error);
  useErrorToast(copyItemMutation.error);

  const handleAdd = useCallback(
    (
      type: QuestionType,
      subType?: "text" | "images",
      practiceSetItem?: IPracticeSetItem
    ) => {
      if (!practiceSet) return;

      switch (type) {
        case QuestionType.Diagram:
          history.push(`/t/my-library/sets/${practiceSet.id}/diagram`);
          break;
        case QuestionType.MultipleChoice:
        case QuestionType.MultipleSelect:
          history.push({
            pathname: `/t/my-library/sets/${practiceSet.id}/multiple-choice`,
            state: { imageMode: subType === "images", practiceSetItem },
          });
          break;
        case QuestionType.TermDefinition:
          history.push(`/t/my-library/sets/${practiceSet.id}/term`);
          break;
        case QuestionType.OpenEnded:
          history.push(`/t/my-library/sets/${practiceSet.id}/open-ended`);
          break;
        case QuestionType.TextResponse:
          history.push({
            pathname: `/t/my-library/sets/${practiceSet.id}/text-response`,
            state: { practiceSetItem },
          });
          break;
        case QuestionType.NumberResponse:
          history.push({
            pathname: `/t/my-library/sets/${practiceSet.id}/number-response`,
            state: { practiceSetItem },
          });
          break;
        case QuestionType.Classify:
          history.push({
            pathname: `/t/my-library/sets/${practiceSet.id}/classify`,
            state: { imageMode: subType === "images", practiceSetItem },
          });
          break;
        case QuestionType.Draw:
          history.push(`/t/my-library/sets/${practiceSet.id}/draw`);
          break;
      }
    },
    [history, practiceSet]
  );

  const handleSearch = useCallback(
    (
      term: string,
      library: PracticeSetAvailability,
      filters: { subject: string; state: string; grade: string }
    ) => {
      const { subject, grade, state } = filters;

      searchPracticeData.execute({
        document_type: PracticeDataDocumentType.PracticeSetItems,
        include_shared: library !== PracticeSetAvailability.Private,
        availability: library,
        subject_ids: subject ? [subject] : undefined,
        region: state || undefined,
        grade_level_ids: grade ? [grade] : undefined,
        term,
        page: 1,
        per_page: 15,
      });

      trackEvent(
        AnalyticsEvent.TeacherDashboard_PracticeSetDetail_QuestionFlyout_SearchItems,
        {
          library,
          subject_id: subject,
          grade_level_id: grade,
          has_term: !!term,
        }
      );
    },
    [searchPracticeData, trackEvent]
  );

  const handleAddSearchItem = useCallback(
    (item: IPracticeSetItem) => {
      if (!practiceSet) return;

      copyItemMutation.mutate({
        id: item.id,
        practice_set_id: practiceSet.id,
        place_at_end: item.practice_set_id != practiceSet.id,
      });

      pendingItemAddToastRef.current = showPendingToast(
        t("addFromSearchPendingToast")
      );

      trackEvent(
        AnalyticsEvent.TeacherDashboard_PracticeSetDetail_QuestionFlyout_AddItemFromSearch,
        {
          practiceSetItemId: item.id,
          practiceSetId: practiceSet.id,
          isCertified: item.is_certified,
          isPremium: item.is_premium,
        }
      );
    },
    [practiceSet, copyItemMutation, showPendingToast, t, trackEvent]
  );

  // Remove search results when flyout opens
  useEffect(() => {
    if (isAddQuestionsFlyoutOpen) {
      setSearchResults([]);
    }
  }, [isAddQuestionsFlyoutOpen]);

  return useMemo(
    () => ({
      handleAdd,
      handleSearch,
      handleAddSearchItem,
      isAddQuestionsFlyoutOpen,
      isImportQuestionsFlyoutOpen,
      setIsAddQuestionsFlyoutOpen,
      setIsImportQuestionsFlyoutOpen,
      isSearchLoading: searchPracticeData.isLoading,
      searchResults,
    }),
    [
      handleAdd,
      isAddQuestionsFlyoutOpen,
      isImportQuestionsFlyoutOpen,
      handleSearch,
      handleAddSearchItem,
      searchPracticeData.isLoading,
      searchResults,
    ]
  );
};

export { useAddPracticeSetItem };
