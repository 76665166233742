import { ComponentStyleConfig } from "@chakra-ui/react";

import { textStyles } from "adminComponents/theme/textStyles";

const {
  adminP1,
  adminP2,
  adminP3,
  adminMeta,
  adminLinkExtraLarge,
  adminLinkLarge,
  adminLinkMedium,
  adminLinkSmall,
  adminH1,
  adminH2,
  adminH3,
  adminH3TTCommons,
  adminH7,
} = textStyles;

const AdminText: ComponentStyleConfig = {
  baseStyle: (props: { [key: string]: string }): Record<string, unknown> => ({
    color: props.color ? props.color : "primary.warm-black",
  }),
  variants: {
    adminP1,
    adminP1Bold: {
      ...adminP1,
      fontWeight: "600",
    },
    adminP2,
    adminP2Bold: {
      ...adminP2,
      fontWeight: "600",
    },
    adminP3,
    adminP3Bold: {
      ...adminP3,
      fontWeight: "600",
    },
    adminMeta,
    adminLinkExtraLarge,
    adminLinkLarge,
    adminLinkMedium,
    adminLinkSmall,
    adminH1,
    adminH2,
    adminH3,
    adminH3TTCommons,
    adminH7,
  },
};

export { AdminText };
