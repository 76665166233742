import { SpineCanvas } from "@esotericsoftware/spine-webgl";
import React, { useCallback, useEffect, useLayoutEffect, useRef } from "react";
import { useLifecycles, useMeasure, useUpdate } from "react-use";

import { releaseCanvas } from "sessionComponents/utils/releaseCanvas";

import { SpineStage } from "../lib/SpineStage";
import { StageOverlay } from "../lib/StageOverlay";
import { IAvatarStageItemProps } from "./AvatarStageItem";
import { IAvatarStageMemberProps } from "./AvatarStageMember";

export type AvatarStageAlignment = "start" | "center" | "end";

type AvatarStageChild = React.ReactElement<
  IAvatarStageMemberProps | IAvatarStageItemProps
>;

export interface IAvatarStageProps {
  style?: React.CSSProperties;
  alignX?: AvatarStageAlignment;
  alignY?: AvatarStageAlignment;
  stageSize: {
    x: number;
    y: number;
  };
  camera: {
    zoom: number;
    x: number;
    y: number;
    z: number;
  };
  children?:
    | Array<AvatarStageChild>
    | AvatarStageChild
    | ((
        spineStage?: SpineStage,
        stageOverlay?: StageOverlay
      ) => React.ReactElement);
}

const AvatarStage: React.FC<IAvatarStageProps> = ({
  children,
  style,
  stageSize,
  camera,
  alignX,
  alignY,
}) => {
  const mainCanvasRef = useRef<HTMLCanvasElement>(null);
  const overlayCanvasRef = useRef<HTMLCanvasElement>(null);
  const spineStageRef = useRef<SpineStage | null>(null);
  const stageOverlayRef = useRef<StageOverlay | null>(null);
  const [containerRef, { width: containerWidth, height: containerHeight }] =
    useMeasure<HTMLDivElement>();
  const update = useUpdate();

  /**
   * Get the stage scale (relative to stage size) that will
   * fit the stage within the container
   * @returns stage scale
   */
  const getStageScale = useCallback(() => {
    const stageAspectRatio = stageSize.x / stageSize.y;
    const containerAspectRatio = containerWidth / containerHeight;

    let scale = 0;

    if (stageAspectRatio >= containerAspectRatio) {
      scale = containerWidth / stageSize.x;
    } else {
      scale = containerHeight / stageSize.y;
    }

    return scale || 1;
  }, [containerHeight, containerWidth, stageSize]);

  const getScaledCamera = useCallback(() => {
    return {
      ...camera,
      zoom: camera.zoom * (1 / getStageScale()),
    };
  }, [camera, getStageScale]);

  useLifecycles(
    () => {
      if (
        !mainCanvasRef.current ||
        spineStageRef.current ||
        !overlayCanvasRef.current ||
        stageOverlayRef.current
      )
        return;

      try {
        const camera = getScaledCamera();

        spineStageRef.current = new SpineStage({
          camera,
        });

        stageOverlayRef.current = new StageOverlay({
          canvas: overlayCanvasRef.current,
          camera,
        });

        new SpineCanvas(mainCanvasRef.current, {
          app: spineStageRef.current,
          webglConfig: {
            // turning off antialias to fix a bug noticed in ios 16.6
            // potentially related to a bug with a default on experimental setting "WebGL via Metal"
            // without this avatar rendering would eventually break and require a restart of the browser
            // No perceptible visual difference disabling antialias so doing it for all devices
            antialias: false,
          },
        });

        update();
      } catch (e) {
        console.error("Failed to create Spine canvas:", e);
      }
    },
    () => {
      spineStageRef.current?.dispose();
      spineStageRef.current = null;

      stageOverlayRef.current?.dispose();
      stageOverlayRef.current = null;
    }
  );

  // Release canvases before unmount. `useLayoutEffect` is necessary
  // because the references will be lost before the `useLifecycles`
  // callback is executed.
  useLayoutEffect(() => {
    const mainCanvasRefCurrent = mainCanvasRef.current;
    const overlayCanvasRefCurrent = overlayCanvasRef.current;
    return () => {
      mainCanvasRefCurrent && releaseCanvas(mainCanvasRefCurrent);
      overlayCanvasRefCurrent && releaseCanvas(overlayCanvasRefCurrent);
    };
  }, [mainCanvasRef, overlayCanvasRef]);

  useEffect(() => {
    if (!spineStageRef.current || !stageOverlayRef.current) return;

    const scaledCamera = getScaledCamera();
    spineStageRef.current.setCamera(scaledCamera);

    const stageScale = getStageScale();

    const canvasWidth = stageSize.x * stageScale;
    const canvasHeight = stageSize.y * stageScale;
    stageOverlayRef.current.setCanvasSize(canvasWidth, canvasHeight);
    stageOverlayRef.current.setCamera(scaledCamera);
  }, [
    camera.x,
    camera.y,
    camera.zoom,
    camera.z,
    containerWidth,
    containerHeight,
    getScaledCamera,
    getStageScale,
    stageSize,
  ]);

  const getChildren = ():
    | React.ReactElement
    | Array<React.ReactElement>
    | null => {
    if (!mainCanvasRef.current || !children) return null;

    // handle AvatarStageMember and AvatarStageItem prop injection
    if (typeof children === "object") {
      return React.Children.map<AvatarStageChild, AvatarStageChild>(
        children,
        (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, {
              spineStage: spineStageRef.current || undefined,
              stageOverlay: stageOverlayRef.current || undefined,
            });
          }
          return child;
        }
      );
    }

    // handle render function child
    if (typeof children === "function") {
      return children(
        spineStageRef.current || undefined,
        stageOverlayRef.current || undefined
      );
    }

    return null;
  };

  const stageMembers = getChildren();

  const getCanvasStyle = (): React.CSSProperties => {
    const stageScale = getStageScale();

    const canvasWidth = stageSize.x * stageScale;
    const canvasHeight = stageSize.y * stageScale;

    const style: React.CSSProperties = {
      position: "absolute",
      height: canvasHeight,
      width: canvasWidth,
    };

    switch (alignX) {
      case "center":
        style["left"] = "50%";
        style["marginLeft"] = "-" + canvasWidth / 2 + "px";
        break;
      case "end":
        style["right"] = 0;
        break;
      case "start":
      default:
        style["left"] = 0;
        break;
    }

    switch (alignY) {
      case "center":
        style["top"] = "50%";
        style["marginTop"] = "-" + canvasHeight / 2 + "px";
        break;
      case "end":
        style["bottom"] = 0;
        break;
      case "start":
      default:
        style["top"] = 0;
        break;
    }

    return style;
  };

  const canvasStyle = getCanvasStyle();

  return (
    <div
      ref={containerRef}
      style={{
        position: "relative",
        ...style,
      }}
    >
      <canvas ref={mainCanvasRef} style={canvasStyle} />
      <canvas
        ref={overlayCanvasRef}
        onWheel={(e) => {
          window.scrollBy(0, e.deltaY);
        }}
        style={canvasStyle}
      />
      {stageMembers}
    </div>
  );
};

export { AvatarStage };
