import { Flex, Text } from "@chakra-ui/react";
import React from "react";

import { ColorScheme } from "adminComponents";
import { QuestionType } from "links/lib/types";

import { IResponseIconProps, ResponseIcon } from "../ResponseIcon";

type IImageResponseIconProps = Omit<IResponseIconProps, "iconColor"> & {
  userCount?: number;
  borderRadius: string;
  iconRightMargin: string;
  padding: string;
  showUserCount?: boolean;
  primaryColor: ColorScheme;
  iconColorOverride?: ColorScheme;
  questionType?: QuestionType;
  spinnerWidth: string;
};

export const ImageResponseIcon: React.FC<IImageResponseIconProps> = ({
  showCheckbox,
  showRadio,
  iconColorOverride,
  primaryColor,
  variant,
  success,
  error,
  selected,
  onClick,
  isDisabled,
  isLoading,
  iconSize,
  fontSize,
  userCount,
  borderRadius,
  iconRightMargin,
  padding,
  showUserCount = true,
  questionType,
  spinnerWidth,
  showAsDefaultWrongAnswer,
}) => {
  const getBackgroundColor = () => {
    if (userCount === undefined) return "transparent";
    if (userCount === 0) return "primary.tan";
    if (success) return "utility.badge-green";
    if (error) return "utility.badge-red";
  };
  return (
    <Flex
      backgroundColor={getBackgroundColor()}
      borderRadius={borderRadius}
      alignItems="center"
      height={iconSize}
      paddingRight={padding}
    >
      <ResponseIcon
        showCheckbox={showCheckbox}
        showRadio={showRadio}
        iconColor={iconColorOverride || primaryColor}
        variant={variant}
        iconSize={iconSize}
        fontSize={fontSize}
        success={success}
        error={error || showAsDefaultWrongAnswer}
        selected={selected}
        onClick={onClick}
        isDisabled={isDisabled}
        isLoading={isLoading}
        marginRight={iconRightMargin}
        questionType={questionType}
        spinnerWidth={spinnerWidth}
        showAsDefaultWrongAnswer={showAsDefaultWrongAnswer}
      />
      {showUserCount && (
        <Text
          as="span"
          textStyle="gameText"
          fontSize={fontSize}
          userSelect="none"
        >
          {userCount}
        </Text>
      )}
    </Flex>
  );
};
