import { AxiosError } from "axios";
import { UseMutationResult, useMutation } from "react-query";

import { CreateQuestionFormDataType } from "adminComponents/utils";
import { useAxios } from "links/lib/hooks/useAxios";
import { QuestionType, RichValue } from "links/lib/types";

export interface IGeneratePracticeSetItemFeedbackResponse {
  feedback: RichValue;
}

export interface IUseGenerateFeedbackArgs {
  onError?: (err: AxiosError) => void;
  onSuccess?: (data: IGeneratePracticeSetItemFeedbackResponse) => void;
  onSettled?: () => void;
}

export interface IGenerateFeedbackArgs {
  formData: CreateQuestionFormDataType;
  practiceSetItemId?: string;
}

const convertFormDataToPracticeSetItem = (
  form: CreateQuestionFormDataType,
  practiceSetItemId: string
) => {
  if (form.multipleChoice) {
    return {
      id: practiceSetItemId,
      question_type: QuestionType.MultipleChoice,
      image_alt_text: form.multipleChoice.imageAltText,
      multiple_choice: {
        prompt: JSON.stringify(form.multipleChoice.prompt),
        choices: form.multipleChoice.responses.map((c) => {
          return {
            text: JSON.stringify(c.value),
            image_alt_text: c.imageAltText,
            is_correct: c.isCorrect,
          };
        }),
      },
    };
  } else if (form.textResponse) {
    return {
      id: practiceSetItemId,
      question_type: QuestionType.TextResponse,
      image_alt_text: form.textResponse.imageAltText,
      text_response: {
        prompt: JSON.stringify(form.textResponse.prompt),
        correct_response: form.textResponse.correctResponse,
      },
    };
  } else if (form.numberResponse) {
    return {
      id: practiceSetItemId,
      question_type: QuestionType.NumberResponse,
      image_alt_text: form.numberResponse.imageAltText,
      number_response: {
        prompt: JSON.stringify(form.numberResponse.prompt),
        correct_response: `${JSON.stringify(form.numberResponse.prefix)} ${
          form.numberResponse.correctResponse
        } ${JSON.stringify(form.numberResponse.suffix)}`,
      },
    };
  }
};

export const useGenerateFeedback = (
  args: IUseGenerateFeedbackArgs
): UseMutationResult<
  IGeneratePracticeSetItemFeedbackResponse,
  unknown,
  IGenerateFeedbackArgs
> => {
  const axios = useAxios();

  const generateFeedback = async (args: IGenerateFeedbackArgs) => {
    const { formData, practiceSetItemId } = args;

    const response = await axios({
      method: "post",
      url: "/v1/practice-sets/items/feedback",
      data: {
        practice_set_item: convertFormDataToPracticeSetItem(
          formData,
          practiceSetItemId || "0"
        ),
      },
    }).then((r) => {
      const response = r.data as { feedback: string };
      return {
        feedback: JSON.parse(response.feedback),
      };
    });

    return response;
  };

  const mutation = useMutation(generateFeedback, {
    onError: args?.onError,
    onSuccess: args?.onSuccess,
    onSettled: args?.onSettled,
  });

  return {
    ...mutation,
  };
};
