import { pxToRem } from "adminComponents/utils/pxToRem";

export const AdminStudentBanner = {
  baseStyle: {
    banner: {
      paddingX: pxToRem(24),
      paddingY: pxToRem(12),
      bgColor: "primary.golden-light",
      alignItems: "center",
      borderRadius: pxToRem(8),
      marginBottom: pxToRem(12),
    },
    mainText: {
      fontWeight: 500,
      marginLeft: pxToRem(12),
    },
  },
  variants: {
    assignmentBanner: {
      banner: {
        justifyContent: "space-between",
      },
      mainText: {
        marginLeft: 0,
      },
      textContainer: {
        justifyContent: "space-between",
        width: "100%",
        flexDirection: ["column", null, "row"],
        marginLeft: pxToRem(12),
      },
    },
  },
};
