import {
  Box,
  BreadcrumbItem,
  BreadcrumbLink,
  Breadcrumb as ChakraBreadcrumb,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

import { NavigationLink } from "adminComponents";
import { Icon } from "adminComponents/atoms/Icon";
import { pxToRem } from "adminComponents/utils/pxToRem";

import { ReactComponent as SeparatorSvg } from "./resource/separator.svg";

export interface BreadcrumbNavigationLink
  extends Omit<NavigationLink, "to" | "isExternal"> {
  to?: string;
}

interface IProps {
  breadcrumbs: BreadcrumbNavigationLink[];
}

const Breadcrumb: React.FC<IProps> = ({ breadcrumbs }) => {
  const styles = useMultiStyleConfig("AdminBreadcrumb", {});

  return (
    <ChakraBreadcrumb
      variant="adminBreadcrumb"
      separator={
        <SeparatorSvg
          color="primary.warm-black"
          aria-hidden
          focusable={false}
        />
      }
      spacing={pxToRem(4)}
      sx={styles.outer}
    >
      {breadcrumbs.map((breadcrumb, index) => {
        const isLast = index === breadcrumbs.length - 1;
        const sxStyles = isLast
          ? styles.crumbLast
          : breadcrumb.to
          ? styles.crumbLink
          : styles.crumb;

        return (
          <BreadcrumbItem key={index}>
            {index === 0 && (
              <Box __css={styles.iconContainer}>
                <Icon icon="folder_outlined" decorative />
              </Box>
            )}
            {breadcrumb.to ? (
              <BreadcrumbLink
                sx={sxStyles}
                as={Link}
                to={{ pathname: breadcrumb.to, state: breadcrumb.state }}
              >
                {breadcrumb.label}
              </BreadcrumbLink>
            ) : (
              <Box sx={sxStyles}>{breadcrumb.label}</Box>
            )}
          </BreadcrumbItem>
        );
      })}
    </ChakraBreadcrumb>
  );
};

export { Breadcrumb };
