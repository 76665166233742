import React, { useEffect } from "react";
import { useAudio, useInterval, useMount } from "react-use";

export interface IProps {
  audioClips: Array<{ src: string; type: string }>;
  volume: number;
  isPlaying: boolean;
}

// fade update interval in milliseconds
const FADE_INTERVAL = 100;
//volume change per fade update
const VOLUME_FADE_RATE = (1 / 1000) * FADE_INTERVAL;

export const BackgroundAudio: React.FC<IProps> = ({
  audioClips,
  volume,
  isPlaying,
}) => {
  const [audio, state, controls] = useAudio(
    <audio preload="auto" loop={true} autoPlay={isPlaying} muted>
      {audioClips.map((clip) => (
        <source key={clip.src} src={clip.src} type={clip.type} />
      ))}
    </audio>
  );

  // set initial volume on player to prevent audio from starting at the wrong level
  useMount(() => {
    controls.volume(volume);
  });

  // if we should be playing: unmute the player which always starts muted
  useEffect(() => {
    if (isPlaying && state.muted && volume > 0) {
      controls.unmute();
    }
  }, [isPlaying, state.muted, volume, controls]);

  useInterval(() => {
    // if in desired state, return
    if (
      (!isPlaying && !state.playing) ||
      (isPlaying && state.playing && volume === state.volume)
    ) {
      return;
    }

    // if should not be playing, and is playing, then reduce volume or stop if
    if (!isPlaying && state.playing) {
      const newVolume = Math.max(state.volume - VOLUME_FADE_RATE, 0);

      controls.volume(newVolume);

      if (newVolume === 0) {
        controls.pause();
      }

      return;
    }

    // if should be playing, and not playing, then start volume at zero and seek to start
    if (isPlaying && !state.playing) {
      controls.seek(0);
      controls.play();
      controls.volume(0);

      return;
    }

    // if should be playing, and volume does not match desired, then increase by increment
    if (isPlaying && state.playing && volume !== state.volume) {
      let newVolume = 0;

      if (volume > state.volume) {
        newVolume = Math.min(state.volume + VOLUME_FADE_RATE, volume);
      } else {
        newVolume = Math.max(state.volume - VOLUME_FADE_RATE, volume);
      }

      controls.volume(newVolume);

      return;
    }
  }, FADE_INTERVAL);

  return <>{audio}</>;
};
