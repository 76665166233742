import { hoverStyles, pxToRem } from "adminComponents/utils";

export const AdminAvatar = {
  baseStyle: ({
    hasInteraction,
  }: {
    hasInteraction: boolean;
  }): Record<string, unknown> => ({
    outer: {
      borderColor: "transparent",
      borderStyle: "solid",
      borderWidth: pxToRem(2),
      display: "inline-block",
      borderRadius: "50%",
      verticalAlign: "top",
      "*": {
        fontFamily: "TTCommons",
        fontSize: pxToRem(24),
        lineHeight: "80%",
      },
      a: {
        display: "block",
      },
      svg: {
        color: "primary.dark-gray",
      },
      _hover: hoverStyles({
        borderColor: hasInteraction ? "primary.golden-hover" : undefined,
        cursor: hasInteraction ? "pointer" : "default",
      }),
      _focus: {
        boxShadow: "none",
        outline: "none",
      },
      _focusVisible: {
        borderColor: "utility.focus",
      },
    },
    premiumIcon: {
      position: "absolute",
      bottom: pxToRem(-5),
      right: pxToRem(-5),
    },
  }),
};
