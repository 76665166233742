import { AxiosError } from "axios";
import { UseMutationResult, useMutation } from "react-query";

import { useAuth } from ".";
import { localStoreAuthKeyName } from "../../constants";
import { useAxios } from "../../hooks/useAxios";
import { useLocalStore } from "../../hooks/useLocalStore";
import type { IUser } from "../../types";
import { fetchCurrentUser } from "../users/useFetchCurrentUser";

interface IExecuteArgs {
  hair_color_hex_code?: string;
  skin_tone_hex_code?: string;
  hair_style_avatar_item_id?: string;
  use_uninitialized_role?: boolean;
  make_ephemeral?: boolean;
}

interface IExecuteResult {
  authUser: IUser;
  authToken: string;
}
// TODO correct params for onSuccess func
export default function useCreateGuest({
  onSuccess,
  onError,
}: {
  onSuccess?: (data: IExecuteResult) => void;
  onError?: (err: AxiosError) => void;
}): UseMutationResult<IExecuteResult, unknown, IExecuteArgs> {
  const { setAuth, isAuthLoading } = useAuth();
  const axios = useAxios();
  const localStore = useLocalStore();

  const createGuest = async (opts: {
    hair_color_hex_code?: string;
    skin_tone_hex_code?: string;
    hair_style_avatar_item_id?: string;
    use_uninitialized_role?: boolean;
    make_ephemeral?: boolean;
  }) => {
    const {
      hair_color_hex_code,
      skin_tone_hex_code,
      hair_style_avatar_item_id,
      use_uninitialized_role = false,
      make_ephemeral,
    } = opts;

    const response = await axios({
      method: "post",
      url: "/v1/users/create-guest",
      data: {
        hair_color_hex_code,
        skin_tone_hex_code,
        hair_style_avatar_item_id,
        use_uninitialized_role,
        make_ephemeral,
      },
    }).then((r) => r.data as unknown as { user: IUser; token: string });

    const { token } = response;

    // save the auth token in local store
    await localStore.setItem(localStoreAuthKeyName, token);

    // update axios client to use current JWT
    // for authorization header on all requests
    axios.defaults.headers.common["Authorization"] = token;

    // fetch full user info
    const userRes = await fetchCurrentUser(axios);

    const data = {
      authUser: userRes.user,
      authToken: token,
      authFeatureFlags: userRes.features,
      authRequiredAgreements: userRes.required_agreements,
      authSharingSettings: userRes.sharing_settings,
      authLicense: userRes.license,
      hasNoPremiumAccess: !userRes.license.has_premium_access,
      isGhostUser: userRes.user.ghost_user_email != "",
      isAuthLoading,
    };

    setAuth(data);

    return data;
  };

  return useMutation(createGuest, { onSuccess, onError });
}
