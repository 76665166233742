import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import { FolderDetailScreen } from "adminComponents/screens/FolderDetailScreen";
import { useAnalytics, usePageTrack } from "lib/contexts/analytics";
import { usePageTitle } from "lib/hooks/usePageTitle";
import { useAuth } from "links/lib/features/auth";
import { useFetchCollections } from "links/lib/features/collections";
import { useFetchPracticeSets } from "links/lib/features/practiceSets";
import { AnalyticsEvent, AnalyticsPage } from "links/lib/types";
import { useNavigationData } from "screens/TeacherDashboard/contexts/TeacherNavigationDataProvider";
import { useCreatePracticeSet } from "sharedComponents/hooks/useCreatePracticeSet";

import { useMutateFolder } from "../shared/hooks/useMutateFolder";
import { usePracticeSetHandlers } from "../shared/hooks/usePracticeSetHandlers";

export const FolderDetail: React.FC = () => {
  const { collectionId } = useParams<{ collectionId: string }>();
  const { authUser, isFeatureEnabled } = useAuth();
  const { navigationData } = useNavigationData();
  const { t } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "myLibraryContainer.folderDetail",
  });
  const history = useHistory();

  const { trackEvent } = useAnalytics();
  usePageTrack(AnalyticsPage.TeacherDashboard_MyLibrary_Collection);

  const collectionsFetch = useFetchCollections();
  const practiceSetsFetch = useFetchPracticeSets({
    collection_id: collectionId,
    include_how_to_play_sets: true,
  });

  const collections = collectionsFetch.data?.collections || [];
  const collection = collectionsFetch.data?.collections.find(
    (c) => c.id === collectionId
  );

  usePageTitle([collection?.name, t("myLibraryBreadcrumb")]);

  const onFolderDelete = () => {
    history.push("/t/my-library");
  };

  const { components: practiceSetHandlerComponents, ...practiceSetHandlers } =
    usePracticeSetHandlers({
      collections,
    });
  const { flyout, handleCreate: handleCreatePracticeSet } =
    useCreatePracticeSet({
      collections,
      gradeLevels: navigationData.gradeLevels,
      subjects: navigationData.subjects,
      onOpen: () =>
        trackEvent(
          AnalyticsEvent.TeacherDashboard_MyLibrary_PracticeSetFlyout_CreatePracticeSetIntent,
          {}
        ),
      onSubmit: (data) =>
        trackEvent(
          AnalyticsEvent.TeacherDashboard_MyLibrary_PracticeSetFlyout_CreatePracticeSetConfirm,
          {
            availability: data.availability,
          }
        ),
    });
  const {
    handleEdit: handleEditFolder,
    handleDuplicate: handleDuplicateFolder,
    handleDelete: handleDeleteFolder,
    components: mutateFolderComponents,
  } = useMutateFolder({
    onDeleteSuccess: onFolderDelete,
  });

  const extendedNavData = {
    ...navigationData,
    breadcrumbs: [
      {
        to: "/t/my-library",
        label: t("myLibraryBreadcrumb"),
      },
      {
        to: `/t/my-library/folders/${collectionId}`,
        label: collection?.name || "",
      },
    ],
  };

  return (
    <>
      <FolderDetailScreen
        authUser={authUser}
        navigationData={extendedNavData}
        isLoading={collectionsFetch.isLoading || practiceSetsFetch.isLoading}
        collection={collection}
        handleDeletePracticeSet={practiceSetHandlers.handleDelete}
        handleMovePracticeSetToFolder={practiceSetHandlers.handleMoveToFolder}
        handleDuplicatePracticeSet={practiceSetHandlers.handleDuplicate}
        handleCreatePracticeSet={handleCreatePracticeSet}
        handleDeleteCollection={handleDeleteFolder}
        handleDuplicateCollection={handleDuplicateFolder}
        handleEditCollection={handleEditFolder}
        practiceSets={
          practiceSetsFetch.data?.practice_sets.map((p) => ({
            practiceSet: p,
          })) || []
        }
        showPremiumMarker={!isFeatureEnabled("playtime.teacher.hide_premium")}
      />
      {practiceSetHandlerComponents}
      {flyout}
      {mutateFolderComponents}
    </>
  );
};
