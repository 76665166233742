import { pxToRem } from "adminComponents/utils/pxToRem";

export const Badge = {
  variants: {
    adminIconButton: (): Record<string, unknown> => ({
      position: "absolute",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      bottom: 0,
      right: 0,
      transform: "translate(42%, 0%)",
      borderRadius: "full",
      borderWidth: 0,
      bgColor: "utility.question-red",
      color: "primary.white",
      width: pxToRem(16),
      height: pxToRem(16),

      p: {
        color: "primary.white",
        fontSize: pxToRem(12),
        fontWeight: 600,
      },
    }),
  },
};
