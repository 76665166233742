import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { useAxios } from "links/lib/hooks/useAxios";
import {
  ISeasonLocationAvatarItem,
  ISeasonLocationAvatarItemGroup,
} from "links/lib/types";

export type ExchangeTicketArgs =
  | {
      avatar_item_id: string;
    }
  | {
      avatar_item_group_id: string;
    };

export interface IUseExchangeTicketArgs {
  onSuccess?: (data: ExchangeTicketResponse) => void;
}

export type ExchangeTicketResponse = {
  avatar_item?: ISeasonLocationAvatarItem;
  avatar_item_group?: ISeasonLocationAvatarItemGroup;
};

export default function useExchangeTicket({
  onSuccess,
}: IUseExchangeTicketArgs): UseMutationResult<
  ExchangeTicketResponse,
  unknown,
  ExchangeTicketArgs
> {
  const axios = useAxios();
  const queryClient = useQueryClient();

  const visitLocation = async (
    args: ExchangeTicketArgs
  ): Promise<ExchangeTicketResponse> => {
    const response = await axios({
      method: "post",
      url: `/v1/season/tickets/exchange`,
      data: args,
    }).then((r) => r.data as ExchangeTicketResponse);

    queryClient.invalidateQueries("seasonMap", { exact: true });
    queryClient.invalidateQueries("avatar", { exact: true });

    return response;
  };

  const mutation = useMutation(visitLocation, { onSuccess });

  return mutation;
}
