import { omit } from "lodash";

import { IUseAsyncReturn, useAsync } from "../../hooks/useAsync";
import { useAxios } from "../../hooks/useAxios";

export interface IUploadFileOpts {
  file: File;
}

export interface IUploadFileResponse {
  url: string;
  name: string;
}

export interface IUploadFileProps {
  onSuccess?: (data: IUploadFileResponse) => void;
  onError?: (err: Error) => void;
}

/**
 * Uploads a file to file storage and returns accessible url
 * @returns
 */
export default function useFetchFileUpload(
  props?: IUploadFileProps
): IUseAsyncReturn<IUploadFileResponse, IUploadFileOpts> {
  const axios = useAxios();

  const uploadFile = async ({ file }: IUploadFileOpts) => {
    const { destination_url, presigned_url } = await axios
      .get(`/v1/files?key=${encodeURIComponent(file.name)}`)
      .then(
        (r) => r.data as { destination_url: string; presigned_url: string }
      );

    await axios({
      method: "put",
      url: presigned_url,
      data: file,
      headers: { "Content-Type": file.type },
      transformRequest: (data, headers) => {
        if (headers?.common) {
          (headers.common as unknown as Partial<Record<string, unknown>>) =
            omit(headers.common as unknown as Record<string, unknown>, [
              "Authorization",
            ]);
        }

        return data;
      },
    });

    return {
      url: destination_url,
      name: file.name,
    };
  };

  const { isLoading, error, data, execute, isSuccess } = useAsync<
    IUploadFileResponse,
    IUploadFileOpts
  >(uploadFile, {
    onError: props?.onError,
    onSuccess: props?.onSuccess,
  });

  return {
    isLoading,
    error,
    data,
    isSuccess,
    execute,
  };
}
