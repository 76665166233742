import React, { createContext, useContext, useMemo } from "react";

import { ISessionUsers } from "../types";

export const SessionTeachersContext = createContext<ISessionUsers>({});

export interface ISessionTeachersProviderProps {
  wsStateTeachers: ISessionUsers;
}

export const SessionTeachersProvider: React.FC<
  ISessionTeachersProviderProps
> = ({ children, wsStateTeachers }) => {
  const value = useMemo(() => wsStateTeachers, [wsStateTeachers]);
  return (
    <SessionTeachersContext.Provider value={value}>
      {children}
    </SessionTeachersContext.Provider>
  );
};

export const useSessionTeachers = (): ISessionUsers => {
  return useContext(SessionTeachersContext);
};
