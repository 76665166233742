import { BaseEditor, Editor, Transforms } from "slate";
import { HistoryEditor } from "slate-history";
import { ReactEditor } from "slate-react";
import { v4 as uuidv4 } from "uuid";

import { RichTextElementType, RichTextTextType } from "links/lib/types";

declare module "slate" {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor & HistoryEditor;
    Element: RichTextElementType;
    Text: RichTextTextType;
  }
}

// Define our own custom set of helpers.
const CustomEditor = {
  isStyleMarkActive(editor: Editor, mark: string): boolean {
    const marks = Editor.marks(editor);
    return marks ? marks[mark as keyof typeof marks] === true : false;
  },

  toggleStyleMark(editor: Editor, mark: string): void {
    const isActive = CustomEditor.isStyleMarkActive(editor, mark);
    if (isActive) {
      Editor.removeMark(editor, mark);
    } else {
      Editor.addMark(editor, mark, true);
    }
  },

  updateLatex(editor: Editor, key: string | null, latexContent: string): void {
    if (!key) {
      Transforms.insertNodes(editor, {
        type: "latex",
        key: uuidv4(),
        content: latexContent,
        children: [{ text: "" }],
      });

      const selection = editor.selection;

      // move selection (and collapse) to after the inserted latex
      if (selection)
        Transforms.move(editor, {
          distance: 1,
          unit: "offset",
        });
    } else {
      Transforms.setNodes(
        editor,
        { content: latexContent },
        {
          match: (n) =>
            "type" in n && n.type === "latex" && "key" in n && n.key === key,
        }
      );
    }
  },
};

export default CustomEditor;
