import { AxiosInstance } from "axios";
import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";

export interface IUseFetchPracticeSetItemAttemptCountProps {
  classroom_id?: string;
  user_id?: string;
  practice_set_id?: string;
  assignment_id?: string;
  practice_set_session_id?: string;
  enabled?: boolean;
}

interface IPracticeSetItemAttemptCount {
  practice_set_item_id: string;
  attempts: number;
}

export interface IUseFetchPracticeSetItemAttemptCountResult {
  practice_set_item_attempts: Array<IPracticeSetItemAttemptCount>;
}

export const fetchPracticeSetItemAttemptCountQuery = (
  axios: AxiosInstance,
  params: IUseFetchPracticeSetItemAttemptCountProps
): (() => Promise<IUseFetchPracticeSetItemAttemptCountResult>) => {
  const {
    classroom_id,
    user_id,
    practice_set_id,
    assignment_id,
    practice_set_session_id,
  } = params;

  return async (): Promise<IUseFetchPracticeSetItemAttemptCountResult> => {
    return await axios
      .get(
        `/v1/groups/${classroom_id}/users/${user_id}/practice-sets/${practice_set_id}/item-attempts-count`,
        {
          params: {
            assignment_id,
            practice_set_session_id,
          },
        }
      )
      .then((r) => r.data as IUseFetchPracticeSetItemAttemptCountResult);
  };
};

export default function useFetchPracticesetItemAttemptsCount(
  params: IUseFetchPracticeSetItemAttemptCountProps
): UseQueryResult<IUseFetchPracticeSetItemAttemptCountResult, unknown> {
  const axios = useAxios();
  const {
    enabled = true,
    classroom_id,
    practice_set_id,
    user_id,
    assignment_id,
    practice_set_session_id,
  } = params;

  const queryEnabled =
    enabled && !!classroom_id && !!practice_set_id && !!user_id;

  const query = useQuery<IUseFetchPracticeSetItemAttemptCountResult>(
    [
      "practice-set-item-attempts",
      {
        classroom_id,
        practice_set_id,
        user_id,
        assignment_id,
        practice_set_session_id,
      },
    ],
    fetchPracticeSetItemAttemptCountQuery(axios, params),
    { enabled: queryEnabled }
  );

  return query;
}
