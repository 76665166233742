import {
  Box,
  Flex,
  useBreakpointValue,
  useMultiStyleConfig,
  usePrevious,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { IOption as DropdownOption } from "adminComponents/atoms/Dropdown";
import { LoadingSpinner } from "adminComponents/atoms/LoadingSpinner";
import { Modal } from "adminComponents/atoms/Modal";
import { Tag } from "adminComponents/atoms/Tag";
import { Text } from "adminComponents/atoms/Text";
import { TextLink } from "adminComponents/atoms/TextLink";
import {
  FilterPracticeSet,
  FilterPracticeSetData,
} from "adminComponents/molecules/FilterPracticeSet";
import { SearchInput } from "adminComponents/molecules/SearchInput";
import { StandardsPickerCard } from "adminComponents/molecules/StandardsPickerCard";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { useAuth } from "links/lib/features/auth";
import { useFetchStandardCollectionsOnce } from "links/lib/features/standardCollections";
import { IStandard } from "links/lib/types";

interface SelectStandardsModalProps {
  isOpen: boolean;
  isLoading: boolean;
  isSearching: boolean;
  states: DropdownOption[];
  regionFilter: string;
  grades: DropdownOption[];
  gradeLevelIdFilter: string;
  standardCollectionIdFilter: string;
  subjects: DropdownOption[];
  subjectIdFilter: string;
  standards: IStandard[];
  handleChangeFilter: (
    state: string,
    subject: string,
    grade: string,
    standardCollection?: string
  ) => void;
  handleClose: () => void;
  handleSearch: (search: string) => void;
  handleSaveAndClose: (standards: IStandard[]) => void;
  selectedStandards?: Array<IStandard>;
}

export const SelectStandardsModal: React.FC<SelectStandardsModalProps> = ({
  isOpen,
  isLoading,
  isSearching,
  states,
  regionFilter,
  grades,
  gradeLevelIdFilter,
  subjects,
  subjectIdFilter,
  standardCollectionIdFilter,
  standards,
  handleChangeFilter,
  handleClose,
  handleSearch,
  handleSaveAndClose,
  selectedStandards,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const { authUser } = useAuth();
  const isMobile = !!useBreakpointValue({ base: true, md: false });
  const [filterData, setFilterData] = useState<FilterPracticeSetData>({
    grade: "",
    state: "",
    subject: "",
    standardCollection: "",
  });
  const [checkedStandards, setCheckedStandards] = useState<IStandard[]>(
    selectedStandards ?? []
  );
  const isListLoading = isSearching || isLoading;
  const styles = useMultiStyleConfig("AdminSelectStandardsModal", {
    isListLoading,
  });

  const standardCollectionFetch = useFetchStandardCollectionsOnce();

  const standardCollectionFetchRef = useRef(standardCollectionFetch);

  const prevSelectedStandards = usePrevious(selectedStandards);
  useEffect(() => {
    if (prevSelectedStandards !== selectedStandards && selectedStandards) {
      setCheckedStandards(selectedStandards);
    }
  }, [prevSelectedStandards, selectedStandards]);

  useEffect(() => {
    setFilterData({
      state: regionFilter,
      subject: subjectIdFilter,
      grade: gradeLevelIdFilter,
    });

    standardCollectionFetchRef.current.execute({
      limit: 50,
      offset: 0,
      country: authUser?.country || "US",
      region: regionFilter,
      grade_level_id: gradeLevelIdFilter,
      subject_id: subjectIdFilter,
    });
  }, [
    gradeLevelIdFilter,
    subjectIdFilter,
    regionFilter,
    authUser?.country,
    standardCollectionFetchRef,
  ]);

  const onChangeFilter = (
    state: string,
    subject: string,
    grade: string,
    standardCollection?: string
  ) => {
    setFilterData({ state, subject, grade, standardCollection });
    handleChangeFilter(state, subject, grade, standardCollection);
  };

  const handleRemoveState = () => {
    setFilterData((prev) => {
      onChangeFilter(
        "",
        filterData.subject,
        filterData.grade,
        filterData.standardCollection
      );
      return { ...prev, state: "" };
    });
  };

  const handleRemoveSubject = () => {
    setFilterData((prev) => {
      onChangeFilter(
        filterData.state,
        "",
        filterData.grade,
        filterData.standardCollection
      );
      return { ...prev, subject: "" };
    });
  };

  const handleRemoveGrade = () => {
    setFilterData((prev) => {
      onChangeFilter(
        filterData.state,
        filterData.subject,
        "",
        filterData.standardCollection
      );
      return { ...prev, grade: "" };
    });
  };

  const handleResetFilter = () => {
    setFilterData({
      grade: "",
      state: "",
      subject: "",
      standardCollection: "",
    });
    onChangeFilter("", "", "", "");
  };

  const handleToggleStandard = (checked: boolean, standard: IStandard) => {
    setCheckedStandards((prevStandards) => {
      let newStandards: IStandard[] = [];
      if (checked) {
        newStandards = [...prevStandards, standard];
      } else {
        newStandards = prevStandards.filter((s) => s.id !== standard.id);
      }

      return newStandards;
    });
  };

  const selectedStateLabel = states.find(
    (state) => state.value === filterData.state
  )?.label;

  const selectedGradeLabel = grades.find(
    (grade) => grade.value === filterData.grade
  )?.label;

  const selectedSubjectLabel = subjects.find(
    (subject) => subject.value === filterData.subject
  )?.label;

  const showTags =
    !!filterData.grade || !!filterData.state || !!filterData.subject;

  const standardCollectionOptions = useMemo(() => {
    const options =
      regionFilter === ""
        ? []
        : standardCollectionFetch.data?.standard_collections.map(
            (standardCollection) => {
              return {
                value: standardCollection.id,
                label: standardCollection.name,
              };
            }
          ) ?? [];

    return [
      {
        label: t("createQuestion.labelAnyStandardCollection"),
        value: "",
      },
    ].concat(options);
  }, [t, regionFilter, standardCollectionFetch.data]);

  return (
    <Modal
      variant="adminSelectStandardsModal"
      isOpen={isOpen}
      onClose={handleClose}
      title={t("selectStandardsModal.title")}
      size="4xl"
      showBackButton={false}
    >
      <Flex flexDir="column">
        <Box __css={styles.outer}>
          <Text
            variant="adminP1"
            color="primary.dark-gray"
            mb={[pxToRem(20), null, pxToRem(24)]}
          >
            {t("selectStandardsModal.description")}
          </Text>
          <SearchInput
            handleSearch={handleSearch}
            placeholder={t("selectStandardsModal.searchPlaceholder")}
            searching={isSearching}
          />
          <Box __css={styles.spacer} />
          <Box>
            <FilterPracticeSet
              grades={grades}
              subjects={subjects}
              states={states}
              value={filterData}
              regionFilter={regionFilter}
              gradeLevelIdFilter={gradeLevelIdFilter}
              subjectIdFilter={subjectIdFilter}
              modalSubmitCopy="Apply Filters"
              handleChangeFilter={onChangeFilter}
              filterButtonSize="sm"
              standardCollections={standardCollectionOptions}
              standardCollectionIdFilter={standardCollectionIdFilter}
              showStandardsCollectionFilter={true}
            />
            {isMobile && showTags && (
              <Flex flexWrap="wrap" gap={pxToRem(12)} mt={pxToRem(32)}>
                {!!filterData.state && (
                  <Tag rightIcon="close" onClick={handleRemoveState}>
                    {selectedStateLabel}
                  </Tag>
                )}
                {!!filterData.grade && (
                  <Tag rightIcon="close" onClick={handleRemoveGrade}>
                    {selectedGradeLabel}
                  </Tag>
                )}
                {!!filterData.subject && (
                  <Tag rightIcon="close" onClick={handleRemoveSubject}>
                    {selectedSubjectLabel}
                  </Tag>
                )}
                <Flex alignItems="center">
                  <TextLink handleClick={handleResetFilter}>
                    {t("common.clearAll")}
                  </TextLink>
                </Flex>
              </Flex>
            )}
          </Box>
          <Box __css={styles.spacer} />
          <Box __css={styles.standardsPickerContainer}>
            {isListLoading ? (
              <LoadingSpinner />
            ) : (
              standards.map((standard) => (
                <StandardsPickerCard
                  checked={!!checkedStandards.find((s) => s.id === standard.id)}
                  handleChange={(checked) =>
                    handleToggleStandard(checked, standard)
                  }
                  standard={standard}
                  key={standard.id}
                  bgColor="primary.light-gray"
                  borderColor="primary.light-gray"
                />
              ))
            )}
          </Box>
        </Box>
        <Box __css={styles.buttonContainer}>
          <Button
            variant="adminButtonFilled"
            onClick={() => handleSaveAndClose(checkedStandards)}
            isLoading={isLoading}
            isFullWidth={isMobile}
          >
            {t("common.saveAndClose")}
          </Button>
        </Box>
      </Flex>
    </Modal>
  );
};
