import { Box, Flex, HStack } from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Card } from "adminComponents/atoms/Card";
import { Dropdown, IOption } from "adminComponents/atoms/Dropdown";
import { Heading } from "adminComponents/atoms/Heading";
import { Icon } from "adminComponents/atoms/Icon";
import { LoadingSpinner } from "adminComponents/atoms/LoadingSpinner";
import { Text } from "adminComponents/atoms/Text";
import { ClassDetailPracticeSets } from "adminComponents/molecules/ClassDetailPracticeSets";
import { EmptyCard } from "adminComponents/molecules/EmptyCard";
import { PieChartWithLabel } from "adminComponents/molecules/PieChartWithLabel";
import { pxToRem } from "adminComponents/utils/pxToRem";
import {
  IClassroom,
  IPracticeSet,
  PracticeSetReportingSortBy,
} from "links/lib/types";

export interface PracticeSetCardProps {
  accuracy?: number;
  practiceSet: IPracticeSet;
}

interface IProps {
  classroom: IClassroom;
  classroomAccuracy?: number;
  handleMyLibrary: () => void;
  handleSharedLibraries: () => void;
  handleViewMorePracticeSets: () => void;
  practiceSetData?: Array<PracticeSetCardProps>;
  showViewMorePracticeSets?: boolean;
  isLoading?: boolean;
}

const sortOptions = [
  {
    value: PracticeSetReportingSortBy.MostRecent,
    valueString: PracticeSetReportingSortBy.MostRecent.toString(),
    labelKey: "sortByMostRecent",
  },
  {
    value: PracticeSetReportingSortBy.LowToHighAccuracy,
    valueString: PracticeSetReportingSortBy.LowToHighAccuracy.toString(),
    labelKey: "sortByLowToHighAccuracy",
  },
  {
    value: PracticeSetReportingSortBy.HighToLowAccuracy,
    valueString: PracticeSetReportingSortBy.HighToLowAccuracy.toString(),
    labelKey: "sortByHighToLowAccuracy",
  },
];

export const PracticeSetsTab: React.FC<IProps> = ({
  handleMyLibrary,
  handleSharedLibraries,
  handleViewMorePracticeSets,
  practiceSetData,
  showViewMorePracticeSets,
  classroom,
  classroomAccuracy,
  isLoading,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const [sortBy, setSortBy] = useState(PracticeSetReportingSortBy.MostRecent);

  const sortedPracticeSets = useMemo(() => {
    return practiceSetData?.sort((a, b) => {
      if (sortBy === PracticeSetReportingSortBy.MostRecent) {
        const aMostRecent = new Date(
          Math.max(
            ...[
              new Date(
                a.practiceSet.most_recent_live_practice_timestamp
              ).getTime(),
              new Date(
                a.practiceSet.most_recent_assignment_timestamp
              ).getTime(),
            ]
          )
        );
        const bMostRecent = new Date(
          Math.max(
            ...[
              new Date(
                b.practiceSet.most_recent_live_practice_timestamp
              ).getTime(),
              new Date(
                b.practiceSet.most_recent_assignment_timestamp
              ).getTime(),
            ]
          )
        );

        if (aMostRecent > bMostRecent) return -1;
        else if (aMostRecent < bMostRecent) return 1;
        else return 0;
      } else {
        const aAccuracy = a.accuracy || 0;
        const bAccuracy = b.accuracy || 0;

        if (sortBy === PracticeSetReportingSortBy.LowToHighAccuracy) {
          return aAccuracy < bAccuracy ? -1 : 1;
        } else if (sortBy === PracticeSetReportingSortBy.HighToLowAccuracy) {
          return aAccuracy < bAccuracy ? 1 : -1;
        } else {
          return 0;
        }
      }
    });
  }, [practiceSetData, sortBy]);

  return (
    <Box mt={pxToRem(40)}>
      {isLoading && <LoadingSpinner />}
      {!isLoading && (
        <>
          <Flex flexDir="column" gap={pxToRem(20)}>
            <Heading as="h1" variant="adminH3">
              {t("classDetailCards.classAccuracy")}
            </Heading>
            <Text variant="adminP1">
              {t("classDetailCards.classAccuracyDescription")}
            </Text>
            <Card
              variant="adminCardSmallBorder"
              borderColor="primary.tan"
              bgColor="primary.white"
            >
              <HStack spacing={pxToRem(5)}>
                <PieChartWithLabel
                  percentage={classroomAccuracy}
                  text={t("classDetailHeader.averageClassAccuracy")}
                />
              </HStack>
            </Card>
          </Flex>
          <Flex flexDir="column" mt={pxToRem(40)} gap={pxToRem(40)}>
            <Box>
              <Heading as="h2" mb={pxToRem(20)} variant="adminH6">
                {t("classDetailCards.practiceSets")}
              </Heading>
              <Text variant="adminP1">
                {t("classDetailCards.practiceSetsDescription")}
              </Text>
            </Box>
            <Box>
              <HStack>
                <Text variant="adminP2">{t("common.sortBy")}</Text>
                <Box flex={["0.9", null, "0.25"]} zIndex="3">
                  <Dropdown
                    isDisabled={!practiceSetData?.length}
                    handleChange={(e) => {
                      const newSortBy = sortOptions.find(
                        (opt) => opt.valueString === (e as IOption).value
                      );
                      if (newSortBy) setSortBy(newSortBy.value);
                    }}
                    options={sortOptions.map((opt) => ({
                      value: opt.valueString,
                      label: t(`classDetailCards.${opt.labelKey}`),
                    }))}
                    value={[
                      sortOptions.find((opt) => opt.value === sortBy) ||
                        sortOptions[0],
                    ].map((val) => ({
                      value: val.valueString,
                      label: t(`classDetailCards.${val.labelKey}`),
                    }))}
                  />
                </Box>
              </HStack>
            </Box>
            <ClassDetailPracticeSets
              classroom={classroom}
              practiceSetData={sortedPracticeSets}
            />
            {showViewMorePracticeSets && (
              <Flex justifyContent="center" mb={pxToRem(32)} mt={pxToRem(32)}>
                <Button
                  onClick={handleViewMorePracticeSets}
                  rightIcon={
                    <Icon
                      height={pxToRem(7)}
                      icon="button_down_arrow"
                      iconColor="utility.link"
                      ml={pxToRem(11)}
                      width={pxToRem(12)}
                    />
                  }
                  variant="adminTextButtonLarge"
                >
                  {t("common.viewMore")}
                </Button>
              </Flex>
            )}
            {!practiceSetData?.length && (
              <EmptyCard
                actions={[
                  {
                    onClick: handleMyLibrary,
                    size: "lg",
                    text: t("classDetailTabs.ctaLibrary"),
                    variant: "adminButtonFilled",
                  },
                  {
                    onClick: handleSharedLibraries,
                    size: "lg",
                    text: t("classDetailTabs.ctaSharedLibraries"),
                    variant: "adminButtonOutlined",
                  },
                ]}
                text={t("classDetailEmptyCards.practiceSetsText")}
                title={t("classDetailEmptyCards.practiceSetsTitle")}
              />
            )}
          </Flex>
        </>
      )}
    </Box>
  );
};
