import { useDisclosure } from "@chakra-ui/react";
import React, { useState } from "react";

import { IHandleViewStudentResponses } from "adminComponents/molecules/ClassroomAnswerResultsMetric";
import { DrawingResultsModal } from "adminComponents/molecules/DrawingResultsModal";
import {
  IStudentsData,
  StudentsModal,
} from "adminComponents/molecules/StudentsModal";
import { IUseFetchClassroomRecentPracticeSetResponsesResult } from "links/lib/features/classrooms/useFetchClassroomRecentPracticeSetResponses";
import { IFetchClassroomUsersResponse } from "links/lib/features/classrooms/useFetchClassroomUsers";
import {
  IPracticeSetItem,
  ISessionItemResponse,
  QuestionType,
} from "links/lib/types";
import { getStudentsWithMatchingAnswers } from "screens/TeacherClassrooms/PracticeSetDetail/utils/studentDataHelper";
import {
  getCorrectResponseData,
  getPrompt,
  getResponseData,
} from "screens/TeacherClassrooms/shared/utils/practiceSetItemsHelpers";

interface IUseStudentsModalParams {
  classroomPracticeSetResponses?: IUseFetchClassroomRecentPracticeSetResponsesResult;
  classroomStudentsData?: IFetchClassroomUsersResponse;
}

interface IUseStudentsModalResponse {
  studentsModal: React.ReactNode;
  drawingResultsModal: React.ReactNode;
  handleViewStudentResponses: IHandleViewStudentResponses;
}

export const useStudentsModal = ({
  classroomPracticeSetResponses,
  classroomStudentsData,
}: IUseStudentsModalParams): IUseStudentsModalResponse => {
  const [modalData, setModalData] = useState<{
    open: boolean;
    studentsData?: IStudentsData;
  }>({ open: false });
  const [practiceSetItem, setPracticeSetItem] = useState<
    IPracticeSetItem | undefined
  >(undefined);
  const {
    isOpen: isDrawingResultsOpen,
    onOpen: onOpenDrawingResults,
    onClose: onCloseDrawingResults,
  } = useDisclosure();

  const handleViewStudentResponses = (
    practiceSetItem: IPracticeSetItem,
    responses: ISessionItemResponse[],
    choiceId?: string
  ) => {
    setPracticeSetItem(practiceSetItem);
    // The modal for drawing results has significantly different behavior than
    // other question types. Its logic is contained in DrawingResultsModal, a
    // sibling modal to StudentsModal
    if (practiceSetItem.question_type === QuestionType.Draw) {
      onOpenDrawingResults();
    } else {
      const data = getStudentsWithMatchingAnswers({
        classroomPracticeSetResponses,
        responses,
        classroomStudentsData,
      });

      const response = getResponseData(practiceSetItem, choiceId || "");
      const correctResponse = getCorrectResponseData(practiceSetItem)[0];
      const question = {
        number:
          (practiceSetItem.diagram_labels?.findIndex(
            (dl) => dl.id === choiceId
          ) || 0) + 1,
        prompt: getPrompt(practiceSetItem),
        questionType: practiceSetItem.question_type,
        correctResponse: choiceId ? response?.text : correctResponse?.text,
        correctImageUrl: choiceId
          ? response?.image_url
          : correctResponse?.image_url,
        correctImageAltText: choiceId
          ? response?.image_alt_text
          : correctResponse?.image_alt_text,
      };

      setModalData({
        open: true,
        studentsData: { data, question, practiceSetItem, choiceId },
      });
    }
  };

  const handleClose = () => {
    setModalData({ open: false, studentsData: undefined });
  };

  const studentsModal = (
    <StudentsModal
      data={modalData.studentsData}
      handleClose={handleClose}
      isOpen={modalData.open}
      classroomId={classroomStudentsData?.classroom_id || "0"}
    />
  );

  const drawingResultsModal = (
    <DrawingResultsModal
      isOpen={isDrawingResultsOpen}
      handleClose={onCloseDrawingResults}
      students={classroomStudentsData?.users || []}
      practiceSetItemResponses={
        classroomPracticeSetResponses?.user_responses || []
      }
      practiceSetItem={practiceSetItem}
      classroomId={classroomStudentsData?.classroom_id || "0"}
    />
  );

  return {
    handleViewStudentResponses,
    drawingResultsModal,
    studentsModal,
  };
};
