import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { useMount, useSessionStorage } from "react-use";

import { TeacherSignInScreen } from "adminComponents/screens/SignInScreen";
import { useErrorToast } from "adminComponents/utils/toast";
import { afterSignInPathSessionKey } from "lib/constants";
import { usePageTitle } from "lib/hooks/usePageTitle";
import { COPPA_URL } from "links/lib/constants";
import { UserRole } from "links/lib/types";

import useFetchProviderRedirectUrl from "../useFetchProviderRedirectUrl";

type LocationState = {
  from?: {
    pathname?: string;
    search?: string;
  };
};

const SignInTeacher: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const [, setAfterSignInPath] = useSessionStorage(afterSignInPathSessionKey);
  const { i18n, t } = useTranslation("admin", { useSuspense: false });
  usePageTitle(t("signInContainer.teacherPageTitle"));
  const { isLoading, error, data, execute } = useFetchProviderRedirectUrl();

  useErrorToast(error);

  useMount(() => {
    // store the originally intended path in session storage
    // before redirect
    if (location?.state) {
      const { from } = location.state as LocationState;
      if (from && from.pathname) {
        setAfterSignInPath(`${from.pathname}${from.search}`);
      }
    }
  });

  const redirectUrl = data?.redirectUrl;

  // if the redirect URL has been set, then redirect
  useEffect(() => {
    if (redirectUrl) {
      // redirect to URL
      window.location.href = redirectUrl;
    }
  }, [redirectUrl]);

  /**
   * Fetch the redirect URL
   * @param role
   */
  function onSignIn(provider: "Google" | "Clever" | "Microsoft") {
    return async function () {
      execute({ provider, role: UserRole.Teacher, language: i18n.language });
    };
  }

  // while the app is redirecting, we still want to show the loading state
  // in the sub-components
  const isRedirecting = isLoading || !!redirectUrl;

  return (
    <TeacherSignInScreen
      isButtonsLoading={isRedirecting}
      handleSignInWithCleverButtonClick={onSignIn("Clever")}
      handleSignInWithGoogleButtonClick={onSignIn("Google")}
      handleSignInWithMicrosoftButtonClick={onSignIn("Microsoft")}
      handleBack={() => history.push("/sign-in")}
      coppaUrl={COPPA_URL}
    />
  );
};

export default SignInTeacher;
