import { AxiosInstance, default as init } from "axios";
import React, { createContext } from "react";

export const AxiosContext = createContext<AxiosInstance>(init);

export interface IAxiosProviderProps {
  client: AxiosInstance;
}

export const AxiosProvider: React.FC<IAxiosProviderProps> = ({
  client,
  children,
}: React.PropsWithChildren<IAxiosProviderProps>) => {
  return (
    <AxiosContext.Provider value={client}>{children}</AxiosContext.Provider>
  );
};
