import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { IPracticeSetItem } from "../../types";

export interface IFetchPracticeSetItemsResponse {
  practice_set_items: Array<IPracticeSetItem>;
}

export interface IUseFetchPracticeSetItemsArgs {
  practice_set_id: string;
  enabled?: boolean;
  onSuccess?: (data: IFetchPracticeSetItemsResponse) => void;
}

export default function useFetchPracticeSetItems(
  args: IUseFetchPracticeSetItemsArgs
): UseQueryResult<IFetchPracticeSetItemsResponse> {
  const {
    practice_set_id,
    enabled = !!practice_set_id && practice_set_id !== "0",
    onSuccess,
  } = args;

  const axios = useAxios();

  const query = useQuery<IFetchPracticeSetItemsResponse>(
    ["practiceSetItems", { practice_set_id }],
    async (): Promise<IFetchPracticeSetItemsResponse> => {
      const response = await axios
        .get(`/v1/practice-sets/${practice_set_id}/items`)
        .then((r) => r.data as { practice_set_items: Array<IPracticeSetItem> });

      return {
        practice_set_items: response.practice_set_items,
      };
    },
    { enabled, onSuccess }
  );

  return query;
}
