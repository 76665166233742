import { UseSessionWebSocketResult } from "./features/sessions/useSessionWebSocket";

export interface ISeason {
  id: string;
  name: string;
  start_date: string;
  end_date: string;
}

export interface IAssignment {
  id: string;
  description: string;
  created_by: string;
  group_id: string;
  practice_set_id: string;
  practice_set: IPracticeSet;
  required_duration_sec: number;
  required_score_percent: number;
  // Deprecated -- these statuses were based on performance in all sessions that included items
  // from the assigned set. We'd like to only track performance in sessions that the student
  // played individually. That performance is tracking in best_user_attempts.
  user_statuses: Array<IAssignmentStatus>;
  best_user_attempts: Array<IAssignmentUserAttempt>;
  out_of_range_practice_set_attempts: Array<IAssignmentOutOfRangeUserPracticeSetAttempt>;
  user_attempt_counts?: Array<IUserAttemptCount>;
  user_ids: Array<string>;
  starts_at: string;
  ends_at: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  third_party: string;
  third_party_assignment_id?: string;
  third_party_group_id?: string;
  randomize_item_order: boolean;
}

export interface IAssignmentOutOfRangeUserPracticeSetAttempt {
  user_id: string;
  practice_set_session_id: string;
  start_time: Date;
  end_time: Date;
  item_count: number;
  response_count: number;
}

export interface IUserAttemptCount {
  user_id: string;
  attempts: number;
}

export interface IAssignmentStatus {
  user_id: string;
  answer_count: number;
  correct_count: number;
  questions_count: number;
  time_spent_sec: number;
}

export interface IAssignmentUserAttempt {
  user_id: string;
  accuracy: number;
  assignment_id?: string;
  group_id?: string;
  id: string;
  practice_set_session_id: string;
  created_at?: string;
  deleted_at?: string;
  updated_at?: string;
}

export interface IFeatureFlags {
  [key: string]: boolean;
}

export enum SharingSettingsVisibility {
  Public = "PUBLIC",
  GSOnly = "GS_ONLY",
  Private = "PRIVATE",
}

export interface ISharingSettings {
  visibility: SharingSettingsVisibility;
  share_statistics: boolean;
  share_grade_levels: boolean;
  share_subjects: boolean;
  share_region: boolean;
}

export enum AgreementType {
  Eula = "EULA",
  PrivacyPolicy = "Privacy Policy",
  ProductPrivacyPolicy = "Product Privacy Policy",
  DataProcessingAgreement = "Data Processing Agreement",
  COPPA = "COPPA",
}

export interface IAgreement {
  id: string;
  title: string;
  subtitle: string;
  agreement_url: string;
  agreement_html: string;
  agreement_text: string;
  agreement_type: AgreementType;
  can_be_deferred: boolean;
  can_defer_days: number;
  major_version: number;
  minor_version: number;
}

export interface ILicense {
  end_date: string;
  has_premium_access: boolean;
  is_trial: boolean;
  is_in_grace_period: boolean;
}

export interface IUser {
  id: string;
  email: string;
  ghost_user_email?: string;
  custom_url_code?: string;
  organization_id?: string;
  organization_name?: string;
  organization_is_internal?: boolean;
  given_name?: string;
  family_name?: string;
  student_nickname?: string;
  teacher_preferred_name?: string;
  teacher_title?: string;
  teacher_school_or_district_name?: string;
  teacher_school_or_district_postal_code?: string;
  profile_image_url?: string;
  role: UserRole;
  true_role: UserRole;
  license_id: string;
  timezone_locale: string;
  language: string;
  country: string;
  region: string;
  is_guest: boolean;
  is_ephemeral?: boolean;
  teacher_grade_levels?: IUserGradeLevel[];
  teacher_subjects?: IUserSubject[];
  student_season_level?: number;
  student_season_current_level_xp_threshold?: number;
  student_season_next_level_xp_threshold?: number;
  student_season_total_xp?: number;
  student_grade_level_id?: string;
  created_at?: string;
}

export interface IUsersGroup {
  id?: string;
  user_id: string;
  group_id: string;
  user_role: UsersGroupsRole;
  created_by?: string;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
}

export interface IHATStats {
  session_count?: number;
  assignment_count?: number;
}

export interface ITeacherOnboardingChecklist {
  has_completed_profile: boolean;
  has_edited_avatar: boolean;
  has_invited_students: boolean;
  // No longer used as an onboarding item in explore page onboarding
  has_copied_public_set: boolean;
  // Replaced by more specific booleans below
  // has_started_live_practice_or_created_assignment: boolean;
  has_started_live_practice: boolean;
  has_created_assignment: boolean;
}

export enum AvatarItemCategoryId {
  // TODO: Add to backend
  Mix = "0",
  Hair = "1",
  Facial_Hair = "2",
  Hat = "3",
  Ears = "4",
  Glasses = "5",
  Eyes = "6",
  Nose = "7",
  Cheeks = "8",
  Facial_Detail = "9",
  Forehead = "10",
  Mouth = "11",
  Head = "12",
  Body = "13",
  Top = "14",
  Cape = "15",
  Neck = "16",
  Back = "17",
  Left_Hand = "18",
  Right_Hand = "19",
  Left_Wrist = "20",
  Right_Wrist = "21",
  Bottom = "22",
  Socks = "23",
  Shoes = "24",
  Extras = "25",
  Wheelchair = "26",
  Lower_Face = "27",
  Waist = "28",
  Ear_Accessories = "29",
  Chair_Style = "30",
  Wheel_Style = "31",
  Head_Attachments = "32",
  Armrest_Style = "33",
  Frame_Stickers = "34",
  Joystick_Style = "35",
  Spoke_Guards = "36",
  Chair_Accessories = "37",
}

export interface ISeasonLevel {
  id: string;
  level: number;
  season_id: string;
  xp_threshold: number;
}

export interface ISeasonZone {
  id: string;
  season_id: string;
  name: string;
  description: string;
  locations: Array<ISeasonLocation>;
}

export interface ISeasonLocation {
  id: string;
  season_id: string;
  zone_id: string;
  name: string;
  description: string;
  total_avatar_items_count: number;
  total_avatar_item_groups_count: number;
  user_avatar_items_count: number;
  user_avatar_item_groups_count: number;
  avatar_items: Array<ISeasonLocationAvatarItem>;
  avatar_item_groups: Array<ISeasonLocationAvatarItemGroup>;
}

export interface ISeasonLocationAvatarItem {
  id: string;
  season_id: string;
  avatar_item_category_id: AvatarItemCategoryId;
  season_location_id: string;
  season_location_order: number;
  name: string;
  description: string;
  preview_image_asset_url: string;
  rarity: AvatarItemRarityLevels;
  is_unlocked: boolean;
  can_unlock: boolean;
  default_replacement_hex_code: string;
}

export interface ISeasonLocationAvatarItemGroup {
  id: string;
  season_id: string;
  season_location_id: string;
  season_location_order: number;
  name: string;
  description: string;
  preview_image_asset_url: string;
  rarity: AvatarItemRarityLevels;
  is_unlocked: boolean;
  can_unlock: boolean;
  avatar_item_category_ids: Array<AvatarItemCategoryId>;
  default_replacement_hex_code: string;
}

export enum UserRole {
  Admin = "ADMIN",
  Student = "STUDENT",
  Teacher = "TEACHER",
  ContentSpecialist = "CONTENT_SPECIALIST",
  Uninitialized = "UNINITIALIZED",
}

export enum UsersGroupsRole {
  Owner = "OWNER",
  Member = "MEMBER",
  Assistant = "ASSISTANT",
}

export interface ISubject {
  id: string;
  parent_id: string;
  parent_name: string;
  name: string;
  country: string;
  region: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

export interface IUserSubject {
  id: string;
  subject_id: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

export interface IGradeLevel {
  id: string;
  grade_level: string;
  country: string;
  region: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

export interface IUserGradeLevel {
  id: string;
  grade_level_id: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

export interface IClassroomGradeLevel {
  id: string;
  grade_level_id: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

export interface IClassroomSubject {
  id: string;
  subject_id: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

export interface IClassroomPracticeSet {
  id: string;
  group_id: string;
  practice_set_id: string;
  is_independent_practice_disabled: boolean;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

export interface IClassroomPendingUser {
  id: string;
  group_id: string;
  user: IUser;
}

export interface IClassroomJoinLink {
  id: string;
  group_id: string;
  code: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  expires_at: string;
}

export enum ThirdParty {
  GOOGLE_CLASSROOM = "GOOGLE_CLASSROOM",
  NONE = "NONE",
}

export interface IClassroom {
  id: string;
  name: string;
  description: string;
  students_count: number;
  teachers_count: number;
  group_subjects: Array<IClassroomSubject>;
  group_grade_levels: Array<IClassroomGradeLevel>;
  third_party: ThirdParty;
  third_party_id?: string;
  users_groups_role?: UsersGroupsRole;
  assignment_notifications_disabled: boolean;
  created_by: string;
  created_at: string;
  archived_at: string;
  updated_at: string;
  deleted_at: string;
}

export interface ICustomSessionGroup {
  id: string;
  group_id?: string;
  created_by?: string;
  user_ids: string[];
}

export interface ICollection {
  id: string;
  created_by: string;
  parent_id?: string;
  name: string;
  description?: string;
  practice_set_count: number;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

export interface IPracticeSetReferenceMaterial {
  id: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  reference_material: IReferenceMaterial;
}

export interface IPracticeSetSubject {
  id: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  subject: ISubject;
}

export interface IPracticeSetGradeLevel {
  id: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  grade_level: IGradeLevel;
}

export enum SmartSetType {
  None = "SMART_SET_TYPE_NONE",
  Review = "REVIEW",
  StandardsIntervention = "STANDARDS_INTERVENTION",
  Topic = "TOPIC",
  SurpriseMe = "SURPRISE_ME",
}

export interface ISmartSetSettings {
  group_id?: string;
  start_time?: Date;
  end_time?: Date;
  subject_ids?: Array<string>;
}

export enum IPracticeSetType {
  Custom = 0,
  Smart = 1,
  Instant = 2,
}

export interface IPracticeSet {
  id: string;
  title: string;
  description: string;
  language_code: string;
  country: string;
  region: string;
  created_by: string;
  collection_id: string;
  status: PracticeSetStatus;
  availability: PracticeSetAvailability;
  cnc_family_code: string;
  cnc_code: string;
  is_certified: boolean;
  is_digital_citizenship: boolean;
  is_premium: boolean;
  is_how_to_play: boolean;
  source_set_is_certified: boolean;
  source_set_is_premium: boolean;
  subjects: Array<IPracticeSetSubject>;
  grade_levels: Array<IPracticeSetGradeLevel>;
  reference_materials: Array<IPracticeSetReferenceMaterial>;
  item_count: number;
  is_active_in_unit: boolean;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  units_practice_sets_id: string;
  units_practice_sets_order: number;
  practice_set_item_standard_labels?: Array<string>;
  cover_image_icon: CoverImageIconType;
  cover_image_bg_pattern: CoverImageBGPatternType;
  cover_image_bg_color_scheme: CoverImageBGColorSchemeType;
  copied_from_set_id?: string;
  author_name?: string;
  author_profile_image_url?: string;
  author_custom_url_code?: string;
  author_profile_is_private?: boolean;
  author_is_internal_content_specialist?: boolean;
  group_practice_sets_created_at: string;
  most_recent_live_practice_timestamp: string;
  most_recent_assignment_timestamp: string;
  smart_set_type: SmartSetType;
  rating?: IPracticeSetRating;
  statistics?: IPracticeSetStatistics;
}

export interface IPracticeSetStatistics {
  copy_count: number;
}

export interface IPracticeSetRating {
  total_count_positive: number;
}

export interface IAverageUserAccuracy {
  user_id: string;
  average_accuracy: number;
}

export interface IUserAccuracy {
  user_id: string;
  accuracy: number;
  has_data: boolean;
}

export interface IAveragePracticeSetItemAccuracy {
  practice_set_item_id: string;
  average_accuracy: number;
}

export interface IAveragePracticeSetAccuracy {
  practice_set_id: string;
  average_accuracy: number;
}

export interface IPracticeSetAccuracy {
  practice_set_id: string;
  accuracy: number;
}

export interface IPracticeSetItemWithClassroomResults {
  practiceSetItem: IPracticeSetItem;
  classroomResults: IPracticeSetSessionItemReport;
}

export interface IPracticeSetItemAccuracy {
  practice_set_item_id: string;
  accuracy: number;
}

export interface IAssignmentAccuracy {
  assignment_id: string;
  accuracy: number;
}

export interface IUserHours {
  user_id: string;
  hours: number;
}

export interface IStandardAccuracy {
  standard_id: string;
  accuracy: number;
}

export interface IAverageStandardAccuracy {
  standard_id: string;
  average_accuracy: number;
}

export enum SessionType {
  Class = "CLASS",
  Individual = "INDIVIDUAL",
  Peer = "PEER",
  TeacherPreview = "TEACHER_PREVIEW",
}

export enum SessionOuterGame {
  None = "NONE",
  BigBoard = "BIG_BOARD",
  QuickPlay = "QUICK_PLAY",
}

export interface IPendingSession {
  assignmentId?: string;
  practiceSetId?: string;
  subjectId?: string;
  onDemandIntent?: OnDemandIntent;
}

export interface IPracticeSetSession {
  id: string;
  practice_set_id: string;
  practice_set_title: string;
  group_id: string;
  assignment_id: string;
  randomize_order: boolean;
  randomize_item_choice_order: boolean;
  on_demand_intent: OnDemandIntent;
  created_by: string;
  start_time: string;
  end_time: string;
  join_code: string;
  status: PracticeSetSessionStatus;
  generate_questions_from_terms: boolean;
  allow_anonymous_students: boolean;
  allow_student_names: boolean;
  seconds_per_question: number;
  session_type: SessionType;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

export interface IPracticeSetSessionResponse {
  id: string;
  practice_set_id: string;
  practice_set_session_id: string;
  practice_set_item_id: string;
  user_id: string;
  response_text: string;
  choice_id: string;
  choice_text: string;
  zone_id: string;
  choice_image_url: string;
  choice_image_alt_text: string;
  drawing_image_url: string;
  is_correct: boolean;
  start_time: string;
  end_time: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

export interface IPracticeSetItemAccuracy {
  practice_set_item_id: string;
  accuracy: number;
}

export interface ISessionItemResponse {
  id: string;
  choice_id: string;
  zone_id: string;
  response_text: string;
  choice_text: string;
  choice_image_url: string;
  choice_image_alt_text: string;
  drawing_image_url: string;
  is_correct: boolean;
  count: number;
  student_name?: string;
}

export interface IPracticeSetSessionItemReport {
  practice_set_item_id: string;
  total_responses: number;
  correct_responses: number;
  prompt: string;
  question_type: QuestionType;
  responses: Array<ISessionItemResponse>;
  accuracy: number;
  attempts?: number;
}

export interface IPracticeSetSessionUserReport {
  user_id: string;
  total_responses: number;
  correct_responses: number;
  total_seconds: number;
  accuracy: number;
}

export interface IPracticeSetArtwork {
  id: string;
  created_by: string;
  resource_url: string;
  group_ids?: Array<string>;
  practice_set_ids?: Array<string>;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

export interface IPracticeSetIcon {
  id: string;
  resource_url: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

export interface ITag {
  id: string;
  created_by: string;
  name: string;
  is_internal: boolean;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

export interface IReferenceMaterial {
  id: string;
  created_by: string;
  name: string;
  description: string;
  content_url: string;
  content_type: ReferenceMaterialContentType;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

export interface IPracticeSetItem {
  id: string;
  audio_url: string;
  created_by: string;
  diagram_labels?: Array<IDiagramLabel>;
  feedback: string;
  feedback_image_url: string;
  feedback_image_alt_text: string;
  feedback_reference_materials: Array<{
    id: string;
    reference_material: IReferenceMaterial;
    created_at: string;
    updated_at: string;
    deleted_at: string;
  }>;
  video_url: string;
  image_url: string;
  image_alt_text: string;
  is_generated: boolean;
  is_higher_order_thinking: boolean;
  multiple_choice: null | {
    id: string;
    practice_set_item_id: string;
    prompt: string;
    choices: Array<IMultipleChoiceChoice>;
    image_mode?: boolean;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
  };
  multiple_select: null | {
    id: string;
    practice_set_item_id: string;
    prompt: string;
    choices: Array<IMultipleChoiceChoice>;
    image_mode?: boolean;
    created_at: string;
    updated_at: string;
    deleted_at: string;
  };
  open_ended: null | {
    id: string;
    practice_set_item_id: string;
    prompt: string;
    created_at: string;
    updated_at: string;
    deleted_at: string;
  };
  draw: null | {
    id: string;
    practice_set_item_id: string;
    prompt: string;
    explanation: string;
    background_image_url: string;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
  };
  diagram: null | {
    id: string;
    practice_set_item_id: string;
    prompt: string;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
  };
  order: number;
  practice_set_id: string;
  question_type: QuestionType;
  original_question_type?: QuestionType;
  standards: Array<IStandard>;
  tags: Array<{
    id: string;
    tag: ITag;
    created_at: string;
    updated_at: string;
    deleted_at: string;
  }>;
  term_definition: null | {
    id: string;
    practice_set_item_id: string;
    term: string;
    definition: string;
    created_at: string;
    updated_at: string;
    deleted_at: string;
  };
  text_response: null | {
    id: string;
    practice_set_item_id: string;
    prompt: string;
    correct_response: string;
    enable_partial_credit: boolean;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
  };
  number_response: null | {
    id: string;
    practice_set_item_id: string;
    prompt: string;
    correct_response: string;
    prefix: string;
    suffix: string;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
  };
  true_false: null | {
    id: string;
    practice_set_item_id: string;
    prompt: string;
    correct_answer: boolean;
    created_at: string;
    updated_at: string;
    deleted_at: string;
  };
  classify: null | {
    id: string;
    practice_set_item_id: string;
    prompt: string;
    categories: Array<IClassifyCategory>;
    image_mode?: boolean;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
  };
  cnc_code: string;
  is_certified: boolean;
  is_premium: boolean;
  source_item_is_certified: boolean;
  source_item_is_premium: boolean;
  skill_id: string;
  skill: ISkill | null;
  guid: string;
  copied_from_item_id: string;
  edited_from_item_id: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  alternate_text_responses: Array<IAlternateTextResponse>;
}

export interface ILLMPracticeSetItem {
  prompt: string;
  question_type: string;
  choices: string[];
  correct_choice: string;
  correct_response: string;
  alternate_spellings: string[];
  categories: {
    label: string;
    choices: string[];
  }[];
  skill_id?: string;
}

export interface ILLMPracticeSetResponse {
  title: string;
  description: string;
  questions: ILLMPracticeSetItem[];
}

export interface IAlternateTextResponse {
  id?: string;
  practice_set_item_id?: string;
  alternate_text: string;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
}

export interface IMultipleChoiceChoice {
  id?: string;
  practice_set_item_id?: string;
  text: string;
  is_correct: boolean;
  image_url?: string;
  image_alt_text?: string;
}

export interface IDiagramLabel {
  id?: string;
  practice_set_item_id?: string;
  label: string;
  x_offset_ratio: number;
  y_offset_ratio: number;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
}

export interface IClassifyChoice {
  id: string;
  practice_set_item_id?: string;
  category_id?: string;
  text: string;
  image_url?: string;
  image_alt_text?: string;
}

export interface IClassifyCategory {
  id: string;
  practice_set_item_id?: string;
  text: string;
  image_url?: string;
  image_alt_text?: string;
  choices: Array<IClassifyChoice>;
}

export interface IStandard {
  id: string;
  pear_standard_id: string;
  parent_id: string;
  standard_collection_id: string;
  standard_collection_name: string;
  region: string;
  grade_levels?: Array<IGradeLevel>;
  subjects?: Array<ISubject>;
  label: string;
  root_label: string;
  description: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

export interface IStandardCollection {
  id: string;
  acronym: string;
  name: string;
  country: string;
  region: string;
  source_url: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

export enum ListSkillsOrderBy {
  ID = "ID",
  CODE = "CODE",
}

export enum ListSkillsOrderDir {
  ASC = "ASC",
  DESC = "DESC",
}

export enum SkillType {
  PRACTICE_ORIENTED = "PRACTICE_ORIENTED",
  DISCOVERY = "DISCOVERY",
}

export interface ISkill {
  id: string;
  code: string;
  description: string;
  skill_type: SkillType;
  standards: Array<IStandard>;
  preceding_skills: Array<ISkill>;
}

export interface IUnit {
  id: string;
  name: string;
  description: string;
  practice_sets: Array<IPracticeSet>;
  reference_materials: Array<IReferenceMaterial>;
  order: number;
  created_by: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

export interface IUnitPracticeSet {
  id?: string;
  unitId: string;
  collectionId: string;
  practiceSetId: string;
  order: number;
  isActive: boolean;
}

export interface IDragItem {
  index: number;
  id: string;
  type: string;
}

export enum OnDemandIntent {
  NONE = "NONE",
  LS_V1 = "LS_V1", // Learning Science v1
  REDEMPTION = "REDEMPTION",
  HOW_TO_PLAY = "HOW_TO_PLAY",
  DIGITAL_CITIZENSHIP = "DIGITAL_CITIZENSHIP",
}

export enum PracticeSetStatus {
  Draft = "DRAFT",
  Active = "ACTIVE",
  Inactive = "INACTIVE",
}

export enum PracticeSetAvailability {
  Private = "PRIVATE",
  Domain = "DOMAIN",
  Public = "PUBLIC",
  ALL_SEARCH = "ALL_SEARCH",
}

export enum PracticeSetSessionStatus {
  Pending = "PENDING",
  Active = "ACTIVE",
  Complet = "COMPLETE",
}

export enum ReferenceMaterialContentType {
  Link = "LINK",
  File = "FILE",
}

export enum AvatarItemRarityLevels {
  Level1 = 1,
  Level2 = 2,
  Level3 = 3,
  Level4 = 4,
  Level5 = 5,
}

export type RichTextParagraphType = {
  type: "paragraph";
  children: Array<RichTextTextType | RichTextLatexType>;
};

export type RichTextElementType =
  | RichTextParagraphType
  | RichTextLatexType
  | RichTextTextType;

export type RichTextLatexType = {
  type: "latex";
  children: Array<{ text: string }>;
  key: string;
  content: string;
};

export type RichTextTextType = {
  text: string;
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
  subscript?: boolean;
  superscript?: boolean;
  strikethrough?: boolean;
};

export type RichValue = RichTextParagraphType[];
export type RichText = RichValue | string;

export enum QuestionType {
  Unknown = "UNKNOWN",
  TermDefinition = "TERM_DEFINITION",
  MultipleChoice = "MULTIPLE_CHOICE",
  MultipleSelect = "MULTIPLE_SELECT",
  OpenEnded = "OPEN_ENDED",
  TextResponse = "TEXT_RESPONSE",
  NumberResponse = "NUMBER_RESPONSE",
  Diagram = "DIAGRAM",
  Classify = "CLASSIFY",
  Draw = "DRAW",
}

export interface ILocalStore {
  getItem(keyName: string): Promise<string | null>;
  setItem(keyName: string, value: string): Promise<void>;
  deleteItem(keyName: string): Promise<void>;
}

export interface ISessionStore {
  getItem(keyName: string): Promise<string | null>;
  setItem(keyName: string, value: string): Promise<void>;
  deleteItem(keyName: string): Promise<void>;
}

export enum SessionWebSocketMessageType {
  None = "NONE",
  ChangeDrawColor = "CHANGE_DRAW_COLOR",
  ChangeGroupNameRequest = "CHANGE_GROUP_NAME_REQUEST",
  ConfirmAnswer = "CONFIRM_ANSWER",
  GameStateUpdate = "GAME_STATE_UPDATE",
  GroupsMutation = "GROUPS_MUTATION",
  InitiateGame = "INITIATE_GAME",
  InitiateGrouping = "INITIATE_GROUPING",
  InitiateLeaderBoard = "INITIATE_LEADER_BOARD",
  InitiateNextRound = "INITIATE_NEXT_ROUND",
  InitiatePriorRound = "INITIATE_PRIOR_ROUND",
  InitiateOuterGameIntro = "INITIATE_OUTER_GAME_INTRO",
  InitiateOuterGame = "INITIATE_OUTER_GAME",
  InitiatePodium = "INITIATE_PODIUM",
  InitiatePrizeRound = "INITIATE_PRIZE_ROUND",
  InitiatePrizeRoundAwards = "INITIATE_PRIZE_ROUND_AWARDS",
  InitiateRoundReview = "INITIATE_ROUND_REVIEW",
  InitiateVoting = "INITIATE_VOTING",
  InitiateVotingResults = "INITIATE_VOTING_RESULTS",
  InitiateVotingAwards = "INITIATE_VOTING_AWARDS",
  InvalidType = "INVALID_TYPE",
  JoinSessionRequest = "JOIN_SESSION_REQUEST",
  JoinSessionResponse = "JOIN_SESSION_RESPONSE",
  ExitGameRequest = "EXIT_GAME_REQUEST",
  ExitGameResponse = "EXIT_GAME_RESPONSE",
  PlaceTheBigBoardToken = "PLACE_THE_BIG_BOARD_TOKEN",
  RoundUpdate = "ROUND_UPDATE",
  SceneUpdate = "SCENE_UPDATE",
  SetChoice = "SET_CHOICE",
  SendChatMessage = "SEND_CHAT_MESSAGE",
  SendReaction = "SEND_REACTION",
  UpdateCursor = "UPDATE_CURSOR",
  UsersMutation = "USERS_MUTATION",
  StopTheBigBoardScanning = "STOP_THE_BIG_BOARD_SCANNING",
  SendTextPartDrag = "SEND_TEXT_PART_DRAG",
  SetTextPartZone = "SEND_TEXT_PART_ZONE",
  SendTextResponse = "SEND_TEXT_RESPONSE",
  SendSortChoiceDrag = "SEND_SORT_CHOICE_DRAG",
  SetSortChoiceZone = "SET_SORT_CHOICE_ZONE",
  SendDiagramLabelDrag = "SEND_DIAGRAM_LABEL_DRAG",
  SetDiagramLabelZone = "SET_DIAGRAM_LABEL_ZONE",
  SendDrawStroke = "SEND_DRAW_STROKE",
  SendDrawImageUrl = "SEND_DRAW_IMAGE_URL",
  RoundGroupStateUpdate = "ROUND_GROUP_STATE_UPDATE",
  RoundGroupsStateUpdate = "ROUND_GROUPS_STATE_UPDATE",
  SetVote = "SET_VOTE",
  Heartbeat = "HEARTBEAT",
  Reward = "REWARD",
  SetDrawingHidden = "SET_DRAWING_HIDDEN",
  UpdateActiveTyping = "UPDATE_ACTIVE_TYPING",
  PutSessionState = "PUT_SESSION_STATE",
  UndoDrawStroke = "UNDO_DRAW_STROKE",
  ExtendRoundTime = "EXTEND_ROUND_TIME",
  ShuffleGuestName = "SHUFFLE_GUEST_NAME",
  AvatarUpdated = "AVATAR_UPDATED",
  GuestSignedIn = "GUEST_SIGNED_IN",
}

export enum SessionWebSocketAudience {
  None = "NONE",
  Group = "GROUP",
  All = "ALL",
}

export type ISessionWebSocketMessage = {
  type: SessionWebSocketMessageType;
  audience: SessionWebSocketAudience;
  sender_id?: string;
  error?: string;
  error_code?: number;
  session?: {
    code: string;
    id?: string;
    group_id?: string;
    group_name?: string;
    creator_name?: string;
    practice_set_id?: string;
    practice_set_title?: string;
    practice_set_is_how_to_play?: boolean;
    practice_set_is_digital_citizenship?: boolean;
    allow_anonymous_students?: boolean;
    session_type?: SessionType;
    seconds_per_round?: number;
    practice_set_cover_image_icon?: CoverImageIconType;
    practice_set_cover_image_background_pattern?: CoverImageBGPatternType;
    practice_set_cover_image_background_color_scheme?: CoverImageBGColorSchemeType;
    on_demand_intent?: OnDemandIntent;
    xp_multiplier?: number;
  };
  cursor?: {
    x: number;
    y: number;
    timestamp?: string;
  };
  chat_message?: SessionChatMessage;
  state?: ISessionWebSocketState;
  user_data?: SessionUserData;
  user_rewards?: Array<IUserReward>;
  server_time?: Date;
  extend_time_by_seconds?: number;
};

export type IUserReward = {
  level: number;
  avatar_item: IAvatarItem;
  is_care_package: boolean;
};

export type ISessionHideDrawing = {
  group_id: string;
  hidden: boolean;
};

export enum ChallengeType {
  Daily = "DAILY",
  Weekly = "WEEKLY",
  Monthly = "MONTHLY",
}

export type CompletedChallenge = {
  challenge_type: ChallengeType;
  awarded_xp: number;
  awarded_avatar_item_id: number;
};

export type SessionUserData = {
  the_big_board_space_id?: number;
  the_big_board_row_index?: number;
  reaction?: SessionReaction;
  choice_id?: string;
  is_selected?: boolean;
  text_part_id?: string;
  text_response?: string;
  zone_id?: string;
  label_id?: string;
  position?: IPosition;
  draw_color?: string;
  draw_stroke?: ISessionDrawStroke;
  draw_image_url?: string;
  group_name_part?: SessionGroupNamePart;
  group_id_vote?: string;
  hide_drawing?: ISessionHideDrawing;
  next_round_number?: number;
  session_state?: ISessionWebSocketState;
  guest_user_id?: string;
};

export type ISessionStudentFeedback = {
  id: string;
  practice_set_session_id: string;
  user_id: string;
  pre_session_mood_score: number;
  pre_session_submitted_at: string;
  post_session_mood_score: number;
  post_session_submitted_at: string;
  created_at: string;
  deleted_at: string;
};

export enum SessionChatMessage {
  FollowMe = "FOLLOW_ME",
  MaybeThis = "MAYBE_THIS",
  ImNotSure = "IM_NOT_SURE",
  MyBad = "MY_BAD",
  NiceWork = "NICE_WORK",
  LetsGo = "LETS_GO",
  ItsOK = "ITS_OK",
  ThisOne = "THIS_ONE",
  DreamTeam = "DREAM_TEAM",
  Scorching = "SCORCHING",
  OnFire = "ON_FIRE",
}

export enum SessionReaction {
  Clap = "CLAP",
  Heart = "HEART",
  Fire = "FIRE",
}

export enum SessionScene {
  Grouping = "GROUPING",
  LeaderBoard = "LEADER_BOARD",
  Lobby = "LOBBY",
  Round = "ROUND",
  RoundReview = "ROUND_REVIEW",
  OuterGameIntro = "OUTER_GAME_INTRO",
  OuterGame = "OUTER_GAME",
  Podium = "PODIUM",
  PrizeRound = "PRIZE_ROUND",
  PrizeRoundAwards = "PRIZE_ROUND_AWARDS",
  IndividualSessionLobby = "STUDENT_SESSION_LOBBY", //todo update when this is updated on BE
  Voting = "VOTING",
  VotingResults = "VOTING_RESULTS",
  VotingAwards = "VOTING_AWARDS",
}

export enum SessionGameType {
  TheBigBoard = "THE_BIG_BOARD",
  QuickPlay = "QUICK_PLAY",
}

export enum PracticeSessionItemVariantType {
  NoVariant = "NO_VARIANT",
  CoopRadios = "COOP_RADIOS",
  CoopChecks = "COOP_CHECKS",
  CoopDragsort = "COOP_DRAGSORT",
  CoopTextMatch = "COOP_TEXT_MATCH",
  CoopTextMatchNumeric = "COOP_TEXT_MATCH_NUMERIC",
  CoopDraw = "COOP_DRAW",
}

export interface ISessionSelectedChoice {
  user_id: string;
  choice_id: string;
  is_correct: boolean;
  is_partially_correct: boolean;
  is_selected: boolean;
}

export enum SessionRoundAnimationNames {
  SlideLeft = "SLIDE_LEFT",
  SlideRight = "SLIDE_RIGHT",
  SlideUp = "SLIDE_UP",
  SlideDown = "SLIDE_DOWN",
}

export enum SessionSplashAnimationNames {
  Swoosh = "SWOOSH",
  Latch = "LATCH",
  VerticalClap = "VERTICAL_CLAP",
  Jump = "JUMP",
}
// todo this needs to be updated on BE also
export interface ISessionDraggableSortItem {
  // the id of the item (generated server-side for round)
  id: string;
  // the text content of the item
  text: string;
  // the image url of the item
  image_url?: string;
  // alternate text for the image for screen readers
  image_alt_text?: string;
  // the id of the zone this item has been placed in.
  zone_id?: string;
  // the id of the user who is currently moving this item.
  user_id?: string;
  // whether the owning user is actively dragging this item
  is_dragging: boolean;
  // the current drag position of this part
  drag_position?: IPosition;
  // whether the choice was correct; set only on round_review
  is_correct?: boolean;
}

// a text response submitted by a user
export interface ISessionUserTextResponse {
  text: string;
  user_id: string;
}

// a choice generated by the server
export interface ISessionGeneratedChoice {
  id: string;
  text: string;
  image_url?: string;
  image_alt_text?: string;
  user_id?: string;
}

export interface ISessionDraggableSortItemZone {
  id: string;
  label: string;
  x?: number;
  y?: number;
  image_url?: string;
  image_alt_text?: string;
}

export interface IPosition {
  x: number;
  y: number;
}

export interface ISessionDrawStrokePart {
  start: IPosition;
  end: IPosition;
}

export interface ISessionUserColor {
  user_id: string;
  color: string;
}

export interface ISessionDrawStroke {
  color: string;
  parts: Array<ISessionDrawStrokePart>;
  user_id?: string;
  is_incomplete: boolean;
}

export type VariantData =
  | CoopDragsortVariantData
  | CoopRadiosVariantData
  | CoopChecksVariantData
  | CoopTextMatchVariantData
  | CoopDrawVariantData
  | Record<string, never>;

export type CoopDragsortVariantData = {
  coop_dragsort: {
    zones: Array<ISessionDraggableSortItemZone>;
    correct_text?: string;
    correct_ids?: Array<string>;
    items: Array<ISessionDraggableSortItem>;
  };
};

export type CoopDrawVariantData = {
  coop_draw: {
    group_strokes: {
      [key: string]: { strokes: Array<ISessionDrawStroke> };
    };
    votes: Array<ISessionVote>;
    teacher_vote_group_id: string;
    popular_vote_group_ids: Array<string>;
    explanation: string;
    background_image_url: string;
  };
};

export type CoopTextMatchVariantData = {
  coop_text_match: {
    correct_text?: string;
    alternate_correct_text?: Array<string>;
  };
};

export type CoopRadiosVariantData = {
  coop_radios: {
    choices: Array<ISessionGeneratedChoice>;
    correct_choice_id?: string;
  };
};

export type CoopChecksVariantData = {
  coop_checks: {
    choices: Array<ISessionGeneratedChoice>;
    correct_choice_ids?: Array<string>;
  };
};

export type SessionRoundVariantData =
  | {
      variant: PracticeSessionItemVariantType.CoopRadios;
      variant_data: CoopRadiosVariantData;
    }
  | {
      variant: PracticeSessionItemVariantType.CoopChecks;
      variant_data: CoopChecksVariantData;
    }
  | {
      variant: PracticeSessionItemVariantType.CoopDragsort;
      variant_data: CoopDragsortVariantData;
    }
  | {
      variant: PracticeSessionItemVariantType.CoopTextMatch;
      variant_data: CoopTextMatchVariantData;
    }
  | {
      variant: PracticeSessionItemVariantType.CoopTextMatchNumeric;
      variant_data: CoopTextMatchVariantData;
    }
  | {
      variant: PracticeSessionItemVariantType.CoopDraw;
      variant_data: CoopDrawVariantData;
    }
  | {
      variant: PracticeSessionItemVariantType.NoVariant;
      variant_data: Record<string, never>;
    };

export interface ISessionVote {
  // The group this vote is for
  group_id: string;
  // The user_id who made the vote
  user_id: string;
}

export interface IPracticeSetSessionItemMultipleChoiceChoice {
  id: string;
  text: string;
  image_url?: string;
  image_alt_text?: string;
  user_id?: string;
}

export interface IPracticeSetSessionItem {
  id: string;
  is_certified: boolean;
  practice_set_item_id: string;
  practice_set_id: string;
  question_type: QuestionType;
  original_question_type?: QuestionType;
  variant: PracticeSessionItemVariantType;
  image_url?: string;
  image_alt_text?: string;
  video_url?: string;
  audio_url?: string;
  term_definition?: {
    definition?: string;
    term?: string;
  };
  multiple_choice?: {
    prompt: string;
    choices: Array<IPracticeSetSessionItemMultipleChoiceChoice>;
  };
  multiple_select?: {
    prompt: string;
    choices: Array<IPracticeSetSessionItemMultipleChoiceChoice>;
  };
  true_false?: {
    prompt: string;
  };
  text_response?: {
    prompt: string;
  };
  number_response?: {
    prompt: string;
    prefix: string;
    suffix: string;
  };
  open_ended?: {
    prompt: string;
  };
  diagram?: {
    prompt: string;
  };
  diagram_labels?: Array<{
    label: string;
  }>;
  draw?: {
    prompt: string;
  };
  classify?: {
    prompt: string;
    categories?: Array<{
      id: string;
      image_url: string;
      text: string;
    }>;
    choices?: Array<{ id: string; image_url: string; text: string }>;
  };
}

export type ISessionRoundGroupState = {
  radios?: {
    selected_choices: Array<ISessionSelectedChoice>;
  };
  checks?: {
    selected_choices: Array<ISessionSelectedChoice>;
  };
  dragsort?: {
    items: Array<ISessionDraggableSortItem>;
  };
  text_match?: {
    text_responses: Array<ISessionUserTextResponse>;
    // choices are generated after all group members have submitted initial text
    choices: Array<ISessionGeneratedChoice>;
    selected_choices: Array<ISessionSelectedChoice>;
  };
  draw?: {
    // the user id of the group member who starts the drawing
    draw_leader_user_id: string;
    // the user pen assignments
    pens: Array<{
      user_id: string;
      color: string;
    }>;
    // the lines each student has drawn
    strokes: Array<ISessionDrawStroke>;
  };
  outcomes?: Array<ISessionRoundGroupStateOutcome>;
};

export type ISessionRoundGroupStateOutcome = {
  user_id: string;
  is_correct: boolean;
  is_partially_correct: boolean;
};

export type SessionRoundState = {
  round_number: number;
  practice_set_session_item: IPracticeSetSessionItem;
  // list of group member user ids who have confirmed (or "locked in") their answers
  confirmed_user_ids: Array<string>;
  points_awarded?: number;
  feedback?: string; // Deprecated; TODO: rm
  starts_at: string;
  ends_at: string;
  round_animation_name: SessionRoundAnimationNames;
  splash_animation_name: SessionSplashAnimationNames;
  rich_feedback?: {
    text?: string;
    image_url?: string;
    image_alt_text?: string;
  };
} & SessionRoundVariantData;

export type QuickPlayGameState = {
  game_type: SessionGameType.QuickPlay;
  final_round_number: number;
  quick_play_state: SessionQuickPlayState;
};

export type BigBoardGameState = {
  game_type: SessionGameType.TheBigBoard;
  the_big_board_state: SessionTheBigBoardState;
  final_round_number: number;
};

export type SessionGameState = QuickPlayGameState | BigBoardGameState;

export type SessionQuickPlayState = {
  care_package_rounds: Array<number>;
};

export type SessionTheBigBoardState = {
  rows: Array<SessionTheBigBoardSpaceRow>;
  round_placed_group_ids: { [key: string]: boolean };
  prize_round_space_id?: number;
  round_allowed_group_ids: Array<string>;
  round_token_control_user_ids: Array<string>;
  prize_round_user_id: string;
  prize_round_points: number;
};

export type SessionTheBigBoardSpaceRow = {
  cols: Array<SessionTheBigBoardSpace>;
};

export enum SessionTheBigBoardSpaceType {
  Lootbox = "LOOTBOX",
  Placeable = "PLACEABLE",
  Smiley = "SMILEY",
}

export type SessionTheBigBoardSpace = {
  space_id: number;
  placement_disabled: boolean;
  placements: Array<SessionTheBigBoardPlacement>;
  space_type: SessionTheBigBoardSpaceType;
  icon_url: string; // TODO: We might want to use a key to reference an icon in the UI instead
  smiley_space_point_multiplier?: string;
};

export type SessionTheBigBoardPlacement = {
  group_id: string;
  round_number: number;
};

export type WebSocketSession = {
  code: string;
  id?: string;
  group_id?: string;
  group_name?: string;
  creator_name?: string;
  practice_set_id?: string;
  practice_set_title?: string;
  practice_set_is_how_to_play?: boolean;
  practice_set_is_digital_citizenship?: boolean;
  allow_anonymous_students?: boolean;
  practice_set_cover_image_icon?: CoverImageIconType | RemixIconType;
  practice_set_cover_image_bg_pattern?:
    | CoverImageBGPatternType
    | RemixBGPatternType;
  practice_set_cover_image_bg_color_scheme?:
    | CoverImageBGColorSchemeType
    | RemixBGColorSchemeType;
  seconds_per_round?: number;
  on_demand_intent?: OnDemandIntent;
  session_type?: SessionType;
  xp_multiplier?: number;
};

export type ISessionWebSocketState = {
  session?: WebSocketSession;
  round_state: SessionRoundState | null;
  game_state: SessionGameState;
  users: ISessionUsers; // Students
  teachers: ISessionUsers;
  groups: ISessionGroups;
  round_group_state: ISessionRoundGroupState;
  // For Teachers only. Key is the ID of the group
  round_groups_state: ISessionRoundGroupsState;
  scene: SessionScene;
  server_time_deltas: number[];
  error?: string;
  error_code?: number;
  custom_groups_partially_applied: boolean;
};

export enum SessionWebSocketErrorCodes {
  CodeClassroomGuestUserPermissionDenied = 3000,
}

export enum SessionWebSocketEvent {
  ChatMessage = "CHAT_MESSAGE",
  Error = "ERROR",
  Connect = "CONNECT",
  Reconnect = "RECONNECT",
  Disconnect = "DISCONNECT",
  Reaction = "REACTION",
  UserRewards = "USER_REWARDS",
  ExitGameResponse = "EXIT_GAME_RESPONSE",
}

export enum SessionGroupNamePart {
  ColorScheme = "COLOR_SCHEME",
  Adjective = "ADJECTIVE",
  Noun = "NOUN",
}

export type ISessionGroup = {
  id: string;
  adjective: string;
  noun: string;
  color_scheme: string;
  icon_url: string;
  name_part_user_assignments: Array<{
    user_id: string;
    name_part: SessionGroupNamePart;
  }>;
  total_awarded_points: number;
  total_bonus_points_between_leader_boards: number;
  question_streak: number;
  lost_question_streak: boolean;
  round_awarded_points: number;
  round_bonus_points: number;
};

export type ISessionUser = {
  id: string;
  is_guest: boolean;
  name: string;
  remaining_name_shuffles: number;
  role: UserRole;
  spine_atlas_url: string;
  spine_json_url: string;
  spritesheet_url: string;
  session_group_id: string;
  profile_image_url: string;
  cursor?: {
    x: number;
    y: number;
    timestamp: string;
  };
  round_awarded_points?: number;
  connection_status: SessionConnectionStatus;
  color_scheme:
    | "amber"
    | "tangerine"
    | "monaco"
    | "smoke"
    | "golden"
    | "capri"
    | "pepto";
  session_xp_total: number;
  session_start_xp: number;
  session_xp_items: number;
  session_xp_accuracy_bonus: number;
  session_xp_challenge_completion: number;
  last_active_typing?: Date;
  session_accuracy?: number; // [0, 100]
  initial_streak_days?: number;
  initial_streak_weeks?: number;
  final_streak_days?: number;
  final_streak_weeks?: number;
  completed_challenges?: Array<CompletedChallenge>;
  user_xp_multiplier?: UserXPMultiplier;
};

export type ISessionUsers = { [key: string]: ISessionUser | undefined };
export type ISessionGroups = { [key: string]: ISessionGroup | undefined };
export type ISessionRoundGroupsState = {
  [key: string]: ISessionRoundGroupState | undefined;
};

export enum SessionConnectionStatus {
  Connected = "CONNECTED",
  Offline = "OFFLINE",
  Disconnected = "DISCONNECTED",
}

export enum AvatarSkeletonType {
  Seated = "SEATED",
  Standing = "STANDING",
}

export type IAvatar = {
  user_id: string;
  items: Array<IAvatarItem>;
  hair_color_hex_code: string;
  skin_tone_hex_code: string;
  skeleton_type: AvatarSkeletonType;
};

export type IAvatarItem = {
  id: string;
  avatar_item_category_id: string;
  name: string;
  description: string;
  rarity: AvatarItemRarityLevels;
  is_active: boolean;
  is_public: boolean;
  is_category_default: boolean;
  preview_image_asset_url: string;
  default_replacement_hex_code: string;
  attachments: Array<IAvatarItemAttachment>;
  colors: Array<IAvatarItemColor>;
  earned_at?: string;
};

export type IAvatarItemGroup = {
  id: string;
  season_id: string;
  season_location_id: string;
  name: string;
  description: string;
  rarity: AvatarItemRarityLevels;
  preview_image_asset_url: string;
};

export interface ISessionRoundTheme {
  splashPrimaryColor: string;
  splashSecondaryColor: string;
  boxOutlineColor: string;
  buttonColorScheme: string;
  backgroundStyle: GameBackgroundStyle;
  backgroundColorScheme: GameBackgroundColorScheme;
}

export interface IGameAssetColorScheme {
  primaryColor: string;
  secondaryColor: string;
}

export enum GameBackgroundStyle {
  Arcs = "ARCS",
  Bricks = "BRICKS",
  Bubbles = "BUBBLES",
  Dots = "DOTS",
  Foam = "FOAM",
  Net = "NET",
  Rain = "RAIN",
  Scratches = "SCRATCHES",
  Tiles = "TILES",
  Waves = "WAVES",
}

export enum GameBackgroundColorScheme {
  Smoke = "Smoke",
  Monaco = "Monaco",
  Lilac = "Lilac",
  Anjou = "Anjou",
  Capri = "Capri",
  Golden = "Golden",
  Amber = "Amber",
  Pepto = "Pepto",
  Tangerine = "Tangerine",
  Tomato = "Tomato",
}

export type IAvatarItemAttachment = {
  id: string;
  avatar_item_id: string;
  avatar_slot_name: string;
  image_asset_url: string;
  data_asset_url: string;
};

export type IAvatarItemColor = {
  id: string;
  avatar_item_id: string;
  is_active: boolean;
  hex_code: string;
};

export interface IFetchAvatarResponse {
  avatar?: IAvatar;
  spine_atlas_url?: string;
  spine_json_url?: string;
  spritesheet_url?: string;
}

export interface IFetchAvatarHairStylesResponse {
  items: Array<IAvatarItem>;
}

// {App Section}.{Screen}?.{Screen Section}?.{Action}
export enum AnalyticsEvent {
  // Common
  Common_WindowSizeChange = "Common.WindowSizeChange",
  Common_GuestCreated = "Common.GuestCreated",

  //Assignments page
  Assignments_Page_Start_Assignment = "Assignments.StartAssignment",
  Assignments_Page_Authorize = "Assignments.StartThirdPartyAssignmentAuthorize",

  // Session.Common
  Session_Common_SendReaction = "Session.Common.SendReaction",
  Session_Common_SendChatMessage = "Session.Common.SendChatMessage",
  Session_Common_SceneChange = "Session.Common.SceneChange",
  Session_Common_ForceEndGame = "Session.Common.ForceEndGame",
  Session_Common_CarePackagePending = "Session.Common.CarePackagePending",
  Session_Common_CarePackageDelivered = "Session.Common.CarePackageDelivered",
  Session_Common_ToggleCursorVisibility = "Session.Common.ToggleCursorVisibility",
  Session_Common_ShowChatPicker = "Session.Common.ShowChatPicker",
  Session_Common_WebSocketDisconnect = "Session.Common.WebSocketDisconnect",
  Session_Common_WebSocketReconnect = "Session.Common.WebSocketReconnect",
  Session_Common_WebSocketError = "Session.Common.WebSocketError",
  Session_Common_CopyJoinLink = "Session.Common.CopyJoinLink",

  // Session.Lobby
  Session_Lobby_ToggleStudentList = "Session.Lobby.ToggleStudentList",
  Session_Lobby_CopyJoinLink = "Session.Lobby.CopyJoinLink",

  // Session.StudentLobby
  Session_StudentLobby_EditAvatar_UnsetCategory = "Session.StudentLobby.EditAvatar.UnsetCategory",
  Session_StudentLobby_EditAvatar_EquipItem = "Session.StudentLobby.EditAvatar.EquipItem",
  Session_StudentLobby_EditAvatar_SetSkeletonType = "Session.StudentLobby.EditAvatar.SetSkeletonType",
  Session_StudentLobby_EditAvatar_SetHairColor = "Session.StudentLobby.EditAvatar.SetHairColor",
  Session_StudentLobby_EditAvatar_SetSkinTone = "Session.StudentLobby.EditAvatar.SetSkinTone",
  Session_StudentLobby_Signin_Complete = "Session.StudentLobby.Signin.Complete",
  Session_StudentLobby_Signup_Complete = "Session.StudentLobby.Signup.Complete",

  // Session.StudentGroups
  Session_StudentGroups_ChangeGroupNamePart = "Session.StudentGroups.ChangeGroupNamePart",

  // Session.Round
  Session_Round_Common_EndRoundEarly = "Session.Round.Common.EndRoundEarly",
  Session_Round_Common_SubmitTextResponse = "Session.Round.Common.SubmitTextResponse",
  Session_Round_Common_SubmitChoice = "Session.Round.Common.SubmitChoice",
  Session_Round_Common_ConfirmAnswer = "Session.Round.Common.ConfirmAnswer",
  Session_Round_Draw_EndStudentVote = "Session.Round.Draw.EndStudentVote",
  Session_Round_Draw_ConfirmVote = "Session.Round.Draw.ConfirmVote",

  // Session.RoundReview
  Session_RoundReview_Explain = "Session.RoundReview.Explain",
  Session_RoundReview_Student_NoOutcome = "Session.RoundReview.Student.NoOutcome",

  // Session.BigBoard
  Session_BigBoard_CompleteTokenPlacement = "Session.BigBoard.CompleteTokenPlacement",
  Session_BigBoard_PlaceToken = "Session.BigBoard.PlaceToken",
  Session_BigBoard_CarePackageAward = "Session.BigBoard.CarePackageAward",
  Session_BigBoard_PrizeRoundAward = "Session.BigBoard.PrizeRoundAward",

  // Session.Podium
  Session_Podium_PlayAgain = "Session.Podium.PlayAgain",
  Session_Podium_ViewXPBoost = "Session.Podium.ViewXPBoost",
  Session_Podium_ViewStreak = "Session.Podium.ViewStreak",
  Session_Podium_ViewChallenge = "Session.Podium.ViewChallenge",
  Session_Podium_ViewCarePackage = "Session.Podium.ViewCarePackage",
  Session_Podium_ViewSummary = "Session.Podium.ViewSummary",
  Session_Podium_LevelUp = "Session.Podium.LevelUp",

  // SignIn.OAuthCallback
  SignIn_OAuthCallback_SignedIn = "SignIn.OAuthCallback.SignedIn",

  // TeacherDashboard.Nav
  TeacherDashboard_Nav_PracticeSetFlyout_CreatePracticeSetIntent = "TeacherDashboard.Nav.PracticeSetFlyout.CreatePracticeSetIntent",
  TeacherDashboard_Nav_PracticeSetFlyout_CreatePracticeSetConfirm = "TeacherDashboard.Nav.PracticeSetFlyout.CreatePracticeSetConfirm",

  // TeacherDashboard.Homepage
  TeacherDashboard_Homepage_CreateClassroomIntent = "TeacherDashboard.Homepage.CreateClassroomIntent",
  TeacherDashboard_Homepage_CreateClassroomConfirm = "TeacherDashboard.Homepage.CreateClassroomConfirm",
  TeacherDashboard_Homepage_LiveBanner_JoinSession = "TeacherDashboard.Homepage.LiveBanner.JoinSession",
  TeacherDashboard_Homepage_FollowUser = "TeacherDashboard.Homepage.FollowUser",

  // TeacherDashboard.Account
  TeacherDashboard_Account_SubmitForm = "TeacherDashboard.Account.SubmitForm",

  // TeacherDashboard.NotificationSettings
  TeacherDashboard_NotificationSettings_SubmitForm = "TeacherDashboard.NotificationSettings.SubmitForm",

  // TeacherDashboard.ProfileSettings
  TeacherDashboard_ProfileSettings_SubmitForm = "TeacherDashboard.ProfileSettings.SubmitForm",

  // TeacherDashboard.Onboard
  TeacherDashboard_Onboard_Begin = "TeacherDashboard.Onboard.Begin",
  TeacherDashboard_Onboard_PageChange = "TeacherDashboard.Onboard.PageChange",
  TeacherDashboard_Onboard_Complete = "TeacherDashboard.Onboard.Complete",
  TeacherDashboard_Onboard_EditAvatar_UnsetCategory = "TeacherDashboard.Onboard.EditAvatar.UnsetCategory",
  TeacherDashboard_Onboard_EditAvatar_EquipItem = "TeacherDashboard.Onboard.EditAvatar.EquipItem",
  TeacherDashboard_Onboard_EditAvatar_SetSkeletonType = "TeacherDashboard.Onboard.EditAvatar.SetSkeletonType",
  TeacherDashboard_Onboard_EditAvatar_SetHairColor = "TeacherDashboard.Onboard.EditAvatar.SetHairColor",
  TeacherDashboard_Onboard_EditAvatar_SetSkinTone = "TeacherDashboard.Onboard.EditAvatar.SetSkinTone",
  TeacherDashboard_Onboard_AddStudents_AddByEmail = "TeacherDashboard.Onboard.AddStudents.AddByEmail",

  // TeacherDashboard.Agreements
  TeacherDashboard_Agreements_Begin = "TeacherDashboard.Agreements.Begin",

  // TeacherDashboard.PracticeSetDetail
  TeacherDashboard_PracticeSetDetail_ItemList_StartReorder = "TeacherDashboard.PracticeSetDetail.ItemList.StartReorder",
  TeacherDashboard_PracticeSetDetail_ItemList_CancelReorder = "TeacherDashboard.PracticeSetDetail.ItemList.CancelReorder",
  TeacherDashboard_PracticeSetDetail_ItemList_SaveReorder = "TeacherDashboard.PracticeSetDetail.ItemList.SaveReorder",
  TeacherDashboard_PracticeSetDetail_InfoCard_DuplicateSetIntent = "TeacherDashboard.PracticeSetDetail.InfoCard.DuplicateSetIntent",
  TeacherDashboard_PracticeSetDetail_InfoCard_DuplicateSetConfirm = "TeacherDashboard.PracticeSetDetail.InfoCard.DuplicateSetConfirm",
  TeacherDashboard_PracticeSetDetail_InfoCard_DeleteSetIntent = "TeacherDashboard.PracticeSetDetail.InfoCard.DeleteSetIntent",
  TeacherDashboard_PracticeSetDetail_InfoCard_DeleteSetConfirm = "TeacherDashboard.PracticeSetDetail.InfoCard.DeleteSetConfirm",
  TeacherDashboard_PracticeSetDetail_InfoCard_EditSetIntent = "TeacherDashboard.PracticeSetDetail.InfoCard.EditSetIntent",
  TeacherDashboard_PracticeSetDetail_PracticeSetDetailFlyout_SaveDetail = "TeacherDashboard.PracticeSetDetail.PracticeSetDetailFlyout.SaveDetail",
  TeacherDashboard_PracticeSetDetail_PracticeSetDetailFlyout_UpdateCoverImage = "TeacherDashboard.PracticeSetDetail.PracticeSetDetailFlyout.UpdateCoverImage",
  TeacherDashboard_PracticeSetDetail_QuestionFlyout_ChangeStandards = "TeacherDashboard.PracticeSetDetail.QuestionFlyout.ChangeStandards",
  TeacherDashboard_PracticeSetDetail_QuestionFlyout_DuplicateItem = "TeacherDashboard.PracticeSetDetail.QuestionFlyout.DuplicateItem",
  TeacherDashboard_PracticeSetDetail_QuestionFlyout_OpenItem = "TeacherDashboard.PracticeSetDetail.QuestionFlyout.OpenItem",
  TeacherDashboard_PracticeSetDetail_QuestionFlyout_CloseItem = "TeacherDashboard.PracticeSetDetail.QuestionFlyout.CloseItem",
  TeacherDashboard_PracticeSetDetail_QuestionFlyout_MutateItem = "TeacherDashboard.PracticeSetDetail.QuestionFlyout.MutateItem",
  TeacherDashboard_PracticeSetDetail_QuestionFlyout_DeleteItem = "TeacherDashboard.PracticeSetDetail.QuestionFlyout.DeleteItem",
  TeacherDashboard_PracticeSetDetail_AssignmentFlyout_CreateAssignmentIntent = "TeacherDashboard.PracticeSetDetail.AssignmentFlyout.CreateAssignmentIntent",
  TeacherDashboard_PracticeSetDetail_AssignmentFlyout_CreateAssignmentConfirm = "TeacherDashboard.PracticeSetDetail.AssignmentFlyout.CreateAssignmentConfirm",
  TeacherDashboard_PracticeSetDetail_QuestionFlyout_AddItemFromSearch = "TeacherDashboard.PracticeSetDetail.QuestionFlyout.AddItemFromSearch",
  TeacherDashboard_PracticeSetDetail_QuestionFlyout_SearchItems = "TeacherDashboard.PracticeSetDetail.QuestionFlyout.SearchItems",
  TeacherDashboard_PracticeSetDetail_QuestionFlyout_GenerateItemIntent = "TeacherDashboard.PracticeSetDetail.QuestionFlyout.GenerateItemIntent",
  TeacherDashboard_PracticeSetDetail_QuestionFlyout_GenerateItemConfirm = "TeacherDashboard.PracticeSetDetail.QuestionFlyout.GenerateItemConfirm",
  TeacherDashboard_PracticeSetDetail_QuestionFlyout_GenerateItemImport = "TeacherDashboard.PracticeSetDetail.QuestionFlyout.GenerateItemImport",
  TeacherDashboard_PracticeSetDetail_QuestionFlyout_FeedbackTab_GenerateFeedback = "TeacherDashboard.PracticeSetDetail.QuestionFlyout.FeedbackTab.GenerateFeedback",
  TeacherDashboard_PracticeSetDetail_QuestionFlyout_QuestionTab_GenerateFeedback = "TeacherDashboard.PracticeSetDetail.QuestionFlyout.QuestionTab.GenerateFeedback",
  TeacherDashboard_PracticeSetDetail_QuestionFlyout_AddMediaModal_RecordAudioIntent = "TeacherDashboard.PracticeSetDetail.QuestionFlyout.AddMedialModal.RecordAudioIntent",
  TeacherDashboard_PracticeSetDetail_QuestionFlyout_AddMediaModal_RecordAudioConfirm = "TeacherDashboard.PracticeSetDetail.QuestionFlyout.AddMedialModal.RecordAudioConfirm",
  TeacherDashboard_PracticeSetDetail_QuestionFlyout_AddMediaModal_TextToAudioIntent = "TeacherDashboard.PracticeSetDetail.QuestionFlyout.AddMedialModal.TextAudioIntent",
  TeacherDashboard_PracticeSetDetail_QuestionFlyout_AddMediaModal_TextToAudioConfirm = "TeacherDashboard.PracticeSetDetail.QuestionFlyout.AddMedialModal.TextAudioConfirm",
  TeacherDashboard_PracticeSetDetail_QuestionFlyout_AddMediaModal_UrlUploadIntent = "TeacherDashboard.PracticeSetDetail.QuestionFlyout.AddMedialModal.UrlUploadIntent",
  TeacherDashboard_PracticeSetDetail_QuestionFlyout_AddMediaModal_UrlUploadConfirm = "TeacherDashboard.PracticeSetDetail.QuestionFlyout.AddMedialModal.UrlUploadConfirm",
  TeacherDashboard_PracticeSetDetail_QuestionFlyout_AddMediaModal_AddImageFromLibrary = "TeacherDashboard.PracticeSetDetail.QuestionFlyout.AddMedialModal.AddImageFromLibrary",

  TeacherDashboard_PracticeSetDetail_ImportItems_UploadCSV = "TeacherDashboard.PracticeSetDetail.ImportItems.UploadCSV",
  TeacherDashboard_PracticeSetDetail_ImportItems_SaveItems = "TeacherDashboard.PracticeSetDetail.ImportItems.SaveItems",
  TeacherDashboard_PracticeSetDetail_CreateSessionFlyout_CreateCreateSessionIntent = "TeacherDashboard.PracticeSetDetail.CreateSessionFlyout.CreateCreateSessionIntent",
  // This old event doesn't follow the naming conventions introduced later, but indicates a session was started
  TeacherDashboard_PracticeSetDetail_InfoCard_StartSession = "TeacherDashboard.PracticeSetDetail.InfoCard.StartSession",

  // TODO: deprecated by the new flyout UI. Remove once flyout is released; update Amplitude
  // TeacherDashboard.SessionCreate
  TeacherDashboard_SessionCreate_CreateSession = "TeacherDashboard.SessionCreate.CreateSession",

  // TeacherDashboard.SessionCreateFlyout
  TeacherDashboard_SessionCreateFlyout_CreateSession = "TeacherDashboard.SessionCreateFlyout.CreateSession",

  // TeacherDashboard.ClassroomStudents
  TeacherDashboard_ClassroomStudents_GoogleClassroomModal_Open = "TeacherDashboard.ClassroomStudents.GoogleClassroomModal.Open",
  TeacherDashboard_ClassroomStudents_GoogleClassroomModal_Utilize = "TeacherDashboard.ClassroomStudents.GoogleClassroomModal.Utilize",

  // TeacherDashboard.ClassroomArchive
  TeacherDashboard_ClassroomArchive_ClassroomCard_RestoreClassroom = "TeacherDashboard.ClassroomArchive.ClassroomCard.Restore",

  // TeacherDashboard.ClassroomList
  TeacherDashboard_ClassroomList_ClassroomDetail_ArchiveClassroomIntent = "TeacherDashboard.ClassroomList.ClassroomDetail.ArchiveClassroomIntent",
  TeacherDashboard_ClassroomList_ClassroomDetail_ArchiveClassroomConfirm = "TeacherDashboard.ClassroomList.ClassroomDetail.ArchiveClassroomConfirm",
  TeacherDashboard_ClassroomList_ClassroomDetail_DeleteClassroomIntent = "TeacherDashboard.ClassroomList.ClassroomDetail.DeleteClassroomIntent",
  TeacherDashboard_ClassroomList_ClassroomDetail_DeleteClassroomConfirm = "TeacherDashboard.ClassroomList.ClassroomDetail.DeleteClassroomConfirm",
  TeacherDashboard_ClassroomList_ClassroomDetail_EditClassroomIntent = "TeacherDashboard.ClassroomList.ClassroomDetail.EditClassroomIntent",
  TeacherDashboard_ClassroomList_ClassroomDetail_EditClassroomConfirm = "TeacherDashboard.ClassroomList.ClassroomDetail.EditClassroomConfirm",
  TeacherDashboard_ClassroomList_Common_CreateClassroomIntent = "TeacherDashboard.ClassroomList.Common.CreateClassroomIntent",
  TeacherDashboard_ClassroomList_Common_CreateClassroomConfirm = "TeacherDashboard.ClassroomList.Common.CreateClassroomConfirm",
  TeacherDashboard_ClassroomList_Common_LiveBanner_JoinSession = "TeacherDashboard.ClassroomList.Common.LiveBanner.JoinSession",
  TeacherDashboard_ClassroomList_AssignmentList_CreateAssignmentIntent = "TeacherDashboard.ClassroomList.AssignmentList.CreateAssignmentIntent",
  TeacherDashboard_ClassroomList_AssignmentList_CreateAssignmentConfirm = "TeacherDashboard.ClassroomList.AssignmentList.CreateAssignmentConfirm",

  // TeacherDashboard.ClassroomDetail
  TeacherDashboard_ClassroomDetail_Common_ArchiveClassroomIntent = "TeacherDashboard.ClassroomDetail.Common.ArchiveClassroomIntent",
  TeacherDashboard_ClassroomDetail_Common_ArchiveClassroomConfirm = "TeacherDashboard.ClassroomDetail.Common.ArchiveClassroomConfirm",
  TeacherDashboard_ClassroomDetail_Common_DeleteClassroomIntent = "TeacherDashboard.ClassroomDetail.Common.DeleteClassroomIntent",
  TeacherDashboard_ClassroomDetail_Common_DeleteClassroomConfirm = "TeacherDashboard.ClassroomDetail.Common.DeleteClassroomConfirm",
  TeacherDashboard_ClassroomDetail_Common_EditClassroomIntent = "TeacherDashboard.ClassroomDetail.Common.EditClassroomIntent",
  TeacherDashboard_ClassroomDetail_Common_EditClassroomConfirm = "TeacherDashboard.ClassroomDetail.Common.EditClassroomConfirm",
  TeacherDashboard_ClassroomDetail_StudentsTab_AddByEmailIntent = "TeacherDashboard.ClassroomDetail.StudentsTab.AddByEmailIntent",
  TeacherDashboard_ClassroomDetail_StudentsTab_AddByEmailConfirm = "TeacherDashboard.ClassroomDetail.StudentsTab.AddByEmailConfirm",
  TeacherDashboard_ClassroomDetail_StudentsTab_AddByLinkIntent = "TeacherDashboard.ClassroomDetail.StudentsTab.AddByLinkIntent",
  TeacherDashboard_ClassroomDetail_StudentsTab_ApproveStudent = "TeacherDashboard.ClassroomDetail.StudentsTab.ApproveStudent",
  TeacherDashboard_ClassroomDetail_StudentsTab_ApproveAllStudents = "TeacherDashboard.ClassroomDetail.StudentsTab.ApproveAllStudents",
  TeacherDashboard_ClassroomDetail_StudentsTab_RejectAllStudentsIntent = "TeacherDashboard.ClassroomDetail.StudentsTab.RejectAllStudentsIntent",
  TeacherDashboard_ClassroomDetail_StudentsTab_RejectAllStudentsConfirm = "TeacherDashboard.ClassroomDetail.StudentsTab.RejectAllStudentsConfirm",
  TeacherDashboard_ClassroomDetail_StudentsTab_RejectStudentIntent = "TeacherDashboard.ClassroomDetail.StudentsTab.RejectStudentIntent",
  TeacherDashboard_ClassroomDetail_StudentsTab_RejectStudentConfirm = "TeacherDashboard.ClassroomDetail.StudentsTab.RejectStudentConfirm",
  TeacherDashboard_ClassroomDetail_StudentsTab_AddByGClassroomIntent = "TeacherDashboard.ClassroomDetail.StudentsTab.AddByGClassroomIntent",
  TeacherDashboard_ClassroomDetail_StudentsTab_AddByGClassroomAuthorize = "TeacherDashboard.ClassroomDetail.StudentsTab.AddByGClassroomAuthorize",
  TeacherDashboard_ClassroomDetail_StudentsTab_AddByGClassroomSelectClassroom = "TeacherDashboard.ClassroomDetail.StudentsTab.AddByGClassroomSelectClassroom",
  TeacherDashboard_ClassroomDetail_StudentsTab_AddByGClassroomConfirmStudents = "TeacherDashboard.ClassroomDetail.StudentsTab.AddByGClassroomConfirmStudents",
  TeacherDashboard_ClassroomDetail_StudentsTab_ConnectGClassroom = "TeacherDashboard.ClassroomDetail.StudentsTab.ConnectGClassroom",
  TeacherDashboard_ClassroomDetail_StudentsTab_ConnectGClassroomAuthorize = "TeacherDashboard.ClassroomDetail.StudentsTab.ConnectGClassroomAuthorize",
  TeacherDashboard_ClassroomDetail_StudentsTab_EditStudentIntent = "TeacherDashboard.ClassroomDetail.StudentsTab.EditStudentIntent",
  TeacherDashboard_ClassroomDetail_StudentsTab_EditStudentConfirm = "TeacherDashboard.ClassroomDetail.StudentsTab.EditStudentConfirm",
  TeacherDashboard_ClassroomDetail_StudentsTab_RemoveStudentConfirm = "TeacherDashboard.ClassroomDetail.StudentsTab.RemoveStudentConfirm",
  TeacherDashboard_ClassroomDetail_StudentsTab_EditStudentGroupsIntent = "TeacherDashboard.ClassroomDetail.StudentsTab.EditStudentGroupsIntent",
  TeacherDashboard_ClassroomDetail_StudentsTab_EditStudentGroupsConfirm = "TeacherDashboard.ClassroomDetail.StudentsTab.EditStudentGroupsConfirm",
  TeacherDashboard_ClassroomDetail_AssignmentsTab_CreateAssignmentIntent = "TeacherDashboard.ClassroomDetail.AssignmentsTab.CreateAssignmentIntent",
  TeacherDashboard_ClassroomDetail_AssignmentsTab_CreateAssignmentConfirm = "TeacherDashboard.ClassroomDetail.AssignmentsTab.CreateAssignmentConfirm",
  TeacherDashboard_ClassroomDetail_AssignmentsTab_AssignToGClassroomAuthorize = "TeacherDashboard.ClassroomDetail.AssignmentsTab.AssignToGClassroomAuthorize",
  TeacherDashboard_ClassroomDetail_AssignmentsTab_EditAssignmentIntent = "TeacherDashboard.ClassroomDetail.AssignmentsTab.EditAssignmentIntent",
  TeacherDashboard_ClassroomDetail_AssignmentsTab_EditAssignmentConfirm = "TeacherDashboard.ClassroomDetail.AssignmentsTab.EditAssignmentConfirm",
  TeacherDashboard_ClassroomDetail_AssignmentsTab_DeleteAssignmentIntent = "TeacherDashboard.ClassroomDetail.AssignmentsTab.DeleteAssignmentIntent",
  TeacherDashboard_ClassroomDetail_AssignmentsTab_DeleteAssignmentConfirm = "TeacherDashboard.ClassroomDetail.AssignmentsTab.DeleteAssignmentConfirm",
  TeacherDashboard_ClassroomDetail_AssignmentsTab_ViewMoreAssignments = "TeacherDashboard.ClassroomDetail.AssignmentsTab.ViewMoreAssignments",
  TeacherDashboard_ClassroomDetail_AssignmentDetail_EditAssignmentIntent = "TeacherDashboard.ClassroomDetail.AssignmentDetail.EditAssignmentIntent",
  TeacherDashboard_ClassroomDetail_AssignmentDetail_EditAssignmentConfirm = "TeacherDashboard.ClassroomDetail.AssignmentDetail.EditAssignmentConfirm",
  TeacherDashboard_ClassroomDetail_AssignmentDetail_DeleteAssignmentIntent = "TeacherDashboard.ClassroomDetail.AssignmentDetail.DeleteAssignmentIntent",
  TeacherDashboard_ClassroomDetail_AssignmentDetail_DeleteAssignmentConfirm = "TeacherDashboard.ClassroomDetail.AssignmentDetail.DeleteAssignmentConfirm",
  TeacherDashboard_ClassroomDetail_AssignmentStudentDetail_EditAssignmentIntent = "TeacherDashboard.ClassroomDetail.AssignmentStudentDetail.EditAssignmentIntent",
  TeacherDashboard_ClassroomDetail_AssignmentStudentDetail_EditAssignmentConfirm = "TeacherDashboard.ClassroomDetail.AssignmentStudentDetail.EditAssignmentConfirm",
  TeacherDashboard_ClassroomDetail_AssignmentStudentDetail_DeleteAssignmentIntent = "TeacherDashboard.ClassroomDetail.AssignmentStudentDetail.DeleteAssignmentIntent",
  TeacherDashboard_ClassroomDetail_AssignmentStudentDetail_DeleteAssignmentConfirm = "TeacherDashboard.ClassroomDetail.AssignmentStudentDetail.DeleteAssignmentConfirm",
  TeacherDashboard_ClassroomDetail_StudentDetail_AssignmentsTab_ViewMoreAssignments = "TeacherDashboard.ClassroomDetail.StudentDetail.AssignmentsTab.ViewMoreAssignments",
  TeacherDashboard_ClassroomDetail_Common_LiveBanner_JoinSession = "TeacherDashboard.ClassroomDetail.Common.LiveBanner.JoinSession",
  TeacherDashboard_ClassroomDetail_TeachersTab_AddByEmailIntent = "TeacherDashboard.ClassroomDetail.TeacherTab.AddByEmailIntent",
  TeacherDashboard_ClassroomDetail_TeachersTab_AddByEmailConfirm = "TeacherDashboard.ClassroomDetail.TeachersTab.AddByEmailConfirm",
  TeacherDashboard_ClassroomDetail_TeachersTab_EditTeacherIntent = "TeacherDashboard.ClassroomDetail.TeachersTab.EditTeacherIntent",
  TeacherDashboard_ClassroomDetail_TeachersTab_EditTeacherConfirm = "TeacherDashboard.ClassroomDetail.TeachersTab.EditTeacherConfirm",
  TeacherDashboard_ClassroomDetail_TeachersTab_RemoveTeacherConfirm = "TeacherDashboard.ClassroomDetail.TeachersTab.RemoveTeacherConfirm",

  // TeacherDashboard.Explore
  TeacherDashboard_Explore_Signup_Complete = "TeacherDashboard.Explore.Signup.Complete",
  TeacherDashboard_Explore_Signup_Intent = "TeacherDashboard.Explore.Signup.Intent",

  // TeacherDashboard.PublicLibrary
  TeacherDashboard_PublicLibrary_Root_Search = "TeacherDashboard.PublicLibrary.Root.Search",
  TeacherDashboard_PublicLibrary_Root_ChangePage = "TeacherDashboard.PublicLibrary.Root.ChangePage",
  TeacherDashboard_PublicLibrary_Root_ClickStandardsBanner = "TeacherDashboard.PublicLibrary.Root.ClickStandardsBanner",
  TeacherDashboard_PublicLibrary_Root_DismissStandardsBanner = "TeacherDashboard.PublicLibrary.Root.DismissStandardsBanner",
  TeacherDashboard_PublicLibrary_Root_ChangeSubjects = "TeacherDashboard.PublicLibrary.Root.ChangeSubjects",
  TeacherDashboard_PublicLibrary_Root_ClickCreatePracticeSet = "TeacherDashboard.PublicLibrary.Root.ClickCreatePracticeSet",
  TeacherDashboard_PublicLibrary_PracticeSetDetail_CopyPracticeSet = "TeacherDashboard.PublicLibrary.PracticeSetDetail.CopyPracticeSet",
  TeacherDashboard_PublicLibrary_PracticeSetDetail_CopyItemToSetIntent = "TeacherDashboard.PublicLibrary.PracticeSetDetail.CopyItemToSetIntent",
  TeacherDashboard_PublicLibrary_PracticeSetDetail_CopyItemToSetConfirm = "TeacherDashboard.PublicLibrary.PracticeSetDetail.CopyItemToSetConfirm",
  TeacherDashboard_PublicLibrary_PracticeSetDetail_StartSession = "TeacherDashboard.PublicLibrary.PracticeSetDetail.StartSession",
  TeacherDashboard_PublicLibrary_PracticeSetDetail_StartSessionIntent = "TeacherDashboard.PublicLibrary.PracticeSetDetail.StartSessionIntent",
  TeacherDashboard_PublicLibrary_PracticeSetDetail_CreateAssignmentIntent = "TeacherDashboard.PublicLibrary.PracticeSetDetail.CreateAssignmentIntent",
  TeacherDashboard_PublicLibrary_PracticeSetDetail_CreateAssignmentConfirm = "TeacherDashboard.PublicLibrary.PracticeSetDetail.CreateAssignmentConfirm",
  TeacherDashboard_PublicLibrary_PracticeSetDetail_RatePracticeSet = "TeacherDashboard.PublicLibrary.PracticeSetDetail.RatePracticeSet",

  // TeacherDashboard.MyLibrary
  TeacherDashboard_MyLibrary_PracticeSetFlyout_CreatePracticeSetIntent = "TeacherDashboard.MyLibrary.PracticeSetFlyout.CreatePracticeSetIntent",
  TeacherDashboard_MyLibrary_PracticeSetFlyout_CreatePracticeSetConfirm = "TeacherDashboard.MyLibrary.PracticeSetFlyout.CreatePracticeSetConfirm",
  TeacherDashboard_MyLibrary_FolderActions_CreateFolderIntent = "TeacherDashboard.MyLibrary.FolderActions.CreateFolderIntent",
  TeacherDashboard_MyLibrary_FolderActions_CreateFolderConfirm = "TeacherDashboard.MyLibrary.FolderActions.CreateFolderConfirm",
  TeacherDashboard_MyLibrary_FolderActions_EditFolderIntent = "TeacherDashboard.MyLibrary.FolderActions.EditFolderIntent",
  TeacherDashboard_MyLibrary_FolderActions_EditFolderConfirm = "TeacherDashboard.MyLibrary.FolderActions.EditFolderConfirm",
  TeacherDashboard_MyLibrary_FolderActions_DeleteFolderIntent = "TeacherDashboard.MyLibrary.FolderActions.DeleteFolderIntent",
  TeacherDashboard_MyLibrary_FolderActions_DeleteFolderConfirm = "TeacherDashboard.MyLibrary.FolderActions.DeleteFolderConfirm",
  TeacherDashboard_MyLibrary_PracticeSetActions_DeletePracticeSetIntent = "TeacherDashboard.MyLibrary.PracticeSetActions.DeletePracticeSetIntent",
  TeacherDashboard_MyLibrary_PracticeSetActions_DeletePracticeSetConfirm = "TeacherDashboard.MyLibrary.PracticeSetActions.DeletePracticeSetConfirm",
  TeacherDashboard_MyLibrary_PracticeSetActions_MovePracticeSetIntent = "TeacherDashboard.MyLibrary.PracticeSetActions.MovePracticeSetIntent",
  TeacherDashboard_MyLibrary_PracticeSetActions_MovePracticeSetConfirm = "TeacherDashboard.MyLibrary.PracticeSetActions.MovePracticeSetConfirm",
  TeacherDashboard_MyLibrary_PracticeSetActions_DuplicatePracticeSetConfirm = "TeacherDashboard.MyLibrary.PracticeSetActions.DuplicatePracticeSetConfirm",
  TeacherDashboard_MyLibrary_Root_ViewMorePracticeSets = "TeacherDashboard.MyLibrary.Root.ViewMorePracticeSets",
  TeacherDashboard_MyLibrary_Root_ViewMoreFolders = "TeacherDashboard.MyLibrary.Root.ViewMoreFolders",
  TeacherDashboard_MyLibrary_Root_Search = "TeacherDashboard.MyLibrary.Root.Search",

  // TeacherDashboard.MyStaffUsers
  TeacherDashboard_MyStaffUsers_ChangeUserRole = "TeacherDashboard.MyStaffUsers.ChangeUserRole",
  TeacherDashboard_MyStaffUsers_RemoveUser = "TeacherDashboard.MyStaffUsers.RemoveUser",
  TeacherDashboard_MyStaffUsers_AddUsers = "TeacherDashboard.MyStaffUsers.AddUsers",

  // TeacherDashboard.MyAvatar
  TeacherDashboard_MyAvatar_Common_UnsetCategory = "TeacherDashboard.MyAvatar.Common.UnsetCategory",
  TeacherDashboard_MyAvatar_Common_EquipItem = "TeacherDashboard.MyAvatar.Common.EquipItem",
  TeacherDashboard_MyAvatar_Common_SetSkeletonType = "TeacherDashboard.MyAvatar.Common.SetSkeletonType",
  TeacherDashboard_MyAvatar_Common_SetHairColor = "TeacherDashboard.MyAvatar.Common.SetHairColor",
  TeacherDashboard_MyAvatar_Common_SetSkinTone = "TeacherDashboard.MyAvatar.Common.SetSkinTone",
  TeacherDashboard_MyAvatar_ShareModal_DownloadImage = "TeacherDashboard.MyAvatar.ShareModal.DownloadImage",
  TeacherDashboard_MyAvatar_ShareModal_Share = "TeacherDashboard.MyAvatar.ShareModal.Share",

  // TeacherDashboard.TeacherProfile
  TeacherDashboard_TeacherProfile_ShareModal_Share = "TeacherDashboard.TeacherProfile.ShareModal.Share",
  TeacherDashboard_TeacherProfile_FollowUser = "TeacherDashboard.TeacherProfile.FollowUser",
  TeacherDashboard_TeacherProfile_FollowingModal_FollowUser = "TeacherDashboard.TeacherProfile.FollowingModal.FollowUser",

  // StudentDashboard.Nav
  StudentDashboard_Nav_AssignmentFlyout_PlayAssignment = "StudentDashboard.Nav.AssignmentFlyout.PlayAssignment",
  StudentDashboard_Nav_AssignmentFlyout_Grant_Access = "StudentDashboard.Nav.AssignmentFlyout.ThirdPartyAssignmentAuthorize",
  StudentDashboard_Nav_AssignmentFlyout_OpenFlyout = "StudentDashboard.Nav.AssignmentFlyout.OpenFlyout",
  StudentDashboard_Nav_OpenProfileMenu = "StudentDashboard.Nav.OpenProfileMenu",

  // StudentDashboard.Home
  StudentDashboard_Home_LiveBanner_JoinSession = "StudentDashboard.Home.LiveBanner.JoinSession",
  StudentDashboard_Home_JoinRemix = "StudentDashboard.Home.JoinRemix",
  StudentDashboard_Home_PlayAssignment = "StudentDashboard.Home.PlayAssignment",
  StudentDashboard_Home_Grant_Access = "StudentDashboard.Home.ThirdPartyAssignmentAuthorize",
  StudentDashboard_Home_PlayRemix = "StudentDashboard.Home.PlayRemix",
  StudentDashboard_Home_PlayPracticeSet = "StudentDashboard.Home.PlayPracticeSet",
  StudentDashboard_Home_ShufflePracticeSets = "StudentDashboard.Home.ShufflePracticeSets",
  StudentDashboard_Home_ViewMoreAssignments = "StudentDashboard.Home.ViewMoreAssignments",
  StudentDashboard_Home_OpenSeasonMapClick = "StudentDashboard.Home.OpenSeasonMapClick",
  StudentDashboard_Home_MyClassesClick = "StudentDashboard.Home.MyClassesClick",
  StudentDashboard_Home_EditAvatarClick = "StudentDashboard.Home.EditAvatarClick",
  StudentDashboard_Home_SocialEventReaction = "StudentDashboard.Home.SocialEventReaction",
  StudentDashboard_Home_SocialEventXPBoostAwarded = "StudentDashboard.Home.SocialEventXPBoostAwarded",

  // StudentDashboard.MyClasses
  StudentDashboard_MyClasses_PlayAssignment = "StudentDashboard.MyClasses.PlayAssignment",
  StudentDashboatd_MyClasses_GrantAccess = "StudentDashboard.MyClasses.ThirdPartyAssignmentAuthorize",
  StudentDashboard_MyClasses_PlayPracticeSet = "StudentDashboard.MyClasses.PlayPracticeSet",
  StudentDashboard_MyClasses_LiveBanner_JoinSession = "StudentDashboard.MyClasses.LiveBanner.JoinSession",

  // StudentDashboard.SeasonMap
  StudentDashboard_SeasonMap_PlayRemix = "StudentDashboard.SeasonMap.PlayRemix",
  StudentDashboard_SeasonMap_JoinRemix = "StudentDashboard.SeasonMap.JoinRemix",

  // StudentDashboard.Locker
  StudentDashboard_Locker_Common_UnsetCategory = "StudentDashboard.Locker.Common.UnsetCategory",
  StudentDashboard_Locker_Common_EquipItem = "StudentDashboard.Locker.Common.EquipItem",
  StudentDashboard_Locker_Common_SetSkeletonType = "StudentDashboard.Locker.Common.SetSkeletonType",
  StudentDashboard_Locker_Common_SetHairColor = "StudentDashboard.Locker.Common.SetHairColor",
  StudentDashboard_Locker_Common_SetSkinTone = "StudentDashboard.Locker.Common.SetSkinTone",
  StudentDashboard_MyAvatar_ShareModal_DownloadImage = "StudentDashboard.Locker.ShareModal.DownloadImage",

  // HubSpot Specific Events
  HubSpot_AccountCreated = "pe5258344_pp_account_created_v2",
  HubSpot_SignedInWithGoogle = "pe5258344_pp_signed_in_with_google",
  HubSpot_SignedInWithClever = "pe5258344_pp_signed_in_with_clever",
  HubSpot_SignedInWithMicrosoft = "pe5258344_pp_signed_in_with_microsoft",
  HubSpot_CopiedPublicSet = "pe5258344_pp_user_copied_practice_set_from_pu",
  HubSpot_CreatedClassroom = "pe5258344_pp_create_a_classroom",
  HubSpot_ImportClassroom = "pe5258344_pp_import_a_classroom",
  HubSpot_AddedStudentsToClassroom = "pe5258344_pp_teacher_has_added_a_student_to_a",
  HubSpot_CreatedPracticeSet = "pe5258344_pp_create_a_practice_set",
  HubSpot_AssignedPracticeSet = "pe5258344_pp_teacher_has_assigned_a_practice_",
  HubSpot_MyLibraryStartLiveSession = "pe5258344_pp_started_live_session_from_their_library",
  HubSpot_SharedLibraryStartLiveSession = "pe5258344_pp_started_live_session_from_shared_practice_set",
}

export enum AnalyticsPage {
  // Admin Dashboard
  AdminDashboard_Home = "AdminDashboard.Home",
  AdminDashboard_Sessions = "AdminDashboard.Sessions",
  AdminDashboard_Assignments = "AdminDashboard.Assignments",

  // Session
  Session_Join = "Session.Join",
  Session_ClassSession_Teacher = "Session.ClassSession.Teacher",
  Session_ClassSession_Student = "Session.ClassSession.Student",
  Session_IndividualSession_Student = "Session.IndividualSession.Student",
  Session_TeacherPreview_Teacher = "Session.TeacherPreview.Teacher",

  // Teacher Onboard
  TeacherOnboard = "TeacherOnboard",

  // Teacher Dashboard
  TeacherDashboard_Home = "TeacherDashboard.Home",

  // Teacher Dashboard - Classroom(s)
  TeacherDashboard_Classrooms = "TeacherDashboard.Classrooms",
  TeacherDashboard_ClassroomList = "TeacherDashboard.ClassroomList",
  TeacherDashboard_ClassroomArchive = "TeacherDashboard.ClassroomArchive",
  TeacherDashboard_ClassroomDetail = "TeacherDashboard.ClassroomDetail",
  TeacherDashboard_ClassroomDetail_Students = "TeacherDashboard.ClassroomDetail.Students",
  TeacherDashboard_ClassroomDetail_Standards = "TeacherDashboard.ClassroomDetail.Standards",
  TeacherDashboard_ClassroomDetail_Teachers = "TeacherDashboard.ClassroomDetail.Teachers",
  TeacherDashboard_ClassroomDetail_Assignments = "TeacherDashboard.ClassroomDetail.Assignments",
  TeacherDashboard_ClassroomDetail_AssignmentDetail = "TeacherDashboard.ClassroomDetail.AssignmentDetail",
  TeacherDashboard_ClassroomDetail_AssignmentDetail_Questions = "TeacherDashboard.ClassroomDetail.AssignmentDetail.Questions",
  TeacherDashboard_ClassroomDetail_AssignmentDetail_StudentPerformance = "TeacherDashboard.ClassroomDetail.AssignmentDetail.StudentPerformance",
  TeacherDashboard_ClassroomDetail_AssignmentDetail_Standards = "TeacherDashboard.ClassroomDetail.AssignmentDetail.Standards",
  TeacherDashboard_ClassroomDetail_AssignmentStudentDetail = "TeacherDashboard.ClassroomDetail.AssignmentStudentDetail",
  TeacherDashboard_ClassroomDetail_AssignmentStudentDetail_Questions = "TeacherDashboard.ClassroomDetail.AssignmentStudentDetail.Questions",
  TeacherDashboard_ClassroomDetail_AssignmentStudentDetail_Standards = "TeacherDashboard.ClassroomDetail.AssignmentStudentDetail.Standards",
  TeacherDashboard_ClassroomDetail_PracticeSets = "TeacherDashboard.ClassroomDetail.PracticeSets",

  // Teacher Dashboard - Student Detail
  TeacherDashboard_ClassroomDetail_StudentDetail = "TeacherDashboard.ClassroomDetail.StudentDetail",
  TeacherDashboard_ClassroomDetail_StudentDetail_PracticeSets = "TeacherDashboard.ClassroomDetail.StudentDetail.PracticeSets",
  TeacherDashboard_ClassroomDetail_StudentDetail_Assignments = "TeacherDashboard.ClassroomDetail.StudentDetail.Assignments",
  TeacherDashboard_ClassroomDetail_StudentDetail_Standards = "TeacherDashboard.ClassroomDetail.StudentDetail.Standards",

  // Teacher Dashboard - My Library
  TeacherDashboard_MyLibrary_Root = "TeacherDashboard.MyLibrary.Root",
  TeacherDashboard_MyLibrary_Collection = "TeacherDashboard.MyLibrary.Collection",

  // Teacher Dashboard - Public Library
  TeacherDashboard_PublicLibrary_Root = "TeacherDashboard.PublicLibrary.Root",
  TeacherDashboard_PublicLibrary_PracticeSetDetail = "TeacherDashboard.PublicLibrary.PracticeSetDetail",

  // Teacher Dashboard - Practice Set Detail
  TeacherDashboard_PracticeSetDetail = "TeacherDashboard.PracticeSetDetail",
  TeacherDashboard_ClassroomDetail_PracticeSetDetail = "TeacherDashboard.ClassroomDetail.PracticeSetDetail",
  TeacherDashboard_ClassroomDetail_PracticeSetDetail_Questions = "TeacherDashboard.ClassroomDetail.PracticeSetDetail.Questions",
  TeacherDashboard_ClassroomDetail_PracticeSetDetail_Students = "TeacherDashboard.ClassroomDetail.PracticeSetDetail.Students",
  TeacherDashboard_ClassroomDetail_PracticeSetDetail_Standards = "TeacherDashboard.ClassroomDetail.PracticeSetDetail.Standards",
  TeacherDashboard_ClassroomDetail_PracticeSetStudentDetail = "TeacherDashboard.ClassroomDetail.PracticeSetStudentDetail",
  TeacherDashboard_ClassroomDetail_PracticeSetStudentDetail_Questions = "TeacherDashboard.ClassroomDetail.PracticeSetStudentDetail.Questions",
  TeacherDashboard_ClassroomDetail_PracticeSetStudentDetail_Standards = "TeacherDashboard.ClassroomDetail.PracticeSetStudentDetail.Standards",

  // Teacher Dashboard - My Avatar
  TeacherDashboard_MyAvatar = "TeacherDashboard.MyAvatar",

  // Teacher Dashboard - Account
  TeacherDashboard_Account = "TeacherDashboard.Account",
  TeacherDashboard_NotificationSettings = "TeacherDashboard.NotificationSettings",
  TeacherDashboard_ProfileSettings = "TeacherDashboard.ProfileSettings",

  // Teacher Dashboard - Session Create
  TeacherDashboard_SessionCreate = "TeacherDashboard.SessionCreate",

  // Student Dashboard
  StudentDashboard_Home = "StudentDashboard.Home",
  StudentDashboard_MyClasses = "StudentDashboard.MyClasses",
  StudentDashboard_Locker = "StudentDashboard.Locker",
  StudentDashboard_Account = "StudentDashboard.Account",
  StudentDashboard_GettingStarted = "StudentDashboard.GettingStarted",
}

export enum BrowserLogStatus {
  Debug = "debug",
  Info = "info",
  Warn = "warn",
  Error = "error",
}

export enum BrowserLogMessage {
  SessionWebSocketDisconnect = "session_web_socket_disconnect",
  SessionWebSocketReconnect = "session_web_socket_reconnect",
  SessionWebSocketError = "session_web_socket_error",
}

export type GoogleClassroomClassroom = {
  id: string;
  name: string;
  description: string;
  section: string;
  room: string;
  students: Array<{
    family_name: string;
    given_name: string;
    email: string;
  }>;
};

export enum MediaType {
  Audio = "AUDIO",
  Image = "IMAGE",
  Video = "VIDEO",
}

export interface IPracticeSetItemMedia {
  audio_url?: string;
  image_url?: string;
  image_alt_text?: string;
  video_url?: string;
}

export interface IUseRoundActionsResult {
  onChoiceSelect: (choiceId: string, isSelected: boolean) => void;
  onConfirm: () => void;
  onTextResponse: (response: string) => void;
  onActiveTyping: () => void;
  onDrawingVoteSubmit: (group_id: string) => void;
  onUndoDrawStroke: () => void;
  onExtendRoundTime: () => void;
}

//todo maybe move this back into generic RoundVariant component
export interface IRoundVariantProps {
  authUser: IUser;
  secondsElapsed: number;
  secondsLeft?: number;
  showQuestionOnly: boolean;
  student: ISessionUser;
  ws: UseSessionWebSocketResult;
  round_state: SessionRoundState;
  round_group_state: ISessionRoundGroupState;
  isClassSession?: boolean;
}

export type IPeriodicChallenge = {
  xp_threshold: number;
  awarded_xp: number;
  reward_xp: number;
  reward_avatar_item?: IAvatarItem;
  reward_avatar_item_group?: IAvatarItemGroup;
  title: string;
  description: string;
  cover_image_url: string;
};

export type IPeriodicChallenges = {
  daily_challenge: IPeriodicChallenge;
  weekly_challenge: IPeriodicChallenge;
  monthly_challenge: IPeriodicChallenge | null;
};

export type IPracticeSessionsStreak = {
  week_count: number;
  current_week_day_count: number;
};

export type ISubjectCount = {
  subject_id: string;
  subject_name: string;
  count: number;
};

export enum PracticeDataDocumentType {
  PracticeSets = "PRACTICE_SETS",
  PracticeSetItems = "PRACTICE_SET_ITEMS",
}

export enum PracticeDataSortBy {
  Default = "DEFAULT",
  Title = "TITLE",
  CreatedAt = "CREATED_AT",
}

export enum StudentSortBy {
  GivenName = "GIVEN_NAME",
  FamilyName = "FAMILY_NAME",
}

export enum PracticeSetReportingSortBy {
  MostRecent = 0,
  LowToHighAccuracy = 1,
  HighToLowAccuracy = 2,
}

export enum PracticeDataSortDir {
  Asc = "ASC",
  Desc = "DESC",
}

export enum SocialEventType {
  RareItemAcquired = "RARE_ITEM_ACQUIRED",
  CompletedDailyChallenge = "COMPLETED_DAILY_CHALLENGE",
  CompletedWeeklyChallenge = "COMPLETED_WEEKLY_CHALLENGE",
  CompletedMonthlyChallenge = "COMPLETED_MONTHLY_CHALLENGE",
  StudentAddedToClassroom = "STUDENT_ADDED_TO_CLASSROOM",
  TeamWonClassroomSession = "TEAM_WON_CLASSROOM_SESSION",
  CompletedAllDailyRemixes = "COMPLETED_ALL_DAILY_REMIXES",
  LeveledUp = "LEVELED_UP",
  UnlockedAllSeasonLocationItems = "UNLOCKED_ALL_SEASON_LOCATION_ITEMS",
  UnlockedSeasonZone = "UNLOCKED_SEASON_ZONE",
}

export type SocialEvent = {
  id: string;
  user_id: string;
  event_type: SocialEventType;
  given_name: string;
  family_name: string;
  student_nickname: string;
  profile_image_url: string;
  group_name: string;
  avatar_item_name: string;
  season_location_name: string;
  season_zone_name: string;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date;
  is_reacted: boolean;
};

export type SocialEventReaction = {
  id: string;
  user_id: string;
  event_type: SocialEventType;
  given_name: string;
  family_name: string;
  student_nickname: string;
  profile_image_url: string;
  group_name: string;
  avatar_item_name: string;
  season_location_name: string;
  season_zone_name: string;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date;
};

export type UserXPMultiplier = {
  xp_multiplier: number;
  starts_at: Date;
  ends_at: Date;
};

export const CoverImageBGPatternOptions = [
  "ELECTIVE_BUSINESS_FINANCE",
  "ELECTIVE_COOKING_BAKING",
  "ELECTIVE_HEALTH_PE",
  "ELECTIVE_WOODWORKING",
  "ENGLISH_BOOKS_READING",
  "ENGLISH_PUNCTUATION_GRAMMAR",
  "HISTORY",
  "MATH_ABSTRACT",
  "MATH_OPERATION_SYMBOLS",
  "OTHER_CULTURE_BG_PATTERN",
  "OTHER_FUN_PARTY",
  "PERFORMATIVE_ARTS",
  "SCIENCE_BIOLOGY_BG_PATTERN",
  "SCIENCE_CHEMISTRY_PHYSICS",
  "SCIENCE_SPACE",
  "VISUAL_ARTS",
  "WORLD_LANGUAGE",
  "SMART_SET",
  "GS_LOGO",
  "GS_OG",
  "PP_LOGO",
  "PP_OG",
  "TRANSPARENT",
] as const;
export type CoverImageBGPatternType = typeof CoverImageBGPatternOptions[number];

export const RemixBGPatternOptions = ["REMIX"] as const;
export type RemixBGPatternType = typeof RemixBGPatternOptions[number];

export const CoverImageBGColorSchemeOptions = [
  "LIGHT_BLUE",
  "BLUE",
  "LIGHT_PINK",
  "PINK",
  "RED",
  "ORANGE",
  "YELLOW",
  "GREEN",
  "TEAL",
  "PURPLE",
  "DARK_GREEN",
  "DARK_ORANGE",
] as const;
export type CoverImageBGColorSchemeType =
  typeof CoverImageBGColorSchemeOptions[number];

export const RemixBGColorSchemeTypeOptions = [
  "ELECTIVES",
  "ENGLISH",
  "HISTORY",
  "MATHEMATICS",
  "OTHER",
  "REDEMPTION",
  "SCIENCE",
  "VISUAL_AND_PERFORMING_ARTS",
  "WORLD_LANGUAGES",
];
export type RemixBGColorSchemeType =
  typeof RemixBGColorSchemeTypeOptions[number];

export const CoverImageIconOptions = [
  // electives
  "ELECTIVES_BUSINESS_AND_FINANCE",
  "ELECTIVES_COOKING_AND_BAKING",
  "ELECTIVES_HEALTH",
  "ELECTIVES_PHYSICAL_EDUCATION",
  "ELECTIVES_WOODWORKING",

  // english
  "ENGLISH_GRAMMAR",
  "ENGLISH_LITERATURE",
  "ENGLISH_PHONICS",
  "ENGLISH_READING",
  "ENGLISH_SPELLING",
  "ENGLISH_WRITING",

  // history
  "HISTORY_COMMUNITY",
  "HISTORY_ECONOMICS",
  "HISTORY_GEOGRAPHY",
  "HISTORY_GOVERNMENT",
  "HISTORY_US_HISTORY",
  "HISTORY_WORLD_HISTORY",

  // mathematics
  "MATHEMATICS_ALGEBRA",
  "MATHEMATICS_CALCULUS",
  "MATHEMATICS_EXPRESSIONS_AND_EQUATIONS",
  "MATHEMATICS_GEOMETRY",
  "MATHEMATICS_NUMBERS_AND_OPERATIONS",
  "MATHEMATICS_STATISTICS_AND_PROBABILITY",

  // other
  "OTHER_CULTURE",
  "OTHER_GENERAL_FUN",
  "OTHER_PARTY_FUN",
  "OTHER_ROCKET",
  "OTHER_TRIVIA",
  "OTHER_VOCABULARY",

  // science
  "SCIENCE_BIOLOGY",
  "SCIENCE_CHEMISTRY",
  "SCIENCE_EARTH_AND_SPACE",
  "SCIENCE_PHYSICS",
  "SCIENCE_PSYCHOLOGY_AND_SOCIOLOGY",
  "SCIENCE_TECHNOLOGY_AND_COMPUTER_SCIENCE",

  // visual and performing arts
  "VISUAL_AND_PERFORMING_ARTS_DANCE",
  "VISUAL_AND_PERFORMING_ARTS_DRAMA_AND_THEATRE",
  "VISUAL_AND_PERFORMING_ARTS_GRAPHIC_DESIGN",
  "VISUAL_AND_PERFORMING_ARTS_MUSIC",
  "VISUAL_AND_PERFORMING_ARTS_STUDIO_ART",
  "VISUAL_AND_PERFORMING_ARTS_VIDEO_AND_PHOTOGRAPHY",

  // world languages
  "WORLD_LANGUAGES_FRENCH",
  "WORLD_LANGUAGES_GERMAN",
  "WORLD_LANGUAGES_JAPANESE",
  "WORLD_LANGUAGES_MANDARIN_CHINESE",
  "WORLD_LANGUAGES_SIGN_LANGUAGE",
  "WORLD_LANGUAGES_SPANISH",
] as const;
export type CoverImageIconType = typeof CoverImageIconOptions[number];

export const RemixIconOptions = [
  "ELECTIVES_REMIX",
  "ENGLISH_REMIX",
  "HISTORY_REMIX",
  "MATHEMATICS_REMIX",
  "OTHER_REMIX",
  "REDEMPTION_REMIX",
  "SCIENCE_REMIX",
  "VISUAL_AND_PERFORMING_ARTS_REMIX",
  "WORLD_LANAGUAGES_REMIX",
  "DIGITAL_CITIZENSHIP_SVG",
];
export type RemixIconType = typeof RemixIconOptions[number];

export interface IRemix {
  item_count: number;
  maximum_xp: number;
  subject_id: string;
  subject_name: string;
  session_id: string;
  practice_set_id: string;
  practice_set_title: string;
  new_session_permitted: boolean;
  is_session_active: boolean;
  cover_image_icon: RemixIconType;
  cover_image_bg_color_scheme: RemixBGColorSchemeType;
  on_demand_intent: OnDemandIntent;
}

export interface IGroupSmartSetCapability {
  smart_set_type: SmartSetType;
  is_capable: boolean;
  subject_ids: Array<string>;
}

export enum GenerateInstantSetStatus {
  Unknown = "STATUS_UNKNOWN",
  ReadingTopic = "STATUS_READING_TOPIC",
  StartingGeneration = "STATUS_STARTING_GENERATION",
  Complete = "STATUS_COMPLETE",
}

export interface IMyStuffItem {
  avatar_item: IAvatarItem;
  is_seen: boolean;
  is_unlocked: boolean;
  is_equipped: boolean;
}

export type CameraType = {
  x: number;
  y: number;
  z: number;
  zoom: number;
};

export interface IAvatarAnimation {
  name: string;
  loop: boolean;
}

export enum SeasonMapMusic {
  NewInTown = "NewInTown",
  PR2024 = "PR2024",
  QuietOnTheSet = "QuietOnTheSet",
}

export enum SeasonMapSoundEffect {
  PassUpgrade = "bus-pass-upgrade",
  BusTransition = "bus-transition-sound",
  ItemUnlock = "item-unlock-with-ticket",
  ItemRevealLevel1 = "item-reveal-level-1",
  ItemRevealLevel2 = "item-reveal-level-2",
  ItemRevealLevel3 = "item-reveal-level-3",
  ItemRevealLevel4 = "item-reveal-level-4",
  ItemRevealLevel5 = "item-reveal-level-5",
  MonorailTransition = "monorail-transition",
  MonorailPassUpgrade = "monorail-pass-upgrade",
  ShipTransition = "ship-transition",
  ShipPassUpgrade = "ship-pass-upgrade",
  GolfCartTransition = "golf-cart-transition",
  S4ZoneUnlock = "zone-unlock-sfx",
}

export type ExplainMessage =
  | {
      sender: ExplainMessageSender.AI;
      text: string;
    }
  | {
      sender: ExplainMessageSender.User;
      text: ExplainAction;
    };

export enum ExplainAction {
  Explain = "EXPLAIN",
  Detail = "DETAIL",
  Quiz = "QUIZ",
  Finish = "FINISH",
  AnswerA = "ANSWER_A",
  AnswerB = "ANSWER_B",
  AnswerC = "ANSWER_C",
  AnswerD = "ANSWER_D",
}

export enum ExplainMessageSender {
  User = "USER",
  AI = "AI",
}

export enum SocialMediaShareProvider {
  X = "x",
  Facebook = "facebook",
  LinkedIn = "linkedin",
  Navigator = "navigator",
}

export type UserPublicProfileStatistics = {
  library_copy_practice_set_count: number;
  library_copy_teacher_count: number;
  followers_count: number;
  following_count: number;
};

export enum UserRecurringNotificationSettingsFrequency {
  Daily = "NOTIFICATIONS_DAILY",
  Weekly = "NOTIFICATIONS_WEEKLY",
}

export enum UserRecurringNotificationSettingsDeliveryMethod {
  Email = "EMAIL",
}

export type UserRecurringNotificationSettings = {
  id?: string;
  frequency: UserRecurringNotificationSettingsFrequency;
  delivery_method: UserRecurringNotificationSettingsDeliveryMethod;
  notifications_disabled: boolean;
  enable_topic_class_performance_snapshot: boolean;
  enable_topic_open_assignments: boolean;
  enable_topic_new_sets_from_followed: boolean;
  enable_topic_community_impact: boolean;
  enable_topic_quick_updates: boolean;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
};

export interface IUserTrialExtension {
  id: string;
  guid: string;
  extended_to_date?: string;
  extended_by_days?: number;
  expires_at?: string;
  required_organization_id?: string;
  required_domain?: string;
  max_use_count?: number;
  prevent_stacking: boolean;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
}

export enum SearchSuggestionType {
  Subject = "SUBJECT",
  GradeLevel = "GRADE_LEVEL",
  Term = "TERM",
  Standard = "STANDARD",
}

export interface ISearchSuggestion {
  id?: string;
  type: SearchSuggestionType;
  value: string;
}
