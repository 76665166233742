import { BoxProps } from "@chakra-ui/react";
import { Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { XYCoord, useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";

import { ISessionDraggableSortItem } from "links/lib/types";
import { useStudent } from "sessionComponents/contexts/student";

import { DragItemPreview } from "../DragItemPreview";

interface IDraggableLabelProps extends BoxProps {
  label: ISessionDraggableSortItem;
  stopDragging: (labelId: string, zoneId: string) => void;
  startDragging?: (labelId: string, coords: XYCoord) => void;
  dragItemPreviewContent: React.ReactNode;
  onCursorPositionChange?: (coords: XYCoord | null) => void;
}

export const DraggableLabel: React.FC<IDraggableLabelProps> = ({
  children,
  label,
  stopDragging,
  startDragging,
  dragItemPreviewContent,
  onCursorPositionChange,
  ...props
}) => {
  const student = useStudent();
  const [checkForError, setCheckForError] = useState(false);
  const [{ isDragging, initialCursorPosition, cursorPosition }, drag, preview] =
    useDrag(() => ({
      type: "LABEL",
      item: label,
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
        initialCursorPosition: monitor.getInitialClientOffset(),
        cursorPosition: monitor.getClientOffset(),
      }),

      end: (item, monitor) => {
        const didDrop = monitor.didDrop();
        const dropResult: { zoneId?: string; itemId?: string } =
          monitor.getDropResult() || {};
        if (!didDrop || (didDrop && !dropResult.zoneId)) {
          stopDragging(item.id, "0");
        }
      },
      canDrag: !label.is_dragging || label.user_id === student.id,
    }));

  useEffect(() => {
    // hides the default react-dnd html5 drag preview in favor of a custom preview
    preview(getEmptyImage());
  }, [preview]);

  useEffect(() => {
    onCursorPositionChange?.(cursorPosition);
  }, [cursorPosition, onCursorPositionChange]);

  useEffect(() => {
    if (isDragging && startDragging) {
      startDragging(label.id, initialCursorPosition || { x: 0, y: 0 });
    }
  }, [isDragging, label.id, startDragging, initialCursorPosition]);

  // The following 2 useEffects look for and fix a broken state where no one can drag items where a
  // drag event was sent after a drop but the user isn't actually dragging
  // First effect checks for broken state and in timeout sets change of state to trigger a second check
  // If second check still detects broken state, send another drop event to BE to fix BE thinking item is still being dragged
  // #1
  useEffect(() => {
    let timeoutId: NodeJS.Timeout | undefined;
    if (!isDragging && label.is_dragging && label.user_id === student.id) {
      timeoutId = setTimeout(() => {
        setCheckForError(true);
      }, 3000);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isDragging, label.is_dragging, label.user_id, student.id, stopDragging]);
  // #2
  useEffect(() => {
    if (checkForError) {
      if (!isDragging && label.is_dragging && label.user_id === student.id) {
        stopDragging(label.id, label.zone_id || "0");
      }
      setCheckForError(false);
    }
  }, [
    checkForError,
    isDragging,
    label.is_dragging,
    label.user_id,
    label.id,
    student.id,
    label.zone_id,
    stopDragging,
  ]);

  return (
    <>
      <Box ref={drag} {...props}>
        {children}
      </Box>
      {isDragging && (
        <DragItemPreview>{dragItemPreviewContent}</DragItemPreview>
      )}
    </>
  );
};
