import { hoverStyles, pxToRem } from "adminComponents/utils";

export const AdminSliderInput = {
  parts: ["button", "inputGroup", "inputValues", "values"],
  baseStyle: (): Record<string, unknown> => ({
    button: {
      bgColor: "transparent",
      borderRadius: pxToRem(8),
      borderWidth: pxToRem(2),
      borderColor: "transparent",
      boxSiding: "border-box",
      color: "primary.dark-gray",
      maxH: pxToRem(32),
      minW: { base: 0 },
      padding: pxToRem(8),
      textStyle: "adminP2",
      _active: {
        bgColor: "primary.golden",
        color: "primary.warm-black",
      },
      _focusVisible: {
        borderColor: "utility.focus",
        boxShadow: "none",
        color: "primary.warm-black",
      },
      _focus: {
        boxShadow: "none",
      },
      _hover: hoverStyles({
        bgColor: "primary.golden-light",
        color: "primary.warm-black",
      }),
    },
    inputGroup: {
      height: "max-content",
      position: "relative",
      pb: pxToRem(8),
      input: {
        w: pxToRem(70),
        _focusVisible: {
          borderColor: "utility.focus",
          borderWidth: pxToRem(2),
        },
      },
      label: {
        color: "primary.dark-gray",
        fontSize: pxToRem(16),
        margin: 0,
        position: "absolute",
        right: pxToRem(10),
        textDecoration: "none",
        textStyle: "adminLinkMedium",
        top: "50%",
        transform: "translateY(calc(-50% - .25rem))",
      },
    },
    inputValues: {
      bgColor: "primary.lightest-gray",
      borderRadius: pxToRem(8),
      justifyContent: "center",
      h: pxToRem(40),
      maxW: pxToRem(345),
      mb: pxToRem(12),
      mt: pxToRem(4),
      w: "100%",
    },
    values: {
      alignItems: "center",
      flexWrap: "wrap",
      h: "max-content",
      justifyContent: "space-between",
      minH: pxToRem(48),
    },
  }),
};
