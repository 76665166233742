import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { useAxios } from "../../hooks/useAxios";

export interface IVisitSeasonLocationArgs {
  location_id: string;
}

export type IVisitSeasonLocationResponse = Record<string, unknown>;

export default function useVisitSeasonLocation(): UseMutationResult<
  IVisitSeasonLocationResponse,
  unknown,
  IVisitSeasonLocationArgs
> {
  const axios = useAxios();
  const queryClient = useQueryClient();

  const visitLocation = async (args: IVisitSeasonLocationArgs) => {
    const { location_id } = args;

    const response = await axios({
      method: "put",
      url: `/v1/season/locations/visit`,
      data: {
        season_location_id: location_id,
      },
    }).then((r) => r.data as IVisitSeasonLocationResponse);

    queryClient.invalidateQueries("seasonMap", { exact: true });

    return response;
  };

  const mutation = useMutation(visitLocation);

  return mutation;
}
