import { Image, VStack, useMultiStyleConfig } from "@chakra-ui/react";
import { debounce } from "lodash";
import React, { useMemo } from "react";

import { Card } from "adminComponents/atoms/Card";
import usePendingSubmit from "links/lib/hooks/usePendingSubmit";

import { Text } from "../Text";

export interface IProps {
  image: string;
  name: string;
  value: string | number;
  id: string;
  isSelected?: boolean;
  handleClick?: (value: string | number) => void;
}

export const AvatarEditingCard: React.FC<IProps> = ({
  image,
  isSelected,
  handleClick,
  value,
  name,
}) => {
  const styles = useMultiStyleConfig("AdminAvatarEditingCard", { isSelected });

  const { isPending, handleSubmit } = usePendingSubmit([isSelected]);

  const onClick = useMemo(
    () =>
      debounce(
        // take dependencies as params, don't want the debounce to get reinstantiated if one changes
        ({ isPending, handleSubmit, handleConfirmClick }) => {
          if (!isPending) {
            handleSubmit();
            handleConfirmClick?.();
          }
        },
        250,
        { leading: true, trailing: false }
      ),
    []
  );

  return (
    <VStack>
      <Card
        as="button"
        sx={styles.card}
        variant="adminCardSelectable"
        borderColor="primary.tan"
        backgroundColor="primary.white"
        isSelected={isSelected}
        onClick={() =>
          onClick({
            isPending,
            handleSubmit,
            handleConfirmClick: () => handleClick?.(value),
          })
        }
        aria-label={name}
        aria-pressed={isSelected}
      >
        <Image src={image} alt={name} />
      </Card>
      {value > 0 && (
        <Text variant="adminP2" noOfLines={1} wordBreak="break-all">
          {name}
        </Text>
      )}
    </VStack>
  );
};
