import { Box, Button } from "@chakra-ui/react";
import React from "react";

export interface IFooterProps {
  content: {
    finishButton: string;
  };
  onFinish: () => void;
}

const Footer: React.FC<IFooterProps> = ({ content, onFinish }) => {
  return (
    <Box w="full" pb={4}>
      <Button type="button" w="full" colorScheme="teal" onClick={onFinish}>
        {content.finishButton}
      </Button>
    </Box>
  );
};

export default Footer;
