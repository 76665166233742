import { Box, VStack } from "@chakra-ui/react";
import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { EmptyCard } from "adminComponents/molecules/EmptyCard";
import {
  INavigationHeaderProps,
  NavigationHeader,
} from "adminComponents/molecules/NavigationHeader";
import { PracticeSetInformationCard } from "adminComponents/molecules/PracticeSetInformationCard";
import { PracticeSetInformationCardPublic } from "adminComponents/molecules/PracticeSetInformationCardPublic";
import PracticeSetQuestionList from "adminComponents/molecules/PracticeSetQuestionList";
import { PracticeSetQuestionListControls } from "adminComponents/molecules/PracticeSetQuestionListControls";
import { PracticeSetQuestionListControlsFixedFooter } from "adminComponents/molecules/PracticeSetQuestionListControlsFixedFooter";
import {
  PracticeSetAddQuestionsFlyout,
  IProps as PracticeSetAddQuestionsFlyoutProps,
} from "adminComponents/organisms/PracticeSetAddQuestionsFlyout";
import {
  PracticeSetDetailFlyout,
  IProps as PracticeSetDetailFlyoutProps,
} from "adminComponents/organisms/PracticeSetDetailFlyout";
import {
  PracticeSetImportQuestionsFlyout,
  IProps as PracticeSetImportQuestionsFlyoutProps,
} from "adminComponents/organisms/PracticeSetImportQuestionsFlyout";
import { isRichTextEmpty } from "adminComponents/organisms/RichTextEditor/util";
import { TemplateWithCenteredHeroOneColumn } from "adminComponents/templates/TemplateWithCenteredHeroOneColumn";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { useAuth } from "links/lib/features/auth";
import { IPracticeSet, IPracticeSetItem, UserRole } from "links/lib/types";

export interface IProps {
  practiceSet?: IPracticeSet;
  personalRating?: number;
  navigationData: INavigationHeaderProps;
  practiceSetDetailData: PracticeSetDetailFlyoutProps;
  practiceSetAddQuestionsData: PracticeSetAddQuestionsFlyoutProps;
  practiceSetImportQuestionsData?: PracticeSetImportQuestionsFlyoutProps;
  practiceSetQuestionsData?: Array<IPracticeSetItem>;
  practiceSetQuestionOrderData: {
    handleSaveOrder: (
      practiceSet: IPracticeSet,
      practiceSetQuestionsData: Array<IPracticeSetItem> | undefined
    ) => void;
    handleStartOrder: () => void;
    handleCancelOrder: () => void;
    isLoading: boolean;
  };
  handlePreviewPracticeSet: () => void;
  handleDeletePracticeSet: () => void;
  handleDuplicatePracticeSet: () => void;
  handleAddQuestion: () => void;
  handleEditPracticeSet: () => void;
  handleEditQuestion: (item: IPracticeSetItem) => void;
  handleDuplicateQuestion: (item: IPracticeSetItem) => void;
  handleDeleteQuestion: (item: IPracticeSetItem) => void;
  handlePreviewQuestion: (item: IPracticeSetItem) => void;
  handleLiveSession?: (practiceSet: IPracticeSet) => void;
  handleCreateAssignment: () => void;
  handleCopyToMyLibrary?: (item: IPracticeSet) => void;
  handleImportQuestions?: MouseEventHandler;
  handleRate?: (rating: number) => void;
  isLoading?: boolean;
  isPublic?: boolean;
  isCopiedToLibrary?: boolean;
  isCopyingToLibrary?: boolean;
  authorName?: string;
  authorCustomUrlCode?: string;
  authorProfileImageUrl?: string;
  authorProfileIsPrivate?: boolean;
  authorIsInternalContentSpecialist?: boolean;
  originalPracticeSet?: IPracticeSet;
  originalAuthorName?: string;
  handleAddQuestionToSet?: (item: IPracticeSetItem) => void;
  showPremiumMarker?: boolean;
}

export const TeacherPracticeSetScreen: React.FC<IProps> = ({
  practiceSet,
  personalRating,
  navigationData,
  practiceSetAddQuestionsData,
  practiceSetDetailData,
  practiceSetQuestionsData,
  practiceSetQuestionOrderData,
  practiceSetImportQuestionsData,
  handlePreviewPracticeSet,
  handleDuplicatePracticeSet,
  handleDeletePracticeSet,
  handleEditPracticeSet,
  handleEditQuestion,
  handleDeleteQuestion,
  handleDuplicateQuestion,
  handlePreviewQuestion,
  handleAddQuestion,
  handleLiveSession,
  handleCreateAssignment,
  handleCopyToMyLibrary,
  handleImportQuestions,
  handleRate,
  isLoading = false,
  isPublic = false,
  isCopiedToLibrary = false,
  authorName,
  authorCustomUrlCode,
  authorProfileImageUrl,
  authorProfileIsPrivate,
  authorIsInternalContentSpecialist,
  originalPracticeSet,
  originalAuthorName,
  isCopyingToLibrary = false,
  handleAddQuestionToSet,
  showPremiumMarker = true,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const { authUser, hasNoPremiumAccess } = useAuth();
  const isContentSpecialist = authUser?.role === UserRole.ContentSpecialist;

  const counters = {
    feedback: 0,
    standards: 0,
  };

  const [questionListData, setQuestionListData] = useState(
    practiceSetQuestionsData
  );

  const [areQuestionsDraggable, setAreQuestionsDraggable] = useState(false);

  practiceSetQuestionsData?.forEach((question) => {
    if (!isRichTextEmpty(question.feedback)) {
      counters.feedback++;
    }
    if (Array.isArray(question.standards) && question.standards.length) {
      counters.standards++;
    }
  });

  const sortedQuestions: IPracticeSetItem[] = useMemo(() => {
    const sortedQuestions = questionListData ? questionListData : [];
    sortedQuestions.sort((a, b) => {
      return a.order <= b.order ? -1 : 1;
    });
    return sortedQuestions;
  }, [questionListData]);

  // if not reordering, update list with any item changes
  const { isLoading: isReorderLoading } = practiceSetQuestionOrderData;
  useEffect(() => {
    if (!areQuestionsDraggable) {
      setQuestionListData(practiceSetQuestionsData);
    }
  }, [practiceSetQuestionsData, areQuestionsDraggable]);

  const {
    handleSaveOrder,
    handleStartOrder,
    handleCancelOrder: _handleCancelOrder,
  } = practiceSetQuestionOrderData;

  const onOrderChange = useCallback((data) => {
    setQuestionListData(data);
  }, []);

  const handleReorderQuestions = useCallback(
    (data) => {
      setAreQuestionsDraggable(data);
      handleStartOrder();
    },
    [handleStartOrder]
  );

  const handleSaveOrderButtonClick = useCallback(() => {
    if (!practiceSet) return;

    setAreQuestionsDraggable(false);
    handleSaveOrder(practiceSet, questionListData);
  }, [handleSaveOrder, practiceSet, questionListData]);

  const handleCancelOrder = useCallback(() => {
    setAreQuestionsDraggable(false);
    const mutableQuestionData = practiceSetQuestionsData
      ? [...practiceSetQuestionsData]
      : [];
    mutableQuestionData.sort((a, b) => {
      return a.order <= b.order ? -1 : 1;
    });
    setQuestionListData(mutableQuestionData);
    _handleCancelOrder();
  }, [practiceSetQuestionsData, _handleCancelOrder]);

  return (
    <VStack>
      <TemplateWithCenteredHeroOneColumn
        nav={<NavigationHeader {...navigationData} isLoading={isLoading} />}
        heroContent={
          <>
            {practiceSet ? (
              isPublic ? (
                <PracticeSetInformationCardPublic
                  authorName={authorName}
                  authorCustomUrlCode={authorCustomUrlCode}
                  authorProfileImageUrl={authorProfileImageUrl}
                  authorProfileIsPrivate={authorProfileIsPrivate}
                  authorIsInternalContentSpecialist={
                    authorIsInternalContentSpecialist
                  }
                  handleDuplicate={handleCopyToMyLibrary}
                  handlePreview={handlePreviewPracticeSet}
                  handleCreateAssignment={handleCreateAssignment}
                  handleLiveSession={handleLiveSession}
                  handleRate={handleRate}
                  isCopiedToLibrary={isCopiedToLibrary}
                  practiceSet={practiceSet}
                  personalRating={personalRating}
                  isLoading={isCopyingToLibrary}
                  showPremiumMarker={showPremiumMarker}
                  disableButtons={
                    !practiceSetQuestionsData ||
                    practiceSetQuestionsData.length <= 0
                  }
                />
              ) : (
                <PracticeSetInformationCard
                  authUser={authUser}
                  hasNoPremiumAccess={hasNoPremiumAccess}
                  practiceSet={practiceSet}
                  handleEdit={handleEditPracticeSet}
                  allowEdit={
                    (!practiceSet.is_certified && !practiceSet.is_premium) ||
                    isContentSpecialist
                  }
                  handleDuplicate={handleDuplicatePracticeSet}
                  handlePreview={handlePreviewPracticeSet}
                  handleDelete={handleDeletePracticeSet}
                  handleLiveSession={handleLiveSession}
                  handleCreateAssignment={handleCreateAssignment}
                  disableButtons={
                    !practiceSetQuestionsData ||
                    practiceSetQuestionsData.length <= 0
                  }
                  originalAuthorName={originalAuthorName}
                  originalPracticeSet={originalPracticeSet}
                  showPremiumMarker={showPremiumMarker}
                />
              )
            ) : (
              <></>
            )}
          </>
        }
        isHeroShifted
        isLoading={isLoading}
        variant="default"
      >
        {!!practiceSet && (
          <>
            {!!practiceSetQuestionsData?.length && (
              <Box marginBottom={{ base: pxToRem(32), md: pxToRem(42) }}>
                <PracticeSetQuestionListControls
                  questionCount={practiceSetQuestionsData.length}
                  feedbackCount={counters.feedback}
                  standardsCount={counters.standards}
                  handleAddQuestion={handleAddQuestion}
                  handleReorderQuestions={handleReorderQuestions}
                  isReorderLoading={isReorderLoading}
                  showReorderControls={areQuestionsDraggable}
                  handleSaveOrder={handleSaveOrderButtonClick}
                  handleCancelOrder={handleCancelOrder}
                  handleImportQuestions={handleImportQuestions}
                  hideControls={
                    isPublic ||
                    ((practiceSet.is_certified || practiceSet.is_premium) &&
                      !isContentSpecialist)
                  }
                />
              </Box>
            )}
            {!practiceSetQuestionsData?.length && !isPublic ? (
              <EmptyCard
                actions={[
                  {
                    icon: "add",
                    onClick: handleAddQuestion,
                    size: "lg",
                    text: t("emptyPracticeSetCard.cta"),
                    variant: "adminButtonFilled",
                  },
                  {
                    icon: "upload",
                    onClick: handleImportQuestions,
                    size: "lg",
                    text: t("emptyPracticeSetCard.ctaImportQuestions"),
                    variant: "adminButtonFilled",
                  },
                ]}
                text={t("emptyPracticeSetCard.text")}
                title={t("emptyPracticeSetCard.title")}
              />
            ) : (
              <>
                <PracticeSetQuestionList
                  authUser={authUser}
                  isPublic={isPublic}
                  setIsCertified={practiceSet.is_certified}
                  setIsPremium={practiceSet.is_premium}
                  addToSetProhibited={
                    practiceSet.is_how_to_play ||
                    practiceSet.is_digital_citizenship
                  }
                  items={sortedQuestions}
                  handleOrderChange={onOrderChange}
                  reorder={areQuestionsDraggable}
                  handleDeleteQuestion={handleDeleteQuestion}
                  handleEditQuestion={handleEditQuestion}
                  handleDuplicateQuestion={handleDuplicateQuestion}
                  handlePreviewQuestion={handlePreviewQuestion}
                  handleAddQuestionToSet={handleAddQuestionToSet}
                  showPremiumMarker={showPremiumMarker}
                />
                {!isPublic && (
                  <PracticeSetQuestionListControlsFixedFooter
                    questionCount={practiceSetQuestionsData?.length || 0}
                    feedbackCount={counters.feedback}
                    standardsCount={counters.standards}
                    handleAddQuestion={handleAddQuestion}
                    handleImportQuestions={handleImportQuestions}
                    handleReorderQuestions={handleReorderQuestions}
                    isReorderLoading={isReorderLoading}
                    showReorderControls={areQuestionsDraggable}
                    handleSaveOrder={handleSaveOrderButtonClick}
                    handleCancelOrder={handleCancelOrder}
                    hideControls={
                      (practiceSet.is_certified || practiceSet.is_premium) &&
                      !isContentSpecialist
                    }
                  />
                )}
              </>
            )}
          </>
        )}
      </TemplateWithCenteredHeroOneColumn>
      {!!practiceSet && !isPublic && (
        <>
          <PracticeSetAddQuestionsFlyout
            {...practiceSetAddQuestionsData}
            showPremiumMarker={showPremiumMarker}
          />
          <PracticeSetDetailFlyout {...practiceSetDetailData} />
          {practiceSetImportQuestionsData && (
            <PracticeSetImportQuestionsFlyout
              {...practiceSetImportQuestionsData}
            />
          )}
        </>
      )}
    </VStack>
  );
};
