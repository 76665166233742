import { Box } from "@chakra-ui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { animated, config, useSpring } from "react-spring";

import { pxToRem } from "adminComponents/utils";
import { useSession } from "links/lib/contexts/session";
import { Icon } from "sessionComponents/atoms/Icon";
import { PodiumSummaryCard } from "sessionComponents/atoms/PodiumSummaryCard";
import { PodiumSummaryStat } from "sessionComponents/atoms/PodiumSummaryStat";
import { Tooltip } from "sessionComponents/atoms/Tooltip";
import { StudentSessionSoundEffect } from "sessionComponents/types";
import { useSessionAudio } from "sharedComponents/contexts/sessionAudio";

import xpIcon from "../resource/xp_icon.svg";

const AnimatedBox = animated(Box);

interface IPracticeCardProps {
  show: boolean;
  handleSetTotalXP: React.Dispatch<React.SetStateAction<number>>;
  handleComplete: () => void;
  practiceXP: number;
}

export const PracticeCard: React.FC<IPracticeCardProps> = ({
  show,
  handleComplete,
  handleSetTotalXP,
  practiceXP,
}) => {
  const [isCardEntered, setIsCardEntered] = useState<boolean>(false);
  const { play: playAudio } = useSessionAudio();
  const session = useSession();
  const { t } = useTranslation("session", {
    useSuspense: false,
    keyPrefix: "podium.summary",
  });

  const xpMult = session?.xp_multiplier;
  const isXPMult = !!xpMult && xpMult > 1;

  const handleCardEnter = () => {
    setIsCardEntered(true);

    if (practiceXP > 0) {
      handleSetTotalXP((val: number) => val + practiceXP);
      playAudio(
        isXPMult
          ? StudentSessionSoundEffect.PracticeXPBoost
          : StudentSessionSoundEffect.PracticeXP
      );
    } else {
      handleComplete();
    }
  };

  const iconSpring = useSpring({
    from: {
      scale: 0,
    },
    to: {
      scale: isCardEntered ? 1 : 0,
    },
    config: config.wobbly,
  });

  const boostAdjective =
    xpMult == 2 ? t("xpBoostAdjDouble") : t("xpBoostAdjExtra");

  return (
    <PodiumSummaryCard
      show={show}
      handleComplete={handleCardEnter}
      iconUrl={xpIcon}
      delay={250}
    >
      <PodiumSummaryStat
        start={0}
        end={practiceXP}
        show={isCardEntered}
        handleComplete={handleComplete}
        label={t("practiceXP")}
        icon={
          isXPMult ? (
            <Tooltip
              tooltipContent={t("xpBoostTooltip", {
                adjective: boostAdjective,
              })}
            >
              <AnimatedBox style={iconSpring}>
                <Icon
                  icon="xp_boost_solo"
                  boxSize={[pxToRem(24), pxToRem(28)]}
                />
              </AnimatedBox>
            </Tooltip>
          ) : undefined
        }
      />
    </PodiumSummaryCard>
  );
};
