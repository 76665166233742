import { AxiosError } from "axios";
import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { IPracticeSet } from "../../types";

export interface IFetchRecommendedPracticeSetsResponse {
  practice_sets: Array<IPracticeSet>;
}

export interface IUseFetchRecommendedPracticeSetsArgs {
  enabled?: boolean;
  limit?: number;
  periodDays?: number;
}

/**
 * Fetches a list of recommended practice sets sets over the provided period in days.
 * The backend limits to sets that match the requesting user's subjects/grade
 * levels, if set.
 * @returns
 */
export default function useFetchRecommendedPracticeSets(
  args: IUseFetchRecommendedPracticeSetsArgs
): UseQueryResult<IFetchRecommendedPracticeSetsResponse, AxiosError> {
  const { enabled = true, limit = 12, periodDays = 30 } = args;
  const params = { enabled, limit, periodDays };

  const axios = useAxios();

  const res = useQuery<IFetchRecommendedPracticeSetsResponse, AxiosError>(
    ["recommendedPracticeSets", params],
    async (): Promise<IFetchRecommendedPracticeSetsResponse> => {
      return await axios
        .get(`/v1/practice-sets/recommended`, {
          params: {
            limit,
            period_days: periodDays,
          },
        })
        .then((r) => r.data as IFetchRecommendedPracticeSetsResponse);
    },
    { enabled }
  );

  return res;
}
