import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";

import { useAnalytics } from "lib/contexts/analytics";
import {
  AnalyticsEvent,
  ISessionUser,
  SessionRoundState,
  SessionScene,
  WebSocketSession,
} from "links/lib/types";

export interface ISessionAnalyticsContext {
  track: (eventName: AnalyticsEvent, props: Record<string, unknown>) => void;
}

export interface ISessionAnalyticsProviderProps {
  session?: WebSocketSession;
  round_state: SessionRoundState | null;
  scene: SessionScene;
  student?: ISessionUser;
}

export const SessionAnalyticsContext = createContext<ISessionAnalyticsContext>({
  track: () => {
    throw new Error("Inop");
  },
});

export const SessionAnalyticsProvider: React.FC<
  ISessionAnalyticsProviderProps
> = ({ children, student, session, round_state, scene }) => {
  const { trackEvent } = useAnalytics();

  const track = useRef(
    (eventName: AnalyticsEvent, props: Record<string, unknown>) => {
      const rootProps: Record<string, unknown> = {
        session_id: session?.id,
        classroom_id: session?.group_id,
        practice_set_id: session?.practice_set_id,
        scene,
        round_number: round_state?.round_number,
        session_type: session?.session_type,
        on_demand_intent: session?.on_demand_intent,
        multiplier: session?.xp_multiplier,
      };

      if (student) {
        rootProps.group_id = student.session_group_id;
      }

      if (scene === SessionScene.Round || scene === SessionScene.RoundReview) {
        rootProps.variant = round_state?.variant;
      }

      trackEvent(eventName, {
        ...rootProps,
        ...props,
      });
    }
  );

  useEffect(() => {
    track.current(AnalyticsEvent.Session_Common_SceneChange, {
      scene,
    });
  }, [scene]);

  const value = useMemo(() => {
    return {
      track: track.current,
    };
  }, []);

  return (
    <SessionAnalyticsContext.Provider value={value}>
      {children}
    </SessionAnalyticsContext.Provider>
  );
};

export const useSessionAnalytics = (): ISessionAnalyticsContext => {
  return useContext(SessionAnalyticsContext);
};
