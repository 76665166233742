import { Box, Button, HStack, VStack } from "@chakra-ui/react";
import React from "react";

import { pxToRem } from "adminComponents/utils/pxToRem";

import { Icon, IconType } from "../../atoms/Icon";
import { useBreakpoints } from "../../contexts/breakpoints";
import { ConfirmButton } from "../ConfirmButton";

interface IMenuListItemProps {
  icon?: IconType;
  iconColor?: string;
  onClick?: () => void;
  onTouchStart?: () => void;
  confirmedWhenChanged?: Array<unknown>;
}

interface IMenuListControlProps {
  icon: IconType;
  iconColor?: string;
  label: string;
}

const useMenuBreakpoints = (): {
  fontSize: string;
  p: string;
  iconMr: string;
} => {
  const { match: currentBreakpoints } = useBreakpoints();
  const fontSize = pxToRem(currentBreakpoints.fontSize);
  const breakpointPadding = currentBreakpoints.padding;
  const p = pxToRem(breakpointPadding);
  const iconMr = pxToRem(breakpointPadding / 2);

  return {
    fontSize,
    p,
    iconMr,
  };
};

const MenuList: React.FC = ({ children }) => {
  return (
    <VStack w="full" justifyContent="flex-start" alignItems="flex-start">
      {children}
    </VStack>
  );
};

const MenuListItem: React.FC<IMenuListItemProps> = ({
  icon,
  iconColor = "primary.warm-black",
  onClick,
  children,
  onTouchStart,
  confirmedWhenChanged,
}) => {
  const { p, iconMr, fontSize } = useMenuBreakpoints();

  const buttonProps = {
    variant: "unstyled",
    display: "flex",
    onTouchStart: onTouchStart,
    leftIcon: icon ? (
      <Icon icon={icon} color={iconColor} boxSize={fontSize} mr={iconMr} />
    ) : undefined,
    fontSize: fontSize,
    textStyle: "gameText",
    justifyContent: "flex-start",
    w: "full",
    p: p,
    borderRadius: "none",
    h: "auto",
    sx: {
      _hover: {
        bg: "primary.warm-white",
      },
    },
  };

  return (
    <>
      {!!confirmedWhenChanged && (
        <ConfirmButton
          {...buttonProps}
          confirmedWhenChanged={confirmedWhenChanged}
          handleConfirmClick={onClick}
        >
          {children}
        </ConfirmButton>
      )}
      {!confirmedWhenChanged && (
        <Button {...buttonProps} onClick={onClick}>
          {children}
        </Button>
      )}
    </>
  );
};

const MenuListControl: React.FC<IMenuListControlProps> = ({
  icon,
  iconColor = "primary.warm-black",
  children,
  label,
}) => {
  const { p, iconMr, fontSize } = useMenuBreakpoints();

  return (
    <VStack w="full" p={p}>
      <HStack w="full" userSelect="none">
        <Icon icon={icon} color={iconColor} boxSize={fontSize} mr={iconMr} />
        <Box textStyle="gameText" fontSize={fontSize}>
          {label}
        </Box>
      </HStack>
      <Box w="full">{children}</Box>
    </VStack>
  );
};

const MenuListDivider: React.FC = () => {
  const { p: px } = useMenuBreakpoints();

  return (
    <Box w="full" px={px} display="flex" alignItems="center">
      <Box w="full" h={pxToRem(3)} bg="primary.tan" />
    </Box>
  );
};

export { MenuList, MenuListItem, MenuListDivider, MenuListControl };
