import { useState } from "react";

import { IColumnData, ISortState, ITableSort } from ".";

function convertColumnsToSortState(columns: IColumnData[] = []) {
  return columns.reduce(
    (acc: unknown, column: IColumnData, i: number) => {
      return {
        ...(acc as Record<string, unknown>),
        sortDirectionByColumn: {
          ...(acc as ISortState)?.sortDirectionByColumn,
          [i]: {
            isAscending: true,
          },
        },
      };
    },
    { activeColumnIndex: 0, sortDirectionByColumn: {} } as ISortState
  );
}

interface IUseSortableTableResponse {
  activeSort: ISortState;
  handleSortClick: (index: number, isAscending?: boolean) => void;
}

export function useSortableTable(
  columns: IColumnData[],
  handleChangeSort?: (sort: ITableSort) => void
): IUseSortableTableResponse {
  const initialSortByColumn = convertColumnsToSortState(columns);
  const [activeSort, setActiveSort] = useState<ISortState>(initialSortByColumn);

  const handleSortClick = (index: number, isAscending?: boolean) => {
    const updatedActiveSort: ISortState = {
      activeColumnIndex: index,
      sortDirectionByColumn: {
        ...activeSort.sortDirectionByColumn,
        [index]: {
          isAscending:
            activeSort.activeColumnIndex === index &&
            typeof isAscending === "undefined"
              ? !activeSort.sortDirectionByColumn[index].isAscending
              : isAscending ??
                activeSort.sortDirectionByColumn[index].isAscending,
        },
      },
    };
    setActiveSort(updatedActiveSort);
    if (handleChangeSort) {
      const col = updatedActiveSort.sortDirectionByColumn[index];
      handleChangeSort({
        columnIndex: index,
        isAscending: col?.isAscending,
      });
    }
  };

  return {
    activeSort,
    handleSortClick,
  };
}
