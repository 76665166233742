import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { useAxios } from "../../hooks/useAxios";

export interface IDeleteSkillArgs {
  id: string;
}

/**
 * Delete a skill
 * @returns
 */
export default function useDeleteSkill(): UseMutationResult<
  unknown,
  unknown,
  IDeleteSkillArgs
> {
  const axios = useAxios();
  const queryClient = useQueryClient();

  const deleteSkill = async (args: IDeleteSkillArgs) => {
    const { id } = args;

    const response = await axios({
      method: "delete",
      url: `/v1/skills/${id}`,
    }).then((r) => r.data);

    queryClient.invalidateQueries("skills");
    return response;
  };

  const mutation = useMutation(deleteSkill);

  return mutation;
}
