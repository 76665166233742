import { SessionScene } from "links/lib/types";

export const getAreStickersDisabled = (scene?: SessionScene): boolean => {
  let disabled = false;

  if (!scene) {
    return disabled;
  }

  switch (scene) {
    case SessionScene.Lobby:
    case SessionScene.Grouping:
    case SessionScene.OuterGameIntro:
    case SessionScene.PrizeRound:
    case SessionScene.PrizeRoundAwards:
    case SessionScene.Podium:
      disabled = true;
      break;
    default:
      disabled = false;
  }

  return disabled;
};
