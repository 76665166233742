import { pxToRem } from "adminComponents/utils/pxToRem";

export const AdminSeasonChallengeMapCard = {
  baseStyle: {
    container: {
      borderRadius: pxToRem(10),
      marginTop: pxToRem(24),
      overflow: "hidden",
      position: "relative",
      width: "100%",
    },
    openMapButton: {
      position: "absolute",
      left: pxToRem(16),
      top: pxToRem(16),
      zIndex: 1,
    },
    ticketButton: {
      display: "inline-flex",
      position: "absolute",
      paddingX: pxToRem(12),
      paddingY: pxToRem(8),
      right: pxToRem(16),
      top: pxToRem(16),
      zIndex: 1,
    },
    imageContainer: {
      _after: {
        content: "''",
        position: "absolute",
        inset: 0,
        backgroundColor: "primary.warm-black",
        opacity: 0.3,
      },
    },
    image: {
      width: "100%",
    },
  },
};
