import { Box, HStack } from "@chakra-ui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Icon } from "adminComponents/atoms/Icon";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { useAuth } from "links/lib/features/auth";
import { UserRole } from "links/lib/types";

export const StudentModeBanner: React.FC = () => {
  const { authUser: user } = useAuth();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation("translation", {
    keyPrefix: "studentModeBanner",
    useSuspense: false,
  });

  const isInStudentMode =
    user &&
    user.role === UserRole.Student &&
    user.true_role !== UserRole.Student;

  if (isInStudentMode) {
    return (
      <Box aria-expanded={open} id="studentModeBanner">
        {open && (
          <>
            <Box w="100vw" h={pxToRem(30)} />
            <Box
              h={pxToRem(30)}
              left={0}
              top={0}
              position="fixed"
              padding={pxToRem(5)}
              w="100vw"
              bgColor="black"
              display="flex"
              zIndex={99}
              justifyContent="space-evenly"
            >
              <HStack
                onClick={() => setOpen(false)}
                cursor="pointer"
                aria-controls="studentModeBanner"
              >
                <Text fontSize="sm" color="primary.white">
                  {t("labelExplanation")} <b>{t("studentMode")}</b>
                </Text>
                <Icon iconColor="primary.white" icon="expand_circle_up" />
              </HStack>
            </Box>
          </>
        )}
        {!open && (
          <Box
            aria-controls="studentModeBanner"
            h={pxToRem(30)}
            display="flex"
            justifyContent="center"
            cursor="pointer"
            w={pxToRem(150)}
            left="calc(50% - 75px)"
            position="fixed"
            bgColor="black"
            borderBottomRadius="lg"
            onClick={() => setOpen(true)}
            padding={pxToRem(5)}
            zIndex={99}
          >
            <HStack>
              <Text fontSize="sm" color="primary.white">
                {t("studentMode")}
              </Text>
              <Icon
                decorative
                iconColor="primary.white"
                icon="expand_circle_down"
              />
            </HStack>
          </Box>
        )}
      </Box>
    );
  }

  return null;
};
