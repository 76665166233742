import { AxiosError } from "axios";
import { UseMutationResult, useMutation } from "react-query";

import { useAxios } from "links/lib/hooks/useAxios";
import { QuestionType } from "links/lib/types";

import { IPracticeSetItem } from "../../types";

export interface IGeneratePracticeSetItemResponse {
  practice_set_items: Array<IPracticeSetItem>;
}

export interface IUseGenerateItemArgs {
  onError?: (err: AxiosError) => void;
  onSuccess?: (data: IGeneratePracticeSetItemResponse) => void;
  onSettled?: () => void;
}

export interface IGenerateItemArgs {
  topic: string;
  desiredQuestionTypes: Array<QuestionType>;
  questionCount?: number;
  practice_set_id: string;
}

export interface IGenerateItemResult {
  mutation: UseMutationResult<
    IGeneratePracticeSetItemResponse,
    unknown,
    IGenerateItemArgs
  >;
}

export default function useGenerateItem(
  args: IUseGenerateItemArgs
): IGenerateItemResult {
  const axios = useAxios();

  const generateItem = async (args: IGenerateItemArgs) => {
    const { topic, desiredQuestionTypes, questionCount, practice_set_id } =
      args;

    const response = await axios({
      method: "post",
      url: "/v1/practice-sets/items/generate",
      data: {
        topic,
        desired_question_types: desiredQuestionTypes,
        question_count: questionCount || 1,
        practice_set_id,
      },
    }).then((r) => r.data as IGeneratePracticeSetItemResponse);

    return response;
  };

  const mutation = useMutation(generateItem, {
    onError: args?.onError,
    onSuccess: (data: IGeneratePracticeSetItemResponse) => {
      args?.onSuccess?.(data);
    },
    onSettled: args?.onSettled,
  });

  return {
    mutation,
  };
}
