import { Box, Image, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { pxToRem } from "../../../adminComponents/utils/pxToRem";
import { ColorScheme } from "adminComponents";

import { useBreakpoints } from "../../contexts/breakpoints";
import { Tooltip } from "../Tooltip";

interface IAvatarIconProps {
  avatarImgUrl: string;
  avatarIconSize?: string;
  name: string;
  primaryColor?: string | ColorScheme;
  tooltipDisabled?: boolean;
  isSelf?: boolean;
  isGuest?: boolean;
}

export const AvatarIcon: React.FC<IAvatarIconProps> = ({
  avatarImgUrl,
  avatarIconSize,
  name,
  primaryColor,
  tooltipDisabled,
  isSelf,
  isGuest,
}) => {
  const { t } = useTranslation("session", {
    keyPrefix: "avatarLayout",
    useSuspense: false,
  });

  const { match: currentBreakpoints } = useBreakpoints();
  const [showImgUrl, setShowImgUrl] = useState(!!avatarImgUrl);

  const imgSize = avatarIconSize || pxToRem(currentBreakpoints.avatarIconSize);

  const tooltipContent = isSelf
    ? t("selfAvatarLabel", {
        guestNameSuffix: isGuest ? `, ${name}` : "",
      })
    : name;

  return (
    <Tooltip
      tooltipContent={tooltipContent}
      isDisabled={tooltipDisabled}
      placement="top"
    >
      {showImgUrl ? (
        <Image
          boxSize={imgSize}
          alt={tooltipContent}
          src={avatarImgUrl}
          onError={() => setShowImgUrl(false)}
        />
      ) : (
        <Box
          boxSize={imgSize}
          borderRadius="full"
          display="flex"
          justifyContent="center"
          alignItems="center"
          backgroundColor={primaryColor || "primary.medium-gray"}
          transform="scale(0.8)"
        >
          <Text
            display="gameText"
            color="primary.white"
            fontSize={pxToRem(currentBreakpoints.fontSize)}
          >
            {name.charAt(0).toUpperCase()}
          </Text>
        </Box>
      )}
    </Tooltip>
  );
};
