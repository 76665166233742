import React from "react";
import { useTranslation } from "react-i18next";

import {
  ConfirmModal,
  IConfirmModalProps,
} from "adminComponents/molecules/ConfirmModal";

interface IProps {
  isLoading?: IConfirmModalProps["isLoading"];
  isOpen: IConfirmModalProps["isOpen"];
  handleClose: IConfirmModalProps["handleClose"];
}

export const DeleteAssignmentModal: React.FC<IProps> = (props) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  return (
    <ConfirmModal
      {...props}
      buttonCancelLabel={t("common.cancel")}
      buttonOKLabel={t("common.delete")}
      description={t("deleteAssignmentModal.description")}
      mobileButtonsDirection="vertical"
      size="2xl"
      title={t("deleteAssignmentModal.title")}
      variant="adminModalCompactMobile"
    />
  );
};
