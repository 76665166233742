import React from "react";

import { useSessionActions } from "links/lib/contexts/sessionActions";
import { useSessionGameState } from "links/lib/contexts/sessionGameState";
import { useSessionGroups } from "links/lib/contexts/sessionGroups";
import { useStudent } from "sessionComponents/contexts/student";
import { useGroupUsers } from "sessionComponents/hooks/useGroupUsers";
import { StudentTeamUp as TheStudentTeamUp } from "sessionComponents/organisms/StudentTeamUp";
import { getGroupWithWarning } from "sessionComponents/utils/getGroupWithWarning";

// todo consolidate into organism, add fake data for storybook
export const StudentTeamUp: React.FC = () => {
  const sessionGroups = useSessionGroups();
  const gameState = useSessionGameState();
  const { changeGroupName } = useSessionActions();
  const student = useStudent();
  const group = getGroupWithWarning(sessionGroups, student.session_group_id);
  const groupUsers = useGroupUsers(student.session_group_id);

  //todo a crash page for StudentTeamUp
  if (!group) return <></>;

  return (
    <TheStudentTeamUp
      outerGame={gameState?.game_type}
      groupUsers={groupUsers}
      student={student}
      onSwap={changeGroupName}
      group={group}
    />
  );
};
