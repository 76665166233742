import { TFunction } from "react-i18next";
import * as Yup from "yup";

export const folderMoveValidationSchema = (
  t: TFunction<"admin", undefined>
): unknown =>
  Yup.object({
    selectedFolder: Yup.object().required(
      t("folderMoveModal.nameErrorMessage")
    ),
  });
