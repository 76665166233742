import React from "react";
import { useTranslation } from "react-i18next";

import { IconType } from "adminComponents";
import { Tag } from "adminComponents/atoms/Tag";
import { Text } from "adminComponents/atoms/Text";
import { PracticeSetAvailability } from "links/lib/types";

type PrivacyTagOptions = {
  icon: IconType;
  labelKey: string;
  descriptionKey: string;
} | null;

interface IProps {
  availability: PracticeSetAvailability;
}

const getPrivacyTagOptions = (
  availability: PracticeSetAvailability
): PrivacyTagOptions => {
  switch (availability) {
    case PracticeSetAvailability.Public:
      return {
        icon: "lock_open_outlined",
        labelKey: "privacyTag.public",
        descriptionKey: "privacyTag.publicDescription",
      };
    case PracticeSetAvailability.Domain:
      return {
        icon: "lock_open_outlined",
        labelKey: "privacyTag.domain",
        descriptionKey: "privacyTag.domainDescription",
      };
    case PracticeSetAvailability.Private:
      return {
        icon: "lock_outlined",
        labelKey: "privacyTag.private",
        descriptionKey: "privacyTag.privateDescription",
      };
    default:
      return null;
  }
};

export const PrivacyTag: React.FC<IProps> = ({ availability }) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const privacyTagOptions = getPrivacyTagOptions(availability);
  if (privacyTagOptions === null) {
    return null;
  }

  const { icon, labelKey, descriptionKey } = privacyTagOptions;
  return (
    <Tag variant="adminSolid" leftIcon={icon} decorativeIcon>
      <>
        <Text as="span" variant="adminP2Bold">
          {t(labelKey)}
        </Text>{" "}
        ({t(descriptionKey)})
      </>
    </Tag>
  );
};
