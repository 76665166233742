import {
  Box,
  Center,
  HStack,
  Stack,
  StackDirection,
  Switch,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Card } from "adminComponents/atoms/Card";
import { CertifiedText } from "adminComponents/atoms/CertifiedText";
import { Icon } from "adminComponents/atoms/Icon";
import { Modal } from "adminComponents/atoms/Modal";
import { PremiumText } from "adminComponents/atoms/PremiumText";
import { Tag } from "adminComponents/atoms/Tag";
import {
  PracticeSetQuestionCard,
  PracticeSetQuestionCardType,
} from "adminComponents/molecules/PracticeSetQuestionCard";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { IPracticeSetItem } from "links/lib/types";

import { ISessionFormItem } from "..";

export interface ISkipQuestionsModalProps {
  isOpen: boolean;
  onClose: () => void;
  unskippedSessionItems: Array<ISessionFormItem>;
  sessionItems: Array<IPracticeSetItem>;
  toggleSkipItem: (item: IPracticeSetItem) => void;
}

export const SkipQuestionsModal: React.FC<ISkipQuestionsModalProps> = ({
  isOpen,
  onClose,
  unskippedSessionItems,
  sessionItems,
  toggleSkipItem,
}) => {
  const { t } = useTranslation("admin", {
    keyPrefix: "startSession",
    useSuspense: false,
  });
  const [showAnswers, setShowAnswers] = useState(false);
  const smallContentDirectionReverse = useBreakpointValue({
    base: "column-reverse",
    sm: "column-reverse",
    md: "row",
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      showBackButton={false}
      size="full"
      title={t("skippedQuestionsModal.title")}
      headingProps={{
        as: "h2",
        textStyle: "gameHeaderText",
        variant: "adminH3",
      }}
    >
      <HStack>
        <Tag
          borderColor="primary.tan"
          variant="adminOutline"
          tooltip={t("totalQuestionsTooltip")}
        >
          {t("totalQuestions", {
            questions: unskippedSessionItems.length,
            totalQuestions: sessionItems.length,
          })}
        </Tag>
        <Tag borderColor="primary.tan" variant="adminOutline">
          <HStack mt={pxToRem(2)}>
            <Switch
              isChecked={showAnswers}
              onChange={(e) => setShowAnswers(e.target.checked)}
            ></Switch>
            <Box>{t("showAnswers")}</Box>
          </HStack>
        </Tag>
      </HStack>
      <Card
        mt={pxToRem(32)}
        variant="adminCardSmallBorder"
        borderColor="primary.light-gray"
      >
        {sessionItems.map((item, index) => {
          const isSkipped = !unskippedSessionItems.find(
            (unskippedSessionItem) =>
              unskippedSessionItem.practice_set_item.id === item.id
          );
          return (
            <Box key={`practiceSetItem-${index}-${item.guid}`} mb={pxToRem(32)}>
              <PracticeSetQuestionCard
                cardBorderColor="primary.light-gray"
                cardType={PracticeSetQuestionCardType.Answers}
                color={isSkipped ? "primary.dark-gray" : "primary.warm-black"}
                practiceSetItem={item}
                questionNumber={index + 1}
                hideAnswers={!showAnswers}
                topRightContent={
                  <Stack
                    direction={smallContentDirectionReverse as StackDirection}
                  >
                    <Center>
                      {item.is_certified && <CertifiedText type="item" />}
                    </Center>
                    <Center>
                      {item.is_premium && <PremiumText type="item" />}
                    </Center>
                    <Button
                      onClick={() => toggleSkipItem(item)}
                      variant={
                        isSkipped ? "adminButtonFilled" : "adminButtonOutlined"
                      }
                      leftIcon={
                        <Icon
                          icon={
                            isSkipped
                              ? "add_circle_outlined"
                              : "cancel_outlined"
                          }
                        />
                      }
                    >
                      {isSkipped
                        ? t("skippedQuestionsModal.unskipButton")
                        : t("skippedQuestionsModal.skipButton")}
                    </Button>
                  </Stack>
                }
              />
            </Box>
          );
        })}
      </Card>
    </Modal>
  );
};
