import { useMemo } from "react";

import { ISessionUser } from "links/lib/types";
import { useSafeUserArray } from "sharedComponents/hooks/useSafeUserArray";

export const useGroupUsers = (groupId: string): ISessionUser[] => {
  const users = useSafeUserArray();
  const groupUsers = useMemo(() => {
    return users.filter(
      (u) => u.session_group_id === groupId
    ) as ISessionUser[];
  }, [users, groupId]);

  return groupUsers;
};
