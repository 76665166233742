import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { EmptyCard } from "adminComponents/molecules/EmptyCard";
import { StudentAssignmentCard } from "adminComponents/molecules/StudentAssignmentCard";
import { IMyAssignment } from "adminComponents/screens/MyClassesScreen";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { IAssignment, IPendingSession } from "links/lib/types";

export interface IProps {
  myAssignments?: IMyAssignment[];
  hasCompletedAssignments?: boolean;
  handlePlayAssignment: (assignment: IAssignment) => void;
  handleViewCompletedAssignments: () => void;
  pendingSession?: IPendingSession;
}

export const MyClassAssignments: React.FC<IProps> = ({
  myAssignments,
  hasCompletedAssignments,
  handlePlayAssignment,
  handleViewCompletedAssignments,
  pendingSession,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  return (
    <>
      {myAssignments?.length ? (
        myAssignments.map((card) => {
          const { assignment } = card;

          return (
            <Box key={assignment.id} mb={pxToRem(24)}>
              <StudentAssignmentCard
                {...card}
                size="lg"
                headingLevel="h3"
                handlePlayButton={() => handlePlayAssignment(assignment)}
                isLoading={pendingSession?.assignmentId === assignment.id}
                isDisabled={!!pendingSession}
              />
            </Box>
          );
        })
      ) : (
        <EmptyCard title={t("myClassesScreen.emptyAssignments")} />
      )}

      {hasCompletedAssignments && (
        <Flex justifyContent="center" mt={pxToRem(36)}>
          <Button
            variant="adminButtonOutlined"
            size="lg"
            onClick={handleViewCompletedAssignments}
            sx={{ width: { base: "100%", md: "auto" } }}
          >
            {t("myClassesScreen.completedAssignmentsButton")}
          </Button>
        </Flex>
      )}
    </>
  );
};
