import { ComponentStyleConfig } from "@chakra-ui/react";

import { textStyles } from "adminComponents/theme/textStyles";

const {
  adminH1,
  adminH2,
  adminH3,
  adminH3TTCommons,
  adminH4,
  adminH5,
  adminH5Gooper,
  adminH6,
  adminH7,
} = textStyles;

const AdminHeading: ComponentStyleConfig = {
  baseStyle: (props: { [key: string]: string }): Record<string, unknown> => ({
    color: props.color ? props.color : "primary.warm-black",
  }),
  variants: {
    adminH1,
    adminH2,
    adminH3,
    adminH3TTCommons,
    adminH4,
    adminH5,
    adminH5Bold: {
      ...adminH5,
      fontWeight: "700",
    },
    adminH5Gooper,
    adminH6,
    adminH6Bold: {
      ...adminH6,
      fontWeight: "700",
    },
    adminH7,
    adminH7Bold: {
      ...adminH7,
      fontWeight: "700",
    },
  },
};

export { AdminHeading };
