import { textStyles } from "adminComponents/theme/textStyles";
import { pxToRem } from "adminComponents/utils";

export const Table = {
  variants: {
    adminScoreGrid: {
      th: {
        color: "primary.warm-black",
        borderColor: "primary.tan",
        borderBottomWidth: pxToRem(1),
        borderRightWidth: pxToRem(1),
        borderStyle: "solid",
        ...textStyles.adminP2,
        textTransform: "none",
        fontWeight: "medium",
      },
      td: {
        color: "primary.warm-black",
        borderColor: "primary.tan",
        borderBottomWidth: pxToRem(1),
        borderRightWidth: pxToRem(1),
        borderStyle: "solid",
        ...textStyles.adminP2,
      },
      tr: {
        "&:last-child": {
          td: {
            borderBottomWidth: 0,
          },
        },
      },
      tbody: {
        td: {},
        tr: {},
      },
      tfoot: {},
    },
  },
};
