import { Flex } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Card } from "adminComponents/atoms/Card";
import { Divider } from "adminComponents/atoms/Divider";
import { Text } from "adminComponents/atoms/Text";
import {
  ClassroomAnswerResultsMetric,
  IHandleViewStudentResponses,
} from "adminComponents/molecules/ClassroomAnswerResultsMetric";
import { pxToRem } from "adminComponents/utils/pxToRem";
import {
  IPracticeSetItem,
  IPracticeSetSessionItemReport,
  ISessionItemResponse,
  QuestionType,
} from "links/lib/types";
import { RichTextRenderer } from "sharedComponents/atoms/RichTextRenderer";

export interface IProps {
  results: IPracticeSetSessionItemReport;
  practiceSetItem: IPracticeSetItem;
  handleViewStudentResponses: IHandleViewStudentResponses;
  studentsCount?: number;
}

export const ClassroomAnswerResults: React.FC<IProps> = ({
  results,
  practiceSetItem,
  handleViewStudentResponses,
  studentsCount,
}) => {
  const { question_type, total_responses, correct_responses, responses } =
    results;
  const incorrectResponsesCount = total_responses - correct_responses;
  const incorrectPercentage =
    incorrectResponsesCount > 0
      ? (incorrectResponsesCount / total_responses) * 100
      : 0;
  const isMultiChoice =
    question_type === QuestionType.MultipleChoice ||
    question_type === QuestionType.MultipleSelect;
  const isClassify = question_type == QuestionType.Classify;
  const showIncorrectResponses = !isMultiChoice && !isClassify;
  const incorrectResponsesData: ISessionItemResponse[] = responses.filter(
    (r) => !r.is_correct
  );

  const { t } = useTranslation("admin", {
    useSuspense: false,
  });

  // Special case for classify items:
  // We wish to start from the correct category choices, then display stats for each
  // rather than the typical approach for other question types, where the responses
  // themselves are the starting point.
  if (isClassify) {
    return (
      <ClassifyClassroomAnswerResults
        results={results}
        practiceSetItem={practiceSetItem}
        handleViewStudentResponses={handleViewStudentResponses}
        studentsCount={studentsCount}
      />
    );
  }

  return (
    <Flex gap={{ base: pxToRem(30), md: pxToRem(20) }} direction="column">
      {responses.map((response) => {
        const responseData = [response];
        const {
          response_text,
          choice_image_url,
          choice_image_alt_text,
          is_correct,
          count,
        } = response;
        const percentage =
          total_responses > 0 ? (count / total_responses) * 100 : 0;
        const displayResponseText = isMultiChoice || is_correct;

        if (displayResponseText) {
          return (
            <Flex
              key={response.id}
              gap={{ base: pxToRem(8), md: pxToRem(10) }}
              direction="column"
            >
              {!isMultiChoice && !isClassify && (
                <Text variant="adminP2Bold">
                  {t("classroomAssignmentReport.correctResponse")}
                </Text>
              )}
              <ClassroomAnswerResultsMetric
                responses={responseData}
                isCorrect={is_correct}
                responseText={response_text}
                responseImageUrl={choice_image_url}
                responseImageAltText={choice_image_alt_text}
                percentage={percentage}
                studentsCount={count}
                practiceSetItem={practiceSetItem}
                handleViewStudentResponses={handleViewStudentResponses}
              />
            </Flex>
          );
        }
      })}

      {showIncorrectResponses && !!incorrectResponsesData.length && (
        <ClassroomAnswerResultsMetric
          responses={incorrectResponsesData}
          isCorrect={false}
          isIncorrectGroup
          percentage={incorrectPercentage}
          studentsCount={incorrectResponsesCount}
          practiceSetItem={practiceSetItem}
          handleViewStudentResponses={handleViewStudentResponses}
        />
      )}
    </Flex>
  );
};

const ClassifyClassroomAnswerResults: React.FC<IProps> = ({
  results,
  practiceSetItem,
  handleViewStudentResponses,
  studentsCount,
}) => {
  const { responses } = results;

  const { t } = useTranslation("admin", {
    useSuspense: false,
  });

  const classifyData = useMemo(() => {
    const data: {
      [key: string]: {
        responses: ISessionItemResponse[];
        correctResponses: ISessionItemResponse[];
        correctResponseCount: number;
        percentage: number;
      };
    } = {};
    practiceSetItem.classify?.categories.forEach((category) => {
      category.choices.forEach((choice) => {
        const responsesForChoice = responses.filter(
          (r) => r.choice_id === choice.id
        );
        const totalResponseCount = responsesForChoice.reduce(
          (prev, curr) => prev + curr.count,
          0
        );
        const correctResponses = responsesForChoice.filter((r) => r.is_correct);
        const correctResponseCount = correctResponses.reduce(
          (prev, curr) => prev + curr.count,
          0
        );

        const percentageCorrect =
          totalResponseCount > 0
            ? (correctResponseCount / totalResponseCount) * 100
            : 0;
        data[choice.id] = {
          responses: responsesForChoice,
          correctResponses,
          correctResponseCount,
          percentage: percentageCorrect,
        };
      });
    });

    return data;
  }, [practiceSetItem.classify?.categories, responses]);

  return (
    <Flex gap={{ base: pxToRem(30), md: pxToRem(20) }} direction="column">
      {practiceSetItem.classify?.categories.map((category) => {
        return (
          <Flex
            key={category.id}
            gap={{ base: pxToRem(8), md: pxToRem(10) }}
            direction="column"
          >
            <Text as="div" variant="adminP2Bold">
              <RichTextRenderer content={category.text} />
            </Text>
            {category.choices.map((choice) => {
              const choiceData = classifyData[choice.id] || {};
              return (
                <ClassroomAnswerResultsMetric
                  key={choice.id}
                  responses={choiceData.responses}
                  isCorrect={true}
                  choiceId={choice.id}
                  responseText={choice.text}
                  responseImageUrl={choice.image_url}
                  responseImageAltText={choice.image_alt_text}
                  percentage={choiceData.percentage}
                  studentsCount={choiceData.correctResponseCount}
                  practiceSetItem={practiceSetItem}
                  handleViewStudentResponses={handleViewStudentResponses}
                />
              );
            })}
          </Flex>
        );
      })}
      {responses?.length > 0 && (
        <>
          <Divider color="primary.light-gray" />
          <Card
            borderColor="primary.light-gray"
            backgroundColor="primary.light-gray"
            variant="adminCardSolid"
          >
            <Flex justifyContent="space-between">
              <Text as="div" variant="adminP2Bold">
                {t("classroomPracticeSetReport.allStudentResponses")}
              </Text>
              <Button
                variant="adminTextButtonMedium"
                onClick={() =>
                  handleViewStudentResponses(practiceSetItem, responses)
                }
                aria-label={t("classroomPracticeSetReport.allStudentResponses")}
              >
                {t("classroomPracticeSetReport.student", {
                  count: studentsCount || 0,
                })}
              </Button>
            </Flex>
          </Card>
        </>
      )}
    </Flex>
  );
};
