import { IStageCamera, IStageOverlayAsset } from "../types";

export interface IStageOverlayConstructorArgs {
  canvas: HTMLCanvasElement;
  camera: IStageCamera;
}

class StageOverlay {
  camera: IStageCamera;
  canvas: HTMLCanvasElement;
  ctx: CanvasRenderingContext2D;
  assets: Array<IStageOverlayAsset>;
  pixelRatio: number;
  animationFrameRequest: number;

  constructor(args: IStageOverlayConstructorArgs) {
    this.camera = args.camera;
    this.canvas = args.canvas;
    this.assets = [];

    const ctx = args.canvas.getContext("2d");

    if (!ctx) throw new Error("Could not get 2D context");

    this.ctx = ctx;
    this.pixelRatio = window.devicePixelRatio;

    this.animationFrameRequest = requestAnimationFrame(() => this.render());
  }

  setCanvasSize(w: number, h: number): void {
    const { ctx, canvas } = this;

    const ratio = window.devicePixelRatio;
    canvas.width = w * ratio;
    canvas.height = h * ratio;
    canvas.style.width = w + "px";
    canvas.style.height = h + "px";
    ctx.scale(ratio, ratio);

    this.pixelRatio = ratio;
  }

  setCamera(camera: IStageCamera): void {
    this.camera = camera;
  }

  registerAsset(asset: IStageOverlayAsset): void {
    this.assets.push(asset);
  }

  unregisterAsset(asset: IStageOverlayAsset): void {
    this.assets = this.assets.filter((a) => a !== asset);
  }

  dispose(): void {
    cancelAnimationFrame(this.animationFrameRequest);

    return;
  }

  update(): void {
    return;
  }

  render(): void {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

    this.assets.forEach((a) => {
      a.render(this.ctx, this.camera, this.pixelRatio);
    });

    this.animationFrameRequest = requestAnimationFrame(() => this.render());
  }
}

export { StageOverlay };
