import {
  Flex,
  Square,
  useBreakpointValue,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Icon } from "adminComponents/atoms/Icon";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils";
import { IUser } from "links/lib/types";

import { SessionGroupStudent } from "./SessionGroupStudent";

interface SessionGroupProps {
  students: IUser[];
  onSelect?: () => void;
  selected: boolean;
  sessionGroupNumber: number;
  onRemove?: (groupId: string) => void;
  showErrors: boolean;
  sessionGroupId: string;
  alwaysShowStudents?: boolean;
  hideEmptyStudentSlots?: boolean;
}

export const SessionGroup: React.FC<SessionGroupProps> = ({
  students,
  onSelect,
  selected,
  sessionGroupNumber,
  onRemove,
  showErrors,
  sessionGroupId,
  alwaysShowStudents = false,
  hideEmptyStudentSlots = false,
}) => {
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const styles = useMultiStyleConfig("AdminSessionGroupsModal", {
    isMobile,
    selected,
    showErrors,
    onSelect,
  });
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });

  const freeSlots = 3 - students.length;

  return (
    <Flex sx={styles.sessionGroup} onClick={() => onSelect?.()}>
      {onRemove && (
        <Square p={pxToRem(5)}>
          <Square
            p={pxToRem(5)}
            aria-label={t("sessionGroupsModal.removeGroup")}
            borderRadius="xl"
            _hover={{
              bgColor: "primary.white-disabled",
            }}
          >
            <Icon
              icon="close"
              onClick={(e) => {
                e.stopPropagation();
                onRemove(sessionGroupId);
              }}
            />
          </Square>
        </Square>
      )}
      <Square
        p={pxToRem(10)}
        borderLeft={isMobile ? `${pxToRem(2)} solid` : undefined}
        borderColor={selected ? "primary.golden" : "primary.tan"}
      >
        <Text>
          {t("sessionGroupsModal.groupIndex", { index: sessionGroupNumber })}
        </Text>
      </Square>
      {(!isMobile || alwaysShowStudents) && (
        <Flex
          p={pxToRem(10)}
          w="100%"
          justifyContent="flex-end"
          gap={pxToRem(10)}
        >
          {students.map((student) => {
            return <SessionGroupStudent student={student} key={student.id} />;
          })}
          {!hideEmptyStudentSlots && (
            <>
              {Array.from({ length: freeSlots }, (_, i) => 4 - (i + 1))
                .reverse()
                .map((studentNumber) => (
                  <SessionGroupStudent
                    showErrors={showErrors}
                    studentNumber={studentNumber}
                    key={studentNumber}
                  />
                ))}
            </>
          )}
        </Flex>
      )}
    </Flex>
  );
};
