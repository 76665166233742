import { IPracticeSetItem, QuestionType } from "links/lib/types";

const isPremiumType = (questionType: QuestionType): boolean => {
  switch (questionType) {
    case QuestionType.Classify:
    case QuestionType.Diagram:
      return true;
    default:
      return false;
  }
};

export const isPremiumItem = (practiceSetItem: IPracticeSetItem): boolean => {
  // indicates item is a starter item/from a starter set
  if (practiceSetItem.is_certified && !practiceSetItem.is_premium) {
    return false;
  }

  if (isPremiumType(practiceSetItem.question_type)) {
    return true;
  }

  if (practiceSetItem.is_premium) {
    return true;
  }

  if (practiceSetItem.audio_url !== "" || practiceSetItem.video_url !== "") {
    return true;
  }

  return false;
};
