import { Box, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { useGroupShortName } from "links/lib/hooks/useGroupShortName";
import { ISessionGroup } from "links/lib/types";
import { Stage } from "sessionComponents/atoms/Stage";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";

export interface INoTokenPlacementProps {
  group: ISessionGroup;
}

export const NoTokenPlacement: React.FC<INoTokenPlacementProps> = ({
  group,
}) => {
  const { match: currentBreakpoints } = useBreakpoints();
  const groupShortName = useGroupShortName(group);

  const { t } = useTranslation("session", {
    keyPrefix: "bigBoard",
    useSuspense: false,
  });

  return (
    <Box h="full" position="relative" overflow="hidden">
      <Stage showCurtainOnly>
        <Box
          h="100%"
          minH="100%"
          w="100vw"
          minW="100vw"
          p={pxToRem(currentBreakpoints.padding)}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Text
            textStyle="gameText"
            textAlign="center"
            fontSize={pxToRem(currentBreakpoints.fontSize)}
            color="overlay.white"
          >
            {t("groupCantPlaceLabel", { groupShortName })}
          </Text>
        </Box>
      </Stage>
    </Box>
  );
};
