import { Box, SimpleGrid, Square, Text, VStack } from "@chakra-ui/react";
import React, { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { animated, config, useTransition } from "react-spring";
import { useUnmount } from "react-use";
import { clearTimeout, setTimeout } from "timers";

import { pxToRem } from "adminComponents/utils/pxToRem";
import {
  AnalyticsEvent,
  ISessionGroup,
  ISessionUser,
  SessionGameType,
  SessionGroupNamePart,
  UserRole,
} from "links/lib/types";
import { capitalizeFirstLetter } from "links/lib/util";
import { Card } from "sessionComponents/atoms/Card";
import { GroupToken } from "sessionComponents/atoms/GroupToken";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { AvatarLayout } from "sessionComponents/molecules/AvatarLayout";
import { ConfirmButton } from "sessionComponents/molecules/ConfirmButton";
import { LobbyLayout } from "sessionComponents/molecules/LobbyLayout";
import { useDetectOrientation } from "sessionComponents/theme/hooks/useDetectOrientation";
import { StudentSessionSoundEffect } from "sessionComponents/types";
import { getAssetColorScheme } from "sessionComponents/utils/getAssetColorScheme";
import { useSessionAnalytics } from "sharedComponents/contexts/sessionAnalytics";
import { useSessionAudio } from "sharedComponents/contexts/sessionAudio";

export interface IStudentTeamUpProps {
  outerGame?: SessionGameType;
  groupUsers: ISessionUser[];
  onSwap: (part: SessionGroupNamePart) => void;
  group: ISessionGroup;
  student: ISessionUser;
}

const AnimatedBox = animated(Box);

export const StudentTeamUp: React.FC<IStudentTeamUpProps> = ({
  outerGame = SessionGameType.TheBigBoard,
  groupUsers,
  onSwap,
  group,
  student,
}) => {
  const isQuickPlay = outerGame === SessionGameType.QuickPlay;
  const { match: currentBreakpoints } = useBreakpoints();

  const { isPortrait } = useDetectOrientation();
  const { t } = useTranslation("session", {
    keyPrefix: "studentTeamUp",
    useSuspense: false,
  });
  const { track } = useSessionAnalytics();
  const { play: playAudio } = useSessionAudio();

  const students = groupUsers.filter((user) => user.role === UserRole.Student);

  const {
    color_scheme,
    icon_url,
    adjective,
    name_part_user_assignments,
    noun,
  } = group;

  // state to track if name changes are currently pending
  const [isColorPending, setColorPending] = useState(false);
  const [isNounPending, setNounPending] = useState(false);
  const [isAdjectivePending, setAdjectivePending] = useState(false);

  // refs to pending change timeouts
  const colorChangeTimeout = useRef<NodeJS.Timeout | null>(null);
  const adjectiveChangeTimeout = useRef<NodeJS.Timeout | null>(null);
  const nounChangeTimeout = useRef<NodeJS.Timeout | null>(null);
  // the delay between name changes
  const CHANGE_DELAY_MS = 500;

  const changePartTimeout = useRef<NodeJS.Timeout | null>(null);

  // Clear any active timeouts
  useUnmount(() => {
    if (colorChangeTimeout.current) clearTimeout(colorChangeTimeout.current);
    if (nounChangeTimeout.current) clearTimeout(nounChangeTimeout.current);
    if (adjectiveChangeTimeout.current)
      clearTimeout(adjectiveChangeTimeout.current);
    if (changePartTimeout.current) clearTimeout(changePartTimeout.current);
  });

  const userAssignments = useMemo(() => {
    return name_part_user_assignments
      .filter((assignment) => assignment.user_id === student.id)
      .map((assignment) => assignment.name_part);
  }, [name_part_user_assignments, student.id]);

  const memoizedNameElements = useMemo(
    () => [{ adjective, noun }],
    [adjective, noun]
  );
  const memoizedTokenElements = useMemo(
    () => [{ color_scheme, icon_url }],
    [color_scheme, icon_url]
  );

  const colorTransitions = useTransition(memoizedTokenElements, {
    from: { opacity: 0, ty: -40 },
    enter: { opacity: 1, ty: 0 },
    leave: { opacity: 0, ty: 40 },
    config: config.wobbly,
  });

  const nameTransitions = useTransition(memoizedNameElements, {
    from: { opacity: 0, ty: -40 },
    enter: { opacity: 1, ty: 0 },
    leave: { opacity: 0, ty: 40 },
    config: config.wobbly,
  });

  const changePart = (part: SessionGroupNamePart) => {
    switch (part) {
      case SessionGroupNamePart.ColorScheme:
        setColorPending(true);
        colorChangeTimeout.current = setTimeout(
          () => setColorPending(false),
          CHANGE_DELAY_MS
        );
        break;
      case SessionGroupNamePart.Adjective:
        setAdjectivePending(true);
        adjectiveChangeTimeout.current = setTimeout(
          () => setAdjectivePending(false),
          CHANGE_DELAY_MS
        );
        break;
      case SessionGroupNamePart.Noun:
        setNounPending(true);
        nounChangeTimeout.current = setTimeout(
          () => setNounPending(false),
          CHANGE_DELAY_MS
        );
        break;
    }
    onSwap(part);
    playAudio(StudentSessionSoundEffect.Switch);
    track(AnalyticsEvent.Session_StudentGroups_ChangeGroupNamePart, {
      part,
    });
  };

  const handleChangeUserAssignments = () => {
    userAssignments.forEach((namePart, index) => {
      // multiple changes have to happen on an increasing delay, starting at 0 (immediate execution)
      const timeoutDelay = CHANGE_DELAY_MS * index;

      changePartTimeout.current = setTimeout(
        () => changePart(namePart),
        timeoutDelay
      );
    });
  };

  const getConfirmedWhenChangedValues = () => {
    const namePartConfirmItemMap = {
      [SessionGroupNamePart.Adjective]: adjective,
      [SessionGroupNamePart.ColorScheme]: color_scheme,
      [SessionGroupNamePart.Noun]: noun,
    };
    return userAssignments.map((namePart) => namePartConfirmItemMap[namePart]);
  };

  const getIsPending = () => {
    const namePartPendingMap = {
      [SessionGroupNamePart.Adjective]: isAdjectivePending,
      [SessionGroupNamePart.ColorScheme]: isColorPending,
      [SessionGroupNamePart.Noun]: isNounPending,
    };

    return userAssignments.some((namePart) => !!namePartPendingMap[namePart]);
  };

  const getShuffleKeyPrefix = () => {
    const namePartPrefixMap = {
      [SessionGroupNamePart.Adjective]: "shuffleAdjectiveButton",
      [SessionGroupNamePart.ColorScheme]: "shuffleColorButton",
      [SessionGroupNamePart.Noun]: "shuffleNounButton",
    };

    if (userAssignments.length === 3) return "shuffleTeamNameButton";
    if (userAssignments.length === 2) return "shuffleNounButton";
    else return namePartPrefixMap[userAssignments[0]];
  };

  const showChangeTeamNameButton = !!userAssignments.length;

  const actionContent = (
    <VStack w="full" h="full" justifyContent="space-between">
      <Box
        w="full"
        padding={pxToRem(currentBreakpoints.padding)}
        position={isPortrait ? "absolute" : "relative"}
        top={isPortrait ? "10px" : undefined}
        zIndex="10"
      >
        <Card h="100%" useThickBorder>
          <Box h="100%" w="100%">
            <Square size="100%">
              <VStack width="full" spacing="10px">
                <Box
                  position="relative"
                  minWidth="100%"
                  w="100%"
                  h={pxToRem(currentBreakpoints.buttonHeight)}
                  overflow="visible"
                >
                  {colorTransitions(
                    ({ opacity, ty }, { color_scheme, icon_url }) => (
                      <AnimatedBox
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        position="absolute"
                        top="0"
                        left="0"
                        w="full"
                        h="full"
                        style={{
                          opacity,
                          transform: ty.to((v) => `translateY(${v}px)`),
                        }}
                      >
                        <GroupToken
                          colorScheme={getAssetColorScheme(color_scheme)}
                          imageUrl={icon_url}
                          name={`${adjective} ${noun}`}
                          hideTooltip
                        />
                      </AnimatedBox>
                    )
                  )}
                </Box>

                <Box
                  position="relative"
                  minWidth="100%"
                  w="100%"
                  h={pxToRem(
                    currentBreakpoints.studentTeamUpTextAnimationHeight
                  )}
                  overflow="visible"
                >
                  {nameTransitions(({ opacity, ty }, { noun, adjective }) => (
                    <AnimatedBox
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      position="absolute"
                      top="0"
                      left="0"
                      w="full"
                      h="full"
                      style={{
                        opacity,
                        transform: ty.to((v) => `translateY(${v}px)`),
                      }}
                    >
                      <Text
                        textAlign="center"
                        fontSize={pxToRem(currentBreakpoints.fontSize * 1.5)}
                        textStyle="gameDisplay"
                      >
                        {`${capitalizeFirstLetter(
                          adjective
                        )} ${capitalizeFirstLetter(noun)}`}
                      </Text>
                    </AnimatedBox>
                  ))}
                </Box>
              </VStack>
            </Square>
          </Box>
        </Card>
      </Box>

      {showChangeTeamNameButton && (
        <SimpleGrid
          w="100%"
          padding={pxToRem(currentBreakpoints.padding)}
          position={isPortrait ? "absolute" : "relative"}
          bottom={isPortrait ? "10px" : undefined}
          minChildWidth={isPortrait ? "75%" : "25%"}
          spacing={pxToRem(currentBreakpoints.padding / 2)}
          zIndex={10}
        >
          <Box w="100%">
            <ConfirmButton
              confirmedWhenChanged={getConfirmedWhenChangedValues()}
              handleConfirmClick={handleChangeUserAssignments}
              h={pxToRem(currentBreakpoints.buttonHeight)}
              w="100%"
              variant="buttonFilled"
              borderWidth={
                isQuickPlay ? currentBreakpoints.borderWidth : undefined
              }
              borderColor={isQuickPlay ? "primary.white" : undefined}
              disabled={getIsPending()}
            >
              <Text
                fontSize={pxToRem(currentBreakpoints.fontSize)}
                fontStyle="gameText"
              >
                {t(getShuffleKeyPrefix())}
              </Text>
            </ConfirmButton>
          </Box>
        </SimpleGrid>
      )}
    </VStack>
  );

  const avatarStage = <AvatarLayout students={students} selfId={student.id} />;

  return (
    <LobbyLayout
      avatarComponent={avatarStage}
      actionContent={actionContent}
      stageProps={{ outerGame: outerGame }}
    />
  );
};
