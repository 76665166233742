import { Box, Center, Stack, Text } from "@chakra-ui/react";
import React, { useEffect, useMemo } from "react";
import { useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { useTranslation } from "react-i18next";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { useShortName } from "links/lib/hooks/useShortName";
import {
  AnalyticsEvent,
  BigBoardGameState,
  ISessionGroup,
  ISessionUser,
} from "links/lib/types";
import { BigBoardStage } from "sessionComponents/atoms/BigBoardStage";
import { DragItemPreview } from "sessionComponents/atoms/DragItemPreview";
import { GroupToken } from "sessionComponents/atoms/GroupToken";
import { Icon } from "sessionComponents/atoms/Icon";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { AvatarLayout } from "sessionComponents/molecules/AvatarLayout";
import { BigBoard, IBoardSpace } from "sessionComponents/molecules/BigBoard";
import { useDetectOrientation } from "sessionComponents/theme/hooks/useDetectOrientation";
import { StudentSessionSoundEffect } from "sessionComponents/types";
import { getAssetColorScheme } from "sessionComponents/utils/getAssetColorScheme";
import { getFormattedGroupName } from "sessionComponents/utils/getFormattedGroupName";
import { getBigBoardSecondaryContentContainerHeight } from "sessionComponents/utils/layoutUtils";
import { useSessionAnalytics } from "sharedComponents/contexts/sessionAnalytics";
import { useSessionAudio } from "sharedComponents/contexts/sessionAudio";

export interface IStudentBigBoardPlacementProps {
  userId: string;
  placingUser: ISessionUser;
  placingUserGroup: ISessionGroup;
  rows: Array<Array<IBoardSpace>>;
  showSpecialSpaces?: boolean;
  gameState: BigBoardGameState;
  onPlaceToken: (spaceId: number) => void;
}

export const StudentBigBoardPlacement: React.FC<
  IStudentBigBoardPlacementProps
> = ({
  userId,
  placingUser,
  rows,
  placingUserGroup,
  showSpecialSpaces = false,
  gameState,
  onPlaceToken,
}) => {
  const { match: currentBreakpoints } = useBreakpoints();
  const { isPortrait, isLandscape } = useDetectOrientation();
  const { t } = useTranslation("session", {
    keyPrefix: "bigBoard",
    useSuspense: false,
  });
  const { play: playAudio } = useSessionAudio();
  const { track } = useSessionAnalytics();

  const [{ isDragging, didDrop }, dragRef, dragPreview] = useDrag(
    () => ({
      type: "TOKEN",
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        didDrop: monitor.didDrop(),
      }),
    }),
    []
  );

  const {
    the_big_board_state: { round_placed_group_ids },
  } = gameState;
  const isTokenPlaced = round_placed_group_ids[placingUserGroup.id];

  const allowDrop = userId === placingUser.id;
  const placementUserName = useShortName(placingUser.name, false);

  useEffect(() => {
    dragPreview(getEmptyImage());
  }, [dragPreview]);

  useEffect(() => {
    if (isTokenPlaced) {
      playAudio(StudentSessionSoundEffect.TeammateSelected);
    }
  }, [isTokenPlaced, playAudio]);

  useEffect(() => {
    if (didDrop) {
      playAudio(StudentSessionSoundEffect.TokenPlaced);
      track(AnalyticsEvent.Session_BigBoard_PlaceToken, {});
    }
  }, [didDrop, track, playAudio]);

  let bigBoardContainerHeight = "100%";
  if (isPortrait && !allowDrop) {
    bigBoardContainerHeight = "55%";
  }

  const avatarStage = useMemo(
    () => (
      <Box
        w={isLandscape ? "50%" : "100%"}
        h={getBigBoardSecondaryContentContainerHeight(isPortrait)}
      >
        <AvatarLayout
          students={[placingUser]}
          selfId={userId}
          showNametags={false}
          upperBodyZoom
        />

        {!allowDrop && !isLandscape && (
          <Box
            w="100%"
            bottom={pxToRem(currentBreakpoints.headerHeight)}
            position="relative"
          >
            <Center>
              <Text
                textAlign="center"
                fontSize={currentBreakpoints.fontSize}
                color="overlay.white"
                textStyle="gameText"
              >
                {t("teammatePlacingText", { placementUserName })}
              </Text>
            </Center>
          </Box>
        )}
      </Box>
    ),
    [
      isLandscape,
      isPortrait,
      placingUser,
      currentBreakpoints,
      allowDrop,
      placementUserName,
      userId,
      t,
    ]
  );

  const placementInfo = (
    <Box
      bgColor="primary.warm-white"
      w={isLandscape ? "50%" : "100%"}
      bottom={0}
      right={0}
      position="absolute"
      height={pxToRem(currentBreakpoints.headerHeight)}
    >
      <Center h="100%" w="100%">
        {allowDrop && (
          <Stack direction="row" alignContent="center" alignItems="center">
            {!isTokenPlaced && (
              <Box
                backgroundColor="transparent"
                cursor={isDragging ? "grabbing" : "grab"}
                ref={dragRef}
                style={{
                  visibility: isDragging ? "hidden" : "inherit",
                }}
              >
                <GroupToken
                  colorScheme={getAssetColorScheme(
                    placingUserGroup?.color_scheme || ""
                  )}
                  imageUrl={placingUserGroup?.icon_url || ""}
                  name={getFormattedGroupName(placingUserGroup)}
                  hideTooltip
                />
              </Box>
            )}

            <>
              {!isTokenPlaced && (
                <Icon
                  w={pxToRem(currentBreakpoints.fontSize)}
                  h={pxToRem(currentBreakpoints.fontSize)}
                  icon="left_arrow"
                  decorative
                />
              )}
              <Text
                fontSize={pxToRem(currentBreakpoints.fontSize)}
                textStyle="gameTextWeighted"
              >
                {isTokenPlaced
                  ? t("notPlacingLabel")
                  : t("placementInstructions")}
              </Text>
            </>
          </Stack>
        )}
        {!allowDrop && (
          <Text
            fontSize={pxToRem(currentBreakpoints.fontSize)}
            textStyle="gameText"
          >
            {t("teammatePlacingText", { placementUserName })}
          </Text>
        )}
      </Center>
    </Box>
  );

  return (
    <BigBoardStage>
      <Stack h="full" w="full" direction={isPortrait ? "column" : "row"}>
        <Box
          zIndex={isLandscape ? "1" : "unset"}
          w={isLandscape ? "50%" : "100%"}
          h={bigBoardContainerHeight}
        >
          <Box
            h={
              isLandscape
                ? "100%"
                : `calc(100% - ${
                    allowDrop ? 0 : currentBreakpoints.headerHeight
                  }px)`
            }
            w="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            p={pxToRem(currentBreakpoints.padding)}
            pb={
              isLandscape
                ? pxToRem(currentBreakpoints.padding)
                : pxToRem(currentBreakpoints.padding * 5)
            }
          >
            <Center h="full" w="full">
              <BigBoard
                allowDrop={allowDrop}
                rows={rows}
                showSpecialSpaces={showSpecialSpaces}
                isSpinning={false}
                onPlaceToken={onPlaceToken}
              />
            </Center>
          </Box>
        </Box>
        {(isLandscape || !allowDrop) && avatarStage}
      </Stack>

      {isPortrait && !allowDrop ? <></> : placementInfo}
      {allowDrop && placingUserGroup && (
        <DragItemPreview>
          <GroupToken
            colorScheme={getAssetColorScheme(placingUserGroup.color_scheme)}
            imageUrl={placingUserGroup.icon_url}
            name={`${placingUserGroup.adjective} ${placingUserGroup.noun}`}
            hideTooltip
          />
        </DragItemPreview>
      )}
    </BigBoardStage>
  );
};
