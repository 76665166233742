import { hoverStyles, pxToRem } from "adminComponents/utils";

interface BoxProps {
  colorScheme: string;
  colorSchemeHover: string;
  iconColor: string;
  isSVG: boolean;
  isFullSizeIcon: boolean;
}

export const AdminIconButton = {
  parts: ["box", "icon"],
  baseStyle: ({ colorScheme, isSVG }: BoxProps): Record<string, unknown> => ({
    box: {
      backgroundColor: !isSVG ? colorScheme : null,
      borderRadius: !isSVG ? "100%" : null,
      borderColor: !isSVG ? "transparent" : null,
      borderStyle: !isSVG ? "solid" : null,
      borderWidth: !isSVG ? "2px" : null,
      color: colorScheme,
      position: "relative",
      width: isSVG ? "auto" : pxToRem(24),
      height: isSVG ? "auto" : pxToRem(24),
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      outline: "none",
      boxShadow: "none",
      WebkitTapHighlightColor: "transparent",
      transition: "background-color 250ms, border-color 250ms",
      "*": {
        outline: "none",
        boxShadow: "none",
      },
      "> svg:first-of-type": {
        transition: "fill 250ms, stroke 250ms",
      },
      _focusVisible: {
        borderColor: !isSVG ? "utility.focus" : null,
        borderStyle: !isSVG ? "solid" : null,
        borderWidth: !isSVG ? "2px" : null,
        "> svg:first-of-type": {
          fill: "transparent",
          stroke: "utility.focus",
          strokeWidth: "2px",
        },
      },
      _disabled: {
        opacity: 0.4,
        pointerEvents: "none",
      },
    },
    iconContainer: {
      position: "relative",
    },
    icon: {
      position: isSVG ? "absolute" : null,
      top: "50%",
      left: "50%",
      transform: isSVG ? "translateX(-50%) translateY(-50%)" : null,
      width: isSVG ? 5 : pxToRem(14),
      height: isSVG ? 5 : pxToRem(14),
    },
    spinner: {
      position: "absolute",
      top: "50%",
      left: "50%",
      display: "flex",
      transform: "translateX(-50%) translateY(-50%)",
      width: "auto",
      height: "auto",
    },
  }),
  variants: {
    default: ({
      colorScheme,
      colorSchemeHover,
      isSVG,
    }: BoxProps): Record<string, unknown> => ({
      box: {
        "> svg:first-of-type": {
          fill: colorScheme,
          stroke: colorScheme,
        },
        _hover: hoverStyles({
          backgroundColor: !isSVG ? colorSchemeHover : null,
          "> svg:first-of-type": {
            fill: colorSchemeHover,
            stroke: colorSchemeHover,
          },
        }),
        _focusVisible: {
          "> svg:first-of-type": {
            fill: colorScheme,
          },
        },
      },
    }),
    outline: ({
      colorScheme,
      colorSchemeHover,
      isSVG,
    }: BoxProps): Record<string, unknown> => ({
      box: {
        backgroundColor: !isSVG ? "transparent" : null,
        borderColor: !isSVG ? colorScheme : null,
        "> svg:first-of-type": {
          fill: "transparent",
          stroke: colorScheme,
        },
        _hover: hoverStyles({
          borderColor: !isSVG ? colorSchemeHover : null,
          "> svg:first-of-type": {
            stroke: colorSchemeHover,
          },
        }),
        _focusVisible: {
          borderColor: !isSVG ? "utility.focus" : null,
        },
      },
    }),
    ghost: {
      box: {
        "> svg:first-of-type": {
          fill: "transparent",
          stroke: "transparent",
        },
        _hover: hoverStyles({
          "> svg:first-of-type": {
            fill: "utility.badge-grey",
            stroke: "utility.badge-grey",
          },
        }),
        _disabled: {
          fill: "transparent",
          stroke: "transparent",
        },
      },
    },

    adminCustomization: ({
      colorScheme,
      colorSchemeHover,
      isSVG,
    }: BoxProps): Record<string, unknown> => ({
      box: {
        maxW: "max-content",
        "> svg:first-of-type": {
          fill: colorScheme,
          stroke: colorScheme,
        },
        _pressed: {
          "> svg:first-of-type": {
            fill: "primary.white",
            stroke: "utility.link",
          },
          _hover: hoverStyles({
            backgroundColor: !isSVG ? colorSchemeHover : null,
            "> svg:first-of-type": {
              fill: "primary.white",
            },
          }),
        },
        _hover: hoverStyles({
          backgroundColor: !isSVG ? colorSchemeHover : null,
          "> svg:first-of-type": {
            fill: colorScheme,
            stroke: colorSchemeHover,
          },
        }),
        _focusVisible: {
          "> svg:first-of-type": {
            fill: colorScheme,
          },
          _pressed: {
            "> svg:first-of-type": {
              stroke: "utility.focus",
            },
            _hover: hoverStyles({
              "> svg:first-of-type": {
                stroke: colorSchemeHover,
              },
            }),
          },
        },
      },
    }),
    adminNavIconButton: ({
      colorScheme,
      colorSchemeHover,
      isSVG,
      isFullSizeIcon,
    }: BoxProps): Record<string, unknown> => ({
      box: {
        borderRadius: !isSVG ? "100%" : null,
        borderWidth: 0,
        width: isSVG ? "auto" : pxToRem(32),
        height: isSVG ? "auto" : pxToRem(32),
        margin: !isSVG ? pxToRem(4) : 0,

        "> svg:first-of-type": {
          fill: colorScheme,
          stroke: colorScheme,
        },

        _hover: hoverStyles({
          outlineColor: !isSVG ? colorSchemeHover : null,
          "> svg:first-of-type": {
            fill: colorSchemeHover,
            stroke: colorSchemeHover,
          },
        }),
        _focusVisible: {
          borderWidth: 0,
          outlineColor: !isSVG ? "utility.focus" : null,
          "> svg:first-of-type": {
            fill: colorScheme,
          },
        },
        _disabled: {
          borderWidth: 0,
          _focusVisible: {
            outlineColor: "transparent",
          },
          _hover: hoverStyles({
            outlineColor: "transparent",
          }),
        },
      },
      icon: {
        width: isSVG ? 5 : isFullSizeIcon ? "full" : pxToRem(20),
        height: isSVG ? 5 : isFullSizeIcon ? "full" : pxToRem(20),
      },
    }),
    adminLibraryPracticeSetCard: ({
      colorSchemeHover,
    }: BoxProps): Record<string, unknown> => ({
      box: {
        "> svg:first-of-type": {
          fill: "transparent",
          stroke: "transparent",
        },
        _active: hoverStyles({
          "> svg:first-of-type": {
            fill: colorSchemeHover,
            stroke: "utility.badge-grey",
          },
        }),
        _hover: hoverStyles({
          "> svg:first-of-type": {
            fill: colorSchemeHover,
            stroke: "utility.badge-grey",
          },
        }),
        _disabled: {
          fill: "transparent",
          stroke: "transparent",
        },
      },
    }),
    adminPlayButton: ({ colorScheme }: BoxProps): Record<string, unknown> => ({
      box: {
        "> span > svg:first-of-type": {
          fill: colorScheme,
          stroke: colorScheme,
        },
        _disabled: {
          fill: "transparent",
          stroke: "transparent",
        },
      },
    }),
  },
};
