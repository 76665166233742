import { useMemo } from "react";
import { useTimeout } from "react-use";

import {
  quickPlayCarePackageRoundIntroDurationMillis,
  quickPlayCarePackageRoundIntroDurationSeconds,
  roundIntroDurationMillis,
  roundIntroDurationSeconds,
} from "links/lib/constants";
import { useLazyRoundTime } from "sessionComponents/contexts/roundTime";

export interface IUseDelayedRoundIntroArgs {
  disableRoundIntro?: boolean;
  isQuickPlayCarePackageRound?: boolean;
}

export const useDelayedRoundIntro = ({
  disableRoundIntro = false,
  isQuickPlayCarePackageRound = false,
}: IUseDelayedRoundIntroArgs): boolean => {
  const { getSecondsElapsed } = useLazyRoundTime();
  const [showIntroTimeout] = useTimeout(
    isQuickPlayCarePackageRound
      ? quickPlayCarePackageRoundIntroDurationMillis
      : roundIntroDurationMillis
  );
  const introComplete = !showIntroTimeout();
  const actualRoundIntroDurationSeconds = isQuickPlayCarePackageRound
    ? quickPlayCarePackageRoundIntroDurationSeconds
    : roundIntroDurationSeconds;

  const showRoundIntro = useMemo(
    () =>
      (!disableRoundIntro || isQuickPlayCarePackageRound) &&
      // This ensures that refreshes of the page don't replay the intro
      getSecondsElapsed() < actualRoundIntroDurationSeconds &&
      introComplete,
    [
      disableRoundIntro,
      getSecondsElapsed,
      introComplete,
      isQuickPlayCarePackageRound,
      actualRoundIntroDurationSeconds,
    ]
  );

  return showRoundIntro;
};
