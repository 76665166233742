import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import {
  AddStudentDirectionsModal,
  DescriptionCase,
} from "adminComponents/organisms/AddStudentDirectionsModal";
import {
  ClassroomDetailScreen,
  ClassroomDetailTab,
} from "adminComponents/screens/ClassroomDetailScreen";
import { useErrorToast } from "adminComponents/utils/toast";
import { useAnalytics, usePageTrack } from "lib/contexts/analytics";
import { usePageTitle } from "lib/hooks/usePageTitle";
import { useAuth } from "links/lib/features/auth";
import {
  useFetchAverageUserAccuracies,
  useFetchClassroom,
} from "links/lib/features/classrooms";
import { useGetClassroomLiveBanners } from "links/lib/hooks/useGetClassroomLiveBanners";
import {
  AnalyticsEvent,
  AnalyticsPage,
  IClassroom,
  IPracticeSetSession,
} from "links/lib/types";
import { useNavigationData } from "screens/TeacherDashboard/contexts/TeacherNavigationDataProvider";

import { useArchiveClassroom } from "../shared/hooks/useArchiveClassroom";
import { useDeleteClassroom } from "../shared/hooks/useDeleteClassroom";
import { useMutateClassroom } from "../shared/hooks/useMutateClassroom";
import { useStoreRecentClassroomIds } from "../shared/hooks/useStoreRecentClassroomIds";
import { AssignmentsTab } from "./components/AssignmentsTab";
import { ClassAccuracyTab } from "./components/ClassAccuracyTab";
import { StandardsTab } from "./components/StandardsTab";
import { StudentsTab } from "./components/StudentsTab";
import { TeachersTab } from "./components/TeachersTab";

export const ClassroomDetail: React.FC = () => {
  const { navigationData } = useNavigationData();
  const params = useParams() as { classroomId: string };
  const history = useHistory();
  const {
    state: { addedStudent = false, disableNotifications = false } = {
      addedStudent: false,
      disableNotifications: false,
    },
  } = useLocation<{
    addedStudent: boolean;
    disableNotifications: boolean;
  }>();
  const [isAddStudentDirectionsModalOpen, setIsAddStudentDirectionsModalOpen] =
    useState<boolean>(addedStudent);
  const [disableNotificationsFromStudentAdd] =
    useState<boolean>(disableNotifications);
  const { isFeatureEnabled } = useAuth();
  const { trackEvent } = useAnalytics();
  const { t } = useTranslation("admin", {
    keyPrefix: "classroomDetailContainer",
    useSuspense: false,
  });

  usePageTrack(AnalyticsPage.TeacherDashboard_ClassroomDetail);

  const { classroomId } = params;
  useStoreRecentClassroomIds({ classroomId });

  const liveBanners = useGetClassroomLiveBanners({ classroomId });

  const handleTabChange = useCallback(
    (tab: ClassroomDetailTab) => {
      switch (tab) {
        case ClassroomDetailTab.Students:
          history.push(`/t/classrooms/${classroomId}/students`);
          return;
        case ClassroomDetailTab.PracticeSets:
          history.push(`/t/classrooms/${classroomId}/sets`);
          return;
        case ClassroomDetailTab.Assignments:
          history.push(`/t/classrooms/${classroomId}/assignments`);
          return;
        case ClassroomDetailTab.Standards:
          history.push(`/t/classrooms/${classroomId}/standards`);
          return;
        case ClassroomDetailTab.Teachers:
          history.push(`/t/classrooms/${classroomId}/teachers`);
          return;
      }
    },
    [history, classroomId]
  );

  const isStudentsTab = useRouteMatch("/t/classrooms/:classroom_id/students");
  const isPracticeSetsTab = useRouteMatch("/t/classrooms/:classroom_id/sets");
  const isAssignmentsTab = useRouteMatch(
    "/t/classrooms/:classroom_id/assignments"
  );
  const isStandardsTab = useRouteMatch("/t/classrooms/:classroom_id/standards");
  const isTeachersTab = useRouteMatch("/t/classrooms/:classroom_id/teachers");

  const classroomFetch = useFetchClassroom({
    id: classroomId,
    onPermissionDenied: () => {
      history.push("/t/classrooms");
    },
  });
  useErrorToast(classroomFetch.error);

  const averageUserAccuraciesFetch = useFetchAverageUserAccuracies({
    classroom_id: classroomId,
  });
  useErrorToast(averageUserAccuraciesFetch.error);

  usePageTitle([classroomFetch.data?.classroom.name]);

  const onArchiveConfirm = useCallback(
    (classroom: IClassroom) => {
      trackEvent(
        AnalyticsEvent.TeacherDashboard_ClassroomDetail_Common_ArchiveClassroomConfirm,
        { classroomId: classroom.id }
      );
    },
    [trackEvent]
  );

  const onArchiveSuccess = useCallback(() => {
    history.push("/t/classrooms/archive");
  }, [history]);

  const onDeleteConfirm = useCallback(
    (classroom: IClassroom) => {
      trackEvent(
        AnalyticsEvent.TeacherDashboard_ClassroomDetail_Common_DeleteClassroomConfirm,
        { classroomId: classroom.id }
      );
    },
    [trackEvent]
  );

  const onDeleteSuccess = useCallback(() => {
    history.push("/t/classrooms");
  }, [history]);

  const onMutateClassroomSubmit = useCallback(
    (classroomId?: string) => {
      if (!classroomId) return;

      trackEvent(
        AnalyticsEvent.TeacherDashboard_ClassroomDetail_Common_EditClassroomConfirm,
        { classroomId }
      );
    },
    [trackEvent]
  );

  const { handleArchiveIntent, modal: archiveModal } = useArchiveClassroom({
    onConfirm: onArchiveConfirm,
    onSuccess: onArchiveSuccess,
  });
  const { handleDeleteIntent, modal: deleteModal } = useDeleteClassroom({
    onConfirm: onDeleteConfirm,
    onSuccess: onDeleteSuccess,
  });
  const {
    handleEditIntent: handleEditClassroomIntent,
    flyout: classroomFlyout,
  } = useMutateClassroom({
    gradeLevels: navigationData.gradeLevels,
    subjects: navigationData.subjects,
    onSubmit: onMutateClassroomSubmit,
  });

  const accuracy = useMemo(() => {
    if (!averageUserAccuraciesFetch.data) return;

    const accuracies = averageUserAccuraciesFetch.data.average_user_accuracies;
    const hasAccuracy = !!accuracies.length;
    const sumValidAccuracy = accuracies.reduce((sum, a) => {
      return sum + a.average_accuracy;
    }, 0);
    const accuracy = hasAccuracy
      ? Math.round((sumValidAccuracy / accuracies.length) * 100)
      : undefined;

    return accuracy;
  }, [averageUserAccuraciesFetch]);

  const handleArchiveClassroom = useCallback(
    (classroom: IClassroom) => {
      handleArchiveIntent(classroom, accuracy);
      trackEvent(
        AnalyticsEvent.TeacherDashboard_ClassroomDetail_Common_ArchiveClassroomIntent,
        { classroomId: classroom.id }
      );
    },
    [trackEvent, handleArchiveIntent, accuracy]
  );

  const handleDeleteClassroom = useCallback(
    (classroom: IClassroom) => {
      handleDeleteIntent(classroom, accuracy);
      trackEvent(
        AnalyticsEvent.TeacherDashboard_ClassroomDetail_Common_DeleteClassroomIntent,
        { classroomId: classroom.id }
      );
    },
    [trackEvent, handleDeleteIntent, accuracy]
  );

  const handleDuplicateClassroom = useCallback(() => {
    // Not implemented
    return;
  }, []);

  const handleEditClassroom = useCallback(
    (classroom: IClassroom) => {
      handleEditClassroomIntent(classroom);
      trackEvent(
        AnalyticsEvent.TeacherDashboard_ClassroomDetail_Common_EditClassroomIntent,
        { classroomId: classroom.id }
      );
    },
    [trackEvent, handleEditClassroomIntent]
  );

  const handleJoinSession = (session: IPracticeSetSession) => {
    history.push(`/session/join/${session.join_code}`);

    trackEvent(
      AnalyticsEvent.TeacherDashboard_ClassroomDetail_Common_LiveBanner_JoinSession,
      {
        sessionId: session.id,
      }
    );
  };

  const navData = useMemo(() => {
    const breadcrumbs = [
      {
        label: t("breadcrumbClassrooms"),
        to: "/t/classrooms",
        isExternal: false,
      },
      {
        label: classroomFetch.data?.classroom.name || "",
        to: `/t/classrooms/${classroomId}`,
        isExternal: false,
      },
    ];

    return {
      ...navigationData,
      breadcrumbs,
    };
  }, [navigationData, classroomFetch, classroomId, t]);

  const tabIndex = useMemo(() => {
    if (isStudentsTab) {
      return ClassroomDetailTab.Students;
    }

    if (isPracticeSetsTab) {
      return ClassroomDetailTab.PracticeSets;
    }

    if (isAssignmentsTab) {
      return ClassroomDetailTab.Assignments;
    }

    if (isStandardsTab) {
      return ClassroomDetailTab.Standards;
    }

    if (isTeachersTab) {
      return ClassroomDetailTab.Teachers;
    }
  }, [
    isAssignmentsTab,
    isStudentsTab,
    isStandardsTab,
    isPracticeSetsTab,
    isTeachersTab,
  ]);

  const isLoading =
    classroomFetch.isLoading || averageUserAccuraciesFetch.isLoading;

  const classroom = classroomFetch.data?.classroom;

  return (
    <>
      <ClassroomDetailScreen
        isLoading={isLoading}
        accuracy={accuracy}
        subjects={navigationData.subjects}
        gradeLevels={navigationData.gradeLevels}
        classroom={classroom}
        navigationData={navData}
        handleArchive={handleArchiveClassroom}
        handleDelete={handleDeleteClassroom}
        handleDuplicate={handleDuplicateClassroom}
        handleEdit={handleEditClassroom}
        handleTabChange={handleTabChange}
        tabIndex={tabIndex}
        liveBanners={liveBanners}
        handleJoinSession={handleJoinSession}
        showPremiumMarker={!isFeatureEnabled("playtime.teacher.hide_premium")}
      >
        <Switch>
          <Redirect
            from="/t/classrooms/:classroomId"
            exact={true}
            to="/t/classrooms/:classroomId/students"
          />
          {!!classroom && (
            <>
              <Route path="/t/classrooms/:classroomId/students">
                <StudentsTab classroom={classroom} />
              </Route>
              <Route path="/t/classrooms/:classroomId/sets">
                <ClassAccuracyTab
                  classroom={classroom}
                  classroomAccuracy={accuracy}
                />
              </Route>
              <Route path="/t/classrooms/:classroomId/assignments">
                <AssignmentsTab classroom={classroom} />
              </Route>
              <Route path="/t/classrooms/:classroomId/standards">
                <StandardsTab classroom={classroom} />
              </Route>
              {isFeatureEnabled("playtime.teacher.enable_coteachers") && (
                <Route path="/t/classrooms/:classroomId/teachers">
                  <TeachersTab classroom={classroom} />
                </Route>
              )}
            </>
          )}
        </Switch>
      </ClassroomDetailScreen>
      {archiveModal}
      {deleteModal}
      {classroomFlyout}
      <AddStudentDirectionsModal
        handleClose={() => setIsAddStudentDirectionsModalOpen(false)}
        isOpen={isAddStudentDirectionsModalOpen}
        descriptionCase={
          disableNotificationsFromStudentAdd
            ? DescriptionCase.GOOGLE_NO_NOTIFICATION
            : DescriptionCase.GOOGLE
        }
      />
    </>
  );
};
