import React, { createContext, useContext, useMemo } from "react";

export const SessionCustomGroupsPartiallyAppliedContext =
  createContext<boolean>(false);

export interface ISessionCustomGroupsPartiallyAppliedProviderProps {
  customGroupsPartiallyApplied: boolean;
}

export const SessionCustomGroupsPartiallyAppliedProvider: React.FC<
  ISessionCustomGroupsPartiallyAppliedProviderProps
> = ({ children, customGroupsPartiallyApplied }) => {
  const value = useMemo(
    () => customGroupsPartiallyApplied,
    [customGroupsPartiallyApplied]
  );
  return (
    <SessionCustomGroupsPartiallyAppliedContext.Provider value={value}>
      {children}
    </SessionCustomGroupsPartiallyAppliedContext.Provider>
  );
};

export const useSessionCustomGroupsPartiallyApplied = (): boolean => {
  return useContext(SessionCustomGroupsPartiallyAppliedContext);
};
