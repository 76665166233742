import { FormControl, SimpleGrid } from "@chakra-ui/react";
import { FormikProps, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { usePrevious } from "react-use";

import { Dropdown, IOption } from "adminComponents/atoms/Dropdown";
import { FormErrorMessage } from "adminComponents/atoms/FormErrorMessage";
import { FormLabel } from "adminComponents/atoms/FormLabel";
import {
  ConfirmModal,
  IConfirmModalProps,
} from "adminComponents/molecules/ConfirmModal";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { ICollection } from "links/lib/types";

import { FolderDetailsFormControls } from "../FolderDetailsModal/components/FolderDetailsFormControls";
import { folderDetailsValidationSchema } from "../FolderDetailsModal/components/folderDetailsValidationSchema";
import { folderMoveValidationSchema } from "./components/folderMoveValidationSchema";

enum FolderMoveModalMode {
  NEW_FOLDER,
  SELECT,
}

interface IForm {
  name: string;
  description: string;
  selectedFolder: ICollection;
}

interface IProps {
  folders: ICollection[];
  handleClose: () => void;
  handleSave: (practiceSetId: string, selectedFolder: ICollection) => void;
  currentValue?: ICollection;
  initialValue: ICollection;
  isLoading?: IConfirmModalProps["isLoading"];
  isOpen: IConfirmModalProps["isOpen"];
  practiceSetId: string;
}

export const NEW_FOLDER: ICollection = {
  id: "-1",
  name: "",
  description: "",
  created_by: "",
  created_at: "",
  deleted_at: "",
  updated_at: "",
  practice_set_count: 0,
};

export const FolderMoveModal: React.FC<IProps> = ({
  folders,
  handleClose,
  handleSave,
  initialValue,
  currentValue,
  practiceSetId,
  ...props
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  const [modalMode, setModalMode] = useState<FolderMoveModalMode>(
    initialValue?.id === NEW_FOLDER.id
      ? FolderMoveModalMode.NEW_FOLDER
      : FolderMoveModalMode.SELECT
  );
  const [initialFormValue, setInitialFormValue] = useState<
    IForm & { initTime: number }
  >({
    selectedFolder: initialValue,
    name: "",
    description: "",
    initTime: Date.now(),
  });

  const prevIsOpen = usePrevious(props.isOpen);
  useEffect(() => {
    if (!prevIsOpen && props.isOpen) {
      setModalMode(
        initialValue?.id === NEW_FOLDER.id
          ? FolderMoveModalMode.NEW_FOLDER
          : FolderMoveModalMode.SELECT
      );

      setInitialFormValue({
        selectedFolder: { ...initialValue },
        name: "",
        description: "",
        // ensure that form is re-initialized on open
        initTime: Date.now(),
      });
    }
  }, [props.isOpen, prevIsOpen, initialValue]);

  const formik = useFormik<IForm>({
    enableReinitialize: true,
    initialValues: initialFormValue,
    onSubmit: (values: IForm) => {
      const submitFolder: ICollection =
        modalMode === FolderMoveModalMode.SELECT
          ? values.selectedFolder
          : ({
              ...values,
              id: "-1",
              created_at: "",
              created_by: "",
              updated_at: "",
              deleted_at: "",
              practice_set_count: 0,
            } as ICollection);
      handleSave(practiceSetId, submitFolder);
    },
    validateOnMount: true,
    validationSchema:
      modalMode === FolderMoveModalMode.SELECT
        ? folderMoveValidationSchema(t)
        : folderDetailsValidationSchema(t),
  });

  const handleConfirmModalClose = (confirm?: boolean) =>
    confirm ? formik.submitForm() : handleClose();

  const addNewOption: IOption = {
    value: NEW_FOLDER.id,
    label: t("folderMoveModal.addNewFolderLabel"),
  };

  const selectOptions: IOption[] = [addNewOption].concat(
    folders.map((folder) => ({
      value: folder.id,
      label: folder.name,
    }))
  );

  const selectedOption: IOption | undefined = selectOptions.find(
    ({ value }) => value === formik.values.selectedFolder?.id
  );

  const updateForm = async (
    newModalMode: FolderMoveModalMode,
    selectedFolder: ICollection
  ) => {
    setModalMode(newModalMode);
    await formik.setFieldValue("selectedFolder", selectedFolder);
    await formik.validateForm();
  };

  const handleSelectOptionChange = (v: unknown) => {
    const selectedValue = (v as IOption).value.toString();
    if (selectedValue === NEW_FOLDER.id) {
      updateForm(FolderMoveModalMode.NEW_FOLDER, NEW_FOLDER);
    } else {
      updateForm(
        FolderMoveModalMode.SELECT,
        folders.find((folder) => folder.id === selectedValue) as ICollection
      );
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    formik.handleSubmit();
  };

  return (
    <ConfirmModal
      {...props}
      buttonCancelLabel={t("folderMoveModal.cancelButton")}
      buttonOKLabel={t("folderMoveModal.confirmButton")}
      handleClose={handleConfirmModalClose}
      isDisabled={
        !formik.isValid || formik.values.selectedFolder.id === currentValue?.id
      }
      size="2xl"
      title={t("folderMoveModal.title")}
      variant="adminModalCompactMobile"
    >
      <form onSubmit={handleSubmit}>
        <SimpleGrid gap={[pxToRem(24), null, pxToRem(32)]}>
          <FormControl
            isDisabled={props.isLoading}
            isRequired
            isInvalid={
              !!formik.errors.selectedFolder && !!formik.touched.selectedFolder
            }
            variant="adminFormControl"
          >
            {modalMode !== FolderMoveModalMode.NEW_FOLDER && (
              <FormLabel
                variant="adminFormLabelDarkGray"
                htmlFor="selectFolder"
              >
                {t("folderMoveModal.nameLabel")}
              </FormLabel>
            )}
            <Dropdown
              id="selectFolder"
              handleChange={handleSelectOptionChange}
              options={selectOptions}
              placeholder={t("folderMoveModal.namePlaceholder")}
              value={selectedOption ? [selectedOption] : []}
            />
            <FormErrorMessage>
              {formik.touched.selectedFolder && formik.errors.selectedFolder}
            </FormErrorMessage>
          </FormControl>
          {modalMode === FolderMoveModalMode.NEW_FOLDER && (
            <FolderDetailsFormControls
              formik={formik as unknown as FormikProps<ICollection>}
              isLoading={props.isLoading}
            />
          )}
        </SimpleGrid>
      </form>
    </ConfirmModal>
  );
};
