import { Box, Center, Text, VStack } from "@chakra-ui/react";
import React from "react";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { ISessionGroup, ISessionUser } from "links/lib/types";
import { Card } from "sessionComponents/atoms/Card";
import { ICardProps } from "sessionComponents/atoms/Card";
import { GroupToken } from "sessionComponents/atoms/GroupToken";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { getAssetColorScheme } from "sessionComponents/utils/getAssetColorScheme";
import { getFormattedGroupName } from "sessionComponents/utils/getFormattedGroupName";
import { getFormattedTeamNames } from "sessionComponents/utils/getFormattedTeamNames";

export interface ISessionGroupCardProps extends ICardProps {
  sessionGroup: ISessionGroup;
  sessionGroupStudents: ISessionUser[];
}

export const SessionGroupCard: React.FC<ISessionGroupCardProps> = ({
  sessionGroup,
  sessionGroupStudents,
  ...rest
}) => {
  const { match: currentBreakpoints } = useBreakpoints();
  const { color_scheme, icon_url, adjective, noun } = sessionGroup;

  return (
    <Card
      paddingY={pxToRem(currentBreakpoints.padding / 2)}
      paddingX={pxToRem(currentBreakpoints.padding / 2)}
      {...rest}
    >
      <VStack>
        <GroupToken
          colorScheme={getAssetColorScheme(color_scheme)}
          imageUrl={icon_url}
          name={`${adjective} ${noun}`}
          hideTooltip
        />
        <Box width="100%">
          <Text
            fontSize={pxToRem(currentBreakpoints.fontSize)}
            textStyle="gameDisplay"
            textAlign="center"
          >
            {getFormattedGroupName(sessionGroup)}
          </Text>
        </Box>
        <Box textOverflow="ellipsis" overflow="hidden" width="100%">
          <Center>
            <Text
              fontSize={pxToRem(currentBreakpoints.fontSize)}
              textStyle="gameText"
              textAlign="center"
              textColor="primary.dark-gray"
            >
              {getFormattedTeamNames(sessionGroupStudents)}
            </Text>
          </Center>
        </Box>
      </VStack>
    </Card>
  );
};
