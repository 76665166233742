import { Box } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { SeasonMapHeader } from "sharedComponents/molecules/SeasonMapHeader";

import { ReactComponent as TicketIconSVG } from "./resource/ticket_icon.svg";

export interface ISeason4MapHeaderProps {
  ticketCount: number;
  isMusicPlaying: boolean;
  handleBack?: () => void;
  handleHome?: () => void;
  handleMusicToggle?: () => void;
}

export const Season4MapHeader: React.FC<ISeason4MapHeaderProps> = ({
  ticketCount,
  handleBack,
  handleHome,
  handleMusicToggle,
  isMusicPlaying,
}) => {
  const { t } = useTranslation("map", {
    keyPrefix: "season4.header",
    useSuspense: false,
  });

  // wrapping the SVG box styling is required
  // to make it size correctly inside the header button
  const ticketIcon = (
    <Box display="inline-flex" justifyContent="center" alignItems="center">
      <TicketIconSVG />
    </Box>
  );

  return (
    <SeasonMapHeader
      tokenIcon={ticketIcon}
      tokenCount={ticketCount}
      tokenLabel={t("availableTicketsLabel")}
      handleBack={handleBack}
      handleHome={handleHome}
      isMusicPlaying={isMusicPlaying}
      handleMusicToggle={handleMusicToggle}
    />
  );
};
