import { Box, Text, usePrefersReducedMotion } from "@chakra-ui/react";
import lottie, { AnimationItem } from "lottie-web/build/player/lottie_light";
import React, { useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { animated, config, useSpring } from "react-spring";
import { useMount, useUnmount } from "react-use";

import { pxToRem } from "adminComponents/utils";
import { AnalyticsEvent, IAvatarItem } from "links/lib/types";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { useSessionAnalytics } from "sharedComponents/contexts/sessionAnalytics";

import animationData from "./resource/floating-care-package.json";

const animationDataString = JSON.stringify(animationData);

export interface IPodiumCarePackageProps {
  carePackages: Array<IAvatarItem>;
}

const AnimatedBox = animated(Box);

export const PodiumCarePackage: React.FC<IPodiumCarePackageProps> = ({
  carePackages,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const animationRef = useRef<AnimationItem | null>(null);
  const [cpAnimLoaded, setCPAnimLoaded] = useState<boolean>(false);
  const { match: currentBreakpoints } = useBreakpoints();
  const reduceMotion = usePrefersReducedMotion();
  const { track } = useSessionAnalytics();
  const { t } = useTranslation("session", { useSuspense: false });

  useMount(() => {
    if (!containerRef.current) {
      return;
    }

    const anim = (animationRef.current = lottie.loadAnimation({
      container: containerRef.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: JSON.parse(animationDataString),
    }));

    anim.addEventListener("DOMLoaded", () => {
      setCPAnimLoaded(true);
    });

    track(AnalyticsEvent.Session_Podium_ViewCarePackage, {
      carePackageCount: carePackages.length,
    });
  });

  useUnmount(() => {
    animationRef.current?.stop();
    animationRef.current?.destroy();
    lottie.stop();
    lottie.destroy();
  });

  const textSpring = useSpring({
    from: {
      opacity: 0,
      scale: reduceMotion ? 1 : 0,
    },
    to: {
      opacity: cpAnimLoaded ? 1 : 0,
      scale: reduceMotion || cpAnimLoaded ? 1 : 0,
    },
    config: config.default,
  });

  const cpSpring = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: cpAnimLoaded ? 1 : 0,
    },
    config: config.molasses,
  });

  return (
    <Box
      w="full"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      h="full"
      position="relative"
    >
      <AnimatedBox
        style={cpSpring}
        ref={containerRef}
        w="full"
        maxH="60%"
        maxW="full"
      />
      <AnimatedBox
        style={textSpring}
        position="absolute"
        top="0"
        left="0"
        right="0"
        h="30%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Text
          textAlign="center"
          fontSize={pxToRem(currentBreakpoints.fontSize * 1.5)}
          textStyle="gameDisplay"
          fontWeight="700"
        >
          <Trans
            t={t}
            i18nKey="podium.carePackage.description"
            count={carePackages.length}
          >
            You earned
            <br /> {{ count: carePackages.length }} Care Packages!
          </Trans>
        </Text>
      </AnimatedBox>
    </Box>
  );
};
