import { Box } from "@chakra-ui/react";
import React from "react";

import { ColorScheme } from "adminComponents";
import { AnswerDiagram } from "adminComponents/molecules/AnswerDiagram";
import { AnswerMultipleChoice } from "adminComponents/molecules/AnswerMultipleChoice";
import { AnswerTermDefinition } from "adminComponents/molecules/AnswerTermDefinition";
import { AnswerTextResponse } from "adminComponents/molecules/AnswerTextResponse";
import { ClassroomAnswerResults } from "adminComponents/molecules/ClassroomAnswerResults";
import { IHandleViewStudentResponses } from "adminComponents/molecules/ClassroomAnswerResultsMetric";
import { PracticeSetQuestionCardType } from "adminComponents/molecules/PracticeSetQuestionCard";
import { StudentAnswerResults } from "adminComponents/molecules/StudentAnswerResults";
import { StudentDiagramAnswerResults } from "adminComponents/molecules/StudentDiagramAnswerResults";
import {
  IPracticeSetItem,
  IPracticeSetSessionItemReport,
  QuestionType,
} from "links/lib/types";
import { getCorrectResponseData } from "screens/TeacherClassrooms/shared/utils/practiceSetItemsHelpers";

import { AnswerClassify } from "../AnswerClassify";
import { AnswerDraw } from "../AnswerDraw";
import { AnswerNumberResponse } from "../AnswerNumberResponse";
import { ClassroomAnswerResultsList } from "../ClassroomAnswerResultsList";
import { StudentClassifyAnswerResults } from "../StudentClassifyAnswerResults";
import { StudentDrawAnswerResults } from "../StudentDrawAnswerResults";

export interface IProps {
  color?: ColorScheme;
  practiceSetItem: IPracticeSetItem;
  answerDisplayType: PracticeSetQuestionCardType;
  classroomResultsReport?:
    | IPracticeSetSessionItemReport
    | IPracticeSetSessionItemReport[];
  studentResultsReport?: IPracticeSetSessionItemReport;
  handleViewStudentResponses?: IHandleViewStudentResponses;
  handleOpenImageModal?: (imageUrl: string, imageAltText: string) => void;
  studentsCount?: number;
  hasNoPremiumAccess?: boolean;
  isInPremiumSet?: boolean; // If true, will blur on third and higher items
}

export const PracticeSetQuestionCardAnswer: React.FC<IProps> = ({
  color = "primary.warm-black",
  practiceSetItem,
  answerDisplayType,
  classroomResultsReport,
  studentResultsReport,
  handleViewStudentResponses,
  handleOpenImageModal,
  studentsCount,
  hasNoPremiumAccess,
  isInPremiumSet,
}) => {
  const {
    multiple_choice,
    question_type,
    text_response,
    alternate_text_responses,
    diagram_labels,
    term_definition,
    number_response,
    diagram,
    draw,
    classify,
    multiple_select,
  } = practiceSetItem;
  const {
    Diagram,
    MultipleChoice,
    TermDefinition,
    TextResponse,
    Draw,
    NumberResponse,
    Classify,
    MultipleSelect,
  } = QuestionType;

  const correctResponse = getCorrectResponseData(practiceSetItem, true);

  const {
    Answers,
    Generated,
    ClassroomResults,
    StudentPracticeSetResults,
    StudentAssignmentResults,
  } = PracticeSetQuestionCardType;

  const renderTextResponse = () =>
    text_response && (
      <AnswerTextResponse
        answer={text_response.correct_response}
        alternateAnswers={alternate_text_responses}
      />
    );

  const renderTermDefinition = () =>
    term_definition && (
      <AnswerTermDefinition
        term={term_definition.term}
        alternateSpellings={alternate_text_responses}
      />
    );

  const renderMultipleChoiceResponse = () =>
    multiple_choice && (
      <AnswerMultipleChoice
        choices={multiple_choice.choices}
        handleOpenImageModal={handleOpenImageModal}
        color={color}
      />
    );

  const renderMultipleSelectResponse = () =>
    multiple_select && (
      <AnswerMultipleChoice choices={multiple_select.choices} color={color} />
    );

  const renderDiagramResponse = () =>
    diagram_labels && <AnswerDiagram diagramLabels={diagram_labels} />;

  const renderNumberResponse = () =>
    number_response && (
      <AnswerNumberResponse
        prefix={number_response.prefix}
        suffix={number_response.suffix}
        answer={number_response.correct_response}
        alternateAnswers={alternate_text_responses}
        color={color}
      />
    );

  const renderDraw = () => draw && <AnswerDraw />;

  const renderClassify = () =>
    classify && (
      <AnswerClassify
        categories={classify.categories}
        handleOpenImageModal={handleOpenImageModal}
        color={color}
      />
    );

  const renderCorrectAnswerForType = (question_type: QuestionType) => {
    switch (question_type) {
      case TextResponse:
        return renderTextResponse();
      case TermDefinition:
        return renderTermDefinition();
      case MultipleChoice:
        return renderMultipleChoiceResponse();
      case Diagram:
        return renderDiagramResponse();
      case NumberResponse:
        return renderNumberResponse();
      case Classify:
        return renderClassify();
      case MultipleSelect:
        return renderMultipleSelectResponse();
      case Draw:
        return renderDraw();
    }
  };

  const renderClassroomResults = () => {
    const report = classroomResultsReport as IPracticeSetSessionItemReport;
    return (
      classroomResultsReport &&
      handleViewStudentResponses && (
        <ClassroomAnswerResults
          practiceSetItem={practiceSetItem}
          results={report}
          handleViewStudentResponses={handleViewStudentResponses}
          studentsCount={studentsCount}
        />
      )
    );
  };

  const renderClassroomResultsList = () => {
    const report = classroomResultsReport as IPracticeSetSessionItemReport;
    return (
      diagram &&
      classroomResultsReport &&
      handleViewStudentResponses && (
        <ClassroomAnswerResultsList
          practiceSetItem={practiceSetItem}
          diagramLabels={diagram_labels || []}
          report={report}
          handleViewStudentResponses={handleViewStudentResponses}
        />
      )
    );
  };

  const renderClassroomResultsForType = (question_type: QuestionType) => {
    switch (question_type) {
      case Classify:
      case NumberResponse:
      case TextResponse:
      case TermDefinition:
      case MultipleSelect:
      case MultipleChoice:
        return renderClassroomResults();
      case Diagram:
        return renderClassroomResultsList();
      case Draw:
        // Rather than a results list that appears in the card itself, hitting "View
        // responses" opens a modal so that the space-intensive drawings can be
        // better visualized. See the PracticeSetQuestionCard component for detail.
        return;
    }
  };

  const renderStudentResults = () => {
    const studentName = studentResultsReport?.responses[0]?.student_name || "";

    const correctResponses =
      correctResponse.map((response) => ({
        text: response?.text,
        imageAltText: response?.image_alt_text,
        imageUrl: response?.image_url,
        id: response?.id || "",
      })) || [];

    return (
      studentName && (
        <StudentAnswerResults
          studentName={studentName}
          questionType={question_type}
          answers={studentResultsReport?.responses || []}
          correctAnswers={correctResponses}
        />
      )
    );
  };

  const renderStudentClassifyResults = () => {
    const studentName = studentResultsReport?.responses[0]?.student_name || "";

    const categories =
      practiceSetItem.classify?.categories.map((category) => {
        const sortedChoices = (category.choices || []).sort((a, b) => {
          if (a.id > b.id) return 1;
          else return -1;
        });

        const studentAnswers = (
          studentResultsReport?.responses.filter(
            (response) => response.zone_id === category.id
          ) || []
        ).sort((a, b) => {
          if (a.choice_id > b.choice_id) return 1;
          else return -1;
        });

        return {
          ...category,
          choices: sortedChoices,
          studentAnswers,
        };
      }) || [];

    return (
      classify &&
      studentName && (
        <StudentClassifyAnswerResults
          studentName={studentName}
          classifyCategories={categories}
          questionType={question_type}
        />
      )
    );
  };

  const renderStudentDiagramResults = () => {
    const studentName = studentResultsReport?.responses[0]?.student_name || "";
    const responses = studentResultsReport && studentResultsReport.responses;

    return (
      responses &&
      studentName && (
        <StudentDiagramAnswerResults
          studentName={studentName}
          resultsList={responses}
          correctResponses={diagram_labels || []}
          questionType={practiceSetItem.question_type}
        />
      )
    );
  };

  const renderStudentDrawResults = () => {
    const studentName = studentResultsReport?.responses[0]?.student_name || "";
    const responses = studentResultsReport && studentResultsReport.responses;

    return (
      responses &&
      studentName && (
        <StudentDrawAnswerResults
          studentName={studentName}
          resultsList={responses}
          questionType={practiceSetItem.question_type}
        />
      )
    );
  };

  const renderStudentResultsForType = (question_type: QuestionType) => {
    switch (question_type) {
      case TextResponse:
      case NumberResponse:
      case TermDefinition:
      case MultipleChoice:
      case MultipleSelect:
        return renderStudentResults();
      case Diagram:
        return renderStudentDiagramResults();
      case Classify:
        return renderStudentClassifyResults();
      case Draw:
        return renderStudentDrawResults();
    }
  };

  const renderResultsForAnswerType = (
    answerType: PracticeSetQuestionCardType
  ) => {
    switch (answerType) {
      case Answers:
      case Generated:
        return (
          <Box
            style={{
              filter:
                hasNoPremiumAccess &&
                isInPremiumSet &&
                practiceSetItem.order > 2
                  ? `blur(5px)`
                  : undefined,
              WebkitFilter:
                hasNoPremiumAccess &&
                isInPremiumSet &&
                practiceSetItem.order > 2
                  ? `blur(5px)`
                  : undefined,
            }}
          >
            {renderCorrectAnswerForType(question_type)}
          </Box>
        );
      case ClassroomResults:
        return renderClassroomResultsForType(question_type);
      case StudentPracticeSetResults:
        return renderStudentResultsForType(question_type);
      case StudentAssignmentResults:
        return renderStudentResultsForType(question_type);
    }
  };

  return <>{renderResultsForAnswerType(answerDisplayType)}</>;
};
