import { Flex, HStack, Square } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Heading } from "adminComponents/atoms/Heading";
import { Icon } from "adminComponents/atoms/Icon";
import { Tabs } from "adminComponents/atoms/Tabs";
import { AdminHomepageHeader } from "adminComponents/molecules/AdminHomepageHeader";
import { DistrictAdminFooter } from "adminComponents/molecules/DistrictAdminFooter";
import {
  INavigationHeaderProps,
  OriginalNavigationHeader,
} from "adminComponents/molecules/NavigationHeader";
import { TemplateWithCenteredHeroOneColumn } from "adminComponents/templates/TemplateWithCenteredHeroOneColumn";
import { pxToRem } from "adminComponents/utils";
import { useAuth } from "links/lib/features/auth";
import { IUser } from "links/lib/types";

export interface IProps {
  navigationData: INavigationHeaderProps;
  authUser: IUser;
  isLoading?: boolean;
  practiceMinutesIsLoading?: boolean;
  tabIndex?: number;
  handleTabChange: (tab: AdminTab) => void;
  practiceMinutes: number;
  totalOrgUsers: number;
  topHalfPracticeMinutes?: boolean;
}

export enum AdminTab {
  Sessions = 0,
  Assignments = 1,
}

export const AdminHomepage: React.FC<IProps> = ({
  navigationData,
  authUser,
  isLoading,
  practiceMinutesIsLoading,
  tabIndex,
  handleTabChange,
  practiceMinutes,
  totalOrgUsers,
  topHalfPracticeMinutes,
  children,
}) => {
  const { hasNoPremiumAccess } = useAuth();
  const { t } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "districtAdminHomepage",
  });

  const navData = {
    isLoading,
    ...navigationData,
    variant: "teacherHomepageNav",
    adminMode: true,
  };

  return (
    <>
      <TemplateWithCenteredHeroOneColumn
        isLoading={isLoading}
        nav={<OriginalNavigationHeader {...navData} />}
        adminMode
        heroContent={
          <AdminHomepageHeader
            hasNoPremiumAccess={hasNoPremiumAccess}
            teacher={authUser}
            practiceMinutes={practiceMinutes}
            topHalfPracticeMinutes={topHalfPracticeMinutes}
            isLoading={practiceMinutesIsLoading}
          />
        }
        isHeroShifted
      >
        <Flex gap={pxToRem(10)} flexDir="column">
          <Heading variant="adminH3" as="h1">
            {t("topTeachers.title")}
          </Heading>
          <HStack>
            <Square
              borderRadius="full"
              borderWidth={pxToRem(2)}
              borderColor="black"
            >
              <Icon icon="person_outlined" />
            </Square>
            <Heading variant="adminH6" as="h3">
              {t("topTeachers.teachersCount", {
                count: totalOrgUsers,
                total: totalOrgUsers.toLocaleString(),
              })}
            </Heading>
          </HStack>
          <Tabs
            tabIndex={tabIndex}
            handleChange={handleTabChange}
            tabData={[
              {
                label: t("tabs.sessions"),
                content: <></>,
              },
              {
                label: t("tabs.assignments"),
                content: <></>,
              },
            ]}
            variant="adminScreenTabs"
          />
          {children}
          <DistrictAdminFooter />
        </Flex>
      </TemplateWithCenteredHeroOneColumn>
    </>
  );
};
