import { AxiosInstance } from "axios";
import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { IAssignmentUserAttempt } from "../../types";

export interface IFetchAssignmentUserAttemptsParams {
  assignment_id: string;
  group_id: string;
  user_id: string;
  onSuccess?: (data: IFetchAssignmentUserAttemptsResponse) => void;
}

export interface IFetchAssignmentUserAttemptsResponse {
  user_attempts: IAssignmentUserAttempt[];
}

export const fetchAssignmentUserAttemptsQuery = (
  axios: AxiosInstance,
  params: IFetchAssignmentUserAttemptsParams
): (() => Promise<IFetchAssignmentUserAttemptsResponse>) => {
  const { group_id, user_id, assignment_id } = params;

  return async (): Promise<IFetchAssignmentUserAttemptsResponse> => {
    return await axios
      .get(`/v1/assignments/${assignment_id}/attempts`, {
        params: { group_id, user_id },
      })
      .then((r) => r.data as IFetchAssignmentUserAttemptsResponse);
  };
};

/**
 * Fetches assignment user attempts for the provided assignment/group/user
 * @returns
 */
export default function useFetchAssignmentUserAttempts(
  params: IFetchAssignmentUserAttemptsParams
): UseQueryResult<IFetchAssignmentUserAttemptsResponse> {
  const axios = useAxios();

  const { assignment_id, user_id, onSuccess } = params;

  const query = useQuery<IFetchAssignmentUserAttemptsResponse>(
    ["assignmentUserAttempts", { assignment_id, user_id }],
    fetchAssignmentUserAttemptsQuery(axios, params),
    { onSuccess, refetchOnWindowFocus: false }
  );

  return query;
}
