import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";

export interface IFetchIndividualSessionsCountResponse {
  individual_sessions_count: number;
  prev_window_individual_sessions_count: number;
  remix_sessions_count: number;
  prev_window_remix_sessions_count: number;
}

export interface IUseFetchIndividualSessionsCountArgs {
  period_days?: number;
  enabled?: boolean;
}

/**
 * Fetches a list of practice set sessions
 * for the provided group
 * @returns
 */
export default function useFetchIndividualSessionsCount(
  args: IUseFetchIndividualSessionsCountArgs
): UseQueryResult<IFetchIndividualSessionsCountResponse, unknown> {
  const { period_days, enabled = true } = args;
  const params = { period_days };

  const axios = useAxios();

  const query = useQuery<IFetchIndividualSessionsCountResponse>(
    ["individualSessionsCount", params],
    async (): Promise<IFetchIndividualSessionsCountResponse> => {
      return await axios
        .get("/v1/practice-sets/sessions/individual", {
          params,
        })
        .then((r) => r.data as IFetchIndividualSessionsCountResponse);
    },
    {
      enabled,
    }
  );

  return query;
}
