import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import type { ISeasonZone } from "../../types";

export interface IResult {
  season_id: string;
  progress_toward_zone_unlock: number;
  current_location_id: string;
  current_unexchanged_tickets: number;
  zones: Array<ISeasonZone>;
}

export default function useFetchSeasonMap(): UseQueryResult<IResult, unknown> {
  const axios = useAxios();

  const query = useQuery<IResult>(["seasonMap"], async (): Promise<IResult> => {
    return await axios.get(`/v1/season/map`).then((r) => {
      return r.data as IResult;
    });
  });

  return query;
}
