import { Flex, SimpleGrid, SimpleGridProps } from "@chakra-ui/react";
import React from "react";

import { IconButton } from "adminComponents/atoms/IconButton";
import { Text } from "adminComponents/atoms/Text";
import { TextLink } from "adminComponents/atoms/TextLink";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { IGradeLevel } from "links/lib/types";

interface LibraryGradePickerProps extends SimpleGridProps {
  handleSelectGradeLevel: (gradeLevelId: string) => void;
  gradeLevels: IGradeLevel[];
  circleShape?: "circle" | "type1" | "type2";
  iconWidth?: string;
  iconHeight?: string;
}

export const LibraryParentGradePickerGrid: React.FC<
  LibraryGradePickerProps
> = ({
  handleSelectGradeLevel,
  gradeLevels,
  circleShape = "circle",
  iconWidth,
  iconHeight,
  ...rest
}) => {
  return (
    <SimpleGrid gap={pxToRem(20)} minChildWidth={pxToRem(150)} {...rest}>
      {gradeLevels.map((gradeLevel) => {
        // TODO: How to localize grade levels abbreviations?
        let shortGrade = "";
        if (gradeLevel.country === "US") {
          const digitMatches = gradeLevel.grade_level.match(/\d+/);
          shortGrade = digitMatches
            ? digitMatches[0]
            : gradeLevel.grade_level.charAt(0).toUpperCase();
        }
        return (
          <Flex
            key={gradeLevel.id}
            alignItems="center"
            gap={pxToRem(12)}
            onClick={() => handleSelectGradeLevel(gradeLevel.id)}
          >
            {shortGrade && (
              <IconButton
                ariaLabel={gradeLevel.grade_level}
                width={iconWidth || pxToRem(50)}
                height={iconHeight || pxToRem(50)}
                hideIcon
                icon="link"
                shape={circleShape}
                colorScheme="utility.grade-level-bg"
                colorSchemeHover="utility.link"
              >
                <Text
                  fontSize={rest.fontSize || pxToRem(40)}
                  fontFamily="Cabin Sketch"
                  color="primary.warm-white"
                  position="absolute"
                >
                  {shortGrade}
                </Text>
              </IconButton>
            )}
            <TextLink size="lg">{gradeLevel.grade_level}</TextLink>
          </Flex>
        );
      })}
    </SimpleGrid>
  );
};
