import { Box, useToken } from "@chakra-ui/react";
import React from "react";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { IGameAssetColorScheme, SessionGameType } from "links/lib/types";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { useDetectOrientation } from "sessionComponents/theme/hooks/useDetectOrientation";

import { Background } from "./resource/Background";
import { ReactComponent as TrophySVG } from "./resource/trophy.svg";
import { ReactComponent as TrophyFlameSVG } from "./resource/trophy_flame.svg";

export interface IWinningTeamScoreBoardProps {
  colorScheme: IGameAssetColorScheme;
  includeTrophy?: boolean;
  showNameFirst?: boolean;
  showPlus?: boolean;
  groupName: string;
  score: number;
  outerGame?: SessionGameType;
}

export const WinningTeamScoreBoard: React.FC<IWinningTeamScoreBoardProps> = ({
  includeTrophy = true,
  showNameFirst,
  showPlus,
  groupName,
  score,
  colorScheme,
  outerGame = SessionGameType.TheBigBoard,
}) => {
  const { match: currentBreakpoints, size } = useBreakpoints();
  const { isPortrait } = useDetectOrientation();
  const primaryColor = useToken("colors", colorScheme.primaryColor);

  const showBigBoardTrophy =
    includeTrophy && !isPortrait && outerGame === SessionGameType.TheBigBoard;
  const showQuickPlayTrophy =
    includeTrophy && !isPortrait && outerGame === SessionGameType.QuickPlay;

  const isPortraitXL = isPortrait && size === "xl";

  return (
    <Box
      minH="100%"
      h="full"
      w="full"
      padding={pxToRem(currentBreakpoints.padding)}
    >
      <Box
        position="relative"
        h="full"
        w="full"
        display={isPortraitXL ? "flex" : undefined}
        justifyContent={isPortraitXL ? "center" : undefined}
      >
        {showBigBoardTrophy && (
          <Box
            position="absolute"
            top="25%"
            left={isPortraitXL ? "22%" : "-5%"}
            height="50%"
          >
            <TrophySVG />
          </Box>
        )}
        {showQuickPlayTrophy && (
          <Box
            position="absolute"
            top="32%"
            left={-currentBreakpoints.avatarStickerSize / 4 + "px"}
          >
            <TrophyFlameSVG
              height={pxToRem(currentBreakpoints.avatarStickerSize)}
              width={pxToRem(currentBreakpoints.avatarStickerSize)}
            />
          </Box>
        )}
        <Background
          color={primaryColor}
          scoreText={`${showPlus ? "+" : ""}${score}`}
          showNameFirst={showNameFirst}
          constrainWidth={isPortraitXL}
          teamName={groupName}
        />
      </Box>
    </Box>
  );
};
