import { Box, Image, Text, usePrefersReducedMotion } from "@chakra-ui/react";
import React, { useState } from "react";
import { animated, config, useSpring } from "react-spring";

import { ColorScheme } from "adminComponents";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { useInitials } from "links/lib/hooks/useInitials";
import { IGameAssetColorScheme, SessionChatMessage } from "links/lib/types";
import { useDetectOrientation } from "sessionComponents/theme/hooks/useDetectOrientation";

import { useBreakpoints } from "../../contexts/breakpoints";
import { ReactComponent as TextBgSVG } from "./resource/text_bg.svg";

export const PORTRAIT_STICKER_TEXT_WIDTH = 144;
export const LANDSCAPE_STICKER_TEXT_WIDTH = 225;

const messageOpts: {
  [key in SessionChatMessage]: { bgColor: ColorScheme | string; text: string };
} = {
  [SessionChatMessage.FollowMe]: {
    bgColor: "team.03",
    text: "FOLLOW ME!",
  },
  [SessionChatMessage.MaybeThis]: {
    bgColor: "capri.02",
    text: "MAYBE THIS?",
  },
  [SessionChatMessage.ImNotSure]: {
    bgColor: "tangerine.03",
    text: "I'M NOT SURE!",
  },
  [SessionChatMessage.LetsGo]: {
    bgColor: "anjou.base-shadow-01",
    text: "LET'S GO!",
  },
  [SessionChatMessage.MyBad]: {
    bgColor: "monaco.02",
    text: "MY BAD!",
  },
  [SessionChatMessage.NiceWork]: {
    bgColor: "team.05",
    text: "NICE WORK!",
  },
  [SessionChatMessage.DreamTeam]: {
    bgColor: "tangerine.04",
    text: "DREAM TEAM!",
  },
  [SessionChatMessage.ItsOK]: {
    bgColor: "tangerine.base-shadow-01",
    text: "IT'S OK!",
  },
  // Quick Play specific messages
  [SessionChatMessage.Scorching]: {
    bgColor: "team.05",
    text: "SCORCHING!",
  },
  [SessionChatMessage.OnFire]: {
    bgColor: "tangerine.04",
    text: "ON FIRE!",
  },
  // the below are legacy messages that will not actually appear in the new game
  [SessionChatMessage.ThisOne]: {
    bgColor: "tangerine.base-shadow-01",
    text: "THIS ONE!",
  },
};

export interface IStickerProps {
  chatMessage: SessionChatMessage;
  userName: string;
  userProfileImageUrl: string;
  userColorScheme: IGameAssetColorScheme;
  show: boolean;
  position?: "relative" | "absolute";
  showAvatar?: boolean;
  onClick?: () => void;
  useLargeSizeOnMobile?: boolean; // used to keep the larger size in the sticker selection modal even on mobile
}

const AnimatedBox = animated(Box);

export const Sticker: React.FC<IStickerProps> = ({
  chatMessage,
  userName,
  userProfileImageUrl,
  show,
  position = "relative",
  userColorScheme,
  showAvatar = true,
  onClick,
  useLargeSizeOnMobile,
}) => {
  const { match: currentBreakpoints } = useBreakpoints();
  const prefersReducedMotion = usePrefersReducedMotion();
  const [showInitials, setShowInitials] = useState(false);
  const initials = useInitials(userName);

  const profileImageSpring = useSpring({
    from: {
      opacity: 0,
      scale: prefersReducedMotion ? 1 : 0,
    },
    to: {
      opacity: show ? 1 : 0,
      scale: prefersReducedMotion ? 1 : show ? 1 : 0,
    },
    config: prefersReducedMotion ? config.gentle : config.stiff,
  });

  const stickerTextSpring = useSpring({
    from: {
      opacity: 0,
      scale: prefersReducedMotion ? 1 : 0,
    },
    to: {
      opacity: show ? 1 : 0,
      scale: prefersReducedMotion ? 1 : show ? 1 : 0,
    },
    config: prefersReducedMotion ? config.gentle : config.wobbly,
  });

  const textOptions = messageOpts[chatMessage];

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      aria-live="polite"
      aria-label={`${userName} ${chatMessage.replace("_", " ")}`}
      position={position}
    >
      {showAvatar && (
        <AnimatedBox
          boxSize={pxToRem(currentBreakpoints.avatarStickerSize)}
          bg="transparent"
          overflow="hidden"
          style={{
            opacity: profileImageSpring.opacity,
            transform: profileImageSpring.scale.to((s) => `scale(${s})`),
          }}
        >
          {showInitials ? (
            <Box
              bg={userColorScheme.primaryColor}
              w="full"
              h="full"
              display="flex"
              borderRadius="full"
              alignItems="center"
              justifyContent="center"
              cursor={onClick ? "pointer" : "unset"}
              onClick={onClick}
            >
              <Text
                color="primary.white"
                fontSize={pxToRem(currentBreakpoints.fontSize * 1.5)}
                textStyle="gameDisplay"
              >
                {initials}
              </Text>
            </Box>
          ) : (
            <Image
              w="full"
              h="full"
              onError={() => setShowInitials(true)}
              src={userProfileImageUrl}
              alt={userName}
              cursor={onClick ? "pointer" : "unset"}
              onClick={onClick}
            />
          )}
        </AnimatedBox>
      )}
      <AnimatedBox
        w="full"
        display="flex"
        alignItems="center"
        justifyContent="center"
        overflow="visible"
        style={{
          opacity: stickerTextSpring.opacity,
          transform: stickerTextSpring.scale.to((s) => `scale(${s})`),
        }}
      >
        <StickerText
          message={textOptions.text}
          backgroundColor={textOptions.bgColor}
          onClick={onClick}
          useLargeSizeOnMobile={useLargeSizeOnMobile}
        />
      </AnimatedBox>
    </Box>
  );
};

const StickerText = ({
  message,
  backgroundColor,
  onClick,
  useLargeSizeOnMobile,
}: {
  message: string;
  backgroundColor: ColorScheme | string;
  onClick?: () => void;
  useLargeSizeOnMobile?: boolean;
}) => {
  const {
    match: { fontSize },
  } = useBreakpoints();
  const { isPortrait } = useDetectOrientation();

  const width =
    isPortrait && !useLargeSizeOnMobile
      ? PORTRAIT_STICKER_TEXT_WIDTH
      : LANDSCAPE_STICKER_TEXT_WIDTH;

  const maxFontSize = isPortrait && !useLargeSizeOnMobile ? 16 : 24;

  return (
    <Box
      color={backgroundColor}
      position="relative"
      marginTop="-1px"
      onClick={onClick}
      cursor={onClick ? "pointer" : "unset"}
    >
      <TextBgSVG width={pxToRem(width)} />
      <Text
        position="absolute"
        textStyle="gameDisplay"
        color="primary.white"
        fontSize={Math.min(fontSize, maxFontSize)}
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        textAlign="center"
        whiteSpace="nowrap"
      >
        {message}
      </Text>
    </Box>
  );
};
