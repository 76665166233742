import { pxToRem } from "adminComponents/utils/pxToRem";

export const AdminButtonGroup = {
  baseStyle: {},
  variants: {
    adminStudentAccuracy: {
      buttonGroup: {
        bgColor: "primary.tan",
        borderRadius: pxToRem(22),
        display: "flex",
        gap: pxToRem(4),
        justify: "space-between",
        overflowWrap: "break-word",
        padding: pxToRem(4),
        textStyle: "adminP2",
      },
      button: {
        borderRadius: pxToRem(22),
        overflowWrap: "break-word",
        padding: pxToRem(4),
        textAlign: "center",
        whiteSpace: "normal",
        minHeight: "100%",
      },
    },
  },
};
