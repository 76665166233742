import { Box, Center, Flex, Image, useBreakpointValue } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Accordion } from "adminComponents/atoms/Accordion";
import { Button } from "adminComponents/atoms/Button";
import { Icon } from "adminComponents/atoms/Icon";
import { PremiumTooltipRich } from "adminComponents/atoms/PremiumTooltipRich";
import { pxToRem } from "adminComponents/utils";
import { useAuth } from "links/lib/features/auth";

export interface IImageLibraryProps {
  images: Map<string, string[]>;
  onSelect: (image: string, category: string) => void;
}

export const ImageLibrary: React.FC<IImageLibraryProps> = ({
  images,
  onSelect,
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });
  const { authLicense } = useAuth();
  const hasNoPremiumAccess = !authLicense?.has_premium_access || false;
  const isMobile = useBreakpointValue({ base: true, lg: false });

  const accordionData = Array.from(images).map(([key, images]) => ({
    title: key,
    content: (
      <ImagesCategory
        key={key}
        images={images}
        onSelect={onSelect}
        category={key}
      />
    ),
  }));

  return (
    <Box maxW="100%" overflowY="auto" maxH={isMobile ? "100%" : "50vh"}>
      <Accordion accordions={accordionData} initialActiveIndexes={[0]} />
      {hasNoPremiumAccess && (
        <PremiumTooltipRich isDisabled={!hasNoPremiumAccess}>
          <Center>
            <Button
              variant="adminButtonFilled"
              size="lg"
              width={["full", null, "auto"]}
              isDisabled
              leftIcon={<Icon icon="lock" />}
            >
              {t("addMediaModal.imageUpload.addImage")}
            </Button>
          </Center>
        </PremiumTooltipRich>
      )}
    </Box>
  );
};

export interface IImagesCategoryProps {
  category: string;
  images: string[];
  onSelect: (image: string, category: string) => void;
}

const ImagesCategory: React.FC<IImagesCategoryProps> = ({
  category,
  images,
  onSelect,
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "imageLibrary",
  });
  const { hasNoPremiumAccess } = useAuth();

  return (
    <Flex
      flexWrap="wrap"
      flexDir="row"
      justifyContent="center"
      overflowY="scroll"
    >
      {images.map((image, index) => {
        return (
          <Image
            key={image}
            src={image}
            w={pxToRem(100)}
            h={pxToRem(100)}
            borderRadius="lg"
            m={pxToRem(25)}
            cursor={hasNoPremiumAccess ? "not-allowed" : "pointer"}
            borderColor="primary.dark-gray"
            borderWidth={pxToRem(2)}
            borderStyle="solid"
            onClick={() => {
              if (hasNoPremiumAccess) return;
              onSelect(image, category);
            }}
            alt={t("altText", { number: index + 1, category })}
            _hover={
              hasNoPremiumAccess
                ? undefined
                : {
                    borderColor: "primary.golden",
                  }
            }
          />
        );
      })}
    </Flex>
  );
};
