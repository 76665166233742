import { HStack } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Icon } from "adminComponents/atoms/Icon";
import { AdminFlyout } from "adminComponents/molecules/Flyout";
import { GeneratePracticeSetResult } from "adminComponents/molecules/GeneratePracticeSetResult";
import {
  IGroupSmartSetCapabilities,
  SmartSetConfiguration,
} from "adminComponents/molecules/SmartSetConfiguration";
import { pxToRem } from "adminComponents/utils";
import { IPracticeSet, ISubject } from "links/lib/types";
import {
  IClassroom,
  IPracticeSetItem,
  ISmartSetSettings,
  SmartSetType,
} from "links/lib/types";

export interface IGenerateState {
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  errorMessage?: string;
  items?: Array<IPracticeSetItem>;
  practiceSet?: IPracticeSet;
}

export interface ISmartSetFlyoutProps {
  classrooms: Array<IClassroom>;
  subjects: Array<ISubject>;
  groupSmartSetsCapabilities?: Array<IGroupSmartSetCapabilities>;
  defaultValue?: {
    smartSetType?: SmartSetType;
    smartSetSettings?: ISmartSetSettings;
  };
  isSaving: boolean;
  isOpen: boolean;
  showBack?: boolean;
  generateState: IGenerateState;
  handleGenerate: (data: {
    smartSetType: SmartSetType;
    smartSetSettings: ISmartSetSettings;
  }) => void;
  handleSave: () => void;
  handleRestart: () => void;
  handleClose: () => void;
  handleBack: () => void;
}

export const SmartSetFlyout: React.FC<ISmartSetFlyoutProps> = ({
  classrooms,
  subjects,
  groupSmartSetsCapabilities,
  defaultValue,
  generateState,
  isSaving,
  isOpen,
  showBack,
  handleClose,
  handleBack,
  handleGenerate,
  handleSave,
  handleRestart,
}) => {
  const { t } = useTranslation("admin", {
    keyPrefix: "smartSetFlyout",
    useSuspense: false,
  });

  /**
   * Handles back button click on flyout based on current state
   */
  const _handleBack = () => {
    // Reset the state if the generation if back is clicked during generation or result
    if (
      generateState.isLoading ||
      generateState.isSuccess ||
      generateState.isError
    ) {
      handleRestart();
    } else {
      handleBack?.();
    }
  };

  const showResult =
    generateState.isSuccess &&
    !!generateState.practiceSet &&
    !!generateState.items?.length;

  const title = !showResult ? t("title") : "";
  const subtitle = !showResult ? t("subtitle") : "";

  const footerContent = showResult ? (
    <HStack spacing={pxToRem(10)}>
      <Button
        onClick={handleSave}
        variant="adminButtonFilled"
        leftIcon={<Icon icon="add_circle_outlined" boxSize={pxToRem(16)} />}
        isLoading={isSaving}
      >
        {t("saveButton")}
      </Button>
      <Button
        isLoading={isSaving}
        onClick={handleRestart}
        variant="adminButtonOutlinedMuted"
      >
        {t("backButton")}
      </Button>
    </HStack>
  ) : undefined;

  return (
    <AdminFlyout
      isOpen={isOpen}
      title={title}
      subtitle={subtitle}
      footerContent={footerContent}
      showBack={showBack}
      onClose={handleClose}
      onBack={_handleBack}
    >
      {!showResult && (
        <SmartSetConfiguration
          classrooms={classrooms}
          subjects={subjects}
          groupSmartSetsCapabilities={groupSmartSetsCapabilities}
          defaultValue={defaultValue}
          generateState={generateState}
          handleGenerate={handleGenerate}
        />
      )}

      {showResult && !!generateState.practiceSet && !!generateState.items && (
        <GeneratePracticeSetResult
          practiceSet={generateState.practiceSet}
          items={generateState.items}
        />
      )}
    </AdminFlyout>
  );
};
