import {
  Box,
  FormControl,
  HStack,
  Spacer,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useQueryClient } from "react-query";
import { Link, useParams } from "react-router-dom";

import { Button } from "adminComponents/atoms/Button";
import { LoadingSpinner } from "adminComponents/atoms/LoadingSpinner";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils";
import { useAuth } from "links/lib/features/auth";
import { usePerformLLMChat } from "links/lib/features/llm/usePerformLLMChat";
import { UserRole } from "links/lib/types";

import { StepCard } from "../components/StepCard";
import { PostStepAction, ProcessFlow, Step } from "../types";
import { PracticeSetFlyout } from "./components/PracticeSetFlyout";
import { SkillsFlyout } from "./components/SkillsFlyout";
import { IParseSkillsReslt, parseSkills } from "./utils/parseSkills";

const getLastStepWithResults = (steps: Step[]): number => {
  let lastStepWithResults = 0;
  for (let i = 0; i < steps.length; i++) {
    if (steps[i].results) {
      lastStepWithResults = i;
    } else {
      break;
    }
  }
  return lastStepWithResults;
};

interface IProps {
  processFlow: ProcessFlow;
  onSave: () => void;
}

export const LLMProcessRunner: React.FC<IProps> = ({ processFlow, onSave }) => {
  const { authUser } = useAuth();
  const { index: indexString } = useParams<{ index: string }>();
  const processIndex = parseInt(indexString);
  const [currentStepIndex, setCurrentStepIndex] = useState<number | undefined>(
    getLastStepWithResults(processFlow.steps)
  );
  const [skills, setSkills] = useState<IParseSkillsReslt>();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: practiceSetFlyoutIsOpen,
    onClose: practiceSetFlyoutOnClose,
    onOpen: practiceSetFlyoutOnOpen,
  } = useDisclosure();

  const [practiceSetResponse, setPracticeSetResponse] = useState<string>("");
  const [error, setError] = useState<string>();
  const queryClient = useQueryClient();
  const [isReadOnly, setIsReadOnly] = useState<boolean>(false);

  const { isLoading, mutateAsync } = usePerformLLMChat({
    onError: (error) => setError(JSON.stringify(error)),
  });

  const { setValue, getValues } = useFormContext();

  const parse = useCallback(
    (step: Step) => {
      switch (step.postStepAction) {
        case PostStepAction.PARSE_SKILLS: {
          const skills = parseSkills(step.results || "");
          setSkills(skills);
          onOpen();
          break;
        }
        case PostStepAction.PARSE_PRACTICE_SET: {
          setPracticeSetResponse(step.results || "");
          practiceSetFlyoutOnOpen();
          break;
        }
      }
    },
    [onOpen, practiceSetFlyoutOnOpen]
  );

  const chat = useCallback(
    async (step: Step, index: number) => {
      const messages = [
        {
          content: step.prompt || "",
        },
      ];

      if (index === 0 && step.extraInput) {
        messages.push({
          content: step.extraInput,
        });
      }

      const steps = getValues("steps");

      if (index > 0) {
        messages.push({
          content: steps?.[index - 1]?.results,
        });
      }

      const result = await mutateAsync({
        messages,
        llm_model: step.llmModel,
      });

      setValue(`steps.${index}.results`, result.response.content);
      if (!isReadOnly) {
        onSave();
      }

      parse(step);

      if (
        step.autoAdvanceToNextStep &&
        steps?.[index + 1] &&
        step.postStepAction === PostStepAction.NONE
      ) {
        setCurrentStepIndex(index + 1);
        chat(steps?.[index + 1], index + 1);
      }
    },
    [mutateAsync, getValues, parse, onSave, setValue, isReadOnly]
  );

  const clearAllStepResults = useCallback(() => {
    const steps: Step[] = getValues("steps");

    const newSteps: Step[] = steps.map((step) => ({
      ...step,
      results: undefined,
    }));

    setValue("steps", newSteps);
  }, [getValues, setValue]);

  const cancelChat = useCallback(async () => {
    await queryClient.cancelMutations();
  }, [queryClient]);

  if (!authUser || authUser.role !== UserRole.ContentSpecialist) return null;

  return (
    <>
      {isLoading && (
        <Box
          w="100vw"
          h="100vh"
          top={0}
          position="fixed"
          display="flex"
          alignItems="center"
          alignContent="center"
          justifyContent="center"
          justifyItems="center"
          flexDir="column"
          zIndex={99}
        >
          <Button onClick={cancelChat} variant="adminButtonFilled">
            Cancel
          </Button>
          <Box>
            <LoadingSpinner />
          </Box>
        </Box>
      )}
      <Box h="100vh" w="100vw">
        <FormControl variant="adminFormControl">
          <VStack
            zIndex={10}
            as="header"
            position="sticky"
            top={0}
            alignItems="flex-start"
            w="full"
            p={pxToRem(16)}
            background={
              isReadOnly ? "utility.light-orange" : "primary.light-gray"
            }
          >
            <HStack w="full">
              <Button variant="adminButtonFilled" onClick={clearAllStepResults}>
                Clear All Results
              </Button>
              <Spacer />
              <Text>{processFlow.name}</Text>
              <Spacer />
              <Button
                variant="adminButtonFilled"
                onClick={() => setIsReadOnly((isReadOnly) => !isReadOnly)}
              >
                {isReadOnly ? "Allow Saves" : "Set Read Only"}
              </Button>
              <Button
                variant="adminButtonFilled"
                as={Link}
                to={`/e0fe8aed-6b71-420f-8f9f-48780bfbd3dc/llm-process-flows/${processIndex}/edit`}
              >
                Edit
              </Button>
            </HStack>
          </VStack>

          <VStack p={pxToRem(16)} spacing={pxToRem(16)}>
            {processFlow.steps.map((step, index) => (
              <StepCard
                selected={index === currentStepIndex}
                index={index}
                key={`${index}-${step.name}`}
                onChat={() => chat(step, index)}
                onNext={
                  index === processFlow.steps.length - 1
                    ? undefined
                    : () => setCurrentStepIndex(index + 1)
                }
                onPrevious={
                  index <= 0 ? undefined : () => setCurrentStepIndex(index - 1)
                }
                onParse={
                  step.postStepAction !== PostStepAction.NONE
                    ? () => parse(step)
                    : undefined
                }
              />
            ))}
          </VStack>
        </FormControl>
      </Box>
      <SkillsFlyout skills={skills} isOpen={isOpen} onClose={onClose} />
      <PracticeSetFlyout
        practiceSetString={practiceSetResponse}
        isOpen={practiceSetFlyoutIsOpen}
        onClose={practiceSetFlyoutOnClose}
        error={error}
      />
    </>
  );
};
