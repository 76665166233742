import { AxiosError } from "axios";
import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { IPracticeSet } from "../../types";

export interface IFetchPracticeSetsByUserExperienceResponse {
  classroom_practice_sets: Array<{
    classroom_id: string;
    practice_sets_with_user_details: Array<{
      practice_set: IPracticeSet;
      maximum_xp: number;
      item_count: number;
    }>;
  }>;
}

export interface IFetchPracticeSetsByUserExperienceResponseGroups {
  group_practice_sets: Array<{
    group_id: string;
    practice_sets_with_user_details: Array<{
      practice_set: IPracticeSet;
      maximum_xp: number;
      item_count: number;
    }>;
  }>;
}

/**
 * Fetches a list of practice sets available
 * for the current user based on experience
 * @returns
 */
export default function useFetchPracticeSetsByUserExperience({
  enabled = true,
}: {
  enabled?: boolean;
}): UseQueryResult<IFetchPracticeSetsByUserExperienceResponse, AxiosError> {
  const axios = useAxios();

  const fetchPracticeSetsResponse = useQuery<
    IFetchPracticeSetsByUserExperienceResponse,
    AxiosError
  >(
    ["practiceSetsByUserExperience"],
    async (): Promise<IFetchPracticeSetsByUserExperienceResponse> => {
      const response = await axios
        .get("/v1/practice-sets/user-experience")
        .then((r) => r.data as IFetchPracticeSetsByUserExperienceResponseGroups)
        .then((r) => {
          return {
            classroom_practice_sets: r.group_practice_sets.map(
              (groupPracticeSets) => {
                return {
                  classroom_id: groupPracticeSets.group_id,
                  practice_sets_with_user_details:
                    groupPracticeSets.practice_sets_with_user_details,
                };
              }
            ),
          };
        });

      return response;
    },
    { enabled }
  );

  return fetchPracticeSetsResponse;
}
