import { HStack } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Icon } from "adminComponents/atoms/Icon";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { useSessionGameState } from "links/lib/contexts/sessionGameState";
import { useSessionRoundState } from "links/lib/contexts/sessionRoundState";
import { useSessionScene } from "links/lib/contexts/sessionScene";
import { SessionGameType } from "links/lib/types";
import { Button } from "sessionComponents/atoms/Button";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { ConfirmButton } from "sessionComponents/molecules/ConfirmButton";

interface IRoundReviewButtonsProps {
  showFeedbackButton?: boolean;
  showExplainButton?: boolean;
  onFeedbackClick: () => void;
  onExplainClick?: () => void;
  onConfirmButtonClick: () => void;
  isTeacher: boolean;
  isFinalRound?: boolean; //only in individual session?
  isClassSession: boolean;
  isConfirmLoading?: boolean;
  isDrawItem?: boolean;
}

export const RoundReviewButtons: React.FC<IRoundReviewButtonsProps> = ({
  showFeedbackButton = true,
  showExplainButton = false,
  onFeedbackClick,
  onExplainClick,
  onConfirmButtonClick,
  isTeacher,
  isFinalRound,
  isClassSession,
  isConfirmLoading,
  isDrawItem,
}) => {
  const {
    match: { buttonHeight, margin, fontSize },
  } = useBreakpoints();

  const { t } = useTranslation("session", {
    keyPrefix: "roundReview",
    useSuspense: false,
  });

  const gameState = useSessionGameState();
  const roundState = useSessionRoundState();
  const sessionScene = useSessionScene();

  const isQuickPlay = gameState?.game_type === SessionGameType.QuickPlay;
  const isThirdQuestion = !(roundState.round_number % 3);
  const isFinalQuestion =
    roundState.round_number === gameState?.final_round_number;

  let teacherText = t("placeTokenButtonMsg");
  if (isDrawItem) {
    teacherText = t("startVotingButtonMsg");
  } else if (isQuickPlay) {
    if (isThirdQuestion) {
      teacherText = t("leaderBoardButtonMsg");
    } else {
      teacherText = t("nextQuestionButtonMsg");
    }
    if (isFinalQuestion) teacherText = t("finishPracticeButtonMsg");
  }

  const studentText = isFinalRound
    ? t("finishPracticeButtonMsg")
    : t("nextQuestionButtonMsg");
  const confirmButtonText = isTeacher ? teacherText : studentText;

  const showConfirmButton = isTeacher || (!isTeacher && !isClassSession);

  return (
    <HStack spacing={pxToRem(margin / 2)} w="full">
      {showExplainButton && (
        <ConfirmButton
          variant="buttonOutlined"
          height={pxToRem(buttonHeight)}
          onClick={onExplainClick}
          w="full"
          fontSize={pxToRem(fontSize)}
          confirmedWhenChanged={[]}
        >
          <Icon icon="chat_bubble_outlined" />
          &nbsp;
          {t("explainButtonMsg")}
        </ConfirmButton>
      )}

      {showFeedbackButton && !showExplainButton && (
        <Button
          variant="buttonOutlined"
          height={pxToRem(buttonHeight)}
          onClick={onFeedbackClick}
          w="full"
          fontSize={pxToRem(fontSize)}
        >
          {t("feedbackButtonMsg")}
        </Button>
      )}
      {showConfirmButton && (
        <ConfirmButton
          variant="buttonFilled"
          height={pxToRem(buttonHeight)}
          isLoading={isConfirmLoading}
          handleConfirmClick={onConfirmButtonClick}
          confirmedWhenChanged={[sessionScene]}
          fontSize={pxToRem(fontSize)}
          w="full"
        >
          {confirmButtonText}
        </ConfirmButton>
      )}
    </HStack>
  );
};
