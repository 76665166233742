import { ADVOKIT_URL, PREMIUM_SUPPORT_URL } from "links/lib/constants";
import { useAuth } from "links/lib/features/auth";
import { UserRole } from "links/lib/types";

export const useGetPremiumLink = (): string => {
  const { authUser } = useAuth();

  let premiumLink = PREMIUM_SUPPORT_URL;
  if (authUser?.role !== UserRole.Admin && authUser?.organization_id === "0") {
    premiumLink = ADVOKIT_URL;
  }

  return premiumLink;
};
