import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";

import { IPracticeSet, IPracticeSetItem, QuestionType } from "links/lib/types";

interface IArgs {
  practiceSet?: IPracticeSet;
}

interface IResult {
  handleEdit: (item: IPracticeSetItem) => void;
}

const usePracticeSetItemEdit = ({ practiceSet }: IArgs): IResult => {
  const history = useHistory();

  const handleEdit = useCallback(
    (item: IPracticeSetItem) => {
      if (!practiceSet) return;

      switch (item.question_type) {
        case QuestionType.Diagram:
          history.push(
            `/t/my-library/sets/${practiceSet.id}/diagram/${item.id}`
          );
          break;
        case QuestionType.MultipleChoice:
        case QuestionType.MultipleSelect:
          history.push(
            `/t/my-library/sets/${practiceSet.id}/multiple-choice/${item.id}`
          );
          break;
        case QuestionType.TermDefinition:
          history.push(`/t/my-library/sets/${practiceSet.id}/term/${item.id}`);
          break;
        case QuestionType.OpenEnded:
          history.push(
            `/t/my-library/sets/${practiceSet.id}/open-ended/${item.id}`
          );
          break;
        case QuestionType.TextResponse:
          history.push(
            `/t/my-library/sets/${practiceSet.id}/text-response/${item.id}`
          );
          break;
        case QuestionType.NumberResponse:
          history.push(
            `/t/my-library/sets/${practiceSet.id}/number-response/${item.id}`
          );
          break;
        case QuestionType.Classify:
          history.push(
            `/t/my-library/sets/${practiceSet.id}/classify/${item.id}`
          );
          break;
        case QuestionType.Draw:
          history.push(`/t/my-library/sets/${practiceSet.id}/draw/${item.id}`);
          break;
      }
    },
    [history, practiceSet]
  );

  return useMemo(
    () => ({
      handleEdit,
    }),
    [handleEdit]
  );
};

export { usePracticeSetItemEdit };
