import {
  Circle,
  Flex,
  HStack,
  VStack,
  useBreakpointValue,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { animated, config, useSpring } from "react-spring";

import { Card } from "adminComponents/atoms/Card";
import { Heading } from "adminComponents/atoms/Heading";
import { Icon } from "adminComponents/atoms/Icon";
import { IconTooltip } from "adminComponents/atoms/IconTooltip";
import { LoadingSpinner } from "adminComponents/atoms/LoadingSpinner";
import { Tag } from "adminComponents/atoms/Tag";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils";

export interface IProps {
  coverImage: JSX.Element;
  totalStat: number;
  currentStat?: number;
  previousStat?: number;
  topHalfStat?: boolean;
  topHalfTooltip?: string;
  statLabel: string;
  tooltipText?: string;
  decreaseTooltipTextKey?: string;
  isLoading?: boolean;
  large?: boolean;
  animate?: boolean;
  usePortalTooltips?: boolean;
}

export const StatCard: React.FC<IProps> = ({
  coverImage,
  totalStat,
  currentStat = 0,
  previousStat = 0,
  topHalfStat,
  topHalfTooltip,
  statLabel,
  tooltipText,
  decreaseTooltipTextKey = "",
  isLoading,
  large,
  animate,
  usePortalTooltips,
}) => {
  const styles = useMultiStyleConfig("AdminStatCard", {});
  const { t } = useTranslation("admin", { useSuspense: false });
  const isMobile = useBreakpointValue({ base: true, md: false });

  const { animatedTotalStat } = useSpring({
    from: {
      animatedTotalStat: 0,
    },
    to: {
      animatedTotalStat: totalStat,
    },
    delay: 250,
    config: config.slow,
  });

  let percentChange = 0;
  let diff = 0;
  let percentChangeLabel = "";
  let toolTipExplanation = "";

  if (previousStat > 0 && currentStat > 0) {
    diff = currentStat - previousStat;
    percentChange = Math.abs(Math.round((diff / previousStat) * 100));
    percentChangeLabel =
      diff > 0
        ? t("districtAdminHomepage.studentSnapshot.percentIncrease", {
            percent: percentChange,
          })
        : t("districtAdminHomepage.studentSnapshot.percentDecrease", {
            percent: percentChange,
          });
    toolTipExplanation =
      diff > 0
        ? t("districtAdminHomepage.studentSnapshot.increaseExplanation", {
            percent: percentChange,
          })
        : t(decreaseTooltipTextKey, { percent: percentChange });
  }

  return (
    <Card
      noPadding
      borderColor="primary.tan"
      backgroundColor="primary.white"
      variant="adminCardSmallBorder"
      sx={styles.card}
    >
      <Flex sx={styles.body}>
        <Flex sx={styles.headerContainer}>
          {!isLoading && topHalfStat && (
            <Tag
              tooltip={topHalfTooltip}
              borderTopLeftRadius={pxToRem(16)}
              borderBottomLeftRadius={0}
              borderTopRightRadius={0}
              colorScheme="utility.top-green"
            >
              <Flex gap={pxToRem(6)} alignItems="center">
                <Circle bgColor="utility.focus" padding={pxToRem(4)}>
                  <Icon
                    icon="star_outlined"
                    boxSize={pxToRem(14)}
                    color="white"
                  />
                </Circle>
                {t("districtAdminHomepage.studentSnapshot.topOrg")}
              </Flex>
            </Tag>
          )}
        </Flex>
        {coverImage}
        <VStack sx={styles.content}>
          <Heading
            variant={large ? "adminH3" : "adminH5Gooper"}
            as={large ? "h3" : "h5"}
          >
            {animate && (
              <animated.div>
                {!isLoading &&
                  animatedTotalStat.to((val) =>
                    Math.floor(val).toLocaleString()
                  )}
              </animated.div>
            )}
            {!animate && !isLoading && totalStat.toLocaleString()}
            {isLoading && <LoadingSpinner size="md" />}
          </Heading>
          <HStack>
            <Text variant="adminP2" color="primary.dark-gray">
              {statLabel}
            </Text>
            {tooltipText && (
              <IconTooltip usePortal={usePortalTooltips || isMobile}>
                {tooltipText}
              </IconTooltip>
            )}
          </HStack>
          {!isLoading && percentChange > 0 && (
            <Tag
              leftIcon={diff > 0 ? "arrow_upward" : "arrow_downward"}
              tooltip={toolTipExplanation}
              colorScheme={diff > 0 ? "green" : "red"}
            >
              {percentChangeLabel}
            </Tag>
          )}
        </VStack>
      </Flex>
    </Card>
  );
};
