import { Alert, Box, Select, SimpleGrid, Text } from "@chakra-ui/react";
import React from "react";

import { AttachmentSelector } from "./AttachmentSelector";

type Step2Props = {
  attachmentNames: Array<string>;
  imagesBase64: { [key: string]: string };
  selectedSlots: { [key: string]: boolean };
  skinNames: Array<string>;
  setSelectedSkinName: (skinName: string) => void;
  onSelectSlot: (slotName: string) => void;
};

export const Step2: React.FC<Step2Props> = ({
  attachmentNames,
  imagesBase64,
  selectedSlots,
  skinNames,
  setSelectedSkinName,
  onSelectSlot,
}: Step2Props) => {
  return (
    <>
      <Text>
        2. Select the skin, relevant attachments, and enter applicable
        attachment details
      </Text>

      <Select
        onChange={(e) => {
          setSelectedSkinName(e.target.value);
        }}
      >
        {skinNames
          .sort((a, b) => (a === "default" ? -1 : a > b ? 1 : -1))
          .map((skinName) => {
            return (
              <option key={skinName} value={skinName}>
                {skinName}
              </option>
            );
          })}
      </Select>

      {attachmentNames && (
        <SimpleGrid columns={4} w="full" bgColor="white">
          {attachmentNames.map((slotName: string) => {
            if (slotName.indexOf(" ") >= 0 || slotName.indexOf("-") >= 0) {
              return (
                <Box key={slotName}>
                  <Alert status="error">
                    Slot with invalid name found: {slotName}
                  </Alert>
                </Box>
              );
            }

            const isChecked = !!selectedSlots[slotName];
            const imageBase64 = imagesBase64[slotName];

            return (
              <AttachmentSelector
                key={slotName}
                slotName={slotName}
                isChecked={isChecked}
                imageBase64={imageBase64}
                onSelectSlot={onSelectSlot}
              />
            );
          })}
        </SimpleGrid>
      )}
    </>
  );
};
