import {
  Box,
  Flex,
  FormLabel,
  VisuallyHidden,
  useDisclosure,
} from "@chakra-ui/react";
import qs from "qs";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { Button } from "adminComponents/atoms/Button";
import { Card } from "adminComponents/atoms/Card";
import { Heading } from "adminComponents/atoms/Heading";
import { Text } from "adminComponents/atoms/Text";
import { LiveClassroomBanner } from "adminComponents/molecules/LiveClassroomBanner";
import {
  INavigationHeaderProps,
  NavigationHeader,
} from "adminComponents/molecules/NavigationHeader";
import { TeacherHomepageHeader } from "adminComponents/molecules/TeacherHomepageHeader";
import { LibrarySearchFilters } from "adminComponents/organisms/LibrarySearchFilters";
import { TemplateWithCenteredHeroOneColumn } from "adminComponents/templates/TemplateWithCenteredHeroOneColumn";
import { pxToRem } from "adminComponents/utils";
import { PUBLIC_SEARCH_PER_PAGE } from "links/lib/constants";
import { useAuth } from "links/lib/features/auth";
import { usePublicLibrarySearch } from "links/lib/features/search/usePublicLibrarySearch";
import { LiveBanner } from "links/lib/hooks/useGetClassroomLiveBanners";
import {
  IPracticeSet,
  IPracticeSetSession,
  IUser,
  PracticeSetAvailability,
} from "links/lib/types";
import { useInitialQuery } from "screens/TeacherPublicLibrary/LibraryRoot/hooks/useInitialQuery";

import { NewSetsFromFollowed } from "./components/NewSetsFromFollowed";
import { RecommendedCreators } from "./components/RecommendedCreators";
import { RecommendedSets } from "./components/RecommendedSets";
import { TopIndividualPracticersModal } from "./components/TopIndividualPracticersModal";
import { TopSocialEventReactorsModal } from "./components/TopSocialEventReactorsModal";
import footerCardBackgroundDesktop from "./resource/footer-card-background-desktop.svg";
import footerCardBackgroundMobile from "./resource/footer-card-background-mobile.svg";
import footerCardImageDesktop from "./resource/footer-card-image-desktop.svg";
import footerCardImageMobile from "./resource/footer-card-image-mobile.svg";

export interface IProps {
  navigationData: INavigationHeaderProps;
  authUser: IUser;
  isLoading?: boolean;
  socialEventReactionsCount: string;
  prevWindowSocialEventReactionsCount: string;
  individualSessionsCount: number;
  prevWindowIndividualSessionsCount: number;
  recommendedPracticeSets: Array<IPracticeSet>;
  mostCopiedCreators: Array<IUser>;
  newSetsFromFollowed: Array<IPracticeSet>;
  // TODO: should these still be displayed somewhere?
  liveBanners: LiveBanner[];
  handleJoinSession: (session: IPracticeSetSession) => void;
}

export const TeacherHomepageImproved: React.FC<IProps> = ({
  navigationData,
  authUser,
  isLoading,
  socialEventReactionsCount,
  prevWindowSocialEventReactionsCount,
  individualSessionsCount,
  prevWindowIndividualSessionsCount,
  recommendedPracticeSets,
  mostCopiedCreators,
  newSetsFromFollowed,
  liveBanners,
  handleJoinSession,
}) => {
  const { isFeatureEnabled } = useAuth();
  const history = useHistory();
  const { t } = useTranslation("admin", { useSuspense: false });

  const navData = {
    isLoading,
    ...navigationData,
    variant: "teacherHomepageNav",
  };

  const {
    isOpen: isTopIndividualPracticersModalOpen,
    onOpen: onTopIndividualPracticersModalOpen,
    onClose: onTopIndividualPracticersModalClose,
  } = useDisclosure();
  const {
    isOpen: isTopSocialEventReactorsModalOpen,
    onOpen: onTopSocialEventReactorsModalOpen,
    onClose: onTopSocialEventReactorsModalClose,
  } = useDisclosure();

  const headerData = {
    teacher: authUser,
    handleTopIndividualPracticersModalOpen: onTopIndividualPracticersModalOpen,
    handleTopSocialEventReactorsModalOpen: onTopSocialEventReactorsModalOpen,
  };

  const showPremium = !isFeatureEnabled("playtime.teacher.hide_premium");

  const teacherSubjectIds =
    authUser.teacher_subjects?.map((ts) => ts.subject_id) ?? [];
  const teacherGradeLevelIds =
    authUser.teacher_grade_levels?.map((tgl) => tgl.grade_level_id) ?? [];

  const initialQuery = useInitialQuery({
    subjects: navigationData.subjects,
    authUser,
  });

  const {
    handleClearFilters,
    handleClearSearchTerm,
    handleRemoveFilterTag,
    handleSetTerm,
    handleOpenFilterFlyout,
    handleSuggest,
    isSearchLoading,
    query,
    filterFlyout,
    standardsModal,
    suggestions,
  } = usePublicLibrarySearch({
    gradeLevels: navigationData.gradeLevels,
    subjects: navigationData.subjects,
    region: authUser?.region,
    country: authUser?.country || "US",
    basicSearchPage: 1,
    perPage: PUBLIC_SEARCH_PER_PAGE,
    initialQuery,
    availability: PracticeSetAvailability.Public,
    showPremiumFilter: showPremium,
    showSubjectsCounts: false,
  });

  const handleSearch = useCallback(
    (search: string) => {
      const params = qs.stringify(
        {
          term: search,
          page: 1,
          ...query.filters,
        },
        { encodeValuesOnly: true }
      );

      history.push(`/t/public-library?${params}`);
    },
    [history, query]
  );

  const socialEventReactionsCountNumber = parseInt(
    socialEventReactionsCount,
    10
  );

  const numActiveSearchFilters = useMemo(() => {
    const numChildSubjectsWithParentAlreadySelected =
      query.filters.subjectIds.filter((sId) =>
        query.filters.subjectIds.includes(
          navigationData.subjects.find((s) => s.id === sId)?.parent_id || "0"
        )
      ).length;
    return (
      query.filters.gradeLevelIds.length +
      query.filters.subjectIds.length +
      query.filters.standardIds.length -
      numChildSubjectsWithParentAlreadySelected
    );
  }, [navigationData.subjects, query.filters]);

  return (
    <>
      <TemplateWithCenteredHeroOneColumn
        isLoading={isLoading}
        nav={<NavigationHeader {...navData} />}
        heroContent={
          <TeacherHomepageHeader
            {...headerData}
            individualSessionCount={individualSessionsCount}
            prevWindowIndividualSessionCount={prevWindowIndividualSessionsCount}
            socialEventReactionsCount={socialEventReactionsCountNumber}
            prevWindowSocialEventReactionsCount={parseInt(
              prevWindowSocialEventReactionsCount,
              10
            )}
          />
        }
      >
        <Flex gap={pxToRem(58)} flexDir="column">
          {!!liveBanners?.length && (
            <Flex direction="column" gap={pxToRem(32)}>
              {liveBanners.map((l) => (
                <LiveClassroomBanner
                  key={l.session.id}
                  handleJoinSession={handleJoinSession}
                  {...l}
                />
              ))}
            </Flex>
          )}
          {/* Search practice sets */}
          <Flex flexDir="column" gap={pxToRem(24)}>
            <Heading variant="adminH7" as="h1">
              {t("common.search")}
            </Heading>
            <VisuallyHidden>
              <FormLabel id="searchLabel">
                {t("myLibrary.searchPracticeSets")}
              </FormLabel>
            </VisuallyHidden>
            <Flex w="full" gap={pxToRem(8)}>
              <Box flex="1">
                <LibrarySearchFilters
                  showFilterButton={false}
                  showActiveFilters={false}
                  gradeLevels={navData.gradeLevels}
                  gradeLevelIds={query.filters.gradeLevelIds}
                  subjects={navData.subjects}
                  subjectIds={query.filters.subjectIds}
                  handleClearAllFilters={handleClearFilters}
                  handleOpenFilterFlyout={handleOpenFilterFlyout}
                  handleRemoveFilterTag={handleRemoveFilterTag}
                  handleSetSearchTerm={handleSetTerm}
                  handleSuggest={handleSuggest}
                  handleClearSearchInput={handleClearSearchTerm}
                  handleSearch={handleSearch}
                  isLoading={isSearchLoading}
                  suggestions={suggestions}
                  searchPlaceholder={t("myLibrary.searchPracticeSets")}
                  aria-labelled-by="searchLabel"
                />
              </Box>
              <Button
                mt={pxToRem(4)}
                variant="adminButtonOutlined"
                onClick={() => handleOpenFilterFlyout()}
              >
                {numActiveSearchFilters > 0
                  ? `(${numActiveSearchFilters}) `
                  : ""}
                {t("common.filter")}
              </Button>
            </Flex>
          </Flex>
          <RecommendedSets
            hasSubjectsAndOrGrades={
              !!teacherSubjectIds.length && !!teacherGradeLevelIds.length
            }
            practiceSets={recommendedPracticeSets}
          />
          <NewSetsFromFollowed newSetsFromFollowed={newSetsFromFollowed} />
          <RecommendedCreators
            authUser={authUser}
            navigationData={navigationData}
            mostCopiedCreators={mostCopiedCreators}
          />
          <Card
            variant="adminCardSolid"
            backgroundColor="primary.golden-light"
            borderColor="primary.tan"
            backgroundImage={[
              footerCardBackgroundMobile,
              null,
              footerCardBackgroundDesktop,
            ]}
            backgroundRepeat="no-repeat"
            backgroundSize="cover"
          >
            <Flex
              alignItems={["center", null]}
              flexDir={["column", null, "row"]}
            >
              <Flex
                flexDir="column"
                gap={pxToRem(16)}
                maxW={["full", null, "60%"]}
                px={[pxToRem(32), null, pxToRem(56)]}
                py={[pxToRem(40), null, pxToRem(30)]}
              >
                <Heading variant="adminH6" as="h2">
                  {t("teacherHomepageImproved.footer.heading")}
                </Heading>
                <Text variant="adminP2">
                  {t("teacherHomepageImproved.footer.description")}
                </Text>
                <Button
                  variant="adminButtonWhite"
                  w="fit-content"
                  onClick={() => history.push("/t/public-library")}
                >
                  {t("teacherHomepageImproved.footer.button")}
                </Button>
              </Flex>
              <Box
                backgroundImage={[
                  footerCardImageMobile,
                  null,
                  footerCardImageDesktop,
                ]}
                w={[pxToRem(290), null, "50%"]}
                h={[pxToRem(275), null, pxToRem(273)]}
                backgroundSize="contain"
                backgroundRepeat="no-repeat"
                // adminCardSolid has py of admin.cards.xs,
                // make this image hug the bottom of the card
                mb="calc(-1*var(--chakra-space-admin-cards-xs))"
              />
            </Flex>
          </Card>
        </Flex>
      </TemplateWithCenteredHeroOneColumn>

      {filterFlyout}
      {standardsModal}

      <TopIndividualPracticersModal
        isOpen={isTopIndividualPracticersModalOpen}
        onClose={onTopIndividualPracticersModalClose}
        totalIndividualSessions={individualSessionsCount}
      />

      <TopSocialEventReactorsModal
        isOpen={isTopSocialEventReactorsModalOpen}
        onClose={onTopSocialEventReactorsModalClose}
        totalSocialEventReactions={socialEventReactionsCountNumber}
      />
    </>
  );
};
