import { Box, Flex, HStack } from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Dropdown, IOption } from "adminComponents/atoms/Dropdown";
import { Heading } from "adminComponents/atoms/Heading";
import { Icon } from "adminComponents/atoms/Icon";
import { Text } from "adminComponents/atoms/Text";
import { EmptyCard } from "adminComponents/molecules/EmptyCard";
import { PracticeSetCard } from "adminComponents/molecules/PracticeSetCard";
import { pxToRem } from "adminComponents/utils/pxToRem";
import {
  IClassroom,
  IPracticeSet,
  PracticeSetReportingSortBy,
} from "links/lib/types";

const sortOptions = [
  {
    value: PracticeSetReportingSortBy.MostRecent,
    valueString: PracticeSetReportingSortBy.MostRecent.toString(),
    labelKey: "sortByMostRecent",
  },
  {
    value: PracticeSetReportingSortBy.LowToHighAccuracy,
    valueString: PracticeSetReportingSortBy.LowToHighAccuracy.toString(),
    labelKey: "sortByLowToHighAccuracy",
  },
  {
    value: PracticeSetReportingSortBy.HighToLowAccuracy,
    valueString: PracticeSetReportingSortBy.HighToLowAccuracy.toString(),
    labelKey: "sortByHighToLowAccuracy",
  },
];

export interface IPracticeSetProps {
  accuracy?: number;
  practiceSet: IPracticeSet;
}

interface IProps {
  handleViewMorePracticeSets: () => void;
  practiceSetsData?: IPracticeSetProps[];
  showViewMorePracticeSets?: boolean;
  classroom: IClassroom;
  studentId: string;
}

export const PracticeSetsTab: React.FC<IProps> = ({
  handleViewMorePracticeSets,
  practiceSetsData,
  showViewMorePracticeSets,
  classroom,
  studentId,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const [sortBy, setSortBy] = useState(PracticeSetReportingSortBy.MostRecent);

  const sortedPracticeSets = useMemo(() => {
    return practiceSetsData?.sort((a, b) => {
      if (sortBy === PracticeSetReportingSortBy.MostRecent) {
        const aMostRecent = new Date(
          Math.max(
            ...[
              new Date(
                a.practiceSet.most_recent_live_practice_timestamp
              ).getTime(),
              new Date(
                a.practiceSet.most_recent_assignment_timestamp
              ).getTime(),
            ]
          )
        );
        const bMostRecent = new Date(
          Math.max(
            ...[
              new Date(
                b.practiceSet.most_recent_live_practice_timestamp
              ).getTime(),
              new Date(
                b.practiceSet.most_recent_assignment_timestamp
              ).getTime(),
            ]
          )
        );

        if (aMostRecent > bMostRecent) return -1;
        else if (aMostRecent < bMostRecent) return 1;
        else return 0;
      } else {
        const aAccuracy = a.accuracy || 0;
        const bAccuracy = b.accuracy || 0;

        if (sortBy === PracticeSetReportingSortBy.LowToHighAccuracy) {
          return aAccuracy < bAccuracy ? -1 : 1;
        } else if (sortBy === PracticeSetReportingSortBy.HighToLowAccuracy) {
          return aAccuracy < bAccuracy ? 1 : -1;
        } else {
          return 0;
        }
      }
    });
  }, [practiceSetsData, sortBy]);

  return (
    <Box mt={pxToRem(40)}>
      <Flex flexDir="column" mt={pxToRem(40)} gap={pxToRem(40)}>
        <Box>
          <Heading as="h2" variant="adminH3" mb={pxToRem(20)}>
            {t("studentDetailScreen.practiceSetsLabel")}
          </Heading>
          <Text variant="adminP1">
            {t("studentDetailScreen.practiceSetsDescription")}
          </Text>
        </Box>
        <Box>
          <HStack>
            <Text variant="adminP2">{t("common.sortBy")}</Text>
            <Box
              flex={["0.9", null, "0.25"]}
              zIndex="3"
              cursor={practiceSetsData?.length ? "pointer" : "not-allowed"}
            >
              <Dropdown
                isDisabled={!practiceSetsData?.length}
                handleChange={(e) => {
                  const newSortBy = sortOptions.find(
                    (opt) => opt.valueString === (e as IOption).value
                  );
                  if (newSortBy) setSortBy(newSortBy.value);
                }}
                options={sortOptions.map((opt) => ({
                  value: opt.valueString,
                  label: t(`studentDetailScreen.${opt.labelKey}`),
                }))}
                value={[
                  sortOptions.find((opt) => opt.value === sortBy) ||
                    sortOptions[0],
                ].map((val) => ({
                  value: val.valueString,
                  label: t(`studentDetailScreen.${val.labelKey}`),
                }))}
              />
            </Box>
          </HStack>
        </Box>
        {sortedPracticeSets?.map((practiceSetCardData, i) => {
          const { practiceSet } = practiceSetCardData;

          return (
            <Box key={i} mb={pxToRem(12)}>
              <PracticeSetCard
                {...practiceSetCardData}
                classroom={classroom}
                accuracyType="student"
                key={i}
                practiceSet={practiceSet}
                studentId={studentId}
                showStandardsInfo
                showMostRecentInteractionInfo
              />
            </Box>
          );
        })}
        {showViewMorePracticeSets && (
          <Flex mt={pxToRem(32)} justifyContent="center">
            <Button
              onClick={handleViewMorePracticeSets}
              variant="adminTextButtonLarge"
              rightIcon={
                <Icon
                  ml={pxToRem(11)}
                  width={pxToRem(12)}
                  height={pxToRem(7)}
                  icon="button_down_arrow"
                  iconColor="utility.link"
                />
              }
            >
              {t("classDetailTabs.ctaViewMore")}
            </Button>
          </Flex>
        )}
        {!practiceSetsData?.length && (
          <EmptyCard
            text={t("studentDetailEmptyCards.practiceSetsText")}
            title={t("studentDetailEmptyCards.practiceSetsTitle")}
          />
        )}
      </Flex>
    </Box>
  );
};
