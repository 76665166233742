import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { ICollection } from "../../types";

export interface IArgs {
  disabled?: boolean;
}

export interface IFetchCollectionsResponse {
  collections: Array<ICollection>;
}

/**
 * Fetches a list of collections
 * for the current user
 * @returns
 */
export default function useFetchCollections(
  args?: IArgs
): UseQueryResult<IFetchCollectionsResponse> {
  const axios = useAxios();

  const query = useQuery<IFetchCollectionsResponse>(
    "collections",
    async (): Promise<IFetchCollectionsResponse> => {
      return await axios
        .get(`/v1/collections`)
        .then((r) => r.data as IFetchCollectionsResponse);
    },
    {
      enabled: !args?.disabled,
    }
  );

  return query;
}
