import { hoverStyles, pxToRem } from "adminComponents/utils";

export const AdminStudentNavigationHeader = {
  parts: ["banner", "container", "divider", "logo", "menuButton", "mobileNav"],
  variants: {
    adminStudentNavigationHeader: ({
      isNavTransparent,
    }: {
      isNavTransparent: boolean;
    }): Record<string, unknown> => ({
      banner: {
        alignItems: "center",
        bgColor: isNavTransparent
          ? "transparent"
          : "utility.navigation-bg-blue",
        display: "flex",
        justifyContent: "space-between",
        position: "fixed",
        paddingX: { base: "admin.mobileXPadding", md: "admin.desktopXPadding" },
        height: {
          base: "admin.nav.mobileTopRowHeight",
          md: "admin.nav.desktopTopRowHeight",
        },
        transition: "background-color 250ms",
        zIndex: 9,
        a: {
          _first: {
            position: { base: "absolute", md: "static" },
            left: { base: "50%", md: "auto" },
            transform: { base: "translateX(-50%)", md: "translateX(0)" },
          },
        },
      },
      container: {
        paddingBottom: { base: pxToRem(80), md: pxToRem(124) },
      },
      divider: {
        "&&": {
          borderBottomWidth: pxToRem(3),
          borderColor: "primary.tan",
          mt: pxToRem(0),
          mb: pxToRem(14),
        },
      },
      logo: {
        borderColor: "transparent",
        borderRadius: pxToRem(4),
        borderWidth: pxToRem(2),
        boxSizing: "content-box",
        _focus: {
          outline: "none",
        },
        _focusVisible: {
          borderColor: "utility.focus",
        },
      },
      menuButton: {
        bgColor: "transparent",
        borderRadius: pxToRem(4),
        m: 0,
        p: 0,
        _hover: hoverStyles({
          borderColor: "transparent",
        }),
      },
      mobileNav: {
        button: {
          _focus: {
            outline: "none",
          },
          _focusVisible: {
            borderWidth: pxToRem(2),
            borderColor: "utility.focus",
          },
        },
        a: {
          borderColor: "transparent",
          borderRadius: pxToRem(4),
          borderWidth: pxToRem(2),
          color: "primary.dark-gray",
          _focus: {
            boxShadow: "none",
          },
          _focusVisible: {
            borderColor: "utility.focus",
          },
          _hover: hoverStyles({
            bgColor: "primary.light-gray",
            textDecoration: "none",
          }),
        },
      },
    }),
  },
};
