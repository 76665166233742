export const colors = {
  sessionSecondary: {
    light: "#F1E9DA",
    base: "#DABEA3",
  },
  game: {
    "timer-red": "#D1522A",
  },
  team: {
    "03": "#686BA6",
    "05": "#A917A2",
    "13": "#770051",
  },
  map: {
    "progress-blue": "#00A3DE",
    "locked-dark-gray": "#708472",
    "locked-gray": "#a9b7aa",
  },
  link: {
    "link-blue": "#006D77",
    "link-hover": "#002C30",
  },
  button: {
    primary: "#D9DC40",
    secondary: "#FFFFFF",
    border: "#F1A83F",
  },
  onlineStatus: {
    connected: "#9CC300",
    offline: "#D3D3D3",
    disconnected: "#D12A77",
  },
  overlay: {
    white: "#FFFFFF",
  },
  stage: {
    fallbackCurtain: "#064979",
    fallbackStage: "#864C19",
  },
  progress: {
    yellow: "#D9DC40",
  },
  light: {
    golden: "#FFC629",
    lightGolden: "#FFEEBF",
    white: "#FFFFFF",
    mediumGray: "#D3D3D3",
    lightGray: "#EAEAEA",
    background: "#A21B17",
  },
  bigBoard: {
    notHighlightedBackground: "#EADEC9",
  },
  quickPlay: {
    background: "#FFCD71",
    flame: "#EEB65C",
  },
  podium: {
    summaryCard: "#ECFCFC",
    summaryStat: "#2A64B6",
    carePackageBackground: "#F1D2F0",
    levelUpBackground: "#ecfcfc",
  },
};
