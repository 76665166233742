import { Box, keyframes } from "@chakra-ui/react";
import React from "react";

import { ColorScheme } from "adminComponents";
import { pxToRem } from "adminComponents/utils";

import { Icon } from "../Icon";

const pulse = keyframes`
  from {opacity: 0;}
  to {opacity: 1;}
`;

export interface ITypingIndicatorProps {
  size?: "sm" | "md" | "lg";
  color?: ColorScheme;
}

const boxSizeMap = {
  sm: pxToRem(10),
  md: pxToRem(16),
  lg: pxToRem(24),
};

const gapSizeMap = {
  sm: pxToRem(4),
  md: pxToRem(6),
  lg: pxToRem(8),
};

export const TypingIndicator: React.FC<ITypingIndicatorProps> = ({
  size = "md",
  color,
}) => {
  const pulseAnimation = `${pulse} infinite 1s ease-in-out`;

  const boxSize = boxSizeMap[size];

  return (
    <Box display="inline-flex" gap={gapSizeMap[size]}>
      <Icon
        iconColor={color}
        boxSize={boxSize}
        icon="circle_filled"
        animation={pulseAnimation}
        sx={{ animationDirection: "alternate" }}
      />
      <Icon
        iconColor={color}
        icon="circle_filled"
        boxSize={boxSize}
        animation={pulseAnimation}
        sx={{ animationDirection: "alternate", animationDelay: "333ms" }}
      />
      <Icon
        iconColor={color}
        icon="circle_filled"
        boxSize={boxSize}
        animation={pulseAnimation}
        sx={{ animationDirection: "alternate", animationDelay: "666ms" }}
      />
    </Box>
  );
};
