import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";

export interface IFetchOrganizationPracticedCertifiedItemsCountParams {
  days_ago: number;
  include_previous_period?: boolean;
  include_top_org_check?: boolean;
}

export interface IFetchOrganizationPracticedCertifiedItemCountResponse {
  practiced_certified_item_count: string;
  previous_period_practiced_certified_item_count: string;
  top_half_of_all_organizations: boolean;
  top_third_of_all_organizations: boolean;
}

export default function useFetchOrganizationPracticedCertifiedItemsCount({
  days_ago,
  include_previous_period = false,
  include_top_org_check = false,
}: IFetchOrganizationPracticedCertifiedItemsCountParams): UseQueryResult<
  IFetchOrganizationPracticedCertifiedItemCountResponse,
  unknown
> {
  const axios = useAxios();

  const query = useQuery<IFetchOrganizationPracticedCertifiedItemCountResponse>(
    [
      "org-certified-items-practiced-count",
      {
        days_ago,
        include_previous_period,
        include_top_org_check,
      },
    ],
    async (): Promise<IFetchOrganizationPracticedCertifiedItemCountResponse> => {
      return await axios
        .get(
          `/v1/organizations/practice-sets/sessions/certified-items-practiced-count`,
          {
            params: {
              days_ago,
              include_previous_period,
              include_top_org_check,
            },
          }
        )
        .then((r) => {
          return r.data as IFetchOrganizationPracticedCertifiedItemCountResponse;
        });
    }
  );

  return query;
}
