import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { DeleteAssignmentModal } from "adminComponents/molecules/DeleteAssignmentModal";
import { useErrorToast, useShowToast } from "adminComponents/utils/toast";
import { useAnalytics } from "lib/contexts/analytics";
import { useDeleteAssignment as useDeleteAssignmentMutation } from "links/lib/features/assignments";
import { AnalyticsEvent, IAssignment } from "links/lib/types";

export interface IResult {
  modal: React.ReactElement;
  handleDeleteIntent: (assignment: IAssignment) => void;
}

export interface IArgs {
  onConfirm?: (assignment: IAssignment) => void;
  onSuccess?: () => void;
  classroom_id: string;
  deleteIntentAnalyticsEvent?: AnalyticsEvent;
  deleteConfirmAnalyticsEvent?: AnalyticsEvent;
}

const useDeleteAssignment = ({
  onConfirm,
  onSuccess,
  classroom_id,
  deleteIntentAnalyticsEvent,
  deleteConfirmAnalyticsEvent,
}: IArgs): IResult => {
  const showToast = useShowToast();
  const { trackEvent } = useAnalytics();
  const { t } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "classroomCommon.assignmentDelete",
  });

  const [state, setState] = useState<{
    isModalOpen: boolean;
  }>({ isModalOpen: false });
  const [currentAssignment, setCurrentAssignment] =
    useState<IAssignment | null>(null);

  const _onSuccess = useCallback(() => {
    showToast(t("successToast"));
    setState({ isModalOpen: false });
    onSuccess?.();
  }, [showToast, t, onSuccess]);

  const deleteAssignment = useDeleteAssignmentMutation({
    onSuccess: _onSuccess,
  });

  useErrorToast(deleteAssignment.error);

  const handleDeleteIntent = useCallback(
    (assignment: IAssignment) => {
      setCurrentAssignment(assignment);

      if (deleteIntentAnalyticsEvent) {
        trackEvent(deleteIntentAnalyticsEvent, {
          classroomId: classroom_id,
          assignmentId: assignment.id,
        });
      }

      setState({
        isModalOpen: true,
      });
    },
    [classroom_id, deleteIntentAnalyticsEvent, trackEvent]
  );

  const handleModalClose = useCallback(
    (isConfirmed?: boolean) => {
      if (!isConfirmed || !currentAssignment) {
        setState({ isModalOpen: false });
        return;
      }

      deleteAssignment.mutate({ id: currentAssignment.id, classroom_id });

      if (deleteConfirmAnalyticsEvent) {
        trackEvent(deleteConfirmAnalyticsEvent, {
          classroomId: classroom_id,
          assignmentId: currentAssignment.id,
        });
      }
      onConfirm?.(currentAssignment);
    },
    [
      currentAssignment,
      onConfirm,
      classroom_id,
      deleteAssignment,
      deleteConfirmAnalyticsEvent,
      trackEvent,
    ]
  );

  const modal = useMemo(() => {
    return (
      <DeleteAssignmentModal
        handleClose={handleModalClose}
        isLoading={deleteAssignment.isLoading}
        isOpen={state.isModalOpen}
      />
    );
  }, [state.isModalOpen, handleModalClose, deleteAssignment.isLoading]);

  return useMemo(
    () => ({
      handleDeleteIntent,
      modal,
    }),
    [handleDeleteIntent, modal]
  );
};

export { useDeleteAssignment };
