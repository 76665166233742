import React, { createContext, useEffect, useMemo, useState } from "react";

import { localStoreAuthKeyName } from "../constants";
import useFetchCurrentUser from "../features/users/useFetchCurrentUser";
import useInitializeUserRole from "../features/users/useInitializeUserRole";
import { useAxios } from "../hooks/useAxios";
import { useLocalStore } from "../hooks/useLocalStore";
import {
  IAgreement,
  IFeatureFlags,
  ILicense,
  ISharingSettings,
  IUser,
} from "../types";

export interface IAuthState {
  authRequiredAgreements?: Array<IAgreement>;
  authFeatureFlags?: IFeatureFlags;
  authSharingSettings?: ISharingSettings;
  authUser?: IUser;
  authToken?: string;
  authLicense?: ILicense;
  hasNoPremiumAccess: boolean;
  isGhostUser: boolean;
  isAuthLoading: boolean;
}

export interface IAuthContext {
  authRequiredAgreements?: Array<IAgreement>;
  authSharingSettings?: ISharingSettings;
  authUser?: IUser;
  authToken?: string;
  authLicense?: ILicense;
  hasNoPremiumAccess: boolean;
  isGhostUser: boolean;
  isAuthLoading: boolean;
  isFeatureEnabled(flag: string): boolean;
  getFeatureFlags: () => IFeatureFlags | undefined;
  setAuth: (state: IAuthState) => void;
  setAuthToken: (token: string) => void;
  refetchUser: () => void;
  initializeUserRole: (
    isStudent: boolean,
    accepted_agreement_ids?: Array<string>
  ) => void;
}

export const emptyAuthContext = {
  setAuth: (): void => {
    // do nothing
  },
  setAuthToken: (): void => {
    // do nothing
  },
  refetchUser: (): void => {
    // do nothing
  },
  initializeUserRole: (): void => {
    // do nothing
  },
  isFeatureEnabled: (): boolean => {
    return false;
  },
  getFeatureFlags: (): IFeatureFlags | undefined => {
    return undefined;
  },
  hasNoPremiumAccess: false, // Defaults to open
  isGhostUser: false,
  isAuthLoading: true,
};

export const AuthContext = createContext<IAuthContext>(emptyAuthContext);

export const AuthProvider: React.FC = ({ children }) => {
  const [state, setState] = useState<IAuthState>({
    hasNoPremiumAccess: false,
    isGhostUser: false,
    isAuthLoading: true,
  });
  const localStore = useLocalStore();
  const axios = useAxios();

  const {
    data: currentUserData,
    execute: fetchCurrentUser,
    isLoading: isFetchCurrentUserLoading,
  } = useFetchCurrentUser();

  const {
    execute: initializeUserRole,
    isLoading: isInitializeUserRoleLoading,
  } = useInitializeUserRole();

  useEffect(() => {
    if (currentUserData?.user) {
      setState((state) => ({
        ...state,
        authUser: currentUserData.user,
        authRequiredAgreements: currentUserData.required_agreements,
        authFeatureFlags: currentUserData.features,
        authSharingSettings: currentUserData.sharing_settings,
        authLicense: currentUserData.license,
        hasNoPremiumAccess: !currentUserData.license.has_premium_access,
        isGhostUser: currentUserData.user.ghost_user_email != "",
        isAuthLoading: false,
      }));
    }
  }, [currentUserData]);

  const value = useMemo(
    () => ({
      ...state,
      isAuthLoading: isFetchCurrentUserLoading || isInitializeUserRoleLoading,
      setAuth: (state: IAuthState) => setState(state),
      setAuthToken: (token: string) => {
        localStore.setItem(localStoreAuthKeyName, token);

        // update axios client to use current JWT
        // for authorization header on all requests
        axios.defaults.headers.common["Authorization"] = token;
        setState({ ...state, authToken: token });
      },
      refetchUser: () => fetchCurrentUser(),
      isFeatureEnabled: (flag: string) => {
        return state.authFeatureFlags?.[flag] ?? false;
      },
      getFeatureFlags: (): IFeatureFlags | undefined => {
        return state.authFeatureFlags;
      },
      initializeUserRole: (
        isStudent: boolean,
        accepted_agreement_ids?: Array<string>
      ) => {
        initializeUserRole({
          isStudent,
          accepted_agreement_ids,
        }).then((res) => {
          const token = res?.token;

          if (token) {
            localStorage.setItem(localStoreAuthKeyName, token);

            // update axios client to use current JWT
            // for authorization header on all requests
            axios.defaults.headers.common["Authorization"] = token;
          }

          fetchCurrentUser();
        });
      },
    }),
    [
      state,
      fetchCurrentUser,
      localStore,
      initializeUserRole,
      axios.defaults.headers.common,
      isFetchCurrentUserLoading,
      isInitializeUserRoleLoading,
    ]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
