import React, { createContext, useEffect, useMemo, useState } from "react";
import { Redirect, Route } from "react-router-dom";

import { regionMap } from "../constants";
import useFetchGeo, { IFetchGeoResponse } from "../features/geo/useFetchGeo";

interface IGeoStateWithShortRegion extends IFetchGeoResponse {
  region_short: string;
}

export interface IGeoState {
  geo?: IGeoStateWithShortRegion;
}

export interface IGeoContext {
  geo?: IGeoStateWithShortRegion;
}

export const GeoContext = createContext<IGeoContext>({});

export const GeoProvider: React.FC = ({ children, ...rest }) => {
  const [state, setState] = useState<IGeoState>({});

  const geo = useFetchGeo();

  useEffect(() => {
    if (geo.isSuccess) {
      // fetchGeo returns a long name region, e.g. "North Carolina" which we translate into "NC"
      // if possible and append as "region_short"
      const geoUSRegionShortIndex = Object.values(regionMap["US"]).indexOf(
        geo.data?.region || ""
      );
      const shortRegion =
        geoUSRegionShortIndex !== -1
          ? Object.keys(regionMap["US"])[geoUSRegionShortIndex] || ""
          : "";

      const geoWithShortRegion: IFetchGeoResponse & { region_short: string } = {
        ...geo.data,
        city: geo.data?.city || "",
        country_long: geo.data?.country_long || "",
        country_short: geo.data?.country_short || "",
        region: geo.data?.region || "",
        ip_address: geo.data?.ip_address || "",
        region_short: shortRegion,
      };
      setState((state) => ({
        ...state,
        geo: geoWithShortRegion,
      }));
    }
  }, [geo.isSuccess, geo.data]);

  const value = useMemo(
    () => ({
      ...state,
    }),
    [state]
  );

  const renderRoute = () => {
    if (
      state.geo?.status_code === 451 &&
      location.pathname !== "/unavailable" &&
      location.pathname !== "/t/account/notifications/unsubscribe"
    ) {
      return <Redirect to="/unavailable" />;
    }

    return <>{children}</>;
  };

  return (
    <GeoContext.Provider value={value}>
      <Route {...rest} render={renderRoute} />
    </GeoContext.Provider>
  );
};
