import { Box } from "@chakra-ui/react";
import { camelCase } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { animated, useTransition } from "react-spring";

import { quickPlayCarePackageRoundIntroDurationMillis } from "links/lib/constants";
import { useSessionActions } from "links/lib/contexts/sessionActions";
import { useSessionRoundGroupState } from "links/lib/contexts/sessionRoundGroupState";
import { useSessionRoundState } from "links/lib/contexts/sessionRoundState";
import { useSessionScene } from "links/lib/contexts/sessionScene";
import {
  CoopDragsortVariantData,
  CoopDrawVariantData,
  PracticeSessionItemVariantType,
  QuestionType,
  SessionGameState,
  SessionGameType,
  SessionScene,
  UserRole,
} from "links/lib/types";
import { QuestionLayout } from "sessionComponents/atoms/QuestionLayout";
import { RoundNumberSticker } from "sessionComponents/atoms/RoundNumberSticker";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { useStudent } from "sessionComponents/contexts/student";
import { useConvertedDrawOptions } from "sessionComponents/hooks/useConvertedDrawOptions";
import { useDelayedRoundIntro } from "sessionComponents/hooks/useDelayedRoundIntro";
import { useGetStudentConfirmButtonDisabled } from "sessionComponents/hooks/useGetStudentConfirmButtonDisabled";
import { useGroupUsers } from "sessionComponents/hooks/useGroupUsers";
import { useRoundActions } from "sessionComponents/hooks/useRoundActions";
import { useRoundContentTransition } from "sessionComponents/hooks/useRoundContentTransition";
import { useTeammateSoundEffects } from "sessionComponents/hooks/useTeammateSoundEffects";
import { useTextMatchPhase } from "sessionComponents/hooks/useTextMatchPhase";
import { ClassificationResponseGroup } from "sessionComponents/molecules/ClassificationResponseGroup";
import { ConfirmAnswerButton } from "sessionComponents/molecules/ConfirmAnswerButton";
import { DrawReviewOptions } from "sessionComponents/organisms/DrawReviewOptions";
import { QuestionBoxRenderer } from "sessionComponents/organisms/QuestionBoxRenderer";
import { StudentDiagramOptions } from "sessionComponents/organisms/StudentDiagramOptions";
import { StudentDrawing } from "sessionComponents/organisms/StudentDrawing";
import { StudentMultipleChoiceOptions } from "sessionComponents/organisms/StudentMultipleChoiceOptions";
import { StudentTextMatchOptions } from "sessionComponents/organisms/StudentTextMatchOptions";
import { SplashScene, SplashType } from "sessionComponents/scenes/SplashScene";
import { fullScreenStyles } from "sessionComponents/theme";
import { useDetectOrientation } from "sessionComponents/theme/hooks/useDetectOrientation";
import { getItemInstructions } from "sessionComponents/utils/getItemInstructions";
import { useSafeConnectedUserArray } from "sharedComponents/hooks/useSafeConnectedUserArray";

type StudentRoundProps = {
  teacherPreviewConfirmAreaComponent?: JSX.Element;
  disableRoundIntro?: boolean;
  gameState?: SessionGameState;
};

const AnimatedBox = animated(Box);

export const StudentQuestionRound: React.FC<StudentRoundProps> = ({
  teacherPreviewConfirmAreaComponent,
  disableRoundIntro,
  gameState,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [selectedDrawOption, setSelectedDrawOption] = useState("");
  const {
    match: { imageResponseSize },
  } = useBreakpoints();
  const { isPortrait } = useDetectOrientation();
  const scene = useSessionScene();
  const roundState = useSessionRoundState();
  const roundGroupState = useSessionRoundGroupState();
  const student = useStudent();
  const connectedUsers = useSafeConnectedUserArray();
  const connectedStudents = useMemo(
    () => connectedUsers.filter((u) => u.role === UserRole.Student),
    [connectedUsers]
  );
  const groupUsers = useGroupUsers(student.session_group_id);
  useTeammateSoundEffects(student);
  const { setSortChoiceZone } = useSessionActions();
  const { onConfirm, onTextResponse, onActiveTyping, onDrawingVoteSubmit } =
    useRoundActions();

  const { isTextMatch, isTextSubmissionPhase, hasUserSubmittedText } =
    useTextMatchPhase(student.session_group_id, student.id);

  const { variant, practice_set_session_item, variant_data } = roundState;

  const drawVariantData = variant_data as CoopDrawVariantData;
  const isDrawVotingRound = scene === SessionScene.Voting;
  const isStudentDrawingRound =
    variant === PracticeSessionItemVariantType.CoopDraw && !isDrawVotingRound;

  const userDrawVote = useMemo(
    () =>
      drawVariantData?.coop_draw?.votes.find(
        (vote) => vote.user_id === student.id
      ),
    [drawVariantData?.coop_draw?.votes, student.id]
  );

  const { t } = useTranslation("session", { useSuspense: false });

  const isQuickPlay = gameState?.game_type === SessionGameType.QuickPlay;
  const isQuickPlayCarePackageRound =
    isQuickPlay &&
    gameState.quick_play_state.care_package_rounds.includes(
      roundState.round_number
    );
  const showRoundIntro = useDelayedRoundIntro({
    disableRoundIntro:
      disableRoundIntro && isQuickPlay && !isQuickPlayCarePackageRound,
    isQuickPlayCarePackageRound,
  });

  const immediateTransition = useTransition([scene], {
    from: { opacity: 1 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    immediate: true,
  });

  const roundTransition = useRoundContentTransition({ showRoundIntro });

  const roundContentTransition = disableRoundIntro
    ? immediateTransition
    : roundTransition;

  const instructionKey = getItemInstructions(practice_set_session_item);

  const instructions = instructionKey
    ? t(`practiceSets.instructions.${instructionKey}`)
    : "";

  // get group canvases as array of multichoice options
  const convertedDrawOptions = useConvertedDrawOptions(
    drawVariantData,
    imageResponseSize
  );

  const drawOptions = useMemo(() => {
    return convertedDrawOptions.filter((opt) => {
      const isOwnGroupDrawing = opt.id === student.session_group_id;
      const groupIsConnected = connectedStudents.some(
        (u) => u.session_group_id === opt.id
      );
      const validImageUrl = !!opt.image_url;
      return !isOwnGroupDrawing && groupIsConnected && validImageUrl;
    });
  }, [student.session_group_id, connectedStudents, convertedDrawOptions]);

  // if there is only one draw option to vote for (eg, there are only 2 groups in the game), automatically vote for it
  useEffect(() => {
    if (
      isDrawVotingRound &&
      !!drawOptions &&
      drawOptions.length === 1 &&
      !selectedDrawOption
    ) {
      const targetId = drawOptions[0].id;
      setSelectedDrawOption(targetId);
      onDrawingVoteSubmit(targetId);
    }
  }, [isDrawVotingRound, drawOptions, onDrawingVoteSubmit, selectedDrawOption]);

  const studentAnswerOptions = useMemo(() => {
    switch (variant) {
      case PracticeSessionItemVariantType.CoopRadios:
      case PracticeSessionItemVariantType.CoopChecks:
        return <StudentMultipleChoiceOptions />;
      case PracticeSessionItemVariantType.CoopTextMatch:
      case PracticeSessionItemVariantType.CoopTextMatchNumeric:
        return (
          <StudentTextMatchOptions
            inputValue={inputValue}
            setInputValue={setInputValue}
            onActiveTyping={onActiveTyping}
          />
        );
      case PracticeSessionItemVariantType.CoopDragsort:
        return practice_set_session_item.question_type ===
          QuestionType.Diagram ? (
          <StudentDiagramOptions />
        ) : (
          <ClassificationResponseGroup
            practiceSetSessionItem={practice_set_session_item}
            roundGroupState={roundGroupState}
            variantData={variant_data as CoopDragsortVariantData}
            onDragStop={setSortChoiceZone}
            groupUsers={groupUsers}
          />
        );
      case PracticeSessionItemVariantType.CoopDraw:
        return isDrawVotingRound ? (
          <DrawReviewOptions
            isTeacher={false}
            optionsSelectable
            selectedOptionId={selectedDrawOption || userDrawVote?.group_id}
            handleDrawOptionClick={
              userDrawVote ? undefined : setSelectedDrawOption
            }
            drawOptions={drawOptions}
          />
        ) : (
          <StudentDrawing student={student} />
        );
      default:
        return <></>;
    }
  }, [
    inputValue,
    onActiveTyping,
    practice_set_session_item,
    roundGroupState,
    setSortChoiceZone,
    student,
    variant,
    variant_data,
    isDrawVotingRound,
    selectedDrawOption,
    drawOptions,
    userDrawVote,
    groupUsers,
  ]);

  const handleConfirmSelection = () => {
    onConfirm();
  };

  const handleTextSubmit = () => {
    const trimmedValue = inputValue.trim();
    onTextResponse(trimmedValue);
  };

  const handleSubmitVote = () => {
    onDrawingVoteSubmit(selectedDrawOption);
  };

  const isTextSubmissionDisabled = useMemo(() => {
    const isNumericTextMatch =
      variant === PracticeSessionItemVariantType.CoopTextMatchNumeric;
    if (isNumericTextMatch) {
      const numRegex = new RegExp("[0-9]");
      return !numRegex.test(inputValue);
    } else return !inputValue.trim().length;
  }, [inputValue, variant]);

  const showTextSubmissionLabel = isTextMatch && isTextSubmissionPhase;

  const confirmButtonDisabled = useGetStudentConfirmButtonDisabled({
    variant,
    student,
    isTextSubmissionDisabled,
    isTextSubmissionPhase: isTextMatch && isTextSubmissionPhase,
    roundGroupState,
    isDrawVotingRound,
    userHasSelectedDrawing: !!selectedDrawOption,
  });

  const getConfirmAction = () => {
    switch (variant) {
      case PracticeSessionItemVariantType.CoopChecks:
      case PracticeSessionItemVariantType.CoopRadios:
      case PracticeSessionItemVariantType.CoopDragsort:
        return handleConfirmSelection;
      case PracticeSessionItemVariantType.CoopTextMatch:
      case PracticeSessionItemVariantType.CoopTextMatchNumeric:
        return isTextSubmissionPhase
          ? handleTextSubmit
          : handleConfirmSelection;
      case PracticeSessionItemVariantType.CoopDraw:
        return isDrawVotingRound ? handleSubmitVote : handleConfirmSelection;
      default:
        return handleConfirmSelection;
    }
  };

  const onSubmit = () => {
    getConfirmAction()();
  };

  const isDragsortRound =
    variant === PracticeSessionItemVariantType.CoopDragsort;

  //hide confirm button in portrait mode dragsort rounds until all options are placed
  const showConfirmButton = useMemo(() => {
    if (!isPortrait || !isDragsortRound) {
      return true;
    } else {
      return roundGroupState.dragsort?.items.every(
        (item) => item.zone_id !== "0"
      );
    }
  }, [isPortrait, isDragsortRound, roundGroupState.dragsort?.items]);

  // Wait until the care package animation finishes before showing the round sticker
  const roundNumberStickerDelayMs =
    isQuickPlay &&
    gameState.quick_play_state.care_package_rounds.includes(
      roundState.round_number
    )
      ? quickPlayCarePackageRoundIntroDurationMillis
      : 0;

  return (
    <>
      {roundContentTransition(({ opacity }, item) =>
        item === "splash" ? (
          <AnimatedBox
            {...fullScreenStyles}
            style={{
              opacity,
            }}
          >
            <SplashScene
              splashType={SplashType.RoundStart}
              animationContainerStyle={{ opacity }}
            />
          </AnimatedBox>
        ) : (
          <AnimatedBox
            w="full"
            h="full"
            display="flex"
            flexDirection="column"
            overflow="hidden"
            style={{ opacity }}
          >
            <QuestionLayout
              outerGame={gameState?.game_type}
              isDragsortItem={isDragsortRound}
              // todo animations? vv
              questionComponent={
                <QuestionBoxRenderer
                  variant={variant}
                  practice_set_session_item={practice_set_session_item}
                  variantData={variant_data}
                  instructions={instructions}
                  dragsortItems={roundGroupState.dragsort?.items || []}
                  groupUsers={groupUsers}
                />
              }
              answerOptionsComponent={studentAnswerOptions}
              answerOptionsContainerHeight={
                isStudentDrawingRound ? "100%" : undefined
              }
              confirmAreaComponent={
                teacherPreviewConfirmAreaComponent ||
                (showConfirmButton ? (
                  <ConfirmAnswerButton
                    studentId={student.id}
                    groupId={student.session_group_id}
                    disabled={confirmButtonDisabled}
                    translationKeyPrefix={getTranslationKeyPrefix(variant)}
                    showTextSubmissionLabel={showTextSubmissionLabel}
                    userSubmittedTextChoice={hasUserSubmittedText}
                    isTextSubmissionPhase={isTextSubmissionPhase}
                    isDrawVoting={isDrawVotingRound}
                    drawVariantData={drawVariantData}
                    showClassificationLabel={
                      practice_set_session_item.question_type ===
                      QuestionType.Classify
                    }
                    handleConfirm={onSubmit}
                  />
                ) : undefined)
              }
            />
          </AnimatedBox>
        )
      )}
      {!isDrawVotingRound && (
        <RoundNumberSticker
          roundNumber={roundState.round_number}
          delayMs={roundNumberStickerDelayMs}
        />
      )}
    </>
  );
};

const getTranslationKeyPrefix = (variant: PracticeSessionItemVariantType) => {
  return camelCase(
    Object.keys(PracticeSessionItemVariantType).find(
      (key) =>
        PracticeSessionItemVariantType[
          key as keyof typeof PracticeSessionItemVariantType
        ] === variant
    )
  );
};
