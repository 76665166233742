import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Modal } from "adminComponents/atoms/Modal";
import { Tabs } from "adminComponents/atoms/Tabs";
import { useEditPracticeSetMediaModalContext } from "adminComponents/contexts/EditPracticeSetMediaModalContext";
import { ImageLibrary } from "adminComponents/molecules/ImageLibrary";
import { toFile } from "adminComponents/molecules/ImageLibrary/utils/toFile";
import { ImageUpload } from "adminComponents/molecules/ImageUpload";
import { RecordAudio } from "adminComponents/molecules/RecordAudio";
import { TextToAudio } from "adminComponents/molecules/TextToAudio";
import { UrlUpload } from "adminComponents/molecules/UrlUpload";
import { useAnalytics } from "lib/contexts/analytics";
import { AnalyticsEvent } from "links/lib/types";

interface AddMediaModalProps {
  isOpen: boolean;
  searching: boolean;
  loading: boolean;
  uploading: boolean;
  uploadError: boolean;
  imagesFromLibrary: string[];
  imageUrlToAdd: string;
  imageAltText?: string;
  audioUrlToAdd?: string;
  videoUrlToAdd?: string;
  invalidUrl: boolean;
  invalidFormat: boolean;
  invalidFormatErrorType: string;
  textToSpeechError: boolean;
  handleClose: () => void;
  handleSearch: (search: string) => void;
  handleUploadImage: (image: File) => Promise<void>;
  handleUploadImageFromUrl: (link: string) => Promise<void>;
  handleConvertTextToSpeech: (text: string) => Promise<void>;
  handleUploadAudio: (audio: File) => Promise<void>;
  handlePickImageFromLibrary: (imageUrl: string) => void;
  handleResetImagesFromLibrary: () => void;
  handleInsertMedia: (
    imageUrl: string,
    altText: string,
    audioUrl?: string,
    videoUrl?: string
  ) => void;
  handleRemoveImage: () => void;
  handleRemoveAudio: () => void;
  handleInvalidFormat: () => void;
  imageOnly?: boolean;
  presetImages?: Map<string, string[]>;
}

export const AddMediaModal: React.FC<AddMediaModalProps> = ({
  searching,
  isOpen,
  imageUrlToAdd,
  imageAltText,
  audioUrlToAdd,
  videoUrlToAdd,
  loading,
  uploading,
  uploadError,
  imagesFromLibrary,
  invalidUrl,
  invalidFormat,
  invalidFormatErrorType,
  textToSpeechError,
  handleClose,
  handleSearch,
  handleUploadImage,
  handleUploadImageFromUrl,
  handleConvertTextToSpeech,
  handleUploadAudio,
  handleInsertMedia,
  handleRemoveImage,
  handleRemoveAudio,
  handlePickImageFromLibrary,
  handleResetImagesFromLibrary,
  handleInvalidFormat,
  imageOnly = false,
  presetImages,
}) => {
  const {
    audioUrlToAdd: unsavedAudioUrl,
    imageUrlToAdd: unsavedImageUrl,
    imageAltText: unsavedImageAltText,
    videoUrlToAdd: unsavedVideoUrl,
    showPremiumMarker,
  } = useEditPracticeSetMediaModalContext();

  const { t } = useTranslation("admin", { useSuspense: false });
  const { trackEvent } = useAnalytics();

  const [tabIndex, setTabIndex] = useState<number>(0);

  const tabs = [
    {
      label: t("addMediaModal.imageUpload.tabTitle"),
      isPremium: false,
      content: (
        <ImageUpload
          searching={searching}
          imageUrlToAdd={imageUrlToAdd || unsavedImageUrl}
          imageAltText={imageAltText || unsavedImageAltText}
          videoUrlToAdd={videoUrlToAdd || unsavedVideoUrl}
          loading={loading}
          uploading={uploading}
          uploadError={uploadError}
          imagesFromLibrary={imagesFromLibrary}
          invalidFormat={invalidFormat}
          invalidFormatErrorType={invalidFormatErrorType}
          handleSearch={handleSearch}
          handleUploadImage={handleUploadImage}
          handleInsertMedia={handleInsertMedia}
          handleRemoveImage={handleRemoveImage}
          handlePickImageFromLibrary={handlePickImageFromLibrary}
          handleResetImagesFromLibrary={handleResetImagesFromLibrary}
          handleInvalidFormat={handleInvalidFormat}
          imageOnly={imageOnly}
        />
      ),
    },
    {
      label: t("addMediaModal.urlUpload.tabTitle"),
      isPremium: showPremiumMarker,
      content: (
        <UrlUpload
          imageUrlToAdd={imageUrlToAdd || unsavedImageUrl}
          imageAltText={imageAltText || unsavedImageAltText}
          videoUrlToAdd={videoUrlToAdd || unsavedVideoUrl}
          loading={loading}
          uploading={uploading}
          uploadError={uploadError}
          invalidUrl={invalidUrl}
          handleUploadImageFromUrl={handleUploadImageFromUrl}
          handleInsertMedia={handleInsertMedia}
          handleRemoveImage={handleRemoveImage}
          imageOnly={imageOnly}
        />
      ),
    },
  ];

  const onSelect = (image: string, category: string) => {
    toFile(image).then((file: File) => {
      handleUploadImage(file);
      setTabIndex(0);
    });
    trackEvent(
      AnalyticsEvent.TeacherDashboard_PracticeSetDetail_QuestionFlyout_AddMediaModal_AddImageFromLibrary,
      {
        image_category: category,
      }
    );
  };

  if (presetImages) {
    tabs.push({
      label: t("imageLibrary.title"),
      isPremium: showPremiumMarker,
      content: <ImageLibrary images={presetImages} onSelect={onSelect} />,
    });
  }

  if (!imageOnly) {
    tabs.push({
      label: t("addMediaModal.textToAudio.tabTitle"),
      isPremium: showPremiumMarker,
      content: (
        <TextToAudio
          audioUrlToAdd={audioUrlToAdd || unsavedAudioUrl}
          uploading={uploading}
          uploadError={uploadError}
          textToSpeechError={textToSpeechError}
          handleConvertTextToSpeech={handleConvertTextToSpeech}
          handleInsertMedia={handleInsertMedia}
          handleRemoveAudio={handleRemoveAudio}
        />
      ),
    });
    tabs.push({
      label: t("addMediaModal.recordAudio.tabTitle"),
      isPremium: showPremiumMarker,
      content: (
        <RecordAudio
          audioUrlToAdd={audioUrlToAdd || unsavedAudioUrl}
          uploading={uploading}
          uploadError={uploadError}
          handleUploadAudio={handleUploadAudio}
          handleInsertMedia={handleInsertMedia}
        />
      ),
    });
  }

  return (
    <Modal
      title={t("common.addMedia")}
      isOpen={isOpen}
      onClose={handleClose}
      size="4xl"
      showBackButton={false}
    >
      <Tabs
        variant="adminAddMediaModal"
        showCheck
        tabData={tabs}
        tabIndex={tabIndex}
        handleChange={(tabIndex) => setTabIndex(tabIndex)}
      />
    </Modal>
  );
};
