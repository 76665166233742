import { pxToRem } from "adminComponents/utils/pxToRem";

export const AdminPracticeSetInformationCardPublicBody = {
  baseStyle: {
    container: {
      paddingTop: { base: "admin.cards.small", md: "admin.cards.large" },
      paddingBottom: { base: "admin.cards.small", md: "admin.cards.medium" },
    },
    header: {
      position: "absolute",
      top: "admin.cards.small",
      right: "admin.cards.small",
      display: "flex",
      justifyContent: "end",
      gap: pxToRem(12),
      zIndex: 10,
    },
    body: {
      paddingTop: pxToRem(20),
      paddingBottom: { base: "admin.cards.small", md: "admin.cards.medium" },
      paddingX: { base: "admin.cards.small", md: "admin.cards.large" },
      display: { md: "flex" },
      gap: { md: "admin.cards.small" },
    },
    footer: {
      display: { md: "flex" },
      justifyContent: { md: "space-between" },
      paddingTop: "admin.cards.small",
      paddingX: { base: "admin.cards.small", md: "admin.cards.large" },
    },
    coverImageContainer: {
      display: "flex",
      justifyContent: "center",
      position: "relative",
    },
    divider: {
      marginX: { base: pxToRem(6.5), md: pxToRem(-1) },
    },
    rightTags: {
      justify: { base: "start", md: "end" },
      spacingX: { base: pxToRem(8), md: pxToRem(12) },
      spacingY: { base: pxToRem(8), md: pxToRem(10) },
    },
    buttonWrapper: {
      gap: pxToRem(12),
      flexWrap: "wrap",
      marginTop: pxToRem(24),
      display: "flex",
      justifyContent: { base: "center", md: "flex-start" },
    },
    button: {
      maxWidth: pxToRem(285),
    },
    authorContainer: {
      gap: [pxToRem(8), null, pxToRem(12)],
      alignItems: "center",
      marginTop: pxToRem(12),
    },
    certifiedContainer: {
      position: "absolute",
      height: pxToRem(30),
      width: pxToRem(30),
      transform: [
        `translate(${pxToRem(35)}, ${pxToRem(70)})`,
        null,
        `translate(${pxToRem(50)}, ${pxToRem(104)})`,
      ],
    },
  },
};
