import { Box, Flex, FormControl, Image } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Dropdown, IOption } from "adminComponents/atoms/Dropdown";
import { FormLabel } from "adminComponents/atoms/FormLabel";
import { Heading } from "adminComponents/atoms/Heading";
import { FullStudentAvatar } from "adminComponents/molecules/FullStudentAvatar";
import {
  IProps as INavigationProps,
  StudentNavigationHeader,
} from "adminComponents/molecules/StudentNavigationHeader";
import { StudentTemplateWithTwoColumns } from "adminComponents/templates/StudentTemplateWithTwoColumns";
import { pxToRem } from "adminComponents/utils";
import { langLabelMap, supportedLangs } from "links/lib/lang";
import { IUser } from "links/lib/types";

export interface IProps {
  navigationData: INavigationProps;
  studentAvatarSrc?: string;
  studentAvatarAltText: string;
  student?: IUser;
  isLoading?: boolean;
  activeLanguage: string;
  handleStudentAvatarEdit: () => void;
  handleStudentAvatarShare: () => void;
  handleSelectLanguage: (language: string) => void;
  handleSignOutClick: () => void;
}

export const MyAccountScreen: React.FC<IProps> = ({
  navigationData,
  studentAvatarSrc,
  studentAvatarAltText,
  isLoading,
  activeLanguage,
  handleStudentAvatarEdit,
  handleStudentAvatarShare,
  handleSelectLanguage,
  handleSignOutClick,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  const languageOptions: IOption[] = supportedLangs?.map((language) => ({
    value: language,
    label: langLabelMap[language],
  }));

  const selectedLanguageOption: IOption | undefined = languageOptions.find(
    (language) => language.value === activeLanguage
  );

  const handleSelectLanguageOption = (newValue: IOption) => {
    const language = supportedLangs.find(
      (language) => language === newValue.value
    ) as string;
    handleSelectLanguage(language);
  };

  const renderNav = () => {
    return (
      <StudentNavigationHeader
        {...navigationData}
        pageTitle={t("myAccountScreen.myAccountHeading")}
      />
    );
  };

  const renderTopContent = () => {
    return (
      <Box display={{ base: "none", md: "block" }} paddingBottom={pxToRem(64)}>
        <Heading variant="adminH2" as="h1" textAlign="center">
          {t("myAccountScreen.myAccountHeading")}
        </Heading>
      </Box>
    );
  };

  const renderLeftContent = () => {
    return (
      <Box
        maxWidth={{ md: pxToRem(403) }}
        float={{ md: "right" }}
        width="100%"
        marginTop={{ base: pxToRem(32), md: 0 }}
      >
        <FullStudentAvatar
          handleEdit={handleStudentAvatarEdit}
          handleShare={handleStudentAvatarShare}
        >
          <Image
            src={studentAvatarSrc}
            alt={studentAvatarAltText}
            objectFit="contain"
          />
        </FullStudentAvatar>
      </Box>
    );
  };

  const renderRightContent = () => {
    return (
      <Flex
        maxWidth={{ md: pxToRem(403) }}
        direction="column"
        align={{ md: "flex-start" }}
      >
        <FormControl marginBottom={pxToRem(32)}>
          <FormLabel>{t("myAccountScreen.languageLabel")}</FormLabel>
          <Dropdown
            handleChange={(e) => handleSelectLanguageOption(e as IOption)}
            options={languageOptions}
            value={selectedLanguageOption ? [selectedLanguageOption] : []}
          />
        </FormControl>
        <Button variant="adminTextButtonMedium" onClick={handleSignOutClick}>
          {t("myAccountScreen.signOutButtonLabel")}
        </Button>
      </Flex>
    );
  };

  return (
    <StudentTemplateWithTwoColumns
      isLoading={isLoading}
      nav={renderNav()}
      topContent={renderTopContent()}
      leftColumnContent={renderLeftContent()}
      rightColumnContent={renderRightContent()}
    />
  );
};
