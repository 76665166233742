import {
  Text as ChakraText,
  TextProps,
  useStyleConfig,
} from "@chakra-ui/react";
import React from "react";

export const Text: React.FC<TextProps> = ({
  as,
  children,
  variant,
  color,
  sx,
  ...rest
}) => {
  const styles = useStyleConfig("AdminText", { variant, color });

  return (
    <ChakraText {...rest} sx={{ ...styles, ...sx }} as={as}>
      {children}
    </ChakraText>
  );
};
