import { Box, Flex } from "@chakra-ui/react";
import React from "react";

import { Card } from "adminComponents/atoms/Card";
import { Divider } from "adminComponents/atoms/Divider";
import { UpgradeCallToAction } from "adminComponents/atoms/UpgradeCallToAction";
import { pxToRem } from "adminComponents/utils";

export interface IProps {
  upgradeText?: string;
}

export const PracticeSetPlaceholderCard: React.FC<IProps> = ({
  upgradeText,
}) => {
  return (
    <Card
      borderColor="primary.tan"
      backgroundColor="primary.white"
      variant="adminCardThickBorder"
    >
      <Box w="100%" h="100%" position="relative">
        {upgradeText && (
          <Box top="25%" w="100%" position="absolute">
            <UpgradeCallToAction upgradeText={upgradeText} />
          </Box>
        )}
        <Flex paddingBottom={pxToRem(25)} w="100%" justify="space-between">
          <Box
            borderRadius="100%"
            boxSize={pxToRem(65)}
            bgColor="primary.light-gray"
          />
          <Box
            h={pxToRem(25)}
            w="15%"
            borderRadius="lg"
            bgColor="primary.light-gray"
          />
        </Flex>
        <Flex w="100%" flexDir="column">
          <Box
            w="35%"
            h={pxToRem(60)}
            bgColor="primary.light-gray"
            borderRadius="lg"
          />
          <Box w="35%" h={pxToRem(60)} />
        </Flex>
        <Divider
          color="primary.light-gray"
          marginTop={{ base: pxToRem(21), md: pxToRem(30) }}
          marginBottom={{ base: pxToRem(21), md: pxToRem(30) }}
        />
        <Flex w="100%" justify="space-between">
          <Box
            w="10%"
            h={pxToRem(20)}
            bgColor="primary.light-gray"
            borderRadius="lg"
          />
          <Box
            w="20%"
            h={pxToRem(20)}
            bgColor="primary.light-gray"
            borderRadius="lg"
          />
        </Flex>
      </Box>
    </Card>
  );
};
