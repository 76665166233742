import { Grid, useBreakpointValue } from "@chakra-ui/react";
import React from "react";

import { Carousel } from "adminComponents/molecules/Carousel";
import { StudentPracticeSetCard } from "adminComponents/molecules/StudentPracticeSetCard";
import { pxToRem } from "adminComponents/utils/pxToRem";

export const StudentPracticeSetGridCarousel: React.FC = ({ children }) => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  const childrenElement = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      if (child.type === StudentPracticeSetCard) {
        return child;
      }
    }
  });

  return (
    <>
      {isMobile ? (
        <Carousel slidesPerView={1.08} alignItems="left" showDots={true}>
          {childrenElement}
        </Carousel>
      ) : (
        <Grid
          templateColumns="repeat(2, 1fr)"
          mt={pxToRem(24)}
          gap={pxToRem(24)}
        >
          {childrenElement}
        </Grid>
      )}
    </>
  );
};
