import { Box, Flex, Grid, useBreakpointValue } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import ColorButton from "adminComponents/atoms/ColorButton";
import { Carousel } from "adminComponents/molecules/Carousel";
import { pxToRem } from "adminComponents/utils";

export type ColorPickerDisplay = "carousel" | "grid";

export interface ColorPickerProps {
  id?: string;
  colors: Array<string>;
  selectedColor?: string;
  display?: ColorPickerDisplay;
  translationKey?: string;
  useModal?: boolean;
  handleColorClick?: (color: string) => void;
}

export const ColorPicker: React.FC<ColorPickerProps> = ({
  display = "carousel",
  colors,
  id,
  selectedColor,
  translationKey = "colorButton.selectColor",
  useModal,
  handleColorClick,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const isMobile = useBreakpointValue({ base: true, lg: false });

  const renderColors = colors.map((color) => (
    <ColorButton
      key={`icon-carousel-icon-${color}`}
      size={display === "grid" ? "medium" : "small"}
      color={color}
      handleClick={() => handleColorClick?.(color)}
      isSelected={color === selectedColor}
      ariaLabel={t(translationKey, { color })}
    />
  ));

  return display === "carousel" ? (
    isMobile ? (
      <Box overflow="hidden" position="relative" width="100%">
        <Flex
          gap={pxToRem(16)}
          overflow="auto"
          mb={pxToRem(-16)}
          pb={pxToRem(16)}
          px={pxToRem(16)}
          width="100%"
        >
          {renderColors}
        </Flex>
        <Box
          bgGradient={`linear(to right, primary.warm-white, rgba(253, 250, 243, 0) 100%)`}
          sx={{
            display: "block",
            width: pxToRem(16),
            height: "100%",
            zIndex: 10,
            position: "absolute",
            left: 0,
            top: 0,
          }}
        />
        <Box
          bgGradient={`linear(to left, primary.warm-white, rgba(253, 250, 243, 0) 100%)`}
          sx={{
            display: "block",
            width: pxToRem(16),
            height: "100%",
            zIndex: 10,
            position: "absolute",
            right: 0,
            top: 0,
          }}
        />
      </Box>
    ) : (
      <Carousel
        id={id}
        variant="adminCarousel"
        slidesPerView={7}
        bgColor="primary.warm-white"
        navigationShadow
        showArrows
      >
        {renderColors}
      </Carousel>
    )
  ) : (
    <Grid
      templateColumns={useModal ? "repeat(5, 0.2fr)" : "repeat(4, 1fr)"}
      gridRowGap={pxToRem(30)}
    >
      {renderColors}
    </Grid>
  );
};
