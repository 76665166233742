import type { ComponentStyleConfig } from "@chakra-ui/theme";

import { hoverStyles, pxToRem } from "adminComponents/utils";

export const AdminTextLink: ComponentStyleConfig = {
  baseStyle: (props: { [key: string]: string }): Record<string, unknown> => ({
    container: {
      color: props.isWarning
        ? "utility.error"
        : props.size === "xl"
        ? "primary.warm-black"
        : "utility.link",
      display: "inline-flex",
      opacity: props.isDisabled ? 0.4 : 1,
      pointerEvents: props.isDisabled ? "none" : "auto",
      textDecoration: props.size === "xl" ? "none" : "underline",
      _hover: hoverStyles({
        color: props.isWarning
          ? "utility.error-hover"
          : props.size === "xl"
          ? "primary.warm-black"
          : "utility.link-hover",
        textDecoration: "underline",
      }),
      _focus: {
        boxShadow: "none",
      },
      _focusVisible: {
        borderRadius: pxToRem(4),
        outline: `${pxToRem(1)} solid`,
        outlineColor: "utility.focus",
      },
    },
    text: {
      textStyle: props.textVariant,
      color: "inherit",
      textDecoration: "inherit",
    },
    iconContainer: {
      display: "flex",
      marginLeft: pxToRem(10),
    },
  }),
  variants: {
    ghost: {
      container: {
        color: "inherit",
        textDecoration: "none",
        _hover: hoverStyles({
          color: "inherit",
          textDecoration: "none",
        }),
      },
    },
    black: {
      container: {
        color: "primary.warm-black",
        textDecoration: "underline",
      },
    },
  },
};
