import { AxiosError } from "axios";
import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { IUser } from "../../types";

export interface IFetchEducatorWithGroupDetailsBulkParams {
  userIds: Array<string>;
  enabled?: boolean;
}

export interface IFetchEducatorWithGroupDetailsResponse {
  user: IUser;
  group_count: string;
  student_count: string;
}

export interface IFetchEducatorWithGroupDetailsBulkResponse {
  teachers: Array<IFetchEducatorWithGroupDetailsResponse>;
}

export default function useFetchEducatorWithGroupDetailsBulk(
  params: IFetchEducatorWithGroupDetailsBulkParams
): UseQueryResult<IFetchEducatorWithGroupDetailsBulkResponse, AxiosError> {
  const axios = useAxios();

  const { userIds, enabled = false } = params;

  const reactQuery = useQuery<
    IFetchEducatorWithGroupDetailsBulkResponse,
    AxiosError
  >(
    ["educator-with-group-details-bulk", { userIds }],
    async (): Promise<IFetchEducatorWithGroupDetailsBulkResponse> => {
      return await axios
        .get(`/v1/users/group-stats/bulk`, {
          params: {
            user_ids: userIds,
          },
        })
        .then((r) => r.data as IFetchEducatorWithGroupDetailsBulkResponse);
    },
    { enabled }
  );

  return reactQuery;
}
