import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { IPracticeSetItem } from "../../types";

export interface IFetchPracticeSetItemResponse {
  practice_set_item: IPracticeSetItem;
}

export interface IUseFetchPracticeSetItemArgs {
  id: string;
  isQueryEnabled: boolean;
}

export default function useFetchPracticeSetItem(
  args: IUseFetchPracticeSetItemArgs
): UseQueryResult<IFetchPracticeSetItemResponse> {
  const { id, isQueryEnabled } = args;

  const axios = useAxios();

  const query = useQuery<IFetchPracticeSetItemResponse>(
    ["practiceSetItems", { id }],
    async (): Promise<IFetchPracticeSetItemResponse> => {
      const response = await axios
        .get(`/v1/practice-sets/items/${id}`)
        .then((r) => r.data as { practice_set_item: IPracticeSetItem });

      return {
        practice_set_item: response.practice_set_item,
      };
    },
    {
      enabled: isQueryEnabled,
    }
  );

  return query;
}
