import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { ScreenOrientation } from "sessionComponents/types";

export const useDetectOrientation = (): {
  orientation: ScreenOrientation;
  isPortrait: boolean;
  isLandscape: boolean;
} => {
  const { orientation } = useBreakpoints();

  return {
    orientation,
    isPortrait: orientation === ScreenOrientation.Portrait,
    isLandscape: orientation === ScreenOrientation.Landscape,
  };
};
