import {
  Box,
  CheckboxGroup,
  Flex,
  Tooltip,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import React, {
  PropsWithChildren,
  ReactElement,
  ReactNode,
  cloneElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Checkbox } from "adminComponents/atoms/Checkbox";
import { Heading } from "adminComponents/atoms/Heading";
import { Modal } from "adminComponents/atoms/Modal";
import { Text } from "adminComponents/atoms/Text";
import { TextLink } from "adminComponents/atoms/TextLink";
import { generateStudentName, pxToRem } from "adminComponents/utils";
import { IClassroom, IUser } from "links/lib/types";

interface SelectStudentModalProps {
  isOpen: boolean;
  children: ReactNode;
  classroom?: IClassroom;
  isLoading: boolean;
  selectedStudentIds: string[];
  customTitle?: string;
  customSubmitLabel?: string;
  requireStudentSelection?: boolean;
  handleClose: () => void;
  handleSubmit: (students: string[]) => void;
  unselectableStudents?: IUser[];
  disableNotifications?: boolean;
  setDisableNotifications?: (disableNotifications: boolean) => void;
}

export const SelectStudentModal: React.FC<SelectStudentModalProps> = ({
  isOpen,
  classroom,
  isLoading,
  selectedStudentIds,
  customSubmitLabel,
  customTitle,
  requireStudentSelection = true,
  handleClose,
  handleSubmit,
  unselectableStudents,
  disableNotifications,
  setDisableNotifications,
  children,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const styles = useMultiStyleConfig("AdminSelectStudentModal", {});

  const [selected, setSelected] = useState(selectedStudentIds);
  const [allStudentIds, setAllStudentIds] = useState<string[]>([]);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const renderWithHandleChange = useMemo(() => {
    const handleChange = (studentId: string) => {
      let newValue = [...selected];
      if (newValue.includes(studentId)) {
        newValue = newValue.filter((s) => s != studentId);
      } else {
        newValue.push(studentId);
      }

      setSelected(newValue);
    };

    const allIds: string[] = [];

    const element = React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        const item = child as ReactElement<PropsWithChildren<StudentItemProps>>;
        if (item.type === StudentItem) {
          if (!item.props.isInvalid) {
            allIds.push(item.props.id);
          }
          return cloneElement(item, {
            handleChange: () => {
              handleChange(item.props.id);
            },
          });
        } else {
          return child;
        }
      }
    });

    setAllStudentIds(allIds);

    return element;
  }, [children, selected]);

  useEffect(() => {
    setSelected(selectedStudentIds);
  }, [selectedStudentIds]);

  const submit = useCallback(() => {
    handleSubmit(selected);
  }, [handleSubmit, selected]);

  const close = useCallback(() => {
    handleClose();
    setSelected(selectedStudentIds);
  }, [handleClose, selectedStudentIds]);

  return (
    <Modal
      title={customTitle || t("selectStudentModal.title")}
      isOpen={isOpen}
      onClose={close}
      size="xl"
      headingProps={{ as: "h2", variant: "adminH6" }}
      showBackButton={false}
    >
      <Flex sx={styles.container}>
        {classroom && (
          <Flex>
            <Text sx={styles.classroomTitle} variant="adminP1">
              {t("common.classroom")}:
            </Text>
            <Text sx={styles.classroomName} variant="adminP1">
              {classroom.name}
            </Text>
          </Flex>
        )}

        <Flex sx={styles.header}>
          <Flex sx={styles.selectHeaderText}>
            <Heading as="p" variant="adminH3">
              {selected.length}
            </Heading>
            <Text sx={styles.selectedStudents} variant="adminP1">
              {selected.length == 1
                ? t("selectStudentModal.studentsSelectedSingular")
                : t("selectStudentModal.studentsSelectedPlural")}
            </Text>
          </Flex>

          <Flex sx={styles.selectHeaderLinks}>
            <TextLink
              isDisabled={selected.length === allStudentIds.length}
              handleClick={() => {
                setSelected(allStudentIds);
              }}
            >
              {t("common.selectAll")}
            </TextLink>
            <TextLink
              isDisabled={selected.length === 0}
              handleClick={() => {
                setSelected([]);
              }}
            >
              {t("common.deselectAll")}
            </TextLink>
          </Flex>
        </Flex>
        {unselectableStudents && unselectableStudents.length > 0 && (
          <Box sx={styles.unselectableTextContainer}>
            <Text color="utility.error">
              {t("selectStudentModal.classDifferencesHeading", {
                count: unselectableStudents.length,
              })}{" "}
              <TextLink
                handleClick={() => scrollContainerRef.current?.scrollIntoView()}
                isWarning
              >
                {t("selectStudentModal.review", {
                  count: unselectableStudents.length,
                })}
              </TextLink>
            </Text>
          </Box>
        )}
        <Flex
          maxH="full"
          paddingTop={pxToRem(10)}
          sx={styles.checkboxContainer}
          gap={pxToRem(12)}
        >
          <CheckboxGroup value={selected}>
            {renderWithHandleChange}
            {unselectableStudents && unselectableStudents.length > 0 && (
              <Box ref={scrollContainerRef} paddingBottom={pxToRem(2000)}>
                <Text>
                  {t("selectStudentModal.classDifferences", {
                    count: unselectableStudents.length,
                  })}
                </Text>

                {unselectableStudents.map((s, i) => {
                  const name = generateStudentName({
                    given_name: s.given_name,
                    family_name: s.family_name,
                  });

                  return (
                    <StudentItem
                      key={`student-${s.email}-${i}`}
                      id={s.email}
                      displayName={`${name.primary} (${s.email})`}
                      isInvalid
                      invalidText={t("selectStudentModal.onlyInGiantSteps")}
                    />
                  );
                })}
              </Box>
            )}
          </CheckboxGroup>
        </Flex>
        {setDisableNotifications && (
          <Box paddingTop={pxToRem(20)}>
            <Checkbox
              isChecked={!!disableNotifications}
              onChange={(e) => setDisableNotifications(e.target.checked)}
            >
              {t("common.sendEmails")}
            </Checkbox>
          </Box>
        )}
        <Flex sx={styles.footer}>
          <Flex sx={styles.footerButtons}>
            <Button
              size="lg"
              variant="adminButtonFilled"
              flex={[1, null, "auto"]}
              isLoading={isLoading}
              isDisabled={selected.length === 0 && requireStudentSelection}
              onClick={submit}
            >
              {customSubmitLabel || t("common.save")}
            </Button>
            <Button
              size="lg"
              variant="adminButtonOutlined"
              onClick={close}
              flex={[1, null, "auto"]}
              isLoading={isLoading}
            >
              {t("common.cancel")}
            </Button>
          </Flex>

          {selected.length == 0 && requireStudentSelection && (
            <Box sx={styles.footerTextContainer}>
              <Text variant="adminP2" sx={styles.footerText}>
                {t("selectStudentModal.youMustSelectOneStudent")}
              </Text>
            </Box>
          )}
        </Flex>
      </Flex>
    </Modal>
  );
};

export interface StudentItemProps {
  id: string;
  displayName: string;
  isInvalid?: boolean;
  invalidText?: string;
  handleChange?: () => void;
}

export const StudentItem: React.FC<StudentItemProps> = ({
  id,
  displayName,
  isInvalid,
  invalidText,
  handleChange,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  return (
    <Checkbox value={id} onChange={handleChange} isDisabled={isInvalid}>
      {isInvalid ? (
        <Tooltip
          label={
            invalidText ? invalidText : t("selectStudentModal.invalidEmail")
          }
          placement="top"
        >
          {displayName}
        </Tooltip>
      ) : (
        displayName
      )}
    </Checkbox>
  );
};
