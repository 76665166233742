import { AxiosError } from "axios";
import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import {
  IGradeLevel,
  ISubject,
  IUser,
  UserPublicProfileStatistics,
} from "../../types";

export interface IFetchUserPublicProfileAnonymousParams {
  customUrlCode: string;
  onError?: (err: AxiosError) => void;
  onForbidden?: () => void;
  onNotFound?: () => void;
}

export interface IFetchUserPublicProfileAnonymousResponse {
  user: IUser;
  statistics: UserPublicProfileStatistics;
  grade_levels: Array<IGradeLevel>;
  subjects: Array<ISubject>;
}

/**
 * Fetches the public profile of a user for an anonymous visitor
 * @returns
 */
export default function useFetchUserPublicProfileAnonymous(
  params: IFetchUserPublicProfileAnonymousParams
): UseQueryResult<IFetchUserPublicProfileAnonymousResponse, AxiosError> {
  const axios = useAxios();

  const { customUrlCode, onError, onForbidden, onNotFound } = params;

  const reactQuery = useQuery<
    IFetchUserPublicProfileAnonymousResponse,
    AxiosError
  >(
    ["user-public-profiles-anonymous", params],
    async (): Promise<IFetchUserPublicProfileAnonymousResponse> => {
      return await axios
        .get(`/v1/users-public-profiles/anon`, {
          params: {
            custom_url_code: customUrlCode,
          },
        })
        .then((r) => r.data as IFetchUserPublicProfileAnonymousResponse);
    },
    {
      onError: (err: AxiosError) => {
        if (err.response?.status === 403) {
          if (onForbidden) {
            onForbidden();
            return;
          }
        }
        if (err.response?.status === 404) {
          if (onNotFound) {
            onNotFound();
            return;
          }
        }

        onError?.(err);
      },
    }
  );

  return reactQuery;
}
