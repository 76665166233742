import { AspectRatio, Box, Image } from "@chakra-ui/react";
import React from "react";

import { pxToRem } from "adminComponents/utils";

export interface IShareAvatarPreviewProps {
  imageUrl: string;
}

export const ShareAvatarPreview: React.FC<IShareAvatarPreviewProps> = ({
  imageUrl,
}) => {
  return (
    <Box
      borderColor="primary.tan"
      borderStyle="solid"
      borderWidth={{ base: pxToRem(6), lg: pxToRem(12) }}
    >
      <AspectRatio maxW="full" ratio={1}>
        <Image src={imageUrl} />
      </AspectRatio>
    </Box>
  );
};
