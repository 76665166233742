import { Box } from "@chakra-ui/react";
import React, { ComponentProps } from "react";
import { useDrop } from "react-dnd";

import { ISessionDraggableSortItem } from "links/lib/types";

interface IDroppableZoneProps extends ComponentProps<typeof Box> {
  moveLabel?: (labelId: string, zoneId: string) => void;
  zoneId?: string;
  hoverColor?: string;
  bgColor?: string;
  borderStyle?: string;
}

export const DroppableZone: React.FC<IDroppableZoneProps> = (props) => {
  const { children, moveLabel, zoneId, bgColor, hoverColor, borderStyle } =
    props;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const [{ isOver }, drop] = useDrop({
    accept: "LABEL",
    collect(monitor) {
      return {
        isOver: !!monitor.isOver(),
      };
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    drop(item: ISessionDraggableSortItem) {
      moveLabel && moveLabel(item.id, zoneId || "0");

      return {
        zoneId,
        itemId: item.id,
      };
    },
  });

  // remove custom props before passing into Box
  const boxProps = { ...props };
  delete boxProps.moveLabel;
  delete boxProps.zoneId;
  delete boxProps.hoverColor;
  delete boxProps.bgColor;

  return (
    <Box
      ref={drop}
      backgroundColor={isOver ? hoverColor : bgColor}
      border={isOver ? borderStyle : undefined}
      {...boxProps}
    >
      {children}
    </Box>
  );
};
