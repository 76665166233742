import { Box, Flex, useMultiStyleConfig } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Icon } from "adminComponents/atoms/Icon";
import { Text } from "adminComponents/atoms/Text";
import { GoalsProgressBar } from "adminComponents/molecules/GoalsProgressBar";

export interface IProps {
  accuracy: number;
  accuracyGoal: number;
  rewardXp: number;
}

export const AssignmentProgress: React.FC<IProps> = ({
  accuracy,
  accuracyGoal,
  rewardXp,
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });
  const styles = useMultiStyleConfig("AdminStudentAssignmentCard", {});

  return (
    <Box>
      <Flex alignItems="center">
        <Icon
          decorative
          sx={styles.xpIcon}
          icon="star_outlined"
          iconColor="primary.white"
          backgroundColor="utility.focus"
          variant="circleWithBackgroundColor"
        />
        <Text sx={styles.xp} variant="adminP2">
          <strong>{rewardXp}</strong> {t("common.xpReward")}
        </Text>
      </Flex>
      <Box sx={styles.progress}>
        <GoalsProgressBar
          variant="adminAccuracyProgress"
          accuracy={accuracy}
          accuracyGoal={accuracyGoal}
        />
      </Box>
    </Box>
  );
};
