import { Box, Flex } from "@chakra-ui/react";
import React from "react";

import { Divider } from "adminComponents/atoms/Divider";
import { StudentAnswerResults } from "adminComponents/molecules/StudentAnswerResults";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { ISessionItemResponse, QuestionType } from "links/lib/types";

export interface IProps {
  studentName: string;
  resultsList: ISessionItemResponse[];
  questionType: QuestionType;
}

export const StudentDrawAnswerResults: React.FC<IProps> = ({
  studentName,
  resultsList,
  questionType,
}) => {
  return (
    <Flex direction="column" gap={{ base: pxToRem(24), md: pxToRem(28) }}>
      {resultsList.map((result, index) => {
        return (
          <Box key={result.id}>
            <Flex direction="column" gap={{ base: pxToRem(4), md: pxToRem(8) }}>
              <StudentAnswerResults
                studentName={studentName}
                answers={[result]}
                correctAnswers={[]}
                questionType={questionType}
              />
            </Flex>
            {resultsList.length > index + 1 && (
              <Divider color="primary.light-gray" />
            )}
          </Box>
        );
      })}
    </Flex>
  );
};
