import { AxiosError } from "axios";
import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { IUser } from "../../types";

export interface IFetchMostCopiedCreatorsResponse {
  most_copied_users: Array<IUser>;
}

export interface IUseFetchMostCopiedCreatorsArgs {
  enabled?: boolean;
  limit?: number;
  periodDays?: number;
}

/**
 * Fetches a list of the users who created the most copied sets over
 * the specified period in days. The backend limits to sets that match
 * the requesting user's subjects/grade levels, if set.
 * @returns
 */
export default function useFetchMostCopiedCreators(
  args: IUseFetchMostCopiedCreatorsArgs
): UseQueryResult<IFetchMostCopiedCreatorsResponse, AxiosError> {
  const { enabled = true, limit = 12, periodDays = 30 } = args;
  const params = { enabled, limit, periodDays };

  const axios = useAxios();

  const fetchMostCopiedCreatorsResponse = useQuery<
    IFetchMostCopiedCreatorsResponse,
    AxiosError
  >(
    ["mostCopiedCreators", params],
    async (): Promise<IFetchMostCopiedCreatorsResponse> => {
      return await axios
        .get(`/v1/practice-sets/most-copied/creators`, {
          params: {
            limit,
            period_days: periodDays,
          },
        })
        .then((r) => r.data as { most_copied_users: Array<IUser> });
    },
    { enabled }
  );

  return fetchMostCopiedCreatorsResponse;
}
