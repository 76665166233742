import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { IFetchAvatarResponse } from "../../types";

export interface IEquipAvatarItemArgs {
  user_id: string;
  avatar_item_id: string;
  avatar_item_color_id?: string;
}

/**
 * Equips an avatar item
 * @returns
 */
export default function useEquipAvatarItem(): UseMutationResult<
  IFetchAvatarResponse,
  unknown,
  IEquipAvatarItemArgs
> {
  const axios = useAxios();
  const queryClient = useQueryClient();

  const mutateAvatar = async (args: IEquipAvatarItemArgs) => {
    const { avatar_item_id, avatar_item_color_id, user_id } = args;

    const response = await axios({
      method: "post",
      url: `/v1/avatar/${user_id}/items/equip`,
      data: {
        avatar_item_id,
        avatar_item_color_id,
      },
    }).then((r) => r.data as IFetchAvatarResponse);

    queryClient.invalidateQueries("avatar", { exact: true });

    return response;
  };

  const mutation = useMutation(mutateAvatar);

  return mutation;
}
