import { useMemo } from "react";

import { useSessionRoundGroupsState } from "links/lib/contexts/sessionRoundGroupsState";
import { ISessionRoundGroupState } from "links/lib/types";

export const useSafeRoundGroupStateArray = (): ISessionRoundGroupState[] => {
  const roundGroupsStateMap = useSessionRoundGroupsState();
  const safeRoundGroupsStateArray = useMemo(
    () =>
      Object.values(roundGroupsStateMap).filter(
        (rgs) => !!rgs
      ) as ISessionRoundGroupState[],
    [roundGroupsStateMap]
  );

  return safeRoundGroupsStateArray;
};
