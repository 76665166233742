import React, { useEffect, useState } from "react";
import {
  Redirect,
  Route,
  RouteProps,
  useHistory,
  useLocation,
} from "react-router-dom";

import { useAnalytics } from "lib/contexts/analytics";
import { localStoreAuthKeyName } from "links/lib/constants";
import {
  useAuth,
  useCreateGuest,
  useInitAuth,
  useIsFlagEnabled,
} from "links/lib/features/auth";
import { AnalyticsEvent, UserRole } from "links/lib/types";
import AppError from "screens/AppError";

import AppSpinner from "./AppSpinner";

export interface ProtectedRouteProps extends RouteProps {
  role?: UserRole | Array<UserRole>;
  renderForPreventedGuest?: React.ReactNode;
  preventGuest?: boolean;
}

/**
 * ProtectedRoute checks the value for authUser. If present,
 * it will ensure role match if the `role` prop is present, and then render the route props as expected.
 * If not, it will render a React Router `Redirect` to the "/sign-in" path
 * @returns ProtectedRoute
 */

const createGuestFeature = "playtime.explore.create_guest";
const createEphemeralGuestFeature = "playtime.explore.create_ephemeral_guest";

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  renderForPreventedGuest,
  preventGuest = false,
  children,
  role,
  ...rest
}) => {
  const { authUser: user } = useAuth();
  const location = useLocation();
  const history = useHistory();
  const fetchFlags = useIsFlagEnabled({
    enabled: !user,
    flags: [createGuestFeature, createEphemeralGuestFeature],
    properties: {},
  });
  const createGuestForNoAuthUser =
    !!fetchFlags.data?.flags[createGuestFeature] && !preventGuest;
  const createEphemeralGuestForNoAuthUser =
    !!fetchFlags.data?.flags[createEphemeralGuestFeature];

  const { trackEvent } = useAnalytics();

  const { mutate: createGuestUser, isLoading: createGuestUserIsLoading } =
    useCreateGuest({
      onError: () => {
        history.push("/sign-in");
      },
      onSuccess: () => {
        trackEvent(
          AnalyticsEvent.Common_GuestCreated,
          {
            location: location.pathname,
          },
          { queueEvent: true }
        );
      },
    });

  const [newUserCreateCalled, setNewUserCreateCalled] =
    useState<boolean>(false);

  const isLoading = fetchFlags.isLoading || createGuestUserIsLoading;
  const shouldCreateGuest =
    !user && createGuestForNoAuthUser && !newUserCreateCalled;

  useEffect(() => {
    if (shouldCreateGuest && !isLoading) {
      createGuestUser({
        use_uninitialized_role: true,
        make_ephemeral: createEphemeralGuestForNoAuthUser,
      });
      setNewUserCreateCalled(true);
    }
  }, [
    isLoading,
    shouldCreateGuest,
    createGuestUser,
    createEphemeralGuestForNoAuthUser,
  ]);

  function renderRoute() {
    if (!user) {
      if (!shouldCreateGuest && !isLoading) {
        // If the user is not authenticated, then redirect to login with state
        // to store intended route
        return (
          <Redirect
            to={{
              pathname: "/sign-in",
              state: { from: location },
            }}
          />
        );
      }

      return <AppSpinner />;
    }

    if (user.is_guest && preventGuest) {
      if (renderForPreventedGuest) {
        return renderForPreventedGuest;
      }

      return <AppError code={403} />;
    }

    const roles = Array.isArray(role) ? role : role ? [role] : [];

    // If a role prop has been specified and the user
    // role does not match, render renderOnNotAllowed if provided
    // or 403 instead of route content
    if (roles.length > 0 && !roles.includes(user.role)) {
      // If the user has access to the route because of their true
      // role, exit their current session-based role.
      if (roles.includes(user.true_role)) {
        return <ExitSessionRole />;
      }

      return <AppError code={403} />;
    }

    return children;
  }

  return <Route {...rest} render={renderRoute} />;
};

export default ProtectedRoute;

const ExitSessionRole = () => {
  sessionStorage.removeItem(localStoreAuthKeyName);
  useInitAuth();

  window.location.href = location.href;

  return null;
};
