import {
  Box,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { ISessionDraggableSortItemZone } from "links/lib/types";
import { Button } from "sessionComponents/atoms/Button";
import { ZoneWithMarker } from "sessionComponents/atoms/ZoneWithMarker";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";

export type Label = ISessionDraggableSortItemZone & {
  isCorrect: boolean;
  number: number;
};

export interface IZoomableImageProps {
  src: string;
  alt: string;
  maxH: number | string;
  labels?: Label[];
}

export const ZoomableImage: React.FC<IZoomableImageProps> = ({
  src,
  alt,
  maxH,
  children,
  labels,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { match: currentBreakpoints } = useBreakpoints();
  const resizeTimer = useRef<NodeJS.Timeout | null>(null);
  const modalSize = useBreakpointValue({
    base: "xl",
    md: "3xl",
    lg: "4xl",
    xl: "6xl",
  });
  const imageRef = useRef<HTMLImageElement>(null);
  const [dimensions, setDimensions] = useState({ h: 0, w: 0 });
  const modalImageRef = useRef<HTMLImageElement>(null);
  const [modalImageDimensions, setModalImageDimensions] = useState({
    h: 0,
    w: 0,
  });

  const { t } = useTranslation("session", { useSuspense: false });

  useEffect(() => {
    const handleResize = () => {
      if (resizeTimer.current) clearTimeout(resizeTimer.current);
      resizeTimer.current = setTimeout(() => {
        setDimensions({
          w: imageRef.current?.clientWidth || 0,
          h: imageRef.current?.clientHeight || 0,
        });
      }, 250);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dimensions]);

  return (
    <>
      <Button
        variant="unstyled"
        w="auto"
        h="auto"
        borderRadius={pxToRem(currentBreakpoints.lightBorderRadius)}
        borderWidth={pxToRem(currentBreakpoints.borderWidth)}
        borderColor="transparent"
        position="relative"
        onClick={() => setIsOpen(true)}
        sx={{
          _hover: {
            borderColor: "primary.golden",
          },
          _focus: {
            borderColor: "primary.golden",
          },
        }}
        mt={
          (labels?.length || 0) > 0
            ? pxToRem(currentBreakpoints.diagramTargetSize / 2 + 16)
            : undefined
        }
      >
        <Image
          ref={imageRef}
          src={src}
          alt={alt}
          maxH={maxH}
          borderRadius={pxToRem(currentBreakpoints.lightBorderRadius)}
          onLoad={() =>
            setDimensions({
              h: imageRef.current?.clientHeight || 0,
              w: imageRef.current?.clientWidth || 0,
            })
          }
        />
        {labels?.map((label) => {
          const left =
            (label.x || 0) * dimensions.w -
            currentBreakpoints.diagramTargetSize / 2;
          const top =
            (label.y || 0) * dimensions.h -
            currentBreakpoints.diagramTargetSize / 2;
          return (
            <ZoneWithMarker
              key={label.id}
              left={left}
              top={top}
              number={label.number}
              iconColor={label.isCorrect ? "utility.success" : "utility.error"}
              zoneBackgroundColor="white"
            />
          );
        })}
        {children}
        <Box
          alignItems="center"
          justifyContent="center"
          position="absolute"
          top="0"
          left="0"
          bottom="0"
          right="0"
          display="flex"
          opacity="0"
          bg="rgba(0, 157, 214, 0.1)"
          transition="opacity 250ms ease-in-out"
        />
      </Button>

      <Modal onClose={() => setIsOpen(false)} size={modalSize} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent borderRadius={pxToRem(currentBreakpoints.borderRadius)}>
          <ModalCloseButton
            borderRadius={pxToRem(currentBreakpoints.borderRadius)}
            zIndex="10"
          />
          <ModalBody
            px={pxToRem(currentBreakpoints.diagramTargetSize / 2)}
            pt={pxToRem(currentBreakpoints.diagramTargetSize + 16)}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box h="full" w="full" position="relative">
              <Image
                ref={modalImageRef}
                w="full"
                src={src}
                alt={alt}
                onLoad={() =>
                  setModalImageDimensions({
                    h: modalImageRef.current?.clientHeight || 0,
                    w: modalImageRef.current?.clientWidth || 0,
                  })
                }
              />
              {labels?.map((label) => {
                const left =
                  (label.x || 0) * modalImageDimensions.w -
                  currentBreakpoints.diagramTargetSize / 2;
                const top =
                  (label.y || 0) * modalImageDimensions.h -
                  currentBreakpoints.diagramTargetSize / 2;

                return (
                  <ZoneWithMarker
                    key={label.id}
                    left={left}
                    top={top}
                    number={label.number}
                    iconColor={
                      label.isCorrect ? "utility.success" : "utility.error"
                    }
                    zoneBackgroundColor="white"
                  />
                );
              })}
            </Box>
          </ModalBody>
          <ModalFooter pt={0}>
            <Button variant="buttonFilled" onClick={() => setIsOpen(false)}>
              {t("modals.closeButton")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
