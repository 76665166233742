import React from "react";
import { useHistory } from "react-router-dom";

import { CreateInstantSet } from "adminComponents/screens/CreateInstantSet";
import { useGuestTeacherNavigationData } from "screens/TeacherDashboard/contexts/GuestTeacherNavigationDataProvider";

export const TeacherCreateInstantSet: React.FC = () => {
  const history = useHistory();
  const navigationData = useGuestTeacherNavigationData();
  return (
    <CreateInstantSet
      navigationData={navigationData}
      handleGenerateSet={() => {
        // noop, this is just an advertisement for instant sets
      }}
      handleSignIn={() => history.push("/sign-in/teacher")}
    />
  );
};
