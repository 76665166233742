import { Flex, SimpleGrid } from "@chakra-ui/react";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Card } from "adminComponents/atoms/Card";
import { Heading } from "adminComponents/atoms/Heading";
import { Icon } from "adminComponents/atoms/Icon";
import { Text } from "adminComponents/atoms/Text";
import { TextLink } from "adminComponents/atoms/TextLink";
import { LibraryPracticeSetCard } from "adminComponents/molecules/LibraryPracticeSetCard";
import { pxToRem } from "adminComponents/utils";
import { IPracticeSet } from "links/lib/types";
import { HashScrollOnMount } from "sharedComponents/atoms/HashScrollOnMount";

export interface IProps {
  hasSubjectsAndOrGrades: boolean;
  practiceSets: Array<IPracticeSet>;
}

export const RecommendedSets: React.FC<IProps> = ({
  hasSubjectsAndOrGrades,
  practiceSets,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  const [recommendedSetsToShow, setRecommendedSetsToShow] = useState(3);
  const shownRecommendedSets = useMemo(() => {
    return practiceSets.slice(0, recommendedSetsToShow);
  }, [practiceSets, recommendedSetsToShow]);

  const handleViewMoreRecommendedSets = useCallback(() => {
    setRecommendedSetsToShow(shownRecommendedSets.length + 4);
  }, [shownRecommendedSets.length]);

  return (
    <Flex flexDir="column" gap={pxToRem(20)}>
      <SimpleGrid columns={[1, null, 1, 4]} gap={pxToRem(20)}>
        <Flex alignItems="flex-start" gap={pxToRem(20)} flexDir="column">
          <Heading variant="adminH3" as="h1" maxW="100%">
            {t("teacherHomepageImproved.recommendedSets.heading")}
          </Heading>
          <HashScrollOnMount id="recommended-sets">
            {hasSubjectsAndOrGrades && (
              <TextLink
                navigationLink={{
                  label: "Account Page",
                  to: "/t/account",
                  isExternal: false,
                }}
                size="lg"
              >
                {t("teacherHomepageImproved.recommendedSets.accountEditLink")}
              </TextLink>
            )}
          </HashScrollOnMount>
          {!hasSubjectsAndOrGrades && (
            <Card
              mt="auto"
              variant="adminCardSolid"
              backgroundColor="primary.light-gray"
              borderColor="primary.tan"
            >
              <Text variant="adminP2">
                {t(
                  "teacherHomepageImproved.recommendedSets.subjectsGradesCTADescription"
                )}
              </Text>
              <TextLink
                navigationLink={{
                  label: "Account Page",
                  to: "/t/account",
                  isExternal: false,
                }}
                size="md"
              >
                {t("teacherHomepageImproved.recommendedSets.subjectsGradesCTA")}
              </TextLink>
            </Card>
          )}
        </Flex>
        {shownRecommendedSets?.map((practiceSet, i) => (
          <LibraryPracticeSetCard
            key={`${practiceSet.id}-${i}`}
            showLargeAuthorName
            authorName={practiceSet.author_name}
            authorCustomUrlCode={practiceSet.author_custom_url_code}
            authorProfileImageUrl={practiceSet.author_profile_image_url}
            authorProfileIsPrivate={practiceSet.author_profile_is_private}
            authorIsInternalContentSpecialist={
              practiceSet.author_is_internal_content_specialist
            }
            practiceSet={practiceSet}
          />
        ))}
      </SimpleGrid>
      {practiceSets.length > shownRecommendedSets.length && (
        <Button
          alignSelf="center"
          onClick={handleViewMoreRecommendedSets}
          rightIcon={
            <Icon
              color="currentColor"
              height={pxToRem(7)}
              icon="button_down_arrow"
              ml={pxToRem(5)}
              width={pxToRem(12)}
            />
          }
          variant="adminTextButtonLarge"
        >
          {t("common.viewMore")}
        </Button>
      )}
    </Flex>
  );
};
