import { Box, Flex, useMultiStyleConfig } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

import { Heading } from "adminComponents/atoms/Heading";
import { ProgressBar } from "adminComponents/atoms/ProgressBar";
import { Text } from "adminComponents/atoms/Text";
import { TextLink } from "adminComponents/atoms/TextLink";
import { clampString } from "adminComponents/utils/clampString";
import { IPeriodicChallenge } from "links/lib/types";

type ProgressBarVariant =
  | "default"
  | "adminSeasonProgress"
  | "adminAccuracyProgress";

export type ChallengeProps = Omit<
  IPeriodicChallenge,
  "reward_xp" | "reward_avatar_item" | "cover_image_url"
>;

export interface IProps {
  challenge?: ChallengeProps;
  accuracy?: number;
  accuracyGoal?: number;
  remainingDays?: number;
  hasIcon?: boolean;
  variant?: ProgressBarVariant;
  headingLevel?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p";
  isComplete?: boolean;
  handleMonthlyChallengeModalOpen?: () => void;
}

export const GoalsProgressBar: React.FC<IProps> = ({
  challenge,
  accuracy,
  accuracyGoal,
  remainingDays,
  hasIcon = false,
  variant = "default",
  headingLevel,
  isComplete,
  handleMonthlyChallengeModalOpen,
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });

  const ariaLabelId = uuidv4();

  const styles = useMultiStyleConfig("AdminGoalsProgressBar", {
    hasIcon,
    accuracyGoal,
  });
  const isSeasonProgress = variant === "adminSeasonProgress";
  const isAccuracyProgress = variant === "adminAccuracyProgress";
  const isChallengeComplete =
    (challenge?.awarded_xp || 0) >= (challenge?.xp_threshold || 1);

  const progressTitle = !isSeasonProgress ? (
    handleMonthlyChallengeModalOpen ? (
      <TextLink handleClick={handleMonthlyChallengeModalOpen}>
        {challenge?.title}
      </TextLink>
    ) : (
      <Text variant="adminP2Bold">{challenge?.title}</Text>
    )
  ) : (
    <Heading as={headingLevel ? headingLevel : "p"} variant="adminH6Bold">
      {challenge?.title}
    </Heading>
  );

  const daysLeft = (
    <Text sx={styles.remainingDays} variant="adminP2Bold">
      {isChallengeComplete
        ? t("common.complete")
        : t("common.daysLeft", { count: remainingDays ?? 0 })}
    </Text>
  );

  return (
    <Box sx={styles.container}>
      {challenge?.title && (
        <Flex sx={styles.titleContainer}>
          {progressTitle}
          {typeof remainingDays !== "undefined" && daysLeft}
        </Flex>
      )}

      {challenge?.description && (
        <Text sx={styles.description} variant="adminP2">
          {clampString(challenge?.description, 100)}
        </Text>
      )}

      {!isComplete && (
        <Box sx={styles.progressBar}>
          <ProgressBar
            aria-labelledby={ariaLabelId}
            variant="adminStudentGoal"
            value={!isAccuracyProgress ? challenge?.awarded_xp : accuracy}
            max={!isAccuracyProgress ? challenge?.xp_threshold : 100}
            icon={
              hasIcon
                ? {
                    iconName: isSeasonProgress ? "star_outlined" : "check",
                  }
                : undefined
            }
          />
          {accuracyGoal && <Box sx={styles.goal}></Box>}
        </Box>
      )}

      {!isAccuracyProgress ? (
        <>
          {!isComplete && (
            <Text sx={styles.stats} id={ariaLabelId} variant="adminP2">
              {t("common.xpProgress", {
                awarded: Math.min(
                  challenge?.awarded_xp ?? 0,
                  challenge?.xp_threshold ?? 0
                ),
                threshold: challenge?.xp_threshold,
              })}
            </Text>
          )}
        </>
      ) : (
        <Flex sx={styles.accuracyStats} id={ariaLabelId} alignItems="center">
          <Text sx={styles.accuracy} variant="adminP3">
            {t("accuracyGoals.goal", {
              number: accuracyGoal,
            })}
          </Text>
          <Box sx={styles.divider} role="presentation" aria-hidden>
            &bull;
          </Box>
          <Text sx={styles.accuracy} variant="adminP3">
            {t("accuracyGoals.bestAttempt", {
              number: accuracy,
            })}
          </Text>
        </Flex>
      )}
    </Box>
  );
};
