import { convertToSlateJSON } from "lib/slateUtils";
import {
  IAlternateTextResponse,
  IPracticeSetItem,
  QuestionType,
} from "links/lib/types";

export enum CSVTargetType {
  GiantSteps = "GIANT_STEPS",
  Blooket = "BLOOKET",
  Gimkit = "GIMKIT",
  Kahoot = "KAHOOT",
  Quizizz = "QUIZIZZ",
  Unknown = "UNKOWN",
}

export enum ParseErrorType {
  InvalidAnswerIndex = "INVALID_ANSWER_INDEX",
  NoAnswerIndex = "NO_ANSWER_INDEX",
  NoAnswers = "NO_ANSWERS",
}

export type ParseErrorCounts = Map<ParseErrorType, number>;

export const csvToPracticeSetItems = (
  data: Array<Array<string>>,
  randomlySortChoices?: boolean
): [Array<IPracticeSetItem>, ParseErrorCounts] => {
  let practiceSetItems: Array<IPracticeSetItem> = [];
  let errors: Map<ParseErrorType, number> = new Map();

  switch (detectCSVTargetType(data)) {
    case CSVTargetType.GiantSteps:
      [practiceSetItems, errors] = giantStepsCSVToPracticeSetItems(data);
      break;
    case CSVTargetType.Blooket:
      practiceSetItems = blooketCSVToPracticeSetItems(data);
      break;
    case CSVTargetType.Gimkit:
      practiceSetItems = gimkitCSVToPracticeSetItems(data);
      break;
    case CSVTargetType.Quizizz:
      practiceSetItems = quizizzCSVToPracticeSetItems(data);
      break;
    case CSVTargetType.Kahoot:
      practiceSetItems = kahootCSVToPracticeSetItems(data);
      break;
  }

  if (randomlySortChoices) {
    practiceSetItems.map((practiceSetItem) => {
      return {
        ...practiceSetItem,
        multiple_choice: practiceSetItem.multiple_choice && {
          ...practiceSetItem.multiple_choice,
          choices: practiceSetItem.multiple_choice.choices.sort(
            () => Math.random() - 0.5
          ),
        },
        multiple_select: practiceSetItem.multiple_select && {
          ...practiceSetItem.multiple_select,
          choices: practiceSetItem.multiple_select.choices.sort(
            () => Math.random() - 0.5
          ),
        },
      };
    });
  }

  return [practiceSetItems, errors];
};

export const detectCSVTargetType = (
  data: Array<Array<string>>
): CSVTargetType => {
  if (data.length === 0 || data[0].length === 0) {
    return CSVTargetType.Unknown;
  }

  if (data[0].length === 8 && data[0][0].includes("Blooket")) {
    return CSVTargetType.Blooket;
  }

  if (data[0].length === 5 && data[0][0].includes("Gimkit")) {
    return CSVTargetType.Gimkit;
  }

  if (data[0].length >= 8 && data[1][1].includes("Quiz template")) {
    return CSVTargetType.Kahoot;
  }

  if (
    data[0].length == 10 &&
    data[0][0].includes("Question Text") &&
    data[0][1].includes("Question Type")
  ) {
    return CSVTargetType.Quizizz;
  }

  if (
    data[0].length >= 10 &&
    (data[0][1].includes("Pearville") ||
      data[0][1].includes("Giant Steps") ||
      data[0][1].includes("Pear Practice"))
  ) {
    return CSVTargetType.GiantSteps;
  }

  return CSVTargetType.Unknown;
};

const gimkitCSVToPracticeSetItems = (
  data: Array<Array<string>>
): Array<IPracticeSetItem> => {
  const practiceSetItems: Array<IPracticeSetItem> = [];
  data.slice(2, data.length).forEach((row) => {
    if (row.length < 3) return;

    const answers = row.slice(1, 5).filter((answer) => !!answer.trim());
    if (answers.length < 2) return;

    const multipleChoice = {
      ...defaultPracticeSetItemMultipleChoice,
      prompt: convertToSlateJSON(row[0]),
      choices: answers
        .map((answer, i) => {
          return {
            text: convertToSlateJSON(answer),
            is_correct: i === 0,
          };
        })
        // Gimkit structures the CSV so that the correct answer is
        // always first. Shuffle the answers for convenience.
        .sort(() => 0.5 - Math.random()),
    };

    practiceSetItems.push({
      ...defaultPracticeSetItem,
      multiple_choice: multipleChoice,
      question_type: QuestionType.MultipleChoice,
    });
  });

  return practiceSetItems;
};

const giantStepsCSVToPracticeSetItems = (
  data: Array<Array<string>>
): [Array<IPracticeSetItem>, ParseErrorCounts] => {
  const practiceSetItems: Array<IPracticeSetItem> = [];
  const parseErrorCounts: ParseErrorCounts = new Map();

  data.slice(3, data.length).forEach((row) => {
    if (row.length < 10) return;

    const answers = row.slice(2, 8).filter((answer) => !!answer.trim());
    const hasAnswers = answers.length > 0;

    row[8] = row[8].trim();
    const hasAnswerIndexes = row[8] !== "";

    if (hasAnswers && !hasAnswerIndexes) {
      parseErrorCounts.set(
        ParseErrorType.NoAnswerIndex,
        (parseErrorCounts.get(ParseErrorType.NoAnswerIndex) || 0) + 1
      );
      return;
    }

    // Just an unpopulated line
    if (!hasAnswerIndexes) {
      return;
    }

    if (!hasAnswers) {
      parseErrorCounts.set(
        ParseErrorType.NoAnswers,
        (parseErrorCounts.get(ParseErrorType.NoAnswers) || 0) + 1
      );
      return;
    }

    const correctAnswerIndexes = row[8]
      .split(",")
      .map((answerNumber) => parseInt(answerNumber, 10) - 1);
    if (correctAnswerIndexes.length < 1) {
      parseErrorCounts.set(
        ParseErrorType.NoAnswerIndex,
        (parseErrorCounts.get(ParseErrorType.NoAnswerIndex) || 0) + 1
      );
      return;
    }
    const containsInvalidAnswerIndex =
      correctAnswerIndexes.filter((i) => i < 0 || i > 5 || isNaN(i)).length > 0;
    if (containsInvalidAnswerIndex) {
      parseErrorCounts.set(
        ParseErrorType.InvalidAnswerIndex,
        (parseErrorCounts.get(ParseErrorType.InvalidAnswerIndex) || 0) + 1
      );
      return;
    }

    const questionType =
      answers.length === 1
        ? QuestionType.TextResponse
        : correctAnswerIndexes.length > 1
        ? QuestionType.MultipleSelect
        : QuestionType.MultipleChoice;

    let multipleChoice = null;
    let multipleSelect = null;
    let textResponse = null;
    switch (questionType) {
      case QuestionType.MultipleChoice:
        multipleChoice = {
          ...defaultPracticeSetItemMultipleChoice,
          prompt: convertToSlateJSON(row[1]),
          choices: answers.map((answer, i) => {
            return {
              text: convertToSlateJSON(answer),
              is_correct: correctAnswerIndexes.indexOf(i) >= 0,
            };
          }),
        };
        if (!multipleChoice.choices.find((choice) => choice.is_correct)) {
          parseErrorCounts.set(
            ParseErrorType.InvalidAnswerIndex,
            (parseErrorCounts.get(ParseErrorType.InvalidAnswerIndex) || 0) + 1
          );
          return;
        }
        break;
      case QuestionType.MultipleSelect:
        multipleSelect = {
          ...defaultPracticeSetItemMultipleSelect,
          prompt: convertToSlateJSON(row[1]),
          choices: answers.map((answer, i) => {
            return {
              text: convertToSlateJSON(answer),
              is_correct: correctAnswerIndexes.indexOf(i) >= 0,
            };
          }),
        };
        if (!multipleSelect.choices.find((choice) => choice.is_correct)) {
          parseErrorCounts.set(
            ParseErrorType.InvalidAnswerIndex,
            (parseErrorCounts.get(ParseErrorType.InvalidAnswerIndex) || 0) + 1
          );
          return;
        }
        break;
      case QuestionType.TextResponse:
        textResponse = {
          ...defaultPracticeSetItemTextResponse,
          prompt: convertToSlateJSON(row[1]),
          correct_response: answers[0],
        };
        break;
    }

    practiceSetItems.push({
      ...defaultPracticeSetItem,
      multiple_choice: multipleChoice,
      multiple_select: multipleSelect,
      text_response: textResponse,
      question_type: questionType,
      feedback: convertToSlateJSON(row[9]),
    });
  });

  return [practiceSetItems, parseErrorCounts];
};

const quizizzCSVToPracticeSetItems = (
  data: Array<Array<string>>
): Array<IPracticeSetItem> => {
  const practiceSetItems: Array<IPracticeSetItem> = [];
  data.slice(2, data.length).forEach((row) => {
    if (row.length < 7) return;

    const answers = row.slice(2, 7).filter((answer) => !!answer.trim());
    if (answers.length < 2) return;

    // Do not support Poll type
    if (row[1] === "Poll") return;

    row[7] = row[7].trim();
    const correctAnswerIndexes =
      row[7] === ""
        ? []
        : row[7]
            .split(",")
            .map((answerNumber) => parseInt(answerNumber, 10) - 1);
    const questionType =
      correctAnswerIndexes.length === 0
        ? QuestionType.TextResponse
        : correctAnswerIndexes.length > 1
        ? QuestionType.MultipleSelect
        : QuestionType.MultipleChoice;

    let multipleChoice = null;
    let multipleSelect = null;
    let textResponse = null;
    let alternativeTextResponses: Array<IAlternateTextResponse> = [];
    switch (questionType) {
      case QuestionType.MultipleChoice:
        multipleChoice = {
          ...defaultPracticeSetItemMultipleChoice,
          prompt: convertToSlateJSON(row[0]),
          choices: answers.map((answer, i) => {
            return {
              text: convertToSlateJSON(answer),
              is_correct: correctAnswerIndexes.indexOf(i) >= 0,
            };
          }),
        };
        break;
      case QuestionType.MultipleSelect:
        multipleSelect = {
          ...defaultPracticeSetItemMultipleSelect,
          prompt: convertToSlateJSON(row[0]),
          choices: answers.map((answer, i) => {
            return {
              text: convertToSlateJSON(answer),
              is_correct: correctAnswerIndexes.indexOf(i) >= 0,
            };
          }),
        };
        break;
      case QuestionType.TextResponse:
        textResponse = {
          ...defaultPracticeSetItemTextResponse,
          prompt: convertToSlateJSON(row[0]),
          correct_response: answers[0],
        };
        alternativeTextResponses = answers
          .slice(1, answers.length)
          .map((answer) => {
            return {
              alternate_text: answer,
            };
          });
        break;
    }

    practiceSetItems.push({
      ...defaultPracticeSetItem,
      multiple_choice: multipleChoice,
      multiple_select: multipleSelect,
      question_type: questionType,
      text_response: textResponse,
      alternate_text_responses: alternativeTextResponses,
    });
  });

  return practiceSetItems;
};

const blooketCSVToPracticeSetItems = (
  data: Array<Array<string>>
): Array<IPracticeSetItem> => {
  const practiceSetItems: Array<IPracticeSetItem> = [];
  data.slice(2, data.length).forEach((row) => {
    if (row.length < 7) return;

    row[7] = row[7].trim();
    if (row[7] === "") {
      return;
    }

    const answers = row.slice(2, 6).filter((answer) => !!answer.trim());
    if (answers.length < 2) return;

    const correctAnswerIndexes = row[7]
      .split(",")
      .map((answerNumber) => parseInt(answerNumber, 10) - 1);
    const questionType =
      correctAnswerIndexes.length > 1
        ? QuestionType.MultipleSelect
        : QuestionType.MultipleChoice;

    let multipleChoice = null;
    let multipleSelect = null;
    switch (questionType) {
      case QuestionType.MultipleChoice:
        multipleChoice = {
          ...defaultPracticeSetItemMultipleChoice,
          prompt: convertToSlateJSON(row[1]),
          choices: answers.map((answer, i) => {
            return {
              text: convertToSlateJSON(answer),
              is_correct: correctAnswerIndexes.indexOf(i) >= 0,
            };
          }),
        };
        break;
      case QuestionType.MultipleSelect:
        multipleSelect = {
          ...defaultPracticeSetItemMultipleSelect,
          prompt: convertToSlateJSON(row[1]),
          choices: answers.map((answer, i) => {
            return {
              text: convertToSlateJSON(answer),
              is_correct: correctAnswerIndexes.indexOf(i) >= 0,
            };
          }),
        };
        break;
    }

    practiceSetItems.push({
      ...defaultPracticeSetItem,
      multiple_choice: multipleChoice,
      multiple_select: multipleSelect,
      question_type: questionType,
    });
  });

  return practiceSetItems;
};

const kahootCSVToPracticeSetItems = (
  data: Array<Array<string>>
): Array<IPracticeSetItem> => {
  const practiceSetItems: Array<IPracticeSetItem> = [];
  data.slice(8, data.length).forEach((row) => {
    if (row.length < 8) return;

    row[7] = row[7].trim();
    if (row[7] === "") {
      return;
    }

    const answers = row.slice(2, 6).filter((answer) => !!answer.trim());
    if (answers.length < 2) return;

    const correctAnswerIndexes = row[7]
      .split(",")
      .map((answerNumber) => parseInt(answerNumber, 10) - 1);
    const questionType =
      correctAnswerIndexes.length > 1
        ? QuestionType.MultipleSelect
        : QuestionType.MultipleChoice;

    let multipleChoice = null;
    let multipleSelect = null;
    switch (questionType) {
      case QuestionType.MultipleChoice:
        multipleChoice = {
          ...defaultPracticeSetItemMultipleChoice,
          prompt: convertToSlateJSON(row[1]),
          choices: answers.map((answer, i) => {
            return {
              text: convertToSlateJSON(answer),
              is_correct: correctAnswerIndexes.indexOf(i) >= 0,
            };
          }),
        };
        break;
      case QuestionType.MultipleSelect:
        multipleSelect = {
          ...defaultPracticeSetItemMultipleSelect,
          prompt: convertToSlateJSON(row[1]),
          choices: answers.map((answer, i) => {
            return {
              text: convertToSlateJSON(answer),
              is_correct: correctAnswerIndexes.indexOf(i) >= 0,
            };
          }),
        };
        break;
    }

    practiceSetItems.push({
      ...defaultPracticeSetItem,
      multiple_choice: multipleChoice,
      multiple_select: multipleSelect,
      question_type: questionType,
    });
  });

  return practiceSetItems;
};

const defaultPracticeSetItemTextResponse = {
  id: "0",
  practice_set_item_id: "0",
  prompt: "",
  correct_response: "",
  enable_partial_credit: true,
};

const defaultPracticeSetItemMultipleSelect = {
  id: "0",
  practice_set_item_id: "0",
  prompt: "",
  choices: [],
  created_at: "0001-01-01T00:00:00Z",
  updated_at: "0001-01-01T00:00:00Z",
  deleted_at: "0001-01-01T00:00:00Z",
};

const defaultPracticeSetItemMultipleChoice = {
  id: "0",
  practice_set_item_id: "0",
  prompt: "",
  choices: [],
};

const defaultPracticeSetItem: IPracticeSetItem = {
  id: "0",
  copied_from_item_id: "0",
  edited_from_item_id: "0",
  practice_set_id: "0",
  created_by: "0",
  skill_id: "0",
  order: 0,
  audio_url: "",
  image_url: "",
  image_alt_text: "",
  video_url: "",
  feedback: "",
  feedback_image_alt_text: "",
  feedback_image_url: "",
  feedback_reference_materials: [],
  is_generated: false,
  is_higher_order_thinking: false,
  question_type: QuestionType.Unknown,
  multiple_choice: null,
  multiple_select: null,
  open_ended: null,
  draw: null,
  diagram: null,
  term_definition: null,
  text_response: null,
  number_response: null,
  true_false: null,
  classify: null,
  standards: [],
  tags: [],
  cnc_code: "",
  is_certified: false,
  is_premium: false,
  source_item_is_certified: false,
  source_item_is_premium: false,
  skill: null,
  guid: "",
  created_at: "0001-01-01T00:00:00Z",
  updated_at: "0001-01-01T00:00:00Z",
  deleted_at: "0001-01-01T00:00:00Z",
  alternate_text_responses: [],
};
