export const userDefinedReserveColor = "#ff00ff";
export const hairReservedColor = "#0000ff";

export const hairColorOptions = [
  "#aa8866",
  "#debe99",
  "#241c11",
  "#4f1a00",
  "#9a3300",
];

export const slotModifierActions = ["N/A", "ENABLE", "DISABLE"];

export const slotModifierSlots = [
  // Selected to disable parts of hair for hats
  "hair_front",
  "hair_mid",
  "hair_back",
  "hair_front_hat",
  "hair_mid_hat",
  "hair_back_hat",
];

export const slotModifierHandLGroupSlots = [
  "hand_l",
  "hand_l_bend",
  "hand_l_bend2",
  "hand_l_fist",
  "hand_l_peace",
  "hand_l_peace2",
  "hand_l_thumbs_up",
  "hand_l_thumbs_up2",
];

export const slotModifierHandRGroupSlots = [
  "hand_r",
  "hand_r_bend",
  "hand_r_bend2",
  "hand_r_bend3",
  "hand_r_fingers",
  "hand_r_fist",
  "hand_r_peace",
  "hand_r_thumbs_up",
];
