import { Box, Flex, FormControl, SimpleGrid } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { usePrevious, useUnmount } from "react-use";

import { Button } from "adminComponents/atoms/Button";
import { IOption } from "adminComponents/atoms/Dropdown";
import { FormErrorMessage } from "adminComponents/atoms/FormErrorMessage";
import { FormLabel } from "adminComponents/atoms/FormLabel";
import { Icon } from "adminComponents/atoms/Icon";
import { IconCTAButton } from "adminComponents/atoms/IconCTAButton";
import { Input } from "adminComponents/atoms/Input";
import { Text } from "adminComponents/atoms/Text";
import { useEditPracticeSetMediaModalContext } from "adminComponents/contexts/EditPracticeSetMediaModalContext";
import { CreateQuestionFooter } from "adminComponents/molecules/CreateQuestionFooter";
import { QuestionAddMedia } from "adminComponents/molecules/QuestionAddMedia";
import { QuestionTypeDescription } from "adminComponents/molecules/QuestionTypeDescription";
import { AddMediaModal } from "adminComponents/organisms/AddMediaModal";
import { RichTextEditor } from "adminComponents/organisms/RichTextEditor";
import {
  getTotalCharCount,
  isRichTextEmpty,
} from "adminComponents/organisms/RichTextEditor/util";
import { CreateQuestionFormDataType } from "adminComponents/utils";
import { isNonNumericRegex } from "adminComponents/utils/isNumericRegex";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { RichValue } from "links/lib/types";
import { generateEmptySlateState } from "links/lib/util";

const PREFIX_SUFFIX_MAX_LENGTH = 16;

export interface CreateNumberResponseTabForm {
  audioUrl: string;
  videoUrl: string;
  imageUrl: string;
  imageAltText: string;
  prompt: RichValue;
  correctResponse: string;
  prefix: RichValue;
  suffix: RichValue;
  requiresHigherOrderThinking: boolean;
  alternateCorrectResponses: { response: string }[];
}

const initialValue: CreateNumberResponseTabForm = {
  audioUrl: "",
  videoUrl: "",
  imageAltText: "",
  imageUrl: "",
  prompt: generateEmptySlateState(),
  correctResponse: "",
  prefix: generateEmptySlateState(),
  suffix: generateEmptySlateState(),
  requiresHigherOrderThinking: false,
  alternateCorrectResponses: [],
};

interface IProps {
  defaultValue: CreateQuestionFormDataType;
  questionTypes: IOption[];
  hasFeedback: boolean;
  canSubmit: boolean;
  isSubmitting: boolean;
  handleSaveAndClose: (form: CreateNumberResponseTabForm) => void;
  handleChangeForm: (
    isValid: boolean,
    form?: CreateNumberResponseTabForm
  ) => void;
  handleNextTab: () => void;
  handleDirty?: () => void;
  handleSecondaryNextTab?: (theFormData: CreateQuestionFormDataType) => void;
  showSecondaryFooterCta?: boolean;
}

export const CreateQuestionNumberResponseTab: React.FC<IProps> = ({
  defaultValue,
  handleSaveAndClose,
  handleChangeForm,
  handleNextTab,
  hasFeedback,
  canSubmit,
  isSubmitting,
  handleDirty,
  handleSecondaryNextTab,
  showSecondaryFooterCta,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const mediaModalProps = useEditPracticeSetMediaModalContext();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    getValues,
    formState: { errors, isValid, touchedFields, isDirty },
  } = useForm<CreateNumberResponseTabForm>({
    defaultValues: defaultValue.numberResponse ?? initialValue,
    mode: "onChange",
  });

  const _handleSubmit = (data: CreateNumberResponseTabForm) => {
    handleSaveAndClose(data);
  };

  useEffect(() => {
    if (isDirty && handleDirty) handleDirty();
  }, [isDirty, handleDirty]);

  useUnmount(() => {
    handleChangeForm(isValid, getValues());
  });

  const prevIsValid = usePrevious(isValid);
  useEffect(() => {
    if (prevIsValid !== isValid) {
      handleChangeForm(isValid);
    }
  }, [isValid, handleChangeForm, prevIsValid]);

  const {
    fields: alternateResponseFields,
    append: appendAlternateResponse,
    remove: removeAlternateResponse,
  } = useFieldArray({
    control,
    name: "alternateCorrectResponses",
  });

  const [
    audioUrl,
    imageAltText,
    imageUrl,
    videoUrl,
    requiresHigherOrderThinking,
  ] = watch([
    "audioUrl",
    "imageAltText",
    "imageUrl",
    "videoUrl",
    "requiresHigherOrderThinking",
  ]);

  const _handleNextTab = async () => {
    handleNextTab();
  };

  const handleAddAlternateCorrectAnswers = () => {
    appendAlternateResponse({ response: "" });
  };

  const handleDeleteAlternateCorrectAnswer = (index: number) => {
    removeAlternateResponse(index);
  };

  const _handleSecondaryNextTab = () => {
    handleSecondaryNextTab?.({ numberResponse: getValues() });
  };

  const _handleInsertMedia = (
    imageUrl: string,
    imageAltText: string,
    audioUrl?: string,
    videoUrl?: string
  ) => {
    setValue("audioUrl", audioUrl ?? "");
    setValue("imageUrl", imageUrl);
    setValue("imageAltText", imageAltText);
    setValue("videoUrl", videoUrl ?? "");
    mediaModalProps.handleInsertMedia(
      imageUrl,
      imageAltText,
      audioUrl,
      videoUrl
    );
  };

  const _handleRemoveAudio = () => {
    setValue("audioUrl", "");
    mediaModalProps.handleRemoveAudio();
  };

  const _handleRemoveMedia = () => {
    setValue("audioUrl", "");
    setValue("imageUrl", "");
    setValue("imageAltText", "");
    setValue("videoUrl", "");
    mediaModalProps.handleRemoveImage();
  };

  const validateIsNumeric = (v: string) => {
    const vTrimmed = v.trim();
    const isInvalid = vTrimmed === "" || isNonNumericRegex.test(vTrimmed);
    return (
      !isInvalid || (t("createQuestion.errCorrectResponseNonNumeric") as string)
    );
  };

  return (
    <form onSubmit={handleSubmit(_handleSubmit)}>
      <SimpleGrid
        py={pxToRem(40)}
        px={[
          "admin.flyout.mobileXPadding",
          null,
          "admin.flyout.desktopXPadding",
        ]}
        gap={pxToRem(40)}
      >
        <QuestionTypeDescription
          icon="format_align_left_outlined"
          iconColor="utility.badge-green"
          title={t(
            "createNewNumberResponseFlyout.numberResponseQuestionDropdownTitle"
          )}
          subTitle={t(
            "createNewNumberResponseFlyout.numberResponseQuestionDropdownSubTitle"
          )}
        />
        <FormControl
          isRequired
          variant="adminFormControl"
          isInvalid={!!touchedFields.prompt && !!errors.prompt}
          minWidth={0}
        >
          <FormLabel>{t("createQuestion.labelPrompt")}</FormLabel>
          <Controller
            control={control}
            name="prompt"
            rules={{
              validate: {
                isNotEmpty: (v) =>
                  !isRichTextEmpty(v) ||
                  (t("createQuestion.errPromptRequired") as string),
              },
            }}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { isTouched, error },
            }) => (
              <RichTextEditor
                aria-label={t("createQuestion.placeholderPrompt")}
                ref={ref}
                name={name}
                handleChangeRaw={onChange}
                handleBlur={onBlur}
                value={value}
                placeholder={t("createQuestion.placeholderPrompt")}
                hasError={isTouched && !!error}
              />
            )}
          />

          <FormErrorMessage>{errors.prompt?.message}</FormErrorMessage>
        </FormControl>
        <QuestionAddMedia
          handleAddMedia={mediaModalProps.handleOpen}
          handleRemoveMedia={_handleRemoveMedia}
          imageAdded={imageUrl}
          imageAddedAltText={imageAltText}
          audioAdded={audioUrl}
          videoAdded={videoUrl}
        />
        <SimpleGrid gap={pxToRem(16)}>
          <FormControl
            isRequired
            variant="adminFormControl"
            isInvalid={
              !!errors.correctResponse && !!touchedFields.correctResponse
            }
          >
            <FormLabel>{t("createQuestion.labelCorrectResponse")}</FormLabel>
            <Input
              {...register("correctResponse", {
                required: t(
                  "createQuestion.errCorrectResponseRequired"
                ) as string,
                validate: {
                  validateIsNumeric,
                },
              })}
              placeholder={t("createQuestion.placeholderCorrectResponse")}
            />
            <FormErrorMessage>
              {errors.correctResponse?.message}
            </FormErrorMessage>
          </FormControl>
          {alternateResponseFields.map((field, index) => {
            return (
              <Flex key={field.id} flexDir="column">
                <Box display="flex" alignItems="center" gap={pxToRem(16)}>
                  <Input
                    placeholder={t(
                      "createQuestion.placeholderAlternateCorrectResponse"
                    )}
                    {...register(
                      `alternateCorrectResponses.${index}.response` as const,
                      {
                        validate: {
                          validateIsNumeric,
                        },
                      }
                    )}
                  />
                  <Box>
                    <IconCTAButton
                      ariaLabel={t("common.ariaLabelDeleteAlternateSpelling")}
                      onClick={() => handleDeleteAlternateCorrectAnswer(index)}
                    />
                  </Box>
                </Box>
                {!!errors.alternateCorrectResponses?.[index]?.response && (
                  <Text fontSize={pxToRem(12)} color="utility.error">
                    {
                      errors.alternateCorrectResponses?.[index]?.response
                        ?.message
                    }
                  </Text>
                )}
              </Flex>
            );
          })}

          <Box>
            <Button
              variant="adminButtonOutlined"
              leftIcon={<Icon icon="add" iconColor="primary.warm-black" />}
              size="sm"
              onClick={handleAddAlternateCorrectAnswers}
            >
              {t("createQuestion.addAlternateCorrectResponse")}
            </Button>
          </Box>
          <FormControl
            variant="adminFormControl"
            isInvalid={!!touchedFields.prefix && !!errors.prefix}
            minWidth={0}
          >
            <FormLabel>{t("createQuestion.labelPrefix")}</FormLabel>
            <Controller
              control={control}
              name="prefix"
              rules={{
                validate: {
                  isUnderCharLimit: (v) =>
                    getTotalCharCount(v) <= PREFIX_SUFFIX_MAX_LENGTH ||
                    (t("createQuestion.errInputLength", {
                      field: "Prefix",
                      length: PREFIX_SUFFIX_MAX_LENGTH,
                    }) as string),
                },
              }}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { isTouched, error },
              }) => (
                <RichTextEditor
                  ref={ref}
                  name={name}
                  handleChangeRaw={onChange}
                  handleBlur={onBlur}
                  value={value}
                  hasError={isTouched && !!error}
                  includeCharCountInChangeHandler
                />
              )}
            />

            <FormErrorMessage>{errors.prefix?.message}</FormErrorMessage>
          </FormControl>
          <FormControl
            variant="adminFormControl"
            isInvalid={!!touchedFields.suffix && !!errors.suffix}
            minWidth={0}
          >
            <FormLabel>{t("createQuestion.labelSuffix")}</FormLabel>
            <Controller
              control={control}
              name="suffix"
              rules={{
                validate: {
                  isUnderCharLimit: (v) =>
                    getTotalCharCount(v) <= PREFIX_SUFFIX_MAX_LENGTH ||
                    (t("createQuestion.errInputLength", {
                      field: "Suffix",
                      length: PREFIX_SUFFIX_MAX_LENGTH,
                    }) as string),
                },
              }}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { isTouched, error },
              }) => (
                <RichTextEditor
                  ref={ref}
                  name={name}
                  handleChangeRaw={onChange}
                  handleBlur={onBlur}
                  value={value}
                  hasError={isTouched && !!error}
                  includeCharCountInChangeHandler
                />
              )}
            />

            <FormErrorMessage>{errors.suffix?.message}</FormErrorMessage>
          </FormControl>
        </SimpleGrid>
        <CreateQuestionFooter
          canSubmit={canSubmit}
          isSubmitting={isSubmitting}
          requiresHigherOrderThinking={requiresHigherOrderThinking}
          handleChangeRequireHigherOrderThinking={(r) =>
            setValue("requiresHigherOrderThinking", r)
          }
          handleNextTab={_handleNextTab}
          handleSaveAndClose={handleSubmit(_handleSubmit)}
          nextSectionTextCopies={{
            nextSectionCta: hasFeedback
              ? t("createQuestion.editFeedback")
              : t("createQuestion.addFeedback"),
            nextSectionTitle: t("createQuestion.feedback"),
            nextSectionDescription: t("createQuestion.feedbackDescription"),
            nextSectionIcon: hasFeedback ? "edit_outlined" : "add",
            secondaryNextSectionCta: showSecondaryFooterCta
              ? t("createQuestion.feedbackGenerate")
              : undefined,
            secondaryNextSectionIcon: "zap",
            secondaryNextSectionOnClick: showSecondaryFooterCta
              ? _handleSecondaryNextTab
              : undefined,
          }}
        />
        <AddMediaModal
          {...mediaModalProps}
          audioUrlToAdd={audioUrl}
          imageUrlToAdd={imageUrl}
          imageAltText={imageAltText}
          videoUrlToAdd={videoUrl}
          handleInsertMedia={_handleInsertMedia}
          handleRemoveImage={_handleRemoveMedia}
          handleRemoveAudio={_handleRemoveAudio}
        />
      </SimpleGrid>
    </form>
  );
};
