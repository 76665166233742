import { Box, Portal, usePrefersReducedMotion } from "@chakra-ui/react";
import EventEmitter from "events";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { animated, config, useTransition } from "react-spring";
import { useInterval } from "react-use";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { useSessionScene } from "links/lib/contexts/sessionScene";
import {
  ISessionUser,
  ISessionUsers,
  SessionChatMessage,
  SessionScene,
  SessionWebSocketEvent,
} from "links/lib/types";
import {
  PORTRAIT_STICKER_TEXT_WIDTH,
  Sticker,
} from "sessionComponents/atoms/Sticker";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { useDetectOrientation } from "sessionComponents/theme/hooks/useDetectOrientation";
import { getAssetColorScheme } from "sessionComponents/utils/getAssetColorScheme";
import { getUserWithWarning } from "sessionComponents/utils/getUserWithWarning";

const AnimatedBox = animated(Box);

type Sticker = {
  id: number;
  user: ISessionUser;
  chatMessage: SessionChatMessage;
  timestamp: number;
  scene: SessionScene;
};

interface IStudentStickerPopupProps {
  users: ISessionUsers;
  events: EventEmitter;
  messageLifetime?: number; //number in MS
}

const LANDSCAPE_MSG_LIFETIME = 10000;
const PORTRAIT_MSG_LIFETIME = 5000;

export const StudentStickerPopup: React.FC<IStudentStickerPopupProps> = ({
  users,
  events,
  messageLifetime,
}) => {
  const [stickers, setStickers] = useState<Sticker[]>([]);
  const { isPortrait } = useDetectOrientation();
  const messageCounter = useRef<number>(0);

  const messageLifetimeMS =
    messageLifetime ?? isPortrait
      ? PORTRAIT_MSG_LIFETIME
      : LANDSCAPE_MSG_LIFETIME;

  const { match: currentBreakpoints } = useBreakpoints();
  const size = currentBreakpoints.avatarStickerSize;
  const prefersReducedMotion = usePrefersReducedMotion();
  const scene = useSessionScene();

  const bottom = isPortrait
    ? pxToRem(currentBreakpoints.headerHeight + currentBreakpoints.margin)
    : pxToRem(currentBreakpoints.margin);

  const stickerTransitions = useTransition(stickers, {
    from: {
      opacity: 1,
      y: prefersReducedMotion ? 0 : 200,
    },
    enter: {
      opacity: 1,
      y: 0,
    },
    leave: {
      opacity: 0,
      y: prefersReducedMotion ? 0 : 200,
    },
    trail: 500,
    sort: (a, b) => a.timestamp - b.timestamp,
    keys: ({ id }) => id,
    config: config.stiff,
  });

  // calculates the current left positions for active stickers and updated last left positions
  const stickerLeftMap = useMemo(() => {
    const map: { [key: number]: string } = {};

    stickers.forEach((sticker, i) => {
      let position = size * i + "px";
      // in Portrait, stickers start at the right and move left
      // in Landscape, stickers start at the left and move right
      if (isPortrait) position = `-${position}`;
      map[sticker.id] = position;
    });

    return map;
  }, [stickers, size, isPortrait]);

  useInterval(() => {
    setStickers((prevState) => {
      const now = Date.now();

      const potentialState = prevState
        .filter((sticker) => {
          return now - sticker.timestamp <= messageLifetimeMS;
        })
        .filter((m) => m.scene === scene);

      if (potentialState.length === prevState.length) return prevState;

      return potentialState;
    });
  }, 200);

  // new sticker message handler
  const handleNewStickerReq = useCallback(
    (args: { sender_id: string; chat_message: SessionChatMessage }) => {
      const { sender_id, chat_message } = args;

      setStickers((prevState) => {
        const user = getUserWithWarning(users, sender_id);
        if (!user) return prevState;

        messageCounter.current += 1;

        return prevState
          .filter((m) => m.user.id !== sender_id)
          .concat({
            id: messageCounter.current,
            chatMessage: chat_message,
            user,
            timestamp: Date.now(),
            scene,
          });
      });
    },
    [users, scene]
  );

  useEffect(() => {
    events.on(SessionWebSocketEvent.ChatMessage, handleNewStickerReq);

    return () => {
      events.off(SessionWebSocketEvent.ChatMessage, handleNewStickerReq);
    };
  }, [handleNewStickerReq, events]);

  const removeSticker = (id: number) => {
    setStickers((prevStickers) => {
      return prevStickers.filter((sticker) => sticker.id !== id);
    });
  };

  return (
    <Portal>
      <Box
        position="fixed"
        w="1px"
        h="1px"
        overflow="visible"
        bottom={bottom}
        right={
          isPortrait
            ? pxToRem(PORTRAIT_STICKER_TEXT_WIDTH + currentBreakpoints.margin)
            : undefined
        } // right-align stickers on portrait
        left={isPortrait ? undefined : pxToRem(currentBreakpoints.margin)} // left-align stickers on landscape
        zIndex="2"
      >
        {stickerTransitions(({ opacity, y }, sticker) => (
          <AnimatedBox
            w="fit-content"
            h="fit-content"
            position="absolute"
            bottom="0"
            left={() => {
              return stickerLeftMap[sticker.id]
                ? stickerLeftMap[sticker.id]
                : 0;
            }}
            style={{ opacity, transform: y.to((y) => `translateY(${y}px)`) }}
            display="flex"
            justifyContent="flex-end"
            transition={
              prefersReducedMotion
                ? ""
                : "left 200ms linear, right 200ms linear"
            }
          >
            <Sticker
              chatMessage={sticker.chatMessage}
              userName={sticker.user.name}
              userProfileImageUrl={sticker.user.profile_image_url}
              userColorScheme={getAssetColorScheme(sticker.user.color_scheme)}
              show
              onClick={() => removeSticker(sticker.id)}
            />
          </AnimatedBox>
        ))}
      </Box>
    </Portal>
  );
};
