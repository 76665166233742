import { Box, Image, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { useDetectOrientation } from "sessionComponents/theme/hooks/useDetectOrientation";

import NextTimeSvg from "./resource/nextTime.svg";

export const NoWinnersMessage: React.FC = () => {
  const { t } = useTranslation("session", {
    keyPrefix: "prizeRoundAwards",
    useSuspense: false,
  });
  const { match: currentBreakpoints } = useBreakpoints();
  const { isPortrait } = useDetectOrientation();

  return (
    <Box
      w="full"
      h="full"
      display="flex"
      flexDir="column"
      alignItems="center"
      justifyContent="center"
      m="auto"
    >
      <Image w="full" maxW={isPortrait ? "100vw" : "50vw"} src={NextTimeSvg} />
      <Text
        fontSize={pxToRem(currentBreakpoints.fontSize)}
        color="primary.white"
        textStyle="gameDisplay"
      >
        {t("noWinnersText")}
      </Text>
    </Box>
  );
};
