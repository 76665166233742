import {
  Modal as ChakraModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";

import { pxToRem } from "adminComponents/utils";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { MenuListDivider } from "sessionComponents/molecules/MenuList";
import { useDetectOrientation } from "sessionComponents/theme/hooks/useDetectOrientation";

interface IModalProps {
  isOpen: boolean;
  onClose: () => void;
  modalHeaderContent?: React.ReactNode;
  landscapeSize?:
    | "xs"
    | "sm"
    | "md"
    | "lg"
    | "xl"
    | "2xl"
    | "3xl"
    | "4xl"
    | "5xl"
    | "6xl"
    | "full";
}

export const Modal: React.FC<IModalProps> = ({
  isOpen,
  onClose,
  landscapeSize = "md",
  modalHeaderContent,
  children,
}) => {
  const { isPortrait } = useDetectOrientation();
  const { match: currentBreakpoints } = useBreakpoints();

  return (
    <ChakraModal
      isOpen={isOpen}
      onClose={onClose}
      size={isPortrait ? "full" : landscapeSize}
    >
      <ModalOverlay />

      <ModalContent borderRadius={isPortrait ? 0 : "1rem"} minW="fit-content">
        <ModalCloseButton backgroundColor="white" />
        {modalHeaderContent && (
          <>
            <ModalHeader>{modalHeaderContent}</ModalHeader>
            <MenuListDivider />
          </>
        )}

        <ModalBody padding={pxToRem(currentBreakpoints.padding)}>
          {children}
        </ModalBody>
      </ModalContent>
    </ChakraModal>
  );
};
