import { Flex } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Card } from "adminComponents/atoms/Card";
import { Heading } from "adminComponents/atoms/Heading";
import { AdminFlyout } from "adminComponents/molecules/Flyout";
import { StudentAssignmentCard } from "adminComponents/molecules/StudentAssignmentCard";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { IAssignment, IPendingSession } from "links/lib/types";

export interface IMyAssignment {
  assignment: IAssignment;
  accuracy: number;
  rewardXp: number;
  isDueToday: boolean;
}

export interface IProps {
  isOpen: boolean;
  handleClose: () => void;
  handlePlayButton: (assignment: IAssignment) => void;
  myAssignments?: IMyAssignment[];
  pendingSession?: IPendingSession;
}

export const MyAssignmentsFlyout: React.FC<IProps> = ({
  isOpen,
  handleClose,
  handlePlayButton,
  myAssignments,
  pendingSession,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  return (
    <AdminFlyout
      isOpen={isOpen}
      onClose={handleClose}
      title={t("myAssignmentsFlyout.title")}
    >
      <Flex
        direction="column"
        gap={{ base: pxToRem(32), md: pxToRem(40) }}
        paddingX={{
          base: "admin.flyout.mobileXPadding",
          md: "admin.flyout.desktopXPadding",
        }}
        paddingBottom={{ base: pxToRem(17), md: pxToRem(56) }}
      >
        {myAssignments && myAssignments.length > 0 ? (
          myAssignments.map((myAssignment, index) => {
            const { assignment, accuracy, rewardXp, isDueToday } = myAssignment;
            return (
              <StudentAssignmentCard
                key={index}
                assignment={assignment}
                accuracy={accuracy}
                rewardXp={rewardXp}
                isDueToday={isDueToday}
                handlePlayButton={() => handlePlayButton(assignment)}
                isDisabled={!!pendingSession}
                isLoading={pendingSession?.assignmentId === assignment.id}
              />
            );
          })
        ) : (
          <Card variant="adminCardSmallBorder" borderColor="primary.tan">
            <Heading variant="adminH6" as="p" textAlign="center">
              {t("myAssignmentsFlyout.emptyCardTitle")}
            </Heading>
          </Card>
        )}
      </Flex>
    </AdminFlyout>
  );
};
