import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { pxToRem } from "../../../adminComponents/utils/pxToRem";
import {
  IGameAssetColorScheme,
  SessionChatMessage,
  SessionGameType,
} from "links/lib/types";
import { useDetectOrientation } from "sessionComponents/theme/hooks/useDetectOrientation";

import { Sticker } from "../../atoms/Sticker";
import { useBreakpoints } from "../../contexts/breakpoints";

interface IStickerSelectionModalProps {
  outerGame?: SessionGameType;
  visible: boolean;
  sendChatMessage: (msg: SessionChatMessage) => void;
  userName: string;
  userProfileImageUrl: string;
  userColorScheme: IGameAssetColorScheme;
  onClose: () => void;
}

export const StickerSelectionModal: React.FC<IStickerSelectionModalProps> = ({
  outerGame = SessionGameType.TheBigBoard,
  visible,
  sendChatMessage,
  userName,
  userProfileImageUrl,
  userColorScheme,
  onClose,
}) => {
  const {
    match: { fontSize, margin },
  } = useBreakpoints();
  const { isPortrait } = useDetectOrientation();
  const { t } = useTranslation("session", {
    useSuspense: false,
    keyPrefix: "stickerSelectionModal",
  });

  //todo the first filter can be removed when the old messages are gone from the type
  const messagesArray = Object.keys(SessionChatMessage)
    .map((msg) => SessionChatMessage[msg as keyof typeof SessionChatMessage])
    .filter((msg) => msg !== SessionChatMessage.ThisOne)
    .filter((msg) => {
      if (outerGame === SessionGameType.TheBigBoard) {
        return ![
          SessionChatMessage.Scorching,
          SessionChatMessage.OnFire,
        ].includes(msg);
      } else if (outerGame === SessionGameType.QuickPlay) {
        return ![
          SessionChatMessage.DreamTeam,
          SessionChatMessage.NiceWork,
        ].includes(msg);
      }
      return true;
    });

  const handleSendChatMessage = (chatMessage: SessionChatMessage) => {
    sendChatMessage(chatMessage);
    onClose();
  };

  return (
    <>
      {visible && (
        <Modal
          isOpen={visible}
          onClose={onClose}
          size={isPortrait ? "full" : undefined}
        >
          <ModalOverlay />
          <ModalContent minWidth="fit-content">
            <ModalHeader>
              <Text textStyle="gameDisplay" fontSize={fontSize}>
                {t("title")}
              </Text>
            </ModalHeader>
            <ModalCloseButton onClick={onClose} />
            <ModalBody>
              <SimpleGrid columns={isPortrait ? 1 : 4}>
                {messagesArray.map((msg) => (
                  <Box
                    key={msg}
                    marginRight={pxToRem(margin)}
                    cursor="pointer"
                    onClick={() => handleSendChatMessage(msg)}
                    as="button"
                  >
                    <Sticker
                      chatMessage={msg}
                      show
                      showAvatar={!isPortrait}
                      userColorScheme={userColorScheme}
                      userName={userName}
                      userProfileImageUrl={userProfileImageUrl}
                      useLargeSizeOnMobile
                    />
                  </Box>
                ))}
              </SimpleGrid>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};
