import { Box, BoxProps, useStyleConfig } from "@chakra-ui/react";
import React from "react";

import { CertifiedCheckInvertedSVG, CertifiedCheckSVG } from "./components";

export interface IProps extends BoxProps {
  inverted?: boolean;
}

export const CertifiedCheckIcon = React.forwardRef<HTMLDivElement, IProps>(
  (p, ref) => {
    const { inverted, style, ...rest } = p;

    const baseStyles = useStyleConfig("AdminIcon", {
      variant: "certifiedCheck",
    });

    return (
      <Box {...rest} __css={{ ...baseStyles, ...style }} ref={ref}>
        {inverted ? <CertifiedCheckInvertedSVG /> : <CertifiedCheckSVG />}
      </Box>
    );
  }
);

CertifiedCheckIcon.displayName = "CertifiedCheckIcon";
