import themeColors from "theme/colors";

const colors = Object.assign(themeColors, {
  primary: {
    "warm-black": "#191404",
    "dark-gray": "#757575",
    "medium-gray": "#D3D3D3",
    "light-gray": "#EAEAEA",
    "lightest-gray": "#F7F7F7",
    white: "#FFF",
    "white-hover": "#FFF",
    "white-disabled": "#EAEAEA",
    "warm-white": "#FDFAF3",
    tan: "#F1E9DA",
    "tan-dark": "#F5E6CD",
    "tan-light": "#FEFAF3",
    "golden-hover": "#FFB018",
    "golden-disabled": "#FFE166",
    golden: "#FFC629",
    "golden-medium": "#FFE166",
    "golden-light": "#FFEEBF",
    lilac: "#686BA6",
    monaco: "#00A3DE",
  },
  utility: {
    link: "#006D77",
    "link-hover": "#002C30",
    focus: "#2EB1A5",
    success: "#5C7300",
    error: "#D12A77",
    "error-light": "#FDE5F4",
    "error-hover": "#541130",
    "badge-blue": "#BCEEEB",
    "badge-dark-gray": "#364443",
    "badge-purple": "#D9D9EB",
    "badge-green": "#F0F19F",
    "badge-red": "#FED1C4",
    "badge-grey": "#EAEAEA",
    "badge-green-grey": "#DCE2DC",
    "badge-orange": "#FDECC7",
    "badge-light-blue": "#9BD0EE",
    "top-green": "#D5F4F3",
    "question-green": "#9CC300",
    "question-red": "#D12A2A",
    orange: "#F1A83F",
    "light-orange": "#FBE5C7",
    "dark-orange": "#FA553A",
    "navigation-bg-blue": "#CFEEFF",
    "navigation-bg-gray": "#E7E7E7",
    "clever-button-bg": "#4E73EE",
    "clever-button-bg-hover": "#335ADC",
    "microsoft-button-bg": "#549DF6",
    "microsoft-button-bg-hover": "#3484E6",
    premium: "#FFC629",
    salmon: "#FEC2B0",
    "grade-level-bg": "#1A9688",
  },
  "season-map": {
    "zone-unlock-bg": "#1a3c34",
    "transition-bg": "#D9DC3F",
  },
  "xp-boost": {
    "timer-bg": "#FBD6EE",
  },
  "giant-steps": {
    orange: "#ff7511",
    white: "#ffffff",
    tan: "#f1e9Da",
    mustard: "#d0a444",
  },
  "pear-practice": {
    chalkboard: "#195e44",
    "cb-shade-1": "#103b2b",
    "blue-skies": "#cfeeff",
  },
});

const colorMapping: Record<string, string> = {
  "primary.dark-gray": colors.primary["dark-gray"],
  "primary.golden": colors.primary.golden,
  "primary.golden-hover": colors.primary["golden-hover"],
  "primary.golden-light": colors.primary["golden-light"],
  "primary.golden-disabled": colors.primary["golden-disabled"],
  "primary.golden-medium": colors.primary["golden-medium"],
  "primary.light-gray": colors.primary["light-gray"],
  "primary.lightest-gray": colors.primary["lightest-gray"],
  "primary.lilac": colors.primary.lilac,
  "primary.monaco": colors.primary.monaco,
  "primary.medium-gray": colors.primary["medium-gray"],
  "primary.tan": colors.primary.tan,
  "primary.tan-dark": colors.primary["tan-dark"],
  "primary.tan-light": colors.primary["tan-light"],
  "primary.warm-black": colors.primary["warm-black"],
  "primary.warm-white": colors.primary["warm-white"],
  "primary.white": colors.primary["white"],
  "primary.white-hover": colors.primary["white-hover"],
  "primary.white-disabled": colors.primary["white-disabled"],
  "utility.badge-blue": colors.utility["badge-blue"],
  "utility.badge-green": colors.utility["badge-green"],
  "utility.badge-grey": colors.utility["badge-grey"],
  "utility.badge-purple": colors.utility["badge-purple"],
  "utility.badge-red": colors.utility["badge-red"],
  "utility.badge-green-grey": colors.utility["badge-green-grey"],
  "utility.badge-orange": colors.utility["badge-orange"],
  "utility.badge-light-blue": colors.utility["badge-light-blue"],
  "utility.top-green": colors.utility["top-green"],
  "utility.error": colors.utility.error,
  "utility.error-light": colors.utility["error-light"],
  "utility.error-hover": colors.utility["error-hover"],
  "utility.light-orange": colors.utility["light-orange"],
  "utility.dark-orange": colors.utility["dark-orange"],
  "utility.link": colors.utility.link,
  "utility.link-hover": colors.utility["link-hover"],
  "utility.focus": colors.utility["focus"],
  "utility.orange": colors.utility.orange,
  "utility.question-green": colors.utility["question-green"],
  "utility.question-red": colors.utility["question-red"],
  "utility.success": colors.utility.success,
  "utility.navigation-bg-blue": colors.utility["navigation-bg-blue"],
  "utility.navigation-bg-gray": colors.utility["navigation-bg-gray"],
  "utility.clever-button-bg": colors.utility["clever-button-bg"],
  "utility.clever-button-bg-hover": colors.utility["clever-button-bg-hover"],
  "utility.microsoft-button-bg": colors.utility["microsoft-button-bg"],
  "utility.microsoft-button-bg-hover":
    colors.utility["microsoft-button-bg-hover"],
  "utility.salmon": colors.utility.salmon,
  "utility.grade-level-bg": colors.utility["grade-level-bg"],
  "season-map.zone-unlock-bg": colors["season-map"]["zone-unlock-bg"],
  "season-map.transition-bg": colors["season-map"]["transition-bg"],
  "utility.premium": colors["utility"]["premium"],
  "giant-steps.orange": colors["giant-steps"]["orange"],
  "giant-steps.white": colors["giant-steps"]["white"],
  "pear-practice.chalkboard": colors["pear-practice"]["chalkboard"],
  "pear-practice.cb-shade-1": colors["pear-practice"]["cb-shade-1"],
  "pear-practice.blue-skies": colors["pear-practice"]["blue-skies"],
};

for (const [color, variants] of Object.entries(themeColors)) {
  for (const [subColor, hex] of Object.entries(variants)) {
    colorMapping[`${color}.${subColor}`] = hex;
  }
}

export { colors, colorMapping };
