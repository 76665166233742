import button from "./button";
import container from "./container";
import heading from "./heading";
import input from "./input";
import link from "./link";
import popover from "./popover";
import progress from "./progress";
import text from "./text";

export default {
  Button: button,
  Text: text,
  Heading: heading,
  Link: link,
  Container: container,
  Input: input,
  Popover: popover,
  Progress: progress,
};
