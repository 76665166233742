import { useEffect, useState } from "react";
import { usePrevious } from "react-use";

import { useErrorToast } from "adminComponents/utils/toast";
import { useFetchClassroomPracticeSetItemAccuracies } from "links/lib/features/classrooms";
import { IUseFetchClassroomRecentPracticeSetResponsesResult } from "links/lib/features/classrooms/useFetchClassroomRecentPracticeSetResponses";
import { IFetchPracticeSetItemsResponse } from "links/lib/features/practiceSetItems/useFetchPracticeSetItems";
import {
  IPracticeSetItem,
  IPracticeSetItemWithClassroomResults,
} from "links/lib/types";

import {
  getPracticeSetItemResponseStatsMap,
  getPrompt,
  getResponseData,
} from "../utils/practiceSetItemsHelpers";

interface IUseQuestionsData {
  practiceSetItemsData?: IFetchPracticeSetItemsResponse;
  classroomPracticeSetResponses?: IUseFetchClassroomRecentPracticeSetResponsesResult;
  classroomId: string;
  practiceSetId: string;
  studentId?: string;
  assignmentId?: string;
  practiceSetSessionId?: string;
}

interface IUseQuestionDataResponse {
  questionsData: IPracticeSetItemWithClassroomResults[];
  isLoading: boolean;
}

export const useQuestionsData = ({
  classroomId,
  practiceSetId,
  practiceSetItemsData,
  classroomPracticeSetResponses,
  studentId,
  assignmentId,
  practiceSetSessionId,
}: IUseQuestionsData): IUseQuestionDataResponse => {
  const [questionsData, setQuestionsData] = useState<{
    isSet: boolean;
    data: IPracticeSetItemWithClassroomResults[];
  }>({ isSet: false, data: [] });

  const {
    data: classroomPracticesetAccuracyData,
    isLoading,
    error: classroomPracticeSetAccuracyError,
  } = useFetchClassroomPracticeSetItemAccuracies({
    classroom_id: classroomId,
    practice_set_id: practiceSetId,
    user_id: studentId,
    assignment_id: assignmentId,
    practice_set_session_id: practiceSetSessionId,
  });

  useErrorToast(classroomPracticeSetAccuracyError);

  const prevAssignmentId = usePrevious(assignmentId);
  const prevPracticeSetSessionId = usePrevious(practiceSetSessionId);
  useEffect(() => {
    if (
      prevAssignmentId !== assignmentId ||
      prevPracticeSetSessionId !== practiceSetSessionId
    ) {
      setQuestionsData((questionsData) => ({ ...questionsData, isSet: false }));
    }
  }, [
    assignmentId,
    practiceSetSessionId,
    prevAssignmentId,
    prevPracticeSetSessionId,
  ]);

  useEffect(() => {
    if (
      practiceSetItemsData?.practice_set_items.length &&
      classroomPracticeSetResponses?.user_responses &&
      classroomPracticesetAccuracyData?.average_practice_set_item_accuracies &&
      !questionsData.isSet
    ) {
      const practiceSetItemMap = new Map<
        string,
        { item: IPracticeSetItem; accuracy?: number }
      >();
      practiceSetItemsData?.practice_set_items.forEach((practiceSetItem) =>
        practiceSetItemMap.set(practiceSetItem.id, { item: practiceSetItem })
      );
      classroomPracticesetAccuracyData?.average_practice_set_item_accuracies.forEach(
        (itemAccuracy) => {
          const item = practiceSetItemMap.get(
            itemAccuracy.practice_set_item_id
          );
          if (item) {
            practiceSetItemMap.set(itemAccuracy.practice_set_item_id, {
              ...item,
              accuracy: itemAccuracy.average_accuracy,
            });
          }
        }
      );

      const practiceSetAnswersStats = getPracticeSetItemResponseStatsMap(
        classroomPracticeSetResponses
      );

      const newPracticeSetItemData: IPracticeSetItemWithClassroomResults[] = [];
      practiceSetItemMap.forEach((practiceSetItem) => {
        const practiceSetItemStats = practiceSetAnswersStats.get(
          practiceSetItem.item.id
        );
        const total_responses = practiceSetItemStats?.totalAnswers || 0;
        const correct_responses = practiceSetItemStats?.correctResponses || 0;
        const responses = practiceSetItemStats?.responses || [];

        responses.forEach((response) => {
          if (!response.response_text) {
            response.response_text =
              getResponseData(practiceSetItem.item, response.choice_id)?.text ||
              "";
          }
        });

        newPracticeSetItemData.push({
          practiceSetItem: practiceSetItem.item,
          classroomResults: {
            accuracy: practiceSetItem.accuracy || 0,
            correct_responses,
            practice_set_item_id: practiceSetItem.item.id,
            prompt: getPrompt(practiceSetItem.item) || "",
            total_responses,
            responses,
            question_type: practiceSetItem.item.question_type,
          },
        });
      });

      setQuestionsData({ isSet: true, data: newPracticeSetItemData });
    }
  }, [
    practiceSetItemsData,
    classroomPracticesetAccuracyData,
    questionsData.isSet,
    classroomPracticeSetResponses,
  ]);

  return {
    questionsData: questionsData.data,
    isLoading,
  };
};
