import { colors } from "../../../adminComponents/theme/colors";

export const Input = {
  variants: {
    gameInput: (props: { [key: string]: string }): Record<string, unknown> => ({
      field: {
        borderRadius: props.borderRadius,
        bg: "white",
        borderColor: props.error ? "utility.question-red" : "primary.tan",
        backgroundColor: props.error ? "utility.badge-red" : "primary.white",
        borderStyle: "solid",
        color: "primary.warm-black",
        textStyle: "gameText",
        "::placeholder": {
          color: "primary.medium-gray",
        },
        height: props.height,
        fontSize: props.fontSize,
        // todo more of these too
        borderWidth: props.borderWidth,
        caretColor: colors.utility.link,
        // _focus: {
        //   boxShadow: "none",
        //   borderColor: "golden.base",
        // },
      },
    }),
  },
};
