import { useDisclosure } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useMount } from "react-use";

import { isRichTextEmpty } from "adminComponents/organisms/RichTextEditor/util";
import { useSessionActions } from "links/lib/contexts/sessionActions";
import { useSessionGameState } from "links/lib/contexts/sessionGameState";
import { useSessionGroups } from "links/lib/contexts/sessionGroups";
import { useSessionRoundState } from "links/lib/contexts/sessionRoundState";
import { useSessionScene } from "links/lib/contexts/sessionScene";
import usePendingSubmit from "links/lib/hooks/usePendingSubmit";
import { CoopDragsortVariantData, SessionGameType } from "links/lib/types";
import { ClassificationRoundReview } from "sessionComponents/organisms/ClassificationRoundReview";
import { FeedbackFlyout } from "sessionComponents/organisms/FeedbackFlyout";
import { TeacherSessionSoundEffect } from "sessionComponents/types";
import { getQuestionPrompt } from "sessionComponents/utils/getQuestionPrompt";
import { useSessionAudio } from "sharedComponents/contexts/sessionAudio";
import { useSafeRoundGroupStateArray } from "sharedComponents/hooks/useSafeRoundGroupStateArray";

import { SplashScene, SplashType } from "./SplashScene";

export const TeacherClassificationRoundReview: React.FC = () => {
  const roundState = useSessionRoundState();
  const roundGroupsState = useSafeRoundGroupStateArray();
  const gameState = useSessionGameState();
  const {
    practice_set_session_item,
    rich_feedback,
    round_number,
    variant,
    variant_data,
  } = roundState;
  const dragsortVariantData = variant_data as CoopDragsortVariantData;
  const groups = useSessionGroups();
  const scene = useSessionScene();
  const { play: playAudio } = useSessionAudio();
  const isQuickPlay = gameState?.game_type === SessionGameType.QuickPlay;
  const { initiateOuterGame, initiateNextRound, initiateLeaderBoard } =
    useSessionActions();

  const sceneChangeSubmit = usePendingSubmit([scene]);

  useMount(() => {
    playAudio(TeacherSessionSoundEffect.ReviewSting);
  });

  const groupTotal = Object.keys(groups).length;

  const handleConfirmButtonClick = () => {
    const isThirdQuestion = !(round_number % 3);
    let toNextScene = initiateOuterGame;
    if (isQuickPlay) {
      // initateNextRound will handle ending the game for us if this is the last question
      toNextScene = isThirdQuestion ? initiateLeaderBoard : initiateNextRound;
    }

    toNextScene();

    sceneChangeSubmit.handleSubmit();
  };

  const prompt = getQuestionPrompt(
    practice_set_session_item.question_type,
    practice_set_session_item
  );

  const categories = practice_set_session_item.classify?.categories || [];
  const items = useMemo(
    () => dragsortVariantData?.coop_dragsort?.items || [],
    [dragsortVariantData?.coop_dragsort?.items]
  );

  const accuracyPercentages = useMemo(() => {
    const correctZoneByItemIdMap: { [key: string]: string } = items.reduce(
      (map, item) => {
        map[item.id] = item.zone_id || "";
        return map;
      },
      {} as { [key: string]: string }
    );

    const allGroupsAnswers = roundGroupsState.flatMap(
      (groupState) => groupState.dragsort?.items || []
    );

    const correctAnswerCountByItemIdMap: { [key: string]: number } =
      allGroupsAnswers.reduce((map, answer) => {
        const currentCount = map[answer.id] || 0;
        const isCorrect = answer.zone_id === correctZoneByItemIdMap[answer.id];
        if (isCorrect) map[answer.id] = currentCount + 1;
        else map[answer.id] = currentCount;
        return map;
      }, {} as { [key: string]: number });

    // after getting the total correct counts, convert into percentage of accurate answers to total students playing

    const accuracyPercentages: { [key: string]: number } = {};

    // Denominator is the number of groups, since dragsort items share state across all members of a group
    Object.keys(correctAnswerCountByItemIdMap).forEach(
      (itemId) =>
        (accuracyPercentages[itemId] =
          (correctAnswerCountByItemIdMap[itemId] / groupTotal) * 100)
    );

    return accuracyPercentages;
  }, [items, roundGroupsState, groupTotal]);

  const {
    isOpen: isFeedbackOpen,
    onOpen: onFeedbackOpen,
    onClose: onFeedbackClose,
  } = useDisclosure();
  const showFeedbackButton =
    !isRichTextEmpty(rich_feedback?.text) || !!rich_feedback?.image_url;

  return (
    <>
      <ClassificationRoundReview
        outerGame={gameState?.game_type}
        isClassSession
        splashScene={<SplashScene splashType={SplashType.TeacherReview} />}
        prompt={prompt}
        categories={categories}
        items={items}
        getIsItemCorrect={() => true} // only show items as correct in teacher view
        onFeedbackClick={onFeedbackOpen}
        showFeedbackButton={showFeedbackButton}
        onConfirmButtonClick={handleConfirmButtonClick}
        isConfirmLoading={sceneChangeSubmit.isPending}
        isTeacher
        showAccuracyBar
        accuracyPercentages={accuracyPercentages}
        variant={variant}
        questionType={practice_set_session_item.question_type}
      />
      <FeedbackFlyout
        isOpen={isFeedbackOpen}
        onClose={onFeedbackClose}
        imageUrl={rich_feedback?.image_url}
        imageAltText={rich_feedback?.image_alt_text}
        text={rich_feedback?.text}
      />
    </>
  );
};
