import {
  Box,
  Circle,
  Flex,
  HStack,
  Show,
  Wrap,
  WrapItem,
  useBreakpointValue,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Avatar } from "adminComponents/atoms/Avatar";
import { Button } from "adminComponents/atoms/Button";
import { Card } from "adminComponents/atoms/Card";
import { CertifiedText } from "adminComponents/atoms/CertifiedText";
import { Divider } from "adminComponents/atoms/Divider";
import { ExpandableText } from "adminComponents/atoms/ExpandableText";
import { Heading } from "adminComponents/atoms/Heading";
import { Icon } from "adminComponents/atoms/Icon";
import { PremiumText } from "adminComponents/atoms/PremiumText";
import { PremiumTooltipRich } from "adminComponents/atoms/PremiumTooltipRich";
import { RateButton } from "adminComponents/atoms/RateButton";
import { SubmarkLogo } from "adminComponents/atoms/SubmarkLogo";
import { Tag } from "adminComponents/atoms/Tag";
import { Text } from "adminComponents/atoms/Text";
import { TextLink } from "adminComponents/atoms/TextLink";
import { VerticalTextDivider } from "adminComponents/atoms/VerticalTextDivider";
import CoverImageBlob from "adminComponents/molecules/CoverImageBlob";
import { colors } from "adminComponents/theme/colors";
import { isPremiumItem } from "adminComponents/utils/isPremiumItem";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { useAuth } from "links/lib/features/auth";
import { useFetchPracticeSetItems } from "links/lib/features/practiceSetItems";
import { IPracticeSet } from "links/lib/types";

export interface IPracticeSetInformationCardPublicProps {
  practiceSet: IPracticeSet;
  personalRating?: number;
  handleDuplicate?: (practiceSet: IPracticeSet) => void;
  handlePreview?: (practiceSet: IPracticeSet) => void;
  handleCreateAssignment?: () => void;
  handleLiveSession?: (practiceSet: IPracticeSet) => void;
  handleRate?: (rating: number) => void;
  hideButtons?: boolean;
  disableButtons?: boolean;
  isCopiedToLibrary?: boolean;
  authorName?: string;
  authorCustomUrlCode?: string;
  authorProfileImageUrl?: string;
  authorProfileIsPrivate?: boolean;
  authorIsInternalContentSpecialist?: boolean;
  isLoading?: boolean;
  showPremiumMarker?: boolean;
}

export const PracticeSetInformationCardPublic: React.FC<
  IPracticeSetInformationCardPublicProps
> = ({
  handleDuplicate,
  handlePreview,
  handleCreateAssignment,
  handleLiveSession,
  handleRate,
  practiceSet,
  personalRating,
  hideButtons,
  disableButtons,
  isLoading,
  authorName,
  authorCustomUrlCode,
  authorProfileImageUrl,
  authorProfileIsPrivate,
  authorIsInternalContentSpecialist,
  showPremiumMarker = true,
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });
  const { hasNoPremiumAccess } = useAuth();
  const practiceSetItemFetch = useFetchPracticeSetItems({
    practice_set_id: practiceSet.id,
  });

  const coverImageSize = useBreakpointValue({ base: 100, md: 140 });
  const arrangeCreatorAttributesVertically = useBreakpointValue({
    base: true,
    lg: false,
  });

  const allItemsArePremium = useMemo(() => {
    const practiceSetItems =
      practiceSetItemFetch.data?.practice_set_items || [];
    return practiceSetItems.length > 0 && practiceSetItems.every(isPremiumItem);
  }, [practiceSetItemFetch.data?.practice_set_items]);
  const premiumActionsDisallowed =
    hasNoPremiumAccess && (practiceSet.is_premium || allItemsArePremium);

  const styles = useMultiStyleConfig(
    "AdminPracticeSetInformationCardPublicBody",
    {}
  );

  const isActionsRestrictedSet =
    practiceSet.is_how_to_play || practiceSet.is_digital_citizenship;

  return (
    <Card
      borderColor="primary.tan"
      backgroundColor="primary.white"
      variant="adminCardThickBorder"
      noPadding
    >
      <Box __css={styles.container}>
        {/* Header (Menu buttons) */}
        <Box __css={styles.header}>
          {practiceSet && practiceSet.rating && handleRate && (
            <RateButton
              rating={practiceSet.rating}
              personalRating={personalRating || 0}
              handleRate={handleRate}
              upvoteAriaLabel={t(
                "practiceSetInformationCardPublic.upvoteAriaLabel"
              )}
              downvoteAriaLabel={t(
                "practiceSetInformationCardPublic.downvoteAriaLabel"
              )}
            />
          )}
        </Box>
        {/* Body (Cover Image, Heading, Description) */}
        <Box __css={styles.body}>
          <Box __css={styles.coverImageContainer}>
            <CoverImageBlob
              backgroundColorScheme={practiceSet.cover_image_bg_color_scheme}
              backgroundPattern={practiceSet.cover_image_bg_pattern}
              icon={practiceSet.cover_image_icon}
              boxSize={coverImageSize || 140}
            />
          </Box>
          <Box w="full">
            {practiceSet.title && (
              <Heading
                as="h1"
                variant="adminH2"
                noOfLines={{ base: 4, md: 3 }}
                wordBreak="normal"
                overflowWrap="anywhere"
              >
                {practiceSet.title}
              </Heading>
            )}
            {practiceSet.description && (
              <ExpandableText
                noOfLines={{ base: 3, md: 2 }}
                textVariant="adminP1"
                color="primary.warm-black"
                pt={pxToRem(12)}
                wordBreak="normal"
                overflowWrap="anywhere"
              >
                {practiceSet.description}
              </ExpandableText>
            )}

            <Flex
              sx={styles.authorContainer}
              flexDirection={
                arrangeCreatorAttributesVertically ? "column" : "row"
              }
            >
              <HStack spacing={pxToRem(12)}>
                <CertifiedText
                  type="set"
                  isCertified={practiceSet.is_certified}
                />

                {showPremiumMarker && practiceSet.is_premium && (
                  <PremiumText type="set" />
                )}
              </HStack>

              {!arrangeCreatorAttributesVertically && <VerticalTextDivider />}

              <Text variant="adminP1" color="primary.dark-gray">
                <HStack spacing={pxToRem(8)}>
                  <Text
                    variant="adminP1"
                    color="primary.dark-gray"
                    sx={{ textWrap: "nowrap" }}
                  >
                    {t("practiceSetInformationCardPublic.createdBy")}
                  </Text>
                  {authorIsInternalContentSpecialist && (
                    <Circle
                      bgColor={colors["pear-practice"]["blue-skies"]}
                      size={pxToRem(24)}
                      p={pxToRem(4)}
                      overflow="hidden"
                    >
                      <SubmarkLogo />
                    </Circle>
                  )}
                  {!authorIsInternalContentSpecialist &&
                    authorProfileImageUrl && (
                      <Avatar
                        size="sm"
                        src={authorProfileImageUrl}
                        name={authorName}
                      />
                    )}
                  {authorCustomUrlCode && !authorProfileIsPrivate ? (
                    <TextLink
                      size="lg"
                      height={pxToRem(24)}
                      navigationLink={{
                        to: `/t/profiles/${authorCustomUrlCode}`,
                        label: authorName || "",
                        isExternal: false,
                      }}
                      noOfLines={1}
                      wordBreak="break-all"
                    >
                      {authorName}
                    </TextLink>
                  ) : (
                    <Text noOfLines={1} wordBreak="break-all">
                      {authorName}
                    </Text>
                  )}
                </HStack>
              </Text>
            </Flex>

            {!hideButtons && !!handleDuplicate && (
              <Flex __css={styles.buttonWrapper}>
                {handleLiveSession && (
                  <Box as="span" sx={styles.button}>
                    <PremiumTooltipRich isDisabled={!premiumActionsDisallowed}>
                      <Box>
                        <Button
                          size="lg"
                          variant="adminButtonFilled"
                          isDisabled={
                            disableButtons || premiumActionsDisallowed
                          }
                          leftIcon={
                            premiumActionsDisallowed ? (
                              <Icon
                                w={pxToRem(24)}
                                h={pxToRem(24)}
                                icon="lock"
                              />
                            ) : (
                              <Icon
                                w={pxToRem(24)}
                                h={pxToRem(24)}
                                icon="play_outlined"
                              />
                            )
                          }
                          onClick={
                            premiumActionsDisallowed
                              ? undefined
                              : () => handleLiveSession(practiceSet)
                          }
                          w="full"
                        >
                          <Flex
                            display="flex"
                            w="full"
                            justifyContent="center"
                            alignItems="center"
                            gap={pxToRem(8)}
                          >
                            {t(
                              "practiceSetInformationCardPublic.labelStartSession"
                            )}
                          </Flex>
                        </Button>
                      </Box>
                    </PremiumTooltipRich>
                  </Box>
                )}
                {!isActionsRestrictedSet && handleDuplicate && (
                  <Box as="span" sx={styles.button}>
                    <PremiumTooltipRich isDisabled={!premiumActionsDisallowed}>
                      <Box>
                        <Button
                          size="lg"
                          variant="adminButtonOutlined"
                          isDisabled={
                            disableButtons || premiumActionsDisallowed
                          }
                          leftIcon={
                            premiumActionsDisallowed ? (
                              <Icon
                                w={pxToRem(24)}
                                h={pxToRem(24)}
                                icon="lock"
                              />
                            ) : (
                              <Icon
                                w={pxToRem(24)}
                                h={pxToRem(24)}
                                icon="add_circle_outlined"
                              />
                            )
                          }
                          onClick={
                            premiumActionsDisallowed
                              ? undefined
                              : () => handleDuplicate(practiceSet)
                          }
                          isLoading={isLoading}
                        >
                          <Show above="md">
                            {t(
                              "practiceSetInformationCardPublic.labelDuplicate"
                            )}
                          </Show>
                          <Show below="md">
                            {t(
                              "practiceSetInformationCardPublic.labelDuplicateShort"
                            )}
                          </Show>
                        </Button>
                      </Box>
                    </PremiumTooltipRich>
                  </Box>
                )}
                {!isActionsRestrictedSet && handleCreateAssignment && (
                  <Box as="span" sx={styles.button}>
                    <PremiumTooltipRich isDisabled={!premiumActionsDisallowed}>
                      <Box>
                        <Button
                          size="lg"
                          variant="adminButtonOutlined"
                          isDisabled={
                            disableButtons || premiumActionsDisallowed
                          }
                          leftIcon={
                            premiumActionsDisallowed ? (
                              <Icon
                                w={pxToRem(24)}
                                h={pxToRem(24)}
                                icon="lock"
                              />
                            ) : (
                              <Icon
                                w={pxToRem(24)}
                                h={pxToRem(24)}
                                icon="person_add_outlined"
                              />
                            )
                          }
                          onClick={
                            premiumActionsDisallowed
                              ? undefined
                              : handleCreateAssignment
                          }
                        >
                          <Flex
                            display="flex"
                            w="full"
                            justifyContent="center"
                            alignItems="center"
                            gap={pxToRem(8)}
                          >
                            {t(
                              "practiceSetInformationCardPublic.labelCreateAssignment"
                            )}
                          </Flex>
                        </Button>
                      </Box>
                    </PremiumTooltipRich>
                  </Box>
                )}
                {handlePreview && (
                  <Box as="span" sx={styles.button}>
                    <PremiumTooltipRich isDisabled={!premiumActionsDisallowed}>
                      <Box>
                        <Button
                          size="lg"
                          isDisabled={
                            disableButtons || premiumActionsDisallowed
                          }
                          leftIcon={
                            premiumActionsDisallowed ? (
                              <Icon
                                w={pxToRem(24)}
                                h={pxToRem(24)}
                                icon="lock"
                              />
                            ) : (
                              <Icon
                                w={pxToRem(24)}
                                h={pxToRem(24)}
                                icon="search_preview"
                              />
                            )
                          }
                          variant="adminButtonOutlined"
                          onClick={
                            premiumActionsDisallowed
                              ? undefined
                              : () => handlePreview(practiceSet)
                          }
                        >
                          <Flex
                            display="flex"
                            w="full"
                            justifyContent="center"
                            alignItems="center"
                            gap={pxToRem(8)}
                          >
                            {t("common.preview")}
                          </Flex>
                        </Button>
                      </Box>
                    </PremiumTooltipRich>
                  </Box>
                )}
              </Flex>
            )}
          </Box>
        </Box>
        {/* Divider */}
        <Box __css={styles.divider}>
          <Divider color="primary.light-gray" />
        </Box>
        {/* Footer (Tags) */}
        <Box __css={styles.footer}>
          {/* Right tags */}
          <Wrap>
            {practiceSet.subjects
              .filter((s) => s.subject)
              .map((s) => {
                return (
                  <WrapItem key={s.id}>
                    <Tag variant="adminSolid">
                      {s.subject.parent_name
                        ? `${s.subject.parent_name}: ${s.subject.name}`
                        : s.subject.name}
                    </Tag>
                  </WrapItem>
                );
              })}
            {practiceSet.grade_levels &&
              practiceSet.grade_levels
                .filter((g) => g.grade_level)
                .map((g) => {
                  return (
                    <WrapItem key={g.id}>
                      <Tag variant="adminSolid">
                        {g.grade_level.grade_level}
                      </Tag>
                    </WrapItem>
                  );
                })}
          </Wrap>
        </Box>
      </Box>
    </Card>
  );
};
