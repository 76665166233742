import { FormControl } from "@chakra-ui/react";
import { FormikProps } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

import { FormErrorMessage } from "adminComponents/atoms/FormErrorMessage";
import { FormLabel } from "adminComponents/atoms/FormLabel";
import { Input } from "adminComponents/atoms/Input";
import { Textarea } from "adminComponents/atoms/Textarea";
import { ICollection } from "links/lib/types";

interface IProps {
  formik: FormikProps<ICollection>;
  isLoading?: boolean;
}

export const FolderDetailsFormControls: React.FC<IProps> = ({
  formik,
  isLoading,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  return (
    <>
      <FormControl
        isDisabled={isLoading}
        isInvalid={!!formik.errors.name && !!formik.touched.name}
        isRequired
        variant="adminFormControl"
      >
        <FormLabel variant="adminFormLabelDarkGray" htmlFor="name">
          {t("folderDetailsModal.nameLabel")}
        </FormLabel>
        <Input
          id="name"
          name="name"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          placeholder={t("folderDetailsModal.namePlaceholder")}
          value={formik.values.name}
          maxLength={64}
        />
        <FormErrorMessage>
          {formik.touched.name && formik.errors.name}
        </FormErrorMessage>
      </FormControl>
      <FormControl isDisabled={isLoading} variant="adminFormControl">
        <FormLabel variant="adminFormLabelDarkGray" htmlFor="description">
          {t("folderDetailsModal.descriptionLabel")}
        </FormLabel>
        <Textarea
          id="description"
          name="description"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          placeholder={t("folderDetailsModal.descriptionPlaceholder")}
          value={formik.values.description}
          rows={6}
        />
      </FormControl>
    </>
  );
};
