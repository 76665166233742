import { Box, useMultiStyleConfig } from "@chakra-ui/react";
import React from "react";

import { Heading } from "adminComponents/atoms/Heading";
import { Text } from "adminComponents/atoms/Text";

import notFoundImage from "./resources/not_found.png";

interface IProps {
  description: string;
  imagePosition?: "center" | "left";
  title: string;
}

export const NotFoundModule: React.FC<IProps> = ({
  imagePosition = "left",
  description,
  title,
}) => {
  const styles = useMultiStyleConfig("AdminNotFoundModule", {});

  return (
    <Box __css={styles.outerContainer}>
      <Box
        __css={styles.imageContainer}
        backgroundImage={notFoundImage}
        backgroundPosition={`top ${imagePosition}`}
      />
      <Box __css={styles.innerContainer}>
        <Box __css={styles.textContainer}>
          <Heading as="h1" variant="adminH3">
            {title}
          </Heading>
          <Text variant="adminP1">{description}</Text>
        </Box>
      </Box>
    </Box>
  );
};
