import { ContextType } from "react";

import { AxiosContext } from "../../contexts/axios";
import { IUseAsyncReturn, useAsync } from "../../hooks/useAsync";
import { useAxios } from "../../hooks/useAxios";
import {
  IAgreement,
  IFeatureFlags,
  ILicense,
  ISharingSettings,
  IUser,
} from "../../types";

export interface IFetchCurrentUserResponse {
  user: IUser;
  license: ILicense;
  features: IFeatureFlags;
  sharing_settings: ISharingSettings;
  required_agreements: Array<IAgreement>;
  pear_token: string;
  should_renew_token: boolean;
}

export const fetchCurrentUser = async (
  axios: ContextType<typeof AxiosContext>
): Promise<IFetchCurrentUserResponse> => {
  return await axios
    .get(`/v1/users/self`)
    .then((r) => r.data as IFetchCurrentUserResponse);
};

export default function useFetchCurrentUser(): IUseAsyncReturn<
  IFetchCurrentUserResponse,
  void
> {
  const axios = useAxios();

  const { isLoading, error, data, execute, isSuccess } =
    useAsync<IFetchCurrentUserResponse>(() => fetchCurrentUser(axios));

  return {
    isLoading,
    error,
    data,
    isSuccess,
    execute,
  };
}
