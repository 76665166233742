import { hoverStyles, pxToRem } from "adminComponents/utils";

export const AdminLibraryPracticeSetCard = {
  baseStyle: {
    card: {
      transition: "color 250ms",
      _hover: hoverStyles({
        "@media (hover: hover)": {
          color: "primary.golden-hover",
        },
      }),
      _focusVisible: {
        color: "utility.focus",
        outline: "none",
      },
    },
    headerContainer: {
      width: "100%",
      justifyContent: "space-between",
      paddingRight: pxToRem(16),
      position: "absolute",
      top: pxToRem(3),
      left: pxToRem(1),
      minHeight: pxToRem(32),
    },
    body: {
      padding: pxToRem(30),
    },
    heading: {
      zIndex: 2,
    },
    content: {
      maxWidth: "100%",

      button: {
        maxWidth: "100%",
      },
    },
    linkOverlay: {
      _focusVisible: {
        outlineColor: "utility.focus",
      },
    },
  },
  variants: {
    compact: {
      body: {
        alignItems: "center",
        gap: pxToRem(16),
        paddingTop: pxToRem(40),
        paddingBottom: pxToRem(20),
      },
      content: {
        minWidth: 0,
      },
    },
    full: {
      coverImageBackground: {
        border: `solid ${pxToRem(3)}`,
        borderBottom: "none",
        height: pxToRem(148),
        borderRadius: `${pxToRem(21)} ${pxToRem(16)} 0px 0px}`,
      },
      heading: {
        marginBottom: pxToRem(8),
        fontSize: "1.1rem",
      },
      divider: {
        paddingY: pxToRem(16),
      },
    },
  },
};
