import { cssVar } from "@chakra-ui/theme-tools";

const $bg = cssVar("tooltip-bg");
const $fg = cssVar("tooltip-fg");
const $arrowBg = cssVar("popper-arrow-bg");

export const Tooltip = {
  variants: {
    adminDark: {
      bg: $bg.reference,
      color: $fg.reference,
      [$bg.variable]: "colors.primary.warm-black",
      [$fg.variable]: "colors.whiteAlpha.900",
      _dark: {
        [$bg.variable]: "colors.gray.300",
        [$fg.variable]: "colors.gray.900",
      },
      [$arrowBg.variable]: $bg.reference,
      px: "2",
      py: "0.5",
      borderRadius: "md",
      fontWeight: "normal",
      fontSize: "sm",
      boxShadow: "md",
      maxW: "xs",
      zIndex: "tooltip",
    },
  },
};
