import { IStageCamera, IStageOverlayAsset } from "../types";

export interface IStageCarePackageConstructorArgs {
  x: number;
  y: number;
  image: HTMLImageElement;
}

class StageCarePackage implements IStageOverlayAsset {
  x: number;
  y: number;
  image: HTMLImageElement;

  yTravelDown: boolean;
  yTravel: number;
  maxTravelY: number;

  constructor(args: IStageCarePackageConstructorArgs) {
    this.x = args.x;
    this.y = args.y;
    this.image = args.image;

    this.yTravelDown = true;
    this.yTravel = 0;
    this.maxTravelY = 40;
  }

  setProps(props: { image: HTMLImageElement }): void {
    const { image } = props;

    this.image = image;
  }

  setTransform(x: number, y: number): void {
    this.x = x;
    this.y = y;
  }

  drawImage(ctx: CanvasRenderingContext2D, x: number, y: number): void {
    ctx.drawImage(this.image, x, y);
  }

  render(
    ctx: CanvasRenderingContext2D,
    camera: IStageCamera,
    pixelRatio: number
  ): void {
    const scale = 1 / camera.zoom;

    // x and y are relative to top center of canvas
    const canvasWidth = ctx.canvas.width / pixelRatio;
    const canvasHeight = ctx.canvas.height / pixelRatio;
    const x = canvasWidth / 2 + this.x * scale;
    const y = canvasHeight / 2 - this.y * scale + this.yTravel;

    const packageX = x - (this.image.width / 2) * scale;
    const packageY = y * scale;

    if (this.yTravelDown) {
      this.yTravel++;
      if (this.yTravel >= this.maxTravelY) {
        this.yTravelDown = false;
      }
    }

    if (!this.yTravelDown) {
      this.yTravel--;
      if (this.yTravel <= 0) {
        this.yTravelDown = true;
      }
    }

    this.drawImage(ctx, packageX, packageY);
  }
}

export { StageCarePackage };
