import { Flex, Tooltip } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { IconType } from "adminComponents";
import { Card } from "adminComponents/atoms/Card";
import { Heading } from "adminComponents/atoms/Heading";
import { IconButton } from "adminComponents/atoms/IconButton";
import { Tag } from "adminComponents/atoms/Tag";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils";

export interface IProps {
  title: string;
  icon: IconType;
  value: number;
  percentChange?: number;
  handleClick?: () => void;
}

export const QuickStat: React.FC<IProps> = ({
  title,
  icon,
  value,
  percentChange,
  handleClick,
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });

  const tooltipLabel =
    percentChange &&
    (percentChange > 0
      ? t("teacherHomepageImproved.quickStats.increaseTooltipLabel", {
          percentChange: percentChange.toFixed(),
        })
      : t("teacherHomepageImproved.quickStats.decreaseTooltipLabel", {
          percentChange: percentChange.toFixed(),
        }));

  return (
    <Card
      variant="adminCardSmallBorder"
      borderColor="primary.tan"
      backgroundColor="primary.white"
      cursor={value > 0 ? "pointer" : undefined}
      noPadding
      paddingX={pxToRem(30)}
      paddingY={pxToRem(15)}
      onClick={handleClick}
    >
      <Flex alignItems="center" flexDir="row" gap={pxToRem(12)}>
        <IconButton
          ariaLabel=""
          width={pxToRem(40)}
          height={pxToRem(40)}
          cursor="default"
          colorScheme="primary.medium-gray"
          colorSchemeHover="primary.medium-gray"
          shape="type1"
          icon={icon}
        />
        <Flex flexDir="column" gap={pxToRem(4)}>
          <Heading variant="adminH7" as="h5">
            {title}
          </Heading>
          <Flex
            alignItems="center"
            flexDir="row"
            gap={pxToRem(12)}
            position="relative"
          >
            <Text variant="adminP1" fontSize={pxToRem(20)}>
              {value}
            </Text>
            {!!percentChange && (
              <Tooltip
                placement="bottom"
                hasArrow
                label={tooltipLabel || ""}
                aria-label={tooltipLabel || ""}
              >
                <span>
                  <Tag
                    leftIcon={
                      percentChange > 0 ? "arrow_upward" : "arrow_downward"
                    }
                    colorScheme={percentChange > 0 ? "green" : "red"}
                  >
                    <Text variant="adminP2">{`${percentChange.toFixed()}% ${
                      percentChange > 0
                        ? t("teacherHomepageImproved.quickStats.increase")
                        : t("teacherHomepageImproved.quickStats.decrease")
                    }`}</Text>
                  </Tag>
                </span>
              </Tooltip>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};
