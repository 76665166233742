import {
  CoopChecksVariantData,
  CoopDragsortVariantData,
  CoopRadiosVariantData,
  CoopTextMatchVariantData,
  ISessionDraggableSortItem,
  PracticeSessionItemVariantType,
  VariantData,
} from "links/lib/types";

import { ResponseOption } from "../hooks/useItemResponseOptions";

export const getCorrectChoiceIds = (
  variant: PracticeSessionItemVariantType,
  variant_data: VariantData,
  answerOptions?: ResponseOption[]
): string[] => {
  const radioVariantData = variant_data as CoopRadiosVariantData;
  const checksVariantData = variant_data as CoopChecksVariantData;
  const textMatchVariantData = variant_data as CoopTextMatchVariantData;
  const dragsortVariantData = variant_data as CoopDragsortVariantData;
  const dragsortAnswerOptions = answerOptions as ISessionDraggableSortItem[];
  switch (variant) {
    case PracticeSessionItemVariantType.CoopRadios:
      return radioVariantData.coop_radios.correct_choice_id
        ? [radioVariantData.coop_radios.correct_choice_id]
        : [];
    case PracticeSessionItemVariantType.CoopChecks:
      return checksVariantData.coop_checks.correct_choice_ids || [];
    case PracticeSessionItemVariantType.CoopTextMatch:
    case PracticeSessionItemVariantType.CoopTextMatchNumeric:
      return getAllCorrectTextOptions(textMatchVariantData);
    case PracticeSessionItemVariantType.CoopDragsort:
      return dragsortVariantData?.coop_dragsort?.items
        .filter((item) => {
          const correspondingAnswer = dragsortAnswerOptions?.find(
            (opt) => opt.id === item.id
          );

          return correspondingAnswer?.zone_id === item.zone_id;
        })
        .map((item) => item.id);
    default:
      return [];
  }
};

// combines correct_text and alternate_correct_text into one array
// and filters out duplicates where an alternate that is identical to the correct text occurs
const getAllCorrectTextOptions = (
  variantData: CoopTextMatchVariantData
): string[] => {
  const {
    coop_text_match: { correct_text, alternate_correct_text },
  } = variantData;
  const correctText = correct_text || "";
  const alternateText = alternate_correct_text || [];
  return [
    correctText.toLowerCase(),
    ...alternateText
      .map((text) => text.toLowerCase())
      .filter((txt) => txt.toLowerCase() !== correctText.toLowerCase()),
  ];
};
