import { transparentize } from "@chakra-ui/theme-tools";

import { hoverStyles, pxToRem } from "adminComponents/utils";

export const Modal = {
  variants: {
    adminModal: {
      overlay: {
        background: transparentize("primary.warm-black", 0.3),
      },
      header: {
        paddingX: [pxToRem(15), null, pxToRem(48)],
        paddingTop: [pxToRem(56), null, pxToRem(48)],
        paddingBottom: [pxToRem(20), null, pxToRem(24)],
      },
      dialog: {
        borderRadius: [pxToRem(0), null, pxToRem(20)],
      },
      body: {
        paddingX: [pxToRem(15), null, pxToRem(48)],
        paddingTop: pxToRem(0),
        paddingBottom: [pxToRem(32), null, pxToRem(48)],
      },
      confirmButtonsContainer: {
        alignItems: ["center", null, "flex-end"],
        flexDirection: ["column", null, "row"],
        gap: pxToRem(12),
        mt: [pxToRem(32), null, pxToRem(40)],
      },
    },
    adminModalCompactMobile: ({
      horizontalMobileButtonsDirection,
    }: {
      [key: string]: unknown;
    }): Record<string, unknown> => ({
      overlay: {
        background: transparentize("primary.warm-black", 0.3),
      },
      header: {
        paddingX: [pxToRem(20), null, pxToRem(58)],
        paddingTop: [pxToRem(56), null, pxToRem(58)],
        paddingBottom: [pxToRem(20), null, pxToRem(24)],
      },
      dialog: {
        borderRadius: pxToRem(20),
        display: "table",
      },
      body: {
        paddingX: [pxToRem(20), null, pxToRem(58)],
        paddingTop: pxToRem(0),
        paddingBottom: [pxToRem(32), null, pxToRem(58)],
      },
      confirmButtonsContainer: {
        alignItems: ["center", null, "flex-end"],
        flexDirection: horizontalMobileButtonsDirection
          ? "row"
          : ["column", null, "row"],
        gap: pxToRem(12),
        mt: [
          horizontalMobileButtonsDirection ? pxToRem(24) : pxToRem(32),
          null,
          pxToRem(40),
        ],
      },
    }),
    adminStudentsModal: {
      header: {
        paddingX: [pxToRem(15), null, pxToRem(48)],
        paddingTop: [pxToRem(56), null, pxToRem(48)],
        paddingBottom: [pxToRem(20), null, pxToRem(24)],
      },
      dialog: {
        borderRadius: [pxToRem(0), null, pxToRem(20)],
        minHeight: ["100vh", null, pxToRem(350)],
      },
      body: {
        display: "flex",
        flexDirection: "column",
        paddingX: [pxToRem(15), null, pxToRem(48)],
        paddingTop: pxToRem(0),
        paddingBottom: [pxToRem(32), null, pxToRem(48)],
      },
    },
    adminStudentEditModal: {
      overlay: {
        background: transparentize("primary.warm-black", 0.3),
      },
      header: {
        paddingX: [pxToRem(20), null, pxToRem(58)],
        paddingTop: [pxToRem(48), null, pxToRem(64)],
        paddingBottom: pxToRem(24),
      },
      dialog: {
        borderRadius: pxToRem(20),
      },
      body: {
        paddingX: [pxToRem(20), null, pxToRem(58)],
        paddingTop: pxToRem(0),
        paddingBottom: [pxToRem(20), null, pxToRem(64)],
      },
      dialogContainer: {
        paddingX: pxToRem(15),
      },
    },
    adminStudentDeleteConfirmation: {
      overlay: {
        background: transparentize("primary.warm-black", 0.3),
      },
      header: {
        paddingX: [pxToRem(20), null, pxToRem(58)],
        paddingTop: [pxToRem(48), null, pxToRem(64)],
        paddingBottom: pxToRem(8),
      },
      dialog: {
        borderRadius: pxToRem(20),
      },
      body: {
        paddingX: [pxToRem(20), null, pxToRem(58)],
        paddingTop: pxToRem(0),
        paddingBottom: [pxToRem(20), null, pxToRem(64)],
      },
      dialogContainer: {
        paddingX: pxToRem(15),
      },
    },
    adminStudentRejectModal: {
      overlay: {
        background: transparentize("primary.warm-black", 0.3),
      },
      header: {
        paddingX: [pxToRem(20), null, pxToRem(58)],
        paddingTop: [pxToRem(48), null, pxToRem(64)],
        paddingBottom: pxToRem(24),
      },
      dialog: {
        borderRadius: pxToRem(20),
      },
      body: {
        paddingX: [pxToRem(20), null, pxToRem(58)],
        paddingTop: pxToRem(0),
        paddingBottom: [pxToRem(20), null, pxToRem(64)],
      },
      dialogContainer: {
        paddingX: pxToRem(15),
      },
    },
    adminDuplicateClassroomModal: {
      overlay: {
        background: transparentize("primary.warm-black", 0.3),
      },
      header: {
        paddingX: [pxToRem(20), null, pxToRem(58)],
        paddingTop: [pxToRem(48), null, pxToRem(64)],
        paddingBottom: pxToRem(8),
      },
      dialog: {
        borderRadius: pxToRem(20),
      },
      body: {
        paddingX: [pxToRem(20), null, pxToRem(58)],
        paddingTop: pxToRem(0),
        paddingBottom: [pxToRem(20), null, pxToRem(64)],
      },
      dialogContainer: {
        paddingX: pxToRem(15),
      },
    },
    adminGoogleClassroomAuthorizeModal: {
      overlay: {
        background: transparentize("primary.warm-black", 0.3),
      },
      header: {
        paddingX: [pxToRem(20), null, pxToRem(58)],
        paddingTop: [pxToRem(52), null, pxToRem(64)],
        paddingBottom: pxToRem(8),
      },
      dialog: {
        borderRadius: pxToRem(20),
      },
      body: {
        paddingX: [pxToRem(20), null, pxToRem(58)],
        paddingTop: pxToRem(0),
        paddingBottom: [pxToRem(20), null, pxToRem(64)],
      },
      dialogContainer: {
        paddingX: pxToRem(15),
      },
    },
    adminStudentLockerModal: {
      overlay: {
        background: transparentize("primary.warm-black", 0.3),
      },
      body: {
        padding: 0,
      },
      dialog: {
        borderRadius: pxToRem(20),
        overflow: "hidden",
      },
      dialogContainer: {
        paddingX: pxToRem(15),
      },
    },
    adminSelectStandardsModal: {
      overlay: {
        background: transparentize("primary.warm-black", 0.3),
        opacity: 0.3,
      },
      closeButton: {
        boxShadow: "none",
        borderRadius: "100%",
        top: pxToRem(20),
        right: [null, null, pxToRem(20)],
        left: [pxToRem(15), "unset"],
        _focus: {
          boxShadow: "none",
          border: [null, null, `${pxToRem(2)} solid`],
          borderColor: [null, null, "primary.golden"],
        },
        _hover: hoverStyles({
          border: [null, null, `${pxToRem(2)} solid`],
          borderColor: [null, null, "primary.golden"],
        }),
        _active: {
          backgroundcolor: "transparent",
        },
        width: ["auto", null, pxToRem(32)],
      },
      header: {
        paddingX: [pxToRem(15), null, pxToRem(48)],
        paddingTop: [pxToRem(56), null, pxToRem(48)],
        paddingBottom: [pxToRem(20), null, pxToRem(8)],
      },
      dialog: {
        borderRadius: [pxToRem(0), null, pxToRem(20)],
      },
      body: {
        paddingX: [pxToRem(15), null, pxToRem(48)],
        paddingTop: pxToRem(0),
        paddingBottom: [pxToRem(32), null, pxToRem(48)],
      },
    },
    adminMultistepModal: ({
      horizontalMobileButtonsDirection,
    }: {
      [key: string]: unknown;
    }): Record<string, unknown> => ({
      overlay: {
        background: transparentize("primary.warm-black", 0.3),
      },
      header: {
        paddingX: [pxToRem(20), null, pxToRem(58)],
        paddingTop: [pxToRem(56), null, pxToRem(58)],
        paddingBottom: [pxToRem(20), null, pxToRem(24)],
      },
      dialog: {
        borderRadius: pxToRem(20),
      },
      body: {
        paddingX: [pxToRem(20), null, pxToRem(58)],
        paddingTop: pxToRem(0),
        paddingBottom: [pxToRem(32), null, pxToRem(58)],
      },
      confirmButtonsContainer: {
        alignItems: ["center", null, "flex-end"],
        flexDirection: horizontalMobileButtonsDirection
          ? "row"
          : ["column", null, "row"],
        gap: pxToRem(12),
        mt: [
          horizontalMobileButtonsDirection ? pxToRem(24) : pxToRem(32),
          null,
          pxToRem(40),
        ],
      },
    }),
  },
};
