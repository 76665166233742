import {
  Box,
  Button,
  Center,
  Divider,
  HStack,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useMemo } from "react";

import { ISkill } from "links/lib/types";
import { MockCheckbox } from "sharedComponents/atoms/MockCheckbox";

export interface ISkillsListProps {
  selectedSkills: Array<ISkill>;
  skills: Array<ISkill>;
  isLoading: boolean;
  content: {
    noResultsMessage: string;
  };
  onSkillSelect: (skill: ISkill, isSelected: boolean) => void;
}

export interface ISkillsListItem {
  skill: ISkill;
  isSelected: boolean;
  onSelect: (skill: ISkill, isSelected: boolean) => void;
}

export interface ISkillsListEmptyProps {
  content: {
    noResultsMessage: string;
  };
}

const SkillsListItem: React.FC<ISkillsListItem> = ({
  skill,
  isSelected,
  onSelect,
}) => {
  const onButtonClick = () => {
    onSelect(skill, !isSelected);
  };

  return (
    <Button
      aria-label={skill.code}
      bg="transparent"
      h="fit-content"
      w="full"
      px={4}
      py={2}
      borderRadius="none"
      _hover={{ bg: "gray.50" }}
      _active={{ bg: "gray.100" }}
      _selected={{ bg: "transparent" }}
      onClick={onButtonClick}
    >
      <HStack
        justifyContent="flex-start"
        alignItems="flex-start"
        w="full"
        spacing={4}
        minW={0}
      >
        <Box mt={5}>
          <MockCheckbox size="md" isChecked={isSelected} />
        </Box>
        <Box textAlign="left" overflow="hidden" alignItems="flex-start">
          <Text mt={2} fontSize="sm" fontWeight="bold" display="flex">
            {skill.code}
          </Text>
          <Text
            mt={1}
            whiteSpace="break-spaces"
            textOverflow="ellipsis"
            fontSize="sm"
          >
            {skill.description}
          </Text>
        </Box>
      </HStack>
    </Button>
  );
};

const SkillsListEmpty: React.FC<ISkillsListEmptyProps> = ({ content }) => {
  return (
    <Box w="full" mt={10}>
      <Center>
        <VStack>
          <Text fontSize="md">{content.noResultsMessage}</Text>
        </VStack>
      </Center>
    </Box>
  );
};

const SkillsList: React.FC<ISkillsListProps> = ({
  selectedSkills,
  skills,
  isLoading,
  content,
  onSkillSelect,
}) => {
  const sortedSkills = useMemo(() => {
    return skills.sort((a, b) => {
      return parseInt(a.id, 10) - parseInt(b.id, 10);
    });
  }, [skills]);

  const selectedSkillsMap = useMemo(() => {
    const map: { [key: string]: boolean } = {};

    selectedSkills.forEach((s) => {
      map[s.id] = true;
    });

    return map;
  }, [selectedSkills]);

  return (
    <Box w="full" h="300px" maxH="300px">
      <Divider />
      {isLoading && (
        <Center my={10}>
          <Spinner />
        </Center>
      )}
      <Box maxH="full" overflowY="auto" overflowX="hidden" w="full">
        {!isLoading && !!sortedSkills.length && (
          <VStack mt={6} w="full">
            {sortedSkills.map((skill) => (
              <SkillsListItem
                onSelect={onSkillSelect}
                key={`skill-${skill.id}`}
                skill={skill}
                isSelected={!!selectedSkillsMap[skill.id]}
              />
            ))}
          </VStack>
        )}
        {!isLoading && !sortedSkills.length && (
          <SkillsListEmpty
            content={{
              noResultsMessage: content.noResultsMessage,
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default SkillsList;
