import React from "react";

import { colors } from "adminComponents/theme/colors";

export const CertifiedCheckSVG: React.FC = () => (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      cx="9.16699"
      cy="9"
      rx="5.625"
      ry="5.66667"
      fill={colors.primary.lilac}
    />
    <path
      d="M18.167 9L16.1706 6.74143L16.4488 3.75429L13.4952 3.09048L11.9488 0.5L9.16699 1.6819L6.38517 0.5L4.83881 3.08238L1.88517 3.7381L2.16336 6.73333L0.166992 9L2.16336 11.2586L1.88517 14.2538L4.83881 14.9176L6.38517 17.5L9.16699 16.31L11.9488 17.4919L13.4952 14.9095L16.4488 14.2457L16.1706 11.2586L18.167 9ZM7.60427 12.821L4.49517 9.73667L5.70608 8.53857L7.60427 10.4248L12.3906 5.67286L13.6015 6.87095L7.60427 12.821Z"
      fill="white"
    />
  </svg>
);
