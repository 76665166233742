import { pxToRem } from "adminComponents/utils";

export const AdminNotFoundModule = {
  baseStyle: {
    outerContainer: {
      bgColor: "primary.white",
      borderColor: "primary.tan",
      borderRadius: pxToRem(20),
      borderStyle: "solid",
      borderWidth: pxToRem(3),
      display: "flex",
      flexDirection: ["column", null, "row"],
      minH: [null, null, pxToRem(560)],
      overflow: "hidden",
      maxWidth: [pxToRem(345), null, pxToRem(864)],
    },
    imageContainer: {
      alignItems: "center",
      display: "flex",
      flexShrink: "0",
      flexDirection: "column",
      justifyContent: "center",
      h: [pxToRem(250), null, "auto"],
      w: ["full", null, pxToRem(300), pxToRem(404)],
      backgroundSize: "cover",
    },
    innerContainer: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      ml: [null, null, "auto"],
      mt: ["auto", null, "0"],
      px: [pxToRem(30), null, pxToRem(58)],
      py: [pxToRem(32), null, pxToRem(32)],
      minW: [pxToRem(300), pxToRem(345), pxToRem(300), pxToRem(460)],
    },
    textContainer: {
      display: "flex",
      flexDirection: "column",
      w: "full",
      "> p": {
        mt: [pxToRem(8), null, pxToRem(16)],
        opacity: "0.8",
      },
    },
    buttonsContainer: {
      display: "flex",
      flexDirection: "column",
      gap: pxToRem(12),
      mt: [pxToRem(24), null, pxToRem(40)],
      w: "full",
    },
  },
};
