export const Container = {
  variants: {
    adminContentWrapper: {
      paddingX: { base: "admin.mobileXPadding", md: "admin.desktopXPadding" },
      maxW: "container.xl",
      h: "full",
    },
    navHeaderHeroContainer: {
      paddingX: 0,
      maxW: "full",
      w: "full",
      h: "full",
    },
  },
};
