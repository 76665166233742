import { pxToRem } from "adminComponents/utils/pxToRem";

export const textStyles = {
  adminH1: {
    fontFamily: "Gooper",
    fontSize: [pxToRem(48), null, pxToRem(72)],
    lineHeight: ["120%", null, "105%"],
    letterSpacing: "0",
    fontWeight: "600",
  },
  adminH2: {
    fontFamily: "Gooper",
    fontSize: [pxToRem(32), null, pxToRem(48)],
    lineHeight: "120%",
    letterSpacing: "0",
    fontWeight: "600",
  },
  adminH3: {
    fontFamily: "Gooper",
    fontSize: [pxToRem(24), null, pxToRem(32)],
    lineHeight: ["140%", null, "120%"],
    letterSpacing: "0",
    fontWeight: "600",
  },
  adminH3TTCommons: {
    fontFamily: "TTCommons",
    fontSize: [pxToRem(24), null, pxToRem(32)],
    lineHeight: ["140%", null, "120%"],
    letterSpacing: "0",
    fontWeight: "600",
  },
  adminH3Bold: {
    fontFamily: "TTCommons",
    fontWeight: "700",
    fontSize: [pxToRem(28), null, pxToRem(32)],
    lineHeight: ["140%", null, "120%"],
    letterSpacing: "0",
  },
  adminH4: {
    fontFamily: "Gooper",
    fontSize: [pxToRem(20), null, pxToRem(24)],
    lineHeight: ["140%", null, "120%"],
    letterSpacing: "0",
    fontWeight: "600",
  },
  adminH5: {
    fontFamily: "TTCommons",
    fontWeight: "500",
    fontSize: [pxToRem(20), null, pxToRem(32)],
    lineHeight: ["130%", null, "120%"],
    letterSpacing: "0",
  },
  adminH5Bold: {
    fontFamily: "TTCommons",
    fontWeight: "700",
    fontSize: [pxToRem(20), null, pxToRem(32)],
    lineHeight: ["130%", null, "120%"],
    letterSpacing: "0",
  },
  adminH5Gooper: {
    fontFamily: "Gooper",
    fontWeight: "400",
    fontSize: [pxToRem(18), null, pxToRem(20)],
    lineHeight: ["130%", null, "120%"],
    letterSpacing: "0",
  },
  adminH6: {
    fontFamily: "TTCommons",
    fontWeight: "500",
    fontSize: [pxToRem(18), null, pxToRem(24)],
    lineHeight: "130%",
    letterSpacing: ["1%", null, 0],
  },
  adminH6Bold: {
    fontFamily: "TTCommons",
    fontWeight: "700",
    fontSize: [pxToRem(18), null, pxToRem(24)],
    lineHeight: "130%",
    letterSpacing: ["1%", null, 0],
  },
  adminH7: {
    fontFamily: "TTCommons",
    fontWeight: "500",
    fontSize: [pxToRem(16), null, pxToRem(20)],
    lineHeight: "130%",
    letterSpacing: "0",
  },
  adminH7Bold: {
    fontFamily: "TTCommons",
    fontWeight: "700",
    fontSize: [pxToRem(16), null, pxToRem(20)],
    lineHeight: "130%",
    letterSpacing: "0",
  },
  adminP1: {
    fontFamily: "TTCommons",
    fontWeight: "400",
    fontSize: [pxToRem(18), null, pxToRem(20)],
    lineHeight: "130%",
    letterSpacing: "0",
  },
  adminP2: {
    fontFamily: "TTCommons",
    fontWeight: "400",
    fontSize: pxToRem(16),
    lineHeight: "130%",
    letterSpacing: "0",
  },
  adminP3: {
    fontFamily: "TTCommons",
    fontWeight: "400",
    fontSize: pxToRem(14),
    lineHeight: "130%",
    letterSpacing: "0",
  },
  adminMeta: {
    fontFamily: "TTCommons",
    fontWeight: "400",
    fontSize: pxToRem(12),
    lineHeight: "130%",
    letterSpacing: "0",
  },
  adminLinkSmall: {
    fontFamily: "TTCommons",
    fontWeight: "500",
    fontSize: pxToRem(14),
    textDecoration: "underline",
    lineHeight: "130%",
    letterSpacing: "0",
  },
  adminLinkMedium: {
    fontFamily: "TTCommons",
    fontWeight: "500",
    fontSize: pxToRem(16),
    textDecoration: "underline",
    lineHeight: "140%",
    letterSpacing: "0",
  },
  adminLinkLarge: {
    fontFamily: "TTCommons",
    fontWeight: "400",
    fontSize: [pxToRem(18), null, pxToRem(20)],
    textDecoration: "underline",
    lineHeight: "130%",
    letterSpacing: "0",
  },
  adminLinkExtraLarge: {
    fontFamily: "Gooper",
    fontWeight: "400",
    fontSize: [pxToRem(24), null, pxToRem(32)],
    textDecoration: "underline",
    lineHeight: ["140%", null, "120%"],
    letterSpacing: "0",
  },
};

export const fontWeights = {
  normal: 400,
  medium: 500,
  demibold: 600,
  bold: 700,
};

export const fonts = {
  adminBody: "TTCommons",
  adminHeading: "Gooper",
};
