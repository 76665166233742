import { Box, Flex, HStack } from "@chakra-ui/react";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link, Redirect, Route, Switch, useParams } from "react-router-dom";
import { useLocalStorage } from "react-use";

import { Button } from "adminComponents/atoms/Button";
import { Card } from "adminComponents/atoms/Card";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils";
import { useShowToast } from "adminComponents/utils/toast";

import { LLMProcessCreator } from "./LLMProcessCreator";
import { LLMProcessRunner } from "./LLMProcessRunner";
import { ProcessFlow } from "./types";

const PROCESSES_KEY = "PROCESS_FLOWS";

export const LLMProcessFlows: React.FC = () => {
  return (
    <Switch>
      <Route
        path="/e0fe8aed-6b71-420f-8f9f-48780bfbd3dc/llm-process-flows"
        exact={true}
      >
        <LLMProcessFlowPicker />
      </Route>
      <Route path="/e0fe8aed-6b71-420f-8f9f-48780bfbd3dc/llm-process-flows/:index">
        <LLMProcessFlowManager />
      </Route>
    </Switch>
  );
};

export const LLMProcessFlowPicker: React.FC = () => {
  const [processes] = useLocalStorage<ProcessFlow[]>(PROCESSES_KEY, []);

  return (
    <Flex
      gap={pxToRem(16)}
      p={pxToRem(16)}
      flexDir="column"
      alignItems="center"
    >
      <Text>Choose a process or create a new one</Text>
      <HStack>
        <Button
          variant="adminButtonFilled"
          as={Link}
          to={`/e0fe8aed-6b71-420f-8f9f-48780bfbd3dc/llm-process-flows/${processes?.length}`}
        >
          Create New
        </Button>
      </HStack>
      <Flex gap={pxToRem(16)} p={pxToRem(16)}>
        {processes?.map((process, index) => (
          <Card
            w={pxToRem(250)}
            key={`${process.name}-${index}`}
            borderColor="primary.tan"
            backgroundColor="primary.white"
            variant="adminCardMediumBorder"
            onClick={() => {
              window.location.href = `/e0fe8aed-6b71-420f-8f9f-48780bfbd3dc/llm-process-flows/${index}`;
            }}
            cursor="pointer"
          >
            <Text>{process.name || `Process ${index}`}</Text>
          </Card>
        ))}
      </Flex>
    </Flex>
  );
};

export const LLMProcessFlowManager: React.FC = () => {
  const { index: indexString } = useParams<{ index: string }>();
  const index = parseInt(indexString);

  const [processes, setProcesses] =
    useLocalStorage<ProcessFlow[]>(PROCESSES_KEY);
  const methods = useForm<ProcessFlow>({
    defaultValues: processes?.[index],
  });
  const showToast = useShowToast();

  const watchedProcess = methods.watch();

  const onSave = () => {
    if (processes) {
      processes[index] = methods.getValues();
      setProcesses(processes);
      showToast("Process Saved");
    }
  };

  const onDelete = () => {
    if (
      processes &&
      window.confirm("Do you really want to delete this process?")
    ) {
      processes.splice(index, 1);
      setProcesses(processes);
      window.location.href =
        "/e0fe8aed-6b71-420f-8f9f-48780bfbd3dc/llm-process-flows";
    }
  };

  return (
    <Box minH="100vh">
      <FormProvider {...methods}>
        <Switch>
          <Route
            path="/e0fe8aed-6b71-420f-8f9f-48780bfbd3dc/llm-process-flows/:index"
            exact={true}
          >
            <Redirect
              to={`/e0fe8aed-6b71-420f-8f9f-48780bfbd3dc/llm-process-flows/${index}/edit`}
            />
          </Route>
          <Route
            path="/e0fe8aed-6b71-420f-8f9f-48780bfbd3dc/llm-process-flows/:index/edit"
            exact={true}
          >
            <LLMProcessCreator onSave={onSave} onDelete={onDelete} />
          </Route>
          <Route
            path="/e0fe8aed-6b71-420f-8f9f-48780bfbd3dc/llm-process-flows/:index/run"
            exact={true}
          >
            <LLMProcessRunner onSave={onSave} processFlow={watchedProcess} />
          </Route>
        </Switch>
      </FormProvider>
    </Box>
  );
};
