import {
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuProps,
  Text,
  ThemeComponentProps,
  Tooltip,
} from "@chakra-ui/react";
import React, { useState } from "react";

import { Icon } from "adminComponents/atoms/Icon";
import { pxToRem } from "adminComponents/utils/pxToRem";

interface IProps extends Omit<MenuProps, "children"> {
  buttonText: string;
  menuItems: MenuItemDetails[];
  value?: string;
  menuButtonProps?: ThemeComponentProps["MenuButton"];
}

interface MenuItemDetails {
  isSelected?: boolean;
  isDisabled?: boolean;
  label: string;
  handleClick?: React.MouseEventHandler<HTMLButtonElement>;
  value: string;
  leftIcon?: JSX.Element;
  tooltipText?: string;
}

export const TextButtonDropdown: React.FC<IProps> = ({
  menuItems,
  buttonText,
  value,
  menuButtonProps,
  ...rest
}) => {
  let t = buttonText;
  if (value) {
    const item = menuItems.find((i) => i.value === value);
    if (item) {
      t = item.label;
    }
  }

  const [text, setText] = useState(t);

  const handleItemClick = (
    evt: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: MenuItemDetails
  ) => {
    setText(item.label);
    item?.handleClick?.(evt);
  };

  return (
    <Menu {...rest} variant="adminTextButtonDropdown">
      <MenuButton {...menuButtonProps}>
        {text}
        <Icon
          h={pxToRem(6)}
          icon="keyboard_arrow_down"
          verticalAlign="middle"
          color="inherit"
        />
      </MenuButton>
      <MenuList>
        {menuItems.map((item, index) => (
          <Tooltip
            key={index}
            label={item.tooltipText}
            aria-label={item.tooltipText}
            variant="adminDark"
            hasArrow
          >
            <MenuItem
              cursor={item.isDisabled ? "not-allowed" : "pointer"}
              onClick={
                item.isDisabled ? undefined : (e) => handleItemClick(e, item)
              }
            >
              <>
                {item.leftIcon}
                <Text
                  ml={item.leftIcon ? pxToRem(6) : undefined}
                  variant="adminP2"
                >
                  {item.label}
                </Text>
              </>
            </MenuItem>
          </Tooltip>
        ))}
      </MenuList>
    </Menu>
  );
};
