import mobileImage from "adminComponents/assets/background-images/illustrated-background-mobile.svg";
import { hoverStyles, pxToRem } from "adminComponents/utils";

export const AdminTeacherAccountMenu = {
  parts: ["container", "header", "divider", "links", "link", "text"],
  baseStyle: ({ maxH }: { maxH: string }): Record<string, unknown> => ({
    container: {
      boxShadow: `${pxToRem(5)} ${pxToRem(7)} ${pxToRem(27)} ${pxToRem(
        -6
      )} rgba(0, 0, 0, 0.1)`,
      backgroundColor: { base: "primary.warm-white", lg: "white" },
      borderColor: "primary.tan",
      borderStyle: "solid",
      borderWidth: { lg: pxToRem(2) },
      borderRadius: { lg: pxToRem(20) },
      width: { base: "100%", lg: pxToRem(301) },
      position: { base: "fixed", lg: "relative" },
      top: { base: "0", lg: "initial" },
      right: { base: "0", lg: "initial" },
      height: { base: "100%", lg: "initial" },
      zIndex: 10,
      overflowY: "auto",
      maxH,
    },
    close: {
      display: { base: "flex", lg: "none" },
      justifyContent: "flex-end",
    },
    divider: {
      borderBottomWidth: pxToRem(2),
      opacity: "1",
      _first: {
        display: { base: "none", lg: "block" },
      },
    },
    header: {
      backgroundImage: { base: mobileImage, lg: "none" },
      backgroundPosition: "center center",
      backgroundSize: "cover",
      padding: pxToRem(30),
    },
    links: {
      listStyle: "none",
      paddingTop: pxToRem(12),
      paddingBottom: pxToRem(16),
    },
    link: {
      padding: 0,
      margin: 0,
      height: pxToRem(40),
      boxSizing: "border-box",
      a: {
        svg: {
          width: pxToRem(16),
          height: pxToRem(16),
          marginBottom: pxToRem(4),
        },
        span: {
          textStyle: "adminP1",
        },

        borderColor: "transparent",
        borderWidth: { md: pxToRem(2) },
        borderStyle: "solid",
        cursor: "pointer",
        display: "block",
        padding: `${pxToRem(8)} ${pxToRem(0)} ${pxToRem(8)} ${pxToRem(30)}`,
        _focus: {
          background: "none",
          boxShadow: "none",
        },
        _focusVisible: {
          borderRadius: 0,
          borderColor: "utility.focus",
        },
        _hover: hoverStyles({
          borderRadius: 0,
          bgColor: "primary.golden-light",
        }),
      },
    },
    premiumContainer: {
      mt: pxToRem(8),
    },
  }),
};
