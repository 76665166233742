import {
  Box,
  Divider,
  LinkBox,
  Text,
  Tooltip,
  useBreakpointValue,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { Avatar } from "adminComponents/atoms/Avatar";
import { Button } from "adminComponents/atoms/Button";
import { Card } from "adminComponents/atoms/Card";
import { Icon } from "adminComponents/atoms/Icon";
import { Text as AdminComponentText } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils";
import { useShowToast } from "adminComponents/utils/toast";
import { useAnalytics } from "lib/contexts/analytics";
import { useFollowUser, useUnfollowUser } from "links/lib/features/users";
import { IUserFollowUserParams } from "links/lib/features/users/useFollowUser";
import { AnalyticsEvent, IGradeLevel, ISubject, IUser } from "links/lib/types";

interface IProps {
  authUser: IUser;
  user: IUser;
  gradeLevels: Array<IGradeLevel>;
  subjects: Array<ISubject>;
}

export const TeacherCard: React.FC<IProps> = ({
  authUser,
  user,
  gradeLevels,
  subjects,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const showToast = useShowToast();
  const history = useHistory();
  const { trackEvent } = useAnalytics();

  const [following, setFollowing] = useState(false);
  const { mutate: followUser } = useFollowUser({
    onSuccess: (_, args: IUserFollowUserParams) => {
      trackEvent(AnalyticsEvent.TeacherDashboard_Homepage_FollowUser, {
        user_id: args.userId,
      });
      showToast(t("common.followUserSuccess", { name: fullName }));
      setFollowing(true);
    },
    onError: () => {
      showToast(t("common.followUserError", { name: fullName }));
    },
  });
  const { mutate: unfollowUser } = useUnfollowUser({
    onSuccess: () => {
      showToast(t("common.unfollowUserSuccess", { name: fullName }));
      setFollowing(false);
    },
    onError: () => {
      showToast(t("common.unfollowUserError", { name: fullName }));
    },
  });

  const {
    family_name,
    given_name,
    profile_image_url,
    teacher_grade_levels,
    teacher_subjects,
  } = user;

  const fullName = given_name
    ? given_name + `${family_name ? " " + family_name : ""}`
    : undefined;

  const styles = useMultiStyleConfig("AdminTeacherCard", {});

  const avatarSize = useBreakpointValue({
    base: "lg",
    md: "xl",
  });

  const teacherSubjectsString = useMemo(() => {
    const authUserSubjectIds =
      authUser.teacher_subjects?.map((s) => s.subject_id) || [];
    return teacher_subjects
      ?.sort((s) => {
        if (authUserSubjectIds.includes(s.subject_id)) {
          return -1;
        }
        return 0;
      })
      ?.map((ts) => subjects.find((s) => s.id === ts.subject_id)?.name)
      .join(", ");
  }, [authUser.teacher_subjects, subjects, teacher_subjects]);
  const teacherGradeLevelsString = useMemo(() => {
    const authUserGradeLevelIds =
      authUser.teacher_grade_levels?.map((gl) => gl.grade_level_id) || [];
    return teacher_grade_levels
      ?.sort((gl) => {
        if (authUserGradeLevelIds.includes(gl.grade_level_id)) {
          return -1;
        }
        return 0;
      })
      ?.map(
        (tgl) =>
          gradeLevels.find((gl) => gl.id === tgl.grade_level_id)?.grade_level
      )
      .join(", ");
  }, [authUser.teacher_grade_levels, gradeLevels, teacher_grade_levels]);

  const subjectsLineRef = useRef<HTMLInputElement>(null);
  const gradeLevelsLineRef = useRef<HTMLInputElement>(null);
  const [isSubjectLineClamped, setIsSubjectsLineClamped] = useState(false);
  const [isGradeLevelsLineClamped, setIsGradeLevelsLineClamped] =
    useState(false);

  useEffect(() => {
    const clamped =
      (subjectsLineRef.current?.scrollHeight as number) >
      (subjectsLineRef.current?.clientHeight as number);
    setIsSubjectsLineClamped(clamped);
  }, [subjectsLineRef]);
  useEffect(() => {
    const clamped =
      (gradeLevelsLineRef.current?.scrollHeight as number) >
      (gradeLevelsLineRef.current?.clientHeight as number);
    setIsGradeLevelsLineClamped(clamped);
  }, [gradeLevelsLineRef]);

  const teacherUrl = `/t/profiles/${user.custom_url_code}`;

  const handleViewProfile = () => {
    history.push(teacherUrl);
  };

  const useShortenedProfileCopy = useBreakpointValue({ base: true, lg: false });

  return (
    <LinkBox>
      <Card
        backgroundColor="primary.white"
        height="full"
        borderColor="primary.tan"
        sx={styles.container}
        variant="adminCardSmallBorderSmallPadding"
      >
        <Box __css={styles.left}>
          <Box __css={styles.avatar}>
            <Avatar size={avatarSize} src={profile_image_url || ""} />
          </Box>
          <Box __css={styles.content}>
            <AdminComponentText variant="adminP1">
              {fullName}
            </AdminComponentText>
          </Box>
        </Box>
        <Divider __css={styles.divider} />
        <Box __css={styles.content}>
          <Tooltip
            isDisabled={!isSubjectLineClamped}
            label={teacherSubjectsString}
            hasArrow
          >
            <Text
              ref={subjectsLineRef}
              color="primary.warm-black"
              sx={styles.bottomLine}
            >
              {teacherSubjectsString}
            </Text>
          </Tooltip>
          <Tooltip
            isDisabled={!isGradeLevelsLineClamped}
            label={teacherGradeLevelsString}
            hasArrow
          >
            <Text
              ref={gradeLevelsLineRef}
              color="primary.warm-black"
              sx={styles.bottomLine}
            >
              {teacherGradeLevelsString}
            </Text>
          </Tooltip>
        </Box>
        <Box __css={styles.buttonContainer}>
          <Button
            leftIcon={following ? <Icon icon="remove" /> : <Icon icon="add" />}
            onClick={() => {
              if (!following) {
                followUser({ userId: user.id, authUserId: authUser.id });
              } else {
                unfollowUser({ userId: user.id, authUserId: authUser.id });
              }
            }}
            size="md"
            variant="adminButtonFilled"
            sx={{
              fontSize: pxToRem(14),
            }}
          >
            {following ? t("common.unfollow") : t("common.follow")}
          </Button>
          <Button
            onClick={handleViewProfile}
            size="md"
            variant="adminButtonOutlined"
            sx={{
              fontSize: pxToRem(14),
            }}
          >
            {useShortenedProfileCopy
              ? t("common.profile")
              : t("common.viewProfile")}
          </Button>
        </Box>
      </Card>
    </LinkBox>
  );
};
