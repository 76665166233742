import { pxToRem } from "adminComponents/utils/pxToRem";

export const AdminFilterPracticeSet = {
  baseStyle: {
    buttonContainer: {
      pos: ["fixed", null, "relative"],
      bottom: [0, null, null],
      left: [0, null, null],
      width: ["100%", null, "auto"],
      paddingX: ["admin.mobileXPadding", null, 0],
      paddingTop: [pxToRem(20), null, 0],
      paddingBottom: [pxToRem(40), null, 0],
      borderTop: [`${pxToRem(1)} solid`, null, "none"],
      borderColor: ["primary.light-gray", null, null],
      backgroundColor: ["primary.white", null, null],
    },
  },
};
