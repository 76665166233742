import {
  Box,
  Flex,
  Image,
  SimpleGrid,
  Spacer,
  Spinner,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";

import { Button } from "adminComponents/atoms/Button";
import { Card } from "adminComponents/atoms/Card";
import { IOption } from "adminComponents/atoms/Dropdown";
import { Heading } from "adminComponents/atoms/Heading";
import { Icon } from "adminComponents/atoms/Icon";
import { TabData, Tabs } from "adminComponents/atoms/Tabs";
import { Text } from "adminComponents/atoms/Text";
import { FilterPracticeSet } from "adminComponents/molecules/FilterPracticeSet";
import { AdminFlyout } from "adminComponents/molecules/Flyout";
import { PracticeSetAddQuestions } from "adminComponents/molecules/PracticeSetAddQuestions";
import { GenerateItemForm } from "adminComponents/molecules/PracticeSetAddQuestions/components/GenerateItemForm";
import { PracticeSetItemAccordion } from "adminComponents/molecules/PracticeSetItemAccordion";
import { SearchInput } from "adminComponents/molecules/SearchInput";
import { TextButtonDropdown } from "adminComponents/molecules/TextButtonDropdown";
import { isPremiumItem } from "adminComponents/utils/isPremiumItem";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { useAnalytics } from "lib/contexts/analytics";
import { useAuth } from "links/lib/features/auth";
import {
  AnalyticsEvent,
  IPracticeSet,
  IPracticeSetItem,
  PracticeSetAvailability,
  QuestionType,
} from "links/lib/types";
import { useGetPremiumLink } from "sharedComponents/hooks/useGetPremiumLink";

import PremiumCrownSparklesSVG from "../../atoms/PremiumTooltipRich/resource/PremiumCrownSparkles.svg";
import ClassifyPreviewMP4 from "./resource/ClassifyPreview.mp4";
import DiagramPreviewMP4 from "./resource/DiagramPreview.mp4";

export interface IProps {
  isOpen: boolean;
  libraries: {
    label: string;
    value: PracticeSetAvailability;
    isDisabled?: boolean;
    leftIcon?: JSX.Element;
    tooltipText?: string;
  }[];
  states: IOption[];
  grades: IOption[];
  subjects: IOption[];
  practiceSet?: IPracticeSet;
  practiceSetItems: IPracticeSetItem[];
  searchLoading: boolean;
  preventCertifiedItemAdd?: boolean;
  preventPremiumItemAdd?: boolean;
  handleAddNew: (type: QuestionType, subType?: "text" | "images") => void;
  handleAddToSet: (set: IPracticeSetItem) => void;
  handleChangeFilter: (
    state: string,
    subject: string,
    grade: string,
    standardCollection?: string
  ) => void;
  handleSearch: (
    search: string,
    selectedLibrary: PracticeSetAvailability,
    filters: { state: string; subject: string; grade: string }
  ) => void;
  handleClose: () => void;
  handleEditPracticeSet: () => void;
  showPremiumMarker?: boolean;
}

export const PracticeSetAddQuestionsFlyout: React.FC<IProps> = ({
  isOpen,
  libraries,
  grades,
  states,
  subjects,
  practiceSet,
  practiceSetItems,
  searchLoading,
  preventCertifiedItemAdd,
  preventPremiumItemAdd,
  handleAddNew,
  handleAddToSet,
  handleClose,
  handleChangeFilter,
  handleSearch,
  handleEditPracticeSet,
  showPremiumMarker = true,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const [selectedQuestionType, setSelectedQuestionType] = useState(
    QuestionType.Unknown
  );
  const [selectedLibrary, setSelectedLibrary] = useState(libraries[0].value);
  const [isSearch, setIsSearch] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchFilters, setSearchFilters] = useState<{
    state: string;
    subject: string;
    grade: string;
  }>({ state: "", subject: "", grade: "" });
  const { trackEvent } = useAnalytics();
  const { hasNoPremiumAccess } = useAuth();
  const [showPremiumItemTypePreview, setShowPremiumItemTypePreview] =
    useState(false);

  const [showMCImageAndTextSubTypes, setShowMCImageAndTextSubTypes] =
    useState(false);
  const [
    showClassifyImageAndTextSubTypes,
    setShowClassifyImageAndTextSubTypes,
  ] = useState(false);
  const showImageAndTextSubTypes =
    showMCImageAndTextSubTypes || showClassifyImageAndTextSubTypes;

  const handleClickItemTypeWithSubTypes = (questionType: QuestionType) => {
    switch (questionType) {
      case QuestionType.MultipleChoice:
      case QuestionType.MultipleSelect:
        setShowMCImageAndTextSubTypes(true);
        break;
      case QuestionType.Classify:
        setShowClassifyImageAndTextSubTypes(true);
    }
  };

  const handleClickPremiumItemType = (questionType: QuestionType) => {
    setSelectedQuestionType(questionType);
    if (hasNoPremiumAccess) {
      setShowPremiumItemTypePreview(true);
    } else {
      if (questionType === QuestionType.Diagram) {
        _handleAddNew(questionType);
      } else if (questionType === QuestionType.Classify) {
        setShowClassifyImageAndTextSubTypes(true);
      }
    }
  };

  const _handleAddNew = (type: QuestionType, subType?: "text" | "images") => {
    setShowMCImageAndTextSubTypes(false);
    setShowClassifyImageAndTextSubTypes(false);
    setShowPremiumItemTypePreview(false);
    handleAddNew(type, subType);
  };

  const selectedLibraryLabel =
    libraries.find((lib) => lib.value === selectedLibrary)?.label ?? "";

  const handleTermSearch = useCallback(
    (value) => {
      setSearchTerm(value);

      if (value.trim() === "") {
        setIsSearch(false);
      } else {
        setIsSearch(true);
        handleSearch(value, selectedLibrary, searchFilters);
      }
    },
    [selectedLibrary, searchFilters, handleSearch]
  );

  const handleFilterChange = useCallback(
    (state, subject, grade, standardCollection) => {
      const filters = { state, subject, grade, standardCollection };
      setSearchFilters(filters);
      handleChangeFilter(state, subject, grade, standardCollection);
      handleSearch(searchTerm, selectedLibrary, filters);
    },
    [handleSearch, searchTerm, selectedLibrary, handleChangeFilter]
  );

  const _handleSelectLibrary = (library: PracticeSetAvailability) => {
    setSelectedLibrary(library);

    if (isSearch) {
      handleSearch(searchTerm, library, searchFilters);
    }
  };

  const _handleClose = () => {
    setShowMCImageAndTextSubTypes(false);
    setShowClassifyImageAndTextSubTypes(false);
    setShowPremiumItemTypePreview(false);
    handleClose();
  };

  const handleClearSearchTerm = () => {
    handleTermSearch("");
    setIsSearch(false);
  };

  const premiumLink = useGetPremiumLink();
  const handleUpgradeToPremiumClick = () => {
    window.location.href = premiumLink;
  };

  // reset search state on open
  useEffect(() => {
    if (isOpen) {
      setIsSearch(false);
      setSearchTerm("");
      setSearchFilters({
        state: "",
        subject: "",
        grade: "",
      });
    }
  }, [isOpen]);

  const getAllowCopy = ({
    isCertifiedItem,
    isPremiumItem,
  }: {
    isCertifiedItem: boolean;
    isPremiumItem: boolean;
  }) => {
    const allowCertifiedAdd = preventCertifiedItemAdd ? !isCertifiedItem : true;
    const allowPremiumAdd = preventPremiumItemAdd ? !isPremiumItem : true;
    return allowCertifiedAdd && allowPremiumAdd;
  };

  let title = t("practiceSetAddQuestionsFlyout.title");
  if (showMCImageAndTextSubTypes) {
    title = t("createMultipleChoiceFlyout.title");
  } else if (showClassifyImageAndTextSubTypes) {
    title = t("createClassificationFlyout.title");
  } else if (showPremiumItemTypePreview) {
    title = t(
      `practiceSetAddQuestions.${selectedQuestionType.toString().toLowerCase()}`
    );
  }

  let showSearchLibraries = true;
  if (
    showImageAndTextSubTypes ||
    showClassifyImageAndTextSubTypes ||
    showPremiumItemTypePreview
  ) {
    showSearchLibraries = false;
  }

  const [itemsToShow, hiddenPremiumItemCount] = useMemo(() => {
    const itemsToShow = hasNoPremiumAccess
      ? practiceSetItems.filter((item) => !isPremiumItem(item))
      : practiceSetItems;

    return [itemsToShow, practiceSetItems.length - itemsToShow.length];
  }, [hasNoPremiumAccess, practiceSetItems]);

  return (
    <AdminFlyout
      isOpen={isOpen}
      onClose={_handleClose}
      title={title}
      showBack={showImageAndTextSubTypes || showPremiumItemTypePreview}
      onBack={() => {
        setShowMCImageAndTextSubTypes(false);
        setShowClassifyImageAndTextSubTypes(false);
        setShowPremiumItemTypePreview(false);
      }}
    >
      {showPremiumItemTypePreview && (
        <Flex
          flexDir="column"
          w="full"
          h="full"
          alignItems="center"
          justifyContent="center"
          px={pxToRem(20)}
          py={pxToRem(100)}
          gap={pxToRem(30)}
        >
          <ReactPlayer
            url={
              selectedQuestionType === QuestionType.Diagram
                ? DiagramPreviewMP4
                : ClassifyPreviewMP4
            }
            controls={false}
            loop
            playing
            width="100%"
            height="100%"
          />
          <Spacer />
          <Flex
            alignItems="center"
            w="full"
            my={pxToRem(16)}
            borderColor="primary.tan"
            borderWidth={pxToRem(2)}
            borderRadius={pxToRem(10)}
            p={pxToRem(20)}
            gap={pxToRem(12)}
          >
            <Box
              bgColor="utility.premium"
              borderRadius="full"
              boxSize={pxToRem(36)}
              display="inline-flex"
              alignItems="center"
              justifyContent="center"
            >
              <Icon
                boxSize={pxToRem(22)}
                icon="lock"
                iconColor="primary.warm-white"
              />
            </Box>
            <Flex flexDir="column" w="full">
              <Text variant="adminP2">
                {selectedQuestionType === QuestionType.Diagram
                  ? t("premiumText.premiumItemPreview.diagramText")
                  : t("premiumText.premiumItemPreview.classifyText")}
              </Text>
              <Text
                alignSelf="start"
                cursor="pointer"
                variant="adminLinkMedium"
                color="utility.link"
                onClick={() => {
                  window.location.href = premiumLink;
                }}
              >
                {t("premiumText.upgradeToPremiumLink")}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      )}
      {!showPremiumItemTypePreview && (
        <Tabs
          variant="adminFlyoutTabs"
          handleChange={(index: number) => {
            if (index === 1) {
              trackEvent(
                AnalyticsEvent.TeacherDashboard_PracticeSetDetail_QuestionFlyout_GenerateItemIntent,
                { practiceSetId: practiceSet?.id }
              );
            }
          }}
          tabData={
            [
              {
                label: t(
                  "practiceSetAddQuestionsFlyout.customQuestionsTabLabel"
                ),
                content: (
                  <Box
                    pt={pxToRem(24)}
                    pb={[pxToRem(15), null, pxToRem(58)]}
                    px={[
                      "admin.flyout.mobileXPadding",
                      null,
                      "admin.flyout.desktopXPadding",
                    ]}
                  >
                    {showSearchLibraries && (
                      <>
                        <Box
                          py={[pxToRem(4.5), null, pxToRem(3)]}
                          mb={[pxToRem(16), null, pxToRem(12)]}
                        >
                          <Heading as="h3" variant="adminH6">
                            {t("practiceSetAddQuestions.addExistingFrom")}
                            <TextButtonDropdown
                              buttonText={t(
                                "practiceSetAddQuestions.searchLibraries"
                              )}
                              value={selectedLibrary}
                              menuItems={libraries.map((opt) => ({
                                label: opt.label,
                                value: opt.value,
                                isDisabled: opt.isDisabled,
                                leftIcon: opt.leftIcon,
                                tooltipText: opt.tooltipText,
                                handleClick: () =>
                                  _handleSelectLibrary(opt.value),
                              }))}
                            />
                          </Heading>
                        </Box>
                        <SearchInput
                          placeholder={t(
                            "practiceSetAddQuestions.searchTheLibrary",
                            {
                              library: selectedLibraryLabel,
                            }
                          )}
                          searching={searchLoading}
                          handleSearch={handleTermSearch}
                          handleClear={handleClearSearchTerm}
                        />
                      </>
                    )}
                    {isSearch && (
                      <Box mt={[pxToRem(16), null, pxToRem(12)]}>
                        <FilterPracticeSet
                          grades={grades}
                          gradeLevelIdFilter={searchFilters.grade}
                          states={states}
                          regionFilter={searchFilters.state}
                          subjects={subjects}
                          subjectIdFilter={searchFilters.subject}
                          handleChangeFilter={handleFilterChange}
                          mb={pxToRem(40)}
                        />
                      </Box>
                    )}
                    {!isSearch && (
                      <PracticeSetAddQuestions
                        handleAddNew={_handleAddNew}
                        handleClickItemTypeWithSubTypes={
                          handleClickItemTypeWithSubTypes
                        }
                        handleClickPremiumItemType={handleClickPremiumItemType}
                        showImageAndTextSubTypes={showImageAndTextSubTypes}
                        showPremiumMarker={showPremiumMarker}
                        questionType={
                          showMCImageAndTextSubTypes
                            ? QuestionType.MultipleChoice
                            : showClassifyImageAndTextSubTypes
                            ? QuestionType.Classify
                            : undefined
                        }
                      />
                    )}
                    {isSearch && !searchLoading && !!practiceSetItems.length && (
                      <SimpleGrid gap={pxToRem(24)}>
                        {itemsToShow.map((practiceSetItem) => (
                          <PracticeSetItemAccordion
                            handleAddToSet={handleAddToSet}
                            practiceSetItem={practiceSetItem}
                            allowCopy={getAllowCopy({
                              isCertifiedItem: practiceSetItem.is_certified,
                              isPremiumItem: isPremiumItem(practiceSetItem),
                            })}
                            key={practiceSetItem.id}
                            showPremiumMarker={showPremiumMarker}
                          />
                        ))}
                        {hiddenPremiumItemCount > 0 && (
                          <Card
                            variant="adminCardSmallBorder"
                            borderColor="primary.tan"
                          >
                            <Flex
                              flexDir="column"
                              gap={pxToRem(5)}
                              textAlign="center"
                              alignItems="center"
                            >
                              <Image
                                boxSize={[pxToRem(60), pxToRem(130)]}
                                src={PremiumCrownSparklesSVG}
                              />
                              <Text variant="adminP1Bold">
                                {t(
                                  "practiceSetAddQuestionsFlyout.premiumCertifiedItemsFound",
                                  { count: hiddenPremiumItemCount }
                                )}
                              </Text>
                              <Text variant="adminP1" color="primary.dark-gray">
                                {t(
                                  "practiceSetAddQuestionsFlyout.premiumDescription"
                                )}
                              </Text>
                              <Button
                                variant="adminButtonOutlined"
                                mt={pxToRem(10)}
                                onClick={() => handleUpgradeToPremiumClick()}
                              >
                                {t(
                                  "practiceSetAddQuestionsFlyout.upgradeToPremium"
                                )}
                              </Button>
                            </Flex>
                          </Card>
                        )}
                      </SimpleGrid>
                    )}
                    {isSearch && !practiceSetItems.length && !searchLoading && (
                      <Box
                        w="full"
                        textAlign="center"
                        borderWidth={pxToRem(2)}
                        borderColor="primary.tan"
                        borderRadius={pxToRem(10)}
                        p={pxToRem(15)}
                      >
                        <Text variant="adminP1">
                          {t("practiceSetAddQuestions.searchNoResults")}
                        </Text>
                      </Box>
                    )}
                    {isSearch && searchLoading && (
                      <Box w="full" display="flex" justifyContent="center">
                        <Spinner />
                      </Box>
                    )}
                  </Box>
                ),
              },
              {
                label: t(
                  "practiceSetAddQuestionsFlyout.aiQuestionGeneratorTabLabel"
                ),
                isPremium: true,
                content: (
                  <Box
                    pt={pxToRem(32)}
                    pb={[pxToRem(15), null, pxToRem(58)]}
                    px={[
                      "admin.flyout.mobileXPadding",
                      null,
                      "admin.flyout.desktopXPadding",
                    ]}
                  >
                    <GenerateItemForm
                      practiceSet={practiceSet}
                      handleEditPracticeSet={handleEditPracticeSet}
                    />
                  </Box>
                ),
              },
            ].filter((tab) => !!tab) as Array<TabData>
          }
        />
      )}
    </AdminFlyout>
  );
};
