import { useLifecycles } from "react-use";

import { SessionMusic } from "sessionComponents/types";
import { useSessionAudio } from "sharedComponents/contexts/sessionAudio";

export interface IArgs {
  roundNumber: number;
}

const roundTracks = [
  SessionMusic.Goldfish,
  SessionMusic.Lobsters,
  SessionMusic.Quokkas,
];

export const useRoundMusic = ({ roundNumber }: IArgs): void => {
  const { setBackgroundAudio } = useSessionAudio();

  useLifecycles(
    () => {
      const trackCount = roundTracks.length;
      const trackIndex = (roundNumber - 1) % trackCount;

      setBackgroundAudio(roundTracks[trackIndex]);
    },
    () => {
      setBackgroundAudio();
    }
  );
};
