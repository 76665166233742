import { Flex, Grid } from "@chakra-ui/react";
import React from "react";

import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { IDiagramLabel } from "links/lib/types";

export interface IProps {
  diagramLabels: Array<IDiagramLabel>;
  forceOneColumn?: boolean;
}

export const AnswerDiagram: React.FC<IProps> = ({
  diagramLabels,
  forceOneColumn = false,
}) => {
  return (
    <Grid
      templateColumns={forceOneColumn ? "1fr" : { base: "1fr", md: "1fr 1fr" }}
      rowGap={pxToRem(16)}
      columnGap={pxToRem(58)}
    >
      {diagramLabels.map((diagramLabel, index) => (
        <Flex key={index} align="baseline" gap={pxToRem(12)}>
          <Text
            variant="adminP1"
            fontWeight="demibold"
            w={pxToRem(18)}
            textAlign="right"
          >
            {index + 1}.
          </Text>
          <Text variant="adminP2">{diagramLabel.label}</Text>
        </Flex>
      ))}
    </Grid>
  );
};
