import { pxToRem } from "adminComponents/utils/pxToRem";

interface IProps {
  isHeroShifted: boolean;
  adminMode?: boolean;
}

export const AdminTemplateWithCenteredHeroOneColumn = {
  parts: [
    "outerContainer",
    "backgroundContainer",
    "shiftedHeroContainer",
    "heroContainer",
    "innerContainer",
    "loadingSpinner",
  ],
  baseStyle: ({
    isHeroShifted,
    adminMode,
  }: IProps): Record<string, unknown> => ({
    outerContainer: {
      backgroundColor: "primary.warm-white",
      minHeight: "100vh",
      paddingBottom: pxToRem(50),
      width: "100%",
    },
    backgroundContainer: {
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundAttachment: adminMode ? undefined : "fixed",
      backgroundBlendMode: "saturation",
    },
    heroContainerOuter: {
      paddingTop: isHeroShifted && !adminMode ? pxToRem(56) : 0,
    },
    shiftedHeroContainer: {
      position: "relative",
      zIndex: "0",
      _after: {
        content: "''",
        position: "absolute",
        top: adminMode ? "80%" : "50%",
        left: "0",
        right: "0",
        bottom: "0",
        backgroundColor: "primary.warm-white",
        zIndex: "-1",
      },
    },
    heroContainer: {
      paddingBottom: {
        base: adminMode ? pxToRem(16) : pxToRem(32),
        md: adminMode ? pxToRem(16) : pxToRem(64),
      },
      marginBottom: pxToRem(6),
    },
    innerContainer: {
      paddingTop: {
        base: adminMode ? pxToRem(16) : pxToRem(32),
        md: adminMode ? pxToRem(16) : pxToRem(56),
      },
      paddingBottom: pxToRem(50),
    },
    loadingSpinner: {
      marginTop: pxToRem(44),
    },
  }),
};
