import {
  Flex,
  FormControl,
  FormLabel,
  HStack,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import React, { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Card } from "adminComponents/atoms/Card";
import { Checkbox } from "adminComponents/atoms/Checkbox";
import { Dropdown, IOption } from "adminComponents/atoms/Dropdown";
import { Heading } from "adminComponents/atoms/Heading";
import { Switch } from "adminComponents/atoms/Switch";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils";
import {
  UserRecurringNotificationSettings,
  UserRecurringNotificationSettingsFrequency,
} from "links/lib/types";

export interface IProps {
  handleSubmit: (settings: UserRecurringNotificationSettings) => void;
  isLoading: boolean;
  initialSettings: UserRecurringNotificationSettings;
}

export const TeacherNotificationSettingsForm: React.FC<IProps> = ({
  handleSubmit,
  isLoading,
  initialSettings,
}) => {
  const [isFormChanged, setIsFormChanged] = useState(false);
  const { t } = useTranslation("admin", {
    keyPrefix: "teacherNotificationSettingsForm",
    useSuspense: false,
  });
  const { t: tCommon } = useTranslation("admin", {
    keyPrefix: "common",
    useSuspense: false,
  });
  const styles = useMultiStyleConfig("AdminTeacherAccountInformationForm", {});
  const [settings, setSettings] = useState(initialSettings);

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    handleSubmit(settings);
  };

  useEffect(() => {
    const isChanged =
      JSON.stringify(settings) !== JSON.stringify(initialSettings);
    setIsFormChanged(isChanged);
  }, [initialSettings, settings]);

  const toggleSetting = (
    settingKey: keyof UserRecurringNotificationSettings
  ) => {
    return () => {
      setSettings({
        ...settings,
        [settingKey]: !settings[settingKey],
      });
    };
  };

  const frequencyOptions = [
    {
      label: t("frequencyDaily"),
      value: UserRecurringNotificationSettingsFrequency.Daily,
    },
    {
      label: t("frequencyWeekly"),
      value: UserRecurringNotificationSettingsFrequency.Weekly,
    },
  ];

  const selectedFrequencyOption = frequencyOptions.find(
    (option) => option.value === settings.frequency
  );

  const onChangeFrequency = (option: unknown) => {
    setSettings({
      ...settings,
      frequency: (option as IOption)
        .value as UserRecurringNotificationSettingsFrequency,
    });
  };

  return (
    <Card
      sx={styles.outer}
      variant="adminCardThickBorder"
      borderColor="primary.tan"
    >
      <form onSubmit={onSubmit}>
        <Flex sx={styles.headerContainer}>
          <Heading as="h2" variant="adminH4">
            {t("heading")}
          </Heading>
        </Flex>
        <Flex sx={styles.formContainer}>
          <Flex sx={styles.fieldContainer}>
            <FormControl>
              <FormLabel htmlFor="disableNotifications">
                {t("labelDisableNotifications")}
              </FormLabel>
              <HStack>
                <Switch
                  name="disableNotifications"
                  id="disableNotifications"
                  isChecked={settings.notifications_disabled}
                  onChange={toggleSetting("notifications_disabled")}
                />
                <Text variant="adminP2">
                  {t("disableNotificationsDescription")}
                </Text>
              </HStack>
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="frequency">{t("labelFrequency")}</FormLabel>
              <Dropdown
                id="frequency"
                value={selectedFrequencyOption}
                options={frequencyOptions}
                isDisabled={settings.notifications_disabled}
                handleChange={onChangeFrequency}
              />
              <Text mt={pxToRem(6)} variant="adminP2">
                {t("frequencyDescription")}
              </Text>
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="enableTopicClassPerformanceSnapshot">
                {t("labelEnableTopicClassPerformanceSnapshot")}
              </FormLabel>
              <Checkbox
                name="enableTopicClassPerformanceSnapshot"
                id="enableTopicClassPerformanceSnapshot"
                isChecked={settings.enable_topic_class_performance_snapshot}
                isDisabled={settings.notifications_disabled}
                onChange={toggleSetting(
                  "enable_topic_class_performance_snapshot"
                )}
              >
                {t("enableTopicClassPerformanceSnapshotDescription")}
              </Checkbox>
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="enableTopicOpenAssignments">
                {t("labelEnableTopicOpenAssignments")}
              </FormLabel>
              <Checkbox
                name="enableTopicOpenAssignments"
                id="enableTopicOpenAssignments"
                isChecked={settings.enable_topic_open_assignments}
                isDisabled={settings.notifications_disabled}
                onChange={toggleSetting("enable_topic_open_assignments")}
              >
                {t("enableTopicOpenAssignmentsDescription")}
              </Checkbox>
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="enableTopicNewSetsFromFollowed">
                {t("labelEnableTopicNewSetsFromFollowed")}
              </FormLabel>
              <Checkbox
                name="enableTopicNewSetsFromFollowed"
                id="enableTopicNewSetsFromFollowed"
                isChecked={settings.enable_topic_new_sets_from_followed}
                isDisabled={settings.notifications_disabled}
                onChange={toggleSetting("enable_topic_new_sets_from_followed")}
              >
                {t("enableTopicNewSetsFromFollowedDescription")}
              </Checkbox>
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="enableTopicCommunityImpact">
                {t("labelEnableTopicCommunityImpact")}
              </FormLabel>
              <Checkbox
                name="enableTopicCommunityImpact"
                id="enableTopicCommunityImpact"
                isChecked={settings.enable_topic_community_impact}
                isDisabled={settings.notifications_disabled}
                onChange={toggleSetting("enable_topic_community_impact")}
              >
                {t("enableTopicCommunityImpactDescription")}
              </Checkbox>
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="enableTopicQuickUpdates">
                {t("labelEnableTopicQuickUpdates")}
              </FormLabel>
              <Checkbox
                name="enableTopicQuickUpdates"
                id="enableTopicQuickUpdates"
                isChecked={settings.enable_topic_quick_updates}
                isDisabled={settings.notifications_disabled}
                onChange={toggleSetting("enable_topic_quick_updates")}
              >
                {t("enableTopicQuickUpdatesDescription")}
              </Checkbox>
            </FormControl>
          </Flex>
          <Flex sx={styles.buttonContainer}>
            <Button
              size="sm"
              type="submit"
              variant="adminButtonFilled"
              disabled={!isFormChanged}
              sx={styles.button}
              isLoading={isLoading}
            >
              {tCommon("save")}
            </Button>
          </Flex>
        </Flex>
      </form>
    </Card>
  );
};
