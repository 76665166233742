import { pxToRem } from "adminComponents/utils/pxToRem";

export const AdminQuestionCard = {
  baseStyle: ({
    hasNoPremiumAccess,
  }: {
    hasNoPremiumAccess: boolean;
  }): Record<string, unknown> => ({
    outer: {
      display: "flex",
      flexDirection: ["row", null, "column"],
      alignItems: "center",
      paddingTop: { base: pxToRem(20), md: pxToRem(24) },
      paddingBottom: { base: pxToRem(20), md: pxToRem(28) },
      paddingX: { base: pxToRem(20), md: pxToRem(21) },
      gap: { base: pxToRem(20), md: pxToRem(16) },
      position: "relative",
    },
    iconContainer: {
      width: pxToRem(44),
      height: pxToRem(44),
      position: "relative",
    },
    icon: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translateX(-50%) translateY(-50%)",
      display: "inline-flex",
      width: pxToRem(18),
      height: pxToRem(18),
    },
    textContainer: {
      textAlign: { base: "left", md: "center" },
    },
    title: {
      mb: pxToRem(4),
    },
    premiumBox: {
      backgroundColor: hasNoPremiumAccess ? "transparent" : "utility.premium",
      borderRadius: "full",
      width: pxToRem(24),
      height: pxToRem(24),
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      top: pxToRem(10),
      right: pxToRem(10),
    },
    premiumIcon: {
      width: pxToRem(14),
      height: pxToRem(14),
      display: "inline-flex",
    },
  }),
};
