import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { useAxios } from "../../hooks/useAxios";

export interface IAddUsersToOrganizationParams {
  emails: Array<string>;
  sendEmailNotifications: boolean;
}

export enum ErrorType {
  None = "NONE",
  AlreadyInOrganization = "ALREADY_IN_ORGANIZATION",
  HasExistingOrganization = "HAS_EXISTING_ORGANIZATION",
  InvalidEmail = "INVALID_EMAIL",
  IsStudent = "IS_STUDENT",
  Internal = "INTERNAL",
}

export interface IAddUsersToOrganizationResponse {
  results: Array<{
    email_address: string;
    error_type: ErrorType;
  }>;
}

export default function useAddUsersToOrganization(): UseMutationResult<
  IAddUsersToOrganizationResponse,
  unknown,
  IAddUsersToOrganizationParams
> {
  const axios = useAxios();
  const queryClient = useQueryClient();

  const AddUsersToOrganization = async (
    args: IAddUsersToOrganizationParams
  ) => {
    const { emails, sendEmailNotifications } = args;

    const response = await axios({
      method: "post",
      url: `/v1/organizations/users`,
      data: {
        email_addresses: emails,
        send_email_notifications: sendEmailNotifications,
      },
    }).then((r) => r.data as IAddUsersToOrganizationResponse);

    queryClient.invalidateQueries(["organizationUsers"]);
    queryClient.invalidateQueries([
      "top-organization-session-creators",
      { exact: false },
    ]);
    queryClient.invalidateQueries([
      "top-organization-assignment-creators",
      { exact: false },
    ]);
    queryClient.invalidateQueries([
      "educator-with-group-details-bulk",
      { exact: false },
    ]);

    return response;
  };

  const mutation = useMutation(AddUsersToOrganization);

  return mutation;
}
