import {
  Box,
  HStack,
  Spinner,
  ToastId,
  ToastPositionWithLogical,
  useToast,
} from "@chakra-ui/react";
import React, { ReactNode, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Toast } from "adminComponents/atoms/Toast";

export type { ToastId } from "@chakra-ui/react";

type ShowToastFN = (
  children: ReactNode,
  duration?: number
) => ToastId | undefined;

export const useShowToast = (): ShowToastFN => {
  const toast = useToast();

  const showToast = useCallback(
    (children: ReactNode, duration = 5000): ToastId | undefined => {
      // We determine the position for the toast based on the width of
      // the screen. We'd like to get this value for the mobile breakpoint
      // from Chakra rather than hardcoding it, but we were unable to determine
      // how to do that.
      const position: ToastPositionWithLogical =
        window.innerWidth >= 768 ? "bottom-right" : "top";

      const toastId = toast({
        position: position,
        isClosable: true,
        duration: duration,
        containerStyle: {
          minWidth: "initial",
        },
        render: (props) => {
          return <Toast toastId={props.id}>{children}</Toast>;
        },
      });

      return toastId;
    },
    [toast]
  );

  return showToast;
};

export const usePendingToast = (): {
  showPendingToast: ShowToastFN;
  closePendingToast: (toastId?: ToastId) => void;
} => {
  const toast = useToast();
  const showToast = useShowToast();

  const showPendingToast = useCallback(
    (children: ReactNode, duration = 5000) => {
      return showToast(
        <HStack as="span">
          <Spinner as="span" />
          <Box as="span">{children}</Box>
        </HStack>,
        duration
      );
    },
    [showToast]
  );

  const closePendingToast = useCallback(
    (toastId?: ToastId) => {
      if (!toastId) return;
      toast.close(toastId);
    },
    [toast]
  );

  const value = useMemo(
    () => ({
      showPendingToast,
      closePendingToast,
    }),
    [showPendingToast, closePendingToast]
  );

  return value;
};

export const useErrorToast = (error: unknown, title?: string): void => {
  const toast = useShowToast();
  const { t } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "common",
  });

  const defaultTitle = t("error") + ": ";

  useEffect(() => {
    if (error && error instanceof Error) {
      toast((title ? title : defaultTitle) + error.message);
    }
  }, [error, defaultTitle, title, toast]);
};
