import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import {
  AvatarSkeletonType,
  IAvatarItem,
  IFetchAvatarResponse,
} from "../../types";

export interface IFetchLiveAvatarParams {
  disabled?: boolean;
  item: IAvatarItem;
  spine_config_seated: string;
  spine_config_standing: string;
  skeleton_type: AvatarSkeletonType;
  onSuccess?: (data: IFetchAvatarResponse) => void;
}

/**
 * Fetches the avatar for the current user with the provided
 * live item and spine configurations.
 * @returns
 */
export default function useFetchLiveAvatar(
  params: IFetchLiveAvatarParams
): UseQueryResult<IFetchAvatarResponse> {
  const axios = useAxios();

  const {
    disabled,
    item,
    spine_config_seated,
    spine_config_standing,
    skeleton_type,
    onSuccess,
  } = params;

  const query = useQuery<IFetchAvatarResponse>(
    ["avatar", { item, skeleton_type }],
    async (): Promise<IFetchAvatarResponse> => {
      return await axios
        .post("/v1/avatar/self", {
          include_items: true,
          item: {
            ...item,
            rarity: item.rarity.toString(),
          },
          spine_config_seated,
          spine_config_standing,
          skeleton_type,
        })
        .then((r) => r.data as IFetchAvatarResponse);
    },
    {
      enabled: !disabled,
      onSuccess: onSuccess,
    }
  );

  return query;
}
