import { Box, Image } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { SessionTheBigBoardSpaceType } from "links/lib/types";

import CarePackage from "../resource/carePackage.svg";
import { Bonus } from "./Bonus";

export interface ISpecialBoardCellProps {
  spaceType: SessionTheBigBoardSpaceType;
  disabled: boolean;
  prizeRoundPoints?: number;
}

export const SpecialBoardCell: React.FC<ISpecialBoardCellProps> = ({
  spaceType,
  disabled,
  prizeRoundPoints,
}) => {
  const { t } = useTranslation("session", {
    keyPrefix: "bigBoard",
    useSuspense: false,
  });

  const getImage = (spaceType: SessionTheBigBoardSpaceType) => {
    switch (spaceType) {
      case SessionTheBigBoardSpaceType.Lootbox:
        return (
          <Image
            w="90%"
            h="90%"
            alt={t("carePackageImgAltText")}
            src={CarePackage}
          />
        );
      case SessionTheBigBoardSpaceType.Smiley:
        return <Bonus prizeRoundPoints={prizeRoundPoints || 0}></Bonus>;
      default:
        return <></>; //not implemented
    }
  };

  const image = getImage(spaceType);

  return (
    <Box
      bgColor={disabled ? "primary.tan" : undefined}
      boxSize="full"
      position="absolute"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {!disabled && image}
    </Box>
  );
};
