import {
  Avatar,
  AvatarBadge,
  AvatarProps,
  Box,
  Image,
  useMultiStyleConfig,
  usePrefersReducedMotion,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { ColorScheme } from "adminComponents";
import Silhouette from "adminComponents/assets/images/SilhouetteAvatar.png";

import FlameGif from "./resources/flame.gif";
import { ReactComponent as Flame } from "./resources/flame.svg";
import { ReactComponent as SegmentSvgMedium } from "./resources/segment-md.svg";
import { ReactComponent as SegmentSvgSmall } from "./resources/segment-sm.svg";

const TOTAL_SEGMENTS = 3;

export interface IProps extends AvatarProps {
  activeSegment?: number;
  showSilhouetteAsFallback?: boolean;
  weekCount?: number;
  backgroundColor?: ColorScheme;
}

export const AvatarStreak: React.FC<IProps> = ({
  activeSegment = 0,
  showSilhouetteAsFallback,
  weekCount = 0,
  backgroundColor,
  name,
  size,
  src,
  variant = "adminAvatarStreak",
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const prefersReducedMotion = usePrefersReducedMotion();
  const styles = useMultiStyleConfig("AdminAvatarStreak", {
    activeSegment,
    backgroundColor,
    size,
    variant,
  });
  const Segment = size === "sm" ? SegmentSvgSmall : SegmentSvgMedium;
  const imageSrc = src
    ? src
    : showSilhouetteAsFallback
    ? Silhouette
    : undefined;
  return (
    <Box
      sx={styles.container}
      aria-label={name || t("common.avatar")}
      role="progressbar"
      aria-valuenow={activeSegment}
      aria-valuemin={0}
      aria-valuemax={TOTAL_SEGMENTS}
    >
      <Avatar sx={styles.avatar} src={imageSrc} name={name}>
        {weekCount > 0 && <Box sx={styles.weekCountContainer}>{weekCount}</Box>}
        <AvatarBadge sx={styles.badge}>
          {activeSegment > 0 &&
            (prefersReducedMotion ? (
              <Flame />
            ) : (
              <Image src={FlameGif} alt="flame" />
            ))}
        </AvatarBadge>
      </Avatar>
      {[...new Array(TOTAL_SEGMENTS)].map((_, index) => {
        return (
          <Box
            as={Segment}
            key={index}
            sx={styles.segment}
            aria-hidden
            focusable={false}
          />
        );
      })}
    </Box>
  );
};
