// for multiple choice and multi select (checkbox and radio) questions
import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { v4 as uuid } from "uuid";

import { isRichTextEmpty } from "adminComponents/organisms/RichTextEditor/util";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { RichText } from "links/lib/types";
import {
  QuestionResponseWrapper,
  QuestionResponseWrapperInjectedProps,
} from "sessionComponents/atoms/QuestionResponseWrapper";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import {
  IGenericResponseComponentContentProps,
  IGenericResponseComponentProps,
} from "sessionComponents/types";
import { RichTextRenderer } from "sharedComponents/atoms/RichTextRenderer";

import { ResponseAvatarIcons } from "../ResponseAvatarIcons";
import { ResponseIcon } from "../ResponseIcon";

export interface IGenericTextOptionProps
  extends IGenericResponseComponentProps {
  text: string;
  prefix?: RichText;
  suffix?: RichText;
}

export const GenericTextOption: React.FC<IGenericTextOptionProps> = (props) => {
  const { showAccuracyBar, ariaLabel, text, maxWidth } = props;
  const { match: currentBreakpoints } = useBreakpoints();
  const height = currentBreakpoints.responseHeight;

  return (
    <QuestionResponseWrapper
      minHeight={pxToRem(showAccuracyBar ? height + 16 : height)}
      maxWidth={maxWidth}
      overflow="hidden"
      ariaLabel={ariaLabel || text}
      {...props}
    >
      {(injectedProps: QuestionResponseWrapperInjectedProps) => (
        <GenericTextOptionContent {...props} {...injectedProps} />
      )}
    </QuestionResponseWrapper>
  );
};

interface IGenericTextOptionContentProps
  extends IGenericResponseComponentContentProps {
  text: string;
  prefix?: RichText;
  suffix?: RichText;
}

const GenericTextOptionContent = (props: IGenericTextOptionContentProps) => {
  const {
    showCheckbox,
    showAccuracyBar,
    showRadio,
    iconColor,
    primaryColor,
    variant,
    success,
    error,
    selected,
    onClick,
    disabled,
    loading,
    users,
    text,
    showTextInputIcon,
    fontColor,
    questionType,
    showAsSelectable,
    showAsDefaultWrongAnswer,
    prefix,
    suffix,
    partiallyCorrect,
    otherTeammatePartiallyCorrect,
  } = props;

  const { match: currentBreakpoints } = useBreakpoints();
  const hasPrefix = !isRichTextEmpty(prefix as string);
  const hasSuffix = !isRichTextEmpty(suffix as string);

  const labelId = uuid();
  return (
    <>
      <Flex alignItems="center">
        {showAsSelectable && (
          <ResponseIcon
            aria-labelledby={labelId}
            showCheckbox={showCheckbox}
            showRadio={showRadio}
            iconColor={iconColor || primaryColor}
            variant={variant}
            iconSize={pxToRem(currentBreakpoints.iconSize)}
            fontSize={pxToRem(currentBreakpoints.fontSize)}
            marginRight={pxToRem(currentBreakpoints.margin / 2)}
            success={
              success || otherTeammatePartiallyCorrect || partiallyCorrect
            }
            error={error || showAsDefaultWrongAnswer}
            selected={selected}
            onClick={onClick}
            isDisabled={disabled}
            isLoading={loading}
            showTextInputIcon={showTextInputIcon}
            questionType={questionType}
            spinnerWidth={pxToRem(currentBreakpoints.borderWidth)}
          />
        )}

        <Box minWidth={0} id={labelId}>
          <Text
            as="div"
            textStyle="gameText"
            fontSize={pxToRem(currentBreakpoints.fontSize)}
            userSelect="none"
            display="flex"
            color={fontColor}
          >
            {hasPrefix && prefix && (
              <Box marginRight={pxToRem(5)} minWidth="max-content">
                <RichTextRenderer content={prefix} />
              </Box>
            )}
            <RichTextRenderer content={text} />
            {hasSuffix && suffix && (
              <Box marginLeft={pxToRem(5)} minWidth="max-content">
                <RichTextRenderer content={suffix} />
              </Box>
            )}
          </Text>
        </Box>
      </Flex>
      {!!users?.length && (
        <ResponseAvatarIcons
          // note: these need to be numbers bc they are added together in this child component
          avatarIconSize={currentBreakpoints.avatarIconSize}
          right={currentBreakpoints.margin}
          bottom={showAccuracyBar ? 16 : 0}
          users={users}
        />
      )}
    </>
  );
};
