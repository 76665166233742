import { pxToRem } from "adminComponents/utils/pxToRem";

export const AdminShareAvatarOverlayLayout = {
  baseStyle: {
    content: {
      bgColor: "primary.warm-white",
      transform: "none !important",
    },
    body: {
      padding: 0,
    },
    container: {
      height: {
        lg: "100%",
      },
      flexDirection: {
        base: "column",
        lg: "row",
      },
      justifyContent: {
        lg: "space-between",
      },
    },
    leftColumn: {
      width: {
        base: "100%",
        lg: "50%",
      },
      paddingTop: pxToRem(46),
      paddingBottom: {
        base: pxToRem(92),
        lg: pxToRem(46),
      },
      paddingLeft: {
        base: pxToRem(36),
        lg: pxToRem(15),
      },
      paddingRight: {
        base: pxToRem(36),
        lg: pxToRem(15),
      },
    },
    rightColumn: {
      backgroundColor: {
        base: "transparent",
        lg: "primary.warm-white",
      },
      width: {
        base: "100%",
        lg: "50%",
      },
      minW: {
        base: "100%",
        lg: pxToRem(590),
      },
      ml: "auto",
      mr: "auto",
      mt: {
        base: pxToRem(-44),
        md: pxToRem(-54),
        lg: 0,
      },
      paddingTop: {
        base: pxToRem(0),
        lg: pxToRem(120),
      },
      paddingBottom: pxToRem(46),
      paddingLeft: {
        base: pxToRem(0),
        lg: pxToRem(0),
      },
      paddingRight: {
        base: pxToRem(0),
        lg: pxToRem(0),
      },
      position: "relative",
      _before: {
        content: `""`,
        position: "absolute",
        width: "100%",
        height: "100%",
        bg: {
          base: "tranparent",
          lg: "primary.warm-white",
        },
        top: pxToRem(0),
        zIndex: -1,
      },
    },
    iconWrapper: {
      position: "relative",
      justifyContent: "flex-start",
      marginBottom: {
        base: pxToRem(44),
        lg: pxToRem(42),
      },
      pl: {
        base: pxToRem(0),
        lg: pxToRem(43),
      },
    },
    icon: {
      backgroundColor: "primary.golden",
      borderRadius: "100%",
      marginRight: pxToRem(28),
    },
  },
};
