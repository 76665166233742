import {
  Box,
  Center,
  Flex,
  HStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Card } from "adminComponents/atoms/Card";
import { Dropdown } from "adminComponents/atoms/Dropdown";
import { Dropzone } from "adminComponents/atoms/Dropzone";
import { FormLabel } from "adminComponents/atoms/FormLabel";
import { Heading } from "adminComponents/atoms/Heading";
import { Icon } from "adminComponents/atoms/Icon";
import { Input } from "adminComponents/atoms/Input";
import { RiveIcon } from "adminComponents/atoms/RiveIcon";
import { Tabs } from "adminComponents/atoms/Tabs";
import { Tag } from "adminComponents/atoms/Tag";
import { Text } from "adminComponents/atoms/Text";
import { Textarea } from "adminComponents/atoms/Textarea";
import { NavigationHeader } from "adminComponents/molecules/NavigationHeader";
import InstantSetRiveSrc from "adminComponents/molecules/PracticeSetTypeSelection/resource/magic_set.riv";
import { TemplateWithCenteredHeroOneColumn } from "adminComponents/templates/TemplateWithCenteredHeroOneColumn";
import { pxToRem } from "adminComponents/utils";
import { IGuestNavigationDataContext } from "screens/TeacherDashboard/contexts/GuestTeacherNavigationDataProvider";

import { SignInCard } from "./components/SignInCard";

interface IProps {
  navigationData: IGuestNavigationDataContext;
  handleGenerateSet: () => void;
  handleSignIn: () => void;
}

/**
 * CreateInstantSet is a read-only screen that displays to guest teachers when
 * they attempt to create an instant set. It provides a preview of how instant
 * sets can be generated, and prompts the user to sign in to access the feature.
 */
export const CreateInstantSet: React.FC<IProps> = ({
  navigationData,
  handleGenerateSet,
  handleSignIn,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const [triggerSignInAnimation, setTriggerSignInAnimation] = useState(false);
  const [isHover, setIsHover] = useState<boolean>(false);
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <TemplateWithCenteredHeroOneColumn
      outerContainerStyle={{
        paddingBottom: pxToRem(250),
      }}
      nav={
        <NavigationHeader
          resourceLinks={[]}
          accountLinks={[]}
          {...navigationData}
          handleCreatePracticeSet={handleGenerateSet}
          handleSelectGrades={() => {
            // noop
          }}
          handleSelectSubjects={() => {
            // noop
          }}
          isGuest={true}
          disableDropdowns={true}
          handleSignUp={handleSignIn}
        />
      }
      heroContent={
        <Flex
          mt={{ base: undefined, md: pxToRem(30) }}
          w="full"
          flexDirection={isMobile ? "column" : "row"}
          alignItems="center"
          justifyContent="start"
          textAlign="center"
          gap={pxToRem(24)}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          <RiveIcon
            src={InstantSetRiveSrc}
            isPlaying={isHover}
            animations="loop"
          />
          <Heading variant="adminH3" as="h1">
            {t("guestTeacherCreateInstantSet.title")}
          </Heading>
          <Tag colorScheme="primary.tan-dark">{t("common.new")}</Tag>
        </Flex>
      }
    >
      <Flex
        flexDirection={isMobile ? "column" : "row"}
        w="full"
        h="full"
        gap={pxToRem(50)}
        mb={pxToRem(24)}
      >
        <Card
          borderColor="primary.tan"
          bgColor="primary.white"
          variant="adminCardMediumBorder"
          h="fit-content"
        >
          <Tabs
            variant="adminTabs"
            tabData={[
              {
                label: t("guestTeacherCreateInstantSet.tabTopic"),
                content: (
                  <Flex
                    h="full"
                    flexDirection="column"
                    gap={pxToRem(16)}
                    p={pxToRem(28)}
                    onClick={() =>
                      setTriggerSignInAnimation(
                        (triggerSignInAnimation) => !triggerSignInAnimation
                      )
                    }
                  >
                    <Flex flexDirection="column" gap={pxToRem(8)}>
                      <FormLabel>Topic</FormLabel>
                      <Textarea
                        isReadOnly
                        h={pxToRem(200)}
                        placeholder={t(
                          "instantSetConfiguration.textInputPlaceholder"
                        )}
                      />
                    </Flex>
                    <Flex
                      flexDirection={{ base: "column", sm: "row" }}
                      gap={pxToRem(16)}
                      w="full"
                    >
                      <Flex
                        flexDirection="column"
                        gap={pxToRem(8)}
                        w={{ base: "full", sm: "50%" }}
                      >
                        <FormLabel>{t("common.gradeLevel")}</FormLabel>
                        <Dropdown
                          isDisabled
                          placeholder={t(
                            "guestTeacherCreateInstantSet.gradeLevelPlaceholder"
                          )}
                        />
                      </Flex>
                      <Flex
                        flexDirection="column"
                        gap={pxToRem(8)}
                        w={{ base: "full", sm: "50%" }}
                      >
                        <FormLabel>
                          {t("instantSetConfiguration.questionCountLabel")}
                        </FormLabel>
                        <Dropdown isDisabled placeholder="5" />
                      </Flex>
                    </Flex>
                    <Button
                      mt={pxToRem(16)}
                      w="fit-content"
                      variant="adminButtonFilled"
                      leftIcon={<Icon icon="sparkle" />}
                    >
                      {t("ctaBanner.buttonText")}
                    </Button>
                  </Flex>
                ),
              },
              {
                label: t("guestTeacherCreateInstantSet.tabArticle"),
                content: (
                  <Flex
                    h="full"
                    flexDirection="column"
                    gap={pxToRem(16)}
                    p={pxToRem(28)}
                    onClick={() =>
                      setTriggerSignInAnimation(
                        (triggerSignInAnimation) => !triggerSignInAnimation
                      )
                    }
                  >
                    <Flex flexDirection="column" gap={pxToRem(8)}>
                      <FormLabel>Topic</FormLabel>
                      <Input
                        isReadOnly
                        placeholder={t("instantSetConfiguration.urlInputLabel")}
                      />
                    </Flex>
                    <Flex
                      flexDirection={{ base: "column", sm: "row" }}
                      gap={pxToRem(16)}
                      w="full"
                    >
                      <Flex
                        flexDirection="column"
                        gap={pxToRem(8)}
                        w={{ base: "full", sm: "50%" }}
                      >
                        <FormLabel>{t("common.gradeLevel")}</FormLabel>
                        <Dropdown isDisabled placeholder="7th Grade" />
                      </Flex>
                      <Flex
                        flexDirection="column"
                        gap={pxToRem(8)}
                        w={{ base: "full", sm: "50%" }}
                      >
                        <FormLabel>
                          {t("instantSetConfiguration.questionCountLabel")}
                        </FormLabel>
                        <Dropdown isDisabled placeholder="5" />
                      </Flex>
                    </Flex>
                    <Button
                      mt={pxToRem(16)}
                      w="fit-content"
                      variant="adminButtonFilled"
                      leftIcon={<Icon icon="sparkle" />}
                    >
                      {t("ctaBanner.buttonText")}
                    </Button>
                  </Flex>
                ),
              },
              {
                label: t("guestTeacherCreateInstantSet.tabFile"),
                content: (
                  <Flex
                    h="full"
                    flexDirection="column"
                    gap={pxToRem(16)}
                    p={pxToRem(28)}
                    onClick={() =>
                      setTriggerSignInAnimation(
                        (triggerSignInAnimation) => !triggerSignInAnimation
                      )
                    }
                  >
                    <Flex flexDirection="column" gap={pxToRem(8)}>
                      <FormLabel>Topic</FormLabel>
                      <Dropzone
                        sx={{
                          h: pxToRem(200),
                        }}
                        maxFiles={0}
                        accept={[]}
                        multiple={false}
                        isDisabled
                      >
                        <Center>
                          <Text variant="adminP2Bold">
                            {t(
                              "instantSetConfiguration.fileInputDropzoneLabel"
                            )}
                          </Text>
                        </Center>
                      </Dropzone>
                    </Flex>
                    <Flex
                      flexDirection={{ base: "column", sm: "row" }}
                      gap={pxToRem(16)}
                      w="full"
                    >
                      <Flex
                        flexDirection="column"
                        gap={pxToRem(8)}
                        w={{ base: "full", sm: "50%" }}
                      >
                        <FormLabel>{t("common.gradeLevel")}</FormLabel>
                        <Dropdown isDisabled placeholder="7th Grade" />
                      </Flex>
                      <Flex
                        flexDirection="column"
                        gap={pxToRem(8)}
                        w={{ base: "full", sm: "50%" }}
                      >
                        <FormLabel>
                          {t("instantSetConfiguration.questionCountLabel")}
                        </FormLabel>
                        <Dropdown isDisabled placeholder="5" />
                      </Flex>
                    </Flex>
                    <Button
                      mt={pxToRem(16)}
                      w="fit-content"
                      variant="adminButtonFilled"
                      leftIcon={<Icon icon="sparkle" />}
                    >
                      {t("ctaBanner.buttonText")}
                    </Button>
                  </Flex>
                ),
              },
            ]}
          ></Tabs>
        </Card>
        <Flex
          maxW={isMobile ? "full" : "33%"}
          flexDirection="column"
          gap={pxToRem(32)}
        >
          <Heading variant="adminH5Bold" as="h2">
            {t("guestTeacherCreateInstantSet.subHeading")}
          </Heading>
          <HStack alignItems="start">
            <Icon icon="timer_outlined" />
            <Text color="primary.dark-gray" variant="adminP1">
              {t("guestTeacherCreateInstantSet.feature1")}
            </Text>
          </HStack>
          <HStack alignItems="start">
            <Icon icon="check" />
            <Text color="primary.dark-gray" variant="adminP1">
              {t("guestTeacherCreateInstantSet.feature2")}
            </Text>
          </HStack>
          <HStack alignItems="start">
            <Icon icon="edit" />
            <Text color="primary.dark-gray" variant="adminP1">
              {t("guestTeacherCreateInstantSet.feature3")}
            </Text>
          </HStack>
          <HStack alignItems="start">
            <Icon icon="diagram_outlined" />
            <Text color="primary.dark-gray" variant="adminP1">
              {t("guestTeacherCreateInstantSet.feature4")}
            </Text>
          </HStack>
          <Box borderRadius="xl" bgColor="primary.tan" p={pxToRem(24)}>
            <Text
              variant="adminP1"
              fontStyle="italic"
              color="primary.dark-gray"
              whiteSpace="pre-line"
            >
              {t("guestTeacherCreateInstantSet.testimonial")}
            </Text>
          </Box>
        </Flex>
      </Flex>
      <SignInCard
        key={String(triggerSignInAnimation)}
        handleSignIn={handleSignIn}
      />
    </TemplateWithCenteredHeroOneColumn>
  );
};
