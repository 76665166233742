import { useCallback } from "react";

import { IUseAsyncReturn, useAsync } from "links/lib/hooks/useAsync";

export interface ILoadImageArgs {
  src: string;
}

export interface ILoadImageResponse {
  image: HTMLImageElement;
}

// Load an image on execute
const useImage = (): IUseAsyncReturn<ILoadImageResponse, ILoadImageArgs> => {
  const loadImage = useCallback((args: ILoadImageArgs) => {
    return new Promise<ILoadImageResponse>((resolve, reject) => {
      const image = new Image();

      image.onload = function () {
        resolve({ image });
      };

      image.onerror = function () {
        reject(new Error("Failed to load image"));
      };

      image.src = args.src;
    });
  }, []);

  return useAsync<ILoadImageResponse, ILoadImageArgs>(loadImage);
};

export default useImage;
