import { Box, Image } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useDrop } from "react-dnd";
import { useUnmount } from "react-use";

import { pxToRem } from "adminComponents/utils/pxToRem";
import {
  IPosition,
  ISessionDraggableSortItem,
  ISessionDraggableSortItemZone,
} from "links/lib/types";
import { DraggableLabel } from "sessionComponents/atoms/DraggableLabel";
import { DroppableZone } from "sessionComponents/atoms/DroppableZone";
import { Icon } from "sessionComponents/atoms/Icon";
import { Marker } from "sessionComponents/atoms/Marker";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";

interface IDiagramDropZoneProps {
  imageUrl: string;
  zones: ISessionDraggableSortItemZone[];
  dragsortItems: ISessionDraggableSortItem[];
  onDrop: (labelId: string, zoneId: string) => void; // ws.setSortChoiceZone
  handleHover: (labelId: string, coords: IPosition) => void;
  imageAlt?: string;
  userIsDraggingItem: boolean;
}

export const DiagramDropZone: React.FC<IDiagramDropZoneProps> = ({
  imageUrl,
  zones,
  dragsortItems,
  onDrop,
  handleHover,
  imageAlt,
  userIsDraggingItem,
}) => {
  const [showZones, setShowZones] = useState(true);
  const { match: currentBreakpoints } = useBreakpoints();
  const imageRef = useRef<HTMLImageElement>(null);
  const resizeTimer = useRef<NodeJS.Timeout | null>(null);
  const [dimensions, setDimensions] = useState({ w: 0, h: 0 });
  const { diagramTargetSize, iconSize, margin } = currentBreakpoints;

  const [, drop] = useDrop({
    accept: "LABEL",
    drop(item: ISessionDraggableSortItem) {
      onDrop(item.id, "0");
    },
    hover(item: ISessionDraggableSortItem, monitor) {
      const coords = monitor.getClientOffset() || { x: 0, y: 0 };
      handleHover(item.id, coords);
    },
  });

  useEffect(() => {
    const handleResize = () => {
      if (resizeTimer.current) clearTimeout(resizeTimer.current);
      resizeTimer.current = setTimeout(() => {
        setDimensions({
          w: imageRef.current?.clientWidth || 0,
          h: imageRef.current?.clientHeight || 0,
        });
      }, 250);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dimensions]);

  useUnmount(() => {
    if (resizeTimer.current) clearTimeout(resizeTimer.current);
  });

  return (
    <Box display="flex" justifyContent="center" marginTop={pxToRem(margin)}>
      <Box
        bgColor="white"
        h="max-content"
        position="relative"
        maxWidth="fit-content"
      >
        <Box ref={drop}>
          <Image
            ref={imageRef}
            position="relative"
            src={imageUrl}
            alt={imageAlt}
            fill="white"
            onLoad={() =>
              setDimensions({
                w: imageRef.current?.clientWidth || 0,
                h: imageRef.current?.clientHeight || 0,
              })
            }
          />
        </Box>
        {zones.map((z) => {
          const left = (z.x || 0) * dimensions.w - diagramTargetSize / 2;
          const top = (z.y || 0) * dimensions.h - diagramTargetSize / 2;

          const labelInZoneIndex = dragsortItems.findIndex((label) => {
            return label.zone_id === z.id;
          });
          const labelInZone =
            labelInZoneIndex !== -1 && dragsortItems[labelInZoneIndex];

          const LabeledMarker = (
            <Marker
              containerHeight={pxToRem(currentBreakpoints.responseHeight)}
              iconHeight={pxToRem(currentBreakpoints.markerSize)}
              iconColor="utility.link"
              number={labelInZoneIndex + 1}
            />
          );

          return (
            <Box
              key={`zone-${z.id}`}
              left={left}
              top={top}
              className="dnd-drop-zone"
              data-zone-id={z.id}
              position="absolute"
              borderRadius="full"
              opacity={showZones ? 1 : 0}
              transition="opacity 200ms linear"
            >
              <DroppableZone
                moveLabel={onDrop}
                zoneId={z.id}
                borderRadius="full"
                bgColor="primary.white"
                hoverColor="primary.golden-medium"
              >
                {labelInZone && (
                  <Box
                    className="dnd-option-dropped"
                    data-option-id={labelInZone.id}
                    opacity={userIsDraggingItem ? "0.7" : undefined}
                    zIndex={userIsDraggingItem ? 0 : undefined}
                  >
                    <DraggableLabel
                      label={labelInZone}
                      stopDragging={onDrop}
                      key={`label-${labelInZone.id}`}
                      position="absolute"
                      top={`-${pxToRem(currentBreakpoints.responseHeight / 2)}`}
                      transform="translate(0, 0)"
                      dragItemPreviewContent={
                        <Box w={pxToRem(currentBreakpoints.markerSize)}>
                          {LabeledMarker}
                        </Box>
                      }
                    >
                      {LabeledMarker}
                    </DraggableLabel>
                  </Box>
                )}
                <Target
                  diagramTargetSize={diagramTargetSize}
                  userIsDraggingItem={userIsDraggingItem}
                />
              </DroppableZone>
            </Box>
          );
        })}
        <Icon
          position="absolute"
          iconColor="utility.link"
          right="0"
          bottom="0"
          boxSize={pxToRem(iconSize)}
          icon="search"
          onMouseEnter={() => setShowZones(false)}
          onMouseLeave={() => setShowZones(true)}
          decorative
        />
      </Box>
    </Box>
  );
};

const Target = ({
  diagramTargetSize,
  userIsDraggingItem,
}: {
  diagramTargetSize: number;
  userIsDraggingItem: boolean;
}) => (
  <Box
    border="4px solid"
    borderColor="utility.link"
    borderRadius="full"
    backgroundColor="transparent"
    boxSize={pxToRem(diagramTargetSize)}
    position={userIsDraggingItem ? "relative" : undefined}
    zIndex={userIsDraggingItem ? 1 : undefined}
  />
);
