import {
  Box,
  ButtonProps,
  Button as ChakraButton,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import React from "react";

import { ColorScheme } from "adminComponents";

export interface IProps extends ButtonProps {
  to?: string;
  target?: string;
  svgDisabledColor?: ColorScheme;
  variant?:
    | "adminButtonFilled"
    | "adminButtonOutlined"
    | "adminButtonOutlinedMuted"
    | "adminButtonWhite"
    | "adminButtonTan"
    | "adminDropZone"
    | "adminGoogleClassroomPickButton"
    | "adminMathButton"
    | "adminLibraryImage"
    | "adminInlineIconButton"
    | "adminSeasonMapTicketCounter"
    | "adminSignInButton"
    | "adminSortableTableHeaderButtonMobile"
    | "adminStudentAccuracy"
    | "adminTextButtonExtraLarge"
    | "adminTextButtonLarge"
    | "adminTextButtonLargeNoUnderline"
    | "adminTextButtonMedium"
    | "adminTextButtonSmall"
    | "adminDropdownButton";
}

export const Button = React.forwardRef<HTMLButtonElement, IProps>((p, ref) => {
  const {
    children,
    leftIcon,
    rightIcon,
    variant,
    size = "md",
    textStyle,
    sx,
    svgDisabledColor,
    ...rest
  } = p;

  const hasLeftIcon = leftIcon ? true : false;
  const hasRightIcon = rightIcon ? true : false;

  const styles = useMultiStyleConfig("AdminButton", {
    variant,
    hasLeftIcon,
    hasRightIcon,
    size,
    textStyle,
    svgDisabledColor,
  });

  return (
    <ChakraButton {...rest} ref={ref} sx={{ ...styles.outer, ...sx }}>
      {leftIcon && leftIcon}
      {variant === "adminMathButton" ? (
        <>{children}</>
      ) : (
        <>
          {children && (
            <Box as="span" w="full" sx={styles.textContainer}>
              {children}
            </Box>
          )}
        </>
      )}
      {rightIcon && rightIcon}
    </ChakraButton>
  );
});

Button.displayName = "Button";
