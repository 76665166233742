import React, { createContext, useContext, useMemo } from "react";

import {
  ISessionDrawStroke,
  SessionChatMessage,
  SessionGroupNamePart,
  SessionReaction,
} from "../types";

interface ISessionActionsContext {
  avatarUpdated: () => void;
  changeDrawColor: (draw_color: string) => void;
  changeGroupName: (group_name_part: SessionGroupNamePart) => void;
  shuffleGuestName: () => void;
  confirmAnswer: () => void;
  initiateGame: () => void;
  initiateGrouping: () => void;
  initiateLeaderBoard: () => void;
  initiateNextRound: () => void;
  initiatePriorRound: () => void;
  initiateOuterGameIntro: () => void;
  initiateOuterGame: () => void;
  initiatePodium: () => void;
  initiatePrizeRound: () => void;
  initiatePrizeRoundAwards: () => void;
  initiateRoundReview: () => void;
  initiateVoting: () => void;
  initiateVotingResults: () => void;
  initiateVotingAwards: () => void;
  placeTheBigBoardToken: (spaceId: number) => void;
  setChoice: (choice_id: string, is_selected: boolean) => void;
  sendChatMessage: (message: SessionChatMessage) => void;
  sendCursor: (x: number, y: number) => void;
  sendDiagramLabelDrag: (
    label_id: string,
    pos: { x: number; y: number }
  ) => void;
  sendDrawStroke: (stroke: ISessionDrawStroke) => void;
  sendDrawImageUrl: (imageUrl: string) => void;
  sendReaction: (reaction: SessionReaction) => void;
  sendSortChoiceDrag: (
    choice_id: string,
    pos: { x: number; y: number }
  ) => void;
  sendTextPartDrag: (part_id: string, pos: { x: number; y: number }) => void;
  sendTextResponse: (text: string) => void;
  setDiagramLabelZone: (label_id: string, zone_id: string) => void;
  setSortChoiceZone: (choice_id: string, zone_id: string) => void;
  setTextPartZone: (part_id: string, zone_id: string) => void;
  stopTheBigBoardScanning: (rowIndex: number) => void;
  setVote: (group_id?: string) => void;
  setDrawingHidden: (group_id: string, hidden: boolean) => void;
  sendActiveTyping: () => void;
  undoDrawStroke: () => void;
  extendRoundTime: () => void;
  exitGame: () => void;
}

export const SessionActionsContext = createContext<ISessionActionsContext>({
  avatarUpdated: () => {
    // do nothing
  },
  changeDrawColor: () => {
    // do nothing
  },
  changeGroupName: () => {
    // do nothing
  },
  shuffleGuestName: () => {
    // do nothing
  },
  confirmAnswer: () => {
    // do nothing
  },
  initiateGame: () => {
    // do nothing
  },
  initiateGrouping: () => {
    // do nothing
  },
  initiateLeaderBoard: () => {
    // do nothing
  },
  initiateNextRound: () => {
    // do nothing
  },
  initiatePriorRound: () => {
    // do nothing
  },
  initiateOuterGameIntro: () => {
    // do nothing
  },
  initiateOuterGame: () => {
    // do nothing
  },
  initiatePodium: () => {
    // do nothing
  },
  initiatePrizeRound: () => {
    // do nothing
  },
  initiatePrizeRoundAwards: () => {
    // do nothing
  },
  initiateRoundReview: () => {
    // do nothing
  },
  initiateVoting: () => {
    // do nothing
  },
  initiateVotingResults: () => {
    // do nothing
  },
  initiateVotingAwards: () => {
    // do nothing
  },
  placeTheBigBoardToken: () => {
    //do nothing
  },
  setChoice: () => {
    //do nothing
  },
  sendChatMessage: () => {
    //do nothing
  },
  sendCursor: () => {
    //do nothing
  },
  sendDiagramLabelDrag: () => {
    //do nothing
  },
  sendDrawStroke: () => {
    //do nothing
  },
  sendDrawImageUrl: () => {
    //do nothing
  },
  sendReaction: () => {
    //do nothing
  },
  sendSortChoiceDrag: () => {
    //do nothing
  },
  sendTextPartDrag: () => {
    //do nothing
  },
  sendTextResponse: () => {
    //do nothing
  },
  setDiagramLabelZone: () => {
    //do nothing
  },
  setSortChoiceZone: () => {
    //do nothing
  },
  setTextPartZone: () => {
    //do nothing
  },
  stopTheBigBoardScanning: () => {
    //do nothing
  },
  setVote: () => {
    //do nothing
  },
  setDrawingHidden: () => {
    //do nothing
  },
  sendActiveTyping() {
    //do nothing
  },
  undoDrawStroke() {
    //do nothing
  },
  extendRoundTime() {
    //do nothing
  },
  exitGame() {
    //do nothing
  },
});

export interface ISessionActionsProviderProps {
  wsActions: ISessionActionsContext;
}

export const SessionActionsProvider: React.FC<ISessionActionsProviderProps> = ({
  children,
  wsActions,
}) => {
  const value = useMemo(() => wsActions, [wsActions]);
  return (
    <SessionActionsContext.Provider value={value}>
      {children}
    </SessionActionsContext.Provider>
  );
};

export const useSessionActions = (): ISessionActionsContext => {
  return useContext(SessionActionsContext);
};
