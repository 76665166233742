import {
  Box,
  Flex,
  Hide,
  Image,
  Show,
  Spacer,
  Text,
  VStack,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMeasure, usePrevious, useWindowSize } from "react-use";

import { Icon } from "adminComponents/atoms/Icon";
import { TemplateWithVerticalCenteredContent } from "adminComponents/templates/TemplateWithVerticalCenteredContent";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { AvatarAnimations } from "links/lib/constants";
import { useFetchCurrentSeason } from "links/lib/features/season";
import { ISessionUser, IUser, SessionGameType } from "links/lib/types";
import { Button } from "sessionComponents/atoms/Button";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { AvatarLayout } from "sessionComponents/molecules/AvatarLayout";
import SignInProviderModal from "sessionComponents/molecules/SignInProviderModal";
import { HeaderWithLanguagePicker } from "sharedComponents/atoms/HeaderWithLanguagePicker";
import { useLanguageDropdown } from "sharedComponents/hooks/useLanguageDropdown";

import { StudentCard } from "./components/StudentCard";
import { StudentSessionIndicator } from "./components/StudentSessionIndicator";
import {
  minWindowHeightForAvatar,
  minWindowHeightForSeasonFooter,
  minWindowWidthForSeasonFooterImage,
  windowHeightThresholdForAdditionalYOffset,
} from "./constants";
import Season3PNG from "./resource/season-3.png";

export interface IStudentLobbyProps {
  authUser: IUser;
  outerGame: SessionGameType;
  joinCode: string;
  student: ISessionUser;
  sessionCreatorName?: string;
  onOpenEditAvatarModal: () => void;
  onShuffleGuestName: () => void;
  onSignInSuccess: () => void;
  onSignOut: () => void;
}

export const StudentLobby: React.FC<IStudentLobbyProps> = ({
  authUser,
  outerGame,
  joinCode,
  student,
  sessionCreatorName,
  onOpenEditAvatarModal,
  onShuffleGuestName,
  onSignInSuccess,
  onSignOut,
}) => {
  const { match: currentBreakpoints } = useBreakpoints();
  const { height: windowHeight, width: windowWidth } = useWindowSize();
  const avatarScaleReductionFactor = useBreakpointValue({
    base: 0.5,
    sm: 0.6,
    md: 0.7,
  });

  const [avatarContainerRef, { height: avatarContainerHeight }] =
    useMeasure<HTMLDivElement>();

  let avatarPosition = -avatarContainerHeight * 0.4;
  if (windowHeight > windowHeightThresholdForAdditionalYOffset) {
    avatarPosition -= windowHeight - windowHeightThresholdForAdditionalYOffset;
  }
  const {
    isOpen: isSignInModalOpen,
    onOpen: onOpenSignInModal,
    onClose: onCloseSignInModal,
  } = useDisclosure();

  const fetchSeason = useFetchCurrentSeason();

  const daysLeftInSeason = useMemo(() => {
    const seasonEndDate = moment(fetchSeason.data?.season.end_date);
    return seasonEndDate.diff(moment(), "days");
  }, [fetchSeason.data]);

  const { t } = useTranslation("session", {
    keyPrefix: "studentLobbyLight",
    useSuspense: false,
  });

  const [avatarAnimations, setAvatarAnimations] = useState(
    student.is_guest
      ? AvatarAnimations.LobbyStudentIdle
      : AvatarAnimations.LobbyStudentIdleShiny
  );
  const prevStudent = usePrevious(student);
  useEffect(() => {
    if (prevStudent && prevStudent.is_guest && !student.is_guest) {
      setAvatarAnimations(AvatarAnimations.LobbyStudentActivate);
      setTimeout(() => {
        setAvatarAnimations(AvatarAnimations.LobbyStudentIdleShiny);
      }, 1200);
    }
  }, [prevStudent, student.is_guest]);

  const windowHeightSupportsAvatar = windowHeight > minWindowHeightForAvatar;
  const windowHeightSupportsFooter =
    windowHeight > minWindowHeightForSeasonFooter;

  const langDropdown = useLanguageDropdown();

  const showSeasonImage = windowWidth > minWindowWidthForSeasonFooterImage;

  return (
    // TODO: Something about TemplateWithVerticalCenteredContent is adding some
    // horizontal/vertical scrolling; hide it by wrapping in overflow: hidden for now
    <Box w="full" h="full" overflow="hidden">
      <TemplateWithVerticalCenteredContent
        headerContent={
          <HeaderWithLanguagePicker
            showSignOut={!student.is_guest}
            onSignOut={onSignOut}
          >
            <Show above="md">
              <StudentSessionIndicator
                outerGame={outerGame}
                sessionCode={joinCode}
                sessionCreatorName={sessionCreatorName}
              />
            </Show>
          </HeaderWithLanguagePicker>
        }
        bgColor="primary.tan-light"
      >
        <Hide above="md">
          <Show below="md">
            <StudentSessionIndicator
              outerGame={SessionGameType.TheBigBoard}
              sessionCode={joinCode}
              sessionCreatorName={sessionCreatorName}
            />
          </Show>
        </Hide>
        <Flex
          pt={{ base: 0, md: pxToRem(currentBreakpoints.padding) }}
          pb={pxToRem(currentBreakpoints.padding)}
          h="100%"
          w="full"
          justifyContent="space-between"
          gap={pxToRem(currentBreakpoints.margin)}
        >
          {windowHeightSupportsAvatar && (
            <Show above="md">
              <Spacer />
              <Flex
                flexDirection="column"
                gap={pxToRem(currentBreakpoints.margin / 2)}
                justifyContent="center"
              >
                <Box h="full" maxH={pxToRem(700)} ref={avatarContainerRef}>
                  <AvatarLayout
                    students={[student]}
                    candidateAnimations={avatarAnimations}
                    selfId={student.id}
                    showNametags={false}
                    yPosition={avatarPosition}
                    additionalScaleFactor={avatarScaleReductionFactor}
                  />
                </Box>
                <Button
                  ml={pxToRem(-35)} // Avatars aren't really vertically centered, shifting this button looks best
                  py={pxToRem(currentBreakpoints.padding / 1.5)}
                  px={pxToRem(currentBreakpoints.padding)}
                  variant="buttonFilled"
                  leftIcon={
                    <Icon
                      boxSize={pxToRem(currentBreakpoints.iconSize)}
                      icon="edit_outlined"
                    />
                  }
                  onClick={() => onOpenEditAvatarModal()}
                >
                  <Text
                    textStyle="gameText"
                    fontSize={pxToRem(currentBreakpoints.fontSize / 2)}
                  >
                    {t("editMyAvatarButton")}
                  </Text>
                </Button>
              </Flex>
              <Spacer />
            </Show>
          )}
          <StudentCard
            authUser={authUser}
            student={student}
            onOpenEditAvatarModal={onOpenEditAvatarModal}
            onOpenSignInModal={onOpenSignInModal}
            onShuffleGuestName={onShuffleGuestName}
          />
        </Flex>
        {windowHeightSupportsFooter && (
          <Flex
            bgColor="primary.golden-light"
            p={pxToRem(currentBreakpoints.padding)}
            w="full"
            position="relative"
          >
            <VStack alignItems="start" w={showSeasonImage ? "40%" : "full"}>
              {fetchSeason.data && (
                <Text
                  textStyle="gameTextWeighted"
                  fontSize={pxToRem(currentBreakpoints.fontSize)}
                >
                  {t("seasonRemainingText", {
                    count: daysLeftInSeason,
                    seasonName: fetchSeason.data?.season.name,
                  })}
                </Text>
              )}
              <Text textStyle="gameText">
                {t("signInText", { seasonName: fetchSeason.data?.season.name })}
              </Text>
            </VStack>
            <Spacer />
            {showSeasonImage && (
              <Image
                zIndex={0}
                position="absolute"
                right="0"
                bottom="0"
                src={Season3PNG}
                w={["40%", "50%"]}
              />
            )}
          </Flex>
        )}
        <Show below="lg">
          <Box my={pxToRem(30)}>{langDropdown}</Box>
        </Show>
        <SignInProviderModal
          joinCode={joinCode}
          isOpen={isSignInModalOpen}
          onClose={onCloseSignInModal}
          onSuccess={onSignInSuccess}
        />
      </TemplateWithVerticalCenteredContent>
    </Box>
  );
};
