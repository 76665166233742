import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { usePrevious } from "react-use";

import {
  FilterPracticeSetFlyout,
  IFilterPracticeSetState,
} from "adminComponents/organisms/FilterPracticeSetFlyout";
import { SelectStandardsModal } from "adminComponents/organisms/SelectStandardsModal";
import { useErrorToast } from "adminComponents/utils/toast";
import { regionMap } from "links/lib/constants";
import { useSearchStandardDataOnce } from "links/lib/features/search";
import {
  IGradeLevel,
  IStandard,
  ISubject,
  ISubjectCount,
} from "links/lib/types";

export interface ISearchQuery {
  term?: string;
  filters: IFilterPracticeSetState;
}

interface IStandardsModalState {
  regionFilter: string;
  gradeLevelIdFilter: string;
  subjectIdFilter: string;
  term: string;
  standardCollectionIdFilter: string;
}

export interface IArgs {
  initialQuery?: ISearchQuery;
  gradeLevels: Array<IGradeLevel>;
  subjects: Array<ISubject>;
  country: string;
  region?: string;
  isLoading: boolean;
  subjectCounts: Array<ISubjectCount>;
  showPremiumFilter?: boolean;
  showSubjectsCounts?: boolean;
  disabled?: boolean;
}

export interface IHandleSetQueryArgs {
  term: string;
  subjectIds: string[];
  gradeLevelIds: string[];
}

export interface IResult {
  query: ISearchQuery;
  handleUpdateQuery: (args: IHandleSetQueryArgs) => void;
  handleSetTerm: (term: string) => void;
  handleClearFilters: () => void;
  handleRemoveFilterTag: (
    category: "subject" | "grade" | "standard",
    id: string
  ) => void;
  handleOpenFilterFlyout: () => void;
  handleClearSearchTerm: () => void;
  handleChangeSubjects: (subjectIds: Array<string>) => void;
  handleChangeGradeLevels: (gradeLevelIds: Array<string>) => void;
  filterFlyout: React.ReactElement;
  standards: Array<IStandard>;
  standardsModal: React.ReactElement;
  handleOpenStandardsModal: (isDirectSearch?: boolean) => void;
}

export const useLibraryFilter = (args: IArgs): IResult => {
  const {
    gradeLevels,
    subjects,
    country,
    region,
    isLoading,
    subjectCounts,
    initialQuery,
    disabled,
    showPremiumFilter = true,
    showSubjectsCounts = true,
  } = args;

  const prevInitialQuery = usePrevious(initialQuery);
  useEffect(() => {
    if (prevInitialQuery !== initialQuery && initialQuery?.filters) {
      setFilterFlyoutState(initialQuery?.filters);
      setQuery({ term: initialQuery?.term, filters: initialQuery?.filters });
    }
  }, [initialQuery, prevInitialQuery]);

  const getDefaultPracticeSetFilters = () => {
    return {
      standardIds: [],
      subjectIds: [],
      numQuestions: [],
      languages: [],
      gradeLevelIds: [],
      certifiedOnly: false,
      hidePremium: false,
    };
  };

  const [filterFlyoutState, setFilterFlyoutState] =
    useState<IFilterPracticeSetState>(
      initialQuery?.filters ?? getDefaultPracticeSetFilters()
    );
  const [query, setQuery] = useState<ISearchQuery>({
    term: initialQuery?.term,
    filters: filterFlyoutState,
  });
  const [isFilterFlyoutOpen, setIsFilterFlyoutOpen] = useState(false);
  const [isStandardsModalOpen, setIsStandardsModalOpen] = useState(false);
  const [standardsModalState, setStandardsModalState] =
    useState<IStandardsModalState>({
      regionFilter: region || "",
      subjectIdFilter: "",
      gradeLevelIdFilter: "",
      term: "",
      standardCollectionIdFilter: "",
    });
  const [selectedStandards, setSelectedStandards] = useState<Array<IStandard>>(
    []
  );
  const [isStandardsDirectSearch, setIsStandardsDirectSearch] = useState(false);
  const { t } = useTranslation("admin", { useSuspense: false });

  const searchStandards = useSearchStandardDataOnce({});

  useErrorToast(searchStandards.error);

  // This is needed in order to set term and filters at the same time without
  // spawning competing search requests.  This is meant to be expanded with more parameter properties as needed
  const handleUpdateQuery = ({
    term,
    subjectIds,
    gradeLevelIds,
  }: IHandleSetQueryArgs) => {
    setFilterFlyoutState((val) => ({
      ...val,
      subjectIds,
      gradeLevelIds,
    }));

    setQuery((val) => ({
      ...val,
      term,
      filters: {
        ...val.filters,
        subjectIds,
        gradeLevelIds,
      },
    }));
  };

  const handleSetTerm = (term: string) => {
    setQuery((current) => ({
      ...current,
      term,
    }));
  };

  const handleClearFilters = () => {
    setQuery((val) => ({
      ...val,
      filters: getDefaultPracticeSetFilters(),
    }));
    setFilterFlyoutState(getDefaultPracticeSetFilters());
  };

  const handleChangeSubjects = (subjectIds: Array<string>) => {
    setFilterFlyoutState((val) => ({
      ...val,
      subjectIds,
    }));

    setQuery((val) => ({
      ...val,
      filters: {
        ...val.filters,
        subjectIds,
      },
    }));
  };

  const handleChangeGradeLevels = (gradeLevelIds: Array<string>) => {
    setFilterFlyoutState((val) => ({
      ...val,
      gradeLevelIds,
    }));

    setQuery((val) => ({
      ...val,
      filters: {
        ...val.filters,
        gradeLevelIds,
      },
    }));
  };

  const handleOpenFilterFlyout = useCallback(() => {
    setIsFilterFlyoutOpen(true);
    setQuery(query);
  }, [query]);

  const handleCloseFilterFlyout = () => {
    setIsFilterFlyoutOpen(false);
  };

  const handleRemoveFilterTag = (
    category: "subject" | "grade" | "standard",
    id: string
  ) => {
    setQuery((value) => {
      const q = { ...value };

      if (!q.filters) return q;

      const filters = { ...q.filters };

      if (category === "subject") {
        const childSubjectIds = subjects
          .filter((subject) => subject.parent_id === id)
          .map((subject) => subject.id);
        filters.subjectIds = filters.subjectIds.filter(
          (subjectId) =>
            subjectId !== id && childSubjectIds.indexOf(subjectId) < 0
        );
      } else if (category === "grade") {
        filters.gradeLevelIds = filters.gradeLevelIds.filter(
          (gradeLevelId) => gradeLevelId !== id
        );
      } else if (category === "standard") {
        filters.standardIds = filters.standardIds.filter(
          (standardId) => standardId !== id
        );
      }

      q.filters = filters;

      setFilterFlyoutState(filters);

      return q;
    });
  };

  const previousStandardsState = usePrevious(standardsModalState);
  useEffect(() => {
    if (disabled) return;
    if (previousStandardsState === standardsModalState) return;
    searchStandards.execute({
      term: standardsModalState.term,
      grade_level_ids: standardsModalState.gradeLevelIdFilter
        ? [standardsModalState.gradeLevelIdFilter]
        : undefined,
      subject_ids: standardsModalState.subjectIdFilter
        ? [standardsModalState.subjectIdFilter]
        : undefined,
      region: standardsModalState.regionFilter,
      standard_collection_id: standardsModalState.standardCollectionIdFilter,
    });
  }, [disabled, previousStandardsState, standardsModalState, searchStandards]);

  const handleResetStandards = () => {
    setSelectedStandards([]);
    setFilterFlyoutState((value) => {
      return {
        ...value,
        standardIds: [],
      };
    });
  };

  const handleChangeFilters = (value: IFilterPracticeSetState) => {
    setFilterFlyoutState(value);
  };

  const handleRemoveStandard = (standardId: string) => {
    setFilterFlyoutState((value) => {
      return {
        ...value,
        standardIds: value.standardIds.filter((s) => s !== standardId),
      };
    });
  };

  const handleOpenStandardsModal = useCallback(
    (isDirectSearch?: boolean) => {
      setIsStandardsModalOpen(true);
      setIsStandardsDirectSearch(!!isDirectSearch);

      const standards = searchStandards.data?.standards || [];
      setSelectedStandards(
        standards.filter((standard) =>
          filterFlyoutState.standardIds.some((s) => s === standard.id)
        )
      );
    },
    [filterFlyoutState.standardIds, searchStandards.data?.standards]
  );

  const handleApplyFilters = useCallback(() => {
    setQuery({
      ...query,
      filters: filterFlyoutState,
    });

    handleCloseFilterFlyout();
  }, [filterFlyoutState, query]);

  const handleClearSearchTerm = () => {
    setQuery((val) => ({
      ...val,
      term: "",
    }));
  };

  const handleCloseStandardsModal = () => {
    setIsStandardsModalOpen(false);
  };

  const handleChangeStandardsFilter = (
    state: string,
    subject: string,
    grade: string,
    standardCollection?: string
  ) => {
    setStandardsModalState((val) => ({
      ...val,
      subjectIdFilter: subject,
      gradeLevelIdFilter: grade,
      regionFilter: state,
      standardCollectionIdFilter: standardCollection || "",
    }));
  };

  const handleStandardsSearch = (search: string) => {
    setStandardsModalState((val) => ({ ...val, term: search }));
  };

  const handleSaveAndCloseStandardsModal = useCallback(
    (standards: Array<IStandard>) => {
      const standardIds = standards.map((s) => s.id);

      setFilterFlyoutState((val) => {
        return {
          ...val,
          standardIds,
        };
      });

      setIsStandardsModalOpen(false);

      // If standards modal was opened directly, then update query immediately
      if (isStandardsDirectSearch) {
        setQuery((val) => ({
          ...val,
          filters: {
            ...val.filters,
            standardIds,
          },
        }));
      }
    },
    [isStandardsDirectSearch]
  );

  const getStandardsModal = () => {
    const {
      regionFilter,
      subjectIdFilter,
      gradeLevelIdFilter,
      standardCollectionIdFilter,
    } = standardsModalState;

    const anySubjectLabel = t("common.searchFilterOptionAnySubject");
    const anyGradeLabel = t("common.searchFilterOptionAnyGrade");
    const anyStateLabel = t("common.searchFilterOptionAnyState");

    const stateOptions = [{ value: "", label: anyStateLabel }].concat(
      Object.keys(regionMap[country]).map((code) => ({
        value: code,
        label: regionMap[country][code],
      }))
    );

    const subjectOptions = [{ value: "", label: anySubjectLabel }].concat(
      subjects
        .filter((s) => s.parent_id === "0")
        .map((s) => ({ label: s.name, value: s.id }))
    );

    const gradeLevelOptions = [{ value: "", label: anyGradeLabel }].concat(
      gradeLevels.map((g) => ({ label: g.grade_level, value: g.id }))
    );

    const standards = searchStandards.data?.standards || [];

    return (
      <SelectStandardsModal
        isLoading={false}
        isSearching={searchStandards.isLoading}
        standards={standards}
        states={stateOptions}
        subjects={subjectOptions}
        grades={gradeLevelOptions}
        subjectIdFilter={subjectIdFilter}
        gradeLevelIdFilter={gradeLevelIdFilter}
        standardCollectionIdFilter={standardCollectionIdFilter}
        regionFilter={regionFilter}
        isOpen={isStandardsModalOpen}
        handleClose={handleCloseStandardsModal}
        handleChangeFilter={handleChangeStandardsFilter}
        handleSearch={handleStandardsSearch}
        handleSaveAndClose={handleSaveAndCloseStandardsModal}
        selectedStandards={selectedStandards}
      />
    );
  };

  const filterFlyout = useMemo(
    () => (
      <FilterPracticeSetFlyout
        numQuestionOptions={[]}
        languageOptions={[]}
        subjectCounts={subjectCounts}
        subjectOptions={subjects}
        gradeOptions={gradeLevels}
        isOpen={isFilterFlyoutOpen}
        isLoading={isLoading}
        handleClose={handleCloseFilterFlyout}
        handleResetStandards={handleResetStandards}
        handleChangeValue={handleChangeFilters}
        handleApplyFilters={handleApplyFilters}
        handleSelectStandards={() => handleOpenStandardsModal(false)}
        handleRemoveStandard={handleRemoveStandard}
        value={filterFlyoutState}
        showPremiumFilter={showPremiumFilter}
        showSubjectsCounts={showSubjectsCounts}
      />
    ),
    [
      filterFlyoutState,
      gradeLevels,
      handleApplyFilters,
      handleOpenStandardsModal,
      isFilterFlyoutOpen,
      isLoading,
      showPremiumFilter,
      showSubjectsCounts,
      subjectCounts,
      subjects,
    ]
  );

  return {
    handleUpdateQuery,
    handleSetTerm,
    handleClearFilters,
    handleRemoveFilterTag,
    handleOpenFilterFlyout,
    query,
    filterFlyout,
    handleClearSearchTerm,
    standards: searchStandards.data?.standards || [],
    standardsModal: getStandardsModal(),
    handleChangeSubjects,
    handleChangeGradeLevels,
    handleOpenStandardsModal,
  };
};
