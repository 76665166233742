import { Box, Text } from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { useSessionActions } from "links/lib/contexts/sessionActions";
import { useSessionGameState } from "links/lib/contexts/sessionGameState";
import { useSessionGroups } from "links/lib/contexts/sessionGroups";
import { SessionGameType } from "links/lib/types";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { useStudent } from "sessionComponents/contexts/student";
import { AvatarCarousel } from "sessionComponents/molecules/AvatarCarousel";
import { ConfirmButton } from "sessionComponents/molecules/ConfirmButton";
import { LobbyLayout } from "sessionComponents/molecules/LobbyLayout";
import { GroupScores } from "sessionComponents/organisms/GroupScores";
import { QuickPlayGroupScores } from "sessionComponents/organisms/QuickPlayGroupScores";
import { useDetectOrientation } from "sessionComponents/theme/hooks/useDetectOrientation";
import { useSafeConnectedUserArray } from "sharedComponents/hooks/useSafeConnectedUserArray";
import { useSafeGroupsArray } from "sharedComponents/hooks/useSafeGroupsArray";

export interface ILeaderBoardProps {
  isEndOfGame?: boolean;
  isTeacher: boolean;
}

export const LeaderBoard: React.FC<ILeaderBoardProps> = ({
  isEndOfGame,
  isTeacher,
}) => {
  const { t } = useTranslation("session", {
    keyPrefix: "leaderBoardScreen",
    useSuspense: false,
  });

  const { match: currentBreakpoints } = useBreakpoints();
  const { isPortrait } = useDetectOrientation();
  const connectedUsers = useSafeConnectedUserArray();
  const gameState = useSessionGameState();
  const student = useStudent();
  const groupMap = useSessionGroups();
  const groups = useSafeGroupsArray();
  const { initiateNextRound } = useSessionActions();

  const [disableMoveOn, setDisableMoveOn] = useState(true);

  const sortedGroups = useMemo(() => {
    return groups.sort(
      (a, b) => b.total_awarded_points - a.total_awarded_points
    );
  }, [groups]);
  const winningGroupScore =
    (sortedGroups.length && sortedGroups[0].total_awarded_points) || 0;

  const groupedStudents = useMemo(() => {
    const winningGroupIds = Object.values(groupMap)
      .filter((g) => g?.total_awarded_points === winningGroupScore)
      .flatMap((g) => (g ? [g.id] : []));

    return winningGroupIds.map((groupId) => {
      return connectedUsers.filter((u) => u.session_group_id === groupId);
    });
  }, [groupMap, connectedUsers, winningGroupScore]);

  const disableCycle = groupedStudents.length === 1;

  const TeacherConfirmButton = (
    <ConfirmButton
      w="full"
      textStyle="gameText"
      color="black"
      bgColor="primary.golden"
      _hover={{
        bgColor: "primary.golden-hover",
      }}
      borderRadius={currentBreakpoints.borderRadius}
      p={pxToRem(currentBreakpoints.padding)}
      fontSize={pxToRem(currentBreakpoints.fontSize)}
      confirmedWhenChanged={[]}
      handleConfirmClick={() => initiateNextRound()}
      disabled={disableMoveOn}
    >
      {isEndOfGame
        ? t("showFinalResultsButtonText")
        : t("nextQuestionButtonText")}
    </ConfirmButton>
  );

  return (
    <LobbyLayout
      avatarComponent={
        isPortrait ? undefined : (
          <AvatarCarousel
            students={[]}
            groupedStudents={groupedStudents}
            useGroupedStudents={true}
            selfId={student.id}
            disableCycle={disableCycle}
          />
        )
      }
      actionContent={
        <Box
          display="flex"
          flexDirection="column"
          w="full"
          h="full"
          justifyContent="space-between"
        >
          {gameState?.game_type === SessionGameType.QuickPlay && (
            <QuickPlayGroupScores
              onAnimationEnd={() => setDisableMoveOn(false)}
            />
          )}
          {gameState?.game_type !== SessionGameType.QuickPlay && (
            <GroupScores onAnimationEnd={() => setDisableMoveOn(false)} />
          )}
          <Box
            w="full"
            h={pxToRem(currentBreakpoints.headerHeight)}
            bgColor="primary.warm-white"
            p={pxToRem(currentBreakpoints.padding)}
            borderTopWidth={pxToRem(currentBreakpoints.borderWidth)}
            borderColor="primary.tan"
            textAlign="center"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {isTeacher ? (
              TeacherConfirmButton
            ) : (
              <Text
                textStyle="gameText"
                fontSize={pxToRem(currentBreakpoints.fontSize)}
              >
                {t("waitingForTeacherText")}
              </Text>
            )}
          </Box>
        </Box>
      }
      stageProps={{
        showCurtainOnly: isPortrait,
        outerGame: gameState?.game_type,
      }}
    />
  );
};
