import React, { createContext, useContext, useMemo } from "react";

import { useSessionActions } from "links/lib/contexts/sessionActions";
import { SessionReaction } from "links/lib/types";

import { StudentReactionsDisplay } from "../atoms/StudentReactionsDisplay";

interface IStudentReactionsContext {
  sendReaction: (reaction: SessionReaction) => void;
}

const StudentReactionsContext = createContext<IStudentReactionsContext>({
  sendReaction: () => {
    throw new Error("Not Implemented");
  },
});

export const StudentReactionsProvider: React.FC = ({ children }) => {
  const { sendReaction } = useSessionActions();

  const value = useMemo(
    () => ({
      sendReaction: (reaction: SessionReaction) => sendReaction(reaction),
    }),
    [sendReaction]
  );

  return (
    <StudentReactionsContext.Provider value={value}>
      {children}
      <StudentReactionsDisplay />
    </StudentReactionsContext.Provider>
  );
};

export const useStudentReactions = (): IStudentReactionsContext => {
  return useContext(StudentReactionsContext);
};
