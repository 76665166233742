import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import type { IGradeLevel } from "../../types";

interface IFetchGradeLevelsServerResponse {
  grade_levels: Array<IGradeLevel>;
}

export interface IFetchGradeLevelsResponse {
  gradeLevels: Array<IGradeLevel>;
}

export interface IFetchGradeLevelsOpts {
  limit: number;
  country: string;
  region?: string;
}

/**
 * Fetches a list of grade levels
 * @returns
 */
export default function useFetchGradeLevels(
  opts: IFetchGradeLevelsOpts
): UseQueryResult<IFetchGradeLevelsResponse, unknown> {
  const { limit, country, region } = opts;

  const axios = useAxios();

  const query = useQuery<IFetchGradeLevelsResponse>(
    ["gradeLevels", { limit, country, region }],
    async (): Promise<IFetchGradeLevelsResponse> => {
      return await axios
        .get(
          `/v1/grade-levels?limit=${limit}&country=${country}&region=${
            region || ""
          }`
        )
        .then((r) => {
          return {
            gradeLevels: (
              r.data as IFetchGradeLevelsServerResponse
            ).grade_levels.filter((gradeLevel) => {
              // The C&C team have requested these grade levels
              // be hidden
              return (
                gradeLevel.grade_level !== "Preschool" &&
                gradeLevel.grade_level !== "13th grade"
              );
            }),
          };
        });
    }
  );

  return query;
}
