import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";

import { Button } from "adminComponents/atoms/Button";
import { Modal } from "adminComponents/atoms/Modal";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils";
import { useGetPremiumLink } from "sharedComponents/hooks/useGetPremiumLink";

import GenerateFeedbackPreviewMP4 from "../resource/GenerateFeedbackPreview.mp4";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

export const GenerateFeedbackPreviewModal: React.FC<IProps> = ({
  isOpen,
  onClose,
}) => {
  const { t: tCommon } = useTranslation("admin", {
    keyPrefix: "common",
    useSuspense: false,
  });
  const { t } = useTranslation("admin", {
    keyPrefix: "premiumText",
    useSuspense: false,
  });
  const premiumLink = useGetPremiumLink();
  const handlePremiumClick = () => {
    window.location.href = premiumLink;
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md" showBackButton={false}>
      <Flex
        px={pxToRem(10)}
        pt={pxToRem(80)}
        flexDir="column"
        w="full"
        alignItems="center"
        justifyContent="center"
        gap={pxToRem(6)}
      >
        <ReactPlayer
          url={GenerateFeedbackPreviewMP4}
          controls={false}
          loop
          playing
          width="100%"
          height="100%"
        />
        <Box my={pxToRem(16)}>
          <Text variant="adminP1">{t("generateFeedbackModalText")}</Text>
          <Text
            alignSelf="start"
            cursor="pointer"
            variant="adminLinkLarge"
            onClick={handlePremiumClick}
          >
            {t("upgradeToPremiumLink")}
          </Text>
        </Box>
        <Button
          onClick={onClose}
          variant="adminButtonFilled"
          w="full"
          mt={pxToRem(8)}
        >
          {tCommon("close")}
        </Button>
      </Flex>
    </Modal>
  );
};
