import { Box } from "@chakra-ui/react";
import React from "react";

import { AssignmentCard } from "adminComponents/molecules/AssignmentCard";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { IAssignment } from "links/lib/types";

interface AssignmentCardProps {
  assignment: IAssignment;
  accuracy?: number;
  totalStudentCount?: number;
  completedStudentCount?: number;
}

interface IProps {
  assignmentData?: Array<AssignmentCardProps>;
  isUpcoming?: boolean;
  handleEdit?: (assignment: IAssignment) => void;
  handleDelete?: (assignment: IAssignment) => void;
}

export const ClassDetailAssignments: React.FC<IProps> = ({
  assignmentData,
  isUpcoming,
  handleEdit,
  handleDelete,
}) => {
  return (
    <>
      {assignmentData?.map((assignmentCardData) => {
        const {
          assignment,
          accuracy,
          totalStudentCount,
          completedStudentCount,
        } = assignmentCardData;

        const handleEditAssignmentCardClick = handleEdit
          ? () => handleEdit(assignment)
          : undefined;

        const handleDeleteAssignmentCardClick = handleDelete
          ? () => handleDelete(assignment)
          : undefined;

        return (
          <Box key={assignment.id} mb={pxToRem(12)}>
            <AssignmentCard
              assignment={assignment}
              accuracy={accuracy}
              accuracyType="class"
              completedStudentCount={completedStudentCount}
              totalStudentCount={totalStudentCount}
              isUpcoming={isUpcoming}
              handleEdit={handleEditAssignmentCardClick}
              handleDelete={handleDeleteAssignmentCardClick}
            />
          </Box>
        );
      })}
    </>
  );
};
