import {
  Box,
  ButtonGroup,
  Flex,
  FormControl,
  FormLabel,
  Image,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Swiper from "swiper";

import { Button } from "adminComponents/atoms/Button";
import { Modal } from "adminComponents/atoms/Modal";
import { Carousel } from "adminComponents/molecules/Carousel";
import { pxToRem } from "adminComponents/utils";
import { hairColorHexCodes, skinToneHexCodes } from "links/lib/constants";
import { useAuth } from "links/lib/features/auth";
import { useMutateUserAvatarSettings } from "links/lib/features/avatars";
import { useStudentDashboardData } from "screens/StudentDashboard/contexts/StudentDashboardDataProvider";
import { QuickColorPicker } from "sharedComponents/molecules/QuickColorPicker";

import Step2 from "./assets/Step2.png";

export const StudentOnboardingModal: React.FC = () => {
  const { authUser } = useAuth();
  const { avatarResponse } = useStudentDashboardData();
  const mutateUserAvatarSettings = useMutateUserAvatarSettings();
  const [isOpen, setIsOpen] = useState(true);
  const [swiper, setSwiper] = useState<Swiper | undefined>();
  const [currentSlideIndex, setCurentSlideIndex] = useState(0);
  const [hairColor, setHairColor] = useState("");
  const [skinTone, setSkinTone] = useState("");
  const { t: tCommon } = useTranslation("admin", {
    keyPrefix: "common",
    useSuspense: false,
  });
  const { t } = useTranslation("admin", {
    keyPrefix: "studentOnboardingModal",
    useSuspense: false,
  });
  const videoHeight = useBreakpointValue({
    base: pxToRem(200),
    md: pxToRem(280),
  });

  const isMissingOnboardingValues =
    avatarResponse &&
    (avatarResponse.avatar?.hair_color_hex_code === "" ||
      avatarResponse.avatar?.skin_tone_hex_code === "");
  const setOnboardingValuesInModal = !!hairColor || !!skinTone;
  if (!isMissingOnboardingValues && !setOnboardingValuesInModal) {
    return null;
  }

  const onClickPrevious = () => {
    if (currentSlideIndex <= 0) return;
    const newIndex = currentSlideIndex - 1;
    setCurentSlideIndex(newIndex);
    swiper?.slideTo(newIndex, 100, false);
  };

  const onClickNext = () => {
    if (currentSlideIndex + 1 >= slides.length) return;
    const newIndex = currentSlideIndex + 1;
    setCurentSlideIndex(newIndex);
    swiper?.slideTo(newIndex, 100, false);
  };

  const onSkinToneSelect = (skinTone: string) => {
    setSkinTone(skinTone);

    authUser &&
      mutateUserAvatarSettings.mutate({
        user_id: authUser?.id,
        skin_tone_hex_code: skinTone,
      });
  };

  const onHairColorSelect = (hairColor: string) => {
    setHairColor(hairColor);

    authUser &&
      mutateUserAvatarSettings.mutate({
        user_id: authUser?.id,
        hair_color_hex_code: hairColor,
      });
  };

  const onSwiperSlideChange = (swiper: Swiper) => {
    setSwiper(swiper);
    setCurentSlideIndex(swiper.realIndex);
  };

  const onSwiper = (swiper: Swiper) => {
    setSwiper(swiper);
  };

  const onCloseModel = () => {
    if (isMissingOnboardingValues) return;
    setIsOpen(false);
  };

  const isLoading = mutateUserAvatarSettings.isLoading;

  const slides = [
    {
      title: t("step1.title"),
      description: t("step1.description"),
      video_url: "https://fast.wistia.net/embed/iframe/zepfl8kwwu",
      image_url: null,
      show_color_selection: false,
      buttons: [
        <Button
          key="0"
          isLoading={isLoading}
          onClick={onClickNext}
          variant="adminButtonFilled"
          size="lg"
        >
          {tCommon("next")}
        </Button>,
      ],
    },
    {
      title: t("step2.title"),
      description: t("step2.description"),
      video_url: null,
      image_url: Step2,
      show_color_selection: false,
      buttons: [
        <Button
          key="0"
          isLoading={isLoading}
          onClick={onClickNext}
          variant="adminButtonFilled"
          size="lg"
        >
          {tCommon("next")}
        </Button>,
      ],
    },
    {
      title: t("step3.title"),
      description: t("step3.description"),
      video_url: null,
      image_url: null,
      show_color_selection: true,
      buttons: [
        <Button
          key="0"
          isLoading={isLoading}
          isDisabled={isMissingOnboardingValues}
          variant="adminButtonFilled"
          onClick={onCloseModel}
          size="lg"
          as={Link}
          to={isMissingOnboardingValues ? "#" : "/s/locker"}
        >
          {t("buttonMyLocker")}
        </Button>,
        <Button
          key="1"
          isLoading={isLoading}
          isDisabled={isMissingOnboardingValues}
          variant="adminButtonOutlined"
          onClick={onCloseModel}
          size="lg"
          as={Link}
          to={isMissingOnboardingValues ? "#" : "/s/home"}
        >
          {tCommon("home")}
        </Button>,
      ],
    },
  ];

  const currentSlide = slides[currentSlideIndex];

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        // Don't permit closing the modal
      }}
      onBack={onClickPrevious}
      showBackButton={false}
      showBackButtonAllSizes={currentSlideIndex > 0}
      hideClose={true}
      title={currentSlide.title}
      variant="adminModalCompactMobile"
      headingProps={{
        as: "h2",
        fontSize: [pxToRem(18), null, pxToRem(24)],
        mt: pxToRem(12),
        variant: "adminH3",
      }}
    >
      <VStack spacing={pxToRem(24)}>
        <Carousel
          showDots={true}
          onSlideChange={onSwiperSlideChange}
          onSwiper={onSwiper}
        >
          {slides.map((slide, i) => {
            return (
              <Flex
                key={`modal_step_${i}`}
                flexDirection={"column"}
                mt="-1.5rem"
              >
                <VStack spacing={pxToRem(16)}>
                  <Box>{slide.description}</Box>
                  {i === currentSlideIndex && (
                    <>
                      {!!slide.video_url && (
                        <Box
                          as="iframe"
                          src={slide.video_url}
                          frameBorder="0"
                          scrolling="no"
                          allowFullScreen
                          width="100%"
                          height={videoHeight}
                        />
                      )}
                      {!!slide.image_url && (
                        <Image src={slide.image_url} w="full"></Image>
                      )}
                      {slide.show_color_selection && (
                        <>
                          <FormControl variant="adminFormControl">
                            <FormLabel>{t("labelSkinTone")}</FormLabel>
                            <QuickColorPicker
                              colorOptions={skinToneHexCodes}
                              onColorSelect={onSkinToneSelect}
                              selectedColor={skinTone}
                            />
                          </FormControl>
                          <FormControl variant="adminFormControl">
                            <FormLabel>{t("labelHairColor")}</FormLabel>
                            <QuickColorPicker
                              colorOptions={hairColorHexCodes}
                              onColorSelect={onHairColorSelect}
                              selectedColor={hairColor}
                            />
                          </FormControl>
                        </>
                      )}
                    </>
                  )}
                </VStack>
              </Flex>
            );
          })}
        </Carousel>
        <ButtonGroup>{currentSlide.buttons}</ButtonGroup>
      </VStack>
    </Modal>
  );
};
