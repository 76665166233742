import {
  Flex,
  Spinner,
  useBreakpointValue,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Icon } from "adminComponents/atoms/Icon";
import { IconTooltip } from "adminComponents/atoms/IconTooltip";
import { Modal } from "adminComponents/atoms/Modal";
import { Text } from "adminComponents/atoms/Text";
import { useShowToast } from "adminComponents/utils/toast";

interface AddStudentsViaLinkModal {
  isOpen: boolean;
  link?: string;
  isLoading?: boolean;
  handleClose: () => void;
  handleNext: () => void;
}

const copyToClipboard = (text: string): Promise<void> => {
  return navigator.clipboard.writeText(text);
};

export const AddStudentsViaLinkModal: React.FC<AddStudentsViaLinkModal> = ({
  isOpen,
  link,
  handleClose,
  handleNext,
  isLoading,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const styles = useMultiStyleConfig("AdminAddStudentsViaLinkModal", {
    isLoading,
  });
  const showToast = useShowToast();

  const isMobile = useBreakpointValue({ base: true, md: false });

  const linkIcon = <Icon height="1rem" icon="link" width="1rem" />;

  const handleClick = () => {
    if (!link) return;

    copyToClipboard(link).then(
      () => showToast(t("addStudentsViaLinkModal.linkCopied")),
      () => showToast(t("common.error"))
    );
  };

  return (
    <Modal
      variant="adminModalCompactMobile"
      title={t("addStudentsViaLinkModal.title")}
      isOpen={isOpen}
      onClose={handleClose}
      showBackButton={false}
      headingProps={{ as: "h2", variant: "adminH6" }}
    >
      <Text size="md" variant="adminP1">
        {t("addStudentsViaLinkModal.description")}
      </Text>
      <Text sx={styles.labelText}>{t("addStudentsViaLinkModal.label")}</Text>
      <Flex sx={styles.linkContainer}>
        {isLoading && <Spinner />}
        {!isLoading && (
          <>
            <Text size="md" variant="adminP1" sx={styles.link}>
              {link}
            </Text>
            <Button
              sx={styles.linkButton}
              leftIcon={linkIcon}
              size="sm"
              variant="adminButtonOutlined"
              onClick={handleClick}
              disabled={!link}
            >
              {isMobile
                ? t("common.copyLink")
                : t("addStudentsViaLinkModal.copyLink")}
            </Button>
          </>
        )}
      </Flex>
      <Flex sx={styles.warning}>
        <Text size="md" variant="adminP1">
          {t("addStudentsViaLinkModal.warning")}
        </Text>
        <IconTooltip>{t("addStudentsViaLinkModal.tooltip")}</IconTooltip>
      </Flex>
      <Flex sx={styles.warning}>
        <Button
          sx={styles.button}
          variant="adminButtonFilled"
          onClick={handleNext}
        >
          {isMobile ? t("common.next") : t("addStudentsViaLinkModal.next")}
        </Button>
        <Button
          sx={styles.button}
          variant="adminButtonOutlined"
          onClick={handleClose}
        >
          {t("common.close")}
        </Button>
      </Flex>
    </Modal>
  );
};
