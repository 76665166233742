import { Box, Flex, useBreakpointValue } from "@chakra-ui/react";
import React from "react";

import { IProps as Icon, IconButton } from "adminComponents/atoms/IconButton";
import { Carousel } from "adminComponents/molecules/Carousel";
import { pxToRem } from "adminComponents/utils";

export interface IconCarouselProps {
  id?: string;
  icons: Array<Icon>;
  handleIconClick(icon: Icon): void;
}

export const IconCarousel: React.FC<IconCarouselProps> = ({
  id,
  icons,
  handleIconClick,
}) => {
  const isMobile = useBreakpointValue({ base: true, lg: false });

  const renderIcons = icons.map((icon) => (
    <IconButton
      // the active icon needs to override the variant for visual purposes
      // so we set it before the spread so the spread can override it
      // without the key and click being overwritten manually after
      variant="default"
      {...icon}
      key={`icon-carousel-icon-${icon.ariaLabel}`}
      onClick={() => handleIconClick?.(icon)}
    />
  ));

  return isMobile ? (
    <Box mt={pxToRem(16)} overflow="hidden" position="relative" width="100%">
      <Flex
        gap={pxToRem(16)}
        overflow="auto"
        mb={pxToRem(-16)}
        pb={pxToRem(16)}
        px={pxToRem(16)}
        width="100%"
      >
        {renderIcons}
      </Flex>
      <Box
        bgGradient={`linear(to right, primary.warm-white, rgba(253, 250, 243, 0) 100%)`}
        sx={{
          display: "block",
          width: pxToRem(16),
          height: "100%",
          zIndex: 10,
          position: "absolute",
          left: 0,
          top: 0,
        }}
      />
      <Box
        bgGradient={`linear(to left, primary.warm-white, rgba(253, 250, 243, 0) 100%)`}
        sx={{
          display: "block",
          width: pxToRem(16),
          height: "100%",
          zIndex: 10,
          position: "absolute",
          right: 0,
          top: 0,
        }}
      />
    </Box>
  ) : (
    <Carousel
      id={id}
      variant="adminCarousel"
      slidesPerView={6.5}
      navigationShadow
      bgColor="primary.warm-white"
      showArrows
    >
      {renderIcons}
    </Carousel>
  );
};
