import { Box, Container, useMultiStyleConfig } from "@chakra-ui/react";
import React, { ReactNode } from "react";

import desktopImage from "adminComponents/assets/background-images/illustrated-background-desktop.svg";
import mobileImage from "adminComponents/assets/background-images/illustrated-background-mobile.svg";
import { LoadingSpinner } from "adminComponents/atoms/LoadingSpinner";

export interface IProps {
  headerContent: ReactNode;
  children: ReactNode;
  isLoading?: boolean;
}

export const TemplateWithCenteredContent: React.FC<IProps> = ({
  headerContent,
  children,
  isLoading = false,
}) => {
  const styles = useMultiStyleConfig("AdminTemplateWithCenteredContent", {});

  return (
    <Box __css={styles.outerContainer}>
      <Box
        __css={styles.backgroundContainer}
        backgroundImage={{
          base: mobileImage,
          md: desktopImage,
        }}
      >
        <Box __css={styles.headingContainer}>{!isLoading && headerContent}</Box>
        {!isLoading && (
          <Box __css={styles.centeredContentContainer}>
            <Container centerContent variant="adminContentWrapper">
              {children}
            </Container>
          </Box>
        )}
      </Box>
      {isLoading && (
        <Box __css={styles.loadingSpinner}>
          <LoadingSpinner />
        </Box>
      )}
    </Box>
  );
};
