import { useDisclosure } from "@chakra-ui/react";
import React, { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { useInterval } from "react-use";

import { useLibrarySearch } from "adminComponents/hooks/useLibrarySearch";
import { SignUpFooter } from "adminComponents/molecules/SignUpFooter";
import { TeacherSignInModal } from "adminComponents/organisms/TeacherSignInModal";
import { TeacherProfileScreen } from "adminComponents/screens/TeacherProfileScreen";
import { TeacherProfileUnavailableScreen } from "adminComponents/screens/TeacherProfileUnavailableScreen";
import { useErrorToast } from "adminComponents/utils/toast";
import { COPPA_URL } from "links/lib/constants";
import { useAuth, useInitAuth } from "links/lib/features/auth";
import { useFetchUserPublicProfileAnonymous } from "links/lib/features/users";
import { PracticeSetAvailability, UserRole } from "links/lib/types";
import useFetchProviderRedirectUrl from "screens/SignIn/useFetchProviderRedirectUrl";
import { useShowNewLibrary } from "sharedComponents/hooks/useShowNewLibrary";

export interface ITeacherProfileProps {
  children?: ReactNode;
}

export const AnonymousTeacherProfile: React.FC<ITeacherProfileProps> = ({
  children,
}) => {
  const { customUrlCode } = useParams<{ customUrlCode: string }>();

  const { authUser } = useAuth();
  const newLibraryFeatureEnabled = useShowNewLibrary();
  const history = useHistory();
  const { t } = useTranslation("admin");
  const { execute: executeInitAuth } = useInitAuth();

  const [profileAvailable, setProfileAvailable] = useState(true);
  const fetchUser = useFetchUserPublicProfileAnonymous({
    customUrlCode,
    onForbidden: () => {
      setProfileAvailable(false);
    },
    onNotFound: () => {
      setProfileAvailable(false);
    },
  });
  const [authenticationWindow, setAuthenticationWindow] =
    useState<Window | null>();

  // Check for sign-in outside the current tab/window
  useInterval(executeInitAuth, 5000);

  // If the user is authenticated, is not a guest, and not a student, redirect them
  // to the full teacher experience
  useEffect(() => {
    if (!authUser || authUser.is_guest || authUser.role === UserRole.Student)
      return;
    history.push(`/t/profiles/${customUrlCode}`);
  }, [authUser, fetchUser.data, customUrlCode, history]);

  const {
    handleSetTerm,
    query,
    handleViewMorePracticeSets,
    practiceSets,
    totalCounts,
    isSearchLoading,
    showViewMorePracticeSets,
    handleClearSearchTerm,
  } = useLibrarySearch({
    userId: fetchUser.data?.user.id,
    gradeLevels: fetchUser.data?.grade_levels || [],
    subjects: fetchUser.data?.subjects || [],
    region: fetchUser.data?.user.region,
    country: fetchUser.data?.user.country || "US",
    availability: PracticeSetAvailability.Public,
    ignoreCollections: !authUser || authUser.is_guest,
    disableRecentPracticeSets: !authUser,
    anonymous: !authUser,
  });

  const {
    isOpen: isSignInModalOpen,
    onClose: onCloseSignInModal,
    onOpen: onOpenSignInModal,
  } = useDisclosure();
  const { isLoading, error, data, execute } = useFetchProviderRedirectUrl();

  useErrorToast(error);

  const redirectUrl = data?.redirectUrl;

  // When the redirect URL has been set, then redirect
  useEffect(() => {
    if (authenticationWindow && redirectUrl) {
      authenticationWindow.location.href = redirectUrl;
    }
  }, [authenticationWindow, redirectUrl]);

  // This fetches a URL for the given provider, which results in a
  // redirect by the above effect
  function onSignIn(provider: "Google" | "Clever" | "Microsoft") {
    return () => {
      setAuthenticationWindow(window.open("", "_blank"));
      execute({
        provider,
        role: UserRole.Teacher,
        language: authUser?.language,
      });
    };
  }

  const handleSignUp = () => {
    onOpenSignInModal();
  };

  const handleClickPracticeSetCard = (
    e: React.MouseEvent<HTMLAnchorElement>
  ) => {
    e.preventDefault();
    e.stopPropagation();
    handleSignUp();
  };

  const subjects = fetchUser.data?.subjects || [];

  const navigationData = {
    logoLink: {
      label: t("logo.ariaLabel"),
      to: newLibraryFeatureEnabled ? "/t/explore" : "/t/home",
    },
    avatarData: {},
    resourceLinks: [],
    authActionLinks: [{ to: "/sign-in/teacher", label: t("common.signIn") }],
    accountLinks: [],
    user: authUser,
    subjects,
    parentSubjects: subjects.filter((s) => s.parent_id === "0"),
    gradeLevels: fetchUser.data?.grade_levels || [],
    handleLogIn: handleSignUp,
    handleSignUp: handleSignUp,
    handleCreatePracticeSet: () => history.push("/t/instant-sets"),
  };

  return (
    <>
      {profileAvailable && (
        <TeacherProfileScreen
          user={fetchUser.data?.user}
          statistics={fetchUser.data?.statistics}
          navigationData={navigationData}
          isLoading={fetchUser.isLoading}
          practiceSets={practiceSets}
          isSearchLoading={isSearchLoading}
          practiceSetTotalCount={totalCounts.practiceSets}
          showViewMorePracticeSets={showViewMorePracticeSets}
          initialSearchTerm={query.term || ""}
          hidePracticeSetCardMenus={true}
          handleViewMorePracticeSets={handleViewMorePracticeSets}
          handleSearch={handleSetTerm}
          handleClearSearchInput={handleClearSearchTerm}
          handleClickPracticeSetCard={handleClickPracticeSetCard}
          handleOpenFollowersModal={handleSignUp}
          handleOpenFollowingModal={handleSignUp}
          handleFollowUser={handleSignUp}
        >
          {children}
          <SignUpFooter handleSignUp={handleSignUp} />
        </TeacherProfileScreen>
      )}
      {!profileAvailable && (
        <TeacherProfileUnavailableScreen navigationData={navigationData} />
      )}
      <TeacherSignInModal
        handleBack={onCloseSignInModal}
        handleNotTeacherClick={
          authUser
            ? () => {
                window.open("/u/home", "_blank");
              }
            : undefined
        }
        handleSignInWithCleverButtonClick={onSignIn("Clever")}
        handleSignInWithGoogleButtonClick={onSignIn("Google")}
        handleSignInWithMicrosoftButtonClick={onSignIn("Microsoft")}
        isLoading={isLoading}
        coppaUrl={COPPA_URL}
        isOpen={isSignInModalOpen}
        handleClose={onCloseSignInModal}
      />
    </>
  );
};
