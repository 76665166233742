import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { EditTeacherDetailsModal } from "adminComponents/organisms/EditTeacherDetailsModal";
import { useErrorToast, useShowToast } from "adminComponents/utils/toast";
import { useAnalytics } from "lib/contexts/analytics";
import {
  useMutateClassroomUsers,
  useRemoveClassroomUser,
} from "links/lib/features/classrooms";
import { AnalyticsEvent, IUser, UsersGroupsRole } from "links/lib/types";

interface IArgs {
  classroomId: string;
}

interface IResult {
  handleEditIntent: (user: IUser, usersGroupsRole: UsersGroupsRole) => void;
  handleRemove: (user: IUser) => void;
  handleSave: (user: IUser, usersGroupsRole: UsersGroupsRole) => void;
  editModal: React.ReactElement;
}

export const useEditTeacher = ({ classroomId }: IArgs): IResult => {
  const [state, setState] = useState<
    { user: IUser; usersGroupsRole: UsersGroupsRole } | undefined
  >();
  const showToast = useShowToast();
  const { t } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "classroomDetailContainer.teachersTab.editTeacherModal",
  });
  const { trackEvent } = useAnalytics();

  const onRemoveSuccess = useCallback(() => {
    setState(undefined);

    showToast(t("removeSuccessToast"));
  }, [showToast, t]);

  const onEditSuccess = useCallback(() => {
    setState(undefined);

    showToast(t("editSuccessToast"));
  }, [showToast, t]);

  const removeUser = useRemoveClassroomUser({
    onSuccess: onRemoveSuccess,
  });
  useErrorToast(removeUser.error);

  const editTeacher = useMutateClassroomUsers({
    onSuccess: onEditSuccess,
  });
  useErrorToast(editTeacher.error);

  const handleEditIntent = useCallback(
    (user: IUser, usersGroupsRole: UsersGroupsRole) => {
      setState({ user, usersGroupsRole });
      trackEvent(
        AnalyticsEvent.TeacherDashboard_ClassroomDetail_TeachersTab_EditTeacherIntent,
        {
          classroomId,
          userId: user.id,
        }
      );
    },
    [trackEvent, classroomId]
  );

  const handleClose = useCallback(() => {
    setState(undefined);
  }, []);

  const handleSave = useCallback(
    (user: IUser, usersGroupsRole: UsersGroupsRole) => {
      editTeacher.mutate({
        classroomId: classroomId,
        users: [user],
        disableNotifications: true,
        usersGroupsRole,
      });

      trackEvent(
        AnalyticsEvent.TeacherDashboard_ClassroomDetail_TeachersTab_EditTeacherConfirm,
        {
          classroomId,
          userId: user.id,
        }
      );
    },
    [editTeacher, classroomId, trackEvent]
  );

  const handleRemove = useCallback(
    (user: IUser) => {
      removeUser.mutate({
        classroomId: classroomId,
        email: user.email,
      });

      trackEvent(
        AnalyticsEvent.TeacherDashboard_ClassroomDetail_TeachersTab_RemoveTeacherConfirm,
        {
          classroomId,
          userId: user.id,
        }
      );
    },
    [classroomId, removeUser, trackEvent]
  );

  const editModal = useMemo(() => {
    return (
      <EditTeacherDetailsModal
        isOpen={!!state?.user}
        isLoading={editTeacher.isLoading}
        removing={removeUser.isLoading}
        handleClose={handleClose}
        handleSave={handleSave}
        handleRemove={handleRemove}
        user={state?.user}
        defaultUsersGroupsRole={state?.usersGroupsRole || UsersGroupsRole.Owner}
      />
    );
  }, [
    handleClose,
    handleSave,
    handleRemove,
    state,
    removeUser.isLoading,
    editTeacher.isLoading,
  ]);

  return useMemo(() => {
    return {
      handleEditIntent,
      handleRemove,
      handleSave,
      editModal,
    };
  }, [handleEditIntent, handleRemove, handleSave, editModal]);
};
