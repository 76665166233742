import { useTranslation } from "react-i18next";

import { useSessionRoundState } from "links/lib/contexts/sessionRoundState";
import { useSessionScene } from "links/lib/contexts/sessionScene";
import {
  PracticeSessionItemVariantType,
  QuestionType,
  SessionScene,
} from "links/lib/types";

import { useBigBoardSpinHeaderTitleText } from "./useBigBoardSpinHeaderTitleText";
import { useTokenPlacementHeaderTitleText } from "./useTokenPlacementHeaderTitleText";

export interface IOpts {
  isTeacher: boolean;
  userId: string;
}

export const useHeaderTitleText = ({ isTeacher, userId }: IOpts): string => {
  const scene = useSessionScene();
  const roundState = useSessionRoundState();
  const tokenPlacementHeaderTitleText = useTokenPlacementHeaderTitleText({
    isTeacher,
    userId,
  });
  const bigBoardSpinHeaderTitleText = useBigBoardSpinHeaderTitleText(userId);
  const { t } = useTranslation("session", { useSuspense: false });
  switch (scene) {
    case SessionScene.Lobby:
      return isTeacher ? t("teacherLobby.heading") : t("studentLobby.heading");
    case SessionScene.Grouping:
      return isTeacher
        ? t("teacherTeamUp.heading")
        : t("studentTeamUp.heading");
    case SessionScene.LeaderBoard:
      return t("leaderBoardScreen.heading");
    case SessionScene.Podium:
      return isTeacher
        ? t("podium.headingTeacher")
        : t("podium.headingStudent");
    case SessionScene.OuterGameIntro:
      return t("gameInstructions.screenTitle");
    case SessionScene.OuterGame:
      return tokenPlacementHeaderTitleText;
    case SessionScene.PrizeRound:
      return bigBoardSpinHeaderTitleText;
    case SessionScene.PrizeRoundAwards:
      return t("prizeRoundAwards.heading");
    case SessionScene.Round:
      switch (roundState.variant) {
        case PracticeSessionItemVariantType.CoopChecks:
          return t("sessionHeader.coopChecksRoundTitle");
        case PracticeSessionItemVariantType.CoopRadios:
          return t("sessionHeader.coopRadiosRoundTitle");
        case PracticeSessionItemVariantType.CoopTextMatch:
          return t("sessionHeader.coopTextMatchTitle");
        case PracticeSessionItemVariantType.CoopTextMatchNumeric:
          return t("sessionHeader.coopTextMatchNumericTitle");
        case PracticeSessionItemVariantType.CoopDragsort:
          return roundState.practice_set_session_item.question_type ===
            QuestionType.Classify
            ? t("sessionHeader.classificationTitle")
            : t("sessionHeader.diagramTitle");
        case PracticeSessionItemVariantType.CoopDraw:
          return t("sessionHeader.coopDrawTitle");
        default:
          return "";
      }
    case SessionScene.RoundReview:
      return t("roundReview.screenTitle");
    case SessionScene.Voting:
      return t("sessionHeader.studentVotingTitle");
    case SessionScene.VotingResults:
      return t("sessionHeader.teacherVotingTitle");
    case SessionScene.VotingAwards:
      return t("sessionHeader.votingResultsTitle");
    default:
      return "";
  }
};
