import React from "react";
import { useTranslation } from "react-i18next";

import {
  INavigationHeaderProps,
  NavigationHeader,
} from "adminComponents/molecules/NavigationHeader";
import { NotFoundModule } from "adminComponents/molecules/NotFoundModule";
import { TemplateWithVerticalCenteredContent } from "adminComponents/templates/TemplateWithVerticalCenteredContent";
import { usePageTitle } from "lib/hooks/usePageTitle";
import { useAuth } from "links/lib/features/auth";

export interface ITeacherProfileUnavailableScreenProps {
  navigationData: INavigationHeaderProps;
}

export const TeacherProfileUnavailableScreen: React.FC<
  ITeacherProfileUnavailableScreenProps
> = ({ navigationData }) => {
  const { authUser } = useAuth();
  const { t } = useTranslation("admin", {
    keyPrefix: "teacherProfileUnavailable",
    useSuspense: false,
  });

  usePageTitle(t("pageTitle"));

  return (
    <TemplateWithVerticalCenteredContent
      nav={<NavigationHeader {...navigationData} />}
    >
      <NotFoundModule
        title={t("heading")}
        description={
          authUser && !authUser.is_guest
            ? t("description")
            : t("descriptionUnauthenticated")
        }
      />
    </TemplateWithVerticalCenteredContent>
  );
};
