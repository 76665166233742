import { InfoIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Checkbox,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  GridItem,
  HStack,
  Heading,
  Input,
  SimpleGrid,
  Text,
  useToast,
} from "@chakra-ui/react";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { useForm } from "react-hook-form";

import { useMutateUserTrialExtension } from "links/lib/features/userTrialExtensions";
import { IUserTrialExtension } from "links/lib/types";

interface IUserTrialExtensionCardProps {
  userTrialExtension?: IUserTrialExtension;
  isOpen: boolean;
  onClose: () => void;
}

export interface IUserTrialExtensionForm {
  id: string;
  guid: string;
  extended_to_date?: Date | null;
  extended_by_days?: string;
  expires_at?: Date | null;
  required_organization_id?: string;
  required_domain?: string;
  max_use_count?: string;
  prevent_stacking: boolean;
}

const emptyForm = {
  id: undefined,
  guid: undefined,
  extended_to_date: undefined,
  extended_by_days: undefined,
  expires_at: undefined,
  required_organization_id: undefined,
  required_domain: undefined,
  max_use_count: undefined,
  prevent_stacking: true,
};

export const UserTrialExtensionFlyout: React.FC<
  IUserTrialExtensionCardProps
> = ({ userTrialExtension, isOpen, onClose }) => {
  const { register, reset, setValue, watch, getValues } =
    useForm<IUserTrialExtensionForm>();
  const { mutate } = useMutateUserTrialExtension();
  const showToast = useToast();
  const [error, setError] = useState<string>("");

  const watchedForm = watch();

  useEffect(() => {
    if (userTrialExtension) {
      const extensionDateMoment = moment(userTrialExtension.extended_to_date);
      const expirationDateMoment = moment(userTrialExtension.expires_at);

      reset({
        id: userTrialExtension.id,
        guid: userTrialExtension.guid,
        extended_to_date:
          extensionDateMoment.unix() >= 0
            ? extensionDateMoment.toDate()
            : undefined,
        extended_by_days: userTrialExtension.extended_by_days?.toString(),
        expires_at:
          expirationDateMoment.unix() >= 0
            ? expirationDateMoment.toDate()
            : undefined,
        required_organization_id: userTrialExtension.required_organization_id,
        required_domain: userTrialExtension.required_domain,
        max_use_count: userTrialExtension.max_use_count?.toString(),
        prevent_stacking: userTrialExtension.prevent_stacking,
      });
    } else {
      // reset() with no args seems like it should function the same as below but
      // that wasn't observed
      reset(emptyForm);
    }
    setError("");
  }, [userTrialExtension, reset]);

  const onSubmit = useCallback(() => {
    setError("");
    const userTrialExtension = getValues();

    mutate(
      {
        user_trial_extension: {
          id: userTrialExtension.id,
          guid: userTrialExtension.guid,
          extended_by_days: userTrialExtension.extended_by_days
            ? parseInt(userTrialExtension.extended_by_days)
            : undefined,
          prevent_stacking: userTrialExtension.prevent_stacking,
          required_domain: userTrialExtension.required_domain || undefined,
          max_use_count: userTrialExtension.max_use_count
            ? parseInt(userTrialExtension.max_use_count)
            : undefined,
          required_organization_id:
            userTrialExtension.required_organization_id || undefined,
          extended_to_date: userTrialExtension.extended_to_date
            ? moment(userTrialExtension.extended_to_date).toISOString()
            : undefined,
          expires_at: userTrialExtension.expires_at
            ? moment(userTrialExtension.expires_at).toISOString()
            : undefined,
        },
      },
      {
        onSuccess: () => {
          showToast({ title: "Saved User Trial Extension" });
          onClose();
        },
        onError: (error) => {
          setError(
            (error as { response: { data: { message: string } } })?.response
              ?.data?.message || JSON.stringify(error)
          );
        },
      }
    );
  }, [getValues, mutate, onClose, showToast, setError]);

  return (
    <Drawer isOpen={isOpen} onClose={onClose} size="xl">
      <DrawerOverlay />
      <DrawerContent>
        <Box
          padding="24px"
          borderRadius="xl"
          borderColor="gray"
          flexDir="column"
          gap="12px"
          overflowY="auto"
        >
          <Center paddingBottom="10px">
            <Heading>{`${
              userTrialExtension?.id ? "Edit" : "Create"
            } User Trial Extension`}</Heading>
          </Center>
          {!!error && <Text color="red">{error}</Text>}
          <SimpleGrid
            columns={2}
            spacing="25px"
            alignItems="flex-end"
            marginTop="30px"
          >
            <Text>Max Use Count:</Text>
            <Input type="number" {...register("max_use_count")} />
            <GridItem marginTop="-25px" colSpan={2}>
              <HStack>
                <InfoIcon />
                <Text>
                  Limit the number of users who can extend their trial with this
                  link.
                </Text>
              </HStack>
            </GridItem>

            <Text>Extended To Date:</Text>
            <Box borderRadius="lg" borderWidth="2px" borderColor="gray" p="3px">
              <ReactDatePicker
                dateFormat="EEEE MMMM d"
                onChange={(date) =>
                  setValue("extended_to_date", date as Date | null)
                }
                selected={watchedForm.extended_to_date}
                startOpen={false}
              />
            </Box>
            <GridItem marginTop="-25px" colSpan={2}>
              <HStack>
                <InfoIcon />
                <Text>
                  You can either choose to extend trials to this date or add a
                  number of days below.
                </Text>
              </HStack>
            </GridItem>

            <Text>Extend By Days:</Text>
            <Input type="number" {...register("extended_by_days")} />
            <GridItem marginTop="-25px" colSpan={2}>
              <HStack>
                <InfoIcon />
                <Text>
                  You can either choose to extend trials by this many days or
                  use the extension date above.
                </Text>
              </HStack>
            </GridItem>

            <Text>Expires At:</Text>
            <Box borderRadius="lg" borderWidth="2px" borderColor="gray" p="3px">
              <ReactDatePicker
                dateFormat="EEEE MMMM d"
                onChange={(date) => setValue("expires_at", date as Date | null)}
                selected={watchedForm.expires_at}
                startOpen={false}
              />
            </Box>
            <GridItem marginTop="-25px" colSpan={2}>
              <HStack>
                <InfoIcon />
                <Text>
                  You must set a date that this trial link will expire and will
                  not be usable after.
                </Text>
              </HStack>
            </GridItem>

            <Text>Organization ID:</Text>
            <Input {...register("required_organization_id")} />
            <GridItem marginTop="-25px" colSpan={2}>
              <HStack>
                <InfoIcon />
                <Text>
                  You can limit this trial link to users of a certain
                  organization.
                </Text>
              </HStack>
            </GridItem>

            <Text>Domain:</Text>
            <Input {...register("required_domain")} />
            <GridItem marginTop="-25px" colSpan={2}>
              <HStack>
                <InfoIcon />
                <Text>
                  You can limit this trial link to users with an email matching
                  this domain.
                </Text>
              </HStack>
            </GridItem>

            <Text>Prevent Stacking:</Text>
            <Checkbox {...register("prevent_stacking")} />
            <GridItem marginTop="-25px" colSpan={2}>
              <HStack>
                <InfoIcon />
                <Text>
                  If you prevent stacking you are preventing users from using
                  this trial link if they already have more trial time than this
                  link would give them. This will also prevent their trial from
                  being extended by more than this extension is for. Example:
                  current user trial end date is 9 days from now, this is for a
                  10 day extension, with prevent stacking user would only have
                  their trial extended 1 day.
                </Text>
              </HStack>
            </GridItem>
          </SimpleGrid>

          <Center marginTop="30px">
            <Button borderRadius="xl" color="gray" onClick={onSubmit}>
              Submit
            </Button>
          </Center>
        </Box>
      </DrawerContent>
    </Drawer>
  );
};
