import { useEffect, useState } from "react";

import {
  CoopDrawVariantData,
  IPracticeSetSessionItemMultipleChoiceChoice,
} from "links/lib/types";
import { getCanvasAsImage, getImageElement } from "links/lib/util";

export const getConvertedDrawOptions = async (
  drawVariantData: CoopDrawVariantData,
  drawOptionWidth: number
): Promise<IPracticeSetSessionItemMultipleChoiceChoice[]> => {
  if (!drawVariantData || !drawVariantData.coop_draw) return [];
  const { group_strokes, background_image_url } = drawVariantData.coop_draw;

  const background = await getImageElement(background_image_url);

  return Promise.all(
    Object.keys(group_strokes).map(async (groupId) => {
      const groupDrawData =
        group_strokes[groupId as keyof typeof group_strokes];

      if (!groupDrawData.strokes.length) {
        return {
          id: groupId,
          text: "",
          image_url: "",
          image_alt_text: `group-${groupId}-drawing`,
        };
      }

      const imageUrl =
        getCanvasAsImage(
          groupDrawData.strokes,
          drawOptionWidth,
          drawOptionWidth * 0.75,
          background
        ) || "";

      return {
        id: groupId,
        text: "",
        image_url: imageUrl,
        image_alt_text: `group-${groupId}-drawing`,
      };
    })
  );
};

export const useConvertedDrawOptions = (
  drawVariantData: CoopDrawVariantData,
  drawOptionWidth: number
): IPracticeSetSessionItemMultipleChoiceChoice[] => {
  const [drawOptions, setDrawOptions] = useState<
    IPracticeSetSessionItemMultipleChoiceChoice[]
  >([]);

  useEffect(() => {
    (async () => {
      setDrawOptions(
        (await getConvertedDrawOptions(drawVariantData, drawOptionWidth)) || []
      );
    })();
  }, [drawVariantData, drawOptionWidth]);

  return drawOptions;
};
