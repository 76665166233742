import { Box, useMultiStyleConfig } from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

import { Icon } from "adminComponents/atoms/Icon";

interface Video {
  src: string;
  type: "mp4" | "webm" | "ogg";
}
interface VideoTrack {
  label: string;
  kind: "subtitles" | "captions";
  srcLang: string;
  src: string;
}

export interface VideoPlayerProps {
  posterImageUrl?: string;
  videoUrls: Video[];
  tracks: VideoTrack[];
}

export const VideoPlayer: React.FC<VideoPlayerProps> = ({
  posterImageUrl,
  videoUrls,
  tracks,
  ...props
}) => {
  const videoRef = useRef<HTMLVideoElement & HTMLDivElement>(null);

  const { t } = useTranslation("admin", { useSuspense: false });
  const styles = useMultiStyleConfig("AdminVideoPlayer", {});

  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlay = () => {
    const video = videoRef.current;

    if (video?.paused) {
      video.play();
      video.controls = true;
      setIsPlaying(true);
    } else {
      video?.pause();
      setIsPlaying(false);
    }
  };

  return (
    <Box sx={styles.container}>
      <Box
        sx={styles.videoPlayer}
        as="video"
        poster={posterImageUrl}
        controls={false}
        ref={videoRef}
        {...props}
      >
        {videoUrls?.map((video) => {
          const { src, type } = video;
          return <source key={uuidv4()} src={src} type={`video/${type}`} />;
        })}

        {tracks?.map((track) => {
          return <track key={`video-track-${track.label}`} {...track} />;
        })}

        {t("common.videoNotAvailable")}
      </Box>

      {!isPlaying && (
        <Box sx={styles.playButtonContainer} as="button" onClick={handlePlay}>
          <Icon sx={styles.playButtonIcon} icon="play" />
        </Box>
      )}
    </Box>
  );
};
