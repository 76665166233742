import { useState } from "react";

import { useErrorToast } from "adminComponents/utils/toast";
import {
  useExplainSessionItem,
  useRateExplainConversation,
} from "links/lib/features/explain";
import {
  ExplainAction,
  ExplainMessage,
  ExplainMessageSender,
  IPracticeSetSessionItem,
} from "links/lib/types";

interface IArgs {
  practice_set_session_id: string;
  practice_set_session_item_id: string;
  practice_set_item_id: string;
  group_id: string;
  practice_set_session_item: IPracticeSetSessionItem;
}

interface IResult {
  handleAction: (action: ExplainAction) => Promise<void>;
  handleRateConversation: (rating: number) => void;
  isLoading: boolean;
  isRateConversationLoading: boolean;
  isRateConversationSuccess: boolean;
  messages: Array<ExplainMessage>;
  availableUserActions: Array<ExplainAction>;
  rating: number;
}

export const useExplain = ({
  practice_set_session_id,
  practice_set_session_item_id,
  practice_set_item_id,
  group_id,
  practice_set_session_item,
}: IArgs): IResult => {
  const { isLoading, error, mutateAsync } = useExplainSessionItem();
  const {
    isLoading: isRateConversationLoading,
    isSuccess: isRateConversationSuccess,
    error: rateConversationError,
    mutateAsync: mutateRateConversationAsync,
  } = useRateExplainConversation();
  const [messages, setMessages] = useState<Array<ExplainMessage>>([]);
  const [availableUserActions, setAvailableUserActions] = useState<
    Array<ExplainAction>
  >([]);
  const [rating, setRating] = useState(0);

  const handleAction = async (action: ExplainAction): Promise<void> => {
    if (action !== ExplainAction.Explain) {
      setMessages((messages) =>
        messages.concat([{ sender: ExplainMessageSender.User, text: action }])
      );
    }

    const result = await mutateAsync({
      practice_set_session_id,
      practice_set_session_item_id,
      practice_set_item_id,
      action,
      group_id,
      practice_set_session_item,
    });

    if (result?.messages) {
      setMessages(result.messages);
    }

    if (result?.available_actions) {
      setAvailableUserActions(result.available_actions);
    }
  };

  const handleRateConversation = async (rating: number) => {
    try {
      await mutateRateConversationAsync({
        practice_set_session_id,
        practice_set_session_item_id,
        practice_set_item_id,
        rating,
      });
      setRating(rating);
    } catch (_) {
      // Do nothing
    }
  };

  useErrorToast(error);
  useErrorToast(rateConversationError);

  return {
    isLoading,
    isRateConversationLoading,
    isRateConversationSuccess,
    handleAction,
    handleRateConversation,
    messages,
    availableUserActions,
    rating,
  };
};
