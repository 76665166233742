import { AxiosInstance } from "axios";
import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { IAssignment } from "../../types";

export interface IFetchClassroomAssignmentsParams {
  classroom_id: string;
  practice_set_id?: string;
  start_date: Date;
  end_date: Date;
  sort_desc: boolean;
  limit: number;
  keepPreviousData?: boolean;
  enabled?: boolean;
}

export interface IFetchClassroomAssignmentsResponse {
  assignments: Array<IAssignment>;
}

export const fetchClassroomAssignmentsQuery = (
  axios: AxiosInstance,
  params: IFetchClassroomAssignmentsParams
): (() => Promise<IFetchClassroomAssignmentsResponse>) => {
  const {
    classroom_id,
    practice_set_id,
    start_date,
    end_date,
    sort_desc,
    limit,
  } = params;

  return async (): Promise<IFetchClassroomAssignmentsResponse> => {
    return await axios
      .get(`/v1/assignments`, {
        params: {
          group_id: classroom_id,
          practice_set_id,
          sort_desc,
          limit,
          start_date: start_date.toISOString(),
          end_date: end_date.toISOString(),
        },
      })
      .then((r) => r.data as IFetchClassroomAssignmentsResponse);
  };
};

/**
 * Fetches a list of assignments for the classroom
 * @returns
 */
export default function useFetchClassroomAssignments(
  params: IFetchClassroomAssignmentsParams
): UseQueryResult<IFetchClassroomAssignmentsResponse, unknown> {
  const axios = useAxios();
  const {
    classroom_id,
    practice_set_id,
    start_date,
    end_date,
    sort_desc,
    limit,
    keepPreviousData,
    enabled = true,
  } = params;

  const query = useQuery<IFetchClassroomAssignmentsResponse>(
    [
      "assignments",
      { classroom_id },
      { practice_set_id, start_date, end_date, sort_desc, limit },
    ],
    fetchClassroomAssignmentsQuery(axios, params),
    { enabled: enabled && classroom_id !== "0", keepPreviousData }
  );

  return query;
}
