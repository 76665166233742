import { useCallback, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";

import {
  ToastId,
  useErrorToast,
  usePendingToast,
  useShowToast,
} from "adminComponents/utils/toast";
import { useCopyPracticeSetItem } from "links/lib/features/practiceSetItems";
import { IPracticeSet, IPracticeSetItem } from "links/lib/types";

interface IArgs {
  practiceSet?: IPracticeSet;
}

interface IResult {
  handleDuplicate: (item: IPracticeSetItem) => void;
}

const useDuplicatePracticeSetItem = ({ practiceSet }: IArgs): IResult => {
  const showToast = useShowToast();
  const { showPendingToast, closePendingToast } = usePendingToast();
  const { t } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "practiceSetDetailContainer.practiceSetItemDuplicate",
  });
  const pendingToastRef = useRef<ToastId | undefined>();

  const onSuccess = useCallback(() => {
    showToast(t("successToast"));
  }, [showToast, t]);

  const onSettled = useCallback(() => {
    if (pendingToastRef.current) {
      closePendingToast(pendingToastRef.current);
      pendingToastRef.current = undefined;
    }
  }, [closePendingToast]);

  const itemCopyMutation = useCopyPracticeSetItem({ onSettled, onSuccess });

  useErrorToast(itemCopyMutation.error);

  const handleDuplicate = useCallback(
    (item: IPracticeSetItem) => {
      if (!practiceSet) return;

      itemCopyMutation.mutate({
        id: item.id,
        practice_set_id: practiceSet.id,
      });

      pendingToastRef.current = showPendingToast(t("pendingToast"));
    },
    [practiceSet, itemCopyMutation, showPendingToast, t]
  );

  return useMemo(
    () => ({
      handleDuplicate,
    }),
    [handleDuplicate]
  );
};

export { useDuplicatePracticeSetItem };
