import { pxToRem } from "adminComponents/utils/pxToRem";

export const Tabs = {
  variants: {
    gameTabs: (props: { [key: string]: string }): Record<string, unknown> => ({
      marginBottom: 0,
      tablist: {
        borderBottom: `${props.$borderWidth} solid`,
        borderBottomColor: "primary.light-gray",
        justifyContent: "center",
      },
      tabpanel: {
        padding: "0px",
      },
      tab: {
        textStyle: ["gameTextWeighted"],
        fontSize: props.$fontSize,
        color: "primary.dark-gray",
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: [pxToRem(15)],
        borderBottom: `${pxToRem(3)} solid`,
        borderBottomColor: "transparent",
        marginLeft: pxToRem(48),
        _first: {
          marginLeft: 0,
        },
        _hover: {
          paddingBottom: [pxToRem(15)],
          borderBottom: `${pxToRem(3)} solid`,
          borderBottomColor: "primary.medium-gray",
        },
        _selected: {
          paddingBottom: [pxToRem(15)],
          borderBottom: `${pxToRem(3)} solid`,
          borderBottomColor: "utility.link",
          color: "primary.warm-black",
        },
        _focus: {
          boxShadow: "none",
          position: "relative",
        },
        _disabled: {
          opacity: 0.4,
          cursor: "not-allowed",
        },
      },
    }),
  },
};
