import { Box, HStack, RadioGroup, Stack, VStack } from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMount } from "react-use";

import { Button } from "adminComponents/atoms/Button";
import { Card } from "adminComponents/atoms/Card";
import { Checkbox } from "adminComponents/atoms/Checkbox";
import { DatePicker } from "adminComponents/atoms/DatePicker";
import { Dropdown, IOption } from "adminComponents/atoms/Dropdown";
import { Heading } from "adminComponents/atoms/Heading";
import { Icon } from "adminComponents/atoms/Icon";
import { IconTooltip } from "adminComponents/atoms/IconTooltip";
import { Radio } from "adminComponents/atoms/Radio";
import { Text } from "adminComponents/atoms/Text";
import { PracticeSetTypeCard } from "adminComponents/molecules/PracticeSetTypeCard";
import { pxToRem } from "adminComponents/utils";
import { useAuth } from "links/lib/features/auth";
import {
  IGroupSmartSetCapability,
  IPracticeSet,
  ISubject,
} from "links/lib/types";
import {
  IClassroom,
  IPracticeSetItem,
  ISmartSetSettings,
  SmartSetType,
} from "links/lib/types";

import { ReactComponent as SmartSetReviewSVG } from "./resource/smart_set_review.svg";
import { ReactComponent as SmartSetStandardsInterventionSVG } from "./resource/smart_set_standards_intervention.svg";

export interface IGroupSmartSetCapabilities {
  group_id: string;
  capabilities: Array<IGroupSmartSetCapability>;
}

interface IProps {
  classrooms: Array<IClassroom>;
  subjects: Array<ISubject>;
  groupSmartSetsCapabilities?: Array<IGroupSmartSetCapabilities>;
  defaultValue?: {
    smartSetType?: SmartSetType;
    smartSetSettings?: ISmartSetSettings;
  };
  generateState: {
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    errorMessage?: string;
    items?: Array<IPracticeSetItem>;
    practiceSet?: IPracticeSet;
  };
  handleGenerate: (data: {
    smartSetType: SmartSetType;
    smartSetSettings: ISmartSetSettings;
  }) => void;
}

export const SmartSetConfiguration: React.FC<IProps> = ({
  classrooms,
  subjects,
  groupSmartSetsCapabilities,
  defaultValue,
  generateState,
  handleGenerate,
}) => {
  const { hasNoPremiumAccess } = useAuth();
  const { t } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "smartSetConfiguration",
  });

  const [smartSetSettings, setSmartSetSettings] = useState<ISmartSetSettings>(
    defaultValue?.smartSetSettings ?? {}
  );
  const [smartSetType, setSmartSetType] = useState<SmartSetType>(
    defaultValue?.smartSetType ?? SmartSetType.None
  );
  const [smartSetReviewPeriod, setSmartSetReviewPeriod] = useState(
    defaultValue?.smartSetSettings?.start_time ? "custom" : ""
  );
  const [isDirty, setIsDirty] = useState(false);
  const [isIneligible, setIsIneligible] = useState(false);

  const showNoItemsWarning =
    generateState.isSuccess && generateState.items?.length === 0;

  // The settings aren't eligible if we've requested items and there aren't any.
  useEffect(() => {
    setIsIneligible(showNoItemsWarning);
  }, [showNoItemsWarning]);

  const handleToggleSubject = (enabled: boolean, subjectId: string) => {
    const subjectIds = [...(smartSetSettings?.subject_ids || [])];

    if (enabled) {
      subjectIds.push(subjectId);
    } else {
      subjectIds.splice(subjectIds.indexOf(subjectId), 1);
    }

    updateSmartSetSettings({ subject_ids: subjectIds });
  };

  /**
   * Partially update the smart set settings and generate a new preview (if valid)
   * @param update
   */
  const updateSmartSetSettings = (update: Partial<ISmartSetSettings>) => {
    setSmartSetSettings((s) => {
      const newSettings = {
        ...s,
        ...update,
      };

      return newSettings;
    });

    setIsDirty(true);

    // Clear ineligiblity indication as a subsequent generation may yield
    // items.
    setIsIneligible(false);
  };

  const { reviewCapability, standardsInterventionCapability } = useMemo(() => {
    const capabilities: {
      reviewCapability?: IGroupSmartSetCapability;
      standardsInterventionCapability?: IGroupSmartSetCapability;
    } = {};

    if (!smartSetSettings.group_id) return capabilities;
    if (!groupSmartSetsCapabilities) return capabilities;

    const groupCapabilities =
      groupSmartSetsCapabilities.find(
        (group) => group.group_id === smartSetSettings.group_id
      )?.capabilities || [];

    groupCapabilities.forEach((gc) => {
      switch (gc.smart_set_type) {
        case SmartSetType.Review:
          capabilities.reviewCapability = gc;
          break;
        case SmartSetType.StandardsIntervention:
          if (gc.is_capable && gc.subject_ids.length > 0) {
            capabilities.standardsInterventionCapability = gc;
          }
          break;
      }
    });

    return capabilities;
  }, [smartSetSettings, groupSmartSetsCapabilities]);

  const classroomSmartSetOptions = useMemo(() => {
    if (!classrooms) return [];
    if (!groupSmartSetsCapabilities) return [];

    return classrooms.map(({ name, id }) => {
      const groupCapabilities =
        groupSmartSetsCapabilities.find((group) => group.group_id === id)
          ?.capabilities || [];
      const isSmartSetsCapable =
        groupCapabilities.filter((capability) => capability.is_capable).length >
        0;
      return {
        label: name,
        value: id,
        isDisabled: !isSmartSetsCapable,
      };
    });
  }, [classrooms, groupSmartSetsCapabilities]);

  // Select the first capable classroom automatically
  useMount(() => {
    if (smartSetSettings.group_id) return;

    const smartSetCapableClassroom = classroomSmartSetOptions.find(
      (classroom) => !classroom.isDisabled
    );
    if (!smartSetCapableClassroom) return;

    setSmartSetSettings((state) => {
      return {
        ...state,
        group_id: smartSetCapableClassroom.value,
      };
    });
  });

  const standardsInterventionSubjectOptions = (
    standardsInterventionCapability?.subject_ids || []
  )
    .map((subjectId) => {
      const subject = subjects?.find((subject) => subject.id === subjectId);
      if (!subject) return;

      return {
        id: subjectId,
        subjectName: subject.name,
      };
    })
    .filter((a) => !!a)
    .sort((a, b) => {
      if (!a || !b) return 0;
      return a.subjectName < b.subjectName
        ? -1
        : a.subjectName > b.subjectName
        ? 1
        : 0;
    }) as Array<{ id: string; subjectName: string }>;

  const checkSmartSetSettings = (
    type: SmartSetType,
    settings: ISmartSetSettings
  ) => {
    if (
      type !== SmartSetType.Review &&
      type !== SmartSetType.StandardsIntervention
    )
      return false;

    const hasValidStandardsInterventionSettings =
      type === SmartSetType.StandardsIntervention &&
      settings.subject_ids &&
      settings.subject_ids.length > 0;
    const hasValidReviewSettings =
      type === SmartSetType.Review &&
      !!settings.start_time &&
      !!settings.end_time;
    return hasValidReviewSettings || hasValidStandardsInterventionSettings;
  };

  const _handleGenerate = () => {
    if (checkSmartSetSettings(smartSetType, smartSetSettings)) {
      setIsDirty(false);

      handleGenerate({
        smartSetType,
        smartSetSettings,
      });
    }
  };

  const smartSetSettingsValid = checkSmartSetSettings(
    smartSetType,
    smartSetSettings
  );

  return (
    <VStack
      alignItems="space-between"
      justifyContent="space-between"
      height="full"
      margin={`0 ${pxToRem(60)}`}
      spacing={0}
    >
      <VStack spacing={pxToRem(24)} alignItems="flex-start">
        <VStack w="full" spacing={pxToRem(12)} alignItems="flex-start">
          <Heading as="p" variant="adminH6">
            {t("chooseClassroomHeading")}
          </Heading>
          <Text variant="adminP2">{t("chooseClassroomInstructions")}*</Text>
          <Box w="full">
            <Dropdown
              aria-labelled-by="classroomsLabel"
              placeholder={t("chooseClassroomPlaceholder")}
              id="classroomId"
              options={classroomSmartSetOptions}
              handleChange={(option) => {
                setSmartSetSettings({
                  group_id: (option as IOption).value,
                });
                setSmartSetReviewPeriod("");
                setSmartSetType(SmartSetType.None);
              }}
              value={classroomSmartSetOptions.find(
                (option) => option.value === smartSetSettings.group_id
              )}
            />
          </Box>
          {classroomSmartSetOptions.some((option) => option.isDisabled) && (
            <HStack spacing={pxToRem(6)}>
              <Text variant="adminP2">{t("classroomsDisabledWarning")}</Text>
              <IconTooltip>{t("classroomsDisabledTooltip")}</IconTooltip>
            </HStack>
          )}
        </VStack>
        <VStack w="full" spacing={pxToRem(12)} alignItems="flex-start">
          <Heading as="p" variant="adminH6">
            {t("chooseTypeHeading")}
          </Heading>

          <PracticeSetTypeCard
            isDisabled={!reviewCapability}
            isActive={smartSetType === SmartSetType.Review}
            heading={t("typeReviewHeading")}
            subheading={t("typeReviewDescription")}
            icon={<SmartSetReviewSVG width={pxToRem(68)} />}
            onClick={() => {
              if (!reviewCapability) return;
              setSmartSetType(SmartSetType.Review);
              setIsDirty(true);
            }}
          />

          <PracticeSetTypeCard
            isDisabled={!standardsInterventionCapability}
            disabledLabel={t("typeStandardsInteventionDisabledLabel")}
            disabledTooltip={t("typeStandardsInteventionDisabledTooltip")}
            isActive={smartSetType === SmartSetType.StandardsIntervention}
            isPremium={true}
            hasNoPremiumAccess={hasNoPremiumAccess}
            heading={t("typeStandardsInterventionHeading")}
            subheading={t("typeStandardsInterventionDescription")}
            icon={<SmartSetStandardsInterventionSVG width={pxToRem(68)} />}
            onClick={() => {
              if (!standardsInterventionCapability) return;
              setSmartSetType(SmartSetType.StandardsIntervention);
              setIsDirty(true);
            }}
          />
        </VStack>
        {smartSetType == SmartSetType.StandardsIntervention && (
          <VStack w="full" spacing={pxToRem(12)} alignItems="flex-start">
            <Heading as="p" variant="adminH6">
              {t("chooseSubjects")}
            </Heading>

            <VStack w="full" alignItems="flex-start">
              {standardsInterventionSubjectOptions.map((subjectOption) => (
                <Checkbox
                  key={subjectOption.id}
                  onChange={(e) => {
                    handleToggleSubject(e.target.checked, subjectOption.id);
                  }}
                  isChecked={
                    !!(smartSetSettings?.subject_ids || []).find(
                      (subjectId) => subjectId === subjectOption.id
                    )
                  }
                >
                  {subjectOption?.subjectName}
                </Checkbox>
              ))}
            </VStack>
          </VStack>
        )}
        {smartSetType == SmartSetType.Review && (
          <VStack w="full" spacing={pxToRem(12)} alignItems="flex-start">
            <Heading as="p" variant="adminH6">
              {t("chooseTimeRange")}
            </Heading>
            <RadioGroup
              onChange={(v) => {
                setSmartSetReviewPeriod(v);
                updateSmartSetSettings({
                  start_time: new Date(
                    new Date().setDate(new Date().getDate() - 30)
                  ),
                  end_time: new Date(),
                });
              }}
              value={smartSetReviewPeriod}
            >
              <Stack direction="row" spacing={5}>
                <Radio value="last30">{t("chooseTimeRangeLast30")}</Radio>
                <Radio value="custom">{t("chooseTimeRangeCustom")}</Radio>
              </Stack>
            </RadioGroup>

            {smartSetReviewPeriod === "custom" && (
              <VStack w="full" spacing={pxToRem(16)} pt={pxToRem(8)}>
                <VStack w="full" spacing={pxToRem(8)} alignItems="flex-start">
                  <HStack spacing={pxToRem(6)}>
                    <Text variant="adminP2">
                      {t("chooseTimeRangeStartDate")}*
                    </Text>
                    <IconTooltip>{t("chooseTimeRangeTooltip")}</IconTooltip>
                  </HStack>
                  <Box w="full">
                    <DatePicker
                      onChange={(startTime: Date) => {
                        updateSmartSetSettings({
                          start_time: startTime,
                        });
                      }}
                      selected={smartSetSettings?.start_time}
                    />
                  </Box>
                </VStack>
                <VStack w="full" spacing={pxToRem(8)} alignItems="flex-start">
                  <HStack spacing={pxToRem(6)}>
                    <Text variant="adminP2">
                      {t("chooseTimeRangeEndDate")}*
                    </Text>
                    <IconTooltip>{t("chooseTimeRangeTooltip")}</IconTooltip>
                  </HStack>
                  <Box w="full">
                    <DatePicker
                      onChange={(endTime: Date) => {
                        updateSmartSetSettings({
                          end_time: endTime,
                        });
                      }}
                      selected={smartSetSettings?.end_time}
                    />
                  </Box>
                </VStack>
              </VStack>
            )}
          </VStack>
        )}
      </VStack>
      <VStack py={pxToRem(60)} spacing={pxToRem(20)} alignItems="flex-start">
        {!isDirty && (
          <>
            {showNoItemsWarning && (
              <Card
                variant="adminCardSolid"
                bgColor="utility.error-light"
                borderColor="utility.error-light"
              >
                <VStack alignItems="flex-start">
                  <HStack spacing={pxToRem(6)}>
                    <Heading as="p" variant="adminH7">
                      {t("noPreviewItemsWarning")}
                    </Heading>
                    <IconTooltip>{t("noPreviewItemsTooltip")}</IconTooltip>
                  </HStack>
                  <Text variant="adminP2">
                    {t("noPreviewItemsDescription")}
                  </Text>
                </VStack>
              </Card>
            )}

            {generateState.isError && (
              <Card
                variant="adminCardSolid"
                bgColor="utility.error-light"
                borderColor="utility.error-light"
              >
                <VStack alignItems="flex-start">
                  <HStack spacing={pxToRem(6)}>
                    <Heading as="p" variant="adminH7">
                      {t("errorHeading")}
                    </Heading>
                  </HStack>
                  <Text variant="adminP2"> {generateState.errorMessage}</Text>
                </VStack>
              </Card>
            )}
          </>
        )}

        <Button
          onClick={_handleGenerate}
          variant="adminButtonFilled"
          isDisabled={!smartSetSettingsValid || isIneligible}
          isLoading={generateState.isLoading}
          rightIcon={
            <Icon icon="arrow_forward_outlined" boxSize={pxToRem(16)} />
          }
        >
          {t("generateButton")}
        </Button>
      </VStack>
    </VStack>
  );
};
