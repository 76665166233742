import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { IPracticeSet, PracticeDataDocumentType } from "../../types";

export interface IRatePracticeSetArgs {
  practiceSetId: string;
  rating: number;
}

export interface IUseRatePracticeSetProps {
  onSuccess?: () => void;
  onError?: () => void;
  onSettled?: () => void;
}

export default function useRatePracticeSet(
  props?: IUseRatePracticeSetProps
): UseMutationResult<unknown, unknown, IRatePracticeSetArgs> {
  const axios = useAxios();
  const queryClient = useQueryClient();

  const ratePracticeSet = async (args: IRatePracticeSetArgs) => {
    const { practiceSetId, rating } = args;

    const response = await axios({
      method: "post",
      url: `/v1/practice-sets/${practiceSetId}/rate`,
      data: {
        rating,
      },
    }).then((r) => r.data as { practice_set: IPracticeSet });

    queryClient.invalidateQueries(["practiceSets", { id: practiceSetId }]);
    queryClient.invalidateQueries(["recommendedPracticeSets"]);
    queryClient.invalidateQueries([
      "searchPracticeData",
      {
        include_shared: false,
        document_type: PracticeDataDocumentType.PracticeSets,
      },
    ]);
    queryClient.invalidateQueries([
      "searchPracticeDataInfinite",
      {
        include_shared: false,
        document_type: PracticeDataDocumentType.PracticeSets,
      },
    ]);

    return response;
  };

  const mutation = useMutation(ratePracticeSet, {
    onSuccess: props?.onSuccess,
    onError: props?.onError,
    onSettled: props?.onSettled,
  });

  return {
    ...mutation,
  };
}
