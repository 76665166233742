import katex from "katex";
import "katex/dist/katex.css";

const regexpNestedSquareBrackets = /\[\\[a-z]{1,}\[.*\]/g;

export const latexContainsNestedSquareBrackets = (latex: string): boolean => {
  return !!latex.match(regexpNestedSquareBrackets);
};

/**
 * Convert a latex string to katex and return the resulting HTML for display
 * @param string latex content
 * @param options options to pass to the katex renderer
 * @returns
 */
export const renderLatexString = (
  string: string,
  options?: Record<string, unknown>
): string => {
  const stripDollars = (stringToStrip: string) => {
    return stringToStrip[0] === "$" && stringToStrip[1] !== "$"
      ? stringToStrip.slice(1, -1)
      : stringToStrip.slice(2, -2);
  };

  const processResult = (result: string) => {
    let resultToProcess = result;

    resultToProcess = resultToProcess.replace(/\\overarc/g, "\\overgroup");

    // Remove LaTeX functions with square brackets from within
    // square brackets.
    resultToProcess = resultToProcess.replace(regexpNestedSquareBrackets, "[]");

    try {
      return katex.renderToString(
        resultToProcess,
        Object.assign({ displayMode: false }, options)
      );
    } catch (e) {
      console.error("Error rendering LaTeX string:", {
        error: e,
        resultToProcess,
      });
      return "";
    }
  };

  const regularExpression =
    // eslint-disable-next-line
    /\$\$[\s\S]+?\$\$|\\\[[\s\S]+?\\\]|\\\([\s\S]+?\\\)|\$[^\$\\]*(?:\\.[^\$\\]*)*\$/g;

  const latexMatch = string.match(regularExpression);

  // Returns list of spans with latex and non-latex strings.
  return processResult(latexMatch ? stripDollars(string) : string);
};

export const latexToHumanReadable = (latex: string): string => {
  const mathml = renderLatexString(latex, { output: "mathml" });

  const parser = new DOMParser();
  const doc = parser.parseFromString(mathml, "application/xml");
  const root = doc.documentElement;

  const traverse = (node: Element, context = ""): string => {
    switch (node.tagName) {
      case "mi":
        if (node.textContent === "∣") {
          if (context === "absOpen") {
            return "";
          } else {
            return "absolute value of";
          }
        }

        if (node.textContent && /^[A-Za-z]$/.test(node.textContent)) {
          return `"${node.textContent}"`;
        }

        return node.textContent ?? "";
      case "mo":
        switch (node.textContent) {
          case "+":
            return "plus";
          case "−":
            return "minus";
          case "*":
          case "×":
          case "⋅":
            return "times";
          case "/":
          case "÷":
            return "divided by";
          case "±":
            return "plus or minus";
          case "%":
            return "percent";
          case "=":
            return "equals";
          case "≠":
            return "does not equal";
          case "≈":
            return "is approximately equal to";
          case ">":
            return "greater than";
          case "<":
            return "less than";
          case "≥":
            return "greater than or equal to";
          case "≤":
            return "less than or equal to";
          case "∩":
            return "intersection";
          case "∪":
            return "union";
          case ":":
            return "such that";
          case "⇒":
            return "implies";
          case "°":
            return "degrees";
          case "∠":
            return "angle";
          case "↔︎":
            return "line segment";
          case "‾":
            return "line";
          case "→":
            return "vector";
          case "⌒":
            return "arc";
          case "△":
            return "triangle";
          case "●":
            return "circle";
          case "≅":
            return "congruent to";
          case "∼":
            return "similar to";
          case "⊥":
            return "perpendicular to";
          case "(":
            return "open parenthesis";
          case ")":
            return "close parenthesis";
          case "[":
            return "open bracket";
          case "]":
            return "close bracket";
          case "{":
            return "open brace";
          case "}":
            return "close brace";
          case "∣":
            if (context === "determinant") {
              return "close parenthesis";
            } else {
              return "determinant";
            }
          case "^":
            return "hat";
          case "_":
            return "sub";
          case "π":
            return "pi";
          case "e":
            return "euler's number";
          case "i":
            return "imaginary unit";
          case "θ":
            return "theta";
          case "∞":
            return "infinity";
          case "μ":
            return "mu";
          case "σ":
            return "sigma";
          case "∫":
            return "integral";
          case "∑":
            return "summation";
          default:
            return node.textContent ?? "";
        }
      case "msub":
        return `${traverse(node.children[0])} sub ${traverse(
          node.children[1]
        )}`;
      case "mtable": {
        const rows = [];
        for (let i = 0; i < node.children.length; i++) {
          const row = [];
          for (let j = 0; j < node.children[i].children.length; j++) {
            row.push(traverse(node.children[i].children[j]));
          }
          rows.push(row.join(" comma "));
        }
        return `open bracket ${rows.join(
          " close bracket comma open bracket "
        )} close bracket`;
      }
      case "mfrac":
        return `(${traverse(node.children[0])}) over (${traverse(
          node.children[1]
        )})`;
      case "msqrt":
        return `square root of ${traverse(node.children[0])}`;
      case "mroot":
        return `${traverse(node.children[1])}-th root of ${traverse(
          node.children[0]
        )}`;
      case "mrow":
        return (
          Array.from(node.children)
            .map((e, i) => {
              let childContext = "";
              if (i !== 0 && e.textContent === "∣") {
                childContext = "absOpen";
              } else if (i > 0 && node.children[i - 1].textContent === "∣") {
                childContext = "determinant";
              }

              return traverse(e, childContext);
            })
            .filter((n) => n)
            .join(" ")
            // commas should not have additional spaces inserted
            .replace(/( , )/g, ",")
        );
      case "msup":
        return `${traverse(node.children[0])} to the power of ${traverse(
          node.children[1]
        )}`;
      case "msubsup":
        if (node.children[0].textContent === "∫") {
          return `integral from ${traverse(node.children[1])} to ${traverse(
            node.children[2]
          )}`;
        } else if (node.children[0].textContent === "∑") {
          return `summation from ${traverse(node.children[1])} to ${traverse(
            node.children[2]
          )}`;
        } else {
          return `${traverse(node.children[0])} sub ${traverse(
            node.children[1]
          )} sup ${traverse(node.children[2])}`;
        }
      case "munder":
        return `${traverse(node.children[0])} from ${traverse(
          node.children[1]
        )}`;
      case "mover":
        if (node.children[1].textContent === "⃗") {
          return `vector ${traverse(node.children[0])}`;
        }

        return `${traverse(node.children[0])} to ${traverse(node.children[1])}`;
      case "munderover":
        if (node.children[0].textContent === "∫") {
          return `integral from ${traverse(node.children[1])} to ${traverse(
            node.children[2]
          )}`;
        } else if (node.children[0].textContent === "∑") {
          return `summation from ${traverse(node.children[1])} to ${traverse(
            node.children[2]
          )}`;
        } else {
          return `${traverse(node.children[0])} under ${traverse(
            node.children[1]
          )} over ${traverse(node.children[2])}`;
        }
      case "mn":
        return node.textContent ?? "";
      case "mtext":
        return (node.textContent ?? "").trim();
      case "annotation":
        return "";
      case "span":
      case "math":
      case "semantics":
        return traverse(node.children[0]);
      default:
        return Array.from(node.children)
          .map((e) => traverse(e))
          .join(" ");
    }
  };

  return traverse(root);
};
