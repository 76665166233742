import {
  IPracticeSetSessionItemMultipleChoiceChoice,
  ISessionSelectedChoice,
  ISessionUser,
  ISessionUsers,
} from "links/lib/types";

//key here is option id
type SelectionUserMap = {
  [key in string]: ISessionUser[];
};

export const getSelectionUserMap = (
  allOptions: IPracticeSetSessionItemMultipleChoiceChoice[],
  users: ISessionUsers,
  selectedChoices?: ISessionSelectedChoice[]
): SelectionUserMap => {
  return allOptions.reduce((selectionUserMap, option) => {
    const optionSelectedInstances = selectedChoices?.filter(
      (choice) => choice.choice_id === option.id
    );
    if (optionSelectedInstances && optionSelectedInstances.length) {
      selectionUserMap[option.id] = optionSelectedInstances
        .map((instance) => users[instance.user_id])
        .filter((u) => !!u) as ISessionUser[];

      return selectionUserMap;
    } else {
      selectionUserMap[option.id] = [];
      return selectionUserMap;
    }
  }, {} as SelectionUserMap);
};
