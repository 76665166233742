import { ColorScheme } from "adminComponents";
import { colorMapping } from "adminComponents/theme/colors";
import { hoverStyles, pxToRem } from "adminComponents/utils";

enum COLORS {
  YELLOW = "primary.golden",
  WHITE = "primary.white",
  TAN = "giant-steps.tan",
}

interface UnknownProps {
  [key: string]: string;
}

const createAdminButtonStyles = (
  filled: boolean,
  { hasLeftIcon, hasRightIcon, size, svgDisabledColor }: UnknownProps,
  color: COLORS = COLORS.YELLOW,
  borderColorScheme: ColorScheme = "primary.golden"
) => ({
  outer: {
    borderRadius: "full",
    bg: filled ? color : "transparent",
    color: "primary.warm-black",
    border: "none",
    boxShadow:
      !filled && `0 0 0 ${pxToRem(2)} inset ${colorMapping[borderColorScheme]}`,
    justifyContent: "center",
    alignItems: "center",
    textStyle: "adminP2",
    fontSize: null,
    fontWeight: null,
    WebkitTapHighlightColor: "transparent",

    _hover: hoverStyles({
      bg: filled ? `${color}-hover` : "transparent",
      boxShadow: `0 0 0 ${pxToRem(2)} inset ${
        colorMapping["primary.golden-hover"]
      }`,

      _disabled: {
        bg: filled ? `${color}-disabled` : "transparent",
        boxShadow: !filled
          ? `0 0 0 ${pxToRem(2)} inset ${colorMapping["primary.golden-light"]}`
          : "none",
      },
    }),

    _disabled: {
      bg: filled ? `${color}-disabled` : "transparent",
      color: "primary.dark-gray",
      opacity: 1,
      cursor: "not-allowed",
      boxShadow: !filled
        ? `0 0 0 ${pxToRem(2)} inset ${colorMapping["primary.golden-light"]}`
        : "none",

      svg: {
        color: svgDisabledColor || "primary.dark-gray",
      },
    },

    _focusVisible: {
      bg: filled ? color : "transparent",
      boxShadow: `0 0 0 ${pxToRem(2)} inset ${colorMapping["utility.focus"]}`,
    },

    _focus: {
      boxShadow: !filled
        ? `0 0 0 ${pxToRem(2)} inset ${colorMapping["primary.golden-hover"]}`
        : "none",
    },

    _active: {
      bg: filled ? `${color}-hover` : "transparent",
    },

    height: () => {
      switch (size) {
        case "sm":
          return pxToRem(36);
        case "md":
          return pxToRem(48);
        case "lg":
          return pxToRem(56);
      }
    },
    padding: () => {
      switch (size) {
        case "xs":
          return `${pxToRem(8)} ${pxToRem(12)}`;
        case "sm":
          return `${pxToRem(16)} ${pxToRem(16)}`;
        case "md":
          return `${pxToRem(22)} ${pxToRem(20)}`;
        case "lg":
          return `${pxToRem(26)} ${pxToRem(28)}`;
      }
    },
    svg: () => {
      const iconSize = () => {
        switch (size) {
          case "sm":
            return pxToRem(16);
          case "md":
            return pxToRem(20);
          case "lg":
            return pxToRem(20);
        }
      };
      return {
        width: iconSize,
        height: iconSize,
      };
    },
  },
  textContainer: {
    paddingLeft: () => {
      if (!hasLeftIcon) return null;
      switch (size) {
        case "sm":
          return pxToRem(4);
        case "md":
          return pxToRem(8);
        case "lg":
          return pxToRem(12);
      }
    },
    paddingRight: () => {
      if (!hasRightIcon) return null;
      switch (size) {
        case "sm":
          return pxToRem(4);
        case "md":
          return pxToRem(8);
        case "lg":
          return pxToRem(12);
      }
    },
  },
});

const createAdminTextButtonStyles = (
  textStyle: string,
  removeUnderline?: boolean,
  textColor?: string
) => ({
  outer: {
    backgroundColor: "transparent",
    padding: 0,
    margin: 0,
    borderStyle: "solid",
    borderWidth: pxToRem(2),
    borderColor: "transparent",
    borderRadius: pxToRem(4),
    color: textColor ? textColor : "utility.link",
    height: "unset",
    textStyle: textStyle,
    textDecoration: removeUnderline ? "none" : "underline",
    minWidth: "unset",
    _hover: hoverStyles({
      color: "utility.link-hover",
    }),
    _focus: {
      boxShadow: "none",
    },
    _focusVisible: {
      borderColor: "utility.focus",
    },
    _active: {
      bg: "transparent",
    },
    // It's necessary to set this to null to allow the font size from
    // the textStyle to take effect
    fontSize: null,
    fontWeight: null,
  },
  textContainer: {
    display: "flex",
    alignItems: "center",
  },
});

export const AdminButton = {
  variants: {
    adminButtonFilled: (props: UnknownProps): Record<string, unknown> =>
      createAdminButtonStyles(true, props, COLORS.YELLOW),
    adminButtonOutlined: (props: UnknownProps): Record<string, unknown> =>
      createAdminButtonStyles(false, props),
    adminButtonOutlinedMuted: (props: UnknownProps): Record<string, unknown> =>
      createAdminButtonStyles(false, props, undefined, "primary.medium-gray"),
    adminTextButtonSmall: (): Record<string, unknown> =>
      createAdminTextButtonStyles("adminLinkSmall"),
    adminTextButtonMedium: (): Record<string, unknown> =>
      createAdminTextButtonStyles("adminLinkMedium"),
    adminTextButtonLarge: (): Record<string, unknown> =>
      createAdminTextButtonStyles("adminLinkLarge"),
    adminTextButtonLargeNoUnderline: (): Record<string, unknown> =>
      createAdminTextButtonStyles("adminLinkLarge", true),
    adminTextButtonExtraLarge: (): Record<string, unknown> =>
      createAdminTextButtonStyles(
        "adminLinkExtraLarge",
        false,
        "primary.warm-black"
      ),
    adminButtonWhite: (props: UnknownProps): Record<string, unknown> =>
      createAdminButtonStyles(true, props, COLORS.WHITE),
    adminButtonTan: (props: UnknownProps): Record<string, unknown> =>
      createAdminButtonStyles(true, props, COLORS.TAN),

    adminInlineIconButton: {
      outer: {
        backgroundColor: "transparent",
        padding: 0,
        height: "unset",
        width: "unset",
        minWidth: "unset",
        borderStyle: "solid",
        borderWidth: pxToRem(2),
        borderColor: "transparent",
        borderRadius: pxToRem(4),
        _hover: hoverStyles({
          backgroundColor: "transparent",
        }),
        _active: {
          backgroundColor: "transparent",
        },
        _focus: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
        _focusVisible: {
          backgroundColor: "transparent",
          borderColor: "utility.focus",
        },
        div: {
          display: "flex",
        },
      },
    },
    adminMathButton: {
      outer: {
        padding: 0,
        height: pxToRem(48),
        width: pxToRem(48),
        backgroundColor: "transparent",
        borderColor: "transparent",
        borderRadius: pxToRem(4),
        borderWidth: pxToRem(2),
        color: "primary.warm-black",
        _active: {
          backgroundColor: "primary.golden-light",
        },
        _focus: {
          boxShadow: "none",
          bgColor: "transparent",
        },
        _focusVisible: {
          borderColor: "utility.focus",
        },
        _hover: hoverStyles({
          bgColor: "primary.golden-light",
          borderColor: "primary.golden-light",
        }),
      },
    },
    adminDropZone: {
      outer: {
        display: "flex",
        flexDir: "column",
        alignItems: "center",
        h: [pxToRem(328), null, pxToRem(360)],
        w: "full",
        cursor: "pointer",
        borderWidth: pxToRem(1),
        borderStyle: "dashed",
        transition: "all ease-in-out .25s",
        color: "primary.warm-black",
        bg: "transparent",
        _hover: hoverStyles({
          borderColor: "primary.warm-black",
        }),
        _active: {
          borderColor: "utility.focus",
          borderWidth: pxToRem(2),
        },
        _focus: {
          boxShadow: "none",
        },
        _focusVisible: {
          borderColor: "utility.focus",
        },
        _disabled: {
          borderColor: "primary.medium-gray",
          _hover: hoverStyles({
            borderColor: "primary.medium-gray",
          }),
        },
      },
    },
    adminLibraryImage: {
      outer: {
        w: "full",
        h: [pxToRem(104), null, pxToRem(136)],
        borderColor: "primary.medium-gray",
        borderWidth: pxToRem(1),
        backgroundOrigin: "border-box",
        bgSize: "cover",
        _hover: hoverStyles({
          borderColor: "primary.golden-hover",
          borderWidth: pxToRem(3),
        }),
        _active: {
          borderColor: "utility.link",
          borderWidth: pxToRem(3),
        },
        _focus: {
          boxShadow: "none",
          borderWidth: pxToRem(3),
          borderColor: "transparent",
        },
        _focusVisible: {
          borderWidth: pxToRem(3),
          borderColor: "utility.focus",
        },
      },
    },
    adminSortableTableHeaderButtonMobile: {
      outer: {
        w: "full",
        bgColor: "white",
        fontSize: null,
        fontWeight: null,
        justifyContent: "flex-start",
        px: pxToRem(18),
        borderStyle: "solid",
        borderWidth: pxToRem(2),
        borderColor: "transparent",
        borderRadius: pxToRem(4),
        _active: { bgColor: "primary.golden-light" },
        _focus: {
          outline: "none",
          boxShadow: "none",
        },
        _focusVisible: {
          borderColor: "utility.focus",
        },
        _hover: hoverStyles({
          bgColor: "primary.golden-light",
        }),
        "&&": {
          borderRadius: "none",
        },
      },
      textContainer: {
        "&[aria-selected=true] p": {
          color: "primary.warm-black",
        },
        p: {
          color: "primary.warm-black",
        },
      },
    },
    adminSeasonMapTicketCounter: ({
      hasLeftIcon,
      hasRightIcon,
      size,
      textStyle,
    }: UnknownProps): Record<string, unknown> => ({
      outer: {
        borderColor: "transparent",
        borderStyle: "solid",
        borderWidth: pxToRem(2),
        borderRadius: "full",
        bg: "whiteAlpha.800",
        color: "primary.warm-black",
        boxShadow: "none",
        justifyContent: "center",
        alignItems: "center",
        textStyle: textStyle ? textStyle : "adminP2",
        // It's necessary to set this to null to allow the font size from
        // the textStyle to take effect
        fontSize: null,
        fontWeight: null,
        _hover: hoverStyles({
          bg: "whiteAlpha.800",
          borderColor: "transparent",
          _disabled: {
            bg: "whiteAlpha.700",
            borderColor: "transparent",
          },
        }),
        _focus: {
          boxShadow: "none",
        },
        _focusVisible: {
          borderColor: "utility.focus",
        },
        _active: {
          bg: "whiteAlpha.800",
          boxShadow: "none",
        },
        padding: () => {
          switch (size) {
            case "sm":
              return `${pxToRem(16)} ${pxToRem(16)}`;
            case "md":
              return `${pxToRem(22)} ${pxToRem(20)}`;
            case "lg":
              return `${pxToRem(26)} ${pxToRem(28)}`;
          }
        },
        svg: () => {
          const iconSize = () => {
            switch (size) {
              case "sm":
                return pxToRem(16);
              case "md":
                return pxToRem(18);
              case "lg":
                return pxToRem(20);
            }
          };
          return {
            width: iconSize,
            height: iconSize,
          };
        },
      },
      textContainer: {
        paddingLeft: () => {
          if (!hasLeftIcon) return null;
          switch (size) {
            case "sm":
              return pxToRem(6);
            case "md":
              return pxToRem(10);
            case "lg":
              return pxToRem(14);
          }
        },
        paddingRight: () => {
          if (!hasRightIcon) return null;
          switch (size) {
            case "sm":
              return pxToRem(6);
            case "md":
              return pxToRem(10);
            case "lg":
              return pxToRem(14);
          }
        },
      },
    }),
    adminGoogleClassroomPickButton: {
      outer: {
        "&&": {
          p: pxToRem(16),
          borderWidth: pxToRem(1),
          borderColor: "primary.medium-gray",
          borderRadius: pxToRem(8),
          bg: "transparent",
          cursor: "pointer",
          _hover: hoverStyles({
            borderWidth: pxToRem(3),
            p: pxToRem(14),
            borderColor: "utility.link",
          }),
          _focus: {
            boxShadow: "none",
          },
          _focusVisible: {
            borderWidth: pxToRem(3),
            p: pxToRem(14),
            borderColor: "utility.focus",
          },
          height: "fit-content",
          justifyContent: "flex-start",
          textAlign: "left",
        },
      },
    },
    adminStudentAccuracy: {
      outer: {
        bg: "transparent",
        borderRadius: pxToRem(20),
        fontWeight: 400,
        w: "full",
        _active: {
          bgColor: "primary.white",
        },
        _focus: { outline: "none", boxShadow: "none" },
        _focusVisible: {
          borderWidth: pxToRem(2),
          borderColor: "utility.focus",
        },
        _hover: hoverStyles({
          bgColor: "primary.warm-white",
        }),
      },
    },
    adminSignInButton: {
      outer: {
        alignItems: "center",
        borderRadius: "full",
        boxShadow: "none",
        fontSize: null,
        fontWeight: null,
        justifyContent: "center",
        textStyle: "adminP2",
        "-webkit-tap-highlight-color": "transparent",
        _focus: { outline: "none", boxShadow: "none" },
        _focusVisible: {
          outlineColor: "utility.focus",
          outlineStyle: "solid",
          outlineWidth: pxToRem(3),
          outlineOffset: "0.1rem",
        },
        padding: `${pxToRem(26)} ${pxToRem(28)}`,
        svg: { width: pxToRem(20), height: pxToRem(20) },
      },
      textContainer: {
        paddingLeft: pxToRem(12),
      },
    },
    adminDropdownButton: {
      outer: {
        borderRadius: pxToRem(8),
        borderWidth: pxToRem(1),
        borderColor: "primary.medium-gray",
        borderStyle: "solid",
        background: "primary.white",
        gap: pxToRem(16),
      },
    },
  },
};
