import {
  Box,
  FormControl,
  FormLabel,
  Input,
  VStack,
  VisuallyHidden,
} from "@chakra-ui/react";
import React, { ChangeEvent, FormEvent, LegacyRef } from "react";

export interface ISearchFormProps {
  onSearchSubmit: (
    e: FormEvent<HTMLFormElement> & FormEvent<HTMLDivElement>
  ) => void;
  initialFocusRef: LegacyRef<HTMLInputElement>;
  content: {
    searchQueryLabel: string;
    searchQueryPlaceholder: string;
  };
  values: {
    query: string;
  };
  onQueryChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const SearchForm: React.FC<ISearchFormProps> = ({
  content,
  onSearchSubmit,
  values,
  onQueryChange,
  initialFocusRef,
}) => {
  return (
    <Box as="form" w="full" onSubmit={onSearchSubmit}>
      <VStack spacing={2}>
        {/* Search Query */}
        <FormControl>
          <VisuallyHidden>
            <FormLabel htmlFor="skillsSearchQuery">
              {content.searchQueryLabel}
            </FormLabel>
          </VisuallyHidden>
          <Input
            id="skillsSearchQuery"
            name="skillsSearchQuery"
            ref={initialFocusRef}
            placeholder={content.searchQueryPlaceholder}
            value={values.query}
            onChange={onQueryChange}
          />
        </FormControl>
      </VStack>
    </Box>
  );
};

export default SearchForm;
