import { Box, Text } from "@chakra-ui/react";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { animated } from "react-spring";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { CoopDrawVariantData } from "links/lib/types";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { useConfirmAnswerButtonTransition } from "sessionComponents/hooks/useConfirmAnswerButtonTransition";
import { useConfirmationStatus } from "sessionComponents/hooks/useConfirmationStatus";
import { ConfirmButton } from "sessionComponents/molecules/ConfirmButton";

const AnimatedBox = animated(Box);

export interface IConfirmAnswerButtonProps {
  studentId: string;
  groupId: string;
  animation?: string;
  disabled?: boolean;
  translationKeyPrefix: string;
  showTextSubmissionLabel?: boolean;
  userSubmittedTextChoice?: boolean;
  isTextSubmissionPhase?: boolean;
  isDrawVoting?: boolean;
  drawVariantData?: CoopDrawVariantData;
  showClassificationLabel?: boolean;
  handleConfirm?: () => void;
}

export const ConfirmAnswerButton: React.FC<IConfirmAnswerButtonProps> = ({
  studentId,
  groupId,
  animation,
  disabled,
  translationKeyPrefix,
  showTextSubmissionLabel,
  userSubmittedTextChoice,
  isTextSubmissionPhase,
  isDrawVoting,
  drawVariantData,
  showClassificationLabel,
  handleConfirm,
}) => {
  const {
    match: { buttonHeight, fontSize },
  } = useBreakpoints();

  const { t } = useTranslation("session", { useSuspense: false });

  const { isConfirmed, isAllGroupConfirmed } = useConfirmationStatus({
    studentId,
    groupId,
  });

  const userHasVoted = useMemo(
    () =>
      !!drawVariantData?.coop_draw?.votes.find(
        (vote) => vote.user_id === studentId
      ),
    [drawVariantData?.coop_draw?.votes, studentId]
  );

  const { actionsTransition } = useConfirmAnswerButtonTransition({
    isAllGroupConfirmed,
    isConfirmed,
    userSubmittedTextChoice,
    isTextSubmissionPhase,
    userHasVoted,
    isDrawVoting,
  });

  let prefix = translationKeyPrefix;
  if (isDrawVoting) prefix = prefix + "Voting";

  const roundVariantsKeyPrefix = `roundVariants.${prefix}`;

  const getButtonText = () => {
    let disabledMsg = t(`${roundVariantsKeyPrefix}.confirmButtonDisabled`);
    if (showTextSubmissionLabel)
      disabledMsg = t(`${roundVariantsKeyPrefix}.textSubmitLabel`);
    if (showClassificationLabel)
      disabledMsg = t("classificationResponseGroup.confirmButtonText");

    return disabled
      ? disabledMsg
      : t(`${roundVariantsKeyPrefix}.confirmButtonEnabled`);
  };

  const getWaitingText = (action: string) =>
    action === "class"
      ? t("round.waitingForClassPrompt")
      : t("round.waitingForGroupPrompt");

  const onEnterPress = useCallback(
    (e: KeyboardEvent) => {
      const keyCode = e.code ? e.code : e.key;
      if (keyCode !== "Enter" || disabled || isConfirmed) return;
      handleConfirm?.();
    },
    [isConfirmed, disabled, handleConfirm]
  );

  useEffect(() => {
    window.addEventListener("keypress", onEnterPress);

    return () => {
      window.removeEventListener("keypress", onEnterPress);
    };
  }, [onEnterPress]);

  return (
    <>
      {actionsTransition(({ opacity, scale }, action) => {
        return (
          <AnimatedBox
            style={{ opacity, transform: scale.to((s) => `scale(${s})`) }}
            w="full"
            textAlign="center"
          >
            {action === "confirm" || action === "confirm-choice" ? (
              <ConfirmButton
                disabled={disabled}
                variant="buttonFilled"
                height={pxToRem(buttonHeight)}
                animation={animation}
                w="full"
                confirmedWhenChanged={[isConfirmed]}
                type="submit"
                handleConfirmClick={handleConfirm}
                fontSize={pxToRem(fontSize)}
              >
                {getButtonText()}
              </ConfirmButton>
            ) : (
              <Text fontSize={pxToRem(fontSize)} textStyle="gameTextWeighted">
                {getWaitingText(action)}
              </Text>
            )}
          </AnimatedBox>
        );
      })}
    </>
  );
};
