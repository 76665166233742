import { Box, HStack, VStack, useDisclosure } from "@chakra-ui/react";
import Papa from "papaparse";
import React, { useState } from "react";
import { useCopyToClipboard } from "react-use";

import { Button } from "adminComponents/atoms/Button";
import { Text } from "adminComponents/atoms/Text";
import { AdminFlyout } from "adminComponents/molecules/Flyout";
import { pxToRem } from "adminComponents/utils";
import { useShowToast } from "adminComponents/utils/toast";
import { ISkill } from "links/lib/types";
import SkillEditor from "screens/InternalTools/Skills/components/SkillEditor";

import { IParseSkillsReslt } from "../utils/parseSkills";
import { SkillCard } from "./SkillCard";

export interface IProps {
  skills?: IParseSkillsReslt;
  isOpen: boolean;
  onClose: () => void;
}

export const SkillsFlyout: React.FC<IProps> = ({ skills, isOpen, onClose }) => {
  const {
    isOpen: skillEditorIsOpen,
    onClose: skillEditorOnClose,
    onOpen: skillEditorOnOpen,
  } = useDisclosure();
  const [currentSkill, setCurrentSkill] = useState<ISkill>();
  const [savedSkills, setSavedSkills] = useState<ISkill[]>([]);
  const [, copyToClipboard] = useCopyToClipboard();
  const showToast = useShowToast();

  const getCSV = ({ includeHeader = true }) => {
    const skillsWithSaved =
      skills?.skills?.map((skill) => {
        const savedSkill = savedSkills.find(
          (savedSkill) =>
            savedSkill.code.toLowerCase() === skill.code.toLowerCase()
        );

        return savedSkill || skill;
      }) || [];

    return Papa.unparse(
      skillsWithSaved.map((skill) => {
        const csvRow: { [key: string]: string } = {
          id: skill.id || "",
          code: skill.code || "",
          description: skill.description || "",
          standard_ids: skill.standards
            ?.map((standard) => standard?.id)
            .join(", "),
          standard_labels: skill.standards
            ?.map((standard) => standard?.label)
            .join(", "),
          preceeding_skill_ids: skill.preceding_skills
            ?.map((skill) => skill.id)
            .join(", "),
          preceeding_skill_codes: skill.preceding_skills
            ?.map((skill) => skill.code)
            .join(", "),
        };

        return csvRow;
      }),
      { header: includeHeader }
    );
  };

  const handleDownloadSkillsCSV = () => {
    const csv = getCSV({ includeHeader: true });

    const link = document.createElement("a");
    link.href = `data:text/csv;charset=utf-8,${csv}`;
    link.setAttribute("download", `skills_${new Date().getTime()}.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const copy = () => {
    copyToClipboard(getCSV({ includeHeader: false }));
    showToast("Skills Copied");
  };

  return (
    <>
      <AdminFlyout isOpen={isOpen} onClose={onClose} title="Skills" size="xl">
        {(!skills?.errors ||
          (skills?.errors && skills?.errors?.length < 1)) && (
          <HStack p={pxToRem(16)} spacing={pxToRem(16)}>
            <Button
              variant="adminButtonFilled"
              onClick={handleDownloadSkillsCSV}
            >
              Download Skills CSV
            </Button>
            <Button variant="adminButtonFilled" onClick={copy}>
              Copy Skills
            </Button>
          </HStack>
        )}
        <Box p={pxToRem(16)}>
          {skills && skills?.errors?.length > 0 && (
            <VStack>
              <Text>Errors:</Text>
              {skills?.errors.map((error) => (
                <Text color="red" key={error}>
                  {error}
                </Text>
              ))}
            </VStack>
          )}
          <VStack>
            {skills?.skills?.map((skill) => {
              const alreadysavedSkill = savedSkills.find(
                (savedSkill) =>
                  savedSkill.code.toLowerCase() === skill.code.toLowerCase()
              );

              if (alreadysavedSkill) {
                skill = alreadysavedSkill;
              }

              return (
                <SkillCard
                  onClick={() => {
                    setCurrentSkill(skill);
                    skillEditorOnOpen();
                  }}
                  key={skill.code}
                  skill={skill}
                />
              );
            })}
          </VStack>
        </Box>
      </AdminFlyout>
      <AdminFlyout
        isOpen={skillEditorIsOpen}
        onClose={skillEditorOnClose}
        size="xl"
        title="Skill Editor"
      >
        <SkillEditor
          skill={currentSkill}
          onSave={(skill) => {
            const newSavedSkills = savedSkills.filter(
              (savedSkill) => savedSkill.id !== skill.id
            );
            newSavedSkills.push(skill);
            setSavedSkills(newSavedSkills);
            setCurrentSkill(undefined);
            skillEditorOnClose();
          }}
        />
      </AdminFlyout>
    </>
  );
};
