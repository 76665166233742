import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { ISkill } from "../../types";

export interface IMutateSkillOpts {
  skill: ISkill;
}

export interface IMutateSkillResponse {
  skill: ISkill;
}

/**
 * Mutates single skill
 * @returns
 */
export default function useMutateSkill(): UseMutationResult<
  IMutateSkillResponse,
  unknown,
  IMutateSkillOpts
> {
  const axios = useAxios();
  const queryClient = useQueryClient();

  const mutateSkill = async (
    args: IMutateSkillOpts
  ): Promise<IMutateSkillResponse> => {
    const { skill } = args;
    const isUpdate = parseInt(skill.id, 10) > 0;
    const response = await axios({
      method: isUpdate ? "put" : "post",
      url: `/v1/skills${isUpdate ? `/${skill.id}` : ""}`,
      data: {
        skill,
      },
    }).then((r) => r.data as IMutateSkillResponse);

    queryClient.invalidateQueries(["skills", { id: skill.id }]);
    queryClient.setQueryData(["skills", { id: response.skill.id }], {
      skill: response.skill,
    });

    return response;
  };

  const mutation = useMutation(mutateSkill);

  return {
    ...mutation,
  };
}
