import { Tooltip } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

const CertifiedTooltip: React.FC = ({ children }) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  return (
    <Tooltip
      label={t("common.certified")}
      aria-label={t("common.certified")}
      openDelay={500}
      variant="adminDark"
    >
      {children}
    </Tooltip>
  );
};

export { CertifiedTooltip };
