import EventEmitter from "events";
import React, { createContext, useContext, useMemo } from "react";

export const SessionEventsContext = createContext<EventEmitter | undefined>(
  undefined
);

export interface ISessionEventsProviderProps {
  wsEvents: EventEmitter;
}

export const SessionEventsProvider: React.FC<ISessionEventsProviderProps> = ({
  children,
  wsEvents,
}) => {
  const value = useMemo(() => wsEvents, [wsEvents]);
  return (
    <SessionEventsContext.Provider value={value}>
      {children}
    </SessionEventsContext.Provider>
  );
};

export const useSessionEvents = (): EventEmitter | undefined => {
  return useContext(SessionEventsContext);
};
