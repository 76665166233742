import { pxToRem } from "adminComponents/utils/pxToRem";

export const AdminGoalsProgressBar = {
  baseStyle: ({
    hasIcon,
    accuracyGoal,
  }: {
    hasIcon?: boolean;
    accuracyGoal?: number;
  }): Record<string, unknown> => ({
    titleContainer: {
      justifyContent: "space-between",
      alignItems: "center",
    },
    description: {
      marginTop: pxToRem(4),
      marginBottom: hasIcon ? pxToRem(8) : pxToRem(16),
    },
    progressBar: {
      position: "relative",
      marginTop: hasIcon ? pxToRem(8) : pxToRem(12),
    },
    stats: {
      color: "primary.warm-black",
      marginTop: hasIcon ? pxToRem(2) : pxToRem(10),
    },
    accuracyStats: {
      color: "primary.warm-black",
      marginTop: pxToRem(16),
    },
    accuracy: {
      color: "primary.warm-black",
    },
    remainingDays: {
      color: "utility.error",
      marginRight: pxToRem(6),
      textAlign: "right",
    },
    goal: {
      bg: "primary.white",
      width: pxToRem(3),
      height: "100%",
      position: "absolute",
      top: 0,
      left: `${accuracyGoal}%`,
    },
    divider: {
      marginLeft: pxToRem(8),
      marginRight: pxToRem(8),
    },
  }),
};
