import { pxToRem } from "adminComponents/utils/pxToRem";

export const AdminSessionGroupsModal = {
  baseStyle: ({
    studentNumber,
    isMobile,
    selected,
    showErrors,
    onSelect,
  }: {
    studentNumber?: number;
    isMobile?: boolean;
    selected?: boolean;
    showErrors?: boolean;
    onSelect?: () => void;
  }): Record<string, unknown> => ({
    container: {
      h: isMobile ? undefined : "75vh",
      w: "100%",
      border: `${pxToRem(2)} solid`,
      borderColor: "primary.tan",
      borderRadius: "xl",
    },
    groupsContainer: {
      h: isMobile ? "fit-content" : "100%",
      w: isMobile ? "100%" : "65%",
      borderRight: isMobile ? undefined : `${pxToRem(2)} solid`,
      borderColor: "primary.tan",
      overflowY: isMobile ? undefined : "hidden",
      p: pxToRem(15),
    },
    headerCtas: {
      gap: pxToRem(10),
      justifyContent: "flex-end",
      w: "fit-content",
    },
    sessionGroup: {
      borderRadius: "xl",
      w: isMobile ? "fit-content" : "100%",
      minW: pxToRem(125),
      cursor: onSelect ? "pointer" : undefined,
      border: `${pxToRem(2)} solid`,
      borderColor: selected ? "primary.golden" : "primary.tan",
      background: selected ? "primary.warm-white" : undefined,
    },
    sessionGroups: {
      paddingY: pxToRem(15),
      paddingX: isMobile ? pxToRem(5) : pxToRem(15),
      w: "100%",
      maxW: "100%",
      h: "90%",
      maxH: "90%",
      flexDirection: "column",
      gap: pxToRem(10),
      overflowY: "scroll",
    },
    student: {
      borderRadius: "xl",
      border: `${pxToRem(2)} ${studentNumber ? "dashed" : "solid"}`,
      borderColor:
        !!showErrors && (studentNumber ?? 3) < 3
          ? "utility.error"
          : "primary.light-gray",
      paddingX: pxToRem(10),
      paddingY: pxToRem(2),
      background:
        !!showErrors && (studentNumber ?? 3) < 3
          ? "utility.error-light"
          : "primary.white",
      opacity: studentNumber ? 0.5 : 1,
    },
    studentOption: {
      borderRadius: "xl",
      border: `${pxToRem(2)} solid`,
      borderColor: "primary.tan",
      p: pxToRem(15),
      gap: pxToRem(10),
      w: "100%",
      background: "primary.white",
      justifyContent: "space-between",
    },
    studentOptions: {
      h: isMobile ? undefined : "100%",
      w: "100%",
      overflow: "auto",
      flexDirection: "column",
      gap: pxToRem(10),
    },
    studentsContainer: {
      p: pxToRem(15),
      h: isMobile ? undefined : "100%",
      w: isMobile ? "100%" : "35%",
      overflow: isMobile ? "scroll" : "hidden",
      background: "primary.warm-white",
      flexDirection: "column",
      gap: pxToRem(10),
    },
    clampedText: {
      fontSize: "1rem",
      fontWeight: 400,
      color:
        !!showErrors && (studentNumber ?? 3) < 3 ? "utility.error" : undefined,
    },
  }),
};
