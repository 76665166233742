import { Box, Flex, SimpleGrid, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { ISessionUser, IUser, SessionConnectionStatus } from "links/lib/types";
import { OnlineStatusCircle } from "sessionComponents/atoms/OnlineStatusCircle";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";

import { useDetectOrientation } from "../../theme/hooks/useDetectOrientation";
import { toStudentList } from "./utils";

export interface IStudentListProps {
  onlineStudents: ISessionUser[];
  classroomStudents: IUser[];
}

export interface ListStudent {
  name: string;
  status: SessionConnectionStatus;
  id: string;
  isGuest: boolean;
}

export const StudentList: React.FC<IStudentListProps> = ({
  onlineStudents,
  classroomStudents,
}) => {
  const { match: currentBreakpoints } = useBreakpoints();
  const { isLandscape } = useDetectOrientation();
  const students = toStudentList(onlineStudents, classroomStudents);
  const { t } = useTranslation("session", {
    useSuspense: false,
    keyPrefix: "connectionStatus",
  });
  return (
    <SimpleGrid
      columns={isLandscape ? 3 : 1}
      paddingBottom={pxToRem(currentBreakpoints.padding)}
      spacing={pxToRem(currentBreakpoints.margin)}
    >
      {students.map((student) => (
        <Flex key={`studentlist-${student.id}}`} alignItems="center">
          <Box marginRight={pxToRem(currentBreakpoints.margin * 0.75)}>
            <OnlineStatusCircle
              size={currentBreakpoints.fontSize}
              status={student.status}
            />
          </Box>
          <Text
            textStyle="gameText"
            fontSize={currentBreakpoints.fontSize}
            color={
              student.status === SessionConnectionStatus.Disconnected
                ? "utility.error"
                : "primary.black"
            }
          >
            {student.name}
          </Text>
          {student.isGuest && (
            <Text
              textStyle="gameText"
              fontSize={currentBreakpoints.fontSize}
              color="primary.dark-gray"
              marginLeft={pxToRem(5)}
            >
              {`(${t("guest")})`}
            </Text>
          )}
          {student.status === SessionConnectionStatus.Disconnected && (
            <Text
              textStyle="gameText"
              fontSize={currentBreakpoints.fontSize}
              color="primary.dark-gray"
              marginLeft={pxToRem(5)}
            >
              {`(${t("disconnected")})`}
            </Text>
          )}
        </Flex>
      ))}
    </SimpleGrid>
  );
};
