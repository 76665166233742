import { Box, Flex, Image, PlacementWithLogical, Text } from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { useShortName } from "links/lib/hooks/useShortName";
import { IGameAssetColorScheme, ISessionUser } from "links/lib/types";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";

import { AnimatedCheckIcon } from "../AnimatedCheckIcon";
import { Tooltip } from "../Tooltip";
import pendingSvg from "./resource/pending.svg";

interface IGroupTokenProps {
  colorScheme: IGameAssetColorScheme;
  inverse?: boolean;
  imageUrl: string;
  name: string;
  pending?: boolean;
  confirmed?: boolean;
  hideTooltip?: boolean;
  tooltipPlacement?: PlacementWithLogical;
  groupMembers?: ISessionUser[];
}

export const GroupToken: React.FC<IGroupTokenProps> = ({
  colorScheme,
  inverse,
  imageUrl,
  name,
  pending,
  confirmed,
  hideTooltip = false,
  tooltipPlacement,
  groupMembers,
}) => {
  const [showInitials, setShowInitials] = useState(false);
  const { match: currentBreakpoints } = useBreakpoints();
  const groupTokenSize = currentBreakpoints.buttonHeight;
  const groupMemberNames = useMemo(
    () => groupMembers?.map((user) => user.name) || [],
    [groupMembers]
  );
  const { t } = useTranslation("session", {
    keyPrefix: "bigBoard",
    useSuspense: false,
  });

  return (
    <Tooltip
      isDisabled={hideTooltip}
      placement={tooltipPlacement || "auto"}
      tooltipContent={
        <TeamTooltipContent groupName={name} userNames={groupMemberNames} />
      }
    >
      <Box
        boxSize={pxToRem(groupTokenSize)}
        borderWidth={pxToRem(currentBreakpoints.borderWidth)}
        borderRadius="full"
        borderColor="transparent"
        position="relative"
      >
        {confirmed && (
          <Box
            boxSize="full"
            borderRadius="full"
            position="absolute"
            top="0"
            left="0"
            zIndex={2}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box
              boxSize="full"
              borderRadius="full"
              bgColor="primary.white"
              opacity={0.9}
              position="absolute"
              top="0"
              left="0"
              zIndex={-1}
            />
            <AnimatedCheckIcon
              color="utility.success"
              maxWidth="60%"
              checked={confirmed}
            />
          </Box>
        )}
        <Box
          borderWidth={pxToRem(currentBreakpoints.borderWidth)}
          borderColor={
            inverse ? colorScheme.primaryColor : colorScheme.secondaryColor
          }
          bgColor={
            inverse ? colorScheme.secondaryColor : colorScheme.primaryColor
          }
          color="primary.white"
          display="flex"
          justifyContent="center"
          alignItems="center"
          boxSize="full"
          borderRadius="full"
        >
          {showInitials ? (
            <Text
              color="white"
              textStyle="gameDisplay"
              size={pxToRem(currentBreakpoints.fontSize)}
            >
              {name.charAt(0).toUpperCase()}
            </Text>
          ) : (
            <Image
              boxSize={pxToRem(currentBreakpoints.iconSize)}
              fill="white"
              draggable="false"
              pointerEvents="none"
              src={imageUrl}
              alt={t("teamToken", { teamName: name })}
              onError={() => setShowInitials(true)}
            />
          )}

          {pending && (
            <Image
              boxSize={pxToRem(groupTokenSize * 0.45)}
              fill="white"
              draggable="false"
              pointerEvents="none"
              src={pendingSvg}
              alt={name}
              onError={() => setShowInitials(true)}
              position="absolute"
              bottom={pxToRem(-10)}
              right={pxToRem(-10)}
            />
          )}
        </Box>
      </Box>
    </Tooltip>
  );
};

const TeamTooltipContent = ({
  groupName,
  userNames,
}: {
  groupName: string;
  userNames: string[];
}) => {
  const {
    match: { padding },
  } = useBreakpoints();
  return (
    <Flex
      flexDir="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      paddingY={pxToRem(padding / 4)}
      paddingX={pxToRem(padding / 2)}
    >
      <Text textStyle="gameText">{groupName}</Text>
      {userNames.map((name) => (
        <TooltipName name={name} key={name} />
      ))}
    </Flex>
  );
};

const TooltipName = ({ name }: { name: string }) => {
  const formattedName = useShortName(name);

  return (
    <Text textStyle="gameText" fontWeight={400} color="primary.golden">
      {formattedName}
    </Text>
  );
};
