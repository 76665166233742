import { Box, HStack, useMultiStyleConfig } from "@chakra-ui/react";
import lottie, { AnimationItem } from "lottie-web/build/player/lottie_light";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useMount, useUnmount } from "react-use";

import { Text } from "adminComponents/atoms/Text";
import { generateStudentName, pxToRem } from "adminComponents/utils";
import { SocialEvent, SocialEventType } from "links/lib/types";

import { Avatar } from "../Avatar";
import animationData from "./resource/high-five-animation.json";

const animationDataString = JSON.stringify(animationData);

export interface IProps {
  socialEvent: SocialEvent;
  handleClick: (socialEvent: SocialEvent) => void;
}

export const HighFive: React.FC<IProps> = ({ socialEvent, handleClick }) => {
  const { t } = useTranslation("admin", {
    keyPrefix: "highFivesCard.social",
    useSuspense: false,
  });

  const playerRef = useRef<HTMLDivElement | null>(null);
  const animRef = useRef<AnimationItem | null>(null);

  useMount(() => {
    if (!playerRef.current) return;

    animRef.current = lottie.loadAnimation({
      container: playerRef.current,
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: JSON.parse(animationDataString),
    });

    if (is_reacted) {
      // This is a pretty large, arbitrary number to make the animation play to
      // the end. The `totalFrames` property doesn't seem to work, hence this hack
      animRef.current.goToAndStop(1000);
    }
  });

  useUnmount(() => {
    animRef.current?.stop();
    animRef.current?.destroy();
  });

  const handleHighFiveClick = () => {
    if (is_reacted) return;

    if (!animRef.current?.isLoaded) {
      console.warn("Could not play high five animation - animation not loaded");
      return;
    }

    animRef.current?.stop();
    animRef.current?.goToAndPlay(0);

    handleClick(socialEvent);
  };

  const {
    event_type,
    given_name,
    family_name,
    student_nickname,
    group_name,
    profile_image_url,
    avatar_item_name,
    is_reacted,
    season_location_name,
    season_zone_name,
  } = socialEvent;

  const name = generateStudentName({
    given_name,
    family_name,
    student_nickname,
  }).primary;

  const [first, last] = name.split(" ");
  const abbreviatedName = `${first} ${last ? `${last[0]}.` : ""}`.trim();

  const getSocialEventActionText = (eventType: SocialEventType): string => {
    let actionText = "";
    switch (eventType) {
      case SocialEventType.RareItemAcquired:
        actionText = t("eventTextRareItemAcquired", {
          itemName: avatar_item_name,
          userName: abbreviatedName,
        });
        break;
      case SocialEventType.CompletedDailyChallenge:
        actionText = t("eventTextCompletedDailyChallenge", {
          userName: abbreviatedName,
        });
        break;
      case SocialEventType.CompletedWeeklyChallenge:
        actionText = t("eventTextCompletedWeeklyChallenge", {
          userName: abbreviatedName,
        });
        break;
      case SocialEventType.CompletedMonthlyChallenge:
        actionText = t("eventTextCompletedMonthlyChallenge", {
          userName: abbreviatedName,
        });
        break;
      case SocialEventType.StudentAddedToClassroom:
        actionText = t("eventTextStudentAddedToClassroom", {
          classroomName: group_name,
          userName: abbreviatedName,
        });
        break;
      case SocialEventType.TeamWonClassroomSession:
        actionText = t("eventTextTeamWonClassroomSession", {
          classroomName: group_name,
          userName: abbreviatedName,
        });
        break;
      case SocialEventType.CompletedAllDailyRemixes:
        actionText = t("eventTextCompletedAllDailyRemixes", {
          userName: abbreviatedName,
        });
        break;
      case SocialEventType.LeveledUp:
        actionText = t("eventTextLeveledUp", {
          userName: abbreviatedName,
        });
        break;
      case SocialEventType.UnlockedAllSeasonLocationItems:
        actionText = t("eventTextUnlockedAllSeasonLocationItems", {
          seasonLocationName: season_location_name,
          userName: abbreviatedName,
        });
        break;
      case SocialEventType.UnlockedSeasonZone:
        actionText = t("eventTextUnlockedSeasonZone", {
          seasonZoneName: season_zone_name,
          userName: abbreviatedName,
        });
        break;
      default:
        actionText = eventType;
    }
    return actionText;
  };

  const styles = useMultiStyleConfig("AdminHighFive", {});

  return (
    <HStack w="full" justifyContent={"space-between"}>
      <HStack>
        <Avatar name={name} src={profile_image_url} boxSize={pxToRem(36)} />
        <Text sx={styles.description} variant="adminP2">
          {getSocialEventActionText(event_type)}
        </Text>
      </HStack>

      <Box
        boxSize={pxToRem(50)}
        minW={pxToRem(50)}
        cursor={is_reacted ? "" : "pointer"}
        opacity={is_reacted ? 0.5 : 1}
        onClick={handleHighFiveClick}
        ref={playerRef}
        marginTop={pxToRem(6)}
      />
    </HStack>
  );
};
