import { Editor, Transforms } from "slate";

export const withInlines = (editor: Editor): Editor => {
  const { isInline } = editor;

  editor.isInline = (element) =>
    ("type" in element && element.type === "latex") || isInline(element);

  return editor;
};

export const withVoids = (editor: Editor): Editor => {
  const { isVoid } = editor;

  editor.isVoid = (element) =>
    ("type" in element && element.type === "latex") || isVoid(element);

  return editor;
};

/**
 * Adds the ability to force all paragraph nodes to display as inline
 * @param editor
 * @param forceInline
 * @returns
 */
export const withEnforcedInline = (
  editor: Editor,
  forceInline: boolean
): Editor => {
  const { normalizeNode } = editor;

  editor.normalizeNode = (entry) => {
    if (forceInline) {
      const path = entry[1];

      if (path.length === 0) {
        if (editor.children.length > 1) {
          Transforms.mergeNodes(editor);
        }
      }
    }

    // Fall back to the original `normalizeNode` to enforce other constraints.
    return normalizeNode(entry);
  };

  return editor;
};

export const withCharacterLimit = (editor: Editor, limit = 0): Editor => {
  const { normalizeNode } = editor;

  editor.normalizeNode = (entry) => {
    const characterCount = Editor.string(editor, [], { voids: false }).length;

    if (limit && characterCount > limit) {
      Transforms.delete(editor, {
        unit: "character",
        distance: characterCount - limit,
        reverse: true,
      });
    }

    return normalizeNode(entry);
  };

  return editor;
};
