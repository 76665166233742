import { Button, SimpleGrid, VisuallyHidden, useToken } from "@chakra-ui/react";
import React from "react";

import { pxToRem } from "adminComponents/utils/pxToRem";

export interface IQuickColorPickerProps {
  // array of hex codes
  colorOptions: Array<string>;
  onColorSelect?: (color: string) => void;
  selectedColor?: string;
}

const QuickColorPicker: React.FC<IQuickColorPickerProps> = ({
  colorOptions,
  onColorSelect,
  selectedColor,
}) => {
  const buttonSize = [pxToRem(40), pxToRem(36), pxToRem(32), pxToRem(28)];
  const goldenColor = useToken("colors", "primary.golden");
  const goldenHoverColor = useToken("colors", "primary.golden-hover");
  const hoverBoxShadow = `0 0 0 ${pxToRem(3)} ${goldenHoverColor}`;
  const selectedBoxShadow = `0 0 0 ${pxToRem(3)} ${goldenColor}`;
  const listSpacing = [pxToRem(8), pxToRem(6), pxToRem(4)];

  return (
    <SimpleGrid spacing={listSpacing} minChildWidth={buttonSize}>
      {colorOptions.map((color: string) => (
        <Button
          key={color}
          w={buttonSize}
          h={buttonSize}
          padding={0}
          borderRadius="100%"
          bg={color}
          minW="unset"
          minH="unset"
          _hover={{
            boxShadow: hoverBoxShadow,
          }}
          _focus={{}}
          _focusVisible={{ boxShadow: "var(--chakra-shadows-outline)" }}
          _active={{}}
          boxShadow={color === selectedColor ? selectedBoxShadow : undefined}
          onClick={() => onColorSelect?.(color)}
        >
          <VisuallyHidden>{color}</VisuallyHidden>
        </Button>
      ))}
    </SimpleGrid>
  );
};

export { QuickColorPicker };
