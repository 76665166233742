import { Box, Flex, VStack, useMultiStyleConfig } from "@chakra-ui/react";
import React from "react";

import { Card } from "adminComponents/atoms/Card";
import { pxToRem } from "adminComponents/utils";

export const BlankStatCard: React.FC = () => {
  const styles = useMultiStyleConfig("AdminBlankStatCard", {});

  return (
    <Card
      noPadding
      borderColor="primary.light-gray"
      backgroundColor="primary.white"
      variant="adminCardSmallBorder"
      sx={styles.card}
      w="100%"
    >
      <Flex sx={styles.body} alignItems="center" h="100%">
        <Box
          borderRadius="full"
          boxSize={pxToRem(90)}
          backgroundColor="primary.light-gray"
        />
        <VStack sx={styles.content} w="50%" h="100%" align="start">
          <Box
            borderRadius="lg"
            backgroundColor="primary.light-gray"
            w="60%"
            h={pxToRem(20)}
          />
          <Box
            borderRadius="lg"
            backgroundColor="primary.light-gray"
            w="100%"
            h={pxToRem(20)}
          />
          <Box
            borderRadius="lg"
            backgroundColor="primary.golden-light"
            w="80%"
            h={pxToRem(20)}
          />
        </VStack>
      </Flex>
    </Card>
  );
};
