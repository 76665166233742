import React from "react";

import { useSessionActions } from "links/lib/contexts/sessionActions";
import {
  CoopDragsortVariantData,
  IPracticeSetSessionItem,
  ISessionDraggableSortItem,
  ISessionDraggableSortItemZone,
  ISessionUser,
  PracticeSessionItemVariantType,
  QuestionType,
  VariantData,
} from "links/lib/types";
import {
  ClassificationQuestionGroup,
  Zone,
} from "sessionComponents/molecules/ClassificationQuestionGroup";
import { DiagramQuestionBox } from "sessionComponents/molecules/DiagramQuestionBox";
import { QuestionBox } from "sessionComponents/molecules/QuestionBox";
import { getQuestionPrompt } from "sessionComponents/utils/getQuestionPrompt";

interface IQuestionBoxRendererProps {
  variant: PracticeSessionItemVariantType;
  practice_set_session_item: IPracticeSetSessionItem;
  variantData: VariantData;
  instructions: string;
  dragsortItems: ISessionDraggableSortItem[];
  groupUsers: ISessionUser[];
}

export const QuestionBoxRenderer: React.FC<IQuestionBoxRendererProps> = ({
  variant,
  practice_set_session_item,
  variantData,
  instructions,
  dragsortItems,
  groupUsers,
}) => {
  const { setSortChoiceZone } = useSessionActions();
  const dragsortVariantData = variantData as CoopDragsortVariantData;

  const getDragsortZoneData = (): ISessionDraggableSortItemZone[] | Zone[] => {
    const zoneData = dragsortVariantData.coop_dragsort.zones;
    if (practice_set_session_item.question_type === QuestionType.Diagram) {
      return zoneData;
    }

    // for classification type, map items to their respective zones
    return zoneData.map((zone) => ({
      items: dragsortItems.filter((item) => item.zone_id === zone.id),
      zoneId: zone.id,
      title: zone.label,
    }));
  };
  switch (variant) {
    case PracticeSessionItemVariantType.CoopChecks:
    case PracticeSessionItemVariantType.CoopRadios:
    case PracticeSessionItemVariantType.CoopTextMatch:
    case PracticeSessionItemVariantType.CoopTextMatchNumeric:
    case PracticeSessionItemVariantType.CoopDraw:
      return (
        <QuestionBox
          prompt={getQuestionPrompt(
            practice_set_session_item.question_type,
            practice_set_session_item
          )}
          imageAltText={practice_set_session_item.image_alt_text}
          imageUrl={practice_set_session_item.image_url}
          instructions={instructions}
          videoUrl={practice_set_session_item.video_url}
          audioUrl={practice_set_session_item.audio_url}
        />
      );
    case PracticeSessionItemVariantType.CoopDragsort:
      return practice_set_session_item.question_type ===
        QuestionType.Diagram ? (
        <DiagramQuestionBox
          imageUrl={practice_set_session_item.image_url || ""}
          imageAlt={practice_set_session_item.image_alt_text}
          dragsortItems={dragsortItems}
          dropzones={getDragsortZoneData() as ISessionDraggableSortItemZone[]}
          prompt={getQuestionPrompt(
            practice_set_session_item.question_type,
            practice_set_session_item
          )}
        />
      ) : (
        <ClassificationQuestionGroup
          onDragStop={setSortChoiceZone}
          onLabelMove={setSortChoiceZone}
          zones={getDragsortZoneData() as Zone[]}
          groupUsers={groupUsers}
          prompt={getQuestionPrompt(
            practice_set_session_item.question_type,
            practice_set_session_item
          )}
        />
      );
    default:
      return <></>;
  }
};
