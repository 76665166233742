import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  ClassroomFlyout,
  ClassroomFormData,
} from "adminComponents/organisms/ClassroomFlyout";
import { useShowToast } from "adminComponents/utils/toast";
import { useHubSpot } from "lib/hooks/useHubSpot";
import { useMutateClassroom as _useMutateClassroom } from "links/lib/features/classrooms";
import {
  AnalyticsEvent,
  IClassroom,
  IGradeLevel,
  ISubject,
} from "links/lib/types";

export interface IUseMutateClassroomResult {
  handleCreateIntent: () => void;
  handleEditIntent: (classroom: IClassroom) => void;
  flyout: React.ReactElement;
}

export interface IUseMutateClassroomArgs {
  gradeLevels: Array<IGradeLevel>;
  subjects: Array<ISubject>;
  onSubmit?: (classroomId?: string) => void;
}

const useMutateClassroom = ({
  gradeLevels,
  subjects,
  onSubmit,
}: IUseMutateClassroomArgs): IUseMutateClassroomResult => {
  const [isFlyoutOpen, setIsFlyoutOpen] = useState<boolean>(false);
  const [selectedClassroom, setSelectedClassroom] = useState<
    IClassroom | undefined
  >(undefined);
  const showToast = useShowToast();
  const { t } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "classroomCommon.classroomMutate",
  });
  const history = useHistory();
  const { trackHubSpotEvent } = useHubSpot();

  const handleSuccess = useCallback(
    (data) => {
      showToast(t("successToast"));
      setIsFlyoutOpen(false);

      if (!selectedClassroom) {
        trackHubSpotEvent(AnalyticsEvent.HubSpot_CreatedClassroom);

        history.push("/t/classrooms/" + data.classroom.id);
      }
    },
    [showToast, t, history, selectedClassroom, trackHubSpotEvent]
  );

  const mutateClassroom = _useMutateClassroom({ onSuccess: handleSuccess });

  const handleCreateIntent = useCallback(() => {
    setIsFlyoutOpen(true);
    setSelectedClassroom(undefined);
  }, []);

  const handleEditIntent = useCallback((classroom: IClassroom) => {
    setIsFlyoutOpen(true);
    setSelectedClassroom(classroom);
  }, []);

  const handleSubmit = useCallback(
    (formData: ClassroomFormData, classroomId?: string) => {
      mutateClassroom.mutate({
        id: classroomId,
        name: formData.name,
        description: formData.description,
        group_grade_levels: formData.grade_level_ids.map((gid) => ({
          grade_level_id: gid,
        })),
        group_subjects: formData.subject_ids.map((sid) => ({
          subject_id: sid,
        })),
        third_party: formData.third_party,
        third_party_id: formData.third_party_id,
        assignment_notifications_disabled:
          formData.assignment_notifications_disabled,
      });

      onSubmit?.(classroomId);
    },
    [mutateClassroom, onSubmit]
  );

  const handleClose = useCallback(() => {
    setIsFlyoutOpen(false);
    setSelectedClassroom(undefined);
  }, []);

  const flyout = useMemo(() => {
    return (
      <ClassroomFlyout
        isOpen={isFlyoutOpen}
        isLoading={mutateClassroom.isLoading}
        grades={gradeLevels}
        subjects={subjects.filter((s) => s.parent_id === "0")}
        defaultValue={selectedClassroom}
        handleSubmit={handleSubmit}
        handleClose={handleClose}
      />
    );
  }, [
    handleSubmit,
    handleClose,
    isFlyoutOpen,
    selectedClassroom,
    mutateClassroom,
    subjects,
    gradeLevels,
  ]);

  return useMemo(() => {
    return {
      handleCreateIntent,
      handleEditIntent,
      flyout,
    };
  }, [handleCreateIntent, handleEditIntent, flyout]);
};

export { useMutateClassroom };
