import { Box, Text, useDisclosure } from "@chakra-ui/react";
import React, { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSessionStorage } from "react-use";

import { isRichTextEmpty } from "adminComponents/organisms/RichTextEditor/util";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { quickPlayStudenRoundReviewInterstitialTimeMs } from "links/lib/constants";
import { useSession } from "links/lib/contexts/session";
import { useSessionActions } from "links/lib/contexts/sessionActions";
import { useSessionGameState } from "links/lib/contexts/sessionGameState";
import { useSessionRoundGroupState } from "links/lib/contexts/sessionRoundGroupState";
import { useSessionRoundState } from "links/lib/contexts/sessionRoundState";
import { useSessionScene } from "links/lib/contexts/sessionScene";
import { useSessionUsers } from "links/lib/contexts/sessionUsers";
import { useUploadFile } from "links/lib/features/files";
import usePendingSubmit from "links/lib/hooks/usePendingSubmit";
import {
  AnalyticsEvent,
  CoopDragsortVariantData,
  CoopDrawVariantData,
  ExplainAction,
  PracticeSessionItemVariantType,
  QuestionType,
  SessionConnectionStatus,
  SessionGameType,
  SessionScene,
  SessionType,
} from "links/lib/types";
import { QuestionLayout } from "sessionComponents/atoms/QuestionLayout";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { useStudent } from "sessionComponents/contexts/student";
import {
  ResponseOption,
  useItemResponseOptions,
} from "sessionComponents/hooks/useItemResponseOptions";
import { useTextMatchPhase } from "sessionComponents/hooks/useTextMatchPhase";
import { QuestionBox } from "sessionComponents/molecules/QuestionBox";
import { QuestionResponse } from "sessionComponents/molecules/QuestionResponse";
import { RoundReviewAnimatedTransition } from "sessionComponents/molecules/RoundReviewAnimatedTransition";
import { ExplainFlyout } from "sessionComponents/organisms/ExplainFlyout";
import { FeedbackFlyout } from "sessionComponents/organisms/FeedbackFlyout";
import { RoundReviewButtons } from "sessionComponents/organisms/RoundReviewButtons";
import { SplashScene, SplashType } from "sessionComponents/scenes/SplashScene";
import { boxShadows } from "sessionComponents/theme/boxShadows";
import { getAssetColorScheme } from "sessionComponents/utils/getAssetColorScheme";
import { getGameChoiceType } from "sessionComponents/utils/getGameChoiceType";
import { getItemInstructions } from "sessionComponents/utils/getItemInstructions";
import { getQuestionPrompt } from "sessionComponents/utils/getQuestionPrompt";
import { getSelectedChoices } from "sessionComponents/utils/getSelectedChoices";
import { getImageResponseStyleProps } from "sessionComponents/utils/layoutUtils";
import { DrawCanvas } from "sharedComponents/atoms/DrawCanvas";
import { useSessionAnalytics } from "sharedComponents/contexts/sessionAnalytics";

import { useExplain } from "./hooks/useExplain";
import dataUrlToFile from "./utils/dataUrlToFile";
import { getDragsortImageLabels } from "./utils/getDragsortImageLabels";
import { getSelectionUserMap } from "./utils/getSelectionUserMap";
import { getSortedByCorrectOptions } from "./utils/getSortedByCorrectOptions";

type StudentRoundReviewProps = {
  isClassSession: boolean;
};

const INDIVIDUAL_SPLASH_LIFETIME_MS = 3000;

const DEFAULT_ITEM_RESPONSE_OPTIONS: Array<ResponseOption> = [];

export const StudentRoundReview: React.FC<StudentRoundReviewProps> = ({
  isClassSession,
}) => {
  const { match } = useBreakpoints();
  const roundState = useSessionRoundState();
  const roundGroupState = useSessionRoundGroupState();
  const users = useSessionUsers();
  const scene = useSessionScene();
  const session = useSession();
  const gameState = useSessionGameState();
  const { initiateNextRound, sendDrawImageUrl } = useSessionActions();
  const [drawingUploadMap, setDrawingUploadMap] = useSessionStorage<{
    [key: string]: boolean;
  }>(`session-${session?.id}-drawing-uploads`, {});

  const student = useStudent();

  const {
    variant,
    practice_set_session_item,
    rich_feedback,
    round_number,
    variant_data,
  } = roundState;

  const fileUpload = useUploadFile({
    onError: (err) => console.error("Error uploading drawing", err),
  });
  const fileUploadRef = useRef(fileUpload.execute);

  const isDraw = variant === PracticeSessionItemVariantType.CoopDraw;
  const isTeacherDrawVotingRound = scene === SessionScene.VotingResults;
  const isDrawVotingAwardsRound = scene === SessionScene.VotingAwards;
  const isStudentDrawVotingRound = scene === SessionScene.Voting;

  const { isTextMatch } = useTextMatchPhase(
    student.session_group_id,
    student.id
  );

  const { t } = useTranslation("session", { useSuspense: false });
  const { track } = useSessionAnalytics();

  const instructionKey = getItemInstructions(practice_set_session_item);

  const instructions = instructionKey
    ? t(`practiceSets.instructions.${instructionKey}`)
    : "";

  const selectedChoices = useMemo(
    () => getSelectedChoices(roundGroupState),
    [roundGroupState]
  );

  const itemResponseOptions =
    useItemResponseOptions(
      practice_set_session_item,
      roundGroupState,
      variant_data,
      440
    ) || DEFAULT_ITEM_RESPONSE_OPTIONS;

  const answerOptions = useMemo(() => {
    // sort text match types to put correct answer at top of list, if no one entered correct answer add it to the list
    const sortByCorrect = [
      PracticeSessionItemVariantType.CoopTextMatch,
      PracticeSessionItemVariantType.CoopTextMatchNumeric,
    ].includes(variant);

    return sortByCorrect
      ? getSortedByCorrectOptions({
          variant,
          variant_data,
          options: itemResponseOptions,
          selectedChoices,
        })
      : itemResponseOptions;
  }, [variant_data, variant, selectedChoices, itemResponseOptions]);

  const {
    handleAction: handleExplainAction,
    handleRateConversation,
    isLoading: isExplainLoading,
    isRateConversationLoading,
    isRateConversationSuccess,
    messages: explainMessages,
    availableUserActions: explainAvailableUserActions,
    rating,
  } = useExplain({
    practice_set_session_item_id: practice_set_session_item.id,
    practice_set_session_id: session?.id ?? "0",
    group_id: session?.group_id ?? "0",
    practice_set_item_id: practice_set_session_item.practice_set_item_id,
    practice_set_session_item,
  });

  const drawingDataUrl = isDraw
    ? answerOptions.find(
        (option) =>
          option.image_url !== "" && option.id === student.session_group_id
      )?.image_url ?? ""
    : "";
  useEffect(() => {
    if (
      !drawingDataUrl ||
      !session?.id ||
      !practice_set_session_item.id ||
      fileUpload.isLoading ||
      fileUpload.isSuccess ||
      drawingUploadMap[practice_set_session_item.id]
    ) {
      return;
    }

    dataUrlToFile({
      dataUrl: drawingDataUrl,
      fileName: "drawing.png",
    }).then((drawingFile) => {
      if (!drawingFile || drawingUploadMap[practice_set_session_item.id]) {
        return;
      }

      fileUploadRef.current({ file: drawingFile }).then((data) => {
        if (!data?.url || drawingUploadMap[practice_set_session_item.id]) {
          return;
        }

        setDrawingUploadMap({
          [practice_set_session_item.id]: true,
          ...drawingUploadMap,
        });
        sendDrawImageUrl(data.url);
      });
    });
  }, [
    drawingDataUrl,
    fileUpload.isLoading,
    fileUpload.isSuccess,
    fileUploadRef,
    session?.id,
    practice_set_session_item.id,
    drawingUploadMap,
    setDrawingUploadMap,
    sendDrawImageUrl,
  ]);

  const selectionUserMap = useMemo(
    () => getSelectionUserMap(answerOptions, users, selectedChoices),
    [answerOptions, users, selectedChoices]
  );

  const sceneChangeSubmit = usePendingSubmit([scene]);

  // only in individual session
  const onConfirmButtonClick = () => {
    initiateNextRound();
    sceneChangeSubmit.handleSubmit();
  };

  const isFinalRound = round_number === gameState?.final_round_number;
  const isDragsortRound =
    variant === PracticeSessionItemVariantType.CoopDragsort;
  const dragsortVariantData = variant_data as CoopDragsortVariantData;

  const imageLabels = useMemo(
    () =>
      getDragsortImageLabels({
        dragsortVariantData,
        groupDragsortItems: roundGroupState.dragsort?.items || [],
      }),
    [dragsortVariantData, roundGroupState]
  );

  const {
    isOpen: isFeedbackOpen,
    onOpen: onFeedbackOpen,
    onClose: onFeedbackClose,
  } = useDisclosure();
  const showFeedback =
    !isRichTextEmpty(rich_feedback?.text) || !!rich_feedback?.image_url;

  const {
    isOpen: isExplainOpen,
    onOpen: handleExplainOpen,
    onClose: handleExplainClose,
  } = useDisclosure();

  const onExplainOpen = () => {
    if (!explainMessages.length) {
      handleExplainAction(ExplainAction.Explain);
      track(AnalyticsEvent.Session_RoundReview_Explain, {});
    }
    handleExplainOpen();
  };

  const showExplain = useMemo(() => {
    // Item already has feedback
    if (showFeedback) return false;

    // Explain is only available for individual practice
    if (isClassSession) return false;

    // Special generally available sets are excluded
    if (session?.practice_set_is_how_to_play) return false;
    if (session?.practice_set_is_digital_citizenship) return false;

    // Only certified items support Explain
    if (!practice_set_session_item.is_certified) return false;

    // Only certain item types are supported
    switch (practice_set_session_item.question_type) {
      case QuestionType.MultipleChoice:
      case QuestionType.MultipleSelect:
      case QuestionType.TextResponse:
      case QuestionType.NumberResponse:
        return true;
      default:
        return false;
    }
  }, [
    showFeedback,
    isClassSession,
    session?.practice_set_is_how_to_play,
    session?.practice_set_is_digital_citizenship,
    practice_set_session_item.is_certified,
    practice_set_session_item.question_type,
  ]);

  const getConfirmAreaComponent = () => {
    if (isTeacherDrawVotingRound) {
      return (
        <Text textStyle="gameText" fontSize={pxToRem(match.fontSize)}>
          {t("roundReview.waitingForTeacherText")}
        </Text>
      );
    }

    if (isClassSession && !showFeedback && !isFinalRound) return undefined;

    return (
      <RoundReviewButtons
        showExplainButton={showExplain}
        showFeedbackButton={showFeedback}
        isClassSession={isClassSession}
        isFinalRound={isFinalRound}
        onConfirmButtonClick={onConfirmButtonClick}
        onFeedbackClick={onFeedbackOpen}
        onExplainClick={onExplainOpen}
        isTeacher={false}
        isConfirmLoading={sceneChangeSubmit.isPending}
      />
    );
  };

  const showAnswerOptions = !isDraw || (isDraw && isStudentDrawVotingRound);
  const noSubmission =
    (!answerOptions.length && isTextMatch) ||
    (isDraw &&
      !isStudentDrawVotingRound &&
      !(roundGroupState.draw?.strokes || []).length);

  const isImageResponse = useMemo(
    () => showAnswerOptions && answerOptions.some((opt) => !!opt.image_url),
    [showAnswerOptions, answerOptions]
  );

  let roundReviewSplashLifetimeMs = undefined;
  if (session?.session_type === SessionType.Individual) {
    roundReviewSplashLifetimeMs = INDIVIDUAL_SPLASH_LIFETIME_MS;
  } else if (gameState?.game_type === SessionGameType.QuickPlay) {
    roundReviewSplashLifetimeMs = quickPlayStudenRoundReviewInterstitialTimeMs;
    if (isDrawVotingAwardsRound) {
      roundReviewSplashLifetimeMs = 0; // Skip splash screen in Quick Play drawing awards
    }
  }

  return (
    <>
      <RoundReviewAnimatedTransition
        canSkip={!isClassSession}
        splashScene={
          <SplashScene
            splashType={
              isTeacherDrawVotingRound || isDrawVotingAwardsRound
                ? SplashType.RoundStart
                : SplashType.StudentReview
            }
            student={student}
          />
        }
        splashLifetimeMs={roundReviewSplashLifetimeMs}
        overflow="hidden"
      >
        <QuestionLayout
          outerGame={gameState?.game_type}
          isDragsortItem={isDragsortRound}
          // todo animations? vv
          questionComponent={
            <QuestionBox
              prompt={getQuestionPrompt(
                practice_set_session_item.question_type,
                practice_set_session_item
              )}
              imageAltText={practice_set_session_item.image_alt_text}
              imageUrl={practice_set_session_item.image_url}
              instructions={instructions}
              videoUrl={practice_set_session_item.video_url}
              audioUrl={practice_set_session_item.audio_url}
              imageLabels={imageLabels}
            />
          }
          answerOptionsComponent={
            <Box
              {...getImageResponseStyleProps(isImageResponse, match.padding)}
              paddingBottom={pxToRem(match.padding)}
            >
              {/* todo animations here  */}
              {showAnswerOptions &&
                answerOptions.map((option, index) => {
                  let isCorrect = false;
                  let isPartiallyCorrect = false;
                  switch (variant) {
                    case PracticeSessionItemVariantType.CoopDragsort:
                      // eslint-disable-next-line
                      const selectedDragItem = (
                        roundGroupState.dragsort?.items || []
                      ).find((item) => item.id === option.id);
                      if (selectedDragItem) {
                        isCorrect = selectedDragItem.is_correct || false;
                      } else {
                        isCorrect = (
                          roundState.variant_data.coop_dragsort.correct_ids ||
                          []
                        ).includes(option.id);
                      }
                      break;
                    case PracticeSessionItemVariantType.CoopTextMatch:
                    case PracticeSessionItemVariantType.CoopTextMatchNumeric:
                      // eslint-disable-next-line
                      const optionSelectedChoice = (
                        roundGroupState.text_match?.selected_choices || []
                      ).find(
                        (selectedChoice) =>
                          selectedChoice.choice_id === option.id
                      );
                      if (optionSelectedChoice) {
                        isCorrect = optionSelectedChoice.is_correct || false;
                        isPartiallyCorrect =
                          optionSelectedChoice.is_partially_correct || false;
                      } else {
                        // eslint-disable-next-line
                        const isCorrectPrimary =
                          roundState.variant_data.coop_text_match.correct_text?.toLowerCase() ===
                          option.text.toLowerCase();
                        // eslint-disable-next-line
                        const isCorrectAlternate = (
                          roundState.variant_data.coop_text_match
                            .alternate_correct_text || []
                        )
                          .map((a) => a.toLowerCase())
                          .includes(option.text.toLocaleLowerCase());
                        isCorrect = isCorrectPrimary || isCorrectAlternate;
                      }
                      break;
                    case PracticeSessionItemVariantType.CoopRadios:
                      // eslint-disable-next-line
                      const selectedRadioChoice = (
                        roundGroupState.radios?.selected_choices || []
                      ).find(
                        (selectedChoice) =>
                          selectedChoice.choice_id === option.id
                      );
                      if (selectedRadioChoice) {
                        isCorrect = selectedRadioChoice.is_correct;
                      } else {
                        isCorrect =
                          roundState.variant_data.coop_radios
                            .correct_choice_id === option.id;
                      }
                      break;
                    case PracticeSessionItemVariantType.CoopChecks:
                      // eslint-disable-next-line
                      const selectedCheckChoice = (
                        roundGroupState.checks?.selected_choices || []
                      ).find(
                        (selectedChoice) =>
                          selectedChoice.choice_id === option.id
                      );
                      if (selectedCheckChoice) {
                        isCorrect = selectedCheckChoice.is_correct;
                      } else {
                        isCorrect = (
                          roundState.variant_data.coop_checks
                            .correct_choice_ids || []
                        ).includes(option.id);
                      }
                      break;
                    default:
                      isCorrect =
                        roundGroupState.outcomes?.find(
                          (outcome) => outcome.user_id === option.user_id
                        )?.is_correct || false;
                  }

                  const userSelected = isDragsortRound
                    ? !isCorrect
                    : !!selectionUserMap[option.id].find(
                        (user) => user.id === student.id
                      );

                  return (
                    <Box key={option.id} marginTop={pxToRem(match.margin / 2)}>
                      <QuestionResponse
                        variant={variant}
                        choiceType={getGameChoiceType(variant)}
                        text={option.text}
                        imageUrl={option.image_url}
                        imageAltText={option.image_alt_text}
                        success={isCorrect}
                        showAsDefaultWrongAnswer={!userSelected && !isCorrect}
                        error={userSelected && !isCorrect}
                        partiallyCorrect={userSelected && isPartiallyCorrect}
                        otherTeammatePartiallyCorrect={
                          !userSelected && isPartiallyCorrect
                        }
                        questionType={practice_set_session_item.question_type}
                        number={index + 1}
                        users={selectionUserMap[option.id]
                          .filter(
                            (u) =>
                              u.connection_status !==
                              SessionConnectionStatus.Disconnected
                          )
                          .map((user) => ({
                            avatarUrl: user.profile_image_url,
                            id: user.id,
                            name: user.name,
                            primaryColor: getAssetColorScheme(user.color_scheme)
                              .primaryColor,
                          }))}
                        prefix={
                          practice_set_session_item.number_response?.prefix
                        }
                        suffix={
                          practice_set_session_item.number_response?.suffix
                        }
                        maxHeight="100%"
                        maxWidth="100%"
                      />
                    </Box>
                  );
                })}
              {noSubmission && (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  h="full"
                  w="full"
                >
                  {
                    <Text
                      fontSize={pxToRem(match.fontSize)}
                      textStyle="gameText"
                    >
                      {isDraw
                        ? t("roundReview.noDrawingStudentFallbackMsg")
                        : t("roundReview.noTextStudentFallbackMsg")}
                    </Text>
                  }
                </Box>
              )}
              {isDraw &&
                !isStudentDrawVotingRound &&
                !!(roundGroupState.draw?.strokes || []).length && (
                  // review for draw variant
                  <Box w="full" h="full">
                    <DrawCanvas
                      disabled
                      strokes={roundGroupState.draw?.strokes || []}
                      studentId={student.id}
                      boxShadow={boxShadows.xl}
                      backgroundImageUrl={
                        (variant_data as CoopDrawVariantData).coop_draw
                          .background_image_url
                      }
                    />
                  </Box>
                )}
            </Box>
          }
          confirmAreaComponent={getConfirmAreaComponent()}
        />
      </RoundReviewAnimatedTransition>

      <FeedbackFlyout
        isOpen={isFeedbackOpen}
        onClose={onFeedbackClose}
        imageUrl={rich_feedback?.image_url}
        imageAltText={rich_feedback?.image_alt_text}
        text={rich_feedback?.text}
      />

      {showExplain && (
        <ExplainFlyout
          handleAction={handleExplainAction}
          handleRateConversation={handleRateConversation}
          messages={explainMessages}
          userProfileImageSrc={student.profile_image_url}
          isOpen={isExplainOpen}
          handleClose={handleExplainClose}
          isSystemResponding={isExplainLoading}
          isRateConversationLoading={isRateConversationLoading}
          isRateConversationSuccess={isRateConversationSuccess}
          availableUserActions={explainAvailableUserActions}
          rating={rating}
        />
      )}
    </>
  );
};
