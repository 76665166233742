import axe from "@axe-core/react";
import { ChakraProvider } from "@chakra-ui/react";
import { RuntimeLoader } from "@rive-app/canvas";
import { AxiosError } from "axios";
// Polyfill all features
import "core-js/full";
import React from "react";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  DndProvider,
  MouseTransition,
  TouchTransition,
} from "react-dnd-multi-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import "regenerator-runtime/runtime";
import { theme } from "theme";

import { LoggingProvider } from "lib/contexts/logging";
import "lib/i18n";
import { localStore, sessionStore } from "lib/util";
// import reportWebVitals from "reportWebVitals";
import axiosClient from "links/lib/axios";
import { config } from "links/lib/constants";
import { AuthProvider } from "links/lib/contexts/auth";
import { AxiosProvider } from "links/lib/contexts/axios";
import { GeoProvider } from "links/lib/contexts/geo";
import { LocalStoreProvider } from "links/lib/contexts/localStore";
import { SessionStoreProvider } from "links/lib/contexts/sessionStore";
import App from "screens/App/index";

// run axe accessibility checking in development/staging
if (config.nodeEnv !== "production") {
  console.log(`NODE_ENV: ${config.nodeEnv}`);
  axe(React, ReactDOM, 1000, undefined, {
    exclude: [["grammarly-desktop-integration"]],
  });
}

// set WASM resource to local URL for rive
RuntimeLoader.setWasmUrl("/rive.wasm");

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount: number, err: unknown): boolean => {
        const statusCode = (err as AxiosError)?.response?.status || 0;
        if (statusCode >= 400 && statusCode < 500) {
          return false;
        }
        return failureCount < 3;
      },
    },
  },
});

export const HTML5toTouch = {
  backends: [
    // Putting touch backend first prevents issues with custom drag layers
    // not receiving intitial touch coordinates for previews on first drag
    {
      id: "touch",
      backend: TouchBackend,
      options: {
        delayTouchStart: 150, // allows dragging to scroll
        ignoreContextMenu: true, // prevent long press from canceling drag}
      },
      transition: TouchTransition,
    },
    {
      id: "html5",
      backend: HTML5Backend,
      transition: MouseTransition,
    },
  ],
};

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme} portalZIndex={30}>
      <BrowserRouter>
        <GeoProvider>
          <AuthProvider>
            <LoggingProvider>
              <QueryClientProvider client={queryClient}>
                <LocalStoreProvider localStore={localStore}>
                  <SessionStoreProvider sessionStore={sessionStore}>
                    <AxiosProvider client={axiosClient}>
                      <DndProvider options={HTML5toTouch}>
                        <App />
                      </DndProvider>
                    </AxiosProvider>
                  </SessionStoreProvider>
                </LocalStoreProvider>
              </QueryClientProvider>
            </LoggingProvider>
          </AuthProvider>
        </GeoProvider>
      </BrowserRouter>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
