import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useInterval, useUnmount } from "react-use";
import { setTimeout } from "timers";

import { SessionTheBigBoardSpaceRow } from "links/lib/types";

export interface IUsePrizeRoundHighlightArgs {
  targetSpaceId: number;
  rows: Array<SessionTheBigBoardSpaceRow>;
}

export interface IUsePrizeRoundHighlightResult {
  highlightedSpaceIds: Array<number>;
  highlightedRow: number;
  highlightedCol: number;
  reachedTargetRow: boolean;
  reachedTargetCol: boolean;
  atRest: boolean;
  reset: () => void;
}

const getBoardSpeed = (pass: number) => {
  switch (pass) {
    case 0:
      return 150;
    case 1:
      return 200;
    case 2:
      return 280;
    case 3:
      return 400;
    default:
      return 1000;
  }
};

const TARGET_ROW_PAUSE_DURATION = 1000;
//TODO FIX
const usePrizeRoundHighlight = ({
  targetSpaceId,
  rows,
}: IUsePrizeRoundHighlightArgs): IUsePrizeRoundHighlightResult => {
  const targetRowPauseInterval = useRef<NodeJS.Timeout | null>(null);
  const [state, setState] = useState({
    highlightedRow: 0,
    highlightedCol: 0,
    reachedTargetRow: false,
    reachedTargetCol: false,
    targetRowPaused: true,
  });

  const spaceToRowMap = useMemo(() => {
    const rowMap: { [key: number]: number } = {};
    rows.forEach((row, rowIndex) => {
      row.cols.forEach((col) => {
        rowMap[col.space_id] = rowIndex;
      });
    });
    return rowMap;
  }, [rows]);

  const spaceToColMap = useMemo(() => {
    const colMap: { [key: number]: number } = {};
    rows.forEach((row) => {
      row.cols.forEach((col, colIndex) => {
        colMap[col.space_id] = colIndex;
      });
    });
    return colMap;
  }, [rows]);

  const rowToSpaceMap = useMemo(() => {
    const rowsMap: { [key: number]: number[] } = {};
    rows.forEach((row, rowIndex) => {
      row.cols.forEach((col) => {
        if (!rowsMap[rowIndex]) {
          rowsMap[rowIndex] = [];
        }
        rowsMap[rowIndex].push(col.space_id);
      });
    });
    return rowsMap;
  }, [rows]);

  const targetRowPasses = useRef(0);
  const targetColPasses = useRef(0);

  useInterval(
    () => {
      setState((prevState) => {
        const nextRow =
          prevState.highlightedRow === rows.length - 1
            ? 0
            : prevState.highlightedRow + 1;
        return {
          ...prevState,
          highlightedRow: nextRow,
        };
      });
    },

    state.reachedTargetRow ? null : getBoardSpeed(targetRowPasses.current)
  );

  useInterval(
    () => {
      setState((prevState) => {
        const nextCol =
          prevState.highlightedCol === rows[0].cols.length - 1
            ? 0
            : prevState.highlightedCol + 1;

        return {
          ...prevState,
          highlightedCol: nextCol,
        };
      });
    },
    !state.reachedTargetRow || state.reachedTargetCol || state.targetRowPaused
      ? null
      : getBoardSpeed(targetColPasses.current)
  );

  const handleBoardHighlightLogic = useCallback(
    (isRow: boolean) => {
      if (isRow && state.reachedTargetRow) return;
      if (!isRow && state.reachedTargetCol) return;
      let target = -1;
      if (targetSpaceId) {
        if (isRow) target = spaceToRowMap[targetSpaceId];
        else target = spaceToColMap[targetSpaceId];
      }

      const currentHighlight = isRow
        ? state.highlightedRow
        : state.highlightedCol;

      const currentPasses = isRow
        ? targetRowPasses.current
        : targetColPasses.current;

      if (currentHighlight === target) {
        if (currentPasses === 3) {
          if (isRow) handleArriveAtTargetRow(target);
          else handleArriveAtTargetCol(target);
        } else {
          // increment the pass counters
          if (isRow) targetRowPasses.current = targetRowPasses.current + 1;
          else targetColPasses.current = targetColPasses.current + 1;
        }
      }
    },
    [
      state.highlightedCol,
      state.highlightedRow,
      state.reachedTargetCol,
      state.reachedTargetRow,
      targetSpaceId,
      spaceToRowMap,
      spaceToColMap,
    ]
  );

  const handleArriveAtTargetRow = (targetRow: number) => {
    setState((prevState) => {
      return {
        ...prevState,
        reachedTargetRow: true,
        highlightedRow: targetRow,
      };
    });

    // hang on this row before moving to a column scan
    targetRowPauseInterval.current = setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        targetRowPaused: false,
      }));
    }, TARGET_ROW_PAUSE_DURATION);
  };

  const handleArriveAtTargetCol = (targetCol: number) => {
    setState((prevState) => {
      return {
        ...prevState,
        reachedTargetCol: true,
        highlightedCol: targetCol,
      };
    });
  };

  useEffect(() => {
    handleBoardHighlightLogic(!state.reachedTargetRow);
  }, [handleBoardHighlightLogic, state.reachedTargetRow]);

  useUnmount(() => {
    if (targetRowPauseInterval.current) {
      clearTimeout(targetRowPauseInterval.current);
    }
  });

  const reset = useRef(() => {
    setState({
      highlightedRow: 0,
      highlightedCol: 0,
      reachedTargetRow: false,
      reachedTargetCol: false,
      targetRowPaused: true,
    });

    targetRowPasses.current = 0;
    targetColPasses.current = 0;

    if (targetRowPauseInterval.current) {
      clearTimeout(targetRowPauseInterval.current);
      targetRowPauseInterval.current = null;
    }
  });

  return useMemo(() => {
    if (!state.reachedTargetRow || state.targetRowPaused) {
      return {
        highlightedSpaceIds: rowToSpaceMap[state.highlightedRow] || [],
        highlightedRow: state.highlightedRow,
        highlightedCol: state.highlightedCol,
        reachedTargetRow: state.reachedTargetRow,
        reachedTargetCol: state.reachedTargetCol,
        atRest: false,
        reset: reset.current,
      };
    }

    if (!state.reachedTargetCol) {
      return {
        highlightedCol: state.highlightedCol,
        highlightedRow: state.highlightedRow,
        highlightedSpaceIds: [
          rowToSpaceMap[state.highlightedRow][state.highlightedCol],
        ],
        reachedTargetRow: state.reachedTargetRow,
        reachedTargetCol: state.reachedTargetCol,
        atRest: false,
        reset: reset.current,
      };
    }

    return {
      highlightedSpaceIds: [targetSpaceId],
      highlightedRow: state.highlightedRow,
      highlightedCol: state.highlightedCol,
      reachedTargetRow: state.reachedTargetRow,
      reachedTargetCol: state.reachedTargetCol,
      atRest: true,
      reset: reset.current,
    };
  }, [
    targetSpaceId,
    state.highlightedCol,
    state.highlightedRow,
    state.reachedTargetRow,
    state.reachedTargetCol,
    state.targetRowPaused,
    rowToSpaceMap,
  ]);
};

export default usePrizeRoundHighlight;
