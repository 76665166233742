import React from "react";

import {
  IAvatar,
  ISeasonLocationAvatarItem,
  ISeasonLocationAvatarItemGroup,
} from "links/lib/types";
import { SeasonLocation } from "sharedComponents/molecules/SeasonLocation";
import { CombinedItem } from "sharedComponents/molecules/SeasonLocationProgress";

import BampPowSVG from "./resource/bam_pow.svg";
import CascadeFailureSVG from "./resource/cascade_failure.svg";
import DeepDiveSVG from "./resource/deep_dive.svg";
import EerieEstatesSVG from "./resource/eerie_estates.svg";
import GiftShopSVG from "./resource/gift_shop.svg";
import MesozoicMayhemSVG from "./resource/mesozoic_mayhem.svg";
import RiverOfRuinSVG from "./resource/river_of_ruin.svg";
import RobotRodeoSVG from "./resource/robot_rodeo.svg";
import SaucerSpinSVG from "./resource/saucer_spin.svg";
import ShrinkRaySVG from "./resource/shrink_ray.svg";

export interface ISeason2LocationProps {
  width: number;
  height: number;
  canUnlock: boolean;
  avatarItems: Array<ISeasonLocationAvatarItem>;
  avatarItemGroups: Array<ISeasonLocationAvatarItemGroup>;
  locationId: string;
  pathColor: string;
  isLoading: boolean;
  avatarAtlasUrl: string;
  avatarSkeletonUrl: string;
  avatar: IAvatar;
  handleRequestUnlock?: (item: CombinedItem) => void;
}

const locationToBGMap: { [locationId: string]: string } = {
  15: GiftShopSVG,
  16: MesozoicMayhemSVG,
  17: DeepDiveSVG,
  18: ShrinkRaySVG,
  19: CascadeFailureSVG,
  20: RobotRodeoSVG,
  21: SaucerSpinSVG,
  22: BampPowSVG,
  23: RiverOfRuinSVG,
  24: EerieEstatesSVG,
};

export const Season2Location: React.FC<ISeason2LocationProps> = ({
  width,
  height,
  avatarItems,
  avatarItemGroups,
  locationId,
  pathColor,
  isLoading,
  avatarAtlasUrl,
  avatarSkeletonUrl,
  handleRequestUnlock,
  canUnlock,
  avatar,
}) => {
  return (
    <SeasonLocation
      width={width}
      height={height}
      canUnlock={canUnlock}
      avatarAtlasUrl={avatarAtlasUrl}
      avatarSkeletonUrl={avatarSkeletonUrl}
      handleRequestUnlock={handleRequestUnlock}
      avatarItems={avatarItems}
      avatarItemGroups={avatarItemGroups}
      pathColor={pathColor}
      isLoading={isLoading}
      avatar={avatar}
      bgSVG={locationToBGMap[locationId]}
    />
  );
};
