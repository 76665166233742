import {
  GLTexture,
  SceneRenderer,
  SpineCanvas,
} from "@esotericsoftware/spine-webgl";

import { ISpineStageAsset } from "../types";

export interface ISpineItemConstructorArgs {
  x: number;
  y: number;
  scale: number;
  imageSrc: string;
  drawOrder?: number;
}

class SpineItem implements ISpineStageAsset {
  x: number;
  y: number;
  worldX: number;
  worldY: number;
  scale: number;
  imageSrc: string;
  image?: HTMLImageElement;
  texture?: GLTexture;
  isInitialized: boolean;
  drawOrder: number;

  constructor({ x, y, scale, imageSrc, drawOrder }: ISpineItemConstructorArgs) {
    this.x = x;
    this.y = y;
    this.scale = scale;
    this.imageSrc = imageSrc;
    this.isInitialized = false;
    this.drawOrder = drawOrder || 0;

    this.worldX = 0;
    this.worldY = 0;
  }

  async loadAssets(): Promise<void> {
    this.image = new Image();

    const loadImage = () => {
      if (!this.image) return;

      const loadPromise = new Promise<void>((resolve, reject) => {
        if (!this.image) return;

        this.image.onload = () => {
          resolve();
        };

        this.image.onerror = () => {
          reject();
        };
      });

      this.image.src = this.imageSrc;

      return loadPromise;
    };

    await loadImage();

    return;
  }

  dispose(): void {
    this.texture?.dispose();
    delete this.image;
    return;
  }

  async initialize(canvas: SpineCanvas): Promise<void> {
    if (!this.image) return;

    try {
      this.texture = new GLTexture(canvas.gl, this.image, true);
      this.isInitialized = true;
    } catch (err) {
      console.error("Could not initialize Spine Item: " + err);
    }

    return;
  }

  setTransform(x: number, y: number, scale: number): void {
    this.x = x;
    this.y = y;
    this.scale = scale;
  }

  update(
    canvas: SpineCanvas,
    delta: number,
    stageToWorldPosition: { x: number; y: number }
  ): void {
    if (!this.image) return;

    // position texture via its center rather than bottom left corner (spine default)
    this.worldX =
      (this.x - (this.image.width * this.scale) / 2) * stageToWorldPosition.x;
    this.worldY =
      (this.y - (this.image.height * this.scale) / 2) * stageToWorldPosition.y;
  }

  render(canvas: SpineCanvas, renderer: SceneRenderer): void {
    if (!this.texture || !this.image) return;

    const { width, height } = this.image;
    const { worldX, worldY, scale } = this;

    renderer.drawTexture(
      this.texture,
      worldX,
      worldY,
      width * scale,
      height * scale
    );

    return;
  }
}

export { SpineItem };
