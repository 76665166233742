import {
  Box,
  ButtonGroup,
  Flex,
  FormControl,
  FormLabel,
  SimpleGrid,
} from "@chakra-ui/react";
import katex from "katex";
import React, { useLayoutEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useMount } from "react-use";

import { Button } from "adminComponents/atoms/Button";
import { Modal } from "adminComponents/atoms/Modal";
import { Tabs } from "adminComponents/atoms/Tabs";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { latexContainsNestedSquareBrackets } from "lib/latexUtils";
import MathQuill from "lib/pkg/MathQuill";

export interface ILatexModalProps {
  value?: string;
  entityKey: string | null;
  isOpen: boolean;
  content: {
    heading: string;
    saveButton: string;
    closeButton: string;
    mathTab: string;
    greekTab: string;
  };
  handleClose: () => void;
  handleChange: (entityKey: string | null, latexContent: string) => void;
}

export interface ILatexModalContentProps {
  handleClose: () => void;
  value?: string;
  content: {
    heading: string;
    saveButton: string;
    closeButton: string;
    mathTab: string;
    greekTab: string;
  };
  handleChange: (latexContent: string) => void;
}

export interface ILatexCommandButtonProps {
  cmd: string;
  katex: string;
  onCmd: (cmd: string) => void;
  scale?: number;
}

const commandButtons: {
  [key: string]: Array<{ katex: string; cmd: string; scale?: number }>;
} = {
  // Operations and symbols
  Math: [
    { katex: "+", cmd: "+" },
    { katex: "-", cmd: "-" },
    { katex: ",", cmd: "{,}" },
    { katex: "\\times", cmd: "\\times" },
    { katex: "\\centerdot", cmd: "\\cdot" },
    { katex: "\\div", cmd: "\\div" },
    { katex: "/", cmd: "\\slash" },
    { katex: "\\pm", cmd: "\\pm" },
    { katex: "\\%", cmd: "%" },

    // Equality, inequality, and other relations
    { katex: "=", cmd: "=" },
    { katex: "\\neq", cmd: "\\neq" },
    { katex: "\\approx", cmd: "\\approx" },
    { katex: "\\gt", cmd: ">" },
    { katex: "\\lt", cmd: "<" },
    { katex: "\\geq", cmd: "\\geq" },
    { katex: "\\leq", cmd: "\\leq" },
    { katex: "\\cap", cmd: "\\cap" },
    { katex: "\\cup", cmd: "\\cup" },
    { katex: "\\colon", cmd: ":" },
    { katex: "\\rArr", cmd: "\\Rightarrow" },

    // Geometry symbols
    { katex: "\\degree", cmd: "\\degree" },
    { katex: "\\angle", cmd: "\\angle" },
    { katex: "\\overleftrightarrow{AB}", cmd: "\\overleftrightarrow" },
    { katex: "\\overline{AB}", cmd: "\\overline" },
    { katex: "\\overrightarrow{AB}", cmd: "\\overrightarrow" },
    { katex: "\\overgroup{AB}", cmd: "\\overarc" },
    { katex: "\\triangle", cmd: "\\triangle" },
    { katex: "\\odot", cmd: "\\odot" },
    { katex: "\\cong", cmd: "\\cong" },
    { katex: "\\sim", cmd: "\\sim" },
    { katex: "\\parallel", cmd: "\\parallel" },
    { katex: "\\perp", cmd: "\\perp" },

    // Grouping symbols and other advanced formatting
    { katex: "(", cmd: "(" },
    { katex: ")", cmd: ")" },
    { katex: "[", cmd: "[" },
    { katex: "]", cmd: "]" },
    { katex: "\\{", cmd: "{" },
    { katex: "\\}", cmd: "}" },
    { katex: "\\sqrt{x}", cmd: "\\sqrt" },
    { katex: "\\sqrt[y]{x}", cmd: "\\nthroot" },
    { katex: "\\vert", cmd: "\\vert" },
    { katex: "|", cmd: "|" },
    { katex: "x^y", cmd: "^" },
    { katex: "x_y", cmd: "_" },
    { katex: "\\sin{x}", cmd: "\\sin", scale: 0.9 },
    { katex: "\\cos{x}", cmd: "\\cos", scale: 0.9 },
    { katex: "\\tan{x}", cmd: "\\tan", scale: 0.9 },
    { katex: "\\arcsin{x}", cmd: "\\arcsin", scale: 0.6 },
    { katex: "\\arccos{x}", cmd: "\\arccos", scale: 0.6 },
    { katex: "\\arctan{x}", cmd: "\\arctan", scale: 0.6 },
    { katex: "\\log", cmd: "\\log" },
    { katex: "\\ln", cmd: "\\ln" },

    // Miscellaneous
    { katex: "\\$", cmd: "$" },
    { katex: "¢", cmd: "¢" },
    { katex: "\\vec{x}", cmd: "\\vec" },
    { katex: "\\displaystyle\\sum_{i=1}^n", cmd: "\\sum", scale: 0.8 },
    { katex: "\\displaystyle\\int_{i=1}^n", cmd: "\\int", scale: 0.8 },
  ],

  // Special characters
  Greek: [
    { katex: "\\pi", cmd: "\\pi" },
    { katex: "e", cmd: "e" },
    { katex: "i", cmd: "i" },
    { katex: "\\theta", cmd: "\\theta" },
    { katex: "\\infin", cmd: "\\infin" },
    { katex: "\\mu", cmd: "\\mu" },
    { katex: "\\sigma", cmd: "\\sigma" },
  ],
};

const LatexCommandButton: React.FC<ILatexCommandButtonProps> = ({
  katex: katexContent,
  cmd,
  onCmd,
  scale,
}) => {
  const buttonRef = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    if (!buttonRef.current) return;
    katex.render(katexContent, buttonRef.current, { displayMode: false });
  }, [katexContent]);

  return (
    <Button variant="adminMathButton" onClick={() => onCmd(cmd)}>
      <Box
        as="span"
        transform={`scale(${scale ? scale : 1})`}
        ref={buttonRef}
      />
    </Button>
  );
};

const LatexModalContent: React.FC<ILatexModalContentProps> = ({
  handleChange,
  handleClose,
  content,
  value,
}) => {
  const inputRef = useRef<HTMLDivElement | null>(null);
  const fieldRef = useRef<typeof MathQuill.MathField | null>(null);

  const { t } = useTranslation("translation", {
    keyPrefix: "richTextEditor",
    useSuspense: false,
  });

  useMount(() => {
    if (!inputRef.current && !fieldRef.current) return;

    fieldRef.current = MathQuill.MathField(inputRef.current, {});

    fieldRef.current.latex(value || "");

    fieldRef.current.reflow();
  });

  const handleCmd = (cmd: string) => {
    if (!fieldRef.current) return;

    const originalLatex = fieldRef.current.latex();

    fieldRef.current.cmd(cmd);

    const updatedLatex = fieldRef.current.latex();

    // Mathquill permits creating invalid LaTeX. To avoid this,
    // if it is detected, undo the most recent action.
    if (latexContainsNestedSquareBrackets(updatedLatex)) {
      fieldRef.current.latex(originalLatex);
    }

    fieldRef.current.focus();
  };

  const commandSections = Object.keys(commandButtons);

  const getSectionLabel = (sectionName: string): string => {
    switch (sectionName) {
      case "Math":
        return content.mathTab;
      case "Greek":
        return content.greekTab;
      default:
        return "";
    }
  };

  return (
    <>
      <FormControl variant="adminLatexFormControl">
        <FormLabel hidden htmlFor="latexInput" id="latexInputLabel">
          {t("latexModal.inputLabel")}
        </FormLabel>
        <Box
          id="latexInput"
          aria-labelledby="latexInputLabel"
          w="full"
          ref={inputRef}
        />
      </FormControl>
      <Tabs
        variant="adminMathTab"
        tabData={commandSections.map((sectionName) => ({
          content: (
            <SimpleGrid w="full" minChildWidth="48px" spacing="6px">
              {commandButtons[sectionName].map(({ katex, cmd, scale }) => (
                <Flex key={cmd} alignItems="center" justifyContent="center">
                  <LatexCommandButton
                    katex={katex}
                    cmd={cmd}
                    onCmd={handleCmd}
                    scale={scale}
                  />
                </Flex>
              ))}
            </SimpleGrid>
          ),
          label: getSectionLabel(sectionName),
        }))}
      />
      <ButtonGroup mt={pxToRem(24)}>
        <Button
          variant="adminButtonFilled"
          onClick={() => handleChange(fieldRef.current.latex())}
        >
          {content.saveButton}
        </Button>
        <Button variant="adminButtonOutlined" onClick={handleClose}>
          {content.closeButton}
        </Button>
      </ButtonGroup>
    </>
  );
};

const LatexModal: React.FC<ILatexModalProps> = ({
  isOpen,
  entityKey,
  content,
  value,
  handleClose,
  handleChange,
}) => {
  const handleChangeLatexContent = (latexContent: string) => {
    handleChange(entityKey, latexContent);
  };

  return (
    <Modal title={content.heading} isOpen={isOpen} onClose={handleClose}>
      {isOpen && (
        <LatexModalContent
          content={content}
          value={value}
          handleClose={handleClose}
          handleChange={handleChangeLatexContent}
        />
      )}
    </Modal>
  );
};

export default LatexModal;
