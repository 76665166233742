import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  InputGroup,
  InputLeftAddon,
  RadioGroup,
  Show,
  Stack,
  Tooltip,
  VStack,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import { debounce } from "lodash";
import React, { FormEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Card } from "adminComponents/atoms/Card";
import { Heading } from "adminComponents/atoms/Heading";
import { Icon } from "adminComponents/atoms/Icon";
import { Input } from "adminComponents/atoms/Input";
import { Radio } from "adminComponents/atoms/Radio";
import { Switch } from "adminComponents/atoms/Switch";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils";
import { config } from "links/lib/constants";
import { useCheckCustomUrlCode } from "links/lib/features/account";
import { useAuth } from "links/lib/features/auth";
import { ISharingSettings, SharingSettingsVisibility } from "links/lib/types";

export interface IProps {
  handleSubmit: (settings: ISharingSettings, customUrlCode: string) => void;
  isLoading: boolean;
  initialSettings: ISharingSettings;
}

export const TeacherProfileSettingsForm: React.FC<IProps> = ({
  handleSubmit,
  isLoading,
  initialSettings,
}) => {
  const { authUser } = useAuth();
  const [isFormChanged, setIsFormChanged] = useState(false);
  const { t } = useTranslation("admin", {
    keyPrefix: "teacherProfileSettingsForm",
    useSuspense: false,
  });
  const { t: tCommon } = useTranslation("admin", {
    keyPrefix: "common",
    useSuspense: false,
  });
  const styles = useMultiStyleConfig("AdminTeacherAccountInformationForm", {});
  const [settings, setSettings] = useState(initialSettings);
  const [customUrlCode, setCustomUrlCode] = useState(
    authUser?.custom_url_code || ""
  );
  const [customUrlCodeDebounce, setCustomUrlCodeDebounce] =
    useState(customUrlCode);

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    handleSubmit(settings, customUrlCode);
  };

  useEffect(() => {
    const isChanged =
      JSON.stringify(settings) !== JSON.stringify(initialSettings) ||
      customUrlCode !== authUser?.custom_url_code;
    setIsFormChanged(isChanged);
  }, [initialSettings, settings, customUrlCode, authUser?.custom_url_code]);

  const toggleSetting = (settingKey: keyof ISharingSettings) => {
    return () => {
      setSettings({
        ...settings,
        [settingKey]: !settings[settingKey],
      });
    };
  };

  const visibilityOptions = [
    {
      label: t("visibilityPublic"),
      description: t("visibilityPublicDescription"),
      value: SharingSettingsVisibility.Public,
    },
    {
      label: t("visibilityGSOnly"),
      description: t("visibilityGSOnlyDescription"),
      value: SharingSettingsVisibility.GSOnly,
    },
    {
      label: t("visibilityPrivate"),
      description: t("visibilityPrivateDescription"),
      value: SharingSettingsVisibility.Private,
    },
  ];

  const onChangeVisibility = (visibility: SharingSettingsVisibility) => {
    setSettings({
      ...settings,
      visibility,
    });
  };

  const checkCustomUrlCode = useCheckCustomUrlCode({
    customUrlCode: customUrlCodeDebounce,
  });

  const setCustomUrlCodeDebounceDebounced = useRef(
    debounce(
      (value) => {
        setCustomUrlCodeDebounce(value);
      },
      500,
      {
        leading: false,
        trailing: true,
      }
    )
  );

  useEffect(() => {
    setCustomUrlCodeDebounceDebounced.current(customUrlCode);
  }, [customUrlCode]);

  const onChangeCustomUrlCode = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;

    setCustomUrlCode(value);
  };

  const isInvalid =
    checkCustomUrlCode.data && !!checkCustomUrlCode.data.error_message;

  return (
    <Card
      sx={styles.outer}
      variant="adminCardThickBorder"
      borderColor="primary.tan"
    >
      <form onSubmit={onSubmit}>
        <Flex sx={styles.headerContainer}>
          <Heading as="h2" variant="adminH4">
            {t("heading")}
          </Heading>
        </Flex>
        <Flex sx={styles.headerContainer}>
          <Text size="sm" mt={pxToRem(24)}>
            {t("subheading")}
          </Text>
        </Flex>

        <Flex sx={styles.formContainer}>
          <Flex sx={styles.fieldContainer} w="full">
            <FormControl isInvalid={isInvalid}>
              <FormLabel htmlFor="customUrlCode">
                {t("labelCustomUrlCode")}
              </FormLabel>

              <Text>{t("labelCustomUrlCodeDescription")}</Text>

              <InputGroup mt={pxToRem(8)}>
                <Show above="sm">
                  <InputLeftAddon
                    h={pxToRem(48)}
                    bgColor="primary.light-gray"
                    color="primary.warm-black"
                  >
                    {config.baseUrl}/profiles/
                  </InputLeftAddon>
                </Show>
                <Input
                  id="customUrlCode"
                  name="customUrlCode"
                  value={customUrlCode}
                  size="md"
                  borderLeftRadius={{ base: undefined, md: 0 }}
                  onChange={onChangeCustomUrlCode}
                ></Input>
              </InputGroup>
              {isInvalid && (
                <FormErrorMessage>
                  {checkCustomUrlCode.data?.error_message}
                </FormErrorMessage>
              )}
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="visibility">{t("labelVisibility")}</FormLabel>
              <RadioGroup
                id="visibility"
                name="visibility"
                onChange={(v) =>
                  onChangeVisibility(v as SharingSettingsVisibility)
                }
                value={settings.visibility}
              >
                <Stack spacing={pxToRem(16)}>
                  {visibilityOptions.map((visibilityOption, i) => {
                    return (
                      <Radio size="lg" key={i} value={visibilityOption.value}>
                        <Box ml={pxToRem(8)}>
                          <Text fontSize={pxToRem(16)} fontWeight="bold">
                            {visibilityOption.label}
                          </Text>
                          <Text variant="adminP2">
                            {visibilityOption.description}
                          </Text>
                        </Box>
                      </Radio>
                    );
                  })}
                </Stack>
              </RadioGroup>
            </FormControl>

            <VStack spacing={pxToRem(12)}>
              <FormLabel w="full">{t("labelCustomizeSharing")}</FormLabel>

              <TeacherProfileSettingsSwitchField
                name="share_statistics"
                label={t("labelShareStatistics")}
                labelSettingActive={t("labelSettingActive")}
                labelSettingInactive={t("labelSettingInactive")}
                isChecked={settings.share_statistics}
                onChange={toggleSetting("share_statistics")}
              />

              <TeacherProfileSettingsSwitchField
                name="share_grade_levels"
                label={t("labelShareGradeLevels")}
                labelSettingActive={t("labelSettingActive")}
                labelSettingInactive={t("labelSettingInactive")}
                isChecked={settings.share_grade_levels}
                onChange={toggleSetting("share_grade_levels")}
              />

              <TeacherProfileSettingsSwitchField
                name="share_subjects"
                label={t("labelShareSubjects")}
                labelSettingActive={t("labelSettingActive")}
                labelSettingInactive={t("labelSettingInactive")}
                isChecked={settings.share_subjects}
                onChange={toggleSetting("share_subjects")}
              />

              <TeacherProfileSettingsSwitchField
                name="share_region"
                label={t("labelShareRegion")}
                labelSettingActive={t("labelSettingActive")}
                labelSettingInactive={t("labelSettingInactive")}
                isChecked={settings.share_region}
                onChange={toggleSetting("share_region")}
              />
            </VStack>
          </Flex>

          <Flex sx={styles.buttonContainer}>
            <Button
              size="sm"
              type="submit"
              variant="adminButtonFilled"
              disabled={!isFormChanged || isInvalid}
              sx={styles.button}
              isLoading={isLoading}
            >
              {tCommon("save")}
            </Button>
          </Flex>
        </Flex>
      </form>
    </Card>
  );
};

interface ITeacherProfileSettingsSwitchField {
  name: keyof ISharingSettings;
  label: string;
  labelSettingActive: string;
  labelSettingInactive: string;
  isChecked: boolean;
  onChange: () => void;
}

const TeacherProfileSettingsSwitchField: React.FC<
  ITeacherProfileSettingsSwitchField
> = ({
  name,
  label,
  isChecked,
  labelSettingActive,
  labelSettingInactive,
  onChange,
}) => {
  return (
    <FormControl>
      <HStack spacing={pxToRem(16)} cursor="pointer">
        <FormLabel
          flex="10"
          htmlFor={name}
          color="primary.warm-black"
          fontWeight="semibold"
          cursor="pointer"
        >
          {label}
        </FormLabel>
        <Tooltip
          hasArrow
          label={isChecked ? labelSettingActive : labelSettingInactive}
          aria-label={isChecked ? labelSettingActive : labelSettingInactive}
        >
          <VStack h="full">
            <Icon
              icon={isChecked ? "eye" : "eye_strikethrough"}
              color={isChecked ? undefined : "primary.dark-gray"}
            />
          </VStack>
        </Tooltip>
        <Switch
          name={name}
          id={name}
          isChecked={isChecked}
          onChange={onChange}
        ></Switch>
      </HStack>
    </FormControl>
  );
};
