import React, { createContext, useContext, useMemo } from "react";

import { SessionScene } from "../types";

export const SessionSceneContext = createContext<SessionScene>(
  SessionScene.Lobby
);

export interface ISessionSceneProps {
  wsSessionScene: SessionScene;
}

export const SessionSceneProvider: React.FC<ISessionSceneProps> = ({
  children,
  wsSessionScene,
}) => {
  const value = useMemo(() => wsSessionScene, [wsSessionScene]);

  return (
    <SessionSceneContext.Provider value={value}>
      {children}
    </SessionSceneContext.Provider>
  );
};

export const useSessionScene = (): SessionScene => {
  return useContext(SessionSceneContext);
};
