import { Flex, useMultiStyleConfig } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Avatar } from "adminComponents/atoms/Avatar";
import { Text } from "adminComponents/atoms/Text";
import { generateStudentName } from "adminComponents/utils/generateStudentName";
import { IUser } from "links/lib/types";

export interface IProps {
  student: IUser;
}

export const StudentPracticeSetBanner: React.FC<IProps> = ({ student }) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });
  const styles = useMultiStyleConfig("AdminStudentBanner", {});
  const studentName = generateStudentName(student).primary;

  return (
    <Flex sx={styles.banner}>
      <Avatar src={student.profile_image_url} />
      <Text variant="adminP1" sx={styles.mainText}>
        {t("studentBanner.practiceSetReport", { studentName })}
      </Text>
    </Flex>
  );
};
