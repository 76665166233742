import { Box, BoxProps, VisuallyHidden } from "@chakra-ui/react";
import React, { ReactElement, ReactNode } from "react";

import { ColorScheme } from "adminComponents";

import { ReactComponent as SvgAway } from "./resource/away.svg";
import { ReactComponent as SvgBack } from "./resource/back.svg";
import { ReactComponent as SvgBrush } from "./resource/brush.svg";
import { ReactComponent as SvgChat } from "./resource/chat.svg";
import { ReactComponent as SvgCheckmark } from "./resource/checkmark.svg";
import { ReactComponent as SvgCircleFilled } from "./resource/circle_filled.svg";
import { ReactComponent as SvgClipboard } from "./resource/clipboard.svg";
import { ReactComponent as SvgClose } from "./resource/close.svg";
import { ReactComponent as SvgCorrect } from "./resource/correct.svg";
import { ReactComponent as SvgDiagramOutlined } from "./resource/diagram_outlined.svg";
import { ReactComponent as SvgDrag } from "./resource/drag.svg";
import { ReactComponent as SvgEraser } from "./resource/eraser.svg";
import { ReactComponent as SvgExit } from "./resource/exit.svg";
import { ReactComponent as SvgForward } from "./resource/forward.svg";
import { ReactComponent as SvgIncorrect } from "./resource/incorrect.svg";
import { ReactComponent as LeftArrow } from "./resource/left-arrow.svg";
import { ReactComponent as SvgLock } from "./resource/lock.svg";
import { ReactComponent as SvgMenu } from "./resource/menu.svg";
import { ReactComponent as SvgPin } from "./resource/pin.svg";
import { ReactComponent as SvgPointer } from "./resource/pointer.svg";
import { ReactComponent as SvgReaction } from "./resource/reaction.svg";
import { ReactComponent as SvgRefresh } from "./resource/refresh.svg";
import { ReactComponent as SvgResize } from "./resource/resize.svg";
import { ReactComponent as SvgReveal } from "./resource/reveal.svg";
import { ReactComponent as SvgSearch } from "./resource/search.svg";
import { ReactComponent as SvgSoundOff } from "./resource/sound_off.svg";
import { ReactComponent as SvgSoundOn } from "./resource/sound_on.svg";
import { ReactComponent as SvgTeam } from "./resource/team.svg";
import { ReactComponent as Undo } from "./resource/undo.svg";
import { ReactComponent as SvgWaiting } from "./resource/waiting.svg";
import { ReactComponent as SvgXPBoost } from "./resource/xp_boost.svg";
import { ReactComponent as SvgXPBoostSolo } from "./resource/xp_boost_solo.svg";
import { ReactComponent as SvgZoomIn } from "./resource/zoom_in.svg";
import { ReactComponent as SvgZoomOut } from "./resource/zoom_out.svg";

export type IconType =
  | "reaction"
  | "pointer"
  | "waiting"
  | "checkmark"
  | "refresh"
  | "menu"
  | "incorrect"
  | "correct"
  | "brush"
  | "away"
  | "reveal"
  | "sound_on"
  | "sound_off"
  | "zoom_out"
  | "zoom_in"
  | "eraser"
  | "chat"
  | "drag"
  | "close"
  | "exit"
  | "circle_filled"
  | "pin"
  | "lock"
  | "resize"
  | "left_arrow"
  | "undo"
  | "clipboard"
  | "team"
  | "back"
  | "forward"
  | "search"
  | "diagram_outlined"
  | "xp_boost"
  | "xp_boost_solo";

export interface IIconProps extends BoxProps {
  icon: IconType;
  iconColor?: ColorScheme;
  decorative?: boolean;
  label?: string;
}

const IconResources: Record<IconType, ReactNode> = {
  away: <SvgAway height="100%" />,
  brush: <SvgBrush height="100%" />,
  chat: <SvgChat height="100%" />,
  checkmark: <SvgCheckmark height="100%" />,
  correct: <SvgCorrect height="100%" />,
  drag: <SvgDrag height="100%" />,
  eraser: <SvgEraser height="100%" />,
  incorrect: <SvgIncorrect height="100%" />,
  menu: <SvgMenu height="100%" />,
  pin: <SvgPin height="100%" />,
  pointer: <SvgPointer height="100%" />,
  search: <SvgSearch height="100%" />,
  reaction: <SvgReaction height="100%" />,
  refresh: <SvgRefresh height="100%" />,
  reveal: <SvgReveal height="100%" />,
  sound_off: <SvgSoundOff height="100%" />,
  sound_on: <SvgSoundOn height="100%" />,
  waiting: <SvgWaiting height="100%" />,
  zoom_in: <SvgZoomIn height="100%" />,
  zoom_out: <SvgZoomOut height="100%" />,
  close: <SvgClose height="100%" />,
  exit: <SvgExit height="100%" />,
  circle_filled: <SvgCircleFilled height="100%" />,
  lock: <SvgLock height="100%" />,
  resize: <SvgResize height="100%" />,
  left_arrow: <LeftArrow height="100%" />,
  undo: <Undo height="100%" />,
  clipboard: <SvgClipboard height="100%" />,
  team: <SvgTeam height="100%" />,
  back: <SvgBack height="100%" />,
  forward: <SvgForward height="100%" />,
  diagram_outlined: <SvgDiagramOutlined height="100%" />,
  xp_boost: <SvgXPBoost height="100%" />,
  xp_boost_solo: <SvgXPBoostSolo height="100%" />,
};

const getIcon = (icon: IconType, decorative: boolean): React.ReactNode => {
  if (React.isValidElement(IconResources[icon])) {
    const svg = IconResources[icon] as ReactElement;
    return React.cloneElement(
      svg,
      decorative ? { "aria-hidden": true, focusable: false } : undefined
    );
  }
};

const Icon: React.FC<IIconProps> = ({
  icon,
  iconColor = "primary.warm-black",
  decorative = false,
  label,
  ...rest
}) => {
  return (
    <Box
      display="inline-flex"
      justifyContent="center"
      alignItems="center"
      color={iconColor}
      {...rest}
    >
      <VisuallyHidden>{label ? label : icon}</VisuallyHidden>
      {getIcon(icon, decorative)}
    </Box>
  );
};

export { Icon };
