import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  PracticeSessionItemVariantType,
  SessionGameType,
} from "links/lib/types";
import { getRandomItem } from "links/lib/util";

export interface IUseRoundReviewInterstitialFlavorTextParams {
  outerGame?: SessionGameType;
  variant: PracticeSessionItemVariantType | undefined;
  groupName: string;
  studentCorrect: boolean;
  groupCorrect: boolean;
  groupSize: number;
  isClassSession: boolean;
}

interface IFlavorTextOption {
  key: string;
  params?: Record<string, unknown>;
}

enum FlavorTextCase {
  CoopDraw = "COOP_DRAW",
  StudentAndGroupCorrect = "STUDENT_CORRECT_GROUP_CORRECT",
  StudentCorrectGroupIncorrect = "STUDENT_CORECT_GROUP_INCORRECT",
  StudentIncorrectGroupCorrect = "STUDENT_INCORRECT_GROUP_CORRECT",
  StudentAndGroupIncorrect = "STUDENT_INCORRECT_GROUP_INCORRECT",
}

const getFlavorTextCase = ({
  variant,
  studentCorrect,
  groupCorrect,
}: {
  variant: PracticeSessionItemVariantType | undefined;
  studentCorrect: boolean;
  groupCorrect: boolean;
}): FlavorTextCase => {
  let flavorTextCase;
  if (variant === PracticeSessionItemVariantType.CoopDraw) {
    flavorTextCase = FlavorTextCase.CoopDraw;
  } else if (studentCorrect && groupCorrect) {
    flavorTextCase = FlavorTextCase.StudentAndGroupCorrect;
  } else if (studentCorrect && !groupCorrect) {
    flavorTextCase = FlavorTextCase.StudentCorrectGroupIncorrect;
  } else if (!studentCorrect && groupCorrect) {
    flavorTextCase = FlavorTextCase.StudentIncorrectGroupCorrect;
  } else {
    // !studentCorrect && !groupCorrect
    flavorTextCase = FlavorTextCase.StudentAndGroupIncorrect;
  }
  return flavorTextCase;
};

const getDrawFlavorTextKeyOptions = ({
  groupSize,
  groupName,
}: {
  groupSize: number;
  groupName: string;
}): IFlavorTextOption[] => {
  const options: IFlavorTextOption[] = [
    { key: "drawFlavorTextOptions.drawOn" },
    { key: "drawFlavorTextOptions.wayToDraw" },
    { key: "sharedFlavorTextOptions.goGroup", params: { group: groupName } },
  ];

  if (groupSize > 1) {
    options.push(
      { key: "drawFlavorTextOptions.drawTogether" },
      { key: "sharedFlavorTextOptions.teamwork" },
      { key: "sharedFlavorTextOptions.practicing" },
      { key: "drawFlavorTextOptions.drawTeam" }
    );
  }

  return options;
};

const getStudentAndGroupCorrectFlavorTextKeyOptions = ({
  outerGame,
  groupSize,
  groupName,
  isClassSession,
}: {
  outerGame: SessionGameType;
  groupSize: number;
  groupName: string;
  isClassSession: boolean;
}): IFlavorTextOption[] => {
  const options: IFlavorTextOption[] = [];
  switch (outerGame) {
    case SessionGameType.QuickPlay:
      return [
        { key: "correctFlavorTextOptions.quickPlay.heatingUp" },
        { key: "correctFlavorTextOptions.quickPlay.broughtFire" },
        { key: "correctFlavorTextOptions.quickPlay.onFire" },
        { key: "correctFlavorTextOptions.quickPlay.bright" },
        { key: "correctFlavorTextOptions.quickPlay.lightingUp" },
        { key: "correctFlavorTextOptions.quickPlay.sizzle" },
        { key: "correctFlavorTextOptions.quickPlay.scorch" },
        { key: "correctFlavorTextOptions.quickPlay.bringingHeat" },
        { key: "correctFlavorTextOptions.quickPlay.inextinguishable" },
        { key: "correctFlavorTextOptions.quickPlay.leaderBoardOnFire" },
      ];
    case SessionGameType.TheBigBoard:
    default:
      options.push(
        { key: "correctFlavorTextOptions.ding" },
        { key: "correctFlavorTextOptions.studying" }
      );
  }

  if (isClassSession) {
    options.push({
      key: "sharedFlavorTextOptions.goGroup",
      params: { group: groupName },
    });
  }

  if (groupSize > 1) {
    options.push(
      { key: "correctFlavorTextOptions.greatMinds" },
      { key: "correctFlavorTextOptions.practiceTogether" },
      { key: "sharedFlavorTextOptions.teamwork" },
      { key: "sharedFlavorTextOptions.practicing" },
      { key: "correctFlavorTextOptions.dreamTeam" },
      { key: "correctFlavorTextOptions.oneForAll" }
    );
  }

  return options;
};

const getStudentCorrectGroupIncorrectFlavorTextKeyOptions = ({
  outerGame,
}: {
  outerGame: SessionGameType;
}): IFlavorTextOption[] => {
  if (outerGame === SessionGameType.QuickPlay) {
    return [
      { key: "correctFlavorTextOptions.quickPlay.groupIncorrect.heatingUp" },
      { key: "correctFlavorTextOptions.quickPlay.groupIncorrect.sun" },
      { key: "correctFlavorTextOptions.quickPlay.groupIncorrect.success" },
      { key: "correctFlavorTextOptions.quickPlay.groupIncorrect.sizzle" },
      { key: "correctFlavorTextOptions.quickPlay.groupIncorrect.cylinders" },
      { key: "correctFlavorTextOptions.quickPlay.groupIncorrect.scoreboard" },
      { key: "correctFlavorTextOptions.quickPlay.groupIncorrect.ignited" },
      { key: "correctFlavorTextOptions.quickPlay.groupIncorrect.onFire" },
      { key: "correctFlavorTextOptions.quickPlay.groupIncorrect.bringingHeat" },
      { key: "correctFlavorTextOptions.quickPlay.groupIncorrect.onFireYou" },
    ];
  }

  return [
    { key: "correctFlavorTextOptions.swish" },
    { key: "correctFlavorTextOptions.believed" },
    { key: "correctFlavorTextOptions.trendsetter" },
    { key: "correctFlavorTextOptions.studying" },
    { key: "correctFlavorTextOptions.tide" },
    { key: "correctFlavorTextOptions.leading" },
    { key: "correctFlavorTextOptions.confident" },
    { key: "correctFlavorTextOptions.mvp" },
    { key: "correctFlavorTextOptions.practice" },
    { key: "correctFlavorTextOptions.outsideBox" },
  ];
};

const getStudentIncorrectGroupCorrectFlavorTextKeyOptions = ({
  outerGame,
}: {
  outerGame: SessionGameType;
}): IFlavorTextOption[] => {
  if (outerGame === SessionGameType.QuickPlay) {
    return [
      { key: "incorrectFlavorTextOptions.quickPlay.groupCorrect.warmerTrying" },
      { key: "incorrectFlavorTextOptions.quickPlay.groupCorrect.extinguish" },
      { key: "incorrectFlavorTextOptions.quickPlay.groupCorrect.warmedUp" },
      { key: "incorrectFlavorTextOptions.quickPlay.groupCorrect.scorcher" },
      { key: "incorrectFlavorTextOptions.quickPlay.groupCorrect.sweat" },
      { key: "incorrectFlavorTextOptions.quickPlay.groupCorrect.freeze" },
      { key: "incorrectFlavorTextOptions.quickPlay.groupCorrect.fiery" },
      { key: "incorrectFlavorTextOptions.quickPlay.groupCorrect.onFire" },
      { key: "incorrectFlavorTextOptions.quickPlay.groupCorrect.roaring" },
      { key: "incorrectFlavorTextOptions.quickPlay.groupCorrect.warmerGoing" },
    ];
  }

  return [
    { key: "partiallyCorrectFlavorTextOptions.soClose" },
    { key: "partiallyCorrectFlavorTextOptions.darn" },
    { key: "partiallyCorrectFlavorTextOptions.notQuite" },
    { key: "partiallyCorrectFlavorTextOptions.notThisTime" },
    { key: "partiallyCorrectFlavorTextOptions.outside" },
    { key: "partiallyCorrectFlavorTextOptions.alwaysLearning" },
    { key: "partiallyCorrectFlavorTextOptions.almost" },
    { key: "partiallyCorrectFlavorTextOptions.helpFromFriends" },
    { key: "partiallyCorrectFlavorTextOptions.keepTrying" },
    { key: "partiallyCorrectFlavorTextOptions.teamWork" },
  ];
};

const getStudentAndGroupIncorrectFlavorTextKeyOptions = ({
  outerGame,
  groupSize,
}: {
  outerGame: SessionGameType;
  groupSize: number;
}): IFlavorTextOption[] => {
  if (outerGame === SessionGameType.QuickPlay) {
    return [
      { key: "incorrectFlavorTextOptions.quickPlay.ignite" },
      { key: "incorrectFlavorTextOptions.quickPlay.burning" },
      { key: "incorrectFlavorTextOptions.quickPlay.flame" },
      { key: "incorrectFlavorTextOptions.quickPlay.sweat" },
      { key: "incorrectFlavorTextOptions.quickPlay.freeze" },
      { key: "incorrectFlavorTextOptions.quickPlay.furnace" },
      { key: "incorrectFlavorTextOptions.quickPlay.warmer" },
      { key: "incorrectFlavorTextOptions.quickPlay.extinguish" },
      { key: "incorrectFlavorTextOptions.quickPlay.warmedUp" },
      { key: "incorrectFlavorTextOptions.quickPlay.ashes" },
    ];
  }

  const options: IFlavorTextOption[] = [
    { key: "incorrectFlavorTextOptions.goodEffort" },
    { key: "incorrectFlavorTextOptions.notQuite" },
    { key: "incorrectFlavorTextOptions.believing" },
    { key: "incorrectFlavorTextOptions.greatTry" },
    { key: "incorrectFlavorTextOptions.cantWinEmAll" },
    { key: "incorrectFlavorTextOptions.youGotThis" },
    { key: "incorrectFlavorTextOptions.sadTrombone" },
    { key: "incorrectFlavorTextOptions.nextTime" },
  ];

  if (groupSize > 1) {
    options.push({ key: "incorrectFlavorTextOptions.allNextTime" });
  }

  return options;
};

const getFlavorTextKeyOptions = ({
  outerGame = SessionGameType.TheBigBoard,
  variant,
  groupName,
  studentCorrect,
  groupCorrect,
  groupSize,
  isClassSession,
}: IUseRoundReviewInterstitialFlavorTextParams): IFlavorTextOption[] => {
  let flavorTextKeyOptions;

  switch (getFlavorTextCase({ variant, studentCorrect, groupCorrect })) {
    case FlavorTextCase.CoopDraw:
      flavorTextKeyOptions = getDrawFlavorTextKeyOptions({
        groupSize,
        groupName,
      });
      break;
    case FlavorTextCase.StudentAndGroupCorrect:
      flavorTextKeyOptions = getStudentAndGroupCorrectFlavorTextKeyOptions({
        outerGame,
        groupName,
        groupSize,
        isClassSession,
      });
      break;
    case FlavorTextCase.StudentCorrectGroupIncorrect:
      flavorTextKeyOptions =
        getStudentCorrectGroupIncorrectFlavorTextKeyOptions({ outerGame });
      break;
    case FlavorTextCase.StudentIncorrectGroupCorrect:
      flavorTextKeyOptions =
        getStudentIncorrectGroupCorrectFlavorTextKeyOptions({ outerGame });
      break;
    case FlavorTextCase.StudentAndGroupIncorrect:
      flavorTextKeyOptions = getStudentAndGroupIncorrectFlavorTextKeyOptions({
        outerGame,
        groupSize,
      });
      break;
  }

  return flavorTextKeyOptions;
};

export const useRoundReviewInterstitialFlavorText = ({
  outerGame = SessionGameType.TheBigBoard,
  variant,
  groupName,
  studentCorrect,
  groupCorrect,
  groupSize,
  isClassSession,
}: IUseRoundReviewInterstitialFlavorTextParams): string => {
  const { t } = useTranslation("session", {
    keyPrefix: "roundReviewInterstitial",
    useSuspense: false,
  });

  const flavorTextOption = useMemo(() => {
    const options = getFlavorTextKeyOptions({
      outerGame,
      variant,
      groupName,
      studentCorrect,
      groupCorrect,
      groupSize,
      isClassSession,
    });

    return getRandomItem(options);
  }, [
    outerGame,
    variant,
    groupName,
    studentCorrect,
    groupCorrect,
    groupSize,
    isClassSession,
  ]);

  return t(flavorTextOption.key, flavorTextOption.params);
};
