import { ISessionGroup, ISessionGroups } from "links/lib/types";

export const getGroupWithWarning = (
  groups: ISessionGroups,
  groupId: string
): ISessionGroup | undefined => {
  const group = groups[groupId];

  if (!group) {
    console.error(`Group with ID ${groupId} not found`);
  }

  return group;
};
