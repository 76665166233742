import { Box } from "@chakra-ui/react";
import { useRive } from "@rive-app/react-canvas";
import React from "react";
import { useEffect } from "react";

import { pxToRem } from "adminComponents/utils";

interface IRiveIconProps {
  src: string;
  isPlaying: boolean;
  stateMachines?: string | string[];
  animations?: string | string[];
}

export const RiveIcon: React.FC<IRiveIconProps> = ({
  src,
  isPlaying,
  stateMachines,
  animations,
}) => {
  const { rive, RiveComponent } = useRive({
    src,
    autoplay: false,
    stateMachines,
    animations,
  });

  useEffect(() => {
    if (isPlaying) {
      rive?.play();
    } else {
      rive?.pause();
    }
  }, [isPlaying, rive]);

  return (
    <Box boxSize={pxToRem(68)}>
      <RiveComponent width={pxToRem(68)} height={pxToRem(68)} />
    </Box>
  );
};
