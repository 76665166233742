import {
  Box,
  HStack,
  Stack,
  Text,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { usePrevious, useTimeout } from "react-use";

import { Icon, IconType } from "adminComponents/atoms/Icon";
import { IconButtonWithTooltip } from "adminComponents/atoms/IconButtonWithTooltip";
import { Tag } from "adminComponents/atoms/Tag";
import { pxToRem } from "adminComponents/utils";
import {
  ExplainAction,
  ExplainMessage,
  ExplainMessageSender,
} from "links/lib/types";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { useRichTextToSpeakable } from "sessionComponents/hooks/useRichTextToSpeakable";
import { useTextReader } from "sessionComponents/hooks/useTextReader";
import { ChatMessageGroup } from "sessionComponents/molecules/ChatMessageGroup";
import { ConfirmButton } from "sessionComponents/molecules/ConfirmButton";
import { GameFlyout } from "sessionComponents/molecules/Flyout";
import { RichTextRenderer } from "sharedComponents/atoms/RichTextRenderer";

import ExplainAvatarGIF from "./resource/explain_avatar.gif";

const conversationRatings = [
  {
    rating: 1,
    labelKey: "notHelpful",
    icon: "thumb_down_outlined",
  },
  {
    rating: 2,
    labelKey: "helpful",
    icon: "thumb_up_outlined",
  },
  {
    rating: 3,
    labelKey: "veryHelpful",
    icon: "double_thumb_up_outlined",
  },
];

export interface IExplainFlyoutProps {
  isOpen?: boolean;
  handleClose: () => void;
  handleAction: (action: ExplainAction) => void;
  handleRateConversation: (rating: number) => void;
  isSystemResponding?: boolean;
  isRateConversationLoading?: boolean;
  isRateConversationSuccess?: boolean;
  messages: Array<ExplainMessage>;
  availableUserActions: Array<ExplainAction>;
  userProfileImageSrc: string;
  rating?: number;
}

export const ExplainFlyout: React.FC<IExplainFlyoutProps> = ({
  isOpen = false,
  handleClose,
  handleAction,
  handleRateConversation,
  isSystemResponding = false,
  isRateConversationLoading = false,
  isRateConversationSuccess = false,
  messages,
  userProfileImageSrc,
  availableUserActions,
  rating = 0,
}) => {
  const { match } = useBreakpoints();
  const { t } = useTranslation("session", {
    useSuspense: false,
    keyPrefix: "explainFlyout",
  });
  const {
    handleSpeak: _handleSpeak,
    handleCancelSpeak,
    isSpeaking,
    isSpeechEnabled,
  } = useTextReader();
  const { convert } = useRichTextToSpeakable();
  const [currentSpeakingMsgId, setCurrentSpeakingMsgId] =
    React.useState<string>("-1");

  const rateConversationPaddingLeft =
    useBreakpointValue({ base: 32, lg: 48 }) || 32;
  const [reviewSavedTimeout, , reset] = useTimeout(2000);
  const prevIsRateConversationSuccess = usePrevious(isRateConversationSuccess);

  useEffect(() => {
    if (
      isRateConversationSuccess &&
      prevIsRateConversationSuccess !== isRateConversationSuccess
    ) {
      reset();
    }
  }, [isRateConversationSuccess, prevIsRateConversationSuccess, reset]);

  const _isSpeaking = isSpeaking();
  useEffect(() => {
    if (_isSpeaking && !isOpen) {
      handleCancelSpeak();
    }
  }, [_isSpeaking, isOpen, handleCancelSpeak]);

  const actionLabelLookup: {
    [key in ExplainAction]: { label: string; icon?: IconType };
  } = {
    [ExplainAction.Detail]: {
      label: t("actionDetail"),
      icon: "sms_outlined",
    },
    [ExplainAction.Finish]: {
      label: t("actionFinish"),
    },
    [ExplainAction.Quiz]: {
      label: t("actionQuiz"),
      icon: "star_outlined",
    },
    [ExplainAction.Explain]: {
      label: t("actionExplain"),
      icon: "sms_outlined",
    },
    [ExplainAction.AnswerA]: { label: t("actionAnswerA"), icon: "check" },
    [ExplainAction.AnswerB]: { label: t("actionAnswerB"), icon: "check" },
    [ExplainAction.AnswerC]: { label: t("actionAnswerC"), icon: "check" },
    [ExplainAction.AnswerD]: { label: t("actionAnswerD"), icon: "check" },
  };

  const handleSpeak = (messageId: string) => {
    if (isSpeaking()) {
      handleCancelSpeak();

      // Don't re-read if trying to speak currently speaking message
      if (currentSpeakingMsgId === messageId) return;
    }

    const message = messages[parseInt(messageId, 10)];

    if (!message) return;

    _handleSpeak(convert(message.text));
    setCurrentSpeakingMsgId(messageId);
  };

  const makeMessageContent = (text: string) => {
    return (
      <Text lineHeight={1.2} fontSize={pxToRem(18)} fontWeight="400">
        {text}
      </Text>
    );
  };

  return (
    <GameFlyout isOpen={isOpen} onClose={handleClose} title={t("flyoutTitle")}>
      <Box
        w="full"
        h="full"
        bgColor="primary.warm-white"
        position="relative"
        overflow="hidden"
      >
        <Box w="full" h="full" overflow="auto">
          <VStack
            w="full"
            p={pxToRem(match.padding)}
            spacing={pxToRem(match.padding / 2)}
          >
            <Stack
              w="full"
              alignItems="center"
              justifyContent="center"
              direction={["column", null, "row"]}
            >
              <Tag colorScheme="white" leftIcon="info_outlined">
                {t("aiDisclosure")}
              </Tag>
              <Tag colorScheme="blue" leftIcon="account_circle_outlined">
                {t("beta")}
              </Tag>
            </Stack>
            {messages.map((message, i) => (
              <ChatMessageGroup
                flip={message.sender === ExplainMessageSender.User}
                messages={
                  message.sender === ExplainMessageSender.AI
                    ? [
                        {
                          id: i.toString(),
                          isSpeakable: isSpeechEnabled,
                          content: (
                            <RichTextRenderer
                              key={0}
                              content={message.text}
                              contentStyle={{
                                whiteSpace: "pre-line",
                              }}
                              containerStyle={{
                                fontSize: pxToRem(18),
                              }}
                            />
                          ),
                        },
                      ]
                    : [
                        {
                          isSpeakable: false,
                          id: i.toString(),
                          content: makeMessageContent(
                            actionLabelLookup[message.text].label
                          ),
                        },
                      ]
                }
                senderProfileImageSrc={
                  message.sender === ExplainMessageSender.AI
                    ? ExplainAvatarGIF
                    : userProfileImageSrc
                }
                handleSpeak={handleSpeak}
                key={i}
                bubbleColor={
                  message.sender === ExplainMessageSender.AI
                    ? "primary.tan"
                    : "primary.golden"
                }
              />
            ))}

            {isSystemResponding && (
              <ChatMessageGroup
                isLoading={true}
                bubbleColor="primary.tan"
                senderProfileImageSrc={ExplainAvatarGIF}
              />
            )}
            {!!availableUserActions.length && (
              <Stack
                w="full"
                pt={pxToRem(match.padding)}
                px={pxToRem(match.padding)}
                pl={pxToRem(rateConversationPaddingLeft + match.padding / 2)}
                alignItems="center"
                justifyContent="start"
                direction={["column", null, "row"]}
              >
                <Text>{t("reviewExplanation")}</Text>
                <HStack alignSelf={["center", null, "start"]}>
                  {conversationRatings.map((cr) => (
                    <IconButtonWithTooltip
                      key={cr.rating}
                      disabled={isRateConversationLoading}
                      shape="type1"
                      ariaLabel={t(cr.labelKey)}
                      icon={cr.icon as IconType}
                      colorScheme={
                        cr.rating === rating
                          ? "primary.golden"
                          : "primary.warm-white"
                      }
                      colorSchemeHover={
                        cr.rating === rating
                          ? "primary.golden-hover"
                          : "primary.light-gray"
                      }
                      tooltipProps={{
                        popoverVariant: "",
                      }}
                      onClick={() => {
                        if (cr.rating === rating) return;
                        handleRateConversation(cr.rating);
                      }}
                    />
                  ))}
                </HStack>
                {isRateConversationSuccess && !reviewSavedTimeout() && (
                  <Text>{t("reviewSaved")}</Text>
                )}
              </Stack>
            )}
            {!isSystemResponding && !!availableUserActions.length && (
              <Stack
                w="full"
                h="full"
                alignItems="center"
                justifyContent="center"
                pt={pxToRem(match.padding)}
                direction={["column", null, "row"]}
              >
                {availableUserActions
                  .sort((a, b) => {
                    // Ensure that "Done" appears last
                    if (a === ExplainAction.Finish) return 1;
                    if (b === ExplainAction.Finish) return -1;
                    return 0;
                  })
                  .map((action) => {
                    const icon = actionLabelLookup[action].icon;
                    const isFinishAction = action === ExplainAction.Finish;
                    return (
                      <ConfirmButton
                        sx={{
                          borderWidth: pxToRem(2),
                          fontWeight: "unset",
                        }}
                        key={action}
                        variant={
                          isFinishAction ? "buttonFilled" : "buttonOutlined"
                        }
                        onClick={() => {
                          if (isFinishAction) {
                            handleClose();
                          } else {
                            handleAction(action);
                          }
                        }}
                        leftIcon={icon ? <Icon icon={icon} /> : undefined}
                        confirmedWhenChanged={[messages]}
                      >
                        {actionLabelLookup[action].label}
                      </ConfirmButton>
                    );
                  })}
              </Stack>
            )}
          </VStack>
        </Box>
      </Box>
    </GameFlyout>
  );
};
