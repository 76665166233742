import { Box, Flex, HStack, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useCopyToClipboard } from "react-use";

import { pxToRem } from "adminComponents/utils";
import { useShowToast } from "adminComponents/utils/toast";
import { config } from "links/lib/constants";
import { AnalyticsEvent } from "links/lib/types";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { useSessionAnalytics } from "sharedComponents/contexts/sessionAnalytics";

import { Icon } from "../Icon";
import { Tooltip } from "../Tooltip";

export interface IProps {
  sessionCode: string;
  hideHost?: boolean;
}

export const SessionIndicator: React.FC<IProps> = ({
  sessionCode,
  hideHost,
}) => {
  const { match: currentBreakpoints } = useBreakpoints();
  const [, copyToClipboard] = useCopyToClipboard();
  const showToast = useShowToast();
  const { track } = useSessionAnalytics();

  const { t } = useTranslation("session", {
    keyPrefix: "joinSessionCard",
    useSuspense: false,
  });

  const fontSize = pxToRem(currentBreakpoints.fontSize);
  const sessionCodeFontSize = currentBreakpoints.fontSize * 1.2;
  const generalPadding = currentBreakpoints.padding / 2;
  const padding = pxToRem(generalPadding);
  const horizontalPadding = pxToRem(generalPadding * 1.5);

  const host = config.joinHost || "play.peardeck.com";
  const joinUrl = `${config.joinBaseUrl}/${sessionCode.toLocaleLowerCase()}`;

  const onCopyLink = () => {
    copyToClipboard(joinUrl);
    showToast(t("copiedLink"));

    track(AnalyticsEvent.Session_Common_CopyJoinLink, {});
  };

  return (
    <HStack cursor="pointer" onClick={onCopyLink}>
      <HStack
        borderRadius="xl"
        background="sessionSecondary.light"
        width="fit-content"
        spacing={0}
      >
        {!hideHost && (
          <Box paddingX={horizontalPadding}>
            <Text textStyle="gameText" whiteSpace="nowrap" fontSize={fontSize}>
              {host}
            </Text>
          </Box>
        )}
        <Box
          paddingY={pxToRem(5)}
          paddingX={horizontalPadding}
          background="sessionSecondary.base"
          borderRightRadius="xl"
          borderLeftRadius={hideHost ? "xl" : undefined}
        >
          <Text textStyle="sessionCode" fontSize={pxToRem(sessionCodeFontSize)}>
            {sessionCode.toUpperCase()}
          </Text>
        </Box>
      </HStack>
      <Tooltip tooltipContent={t("copyJoinLink")}>
        <Box h="full" p={padding}>
          <Flex h="full" align="center" justify="center" w="fit-content">
            <Icon
              as="button"
              icon="clipboard"
              iconColor="utility.link"
              boxSize={pxToRem(currentBreakpoints.iconSize)}
              aria-label={t("copyJoinLink")}
            />
          </Flex>
        </Box>
      </Tooltip>
    </HStack>
  );
};
