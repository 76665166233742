import { useMemo } from "react";

import { IPracticeSetProps } from "adminComponents/screens/StudentDetailScreen/components/PracticeSetsTab";
import { useErrorToast } from "adminComponents/utils/toast";
import {
  useFetchAveragePracticeSetAccuracies,
  useFetchClassroomPracticeSets,
  useFetchClassroomUsers,
} from "links/lib/features/classrooms";
import { useFetchPracticeSets } from "links/lib/features/practiceSets";
import { UsersGroupsRole } from "links/lib/types";
import { sortAlpha } from "links/lib/util";

interface IUsePracticeSetsDataParam {
  classroomId: string;
  studentId: string;
}

interface IUsePracticeSetsDataResponse {
  practiceSetData: IPracticeSetProps[];
  handleViewMore: () => void;
  isLoading: boolean;
}

export const usePracticeSetsData = ({
  classroomId,
  studentId,
}: IUsePracticeSetsDataParam): IUsePracticeSetsDataResponse => {
  const fetchTeacherUsers = useFetchClassroomUsers({
    classroom_id: classroomId,
    group_roles: [UsersGroupsRole.Owner, UsersGroupsRole.Assistant],
  });
  useErrorToast(fetchTeacherUsers.error);

  const teacherIds = useMemo(() => {
    return fetchTeacherUsers.data?.users.map((u) => u.id) || [];
  }, [fetchTeacherUsers.data]);

  const classroomPracticeSetsFetch = useFetchClassroomPracticeSets({
    classroom_id: classroomId,
  });
  useErrorToast(classroomPracticeSetsFetch.error);

  const accuraciesFetch = useFetchAveragePracticeSetAccuracies({
    classroom_id: classroomId,
    user_id: studentId,
  });
  useErrorToast(accuraciesFetch.error);

  const practiceSetIds = useMemo(() => {
    return (
      classroomPracticeSetsFetch.data?.classroom_practice_sets.map(
        (p) => p.practice_set_id
      ) || []
    );
  }, [classroomPracticeSetsFetch.data]);

  const practiceSetsFetch = useFetchPracticeSets({
    ids: practiceSetIds,
    classroom_id: classroomId,
    created_by_user_ids: teacherIds,
    include_most_recent_live_practice_timestamp: true,
    include_most_recent_assignment_timestamp: true,
    enabled: !!practiceSetIds.length && !!teacherIds.length,
  });
  useErrorToast(practiceSetsFetch.error);

  const handleViewMore = () => {
    // No-op - currently paging is not implemented
    return;
  };

  const practiceSetData = useMemo(() => {
    const accuracyMap: { [practiceSetId: string]: number } = {};

    accuraciesFetch.data?.average_practice_set_accuracies.forEach((a) => {
      accuracyMap[a.practice_set_id] = a.average_accuracy;
    });

    const data =
      practiceSetsFetch.data?.practice_sets.map((practiceSet) => {
        const accuracy = accuracyMap[practiceSet.id];

        return {
          practiceSet,
          accuracy: isNaN(accuracy) ? undefined : Math.round(accuracy * 100),
        };
      }) || [];

    data.sort((a, b) => {
      return sortAlpha(a.practiceSet.title, b.practiceSet.title);
    });

    return data;
  }, [accuraciesFetch.data, practiceSetsFetch.data]);

  return {
    practiceSetData,
    handleViewMore,
    isLoading:
      classroomPracticeSetsFetch.isLoading &&
      accuraciesFetch.isLoading &&
      practiceSetsFetch.isLoading,
  };
};
