import { pxToRem } from "adminComponents/utils/pxToRem";

export const AdminTeacherAgreementsScreen = {
  baseStyle: {
    outer: {
      maxWidth: pxToRem(634),
      backgroundColor: "primary.white",
    },
    headerContainer: {
      flexDirection: "column",
      gap: pxToRem(4),
    },
    pageContainer: {
      paddingY: pxToRem(16),
    },
    fieldContainer: {
      flexDirection: "column",
      gap: pxToRem(20),
      color: "primary.medium-gray",
    },
    tosContainer: {
      borderWidth: pxToRem(1),
      borderColor: "primary.medium-gray",
      padding: pxToRem(14),
      borderRadius: pxToRem(8),
      marginBottom: pxToRem(4),
    },
    button: {
      height: pxToRem(48),
      width: "100%",
      maxWidth: ["100%", null, pxToRem(162)],
    },
    buttonContainer: {
      justifyContent: ["center", null, "flex-end"],
      gap: pxToRem(10),
    },
  },
};
