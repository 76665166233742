import { pxToRem } from "adminComponents/utils/pxToRem";

export const AdminLevelProgressBar = {
  parts: ["container", "level"],
  baseStyle: {
    container: {
      position: "relative",
    },
    level: {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: pxToRem(25),
      display: "flex",
      alignItems: "center",
      zIndex: 10,
    },
  },
};
