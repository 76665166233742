import { QuestionIcon } from "@chakra-ui/icons";
import {
  Alert,
  Box,
  HStack,
  Image,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";
import { ParseResult } from "papaparse";
import React, { ChangeEvent, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCSVReader } from "react-papaparse";

import { Accordion, AccordionData } from "adminComponents/atoms/Accordion";
import { Button } from "adminComponents/atoms/Button";
import { Card } from "adminComponents/atoms/Card";
import { Checkbox } from "adminComponents/atoms/Checkbox";
import { Heading } from "adminComponents/atoms/Heading";
import { IconTooltip } from "adminComponents/atoms/IconTooltip";
import { Text } from "adminComponents/atoms/Text";
import { Textarea } from "adminComponents/atoms/Textarea";
import { ICSVReaderProps } from "adminComponents/organisms/PracticeSetImportQuestionsFlyout";
import { pxToRem } from "adminComponents/utils";
import {
  ErrorType,
  IAddUsersToOrganizationResponse,
} from "links/lib/features/organizations/useAddUsersToOrganization";

import ClapSVG from "./assets/clap.svg";

type AddUsersModalProps = {
  isOpen: boolean;
  result?: IAddUsersToOrganizationResponse;
  onClose: () => void;
  onSubmit: (emails: Array<string>, sendEmailNotifications: boolean) => void;
};

enum Screen {
  Input = 1,
  Outcome = 2,
}

export const AddUsersModal: React.FC<AddUsersModalProps> = ({
  isOpen,
  result,
  onClose,
  onSubmit,
}: AddUsersModalProps) => {
  const { CSVReader } = useCSVReader();
  const [inputValue, setInputValue] = useState("");
  const [parsedEmails, setParsedEmails] = useState<Array<string>>([]);
  const [error, setError] = useState("");
  const [screen, setScreen] = useState(Screen.Input);
  const [sendEmailNotifications, setSendEmailNotifications] = useState(true);
  const { t } = useTranslation("admin", {
    keyPrefix: "teacherStaffManagement.addUsersModal",
  });

  const onChangeList = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const list = e.target.value;
    setInputValue(list);
    setParsedEmails(parseEmails(list));
  };

  const parseEmails = (list: string): Array<string> => {
    return Array.from(
      new Set(
        list
          .split("\n")
          .join(",")
          .split(",")
          .map((email) => email.trim())
          .filter((email) => email.includes("@") && email.length > 1)
      )
    );
  };

  const onUploadFile = (result: ParseResult<Array<string>>) => {
    if (result.errors.length > 0) {
      console.log("Errors parsing uploaded CSV:", result.errors);
      setError(result.errors[0].message);
    }

    const parsedEmails = parseEmails(result.data.join("\n"));
    setInputValue(parsedEmails.join("\n"));
    setParsedEmails(parsedEmails);
  };

  const addedCount = useMemo(() => {
    return (
      (
        result?.results.filter(
          (result) => result.error_type === ErrorType.None
        ) || []
      ).length || 0
    );
  }, [result]);

  const errorResultsByType = useMemo(() => {
    if (!result) return;
    const emailsGroupedByErrorType = result.results.reduce((prev, curr) => {
      const errorType = curr.error_type as string;
      const list = [...(prev[errorType] || []), curr.email_address];
      return {
        ...prev,
        [errorType]: list,
      };
    }, {} as { [key: string]: Array<string> });

    Object.keys(emailsGroupedByErrorType).forEach((errorType) => {
      emailsGroupedByErrorType[errorType].sort((a, b) => {
        return a < b ? -1 : a > b ? 1 : 0;
      });
    });

    return emailsGroupedByErrorType;
  }, [result]);

  const importHadErrors = useMemo(() => {
    if (!errorResultsByType) return false;
    return (
      Object.keys(errorResultsByType).filter((key) => key !== ErrorType.None)
        .length > 0
    );
  }, [errorResultsByType]);

  const submit = () => {
    onSubmit(parsedEmails, sendEmailNotifications);
    setScreen(Screen.Outcome);
  };

  const onStartOver = () => {
    setInputValue("");
    setParsedEmails([]);
    setScreen(Screen.Input);
  };

  const accordionContent = useMemo<AccordionData[]>(() => {
    const accordionData: AccordionData[] = [];
    if (!errorResultsByType) return accordionData;

    if (errorResultsByType[ErrorType.None]) {
      accordionData.push({
        alertLabel: t("usersCount", {
          count: errorResultsByType[ErrorType.None].length,
        }),
        alertStatus: "success",
        title: t("labelSuccessfullyInvited"),
        content: <EmailList emails={errorResultsByType[ErrorType.None]} />,
      });
    }

    if (errorResultsByType[ErrorType.AlreadyInOrganization]) {
      accordionData.push({
        alertLabel: t("usersCount", {
          count: errorResultsByType[ErrorType.AlreadyInOrganization].length,
        }),
        alertStatus: "warning",
        title: t("labelAlreadyInOrganization"),
        content: (
          <EmailList
            emails={errorResultsByType[ErrorType.AlreadyInOrganization]}
          />
        ),
      });
    }

    if (errorResultsByType[ErrorType.HasExistingOrganization]) {
      accordionData.push({
        alertLabel: t("usersCount", {
          count: errorResultsByType[ErrorType.HasExistingOrganization].length,
        }),
        alertStatus: "error",
        title: t("labelHasExistingOrganization"),
        content: (
          <EmailList
            emails={errorResultsByType[ErrorType.HasExistingOrganization]}
          />
        ),
      });
    }

    if (errorResultsByType[ErrorType.IsStudent]) {
      accordionData.push({
        alertLabel: t("usersCount", {
          count: errorResultsByType[ErrorType.IsStudent].length,
        }),
        alertStatus: "error",
        title: t("labelIsStudent"),
        content: <EmailList emails={errorResultsByType[ErrorType.IsStudent]} />,
      });
    }

    if (errorResultsByType[ErrorType.InvalidEmail]) {
      accordionData.push({
        alertLabel: t("usersCount", {
          count: errorResultsByType[ErrorType.InvalidEmail].length,
        }),
        alertStatus: "error",
        title: t("labelInvalidEmail"),
        content: (
          <EmailList emails={errorResultsByType[ErrorType.InvalidEmail]} />
        ),
      });
    }

    if (errorResultsByType[ErrorType.Internal]) {
      accordionData.push({
        alertLabel: t("usersCount", {
          count: errorResultsByType[ErrorType.Internal].length,
        }),
        alertStatus: "error",
        title: t("labelInternal"),
        content: <EmailList emails={errorResultsByType[ErrorType.Internal]} />,
      });
    }

    return accordionData;
  }, [errorResultsByType, t]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl" isCentered={true}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          {screen === Screen.Input && (
            <VStack
              w="full"
              alignItems="flex-start"
              spacing={pxToRem(24)}
              p={pxToRem(32)}
              pt={0}
            >
              <VStack alignItems="flex-start">
                <Heading as="h6" variant="adminH6">
                  {t("inputScreen.heading")}
                </Heading>
                <Text color="primary.dark-gray">
                  {t("inputScreen.subHeading")}
                </Text>
              </VStack>
              <Textarea
                value={inputValue}
                onChange={onChangeList}
                h={pxToRem(120)}
                maxH="40vh"
              ></Textarea>

              <UnorderedList pl={pxToRem(24)}>
                <ListItem>
                  <Text color="primary.dark-gray">
                    {t("inputScreen.emailRules1")}
                  </Text>
                </ListItem>
                <ListItem>
                  <Text color="primary.dark-gray">
                    {t("inputScreen.emailRules2")}
                  </Text>
                </ListItem>
                <ListItem>
                  <Text color="primary.dark-gray">
                    {t("inputScreen.emailRules3")}
                  </Text>
                </ListItem>
              </UnorderedList>

              <HStack>
                <Checkbox
                  isChecked={sendEmailNotifications}
                  onChange={() => {
                    setSendEmailNotifications(!sendEmailNotifications);
                  }}
                >
                  <HStack>
                    <Text>{t("inputScreen.labelSendEmailsNow")}</Text>
                    <IconTooltip
                      placement="top-end"
                      triggerEl={
                        <QuestionIcon
                          color="utility.link"
                          width={pxToRem(24)}
                          height={pxToRem(24)}
                        />
                      }
                    >
                      {t("inputScreen.tooltipSendEmailsNow")}
                    </IconTooltip>
                  </HStack>
                </Checkbox>
              </HStack>
              {error && <Alert status="warning">{error}</Alert>}
              <HStack>
                <Button
                  variant="adminButtonFilled"
                  onClick={parsedEmails.length > 0 ? submit : undefined}
                  disabled={parsedEmails.length === 0}
                >
                  {t("inputScreen.buttonAddUsers", {
                    count: parsedEmails.length,
                  })}
                </Button>
                <CSVReader onUploadAccepted={onUploadFile}>
                  {({ getRootProps }: ICSVReaderProps) => {
                    return (
                      <VStack>
                        <Button
                          variant="adminButtonOutlined"
                          onClick={getRootProps().onClick}
                        >
                          {t("inputScreen.buttonUploadCSV")}
                        </Button>
                      </VStack>
                    );
                  }}
                </CSVReader>
                <Button variant="adminButtonOutlined" onClick={onClose}>
                  {t("inputScreen.buttonCancel")}
                </Button>
              </HStack>
            </VStack>
          )}

          {screen === Screen.Outcome && (
            <VStack
              w="full"
              alignItems="flex-start"
              spacing={pxToRem(24)}
              p={pxToRem(32)}
              pt={0}
            >
              <VStack alignItems="flex-start">
                <HStack>
                  <Heading as="h6" variant="adminH6">
                    {importHadErrors
                      ? t("outcomeScreen.headingError")
                      : t("outcomeScreen.heading", {
                          count: addedCount,
                        })}
                  </Heading>
                  {!importHadErrors && <Image h={pxToRem(24)} src={ClapSVG} />}
                </HStack>
                {importHadErrors ? (
                  <Text color="primary.dark-gray">
                    {t("outcomeScreen.subHeadingError")}
                  </Text>
                ) : (
                  <Text color="primary.dark-gray">
                    {t("outcomeScreen.subHeadingSuccess")}
                  </Text>
                )}
              </VStack>

              {importHadErrors && (
                <Card
                  borderColor="primary.tan"
                  backgroundColor="primary.white"
                  variant="adminCardThickBorder"
                >
                  <Box color="primary.warm-black">
                    <Accordion accordions={accordionContent}></Accordion>
                  </Box>
                </Card>
              )}

              <HStack>
                <Button variant="adminButtonFilled" onClick={onClose}>
                  {t("outcomeScreen.buttonDone")}
                </Button>
                <Button variant="adminButtonOutlined" onClick={onStartOver}>
                  {t("outcomeScreen.buttonStartOver")}
                </Button>
              </HStack>
            </VStack>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

type EmailListProps = {
  emails: Array<string>;
};

export const EmailList: React.FC<EmailListProps> = ({
  emails,
}: EmailListProps) => {
  return (
    <VStack
      w="full"
      alignItems="flex-start"
      maxH={pxToRem(160)}
      overflowY="auto"
    >
      {emails.map((email) => {
        return <Box key={`${email}`}>{email}</Box>;
      })}
    </VStack>
  );
};
