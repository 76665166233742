import { Box, Flex } from "@chakra-ui/react";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Heading } from "adminComponents/atoms/Heading";
import { Icon } from "adminComponents/atoms/Icon";
import { Tag } from "adminComponents/atoms/Tag";
import { SearchInput } from "adminComponents/molecules/SearchInput";
import { pxToRem } from "adminComponents/utils";
import { useAuth } from "links/lib/features/auth";
import { useFetchStandards } from "links/lib/features/standards";
import { IGradeLevel, ISubject } from "links/lib/types";

export interface IProps {
  gradeLevelIds?: string[];
  gradeLevels: IGradeLevel[];
  handleClearAllFilters: () => void;
  handleClearSearchInput?: () => void;
  handleOpenFilterFlyout: () => void;
  handleRemoveFilterTag: (
    category: "subject" | "grade" | "standard",
    id: string
  ) => void;
  handleSetSearchTerm: (searchTerm: string) => void;
  handleSearch?: (search: string) => void;
  handleSuggest?: (searchTerm: string) => void;
  initialSearchTerm?: string;
  isLoading: boolean;
  searchPlaceholder?: string;
  standardIds?: string[];
  subjectIds?: string[];
  subjects: ISubject[];
  totalCount?: number;
  "aria-labelled-by"?: string;
  inputId?: string;
  showFilterButton?: boolean;
  showActiveFilters?: boolean;
  suggestions?: string[];
}

export const LibrarySearchFilters: React.FC<IProps> = ({
  gradeLevelIds,
  gradeLevels,
  handleClearAllFilters,
  handleClearSearchInput: _handleClearSearchInput,
  handleOpenFilterFlyout,
  handleRemoveFilterTag,
  handleSetSearchTerm,
  handleSearch,
  handleSuggest,
  initialSearchTerm = "",
  isLoading,
  searchPlaceholder,
  standardIds,
  subjectIds,
  subjects,
  suggestions,
  totalCount,
  "aria-labelled-by": ariaLabelledBy,
  inputId,
  showFilterButton = true,
  showActiveFilters = true,
}) => {
  const { authUser } = useAuth();
  const { t } = useTranslation("admin", {
    keyPrefix: "common",
    useSuspense: false,
  });
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);

  const _handleSearch = (search: string) => {
    setSearchTerm(search);
    handleSetSearchTerm(search);
    handleSearch?.(search);
  };

  const fetchStandards = useFetchStandards({
    ids: standardIds,
    offset: 0,
    limit: (standardIds || []).length,
    enabled: (standardIds || []).length > 0,
  });

  const filtersCount =
    (subjectIds?.length || 0) +
    (standardIds?.length || 0) +
    (gradeLevelIds?.length || 0);

  const renderTag = useCallback(
    (category: "subject" | "grade" | "standard", id: string, label: string) => (
      <Tag
        handleClickRightIcon={() => handleRemoveFilterTag(category, id)}
        key={id}
        mb={pxToRem(6)}
        mr={pxToRem(12)}
        mt={pxToRem(6)}
        rightIcon="close"
      >
        {label}
      </Tag>
    ),
    [handleRemoveFilterTag]
  );

  const handleClearSearchInput = () => {
    setSearchTerm("");
    _handleClearSearchInput?.();
  };

  // Only show parent tags for subject if present
  const subjectTags = useMemo(() => {
    const parentIdMap: { [key: string]: true } = {};

    return (subjectIds || [])
      .map((subjectId) => {
        return subjects.find((subject) => subject.id === subjectId);
      })
      .map((subject) => {
        if (subject && subject.parent_id === "0")
          parentIdMap[subject.id] = true;

        return subject;
      })
      .filter((subject) => {
        return !!subject && !parentIdMap[subject.parent_id];
      })
      .map((subject) => {
        return subject ? renderTag("subject", subject.id, subject.name) : null;
      });
  }, [subjects, subjectIds, renderTag]);

  return (
    <Box>
      <SearchInput
        handleClear={handleClearSearchInput}
        handleSearch={_handleSearch}
        handleSuggest={handleSuggest}
        initialSearch={initialSearchTerm}
        placeholder={searchPlaceholder}
        searching={isLoading}
        suggestions={suggestions}
        id={inputId}
        aria-labelledby={ariaLabelledBy}
      />
      <Flex
        alignItems={{ base: "left", md: "center" }}
        direction={{ base: "column-reverse", md: "row" }}
        justifyContent="space-between"
        mt={pxToRem(24)}
      >
        {showActiveFilters && (
          <Box>
            {totalCount !== undefined && (
              <Heading
                as="p"
                color="primary.warm-black"
                mb={{ base: pxToRem(6), md: 0 }}
                variant="adminH6"
              >
                {t(searchTerm ? "resultCountFor" : "resultCount", {
                  count: totalCount,
                  countFormatted: totalCount.toLocaleString(authUser?.language),
                  searchTerm: searchTerm,
                })}
              </Heading>
            )}
            <Flex alignItems="center" wrap="wrap">
              {subjectTags}
              {gradeLevelIds?.map((gradeLevelId) => {
                const gradeLevel = gradeLevels.find(
                  (gradeLevel) => gradeLevel.id === gradeLevelId
                );

                return gradeLevel
                  ? renderTag("grade", gradeLevelId, gradeLevel.grade_level)
                  : null;
              })}
              {fetchStandards.data &&
                standardIds?.map((standardId) => {
                  const standard = fetchStandards.data.standards.find(
                    (s) => s.id === standardId
                  );
                  if (!standard) return;

                  return renderTag(
                    "standard",
                    standard.id,
                    standard.root_label || standard.label
                  );
                })}
              {filtersCount > 0 && (
                <Button
                  onClick={handleClearAllFilters}
                  variant="adminTextButtonLarge"
                >
                  {t("clearAll")}
                </Button>
              )}
            </Flex>
          </Box>
        )}
        {showFilterButton && (
          <Box>
            <Button
              leftIcon={<Icon icon="filter" />}
              mb={{ base: pxToRem(24), md: 0 }}
              onClick={handleOpenFilterFlyout}
              variant="adminButtonOutlined"
            >
              {t("filter")}
            </Button>
          </Box>
        )}
      </Flex>
    </Box>
  );
};
