import { Image } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { FaMicrosoft as MicrosoftIcon } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";

import cleverIcon from "adminComponents/assets/icons/clever-icon.png";
import { Button, IProps as ButtonProps } from "adminComponents/atoms/Button";
import { hoverStyles, pxToRem } from "adminComponents/utils";

const CleverIcon = () => <Image src={cleverIcon} maxH={pxToRem(24)} />;

enum SignInButtonType {
  GOOGLE = "Google",
  CLEVER = "Clever",
  MICROSOFT = "Microsoft",
}

interface ISignInButtonProps extends ButtonProps {
  buttonType: SignInButtonType;
  handleOnButtonClick?: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
  // for account buttons rendered as anchor tags
  href?: string;
}

const ExternalAccountButton: React.FC<ISignInButtonProps> = ({
  handleOnButtonClick,
  isDisabled = false,
  isLoading = false,
  // eslint-disable-next-line
  buttonType, // destructure to omit from overrideProps
  children,
  ...overrideProps
}) => {
  return (
    <Button
      isDisabled={isDisabled}
      isLoading={isLoading}
      onClick={handleOnButtonClick}
      size="lg"
      variant="adminSignInButton"
      w="full"
      {...overrideProps}
    >
      {children}
    </Button>
  );
};

export type ExternalAccountButton = React.FC<
  Omit<ISignInButtonProps, "buttonType">
>;

// Sign-in Buttons

export const CleverSignInButton: ExternalAccountButton = (props) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  return (
    <ExternalAccountButton
      {...props}
      _hover={hoverStyles({ bg: "utility.clever-button-bg-hover" })}
      bgColor="utility.clever-button-bg"
      buttonType={SignInButtonType.CLEVER}
      color="primary.white"
      leftIcon={<CleverIcon />}
    >
      {t("externalAccountButton.signInWithCleverButton")}
    </ExternalAccountButton>
  );
};

export const GoogleSignInButton: ExternalAccountButton = (props) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  return (
    <ExternalAccountButton
      {...props}
      borderColor="primary.light-gray"
      borderWidth={pxToRem(2)}
      _hover={hoverStyles({ bg: "primary.light-gray" })}
      bgColor="primary.white"
      buttonType={SignInButtonType.GOOGLE}
      borderStyle="solid"
      color="primary.warm-black"
      leftIcon={<FcGoogle />}
    >
      {t("externalAccountButton.signInWithGoogleButton")}
    </ExternalAccountButton>
  );
};

export const GoogleAuthorizeButton: ExternalAccountButton = (props) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  return (
    <ExternalAccountButton
      {...props}
      borderColor="primary.light-gray"
      borderWidth={pxToRem(2)}
      _hover={hoverStyles({ bg: "primary.light-gray" })}
      bgColor="primary.white"
      buttonType={SignInButtonType.GOOGLE}
      borderStyle="solid"
      color="primary.warm-black"
      leftIcon={<FcGoogle />}
    >
      {t("externalAccountButton.authorizeWithGoogleButton")}
    </ExternalAccountButton>
  );
};

export const MicrosoftSignInButton: ExternalAccountButton = (props) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  return (
    <ExternalAccountButton
      {...props}
      _hover={hoverStyles({ bg: "utility.microsoft-button-bg-hover" })}
      bgColor="utility.microsoft-button-bg"
      buttonType={SignInButtonType.MICROSOFT}
      color="primary.white"
      leftIcon={<MicrosoftIcon />}
    >
      {t("externalAccountButton.signInWithMicrosoftButton")}
    </ExternalAccountButton>
  );
};

// Account Buttons

export const CleverAccountButton: ExternalAccountButton = (props) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  return (
    <ExternalAccountButton
      {...props}
      _hover={hoverStyles({ bg: "utility.clever-button-bg-hover" })}
      bgColor="utility.clever-button-bg"
      buttonType={SignInButtonType.CLEVER}
      color="primary.white"
      leftIcon={<CleverIcon />}
      target="_blank"
      as="a"
    >
      {t("externalAccountButton.accountButton", { account: "Clever" })}
    </ExternalAccountButton>
  );
};

export const GoogleAccountButton: ExternalAccountButton = (props) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  return (
    <ExternalAccountButton
      {...props}
      borderColor="primary.light-gray"
      borderWidth={pxToRem(2)}
      _hover={hoverStyles({ bg: "primary.light-gray" })}
      bgColor="primary.white"
      buttonType={SignInButtonType.GOOGLE}
      borderStyle="solid"
      color="primary.warm-black"
      leftIcon={<FcGoogle />}
      target="_blank"
      as="a"
    >
      {t("externalAccountButton.accountButton", { account: "Google" })}
    </ExternalAccountButton>
  );
};

export const MicrosoftAccountButton: ExternalAccountButton = (props) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  return (
    <ExternalAccountButton
      {...props}
      _hover={hoverStyles({ bg: "utility.microsoft-button-bg-hover" })}
      bgColor="utility.microsoft-button-bg"
      buttonType={SignInButtonType.MICROSOFT}
      color="primary.white"
      leftIcon={<MicrosoftIcon />}
      target="_blank"
      as="a"
    >
      {t("externalAccountButton.accountButton", { account: "Microsoft" })}
    </ExternalAccountButton>
  );
};
