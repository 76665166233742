import { Box } from "@chakra-ui/react";
import React, { useMemo } from "react";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { SessionConnectionStatus } from "links/lib/types";

export interface IOnlineStatusCircleProps {
  status: SessionConnectionStatus;
  size?: number;
  customConnectedColor?: string;
  customDisconnectedColor?: string;
  customOfflineColor?: string;
}

export const OnlineStatusCircle: React.FC<IOnlineStatusCircleProps> = ({
  status,
  size = 20,
  customConnectedColor,
  customDisconnectedColor,
  customOfflineColor,
}) => {
  const circleSize = pxToRem(size);

  const color = useMemo(() => {
    switch (status) {
      case SessionConnectionStatus.Connected:
        return customConnectedColor || "onlineStatus.connected";
      case SessionConnectionStatus.Disconnected:
        return customDisconnectedColor || "onlineStatus.disconnected";
      case SessionConnectionStatus.Offline:
        return customOfflineColor || "onlineStatus.offline";
      default:
        return "onlineStatus.offline";
    }
  }, [
    status,
    customConnectedColor,
    customDisconnectedColor,
    customOfflineColor,
  ]);

  return (
    <Box
      bg={color}
      borderRadius="50%"
      minH={circleSize}
      minW={circleSize}
      h={circleSize}
      w={circleSize}
    />
  );
};
