import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { IUser } from "../../types";

export enum SortBy {
  GivenName = "GIVEN_NAME",
  FamilyName = "FAMILY_NAME",
  Email = "EMAIL",
  Role = "ROLE",
}

export enum SortDir {
  Asc = "ASC",
  Desc = "DESC",
}

export interface IUseFetchOrganizationUsersParams {
  query?: string;
  page: number;
  perPage?: number;
  enabled?: boolean;
  sortBy?: SortBy;
  sortDir?: SortDir;
}

export interface IUseFetchOrganizationUsersResponse {
  users: Array<IUser>;
  page: number;
  per_page: number;
  total_count: number;
}

export default function useFetchOrganizationUsers(
  args: IUseFetchOrganizationUsersParams
): UseQueryResult<IUseFetchOrganizationUsersResponse> {
  const {
    query: searchQuery,
    page,
    perPage = 25,
    sortBy = SortBy.FamilyName,
    sortDir = SortDir.Asc,
    enabled = true,
  } = args;

  const axios = useAxios();

  const query = useQuery<IUseFetchOrganizationUsersResponse>(
    ["organizationUsers", args],
    async (): Promise<IUseFetchOrganizationUsersResponse> => {
      return await axios
        .get(`/v1/organizations/users`, {
          params: {
            page,
            per_page: perPage,
            query: searchQuery,
            sort_by: sortBy,
            sort_dir: sortDir,
          },
        })
        .then((r) => r.data as IUseFetchOrganizationUsersResponse);
    },
    { enabled }
  );

  return query;
}
