import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { TextLink } from "adminComponents/atoms/TextLink";
import { MenuItemDetails } from "adminComponents/molecules/IconButtonDropdown";
import { IPracticeSet, IUser, PracticeSetAvailability } from "links/lib/types";

import {
  CompactLibraryPracticeSetCard,
  FullLibraryPracticeSetCard,
} from "./components";

interface CommonProps {
  authUser?: IUser;
  handleDelete?: (practiceSet: IPracticeSet) => void;
  handleDuplicate?: (practiceSet: IPracticeSet) => void;
  handleMoveToFolder?: (practiceSet: IPracticeSet) => void;
  handleRemoveFromFolder?: (practiceSet: IPracticeSet) => void;
  handleClick?: React.MouseEventHandler;
  hideMenu?: boolean;
  practiceSet: IPracticeSet;
  showPremiumMarker?: boolean;
}

export type IProps = CommonProps & {
  authorName?: string;
  authorCustomUrlCode?: string;
  authorProfileImageUrl?: string;
  authorProfileIsPrivate?: boolean;
  authorIsInternalContentSpecialist?: boolean;
  showLargeAuthorName?: boolean;
  compact?: boolean;
  isShared?: boolean;
};

export const getPracticeSetUrl = (
  practiceSet: IPracticeSet,
  authUser?: IUser
): string => {
  if (authUser?.id === practiceSet.created_by) {
    return `/t/my-library/sets/${practiceSet.id}`;
  }

  switch (practiceSet.availability) {
    case PracticeSetAvailability.Public:
      return `/t/public-library/sets/${practiceSet.id}`;
    case PracticeSetAvailability.Domain:
      return `/t/my-school-library/sets/${practiceSet.id}`;
    case PracticeSetAvailability.Private:
      // ? ensures redirection out of a given set if in /t/my-library/__
      return "/t/my-library?";
    default:
      return "";
  }
};

/**
 * LibraryPracticeSetCard represents a practice set card as seen in the original library
 * screens. It displays several pieces of information about the practice set, and is rather
 * large. The library screens were redesigned in Q2 2024 with more compact versions of
 * practice set cards, found in the `CompactLibraryPracticeSetCard` component.
 */
export const LibraryPracticeSetCard: React.FC<IProps> = ({
  authorName,
  authorCustomUrlCode,
  authorProfileImageUrl,
  authorProfileIsPrivate,
  authorIsInternalContentSpecialist,
  showLargeAuthorName = false,
  authUser,
  compact = false,
  handleMoveToFolder,
  handleDelete,
  handleDuplicate,
  handleRemoveFromFolder,
  handleClick,
  hideMenu = false,
  isShared = false,
  practiceSet,
  showPremiumMarker = true,
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });

  const practiceSetUrl = getPracticeSetUrl(practiceSet, authUser);

  const navigationLink = {
    label: t("common.open"),
    to: practiceSetUrl,
  };

  const handleMoveItem = useCallback(
    () => handleMoveToFolder?.(practiceSet),
    [handleMoveToFolder, practiceSet]
  );
  const handleDuplicateItem = useCallback(
    () => handleDuplicate?.(practiceSet),
    [handleDuplicate, practiceSet]
  );
  const handleDeleteItem = useCallback(
    () => handleDelete?.(practiceSet),
    [handleDelete, practiceSet]
  );
  const handleRemoveFromFolderItem = useCallback(
    () => handleRemoveFromFolder?.(practiceSet),
    [handleRemoveFromFolder, practiceSet]
  );

  const isActionsRestrictedSet =
    practiceSet.is_how_to_play || practiceSet.is_digital_citizenship;

  const menuItems: MenuItemDetails[] = hideMenu
    ? []
    : [
        {
          handleClick: () => {
            // No-op to make link visible
          },
          label: (
            <TextLink
              display="block"
              navigationLink={navigationLink}
              variant="ghost"
              w="full"
            >
              {t("libraryPracticeSetCard.open")}
            </TextLink>
          ),
        },
        {
          handleClick:
            handleMoveToFolder && !isActionsRestrictedSet
              ? handleMoveItem
              : undefined,
          label: t("libraryPracticeSetCard.moveToFolder"),
        },
        {
          handleClick:
            handleDuplicate && !isActionsRestrictedSet
              ? handleDuplicateItem
              : undefined,
          label: t("common.duplicate"),
        },
        {
          handleClick:
            handleRemoveFromFolder && !isActionsRestrictedSet
              ? handleRemoveFromFolderItem
              : undefined,
          label: t("libraryPracticeSetCard.removeFromFolder"),
        },
        {
          handleClick:
            handleDelete && !isActionsRestrictedSet
              ? handleDeleteItem
              : undefined,
          label: t("common.delete"),
        },
      ].filter((menuItem) => !!menuItem.handleClick);

  if (compact) {
    return (
      <CompactLibraryPracticeSetCard
        isShared={isShared}
        menuItems={menuItems}
        practiceSet={practiceSet}
        practiceSetUrl={practiceSetUrl}
        showPremiumMarker={showPremiumMarker}
      />
    );
  } else {
    return (
      <FullLibraryPracticeSetCard
        authorName={authorName}
        authorCustomUrlCode={authorCustomUrlCode}
        authorProfileImageUrl={authorProfileImageUrl}
        authorProfileIsPrivate={authorProfileIsPrivate}
        authorIsInternalContentSpecialist={authorIsInternalContentSpecialist}
        authUserLanguage={authUser?.language}
        showLargeAuthorName={showLargeAuthorName}
        authUser={authUser}
        isShared={isShared}
        menuItems={menuItems}
        practiceSet={practiceSet}
        practiceSetUrl={practiceSetUrl}
        handleClick={handleClick}
        showPremiumMarker={showPremiumMarker}
      />
    );
  }
};
