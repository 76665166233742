import {
  Box,
  Modal as ChakraModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { GoogleAuthorizeButton } from "adminComponents/atoms/ExternalAccountButton";
import { Heading } from "adminComponents/atoms/Heading";
import {
  IconCTAButtonWrapper as CloseButtonWrapper,
  IconCTAButton,
} from "adminComponents/atoms/IconCTAButton";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils/pxToRem";

interface GoogleClassroomAuthorizeModalProps {
  isOpen: boolean;
  isLoading: boolean;
  handleClose: () => void;
  handleGrantAccess: () => void;
  heading?: string;
  explanation?: string;
  secondaryExplanation?: string;
}

export const GoogleClassroomAuthorizeModal: React.FC<
  GoogleClassroomAuthorizeModalProps
> = ({
  isOpen,
  isLoading,
  handleClose,
  handleGrantAccess,
  heading,
  explanation,
  secondaryExplanation,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  return (
    <ChakraModal
      isOpen={isOpen}
      isCentered
      size="2xl"
      variant="adminGoogleClassroomAuthorizeModal"
      onClose={handleClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading as="h2" variant="adminH6">
            {heading ? heading : t("addStudentsFromGoogleClassroomModal.title")}
          </Heading>
        </ModalHeader>
        <ModalCloseButton
          as={CloseButtonWrapper}
          top={pxToRem(20)}
          right={pxToRem(20)}
        >
          <IconCTAButton ariaLabel={t("common.close")} />
        </ModalCloseButton>
        <ModalBody>
          <Box mb={pxToRem(40)}>
            <Text variant="adminP1" color="primary.dark-gray">
              {explanation
                ? explanation
                : t(
                    "addStudentsFromGoogleClassroomModal.accessNotGrantedDescription"
                  )}
            </Text>
            {secondaryExplanation && (
              <Text
                paddingTop={pxToRem(20)}
                variant="adminP1"
                color="primary.dark-gray"
              >
                {secondaryExplanation}
              </Text>
            )}
            <Box mt={pxToRem(10)} paddingTop={pxToRem(40)}>
              <GoogleAuthorizeButton
                isLoading={isLoading}
                handleOnButtonClick={handleGrantAccess}
              />
            </Box>
          </Box>
        </ModalBody>
      </ModalContent>
    </ChakraModal>
  );
};
