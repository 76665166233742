import {
  Box,
  Flex,
  HStack,
  Image,
  Link,
  RadioGroup,
  Show,
  SimpleGrid,
  Spacer,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { useFormContext } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Heading } from "adminComponents/atoms/Heading";
import { Icon } from "adminComponents/atoms/Icon";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils";
import { useAuth } from "links/lib/features/auth";
import { IAgreement, SharingSettingsVisibility } from "links/lib/types";

import { useCircularProgressAvatar } from "../hooks/useCircularProgressAvatar";
import CommunitySVG from "../resource/community.svg";
import { SharingVisibilityRadio } from "./SharingVisibilityRadio";

const STARTING_PERCENTAGE_PROGRESS = 50;
const MIN_PERCENTAGE_PROGRESS = 78;
const MAX_PERCENTAGE_PROGRESS = 100;

interface IProps {
  agreements: Array<IAgreement>;
  handleBack: () => void;
  isLoading: boolean;
}

export const TeacherOnboardingStep3: React.FC<IProps> = ({
  agreements,
  handleBack,
  isLoading,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const { authUser } = useAuth();

  const hasRequiredAgreements = !!agreements.length;

  const { formState, watch } = useFormContext();
  const selectedSharingVisbility = watch("sharingVisibility");

  const { setPercentProgress, circularProgressAvatar } =
    useCircularProgressAvatar({
      profileImageUrl: authUser?.profile_image_url || "",
      startingPercentProgress: STARTING_PERCENTAGE_PROGRESS,
      minPercentProgress: MIN_PERCENTAGE_PROGRESS,
    });

  return (
    <HStack
      w="full"
      pl={{ base: "admin.mobileXPadding", md: "admin.desktopXPadding" }}
    >
      <VStack
        alignItems={["center", null, null, "start"]}
        w={["full", null, null, "auto"]}
        flex="1.4"
        gap={[pxToRem(16), null, null, pxToRem(32)]}
        py={pxToRem(32)}
        pr={pxToRem(32)}
      >
        <VStack alignItems="start" w="full" gap={pxToRem(12)}>
          <Flex alignItems="center" cursor="pointer" onClick={handleBack}>
            <Icon color="utility.link" icon="keyboard_arrow_left" />
            <Text color="utility.link" variant="adminP1">
              {t("common.goBack")}
            </Text>
          </Flex>
          <Heading as="h1" variant="adminH2" textAlign="left">
            {t("teacherOnboardContainer.step3.heading")}
          </Heading>
          <Text variant="adminP1" textAlign="left">
            {t("teacherOnboardContainer.step3.description")}
          </Text>
        </VStack>
        <Box background="white" width="full" overflow="hidden">
          <VStack
            w="full"
            alignItems={["center", null, null, "start"]}
            justifyContent="center"
            gap={pxToRem(16)}
          >
            <Text variant="adminP1Bold">
              {t("teacherOnboardContainer.step3.profileVisibility")}
            </Text>
            <RadioGroup w="full" value={selectedSharingVisbility}>
              <SimpleGrid w="full" minChildWidth={125} gap={pxToRem(8)}>
                <SharingVisibilityRadio
                  sharingVisibility={SharingSettingsVisibility.Public}
                />
                <SharingVisibilityRadio
                  sharingVisibility={SharingSettingsVisibility.GSOnly}
                />
                <SharingVisibilityRadio
                  sharingVisibility={SharingSettingsVisibility.Private}
                />
              </SimpleGrid>
            </RadioGroup>
            <Button
              alignSelf="center"
              size="lg"
              variant="adminButtonFilled"
              disabled={!formState.isValid}
              onClick={() => {
                setPercentProgress(MAX_PERCENTAGE_PROGRESS);
              }}
              isLoading={isLoading}
              w={pxToRem(200)}
              type="submit"
            >
              {hasRequiredAgreements
                ? t("teacherOnboardContainer.step3.acceptAndContinue")
                : t("common.finish")}
            </Button>
            {hasRequiredAgreements && (
              <Text
                alignSelf="center"
                textAlign="center"
                variant="adminMeta"
                mt={pxToRem(-5)}
              >
                <Trans
                  i18nKey="teacherOnboardContainer.step3.termsAndConditions"
                  t={t}
                >
                  <Link
                    target="_blank"
                    color="utility.link"
                    href="https://www.peardeck.com/policies/product-terms-and-end-user-license-agreement"
                  >
                    Terms of Service
                  </Link>
                </Trans>
              </Text>
            )}
          </VStack>
        </Box>
      </VStack>
      <Show above="lg">
        <Flex
          bgColor="primary.lightest-gray"
          backgroundPosition="top left"
          backgroundSize="cover"
          borderTopRightRadius={pxToRem(20)}
          borderBottomRightRadius={pxToRem(20)}
          flex="1"
          h={[pxToRem(250), null, pxToRem(700)]}
          alignItems="center"
          justifyContent="center"
        >
          <VStack
            h="full"
            sx={{
              "svg.circular-progress": {
                position: "absolute",
                zIndex: 1,
                top: 0,
                left: 0,
              },
              circle: {
                stroke: "primary.lilac",
                transform: "rotate(-90deg)",
                transformOrigin: "50% 50%",
                transition: "stroke-dashoffset 0.35s",
              },
            }}
          >
            <Spacer />
            {circularProgressAvatar}
            <Image mt="auto" src={CommunitySVG} />
          </VStack>
        </Flex>
      </Show>
    </HStack>
  );
};
