import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";

export interface IFetchOrganizationStudentCountResponse {
  student_count: string;
}

export default function useFetchOrganizationStudentCount(): UseQueryResult<
  IFetchOrganizationStudentCountResponse,
  unknown
> {
  const axios = useAxios();

  const query = useQuery<IFetchOrganizationStudentCountResponse>(
    ["org-student-count"],
    async (): Promise<IFetchOrganizationStudentCountResponse> => {
      return await axios.get(`/v1/organizations/students/count`).then((r) => {
        return r.data as IFetchOrganizationStudentCountResponse;
      });
    }
  );

  return query;
}
