import { usePrefersReducedMotion } from "@chakra-ui/react";
import React from "react";
import { animated, config, useSpring } from "react-spring";

import { ColorScheme } from "adminComponents";

import { Icon } from "../Icon";

interface IAnimatedCheckIconProps {
  checked: boolean;
  color: ColorScheme;
  maxWidth?: string;
}

const AnimatedIcon = animated(Icon);

export const AnimatedCheckIcon: React.FC<IAnimatedCheckIconProps> = ({
  checked,
  color,
  maxWidth,
}) => {
  const prefersReducedMotion = usePrefersReducedMotion();
  const checkStyles = useSpring({
    opacity: checked ? 1 : 0,
    scale: checked ? 1 : prefersReducedMotion ? 1 : 0,
    config: config.stiff,
  });

  return (
    <AnimatedIcon
      icon="checkmark"
      color={color}
      maxW={maxWidth}
      style={checkStyles}
    />
  );
};
