import { Box, BoxProps, useMultiStyleConfig } from "@chakra-ui/react";
import React from "react";

import { ColorScheme, IconType } from "adminComponents";
import { Card } from "adminComponents/atoms/Card";
import { Icon } from "adminComponents/atoms/Icon";
import { Text } from "adminComponents/atoms/Text";
import { colorMapping } from "adminComponents/theme/colors";
import { useAuth } from "links/lib/features/auth";

import { PremiumTooltip } from "../PremiumTooltip";
import { ReactComponent as IconBg } from "./resource/icon_bg.svg";

interface IProps extends Omit<BoxProps, "backgroundColor" | "borderColor"> {
  icon: IconType;
  iconColor: ColorScheme;
  title: string;
  isActive?: boolean;
  isPremium?: boolean;
  ariaLabel: string;
}

export const QuestionCard: React.FC<IProps> = ({
  icon,
  iconColor,
  title,
  children,
  isActive = false,
  isPremium = false,
  ariaLabel,
  ...rest
}) => {
  const { hasNoPremiumAccess } = useAuth();
  const styles = useMultiStyleConfig("AdminQuestionCard", {
    hasNoPremiumAccess,
  });

  return (
    <Card
      as="button"
      variant="adminCardSelectable"
      noPadding
      backgroundColor="primary.white"
      borderColor={isActive ? "utility.link" : "primary.medium-gray"}
      isSelected={isActive}
      aria-label={ariaLabel}
      {...rest}
    >
      <Box as="span" __css={styles.outer}>
        <Box as="span" __css={styles.iconContainer}>
          <IconBg
            color={colorMapping[iconColor]}
            aria-hidden
            focusable={false}
          />
          <Icon as="span" styles={styles.icon} icon={icon} decorative />
        </Box>
        <Box as="span" __css={styles.textContainer}>
          <Text variant="adminP2">{title}</Text>
          <Text variant="adminP3" color="primary.dark-gray">
            {children}
          </Text>
        </Box>
        {isPremium && (
          <PremiumTooltip>
            <Box as="span" sx={styles.premiumBox}>
              <Icon
                icon={hasNoPremiumAccess ? "lock" : "crown"}
                as="span"
                iconColor={
                  hasNoPremiumAccess ? "primary.dark-gray" : "primary.white"
                }
                styles={styles.premiumIcon}
              />
            </Box>
          </PremiumTooltip>
        )}
      </Box>
    </Card>
  );
};
