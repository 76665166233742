import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import type { IUserTrialExtension } from "../../types";

export interface IListUserTrialExtensionsResponse {
  user_trial_extensions: Array<IUserTrialExtension>;
}

export function useListUserTrialExtensions(): UseQueryResult<
  IListUserTrialExtensionsResponse,
  unknown
> {
  const axios = useAxios();

  return useQuery<IListUserTrialExtensionsResponse>(
    ["user-trial-extensions"],
    async (): Promise<IListUserTrialExtensionsResponse> => {
      return await axios
        .get("/v1/subscriptions/users/extensions")
        .then((r) => r.data as IListUserTrialExtensionsResponse);
    }
  );
}
