import { useEffect, useRef, useState } from "react";
import { useUnmount } from "react-use";
import { clearTimeout, setTimeout } from "timers";

export interface IUsePendingSubmitOptions {
  // number of milliseconds to consider an item pending
  pendingInterval?: number;
  // function that allows for custom resolution conditions
  resolve?: (deps: Array<unknown>) => boolean;
}

export interface IUsePendingSubmitResult {
  handleSubmit: () => void;
  isPending: boolean;
  isExpired: boolean;
  isComplete: boolean;
}

const usePendingSubmit = (
  deps: Array<unknown>,
  opts?: IUsePendingSubmitOptions
): IUsePendingSubmitResult => {
  const pendingInterval = useRef<NodeJS.Timeout | null>(null);
  const [isPending, setIsPending] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const pendingIntervalDuration = opts?.pendingInterval || 5000;

  useEffect(() => {
    if (opts?.resolve && !opts.resolve(deps)) return;

    if (pendingInterval.current) {
      clearTimeout(pendingInterval.current);
      pendingInterval.current = null;
    }

    setIsPending(false);
    setIsExpired(false);
    setIsComplete(true);
    // eslint-disable-next-line
  }, deps);

  useUnmount(() => {
    if (pendingInterval.current) {
      clearTimeout(pendingInterval.current);
      pendingInterval.current = null;
    }
  });

  const handleSubmit = () => {
    setIsPending(true);
    setIsExpired(false);

    if (pendingInterval.current) {
      clearTimeout(pendingInterval.current);
      pendingInterval.current = null;
    }

    pendingInterval.current = setTimeout(() => {
      setIsPending(false);
      setIsExpired(true);
      pendingInterval.current = null;
    }, pendingIntervalDuration);
  };

  return {
    handleSubmit,
    isPending,
    isExpired,
    isComplete,
  };
};

export default usePendingSubmit;
