import { pxToRem } from "adminComponents/utils/pxToRem";

export const sizes = {
  admin: {
    nav: {
      desktopTopRowHeight: pxToRem(80),
      mobileTopRowHeight: pxToRem(48),
    },
  },
};
