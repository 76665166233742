import { Flex, Image, Spinner, Stack, useDisclosure } from "@chakra-ui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useCopyToClipboard, useMount, useSearchParam } from "react-use";

import GoogleClassroomImage from "adminComponents/assets/GoogleClassroom.svg";
import { Button } from "adminComponents/atoms/Button";
import { Checkbox } from "adminComponents/atoms/Checkbox";
import { Heading } from "adminComponents/atoms/Heading";
import { Icon } from "adminComponents/atoms/Icon";
import { Tabs } from "adminComponents/atoms/Tabs";
import { Text } from "adminComponents/atoms/Text";
import { Textarea } from "adminComponents/atoms/Textarea";
import { pxToRem } from "adminComponents/utils";
import { useErrorToast, useShowToast } from "adminComponents/utils/toast";
import { useFetchClassroomJoinLinksOnce } from "links/lib/features/classrooms";
import { IClassroom } from "links/lib/types";
import { useAddStudentsByGClassroom } from "screens/TeacherClassrooms/ClassroomDetail/components/StudentsTab/hooks/useAddStudentsByGClassroom";
import { makeJoinLink } from "screens/TeacherClassrooms/ClassroomDetail/components/StudentsTab/hooks/useAddStudentsByLink";

import { StudentPreviewModal } from "./StudentPreviewModal";

interface ITeacherTourStep3Props {
  classroom: IClassroom;
  disableNotifications: boolean;
  emailsString: string;
  errorString?: string;
  tab?: number;
  handleChangeTab?: (tabIndex: number) => void;
  setDisableNotifications: (disableNotifications: boolean) => void;
  setEmailsString: (emailsString: string) => void;
}

export const TeacherTourStep3: React.FC<ITeacherTourStep3Props> = ({
  classroom,
  disableNotifications,
  emailsString,
  errorString,
  tab,
  handleChangeTab,
  setDisableNotifications,
  setEmailsString,
}) => {
  const history = useHistory();
  const { t } = useTranslation("admin");
  const showToast = useShowToast();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const joinLinkFetch = useFetchClassroomJoinLinksOnce();
  useMount(() => joinLinkFetch.execute({ classroom_id: classroom.id }));
  useErrorToast(joinLinkFetch.error);

  const [, copyToClipboard] = useCopyToClipboard();

  const handleCopyJoinLinkClick = () => {
    if (!joinLinkFetch.data?.current_link) return;

    copyToClipboard(makeJoinLink(joinLinkFetch.data.current_link.code));
    showToast(t("addStudentsViaLinkModal.linkCopied"));
  };

  const isLoading = joinLinkFetch.isLoading;

  const searchParamTab = useSearchParam("tab");
  const [tabIndex, setTabIndex] = useState(
    tab ? tab : Number(searchParamTab) || 0
  );

  const {
    handleAddIntent: handleAddIntentByGClassroom,
    authorizeModal,
    classroomSelectModal,
    studentSelectModal,
  } = useAddStudentsByGClassroom({
    classroom,
    classroomStudents: [],
  });

  return (
    <>
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        gap={pxToRem(32)}
        w="full"
      >
        <Heading as="h1" variant="adminH2">
          {t("teacherTour.step3.heading")}
        </Heading>
        <Stack direction={{ base: "column", lg: "row" }}>
          <Text variant="adminP1">
            {t("teacherTour.step3.description", {
              classroomName: classroom.name,
            })}
          </Text>
          <Text
            variant="adminLinkLarge"
            color="utility.link"
            cursor="pointer"
            fontWeight="bold"
            onClick={onOpen}
          >
            {t("teacherTour.step3.studentPreviewLink")}
          </Text>
        </Stack>
        <Tabs
          sx={{ width: "100%" }}
          variant="adminFlyoutTabs"
          centerTabs
          tabIndex={tabIndex}
          handleChange={(index) => {
            if (handleChangeTab) {
              setTabIndex(index);
              handleChangeTab(index);
              return;
            }
            const params = new URLSearchParams(location.search);
            params.delete("tab");
            params.append("tab", String(index));
            history.replace({
              pathname: location.pathname,
              search: params.toString(),
            });
            setTabIndex(index);
          }}
          tabData={[
            {
              label: t("teacherTour.step3.tabJoinLink"),
              content: (
                <Flex
                  flexDir="column"
                  minH={pxToRem(150)}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Flex
                    borderWidth={pxToRem(4)}
                    borderColor="primary.tan"
                    borderRadius={pxToRem(20)}
                    p={pxToRem(23)}
                    mt={{ base: pxToRem(32), lg: 0 }}
                  >
                    {isLoading && <Spinner />}
                    {!isLoading && (
                      <Stack
                        gap={pxToRem(36)}
                        direction={{ base: "column", lg: "row" }}
                      >
                        <Text
                          size="md"
                          variant="adminP1Bold"
                          color="utility.link"
                        >
                          {makeJoinLink(
                            joinLinkFetch.data?.current_link.code ?? ""
                          )}
                        </Text>
                        <Button
                          leftIcon={
                            <Icon height="1rem" icon="link" width="1rem" />
                          }
                          size="sm"
                          variant="adminButtonOutlined"
                          onClick={handleCopyJoinLinkClick}
                          disabled={!joinLinkFetch.data?.current_link}
                        >
                          {t("addStudentsViaLinkModal.copyLink")}
                        </Button>
                      </Stack>
                    )}
                  </Flex>
                </Flex>
              ),
            },
            {
              label: t("teacherTour.step3.tabAddEmails"),
              content: (
                <Flex
                  flexDir="column"
                  minH={pxToRem(150)}
                  alignItems="center"
                  justifyContent="center"
                  gap={pxToRem(23)}
                >
                  <Textarea
                    value={emailsString}
                    onChange={(e) => setEmailsString(e.target.value)}
                    placeholder={t("teacherTour.step3.addEmailsPlaceholder")}
                    h={pxToRem(120)}
                    mt={pxToRem(32)}
                  />
                  <Text variant="adminMeta" color="utility.error">
                    {errorString}
                  </Text>
                  <Checkbox
                    isChecked={!disableNotifications}
                    onChange={(e) => setDisableNotifications(!e.target.checked)}
                  >
                    {t("teacherTour.step3.addEmailsCheckbox")}
                  </Checkbox>
                </Flex>
              ),
            },
            {
              label: t("teacherTour.step3.tabGoogleClassroom"),
              content: (
                <Flex
                  flexDir="column"
                  minH={pxToRem(150)}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Flex
                    borderWidth={pxToRem(4)}
                    borderColor="primary.tan"
                    borderRadius={pxToRem(20)}
                    px={{ base: pxToRem(12), lg: pxToRem(140) }}
                    py={pxToRem(26)}
                    w="full"
                    justifyContent="center"
                  >
                    <Button
                      variant="adminButtonOutlined"
                      size="lg"
                      leftIcon={
                        <Image w={pxToRem(32)} src={GoogleClassroomImage} />
                      }
                      onClick={() => handleAddIntentByGClassroom()}
                    >
                      {t("teacherTour.step3.addFromGoogleClassroom")}
                    </Button>
                  </Flex>
                </Flex>
              ),
            },
          ]}
        />
      </Flex>
      {authorizeModal}
      {classroomSelectModal}
      {studentSelectModal}
      <StudentPreviewModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};
