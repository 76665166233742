import { Box, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { pxToRem } from "adminComponents/utils";
import { IconButton } from "sessionComponents/atoms/IconButton";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";

interface ITeacherPreviewNavButtonsProps {
  onInitiatePriorRoundClick: () => void;
  onInitiateNextRoundClick: () => void;
  currentRoundNumber: number;
  finalRoundNumber: number;
}

//todo add a story
export const TeacherPreviewNavButtons: React.FC<
  ITeacherPreviewNavButtonsProps
> = ({
  onInitiatePriorRoundClick,
  onInitiateNextRoundClick,
  currentRoundNumber,
  finalRoundNumber,
}) => {
  const { match: currentBreakpoints, fontSizeHalf } = useBreakpoints();
  const { t } = useTranslation("session", {
    keyPrefix: "teacherPreview.navigation",
    useSuspense: false,
  });

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      w="full"
    >
      <IconButton
        onClick={onInitiatePriorRoundClick}
        ariaLabel={t("initiatePriorRoundAriaLabel")}
        boxSize={pxToRem(currentBreakpoints.buttonHeight)}
        icon="back"
        isDisabled={currentRoundNumber <= 1}
        iconSize={pxToRem(currentBreakpoints.iconSize)}
      />
      <VStack>
        <Text
          textTransform="uppercase"
          textStyle="gameText"
          fontSize={pxToRem(fontSizeHalf)}
        >
          {t("currentQuestionHeading")}
        </Text>
        <Text
          fontSize={pxToRem(currentBreakpoints.fontSize)}
          textStyle="gameText"
        >
          {t("currentQuestionLabel", {
            currentRoundNumber,
            finalRoundNumber,
          })}
        </Text>
      </VStack>
      <IconButton
        onClick={onInitiateNextRoundClick}
        ariaLabel={t("initiateNextRoundAriaLabel")}
        boxSize={pxToRem(currentBreakpoints.buttonHeight)}
        icon="forward"
        iconSize={pxToRem(currentBreakpoints.iconSize)}
        isDisabled={currentRoundNumber >= finalRoundNumber}
      />
    </Box>
  );
};
