import { DebouncedFunc, throttle } from "lodash";
import { useRef } from "react";

import { useSessionActions } from "links/lib/contexts/sessionActions";
import { IPosition } from "links/lib/types";

export const useSendThrottledDragCoords = (): React.MutableRefObject<
  DebouncedFunc<(labelId: string, coords: IPosition) => void>
> => {
  const { sendSortChoiceDrag } = useSessionActions();
  const sendThrottledCoords = useRef<
    DebouncedFunc<(labelId: string, coords: IPosition) => void>
  >(
    throttle(
      (labelId: string, coords: IPosition) => {
        sendSortChoiceDrag(labelId, coords);
      },
      500,
      { leading: true, trailing: false }
    )
  );

  return sendThrottledCoords;
};
