import { Box, HStack, Text } from "@chakra-ui/react";
import React from "react";

import { pxToRem } from "adminComponents/utils/pxToRem";
import {
  PracticeSessionItemVariantType,
  QuestionType,
  RichText,
} from "links/lib/types";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { QuestionResponse } from "sessionComponents/molecules/QuestionResponse";
import { getGameChoiceType } from "sessionComponents/utils/getGameChoiceType";

interface ITeacherReviewOptionProps {
  variant: PracticeSessionItemVariantType;
  isCorrect: boolean;
  answerCount: number;
  maxAnswerCount: number;
  studentTotal: number;
  text?: string;
  imageUrl?: string;
  imageAltText?: string;
  questionType?: QuestionType;
  diagramMarkerNumber?: number;
  alwaysShowAccuracyBar?: boolean;
  prefix?: RichText;
  suffix?: RichText;
  showTopMargin?: boolean;
}

export const TeacherReviewOption: React.FC<ITeacherReviewOptionProps> = ({
  variant,
  isCorrect,
  answerCount,
  maxAnswerCount,
  studentTotal,
  text,
  imageAltText,
  imageUrl,
  questionType,
  diagramMarkerNumber,
  alwaysShowAccuracyBar,
  prefix,
  suffix,
  showTopMargin,
}) => {
  const { match } = useBreakpoints();
  const isImageResponse = !!imageUrl;
  const accuracyPercentage = (answerCount / studentTotal) * 100;
  const isDragsortOption =
    variant === PracticeSessionItemVariantType.CoopDragsort;
  const isSelectedIncorrectly = !isCorrect && !!answerCount;
  const showAsDefaultWrongAnswer = !isCorrect && !answerCount;

  return (
    <HStack
      marginTop={showTopMargin ? pxToRem(match.margin / 2) : undefined}
      spacing={pxToRem(match.margin)}
    >
      <QuestionResponse
        variant={variant}
        choiceType={getGameChoiceType(variant)}
        questionType={questionType}
        text={text}
        imageUrl={imageUrl}
        imageAltText={imageAltText}
        number={diagramMarkerNumber}
        showUserCount={isImageResponse}
        success={isCorrect}
        error={isSelectedIncorrectly && !isDragsortOption}
        iconColor={
          isImageResponse && showAsDefaultWrongAnswer
            ? "primary.medium-gray"
            : undefined
        }
        showAccuracyBar={
          !isImageResponse && (alwaysShowAccuracyBar || !!answerCount)
        }
        accuracyPercentage={accuracyPercentage}
        showAsDefaultWrongAnswer={showAsDefaultWrongAnswer}
        userCount={answerCount}
        prefix={prefix}
        suffix={suffix}
      />

      {!isImageResponse && (
        <Box minW={pxToRem(maxAnswerCount.toString().length * 16)}>
          <Text textStyle="gameText" fontSize={pxToRem(match.fontSize)}>
            {answerCount}
          </Text>
        </Box>
      )}
    </HStack>
  );
};
