import moment from "moment";
import { useMemo } from "react";

import { useFetchClassrooms } from "links/lib/features/classrooms";
import useFetchSessions from "links/lib/features/sessions/useFetchSessions";
import { IPracticeSetSession, SessionType } from "links/lib/types";

const LIVE_SESSION_REFETCH_INTERVAL = 10000;

export type LiveBanner = {
  classroomName: string;
  session: IPracticeSetSession;
  practiceSetName: string;
  classroomId: string;
};

export const useGetClassroomLiveBanners = ({
  classroomId = "",
  showClassroomName,
}: {
  classroomId?: string;
  showClassroomName?: boolean;
}): LiveBanner[] => {
  const activeSessionStartTime = useMemo(
    () => moment().startOf("minute").subtract(24, "h").toDate(),
    []
  );

  const fetchSessions = useFetchSessions({
    incomplete_sessions_only: true,
    start_time: activeSessionStartTime,
    group_id: classroomId,
    keepPreviousData: true,
    refetchInterval: LIVE_SESSION_REFETCH_INTERVAL,
  });

  const classroomsFetch = useFetchClassrooms({
    archived: false,
    disabled: !showClassroomName,
    id: classroomId,
    includeAllRoles: true,
  });

  const liveBanners = useMemo(() => {
    return (fetchSessions.data?.sessions || [])
      .filter((s: IPracticeSetSession) => {
        return s.session_type === SessionType.Class;
      })
      .flatMap((s: IPracticeSetSession) => {
        const classroom = classroomsFetch.data?.classrooms?.find(
          (c) => c.id === s.group_id
        );
        if (showClassroomName && !classroom) return [];

        return [
          {
            classroomId: s.group_id,
            classroomName: classroom?.name || "",
            session: s,
            practiceSetName: s.practice_set_title,
          },
        ];
      });
  }, [showClassroomName, fetchSessions.data, classroomsFetch.data?.classrooms]);

  return liveBanners;
};
