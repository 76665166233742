import { Box } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useMeasure } from "react-use";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { useSessionConnectedUsers } from "links/lib/contexts/sessionConnectedUsers";
import { useSessionRoundGroupState } from "links/lib/contexts/sessionRoundGroupState";
import { useSessionRoundState } from "links/lib/contexts/sessionRoundState";
import {
  IPracticeSetSessionItemMultipleChoiceChoice,
  ISessionRoundGroupState,
  ISessionUser,
  ISessionUsers,
  PracticeSessionItemVariantType,
} from "links/lib/types";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { useStudent } from "sessionComponents/contexts/student";
import { useConfirmationStatus } from "sessionComponents/hooks/useConfirmationStatus";
import {
  ResponseOption,
  useItemResponseOptions,
} from "sessionComponents/hooks/useItemResponseOptions";
import { useRoundActions } from "sessionComponents/hooks/useRoundActions";
import { QuestionResponse } from "sessionComponents/molecules/QuestionResponse";
import { getAssetColorScheme } from "sessionComponents/utils/getAssetColorScheme";
import { getGameChoiceType } from "sessionComponents/utils/getGameChoiceType";
import { getSelectedChoices } from "sessionComponents/utils/getSelectedChoices";
import { getImageResponseStyleProps } from "sessionComponents/utils/layoutUtils";

const DEFAULT_ANSWER_OPTIONS: Array<ResponseOption> = [];

export const StudentMultipleChoiceOptions: React.FC = () => {
  const { match } = useBreakpoints();
  const roundState = useSessionRoundState();
  const roundGroupState = useSessionRoundGroupState();
  const connectedUsers = useSessionConnectedUsers();
  const student = useStudent();
  const { onChoiceSelect } = useRoundActions();
  const [optionsContainerRef, { width: optionsContainerRefWidth }] =
    useMeasure<HTMLDivElement>();
  const { variant, practice_set_session_item } = roundState;

  const { isConfirmed } = useConfirmationStatus({
    studentId: student.id,
    groupId: student.session_group_id,
  });

  const answerOptions =
    useItemResponseOptions(practice_set_session_item, roundGroupState) ||
    DEFAULT_ANSWER_OPTIONS;

  const isImageResponse = useMemo(
    () => answerOptions.some((opt) => !!opt.image_url),
    [answerOptions]
  );

  return (
    <Box
      {...getImageResponseStyleProps(isImageResponse, match.padding)}
      paddingBottom={pxToRem(match.padding)}
      ref={optionsContainerRef}
    >
      {/* todo animations here  */}
      {answerOptions.map((option, index) => {
        const { isSelected, isChecked, selectingUsers } = getIsChoiceSelected({
          variant,
          roundGroupState,
          option,
          users: connectedUsers,
          student,
        });

        return (
          <Box
            key={option.id}
            marginTop={
              index && !isImageResponse ? pxToRem(match.margin / 2) : undefined
            }
          >
            <QuestionResponse
              variant={variant}
              choiceType={getGameChoiceType(variant)}
              text={option.text}
              imageUrl={option.image_url}
              imageAltText={option.image_alt_text}
              selected={isChecked}
              onClick={() => {
                if (isConfirmed) return;
                // todo usePendingSubmit with isChecked
                onChoiceSelect(option.id, !!isSelected);
              }}
              disabled={isConfirmed}
              users={selectingUsers.map((user) => ({
                avatarUrl: user.profile_image_url,
                id: user.id,
                name: user.name,
                primaryColor: getAssetColorScheme(user.color_scheme)
                  .primaryColor,
              }))}
              showUserCount={false}
              prefix={practice_set_session_item.number_response?.prefix}
              suffix={practice_set_session_item.number_response?.suffix}
              maxHeight={
                isImageResponse
                  ? pxToRem(optionsContainerRefWidth / 2)
                  : undefined
              }
              maxWidth={
                isImageResponse
                  ? pxToRem(optionsContainerRefWidth / 2)
                  : undefined
              }
            />
          </Box>
        );
      })}
    </Box>
  );
};

const getIsChoiceSelected = ({
  variant,
  roundGroupState,
  option,
  users,
  student,
}: {
  variant: PracticeSessionItemVariantType;
  roundGroupState: ISessionRoundGroupState;
  option: IPracticeSetSessionItemMultipleChoiceChoice;
  users: ISessionUsers;
  student: ISessionUser;
}): {
  isSelected: boolean | undefined;
  isChecked: boolean | undefined;
  selectingUsers: ISessionUser[];
} => {
  switch (variant) {
    case PracticeSessionItemVariantType.CoopChecks:
    case PracticeSessionItemVariantType.CoopRadios:
    case PracticeSessionItemVariantType.CoopTextMatch:
    case PracticeSessionItemVariantType.CoopTextMatchNumeric:
      return getMultiChoiceVariantsSelectedChoices(
        variant,
        roundGroupState,
        option,
        users,
        student
      );
    default:
      //todo should this be undefined?
      return {
        isSelected: undefined,
        isChecked: undefined,
        selectingUsers: [],
      };
  }
};
// TODO look into improving the logic here so it's less confusing
const getMultiChoiceVariantsSelectedChoices = (
  variant: PracticeSessionItemVariantType,
  roundGroupState: ISessionRoundGroupState,
  option: IPracticeSetSessionItemMultipleChoiceChoice,
  users: ISessionUsers,
  student: ISessionUser
) => {
  const selectedChoices = getSelectedChoices(roundGroupState);
  const targetChoices =
    selectedChoices?.filter((choice) => choice.choice_id === option.id) || [];
  const selectingUsers = targetChoices
    .map((choice) => users[choice.user_id])
    .filter((u) => !!u) as ISessionUser[];

  const studentSelected = selectingUsers.some((u) => u && u.id === student?.id);

  const studentSelectedChoice = selectedChoices?.find(
    (choice) => choice.user_id === student?.id
  );

  const isSelected =
    variant === PracticeSessionItemVariantType.CoopChecks
      ? !studentSelected
      : true;

  const isChecked =
    variant === PracticeSessionItemVariantType.CoopChecks
      ? studentSelected
      : studentSelectedChoice?.choice_id === option.id;

  return { isSelected, isChecked, selectingUsers };
};
