import { Flex, HStack } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Card } from "adminComponents/atoms/Card";
import { Heading } from "adminComponents/atoms/Heading";
import { Icon } from "adminComponents/atoms/Icon";
import { Text } from "adminComponents/atoms/Text";
import { PieChartWithLabel } from "adminComponents/molecules/PieChartWithLabel";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { IClassroom } from "links/lib/types";

interface IProps {
  accuracy?: number;
  classroom: IClassroom;
}

export const ClassroomSummaryCard: React.FC<IProps> = ({
  accuracy,
  classroom,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const { name, description, students_count } = classroom;
  return (
    <Card
      backgroundColor="primary.tan"
      borderColor="primary.tan"
      variant="adminCardSmallBorderSmallPadding"
    >
      <Heading as="h2" variant="adminH4" wordBreak="break-word">
        {name}
      </Heading>
      <Flex
        direction={["column", null, "row"]}
        gap={[pxToRem(8), null, pxToRem(24)]}
        mt={[pxToRem(16), null, pxToRem(10)]}
      >
        {description && <Text variant="adminP1">{description}</Text>}
        <HStack spacing={pxToRem(8)}>
          <PieChartWithLabel
            percentage={accuracy}
            text={t("classroomSummaryCard.averageClassAccuracy")}
          />
        </HStack>
        <HStack spacing={pxToRem(5)}>
          <Icon icon="account_circle_outlined" />
          <Text variant="adminP1">
            {t("classroomSummaryCard.student", { count: students_count })}
          </Text>
        </HStack>
      </Flex>
    </Card>
  );
};
