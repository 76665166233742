import { UseMutationResult, useMutation } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import {
  ExplainAction,
  ExplainMessage,
  IPracticeSetSessionItem,
} from "../../types";

export interface IExplainSessionItemArgs {
  action: ExplainAction;
  practice_set_session_id: string;
  practice_set_session_item_id: string;
  practice_set_item_id: string;
  group_id: string;
  practice_set_session_item: IPracticeSetSessionItem;
}

export interface IExplainSessionItemResponse {
  messages: Array<ExplainMessage>;
  available_actions: Array<ExplainAction>;
}

export function useExplainSessionItem(): UseMutationResult<
  IExplainSessionItemResponse,
  unknown,
  IExplainSessionItemArgs
> {
  const axios = useAxios();

  const explainSessionItem = async (args: IExplainSessionItemArgs) => {
    const {
      action,
      practice_set_session_id,
      practice_set_session_item_id,
      group_id,
      practice_set_item_id,
      practice_set_session_item,
    } = args;

    const response = await axios({
      method: "post",
      url: `/v1/practice-sets/sessions/${practice_set_session_id}/items/${practice_set_session_item_id}/explain`,
      data: {
        action,
        group_id,
        practice_set_item_id,
        practice_set_session_item,
      },
    }).then((r) => r.data as IExplainSessionItemResponse);

    return response;
  };

  const mutation = useMutation(explainSessionItem);

  return mutation;
}
