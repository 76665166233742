import { Box, Stack } from "@chakra-ui/react";
import React, { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useTimeout, useUnmount } from "react-use";

import { pxToRem } from "adminComponents/utils/pxToRem";
import {
  AnalyticsEvent,
  BigBoardGameState,
  ISessionGroups,
  ISessionUser,
} from "links/lib/types";
import { BigBoardStage } from "sessionComponents/atoms/BigBoardStage";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { AvatarLayout } from "sessionComponents/molecules/AvatarLayout";
import { BigBoard } from "sessionComponents/molecules/BigBoard";
import { ConfirmButton } from "sessionComponents/molecules/ConfirmButton";
import { StudentReactionsPicker } from "sessionComponents/molecules/StudentReactionsPicker";
import { useDetectOrientation } from "sessionComponents/theme/hooks/useDetectOrientation";
import { TeacherSessionSoundEffect } from "sessionComponents/types";
import { getBigBoardRows } from "sessionComponents/utils/getBigBoardRows";
import { getBigBoardSecondaryContentContainerHeight } from "sessionComponents/utils/layoutUtils";
import { useCarePackage } from "sharedComponents/contexts/carePackage";
import { useSessionAnalytics } from "sharedComponents/contexts/sessionAnalytics";
import { useSessionAudio } from "sharedComponents/contexts/sessionAudio";
import useBoardOutcome from "sharedComponents/hooks/useBoardOutcome";
import usePrizeRoundHighlight from "sharedComponents/hooks/usePrizeRoundHighlight";

import { useAvatarSpinAnimations } from "./hooks/useAvatarSpinAnimations";

export interface IBigBoardSpinProps {
  student?: ISessionUser;
  spinningUser: ISessionUser;
  gameState: BigBoardGameState;
  groups: ISessionGroups;
  buttonAction: (row: number) => void;
  isTeacher: boolean;
  nextAction: (isCarePackage: boolean) => void;
}

export const BigBoardSpin: React.FC<IBigBoardSpinProps> = ({
  student,
  spinningUser,
  gameState,
  groups,
  buttonAction,
  isTeacher,
  nextAction,
}) => {
  const { match: currentBreakpoints } = useBreakpoints();
  const { isPortrait, isLandscape } = useDetectOrientation();
  const { t } = useTranslation("session", {
    keyPrefix: "bigBoardSpin",
    useSuspense: false,
  });
  const { play: playAudio } = useSessionAudio();
  const { track } = useSessionAnalytics();

  // teachers do not see stop button until 5 seconds have passed
  // to give their students a chance to press it
  const [showStopButton] = useTimeout(isTeacher ? 5000 : 0);

  const { the_big_board_state } = gameState;
  const targetSpaceId = the_big_board_state.prize_round_space_id || 0;

  const isSpinningUser = student?.id === spinningUser.id;
  const groupIsControlGroup =
    spinningUser.session_group_id === student?.session_group_id;

  const {
    highlightedSpaceIds,
    highlightedRow,
    highlightedCol,
    atRest,
    reachedTargetRow,
    reset: resetHighlight,
  } = usePrizeRoundHighlight({
    targetSpaceId,
    rows: the_big_board_state.rows,
  });

  const boardOutcome = useBoardOutcome({
    user: spinningUser,
    targetSpaceId,
    boardState: the_big_board_state,
  });
  const { showNotice: showCPNotice } = useCarePackage();

  const progressIntervalRef = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    if (!isTeacher) return;
    if (targetSpaceId && atRest && !progressIntervalRef.current) {
      progressIntervalRef.current = setTimeout(() => {
        progressIntervalRef.current = null;

        nextAction(boardOutcome.isCarePackage);

        if (boardOutcome.isCarePackage) {
          playAudio(TeacherSessionSoundEffect.BBWin);
          track(AnalyticsEvent.Session_BigBoard_CarePackageAward, {});
        }
      }, 4000);
    } else if (!targetSpaceId && progressIntervalRef.current) {
      clearTimeout(progressIntervalRef.current);
      progressIntervalRef.current = null;
    }
  }, [
    targetSpaceId,
    atRest,
    boardOutcome.isCarePackage,
    isTeacher,
    playAudio,
    track,
    nextAction,
  ]);

  useUnmount(() => {
    if (progressIntervalRef.current) clearTimeout(progressIntervalRef.current);
  });

  // Reset the highlight when the board state resets
  useEffect(() => {
    if (!targetSpaceId) resetHighlight();
  }, [targetSpaceId, resetHighlight]);

  useEffect(() => {
    if (isTeacher && (highlightedRow || highlightedCol)) {
      playAudio(TeacherSessionSoundEffect.BBTick);
    }
  }, [highlightedRow, highlightedCol, isTeacher, playAudio]);

  useEffect(() => {
    if (isTeacher) return;
    if (boardOutcome.isCarePackage && atRest && groupIsControlGroup) {
      showCPNotice();
    }
  }, [
    atRest,
    boardOutcome.isCarePackage,
    groupIsControlGroup,
    showCPNotice,
    isTeacher,
  ]);

  const rows = useMemo(() => {
    return getBigBoardRows({
      the_big_board_state,
      groups,
      isSpinning: true,
      highlightedSpaceIds,
    });
  }, [the_big_board_state, highlightedSpaceIds, groups]);

  const bigBoardContainerHeight = isPortrait ? "55%" : "100%";

  const avatarAnimations = useAvatarSpinAnimations({
    atRest,
    boardOutcome,
    reachedTargetRow,
    targetSpaceId,
  });

  const memoizedAvatarCarousel = useMemo(
    () => (
      <AvatarLayout
        students={[spinningUser]}
        selfId={student?.id || "0"}
        showNametags={false}
        candidateAnimations={avatarAnimations}
        upperBodyZoom
      />
    ),
    [avatarAnimations, spinningUser, student?.id]
  );

  const stopSpinButton = showStopButton() ? (
    <ConfirmButton
      h={pxToRem(currentBreakpoints.buttonHeight)}
      w="100%"
      variant="buttonFilled"
      confirmedWhenChanged={[targetSpaceId]}
      handleConfirmClick={() => buttonAction(highlightedRow)}
      fontSize={pxToRem(currentBreakpoints.fontSize)}
    >
      {t("stopSpinButtonText")}
    </ConfirmButton>
  ) : (
    <></>
  );

  return (
    <BigBoardStage>
      <Stack h="full" w="full" direction={isPortrait ? "column" : "row"}>
        <Box w={isLandscape ? "50%" : "100%"} h={bigBoardContainerHeight}>
          <Box
            h="full"
            w="full"
            display="flex"
            alignItems="center"
            justifyContent="center"
            p={pxToRem(currentBreakpoints.padding)}
          >
            <BigBoard
              allowDrop={false}
              rows={rows}
              showSpecialSpaces
              isSpinning
              hideEmptyPlacements
              reachedTargetRow={reachedTargetRow}
              allLightsLit
              prizeRoundPoints={the_big_board_state.prize_round_points}
            />
          </Box>
        </Box>
        <Box
          w={isLandscape ? "50%" : "100%"}
          h={getBigBoardSecondaryContentContainerHeight(isPortrait)}
        >
          {memoizedAvatarCarousel}
        </Box>
      </Stack>
      <Box
        w={isLandscape ? "50%" : "100%"}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={pxToRem(currentBreakpoints.headerHeight)}
        p={pxToRem(currentBreakpoints.padding)}
      >
        {(isSpinningUser || isTeacher) && !targetSpaceId && stopSpinButton}
        {!isSpinningUser && !isTeacher && <StudentReactionsPicker />}
      </Box>
    </BigBoardStage>
  );
};
