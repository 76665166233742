import { Portal } from "@chakra-ui/react";
import EventEmitter from "events";
import React, {
  createContext,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLifecycles, useSessionStorage } from "react-use";

import {
  AnalyticsEvent,
  IAvatarItem,
  IUserReward,
  SessionWebSocketEvent,
} from "links/lib/types";
import { CarePackageNotice } from "sharedComponents/atoms/CarePackageNotice";
import { useSessionAnalytics } from "sharedComponents/contexts/sessionAnalytics";

export interface ICarePackageProviderProps {
  events: EventEmitter;
}

export interface ICarePackageContext {
  showNotice: () => void;
  requestItems: () => Array<IAvatarItem>;
  clearItems: () => void;
}

export const CarePackageContext = createContext<ICarePackageContext>({
  showNotice: () => {
    throw new Error("Not Implemented");
  },
  requestItems: () => {
    throw new Error("Not Implemented");
  },
  clearItems: () => {
    throw new Error("Not Implemented");
  },
});

const CARE_PACKAGE_PENDING_ITEMS_KEY = "CARE_PACKAGE_PENDING_ITEMS";

export const CarePackageProvider: React.FC<ICarePackageProviderProps> = ({
  events,
  children,
}) => {
  const [storedPendingItems, setStoredPendingItems] = useSessionStorage(
    CARE_PACKAGE_PENDING_ITEMS_KEY,
    "[]"
  );

  const pendingItems = useRef<Array<IAvatarItem>>(
    JSON.parse(storedPendingItems) || []
  );

  const { track } = useSessionAnalytics();
  const [showNotice, setShowNotice] = useState(false);

  const onNoticeEnd = useRef(() => {
    setShowNotice(false);
  });

  const requestItems = useRef(() => {
    setShowNotice(false);

    return pendingItems.current;
  });

  const clearItems = useRef(() => {
    setShowNotice(false);

    pendingItems.current = [];
    setStoredPendingItems("[]");
  });

  const requestShowNotice = useRef(() => {
    if (pendingItems.current.length) setShowNotice(true);
  });

  const value = useMemo(
    () => ({
      showNotice: requestShowNotice.current,
      requestItems: requestItems.current,
      clearItems: clearItems.current,
    }),
    []
  );

  const onUserRewards = useRef((args: { user_rewards: Array<IUserReward> }) => {
    const items = args.user_rewards.map((r) => r.avatar_item);

    const combinedItems = pendingItems.current.concat(items);

    // When a user levels up, all of the items they were awarded in the
    // course of a session are re-broadcast. De-duplicate to prevent
    // showing the same item twice.
    pendingItems.current = [
      ...new Map(combinedItems.map((item) => [item.id, item])).values(),
    ];

    setStoredPendingItems(JSON.stringify(pendingItems.current));

    items.forEach((item) => {
      track(AnalyticsEvent.Session_Common_CarePackagePending, {
        itemId: item.id,
      });
    });
  });

  useLifecycles(
    () => {
      events.on(SessionWebSocketEvent.UserRewards, onUserRewards.current);
    },
    () => {
      events.off(SessionWebSocketEvent.UserRewards, onUserRewards.current);
    }
  );

  return (
    <CarePackageContext.Provider value={value}>
      {children}
      <Portal>
        <CarePackageNotice show={showNotice} onEnd={onNoticeEnd.current} />
      </Portal>
    </CarePackageContext.Provider>
  );
};

export const useCarePackage = (): ICarePackageContext => {
  return useContext(CarePackageContext);
};
