import { Box } from "@chakra-ui/react";
import React from "react";

import { LoadingSpinner } from "adminComponents/atoms/LoadingSpinner";
import { QuestionsTab as StudentQuestionsTab } from "adminComponents/screens/StudentAssignmentReportScreen/components/QuestionsTab";
import { pxToRem } from "adminComponents/utils";
import { usePageTrack } from "lib/contexts/analytics";
import { useAuth } from "links/lib/features/auth";
import { useFetchPracticeSetItems } from "links/lib/features/practiceSetItems";
import { AnalyticsPage, IAssignment, IClassroom, IUser } from "links/lib/types";
import { useStudentQuestionsData } from "screens/TeacherClassrooms/PracticeSetDetail/components/PracticeSetStudentDetail/hooks/useStudentQuestionData";

interface IQuestionsTabProps {
  classroom: IClassroom;
  assignment: IAssignment;
  student: IUser;
  practiceSetSessionId?: string;
}

export const QuestionsTab: React.FC<IQuestionsTabProps> = ({
  assignment,
  classroom,
  student,
  practiceSetSessionId,
}) => {
  const { hasNoPremiumAccess } = useAuth();
  usePageTrack(
    AnalyticsPage.TeacherDashboard_ClassroomDetail_AssignmentDetail_Questions
  );

  const itemsFetch = useFetchPracticeSetItems({
    practice_set_id: assignment?.practice_set_id || "0",
  });

  const questionsData = useStudentQuestionsData({
    practiceSetItemsData: itemsFetch.data,
    classroomId: classroom.id,
    practiceSetId: assignment?.practice_set_id,
    practiceSetSessionId: practiceSetSessionId,
    student,
    assignmentId: assignment.id,
  });

  const isLoading = itemsFetch.isLoading || questionsData.isLoading;

  return (
    <>
      {isLoading ? (
        <Box mt={pxToRem(20)}>
          <LoadingSpinner />
        </Box>
      ) : (
        <StudentQuestionsTab
          questions={questionsData.questionsData || []}
          hasNoPremiumAccess={hasNoPremiumAccess}
        />
      )}
    </>
  );
};
