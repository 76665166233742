import { Box } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { EmptyCard } from "adminComponents/molecules/EmptyCard";
import { StudentPracticeSetCard } from "adminComponents/molecules/StudentPracticeSetCard";
import { StudentPracticeSetGridCarousel } from "adminComponents/molecules/StudentPracticeSetGridCarousel";
import { IMyPracticeSet } from "adminComponents/screens/MyClassesScreen";
import { IPendingSession, IPracticeSet } from "links/lib/types";

export interface IProps {
  myPracticeSets?: IMyPracticeSet[];
  pendingSession?: IPendingSession;
  handlePlayPracticeSet: (practiceSet: IPracticeSet) => void;
}

export const MyClassPracticeSets: React.FC<IProps> = ({
  myPracticeSets,
  pendingSession,
  handlePlayPracticeSet,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  const sortedPracticeSets = useMemo(() => {
    return myPracticeSets?.sort((a, b) => {
      return a.practiceSet.group_practice_sets_created_at <
        b.practiceSet.group_practice_sets_created_at
        ? 1
        : a.practiceSet.group_practice_sets_created_at >
          b.practiceSet.group_practice_sets_created_at
        ? -1
        : 0;
    });
  }, [myPracticeSets]);

  return (
    <>
      {sortedPracticeSets?.length ? (
        <Box
          ml={{ base: "-admin.mobileXPadding", md: 0 }}
          sx={{
            ".swiper": {
              pl: { base: "admin.mobileXPadding", md: 0 },
              pr: { base: "admin.mobileXPadding", md: 0 },
            },
          }}
          width={{
            base: "calc(100% + (var(--chakra-space-admin-mobileXPadding) * 2))",
            md: "initial",
          }}
        >
          <StudentPracticeSetGridCarousel>
            {sortedPracticeSets.map((card) => {
              const { rewardXp, practiceSet } = card;

              return (
                <StudentPracticeSetCard
                  key={practiceSet.id}
                  headingLevel="h3"
                  practiceSet={practiceSet}
                  rewardXp={rewardXp}
                  handlePlayButton={() => handlePlayPracticeSet(practiceSet)}
                  isDisabled={!!pendingSession}
                  isLoading={pendingSession?.practiceSetId === practiceSet.id}
                />
              );
            })}
          </StudentPracticeSetGridCarousel>
        </Box>
      ) : (
        <EmptyCard title={t("myClassesScreen.emptyPracticeSets")} />
      )}
    </>
  );
};
