import { Box, VStack } from "@chakra-ui/react";
import React, { useCallback, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { animated, config, useTransition } from "react-spring";
import { usePrevious } from "react-use";

import { usePageTrack } from "lib/contexts/analytics";
import { config as appConfig } from "links/lib/constants";
import { useSession } from "links/lib/contexts/session";
import { useSessionEvents } from "links/lib/contexts/sessionEvents";
import { useSessionGameState } from "links/lib/contexts/sessionGameState";
import { useSessionRoundState } from "links/lib/contexts/sessionRoundState";
import { SessionRoundThemeProvider } from "links/lib/contexts/sessionRoundTheme";
import { useSessionScene } from "links/lib/contexts/sessionScene";
import { useSessionUsers } from "links/lib/contexts/sessionUsers";
import { useAuth } from "links/lib/features/auth";
import { useGenerateSessionRoundTheme } from "links/lib/features/sessionRoundTheme";
import { useRestartSession } from "links/lib/features/sessions";
import { AnalyticsPage, IUser, SessionScene } from "links/lib/types";
import {
  RoundReviewProvider,
  useRoundReview,
} from "sessionComponents/contexts/roundReview";
import { StudentProvider } from "sessionComponents/contexts/student";
import { StudentReactionsProvider } from "sessionComponents/contexts/studentReactions";
import { useHeaderTitleText } from "sessionComponents/hooks/useHeaderTitleText";
import { StudentFinalPodiumView } from "sessionComponents/organisms/StudentFinalPodiumView";
import { StudentHeader } from "sessionComponents/organisms/StudentHeader";
import { fullScreenStyles } from "sessionComponents/theme";
import { CarePackageProvider } from "sharedComponents/contexts/carePackage";
import { SessionAnalyticsProvider } from "sharedComponents/contexts/sessionAnalytics";
import {
  SessionAudioProvider,
  useSessionAudio,
} from "sharedComponents/contexts/sessionAudio";
import "sharedComponents/css/ios-full-height.css";
import StudentSessionAudioSpriteDetail from "sharedComponents/resource/audio/student/output.json";
import StudentSessionAudioSpriteSrcMP3 from "sharedComponents/resource/audio/student/output.mp3";
import StudentSessionAudioSpriteSrcWebM from "sharedComponents/resource/audio/student/output.webm";

import { IndividualSessionLobby } from "../../../scenes/IndividualSessionLobby";
import { StudentQuestionRound } from "../shared/StudentQuestionRound";
import { StudentRoundReviewWrapper } from "../shared/StudentRoundReviewWrapper";

interface IIndividualSessionViewProps {
  authUser: IUser;
  disableUnloadWarning: () => void;
}

const AnimatedBox = animated(Box);

export const IndividualSessionView: React.FC<IIndividualSessionViewProps> = (
  props: IIndividualSessionViewProps
) => {
  const scene = useSessionScene();
  const roundState = useSessionRoundState();
  const { round_number } = roundState;
  const session = useSession();
  const events = useSessionEvents();
  const users = useSessionUsers();
  const { authUser, disableUnloadWarning } = props;
  const location = useLocation() as { state: { redirectToSeasonMap: boolean } };
  const history = useHistory();

  const endGameRedirect = location.state?.redirectToSeasonMap
    ? "/s/season-map"
    : "/";

  const onEndGame = () => {
    history.push(endGameRedirect);
  };

  const restartSession = useRestartSession({
    onSuccess: ({ practice_set_session }) => {
      window.location.href = `${appConfig.baseUrl}/session/join/${
        practice_set_session.join_code
      }?redirectToSeasonMap=${!!location.state
        ?.redirectToSeasonMap}&isIndividualSession=true`;
    },
    onError: (err) => {
      if (err.response?.status === 404) {
        history.push("/");
      }
    },
  });
  const gameState = useSessionGameState();
  const finalRoundNumber = gameState?.final_round_number ?? 1; // prevent divide by 0

  const student = users[authUser.id];

  usePageTrack(AnalyticsPage.Session_IndividualSession_Student);
  const roundTheme = useGenerateSessionRoundTheme(round_number);

  const onRestart = useCallback(() => {
    disableUnloadWarning();
    restartSession.mutate({
      practiceSetSessionId: session?.id || "",
    });
  }, [restartSession, session?.id, disableUnloadWarning]);

  if (!events) return null;

  return (
    <StudentProvider userId={authUser.id}>
      <SessionAnalyticsProvider
        student={student}
        session={session}
        round_state={roundState}
        scene={scene}
      >
        <SessionAudioProvider
          srcVolume={1}
          spriteSrc={[
            StudentSessionAudioSpriteSrcWebM,
            StudentSessionAudioSpriteSrcMP3,
          ]}
          sprite={StudentSessionAudioSpriteDetail.sprite}
        >
          <SessionRoundThemeProvider roundTheme={roundTheme}>
            <CarePackageProvider events={events}>
              <StudentReactionsProvider>
                <RoundReviewProvider>
                  <IndividualSession
                    authUser={authUser}
                    scene={scene}
                    onRestart={onRestart}
                    roundNumber={round_number}
                    finalRoundNumber={finalRoundNumber}
                    onEndGame={onEndGame}
                  />
                </RoundReviewProvider>
              </StudentReactionsProvider>
            </CarePackageProvider>
          </SessionRoundThemeProvider>
        </SessionAudioProvider>
      </SessionAnalyticsProvider>
    </StudentProvider>
  );
};

interface IIndividualSessionProps {
  authUser: IUser;
  scene: SessionScene;
  onRestart: () => void;
  roundNumber: number;
  finalRoundNumber: number;
  onEndGame: () => void;
}

const IndividualSession: React.FC<IIndividualSessionProps> = ({
  authUser,
  scene,
  onRestart,
  finalRoundNumber,
  roundNumber,
  onEndGame,
}) => {
  const { setVolume, volume } = useSessionAudio();
  const headerTitleText = useHeaderTitleText({
    isTeacher: false,
    userId: authUser.id,
  });
  const { isSplashScreenVisible } = useRoundReview();
  const { isFeatureEnabled } = useAuth();

  const onToggleSound = (enabled: boolean) => {
    if (enabled) {
      setVolume(1);
    } else {
      setVolume(0);
    }
  };

  const sceneTransition = useTransition([scene], {
    from: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
    },
    leave: {
      opacity: 1,
    },
    exitBeforeEnter: false,
    config: config.slow,
  });

  const renderScene = (scene: SessionScene): JSX.Element => {
    switch (scene) {
      case SessionScene.IndividualSessionLobby:
        return <IndividualSessionLobby />;
      case SessionScene.Podium:
        return <StudentFinalPodiumView />;
      case SessionScene.Round:
        return <StudentQuestionRound disableRoundIntro />;
      case SessionScene.RoundReview:
        return <StudentRoundReviewWrapper isClassSession={false} />;
    }

    return <>Scene not implemented: {scene}</>;
  };

  const isEndOfGame = scene === SessionScene.Podium;
  const isRoundReview = scene === SessionScene.RoundReview;

  // race condition with scene updating independently of round number causes issues
  // with updating progress bar too early. Ensure the progress bar only moves when:
  // - the scene is round review
  // - and the splash screen has finished
  const lastReviewRound = useRef<number>(roundNumber);
  const prevScene = usePrevious(scene);
  if (prevScene === SessionScene.Round && scene === SessionScene.RoundReview) {
    lastReviewRound.current = roundNumber;
  }

  const lastSplashRound = useRef<number | undefined>(undefined);
  if (isSplashScreenVisible) {
    lastSplashRound.current = roundNumber;
  }

  const sessionProgressEnabled = isFeatureEnabled("playtime.session.progress");
  const showProgress = sessionProgressEnabled && !isEndOfGame;
  // only increase progress when in round review and the splash screen has finished
  const shouldProgressUpdate =
    isRoundReview &&
    !isSplashScreenVisible &&
    lastSplashRound.current === roundNumber;
  const progressRound = shouldProgressUpdate
    ? lastReviewRound.current
    : roundNumber - 1;
  const sessionProgress = showProgress
    ? (progressRound / finalRoundNumber) * 100
    : undefined;

  return sceneTransition((style, s) => (
    <VStack {...fullScreenStyles} spacing="0">
      <StudentHeader
        headingText={headerTitleText}
        cursorsVisible={false}
        isSoundEnabled={!!volume}
        onToggleSound={onToggleSound}
        onExit={onEndGame}
        hideStickers
        hideCursorButton
        isEndOfGame={isEndOfGame}
        onRestart={onRestart}
        sessionProgress={sessionProgress}
      />
      <AnimatedBox
        zIndex={s === scene ? "auto" : "-1"}
        w="full"
        h="full"
        overflow="hidden"
        style={style}
      >
        {renderScene(s)}
      </AnimatedBox>
    </VStack>
  ));
};
