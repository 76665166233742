import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import type { ISubject } from "../../types";

export interface IFetchSubjectsResponse {
  subjects: Array<ISubject>;
}

export interface IFetchSubjectsOpts {
  limit: number;
  country: string;
  region?: string;
}

/**
 * Fetches a list of subjects
 * @returns
 */
export default function useFetchSubjects(
  opts: IFetchSubjectsOpts
): UseQueryResult<IFetchSubjectsResponse, unknown> {
  const { limit, country, region } = opts;
  const axios = useAxios();

  const query = useQuery<IFetchSubjectsResponse>(
    ["subjects", { limit, country, region }],
    async (): Promise<IFetchSubjectsResponse> => {
      return await axios
        .get(
          `/v1/subjects?limit=${limit}&country=${country}&region=${
            region || ""
          }`
        )
        .then((r) => r.data as IFetchSubjectsResponse);
    }
  );

  return query;
}
