import { useMemo } from "react";

import { ISessionUser } from "links/lib/types";
import { useSafeConnectedUserArray } from "sharedComponents/hooks/useSafeConnectedUserArray";

export const useGroupConnectedUsers = (groupId: string): ISessionUser[] => {
  const users = useSafeConnectedUserArray();
  const groupUsers = useMemo(() => {
    return users.filter(
      (u) => u.session_group_id === groupId
    ) as ISessionUser[];
  }, [users, groupId]);

  return groupUsers;
};
