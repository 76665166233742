import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useSessionActions } from "links/lib/contexts/sessionActions";
import { useSessionGameState } from "links/lib/contexts/sessionGameState";
import { useSessionGroups } from "links/lib/contexts/sessionGroups";
import { useSessionRoundState } from "links/lib/contexts/sessionRoundState";
import { useSessionUsers } from "links/lib/contexts/sessionUsers";
import { AnalyticsEvent, BigBoardGameState } from "links/lib/types";
import { useStudent } from "sessionComponents/contexts/student";
import { useTokenPlacementAudio } from "sessionComponents/hooks/useTokenPlacementAudio";
import { NoTokenPlacement } from "sessionComponents/molecules/NoTokenPlacement";
import { StudentBigBoardPlacement } from "sessionComponents/organisms/StudentBigBoardPlacement";
import { TeacherBigBoardPlacement } from "sessionComponents/organisms/TeacherBigBoardPlacement";
import { getBigBoardRows } from "sessionComponents/utils/getBigBoardRows";
import { getGroupWithWarning } from "sessionComponents/utils/getGroupWithWarning";
import { useSessionAnalytics } from "sharedComponents/contexts/sessionAnalytics";

export interface IBigBoardTokenPlacementSceneProps {
  isTeacher: boolean;
}

export const BigBoardTokenPlacementScene: React.FC<
  IBigBoardTokenPlacementSceneProps
> = ({ isTeacher }) => {
  if (isTeacher) {
    return <TeacherBigBoardPlacementScene />;
  } else {
    return <StudentBigBoardPlacementScene />;
  }
};

const TeacherBigBoardPlacementScene: React.FC = () => {
  const gameState = useSessionGameState() as BigBoardGameState;
  const groups = useSessionGroups();
  const roundState = useSessionRoundState();
  const { initiateNextRound, initiatePrizeRound } = useSessionActions();
  const { track } = useSessionAnalytics();
  const { t } = useTranslation("session", {
    keyPrefix: "bigBoard",
    useSuspense: false,
  });

  useTokenPlacementAudio();

  const { the_big_board_state } = gameState;
  const { round_placed_group_ids, round_allowed_group_ids } =
    the_big_board_state ?? {
      round_placed_group_ids: [],
      round_allowed_group_ids: [],
    };

  const allowedGroups = useMemo(() => {
    return round_allowed_group_ids.map((gid) => groups[gid]).filter((g) => !!g);
  }, [round_allowed_group_ids, groups]);

  const getProgressButton = useCallback(() => {
    const nextRound = () => {
      initiateNextRound();

      track(AnalyticsEvent.Session_BigBoard_CompleteTokenPlacement, {
        all_groups_placed:
          allowedGroups.length === Object.values(round_placed_group_ids).length,
      });
    };

    const prizeRound = () => {
      initiatePrizeRound();

      track(AnalyticsEvent.Session_BigBoard_CompleteTokenPlacement, {
        all_groups_placed:
          allowedGroups.length === Object.values(round_placed_group_ids).length,
      });
    };

    const isPrizeRound = !(roundState.round_number % 3);

    if (
      roundState.round_number !== gameState.final_round_number &&
      !isPrizeRound
    ) {
      return {
        label: allowedGroups.length
          ? t("teacherNextRoundButton")
          : t("teacherNextRoundNoGroupsButton"),
        action: nextRound,
      };
    } else {
      return {
        label: t("teacherPrizeRoundButton"),
        action: prizeRound,
      };
    }
  }, [
    gameState.final_round_number,
    roundState.round_number,
    allowedGroups.length,
    round_placed_group_ids,
    track,
    initiateNextRound,
    initiatePrizeRound,
    t,
  ]);

  const rows = useMemo(() => {
    return getBigBoardRows({ the_big_board_state, groups });
  }, [the_big_board_state, groups]);

  return (
    <TeacherBigBoardPlacement
      gameState={gameState}
      groups={groups}
      rows={rows}
      progressButton={getProgressButton()}
    />
  );
};

const StudentBigBoardPlacementScene: React.FC = () => {
  const gameState = useSessionGameState() as BigBoardGameState;
  const groups = useSessionGroups();
  const users = useSessionUsers();
  const { placeTheBigBoardToken } = useSessionActions();

  const student = useStudent();

  const { the_big_board_state } = gameState;
  const { round_token_control_user_ids, round_allowed_group_ids } =
    the_big_board_state ?? {
      round_token_control_user_ids: [],
      round_allowed_group_ids: [],
    };

  const groupTokenAllowed = useMemo(() => {
    return round_allowed_group_ids.includes(student.session_group_id);
  }, [round_allowed_group_ids, student.session_group_id]);

  const groupTokenControlUser = useMemo(() => {
    const groupUserId = round_token_control_user_ids.find((uid) => {
      return users[uid]?.session_group_id === student.session_group_id;
    });

    if (!groupUserId) return null;

    return users[groupUserId];
  }, [round_token_control_user_ids, student.session_group_id, users]);

  const group = getGroupWithWarning(groups, student.session_group_id);

  const rows = useMemo(() => {
    return getBigBoardRows({ the_big_board_state, groups, groupId: group?.id });
  }, [the_big_board_state, group?.id, groups]);

  // todo some kind of crash state needed for big board
  if (!group) return <></>;

  return (
    <>
      {groupTokenAllowed && groupTokenControlUser && (
        <StudentBigBoardPlacement
          gameState={gameState}
          placingUser={groupTokenControlUser}
          placingUserGroup={group}
          rows={rows}
          userId={student.id}
          onPlaceToken={placeTheBigBoardToken}
        />
      )}

      {!groupTokenAllowed && <NoTokenPlacement group={group} />}
    </>
  );
};
