import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { usePrevious } from "react-use";

import { useErrorToast } from "adminComponents/utils/toast";
import { usePageTitle } from "lib/hooks/usePageTitle";
import { useAuth } from "links/lib/features/auth";
import {
  useExchangeTicket,
  useFetchSeasonMap,
  useVisitSeasonLocation,
} from "links/lib/features/season";
import { ExchangeTicketResponse } from "links/lib/features/season/useExchangeTicket";
import { IAvatarItem } from "links/lib/types";
import { useStudentDashboardData } from "screens/StudentDashboard/contexts/StudentDashboardDataProvider";
import { Season1MapAudio } from "sharedComponents/molecules/Season1MapAudio";
import { Season1MapZoneUnlock } from "sharedComponents/molecules/Season1MapZoneUnlock";
import { Season2MapAudio } from "sharedComponents/molecules/Season2MapAudio";
import { Season2MapZoneUnlock } from "sharedComponents/molecules/Season2MapZoneUnlock";
import { Season3MapAudio } from "sharedComponents/molecules/Season3MapAudio";
import { Season3MapZoneUnlock } from "sharedComponents/molecules/Season3MapZoneUnlock";
import { Season4MapAudio } from "sharedComponents/molecules/Season4MapAudio";
import { Season4MapZoneUnlock } from "sharedComponents/molecules/Season4MapZoneUnlock";
import { CombinedItem } from "sharedComponents/molecules/SeasonLocationProgress";
import { SeasonMapItemUnlocked } from "sharedComponents/molecules/SeasonMapItemUnlocked";
import { Season1Modal } from "sharedComponents/organisms/Season1Modal";
import { Season2Modal } from "sharedComponents/organisms/Season2Modal";
import { Season3Modal } from "sharedComponents/organisms/Season3Modal";
import { Season4Modal } from "sharedComponents/organisms/Season4Modal";

export const StudentSeasonMap: React.FC = () => {
  const history = useHistory();
  const { authUser } = useAuth();
  const { avatarResponse } = useStudentDashboardData();
  // whether the bus pass splash should show
  const [showPassUpgrade, setShowPassUpgrade] = useState(false);
  // If an item has been unlocked
  const [unlockedItem, setUnlockedItem] = useState<IAvatarItem | undefined>(
    undefined
  );
  // whether the unlocked item splash should show
  const [showUnlockedItem, setShowUnlockedItem] = useState(false);
  const { t } = useTranslation("admin", {
    keyPrefix: "studentSeasonMapContainer",
    useSuspense: false,
  });

  usePageTitle(t("pageTitle"));

  const fetchSeasonMap = useFetchSeasonMap();
  const visitSeasonLocation = useVisitSeasonLocation();

  useErrorToast(visitSeasonLocation.error);
  useErrorToast(fetchSeasonMap.error);

  const handleExchangeTicketSuccess = (data: ExchangeTicketResponse) => {
    const { avatar_item, avatar_item_group } = data;
    if (avatar_item) {
      setUnlockedItem({
        ...avatar_item,
        is_active: true,
        is_public: false,
        is_category_default: false,
        attachments: [],
        colors: [],
      });
      setShowUnlockedItem(true);
    } else if (avatar_item_group) {
      setUnlockedItem({
        ...avatar_item_group,
        avatar_item_category_id: "0",
        is_active: true,
        is_public: false,
        is_category_default: false,
        attachments: [],
        colors: [],
      });
      setShowUnlockedItem(true);
    }
  };

  const exhangeTicket = useExchangeTicket({
    onSuccess: handleExchangeTicketSuccess,
  });

  useErrorToast(exhangeTicket.error);

  const handleClose = () => {
    history.goBack();
  };

  const handleRequestUnlock = (item: CombinedItem) => {
    exhangeTicket.mutate(
      item.type === "group"
        ? { avatar_item_group_id: item.content.id }
        : { avatar_item_id: item.content.id }
    );
  };

  const handleVisitLocation = (location_id: string) => {
    visitSeasonLocation.mutate({ location_id });
  };

  const seasonData = useMemo(() => {
    const unlockedZoneIds = fetchSeasonMap.data?.zones.map((z) => z.id) ?? [];

    const locationProgess =
      fetchSeasonMap.data?.zones
        .flatMap((z) => z.locations)
        .map((l) => {
          return {
            locationId: l.id,
            totalSpaces:
              l.total_avatar_items_count + l.total_avatar_item_groups_count,
            spacesCollected:
              l.user_avatar_items_count + l.user_avatar_item_groups_count,
          };
        }) ?? [];

    const avatarItems =
      fetchSeasonMap.data?.zones
        .flatMap((z) => z.locations)
        .flatMap((l) => {
          return l.avatar_items;
        }) ?? [];

    const avatarItemGroups =
      fetchSeasonMap.data?.zones
        .flatMap((z) => z.locations)
        .flatMap((l) => {
          return l.avatar_item_groups;
        }) ?? [];

    return { unlockedZoneIds, avatarItems, locationProgess, avatarItemGroups };
  }, [fetchSeasonMap.data]);

  // Check if the number of unlocked zones have changed
  // and play bus pass upgrade animation if so
  const prevUnlockedZonesCount =
    usePrevious(seasonData.unlockedZoneIds.length) ?? 0;
  useEffect(() => {
    if (
      prevUnlockedZonesCount > 0 &&
      prevUnlockedZonesCount < seasonData.unlockedZoneIds.length
    ) {
      setShowPassUpgrade(true);
    }
  }, [seasonData.unlockedZoneIds.length, prevUnlockedZonesCount]);

  const handlePassUpgradeComplete = useCallback(() => {
    setShowPassUpgrade(false);
  }, []);

  const handleUnlockAnimComplete = useCallback(() => {
    setShowUnlockedItem(false);
  }, []);

  const isSeasonFour = fetchSeasonMap.data?.season_id === "5";
  if (isSeasonFour) {
    return (
      <Season4MapAudio>
        <Season4Modal
          isOpen={true}
          isLoading={fetchSeasonMap.isLoading}
          handleClose={handleClose}
          locationsProgress={seasonData.locationProgess}
          ticketCount={fetchSeasonMap.data?.current_unexchanged_tickets ?? 0}
          currentLocationId={fetchSeasonMap.data?.current_location_id}
          avatarItems={seasonData.avatarItems}
          avatarItemGroups={seasonData.avatarItemGroups}
          avatarAtlasUrl={avatarResponse?.spine_atlas_url ?? ""}
          avatarSkeletonUrl={avatarResponse?.spine_json_url ?? ""}
          avatar={avatarResponse?.avatar}
          handleRequestUnlock={handleRequestUnlock}
          isUnlockLoading={exhangeTicket.isLoading}
          unlockedZoneIds={seasonData.unlockedZoneIds}
          userProfileImageUrl={authUser?.profile_image_url}
          handleVisitLocation={handleVisitLocation}
        />
        <Season4MapZoneUnlock
          handleComplete={handlePassUpgradeComplete}
          show={!showUnlockedItem && showPassUpgrade}
          zoneCount={seasonData.unlockedZoneIds.length}
        />
        <SeasonMapItemUnlocked
          show={showUnlockedItem}
          item={unlockedItem}
          handleComplete={handleUnlockAnimComplete}
          skinToneHexColor={avatarResponse?.avatar?.skin_tone_hex_code}
          hairColorHexColor={avatarResponse?.avatar?.hair_color_hex_code}
        />
      </Season4MapAudio>
    );
  }

  const isSeasonThree = fetchSeasonMap.data?.season_id === "4";
  if (isSeasonThree) {
    return (
      <Season3MapAudio>
        <Season3Modal
          isOpen={true}
          isLoading={fetchSeasonMap.isLoading}
          handleClose={handleClose}
          locationsProgress={seasonData.locationProgess}
          ticketCount={fetchSeasonMap.data?.current_unexchanged_tickets ?? 0}
          currentLocationId={fetchSeasonMap.data?.current_location_id}
          avatarItems={seasonData.avatarItems}
          avatarItemGroups={seasonData.avatarItemGroups}
          avatarAtlasUrl={avatarResponse?.spine_atlas_url ?? ""}
          avatarSkeletonUrl={avatarResponse?.spine_json_url ?? ""}
          avatar={avatarResponse?.avatar}
          handleRequestUnlock={handleRequestUnlock}
          isUnlockLoading={exhangeTicket.isLoading}
          unlockedZoneIds={seasonData.unlockedZoneIds}
          userProfileImageUrl={authUser?.profile_image_url}
          handleVisitLocation={handleVisitLocation}
        />
        <Season3MapZoneUnlock
          handleComplete={handlePassUpgradeComplete}
          show={!showUnlockedItem && showPassUpgrade}
          zoneCount={seasonData.unlockedZoneIds.length}
        />
        <SeasonMapItemUnlocked
          show={showUnlockedItem}
          item={unlockedItem}
          handleComplete={handleUnlockAnimComplete}
          skinToneHexColor={avatarResponse?.avatar?.skin_tone_hex_code}
          hairColorHexColor={avatarResponse?.avatar?.hair_color_hex_code}
        />
      </Season3MapAudio>
    );
  }

  const isSeasonTwo = fetchSeasonMap.data?.season_id === "3";
  if (isSeasonTwo) {
    return (
      <Season2MapAudio>
        <Season2Modal
          isOpen={true}
          isLoading={fetchSeasonMap.isLoading}
          handleClose={handleClose}
          locationsProgress={seasonData.locationProgess}
          ticketCount={fetchSeasonMap.data?.current_unexchanged_tickets ?? 0}
          currentLocationId={fetchSeasonMap.data?.current_location_id}
          avatarItems={seasonData.avatarItems}
          avatarItemGroups={seasonData.avatarItemGroups}
          avatarAtlasUrl={avatarResponse?.spine_atlas_url ?? ""}
          avatarSkeletonUrl={avatarResponse?.spine_json_url ?? ""}
          avatar={avatarResponse?.avatar}
          handleRequestUnlock={handleRequestUnlock}
          isUnlockLoading={exhangeTicket.isLoading}
          unlockedZoneIds={seasonData.unlockedZoneIds}
          userProfileImageUrl={authUser?.profile_image_url}
          handleVisitLocation={handleVisitLocation}
        />
        <Season2MapZoneUnlock
          handleComplete={handlePassUpgradeComplete}
          show={!showUnlockedItem && showPassUpgrade}
          zoneCount={seasonData.unlockedZoneIds.length}
        />
        <SeasonMapItemUnlocked
          show={showUnlockedItem}
          item={unlockedItem}
          handleComplete={handleUnlockAnimComplete}
          skinToneHexColor={avatarResponse?.avatar?.skin_tone_hex_code}
          hairColorHexColor={avatarResponse?.avatar?.hair_color_hex_code}
        />
      </Season2MapAudio>
    );
  }

  return (
    <Season1MapAudio>
      <Season1Modal
        isOpen={true}
        isLoading={fetchSeasonMap.isLoading}
        handleClose={handleClose}
        locationsProgress={seasonData.locationProgess}
        ticketCount={fetchSeasonMap.data?.current_unexchanged_tickets ?? 0}
        currentLocationId={fetchSeasonMap.data?.current_location_id}
        avatarItems={seasonData.avatarItems}
        avatarItemGroups={seasonData.avatarItemGroups}
        avatarAtlasUrl={avatarResponse?.spine_atlas_url ?? ""}
        avatarSkeletonUrl={avatarResponse?.spine_json_url ?? ""}
        avatar={avatarResponse?.avatar}
        handleRequestUnlock={handleRequestUnlock}
        isUnlockLoading={exhangeTicket.isLoading}
        unlockedZoneIds={seasonData.unlockedZoneIds}
        userProfileImageUrl={authUser?.profile_image_url}
        handleVisitLocation={handleVisitLocation}
      />
      <Season1MapZoneUnlock
        handleComplete={handlePassUpgradeComplete}
        show={!showUnlockedItem && showPassUpgrade}
        zoneCount={seasonData.unlockedZoneIds.length}
      />
      <SeasonMapItemUnlocked
        show={showUnlockedItem}
        item={unlockedItem}
        handleComplete={handleUnlockAnimComplete}
        skinToneHexColor={avatarResponse?.avatar?.skin_tone_hex_code}
        hairColorHexColor={avatarResponse?.avatar?.hair_color_hex_code}
      />
    </Season1MapAudio>
  );
};
