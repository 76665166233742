import {
  Box,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { IIconButtonProps, IconButton } from "../../atoms/IconButton";

interface IImageZoomButtonProps extends IIconButtonProps {
  imageUrl: string;
  imageAltText: string;
  showZoom: boolean;
}

export const ImageZoomButton: React.FC<IImageZoomButtonProps> = ({
  icon,
  position,
  bottom,
  left,
  boxSize,
  iconSize,
  zIndex,
  imageUrl,
  imageAltText,
  showZoom,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation("session", {
    keyPrefix: "ariaLabels",
    useSuspense: false,
  });

  const handleKeypress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (showZoom && e.key === " ") {
      e.stopPropagation();
      setModalOpen(true);
    }
  };

  return (
    <>
      <Box
        tabIndex={0}
        position={position}
        bottom={bottom}
        left={left}
        display="flex"
        onKeyPress={handleKeypress}
      >
        {showZoom && (
          <IconButton
            aria-label={t("zoomButtonLabel")}
            icon={icon}
            boxSize={boxSize}
            iconSize={iconSize}
            zIndex={zIndex}
            type="button"
            onClick={(e: React.MouseEvent<HTMLDivElement>) => {
              e.stopPropagation();
              setModalOpen(true);
            }}
          />
        )}
      </Box>
      {modalOpen && (
        <FullSizeModal
          imageUrl={imageUrl}
          imageAltText={imageAltText}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
        />
      )}
    </>
  );
};

const FullSizeModal = ({
  imageUrl,
  imageAltText,
  modalOpen,
  setModalOpen,
}: {
  imageUrl: string;
  imageAltText: string;
  modalOpen: boolean;
  setModalOpen: (open: boolean) => void;
}) => {
  return (
    <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)} size="5xl">
      <ModalOverlay />

      <ModalContent>
        <ModalCloseButton
          backgroundColor="white"
          onKeyPress={(e: React.KeyboardEvent<HTMLButtonElement>) =>
            e.stopPropagation()
          }
        />

        <ModalBody>
          <Image
            w="full"
            maxH="80vh"
            fill="white"
            objectFit="contain"
            draggable="false"
            pointerEvents="none"
            src={imageUrl}
            alt={imageAltText}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
