import { Box, VStack } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { PracticeSetSection } from "adminComponents/molecules/PracticeSetSection";
import { SearchResultsTab } from "adminComponents/organisms/LibrarySearchResultsTabs";
import { IPracticeSet } from "links/lib/types";

export interface IProps {
  isLoading: boolean;
  handleTabChange: (tab: SearchResultsTab) => void;
  setsFromExperts: IPracticeSet[];
  setsFromExpertsCount: number;
  setsFromCommunity: IPracticeSet[];
  setsFromCommunityCount: number;
  mySets: IPracticeSet[];
  mySetsCount: number;
  myDistrictSets: IPracticeSet[];
  myDistrictSetsCount: number;
  onPracticeSetPreview: (set: IPracticeSet) => void;
  hideExpertTab?: boolean;
}

export const AllSetsTab: React.FC<IProps> = ({
  isLoading,
  handleTabChange,
  setsFromExperts,
  setsFromExpertsCount,
  setsFromCommunity,
  setsFromCommunityCount,
  mySets,
  mySetsCount,
  myDistrictSets,
  myDistrictSetsCount,
  onPracticeSetPreview,
  hideExpertTab = false,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  return (
    <Box bgColor="primary.tan-light">
      <VStack>
        {!hideExpertTab && (
          <PracticeSetSection
            count={setsFromExpertsCount}
            isLoading={isLoading}
            practiceSets={setsFromExperts}
            handleViewMore={() => {
              handleTabChange(SearchResultsTab.FromExperts);
            }}
            title={t("librarySearchResults.fromExperts")}
            onPracticeSetPreview={onPracticeSetPreview}
          />
        )}
        <PracticeSetSection
          count={setsFromCommunityCount}
          isLoading={isLoading}
          practiceSets={setsFromCommunity}
          handleViewMore={() => {
            handleTabChange(SearchResultsTab.FromCommunity);
          }}
          title={t("librarySearchResults.fromCommunity")}
          onPracticeSetPreview={onPracticeSetPreview}
        />
        <PracticeSetSection
          count={mySetsCount}
          isLoading={isLoading}
          practiceSets={mySets}
          handleViewMore={() => {
            handleTabChange(SearchResultsTab.MySets);
          }}
          title={t("librarySearchResults.mySets")}
          onPracticeSetPreview={onPracticeSetPreview}
        />
        <PracticeSetSection
          count={myDistrictSetsCount}
          isLoading={isLoading}
          practiceSets={myDistrictSets}
          handleViewMore={() => {
            handleTabChange(SearchResultsTab.MyDistrict);
          }}
          title={t("librarySearchResults.myDistrict")}
          onPracticeSetPreview={onPracticeSetPreview}
        />
      </VStack>
    </Box>
  );
};
