import { BoxProps } from "@chakra-ui/react";
import React from "react";

import BlobSVG from "adminComponents/assets/blobs/blob-1.svg";
import { pxToRem } from "adminComponents/utils/pxToRem";
import {
  CoverImageBGColorSchemeType,
  CoverImageBGPatternType,
  CoverImageIconType,
} from "links/lib/types";

import { CoverImageBackground } from "../../atoms/CoverImageBackground";
import { CoverImageIcon } from "../../atoms/CoverImageIcon";

interface ICoverImageBlobProps extends BoxProps {
  boxSize: number;
  icon: CoverImageIconType;
  backgroundColorScheme: CoverImageBGColorSchemeType;
  backgroundPattern: CoverImageBGPatternType;
}

const CoverImageBlob: React.FC<ICoverImageBlobProps> = ({
  boxSize,
  backgroundPattern,
  backgroundColorScheme,
  icon,
  ...rest
}) => {
  // icon size should always be about 50% of blob size
  const iconSize = boxSize * 0.6;

  return (
    <CoverImageBackground
      boxSize={pxToRem(boxSize)}
      display="inline-flex"
      position="relative"
      pattern={backgroundPattern}
      colorScheme={backgroundColorScheme}
      // tile size should always be about twice the icon size
      tileSize={iconSize * 2}
      sx={{
        mask: `url(${BlobSVG})`,
        maskSize: "100%",
        maskRepeat: "no-repeat",
      }}
      {...rest}
    >
      <CoverImageIcon boxSize={pxToRem(iconSize)} icon={icon} />
    </CoverImageBackground>
  );
};

export default CoverImageBlob;
