import colors from "theme/colors";

import { pxToRem } from "adminComponents/utils/pxToRem";

export interface UnknownProps {
  colorScheme:
    | "gray" // Chakra's default
    | "pepto"
    | "tangerine"
    | "amber"
    | "tomato"
    | "golden"
    | "anjou"
    | "capri"
    | "monaco"
    | "lilac"
    | "smoke";
}

export const Button = {
  variants: {
    buttonFilled: (props: UnknownProps): Record<string, unknown> => {
      let colorScheme = "golden";
      if (props.colorScheme && props.colorScheme !== "gray") {
        colorScheme = props.colorScheme;
      }
      const colorSchemeBase = colorScheme + ".base";
      // For use with box-shadow CSS property;
      let baseShadowHexValue = "";
      const colorObject = colors[colorScheme as keyof typeof colors];
      if ("base-shadow-01" in colorObject) {
        baseShadowHexValue = colorObject["base-shadow-01"];
      }
      return {
        bg: colorSchemeBase,
        color: "primary.warm-black",
        borderColor: "transparent",
        textStyle: "gameTextWeighted",
        boxShadow: "none",
        justifyContent: "center",
        alignItems: "center",
        _hover: {
          bg: `${colorScheme}.base-shadow-01`,
          _disabled: {
            bg: colorSchemeBase,
          },
        },
        _focus: {
          bg: colorSchemeBase,
          boxShadow: `0 0 0 ${pxToRem(2)} ${baseShadowHexValue}`,
        },
        // It's necessary to set this to null to allow the font size from
        // the textStyle to take effect
        fontSize: null,
      };
    },
    buttonOutlined: (props: UnknownProps): Record<string, unknown> => {
      let colorScheme = "golden";
      if (props.colorScheme && props.colorScheme !== "gray") {
        colorScheme = props.colorScheme;
      }
      const colorSchemeBase = colorScheme + ".base";
      return {
        color: "primary.warm-black",
        borderColor: colorSchemeBase,
        borderStyle: "solid",
        boxShadow: "none",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "primary.white",
        _hover: {
          borderColor: `${colorScheme}.base-shadow-01`,
          _disabled: {
            borderColor: colorSchemeBase,
          },
        },
        _focus: {
          boxShadow: "none",
          borderColor: colorSchemeBase,
        },
        textStyle: "gameTextWeighted",
        // It's necessary to set this to null to allow the font size from
        // the textStyle to take effect
        fontSize: null,
      };
    },
  },
};
