import React from "react";

import {
  IAvatar,
  ISeasonLocationAvatarItem,
  ISeasonLocationAvatarItemGroup,
} from "links/lib/types";
import { SeasonLocation } from "sharedComponents/molecules/SeasonLocation";
import { CombinedItem } from "sharedComponents/molecules/SeasonLocationProgress";

import FabledForestSVG from "./resource/fabled_forest.svg";
import JeweledJungleSVG from "./resource/jeweled_jungle.svg";
import PolarPlainsSVG from "./resource/polar_plains.svg";
import SapphireSeaSVG from "./resource/sapphire_sea.svg";
import SizzlingSandsSVG from "./resource/sizzling_sands.svg";
import SunlitSwampSVG from "./resource/sunlit_swamp.svg";

export interface ISeason3LocationProps {
  width: number;
  height: number;
  canUnlock: boolean;
  avatarItems: Array<ISeasonLocationAvatarItem>;
  avatarItemGroups: Array<ISeasonLocationAvatarItemGroup>;
  locationId: string;
  pathColor: string;
  isLoading: boolean;
  avatarAtlasUrl: string;
  avatarSkeletonUrl: string;
  avatar: IAvatar;
  handleRequestUnlock?: (item: CombinedItem) => void;
}

const locationToBGMap: { [locationId: string]: string } = {
  25: FabledForestSVG,
  26: SunlitSwampSVG,
  27: JeweledJungleSVG,
  28: SizzlingSandsSVG,
  29: PolarPlainsSVG,
  30: SapphireSeaSVG,
};

export const Season3Location: React.FC<ISeason3LocationProps> = ({
  width,
  height,
  avatarItems,
  avatarItemGroups,
  locationId,
  pathColor,
  isLoading,
  avatarAtlasUrl,
  avatarSkeletonUrl,
  handleRequestUnlock,
  canUnlock,
  avatar,
}) => {
  return (
    <SeasonLocation
      width={width}
      height={height}
      canUnlock={canUnlock}
      avatarAtlasUrl={avatarAtlasUrl}
      avatarSkeletonUrl={avatarSkeletonUrl}
      handleRequestUnlock={handleRequestUnlock}
      avatarItems={avatarItems}
      avatarItemGroups={avatarItemGroups}
      pathColor={pathColor}
      isLoading={isLoading}
      avatar={avatar}
      bgSVG={locationToBGMap[locationId]}
      avatarScaleConstant={3000}
      avatarOffsetYRatioConstant={0.26}
      roadColor="#102421"
    />
  );
};
