import { Box, Flex, FlexProps, useMultiStyleConfig } from "@chakra-ui/react";
import React from "react";

import { IconTooltip } from "adminComponents/atoms/IconTooltip";
import { Tag, TagColor } from "adminComponents/atoms/Tag";
import { IPracticeSetSubject } from "links/lib/types";

export interface IProps extends FlexProps {
  subjects: IPracticeSetSubject[];
  colorScheme?: TagColor;
}

export const SummarizedSubjectTags: React.FC<IProps> = ({
  subjects,
  colorScheme,
  ...rest
}) => {
  const styles = useMultiStyleConfig("AdminSummarizedSubjectTags", {});
  const tagColor = colorScheme ? colorScheme : "white";

  const triggerElement = (
    <Tag sx={styles.tag} colorScheme={tagColor}>{`+${
      subjects.length - 2
    }`}</Tag>
  );

  return (
    <Flex {...rest}>
      {subjects.map((tag, i) => {
        if (i <= 1) {
          const { id, name } = tag.subject;
          return (
            <Tag
              key={`tag-subject-${id}`}
              sx={styles.tag}
              colorScheme={tagColor}
            >
              {name}
            </Tag>
          );
        }
      })}

      {subjects.length > 2 && (
        <Box sx={styles.tooltip}>
          <IconTooltip placement="bottom" triggerEl={triggerElement}>
            {subjects.slice(2).map((tag) => {
              const { id, name } = tag.subject;
              return (
                <Box key={`subject-${id}`} as="p">
                  {name}
                </Box>
              );
            })}
          </IconTooltip>
        </Box>
      )}
    </Flex>
  );
};
