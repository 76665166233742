import React, { createContext } from "react";

import { ILocalStore } from "../types";

function logWarning() {
  console.warn(
    "LocalStoreProvider has not been provided with implementation of ILocalStore."
  );
}

const mockLocalStore: ILocalStore = {
  // eslint-disable-next-line
  getItem: (keyName: string) => {
    logWarning();
    return Promise.resolve("");
  },
  // eslint-disable-next-line
  setItem: (keyName: string, value: string) => {
    logWarning();
    return Promise.resolve();
  },
  // eslint-disable-next-line
  deleteItem: (keyName: string) => {
    logWarning();
    return Promise.resolve();
  },
};

export const LocalStoreContext = createContext<ILocalStore>(mockLocalStore);

export interface ILocalStoreProviderProps {
  localStore: ILocalStore;
}

export const LocalStoreProvider: React.FC<ILocalStoreProviderProps> = ({
  localStore,
  children,
}: React.PropsWithChildren<ILocalStoreProviderProps>) => {
  return (
    <LocalStoreContext.Provider value={localStore}>
      {children}
    </LocalStoreContext.Provider>
  );
};
