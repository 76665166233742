import { AxiosInstance } from "axios";
import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { IAverageUserAccuracy } from "../../types";

export interface IUseFetchAverageUserAccuraciesProps {
  classroom_id: string;
  user_id?: string;
  practice_set_id?: string;
  assignment_id?: string;
  practice_set_session_id?: string;
  enabled?: boolean;
}

export interface IUseFetchAverageUserAccuraciesResult {
  average_user_accuracies: Array<IAverageUserAccuracy>;
}

export const fetchAverageUserAccuraciesQuery = (
  axios: AxiosInstance,
  params: IUseFetchAverageUserAccuraciesProps
): (() => Promise<IUseFetchAverageUserAccuraciesResult>) => {
  const {
    classroom_id,
    practice_set_id,
    assignment_id,
    practice_set_session_id,
    user_id,
  } = params;

  return async (): Promise<IUseFetchAverageUserAccuraciesResult> => {
    return await axios
      .get("/v1/accuracies/users", {
        params: {
          group_id: classroom_id,
          user_id,
          practice_set_id,
          assignment_id,
          practice_set_session_id,
        },
      })
      .then((r) => r.data as IUseFetchAverageUserAccuraciesResult);
  };
};

export default function useFetchAverageUserAccuracies(
  params: IUseFetchAverageUserAccuraciesProps
): UseQueryResult<IUseFetchAverageUserAccuraciesResult, unknown> {
  const axios = useAxios();
  const { enabled = true } = params;

  const query = useQuery<IUseFetchAverageUserAccuraciesResult>(
    ["user-accuracies", params],
    fetchAverageUserAccuraciesQuery(axios, params),
    { enabled }
  );

  return query;
}
