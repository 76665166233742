import { Box, BoxProps, Image, useStyleConfig } from "@chakra-ui/react";
import React, { ReactElement, ReactNode } from "react";

import { ColorScheme } from "adminComponents";

import { ReactComponent as SvgAccountCircle } from "./resource/account_circle.svg";
import { ReactComponent as SvgAccountCircleOutlined } from "./resource/account_circle_outlined.svg";
import { ReactComponent as SvgAccuracy25 } from "./resource/accuracy_25.svg";
import { ReactComponent as SvgAccuracy50 } from "./resource/accuracy_50.svg";
import { ReactComponent as SvgAccuracy75 } from "./resource/accuracy_75.svg";
import { ReactComponent as SvgAccuracy100 } from "./resource/accuracy_100.svg";
import { ReactComponent as SvgAccuracyNA } from "./resource/accuracy_na.svg";
import { ReactComponent as SvgAdd } from "./resource/add.svg";
import { ReactComponent as SvgAddCircle } from "./resource/add_circle.svg";
import { ReactComponent as SvgAddCircleOutlined } from "./resource/add_circle_outlined.svg";
import { ReactComponent as SvgAddOutlined } from "./resource/add_outlined.svg";
import { ReactComponent as SvgApps } from "./resource/apps.svg";
import { ReactComponent as SvgAppsOutlined } from "./resource/apps_outlined.svg";
import { ReactComponent as SvgArrowBack } from "./resource/arrow_back.svg";
import { ReactComponent as SvgArrowBackOutlined } from "./resource/arrow_back_outlined.svg";
import { ReactComponent as SvgArrowDownward } from "./resource/arrow_downward.svg";
import { ReactComponent as SvgArrowDownwardOutlined } from "./resource/arrow_downward_outlined.svg";
import { ReactComponent as SvgArrowDropDown } from "./resource/arrow_drop_down.svg";
import { ReactComponent as SvgArrowDropDownOutlined } from "./resource/arrow_drop_down_outlined.svg";
import { ReactComponent as SvgArrowDropUp } from "./resource/arrow_drop_up.svg";
import { ReactComponent as SvgArrowDropUpOutlined } from "./resource/arrow_drop_up_outlined.svg";
import { ReactComponent as SvgArrowForward } from "./resource/arrow_forward.svg";
import { ReactComponent as SvgArrowForwardOutlined } from "./resource/arrow_forward_outlined.svg";
import { ReactComponent as SvgArrowUpward } from "./resource/arrow_upward.svg";
import { ReactComponent as SvgArrowUpwardOutlined } from "./resource/arrow_upward_outlined.svg";
import { ReactComponent as SvgBackpack } from "./resource/backpack.svg";
import { ReactComponent as SvgBookmark } from "./resource/bookmark.svg";
import { ReactComponent as SvgBookmarkOutlined } from "./resource/bookmark_outlined.svg";
import { ReactComponent as SvgButtonDownArrow } from "./resource/button_down_arrow.svg";
import { ReactComponent as SvgCalendarToday } from "./resource/calendar_today.svg";
import { ReactComponent as SvgCalendarTodayOutlined } from "./resource/calendar_today_outlined.svg";
import { ReactComponent as SvgCancel } from "./resource/cancel.svg";
import { ReactComponent as SvgCancelOutlined } from "./resource/cancel_outlined.svg";
import { ReactComponent as SvgChatBubble } from "./resource/chat_bubble.svg";
import { ReactComponent as SvgChatBubbleOutlined } from "./resource/chat_bubble_outlined.svg";
import { ReactComponent as SvgCheck } from "./resource/check.svg";
import { ReactComponent as SvgCheckOutlined } from "./resource/check_outlined.svg";
import { ReactComponent as SvgClose } from "./resource/close.svg";
import { ReactComponent as SvgCloseFullscreen } from "./resource/close_fullscreen.svg";
import { ReactComponent as SvgCloseFullscreenOutlined } from "./resource/close_fullscreen_outlined.svg";
import { ReactComponent as SvgCloseOutlined } from "./resource/close_outlined.svg";
import { ReactComponent as SvgCombScissors } from "./resource/comb_scissors.svg";
import { ReactComponent as SvgContentCopy } from "./resource/content_copy.svg";
import { ReactComponent as SvgContentCopyOutlined } from "./resource/content_copy_outlined.svg";
import { ReactComponent as SvgCrown } from "./resource/crown.svg";
import { ReactComponent as SvgDateRange } from "./resource/date_range.svg";
import { ReactComponent as SvgDateRangeOutlined } from "./resource/date_range_outlined.svg";
import { ReactComponent as SvgDelete } from "./resource/delete.svg";
import { ReactComponent as SvgDeleteOutlined } from "./resource/delete_outlined.svg";
import { ReactComponent as SvgDiagram } from "./resource/diagram.svg";
import { ReactComponent as SvgDiagramOutlined } from "./resource/diagram_outlined.svg";
import { ReactComponent as SvgDoubleThumbUpOutlined } from "./resource/double_thumb_up_outlined.svg";
import { ReactComponent as SvgDownload } from "./resource/download.svg";
import { ReactComponent as SvgDownloadOutlined } from "./resource/download_outlined.svg";
import { ReactComponent as SvgDraft } from "./resource/draft.svg";
import { ReactComponent as SvgDraftOutlined } from "./resource/draft_outlined.svg";
import { ReactComponent as SvgDrafts } from "./resource/drafts.svg";
import { ReactComponent as SvgDraftsOutlined } from "./resource/drafts_outlined.svg";
import { ReactComponent as SvgDress } from "./resource/dress.svg";
import { ReactComponent as SvgEar } from "./resource/ear.svg";
import { ReactComponent as SvgEarrings } from "./resource/earrings.svg";
import { ReactComponent as SvgEdit } from "./resource/edit.svg";
import { ReactComponent as SvgEditOutlined } from "./resource/edit_outlined.svg";
import { ReactComponent as SvgError } from "./resource/error.svg";
import { ReactComponent as SvgErrorOutlined } from "./resource/error_outlined.svg";
import { ReactComponent as SvgEvent } from "./resource/event.svg";
import { ReactComponent as SvgEventNote } from "./resource/event_note.svg";
import { ReactComponent as SvgEventNoteOutlined } from "./resource/event_note_outlined.svg";
import { ReactComponent as SvgEventOutlined } from "./resource/event_outlined.svg";
import { ReactComponent as SvgExpandCircleDown } from "./resource/expand_circle_down.svg";
import { ReactComponent as SvgExpandCircleDownOutlined } from "./resource/expand_circle_down_outlined.svg";
import { ReactComponent as SvgExpandCircleUp } from "./resource/expand_circle_up.svg";
import { ReactComponent as SvgExpandCircleUpOutlined } from "./resource/expand_circle_up_outlined.svg";
import { ReactComponent as SvgExpandFrame } from "./resource/expand_frame.svg";
import { ReactComponent as SvgExpandFrameOutlined } from "./resource/expand_frame_outlined.svg";
import { ReactComponent as SvgEye } from "./resource/eye.svg";
import { ReactComponent as SvgEyeGlasses } from "./resource/eye_glasses.svg";
import { ReactComponent as SvgEyeStrikethrough } from "./resource/eye_strikethrough.svg";
import { ReactComponent as SvgFavorite } from "./resource/favorite.svg";
import { ReactComponent as SvgFavoriteOutlined } from "./resource/favorite_outlined.svg";
import { ReactComponent as SvgFilter } from "./resource/filter.svg";
import { ReactComponent as SvgFolder } from "./resource/folder.svg";
import { ReactComponent as SvgFolderOutlined } from "./resource/folder_outlined.svg";
import { ReactComponent as SvgFormatAlignCenter } from "./resource/format_align_center.svg";
import { ReactComponent as SvgFormatAlignCenterOutlined } from "./resource/format_align_center_outlined.svg";
import { ReactComponent as SvgFormatAlignJustify } from "./resource/format_align_justify.svg";
import { ReactComponent as SvgFormatAlignJustifyOutlined } from "./resource/format_align_justify_outlined.svg";
import { ReactComponent as SvgFormatAlignLeft } from "./resource/format_align_left.svg";
import { ReactComponent as SvgFormatAlignLeftOutlined } from "./resource/format_align_left_outlined.svg";
import { ReactComponent as SvgFormatAlignRight } from "./resource/format_align_right.svg";
import { ReactComponent as SvgFormatAlignRightOutlined } from "./resource/format_align_right_outlined.svg";
import { ReactComponent as SvgFormatBold } from "./resource/format_bold.svg";
import { ReactComponent as SvgFormatBoldOutlined } from "./resource/format_bold_outlined.svg";
import { ReactComponent as SvgFormatFunctionOutlined } from "./resource/format_function_outlined.svg";
import { ReactComponent as SvgFormatItalic } from "./resource/format_italic.svg";
import { ReactComponent as SvgFormatItalicOutlined } from "./resource/format_italic_outlined.svg";
import { ReactComponent as SvgFormatSize } from "./resource/format_size.svg";
import { ReactComponent as SvgFormatSizeOutlined } from "./resource/format_size_outlined.svg";
import { ReactComponent as SvgFormatStrikethrough } from "./resource/format_strikethrough.svg";
import { ReactComponent as SvgFormatStrikethroughOutlined } from "./resource/format_strikethrough_outlined.svg";
import { ReactComponent as SvgFormatSubOutlined } from "./resource/format_sub_outlined.svg";
import { ReactComponent as SvgFormatSupOutlined } from "./resource/format_sup_outlined.svg";
import { ReactComponent as SvgFormatUnderlined } from "./resource/format_underlined.svg";
import { ReactComponent as SvgFormatUnderlinedOutlined } from "./resource/format_underlined_outlined.svg";
import { ReactComponent as SvgForward } from "./resource/forward.svg";
import { ReactComponent as SvgForwardOutlined } from "./resource/forward_outlined.svg";
import { ReactComponent as SvgFreckles } from "./resource/freckles.svg";
import { ReactComponent as SvgHand } from "./resource/hand.svg";
import { ReactComponent as SvgHat } from "./resource/hat.svg";
import { ReactComponent as SvgHead } from "./resource/head.svg";
import { ReactComponent as SvgHighFive } from "./resource/high_five.svg";
import { ReactComponent as SvgHome } from "./resource/home.svg";
import { ReactComponent as SvgHomeOutlined } from "./resource/home_outlined.svg";
import { ReactComponent as SvgImage } from "./resource/image.svg";
import { ReactComponent as SvgImageOutlined } from "./resource/image_outlined.svg";
import { ReactComponent as SvgInbox } from "./resource/inbox.svg";
import { ReactComponent as SvgInboxOutlined } from "./resource/inbox_outlined.svg";
import { ReactComponent as SvgInfo } from "./resource/info.svg";
import { ReactComponent as SvgInfoOutlined } from "./resource/info_outlined.svg";
import { ReactComponent as SvgKeyboardArrowDown } from "./resource/keyboard_arrow_down.svg";
import { ReactComponent as SvgKeyboardArrowDownOutlined } from "./resource/keyboard_arrow_down_outlined.svg";
import { ReactComponent as SvgKeyboardArrowLeft } from "./resource/keyboard_arrow_left.svg";
import { ReactComponent as SvgKeyboardArrowLeftOutlined } from "./resource/keyboard_arrow_left_outlined.svg";
import { ReactComponent as SvgKeyboardArrowRight } from "./resource/keyboard_arrow_right.svg";
import { ReactComponent as SvgKeyboardArrowRightOutlined } from "./resource/keyboard_arrow_right_outlined.svg";
import { ReactComponent as SvgKeyboardArrowUp } from "./resource/keyboard_arrow_up.svg";
import { ReactComponent as SvgKeyboardArrowUpOutlined } from "./resource/keyboard_arrow_up_outlined.svg";
import { ReactComponent as SvgKeyboardDoubleArrowDown } from "./resource/keyboard_double_arrow_down.svg";
import { ReactComponent as SvgKeyboardDoubleArrowDownOutlined } from "./resource/keyboard_double_arrow_down_outlined.svg";
import { ReactComponent as SvgKeyboardDoubleArrowLeft } from "./resource/keyboard_double_arrow_left.svg";
import { ReactComponent as SvgKeyboardDoubleArrowLeftOutlined } from "./resource/keyboard_double_arrow_left_outlined.svg";
import { ReactComponent as SvgKeyboardDoubleArrowRight } from "./resource/keyboard_double_arrow_right.svg";
import { ReactComponent as SvgKeyboardDoubleArrowRightOutlined } from "./resource/keyboard_double_arrow_right_outlined.svg";
import { ReactComponent as SvgKeyboardDoubleArrowUp } from "./resource/keyboard_double_arrow_up.svg";
import { ReactComponent as SvgKeyboardDoubleArrowUpOutlined } from "./resource/keyboard_double_arrow_up_outlined.svg";
import { ReactComponent as SvgLanguage } from "./resource/language.svg";
import { ReactComponent as SvgLanguageOutlined } from "./resource/language_outlined.svg";
import { ReactComponent as SvgLink } from "./resource/link.svg";
import { ReactComponent as SvgLinkOutlined } from "./resource/link_outlined.svg";
import { ReactComponent as SvgLips } from "./resource/lips.svg";
import { ReactComponent as SvgList } from "./resource/list.svg";
import { ReactComponent as SvgListOutlined } from "./resource/list_outlined.svg";
import { ReactComponent as SvgLock } from "./resource/lock.svg";
import { ReactComponent as SvgLockOpen } from "./resource/lock_open.svg";
import { ReactComponent as SvgLockOpenOutlined } from "./resource/lock_open_outlined.svg";
import { ReactComponent as SvgLockOutlined } from "./resource/lock_outlined.svg";
import { ReactComponent as SvgLowerFace } from "./resource/lower_face.svg";
import { ReactComponent as SvgMail } from "./resource/mail.svg";
import { ReactComponent as SvgMailOutlined } from "./resource/mail_outlined.svg";
import { ReactComponent as SvgMapMusic } from "./resource/map_music.svg";
import { ReactComponent as SvgMapPin } from "./resource/map_pin.svg";
import { ReactComponent as SvgMenu } from "./resource/menu.svg";
import { ReactComponent as SvgMenuOutlined } from "./resource/menu_outlined.svg";
import { ReactComponent as SvgMoreHoriz } from "./resource/more_horiz.svg";
import { ReactComponent as SvgMoreHorizOutlined } from "./resource/more_horiz_outlined.svg";
import { ReactComponent as SvgMoreVert } from "./resource/more_vert.svg";
import { ReactComponent as SvgMoreVertOutlined } from "./resource/more_vert_outlined.svg";
import { ReactComponent as SvgMoustache } from "./resource/moustache.svg";
import { ReactComponent as SvgNecklace } from "./resource/necklace.svg";
import { ReactComponent as SvgNose } from "./resource/nose.svg";
import { ReactComponent as SvgNotifications } from "./resource/notifications.svg";
import { ReactComponent as SvgNotificationsOutlined } from "./resource/notifications_outlined.svg";
import { ReactComponent as SvgOpenInFull } from "./resource/open_in_full.svg";
import { ReactComponent as SvgOpenInFullOutlined } from "./resource/open_in_full_outlined.svg";
import { ReactComponent as SvgOpenInNew } from "./resource/open_in_new.svg";
import { ReactComponent as SvgOpenInNewOutlined } from "./resource/open_in_new_outlined.svg";
import { ReactComponent as SvgPending } from "./resource/pending.svg";
import { ReactComponent as SvgPendingOutlined } from "./resource/pending_outlined.svg";
import { ReactComponent as SvgPerson } from "./resource/person.svg";
import { ReactComponent as SvgPersonAdd } from "./resource/person_add.svg";
import { ReactComponent as SvgPersonAddOutlined } from "./resource/person_add_outlined.svg";
import { ReactComponent as SvgPersonOutlined } from "./resource/person_outlined.svg";
import { ReactComponent as SvgPhotoCamera } from "./resource/photo_camera.svg";
import { ReactComponent as SvgPhotoCameraOutlined } from "./resource/photo_camera_outlined.svg";
import { ReactComponent as SvgPlay } from "./resource/play.svg";
import { ReactComponent as SvgPlayOutlined } from "./resource/play_outlined.svg";
import { ReactComponent as SvgPreview } from "./resource/preview.svg";
import { ReactComponent as SvgPreviewOutlined } from "./resource/preview_outlined.svg";
import { ReactComponent as SvgRearrange } from "./resource/rearrange.svg";
import { ReactComponent as SvgRearrangeOutlined } from "./resource/rearrange_outlined.svg";
import { ReactComponent as SvgRedo } from "./resource/redo.svg";
import { ReactComponent as SvgRedoOutlined } from "./resource/redo_outlined.svg";
import { ReactComponent as SvgRefresh } from "./resource/refresh.svg";
import { ReactComponent as SvgRefreshOutlined } from "./resource/refresh_outlined.svg";
import { ReactComponent as SvgRemove } from "./resource/remove.svg";
import { ReactComponent as SvgRemoveCircle } from "./resource/remove_circle.svg";
import { ReactComponent as SvgRemoveCircleOutlined } from "./resource/remove_circle_outlined.svg";
import { ReactComponent as SvgRemoveOutlined } from "./resource/remove_outlined.svg";
import { ReactComponent as SvgReorder } from "./resource/reorder.svg";
import { ReactComponent as SvgReply } from "./resource/reply.svg";
import { ReactComponent as SvgReplyOutlined } from "./resource/reply_outlined.svg";
import { ReactComponent as SvgSchedule } from "./resource/schedule.svg";
import { ReactComponent as SvgScheduleOutlined } from "./resource/schedule_outlined.svg";
import { ReactComponent as SvgSearch } from "./resource/search.svg";
import { ReactComponent as SvgSearchOutlined } from "./resource/search_outlined.svg";
import { ReactComponent as SvgSearchPreview } from "./resource/search_preview.svg";
import { ReactComponent as SvgSend } from "./resource/send.svg";
import { ReactComponent as SvgSendOutlined } from "./resource/send_outlined.svg";
import { ReactComponent as SvgSetting } from "./resource/setting.svg";
import { ReactComponent as SvgSettingOutlined } from "./resource/setting_outlined.svg";
import { ReactComponent as SvgShare } from "./resource/share.svg";
import { ReactComponent as SvgShareOutlined } from "./resource/share_outlined.svg";
import { ReactComponent as SvgShirt } from "./resource/shirt.svg";
import { ReactComponent as SvgShorts } from "./resource/shorts.svg";
import { ReactComponent as SvgShuffleArrows } from "./resource/shuffle_arrows.svg";
import { ReactComponent as SvgSlider } from "./resource/slider.svg";
import { ReactComponent as SvgSms } from "./resource/sms.svg";
import { ReactComponent as SvgSmsOutlined } from "./resource/sms_outlined.svg";
import { ReactComponent as SvgSneaker } from "./resource/sneaker.svg";
import { ReactComponent as SvgSock } from "./resource/sock.svg";
import { ReactComponent as SvgSparkle } from "./resource/sparkle.svg";
import { ReactComponent as SvgStar } from "./resource/star.svg";
import { ReactComponent as SvgStarOutlined } from "./resource/star_outlined.svg";
import { ReactComponent as SvgSync } from "./resource/sync.svg";
import { ReactComponent as SvgSyncOutlined } from "./resource/sync_outlined.svg";
import { ReactComponent as SvgThumbDown } from "./resource/thumb_down.svg";
import { ReactComponent as SvgThumbDownOutlined } from "./resource/thumb_down_outlined.svg";
import { ReactComponent as SvgThumbUp } from "./resource/thumb_up.svg";
import { ReactComponent as SvgThumbUpOutlined } from "./resource/thumb_up_outlined.svg";
import { ReactComponent as SvgTimer } from "./resource/timer.svg";
import { ReactComponent as SvgTimerOutlined } from "./resource/timer_outlined.svg";
import { ReactComponent as SvgTitle } from "./resource/title.svg";
import { ReactComponent as SvgTitleOutlined } from "./resource/title_outlined.svg";
import { ReactComponent as SvgToolbarRedo } from "./resource/toolbar_redo.svg";
import { ReactComponent as SvgToolbarUndo } from "./resource/toolbar_undo.svg";
import { ReactComponent as SvgUndo } from "./resource/undo.svg";
import { ReactComponent as SvgUndoOutlined } from "./resource/undo_outlined.svg";
import { ReactComponent as SvgUpload } from "./resource/upload.svg";
import { ReactComponent as SvgUploadOutlined } from "./resource/upload_outlined.svg";
import { ReactComponent as SvgVideocam } from "./resource/videocam.svg";
import { ReactComponent as SvgVideocamOutlined } from "./resource/videocam_outlined.svg";
import { ReactComponent as SvgWheelchairAccessories } from "./resource/wheelchair_accessories.svg";
import { ReactComponent as SvgWheelchairArmrests } from "./resource/wheelchair_armrests.svg";
import { ReactComponent as SvgWheelchairChairs } from "./resource/wheelchair_chairs.svg";
import { ReactComponent as SvgWheelchairFrameStickers } from "./resource/wheelchair_frame_stickers.svg";
import { ReactComponent as SvgWheelchairHeadAttachments } from "./resource/wheelchair_head_attachments.svg";
import { ReactComponent as SvgWheelchairJoySticks } from "./resource/wheelchair_joysticks.svg";
import { ReactComponent as SvgWheelchairSpokeGuards } from "./resource/wheelchair_spoke_guards.svg";
import { ReactComponent as SvgWheelchairWheels } from "./resource/wheelchair_wheels.svg";
import { ReactComponent as SvgWristWatch } from "./resource/wrist_watch.svg";
import { ReactComponent as SvgXPBoost } from "./resource/xp_boost.svg";
import { ReactComponent as SvgXPBoostSolo } from "./resource/xp_boost_solo.svg";
import { ReactComponent as SvgZap } from "./resource/zap.svg";
import { ReactComponent as SvgZoomIn } from "./resource/zoom_in.svg";
import { ReactComponent as SvgZoomInOutlined } from "./resource/zoom_in_outlined.svg";
import { ReactComponent as SvgZoomOut } from "./resource/zoom_out.svg";
import { ReactComponent as SvgZoomOutOutlined } from "./resource/zoom_out_outlined.svg";

export const IconOptions = [
  "account_circle_outlined",
  "account_circle",
  "accuracy_100",
  "accuracy_25",
  "accuracy_50",
  "accuracy_75",
  "accuracy_na",
  "add_circle_outlined",
  "add_circle",
  "add_outlined",
  "add",
  "apps_outlined",
  "apps",
  "arrow_back_outlined",
  "arrow_back",
  "arrow_downward_outlined",
  "arrow_downward",
  "arrow_drop_down_outlined",
  "arrow_drop_down",
  "arrow_drop_up_outlined",
  "arrow_drop_up",
  "arrow_forward_outlined",
  "arrow_forward",
  "arrow_upward_outlined",
  "arrow_upward",
  "backpack",
  "bookmark_outlined",
  "bookmark",
  "button_down_arrow",
  "calendar_today_outlined",
  "calendar_today",
  "cancel_outlined",
  "cancel",
  "chat_bubble_outlined",
  "chat_bubble",
  "check_outlined",
  "check",
  "close_fullscreen_outlined",
  "close_fullscreen",
  "close_outlined",
  "close",
  "comb_scissors",
  "content_copy_outlined",
  "content_copy",
  "crown",
  "date_range_outlined",
  "date_range",
  "delete_outlined",
  "delete",
  "diagram_outlined",
  "diagram",
  "double_thumb_up_outlined",
  "download_outlined",
  "download",
  "draft_outlined",
  "draft",
  "drafts_outlined",
  "drafts",
  "dress",
  "ear",
  "earrings",
  "edit_outlined",
  "edit",
  "error_outlined",
  "error",
  "event_note_outlined",
  "event_note",
  "event_outlined",
  "event",
  "expand_circle_down_outlined",
  "expand_circle_down",
  "expand_circle_up_outlined",
  "expand_circle_up",
  "expand_frame_outlined",
  "expand_frame",
  "eye_glasses",
  "eye_strikethrough",
  "eye",
  "favorite_outlined",
  "favorite",
  "filter",
  "folder_outlined",
  "folder",
  "format_align_center_outlined",
  "format_align_center",
  "format_align_justify_outlined",
  "format_align_justify",
  "format_align_left_outlined",
  "format_align_left",
  "format_align_right_outlined",
  "format_align_right",
  "format_bold_outlined",
  "format_bold",
  "format_function_outlined",
  "format_italic_outlined",
  "format_italic",
  "format_size_outlined",
  "format_size",
  "format_strikethrough_outlined",
  "format_strikethrough",
  "format_sub_outlined",
  "format_sup_outlined",
  "format_underlined_outlined",
  "format_underlined",
  "forward_outlined",
  "forward",
  "freckles",
  "hand",
  "hat",
  "head",
  "high_five",
  "home_outlined",
  "home",
  "image_outlined",
  "image",
  "inbox_outlined",
  "inbox",
  "info_outlined",
  "info",
  "keyboard_arrow_down_outlined",
  "keyboard_arrow_down",
  "keyboard_arrow_left_outlined",
  "keyboard_arrow_left",
  "keyboard_arrow_right_outlined",
  "keyboard_arrow_right",
  "keyboard_arrow_up_outlined",
  "keyboard_arrow_up",
  "keyboard_double_arrow_down_outlined",
  "keyboard_double_arrow_down",
  "keyboard_double_arrow_left_outlined",
  "keyboard_double_arrow_left",
  "keyboard_double_arrow_right_outlined",
  "keyboard_double_arrow_right",
  "keyboard_double_arrow_up_outlined",
  "keyboard_double_arrow_up",
  "language_outlined",
  "language",
  "link_outlined",
  "link",
  "lips",
  "list_outlined",
  "list",
  "lock_open_outlined",
  "lock_open",
  "lock_outlined",
  "lock",
  "lower_face",
  "mail_outlined",
  "mail",
  "map_music",
  "map_pin",
  "menu_outlined",
  "menu",
  "more_horiz_outlined",
  "more_horiz",
  "more_vert_outlined",
  "more_vert",
  "moustache",
  "necklace",
  "nose",
  "notifications_outlined",
  "notifications",
  "open_in_full_outlined",
  "open_in_full",
  "open_in_new_outlined",
  "open_in_new",
  "pending_outlined",
  "pending",
  "person_add_outlined",
  "person_add",
  "person_outlined",
  "person",
  "photo_camera_outlined",
  "photo_camera",
  "play_outlined",
  "play",
  "preview_outlined",
  "preview",
  "rearrange_outlined",
  "rearrange",
  "redo_outlined",
  "redo",
  "refresh_outlined",
  "refresh",
  "remove_circle_outlined",
  "remove_circle",
  "remove_outlined",
  "remove",
  "reorder",
  "reply_outlined",
  "reply",
  "schedule_outlined",
  "schedule",
  "search_outlined",
  "search_preview",
  "search",
  "send_outlined",
  "send",
  "setting_outlined",
  "setting",
  "share_outlined",
  "share",
  "shirt",
  "shorts",
  "shuffle_arrows",
  "slider",
  "sms_outlined",
  "sms",
  "sneaker",
  "sock",
  "sparkle",
  "star_outlined",
  "star",
  "sync_outlined",
  "sync",
  "thumb_down_outlined",
  "thumb_down",
  "thumb_up_outlined",
  "thumb_up",
  "timer_outlined",
  "timer",
  "title_outlined",
  "title",
  "toolbar_redo",
  "toolbar_undo",
  "undo_outlined",
  "undo",
  "upload_outlined",
  "upload",
  "videocam_outlined",
  "videocam",
  "wheelchair_accessories",
  "wheelchair_armrests",
  "wheelchair_chairs",
  "wheelchair_frame_stickers",
  "wheelchair_head_attachments",
  "wheelchair_joysticks",
  "wheelchair_spoke_guards",
  "wheelchair_wheels",
  "wrist_watch",
  "zap",
  "zoom_in_outlined",
  "zoom_in",
  "zoom_out_outlined",
  "zoom_out",
  "xp_boost",
  "xp_boost_solo",
] as const;

export type IconType = typeof IconOptions[number];

const IconResources: Record<IconType, ReactNode> = {
  account_circle_outlined: <SvgAccountCircleOutlined />,
  account_circle: <SvgAccountCircle />,
  accuracy_100: <SvgAccuracy100 />,
  accuracy_25: <SvgAccuracy25 />,
  accuracy_50: <SvgAccuracy50 />,
  accuracy_75: <SvgAccuracy75 />,
  accuracy_na: <SvgAccuracyNA />,
  add_circle_outlined: <SvgAddCircleOutlined />,
  add_circle: <SvgAddCircle />,
  add_outlined: <SvgAddOutlined />,
  add: <SvgAdd />,
  apps_outlined: <SvgAppsOutlined />,
  apps: <SvgApps />,
  arrow_back_outlined: <SvgArrowBackOutlined />,
  arrow_back: <SvgArrowBack />,
  arrow_downward_outlined: <SvgArrowDownwardOutlined />,
  arrow_downward: <SvgArrowDownward />,
  arrow_drop_down_outlined: <SvgArrowDropDownOutlined />,
  arrow_drop_down: <SvgArrowDropDown />,
  arrow_drop_up_outlined: <SvgArrowDropUpOutlined />,
  arrow_drop_up: <SvgArrowDropUp />,
  arrow_forward_outlined: <SvgArrowForwardOutlined />,
  arrow_forward: <SvgArrowForward />,
  arrow_upward_outlined: <SvgArrowUpwardOutlined />,
  arrow_upward: <SvgArrowUpward />,
  backpack: <SvgBackpack />,
  bookmark_outlined: <SvgBookmarkOutlined />,
  bookmark: <SvgBookmark />,
  button_down_arrow: <SvgButtonDownArrow />,
  calendar_today_outlined: <SvgCalendarTodayOutlined />,
  calendar_today: <SvgCalendarToday />,
  cancel_outlined: <SvgCancelOutlined />,
  cancel: <SvgCancel />,
  chat_bubble_outlined: <SvgChatBubbleOutlined />,
  chat_bubble: <SvgChatBubble />,
  check_outlined: <SvgCheckOutlined />,
  check: <SvgCheck />,
  close_fullscreen_outlined: <SvgCloseFullscreenOutlined />,
  close_fullscreen: <SvgCloseFullscreen />,
  close_outlined: <SvgCloseOutlined />,
  close: <SvgClose />,
  comb_scissors: <SvgCombScissors />,
  content_copy_outlined: <SvgContentCopyOutlined />,
  content_copy: <SvgContentCopy />,
  crown: <SvgCrown />,
  date_range_outlined: <SvgDateRangeOutlined />,
  date_range: <SvgDateRange />,
  delete_outlined: <SvgDeleteOutlined />,
  delete: <SvgDelete />,
  diagram_outlined: <SvgDiagramOutlined />,
  diagram: <SvgDiagram />,
  double_thumb_up_outlined: <SvgDoubleThumbUpOutlined />,
  download_outlined: <SvgDownloadOutlined />,
  download: <SvgDownload />,
  draft_outlined: <SvgDraftOutlined />,
  draft: <SvgDraft />,
  drafts_outlined: <SvgDraftsOutlined />,
  drafts: <SvgDrafts />,
  dress: <SvgDress />,
  ear: <SvgEar />,
  earrings: <SvgEarrings />,
  edit_outlined: <SvgEditOutlined />,
  edit: <SvgEdit />,
  error_outlined: <SvgErrorOutlined />,
  error: <SvgError />,
  event_note_outlined: <SvgEventNoteOutlined />,
  event_note: <SvgEventNote />,
  event_outlined: <SvgEventOutlined />,
  event: <SvgEvent />,
  expand_circle_down_outlined: <SvgExpandCircleDownOutlined />,
  expand_circle_down: <SvgExpandCircleDown />,
  expand_circle_up_outlined: <SvgExpandCircleUpOutlined />,
  expand_circle_up: <SvgExpandCircleUp />,
  expand_frame_outlined: <SvgExpandFrameOutlined />,
  expand_frame: <SvgExpandFrame />,
  eye_glasses: <SvgEyeGlasses />,
  eye_strikethrough: <SvgEyeStrikethrough />,
  eye: <SvgEye />,
  favorite_outlined: <SvgFavoriteOutlined />,
  favorite: <SvgFavorite />,
  filter: <SvgFilter />,
  folder_outlined: <SvgFolderOutlined />,
  folder: <SvgFolder />,
  format_align_center_outlined: <SvgFormatAlignCenterOutlined />,
  format_align_center: <SvgFormatAlignCenter />,
  format_align_justify_outlined: <SvgFormatAlignJustifyOutlined />,
  format_align_justify: <SvgFormatAlignJustify />,
  format_align_left_outlined: <SvgFormatAlignLeftOutlined />,
  format_align_left: <SvgFormatAlignLeft />,
  format_align_right_outlined: <SvgFormatAlignRightOutlined />,
  format_align_right: <SvgFormatAlignRight />,
  format_bold_outlined: <SvgFormatBoldOutlined />,
  format_bold: <SvgFormatBold />,
  format_function_outlined: <SvgFormatFunctionOutlined />,
  format_italic_outlined: <SvgFormatItalicOutlined />,
  format_italic: <SvgFormatItalic />,
  format_size_outlined: <SvgFormatSizeOutlined />,
  format_size: <SvgFormatSize />,
  format_strikethrough_outlined: <SvgFormatStrikethroughOutlined />,
  format_strikethrough: <SvgFormatStrikethrough />,
  format_sub_outlined: <SvgFormatSubOutlined />,
  format_sup_outlined: <SvgFormatSupOutlined />,
  format_underlined_outlined: <SvgFormatUnderlinedOutlined />,
  format_underlined: <SvgFormatUnderlined />,
  forward_outlined: <SvgForwardOutlined />,
  forward: <SvgForward />,
  freckles: <SvgFreckles />,
  hand: <SvgHand />,
  hat: <SvgHat />,
  head: <SvgHead />,
  high_five: <SvgHighFive />,
  home_outlined: <SvgHomeOutlined />,
  home: <SvgHome />,
  image_outlined: <SvgImageOutlined />,
  image: <SvgImage />,
  inbox_outlined: <SvgInboxOutlined />,
  inbox: <SvgInbox />,
  info_outlined: <SvgInfoOutlined />,
  info: <SvgInfo />,
  keyboard_arrow_down_outlined: <SvgKeyboardArrowDownOutlined />,
  keyboard_arrow_down: <SvgKeyboardArrowDown />,
  keyboard_arrow_left_outlined: <SvgKeyboardArrowLeftOutlined />,
  keyboard_arrow_left: <SvgKeyboardArrowLeft />,
  keyboard_arrow_right_outlined: <SvgKeyboardArrowRightOutlined />,
  keyboard_arrow_right: <SvgKeyboardArrowRight />,
  keyboard_arrow_up_outlined: <SvgKeyboardArrowUpOutlined />,
  keyboard_arrow_up: <SvgKeyboardArrowUp />,
  keyboard_double_arrow_down_outlined: <SvgKeyboardDoubleArrowDownOutlined />,
  keyboard_double_arrow_down: <SvgKeyboardDoubleArrowDown />,
  keyboard_double_arrow_left_outlined: <SvgKeyboardDoubleArrowLeftOutlined />,
  keyboard_double_arrow_left: <SvgKeyboardDoubleArrowLeft />,
  keyboard_double_arrow_right_outlined: <SvgKeyboardDoubleArrowRightOutlined />,
  keyboard_double_arrow_right: <SvgKeyboardDoubleArrowRight />,
  keyboard_double_arrow_up_outlined: <SvgKeyboardDoubleArrowUpOutlined />,
  keyboard_double_arrow_up: <SvgKeyboardDoubleArrowUp />,
  language_outlined: <SvgLanguageOutlined />,
  language: <SvgLanguage />,
  link_outlined: <SvgLinkOutlined />,
  link: <SvgLink />,
  lips: <SvgLips />,
  list_outlined: <SvgListOutlined />,
  list: <SvgList />,
  lock_open_outlined: <SvgLockOpenOutlined />,
  lock_open: <SvgLockOpen />,
  lock_outlined: <SvgLockOutlined />,
  lock: <SvgLock />,
  lower_face: <SvgLowerFace />,
  mail_outlined: <SvgMailOutlined />,
  mail: <SvgMail />,
  map_music: <SvgMapMusic />,
  map_pin: <SvgMapPin />,
  menu_outlined: <SvgMenuOutlined />,
  menu: <SvgMenu />,
  more_horiz_outlined: <SvgMoreHorizOutlined />,
  more_horiz: <SvgMoreHoriz />,
  more_vert_outlined: <SvgMoreVertOutlined />,
  more_vert: <SvgMoreVert />,
  moustache: <SvgMoustache />,
  necklace: <SvgNecklace />,
  nose: <SvgNose />,
  notifications_outlined: <SvgNotificationsOutlined />,
  notifications: <SvgNotifications />,
  open_in_full_outlined: <SvgOpenInFullOutlined />,
  open_in_full: <SvgOpenInFull />,
  open_in_new_outlined: <SvgOpenInNewOutlined />,
  open_in_new: <SvgOpenInNew />,
  pending_outlined: <SvgPendingOutlined />,
  pending: <SvgPending />,
  person_add_outlined: <SvgPersonAddOutlined />,
  person_add: <SvgPersonAdd />,
  person_outlined: <SvgPersonOutlined />,
  person: <SvgPerson />,
  photo_camera_outlined: <SvgPhotoCameraOutlined />,
  photo_camera: <SvgPhotoCamera />,
  play_outlined: <SvgPlayOutlined />,
  play: <SvgPlay />,
  preview_outlined: <SvgPreviewOutlined />,
  preview: <SvgPreview />,
  rearrange_outlined: <SvgRearrangeOutlined />,
  rearrange: <SvgRearrange />,
  redo_outlined: <SvgRedoOutlined />,
  redo: <SvgRedo />,
  refresh_outlined: <SvgRefreshOutlined />,
  refresh: <SvgRefresh />,
  remove_circle_outlined: <SvgRemoveCircleOutlined />,
  remove_circle: <SvgRemoveCircle />,
  remove_outlined: <SvgRemoveOutlined />,
  remove: <SvgRemove />,
  reorder: <SvgReorder />,
  reply_outlined: <SvgReplyOutlined />,
  reply: <SvgReply />,
  schedule_outlined: <SvgScheduleOutlined />,
  schedule: <SvgSchedule />,
  search_outlined: <SvgSearchOutlined />,
  search_preview: <SvgSearchPreview />,
  search: <SvgSearch />,
  send_outlined: <SvgSendOutlined />,
  send: <SvgSend />,
  setting_outlined: <SvgSettingOutlined />,
  setting: <SvgSetting />,
  share_outlined: <SvgShareOutlined />,
  share: <SvgShare />,
  shirt: <SvgShirt />,
  shorts: <SvgShorts />,
  shuffle_arrows: <SvgShuffleArrows />,
  slider: <SvgSlider />,
  sms_outlined: <SvgSmsOutlined />,
  sms: <SvgSms />,
  sneaker: <SvgSneaker />,
  sock: <SvgSock />,
  sparkle: <SvgSparkle />,
  star_outlined: <SvgStarOutlined />,
  star: <SvgStar />,
  sync_outlined: <SvgSyncOutlined />,
  sync: <SvgSync />,
  thumb_down_outlined: <SvgThumbDownOutlined />,
  thumb_down: <SvgThumbDown />,
  thumb_up_outlined: <SvgThumbUpOutlined />,
  thumb_up: <SvgThumbUp />,
  timer_outlined: <SvgTimerOutlined />,
  timer: <SvgTimer />,
  title_outlined: <SvgTitleOutlined />,
  title: <SvgTitle />,
  toolbar_redo: <SvgToolbarRedo />,
  toolbar_undo: <SvgToolbarUndo />,
  undo_outlined: <SvgUndoOutlined />,
  undo: <SvgUndo />,
  upload_outlined: <SvgUploadOutlined />,
  upload: <SvgUpload />,
  videocam_outlined: <SvgVideocamOutlined />,
  videocam: <SvgVideocam />,
  wheelchair_accessories: <SvgWheelchairAccessories />,
  wheelchair_armrests: <SvgWheelchairArmrests />,
  wheelchair_chairs: <SvgWheelchairChairs />,
  wheelchair_frame_stickers: <SvgWheelchairFrameStickers />,
  wheelchair_head_attachments: <SvgWheelchairHeadAttachments />,
  wheelchair_joysticks: <SvgWheelchairJoySticks />,
  wheelchair_spoke_guards: <SvgWheelchairSpokeGuards />,
  wheelchair_wheels: <SvgWheelchairWheels />,
  wrist_watch: <SvgWristWatch />,
  zap: <SvgZap />,
  zoom_in_outlined: <SvgZoomInOutlined />,
  zoom_in: <SvgZoomIn />,
  zoom_out_outlined: <SvgZoomOutOutlined />,
  zoom_out: <SvgZoomOut />,
  xp_boost: <SvgXPBoost />,
  xp_boost_solo: <SvgXPBoostSolo />,
};

export interface IProps extends BoxProps {
  variant?: string;
  icon: IconType;
  iconColor?: ColorScheme | "inherit";
  iconSrc?: string;
  backgroundColor?: ColorScheme;
  styles?: Record<string, unknown>;
  iconStyles?: Record<string, unknown>;
  decorative?: boolean;
}

export const Icon: React.FC<IProps> = ({
  variant = "default",
  icon,
  iconColor = "primary.warm-black",
  iconSrc,
  backgroundColor,
  styles = {},
  iconStyles = {},
  decorative = false,
  ...rest
}) => {
  const baseStyles = useStyleConfig("AdminIcon", {
    variant,
    iconColor,
    backgroundColor,
    iconSrc,
  });

  // These props are injected by chakra checkbox
  // when an icon is included. Ensure we do not pass on to Box
  const cleanProps = { ...rest } as Record<string, unknown>;
  delete cleanProps["isIndeterminate"];
  delete cleanProps["isChecked"];

  const getIcon = (icon: IconType, decorative: boolean): React.ReactNode => {
    if (React.isValidElement(IconResources[icon])) {
      const svg = IconResources[icon] as ReactElement;
      return React.cloneElement(
        svg,
        decorative ? { "aria-hidden": true, focusable: false } : undefined
      );
    }
  };

  return (
    <Box {...cleanProps} __css={{ ...baseStyles, ...styles }}>
      {iconSrc ? (
        <Image
          __css={{ ...iconStyles }}
          src={iconSrc}
          alt={decorative ? "" : undefined}
        />
      ) : (
        getIcon(icon, decorative)
      )}
    </Box>
  );
};
