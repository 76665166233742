import { Howler } from "howler";
import { throttle } from "lodash";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLifecycles, useLocalStorage, useMount } from "react-use";

import { getIsAppleMobileDevice } from "sessionComponents/utils/getIsAppleMobileDevice";
import { BackgroundAudio } from "sharedComponents/atoms/BackgroundAudio";
import useHowl from "sharedComponents/hooks/useHowl";

export interface IAudioContext {
  setBackgroundAudio: (spriteName?: string) => void;
  setVolume: (volumeScale: number) => void;
  volume: number;
  play: (spriteName: string) => number;
  pause: (playId: number) => void;
  stop: (playId: number) => void;
  setSpriteVolume: (playId: number, volume: number) => void;
}

export interface IAudioProviderProps {
  spriteSrc: Array<string>;
  srcVolume: number;
  volumeStorageKey: string;
  sprite: {
    [key: string]: (number | boolean)[];
  };
  backgroundClips?: {
    [key: string]: Array<{ src: string; type: string }>;
  };
}

const NOT_INIT_WARNING_MSG = "AudioProvider has not been initialized.";

export const AudioContext = createContext<IAudioContext>({
  setBackgroundAudio: () => {
    console.warn(NOT_INIT_WARNING_MSG);
  },
  setVolume: () => {
    console.warn(NOT_INIT_WARNING_MSG);
  },
  play: () => {
    console.warn(NOT_INIT_WARNING_MSG);
    return 0;
  },
  pause: () => {
    console.warn(NOT_INIT_WARNING_MSG);
  },
  stop: () => {
    console.warn(NOT_INIT_WARNING_MSG);
  },
  setSpriteVolume: () => {
    console.warn(NOT_INIT_WARNING_MSG);
  },
  volume: 1,
});

export const AudioProvider: React.FC<IAudioProviderProps> = ({
  children,
  spriteSrc,
  sprite,
  srcVolume,
  backgroundClips,
  volumeStorageKey,
}) => {
  const [storedVolume, setStoredVolume] = useLocalStorage(
    volumeStorageKey,
    srcVolume
  );
  const [volume, setVolumeState] = useState<number>(
    storedVolume || storedVolume === 0 ? storedVolume : srcVolume
  );

  const [currentBackgroundClip, setCurrentBackgroundClip] = useState<
    string | undefined
  >(undefined);

  const spriteHowl = useHowl({
    src: spriteSrc,
    sprite,
    loop: false,
    volume, // Respect user volume setting
    debug: false,
  });

  const backgroundClipKeys = useMemo(() => {
    return Object.keys(backgroundClips || {});
  }, [backgroundClips]);

  const updateStoredVolume = useRef(
    throttle(
      (v: number) => {
        setStoredVolume(v);
      },
      500,
      { leading: true, trailing: true }
    )
  );

  const setVolume = useRef((v: number) => {
    setVolumeState(v);
    updateStoredVolume.current(v);
  });

  const play = useRef((spriteName: string) => {
    return spriteHowl.play(spriteName);
  });

  const pause = useRef((playId: number) => {
    return spriteHowl.pause(playId);
  });

  const stop = useRef((playId: number) => {
    spriteHowl.stop(playId);
  });

  const setSpriteVolume = useRef((playId: number, volume: number) => {
    spriteHowl.volume(playId, volume);
  });

  const setBackgroundAudio = useRef((clipName?: string) => {
    setCurrentBackgroundClip(clipName);
  });

  const value = useMemo(
    () => ({
      setVolume: setVolume.current,
      setBackgroundAudio: setBackgroundAudio.current,
      play: play.current,
      pause: pause.current,
      stop: stop.current,
      setSpriteVolume: setSpriteVolume.current,
      volume,
    }),
    [volume]
  );

  //Start with correct volume
  useMount(() => {
    Howler.volume(volume);
  });

  // set global volume on volume change
  useEffect(() => {
    Howler.volume(volume);
  }, [volume]);

  useLifecycles(
    () => {
      Howler.autoSuspend = false;
    },
    () => {
      // unload cache on unmount
      Howler.unload();
      Howler.autoSuspend = true;
    }
  );

  // iOS requires a user to touch their device before audio
  // can be played. If we create an audio element with auto-play
  // before the user has touched their device, nothing will play
  // even after the user has touched the device. This logic
  // ensures that a user has touched their device before creating
  // audio elements when necessary.
  const [hadRequiredTouch, setHadRequiredTouch] = useState(
    !getIsAppleMobileDevice()
  );
  useMount(() => {
    document.addEventListener("touchstart", () => {
      setHadRequiredTouch(true);
    });
  });
  const backgroundAudioPlayers = useMemo(() => {
    if (!hadRequiredTouch) return [];
    if (!currentBackgroundClip) return [];
    if (!backgroundClips) return [];

    return backgroundClipKeys.map((clipKey) => (
      <BackgroundAudio
        key={clipKey}
        audioClips={backgroundClips[clipKey]}
        isPlaying={currentBackgroundClip === clipKey}
        volume={volume}
      />
    ));
  }, [
    backgroundClips,
    backgroundClipKeys,
    currentBackgroundClip,
    volume,
    hadRequiredTouch,
  ]);

  return (
    <AudioContext.Provider value={value}>
      {children}
      {backgroundAudioPlayers}
    </AudioContext.Provider>
  );
};

export const useAudio = (): IAudioContext => {
  return useContext(AudioContext);
};
