import { Box, VStack } from "@chakra-ui/react";
import EventEmitter from "events";
import React, { useCallback, useEffect } from "react";
import { animated, config, useTransition } from "react-spring";
import { useMount } from "react-use";

import { usePageTrack } from "lib/contexts/analytics";
import {
  localStoreAuthKeyName,
  localStorePostSessionReturnPath,
} from "links/lib/constants";
import { useSession } from "links/lib/contexts/session";
import { useSessionActions } from "links/lib/contexts/sessionActions";
import { useSessionEvents } from "links/lib/contexts/sessionEvents";
import { useSessionGameState } from "links/lib/contexts/sessionGameState";
import { useSessionRoundState } from "links/lib/contexts/sessionRoundState";
import { SessionRoundThemeProvider } from "links/lib/contexts/sessionRoundTheme";
import { useSessionScene } from "links/lib/contexts/sessionScene";
import { useGenerateSessionRoundTheme } from "links/lib/features/sessionRoundTheme";
import {
  AnalyticsPage,
  IUser,
  SessionScene,
  SessionWebSocketEvent,
} from "links/lib/types";
import { RoundReviewProvider } from "sessionComponents/contexts/roundReview";
import {
  StudentProvider,
  useStudent,
} from "sessionComponents/contexts/student";
import { StudentReactionsProvider } from "sessionComponents/contexts/studentReactions";
import { useHeaderTitleText } from "sessionComponents/hooks/useHeaderTitleText";
import { TeacherPreviewNavButtons } from "sessionComponents/molecules/TeacherPreviewNavButtons";
import { StudentHeader } from "sessionComponents/organisms/StudentHeader";
import { fullScreenStyles } from "sessionComponents/theme";
import { CarePackageProvider } from "sharedComponents/contexts/carePackage";
import { SessionAnalyticsProvider } from "sharedComponents/contexts/sessionAnalytics";
import {
  SessionAudioProvider,
  useSessionAudio,
} from "sharedComponents/contexts/sessionAudio";
import "sharedComponents/css/ios-full-height.css";
import StudentSessionAudioSpriteDetail from "sharedComponents/resource/audio/student/output.json";
import StudentSessionAudioSpriteSrcMP3 from "sharedComponents/resource/audio/student/output.mp3";
import StudentSessionAudioSpriteSrcWebM from "sharedComponents/resource/audio/student/output.webm";

import { StudentQuestionRound } from "../shared/StudentQuestionRound";

interface ITeacherPreviewSessionViewProps {
  authUser: IUser;
}

const AnimatedBox = animated(Box);

export const TeacherPreviewSessionView: React.FC<
  ITeacherPreviewSessionViewProps
> = ({ authUser }: ITeacherPreviewSessionViewProps) => {
  const scene = useSessionScene();
  const roundState = useSessionRoundState();
  const session = useSession();
  const events = useSessionEvents();
  const { initiateNextRound } = useSessionActions();

  const currentRoundNumber = roundState.round_number;

  const student = useStudent();

  usePageTrack(AnalyticsPage.Session_TeacherPreview_Teacher);
  const roundTheme = useGenerateSessionRoundTheme(currentRoundNumber);

  useMount(() => {
    if (currentRoundNumber > 0) return;
    initiateNextRound();
  });

  if (!student || !events) return null;

  return (
    <StudentProvider userId={authUser.id}>
      <SessionAnalyticsProvider
        student={student}
        session={session}
        round_state={roundState}
        scene={scene}
      >
        <SessionAudioProvider
          srcVolume={1}
          spriteSrc={[
            StudentSessionAudioSpriteSrcWebM,
            StudentSessionAudioSpriteSrcMP3,
          ]}
          sprite={StudentSessionAudioSpriteDetail.sprite}
        >
          <SessionRoundThemeProvider roundTheme={roundTheme}>
            <CarePackageProvider events={events}>
              <StudentReactionsProvider>
                <RoundReviewProvider>
                  <TeacherPreviewSession
                    authUser={authUser}
                    scene={scene}
                    practiceSetId={session?.practice_set_id || "0"}
                    events={events}
                  />
                </RoundReviewProvider>
              </StudentReactionsProvider>
            </CarePackageProvider>
          </SessionRoundThemeProvider>
        </SessionAudioProvider>
      </SessionAnalyticsProvider>
    </StudentProvider>
  );
};

interface ITeacherPreviewSessionProps {
  authUser: IUser;
  scene: SessionScene;
  practiceSetId: string;
  events: EventEmitter;
}

const TeacherPreviewSession: React.FC<ITeacherPreviewSessionProps> = ({
  authUser,
  scene,
  practiceSetId,
  events,
}) => {
  const { setVolume, volume } = useSessionAudio();
  const roundState = useSessionRoundState();
  const { initiateNextRound, initiatePriorRound, exitGame } =
    useSessionActions();
  const gameState = useSessionGameState();

  const headerTitleText = useHeaderTitleText({
    isTeacher: false,
    userId: authUser.id,
  });

  const currentRoundNumber = roundState.round_number;
  const finalRoundNumber = gameState?.final_round_number || 0;

  const roundTransition = useTransition([currentRoundNumber], {
    immediate: true,
  });

  const sceneTransition = useTransition([scene], {
    from: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
    },
    leave: {
      opacity: 1,
    },
    exitBeforeEnter: false,
    config: config.slow,
  });

  const onToggleSound = (enabled: boolean) => {
    if (enabled) {
      setVolume(1);
    } else {
      setVolume(0);
    }
  };

  const renderScene = (scene: SessionScene): JSX.Element => {
    switch (scene) {
      case SessionScene.IndividualSessionLobby:
        // Will transition into first round automatically
        return <></>;
      case SessionScene.Round:
        return (
          <StudentQuestionRound
            disableRoundIntro
            teacherPreviewConfirmAreaComponent={
              <TeacherPreviewNavButtons
                onInitiatePriorRoundClick={initiatePriorRound}
                onInitiateNextRoundClick={initiateNextRound}
                currentRoundNumber={currentRoundNumber}
                finalRoundNumber={finalRoundNumber}
              />
            }
          />
        );
    }

    return <>Scene not implemented: {scene}</>;
  };

  const handleExit = () => {
    exitGame();
  };

  const redirectToSetDetail = useCallback(() => {
    sessionStorage.removeItem(localStoreAuthKeyName);

    window.location.href =
      sessionStorage.getItem(localStorePostSessionReturnPath) ||
      `/t/my-library/sets/${practiceSetId}`;
  }, [practiceSetId]);

  useEffect(() => {
    events.on(SessionWebSocketEvent.ExitGameResponse, redirectToSetDetail);

    return () => {
      events.off(SessionWebSocketEvent.ExitGameResponse, redirectToSetDetail);
    };
  }, [redirectToSetDetail, events]);

  return sceneTransition((style, s) => (
    <VStack {...fullScreenStyles} spacing="0">
      <StudentHeader
        headingText={headerTitleText}
        cursorsVisible={false}
        isSoundEnabled={!!volume}
        onExit={handleExit}
        hideStickers
        hideCursorButton
        onToggleSound={onToggleSound}
      />
      <AnimatedBox
        zIndex={s === scene ? "auto" : "-1"}
        w="full"
        h="full"
        overflow="hidden"
        style={style}
      >
        {roundTransition(() => renderScene(s))}
      </AnimatedBox>
    </VStack>
  ));
};
