import { AxiosError } from "axios";
import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { IClassroom } from "../../types";

export interface IFetchClassroomsParams {
  id?: string;
  query?: string;
  archived?: boolean;
  refetchInterval?: number;
  includeAllRoles?: boolean;
  disabled?: boolean;
  onSuccess?: (data: IFetchClassroomsResponse) => void;
  onError?: (error: Error) => void;
  onUnauthenticated?: () => void;
}

export interface IFetchClassroomsResponse {
  classrooms: Array<IClassroom>;
}

interface IFetchGroupsResponse {
  groups: Array<IClassroom>;
}

/**
 * Fetches a list of classes for the current user
 * @returns
 */
export default function useFetchClassrooms(
  params: IFetchClassroomsParams
): UseQueryResult<IFetchClassroomsResponse, AxiosError> {
  const axios = useAxios();

  const query = useQuery<IFetchClassroomsResponse, AxiosError>(
    [
      "classrooms",
      {
        id: params.id,
        query: params.query,
        archived: params.archived,
        includeAllRoles: params.includeAllRoles,
      },
    ],
    async (): Promise<IFetchClassroomsResponse> => {
      return await axios
        .get(`/v1/groups${params.id ? `/${params.id}` : ""}`, {
          params: {
            query: params.query,
            archived: params.archived,
            include_all_roles: params.includeAllRoles,
          },
        })
        .then((r) => {
          const data = r.data as IFetchGroupsResponse;
          return { data: { classrooms: data.groups || [] } };
        })
        .then((r) => r.data as IFetchClassroomsResponse);
    },
    {
      onSuccess: params.onSuccess,
      refetchInterval: params.refetchInterval,
      enabled: !params.disabled,
      onError: (err: AxiosError) => {
        if (err.response?.status === 401) {
          if (params.onUnauthenticated) {
            params.onUnauthenticated();
            return;
          }
        }

        params.onError?.(err);
      },
    }
  );

  return query;
}
