import moment from "moment";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useMount } from "react-use";

import { ClassroomsScreen } from "adminComponents/screens/ClassroomsScreen";
import { useErrorToast } from "adminComponents/utils/toast";
import { useAnalytics, usePageTrack } from "lib/contexts/analytics";
import { usePageTitle } from "lib/hooks/usePageTitle";
import { useRouteStateOnMount } from "lib/hooks/useRouteStateOnMount";
import {
  useFetchAverageUserAccuraciesBulk,
  useFetchClassroomAssignmentsBulk,
  useFetchClassrooms,
} from "links/lib/features/classrooms";
import { useGetClassroomLiveBanners } from "links/lib/hooks/useGetClassroomLiveBanners";
import {
  AnalyticsEvent,
  AnalyticsPage,
  IClassroom,
  IPracticeSetSession,
} from "links/lib/types";
import { sortAlpha } from "links/lib/util";
import { useNavigationData } from "screens/TeacherDashboard/contexts/TeacherNavigationDataProvider";

import { useImportClassroomByGClassroom } from "../ClassroomDetail/components/StudentsTab/hooks/useImportClassroomByGClassroom";
import { useArchiveClassroom } from "../shared/hooks/useArchiveClassroom";
import { useCreateAssignment } from "../shared/hooks/useCreateAssignment";
import { useDeleteClassroom } from "../shared/hooks/useDeleteClassroom";
import { useMutateClassroom } from "../shared/hooks/useMutateClassroom";

const MAX_VISIBLE_ASSIGNMENTS = 3;

export interface IProps {
  initialCreateOpen?: boolean;
}

export const ClassroomList: React.FC<IProps> = ({
  initialCreateOpen = false,
}) => {
  const { navigationData } = useNavigationData();
  const { t } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "classroomListContainer",
  });
  const history = useHistory();

  const { trackEvent } = useAnalytics();
  usePageTitle(t("pageTitle"));
  usePageTrack(AnalyticsPage.TeacherDashboard_ClassroomList);

  // round to the start of day
  const today = useMemo(() => moment().startOf("D").toDate(), []);
  // round to 7 days from now
  const weekFromToday = useMemo(
    () => moment().add(7, "d").startOf("D").toDate(),
    []
  );

  const fetchClassrooms = useFetchClassrooms({ archived: false });
  const fetchArchivedClassrooms = useFetchClassrooms({ archived: true });
  const classroom_ids = fetchClassrooms.data?.classrooms.map((c) => c.id);
  const fetchAssignments = useFetchClassroomAssignmentsBulk({
    classroom_ids,
    start_date: today,
    end_date: weekFromToday,
    limit: MAX_VISIBLE_ASSIGNMENTS + 1,
  });
  const fetchAccuracies = useFetchAverageUserAccuraciesBulk({
    classroom_ids,
    refetchOnWindowFocus: false,
  });

  const {
    handleAddIntent: handleImportClassroomIntentByGClassroom,
    authorizeModal: importClassroomAuthorizeModal,
    classroomSelectModal: importClassroomClassroomSelectModal,
    studentSelectModal: importClassroomStudentSelectModal,
  } = useImportClassroomByGClassroom();

  useErrorToast(fetchClassrooms.error);
  useErrorToast(fetchArchivedClassrooms.error);

  const onArchiveConfirm = useCallback(
    (classroom: IClassroom) => {
      trackEvent(
        AnalyticsEvent.TeacherDashboard_ClassroomList_ClassroomDetail_ArchiveClassroomConfirm,
        { classroomId: classroom.id }
      );
    },
    [trackEvent]
  );

  const onDeleteConfirm = useCallback(
    (classroom: IClassroom) => {
      trackEvent(
        AnalyticsEvent.TeacherDashboard_ClassroomList_ClassroomDetail_DeleteClassroomConfirm,
        { classroomId: classroom.id }
      );
    },
    [trackEvent]
  );

  const onCreateAssignmentSubmit = useCallback(() => {
    trackEvent(
      AnalyticsEvent.TeacherDashboard_ClassroomList_AssignmentList_CreateAssignmentConfirm,
      {}
    );
  }, [trackEvent]);

  const onMutateClassroomSubmit = useCallback(
    (classroomId?: string) => {
      if (classroomId) {
        trackEvent(
          AnalyticsEvent.TeacherDashboard_ClassroomList_ClassroomDetail_EditClassroomConfirm,
          { classroomId }
        );
      } else {
        trackEvent(
          AnalyticsEvent.TeacherDashboard_ClassroomList_Common_CreateClassroomConfirm,
          {}
        );
      }
    },
    [trackEvent]
  );

  const { handleArchiveIntent, modal: archiveModal } = useArchiveClassroom({
    onConfirm: onArchiveConfirm,
  });
  const { handleDeleteIntent, modal: deleteModal } = useDeleteClassroom({
    onConfirm: onDeleteConfirm,
  });
  const {
    handleCreateIntent: handleCreateAssignmentIntent,
    component: createAssignmentComponent,
  } = useCreateAssignment({
    classrooms: fetchClassrooms.data?.classrooms || [],
    onSubmit: onCreateAssignmentSubmit,
  });
  const {
    handleCreateIntent: handleCreateClassroomIntent,
    handleEditIntent: handleEditClassroomIntent,
    flyout: classroomFlyout,
  } = useMutateClassroom({
    gradeLevels: navigationData.gradeLevels,
    subjects: navigationData.subjects,
    onSubmit: onMutateClassroomSubmit,
  });

  // Open flyout if state create key is present
  useRouteStateOnMount({
    handler: handleCreateClassroomIntent,
    stateKey: "createClassroom",
    replace: true,
  });

  useMount(() => {
    if (initialCreateOpen) {
      handleCreateClassroomIntent();
      history.push("/t/classrooms");
    }
  });

  const classroomsData = useMemo(() => {
    return fetchClassrooms.data?.classrooms
      .map((classroom) => {
        const assignments = (
          fetchAssignments.assignmentData[classroom.id] || []
        ).map((a) => ({ assignment: a }));

        const accuracies =
          fetchAccuracies.accuracyData[classroom.id].average_user_accuracies;
        const hasAccuracy = !!accuracies.length;
        const sumValidAccuracy = accuracies.reduce((sum, a) => {
          return sum + a.average_accuracy;
        }, 0);
        const accuracy = hasAccuracy
          ? Math.round((sumValidAccuracy / accuracies.length) * 100)
          : undefined;

        return {
          classroom,
          accuracy,
          assignments: assignments.slice(0, MAX_VISIBLE_ASSIGNMENTS),
          hasMoreAssignments: assignments.length > MAX_VISIBLE_ASSIGNMENTS,
        };
      })
      .sort((a, b) => {
        return sortAlpha(a.classroom.name, b.classroom.name);
      });
  }, [
    fetchClassrooms,
    fetchAssignments.assignmentData,
    fetchAccuracies.accuracyData,
  ]);

  const handleArchiveClassroom = useCallback(
    (classroom: IClassroom) => {
      const accuracy = classroomsData?.find(
        (c) => c.classroom.id === classroom.id
      )?.accuracy;
      handleArchiveIntent(classroom, accuracy);
    },
    [classroomsData, handleArchiveIntent]
  );

  const handleDeleteClassroom = useCallback(
    (classroom: IClassroom) => {
      const accuracy = classroomsData?.find(
        (c) => c.classroom.id === classroom.id
      )?.accuracy;
      handleDeleteIntent(classroom, accuracy);
    },
    [classroomsData, handleDeleteIntent]
  );

  const handleDuplicateClassroom = useCallback(() => {
    // Not implemented
    return;
  }, []);

  const handleSeeClassroomArchive = useCallback(() => {
    history.push("/t/classrooms/archive");
  }, [history]);

  const handleCreateAssignment = useCallback(
    (classroom: IClassroom) => {
      handleCreateAssignmentIntent(classroom);

      trackEvent(
        AnalyticsEvent.TeacherDashboard_ClassroomList_AssignmentList_CreateAssignmentIntent,
        {}
      );
    },
    [handleCreateAssignmentIntent, trackEvent]
  );

  const handleCreateClassroom = useCallback(() => {
    handleCreateClassroomIntent();

    trackEvent(
      AnalyticsEvent.TeacherDashboard_ClassroomList_Common_CreateClassroomIntent,
      {}
    );
  }, [trackEvent, handleCreateClassroomIntent]);

  const handleEditClassroom = useCallback(
    (classroom: IClassroom) => {
      handleEditClassroomIntent(classroom);

      trackEvent(
        AnalyticsEvent.TeacherDashboard_ClassroomList_ClassroomDetail_EditClassroomIntent,
        { classroomId: classroom.id }
      );
    },
    [trackEvent, handleEditClassroomIntent]
  );

  const handleJoinSession = (session: IPracticeSetSession) => {
    history.push(`/session/join/${session.join_code}`);

    trackEvent(
      AnalyticsEvent.TeacherDashboard_ClassroomList_Common_LiveBanner_JoinSession,
      {
        sessionId: session.id,
      }
    );
  };

  const liveBanners = useGetClassroomLiveBanners({});

  return (
    <>
      <ClassroomsScreen
        classrooms={classroomsData}
        liveBanners={liveBanners}
        isLoading={fetchClassrooms.isLoading || fetchAssignments.isLoading}
        navigationData={navigationData}
        handleArchiveClassroom={handleArchiveClassroom}
        handleCreateAssignment={handleCreateAssignment}
        handleCreateClassroom={handleCreateClassroom}
        handleDeleteClassroom={handleDeleteClassroom}
        handleDuplicateClassroom={handleDuplicateClassroom}
        handleEditClassroom={handleEditClassroom}
        handleImportClassroomIntentByGClassroom={
          handleImportClassroomIntentByGClassroom
        }
        handleSeeClassroomArchive={handleSeeClassroomArchive}
        handleJoinSession={handleJoinSession}
        // Only hide archive if the user has no classrooms (archived or active)
        showArchive={
          !!classroomsData?.length ||
          !!fetchArchivedClassrooms.data?.classrooms.length
        }
      />
      {archiveModal}
      {deleteModal}
      {createAssignmentComponent}
      {classroomFlyout}
      {importClassroomAuthorizeModal}
      {importClassroomClassroomSelectModal}
      {importClassroomStudentSelectModal}
    </>
  );
};
