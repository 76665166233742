import { DeleteIcon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  HStack,
  SimpleGrid,
  Spacer,
  Switch,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useSessionStorage } from "react-use";

import {
  CoopChecksVariantData,
  CoopDragsortVariantData,
  CoopRadiosVariantData,
  ISessionRoundGroupState,
  ISessionRoundGroupsState,
  ISessionUser,
  ISessionWebSocketState,
  IUser,
  PracticeSessionItemVariantType,
  QuestionType,
  SessionConnectionStatus,
  SessionRoundState,
  SessionScene,
  UserRole,
} from "links/lib/types";

export interface ISessionDeveloperToolsProps {
  authUser: IUser;
  state: ISessionWebSocketState;
  putSessionState: (state: ISessionWebSocketState) => void;
}

export const SessionDeveloperTools: React.FC<ISessionDeveloperToolsProps> = ({
  authUser,
  state,
  putSessionState,
}) => {
  const [sessionStateSnapshots, setSessionStateSnapshots] = useSessionStorage<
    Array<{ label: string; state: ISessionWebSocketState }>
  >("session.developer.state-snapshots", []);

  const [enableAutomaticeSceneSnapshots, setEnableAutomaticeSceneSnapshots] =
    useState(sessionStateSnapshots.length === 0);

  const [sessionStateString, setSessionStateString] = useState<string>(
    JSON.stringify(state, null, 2)
  );

  const onTakeSnapshot = () => {
    const newSnapshots = [...sessionStateSnapshots];

    const label = `${state.scene} ${state.round_state?.round_number || ""}`;
    newSnapshots.push({ label: label, state: state });

    setSessionStateSnapshots(newSnapshots);
  };

  const onClearSnapshots = () => {
    setSessionStateSnapshots([]);
  };

  const onRemoveUser = () => {
    const maxId = parseInt(
      Object.keys(state.users).sort((a, b) => (a > b ? -1 : 1))[0] || "0",
      10
    );
    delete state.users[maxId];
    setSessionStateString(JSON.stringify(state, null, 2));
    putSessionState(state);
  };

  const onAddUser = () => {
    const demoAvatarStrings = ["demo", "demo2"];
    const maxId = parseInt(
      Object.keys(state.users).sort((a, b) => (a > b ? -1 : 1))[0] || "0",
      10
    );
    const randomDemoAvatarString =
      demoAvatarStrings[Math.floor(Math.random() * demoAvatarStrings.length)];

    state.users[maxId + 1] = {
      id: (maxId + 1).toString(),
      name: `Fake-${maxId + 1}`,
      remaining_name_shuffles: 5,
      role: UserRole.Student,
      spine_atlas_url: `http://localhost:9000/demo-avatar/${randomDemoAvatarString}.atlas`,
      spine_json_url: `http://localhost:9000/demo-avatar/${randomDemoAvatarString}.json`,
      spritesheet_url: `http://localhost:9000/demo-avatar/spritesheet.${randomDemoAvatarString}.png`,
      session_group_id: "0",
      cursor: undefined,
      profile_image_url: `https://playtime-files-stg.giantsteps.app/39/7a1092c0-49ad-4a16-aa9f-7b93096d1d39/profile-image.png`,
      round_awarded_points: 0,
      connection_status: SessionConnectionStatus.Connected,
      color_scheme: "smoke",
      session_xp_total: 0,
      session_start_xp: 0,
      session_xp_accuracy_bonus: 0,
      session_xp_items: 0,
      session_xp_challenge_completion: 0,
      is_guest: false,
    };
    setSessionStateString(JSON.stringify(state, null, 2));
    putSessionState(state);
  };

  const onAllStudentsAnswer = () => {
    const fakeStudents = Object.values(state.users).filter(
      (u) => u && u.name.startsWith("Fake")
    ) as ISessionUser[];
    const userIds = fakeStudents.map((u) => u.id);
    const newRoundGroupsState: ISessionRoundGroupsState = {};
    let getZoneId: (i?: number) => string;
    switch (state.round_state?.variant) {
      case PracticeSessionItemVariantType.CoopTextMatch:
      case PracticeSessionItemVariantType.CoopTextMatchNumeric:
        fakeStudents.forEach((u) => {
          const newRoundGroupStateTextMatch: ISessionRoundGroupState =
            newRoundGroupsState[u.session_group_id] || {
              text_match: {
                choices: [],
                selected_choices: [],
                text_responses: [],
              },
            };
          const choiceId = (
            (newRoundGroupStateTextMatch.text_match?.choices || []).length + 1
          ).toString();
          newRoundGroupStateTextMatch.text_match?.choices.push({
            id: choiceId,
            image_alt_text: "",
            image_url: "",
            text: `Answer ${choiceId}`,
            user_id: u.id,
          });
          newRoundGroupStateTextMatch.text_match?.selected_choices.push({
            choice_id: choiceId,
            is_correct: false,
            is_partially_correct: false,
            is_selected: true,
            user_id: u.id,
          });

          newRoundGroupsState[u.session_group_id] = newRoundGroupStateTextMatch;
        });
        break;
      case PracticeSessionItemVariantType.CoopRadios:
        fakeStudents.forEach((u) => {
          const newRoundGroupStateRadios: ISessionRoundGroupState =
            newRoundGroupsState[u.session_group_id] || {
              radios: { selected_choices: [] },
            };
          const choices =
            (state.round_state?.variant_data as CoopRadiosVariantData)
              .coop_radios.choices || [];
          const randomChoiceId =
            choices[Math.floor(Math.random() * choices.length)].id;
          newRoundGroupStateRadios.radios?.selected_choices.push({
            choice_id: randomChoiceId,
            is_correct: false,
            is_partially_correct: false,
            is_selected: true,
            user_id: u.id,
          });

          newRoundGroupsState[u.session_group_id] = newRoundGroupStateRadios;
        });
        break;
      case PracticeSessionItemVariantType.CoopChecks:
        fakeStudents.forEach((u) => {
          const newRoundGroupStateChecks: ISessionRoundGroupState =
            newRoundGroupsState[u.session_group_id] || {
              checks: { selected_choices: [] },
            };
          const choices =
            (state.round_state?.variant_data as CoopChecksVariantData)
              .coop_checks.choices || [];
          const randomChoiceId =
            choices[Math.floor(Math.random() * choices.length)].id;
          newRoundGroupStateChecks.checks?.selected_choices.push({
            choice_id: randomChoiceId,
            is_correct: false,
            is_partially_correct: false,
            is_selected: true,
            user_id: u.id,
          });

          newRoundGroupsState[u.session_group_id] = newRoundGroupStateChecks;
        });
        break;
      case PracticeSessionItemVariantType.CoopDragsort:
        // Dragsort items are shared across the members of the group, so rather
        // than faking an answer for each added student, fake a drag/drop event
        // for each item
        switch (state.round_state.practice_set_session_item.question_type) {
          case QuestionType.Diagram:
            getZoneId = (i?: number) => {
              const zones =
                (state.round_state?.variant_data as CoopDragsortVariantData)
                  .coop_dragsort.zones || [];
              return zones[i || 0].id;
            };
            break;
          case QuestionType.Classify:
            getZoneId = () => {
              const zones =
                (state.round_state?.variant_data as CoopDragsortVariantData)
                  .coop_dragsort.zones || [];
              return zones[Math.floor(Math.random() * zones.length)].id;
            };
            break;
        }
        (
          (state.round_state?.variant_data as CoopDragsortVariantData)
            .coop_dragsort.items || []
        ).forEach((item, i) => {
          Object.keys(state.groups).forEach((groupId) => {
            const users = Object.values(state.users).filter(
              (u) => u && u.session_group_id === groupId
            ) as ISessionUser[];
            const randomUser = users[Math.floor(Math.random() * users.length)];
            const newRoundGroupStateDragsort: ISessionRoundGroupState =
              newRoundGroupsState[groupId] || {
                dragsort: { items: [] },
              };
            newRoundGroupStateDragsort.dragsort?.items.push({
              id: item.id,
              text: item.text,
              zone_id: getZoneId(i),
              is_correct: false,
              is_dragging: false,
              user_id: randomUser.id,
            });

            newRoundGroupsState[groupId] = newRoundGroupStateDragsort;
          });
        });
        break;
      case PracticeSessionItemVariantType.CoopDraw:
        fakeStudents.forEach((u) => {
          const newRoundGroupStateDraw: ISessionRoundGroupState =
            newRoundGroupsState[u.session_group_id] || {
              draw: {
                strokes: [],
                draw_leader_user_id: "",
                pens: [],
              },
            };
          newRoundGroupStateDraw.draw?.strokes.push({
            color: "#DD1814",
            parts: [
              {
                start: {
                  x: Math.random(),
                  y: Math.random(),
                },
                end: {
                  x: Math.random(),
                  y: Math.random(),
                },
              },
            ],
            user_id: u.id,
            is_incomplete: false,
          });

          newRoundGroupsState[u.session_group_id] = newRoundGroupStateDraw;
        });
        break;
    }
    state.round_groups_state = newRoundGroupsState;
    const roundState = state.round_state as SessionRoundState;
    roundState.confirmed_user_ids =
      roundState.confirmed_user_ids.concat(userIds);
    state.round_state = roundState;
    setSessionStateString(JSON.stringify(state, null, 2));
    putSessionState(state);
  };

  const onChangeSessionState = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSessionStateString(e.target.value);
  };

  const onRefreshSessionState = () => {
    setSessionStateString(JSON.stringify(state, null, 2));
  };

  const onRestoreSessionStateSnapshot = (i: number) => {
    setEnableAutomaticeSceneSnapshots(false);
    putSessionState(sessionStateSnapshots[i].state);
  };

  const onDestroySessionStateSnapshot = (i: number) => {
    const newSnapshots = [...sessionStateSnapshots];
    newSnapshots.splice(i, 1);

    setSessionStateSnapshots(newSnapshots);
  };

  const onSubmitSessionState = () => {
    try {
      const newSessionState = JSON.parse(sessionStateString);
      putSessionState(newSessionState);
    } catch (err) {
      console.error("Invalid JSON", err);
    }
  };

  const onToggleAutomaticSnapshots = () => {
    setEnableAutomaticeSceneSnapshots(!enableAutomaticeSceneSnapshots);
  };

  useEffect(() => {
    if (!enableAutomaticeSceneSnapshots) return;
    // Allow a small amount of time for related scene data to arrive
    setTimeout(onTakeSnapshot, 100);
    // eslint-disable-next-line
  }, [state.scene]);

  const [isOpen, setIsOpen] = useState(false);

  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      zIndex={1000000}
      bgColor="gray.100"
      w={isOpen ? "full" : "auto"}
    >
      <Accordion allowToggle>
        <AccordionItem>
          <h2>
            <AccordionButton
              onClick={() => {
                setIsOpen(!isOpen);
                setSessionStateString(JSON.stringify(state, null, 2));
              }}
            >
              <Box flex="1" textAlign="left">
                State Control
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <VStack>
              <Box w="full">
                <Switch
                  onChange={onToggleAutomaticSnapshots}
                  isChecked={enableAutomaticeSceneSnapshots}
                >
                  Enable automatic scene snapshots
                </Switch>
              </Box>
              {sessionStateSnapshots.length && (
                <SimpleGrid
                  columns={{ sm: 2, md: 3 }}
                  spacing="0 16px"
                  borderWidth="1px"
                  borderColor="gray.100"
                  w="full"
                  p="2px 0"
                  bgColor="white"
                  borderRadius="8px"
                >
                  {sessionStateSnapshots.map((sessionState, i) => {
                    return (
                      <HStack
                        key={i}
                        _hover={{ bgColor: "gray.50" }}
                        p="2px 4px"
                        spacing="4px"
                      >
                        <Button
                          colorScheme="red"
                          onClick={() => {
                            onDestroySessionStateSnapshot(i);
                          }}
                          p="8px"
                          size="sm"
                        >
                          <DeleteIcon />
                        </Button>
                        <Button
                          flex="2"
                          onClick={() => {
                            onRestoreSessionStateSnapshot(i);
                          }}
                          p="8px"
                          size="sm"
                          justifyContent="flex-start"
                        >
                          {sessionState.label}
                        </Button>
                      </HStack>
                    );
                  })}
                </SimpleGrid>
              )}

              <HStack w="full" justifyContent="flex-end">
                {sessionStateSnapshots.length && (
                  <Button onClick={onClearSnapshots} colorScheme="red">
                    Destroy all snapshots
                  </Button>
                )}
                <Button onClick={onTakeSnapshot} colorScheme="green">
                  Take a snapshot
                </Button>
              </HStack>

              <Spacer />

              <Box w="full" color="red.600">
                Danger Zone
              </Box>

              {authUser.role !== UserRole.Student &&
                state.scene === SessionScene.Lobby && (
                  <HStack w="full" justifyContent="flex-start">
                    <Button onClick={onRemoveUser} colorScheme="red">
                      Remove User
                    </Button>
                    <Button onClick={onAddUser} colorScheme="green">
                      Add User
                    </Button>
                  </HStack>
                )}

              {authUser.role !== UserRole.Student &&
                state.scene === SessionScene.Round && (
                  <HStack w="full" justifyContent="flex-start">
                    <Button onClick={onAllStudentsAnswer} colorScheme="green">
                      All Students Answer
                    </Button>
                  </HStack>
                )}

              <Textarea
                bgColor="white"
                value={sessionStateString}
                onChange={onChangeSessionState}
              />
              <HStack justifyContent="flex-end" w="full">
                <Button onClick={onRefreshSessionState} colorScheme="green">
                  Reset to current
                </Button>
                <Button onClick={onSubmitSessionState} colorScheme="red">
                  Set state
                </Button>
              </HStack>
            </VStack>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};
