import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";

export interface IFetchOrganizationExplainConversationCountParams {
  days_ago: number;
  include_previous_period?: boolean;
  include_top_org_check?: boolean;
}

export interface IFetchOrganizationExplainConversationCountResponse {
  explain_count: string;
  previous_period_explain_count: string;
  top_half_of_all_organizations: boolean;
  top_third_of_all_organizations: boolean;
}

export function useFetchOrganizationExplainConversationsCount({
  days_ago,
  include_previous_period = false,
  include_top_org_check = false,
}: IFetchOrganizationExplainConversationCountParams): UseQueryResult<
  IFetchOrganizationExplainConversationCountResponse,
  unknown
> {
  const axios = useAxios();

  const query = useQuery<IFetchOrganizationExplainConversationCountResponse>(
    [
      "org-explain-count",
      {
        days_ago,
        include_previous_period,
        include_top_org_check,
      },
    ],
    async (): Promise<IFetchOrganizationExplainConversationCountResponse> => {
      return await axios
        .get(`/v1/organizations/explain/count`, {
          params: {
            days_ago,
            include_previous_period,
            include_top_org_check,
          },
        })
        .then((r) => {
          return r.data as IFetchOrganizationExplainConversationCountResponse;
        });
    }
  );

  return query;
}
