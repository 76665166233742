import { UseQueryResult, useQuery } from "react-query";

import { SessionType } from "../../../../../../lib/types";
import { useAxios } from "../../hooks/useAxios";

export interface IFetchOrganizationSessionCountParams {
  days_ago: number;
  session_type: SessionType;
  include_previous_period?: boolean;
  include_top_org_check?: boolean;
}

export interface IFetchOrganizationSessionCountResponse {
  session_count: string;
  previous_period_session_count: string;
  top_half_of_all_organizations: boolean;
  top_third_of_all_organizations: boolean;
}

export default function useFetchOrganizationSessionCount({
  session_type,
  days_ago,
  include_previous_period = false,
  include_top_org_check = false,
}: IFetchOrganizationSessionCountParams): UseQueryResult<
  IFetchOrganizationSessionCountResponse,
  unknown
> {
  const axios = useAxios();

  const query = useQuery<IFetchOrganizationSessionCountResponse>(
    [
      "organization-session-count",
      {
        days_ago,
        session_type,
        include_previous_period,
        include_top_org_check,
      },
    ],
    async (): Promise<IFetchOrganizationSessionCountResponse> => {
      return await axios
        .get(`/v1/practice-sets/sessions/org/count`, {
          params: {
            session_type,
            days_ago,
            include_previous_period,
            include_top_org_check,
          },
        })
        .then((r) => {
          return r.data as IFetchOrganizationSessionCountResponse;
        });
    }
  );

  return query;
}
