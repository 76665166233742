import { ContextType } from "react";

import { AxiosContext } from "../../contexts/axios";
import { IUseAsyncReturn, useAsync } from "../../hooks/useAsync";
import { useAxios } from "../../hooks/useAxios";

export interface IInitializeUserRoleResponse {
  token: string;
}

export interface IInitializeUserRoleParams {
  isStudent: boolean;
  accepted_agreement_ids?: Array<string>;
}

export const initializeUserRole = async (
  axios: ContextType<typeof AxiosContext>,
  isStudent: boolean,
  accepted_agreement_ids?: Array<string>
): Promise<IInitializeUserRoleResponse> => {
  return await axios
    .post("/v1/users/initialize-role", {
      is_student: isStudent,
      accepted_agreement_ids,
    })
    .then((r) => r.data as IInitializeUserRoleResponse);
};

export default function useInitializeUserRole(): IUseAsyncReturn<
  IInitializeUserRoleResponse,
  IInitializeUserRoleParams
> {
  const axios = useAxios();

  const { isLoading, error, data, execute, isSuccess } = useAsync<
    IInitializeUserRoleResponse,
    IInitializeUserRoleParams
  >(({ isStudent, accepted_agreement_ids }) =>
    initializeUserRole(axios, isStudent, accepted_agreement_ids)
  );

  return {
    isLoading,
    error,
    data,
    isSuccess,
    execute,
  };
}
