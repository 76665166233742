import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { TeacherProfileSettingsScreen } from "adminComponents/screens/TeacherProfileSettingsScreen";
import { useErrorToast, useShowToast } from "adminComponents/utils/toast";
import { useAnalytics, usePageTrack } from "lib/contexts/analytics";
import { usePageTitle } from "lib/hooks/usePageTitle";
import { useMutateAuthUser } from "links/lib/features/account";
import { useAuth } from "links/lib/features/auth";
import {
  AnalyticsEvent,
  AnalyticsPage,
  ISharingSettings,
} from "links/lib/types";
import { useNavigationData } from "screens/TeacherDashboard/contexts/TeacherNavigationDataProvider";

export const TeacherProfileSettings: React.FC = () => {
  const { authUser, authSharingSettings } = useAuth();
  const { navigationData } = useNavigationData();
  const showToast = useShowToast();
  const { trackEvent } = useAnalytics();
  const { t } = useTranslation("admin", {
    keyPrefix: "teacherProfileSettings",
  });
  usePageTitle(t("pageTitle"));
  usePageTrack(AnalyticsPage.TeacherDashboard_ProfileSettings);

  const {
    mutate,
    isLoading: isFormLoading,
    isSuccess,
    error,
  } = useMutateAuthUser();
  useErrorToast(error, t("errorMutate"));

  useEffect(() => {
    if (!isSuccess) return;
    showToast(t("successToast"));
  }, [isSuccess, showToast, t]);

  const onSubmit = (settings: ISharingSettings, customUrlCode: string) => {
    trackEvent(AnalyticsEvent.TeacherDashboard_ProfileSettings_SubmitForm, {});

    mutate({
      custom_url_code: customUrlCode,
      teacher_preferred_name: authUser?.teacher_preferred_name,
      teacher_title: authUser?.teacher_title,
      teacher_school_or_district_name:
        authUser?.teacher_school_or_district_name,
      teacher_school_or_district_postal_code:
        authUser?.teacher_school_or_district_postal_code,
      timezone_locale: authUser?.timezone_locale.replaceAll(" ", "_"),
      grade_level_ids: (authUser?.teacher_grade_levels || []).map(
        (gl) => gl.grade_level_id
      ),
      subject_ids: (authUser?.teacher_subjects || []).map((s) => s.subject_id),
      language: authUser?.language,
      country: authUser?.country,
      region: authUser?.region,
      visibility: settings.visibility,
      share_statistics: settings.share_statistics,
      share_grade_levels: settings.share_grade_levels,
      share_subjects: settings.share_subjects,
      share_region: settings.share_region,
    });
  };

  return (
    <TeacherProfileSettingsScreen
      isLoading={isFormLoading}
      navigationData={navigationData}
      settings={authSharingSettings}
      isFormLoading={isFormLoading}
      handleSubmit={onSubmit}
    />
  );
};
