import { VStack } from "@chakra-ui/react";
import React from "react";

import { IHandleViewStudentResponses } from "adminComponents/molecules/ClassroomAnswerResultsMetric";
import {
  PracticeSetQuestionCard,
  PracticeSetQuestionCardType,
} from "adminComponents/molecules/PracticeSetQuestionCard";
import { pxToRem } from "adminComponents/utils/pxToRem";
import {
  IPracticeSetItem,
  IPracticeSetSessionItemReport,
} from "links/lib/types";

export interface IItemListProps {
  handleViewStudentResponses?: IHandleViewStudentResponses;
  items: {
    practiceSetItem: IPracticeSetItem;
    classroomResults:
      | IPracticeSetSessionItemReport
      | IPracticeSetSessionItemReport[];
  }[];
  studentsCount?: number;
}

export const ClassroomReportQuestionList: React.FC<IItemListProps> = ({
  handleViewStudentResponses,
  items,
  studentsCount,
}) => (
  <VStack spacing={{ base: pxToRem(16), md: pxToRem(32) }} w="full">
    {items.map((item, index) => (
      <PracticeSetQuestionCard
        cardType={PracticeSetQuestionCardType.ClassroomResults}
        classroomResultsReport={item.classroomResults}
        handleViewStudentResponses={handleViewStudentResponses}
        key={`item-${item.practiceSetItem.guid}`}
        practiceSetItem={item.practiceSetItem}
        questionNumber={index + 1}
        studentsCount={studentsCount}
      />
    ))}
  </VStack>
);
