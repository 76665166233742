import { Box } from "@chakra-ui/react";
import React, { useEffect } from "react";

interface IProps {
  portalId: string;
  formId: string;
}

export const EmbeddedHubspotForm: React.FC<IProps> = ({ portalId, formId }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      // eslint-disable-next-line
      if ((window as any).hbspt) {
        // eslint-disable-next-line
        (window as any).hbspt.forms.create({
          portalId,
          formId,
          target: "#hubspotForm",
        });
      }
    });

    return () => {
      script.remove();
    };
  }, [formId, portalId]);

  return (
    <Box>
      <Box id="hubspotForm" />
    </Box>
  );
};
