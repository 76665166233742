import { Flex, SimpleGrid } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Divider } from "adminComponents/atoms/Divider";
import { Heading } from "adminComponents/atoms/Heading";
import { Text } from "adminComponents/atoms/Text";
import { EmptyCard } from "adminComponents/molecules/EmptyCard";
import { FolderDetailHeader } from "adminComponents/molecules/FolderDetailHeader";
import {
  IProps as ILibraryPracticeSetCard,
  LibraryPracticeSetCard,
} from "adminComponents/molecules/LibraryPracticeSetCard";
import {
  INavigationHeaderProps,
  NavigationHeader,
} from "adminComponents/molecules/NavigationHeader";
import { TemplateWithCenteredHeroOneColumn } from "adminComponents/templates/TemplateWithCenteredHeroOneColumn";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { ICollection, IPracticeSet, IUser } from "links/lib/types";

type ILibraryPracticeSetCardProps = Omit<
  ILibraryPracticeSetCard,
  "handleDelete" | "handleDuplicate" | "handleMoveToFolder"
>;

interface IProps {
  authUser?: IUser;
  collection?: ICollection;
  handleCreatePracticeSet: (collection: ICollection) => void;
  handleDeleteCollection: (collection: ICollection) => void;
  handleDeletePracticeSet: (practiceSet: IPracticeSet) => void;
  handleDuplicateCollection: (collection: ICollection) => void;
  handleDuplicatePracticeSet: (practiceSet: IPracticeSet) => void;
  handleEditCollection: (collection: ICollection) => void;
  handleMovePracticeSetToFolder: (practiceSet: IPracticeSet) => void;
  isLoading?: boolean;
  navigationData: INavigationHeaderProps;
  practiceSets?: ILibraryPracticeSetCardProps[];
  showPremiumMarker?: boolean;
}

export const FolderDetailScreen: React.FC<IProps> = ({
  authUser,
  collection,
  handleCreatePracticeSet,
  handleDeleteCollection,
  handleDeletePracticeSet,
  // handleDuplicateCollection,
  handleDuplicatePracticeSet,
  handleEditCollection,
  handleMovePracticeSetToFolder,
  isLoading,
  navigationData,
  practiceSets,
  showPremiumMarker = true,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  return (
    <TemplateWithCenteredHeroOneColumn
      isLoading={isLoading}
      nav={<NavigationHeader {...navigationData} isLoading={isLoading} />}
    >
      {!!collection && (
        <>
          <FolderDetailHeader
            collection={collection}
            handleCreatePracticeSet={handleCreatePracticeSet}
            handleDelete={handleDeleteCollection}
            // handleDuplicate={handleDuplicateCollection}
            handleEdit={handleEditCollection}
            headerAs="h1"
            headerVariant="adminH3"
            isLoading={isLoading}
          />
          <Divider color="primary.tan" mb={pxToRem(32)} mt={pxToRem(32)} />
          <Flex justifyContent="space-between" mb={pxToRem(20)}>
            <Heading as="h2" variant="adminH6">
              {t("folderDetailScreen.practiceSetsLabel")}
            </Heading>
            {practiceSets && practiceSets.length > 0 && (
              <Text color="primary.warm-black" variant="adminH6">
                {t("folderDetailScreen.practiceSetCount", {
                  count: practiceSets.length,
                })}
              </Text>
            )}
          </Flex>
          {practiceSets && practiceSets.length ? (
            <SimpleGrid minChildWidth={pxToRem(290)} spacing={pxToRem(10)}>
              {practiceSets.map((practiceSetData) => {
                const { practiceSet } = practiceSetData;

                return (
                  <LibraryPracticeSetCard
                    authUser={authUser}
                    {...practiceSetData}
                    handleMoveToFolder={() =>
                      handleMovePracticeSetToFolder(practiceSet)
                    }
                    handleDuplicate={() =>
                      handleDuplicatePracticeSet(practiceSet)
                    }
                    handleDelete={() => handleDeletePracticeSet(practiceSet)}
                    practiceSet={practiceSet}
                    key={`practice-set-${practiceSet.id}`}
                    showPremiumMarker={showPremiumMarker}
                  />
                );
              })}
            </SimpleGrid>
          ) : (
            <EmptyCard
              text={t("folderDetailScreen.emptyText")}
              title={t("folderDetailScreen.emptyTitle")}
            />
          )}
        </>
      )}
    </TemplateWithCenteredHeroOneColumn>
  );
};
