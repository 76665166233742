import { pxToRem } from "adminComponents/utils/pxToRem";

interface IProps {
  isListLoading: boolean;
}

export const AdminSelectStandardsModal = {
  baseStyle: ({ isListLoading }: IProps): Record<string, unknown> => ({
    outer: {
      paddingBottom: [pxToRem(100), null, pxToRem(40)],
    },
    spacer: {
      mb: [pxToRem(20), pxToRem(40)],
    },
    standardsPickerContainer: {
      borderColor: "primary.medium-gray",
      p: [pxToRem(0), null, pxToRem(24)],
      borderWidth: [pxToRem(0), null, pxToRem(1)],
      borderRadius: [pxToRem(0), null, pxToRem(20)],
      bg: "primary.white",
      display: "grid",
      gap: [pxToRem(12), null, pxToRem(8)],
      height: ["auto", null, pxToRem(266)],
      overflowY: isListLoading ? "unset" : "auto",
    },
    buttonContainer: {
      pos: ["fixed", null, "relative"],
      bottom: [0, null, null],
      left: [0, null, null],
      width: ["100%", null, "auto"],
      paddingX: ["admin.mobileXPadding", null, 0],
      paddingTop: [pxToRem(20), null, 0],
      paddingBottom: [pxToRem(40), null, 0],
      borderTop: [`${pxToRem(1)} solid`, null, "none"],
      borderColor: ["primary.light-gray", null, null],
      backgroundColor: ["primary.white", null, null],
    },
  }),
};
