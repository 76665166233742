import {
  Box,
  Flex,
  LinkBox,
  LinkOverlay,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

import { Card } from "adminComponents/atoms/Card";
import { ClampedHeading } from "adminComponents/atoms/ClampedHeading";
import { Text } from "adminComponents/atoms/Text";
import CoverImageBlob from "adminComponents/molecules/CoverImageBlob";
import {
  IconButtonDropdown,
  MenuItemDetails,
} from "adminComponents/molecules/IconButtonDropdown";
import { pxToRem } from "adminComponents/utils";
import { IPracticeSet } from "links/lib/types";

import { Banner } from "./Banner";

export interface IProps {
  isShared: boolean;
  menuItems: MenuItemDetails[];
  practiceSet: IPracticeSet;
  practiceSetUrl: string;
  showPremiumMarker?: boolean;
}

export const CompactLibraryPracticeSetCard: React.FC<IProps> = ({
  isShared,
  menuItems,
  practiceSet,
  practiceSetUrl,
  showPremiumMarker = true,
}) => {
  const styles = useMultiStyleConfig("AdminLibraryPracticeSetCard", {
    variant: "compact",
  });
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });

  const questionsCount = practiceSet.item_count;

  return (
    <LinkBox w="100%">
      <Card
        noPadding
        borderColor="primary.tan"
        backgroundColor="primary.white"
        variant="adminCardSmallBorder"
        sx={styles.card}
        h="100%"
      >
        <Flex sx={styles.body}>
          <Flex sx={styles.headerContainer}>
            <Banner
              isCertified={practiceSet.is_certified}
              isPremium={practiceSet.is_premium}
              showPremiumMarker={showPremiumMarker}
            />
            {!isShared && (
              <IconButtonDropdown
                iconVariant="adminLibraryPracticeSetCard"
                menuItems={menuItems}
                usePortal={true}
              />
            )}
          </Flex>
          <CoverImageBlob
            backgroundColorScheme={practiceSet.cover_image_bg_color_scheme}
            backgroundPattern={practiceSet.cover_image_bg_pattern}
            icon={practiceSet.cover_image_icon}
            boxSize={60}
            minW={pxToRem(60)}
          />
          <Box sx={styles.content}>
            <LinkOverlay
              as={RouterLink}
              to={practiceSetUrl}
              sx={styles.linkOverlay}
            >
              <ClampedHeading
                noOfLines={2}
                headingAs="p"
                headingStyles={styles.heading}
                headingVariant="adminH7"
              >
                {practiceSet.title}
              </ClampedHeading>
            </LinkOverlay>
            <Text variant="adminP2" color="primary.dark-gray">
              {t("libraryPracticeSetCard.questionCount", {
                count: questionsCount,
              })}
            </Text>
          </Box>
        </Flex>
      </Card>
    </LinkBox>
  );
};
