import { Box } from "@chakra-ui/react";
import React from "react";

import { LoadingSpinner } from "adminComponents/atoms/LoadingSpinner";
import { QuestionsTab as AdminQuestionsTab } from "adminComponents/screens/ClassroomAssignmentReportScreen/components/QuestionsTab";
import { pxToRem } from "adminComponents/utils";
import { usePageTrack } from "lib/contexts/analytics";
import { useFetchClassroomUsers } from "links/lib/features/classrooms";
import useFetchClassroomRecentPracticeSetResponses from "links/lib/features/classrooms/useFetchClassroomRecentPracticeSetResponses";
import { useFetchPracticeSetItems } from "links/lib/features/practiceSetItems";
import {
  AnalyticsPage,
  IAssignment,
  IClassroom,
  UsersGroupsRole,
} from "links/lib/types";
import { useStudentsModal } from "screens/TeacherClassrooms/PracticeSetDetail/hooks/useStudentsModal";
import { useQuestionsData } from "screens/TeacherClassrooms/shared/hooks/useQuestionsData";

interface IQuestionsTabProps {
  classroom: IClassroom;
  assignment: IAssignment;
}

export const QuestionsTab: React.FC<IQuestionsTabProps> = ({
  assignment,
  classroom,
}) => {
  usePageTrack(
    AnalyticsPage.TeacherDashboard_ClassroomDetail_AssignmentDetail_Questions
  );

  const classroomStudentsFetch = useFetchClassroomUsers({
    classroom_id: classroom.id,
    group_roles: [UsersGroupsRole.Member],
  });
  const itemsFetch = useFetchPracticeSetItems({
    practice_set_id: assignment?.practice_set_id || "0",
  });
  const recentResponsesFetch = useFetchClassroomRecentPracticeSetResponses({
    classroom_id: classroom.id,
    assignment_id: assignment.id,
    practice_set_id: assignment.practice_set_id,
  });

  const questionsData = useQuestionsData({
    assignmentId: assignment.id,
    classroomId: classroom.id,
    practiceSetId: assignment.practice_set_id,
    practiceSetItemsData: itemsFetch.data,
    classroomPracticeSetResponses: recentResponsesFetch.data,
  });

  const { drawingResultsModal, studentsModal, handleViewStudentResponses } =
    useStudentsModal({
      classroomPracticeSetResponses: recentResponsesFetch.data,
      classroomStudentsData: classroomStudentsFetch.data,
    });

  const isLoading = itemsFetch.isLoading || questionsData.isLoading;

  return (
    <>
      {isLoading ? (
        <Box mt={pxToRem(20)}>
          <LoadingSpinner />
        </Box>
      ) : (
        <AdminQuestionsTab
          questionsData={questionsData.questionsData}
          handleViewStudentResponses={handleViewStudentResponses}
        />
      )}
      {studentsModal}
      {drawingResultsModal}
    </>
  );
};
