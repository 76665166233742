import React from "react";
import { Route, Switch } from "react-router-dom";

import { PracticeSetAvailability } from "links/lib/types";
import TeacherPracticeSetPreviewSession from "screens/TeacherPracticeSetPreviewSession";

import { LibraryRoot } from "./LibraryRoot";
import { PracticeSetDetail } from "./PracticeSetDetail";

const TeacherPublicLibrary: React.FC = () => {
  return (
    <Switch>
      <Route path="/t/my-school-library/sets/:id/preview">
        <TeacherPracticeSetPreviewSession />
      </Route>
      <Route path="/t/my-school-library/sets/:id">
        <PracticeSetDetail availability={PracticeSetAvailability.Domain} />
      </Route>
      <Route path="/t/my-school-library">
        <LibraryRoot availability={PracticeSetAvailability.Domain} />
      </Route>
      <Route path="/t/public-library/sets/:id/preview">
        <TeacherPracticeSetPreviewSession />
      </Route>
      <Route path="/t/public-library/sets/:id">
        <PracticeSetDetail availability={PracticeSetAvailability.Public} />
      </Route>
      <Route path="/t/public-library">
        <LibraryRoot availability={PracticeSetAvailability.Public} />
      </Route>
    </Switch>
  );
};

export default TeacherPublicLibrary;
