import { Box } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { EmptyCard } from "adminComponents/molecules/EmptyCard";
import { StandardsCard } from "adminComponents/molecules/StandardsCard";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { IStandard } from "links/lib/types";

export interface IStandardProps {
  accuracy?: number;
  standard: IStandard;
}

interface IProps {
  standardsData?: IStandardProps[];
  hasNoPremiumAccess?: boolean;
}

export const StandardsTab: React.FC<IProps> = ({
  standardsData,
  hasNoPremiumAccess,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  return (
    <Box mt={pxToRem(40)}>
      {standardsData && standardsData.length > 0 ? (
        standardsData.map((standard, i) => (
          <Box key={i} mb={pxToRem(12)}>
            <StandardsCard
              {...standard}
              accuracyType="student"
              key={i}
              hasNoPremiumAccess={hasNoPremiumAccess}
            />
          </Box>
        ))
      ) : (
        <EmptyCard
          text={t("studentDetailEmptyCards.standardsText")}
          title={t("studentDetailEmptyCards.standardsTitle")}
        />
      )}
    </Box>
  );
};
