import EventEmitter from "events";
import { useCallback, useEffect, useRef, useState } from "react";

import { config } from "../../constants";
import {
  IPosition,
  ISessionDrawStroke,
  ISessionWebSocketMessage,
  ISessionWebSocketState,
  OnDemandIntent,
  SessionChatMessage,
  SessionGameType,
  SessionGroupNamePart,
  SessionReaction,
  SessionScene,
  SessionWebSocketAudience,
  SessionWebSocketEvent,
  SessionWebSocketMessageType,
} from "../../types";
import useReconnectingWebSocket from "./useReconnectingWebSocket";

type SessionWebSocketServerMessage = {
  result: ISessionWebSocketMessage;
};

export type UseSessionWebSocketArgs = {
  authToken: string;
  wsOrigin: string;
  sessionCode: string | null;
};

export type UseSessionWebSocketResult = {
  state: ISessionWebSocketState;
  events: EventEmitter;
  connect: () => void;
  reconnect: (onConnect?: () => void) => void;
  changeDrawColor: (drawColor: string) => void;
  changeGroupName: (group_name_part: SessionGroupNamePart) => void;
  isConnected: boolean;
  confirmAnswer: () => void;
  initiateGame: () => void;
  initiateGrouping: () => void;
  initiateLeaderBoard: () => void;
  initiateNextRound: () => void;
  initiatePriorRound: () => void;
  initiateOuterGameIntro: () => void;
  initiateOuterGame: () => void;
  initiatePodium: () => void;
  initiatePrizeRound: () => void;
  initiatePrizeRoundAwards: () => void;
  initiateRoundReview: () => void;
  initiateVoting: () => void;
  initiateVotingResults: () => void;
  initiateVotingAwards: () => void;
  joinSession: (sessionCode: string, replaceUserId?: string) => void;
  placeTheBigBoardToken: (spaceId: number) => void;
  setChoice: (choice_id: string, is_selected: boolean) => void;
  sendChatMessage: (message: SessionChatMessage) => void;
  sendCursor: (x: number, y: number) => void;
  sendDiagramLabelDrag: (
    label_id: string,
    pos: { x: number; y: number }
  ) => void;
  sendDrawStroke: (stroke: ISessionDrawStroke) => void;
  sendDrawImageUrl: (imageUrl: string) => void;
  sendReaction: (reaction: SessionReaction) => void;
  sendSortChoiceDrag: (
    choice_id: string,
    pos: { x: number; y: number }
  ) => void;
  sendTextPartDrag: (part_id: string, pos: { x: number; y: number }) => void;
  sendTextResponse: (text: string) => void;
  setDiagramLabelZone: (label_id: string, zone_id: string) => void;
  setSortChoiceZone: (choice_id: string, zone_id: string) => void;
  setTextPartZone: (part_id: string, zone_id: string) => void;
  stopTheBigBoardScanning: (rowIndex: number) => void;
  setVote: (group_id?: string) => void;
  setDrawingHidden: (group_id: string, hidden: boolean) => void;
  sendActiveTyping: () => void;
  putSessionState: (session_state: ISessionWebSocketState) => void;
  undoDrawStroke: () => void;
  extendRoundTime: () => void;
  exitGame: () => void;
  shuffleGuestName: () => void;
  avatarUpdated: () => void;
  guestSignedIn: (guest_user_id: string) => void;
};

export default function useSessionWebSocket({
  authToken,
  wsOrigin,
  sessionCode,
}: UseSessionWebSocketArgs): UseSessionWebSocketResult {
  const events = useRef<EventEmitter>(new EventEmitter());
  const [queue, setQueue] = useState<Array<string>>([]);
  const [state, setState] = useState<ISessionWebSocketState>({
    scene: SessionScene.Lobby,
    game_state: {
      game_type: SessionGameType.TheBigBoard,
      the_big_board_state: {
        rows: [],
        round_placed_group_ids: {},
        round_allowed_group_ids: [],
        round_token_control_user_ids: [],
        prize_round_user_id: "0",
        prize_round_points: 0,
      },
      final_round_number: 0,
    },
    users: {},
    teachers: {},
    groups: {},
    round_group_state: {},
    round_groups_state: {},
    server_time_deltas: [],
    round_state: null,
    custom_groups_partially_applied: false,
  });

  const parseInboundMessage = (
    message: MessageEvent<string>
  ): SessionWebSocketServerMessage => {
    let messageData = message.data;

    // This middleware rewrites response data, replacing instances
    // of an old URL with a new URL. The purpose of this is that
    // we serve full URLs for Files/Avatars assets in
    // several places and need to support giantsteps.app and
    // practice.peardeck.com prefixes concurrently until we cut
    // over to practice.peardeck.com. After the cut-over, we should
    // run a migration to update all asset URLs and remove this
    // middleware.
    if (
      config.axiosRewriteFilesEnabled &&
      config.axiosRewriteFilesUrlOldRegExp &&
      config.axiosRewriteFilesUrlNew
    ) {
      messageData = messageData.replace(
        config.axiosRewriteFilesUrlOldRegExp,
        config.axiosRewriteFilesUrlNew
      );
    }
    if (
      config.axiosRewriteAvatarsEnabled &&
      config.axiosRewriteAvatarsUrlOldRegExp &&
      config.axiosRewriteAvatarsUrlNew
    ) {
      messageData = messageData.replace(
        config.axiosRewriteAvatarsUrlOldRegExp,
        config.axiosRewriteAvatarsUrlNew
      );
    }

    return JSON.parse(messageData) as SessionWebSocketServerMessage;
  };

  const onMessage = useCallback((message: MessageEvent<string>) => {
    const { result } = parseInboundMessage(message);

    if (!result) {
      console.warn("useSessionWebSocket onMessage: No result object");
      return;
    }

    if (result.error) {
      events.current.emit(SessionWebSocketEvent.Error, result.error);
      setState((prevState: ISessionWebSocketState) => {
        return {
          ...prevState,
          error: result.error || "",
          error_code: result.error_code || 0,
        };
      });
      return;
    }

    switch (result.type) {
      case SessionWebSocketMessageType.InvalidType:
        throw new Error(result.error);
      case SessionWebSocketMessageType.JoinSessionResponse:
        setState((prevState: ISessionWebSocketState) => {
          if (!result.session || !result.state) return prevState;

          return {
            ...prevState,
            session: {
              code: result.session.code,
              id: result.session.id,
              group_id: result.session.group_id,
              group_name: result.session.group_name,
              creator_name: result.session.creator_name,
              practice_set_id: result.session.practice_set_id,
              practice_set_title: result.session.practice_set_title,
              practice_set_is_how_to_play:
                result.session.practice_set_is_how_to_play,
              practice_set_is_digital_citizenship:
                result.session.practice_set_is_digital_citizenship,
              session_type: result.session.session_type,
              seconds_per_round: result.session.seconds_per_round,
              practice_set_cover_image_bg_color_scheme:
                result.session.practice_set_cover_image_background_color_scheme,
              practice_set_cover_image_icon:
                result.session.practice_set_cover_image_icon,
              practice_set_cover_image_bg_pattern:
                result.session.on_demand_intent !== OnDemandIntent.NONE
                  ? "REMIX"
                  : result.session.practice_set_cover_image_background_pattern,
              xp_multiplier: result.session.xp_multiplier,
            },
            scene: result.state.scene,
            round_state: result.state.round_state,
            game_state: result.state.game_state,
            users:
              result.state && result.state.users
                ? result.state.users
                : prevState.users,
            teachers:
              result.state && result.state.teachers
                ? result.state.teachers
                : prevState.teachers,
            groups:
              result.state && result.state.groups
                ? result.state.groups
                : prevState.groups,
            round_group_state:
              result.state && result.state.round_group_state
                ? result.state.round_group_state
                : prevState.round_group_state,
            round_groups_state:
              result.state && result.state.round_groups_state
                ? result.state.round_groups_state
                : prevState.round_groups_state,
            custom_groups_partially_applied: result.state
              ? result.state.custom_groups_partially_applied
              : prevState.custom_groups_partially_applied,
          };
        });
        break;
      case SessionWebSocketMessageType.GameStateUpdate:
        setState((prevState: ISessionWebSocketState) => {
          if (!result.state) return prevState;

          return {
            ...prevState,
            game_state: result.state.game_state,
          };
        });
        break;
      case SessionWebSocketMessageType.GroupsMutation:
        setState((prevState: ISessionWebSocketState) => {
          return {
            ...prevState,
            groups:
              result.state && result.state.groups
                ? result.state.groups
                : prevState.groups,
            custom_groups_partially_applied: result.state
              ? result.state.custom_groups_partially_applied
              : prevState.custom_groups_partially_applied,
          };
        });
        break;
      case SessionWebSocketMessageType.RoundUpdate:
        setState((prevState: ISessionWebSocketState) => {
          if (!result.state || !result.state.round_state) return prevState;

          return {
            ...prevState,
            round_state: result.state.round_state,
          };
        });
        break;
      case SessionWebSocketMessageType.RoundGroupStateUpdate:
        setState((prevState: ISessionWebSocketState) => {
          if (!result.state || !result.state.round_group_state)
            return prevState;

          return {
            ...prevState,
            round_group_state: result.state.round_group_state,
          };
        });
        break;
      case SessionWebSocketMessageType.RoundGroupsStateUpdate:
        setState((prevState: ISessionWebSocketState) => {
          if (!result.state || !result.state.round_groups_state)
            return prevState;

          return {
            ...prevState,
            round_groups_state: result.state.round_groups_state,
          };
        });
        break;
      case SessionWebSocketMessageType.SceneUpdate:
        setState((prevState: ISessionWebSocketState) => {
          return {
            ...prevState,
            scene: result.state?.scene || prevState.scene,
          };
        });
        break;
      case SessionWebSocketMessageType.SendChatMessage:
        if (result.sender_id && result.chat_message) {
          const { sender_id, chat_message } = result;
          events.current.emit(SessionWebSocketEvent.ChatMessage, {
            sender_id,
            chat_message,
          });
        }
        break;
      case SessionWebSocketMessageType.SendReaction:
        if (result.sender_id && result.user_data?.reaction) {
          const { sender_id, user_data } = result;

          events.current.emit(SessionWebSocketEvent.Reaction, {
            sender_id,
            reaction: user_data?.reaction,
          });
        }
        break;
      case SessionWebSocketMessageType.UsersMutation:
        setState((prevState: ISessionWebSocketState) => {
          return {
            ...prevState,
            users:
              result.state && result.state.users
                ? result.state.users
                : prevState.users,
            teachers:
              result.state && result.state.teachers
                ? result.state.teachers
                : prevState.teachers,
          };
        });
        break;
      case SessionWebSocketMessageType.UpdateCursor: {
        setState((prevState: ISessionWebSocketState) => {
          if (!result.cursor) {
            return prevState;
          }
          if (!result.sender_id) {
            return prevState;
          }
          if (!prevState.users) {
            return prevState;
          }

          const users = prevState.users;

          const user = users[result.sender_id];
          if (!user) {
            return prevState;
          }

          user.cursor = {
            ...result.cursor,
            timestamp: result.cursor.timestamp || "",
          };

          return {
            ...prevState,
            users,
          };
        });
        break;
      }
      case SessionWebSocketMessageType.UpdateActiveTyping: {
        setState((prevState: ISessionWebSocketState) => {
          if (!result.sender_id) {
            return prevState;
          }
          if (!prevState.users) {
            return prevState;
          }

          const users = prevState.users;

          const user = users[result.sender_id];
          if (!user) {
            return prevState;
          }

          user.last_active_typing = new Date();

          return {
            ...prevState,
            users,
          };
        });
        break;
      }
      case SessionWebSocketMessageType.Heartbeat: {
        if (!result.server_time) {
          break;
        }

        const delta = Date.now() - new Date(result.server_time).valueOf();

        setState((prevState: ISessionWebSocketState) => {
          const newServerTimeDeltas = [...prevState.server_time_deltas];

          if (newServerTimeDeltas.length < 5) {
            newServerTimeDeltas.push(delta);
          } else {
            newServerTimeDeltas.shift();
            newServerTimeDeltas.push(delta);
          }
          return {
            ...prevState,
            server_time_deltas: newServerTimeDeltas,
          };
        });
        break;
      }
      case SessionWebSocketMessageType.Reward:
        if (result.user_rewards && result.user_rewards.length) {
          events.current.emit(SessionWebSocketEvent.UserRewards, {
            user_rewards: result.user_rewards,
          });
        }

        break;
      case SessionWebSocketMessageType.ExitGameResponse:
        events.current.emit(SessionWebSocketEvent.ExitGameResponse);

        break;
      default:
        throw new Error(
          `Invalid WebSocket server message type: ${result.type}`
        );
    }
  }, []);

  const onError = useCallback((e: Event) => {
    events.current.emit(
      SessionWebSocketEvent.Error,
      new Error((e as ErrorEvent).message || "Websocket Error")
    );
  }, []);

  const onConnect = useCallback(() => {
    events.current.emit(SessionWebSocketEvent.Connect);
  }, []);

  const onReconnect = useCallback(() => {
    events.current.emit(SessionWebSocketEvent.Reconnect);
  }, []);

  const onClose = useCallback((ev: CloseEvent, isIntended: boolean) => {
    events.current.emit(SessionWebSocketEvent.Disconnect, {
      ev,
      isIntended,
    });
  }, []);

  const { ws, connect, connected } = useReconnectingWebSocket({
    origin: wsOrigin,
    authToken,
    maxReconnectAttempts: 20,
    timeoutInterval: 2000,
    reconnectDecay: 1.5,
    reconnectInterval: 1000,
    debug: true,
    sessionCode: sessionCode,
    onMessage,
    onError,
    onConnect,
    onReconnect,
    onClose,
  });

  // Dequeue queued messages
  useEffect(() => {
    if (ws.current?.readyState && queue.length) {
      queue.forEach((message: string) => {
        ws.current?.send(message);
      });

      setQueue([]);
    }
    // eslint erroneously saying there's a missing dependency here
    // eslint-disable-next-line
  }, [connected, queue]);

  const enqueueMessage = (message: ISessionWebSocketMessage) => {
    setQueue((queue) => {
      return [...queue, JSON.stringify(message)];
    });
  };

  return {
    state: state,
    events: events.current,
    isConnected: connected,
    reconnect: (onConnect?: () => void) => {
      connect(true, onConnect);
    },
    changeDrawColor: (draw_color: string) => {
      enqueueMessage({
        type: SessionWebSocketMessageType.ChangeDrawColor,
        audience: SessionWebSocketAudience.None,
        user_data: {
          draw_color,
        },
      });
    },
    changeGroupName: (group_name_part: SessionGroupNamePart) => {
      enqueueMessage({
        type: SessionWebSocketMessageType.ChangeGroupNameRequest,
        audience: SessionWebSocketAudience.None,
        user_data: {
          group_name_part,
        },
      });
    },
    connect: () => {
      connect(false);
    },
    joinSession: (sessionCode: string, replaceUserId?: string) => {
      enqueueMessage({
        type: SessionWebSocketMessageType.JoinSessionRequest,
        audience: SessionWebSocketAudience.None,
        session: {
          code: sessionCode,
        },
        user_data: {
          guest_user_id: replaceUserId,
        },
      });
    },
    sendCursor: (x: number, y: number) => {
      enqueueMessage({
        type: SessionWebSocketMessageType.UpdateCursor,
        audience: SessionWebSocketAudience.Group,
        cursor: {
          x,
          y,
        },
      });
    },
    initiateGame: () => {
      enqueueMessage({
        type: SessionWebSocketMessageType.InitiateGame,
        audience: SessionWebSocketAudience.None,
      });
    },
    initiateGrouping: () => {
      enqueueMessage({
        type: SessionWebSocketMessageType.InitiateGrouping,
        audience: SessionWebSocketAudience.None,
      });
    },
    initiateLeaderBoard: () => {
      enqueueMessage({
        type: SessionWebSocketMessageType.InitiateLeaderBoard,
        audience: SessionWebSocketAudience.None,
      });
    },
    initiateNextRound: () => {
      enqueueMessage({
        type: SessionWebSocketMessageType.InitiateNextRound,
        audience: SessionWebSocketAudience.None,
        user_data: {
          next_round_number:
            (state &&
              state.round_state &&
              state.round_state.round_number + 1) ||
            1,
        },
      });
    },
    initiatePriorRound: () => {
      enqueueMessage({
        type: SessionWebSocketMessageType.InitiatePriorRound,
        audience: SessionWebSocketAudience.None,
      });
    },
    initiateOuterGameIntro: () => {
      enqueueMessage({
        type: SessionWebSocketMessageType.InitiateOuterGameIntro,
        audience: SessionWebSocketAudience.None,
      });
    },
    initiateOuterGame: () => {
      enqueueMessage({
        type: SessionWebSocketMessageType.InitiateOuterGame,
        audience: SessionWebSocketAudience.None,
      });
    },
    initiatePodium: () => {
      enqueueMessage({
        type: SessionWebSocketMessageType.InitiatePodium,
        audience: SessionWebSocketAudience.None,
      });
    },
    initiatePrizeRound: () => {
      enqueueMessage({
        type: SessionWebSocketMessageType.InitiatePrizeRound,
        audience: SessionWebSocketAudience.None,
      });
    },
    initiatePrizeRoundAwards: () => {
      enqueueMessage({
        type: SessionWebSocketMessageType.InitiatePrizeRoundAwards,
        audience: SessionWebSocketAudience.None,
      });
    },
    initiateRoundReview: () => {
      enqueueMessage({
        type: SessionWebSocketMessageType.InitiateRoundReview,
        audience: SessionWebSocketAudience.None,
        user_data: {
          next_round_number:
            (state &&
              state.round_state &&
              state.round_state.round_number + 1) ||
            1,
        },
      });
    },
    initiateVoting: () => {
      enqueueMessage({
        type: SessionWebSocketMessageType.InitiateVoting,
        audience: SessionWebSocketAudience.None,
      });
    },
    initiateVotingResults: () => {
      enqueueMessage({
        type: SessionWebSocketMessageType.InitiateVotingResults,
        audience: SessionWebSocketAudience.None,
      });
    },
    initiateVotingAwards: () => {
      enqueueMessage({
        type: SessionWebSocketMessageType.InitiateVotingAwards,
        audience: SessionWebSocketAudience.None,
      });
    },
    placeTheBigBoardToken: (spaceId: number) => {
      enqueueMessage({
        type: SessionWebSocketMessageType.PlaceTheBigBoardToken,
        audience: SessionWebSocketAudience.None,
        user_data: {
          the_big_board_space_id: spaceId,
        },
      });
    },
    setChoice: (choice_id: string, is_selected: boolean) => {
      enqueueMessage({
        type: SessionWebSocketMessageType.SetChoice,
        audience: SessionWebSocketAudience.None,
        user_data: {
          choice_id,
          is_selected,
        },
      });
    },
    sendChatMessage: (message: SessionChatMessage) => {
      enqueueMessage({
        type: SessionWebSocketMessageType.SendChatMessage,
        audience: SessionWebSocketAudience.Group,
        chat_message: message,
      });
    },
    confirmAnswer: () => {
      enqueueMessage({
        type: SessionWebSocketMessageType.ConfirmAnswer,
        audience: SessionWebSocketAudience.None,
      });
    },
    sendTextPartDrag: (part_id: string, pos: IPosition) => {
      enqueueMessage({
        type: SessionWebSocketMessageType.SendTextPartDrag,
        audience: SessionWebSocketAudience.None,
        user_data: {
          text_part_id: part_id,
          position: pos,
        },
      });
    },
    setTextPartZone: (part_id: string, zone_id: string) => {
      enqueueMessage({
        type: SessionWebSocketMessageType.SetTextPartZone,
        audience: SessionWebSocketAudience.None,
        user_data: {
          text_part_id: part_id,
          zone_id,
        },
      });
    },
    sendTextResponse: (text: string) => {
      enqueueMessage({
        type: SessionWebSocketMessageType.SendTextResponse,
        audience: SessionWebSocketAudience.None,
        user_data: {
          text_response: text,
        },
      });
    },
    sendSortChoiceDrag: (choice_id: string, pos: IPosition) => {
      enqueueMessage({
        type: SessionWebSocketMessageType.SendSortChoiceDrag,
        audience: SessionWebSocketAudience.None,
        user_data: {
          choice_id,
          position: pos,
        },
      });
    },
    setSortChoiceZone: (choice_id: string, zone_id: string) => {
      enqueueMessage({
        type: SessionWebSocketMessageType.SetSortChoiceZone,
        audience: SessionWebSocketAudience.None,
        user_data: {
          choice_id,
          zone_id,
        },
      });
    },
    sendDiagramLabelDrag: (label_id: string, pos: IPosition) => {
      enqueueMessage({
        type: SessionWebSocketMessageType.SendDiagramLabelDrag,
        audience: SessionWebSocketAudience.None,
        user_data: {
          label_id,
          position: pos,
        },
      });
    },
    setDiagramLabelZone: (label_id: string, zone_id: string) => {
      enqueueMessage({
        type: SessionWebSocketMessageType.SetDiagramLabelZone,
        audience: SessionWebSocketAudience.None,
        user_data: {
          label_id,
          zone_id,
        },
      });
    },
    sendDrawStroke: (stroke: ISessionDrawStroke) => {
      enqueueMessage({
        type: SessionWebSocketMessageType.SendDrawStroke,
        audience: SessionWebSocketAudience.None,
        user_data: {
          draw_stroke: stroke,
        },
      });
    },
    sendDrawImageUrl: (imageUrl: string) => {
      enqueueMessage({
        type: SessionWebSocketMessageType.SendDrawImageUrl,
        audience: SessionWebSocketAudience.None,
        user_data: {
          draw_image_url: imageUrl,
        },
      });
    },
    sendReaction: (reaction: SessionReaction) => {
      enqueueMessage({
        type: SessionWebSocketMessageType.SendReaction,
        audience: SessionWebSocketAudience.All,
        user_data: {
          reaction,
        },
      });
    },
    stopTheBigBoardScanning: (rowIndex: number) => {
      enqueueMessage({
        type: SessionWebSocketMessageType.StopTheBigBoardScanning,
        audience: SessionWebSocketAudience.None,
        user_data: {
          the_big_board_row_index: rowIndex,
        },
      });
    },
    setVote: (group_id?: string) => {
      enqueueMessage({
        type: SessionWebSocketMessageType.SetVote,
        audience: SessionWebSocketAudience.None,
        user_data: {
          group_id_vote: group_id,
        },
      });
    },
    setDrawingHidden: (group_id: string, hidden: boolean) => {
      enqueueMessage({
        type: SessionWebSocketMessageType.SetDrawingHidden,
        audience: SessionWebSocketAudience.None,
        user_data: {
          hide_drawing: {
            group_id,
            hidden,
          },
        },
      });
    },
    sendActiveTyping: () => {
      enqueueMessage({
        type: SessionWebSocketMessageType.UpdateActiveTyping,
        audience: SessionWebSocketAudience.Group,
      });
    },
    putSessionState: (session_state: ISessionWebSocketState) => {
      enqueueMessage({
        type: SessionWebSocketMessageType.PutSessionState,
        audience: SessionWebSocketAudience.None,
        user_data: {
          session_state,
        },
      });
    },
    undoDrawStroke: () => {
      enqueueMessage({
        type: SessionWebSocketMessageType.UndoDrawStroke,
        audience: SessionWebSocketAudience.None,
      });
    },
    extendRoundTime: () => {
      enqueueMessage({
        type: SessionWebSocketMessageType.ExtendRoundTime,
        audience: SessionWebSocketAudience.None,
        extend_time_by_seconds: 30,
      });
    },
    exitGame: () => {
      enqueueMessage({
        type: SessionWebSocketMessageType.ExitGameRequest,
        audience: SessionWebSocketAudience.None,
      });
    },
    shuffleGuestName: () => {
      enqueueMessage({
        type: SessionWebSocketMessageType.ShuffleGuestName,
        audience: SessionWebSocketAudience.None,
      });
    },
    avatarUpdated: () => {
      enqueueMessage({
        type: SessionWebSocketMessageType.AvatarUpdated,
        audience: SessionWebSocketAudience.None,
      });
    },
    guestSignedIn: (guest_user_id: string) => {
      enqueueMessage({
        type: SessionWebSocketMessageType.GuestSignedIn,
        audience: SessionWebSocketAudience.None,
        user_data: {
          guest_user_id,
        },
      });
    },
  };
}
