import { pxToRem } from "adminComponents/utils/pxToRem";

export const space = {
  admin: {
    desktopXPadding: pxToRem(40),
    mobileXPadding: pxToRem(16),
    flyout: {
      desktopXPadding: pxToRem(58),
      mobileXPadding: pxToRem(15),
    },
    cards: {
      xxs: pxToRem(16),
      xs: pxToRem(24),
      small: pxToRem(30),
      smallWithBanner: pxToRem(100),
      medium: pxToRem(40),
      large: pxToRem(58),
      xl: pxToRem(62),
      xxl: pxToRem(70),
    },
  },
};
