import { Portal } from "@chakra-ui/react";
import React, { useCallback } from "react";

import { SeasonMapSoundEffect } from "links/lib/types";
import { useAudio } from "sharedComponents/contexts/audio";

import { FullscreenRiveTakeover } from "../FullscreenRiveTakover";
import GolfCartRiveSrc from "./resource/s4_zone_unlock.riv";

export interface ISeason4MapZoneUnlock {
  show: boolean;
  zoneCount: number;
  handleComplete: () => void;
}

const stateMachines = ["zone2-unlock", "zone3-unlock"];

export const Season4MapZoneUnlock: React.FC<ISeason4MapZoneUnlock> = ({
  show,
  handleComplete,
  zoneCount,
}) => {
  const { play } = useAudio();

  const handlePlay = useCallback(() => {
    play(SeasonMapSoundEffect.S4ZoneUnlock);
  }, [play]);

  return (
    <Portal>
      <FullscreenRiveTakeover
        handleEnd={handleComplete}
        handlePlay={handlePlay}
        show={show}
        bgColor="#6646BB"
        src={GolfCartRiveSrc}
        lengthInMillis={14000}
        stateMachines={stateMachines}
        animations={zoneCount < 3 ? "clapboard-zone2" : "clapboard-zone3"}
      />
    </Portal>
  );
};
