import { Analytics, AnalyticsBrowser } from "@segment/analytics-next";
import { utm } from "@segment/analytics-next/dist/cjs/plugins/segmentio/normalize";
import { DebouncedFunc, throttle } from "lodash";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocalStorage, useMount } from "react-use";

export interface IAnalyticsProviderState {
  analytics?: Analytics;
}

interface AnalyticsEvent {
  name: string;
  properties: Record<string, unknown>;
  options?: {
    traits?: Record<string, unknown>;
    integrations?: Record<string, unknown>;
  };
}

export interface IAnalyticsContext {
  analytics?: Analytics;
  userSessionId?: number;
  trackEvent: (
    name: string,
    properties: Record<string, unknown>,
    options?: {
      traits?: Record<string, unknown>;
      integrations?: Record<string, unknown>;
      // queue event to send if analytics aren't loaded yet
      queueEvent?: boolean;
    }
  ) => void;
  trackPage: (name: string, properties: Record<string, unknown>) => void;
  group: (userId: string, properties: Record<string, unknown>) => void;
  identify: (userId: string, properties: Record<string, unknown>) => void;
}

export const AnalyticsContext = createContext<IAnalyticsContext>({
  trackEvent: () => {
    throw new Error("Inop");
  },
  trackPage: () => {
    throw new Error("Inop");
  },
  group: () => {
    throw new Error("Inop");
  },
  identify: () => {
    throw new Error("Inop");
  },
});

const WRITE_KEY = "0nBoSYFBXhf20Y8FFh7h568W4IE8PtiH";
const USER_SESSION_ID_KEY = "USER_SESSION_ID";
const USER_SESSION_UPDATE_TIMESTAMP_KEY = "USER_SESSION_UPDATE_TIMESTAMP";
const UTM_DATA_KEY = "UTM_DATA";
const UTM_DATA_EXPIRATION_DAYS = 30;

const numDeserializer = (value: string): number =>
  parseInt(value, 10) || Date.now();
const numSerializer = (value: number): string => value.toString();

// Deserializes UTM data and returns it if the data is valid and hasn't expired.
const utmDeserializer = (
  value: string
): Record<string, unknown> | undefined => {
  if (!value) return;

  const utmData = JSON.parse(value);

  const expiration = utmData["expiration"];
  if (!expiration) return;

  const expirationDate = new Date(expiration);
  if (expirationDate < new Date()) return;

  delete utmData["expiration"];

  return utmData;
};

// Serializes UTM data along with an expiration date
const utmSerializer = (value: Record<string, unknown> | undefined): string => {
  if (!value) return "";

  const date = new Date();
  const expiration = date.setDate(date.getDate() + UTM_DATA_EXPIRATION_DAYS);

  return JSON.stringify({
    ...value,
    expiration: expiration,
  });
};

const sessionTimeout = 60000 * 30; // 30 minutes
const localStoreThrottleTimeout = 60000 * 1; // 1 minute

export const AnalyticsProvider: React.FC = ({ children }) => {
  const [state, setState] = useState<IAnalyticsProviderState>({});
  const [eventQueue, setEventQueue] = useState<Array<AnalyticsEvent>>([]);
  const [queuedEventsSending, setQueuedEventsSending] =
    useState<boolean>(false);
  const [userSessionId, setUserSessionId] = useLocalStorage(
    USER_SESSION_ID_KEY,
    Date.now(),
    {
      deserializer: numDeserializer,
      serializer: numSerializer,
      raw: false,
    }
  );
  const [lastUserSessionUpdate, setLastUserSessionUpdate] = useLocalStorage(
    USER_SESSION_UPDATE_TIMESTAMP_KEY,
    0,
    {
      deserializer: numDeserializer,
      serializer: numSerializer,
      raw: false,
    }
  );
  const [storedUtmData, setStoredUtmData] = useLocalStorage<
    Record<string, unknown> | undefined
  >(UTM_DATA_KEY, undefined, {
    deserializer: utmDeserializer,
    serializer: utmSerializer,
    raw: false,
  });

  const loadAndPersistUtmData = () => {
    const urlUtmData = utm(window.location.href);
    if (Object.keys(urlUtmData).length > 0) {
      setStoredUtmData(urlUtmData);
    }
  };

  useMount(() => {
    loadAndPersistUtmData();

    const loadAnalytics = async () => {
      const [analytics] = await AnalyticsBrowser.load({ writeKey: WRITE_KEY });

      // Allows other integrations to use segment analytics (e.g. AppCues)
      // eslint-disable-next-line
      (window as any).analytics = analytics;

      setState({ analytics });
    };

    getUserSessionId();

    loadAnalytics();
  });

  const refreshUserSessionUpdateTimestamp = useRef<
    DebouncedFunc<(val: number) => void>
  >(
    throttle(
      (val: number) => {
        setLastUserSessionUpdate(val);
      },
      localStoreThrottleTimeout,
      { leading: true, trailing: true }
    )
  );

  /**
   * Check for session expiration and generate new session
   * if it has expired. Update session update timestamp.
   */
  const getUserSessionId = useCallback(() => {
    const now = Date.now();

    let sid = userSessionId || -1;

    if (
      !userSessionId ||
      !lastUserSessionUpdate ||
      now - lastUserSessionUpdate > sessionTimeout
    ) {
      // consider this a new session
      setUserSessionId(now);
      sid = now;
    }

    refreshUserSessionUpdateTimestamp.current(now);

    return sid;
  }, [lastUserSessionUpdate, setUserSessionId, userSessionId]);

  const analyticsTrack = useCallback(
    (event: AnalyticsEvent) => {
      const sid = getUserSessionId();

      state.analytics?.track(event.name, event.properties, {
        campaign: storedUtmData && {
          ...storedUtmData,
        },
        traits: {
          ...event.options?.traits,
        },
        integrations: {
          ...event.options?.integrations,
          Amplitude: {
            session_id: sid || -1,
          },
        },
      });
    },
    [getUserSessionId, state.analytics, storedUtmData]
  );

  useEffect(() => {
    if (state.analytics && eventQueue.length > 0 && !queuedEventsSending) {
      setQueuedEventsSending(true);

      eventQueue.forEach((event) => {
        analyticsTrack(event);
      });

      setEventQueue([]);
      setQueuedEventsSending(false);
    }
  }, [state.analytics, eventQueue, queuedEventsSending, analyticsTrack]);

  const value = useMemo(() => {
    return {
      analytics: state.analytics,
      userSessionId,
      trackEvent: (
        name: string,
        properties: Record<string, unknown>,
        options?: {
          traits?: Record<string, unknown>;
          integrations?: Record<string, unknown>;
          queueEvent?: boolean;
        }
      ) => {
        if (state.analytics?.track) {
          analyticsTrack({ name, properties, options });
        } else if (options?.queueEvent) {
          setEventQueue((eventQueue) => [
            ...eventQueue,
            { name, properties, options },
          ]);
        }
      },
      trackPage: (name: string, properties: Record<string, unknown>) => {
        const sid = getUserSessionId();

        state.analytics?.page(name, properties, {
          campaign: storedUtmData && {
            ...storedUtmData,
          },
          integrations: {
            Amplitude: {
              session_id: sid || -1,
            },
          },
        });
      },
      group: (groupId: string, properties: Record<string, unknown>) => {
        const sid = getUserSessionId();

        state.analytics?.group(groupId, properties, {
          campaign: storedUtmData && {
            ...storedUtmData,
          },
          integrations: {
            Amplitude: {
              session_id: sid || -1,
            },
          },
        });
      },
      identify: (userId: string, properties: Record<string, unknown>) => {
        const sid = getUserSessionId();

        state.analytics?.identify(userId, properties, {
          campaign: storedUtmData && {
            ...storedUtmData,
          },
          integrations: {
            Amplitude: {
              session_id: sid || -1,
            },
          },
        });
      },
    };
  }, [state, getUserSessionId, storedUtmData, userSessionId, analyticsTrack]);

  return (
    <AnalyticsContext.Provider value={value}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalytics = (): IAnalyticsContext => {
  return useContext(AnalyticsContext);
};

export const usePageTrack = (name?: string): void => {
  const { trackPage, analytics } = useAnalytics();

  useEffect(() => {
    if (analytics && name) trackPage(name, {});
  }, [analytics, name, trackPage]);
};
