import React, { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { ConfirmModal } from "adminComponents/molecules/ConfirmModal";
import {
  ToastId,
  useErrorToast,
  usePendingToast,
  useShowToast,
} from "adminComponents/utils/toast";
import { useDeletePracticeSetItem as useDeletePracticeSetItemMutation } from "links/lib/features/practiceSetItems";
import { IPracticeSet, IPracticeSetItem } from "links/lib/types";

interface IArgs {
  practiceSet?: IPracticeSet;
}

interface IResult {
  handleDeleteIntent: (item: IPracticeSetItem) => void;
  modal: React.ReactElement;
}

const useDeletePracticeSetItem = ({ practiceSet }: IArgs): IResult => {
  const showToast = useShowToast();
  const { showPendingToast, closePendingToast } = usePendingToast();
  const { t } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "practiceSetDetailContainer.practiceSetItemDelete",
  });
  const pendingToastRef = useRef<ToastId | undefined>();

  const onSuccess = useCallback(() => {
    showToast(t("successToast"));
  }, [t, showToast]);

  const onSettled = useCallback(() => {
    if (pendingToastRef.current) {
      closePendingToast(pendingToastRef.current);
      pendingToastRef.current = undefined;
    }
  }, [closePendingToast]);

  const deletePracticeSetItem = useDeletePracticeSetItemMutation({
    onSettled,
    onSuccess,
  });

  useErrorToast(deletePracticeSetItem.error);

  const [state, setState] = useState<{
    isModalOpen: boolean;
    item?: IPracticeSetItem;
  }>({ isModalOpen: false });

  const handleDeleteIntent = useCallback((item: IPracticeSetItem) => {
    setState({ isModalOpen: true, item });
  }, []);

  const handleConfirmModalClose = useCallback(
    (confirm?: boolean) => {
      const { item } = state;

      if (!confirm || !item || !practiceSet) {
        setState({ isModalOpen: false });
        return;
      }

      deletePracticeSetItem.mutate({
        id: item.id,
        practice_set_id: practiceSet.id,
      });

      setState({ isModalOpen: false });

      pendingToastRef.current = showPendingToast(t("pendingToast"));
    },
    [deletePracticeSetItem, practiceSet, showPendingToast, state, t]
  );

  return useMemo(
    () => ({
      handleDeleteIntent,
      modal: (
        <ConfirmModal
          handleClose={handleConfirmModalClose}
          title={t("confirmTitle")}
          description={t("confirmDescription")}
          buttonCancelLabel={t("confirmCancelButton")}
          buttonOKLabel={t("confirmOKButton")}
          isOpen={state.isModalOpen}
          isLoading={deletePracticeSetItem.isLoading}
        />
      ),
    }),
    [
      handleDeleteIntent,
      state.isModalOpen,
      deletePracticeSetItem.isLoading,
      handleConfirmModalClose,
      t,
    ]
  );
};

export { useDeletePracticeSetItem };
