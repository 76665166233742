import { AxiosError } from "axios";
import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { IAssignment } from "../../types";

export interface IFetchAssignmentParams {
  id: string;
  disabled?: boolean;
  onError?: (err: AxiosError) => void;
}

export interface IFetchAssignmentResponse {
  assignment: IAssignment;
}

/**
 * Fetches the assignment corresponding to the provided ID
 * @returns
 */
export default function useFetchAssignment(
  params: IFetchAssignmentParams
): UseQueryResult<IFetchAssignmentResponse, AxiosError> {
  const axios = useAxios();

  const query = useQuery<IFetchAssignmentResponse, AxiosError>(
    ["assignments", { id: params.id }],
    async (): Promise<IFetchAssignmentResponse> => {
      return await axios
        .get(`/v1/assignments/${params.id}`)
        .then((r) => r.data as IFetchAssignmentResponse);
    },
    {
      enabled: !params.disabled,
      onError: params.onError,
    }
  );

  return query;
}
