import { Box, Text } from "@chakra-ui/react";
import lottie, {
  AnimationItem,
  AnimationSegment,
} from "lottie-web/build/player/lottie_light";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { animated, config, useSpring } from "react-spring";
import { useMount, usePrevious, useUnmount } from "react-use";

import { pxToRem } from "adminComponents/utils";
import { AnalyticsEvent, ChallengeType } from "links/lib/types";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { StudentSessionSoundEffect } from "sessionComponents/types";
import { useSessionAnalytics } from "sharedComponents/contexts/sessionAnalytics";
import { useSessionAudio } from "sharedComponents/contexts/sessionAudio";

import dailyChallengeData from "./resource/daily-challenge-completed.json";
import monthlyChallengeData from "./resource/monthly-challenge-completed.json";
import weeklyChallengeData from "./resource/weekly-challenge-completed.json";

export interface IPodiumChallengeProps {
  challengeType: ChallengeType;
  xpEarned?: number;
  handleComplete?: () => void;
}

interface IAnimationData {
  data: Record<string, unknown>;
  loopSegment?: AnimationSegment[];
  completeFrame: number;
  soundEffect: StudentSessionSoundEffect;
}

const animationDataMap: Record<ChallengeType, IAnimationData> = {
  [ChallengeType.Daily]: {
    data: dailyChallengeData,
    loopSegment: undefined,
    completeFrame: 170,
    soundEffect: StudentSessionSoundEffect.DailyChallengeComplete,
  },
  [ChallengeType.Weekly]: {
    data: weeklyChallengeData,
    loopSegment: [
      [0, 270],
      [210, 270],
    ],
    completeFrame: 190,
    soundEffect: StudentSessionSoundEffect.WeeklyChallengeComplete,
  },
  [ChallengeType.Monthly]: {
    data: monthlyChallengeData,
    loopSegment: [
      [0, 270],
      [210, 270],
    ],
    completeFrame: 190,
    soundEffect: StudentSessionSoundEffect.MonthlyChallengeComplete,
  },
};

const AnimatedBox = animated(Box);

export const PodiumChallenge: React.FC<IPodiumChallengeProps> = ({
  challengeType,
  xpEarned,
  handleComplete,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const animationRef = useRef<AnimationItem | null>(null);
  const [coreAnimFinished, setCoreAnimFinished] = useState<boolean>(false);
  const { match: currentBreakpoints } = useBreakpoints();
  const { play: playAudio } = useSessionAudio();
  const { track } = useSessionAnalytics();
  const { t } = useTranslation("session", {
    useSuspense: false,
    keyPrefix: "podium.challenge",
  });

  useMount(() => {
    track(AnalyticsEvent.Session_Podium_ViewChallenge, { challengeType });
  });

  const xpSpring = useSpring({
    from: {
      opacity: 0,
      xp: 0,
    },
    to: {
      opacity: coreAnimFinished ? 1 : 0,
      xp: coreAnimFinished ? xpEarned : 0,
    },
    config: config.slow,
  });

  const handleLoad = useCallback(
    (animationData: IAnimationData) => {
      if (!containerRef.current) {
        return;
      }

      setCoreAnimFinished(false);
      animationRef.current?.stop();
      animationRef.current?.destroy();
      const animationDataString = JSON.stringify(animationData.data);

      const anim = (animationRef.current = lottie.loadAnimation({
        container: containerRef.current,
        renderer: "svg",
        loop: animationData.loopSegment ? true : false,
        autoplay: false,
        animationData: JSON.parse(animationDataString),
      }));

      anim.addEventListener("DOMLoaded", () => {
        if (animationData.loopSegment) {
          anim.playSegments(animationData.loopSegment, true);
        } else {
          anim.play();
        }

        playAudio(animationData.soundEffect);
      });

      let hasFinishedCore = false;

      anim.addEventListener("enterFrame", ({ currentTime }) => {
        if (!hasFinishedCore && currentTime > animationData.completeFrame) {
          setCoreAnimFinished(true);
          hasFinishedCore = true;
          handleComplete?.();
        }
      });
    },
    [playAudio, handleComplete]
  );

  const prevChallengeType = usePrevious(challengeType);
  useEffect(() => {
    if (challengeType !== prevChallengeType) {
      handleLoad(animationDataMap[challengeType]);
    }
  }, [challengeType, prevChallengeType, handleLoad]);

  useUnmount(() => {
    animationRef.current?.stop();
    animationRef.current?.destroy();
    lottie.stop();
    lottie.destroy();
  });

  return (
    <Box
      w="full"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      h="full"
      position="relative"
    >
      <Box
        ref={containerRef}
        sx={{
          svg: {
            transform: "unset",
          },
        }}
        w="full"
        maxH="80%"
        maxW="full"
      />
      <AnimatedBox
        style={xpSpring}
        position="absolute"
        bottom="0"
        left="0"
        right="0"
        h="40%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        {challengeType === ChallengeType.Monthly ? (
          <Text
            textAlign="center"
            fontSize={pxToRem(currentBreakpoints.fontSize * 1.2)}
            textStyle="gameDisplay"
            fontWeight="700"
          >
            {t("carePackageEarned")}
          </Text>
        ) : (
          <Text
            textAlign="center"
            fontSize={pxToRem(currentBreakpoints.fontSize * 1.5)}
            textStyle="gameDisplay"
            fontWeight="700"
          >
            <AnimatedBox as="span">
              {xpSpring.xp.to((xp) => t("bonusXP", { xp: xp.toFixed(0) }))}
            </AnimatedBox>
          </Text>
        )}
      </AnimatedBox>
    </Box>
  );
};
