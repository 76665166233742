import { Box, Spinner } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { ColorScheme } from "adminComponents";
import { PracticeSessionItemVariantType, QuestionType } from "links/lib/types";
import { Checkbox } from "sessionComponents/atoms/Checkbox";
import { Icon, IconType } from "sessionComponents/atoms/Icon";

export interface IResponseIconProps {
  "aria-labelledby"?: string;
  showCheckbox: boolean;
  showRadio: boolean;
  variant: PracticeSessionItemVariantType;
  success?: boolean;
  error?: boolean;
  selected?: boolean;
  iconColor: ColorScheme;
  iconSize: string; // pxToRem converted value
  fontSize: string; // pxToRem converted value
  marginRight?: string; // pxToRem converted value
  onClick?: (checked: boolean) => void;
  isLoading?: boolean;
  isDisabled?: boolean;
  showTextInputIcon?: boolean;
  questionType?: QuestionType; //required for CoopDragsort variant
  spinnerWidth: string; // pxToRem converted value
  showAsDefaultWrongAnswer?: boolean;
}

const iconTranslationKeyLabelMap: { [key: string]: string } = {
  correct: "correct",
  incorrect: "incorrect",
  drag: "drag",
};

// renders an icon, a checkbox, or a radio, or nothing
export const ResponseIcon: React.FC<IResponseIconProps> = ({
  "aria-labelledby": ariaLabelledBy,
  showCheckbox,
  showRadio,
  success,
  error,
  variant,
  selected,
  iconColor,
  iconSize,
  fontSize,
  marginRight,
  onClick,
  isLoading,
  isDisabled,
  showTextInputIcon,
  questionType,
  spinnerWidth,
}) => {
  const { t } = useTranslation("session", {
    keyPrefix: "question",
    useSuspense: false,
  });

  const getIconType = (): IconType | "" => {
    switch (variant) {
      case PracticeSessionItemVariantType.CoopChecks:
      case PracticeSessionItemVariantType.CoopRadios:
        if (success) return "correct";
        else if (error) return "incorrect";
        else return ""; //default for selectable options is a checkbox/radio not an icon
      case PracticeSessionItemVariantType.CoopDragsort:
        if (questionType === QuestionType.Classify) {
          if (success) return "correct";
          else if (error) return "incorrect";
          else return "drag";
        } else return "";
      case PracticeSessionItemVariantType.CoopTextMatch:
        if (showTextInputIcon) return "checkmark";
        else return "";
      default:
        return "";
    }
  };

  const iconType = getIconType();
  const iconLabelKey = iconTranslationKeyLabelMap[iconType];
  const iconLabel = iconLabelKey ? t(iconLabelKey) : undefined;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    onClick?.(e.target.checked);
  };

  if (isLoading) {
    return (
      <Spinner
        h={iconSize}
        w={iconSize}
        color="utility.link"
        emptyColor="primary.tan"
        thickness={spinnerWidth}
        marginRight={marginRight}
      />
    );
  }
  return (
    <>
      {!!iconType && (
        <Icon
          icon={iconType}
          iconColor={iconColor}
          h={iconSize}
          w={iconSize}
          minW={iconSize}
          minH={iconSize}
          marginRight={marginRight}
          decorative
          label={iconLabel}
        />
      )}
      {(showRadio || showCheckbox) && (
        <Box marginRight={marginRight}>
          <Checkbox
            aria-labelledby={ariaLabelledBy}
            color={
              selected || showRadio ? "utility.link" : "primary.medium-gray"
            }
            size={fontSize}
            isChecked={selected}
            onChange={handleChange}
            type={showRadio ? "radio" : "checkbox"}
            isDisabled={isDisabled}
            isLoading={isLoading}
          />
        </Box>
      )}
    </>
  );
};
