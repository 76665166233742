import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { UserRole } from "../../types";

export interface IMutateOrganizationUserRoleProps {
  onError?: () => void;
  onSettled?: () => void;
  onSuccess?: () => void;
}

export interface IMutateOrganizationUserRoleParams {
  user_id: string;
  role: UserRole;
}

// eslint-disable-next-line
export interface IMutateOrganizationUserRoleResponse {}

export default function useMutateOrganizationUserRole(
  props: IMutateOrganizationUserRoleProps
): UseMutationResult<
  IMutateOrganizationUserRoleResponse,
  unknown,
  IMutateOrganizationUserRoleParams
> {
  const axios = useAxios();
  const queryClient = useQueryClient();

  const MutateOrganizationUserRole = async (
    args: IMutateOrganizationUserRoleParams
  ) => {
    const { user_id, role } = args;

    const response = await axios({
      method: "put",
      url: `/v1/organizations/users/${user_id}/role`,
      data: {
        user_id,
        role,
      },
    }).then((r) => r.data as IMutateOrganizationUserRoleResponse);

    queryClient.invalidateQueries(["organizationUsers"]);

    return response;
  };

  const mutation = useMutation(MutateOrganizationUserRole, {
    onError: props.onError,
    onSettled: props.onSettled,
    onSuccess: props.onSuccess,
  });

  return mutation;
}
