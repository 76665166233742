import { debounce } from "lodash";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { getOrientation } from "sessionComponents/utils/layoutUtils";

import { Breakpoint, Breakpoints, breakpoints } from "../theme/breakpoints";
import { ScreenOrientation } from "../types";

export interface IBreakpointsContext {
  breakpoints: Breakpoints;
  match: Breakpoint;
  size: string;
  orientation: ScreenOrientation;
  fontSize2x: number;
  fontSize3x: number;
  fontSizeHalf: number;
}

export const BreakpointsContext = createContext<IBreakpointsContext>({
  breakpoints: breakpoints[getOrientation() as keyof typeof breakpoints],
  match: breakpoints.landscape.sm,
  size: "sm",
  orientation: getOrientation(),
  fontSize2x: 2 * breakpoints.landscape.sm.fontSize,
  fontSize3x: 3 * breakpoints.landscape.sm.fontSize,
  fontSizeHalf: breakpoints.landscape.sm.fontSize / 2,
});

export const BreakpointsProvider: React.FC = ({ children }) => {
  const initialOrientation = useMemo(() => getOrientation(), []);
  const [match, setMatch] = useState<Breakpoint>(breakpoints.landscape.sm);
  const [size, setSize] = useState<string>("sm");
  const [orientation, setOrientation] =
    useState<ScreenOrientation>(initialOrientation);
  const [allBp, setAllBp] = useState(
    breakpoints[initialOrientation as keyof typeof breakpoints]
  );

  // if in landscape mode, we target height
  // if in portrait mode we target width
  const getBreakpointsByMediaQuery = useCallback(() => {
    const orientation = getOrientation();
    const isPortrait = orientation === ScreenOrientation.Portrait;
    const bp = breakpoints[orientation as keyof typeof breakpoints];

    const targetMinAttr = isPortrait ? "min-width" : "min-height";
    const targetMaxAttr = isPortrait ? "max-width" : "max-height";

    const targetKey = Object.keys(bp).find((size) => {
      const breakpoints: Breakpoint = bp[size as keyof typeof bp];

      const { matches } = window.matchMedia(
        `(${targetMinAttr}: ${breakpoints.min}px)${
          breakpoints.max ? ` and (${targetMaxAttr}: ${breakpoints.max}px)` : ""
        }`
      );

      return matches;
    });

    if (targetKey) {
      setSize(targetKey);
      setMatch(bp[targetKey as keyof typeof bp]);
    }
    setOrientation(orientation);
    setAllBp(bp);
  }, []);

  const debouncedMediaQueryFunc = useRef(
    debounce(getBreakpointsByMediaQuery, 200, { leading: true, trailing: true })
  );

  useEffect(() => {
    const eventListenerRef = debouncedMediaQueryFunc;

    debouncedMediaQueryFunc.current();

    window.addEventListener("resize", debouncedMediaQueryFunc.current);

    return () => {
      window.removeEventListener("resize", eventListenerRef.current);
      eventListenerRef.current.cancel();
    };
  }, []);

  const fontSize2x = useMemo((): number => {
    return 2 * match.fontSize;
  }, [match.fontSize]);

  const fontSize3x = useMemo((): number => {
    return 3 * match.fontSize;
  }, [match.fontSize]);

  const fontSizeHalf = useMemo((): number => {
    return match.fontSize / 2;
  }, [match.fontSize]);

  const contextValue = useMemo(() => {
    return {
      breakpoints: allBp,
      match,
      size,
      orientation,
      fontSize2x,
      fontSize3x,
      fontSizeHalf,
    };
  }, [allBp, match, orientation, size, fontSize2x, fontSize3x, fontSizeHalf]);

  return (
    <BreakpointsContext.Provider value={contextValue}>
      {children}
    </BreakpointsContext.Provider>
  );
};

export const useBreakpoints = (): IBreakpointsContext => {
  return useContext(BreakpointsContext);
};
