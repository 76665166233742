import React from "react";
import { Route, Switch } from "react-router-dom";

import AppError from "screens/AppError";

import { FolderDetail } from "./FolderDetail";
import { LibraryRoot } from "./LibraryRoot";

const TeacherClassrooms: React.FC = () => {
  return (
    <Switch>
      <Route path="/t/my-library/create" exact={true}>
        <LibraryRoot initialCreateOpen />
      </Route>
      <Route path="/t/my-library" exact={true}>
        <LibraryRoot />
      </Route>
      <Route path="/t/my-library/folders/:collectionId">
        <FolderDetail />
      </Route>
      <Route path="*">
        <AppError code={404} />
      </Route>
    </Switch>
  );
};

export default TeacherClassrooms;
