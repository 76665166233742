import React, { useEffect, useRef } from "react";

import { SpineItem } from "../lib/SpineItem";
import { SpineStage } from "../lib/SpineStage";

export interface IAvatarStageItemProps {
  // set by parent canvas
  spineStage?: SpineStage;
  x: number;
  y: number;
  scale: number;
  imageSrc: string;
  drawOrder?: number;
}

const AvatarStageItem: React.FC<IAvatarStageItemProps> = ({
  spineStage,
  x,
  y,
  scale,
  imageSrc,
  drawOrder,
}) => {
  const spineItemRef = useRef<SpineItem | null>(null);

  useEffect(() => {
    if (!spineStage || spineItemRef.current) return;

    spineItemRef.current = new SpineItem({
      x,
      y,
      scale,
      imageSrc,
      drawOrder,
    });

    spineStage.registerAsset(spineItemRef.current).catch((err) => {
      console.error("Could not create Spine Item: " + err);
    });

    return () => {
      if (!spineItemRef.current) return;

      spineStage?.unregisterAsset(spineItemRef.current);
      spineItemRef.current?.dispose();
    };
  }, [spineStage, drawOrder, imageSrc, scale, x, y]);

  useEffect(() => {
    if (!spineItemRef.current) return;

    spineItemRef.current.setTransform(x, y, scale);
  }, [x, y, scale]);

  return null;
};

export { AvatarStageItem };
