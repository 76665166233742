import { AxiosError } from "axios";
import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";

export interface IFetchThirdPartyAssignmentSubmissionParams {
  assignmentId: string;
  onError?: (err: AxiosError) => void;
  enabled?: boolean;
}

export interface IFetchThirdPartyAssignmentSubmissionResponse {
  student_assignment_submission_id: string;
  requires_authorization: boolean;
}

export default function useFetchThirdPartyAssignmentSubmission(
  params: IFetchThirdPartyAssignmentSubmissionParams
): UseQueryResult<IFetchThirdPartyAssignmentSubmissionResponse, AxiosError> {
  const axios = useAxios();

  const { assignmentId, onError, enabled = true } = params;

  const query = useQuery<
    IFetchThirdPartyAssignmentSubmissionResponse,
    AxiosError
  >(
    ["assignments-third-party-submission", { assignmentId }],
    async (): Promise<IFetchThirdPartyAssignmentSubmissionResponse> => {
      return await axios
        .get(`/v1/assignments/${params.assignmentId}/third-party/submission`)
        .then((r) => r.data as IFetchThirdPartyAssignmentSubmissionResponse);
    },
    {
      onError,
      enabled,
    }
  );

  return query;
}
