import { Box, Flex, FormControl, SimpleGrid } from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { FormErrorMessage } from "adminComponents/atoms/FormErrorMessage";
import { Icon } from "adminComponents/atoms/Icon";
import { Text } from "adminComponents/atoms/Text";
import { useEditPracticeSetMediaModalContext } from "adminComponents/contexts/EditPracticeSetMediaModalContext";
import { AddMediaModal } from "adminComponents/organisms/AddMediaModal";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { RichValue } from "links/lib/types";
import { generateEmptySlateState } from "links/lib/util";

import { CreateClassificationTabForm } from "../CreateQuestionClassificationTab";
import { CategoryField } from "./components/CategoryField";

export interface IClassifyFormChoice {
  text: RichValue;
  image_url?: string;
  image_alt_text?: string;
}

export interface IClassifyFormCategory {
  text: RichValue;
  image_url?: string;
  image_alt_text?: string;
  choices: Array<IClassifyFormChoice>;
}

interface IProps {
  hasNoPremiumAccess?: boolean;
  label: string;
  isImageMode: boolean;
}

// Checks category limits while isolating watch (and re-renders)
// to another hook
export const useCategoryLimits = (): {
  canAddCategory: boolean;
  canRemoveCategory: boolean;
  canAddResponse: boolean;
} => {
  const { control } = useFormContext<CreateClassificationTabForm>();
  const categories = useWatch({ control, name: "categories" });

  const canAddResponse =
    categories.reduce((sum, cat) => sum + cat.choices.length, 0) < 6;

  const canAddCategory = categories.length < 4 && canAddResponse;
  const canRemoveCategory = categories.length > 2;

  return useMemo(
    () => ({ canAddCategory, canRemoveCategory, canAddResponse }),
    [canAddCategory, canRemoveCategory, canAddResponse]
  );
};

export const Classifications: React.FC<IProps> = ({
  hasNoPremiumAccess = false,
  label,
  isImageMode,
}) => {
  const mediaModalProps = useEditPracticeSetMediaModalContext();

  const { t } = useTranslation("admin", { useSuspense: false });
  const [categoryIndex, setCategoryIndex] = useState(0);
  const [choiceIndex, setChoiceIndex] = useState(0);
  const [choiceImageUrl, setChoiceImageUrl] = useState("");
  const [choiceImageAltText, setChoiceImageAltText] = useState("");

  const { control, getValues, setValue, getFieldState, formState } =
    useFormContext<CreateClassificationTabForm>();
  const {
    append: appendCategory,
    remove: removeCategory,
    fields: categoryFields,
  } = useFieldArray({
    control,
    name: "categories",
    rules: {
      minLength: 2,
      maxLength: 4,
    },
  });

  const { canAddCategory, canRemoveCategory, canAddResponse } =
    useCategoryLimits();

  const handleAddNewCategory = () => {
    appendCategory({
      text: generateEmptySlateState(),
      choices:
        isImageMode || !canAddResponse
          ? []
          : [{ text: generateEmptySlateState() }],
    });
  };

  const handleRemoveCategory = (categoryIndex: number) => {
    removeCategory(categoryIndex);
  };

  const handleInsertMedia = (imageUrl: string, imageAltText: string) => {
    const { categories } = getValues();

    const updatingExistingChoice =
      categories[categoryIndex] &&
      categories[categoryIndex].choices[choiceIndex];

    if (updatingExistingChoice) {
      setValue(`categories.${categoryIndex}.choices.${choiceIndex}`, {
        text: generateEmptySlateState(),
        image_url: imageUrl,
        image_alt_text: imageAltText,
      });
    } else {
      setValue(`categories.${categoryIndex}.choices`, [
        ...categories[categoryIndex].choices,
        {
          text: generateEmptySlateState(),
          image_url: imageUrl,
          image_alt_text: imageAltText,
        },
      ]);
    }
    mediaModalProps.handleInsertMedia(imageUrl, imageAltText);
    mediaModalProps.handleClose();
  };

  const handleRemoveImage = () => {
    const { categories } = getValues();

    setChoiceImageUrl("");
    setChoiceImageAltText("");

    if (
      categories[categoryIndex] &&
      categories[categoryIndex].choices[choiceIndex]
    ) {
      const newChoices = [...categories[categoryIndex].choices];
      newChoices.splice(choiceIndex, 1);

      setValue(`categories.${categoryIndex}.choices`, newChoices);
    }

    mediaModalProps.handleRemoveImage();
  };

  const handleAddImageChoice = (categoryIndex: number) => {
    const { categories } = getValues();
    setCategoryIndex(categoryIndex);
    setChoiceIndex(categories[categoryIndex].choices.length);
    setChoiceImageUrl("");
    setChoiceImageAltText("");
    mediaModalProps.handleOpen();
  };

  const handleImageChoiceClick = (
    categoryIndex: number,
    choiceIndex: number
  ) => {
    const { categories } = getValues();
    setCategoryIndex(categoryIndex);
    setChoiceIndex(choiceIndex);
    setChoiceImageUrl(
      categories[categoryIndex].choices[choiceIndex].image_url || ""
    );
    setChoiceImageAltText(
      categories[categoryIndex].choices[choiceIndex].image_alt_text || ""
    );
    mediaModalProps.handleOpen();
  };

  const { error, isTouched } = getFieldState(`categories`, formState);
  const isInvalid = !!error?.root;

  return (
    <>
      <FormControl
        isRequired
        variant="adminFormControl"
        isInvalid={isInvalid}
        minW={0}
      >
        <Flex
          flexDir={["column", null, "row"]}
          alignItems={["flex-start", null, "center"]}
          mb={pxToRem(8)}
        >
          <Text
            variant="adminP2"
            color={
              !!isTouched && isInvalid ? "utility.error" : "primary.warm-black"
            }
          >
            {label}*
          </Text>
          <FormErrorMessage>{error?.root?.message}</FormErrorMessage>
        </Flex>
        <Box mb={[pxToRem(20), null, pxToRem(16)]}>
          <Text variant="adminP2" color="primary.dark-gray">
            {t("createClassificationFlyout.categoriesDescription")}
          </Text>
        </Box>
        <SimpleGrid gap={pxToRem(16)}>
          {categoryFields.map((category, categoryIndex) => {
            return (
              <CategoryField
                hasNoPremiumAccess={hasNoPremiumAccess}
                key={category.id}
                categoryIndex={categoryIndex}
                canRemoveCategory={canRemoveCategory}
                canAddResponse={canAddResponse}
                isImageMode={isImageMode}
                handleRemoveCategory={handleRemoveCategory}
                handleAddImageChoice={handleAddImageChoice}
                handleImageChoiceClick={handleImageChoiceClick}
              />
            );
          })}
        </SimpleGrid>
        <Button
          w="fit-content"
          mt={pxToRem(16)}
          variant="adminButtonOutlined"
          onClick={hasNoPremiumAccess ? undefined : handleAddNewCategory}
          disabled={!canAddCategory || hasNoPremiumAccess}
          leftIcon={
            canAddCategory ? (
              <Icon iconColor="primary.warm-black" icon="add_outlined" />
            ) : undefined
          }
        >
          {canAddCategory
            ? t("createClassificationFlyout.addAnotherCategory")
            : t("createClassificationFlyout.addAnotherCategoryDisabled")}
        </Button>
      </FormControl>
      <AddMediaModal
        {...mediaModalProps}
        imageOnly
        imageUrlToAdd={choiceImageUrl}
        imageAltText={choiceImageAltText}
        handleInsertMedia={handleInsertMedia}
        handleRemoveImage={handleRemoveImage}
      />
    </>
  );
};
