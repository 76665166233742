import { Box } from "@chakra-ui/react";
import React from "react";

import { Stage } from "sessionComponents/atoms/Stage";
import { useDetectOrientation } from "sessionComponents/theme/hooks/useDetectOrientation";

import BigBoardBg from "./resource/bigBoardBackground.svg";

export const BigBoardStage: React.FC = ({ children }) => {
  const { isLandscape } = useDetectOrientation();

  return (
    <Stage showCurtainOnly>
      {isLandscape && (
        <Box
          w="50%"
          h="100%"
          left="0"
          top="0"
          position="absolute"
          bgImage={BigBoardBg}
          bgColor="stage.fallbackCurtain"
          bgPosition="center top"
          bgSize="cover"
          bgRepeat="repeat-x"
        />
      )}
      <Box w="100%" h="100%" left="0" top="0" position="absolute">
        {children}
      </Box>
    </Stage>
  );
};
