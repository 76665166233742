import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Text } from "slate";

import { latexToHumanReadable } from "lib/latexUtils";
import { convertToSlate } from "lib/slateUtils";
import { RichText } from "links/lib/types";

export interface IResult {
  convert: (text: RichText) => string;
}

export interface ISpeakableTransMap {
  blank: string;
}

const convertRichTextToSpeakable = (
  text: RichText,
  transMap: ISpeakableTransMap
): string => {
  const contentState = convertToSlate(text) as Array<Record<string, unknown>>;

  const serialize = (node: Record<string, unknown>): string => {
    if (Text.isText(node)) {
      return node.text;
    }

    if (!("type" in node)) return "";

    const children: string =
      ("children" in node &&
        Array.isArray(node.children) &&
        node.children.map((n) => serialize(n)).join("")) ||
      "";

    switch (node.type) {
      case "paragraph": {
        const c = children.trim();
        if (!c) return "";

        // check if the paragraph already ends with a punctuation mark
        const hasPunc = /(\?|\.|!|:|;|,)$/g.test(c);

        // add period to force reader to take pause at end of paragraph
        return c + (hasPunc ? " " : ". ");
      }
      case "latex":
        if ("content" in node && typeof node.content === "string") {
          return latexToHumanReadable(node.content);
        } else {
          return "";
        }
      default:
        return children;
    }
  };

  return contentState
    .map((c) => serialize(c))
    .join("")
    .replace(/___+/g, ` ${transMap.blank} `); // replace three or more underscores with "blank"
};

export const useRichTextToSpeakable = (): IResult => {
  const { t } = useTranslation("session", {
    useSuspense: false,
    keyPrefix: "speakable",
  });

  const convert = useCallback(
    (text: RichText) => {
      const speakable = convertRichTextToSpeakable(text, {
        blank: t("blank"),
      });

      return speakable;
    },
    [t]
  );

  return { convert };
};
