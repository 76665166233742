import { ISeasonLevel } from "links/lib/types";

import { IUseAsyncReturn, useAsync } from "../../hooks/useAsync";
import { useAxios } from "../../hooks/useAxios";

export interface IFetchSeasonLevelsOnceResponse {
  levels: Array<ISeasonLevel>;
}

export default function useFetchSeasonLevelsOnce(): IUseAsyncReturn<
  IFetchSeasonLevelsOnceResponse,
  undefined
> {
  const axios = useAxios();

  const fetchSeasonLevels = async () => {
    return await axios
      .get("/v1/levels")
      .then((r) => r.data as IFetchSeasonLevelsOnceResponse);
  };

  const { isLoading, error, data, execute, isSuccess } =
    useAsync<IFetchSeasonLevelsOnceResponse>(fetchSeasonLevels);

  return {
    isLoading,
    error,
    data,
    execute,
    isSuccess,
  };
}
