import React from "react";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { GameChoiceType } from "sessionComponents/types";

import {
  GenericImageOption,
  IGenericImageOptionProps,
} from "../GenericImageOption";

type DrawVotingOptionProps = Omit<
  IGenericImageOptionProps,
  | "imageButtonBottomPosition"
  | "imageButtonRightPosition"
  | "imageButtonBoxSize"
  | "imageButtonIconSize"
> & {
  allowDeleteImage: boolean;
};

export const DrawVotingOption: React.FC<DrawVotingOptionProps> = (props) => {
  const { match: currentBreakpoints } = useBreakpoints();
  const width = currentBreakpoints.imageResponseSize;

  return (
    <GenericImageOption
      {...props}
      choiceType={GameChoiceType.Radio}
      imageAltText={props.imageAltText}
      imageUrl={props.imageUrl}
      variant={props.variant}
      borderRadius={pxToRem(0)}
      width={pxToRem(width)}
      maxWidth="100%"
      height="fit-content"
      overflow="visible"
      imageButtonBottomPosition="-5%"
      imageButtonRightPosition="-2%"
      imageButtonBoxSize={pxToRem(currentBreakpoints.buttonHeight * 0.66)}
      imageButtonIconSize={pxToRem(currentBreakpoints.iconSize * 0.66)}
      allowDeleteImage={props.allowDeleteImage}
    />
  );
};
