import {
  Button as ChakraButton,
  ButtonProps as IProps,
} from "@chakra-ui/react";
import React from "react";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";

// Copied from adminComponents
const Button: React.FC<IProps> = ({ children, variant, ...rest }) => {
  const { match: currentBreakpoints } = useBreakpoints();

  return (
    <ChakraButton
      borderRadius={pxToRem(currentBreakpoints.borderRadius)}
      borderWidth={
        variant === "buttonOutlined"
          ? pxToRem(currentBreakpoints.borderWidth)
          : undefined
      }
      variant={variant}
      {...rest}
    >
      {children}
    </ChakraButton>
  );
};

export { Button };
