import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { IPracticeSetItem } from "../../types";

export interface IMutatePracticeSetItemArgs {
  practice_set_id: string;
  ordering: Array<{ item_id: string; order: number }>;
}

interface IMutatePracticeSetItemsOrderResponse {
  practice_set_items: Array<IPracticeSetItem>;
}

export interface IUseMutatePracticeSetItemsOrderProps {
  onSuccess?: (data: IMutatePracticeSetItemsOrderResponse) => void;
  onError?: () => void;
  onSettled?: () => void;
}

export default function useMutatePracticeSetItemsOrder(
  props?: IUseMutatePracticeSetItemsOrderProps
): UseMutationResult<
  IMutatePracticeSetItemsOrderResponse,
  unknown,
  IMutatePracticeSetItemArgs
> {
  const axios = useAxios();
  const queryClient = useQueryClient();

  const mutatePracticeSetItemsOrder = async (
    args: IMutatePracticeSetItemArgs
  ) => {
    const { ordering, practice_set_id } = args;

    const response = await axios({
      method: "put",
      url: `/v1/practice-sets/${practice_set_id}/items/reorder`,
      data: {
        ordering,
      },
    }).then((r) => r.data as IMutatePracticeSetItemsOrderResponse);

    queryClient.invalidateQueries(["practiceSets", { id: practice_set_id }]);
    queryClient.invalidateQueries(["practiceSetItems", { practice_set_id }]);

    response.practice_set_items.forEach((practice_set_item) => {
      queryClient.setQueryData(
        ["practiceSetItems", { id: practice_set_item.id }],
        {
          practice_set_item: practice_set_item,
        }
      );
    });

    return response;
  };

  const mutation = useMutation(mutatePracticeSetItemsOrder, {
    onSuccess: props?.onSuccess,
    onError: props?.onError,
    onSettled: props?.onSettled,
  });

  return {
    ...mutation,
  };
}
