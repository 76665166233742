import { Box, Flex, Image } from "@chakra-ui/react";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { IconCTAButton } from "adminComponents/atoms/IconCTAButton";
import { CreateClassificationTabForm } from "adminComponents/molecules/CreateQuestionClassificationTab";
import { RichTextEditor } from "adminComponents/organisms/RichTextEditor";
import { isRichTextEmpty } from "adminComponents/organisms/RichTextEditor/util";
import { pxToRem } from "adminComponents/utils/pxToRem";

interface ITextModeChoiceProps {
  hasNoPremiumAccess?: boolean;
  categoryIndex: number;
  choiceIndex: number;
  handleRemoveCategoryChoice: (choiceIndex: number) => void;
}

export const TextModeChoice: React.FC<ITextModeChoiceProps> = ({
  hasNoPremiumAccess = false,
  categoryIndex,
  choiceIndex,
  handleRemoveCategoryChoice,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const { control } = useFormContext<CreateClassificationTabForm>();

  return (
    <Flex gap={pxToRem(12)} alignItems="center" minWidth={0}>
      <Box flex={1} minW={0}>
        <Controller
          control={control}
          name={`categories.${categoryIndex}.choices.${choiceIndex}.text`}
          rules={{
            validate: {
              isNotEmpty: (v) =>
                !isRichTextEmpty(v) ||
                (t(
                  "createClassificationFlyout.errInvalidCategoryChoice"
                ) as string),
            },
          }}
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { isTouched, error },
          }) => (
            <RichTextEditor
              inline
              name={name}
              value={value}
              ref={ref}
              handleChangeRaw={onChange}
              placeholder={t(
                "createClassificationFlyout.placeholderCategoryChoice"
              )}
              handleBlur={onBlur}
              hasError={isTouched && !!error}
              disabled={hasNoPremiumAccess}
            />
          )}
        />
      </Box>
      <IconCTAButton
        icon="close"
        ariaLabel={t("createClassificationFlyout.removeChoice")}
        colorScheme="primary.white"
        onClick={
          hasNoPremiumAccess
            ? undefined
            : () => handleRemoveCategoryChoice(choiceIndex)
        }
        type="button"
        disabled={hasNoPremiumAccess}
      />
    </Flex>
  );
};

interface IImageModeChoiceProps {
  hasNoPremiumAccess?: boolean;
  categoryIndex: number;
  choiceIndex: number;
  handleRemoveCategoryChoice: (choiceIndex: number) => void;
  handleImageChoiceClick: (categoryIndex: number, choiceIndex: number) => void;
}

export const ImageModeChoice: React.FC<IImageModeChoiceProps> = ({
  hasNoPremiumAccess = false,
  categoryIndex,
  choiceIndex,
  handleRemoveCategoryChoice,
  handleImageChoiceClick,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  const { watch } = useFormContext<CreateClassificationTabForm>();
  const [imageUrl, imageAltText] = watch([
    `categories.${categoryIndex}.choices.${choiceIndex}.image_url`,
    `categories.${categoryIndex}.choices.${choiceIndex}.image_alt_text`,
  ]);

  return (
    <Flex gap={pxToRem(12)} alignItems="center">
      <Image
        borderRadius="lg"
        w="93%"
        objectFit="contain"
        maxH="20vh"
        src={imageUrl}
        alt={imageAltText}
        cursor="pointer"
        onClick={() => handleImageChoiceClick(categoryIndex, choiceIndex)}
      />
      <IconCTAButton
        icon="close"
        ariaLabel={t("createClassificationFlyout.removeChoice")}
        colorScheme="primary.white"
        onClick={
          hasNoPremiumAccess
            ? undefined
            : () => handleRemoveCategoryChoice(choiceIndex)
        }
        disabled={hasNoPremiumAccess}
        type="button"
      />
    </Flex>
  );
};
