import { pxToRem } from "adminComponents/utils/pxToRem";

export const AdminPracticeSetCard = {
  parts: ["container", "body", "progress", "title", "coverImage", "divider"],

  baseStyle: {
    body: {
      display: "flex",
      flexDirection: { base: "column", md: "row" },
      alignItems: { base: "start", md: "center" },
      justifyContent: "space-between",
      gap: { base: pxToRem(16), md: pxToRem(40) },
    },
    progress: {
      flexShrink: 0,
    },
    title: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: pxToRem(16),
    },
    coverImage: {
      flexShrink: 0,
    },
    divider: {
      display: { md: "none" },
    },
    linkOverlay: {
      _focus: {
        boxShadow: "none",
        outline: "none",
      },
      _focusVisible: {
        display: "inline-block",
        outlineStyle: "solid",
        outlineWidth: pxToRem(2),
        outlineColor: "utility.focus",
      },
    },
  },
};
