import { Box } from "@chakra-ui/react";
import React from "react";
import { animated, config, useSpring } from "react-spring";

import { ColorScheme } from "../../../adminComponents";
import { pxToRem } from "../../../adminComponents/utils/pxToRem";

interface IAccuracyBarProps {
  accuracyPercentage: number;
  fillColor: ColorScheme;
  backgroundColor?: ColorScheme;
}

// todo further tweak animation?
export const AccuracyBar: React.FC<IAccuracyBarProps> = ({
  accuracyPercentage,
  fillColor,
  backgroundColor,
}) => {
  const barStyle = useSpring({
    from: {
      width: "0",
    },
    to: {
      width: `${accuracyPercentage}%`,
    },
    config: {
      ...config.default,
      friction: 50,
    },
    delay: 500,
    onRest: () => {
      //todo
    },
    onStart: () => {
      //todo
    },
  });

  const AnimatedBox = animated(Box);

  return (
    <Box
      height={pxToRem(16)}
      width="100%"
      backgroundColor={backgroundColor || "primary.medium-gray"}
    >
      <AnimatedBox height="100%" style={barStyle} backgroundColor={fillColor} />
    </Box>
  );
};
