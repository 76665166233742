import { useMemo, useState } from "react";
import { useIsomorphicLayoutEffect } from "react-use";

export type UseBoundingRectRect = Pick<
  DOMRectReadOnly,
  "x" | "y" | "top" | "left" | "right" | "bottom" | "height" | "width"
>;
export type UseBoundingRectRef<E extends Element = Element> = (
  element: E
) => void;
export type UseBoundingRectResult<E extends Element = Element> = [
  UseBoundingRectRef<E>,
  UseBoundingRectRect
];

// inspired by useMeasure from react-use but returns values via getBoundingClientRect()
export const useBoundingRect = <
  E extends Element = Element
>(): UseBoundingRectResult<E> => {
  const [element, ref] = useState<E | null>(null);
  const [rect, setRect] = useState<UseBoundingRectRect>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  });
  const observer = useMemo(
    () =>
      new window.ResizeObserver((entries) => {
        if (entries[0]) {
          const { x, y, width, height, top, left, bottom, right } =
            entries[0].target.getBoundingClientRect();
          setRect({ x, y, width, height, top, left, bottom, right });
        }
      }),
    []
  );
  useIsomorphicLayoutEffect(() => {
    if (!element) return;
    observer.observe(element);
    return () => {
      observer.disconnect();
    };
  }, [element]);
  return [ref, rect];
};
