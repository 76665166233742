import { AxiosError } from "axios";
import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { IUser, UserPublicProfileStatistics } from "../../types";

export interface IFetchUserPublicProfileParams {
  userId: string;
  customUrlCode?: string;
  includeStatistics: boolean;
  onError?: (err: AxiosError) => void;
  onForbidden?: () => void;
  onNotFound?: () => void;
}

export interface IFetchUserPublicProfileResponse {
  is_following: boolean;
  is_private: boolean;
  user: IUser;
  statistics: UserPublicProfileStatistics;
}

/**
 * Fetches the public profile of a user
 * @returns
 */
export default function useFetchUserPublicProfile(
  params: IFetchUserPublicProfileParams
): UseQueryResult<IFetchUserPublicProfileResponse, AxiosError> {
  const axios = useAxios();

  const {
    userId,
    customUrlCode,
    includeStatistics,
    onError,
    onForbidden,
    onNotFound,
  } = params;

  const reactQuery = useQuery<IFetchUserPublicProfileResponse, AxiosError>(
    ["user-public-profiles", { userId, includeStatistics, customUrlCode }],
    async (): Promise<IFetchUserPublicProfileResponse> => {
      return await axios
        .get(`/v1/users/${userId}/public-profile`, {
          params: {
            custom_url_code: customUrlCode,
            include_statistics: includeStatistics,
          },
        })
        .then((r) => r.data as IFetchUserPublicProfileResponse);
    },
    {
      onError: (err: AxiosError) => {
        if (err.response?.status === 403) {
          if (onForbidden) {
            onForbidden();
            return;
          }
        }
        if (err.response?.status === 404) {
          if (onNotFound) {
            onNotFound();
            return;
          }
        }

        onError?.(err);
      },
    }
  );

  return reactQuery;
}
