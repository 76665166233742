import { useDisclosure } from "@chakra-ui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { TeacherMyTeachersScreen } from "adminComponents/screens/TeacherMyTeachersScreen";
import { useErrorToast, useShowToast } from "adminComponents/utils/toast";
import { useAnalytics, usePageTrack } from "lib/contexts/analytics";
import { usePageTitle } from "lib/hooks/usePageTitle";
import { useAuth } from "links/lib/features/auth";
import {
  useAddUsersToOrganization,
  useFetchOrganizationUsers,
  useMutateOrganizationUserRole,
  useRemoveOrganizationUser,
} from "links/lib/features/organizations";
import {
  IUseFetchOrganizationUsersParams,
  SortBy,
  SortDir,
} from "links/lib/features/organizations/useFetchOrganizationUsers";
import { AnalyticsEvent, AnalyticsPage, UserRole } from "links/lib/types";
import { useNavigationData } from "screens/TeacherDashboard/contexts/TeacherNavigationDataProvider";

import { AddUsersModal } from "./components/AddUsersModal";

const pageSize = 8;
const defaultSortBy = SortBy.FamilyName;
const defaultSortDir = SortDir.Asc;

export interface IProps {
  adminMode?: boolean;
}

export const TeacherStaffManagement: React.FC<IProps> = ({ adminMode }) => {
  const { authUser } = useAuth();
  const { navigationData } = useNavigationData();
  const showToast = useShowToast();
  const { trackEvent } = useAnalytics();
  const { t } = useTranslation("admin", {
    keyPrefix: "teacherStaffManagement",
  });
  usePageTitle(t("pageTitle"));
  usePageTrack(AnalyticsPage.TeacherDashboard_ProfileSettings);

  navigationData.adminMode = adminMode;

  const [query, setQuery] = useState<IUseFetchOrganizationUsersParams>({
    query: "",
    page: 1,
    perPage: pageSize,
    sortBy: defaultSortBy,
    sortDir: defaultSortDir,
  });

  const { data, isLoading } = useFetchOrganizationUsers(query);

  const addUsersToOrganization = useAddUsersToOrganization();
  useErrorToast(addUsersToOrganization.error, t("toastErrorRemoveUser"));

  const mutateUserRole = useMutateOrganizationUserRole({
    onSuccess: () => {
      showToast(t("toastSuccessChangeUserRole"));
    },
  });
  useErrorToast(mutateUserRole.error, t("toastErrorChangeUserRole"));

  const removeUserFromOrganization = useRemoveOrganizationUser({
    onSuccess: () => {
      showToast(t("toastSuccessRemoveUser"));
    },
  });
  useErrorToast(removeUserFromOrganization.error, t("toastErrorRemoveUser"));

  const onAddUsers = (
    emails: Array<string>,
    sendEmailNotifications: boolean
  ) => {
    trackEvent(AnalyticsEvent.TeacherDashboard_MyStaffUsers_AddUsers, {});
    addUsersToOrganization.mutate({ emails, sendEmailNotifications });
  };

  const onRemoveUser = (userId: string) => {
    trackEvent(AnalyticsEvent.TeacherDashboard_MyStaffUsers_RemoveUser, {});
    removeUserFromOrganization.mutate({ user_id: userId });
  };

  const onChangeUserRole = (userId: string, role: UserRole) => {
    trackEvent(AnalyticsEvent.TeacherDashboard_MyStaffUsers_ChangeUserRole, {});
    mutateUserRole.mutate({ user_id: userId, role });
  };

  const onSetQuery = (searchQuery: string) => {
    setQuery((query) => {
      return {
        ...query,
        query: searchQuery,
        page: 1,
      };
    });
  };

  const onSetPage = (page: number) => {
    setQuery((query) => {
      return {
        ...query,
        page,
      };
    });
  };

  const onSetSortBy = (sortBy: SortBy) => {
    if (query.sortBy === sortBy) {
      setQuery((query) => {
        return {
          ...query,
          sortDir: query.sortDir === SortDir.Asc ? SortDir.Desc : SortDir.Asc,
          page: 1,
        };
      });
    } else {
      setQuery((query) => {
        return {
          ...query,
          sortBy,
          sortDir: SortDir.Asc,
          page: 1,
        };
      });
    }
  };

  const {
    onOpen: onOpenAddUsers,
    isOpen: isOpenAddUsers,
    onClose: onCloseAddUsers,
  } = useDisclosure();

  if (!authUser) return <></>;

  return (
    <>
      <TeacherMyTeachersScreen
        teacher={authUser}
        users={data?.users || []}
        page={query.page}
        perPage={pageSize}
        query={query.query || ""}
        totalCount={data?.total_count || 0}
        isLoading={isLoading}
        navigationData={navigationData}
        sortBy={query.sortBy || defaultSortBy}
        sortDir={query.sortDir || defaultSortDir}
        handleAddUsers={onOpenAddUsers}
        handleChangeUserRole={onChangeUserRole}
        handleRemoveUser={onRemoveUser}
        handleSetPage={onSetPage}
        handleSetQuery={onSetQuery}
        handleSortBy={onSetSortBy}
      />
      {isOpenAddUsers && (
        <AddUsersModal
          isOpen={isOpenAddUsers}
          result={addUsersToOrganization.data}
          onClose={onCloseAddUsers}
          onSubmit={onAddUsers}
        />
      )}
    </>
  );
};
