import {
  Popover,
  PopoverAnchor,
  PopoverArrow,
  PopoverArrowProps,
  PopoverBody,
  PopoverBodyProps,
  PopoverContent,
  PopoverContentProps,
  useBreakpointValue,
} from "@chakra-ui/react";
import React from "react";

import { pxToRem } from "adminComponents/utils/pxToRem";

export const SeasonPopover = Popover;
export const SeasonPopoverAnchor = PopoverAnchor;

export const SeasonPopoverContent: React.FC<PopoverContentProps> = ({
  children,
  ...rest
}) => {
  const borderRadius = [pxToRem(16), pxToRem(20), pxToRem(24)];
  const borderWidth = useBreakpointValue([
    pxToRem(8),
    pxToRem(10),
    pxToRem(12),
  ]);

  return (
    <PopoverContent
      borderRadius={borderRadius}
      bg="primary.warm-white"
      borderColor="primary.tan"
      borderWidth={borderWidth}
      sx={{
        "--popper-arrow-size": pxToRem(22),
        "--popper-arrow-shadow-color": "transparent",
      }}
      w={pxToRem(360)}
      {...rest}
    >
      {children}
    </PopoverContent>
  );
};

export const SeasonPopoverArrow: React.FC<PopoverArrowProps> = ({
  ...rest
}) => {
  return <PopoverArrow bg="primary.tan" {...rest} />;
};

export const SeasonPopoverBody: React.FC<PopoverBodyProps> = ({
  children,
  ...rest
}) => {
  const borderRadius = [pxToRem(16), pxToRem(20), pxToRem(24)];

  return (
    <PopoverBody
      bg="primary.warm-white"
      borderRadius={borderRadius}
      p={0}
      {...rest}
    >
      {children}
    </PopoverBody>
  );
};
