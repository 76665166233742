import { QuestionIcon } from "@chakra-ui/icons";
import {
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverProps,
  PopoverTrigger,
  Portal,
  useStyleConfig,
} from "@chakra-ui/react";
import React, { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";

import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils/pxToRem";

type PlacementWithLogical =
  | "top"
  | "top-start"
  | "top-end"
  | "bottom"
  | "bottom-start"
  | "bottom-end";

type PopoverWithoutPlacement = Omit<PopoverProps, "placement">;

export interface IProps extends PopoverWithoutPlacement {
  isDisabled?: boolean;
  placement?: PlacementWithLogical;
  popoverVariant?: string;
  triggerAriaLabel?: string;
  triggerEl?: ReactNode;
  handleKeyDown?: (e: React.KeyboardEvent) => void;
  usePortal?: boolean;
}

const offsets: Record<PlacementWithLogical, [number, number]> = {
  top: [0, 10],
  "top-start": [-18, 10],
  "top-end": [18, 10],
  bottom: [0, 10],
  "bottom-start": [-18, 10],
  "bottom-end": [18, 10],
};

const IconTooltip: React.FC<IProps> = ({
  children,
  isDisabled = false,
  placement = "top",
  popoverVariant = "adminSolid",
  triggerAriaLabel,
  triggerEl = (
    <QuestionIcon
      color="utility.link"
      width={pxToRem(16)}
      height={pxToRem(16)}
    />
  ),
  variant = "adminSolid",
  handleKeyDown,
  usePortal = false,
}) => {
  const triggerElementStyles = useStyleConfig("AdminPopoverTrigger", {
    variant: popoverVariant,
  });

  const { t } = useTranslation("admin", { useSuspense: false });

  // Using this component inside of a <Carousel> was cutting off the tooltip
  // a <Portal> fixed but when not open was overflowing the container and introducing scroll
  // for offscreen items in the <Carousel>.  This stops the content from rendering at all if not open.
  const [visible, setVisible] = useState(false);

  const content = (
    <PopoverContent>
      <PopoverArrow />
      <PopoverBody>
        <Text as="p" variant="adminMeta" color="primary.white">
          {children}
        </Text>
      </PopoverBody>
    </PopoverContent>
  );

  const contentWithPortal = <Portal>{content}</Portal>;

  return (
    <Popover
      computePositionOnMount
      arrowPadding={22}
      arrowShadowColor="transparent"
      offset={offsets[placement]}
      placement={placement}
      trigger="hover"
      variant={variant}
      onOpen={() => setVisible(true)}
      onClose={() => setVisible(false)}
    >
      {isDisabled ? (
        triggerEl
      ) : (
        <PopoverTrigger>
          <Button
            __css={triggerElementStyles}
            type="button"
            onKeyDown={handleKeyDown}
            aria-label={triggerAriaLabel || t("common.openTooltip")}
          >
            {triggerEl}
          </Button>
        </PopoverTrigger>
      )}
      {usePortal ? (visible ? contentWithPortal : undefined) : content}
    </Popover>
  );
};

export { IconTooltip };
