import { useMemo } from "react";

import {
  ISessionRoundGroupState,
  ISessionUser,
  PracticeSessionItemVariantType,
} from "links/lib/types";

import { getSelectedChoices } from "../utils/getSelectedChoices";

interface IGetStudentConfirmButtonDisabledProps {
  variant: PracticeSessionItemVariantType;
  student: ISessionUser;
  roundGroupState: ISessionRoundGroupState;
  isTextSubmissionPhase?: boolean;
  isTextSubmissionDisabled?: boolean;
  isDrawVotingRound?: boolean;
  userHasSelectedDrawing?: boolean;
}

export const useGetStudentConfirmButtonDisabled = ({
  variant,
  student,
  isTextSubmissionPhase,
  isTextSubmissionDisabled,
  roundGroupState,
  isDrawVotingRound,
  userHasSelectedDrawing,
}: IGetStudentConfirmButtonDisabledProps): boolean => {
  const selectedChoices = getSelectedChoices(roundGroupState);
  const userSelectedChoice = useMemo(
    () => selectedChoices?.find((c) => c.user_id === student.id),
    [selectedChoices, student.id]
  );

  const userHasSelected = !!userSelectedChoice;

  const allDragsortItemsPlaced = useMemo(
    () => roundGroupState.dragsort?.items.every((item) => item.zone_id !== "0"),
    [roundGroupState]
  );

  const userHasDrawnOnCanvas = useMemo(
    () =>
      roundGroupState.draw?.strokes?.some(
        (stroke) => stroke.user_id === student.id
      ),
    [roundGroupState?.draw?.strokes, student.id]
  );

  switch (variant) {
    case PracticeSessionItemVariantType.CoopRadios:
    case PracticeSessionItemVariantType.CoopChecks:
      return !userHasSelected;
    case PracticeSessionItemVariantType.CoopTextMatch:
    case PracticeSessionItemVariantType.CoopTextMatchNumeric:
      return isTextSubmissionPhase
        ? !!isTextSubmissionDisabled
        : !userHasSelected;
    case PracticeSessionItemVariantType.CoopDragsort:
      return !allDragsortItemsPlaced;
    case PracticeSessionItemVariantType.CoopDraw:
      return isDrawVotingRound
        ? !userHasSelectedDrawing
        : !userHasDrawnOnCanvas;
    default:
      return !userHasSelected;
  }
};
