import { usePrevious } from "@chakra-ui/react";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { AssignmentGradebookScreen } from "adminComponents/screens/AssignmentGradebookScreen";
import {
  useFetchClassroomAssignments,
  useFetchClassroomUsers,
  useFetchClassrooms,
} from "links/lib/features/classrooms";
import { IClassroom, UsersGroupsRole } from "links/lib/types";
import { useNavigationData } from "screens/TeacherDashboard/contexts/TeacherNavigationDataProvider";

export const TeacherAssignmentGradebook: React.FC = () => {
  const { classroomId } = useParams() as { classroomId: string };
  const previousClassroomId = usePrevious(classroomId);
  const history = useHistory();
  const { navigationData } = useNavigationData();
  const [selectedClassroomId, setSelectedClassroomId] = useState(classroomId);

  const [now] = useState(moment());
  const dates = useMemo(() => {
    const dates: { [key: string]: Date } = {};

    dates.now = now.toDate();

    dates.pastStart = now.clone().subtract(1, "year").toDate();
    dates.pastEnd = now.clone().subtract(2, "minute").toDate();

    return dates;
  }, [now]);

  const classroomsFetch = useFetchClassrooms({});
  const classroomStudentsFetch = useFetchClassroomUsers({
    classroom_id: selectedClassroomId,
    group_roles: [UsersGroupsRole.Member],
  });
  const activeAssignmentsFetch = useFetchClassroomAssignments({
    classroom_id: selectedClassroomId,
    start_date: dates.now,
    end_date: dates.now,
    sort_desc: true,
    limit: 100,
  });
  const pastAssignmentsFetch = useFetchClassroomAssignments({
    classroom_id: selectedClassroomId,
    start_date: dates.pastStart,
    end_date: dates.pastEnd,
    sort_desc: true,
    limit: 100,
  });

  const assignmentDetails = useMemo(() => {
    const assignments = [];
    if (
      activeAssignmentsFetch.data?.assignments &&
      activeAssignmentsFetch.data?.assignments?.length > 0
    ) {
      assignments.push(...activeAssignmentsFetch.data?.assignments);
    }

    if (
      pastAssignmentsFetch.data?.assignments &&
      pastAssignmentsFetch.data?.assignments?.length > 0
    ) {
      assignments.push(
        ...pastAssignmentsFetch.data?.assignments.filter((assignment) => {
          // remove any assignments that are currently active
          return moment(assignment.ends_at).isBefore(now);
        })
      );
    }

    const assignmentDetails = assignments.map((assignment) => ({
      assignment,
      practiceSet: assignment.practice_set,
    }));

    return assignmentDetails;
  }, [
    activeAssignmentsFetch.data?.assignments,
    pastAssignmentsFetch.data?.assignments,
    now,
  ]);

  const students = useMemo(() => {
    return (
      classroomStudentsFetch.data?.users.filter(
        (user) =>
          classroomStudentsFetch.data?.users_groups.find(
            (usersGroup) => usersGroup.user_id == user.id
          )?.user_role === UsersGroupsRole.Member
      ) || []
    );
  }, [classroomStudentsFetch.data]);

  const handleClassroomChange = (classroom: IClassroom) => {
    setSelectedClassroomId(classroom.id);
    history.replace(`/t/classrooms/${classroom.id}/assignment-gradebook`);
  };

  useEffect(() => {
    // catch back button or updates to classroomId in url
    if (
      previousClassroomId !== classroomId &&
      classroomId !== selectedClassroomId
    ) {
      setSelectedClassroomId(classroomId);
    }
  }, [classroomId, previousClassroomId, selectedClassroomId]);

  useEffect(() => {
    // if we navigate here without a classroomId in the url
    const defaultClassroomId = classroomsFetch.data?.classrooms[0]?.id;
    if (!classroomId && !selectedClassroomId && defaultClassroomId) {
      setSelectedClassroomId(defaultClassroomId);
      history.replace(
        `/t/classrooms/${defaultClassroomId}/assignment-gradebook`
      );
    }
  }, [
    classroomId,
    selectedClassroomId,
    classroomsFetch.data?.classrooms,
    history,
  ]);

  const isLoading = classroomsFetch.isLoading;
  const isScoresLoading =
    activeAssignmentsFetch.isLoading ||
    pastAssignmentsFetch.isLoading ||
    classroomStudentsFetch.isLoading;

  return (
    <AssignmentGradebookScreen
      classrooms={classroomsFetch.data?.classrooms || []}
      navigationData={navigationData}
      assignmentDetails={assignmentDetails}
      isScoresLoading={isScoresLoading}
      isLoading={isLoading}
      students={students}
      selectedClassroomId={
        selectedClassroomId || classroomsFetch.data?.classrooms[0]?.id
      }
      handleClassroomChange={handleClassroomChange}
    />
  );
};
