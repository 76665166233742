import { Box, Flex, SimpleGrid, Text, VStack } from "@chakra-ui/react";
import React from "react";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { quickPlayStudenRoundReviewInterstitialTimeMs } from "links/lib/constants";
import {
  ISessionDraggableSortItem,
  PracticeSessionItemVariantType,
  QuestionType,
  SessionGameType,
} from "links/lib/types";
import { Button } from "sessionComponents/atoms/Button";
import { Card } from "sessionComponents/atoms/Card";
import { GameBackground } from "sessionComponents/atoms/GameBackground";
import { Icon } from "sessionComponents/atoms/Icon";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { useRichTextToSpeakable } from "sessionComponents/hooks/useRichTextToSpeakable";
import { useTextReader } from "sessionComponents/hooks/useTextReader";
import { QuestionResponse } from "sessionComponents/molecules/QuestionResponse";
import { RoundReviewAnimatedTransition } from "sessionComponents/molecules/RoundReviewAnimatedTransition";
import { RoundReviewButtons } from "sessionComponents/organisms/RoundReviewButtons";
import { useDetectOrientation } from "sessionComponents/theme/hooks/useDetectOrientation";
import { getGameChoiceType } from "sessionComponents/utils/getGameChoiceType";
import { RichTextRenderer } from "sharedComponents/atoms/RichTextRenderer";

type ClassificationRoundReviewProps = {
  outerGame?: SessionGameType;
  isClassSession: boolean;
  splashScene: React.ReactElement;
  prompt?: string | null;
  categories: {
    id: string;
    image_url: string;
    text: string;
  }[];
  items: ISessionDraggableSortItem[];
  getIsItemCorrect: (id: string) => boolean;
  onFeedbackClick: () => void;
  showFeedbackButton: boolean;
  onConfirmButtonClick: () => void;
  isTeacher: boolean;
  isFinalRound?: boolean;
  isConfirmLoading: boolean;
  showAccuracyBar: boolean;
  accuracyPercentages?: { [key in string]: number };
  variant: PracticeSessionItemVariantType;
  questionType: QuestionType;
};

export const ClassificationRoundReview: React.FC<
  ClassificationRoundReviewProps
> = ({
  outerGame = SessionGameType.TheBigBoard,
  isClassSession,
  splashScene,
  prompt,
  categories,
  items,
  getIsItemCorrect,
  onFeedbackClick,
  showFeedbackButton,
  onConfirmButtonClick,
  isConfirmLoading,
  isFinalRound,
  isTeacher,
  showAccuracyBar,
  accuracyPercentages,
  variant,
  questionType,
}) => {
  const { match } = useBreakpoints();
  const { isPortrait } = useDetectOrientation();

  const { handleSpeak, handleCancelSpeak, isSpeaking, isSpeechEnabled } =
    useTextReader();
  const { convert } = useRichTextToSpeakable();

  const onSpeechClick = () => {
    if (isSpeaking()) {
      handleCancelSpeak();
      return;
    }

    const speakablePrompt = prompt ? convert(prompt) : "";

    handleSpeak(speakablePrompt);
  };

  const hideFeedbackButtonArea =
    !isTeacher && isClassSession && !showFeedbackButton;

  // if there are more than 2 categories, show a max of 3 horizontally, else show 2, and always show 1 on mobile
  const columnCount = isPortrait
    ? 1
    : categories.length > 2
    ? 3
    : categories.length;

  return (
    <>
      <RoundReviewAnimatedTransition
        splashScene={splashScene}
        canSkip={!isClassSession}
        splashLifetimeMs={
          outerGame === SessionGameType.QuickPlay
            ? quickPlayStudenRoundReviewInterstitialTimeMs // Quick Play uses a longer 6s interstitial than the default 5s
            : undefined
        }
      >
        <GameBackground h="full" w="full" flexDirection="column">
          <Box
            h="full"
            w="full"
            padding={pxToRem(match.padding)}
            overflow="auto"
          >
            <VStack spacing={pxToRem(match.margin)}>
              <Flex>
                <Text
                  as="div"
                  textStyle="gameText"
                  fontSize={pxToRem(match.fontSize)}
                  textAlign="center"
                  maxWidth="full"
                >
                  {prompt && <RichTextRenderer content={prompt} />}
                </Text>
                {isSpeechEnabled && (
                  <Box
                    marginLeft={pxToRem(8)}
                    display="flex"
                    justifyContent="flex-end"
                    h={pxToRem(match.iconSize)}
                  >
                    <Button
                      onClick={onSpeechClick}
                      variant="buttonFilled"
                      borderRadius="full"
                      height="unset"
                    >
                      <Icon boxSize={pxToRem(match.iconSize)} icon="sound_on" />
                    </Button>
                  </Box>
                )}
              </Flex>
              <SimpleGrid
                columns={columnCount}
                spacing={pxToRem(match.margin)}
                w="full"
              >
                {categories.map((zone) => {
                  const categoryItems = items.filter(
                    (item) => item.zone_id === zone.id
                  );
                  return (
                    <Card key={zone.id} backgroundColor="primary.warm-white">
                      <Text
                        as="div"
                        textStyle="gameText"
                        fontSize={pxToRem(match.fontSize)}
                        textAlign="center"
                        marginBottom={pxToRem(match.margin)}
                      >
                        <RichTextRenderer content={zone.text} />
                      </Text>
                      {categoryItems.map((option) => {
                        const isCorrect = getIsItemCorrect(option.id);

                        return (
                          <Box
                            key={option.id}
                            marginTop={pxToRem(match.margin / 2)}
                          >
                            <QuestionResponse
                              variant={variant}
                              choiceType={getGameChoiceType(variant)}
                              text={option.text}
                              imageUrl={option.image_url}
                              imageAltText={option.image_alt_text}
                              success={isCorrect && !isTeacher}
                              accuracyBarBackgroundColor="utility.error"
                              accuracyBarFillColor="utility.success"
                              showAsSelectable={!isTeacher}
                              error={!isCorrect}
                              questionType={questionType}
                              showAccuracyBar={showAccuracyBar}
                              accuracyPercentage={
                                accuracyPercentages?.[
                                  option.id as keyof typeof accuracyPercentages
                                ]
                              }
                              maxWidth="100%"
                            />
                          </Box>
                        );
                      })}
                    </Card>
                  );
                })}
              </SimpleGrid>
            </VStack>
          </Box>
          {!hideFeedbackButtonArea && (
            <Box
              w="full"
              padding={pxToRem(match.padding)}
              backgroundColor="primary.warm-white"
            >
              <RoundReviewButtons
                showFeedbackButton={showFeedbackButton}
                onFeedbackClick={onFeedbackClick}
                onConfirmButtonClick={onConfirmButtonClick}
                isTeacher={isTeacher}
                isFinalRound={isFinalRound}
                isClassSession={isClassSession}
                isConfirmLoading={isConfirmLoading}
                isDrawItem={false}
              />
            </Box>
          )}
        </GameBackground>
      </RoundReviewAnimatedTransition>
    </>
  );
};
