import { AxiosError } from "axios";
import { useMount } from "react-use";

import { localStoreAuthKeyName } from "links/lib/constants";
import { useAuth } from "links/lib/features/auth";
import { fetchCurrentUser } from "links/lib/features/users/useFetchCurrentUser";
import { IUseAsyncReturn, useAsync } from "links/lib/hooks/useAsync";
import { useAxios } from "links/lib/hooks/useAxios";
import { useLocalStore } from "links/lib/hooks/useLocalStore";
import { type IUser, UserRole } from "links/lib/types";

interface IExecuteResult {
  authUser?: IUser;
  authToken?: string;
}

export default function useFetchAuthFromProvider(opts: {
  enabled?: boolean;
  paramCode: string;
  paramState: string;
  onError?: (err: AxiosError) => void;
  onBadRequest?: (err: Error) => void;
}): IUseAsyncReturn<IExecuteResult, AxiosError> {
  const { enabled = true, paramCode, paramState, onBadRequest, onError } = opts;

  const { setAuth, authUser, authToken, isAuthLoading } = useAuth();
  const axios = useAxios();
  const localStore = useLocalStore();

  const fetchAuthFromProvider = async () => {
    const response = await axios
      .get(`/v1/oauth/callback?code=${paramCode}&state=${paramState}`)
      .then((r) => {
        return r.data as unknown as { user: IUser; token: string };
      })
      .catch((err: AxiosError) => {
        if (onBadRequest && err.response?.status === 400) {
          onBadRequest(
            new Error((err.response?.data as { message: string }).message)
          );
          return;
        }

        onError?.(err);
      });

    const { token } = response || { token: "" };

    if (!token) {
      return { authUser, authToken };
    }

    // save the auth token in local store
    await localStore.setItem(localStoreAuthKeyName, token);

    // update axios client to use current JWT
    // for authorization header on all requests
    axios.defaults.headers.common["Authorization"] = token;

    // fetch full user info
    const userRes = await fetchCurrentUser(axios);

    if (userRes.user.role !== UserRole.Student && !userRes.user.is_guest) {
      // eslint-disable-next-line
      (window as any).pear?.identifyUser?.(userRes.pear_token);
    }

    const data = {
      authUser: userRes.user,
      authToken: token,
      authRequiredAgreements: userRes.required_agreements,
      authFeatureFlags: userRes.features,
      authSharingSettings: userRes.sharing_settings,
      authLicense: userRes.license,
      hasNoPremiumAccess: !userRes.license.has_premium_access,
      isGhostUser: userRes.user.ghost_user_email != "",
      isAuthLoading,
    };

    setAuth(data);

    return data;
  };

  const { isLoading, isSuccess, error, data, execute } = useAsync<{
    authUser?: IUser;
    authToken?: string;
  }>(fetchAuthFromProvider, {
    initState: {
      isLoading: enabled,
    },
  });

  useMount(() => {
    if (!enabled) return;
    execute();
  });

  return {
    isLoading,
    isSuccess,
    error,
    data,
    execute,
  };
}
