import { useAxios } from "links/lib/hooks/useAxios";
import { IClassroomJoinLink } from "links/lib/types";

import { useCreateClassroomJoinLink } from ".";
import { IUseAsyncReturn, useAsync } from "../../hooks/useAsync";

export interface IArgs {
  classroom_id: string;
}

export interface IResult {
  groups_join_links: Array<IClassroomJoinLink>;
  current_link: IClassroomJoinLink;
}

export default function useFetchClassroomJoinLinksOnce(): IUseAsyncReturn<
  IResult,
  IArgs
> {
  const axios = useAxios();
  const linkCreate = useCreateClassroomJoinLink();

  const fetchLinks = async ({ classroom_id }: IArgs) => {
    const res = await axios
      .get(`/v1/groups/${classroom_id}/groups-join-links`)
      .then((r) => {
        return r.data as IResult;
      });

    // Sort the longest living link to the front
    res.groups_join_links.sort((a, b) => {
      const dateA = new Date(a.expires_at);
      const dateB = new Date(b.expires_at);

      if (dateA >= dateB) {
        return -1;
      } else {
        return 1;
      }
    });

    // Pull longest living link
    const existingLink = res.groups_join_links[0];

    if (existingLink) {
      res.current_link = existingLink;
      return res;
    }

    const createResult = await linkCreate.execute({
      classroom_id: classroom_id,
    });
    if (createResult) {
      res.current_link = createResult.groups_join_link;
    }

    return res;
  };

  const { isLoading, error, data, execute, isSuccess } = useAsync<
    IResult,
    IArgs
  >(fetchLinks);

  return {
    isLoading,
    error,
    data,
    execute,
    isSuccess,
  };
}
