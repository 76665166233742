import React from "react";

import { SeasonMapMusic } from "links/lib/types";
import { AudioProvider } from "sharedComponents/contexts/audio";

import spriteData from "./resource/output.json";
import spriteMP3 from "./resource/output.mp3";
import spriteWebM from "./resource/output.webm";
import QuietOnTheSetMP3 from "./resource/quietOnTheSet.mp3";
import QuietOnTheSetWebM from "./resource/quietOnTheSet.webm";

const backgroundClips = {
  [SeasonMapMusic.QuietOnTheSet]: [
    { src: QuietOnTheSetWebM, type: "audio/webm" },
    { src: QuietOnTheSetMP3, type: "audio/mpeg" },
  ],
};

const SEASON_MAP_VOLUME_KEY = "SEASON_MAP_VOLUME";

export const Season4MapAudio: React.FC = ({ children }) => {
  return (
    <AudioProvider
      backgroundClips={backgroundClips}
      srcVolume={1}
      spriteSrc={[spriteWebM, spriteMP3]}
      sprite={spriteData.sprite}
      volumeStorageKey={SEASON_MAP_VOLUME_KEY}
    >
      {children}
    </AudioProvider>
  );
};
