import { useAnalytics } from "lib/contexts/analytics";
import { AnalyticsEvent, IPracticeSet, QuestionType } from "links/lib/types";

export interface IUseItemAnalyticsGenericArgs {
  practiceSet: IPracticeSet;
  itemId?: string;
}

export interface IUseItemAnalyticsGenericResult {
  trackItemDeletion(questionType: QuestionType): void;
  trackItemMutation(questionType: QuestionType): void;
  trackItemClose(questionType: QuestionType): void;
}

const useItemAnalyticsGeneric = (
  args: IUseItemAnalyticsGenericArgs
): IUseItemAnalyticsGenericResult => {
  const { practiceSet, itemId } = args;
  const { trackEvent } = useAnalytics();

  const eventProps = {
    practice_set_id: practiceSet.id,
    item_id: itemId,
  };

  return {
    trackItemDeletion: (questionType: QuestionType) => {
      trackEvent(
        AnalyticsEvent.TeacherDashboard_PracticeSetDetail_QuestionFlyout_DeleteItem,
        {
          ...eventProps,
          questionType,
        }
      );
    },
    trackItemMutation: (questionType: QuestionType) => {
      trackEvent(
        AnalyticsEvent.TeacherDashboard_PracticeSetDetail_QuestionFlyout_MutateItem,
        {
          ...eventProps,
          questionType,
        }
      );
    },
    trackItemClose: (questionType: QuestionType) => {
      trackEvent(
        AnalyticsEvent.TeacherDashboard_PracticeSetDetail_QuestionFlyout_CloseItem,
        {
          ...eventProps,
          questionType,
        }
      );
    },
  };
};

export default useItemAnalyticsGeneric;
