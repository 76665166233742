import { Box, Flex, useMultiStyleConfig } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Card } from "adminComponents/atoms/Card";
import { Divider } from "adminComponents/atoms/Divider";
import { ExpandableText } from "adminComponents/atoms/ExpandableText";
import { Heading } from "adminComponents/atoms/Heading";
import {
  AccuracyType,
  ReportingAccuracyTag,
} from "adminComponents/atoms/ReportingAccuracyTag";
import CoverImageBlob from "adminComponents/molecules/CoverImageBlob";
import { PieChartWithLabel } from "adminComponents/molecules/PieChartWithLabel";
import {
  TEACHER_REPORTING_PRACTICE_SET_HIGH_ACCURACY_THRESHOLD,
  TEACHER_REPORTING_PRACTICE_SET_LOW_ACCURACY_THRESHOLD,
} from "links/lib/constants";
import { IPracticeSet } from "links/lib/types";

type practiceSet = Partial<IPracticeSet> &
  Pick<
    IPracticeSet,
    | "title"
    | "description"
    | "cover_image_icon"
    | "cover_image_bg_pattern"
    | "cover_image_bg_color_scheme"
  >;

export interface IProps {
  practiceSet: practiceSet;
  accuracy: number;
}

export const ClassroomPracticeSetReportHeroCard: React.FC<IProps> = ({
  practiceSet,
  accuracy,
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });

  const styles = useMultiStyleConfig("AdminHeroCard", {
    variant: "classroomPracticeSetReport",
  });

  const {
    title,
    description,
    cover_image_icon: icon,
    cover_image_bg_pattern: backgroundPattern,
    cover_image_bg_color_scheme: backgroundColorScheme,
  } = practiceSet;

  return (
    <Card
      borderColor="primary.tan"
      backgroundColor="primary.white"
      variant="adminCardThickBorder"
    >
      <Flex sx={styles.main}>
        <Box sx={styles.image}>
          <CoverImageBlob
            backgroundColorScheme={backgroundColorScheme}
            backgroundPattern={backgroundPattern}
            icon={icon}
            boxSize={115}
          />
        </Box>
        <Flex sx={styles.studentDetails} overflow="hidden">
          <Box sx={styles.studentDetailsText}>
            <Heading as="h1" variant="adminH3" sx={styles.heading}>
              {title}
            </Heading>
            <ExpandableText
              textVariant="adminP1"
              noOfLines={2}
              sx={styles.text}
            >
              {description}
            </ExpandableText>
          </Box>
          <Divider color="primary.light-gray" />
          <Flex sx={styles.studentDetailsStats}>
            <PieChartWithLabel
              percentage={accuracy}
              text={t("classroomAssignmentReport.classAccuracy")}
            />
            {(accuracy <=
              TEACHER_REPORTING_PRACTICE_SET_LOW_ACCURACY_THRESHOLD ||
              accuracy >=
                TEACHER_REPORTING_PRACTICE_SET_HIGH_ACCURACY_THRESHOLD) && (
              <ReportingAccuracyTag
                accuracyType={AccuracyType.Class}
                isHighAccuracy={
                  accuracy >=
                  TEACHER_REPORTING_PRACTICE_SET_HIGH_ACCURACY_THRESHOLD
                }
              />
            )}
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};
