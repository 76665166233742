import { useMemo } from "react";
import { useQueries } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { IAssignment } from "../../types";
import { fetchClassroomAssignmentsQuery } from "./useFetchClassroomAssignments";

export interface IUseFetchClassroomAssignmentsBulkProps {
  classroom_ids?: Array<string>;
  start_date: Date;
  end_date: Date;
  limit: number;
  keepPreviousData?: boolean;
  enabled?: boolean;
  onSuccess?: (data: { [classroom_id: string]: Array<IAssignment> }) => void;
}

export interface IUseFetchClassroomAssignmentsBulkResult {
  isLoading: boolean;
  assignmentData: { [classroom_id: string]: Array<IAssignment> };
}

const useFetchClassroomAssignmentsBulk = ({
  classroom_ids = [],
  start_date,
  end_date,
  limit,
  keepPreviousData,
  enabled = true,
  onSuccess,
}: IUseFetchClassroomAssignmentsBulkProps): IUseFetchClassroomAssignmentsBulkResult => {
  const axios = useAxios();

  const assignmentQueries = useQueries(
    classroom_ids.map((classroom_id) => {
      const params = {
        classroom_id,
        sort_desc: false,
        limit,
        start_date,
        end_date,
        enabled,
      };

      return {
        queryKey: [
          "assignments",
          { classroom_id },
          { start_date, end_date, limit },
        ],
        queryFn: fetchClassroomAssignmentsQuery(axios, params),
        keepPreviousData,
        enabled,
      };
    })
  );

  const assignmentData = useMemo(() => {
    const assignmentsByClassroom: { [key: string]: Array<IAssignment> } = {};

    assignmentQueries.forEach((q, i) => {
      assignmentsByClassroom[classroom_ids[i]] = q.data?.assignments || [];
    });

    return assignmentsByClassroom;
  }, [assignmentQueries, classroom_ids]);

  if (assignmentQueries.every((q) => q.isSuccess)) {
    onSuccess?.(assignmentData);
  }

  return {
    isLoading: assignmentQueries.some((q) => q.isLoading),
    assignmentData,
  };
};

export default useFetchClassroomAssignmentsBulk;
