import { Box, useBreakpointValue, useMultiStyleConfig } from "@chakra-ui/react";
import React, {
  Children,
  FC,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
// import Swiper core and required modules
import {
  A11y,
  Keyboard,
  Navigation,
  Pagination,
  SwiperOptions,
  Swiper as SwiperProps,
} from "swiper";
import "swiper/modules/a11y/a11y.min.css";
import "swiper/modules/keyboard/keyboard.min.css";
import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/pagination/pagination.min.css";
import {
  Swiper as SwiperReact,
  SwiperProps as SwiperReactProps,
  SwiperSlide,
  SwiperSlideProps,
} from "swiper/react";

import { ColorScheme } from "adminComponents";
import { IconButton } from "adminComponents/atoms/IconButton";

// Import Swiper styles
// IMPORTANT: note that the first import is a modified local copy of Swiper's
// main css file for version 8.4.4, retrieved from here:
// https://unpkg.com/swiper@8.4.4/swiper.min.css
//
// This modified version of the file has had a @font-face tag removed. The font
// was defined as a data url, which violated the CSP configuration of the site.
// We're also not using the font, which defined some icons for carousels which
// we have replaced.
//
// If it's necessary to replace this file for a future version of Swiper, you
// should be able to visit unpkg.com using a url similar to that seen above
// to get the original file, and then remove the @font-face and overwrite
// the local version of the file.
//
// This is a known issue in Swiper but it's not clear when it will be resolved:
// https://github.com/nolimits4web/swiper/issues/4990
import "./css/swiper.removed-data-url.min.css";

const getClassNameForSwiperInstance = (
  id: string | undefined,
  className: string
) => {
  if (!id) {
    return className;
  }
  return `${className}-${id}`;
};

export interface ICarouselPosition {
  isBeginning: boolean;
  isEnd: boolean;
  activeIndex: number;
}

interface IProps {
  bgColor?: ColorScheme;
  navigationShadow?: boolean;
  showArrows?: boolean;
  showDots?: boolean;
  alignItems?: "left" | "center" | "right";
  slideProps?: SwiperSlideProps;
  variant?: string;
  nextElSelector?: string;
  previousElSelector?: string;
  stateUpdatedHandler?: ({
    isBeginning,
    isEnd,
    activeIndex,
  }: ICarouselPosition) => void;
}

export const Carousel: FC<IProps & SwiperReactProps> = ({
  bgColor,
  children,
  navigationShadow,
  showArrows = false,
  showDots = false,
  slideProps,
  variant,
  alignItems = "center",
  nextElSelector,
  previousElSelector,
  stateUpdatedHandler,
  ...swiperProps
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const isMobile = useBreakpointValue({ base: true, lg: false });

  const [{ isBeginning, isEnd, activeIndex }, setCarouselPositionState] =
    useState<ICarouselPosition>({
      isBeginning: false,
      isEnd: false,
      activeIndex: 0,
    });

  const styles = useMultiStyleConfig("AdminCarousel", {
    bgColor,
    isBeginning,
    isEnd,
    showDots,
    variant,
    alignItems,
  });

  const defaultSwiperParams: SwiperOptions = useMemo(
    () => ({
      keyboard: {
        enabled: true,
      },
      loop: false,
      navigation: {
        prevEl: getClassNameForSwiperInstance(
          swiperProps.id,
          previousElSelector || ".admin-Carousel-prevButton"
        ),
        nextEl: getClassNameForSwiperInstance(
          swiperProps.id,
          nextElSelector || ".admin-Carousel-nextButton"
        ),
      },
      noSwiping: !isMobile,
      noSwipingClass: "swiper-slide",
      pagination: {
        clickable: true,
      },
      slidesPerView: "auto",
      spaceBetween: 24,
    }),
    [isMobile, nextElSelector, previousElSelector, swiperProps.id]
  );

  const showArrow = useMemo(
    () => !isMobile && showArrows,
    [isMobile, showArrows]
  );

  const addNavigationModule = useMemo(
    () => showArrows || nextElSelector,
    [nextElSelector, showArrows]
  );

  const showBeginningGradient = useMemo(
    () => bgColor && navigationShadow && !isBeginning,
    [bgColor, isBeginning, navigationShadow]
  );

  const showEndGradient = useMemo(
    () => bgColor && navigationShadow && !isEnd,
    [bgColor, isEnd, navigationShadow]
  );

  const setCarouselPosition = ({
    isEnd,
    isBeginning,
    activeIndex,
  }: SwiperProps) => {
    setCarouselPositionState({ isBeginning, isEnd, activeIndex });
  };

  const modules = [A11y, Keyboard];

  if (addNavigationModule) {
    modules.push(Navigation);
  }

  if (showDots) {
    modules.push(Pagination);
  }

  const slides = useMemo(
    () =>
      Children.map(children, (child) => (
        <SwiperSlide {...slideProps}>{child}</SwiperSlide>
      )),
    [children, slideProps]
  );

  const stateUpdatedHandlerRef = useRef(stateUpdatedHandler);
  useEffect(() => {
    stateUpdatedHandlerRef.current?.({ isBeginning, isEnd, activeIndex });
  }, [isBeginning, isEnd, activeIndex, stateUpdatedHandlerRef]);

  return (
    <Box sx={styles.wrapper}>
      {showArrow && (
        <Box sx={styles.prevArrow}>
          <IconButton
            ariaLabel={t("common.previous")}
            className={getClassNameForSwiperInstance(
              swiperProps.id,
              "admin-Carousel-prevButton"
            )}
            colorScheme="primary.warm-white"
            disabled={isBeginning}
            icon="keyboard_arrow_left"
            shape="type1"
            variant="ghost"
          />
        </Box>
      )}
      <SwiperReact
        {...defaultSwiperParams}
        modules={modules}
        onInit={setCarouselPosition}
        onSlideChange={setCarouselPosition}
        {...swiperProps}
        // Force re-initialization in order for the arrow btn click handlers to get reset
        key={isMobile ? "mobile" : "desktop"}
      >
        {showBeginningGradient && (
          <Box
            bgGradient={`linear(to right, ${bgColor}, rgba(253, 250, 243, 0) 142.71%)`}
            sx={styles.scrimLeft}
          />
        )}
        {slides}
        {showEndGradient && (
          <Box
            bgGradient={`linear(to left, ${bgColor}, rgba(253, 250, 243, 0) 142.71%)`}
            sx={styles.scrimRight}
          />
        )}
      </SwiperReact>
      {showArrow && (
        <Box sx={styles.nextArrow}>
          <IconButton
            ariaLabel={t("common.next")}
            className={getClassNameForSwiperInstance(
              swiperProps.id,
              "admin-Carousel-nextButton"
            )}
            colorScheme="primary.warm-white"
            disabled={isEnd}
            icon="keyboard_arrow_right"
            shape="type1"
            variant="ghost"
          />
        </Box>
      )}
    </Box>
  );
};
