import { Box, useMultiStyleConfig } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

import { ProgressBar } from "adminComponents/atoms/ProgressBar";
import { Text } from "adminComponents/atoms/Text";

export interface IProps {
  level: number;
  value: number;
}

export const LevelProgressBar: React.FC<IProps> = ({ level, value }) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });

  const styles = useMultiStyleConfig("AdminLevelProgressBar", {});
  const ariaLabelId = uuidv4();

  return (
    <Box sx={styles.container}>
      <Box sx={styles.level}>
        <Text id={ariaLabelId} variant="adminP2Bold">
          {t("common.level", { number: level })}
        </Text>
      </Box>

      <ProgressBar
        aria-labelledby={ariaLabelId}
        variant="adminLevelProgress"
        value={value}
      />
    </Box>
  );
};
