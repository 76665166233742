import { UseInfiniteQueryResult, useInfiniteQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";

export interface IFetchUserFollowersInfiniteResponse {
  user_ids: Array<string>;
  page: number;
  per_page: number;
}

export interface IFetchUserFollowersInfiniteParams {
  userId: string;
  perPage?: number;
  disabled?: boolean;
}

export default function useFetchUserFollowersInfinite(
  args: IFetchUserFollowersInfiniteParams
): UseInfiniteQueryResult<IFetchUserFollowersInfiniteResponse> {
  const axios = useAxios();

  const { disabled, userId, perPage } = args;

  const query = useInfiniteQuery<IFetchUserFollowersInfiniteResponse>(
    ["fetchUserFollowersInfinite", { userId }],
    async ({ pageParam = 1 }): Promise<IFetchUserFollowersInfiniteResponse> => {
      return await axios
        .get(`/v1/users/${userId}/followers`, {
          params: {
            page: pageParam,
            per_page: perPage,
          },
        })
        .then((r) => r.data as IFetchUserFollowersInfiniteResponse);
    },
    {
      enabled: !disabled,
      getNextPageParam: (lastPage) => {
        return lastPage.page + 1;
      },
    }
  );

  return query;
}
