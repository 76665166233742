import { ColorScheme } from "adminComponents";
import { hoverStyles, pxToRem } from "adminComponents/utils";

export const Radio = {
  variants: {
    adminRadio: ({
      colorScheme = "primary.golden",
    }: {
      colorScheme?: ColorScheme;
    }): Record<string, unknown> => {
      return {
        container: {},
        control: {
          w: pxToRem(24),
          h: pxToRem(24),
          bgColor: "primary.white",
          borderColor: "primary.medium-gray",
          borderWidth: pxToRem(1),
          _checked: {
            _before: {
              bg: colorScheme || "primary.golden",
              w: pxToRem(16),
              h: pxToRem(16),
            },
            _disabled: {
              bgColor: "primary.white",
            },
            _focus: {
              boxShadow: "none",
              borderColor: "utility.focus",
              borderWidth: pxToRem(2),
              _disabled: {
                borderColor: "primary.light-gray",
                borderWidth: pxToRem(1),
              },
              _hover: hoverStyles({
                _disabled: {
                  borderColor: "primary.light-gray",
                },
                borderColor: colorScheme || "primary.golden",
                borderWidth: pxToRem(1),
              }),
            },
            _hover: hoverStyles({
              bgColor: "primary.white",
              borderColor: colorScheme || "primary.golden",
            }),
            bgColor: "primary.white",
            borderColor: "primary.medium-gray",
          },
          _disabled: {
            _before: {
              bg: "primary.golden-light",
            },
            bgColor: "primary.white",
            borderColor: "primary.light-gray",
          },
          _focus: {
            boxShadow: "none",
            borderColor: "utility.focus",
            borderWidth: pxToRem(2),
            _disabled: {
              borderColor: "primary.light-gray",
              borderWidth: pxToRem(1),
            },
            _hover: hoverStyles({
              borderWidth: pxToRem(1),
            }),
          },
          _hover: hoverStyles({
            borderColor: colorScheme || "primary.golden",
            _disabled: {
              borderColor: "primary.light-gray",
            },
          }),
        },
      };
    },
  },
};
