import { Box, ComponentWithAs, InputProps, forwardRef } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { IAdminInputProps, Input } from "adminComponents/atoms/Input";

const DatePickerInput: ComponentWithAs<"input", IAdminInputProps> = forwardRef<
  InputProps,
  "input"
>((props, ref) => <Input {...props} ref={ref} />);

export const DatePicker: React.FC<ReactDatePickerProps> = ({
  dateFormat = "EEEE MMMM d",
  onChange,
  selected,
  ...rest
}) => {
  const [date, setDate] = useState(selected);

  const handleChange = (date: Date, event: React.SyntheticEvent<unknown>) => {
    setDate(date);
    onChange?.(date, event);
  };

  useEffect(() => {
    setDate(selected);
  }, [selected]);

  return (
    <Box
      __css={{
        ".react-datepicker__tab-loop": {
          lineHeight: "21px",
          "*, *::before, *::after": {
            boxSizing: "content-box",
          },
        },
      }}
    >
      <ReactDatePicker
        {...rest}
        customInput={
          <DatePickerInput rightIcon={{ icon: "keyboard_arrow_down" }} />
        }
        dateFormat={dateFormat}
        onChange={handleChange}
        popperModifiers={[
          {
            name: "arrow",
            options: {
              padding: 50,
            },
          },
        ]}
        selected={date}
      />
    </Box>
  );
};
