import { Flex, useMultiStyleConfig } from "@chakra-ui/react";
import { FormikProps, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Card } from "adminComponents/atoms/Card";
import { IOption } from "adminComponents/atoms/Dropdown";
import { Heading } from "adminComponents/atoms/Heading";
import {
  IGradeLevel,
  ISubject,
  SharingSettingsVisibility,
} from "links/lib/types";

import { LocationForm, TeacherInfoForm } from "./components";
import {
  fields,
  setInitialValues,
  validationSchema,
} from "./components/formConfig";

export interface IForm {
  country: string;
  grades?: string[];
  language: string;
  title: string;
  nickname: string;
  state: string;
  subjects?: string[];
  timezone: string;
  schoolOrDistrictName: string;
  schoolOrDistrictPostalCode: string;
  sharingVisibility: SharingSettingsVisibility;
  acceptedAgreementIds: Array<string>;
}

export type Field = keyof IForm;
export type Formik = FormikProps<IForm>;

export interface IProps {
  handleSubmit: (form: IForm) => void;
  handleChange: (form: IForm) => void;
  grades: Array<IGradeLevel>;
  subjects: Array<ISubject>;
  titles: Array<IOption>;
  isLoading: boolean;
  initialValues?: Partial<IForm>;
}

export const TeacherAccountInformationForm: React.FC<IProps> = ({
  handleSubmit,
  handleChange,
  grades,
  subjects,
  titles,
  isLoading,
  initialValues,
}) => {
  const [isFormChanged, setIsFormChanged] = useState(false);
  const { t } = useTranslation("admin", { useSuspense: false });
  const styles = useMultiStyleConfig("AdminTeacherAccountInformationForm", {});
  const form = useFormik<IForm>({
    initialValues: setInitialValues(initialValues),
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const nickname = values.nickname.trim();
      handleSubmit({
        ...values,
        nickname,
      });
    },
  });

  const { values, initialValues: initialFormValues } = form;

  // set isFormChanged true if any form values change
  useEffect(() => {
    const fieldChanged = Object.entries(values)?.find(([key, val]) => {
      const field = fields[key as Field];
      return val !== initialFormValues[field];
    });
    if (!isFormChanged && Boolean(fieldChanged)) {
      setIsFormChanged(true);
      handleChange(form.values);
    }
  }, [values, initialFormValues, isFormChanged, handleChange, form.values]);

  return (
    <Card
      sx={styles.outer}
      variant="adminCardThickBorder"
      borderColor="primary.tan"
    >
      <form onSubmit={form.handleSubmit}>
        <Flex sx={styles.headerContainer}>
          <Heading as="h2" variant="adminH4">
            {t("myAccountScreen.accountInformation")}
          </Heading>
        </Flex>
        <Flex sx={styles.formContainer}>
          <TeacherInfoForm
            agreements={[]}
            form={form}
            grades={grades}
            subjects={subjects}
            titles={titles}
            fieldStyles={styles.fieldContainer}
            isOnboarding={false}
          />
          <LocationForm form={form} fieldStyles={styles.fieldContainer} />
        </Flex>
        <Flex sx={styles.buttonContainer}>
          <Button
            size="sm"
            type="submit"
            variant="adminButtonFilled"
            disabled={!isFormChanged}
            sx={styles.button}
            isLoading={isLoading}
          >
            {t("common.save")}
          </Button>
        </Flex>
      </form>
    </Card>
  );
};
