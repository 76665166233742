import moment from "moment";
import React, { createContext, useContext, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useInterval } from "react-use";

import { useErrorToast } from "adminComponents/utils/toast";
import { useAuth } from "links/lib/features/auth";
import { useFetchAvatar } from "links/lib/features/avatars";
import {
  useFetchAverageStandardAccuraciesBulk,
  useFetchClassroomAssignmentsBulk,
  useFetchClassrooms,
} from "links/lib/features/classrooms";
import { IUseFetchClassroomAverageStandardAccuraciesResult } from "links/lib/features/classrooms/useFetchAverageStandardAccuracies";
import { useFetchPracticeSetsByUserExperience } from "links/lib/features/practiceSets";
import { useFetchSessions } from "links/lib/features/sessions";
import { useFetchStandards } from "links/lib/features/standards";
import {
  IAssignment,
  IClassroom,
  IFetchAvatarResponse,
  IPracticeSet,
  IPracticeSetSession,
  IStandard,
  UserXPMultiplier,
} from "links/lib/types";

interface IPracticeSetDetail {
  practice_set: IPracticeSet;
  classroom_id: string;
  maximum_xp: number;
  item_count: number;
}

export interface IStudentDashboardDataContext {
  avatarResponse?: IFetchAvatarResponse;
  activeAssignments: Array<IAssignment>;
  practiceSetDetailArray: Array<IPracticeSetDetail>;
  practiceSetDetailMap: {
    [practiceSetId: string]: IPracticeSetDetail;
  };
  classroomMap: { [classroomId: string]: IClassroom };
  liveSessionMap: { [sessionId: string]: IPracticeSetSession };
  classroomStandardAccuraciesMap: {
    [classroomId: string]: IUseFetchClassroomAverageStandardAccuraciesResult;
  };
  standards: Array<IStandard>;
  userXpMultiplier?: UserXPMultiplier;
  isLoading: boolean;
  // because assignment data is changing query every minute
  // it should be handled differently in loading states
  isAssignmentDataLoading: boolean;
}

export const StudentDashboardDateContext =
  createContext<IStudentDashboardDataContext>({
    activeAssignments: [],
    practiceSetDetailArray: [],
    practiceSetDetailMap: {},
    classroomMap: {},
    liveSessionMap: {},
    isLoading: true,
    isAssignmentDataLoading: true,
    classroomStandardAccuraciesMap: {},
    standards: [],
  });

export const useStudentDashboardData = (): IStudentDashboardDataContext => {
  return useContext(StudentDashboardDateContext);
};

const LIVE_SESSION_REFETCH_INTERVAL = 10000;
const CLASSROOMS_REFETCH_INTERVAL = 10000;

const StudentDashboardDataProvider: React.FC = ({ children }) => {
  const { authUser } = useAuth();
  const history = useHistory();
  const [now, setNow] = useState<Date>(moment().startOf("minute").toDate());

  // check active assignments
  // with new dates every minute
  useInterval(() => {
    setNow(moment().startOf("minute").toDate());
  }, 60000);

  const activeSessionStartTime = useMemo(
    () => moment().startOf("minute").subtract(24, "h").toDate(),
    []
  );

  const fetchClassrooms = useFetchClassrooms({
    archived: false,
    refetchInterval: CLASSROOMS_REFETCH_INTERVAL,
    includeAllRoles: true,
    onUnauthenticated: () => {
      history.push("/sign-out");
    },
  });
  useErrorToast(fetchClassrooms.error);

  const fetchAverageStandardAccuracies = useFetchAverageStandardAccuraciesBulk({
    classroom_ids: fetchClassrooms.data?.classrooms.map((c) => c.id) || [],
    user_id: authUser?.id,
    refetchOnWindowFocus: false,
  });

  const standardIds = useMemo(() => {
    return Object.keys(fetchAverageStandardAccuracies.accuracyData).flatMap(
      (classroomId) =>
        fetchAverageStandardAccuracies.accuracyData[
          classroomId
        ].average_standard_accuracies.map((a) => a.standard_id)
    );
  }, [fetchAverageStandardAccuracies.accuracyData]);

  const standardsFetch = useFetchStandards({
    ids: standardIds,
    enabled: !!standardIds.length,
    limit: standardIds.length || 1,
    offset: 0,
  });
  useErrorToast(standardsFetch.error);

  const fetchActiveAssignments = useFetchClassroomAssignmentsBulk({
    classroom_ids: fetchClassrooms.data?.classrooms.map((c) => c.id) || [],
    start_date: now,
    end_date: now,
    limit: 50,
    keepPreviousData: true,
  });

  const fetchPracticeSets = useFetchPracticeSetsByUserExperience({
    enabled: !!fetchClassrooms.data?.classrooms.length,
  });
  useErrorToast(fetchPracticeSets.error);

  const fetchSessions = useFetchSessions({
    incomplete_sessions_only: true,
    start_time: activeSessionStartTime,
    group_id: "",
    keepPreviousData: true,
    refetchInterval: LIVE_SESSION_REFETCH_INTERVAL,
    enabled: !!fetchClassrooms.data?.classrooms.length,
  });

  const fetchAvatar = useFetchAvatar({
    include_items: true,
  });
  useErrorToast(fetchAvatar.error);

  const avatarResponse = useMemo(() => {
    return fetchAvatar.data;
  }, [fetchAvatar.data]);

  const activeAssignments = useMemo(
    () =>
      Object.values(fetchActiveAssignments.assignmentData)
        .flatMap((v) => v)
        .filter((a) => {
          return new Date(a.ends_at) > now;
        }),
    [fetchActiveAssignments.assignmentData, now]
  );

  const classroomMap: { [key: string]: IClassroom } = useMemo(() => {
    const map: {
      [key: string]: IClassroom;
    } = {};

    fetchClassrooms.data?.classrooms.forEach((c) => {
      map[c.id] = c;
    });

    return map;
  }, [fetchClassrooms.data]);

  const [practiceSetDetailArray, practiceSetDetailMap]: [
    Array<IPracticeSetDetail>,
    {
      [practiceSetId: string]: IPracticeSetDetail;
    }
  ] = useMemo(() => {
    const map: {
      [key: string]: {
        practice_set: IPracticeSet;
        maximum_xp: number;
        item_count: number;
        classroom_id: string;
      };
    } = {};

    const array =
      fetchPracticeSets.data?.classroom_practice_sets.flatMap((c) =>
        c.practice_sets_with_user_details.map((p) => ({
          ...p,
          classroom_id: c.classroom_id,
        }))
      ) || [];

    array.forEach((p) => {
      map[p.practice_set.id] = p;
    });

    return [array, map];
  }, [fetchPracticeSets.data?.classroom_practice_sets]);

  const liveSessionMap: { [key: string]: IPracticeSetSession } = useMemo(() => {
    const map: { [key: string]: IPracticeSetSession } = {};
    fetchSessions.data?.sessions.forEach((s) => {
      map[s.id] = s;
    });

    return map;
  }, [fetchSessions.data?.sessions]);

  const value = useMemo(() => {
    return {
      avatarResponse,
      liveSessionMap,
      activeAssignments,
      practiceSetDetailArray,
      practiceSetDetailMap,
      classroomMap,
      isAssignmentDataLoading: fetchActiveAssignments.isLoading,
      userXpMultiplier: fetchSessions.data?.user_xp_multiplier
        ? fetchSessions.data?.user_xp_multiplier.xp_multiplier > 1
          ? fetchSessions.data?.user_xp_multiplier
          : undefined
        : undefined,
      classroomStandardAccuraciesMap:
        fetchAverageStandardAccuracies.accuracyData,
      standards: standardsFetch.data?.standards || [],
      isLoading:
        fetchSessions.isLoading ||
        fetchPracticeSets.isLoading ||
        fetchClassrooms.isLoading ||
        fetchAvatar.isLoading ||
        fetchAverageStandardAccuracies.isLoading ||
        standardsFetch.isLoading,
    };
  }, [
    avatarResponse,
    liveSessionMap,
    activeAssignments,
    practiceSetDetailArray,
    practiceSetDetailMap,
    classroomMap,
    fetchSessions.isLoading,
    fetchPracticeSets.isLoading,
    fetchClassrooms.isLoading,
    fetchActiveAssignments.isLoading,
    fetchAvatar.isLoading,
    fetchAverageStandardAccuracies.isLoading,
    fetchAverageStandardAccuracies.accuracyData,
    standardsFetch.isLoading,
    standardsFetch.data,
    fetchSessions.data?.user_xp_multiplier,
  ]);

  return (
    <StudentDashboardDateContext.Provider value={value}>
      {children}
    </StudentDashboardDateContext.Provider>
  );
};

export { StudentDashboardDataProvider };
