import { CloseIcon } from "@chakra-ui/icons";
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  HStack,
  Heading,
  Input,
  Select,
  SimpleGrid,
  Spacer,
  Table,
  Tbody,
  Td,
  Text,
  Textarea,
  Tooltip,
  Tr,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";

import { useErrorToast, useShowToast } from "adminComponents/utils/toast";
import { useAuth } from "links/lib/features/auth";
import {
  useDeleteSkill,
  useFetchSkill,
  useMutateSkill,
} from "links/lib/features/skills";
import { ISkill, IStandard, SkillType, UserRole } from "links/lib/types";
import { SkillsModal } from "sharedComponents/organisms/SkillsModal";
import { StandardsModal } from "sharedComponents/organisms/StandardsModal";

interface IFormValues {
  skillType: SkillType;
  code: string;
  description: string;
  standards: Array<IStandard>;
  precedingSkills: Array<ISkill>;
}

interface IProps {
  skill?: ISkill;
  onSave?: (savedSkill: ISkill) => void;
}

const SkillEditor: React.FC<IProps> = ({ skill, onSave }) => {
  const { authUser } = useAuth();
  let { skillId } = useParams<{ skillId: string }>();
  // This component is used both for editing existing skills and creating new ones.
  // Handle the case that we're creating a new skill by overriding the skill ID explicitly
  if (skillId === "create" || !skill?.id) {
    skillId = "0";
  } else if (skill && skill?.id !== "0") {
    skillId = skill.id;
  }

  const history = useHistory();
  const showToast = useShowToast();

  const mutateSkill = useMutateSkill();
  const deleteSkill = useDeleteSkill();
  const skillFetch = useFetchSkill({
    id: skillId,
  });

  const [error, setError] = useState("");
  const [region, setRegion] = useState("");
  const [subjectId, setSubjectId] = useState("");
  const [gradeLevelId, setGradeLevelId] = useState("");

  const initialValues: IFormValues = {
    skillType: skill?.skill_type || SkillType.PRACTICE_ORIENTED,
    code: skill?.code || "",
    description: skill?.description || "",
    standards:
      skill?.standards && skill?.standards?.length > 0 ? skill?.standards : [],
    precedingSkills:
      skill?.preceding_skills && skill?.preceding_skills?.length > 0
        ? skill?.preceding_skills
        : [],
  };

  const formik = useFormik({
    initialValues,
    validateOnMount: true,
    validationSchema: Yup.object({
      skillType: Yup.string().trim().required("A skill type is required"),
      code: Yup.string().trim().required("A code is required"),
      description: Yup.string().trim().required("A description is required"),
      standards: Yup.array().of(Yup.object().shape({ id: Yup.string() })),
      precedingSkills: Yup.array().of(Yup.object()),
    }),
    onSubmit: (values) => {
      const code = values.code.trim();
      const description = values.description.trim();

      const { skillType, standards, precedingSkills } = values;

      mutateSkill.mutate({
        skill: {
          id: skillId,
          code,
          description,
          skill_type: skillType,
          standards,
          preceding_skills: precedingSkills,
        },
      });

      resetState();
    },
  });

  const resetState = () => {
    setError("");
    formik.resetForm();
  };

  const {
    isOpen: isStandardsModalOpen,
    onOpen: onStandardsModalOpen,
    onClose: onStandardsModalClose,
  } = useDisclosure();

  const {
    isOpen: isSkillsModalOpen,
    onOpen: onSkillsModalOpen,
    onClose: onSkillsModalClose,
  } = useDisclosure();

  // on successful skill load, update field values
  useEffect(
    () => {
      if (skillFetch.isSuccess && skillFetch.data) {
        const { code, description, skill_type, preceding_skills, standards } =
          skillFetch.data.skill;

        formik.setValues({
          code,
          description,
          skillType: skill_type,
          standards,
          precedingSkills: preceding_skills,
        });
      }
    },
    // eslint-disable-next-line
    [skillFetch.isSuccess]
  );

  // on successful mutate, redirect back to skill search
  useEffect(
    () => {
      if (mutateSkill.isSuccess) {
        showToast("Skill saved");
        if (!skill) {
          history.push("/ce95fe32-e800-42de-80e8-033897d829ae/skills");
        } else if (onSave) {
          onSave(mutateSkill.data.skill);
        }
      }
    },
    // eslint-disable-next-line
    [mutateSkill.isSuccess, skill, mutateSkill.data]
  );

  // on successful delete, redirect back to skill search
  useEffect(
    () => {
      if (deleteSkill.isSuccess) {
        showToast("Skill deleted");

        history.push("/ce95fe32-e800-42de-80e8-033897d829ae/skills");
      }
    },
    // eslint-disable-next-line
    [deleteSkill.isSuccess]
  );

  useErrorToast(skillFetch.error, "Error retrieving skill");
  useErrorToast(mutateSkill.error, "Error saving skill");
  useErrorToast(deleteSkill.error, "Error deleting skill");

  if (!authUser || authUser.role !== UserRole.ContentSpecialist) return null;

  return (
    <Box p="50px" minH="100vh" bgColor="gray.100">
      {error && <Alert status="error">{error}</Alert>}

      <form
        onSubmit={formik.handleSubmit}
        style={{ width: "100%" }}
        autoComplete="off"
      >
        <VStack p="50px" bgColor="gray.50" spacing="24px" justifyContent="left">
          <HStack display="flex" w="full">
            <Spacer />
            <Heading>Skill Editor</Heading>
            <Spacer />
            <CloseIcon cursor="pointer" onClick={() => history.goBack()} />
          </HStack>
          <Table bgColor="white">
            <Tbody>
              <Tr>
                <Td w="300px">Code</Td>
                <Td>
                  <FormControl
                    isRequired
                    isInvalid={!!formik.errors.code && formik.touched.code}
                  >
                    <Input
                      id="code"
                      name="code"
                      value={formik.values.code}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                    <FormErrorMessage>
                      {formik.touched.code && formik.errors.code}
                    </FormErrorMessage>
                  </FormControl>
                </Td>
              </Tr>
              <Tr>
                <Td w="300px">Description</Td>
                <Td>
                  <FormControl
                    isRequired
                    isInvalid={
                      !!formik.errors.description && formik.touched.description
                    }
                  >
                    <Textarea
                      id="description"
                      name="description"
                      value={formik.values.description}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                    <FormErrorMessage>
                      {formik.touched.description && formik.errors.description}
                    </FormErrorMessage>
                  </FormControl>
                </Td>
              </Tr>
              <Tr>
                <Td w="300px">Skill Type</Td>
                <Td>
                  <FormControl
                    isRequired
                    isInvalid={
                      !!formik.errors.skillType && formik.touched.skillType
                    }
                  >
                    <Select
                      id="skillType"
                      name="skillType"
                      value={formik.values.skillType}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    >
                      <option value="0">-- Choose --</option>
                      {/* TODO: skillType values */}
                      <option value={SkillType.DISCOVERY}>Discovery</option>
                      <option value={SkillType.PRACTICE_ORIENTED}>
                        Practice Oriented
                      </option>
                    </Select>

                    <FormErrorMessage>
                      {formik.touched.skillType && formik.errors.skillType}
                    </FormErrorMessage>
                  </FormControl>
                </Td>
              </Tr>
              <Tr>
                <Td w="300px">Preceding Skill(s)</Td>
                <Td>
                  <FormControl>
                    <SimpleGrid columns={5} spacing="10px" my="10px">
                      {formik.values.precedingSkills.map((s) => (
                        <Tooltip label={s.description} key={s.id}>
                          <Box
                            display="flex"
                            bgColor="gray.100"
                            p="5px"
                            borderRadius="8px"
                          >
                            <Spacer />
                            <Text fontSize="large">{s.code}</Text>
                            <Spacer />
                            <CloseIcon
                              color="gray.500"
                              cursor="pointer"
                              boxSize="10px"
                              my="auto"
                              mr="5px"
                              onClick={() => {
                                formik.setFieldValue(
                                  "precedingSkills",
                                  formik.values.precedingSkills.filter(
                                    (skill) => skill.id !== s.id
                                  )
                                );
                              }}
                            />
                          </Box>
                        </Tooltip>
                      ))}
                    </SimpleGrid>
                    <SkillsModal
                      isOpen={isSkillsModalOpen}
                      onClose={onSkillsModalClose}
                      selectedSkills={formik.values.precedingSkills}
                      onChange={(skills) => {
                        formik.setFieldValue("precedingSkills", skills);
                      }}
                    />

                    <Button onClick={onSkillsModalOpen}>Choose Skill(s)</Button>
                  </FormControl>
                </Td>
              </Tr>
              <Tr>
                <Td w="300px">Linked Standard(s)</Td>
                <Td>
                  <FormControl>
                    <SimpleGrid columns={5} spacing="10px" my="10px">
                      {formik.values.standards.map((s: IStandard) => (
                        <Tooltip label={s.description} key={s.id}>
                          <Box
                            display="flex"
                            bgColor="gray.100"
                            p="5px"
                            borderRadius="8px"
                          >
                            <Spacer />
                            <Text fontSize="large">{s.label}</Text>
                            <Spacer />
                            <CloseIcon
                              color="gray.500"
                              cursor="pointer"
                              boxSize="10px"
                              my="auto"
                              mr="5px"
                              onClick={() => {
                                formik.setFieldValue(
                                  "standards",
                                  formik.values.standards.filter(
                                    (standard) => standard.id !== s.id
                                  )
                                );
                              }}
                            />
                          </Box>
                        </Tooltip>
                      ))}
                    </SimpleGrid>
                    <StandardsModal
                      isOpen={isStandardsModalOpen}
                      onClose={onStandardsModalClose}
                      selectedStandards={formik.values.standards}
                      country={authUser?.country || "US"}
                      initialSearchValues={{
                        region,
                        grade_level_id: gradeLevelId,
                        subject_id: subjectId,
                      }}
                      onChange={(standards) => {
                        formik.setFieldValue("standards", standards);
                      }}
                      onRegionChange={setRegion}
                      onSubjectChange={setSubjectId}
                      onGradeLevelChange={setGradeLevelId}
                    />

                    <Button onClick={onStandardsModalOpen}>
                      Choose Standard(s)
                    </Button>
                  </FormControl>
                </Td>
              </Tr>
            </Tbody>
          </Table>

          <ButtonGroup>
            <Button
              colorScheme="blue"
              disabled={!formik.isValid}
              onClick={formik.submitForm}
            >
              Save
            </Button>
            <Button
              colorScheme="red"
              disabled={
                skillFetch.isLoading ||
                skillFetch.isError ||
                parseInt(skillId, 10) === 0
              }
              onClick={() => {
                const confirmed = confirm(
                  "Are you sure you want to delete this skill?"
                );
                if (confirmed && skillFetch.data !== undefined) {
                  deleteSkill.mutate({ id: skillFetch.data.skill.id });
                }
              }}
            >
              Delete Skill
            </Button>
          </ButtonGroup>
        </VStack>
      </form>
    </Box>
  );
};

export default SkillEditor;
