import { useHistory } from "react-router-dom";

import { useErrorToast } from "adminComponents/utils/toast";
import { useFetchAveragePracticeSetAccuracies } from "links/lib/features/classrooms";
import { IUseFetchAveragePracticeSetAccuraciesResult } from "links/lib/features/classrooms/useFetchAveragePracticeSetAccuracies";
import useFetchClassroom, {
  IFetchClassroomResponse,
} from "links/lib/features/classrooms/useFetchClassroom";
import useFetchClassroomRecentPracticeSetResponses, {
  IUseFetchClassroomRecentPracticeSetResponsesResult,
} from "links/lib/features/classrooms/useFetchClassroomRecentPracticeSetResponses";
import useFetchClassroomUsers, {
  IFetchClassroomUsersResponse,
} from "links/lib/features/classrooms/useFetchClassroomUsers";
import useFetchPracticeSetItems, {
  IFetchPracticeSetItemsResponse,
} from "links/lib/features/practiceSetItems/useFetchPracticeSetItems";
import useFetchPracticeSet, {
  IFetchPracticeSetResponse,
} from "links/lib/features/practiceSets/useFetchPracticeSet";
import { UsersGroupsRole } from "links/lib/types";

interface IUsePracticeSetReportQueriesParams {
  practiceSetId: string;
  classroomId: string;
  assignmentId?: string;
  practiceSetSessionId?: string;
}

interface IUsePracticeSetReportQueriesResponse {
  isLoading: boolean;
  practiceSetData?: IFetchPracticeSetResponse;
  classroomData?: IFetchClassroomResponse;
  classroomStudentsData?: IFetchClassroomUsersResponse;
  classroomAccuracyData?: IUseFetchAveragePracticeSetAccuraciesResult;
  classroomPracticeSetResponses?: IUseFetchClassroomRecentPracticeSetResponsesResult;
  practiceSetItemsData?: IFetchPracticeSetItemsResponse;
}

export const usePracticeSetReportQueries = ({
  practiceSetId,
  classroomId,
  assignmentId,
  practiceSetSessionId,
}: IUsePracticeSetReportQueriesParams): IUsePracticeSetReportQueriesResponse => {
  const history = useHistory();
  const {
    data: practiceSetData,
    isLoading: practiceSetLoading,
    error: practiceSetError,
  } = useFetchPracticeSet({ id: practiceSetId });

  const {
    data: classroomData,
    isLoading: classroomLoading,
    error: classroomError,
  } = useFetchClassroom({
    id: classroomId,
    onPermissionDenied: () => {
      history.push("/t/classrooms");
    },
  });

  const {
    data: classroomStudentsData,
    isLoading: classroomStudentsLoading,
    error: classroomStudentsError,
  } = useFetchClassroomUsers({
    classroom_id: classroomId,
    group_roles: [UsersGroupsRole.Member],
  });

  const {
    data: classroomAccuracyData,
    isLoading: classroomAccuracyLoading,
    error: classroomAccuracyError,
  } = useFetchAveragePracticeSetAccuracies({
    classroom_id: classroomId,
    practice_set_id: practiceSetId,
    assignment_id: assignmentId,
    practice_set_session_id: practiceSetSessionId,
  });

  const {
    data: classroomPracticeSetResponses,
    isLoading: classroomPracticeSetResponsesLoading,
    error: classroomPracticeSetResponsesError,
  } = useFetchClassroomRecentPracticeSetResponses({
    classroom_id: classroomId,
    practice_set_id: practiceSetId,
    assignment_id: assignmentId,
    practice_set_session_id: practiceSetSessionId,
  });

  const {
    data: practiceSetItemsData,
    isLoading: practiceSetItemsLoading,
    error: practiceSetItemsError,
  } = useFetchPracticeSetItems({ practice_set_id: practiceSetId });

  useErrorToast(practiceSetError);
  useErrorToast(classroomError);
  useErrorToast(classroomStudentsError);
  useErrorToast(classroomAccuracyError);
  useErrorToast(classroomPracticeSetResponsesError);
  useErrorToast(practiceSetItemsError);

  const isLoading =
    classroomLoading ||
    classroomAccuracyLoading ||
    practiceSetLoading ||
    practiceSetItemsLoading ||
    classroomStudentsLoading ||
    classroomPracticeSetResponsesLoading;

  return {
    isLoading,
    practiceSetData,
    classroomData,
    classroomStudentsData,
    classroomAccuracyData,
    classroomPracticeSetResponses,
    practiceSetItemsData,
  };
};
