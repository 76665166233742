import { useHistory, useLocation } from "react-router-dom";
import { useMount } from "react-use";

export interface IArgs {
  handler: () => void;
  replace: boolean;
  stateKey: string;
}

/**
 * Checks for presence of a route state key on mount and calls handler if present. If `replace`
 * is true, the history object will be replaced without the presence of the state key after handler
 * is run
 * @param args
 */
export const useRouteStateOnMount = ({
  handler,
  replace,
  stateKey,
}: IArgs): void => {
  const location = useLocation();
  const history = useHistory();

  const state = location.state as Record<string, unknown> | undefined;

  useMount(() => {
    if (state && state[stateKey]) {
      handler();

      if (replace) {
        // replace the existing state to prevent back navigations from initiating state
        history.replace(location.pathname, {
          ...state,
          [stateKey]: undefined,
        });
      }
    }
  });
};
