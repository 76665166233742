import { Button, Flex, useDisclosure } from "@chakra-ui/react";
import React, { useState } from "react";

import {
  useDeleteUserTrialExtension,
  useListUserTrialExtensions,
} from "links/lib/features/userTrialExtensions";
import { IUserTrialExtension } from "links/lib/types";

import { UserTrialExtensionCard } from "./components/UserTrialExtensionCard";
import { UserTrialExtensionFlyout } from "./components/UserTrialExtensionFlyout";

export const UserTrialExtensionManager: React.FC = () => {
  const fetchUserTrialExtensions = useListUserTrialExtensions();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [selectedUserTrialExtension, setSelectedUserTrialExtension] =
    useState<IUserTrialExtension>();

  const deleteUserTrialExtension = useDeleteUserTrialExtension();

  return (
    <Flex
      p="12px"
      gap="12px"
      w="full"
      h="full"
      alignItems="center"
      flexDir="column"
    >
      <Button
        borderRadius="xl"
        color="gray"
        onClick={() => {
          setSelectedUserTrialExtension(undefined);
          onOpen();
        }}
      >
        Create New
      </Button>
      <Flex flexWrap="wrap" w="full" h="full" gap="10px">
        {fetchUserTrialExtensions.data?.user_trial_extensions?.map(
          (extension) => (
            <UserTrialExtensionCard
              key={extension.id}
              userTrialExtension={extension}
              onClick={() => {
                setSelectedUserTrialExtension(extension);
                onOpen();
              }}
              onDelete={() =>
                deleteUserTrialExtension.mutate({ id: extension.id })
              }
            />
          )
        )}
      </Flex>
      <UserTrialExtensionFlyout
        isOpen={isOpen}
        onClose={onClose}
        userTrialExtension={selectedUserTrialExtension}
      />
    </Flex>
  );
};
