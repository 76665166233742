import { AxiosInstance } from "axios";
import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { IPracticeSetSession, UserXPMultiplier } from "../../types";

export interface IFetchSessionsResponse {
  classroom_id: string;
  sessions: Array<IPracticeSetSession>;
  user_xp_multiplier?: UserXPMultiplier;
}

export interface IUseFetchSessionsArgs {
  group_id: string;
  practice_set_id?: string;
  incomplete_sessions_only?: boolean;
  start_time?: Date;
  limit?: number;
  keepPreviousData?: boolean;
  refetchInterval?: number;
  enabled?: boolean;
}

export const fetchClassroomSessionsQuery = (
  axios: AxiosInstance,
  params: IUseFetchSessionsArgs
): (() => Promise<IFetchSessionsResponse>) => {
  const {
    group_id,
    practice_set_id,
    incomplete_sessions_only,
    start_time,
    limit,
  } = params;

  return async (): Promise<IFetchSessionsResponse> => {
    return await axios
      .get(`/v1/practice-sets/sessions`, {
        params: {
          group_id: group_id === "0" || group_id === "" ? undefined : group_id,
          practice_set_id,
          incomplete_sessions_only,
          start_time,
          limit,
        },
      })
      .then((r) => {
        return {
          classroom_id: group_id,
          sessions:
            (
              r.data as {
                practice_set_sessions: Array<IPracticeSetSession>;
              }
            ).practice_set_sessions || [],
          user_xp_multiplier: (
            r.data as { user_xp_multiplier?: UserXPMultiplier }
          ).user_xp_multiplier,
        };
      });
  };
};

/**
 * Fetches a list of practice set sessions
 * for the provided group
 * @returns
 */
export default function useFetchSessions(
  args: IUseFetchSessionsArgs
): UseQueryResult<IFetchSessionsResponse, unknown> {
  const {
    group_id,
    practice_set_id,
    incomplete_sessions_only,
    start_time,
    enabled = true,
  } = args;

  const axios = useAxios();

  const query = useQuery<IFetchSessionsResponse>(
    [
      "sessions",
      args,
      { practice_set_id, incomplete_sessions_only, start_time },
    ],
    fetchClassroomSessionsQuery(axios, args),
    // only run when group_id has been set which
    // allows for using as dependent query
    {
      enabled: group_id !== "0" && enabled,
      keepPreviousData: args.keepPreviousData,
      refetchInterval: args.refetchInterval,
    }
  );

  return query;
}
