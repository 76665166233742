import { Box, Flex, GridItem, SimpleGrid, Text } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { pxToRem } from "adminComponents/utils";
import { useSessionGroups } from "links/lib/contexts/sessionGroups";
import { useShortName } from "links/lib/hooks/useShortName";
import { ISessionUser, SessionConnectionStatus } from "links/lib/types";
import { AvatarIcon } from "sessionComponents/atoms/AvatarIcon";
import { GroupToken } from "sessionComponents/atoms/GroupToken";
import { Modal } from "sessionComponents/atoms/Modal";
import { OnlineStatusCircle } from "sessionComponents/atoms/OnlineStatusCircle";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { useStudent } from "sessionComponents/contexts/student";
import { useGroupUsers } from "sessionComponents/hooks/useGroupUsers";
import { useDetectOrientation } from "sessionComponents/theme/hooks/useDetectOrientation";
import { getAssetColorScheme } from "sessionComponents/utils/getAssetColorScheme";
import { getFormattedGroupName } from "sessionComponents/utils/getFormattedGroupName";

interface IMyTeamModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const MyTeamModal: React.FC<IMyTeamModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { match: currentBreakpoints } = useBreakpoints();
  const student = useStudent();
  const groupUsers = useGroupUsers(student.session_group_id);
  const groups = useSessionGroups();

  const group = useMemo(
    () => groups[student.session_group_id],
    [groups, student.session_group_id]
  );

  const groupName = getFormattedGroupName(group);
  const imageUrl = group?.icon_url || "";

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      landscapeSize="lg"
      modalHeaderContent={
        <Flex
          as="h2"
          fontSize={pxToRem(currentBreakpoints.fontSize)}
          textStyle="gameDisplayInline"
          alignItems="center"
        >
          <Box marginRight={pxToRem(currentBreakpoints.margin)}>
            <GroupToken
              colorScheme={getAssetColorScheme(group?.color_scheme)}
              imageUrl={imageUrl}
              name={groupName}
              hideTooltip
            />
          </Box>
          {groupName}
        </Flex>
      }
    >
      <>
        {groupUsers.map((user) => (
          <TeamMember
            key={user.id}
            user={user}
            isSelf={user.id === student.id}
          />
        ))}
      </>
    </Modal>
  );
};

const TeamMember = ({
  user,
  isSelf,
}: {
  user: ISessionUser;
  isSelf: boolean;
}) => {
  const {
    match: { fontSize, buttonHeight, margin },
  } = useBreakpoints();

  const { isLandscape, isPortrait } = useDetectOrientation();

  const { t } = useTranslation("session", {
    useSuspense: false,
    keyPrefix: "connectionStatus",
  });

  const displayName = useShortName(user.name);

  return (
    <SimpleGrid columns={3} marginBottom={pxToRem(margin)}>
      <GridItem colSpan={isLandscape ? 2 : 3}>
        <Flex alignItems="center">
          <Box marginRight={pxToRem(margin)} display="relative">
            {user.connection_status ===
              SessionConnectionStatus.Disconnected && (
              <Box
                position="absolute"
                boxSize={pxToRem(buttonHeight)}
                backgroundColor="primary.white"
                opacity={0.7}
              />
            )}
            <AvatarIcon
              avatarImgUrl={user.profile_image_url}
              name={user.name}
              primaryColor={getAssetColorScheme(user.color_scheme).primaryColor}
              tooltipDisabled
              avatarIconSize={pxToRem(buttonHeight)}
            />
          </Box>

          <Flex alignItems="center">
            {isPortrait && (
              <Box marginRight={pxToRem(12)}>
                <OnlineStatusCircle
                  status={user.connection_status}
                  size={30}
                  customConnectedColor="utility.success"
                />
              </Box>
            )}
            <Box
              as="h2"
              fontSize={pxToRem(fontSize)}
              textStyle="gameDisplayInline"
            >
              {displayName} {isSelf ? t("you") : ""}
            </Box>
          </Flex>
        </Flex>
      </GridItem>

      {isLandscape && (
        <Flex alignItems="center">
          <OnlineStatusCircle size={fontSize} status={user.connection_status} />
          <Text
            marginLeft={pxToRem(10)}
            textStyle="gameText"
            fontSize={fontSize}
            color={
              user.connection_status === SessionConnectionStatus.Disconnected
                ? "utility.error"
                : "primary.black"
            }
          >
            {user.connection_status === SessionConnectionStatus.Connected
              ? t("connected")
              : t("disconnected")}
          </Text>
        </Flex>
      )}
    </SimpleGrid>
  );
};
