import { useMemo } from "react";

export function useShortName(name: string, usePeriod = true): string {
  const shortName = useMemo(() => {
    if (!name) return "";
    const nameParts = name.trim().split(" ");

    const [first, last] = nameParts;
    const lastI = last?.trim().length
      ? last[0].toUpperCase() + (usePeriod ? "." : "")
      : "";

    return `${first} ${lastI}`.trim();
  }, [name, usePeriod]);

  return shortName;
}
