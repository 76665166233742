import { useBreakpointValue } from "@chakra-ui/react";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import { StudentNavigationHeader } from "adminComponents/molecules/StudentNavigationHeader";
import { EditAvatarModal } from "adminComponents/organisms/EditAvatarModal";
import { ShareAvatarModal } from "adminComponents/organisms/ShareAvatarModal";
import { StudentLockerModal } from "adminComponents/organisms/StudentLockerModal";
import { StudentLockerScreen } from "adminComponents/screens/StudentLockerScreen";
import { pxToRem } from "adminComponents/utils";
import { useAnalytics, usePageTrack } from "lib/contexts/analytics";
import {
  CameraTypes,
  useLockerAvatarCamera,
} from "lib/hooks/useLockerAvatarCamera";
import { usePageTitle } from "lib/hooks/usePageTitle";
import { downloadBlob } from "lib/shareUtils";
import { useAuth } from "links/lib/features/auth";
import {
  useDeequipAvatarItem,
  useEquipAvatarItem,
  useMutateUserAvatarSettings,
} from "links/lib/features/avatars";
import {
  AnalyticsEvent,
  AnalyticsPage,
  AvatarSkeletonType,
  IAvatarItem,
  IAvatarItemColor,
  IFetchAvatarResponse,
  IMyStuffItem,
} from "links/lib/types";
import { useStudentDashboardData } from "screens/StudentDashboard/contexts/StudentDashboardDataProvider";
import { useNavigationData } from "screens/StudentDashboard/contexts/StudentNavigationDataProvider";
import {
  AvatarStage,
  AvatarStageMember,
} from "sharedComponents/molecules/AvatarStage";

import { useUpdateProfileImage } from "../../lib/hooks/useUpdateProfileImage";

const MY_STUFF_PAGE_SIZE = 6;

export interface IAvatarAnimation {
  name: string;
  loop: boolean;
}

const lockerCameraStanding = { ...CameraTypes.StandingDefault, zoom: 0.8 };
const lockerCameraSeated = { ...CameraTypes.SeatedDefault, zoom: 0.8 };

export const StudentLocker: React.FC = () => {
  const { authUser } = useAuth();
  const { trackEvent } = useAnalytics();
  const { navigationData } = useNavigationData();
  const { avatarResponse, isLoading: isLoadingStudentDashboardData } =
    useStudentDashboardData();
  const equipAvatarItem = useEquipAvatarItem();
  const deequipAvatarItem = useDeequipAvatarItem();
  const avatarSettings = useMutateUserAvatarSettings();
  const history = useHistory();
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });
  const isMobile = useBreakpointValue({ base: true, lg: false });

  usePageTrack(AnalyticsPage.StudentDashboard_Locker);
  usePageTitle(t("studentLockerContainer.pageTitle"));

  const { mode } = useParams<{ mode?: string }>();
  const showAvatarEditModal = mode === "edit";
  const showAvatarShareModal = mode === "share";

  const [showMyStuffCount, setShowMyStuffCount] = useState(MY_STUFF_PAGE_SIZE);
  const [avatarItemID, setAvatarItemID] = useState<string | undefined>();
  const [avatarEditSectionId, setAvatarEditSectionId] = useState<string>();
  const [showAvatarItemModal, setShowAvatarItemModal] = useState(false);

  const avatarItem = useMemo(() => {
    return avatarResponse?.avatar?.items.find(
      (item) => item.id === avatarItemID
    );
  }, [avatarResponse, avatarItemID]);

  const myStuffItems =
    avatarResponse?.avatar?.items
      .filter((item) => !item.is_public)
      .sort((a, b) =>
        moment(a.earned_at).isBefore(moment(b.earned_at)) ? 1 : -1
      ) || [];

  const onClickViewMore = () => {
    setShowMyStuffCount(showMyStuffCount + MY_STUFF_PAGE_SIZE);
  };

  const onClickShowAvatarItemModal = (myStuffItem: IMyStuffItem) => {
    setShowAvatarItemModal(true);
    setAvatarItemID(myStuffItem.avatar_item.id);
  };

  const onClickHideAvatarItemModal = () => {
    setShowAvatarItemModal(false);
  };

  const onEquipAvatarItem = (
    avatarItemId: string,
    categoryId: string | undefined,
    colorHex: string | undefined
  ) => {
    if (!authUser) return;

    const color = avatarResponse?.avatar?.items
      .find((item) => item.id === avatarItemId)
      ?.colors.find((color) => color.hex_code === colorHex);

    equipAvatarItem.mutate({
      user_id: authUser.id,
      avatar_item_id: avatarItemId,
      avatar_item_color_id: color?.id,
    });

    trackEvent(AnalyticsEvent.StudentDashboard_Locker_Common_EquipItem, {
      itemId: avatarItemId,
      itemCategoryId: categoryId,
      itemColorId: color?.id,
      itemColorHexCode: colorHex,
    });
  };

  const onDeequipAvatarItem = (
    avatarItemId: string,
    categoryId: string | undefined
  ) => {
    if (!authUser) return;
    if (!categoryId) return;

    deequipAvatarItem.mutate({
      user_id: authUser.id,
      avatar_item_category_id: categoryId,
    });

    trackEvent(AnalyticsEvent.StudentDashboard_Locker_Common_UnsetCategory, {
      itemCategoryId: categoryId,
    });
  };

  const onClickAddItemToAvatar = (
    avatarItem: IAvatarItem,
    color?: IAvatarItemColor
  ) => {
    onEquipAvatarItem(
      avatarItem.id,
      avatarItem.avatar_item_category_id,
      color?.hex_code
    );
  };

  const onClickRemoveItemFromAvatar = (avatarItem: IAvatarItem) => {
    if (!authUser) return;
    onDeequipAvatarItem(avatarItem.id, avatarItem.avatar_item_category_id);
  };

  const onClickShowAvatarEdit = () => {
    history.push("/s/locker/edit");
  };

  const onClickShowAvatarShare = () => {
    history.push("/s/locker/share");
  };

  const handleCloseModals = () => {
    history.push("/s/locker");
  };

  const onAvatarEditSectionIdChange = (sectionId: string) => {
    setAvatarEditSectionId(sectionId);
  };

  const setSkeletonType = (skeletonType: AvatarSkeletonType) => {
    if (!authUser) return;
    avatarSettings.mutate({
      user_id: authUser.id,
      skeleton_type: skeletonType,
    });

    trackEvent(AnalyticsEvent.StudentDashboard_Locker_Common_SetSkeletonType, {
      skeletonType: skeletonType,
    });
  };

  const setHairColor = (hairColorHexCode: string) => {
    if (!authUser) return;
    avatarSettings.mutate({
      user_id: authUser.id,
      hair_color_hex_code: hairColorHexCode,
    });

    trackEvent(AnalyticsEvent.StudentDashboard_Locker_Common_SetHairColor, {
      colorHexCode: hairColorHexCode,
    });
  };

  const setSkinTone = (skinToneHexCode: string) => {
    if (!authUser) return;
    avatarSettings.mutate({
      user_id: authUser.id,
      skin_tone_hex_code: skinToneHexCode,
    });

    trackEvent(AnalyticsEvent.StudentDashboard_Locker_Common_SetSkinTone, {
      toneHexCode: skinToneHexCode,
    });
  };

  const handleDownload = (blobUrl: string) => {
    downloadBlob(blobUrl, `${t("common.avatar")}.png`);

    trackEvent(
      AnalyticsEvent.StudentDashboard_MyAvatar_ShareModal_DownloadImage,
      {}
    );
  };

  const isAvatarItemModalLoading =
    equipAvatarItem.isLoading || deequipAvatarItem.isLoading;

  const avatarStageSize =
    useBreakpointValue({ base: 164, md: 256, lg: 516 }) || 516;

  const isAvatarSeated =
    avatarResponse?.avatar?.skeleton_type === AvatarSkeletonType.Seated;

  const modalStageCamera = useLockerAvatarCamera({
    editSectionId: avatarEditSectionId,
    isSeated: isAvatarSeated,
  });

  const avatarAnimation = useMemo(() => ({ name: "idle", loop: true }), []);

  const profileImageCapture = useUpdateProfileImage({ avatarResponse });

  // authUser is validated in StudentDashboard, this is just
  // for the benefit of TypeScript
  if (!authUser) return <></>;

  const getAvatarPreviewState = (
    avatarResponse: IFetchAvatarResponse,
    isModalPreview = true
  ) => {
    const remAvatarStageSize = pxToRem(avatarStageSize);
    const remAvatarStageOffset = pxToRem(-avatarStageSize / 2);

    const baseStyle = {
      width: remAvatarStageSize,
      maxWidth: remAvatarStageSize,
      maxHeight: remAvatarStageSize,
      background: "white",
    };

    const modalStyle = {
      position: "absolute",
      top: "0",
      left: "0",
      marginLeft: "50%",
      marginTop: "50%",
      minWidth: remAvatarStageSize,
      height: remAvatarStageSize,
      transform: `translateX(${remAvatarStageOffset}) translateY(${remAvatarStageOffset})`,
    };

    const style = isModalPreview ? { ...baseStyle, ...modalStyle } : baseStyle;
    const camera = isModalPreview
      ? modalStageCamera
      : isAvatarSeated
      ? lockerCameraSeated
      : lockerCameraStanding;

    if (!avatarResponse.spine_atlas_url || !avatarResponse.spine_json_url)
      return <></>;

    return (
      <AvatarStage
        camera={camera}
        alignX="center"
        alignY="center"
        style={style}
        stageSize={{ x: 600, y: 600 }}
      >
        <AvatarStageMember
          key={avatarResponse.spine_atlas_url}
          animation={avatarAnimation}
          scale={0.4}
          x={0}
          y={-160}
          atlasUrl={avatarResponse.spine_atlas_url}
          skeletonUrl={avatarResponse.spine_json_url}
        />
      </AvatarStage>
    );
  };

  const currentLevelXP =
    (authUser.student_season_total_xp ?? 0) -
    (authUser.student_season_current_level_xp_threshold ?? 0);
  const nextLevelXP =
    (authUser.student_season_next_level_xp_threshold ?? 0) -
    (authUser.student_season_current_level_xp_threshold ?? 0);

  return (
    <>
      <StudentLockerScreen
        student={authUser}
        avatar={avatarResponse?.avatar}
        studentAvatarStage={
          !!avatarResponse && getAvatarPreviewState(avatarResponse, false)
        }
        myStuff={myStuffItems.slice(0, showMyStuffCount).map((item) => {
          return {
            avatar_item: item,
            is_seen: true, // TODO
            is_unlocked: !item.is_public,
            is_equipped: item.is_active,
          };
        })}
        totalXP={authUser.student_season_total_xp || 0}
        currentXP={currentLevelXP}
        nextLevelXP={nextLevelXP}
        isLoading={isLoadingStudentDashboardData}
        nav={<StudentNavigationHeader {...navigationData} />}
        showViewMoreStuffButton={showMyStuffCount < myStuffItems.length}
        handleStudentAvatarEdit={onClickShowAvatarEdit}
        handleStudentAvatarShare={onClickShowAvatarShare}
        handleViewMore={onClickViewMore}
        handleMyStuffCardClick={onClickShowAvatarItemModal}
      />

      {!!avatarResponse && !!avatarItem && avatarResponse.avatar && (
        <StudentLockerModal
          avatar={avatarResponse.avatar}
          avatarItem={avatarItem}
          isEquipped={avatarItem.is_active || false}
          isOpen={showAvatarItemModal}
          isLoading={isAvatarItemModalLoading}
          handleClose={onClickHideAvatarItemModal}
          handleAddToAvatar={onClickAddItemToAvatar}
          handleRemoveFromAvatar={onClickRemoveItemFromAvatar}
        />
      )}

      {!!avatarResponse && avatarResponse.avatar && (
        <EditAvatarModal
          isSaving={
            avatarSettings.isLoading ||
            equipAvatarItem.isLoading ||
            deequipAvatarItem.isLoading
          }
          isOpen={showAvatarEditModal}
          isMobile={!!isMobile}
          avatarPreviewStage={getAvatarPreviewState(avatarResponse)}
          avatar={avatarResponse.avatar}
          handleBack={handleCloseModals}
          handleClose={handleCloseModals}
          handleDone={handleCloseModals}
          handleSelectAvatarType={setSkeletonType}
          handleSkinToneColorChange={setSkinTone}
          handleHairColorChange={setHairColor}
          handleEquip={(avatarItemId, categoryId, colorHex) => {
            onEquipAvatarItem(avatarItemId, categoryId, colorHex);
          }}
          handleDeequip={(avatarItemId, categoryId) => {
            onDeequipAvatarItem(avatarItemId, categoryId);
          }}
          handleSectionIdChange={onAvatarEditSectionIdChange}
        />
      )}

      {!!avatarResponse &&
        avatarResponse.spine_atlas_url &&
        avatarResponse.spine_json_url && (
          <ShareAvatarModal
            atlasUrl={avatarResponse.spine_atlas_url}
            skeletonUrl={avatarResponse.spine_json_url}
            handleClose={handleCloseModals}
            isOpen={showAvatarShareModal}
            showShareProviders={false}
            handleDownload={handleDownload}
            handleShare={() => {
              // no-op
              return;
            }}
          />
        )}

      {profileImageCapture}
    </>
  );
};
