import { Flex } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { IAlternateTextResponse } from "links/lib/types";

export interface IProps {
  term: string;
  alternateSpellings?: Array<IAlternateTextResponse>;
}

export const AnswerTermDefinition: React.FC<IProps> = ({
  term,
  alternateSpellings,
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });
  return (
    <Flex direction="column" gap={{ base: pxToRem(4), md: pxToRem(12) }}>
      <Text variant="adminP2">{term}</Text>
      {alternateSpellings &&
        alternateSpellings.map((alternateSpelling, index) => (
          <Text
            key={index}
            variant="adminP2"
            color="primary.dark-gray"
            fontStyle="italic"
          >
            {t("practiceSetQuestionCard.labelAlternateSpelling")}:{" "}
            {alternateSpelling.alternate_text}
          </Text>
        ))}
    </Flex>
  );
};
