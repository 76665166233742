import axios, { AxiosError, AxiosResponse } from "axios";
import qs from "qs";

import { config } from "./constants";

axios.defaults.baseURL = config.gatewayOrigin;

axios.defaults.paramsSerializer = (params) => {
  return qs.stringify(params, { arrayFormat: "repeat" });
};

// This middleware rewrites response data, replacing instances
// of an old URL with a new URL. The purpose of this is that
// we store full URLs for Files assets in the database in
// several places and need to support giantsteps.app and
// practice.peardeck.com prefixes concurrently until we cut
// over to practice.peardeck.com. After the cut-over, we should
// run a migration to update all asset URLs and remove this
// middleware.
axios.interceptors.response.use(
  function (response: AxiosResponse) {
    if (!config.axiosRewriteFilesEnabled) return response;

    // Make linter happy
    if (
      !config.axiosRewriteFilesUrlOldRegExp ||
      !config.axiosRewriteFilesUrlNew
    )
      return response;

    const modifiedData = JSON.parse(
      JSON.stringify(response.data).replace(
        config.axiosRewriteFilesUrlOldRegExp,
        config.axiosRewriteFilesUrlNew
      )
    );

    return {
      ...response,
      data: modifiedData,
    };
  },
  function (error: AxiosError) {
    return Promise.reject(error);
  }
);

export default axios;
