import {
  ChakraStylesConfig,
  GroupBase,
  OptionBase,
  Select,
  SelectComponentsConfig,
  useChakraSelectProps,
} from "chakra-react-select";
import React from "react";
import { useTranslation } from "react-i18next";

import { dropdownStyles } from "adminComponents/theme/components/Dropdown";

export interface IOption extends OptionBase {
  value: string;
  label: string;
  variant?: "adminSolid" | "adminOutline";
  tooltip?: JSX.Element | string;
}

interface IDropdownProps {
  id?: string;
  disabled?: boolean;
  placeholder?: string;
  value?: Array<IOption> | IOption | null;
  options?: Array<IOption>;
  maxHeight?: number | string;
  handleChange?: (newValue: IOption | Array<IOption> | unknown) => void;
  handleBlur?: (e: React.FocusEvent<HTMLElement, Element>) => void;
  variant?: string;
  chakraStyles?: ChakraStylesConfig;
  isMulti?: boolean;
  isInvalid?: boolean;
  isDisabled?: boolean;
  isSearchable?: boolean;
  components?: SelectComponentsConfig<unknown, boolean, GroupBase<unknown>>;
  "aria-labelled-by"?: string;
  menuIsOpen?: boolean;
  controlShouldRenderValue?: boolean;
}

const Dropdown: React.FC<IDropdownProps> = ({
  options,
  handleChange,
  handleBlur,
  chakraStyles,
  placeholder,
  value,
  id,
  isMulti,
  isInvalid,
  isDisabled,
  isSearchable,
  components,
  "aria-labelled-by": ariaLabelledBy,
  menuIsOpen,
  controlShouldRenderValue = true,
}) => {
  const { t } = useTranslation("admin", {
    keyPrefix: "common",
    useSuspense: false,
  });

  // sets variant for each tag to adminSolid
  // tagVariant prop for the base Chakra React Select is limited to
  // "solid", "outline", or "subtle"; we need to override this to use our
  // custom "adminSolid" variant instead.
  options = options?.map((o) => {
    o.variant = "adminSolid";
    return o;
  });

  const handleChangeValue = (newValue: IOption | Array<IOption> | unknown) => {
    handleChange?.(newValue);
  };

  const selectProps = useChakraSelectProps({
    options,
    isInvalid,
    isDisabled,
    onChange: handleChangeValue,
    chakraStyles: { ...dropdownStyles, ...chakraStyles },
    onBlur: handleBlur,
  });

  return (
    <Select
      menuIsOpen={menuIsOpen}
      {...selectProps}
      closeMenuOnSelect={!isMulti}
      isMulti={isMulti}
      // Makes dropdown stay open on touch-screen
      blurInputOnSelect={isMulti ? false : undefined}
      placeholder={placeholder || t("selectPlaceholder")}
      noOptionsMessage={() => t("noOptionsMessage")}
      id={id}
      hideSelectedOptions={false}
      isSearchable={isSearchable}
      value={value || ""}
      components={components}
      aria-labelledby={ariaLabelledBy}
      controlShouldRenderValue={controlShouldRenderValue}
    />
  );
};

export { Dropdown };
