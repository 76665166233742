import { Box, VStack } from "@chakra-ui/react";
import moment from "moment";
import React from "react";
import { Trans, useTranslation } from "react-i18next";

import { Heading } from "adminComponents/atoms/Heading";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils";
import { UserXPMultiplier } from "links/lib/types";

import { Icon } from "../Icon";

export interface IXPBoostContentProps {
  userXPMultiplier: UserXPMultiplier;
  secondsRemaining: number;
}

export const XPBoostContent: React.FC<IXPBoostContentProps> = ({
  userXPMultiplier,
  secondsRemaining,
}: IXPBoostContentProps) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "xpBoost",
  });

  const minutesRemaining = Math.floor(secondsRemaining / 60);

  const boostAdjective =
    userXPMultiplier?.xp_multiplier == 2 ? t("double") : t("extra");

  return (
    <>
      <VStack spacing={pxToRem(-14)}>
        <Icon icon="xp_boost" boxSize={pxToRem(100)} />
        <Box
          display="flex"
          minW={pxToRem(80)}
          h={pxToRem(30)}
          borderRadius="full"
          justifyContent="center"
          alignItems="center"
          bgColor="xp-boost.timer-bg"
        >
          <Text variant="adminP1" color="primary.warm-black">
            {moment.utc(secondsRemaining * 1000).format("m:ss")}
          </Text>
        </Box>
      </VStack>
      <Heading as="h2" variant="adminH4">
        {t("heading", {
          adjective: boostAdjective,
        })}
      </Heading>
      <Text>
        <Trans
          i18nKey="description"
          t={t}
          values={{
            adjective: boostAdjective,
            minutes: minutesRemaining,
            count: minutesRemaining,
          }}
          components={{ bold: <strong /> }}
        />
      </Text>
    </>
  );
};
