import {
  Box,
  Checkbox,
  FormControl,
  FormLabel,
  SimpleGrid,
  Tooltip,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocalStorage, useUnmount } from "react-use";

import { Input } from "adminComponents/atoms/Input";
import { Tag } from "adminComponents/atoms/Tag";
import { Text } from "adminComponents/atoms/Text";
import { CreateQuestionFooter } from "adminComponents/molecules/CreateQuestionFooter";
import { SearchInput } from "adminComponents/molecules/SearchInput";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { ISkill } from "links/lib/types";

import { SkillsPickerCard } from "../SkillsPickerCard";

export interface CreateInternalTabForm {
  cncCode: string;
  isCertified: boolean;
  isPremium: boolean;
  // omission of preceding skills fixes error with react hook form typescript
  // checking for circular dependencies
  skill: Omit<ISkill, "preceding_skills"> | null;
}

const initialValue: CreateInternalTabForm = {
  cncCode: "",
  isCertified: false,
  isPremium: false,
  skill: null,
};

interface CreateQuestionInternalTabProps {
  skillOptions: ISkill[];
  canSubmit: boolean;
  isSubmitting: boolean;
  defaultValue?: CreateInternalTabForm;
  handleSaveAndClose: (form: CreateInternalTabForm) => void;
  handleChangeForm: (form: CreateInternalTabForm) => void;
  handleSearch: (search: string) => void;
  handleDirty?: () => void;
}

export const CreateQuestionInternalTab: React.FC<
  CreateQuestionInternalTabProps
> = ({
  handleSaveAndClose,
  handleChangeForm,
  skillOptions,
  defaultValue,
  handleSearch,
  canSubmit,
  isSubmitting,
  handleDirty,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const [mostRecentlyUsedSkillIds, setMostRecentlyUsedSkillIds] =
    useLocalStorage<Array<string>>("cnc-recent-skill-ids", []);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { isDirty },
  } = useForm<CreateInternalTabForm>({
    defaultValues: defaultValue ?? initialValue,
    mode: "onChange",
  });

  useEffect(() => {
    if (isDirty && handleDirty) handleDirty();
  }, [isDirty, handleDirty]);

  const selectedSkill = watch("skill");

  useUnmount(() => {
    handleChangeForm(getValues());
  });

  const _handleSubmit = (data: CreateInternalTabForm) => {
    handleSaveAndClose(data);
  };

  const handleCheckSkill = (checked: boolean, value: ISkill) => {
    if (checked) {
      setValue("skill", value);

      const newMostRecentlyUsedSkillIds = [
        value.id,
        ...(mostRecentlyUsedSkillIds || []).filter((id) => id !== value.id),
      ].slice(0, 3);

      setMostRecentlyUsedSkillIds(newMostRecentlyUsedSkillIds);
    } else {
      handleRemoveSkill();
    }
  };

  const handleRemoveSkill = () => {
    setValue("skill", null);
  };

  return (
    <form onSubmit={handleSubmit(_handleSubmit)}>
      <SimpleGrid
        py={pxToRem(40)}
        px={[
          "admin.flyout.mobileXPadding",
          null,
          "admin.flyout.desktopXPadding",
        ]}
        gap={pxToRem(40)}
      >
        <FormControl variant="adminFormControl">
          <FormLabel>{t("createQuestion.labelCncCode")}</FormLabel>
          <Input {...register("cncCode")} />
        </FormControl>
        <Box>
          <Box
            display="flex"
            alignItems="center"
            gap={pxToRem(6)}
            marginBottom={pxToRem(8)}
          >
            <Text variant="adminP2">{t("createQuestion.certified")}</Text>
          </Box>
          <Text
            variant="adminP2"
            color="primary.dark-gray"
            marginBottom={pxToRem(16)}
            verticalAlign="middle"
          >
            {t("createQuestion.certifiedDescription")}
          </Text>
          <Checkbox
            {...register("isCertified")}
            marginBottom={pxToRem(40)}
            variant="adminCheckboxWhite"
          >
            <Text>{t("createQuestion.certifiedCheckbox")}</Text>
          </Checkbox>
        </Box>
        <Box>
          <Box
            display="flex"
            alignItems="center"
            gap={pxToRem(6)}
            marginBottom={pxToRem(8)}
          >
            <Text variant="adminP2">{t("createQuestion.premium")}</Text>
          </Box>
          <Text
            variant="adminP2"
            color="primary.dark-gray"
            marginBottom={pxToRem(16)}
            verticalAlign="middle"
          >
            {t("createQuestion.premiumDescription")}
          </Text>
          <Checkbox
            {...register("isPremium")}
            marginBottom={pxToRem(40)}
            variant="adminCheckboxWhite"
          >
            <Text>{t("createQuestion.premiumCheckbox")}</Text>
          </Checkbox>
        </Box>
        <SimpleGrid gap={pxToRem(24)}>
          <Text as="h4" variant="adminP1">
            {t("createQuestion.skillsTitle")}
          </Text>
          <Box>
            <SearchInput
              placeholder={t("common.search")}
              handleSearch={handleSearch}
              buttonText={t("common.search")}
            />
          </Box>
          {selectedSkill && (
            <Tooltip label={selectedSkill.description}>
              <Box>
                <Tag
                  variant="adminSolid"
                  key={selectedSkill.id}
                  rightIcon="close"
                  colorScheme="default"
                  handleClickRightIcon={handleRemoveSkill}
                  maxW={pxToRem(500)}
                >
                  <Text as="span" fontWeight="bold">
                    {selectedSkill.code}
                  </Text>
                  : {selectedSkill.description}
                </Tag>
              </Box>
            </Tooltip>
          )}
        </SimpleGrid>

        <Box>
          <Text mb={pxToRem(8)}>Recently Used</Text>
          <SimpleGrid gap={pxToRem(12)}>
            {(mostRecentlyUsedSkillIds || []).map((skillId) => {
              const skill = skillOptions.find((skill) => skill.id === skillId);
              if (!skill) return <></>;
              return (
                <SkillsPickerCard
                  key={skill.id}
                  checked={selectedSkill?.id === skill.id}
                  skill={skill}
                  onChange={(checked) => handleCheckSkill(checked, skill)}
                />
              );
            })}
          </SimpleGrid>
        </Box>

        <Box>
          <Text mb={pxToRem(8)}>Other</Text>
          <SimpleGrid gap={pxToRem(12)}>
            {skillOptions
              .filter(
                (skill) => mostRecentlyUsedSkillIds?.indexOf(skill.id) === -1
              )
              .map((skill) => (
                <SkillsPickerCard
                  key={skill.id}
                  checked={selectedSkill?.id === skill.id}
                  skill={skill}
                  onChange={(checked) => handleCheckSkill(checked, skill)}
                />
              ))}
          </SimpleGrid>
        </Box>

        <CreateQuestionFooter
          canSubmit={canSubmit}
          isSubmitting={isSubmitting}
          hideHigherOrderThinking
          handleSaveAndClose={handleSubmit(_handleSubmit)}
        />
      </SimpleGrid>
    </form>
  );
};
