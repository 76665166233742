import React from "react";

interface IBackgroundProps {
  color: string;
  scoreText: string;
  teamName: string;
  showNameFirst?: boolean;
  constrainWidth?: boolean;
}

export const Background: React.FC<IBackgroundProps> = ({
  color,
  scoreText,
  teamName,
  showNameFirst,
  constrainWidth,
}) => {
  const teamNameStyles = {
    fontSize: 36,
    fill: color,
  };

  const scoreStyles = {
    fontSize: 100,
    fill: "black",
  };

  const firstTextFieldStyles = showNameFirst ? teamNameStyles : scoreStyles;
  const secondTextFieldStyles = showNameFirst ? scoreStyles : teamNameStyles;
  return (
    <svg
      width={constrainWidth ? "50%" : "100%"}
      height="100%"
      viewBox="0 0 820 555"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_3037_130654)">
        <path
          d="M780 271.8C780 451.033 571.283 515 348.926 515C126.57 515 40 388.117 40 256.6C40 125.083 187.05 40 409.407 40C631.764 40 771.106 86.2333 780 271.8Z"
          fill={color}
        />
        <path
          d="M725.222 273C725.222 414.5 549.933 465 363.19 465C176.446 465 103.742 364.83 103.742 261C103.742 157.17 227.24 90 413.984 90C600.728 90 717.752 126.5 725.222 273Z"
          fill="white"
        />
        <text
          {...firstTextFieldStyles}
          x="50%"
          textAnchor="middle"
          fontFamily={`"Gooper", Georgia, serif`}
          y={showNameFirst ? "45%" : "50%"}
          id="winningTeamScoreBoardBackgroundTeamScore"
        >
          {showNameFirst ? teamName : scoreText}
        </text>
        <text
          {...secondTextFieldStyles}
          x="50%"
          textAnchor="middle"
          fontFamily={`"Gooper", Georgia, serif`}
          y={showNameFirst ? "63%" : "60%"}
        >
          {showNameFirst ? scoreText : teamName}
        </text>
        <g filter="url(#filter1_d_3037_130654)">
          <circle cx="358.789" cy="65" r="10" fill="#FFC629" />
        </g>
        <circle cx="355.455" cy="61.6667" r="5" fill="#FFEEBF" />
        <circle cx="354.622" cy="60.8333" r="2.5" fill="white" />
        <g filter="url(#filter2_d_3037_130654)">
          <circle cx="490.256" cy="65" r="10" fill="#FFC629" />
        </g>
        <circle cx="486.922" cy="61.6667" r="5" fill="#FFEEBF" />
        <circle cx="486.089" cy="60.8333" r="2.5" fill="white" />
        <g filter="url(#filter3_d_3037_130654)">
          <circle cx="623.715" cy="94" r="10" fill="#FFC629" />
        </g>
        <circle cx="620.381" cy="90.6667" r="5" fill="#FFEEBF" />
        <circle cx="619.548" cy="89.8333" r="2.5" fill="white" />
        <g filter="url(#filter4_d_3037_130654)">
          <circle cx="725.303" cy="171" r="10" fill="#FFC629" />
        </g>
        <circle cx="721.969" cy="167.667" r="5" fill="#FFEEBF" />
        <circle cx="721.136" cy="166.833" r="2.5" fill="white" />
        <g filter="url(#filter5_d_3037_130654)">
          <circle cx="749.206" cy="298" r="10" fill="#FFC629" />
        </g>
        <circle cx="745.873" cy="294.667" r="5" fill="#FFEEBF" />
        <circle cx="745.04" cy="293.833" r="2.5" fill="white" />
        <g filter="url(#filter6_d_3037_130654)">
          <circle cx="692.436" cy="405" r="10" fill="#FFC629" />
        </g>
        <circle cx="689.103" cy="401.667" r="5" fill="#FFEEBF" />
        <circle cx="688.27" cy="400.833" r="2.5" fill="white" />
        <g filter="url(#filter7_d_3037_130654)">
          <circle cx="573.917" cy="460" r="10" fill="#FFC629" />
        </g>
        <circle cx="570.583" cy="456.667" r="5" fill="#FFEEBF" />
        <circle cx="569.75" cy="455.833" r="2.5" fill="white" />
        <g filter="url(#filter8_d_3037_130654)">
          <circle cx="440.458" cy="485" r="10" fill="#FFC629" />
        </g>
        <circle cx="437.124" cy="481.667" r="5" fill="#FFEEBF" />
        <circle cx="436.291" cy="480.833" r="2.5" fill="white" />
        <g filter="url(#filter9_d_3037_130654)">
          <circle cx="313.971" cy="485" r="10" fill="#FFC629" />
        </g>
        <circle cx="310.637" cy="481.667" r="5" fill="#FFEEBF" />
        <circle cx="309.804" cy="480.833" r="2.5" fill="white" />
        <g filter="url(#filter10_d_3037_130654)">
          <circle cx="191.467" cy="454" r="10" fill="#FFC629" />
        </g>
        <circle cx="188.134" cy="450.667" r="5" fill="#FFEEBF" />
        <circle cx="187.3" cy="449.833" r="2.5" fill="white" />
        <g filter="url(#filter11_d_3037_130654)">
          <circle cx="103.823" cy="383" r="10" fill="#FFC629" />
        </g>
        <circle cx="100.489" cy="379.667" r="5" fill="#FFEEBF" />
        <circle cx="99.6558" cy="378.833" r="2.5" fill="white" />
        <g filter="url(#filter12_d_3037_130654)">
          <circle cx="69.9597" cy="265" r="10" fill="#FFC629" />
        </g>
        <circle cx="66.6265" cy="261.667" r="5" fill="#FFEEBF" />
        <circle cx="65.793" cy="260.833" r="2.5" fill="white" />
        <g filter="url(#filter13_d_3037_130654)">
          <circle cx="109.798" cy="151" r="10" fill="#FFC629" />
        </g>
        <circle cx="106.465" cy="147.667" r="5" fill="#FFEEBF" />
        <circle cx="105.631" cy="146.833" r="2.5" fill="white" />
        <g filter="url(#filter14_d_3037_130654)">
          <circle cx="239.273" cy="90" r="10" fill="#FFC629" />
        </g>
        <circle cx="235.94" cy="86.6667" r="5" fill="#FFEEBF" />
        <circle cx="235.107" cy="85.8333" r="2.5" fill="white" />
      </g>
      <defs>
        <filter
          id="filter0_d_3037_130654"
          x="0"
          y="0"
          width="820"
          height="555"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="20" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3037_130654"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3037_130654"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_3037_130654"
          x="344.789"
          y="51"
          width="28"
          height="28"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3037_130654"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3037_130654"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_3037_130654"
          x="476.256"
          y="51"
          width="28"
          height="28"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3037_130654"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3037_130654"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_d_3037_130654"
          x="609.715"
          y="80"
          width="28"
          height="28"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3037_130654"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3037_130654"
            result="shape"
          />
        </filter>
        <filter
          id="filter4_d_3037_130654"
          x="711.303"
          y="157"
          width="28"
          height="28"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3037_130654"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3037_130654"
            result="shape"
          />
        </filter>
        <filter
          id="filter5_d_3037_130654"
          x="735.206"
          y="284"
          width="28"
          height="28"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3037_130654"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3037_130654"
            result="shape"
          />
        </filter>
        <filter
          id="filter6_d_3037_130654"
          x="678.436"
          y="391"
          width="28"
          height="28"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3037_130654"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3037_130654"
            result="shape"
          />
        </filter>
        <filter
          id="filter7_d_3037_130654"
          x="559.917"
          y="446"
          width="28"
          height="28"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3037_130654"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3037_130654"
            result="shape"
          />
        </filter>
        <filter
          id="filter8_d_3037_130654"
          x="426.458"
          y="471"
          width="28"
          height="28"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3037_130654"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3037_130654"
            result="shape"
          />
        </filter>
        <filter
          id="filter9_d_3037_130654"
          x="299.971"
          y="471"
          width="28"
          height="28"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3037_130654"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3037_130654"
            result="shape"
          />
        </filter>
        <filter
          id="filter10_d_3037_130654"
          x="177.467"
          y="440"
          width="27.9998"
          height="28"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3037_130654"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3037_130654"
            result="shape"
          />
        </filter>
        <filter
          id="filter11_d_3037_130654"
          x="89.8225"
          y="369"
          width="28"
          height="28"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3037_130654"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3037_130654"
            result="shape"
          />
        </filter>
        <filter
          id="filter12_d_3037_130654"
          x="55.9597"
          y="251"
          width="28"
          height="28"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3037_130654"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3037_130654"
            result="shape"
          />
        </filter>
        <filter
          id="filter13_d_3037_130654"
          x="95.7981"
          y="137"
          width="28"
          height="28"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3037_130654"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3037_130654"
            result="shape"
          />
        </filter>
        <filter
          id="filter14_d_3037_130654"
          x="225.273"
          y="76"
          width="28"
          height="28"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3037_130654"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3037_130654"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
