import { Box, HStack } from "@chakra-ui/react";
import React from "react";

import { Card } from "adminComponents/atoms/Card";
import { Heading } from "adminComponents/atoms/Heading";
import { pxToRem } from "adminComponents/utils";

import TipSVG from "./resource/tip.svg";

export interface ITipCardProps {
  heading: string;
}

export const TipCard: React.FC<ITipCardProps> = ({ heading, children }) => {
  return (
    <Card
      variant="adminCardSmallBorder"
      borderColor="primary.tan"
      bgColor="white"
    >
      <HStack w="full" alignItems="flex-start">
        <Box
          flexShrink={0}
          display={["none", "block"]}
          boxSize={[pxToRem(56), null, pxToRem(64)]}
          backgroundImage={`url(${TipSVG})`}
          backgroundRepeat="no-repeat"
          backgroundPosition="center center"
          backgroundSize="contain"
        />
        <Box>
          <Heading variant="adminH4" as="p" color="utility.link">
            {heading}
          </Heading>
          <Heading as="p" variant="adminH6" color="primary.warm-black">
            {children}
          </Heading>
        </Box>
      </HStack>
    </Card>
  );
};
