import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSessionStorage } from "react-use";

import {
  AssignmentFlyout,
  FormDataProps,
  dateDataToMoment,
} from "adminComponents/organisms/AssignmentFlyout";
import { GoogleClassroomAuthorizeModal } from "adminComponents/organisms/GoogleClassroomAuthorizeModal";
import {
  SelectStudentModal,
  StudentItem,
} from "adminComponents/organisms/SelectStudentModal";
import { generateStudentName } from "adminComponents/utils";
import { useErrorToast, useShowToast } from "adminComponents/utils/toast";
import {
  ASSIGNMENT_ACCURACY_GOALS,
  ASSIGNMENT_TIME_LIST,
  ASSIGNMENT_TIME_OF_DAY,
  afterSignInPathSessionKey,
} from "lib/constants";
import { useAnalytics } from "lib/contexts/analytics";
import { useHubSpot } from "lib/hooks/useHubSpot";
import { useMutateAssignment } from "links/lib/features/assignments";
import {
  IMutateAssignmentArgs,
  IMutateAssignmentResponse,
} from "links/lib/features/assignments/useMutateAssignment";
import {
  useFetchClassroomUsersBulk,
  useFetchClassrooms,
  useFetchGoogleClassroomClassrooms,
} from "links/lib/features/classrooms";
import {
  AnalyticsEvent,
  IAssignment,
  IClassroom,
  IPracticeSet,
  ThirdParty,
  UserRole,
  UsersGroupsRole,
} from "links/lib/types";
import useFetchProviderRedirectUrl from "screens/SignIn/useFetchProviderRedirectUrl";

export interface IArgs {
  practiceSet?: IPracticeSet;
  intentEvent?: AnalyticsEvent;
  confirmEvent?: AnalyticsEvent;
  onSubmit?: (assignment?: IAssignment) => void;
}

export interface IResult {
  handleCreateIntent: () => void;
  component: React.ReactElement;
}

const useCreateAssignment = ({
  onSubmit,
  practiceSet,
  intentEvent = AnalyticsEvent.TeacherDashboard_PracticeSetDetail_AssignmentFlyout_CreateAssignmentIntent,
  confirmEvent = AnalyticsEvent.TeacherDashboard_PracticeSetDetail_AssignmentFlyout_CreateAssignmentConfirm,
}: IArgs): IResult => {
  const showToast = useShowToast();
  const [selectedStudentIds, setSelectedStudentIds] = useState<Array<string>>(
    []
  );
  const [isStudentSelectModalOpen, setIsStudentSelectModalOpen] =
    useState<boolean>(false);
  const [isFlyoutOpen, setIsFlyoutOpen] = useState<boolean>(false);
  const [selectedClassroom, setSelectedClassroom] = useState<IClassroom | null>(
    null
  );
  const { t } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "practiceSetDetailContainer.assignmentFlyout",
  });
  const { trackHubSpotEvent } = useHubSpot();
  const { trackEvent } = useAnalytics();

  const [isAuthorizeModalOpen, setIsAuthorizeModalOpen] =
    useState<boolean>(false);

  const [, setSessionRedirectPath] = useSessionStorage(
    afterSignInPathSessionKey,
    "/"
  );

  const gclassroomFetch = useFetchGoogleClassroomClassrooms();
  const redirectFetch = useFetchProviderRedirectUrl();

  const classroomsFetch = useFetchClassrooms({
    archived: false,
  });

  const classrooms = useMemo(
    () => classroomsFetch.data?.classrooms || [],
    [classroomsFetch.data?.classrooms]
  );

  const classroomStudentsBulkFetch = useFetchClassroomUsersBulk({
    classroom_ids:
      classrooms.length > 0 ? classrooms.map((c) => c.id) : undefined,
    group_roles: [UsersGroupsRole.Member],
  });

  const handleFlyoutClose = useCallback(() => {
    setIsFlyoutOpen(false);
    setSelectedClassroom(null);
    setSelectedStudentIds([]);
  }, []);

  const onCreateSuccess = useCallback(() => {
    trackHubSpotEvent(AnalyticsEvent.HubSpot_AssignedPracticeSet, {});
  }, [trackHubSpotEvent]);

  const assignmentMutation = useMutateAssignment({
    onSuccess: onCreateSuccess,
  });
  useErrorToast(assignmentMutation.error);

  const handleClassroomSelection = useCallback(
    (classroom: IClassroom) => {
      setSelectedStudentIds(
        classroomStudentsBulkFetch.classroomData[classroom.id]
          ?.filter((u) => u.id !== "0")
          .map((u) => u.id) || []
      );
      setSelectedClassroom(classroom);
    },
    [classroomStudentsBulkFetch.classroomData]
  );

  const initialSelectedClassroom = useMemo(() => {
    const eligibleClassrooms = classrooms.filter((c) => c.students_count > 0);
    return eligibleClassrooms.length === 1 ? eligibleClassrooms[0] : undefined;
  }, [classrooms]);

  const handleCreateIntent = useCallback(() => {
    setSelectedStudentIds([]);
    setSelectedClassroom(null);

    if (initialSelectedClassroom) {
      setSelectedClassroom(initialSelectedClassroom);
      setSelectedStudentIds(
        classroomStudentsBulkFetch.classroomData[initialSelectedClassroom.id]
          ?.filter((u) => u.id !== "0")
          .map((u) => u.id) || []
      );
    }

    setIsFlyoutOpen(true);

    trackEvent(intentEvent, {
      practice_set_id: practiceSet?.id,
    });
  }, [
    trackEvent,
    practiceSet?.id,
    intentEvent,
    classroomStudentsBulkFetch.classroomData,
    initialSelectedClassroom,
  ]);

  const handleStudentSelection = useCallback(() => {
    setIsStudentSelectModalOpen(true);
  }, []);

  const handleStudentSelectSubmit = useCallback((students: Array<string>) => {
    setSelectedStudentIds(students);
    setIsStudentSelectModalOpen(false);
  }, []);

  const handleStudentSelectClose = useCallback(() => {
    setIsStudentSelectModalOpen(false);
  }, []);

  const handleSubmit = useCallback(
    (formData: FormDataProps) => {
      const { classroomIds, practiceSet: practiceSetId } = formData.form;
      const classroomCount = classroomIds?.length || 0;

      const firstClassroomId = classroomIds.shift();
      if (!firstClassroomId) return;

      assignmentMutation
        .mutateAsync(
          getMutateAssignmentArgs({
            classroomId: firstClassroomId,
            formData,
            selectedStudentIds,
            practiceSetId,
            classroomCount,
          })
        )
        .then((response: IMutateAssignmentResponse) => {
          if (response.requires_third_party_authorization) {
            redirectFetch.execute({
              provider: "google_classroom",
              role: UserRole.Teacher,
            });
            setIsAuthorizeModalOpen(true);
            throw new Error("Need to Authorize with Google");
          }

          // Use practice set ID from the first class assignment
          const mutations = classroomIds.map((classroomId) => {
            return assignmentMutation
              .mutateAsync(
                getMutateAssignmentArgs({
                  classroomId,
                  formData,
                  selectedStudentIds,
                  // get the practice set id from the response from creating the first assignment so that we don't copy public sets into
                  // users private library multiple times
                  practiceSetId: response.assignment.practice_set_id,
                  classroomCount,
                })
              )
              .then((response: IMutateAssignmentResponse) => {
                if (response.requires_third_party_authorization) {
                  redirectFetch.execute({
                    provider: "google_classroom",
                    role: UserRole.Teacher,
                  });
                  setIsAuthorizeModalOpen(true);
                  throw new Error("Need to Authorize with Google");
                }
              });
          });

          if (!mutations.length) {
            showToast(t("successToast"));
            handleFlyoutClose();
          }

          Promise.all(mutations)
            .then(() => {
              if (mutations.length) {
                const successMessage =
                  mutations.length > 0
                    ? t("successToastMultiple", { count: mutations.length + 1 })
                    : t("successToast");
                showToast(successMessage);
              }
              handleFlyoutClose();
            })
            .catch(() => {
              // opened auth modal in then of 'assignmentMutation.mutateAsync' do nothing here
            });
        })
        .catch(() => {
          // opened auth modal in then of 'assignmentMutation.mutateAsync' do nothing here
        });

      onSubmit?.();

      trackEvent(confirmEvent, {
        practice_set_id: practiceSetId,
        is_certified: practiceSet?.is_certified || false,
      });
    },
    [
      selectedStudentIds,
      assignmentMutation,
      onSubmit,
      t,
      showToast,
      handleFlyoutClose,
      trackEvent,
      practiceSet?.is_certified,
      redirectFetch,
      confirmEvent,
    ]
  );

  const practiceSets = useMemo(
    () => (practiceSet ? [practiceSet] : []),
    [practiceSet]
  );

  const handleAuthorizeClose = useCallback(() => {
    setIsAuthorizeModalOpen(false);
  }, []);

  const handleGrantAccess = useCallback(() => {
    if (!redirectFetch.data) return;

    trackEvent(
      AnalyticsEvent.TeacherDashboard_ClassroomDetail_AssignmentsTab_AssignToGClassroomAuthorize,
      {}
    );

    setSessionRedirectPath(window.location.pathname);
    window.location.href = redirectFetch.data.redirectUrl;
  }, [redirectFetch, setSessionRedirectPath, trackEvent]);

  const authorizeModal = useMemo(() => {
    return (
      <GoogleClassroomAuthorizeModal
        isOpen={isAuthorizeModalOpen}
        isLoading={redirectFetch.isLoading}
        handleGrantAccess={handleGrantAccess}
        handleClose={handleAuthorizeClose}
      />
    );
  }, [
    isAuthorizeModalOpen,
    handleAuthorizeClose,
    handleGrantAccess,
    redirectFetch.isLoading,
  ]);

  const handleToggleAssignmentInThirdParty = useCallback(
    (assignInThirdParty: boolean) => {
      if (
        gclassroomFetch.data?.requires_authorization &&
        assignInThirdParty &&
        !isAuthorizeModalOpen
      ) {
        setIsAuthorizeModalOpen(true);
        redirectFetch.execute({
          provider: "google_classroom",
          role: UserRole.Teacher,
        });
      }
    },
    [
      gclassroomFetch.data?.requires_authorization,
      isAuthorizeModalOpen,
      redirectFetch,
    ]
  );

  const component = useMemo(() => {
    return (
      <>
        <AssignmentFlyout
          classrooms={classrooms}
          accuracyGoals={ASSIGNMENT_ACCURACY_GOALS}
          isOpen={isFlyoutOpen}
          isLoading={assignmentMutation.isLoading}
          practiceSets={practiceSets}
          selectedStudentIds={
            selectedStudentIds.length !==
            classroomStudentsBulkFetch.classroomData[
              selectedClassroom?.id || ""
            ]?.length
              ? selectedStudentIds
              : []
          }
          handleClose={handleFlyoutClose}
          handleSubmit={handleSubmit}
          handleClassroomSelection={handleClassroomSelection}
          handleStudentSelection={handleStudentSelection}
          timeList={ASSIGNMENT_TIME_LIST}
          timeOfDay={ASSIGNMENT_TIME_OF_DAY}
          lockToPracticeSet={practiceSet}
          toggleAssignInThirdParty={handleToggleAssignmentInThirdParty}
          requiresThirdPartyAuth={
            !!gclassroomFetch.data?.requires_authorization
          }
          classroomStudentsMap={classroomStudentsBulkFetch.classroomData}
          googleClassrooms={gclassroomFetch.data?.classrooms}
          initialSelectedClassroom={initialSelectedClassroom}
        />
        {authorizeModal}
        {!!selectedClassroom && (
          <SelectStudentModal
            isLoading={classroomStudentsBulkFetch.isLoading}
            classroom={selectedClassroom}
            isOpen={isStudentSelectModalOpen}
            selectedStudentIds={selectedStudentIds}
            handleSubmit={handleStudentSelectSubmit}
            handleClose={handleStudentSelectClose}
          >
            {(
              classroomStudentsBulkFetch.classroomData?.[
                selectedClassroom.id
              ] || []
            )
              .filter((user) => user.id !== "0")
              .map((user) => {
                const studentName = generateStudentName(user);

                return (
                  <StudentItem
                    key={`student-${user.id}`}
                    id={user.id}
                    displayName={studentName ? studentName.primary : user.email}
                  />
                );
              })}
          </SelectStudentModal>
        )}
      </>
    );
  }, [
    classrooms,
    selectedStudentIds,
    handleFlyoutClose,
    handleStudentSelection,
    handleClassroomSelection,
    isFlyoutOpen,
    isStudentSelectModalOpen,
    classroomStudentsBulkFetch.classroomData,
    selectedClassroom,
    handleStudentSelectClose,
    handleStudentSelectSubmit,
    classroomStudentsBulkFetch.isLoading,
    assignmentMutation.isLoading,
    handleSubmit,
    practiceSet,
    practiceSets,
    authorizeModal,
    gclassroomFetch.data,
    handleToggleAssignmentInThirdParty,
    initialSelectedClassroom,
  ]);

  return useMemo(() => {
    return {
      component,
      handleCreateIntent,
    };
  }, [component, handleCreateIntent]);
};

export { useCreateAssignment };

interface IGetMutateAssignmentArgs {
  classroomId: string;
  formData: FormDataProps;
  selectedStudentIds: string[];
  practiceSetId: string;
  classroomCount: number;
}

const getMutateAssignmentArgs = (
  assignmentArgs: IGetMutateAssignmentArgs
): IMutateAssignmentArgs => {
  const {
    assignmentId,
    description,
    accuracyGoal,
    customStartDate,
    startDate,
    startHour,
    startTimeOfDay,
    dueDate,
    dueHour,
    dueTimeOfDay,
    assignToGoogleClassroom,
    randomizeItemOrder,
  } = assignmentArgs.formData.form;

  const isCustomStartTime = customStartDate === "custom";

  return {
    id: assignmentId,
    classroom_id: assignmentArgs.classroomId,
    practice_set_id: assignmentArgs.practiceSetId,
    description: description,
    required_score_percent: Math.min(Math.max(Math.ceil(accuracyGoal), 1), 100),
    required_duration_sec: 0,
    starts_at: isCustomStartTime
      ? dateDataToMoment(startDate, startHour, startTimeOfDay).toISOString()
      : new Date().toISOString(),
    ends_at: dateDataToMoment(dueDate, dueHour, dueTimeOfDay).toISOString(),
    user_ids:
      assignmentArgs.classroomCount === 1
        ? assignmentArgs.selectedStudentIds
        : [],
    start_now: !isCustomStartTime,
    third_party: assignToGoogleClassroom
      ? ThirdParty.GOOGLE_CLASSROOM
      : ThirdParty.NONE,
    randomize_item_order: randomizeItemOrder,
  };
};
