import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { config } from "react-spring";

import { PodiumSummaryCard } from "sessionComponents/atoms/PodiumSummaryCard";
import { PodiumSummaryStat } from "sessionComponents/atoms/PodiumSummaryStat";
import { StudentSessionSoundEffect } from "sessionComponents/types";
import { useSessionAudio } from "sharedComponents/contexts/sessionAudio";

import accuracyIcon from "../resource/accuracy_icon.svg";

interface IAccuracyCardProps {
  show: boolean;
  handleSetTotalXP: React.Dispatch<React.SetStateAction<number>>;
  handleComplete: () => void;
  accuracyXP: number;
  practiceAccuracy: number;
}

const stringifyPercentage = (val: number) => `${val.toFixed(0)}%`;

export const AccuracyCard: React.FC<IAccuracyCardProps> = ({
  show,
  handleComplete,
  handleSetTotalXP,
  practiceAccuracy,
  accuracyXP,
}) => {
  const [isCardEntered, setIsCardEntered] = useState<boolean>(false);
  const [isAccuracyComplete, setIsAccuracyComplete] = useState<boolean>(false);
  const { play: playAudio } = useSessionAudio();
  const { t } = useTranslation("session", {
    useSuspense: false,
    keyPrefix: "podium.summary",
  });

  const handleCardEnter = () => {
    setIsCardEntered(true);

    if (practiceAccuracy === 0) {
      handleComplete();
    }
  };

  const showXP = accuracyXP > 0;

  const handleAccuracyComplete = () => {
    setIsAccuracyComplete(true);

    if (showXP) {
      handleSetTotalXP((val: number) => val + accuracyXP);
      playAudio(StudentSessionSoundEffect.AccuracyXP);
    } else {
      handleComplete();
    }
  };

  return (
    <PodiumSummaryCard
      show={show}
      handleComplete={handleCardEnter}
      iconUrl={accuracyIcon}
    >
      <PodiumSummaryStat
        start={0}
        end={practiceAccuracy}
        show={isCardEntered}
        handleComplete={handleAccuracyComplete}
        handleStatStringify={stringifyPercentage}
        animConfig={config.default}
        label={t("accuracy")}
      />
      {showXP && (
        <PodiumSummaryStat
          start={0}
          end={accuracyXP}
          show={isAccuracyComplete}
          handleComplete={handleComplete}
          label="XP"
        />
      )}
    </PodiumSummaryCard>
  );
};
