import { pxToRem } from "adminComponents/utils/pxToRem";

export const AdminTeacherCard = {
  baseStyle: {
    avatar: {
      alignItems: "center",
      display: "flex",
      "> *:first-child": {
        marginRight: { base: pxToRem(12), md: pxToRem(20) },
      },
    },
    container: {
      alignItems: { base: "flex-start", md: "center" },
      display: "flex",
      flexDirection: { base: "column" },
      gap: pxToRem(16),
      justifyContent: "space-between",
      position: "relative",
      width: "100%",
      wordBreak: "break-word",
      maxWidth: "full",
    },
    content: {
      alignSelf: "start",
      textAlign: "left",
      wordBreak: "normal",
      maxWidth: "full",
      overflow: "hidden",
    },
    divider: {
      borderBottomWidth: pxToRem(2),
      borderColor: "primary.light-gray",
      display: "block",
      opacity: "1",
    },
    left: {
      display: "flex",
      flexDirection: { base: "column", lg: "row" },
      gap: pxToRem(16),
      alignItems: "center",
      maxWidth: "full",
    },
    right: {
      display: "flex",
      alignItems: "center",
      flexShrink: 0,
      width: { base: "100%", md: "auto" },
    },
    buttonContainer: {
      display: "flex",
      flexDirection: {
        base: "row",
        md: "column",
        xl: "row",
      },
      width: "100%",
      gap: pxToRem(16),
    },
    linkOverlay: {
      _focus: {
        boxShadow: "none",
        outline: "none",
      },
      _focusVisible: {
        outlineStyle: "solid",
        outlineWidth: pxToRem(2),
        outlineColor: "utility.focus",
      },
    },
    bottomLine: {
      display: "-webkit-box !important",
      "-webkit-line-clamp": "1",
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      wordBreak: "break-word",
      fontFamily: "TTCommons",
      fontWeight: "400",
      fontSize: pxToRem(14),
      lineHeight: "130%",
      letterSpacing: "0",
    },
  },
};
