import { Portal } from "@chakra-ui/react";
import React, { useCallback } from "react";

import { SeasonMapSoundEffect } from "links/lib/types";
import { useAudio } from "sharedComponents/contexts/audio";

import { FullscreenAnimationTakeover } from "../FullscreenAnimationTakeover";
import { default as monorailTransitionData } from "./resource/monorail-transition.json";

export interface ISeason2MapTransitionProps {
  show: boolean;
  handleComplete: () => void;
}

export const Season2MapTransition: React.FC<ISeason2MapTransitionProps> = ({
  handleComplete,
  show,
}) => {
  const { play } = useAudio();

  const handlePlay = useCallback(() => {
    play(SeasonMapSoundEffect.MonorailTransition);
  }, [play]);

  return (
    <Portal>
      <FullscreenAnimationTakeover
        bgColor="season-map.transition-bg"
        show={show}
        animationData={monorailTransitionData}
        handleEnd={handleComplete}
        handlePlay={handlePlay}
      />
    </Portal>
  );
};
