import { Box, Flex, HStack, Spacer, Text, VStack } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { pxToRem } from "adminComponents/utils/pxToRem";
import {
  SessionGameState,
  SessionGameType,
  SessionScene,
} from "links/lib/types";
import { Stage } from "sessionComponents/atoms/Stage";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { Carousel } from "sessionComponents/molecules/Carousel";
import { ConfirmButton } from "sessionComponents/molecules/ConfirmButton";
import { InstructionCard } from "sessionComponents/molecules/InstructionCard";
import { useDetectOrientation } from "sessionComponents/theme/hooks/useDetectOrientation";

export interface IGameInstructionsProps {
  isTeacher: boolean;
  gameState?: SessionGameState;
  scene: SessionScene;
  handleStartGame: () => void;
}

export const GameInstructions: React.FC<IGameInstructionsProps> = ({
  isTeacher,
  gameState,
  scene,
  handleStartGame,
}) => {
  const { t } = useTranslation("session", { useSuspense: false });
  //todo tracking?
  const { isPortrait, isLandscape } = useDetectOrientation();
  const { match: currentBreakpoints } = useBreakpoints();

  const instructionCards = useMemo(() => {
    if (gameState?.game_type === SessionGameType.TheBigBoard) {
      return [1, 2, 3].map((n) => (
        <InstructionCard
          outerGame={gameState?.game_type}
          order={n}
          key={`instruction_${n}`}
          height={isPortrait ? "100%" : undefined}
          style={
            isPortrait
              ? {
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }
              : undefined
          }
        />
      ));
    } else if (gameState?.game_type === SessionGameType.QuickPlay) {
      const steps = [1, 2];
      // Quick Play sessions with more than 5 items have a care package
      // event round which warrants extra instructions
      if (gameState.final_round_number >= 5) {
        steps.push(3);
      }

      return steps.map((n) => (
        <InstructionCard
          outerGame={gameState?.game_type}
          order={n}
          key={`instruction_${n}`}
          height={isPortrait ? "100%" : undefined}
          style={
            isPortrait
              ? {
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }
              : undefined
          }
        />
      ));
    } else {
      return [
        <Text
          key="instruction_1"
          w="full"
          alignSelf="center"
          textAlign="center"
          textStyle="gameDisplay"
          color="primary.warm-white"
          size="xl"
        >
          Unknown game_type
        </Text>,
      ];
    }
  }, [isPortrait, gameState?.game_type, gameState?.final_round_number]);

  return (
    <Stage showCurtainOnly outerGame={gameState?.game_type}>
      <VStack h="full" display="flex" flexDir="column" spacing="0">
        <Box
          padding={pxToRem(currentBreakpoints.padding)}
          flexGrow={1}
          h="full"
          overflowX="hidden"
          overflowY="auto"
        >
          {isPortrait ? (
            <Carousel items={instructionCards} />
          ) : (
            <HStack
              spacing={pxToRem(currentBreakpoints.margin)}
              alignItems="stretch"
              h="100%"
            >
              {instructionCards}
            </HStack>
          )}
        </Box>
        {isTeacher && (
          <>
            <Spacer />
            <Flex
              justifyContent="center"
              w="full"
              p={pxToRem(currentBreakpoints.padding)}
              bgColor="primary.white"
            >
              <ConfirmButton
                variant="buttonFilled"
                w="full"
                maxW={isLandscape ? "50%" : undefined}
                h={pxToRem(currentBreakpoints.buttonHeight)}
                fontSize={pxToRem(currentBreakpoints.fontSize)}
                handleConfirmClick={handleStartGame}
                confirmedWhenChanged={[scene]}
              >
                {t("gameInstructions.startGameButton")}
              </ConfirmButton>
            </Flex>
          </>
        )}
      </VStack>
    </Stage>
  );
};
