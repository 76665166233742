import { pxToRem } from "adminComponents/utils/pxToRem";

import { colorMapping } from "../colors";

export const Textarea = {
  variants: {
    adminTextarea: {
      border: `${pxToRem(1)} solid`,
      borderColor: "primary.medium-gray",
      textStyle: "adminP2",
      paddingX: pxToRem(16),
      paddingY: pxToRem(14),
      bg: "primary.white",
      color: "primary.warm-black",
      _readOnly: {
        boxShadow: "none !important",
        userSelect: "all",
      },
      _disabled: {
        opacity: 0.5,
        cursor: "not-allowed",
      },
      _invalid: {
        borderColor: "utility.error",
      },
      _focus: {
        border: `${pxToRem(2)} solid ${colorMapping["utility.focus"]}`,
        paddingX: pxToRem(15),
        paddingY: pxToRem(13),
        // removes 1px of padding for focus state to account for additional 1px of borderWidth
      },
      "::placeholder": {
        color: "primary.dark-gray",
      },
    },
  },
};
