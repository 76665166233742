import { Box, Center, Text, useDisclosure } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useMount } from "react-use";

import { isRichTextEmpty } from "adminComponents/organisms/RichTextEditor/util";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { useSessionActions } from "links/lib/contexts/sessionActions";
import { useSessionGameState } from "links/lib/contexts/sessionGameState";
import { useSessionGroups } from "links/lib/contexts/sessionGroups";
import { useSessionRoundGroupState } from "links/lib/contexts/sessionRoundGroupState";
import { useSessionRoundState } from "links/lib/contexts/sessionRoundState";
import { useSessionScene } from "links/lib/contexts/sessionScene";
import usePendingSubmit from "links/lib/hooks/usePendingSubmit";
import {
  CoopDragsortVariantData,
  CoopDrawVariantData,
  PracticeSessionItemVariantType,
  SessionGameType,
  SessionScene,
  UserRole,
} from "links/lib/types";
import { QuestionLayout } from "sessionComponents/atoms/QuestionLayout";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { useItemResponseOptions } from "sessionComponents/hooks/useItemResponseOptions";
import { QuestionBox } from "sessionComponents/molecules/QuestionBox";
import { RoundReviewAnimatedTransition } from "sessionComponents/molecules/RoundReviewAnimatedTransition";
import { Label } from "sessionComponents/molecules/ZoomableImage";
import { AwardedDrawings } from "sessionComponents/organisms/AwardedDrawings";
import { DrawReviewOptions } from "sessionComponents/organisms/DrawReviewOptions";
import { FeedbackFlyout } from "sessionComponents/organisms/FeedbackFlyout";
import { RoundReviewButtons } from "sessionComponents/organisms/RoundReviewButtons";
import { TeacherDiagramReviewOptions } from "sessionComponents/organisms/TeacherDiagramReviewOptions";
import { TeacherMultipleChoiceReviewOptions } from "sessionComponents/organisms/TeacherMultipleChoiceReviewOptions";
import { TeacherTextMatchReviewOptions } from "sessionComponents/organisms/TeacherTextMatchReviewOptions";
import { SplashScene, SplashType } from "sessionComponents/scenes/SplashScene";
import { TeacherSessionSoundEffect } from "sessionComponents/types";
import { getItemInstructions } from "sessionComponents/utils/getItemInstructions";
import { getQuestionPrompt } from "sessionComponents/utils/getQuestionPrompt";
import { useSessionAudio } from "sharedComponents/contexts/sessionAudio";
import { useSafeConnectedUserArray } from "sharedComponents/hooks/useSafeConnectedUserArray";

export const TeacherRoundReview: React.FC = () => {
  const { match } = useBreakpoints();
  const gameState = useSessionGameState();
  const roundState = useSessionRoundState();
  const roundGroupState = useSessionRoundGroupState();
  const {
    practice_set_session_item,
    rich_feedback,
    round_number,
    variant,
    variant_data,
  } = roundState;
  const connectedUsers = useSafeConnectedUserArray();
  const groups = useSessionGroups();
  const scene = useSessionScene();
  const isDrawVotingAwardsRound = scene === SessionScene.VotingAwards;

  const dragsortVariantData = variant_data as CoopDragsortVariantData;
  const drawingVariantData = variant_data as CoopDrawVariantData;

  const isQuickPlay = gameState?.game_type === SessionGameType.QuickPlay;

  const { play: playAudio } = useSessionAudio();
  const {
    initiateVoting,
    initiateOuterGame,
    initiateNextRound,
    initiateLeaderBoard,
  } = useSessionActions();

  const { t } = useTranslation("session", { useSuspense: false });

  const isDrawItem = variant === PracticeSessionItemVariantType.CoopDraw;

  const sceneChangeSubmit = usePendingSubmit([scene]);

  useMount(() => {
    playAudio(TeacherSessionSoundEffect.ReviewSting);
  });

  const instructionKey = getItemInstructions(practice_set_session_item);

  const instructions = instructionKey
    ? t(`practiceSets.instructions.${instructionKey}`)
    : "";

  const studentTotal = connectedUsers.filter(
    (u) => u.role === UserRole.Student
  ).length;
  const groupTotal = Object.keys(groups).length;

  const isInitialDrawingReview = isDrawItem && !isDrawVotingAwardsRound;

  const handleConfirmButtonClick = () => {
    const isThirdQuestion = !(round_number % 3);
    const isFinalQuestion = round_number === gameState?.final_round_number;
    let toNextScene = initiateOuterGame;
    if (isQuickPlay) {
      // initateNextRound will handle ending the game for us if this is the last question
      toNextScene =
        isThirdQuestion && !isFinalQuestion
          ? initiateLeaderBoard
          : initiateNextRound;
    }

    if (isInitialDrawingReview) {
      noValidImageUrls ? toNextScene() : initiateVoting();
    } else {
      toNextScene();
    }
    sceneChangeSubmit.handleSubmit();
  };

  const answerOptions =
    useItemResponseOptions(
      practice_set_session_item,
      roundGroupState,
      variant_data,
      match.imageResponseSize
    ) || [];

  const noValidImageUrls = answerOptions.every((option) => !option.image_url);

  const getReviewOptions = () => {
    switch (variant) {
      case PracticeSessionItemVariantType.CoopChecks:
      case PracticeSessionItemVariantType.CoopRadios:
        return (
          <TeacherMultipleChoiceReviewOptions
            studentTotal={studentTotal}
            answerOptions={answerOptions}
          />
        );
      case PracticeSessionItemVariantType.CoopTextMatch:
      case PracticeSessionItemVariantType.CoopTextMatchNumeric:
        return <TeacherTextMatchReviewOptions studentTotal={studentTotal} />;
      case PracticeSessionItemVariantType.CoopDragsort:
        return (
          <TeacherDiagramReviewOptions
            // Set to number of groups here, since dragsort items share state across all members of a group
            studentTotal={groupTotal}
            answerOptions={answerOptions}
          />
        );
      case PracticeSessionItemVariantType.CoopDraw:
        return isInitialDrawingReview ? (
          noValidImageUrls ? (
            <Center w="full" h="full" textAlign="center">
              <Text
                textStyle="gameText"
                fontSize={pxToRem(match.fontSize)}
                p={pxToRem(match.padding)}
                w="full"
                as="div"
              >
                {t("roundReview.noValidDrawingsTeacherMsg")}
              </Text>
            </Center>
          ) : (
            <DrawReviewOptions
              drawOptions={answerOptions}
              isTeacher
              optionsSelectable={false}
            />
          )
        ) : (
          <AwardedDrawings
            options={answerOptions}
            teacherVote={
              drawingVariantData?.coop_draw?.teacher_vote_group_id || ""
            }
            studentVotes={
              drawingVariantData?.coop_draw?.popular_vote_group_ids || []
            }
          />
        );
      default:
        return <></>;
    }
  };

  const imageLabels: Label[] = useMemo(() => {
    const zones = dragsortVariantData.coop_dragsort?.zones || [];
    const dragsortItems = dragsortVariantData.coop_dragsort?.items || [];
    return zones.map((zone) => {
      // get the correct answer for this zone and its index
      const targetItemIndex = dragsortItems.findIndex(
        (opt) => opt.text === zone.label
      );

      return {
        ...zone,
        isCorrect: true,
        number: targetItemIndex + 1,
      };
    });
  }, [
    dragsortVariantData.coop_dragsort?.zones,
    dragsortVariantData.coop_dragsort?.items,
  ]);

  const {
    isOpen: isFeedbackOpen,
    onOpen: onFeedbackOpen,
    onClose: onFeedbackClose,
  } = useDisclosure();
  const showFeedbackButton =
    !isRichTextEmpty(rich_feedback?.text) || !!rich_feedback?.image_url;

  let roundReviewSplashLifetimeMs = undefined;
  if (gameState?.game_type === SessionGameType.QuickPlay) {
    // Quick Play uses a shorter 4s interstitial than the default 5s (for teachers)
    roundReviewSplashLifetimeMs = 4000;
    if (isDrawVotingAwardsRound) {
      roundReviewSplashLifetimeMs = 0; // Skip splash screen in Quick Play drawing awards
    }
  }

  return (
    <>
      <RoundReviewAnimatedTransition
        splashScene={<SplashScene splashType={SplashType.TeacherReview} />}
        splashLifetimeMs={roundReviewSplashLifetimeMs}
      >
        <QuestionLayout
          outerGame={gameState?.game_type}
          // todo animations vv?
          questionComponent={
            <QuestionBox
              prompt={getQuestionPrompt(
                practice_set_session_item.question_type,
                practice_set_session_item
              )}
              imageAltText={practice_set_session_item.image_alt_text}
              imageUrl={practice_set_session_item.image_url}
              instructions={instructions}
              videoUrl={practice_set_session_item.video_url}
              audioUrl={practice_set_session_item.audio_url}
              imageLabels={imageLabels}
            />
          }
          answerOptionsComponent={
            <Box w="full" h="full">
              {/* todo animations here   */}
              {getReviewOptions()}
            </Box>
          }
          isDragsortItem={
            variant === PracticeSessionItemVariantType.CoopDragsort
          }
          confirmAreaComponent={
            <RoundReviewButtons
              showFeedbackButton={showFeedbackButton}
              onFeedbackClick={onFeedbackOpen}
              onConfirmButtonClick={handleConfirmButtonClick}
              isTeacher
              isClassSession
              isConfirmLoading={sceneChangeSubmit.isPending}
              isDrawItem={isInitialDrawingReview && !noValidImageUrls}
            />
          }
        />
      </RoundReviewAnimatedTransition>

      <FeedbackFlyout
        isOpen={isFeedbackOpen}
        onClose={onFeedbackClose}
        imageUrl={rich_feedback?.image_url}
        imageAltText={rich_feedback?.image_alt_text}
        text={rich_feedback?.text}
      />
    </>
  );
};
