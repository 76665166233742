import { Box, Flex, Text, usePrefersReducedMotion } from "@chakra-ui/react";
import lottie, { AnimationItem } from "lottie-web/build/player/lottie_light";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { animated, config, useSpring } from "react-spring";

import { pxToRem } from "adminComponents/utils";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { StudentSessionSoundEffect } from "sessionComponents/types";
import { useSessionAudio } from "sharedComponents/contexts/sessionAudio";

import animationData from "./resource/care_package_open.json";

const animationDataString = JSON.stringify(animationData);

const AnimatedBox = animated(Box);

export const QuickPlayCarePackageRoundInterstitial: React.FC = () => {
  const { t } = useTranslation("session", {
    keyPrefix: "quickPlay.carePackageRoundInterstitial",
    useSuspense: false,
  });
  const { match: currentBreakpoints, fontSize3x } = useBreakpoints();

  const playerRef = useRef<HTMLDivElement | null>(null);
  const animRef = useRef<AnimationItem | null>(null);
  const { play: playAudio } = useSessionAudio();
  const reduceMotion = usePrefersReducedMotion();

  const splashSpring = useSpring({
    from: {
      opacity: 0,
      scale: reduceMotion ? 1 : 0,
    },
    to: {
      opacity: 1,
      scale: 1,
    },
    config: {
      ...config.gentle,
      friction: 20,
    },
  });

  const textSpring = useSpring({
    from: {
      opacity: 0,
      scale: reduceMotion ? 1 : 0,
    },
    to: {
      opacity: 1,
      scale: 1,
    },
    config: {
      ...config.gentle,
      friction: 20,
    },
    delay: 4150,
  });

  useEffect(() => {
    if (!playerRef.current) {
      return;
    }

    animRef.current = lottie.loadAnimation({
      container: playerRef.current,
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: JSON.parse(animationDataString),
      rendererSettings: {
        progressiveLoad: false,
        preserveAspectRatio: "xMidYMax slice",
      },
    });

    const play = () => {
      animRef.current?.play();
      playAudio(StudentSessionSoundEffect.QuickPlayCarePackageRound);
    };

    animRef.current.addEventListener("DOMLoaded", play);

    return () => {
      animRef.current?.removeEventListener("DOMLoaded", play);
      animRef.current?.stop();
      animRef.current?.destroy();
      lottie.stop();
      lottie.destroy();
    };
  }, [playAudio]);

  return (
    <Box
      w="full"
      h="full"
      bgColor="quickPlay.background"
      display="flex"
      flexDir="column"
      alignItems="center"
      alignContent="center"
      justifyContent="center"
      justifyItems="center"
    >
      <AnimatedBox
        style={{
          opacity: splashSpring.opacity,
          scale: splashSpring.scale,
          width: "full",
          height: "full",
        }}
        zIndex="20"
      >
        <Box
          position="fixed"
          w="full"
          top="50%"
          left="50%"
          transform="translate(-50%,-50%)"
          ref={playerRef}
        />
      </AnimatedBox>
      <AnimatedBox
        style={{ opacity: textSpring.opacity, scale: textSpring.scale }}
        w="60%"
        position="fixed"
        zIndex="21"
      >
        <Flex
          flexDir="column"
          w="full"
          alignContent="center"
          alignItems="center"
          textAlign="center"
          justifyContent="center"
          justifyItems="center"
          gap={pxToRem(currentBreakpoints.margin * 2)}
          color="primary.warm-black"
        >
          <Text textStyle="gameDisplay" fontSize={pxToRem(fontSize3x)}>
            {t("carePackageRound")}
          </Text>
          <Text
            textStyle="gameDisplay"
            fontSize={pxToRem(currentBreakpoints.fontSize * 1.5)}
          >
            {t("instructions")}
          </Text>
        </Flex>
      </AnimatedBox>
    </Box>
  );
};
