import { Flex } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import ExamplePear from "adminComponents/assets/ExamplePearCardBackground.png";
import { Button } from "adminComponents/atoms/Button";
import { Card } from "adminComponents/atoms/Card";
import { Heading } from "adminComponents/atoms/Heading";
import { pxToRem } from "adminComponents/utils";

interface IProps {
  handleOpenModal: () => void;
  handleDismiss: () => void;
}

export const WelcomeCard: React.FC<IProps> = ({
  handleDismiss,
  handleOpenModal,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  return (
    <Card
      variant="adminCardSmallBorder"
      backgroundColor="utility.badge-green"
      borderColor="primary.tan"
      backgroundImage={ExamplePear}
      backgroundSize="cover"
      backgroundPosition="bottom left"
      padding={{ base: "admin.cards.xs", md: "admin.cards.medium" }}
    >
      <Flex direction="column" align="center" gap={pxToRem(16)}>
        <Heading variant="adminH3" as="p">
          {t("welcomeCard.header")}
        </Heading>
        <Button variant="adminButtonFilled" size="md" onClick={handleOpenModal}>
          {t("welcomeCard.modalButton")}
        </Button>
        <Button variant="adminTextButtonMedium" onClick={handleDismiss}>
          {t("welcomeCard.dismissButton")}
        </Button>
      </Flex>
    </Card>
  );
};
