import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { IClassroom } from "../../types";

export interface IArchiveClassroomArgs {
  id: string;
}

export interface IArchiveClassroomResponse {
  classroom: IClassroom;
}

export interface IUseArchiveClassroomProps {
  onSuccess?: (data: IArchiveClassroomResponse) => void;
  onError?: () => void;
  onSettled?: () => void;
}

interface IArchiveGroupResponse {
  group: IClassroom;
}

/**
 * Archives a classroom
 * @returns
 */
export default function useArchiveClassroom({
  onError,
  onSettled,
  onSuccess,
}: IUseArchiveClassroomProps): UseMutationResult<
  IArchiveClassroomResponse,
  unknown,
  IArchiveClassroomArgs
> {
  const axios = useAxios();
  const queryClient = useQueryClient();

  const archiveClassroom = async (args: IArchiveClassroomArgs) => {
    const { id } = args;

    const response = await axios({
      method: id ? "put" : "post",
      url: `/v1/groups${id ? "/" + id : ""}/archive`,
    })
      .then((r) => {
        const data = r.data as IArchiveGroupResponse;
        return { data: { classroom: data.group } };
      })
      .then((r) => r.data as IArchiveClassroomResponse);

    queryClient.invalidateQueries("classrooms", { exact: false });

    return response;
  };

  const mutation = useMutation(archiveClassroom, {
    onError,
    onSettled,
    onSuccess,
  });

  return mutation;
}
