import { AxiosError } from "axios";
import { UseMutationResult, useMutation, useQueryClient } from "react-query";
import { string } from "yup";

import { useAxios } from "../../hooks/useAxios";
import { IUser, UsersGroupsRole } from "../../types";

const emailSchema = string().email();

export interface IUseMutateClassroomUsersArgs {
  onSuccess?: () => void;
  onBadRequest?: (e: Error) => void;
  onError?: (e: AxiosError) => void;
}

export interface IMutateClassroomUsersArgs {
  classroomId: string;
  users: Array<IUser>;
  disableNotifications?: boolean;
  usersGroupsRole: UsersGroupsRole;
}

export interface IMutateClassroomUsersResponse {
  classroomId: string;
  users: Array<IUser>;
}

interface IMutateGroupResponse {
  groupId: string;
  users: Array<IUser>;
}

/**
 * Add users to a classroom
 * @returns
 */
export default function useMutateClassroomUsers({
  onSuccess,
  onBadRequest,
  onError,
}: IUseMutateClassroomUsersArgs): UseMutationResult<
  IMutateClassroomUsersResponse | void,
  unknown,
  IMutateClassroomUsersArgs
> {
  const axios = useAxios();
  const queryClient = useQueryClient();

  const mutateClassroomUsers = async (args: IMutateClassroomUsersArgs) => {
    const {
      classroomId,
      users,
      usersGroupsRole,
      disableNotifications = false,
    } = args;

    const validUsers = users.filter(
      (u) => u.email !== "" && emailSchema.isValidSync(u.email)
    );
    if (validUsers.length !== users.length) {
      console.error("Attempting to add users with invalid emails");
    }

    const response = await axios({
      method: "post",
      url: `/v1/groups/${classroomId}/users`,
      data: {
        group_id: classroomId,
        users: validUsers,
        disable_notifications: disableNotifications,
        users_groups_role: usersGroupsRole,
      },
    })
      .then((r) => {
        const data = r.data as IMutateGroupResponse;
        return { data: { classroomId: data.groupId, users: data.users } };
      })
      .then((r) => r.data as IMutateClassroomUsersResponse)
      .catch((err: AxiosError) => {
        if (onBadRequest && err.response?.status === 400) {
          onBadRequest(
            new Error((err.response?.data as { message: string }).message)
          );
        } else {
          onError?.(err);
        }

        throw err;
      });

    queryClient.invalidateQueries(["classrooms", { id: classroomId }]);
    queryClient.invalidateQueries(["classroom-users", { id: classroomId }], {
      exact: false,
    });
    queryClient.invalidateQueries("onboarding-checklist");

    return response;
  };

  const mutation = useMutation(mutateClassroomUsers, { onSuccess, onError });

  return mutation;
}
