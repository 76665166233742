// goes in the bottom footer area of the game
import { Box, HStack } from "@chakra-ui/react";
import { DebouncedFunc, throttle } from "lodash";
import React, { useRef } from "react";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { AnalyticsEvent, SessionReaction } from "links/lib/types";
import { StudentReactionButton } from "sessionComponents/atoms/StudentReactionButton";
import FireSrc from "sessionComponents/atoms/StudentReactionsDisplay/resource/fire.svg";
import HeartSrc from "sessionComponents/atoms/StudentReactionsDisplay/resource/heart.svg";
import SparklesSrc from "sessionComponents/atoms/StudentReactionsDisplay/resource/sparkles.svg";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { useStudentReactions } from "sessionComponents/contexts/studentReactions";
import { useSessionAnalytics } from "sharedComponents/contexts/sessionAnalytics";

const reactionOptions = [
  { name: SessionReaction.Heart, src: HeartSrc },
  { name: SessionReaction.Fire, src: FireSrc },
  { name: SessionReaction.Clap, src: SparklesSrc },
];

export const StudentReactionsPicker: React.FC = () => {
  const {
    match: { margin },
  } = useBreakpoints();
  const { sendReaction } = useStudentReactions();
  const { track } = useSessionAnalytics();

  const attemptReaction = useRef<
    DebouncedFunc<(reaction: SessionReaction) => void>
  >(
    throttle(
      (reaction: SessionReaction) => {
        sendReaction(reaction);
        track(AnalyticsEvent.Session_Common_SendReaction, {
          reaction,
        });
      },
      500,
      { leading: true, trailing: true }
    )
  );

  return (
    <Box
      position="absolute"
      bottom="0"
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      marginBottom={pxToRem(margin)}
      zIndex={100}
    >
      <HStack spacing={pxToRem(margin)}>
        {reactionOptions.map((option) => (
          <StudentReactionButton
            key={option.name}
            src={option.src}
            name={option.name}
            onClick={attemptReaction.current}
          />
        ))}
      </HStack>
    </Box>
  );
};
