import { DebouncedFunc, throttle } from "lodash";
import { useRef } from "react";

import { isUserTypingInterval } from "links/lib/constants";
import { useSession } from "links/lib/contexts/session";
import { useSessionActions } from "links/lib/contexts/sessionActions";
import { useSessionRoundState } from "links/lib/contexts/sessionRoundState";
import {
  AnalyticsEvent,
  IUseRoundActionsResult,
  SessionType,
} from "links/lib/types";
import { StudentSessionSoundEffect } from "sessionComponents/types";
import { useSessionAnalytics } from "sharedComponents/contexts/sessionAnalytics";
import { useSessionAudio } from "sharedComponents/contexts/sessionAudio";

import { useVibrate } from "./useVibrate";

export const useRoundActions = (): IUseRoundActionsResult => {
  const session = useSession();
  const {
    setChoice,
    confirmAnswer,
    sendTextResponse,
    sendActiveTyping,
    setVote,
    undoDrawStroke,
    extendRoundTime,
  } = useSessionActions();
  const roundState = useSessionRoundState();
  const { round_number, variant } = roundState;

  const { track } = useSessionAnalytics();
  const { play: playAudio } = useSessionAudio();
  const { vibrate } = useVibrate();

  const isClassSession = session?.session_type === SessionType.Class;

  const onActiveTyping = useRef<DebouncedFunc<() => void>>(
    throttle(
      () => {
        sendActiveTyping();
      },
      isUserTypingInterval,
      { leading: true, trailing: false }
    )
  );

  const onChoiceSelect = (choiceId: string, isSelected: boolean) => {
    setChoice(choiceId, isSelected);

    if (isClassSession) {
      playAudio(StudentSessionSoundEffect.ChoiceSelected);
    }

    vibrate(30);

    track(AnalyticsEvent.Session_Round_Common_SubmitChoice, {
      choice_id: choiceId,
      is_selected: isSelected,
    });
  };

  const onConfirm = () => {
    confirmAnswer();

    if (isClassSession) {
      playAudio(StudentSessionSoundEffect.Confirmed);
    }

    vibrate(50);

    track(AnalyticsEvent.Session_Round_Common_ConfirmAnswer, {
      variant,
    });
  };

  const onTextResponse = (response: string) => {
    sendTextResponse(response);

    if (isClassSession) {
      playAudio(StudentSessionSoundEffect.ChoiceSelected);

      vibrate(30);
    }

    track(AnalyticsEvent.Session_Round_Common_SubmitTextResponse, {
      variant,
      session_id: session?.id,
      round_number,
    });
  };

  const onDrawingVoteSubmit = (group_id: string) => {
    setVote(group_id);

    track(AnalyticsEvent.Session_Round_Draw_ConfirmVote, {
      group_id,
    });

    playAudio(StudentSessionSoundEffect.Confirmed);
  };

  const onUndoDrawStroke = () => {
    undoDrawStroke();
  };

  const onExtendRoundTime = () => {
    extendRoundTime();
  };

  return {
    onChoiceSelect,
    onConfirm,
    onTextResponse,
    onActiveTyping: onActiveTyping.current,
    onDrawingVoteSubmit,
    onUndoDrawStroke,
    onExtendRoundTime,
  };
};
