import { Box } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { Text } from "adminComponents/atoms/Text";
import { TextLink } from "adminComponents/atoms/TextLink";
import {
  ConfirmModal,
  IConfirmModalProps,
} from "adminComponents/molecules/ConfirmModal";
import { pxToRem } from "adminComponents/utils/pxToRem";
import {
  IClassroom,
  IPracticeSet,
  PracticeSetAvailability,
} from "links/lib/types";

interface IProps {
  handleClose: () => void;
  handleDelete: (practiceSet: IPracticeSet) => void;
  isDisabled?: boolean;
  isLoading?: IConfirmModalProps["isLoading"];
  isOpen: IConfirmModalProps["isOpen"];
  practiceSet?: IPracticeSet;
  assignedClassrooms: IClassroom[];
}

export const PracticeSetDeleteModal: React.FC<IProps> = ({
  handleClose,
  handleDelete,
  practiceSet,
  assignedClassrooms,
  ...props
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const history = useHistory();

  const handleConfirmModalClose = (confirm?: boolean) => {
    if (assignedClassroomsBlockedDelete) {
      confirm ? history.push("/t/classrooms") : handleClose?.();
    } else {
      if (!practiceSet) return;
      confirm ? handleDelete(practiceSet) : handleClose?.();
    }
  };

  const assignedClassroomsBlockedDelete = !!assignedClassrooms.length;
  const multipleAssignedClassrooms = assignedClassrooms.length > 1;

  let descriptionCopy = t("practiceSetDeleteModal.descriptionPrivateSet");
  switch (practiceSet?.availability) {
    case PracticeSetAvailability.Private:
      if (
        practiceSet?.copied_from_set_id &&
        practiceSet?.copied_from_set_id !== "0"
      ) {
        descriptionCopy = t(
          "practiceSetDeleteModal.descriptionPrivateCopiedSet"
        );
      }
      break;
    case PracticeSetAvailability.Domain:
      descriptionCopy = t("practiceSetDeleteModal.descriptionDomainSet");
      break;
    case PracticeSetAvailability.Public:
      descriptionCopy = t("practiceSetDeleteModal.descriptionPublicSet");
      break;
  }

  const buttonOKLabel = assignedClassroomsBlockedDelete
    ? t("practiceSetDeleteModal.goToClassroomsButton")
    : t("practiceSetDeleteModal.deleteButton");
  const title = assignedClassroomsBlockedDelete
    ? t("practiceSetDeleteModal.titleDeleteBlocked")
    : t("practiceSetDeleteModal.title");
  let description: string | JSX.Element = descriptionCopy;
  if (assignedClassroomsBlockedDelete) {
    description = (
      <>
        <Text
          display="inline"
          color="primary.dark-gray"
          mt={pxToRem(8)}
          variant="adminP1"
        >
          {t("practiceSetDeleteModal.descriptionDeleteBlockedPrefix")}
        </Text>
        {assignedClassrooms.map((classroom, i) => {
          return (
            <Box display="inline" key={classroom.id}>
              {multipleAssignedClassrooms &&
                i + 1 === assignedClassrooms.length && <>&nbsp;&&nbsp;</>}
              <TextLink
                key={classroom.id}
                navigationLink={{
                  label: classroom.name,
                  to: `/t/classrooms/${classroom.id}/assignments`,
                }}
                size="lg"
              >
                {classroom.name}
              </TextLink>
              {multipleAssignedClassrooms &&
                i + 1 < assignedClassrooms.length && <>,&nbsp;</>}
            </Box>
          );
        })}
        <Text
          display="inline"
          color="primary.dark-gray"
          mt={pxToRem(8)}
          variant="adminP1"
        >
          {multipleAssignedClassrooms
            ? t("practiceSetDeleteModal.descriptionDeleteBlockedSuffixPlural")
            : t(
                "practiceSetDeleteModal.descriptionDeleteBlockedSuffixSingular"
              )}
        </Text>
      </>
    );
  }

  return (
    <ConfirmModal
      {...props}
      buttonCancelLabel={t("practiceSetDeleteModal.cancelButton")}
      buttonOKLabel={buttonOKLabel}
      handleClose={handleConfirmModalClose}
      mobileButtonsDirection="vertical"
      size="2xl"
      description={description}
      title={title}
      variant="adminModalCompactMobile"
    />
  );
};
