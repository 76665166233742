import { Box, Tooltip, useMultiStyleConfig } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { ClampedHeading } from "adminComponents/atoms/ClampedHeading";
import { generateStudentName } from "adminComponents/utils";
import { useShortName } from "links/lib/hooks/useShortName";
import { IUser } from "links/lib/types";

interface SessionGroupStudentProps {
  student?: IUser;
  studentNumber?: number;
  showErrors?: boolean;
}

export const SessionGroupStudent: React.FC<SessionGroupStudentProps> = ({
  student,
  studentNumber,
  showErrors,
}) => {
  const styles = useMultiStyleConfig("AdminSessionGroupsModal", {
    studentNumber,
    showErrors,
  });
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });

  const { primary } = generateStudentName({ ...student });
  const studentShortName = useShortName(primary, false);

  return (
    <Tooltip
      isDisabled={!showErrors || (studentNumber ?? 3) > 2}
      label={t("sessionGroupsModal.studentGroupError")}
      variant="adminDark"
      placement="top"
      hasArrow
    >
      <Box sx={styles.student}>
        <ClampedHeading
          headingStyles={styles.clampedText}
          noOfLines={1}
          headingVariant="adminH7"
          headingAs="p"
        >
          {student
            ? studentShortName || ""
            : t("sessionGroupsModal.student", { number: studentNumber })}
        </ClampedHeading>
      </Box>
    </Tooltip>
  );
};
