import { Flex, Image, Spacer, useBreakpointValue } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Text } from "adminComponents/atoms/Text";
import { TemplateWithVerticalCenteredContent } from "adminComponents/templates/TemplateWithVerticalCenteredContent";
import { pxToRem } from "adminComponents/utils";

import gearsImage from "./resource/Gears.svg";

interface IProps {
  handleGoToHomePage: () => void;
}

export const NotFound: React.FC<IProps> = ({ handleGoToHomePage }) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const useSmallerPortraitHeader = useBreakpointValue({
    base: true,
    sm: false,
  });

  return (
    <TemplateWithVerticalCenteredContent>
      {isMobile && (
        <Flex
          alignItems="center"
          direction="column"
          h="100vh"
          paddingBottom={pxToRem(40)}
          paddingX={[pxToRem(20), pxToRem(40)]}
        >
          <Spacer flex="0.4" />
          <Image
            src={gearsImage}
            title={t("notFoundScreen.gearsAltText")}
            marginBottom={pxToRem(40)}
            h="16%"
          />
          <Text align="center" color="utility.link" variant="adminH1">
            404
          </Text>
          <Text
            as="h3"
            align="center"
            variant={useSmallerPortraitHeader ? "adminH3" : "adminH2"}
          >
            {t("notFoundScreen.title")}
          </Text>
          <Text
            variant="adminP1"
            align="center"
            marginTop={[pxToRem(10), pxToRem(40)]}
          >
            {t("notFoundScreen.description")}
          </Text>
          <Spacer flex="0.5" />
          <Button
            w="full"
            variant="adminButtonFilled"
            onClick={() => handleGoToHomePage()}
          >
            {t("notFoundScreen.buttonGoToHomePage")}
          </Button>
        </Flex>
      )}
      {!isMobile && (
        <Flex
          alignItems="center"
          direction="row"
          w="full"
          h="100vh"
          justifyContent="center"
        >
          <Image
            src={gearsImage}
            title={t("notFoundScreen.gearsAltText")}
            h="35%"
            maxH="35%"
          />
          <Spacer flex="0.5" />
          <Flex
            align="center"
            direction="column"
            justify="center"
            maxW={pxToRem(700)}
            h="full"
            paddingBottom={pxToRem(40)}
          >
            <Spacer flex="0.2" />
            <Flex direction="column" alignSelf="flex-start">
              <Text
                alignSelf="flex-start"
                align="left"
                color="utility.link"
                variant="adminH1"
              >
                404
              </Text>
              <Text alignSelf="flex-start" align="left" variant="adminH3">
                {t("notFoundScreen.title")}
              </Text>
              <Text variant="adminP1" align="left" marginTop={pxToRem(40)}>
                {t("notFoundScreen.description")}
              </Text>
            </Flex>
            <Spacer flex="0.4" />
            <Button
              w="full"
              marginTop={pxToRem(40)}
              variant="adminButtonFilled"
              onClick={() => handleGoToHomePage()}
            >
              {t("notFoundScreen.buttonGoToHomePage")}
            </Button>
          </Flex>
        </Flex>
      )}
    </TemplateWithVerticalCenteredContent>
  );
};
