import { Flex, SimpleGrid } from "@chakra-ui/react";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Heading } from "adminComponents/atoms/Heading";
import { Icon } from "adminComponents/atoms/Icon";
import { LibraryPracticeSetCard } from "adminComponents/molecules/LibraryPracticeSetCard";
import { pxToRem } from "adminComponents/utils";
import { IPracticeSet } from "links/lib/types";

export interface IProps {
  newSetsFromFollowed: Array<IPracticeSet>;
}

export const NewSetsFromFollowed: React.FC<IProps> = ({
  newSetsFromFollowed,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const [newSetsFromFollowedToShow, setNewSetsFromFollowedToShow] = useState(4);
  const shownNewSetsFromFollowed = useMemo(() => {
    return newSetsFromFollowed.slice(0, newSetsFromFollowedToShow);
  }, [newSetsFromFollowed, newSetsFromFollowedToShow]);

  const handleViewMoreNewSetsFromFollowed = useCallback(() => {
    setNewSetsFromFollowedToShow(shownNewSetsFromFollowed.length + 4);
  }, [shownNewSetsFromFollowed.length]);

  if (!newSetsFromFollowed.length) {
    return <></>;
  }

  return (
    <Flex flexDir="column" gap={pxToRem(20)}>
      <Heading variant="adminH4" as="h1">
        {t("teacherHomepageImproved.newSetsFromFollowed.heading")}
      </Heading>
      <SimpleGrid columns={[1, null, null, 4]} gap={pxToRem(20)}>
        {shownNewSetsFromFollowed?.map((ps, i) => (
          <LibraryPracticeSetCard
            key={`${ps.id}-${i}`}
            showLargeAuthorName
            authorName={ps.author_name}
            authorCustomUrlCode={ps.author_custom_url_code}
            authorProfileImageUrl={ps.author_profile_image_url}
            authorProfileIsPrivate={ps.author_profile_is_private}
            authorIsInternalContentSpecialist={
              ps.author_is_internal_content_specialist
            }
            practiceSet={ps}
          />
        ))}
      </SimpleGrid>
      {newSetsFromFollowed.length > shownNewSetsFromFollowed.length && (
        <Button
          alignSelf="center"
          onClick={handleViewMoreNewSetsFromFollowed}
          rightIcon={
            <Icon
              color="currentColor"
              height={pxToRem(7)}
              icon="button_down_arrow"
              ml={pxToRem(5)}
              width={pxToRem(12)}
            />
          }
          variant="adminTextButtonLarge"
        >
          {t("common.viewMore")}
        </Button>
      )}
    </Flex>
  );
};
