import { useMemo, useState } from "react";

import {
  ISectionConfig,
  ISubSection,
  IconsByTab,
  SectionIcon,
} from "../config";

export function useSectionData(sections: { [key: string]: ISectionConfig }): {
  iconsByTab: IconsByTab;
  tabLabels: Array<string>;
  activeIcon: SectionIcon;
  activeTabIndex: number;
  activeIconIndex: number;
  showHairColorSelection?: boolean;
  showSkinToneSelection?: boolean;
  subSections: Array<ISubSection>;
  setActiveIconIndex: (n: number) => void;
  setActiveTabIndex: (n: number) => void;
} {
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [activeIconIndex, setActiveIconIndex] = useState<number>(0);

  const sectionData = useMemo(() => {
    const tabLabels = [
      ...new Set(Object.values(sections).map((section) => section.sectionKey)), // dedup
    ];

    const iconsByTab = Object.keys(sections).reduce(
      (acc: IconsByTab, id: string) => {
        const {
          sectionKey,
          config: { icon },
        } = sections[id];
        const iconObj: SectionIcon = { sectionId: id, name: icon };
        return {
          ...acc,
          [sectionKey]: acc[sectionKey]
            ? acc[sectionKey].concat(iconObj)
            : [iconObj],
        };
      },
      {}
    );

    return { iconsByTab, tabLabels };
  }, [sections]);

  const activeTabId = sectionData.tabLabels[activeTabIndex];
  const activeIcon: SectionIcon =
    sectionData.iconsByTab?.[activeTabId]?.[activeIconIndex];
  const { subSections } = sections[activeIcon.sectionId].config;
  const showSkinToneSelection =
    sections[activeIcon.sectionId].config.showSkinToneSelection;
  const showHairColorSelection =
    sections[activeIcon.sectionId].config.showHairColorSelection;

  return {
    ...sectionData,
    activeIcon,
    activeTabIndex,
    activeIconIndex,
    showHairColorSelection,
    showSkinToneSelection,
    subSections,
    setActiveIconIndex,
    setActiveTabIndex,
  };
}
