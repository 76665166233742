import { useTranslation } from "react-i18next";

import { useSessionGameState } from "links/lib/contexts/sessionGameState";
import { useSessionScene } from "links/lib/contexts/sessionScene";
import { BigBoardGameState, SessionScene } from "links/lib/types";

export const useTokenPlacementHeaderTitleText = ({
  isTeacher,
  userId,
}: {
  isTeacher?: boolean;
  userId: string;
}): string => {
  const gameState = useSessionGameState() as BigBoardGameState;
  const scene = useSessionScene();
  const { t } = useTranslation("session", {
    keyPrefix: "bigBoard",
    useSuspense: false,
  });

  if (scene !== SessionScene.OuterGame) {
    return "";
  }

  if (isTeacher) {
    return t("teacherHeaderTitle");
  } else {
    const placingUser =
      gameState?.the_big_board_state?.round_token_control_user_ids.includes(
        userId
      );

    return placingUser
      ? t("studentPlacingHeaderTitle")
      : t("studentNotPlacingHeaderTitle");
  }
};
