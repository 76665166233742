import { hoverStyles, pxToRem } from "adminComponents/utils";

export const AdminLibraryPracticeSetFolder = {
  baseStyle: {
    card: {
      padding: pxToRem(30),
      transition: "color 250ms",
      _hover: hoverStyles({
        color: "primary.golden-hover",
      }),
      _focusVisible: {
        color: "utility.focus",
        outline: "none",
      },
    },
    body: {
      gap: pxToRem(13),
    },
    icon: {
      padding: pxToRem(7),
      backgroundColor: "primary.tan",
      borderRadius: "100%",
      height: pxToRem(30),
      width: pxToRem(30),
    },
    headerContainer: {
      position: "absolute",
      right: pxToRem(14),
      top: pxToRem(14),
    },
    heading: {
      zIndex: 2,
      maxWidth: pxToRem(180),
    },
    linkOverlay: {
      _focusVisible: {
        outlineColor: "utility.focus",
      },
    },
  },
};
