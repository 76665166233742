import { hoverStyles, pxToRem } from "adminComponents/utils";

export const AdminPagination = {
  baseStyle: (): Record<string, unknown> => ({
    button: {
      position: "relative",
      width: "auto",
      height: "auto",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      outline: "none",
      boxShadow: "none",
      padding: `0 ${pxToRem(5)}`,
      margin: 0,
      "*": {
        margin: 0,
        outline: "none",
        boxShadow: "none",
      },
      "> svg": {
        strokeWidth: "2px",
        transition: "fill 250ms, stroke 250ms",
      },
      _hover: hoverStyles({
        "@media (hover: hover)": {
          "> svg": {
            fill: "utility.badge-grey",
            stroke: "utility.badge-grey",
          },
        },
      }),
      _focusVisible: {
        "> svg": {
          fill: "primary.badge-grey",
          stroke: "utility.focus",
        },
      },
      _disabled: {
        opacity: 0.4,
        pointerEvents: "none",
      },
    },
    buttonText: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translateX(-50%) translateY(-50%)",
    },
  }),
  variants: {},
};
