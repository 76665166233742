import { pxToRem } from "adminComponents/utils/pxToRem";

export const Switch = {
  variants: {
    adminSwitch: {
      thumb: {
        bg: "primary.golden",
        opacity: 0.4,
        _checked: {
          opacity: 1,
          bg: "primary.white",
        },
      },
      track: {
        borderColor: "primary.golden",
        bg: "primary.white",
        borderWidth: pxToRem(2),
        _focus: {
          boxShadow: "none",
        },
        _checked: {
          bg: "primary.golden",
        },
      },
    },
  },
};
