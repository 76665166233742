import { Box, Flex, Image, SimpleGrid } from "@chakra-ui/react";
import React from "react";

import { ColorScheme } from "adminComponents";
import { ClampedTooltip } from "adminComponents/atoms/ClampedTooltip";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { IClassifyCategory } from "links/lib/types";
import { RichTextRenderer } from "sharedComponents/atoms/RichTextRenderer";

interface IProps {
  categories: Array<IClassifyCategory>;
  handleOpenImageModal?: (imageUrl: string, imageAltText: string) => void;
  color?: ColorScheme;
}

const AnswerClassify: React.FC<IProps> = ({
  categories,
  handleOpenImageModal,
  color,
}) => {
  const isImageMode = categories.some((category) =>
    category.choices.some((choice) => !!choice.image_url)
  );

  const categoryGridColumns = isImageMode ? 1 : [1, null, 2];

  return (
    <SimpleGrid columns={categoryGridColumns} spacing={pxToRem(16)}>
      {categories.map((cat) => (
        <Box key={cat.id}>
          <Text as="div" variant="adminP2" color={color} mb={pxToRem(10)}>
            <RichTextRenderer content={cat.text} />
          </Text>
          {isImageMode && (
            <Flex w="full" flexWrap="wrap" gap={pxToRem(24)}>
              {cat.choices.map((choice) => (
                <ClampedTooltip
                  key={choice.id}
                  hasArrow
                  isDisabled={!choice.image_alt_text}
                  label={choice.image_alt_text}
                >
                  <Image
                    cursor={handleOpenImageModal ? "pointer" : undefined}
                    borderRadius="lg"
                    h="100%"
                    w={[pxToRem(72), null, pxToRem(120)]}
                    src={choice.image_url}
                    alt={choice.image_alt_text}
                    onClick={() => {
                      handleOpenImageModal?.(
                        choice.image_url || "",
                        choice.image_alt_text || ""
                      );
                    }}
                  />
                </ClampedTooltip>
              ))}
            </Flex>
          )}

          {!isImageMode &&
            cat.choices.map((choice) => (
              <Text
                as="div"
                key={choice.id}
                variant="adminP2"
                color="primary.dark-gray"
                fontStyle="italic"
              >
                <RichTextRenderer content={choice.text} />
              </Text>
            ))}
        </Box>
      ))}
    </SimpleGrid>
  );
};

export { AnswerClassify };
