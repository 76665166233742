import { Box, Flex, HStack, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { animated, config, useSpring } from "react-spring";
import { useMeasure } from "react-use";

import { AvatarStreak } from "adminComponents/molecules/AvatarStreak";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { ISessionUser } from "links/lib/types";
import { Card } from "sessionComponents/atoms/Card";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { getUserColorScheme } from "sessionComponents/utils/getUserColorScheme";

const AnimatedCard = animated(Card);

export interface IStudentCardProps {
  student: ISessionUser;
}

export const StudentCard: React.FC<IStudentCardProps> = ({ student }) => {
  const { match: currentBreakpoints } = useBreakpoints();
  const halfPadding = pxToRem(currentBreakpoints.padding / 2);
  const halfMargin = pxToRem(currentBreakpoints.margin / 2);

  const [avatarStreakRef, { width: avatarStreakWidth }] =
    useMeasure<HTMLDivElement>();

  const { t } = useTranslation("session", {
    keyPrefix: "teacherLobbyLight",
    useSuspense: false,
  });

  const styles = useSpring({
    scale: 1,
    from: { scale: 0 },
    config: config.default,
  });

  const colorScheme = getUserColorScheme(student.color_scheme);

  return (
    <AnimatedCard
      style={styles}
      w="auto"
      maxW={pxToRem(350)}
      height="auto"
      pt={0}
      px={0}
      pb={halfPadding}
    >
      <HStack
        w="full"
        h="60%"
        bgColor={colorScheme}
        // Since this inner div is slightly smaller than the parent Card, we need
        // to tweak the border radius slightly to fill in the gap
        // TODO: does this ratio work for all breakpoints?
        borderTopRadius={pxToRem(currentBreakpoints.borderRadius / 1.14)}
        gap={halfMargin}
      >
        <Box
          ref={avatarStreakRef}
          mb={pxToRem(-32)}
          pl={halfMargin}
          pt={pxToRem(currentBreakpoints.margin)}
        >
          <AvatarStreak
            size="sm"
            activeSegment={student.initial_streak_days}
            name={student.name}
            backgroundColor={colorScheme}
            src={student.profile_image_url}
            weekCount={student.initial_streak_weeks}
            showSilhouetteAsFallback
          />
        </Box>
        <Text
          marginInlineStart="0 !important"
          textStyle="gameDisplay"
          fontSize={pxToRem(currentBreakpoints.fontSize / 1.75)}
          noOfLines={3}
          wordBreak="break-word"
          pr={pxToRem(16)}
        >
          {student.name}
        </Text>
      </HStack>
      <Flex
        w="full"
        h="40%"
        alignItems="start"
        justifyContent="end"
        flexDirection="column"
        gap={pxToRem(currentBreakpoints.margin / 2)}
        px={halfPadding}
        pt={halfPadding}
      >
        <Box
          ml={pxToRem(avatarStreakWidth + currentBreakpoints.margin / 2)}
          bgColor={student.is_guest ? "primary.tan" : "utility.question-green"}
          px={pxToRem(8)}
          py={pxToRem(4)}
          borderRadius="lg"
          mt={pxToRem(-30)}
        >
          <Text textStyle="gameText">
            {student.is_guest ? t("guest") : t("signedIn")}
          </Text>
        </Box>
      </Flex>
    </AnimatedCard>
  );
};
