import { Box, Flex, Text, usePrefersReducedMotion } from "@chakra-ui/react";
import lottie, { AnimationItem } from "lottie-web/build/player/lottie_light";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  animated,
  config,
  useChain,
  useSpring,
  useSpringRef,
  useTransition,
} from "react-spring";
import { useMeasure, useUnmount } from "react-use";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { ISessionGroup } from "links/lib/types";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";

import Blob from "../resource/Blob.svg";
import EmptyStreak from "../resource/empty_streak.svg";
import progress3Data from "../resource/streak_extended.json";
import progress1Data from "../resource/streak_progress_1.json";
import progress2Data from "../resource/streak_progress_2.json";

const AnimatedBox = animated(Box);
const AnimatedText = animated(Text);

export interface IQuickPlayStudentRoundReviewInterstitialContentProps {
  group: ISessionGroup;
  isDrawItem: boolean;
  studentIsCorrect?: boolean;
}

const animationDataMap: Record<number, { data: unknown }> = {
  [1]: {
    data: progress1Data,
  },
  [2]: {
    data: progress2Data,
  },
  [3]: {
    data: progress3Data,
  },
};

// QuickPlayStudentRoundReviewInterstitial is largely similar to the parent component
// StudentRoundReviewInterstitial, but due to the large amount of streak + bonus +
// animation logic necessary for the Quick Play version, it is extracted out to this
// separate component.
export const QuickPlayStudentRoundReviewInterstitialContent: React.FC<
  IQuickPlayStudentRoundReviewInterstitialContentProps
> = ({ group, isDrawItem, studentIsCorrect }) => {
  const {
    match: currentBreakpoints,
    fontSize2x,
    fontSizeHalf,
  } = useBreakpoints();
  const { t } = useTranslation("session", {
    keyPrefix: "roundReviewInterstitial",
    useSuspense: false,
  });
  const prefersReducedMotion = usePrefersReducedMotion();

  const containerRef = useRef<HTMLDivElement | null>(null);
  const animationRef = useRef<AnimationItem | null>(null);

  const [
    streakMessageContainerRef,
    { height: streakMessageContainerRefHeight },
  ] = useMeasure<HTMLDivElement>();

  const possibleStreakMessages = useMemo(() => {
    const messages = [
      group.lost_question_streak
        ? t("quickPlay.lostTeamStreak")
        : t("quickPlay.teamStreak"),
    ];
    if (group.round_bonus_points > 0) {
      messages.push(t("quickPlay.streakReset"));
    }

    return messages;
  }, [t, group.lost_question_streak, group.round_bonus_points]);

  const [streakMessageIndex, setStreakMessageIndex] = useState(0);
  const [switchToEmptyStreak, setSwitchToEmptyStreak] = useState(false);

  const handleLoad = useCallback(
    (animationData: { data: unknown }) => {
      if (!containerRef.current) {
        return;
      }

      animationRef.current?.stop();
      animationRef.current?.destroy();
      const animationDataString = JSON.stringify(animationData.data);

      const anim = (animationRef.current = lottie.loadAnimation({
        container: containerRef.current,
        renderer: "svg",
        loop: false,
        autoplay: false,
        animationData: JSON.parse(animationDataString),
      }));

      const onComplete = () => {
        if (streakMessageIndex !== possibleStreakMessages.length - 1) {
          setStreakMessageIndex(streakMessageIndex + 1);
          setSwitchToEmptyStreak(true);
        }
      };

      anim.addEventListener("DOMLoaded", () => {
        anim.play();
      });
      anim.addEventListener("complete", onComplete);
    },
    [possibleStreakMessages, streakMessageIndex]
  );

  useUnmount(() => {
    animationRef.current?.stop();
    animationRef.current?.destroy();
    lottie.stop();
    lottie.destroy();
  });

  const pointsSpringRef = useSpringRef();
  const pointsSpring = useSpring({
    ref: pointsSpringRef,
    from: {
      opacity: 0,
      scale: prefersReducedMotion ? 1 : 0,
    },
    to: {
      opacity: 1,
      scale: 1,
    },
    config: prefersReducedMotion
      ? config.default
      : !studentIsCorrect
      ? config.slow
      : config.wobbly,
    onRest: () => {
      if (currentStreak > 0) {
        handleLoad(animationDataMap[currentStreak]);
      }
    },
  });

  let currentStreak = group.question_streak;
  if (currentStreak < 0 || currentStreak > 3) {
    console.warn(
      "Received Quick Play group question_streak outside of the valid range [0,3]",
      { question_streak: currentStreak }
    );
    currentStreak = 0;
  }
  // Upon reaching a streak of 3, the question_streak is reset to 0 and
  // "traded in" for bonus points
  if (group.round_bonus_points > 0) {
    currentStreak = 3;
  }

  if (switchToEmptyStreak) {
    currentStreak = 0;
  }

  const streakMessageContainerSpringRef = useSpringRef();
  const streakMessageContainerSpring = useSpring({
    ref: streakMessageContainerSpringRef,
    from: {
      opacity: 0,
      scale: prefersReducedMotion ? 1 : 0,
    },
    to: {
      opacity: 1,
      scale: 1,
    },
    delay: 1000,
    config: prefersReducedMotion ? config.default : config.wobbly,
  });

  const streakMessageTransitionRef = useSpringRef();
  const streakMessageTransition = useTransition(streakMessageIndex, {
    key: streakMessageIndex,
    ref: streakMessageTransitionRef,
    from: {
      opacity: 0,
      scale: prefersReducedMotion ? 1 : 0,
    },
    enter: {
      opacity: 1,
      scale: 1,
    },
    config: prefersReducedMotion ? config.default : config.wobbly,
    exitBeforeEnter: true,
  });

  const bonusPointsTransitionRef = useSpringRef();
  const bonusPointsTransition = useTransition(null, {
    ref: bonusPointsTransitionRef,
    from: {
      opacity: 0,
      scale: prefersReducedMotion ? 1 : 0,
    },
    enter: {
      opacity: 1,
      scale: 1,
    },
    leave: {
      opacity: 0,
      scale: prefersReducedMotion ? 1 : 0,
    },
    config: prefersReducedMotion ? config.default : config.wobbly,
  });

  const addBonusPointsSpringRef = useSpringRef();
  const addBonusPointsSpring = useSpring({
    ref: addBonusPointsSpringRef,
    from: {
      points: group.round_awarded_points,
    },
    to: {
      points: group.round_awarded_points + group.round_bonus_points,
    },
    config: config.slow,
  });

  useChain(
    [
      pointsSpringRef,
      streakMessageContainerSpringRef,
      streakMessageTransitionRef,
      bonusPointsTransitionRef,
      addBonusPointsSpringRef,
    ],
    [0, 0.3, 0.6, 1]
  );

  const getStreakCountText = () => {
    return (
      <Box
        textAlign="center"
        position="absolute"
        left="50%"
        top="50%"
        transform="translate(-50%,-50%)"
      >
        <Text
          textStyle="gameDisplay"
          fontSize={pxToRem(currentBreakpoints.fontSize)}
        >
          {currentStreak}/3
        </Text>
        <Text textStyle="gameDisplay" fontSize={pxToRem(fontSizeHalf)}>
          {t("quickPlay.streaks")}
        </Text>
      </Box>
    );
  };

  return (
    <>
      <AnimatedBox
        style={{
          opacity: pointsSpring.opacity,
          transform: pointsSpring.scale.to((s) => `scale(${s})`),
        }}
      >
        {!isDrawItem && (
          <Box
            padding={pxToRem(currentBreakpoints.padding)}
            backgroundImage={Blob}
            backgroundSize="100% 100%"
            backgroundRepeat="no-repeat"
            position="relative"
          >
            <Flex flexDirection="column" alignItems="center">
              <Box>
                <Text
                  display="inline"
                  textColor="primary.white"
                  textStyle="gameDisplay"
                  lineHeight="100%"
                  fontSize={pxToRem(fontSize2x)}
                >
                  +
                </Text>
                <AnimatedText
                  display="inline"
                  textColor="primary.white"
                  textStyle="gameDisplay"
                  lineHeight="100%"
                  fontSize={pxToRem(fontSize2x)}
                >
                  {addBonusPointsSpring.points.to((val) => Math.floor(val))}
                </AnimatedText>
              </Box>
              <Text
                textColor="primary.white"
                textStyle="gameDisplay"
                lineHeight="100%"
                fontSize={pxToRem(currentBreakpoints.fontSize)}
              >
                {t("pointsTitle")}
              </Text>
            </Flex>
            {group.round_bonus_points > 0 &&
              bonusPointsTransition((style) => (
                <AnimatedBox
                  style={{
                    opacity: style.opacity,
                    transform: style.scale.to((s) => `scale(${s})`),
                  }}
                  position="absolute"
                  top="0"
                  left={pxToRem(currentBreakpoints.margin * 5)}
                >
                  <Box
                    bgColor="primary.white"
                    borderRadius="full"
                    boxShadow={`0 ${pxToRem(4)} ${pxToRem(4)} 0 #0000001A`}
                    px={pxToRem(currentBreakpoints.padding / 3)}
                    py={pxToRem(currentBreakpoints.padding / 2)}
                    w="fit-content"
                  >
                    <Text
                      textStyle="gameDisplay"
                      fontSize={pxToRem(currentBreakpoints.fontSize / 1.5)}
                    >
                      +{group.round_bonus_points}pts
                    </Text>
                  </Box>
                </AnimatedBox>
              ))}
          </Box>
        )}
      </AnimatedBox>
      {(group.question_streak > 0 ||
        group.round_bonus_points > 0 ||
        group.lost_question_streak) && (
        <AnimatedBox
          w="85%"
          style={{
            opacity: streakMessageContainerSpring.opacity,
            transform: streakMessageContainerSpring.scale.to(
              (s) => `scale(${s})`
            ),
          }}
        >
          <Box
            ref={streakMessageContainerRef}
            display="flex"
            flexDir="column"
            alignItems="center"
            position="relative"
            bgColor={
              group.lost_question_streak ? "utility.link" : "tomato.base"
            }
            borderRadius="full"
            p={pxToRem(currentBreakpoints.padding)}
            willChange="opacity"
          >
            {/* Streak animation */}
            {(group.question_streak > 0 || group.round_bonus_points > 0) &&
              !switchToEmptyStreak && (
                <Box
                  boxSize={pxToRem(
                    (streakMessageContainerRefHeight +
                      currentBreakpoints.padding * 2) *
                      1.8
                  )}
                  position="absolute"
                  top="0"
                  left="0"
                  transform="translate(-40%,-22%)"
                >
                  <Box ref={containerRef} w="full" h="full"></Box>
                  {getStreakCountText()}
                </Box>
              )}
            {(group.lost_question_streak || switchToEmptyStreak) && (
              <Box
                bgImage={EmptyStreak}
                backgroundSize="100% 100%"
                backgroundRepeat="no-repeat"
                boxSize={pxToRem(
                  (streakMessageContainerRefHeight +
                    currentBreakpoints.padding * 2) *
                    1.3
                )}
                position="absolute"
                top="0"
                left="0"
                transform={
                  switchToEmptyStreak
                    ? "translate(-35%,-10%)"
                    : "translate(-15%,-10%)"
                }
              >
                {getStreakCountText()}
              </Box>
            )}
            {streakMessageTransition((style, i) => (
              <AnimatedBox
                style={{
                  opacity: style.opacity,
                  transform:
                    i !== possibleStreakMessages.length - 1
                      ? style.scale.to((s) => `scale(${s})`)
                      : undefined,
                }}
                textAlign="center"
                ml={pxToRem(50)}
              >
                <Text
                  color="primary.white"
                  textStyle="gameDisplay"
                  fontSize={pxToRem(currentBreakpoints.fontSize)}
                >
                  {possibleStreakMessages[i]}
                </Text>
                {i === 0 && !group.lost_question_streak && (
                  <Text color="primary.white" textStyle="gameDisplay">
                    {t("quickPlay.correctAnswer", {
                      count: currentStreak,
                    })}
                  </Text>
                )}
              </AnimatedBox>
            ))}
          </Box>
        </AnimatedBox>
      )}
      {/* No existing streak, no streak gained case */}
      {currentStreak === 0 &&
        !group.lost_question_streak &&
        group.round_bonus_points === 0 && (
          <AnimatedBox
            w="full"
            style={{
              opacity: streakMessageContainerSpring.opacity,
              transform: streakMessageContainerSpring.scale.to(
                (s) => `scale(${s})`
              ),
            }}
          >
            <Box
              bgImage={EmptyStreak}
              backgroundSize="100% 100%"
              backgroundRepeat="no-repeat"
              boxSize={pxToRem(
                (streakMessageContainerRefHeight +
                  currentBreakpoints.padding * 2) *
                  2
              )}
              position="relative"
              mx="auto"
            >
              {getStreakCountText()}
            </Box>
          </AnimatedBox>
        )}
    </>
  );
};
