import { Box } from "@chakra-ui/react";
import escapeHtml from "escape-html";
import React, { useMemo } from "react";
import { Text } from "slate";

import { pxToRem } from "adminComponents/utils";
import { renderLatexString } from "lib/latexUtils";
import { convertToSlate } from "lib/slateUtils";
import { RichText } from "links/lib/types";

export interface IRichTextRendererProps {
  content: RichText;
  containerStyle?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
}

const serialize = (node: Record<string, unknown>) => {
  if (Text.isText(node)) {
    let string = escapeHtml(node.text);
    if (node.bold) {
      string = `<strong>${string}</strong>`;
    }
    if (node.italic) {
      string = `<em>${string}</em>`;
    }
    if (node.strikethrough) {
      string = `<span style="text-decoration:line-through;">${string}</span>`;
    }
    if (node.subscript) {
      string = `<span style="vertical-align:sub;font-size:0.6em">${string}</span>`;
    }
    if (node.superscript) {
      string = `<span style="vertical-align:super;font-size:0.6em">${string}</span>`;
    }
    if (node.underline) {
      string = `<span style="text-decoration:underline;">${string}</span>`;
    }
    return string;
  }

  if (!("type" in node)) return;

  const children: string =
    ("children" in node &&
      Array.isArray(node.children) &&
      node.children.map((n) => serialize(n)).join("")) ||
    "";

  switch (node.type) {
    case "paragraph":
      // force empty paragraphs to render at full line height by rendering space
      return `<p>${children.trim() === "" ? "&nbsp;" : children}</p>`;
    case "latex":
      return (
        "content" in node &&
        typeof node.content === "string" &&
        `<span data-latex>${renderLatexString("\\sf " + node.content)}</span>`
      );
    default:
      return children;
  }
};

export const RichTextRenderer: React.FC<IRichTextRendererProps> = ({
  content,
  containerStyle,
  contentStyle,
}) => {
  const html = useMemo(() => {
    const contentState = convertToSlate(content) as Array<
      Record<string, unknown>
    >;
    return contentState.map((c) => serialize(c)).join("");
  }, [content]);

  return (
    <Box
      w="full"
      sx={{
        p: {
          minHeight: pxToRem(8),
          ...contentStyle,
          "& .katex": {
            fontSize: "1em",
          },
        },
        // Force scrollbar to show on overflowing spans (math content)
        span: {
          "&[data-latex]": {
            display: "inline-flex",
            whiteSpace: "nowrap",
            maxWidth: "100%",
            overflowX: "auto",
            overflowY: "hidden",
            verticalAlign: "middle",
            padding: pxToRem(2),
          },

          "&[data-latex]::-webkit-scrollbar": {
            WebkitAppearance: "none",
            width: pxToRem(10),
            height: pxToRem(10),
            backgroundColor: "primary.light-gray",
          },

          "&[data-latex]::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(0, 0, 0, .3)",
          },
        },
      }}
      dangerouslySetInnerHTML={{ __html: html }}
      style={containerStyle}
    />
  );
};
