import { Box } from "@chakra-ui/react";
import React, { useState } from "react";
import { animated, config, useTransition } from "react-spring";
import { useInterval } from "react-use";

import { AvatarAnimations } from "links/lib/constants";
import { SessionGameType } from "links/lib/types";
import { AnimatedConfetti } from "sessionComponents/atoms/AnimatedConfetti";
import { WinningTeamScoreBoard } from "sessionComponents/atoms/WinningTeamScoreBoard";
import { AvatarCarousel } from "sessionComponents/molecules/AvatarCarousel";
import { LobbyLayout } from "sessionComponents/molecules/LobbyLayout";
import { useDetectOrientation } from "sessionComponents/theme/hooks/useDetectOrientation";
import { WinningTeam } from "sessionComponents/types";

const AnimatedBox = animated(Box);

interface IWinningTeamCarouselProps {
  winningTeams: WinningTeam[];
  footer?: JSX.Element;
  userId: string;
  showTrophy: boolean;
  showPlus: boolean;
  showNameFirst: boolean;
  outerGame?: SessionGameType;
  handleComplete?: () => void;
}

const loopingVictoryAnimations = AvatarAnimations.Victory.filter((a) => a.loop);

export const WinningTeamCarousel: React.FC<IWinningTeamCarouselProps> = ({
  winningTeams,
  footer,
  userId,
  showTrophy,
  showPlus,
  showNameFirst,
  outerGame = SessionGameType.TheBigBoard,
  handleComplete,
}) => {
  const { isPortrait } = useDetectOrientation();

  const [currentWinnerIndex, setCurrentWinnerIndex] = useState(0);
  useInterval(() => {
    if (currentWinnerIndex < winningTeams.length - 1) {
      setCurrentWinnerIndex(currentWinnerIndex + 1);
    } else {
      handleComplete?.();
    }
  }, 5000);

  const avatarTransition = useTransition(
    currentWinnerIndex < winningTeams.length
      ? [winningTeams[currentWinnerIndex]]
      : [],
    {
      from: {
        x: 0,
        opacity: 1,
      },
      enter: {
        x: 0,
        opacity: 1,
      },
      leave: {
        x: -200,
        opacity: 0,
      },
      keys: ({ key }) => key,
      config: config.gentle,
      exitBeforeEnter: true,
    }
  );

  const scoreBoardTransition = useTransition(
    currentWinnerIndex < winningTeams.length
      ? [winningTeams[currentWinnerIndex]]
      : [],
    {
      from: {
        y: -200,
        opacity: 1,
      },
      enter: {
        y: 0,
        opacity: 1,
      },
      leave: {
        y: -200,
        opacity: 0,
      },
      keys: ({ key }) => key,
      config: config.gentle,
      exitBeforeEnter: true,
    }
  );

  return (
    <Box w="full" h="full">
      <AnimatedConfetti />
      <LobbyLayout
        stageProps={{ outerGame }}
        avatarZIndex={1}
        avatarComponent={avatarTransition((groupStyle, group) => (
          <AnimatedBox w="full" h="full" style={groupStyle}>
            <AvatarCarousel
              students={group.members}
              selfId={userId}
              candidateAnimations={loopingVictoryAnimations}
              disableCycle
            />
          </AnimatedBox>
        ))}
        actionContent={
          <Box
            position={isPortrait ? "initial" : "relative"}
            h="full"
            w="full"
            zIndex={10}
          >
            {scoreBoardTransition((groupStyle, group) => (
              <AnimatedBox style={groupStyle}>
                <WinningTeamScoreBoard
                  outerGame={outerGame}
                  colorScheme={group.colorScheme}
                  includeTrophy={showTrophy}
                  showNameFirst={showNameFirst}
                  groupName={group.name}
                  showPlus={showPlus}
                  score={group.score}
                />
              </AnimatedBox>
            ))}
          </Box>
        }
      />
      {footer}
    </Box>
  );
};
