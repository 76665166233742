import { Flex, FormControl, SimpleGrid } from "@chakra-ui/react";
import React from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { FormErrorMessage } from "adminComponents/atoms/FormErrorMessage";
import { Icon } from "adminComponents/atoms/Icon";
import { IconCTAButton } from "adminComponents/atoms/IconCTAButton";
import { Text } from "adminComponents/atoms/Text";
import { CreateClassificationTabForm } from "adminComponents/molecules/CreateQuestionClassificationTab";
import { RichTextEditor } from "adminComponents/organisms/RichTextEditor";
import { isRichTextEmpty } from "adminComponents/organisms/RichTextEditor/util";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { generateEmptySlateState } from "links/lib/util";

import { ImageModeChoice, TextModeChoice } from "./CategoryChoice";

export interface ICategoryFieldProps {
  hasNoPremiumAccess?: boolean;
  categoryIndex: number;
  isImageMode: boolean;
  canRemoveCategory: boolean;
  canAddResponse: boolean;
  handleRemoveCategory: (index: number) => void;
  handleAddImageChoice: (index: number) => void;
  handleImageChoiceClick: (categoryIndex: number, choiceIndex: number) => void;
}

export const CategoryField: React.FC<ICategoryFieldProps> = ({
  hasNoPremiumAccess = false,
  categoryIndex,
  isImageMode,
  canRemoveCategory,
  canAddResponse,
  handleRemoveCategory,
  handleAddImageChoice,
  handleImageChoiceClick,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  const { control, getFieldState, formState } =
    useFormContext<CreateClassificationTabForm>();

  const {
    append: appendChoice,
    remove: removeChoice,
    fields: choiceFields,
  } = useFieldArray({
    control,
    name: `categories.${categoryIndex}.choices`,
    rules: {
      validate: {
        hasMinChoices: (v) =>
          !v ||
          v.length >= 1 ||
          (t("createClassificationFlyout.errNumCategoryChoices") as string),
        hasImages: (v) =>
          !isImageMode ||
          v.every((c) => c.image_url) ||
          (t("createClassificationFlyout.errInvalidCategoryChoice") as string),
      },
    },
  });

  const handleAddNewCategoryChoice = () => {
    appendChoice({ text: generateEmptySlateState() });
  };

  const handleRemoveCategoryChoice = (index: number) => {
    removeChoice(index);
  };

  const { error } = getFieldState(
    `categories.${categoryIndex}.choices`,
    formState
  );
  const categoryError = error?.root;
  const isInvalid = !!categoryError;

  return (
    <Flex
      gap={pxToRem(12)}
      alignItems="center"
      flexDir="column"
      borderRadius="xl"
      border="1px solid"
      borderColor="primary.medium-gray"
      minW={0}
    >
      <FormControl
        isRequired
        variant="adminFormControl"
        isInvalid={isInvalid}
        minW={0}
      >
        <Flex
          w="full"
          alignItems="start"
          flexDir="column"
          borderTopRadius="xl"
          gap={pxToRem(12)}
          bgColor="primary.light-gray"
          px={[pxToRem(36), null, pxToRem(36)]}
          py={[pxToRem(24), null, pxToRem(24)]}
        >
          <Flex w="full" justifyContent="space-between">
            <Text
              variant="adminP2"
              color={categoryError ? "utility.error" : "primary.warm-black"}
            >
              {t("createClassificationFlyout.categoryName")}*
            </Text>
            {canRemoveCategory && (
              <IconCTAButton
                boxSize={20}
                iconSize={16}
                icon="close"
                ariaLabel={t("createClassificationFlyout.removeCategory")}
                colorScheme="primary.white"
                onClick={
                  hasNoPremiumAccess
                    ? undefined
                    : () => handleRemoveCategory(categoryIndex)
                }
                type="button"
                disabled={hasNoPremiumAccess}
              />
            )}
          </Flex>
          <FormErrorMessage>{categoryError?.message}</FormErrorMessage>

          <Controller
            control={control}
            name={`categories.${categoryIndex}.text` as const}
            rules={{
              validate: {
                isNotEmpty: (v) =>
                  !isRichTextEmpty(v) ||
                  (t(
                    "createClassificationFlyout.errCategoryNameRequired"
                  ) as string),
              },
            }}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { isTouched, error },
            }) => (
              <RichTextEditor
                ref={ref}
                name={name}
                handleChangeRaw={onChange}
                handleBlur={onBlur}
                value={value}
                hasError={isTouched && !!error}
                inline
                placeholder={t(
                  "createClassificationFlyout.placeholderCategoryName"
                )}
                disabled={hasNoPremiumAccess}
              />
            )}
          />
        </Flex>
      </FormControl>
      <SimpleGrid
        w="full"
        gap={pxToRem(12)}
        px={[pxToRem(36), null, pxToRem(36)]}
        py={[pxToRem(24), null, pxToRem(24)]}
      >
        <Text
          variant="adminP2"
          color={categoryError ? "utility.error" : "primary.warm-black"}
        >
          {t("createClassificationFlyout.categoryItems")}*
        </Text>
        {choiceFields.map((choice, choiceIndex) =>
          isImageMode ? (
            <ImageModeChoice
              hasNoPremiumAccess={hasNoPremiumAccess}
              key={choice.id}
              categoryIndex={categoryIndex}
              choiceIndex={choiceIndex}
              handleImageChoiceClick={handleImageChoiceClick}
              handleRemoveCategoryChoice={handleRemoveCategoryChoice}
            />
          ) : (
            <TextModeChoice
              hasNoPremiumAccess={hasNoPremiumAccess}
              key={choice.id}
              categoryIndex={categoryIndex}
              choiceIndex={choiceIndex}
              handleRemoveCategoryChoice={handleRemoveCategoryChoice}
            />
          )
        )}
        <Button
          w="fit-content"
          variant="adminButtonFilled"
          onClick={
            hasNoPremiumAccess
              ? undefined
              : () =>
                  isImageMode
                    ? handleAddImageChoice(categoryIndex)
                    : handleAddNewCategoryChoice()
          }
          disabled={!canAddResponse || hasNoPremiumAccess}
          leftIcon={
            canAddResponse ? (
              <Icon iconColor="primary.warm-black" icon="add_outlined" />
            ) : undefined
          }
        >
          {canAddResponse
            ? t("createClassificationFlyout.addChoice")
            : t("createClassificationFlyout.addChoiceDisabled")}
        </Button>
      </SimpleGrid>
    </Flex>
  );
};
