import desktopImage from "adminComponents/assets/background-images/illustrated-background-desktop.svg";
import mobileImage from "adminComponents/assets/background-images/illustrated-background-mobile.svg";
import { pxToRem } from "adminComponents/utils/pxToRem";

export const AdminStudentTemplateWithTwoColumns = {
  baseStyle: ({
    isLeftColumnSticky,
  }: {
    isLeftColumnSticky?: boolean;
  }): Record<string, unknown> => ({
    pageContainer: {
      bg: "primary.warm-white",
      minHeight: "100vh",
    },
    topContainer: {
      backgroundImage: { base: mobileImage, md: desktopImage },
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundAttachment: "fixed",
      backgroundBlendMode: "saturation",
    },
    bottomContainer: {
      flexDirection: { base: "column", md: "row" },
      gap: { md: pxToRem(58) },
      paddingTop: { md: pxToRem(56) },
    },
    leftColumn: {
      paddingBottom: { base: pxToRem(24), md: pxToRem(56) },
      width: { base: "100%", md: "50%" },
    },
    leftColumnInnerContent: {
      position: isLeftColumnSticky ? { md: "sticky" } : "initial",
      top: isLeftColumnSticky ? { md: pxToRem(136) } : "unset", // nav height plus bottomContainer paddingTop height
    },
    leftColumnShiftedContent: {
      position: "relative",
      zIndex: "0",
      _after: {
        content: "''",
        position: "absolute",
        top: "50%",
        left: "0",
        right: "0",
        bottom: "0",
        backgroundColor: "primary.warm-white",
        zIndex: "-1",
      },
    },
    rightColumn: {
      paddingBottom: pxToRem(56),
      width: { base: "100%", md: "50%" },
    },
    loadingSpinner: {
      paddingTop: pxToRem(100),
      paddingBottom: pxToRem(100),
    },
  }),
};
