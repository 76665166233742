import {
  Box,
  LinkBox,
  LinkOverlay,
  useBreakpointValue,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

import { CalendarDate } from "adminComponents/atoms/CalendarDate";
import { Card } from "adminComponents/atoms/Card";
import { Divider } from "adminComponents/atoms/Divider";
import { Heading } from "adminComponents/atoms/Heading";
import { IconButtonWithTooltip } from "adminComponents/atoms/IconButtonWithTooltip";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { IAssignment } from "links/lib/types";

import CoverImageBlob from "../CoverImageBlob";
import { PieChartWithLabel } from "../PieChartWithLabel";

export interface IProps {
  assignment: IAssignment;
  accuracy?: number;
  accuracyType?: "class" | "student";
  completedStudentCount?: number;
  totalStudentCount?: number;
  attempts?: number;
  isUpcoming?: boolean;
  handleEdit?: (assignment: IAssignment) => void;
  handleDelete?: (assignment: IAssignment) => void;
  studentId?: string;
}

export const AssignmentCard: React.FC<IProps> = ({
  assignment,
  accuracy,
  accuracyType,
  completedStudentCount,
  totalStudentCount,
  attempts,
  isUpcoming = false,
  handleEdit,
  handleDelete,
  studentId,
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });
  const styles = useMultiStyleConfig("AdminAssignmentCard", { isUpcoming });

  const hasStudentCount =
    typeof completedStudentCount !== "undefined" &&
    typeof totalStudentCount !== "undefined";
  const coverImageSize = useBreakpointValue({ base: 60, md: 100 });
  const shouldDisplayAccuracy = typeof accuracy !== "undefined" && accuracyType;
  const hasProgressInfo =
    shouldDisplayAccuracy || typeof attempts !== "undefined" || hasStudentCount;
  const accuracyTypeLabel =
    accuracyType === "class"
      ? t("assignmentCard.classAccuracy")
      : t("assignmentCard.studentAccuracy");

  const handleUpcomingEdit = useCallback(
    () => handleEdit?.(assignment),
    [assignment, handleEdit]
  );

  const handleUpcomingDelete = useCallback(
    () => handleDelete?.(assignment),
    [assignment, handleDelete]
  );

  const date = isUpcoming ? assignment.starts_at : assignment.ends_at;
  const dateType = isUpcoming ? "start" : "due";

  const assignmentUrl =
    accuracyType === "student"
      ? `/t/classrooms/${assignment.group_id}/assignments/${assignment.id}/students/${studentId}`
      : `/t/classrooms/${assignment.group_id}/assignments/${assignment.id}`;

  const { practice_set: practiceSet } = assignment;

  // LinkOverlay will be limited in size to ClampedHeading as its child, make it the parent
  const heading = useMemo(() => {
    let heading = (
      <Heading noOfLines={{ base: 4, md: 2 }} as="p" variant="adminH6">
        {practiceSet.title}
      </Heading>
    );

    if (!isUpcoming) {
      heading = (
        <LinkOverlay as={RouterLink} to={assignmentUrl} sx={styles.linkOverlay}>
          {heading}
        </LinkOverlay>
      );
    }

    return heading;
  }, [isUpcoming, assignmentUrl, styles.linkOverlay, practiceSet.title]);

  return (
    <LinkBox>
      <Card
        borderColor="primary.tan"
        backgroundColor={isUpcoming ? "transparent" : "primary.white"}
        variant={
          isUpcoming
            ? "adminCardSmallBorderSmallPadding"
            : "adminCardSelectable"
        }
      >
        <Box __css={styles.body}>
          <Box __css={styles.title}>
            {practiceSet.cover_image_bg_color_scheme &&
              practiceSet.cover_image_icon &&
              practiceSet.cover_image_bg_pattern && (
                <Box __css={styles.coverImage}>
                  <CoverImageBlob
                    backgroundColorScheme={
                      practiceSet.cover_image_bg_color_scheme
                    }
                    backgroundPattern={practiceSet.cover_image_bg_pattern}
                    icon={practiceSet.cover_image_icon}
                    boxSize={coverImageSize || 100}
                  />
                </Box>
              )}
            <Box>
              {practiceSet.title && heading}
              {date && (
                <Box display={{ base: "none", md: "block" }} mt={pxToRem(10)}>
                  <CalendarDate date={date} dateType={dateType} />
                </Box>
              )}
            </Box>
          </Box>
          {date && (
            <Box display={{ base: "block", md: "none" }}>
              <CalendarDate date={date} dateType={dateType} />
            </Box>
          )}
          {isUpcoming ? (
            <Box __css={styles.upcoming}>
              <IconButtonWithTooltip
                icon="edit_outlined"
                shape="type2"
                onClick={handleUpcomingEdit}
                ariaLabel={t("common.edit")}
                tooltipProps={{ popoverVariant: "default" }}
              />
              <IconButtonWithTooltip
                icon="delete_outlined"
                shape="type1"
                onClick={handleUpcomingDelete}
                ariaLabel={t("common.delete")}
                tooltipProps={{ popoverVariant: "default" }}
              />
            </Box>
          ) : (
            hasProgressInfo && (
              <>
                <Divider __css={styles.divider} color="primary.tan" />
                <Box>
                  {shouldDisplayAccuracy && (
                    <Box __css={styles.progress}>
                      <PieChartWithLabel
                        percentage={accuracy}
                        text={accuracyTypeLabel}
                      />
                    </Box>
                  )}
                  {(hasStudentCount || typeof attempts !== "undefined") && (
                    <Text
                      variant="adminP1"
                      mt={
                        shouldDisplayAccuracy
                          ? { base: pxToRem(8), md: pxToRem(12) }
                          : 0
                      }
                    >
                      {hasStudentCount
                        ? `${completedStudentCount}/${totalStudentCount} ${t(
                            "assignmentCard.studentCompletion"
                          )}`
                        : `${attempts} ${
                            (attempts as number) != 1
                              ? t("assignmentCard.attemptsPlural")
                              : t("assignmentCard.attemptsSingular")
                          }`}
                    </Text>
                  )}
                </Box>
              </>
            )
          )}
        </Box>
      </Card>
    </LinkBox>
  );
};
