import { useMemo } from "react";

import { useSessionGroups } from "links/lib/contexts/sessionGroups";
import { ISessionGroup } from "links/lib/types";

export const useSafeGroupsArray = (): ISessionGroup[] => {
  const groups = useSessionGroups();
  const safeGroups = useMemo(
    () => Object.values(groups).filter((g) => !!g) as ISessionGroup[],
    [groups]
  );

  return safeGroups;
};
