import React, {
  MutableRefObject,
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";

import { ISessionUser } from "links/lib/types";

import { StudentCursors } from "../molecules/StudentCursors";

export interface IStudentCursorsContext {
  setCursorsEnabled: (isEnabled: boolean) => void;
  setCursorsTarget: (
    target: MutableRefObject<HTMLDivElement | null> | null
  ) => void;
  setCursorsVisible: (isVisible: boolean) => void;
  isCursorsEnabled: boolean;
  isCursorsVisible: boolean;
}

export const StudentCursorsContext = createContext<IStudentCursorsContext>({
  setCursorsEnabled: () => {
    throw new Error("Not Implemented");
  },
  setCursorsTarget: () => {
    throw new Error("Not Implemented");
  },
  setCursorsVisible: () => {
    throw new Error("Not Implemented");
  },
  isCursorsEnabled: false,
  isCursorsVisible: true,
});

export const StudentCursorsProvider: React.FC<{
  student?: ISessionUser;
}> = ({ student, children }) => {
  const [isEnabled, setCursorsEnabled] = useState(true);
  const [isVisible, setCursorsVisible] = useState(true);
  const [target, setCursorsTarget] =
    useState<MutableRefObject<HTMLDivElement | null> | null>(null);

  const value = useMemo(
    () => ({
      setCursorsEnabled,
      setCursorsTarget,
      setCursorsVisible,
      isCursorsEnabled: isEnabled,
      isCursorsVisible: isVisible,
    }),
    [isEnabled, isVisible]
  );

  return (
    <StudentCursorsContext.Provider value={value}>
      {children}
      {isEnabled && student && (
        <StudentCursors
          isVisible={isVisible}
          student={student}
          target={target}
        />
      )}
    </StudentCursorsContext.Provider>
  );
};

export const useStudentCursors = (): IStudentCursorsContext => {
  return useContext(StudentCursorsContext);
};
