import { ButtonProps, HStack } from "@chakra-ui/react";
import React from "react";

import { Button } from "adminComponents/atoms/Button";
import { Icon } from "adminComponents/atoms/Icon";
import { pxToRem } from "adminComponents/utils";
import { useAuth } from "links/lib/features/auth";
import { IPracticeSetRating } from "links/lib/types";

interface IRateButtonProps extends ButtonProps {
  rating: IPracticeSetRating;
  personalRating: number;
  handleRate: (rating: number) => void;
  upvoteAriaLabel: string;
  downvoteAriaLabel: string;
}

export const RateButton: React.FC<IRateButtonProps> = ({
  rating,
  personalRating,
  handleRate,
  upvoteAriaLabel,
  downvoteAriaLabel,
}) => {
  const { authUser } = useAuth();

  return (
    <HStack spacing={pxToRem(2)}>
      <Button
        size="sm"
        variant={personalRating === 1 ? "adminButtonFilled" : "adminButtonTan"}
        borderRightRadius={0}
        leftIcon={
          <Icon w={pxToRem(24)} h={pxToRem(24)} icon="thumb_up_outlined" />
        }
        onClick={() => {
          const newRating = personalRating === 1 ? 0 : 1;
          handleRate(newRating);
        }}
        aria-label={upvoteAriaLabel}
      >
        {rating.total_count_positive > 0 &&
          rating.total_count_positive.toLocaleString(authUser?.language)}
      </Button>
      <Button
        size="sm"
        variant={personalRating === -1 ? "adminButtonFilled" : "adminButtonTan"}
        borderLeftRadius={0}
        leftIcon={
          <Icon w={pxToRem(24)} h={pxToRem(24)} icon="thumb_down_outlined" />
        }
        onClick={() => {
          const newRating = personalRating === -1 ? 0 : -1;
          handleRate(newRating);
        }}
        aria-label={downvoteAriaLabel}
      />
    </HStack>
  );
};
