import BlobSVG from "adminComponents/assets/blobs/blob-1.svg";
import { hoverStyles, pxToRem } from "adminComponents/utils";

export const AdminCoverImageChoice = {
  baseStyle: ({
    isSelected,
    boxSize,
    iconSize,
    coverImageSize,
  }: {
    isSelected: boolean;
    boxSize: number;
    iconSize: number;
    coverImageSize: number;
  }): Record<string, unknown> => ({
    container: {
      bg: isSelected ? "utility.link" : "transparent",
      boxSize: pxToRem(boxSize),
      cursor: "pointer",
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      mask: `url(${BlobSVG})`,
      maskSize: "100%",
      maskRepeat: "no-repeat",

      _hover: hoverStyles({
        bg: isSelected ? undefined : "primary.golden-hover",
      }),
      _focusVisible: {
        bg: isSelected ? undefined : "utility.focus",
      },
    },
    coverImage: {
      boxSize: pxToRem(coverImageSize),
      mask: `url(${BlobSVG})`,
      maskSize: "100%",
      maskRepeat: "no-repeat",
    },
    icon: {
      boxSize: pxToRem(iconSize),
    },
  }),
};
