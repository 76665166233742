import { Box, BoxProps, useMultiStyleConfig } from "@chakra-ui/react";
import React from "react";

import { ColorScheme } from "adminComponents";
import { CoverImageIconType, RemixIconType } from "links/lib/types";

import DIGITAL_CITIZENSHIP_SVG from "./resource/DIGITAL_CITIZENSHIP.svg";
import ELECTIVES_BUSINESS_AND_FINANCE_SVG from "./resource/ELECTIVES_BUSINESS_AND_FINANCE.svg";
import ELECTIVES_COOKING_AND_BAKING_SVG from "./resource/ELECTIVES_COOKING_AND_BAKING.svg";
import ELECTIVES_HEALTH_SVG from "./resource/ELECTIVES_HEALTH.svg";
import ELECTIVES_PHYSICAL_EDUCATION_SVG from "./resource/ELECTIVES_PHYSICAL_EDUCATION.svg";
import ELECTIVES_REMIX_SVG from "./resource/ELECTIVES_REMIX.svg";
import ELECTIVES_WOODWORKING_SVG from "./resource/ELECTIVES_WOODWORKING.svg";
import ENGLISH_GRAMMAR_SVG from "./resource/ENGLISH_GRAMMAR.svg";
import ENGLISH_LITERATURE_SVG from "./resource/ENGLISH_LITERATURE.svg";
import ENGLISH_PHONICS_SVG from "./resource/ENGLISH_PHONICS.svg";
import ENGLISH_READING_SVG from "./resource/ENGLISH_READING.svg";
import ENGLISH_REMIX_SVG from "./resource/ENGLISH_REMIX.svg";
import ENGLISH_SPELLING_SVG from "./resource/ENGLISH_SPELLING.svg";
import ENGLISH_WRITING_SVG from "./resource/ENGLISH_WRITING.svg";
import HISTORY_COMMUNITY_SVG from "./resource/HISTORY_COMMUNITY.svg";
import HISTORY_ECONOMICS_SVG from "./resource/HISTORY_ECONOMICS.svg";
import HISTORY_GEOGRAPHY_SVG from "./resource/HISTORY_GEOGRAPHY.svg";
import HISTORY_GOVERNMENT_SVG from "./resource/HISTORY_GOVERNMENT.svg";
import HISTORY_REMIX_SVG from "./resource/HISTORY_REMIX.svg";
import HISTORY_US_HISTORY_SVG from "./resource/HISTORY_US_HISTORY.svg";
import HISTORY_WORLD_HISTORY_SVG from "./resource/HISTORY_WORLD_HISTORY.svg";
import MATHEMATICS_ALGEBRA_SVG from "./resource/MATHEMATICS_ALGEBRA.svg";
import MATHEMATICS_CALCULUS_SVG from "./resource/MATHEMATICS_CALCULUS.svg";
import MATHEMATICS_EXPRESSIONS_AND_EQUATIONS_SVG from "./resource/MATHEMATICS_EXPRESSIONS_AND_EQUATIONS.svg";
import MATHEMATICS_GEOMETRY_SVG from "./resource/MATHEMATICS_GEOMETRY.svg";
import MATHEMATICS_NUMBERS_AND_OPERATIONS_SVG from "./resource/MATHEMATICS_NUMBERS_AND_OPERATIONS.svg";
import MATHEMATICS_REMIX_SVG from "./resource/MATHEMATICS_REMIX.svg";
import MATHEMATICS_STATISTICS_AND_PROBABILITY_SVG from "./resource/MATHEMATICS_STATISTICS_AND_PROBABILITY.svg";
import OTHER_CULTURE_SVG from "./resource/OTHER_CULTURE.svg";
import OTHER_GENERAL_FUN_SVG from "./resource/OTHER_GENERAL_FUN.svg";
import OTHER_PARTY_FUN_SVG from "./resource/OTHER_PARTY_FUN.svg";
import OTHER_REMIX_SVG from "./resource/OTHER_REMIX.svg";
import OTHER_ROCKET_SVG from "./resource/OTHER_ROCKET.svg";
import OTHER_TRIVIA_SVG from "./resource/OTHER_TRIVIA.svg";
import OTHER_VOCABULARY_SVG from "./resource/OTHER_VOCABULARY.svg";
import REDEMPTION_REMIX_SVG from "./resource/REDEMPTION_REMIX.svg";
import SCIENCE_BIOLOGY_SVG from "./resource/SCIENCE_BIOLOGY.svg";
import SCIENCE_CHEMISTRY_SVG from "./resource/SCIENCE_CHEMISTRY.svg";
import SCIENCE_EARTH_AND_SPACE_SVG from "./resource/SCIENCE_EARTH_AND_SPACE.svg";
import SCIENCE_PHYSICS_SVG from "./resource/SCIENCE_PHYSICS.svg";
import SCIENCE_PSYCHOLOGY_AND_SOCIOLOGY_SVG from "./resource/SCIENCE_PSYCHOLOGY_AND_SOCIOLOGY.svg";
import SCIENCE_REMIX_SVG from "./resource/SCIENCE_REMIX.svg";
import SCIENCE_TECHNOLOGY_AND_COMPUTER_SCIENCE_SVG from "./resource/SCIENCE_TECHNOLOGY_AND_COMPUTER_SCIENCE.svg";
import VISUAL_AND_PERFORMING_ARTS_DANCE_SVG from "./resource/VISUAL_AND_PERFORMING_ARTS_DANCE.svg";
import VISUAL_AND_PERFORMING_ARTS_DRAMA_AND_THEATRE_SVG from "./resource/VISUAL_AND_PERFORMING_ARTS_DRAMA_AND_THEATRE.svg";
import VISUAL_AND_PERFORMING_ARTS_GRAPHIC_DESIGN_SVG from "./resource/VISUAL_AND_PERFORMING_ARTS_GRAPHIC_DESIGN.svg";
import VISUAL_AND_PERFORMING_ARTS_MUSIC_SVG from "./resource/VISUAL_AND_PERFORMING_ARTS_MUSIC.svg";
import VISUAL_AND_PERFORMING_ARTS_REMIX_SVG from "./resource/VISUAL_AND_PERFORMING_ARTS_REMIX.svg";
import VISUAL_AND_PERFORMING_ARTS_STUDIO_ART_SVG from "./resource/VISUAL_AND_PERFORMING_ARTS_STUDIO_ART.svg";
import VISUAL_AND_PERFORMING_ARTS_VIDEO_AND_PHOTOGRAPHY_SVG from "./resource/VISUAL_AND_PERFORMING_ARTS_VIDEO_AND_PHOTOGRAPHY.svg";
import WORLD_LANGUAGES_FRENCH_SVG from "./resource/WORLD_LANGUAGES_FRENCH.svg";
import WORLD_LANGUAGES_GERMAN_SVG from "./resource/WORLD_LANGUAGES_GERMAN.svg";
import WORLD_LANGUAGES_JAPANESE_SVG from "./resource/WORLD_LANGUAGES_JAPANESE.svg";
import WORLD_LANGUAGES_MANDARIN_CHINESE_SVG from "./resource/WORLD_LANGUAGES_MANDARIN_CHINESE.svg";
import WORLD_LANGUAGES_REMIX_SVG from "./resource/WORLD_LANGUAGES_REMIX.svg";
import WORLD_LANGUAGES_SIGN_LANGUAGE_SVG from "./resource/WORLD_LANGUAGES_SIGN_LANGUAGE.svg";
import WORLD_LANGUAGES_SPANISH_SVG from "./resource/WORLD_LANGUAGES_SPANISH.svg";
import { ReactComponent as CoverImageIconBorder } from "./resource/circle_bg.svg";

const iconLookup: Record<CoverImageIconType | RemixIconType, string> = {
  DIGITAL_CITIZENSHIP: DIGITAL_CITIZENSHIP_SVG,
  ELECTIVES_BUSINESS_AND_FINANCE: ELECTIVES_BUSINESS_AND_FINANCE_SVG,
  ELECTIVES_COOKING_AND_BAKING: ELECTIVES_COOKING_AND_BAKING_SVG,
  ELECTIVES_HEALTH: ELECTIVES_HEALTH_SVG,
  ELECTIVES_PHYSICAL_EDUCATION: ELECTIVES_PHYSICAL_EDUCATION_SVG,
  ELECTIVES_REMIX: ELECTIVES_REMIX_SVG,
  ELECTIVES_WOODWORKING: ELECTIVES_WOODWORKING_SVG,
  ENGLISH_GRAMMAR: ENGLISH_GRAMMAR_SVG,
  ENGLISH_LITERATURE: ENGLISH_LITERATURE_SVG,
  ENGLISH_PHONICS: ENGLISH_PHONICS_SVG,
  ENGLISH_READING: ENGLISH_READING_SVG,
  ENGLISH_REMIX: ENGLISH_REMIX_SVG,
  ENGLISH_SPELLING: ENGLISH_SPELLING_SVG,
  ENGLISH_WRITING: ENGLISH_WRITING_SVG,
  HISTORY_COMMUNITY: HISTORY_COMMUNITY_SVG,
  HISTORY_ECONOMICS: HISTORY_ECONOMICS_SVG,
  HISTORY_GEOGRAPHY: HISTORY_GEOGRAPHY_SVG,
  HISTORY_GOVERNMENT: HISTORY_GOVERNMENT_SVG,
  HISTORY_REMIX: HISTORY_REMIX_SVG,
  HISTORY_US_HISTORY: HISTORY_US_HISTORY_SVG,
  HISTORY_WORLD_HISTORY: HISTORY_WORLD_HISTORY_SVG,
  MATHEMATICS_ALGEBRA: MATHEMATICS_ALGEBRA_SVG,
  MATHEMATICS_CALCULUS: MATHEMATICS_CALCULUS_SVG,
  MATHEMATICS_EXPRESSIONS_AND_EQUATIONS:
    MATHEMATICS_EXPRESSIONS_AND_EQUATIONS_SVG,
  MATHEMATICS_GEOMETRY: MATHEMATICS_GEOMETRY_SVG,
  MATHEMATICS_NUMBERS_AND_OPERATIONS: MATHEMATICS_NUMBERS_AND_OPERATIONS_SVG,
  MATHEMATICS_REMIX: MATHEMATICS_REMIX_SVG,
  MATHEMATICS_STATISTICS_AND_PROBABILITY:
    MATHEMATICS_STATISTICS_AND_PROBABILITY_SVG,
  OTHER_CULTURE: OTHER_CULTURE_SVG,
  OTHER_GENERAL_FUN: OTHER_GENERAL_FUN_SVG,
  OTHER_PARTY_FUN: OTHER_PARTY_FUN_SVG,
  OTHER_REMIX: OTHER_REMIX_SVG,
  OTHER_ROCKET: OTHER_ROCKET_SVG,
  OTHER_TRIVIA: OTHER_TRIVIA_SVG,
  OTHER_VOCABULARY: OTHER_VOCABULARY_SVG,
  REDEMPTION_REMIX: REDEMPTION_REMIX_SVG,
  SCIENCE_BIOLOGY: SCIENCE_BIOLOGY_SVG,
  SCIENCE_CHEMISTRY: SCIENCE_CHEMISTRY_SVG,
  SCIENCE_EARTH_AND_SPACE: SCIENCE_EARTH_AND_SPACE_SVG,
  SCIENCE_PHYSICS: SCIENCE_PHYSICS_SVG,
  SCIENCE_PSYCHOLOGY_AND_SOCIOLOGY: SCIENCE_PSYCHOLOGY_AND_SOCIOLOGY_SVG,
  SCIENCE_REMIX: SCIENCE_REMIX_SVG,
  SCIENCE_TECHNOLOGY_AND_COMPUTER_SCIENCE:
    SCIENCE_TECHNOLOGY_AND_COMPUTER_SCIENCE_SVG,
  VISUAL_AND_PERFORMING_ARTS_DANCE: VISUAL_AND_PERFORMING_ARTS_DANCE_SVG,
  VISUAL_AND_PERFORMING_ARTS_DRAMA_AND_THEATRE:
    VISUAL_AND_PERFORMING_ARTS_DRAMA_AND_THEATRE_SVG,
  VISUAL_AND_PERFORMING_ARTS_GRAPHIC_DESIGN:
    VISUAL_AND_PERFORMING_ARTS_GRAPHIC_DESIGN_SVG,
  VISUAL_AND_PERFORMING_ARTS_MUSIC: VISUAL_AND_PERFORMING_ARTS_MUSIC_SVG,
  VISUAL_AND_PERFORMING_ARTS_REMIX: VISUAL_AND_PERFORMING_ARTS_REMIX_SVG,
  VISUAL_AND_PERFORMING_ARTS_STUDIO_ART:
    VISUAL_AND_PERFORMING_ARTS_STUDIO_ART_SVG,
  VISUAL_AND_PERFORMING_ARTS_VIDEO_AND_PHOTOGRAPHY:
    VISUAL_AND_PERFORMING_ARTS_VIDEO_AND_PHOTOGRAPHY_SVG,
  WORLD_LANGUAGES_FRENCH: WORLD_LANGUAGES_FRENCH_SVG,
  WORLD_LANGUAGES_GERMAN: WORLD_LANGUAGES_GERMAN_SVG,
  WORLD_LANGUAGES_JAPANESE: WORLD_LANGUAGES_JAPANESE_SVG,
  WORLD_LANGUAGES_MANDARIN_CHINESE: WORLD_LANGUAGES_MANDARIN_CHINESE_SVG,
  WORLD_LANGUAGES_REMIX: WORLD_LANGUAGES_REMIX_SVG,
  WORLD_LANGUAGES_SIGN_LANGUAGE: WORLD_LANGUAGES_SIGN_LANGUAGE_SVG,
  WORLD_LANGUAGES_SPANISH: WORLD_LANGUAGES_SPANISH_SVG,
};

export interface ICoverImageIconProps extends BoxProps {
  icon: CoverImageIconType | RemixIconType;
  disabled?: boolean;
  backgroundColor?: ColorScheme;
  rgbBackgroundColor?: string;
  borderColor?: ColorScheme;
  iconSize?: number;
  variant?: "default" | "adminSolidBorder";
}

const CoverImageIcon: React.FC<ICoverImageIconProps> = ({
  icon,
  disabled = false,
  borderColor,
  iconSize,
  variant = "default",
  rgbBackgroundColor,
  ...rest
}) => {
  const { backgroundColor } = rest;

  const styles = useMultiStyleConfig("AdminCoverImageIcon", {
    borderColor,
    backgroundColor,
    rgbBackgroundColor,
    iconSize,
    disabled,
  });

  const hasBorder = variant === "adminSolidBorder";

  return (
    <Box sx={styles.container} aria-role="img" role="img" aria-hidden title="">
      {hasBorder && (
        <Box sx={styles.border}>
          <CoverImageIconBorder width="100%" height="100%" />
        </Box>
      )}

      {hasBorder && (
        <Box sx={hasBorder ? styles.iconContainer : undefined}>
          <Box
            __css={styles.icon}
            backgroundImage={iconLookup[icon]}
            {...rest}
          />
        </Box>
      )}

      {!hasBorder && (
        <Box __css={styles.icon} backgroundImage={iconLookup[icon]} {...rest} />
      )}
    </Box>
  );
};

export { CoverImageIcon };
