import { Box, usePrefersReducedMotion } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { animated, config, useTransition } from "react-spring";

import { pxToRem } from "../../../../../adminComponents/utils/pxToRem";
import { useStudent } from "sessionComponents/contexts/student";

import { AvatarIcon } from "../../../../atoms/AvatarIcon";

export type AvatarIconUser = {
  id: string;
  avatarUrl: string;
  name: string;
  primaryColor: string;
};

export interface IResponseAvatarIconsProps {
  users: AvatarIconUser[];
  bottom: number;
  right: number;
  avatarIconSize: number;
}

const AnimatedBox = animated(Box);

export const ResponseAvatarIcons: React.FC<IResponseAvatarIconsProps> = ({
  users,
  avatarIconSize,
  bottom,
  right,
}) => {
  // keeps track of currently active bubbles and
  // their desired right position
  const activebubbleRightMap = useMemo(() => {
    const m: { [key: string]: string } = {};

    users.forEach((u, i) => {
      m[u.id] = i * avatarIconSize + i * 4 + "px"; // do not convert to rem
    });

    return m;
  }, [users, avatarIconSize]);

  const student = useStudent();
  const prefersReducedMotion = usePrefersReducedMotion();

  const transitions = useTransition(users, {
    from: { opacity: 0, scale: prefersReducedMotion ? 1 : 0 },
    enter: { opacity: 1, scale: 1 },
    leave: { opacity: 0, scale: prefersReducedMotion ? 1 : 0 },
    config: prefersReducedMotion ? config.stiff : config.wobbly,
    keys: (item) => item.id,
  });

  return (
    <Box
      position="absolute"
      bottom={pxToRem(bottom + avatarIconSize)}
      right={pxToRem(right)}
      display="block"
    >
      {transitions(
        ({ opacity, scale }, user, transition, i) =>
          user && (
            <AnimatedBox
              w={pxToRem(avatarIconSize)}
              h={pxToRem(avatarIconSize)}
              position="absolute"
              top="0"
              transition={
                prefersReducedMotion ? "none" : "right 100ms ease-in-out"
              }
              // if bubble is transitioning out (not active),
              // then use overall index in items to calculate position
              // else, use active bubble position
              right={
                !activebubbleRightMap[user.id]
                  ? i * avatarIconSize + i * 4 + "px" // do not convert to rem
                  : activebubbleRightMap[user.id]
              }
              style={{
                opacity: opacity,
                transform: scale.to((s) => `scale(${s})`),
              }}
            >
              <AvatarIcon
                avatarIconSize={pxToRem(avatarIconSize)}
                avatarImgUrl={user.avatarUrl}
                name={user.name}
                primaryColor={user.primaryColor}
                isSelf={user.id === student.id}
                isGuest={student.is_guest}
              />
            </AnimatedBox>
          )
      )}
    </Box>
  );
};
