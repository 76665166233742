import React from "react";
import { Switch } from "react-router-dom";

import ProtectedRoute from "screens/App/components/ProtectedRoute";

import { PhotoBooth } from "./PhotoBooth";

export const Proto: React.FC = () => {
  return (
    <Switch>
      <ProtectedRoute path="/proto/photo-booth" exact={true}>
        <PhotoBooth />
      </ProtectedRoute>
    </Switch>
  );
};
