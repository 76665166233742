import { pxToRem } from "adminComponents/utils/pxToRem";

export const AdminUserCard = {
  baseStyle: {
    avatar: {
      alignItems: "center",
      display: "flex",
      "> *:first-child": {
        marginRight: { base: pxToRem(12), md: pxToRem(20) },
      },
      ">  *:last-child": {
        display: { base: "block", md: "none" },
      },
    },
    container: {
      alignItems: { base: "flex-start", md: "center" },
      display: "flex",
      flexDirection: { base: "column", md: "row" },
      gap: { base: "none", md: pxToRem(12) },
      justifyContent: "space-between",
      position: "relative",
      width: "100%",
      wordBreak: "break-word",
    },
    content: {
      textAlign: "left",
      "> *:first-child": {
        display: { base: "none", md: "block" },
      },
    },
    divider: {
      borderBottomWidth: pxToRem(2),
      borderColor: "primary.light-gray",
      display: { base: "block", md: "none" },
      opacity: "1",
      margin: `${pxToRem(20)} 0`,
    },
    left: {
      display: { base: "block", md: "flex" },
      alignItems: "center",
    },
    right: {
      display: "flex",
      alignItems: "center",
      flexShrink: 0,
      width: { base: "100%", md: "auto" },
    },
    accuracyEditContainer: {
      display: "flex",
      gap: pxToRem(30),
    },
    editButtonContainer: {
      position: { base: "absolute", md: "relative" },
      top: { base: pxToRem(14), md: "unset" },
      right: { base: pxToRem(14), md: "unset" },
    },
    buttonContainer: {
      display: { base: "grid", md: "flex" },
      gridTemplateColumns: "1fr 1fr",
      width: "100%",
      gap: pxToRem(16),
    },
    linkOverlay: {
      _focus: {
        boxShadow: "none",
        outline: "none",
      },
      _focusVisible: {
        outlineStyle: "solid",
        outlineWidth: pxToRem(2),
        outlineColor: "utility.focus",
      },
    },
    bottomLine: {
      paddingTop: { base: pxToRem(16), md: pxToRem(4) },
    },
  },
};
