export type Breakpoint = {
  min: number;
  max: number | null;
  padding: number;
  margin: number;
  iconSize: number;
  fontSize: number;
  borderRadius: number;
  lightBorderRadius: number;
  avatarIconSize: number;
  buttonHeight: number;
  borderWidth: number;
  imageMaxWidth?: number;
  responseHeight: number;
  imageResponseSize: number;
  imageResponseIconPosition: number;
  headerHeight: number;
  diagramTargetSize: number;
  avatarStickerSize: number;
  studentTeamUpTextAnimationHeight: number;
  bigBoardCellBorderWidth: number;
  bigBoardTokenPlaceholderSize: number;
  interstitialTileSize: number;
  markerSize: number;
};

export type Breakpoints = {
  [key in string]: Breakpoint;
};

/**
 * Portrait values are meant to map to widths, landscape values are meant to map to heights.
 */
const initialBreakpoints = {
  portrait: {
    sm: {
      min: 0,
      max: 374,
      padding: 16,
      margin: 16,
      iconSize: 20,
      fontSize: 16,
      borderRadius: 16,
      lightBorderRadius: 8,
      avatarIconSize: 24,
      buttonHeight: 40,
      borderWidth: 2,
      responseHeight: 52,
      imageResponseSize: 140,
      imageResponseIconPosition: 8,
      headerHeight: 64,
      diagramTargetSize: 30,
      avatarStickerSize: 52,
      studentTeamUpTextAnimationHeight: 60,
      bigBoardCellBorderWidth: 1,
      bigBoardTokenPlaceholderSize: 14,
      prizeRoundAwardsSorryWidth: 320,
      interstitialTileSize: 300,
      markerSize: 38,
    },
    md: {
      min: 375,
      max: 425,
      padding: 20,
      margin: 20,
      iconSize: 25,
      fontSize: 20,
      borderRadius: 20,
      lightBorderRadius: 12,
      avatarIconSize: 30,
      buttonHeight: 50,
      borderWidth: 2,
      responseHeight: 64,
      imageResponseSize: 160,
      imageResponseIconPosition: 10,
      headerHeight: 80,
      diagramTargetSize: 38,
      avatarStickerSize: 64,
      studentTeamUpTextAnimationHeight: 72,
      bigBoardCellBorderWidth: 1,
      bigBoardTokenPlaceholderSize: 17,
      prizeRoundAwardsSorryWidth: 360,
      interstitialTileSize: 400,
      markerSize: 48,
    },
    lg: {
      min: 426,
      max: 725,
      padding: 24,
      margin: 24,
      iconSize: 30,
      fontSize: 24,
      borderRadius: 24,
      lightBorderRadius: 16,
      avatarIconSize: 36,
      buttonHeight: 60,
      borderWidth: 3,
      responseHeight: 77,
      imageResponseSize: 185,
      imageResponseIconPosition: 12,
      headerHeight: 96,
      diagramTargetSize: 45,
      avatarStickerSize: 77,
      studentTeamUpTextAnimationHeight: 86,
      bigBoardCellBorderWidth: 2,
      bigBoardTokenPlaceholderSize: 18,
      interstitialTileSize: 500,
      markerSize: 58,
    },
    xl: {
      min: 726,
      max: null,
      padding: 28,
      margin: 28,
      iconSize: 35,
      fontSize: 28,
      borderRadius: 28,
      lightBorderRadius: 20,
      avatarIconSize: 42,
      buttonHeight: 70,
      borderWidth: 3,
      imageMaxWidth: 500,
      responseHeight: 90,
      imageResponseSize: 335,
      imageResponseIconPosition: 14,
      headerHeight: 112,
      diagramTargetSize: 52,
      avatarStickerSize: 90,
      studentTeamUpTextAnimationHeight: 100,
      bigBoardCellBorderWidth: 2,
      bigBoardTokenPlaceholderSize: 28,
      interstitialTileSize: 600,
      markerSize: 68,
    },
  },
  landscape: {
    sm: {
      min: 0,
      max: 700,
      padding: 24,
      margin: 24,
      iconSize: 30,
      fontSize: 24,
      borderRadius: 24,
      lightBorderRadius: 16,
      avatarIconSize: 36,
      buttonHeight: 60,
      borderWidth: 3,
      responseHeight: 77,
      imageResponseSize: 310,
      imageResponseIconPosition: 12,
      headerHeight: 96,
      diagramTargetSize: 45,
      avatarStickerSize: 144,
      studentTeamUpTextAnimationHeight: 86,
      bigBoardCellBorderWidth: 1,
      bigBoardTokenPlaceholderSize: 25,
      interstitialTileSize: 500,
      markerSize: 58,
    },
    md: {
      min: 701,
      max: 899,
      padding: 28,
      margin: 28,
      iconSize: 35,
      fontSize: 28,
      borderRadius: 28,
      lightBorderRadius: 20,
      avatarIconSize: 42,
      buttonHeight: 70,
      borderWidth: 3,
      responseHeight: 90,
      imageResponseSize: 310,
      imageResponseIconPosition: 14,
      headerHeight: 112,
      diagramTargetSize: 52,
      avatarStickerSize: 168,
      studentTeamUpTextAnimationHeight: 100,
      bigBoardCellBorderWidth: 1,
      bigBoardTokenPlaceholderSize: 29,
      interstitialTileSize: 500,
      markerSize: 68,
    },
    lg: {
      min: 900,
      max: 970,
      padding: 32,
      margin: 32,
      iconSize: 40,
      fontSize: 32,
      borderRadius: 32,
      lightBorderRadius: 24,
      avatarIconSize: 48,
      buttonHeight: 80,
      borderWidth: 4,
      responseHeight: 103,
      imageResponseSize: 320,
      imageResponseIconPosition: 16,
      headerHeight: 128,
      diagramTargetSize: 60,
      avatarStickerSize: 192,
      studentTeamUpTextAnimationHeight: 115,
      bigBoardCellBorderWidth: 2,
      bigBoardTokenPlaceholderSize: 35,
      interstitialTileSize: 600,
      markerSize: 78,
    },
    xl: {
      min: 971,
      max: null,
      padding: 40,
      margin: 40,
      iconSize: 50,
      fontSize: 40,
      borderRadius: 40,
      lightBorderRadius: 32,
      avatarIconSize: 60,
      buttonHeight: 100,
      borderWidth: 4,
      responseHeight: 128,
      imageResponseSize: 440,
      imageResponseIconPosition: 20,
      headerHeight: 160,
      diagramTargetSize: 75,
      avatarStickerSize: 240,
      studentTeamUpTextAnimationHeight: 150,
      bigBoardCellBorderWidth: 1,
      bigBoardTokenPlaceholderSize: 39,
      interstitialTileSize: 600,
      markerSize: 96,
    },
  },
};

export const breakpoints = initialBreakpoints;
