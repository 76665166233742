import { Box, BoxProps, Flex } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { ExpandableText } from "adminComponents/atoms/ExpandableText";
import { Heading, IHeadingProps } from "adminComponents/atoms/Heading";
import { IconButtonDropdown } from "adminComponents/molecules/IconButtonDropdown";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { ICollection } from "links/lib/types";

export interface IProps extends BoxProps {
  collection: ICollection;
  handleCreatePracticeSet: (collection: ICollection) => void;
  handleDelete: (collection: ICollection) => void;
  // handleDuplicate: (collection: ICollection) => void;
  handleEdit: (collection: ICollection) => void;
  headerVariant?: IHeadingProps["variant"];
  headerAs?: IHeadingProps["as"];
  isLoading?: boolean;
}

export const FolderDetailHeader: React.FC<IProps> = ({
  collection,
  handleCreatePracticeSet,
  handleDelete,
  // handleDuplicate,
  handleEdit,
  headerVariant = "adminH2",
  headerAs = "h2",
  isLoading,
  ...rest
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });

  const menuItems = [
    { label: t("common.edit"), handleClick: () => handleEdit(collection) },
    // {
    //   label: t("common.duplicate"),
    //   handleClick: () => handleDuplicate(collection),
    // },
    { label: t("common.delete"), handleClick: () => handleDelete(collection) },
  ];

  const { name, description } = collection;

  return (
    <Box {...rest}>
      <Flex align="flex-start" justify="space-between">
        <Heading as={headerAs} variant={headerVariant} wordBreak="break-word">
          {name}
        </Heading>
        <Box paddingTop={{ md: pxToRem(12) }} paddingX={{ md: pxToRem(12) }}>
          <IconButtonDropdown menuItems={menuItems} />
        </Box>
      </Flex>
      {description && (
        <Box maxWidth={pxToRem(700)} width="100%">
          <ExpandableText noOfLines={{ base: 3, md: 2 }} textVariant="adminP1">
            {description}
          </ExpandableText>
        </Box>
      )}
      <Button
        isLoading={isLoading}
        mt={pxToRem(24)}
        onClick={() => handleCreatePracticeSet(collection)}
        size="lg"
        variant="adminButtonOutlined"
      >
        {t("common.createNewPracticeSet")}
      </Button>
    </Box>
  );
};
