import { Box } from "@chakra-ui/react";
import React from "react";

import { LoadingSpinner } from "adminComponents/atoms/LoadingSpinner";
import { StandardsTab as AdminStandardsTab } from "adminComponents/screens/StudentPracticeSetReportScreen/components/StandardsTab";
import { pxToRem } from "adminComponents/utils";
import { usePageTrack } from "lib/contexts/analytics";
import { useAuth } from "links/lib/features/auth";
import { IFetchPracticeSetItemsResponse } from "links/lib/features/practiceSetItems/useFetchPracticeSetItems";
import { AnalyticsPage } from "links/lib/types";
import { usePracticeSetItemStandardsData } from "screens/TeacherClassrooms/shared/hooks/usePracticeSetItemStandardsData";

interface IStandardsTabProps {
  practiceSetItemsData?: IFetchPracticeSetItemsResponse;
  classroomId: string;
  practiceSetId: string;
  studentId: string;
  assignmentId?: string;
  practiceSetSessionId?: string;
}

export const StandardsTab: React.FC<IStandardsTabProps> = ({
  classroomId,
  practiceSetId,
  practiceSetItemsData,
  studentId,
  assignmentId,
  practiceSetSessionId,
}) => {
  const { hasNoPremiumAccess } = useAuth();
  usePageTrack(
    AnalyticsPage.TeacherDashboard_ClassroomDetail_PracticeSetStudentDetail_Standards
  );

  const { standardsData, isLoading } = usePracticeSetItemStandardsData({
    practiceSetId,
    classroomId,
    practiceSetItemsData,
    userId: studentId,
    assignmentId,
    practiceSetSessionId,
  });

  return (
    <>
      {isLoading ? (
        <Box mt={pxToRem(20)}>
          <LoadingSpinner />
        </Box>
      ) : (
        <AdminStandardsTab
          standardsData={standardsData}
          hasNoPremiumAccess={hasNoPremiumAccess}
        />
      )}
    </>
  );
};
