import { Box } from "@chakra-ui/react";
import React from "react";

import { Card } from "adminComponents/atoms/Card";
import {
  ITableData,
  ITableSort,
} from "adminComponents/molecules/SortableTable";
import { StudentPlacementTable } from "adminComponents/molecules/SortableTable/StudentPlacementTable";
import { pxToRem } from "adminComponents/utils/pxToRem";

interface StudentsPerformanceTabProps {
  studentsData: ITableData;
  handleStudentSortChange: (sortData: ITableSort) => void;
}

export const StudentsPerformanceTab: React.FC<StudentsPerformanceTabProps> = ({
  studentsData,
  handleStudentSortChange,
}) => {
  return (
    <Box py={[pxToRem(32), null, pxToRem(48)]}>
      <Card borderColor="primary.tan" variant="adminCardTable">
        <StudentPlacementTable
          data={studentsData}
          handleChangeSort={handleStudentSortChange}
        />
      </Card>
    </Box>
  );
};
