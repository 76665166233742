import {
  AnimationState,
  AnimationStateData,
  AnimationStateListener,
  AtlasAttachmentLoader,
  BoneData,
  SceneRenderer,
  Skeleton,
  SkeletonJson,
  SpineCanvas,
  TrackEntry,
  Vector2,
} from "@esotericsoftware/spine-webgl";

import { ISpineStageAsset } from "../types";
import { SpineStage } from "./SpineStage";

export enum TransitionType {
  NONE,
  SCALE,
}
export interface ISpineAvatarConstructorArgs {
  skeletonUrl: string;
  atlasUrl: string;
  x: number;
  y: number;
  scale: number;
  drawOrder?: number;
  animation: ISpineAvatarAnimation | null;
  animationStateListener: AnimationStateListener;
  onInitializedHandler?: IOnInitializedHandler;
  transitionType?: TransitionType;
}

export interface ISpineAvatarAnimationListenerConstructorArgs {
  onEnd?: ISpineAvatarAnimationHandler;
  onComplete?: ISpineAvatarAnimationHandler;
}

export interface ISpineAvatarAnimationHandler {
  (animationName: string): void;
}

export interface ISpineAvatarAnimation {
  name: string;
  loop: boolean;
  mixDuration?: number;
}

export interface IOnInitializedHandler {
  (): void;
}

export class SpineAvatarAnimationListener implements AnimationStateListener {
  onEnd?: ISpineAvatarAnimationHandler;
  onComplete?: ISpineAvatarAnimationHandler;

  constructor(args: ISpineAvatarAnimationListenerConstructorArgs) {
    this.onEnd = args.onEnd;
    this.onComplete = args.onComplete;
  }

  end(trackEntry: TrackEntry): void {
    if (!this.onEnd) return;
    if (!trackEntry.animation) return;

    this.onEnd(trackEntry.animation.name);
  }

  complete(trackEntry: TrackEntry): void {
    if (!this.onComplete) return;
    if (!trackEntry.animation) return;

    this.onComplete(trackEntry.animation.name);
  }
}

class SpineAvatar implements ISpineStageAsset {
  animationState: AnimationState | null;
  skeleton: Skeleton | null;
  bodyBone: BoneData | null;
  skeletonUrl: string;
  atlasUrl: string;
  x: number;
  y: number;
  scale: number;
  private currentTransitionScale: number;
  animationStateListener: AnimationStateListener;
  animation: ISpineAvatarAnimation | null;
  isInitialized: boolean;
  private onInitializedHandler?: IOnInitializedHandler;
  drawOrder: number;
  private haveCalledInitializedHandler: boolean;
  private isTransitioningOut: boolean;
  private transitionType: TransitionType;

  constructor({
    skeletonUrl,
    atlasUrl,
    x,
    y,
    scale,
    animationStateListener,
    animation,
    drawOrder,
    onInitializedHandler,
    transitionType = TransitionType.NONE,
  }: ISpineAvatarConstructorArgs) {
    this.animationState = null;
    this.skeleton = null;
    this.bodyBone = null;
    this.animationStateListener = animationStateListener;

    this.animation = animation;
    this.skeletonUrl = skeletonUrl;
    this.atlasUrl = atlasUrl;
    this.x = x;
    this.y = y;
    this.scale = scale;
    this.isInitialized = false;
    this.onInitializedHandler = onInitializedHandler;
    this.drawOrder = drawOrder || 0;
    this.currentTransitionScale =
      transitionType === TransitionType.SCALE ? 0 : 1;
    this.isTransitioningOut = false;
    this.haveCalledInitializedHandler = false;
    this.transitionType = transitionType;
  }

  onIntialized(handler: IOnInitializedHandler): void {
    this.onInitializedHandler = handler;
    if (this.isInitialized) handler();
  }

  async loadAssets(canvas: SpineCanvas): Promise<void> {
    await Promise.all([
      new Promise((resolve, reject) => {
        canvas.assetManager.loadText(this.skeletonUrl, resolve, reject);
      }),
      new Promise((resolve, reject) => {
        canvas.assetManager.loadTextureAtlas(this.atlasUrl, resolve, reject);
      }),
    ]);
  }

  dispose(): void {
    this.animationState?.removeListener(this.animationStateListener);
    this.animationState = null;
    this.skeleton = null;
  }

  async initialize(canvas: SpineCanvas): Promise<void> {
    try {
      const { assetManager } = canvas;
      const atlas = assetManager.require(this.atlasUrl);
      const atlasLoader = new AtlasAttachmentLoader(atlas);
      const skeletonJson = new SkeletonJson(atlasLoader);
      const skeletonData = skeletonJson.readSkeletonData(
        assetManager.require(this.skeletonUrl)
      );

      this.skeleton = new Skeleton(skeletonData);
      this.skeleton.setSkinByName("default");

      this.bodyBone = this.skeleton.data.findBone("root");

      const animationStateData = new AnimationStateData(skeletonData);
      animationStateData.defaultMix = 0.1;
      this.animationState = new AnimationState(animationStateData);

      this.setTransform(this.x, this.y, this.scale);

      this.animationState.clearListeners();
      this.animationState.addListener(this.animationStateListener);

      if (this.animation) {
        this.setAnimation(this.animation);
      }

      this.setSkeletonBounds();

      this.isInitialized = true;
      if (this.transitionType === TransitionType.NONE) {
        this.onInitializedHandler?.();
        this.haveCalledInitializedHandler = true;
      }
    } catch (err) {
      console.error("Could not initialize: " + err);
    }
  }

  setAnimation(animation: ISpineAvatarAnimation): void {
    const skeletonData = this.skeleton?.data;

    if (!skeletonData) return;

    const failSafeSkeletonAnimations = skeletonData.animations.filter((a) =>
      a.name.startsWith("idle")
    );

    const skeletonAnimation =
      // Try to get the one requested
      skeletonData.animations.find((a) => a.name === animation.name) ||
      // If that fails, try to use a fail-safe animation
      (failSafeSkeletonAnimations.length && failSafeSkeletonAnimations[0]) ||
      // If all hope is lost, use the first animation in the skeleton
      skeletonData.animations[0];

    const trackEntry = this.animationState?.setAnimation(
      0,
      skeletonAnimation.name,
      animation.loop
    );

    if (trackEntry && animation.mixDuration) {
      trackEntry.mixDuration = animation.mixDuration;
    }

    this.animation = animation;
  }

  setSkeletonBounds(): void {
    if (!this.skeleton) return;

    this.skeleton.setToSetupPose();
    this.skeleton.updateWorldTransform();
  }

  setTransform(x: number, y: number, scale: number): void {
    this.x = x;
    this.y = y;
    this.scale = scale;
  }

  getBounds(): { offset: Vector2; size: Vector2 } {
    const offset = new Vector2();
    const size = new Vector2();

    if (this.skeleton) {
      this.skeleton.getBounds(offset, size, undefined);
    }

    return { offset, size };
  }

  transitionOut(spineStage?: SpineStage): void {
    switch (this.transitionType) {
      case TransitionType.SCALE:
        this.isTransitioningOut = true;
        setTimeout(() => {
          spineStage?.unregisterAsset(this);
          this.dispose();
        }, 500);
        break;

      default:
        spineStage?.unregisterAsset(this);
        this.dispose();
    }
  }

  /**
   * Transforms the x, y arguments from a main body bone position
   * to a root bone position for positioning skeleton
   * @returns
   */
  getRootPosition(): { x: number; y: number } {
    const { x, y } = this;

    if (!this.bodyBone) return { x, y };

    if (
      this.transitionType === TransitionType.SCALE &&
      this.currentTransitionScale < 1
    ) {
      return {
        x: x - this.bodyBone.x * this.scale * this.currentTransitionScale,
        y: (y - this.bodyBone.y) * this.currentTransitionScale,
      };
    }

    return {
      x: x - this.bodyBone.x * this.scale,
      y: y - this.bodyBone.y * this.scale,
    };
  }

  update(
    canvas: SpineCanvas,
    delta: number,
    stageToWorldPosition: { x: number; y: number }
  ): void {
    if (!this.animationState) return;
    if (!this.skeleton) return;
    if (document.visibilityState !== "visible") return;

    const { x, y } = this.getRootPosition();

    this.skeleton.x = x * stageToWorldPosition.x;
    this.skeleton.y = y * stageToWorldPosition.y;

    if (
      this.transitionType === TransitionType.SCALE &&
      this.isTransitioningOut &&
      this.currentTransitionScale > 0
    ) {
      this.currentTransitionScale -= 0.01;
    } else if (
      this.transitionType === TransitionType.SCALE &&
      this.currentTransitionScale < 1
    ) {
      this.currentTransitionScale += 0.02;
    } else {
      if (this.isInitialized && !this.haveCalledInitializedHandler) {
        this.onInitializedHandler?.();
        this.haveCalledInitializedHandler = true;
      }
    }

    this.skeleton.scaleX = this.skeleton.scaleY =
      this.scale * this.currentTransitionScale;

    this.animationState.update(delta);
    this.animationState.apply(this.skeleton);
    this.skeleton.updateWorldTransform();
  }

  render(canvas: SpineCanvas, renderer: SceneRenderer): void {
    if (!this.skeleton || !this.isInitialized) return;
    renderer.drawSkeleton(this.skeleton, true);
  }
}

export { SpineAvatar };
