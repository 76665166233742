import { Flex, Spacer } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Avatar } from "adminComponents/atoms/Avatar";
import { Heading } from "adminComponents/atoms/Heading";
import { LoadingSpinner } from "adminComponents/atoms/LoadingSpinner";
import { Modal } from "adminComponents/atoms/Modal";
import { Text } from "adminComponents/atoms/Text";
import { generateStudentName, pxToRem } from "adminComponents/utils";
import { useFetchTopIndividualPracticers } from "links/lib/features/sessions";

export interface IProps {
  isOpen: boolean;
  onClose: () => void;
  totalIndividualSessions: number;
}

export const TopIndividualPracticersModal: React.FC<IProps> = ({
  isOpen,
  onClose,
  totalIndividualSessions,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  const fetchTopIndividualPracticers = useFetchTopIndividualPracticers({
    limit: 5,
  });

  return (
    <Modal
      showBackButton={false}
      isOpen={isOpen}
      onClose={onClose}
      title={t("teacherHomepageImproved.topIndividualPracticersModal.heading")}
      headingProps={{ variant: "adminH6", as: "h1" }}
    >
      <Flex flexDir="column" gap={pxToRem(32)}>
        {fetchTopIndividualPracticers.isLoading && <LoadingSpinner />}
        {!fetchTopIndividualPracticers.isLoading && (
          <Flex flexDir="column" gap={pxToRem(20)}>
            <Heading variant="adminH7" as="h2">
              {t(
                "teacherHomepageImproved.topIndividualPracticersModal.totalHeading",
                { count: totalIndividualSessions }
              )}
            </Heading>
            <Text variant="adminP2">
              {t(
                "teacherHomepageImproved.topIndividualPracticersModal.description_first"
              )}
            </Text>
            <Text variant="adminP2">
              {t(
                "teacherHomepageImproved.topIndividualPracticersModal.description_second"
              )}
            </Text>
            <Flex flexDir="column" gap={pxToRem(16)}>
              <Text variant="adminP2Bold">
                {t(
                  "teacherHomepageImproved.topIndividualPracticersModal.studentsListTitle"
                )}
              </Text>
              {fetchTopIndividualPracticers.data?.top_individual_practicers.map(
                (tp) => {
                  const name = generateStudentName(tp.user).primary;

                  return (
                    <Flex
                      key={tp.user.id}
                      flexDir="row"
                      alignItems="center"
                      gap={pxToRem(12)}
                    >
                      <Avatar
                        name={name}
                        src={tp.user.profile_image_url || ""}
                      />
                      <Text variant="adminP2">{name}</Text>
                      <Spacer />
                      <Text variant="adminP2">{tp.sessions_count}</Text>
                    </Flex>
                  );
                }
              )}
            </Flex>
          </Flex>
        )}
      </Flex>
    </Modal>
  );
};
