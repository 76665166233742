import { Box, BoxProps, useMultiStyleConfig } from "@chakra-ui/react";
import React from "react";

export const AvatarPreview: React.FC<BoxProps> = ({ children, ...props }) => {
  const styles = useMultiStyleConfig("AdminAvatarPreview", {});

  return (
    <Box {...props} __css={styles.container}>
      <Box>{children}</Box>
    </Box>
  );
};
