import { Link } from "@chakra-ui/react";
import React from "react";
import { Trans, useTranslation } from "react-i18next";

import { INSTANT_SET_SUPPORT_URL } from "links/lib/constants";

import { CTABanner } from "../CTABanner";

interface IProps {
  title?: string;
  description?: string;
  learnMoreLink?: string;
  handleGenerateInstantSet: () => void;
  isLoading?: boolean;
  enablePremiumTooltip?: boolean;
}

export const InstantSetBanner: React.FC<IProps> = ({
  title,
  description,
  learnMoreLink = INSTANT_SET_SUPPORT_URL,
  handleGenerateInstantSet,
  isLoading,
  enablePremiumTooltip,
}) => {
  const { t } = useTranslation("admin", {
    keyPrefix: "ctaBanner",
    useSuspense: false,
  });
  const defaultTitle = t("title");

  return (
    <CTABanner
      handleClick={handleGenerateInstantSet}
      buttonText={t("buttonText")}
      description={
        description || (
          <Trans i18nKey="description" t={t}>
            <Link target="_blank" color="utility.link" href={learnMoreLink}>
              Learn more
            </Link>
          </Trans>
        )
      }
      title={title && !isLoading ? title : defaultTitle}
      image="instantSet"
      enablePremiumTooltip={enablePremiumTooltip}
    />
  );
};
