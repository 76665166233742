import { Box, Flex } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { FormLabel } from "adminComponents/atoms/FormLabel";
import { Modal } from "adminComponents/atoms/Modal";
import { Switch } from "adminComponents/atoms/Switch";
import { SessionGroup } from "adminComponents/molecules/SessionGroupsModal/components/SessionGroup";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { ICustomSessionGroup, IUser } from "links/lib/types";

export interface IUseCustomSessionGroupsModalProps {
  isOpen: boolean;
  onClose: () => void;
  useSessionGroups: boolean;
  setUseSessionGroups: (selected: boolean) => void;
  customSessionGroups?: ICustomSessionGroup[];
  classroomUsers?: IUser[];
}

export const UseCustomSessionGroupsModal: React.FC<
  IUseCustomSessionGroupsModalProps
> = ({
  isOpen,
  onClose,
  useSessionGroups,
  setUseSessionGroups,
  customSessionGroups,
  classroomUsers,
}) => {
  const { t } = useTranslation("admin", {
    keyPrefix: "startSession.useCustomSessionGroups",
    useSuspense: false,
  });
  const { t: tCommon } = useTranslation("admin", {
    keyPrefix: "common",
    useSuspense: false,
  });

  const usersMap = useMemo(() => {
    const usersMap = new Map<string, IUser>();

    if (classroomUsers) {
      classroomUsers.forEach((user) => usersMap.set(user.id, user));
    }

    return usersMap;
  }, [classroomUsers]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      showBackButton={false}
      size="3xl"
      title={t("title")}
      headingProps={{
        as: "h2",
        textStyle: "gameHeaderText",
        variant: "adminH3",
      }}
    >
      <Box maxH="80vh" overflow="hidden">
        <Flex justifyContent="space-between">
          <FormLabel htmlFor="useSessionGroups">{t("label")}</FormLabel>
          <Switch
            id="useSessionGroups"
            isChecked={useSessionGroups}
            onChange={(e) => setUseSessionGroups(e.target.checked)}
          />
        </Flex>
        <Box paddingY={pxToRem(10)}>
          <FormLabel>{t("studentGroups")}</FormLabel>
        </Box>

        <Flex
          borderRadius="xl"
          border={`${pxToRem(2)} solid`}
          borderColor="primary.medium-gray"
          overflowY="scroll"
          maxH="50vh"
          flexDirection="column"
          gap={pxToRem(10)}
          p={pxToRem(15)}
          mb={pxToRem(15)}
        >
          {customSessionGroups?.map((customSessionGroup, i) => {
            const students = customSessionGroup.user_ids.flatMap(
              (userId) => usersMap.get(userId) || []
            );

            return (
              <SessionGroup
                key={customSessionGroup.id}
                selected={false}
                sessionGroupId={customSessionGroup.id}
                sessionGroupNumber={i + 1}
                showErrors={false}
                students={students}
                alwaysShowStudents
              />
            );
          })}
        </Flex>
        <Button onClick={onClose} variant="adminButtonOutlined">
          {tCommon("close")}
        </Button>
      </Box>
    </Modal>
  );
};
