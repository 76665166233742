import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { ExtendIndividualTrialScreen } from "adminComponents/screens/ExtendIndividualTrialScreen";
import { useErrorToast } from "adminComponents/utils/toast";
import {
  useExtendUserTrial,
  useFetchUserTrialExtensionWithStats,
} from "links/lib/features/userTrialExtensions";
import { ExtendabilityReason } from "links/lib/features/userTrialExtensions/useFetchUserTrialExtensionWithStats";

export const UserTrialExtension: React.FC = () => {
  const { t } = useTranslation("admin", {
    keyPrefix: "extendIndividualTrialScreen",
  });
  const { guid } = useParams<{ guid: string }>();

  const fetchUserTrialExtensionWithStats = useFetchUserTrialExtensionWithStats({
    guid,
  });
  const extendUserTrial = useExtendUserTrial();

  useErrorToast(extendUserTrial.error);

  const onExtendIndividualTrial = () => {
    extendUserTrial.mutate({ guid });
  };

  const cannotExtendMessage = useMemo(() => {
    switch (fetchUserTrialExtensionWithStats.data?.reason) {
      case ExtendabilityReason.ALREADY_EXTENDED:
        return t("descriptionCannotExtendLicense");
      case ExtendabilityReason.LIMIT_REACHED:
        return t("descriptionCannotExtendLimitReached");
      case ExtendabilityReason.EXPIRED:
        return t("descriptionExtendExpired");
      case ExtendabilityReason.STACKING_PREVENTED:
        return t("descriptionStackingPrevented");
      case ExtendabilityReason.INCORRECT_DOMAIN:
        return t("descriptionIncorrectDomain");
      case ExtendabilityReason.INCORRECT_ORGANIZATION:
        return t("descriptionIncorrectOrganization");
      case ExtendabilityReason.USER_TRIAL_ALREADY_PAST_EXTENSION_DATE:
        return t("descriptionAlreadyLongerTrial");
      case ExtendabilityReason.CAN_EXTEND:
        return "";
      case ExtendabilityReason.INVALID:
        return t("descriptionError");
      default:
        return t("descriptionError");
    }
  }, [fetchUserTrialExtensionWithStats.data?.reason, t]);

  return (
    <ExtendIndividualTrialScreen
      hasExtendedTrial={extendUserTrial.isSuccess}
      handleExtendTrial={onExtendIndividualTrial}
      canExtendTrial={
        fetchUserTrialExtensionWithStats.data?.can_user_extend || false
      }
      isLoading={fetchUserTrialExtensionWithStats.isLoading}
      extensionDays={
        fetchUserTrialExtensionWithStats.data?.extension_days ||
        extendUserTrial.data?.extension_days ||
        0
      }
      descriptionCannotExtendLicense={cannotExtendMessage}
    />
  );
};
