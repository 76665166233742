import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { IClassroom, IUser, ThirdParty } from "../../types";

export interface IMutateClassroomArgs {
  id?: string;
  assignment_notifications_disabled: boolean;
  name: string;
  description?: string;
  third_party?: ThirdParty;
  third_party_id?: string;
  group_subjects?: Array<IMutateClassroomSubject>;
  group_grade_levels?: Array<IMutateClassroomGradeLevel>;
  users?: Array<IUser>;
  disableNotifications?: boolean;
}

export interface IMutateClassroomResponse {
  classroom: IClassroom;
}

export interface IMutateClassroomGradeLevel {
  grade_level_id: string;
}

export interface IMutateClassroomSubject {
  subject_id: string;
}

interface IMutateGroupResponse {
  group: IClassroom;
}

export interface IUseMutateClassroomArgs {
  onSuccess?: (data: IMutateClassroomResponse) => void;
  onError?: () => void;
  onSettled?: () => void;
}

/**
 * Create or update a classroom
 * @returns
 */
export default function useMutateClassroom({
  onSettled,
  onError,
  onSuccess,
}: IUseMutateClassroomArgs): UseMutationResult<
  IMutateClassroomResponse,
  unknown,
  IMutateClassroomArgs
> {
  const axios = useAxios();
  const queryClient = useQueryClient();

  const mutateClassroom = async (args: IMutateClassroomArgs) => {
    const {
      id,
      description,
      name,
      group_grade_levels,
      group_subjects,
      users,
      third_party,
      third_party_id,
      assignment_notifications_disabled,
      disableNotifications = false,
    } = args;

    const response = await axios({
      method: id ? "put" : "post",
      url: `/v1/groups${id ? "/" + id : ""}`,
      data: {
        group: {
          name,
          description,
          group_grade_levels,
          group_subjects,
          third_party,
          third_party_id,
          assignment_notifications_disabled,
        },
        users,
        disableNotifications,
      },
    })
      .then((r) => {
        const data = r.data as IMutateGroupResponse;
        return { data: { classroom: data.group } };
      })
      .then((r) => r.data as IMutateClassroomResponse);

    queryClient.invalidateQueries("classrooms");
    queryClient.setQueryData(["classrooms", { id: id }], {
      classroom: response.classroom,
    });

    return response;
  };

  const mutation = useMutation(mutateClassroom, {
    onSettled,
    onSuccess,
    onError,
  });

  return mutation;
}
