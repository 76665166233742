import { Flex } from "@chakra-ui/react";
import React from "react";

import { pxToRem } from "adminComponents/utils/pxToRem";

import { CertifiedPracticeSetBanner } from "./CertifiedPracticeSetBanner";
import { PremiumPracticeSetBanner } from "./PremiumPracticeSetBanner";

export interface IProps {
  withCertifiedText?: boolean;
  isCertified: boolean;
  withPremiumText?: boolean;
  isPremium: boolean;
  showPremiumMarker?: boolean;
}

export const Banner: React.FC<IProps> = ({
  withCertifiedText = false,
  withPremiumText = false,
  isCertified,
  isPremium,
  showPremiumMarker = true,
}) => {
  return (
    <Flex
      borderTopLeftRadius={pxToRem(17)}
      borderBottomRightRadius={pxToRem(8)}
      backgroundColor="utility.premium"
      marginTop={pxToRem(0.02)}
      marginLeft={pxToRem(2)}
      height={pxToRem(32)}
      zIndex={2}
    >
      {isCertified ? (
        <CertifiedPracticeSetBanner withText={withCertifiedText} />
      ) : (
        <span />
      )}
      {showPremiumMarker && isPremium ? (
        <PremiumPracticeSetBanner withText={withPremiumText} />
      ) : (
        <span />
      )}
    </Flex>
  );
};
