import {
  Box,
  Flex,
  HStack,
  List,
  ListItem,
  Square,
  Text,
  useBreakpointValue,
  useDisclosure,
  usePrefersReducedMotion,
} from "@chakra-ui/react";
import JSConfetti from "js-confetti";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { animated, config, useSpring } from "react-spring";
import { useLocalStorage, usePrevious } from "react-use";

import { Card } from "adminComponents/atoms/Card";
import { Heading } from "adminComponents/atoms/Heading";
import { Icon } from "adminComponents/atoms/Icon";
import { ProgressBar } from "adminComponents/atoms/ProgressBar";
import { SubmarkLogo } from "adminComponents/atoms/SubmarkLogo";
import { ConfirmModal } from "adminComponents/molecules/ConfirmModal";
import { IconButtonDropdown } from "adminComponents/molecules/IconButtonDropdown";
import { pxToRem } from "adminComponents/utils";
import {
  localStoreHasCompletedOnboardingKeyName,
  localStoreHideOnboardingUntilKeyName,
} from "links/lib/constants";
import { useAuth } from "links/lib/features/auth";
import { useFetchClassrooms } from "links/lib/features/classrooms";
import { useFetchTeacherOnboardingChecklist } from "links/lib/features/users";
import { IClassroom, ITeacherOnboardingChecklist } from "links/lib/types";

const AnimatedBox = animated(Box);

interface IArgs {
  // If true, use the non-checklist version of onboarding (banners that
  // appear on the explore page)
  useBannerOnboarding?: boolean;
}

export interface IUseOnboardingChecklistResult {
  teacherOnboardingChecklist?: ITeacherOnboardingChecklist;
  checklistElement: JSX.Element;
}

export const useOnboardingChecklist = ({
  useBannerOnboarding,
}: IArgs): IUseOnboardingChecklistResult => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });
  const { authUser } = useAuth();
  const prefersReducedMotion = usePrefersReducedMotion();
  const {
    isOpen: isConfirmModalOpen,
    onClose: onCloseConfirmModal,
    onOpen: onOpenConfirmModal,
  } = useDisclosure();
  const checklistWidth = useBreakpointValue({
    base: "92%",
    md: "50%",
    lg: "30%",
  });

  const classroomFetch = useFetchClassrooms({});

  const autoCreatedClassroom: IClassroom | undefined = useMemo(() => {
    if (classroomFetch.data?.classrooms?.length === 1) {
      return classroomFetch.data.classrooms[0];
    }

    let groupName = "";
    if (authUser?.teacher_preferred_name?.endsWith("s")) {
      groupName = t("teacherTour.classPossessiveEndsWithS", {
        name: authUser?.teacher_preferred_name,
      });
    } else {
      groupName = t("teacherTour.classPossessive", {
        name: authUser?.teacher_preferred_name,
      });
    }

    groupName = "Dr. Lasterson's Class";

    return classroomFetch.data?.classrooms?.find(
      (classroom) => classroom.name === groupName
    );
  }, [authUser?.teacher_preferred_name, classroomFetch.data?.classrooms, t]);

  const [hasCompletedOnboarding, setHasCompletedOnboarding] = useState(true);
  const [
    localStoreHasCompletedOnboarding,
    setLocalStoreHasCompletedOnboarding,
  ] = useLocalStorage(localStoreHasCompletedOnboardingKeyName, false);
  const [localStoreHideOnboardingUntil, setLocalStoreHideOnboardingUntil] =
    useLocalStorage<string | undefined>(
      localStoreHideOnboardingUntilKeyName,
      undefined
    );
  const hideOnboardingUntil = localStoreHideOnboardingUntil
    ? moment(localStoreHideOnboardingUntil)
    : undefined;

  const fetchOnboardingChecklist = useFetchTeacherOnboardingChecklist({
    userId: authUser?.id,
    disabled: !authUser?.id,
  });
  const teacherOnboardingChecklist = fetchOnboardingChecklist.data;
  const prevTeacherOnboardingChecklist = usePrevious(
    teacherOnboardingChecklist
  );

  const shouldHideOnboardingChecklist = useMemo(() => {
    return (
      hasCompletedOnboarding ||
      localStoreHasCompletedOnboarding ||
      (hideOnboardingUntil && moment().isBefore(hideOnboardingUntil))
    );
  }, [
    hasCompletedOnboarding,
    hideOnboardingUntil,
    localStoreHasCompletedOnboarding,
  ]);

  const handleConfirmHideForever = (isConfirmed?: boolean) => {
    onCloseConfirmModal();
    if (!isConfirmed) return;
    setLocalStoreHasCompletedOnboarding(true);
  };

  const handleRemindMeLater = () => {
    setLocalStoreHideOnboardingUntil(moment().add(3, "days").toString());
  };

  useEffect(() => {
    if (useBannerOnboarding) return;
    if (!teacherOnboardingChecklist) return;

    // First load, check if onboarding is already complete and hide checklist if so
    if (!!teacherOnboardingChecklist && !prevTeacherOnboardingChecklist) {
      if (Object.values(teacherOnboardingChecklist).every((v) => !!v)) {
        setLocalStoreHasCompletedOnboarding(true);
      } else {
        setHasCompletedOnboarding(false);
      }
    }

    if (!prevTeacherOnboardingChecklist) return;

    if (
      Object.values(prevTeacherOnboardingChecklist).some((v) => !v) &&
      Object.values(teacherOnboardingChecklist).every((v) => !!v)
    ) {
      const jsConfetti = new JSConfetti();
      jsConfetti.addConfetti({
        confettiColors: ["#E960B1", "#FFC629", "#696BA6", "#2DB2A5"],
      });
      setTimeout(() => setLocalStoreHasCompletedOnboarding(true), 3000);
    }
  }, [
    useBannerOnboarding,
    prevTeacherOnboardingChecklist,
    setLocalStoreHasCompletedOnboarding,
    teacherOnboardingChecklist,
  ]);

  const progressValue = useMemo(() => {
    let progress = 0;
    if (teacherOnboardingChecklist === undefined) return progress;

    Object.values(teacherOnboardingChecklist).forEach((value) => {
      if (value) {
        progress += 25;
      }
    });
    return progress;
  }, [teacherOnboardingChecklist]);

  const opacitySpring = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: shouldHideOnboardingChecklist ? 0 : 1,
    },
    immediate: prefersReducedMotion,
    config: config.gentle,
  });

  const checklistElement = shouldHideOnboardingChecklist ? (
    <></>
  ) : (
    <>
      <AnimatedBox w="fit-content" style={opacitySpring}>
        <Card
          variant="adminCardMediumBorder"
          bgColor="primary.white"
          borderColor="primary.tan"
          position="fixed"
          bottom="1rem"
          left="1rem"
          w={checklistWidth}
          zIndex="11"
        >
          <Box position="absolute" top="1rem" right="1rem">
            <IconButtonDropdown
              iconVariant="outline"
              menuItems={[
                {
                  label: t("teacherOnboardingChecklist.menuItemRemindMeLater"),
                  handleClick: () => handleRemindMeLater(),
                },
                {
                  label: t("teacherOnboardingChecklist.menuItemHideForever"),
                  handleClick: () => {
                    onOpenConfirmModal();
                  },
                },
              ]}
            />
          </Box>
          <Flex flexDirection="column">
            <HStack gap={{ base: 0, md: pxToRem(8) }}>
              <Box boxSize={{ base: pxToRem(50), lg: pxToRem(100) }}>
                <SubmarkLogo />
              </Box>
              <Heading variant="adminH3" as="h2">
                {t("teacherOnboardingChecklist.letsGetPracticing")}
              </Heading>
            </HStack>
            <Box display={{ base: "none", lg: "block" }}>
              <ProgressBar
                background="primary.light-gray"
                variant="adminStudentGoal"
                value={progressValue}
                icon={{
                  boxSize: pxToRem(50),
                  iconName: "star_outlined",
                }}
              />
            </Box>
            <List
              mt={{ base: pxToRem(12), md: undefined }}
              pl={pxToRem(8)}
              spacing={pxToRem(12)}
            >
              <OnboardingChecklistItem
                isComplete={!!teacherOnboardingChecklist?.has_completed_profile}
                itemText={t("teacherOnboardingChecklist.completeProfile")}
                linkTo="/t/account"
              />
              <OnboardingChecklistItem
                isComplete={!!teacherOnboardingChecklist?.has_invited_students}
                itemText={t("teacherOnboardingChecklist.inviteStudents")}
                linkTo={
                  autoCreatedClassroom
                    ? `/t/classrooms/${autoCreatedClassroom.id}/students`
                    : "/t/classrooms"
                }
              />
              <OnboardingChecklistItem
                isComplete={!!teacherOnboardingChecklist?.has_copied_public_set}
                itemText={t("teacherOnboardingChecklist.copyPublicSet")}
                linkTo="/t/public-library"
              />
              <OnboardingChecklistItem
                isComplete={
                  !!teacherOnboardingChecklist?.has_started_live_practice ||
                  !!teacherOnboardingChecklist?.has_created_assignment
                }
                itemText={t(
                  "teacherOnboardingChecklist.startLivePracticeOrCreateAssignment"
                )}
                linkTo={
                  autoCreatedClassroom
                    ? `/t/classrooms/${autoCreatedClassroom.id}/assignments`
                    : "/t/classrooms"
                }
              />
            </List>
          </Flex>
        </Card>
      </AnimatedBox>
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        isLoading={false}
        buttonCancelLabel={t("common.cancel")}
        buttonOKLabel={t("common.confirm")}
        handleClose={handleConfirmHideForever}
        mobileButtonsDirection="vertical"
        size="2xl"
        description={t("teacherOnboardingChecklist.confirmHideForever")}
        title={t("teacherOnboardingChecklist.confirmHideForeverTitle")}
        variant="adminModalCompactMobile"
      />
    </>
  );

  return { teacherOnboardingChecklist, checklistElement };
};

const OnboardingChecklistItem: React.FC<{
  isComplete: boolean;
  itemText: string;
  linkTo?: string;
}> = ({ isComplete, itemText, linkTo }) => {
  const history = useHistory();

  return (
    <ListItem>
      <HStack gap={pxToRem(8)}>
        <Square
          alignSelf="start"
          size={pxToRem(32)}
          bgColor={isComplete ? "utility.focus" : undefined}
          borderRadius={pxToRem(12)}
          borderWidth={isComplete ? undefined : pxToRem(1)}
          borderColor={isComplete ? undefined : "primary.medium-gray"}
        >
          {isComplete && <Icon icon="check" color="primary.warm-black" />}
        </Square>
        <Text
          color="utility.link"
          cursor={linkTo ? "pointer" : undefined}
          textDecoration="underline"
          fontSize={pxToRem(24)}
          fontFamily="TTCommons"
          lineHeight={pxToRem(32)}
          onClick={linkTo ? () => history.push(linkTo) : undefined}
        >
          {itemText}
        </Text>
        <Icon icon="keyboard_arrow_right" color="utility.focus" />
      </HStack>
    </ListItem>
  );
};
