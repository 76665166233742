import { usePrefersReducedMotion } from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { config, useSpring } from "react-spring";
import { useMount } from "react-use";

import { SessionSplashAnimationNames } from "links/lib/types";

export interface IUseRoundSplashAnimationArgs {
  reverse: boolean;
  animationType: SessionSplashAnimationNames;
}

export interface IUseRoundSplashAnimationVariantArgs {
  reverse: boolean;
  mainRef: React.RefObject<HTMLHeadingElement>;
  secondaryRef: React.RefObject<HTMLHeadingElement>;
}

export interface IUseRoundSplashAnimationResult {
  style: {
    main: Record<string, unknown>;
    secondary: Record<string, unknown>;
  };
  mainRef: React.RefObject<HTMLHeadingElement>;
  secondaryRef: React.RefObject<HTMLHeadingElement>;
}

const useLatch = (
  args: IUseRoundSplashAnimationVariantArgs
): IUseRoundSplashAnimationResult => {
  const { reverse, mainRef, secondaryRef } = args;

  const rotateAmount = 30;

  const [isReady, setReady] = useState(false);
  const mainTranslateRef = useRef<number>(0);
  const secondaryTranslateRef = useRef<number>(0);

  const mainSpring = useSpring({
    to: { opacity: 1, yr: [0, 0] },
    from: {
      yr: [mainTranslateRef.current, -rotateAmount],
      opacity: 0,
    },
    reverse,
    cancel: !isReady,
    delay: () => (reverse ? 200 : 0),
    config: {
      ...config.wobbly,
      mass: 1.5,
      friction: 20,
    },
  });

  const secondarySpring = useSpring({
    to: { opacity: 1, yr: [0, 0] },
    from: {
      yr: [secondaryTranslateRef.current, -rotateAmount],
      opacity: 0,
    },
    reverse,
    cancel: !isReady,
    delay: () => (reverse ? 0 : 200),
    config: {
      ...config.wobbly,
      mass: 1.5,
      friction: 20,
    },
  });

  const calculateOffsets = (width: number, height: number, angle: number) => {
    const y = getTanFromDegrees(angle) * width * 0.5;

    return {
      y,
    };
  };

  const getTanFromDegrees = (degrees: number) =>
    Math.tan((degrees * Math.PI) / 180);

  useMount(() => {
    if (mainRef.current) {
      const { clientWidth, clientHeight } = mainRef.current;

      const { y } = calculateOffsets(clientWidth, clientHeight, rotateAmount);

      mainTranslateRef.current = -y;
    }

    if (secondaryRef.current) {
      const { clientWidth, clientHeight } = secondaryRef.current;

      const { y } = calculateOffsets(clientWidth, clientHeight, rotateAmount);

      secondaryTranslateRef.current = y;
    }

    setReady(true);
  });

  return {
    mainRef,
    secondaryRef,
    style: {
      main: {
        opacity: mainSpring.opacity,
        transform: mainSpring.yr.to(
          (y, r) => `translate(0px, ${y}px) rotate(${r}deg)`
        ),
      },
      secondary: {
        opacity: secondarySpring.opacity,
        transform: secondarySpring.yr.to(
          (y, r) => `translate(0px, ${y}px) rotate(${r}deg)`
        ),
      },
    },
  };
};

const useVerticalClap = (
  args: IUseRoundSplashAnimationVariantArgs
): IUseRoundSplashAnimationResult => {
  const { reverse, mainRef, secondaryRef } = args;

  const mainSpring = useSpring({
    to: { opacity: 1, p: [0] },
    from: { p: [-200], opacity: 0 },
    reverse,
    delay: () => (reverse ? 200 : 0),
    config: {
      ...config.wobbly,
      mass: 1.5,
      friction: 20,
    },
  });

  const secondarySpring = useSpring({
    to: { opacity: 1, p: [0] },
    from: { p: [200], opacity: 0 },
    reverse,
    delay: () => (reverse ? 0 : 200),
    config: {
      ...config.wobbly,
      mass: 1.2,
      friction: 20,
    },
  });

  return {
    style: {
      main: {
        opacity: mainSpring.opacity,
        transform: mainSpring.p.to((p) => `translateY(${p}px)`),
      },
      secondary: {
        opacity: secondarySpring.opacity,
        transform: secondarySpring.p.to((p) => `translateY(${p}px)`),
      },
    },
    mainRef,
    secondaryRef,
  };
};

const useJump = (
  args: IUseRoundSplashAnimationVariantArgs
): IUseRoundSplashAnimationResult => {
  const { reverse, mainRef, secondaryRef } = args;

  const mainSpring = useSpring({
    to: { opacity: 1, p: [0] },
    from: { p: [200], opacity: 0 },
    reverse,
    delay: () => (reverse ? 200 : 0),
    config: {
      ...config.wobbly,
      mass: 1.5,
      friction: 20,
    },
  });

  const secondarySpring = useSpring({
    to: { opacity: 1, p: [0] },
    from: { p: [300], opacity: 0 },
    reverse,
    delay: () => (reverse ? 0 : 200),
    config: {
      ...config.wobbly,
      mass: 1.2,
      friction: 20,
    },
  });

  return {
    style: {
      main: {
        opacity: mainSpring.opacity,
        transform: mainSpring.p.to((p) => `translateY(${p}px)`),
      },
      secondary: {
        opacity: secondarySpring.opacity,
        transform: secondarySpring.p.to((p) => `translateY(${p}px)`),
      },
    },
    mainRef,
    secondaryRef,
  };
};

const useSwoosh = (
  args: IUseRoundSplashAnimationVariantArgs
): IUseRoundSplashAnimationResult => {
  const { reverse, mainRef, secondaryRef } = args;

  const mainSpring = useSpring({
    to: { opacity: 1, sp: [0, 0] },
    from: { sp: [30, -200], opacity: 0 },
    reverse,
    delay: () => (reverse ? 200 : 0),
    config: {
      ...config.wobbly,
      mass: 1.5,
      friction: 20,
    },
  });

  const secondarySpring = useSpring({
    to: { opacity: 1, sp: [0, 0] },
    from: { sp: [-30, 200], opacity: 0 },
    reverse,
    delay: () => (reverse ? 0 : 200),
    config: {
      ...config.wobbly,
      mass: 1.5,
      friction: 20,
    },
  });

  return {
    style: {
      main: {
        opacity: mainSpring.opacity,
        transform: mainSpring.sp.to(
          (s, p) => `translateX(${p}px) skewX(${s}deg)`
        ),
      },
      secondary: {
        opacity: secondarySpring.opacity,
        transform: secondarySpring.sp.to(
          (s, p) => `translateX(${p}px) skewX(${s}deg)`
        ),
      },
    },
    mainRef,
    secondaryRef,
  };
};

const useFade = (
  args: IUseRoundSplashAnimationVariantArgs
): IUseRoundSplashAnimationResult => {
  const { reverse, mainRef, secondaryRef } = args;

  const mainSpring = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    reverse,
    delay: () => (reverse ? 200 : 0),
    config: config.default,
  });

  const secondarySpring = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    reverse,
    delay: () => (reverse ? 0 : 200),
    config: config.default,
  });

  return {
    style: {
      main: {
        opacity: mainSpring.opacity,
      },
      secondary: {
        opacity: secondarySpring.opacity,
      },
    },
    mainRef,
    secondaryRef,
  };
};

export const useRoundSplashAnimation = (
  args: IUseRoundSplashAnimationArgs
): IUseRoundSplashAnimationResult => {
  const prefersReducedMotion = usePrefersReducedMotion();

  const mainRef = useRef<HTMLHeadingElement>(null);
  const secondaryRef = useRef<HTMLHeadingElement>(null);

  const variantArgs = {
    reverse: args.reverse,
    mainRef,
    secondaryRef,
  };

  const useFadeResult = useFade(variantArgs);
  const useLatchResult = useLatch(variantArgs);
  const useVerticalClapResult = useVerticalClap(variantArgs);
  const useJumpResult = useJump(variantArgs);
  const useSwooshResult = useSwoosh(variantArgs);

  //TODO fix these eslint errors
  if (prefersReducedMotion) {
    /*eslint-disable-next-line*/
    // return useFade(variantArgs);
    return useFadeResult;
  }

  switch (args.animationType) {
    case SessionSplashAnimationNames.Latch:
      /*eslint-disable-next-line*/
      // return useLatch(variantArgs);
      return useLatchResult;
    case SessionSplashAnimationNames.VerticalClap:
      /*eslint-disable-next-line*/
      // return useVerticalClap(variantArgs);
      return useVerticalClapResult;
    case SessionSplashAnimationNames.Jump:
      /*eslint-disable-next-line*/
      // return useJump(variantArgs);
      return useJumpResult;
    case SessionSplashAnimationNames.Swoosh:
      /*eslint-disable-next-line*/
      // return useSwoosh(variantArgs);
      return useSwooshResult;
    default:
      throw new Error("Not Implemented");
  }
};
