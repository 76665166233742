import { Box } from "@chakra-ui/react";
import React from "react";

import { LoadingSpinner } from "adminComponents/atoms/LoadingSpinner";
import { QuestionsTab as PracticeSetDetailQuestionsTab } from "adminComponents/screens/StudentPracticeSetReportScreen/components/QuestionsTab";
import { pxToRem } from "adminComponents/utils";
import { usePageTrack } from "lib/contexts/analytics";
import { useAuth } from "links/lib/features/auth";
import { IFetchPracticeSetItemsResponse } from "links/lib/features/practiceSetItems/useFetchPracticeSetItems";
import { AnalyticsPage, IUser } from "links/lib/types";
import { useStudentQuestionsData } from "screens/TeacherClassrooms/PracticeSetDetail/components/PracticeSetStudentDetail/hooks/useStudentQuestionData";

interface IQuestionsTabProps {
  practiceSetItemsData?: IFetchPracticeSetItemsResponse;
  classroomId: string;
  practiceSetId: string;
  student?: IUser;
  assignmentId?: string;
  practiceSetSessionId?: string;
}

export const QuestionsTab: React.FC<IQuestionsTabProps> = ({
  classroomId,
  practiceSetId,
  practiceSetItemsData,
  student,
  assignmentId,
  practiceSetSessionId,
}) => {
  const { hasNoPremiumAccess } = useAuth();
  usePageTrack(
    AnalyticsPage.TeacherDashboard_ClassroomDetail_PracticeSetStudentDetail_Questions
  );

  const { isLoading, questionsData } = useStudentQuestionsData({
    classroomId,
    practiceSetId,
    practiceSetItemsData,
    student,
    includeAllSessions: false,
    assignmentId,
    practiceSetSessionId,
  });

  return (
    <>
      {isLoading ? (
        <Box mt={pxToRem(20)}>
          <LoadingSpinner />
        </Box>
      ) : (
        <PracticeSetDetailQuestionsTab
          questions={questionsData}
          hasNoPremiumAccess={hasNoPremiumAccess}
        />
      )}
    </>
  );
};
