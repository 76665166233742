import { AspectRatio, Box } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Card } from "adminComponents/atoms/Card";
import { Heading } from "adminComponents/atoms/Heading";
import {
  IProps as INavigationProps,
  StudentNavigationHeader,
} from "adminComponents/molecules/StudentNavigationHeader";
import { TemplateWithCenteredHeroOneColumn } from "adminComponents/templates/TemplateWithCenteredHeroOneColumn";
import { pxToRem } from "adminComponents/utils";

export interface IStudentGettingStartedScreenProps {
  navigationData: INavigationProps;
}

export const StudentGettingStartedScreen: React.FC<
  IStudentGettingStartedScreenProps
> = ({ navigationData }) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  return (
    <TemplateWithCenteredHeroOneColumn
      heroContent={
        <Heading as="h1" variant="adminH2" textAlign="center">
          {t("studentGettingStartedScreen.heading")}
        </Heading>
      }
      nav={<StudentNavigationHeader {...navigationData} />}
    >
      <Card
        variant="adminCardMediumBorder"
        borderColor="primary.tan"
        bg="primary.tan"
        p={pxToRem(14)}
      >
        <AspectRatio ratio={16 / 9}>
          <Box
            as="iframe"
            src="https://fast.wistia.net/embed/iframe/zepfl8kwwu"
            frameBorder="0"
            scrolling="no"
            allowFullScreen
            width="100%"
            height="auto"
          />
        </AspectRatio>
      </Card>
    </TemplateWithCenteredHeroOneColumn>
  );
};
