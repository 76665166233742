import {
  Box,
  FormControl,
  HStack,
  ListItem,
  UnorderedList,
  useBreakpointValue,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import React, { SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Checkbox } from "adminComponents/atoms/Checkbox";
import { FormErrorMessage } from "adminComponents/atoms/FormErrorMessage";
import { Icon } from "adminComponents/atoms/Icon";
import { Modal } from "adminComponents/atoms/Modal";
import { Text } from "adminComponents/atoms/Text";
import { Textarea } from "adminComponents/atoms/Textarea";
import { pxToRem } from "adminComponents/utils";

interface AddStudentsViaEmailsModal {
  isOpen: boolean;
  handleClose: () => void;
  handleAddStudents: (emails: string) => void;
  errorString?: string;
  isLoading?: boolean;
  disableNotifications: boolean;
  setDisableNotifications: (disableNotifications: boolean) => void;
}

export const AddStudentsViaEmailsModal: React.FC<AddStudentsViaEmailsModal> = ({
  isOpen,
  handleClose,
  handleAddStudents,
  errorString,
  isLoading,
  disableNotifications,
  setDisableNotifications,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const styles = useMultiStyleConfig("AdminAddStudentsViaEmailsModal", {});
  const hasError = !!errorString;

  const isMobile = useBreakpointValue({ base: true, md: false });

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      emails: { value: string };
    };
    const emailsString = target.emails.value;
    handleAddStudents(emailsString);
  };

  const addStudentsButtonText =
    isMobile || disableNotifications
      ? t("addStudentsViaEmailsModal.addStudentsShort")
      : t("addStudentsViaEmailsModal.addStudents");

  let emailIcon: JSX.Element | undefined = <Icon icon="mail_outlined" />;
  if (isMobile || disableNotifications) {
    emailIcon = undefined;
  }

  return (
    <Modal
      variant="adminModalCompactMobile"
      title={t("addStudentsViaEmailsModal.addStudents")}
      isOpen={isOpen}
      onClose={handleClose}
      showBackButton={false}
      headingProps={{ as: "h2", variant: "adminH6" }}
    >
      <Text size="lg" variant="adminP1">
        {t("addStudentsViaEmailsModal.instructions")}
      </Text>

      <form onSubmit={handleSubmit}>
        <FormControl isDisabled={isLoading} isInvalid={hasError}>
          <Box sx={styles.textareaContainer}>
            <Text size="md" variant="adminP1" pb={pxToRem(10)}>
              {t("addStudentsViaEmailsModal.label")}
            </Text>
            <Textarea name="emails" />
            <FormErrorMessage>{errorString}</FormErrorMessage>
            <UnorderedList sx={styles.list}>
              <ListItem>
                <Text size="md" variant="adminP1">
                  {t("addStudentsViaEmailsModal.bullet1")}
                </Text>
              </ListItem>
              <ListItem>
                <Text size="md" variant="adminP1">
                  {t("addStudentsViaEmailsModal.bullet2")}
                </Text>
              </ListItem>
              <ListItem>
                <Text size="md" variant="adminP1">
                  {t("addStudentsViaEmailsModal.bullet3")}
                </Text>
              </ListItem>
            </UnorderedList>
          </Box>
        </FormControl>
        <Box paddingBottom={pxToRem(20)}>
          <Checkbox
            isChecked={disableNotifications}
            onChange={(e) => setDisableNotifications(e.target.checked)}
          >
            {t("common.sendEmails")}
          </Checkbox>
        </Box>
        <HStack w="full">
          <Button
            sx={styles.button}
            type="submit"
            isLoading={isLoading}
            variant={hasError ? "adminButtonOutlined" : "adminButtonFilled"}
            leftIcon={emailIcon}
          >
            {hasError ? t("common.tryAgain") : addStudentsButtonText}
          </Button>
          <Button
            sx={styles.button}
            variant={"adminButtonOutlined"}
            onClick={handleClose}
          >
            {t("common.cancel")}
          </Button>
        </HStack>
      </form>
    </Modal>
  );
};
