import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";

export interface IFetchTopOrganizationAssignmentCreatorsParams {
  days_ago: number;
  limit?: number;
}

interface TopOrganizationAssignmentCreator {
  user_id: string;
  assignments_count: string;
}

export interface IFetchTopOrganizationAssignmentCreatorsResponse {
  top_organization_assignment_creators: Array<TopOrganizationAssignmentCreator>;
}

export default function useFetchTopOrgAssignmentCreators({
  days_ago,
  limit = 9,
}: IFetchTopOrganizationAssignmentCreatorsParams): UseQueryResult<
  IFetchTopOrganizationAssignmentCreatorsResponse,
  unknown
> {
  const axios = useAxios();

  const query = useQuery<IFetchTopOrganizationAssignmentCreatorsResponse>(
    ["top-organization-assignment-creators", { days_ago, limit }],
    async (): Promise<IFetchTopOrganizationAssignmentCreatorsResponse> => {
      return await axios
        .get(`/v1/organizations/assignments/top-creators`, {
          params: {
            days_ago,
            limit,
          },
        })
        .then((r) => {
          return r.data as IFetchTopOrganizationAssignmentCreatorsResponse;
        });
    }
  );

  return query;
}
