import { countryMap, regionMap } from "./constants";

export const sortedCountries = Object.keys(countryMap)
  .sort((a, b) => {
    const ca = countryMap[a];
    const cb = countryMap[b];
    if (ca < cb) {
      return -1;
    }
    if (ca > cb) {
      return 1;
    }
    return 0;
  })
  .map((code) => {
    return {
      code,
      name: countryMap[code],
    };
  });

export const getRegionsForCountry = (
  country: string
): Array<{ code: string; name: string }> => {
  return !regionMap[country]
    ? []
    : Object.keys(regionMap[country])
        .sort((a, b) => {
          const ra = regionMap[country][a];
          const rb = regionMap[country][b];
          if (ra < rb) {
            return -1;
          }
          if (ra > rb) {
            return 1;
          }
          return 0;
        })
        .map((code) => {
          return {
            code,
            name: regionMap[country][code],
          };
        });
};
