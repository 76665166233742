import Papa from "papaparse";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import { LoadingSpinner } from "adminComponents/atoms/LoadingSpinner";
import { ClassroomAssignmentReportScreen } from "adminComponents/screens/ClassroomAssignmentReportScreen";
import {
  getStudentFamilyName,
  getStudentGivenName,
} from "adminComponents/utils";
import { useErrorToast } from "adminComponents/utils/toast";
import { usePageTrack } from "lib/contexts/analytics";
import { usePageTitle } from "lib/hooks/usePageTitle";
import { useFetchAssignment } from "links/lib/features/assignments";
import { useAuth } from "links/lib/features/auth";
import { useFetchClassroom } from "links/lib/features/classrooms";
import {
  AnalyticsEvent,
  AnalyticsPage,
  IAssignmentUserAttempt,
  IUser,
} from "links/lib/types";
import { redirectIfNaN } from "links/lib/util";
import { useDeleteAssignment } from "screens/TeacherClassrooms/shared/hooks/useDeleteAssignment";
import { useNavigationData } from "screens/TeacherDashboard/contexts/TeacherNavigationDataProvider";

import { useEditAssignment } from "../shared/hooks/useEditAssignment";
import { QuestionsTab } from "./components/QuestionsTab";
import { StandardsTab } from "./components/StandardsTab";
import { StudentPerformanceTab } from "./components/StudentPerformanceTab";
import { useStudentPerformanceContext } from "./hooks/useStudentPerformanceData";

export enum AssignmentDetailTab {
  Questions = 0,
  StudentPerfomance = 1,
  Standards = 2,
}

export enum StudentPerformanceSortBy {
  StudentName = 0,
  StudentAccuracy = 1,
}

export const AssignmentDetail: React.FC<{
  downloadedCsv: boolean;
  setDownloadedCsv: (value: boolean) => void;
}> = ({ downloadedCsv, setDownloadedCsv }) => {
  const { navigationData } = useNavigationData();
  const params = useParams() as { classroomId: string; assignmentId: string };
  const history = useHistory();
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });
  const { isFeatureEnabled } = useAuth();

  usePageTrack(AnalyticsPage.TeacherDashboard_ClassroomDetail_AssignmentDetail);

  const { assignmentId, classroomId } = params;

  redirectIfNaN(assignmentId, `/t/classrooms/${classroomId}/assignments`);

  const handleTabChange = (tab: AssignmentDetailTab) => {
    switch (tab) {
      case AssignmentDetailTab.Questions:
        history.push(
          `/t/classrooms/${classroomId}/assignments/${assignmentId}/questions`
        );
        return;
      case AssignmentDetailTab.StudentPerfomance:
        history.push(
          `/t/classrooms/${classroomId}/assignments/${assignmentId}/students`
        );
        return;
      case AssignmentDetailTab.Standards:
        history.push(
          `/t/classrooms/${classroomId}/assignments/${assignmentId}/standards`
        );
        return;
    }
  };

  const isQuestionsTab = useRouteMatch(
    "/t/classrooms/:classroom_id/assignments/:assignment_id/questions"
  );
  const isStudentsTab = useRouteMatch(
    "/t/classrooms/:classroom_id/assignments/:assignment_id/students"
  );
  const isStandardsTab = useRouteMatch(
    "/t/classrooms/:classroom_id/assignments/:assignment_id/standards"
  );

  const tabIndex = useMemo(() => {
    if (isQuestionsTab) {
      return AssignmentDetailTab.Questions;
    }
    if (isStudentsTab) {
      return AssignmentDetailTab.StudentPerfomance;
    }
    if (isStandardsTab) {
      return AssignmentDetailTab.Standards;
    }
  }, [isQuestionsTab, isStudentsTab, isStandardsTab]);

  const classroomFetch = useFetchClassroom({
    id: classroomId,
    onPermissionDenied: () => {
      history.push("/t/classrooms");
    },
  });
  const assignmentFetch = useFetchAssignment({
    id: assignmentId,
    onError: (err) => {
      if (err.response?.status === 404) {
        history.push(`/t/classrooms/${classroomId}/assignments`);
      }
    },
  });

  useErrorToast(classroomFetch.error);
  useErrorToast(assignmentFetch.error);

  const navData = useMemo(() => {
    const breadcrumbs = [
      {
        label: t("assignmentDetailContainer.breadcrumbClassrooms"),
        to: "/t/classrooms",
        isExternal: false,
      },
      {
        label: classroomFetch.data?.classroom.name || "",
        to: `/t/classrooms/${classroomId}`,
        isExternal: false,
      },
      {
        label:
          `${t("assignmentDetailContainer.assignment")}: ${
            assignmentFetch.data?.assignment.practice_set.title
          }` || "",
        isExternal: false,
      },
    ];

    return {
      ...navigationData,
      breadcrumbs,
    };
  }, [navigationData, classroomFetch, classroomId, t, assignmentFetch]);

  const isLoading = classroomFetch.isLoading || assignmentFetch.isLoading;

  const classroom = classroomFetch.data?.classroom;
  const assignment = assignmentFetch.data?.assignment;

  usePageTitle([
    assignment?.practice_set.title
      ? `${t("assignmentDetailContainer.assignment")}: ${
          assignment.practice_set.title
        }`
      : undefined,
    classroom?.name,
  ]);

  const groupAccuracy = useMemo(() => {
    if (!assignment || !assignment.best_user_attempts.length) {
      return 0;
    }
    const groupAccuracySum = assignment.best_user_attempts.reduce(
      (prev: number, curr: IAssignmentUserAttempt) => prev + curr.accuracy,
      0
    );
    return Math.round(groupAccuracySum / assignment?.best_user_attempts.length);
  }, [assignment]);

  const classrooms = classroom ? [classroom] : [];
  const { modal: reassignAssignmentModal, handleEditAssignment } =
    useEditAssignment({
      classrooms,
      editConfirmAnalyticsEvent:
        AnalyticsEvent.TeacherDashboard_ClassroomDetail_AssignmentDetail_EditAssignmentConfirm,
      editIntentAnalyticsEvent:
        AnalyticsEvent.TeacherDashboard_ClassroomDetail_AssignmentDetail_EditAssignmentIntent,
    });

  const handleDeleteAssignmentSuccess = () => {
    history.push(`/t/classrooms/${classroomId}/assignments`);
  };

  const {
    modal: deleteAssignmentModal,
    handleDeleteIntent: handleDeleteAssignment,
  } = useDeleteAssignment({
    classroom_id: classroomId,
    onSuccess: handleDeleteAssignmentSuccess,
    deleteIntentAnalyticsEvent:
      AnalyticsEvent.TeacherDashboard_ClassroomDetail_AssignmentDetail_DeleteAssignmentIntent,
    deleteConfirmAnalyticsEvent:
      AnalyticsEvent.TeacherDashboard_ClassroomDetail_AssignmentDetail_DeleteAssignmentConfirm,
  });

  // Student Performance data is accessed here in order to provide a CSV download
  const { sortedStudentsData } = useStudentPerformanceContext();

  const handleDownloadStudentPerformanceCsv = () => {
    const csv = Papa.unparse(
      sortedStudentsData.map((row) => {
        const student = row[0] as IUser;
        const csvRow: { [key: string]: string } = {};
        csvRow[t("common.firstName")] = getStudentGivenName(student) || "";
        csvRow[t("common.lastName")] = getStudentFamilyName(student) || "";
        csvRow[t("common.accuracy")] = row[2] as string;
        csvRow[t("common.completion")] = row[3]
          ? t("common.complete")
          : t("common.incomplete");
        return csvRow;
      })
    );

    const link = document.createElement("a");
    link.href = `data:text/csv;charset=utf-8,${csv}`;
    link.setAttribute(
      "download",
      `${assignment?.practice_set.title.replaceAll(
        " ",
        "_"
      )}_${new Date().getTime()}.csv`
    );
    document.body.appendChild(link);
    link.click();
    link.remove();
    setDownloadedCsv(true);
  };

  if (!assignment || !classroom) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <ClassroomAssignmentReportScreen
        navigationData={navData}
        assignmentReportData={{
          assignment,
          accuracy: groupAccuracy,
          studentCount:
            assignment?.user_ids.length || classroom?.students_count || 0,
          studentCompletedCount: assignment.best_user_attempts.filter(
            (attempt: IAssignmentUserAttempt) => {
              const aboveAccuracyThreshold =
                attempt.accuracy >= assignment.required_score_percent;
              // user_ids contains the specifically assigned user IDs or is empty if all
              // students were assigned
              if (assignment.user_ids.length) {
                return (
                  assignment.user_ids.includes(attempt.user_id) &&
                  aboveAccuracyThreshold
                );
              }
              return aboveAccuracyThreshold;
            }
          ).length,
          handleEdit: handleEditAssignment,
          handleDelete: handleDeleteAssignment,
        }}
        isLoading={isLoading}
        handleTabChange={handleTabChange}
        tabIndex={tabIndex}
        showPremiumMarker={!isFeatureEnabled("playtime.teacher.hide_premium")}
        handleDownloadCsv={
          sortedStudentsData.length > 0
            ? handleDownloadStudentPerformanceCsv
            : undefined
        }
        downloadedCsv={downloadedCsv}
      >
        <Switch>
          <Redirect
            from="/t/classrooms/:classroomId/assignments/:assignmentId"
            exact={true}
            to="/t/classrooms/:classroomId/assignments/:assignmentId/questions"
          />
          <>
            <Route path="/t/classrooms/:classroomId/assignments/:assignmentId/questions">
              <QuestionsTab classroom={classroom} assignment={assignment} />
            </Route>
            <Route path="/t/classrooms/:classroomId/assignments/:assignmentId/students">
              <StudentPerformanceTab />
            </Route>
            <Route path="/t/classrooms/:classroomId/assignments/:assignmentId/standards">
              <StandardsTab classroom={classroom} assignment={assignment} />
            </Route>
          </>
        </Switch>
      </ClassroomAssignmentReportScreen>
      {reassignAssignmentModal}
      {deleteAssignmentModal}
    </>
  );
};
