import { Box, BoxProps, useStyleConfig } from "@chakra-ui/react";
import React from "react";

import { ColorScheme } from "adminComponents";

interface IProps extends BoxProps {
  backgroundColor?: ColorScheme | "transparent";
  borderColor: ColorScheme;
  styles?: Record<string, unknown>;
  variant:
    | "adminCardThickBorder"
    | "adminCardThickBorderTall"
    | "adminCardMediumBorder"
    | "adminCardMediumBorderSelectable"
    | "adminQuestionCard"
    | "adminFeedbackCard"
    | "adminStandardsCard"
    | "adminStandardsPickerCard"
    | "adminDiagramCard"
    | "adminResponseCtaCard"
    | "adminPracticeSetAccordionCard"
    | "adminDropZoneArea"
    | "adminAddedMediaCard"
    | "adminStudentsListCard"
    | "adminArchiveClassroomCard"
    | "adminStudentsPerformanceTableCard"
    | "adminCardMediumBorderMediumPadding"
    | "adminCardSmallBorder"
    | "adminCardSmallBorderSmallPadding"
    | "adminCardSelectable"
    | "adminCardSolid"
    | "adminCardTable";
  isSelected?: boolean;
  noPadding?: boolean;
}

const Card: React.FC<IProps> = ({
  backgroundColor,
  borderColor,
  children,
  variant = "adminCardThickBorder",
  isSelected,
  noPadding,
  ...rest
}) => {
  const styles = useStyleConfig("AdminCard", {
    backgroundColor,
    borderColor,
    isSelected,
    variant,
    noPadding,
  });

  return (
    <Box {...rest} __css={styles}>
      {children}
    </Box>
  );
};

export { Card };
