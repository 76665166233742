import {
  Checkbox as ChakraCheckbox,
  CheckboxProps as ChakraCheckboxProps,
  useStyleConfig,
} from "@chakra-ui/react";
import React from "react";

import { Icon } from "adminComponents/atoms/Icon";
import { pxToRem } from "adminComponents/utils/pxToRem";

interface CheckboxProps extends ChakraCheckboxProps {
  variant?: string;
}

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  (p, ref) => {
    const { variant = "adminCheckboxYellow", ...rest } = p;

    const styles = useStyleConfig("AdminCheckbox", {
      variant,
    });

    return (
      <ChakraCheckbox
        {...rest}
        sx={styles}
        ref={ref}
        icon={
          <Icon
            icon="check_outlined"
            width={pxToRem(18)}
            height={pxToRem(18)}
            iconColor="inherit"
          />
        }
        variant={variant}
      />
    );
  }
);

Checkbox.displayName = "Checkbox";
