import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps,
} from "@chakra-ui/react";
import React from "react";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";

import { Icon } from "../../atoms/Icon";

export interface IProps extends DrawerProps {
  title?: string;
  width?: string;
  size?: string;
}

export const GameFlyout: React.FC<IProps> = ({
  title,
  children,
  width,
  size,
  ...rest
}) => {
  const { match: currentBreakpoints } = useBreakpoints();
  const fontSize = pxToRem(currentBreakpoints.fontSize);
  const p = pxToRem(currentBreakpoints.padding);
  const closeTop = pxToRem(currentBreakpoints.padding / 2);

  // Chakra makes it difficult to set the width more flexibly than "sm", "md", etc.
  // If a `width` value is specified, optionally set the DrawContent's w/maxW props to
  // support specific widths.
  const drawerContentProps: { w?: string; maxW?: string } = {};
  if (width) {
    drawerContentProps.w = width;
    drawerContentProps.maxW = width;
  }

  return (
    <Drawer {...rest} size={size || "lg"} variant="gameFlyoutDefault">
      <DrawerOverlay variants={{}} />
      <DrawerContent {...drawerContentProps}>
        <DrawerCloseButton top={closeTop} right={p}>
          <Icon icon="close" boxSize={pxToRem(16)} />
        </DrawerCloseButton>
        <DrawerHeader p={p}>
          <Box as="h2" fontSize={fontSize}>
            {title}
          </Box>
        </DrawerHeader>
        <DrawerBody>{children}</DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
