import {
  Box,
  Flex,
  HStack,
  SimpleGrid,
  Spacer,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { isEqual } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCopyToClipboard, useInterval } from "react-use";

import { CircledNumber } from "adminComponents/atoms/CircledNumber";
import { Divider } from "adminComponents/atoms/Divider";
import { Icon as AdminIcon } from "adminComponents/atoms/Icon";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { useShowToast } from "adminComponents/utils/toast";
import { config } from "links/lib/constants";
import { useSessionScene } from "links/lib/contexts/sessionScene";
import { AnalyticsEvent, ISessionUser } from "links/lib/types";
import { Button } from "sessionComponents/atoms/Button";
import { Card } from "sessionComponents/atoms/Card";
import { Icon } from "sessionComponents/atoms/Icon";
import { Tooltip } from "sessionComponents/atoms/Tooltip";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { useLobbyAudio } from "sessionComponents/hooks/useLobbyAudio";
import { ConfirmButton } from "sessionComponents/molecules/ConfirmButton";
import { StudentListModal } from "sessionComponents/molecules/StudentListModal";
import { useSessionAnalytics } from "sharedComponents/contexts/sessionAnalytics";

import { StudentCard } from "./components/StudentCard";
import "./fade-in.css";

export interface ITeacherLobbyProps {
  onlineStudents: ISessionUser[];
  sessionCode: string;
  onStart: () => void;
}

export const TeacherLobby: React.FC<ITeacherLobbyProps> = ({
  onlineStudents,
  sessionCode,
  onStart,
}) => {
  const { match: currentBreakpoints } = useBreakpoints();

  const showToast = useShowToast();
  const [, copyToClipboard] = useCopyToClipboard();
  const sessionScene = useSessionScene();
  const { t } = useTranslation("session", {
    keyPrefix: "teacherLobbyLight",
    useSuspense: false,
  });
  const { track } = useSessionAnalytics();
  useLobbyAudio();

  const [onlineStudentsState, setOnlineStudentsState] =
    useState(onlineStudents);

  // keep onlineStudents in state to avoid rerendering avatars when we don't need to
  useEffect(() => {
    if (!isEqual(onlineStudents, onlineStudentsState)) {
      setOnlineStudentsState(onlineStudents);
    }
  }, [onlineStudents, onlineStudentsState]);

  const joinUrl = `${config.joinBaseUrl}/${sessionCode.toLocaleLowerCase()}`;

  const onCopyJoinCode = () => {
    copyToClipboard(joinUrl);
    showToast(t("copiedCode"));

    track(AnalyticsEvent.Session_Lobby_CopyJoinLink, {});
  };

  const [countdownSecondsRemaining, setCountdownSecondsRemaining] = useState<
    number | undefined
  >();
  const [interval, setInterval] = useState<number | null>(null);
  useInterval(() => {
    if (countdownSecondsRemaining === 0) {
      setInterval(null);
      setCountdownSecondsRemaining(undefined);
      onStart();
      return;
    }
    setCountdownSecondsRemaining((countdownSecondsRemaining || 60) - 1);
  }, interval);

  const handleCountdown = () => {
    setInterval(1000);
    setCountdownSecondsRemaining(60);
  };

  const handleAdd30SecondsToCountdown = () => {
    setCountdownSecondsRemaining((countdownSecondsRemaining || 60) + 30);
  };

  const handleCancelCountdown = () => {
    setInterval(null);
    setCountdownSecondsRemaining(undefined);
  };

  const {
    isOpen: isStudentListModalOpen,
    onOpen: onOpenStudentListModal,
    onClose: onCloseStudentModal,
  } = useDisclosure();

  const fontSize = pxToRem(currentBreakpoints.fontSize);
  const diminishedFontSize = pxToRem(currentBreakpoints.fontSize / 1.5);

  return (
    <>
      <Flex
        position="relative"
        flexDirection="column"
        w="full"
        h="full"
        bgColor="primary.tan-light"
      >
        <Flex
          p={pxToRem(currentBreakpoints.padding)}
          w="full"
          h="full"
          overflowX="hidden"
          overflowY="auto"
          gap={pxToRem(currentBreakpoints.margin)}
          flexDir={{ base: "column", md: "row" }}
          alignItems={{ base: "center", md: "start" }}
        >
          <Card
            p={pxToRem(currentBreakpoints.padding)}
            w="min-content"
            h="min-content"
          >
            <Flex
              flexDirection="column"
              gap={pxToRem(currentBreakpoints.margin)}
            >
              <HStack>
                <CircledNumber number={1} />
                <VStack alignItems="start" spacing={0}>
                  <Text textStyle="gameText" fontSize={diminishedFontSize}>
                    {t("joinInstructions1")}
                  </Text>
                  <Text textStyle="gameTextWeighted" fontSize={fontSize}>
                    {config.joinBaseUrl}
                  </Text>
                </VStack>
              </HStack>
              <HStack>
                <CircledNumber number={2} />
                <VStack alignItems="start">
                  <Text fontSize={diminishedFontSize} textStyle="gameText">
                    {t("joinInstructions2")}
                  </Text>
                  <Box
                    bgColor="utility.question-green"
                    borderRadius="2xl"
                    px={pxToRem(currentBreakpoints.padding / 2)}
                    py={pxToRem(currentBreakpoints.padding / 4)}
                  >
                    <Text textStyle="sessionCode" fontSize={fontSize}>
                      {sessionCode.toUpperCase()}
                    </Text>
                  </Box>
                </VStack>
              </HStack>
              <Divider color="primary.tan" />
              <Button
                leftIcon={
                  <Icon
                    boxSize={pxToRem(currentBreakpoints.iconSize / 2)}
                    color="utility.link"
                    icon="clipboard"
                  />
                }
                variant="buttonOutlined"
                height={pxToRem(currentBreakpoints.buttonHeight / 1.5)}
                onClick={onCopyJoinCode}
                fontSize={diminishedFontSize}
              >
                {t("copyCode")}
              </Button>
            </Flex>
          </Card>
          {!!onlineStudents.length && (
            <Flex
              w="full"
              flexDirection="column"
              gap={pxToRem(currentBreakpoints.margin)}
            >
              <Flex
                flexDirection={{ base: "row", md: "column", lg: "row" }}
                gap={{ base: 0, md: pxToRem(16), lg: 0 }}
                w="full"
                justifyContent="space-between"
              >
                <Text textStyle="gameDisplay" fontSize={fontSize}>
                  {t("students")}
                  {onlineStudents.length
                    ? " " +
                      t("joinedStudentsCount", { count: onlineStudents.length })
                    : ""}
                </Text>
                <Button
                  variant="buttonOutlined"
                  height={pxToRem(currentBreakpoints.buttonHeight / 1.5)}
                  fontSize={diminishedFontSize}
                  onClick={() => {
                    onOpenStudentListModal();
                    track(AnalyticsEvent.Session_Lobby_ToggleStudentList, {
                      isOpen: isStudentListModalOpen,
                    });
                  }}
                >
                  {t("showClassRoster")}
                </Button>
              </Flex>
              <SimpleGrid
                minChildWidth={{ base: pxToRem(200), md: pxToRem(280) }}
                columns={3}
                spacing={pxToRem(20)}
              >
                {onlineStudents.map((student) => {
                  return <StudentCard key={student.id} student={student} />;
                })}
              </SimpleGrid>
            </Flex>
          )}
          {!onlineStudents.length && (
            <Flex w="full" h="full" alignItems="center" justifyContent="center">
              <Box
                bgColor="primary.tan"
                borderRadius={pxToRem(currentBreakpoints.borderRadius)}
                p={pxToRem(currentBreakpoints.padding)}
              >
                <Text
                  animation="fadeIn 1s infinite alternate"
                  textStyle="gameDisplay"
                  fontSize={fontSize}
                >
                  {t("waitingForStudents")}
                </Text>
              </Box>
            </Flex>
          )}
        </Flex>
        <Spacer />
        <Flex
          w="full"
          justifyContent="space-evenly"
          p={pxToRem(currentBreakpoints.padding)}
          borderTopWidth={pxToRem(currentBreakpoints.borderWidth)}
          borderColor="primary.tan"
          gap={pxToRem(currentBreakpoints.margin / 2)}
        >
          {countdownSecondsRemaining === undefined && (
            <Tooltip
              tooltipContent={t("waitingForStudentsTooltip")}
              isDisabled={!!onlineStudents.length}
            >
              <Box w="50%">
                <Button
                  w="full"
                  height={pxToRem(currentBreakpoints.buttonHeight)}
                  fontSize={fontSize}
                  variant="buttonOutlined"
                  disabled={onlineStudents.length < 1}
                  onClick={handleCountdown}
                  leftIcon={
                    <AdminIcon
                      boxSize={pxToRem(currentBreakpoints.iconSize)}
                      icon="schedule_outlined"
                    />
                  }
                >
                  {t("countdown")}
                </Button>
              </Box>
            </Tooltip>
          )}
          {countdownSecondsRemaining !== undefined && (
            <Flex
              w="50%"
              bg="primary.tan"
              alignItems="center"
              borderRadius="xl"
              px={pxToRem(currentBreakpoints.padding)}
              gap={pxToRem(currentBreakpoints.margin / 2)}
            >
              <AdminIcon
                icon="schedule_outlined"
                boxSize={pxToRem(currentBreakpoints.iconSize)}
              />
              <Text textStyle="gameDisplay" fontSize={fontSize}>
                {t("startingIn") +
                  " " +
                  formatSeconds(countdownSecondsRemaining)}
              </Text>
              <Spacer />
              <Button
                variant="buttonOutlined"
                onClick={handleAdd30SecondsToCountdown}
              >
                +30s
              </Button>
              <Button
                variant="buttonOutlined"
                onClick={handleCancelCountdown}
                aria-label={t("stopCountdown")}
              >
                <Box
                  bgColor="primary.warm-black"
                  boxSize={pxToRem(currentBreakpoints.iconSize / 2.5)}
                />
              </Button>
            </Flex>
          )}
          <Tooltip
            tooltipContent={t("waitingForStudentsTooltip")}
            isDisabled={!!onlineStudents.length}
          >
            <Box w="50%">
              <ConfirmButton
                w="full"
                confirmedWhenChanged={[sessionScene]}
                handleConfirmClick={onStart}
                fontSize={fontSize}
                variant="buttonFilled"
                disabled={onlineStudents.length < 1}
                height={pxToRem(currentBreakpoints.buttonHeight)}
              >
                {t("startNow")}
              </ConfirmButton>
            </Box>
          </Tooltip>
        </Flex>
      </Flex>
      {isStudentListModalOpen && (
        <StudentListModal
          isOpen={isStudentListModalOpen}
          onClose={onCloseStudentModal}
        />
      )}
    </>
  );
};

const formatSeconds = (seconds: number) => {
  const pad = (n: number) => (n < 10 ? `0${n}` : n);

  const h = Math.floor(seconds / 3600);
  const m = Math.floor(seconds / 60) - h * 60;
  const s = Math.floor(seconds - h * 3600 - m * 60);

  return `${m}:${pad(s)}`;
};
