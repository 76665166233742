import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { useAxios } from "../../hooks/useAxios";

export interface IDisableUserRecurringNotificationsParams {
  token: string;
}

// eslint-disable-next-line
export interface IDisableUserRecurringNotificationsResponse {
  // Intentionally empty
}

/**
 * Disables recurring notifications for a user
 * @returns
 */
export default function useDisableUserRecurringNotifications(): UseMutationResult<
  IDisableUserRecurringNotificationsResponse,
  unknown,
  IDisableUserRecurringNotificationsParams
> {
  const axios = useAxios();
  const queryClient = useQueryClient();

  const DisableUserRecurringNotifications = async (
    args: IDisableUserRecurringNotificationsParams
  ) => {
    const { token } = args;

    const response = await axios({
      method: "post",
      url: `/v1/users/0/recurring-notifications/settings/disable`,
      data: {
        token,
      },
    }).then((r) => r.data as IDisableUserRecurringNotificationsResponse);

    queryClient.invalidateQueries("user-notification-settings");

    return response;
  };

  const mutation = useMutation(DisableUserRecurringNotifications);

  return mutation;
}
