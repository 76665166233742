import { Box, Image, VisuallyHidden } from "@chakra-ui/react";
import React from "react";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { SessionReaction } from "links/lib/types";
import { ReactComponent as SVGBackground } from "sessionComponents/atoms/IconButton/resource/background.svg";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";

const buttonStyle = {
  opacity: 0.75,
  _hover: {
    opacity: 1,
  },
};

interface IStudentReactionButtonProps {
  name: SessionReaction;
  onClick: (reaction: SessionReaction) => void;
  src: string;
}

export const StudentReactionButton: React.FC<IStudentReactionButtonProps> = ({
  name,
  src,
  onClick,
}) => {
  const {
    match: { buttonHeight, iconSize },
  } = useBreakpoints();
  return (
    <Box
      as="button"
      boxSize={pxToRem(buttonHeight)}
      position="relative"
      sx={buttonStyle}
      onClick={() => onClick(name)}
    >
      <VisuallyHidden>{name}</VisuallyHidden>
      <SVGBackground width="100%" height="100%" />
      <Image
        boxSize={pxToRem(iconSize)}
        src={src}
        alt={`${name} Reaction`}
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
      />
    </Box>
  );
};
