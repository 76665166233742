import { Flex } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";

import { Card } from "adminComponents/atoms/Card";
import { Modal } from "adminComponents/atoms/Modal";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils";

import WhatWillStudentsSeeMP4 from "../resource/what-will-students-see.mp4";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

export const StudentPreviewModal: React.FC<IProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation("admin");

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="6xl"
      showBackButton={false}
      title={t("teacherTour.studentPreviewModal.title")}
      headingProps={{ as: "h1", variant: "adminH3" }}
    >
      <Flex
        px={pxToRem(10)}
        py={pxToRem(32)}
        flexDir="column"
        w="full"
        alignItems="center"
        justifyContent="center"
        gap={pxToRem(32)}
      >
        <ReactPlayer
          url={WhatWillStudentsSeeMP4}
          controls={false}
          loop
          playing
          width="70%"
          height="70%"
          // Hides the video's border
          style={{
            clipPath: "inset(1px 1px)",
          }}
        />
        <Card
          variant="adminCardSolid"
          borderColor="primary.tan"
          bgColor="primary.tan"
        >
          <Text variant="adminP1" textAlign="center">
            {t("teacherTour.studentPreviewModal.description")}
          </Text>
        </Card>
      </Flex>
    </Modal>
  );
};
