import { Box, ButtonGroup } from "@chakra-ui/react";
import React from "react";
import { useSlate } from "slate-react";

import CustomEditor from "adminComponents/organisms/RichTextEditor/CustomEditor";
import { pxToRem } from "adminComponents/utils/pxToRem";

import ToolbarButton from "./ToolbarButton";

export interface IToolbarProps {
  disabled?: boolean;
  isFocused?: boolean;
  content: {
    boldButton: string;
    italicButton: string;
    underlineButton: string;
    subscriptButton: string;
    superscriptButton: string;
    latexModalButton: string;
    strikethroughButton: string;
    undoButton: string;
    redoButton: string;
  };
  onAddLatex: () => void;
  handleUndo: () => void;
  handleRedo: () => void;
  onMark: (mark: string) => void;
}

const Toolbar: React.FC<IToolbarProps> = ({
  disabled,
  isFocused,
  content,
  onAddLatex,
  handleRedo,
  handleUndo,
  onMark,
}) => {
  const editor = useSlate();

  return (
    <Box
      w="full"
      bg="primary.white"
      borderTopColor="primary.medium-gray"
      borderTopWidth="1px"
      borderTopStyle="solid"
      borderBottomRadius="md"
    >
      <ButtonGroup
        paddingX={isFocused ? pxToRem(7) : pxToRem(8)}
        paddingTop={pxToRem(8)}
        paddingBottom={isFocused ? pxToRem(7) : pxToRem(8)}
        spacing={0}
        display="flex"
        gap={pxToRem(4)}
      >
        <ToolbarButton
          handleMouseDown={() => onMark("bold")}
          handleKeyDown={() => onMark("bold")}
          label={content.boldButton}
          disabled={disabled}
          isActive={CustomEditor.isStyleMarkActive(editor, "bold")}
          icon="format_bold_outlined"
        />
        <ToolbarButton
          handleMouseDown={() => onMark("strikethrough")}
          handleKeyDown={() => onMark("strikethrough")}
          label={content.strikethroughButton}
          disabled={disabled}
          isActive={CustomEditor.isStyleMarkActive(editor, "strikethrough")}
          icon="format_strikethrough_outlined"
        />
        <ToolbarButton
          label={content.italicButton}
          disabled={disabled}
          handleMouseDown={() => onMark("italic")}
          handleKeyDown={() => onMark("italic")}
          isActive={CustomEditor.isStyleMarkActive(editor, "italic")}
          icon="format_italic_outlined"
        />
        <ToolbarButton
          label={content.underlineButton}
          disabled={disabled}
          handleMouseDown={() => onMark("underline")}
          handleKeyDown={() => onMark("underline")}
          isActive={CustomEditor.isStyleMarkActive(editor, "underline")}
          icon="format_underlined_outlined"
        />
        <ToolbarButton
          label={content.subscriptButton}
          disabled={disabled}
          handleMouseDown={() => onMark("subscript")}
          handleKeyDown={() => onMark("subscript")}
          isActive={CustomEditor.isStyleMarkActive(editor, "subscript")}
          icon="format_sub_outlined"
        />
        <ToolbarButton
          label={content.superscriptButton}
          disabled={disabled}
          handleMouseDown={() => onMark("superscript")}
          handleKeyDown={() => onMark("superscript")}
          isActive={CustomEditor.isStyleMarkActive(editor, "superscript")}
          icon="format_sup_outlined"
        />
        <ToolbarButton
          label={content.latexModalButton}
          disabled={disabled}
          handleMouseDown={onAddLatex}
          handleKeyDown={onAddLatex}
          icon="format_function_outlined"
        />
        <Box mx="auto"></Box>
        <ToolbarButton
          label={content.undoButton}
          disabled={disabled}
          handleMouseDown={handleUndo}
          handleKeyDown={handleUndo}
          icon="toolbar_undo"
        />
        <ToolbarButton
          label={content.redoButton}
          disabled={disabled}
          handleMouseDown={handleRedo}
          handleKeyDown={handleRedo}
          icon="toolbar_redo"
        />
      </ButtonGroup>
    </Box>
  );
};

export default Toolbar;
