import { Box, ButtonGroup, Flex, HStack, VStack } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Divider } from "adminComponents/atoms/Divider";
import { Icon } from "adminComponents/atoms/Icon";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils";
import { SocialMediaShareProvider } from "links/lib/types";

import FacebookLogo from "./resource/facebook.png";
import LinkedInLogo from "./resource/linkedin.png";
import XLogo from "./resource/x.png";

export interface IShareAvatarControlsProps {
  showShareProviders?: boolean;
  handleDownload: () => void;
  handleBack: () => void;
  handleShare: (provider: SocialMediaShareProvider) => void;
}

export const SocialShareButton: React.FC<{
  provider: SocialMediaShareProvider;
  imageUrl?: string;
  handleClick: (provider: SocialMediaShareProvider) => void;
  ariaLabel?: string;
}> = ({ provider, children, imageUrl, handleClick, ariaLabel }) => {
  return (
    <Box
      boxSize={{ base: pxToRem(32), sm: pxToRem(64) }}
      backgroundImage={imageUrl ? `url(${imageUrl})` : undefined}
      backgroundRepeat="no-repeat"
      backgroundPosition="center center"
      backgroundSize="contain"
      as="button"
      p={0}
      m={0}
      onClick={() => handleClick(provider)}
      aria-label={ariaLabel}
    >
      {children}
    </Box>
  );
};

export const ShareAvatarControls: React.FC<IShareAvatarControlsProps> = ({
  showShareProviders,
  handleDownload,
  handleBack,
  handleShare,
}) => {
  const { t } = useTranslation("admin", {
    keyPrefix: "shareAvatarControls",
    useSuspense: false,
  });
  const { t: tCommon } = useTranslation("admin", {
    keyPrefix: "common",
    useSuspense: false,
  });

  return (
    <Flex
      w="full"
      h="full"
      justifyContent="flex-start"
      alignItems="center"
      flexDir="column"
    >
      <VStack
        gap={{ base: pxToRem(24), lg: pxToRem(48) }}
        maxW={{ base: "full", lg: pxToRem(460) }}
        px={{ base: pxToRem(48), lg: 0 }}
        pt={{ base: 0, lg: pxToRem(60) }}
      >
        {!!showShareProviders && (
          <>
            <Box w="full">
              <Text
                w="full"
                textAlign="left"
                variant="adminP1"
                mb={{ base: pxToRem(12), lg: pxToRem(24) }}
              >
                {t("shareProvidersLabel")}
              </Text>
              <HStack w="full" justifyContent="space-evenly">
                <SocialShareButton
                  provider={SocialMediaShareProvider.X}
                  imageUrl={XLogo}
                  handleClick={handleShare}
                  ariaLabel={tCommon("shareOnX")}
                />
                <SocialShareButton
                  provider={SocialMediaShareProvider.Facebook}
                  imageUrl={FacebookLogo}
                  handleClick={handleShare}
                  ariaLabel={tCommon("shareOnFacebook")}
                />
                <SocialShareButton
                  provider={SocialMediaShareProvider.LinkedIn}
                  imageUrl={LinkedInLogo}
                  handleClick={handleShare}
                  ariaLabel={tCommon("shareOnLinkedIn")}
                />
                {!!navigator.canShare && (
                  <SocialShareButton
                    provider={SocialMediaShareProvider.Navigator}
                    handleClick={handleShare}
                    ariaLabel={tCommon("shareOnNavigator")}
                  >
                    <Icon
                      sx={{
                        svg: {
                          w: { base: pxToRem(32), sm: pxToRem(64) },
                          h: { base: pxToRem(32), sm: pxToRem(64) },
                        },
                      }}
                      color="primary.warm-black"
                      icon="share"
                    />
                  </SocialShareButton>
                )}
              </HStack>
            </Box>
            <Divider color="primary.tan-dark" />
          </>
        )}
        <Box>
          <ButtonGroup flexDir={{ base: "column", sm: "row" }}>
            <Button
              leftIcon={<Icon icon="download" />}
              variant="adminButtonFilled"
              mb={{ base: pxToRem(12), sm: 0 }}
              onClick={handleDownload}
            >
              {t("downloadButton")}
            </Button>
            <Button
              leftIcon={<Icon icon="refresh" />}
              variant="adminButtonOutlined"
              onClick={handleBack}
            >
              {t("backButton")}
            </Button>
          </ButtonGroup>
        </Box>
      </VStack>
    </Flex>
  );
};
