import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { IPracticeSessionsStreak } from "../../types";

export interface IFetchPracticeSessionsStreakParams {
  enabled?: boolean;
}

export interface IFetchPracticeSessionsStreakResponse {
  streak: IPracticeSessionsStreak;
}

/**
 * Fetches the practice sessions streak of the current user
 * @returns
 */
export default function useFetchPracticeSessionsStreak(
  params?: IFetchPracticeSessionsStreakParams
): UseQueryResult<IFetchPracticeSessionsStreakResponse, unknown> {
  const axios = useAxios();

  const enabled = params?.enabled ?? true;

  const query = useQuery<IFetchPracticeSessionsStreakResponse>(
    "practice-sessions-streak",
    async (): Promise<IFetchPracticeSessionsStreakResponse> => {
      const streak = await axios
        .get("/v1/practice-sets/sessions/streak")
        .then((r) => r.data as IPracticeSessionsStreak);

      return { streak };
    },
    { enabled }
  );

  return query;
}
