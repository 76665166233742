export interface IDataUrlToFileParams {
  dataUrl: string;
  fileName: string;
}

/**
 * Fetches a data URL representing a drawing item and returns a File representation
 * @param args
 * @returns
 */
const dataUrlToFile = async (
  args: IDataUrlToFileParams
): Promise<File | undefined> => {
  try {
    if (!args.dataUrl) throw new Error("invalid data URL");

    const drawingBlob = await (await fetch(args.dataUrl)).blob();
    const file = new File([drawingBlob], args.fileName, {
      type: "image/png",
    });

    return file;
  } catch (err) {
    console.error("Error processing data URL", err);
  }
};

export default dataUrlToFile;
