import {
  Box,
  Flex,
  HStack,
  Tooltip,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Icon } from "adminComponents/atoms/Icon";
import { Text } from "adminComponents/atoms/Text";
import {
  TopProperty,
  TopTeacherCard,
} from "adminComponents/molecules/TopTeacherCard";
import { pxToRem } from "adminComponents/utils";
import { IGradeLevel, ISubject, IUser } from "links/lib/types";

export interface ITopTeacher {
  teacher: IUser;
  group_count: string;
  student_count: string;
  assignment_count: string;
}

interface IAssignmentsTabProps {
  topTeachers: ITopTeacher[];
  gradeLevels: Array<IGradeLevel>;
  subjects: Array<ISubject>;
  classAssignmentCount: number;
  hasNoPremiumAccess: boolean;
  sideCard?: JSX.Element;
}

export const AssignmentsTab: React.FC<IAssignmentsTabProps> = ({
  topTeachers,
  gradeLevels,
  subjects,
  classAssignmentCount,
  hasNoPremiumAccess,
  sideCard,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const isMobile = useBreakpointValue({ base: true, lg: false });

  const [topTeachersInView, setTopTeachersInView] = useState<ITopTeacher[]>(
    topTeachers.slice(0, 3)
  );

  useEffect(() => {
    if (topTeachersInView.length === 0 && topTeachers.length > 0) {
      setTopTeachersInView(topTeachers.slice(0, 3));
    }
  }, [topTeachers, topTeachers.length, topTeachersInView.length]);

  const onViewMoreTeachers = useCallback(() => {
    if (topTeachersInView.length < topTeachers.length) {
      setTopTeachersInView(topTeachers.slice(0, topTeachersInView.length + 3));
    }
  }, [topTeachers, topTeachersInView]);

  return (
    <Box w="full">
      <HStack w="100%" align="end" justify="end" paddingBottom={pxToRem(10)}>
        <Text variant="adminP1">
          {t("districtAdminHomepage.topTeachers.classAssignmentCount", {
            count: classAssignmentCount,
            total: classAssignmentCount.toLocaleString(),
          })}
        </Text>
      </HStack>
      <Flex
        w="100%"
        alignItems="stretch"
        gap={pxToRem(10)}
        flexDir={isMobile ? "column" : "row"}
      >
        <VStack spacing={pxToRem(10)} w="full">
          {topTeachersInView.map((topTeacher) => (
            <TopTeacherCard
              teacher={topTeacher.teacher}
              key={topTeacher.teacher.id}
              gradeLevels={gradeLevels}
              subjects={subjects}
              studentCount={topTeacher.student_count}
              groupCount={topTeacher.group_count}
              topPropertyCount={topTeacher.assignment_count}
              topProperty={TopProperty.ASSIGNMENTS}
              hasNoPremiumAccess={hasNoPremiumAccess}
            />
          ))}
          {topTeachersInView.length < topTeachers.length && (
            <HStack justify="center">
              <Tooltip
                label={t("districtAdminHomepage.generalPremiumCTA")}
                aria-label={t("districtAdminHomepage.generalPremiumCTA")}
                variant="adminDark"
                isDisabled={!hasNoPremiumAccess}
              >
                <Box>
                  <Button
                    w="fit-content"
                    onClick={onViewMoreTeachers}
                    variant="adminTextButtonMedium"
                    rightIcon={
                      <Icon
                        color="currentColor"
                        height={pxToRem(7)}
                        icon="button_down_arrow"
                        ml={pxToRem(5)}
                        width={pxToRem(12)}
                      />
                    }
                    leftIcon={
                      hasNoPremiumAccess ? (
                        <Icon
                          color="currentColor"
                          height={pxToRem(7)}
                          icon="lock"
                          ml={pxToRem(5)}
                          width={pxToRem(12)}
                        />
                      ) : undefined
                    }
                    disabled={hasNoPremiumAccess}
                  >
                    {t("common.viewMore")}
                  </Button>
                </Box>
              </Tooltip>
            </HStack>
          )}
        </VStack>
        {sideCard && (
          <Box w={isMobile ? "100%" : "60%"} maxH={pxToRem(488)}>
            {sideCard}
          </Box>
        )}
      </Flex>
    </Box>
  );
};
