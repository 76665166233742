import { Box, Portal, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { animated, config, useSpring } from "react-spring";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";

import { BigBoardLogo } from "../BigBoardLogo";
import CurtainLeft from "./resource/StageCurtainLeft.svg";
import CurtainRight from "./resource/StageCurtainRight.svg";

export interface IStageCurtainsProps {
  isOpen: boolean;
  message?: string;
  showLogo?: boolean;
  buttonText?: string;
  handlePlay?: () => void;
}

const AnimatedBox = animated(Box);

export const StageCurtains: React.FC<IStageCurtainsProps> = ({
  children,
  isOpen,
  message,
  showLogo,
  buttonText,
  handlePlay,
}) => {
  const { match: currentBreakpoints, fontSize3x } = useBreakpoints();
  const [atRest, setAtRest] = useState(true);

  const leftCurtainSpring = useSpring({
    from: {
      left: "0%",
    },
    to: {
      left: isOpen ? "-50%" : "0%",
    },
    config: config.molasses,
    onStart: () => {
      setAtRest(false);
    },
    onRest: () => {
      setAtRest(true);
    },
  });

  const rightCurtainSpring = useSpring({
    from: {
      right: "0%",
    },
    to: {
      right: isOpen ? "-50%" : "0%",
    },
    config: config.molasses,
  });

  return (
    <Box h="100%" w="full" position="relative">
      <Portal>
        {(!atRest || !isOpen) && (
          <>
            <AnimatedBox
              w="50%"
              left="0"
              top="0"
              bottom="0"
              position="fixed"
              bgImage={CurtainLeft}
              bgPosition="center top"
              bgSize="cover"
              bgRepeat="repeat-x"
              bgColor="#7a162d"
              style={leftCurtainSpring}
              zIndex={1000}
            />
            {message && atRest && (
              <Text
                position="fixed"
                h="min-content"
                left="0"
                top="0"
                bottom="0"
                right="0"
                m="auto"
                color="white"
                textStyle="gameDisplay"
                textAlign="center"
                p={pxToRem(currentBreakpoints.padding)}
                fontSize={pxToRem(fontSize3x)}
                zIndex={1002}
              >
                {message}
              </Text>
            )}
            {showLogo && !isOpen && (
              <BigBoardLogo buttonText={buttonText} handlePlay={handlePlay} />
            )}
            <AnimatedBox
              w="50%"
              right="0"
              top="0"
              bottom="0"
              position="fixed"
              bgImage={CurtainRight}
              bgPosition="center top"
              bgSize="cover"
              bgRepeat="repeat-x"
              bgColor="#7a162d"
              style={rightCurtainSpring}
              zIndex={1000}
            />
          </>
        )}
      </Portal>
      {children}
    </Box>
  );
};
