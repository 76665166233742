import { AxiosInstance } from "axios";
import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { IAveragePracticeSetAccuracy } from "../../types";

export interface IUseFetchAveragePracticeSetAccuraciesProps {
  classroom_id: string;
  practice_set_id?: string;
  assignment_id?: string;
  practice_set_session_id?: string;
  user_id?: string;
  enabled?: boolean;
}

export interface IUseFetchAveragePracticeSetAccuraciesResult {
  average_practice_set_accuracies: Array<IAveragePracticeSetAccuracy>;
}

export const fetchAveragePracticeSetAccuraciesQuery = (
  axios: AxiosInstance,
  params: IUseFetchAveragePracticeSetAccuraciesProps
): (() => Promise<IUseFetchAveragePracticeSetAccuraciesResult>) => {
  const {
    classroom_id,
    practice_set_id,
    user_id,
    assignment_id,
    practice_set_session_id,
  } = params;

  return async (): Promise<IUseFetchAveragePracticeSetAccuraciesResult> => {
    return await axios
      .get("/v1/accuracies/sets", {
        params: {
          group_id: classroom_id,
          practice_set_id,
          user_id,
          assignment_id,
          practice_set_session_id,
        },
      })
      .then((r) => r.data as IUseFetchAveragePracticeSetAccuraciesResult);
  };
};

export default function useFetchAveragePracticeSetAccuracies(
  params: IUseFetchAveragePracticeSetAccuraciesProps
): UseQueryResult<IUseFetchAveragePracticeSetAccuraciesResult, unknown> {
  const axios = useAxios();
  const { enabled = true } = params;

  const query = useQuery<IUseFetchAveragePracticeSetAccuraciesResult>(
    ["set-accuracies", params],
    fetchAveragePracticeSetAccuraciesQuery(axios, params),
    { enabled }
  );

  return query;
}
