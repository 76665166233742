export const getIsAppleMobileDevice = (): boolean => {
  const touchSupported = "ontouchstart" in window;
  if (!touchSupported) return false;

  const ua = window.navigator.userAgent;
  return (
    ua.indexOf("iPhone") >= 0 ||
    ua.indexOf("iPad") >= 0 ||
    ua.indexOf("Macintosh") >= 0
  );
};
