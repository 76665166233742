import { AxiosInstance } from "axios";
import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { IUser, IUsersGroup, UsersGroupsRole } from "../../types";

export interface IFetchClassroomUsersParams {
  classroom_id?: string;
  group_roles?: Array<UsersGroupsRole>;
  onSuccess?: (data: IFetchClassroomUsersResponse) => void;
  disabled?: boolean;
}

export interface IFetchClassroomUsersResponse {
  classroom_id: string;
  users: Array<IUser>;
  users_groups: Array<IUsersGroup>;
}

interface IFetchGroupsResponse {
  group_id: string;
  users: Array<IUser>;
  users_groups: Array<IUsersGroup>;
}

export const fetchClassroomUsersQuery = (
  axios: AxiosInstance,
  params: IFetchClassroomUsersParams
): (() => Promise<IFetchClassroomUsersResponse>) => {
  const { classroom_id, group_roles } = params;

  return async (): Promise<IFetchClassroomUsersResponse> => {
    return await axios
      .get(`/v1/users?group_id=${classroom_id}`)
      .then((r) => {
        const data = r.data as IFetchGroupsResponse;
        return {
          data: {
            classroom_id: data.group_id,
            users: data.users,
            users_groups: data.users_groups,
          },
        };
      })
      .then((r) => {
        if (!group_roles || !group_roles.length) return r;

        r.data.users = r.data.users.filter((user) => {
          const usersGroups = r.data.users_groups.find(
            (usersGroup) => usersGroup.user_id === user.id
          );

          return (
            (usersGroups?.user_role &&
              group_roles.indexOf(usersGroups.user_role) >= 0) ||
            // The role provided for a user that hasn't signed in is their UsersGroupsRole
            // for the group.
            group_roles.indexOf(user.role as unknown as UsersGroupsRole) >= 0
          );
        });

        return r;
      })
      .then((r) => r.data as IFetchClassroomUsersResponse);
  };
};

/**
 * Fetches a list of users for the classroom
 * @returns
 */
export default function useFetchClassroomUsers(
  params: IFetchClassroomUsersParams
): UseQueryResult<IFetchClassroomUsersResponse, unknown> {
  const axios = useAxios();

  const { classroom_id, onSuccess, disabled, group_roles } = params;

  const query = useQuery<IFetchClassroomUsersResponse>(
    ["classroom-users", { id: classroom_id, group_roles }],
    fetchClassroomUsersQuery(axios, params),
    { enabled: !!classroom_id && !disabled, onSuccess }
  );

  return query;
}
