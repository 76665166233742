import { Box, Flex } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { Banner } from "adminComponents/atoms/Banner";
import { Tabs } from "adminComponents/atoms/Tabs";
import { Tag } from "adminComponents/atoms/Tag";
import { Text } from "adminComponents/atoms/Text";
import { TextLink } from "adminComponents/atoms/TextLink";
import { ClassDetailHeader } from "adminComponents/molecules/ClassDetailHeader";
import {
  IProps as LiveBannerProps,
  LiveClassroomBanner,
} from "adminComponents/molecules/LiveClassroomBanner";
import {
  INavigationHeaderProps,
  NavigationHeader,
} from "adminComponents/molecules/NavigationHeader";
import { TemplateWithCenteredHeroOneColumn } from "adminComponents/templates/TemplateWithCenteredHeroOneColumn";
import { pxToRem } from "adminComponents/utils";
import { useAuth } from "links/lib/features/auth";
import {
  IClassroom,
  IGradeLevel,
  IPracticeSetSession,
  ISubject,
} from "links/lib/types";

export interface IProps {
  accuracy?: number;
  classroom?: IClassroom;
  subjects: Array<ISubject>;
  gradeLevels: Array<IGradeLevel>;
  children: ReactNode;
  navigationData: INavigationHeaderProps;
  handleEdit: (classroom: IClassroom) => void;
  handleDuplicate: (classroom: IClassroom) => void;
  handleArchive: (classroom: IClassroom) => void;
  handleDelete: (classroom: IClassroom) => void;
  handleTabChange: (tab: ClassroomDetailTab) => void;
  isLoading?: boolean;
  tabIndex?: number;
  liveBanners: Array<Omit<LiveBannerProps, "handleJoinSession">>;
  handleJoinSession: (session: IPracticeSetSession) => void;
  showPremiumMarker?: boolean;
}

export enum ClassroomDetailTab {
  Students = 0,
  // Renders sets this group has practiced, but the UI calls this tab "Class Accuracy"
  PracticeSets = 1,
  Assignments = 2,
  Standards = 3,
  Teachers = 4,
}

export const ClassroomDetailScreen: React.FC<IProps> = ({
  accuracy,
  classroom,
  handleArchive,
  handleDelete,
  handleDuplicate,
  handleEdit,
  isLoading,
  navigationData,
  gradeLevels,
  subjects,
  children,
  handleTabChange,
  tabIndex,
  liveBanners,
  handleJoinSession,
  showPremiumMarker = true,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const { isFeatureEnabled } = useAuth();

  const tabData = [
    {
      label: t("classDetailTabs.tabStudents"),
      content: <></>,
    },
    {
      label: t("classDetailTabs.tabPracticeSets"),
      content: <></>,
    },
    {
      label: t("classDetailTabs.tabAssignments"),
      content: <></>,
    },
    {
      label: t("classDetailTabs.tabStandards"),
      content: <></>,
      isPremium: showPremiumMarker,
    },
  ];

  if (isFeatureEnabled("playtime.teacher.enable_coteachers")) {
    tabData.push({
      label: t("classDetailTabs.tabTeachers"),
      content: <></>,
    });
  }

  return (
    <TemplateWithCenteredHeroOneColumn
      nav={<NavigationHeader {...navigationData} isLoading={isLoading} />}
      heroContent={
        <>
          {classroom ? (
            <ClassDetailHeader
              headerAs="h1"
              accuracy={accuracy}
              classroom={classroom}
              handleEdit={handleEdit}
              handleDuplicate={handleDuplicate}
              handleArchive={handleArchive}
              handleDelete={handleDelete}
              gradeLevels={gradeLevels}
              subjects={subjects}
            />
          ) : (
            <></>
          )}
        </>
      }
      isLoading={isLoading}
      variant="default"
    >
      {isFeatureEnabled("playtime.enable_assignment_gradebook") && (
        <Box marginBottom={pxToRem(20)}>
          <Banner>
            <Flex justifyContent="left" alignItems="center" gap={pxToRem(5)}>
              <Tag colorScheme="utility.badge-blue">{t("common.new")}</Tag>
              <Text fontSize={pxToRem(18)}>{t("classrooms.tryNew")}</Text>
              <TextLink
                size="lg"
                navigationLink={{
                  to: `/t/classrooms/${classroom?.id}/assignment-gradebook`,
                  label: "label",
                  isExternal: false,
                }}
              >
                {t("classrooms.assignmentGradebook")}
              </TextLink>
            </Flex>
          </Banner>
        </Box>
      )}
      {!!liveBanners.length && (
        <Flex marginBottom={pxToRem(32)} direction="column" gap={pxToRem(32)}>
          {liveBanners.map((l) => (
            <LiveClassroomBanner
              key={l.session.id}
              {...l}
              showClassroomName={false}
              alwaysShowPracticeSetName
              joinButtonTextPrefix="openSessionButton"
              handleJoinSession={handleJoinSession}
            />
          ))}
        </Flex>
      )}

      <Tabs
        tabIndex={tabIndex}
        handleChange={handleTabChange}
        tabData={tabData}
        variant="adminScreenTabs"
      />
      {!isLoading && children}
    </TemplateWithCenteredHeroOneColumn>
  );
};
