import { Box, usePrefersReducedMotion } from "@chakra-ui/react";
import lottie, { AnimationItem } from "lottie-web/build/player/lottie_light";
import React, { useEffect, useRef, useState } from "react";
import { animated, config, useSpring } from "react-spring";
import { useMount, useUnmount } from "react-use";

import { StudentSessionSoundEffect } from "sessionComponents/types";
import { useSessionAudio } from "sharedComponents/contexts/sessionAudio";

import animationData from "./resource/level-up-ticket-earned.json";

const animationDataString = JSON.stringify(animationData);

interface ICarePackageDeliveryProps {
  show: boolean;
  handleComplete?: () => void;
}

const AnimatedBox = animated(Box);

const endFrame = 200;

export const LevelUp: React.FC<ICarePackageDeliveryProps> = ({
  show,
  handleComplete,
}) => {
  const playerRef = useRef<HTMLDivElement | null>(null);
  const animRef = useRef<AnimationItem | null>(null);
  const { play: playAudio } = useSessionAudio();
  const reduceMotion = usePrefersReducedMotion();
  const [splashAtRest, setSplashAtRest] = useState(true);

  const splashSpring = useSpring({
    from: {
      opacity: 0,
      scale: reduceMotion ? 1 : 0,
    },
    to: {
      opacity: show ? 1 : 0,
      scale: show ? 1 : 0,
    },
    config: {
      ...config.gentle,
      friction: 20,
    },
    onRest: () => setSplashAtRest(true),
  });

  useMount(() => {
    if (!playerRef.current) return;

    animRef.current = lottie.loadAnimation({
      container: playerRef.current,
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: JSON.parse(animationDataString),
    });
  });

  useEffect(() => {
    setSplashAtRest(false);
  }, [show]);

  useEffect(() => {
    if (!show) {
      animRef?.current?.stop();
      return;
    }

    if (!animRef.current) return;

    const playAnimation = () => {
      if (!animRef.current) return;

      animRef.current.goToAndPlay(0);
      playAudio(StudentSessionSoundEffect.LevelUp);
    };

    let hasCompleted = false;
    const enterFrame = ({ currentTime }: { currentTime: number }) => {
      if (!hasCompleted && currentTime > endFrame) {
        hasCompleted = true;
        handleComplete?.();
      }
    };

    if (animRef.current.isLoaded) {
      playAnimation();
    } else {
      animRef.current.addEventListener("DOMLoaded", playAnimation);
    }

    animRef.current.addEventListener("enterFrame", enterFrame);

    return () => {
      animRef.current?.removeEventListener("DOMLoaded", playAnimation);
      animRef.current?.removeEventListener("enterFrame", enterFrame);
      animRef.current?.stop();
      animRef.current?.destroy();
      lottie.stop();
      lottie.destroy();
    };
  }, [show, playAudio, handleComplete]);

  useUnmount(() => {
    animRef.current?.destroy();
  });

  return (
    <AnimatedBox
      position="fixed"
      top="0"
      left="0"
      right="0"
      bottom="0"
      bg="podium.levelUpBackground"
      display={show || !splashAtRest ? "block" : "none"}
      style={splashSpring}
      zIndex="20"
    >
      <Box
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        ref={playerRef}
      />
    </AnimatedBox>
  );
};
