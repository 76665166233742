// todo can this be consolidated with the adminComponents version?
import { Box, BoxProps } from "@chakra-ui/react";
import React from "react";

import { ColorScheme } from "../../../adminComponents";
import { pxToRem } from "../../../adminComponents/utils/pxToRem";

import { useBreakpoints } from "../../contexts/breakpoints";

export interface ICardProps extends BoxProps {
  borderColor?: ColorScheme;
  backgroundColor?: ColorScheme;
  borderWidth?: string;
  paddingX?: string;
  paddingY?: string;
  borderRadius?: string;
  useThickBorder?: boolean;
  height?: string;
  width?: string;
}

const Card: React.FC<ICardProps> = ({
  borderColor = "primary.tan",
  backgroundColor = "primary.white",
  borderWidth,
  paddingX,
  paddingY,
  borderRadius,
  useThickBorder = false,
  height,
  width,
  children,
  ...rest
}) => {
  const { match: currentBreakpoints } = useBreakpoints();

  const defaultBorderWidth = useThickBorder
    ? currentBreakpoints.borderWidth * 3
    : currentBreakpoints.borderWidth;

  return (
    <Box
      border="solid"
      borderWidth={borderWidth ?? pxToRem(defaultBorderWidth)}
      borderRadius={borderRadius ?? pxToRem(currentBreakpoints.borderRadius)}
      borderColor={borderColor}
      paddingX={paddingX ?? pxToRem(currentBreakpoints.padding)}
      paddingY={paddingY ?? pxToRem(currentBreakpoints.padding)}
      background={backgroundColor}
      height={height ?? "100%"}
      width={width ?? "100%"}
      {...rest}
    >
      {children}
    </Box>
  );
};

export { Card };
