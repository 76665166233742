import { LLMModel } from "links/lib/features/llm/usePerformLLMChat";

// actions to take on the results of a step to facilitate things like saving a practice set or skill
export enum PostStepAction {
  NONE = "None",
  PARSE_PRACTICE_SET = "Parse Practice Set",
  PARSE_SKILLS = "Parse Skills",
}

export interface Step {
  name?: string;
  prompt?: string;
  autoAdvanceToNextStep?: boolean;
  postStepAction?: PostStepAction;
  results?: string;
  // meant for the first step but could have future uses
  extraInput?: string;
  llmModel?: LLMModel;
}
export interface ProcessFlow {
  name: string;
  steps: Step[];
  seed?: number;
  // allow for step 1 to store something for step 12 to include in its input
  globalInputs?: { [stepIndex: number]: { [key: string]: string } };
}
