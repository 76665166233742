import { AxiosInstance } from "axios";
import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { IAverageStandardAccuracy } from "../../types";

export interface IUseFetchClassroomAverageStandardAccuraciesProps {
  classroom_id: string;
  user_id?: string;
  practice_set_id?: string;
  assignment_id?: string;
  practice_set_session_id?: string;
  enabled?: boolean;
}

export interface IUseFetchClassroomAverageStandardAccuraciesResult {
  average_standard_accuracies: Array<IAverageStandardAccuracy>;
}

export const fetchClassroomStandardAccuraciesQuery = (
  axios: AxiosInstance,
  params: IUseFetchClassroomAverageStandardAccuraciesProps
): (() => Promise<IUseFetchClassroomAverageStandardAccuraciesResult>) => {
  const {
    classroom_id,
    user_id,
    assignment_id,
    practice_set_id,
    practice_set_session_id,
  } = params;

  return async (): Promise<IUseFetchClassroomAverageStandardAccuraciesResult> => {
    return await axios
      .get(`/v1/accuracies/standards`, {
        params: {
          group_id: classroom_id,
          user_id,
          practice_set_id,
          assignment_id,
          practice_set_session_id,
        },
      })
      .then((r) => r.data as IUseFetchClassroomAverageStandardAccuraciesResult);
  };
};

export default function useFetchClassroomStandardAccuracies(
  params: IUseFetchClassroomAverageStandardAccuraciesProps
): UseQueryResult<IUseFetchClassroomAverageStandardAccuraciesResult, unknown> {
  const axios = useAxios();
  const { enabled = true } = params;

  const query = useQuery<IUseFetchClassroomAverageStandardAccuraciesResult>(
    ["standard-accuracies", params],
    fetchClassroomStandardAccuraciesQuery(axios, params),
    { enabled }
  );

  return query;
}
