const MAX_IMAGE_DIMENSION = 2048;

// Checks whether a the resolution of an image file exceeds the
// maximum permitted dimensions. If it does not, it returns the
// image file. Otherwise it generates a new image file within
// the maximum permitted dimensions and returns it.
export const checkResizeImageFile = (file: File): Promise<File> => {
  return new Promise((resolve) => {
    try {
      const fileReader = new FileReader();

      fileReader.onload = () => {
        const img = document.createElement("img");

        img.onload = () => {
          if (
            img.width <= MAX_IMAGE_DIMENSION &&
            img.height <= MAX_IMAGE_DIMENSION
          ) {
            resolve(file);
            return;
          }

          const canvas = document.createElement("canvas");

          if (img.width > img.height) {
            canvas.width = MAX_IMAGE_DIMENSION;
            canvas.height = canvas.width * (img.height / img.width);
          } else {
            canvas.height = MAX_IMAGE_DIMENSION;
            canvas.width = canvas.height * (img.width / img.height);
          }

          const ctx = canvas.getContext("2d");
          if (!ctx) {
            resolve(file);
            return;
          }

          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

          // New images will be rendered as JPEGs to apply
          // the appropriate file extension.
          let fileName = file.name;
          const fileNameParts = file.name.split(".");
          if (fileNameParts.length === 1) {
            fileName = file.name + ".jpg";
          } else {
            fileNameParts.pop();
            fileNameParts.push("jpg");
            fileName = fileNameParts.join(".");
          }

          canvas.toBlob(
            (blob) => {
              if (!blob) {
                resolve(file);
                return;
              }

              resolve(new File([blob], fileName));
            },
            "image/jpeg",
            0.75
          );
        };

        if (!fileReader.result) return;

        img.src = fileReader.result as string;
      };

      fileReader.readAsDataURL(file);
    } catch (_) {
      resolve(file);
    }
  });
};
