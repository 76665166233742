import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { useAxios } from "../../hooks/useAxios";

export interface IUseRemoveClassroomUserArgs {
  onSuccess?: () => void;
}

export interface IRemoveClassroomUserArgs {
  classroomId: string;
  email: string;
}

/**
 * Removes a student from a classroom
 * @returns
 */
export default function useRemoveClassroomUser({
  onSuccess,
}: IUseRemoveClassroomUserArgs): UseMutationResult<
  unknown,
  unknown,
  IRemoveClassroomUserArgs
> {
  const axios = useAxios();
  const queryClient = useQueryClient();

  const removeStudent = async (args: IRemoveClassroomUserArgs) => {
    const { classroomId, email } = args;

    const response = await axios({
      method: "delete",
      url: `/v1/groups/${classroomId}/users/${encodeURIComponent(email)}`,
    });

    queryClient.invalidateQueries(["classrooms", { id: classroomId }]);
    queryClient.invalidateQueries(["classroom-users", { id: classroomId }], {
      exact: false,
    });

    return response;
  };

  const mutation = useMutation(removeStudent, { onSuccess });

  return mutation;
}
