export default {
  variants: {
    gameText: (props: { [key: string]: string }): Record<string, unknown> => ({
      field: {
        borderRadius: "none",
        bg: "white",
        borderColor: "smoke.base",
        borderStyle: "solid",
        color: "smoke.04",
        textStyle: "gameLabel",
        "::placeholder": {
          color: "smoke.base-highlight-02",
        },
        fontSize: () => {
          switch (props.size) {
            case "sm":
              return ".75rem";
            case "md":
              return "1rem";
            case "lg":
              return "1.25rem";
          }
        },
        borderWidth: () => {
          switch (props.size) {
            case "sm":
              return "2px";
            case "md":
              return "2px";
            case "lg":
              return "3px";
          }
        },
        boxShadow: () => {
          switch (props.size) {
            case "sm":
              return "4px 4px var(--chakra-colors-smoke-base-shadow-02)";
            case "md":
              return "4px 4px var(--chakra-colors-smoke-base-shadow-02)";
            case "lg":
              return "4px 4px var(--chakra-colors-smoke-base-shadow-02)";
          }
        },
        _focus: {
          boxShadow: "none",
          borderColor: "golden.base",
        },
      },
    }),
  },
};
