import {
  Box,
  Flex,
  HStack,
  Image,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { string } from "yup";

import GoogleClassroomImage from "adminComponents/assets/GoogleClassroom.svg";
import { Button } from "adminComponents/atoms/Button";
import { Dropdown, IOption } from "adminComponents/atoms/Dropdown";
import { Heading } from "adminComponents/atoms/Heading";
import { Icon } from "adminComponents/atoms/Icon";
import { Input } from "adminComponents/atoms/Input";
import { Modal } from "adminComponents/atoms/Modal";
import { Text } from "adminComponents/atoms/Text";
import {
  IOnboardingForm,
  getDefaultValues,
} from "adminComponents/screens/TeacherOnboarding";
import { TeacherOnboardingStep1 } from "adminComponents/screens/TeacherOnboarding/components/Step1";
import { TeacherOnboardingStep2 } from "adminComponents/screens/TeacherOnboarding/components/Step2";
import { generateTeacherName, pxToRem } from "adminComponents/utils";
import { useErrorToast, useShowToast } from "adminComponents/utils/toast";
import { useAnalytics } from "lib/contexts/analytics";
import { useHubSpot } from "lib/hooks/useHubSpot";
import {
  useFetchClassrooms,
  useMutateClassroom,
  useMutateClassroomUsers,
} from "links/lib/features/classrooms";
import { useGeo } from "links/lib/features/geo";
import { useCompleteTeacherOnboarding } from "links/lib/features/users";
import {
  AnalyticsEvent,
  IAgreement,
  IClassroom,
  IGradeLevel,
  ISharingSettings,
  ISubject,
  IUser,
  SharingSettingsVisibility,
  UserRole,
  UsersGroupsRole,
} from "links/lib/types";
import { useImportClassroomByGClassroom } from "screens/TeacherClassrooms/ClassroomDetail/components/StudentsTab/hooks/useImportClassroomByGClassroom";
import { TeacherTourStep3 } from "screens/TeacherTour/components/Step3";
import { useEditAvatar } from "sharedComponents/hooks/useEditAvatar";

interface IArgs {
  authUser?: IUser;
  authSharingSettings?: ISharingSettings;
  authRequiredAgreements?: Array<IAgreement>;
  gradeLevels: Array<IGradeLevel>;
  parentSubjects: Array<ISubject>;
  handleCompleteProfileModal?: () => void;
  handleCompleteClassroomModal?: () => void;
}

interface IResult {
  onOpenProfileModal: () => void;
  profileModal: JSX.Element;
  onOpenClassroomModal: () => void;
  classroomModal: JSX.Element;
  onOpenEditAvatarModal: () => void;
  editAvatarModal: JSX.Element;
}

export const useOnboardingModals = ({
  authUser,
  authSharingSettings,
  authRequiredAgreements,
  gradeLevels,
  parentSubjects,
  handleCompleteClassroomModal,
  handleCompleteProfileModal,
}: IArgs): IResult => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });
  const { trackEvent } = useAnalytics();
  const { trackHubSpotEvent } = useHubSpot();
  const showToast = useShowToast();
  const { geo } = useGeo();
  const queryClient = useQueryClient();
  const isMobile = useBreakpointValue({ base: true, md: false });

  const {
    isOpen: isProfileModalOpen,
    onOpen: onOpenProfileModal,
    onClose: onCloseProfileModal,
  } = useDisclosure();
  const { modal: editAvatarModal, onOpen: onOpenEditAvatarModal } =
    useEditAvatar({
      onDone: () => {
        queryClient.invalidateQueries(["onboarding-checklist"]);
      },
      onEquipAvatarItem: (avatarItemId, categoryId, colorHex) => {
        trackEvent(
          AnalyticsEvent.TeacherDashboard_Onboard_EditAvatar_EquipItem,
          {
            itemId: avatarItemId,
            itemCategoryId: categoryId,
            itemColorHexCode: colorHex,
          }
        );
      },
      onDequipAvatarItem: (avatarItemId, categoryId) => {
        if (!categoryId) return;

        trackEvent(
          AnalyticsEvent.TeacherDashboard_Onboard_EditAvatar_UnsetCategory,
          {
            itemCategoryId: categoryId,
          }
        );
      },
      onSetSkeletonType: (skeletonType) => {
        trackEvent(
          AnalyticsEvent.TeacherDashboard_Onboard_EditAvatar_SetSkeletonType,
          {
            skeletonType: skeletonType,
          }
        );
      },
      onSetHairColor: (hairColorHexCode) => {
        trackEvent(
          AnalyticsEvent.TeacherDashboard_Onboard_EditAvatar_SetHairColor,
          {
            colorHexCode: hairColorHexCode,
          }
        );
      },
      onSetSkinTone: (skinToneHexCode) => {
        trackEvent(
          AnalyticsEvent.TeacherDashboard_Onboard_EditAvatar_SetSkinTone,
          {
            toneHexCode: skinToneHexCode,
          }
        );
      },
    });
  const {
    isOpen: isClassroomModalOpen,
    onOpen: onOpenClassroomModal,
    onClose: onCloseClassroomModal,
  } = useDisclosure();

  const classroomFetch = useFetchClassrooms({
    disabled: !authUser || authUser?.is_guest,
  });
  useErrorToast(classroomFetch.error);

  const completeTeacherOnboarding = useCompleteTeacherOnboarding();
  useErrorToast(completeTeacherOnboarding.error);

  const {
    handleAddIntent: handleImportClassroomIntentByGClassroom,
    authorizeModal: importClassroomAuthorizeModal,
    classroomSelectModal: importClassroomClassroomSelectModal,
    studentSelectModal: importClassroomStudentSelectModal,
  } = useImportClassroomByGClassroom();

  // TODO: Introduce a boolean field on groups to indicate it was auto-created
  const autoCreatedClassroom: IClassroom | undefined = useMemo(() => {
    if (classroomFetch.data?.classrooms?.length === 1) {
      return classroomFetch.data.classrooms[0];
    }

    let groupName = "";
    if (authUser?.teacher_preferred_name?.endsWith("s")) {
      groupName = t("teacherTour.classPossessiveEndsWithS", {
        name: authUser?.teacher_preferred_name,
      });
    } else {
      groupName = t("teacherTour.classPossessive", {
        name: authUser?.teacher_preferred_name,
      });
    }

    return (classroomFetch.data?.classrooms || []).find(
      (classroom) => classroom.name === groupName
    );
  }, [authUser?.teacher_preferred_name, classroomFetch.data?.classrooms, t]);
  useEffect(() => {
    if (autoCreatedClassroom) {
      setClassroomName(autoCreatedClassroom.name);
      setClassroomPeriod(autoCreatedClassroom.description);
    }
  }, [autoCreatedClassroom]);

  const [addStudentsByEmailErrorString, setAddStudentsByEmailErrorString] =
    useState<string | undefined>();
  const [
    addStudentsByEmailDisableNotifications,
    setAddStudentsByEmailDisableNotifications,
  ] = useState(false);
  const [studentEmails, setStudentEmails] = useState("");
  const onMutateClassroomUsersSuccess = useCallback(() => {
    showToast(
      t("classroomDetailContainer.studentsTab.addByEmail.successToast")
    );

    trackHubSpotEvent(AnalyticsEvent.HubSpot_AddedStudentsToClassroom);
  }, [showToast, t, trackHubSpotEvent]);

  const studentsMutation = useMutateClassroomUsers({
    onSuccess: onMutateClassroomUsersSuccess,
  });
  const handleAddStudents = useCallback(
    (content: string) => {
      if (!autoCreatedClassroom) return;

      if (!content.trim()) {
        setAddStudentsByEmailErrorString(
          t(
            "classroomDetailContainer.studentsTab.addByEmail.errorEmailsRequired"
          )
        );
        return;
      }

      const emails = content
        .trim()
        .replaceAll("\n", ",")
        .split(",")
        .map((e) => e.trim())
        .filter((e) => e !== "");

      const isInvalid = emails.some((e) => !string().email().isValidSync(e));

      if (isInvalid) {
        setAddStudentsByEmailErrorString(
          t(
            "classroomDetailContainer.studentsTab.addByEmail.errorEmailsInvalid"
          )
        );
        return;
      }

      // passed validation checks - input is valid
      setAddStudentsByEmailErrorString(undefined);

      const users = emails.map((email): IUser => {
        return {
          id: "0",
          email: email,
          role: UserRole.Student,
          true_role: UserRole.Student,
          license_id: "0",
          timezone_locale: "",
          language: "",
          is_guest: false,
          country: "",
          region: "",
        };
      });

      studentsMutation.mutate({
        classroomId: autoCreatedClassroom.id,
        users,
        disableNotifications: addStudentsByEmailDisableNotifications,
        usersGroupsRole: UsersGroupsRole.Member,
      });

      trackEvent(
        AnalyticsEvent.TeacherDashboard_Onboard_AddStudents_AddByEmail,
        {}
      );
    },
    [
      autoCreatedClassroom,
      studentsMutation,
      addStudentsByEmailDisableNotifications,
      trackEvent,
      t,
    ]
  );

  const teacherGradeLevelIds = authUser?.teacher_grade_levels?.map(
    (teacherGradeLevel) => teacherGradeLevel.grade_level_id
  );

  const teacherSubjectIds = authUser?.teacher_subjects?.map(
    (teacherSubject) => teacherSubject.subject_id
  );

  const methods = useForm<IOnboardingForm>({
    defaultValues: getDefaultValues({
      acceptedAgreementIds: [],
      country: authUser?.country,
      grades: teacherGradeLevelIds,
      selectedGrades: gradeLevels
        .filter((gl) => teacherGradeLevelIds?.includes(gl.id))
        .map((gl) => ({ label: gl.grade_level, value: gl.id })),
      language: authUser?.language,
      title: authUser?.teacher_title,
      nickname:
        authUser?.teacher_preferred_name || authUser
          ? generateTeacherName(authUser).primary
          : "",
      state: authUser?.region || geo?.region_short || "",
      subjects: teacherSubjectIds,
      subjectIndexes: parentSubjects.map((ps) => {
        return teacherSubjectIds?.includes(ps.id) || false;
      }),
      timezone:
        Intl.DateTimeFormat().resolvedOptions().timeZone.replaceAll("_", " ") ||
        authUser?.timezone_locale.replaceAll("_", " "),
      schoolOrDistrictName: authUser?.teacher_school_or_district_name,
      schoolOrDistrictPostalCode:
        authUser?.teacher_school_or_district_postal_code,
      sharingVisibility:
        authSharingSettings?.visibility || SharingSettingsVisibility.Public,
    }),
    mode: "onChange",
  });

  const handleSubmit = (data: IOnboardingForm) => {
    const acceptedAgreementIds = (authRequiredAgreements || []).map(
      (agreement) => agreement.id
    );
    const subjects = data.subjectIndexes.flatMap((v, i) => {
      if (!v) return [];
      const subject = parentSubjects[i];
      if (!subject) return [];
      return [subject.id];
    });
    const grades = data.selectedGrades.map((option) => option.value);

    completeTeacherOnboarding.mutate({
      user: {
        teacher_preferred_name: data.nickname.trim(),
        teacher_title: data.title,
        teacher_school_or_district_name: data.schoolOrDistrictName,
        teacher_school_or_district_postal_code: data.schoolOrDistrictPostalCode,
        timezone_locale: data.timezone.replaceAll(" ", "_"),
        grade_level_ids: grades,
        subject_ids: subjects,
        language: data.language,
        country: data.country,
        region: data.state,
      },
      acceptedAgreementIds: acceptedAgreementIds,
      visibility: data.sharingVisibility,
    });
  };

  const [profileModalStep, setProfileModalStep] = useState(0);
  const profileModal = (
    <Modal
      size="6xl"
      isOpen={isProfileModalOpen}
      onClose={onCloseProfileModal}
      onBack={
        profileModalStep === 1
          ? () => setProfileModalStep(0)
          : onCloseProfileModal
      }
      modalBodyProps={{
        paddingLeft: "0px !important",
        paddingRight: "0px !important",
        paddingBottom: "0px !important",
      }}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          {profileModalStep === 0 && (
            <TeacherOnboardingStep1
              handleContinue={() => setProfileModalStep(1)}
              handleClose={onCloseProfileModal}
              isLoading={false}
              handleEditAvatarModalOpen={onOpenEditAvatarModal}
            />
          )}
          {profileModalStep === 1 && (
            <TeacherOnboardingStep2
              handleContinue={() => {
                handleSubmit(methods.getValues());
                trackHubSpotEvent(AnalyticsEvent.HubSpot_AccountCreated);
                handleCompleteProfileModal?.();
                onCloseProfileModal();
              }}
              isLoading={false}
              isFinalStep={true}
            />
          )}
        </form>
      </FormProvider>
    </Modal>
  );

  const classroomMutation = useMutateClassroom({});
  useErrorToast(classroomMutation.error);
  const [classroomModalStep, setClassroomModalStep] = useState(0);
  const [classroomName, setClassroomName] = useState(
    autoCreatedClassroom?.name || ""
  );
  const [classroomPeriod, setClassroomPeriod] = useState(
    autoCreatedClassroom?.description || ""
  );
  const gradeLevelOptions = gradeLevels.map((gl) => ({
    value: gl.id,
    label: gl.grade_level,
  }));
  const [classroomGrades, setClassroomGrades] = useState(
    autoCreatedClassroom?.group_grade_levels?.map((groupGradeLevel) => ({
      value: groupGradeLevel.grade_level_id,
      label:
        gradeLevels.find((gl) => gl.id === groupGradeLevel.grade_level_id)
          ?.grade_level || "",
    })) || []
  );
  const [classroomModalTab, setClassroomModalTab] = useState(0);

  const _handleCompleteClassroomModal = () => {
    if (classroomModalTab === 1) {
      handleAddStudents(studentEmails);
    }
    if (autoCreatedClassroom && classroomName) {
      classroomMutation.mutate({
        id: autoCreatedClassroom.id,
        name: classroomName,
        description: classroomPeriod,
        group_grade_levels: classroomGrades.map((grade) => ({
          grade_level_id: grade.value,
        })),
        group_subjects: autoCreatedClassroom.group_subjects,
        assignment_notifications_disabled:
          autoCreatedClassroom.assignment_notifications_disabled,
      });
    }
    handleCompleteClassroomModal?.();
    onCloseClassroomModal();
  };

  const classroomModal = (
    <>
      <Modal
        size="4xl"
        isOpen={isClassroomModalOpen}
        onClose={onCloseClassroomModal}
        onBack={
          classroomModalStep === 1
            ? () => setClassroomModalStep(0)
            : onCloseClassroomModal
        }
      >
        {autoCreatedClassroom && (
          <Flex pt={pxToRem(60)} flexDirection="column">
            {classroomModalStep === 0 && (
              <Flex
                w="full"
                flexDirection="column"
                gap={pxToRem(10)}
                py={pxToRem(30)}
                px={pxToRem(20)}
              >
                <Heading alignSelf="center" as="h1" variant="adminH2">
                  {t("teacherOnboardingModals.classroomModal.step1Title")}
                </Heading>
                <Text variant="adminP1" color="primary.dark-gray">
                  {t("teacherOnboardingModals.classroomModal.step1Description")}
                </Text>
                <Flex
                  w="full"
                  justifyContent="space-between"
                  flexDirection={isMobile ? "column-reverse" : "row"}
                >
                  <Flex
                    flexDirection="column"
                    alignItems="start"
                    w={isMobile ? "full" : "40%"}
                    gap={pxToRem(10)}
                    mt={pxToRem(20)}
                  >
                    <Text variant="adminP1">
                      {t("teacherOnboardingModals.classroomModal.nameLabel")}
                    </Text>
                    <Input
                      value={classroomName}
                      onChange={(e) => setClassroomName(e.target.value)}
                    />
                    <Text variant="adminP1">
                      {t("teacherOnboardingModals.classroomModal.periodLabel")}
                    </Text>
                    <Input
                      value={classroomPeriod}
                      onChange={(e) => setClassroomPeriod(e.target.value)}
                    />
                    <Text variant="adminP1">
                      {t("teacherOnboardingModals.classroomModal.gradeLabel")}
                    </Text>
                    <Box w="full">
                      <Dropdown
                        isMulti
                        options={gradeLevelOptions}
                        value={classroomGrades}
                        handleChange={(value) => {
                          setClassroomGrades(value as Array<IOption>);
                        }}
                      />
                    </Box>
                    <Flex
                      flexDirection={{ base: "column", md: "row" }}
                      gap={pxToRem(10)}
                      w="full"
                      mt={pxToRem(32)}
                    >
                      <Button
                        w={isMobile ? undefined : "70%"}
                        variant="adminButtonFilled"
                        onClick={() => setClassroomModalStep(1)}
                        isDisabled={!classroomName}
                      >
                        {t("common.continue")}
                      </Button>
                      <Button
                        variant="adminButtonOutlined"
                        onClick={onCloseClassroomModal}
                      >
                        {t("common.cancel")}
                      </Button>
                    </Flex>
                  </Flex>
                  <Flex
                    flexDirection="column"
                    alignItems="start"
                    bgColor="primary.tan"
                    borderRadius="xl"
                    p={pxToRem(36)}
                    gap={pxToRem(14)}
                    w={isMobile ? "full" : "50%"}
                  >
                    <HStack>
                      <Icon icon="zap" />
                      <Text variant="adminP1" fontWeight={500}>
                        {t(
                          "teacherOnboardingModals.classroomModal.googleClassroom"
                        )}
                      </Text>
                    </HStack>
                    <Text variant="adminP1">
                      {t(
                        "teacherOnboardingModals.classroomModal.googleClassroomDescription"
                      )}
                    </Text>
                    <Button
                      w="full"
                      variant="adminButtonOutlined"
                      onClick={handleImportClassroomIntentByGClassroom}
                      leftIcon={
                        <Image w={pxToRem(32)} src={GoogleClassroomImage} />
                      }
                    >
                      {t("classrooms.importClassroomGClassroom")}
                    </Button>
                  </Flex>
                </Flex>
              </Flex>
            )}
            {classroomModalStep === 1 && (
              <>
                <TeacherTourStep3
                  classroom={autoCreatedClassroom}
                  disableNotifications={addStudentsByEmailDisableNotifications}
                  emailsString={studentEmails}
                  errorString={addStudentsByEmailErrorString}
                  setDisableNotifications={
                    setAddStudentsByEmailDisableNotifications
                  }
                  setEmailsString={setStudentEmails}
                  tab={classroomModalTab}
                  handleChangeTab={setClassroomModalTab}
                />
                <HStack justifyContent="center" w="full" mt={pxToRem(32)}>
                  <Button
                    w="40%"
                    variant="adminButtonFilled"
                    onClick={_handleCompleteClassroomModal}
                  >
                    {t("common.complete")}
                  </Button>
                  <Button
                    variant="adminButtonOutlined"
                    onClick={onCloseClassroomModal}
                  >
                    {t("common.cancel")}
                  </Button>
                </HStack>
              </>
            )}
          </Flex>
        )}
      </Modal>
      {importClassroomAuthorizeModal}
      {importClassroomClassroomSelectModal}
      {importClassroomStudentSelectModal}
    </>
  );

  return {
    onOpenProfileModal,
    profileModal,
    onOpenClassroomModal,
    classroomModal,
    onOpenEditAvatarModal,
    editAvatarModal,
  };
};
