import { pxToRem } from "adminComponents/utils/pxToRem";

export const AdminFlyout = {
  baseStyle: (): Record<string, unknown> => ({
    backButton: {
      top: pxToRem(24),
      left: pxToRem(15),
    },
    backIcon: {
      marginRight: pxToRem(5),
    },
    closeButton: {
      top: pxToRem(24),
      right: pxToRem(24),
      left: "unset",
    },
    subtitle: {
      color: "primary.dark-gray",
      marginTop: pxToRem(10),
    },
    footer: {
      boxShadow:
        "0 -4px 6px -1px rgba(0, 0, 0, 0.1), 0 -2px 4px -1px rgba(0, 0, 0, 0.06);",
      justifyContent: "flex-start",
      zIndex: 1,
    },
  }),
};
