import { Flex, HStack, useBreakpointValue } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  CleverSignInButton,
  GoogleSignInButton,
  MicrosoftSignInButton,
} from "adminComponents/atoms/ExternalAccountButton";
import { Heading } from "adminComponents/atoms/Heading";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils/pxToRem";
import i18n from "lib/i18n";
import { UserRole } from "links/lib/types";
import useFetchProviderRedirectUrl from "screens/SignIn/useFetchProviderRedirectUrl";
import { Card } from "sessionComponents/atoms/Card";
import { Icon } from "sessionComponents/atoms/Icon";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { openSignInPopup } from "sessionComponents/utils/openSignInPopup";

interface ISignInRequiredProps {
  goBack: () => void;
  joinCode: string;
}

export const SignInRequired: React.FC<ISignInRequiredProps> = ({
  goBack,
  joinCode,
}) => {
  const history = useHistory();
  const { match: currentBreakpoints } = useBreakpoints();
  const { t } = useTranslation("session", {
    keyPrefix: "signInRequiredScreen",
    useSuspense: false,
  });

  const padding = useBreakpointValue({ base: 24, sm: 24, md: 28 }) || 24;
  const { isLoading, error, data, execute } = useFetchProviderRedirectUrl();

  useEffect(() => {
    if (error) console.error(error);
  }, [error]);

  useEffect(() => {
    if (data?.redirectUrl) {
      openSignInPopup({
        url: data.redirectUrl,
        onSignInSuccess: () => {
          history.push(`/session/join/${joinCode}`);
          window.location.reload();
        },
      });
    }
  }, [data?.redirectUrl, history, joinCode]);

  const onSignIn = (provider: "Google" | "Clever" | "Microsoft") => {
    execute({
      provider,
      role: UserRole.Uninitialized,
      join_code: joinCode,
      language: i18n.language,
      popup: true,
    });
  };

  return (
    <Flex
      flexDir="column"
      alignItems="center"
      justifyContent="space-between"
      p={pxToRem(padding)}
      gap={pxToRem(padding)}
    >
      <HStack>
        <Icon
          icon="correct"
          iconColor="utility.question-green"
          boxSize={pxToRem(currentBreakpoints.iconSize)}
        />
        <Text
          as="span"
          textStyle="gameText"
          fontSize={pxToRem(currentBreakpoints.fontSize)}
        >
          {t("connectedToHeading")} <b>{joinCode}</b>
        </Text>
      </HStack>
      <Card>
        <Flex
          alignItems="center"
          flexDirection="column"
          p={pxToRem(padding)}
          gap={pxToRem(padding / 2)}
        >
          <Heading as="h2" variant="adminH3">
            {t("signInText")}
          </Heading>
          <Text
            as="span"
            textStyle="gameText"
            fontSize={pxToRem(currentBreakpoints.fontSize / 1.5)}
          >
            {t("description")}
          </Text>
          <Flex
            flexDirection="column"
            gap={pxToRem(padding / 2)}
            mt={pxToRem(padding)}
            mb={pxToRem(padding * 2)}
          >
            <GoogleSignInButton
              handleOnButtonClick={() => onSignIn("Google")}
              isLoading={isLoading}
            />
            <CleverSignInButton
              handleOnButtonClick={() => onSignIn("Clever")}
              isLoading={isLoading}
            />
            <MicrosoftSignInButton
              handleOnButtonClick={() => onSignIn("Microsoft")}
              isLoading={isLoading}
            />
          </Flex>
          <Text
            variant="adminLinkMedium"
            color="utility.link"
            cursor="pointer"
            onClick={goBack}
            textAlign="center"
          >
            {t("goBackText")}
          </Text>
        </Flex>
      </Card>
    </Flex>
  );
};
