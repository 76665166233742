import { Box, Flex, Grid, SimpleGrid } from "@chakra-ui/react";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";

import { Banner, IProps as IBannerProps } from "adminComponents/atoms/Banner";
import { Button } from "adminComponents/atoms/Button";
import { Heading } from "adminComponents/atoms/Heading";
import { LoadingSpinner } from "adminComponents/atoms/LoadingSpinner";
import { Tabs } from "adminComponents/atoms/Tabs";
import { Text } from "adminComponents/atoms/Text";
import { EmptyCard } from "adminComponents/molecules/EmptyCard";
import {
  IProps as ILibraryPracticeSetCard,
  LibraryPracticeSetCard,
} from "adminComponents/molecules/LibraryPracticeSetCard";
import {
  INavigationHeaderProps,
  NavigationHeader,
} from "adminComponents/molecules/NavigationHeader";
import {
  IProps as IPaginationProps,
  Pagination,
} from "adminComponents/molecules/Pagination";
import { SearchResultsPlaceholder } from "adminComponents/molecules/SearchResultsPlaceholder";
import {
  IProps as ILibrarySearchFiltersProps,
  LibrarySearchFilters,
} from "adminComponents/organisms/LibrarySearchFilters";
import { LibrarySubjectPicker } from "adminComponents/organisms/LibrarySubjectPicker";
import { TemplateWithCenteredHeroOneColumn } from "adminComponents/templates/TemplateWithCenteredHeroOneColumn";
import { pxToRem } from "adminComponents/utils";
import {
  IGradeLevel,
  ISubject,
  ISubjectCount,
  IUser,
  PracticeSetAvailability,
} from "links/lib/types";

export enum LibraryTab {
  Public = 0,
  Domain = 1,
}

interface IProps {
  authUser?: IUser;
  banner: Omit<IBannerProps, "children">;
  gradeLevels: IGradeLevel[];
  handleChangeSubjects: (selectedIds: string[]) => void;
  handleCreatePracticeSet: () => void;
  handleFilterByStandards: () => void;
  isLoading: boolean;
  navigationData: INavigationHeaderProps;
  paginate?: Omit<IPaginationProps, "totalCount">;
  practiceSets?: Omit<
    ILibraryPracticeSetCard,
    | "handleDelete"
    | "handleDuplicate"
    | "handleMoveToFolder"
    | "handleRemoveFromFolder"
  >[];
  search: Omit<
    ILibrarySearchFiltersProps,
    "gradeLevels" | "isLoading" | "subjects" | "totalCount"
  >;
  subjectCounts: ISubjectCount[];
  subjects: ISubject[];
  totalCount: number;
  isSearchLoading: boolean;
  availability: PracticeSetAvailability;
  showPremiumMarker?: boolean;
  hasNoPremiumAccess?: boolean;
}

export const PublicLibraryScreen: React.FC<IProps> = ({
  authUser,
  banner,
  gradeLevels,
  handleChangeSubjects,
  handleCreatePracticeSet,
  handleFilterByStandards,
  isLoading,
  navigationData,
  paginate,
  practiceSets,
  search,
  subjectCounts,
  subjects,
  totalCount,
  isSearchLoading,
  availability,
  showPremiumMarker = true,
  hasNoPremiumAccess = false,
}) => {
  const { t: tAdmin } = useTranslation("admin", {
    useSuspense: false,
  });
  const { t } = useTranslation("admin", {
    keyPrefix: "sharedLibraries",
    useSuspense: false,
  });
  const history = useHistory();

  const { subjectIds } = search;

  const isPublicTab = useRouteMatch("/t/public-library");
  const isDomainTab = useRouteMatch("/t/my-school-library");

  const hideContent = isDomainTab && hasNoPremiumAccess;

  const tabIndex = useMemo(() => {
    if (isPublicTab) {
      return LibraryTab.Public;
    }

    if (isDomainTab) {
      return LibraryTab.Domain;
    }
  }, [isPublicTab, isDomainTab]);

  const handleTabChange = useCallback(
    (tab: LibraryTab) => {
      switch (tab) {
        case LibraryTab.Public:
          history.push(`/t/public-library`);
          return;
        case LibraryTab.Domain:
          history.push(`/t/my-school-library`);
          return;
      }
    },
    [history]
  );

  return (
    <TemplateWithCenteredHeroOneColumn
      nav={<NavigationHeader {...navigationData} isLoading={isLoading} />}
      heroContent={
        <>
          <Heading
            as="h1"
            mb={{ base: pxToRem(10), md: pxToRem(20) }}
            variant="adminH2"
          >
            {t("headerTitle")}
          </Heading>
          <Text variant="adminP1">{t("headerText")}</Text>
        </>
      }
      isLoading={isLoading}
    >
      <Tabs
        tabIndex={tabIndex}
        handleChange={handleTabChange}
        tabData={[
          {
            label: tAdmin("sharedLibraryTabs.tabPublic"),
            content: <></>,
          },
          {
            label: tAdmin("sharedLibraryTabs.tabDomain"),
            content: <></>,
            isPremium: showPremiumMarker,
            disablePremium: hasNoPremiumAccess,
          },
        ]}
        variant="adminScreenTabs"
      />
      <Heading
        as="h2"
        mt={{ base: pxToRem(12), md: pxToRem(24) }}
        mb={{ base: pxToRem(12), md: pxToRem(24) }}
        variant="adminH3"
      >
        {t(
          isPublicTab ? "browsePublicPracticeSets" : "browseDomainPracticeSets"
        )}
      </Heading>
      {hideContent && (
        <Box paddingTop={pxToRem(15)} h="50vh">
          <SearchResultsPlaceholder
            leftListRows={8}
            upgradeText={t("practiceSetsUpgradeText")}
          />
        </Box>
      )}
      {!hideContent && (
        <>
          <Banner {...banner} isDismissible>
            <Text variant="adminP1">
              {t("bannerText")}{" "}
              <Button
                onClick={handleFilterByStandards}
                variant="adminTextButtonLarge"
              >
                {t("filterByStandards")}
              </Button>
            </Text>
          </Banner>
          <Grid
            gap={pxToRem(56)}
            mt={{ base: pxToRem(16), md: pxToRem(48) }}
            templateColumns={{
              base: "1fr",
              xl: "minmax(" + pxToRem(200) + ", 1fr) 3fr",
            }}
          >
            <Box display={{ base: "none", xl: "block" }}>
              <LibrarySubjectPicker
                authUser={authUser}
                subjects={subjects}
                subjectCounts={subjectCounts}
                selectedSubjects={subjectIds}
                handleChangeSubjects={handleChangeSubjects}
              />
            </Box>
            <Box>
              <LibrarySearchFilters
                {...search}
                gradeLevels={gradeLevels}
                isLoading={isSearchLoading}
                subjectIds={subjectIds}
                subjects={subjects}
                totalCount={totalCount}
              />
              {practiceSets?.length ? (
                <>
                  <SimpleGrid
                    columns={{ base: 1, sm: 2, md: 3 }}
                    mt={{ base: pxToRem(24), md: pxToRem(32) }}
                    spacing={pxToRem(10)}
                  >
                    {practiceSets.map((practiceSetData) => {
                      const { practiceSet } = practiceSetData;

                      return (
                        <LibraryPracticeSetCard
                          {...practiceSetData}
                          isShared
                          authorName={practiceSet.author_name}
                          key={`practice-set-${practiceSet.id}`}
                          practiceSet={practiceSet}
                          showPremiumMarker={showPremiumMarker}
                          authUser={authUser}
                        />
                      );
                    })}
                  </SimpleGrid>
                  {paginate && (
                    <Flex
                      justifyContent="center"
                      mt={{ base: pxToRem(24), md: pxToRem(32) }}
                    >
                      <Pagination
                        {...paginate}
                        totalCount={totalCount}
                        authUser={authUser}
                      />
                    </Flex>
                  )}
                </>
              ) : (
                <Box mt={{ base: pxToRem(12), md: pxToRem(24) }}>
                  {isSearchLoading ? (
                    <LoadingSpinner />
                  ) : (
                    <EmptyCard
                      actions={[
                        {
                          onClick: handleCreatePracticeSet,
                          text: t("emptyButtonText"),
                          variant: "adminButtonFilled",
                        },
                      ]}
                      text={t("emptyText")}
                      title={t(
                        availability === PracticeSetAvailability.Public
                          ? "emptyTitlePublic"
                          : "emptyTitleDomain"
                      )}
                    />
                  )}
                </Box>
              )}
            </Box>
          </Grid>
        </>
      )}
    </TemplateWithCenteredHeroOneColumn>
  );
};
