import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { ICollection } from "../../types";

export interface IFetchCollectionParams {
  id?: string;
}

export interface IFetchCollectionResponse {
  collection: ICollection;
}

/**
 * Fetches a collection
 * @returns
 */
export default function useFetchCollection(
  params: IFetchCollectionParams
): UseQueryResult<IFetchCollectionResponse> {
  const axios = useAxios();

  const query = useQuery<IFetchCollectionResponse>(
    ["collections", { id: params.id }],
    async (): Promise<IFetchCollectionResponse> => {
      return await axios
        .get(`/v1/collections/${params.id}`)
        .then((r) => r.data as IFetchCollectionResponse);
    },
    // only run when id has been set which
    // allows for using as dependent query
    { enabled: !!params.id }
  );

  return query;
}
