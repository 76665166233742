import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { ISharingSettings, IUser } from "../../types";
import { useAuth } from "../auth";

export interface IMutateAuthUserArgs {
  custom_url_code?: string;
  teacher_preferred_name?: string;
  teacher_title?: string;
  teacher_school_or_district_name?: string;
  teacher_school_or_district_postal_code?: string;
  timezone_locale?: string;
  grade_level_ids?: string[];
  subject_ids?: string[];
  language?: string;
  country?: string;
  region?: string;
  visibility?: string;
  share_statistics?: boolean;
  share_grade_levels?: boolean;
  share_subjects?: boolean;
  share_region?: boolean;
}

export interface IMutateAuthUserResponse {
  user: IUser;
  sharing_settings: ISharingSettings;
}

/**
 * Updated the properties of the auth user
 * @returns
 */
export default function useMutateAuthUser(): UseMutationResult<
  IMutateAuthUserResponse,
  unknown,
  IMutateAuthUserArgs
> {
  const axios = useAxios();
  const queryClient = useQueryClient();
  const {
    authUser,
    setAuth,
    authToken,
    authRequiredAgreements,
    authLicense,
    hasNoPremiumAccess,
    isAuthLoading,
    getFeatureFlags,
  } = useAuth();

  const mutateAuthUser = async (args: IMutateAuthUserArgs) => {
    if (!authUser) {
      throw new Error("Auth User not set");
    }

    const {
      custom_url_code,
      teacher_preferred_name,
      teacher_title,
      teacher_school_or_district_name,
      teacher_school_or_district_postal_code,
      timezone_locale,
      language,
      country,
      region,
      grade_level_ids,
      subject_ids,
      visibility,
      share_statistics,
      share_grade_levels,
      share_subjects,
      share_region,
    } = args;

    const providedShareSettings =
      visibility != undefined ||
      share_statistics != undefined ||
      share_grade_levels !== undefined ||
      share_subjects !== undefined ||
      share_region !== undefined;

    const {
      given_name,
      student_nickname,
      family_name,
      role,
      profile_image_url,
      is_guest,
    } = authUser;

    const response = await axios({
      method: "put",
      url: `/v1/users/self`,
      data: {
        user: {
          custom_url_code,
          given_name,
          family_name,
          student_nickname,
          teacher_preferred_name,
          teacher_title,
          teacher_school_or_district_name,
          teacher_school_or_district_postal_code,
          profile_image_url,
          role,
          timezone_locale,
          language,
          country,
          region,
          is_guest,
          teacher_grade_levels: grade_level_ids?.map((id) => {
            return { grade_level_id: id };
          }),
          teacher_subjects: subject_ids?.map((id) => {
            return { subject_id: id };
          }),
        },
        sharing_settings: providedShareSettings
          ? {
              visibility: visibility,
              share_statistics: share_statistics,
              share_grade_levels: share_grade_levels,
              share_subjects: share_subjects,
              share_region: share_region,
            }
          : undefined,
      },
    }).then((r) => r.data as IMutateAuthUserResponse);

    queryClient.invalidateQueries("users", { exact: true });
    queryClient.invalidateQueries("onboarding-checklist");

    // update app auth
    setAuth({
      authToken,
      authUser: response.user,
      authFeatureFlags: getFeatureFlags(),
      authRequiredAgreements,
      authSharingSettings: response.sharing_settings,
      authLicense,
      hasNoPremiumAccess,
      isGhostUser: response.user.ghost_user_email != "",
      isAuthLoading,
    });

    return response;
  };

  const mutation = useMutation(mutateAuthUser);

  return mutation;
}
