import { Portal } from "@chakra-ui/react";
import React, { useCallback } from "react";

import { SeasonMapSoundEffect } from "links/lib/types";
import { useAudio } from "sharedComponents/contexts/audio";

import { FullscreenAnimationTakeover } from "../FullscreenAnimationTakeover";
import { default as shipTransitionData } from "./resource/ship-transition.json";

export interface ISeason3MapTransitionProps {
  show: boolean;
  handleComplete: () => void;
}

export const Season3MapTransition: React.FC<ISeason3MapTransitionProps> = ({
  handleComplete,
  show,
}) => {
  const { play } = useAudio();

  const handlePlay = useCallback(() => {
    play(SeasonMapSoundEffect.ShipTransition);
  }, [play]);

  return (
    <Portal>
      <FullscreenAnimationTakeover
        bgColor="#0f231e"
        show={show}
        animationData={shipTransitionData}
        handleEnd={handleComplete}
        handlePlay={handlePlay}
      />
    </Portal>
  );
};
