import { ColorScheme } from "adminComponents";
import { hoverStyles, pxToRem } from "adminComponents/utils";

export const AdminCard = {
  baseStyle: ({
    backgroundColor,
    borderColor,
  }: {
    backgroundColor: ColorScheme;
    borderColor: ColorScheme;
  }): Record<string, unknown> => ({
    backgroundColor,
    color: borderColor,
    position: "relative",
    borderRadius: pxToRem(20),
    width: "100%",
  }),
  variants: {
    adminCardThickBorder: (props: {
      [key: string]: string;
    }): Record<string, unknown> => ({
      boxShadow: {
        base: `0 0 0 ${pxToRem(6)} inset`,
        md: `0 0 0 ${pxToRem(10)} inset`,
      },
      paddingX: props.noPadding
        ? null
        : { base: "admin.cards.small", md: "admin.cards.large" },
      paddingY: props.noPadding
        ? null
        : { base: "admin.cards.small", md: "admin.cards.medium" },
    }),
    adminCardThickBorderTall: {
      boxShadow: {
        base: `0 0 0 ${pxToRem(6)} inset`,
        md: `0 0 0 ${pxToRem(10)} inset`,
      },
      paddingBottom: { base: "admin.cards.small", md: "admin.cards.medium" },
      paddingTop: { base: "admin.cards.xl", md: "admin.cards.large" },
      paddingX: { base: "admin.cards.small", md: "admin.cards.large" },
    },
    adminCardMediumBorder: (props: {
      [key: string]: string;
    }): Record<string, unknown> => ({
      boxShadow: `0 0 0 ${pxToRem(6)} inset`,
      padding: props.noPadding ? null : "admin.cards.small",
    }),
    adminCardMediumBorderMediumPadding: {
      boxShadow: `0 0 0 ${pxToRem(6)} inset`,
      padding: ["admin.cards.small", null, "admin.cards.medium"],
    },
    adminCardSmallBorder: (props: {
      [key: string]: string;
    }): Record<string, unknown> => ({
      boxShadow: `0 0 0 ${pxToRem(3)} inset`,
      padding: props.noPadding
        ? null
        : ["admin.cards.small", null, "admin.cards.medium"],
    }),
    adminCardSmallBorderSmallPadding: {
      boxShadow: `0 0 0 ${pxToRem(3)} inset`,
      padding: "admin.cards.small",
    },
    adminCardSelectable: (props: {
      [key: string]: string;
    }): Record<string, unknown> => ({
      color: props.isSelected ? "utility.link" : "primary.tan",
      boxShadow: `0 0 0 ${pxToRem(3)} inset`,
      cursor: "pointer",
      padding: props.noPadding ? null : "admin.cards.small",
      transition: "color 250ms",
      _hover: hoverStyles({
        color: props.isSelected ? "utility.link" : "primary.golden",
      }),
      _focusVisible: {
        color: props.isSelected ? "utility.link" : "utility.focus",
        outline: "none",
      },
    }),
    adminCardSolid: {
      paddingX: "admin.cards.xxs",
      paddingY: "admin.cards.xs",
    },
    adminCardTable: {
      bg: "primary.white",
      boxShadow: `0 0 0 ${pxToRem(3)} inset`,
      paddingX: ["admin.cards.small", null, "admin.cards.xxs"],
      paddingY: ["admin.cards.xs", null, "admin.cards.xxs"],
    },
  },
};
