import { keyframes } from "@chakra-ui/react";
import { ChakraStylesConfig } from "chakra-react-select";

import { colorMapping } from "adminComponents/theme/colors";
import { hoverStyles, pxToRem } from "adminComponents/utils";

const fadeIn = keyframes`
0% {
  opacity: 0;
  transform: translateY(-5%);
}
100% {
  opacity: 1;
  transform: translateY(0);
}
`;

const animation = `${fadeIn} 250ms`;

const dropdownStyles: ChakraStylesConfig = {
  clearIndicator: (provided) => ({
    ...provided,
    display: "none",
  }),
  control: (provided, state) => ({
    // input
    ...provided,
    backgroundColor: "primary.white",
    borderTopRadius: ".5rem",
    borderBottomLeftRadius: state.menuIsOpen ? 0 : ".5rem",
    borderBottomRightRadius: state.menuIsOpen ? 0 : ".5rem",
    borderColor: "primary.medium-gray",
    color: "primary.warm-black",
    minHeight: pxToRem(48),
    padding: pxToRem(1),
    // adds 1px of padding for non-focus state to account for missing 1px of border
    _focus: {
      border: `${pxToRem(2)} solid ${colorMapping["utility.focus"]}`,
      padding: 0,
    },
    _invalid: {
      borderColor: "utility.error",
    },
    _disabled: {
      opacity: 0.5,
    },
    textStyle: "adminP2",
  }),
  downChevron: (provided, { selectProps }) => ({
    ...provided,
    color: "primary.warm-black",
    fontSize: "1.125rem",
    transform: `rotate(${selectProps.menuIsOpen ? -180 : 0}deg)`,
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    bg: "transparent",
  }),
  indicatorSeparator: () => ({
    // vertical line between imput text and chevron
    display: "none",
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: pxToRem(5),
  }),
  menuList: (provided) => ({
    ...provided,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderWidth: 0,
    borderColor: "primary.golden",
    minWidth: "unset",
  }),
  menu: (provided) => ({
    ...provided,
    my: 0,
    border: `${pxToRem(2)} solid ${colorMapping["utility.focus"]}`,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderTopWidth: 0,
    borderBottomRadius: ".5rem",
    animation: animation,
  }),
  multiValue: (provided) => ({
    // tag
    ...provided,
    bg: "primary.light-gray",
    borderRadius: "0.5rem",
  }),
  multiValueLabel: (provided) => ({
    // tag text
    ...provided,
    color: "primary.warm-black",
    textStyle: "adminP2",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    marginLeft: 0,
  }),
  option: (provided, state) => ({
    ...provided,
    color: "primary.warm-black",
    bg: () => {
      if (state.isSelected && state.isFocused) {
        return "primary.golden-light";
      } else if (state.isSelected) {
        return "primary.light-gray";
      } else if (state.isFocused) {
        return "primary.golden-light";
      } else {
        return "transparent";
      }
    },
    textStyle: "adminP2",
    _hover: hoverStyles({
      bg: "primary.golden-light",
    }),
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "primary.dark-gray",
    textStyle: "adminP2",
    paddingLeft: "0.5rem",
  }),
  singleValue: (provided) => ({
    ...provided,
    paddingLeft: "0.5rem",
  }),
};

const libraryDropdownInputStyles = {
  backgroundColor: "transparent",
  borderRadius: ".5rem",
  borderColor: "transparent",
  _hover: {
    borderColor: "transparent",
    cursor: "pointer",
  },
  _focus: {
    border: `${pxToRem(2)} solid ${colorMapping["utility.focus"]}`,
    padding: 0,
  },
};

const libraryDropdownInputStylesRoundedButton = {
  borderColor: "transparent",
  _hover: {
    borderColor: "transparent",
    cursor: "pointer",
    boxShadow: `0 0 0 ${pxToRem(2)} inset ${
      colorMapping["primary.golden-hover"]
    }`,
  },
  _focus: {
    border: `${pxToRem(2)} solid ${colorMapping["utility.focus"]}`,
  },
  borderRadius: "3xl",
  background: "primary.white",
  height: pxToRem(48),
};

const libraryDropdownMenuStyles = (
  dropdownWidth: number,
  dropdownMenuWidth: number
) => {
  return {
    border: `${pxToRem(2)} solid ${colorMapping["primary.tan"]}`,
    borderRadius: ".5rem",
    boxShadow: "5px 7px 27px -6px #0000001A",
    ml: pxToRem(-(dropdownMenuWidth - dropdownWidth) / 2),
    my: pxToRem(20),
    w: pxToRem(dropdownMenuWidth),
    zIndex: 3,
  };
};

const libraryDropdownOptionStyles = (state: {
  isSelected: boolean;
  isFocused: boolean;
}) => {
  return {
    bg: () => {
      if (state.isSelected) {
        return "primary.light-gray";
      } else {
        return "transparent";
      }
    },
    textStyle: "adminP2",
    color: "primary.dark-gray",
    w: "full",
    _hover: hoverStyles({
      bg: "primary.golden-light",
    }),
  };
};

const libraryDropdownPlaceholderStyles = {
  color: "primary.warm-black",
  textStyle: "adminP2",
  paddingLeft: pxToRem(16),
};

const libraryDropdownIndicatorStyles = {
  paddingInlineStart: 0,
  paddingInlineEnd: pxToRem(16),
  bg: "transparent",
};

const libraryValueContainerStyles = {
  padding: 0,
};

export interface IDropDownStyles {
  dropdownWidth: number;
  dropdownMenuWidth?: number;
  roundButtonStyle?: boolean;
}

// The following objects are used in the Library screens for the grade and subject dropdowns.
const chakraStylesGradeDropdown = ({
  dropdownWidth,
  dropdownMenuWidth = 300,
  roundButtonStyle,
}: IDropDownStyles): ChakraStylesConfig => {
  const controlStyles = roundButtonStyle
    ? libraryDropdownInputStylesRoundedButton
    : libraryDropdownInputStyles;
  return {
    control: (provided) => ({
      // input
      ...provided,
      ...controlStyles,
    }),
    menu: (provided) => ({
      ...provided,
      ...libraryDropdownMenuStyles(dropdownWidth, dropdownMenuWidth),
    }),
    menuList: (provided) => ({
      ...provided,
      display: "grid",
      gridTemplateColumns: "repeat(2, 1fr)",
      h: pxToRem(420),
      w: "full",
      zIndex: 3,
    }),
    option: (provided, state) => ({
      ...provided,
      ...libraryDropdownOptionStyles(state),
      pl: pxToRem(35),
    }),
    placeholder: (provided) => ({
      ...provided,
      ...libraryDropdownPlaceholderStyles,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      ...libraryDropdownIndicatorStyles,
    }),
    valueContainer: (provided) => ({
      ...provided,
      ...libraryValueContainerStyles,
    }),
  };
};

const chakraStylesSubjectDropdown = ({
  dropdownWidth,
  dropdownMenuWidth = 200,
  roundButtonStyle,
}: IDropDownStyles): ChakraStylesConfig => {
  const controlStyles = roundButtonStyle
    ? libraryDropdownInputStylesRoundedButton
    : libraryDropdownInputStyles;
  return {
    control: (provided) => ({
      // input
      ...provided,
      ...controlStyles,
    }),
    menu: (provided) => ({
      ...provided,
      ...libraryDropdownMenuStyles(dropdownWidth, dropdownMenuWidth),
    }),
    option: (provided, state) => ({
      ...provided,
      ...libraryDropdownOptionStyles(state),
    }),
    placeholder: (provided) => ({
      ...provided,
      ...libraryDropdownPlaceholderStyles,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      ...libraryDropdownIndicatorStyles,
    }),
    valueContainer: (provided) => ({
      ...provided,
      ...libraryValueContainerStyles,
    }),
  };
};

export {
  dropdownStyles,
  chakraStylesGradeDropdown,
  chakraStylesSubjectDropdown,
};
