import {
  Box,
  Flex,
  HStack,
  Show,
  Spacer,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Dropdown } from "adminComponents/atoms/Dropdown";
import { FormLabel } from "adminComponents/atoms/FormLabel";
import { Heading } from "adminComponents/atoms/Heading";
import { IconTooltip } from "adminComponents/atoms/IconTooltip";
import { Input } from "adminComponents/atoms/Input";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils";
import { useAuth } from "links/lib/features/auth";
import { useFetchGradeLevels } from "links/lib/features/gradeLevels";

import { useCircularProgressAvatar } from "../hooks/useCircularProgressAvatar";
import { LanguageAndTimezoneModal } from "./LanguageAndTimezoneModal";

const STARTING_PERCENTAGE_PROGRESS = 0;
const MIN_PERCENTAGE_PROGRESS = 0;
const MAX_PERCENTAGE_PROGRESS = 25;

interface IProps {
  handleContinue: () => void;
  handleClose?: () => void;
  isLoading: boolean;
  handleEditAvatarModalOpen: () => void;
}

export const TeacherOnboardingStep1: React.FC<IProps> = ({
  handleContinue,
  handleClose,
  isLoading,
  handleEditAvatarModalOpen,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const { authUser } = useAuth();

  const { control, register, formState, watch } = useFormContext();

  const nickname = watch("nickname");
  const state = watch("state");
  const country = watch("country");
  const selectedGrades = watch("selectedGrades");

  const fetchGradeLevels = useFetchGradeLevels({
    country: authUser?.country || "US",
    limit: 100,
  });
  const gradeLevels = useMemo(() => {
    return fetchGradeLevels.data?.gradeLevels || [];
  }, [fetchGradeLevels.data?.gradeLevels]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const completedRequiredFields = [nickname, country, selectedGrades].reduce(
    (acc, val) => {
      if (typeof val === "string" && val !== "") return acc + 1;
      if (typeof val === "object" && val.length > 0) return acc + 1;
      return acc;
    },
    0
  );

  const { setPercentProgress, circularProgressAvatar } =
    useCircularProgressAvatar({
      profileImageUrl: authUser?.profile_image_url || "",
      startingPercentProgress: STARTING_PERCENTAGE_PROGRESS,
      minPercentProgress: MIN_PERCENTAGE_PROGRESS,
    });
  useEffect(() => {
    setPercentProgress((completedRequiredFields / 3) * MAX_PERCENTAGE_PROGRESS);
  }, [completedRequiredFields, setPercentProgress]);

  return (
    <>
      <HStack
        w="full"
        pl={{ base: "admin.mobileXPadding", md: "admin.desktopXPadding" }}
        pr={{ base: "admin.mobileXPadding", lg: 0 }}
      >
        <VStack
          alignItems="start"
          w={["full", null, null, "auto"]}
          flex="1.4"
          gap={[pxToRem(16), null, null, pxToRem(32)]}
          py={pxToRem(64)}
        >
          <VStack alignItems="start" w="full" gap={pxToRem(12)}>
            <Heading
              as="h1"
              variant="adminH2"
              textAlign="left"
              whiteSpace="pre-line" // This string includes a newline character
            >
              {t("teacherOnboardContainer.step1.heading")}
            </Heading>
            <Text variant="adminP1" textAlign="left">
              {t("teacherOnboardContainer.step1.description")}
            </Text>
          </VStack>
          <Box
            background="white"
            width={["full", null, null, pxToRem(500)]}
            overflow="visible"
          >
            <VStack
              w="full"
              alignItems="start"
              justifyContent="center"
              gap={pxToRem(32)}
            >
              <VStack
                w="full"
                justifyContent="start"
                alignItems="start"
                gap={pxToRem(8)}
                direction="column"
              >
                <FormLabel>
                  <Text variant="adminP2Bold">
                    {t(`teacherAccountSetupForm.teacherInfoForm.nickname`)}
                  </Text>
                </FormLabel>
                <Input
                  {...register("nickname", { required: true })}
                  placeholder={t(
                    "teacherOnboardContainer.step1.placeholderNickname"
                  )}
                />
                <FormLabel>
                  <Text variant="adminP2Bold">
                    {t(`teacherAccountSetupForm.teacherInfoForm.grades`)}
                  </Text>
                </FormLabel>
                <Box w="full">
                  <Controller
                    name="selectedGrades"
                    control={control}
                    rules={{
                      validate: {
                        minLength1: (v) =>
                          v?.length >= 1 ||
                          (t(
                            "teacherOnboardContainer.step1.errorGradeRequired"
                          ) as string),
                      },
                      required: true,
                    }}
                    render={({ field: { onChange, onBlur, value, name } }) => (
                      <Dropdown
                        isMulti
                        options={gradeLevels.map((grade) => ({
                          label: grade.grade_level,
                          value: grade.id,
                        }))}
                        placeholder={t(
                          "teacherOnboardContainer.step1.placeholderGrade"
                        )}
                        value={value}
                        handleBlur={onBlur}
                        handleChange={onChange}
                        id={name}
                      />
                    )}
                  />
                </Box>
                <Show below="lg">
                  <HStack alignSelf="center">
                    <Text
                      variant="adminP1"
                      color="utility.link"
                      cursor="pointer"
                      onClick={() => handleEditAvatarModalOpen()}
                    >
                      {t("teacherOnboardContainer.step1.editAvatar")}
                    </Text>
                    <IconTooltip usePortal>
                      <Box textAlign="center">
                        {t("teacherOnboardContainer.step1.editAvatarTooltip")}
                      </Box>
                    </IconTooltip>
                  </HStack>
                  <HStack alignSelf="center">
                    <Text variant="adminP1">
                      {t(
                        "teacherOnboardContainer.step1.locationSummaryString",
                        {
                          state: state || "?",
                          country: country || "?",
                        }
                      )}
                    </Text>
                    <Text
                      variant="adminP1"
                      color="utility.link"
                      cursor="pointer"
                      onClick={() => onOpen()}
                    >
                      {t("common.edit")}
                    </Text>
                  </HStack>
                </Show>
              </VStack>
              <VStack gap={pxToRem(12)} alignSelf="center">
                <Button
                  size="lg"
                  variant="adminButtonFilled"
                  isDisabled={!formState.isValid}
                  onClick={handleContinue}
                  isLoading={isLoading}
                  w={pxToRem(200)}
                >
                  {t("common.continue")}
                </Button>
                {handleClose && (
                  <Text
                    variant="adminLinkMedium"
                    color="utility.link"
                    cursor="pointer"
                    onClick={handleClose}
                  >
                    {t("teacherOnboardContainer.step1.skipStep")}
                  </Text>
                )}
              </VStack>
            </VStack>
          </Box>
        </VStack>
        <Show above="lg">
          <Flex
            bgColor="primary.lightest-gray"
            backgroundPosition="top left"
            backgroundSize="cover"
            borderTopRightRadius={pxToRem(20)}
            borderBottomRightRadius={pxToRem(20)}
            flex="1"
            h={[pxToRem(250), null, pxToRem(700)]}
            alignItems="center"
            justifyContent="center"
          >
            <VStack
              gap={pxToRem(32)}
              sx={{
                "svg.circular-progress": {
                  position: "absolute",
                  zIndex: 1,
                  top: 0,
                  left: 0,
                },
                circle: {
                  stroke: "primary.lilac",
                  transform: "rotate(-90deg)",
                  transformOrigin: "50% 50%",
                  transition: "stroke-dashoffset 0.35s",
                },
              }}
            >
              {circularProgressAvatar}
              <VStack px={pxToRem(16)}>
                <Text wordBreak="break-all" variant="adminH3">
                  {nickname}
                </Text>
                <HStack>
                  <Text
                    variant="adminP1"
                    color="utility.link"
                    cursor="pointer"
                    onClick={() => handleEditAvatarModalOpen()}
                  >
                    {t("teacherOnboardContainer.step1.editAvatar")}
                  </Text>
                  <IconTooltip usePortal>
                    <Box textAlign="center">
                      {t("teacherOnboardContainer.step1.editAvatarTooltip")}
                    </Box>
                  </IconTooltip>
                </HStack>
              </VStack>
              <Spacer />
              <HStack>
                <Text variant="adminP1">
                  {t("teacherOnboardContainer.step1.locationSummaryString", {
                    state: state || "?",
                    country: country || "?",
                  })}
                </Text>
                <Text
                  variant="adminP1"
                  color="utility.link"
                  cursor="pointer"
                  onClick={() => onOpen()}
                >
                  {t("common.edit")}
                </Text>
              </HStack>
            </VStack>
          </Flex>
        </Show>
      </HStack>
      <LanguageAndTimezoneModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};
