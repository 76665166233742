import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { PracticeDataDocumentType } from "links/lib/types";

import { useAxios } from "../../hooks/useAxios";

export interface IDeletePracticeSetArgs {
  id: string;
  collection_id: string;
}

export interface IUseDeletePracticeSetProps {
  onSuccess?: (data: Record<string, unknown>) => void;
  onError?: (err: Error) => void;
  onSettled?: () => void;
}

export default function useDeletePracticeSet(
  props?: IUseDeletePracticeSetProps
): UseMutationResult<Record<string, unknown>, unknown, IDeletePracticeSetArgs> {
  const axios = useAxios();
  const queryClient = useQueryClient();

  const mutatePracticeSet = async (args: IDeletePracticeSetArgs) => {
    const { id, collection_id } = args;

    const response = await axios({
      method: "delete",
      url: `/v1/practice-sets/${id}`,
    }).then((r) => r.data as Record<string, unknown>);

    queryClient.invalidateQueries(["practiceSets", { collection_id }]);
    queryClient.invalidateQueries([
      "searchPracticeData",
      {
        include_shared: false,
        document_type: PracticeDataDocumentType.PracticeSets,
      },
    ]);
    queryClient.invalidateQueries([
      "searchPracticeDataInfinite",
      {
        include_shared: false,
        document_type: PracticeDataDocumentType.PracticeSets,
      },
    ]);
    queryClient.setQueryData(["practiceSets", { id: id }], undefined);

    return response;
  };

  const mutation = useMutation(mutatePracticeSet, {
    onSuccess: props?.onSuccess,
    onError: props?.onError,
    onSettled: props?.onSettled,
  });

  return {
    ...mutation,
  };
}
