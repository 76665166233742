import { useTitle } from "react-use";

import { productName } from "links/lib/constants";

const seperator = "|";
const loadingPlaceholder = "...";

export const usePageTitle = (
  title: string | Array<string | undefined>
): void => {
  const sections = typeof title === "string" ? [title] : title;

  const final = sections.reduce((prev, current, index) => {
    return `${prev}${index > 0 ? ` ${seperator} ` : ""}${
      current ? current : loadingPlaceholder
    }`;
  }, "");

  useTitle(`${final} ${seperator} ${productName}`);
};
