import React from "react";
import { useTranslation } from "react-i18next";

import { SubmarkLogoPearPracticeSVG } from "./resource/SubmarkLogoPearPracticeSVG";

const SubmarkLogo: React.FC = () => {
  const { t } = useTranslation("admin", { useSuspense: false });

  return <SubmarkLogoPearPracticeSVG aria-label={t("logo.ariaLabel")} />;
};

export { SubmarkLogo };
