import { useMemo } from "react";

import { useSessionUsers } from "links/lib/contexts/sessionUsers";
import { ISessionUser } from "links/lib/types";

export const useSafeUserArray = (): Array<ISessionUser> => {
  const users = useSessionUsers();

  const safeUsers = useMemo(
    () => Object.values(users).filter((u) => !!u) as Array<ISessionUser>,
    [users]
  );

  return safeUsers;
};
