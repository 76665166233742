import {
  Box,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";

import { pxToRem } from "../../../adminComponents/utils/pxToRem";

import { useBreakpoints } from "../../contexts/breakpoints";

const colors = [
  "#F1A83F", // tangerine
  "#C74E1C", // amber
  "#E961B1", // pepto
  "#FA553A", // tomato
  "#FFC629", // golden
  "#B8BE14", // anjou
  "#2EB1A5", // capri
  "#00A3DE", // monaco
  "#686BA6", // lilac
  "#708472", // smoke
  "#000000", // black
  "#FFFFFF", // white
];

export interface IPenColorPickerProps {
  onSelect: (color: string) => void;
}

export const PenColorPicker: React.FC<IPenColorPickerProps> = ({
  onSelect,
  children,
}) => {
  const { match: currentBreakpoints } = useBreakpoints();
  const { padding, fontSize } = currentBreakpoints;

  const fontArrowSize = fontSize * 0.75;
  const bRadius = currentBreakpoints.borderRadius;

  const { isOpen, onToggle, onClose } = useDisclosure();

  return (
    <Popover
      isOpen={isOpen}
      onClose={onClose}
      trigger="click"
      arrowSize={fontArrowSize}
      arrowPadding={fontArrowSize}
      offset={[fontArrowSize / 2, fontArrowSize]}
      arrowShadowColor="transparent"
      placement="auto"
      closeOnEsc
      closeOnBlur
    >
      <PopoverTrigger>
        <Box cursor="pointer" onClick={onToggle}>
          {children}
        </Box>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          color="primary.white"
          bg="primary.warm-black"
          borderColor="transparent"
          borderRadius={pxToRem(bRadius)}
          paddingY={pxToRem(padding / 4)}
          paddingX={pxToRem(padding / 2)}
          width="fit-content"
        >
          <PopoverArrow bg="primary.warm-black" />
          <PopoverBody
            fontSize={pxToRem(fontArrowSize)}
            textAlign="center"
            fontWeight="600"
            padding="0px"
          >
            <Flex
              justifyContent="center"
              flexWrap="wrap"
              overflow="hidden"
              flexDirection="row"
              p={pxToRem(currentBreakpoints.padding / 2)}
              maxW="90vw"
              maxH="50vh"
            >
              {colors.map((color) => {
                return (
                  <Box
                    key={color}
                    p={pxToRem(5)}
                    onClick={() => {
                      onToggle();
                      onSelect(color);
                    }}
                    borderColor="primary.light-gray"
                    cursor="pointer"
                    borderRadius="100%"
                    _hover={{
                      borderColor: "light.golden",
                    }}
                    borderWidth={pxToRem(2)}
                    m={pxToRem(5)}
                  >
                    <Box
                      borderRadius="100%"
                      w={pxToRem(45)}
                      h={pxToRem(45)}
                      backgroundColor={color}
                    />
                  </Box>
                );
              })}
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
