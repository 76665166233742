import { ComponentMeta, ComponentStory } from "@storybook/react";
import React from "react";

import { Text } from "adminComponents/atoms/Text";
import { IconOptions } from "adminComponents/theme";

import { Tag } from ".";

export default {
  title: "Admin Components/Atoms/Tag",
  component: Tag,
  argTypes: {
    colorScheme: {
      options: ["default", "blue", "purple", "green", "red"],
      control: { type: "select" },
      defaultValue: "default",
      description: "Tag background color",
    },
    variant: {
      options: ["adminSolid", "adminOutline"],
      control: { type: "select" },
      defaultValue: "adminSolid",
      description: "Tag type",
    },
    children: {
      defaultValue: "Tag",
      description: "Tag Content",
    },
    leftIcon: {
      defaultValue: undefined,
      description: "Icon will be placed in the start of the tag",
      options: IconOptions,
      control: { type: "select" },
    },
    rightIcon: {
      defaultValue: undefined,
      description: "Icon will be placed in the end of the tag",
      options: IconOptions,
      control: { type: "select" },
    },
    tooltip: {
      defaultValue: "",
      description: "Tooltip",
    },
    handleClickRightIcon: {
      action: "handleClickRightIcon",
    },
  },
  parameters: {
    controls: {
      include: [
        "colorScheme",
        "variant",
        "children",
        "leftIcon",
        "rightIcon",
        "tooltip",
        "handleClickRightIcon",
      ],
    },
  },
} as ComponentMeta<typeof Tag>;

const Template: ComponentStory<typeof Tag> = (args) => <Tag {...args} />;

export const DefaultTag = Template.bind({});
DefaultTag.args = {};

export const BlueTag = Template.bind({});
BlueTag.args = { colorScheme: "blue" };

export const PurpleTag = Template.bind({});
PurpleTag.args = { colorScheme: "purple" };

export const GreenTag = Template.bind({});
GreenTag.args = { colorScheme: "green" };

export const RedTag = Template.bind({});
RedTag.args = { colorScheme: "red" };

export const TagWithRightIcon = Template.bind({});
TagWithRightIcon.args = {
  rightIcon: "close",
};

export const TagWithLeftIcon = Template.bind({});
TagWithLeftIcon.args = {
  leftIcon: "check",
};

export const TagWithLeftAndRightIcon = Template.bind({});
TagWithLeftAndRightIcon.args = {
  leftIcon: "check",
  rightIcon: "close",
};

export const TagWithCustomContent = Template.bind({});
TagWithCustomContent.args = {
  leftIcon: "lock_open_outlined",
  children: (
    <>
      <Text as="span" variant="adminP2Bold">
        Private
      </Text>{" "}
      (Only visible to you)
    </>
  ),
};

export const TagWithCustomContentOutlined = Template.bind({});
TagWithCustomContentOutlined.args = {
  variant: "adminOutline",
  leftIcon: "lock_open_outlined",
  children: (
    <>
      <Text as="span" variant="adminP2Bold">
        Private
      </Text>{" "}
      (Only visible to you)
    </>
  ),
};

export const TagWithTooltip = Template.bind({});
TagWithTooltip.args = {
  variant: "adminOutline",
  tooltip: "This is the tooltip text",
  children: "8/10 Feedback",
};
