import {
  Box,
  Flex,
  FormControl,
  HStack,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { SyntheticEvent, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMount } from "react-use";

import { Button } from "adminComponents/atoms/Button";
import { FormErrorMessage } from "adminComponents/atoms/FormErrorMessage";
import { Heading } from "adminComponents/atoms/Heading";
import { IconTooltip } from "adminComponents/atoms/IconTooltip";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils/pxToRem";
import AppSpinner from "screens/App/components/AppSpinner";
import { Input } from "sessionComponents/atoms/Input";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";

interface IJoinCodeProps {
  onJoinSession: (joinCode: string) => void;
  isLoading?: boolean;
  joinCode?: string;
  error?: string;
}

const useFocus = (): [React.RefObject<HTMLInputElement>, () => void] => {
  const inputRef = useRef<HTMLInputElement>(null);
  const setFocus = () => {
    inputRef.current ? inputRef.current.focus() : null;
  };

  return [inputRef, setFocus];
};

export const JoinCode: React.FC<IJoinCodeProps> = ({
  onJoinSession,
  joinCode: initialJoinCode,
  isLoading = false,
  error,
}) => {
  const numInput = 6;
  const inputNums = [...Array(numInput).keys()];
  const [joinCode, setJoinCode] = useState(
    initialJoinCode || "".padEnd(numInput, " ")
  );
  const inputFocuses = inputNums.map(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ref, setFocus] = useFocus();
    return { ref, setFocus };
  });
  useMount(() => inputFocuses[0].setFocus());

  const setJoinCodeCharacter = (i: number) => (character: string) => {
    setJoinCode(
      joinCode.substring(0, i) + character + joinCode.substring(i + 1)
    );
  };

  const { t } = useTranslation("session", {
    keyPrefix: "joinCodeLightScreen",
    useSuspense: false,
  });

  const padding = useBreakpointValue({ base: 24, sm: 24, md: 28 }) || 24;
  const buttonTextSize = useBreakpointValue({
    base: "md",
    sm: "sm",
    md: "md",
    lg: "lg",
  });

  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    onJoinSession(joinCode.trim().toUpperCase());
  };

  const handlePaste = async () => {
    const text = await navigator.clipboard.readText();
    const characters = text.slice(0, numInput).trim();
    setJoinCode(characters.padEnd(numInput, " "));
    inputFocuses[
      characters.length < numInput ? characters.length : numInput - 1
    ].setFocus();
  };

  if (isLoading) {
    return <AppSpinner />;
  }

  return (
    <Flex
      flexDir="column"
      alignItems="center"
      justifyContent="space-between"
      gap={pxToRem(padding)}
    >
      <VStack textAlign="center">
        <Heading as="h1" variant="adminH2">
          {t("instructionText")}
        </Heading>
        <Text as="span" size="md" textStyle="gameText">
          {t("description")}
        </Text>
      </VStack>
      <Box as="form" onSubmit={onSubmit} w="full" h="full">
        <Flex
          h="full"
          alignItems="center"
          flexDirection="column"
          gap={pxToRem(padding)}
        >
          <FormControl w="auto" isInvalid={!!error}>
            <HStack>
              {inputFocuses.map(({ ref }, i) => {
                return (
                  <JoinCodeCharacterInput
                    key={i}
                    ref={ref}
                    value={joinCode.substring(i, i + 1)}
                    setValue={setJoinCodeCharacter(i)}
                    setPrevFocus={inputFocuses[i - 1]?.setFocus}
                    setNextFocus={inputFocuses[i + 1]?.setFocus}
                    handlePaste={handlePaste}
                    handleSubmit={onSubmit}
                  />
                );
              })}
            </HStack>

            <FormErrorMessage justifyContent="center">
              {error && (
                <HStack spacing={pxToRem(10)}>
                  <Box as="span">{error}</Box>
                  <IconTooltip>{t("errorTooltipText")}</IconTooltip>
                </HStack>
              )}
            </FormErrorMessage>
          </FormControl>
          <Button
            variant="adminButtonFilled"
            size={buttonTextSize}
            type="submit"
            w={{ base: "auto", md: "40%" }}
          >
            <Text as="span" size="lg" fontStyle="gameText">
              {t("joinButton")}
            </Text>
          </Button>
        </Flex>
      </Box>
    </Flex>
  );
};

interface JoinCodeCharacterInputProps {
  value: string;
  setValue: (value: string) => void;
  setPrevFocus?: () => void;
  setNextFocus?: () => void;
  handlePaste: () => Promise<void>;
  handleSubmit: (e: SyntheticEvent) => void;
}

const JoinCodeCharacterInput = React.forwardRef<
  HTMLInputElement,
  JoinCodeCharacterInputProps
>(
  (
    { value, setValue, setPrevFocus, setNextFocus, handlePaste, handleSubmit },
    ref
  ) => {
    const { match: currentBreakpoints } = useBreakpoints();

    return (
      <Input
        fontSize={pxToRem(currentBreakpoints.fontSize)}
        w="4ch"
        textAlign="center"
        maxLength={1}
        textTransform="capitalize"
        ref={ref}
        value={value}
        onPaste={handlePaste}
        onKeyDown={(e) => {
          if (e.ctrlKey || e.metaKey || e.shiftKey) return;
          e.preventDefault();
          e.stopPropagation();
          switch (e.key) {
            case "Enter":
              handleSubmit(e);
              break;
            case "ArrowLeft":
              setPrevFocus?.();
              break;
            case "ArrowRight":
              setNextFocus?.();
              break;
            case "Backspace":
              setValue(" ");
              setPrevFocus?.();
              break;
            case "Delete":
              setValue(" ");
              break;
            case "Tab":
              setNextFocus?.();
              break;
            default: {
              const isLetter =
                (e.key >= "a" && e.key <= "z") ||
                (e.key >= "A" && e.key <= "Z");
              const isNumber = e.key >= "0" && e.key <= "9";
              if (isLetter || isNumber) {
                setValue(e.key);
                setNextFocus?.();
              }
            }
          }
        }}
        pattern="[^\s]+"
        _focus={{
          borderColor: "primary.golden",
        }}
      />
    );
  }
);

JoinCodeCharacterInput.displayName = "JoinCodeCharacterInput";
