import { Box } from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { usePrevious } from "react-use";

import { IOption } from "adminComponents/atoms/Dropdown/index";
import { Tabs } from "adminComponents/atoms/Tabs";
import { AdminFlyout } from "adminComponents/molecules/Flyout";
import {
  ICoverImage,
  PracticeSetCoverImagePicker,
} from "adminComponents/molecules/PracticeSetCoverImagePicker";
import {
  IForm,
  PracticeSetDetailForm,
} from "adminComponents/molecules/PracticeSetDetailForm";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { supportedLangs } from "links/lib/lang";
import {
  IPracticeSet,
  PracticeSetAvailability,
  SmartSetType,
} from "links/lib/types";

export type FormData = {
  form: IForm;
};

export interface IProps {
  practiceSet?: IPracticeSet;
  options: {
    folders: Array<IOption>;
    subjects: Array<IOption>;
    gradeLevels: Array<IOption>;
    languages: Array<IOption>;
  };
  isOpen: boolean;
  handleClose: () => void;
  showBack?: boolean;
  handleBack?: () => void;
  handleSubmit: (data: FormData) => void;
  handleCoverImageChange: (coverImage: ICoverImage) => void;
  showCncFields?: boolean;
  allowPublish?: boolean;
  isLoading?: boolean;
  initialCollectionId?: string;
}

const getDefaultAvailability = (cncUser?: boolean) => {
  return cncUser
    ? PracticeSetAvailability.Domain
    : PracticeSetAvailability.Public;
};

export const PracticeSetDetailFlyout: React.FC<IProps> = ({
  isOpen,
  showBack,
  handleBack,
  handleClose,
  handleSubmit,
  handleCoverImageChange,
  practiceSet,
  options,
  showCncFields,
  allowPublish,
  isLoading,
  initialCollectionId,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  const getFormData = useCallback((): IForm => {
    const subjectIds = (
      practiceSet && (practiceSet.subjects || []).length > 0
        ? practiceSet.subjects.map((s) => s.subject.id)
        : []
    ) as Array<string>;

    return {
      title: practiceSet?.title || "",
      description: practiceSet?.description || "",
      folder: practiceSet?.collection_id ?? initialCollectionId ?? "0",
      subjects: subjectIds,
      gradeLevels: practiceSet?.grade_levels.map((g) => g.grade_level.id) || [],
      language: practiceSet?.language_code || supportedLangs[0],
      availability:
        practiceSet?.availability || getDefaultAvailability(showCncFields),
      cncCode: practiceSet?.cnc_code || "",
      cncFamilyCode: practiceSet?.cnc_family_code || "",
      isCertified:
        practiceSet?.is_certified === undefined
          ? !!showCncFields
          : practiceSet?.is_certified,
      isPremium:
        practiceSet?.is_premium === undefined
          ? !!showCncFields
          : practiceSet?.is_premium,
      smartSetType: practiceSet?.smart_set_type || SmartSetType.None,
    };
  }, [practiceSet, initialCollectionId, showCncFields]);

  const getCoverImage = useCallback((): ICoverImage => {
    return {
      icon: practiceSet?.cover_image_icon || "OTHER_TRIVIA",
      bgColorScheme: practiceSet?.cover_image_bg_color_scheme || "LIGHT_BLUE",
      bgPattern: practiceSet?.cover_image_bg_pattern || "OTHER_FUN_PARTY",
    };
  }, [practiceSet]);

  const [formData, setFormData] = useState<IForm>(getFormData());
  const [coverImage, setCoverImage] = useState<ICoverImage>(getCoverImage());
  const lastIsOpen = usePrevious(isOpen);

  // updates form initial values on open
  useEffect(() => {
    if (!lastIsOpen && isOpen) {
      setFormData(getFormData());
      setCoverImage(getCoverImage());
    }
  }, [lastIsOpen, isOpen, getFormData, getCoverImage, practiceSet]);

  useEffect(() => {
    setFormData(getFormData());
    setCoverImage(getCoverImage());
  }, [getFormData, getCoverImage, practiceSet]);

  const handleChangeCoverImage = (image: ICoverImage) => {
    setCoverImage(image);
    handleCoverImageChange(image);
  };

  return (
    <AdminFlyout
      isOpen={isOpen}
      onClose={handleClose}
      showBack={showBack}
      onBack={handleBack}
      title={t("practiceSetDetailFlyout.title")}
    >
      <Tabs
        variant="adminFlyoutTabs"
        tabData={[
          {
            label: t("practiceSetDetailFlyout.tabDetails"),
            content: (
              <Box
                py={pxToRem(40)}
                px={[
                  "admin.flyout.mobileXPadding",
                  null,
                  "admin.flyout.desktopXPadding",
                ]}
              >
                <PracticeSetDetailForm
                  loading={isLoading}
                  isEdit={!!practiceSet}
                  initialValue={formData}
                  options={options}
                  handleSubmit={(data: IForm) => handleSubmit({ form: data })}
                  showCncFields={showCncFields}
                  allowPublish={allowPublish}
                />
              </Box>
            ),
          },
          {
            label: t("practiceSetDetailFlyout.tabCoverImage"),
            content: (
              <Box
                py={pxToRem(40)}
                px={[
                  "admin.flyout.mobileXPadding",
                  null,
                  "admin.flyout.desktopXPadding",
                ]}
              >
                <PracticeSetCoverImagePicker
                  key={formData.smartSetType}
                  isSmartSet={formData.smartSetType !== SmartSetType.None}
                  handleChange={handleChangeCoverImage}
                  selectedCoverImage={coverImage}
                />
              </Box>
            ),
          },
        ]}
      />
    </AdminFlyout>
  );
};
