import {
  Box,
  BoxProps,
  ButtonProps,
  Flex,
  FormControl,
  SimpleGrid,
  useBreakpointValue,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Dropdown, IOption } from "adminComponents/atoms/Dropdown";
import { FormLabel } from "adminComponents/atoms/FormLabel";
import { Icon } from "adminComponents/atoms/Icon";
import { Modal } from "adminComponents/atoms/Modal";
import { pxToRem } from "adminComponents/utils/pxToRem";

export type FilterPracticeSetData = {
  state: string;
  grade: string;
  subject: string;
  standardCollection?: string;
};

interface IProps extends BoxProps {
  value?: FilterPracticeSetData;
  states: IOption[];
  regionFilter: string;
  grades: IOption[];
  gradeLevelIdFilter: string;
  subjects: IOption[];
  modalSubmitCopy?: string;
  filterButtonSize?: ButtonProps["size"];
  subjectIdFilter: string;
  handleChangeFilter: (
    state: string,
    subject: string,
    grade: string,
    standardCollection?: string
  ) => void;
  standardCollections?: IOption[];
  standardCollectionIdFilter?: string;
  showStandardsCollectionFilter?: boolean;
}

export const FilterPracticeSet: React.FC<IProps> = ({
  states,
  regionFilter,
  grades,
  gradeLevelIdFilter,
  subjects,
  modalSubmitCopy,
  filterButtonSize,
  value,
  subjectIdFilter,
  handleChangeFilter,
  standardCollections = [],
  standardCollectionIdFilter,
  showStandardsCollectionFilter = false,
  ...rest
}) => {
  const styles = useMultiStyleConfig("AdminFilterPracticeSet", {});
  const [filterData, setFilterData] = useState<FilterPracticeSetData>({
    state: regionFilter,
    grade: gradeLevelIdFilter,
    subject: subjectIdFilter,
    standardCollection: standardCollectionIdFilter,
  });
  const { t } = useTranslation("admin", { useSuspense: false });
  const isMobile = !!useBreakpointValue({ base: true, md: false });
  const [showFilterModal, setShowFilterModal] = useState(false);

  useEffect(() => {
    if (value) setFilterData(value);
  }, [value]);

  const handleUpdateState = (value: string) => {
    setFilterData((prev) => {
      handleChangeFilter(value, prev.subject, prev.grade);
      return { ...prev, state: value, standardCollection: "" };
    });
  };

  const handleUpdateGrade = (value: string) => {
    setFilterData((prev) => {
      handleChangeFilter(prev.state, prev.subject, value);
      return { ...prev, grade: value };
    });
  };

  const handleUpdateSubject = (value: string) => {
    setFilterData((prev) => {
      handleChangeFilter(prev.state, value, prev.grade);
      return { ...prev, subject: value };
    });
  };

  const handleUpdateStandardCollection = (value: string) => {
    setFilterData((prev) => {
      handleChangeFilter(prev.state, prev.subject, prev.grade, value);
      return { ...prev, standardCollection: value };
    });
  };

  const filtersMarkup = (
    <>
      <FormControl>
        <FormLabel id="createQuestion.stateLabel">
          {t("createQuestion.labelState")}
        </FormLabel>
        <Dropdown
          id="createQuestion.labelState"
          aria-labelled-by="createQuestion.stateLabel"
          options={states}
          value={states.filter((state) => state.value === filterData.state)}
          handleChange={(v) =>
            handleUpdateState((v as IOption).value.toString())
          }
        />
      </FormControl>
      <FormControl>
        <FormLabel id="createQuestion.gradeLabel">
          {t("createQuestion.labelGrade")}
        </FormLabel>
        <Dropdown
          id="createQuestion.labelGrade"
          aria-labelled-by="createQuestion.gradeLabel"
          options={grades}
          value={grades.filter((grade) => grade.value === filterData.grade)}
          handleChange={(v) =>
            handleUpdateGrade((v as IOption).value.toString())
          }
        />
      </FormControl>
      <FormControl>
        <FormLabel id="createQuestion.subjectLabel">
          {t("createQuestion.labelSubject")}
        </FormLabel>
        <Dropdown
          id="createQuestion.labelSubject"
          aria-labelled-by="createQuestion.subjectLabel"
          options={subjects}
          value={subjects.filter(
            (subject) => subject.value === filterData.subject
          )}
          handleChange={(v) =>
            handleUpdateSubject((v as IOption).value.toString())
          }
        />
      </FormControl>
      {showStandardsCollectionFilter && (
        <FormControl
          gridColumn="1/-1"
          isDisabled={standardCollections.length === 1}
        >
          <FormLabel id="createQuestion.standardCollectionsLabel">
            {t("createQuestion.labelStandardCollections")}
          </FormLabel>
          <Dropdown
            id="createQuestion.labelStandardCollections"
            aria-labelled-by="createQuestion.standardCollectionsLabel"
            options={standardCollections}
            value={standardCollections.filter(
              (standardCollection) =>
                standardCollection.value ===
                (filterData.standardCollection || "")
            )}
            handleChange={(v) =>
              handleUpdateStandardCollection((v as IOption).value.toString())
            }
          />
        </FormControl>
      )}
    </>
  );

  if (isMobile) {
    return (
      <Box {...rest}>
        <Button
          variant="adminButtonOutlined"
          leftIcon={<Icon icon="filter" w={pxToRem(14)} h={pxToRem(14)} />}
          onClick={() => setShowFilterModal(true)}
          size={filterButtonSize}
        >
          {t("common.filter")}
        </Button>
        <Modal
          title={t("common.filters")}
          isOpen={showFilterModal}
          onClose={() => setShowFilterModal(false)}
        >
          <Flex flexDir="column" paddingBottom={[pxToRem(100), null, 0]}>
            <SimpleGrid gap={pxToRem(32)} columns={1}>
              {filtersMarkup}
            </SimpleGrid>
            <Box __css={styles.buttonContainer}>
              <Button
                variant="adminButtonFilled"
                onClick={() => setShowFilterModal(false)}
                isFullWidth
              >
                {modalSubmitCopy || t("common.saveAndClose")}
              </Button>
            </Box>
          </Flex>
        </Modal>
      </Box>
    );
  }

  return (
    <SimpleGrid {...rest} gridTemplateColumns="1fr 1fr 1fr" gap={pxToRem(12)}>
      {filtersMarkup}
    </SimpleGrid>
  );
};
