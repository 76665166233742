import React, { createContext, useContext, useMemo } from "react";

import { ISessionGroups } from "../types";

export const SessionGroupsContext = createContext<ISessionGroups>({});

export interface ISessionGroupsProviderProps {
  wsStateGroups: ISessionGroups;
}

export const SessionGroupsProvider: React.FC<ISessionGroupsProviderProps> = ({
  children,
  wsStateGroups,
}) => {
  const value = useMemo(() => wsStateGroups, [wsStateGroups]);
  return (
    <SessionGroupsContext.Provider value={value}>
      {children}
    </SessionGroupsContext.Provider>
  );
};

export const useSessionGroups = (): ISessionGroups => {
  return useContext(SessionGroupsContext);
};
