import {
  Box,
  CSSObject,
  Button as ChakraButton,
  Link as ChakraLink,
  ComponentWithAs,
  LinkProps as IProps,
  LayoutProps,
  forwardRef,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import React from "react";
import { Link, Link as RouterLink } from "react-router-dom";

import { NavigationLink } from "adminComponents";
import { Icon } from "adminComponents/atoms/Icon";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils/pxToRem";

type Sizes = "xl" | "lg" | "md" | "sm";

export interface TextLinkProps extends IProps {
  isWarning?: boolean;
  isDisabled?: boolean;
  size?: Sizes;
  height?: LayoutProps["height"];
  textSx?: CSSObject;
  handleClick?: () => void;
  navigationLink?: NavigationLink;
}

export const TextLink: ComponentWithAs<"a", TextLinkProps> = forwardRef<
  TextLinkProps,
  "a"
>(
  (
    {
      children,
      size = "md",
      isWarning,
      navigationLink,
      isDisabled,
      height,
      handleClick,
      variant,
      w,
      maxW,
      textSx,
      ...rest
    },
    ref
  ) => {
    let textVariant = "adminLinkMedium";

    if (size === "sm") {
      textVariant = "adminLinkSmall";
    } else if (size === "xl") {
      textVariant = "adminLinkExtraLarge";
    } else if (size === "lg") {
      textVariant = "adminLinkLarge";
    }

    const styles = useMultiStyleConfig("AdminTextLink", {
      size,
      variant,
      isWarning,
      isDisabled,
      textVariant,
    });

    return navigationLink ? (
      <ChakraLink
        sx={styles.container}
        ref={ref}
        as={navigationLink.isExternal ? Link : RouterLink}
        aria-label={navigationLink?.label || ""}
        isExternal={navigationLink.isExternal}
        to={{ pathname: navigationLink.to, state: navigationLink.state }}
        w={w}
        {...rest}
      >
        <Box as="span" __css={styles.text}>
          {children}
        </Box>
        {size === "xl" && (
          <Box as="span" __css={styles.iconContainer}>
            <Icon icon="keyboard_arrow_right" color="inherit" />
          </Box>
        )}
      </ChakraLink>
    ) : (
      <ChakraButton
        sx={styles.container}
        style={{
          background: "transparent",
          padding: 0,
          alignItems: "end",
        }}
        height={height ?? pxToRem(16)}
        ref={ref}
        onClick={() => handleClick?.()}
        w={w}
        maxW={maxW}
      >
        <Text
          variant={textVariant}
          sx={{ ...styles.text, ...textSx }}
          as="span"
          color="inherit"
        >
          {children}
        </Text>
        {size === "xl" && <Icon icon="keyboard_arrow_right" sx={styles.icon} />}
      </ChakraButton>
    );
  }
);
