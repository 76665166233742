import { useCallback, useMemo, useState } from "react";

type OptionalArgTuple<A> = A extends undefined ? [] : [A];

export interface IExecuteFn<T, A> {
  (...args: OptionalArgTuple<A>): Promise<T>;
}

export interface IUseAsyncOpts<T> {
  initState?: IUseAsyncInitState<T>;
  onSuccess?: (data: T) => void;
  onError?: (err: Error) => void;
}

export interface IUseAsyncReturn<T, A> {
  isLoading: boolean;
  isSuccess: boolean;
  error?: Error;
  data?: T;
  execute: (...args: OptionalArgTuple<A>) => Promise<T | undefined>;
}

interface IUseAsyncState<T> {
  isLoading: boolean;
  isSuccess: boolean;
  error?: Error;
  data?: T;
}

interface IUseAsyncInitState<T> {
  isLoading?: boolean;
  isSuccess?: boolean;
  error?: Error;
  data?: T;
}

/**
 * Wraps any async function that returns a promise with helper
 * functionality to track loading and error status.
 * @returns
 */
export function useAsync<T, A = undefined>(
  executeFn: IExecuteFn<T, A>,
  opts?: IUseAsyncOpts<T>
): IUseAsyncReturn<T, A> {
  const { initState } = opts || {};

  // create internal state to manage execution function status
  const [state, setState] = useState<IUseAsyncState<T>>({
    isLoading: initState?.isLoading || false,
    error: initState?.error || undefined,
    data: initState?.data || undefined,
    isSuccess: initState?.isSuccess || false,
  });

  const { isLoading, error, data, isSuccess } = state;

  // execution function to run async func
  const execute = useCallback(
    async (...args: OptionalArgTuple<A>): Promise<T | undefined> => {
      setState({
        isLoading: true,
        error: undefined,
        data: undefined,
        isSuccess: false,
      });

      try {
        const data = await executeFn(...args);

        setState({
          isLoading: false,
          error: undefined,
          data,
          isSuccess: true,
        });

        opts?.onSuccess?.(data);

        return data;
      } catch (err) {
        setState({
          isLoading: false,
          error: err as Error,
          data: undefined,
          isSuccess: false,
        });

        opts?.onError?.(err as Error);

        return undefined;
      }
    },
    [executeFn, opts]
  );

  return useMemo(
    () => ({
      isLoading,
      error,
      data,
      execute,
      isSuccess,
    }),
    [isLoading, isSuccess, data, error, execute]
  );
}
