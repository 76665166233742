import {
  Box,
  Flex,
  FormControl,
  Link,
  Spinner,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Card } from "adminComponents/atoms/Card";
import { Checkbox } from "adminComponents/atoms/Checkbox";
import { FormErrorMessage } from "adminComponents/atoms/FormErrorMessage";
import { Heading } from "adminComponents/atoms/Heading";
import { Text } from "adminComponents/atoms/Text";
import { TemplateWithCenteredContent } from "adminComponents/templates/TemplateWithCenteredContent";
import i18n from "lib/i18n";
import { AgreementType, IAgreement } from "links/lib/types";
import { getBrandConditionedAgreementUrl } from "links/lib/util";

interface IProps {
  teacherName: string;
  agreements: Array<IAgreement>;
  handleDeferAgreement: (agreementId: string) => void;
  handleSignAgreement: (agreementId: string) => void;
  handleSignOut: () => void;
}

export const TeacherAgreementsScreen: React.FC<IProps> = ({
  agreements,
  teacherName,
  handleSignAgreement,
  handleSignOut,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const styles = useMultiStyleConfig("AdminTeacherAgreementsScreen", {});
  const [touched, setTouched] = useState(false);
  const [accepted, setAccepted] = useState(false);

  useEffect(() => {
    if (agreements.length) {
      setTouched(false);
      setAccepted(false);
    }
  }, [agreements]);

  const agreementsMinusCOPPA = useMemo(() => {
    return agreements.filter(
      (agreement) => agreement.agreement_type !== AgreementType.COPPA
    );
  }, [agreements]);

  const agreementsListMinusCOPPA = useMemo(() => {
    return new Intl.ListFormat(i18n.language, {
      style: "long",
      type: "conjunction",
    }).formatToParts(agreementsMinusCOPPA.map((a) => a.id));
  }, [agreementsMinusCOPPA]);

  const coppaAgreement = useMemo(() => {
    return agreements.find(
      (agreement) => agreement.agreement_type === AgreementType.COPPA
    );
  }, [agreements]);

  if (!agreements.length) {
    return <Spinner />;
  }

  const handleChange = (checked: boolean) => {
    setAccepted(checked);
    setTouched(true);
  };

  const onClickAccept = () => {
    agreements.map((agreement) => {
      handleSignAgreement(agreement.id);
    });
  };

  return (
    <TemplateWithCenteredContent
      headerContent={
        <Heading as="h1" variant="adminH2">
          {t("common.welcome", { name: teacherName })}
        </Heading>
      }
    >
      <Card
        sx={styles.outer}
        variant="adminCardSmallBorder"
        borderColor="primary.tan"
      >
        <Flex sx={styles.headerContainer}>
          <Heading as="h6" variant="adminH6">
            {t("teacherAgreementsScreen.heading")}
          </Heading>
        </Flex>

        <Box sx={styles.pageContainer}>
          <Flex sx={styles.fieldContainer}>
            <Text variant="adminP2">
              {t("teacherAgreementsScreen.subHeading")}
            </Text>
            <FormControl isInvalid={touched && !accepted}>
              <Checkbox
                id="agreements"
                name="agreements"
                onChange={(e) => handleChange(e.target.checked)}
              >
                {t(
                  "teacherAccountSetupForm.teacherInfoForm.agreementsCheckbox"
                )}
                &nbsp;
                {agreementsListMinusCOPPA.length > 0 && (
                  <>
                    {t("rolePickerModal.agreementsCopy")}
                    &nbsp;
                    {agreementsListMinusCOPPA.map((item) => {
                      if (item.type === "literal") return item.value;
                      const agreement = agreements.find(
                        (a) => a.id === item.value
                      );
                      if (!agreement) return;

                      return (
                        <Link
                          key={agreement.id}
                          color="utility.link"
                          href={getBrandConditionedAgreementUrl(
                            agreement.agreement_url
                          )}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {agreement.title}
                        </Link>
                      );
                    })}
                  </>
                )}
                {!coppaAgreement && <>.</>}
                {agreementsListMinusCOPPA.length > 0 && !!coppaAgreement && (
                  <>;&nbsp;{t("rolePickerModal.and")}&nbsp;</>
                )}
                {coppaAgreement && (
                  <>
                    {t("rolePickerModal.agreementsCopyCOPPA")}
                    &nbsp;
                    <Link
                      key={coppaAgreement.id}
                      color="utility.link"
                      href={getBrandConditionedAgreementUrl(
                        coppaAgreement.agreement_url
                      )}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {coppaAgreement.title}
                    </Link>
                    .
                  </>
                )}
                *
              </Checkbox>
              <FormErrorMessage>
                {t("teacherAgreementsScreen.checkboxAgreementError")}
              </FormErrorMessage>
            </FormControl>
          </Flex>
        </Box>

        <Flex sx={styles.buttonContainer}>
          <Button
            size="sm"
            variant="adminButtonOutlined"
            sx={styles.button}
            onClick={handleSignOut}
          >
            {t("teacherAgreementsScreen.buttonSignOut")}
          </Button>
          <Button
            size="sm"
            variant="adminButtonFilled"
            isDisabled={!accepted}
            sx={styles.button}
            onClick={onClickAccept}
          >
            {t("teacherAgreementsScreen.buttonAccept")}
          </Button>
        </Flex>
      </Card>
    </TemplateWithCenteredContent>
  );
};
