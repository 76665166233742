import { useMemo } from "react";
import { useHistory } from "react-router-dom";

import { useErrorToast } from "adminComponents/utils/toast";
import {
  useFetchAverageUserAccuracies,
  useGroupAverageWeeklyPracticeHours,
} from "links/lib/features/classrooms";
import useFetchClassroom, {
  IFetchClassroomResponse,
} from "links/lib/features/classrooms/useFetchClassroom";
import useFetchClassroomUsers from "links/lib/features/classrooms/useFetchClassroomUsers";
import { useFetchGradeLevels } from "links/lib/features/gradeLevels";
import { IGradeLevel, IUser, UsersGroupsRole } from "links/lib/types";

interface IUseStudentDetailQueriesParams {
  classroomId: string;
  studentId: string;
}

interface IUseStudentDetailQueriesResponse {
  isLoading: boolean;
  classroomData?: IFetchClassroomResponse;
  student?: IUser;
  studentAccuracy: number;
  studentWeeklyPracticeHours: number;
  gradeLevels: Array<IGradeLevel>;
  studentGradeLevel?: string;
}

export const useStudentDetailQueries = ({
  classroomId,
  studentId,
}: IUseStudentDetailQueriesParams): IUseStudentDetailQueriesResponse => {
  const history = useHistory();
  const classroomFetch = useFetchClassroom({
    id: classroomId,
    onPermissionDenied: () => {
      history.push("/t/classrooms");
    },
  });
  const classroomStudentsFetch = useFetchClassroomUsers({
    classroom_id: classroomId,
    group_roles: [UsersGroupsRole.Member],
  });
  const studentAccuracyFetch = useFetchAverageUserAccuracies({
    classroom_id: classroomId,
    user_id: studentId,
  });
  const groupPracticeHoursFetch = useGroupAverageWeeklyPracticeHours({
    classroom_id: classroomId,
    student_id: studentId,
  });

  const student = useMemo(() => {
    return classroomStudentsFetch.data?.users.find((u) => u.id === studentId);
  }, [classroomStudentsFetch.data, studentId]);

  const gradeLevelsFetch = useFetchGradeLevels({
    country: student?.country || "US",
    limit: 100,
  });

  useErrorToast(gradeLevelsFetch.error);
  useErrorToast(classroomFetch.error);
  useErrorToast(classroomStudentsFetch.error);
  useErrorToast(studentAccuracyFetch.error);
  useErrorToast(groupPracticeHoursFetch.error);

  const studentGradeLevel = useMemo(() => {
    if (!student?.student_grade_level_id) {
      return undefined;
    }

    const gradeLevelMap: { [key: string]: IGradeLevel } = {};

    gradeLevelsFetch.data?.gradeLevels.forEach((gradeLevel) => {
      gradeLevelMap[gradeLevel.id] = gradeLevel;
    });

    return gradeLevelMap[student.student_grade_level_id]?.grade_level;
  }, [gradeLevelsFetch.data, student]);

  const studentAccuracy = useMemo(() => {
    return Math.round(
      (studentAccuracyFetch.data?.average_user_accuracies.find(
        (accuracy) => accuracy.user_id === studentId
      )?.average_accuracy || 0) * 100
    );
  }, [studentAccuracyFetch.data, studentId]);

  const studentWeeklyPracticeHours = useMemo(() => {
    return (
      groupPracticeHoursFetch.data?.average_weekly_user_hours.find(
        (userHours) => userHours.user_id === studentId
      )?.hours || 0
    );
  }, [groupPracticeHoursFetch.data, studentId]);

  const isLoading =
    classroomFetch.isLoading ||
    classroomStudentsFetch.isLoading ||
    studentAccuracyFetch.isLoading ||
    groupPracticeHoursFetch.isLoading;

  return {
    isLoading,
    classroomData: classroomFetch.data,
    student,
    studentAccuracy,
    studentWeeklyPracticeHours:
      Math.round(studentWeeklyPracticeHours * 10) / 10,
    gradeLevels: gradeLevelsFetch.data?.gradeLevels || [],
    studentGradeLevel,
  };
};
