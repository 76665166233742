import { Box, FormControl, SimpleGrid } from "@chakra-ui/react";
import { useFormik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { FormErrorMessage } from "adminComponents/atoms/FormErrorMessage";
import { FormLabel } from "adminComponents/atoms/FormLabel";
import { Icon } from "adminComponents/atoms/Icon";
import { IconCTAButton } from "adminComponents/atoms/IconCTAButton";
import { Input } from "adminComponents/atoms/Input";
import { useEditPracticeSetMediaModalContext } from "adminComponents/contexts/EditPracticeSetMediaModalContext";
import { CreateQuestionFooter } from "adminComponents/molecules/CreateQuestionFooter";
import { QuestionAddMedia } from "adminComponents/molecules/QuestionAddMedia";
import { QuestionTypeDescription } from "adminComponents/molecules/QuestionTypeDescription";
import { AddMediaModal } from "adminComponents/organisms/AddMediaModal";
import { RichTextEditor } from "adminComponents/organisms/RichTextEditor";
import { isRichTextEmpty } from "adminComponents/organisms/RichTextEditor/util";
import { CreateQuestionFormDataType } from "adminComponents/utils";
import { pxToRem } from "adminComponents/utils/pxToRem";

export interface CreateTermTabForm {
  definition: string;
  imageUrl: string;
  audioUrl: string;
  videoUrl: string;
  imageAltText: string;
  term: string;
  alternateSpellings: string[];
  requiresHigherOrderThinking: boolean;
}
type FormFieldKey = keyof CreateTermTabForm;

const initialValue: CreateTermTabForm = {
  definition: "",
  imageAltText: "",
  imageUrl: "",
  audioUrl: "",
  videoUrl: "",
  term: "",
  alternateSpellings: [],
  requiresHigherOrderThinking: false,
};

interface TermTabProps {
  defaultValue: CreateQuestionFormDataType;
  hasFeedback: boolean;
  canSubmit: boolean;
  isSubmitting: boolean;
  handleSaveAndClose: (form: CreateTermTabForm) => void;
  handleChangeForm: (form: CreateTermTabForm, isValid: boolean) => void;
  handleNextTab: (valid: boolean) => void;
}

/**
 * CreateQuestionTermTab is responsible for editing/creating term-definition
 * items. As of October 2022, it was determined to remove term items from the
 * UI until such a time as their auto-question-generating functionality could
 * be better presented to users. This component remains in the code base and
 * should work as is if the PracticeSetAddQuestions component re-instates terms.
 */
export const CreateQuestionTermTab: React.FC<TermTabProps> = ({
  defaultValue,
  handleSaveAndClose,
  handleChangeForm,
  handleNextTab,
  hasFeedback,
  canSubmit,
  isSubmitting,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const mediaModalProps = useEditPracticeSetMediaModalContext();

  const _handleSaveAndClose = () => {
    handleSaveAndClose({
      ...formik.values,
    });
  };

  const formik = useFormik<CreateTermTabForm>({
    validateOnMount: true,
    initialValues: defaultValue
      ? {
          alternateSpellings: defaultValue.term?.alternateSpellings ?? [],
          definition: defaultValue.term?.definition ?? "",
          imageAltText: defaultValue.term?.imageAltText ?? "",
          imageUrl: defaultValue.term?.imageUrl ?? "",
          audioUrl: defaultValue.term?.audioUrl ?? "",
          videoUrl: defaultValue.term?.videoUrl ?? "",
          requiresHigherOrderThinking:
            defaultValue.term?.requiresHigherOrderThinking ?? false,
          term: defaultValue.term?.term ?? "",
        }
      : initialValue,
    onSubmit: _handleSaveAndClose,
    validate: (values) => {
      const errors = {} as Record<FormFieldKey, string>;

      if (isRichTextEmpty(values.definition)) {
        errors.definition = t("createQuestion.errDefinitionRequired");
      }

      if (!values.term.trim().length) {
        errors.term = t("createQuestion.errTermRequired");
      }

      handleChangeForm(values, !Object.keys(errors).length);
      return errors;
    },
  });

  const handleAddAlternateSpellingRow = () => {
    formik.setValues({
      ...formik.values,
      alternateSpellings: [...formik.values.alternateSpellings, ""],
    });
  };

  const handleChangeAlternateSpelling = (value: string, index: number) => {
    const alternateSpellings = [...formik.values.alternateSpellings];
    alternateSpellings.splice(index, 1, value);
    formik.setValues({
      ...formik.values,
      alternateSpellings,
    });
  };

  const handleDeleteAlternateSpelling = (index: number) => {
    const alternateSpellings = [...formik.values.alternateSpellings];
    alternateSpellings.splice(index, 1);
    formik.setValues({
      ...formik.values,
      alternateSpellings,
    });
  };

  const handleChangeRequiresHigherOrderThinking = (checked: boolean) => {
    formik.setValues({
      ...formik.values,
      requiresHigherOrderThinking: checked,
    });
  };

  const _handleNextTab = async () => {
    const validationResult = await formik.validateForm();
    handleNextTab(!Object.keys(validationResult).length);
  };

  const handleInsertMediaFormWrapper = (
    imageUrl: string,
    imageAltText: string,
    audioUrl?: string,
    videoUrl?: string
  ) => {
    formik.setValues({
      ...formik.values,
      imageUrl,
      imageAltText,
      audioUrl: audioUrl || "",
      videoUrl: videoUrl || "",
    });
    mediaModalProps.handleInsertMedia(
      imageUrl,
      imageAltText,
      audioUrl,
      videoUrl
    );
  };

  const handleRemoveAudioFormWrapper = () => {
    formik.setValues({
      ...formik.values,
      audioUrl: "",
    });
    mediaModalProps.handleRemoveAudio();
  };

  const handleRemoveMedia = () => {
    formik.setValues({
      ...formik.values,
      audioUrl: "",
      imageUrl: "",
      imageAltText: "",
      videoUrl: "",
    });
    mediaModalProps.handleRemoveImage();
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <SimpleGrid
        py={pxToRem(40)}
        px={[
          "admin.flyout.mobileXPadding",
          null,
          "admin.flyout.desktopXPadding",
        ]}
        gap={pxToRem(40)}
      >
        <QuestionTypeDescription
          icon="chat_bubble_outlined"
          iconColor="utility.badge-blue"
          title={t("createTermsFlyout.termQuestionDropdownTitle")}
          subTitle={t("createTermsFlyout.termQuestionDropdownSubTitle")}
        />
        <FormControl
          isRequired
          variant="adminFormControl"
          isInvalid={!!formik.errors.definition && formik.touched.definition}
        >
          <FormLabel>{t("createQuestion.labelDefinition")}</FormLabel>
          <RichTextEditor
            name="definition"
            placeholder={t("createQuestion.placeholderDefinition")}
            handleBlur={formik.handleBlur}
            handleChange={formik.handleChange}
            value={formik.values.definition}
            hasError={!!formik.errors.definition && formik.touched.definition}
          />
          <FormErrorMessage>{formik.errors.definition}</FormErrorMessage>
        </FormControl>
        <QuestionAddMedia
          handleAddMedia={mediaModalProps.handleOpen}
          handleRemoveMedia={handleRemoveMedia}
          imageAdded={formik.values.imageUrl}
          imageAddedAltText={formik.values.imageAltText}
          audioAdded={formik.values.audioUrl}
          videoAdded={formik.values.videoUrl}
        />
        <SimpleGrid gap={pxToRem(16)}>
          <FormControl
            isRequired
            variant="adminFormControl"
            isInvalid={!!formik.errors.term && formik.touched.term}
          >
            <FormLabel>{t("createQuestion.labelTerm")}</FormLabel>
            <Input
              name="term"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.term}
            />
            <FormErrorMessage>{formik.errors.term}</FormErrorMessage>
          </FormControl>

          {formik.values.alternateSpellings.map((alternateSpelling, index) => (
            <Box
              key={index}
              display="flex"
              alignItems="center"
              gap={pxToRem(16)}
            >
              <Input
                placeholder={t("createQuestion.alternateSpellingPlaceholder")}
                value={alternateSpelling}
                onChange={(e) =>
                  handleChangeAlternateSpelling(e.target.value, index)
                }
              />
              <Box>
                <IconCTAButton
                  ariaLabel={t("common.ariaLabelDeleteAlternateSpelling")}
                  onClick={() => handleDeleteAlternateSpelling(index)}
                />
              </Box>
            </Box>
          ))}

          <Box>
            <Button
              variant="adminButtonOutlined"
              leftIcon={<Icon icon="add" iconColor="primary.warm-black" />}
              size="sm"
              onClick={handleAddAlternateSpellingRow}
            >
              {t("createQuestion.addAlternateSpelling")}
            </Button>
          </Box>
        </SimpleGrid>
        <CreateQuestionFooter
          canSubmit={canSubmit}
          isSubmitting={isSubmitting}
          requiresHigherOrderThinking={
            formik.values.requiresHigherOrderThinking
          }
          handleChangeRequireHigherOrderThinking={
            handleChangeRequiresHigherOrderThinking
          }
          handleNextTab={_handleNextTab}
          handleSaveAndClose={_handleSaveAndClose}
          nextSectionTextCopies={{
            nextSectionCta: hasFeedback
              ? t("createQuestion.editFeedback")
              : t("createQuestion.addFeedback"),
            nextSectionTitle: t("createQuestion.feedback"),
            nextSectionDescription: t("createQuestion.feedbackDescription"),
            nextSectionIcon: hasFeedback ? "edit_outlined" : "add",
          }}
        />
        <AddMediaModal
          {...mediaModalProps}
          audioUrlToAdd={formik.values.audioUrl}
          imageUrlToAdd={formik.values.imageUrl}
          imageAltText={formik.values.imageAltText}
          videoUrlToAdd={formik.values.videoUrl}
          handleInsertMedia={handleInsertMediaFormWrapper}
          handleRemoveImage={handleRemoveMedia}
          handleRemoveAudio={handleRemoveAudioFormWrapper}
        />
      </SimpleGrid>
    </form>
  );
};
