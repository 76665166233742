import { Box, Center, SimpleGrid, Stack, Text } from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { useSessionScene } from "links/lib/contexts/sessionScene";
import {
  BigBoardGameState,
  ISessionGroup,
  ISessionGroups,
} from "links/lib/types";
import { BigBoardStage } from "sessionComponents/atoms/BigBoardStage";
import { GroupToken } from "sessionComponents/atoms/GroupToken";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { BigBoard, IBoardSpace } from "sessionComponents/molecules/BigBoard";
import { ConfirmButton } from "sessionComponents/molecules/ConfirmButton";
import { useDetectOrientation } from "sessionComponents/theme/hooks/useDetectOrientation";
import { getAssetColorScheme } from "sessionComponents/utils/getAssetColorScheme";
import { getFormattedGroupName } from "sessionComponents/utils/getFormattedGroupName";
import {
  getBigBoardContainerHeight,
  getBigBoardSecondaryContentContainerHeight,
} from "sessionComponents/utils/layoutUtils";
import { useSafeConnectedUserArray } from "sharedComponents/hooks/useSafeConnectedUserArray";

export interface ITeacherBigBoardPlacementProps {
  rows: Array<Array<IBoardSpace>>;
  showSpecialSpaces?: boolean;
  groups: ISessionGroups;
  gameState: BigBoardGameState;
  progressButton: {
    label: string;
    action: () => void;
  };
}

export const TeacherBigBoardPlacement: React.FC<
  ITeacherBigBoardPlacementProps
> = ({
  rows,
  showSpecialSpaces = false,
  groups,
  gameState,
  progressButton,
}) => {
  const { match: currentBreakpoints } = useBreakpoints();
  const { isPortrait, isLandscape } = useDetectOrientation();
  const sessionScene = useSessionScene();
  const users = useSafeConnectedUserArray();
  const { t } = useTranslation("session", {
    keyPrefix: "bigBoard",
    useSuspense: false,
  });
  const [nextSceneCalled, setNextSceneCalled] = useState(false);

  const { the_big_board_state } = gameState;

  const { round_placed_group_ids, round_allowed_group_ids } =
    the_big_board_state ?? {
      round_placed_group_ids: [],
      round_allowed_group_ids: [],
    };

  const allowedGroups = useMemo(
    () =>
      round_allowed_group_ids
        .map((gid) => groups[gid])
        .filter((g) => !!g) as ISessionGroup[],
    [round_allowed_group_ids, groups]
  );

  const bigBoardContainerHeight = getBigBoardContainerHeight(isPortrait);

  // When there are tokens to place and all tokens have been placed,
  // proceed automatically to the next round or prize round
  useEffect(() => {
    if (!allowedGroups.length) return;

    if (
      allowedGroups.length === Object.values(round_placed_group_ids).length &&
      !nextSceneCalled
    ) {
      setNextSceneCalled(true);
      progressButton.action();
    }
  }, [allowedGroups, round_placed_group_ids, progressButton, nextSceneCalled]);

  return (
    <BigBoardStage>
      <Stack h="full" w="full" direction={isPortrait ? "column" : "row"}>
        <Box w={isLandscape ? "50%" : "100%"} h={bigBoardContainerHeight}>
          <Box
            h="100%"
            w="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            p={pxToRem(currentBreakpoints.padding)}
          >
            <Center h="100%" w="100%">
              <BigBoard
                allowDrop={false}
                rows={rows}
                showSpecialSpaces={showSpecialSpaces}
                isSpinning={false}
              />
            </Center>
          </Box>
        </Box>
        <Box
          w={isLandscape ? "50%" : "100%"}
          h={`calc(${getBigBoardSecondaryContentContainerHeight(
            isPortrait
          )} - ${currentBreakpoints.headerHeight}px)`}
          p={pxToRem(currentBreakpoints.padding)}
        >
          <Center h="100%" w="100%">
            {allowedGroups.length > 0 && (
              <SimpleGrid
                h="full"
                maxH="full"
                px={pxToRem(16)}
                overflowX="hidden"
                alignContent="center"
                columns={allowedGroups.length > 3 ? 4 : allowedGroups.length}
                spacing={pxToRem(currentBreakpoints.iconSize)}
              >
                {allowedGroups.map((group, index) => {
                  const groupMembers = users.filter(
                    (user) => user.session_group_id === group.id
                  );
                  return (
                    <GroupToken
                      key={`${group.noun}-${group.adjective}-${index}`}
                      colorScheme={getAssetColorScheme(group.color_scheme)}
                      imageUrl={group.icon_url}
                      name={getFormattedGroupName(group)}
                      confirmed={round_placed_group_ids[group.id]}
                      pending={!round_placed_group_ids[group.id]}
                      groupMembers={groupMembers}
                    />
                  );
                })}
              </SimpleGrid>
            )}
            {allowedGroups.length < 1 && (
              <Text
                textAlign="center"
                fontSize={pxToRem(currentBreakpoints.fontSize)}
                color="overlay.white"
                textStyle="gameText"
              >
                {t("noGroupsEarnedTokenLabel")}
              </Text>
            )}
          </Center>
        </Box>
      </Stack>
      <Box
        bgColor="primary.warm-white"
        w={isLandscape ? "50%" : "100%"}
        bottom={0}
        right={0}
        position="absolute"
        height={pxToRem(currentBreakpoints.headerHeight)}
        display="flex"
        justifyContent="center"
        alignItems="center"
        p={pxToRem(currentBreakpoints.padding)}
      >
        <ConfirmButton
          h={pxToRem(currentBreakpoints.buttonHeight)}
          w="100%"
          variant="buttonFilled"
          confirmedWhenChanged={[sessionScene]}
          handleConfirmClick={progressButton.action}
          fontSize={pxToRem(currentBreakpoints.fontSize)}
        >
          {progressButton.label}
        </ConfirmButton>
      </Box>
    </BigBoardStage>
  );
};
