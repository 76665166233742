import {
  Box,
  Button,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";

import { ISessionDraggableSortItemZone } from "links/lib/types";

export interface IZoomableImageProps {
  src: string;
  alt: string;
  maxH: number | string;
  labels?: Array<ISessionDraggableSortItemZone>;
}

const ZoomableImage: React.FC<IZoomableImageProps> = ({
  src,
  alt,
  maxH,
  children,
  labels,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const modalSize = useBreakpointValue({
    base: "xl",
    md: "3xl",
    lg: "4xl",
    xl: "6xl",
  });

  const imageRef = useRef<HTMLImageElement>(null);
  const [dimensions, setDimensions] = useState({ h: 0, w: 0 });
  const modalImageRef = useRef<HTMLImageElement>(null);
  const [modalImageDimensions, setModalImageDimensions] = useState({
    h: 0,
    w: 0,
  });
  const labelRefs: React.RefObject<Array<HTMLDivElement>> = useRef([]);
  const addLabelRef: (el: HTMLDivElement) => void = (el) => {
    if (el && !labelRefs.current?.includes(el)) {
      labelRefs.current?.push(el);
    }
  };
  const modalLabelRefs: React.RefObject<Array<HTMLDivElement>> = useRef([]);
  const addModalLabelRef: (el: HTMLDivElement) => void = (el) => {
    if (el && !modalLabelRefs.current?.includes(el)) {
      modalLabelRefs.current?.push(el);
    }
  };

  return (
    <>
      <Button
        variant="unstyled"
        w="auto"
        h="auto"
        borderRadius="none"
        position="relative"
        onClick={() => setIsOpen(true)}
        sx={{
          _hover: {
            "> div": {
              opacity: 1,
            },
          },
        }}
      >
        <Image
          ref={imageRef}
          src={src}
          alt={alt}
          maxH={maxH}
          onLoad={() =>
            setDimensions({
              h: imageRef.current?.clientHeight || 0,
              w: imageRef.current?.clientWidth || 0,
            })
          }
        />
        {labels &&
          labels.length > 0 &&
          labels.map((label, i) => {
            const ref = labelRefs.current && labelRefs.current[i];
            const left =
              (label.x || 0) * dimensions.w - (ref?.clientWidth || 0) / 2;
            const top =
              (label.y || 0) * dimensions.h - (ref?.clientHeight || 0) / 2;
            return (
              <Box
                key={i}
                ref={addLabelRef}
                left={left}
                top={top}
                bgColor="gray.100"
                border="4px solid"
                borderColor="monaco.02"
                borderRadius="10px"
                position="absolute"
                p="8px"
              >
                {label.label}
              </Box>
            );
          })}
        {children}
        <Box
          alignItems="center"
          justifyContent="center"
          position="absolute"
          top="0"
          left="0"
          bottom="0"
          right="0"
          display="flex"
          opacity="0"
          bg="rgba(0, 157, 214, 0.1)"
          transition="opacity 250ms ease-in-out"
        />
      </Button>

      <Modal onClose={() => setIsOpen(false)} size={modalSize} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent borderRadius="none">
          <ModalCloseButton size="lg" borderRadius="none" />
          <ModalBody mt="50px" position="relative">
            <Image
              ref={modalImageRef}
              w="full"
              src={src}
              alt={alt}
              onLoad={() =>
                setModalImageDimensions({
                  h: modalImageRef.current?.clientHeight || 0,
                  w: modalImageRef.current?.clientWidth || 0,
                })
              }
            />
            {labels &&
              labels.length > 0 &&
              labels.map((label, i) => {
                const ref = modalLabelRefs.current && modalLabelRefs.current[i];
                const left =
                  (label.x || 0) * modalImageDimensions.w -
                  (ref?.clientWidth || 0) / 2;
                const top =
                  (label.y || 0) * modalImageDimensions.h -
                  (ref?.clientHeight || 0) / 2;

                return (
                  <Box
                    key={`modal-${i}`}
                    ref={addModalLabelRef}
                    left={left}
                    top={top}
                    bgColor="gray.100"
                    border="4px solid"
                    borderColor="monaco.02"
                    borderRadius="10px"
                    position="absolute"
                    p="8px"
                  >
                    {label.label}
                  </Box>
                );
              })}
          </ModalBody>
          <ModalFooter>
            {/* TODO: Translate */}
            <Button
              variant="gameTertiary"
              size="lg"
              colorScheme="monaco"
              onClick={() => setIsOpen(false)}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ZoomableImage;
