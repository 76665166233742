import React from "react";

import { useSessionActions } from "links/lib/contexts/sessionActions";
import { useSessionCustomGroupsPartiallyApplied } from "links/lib/contexts/sessionCustomGroupsModified";
import { useSessionGameState } from "links/lib/contexts/sessionGameState";
import { TeacherTeamUp as TheTeacherTeamUp } from "sessionComponents/organisms/TeacherTeamUp";
import { TeacherSessionSoundEffect } from "sessionComponents/types";
import { useSessionAudio } from "sharedComponents/contexts/sessionAudio";
import { useSafeConnectedUserArray } from "sharedComponents/hooks/useSafeConnectedUserArray";
import { useSafeGroupsArray } from "sharedComponents/hooks/useSafeGroupsArray";

//todo consolidate with organism and use fake data for storybook
export const TeacherTeamUp: React.FC = () => {
  const sessionGroups = useSafeGroupsArray();
  const gameState = useSessionGameState();
  const users = useSafeConnectedUserArray();
  const customGroupsPartiallyApplied = useSessionCustomGroupsPartiallyApplied();

  const { initiateOuterGameIntro, initiateGrouping } = useSessionActions();
  const { play: playAudio } = useSessionAudio();

  const startGameIntro = () => {
    playAudio(TeacherSessionSoundEffect.GameStart);
    initiateOuterGameIntro();
  };

  return (
    <TheTeacherTeamUp
      outerGame={gameState?.game_type}
      groups={sessionGroups}
      onInitiateGame={startGameIntro}
      users={users}
      onShuffleTeams={initiateGrouping}
      customGroupsPartiallyApplied={customGroupsPartiallyApplied}
    />
  );
};
