import moment, { Moment } from "moment";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { SHARE_THE_PEAR_URL } from "links/lib/constants";
import { UserRole } from "links/lib/types";

import { useGetPremiumLink } from "./useGetPremiumLink";

interface IPremiumBannerInfo {
  title: string;
  subtitle?: string;
  linkText: string;
  linkTo: string;
}

interface IArgs {
  isTrial: boolean;
  gracePeriodEndDate: Moment;
  isInOrganization: boolean;
  userRole?: UserRole;
  licenseEndDate: Moment;
}

export const usePremiumBannerInfo = ({
  isTrial,
  gracePeriodEndDate,
  isInOrganization,
  userRole,
  licenseEndDate,
}: IArgs): IPremiumBannerInfo => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const defaultPremiumLink = useGetPremiumLink();

  return useMemo(() => {
    // default: individual trial
    let title = t(
      "adminNavigationHeader.premiumGracePeriodBanner.individualTrialExpiringSoonText",
      {
        count: licenseEndDate.diff(moment(), "days"),
      }
    );
    let subtitle: string | undefined = undefined;
    let linkText = t(
      "adminNavigationHeader.premiumGracePeriodBanner.learnToUpgrade"
    );
    let linkTo = defaultPremiumLink;

    if (isInOrganization) {
      title = t("adminNavigationHeader.premiumGracePeriodBanner.expiringSoon", {
        trialOrLicense: isTrial ? t("common.trial") : t("common.license"),
      });

      subtitle = t(
        "adminNavigationHeader.premiumGracePeriodBanner.description",
        {
          endDate: gracePeriodEndDate.format("MM/DD/YY"),
        }
      );

      linkText = t("adminNavigationHeader.premiumGracePeriodBanner.upgrade");

      if (userRole !== UserRole.Admin && isTrial) {
        linkTo = SHARE_THE_PEAR_URL;
        subtitle = t(
          "adminNavigationHeader.premiumGracePeriodBanner.subtitleShareThePear"
        );
        linkText = t(
          "adminNavigationHeader.premiumGracePeriodBanner.sharePearLinkText"
        );
      }
    }

    return {
      title,
      subtitle,
      linkText,
      linkTo,
    };
  }, [
    isInOrganization,
    userRole,
    isTrial,
    gracePeriodEndDate,
    licenseEndDate,
    defaultPremiumLink,
    t,
  ]);
};
