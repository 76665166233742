import {
  Box,
  Flex,
  LinkBox,
  LinkOverlay,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useHistory } from "react-router-dom";

import { Card } from "adminComponents/atoms/Card";
import { ClampedHeading } from "adminComponents/atoms/ClampedHeading";
import { Icon } from "adminComponents/atoms/Icon";
import { Text } from "adminComponents/atoms/Text";
import {
  IconButtonDropdown,
  MenuItemDetails,
} from "adminComponents/molecules/IconButtonDropdown";
import { ICollection } from "links/lib/types";

export interface IProps {
  collection: ICollection;
  handleEdit: (collection: ICollection) => void;
  handleDuplicate: (collection: ICollection) => void;
  handleDelete: (collection: ICollection) => void;
}

export const LibraryPracticeSetFolder: React.FC<IProps> = ({
  collection,
  handleEdit,
  // handleDuplicate,
  handleDelete,
}) => {
  const styles = useMultiStyleConfig("AdminLibraryPracticeSetFolder", {});
  const history = useHistory();

  const { t } = useTranslation("admin", {
    useSuspense: false,
  });

  const collectionUrl = "/t/my-library/folders/" + collection.id;

  const menuItems: MenuItemDetails[] = [
    {
      label: t("common.open"),
      handleClick: () => history.push(collectionUrl),
    },
    {
      label: t("common.editDetails"),
      handleClick: () => handleEdit(collection),
    },
    // {
    //   label: t("common.duplicate"),
    //   handleClick: () => handleDuplicate(collection),
    // },
    {
      label: t("common.delete"),
      handleClick: () => handleDelete(collection),
    },
  ];

  return (
    <LinkBox>
      <Card
        borderColor="primary.tan"
        backgroundColor="primary.white"
        variant="adminCardSmallBorder"
        sx={styles.card}
      >
        <Flex sx={styles.body}>
          <Icon icon="folder_outlined" styles={styles.icon} />
          <Box>
            <LinkOverlay
              as={RouterLink}
              to={collectionUrl}
              sx={styles.linkOverlay}
            >
              <ClampedHeading
                noOfLines={2}
                headingAs="p"
                headingStyles={styles.heading}
                headingVariant="adminH7"
              >
                {collection.name}
              </ClampedHeading>
            </LinkOverlay>
            <Text variant="adminP2" color="primary.dark-gray">
              {t(
                collection.practice_set_count === 1
                  ? "libraryPracticeSetFolder.practiceSetCount_one"
                  : "libraryPracticeSetFolder.practiceSetCount_other",
                {
                  count: collection.practice_set_count,
                }
              )}
            </Text>
          </Box>
          <Box sx={styles.headerContainer}>
            <IconButtonDropdown
              iconVariant="adminLibraryPracticeSetCard"
              menuItems={menuItems}
            />
          </Box>
        </Flex>
      </Card>
    </LinkBox>
  );
};
