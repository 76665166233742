import { cloneDeep } from "lodash";

import {
  ITableData,
  ITableSort,
} from "adminComponents/molecules/SortableTable";
import {
  getStudentFamilyName,
  getStudentGivenName,
} from "adminComponents/utils";
import { IUseFetchAverageUserAccuraciesResult } from "links/lib/features/classrooms/useFetchAverageUserAccuracies";
import { IUseFetchClassroomRecentPracticeSetResponsesResult } from "links/lib/features/classrooms/useFetchClassroomRecentPracticeSetResponses";
import { IFetchClassroomUsersResponse } from "links/lib/features/classrooms/useFetchClassroomUsers";
import {
  IPracticeSetSessionResponse,
  ISessionItemResponse,
  IUser,
} from "links/lib/types";
import { sortAlpha } from "links/lib/util";

export const handleSortStudents = (
  studentsData: ITableData,
  sort: ITableSort
): ITableData => {
  const newStudentsData = cloneDeep(studentsData);

  if (sort.columnIndex === 0) {
    return handleSortStudentsByGivenName(newStudentsData, sort.isAscending);
  } else if (sort.columnIndex === 1) {
    return handleSortStudentsByFamilyName(studentsData, sort.isAscending);
  } else if (sort.columnIndex === 2) {
    return handleSortStudentsByAccuracy(studentsData, sort.isAscending);
  } else {
    console.warn(`Column sort index is unknown. Index:  ${sort.columnIndex}`);
    return studentsData;
  }
};

const handleSortStudentsByAccuracy = (
  studentsData: ITableData,
  isAscending: boolean
): ITableData => {
  return studentsData?.sort((a, b) => {
    const accuracyA = a[2] as number; //TODO
    const accuracyB = b[2] as number;
    if (isAscending) {
      return ascAccuracySortValue(accuracyA, accuracyB);
    } else {
      return descAccuracySortValue(accuracyA, accuracyB);
    }
  });
};

const descAccuracySortValue = (
  accuracyA: number,
  accuracyB: number
): number => {
  return accuracyA - accuracyB;
};

const ascAccuracySortValue = (accuracyA: number, accuracyB: number): number => {
  return accuracyB - accuracyA;
};

const handleSortStudentsByGivenName = (
  studentsData: ITableData,
  isAscending: boolean
): ITableData => {
  return studentsData?.sort((a, b) => {
    const studentA = a[0] as unknown as IUser;
    const studentB = b[0] as unknown as IUser;

    return sortAlpha(
      getStudentGivenName(studentA) || "",
      getStudentGivenName(studentB) || "",
      !isAscending
    );
  });
};

const handleSortStudentsByFamilyName = (
  studentsData: ITableData,
  isAscending: boolean
): ITableData => {
  return studentsData?.sort((a, b) => {
    const studentA = a[0] as unknown as IUser;
    const studentB = b[0] as unknown as IUser;

    return sortAlpha(
      getStudentFamilyName(studentA) || "",
      getStudentFamilyName(studentB) || "",
      !isAscending
    );
  });
};

export const getStudentTableData = ({
  classroomStudentsData,
  classroomPracticeSetUserAccuracyData,
  classroomPracticeSetResponses,
  totalConsideredPracticeSetItems,
  practiceSetId,
  assignmentId,
  practiceSetSessionId,
}: {
  classroomStudentsData?: IFetchClassroomUsersResponse;
  classroomPracticeSetUserAccuracyData?: IUseFetchAverageUserAccuraciesResult;
  classroomPracticeSetResponses?: IUseFetchClassroomRecentPracticeSetResponsesResult;
  totalConsideredPracticeSetItems: number;
  practiceSetId: string;
  assignmentId?: string;
  practiceSetSessionId?: string;
}): ITableData => {
  const studentAnswersMap = new Map<
    string,
    Array<IPracticeSetSessionResponse>
  >();
  classroomPracticeSetResponses?.user_responses.forEach((userAnswers) =>
    studentAnswersMap.set(userAnswers.user_id, userAnswers.responses)
  );

  return classroomStudentsData?.users
    .filter((student) => {
      return student.id !== "0";
    })
    .map((student) => {
      const practiceSetItemAnswersSet = new Set<string>();
      studentAnswersMap
        .get(student.id)
        ?.forEach((answer) =>
          practiceSetItemAnswersSet.add(answer.practice_set_item_id)
        );

      let accuracy = -1;
      const userAccuracy =
        classroomPracticeSetUserAccuracyData?.average_user_accuracies.find(
          (acc) => acc.user_id === student.id
        );
      if (userAccuracy) {
        accuracy = Math.round(userAccuracy.average_accuracy * 100);
      }

      return [
        {
          ...student,
          navigationLink: {
            label: `${student.given_name} ${student.family_name}`,
            to: `/t/classrooms/${classroomStudentsData?.classroom_id}/sets/${practiceSetId}/students/${student.id}`,
            state: { assignmentId, practiceSetSessionId },
          },
        },
        {
          ...student,
          navigationLink: {
            label: `${student.given_name} ${student.family_name}`,
            to: `/t/classrooms/${classroomStudentsData?.classroom_id}/sets/${practiceSetId}/students/${student.id}`,
            state: { assignmentId, practiceSetSessionId },
          },
        },
        accuracy,
        totalConsideredPracticeSetItems > 0 &&
          practiceSetItemAnswersSet.size === totalConsideredPracticeSetItems,
      ];
    }) as ITableData;
};

const doesStudentAnswerMatch = ({
  studentId,
  response,
  classroomPracticeSetResponses,
}: {
  studentId: string;
  response: ISessionItemResponse;
  classroomPracticeSetResponses?: IUseFetchClassroomRecentPracticeSetResponsesResult;
}): boolean => {
  const studentResponses = classroomPracticeSetResponses?.user_responses.find(
    (userResponses) => userResponses.user_id === studentId
  );

  return (
    studentResponses?.responses.some((studentResponse) => {
      if (response.choice_id !== "0") {
        return (
          studentResponse.choice_id === response.choice_id &&
          studentResponse.is_correct === response.is_correct
        );
      } else {
        return (
          studentResponse.response_text === response.response_text &&
          studentResponse.is_correct === response.is_correct &&
          studentResponse.drawing_image_url === response.drawing_image_url
        );
      }
    }) || false
  );
};

interface IStudentWithAnswerText {
  student: IUser;
  response: ISessionItemResponse;
}

export const getStudentsWithMatchingAnswers = ({
  classroomStudentsData,
  responses,
  classroomPracticeSetResponses,
}: {
  classroomStudentsData?: IFetchClassroomUsersResponse;
  responses: ISessionItemResponse[];
  classroomPracticeSetResponses?: IUseFetchClassroomRecentPracticeSetResponsesResult;
}): IStudentWithAnswerText[] => {
  const studentsWithAnswerText: IStudentWithAnswerText[] = [];

  classroomStudentsData?.users.forEach((student) => {
    for (let i = 0; i < responses.length; i++) {
      if (
        doesStudentAnswerMatch({
          studentId: student.id,
          response: responses[i],
          classroomPracticeSetResponses,
        })
      ) {
        studentsWithAnswerText.push({
          student,
          response: responses[i],
        });
      }
    }
  });

  return studentsWithAnswerText;
};
