import { ISessionUser } from "links/lib/types";

export const getFormattedTeamNames = (teamMembers: ISessionUser[]): string => {
  return teamMembers
    .map((student) => {
      const [firstName, lastName] = student.name.split(" ");

      return `${firstName} ${lastName ? `${lastName[0]}.` : ""}`.trim();
    })
    .join(", ");
};
