import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ClassroomArchiveScreen } from "adminComponents/screens/ClassroomArchiveScreen";
import { useErrorToast } from "adminComponents/utils/toast";
import { usePageTrack } from "lib/contexts/analytics";
import { usePageTitle } from "lib/hooks/usePageTitle";
import { useFetchClassrooms } from "links/lib/features/classrooms";
import { AnalyticsPage } from "links/lib/types";
import { useNavigationData } from "screens/TeacherDashboard/contexts/TeacherNavigationDataProvider";

import { useHandleRestoreClassroom } from "./hooks/useHandleRestoreClassroom";

export const ClassroomArchive: React.FC = () => {
  const { navigationData } = useNavigationData();

  const { t } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "classroomArchiveContainer",
  });

  usePageTrack(AnalyticsPage.TeacherDashboard_ClassroomArchive);
  usePageTitle(t("pageTitle"));

  const extendedNavData = useMemo(() => {
    return {
      ...navigationData,
      breadcrumbs: [
        {
          label: t("breadcrumbClassrooms"),
          to: "/t/classrooms",
        },
        {
          label: t("breadcrumbArchive"),
          to: "/t/classrooms/archive",
        },
      ],
    };
  }, [navigationData, t]);

  const fetchClassrooms = useFetchClassrooms({
    archived: true,
  });

  useErrorToast(fetchClassrooms.error);

  const { handleRestoreClassroom, isLoading: isRestoring } =
    useHandleRestoreClassroom();

  return (
    <ClassroomArchiveScreen
      isRestoring={isRestoring}
      isLoading={fetchClassrooms.isLoading}
      classrooms={fetchClassrooms.data?.classrooms || []}
      navigationData={extendedNavData}
      handleRestoreClassroom={handleRestoreClassroom}
    />
  );
};
