import { Box, HStack, SimpleGrid } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { SessionGameType } from "links/lib/types";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { boxShadows } from "sessionComponents/theme/boxShadows";

import { GameBackground } from "../GameBackground";

interface ILandscapeLayoutProps {
  outerGame?: SessionGameType;
  questionComponent: React.ReactElement;
  answerOptionsComponent: React.ReactElement;
  confirmAreaComponent?: React.ReactElement;
  groupStatusComponent?: React.ReactElement; //only on teacher view
  answerOptionsContainerHeight?: number | string;
}

export const LandscapeLayout: React.FC<ILandscapeLayoutProps> = ({
  outerGame = SessionGameType.TheBigBoard,
  questionComponent,
  answerOptionsComponent,
  confirmAreaComponent,
  groupStatusComponent,
  answerOptionsContainerHeight = 0,
}) => {
  const overflowRef = useRef<HTMLDivElement | null>(null);
  const [isOverflowing, setIsOverflowing] = useState<boolean | undefined>();
  const overflowHeight = overflowRef.current?.clientHeight || 0;
  const overflowScrollHeight = overflowRef.current?.scrollHeight || 0;
  const { match: currentBreakpoints } = useBreakpoints();

  useEffect(() => {
    if (
      !!overflowHeight &&
      !!overflowScrollHeight &&
      isOverflowing === undefined
    ) {
      setIsOverflowing(overflowHeight < overflowScrollHeight);
    }
  }, [isOverflowing, overflowHeight, overflowScrollHeight, setIsOverflowing]);

  return (
    <>
      <SimpleGrid
        columns={2}
        spacing={0}
        height="full"
        width="full"
        borderTopWidth={pxToRem(currentBreakpoints.borderWidth)}
        borderTopColor="primary.tan"
      >
        <HStack spacing="0" w="full" h="full" overflow="auto">
          {!!groupStatusComponent && groupStatusComponent}
          <GameBackground minWidth={0} outerGame={outerGame}>
            <Box h="full" w="full" justifyContent="center" display="flex">
              {questionComponent}
            </Box>
          </GameBackground>
        </HStack>
        <Box
          backgroundColor="primary.warm-white"
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDir="column"
          position="relative"
          transition="all .5s"
        >
          <Box
            padding={pxToRem(currentBreakpoints.padding)}
            ref={overflowRef}
            h="100%"
            w="100%"
            overflowY="auto"
          >
            <Box
              height={answerOptionsContainerHeight} // needs a value for Timer and Draw
            >
              {answerOptionsComponent}
            </Box>
          </Box>
          {!!confirmAreaComponent && (
            <Box
              position="relative"
              bottom="0"
              width="100%"
              borderTopWidth={pxToRem(currentBreakpoints.borderWidth)}
              borderTopColor="primary.tan"
              display="flex"
              justifyContent="center"
              alignItems="center"
              padding={pxToRem(currentBreakpoints.padding)}
              backgroundColor="primary.warm-white"
              boxShadow={isOverflowing ? boxShadows.questionLayout : undefined}
            >
              {confirmAreaComponent}
            </Box>
          )}
        </Box>
      </SimpleGrid>
    </>
  );
};
