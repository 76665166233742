import { useEffect, useState } from "react";
import { TransitionFn, config, useTransition } from "react-spring";
import { useTimeout } from "react-use";

import { useRoundReview } from "sessionComponents/contexts/roundReview";

const SPLASH_LIFETIME_MS = 5000;

export interface IResult {
  splashTransition: TransitionFn<
    string,
    {
      opacity: number;
      y: number;
    }
  >;
  contentAtRest: boolean;
  skip: () => void;
}

export const useRoundReviewSplashAnimation = (
  splashTimeoutMs = SPLASH_LIFETIME_MS,
  canSkip = false
): IResult => {
  const [hideSplash, cancelHideSplashTO] = useTimeout(splashTimeoutMs);
  const [contentAtRest, setContentAtRest] = useState(false);
  const [skipSplash, setSkipSplash] = useState(false);
  const { setSplashScreenVisible } = useRoundReview();

  const isSplashVisible = !skipSplash && !hideSplash();

  const skip = () => {
    if (canSkip && isSplashVisible) {
      cancelHideSplashTO();
      setSkipSplash(true);
    }
  };

  const splashTransition = useTransition(
    isSplashVisible ? ["splash"] : ["content"],
    {
      from: {
        opacity: 0,
        y: -100,
      },
      enter: {
        opacity: 1,
        y: 0,
      },
      leave: {
        opacity: 0,
        y: -100,
      },
      exitBeforeEnter: true,
      config: config.stiff,
      onChange: () => setContentAtRest(false),
      onRest: () => setContentAtRest(true),
    }
  );

  useEffect(() => {
    setSplashScreenVisible(isSplashVisible);
  }, [isSplashVisible, setSplashScreenVisible]);

  return { splashTransition, contentAtRest, skip };
};
