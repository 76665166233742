import { FormControl, SimpleGrid } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { usePrevious, useUnmount } from "react-use";

import { FormErrorMessage } from "adminComponents/atoms/FormErrorMessage";
import { FormLabel } from "adminComponents/atoms/FormLabel";
import {
  EditPracticeSetMediaModalContextProvider,
  useEditPracticeSetMediaModalContext,
} from "adminComponents/contexts/EditPracticeSetMediaModalContext";
import { CreateQuestionFooter } from "adminComponents/molecules/CreateQuestionFooter";
import {
  MultipleResponses,
  TResponse,
} from "adminComponents/molecules/MultipleResponses";
import { QuestionAddMedia } from "adminComponents/molecules/QuestionAddMedia";
import { QuestionTypeDescription } from "adminComponents/molecules/QuestionTypeDescription";
import { AddMediaModal } from "adminComponents/organisms/AddMediaModal";
import { RichTextEditor } from "adminComponents/organisms/RichTextEditor";
import { isRichTextEmpty } from "adminComponents/organisms/RichTextEditor/util";
import { CreateQuestionFormDataType } from "adminComponents/utils";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { QuestionType, RichValue } from "links/lib/types";
import { generateEmptySlateState } from "links/lib/util";

export interface CreateMultipleChoiceTabForm {
  prompt: RichValue;
  audioUrl: string;
  videoUrl: string;
  imageUrl: string;
  imageAltText: string;
  requiresHigherOrderThinking: boolean;
  responses: TResponse[];
  questionType: QuestionType.MultipleChoice | QuestionType.MultipleSelect;
}

interface IProps {
  defaultValue: CreateQuestionFormDataType;
  hasFeedback: boolean;
  canSubmit: boolean;
  isSubmitting: boolean;
  isImageMode: boolean;
  handleSaveAndClose: (form: CreateMultipleChoiceTabForm) => void;
  handleChangeForm: (
    isValid: boolean,
    form?: CreateMultipleChoiceTabForm
  ) => void;
  handleNextTab: () => void;
  handleDirty?: () => void;
  handleSecondaryNextTab?: (theFormData: CreateQuestionFormDataType) => void;
  showSecondaryFooterCta?: boolean;
}

const getInitialValues = (
  isImageMode: boolean
): CreateMultipleChoiceTabForm => {
  return {
    imageAltText: "",
    imageUrl: "",
    audioUrl: "",
    videoUrl: "",
    requiresHigherOrderThinking: false,
    prompt: generateEmptySlateState(),
    responses: isImageMode
      ? []
      : [
          { value: generateEmptySlateState(), isCorrect: true },
          { value: generateEmptySlateState(), isCorrect: false },
        ],
    questionType: QuestionType.MultipleChoice,
  };
};

export const CreateQuestionMultipleChoiceTab: React.FC<IProps> = ({
  defaultValue,
  handleSaveAndClose,
  handleChangeForm,
  handleNextTab,
  hasFeedback,
  canSubmit,
  isSubmitting,
  isImageMode,
  handleDirty,
  handleSecondaryNextTab,
  showSecondaryFooterCta,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const mediaModalProps = useEditPracticeSetMediaModalContext();

  const formMethods = useForm<CreateMultipleChoiceTabForm>({
    defaultValues: defaultValue.multipleChoice ?? getInitialValues(isImageMode),
    mode: "onChange",
  });

  const {
    handleSubmit,
    setValue,
    watch,
    control,
    getValues,
    formState: { errors, isValid, touchedFields, isDirty },
  } = formMethods;

  useEffect(() => {
    if (isDirty && handleDirty) handleDirty();
  }, [isDirty, handleDirty]);

  const _handleSubmit = (data: CreateMultipleChoiceTabForm) => {
    handleSaveAndClose(data);
  };

  useUnmount(() => {
    handleChangeForm(isValid, getValues());
  });

  const prevIsValid = usePrevious(isValid);
  useEffect(() => {
    if (prevIsValid !== isValid) {
      handleChangeForm(isValid);
    }
  }, [isValid, handleChangeForm, prevIsValid]);

  const [
    audioUrl,
    imageAltText,
    imageUrl,
    videoUrl,
    requiresHigherOrderThinking,
  ] = watch([
    "audioUrl",
    "imageAltText",
    "imageUrl",
    "videoUrl",
    "requiresHigherOrderThinking",
  ]);

  const _handleNextTab = async () => {
    handleNextTab();
  };

  const _handleSecondaryNextTab = () => {
    handleSecondaryNextTab?.({ multipleChoice: getValues() });
  };

  const handleChangeRequiresHigherOrderThinking = (checked: boolean) => {
    setValue("requiresHigherOrderThinking", checked);
  };

  const _handleInsertMedia = (
    imageUrl: string,
    imageAltText: string,
    audioUrl?: string,
    videoUrl?: string
  ) => {
    setValue("audioUrl", audioUrl ?? "");
    setValue("imageUrl", imageUrl);
    setValue("imageAltText", imageAltText);
    setValue("videoUrl", videoUrl ?? "");
    mediaModalProps.handleInsertMedia(
      imageUrl,
      imageAltText,
      audioUrl,
      videoUrl
    );
  };

  const _handleRemoveAudio = () => {
    setValue("audioUrl", "");
    mediaModalProps.handleRemoveAudio();
  };

  const _handleRemoveMedia = () => {
    setValue("audioUrl", "");
    setValue("imageUrl", "");
    setValue("imageAltText", "");
    setValue("videoUrl", "");
    mediaModalProps.handleRemoveImage();
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(_handleSubmit)}>
        <SimpleGrid
          py={pxToRem(40)}
          px={[
            "admin.flyout.mobileXPadding",
            null,
            "admin.flyout.desktopXPadding",
          ]}
          gap={pxToRem(40)}
        >
          <QuestionTypeDescription
            icon="check_outlined"
            iconColor="utility.badge-purple"
            title={t("createMultipleChoiceFlyout.multipleChoiceDropdownTitle")}
            subTitle={t(
              "createMultipleChoiceFlyout.multipleChoiceDropdownSubTitle"
            )}
          />
          <FormControl
            isRequired
            variant="adminFormControl"
            isInvalid={!!touchedFields.prompt && !!errors.prompt}
            minWidth={0}
          >
            <FormLabel>{t("createQuestion.labelPrompt")}</FormLabel>
            <Controller
              control={control}
              name="prompt"
              rules={{
                validate: {
                  isNotEmpty: (v) =>
                    !isRichTextEmpty(v) ||
                    (t("createQuestion.errPromptRequired") as string),
                },
              }}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { isTouched, error },
              }) => (
                <RichTextEditor
                  aria-label={t("createQuestion.placeholderPrompt")}
                  ref={ref}
                  name={name}
                  handleChangeRaw={onChange}
                  handleBlur={onBlur}
                  value={value}
                  placeholder={t("createQuestion.placeholderPrompt")}
                  hasError={isTouched && !!error}
                />
              )}
            />
            <FormErrorMessage>{errors.prompt?.message}</FormErrorMessage>
          </FormControl>
          <QuestionAddMedia
            handleAddMedia={mediaModalProps.handleOpen}
            handleRemoveMedia={_handleRemoveMedia}
            imageAdded={imageUrl}
            imageAddedAltText={imageAltText}
            audioAdded={audioUrl}
            videoAdded={videoUrl}
          />
          <EditPracticeSetMediaModalContextProvider>
            <MultipleResponses
              label={t("createQuestion.labelResponses")}
              isImageMode={isImageMode}
            />
          </EditPracticeSetMediaModalContextProvider>
          <CreateQuestionFooter
            canSubmit={canSubmit}
            isSubmitting={isSubmitting}
            requiresHigherOrderThinking={requiresHigherOrderThinking}
            handleChangeRequireHigherOrderThinking={
              handleChangeRequiresHigherOrderThinking
            }
            handleNextTab={_handleNextTab}
            handleSaveAndClose={handleSubmit(_handleSubmit)}
            nextSectionTextCopies={{
              nextSectionCta: hasFeedback
                ? t("createQuestion.editFeedback")
                : t("createQuestion.addFeedback"),
              nextSectionTitle: t("createQuestion.feedback"),
              nextSectionDescription: t("createQuestion.feedbackDescription"),
              nextSectionIcon: hasFeedback ? "edit_outlined" : "add",
              secondaryNextSectionCta: showSecondaryFooterCta
                ? t("createQuestion.feedbackGenerate")
                : undefined,
              secondaryNextSectionIcon: "zap",
              secondaryNextSectionOnClick: showSecondaryFooterCta
                ? _handleSecondaryNextTab
                : undefined,
            }}
          />
          <AddMediaModal
            {...mediaModalProps}
            audioUrlToAdd={audioUrl}
            imageUrlToAdd={imageUrl}
            imageAltText={imageAltText}
            videoUrlToAdd={videoUrl}
            handleInsertMedia={_handleInsertMedia}
            handleRemoveImage={_handleRemoveMedia}
            handleRemoveAudio={_handleRemoveAudio}
          />
        </SimpleGrid>
      </form>
    </FormProvider>
  );
};
