import { Box, Divider as ChakraDivider, Flex, VStack } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Card } from "adminComponents/atoms/Card";
import { Divider } from "adminComponents/atoms/Divider";
import { Heading } from "adminComponents/atoms/Heading";
import { HighFive } from "adminComponents/atoms/HighFive";
import { EmptyCard } from "adminComponents/molecules/EmptyCard";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { SocialEvent } from "links/lib/types";

export interface IProps {
  socialEvents: SocialEvent[];
  handleSocialEventClick: (socialEvent: SocialEvent) => void;
}

export const HighFivesCard: React.FC<IProps> = ({
  socialEvents,
  handleSocialEventClick,
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });

  return socialEvents.length ? (
    <Card
      borderColor="primary.tan"
      backgroundColor="primary.white"
      variant="adminCardMediumBorderMediumPadding"
    >
      <VStack alignItems="start" spacing={pxToRem(16)}>
        <Heading as="p" variant="adminH6Bold">
          {t("highFivesCard.title")}
        </Heading>
        <Heading as="p" variant="adminH7">
          {t("highFivesCard.description")}
        </Heading>
      </VStack>
      <Divider color="primary.light-gray" marginY={pxToRem(24)} />
      <Flex direction="column" gap={pxToRem(12)}>
        {socialEvents.map((socialEvent, i) => {
          return (
            <Box key={socialEvent.id}>
              <HighFive
                socialEvent={socialEvent}
                handleClick={() => handleSocialEventClick(socialEvent)}
              />
              {i < socialEvents.length - 1 && (
                <ChakraDivider marginTop={pxToRem(10)} />
              )}
            </Box>
          );
        })}
      </Flex>
    </Card>
  ) : (
    <EmptyCard title={t("highFivesCard.emptySocialEvents")} />
  );
};
