import { Box, usePrefersReducedMotion } from "@chakra-ui/react";
import lottie, { AnimationItem } from "lottie-web/build/player/lottie_light";
import React, { useEffect, useRef, useState } from "react";
import { animated, config, useSpring } from "react-spring";

export interface IFullscreenAnimationTakeoverProps {
  show: boolean;
  animationData: unknown;
  endTime?: number;
  handlePlay?: () => void;
  handleEnd: () => void;
  bgColor?: string;
}

const AnimatedBox = animated(Box);

export const FullscreenAnimationTakeover: React.FC<
  IFullscreenAnimationTakeoverProps
> = ({ show, handleEnd, handlePlay, animationData, endTime, bgColor }) => {
  const playerRef = useRef<HTMLDivElement | null>(null);
  const animRef = useRef<AnimationItem | null>(null);
  const reduceMotion = usePrefersReducedMotion();
  const [showBackdrop, setShowBackdrop] = useState(false);
  const animationDataString = JSON.stringify(animationData);

  const backdropSpring = useSpring({
    from: {
      opacity: 0,
      scale: reduceMotion ? 1 : 0,
    },
    to: {
      opacity: show ? 1 : 0,
      scale: show ? 1 : 0,
    },
    config: config.stiff,
    onRest: () => {
      if (!show) setShowBackdrop(false);
    },
  });

  useEffect(() => {
    if (show) setShowBackdrop(true);
  }, [show]);

  useEffect(() => {
    const loadAndPlay = () => {
      if (!playerRef.current) return;

      const animation = (animRef.current = lottie.loadAnimation({
        container: playerRef.current,
        renderer: "svg",
        loop: false,
        autoplay: false,
        animationData: JSON.parse(animationDataString),
      }));

      const play = () => {
        animation.play();
        handlePlay?.();
      };

      animation.addEventListener("DOMLoaded", play);

      if (endTime) {
        let hasTriggeredEnd = false;
        animation.addEventListener(
          "enterFrame",
          ({ currentTime }: { currentTime: number }) => {
            if (!hasTriggeredEnd && currentTime > endTime) {
              hasTriggeredEnd = true;
              handleEnd();
            }
          }
        );
      } else {
        animation.addEventListener("complete", handleEnd);
      }

      return () => {
        animation.stop();
        animation.destroy();
        lottie.stop();
        lottie.destroy();
      };
    };

    if (show) return loadAndPlay();
  }, [show, handlePlay, endTime, handleEnd, animationDataString]);

  return (
    <AnimatedBox
      position="fixed"
      top="0"
      left="0"
      right="0"
      bottom="0"
      zIndex="10000"
      display={showBackdrop ? "block" : "none"}
      bg={bgColor}
      style={backdropSpring}
    >
      <Box
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        ref={playerRef}
      />
    </AnimatedBox>
  );
};
