import { useTranslation } from "react-i18next";

import {
  PracticeSessionItemVariantType,
  QuestionType,
  SessionRoundState,
  SessionScene,
} from "links/lib/types";

export const useRenderVariantMessage = ({
  variant,
  scene,
  roundState,
}: {
  variant: PracticeSessionItemVariantType;
  scene: SessionScene;
  roundState: SessionRoundState | null;
}): string => {
  const { t } = useTranslation("session", {
    keyPrefix: "round.itemVariants",
    useSuspense: false,
  });
  const renderVariantMessage = (
    variant: PracticeSessionItemVariantType,
    scene: SessionScene
  ): string => {
    switch (variant) {
      case PracticeSessionItemVariantType.CoopRadios:
        return t("coopRadios");
      case PracticeSessionItemVariantType.CoopChecks:
        return t("coopChecks");
      case PracticeSessionItemVariantType.CoopDragsort:
        return roundState?.practice_set_session_item.question_type ===
          QuestionType.Classify
          ? t("coopClassification")
          : t("coopDiagram");
      case PracticeSessionItemVariantType.CoopTextMatch:
        return t("coopTextMatch");
      case PracticeSessionItemVariantType.CoopTextMatchNumeric:
        return t("coopTextMatchNumeric");
      case PracticeSessionItemVariantType.CoopDraw:
        switch (scene) {
          case SessionScene.Voting:
            return t("coopDrawStudentVote");
          case SessionScene.VotingResults:
            return t("coopDrawTeacherVote");
          case SessionScene.VotingAwards:
            return t("coopDrawVoteAwards");
          default:
            return t("coopDraw");
        }

      default:
        return `Round variant not implemented: ${variant}`;
    }
  };

  return renderVariantMessage(variant, scene);
};
