import { Box, Center, Stack, useMultiStyleConfig } from "@chakra-ui/react";
import React from "react";

import { Button, IProps as IButtonProps } from "adminComponents/atoms/Button";
import { Card } from "adminComponents/atoms/Card";
import { Heading } from "adminComponents/atoms/Heading";
import { Icon, IconType } from "adminComponents/atoms/Icon";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils/pxToRem";

interface LabelProps {
  icon?: IconType;
  text: string;
}

export interface IActionProps extends IButtonProps, LabelProps {}

export interface IEmptyCardProps {
  actions?: IActionProps[];
  extraAction?: IActionProps;
  text?: string;
  title?: string;
  cardArt?: JSX.Element;
}

export const EmptyCard: React.FC<IEmptyCardProps> = ({
  actions,
  extraAction,
  text,
  title,
  cardArt,
}) => {
  const styles = useMultiStyleConfig("AdminEmptyCard", {});

  return (
    <Card borderColor="primary.tan" variant="adminCardSmallBorder">
      {cardArt && (
        <Center w="full" justifyContent="center">
          {cardArt}
        </Center>
      )}
      <Box __css={styles.container}>
        {(title || text) && (
          <Stack spacing={pxToRem(4)}>
            {title && (
              <Heading as="p" textAlign="center" variant="adminH6">
                {title}
              </Heading>
            )}
            {text && (
              <Text
                textAlign="center"
                textColor="primary.warm-black"
                variant="adminP1"
              >
                {text}
              </Text>
            )}
          </Stack>
        )}
        {actions && (
          <Box __css={styles.actions}>
            {actions?.map((a, i) => {
              let leftIconOverride = {};
              if (a.leftIcon) {
                leftIconOverride = {
                  leftIcon: a.leftIcon,
                };
              }
              return (
                <Button
                  {...a}
                  key={i}
                  leftIcon={a.icon ? <Icon icon={a.icon} /> : undefined}
                  {...leftIconOverride}
                >
                  {a.text}
                </Button>
              );
            })}
          </Box>
        )}
        {extraAction && (
          <Box __css={styles.extraAction}>
            <Button
              {...extraAction}
              leftIcon={
                extraAction.icon ? <Icon icon={extraAction.icon} /> : undefined
              }
              variant="adminTextButtonMedium"
            >
              {extraAction.text}
            </Button>
          </Box>
        )}
      </Box>
    </Card>
  );
};
