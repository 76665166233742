import {
  IStageCamera,
  IStageOverlayAsset,
  NametagArrowPosition,
} from "../types";

export interface IStageNametagConstructorArgs {
  name: string;
  x: number;
  y: number;
  fontSize: number;
  textColor: string;
  paddingX: number;
  paddingY: number;
  borderRadius: number;
  arrowPosition: NametagArrowPosition;
  arrowSize: number;
  isHidden: boolean;
}

class StageNametag implements IStageOverlayAsset {
  x: number;
  y: number;
  name: string;
  fontSize: number;
  textColor: string;
  paddingX: number;
  paddingY: number;
  borderRadius: number;
  arrowPosition: NametagArrowPosition;
  arrowSize: number;
  isHidden: boolean;

  constructor(args: IStageNametagConstructorArgs) {
    this.x = args.x;
    this.y = args.y;
    this.name = args.name;
    this.fontSize = args.fontSize;
    this.textColor = args.textColor;
    this.paddingX = args.paddingX;
    this.paddingY = args.paddingY;
    this.borderRadius = args.borderRadius;
    this.arrowPosition = args.arrowPosition;
    this.arrowSize = args.arrowSize;
    this.isHidden = args.isHidden;
  }

  setIsHidden(isHidden: boolean): void {
    this.isHidden = isHidden;
  }

  setTransform(x: number, y: number): void {
    this.x = x;
    this.y = y;
  }

  setProps(props: {
    name: string;
    fontSize: number;
    textColor: string;
    paddingX: number;
    paddingY: number;
    borderRadius: number;
    arrowPosition: NametagArrowPosition;
    arrowSize: number;
  }): void {
    const {
      name,
      fontSize,
      textColor,
      paddingX,
      paddingY,
      borderRadius,
      arrowPosition,
      arrowSize,
    } = props;

    this.name = name;
    this.fontSize = fontSize;
    this.textColor = textColor;
    this.paddingX = paddingX;
    this.paddingY = paddingY;
    this.borderRadius = borderRadius;
    this.arrowPosition = arrowPosition;
    this.arrowSize = arrowSize;
  }

  drawRoundRect(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    width: number,
    height: number
  ): void {
    const radius = Math.min(this.borderRadius, height / 2);

    ctx.fillStyle = "rgba(0,0,0,0.8)";
    ctx.beginPath();
    ctx.moveTo(x + radius, y);
    ctx.lineTo(x + width - radius, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
    ctx.lineTo(x + width, y + height - radius);
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
    ctx.lineTo(x + radius, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
    ctx.lineTo(x, y + radius);
    ctx.quadraticCurveTo(x, y, x + radius, y);
    ctx.closePath();
    ctx.fill();
  }

  drawName(ctx: CanvasRenderingContext2D, x: number, y: number): void {
    ctx.fillStyle = this.textColor;
    ctx.textAlign = "center";
    ctx.fillText(this.name, x, y);
  }

  drawArrow(ctx: CanvasRenderingContext2D, x: number, y: number): void {
    ctx.fillStyle = "rgba(0,0,0,0.8)";
    ctx.beginPath();

    ctx.moveTo(x - this.arrowSize, y);
    if (this.arrowPosition === NametagArrowPosition.Top) {
      ctx.lineTo(x, y - this.arrowSize);
    } else {
      ctx.lineTo(x, y + this.arrowSize);
    }
    ctx.lineTo(x + this.arrowSize, y);

    ctx.closePath();
    ctx.fill();
  }

  render(
    ctx: CanvasRenderingContext2D,
    camera: IStageCamera,
    pixelRatio: number
  ): void {
    if (this.isHidden) return;
    const scale = 1 / camera.zoom;
    ctx.font = `${this.fontSize * scale}px TTCommons,sans-serif`;
    const textMetrics = ctx.measureText(this.name);

    // x and y are relative to top center of canvas
    const canvasWidth = ctx.canvas.width / pixelRatio;
    const canvasHeight = ctx.canvas.height / pixelRatio;
    const x = canvasWidth / 2 + this.x * scale;
    const y = canvasHeight / 2 - this.y * scale;

    const textHeight =
      textMetrics.actualBoundingBoxAscent +
      textMetrics.actualBoundingBoxDescent;

    const rectX = x - textMetrics.width / 2 - this.paddingX * scale;
    const rectY = y - textHeight / 2 - this.paddingY * scale;
    const rectWidth = textMetrics.width + this.paddingX * scale * 2;
    const rectHeight = textHeight + this.paddingY * scale * 2;

    // draw nameplate
    this.drawRoundRect(ctx, rectX, rectY, rectWidth, rectHeight);
    // draw arrow
    this.drawArrow(
      ctx,
      rectX + rectWidth / 2,
      this.arrowPosition === NametagArrowPosition.Top
        ? rectY
        : rectY + rectHeight
    );
    // draw name
    this.drawName(ctx, x, y + textMetrics.actualBoundingBoxAscent / 2);
  }
}

export { StageNametag };
