import { useMemo } from "react";
import { TransitionFn, config, useTransition } from "react-spring";

interface IUseRoundContentTransitionProps {
  showRoundIntro: boolean;
}

export const useRoundContentTransition = ({
  showRoundIntro,
}: IUseRoundContentTransitionProps): TransitionFn<
  string,
  {
    opacity: number;
  }
> => {
  const roundContentTransition = useTransition(
    showRoundIntro ? ["splash"] : ["content"],
    {
      from: { opacity: showRoundIntro ? 1 : 0 },
      enter: { opacity: 1 },
      leave: { opacity: 0 },
      delay: showRoundIntro ? 0 : 500,
      config: config.stiff,
    }
  );

  return useMemo(() => roundContentTransition, [roundContentTransition]);
};
