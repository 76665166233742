import { Flex, useMultiStyleConfig } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Checkbox } from "adminComponents/atoms/Checkbox";
import { Tag } from "adminComponents/atoms/Tag";
import { Text } from "adminComponents/atoms/Text";
import { generateStudentName } from "adminComponents/utils";
import { IUser } from "links/lib/types";

interface StudentOptionProps {
  student: IUser;
  highlighted: boolean;
  onSelect: (studentId: string, selected: boolean) => void;
  selected: boolean;
  sessionGroupIndex?: number;
  disabled?: boolean;
}

export const StudentOption: React.FC<StudentOptionProps> = ({
  student,
  highlighted,
  onSelect,
  selected,
  sessionGroupIndex,
  disabled = false,
}) => {
  const styles = useMultiStyleConfig("AdminSessionGroupsModal", {});
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });

  const { primary: name } = generateStudentName(student);

  const isDisabled = disabled || (!highlighted && !!sessionGroupIndex);

  return (
    <Flex key={student.id} sx={styles.studentOption}>
      <Checkbox
        value={student.id}
        isChecked={selected}
        onChange={(e) => onSelect(student.id, e.target.checked)}
        isDisabled={isDisabled}
      >
        <Text>{name}</Text>
      </Checkbox>
      {sessionGroupIndex && (
        <Tag
          opacity={isDisabled ? 0.5 : 1}
          colorScheme={isDisabled ? "white" : "default"}
        >
          {t("sessionGroupsModal.groupIndex", { index: sessionGroupIndex })}
        </Tag>
      )}
    </Flex>
  );
};
