import { Box } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import { useMount, usePrevious } from "react-use";

import { useAnalytics } from "lib/contexts/analytics";
import { APPCUES_PREMIUM_MODAL_FLOW_ID } from "links/lib/constants";
import { useAuth } from "links/lib/features/auth";
import { AnalyticsEvent, UserRole } from "links/lib/types";
import ProtectedRoute from "screens/App/components/ProtectedRoute";
import AppError from "screens/AppError";
import GuestTeacherHome from "screens/GuestTeacherHome";
import { TeacherAccount } from "screens/TeacherAccount";
import { TeacherClassrooms } from "screens/TeacherClassrooms";
import { TeacherCreateInstantSet } from "screens/TeacherCreateInstantSet";
import TeacherHome, { TeacherHomeLibrary } from "screens/TeacherHome";
import { TeacherMyAvatar } from "screens/TeacherMyAvatar";
import TeacherMyLibrary from "screens/TeacherMyLibrary";
import { TeacherStaffManagement } from "screens/TeacherMyTeachers";
import { TeacherNotificationSettings } from "screens/TeacherNotificationSettings";
import TeacherPracticeSetDetail from "screens/TeacherPracticeSetDetail";
import TeacherPracticeSetPreviewSession from "screens/TeacherPracticeSetPreviewSession";
import { TeacherProfile } from "screens/TeacherProfile";
import { TeacherProfileSettings } from "screens/TeacherProfileSettings";
import TeacherPublicLibrary from "screens/TeacherPublicLibrary";
import TeacherStudentSession from "screens/TeacherStudentSession";
import { useShowNewLibrary } from "sharedComponents/hooks/useShowNewLibrary";
import { guestUserHasSignedUp } from "sharedComponents/utils/signinUtils";

import { insertChatbox } from "../../lib/salesforceChat";
import { GuestTeacherNavigationDataProvider } from "./contexts/GuestTeacherNavigationDataProvider";
import { TeacherNavigationDataProvider } from "./contexts/TeacherNavigationDataProvider";

const TeacherDashboard: React.FC = () => {
  const { authUser, authRequiredAgreements, isGhostUser } = useAuth();
  const newLibraryFeatureEnabled = useShowNewLibrary();
  const { trackEvent } = useAnalytics();

  const shouldOnboard = () => {
    if (isGhostUser) return false;
    if (!authUser) return false;
    if (authUser.is_guest) return false;

    // They should onboard, but onboarding is inlined on the explore page
    // rather than on /t/onboard as before.
    if (newLibraryFeatureEnabled) {
      return false;
    }

    if (!authUser.country) return true;
    if (!authUser.language) return true;

    return false;
  };

  const shouldShowAgreements = () => {
    if (isGhostUser) return false;
    if (!authUser) return false;
    if (!authRequiredAgreements) return false;
    if (authUser.role === UserRole.Student) return false;
    if (authUser.is_guest) return false;

    return authRequiredAgreements.length > 0;
  };

  useMount(() => {
    // Load salesforce chat
    insertChatbox(authUser?.role || UserRole.Teacher);
  });

  // Checks if a guest user has signed up, programmatically start
  // and introductory AppCue flow if so.
  const prevAuthUser = usePrevious(authUser);
  useEffect(() => {
    if (
      newLibraryFeatureEnabled &&
      guestUserHasSignedUp({
        initialAuthUser: prevAuthUser,
        newAuthUser: authUser,
      })
    ) {
      window.Appcues?.show(APPCUES_PREMIUM_MODAL_FLOW_ID);
      trackEvent(AnalyticsEvent.TeacherDashboard_Explore_Signup_Complete, {});
    }
  }, [authUser, newLibraryFeatureEnabled, prevAuthUser, trackEvent]);

  if (authUser?.is_guest) {
    return newLibraryFeatureEnabled ? (
      <GuestTeacherNavigationDataProvider>
        <Switch>
          <Route path="/t/explore/:tab?">
            <GuestTeacherHome key={window.location.search} />
          </Route>
          <Route path="/t/instant-sets">
            <TeacherCreateInstantSet />
          </Route>
        </Switch>
      </GuestTeacherNavigationDataProvider>
    ) : (
      <Redirect to={"/sign-in/teacher"} />
    );
  }

  if (shouldOnboard()) {
    return <Redirect to="/t/onboard" />;
  }

  if (shouldShowAgreements()) {
    return <Redirect to={"/t/agreements"} />;
  }

  return (
    <TeacherNavigationDataProvider>
      <Box bg="primary.warm-white" h="full" minH="100vh">
        <Switch>
          <ProtectedRoute
            path="/t/my-teachers"
            role={[UserRole.Admin]}
            exact={false}
          >
            <TeacherStaffManagement />
          </ProtectedRoute>
          <Route path="/t/explore/:tab?">
            {newLibraryFeatureEnabled ? (
              <TeacherHomeLibrary key={window.location.search} />
            ) : (
              <Redirect to="/t/home" />
            )}
          </Route>
          <Route path="/t/home" exact={true}>
            {newLibraryFeatureEnabled ? (
              <Redirect to={{ ...location, pathname: "/t/explore" }} />
            ) : (
              <TeacherHome />
            )}
          </Route>
          <Route path="/t/profiles/:userCustomUrlCode" exact={false}>
            <TeacherProfile />
          </Route>
          <Route path="/t/classrooms" exact={false}>
            <TeacherClassrooms />
          </Route>
          <Route path="/t/my-library/sets/:id/preview">
            <TeacherPracticeSetPreviewSession />
          </Route>
          <Route path="/t/my-library/sets/:id" exact={false}>
            <TeacherPracticeSetDetail />
          </Route>
          <Route path="/t/my-library" exact={false}>
            <TeacherMyLibrary />
          </Route>

          {/* Backwards support for /t/practice-library -> /t/my-library */}
          <Route
            path="/t/practice-library/sets/:id/preview"
            exact={false}
            render={(props: RouteComponentProps<{ id: string }>) => (
              <Redirect
                to={`/t/my-library/sets/${props.match.params.id}/preview`}
              />
            )}
          />
          <Route
            path="/t/practice-library/sets/:id"
            exact={false}
            render={(props: RouteComponentProps<{ id: string }>) => (
              <Redirect to={`/t/my-library/sets/${props.match.params.id}`} />
            )}
          />
          <Route path="/t/practice-library" exact={false}>
            <Redirect to="/t/my-library" />
          </Route>

          <Route path="/t/my-school-library" exact={false}>
            <TeacherPublicLibrary />
          </Route>
          <Route path="/t/public-library" exact={false}>
            <TeacherPublicLibrary />
          </Route>
          <Route path="/t/student-session" exact={false}>
            <TeacherStudentSession />
          </Route>
          <Route path="/t/account/profile/settings" exact>
            <TeacherProfileSettings />
          </Route>
          <Route path="/t/account/notifications/settings" exact>
            <TeacherNotificationSettings />
          </Route>
          <Route path="/t/account" exact={false}>
            <TeacherAccount />
          </Route>
          <Route path="/t/my-avatar/:mode?" exact={false}>
            <TeacherMyAvatar />
          </Route>
          <Route path="*">
            <AppError code={404} />
          </Route>
        </Switch>
      </Box>
    </TeacherNavigationDataProvider>
  );
};

export default TeacherDashboard;
