import { Box, Flex, Image } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";

import { Button } from "adminComponents/atoms/Button";
import { Icon } from "adminComponents/atoms/Icon";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils/pxToRem";

interface IProps {
  imageAdded: string;
  imageAddedAltText?: string;
  audioAdded?: string;
  videoAdded?: string;
  label?: string;
  instructions?: string;
  buttonLabel?: string;
  handleRemoveMedia: () => void;
  handleAddMedia: () => void;
}

export const QuestionAddMedia: React.FC<IProps> = ({
  imageAdded,
  imageAddedAltText,
  audioAdded,
  videoAdded,
  label,
  instructions,
  buttonLabel,
  handleRemoveMedia,
  handleAddMedia,
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });

  return (
    <Box>
      <Box
        display="flex"
        flexDirection={["column", null, "row"]}
        alignItems={["flex-start", null, "center"]}
        gap={[pxToRem(16)]}
      >
        <Box>
          <Text variant="adminP2" marginBottom={pxToRem(8)}>
            {label || t("common.media")}
          </Text>
          <Text variant="adminP2" color="primary.dark-gray">
            {instructions || t("createQuestion.addMatchingMedia")}
          </Text>
        </Box>
        {!imageAdded && !audioAdded && !videoAdded && (
          <Box marginLeft={[0, null, "auto"]}>
            <Button
              variant="adminButtonOutlined"
              onClick={handleAddMedia}
              size="sm"
              leftIcon={<Icon icon="add" color="primary.warm-black" />}
            >
              {buttonLabel || t("createQuestion.addMedia")}
            </Button>
          </Box>
        )}
      </Box>
      {(imageAdded || audioAdded || videoAdded) && (
        <Box mt={pxToRem(16)}>
          {(imageAdded || videoAdded) && (
            <Flex
              backgroundColor="primary.white"
              borderColor="primary.medium-gray"
              cursor="pointer"
              w="full"
              h={[pxToRem(200), null, pxToRem(268)]}
              borderRadius={pxToRem(8)}
              borderWidth={pxToRem(1)}
              onClick={handleAddMedia}
              overflow="hidden"
              justifyContent="center"
            >
              {imageAdded && (
                <Image
                  src={imageAdded}
                  alt={imageAddedAltText}
                  height="full"
                  width="auto"
                />
              )}
              {videoAdded && (
                <ReactPlayer width="100%" url={videoAdded} controls={true} />
              )}
            </Flex>
          )}
          {audioAdded && (
            <ReactPlayer
              height={pxToRem(40)}
              width="100%"
              url={audioAdded}
              controls={true}
            />
          )}
          <Flex justifyContent="flex-end" mt={pxToRem(12)}>
            <Button
              variant="adminTextButtonMedium"
              onClick={(e) => {
                e.preventDefault();
                handleRemoveMedia();
              }}
            >
              {imageAdded
                ? t("addMediaModal.imageUpload.removeImage")
                : audioAdded
                ? t("createQuestion.removeAudio")
                : t("createQuestion.removeVideo")}
            </Button>
          </Flex>
        </Box>
      )}
    </Box>
  );
};
