import { UseQueryResult, useQuery } from "react-query";

import { IHATStats } from "links/lib/types";

import { useAxios } from "../../hooks/useAxios";

export interface IFetcHATStatsResponse {
  hat_stats: IHATStats;
}

export interface IFetchHATStatsParams {
  userId?: string;
  disabled?: boolean;
}

export default function useFetchHATStats(
  args: IFetchHATStatsParams
): UseQueryResult<IFetcHATStatsResponse> {
  const axios = useAxios();

  const { disabled, userId } = args;

  const query = useQuery<IFetcHATStatsResponse>(
    ["hat-stats", { userId }],
    async (): Promise<IFetcHATStatsResponse> => {
      return await axios
        .get(`/v1/users/${userId}/hat-stats`)
        .then((r) => r.data as IFetcHATStatsResponse);
    },
    {
      enabled: !disabled && !!userId,
      refetchOnWindowFocus: false,
    }
  );

  return query;
}
