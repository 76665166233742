import { IProps as StudentPracticeSetCardProps } from "adminComponents/molecules/StudentPracticeSetCard";
import { colorMapping } from "adminComponents/theme/colors";
import { pxToRem } from "adminComponents/utils";

interface IProps extends StudentPracticeSetCardProps {
  rgbBackgroundColor: string;
}

export const AdminStudentPracticeSetCard = {
  baseStyle: ({ rgbBackgroundColor }: IProps): Record<string, unknown> => ({
    container: {
      backgroundColor: "primary.white",
      paddingTop: pxToRem(3),
      paddingLeft: pxToRem(3),
      paddingRight: pxToRem(3),
      paddingBottom: pxToRem(3),
    },
    header: {
      bg: rgbBackgroundColor ? rgbBackgroundColor : "primary.golden-light",
      justifyContent: "flex-end",
      alignItems: "center",
      borderTopLeftRadius: pxToRem(15),
      borderTopRightRadius: pxToRem(15),
      paddingLeft: pxToRem(20),
      paddingRight: pxToRem(20),
      paddingTop: pxToRem(15),
      paddingBottom: pxToRem(15),
      zIndex: 1,
    },
    title: {
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: pxToRem(16),
    },
    iconContainer: {
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      top: pxToRem(10),
      left: pxToRem(30),
    },
    icon: {
      width: pxToRem(44),
      height: pxToRem(28),
    },
    content: {
      padding: pxToRem(30),
      paddingTop: pxToRem(32),
    },
    playButton: {
      position: "unset",
      paddingLeft: pxToRem(15),

      "> span > svg": {
        width: pxToRem(48),
        height: pxToRem(48),
      },

      _focusVisible: {
        _after: {
          boxShadow: `0 0 0 ${pxToRem(3)} inset ${
            colorMapping["utility.focus"]
          }`,
        },
      },

      _after: {
        content: "''",
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        borderRadius: pxToRem(20),
      },
    },
    xp: {
      marginLeft: pxToRem(8),
    },
  }),
};
