import React, { createContext, useContext, useMemo, useState } from "react";

export interface IRoundReviewContext {
  isSplashScreenVisible: boolean;
  setSplashScreenVisible: (isVisible: boolean) => void;
}

const initialValue: IRoundReviewContext = {
  isSplashScreenVisible: false,
  setSplashScreenVisible: () => {
    return;
  },
};

export const RoundReviewContext =
  createContext<IRoundReviewContext>(initialValue);

export const useRoundReview = (): IRoundReviewContext => {
  return useContext(RoundReviewContext);
};

export const RoundReviewProvider: React.FC = ({ children }) => {
  const [isSplashScreenVisible, setSplashScreenVisible] =
    useState<boolean>(false);

  const value = useMemo(() => {
    return {
      isSplashScreenVisible,
      setSplashScreenVisible,
    };
  }, [isSplashScreenVisible]);

  return (
    <RoundReviewContext.Provider value={value}>
      {children}
    </RoundReviewContext.Provider>
  );
};
