import { hoverStyles, pxToRem } from "adminComponents/utils";

export const AdminTabs = {
  baseStyle: {
    marginBottom: 0,
    tablistContainer: {
      overflow: "auto",
    },
    tablist: {
      paddingTop: pxToRem(2),
      borderBottom: `${pxToRem(2)} solid`,
      borderBottomColor: "primary.light-gray",
    },
    tabpanel: {
      padding: pxToRem(0),
    },
    tab: {
      fontSize: null,
      paddingLeft: 0,
      paddingRight: 0,
      borderBottomColor: "transparent",
      borderBottomWidth: pxToRem(4),
      marginBottom: pxToRem(-2),
      textStyle: ["adminH5", null, "adminH6"],
      color: "primary.warm-black",
      paddingBottom: [pxToRem(4), null, pxToRem(8)],
      marginLeft: [pxToRem(20), null, pxToRem(32)],
      _hover: hoverStyles({
        borderBottom: `${pxToRem(4)} solid`,
        borderBottomColor: "primary.medium-gray",
      }),
      _selected: {
        borderBottom: `${pxToRem(4)} solid`,
        borderBottomColor: "utility.link",
        textStyle: ["adminH5Bold", null, "adminH6Bold"],
        color: "primary.warm-black",
      },
      _focus: {
        boxShadow: "none",
      },

      _active: {
        bg: "transparent",
      },

      _focusVisible: {
        boxShadow: "none",
        position: "relative",
        _after: {
          content: "''",
          width: "110%",
          height: "100%",
          position: "absolute",
          outline: `solid ${pxToRem(2)}`,
          outlineColor: "utility.focus",
          borderRadius: pxToRem(4),
        },
      },
      _disabled: {
        opacity: 0.4,
        cursor: "not-allowed",
      },
    },
    checkmarkBox: {
      backgroundColor: "utility.success",
      borderRadius: "full",
      width: pxToRem(24),
      height: pxToRem(24),
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      marginRight: pxToRem(8),
    },
    checkmarkIcon: {
      width: pxToRem(14),
      height: pxToRem(14),
      display: "inline-flex",
    },
    premiumBox: {
      backgroundColor: "utility.premium",
      borderRadius: "full",
      width: pxToRem(20),
      height: pxToRem(20),
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      marginRight: pxToRem(8),
    },
    premiumIcon: {
      width: pxToRem(14),
      height: pxToRem(14),
      display: "inline-flex",
    },
  },
  variants: {
    adminFlyoutTabs: (props: {
      centerTabs: boolean;
    }): Record<string, unknown> => ({
      tab: {
        _first: {
          marginLeft: [pxToRem(15), null, pxToRem(60)],
        },
        whiteSpace: "nowrap",
      },
      tablist: {
        justifyContent: props.centerTabs ? "center" : "flex-start",
      },
    }),
    adminFlyoutSmallTabs: (): Record<string, unknown> => ({
      tab: {
        _first: {
          marginLeft: [pxToRem(15), null, pxToRem(60)],
        },
        _selected: {
          textStyle: "adminH7Bold",
        },
        textStyle: "adminH7",
        whiteSpace: "nowrap",
        tablist: {
          justifyContent: "flex-start",
        },
      },
    }),
    adminScreenTabs: {
      tablistContainer: {
        paddingLeft: pxToRem(8),
      },
      tablist: {
        justifyContent: ["initial", "space-evenly", "initial"],
      },
      tab: {
        whiteSpace: "nowrap",
        _first: {
          marginLeft: 0,
        },
      },
    },
    adminMathTab: {
      tablistContainer: {
        overflow: "visible",
      },
      tablist: {
        borderBottom: `${pxToRem(1)} solid`,
      },
      tabpanel: {
        pt: pxToRem(24),
      },
      tab: {
        textStyle: "adminH7",
        color: "primary.dark-gray",
        paddingBottom: [pxToRem(8)],
        marginLeft: [pxToRem(20)],
        _first: {
          marginLeft: [pxToRem(15)],
        },
        _focus: {
          boxShadow: "none",
        },
        _focusVisible: {
          boxShadow: "none",
          position: "relative",
          _after: {
            content: "''",
            width: "110%",
            height: "100%",
            position: "absolute",
            outline: `solid ${pxToRem(2)}`,
            outlineColor: "utility.focus",
            borderRadius: pxToRem(4),
          },
        },
      },
    },
    adminAddMediaModal: (): Record<string, unknown> => ({
      marginBottom: 0,
      tablistContainer: {
        // Prevent overflow in y direction
        paddingY: pxToRem(1),
      },
      tablist: {
        borderBottom: `${pxToRem(1)} solid`,
        borderBottomColor: "primary.light-gray",
      },
      tabpanel: {
        padding: pxToRem(0),
        pt: pxToRem(24),
      },
      tab: {
        fontSize: null,
        color: "primary.dark-gray",
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0,
        paddingBottom: [pxToRem(8)],
        marginLeft: [pxToRem(20)],
        _first: {
          marginLeft: [0],
        },
        _hover: hoverStyles({
          borderBottom: `${pxToRem(4)} solid`,
          borderBottomColor: "primary.medium-gray",
        }),
        _selected: {
          borderBottom: `${pxToRem(4)} solid`,
          borderBottomColor: "utility.link",
          color: "primary.warm-black",
        },
      },
    }),
  },
};
