import { Box, Flex, Grid } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Card } from "adminComponents/atoms/Card";
import { Divider } from "adminComponents/atoms/Divider";
import { Heading } from "adminComponents/atoms/Heading";
import { Icon } from "adminComponents/atoms/Icon";
import { MyStuffCard } from "adminComponents/atoms/MyStuffCard";
import { Text } from "adminComponents/atoms/Text";
import { FullStudentAvatar } from "adminComponents/molecules/FullStudentAvatar";
import { GoalsProgressBar } from "adminComponents/molecules/GoalsProgressBar";
import { StudentTemplateWithTwoColumns } from "adminComponents/templates/StudentTemplateWithTwoColumns";
import { generateStudentName } from "adminComponents/utils";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { IAvatar, IMyStuffItem, IUser } from "links/lib/types";

export interface IProps {
  nav: React.ReactNode;
  student: IUser;
  totalXP: number;
  daysLeft?: number;
  currentXP: number;
  nextLevelXP: number;
  avatar?: IAvatar;
  myStuff: Array<IMyStuffItem>;
  studentAvatarStage: React.ReactNode;
  isLoading: boolean;
  showViewMoreStuffButton: boolean;
  handleStudentAvatarEdit: () => void;
  handleStudentAvatarShare: () => void;
  handleViewMore: () => void;
  handleMyStuffCardClick: (myStuffItem: IMyStuffItem) => void;
}

export const StudentLockerScreen: React.FC<IProps> = ({
  nav,
  student,
  totalXP,
  daysLeft,
  currentXP,
  nextLevelXP,
  avatar,
  myStuff,
  studentAvatarStage,
  isLoading,
  showViewMoreStuffButton,
  handleStudentAvatarEdit,
  handleStudentAvatarShare,
  handleViewMore,
  handleMyStuffCardClick,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const studentName = generateStudentName(student).primary;
  const remainingXP = nextLevelXP - currentXP;
  const isSeasonComplete = remainingXP < 0;

  const renderMobileShiftedLeftContent = () => {
    return (
      <Flex align="center" direction="column">
        <Flex
          maxWidth={{ base: pxToRem(255), md: "100%" }}
          maxHeight={{ base: pxToRem(284), md: pxToRem(512) }}
          width="100%"
        >
          <FullStudentAvatar
            handleEdit={handleStudentAvatarEdit}
            handleShare={handleStudentAvatarShare}
          >
            {studentAvatarStage}
          </FullStudentAvatar>
        </Flex>
      </Flex>
    );
  };

  const renderRegularLeftContent = () => {
    return (
      <Flex
        direction="column"
        align="center"
        mt={{ base: pxToRem(16), md: pxToRem(24) }}
      >
        <Card
          variant="adminCardSmallBorderSmallPadding"
          borderColor="primary.tan"
          backgroundColor="primary.tan"
        >
          <Text variant="adminP2">
            {t("studentLockerScreen.avatarDescription")}
          </Text>
        </Card>
        <Heading
          variant="adminH4"
          as="h1"
          marginTop={{ base: pxToRem(16), md: pxToRem(24) }}
          marginBottom={{ base: pxToRem(2), md: pxToRem(8) }}
        >
          {studentName}
        </Heading>
        <Text variant="adminP2">
          {t("studentLockerScreen.totalXp", { val: totalXP })}
        </Text>
      </Flex>
    );
  };

  const renderRightContent = () => {
    return (
      <Box>
        {!isSeasonComplete ? (
          <>
            <Divider
              display={{ md: "none" }}
              color="primary.tan"
              marginBottom={pxToRem(24)}
            />
            <GoalsProgressBar
              challenge={{
                title: t("studentLockerScreen.seasonChallengeTitle"),
                description: t(
                  "studentLockerScreen.seasonChallengeDescription",
                  {
                    remainingXP,
                  }
                ),
                xp_threshold: nextLevelXP,
                awarded_xp: currentXP,
              }}
              hasIcon
              variant="adminSeasonProgress"
              remainingDays={daysLeft}
            />
            <Divider
              color="primary.tan"
              marginTop={{ base: pxToRem(24), md: pxToRem(40) }}
              marginBottom={{ base: pxToRem(20), md: pxToRem(32) }}
            />
          </>
        ) : null}
        <Heading variant="adminH6Bold" as="h2">
          {t("studentLockerScreen.myStuffHeading")}
        </Heading>
        <Text
          variant="adminP2"
          marginTop={{ base: pxToRem(12), md: pxToRem(16) }}
          marginBottom={pxToRem(24)}
        >
          {t("studentLockerScreen.howToEarnItems")}
        </Text>
        <Grid
          as="ul"
          templateColumns="1fr 1fr 1fr"
          rowGap={{ base: pxToRem(16), md: pxToRem(32) }}
          columnGap={{ base: pxToRem(15), lg: pxToRem(28) }}
        >
          {avatar &&
            myStuff.map((item) => {
              return (
                <Flex
                  as="li"
                  key={item.avatar_item.id}
                  listStyleType="none"
                  direction="column"
                  align="center"
                  gap={pxToRem(12)}
                >
                  <MyStuffCard
                    avatar={avatar}
                    myStuffItem={item}
                    handleClick={() => handleMyStuffCardClick(item)}
                  ></MyStuffCard>
                  <Text variant="adminP2">{item.avatar_item.name}</Text>
                </Flex>
              );
            })}
          {showViewMoreStuffButton && (
            <Flex
              justifyContent="center"
              gridColumn="span 3"
              marginTop={pxToRem(8)}
            >
              <Button
                onClick={handleViewMore}
                variant="adminButtonOutlined"
                size="lg"
                rightIcon={
                  <Icon
                    height={pxToRem(7)}
                    icon="button_down_arrow"
                    width={pxToRem(12)}
                    color="currentColor"
                  />
                }
              >
                {t("common.viewMore")}
              </Button>
            </Flex>
          )}
        </Grid>
      </Box>
    );
  };

  return (
    <StudentTemplateWithTwoColumns
      nav={nav}
      leftColumnContent={renderRegularLeftContent()}
      rightColumnContent={renderRightContent()}
      leftColumnShiftedContent={renderMobileShiftedLeftContent()}
      isLeftColumnSticky
      isLoading={isLoading}
    />
  );
};
