import { useInterval } from "@chakra-ui/react";
import moment from "moment";
import { useState } from "react";

import { UserXPMultiplier } from "links/lib/types";

export interface IUseBoostTimerArgs {
  userXPMultiplier: UserXPMultiplier;
  onEnd?: () => void;
}

export interface IUseBoostTimerResult {
  secondsRemaining: number;
  totalMinutes: number;
}

export const useBoostTimer = ({
  userXPMultiplier,
  onEnd,
}: IUseBoostTimerArgs): IUseBoostTimerResult => {
  const startsAt = moment(userXPMultiplier.starts_at);
  const endsAt = moment(userXPMultiplier.ends_at);

  const getTotalMinutes = (): number => {
    return endsAt.diff(startsAt, "minutes");
  };

  const getSecondsRemaining = (): number => {
    return endsAt.diff(moment(), "seconds");
  };

  const [secondsRemaining, setSecondsRemaining] = useState(
    getSecondsRemaining()
  );

  const totalMinutes = getTotalMinutes();

  useInterval(() => {
    if (secondsRemaining <= 0) return;

    const seconds = getSecondsRemaining();
    setSecondsRemaining(Math.max(seconds, 0));

    if (seconds <= 0) {
      onEnd?.();
    }
  }, 1000);

  return {
    secondsRemaining: secondsRemaining,
    totalMinutes,
  };
};
