import { Flex, FormControl, SimpleGrid } from "@chakra-ui/react";
import React, { useEffect, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Editor } from "slate";

import { Button } from "adminComponents/atoms/Button";
import { FormLabel } from "adminComponents/atoms/FormLabel";
import { Icon } from "adminComponents/atoms/Icon";
import { PremiumIcon } from "adminComponents/atoms/PremiumIcon";
import { Tag } from "adminComponents/atoms/Tag";
import { Text } from "adminComponents/atoms/Text";
import { useEditPracticeSetMediaModalContext } from "adminComponents/contexts/EditPracticeSetMediaModalContext";
import { CreateQuestionFooter } from "adminComponents/molecules/CreateQuestionFooter";
import { QuestionAddMedia } from "adminComponents/molecules/QuestionAddMedia";
import { AddMediaModal } from "adminComponents/organisms/AddMediaModal";
import { RichTextEditor } from "adminComponents/organisms/RichTextEditor";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { RichValue } from "links/lib/types";
import { generateEmptySlateState } from "links/lib/util";

import { GeneratingModal } from "../GeneratingModal";

export interface CreateFeedbackTabForm {
  imageUrl: string;
  imageAltText: string;
  feedback: RichValue;
}

const initialValue: CreateFeedbackTabForm = {
  feedback: generateEmptySlateState(),
  imageAltText: "",
  imageUrl: "",
};

interface CreateQuestionFeedbackTabProps {
  defaultValue?: CreateFeedbackTabForm;
  canSubmit: boolean;
  isSubmitting: boolean;
  hasStandards: boolean;
  handleSaveAndClose: (data: CreateFeedbackTabForm) => void;
  handleChangeForm: (data: CreateFeedbackTabForm) => void;
  handleNextTab: () => void;
  handleDirty?: () => void;
  handleGenerate?: () => void;
  isGenerating?: boolean;
  showGenerate?: boolean;
  generatedFeedback?: RichValue;
}

export const CreateQuestionFeedbackTab = React.forwardRef<
  HTMLDivElement,
  CreateQuestionFeedbackTabProps
>(
  (
    {
      handleSaveAndClose,
      handleNextTab,
      handleChangeForm,
      defaultValue,
      hasStandards,
      canSubmit,
      isSubmitting,
      handleDirty,
      handleGenerate,
      isGenerating = false,
      showGenerate = false,
      generatedFeedback,
    },
    ref
  ) => {
    const { t } = useTranslation("admin", { useSuspense: false });
    const mediaModalProps = useEditPracticeSetMediaModalContext();
    const editorRef = useRef<Editor | undefined>();

    const {
      handleSubmit,
      setValue,
      watch,
      control,
      getValues,
      formState: { isDirty },
    } = useForm<CreateFeedbackTabForm>({
      defaultValues: defaultValue ?? initialValue,
      mode: "onChange",
    });

    // update feedback text if it has been generated
    useEffect(() => {
      if (generatedFeedback) {
        setValue("feedback", generatedFeedback);

        if (editorRef.current) {
          editorRef.current.children = generatedFeedback;
          editorRef.current.onChange();
        }
      }
    }, [generatedFeedback, setValue]);

    useEffect(() => {
      if (isDirty && handleDirty) handleDirty();
    }, [isDirty, handleDirty]);

    watch(() => {
      handleChangeForm(getValues());
    });

    const [imageUrl, imageAltText] = watch(["imageUrl", "imageAltText"]);

    const _handleSubmit = (data: CreateFeedbackTabForm) => {
      handleSaveAndClose(data);
    };

    const handleInsertMediaFormWrapper = (
      imageUrl: string,
      imageAltText: string,
      audioUrl?: string,
      videoUrl?: string
    ) => {
      setValue("imageUrl", imageUrl);
      setValue("imageAltText", imageAltText);

      mediaModalProps.handleInsertMedia(
        imageUrl,
        imageAltText,
        audioUrl,
        videoUrl
      );
    };

    const handleRemoveImageFormWrapper = () => {
      setValue("imageUrl", "");
      setValue("imageAltText", "");
      mediaModalProps.handleRemoveImage();
    };

    return (
      <form onSubmit={handleSubmit(_handleSubmit)}>
        <SimpleGrid
          ref={ref}
          py={pxToRem(40)}
          px={[
            "admin.flyout.mobileXPadding",
            null,
            "admin.flyout.desktopXPadding",
          ]}
          gap={pxToRem(40)}
        >
          <Text
            as="h4"
            variant="adminP1"
            paddingBottom={[pxToRem(23), null, pxToRem(32)]}
            borderColor="primary.medium-gray"
            borderBottomWidth={pxToRem(1)}
          >
            {t("createQuestion.feedbackTitle")}
          </Text>
          <FormControl minWidth={0} variant="adminFormControl">
            <FormLabel>{t("createQuestion.labelFeedback")}</FormLabel>
            <Controller
              control={control}
              name="feedback"
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <RichTextEditor
                  editorRef={editorRef}
                  ref={ref}
                  name={name}
                  handleChangeRaw={onChange}
                  handleBlur={onBlur}
                  value={value}
                  placeholder={t("createQuestion.placeholderDescription")}
                />
              )}
            />
            {showGenerate && (
              <>
                <Button
                  marginTop={pxToRem(10)}
                  variant="adminButtonOutlined"
                  onClick={handleGenerate}
                  isLoading={isGenerating}
                  w="100%"
                >
                  <Flex
                    justifyContent="center"
                    w="100%"
                    alignItems="center"
                    gap={pxToRem(10)}
                  >
                    <Icon icon="zap" />
                    {t("createQuestion.feedbackGenerate")}
                    <PremiumIcon />
                  </Flex>
                </Button>
                <Flex
                  p={pxToRem(10)}
                  alignItems="center"
                  justifyContent="center"
                  gap={pxToRem(10)}
                >
                  <Text color="primary.dark-gray" variant="adminP3">
                    {t("createQuestion.feedbackGeneratedByAI")}
                  </Text>
                  <Tag colorScheme="blue">{t("common.beta")}</Tag>
                </Flex>
              </>
            )}
          </FormControl>
          <QuestionAddMedia
            handleAddMedia={mediaModalProps.handleOpen}
            handleRemoveMedia={handleRemoveImageFormWrapper}
            imageAdded={imageUrl}
            imageAddedAltText={imageAltText}
          />
          <CreateQuestionFooter
            canSubmit={canSubmit}
            isSubmitting={isSubmitting}
            hideHigherOrderThinking
            handleNextTab={handleNextTab}
            handleSaveAndClose={handleSubmit(_handleSubmit)}
            nextSectionTextCopies={{
              nextSectionCta: hasStandards
                ? t("createQuestion.editStandards")
                : t("createQuestion.addStandards"),
              nextSectionTitle: t("createQuestion.standards"),
              nextSectionDescription: t("createQuestion.standardsDescription"),
              nextSectionIcon: hasStandards ? "edit_outlined" : "add",
            }}
            backgroundColor="primary.warm-white"
          />
          <AddMediaModal
            {...mediaModalProps}
            imageUrlToAdd={imageUrl}
            imageAltText={imageAltText}
            handleInsertMedia={handleInsertMediaFormWrapper}
            imageOnly
          />
        </SimpleGrid>
        <GeneratingModal
          isOpen={isGenerating}
          labelHeading={t("createQuestion.feedbackGeneratingHeading")}
          labelSubHeading={t("createQuestion.feedbackGeneratingSubHeading")}
        />
      </form>
    );
  }
);

CreateQuestionFeedbackTab.displayName = "CreateQuestionFeedbackTab";
