import { pxToRem } from "adminComponents/utils";

export const AdminAvatarPhotoBackgroundCard = {
  baseStyle: (): Record<string, unknown> => ({
    card: {
      borderRadius: {
        base: pxToRem(8),
        lg: pxToRem(12),
      },
      cursor: "pointer",
      height: {
        base: pxToRem(75),
        lg: pxToRem(135),
      },
      width: {
        base: pxToRem(75),
        lg: pxToRem(135),
      },
    },
  }),
};
