import { Box, Divider, Flex } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Heading } from "adminComponents/atoms/Heading";
import { Icon } from "adminComponents/atoms/Icon";
import {
  AssignmentCard,
  IProps as IAssignmentCardProps,
} from "adminComponents/molecules/AssignmentCard";
import { EmptyCard } from "adminComponents/molecules/EmptyCard";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { IAssignment } from "links/lib/types";

export interface IAssignmentProps {
  accuracy?: number;
  assignment: IAssignment;
  attempts?: number;
}

interface IProps {
  currentAssignmentsData?: IAssignmentCardProps[];
  handleDeleteAssignmentCard: (assignment: IAssignment) => void;
  handleEditAssignmentCard: (assignment: IAssignment) => void;
  handleViewMoreAssignments: () => void;
  pastAssignmentsData?: IAssignmentCardProps[];
  showViewMoreAssignments?: boolean;
  studentId: string;
}

export const AssignmentsTab: React.FC<IProps> = ({
  currentAssignmentsData,
  handleDeleteAssignmentCard,
  handleEditAssignmentCard,
  handleViewMoreAssignments,
  pastAssignmentsData,
  showViewMoreAssignments,
  studentId,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  return (
    <Box mt={pxToRem(40)}>
      {!currentAssignmentsData && !pastAssignmentsData ? (
        <EmptyCard
          text={t("studentDetailEmptyCards.assignmentsText")}
          title={t("studentDetailEmptyCards.assignmentsTitle")}
        />
      ) : (
        <>
          <Box>
            <Heading as="h2" variant="adminH3" mb={pxToRem(32)}>
              {t("studentDetailScreen.currentAssignmentsLabel")}
            </Heading>
            {currentAssignmentsData ? (
              currentAssignmentsData.map((assignmentCardData, i) => {
                const { assignment } = assignmentCardData;

                return (
                  <Box
                    key={i}
                    mb={pxToRem(12)}
                    gridTemplateColumns={`"3fr minmax("${pxToRem(200)}", 1fr)"`}
                  >
                    <AssignmentCard
                      {...assignmentCardData}
                      accuracyType="student"
                      assignment={assignment}
                      handleDelete={handleDeleteAssignmentCard}
                      handleEdit={handleEditAssignmentCard}
                      studentId={studentId}
                    />
                  </Box>
                );
              })
            ) : (
              <EmptyCard
                text={t("studentDetailEmptyCards.noActiceAssignments")}
              />
            )}
          </Box>
          {pastAssignmentsData && (
            <>
              <Divider
                borderBottomWidth={pxToRem(2)}
                colorScheme="primary.tan"
                mb={pxToRem(56)}
                mt={pxToRem(56)}
                opacity="1"
              />
              <Box>
                <Heading as="h2" variant="adminH3" mb={pxToRem(32)}>
                  {t("studentDetailScreen.pastAssignmentsLabel")}
                </Heading>
                {pastAssignmentsData.map((assignmentCardData, i) => {
                  const { assignment } = assignmentCardData;

                  return (
                    <Box key={i} mb={pxToRem(12)}>
                      <AssignmentCard
                        {...assignmentCardData}
                        accuracyType="student"
                        assignment={assignment}
                        handleDelete={handleDeleteAssignmentCard}
                        handleEdit={handleEditAssignmentCard}
                        key={i}
                        studentId={studentId}
                      />
                    </Box>
                  );
                })}
              </Box>
              {showViewMoreAssignments && (
                <Flex mt={pxToRem(32)} justifyContent="center">
                  <Button
                    onClick={handleViewMoreAssignments}
                    variant="adminTextButtonLarge"
                    rightIcon={
                      <Icon
                        ml={pxToRem(11)}
                        width={pxToRem(12)}
                        height={pxToRem(7)}
                        icon="button_down_arrow"
                        iconColor="utility.link"
                      />
                    }
                  >
                    {t("classDetailTabs.ctaViewMore")}
                  </Button>
                </Flex>
              )}
            </>
          )}
        </>
      )}
    </Box>
  );
};
