import { Box } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useMeasure } from "react-use";

import { Heading } from "adminComponents/atoms/Heading";
import { pxToRem } from "adminComponents/utils";

import { ReactComponent as StandardsAlignedSVG } from "./resource/standards-aligned-notation.svg";

const smallestSupportedScreenWidth = 430;

export const StandardsAlignedTitle: React.FC = () => {
  const { t } = useTranslation("admin", { useSuspense: false });

  const [headingRef, { width: headingWidth }] = useMeasure<HTMLDivElement>();
  const [standardsRef, { height: standardsHeight, width: standardsWidth }] =
    useMeasure<HTMLDivElement>();

  const standardsSizing = useMemo(() => {
    return {
      left: standardsWidth * 0.45,
      top: standardsHeight * 0.8,
    };
  }, [standardsHeight, standardsWidth]);

  return (
    <Box ref={headingRef}>
      <Heading variant="adminH3" as="h1">
        {t("teacherHomepageLibrary.titlePart1")}
        <Box as="span">
          {window.innerWidth >= smallestSupportedScreenWidth && (
            <Box as="span" position="relative">
              <Box
                ref={standardsRef}
                w={pxToRem(headingWidth * 0.4)}
                position="absolute"
                top={-standardsSizing.top}
                left={-standardsSizing.left}
                aria-label={t("teacherHomepageLibrary.standardsAligned")}
                aria-role="img"
                role="img"
              >
                <StandardsAlignedSVG />
              </Box>
            </Box>
          )}
        </Box>{" "}
        {t("teacherHomepageLibrary.titlePart2")}
      </Heading>
    </Box>
  );
};
