import {
  Box,
  Flex,
  Tooltip,
  Wrap,
  WrapItem,
  useBreakpointValue,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Card } from "adminComponents/atoms/Card";
import { CertifiedText } from "adminComponents/atoms/CertifiedText";
import { Divider } from "adminComponents/atoms/Divider";
import { ExpandableText } from "adminComponents/atoms/ExpandableText";
import { ExplainableText } from "adminComponents/atoms/ExplainableText";
import { Heading } from "adminComponents/atoms/Heading";
import { Icon } from "adminComponents/atoms/Icon";
import { IconButtonWithTooltip } from "adminComponents/atoms/IconButtonWithTooltip";
import { PremiumText } from "adminComponents/atoms/PremiumText";
import { PremiumTooltipRich } from "adminComponents/atoms/PremiumTooltipRich";
import { PrivacyTag } from "adminComponents/atoms/PrivacyTag";
import { Tag } from "adminComponents/atoms/Tag";
import { Text } from "adminComponents/atoms/Text";
import { TextLink } from "adminComponents/atoms/TextLink";
import { VerticalTextDivider } from "adminComponents/atoms/VerticalTextDivider";
import CoverImageBlob from "adminComponents/molecules/CoverImageBlob";
import {
  IconButtonDropdown,
  MenuItemDetails as IconMenuItemDetails,
} from "adminComponents/molecules/IconButtonDropdown";
import { isPremiumItem } from "adminComponents/utils/isPremiumItem";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { useFetchPracticeSetItems } from "links/lib/features/practiceSetItems";
import { IPracticeSet, IUser, UserRole } from "links/lib/types";

import { getPracticeSetUrl } from "../LibraryPracticeSetCard";

export interface IProps {
  authUser?: IUser;
  hasNoPremiumAccess: boolean;
  practiceSet: IPracticeSet;
  handleEdit: (practiceSet: IPracticeSet) => void;
  handlePreview: (practiceSet: IPracticeSet) => void;
  handleDelete: (practiceSet: IPracticeSet) => void;
  handleDuplicate: (practiceSet: IPracticeSet) => void;
  handleLiveSession?: (practiceSet: IPracticeSet) => void;
  handleCreateAssignment?: (practiceSet: IPracticeSet) => void;
  hideButtons?: boolean;
  disableButtons?: boolean;
  originalPracticeSet?: IPracticeSet;
  originalAuthorName?: string;
  allowEdit?: boolean;
  showPremiumMarker?: boolean;
}

export const PracticeSetInformationCard: React.FC<IProps> = ({
  authUser,
  hasNoPremiumAccess,
  handleEdit,
  handlePreview,
  handleDuplicate,
  handleDelete,
  handleLiveSession = () => undefined,
  handleCreateAssignment = () => undefined,
  practiceSet,
  hideButtons,
  disableButtons,
  originalPracticeSet,
  originalAuthorName,
  allowEdit = true,
  showPremiumMarker = true,
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });

  const showCoverImage =
    practiceSet.cover_image_bg_color_scheme &&
    practiceSet.cover_image_icon &&
    practiceSet.cover_image_bg_pattern;

  const coverImageSize = useBreakpointValue({ base: 100, md: 140 });

  const showCoverImageSize = showCoverImage ? coverImageSize || 140 : 0;

  const reservePixelsLeft = useBreakpointValue({
    base: 0,
    md: showCoverImageSize,
  });

  const menuItems: IconMenuItemDetails[] = [
    {
      label: t("common.duplicate"),
      handleClick: () => handleDuplicate(practiceSet),
    },
    { label: t("common.delete"), handleClick: () => handleDelete(practiceSet) },
  ];

  let originalPracticeSetPath;
  if (originalPracticeSet) {
    originalPracticeSetPath = getPracticeSetUrl(practiceSet, authUser);
  }

  const practiceSetItemFetch = useFetchPracticeSetItems({
    practice_set_id: practiceSet.id,
  });

  const allItemsArePremium = useMemo(() => {
    const practiceSetItems =
      practiceSetItemFetch.data?.practice_set_items || [];
    return practiceSetItems.length > 0 && practiceSetItems.every(isPremiumItem);
  }, [practiceSetItemFetch.data?.practice_set_items]);
  const premiumActionsDisallowed =
    hasNoPremiumAccess && (practiceSet.is_premium || allItemsArePremium);

  const styles = useMultiStyleConfig("AdminPracticeSetInformationCardBody", {});

  const TooltipWrap = (button: JSX.Element, disabledTooltipText: string) => {
    return premiumActionsDisallowed ? (
      <PremiumTooltipRich isDisabled={!premiumActionsDisallowed}>
        {button}
      </PremiumTooltipRich>
    ) : (
      <Tooltip
        isDisabled={!disableButtons}
        hasArrow
        placement="bottom"
        label={disabledTooltipText}
      >
        {button}
      </Tooltip>
    );
  };

  return (
    <Card
      borderColor="primary.tan"
      backgroundColor="primary.white"
      variant="adminCardThickBorder"
      noPadding
    >
      <Box __css={styles.container}>
        {/* Header (Menu buttons) */}
        <Box __css={styles.header}>
          {allowEdit && (
            <IconButtonWithTooltip
              shape="type2"
              icon="edit_outlined"
              onClick={() => handleEdit(practiceSet)}
              ariaLabel={t("common.edit")}
              tooltipProps={{ popoverVariant: "default" }}
            />
          )}
          <IconButtonDropdown icon="more_horiz" menuItems={menuItems} />
        </Box>
        {/* Body (Cover Image, Heading, Description) */}
        <Box __css={styles.body}>
          {showCoverImage && (
            <Box __css={styles.coverImageContainer}>
              <CoverImageBlob
                data-role="coverImage"
                backgroundColorScheme={practiceSet.cover_image_bg_color_scheme}
                backgroundPattern={practiceSet.cover_image_bg_pattern}
                icon={practiceSet.cover_image_icon}
                boxSize={showCoverImageSize}
              />
            </Box>
          )}
          <Box w={`calc(100% - ${reservePixelsLeft}px)`}>
            {practiceSet.title && (
              <Heading
                as="h1"
                variant="adminH2"
                noOfLines={{ base: 4, md: 3 }}
                wordBreak="normal"
                overflowWrap="anywhere"
              >
                {practiceSet.title}
              </Heading>
            )}
            {practiceSet.description && (
              <ExpandableText
                noOfLines={{ base: 3, md: 2 }}
                textVariant="adminP1"
                color="primary.warm-black"
                pt={pxToRem(12)}
                wordBreak="normal"
                overflowWrap="anywhere"
              >
                {practiceSet.description}
              </ExpandableText>
            )}
            {(!!originalPracticeSetPath ||
              practiceSet.is_certified ||
              practiceSet.is_premium) && (
              <Flex
                sx={styles.practiceSetMeta}
                gap={[pxToRem(20), null, pxToRem(30)]}
              >
                {practiceSet.is_certified && <CertifiedText type="set" />}
                {practiceSet.is_certified && <ExplainableText />}
                {showPremiumMarker && practiceSet.is_premium && (
                  <PremiumText type="set" />
                )}
                {!!originalPracticeSet && practiceSet.is_certified && (
                  <VerticalTextDivider />
                )}
                {!!originalPracticeSet && (
                  <Text variant="adminP1" color="primary.dark-gray">
                    <Trans
                      i18nKey="practiceSetInformationCard.copiedFrom"
                      t={t}
                    >
                      Copied from
                      <TextLink
                        size="lg"
                        navigationLink={{
                          label: originalPracticeSet?.title || "",
                          to: originalPracticeSetPath ?? "",
                        }}
                      >
                        {{ practiceSetTitle: originalPracticeSet?.title }}
                      </TextLink>
                      by {{ originalAuthorName }}
                    </Trans>
                  </Text>
                )}
              </Flex>
            )}
            {!hideButtons && (
              <Flex __css={styles.buttonWrapper}>
                {TooltipWrap(
                  <Box as="span" sx={styles.button}>
                    <Button
                      size="lg"
                      variant="adminButtonFilled"
                      onClick={() => handleLiveSession(practiceSet)}
                      isDisabled={disableButtons || premiumActionsDisallowed}
                      leftIcon={
                        premiumActionsDisallowed ? (
                          <Icon w={pxToRem(24)} h={pxToRem(24)} icon="lock" />
                        ) : (
                          <Icon
                            w={pxToRem(24)}
                            h={pxToRem(24)}
                            icon="play_outlined"
                          />
                        )
                      }
                    >
                      <Flex
                        display="flex"
                        w="full"
                        justifyContent="center"
                        alignItems="center"
                        gap={pxToRem(8)}
                      >
                        {t("practiceSetInformationCard.labelStartLiveSession")}
                      </Flex>
                    </Button>
                  </Box>,
                  t(
                    "practiceSetInformationCard.disabledStartLiveSessionTooltip"
                  )
                )}
                {TooltipWrap(
                  <Box as="span" sx={styles.button}>
                    <Button
                      size="lg"
                      variant="adminButtonOutlined"
                      onClick={() => handleCreateAssignment(practiceSet)}
                      isDisabled={disableButtons || premiumActionsDisallowed}
                      leftIcon={
                        premiumActionsDisallowed ? (
                          <Icon w={pxToRem(24)} h={pxToRem(24)} icon="lock" />
                        ) : (
                          <Icon
                            w={pxToRem(24)}
                            h={pxToRem(24)}
                            icon="person_add_outlined"
                          />
                        )
                      }
                    >
                      <Flex
                        display="flex"
                        w="full"
                        justifyContent="center"
                        alignItems="center"
                        gap={pxToRem(8)}
                      >
                        {t("practiceSetInformationCard.labelCreateAssignment")}
                      </Flex>
                    </Button>
                  </Box>,
                  t(
                    "practiceSetInformationCard.disabledCreateAssignmentTooltip"
                  )
                )}
                {TooltipWrap(
                  <Box as="span" sx={styles.button}>
                    <Button
                      size="lg"
                      variant="adminButtonOutlined"
                      onClick={() => handlePreview(practiceSet)}
                      isDisabled={disableButtons || premiumActionsDisallowed}
                      leftIcon={
                        premiumActionsDisallowed ? (
                          <Icon w={pxToRem(24)} h={pxToRem(24)} icon="lock" />
                        ) : (
                          <Icon
                            w={pxToRem(24)}
                            h={pxToRem(24)}
                            icon="search_preview"
                          />
                        )
                      }
                    >
                      <Flex
                        display="flex"
                        w="full"
                        justifyContent="center"
                        alignItems="center"
                        gap={pxToRem(8)}
                      >
                        {t("common.preview")}
                      </Flex>
                    </Button>
                  </Box>,
                  t("practiceSetInformationCard.disabledPreviewTooltip")
                )}
              </Flex>
            )}
          </Box>
        </Box>
        {/* Divider */}
        <Box __css={styles.divider}>
          <Divider color="primary.light-gray" />
        </Box>
        {/* Footer (Tags) */}
        <Box __css={styles.footer}>
          {/* Left tags */}
          <Wrap __css={styles.leftTags}>
            <WrapItem>
              <PrivacyTag availability={practiceSet.availability} />
            </WrapItem>
            {authUser?.role === UserRole.ContentSpecialist && (
              <>
                {practiceSet.cnc_code && (
                  <WrapItem>
                    <Tag variant="adminSolid">
                      <Text as="span" fontWeight="bold">
                        Code:
                      </Text>{" "}
                      {practiceSet.cnc_code}
                    </Tag>
                  </WrapItem>
                )}
                {practiceSet.cnc_family_code && (
                  <WrapItem>
                    <Tag variant="adminSolid">
                      <Text as="span" fontWeight="bold">
                        Family Code:
                      </Text>{" "}
                      {practiceSet.cnc_family_code}
                    </Tag>
                  </WrapItem>
                )}
              </>
            )}
          </Wrap>
          {/* Right tags */}
          <Wrap>
            {practiceSet.subjects
              .filter((s) => s.subject)
              .map((s) => {
                return (
                  <WrapItem key={s.id}>
                    <Tag variant="adminSolid">
                      {s.subject.parent_name
                        ? `${s.subject.parent_name}: ${s.subject.name}`
                        : s.subject.name}
                    </Tag>
                  </WrapItem>
                );
              })}
            {practiceSet.grade_levels &&
              practiceSet.grade_levels
                .filter((g) => g.grade_level)
                .sort((a, b) => {
                  const [aId, bId] = [
                    parseInt(a.grade_level.id, 10),
                    parseInt(b.grade_level.id, 10),
                  ];
                  return aId > bId ? 1 : aId < bId ? -1 : 0;
                })
                .map((g) => {
                  return (
                    <WrapItem key={g.id}>
                      <Tag variant="adminSolid">
                        {g.grade_level.grade_level}
                      </Tag>
                    </WrapItem>
                  );
                })}
          </Wrap>
        </Box>
      </Box>
    </Card>
  );
};
