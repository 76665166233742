import { Box } from "@chakra-ui/react";
import React, { useMemo } from "react";

import { pxToRem } from "adminComponents/utils";
import { useSessionConnectedUsers } from "links/lib/contexts/sessionConnectedUsers";
import { useSessionRoundState } from "links/lib/contexts/sessionRoundState";
import { PracticeSessionItemVariantType } from "links/lib/types";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { ResponseOption } from "sessionComponents/hooks/useItemResponseOptions";
import { TeacherReviewOption } from "sessionComponents/molecules/TeacherReviewOption";
import { getCorrectChoiceIds } from "sessionComponents/utils/getCorrectChoiceIds";
import { getImageResponseStyleProps } from "sessionComponents/utils/layoutUtils";
import { useSafeRoundGroupStateArray } from "sharedComponents/hooks/useSafeRoundGroupStateArray";

interface ITeacherMultipleChoiceReviewOptionsProps {
  studentTotal: number;
  answerOptions: ResponseOption[];
}

export const TeacherMultipleChoiceReviewOptions: React.FC<
  ITeacherMultipleChoiceReviewOptionsProps
> = ({ studentTotal, answerOptions }) => {
  const {
    match: { padding, margin },
  } = useBreakpoints();
  const connectedUsers = useSessionConnectedUsers();
  const roundState = useSessionRoundState();
  const roundGroupsState = useSafeRoundGroupStateArray();

  const { variant, variant_data } = roundState;

  const correctChoiceIds = getCorrectChoiceIds(variant, variant_data);

  const allGroupsChoices = useMemo(() => {
    return roundGroupsState.flatMap((groupState) => {
      return variant === PracticeSessionItemVariantType.CoopRadios
        ? groupState.radios?.selected_choices || []
        : groupState.checks?.selected_choices || [];
    });
  }, [roundGroupsState, variant]);

  const isImageResponse = useMemo(
    () => answerOptions.some((opt) => !!opt.image_url),
    [answerOptions]
  );

  const containerStyle = isImageResponse
    ? getImageResponseStyleProps(isImageResponse, padding)
    : { display: "flex", flexDirection: "column", gap: pxToRem(margin / 2) };

  const answerCounts = useMemo(() => {
    return answerOptions.map(
      (option) =>
        allGroupsChoices.filter(
          (choice) =>
            choice.choice_id === option.id && !!connectedUsers[choice.user_id]
        ).length
    );
  }, [answerOptions, allGroupsChoices, connectedUsers]);

  const maxAnswerCount = useMemo(() => {
    return Math.max(...answerCounts);
  }, [answerCounts]);

  return (
    <Box {...containerStyle}>
      {answerOptions.map((option, index) => (
        <TeacherReviewOption
          key={option.id}
          text={option.text}
          imageAltText={option.image_alt_text}
          imageUrl={option.image_url}
          isCorrect={correctChoiceIds.includes(option.id)}
          variant={variant}
          studentTotal={studentTotal}
          answerCount={answerCounts[index]}
          maxAnswerCount={maxAnswerCount}
        />
      ))}
    </Box>
  );
};
