import { hoverStyles, pxToRem } from "adminComponents/utils";

export const AdminCoverImageColorSchemeChoice = {
  baseStyle: ({
    boxSize,
    color1String,
    color2String,
    isSelected,
  }: {
    boxSize: number;
    color1String: string;
    color2String: string;
    isSelected: boolean;
  }): Record<string, unknown> => ({
    container: {
      display: "inline-flex",
      borderStyle: "solid",
      borderWidth: pxToRem(3),
      borderRadius: "full",
      borderColor: isSelected ? "utility.link" : "transparent",
      padding: pxToRem(3),
      outline: "none",
      width: pxToRem(boxSize + 12),
      height: pxToRem(boxSize + 12),
      _hover: hoverStyles({
        borderColor: isSelected ? undefined : "primary.golden-hover",
      }),
      _focusVisible: {
        borderColor: isSelected ? undefined : "utility.focus",
      },
    },
    color: {
      bg: `linear-gradient(90deg, ${color1String} 0%, ${color1String} 50%, ${color2String} 50%, ${color2String} 100%)`,
      boxSize: pxToRem(boxSize),
      borderRadius: "full",
    },
  }),
};
