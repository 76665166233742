import { QuestionIcon } from "@chakra-ui/icons";
import {
  Box,
  ButtonGroup,
  Center,
  Flex,
  HStack,
  Image,
  VStack,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocalStorage } from "react-use";

import GoogleClassroomImage from "adminComponents/assets/GoogleClassroom.svg";
import { Button } from "adminComponents/atoms/Button";
import { Dropdown, IOption } from "adminComponents/atoms/Dropdown";
import { Icon } from "adminComponents/atoms/Icon";
import { IconTooltip } from "adminComponents/atoms/IconTooltip";
import { PremiumIcon } from "adminComponents/atoms/PremiumIcon";
import { Text } from "adminComponents/atoms/Text";
import { EmptyCard, IActionProps } from "adminComponents/molecules/EmptyCard";
import { SessionGroupsModal } from "adminComponents/molecules/SessionGroupsModal";
import { UserCard } from "adminComponents/molecules/UserCard";
import {
  getStudentFamilyName,
  getStudentGivenName,
} from "adminComponents/utils";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { useAnalytics } from "lib/contexts/analytics";
import {
  localStoreClassroomsReportsStudentSortAscKeyName,
  localStoreClassroomsReportsStudentSortByKeyName,
} from "links/lib/constants";
import { useAuth } from "links/lib/features/auth";
import {
  AnalyticsEvent,
  IClassroom,
  ICustomSessionGroup,
  IGradeLevel,
  IUser,
  StudentSortBy,
  ThirdParty,
  UserRole,
  UsersGroupsRole,
} from "links/lib/types";
import { sortAlpha } from "links/lib/util";
import { useConnectGClassroom } from "screens/TeacherClassrooms/ClassroomDetail/components/StudentsTab/hooks/useConnectGClassroom";

const sortOptions = [
  {
    value: StudentSortBy.GivenName,
    sortAsc: true,
    labelKey: "sortByGivenNameAsc",
  },
  {
    value: StudentSortBy.GivenName,
    sortAsc: false,
    labelKey: "sortByGivenNameDesc",
  },
  {
    value: StudentSortBy.FamilyName,
    sortAsc: true,
    labelKey: "sortByFamilyNameAsc",
  },
  {
    value: StudentSortBy.FamilyName,
    sortAsc: false,
    labelKey: "sortByFamilyNameDesc",
  },
];

export interface UserCardProps {
  accuracy?: number;
  gradeLevel?: IGradeLevel;
  pendingUserId?: string;
  user: IUser;
  usersGroupsRole?: UsersGroupsRole;
}

interface IProps {
  classroom: IClassroom;
  customSessionGroups?: ICustomSessionGroup[];
  showTeachersHeading?: boolean;
  usersData?: Array<UserCardProps>;
  usersRole?: UserRole;
  usersGroupsRole?: UsersGroupsRole;
  handleAddByEmail?: (classroom: IClassroom) => void;
  handleAddStudentsFromGoogleClassroom?: (classroom: IClassroom) => void;
  handleApproveStudent?: (user: IUser, pendingUserId?: string) => void;
  handleApproveAllStudents?: (pendingUserIds: string[]) => void;
  handleEdit?: (user: IUser) => void;
  handleEditTeacher?: (user: IUser, usersGroupsRole: UsersGroupsRole) => void;
  handleRemove?: (user: IUser) => void;
  handleGenerateClassroomLink?: (classroom: IClassroom) => void;
  handleMoveStudentToDifferentClassroom?: (user: IUser) => void;
  handleRejectStudent?: (user: IUser, pendingUserId?: string) => void;
  handleRejectAllStudents?: (pendingUserIds: string[]) => void;
  handleCreateCustomGroups?: (
    customSessionGroups: ICustomSessionGroup[]
  ) => void;
}

const isApproved = (userData: UserCardProps) =>
  !userData.pendingUserId || userData.pendingUserId === "0";

// Integration TODO - How are we differentiating between unapproved and pending?
const isPending = (userData: UserCardProps) =>
  !!userData.pendingUserId && userData.pendingUserId !== "0";

export const UsersTab: React.FC<IProps> = ({
  classroom,
  customSessionGroups,
  showTeachersHeading,
  usersData = [],
  usersRole,
  usersGroupsRole,
  handleAddByEmail,
  handleAddStudentsFromGoogleClassroom,
  handleApproveStudent,
  handleApproveAllStudents,
  handleEdit,
  handleEditTeacher,
  handleRemove,
  handleGenerateClassroomLink,
  handleMoveStudentToDifferentClassroom,
  handleRejectStudent,
  handleRejectAllStudents,
  handleCreateCustomGroups,
}) => {
  const { authUser } = useAuth();
  const { t } = useTranslation("admin", { useSuspense: false });
  const { trackEvent } = useAnalytics();

  const [defaultSortBy, setDefaultSortBy] = useLocalStorage(
    localStoreClassroomsReportsStudentSortByKeyName,
    StudentSortBy.GivenName
  );
  const [sortBy, _setSortBy] = useState(defaultSortBy);
  const setSortBy = (sortBy: StudentSortBy) => {
    setDefaultSortBy(sortBy);
    _setSortBy(sortBy);
  };

  const [defaultSortAsc, setDefaultSortAsc] = useLocalStorage(
    localStoreClassroomsReportsStudentSortAscKeyName,
    true
  );
  const [sortAsc, _setSortAsc] = useState(defaultSortAsc);
  const setSortAsc = (sortAsc: boolean) => {
    setDefaultSortAsc(sortAsc);
    _setSortAsc(sortAsc);
  };

  const approvedUsers = useMemo(
    () =>
      usersData
        .filter((userData) => isApproved(userData))
        .sort((a, b) => {
          if (sortBy === StudentSortBy.GivenName) {
            return sortAlpha(
              getStudentGivenName(a.user) || "",
              getStudentGivenName(b.user) || "",
              !sortAsc
            );
          }
          if (sortBy === StudentSortBy.FamilyName) {
            return sortAlpha(
              getStudentFamilyName(a.user) || "",
              getStudentFamilyName(b.user) || "",
              !sortAsc
            );
          }

          return 0;
        }),
    [sortAsc, sortBy, usersData]
  );

  const pendingUsers = useMemo(
    () => usersData.filter((userData) => !isApproved(userData)),
    [usersData]
  );

  const {
    authorizeModal,
    classroomSelectModal,
    confirmLinkModal,
    studentEnrollmentDifferencesModal,
    handleAddIntent,
  } = useConnectGClassroom({
    classroom,
    classroomStudents: usersData.map((s) => s.user),
  });

  const {
    isOpen: isSessionGroupsModalOpen,
    onOpen: onSessionGroupsModalOpen,
    onClose: onSessionGroupsModalClose,
  } = useDisclosure();

  const showConnectToGoogleClassroomButton =
    classroom.users_groups_role === UsersGroupsRole.Owner &&
    classroom.third_party !== ThirdParty.GOOGLE_CLASSROOM &&
    usersData.length > 0;

  const users = useMemo(
    () =>
      approvedUsers
        .filter((userData) => userData.user.id !== "0")
        .map((userData) => userData.user),
    [approvedUsers]
  );

  const showEditStudentGroupsButton =
    classroom.users_groups_role === UsersGroupsRole.Owner && users.length > 1;

  const isMobile = useBreakpointValue({ base: true, lg: false });
  const hasApprovedUsers = !!approvedUsers?.length;

  const emptyCardActions: Array<IActionProps> = [];
  if (handleAddByEmail) {
    emptyCardActions.push({
      onClick: () => handleAddByEmail(classroom),
      size: "lg",
      text: t("classDetailTabs.ctaEmail"),
      variant: "adminButtonFilled",
      icon: "mail_outlined",
    });
  }
  if (handleAddStudentsFromGoogleClassroom) {
    emptyCardActions.push({
      onClick: () => handleAddStudentsFromGoogleClassroom?.(classroom),
      size: "lg",
      text: t("classDetailTabs.ctaClassroom"),
      variant: "adminButtonOutlined",
      leftIcon: <Image w={pxToRem(32)} src={GoogleClassroomImage} />,
    });
  }

  const emptyCardExtraAction = handleGenerateClassroomLink
    ? {
        onClick: () => handleGenerateClassroomLink?.(classroom),
        text: t("classDetailTabs.ctaClassroomLink"),
      }
    : undefined;

  const isCreator = classroom.created_by === authUser?.id;
  const selfRole = classroom.users_groups_role;
  const isOwner = selfRole === UsersGroupsRole.Owner;
  const ownersCount =
    usersData.filter((user) => user.usersGroupsRole === UsersGroupsRole.Owner)
      ?.length || 0;

  return (
    <Box mt={pxToRem(40)}>
      {showTeachersHeading ? (
        <Flex
          justifyContent="space-between"
          w="100%"
          mb={pxToRem(20)}
          gap={pxToRem(10)}
          flexDirection={isMobile ? "column" : "row"}
        >
          <VStack alignItems="flex-start">
            <Text variant="adminH3">{t("common.teachers")}</Text>
            <Text variant="adminP2">
              {t("classDetailTabs.teachersDescription")}
            </Text>
          </VStack>

          {handleAddByEmail && (
            <Button
              variant="adminButtonFilled"
              onClick={() => handleAddByEmail(classroom)}
            >
              {t("classDetailTabs.addTeacherButton")}
            </Button>
          )}
        </Flex>
      ) : (
        <Flex
          justifyContent="right"
          w="100%"
          mb={pxToRem(20)}
          gap={pxToRem(10)}
          flexDirection={isMobile ? "column" : "row"}
        >
          <HStack mr="auto" w={["full", null, "50%"]}>
            <Text variant="adminP2">{t("common.sortBy")}</Text>
            <Box
              cursor={hasApprovedUsers ? "pointer" : "not-allowed"}
              zIndex="3"
              flex={["0.6", null, null, "0.5"]}
            >
              <Dropdown
                isDisabled={!hasApprovedUsers}
                handleChange={(e) => {
                  const newSortBy = sortOptions.find(
                    (opt) =>
                      opt.value === (e as IOption).value &&
                      opt.sortAsc === (e as { sortAsc: boolean }).sortAsc
                  );
                  if (newSortBy) {
                    setSortBy(newSortBy.value);
                    setSortAsc(newSortBy.sortAsc);
                  }
                }}
                options={sortOptions.map((opt) => ({
                  value: opt.value,
                  sortAsc: opt.sortAsc,
                  label: t(`classDetailTabs.${opt.labelKey}`),
                }))}
                value={[
                  sortOptions.find(
                    (opt) => opt.value === sortBy && opt.sortAsc === sortAsc
                  ) || sortOptions[0],
                ].map((val) => ({
                  value: val.value,
                  sortAsc: val.sortAsc,
                  label: t(`classDetailTabs.${val.labelKey}`),
                }))}
              />
            </Box>
          </HStack>

          {showEditStudentGroupsButton && (
            <Button
              leftIcon={<Icon icon="diagram_outlined" />}
              rightIcon={<PremiumIcon />}
              variant="adminButtonOutlined"
              onClick={() => {
                onSessionGroupsModalOpen();
                trackEvent(
                  AnalyticsEvent.TeacherDashboard_ClassroomDetail_StudentsTab_EditStudentGroupsIntent,
                  {
                    group_id: classroom.id,
                  }
                );
              }}
            >
              {t("classDetailTabs.editStudentGroups", {
                count: customSessionGroups ? customSessionGroups.length : 0,
              })}
            </Button>
          )}

          {showConnectToGoogleClassroomButton && (
            <>
              <Button variant="adminButtonFilled" onClick={handleAddIntent}>
                {t("classDetailTabs.connect")}
              </Button>
              <Center>
                <IconTooltip
                  placement="top-end"
                  triggerEl={
                    <QuestionIcon
                      color="utility.link"
                      width={pxToRem(24)}
                      height={pxToRem(24)}
                    />
                  }
                >
                  {t("classDetailTabs.connectDescription")}
                </IconTooltip>
              </Center>
            </>
          )}
        </Flex>
      )}

      {hasApprovedUsers && (
        <Box mb={pxToRem(32)}>
          {approvedUsers.map((userData, i) => {
            const { user, accuracy, gradeLevel, usersGroupsRole } = userData;
            const approved = isApproved(userData);
            const pending = isPending(userData);
            const isSelf = user.id === authUser?.id;
            const canModifyUser = isOwner && !isSelf;
            const canModifyTeacher = canModifyUser && !!usersGroupsRole;
            const canRemoveSelf = (() => {
              if (!isSelf) return;

              // The creator can never remove themselves
              if (isCreator) return false;

              // A teacher can remove themselves as long as they aren't the last owner
              if (isOwner && ownersCount > 1) return true;

              return false;
            })();
            const canRemoveInvited = isOwner && user.id === "0";

            return (
              <Box key={`${user.id}-${i}`} mb={pxToRem(12)}>
                <UserCard
                  accuracy={accuracy}
                  isApproved={approved}
                  isPending={pending}
                  handleApprove={
                    canModifyUser ? handleApproveStudent : undefined
                  }
                  handleEditStudent={canModifyUser ? handleEdit : undefined}
                  handleEditTeacher={
                    canModifyTeacher ? handleEditTeacher : undefined
                  }
                  handleMoveToDifferentClassroom={
                    canModifyUser
                      ? handleMoveStudentToDifferentClassroom
                      : undefined
                  }
                  handleRemove={
                    canRemoveSelf || canRemoveInvited ? handleRemove : undefined
                  }
                  gradeLevel={gradeLevel}
                  handleReject={canModifyUser ? handleRejectStudent : undefined}
                  user={user}
                  showAccuracy={usersGroupsRole === UsersGroupsRole.Member}
                  usersGroupsRole={
                    usersGroupsRole || (user.role as unknown as UsersGroupsRole)
                  }
                  classroomId={classroom.id}
                />
              </Box>
            );
          })}
        </Box>
      )}

      {pendingUsers.length > 0 && (
        <Box mb={pxToRem(32)}>
          <Flex
            mb={pxToRem(32)}
            alignItems="center"
            justifyContent="space-between"
          >
            <Text variant="adminP1">
              {t("classDetailTabs.pendingListTitle", {
                count: pendingUsers.length || 0,
              })}
            </Text>
            <ButtonGroup>
              {handleApproveAllStudents && (
                <Button
                  size="md"
                  variant="adminButtonFilled"
                  onClick={() => {
                    handleApproveAllStudents(
                      pendingUsers.flatMap((ps) => ps.pendingUserId || [])
                    );
                  }}
                >
                  {t("classDetailTabs.pendingListApproveAll")}
                </Button>
              )}
              {handleRejectAllStudents && (
                <Button
                  size="md"
                  variant="adminButtonOutlined"
                  onClick={() => {
                    handleRejectAllStudents(
                      pendingUsers.flatMap((ps) => ps.pendingUserId || [])
                    );
                  }}
                >
                  {t("classDetailTabs.pendingListRejectAll")}
                </Button>
              )}
            </ButtonGroup>
          </Flex>

          {pendingUsers.map((userData) => {
            const { user, accuracy, gradeLevel, pendingUserId } = userData;
            const approved = isApproved(userData);
            const pending = isPending(userData);

            return (
              <Box key={userData.user.id} mb={pxToRem(12)}>
                <UserCard
                  accuracy={accuracy}
                  isApproved={approved}
                  isPending={pending}
                  handleApprove={
                    handleApproveStudent
                      ? (user) => handleApproveStudent(user, pendingUserId)
                      : undefined
                  }
                  handleEditStudent={handleEdit}
                  gradeLevel={gradeLevel}
                  handleReject={
                    handleRejectStudent
                      ? (user) => handleRejectStudent(user, pendingUserId)
                      : undefined
                  }
                  user={user}
                  classroomId={classroom.id}
                />
              </Box>
            );
          })}
        </Box>
      )}

      {/* Add student users card for Owners */}
      {usersRole === UserRole.Student &&
        usersGroupsRole === UsersGroupsRole.Owner && (
          <EmptyCard
            actions={emptyCardActions}
            extraAction={emptyCardExtraAction}
            text={t("classDetailEmptyCards.studentsText")}
            title={t("classDetailEmptyCards.studentsAddTitle")}
          />
        )}

      {/* Empty state for students for Assistants */}
      {usersRole === UserRole.Student &&
        usersGroupsRole !== UsersGroupsRole.Owner &&
        usersData.length == 0 && (
          <EmptyCard
            actions={emptyCardActions}
            extraAction={emptyCardExtraAction}
            text={t("classDetailEmptyCards.assistantStudentsText")}
            title={t("classDetailEmptyCards.assistantStudentsAddTitle")}
          />
        )}

      {handleCreateCustomGroups && (
        <SessionGroupsModal
          handleClose={onSessionGroupsModalClose}
          handleSave={(sessionGroups) => {
            handleCreateCustomGroups(sessionGroups);
            trackEvent(
              AnalyticsEvent.TeacherDashboard_ClassroomDetail_StudentsTab_EditStudentGroupsConfirm,
              {
                group_id: classroom.id,
              }
            );
            onSessionGroupsModalClose();
          }}
          isOpen={isSessionGroupsModalOpen}
          sessionGroups={customSessionGroups || []}
          students={users}
        />
      )}

      {authorizeModal}
      {classroomSelectModal}
      {confirmLinkModal}
      {studentEnrollmentDifferencesModal}
    </Box>
  );
};
