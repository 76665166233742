import { AxiosError } from "axios";
import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { IClassroom } from "../../types";

export interface IFetchClassroomParams {
  id: string;
  onError?: (err: AxiosError) => void;
  onPermissionDenied?: () => void;
}

export interface IFetchClassroomResponse {
  classroom: IClassroom;
}

interface IFetchGroupResponse {
  group: IClassroom;
}

/**
 * Fetches a list of classes for the current user
 * @returns
 */
export default function useFetchClassroom(
  params: IFetchClassroomParams
): UseQueryResult<IFetchClassroomResponse, AxiosError> {
  const axios = useAxios();

  const query = useQuery<IFetchClassroomResponse, AxiosError>(
    ["classrooms", { id: params.id }],
    async (): Promise<IFetchClassroomResponse> => {
      return await axios
        .get(`/v1/groups/${params.id}`)
        .then((r) => {
          const data = r.data as IFetchGroupResponse;
          return { data: { classroom: data.group } };
        })
        .then((r) => r.data as IFetchClassroomResponse);
    },
    {
      enabled: !!params.id,
      onError: (err: AxiosError) => {
        if (err.response?.status === 403) {
          if (params.onPermissionDenied) {
            params.onPermissionDenied();
            return;
          }
        }

        params.onError?.(err);
      },
    }
  );

  return query;
}
