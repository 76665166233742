import { Box, GridItem, SimpleGrid } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useMeasure } from "react-use";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { IStageProps, Stage } from "sessionComponents/atoms/Stage";

import { useDetectOrientation } from "../../theme/hooks/useDetectOrientation";

export interface ILobbyLayoutProps {
  avatarComponent: React.ReactNode | null;
  actionContent: React.ReactNode;
  stageProps?: IStageProps;
  avatarZIndex?: number;
}

export const LobbyLayout: React.FC<ILobbyLayoutProps> = ({
  avatarComponent,
  actionContent,
  stageProps = {},
  avatarZIndex,
}) => {
  const { isLandscape, isPortrait } = useDetectOrientation();
  const [containerRef, { height: containerHeight }] =
    useMeasure<HTMLDivElement>();
  const [actionRef, { height: actionHeight }] = useMeasure<HTMLDivElement>();

  const content = useMemo(() => {
    const avatarComponentHeight = isLandscape
      ? "100%"
      : pxToRem(containerHeight - actionHeight);

    const theActionContent = (
      <GridItem ref={actionRef} display="flex" h="full" overflow="hidden">
        {actionContent}
      </GridItem>
    );

    const elements = [];
    if (isPortrait) {
      elements.push(theActionContent);
    }
    if (avatarComponent) {
      elements.push(
        <GridItem h={avatarComponentHeight} w="100%" zIndex={avatarZIndex}>
          {avatarComponent}
        </GridItem>
      );
    }
    if (isLandscape) {
      elements.push(theActionContent);
    }

    return elements;
  }, [
    isPortrait,
    isLandscape,
    actionContent,
    actionHeight,
    avatarComponent,
    actionRef,
    avatarZIndex,
    containerHeight,
  ]);

  return (
    <Box ref={containerRef} h="full" position="relative">
      <Stage {...stageProps}>
        <SimpleGrid h="full" overflowY="auto" columns={isLandscape ? 2 : 1}>
          {content}
        </SimpleGrid>
      </Stage>
    </Box>
  );
};
