import { Box } from "@chakra-ui/react";
import React from "react";

import { AvatarAnimations } from "links/lib/constants";
import { useSession } from "links/lib/contexts/session";
import { SessionGameType, UserRole } from "links/lib/types";
import { useGameHypeAudio } from "sessionComponents/hooks/useGameHypeAudio";
import { AvatarCarousel } from "sessionComponents/molecules/AvatarCarousel";
import { LobbyLayout } from "sessionComponents/molecules/LobbyLayout";
import { TeacherPodiumFooter } from "sessionComponents/molecules/TeacherPodiumFooter";
import { useDetectOrientation } from "sessionComponents/theme/hooks/useDetectOrientation";
import { useSafeUserArray } from "sharedComponents/hooks/useSafeUserArray";

import { GroupScores } from "../GroupScores";
import { QuickPlayGroupScores } from "../QuickPlayGroupScores";

const loopingVictoryAnimations = AvatarAnimations.Victory.filter((a) => a.loop);

interface ITeacherFinalPodiumViewProps {
  outerGame?: SessionGameType;
}

export const TeacherFinalPodiumView: React.FC<ITeacherFinalPodiumViewProps> = ({
  outerGame = SessionGameType.TheBigBoard,
}) => {
  const { isPortrait } = useDetectOrientation();
  const allStudents = useSafeUserArray().filter(
    (u) => u.role === UserRole.Student
  );
  const session = useSession();
  useGameHypeAudio(false);

  return (
    <LobbyLayout
      avatarComponent={
        isPortrait ? undefined : (
          <AvatarCarousel
            students={allStudents}
            selfId="0"
            candidateAnimations={loopingVictoryAnimations}
          />
        )
      }
      actionContent={
        <Box
          display="flex"
          flexDirection="column"
          w="full"
          h="full"
          justifyContent="space-between"
        >
          {outerGame === SessionGameType.QuickPlay ? (
            <QuickPlayGroupScores />
          ) : (
            <GroupScores />
          )}
          <TeacherPodiumFooter
            practice_set_id={session?.practice_set_id}
            group_id={session?.group_id}
          />
        </Box>
      }
      stageProps={{
        showCurtainOnly: isPortrait,
        outerGame,
      }}
    />
  );
};
