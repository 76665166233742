import { IGameAssetColorScheme } from "links/lib/types";

export const getAssetColorScheme = (
  colorScheme?: string
): IGameAssetColorScheme => {
  switch (colorScheme) {
    case "golden":
      return {
        primaryColor: "golden.base",
        secondaryColor: "golden.base-shadow-02",
      };
    case "pepto":
      return {
        primaryColor: "pepto.base",
        secondaryColor: "pepto.base-shadow-02",
      };
    case "pepto-alt":
      return {
        primaryColor: "pepto.base-shadow-02",
        secondaryColor: "pepto.base",
      };
    case "capri":
      return {
        primaryColor: "capri.base",
        secondaryColor: "capri.base-shadow-02",
      };
    case "capri-alt":
      return {
        primaryColor: "capri.base-shadow-02",
        secondaryColor: "capri.base",
      };
    case "tangerine":
      return {
        primaryColor: "tangerine.base",
        secondaryColor: "tangerine.base-shadow-02",
      };
    case "tangerine-alt":
      return {
        primaryColor: "tangerine.base-shadow-02",
        secondaryColor: "tangerine.base",
      };
    case "anjou":
      return {
        primaryColor: "anjou.base",
        secondaryColor: "anjou.base-shadow-02",
      };
    case "anjou-alt":
      return {
        primaryColor: "anjou.base-shadow-02",
        secondaryColor: "anjou.base",
      };
    case "lilac":
      return {
        primaryColor: "lilac.base",
        secondaryColor: "lilac.base-shadow-02",
      };
    case "lilac-alt":
      return {
        primaryColor: "lilac.base-shadow-02",
        secondaryColor: "lilac.base",
      };
    case "monaco":
      return {
        primaryColor: "monaco.base",
        secondaryColor: "monaco.base-shadow-02",
      };
    case "monaco-alt":
      return {
        primaryColor: "monaco.base-shadow-02",
        secondaryColor: "monaco.base",
      };
    case "tomato":
      return {
        primaryColor: "tomato.base",
        secondaryColor: "tomato.base-shadow-02",
      };
    case "tomato-alt":
      return {
        primaryColor: "tomato.base-shadow-02",
        secondaryColor: "tomato.base",
      };
    case "amber":
      return {
        primaryColor: "amber.base",
        secondaryColor: "amber.base-shadow-02",
      };
    case "amber-alt":
      return {
        primaryColor: "amber.base-shadow-02",
        secondaryColor: "amber.base",
      };
    case "smoke":
      return {
        primaryColor: "smoke.base",
        secondaryColor: "smoke.base-shadow-02",
      };
    default:
      return {
        primaryColor: "black",
        secondaryColor: "black",
      };
  }
};
