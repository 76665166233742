import { Box, BoxProps, HStack } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Icon } from "adminComponents/atoms/Icon";
import { IconTooltip } from "adminComponents/atoms/IconTooltip";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils";

export enum AccuracyType {
  Class = 0,
  Student = 1,
}

export interface IProps extends BoxProps {
  isHighAccuracy: boolean;
  accuracyType: AccuracyType;
}

export const ReportingAccuracyTag: React.FC<IProps> = ({
  isHighAccuracy,
  accuracyType,
  ...rest
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });

  const textKey = accuracyType === AccuracyType.Class ? "Class" : "Student";

  return (
    <Box
      w="fit-content"
      bgColor={
        isHighAccuracy ? "capri.light-shadow-01" : "pepto.light-shadow-01"
      }
      pt={pxToRem(7)}
      pb={pxToRem(4)}
      px={pxToRem(12)}
      borderRadius={pxToRem(8)}
      {...rest}
    >
      <HStack>
        <Icon
          boxSize={pxToRem(16)}
          icon={isHighAccuracy ? "arrow_upward" : "arrow_downward"}
        />
        <Text variant="adminP2">
          {isHighAccuracy
            ? t(`practiceSetCard.high${textKey}Accuracy`)
            : t(`practiceSetCard.low${textKey}Accuracy`)}
        </Text>
        <IconTooltip placement="bottom">
          {isHighAccuracy
            ? t(`practiceSetCard.high${textKey}AccuracyTooltip`)
            : t(`practiceSetCard.low${textKey}AccuracyTooltip`)}
        </IconTooltip>
      </HStack>
    </Box>
  );
};
