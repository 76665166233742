import { Flex, SimpleGrid } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Card } from "adminComponents/atoms/Card";
import { CoverImageIcon } from "adminComponents/atoms/CoverImageIcon";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { subjectIdToIconDataMap } from "links/lib/constants";
import { ISubject, ISubjectCount } from "links/lib/types";

interface LibrarySubjectPickerProps {
  handleSelectSubject: (subjectId: string) => void;
  subjects: ISubject[];
  subjectCounts: ISubjectCount[];
  showCounts?: boolean;
  minChildWidth?: string;
}

export const LibraryParentSubjectPickerGrid: React.FC<
  LibrarySubjectPickerProps
> = ({
  handleSelectSubject,
  subjects,
  subjectCounts,
  showCounts = true,
  minChildWidth = pxToRem(300),
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const subjectsWithCounts: {
    subject: ISubject;
    subjectCount?: ISubjectCount;
  }[] = useMemo(() => {
    const subjectsWithCounts = subjects.map((subject) => {
      const subjectCount = subjectCounts.find(
        (sc) => sc.subject_id === subject.id
      );
      return { subject, subjectCount };
    });

    subjectsWithCounts.sort((a, b) => {
      if (a.subjectCount && b.subjectCount) {
        return b.subjectCount.count - a.subjectCount.count;
      }
      return 0;
    });

    return subjectsWithCounts;
  }, [subjectCounts, subjects]);

  return (
    <SimpleGrid gap={pxToRem(20)} minChildWidth={minChildWidth}>
      {subjectsWithCounts.map(({ subject, subjectCount }) => {
        const iconData = subjectIdToIconDataMap[subject.id];
        return (
          <Card
            variant="adminCardSelectable"
            bgColor="primary.white"
            borderColor="primary.tan"
            key={subject.id}
            onClick={() => handleSelectSubject(subject.id)}
          >
            <Flex alignItems="center" gap={pxToRem(20)}>
              {iconData && (
                <CoverImageIcon
                  borderColor={iconData.color}
                  rgbBackgroundColor={iconData.color}
                  icon={iconData.coverImageIcon}
                  h={pxToRem(36)}
                  w={pxToRem(36)}
                  variant="adminSolidBorder"
                />
              )}
              <Flex flexDirection="column">
                <Text variant="adminP1Bold">{subject.name}</Text>
                {showCounts && subjectCount && (
                  <Text variant="adminP2" color="primary.dark-gray">
                    {`${subjectCount.count} ${
                      subjectCount.count === 1
                        ? t("common.practiceSet")
                        : t("common.practiceSets")
                    }`}
                  </Text>
                )}
              </Flex>
            </Flex>
          </Card>
        );
      })}
    </SimpleGrid>
  );
};
