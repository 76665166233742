import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from "@chakra-ui/react";
import moment from "moment";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Icon } from "adminComponents/atoms/Icon";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils";
import { momentFormatString } from "links/lib/constants";
import { IAssignment, IPracticeSetSession } from "links/lib/types";

export enum ReportingPage {
  Classroom = 0,
  Student = 1,
}

interface IProps {
  pageType: ReportingPage;
  pastAssignments?: Array<IAssignment>;
  specificAssignment?: IAssignment;
  setSpecificAssignment?: (assignment?: IAssignment) => void;
  pastSessions?: Array<IPracticeSetSession>;
  specificSession?: IPracticeSetSession;
  setSpecificSession?: (session?: IPracticeSetSession) => void;
}

export const ReportingSpecificSessionPicker: React.FC<IProps> = ({
  pageType,
  pastAssignments,
  specificAssignment,
  setSpecificAssignment,
  pastSessions,
  specificSession,
  setSpecificSession,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const [sessionOptions, assignmentOptions] = useMemo(() => {
    const assignmentOptions = (pastAssignments || []).map((a) => ({
      value: a.id,
      label: moment(a.ends_at).format(momentFormatString),
    }));
    const sessionOptions = (pastSessions || []).map((s) => ({
      value: s.id,
      label: moment(s.start_time).format(momentFormatString),
    }));

    return [sessionOptions, assignmentOptions];
  }, [pastSessions, pastAssignments]);

  let buttonText =
    pageType === ReportingPage.Classroom
      ? t("reportingSpecificSessionPicker.accumulatedClassAccuracy")
      : t("reportingSpecificSessionPicker.accumulatedStudentAccuracy");
  if (specificAssignment) {
    buttonText = moment(specificAssignment.ends_at).format(momentFormatString);
  }
  if (specificSession) {
    buttonText = moment(specificSession.start_time).format(momentFormatString);
  }

  return (
    <>
      <Flex w="full" flexDir="column" gap={pxToRem(8)}>
        <Text variant="adminP1">
          {t("reportingSpecificSessionPicker.showResultsFor")}
        </Text>
        <Box w="30%" zIndex={2}>
          <Menu placement="bottom" matchWidth>
            <MenuButton
              as={Button}
              variant="adminDropdownButton"
              leftIcon={
                <Icon color="primary.dark-gray" icon="calendar_today" />
              }
              rightIcon={<Icon icon="keyboard_arrow_down" />}
            >
              {buttonText}
            </MenuButton>
            <MenuList minWidth={pxToRem(240)}>
              <MenuItemOption
                type="radio"
                icon={null}
                value="all"
                background={
                  !specificAssignment && !specificSession
                    ? "primary.lightest-gray"
                    : undefined
                }
                onClick={() => {
                  setSpecificAssignment?.(undefined);
                  setSpecificSession?.(undefined);
                }}
                _hover={{
                  background: "primary.golden-light",
                }}
              >
                {pageType === ReportingPage.Classroom
                  ? t("reportingSpecificSessionPicker.accumulatedClassAccuracy")
                  : t(
                      "reportingSpecificSessionPicker.accumulatedStudentAccuracy"
                    )}
              </MenuItemOption>
              {!!sessionOptions.length && (
                <MenuOptionGroup
                  title={t("reportingSpecificSessionPicker.livePractice")}
                  type="radio"
                  color="primary.dark-gray"
                  fontWeight="normal"
                  ml={pxToRem(12)}
                >
                  {sessionOptions.map((opt) => {
                    const session = pastSessions?.find(
                      (s) => s.id === opt.value
                    );
                    const isSelected = specificSession?.id === session?.id;
                    return (
                      <MenuItemOption
                        type="radio"
                        icon={null}
                        key={opt.value}
                        value={opt.value}
                        background={
                          isSelected ? "primary.lightest-gray" : undefined
                        }
                        onClick={() => {
                          setSpecificAssignment?.(undefined);
                          setSpecificSession?.(session);
                        }}
                        _hover={{
                          background: "primary.golden-light",
                        }}
                      >
                        {opt.label}
                      </MenuItemOption>
                    );
                  })}
                </MenuOptionGroup>
              )}
              <MenuDivider />
              {!!assignmentOptions.length && (
                <MenuOptionGroup
                  title={t("reportingSpecificSessionPicker.assignedPractice")}
                  type="radio"
                  color="primary.dark-gray"
                  fontWeight="normal"
                  ml={pxToRem(12)}
                >
                  {assignmentOptions.map((opt) => {
                    const assignment = pastAssignments?.find(
                      (a) => a.id === opt.value
                    );
                    const isSelected =
                      specificAssignment?.id === assignment?.id;
                    return (
                      <MenuItemOption
                        type="radio"
                        icon={null}
                        key={opt.value}
                        value={opt.value}
                        background={
                          isSelected ? "primary.lightest-gray" : undefined
                        }
                        onClick={() => {
                          setSpecificSession?.(undefined);
                          setSpecificAssignment?.(assignment);
                        }}
                        _hover={{
                          background: "primary.golden-light",
                        }}
                      >
                        {opt.label}
                      </MenuItemOption>
                    );
                  })}
                </MenuOptionGroup>
              )}
            </MenuList>
          </Menu>
        </Box>
      </Flex>
      <Text variant="adminP1">
        {!specificAssignment &&
          !specificSession &&
          t("reportingSpecificSessionPicker.descriptionAccumulated")}
        {specificAssignment && (
          <>
            {t("reportingSpecificSessionPicker.descriptionSpecific")}
            <b>
              {t(
                "reportingSpecificSessionPicker.descriptionSpecificAssignment",
                {
                  date: moment(specificAssignment.ends_at).format(
                    momentFormatString
                  ),
                }
              )}
            </b>
          </>
        )}
        {specificSession && (
          <>
            {t("reportingSpecificSessionPicker.descriptionSpecific")}
            <b>
              {t(
                "reportingSpecificSessionPicker.descriptionSpecificLivePractice",
                {
                  date: moment(specificSession.start_time).format(
                    momentFormatString
                  ),
                }
              )}
            </b>
          </>
        )}
      </Text>
    </>
  );
};
