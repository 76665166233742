import { hoverStyles, pxToRem } from "adminComponents/utils";

interface IProps {
  boxSize: number;
  iconSize: number;
  selected?: boolean;
}

export const AdminIconCTAButton = {
  parts: ["box", "icon"],
  baseStyle: ({ boxSize, iconSize }: IProps): Record<string, unknown> => ({
    box: {
      borderRadius: pxToRem(4),
      borderColor: "transparent",
      borderStyle: "solid",
      borderWidth: pxToRem(2),
      position: "relative",
      width: pxToRem(boxSize),
      height: pxToRem(boxSize),
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      outline: "none",
      boxShadow: "none",
      transition: "background-color 250ms, border-color 250ms",
      svg: {
        transition: "color 250ms",
      },
      "*": {
        outline: "none",
        boxShadow: "none",
      },
      _focus: {
        boxShadow: "none !important",
      },
      _focusVisible: {
        borderColor: "utility.focus",
        borderStyle: "solid",
        borderWidth: pxToRem(2),
        "> svg:first-of-type": {
          fill: "transparent",
          stroke: "utility.focus",
          strokeWidth: pxToRem(2),
        },
      },
      _disabled: {
        pointerEvents: "none",
      },
    },
    icon: {
      top: "50%",
      left: "50%",
      width: pxToRem(iconSize),
      height: pxToRem(iconSize),
      transition: "color 250ms, fill 250ms",
    },
  }),
  variants: {
    dark: {
      box: {
        bgColor: "inherit",
        _active: {
          bgColor: "primary.golden",
          _hover: hoverStyles({
            bgColor: "primary.golden-hover",
          }),
          svg: {
            color: "primary.white",
          },
        },
        _disabled: {
          bgColor: "inherit",
          svg: {
            color: "primary.dark-gray",
          },
          _active: {
            bgColor: "primary.dark-gray",
            svg: {
              color: "primary.warm-black",
            },
          },
        },
      },
      icon: {
        color: "primary.white",
        fill: "inherit",
        _active: {
          fill: "primary.golden",
        },
        _hover: hoverStyles({
          color: "primary.golden-hover",
        }),
      },
    },
    light: {
      bgColor: "inherit",
      box: {
        _active: {
          bgColor: "primary.golden",
          _hover: hoverStyles({
            bgColor: "primary.golden-hover",
          }),
        },
        _disabled: {
          svg: {
            color: "primary.medium-gray",
          },
          _active: {
            bgColor: "primary.medium-gray",
            svg: {
              color: "primary.white",
            },
          },
        },
        _hover: hoverStyles({
          bgColor: "primary.golden-light",
        }),
      },
      icon: {
        color: "primary.warm-black",
        fill: "inherit",
        _active: {
          fill: "primary.golden",
        },
      },
    },
  },
};
