import { SimpleGrid } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Heading } from "adminComponents/atoms/Heading";
import { QuestionCard } from "adminComponents/atoms/QuestionCard";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { QuestionType } from "links/lib/types";

export interface PracticeSetAddQuestionsProps {
  handleClickItemTypeWithSubTypes: (t: QuestionType) => void;
  handleClickPremiumItemType: (t: QuestionType) => void;
  handleAddNew: (t: QuestionType, subType?: "text" | "images") => void;
  showImageAndTextSubTypes: boolean;
  questionType?: QuestionType;
  showPremiumMarker?: boolean;
}

export const PracticeSetAddQuestions: React.FC<
  PracticeSetAddQuestionsProps
> = ({
  handleAddNew,
  handleClickItemTypeWithSubTypes,
  handleClickPremiumItemType,
  showImageAndTextSubTypes,
  questionType,
  showPremiumMarker = true,
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });

  let headingText = t("practiceSetAddQuestions.createNew");
  if (showImageAndTextSubTypes) {
    headingText = t("practiceSetAddQuestions.selectType");
  }

  return (
    <>
      <Heading
        as="h3"
        variant="adminH6"
        mt={showImageAndTextSubTypes ? 0 : [pxToRem(40), null, pxToRem(53)]}
        mb={[pxToRem(12), null, pxToRem(16)]}
      >
        {headingText}
      </Heading>
      <SimpleGrid columns={[1, null, 2]} gap={[pxToRem(12), null, pxToRem(20)]}>
        {showImageAndTextSubTypes && (
          <ImageOrTextCards
            questionType={questionType || QuestionType.Unknown}
            handleAddNew={handleAddNew}
          />
        )}
        {!showImageAndTextSubTypes && (
          <ItemCards
            handleAddNew={handleAddNew}
            handleClickItemTypeWithSubTypes={handleClickItemTypeWithSubTypes}
            handleClickPremiumItemType={handleClickPremiumItemType}
            showPremiumMarker={showPremiumMarker}
          />
        )}
      </SimpleGrid>
    </>
  );
};

interface IImageOrTextCardsProps {
  questionType: QuestionType;
  handleAddNew: (
    questionType: QuestionType,
    subType?: "text" | "images"
  ) => void;
}

const ImageOrTextCards: React.FC<IImageOrTextCardsProps> = ({
  questionType,
  handleAddNew,
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });

  let questionTypeString = "";
  if (questionType === QuestionType.MultipleChoice) {
    questionTypeString = "multipleChoice";
  } else if (questionType === QuestionType.Classify) {
    questionTypeString = "classify";
  }

  const getAriaLabel = (subType: string) => {
    return `${t("practiceSetAddQuestions.createNew")} ${t(
      `practiceSetAddQuestions.${questionTypeString}`
    )} ${t(`practiceSetAddQuestions.${subType}`)}`;
  };

  return (
    <>
      <QuestionCard
        icon="format_align_left"
        title={t("practiceSetAddQuestions.text")}
        iconColor="utility.badge-orange"
        onClick={() => handleAddNew(questionType, "text")}
        ariaLabel={getAriaLabel("text")}
      >
        {t(`practiceSetAddQuestions.${questionTypeString}TextDescription`)}
      </QuestionCard>
      <QuestionCard
        icon="photo_camera_outlined"
        title={t("practiceSetAddQuestions.images")}
        iconColor="utility.badge-orange"
        onClick={() => handleAddNew(questionType, "images")}
        ariaLabel={getAriaLabel("images")}
      >
        {t(`practiceSetAddQuestions.${questionTypeString}ImagesDescription`)}
      </QuestionCard>
    </>
  );
};

interface IItemCardsProps {
  handleAddNew: (
    questionType: QuestionType,
    subType?: "text" | "images"
  ) => void;
  handleClickItemTypeWithSubTypes: (questionType: QuestionType) => void;
  handleClickPremiumItemType: (t: QuestionType) => void;
  showPremiumMarker?: boolean;
}

const ItemCards: React.FC<IItemCardsProps> = ({
  handleAddNew,
  handleClickItemTypeWithSubTypes,
  handleClickPremiumItemType,
  showPremiumMarker = true,
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });

  const getAriaLabel = (questionTypeString: string) => {
    return `${t("practiceSetAddQuestions.createNew")} ${t(
      `practiceSetAddQuestions.${questionTypeString}`
    )}`;
  };

  return (
    <>
      {/* TODO: term items will be re-evaluated in the future
         <QuestionCard
          icon="chat_bubble_outlined"
          title={t("practiceSetAddQuestions.term")}
          iconColor="utility.badge-blue"
          onClick={() => handleAddNew(QuestionType.TermDefinition)}
          ariaLabel={
            t("practiceSetAddQuestions.createNew") +
            " " +
            t("practiceSetAddQuestions.term")
          }
        >
          {t("practiceSetAddQuestions.termDescription")}
        </QuestionCard> */}
      <QuestionCard
        icon="check_outlined"
        title={t("practiceSetAddQuestions.multipleChoice")}
        iconColor="utility.badge-purple"
        onClick={() =>
          handleClickItemTypeWithSubTypes(QuestionType.MultipleChoice)
        }
        ariaLabel={getAriaLabel("multipleChoice")}
      >
        {t("practiceSetAddQuestions.multipleChoiceDescription")}
      </QuestionCard>
      <QuestionCard
        icon="format_align_left"
        title={t("practiceSetAddQuestions.textResponse")}
        iconColor="utility.badge-green"
        onClick={() => handleAddNew(QuestionType.TextResponse)}
        ariaLabel={getAriaLabel("textResponse")}
      >
        {t("practiceSetAddQuestions.textResponseDescription")}
      </QuestionCard>
      <QuestionCard
        // TODO: Replace with correct icon
        icon="format_sup_outlined"
        title={t("practiceSetAddQuestions.numberResponse")}
        iconColor="utility.badge-green-grey"
        onClick={() => handleAddNew(QuestionType.NumberResponse)}
        ariaLabel={getAriaLabel("numberResponse")}
      >
        {t("practiceSetAddQuestions.numberResponseDescription")}
      </QuestionCard>
      <QuestionCard
        // TODO: Replace with correct icon
        icon="edit_outlined"
        title={t("practiceSetAddQuestions.draw")}
        iconColor="utility.badge-light-blue"
        onClick={() => handleAddNew(QuestionType.Draw)}
        ariaLabel={getAriaLabel("draw")}
      >
        {t("practiceSetAddQuestions.drawDescription")}
      </QuestionCard>
      <QuestionCard
        icon="diagram_outlined"
        title={t("practiceSetAddQuestions.diagram")}
        iconColor="utility.badge-red"
        onClick={() => handleClickPremiumItemType(QuestionType.Diagram)}
        ariaLabel={getAriaLabel("diagram")}
        isPremium={showPremiumMarker}
      >
        {t("practiceSetAddQuestions.diagramDescription")}
      </QuestionCard>
      <QuestionCard
        // TODO: Replace with correct icon
        icon="list_outlined"
        title={t("practiceSetAddQuestions.classify")}
        iconColor="utility.badge-orange"
        onClick={() => handleClickPremiumItemType(QuestionType.Classify)}
        ariaLabel={getAriaLabel("classify")}
        isPremium={showPremiumMarker}
      >
        {t("practiceSetAddQuestions.classifyDescription")}
      </QuestionCard>
    </>
  );
};
