import React from "react";

import {
  IAvatar,
  ISeasonLocationAvatarItem,
  ISeasonLocationAvatarItemGroup,
} from "links/lib/types";
import { SeasonLocation } from "sharedComponents/molecules/SeasonLocation";
import { CombinedItem } from "sharedComponents/molecules/SeasonLocationProgress";

import ArtMueseumSVG from "./resource/art_museum.svg";
import CafeSVG from "./resource/cafe.svg";
import CityHallSVG from "./resource/city_hall.svg";
import DinerSVG from "./resource/diner.svg";
import FarmersMarketSVG from "./resource/farmers_market.svg";
import LibrarySVG from "./resource/library.svg";
import MusicShopSVG from "./resource/music_shop.svg";
import ParkSVG from "./resource/park.svg";
import PlanetariumSVG from "./resource/planetarium.svg";
import PostOfficeSVG from "./resource/post_office.svg";
import SkateParkSVG from "./resource/skate_park.svg";
import StadiumSVG from "./resource/stadium.svg";
import TVStationSVG from "./resource/tv_station.svg";
import ZooSVG from "./resource/zoo.svg";

export interface ISeason1LocationProps {
  width: number;
  height: number;
  canUnlock: boolean;
  avatarItems: Array<ISeasonLocationAvatarItem>;
  avatarItemGroups: Array<ISeasonLocationAvatarItemGroup>;
  locationId: string;
  pathColor: string;
  isLoading: boolean;
  avatarAtlasUrl: string;
  avatarSkeletonUrl: string;
  avatar: IAvatar;
  handleRequestUnlock?: (item: CombinedItem) => void;
}

const locationToBGMap: { [locationId: string]: string } = {
  1: PostOfficeSVG,
  2: CafeSVG,
  3: LibrarySVG,
  4: ParkSVG,
  5: FarmersMarketSVG,
  6: ZooSVG,
  7: MusicShopSVG,
  8: DinerSVG,
  9: StadiumSVG,
  10: TVStationSVG,
  11: ArtMueseumSVG,
  12: PlanetariumSVG,
  13: SkateParkSVG,
  14: CityHallSVG,
};

export const Season1Location: React.FC<ISeason1LocationProps> = ({
  width,
  height,
  avatarItems,
  avatarItemGroups,
  locationId,
  pathColor,
  isLoading,
  avatarAtlasUrl,
  avatarSkeletonUrl,
  handleRequestUnlock,
  canUnlock,
  avatar,
}) => {
  return (
    <SeasonLocation
      width={width}
      height={height}
      canUnlock={canUnlock}
      avatarAtlasUrl={avatarAtlasUrl}
      avatarSkeletonUrl={avatarSkeletonUrl}
      handleRequestUnlock={handleRequestUnlock}
      avatarItems={avatarItems}
      avatarItemGroups={avatarItemGroups}
      pathColor={pathColor}
      isLoading={isLoading}
      avatar={avatar}
      bgSVG={locationToBGMap[locationId]}
    />
  );
};
