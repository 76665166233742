import { AxiosError } from "axios";
import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { IPracticeSet } from "../../types";

export interface IFetchPracticeSetResponse {
  practice_set: IPracticeSet;
  personal_rating: number;
}

export interface IUseFetchPracticeSetArgs {
  id: string;
  onError?: (err: AxiosError) => void;
  onNotFound?: () => void;
  onForbidden?: () => void;
}

/**
 * Fetches a practice set by id
 * @returns
 */
export default function useFetchPracticeSet(
  args: IUseFetchPracticeSetArgs
): UseQueryResult<IFetchPracticeSetResponse, AxiosError> {
  const { id } = args;

  const axios = useAxios();

  const query = useQuery<IFetchPracticeSetResponse, AxiosError>(
    ["practiceSets", { id }],
    async (): Promise<IFetchPracticeSetResponse> => {
      return await axios.get(`/v1/practice-sets/${id}`).then((r) => {
        return r.data as IFetchPracticeSetResponse;
      });
    },
    {
      enabled: id !== "0",
      onError: (err: AxiosError) => {
        if (err.response?.status === 403) {
          args.onForbidden?.();
        } else if (err.response?.status === 404) {
          args.onNotFound?.();
        }

        args.onError?.(err);
      },
    }
  );

  return query;
}
