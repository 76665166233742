import { Box, SimpleGrid, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useMeasure } from "react-use";

import { pxToRem } from "adminComponents/utils/pxToRem";
import {
  ISessionDraggableSortItem,
  ISessionUser,
  PracticeSessionItemVariantType,
  QuestionType,
} from "links/lib/types";
import { Card } from "sessionComponents/atoms/Card";
import { DraggableLabel } from "sessionComponents/atoms/DraggableLabel";
import { DroppableZone } from "sessionComponents/atoms/DroppableZone";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { useStudent } from "sessionComponents/contexts/student";
import { useSendThrottledDragCoords } from "sessionComponents/hooks/useSendThrottledDragCoords";
import { useDetectOrientation } from "sessionComponents/theme/hooks/useDetectOrientation";
import { getDraggingUserDetails } from "sessionComponents/utils/getDraggingUserDetails";
import { RichTextRenderer } from "sharedComponents/atoms/RichTextRenderer";

import { QuestionResponse } from "../QuestionResponse";

interface IClassifyDropZoneProps {
  title: string;
  itemsInZone: ISessionDraggableSortItem[];
  onDragStop: (labelId: string, zoneId: string) => void;
  onLabelMove?: (labelId: string, zoneId: string) => void;
  zoneId?: string;
  groupUsers: ISessionUser[];
}

export const ClassifyDropZone: React.FC<IClassifyDropZoneProps> = ({
  title,
  itemsInZone,
  onDragStop,
  onLabelMove,
  zoneId,
  groupUsers,
}) => {
  const student = useStudent();
  const { match: currentBreakpoints } = useBreakpoints();
  const { isPortrait } = useDetectOrientation();
  const { t } = useTranslation("session", { useSuspense: false });
  const isImageResponse = itemsInZone.some((item) => !!item.image_url);
  const sendThrottledCoords = useSendThrottledDragCoords();
  const [optionRef, { width: initialOptionWidth }] =
    useMeasure<HTMLDivElement>();
  const handleDropLabelInZone = (labelId: string, zoneId: string) => {
    sendThrottledCoords.current?.cancel();
    // Wait a little bit before we send drop to BE to ensure coord throttle is cancelled and we
    // don't end up sending a drag event after this drop
    setTimeout(() => onLabelMove?.(labelId, zoneId), 100);
  };

  return (
    <DroppableZone
      moveLabel={handleDropLabelInZone}
      zoneId={zoneId}
      marginTop={pxToRem(currentBreakpoints.margin)}
      w="100%"
      bgColor="transparent"
      borderStyle="2px dashed"
      borderColor="utility.link"
      borderRadius={pxToRem(currentBreakpoints.borderRadius)}
      background="transparent"
      zIndex={1}
    >
      <Card
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        h="fit-content"
        w="100%"
        backgroundColor="primary.warm-white"
      >
        <Text
          fontSize={pxToRem(currentBreakpoints.fontSize)}
          textStyle="gameText"
          as="div"
          marginBottom={
            isPortrait ? undefined : pxToRem(currentBreakpoints.margin)
          }
          maxWidth="full"
        >
          <RichTextRenderer content={title} />
        </Text>
        {!!itemsInZone.length && (
          <SimpleGrid
            columns={isImageResponse ? 2 : 1}
            spacing={pxToRem(currentBreakpoints.margin / 2)}
            w="100%"
            backgroundColor="transparent"
          >
            {itemsInZone.map((item) => {
              const draggingUser = getDraggingUserDetails({
                isDragging: item.is_dragging,
                userId: item.user_id,
                groupUsers,
              });
              return (
                <Box ref={optionRef} key={item.id}>
                  <DraggableLabel
                    label={item}
                    stopDragging={(labelId: string, zoneId: string) => {
                      sendThrottledCoords.current?.cancel();
                      onDragStop(labelId, zoneId);
                    }}
                    w="100%"
                    transform="translate(0, 0)"
                    startDragging={(labelId, coords) => {
                      sendThrottledCoords.current?.(labelId, coords);
                    }}
                    dragItemPreviewContent={
                      <QuestionResponse
                        variant={PracticeSessionItemVariantType.CoopDragsort}
                        questionType={QuestionType.Classify}
                        text={item.text}
                        imageUrl={item.image_url}
                        imageAltText={item.image_alt_text}
                        width={pxToRem(initialOptionWidth)}
                      />
                    }
                  >
                    <QuestionResponse
                      variant={PracticeSessionItemVariantType.CoopDragsort}
                      questionType={QuestionType.Classify}
                      text={item.text}
                      imageUrl={item.image_url}
                      imageAltText={item.image_alt_text}
                      disabled={item.is_dragging && item.user_id !== student.id}
                      users={draggingUser ? [draggingUser] : undefined}
                    />
                  </DraggableLabel>
                </Box>
              );
            })}
          </SimpleGrid>
        )}
        {!itemsInZone.length && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height={pxToRem(currentBreakpoints.responseHeight)}
          >
            <Text
              textStyle="gameText"
              color="primary.medium-gray"
              fontSize={pxToRem(currentBreakpoints.fontSize)}
            >
              {t("classifyDropZone.dropZonePlaceholder")}
            </Text>
          </Box>
        )}
      </Card>
    </DroppableZone>
  );
};
