const getGameHeaderSize = (size: string) => {
  switch (size) {
    case "xs":
      return "24px";
    case "sm":
      return "32px";
    case "md":
      return "40px";
    case "lg":
      return "48px";
    case "xl":
      return "64px";
    case "2xl":
      return "80px";
    case "3xl":
      return "96px";
  }
};

export default {
  variants: {
    gameHeaderBold: (props: {
      [key: string]: string;
    }): Record<string, unknown> => ({
      fontFamily: "Gooper",
      fontWeight: "normal",
      fontSize: () => getGameHeaderSize(props.size),
    }),
    gameHeaderText: (props: {
      [key: string]: string;
    }): Record<string, unknown> => ({
      fontFamily: "TTCommons",
      fontWeight: "normal",
      fontSize: () => getGameHeaderSize(props.size),
    }),
  },
};
