import { pxToRem } from "adminComponents/utils/pxToRem";

export const AdminRarityScale = {
  baseStyle: ({
    totalLevels,
  }: {
    totalLevels: number;
  }): Record<string, unknown> => ({
    bar: {
      bg: "primary.light-gray",
      width: `${100 / totalLevels}%`,
      height: pxToRem(25),
      border: "1px solid",
      borderColor: "primary.white",

      "&:first-child": {
        borderLeftRadius: pxToRem(50),
      },

      "&:last-child": {
        borderRightRadius: pxToRem(50),
      },
    },
  }),
};
