import {
  ThemeTypings,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useMemo, useState } from "react";

import { EditAvatarModal } from "adminComponents/organisms/EditAvatarModal";
import { pxToRem } from "adminComponents/utils";
import {
  CameraTypes,
  useLockerAvatarCamera,
} from "lib/hooks/useLockerAvatarCamera";
import { useUpdateProfileImage } from "lib/hooks/useUpdateProfileImage";
import { useAuth } from "links/lib/features/auth";
import {
  useDeequipAvatarItem,
  useEquipAvatarItem,
  useFetchAvatar,
  useMutateUserAvatarSettings,
} from "links/lib/features/avatars";
import { AvatarSkeletonType, IFetchAvatarResponse } from "links/lib/types";
import {
  AvatarStage,
  AvatarStageMember,
} from "sharedComponents/molecules/AvatarStage";

export interface IArgs {
  useModal?: boolean;
  modalSize?: ThemeTypings["components"]["Modal"]["sizes"];
  refetchAvatarOnWindowFocus?: boolean;
  onEquipAvatarItem?: (
    avatarItemId: string,
    categoryId: string | undefined,
    colorHex: string | undefined
  ) => void;
  onDequipAvatarItem?: (
    avatarItemId: string,
    categoryId: string | undefined
  ) => void;
  onSetSkeletonType?: (skeletonType: AvatarSkeletonType) => void;
  onSetHairColor?: (hairColorHexCode: string) => void;
  onSetSkinTone?: (skinToneHexCode: string) => void;
  onClose?: () => void;
  onDone?: () => void;
}

export interface IResult {
  modal: React.ReactElement;
  profileImageCapture: React.ReactElement;
  isAvatarLoading: boolean;
  onOpen: () => void;
}

export const useEditAvatar = ({
  useModal = false,
  modalSize = "full",
  refetchAvatarOnWindowFocus = false,
  onEquipAvatarItem,
  onDequipAvatarItem,
  onSetSkeletonType,
  onSetHairColor,
  onSetSkinTone,
  onClose,
  onDone,
}: IArgs): IResult => {
  const { authUser } = useAuth();
  const isMobile = useBreakpointValue({ base: true, lg: false });

  const [avatarEditSectionId, setAvatarEditSectionId] = useState<string>();
  const {
    isOpen: isEditAvatarModalOpen,
    onOpen: onOpenEditAvatarModal,
    onClose: onCloseEditAvatarModal,
  } = useDisclosure();
  const { data: avatarResponse, isLoading: isAvatarLoading } = useFetchAvatar({
    include_items: true,
    refetchOnWindowFocus: refetchAvatarOnWindowFocus,
  });
  const equipAvatarItem = useEquipAvatarItem();
  const deequipAvatarItem = useDeequipAvatarItem();
  const avatarSettings = useMutateUserAvatarSettings();

  const _onEquipAvatarItem = (
    avatarItemId: string,
    categoryId: string | undefined,
    colorHex: string | undefined
  ) => {
    if (!authUser || !avatarResponse?.avatar) return;

    const color = avatarResponse?.avatar.items
      .find((item) => item.id === avatarItemId)
      ?.colors.find((color) => color.hex_code === colorHex);

    equipAvatarItem.mutate({
      user_id: authUser.id,
      avatar_item_id: avatarItemId,
      avatar_item_color_id: color?.id,
    });

    onEquipAvatarItem?.(avatarItemId, categoryId, colorHex);
  };

  const _onDeequipAvatarItem = (
    avatarItemId: string,
    categoryId: string | undefined
  ) => {
    if (!authUser) return;
    if (!categoryId) return;

    deequipAvatarItem.mutate({
      user_id: authUser.id,
      avatar_item_category_id: categoryId,
    });

    onDequipAvatarItem?.(avatarItemId, categoryId);
  };

  const _setSkeletonType = (skeletonType: AvatarSkeletonType) => {
    if (!authUser) return;
    avatarSettings.mutate({
      user_id: authUser.id,
      skeleton_type: skeletonType,
    });

    onSetSkeletonType?.(skeletonType);
  };

  const _setHairColor = (hairColorHexCode: string) => {
    if (!authUser) return;
    avatarSettings.mutate({
      user_id: authUser.id,
      hair_color_hex_code: hairColorHexCode,
    });

    onSetHairColor?.(hairColorHexCode);
  };

  const _setSkinTone = (skinToneHexCode: string) => {
    if (!authUser) return;
    avatarSettings.mutate({
      user_id: authUser.id,
      skin_tone_hex_code: skinToneHexCode,
    });

    onSetSkinTone?.(skinToneHexCode);
  };

  const _handleDone = () => {
    onDone?.();
    onCloseEditAvatarModal();
  };

  const _handleClose = () => {
    onClose?.();
    onCloseEditAvatarModal();
  };

  const onAvatarEditSectionIdChange = (sectionId: string) => {
    setAvatarEditSectionId(sectionId);
  };

  const avatarStageSize =
    useBreakpointValue({ base: 164, md: 256, lg: 516 }) || 516;

  const isAvatarSeated =
    avatarResponse?.avatar?.skeleton_type === AvatarSkeletonType.Seated;

  const modalStageCamera = useLockerAvatarCamera({
    isSeated: isAvatarSeated,
    editSectionId: avatarEditSectionId,
  });

  const lockerCameraStanding = { ...CameraTypes.StandingDefault, zoom: 0.8 };
  const lockerCameraSeated = { ...CameraTypes.SeatedDefault, zoom: 0.8 };

  const avatarAnimation = useMemo(() => ({ name: "idle", loop: true }), []);

  const profileImageCapture = useUpdateProfileImage({ avatarResponse });

  const getAvatarPreviewState = (
    avatarResponse: IFetchAvatarResponse,
    isModalPreview = true
  ) => {
    const remAvatarStageSize = pxToRem(avatarStageSize);
    const remAvatarStageOffset = pxToRem(-avatarStageSize / 2);

    const baseStyle = {
      width: remAvatarStageSize,
      maxWidth: remAvatarStageSize,
      maxHeight: remAvatarStageSize,
      background: "white",
    };

    const modalStyle = {
      position: "absolute",
      top: "0",
      left: "0",
      marginLeft: "50%",
      marginTop: "50%",
      minWidth: remAvatarStageSize,
      height: remAvatarStageSize,
      transform: `translateX(${remAvatarStageOffset}) translateY(${remAvatarStageOffset})`,
    };

    const style = isModalPreview ? { ...baseStyle, ...modalStyle } : baseStyle;
    const camera = isModalPreview
      ? modalStageCamera
      : isAvatarSeated
      ? lockerCameraSeated
      : lockerCameraStanding;

    if (!avatarResponse.spine_atlas_url || !avatarResponse.spine_json_url)
      return <></>;

    return (
      <AvatarStage
        camera={camera}
        alignX="center"
        alignY="center"
        style={style}
        stageSize={{ x: 600, y: 600 }}
      >
        <AvatarStageMember
          key={avatarResponse.spine_atlas_url}
          animation={avatarAnimation}
          scale={0.4}
          x={0}
          y={-160}
          atlasUrl={avatarResponse.spine_atlas_url}
          skeletonUrl={avatarResponse.spine_json_url}
        />
      </AvatarStage>
    );
  };

  const modal =
    avatarResponse && avatarResponse.avatar ? (
      <EditAvatarModal
        useModal={useModal}
        modalSize={modalSize}
        isSaving={
          avatarSettings.isLoading ||
          equipAvatarItem.isLoading ||
          deequipAvatarItem.isLoading
        }
        isOpen={isEditAvatarModalOpen}
        isMobile={!!isMobile}
        handleClose={_handleClose}
        avatarPreviewStage={getAvatarPreviewState(avatarResponse)}
        avatar={avatarResponse.avatar}
        handleBack={onCloseEditAvatarModal}
        handleDone={_handleDone}
        handleSelectAvatarType={_setSkeletonType}
        handleSkinToneColorChange={_setSkinTone}
        handleHairColorChange={_setHairColor}
        handleEquip={(avatarItemId, categoryId, colorHex) => {
          _onEquipAvatarItem(avatarItemId, categoryId, colorHex);
        }}
        handleDeequip={(avatarItemId, categoryId) => {
          _onDeequipAvatarItem(avatarItemId, categoryId);
        }}
        handleSectionIdChange={onAvatarEditSectionIdChange}
      />
    ) : (
      <></>
    );

  return {
    modal,
    profileImageCapture,
    onOpen: onOpenEditAvatarModal,
    isAvatarLoading,
  };
};
