import { Box, Portal, VStack } from "@chakra-ui/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { animated, config, useSpring } from "react-spring";
import { useMount } from "react-use";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { AvatarAnimations } from "links/lib/constants";
import { useAuth } from "links/lib/features/auth";
import { useFetchSeasonLevelsOnce } from "links/lib/features/season";
import { AnalyticsEvent, SessionGameType } from "links/lib/types";
import AppSpinner from "screens/App/components/AppSpinner";
import { Button } from "sessionComponents/atoms/Button";
import { Card } from "sessionComponents/atoms/Card";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { useStudent } from "sessionComponents/contexts/student";
import { AvatarLayout } from "sessionComponents/molecules/AvatarLayout";
import { CarePackageDelivery } from "sessionComponents/molecules/CarePackageDelivery";
import { LobbyLayout } from "sessionComponents/molecules/LobbyLayout";
import { PodiumCarePackage } from "sessionComponents/molecules/PodiumCarePackage";
import { PodiumChallenge } from "sessionComponents/molecules/PodiumChallenge";
import { PodiumStreak } from "sessionComponents/molecules/PodiumStreak";
import { PodiumSummary } from "sessionComponents/molecules/PodiumSummary";
import { PodiumXPBoost } from "sessionComponents/molecules/PodiumXPBoost";
import { useDetectOrientation } from "sessionComponents/theme/hooks/useDetectOrientation";
import { useCarePackage } from "sharedComponents/contexts/carePackage";
import { useSessionAnalytics } from "sharedComponents/contexts/sessionAnalytics";

interface IPodiumSection {
  handleMount?: () => void;
  component: React.ReactElement;
  continueText: string;
  handleContinue: () => void;
}

interface IStudentFinalPodiumViewProps {
  outerGame?: SessionGameType;
}

const loopingVictoryAnimations = AvatarAnimations.Victory.filter((a) => a.loop);

//todo implement suspense in async calls and implement here
const AnimatedButton = animated(Button);

export const StudentFinalPodiumView: React.FC<IStudentFinalPodiumViewProps> = ({
  outerGame = SessionGameType.TheBigBoard,
}) => {
  const location = useLocation() as { state: { redirectToSeasonMap: boolean } };
  const { refetchUser } = useAuth();
  const { isPortrait } = useDetectOrientation();
  const { match: currentBreakpoints } = useBreakpoints();
  const studentUser = useStudent();
  const fetchSeasonLevels = useFetchSeasonLevelsOnce();
  const { requestItems, clearItems } = useCarePackage();
  const [sectionIndex, setSectionIndex] = useState<number>(0);
  // Whether the care package button should be show
  const [showContinue, setShowContinue] = useState<boolean>(false);
  // Whether the care package delivery splash should be shown
  const [showCarePackages, setShowCarePackages] = useState<boolean>(false);
  const history = useHistory();
  const { t } = useTranslation("session", {
    useSuspense: false,
    keyPrefix: "podium",
  });
  const { track } = useSessionAnalytics();

  useMount(() => {
    fetchSeasonLevels.execute();
    // refetch user to fetch latest student XP before we return to dashboard
    refetchUser();
  });

  const carePackageItems = useMemo(() => {
    return requestItems();
  }, [requestItems]);

  const continueBtnSpring = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: showContinue ? 1 : 0,
    },
    config: config.stiff,
  });

  const handleMoveSection = useCallback(() => {
    setShowContinue(false);
    setSectionIndex((val) => val + 1);
  }, []);

  const sections: Array<IPodiumSection> = useMemo(() => {
    const _sections: Array<IPodiumSection> = [];

    // XP Boost section
    if (studentUser.user_xp_multiplier) {
      _sections.push({
        component: (
          <Box w="full" h="full">
            <PodiumXPBoost
              userXPMultiplier={studentUser.user_xp_multiplier}
              handleComplete={() => setShowContinue(true)}
            />
          </Box>
        ),
        continueText: t("xpBoost.continueButton"),
        handleContinue: handleMoveSection,
      });
    }

    // Streak section
    if (
      studentUser.initial_streak_days !== studentUser.final_streak_days &&
      (studentUser.final_streak_days === 1 ||
        studentUser.final_streak_days === 2 ||
        studentUser.final_streak_days === 3)
    ) {
      _sections.push({
        component: (
          <Box w="full" h="full">
            <PodiumStreak
              dayStreakCount={studentUser.final_streak_days}
              weekStreakCount={studentUser.final_streak_weeks ?? 0}
              studentName={studentUser.name}
              handleComplete={() => setShowContinue(true)}
            />
          </Box>
        ),
        continueText: t("streak.continueButton"),
        handleContinue: handleMoveSection,
      });
    }

    // Challenge sections
    (studentUser.completed_challenges ?? []).forEach((challenge) => {
      _sections.push({
        component: (
          <Box w="full" h="full">
            <PodiumChallenge
              challengeType={challenge.challenge_type}
              xpEarned={challenge.awarded_xp ?? 0}
              handleComplete={() => setShowContinue(true)}
            />
          </Box>
        ),
        continueText: t("challenge.continueButton"),
        handleContinue: handleMoveSection,
      });
    });

    // Care package section
    if (carePackageItems.length > 0) {
      _sections.push({
        handleMount: () => {
          setShowContinue(true);
        },
        component: (
          <Box w="full" h="full">
            <PodiumCarePackage carePackages={carePackageItems} />
          </Box>
        ),
        continueText: t("carePackage.continueButton", {
          count: carePackageItems.length,
        }),
        handleContinue: () => {
          setShowCarePackages(true);
        },
      });
    }

    // Final summary section
    _sections.push({
      component: (
        <Box w="full" h="fit-content">
          <PodiumSummary
            student={studentUser}
            seasonLevels={fetchSeasonLevels.data?.levels || []}
            practiceXP={studentUser.session_xp_items ?? 0}
            bonusXP={studentUser.session_xp_challenge_completion ?? 0}
            accuracyXP={studentUser.session_xp_accuracy_bonus ?? 0}
            practiceAccuracy={studentUser.session_accuracy ?? 0}
            handleComplete={() => setShowContinue(true)}
          />
        </Box>
      ),
      continueText: !studentUser.is_guest
        ? t("summary.continueButton")
        : t("summary.continueButtonGuest"),
      handleContinue: () => {
        if (studentUser.is_guest) {
          history.push("/u/home");
        } else {
          if (location.state?.redirectToSeasonMap) {
            history.push("/s/season-map");
          } else {
            history.push("/");
          }
        }
      },
    });

    return _sections;
  }, [
    studentUser,
    carePackageItems,
    fetchSeasonLevels.data?.levels,
    t,
    handleMoveSection,
    history,
    location.state?.redirectToSeasonMap,
  ]);

  const section = sections[sectionIndex];

  useEffect(() => {
    section.handleMount?.();
  }, [section]);

  const handleCarePackagesComplete = useCallback(() => {
    setShowCarePackages(false);
    handleMoveSection();

    carePackageItems.forEach((item) => {
      track(AnalyticsEvent.Session_Common_CarePackageDelivered, {
        itemId: item.id,
      });
    });

    clearItems();
  }, [handleMoveSection, clearItems, track, carePackageItems]);

  if (!fetchSeasonLevels.data) {
    return <AppSpinner />;
  }

  return (
    <>
      <LobbyLayout
        avatarComponent={
          isPortrait ? undefined : (
            <AvatarLayout
              students={[studentUser]}
              selfId={studentUser.id}
              candidateAnimations={loopingVictoryAnimations}
              showNametags={false}
            />
          )
        }
        actionContent={
          <VStack w="full" h="full" spacing="0" zIndex="2">
            <Card
              useThickBorder={isPortrait}
              h="full"
              w="full"
              margin={isPortrait ? pxToRem(currentBreakpoints.margin) : 0}
              borderRadius={isPortrait ? undefined : "0px"}
              borderTopWidth={isPortrait ? undefined : 0}
              borderBottomWidth={isPortrait ? undefined : 0}
              overflowX="hidden"
              overflowY="auto"
            >
              {section.component}
            </Card>

            <Box
              w="full"
              bgColor="primary.warm-white"
              p={pxToRem(currentBreakpoints.padding)}
              borderTopWidth={pxToRem(currentBreakpoints.borderWidth)}
              borderColor="primary.tan"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {showContinue && (
                <AnimatedButton
                  w="full"
                  variant="buttonFilled"
                  h={pxToRem(currentBreakpoints.buttonHeight)}
                  fontSize={pxToRem(currentBreakpoints.fontSize)}
                  onClick={section.handleContinue}
                  style={continueBtnSpring}
                >
                  {section.continueText}
                </AnimatedButton>
              )}
            </Box>
          </VStack>
        }
        stageProps={{
          showCurtainOnly: isPortrait,
          outerGame,
        }}
      />
      {carePackageItems.length > 0 && (
        <Portal>
          <CarePackageDelivery
            show={showCarePackages}
            items={carePackageItems}
            handleComplete={handleCarePackagesComplete}
          />
        </Portal>
      )}
    </>
  );
};
