import { useMemo } from "react";

import { generateStudentName } from "adminComponents/utils";
import { useErrorToast } from "adminComponents/utils/toast";
import { useFetchPracticeSetItemAttemptsCount } from "links/lib/features/classrooms";
import useFetchClassroomRecentPracticeSetResponses from "links/lib/features/classrooms/useFetchClassroomRecentPracticeSetResponses";
import { IFetchPracticeSetItemsResponse } from "links/lib/features/practiceSetItems/useFetchPracticeSetItems";
import { IPracticeSetItemWithClassroomResults, IUser } from "links/lib/types";
import { useQuestionsData } from "screens/TeacherClassrooms/shared/hooks/useQuestionsData";

interface IUseStudentQuestionsData {
  practiceSetItemsData?: IFetchPracticeSetItemsResponse;
  classroomId: string;
  practiceSetId: string;
  student?: IUser;
  assignmentId?: string;
  practiceSetSessionId?: string;
  includeAllSessions?: boolean;
}

interface IUseStudentQuestionDataResponse {
  questionsData?: IPracticeSetItemWithClassroomResults[];
  isLoading: boolean;
}

export const useStudentQuestionsData = ({
  classroomId,
  practiceSetId,
  practiceSetItemsData,
  student,
  assignmentId,
  practiceSetSessionId,
  includeAllSessions = false,
}: IUseStudentQuestionsData): IUseStudentQuestionDataResponse => {
  const recentResponsesFetch = useFetchClassroomRecentPracticeSetResponses({
    classroom_id: classroomId,
    practice_set_id: practiceSetId,
    user_id: student?.id,
    assignment_id: assignmentId,
    practice_set_session_id: practiceSetSessionId,
    include_all_sessions: includeAllSessions,
  });

  const { isLoading, questionsData } = useQuestionsData({
    classroomId,
    practiceSetId,
    classroomPracticeSetResponses: recentResponsesFetch.data,
    practiceSetItemsData,
    studentId: student?.id,
    assignmentId,
    practiceSetSessionId,
  });

  const attemptsFetch = useFetchPracticeSetItemAttemptsCount({
    classroom_id: classroomId,
    user_id: student?.id,
    practice_set_id: practiceSetId,
    assignment_id: assignmentId,
    practice_set_session_id: practiceSetSessionId,
    // Disable attempts fetch if this is a specific session context (where we show one attempt; the most recent)
    // enabled: practiceSetSessionId === undefined,
  });

  useErrorToast(attemptsFetch.error);
  useErrorToast(recentResponsesFetch.error);

  const studentName = generateStudentName(student || {}).primary;

  const attemptsMap = useMemo(() => {
    const attemptsMap = new Map<string, number>();
    attemptsFetch.data?.practice_set_item_attempts.forEach((attempt) => {
      attemptsMap.set(attempt.practice_set_item_id, attempt.attempts);
    });
    return attemptsMap;
  }, [attemptsFetch.data]);

  const studentQuestionsData = useMemo(() => {
    return questionsData.map(
      (question): IPracticeSetItemWithClassroomResults => {
        const attempts =
          attemptsMap.get(question.classroomResults.practice_set_item_id) || 0;

        return {
          practiceSetItem: question.practiceSetItem,
          classroomResults: {
            ...question.classroomResults,
            attempts,
            responses: question.classroomResults.responses.map((response) => ({
              ...response,
              student_name: studentName,
            })),
          },
        };
      }
    );
  }, [questionsData, attemptsMap, studentName]);

  return {
    questionsData: studentQuestionsData,
    isLoading: isLoading && attemptsFetch.isLoading,
  };
};
