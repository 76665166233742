import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

import { config } from "links/lib/constants";

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(
    new HttpApi(null, {
      loadPath: `${config.translationsPathPrefix}/locales/{{lng}}/{{ns}}.json?${config.gitTag}`, // Git tag is for cache busting
    })
  )
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // Replace branding in copy.
  // TODO: update translations post-cut over
  .use({
    type: "postProcessor",
    name: "branding",
    process: (value: string) => {
      return value
        .replaceAll("Giant Steps’ ", "Pear Practice's ")
        .replaceAll("Giant Steps' ", "Pear Practice's ")
        .replaceAll("Giant Steps", "Pear Practice");
    },
  })
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: "en",
    debug: process.env.NODE_ENV !== "production",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    postProcess: ["branding"],
  });

export default i18n;
