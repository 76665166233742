import { useTranslation } from "react-i18next";

import Shape2D1 from "adminComponents/molecules/ImageLibrary/resource/2D_shapes/1.png";
import Shape2D2 from "adminComponents/molecules/ImageLibrary/resource/2D_shapes/2.png";
import Shape2D3 from "adminComponents/molecules/ImageLibrary/resource/2D_shapes/3.png";
import Shape2D4 from "adminComponents/molecules/ImageLibrary/resource/2D_shapes/4.png";
import Shape2D5 from "adminComponents/molecules/ImageLibrary/resource/2D_shapes/5.png";
import Shape2D6 from "adminComponents/molecules/ImageLibrary/resource/2D_shapes/6.png";
import Shape2D7 from "adminComponents/molecules/ImageLibrary/resource/2D_shapes/7.png";
import Shape2D8 from "adminComponents/molecules/ImageLibrary/resource/2D_shapes/8.png";
import Shape2D9 from "adminComponents/molecules/ImageLibrary/resource/2D_shapes/9.png";
import Shape2D10 from "adminComponents/molecules/ImageLibrary/resource/2D_shapes/10.png";
import Shape2D11 from "adminComponents/molecules/ImageLibrary/resource/2D_shapes/11.png";
import Shape2D12 from "adminComponents/molecules/ImageLibrary/resource/2D_shapes/12.png";
import Shape2D13 from "adminComponents/molecules/ImageLibrary/resource/2D_shapes/13.png";
import Shape2D14 from "adminComponents/molecules/ImageLibrary/resource/2D_shapes/14.png";
import Shape2D15 from "adminComponents/molecules/ImageLibrary/resource/2D_shapes/15.png";
import Shape2D16 from "adminComponents/molecules/ImageLibrary/resource/2D_shapes/16.png";
import Shape2D17 from "adminComponents/molecules/ImageLibrary/resource/2D_shapes/17.png";
import Shape2D18 from "adminComponents/molecules/ImageLibrary/resource/2D_shapes/18.png";
import Shape2D19 from "adminComponents/molecules/ImageLibrary/resource/2D_shapes/19.png";
import Shape2D20 from "adminComponents/molecules/ImageLibrary/resource/2D_shapes/20.png";
import Shape2D21 from "adminComponents/molecules/ImageLibrary/resource/2D_shapes/21.png";
import Shape2D22 from "adminComponents/molecules/ImageLibrary/resource/2D_shapes/22.png";
import Shape2D23 from "adminComponents/molecules/ImageLibrary/resource/2D_shapes/23.png";
import Shape2D24 from "adminComponents/molecules/ImageLibrary/resource/2D_shapes/24.png";
import Shape3D1 from "adminComponents/molecules/ImageLibrary/resource/3D_shapes/1.png";
import Shape3D2 from "adminComponents/molecules/ImageLibrary/resource/3D_shapes/2.png";
import Shape3D3 from "adminComponents/molecules/ImageLibrary/resource/3D_shapes/3.png";
import Shape3D4 from "adminComponents/molecules/ImageLibrary/resource/3D_shapes/4.png";
import Shape3D5 from "adminComponents/molecules/ImageLibrary/resource/3D_shapes/5.png";
import Shape3D6 from "adminComponents/molecules/ImageLibrary/resource/3D_shapes/6.png";
import Shape3D7 from "adminComponents/molecules/ImageLibrary/resource/3D_shapes/7.png";
import Shape3D8 from "adminComponents/molecules/ImageLibrary/resource/3D_shapes/8.png";
import Shape3D9 from "adminComponents/molecules/ImageLibrary/resource/3D_shapes/9.png";
import Shape3D10 from "adminComponents/molecules/ImageLibrary/resource/3D_shapes/10.png";
import Shape3D11 from "adminComponents/molecules/ImageLibrary/resource/3D_shapes/11.png";
import Shape3D12 from "adminComponents/molecules/ImageLibrary/resource/3D_shapes/12.png";
import Shape3D13 from "adminComponents/molecules/ImageLibrary/resource/3D_shapes/13.png";
import Shape3D14 from "adminComponents/molecules/ImageLibrary/resource/3D_shapes/14.png";
import Shape3D15 from "adminComponents/molecules/ImageLibrary/resource/3D_shapes/15.png";
import Shape3D16 from "adminComponents/molecules/ImageLibrary/resource/3D_shapes/16.png";
import Shape3D17 from "adminComponents/molecules/ImageLibrary/resource/3D_shapes/17.png";
import Shape3D18 from "adminComponents/molecules/ImageLibrary/resource/3D_shapes/18.png";
import AreaModel1 from "adminComponents/molecules/ImageLibrary/resource/area_models/1.png";
import AreaModel2 from "adminComponents/molecules/ImageLibrary/resource/area_models/2.png";
import AreaModel3 from "adminComponents/molecules/ImageLibrary/resource/area_models/3.png";
import AreaModel4 from "adminComponents/molecules/ImageLibrary/resource/area_models/4.png";
import AreaModel5 from "adminComponents/molecules/ImageLibrary/resource/area_models/5.png";
import CoordinatePlane1 from "adminComponents/molecules/ImageLibrary/resource/coordinate_plane/1.png";
import CoordinatePlane2 from "adminComponents/molecules/ImageLibrary/resource/coordinate_plane/2.png";
import CoordinatePlane3 from "adminComponents/molecules/ImageLibrary/resource/coordinate_plane/3.png";
import CoordinatePlane4 from "adminComponents/molecules/ImageLibrary/resource/coordinate_plane/4.png";
import CoordinatePlane5 from "adminComponents/molecules/ImageLibrary/resource/coordinate_plane/5.png";
import CoordinatePlane6 from "adminComponents/molecules/ImageLibrary/resource/coordinate_plane/6.png";
import CoordinatePlane7 from "adminComponents/molecules/ImageLibrary/resource/coordinate_plane/7.png";
import CoordinatePlane8 from "adminComponents/molecules/ImageLibrary/resource/coordinate_plane/8.png";
import CoordinatePlane9 from "adminComponents/molecules/ImageLibrary/resource/coordinate_plane/9.png";
import CoordinatePlane10 from "adminComponents/molecules/ImageLibrary/resource/coordinate_plane/10.png";
import CoordinatePlane11 from "adminComponents/molecules/ImageLibrary/resource/coordinate_plane/11.png";
import CoordinatePlane12 from "adminComponents/molecules/ImageLibrary/resource/coordinate_plane/12.png";
import CoordinatePlane13 from "adminComponents/molecules/ImageLibrary/resource/coordinate_plane/13.png";
import CoordinatePlane14 from "adminComponents/molecules/ImageLibrary/resource/coordinate_plane/14.png";
import CoordinatePlane15 from "adminComponents/molecules/ImageLibrary/resource/coordinate_plane/15.png";
import Grid1 from "adminComponents/molecules/ImageLibrary/resource/grids/1.png";
import Grid2 from "adminComponents/molecules/ImageLibrary/resource/grids/2.png";
import Grid3 from "adminComponents/molecules/ImageLibrary/resource/grids/3.png";
import Grid4 from "adminComponents/molecules/ImageLibrary/resource/grids/4.png";
import Grid5 from "adminComponents/molecules/ImageLibrary/resource/grids/5.png";
import Grid6 from "adminComponents/molecules/ImageLibrary/resource/grids/6.png";
import Grid7 from "adminComponents/molecules/ImageLibrary/resource/grids/7.png";
import NumberLine1 from "adminComponents/molecules/ImageLibrary/resource/number_lines/1.png";
import NumberLine2 from "adminComponents/molecules/ImageLibrary/resource/number_lines/2.png";
import NumberLine3 from "adminComponents/molecules/ImageLibrary/resource/number_lines/3.png";
import NumberLine4 from "adminComponents/molecules/ImageLibrary/resource/number_lines/4.png";
import NumberLine5 from "adminComponents/molecules/ImageLibrary/resource/number_lines/5.png";
import NumberLine6 from "adminComponents/molecules/ImageLibrary/resource/number_lines/6.png";
import NumberLine7 from "adminComponents/molecules/ImageLibrary/resource/number_lines/7.png";
import NumberLine8 from "adminComponents/molecules/ImageLibrary/resource/number_lines/8.png";
import NumberLine9 from "adminComponents/molecules/ImageLibrary/resource/number_lines/9.png";
import NumberLine10 from "adminComponents/molecules/ImageLibrary/resource/number_lines/10.png";
import NumberLine11 from "adminComponents/molecules/ImageLibrary/resource/number_lines/11.png";
import NumberLine12 from "adminComponents/molecules/ImageLibrary/resource/number_lines/12.png";
import Table1 from "adminComponents/molecules/ImageLibrary/resource/tables/1.png";
import Table2 from "adminComponents/molecules/ImageLibrary/resource/tables/2.png";
import Table3 from "adminComponents/molecules/ImageLibrary/resource/tables/3.png";
import Table4 from "adminComponents/molecules/ImageLibrary/resource/tables/4.png";
import Table5 from "adminComponents/molecules/ImageLibrary/resource/tables/5.png";
import Table6 from "adminComponents/molecules/ImageLibrary/resource/tables/6.png";
import Table7 from "adminComponents/molecules/ImageLibrary/resource/tables/7.png";
import Table8 from "adminComponents/molecules/ImageLibrary/resource/tables/8.png";

export const useImages = (): Map<string, string[]> => {
  const images = new Map<string, string[]>();

  const shapes2D = [
    Shape2D1,
    Shape2D2,
    Shape2D3,
    Shape2D4,
    Shape2D5,
    Shape2D6,
    Shape2D7,
    Shape2D8,
    Shape2D9,
    Shape2D10,
    Shape2D11,
    Shape2D12,
    Shape2D13,
    Shape2D14,
    Shape2D15,
    Shape2D16,
    Shape2D17,
    Shape2D18,
    Shape2D19,
    Shape2D20,
    Shape2D21,
    Shape2D22,
    Shape2D23,
    Shape2D24,
  ];

  const shapes3D = [
    Shape3D1,
    Shape3D2,
    Shape3D3,
    Shape3D4,
    Shape3D5,
    Shape3D6,
    Shape3D7,
    Shape3D8,
    Shape3D9,
    Shape3D10,
    Shape3D11,
    Shape3D12,
    Shape3D13,
    Shape3D14,
    Shape3D15,
    Shape3D16,
    Shape3D17,
    Shape3D18,
  ];

  const areaModels = [
    AreaModel1,
    AreaModel2,
    AreaModel3,
    AreaModel4,
    AreaModel5,
  ];

  const coordinatePlanes = [
    CoordinatePlane1,
    CoordinatePlane2,
    CoordinatePlane3,
    CoordinatePlane4,
    CoordinatePlane5,
    CoordinatePlane6,
    CoordinatePlane7,
    CoordinatePlane8,
    CoordinatePlane9,
    CoordinatePlane10,
    CoordinatePlane11,
    CoordinatePlane12,
    CoordinatePlane13,
    CoordinatePlane14,
    CoordinatePlane15,
  ];

  const grids = [Grid1, Grid2, Grid3, Grid4, Grid5, Grid6, Grid7];

  const numberLines = [
    NumberLine1,
    NumberLine2,
    NumberLine3,
    NumberLine4,
    NumberLine5,
    NumberLine6,
    NumberLine7,
    NumberLine8,
    NumberLine9,
    NumberLine10,
    NumberLine11,
    NumberLine12,
  ];

  const tables = [
    Table1,
    Table2,
    Table3,
    Table4,
    Table5,
    Table6,
    Table7,
    Table8,
  ];

  const { t } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "imageLibrary",
  });

  images.set(t("shapes"), [...shapes2D, ...shapes3D]);
  images.set(t("planes"), [...coordinatePlanes, ...grids, ...areaModels]);
  images.set(t("numberLines"), numberLines);
  images.set(t("tables"), tables);

  return images;
};
