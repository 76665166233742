import { Box, useBreakpointValue } from "@chakra-ui/react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { AvatarAnimations } from "links/lib/constants";
import {
  IAvatar,
  ISeasonLocationAvatarItem,
  ISeasonLocationAvatarItemGroup,
} from "links/lib/types";
import { getRandomArrayElement } from "links/lib/util";

import { AvatarStage, AvatarStageMember } from "../AvatarStage";
import {
  CombinedItem,
  SeasonLocationProgress,
} from "../SeasonLocationProgress";

interface ISeasonLocationProps {
  width: number;
  height: number;
  avatarItems: Array<ISeasonLocationAvatarItem>;
  avatarItemGroups: Array<ISeasonLocationAvatarItemGroup>;
  bgSVG: string;
  pathColor: string;
  handleRequestUnlock?: (item: CombinedItem) => void;
  isLoading: boolean;
  canUnlock: boolean;
  avatarAtlasUrl: string;
  avatarSkeletonUrl: string;
  avatar: IAvatar;
  avatarScaleConstant?: number;
  avatarOffsetYRatioConstant?: number;
  roadColor?: string;
}

export const SeasonLocation: React.FC<ISeasonLocationProps> = ({
  width,
  height,
  avatarItems,
  avatarItemGroups,
  pathColor,
  bgSVG,
  handleRequestUnlock,
  isLoading,
  canUnlock,
  avatarAtlasUrl,
  avatarSkeletonUrl,
  avatar,
  avatarScaleConstant = 2500,
  avatarOffsetYRatioConstant = 0.065,
  roadColor = "#354636",
}) => {
  const isDesktop = !!useBreakpointValue({ base: false, lg: true });
  const roadHeight = useBreakpointValue({ base: 0, sm: 0, lg: 200 }) || 600;
  const bgHeight = useBreakpointValue({ base: 200, md: 300, lg: 0 }) || 0;
  const [progressSize, setProgressSize] = useState(0);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const currentDrag = useRef<{
    startClick: {
      x: number;
      y: number;
    };
    startScroll: {
      top: number;
      left: number;
    };
  } | null>(null);

  const bgImageHeight = isDesktop ? height - roadHeight : bgHeight;

  const stageSize = useMemo(() => {
    return {
      x: width,
      y: bgImageHeight,
    };
  }, [width, bgImageHeight]);

  const camera = useMemo(() => {
    return {
      x: 0,
      y: 0,
      z: 0,
      zoom: 1,
    };
  }, []);

  const avatarAnim = useMemo(() => {
    return getRandomArrayElement(AvatarAnimations.Idle);
  }, []);

  const stageStyle: React.CSSProperties = useMemo(() => {
    return {
      width: pxToRem(width),
      height: pxToRem(bgImageHeight),
      position: "fixed",
      top: "0",
      left: "0",
    };
  }, [width, bgImageHeight]);

  const handleSizeUpdate = useCallback(
    (progressSize, lastUnlockedPos: number, nextUnlockedPos?: number) => {
      setProgressSize(progressSize);

      if (!scrollContainerRef.current) return;

      const targetPos =
        typeof nextUnlockedPos !== "undefined"
          ? nextUnlockedPos
          : lastUnlockedPos;

      // center last unlocked pos
      scrollContainerRef.current.scrollLeft = targetPos - width / 2;
    },
    [width]
  );

  const handleMouseDown = useCallback((e: React.MouseEvent) => {
    e.preventDefault();

    if (!scrollContainerRef.current) return;

    if (
      document.activeElement &&
      "blur" in document.activeElement &&
      typeof document.activeElement["blur"] === "function"
    ) {
      (document.activeElement as unknown as { blur: () => void }).blur();
    }

    setIsDragging(true);

    currentDrag.current = {
      startClick: {
        x: e.clientX,
        y: e.clientY,
      },
      startScroll: {
        top: scrollContainerRef.current.scrollTop,
        left: scrollContainerRef.current.scrollLeft,
      },
    };
  }, []);

  const handleMouseMove = useCallback((e: MouseEvent) => {
    e.preventDefault();

    if (!currentDrag.current || !scrollContainerRef.current) return;

    const deltaX = e.clientX - currentDrag.current.startClick.x;

    scrollContainerRef.current.scrollLeft =
      currentDrag.current.startScroll.left - deltaX;
  }, []);

  const handleMouseUp = useCallback((e: MouseEvent) => {
    e.preventDefault();

    setIsDragging(false);
    currentDrag.current = null;
  }, []);

  useEffect(() => {
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [handleMouseMove, handleMouseUp]);

  return (
    <Box
      w={pxToRem(width)}
      h={pxToRem(height)}
      position="relative"
      bg={roadColor}
      overflow="auto"
      overflowY="hidden"
      ref={scrollContainerRef}
    >
      <Box width={pxToRem(progressSize)} height={pxToRem(bgImageHeight)}>
        <Box
          width="full"
          height={pxToRem(bgImageHeight)}
          bgRepeat="no-repeat"
          bgSize="cover"
          bgPosition="left bottom"
          bgImage={bgSVG}
          position="fixed"
        />
      </Box>
      <AvatarStage
        style={stageStyle}
        stageSize={stageSize}
        camera={camera}
        alignX="start"
        alignY="start"
      >
        <AvatarStageMember
          animation={avatarAnim}
          scale={bgImageHeight / avatarScaleConstant}
          x={0}
          y={-stageSize.y / 2 + stageSize.y * avatarOffsetYRatioConstant}
          atlasUrl={avatarAtlasUrl}
          skeletonUrl={avatarSkeletonUrl}
        />
      </AvatarStage>
      <SeasonLocationProgress
        handleSizeUpdate={handleSizeUpdate}
        avatarItemGroups={avatarItemGroups}
        avatarItems={avatarItems}
        pathColor={pathColor}
        handleRequestUnlock={handleRequestUnlock}
        isLoading={isLoading}
        minWidth={width}
        canUnlock={canUnlock}
        avatar={avatar}
      />
      <Box
        w={progressSize}
        height="full"
        position="absolute"
        top={0}
        left={0}
        onMouseDown={handleMouseDown}
        cursor={isDragging ? "grabbing" : "grab"}
      />
    </Box>
  );
};
