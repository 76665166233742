import { Box } from "@chakra-ui/react";
import React from "react";
import { animated } from "react-spring";

import { fullScreenStyles } from "sessionComponents/theme";
import { useRoundReviewSplashAnimation } from "sharedComponents/hooks/useRoundReviewSplashAnimation";

const AnimatedBox = animated(Box);

interface IRoundReviewAnimatedTransitionProps {
  overflow?: string;
  splashLifetimeMs?: number;
  splashScene: React.ReactElement;
  canSkip?: boolean;
}

export const RoundReviewAnimatedTransition: React.FC<
  IRoundReviewAnimatedTransitionProps
> = ({
  overflow,
  splashLifetimeMs,
  splashScene,
  canSkip = false,
  children,
}) => {
  const { splashTransition, contentAtRest, skip } =
    useRoundReviewSplashAnimation(splashLifetimeMs, canSkip);
  return (
    <>
      {splashTransition((contentSpring, phase) =>
        phase === "splash" ? (
          <AnimatedBox
            {...fullScreenStyles}
            style={{
              opacity: contentSpring.opacity,
              transform: contentSpring.y.to((y) => `translateY(${y}px)`),
            }}
            onClick={skip}
          >
            {splashScene}
          </AnimatedBox>
        ) : (
          <AnimatedBox
            w="full"
            h="full"
            display="flex"
            flexDirection="column"
            overflow={overflow}
            position={contentAtRest ? "relative" : "fixed"}
            style={{
              opacity: contentSpring.opacity,
              transform: contentSpring.y.to((y) => `translateY(${y}px)`),
            }}
          >
            {children}
          </AnimatedBox>
        )
      )}
    </>
  );
};
