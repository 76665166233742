import { useMemo } from "react";
import { useQueries } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { IAssignmentUserAttempt } from "../../types";
import { fetchAssignmentUserAttemptsQuery } from "./useFetchAssignmentUserAttempts";

export interface IFetchAssignmentUserAttemptsBulkParams {
  assignment_ids?: Array<string>;
  group_id: string;
  user_id: string;
}

export interface IFetchAssignmentUserAttemptsBulkResponse {
  isLoading: boolean;
  assignmentUserAttemptsData: {
    [assignment_id: string]: Array<IAssignmentUserAttempt>;
  };
}

const useFetchAssignmentUserAttemptsBulk = ({
  assignment_ids = [],
  group_id,
  user_id,
}: IFetchAssignmentUserAttemptsBulkParams): IFetchAssignmentUserAttemptsBulkResponse => {
  const axios = useAxios();

  const userAttemptQueries = useQueries(
    assignment_ids.map((assignment_id) => {
      const params = {
        assignment_id,
        group_id,
        user_id,
      };

      return {
        queryKey: ["assignmentUserAttempts", { assignment_id, user_id }],
        queryFn: fetchAssignmentUserAttemptsQuery(axios, params),
      };
    })
  );

  const assignmentUserAttemptsData = useMemo(() => {
    const attemptsByAssignment: {
      [key: string]: Array<IAssignmentUserAttempt>;
    } = {};

    userAttemptQueries.forEach((q, i) => {
      attemptsByAssignment[assignment_ids[i]] = q.data?.user_attempts || [];
    });

    return attemptsByAssignment;
  }, [userAttemptQueries, assignment_ids]);

  return {
    isLoading: userAttemptQueries.some((q) => q.isLoading),
    assignmentUserAttemptsData,
  };
};

export default useFetchAssignmentUserAttemptsBulk;
