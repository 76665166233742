export default {
  sizes: {
    xs: {
      fontSize: ".75rem",
    },
    sm: {
      fontSize: "1rem",
    },
    md: {
      fontSize: "1.25rem",
    },
    lg: {
      fontSize: "1.5rem",
    },
    xl: {
      fontSize: "1.75rem",
    },
    "2xl": {
      fontSize: "2rem",
    },
    "3xl": {
      fontSize: "2.5rem",
    },
    "4xl": {
      fontSize: "3rem",
    },
  },
  variants: {
    gameLabel: {
      textStyle: "gameLabel",
    },
    gameParagraph: {
      textStyle: "gameParagraph",
    },
  },
};
