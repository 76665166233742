import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";

import { useErrorToast, useShowToast } from "adminComponents/utils/toast";
import { useAnalytics } from "lib/contexts/analytics";
import { config } from "links/lib/constants";
import {
  useFetchClassroomUsers,
  useFetchClassrooms,
  useFetchCustomSessionGroups,
} from "links/lib/features/classrooms";
import { useFetchPracticeSetItems } from "links/lib/features/practiceSetItems";
import { useFetchPracticeSet } from "links/lib/features/practiceSets";
import {
  useFetchSessionPreviewOnce,
  useMutateSession,
} from "links/lib/features/sessions";
import {
  AnalyticsEvent,
  IPracticeSetSession,
  QuestionType,
  UsersGroupsRole,
} from "links/lib/types";
import { useOnboardingChecklist } from "screens/TeacherDashboard/hooks/useOnboardingChecklist";
import {
  IFormValues,
  TeacherSessionCreate,
} from "screens/TeacherSessionCreate";
import { useShowNewLibrary } from "sharedComponents/hooks/useShowNewLibrary";

export interface IArgs {
  useModal?: boolean;
  practiceSetId: string;
  classroomId?: string | null;
  practiceSetCreatedById: string;
  onSubmit: (values: IFormValues) => void;
  onSuccess?: (practiceSetSession: IPracticeSetSession) => void;
}

export interface IResult {
  createSessionElement: React.ReactElement;
  handleCreateIntent: () => void;
}

export const useCreateSession = ({
  useModal = false,
  practiceSetId,
  practiceSetCreatedById,
  classroomId,
  onSubmit,
  onSuccess,
}: IArgs): IResult => {
  const newLibraryFeatureEnabled = useShowNewLibrary();
  const [isOpen, setIsOpen] = useState(false);
  const showToast = useShowToast();
  const { t } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "startSession",
  });
  const { trackEvent } = useAnalytics();
  const queryClient = useQueryClient();
  const { teacherOnboardingChecklist } = useOnboardingChecklist({
    useBannerOnboarding: newLibraryFeatureEnabled,
  });

  const [createSessionPending, setCreateSessionPending] = useState(false);

  const fetchPracticeSet = useFetchPracticeSet({ id: practiceSetId });
  const fetchClassrooms = useFetchClassrooms({});
  const sessionPreviewQuery = useFetchSessionPreviewOnce();
  const fetchPracticeSetItems = useFetchPracticeSetItems({
    practice_set_id: practiceSetId,
  });
  const executeSessionPreviewQuery = useRef(sessionPreviewQuery.execute);

  const [currentClassroomId, setCurrentClassroomId] = useState<
    string | undefined
  >(classroomId ? classroomId : undefined);
  const customSessionGroupsFetch = useFetchCustomSessionGroups({
    classroom_id: currentClassroomId,
  });
  const classroomStudentsFetch = useFetchClassroomUsers({
    classroom_id: currentClassroomId,
    group_roles: [UsersGroupsRole.Member],
  });

  useErrorToast(sessionPreviewQuery.error);

  const onMutateSuccess = ({
    practice_set_session,
  }: {
    practice_set_session: IPracticeSetSession;
  }) => {
    showToast(t("sessionCreated"));

    onSuccess?.(practice_set_session);

    setCreateSessionPending(false);
    setIsOpen(false);

    // Special case for if this happens to be the session creation that
    // completes the item for the teacher onboarding checklist
    if (
      !newLibraryFeatureEnabled &&
      teacherOnboardingChecklist &&
      !teacherOnboardingChecklist.has_started_live_practice
    ) {
      queryClient.invalidateQueries(["onboarding-checklist"]);
      setTimeout(() => {
        window.open(
          `${config.baseUrl}/session/join/${practice_set_session.join_code}`,
          "_self"
        );
      }, 3000);
      return;
    }

    window.open(
      `${config.baseUrl}/session/join/${practice_set_session.join_code}`,
      "_self"
    );
  };

  const mutateSession = useMutateSession({ onSuccess: onMutateSuccess });

  useErrorToast(mutateSession.error);

  const handleCreateIntent = () => {
    executeSessionPreviewQuery.current({
      practice_set_id: practiceSetId,
      enable_coop_draw: true,
      generate_questions_from_terms: true,
    });
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSubmit = (values: IFormValues) => {
    if (createSessionPending) {
      return;
    }

    setCreateSessionPending(true);

    onSubmit?.(values);

    const sessionItems = values.sessionItems
      .filter((item) => !item.is_skipped)
      .map((item) => {
        if (
          item.practice_set_item.question_type === QuestionType.Draw ||
          item.practice_set_item.question_type === QuestionType.MultipleChoice
        ) {
          item.practice_set_item.alternate_text_responses = [];
        }
        return item;
      });

    mutateSession.mutate({
      practiceSetId: values.practiceSetId,
      classroomId: values.classroomId,
      outerGame: values.outerGame,
      generateQuestionsFromTerms: values.generateQuestionsFromTerms,
      allowStudentNames: values.allowStudentNames,
      secondsPerQuestion: values.secondsPerQuestion,
      startTime: new Date(),
      enableCoopDraw: values.enableCoopDraw,
      randomizeOrder: values.randomizeOrder,
      allowAnonymousStudents: values.allowAnonymousStudents,
      sessionItems,
      useCustomSessionGroups: values.useCustomSessionGroups,
    });

    trackEvent(
      AnalyticsEvent.TeacherDashboard_SessionCreateFlyout_CreateSession,
      {
        practiceSetId: values.practiceSetId,
        classroomId: values.classroomId,
        generateQuestionsFromTerms: values.generateQuestionsFromTerms,
        allowAnonymouseStudents: values.allowAnonymousStudents,
        allowStudentNames: values.allowStudentNames,
        secondsPerQuestion: values.secondsPerQuestion,
        enableCoopDraw: values.enableCoopDraw,
        randomizeOrder: values.randomizeOrder,
        isCertified: fetchPracticeSet.data?.practice_set.is_certified,
        isPremium: fetchPracticeSet.data?.practice_set.is_premium,
        outerGame: values.outerGame,
        useCustomSessionGroups: values.useCustomSessionGroups,
      }
    );
  };

  const createSessionElement = (
    <TeacherSessionCreate
      useModal={useModal}
      isOpen={isOpen}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      classrooms={fetchClassrooms.data?.classrooms}
      practiceSetCreatedById={practiceSetCreatedById}
      practiceSetId={practiceSetId}
      practiceSetItems={fetchPracticeSetItems.data?.practice_set_items}
      sessionPreviewItems={sessionPreviewQuery.data?.session_items}
      classroomId={classroomId}
      isLoading={mutateSession.isLoading || createSessionPending}
      customSessionGroups={customSessionGroupsFetch.data?.session_groups}
      classroomUsers={classroomStudentsFetch.data?.users}
      setCurrentClassroomId={setCurrentClassroomId}
    />
  );

  return {
    createSessionElement,
    handleCreateIntent,
  };
};
