import {
  FormControl,
  FormLabel,
  HStack,
  VStack,
  useBreakpointValue,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import React, { SyntheticEvent, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Dropdown, IOption } from "adminComponents/atoms/Dropdown";
import { FormErrorMessage } from "adminComponents/atoms/FormErrorMessage";
import { Icon } from "adminComponents/atoms/Icon";
import { IconTooltip } from "adminComponents/atoms/IconTooltip";
import { Input } from "adminComponents/atoms/Input";
import { Modal } from "adminComponents/atoms/Modal";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils";
import { UsersGroupsRole } from "links/lib/types";

interface AddTeacherViaEmailModal {
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: (
    emailAddress: string,
    usersGroupsRole: UsersGroupsRole
  ) => void;
  errorString?: string;
  isLoading?: boolean;
}

export const AddTeacherViaEmailModal: React.FC<AddTeacherViaEmailModal> = ({
  isOpen,
  handleClose,
  handleSubmit,
  errorString,
  isLoading,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const styles = useMultiStyleConfig("AdminAddTeacherViaEmailModal", {});
  const hasError = !!errorString;

  const isMobile = useBreakpointValue({ base: true, md: false });
  const [role, setRole] = useState<UsersGroupsRole>(UsersGroupsRole.Owner);

  const _handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      email: { value: string };
    };

    handleSubmit(target.email.value, role);
  };

  const addTeacherButtonText = isMobile
    ? t("addTeacherViaEmailModal.addTeacherShort")
    : t("addTeacherViaEmailModal.addTeacher");

  let emailIcon: JSX.Element | undefined = <Icon icon="mail_outlined" />;
  if (isMobile) {
    emailIcon = undefined;
  }

  const roles = [UsersGroupsRole.Owner, UsersGroupsRole.Assistant];
  const roleOptions = roles.map((role) => {
    return {
      label: t(`common.usersGroupsRole${role}`),
      value: role,
    };
  });

  return (
    <Modal
      variant="adminModalCompactMobile"
      title={t("addTeacherViaEmailModal.title")}
      isOpen={isOpen}
      onClose={handleClose}
      showBackButton={false}
      headingProps={{ as: "h2", variant: "adminH6" }}
    >
      <Text size="lg" variant="adminP1">
        {t("addTeacherViaEmailModal.instructions")}
      </Text>

      <form onSubmit={_handleSubmit}>
        <VStack spacing={pxToRem(16)} pt={pxToRem(16)} pb={pxToRem(32)}>
          <FormControl isDisabled={isLoading} isInvalid={hasError}>
            <FormLabel htmlFor="email">
              <Text size="md" variant="adminP1">
                {t("addTeacherViaEmailModal.labelEmail")}
              </Text>
            </FormLabel>
            <Input name="email" />
            <FormErrorMessage>{errorString}</FormErrorMessage>
          </FormControl>

          <FormControl isDisabled={isLoading}>
            <FormLabel htmlFor="role">
              <HStack>
                <Text size="md" variant="adminP1">
                  {t("addTeacherViaEmailModal.labelRole")}
                </Text>
                <IconTooltip>
                  {t("addTeacherViaEmailModal.tooltipRole")}
                </IconTooltip>
              </HStack>
            </FormLabel>
            <Dropdown
              id="role"
              options={roleOptions}
              value={roleOptions.filter((r) => r.value === role)}
              handleChange={(v) =>
                setRole((v as IOption).value as UsersGroupsRole)
              }
            />
          </FormControl>
        </VStack>

        <HStack w="full">
          <Button
            sx={styles.button}
            type="submit"
            isLoading={isLoading}
            variant={hasError ? "adminButtonOutlined" : "adminButtonFilled"}
            leftIcon={emailIcon}
          >
            {hasError ? t("common.tryAgain") : addTeacherButtonText}
          </Button>
          <Button
            sx={styles.button}
            variant={"adminButtonOutlined"}
            onClick={handleClose}
          >
            {t("common.cancel")}
          </Button>
        </HStack>
      </form>
    </Modal>
  );
};
