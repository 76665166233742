import {
  Input as ChakraInput,
  ComponentWithAs,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
  VStack,
  forwardRef,
} from "@chakra-ui/react";
import React from "react";

import { Icon, IProps as IconProps } from "adminComponents/atoms/Icon";

import { Text } from "../Text";

export interface IAdminInputProps extends InputProps {
  leftIcon?: IconProps;
  rightIcon?: IconProps;
  maxLengthLabel?: string;
}

export const Input: ComponentWithAs<"input", IAdminInputProps> = forwardRef<
  IAdminInputProps,
  "input"
>(({ leftIcon, rightIcon, maxLength, maxLengthLabel, value, ...rest }, ref) => (
  <VStack alignItems="flex-start" w="full">
    <InputGroup>
      {leftIcon && (
        <InputLeftElement
          height="100%"
          pointerEvents={!leftIcon.onClick ? "none" : undefined}
        >
          <Icon {...leftIcon} />
        </InputLeftElement>
      )}
      <ChakraInput value={value} maxLength={maxLength} {...rest} ref={ref} />
      {rightIcon && (
        <InputRightElement
          height="100%"
          pointerEvents={!rightIcon.onClick ? "none" : undefined}
        >
          <Icon {...rightIcon} />
        </InputRightElement>
      )}
    </InputGroup>
    {maxLength && maxLengthLabel && typeof value === "string" && (
      <Text size="sm" color="primary.dark-gray">
        {value.length || 0}/{maxLength} {maxLengthLabel}
      </Text>
    )}
  </VStack>
));

Input.defaultProps = {
  variant: "adminInput",
};
