import React from "react";

import Silhouette from "adminComponents/assets/images/SilhouetteAvatar.png";
import { Avatar } from "adminComponents/atoms/Avatar";
import {
  IconButton,
  IProps as IconButtonProps,
} from "adminComponents/atoms/IconButton";
import { generateStudentName, hoverStyles } from "adminComponents/utils";
import { IUser } from "links/lib/types";

export interface IUserIconButtonProps extends Partial<IconButtonProps> {
  disabled?: boolean;
  handleClick?: () => void;
  user?: IUser;
}

export const UserIconButton: React.FC<IUserIconButtonProps> = ({
  disabled,
  handleClick,
  user = {},
}) => {
  const userImage = user?.profile_image_url;
  const bgImage = userImage ? `url(${userImage})` : `url(${Silhouette})`;

  const studentName = user ? generateStudentName(user).primary : "";
  return (
    <IconButton
      ariaLabel={studentName}
      colorSchemeHover="primary.golden-hover"
      backgroundColor={userImage ? undefined : "primary.golden-light"}
      icon="person"
      bgImage={bgImage}
      bgRepeat="round"
      shape="circle"
      variant="adminNavIconButton"
      onClick={handleClick}
      sx={{
        "[aria-hidden]": { display: "none" },
        div: { _last: { borderWidth: 0 } },
        svg: {
          display: bgImage ? "none" : "block",
        },
      }}
      disabled={disabled}
    >
      {!bgImage && (
        <Avatar
          name={studentName}
          sx={{
            color: "primary.warm-black",
            w: "32px",
            h: "32px",
            borderWidth: 0,
            _hover: hoverStyles({
              borderWidth: 0,
              borderColor: "transparent",
            }),
          }}
        />
      )}
    </IconButton>
  );
};
