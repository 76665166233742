import { Box, useMultiStyleConfig } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { AdminFlyout } from "adminComponents/molecules/Flyout";
import { StudentCompletedAssignment } from "adminComponents/molecules/StudentCompletedAssignment";
import { IMyAssignment } from "adminComponents/screens/MyClassesScreen";
import { IClassroom } from "links/lib/types";

export interface IProps {
  completedAssignments: IMyAssignment[];
  classroom?: IClassroom;
  isOpen: boolean;
  handleClose: () => void;
}

export const StudentCompletedAssignmentsFlyout: React.FC<IProps> = ({
  completedAssignments,
  classroom,
  isOpen = true,
  handleClose,
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });

  const styles = useMultiStyleConfig(
    "AdminStudentCompletedAssignmentsFlyout",
    {}
  );

  return (
    <AdminFlyout
      isOpen={isOpen}
      onClose={handleClose}
      title={t("common.completedAssignments")}
      subtitle={classroom?.name || ""}
      variant="adminFlyoutDefault"
    >
      <Box sx={styles.container}>
        {completedAssignments.map((item) => {
          const { id } = item.assignment;

          return (
            <Box key={id} sx={styles.assignment}>
              <StudentCompletedAssignment {...item} />
            </Box>
          );
        })}
      </Box>
    </AdminFlyout>
  );
};
