import { Flex } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { ExplainableLightningIcon } from "adminComponents/atoms/ExplainableLightningIcon";
import { IconTooltip } from "adminComponents/atoms/IconTooltip";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils";

export const ExplainableText: React.FC = ({ ...rest }) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });

  return (
    <Flex
      alignItems="center"
      justifyContent="flex-start"
      gap={[pxToRem(4), null, pxToRem(8)]}
    >
      <ExplainableLightningIcon height={pxToRem(23)} width={pxToRem(23)} />
      <Text variant="adminP1" color="primary.dark-gray" {...rest}>
        {t("common.explanations")}
      </Text>
      <IconTooltip>{t("explainableText.itemTooltip")}</IconTooltip>
    </Flex>
  );
};
