import { Box, Hide, Link } from "@chakra-ui/react";
import React from "react";
import { Trans, useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import {
  CleverSignInButton,
  GoogleSignInButton,
  MicrosoftSignInButton,
} from "adminComponents/atoms/ExternalAccountButton";
import { Text } from "adminComponents/atoms/Text";
import { LogInModule } from "adminComponents/molecules/LogInModule";
import { SignInAsModule } from "adminComponents/molecules/SignInAsModule";
import { TemplateWithVerticalCenteredContent } from "adminComponents/templates/TemplateWithVerticalCenteredContent";
import { pxToRem } from "adminComponents/utils";
import { HeaderWithLanguagePicker } from "sharedComponents/atoms/HeaderWithLanguagePicker";
import { useLanguageDropdown } from "sharedComponents/hooks/useLanguageDropdown";

import studentImagePearPractice from "./resource/student_login_pear_practice.png";
import teacherImagePearPractice from "./resource/teacher_login_pear_practice.png";

interface IScreenProps {
  isLoading?: boolean;
}

export type SignInProps = {
  handleSignInWithCleverButtonClick: () => void;
  handleSignInWithGoogleButtonClick: () => void;
  handleSignInWithMicrosoftButtonClick: () => void;
  handleBack: () => void;
  isButtonsDisabled?: boolean;
  isButtonsLoading?: boolean;
  isLoading?: boolean;
  imagePosition?: "center" | "left";
};

type SignInAsProps = Omit<IScreenProps, "description" | "title"> & {
  isButtonsDisabled?: boolean;
  isButtonsLoading?: boolean;
  welcomeMessage?: string;
  handleSignInAsStudent: () => void;
  handleSignInAsTeacher: () => void;
};

type TeacherSignInProps = SignInProps & { coppaUrl: string };

const SignInScreen: React.FC<IScreenProps> = ({
  isLoading = false,
  children,
}) => {
  const langDropdown = useLanguageDropdown();

  return (
    <TemplateWithVerticalCenteredContent
      isLoading={isLoading}
      headerContent={<HeaderWithLanguagePicker showSignOut={false} />}
    >
      <Box mb={pxToRem(30)}>{children}</Box>
      <Hide above="lg">
        <Box mb={pxToRem(30)}>{langDropdown}</Box>
      </Hide>
    </TemplateWithVerticalCenteredContent>
  );
};

export const JoinClassScreen: React.FC<Omit<SignInProps, "handleBack">> = ({
  handleSignInWithCleverButtonClick,
  handleSignInWithGoogleButtonClick,
  handleSignInWithMicrosoftButtonClick,
  isButtonsDisabled,
  isButtonsLoading,
  isLoading,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  return (
    <SignInScreen isLoading={isLoading}>
      <LogInModule
        description={t("joinClassScreen.description")}
        title={t("joinClassScreen.title")}
        image={studentImagePearPractice}
        imagePosition="center"
      >
        <Box id="psi_sign_in" />
        <GoogleSignInButton
          mt={pxToRem(-8)}
          handleOnButtonClick={handleSignInWithGoogleButtonClick}
          isDisabled={isButtonsDisabled}
          isLoading={isButtonsLoading}
        />
        <CleverSignInButton
          handleOnButtonClick={handleSignInWithCleverButtonClick}
          isDisabled={isButtonsDisabled}
          isLoading={isButtonsLoading}
        />
        <MicrosoftSignInButton
          handleOnButtonClick={handleSignInWithMicrosoftButtonClick}
          isDisabled={isButtonsDisabled}
          isLoading={isButtonsLoading}
        />
      </LogInModule>
    </SignInScreen>
  );
};

export const TeacherSignInScreen: React.FC<TeacherSignInProps> = ({
  handleSignInWithCleverButtonClick,
  handleSignInWithGoogleButtonClick,
  handleSignInWithMicrosoftButtonClick,
  isButtonsDisabled,
  isButtonsLoading,
  handleBack,
  coppaUrl,
  isLoading,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  return (
    <SignInScreen isLoading={isLoading}>
      <LogInModule
        description={t("teacherSignInScreen.description")}
        title={t("teacherSignInScreen.title")}
        image={teacherImagePearPractice}
      >
        <Box id="psi_sign_in" />
        <GoogleSignInButton
          mt={pxToRem(-8)}
          handleOnButtonClick={handleSignInWithGoogleButtonClick}
          isDisabled={isButtonsDisabled}
          isLoading={isButtonsLoading}
        />
        <CleverSignInButton
          handleOnButtonClick={handleSignInWithCleverButtonClick}
          isDisabled={isButtonsDisabled}
          isLoading={isButtonsLoading}
        />
        <MicrosoftSignInButton
          handleOnButtonClick={handleSignInWithMicrosoftButtonClick}
          isDisabled={isButtonsDisabled}
          isLoading={isButtonsLoading}
        />
        <Button
          isDisabled={isButtonsDisabled}
          isLoading={isButtonsLoading}
          size="lg"
          variant="adminTextButtonMedium"
          w="full"
          onClick={handleBack}
        >
          {t("teacherSignInScreen.backButton")}
        </Button>
        <Text mt={pxToRem(14)} variant="adminMeta">
          <Trans i18nKey="teacherSignInScreen.coppaDisclosure" t={t}>
            <Link target="_blank" color="utility.link" href={coppaUrl}>
              COPPA Disclosure
            </Link>
          </Trans>
        </Text>
      </LogInModule>
    </SignInScreen>
  );
};

export const StudentSignInScreen: React.FC<SignInProps> = ({
  handleSignInWithCleverButtonClick,
  handleSignInWithGoogleButtonClick,
  handleSignInWithMicrosoftButtonClick,
  isButtonsDisabled,
  isButtonsLoading,
  handleBack,
  isLoading,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  return (
    <SignInScreen isLoading={isLoading}>
      <LogInModule
        description={t("studentSignInScreen.description")}
        title={t("studentSignInScreen.title")}
        image={studentImagePearPractice}
        imagePosition="center"
      >
        <Box id="psi_sign_in" />
        <GoogleSignInButton
          mt={pxToRem(-8)}
          handleOnButtonClick={handleSignInWithGoogleButtonClick}
          isDisabled={isButtonsDisabled}
          isLoading={isButtonsLoading}
        />
        <CleverSignInButton
          handleOnButtonClick={handleSignInWithCleverButtonClick}
          isDisabled={isButtonsDisabled}
          isLoading={isButtonsLoading}
        />
        <MicrosoftSignInButton
          handleOnButtonClick={handleSignInWithMicrosoftButtonClick}
          isDisabled={isButtonsDisabled}
          isLoading={isButtonsLoading}
        />
        <Button
          isDisabled={isButtonsDisabled}
          isLoading={isButtonsLoading}
          size="lg"
          variant="adminTextButtonMedium"
          w="full"
          onClick={handleBack}
        >
          {t("studentSignInScreen.backButton")}
        </Button>
      </LogInModule>
    </SignInScreen>
  );
};

export const SignInAsScreen: React.FC<SignInAsProps> = ({
  isButtonsDisabled,
  isButtonsLoading,
  handleSignInAsStudent,
  handleSignInAsTeacher,
  isLoading,
  welcomeMessage,
}) => {
  return (
    <SignInScreen isLoading={isLoading}>
      <SignInAsModule
        isButtonsDisabled={isButtonsDisabled}
        isButtonsLoading={isButtonsLoading}
        welcomeMessage={welcomeMessage}
        handleSignInAsStudent={handleSignInAsStudent}
        handleSignInAsTeacher={handleSignInAsTeacher}
      />
    </SignInScreen>
  );
};
