import { hoverStyles, pxToRem } from "adminComponents/utils";

export const AdminPopoverTrigger = {
  baseStyle: {
    _focusVisible: {
      outline: "2px solid",
      outlineColor: "utility.focus",
    },
  },
  variants: {
    adminSolid: {
      display: "inline-flex",
      _hover: hoverStyles({
        path: {
          fill: "utility.link-hover",
        },
      }),
      _focusVisible: {
        outline: "2px solid",
        outlineColor: "utility.link-hover",
        borderRadius: "100%",
      },
    },
    adminRichTextToolbar: {
      display: "inline-flex",
      _hover: hoverStyles({
        path: {
          fill: "utility.link-hover",
        },
        bgColor: "primary.golden-light",
        borderRadius: pxToRem(4),
      }),
      _focusVisible: {
        borderRadius: pxToRem(4),
        outline: "2px solid",
        outlineColor: "utility.focus",
      },
    },
    adminClampedHeading: {
      display: "inline-flex",
      textAlign: "left",
    },
  },
};
