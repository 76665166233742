import { Box, Flex, ScaleFade, useMultiStyleConfig } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { ColorScheme } from "adminComponents";
import { IconCTAButton } from "adminComponents/atoms/IconCTAButton";

export interface IProps {
  backgroundColor?: ColorScheme;
  children: ReactNode;
  isDismissed?: boolean;
  isDismissible?: boolean;
  handleDismiss?: () => void;
}

export const Banner: React.FC<IProps> = ({
  children,
  backgroundColor = "primary.light-gray",
  isDismissible = false,
  isDismissed = false,
  handleDismiss,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const variant = isDismissible ? "adminDismissibleBanner" : undefined;
  const styles = useMultiStyleConfig("AdminBanner", { variant });

  if (isDismissible && handleDismiss) {
    return (
      <ScaleFade in={!isDismissed} unmountOnExit>
        <Flex sx={styles.banner} backgroundColor={backgroundColor}>
          {children}
          <IconCTAButton
            icon="close"
            onClick={handleDismiss}
            ariaLabel={t("common.close")}
          />
        </Flex>
      </ScaleFade>
    );
  }

  return (
    <Box sx={styles.banner} backgroundColor={backgroundColor}>
      {children}
    </Box>
  );
};
