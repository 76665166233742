import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { AssignmentHeroCard } from "adminComponents/molecules/AssignmentHeroCard";
import { StudentNavigationHeader } from "adminComponents/molecules/StudentNavigationHeader";
import { PastDueAssignmentScreen } from "adminComponents/screens/PastDueAssignmentScreen";
import { TemplateWithCenteredHeroOneColumn } from "adminComponents/templates/TemplateWithCenteredHeroOneColumn";
import { useAnalytics } from "lib/contexts/analytics";
import { useFetchAssignment } from "links/lib/features/assignments";
import { useAuth } from "links/lib/features/auth";
import { AnalyticsEvent, UserRole } from "links/lib/types";
import { redirectIfNaN } from "links/lib/util";
import AppError from "screens/AppError";
import { useNavigationData } from "screens/StudentDashboard/contexts/StudentNavigationDataProvider";
import { useStartAssignment } from "sharedComponents/hooks/useStartAssignment";

export const Assignment: React.FC = () => {
  const params = useParams() as { assignmentId: string };
  const { assignmentId } = params;

  redirectIfNaN(assignmentId, "/");

  const { authUser } = useAuth();

  const { t } = useTranslation("admin", {
    keyPrefix: "assignmentDetailContainer",
    useSuspense: false,
  });

  const { navigationData } = useNavigationData();
  const history = useHistory();
  const location = useLocation();
  const { trackEvent } = useAnalytics();

  const assignmentFetch = useFetchAssignment({
    id: assignmentId,
    disabled: authUser?.is_guest,
  });

  // If user is a guest, we'll need for them to sign in
  useEffect(() => {
    if (!authUser?.is_guest) return;
    history.push({ pathname: "/sign-in", state: { from: location } });
  }, [authUser, history, location]);

  // If Teacher, redirect to teacher assignment page
  useEffect(() => {
    if (
      assignmentFetch.data?.assignment &&
      authUser?.role !== UserRole.Student
    ) {
      history.push(
        `/t/classrooms/${assignmentFetch.data?.assignment.group_id}/assignments/${assignmentFetch.data?.assignment.id}/questions`
      );
    }
  });

  const handleGrantAccess = useCallback(() => {
    trackEvent(AnalyticsEvent.Assignments_Page_Authorize, {});
  }, [trackEvent]);

  const handleStartAssignment = useCallback(() => {
    trackEvent(AnalyticsEvent.Assignments_Page_Start_Assignment, {
      assignmentId: assignmentFetch.data?.assignment.id,
    });
  }, [trackEvent, assignmentFetch.data?.assignment.id]);

  const { authorizeModal, startAssignment } = useStartAssignment({
    assignments: assignmentFetch.data?.assignment
      ? [assignmentFetch.data?.assignment]
      : [],
    handleGrantAccess,
    handleStartAssignment,
  });

  const isLoading = assignmentFetch.isLoading;

  const pastDue = useMemo(() => {
    let pastDue = false;
    if (assignmentFetch.data?.assignment.ends_at) {
      if (new Date(assignmentFetch.data.assignment.ends_at) < new Date()) {
        pastDue = true;
      }
    }
    return pastDue;
  }, [assignmentFetch]);

  useEffect(() => {
    if (
      !isLoading &&
      authUser?.role === UserRole.Student &&
      assignmentFetch.data &&
      !pastDue
    ) {
      startAssignment(assignmentFetch.data.assignment);
    }
  }, [isLoading, authUser, assignmentFetch, pastDue, startAssignment]);

  const navData = useMemo(() => {
    const breadcrumbs = [
      {
        label: `${t("assignment")}: ${
          assignmentFetch.data?.assignment.practice_set.title
        }`,
        isExternal: false,
      },
    ];

    return {
      ...navigationData,
      breadcrumbs,
    };
  }, [navigationData, t, assignmentFetch]);

  const nav = <StudentNavigationHeader {...navData} />;

  const heroContent = (
    <>
      {assignmentFetch.data?.assignment && (
        <AssignmentHeroCard assignment={assignmentFetch.data?.assignment} />
      )}
    </>
  );

  const assignmentNotFound =
    assignmentFetch.error?.response?.status === 403 ||
    assignmentFetch.error?.response?.status === 404;

  if (assignmentNotFound) {
    return <AppError code={404} />;
  }

  return (
    <>
      {!pastDue && (
        <TemplateWithCenteredHeroOneColumn
          nav={nav}
          heroContent={heroContent}
          variant="default"
          isLoading={isLoading}
          isHeroShifted
        >
          <>{authorizeModal}</>
        </TemplateWithCenteredHeroOneColumn>
      )}
      {pastDue && <PastDueAssignmentScreen />}
    </>
  );
};
