import { AxiosError } from "axios";
import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";

export interface IFetchUserCanExtendIndividualTrialParams {
  onError?: (err: AxiosError) => void;
}

export interface IFetchUserCanExtendIndividualTrialResponse {
  can_extend_trial: boolean;
}

/**
 * Fetches the capability of the user to extend their individual trial
 * @returns
 */
export default function useFetchUserCanExtendIndividualTrial(
  params: IFetchUserCanExtendIndividualTrialParams
): UseQueryResult<IFetchUserCanExtendIndividualTrialResponse, AxiosError> {
  const axios = useAxios();

  const { onError } = params;

  const reactQuery = useQuery<
    IFetchUserCanExtendIndividualTrialResponse,
    AxiosError
  >(
    ["can-extend-individual-trial"],
    async (): Promise<IFetchUserCanExtendIndividualTrialResponse> => {
      return await axios
        .get("/v1/subscriptions/individual/extension")
        .then((r) => r.data as IFetchUserCanExtendIndividualTrialResponse);
    },
    {
      onError: (err: AxiosError) => {
        onError?.(err);
      },
    }
  );

  return reactQuery;
}
