import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { IFetchAvatarResponse } from "../../types";

export interface IFetchAvatarParams {
  disabled?: boolean;
  refetchOnWindowFocus?: boolean;
  include_items: boolean;
  onSuccess?: (data: IFetchAvatarResponse) => void;
}

/**
 * Fetches the avatar for the current user
 * @returns
 */
export default function useFetchAvatar(
  params: IFetchAvatarParams
): UseQueryResult<IFetchAvatarResponse> {
  const axios = useAxios();

  const {
    disabled,
    refetchOnWindowFocus = false,
    include_items,
    onSuccess,
  } = params;

  const query = useQuery<IFetchAvatarResponse>(
    ["avatar"],
    async (): Promise<IFetchAvatarResponse> => {
      return await axios
        .get("/v1/avatar/self", {
          params: { include_items },
        })
        .then((r) => r.data as IFetchAvatarResponse);
    },
    {
      enabled: !disabled,
      onSuccess,
      refetchOnWindowFocus,
    }
  );

  return query;
}
