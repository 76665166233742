import { ISessionGroups, SessionTheBigBoardState } from "links/lib/types";
import {
  IBoardSpace,
  IBoardSpacePlacements,
} from "sessionComponents/molecules/BigBoard";

import { getGroupWithWarning } from "./getGroupWithWarning";

export const getBigBoardRows = ({
  the_big_board_state,
  groups,
  groupId,
  isSpinning = false,
  highlightedSpaceIds,
}: {
  the_big_board_state: SessionTheBigBoardState;
  groups: ISessionGroups;
  groupId?: string;
  isSpinning?: boolean;
  highlightedSpaceIds?: number[];
}): IBoardSpace[][] => {
  return (
    the_big_board_state?.rows.map((row) => {
      return row.cols.map((boardSpace) => {
        const placements = boardSpace.placements
          .map(({ round_number, group_id }) => {
            let showPlaceholder = false;
            if (!isSpinning) {
              showPlaceholder = groupId ? group_id !== groupId : true;
            }

            const group = getGroupWithWarning(groups, group_id);

            if (!group) return undefined;

            return {
              round_number,
              group,
              showPlaceholder,
            };
          })
          .filter((p) => !!p) as IBoardSpacePlacements;

        let isHighlighted = false;
        if (isSpinning && highlightedSpaceIds) {
          isHighlighted = highlightedSpaceIds.includes(boardSpace.space_id);
        }

        return {
          space_id: boardSpace.space_id,
          placement_disabled: boardSpace.placement_disabled,
          space_type: boardSpace.space_type,
          smiley_space_point_multiplier:
            boardSpace.smiley_space_point_multiplier,
          placements,
          isHighlighted,
        };
      });
    }) || []
  );
};
