import { IUseAsyncReturn, useAsync } from "links/lib/hooks/useAsync";
import { useAxios } from "links/lib/hooks/useAxios";
import { UserRole } from "links/lib/types";

interface FetchProviderRedirectUrl {
  provider: string;
  role: UserRole;
  join_code?: string;
  language?: string;
  popup?: boolean;
}

interface IExecuteResponse {
  redirectUrl: string;
}

export default function useFetchProviderRedirectUrl(): IUseAsyncReturn<
  IExecuteResponse,
  FetchProviderRedirectUrl
> {
  const axios = useAxios();

  const getRedirectUrl = async ({
    provider,
    role,
    join_code,
    language,
    popup = false,
  }: FetchProviderRedirectUrl) => {
    const response = await axios
      .get(`/v1/oauth/redirect`, {
        params: {
          provider,
          role,
          join_code,
          language,
          popup,
        },
      })
      .then((r) => r.data);

    const { redirect_url } = response as unknown as {
      redirect_url: string;
    };

    return {
      redirectUrl: redirect_url,
    };
  };

  const { execute, isLoading, isSuccess, error, data } = useAsync<
    { redirectUrl: string },
    FetchProviderRedirectUrl
  >(getRedirectUrl);

  return {
    isLoading,
    error,
    data,
    execute,
    isSuccess,
  };
}
