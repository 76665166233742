import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";

export interface IFetchOrganizationUsagePercentParams {
  enabled?: boolean;
}

export interface IFetchOrganizationUsagePercentResponse {
  top_half_of_all_organizations: boolean;
  top_third_of_all_organizations: boolean;
}

export default function useFetchOrganizationUsagePercent({
  enabled = false,
}: IFetchOrganizationUsagePercentParams): UseQueryResult<
  IFetchOrganizationUsagePercentResponse,
  unknown
> {
  const axios = useAxios();

  const query = useQuery<IFetchOrganizationUsagePercentResponse>(
    ["org-usage-percent"],
    async (): Promise<IFetchOrganizationUsagePercentResponse> => {
      return await axios.get(`/v1/organizations/usage`).then((r) => {
        return r.data as IFetchOrganizationUsagePercentResponse;
      });
    },
    { enabled }
  );

  return query;
}
