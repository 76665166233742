import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useMount } from "react-use";

import { useLibrarySearch } from "adminComponents/hooks/useLibrarySearch";
import { MyLibraryScreen } from "adminComponents/screens/MyLibraryScreen";
import { useErrorToast } from "adminComponents/utils/toast";
import { useAnalytics, usePageTrack } from "lib/contexts/analytics";
import { usePageTitle } from "lib/hooks/usePageTitle";
import { useRouteStateOnMount } from "lib/hooks/useRouteStateOnMount";
import { useAuth } from "links/lib/features/auth";
import { useFetchCollections } from "links/lib/features/collections";
import {
  AnalyticsEvent,
  AnalyticsPage,
  IPracticeSetType,
} from "links/lib/types";
import { useNavigationData } from "screens/TeacherDashboard/contexts/TeacherNavigationDataProvider";
import { useCreatePracticeSet } from "sharedComponents/hooks/useCreatePracticeSet";

import { useMutateFolder } from "../shared/hooks/useMutateFolder";
import { usePracticeSetHandlers } from "../shared/hooks/usePracticeSetHandlers";

export interface IProps {
  initialCreateOpen?: boolean;
}

export const LibraryRoot: React.FC<IProps> = ({ initialCreateOpen }) => {
  const { navigationData } = useNavigationData();
  const { authUser, isFeatureEnabled, hasNoPremiumAccess } = useAuth();
  const { t } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "myLibraryContainer",
  });
  const history = useHistory();

  const { trackEvent } = useAnalytics();
  usePageTrack(AnalyticsPage.TeacherDashboard_MyLibrary_Root);
  usePageTitle(t("pageTitle"));

  const {
    handleSetTerm,
    handleClearFilters,
    handleOpenFilterFlyout,
    handleRemoveFilterTag,
    query,
    filterFlyout,
    handleViewMoreFolders,
    handleViewMorePracticeSets,
    collections,
    practiceSets,
    recentPracticeSets,
    totalCounts,
    isInitialLoad,
    isSearchLoading,
    showViewMoreFolders,
    showViewMorePracticeSets,
    handleClearSearchTerm,
    isSearch,
    standardsModal,
    allCollections,
    handleSuggest,
    suggestions,
  } = useLibrarySearch({
    gradeLevels: navigationData.gradeLevels,
    subjects: navigationData.subjects,
    country: authUser?.country || "US",
    region: authUser?.region,
  });

  const {
    handleCreate: handleCreateFolder,
    handleDelete: handleDeleteFolder,
    handleEdit: handleEditFolder,
    handleDuplicate: handleDuplicateFolder,
    components: mutateFolderComponents,
  } = useMutateFolder({});

  const {
    handleCreate: handleCreatePracticeSet,
    flyout: createPracticeSetFlyout,
  } = useCreatePracticeSet({
    subjects: navigationData.subjects,
    gradeLevels: navigationData.gradeLevels,
    collections: allCollections,
    onOpen: () =>
      trackEvent(
        AnalyticsEvent.TeacherDashboard_MyLibrary_PracticeSetFlyout_CreatePracticeSetIntent,
        {}
      ),
    onSubmit: (data) =>
      trackEvent(
        AnalyticsEvent.TeacherDashboard_MyLibrary_PracticeSetFlyout_CreatePracticeSetConfirm,
        {
          availability: data.availability,
        }
      ),
  });

  const { components: practiceSetHandlerComponents, ...practiceSetHandlers } =
    usePracticeSetHandlers({ collections: allCollections });

  // Open flyout if state create key is present
  useRouteStateOnMount({
    handler: handleCreatePracticeSet,
    replace: true,
    stateKey: "createPracticeSet",
  });

  useMount(() => {
    if (initialCreateOpen) {
      handleCreatePracticeSet();
      history.push("/t/my-library");
    }
  });

  const fetchCollections = useFetchCollections();
  useErrorToast(fetchCollections.error);

  const {
    flyout: createInstantSetFlyout,
    handleCreate: handleCreateInstantSet,
  } = useCreatePracticeSet({
    subjects: navigationData.subjects,
    gradeLevels: navigationData.gradeLevels,
    collections: fetchCollections.data?.collections || [],
    practiceSetType: IPracticeSetType.Instant,
  });

  const practiceSetsData = practiceSets
    .filter((practiceSet) => {
      // Just practice sets with no collection or, if one
      // performed a search, any matching practice set
      return practiceSet.collection_id === "0" || isSearch;
    })
    .map((practiceSet) => {
      return {
        standards: [],
        practiceSet,
      };
    });

  const recentPracticeSetsData = recentPracticeSets.map((practiceSet) => {
    return {
      standards: [],
      practiceSet,
    };
  });

  const collectionsData = collections.map((collection) => {
    return {
      collection,
    };
  });

  const search = {
    handleSetSearchTerm: handleSetTerm,
    handleClearAllFilters: handleClearFilters,
    handleOpenFilterFlyout,
    handleRemoveFilterTag,
    gradeLevelIds: query.filters?.gradeLevelIds,
    subjectIds: query.filters?.subjectIds,
    standardIds: query.filters?.standardIds,
    handleClearSearchInput: handleClearSearchTerm,
    handleSuggest: handleSuggest,
    suggestions: suggestions,
    searchPlaceholder: t("searchPlaceholder"),
  };

  return (
    <>
      <MyLibraryScreen
        authUser={authUser}
        hasNoPremiumAccess={hasNoPremiumAccess}
        folderHandlers={{
          handleDelete: handleDeleteFolder,
          handleDuplicate: handleDuplicateFolder,
          handleEdit: handleEditFolder,
        }}
        folderTotalCount={totalCounts.collections}
        folders={collectionsData}
        gradeLevels={navigationData.gradeLevels}
        handleCreateFolder={handleCreateFolder}
        handleCreatePracticeSet={handleCreatePracticeSet}
        handleViewMoreFolders={handleViewMoreFolders}
        handleViewMorePracticeSets={handleViewMorePracticeSets}
        isLoading={isInitialLoad}
        isSearchLoading={isSearchLoading}
        isSearch={isSearch}
        navigationData={navigationData}
        practiceSetHandlers={practiceSetHandlers}
        practiceSetTotalCount={totalCounts.practiceSets}
        practiceSets={practiceSetsData}
        recentlyAddedHandlers={practiceSetHandlers}
        recentlyAdded={recentPracticeSetsData}
        search={search}
        showViewMoreFolders={showViewMoreFolders}
        showViewMorePracticeSets={showViewMorePracticeSets}
        subjects={navigationData.subjects}
        totalCount={totalCounts.practiceSets + totalCounts.collections}
        showPremiumMarker={!isFeatureEnabled("playtime.teacher.hide_premium")}
        handleCreateInstantSet={handleCreateInstantSet}
      />
      {filterFlyout}
      {standardsModal}
      {mutateFolderComponents}
      {createPracticeSetFlyout}
      {practiceSetHandlerComponents}
      {createInstantSetFlyout}
    </>
  );
};
