import { Box, Circle, Flex, HStack, Link, VStack } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Card } from "adminComponents/atoms/Card";
import { CoverImageIcon } from "adminComponents/atoms/CoverImageIcon";
import { Heading } from "adminComponents/atoms/Heading";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils";

export const ImproveOrgUsageCard: React.FC = () => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "districtAdminHomepage.usage",
  });
  return (
    <Card
      variant="adminCardSmallBorder"
      borderColor="primary.tan"
      bgColor="white"
      h="100%"
      style={{
        padding: pxToRem(30),
      }}
    >
      <VStack
        w="full"
        h="100%"
        alignItems="center"
        spacing={pxToRem(8)}
        justifyContent="center"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          backgroundColor="utility.badge-purple"
          borderRadius="full"
          boxSize={pxToRem(75)}
        >
          <CoverImageIcon boxSize={pxToRem(50)} icon="OTHER_ROCKET" />
        </Box>
        <Heading variant="adminH4" as="h4">
          {t("howToHeader")}
        </Heading>
        <Text variant="adminP2">{t("tips")}</Text>
        <VStack
          paddingTop={pxToRem(15)}
          spacing={pxToRem(16)}
          alignItems="start"
        >
          <HStack>
            <Circle bgColor="primary.light-gray" size={pxToRem(24)}>
              <Text variant="adminP2">1</Text>
            </Circle>

            <Flex w="100%" justifyContent="flex-start">
              <Text display="inline" variant="adminP1">
                {t("tip1")}
              </Text>
            </Flex>
          </HStack>
          <HStack>
            <Circle bgColor="primary.light-gray" size={pxToRem(24)}>
              <Text variant="adminP2">2</Text>
            </Circle>

            <Flex w="100%" justifyContent="flex-start">
              <Text display="inline" as="span" variant="adminP1">
                <Link
                  href="/a/my-teachers"
                  style={{ textDecoration: "underline" }}
                  color="utility.link"
                >
                  {t("inviteCTA")}
                </Link>{" "}
                {t("tip2")}
              </Text>
            </Flex>
          </HStack>
        </VStack>
      </VStack>
    </Card>
  );
};
