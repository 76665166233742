import { Box, Circle, Flex, Spinner, Square, VStack } from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import { useLifecycles } from "react-use";

import { Button } from "adminComponents/atoms/Button";
import { ButtonWithLeftIcon } from "adminComponents/atoms/Button/Button.stories";
import { Icon } from "adminComponents/atoms/Icon";
import { PremiumTooltipRich } from "adminComponents/atoms/PremiumTooltipRich";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { useAnalytics } from "lib/contexts/analytics";
import { useAuth } from "links/lib/features/auth";
import { AnalyticsEvent } from "links/lib/types";

// eslint-disable-next-line
const MicRecorder = require("mic-recorder-to-mp3");

interface RecordAudioProps {
  uploading: boolean;
  uploadError: boolean;
  audioUrlToAdd: string;
  handleUploadAudio: (audio: File) => Promise<void>;
  handleInsertMedia: (
    imageUrl: string,
    altText: string,
    audioUrl?: string,
    videoUrl?: string
  ) => void;
}

export const RecordAudio: React.FC<RecordAudioProps> = ({
  audioUrlToAdd,
  uploading,
  uploadError,
  handleUploadAudio,
  handleInsertMedia,
}) => {
  const { t } = useTranslation("admin", {
    keyPrefix: "addMediaModal.recordAudio",
    useSuspense: false,
  });
  const [isRecording, setIsRecording] = useState(false);
  const { trackEvent } = useAnalytics();
  const { hasNoPremiumAccess } = useAuth();

  const recorder = useMemo(() => {
    return new MicRecorder({
      bitRate: 128,
    });
  }, []);

  const onStartRecording = () => {
    setIsRecording(true);
    recorder.start();
  };

  const onStopRecording = async () => {
    setIsRecording(false);

    const result = await recorder.stop().getMp3();
    const [buffer, blob] = result;

    trackEvent(
      AnalyticsEvent.TeacherDashboard_PracticeSetDetail_QuestionFlyout_AddMediaModal_RecordAudioIntent,
      {}
    );
    handleUploadAudio(
      new File(buffer, "audio.mp3", {
        type: blob.type,
        lastModified: Date.now(),
      })
    );
  };

  // Stop recording on unmount
  useLifecycles(null, () => {
    setIsRecording(false);
    recorder.stop();
  });

  if (uploading) {
    return (
      <Flex w="full" flexDir="column" alignItems="center">
        <VStack
          h={pxToRem(300)}
          w="full"
          backgroundColor="primary.light-gray"
          justifyContent="center"
        >
          <Spinner />
        </VStack>
      </Flex>
    );
  }

  return (
    <Flex w="full" flexDir="column" alignItems="center">
      <VStack
        h={pxToRem(300)}
        w="full"
        backgroundColor="primary.light-gray"
        justifyContent="center"
      >
        {audioUrlToAdd && !isRecording && (
          <ReactPlayer
            height={pxToRem(50)}
            width="80%"
            url={audioUrlToAdd}
            controls={true}
          />
        )}
        {/* // TODO: Add support for pausing */}
        {/* // TODO: Add waveform visualization */}
        <ButtonWithLeftIcon
          variant="adminButtonOutlined"
          leftIcon={
            isRecording ? (
              <Square bgColor="black" size={pxToRem(14)} />
            ) : (
              <Circle bgColor="utility.question-red" size={pxToRem(14)} />
            )
          }
          size="lg"
          w={["full", null, pxToRem(160)]}
          isLoading={uploading}
          onClick={
            hasNoPremiumAccess
              ? undefined
              : () => (isRecording ? onStopRecording() : onStartRecording())
          }
          disabled={hasNoPremiumAccess}
        >
          {isRecording
            ? t("stopRecording")
            : audioUrlToAdd
            ? t("reRecord")
            : t("record")}
        </ButtonWithLeftIcon>
      </VStack>
      {uploadError && (
        <Text
          variant="adminP2"
          color="utility.error"
          mt={pxToRem(8)}
          textAlign="center"
        >
          {t("uploadError")}
        </Text>
      )}

      <Flex w="full" flexDir="row" justifyContent="end" mt={pxToRem(24)}>
        <PremiumTooltipRich isDisabled={!hasNoPremiumAccess}>
          <Box>
            <Button
              variant="adminButtonFilled"
              size="lg"
              w={["full", null, pxToRem(190)]}
              isLoading={uploading}
              isDisabled={hasNoPremiumAccess || !audioUrlToAdd || isRecording}
              leftIcon={hasNoPremiumAccess ? <Icon icon="lock" /> : undefined}
              onClick={() => {
                if (!audioUrlToAdd || hasNoPremiumAccess) return;

                trackEvent(
                  AnalyticsEvent.TeacherDashboard_PracticeSetDetail_QuestionFlyout_AddMediaModal_RecordAudioConfirm,
                  {}
                );
                handleInsertMedia("", "", audioUrlToAdd);
              }}
            >
              {!audioUrlToAdd ? t("recordYourAudio") : t("addRecordedMedia")}
            </Button>
          </Box>
        </PremiumTooltipRich>
      </Flex>
    </Flex>
  );
};
