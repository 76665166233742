import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { QuestionAddToSetModal } from "adminComponents/molecules/QuestionAddToSetModal";
import { useErrorToast, useShowToast } from "adminComponents/utils/toast";
import { useAnalytics } from "lib/contexts/analytics";
import { useCopyPracticeSetItem } from "links/lib/features/practiceSetItems";
import { useFetchPracticeSets } from "links/lib/features/practiceSets";
import {
  AnalyticsEvent,
  IPracticeSet,
  IPracticeSetItem,
} from "links/lib/types";

export interface IResult {
  handleAddToSet: (item: IPracticeSetItem) => void;
  modal: React.ReactElement;
}

interface IModalState {
  isOpen: boolean;
  item?: IPracticeSetItem;
}

export const useCopyItemToSet = (): IResult => {
  const [modalState, setModalState] = useState<IModalState>({ isOpen: false });
  const showToast = useShowToast();
  const { t } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "publicLibraryContainer.practiceSetDetail.addToSet",
  });
  const { trackEvent } = useAnalytics();

  const onSuccess = () => {
    setModalState({ isOpen: false });

    showToast(t("successToast"));
  };

  const copyItem = useCopyPracticeSetItem({ onSuccess });
  const practiceSets = useFetchPracticeSets({ include_all: true });

  useErrorToast(copyItem.error);
  useErrorToast(practiceSets.error);

  const handleAddToSet = (item: IPracticeSetItem) => {
    setModalState({ isOpen: true, item });

    trackEvent(
      AnalyticsEvent.TeacherDashboard_PublicLibrary_PracticeSetDetail_CopyItemToSetIntent,
      {
        practiceSetItemId: item.id,
        isCertified: item.is_certified,
        isPremium: item.is_premium,
      }
    );
  };

  const handleClose = () => {
    setModalState({ isOpen: false });
  };

  const handleSave = (practiceSet: IPracticeSet, item: IPracticeSetItem) => {
    copyItem.mutate({
      id: item.id,
      practice_set_id: practiceSet.id,
      place_at_end: true,
    });

    trackEvent(
      AnalyticsEvent.TeacherDashboard_PublicLibrary_PracticeSetDetail_CopyItemToSetConfirm,
      {
        practiceSetItemId: item.id,
        practiceSetId: practiceSet.id,
        isCertified: item.is_certified,
      }
    );
  };

  const modal = (
    <QuestionAddToSetModal
      practiceSets={
        practiceSets.data?.practice_sets.filter(
          (ps) => !ps.is_certified && !ps.is_premium
        ) || []
      }
      isOpen={modalState.isOpen}
      practiceSetItem={modalState.item}
      handleSave={handleSave}
      handleClose={handleClose}
      isLoading={copyItem.isLoading}
    />
  );

  return {
    handleAddToSet,
    modal,
  };
};
