import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { useAxios } from "../../hooks/useAxios";

export interface IRejectPendingClassroomStudentArgs {
  id: string;
  classroom_id: string;
}

export interface IUseRejectPendingClassroomStudentArgs {
  onSuccess?: () => void;
}

export default function useRejectPendingClassroomStudent({
  onSuccess,
}: IUseRejectPendingClassroomStudentArgs): UseMutationResult<
  unknown,
  unknown,
  IRejectPendingClassroomStudentArgs
> {
  const axios = useAxios();
  const queryClient = useQueryClient();

  const reject = async (args: IRejectPendingClassroomStudentArgs) => {
    const { id, classroom_id } = args;

    const response = await axios({
      method: "delete",
      url: `/v1/groups/users/pending/${id}`,
      data: {},
    }).then((r) => {
      return r.data as unknown;
    });

    queryClient.invalidateQueries(
      ["pending-classroom-students", { classroom_id }],
      {
        exact: false,
      }
    );

    return response;
  };

  const mutation = useMutation(reject, { onSuccess });

  return mutation;
}
