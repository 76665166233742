import { Text, VStack } from "@chakra-ui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Dropdown, IOption } from "adminComponents/atoms/Dropdown";
import { ConfirmModal } from "adminComponents/molecules/ConfirmModal";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { IClassroom, IUser } from "links/lib/types";
import {
  ISimpleUser,
  getStudentName,
} from "sessionComponents/molecules/StudentList/utils";

export interface IMoveStudentModalProps {
  classrooms: Array<IClassroom>;
  student?: IUser;
  isOpen: boolean;
  onClose: () => void;
  handleSubmit: (user: IUser, newClassroomId: string) => void;
}

const MoveStudentModal: React.FC<IMoveStudentModalProps> = ({
  classrooms,
  student,
  isOpen,
  onClose,
  handleSubmit,
}) => {
  const { t } = useTranslation("admin", {
    keyPrefix: "moveStudentModal",
    useSuspense: false,
  });

  const [newClassroomId, setNewClassroomId] = useState<string | undefined>();

  const handleClassroomsChange = (val: unknown) => {
    const classroomId = (val as IOption).value;
    setNewClassroomId(classroomId);
  };

  const handleClose = (isConfirmed?: boolean) => {
    if (!isConfirmed) {
      onClose();
      return;
    }

    if (!student) return;
    if (!newClassroomId) return;

    handleSubmit(student, newClassroomId);
    onClose();
  };

  const classroomOptions = classrooms.map((classroom) => {
    return {
      label: classroom.name,
      value: classroom.id,
    };
  });

  return (
    <ConfirmModal
      title={t("modalTitle")}
      isOpen={isOpen}
      isLoading={!student}
      handleClose={handleClose}
      isCentered={true}
      buttonCancelLabel={t("cancelButton")}
      buttonOKLabel={t("confirmButton")}
      isDisabled={!newClassroomId}
      size="2xl"
      variant="adminModalCompactMobile"
    >
      {student && (
        <VStack spacing={pxToRem(24)} alignItems="left">
          <Text variant="adminP1">
            {t("instructionText", {
              studentName: getStudentName(student as ISimpleUser),
            })}
          </Text>
          <Dropdown
            handleChange={handleClassroomsChange}
            options={classroomOptions}
            placeholder={t("selectAClassroomLabel")}
            value={classroomOptions.find(
              (classroomOption) => classroomOption.value === newClassroomId
            )}
          />
        </VStack>
      )}
    </ConfirmModal>
  );
};

export { MoveStudentModal };
