import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { IClassroom } from "../../types";

export interface IRestoreClassroomArgs {
  id: string;
}

export interface IRestoreClassroomResponse {
  classroom: IClassroom;
}

export interface IUseRestoreClassroomProps {
  onSuccess?: (data: IRestoreClassroomResponse) => void;
  onError?: () => void;
  onSettled?: () => void;
}

interface IRestoreGroupResponse {
  group: IClassroom;
}

/**
 * Restores a classroom (unarchive)
 * @returns
 */
export default function useRestoreClassroom({
  onError,
  onSettled,
  onSuccess,
}: IUseRestoreClassroomProps): UseMutationResult<
  IRestoreClassroomResponse,
  unknown,
  IRestoreClassroomArgs
> {
  const axios = useAxios();
  const queryClient = useQueryClient();

  const restoreClassroom = async (args: IRestoreClassroomArgs) => {
    const { id } = args;

    const response = await axios({
      method: id ? "put" : "post",
      url: `/v1/groups${id ? "/" + id : ""}/restore`,
    })
      .then((r) => {
        const data = r.data as IRestoreGroupResponse;
        return { data: { classroom: data.group } };
      })
      .then((r) => r.data as IRestoreClassroomResponse);

    queryClient.invalidateQueries("classrooms", { exact: false });
    queryClient.setQueryData(["classrooms", { id: id }], {
      classroom: response.classroom,
    });

    return response;
  };

  const mutation = useMutation(restoreClassroom, {
    onError,
    onSettled,
    onSuccess,
  });

  return mutation;
}
