import {
  Box,
  Button as ChakraButton,
  Popover,
  PopoverContent,
  PopoverTrigger,
  chakra,
  useBreakpointValue,
  useDisclosure,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import React, { Fragment } from "react";

import { Button } from "adminComponents/atoms/Button";
import { Card } from "adminComponents/atoms/Card";
import { Divider } from "adminComponents/atoms/Divider";
import { Icon } from "adminComponents/atoms/Icon";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils/pxToRem";

import { useSortableTable } from "./useSortableTable";

export interface ITableSort {
  columnIndex: number;
  isAscending: boolean;
}

export interface IColumnSort {
  isAscending: boolean;
}

export interface ISortState {
  activeColumnIndex?: number;
  sortDirectionByColumn: Record<string, IColumnSort>;
}

type TableLabel = (
  data: unknown
) => React.ReactNode | React.ReactNode | ((data: unknown) => JSX.Element);

export interface IColumnData {
  header: TableLabel;
  headerStyle?: { [key: string]: string | undefined };
  cell: TableLabel;
  cellStyle?: { [key: string]: string | undefined };
  isSortable?: boolean;
  isActiveSort?: boolean;
  labels: {
    asc: string;
    desc: string;
  };
}

export type IRowData = (
  | string
  | number
  | boolean
  | unknown
  | null
  | { [key: string]: string | number }
)[];

export type ITableData = IRowData[];

export interface ISortableTableProps {
  columns: IColumnData[];
  data: ITableData;
  handleChangeSort?: (sort: ITableSort) => void;
  sort?: ITableSort;
  initialSort?: ITableSort;
  includeRowDividers?: boolean;
  variant?: string;
}

export type IMobileMenu = {
  activeSort: ISortState;
  data: ITableData;
  columns: IColumnData[];
  handleSort: (n: number, isAscending?: boolean) => void;
};

const MobileMenu: React.FC<IMobileMenu> = ({
  activeSort,
  data = [],
  columns = [],
  handleSort,
}) => {
  const {
    isOpen,
    onToggle: handleToggle,
    onClose: handleClose,
  } = useDisclosure();

  const colIndex = activeSort?.activeColumnIndex ?? 0;

  const column = columns[colIndex];
  const activeColumnHeader = column?.header;

  return (
    <Box pl={{ base: 0, md: pxToRem(24) }}>
      <Text variant="adminMeta">Sort by</Text>
      <Popover
        autoFocus={false}
        isOpen={isOpen}
        onClose={handleClose}
        placement="bottom-start"
      >
        <PopoverTrigger>
          <chakra.button onClick={handleToggle}>
            <Text as="div" variant="adminLinkMedium">
              {typeof activeColumnHeader === "function"
                ? activeColumnHeader(data)
                : activeColumnHeader}
            </Text>
            <Icon
              icon="keyboard_arrow_down"
              width={pxToRem(16)}
              height={pxToRem(16)}
              color="inherit"
            />
          </chakra.button>
        </PopoverTrigger>
        <PopoverContent
          borderRadius={pxToRem(10)}
          maxW="280px"
          boxShadow={`0 ${pxToRem(8)} ${pxToRem(10)} rgba(0, 0, 0, 0.08)`}
        >
          <Card
            py={pxToRem(10)}
            px={pxToRem(5)}
            borderRadius={pxToRem(10)}
            borderColor="primary.tan"
            variant="adminCardSmallBorder"
          >
            {columns.map(({ header, isSortable, labels }, i) =>
              !isSortable ? null : (
                <Box
                  key={i}
                  borderRadius={pxToRem(10)}
                  color={"primary.warm-black"}
                >
                  <Button
                    key={`asc-${i}`}
                    variant="adminSortableTableHeaderButtonMobile"
                    onClick={() => {
                      handleSort(i, true);
                      handleClose();
                    }}
                  >
                    <Box alignItems="center" display="flex">
                      {typeof header === "function" ? header(data) : header}
                      <Box ml={pxToRem(4)}> ({labels?.asc || "asc"}) </Box>
                    </Box>
                  </Button>
                  <Button
                    key={`desc-${i}`}
                    variant="adminSortableTableHeaderButtonMobile"
                    onClick={() => {
                      handleSort(i, false);
                      handleClose();
                    }}
                  >
                    <Box alignItems="center" display="flex">
                      {typeof header === "function" ? header(data) : header}
                      <Box ml={pxToRem(4)}> ({labels?.desc || "desc"})</Box>
                    </Box>
                  </Button>
                </Box>
              )
            )}
          </Card>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export const SortableTable: React.FC<ISortableTableProps> = ({
  columns,
  data,
  handleChangeSort,
  variant,
}) => {
  const { activeSort, handleSortClick } = useSortableTable(
    columns,
    handleChangeSort
  );
  const isMobile = useBreakpointValue({ base: true, md: false });
  const styles = useMultiStyleConfig("AdminSortableTable", { variant });
  return (
    <Box>
      <chakra.table role="table" __css={styles.container}>
        {!isMobile && (
          <chakra.thead __css={styles.thead}>
            <chakra.tr __css={styles.row}>
              {columns.map(
                ({ header, headerStyle, isSortable }, columnIndex) => {
                  if (!activeSort) {
                    return null;
                  }
                  const isActive = activeSort
                    ? activeSort?.activeColumnIndex === columnIndex
                    : false;
                  const iconDirection = activeSort?.sortDirectionByColumn?.[
                    columnIndex
                  ]?.isAscending
                    ? "down"
                    : "up";
                  const label =
                    typeof header === "function" ? header(data) : header;
                  return (
                    <chakra.th
                      key={`th-${columnIndex}`}
                      __css={styles.th}
                      aria-selected={isActive ? true : false}
                      {...headerStyle}
                    >
                      {!isSortable ? (
                        label
                      ) : (
                        <ChakraButton
                          onClick={() => handleSortClick(columnIndex)}
                          aria-selected={isActive ? true : false}
                          rightIcon={
                            <Icon
                              icon={`arrow_drop_${iconDirection}`}
                              iconColor="primary.dark-gray"
                            />
                          }
                        >
                          {label}
                        </ChakraButton>
                      )}
                    </chakra.th>
                  );
                }
              )}
            </chakra.tr>
          </chakra.thead>
        )}
        {isMobile && (
          <chakra.thead __css={styles.thead}>
            <chakra.tr __css={styles.row}>
              <chakra.td __css={styles.mobileSortSelection}>
                <MobileMenu
                  activeSort={activeSort}
                  columns={columns}
                  data={data}
                  handleSort={handleSortClick}
                />
              </chakra.td>
            </chakra.tr>
          </chakra.thead>
        )}
        <chakra.tbody __css={styles.tbody}>
          {isMobile && (
            <chakra.tr>
              <chakra.td colSpan={columns.length}>
                <Divider
                  __css={styles.divider}
                  color="primary.light-gray"
                  pt="0"
                />
              </chakra.td>
            </chakra.tr>
          )}
          {data?.map((row, iRow) => {
            return (
              <Fragment key={`frag-${iRow}`}>
                <chakra.tr key={`row-${iRow}`} __css={styles.tr}>
                  {row.map((cellData, iColumn) => {
                    const { cell, cellStyle } = columns[iColumn];
                    return (
                      <chakra.td
                        key={`td-${iColumn}`}
                        __css={styles.td}
                        {...cellStyle}
                      >
                        {typeof cell === "function" ? cell(cellData) : cell}
                      </chakra.td>
                    );
                  })}
                </chakra.tr>
                {iRow < data.length - 1 && (
                  <chakra.tr key={`divider-row-${iRow}`}>
                    <chakra.td colSpan={columns.length}>
                      <Divider
                        key={`divider-${iRow}`}
                        __css={styles.divider}
                        color={isMobile ? "primary.light-gray" : "primary.tan"}
                      />
                    </chakra.td>
                  </chakra.tr>
                )}
              </Fragment>
            );
          })}
        </chakra.tbody>
      </chakra.table>
    </Box>
  );
};
