import { Box, BoxProps } from "@chakra-ui/react";
import React from "react";

import {
  CoverImageBGColorSchemeType,
  CoverImageBGPatternType,
  RemixBGColorSchemeType,
  RemixBGPatternType,
} from "links/lib/types";

import { useCoverImageBackground } from "./hooks/useCoverImageBackground";

interface ICoverImageBackgroundProps extends BoxProps {
  pattern: CoverImageBGPatternType | RemixBGPatternType;
  colorScheme: CoverImageBGColorSchemeType | RemixBGColorSchemeType;
  tileSize?: number;
}

const CoverImageBackground: React.FC<ICoverImageBackgroundProps> = ({
  pattern,
  children,
  colorScheme,
  tileSize = 200,
  ...rest
}) => {
  const { tileImage } = useCoverImageBackground({
    pattern,
    colorScheme,
    tileSize,
  });

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      {...rest}
      bgImage={tileImage ? `url(${tileImage})` : undefined}
      bgRepeat="repeat"
      bgPosition="top left"
    >
      {!!tileImage && children}
    </Box>
  );
};

export { CoverImageBackground };
