import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Tabs } from "sessionComponents/atoms/Tabs";

interface ITabQuestionGroupViewProps {
  answerOptionsComponent: React.ReactElement;
  groupStatusComponent: React.ReactElement;
  teamsLength: number;
}

export const TabQuestionGroupView: React.FC<ITabQuestionGroupViewProps> = ({
  answerOptionsComponent,
  groupStatusComponent,
  teamsLength,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const { t } = useTranslation("session", {
    keyPrefix: "round.teacherViewTabs",
    useSuspense: false,
  });
  const tabData = [
    {
      label: t("itemsTab"),
      content: <>{answerOptionsComponent}</>,
    },
    {
      label: t("teamsTab", {
        teamCount: teamsLength,
      }),
      content: <>{groupStatusComponent}</>,
    },
  ];

  return (
    <Tabs
      h="full"
      w="full"
      activeTab={activeTab}
      onChangeIndex={setActiveTab}
      tabData={tabData}
    />
  );
};
