import { UseInfiniteQueryResult, useInfiniteQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import {
  IPracticeSet,
  IPracticeSetItem,
  ISubjectCount,
  PracticeDataDocumentType,
  PracticeDataSortBy,
  PracticeDataSortDir,
  PracticeSetAvailability,
} from "../../types";

export interface ISearchPracticeDataResponse {
  practice_sets: Array<IPracticeSet>;
  practice_set_items: Array<IPracticeSetItem>;
  subject_counts: Array<ISubjectCount>;
  total_count: number;
  page: number;
  per_page: number;
  suggestions: Array<string>;
}

export interface IUseSearchPracticeDataArgs {
  document_type?: PracticeDataDocumentType;
  user_id?: string;
  term?: string;
  standard_ids?: Array<string>;
  subject_ids?: Array<string>;
  grade_level_ids?: Array<string>;
  region?: string;
  per_page?: number;
  include_shared: boolean;
  // -1 for no collection, 0 for no preference (default), >0 for filter by specific collection
  collection_id?: string;
  disabled?: boolean;
  certified_only?: boolean;
  hide_premium?: boolean;
  sort_by?: PracticeDataSortBy;
  sort_dir?: PracticeDataSortDir;
  availability?: PracticeSetAvailability;
  anonymous?: boolean;
}

export default function useSearchPracticeDataInfinite(
  args: IUseSearchPracticeDataArgs
): UseInfiniteQueryResult<ISearchPracticeDataResponse> {
  const axios = useAxios();

  const { disabled, anonymous, ...params } = args;
  const { include_shared, document_type } = params;

  const query = useInfiniteQuery<ISearchPracticeDataResponse>(
    ["searchPracticeDataInfinite", { include_shared, document_type }, params],
    async ({ pageParam = 1 }): Promise<ISearchPracticeDataResponse> => {
      return await axios
        .get(`/v1/search/practice-data${anonymous ? "/anon" : ""}`, {
          params: {
            ...params,
            page: pageParam,
          },
        })
        .then((r) => r.data as ISearchPracticeDataResponse);
    },
    {
      enabled: !disabled,
      getNextPageParam: (lastPage) => {
        return lastPage.page + 1;
      },
    }
  );

  return query;
}
