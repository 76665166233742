import {
  Box,
  Flex,
  HStack,
  Hide,
  Image,
  Show,
  SimpleGrid,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Heading } from "adminComponents/atoms/Heading";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils";

import classroomSetting from "./resources/classroom_setting.jpg";
import curveDesktop from "./resources/curve_desktop.svg";
import curveMobile from "./resources/curve_mobile.svg";
import illustrationAvatar from "./resources/illustration_avatar.png";
import illustrationBrain from "./resources/illustration_brain.png";
import illustrationLiterature from "./resources/illustration_literature.png";
import illustrationWriting from "./resources/illustration_writing.png";

export interface ISignUpFooterProps {
  handleSignUp: () => void;
}

export const SignUpFooter: React.FC<ISignUpFooterProps> = ({
  handleSignUp,
}) => {
  const { t } = useTranslation("admin", {
    keyPrefix: "signUpFooter",
  });

  return (
    <Box
      bgColor="lilac.dark-shadow-02"
      position="absolute"
      left="0"
      right="0"
      mt={pxToRem(48)}
      color="white"
    >
      <SimpleGrid columns={{ base: 1, lg: 2 }}>
        <Show below="lg">
          <Flex
            flex="1"
            position="relative"
            top="0"
            left="0"
            right="0"
            bgImage={classroomSetting}
            bgSize="cover"
            bgPosition="center center"
            h={pxToRem(480)}
            alignItems="flex-end"
          >
            <Image w="full" src={curveMobile} />
          </Flex>
        </Show>
        <Flex
          alignItems="center"
          justifyContent={{ base: "flex-start", lg: "flex-end" }}
        >
          <VStack
            p={pxToRem(48)}
            pt={{ base: 0, lg: pxToRem(48) }}
            pr={{ base: pxToRem(48), lg: 0 }}
            spacing={pxToRem(48)}
            alignItems="flex-start"
            alignContent="center"
            maxW={{ base: "full", lg: pxToRem(720) }}
          >
            <Heading as="p" variant="adminH5" color="white">
              {t("labelSignUp")}{" "}
              <Text
                as="span"
                fontFamily={`"Gooper", Georgia, serif`}
                color="white"
              >
                {t("labelPlay")}
              </Text>
              .
            </Heading>
            <SimpleGrid
              columns={{ base: 1, lg: 2 }}
              spacing={{ base: pxToRem(16), lg: pxToRem(32) }}
            >
              <HStack>
                <Image
                  h={{ base: pxToRem(32), lg: pxToRem(48) }}
                  mr={pxToRem(8)}
                  src={illustrationLiterature}
                />
                <Text color="white">{t("labelStandardsAligned")}</Text>
              </HStack>
              <HStack>
                <Image
                  h={{ base: pxToRem(32), lg: pxToRem(48) }}
                  mr={pxToRem(8)}
                  src={illustrationAvatar}
                />
                <Text color="white">{t("labelPositiveClassroomClimate")}</Text>
              </HStack>
              <HStack>
                <Image
                  h={{ base: pxToRem(32), lg: pxToRem(48) }}
                  mr={pxToRem(8)}
                  src={illustrationWriting}
                />
                <Text color="white">{t("labelStudentMastery")}</Text>
              </HStack>
              <HStack>
                <Image
                  h={{ base: pxToRem(32), lg: pxToRem(48) }}
                  mr={pxToRem(8)}
                  src={illustrationBrain}
                />
                <Text color="white">{t("labelLoveForLearning")}</Text>
              </HStack>
            </SimpleGrid>
            <Box pt={pxToRem(16)}>
              <Button onClick={handleSignUp} variant="adminButtonFilled">
                {t("buttonCreateAnAccount")}
              </Button>
            </Box>
          </VStack>
        </Flex>
        <Hide below="lg">
          <Box
            flex="1"
            position="relative"
            top="0"
            left="0"
            right="0"
            bgImage={classroomSetting}
            bgSize="cover"
            bgPosition="center center"
          >
            <Image h="full" src={curveDesktop} />
          </Box>
        </Hide>
      </SimpleGrid>
    </Box>
  );
};
