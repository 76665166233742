import { Box, Text, VStack } from "@chakra-ui/react";
import React from "react";
import ReactPlayer from "react-player";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { Button } from "sessionComponents/atoms/Button";
import { Card } from "sessionComponents/atoms/Card";
import { Icon } from "sessionComponents/atoms/Icon";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { useRichTextToSpeakable } from "sessionComponents/hooks/useRichTextToSpeakable";
import { useTextReader } from "sessionComponents/hooks/useTextReader";
import { RichTextRenderer } from "sharedComponents/atoms/RichTextRenderer";

import { Label, ZoomableImage } from "../ZoomableImage";

interface IQuestionBoxProps {
  imageUrl?: string;
  instructions?: string;
  prompt?: string;
  videoUrl?: string;
  audioUrl?: string;
  imageAltText?: string;
  imageLabels?: Label[];
}

// todo animations
//todo actual styling of react players
export const QuestionBox: React.FC<IQuestionBoxProps> = ({
  imageUrl,
  instructions,
  prompt,
  videoUrl,
  audioUrl,
  imageAltText,
  imageLabels,
}) => {
  const { match: currentBreakpoints } = useBreakpoints();
  const { handleSpeak, handleCancelSpeak, isSpeaking, isSpeechEnabled } =
    useTextReader();
  const { convert } = useRichTextToSpeakable();

  const onKeyPress = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    speak();
  };

  const onSpeechClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    speak();
  };

  const speak = () => {
    if (isSpeaking()) {
      handleCancelSpeak();
      return;
    }

    const speakablePrompt = prompt ? convert(prompt) : "";
    const speakableText = (instructions ?? "") + speakablePrompt;

    handleSpeak(speakableText);
  };

  const hasMedia = !!imageUrl || !!audioUrl || !!videoUrl;

  return (
    <VStack w="full" h="full" padding={pxToRem(currentBreakpoints.padding)}>
      <Card useThickBorder width="full" height="fit-content" margin="auto">
        <Box mb={hasMedia ? pxToRem(currentBreakpoints.margin) : undefined}>
          <Text
            as="div"
            textStyle="gameText"
            fontSize={pxToRem(currentBreakpoints.fontSize)}
            textAlign="left"
          >
            {instructions && `${instructions}`}
            {instructions && prompt && ": "}
            {prompt && <RichTextRenderer content={prompt} />}
          </Text>
          {isSpeechEnabled && (
            <Box
              marginTop={pxToRem(8)}
              display="flex"
              justifyContent="flex-end"
            >
              <Button
                onClick={onSpeechClick}
                onKeyPress={onKeyPress}
                variant="buttonFilled"
                borderRadius="full"
                height="unset"
              >
                <Icon
                  boxSize={pxToRem(currentBreakpoints.iconSize)}
                  icon="sound_on"
                />
              </Button>
            </Box>
          )}
        </Box>

        {!!imageUrl && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            marginBottom={pxToRem(currentBreakpoints.margin / 2)}
          >
            <ZoomableImage
              src={imageUrl}
              alt={imageAltText || ""}
              maxH="40vh" //todo different size on diagram and classify?
              labels={imageLabels}
            />
          </Box>
        )}
        {!!audioUrl && (
          <ReactPlayer
            height="50px"
            width="100%"
            url={audioUrl}
            controls
            style={{
              marginBottom: pxToRem(currentBreakpoints.margin / 2),
            }}
          />
        )}
        {!!videoUrl && (
          <ReactPlayer
            width="100%"
            url={videoUrl}
            controls
            style={{
              marginBottom: pxToRem(currentBreakpoints.margin / 2),
            }}
          />
        )}
      </Card>
    </VStack>
  );
};
