import { Flex } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ColorScheme } from "adminComponents";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { IAlternateTextResponse } from "links/lib/types";
import { mergeRichText } from "links/lib/util";
import { RichTextRenderer } from "sharedComponents/atoms/RichTextRenderer";

export interface IProps {
  answer: string;
  alternateAnswers?: Array<IAlternateTextResponse>;
  prefix: string;
  suffix: string;
  color?: ColorScheme;
}

export const AnswerNumberResponse: React.FC<IProps> = ({
  answer,
  alternateAnswers,
  prefix,
  suffix,
  color,
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });

  const { prefixRichText, suffixRichText } = useMemo(() => {
    let prefixRichText,
      suffixRichText = null;

    try {
      prefixRichText = prefix ? JSON.parse(prefix) : null;
    } catch (e) {
      prefixRichText = null;
    }

    try {
      suffixRichText = suffix ? JSON.parse(suffix) : null;
    } catch (e) {
      suffixRichText = null;
    }

    return { prefixRichText, suffixRichText };
  }, [prefix, suffix]);

  const answerRich = useMemo(() => {
    return mergeRichText(
      prefixRichText,
      prefixRichText ? " " : "",
      answer,
      suffixRichText ? " " : "",
      suffixRichText
    );
  }, [answer, prefixRichText, suffixRichText]);

  const altAnswerPrefix = t(
    "practiceSetQuestionCard.labelAlternateCorrectAnswer"
  );

  const alternatesRich = useMemo(() => {
    return alternateAnswers?.map((answer) => {
      return {
        id: answer.id,
        richText: mergeRichText(
          altAnswerPrefix + ": ",
          prefixRichText,
          prefixRichText ? " " : "",
          answer.alternate_text,
          suffixRichText ? " " : "",
          suffixRichText
        ),
      };
    });
  }, [prefixRichText, suffixRichText, alternateAnswers, altAnswerPrefix]);

  return (
    <Flex direction="column" gap={{ base: pxToRem(4), md: pxToRem(12) }}>
      <Text as="div" variant="adminP2" color={color}>
        <RichTextRenderer content={answerRich} />
      </Text>
      {alternatesRich &&
        alternatesRich.map((alternateAnswer) => (
          <Text
            as="div"
            key={alternateAnswer.id}
            variant="adminP2"
            color="primary.dark-gray"
            fontStyle="italic"
          >
            <RichTextRenderer content={alternateAnswer.richText} />
          </Text>
        ))}
    </Flex>
  );
};
