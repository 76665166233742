import { pxToRem } from "adminComponents/utils/pxToRem";

export const AdminCertifiedPracticeSetBanner = {
  baseStyle: {
    container: {
      backgroundColor: "primary.lilac",
      padding: `${pxToRem(6)} ${pxToRem(7)}`,
      justifyContent: "center",
      alignItems: "center",
      borderRadius: `${pxToRem(17)} 0px ${pxToRem(8)} 0px`,
      gap: pxToRem(8),
      height: pxToRem(32),
      width: "fit-content",
    },
    icon: {
      height: pxToRem(18),
      width: pxToRem(18),
    },
  },
};
