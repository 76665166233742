import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  LocationInfo,
  SeasonMap,
  SeasonMapLocation,
  SeasonMapPin,
  SeasonMapZone,
} from "sharedComponents/molecules/SeasonMap";
import { ISeasonMapPannerChildProps } from "sharedComponents/molecules/SeasonMapPanner";

import BackdropImage from "./resource/background.svg";
import BuildingsImage from "./resource/buildings_only.svg";
import ArtMuseumPreviewImage from "./resource/locations/location_preview_art_museum.svg";
import CafePreviewImage from "./resource/locations/location_preview_cafe.svg";
import CityHallPreviewImage from "./resource/locations/location_preview_city_hall.svg";
import DinerPreviewImage from "./resource/locations/location_preview_diner.svg";
import FarmersMarketPreviewImage from "./resource/locations/location_preview_farmers_market.svg";
import LibraryPreviewImage from "./resource/locations/location_preview_library.svg";
import MusicShopPreviewImage from "./resource/locations/location_preview_music_shop.svg";
import ParkPreviewImage from "./resource/locations/location_preview_park.svg";
import PlanetariumPreviewImage from "./resource/locations/location_preview_planetarium.svg";
import PostOfficePreviewImage from "./resource/locations/location_preview_post_office.svg";
import SkateParkPreviewImage from "./resource/locations/location_preview_skate_park.svg";
import StadiumPreviewImage from "./resource/locations/location_preview_stadium.svg";
import TVStationPreviewImage from "./resource/locations/location_preview_tv_station.svg";
import ZooPreviewImage from "./resource/locations/location_preview_zoo.svg";
import OverlayImage from "./resource/overlay.svg";
import { ReactComponent as Zone1SVG } from "./resource/zone_1.svg";
import { ReactComponent as Zone2SVG } from "./resource/zone_2.svg";
import { ReactComponent as Zone3SVG } from "./resource/zone_3.svg";

export interface ISeason1MapProps extends ISeasonMapPannerChildProps {
  // zone ids for unlocked zones
  unlockedZoneIds: Array<string>;
  // the id of the user's current location on map
  currentLocationId?: string;
  // the profile image of the user to show in the map pin
  userImg?: string;
  // user progression for each map location
  locationsProgress: Array<{
    totalSpaces: number;
    spacesCollected: number;
    locationId: string;
  }>;
  // handler for when a location is selected
  handleLocationSelect?: (info: LocationInfo) => void;
}

export interface IZoneData {
  zoneId: string;
  offset: { top: number; left: number };
  pathSVG: React.ReactElement<SVGElement>;
  pathColor: string;
  width: number;
  height: number;
  locations: Array<ILocationData>;
}

export interface ILocationData {
  offset: { top: number; left: number };
  locationId: string;
  previewImageUrl: string;
}

export const mapSize = {
  width: 3840,
  height: 2061,
};

export const mapData: Array<IZoneData> = [
  // Zone 1
  {
    zoneId: "1",
    offset: { top: 0, left: 170 },
    pathSVG: <Zone1SVG />,
    pathColor: "#FA553A",
    width: 1154.186,
    height: 2061,
    locations: [
      {
        offset: { top: 1152, left: 394 },
        locationId: "1",
        previewImageUrl: PostOfficePreviewImage,
      },
      {
        offset: { top: 1283, left: 995 },
        locationId: "2",
        previewImageUrl: CafePreviewImage,
      },
      {
        offset: { top: 1621, left: 464 },
        locationId: "3",
        previewImageUrl: LibraryPreviewImage,
      },
      {
        offset: { top: 1788, left: 995 },
        locationId: "4",
        previewImageUrl: ParkPreviewImage,
      },
    ],
  },

  // Zone 2
  {
    zoneId: "2",
    offset: { top: 0, left: 1315 },
    pathSVG: <Zone2SVG />,
    pathColor: "#00A3DE",
    width: 1147.411,
    height: 2061,
    locations: [
      {
        offset: { top: 637, left: 1626 },
        locationId: "5",
        previewImageUrl: FarmersMarketPreviewImage,
      },
      {
        offset: { top: 637, left: 2161 },
        locationId: "6",
        previewImageUrl: ZooPreviewImage,
      },
      {
        offset: { top: 1081, left: 2161 },
        locationId: "7",
        previewImageUrl: MusicShopPreviewImage,
      },
      {
        offset: { top: 1288, left: 1720 },
        locationId: "8",
        previewImageUrl: DinerPreviewImage,
      },
      {
        offset: { top: 1788, left: 1831 },
        locationId: "9",
        previewImageUrl: StadiumPreviewImage,
      },
    ],
  },

  // Zone 3
  {
    zoneId: "3",
    offset: { top: 0, left: 2460 },
    pathSVG: <Zone3SVG />,
    pathColor: "#E961B1",
    width: 1149.118,
    height: 2061,
    locations: [
      {
        offset: { top: 637, left: 3152 },
        locationId: "14",
        previewImageUrl: CityHallPreviewImage,
      },
      {
        offset: { top: 1081, left: 2730 },
        locationId: "12",
        previewImageUrl: PlanetariumPreviewImage,
      },
      {
        offset: { top: 1081, left: 3450 },
        locationId: "13",
        previewImageUrl: SkateParkPreviewImage,
      },
      {
        offset: { top: 1619, left: 2730 },
        locationId: "10",
        previewImageUrl: TVStationPreviewImage,
      },
      {
        offset: { top: 1788, left: 3290 },
        locationId: "11",
        previewImageUrl: ArtMuseumPreviewImage,
      },
    ],
  },
];

export const Season1Map: React.FC<ISeason1MapProps> = ({
  scale = 1,
  handleLocationFocus,
  handleLocationSelect,
  unlockedZoneIds,
  userImg,
  currentLocationId,
  locationsProgress,
}) => {
  const { t } = useTranslation("map", {
    keyPrefix: "season1.locations",
    useSuspense: false,
  });

  // merge map data with translations, location progress, and zone locks
  const combinedMapData = useMemo(() => {
    return mapData.map((zoneData) => {
      return {
        ...zoneData,
        isLocked: !unlockedZoneIds.some((z) => z === zoneData.zoneId),
        locations: zoneData.locations.map((l) => ({
          ...l,
          // translate name
          name: t(`loc${l.locationId}.name`),
          // translate description
          description: t(`loc${l.locationId}.description`),
          // update progress for location
          progress: locationsProgress.find(
            (lp) => lp.locationId === l.locationId
          ) ?? { totalSpaces: 1, spacesCollected: 0, locationId: l.locationId },
        })),
      };
    });
  }, [t, unlockedZoneIds, locationsProgress]);

  return (
    <SeasonMap
      width={mapSize.width}
      height={mapSize.height}
      backdropImage={BackdropImage}
      landmarksImage={BuildingsImage}
      overlayImage={OverlayImage}
      scale={scale}
      handleLocationFocus={handleLocationFocus}
      handleLocationSelect={handleLocationSelect}
    >
      {combinedMapData.map((zoneData) => (
        <SeasonMapZone
          key={zoneData.zoneId}
          offset={zoneData.offset}
          pathSVG={zoneData.pathSVG}
          pathColor={zoneData.pathColor}
          width={zoneData.width}
          height={zoneData.height}
          isLocked={zoneData.isLocked}
        >
          {zoneData.locations.map((locationData) => {
            return (
              <SeasonMapLocation
                key={locationData.locationId}
                name={locationData.name}
                description={locationData.description}
                offset={locationData.offset}
                locationId={locationData.locationId}
                progress={locationData.progress}
                previewImageUrl={locationData.previewImageUrl}
              >
                {currentLocationId === locationData.locationId ? (
                  <SeasonMapPin userImg={userImg} />
                ) : (
                  <></>
                )}
              </SeasonMapLocation>
            );
          })}
        </SeasonMapZone>
      ))}
    </SeasonMap>
  );
};
