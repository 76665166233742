import { Box, Image, useMultiStyleConfig } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Card } from "adminComponents/atoms/Card";
import { Icon } from "adminComponents/atoms/Icon";
import { pxToRem } from "adminComponents/utils";
import { IAvatar, IAvatarItem, IMyStuffItem } from "links/lib/types";
import { getAvatarItemPreviewDataURL } from "links/lib/util";

export interface IProps {
  avatar: IAvatar;
  myStuffItem: IMyStuffItem;
  handleClick: (myStuffItem: IMyStuffItem) => void;
}

const useAvatarImagePreviewData = (
  avatar: IAvatar,
  avatarItem: IAvatarItem
) => {
  const [imagePreviewData, setImagePreviewData] = useState<string>();

  useEffect(() => {
    getAvatarItemPreviewDataURL(avatarItem, avatar).then((result) => {
      setImagePreviewData(result);
    });
  }, [avatar, avatarItem]);

  return imagePreviewData;
};

export const MyStuffCard: React.FC<IProps> = ({
  avatar,
  myStuffItem,
  handleClick,
}) => {
  const {
    is_seen: isSeen,
    is_unlocked: isUnlocked,
    is_equipped: isEquipped,
    avatar_item: avatarItem,
  } = myStuffItem;

  const { name: avatarName } = avatarItem;

  const styles = useMultiStyleConfig("AdminMyStuffCard", {
    isSeen,
  });

  const { t } = useTranslation("admin", { useSuspense: false });
  const lockLabel = isUnlocked ? "" : t("myStuff.lockedAriaLabel");
  const equippedLabel = isEquipped ? t("myStuff.equippedAriaLabel") : "";
  const ariaLabel = t("myStuff.ariaLabelCard", {
    lockLabel,
    equippedLabel,
    avatarName,
  });
  const imagePreviewData = useAvatarImagePreviewData(avatar, avatarItem);

  return (
    <Card
      as="button"
      variant="adminCardSelectable"
      borderColor="primary.tan"
      backgroundColor="primary.white"
      isSelected={isEquipped}
      aria-label={ariaLabel}
      sx={styles.card}
      onClick={() => handleClick(myStuffItem)}
      noPadding
    >
      {isUnlocked && (
        <Box __css={styles.banner}>
          <Icon
            icon="lock_open_outlined"
            iconColor="primary.white"
            width={{ base: pxToRem(14), md: pxToRem(20) }}
          />
        </Box>
      )}
      {imagePreviewData && (
        <Image src={imagePreviewData} alt={avatarName} __css={styles.image} />
      )}
    </Card>
  );
};
