import React, { createContext, useContext, useMemo } from "react";

import {
  PracticeSessionItemVariantType,
  QuestionType,
  SessionRoundAnimationNames,
  SessionRoundState,
  SessionSplashAnimationNames,
} from "../types";

const initialValues: SessionRoundState = {
  round_number: 0,
  practice_set_session_item: {
    id: "",
    is_certified: false,
    practice_set_item_id: "",
    practice_set_id: "",
    question_type: QuestionType.Unknown,
    variant: PracticeSessionItemVariantType.NoVariant,
  },
  confirmed_user_ids: [],
  starts_at: "",
  ends_at: "",
  round_animation_name: SessionRoundAnimationNames.SlideDown,
  splash_animation_name: SessionSplashAnimationNames.Swoosh,
  variant: PracticeSessionItemVariantType.NoVariant,
  variant_data: {},
};

export const SessionRoundStateContext =
  createContext<SessionRoundState>(initialValues);

export interface ISessionRoundStateProviderProps {
  wsRoundState: SessionRoundState | null;
}

export const SessionRoundStateProvider: React.FC<
  ISessionRoundStateProviderProps
> = ({ children, wsRoundState }) => {
  const value = useMemo(() => {
    return wsRoundState || initialValues;
  }, [wsRoundState]);
  return (
    <SessionRoundStateContext.Provider value={value}>
      {children}
    </SessionRoundStateContext.Provider>
  );
};

export const useSessionRoundState = (): SessionRoundState => {
  return useContext(SessionRoundStateContext);
};
