import { Box, HStack, usePrefersReducedMotion } from "@chakra-ui/react";
import React from "react";
import { animated, config, useSpring } from "react-spring";

import { pxToRem } from "adminComponents/utils";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";

export interface IPodiumSummaryCardProps {
  show: boolean;
  iconUrl: string;
  delay?: number;
  handleComplete: () => void;
}

const AnimatedHStack = animated(HStack);

export const PodiumSummaryCard: React.FC<IPodiumSummaryCardProps> = ({
  show,
  iconUrl,
  delay,
  handleComplete,
  children,
}) => {
  const { match: breakpoints } = useBreakpoints();
  const reduceMotion = usePrefersReducedMotion();

  const cardSpring = useSpring({
    from: {
      opacity: 0,
      scale: reduceMotion ? 1 : 0.8,
    },
    to: {
      opacity: 1,
      scale: 1,
    },
    delay,
    config: config.stiff,
    pause: !show,
    onRest: handleComplete,
  });

  return (
    <AnimatedHStack
      borderRadius={pxToRem(breakpoints.lightBorderRadius)}
      padding={pxToRem(breakpoints.padding)}
      w="full"
      background="podium.summaryCard"
      style={cardSpring}
    >
      <Box
        w="25%"
        h={pxToRem(breakpoints.buttonHeight)}
        backgroundImage={`url(${iconUrl})`}
        backgroundRepeat="no-repeat"
        backgroundPosition="center center"
        backgroundSize="contain"
      />
      <HStack flexGrow={1} justifyContent="space-around">
        {children}
      </HStack>
    </AnimatedHStack>
  );
};
