import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { MyAccountScreen } from "adminComponents/screens/MyAccountScreen";
import { generateStudentName } from "adminComponents/utils";
import { useErrorToast, useShowToast } from "adminComponents/utils/toast";
import { usePageTrack } from "lib/contexts/analytics";
import { usePageTitle } from "lib/hooks/usePageTitle";
import { useMutateAuthUser } from "links/lib/features/account";
import { useAuth } from "links/lib/features/auth";
import { AnalyticsPage } from "links/lib/types";
import { useNavigationData } from "screens/StudentDashboard/contexts/StudentNavigationDataProvider";

export const StudentAccount: React.FC = () => {
  const { authUser } = useAuth();
  const history = useHistory();
  const { navigationData } = useNavigationData();
  const showToast = useShowToast();
  const { t, i18n } = useTranslation("admin", {
    keyPrefix: "studentAccountContainer",
    useSuspense: false,
  });

  usePageTitle(t("pageTitle"));
  usePageTrack(AnalyticsPage.StudentDashboard_Account);

  const { isSuccess, mutate, error } = useMutateAuthUser();
  useErrorToast(error);
  useEffect(() => {
    if (!isSuccess) return;
    showToast(t("successToast"));
  }, [isSuccess, showToast, t]);

  const onSelectLanguage = (language: string) => {
    mutate({ language: language });
    i18n.changeLanguage(language);
  };

  const onSignOutClick = () => {
    history.push("/sign-out");
  };

  const onStudentAvatarEdit = () => {
    history.push("/s/locker/edit");
  };

  const onStudentAvatarShare = () => {
    history.push("/s/locker/share");
  };

  // authUser is validated in StudentDashboard, this is just
  // for the benefit of TypeScript
  if (!authUser) return <></>;

  return (
    <MyAccountScreen
      navigationData={navigationData}
      activeLanguage={authUser.language}
      studentAvatarSrc={authUser.profile_image_url}
      studentAvatarAltText={t("studentAvatarAltText", {
        name: generateStudentName(authUser).primary,
      })}
      handleSignOutClick={onSignOutClick}
      handleSelectLanguage={onSelectLanguage}
      handleStudentAvatarEdit={onStudentAvatarEdit}
      handleStudentAvatarShare={onStudentAvatarShare}
    />
  );
};
