import { Button } from "./Button";
import { Drawer } from "./Drawer";
import { Input } from "./Input";
import { Progress } from "./Progress";
import { Slider } from "./Slider";
import { Tabs } from "./Tabs";

export const components = {
  Input,
  Drawer,
  Slider,
  Progress,
  Tabs,
  Button,
};
