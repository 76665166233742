import { Box, Grid, GridItem } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils/pxToRem";
import {
  IClassifyCategory,
  ISessionItemResponse,
  QuestionType,
} from "links/lib/types";
import { RichTextRenderer } from "sharedComponents/atoms/RichTextRenderer";

import {
  AnswerColumn,
  IAnswerProps,
} from "../StudentAnswerResults/components/AnswerColumn";

interface IClassifyCategoryProps extends IClassifyCategory {
  studentAnswers: ISessionItemResponse[];
}

export interface IProps {
  studentName: string;
  classifyCategories: IClassifyCategoryProps[];
  questionType: QuestionType;
}

export const StudentClassifyAnswerResults: React.FC<IProps> = ({
  studentName,
  classifyCategories,
  questionType,
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });

  return (
    <Box>
      <Text
        variant="adminP2Bold"
        marginBottom={{ base: pxToRem(4), md: pxToRem(10) }}
      >
        {t("studentPracticeSetQuestionCardReponse.studentNameReponseLabel", {
          studentName: studentName,
        })}
      </Text>
      {classifyCategories.map((category) => {
        const studentAnswers = category.studentAnswers.map(
          (answer): IAnswerProps => ({
            id: answer.id,
            imageAltText: answer.choice_image_alt_text,
            imageUrl: answer.choice_image_url,
            isCorrect: answer.is_correct,
            text: answer.response_text,
          })
        );

        const correctAnswers = category.choices.map((choice) => ({
          id: choice.id,
          text: choice.text,
          imageAltText: choice.image_alt_text,
          imageUrl: choice.image_url,
        }));

        return (
          <Grid
            key={category.id}
            templateColumns={{ base: "1fr", md: "1fr 1fr" }}
            gap={pxToRem(16)}
          >
            <GridItem>
              <Text
                as="div"
                variant="adminP2Bold"
                marginBottom={{ base: pxToRem(4), md: pxToRem(10) }}
              >
                <RichTextRenderer content={category.text} />
              </Text>
              <AnswerColumn
                answerType="STUDENT_RESPONSES"
                questionType={questionType}
                answers={studentAnswers}
              />
            </GridItem>
            <GridItem>
              <Text
                variant="adminP2Bold"
                marginBottom={{ base: pxToRem(4), md: pxToRem(10) }}
              >
                {t(
                  "studentPracticeSetQuestionCardReponse.correctResponseLabel"
                )}
              </Text>
              <AnswerColumn
                answerType="EXPECTED_CORRECT_RESPONSES"
                questionType={questionType}
                answers={correctAnswers}
              />
            </GridItem>
          </Grid>
        );
      })}
    </Box>
  );
};
