import {
  Box,
  Flex,
  GridItem,
  SimpleGrid,
  Square,
  useBreakpointValue,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useWindowSize } from "react-use";

import desktopImage from "adminComponents/assets/background-images/illustrated-background-desktop.svg";
import mobileImage from "adminComponents/assets/background-images/illustrated-background-mobile.svg";
import { Button } from "adminComponents/atoms/Button";
import { Card } from "adminComponents/atoms/Card";
import { Heading } from "adminComponents/atoms/Heading";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils/pxToRem";

import pastDueArt from "./resource/past-due-assignment.png";

export const PastDueAssignmentScreen: React.FC = () => {
  const history = useHistory();

  const styles = useMultiStyleConfig("AdminTemplateWithCenteredHeroOneColumn", {
    variant: "default",
  });

  const { t } = useTranslation("admin", {
    keyPrefix: "pastDueAssignment",
    useSuspense: false,
  });

  const columns = useBreakpointValue({ base: 1, sm: 1, md: 2 });
  const isTwoColumns = columns === 2;

  const { height } = useWindowSize();
  const portraitArtHeight = height * 0.25;

  return (
    <Box
      w="100%"
      h="100%"
      backgroundImage={{ base: mobileImage, md: desktopImage }}
      bgSize="cover"
      __css={styles.backgroundContainer}
    >
      <Square size="100%">
        <Card
          h="75%"
          w="75%"
          padding={pxToRem(3)}
          borderColor="primary.tan"
          backgroundColor="primary.white"
          variant="adminCardSmallBorder"
          overflow="hidden"
        >
          <SimpleGrid h="100%" columns={columns} overflowY="auto">
            <GridItem h={isTwoColumns ? "100%" : pxToRem(portraitArtHeight)}>
              <Box
                h={isTwoColumns ? "100%" : pxToRem(portraitArtHeight)}
                w="100%"
                backgroundImage={pastDueArt}
                bgSize="cover"
                backgroundRepeat="no-repeat"
                borderLeftRadius={pxToRem(17)}
              />
            </GridItem>
            <GridItem p={pxToRem(30)} w="100%" wordBreak="break-word">
              <Square size="100%">
                <Flex gap={pxToRem(30)} flexDir="column">
                  <Heading as="h2" variant="adminH3">
                    {t("heading")}
                  </Heading>
                  <Text as="span" size="md" fontStyle="gameText">
                    {t("description")}
                  </Text>
                  <Text as="span" size="md" fontStyle="gameText">
                    {t("ctaText")}
                  </Text>
                  <Button
                    variant="adminButtonFilled"
                    onClick={() => {
                      history.push(`/`);
                    }}
                  >
                    <Text as="span" size="md" fontStyle="gameText">
                      {t("buttonLabel")}
                    </Text>
                  </Button>
                </Flex>
              </Square>
            </GridItem>
          </SimpleGrid>
        </Card>
      </Square>
    </Box>
  );
};
