import { Box, VStack } from "@chakra-ui/react";
import {
  Alignment,
  Fit,
  Layout,
  useRive,
  useStateMachineInput,
} from "@rive-app/react-canvas";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { animated, config, useSpring, useTransition } from "react-spring";
import { useInterval, usePrevious, useUnmount } from "react-use";

import { Button } from "adminComponents/atoms/Button";
import { Heading } from "adminComponents/atoms/Heading";
import { ProgressBar } from "adminComponents/atoms/ProgressBar";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils";
import { GenerateInstantSetStatus } from "links/lib/types";

import createAnimation from "./resource/creation_animation.riv";

export interface IInstantSetProgressProps {
  status: GenerateInstantSetStatus;
  startTime?: Date;
  lastUpdate?: Date;
  handleRestart?: () => void;
  handleComplete?: () => void;
}

const StatusProgress: { [key in GenerateInstantSetStatus]: number } = {
  [GenerateInstantSetStatus.Unknown]: 0,
  [GenerateInstantSetStatus.ReadingTopic]: 15,
  [GenerateInstantSetStatus.StartingGeneration]: 30,
  [GenerateInstantSetStatus.Complete]: 100,
};

const StatusStage: { [key in GenerateInstantSetStatus]: number } = {
  [GenerateInstantSetStatus.Unknown]: 1,
  [GenerateInstantSetStatus.ReadingTopic]: 2,
  [GenerateInstantSetStatus.StartingGeneration]: 3,
  [GenerateInstantSetStatus.Complete]: 4,
};

// Maximum time for generation in milliseconds
const maxGenerationTimeMS = 45 * 1000;
// How often progress should be updated in milliseconds
const progressUpdateIntervalMS = 500;
// How long to show the complete state before calling handleComplete in milliseconds
const completeDelayMS = 2500;

const AnimatedProgressBar = animated(ProgressBar);
const AnimatedBox = animated(Box);

export const InstantSetProgress: React.FC<IInstantSetProgressProps> = ({
  status,
  lastUpdate,
  handleRestart,
  handleComplete,
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "instantSetProgress",
  });
  const [progress, setProgress] = React.useState(0);
  // Track when the generation started for updating progress automatically
  const [generateStartTime, setGenerateStartTime] = React.useState<number>(0);
  const [desiredStage, setDesiredStage] = React.useState<number>(
    StatusStage[status]
  );
  const completeTimout = React.useRef<NodeJS.Timeout | null>(null);

  const { rive, RiveComponent } = useRive({
    src: createAnimation,
    autoplay: true,
    layout: new Layout({
      fit: Fit.Fill,
      alignment: Alignment.Center,
    }),
    stateMachines: "State Machine 1",
  });

  const stageInput = useStateMachineInput(rive, "State Machine 1", "stage", 1);

  const statusText = ((progress: number) => {
    if (progress < 15) {
      return t("progress15");
    } else if (progress < 30) {
      return t("progress30");
    } else if (progress < 40) {
      return t("progress40");
    } else if (progress < 50) {
      return t("progress50");
    } else if (progress < 60) {
      return t("progress60");
    } else if (progress < 70) {
      return t("progress70");
    } else if (progress < 80) {
      return t("progress80");
    } else if (progress < 90) {
      return t("progress90");
    } else if (progress < 100) {
      return t("progress100");
    } else {
      return t("progressComplete");
    }
  })(progress);

  // Set the animation stage based on status
  const previousStatus = usePrevious(status);
  useEffect(() => {
    setDesiredStage(StatusStage[status]);

    if (
      previousStatus !== status &&
      status === GenerateInstantSetStatus.Complete
    ) {
      if (completeTimout.current) clearTimeout(completeTimout.current);

      completeTimout.current = setTimeout(() => {
        handleComplete?.();
      }, completeDelayMS);
    }
  }, [status, handleComplete, previousStatus]);

  useUnmount(() => {
    if (completeTimout.current) clearTimeout(completeTimout.current);
  });

  // Set the progress based on status
  useEffect(() => {
    setProgress(StatusProgress[status]);
    if (status === GenerateInstantSetStatus.StartingGeneration) {
      setGenerateStartTime(lastUpdate?.getTime() ?? 0);
    }
  }, [status, lastUpdate]);

  useInterval(
    () => {
      const timeElapsed = Date.now() - generateStartTime;
      const progressPercentage = timeElapsed / maxGenerationTimeMS;
      const startProgress =
        StatusProgress[GenerateInstantSetStatus.StartingGeneration];
      const progressAdd = (100 - startProgress) * progressPercentage;
      const progress = Math.min(startProgress + progressAdd, 99);
      setProgress(progress);
    },
    status === GenerateInstantSetStatus.StartingGeneration
      ? progressUpdateIntervalMS
      : null
  );

  // The state machine needs to be updated sequentially to work, so
  // we only advance the stage by one at a time until it reaches the desired
  // stage
  useInterval(() => {
    if (!stageInput) return;

    if (stageInput.value < desiredStage) {
      stageInput.value = (stageInput.value as number) + 1;
    }
  }, 500);

  const progressSpring = useSpring({
    from: {
      x: 0,
    },
    to: {
      x: progress,
    },
    config: config.default,
  });

  const [transitions] = useTransition([statusText], () => ({
    from: { opacity: 0, transform: "translateY(100%)" },
    enter: { opacity: 1, transform: "translateY(0)" },
    leave: { opacity: 0, position: "absolute" },
    trail: 200,
  }));

  return (
    <VStack
      w="full"
      h="full"
      spacing={[pxToRem(40), null, pxToRem(60)]}
      alignItems="center"
      justifyContent="center"
      px={["admin.flyout.mobileXPadding", null, "admin.flyout.desktopXPadding"]}
    >
      <Box w="full">
        <Heading as="h4" variant="adminH3" textAlign="center">
          {t("heading")}
        </Heading>
      </Box>
      <Box boxSize={pxToRem(300)}>
        <RiveComponent />
      </Box>
      <VStack
        px={[pxToRem(40), null, pxToRem(80)]}
        w="full"
        spacing={pxToRem(20)}
      >
        <Box w="full">
          <AnimatedProgressBar
            size="lg"
            value={progressSpring.x}
            variant="adminStriped"
          />
        </Box>
        <Box position="relative" w="full">
          {transitions((styles, item) => (
            <AnimatedBox w="full" key={item} style={styles}>
              <Text as="p" variant="adminP1" textAlign="center">
                {item}
              </Text>
            </AnimatedBox>
          ))}
        </Box>
      </VStack>
      <Button variant="adminTextButtonMedium" onClick={handleRestart}>
        {t("restartButton")}
      </Button>
    </VStack>
  );
};
