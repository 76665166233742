import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  LocationInfo,
  SeasonMap,
  SeasonMapLocation,
  SeasonMapPin,
  SeasonMapZone,
} from "sharedComponents/molecules/SeasonMap";
import { ISeasonMapPannerChildProps } from "sharedComponents/molecules/SeasonMapPanner";

import BackdropImage from "./resource/backdrop.svg";
import BuildingsImage from "./resource/buildings_only.svg";
import XplosionPreviewImage from "./resource/locations/location_preview_s1_xplosion.svg";
import ClassicCaperPreviewImage from "./resource/locations/location_preview_s2_a_classic_caper.svg";
import ForgottenForestPreviewImage from "./resource/locations/location_preview_s3_forgotten_forest.svg";
import DriveToWinPreviewImage from "./resource/locations/location_preview_s4_drive_to_win.svg";
import MechsVsMonstersPreviewImage from "./resource/locations/location_preview_s5_mechs_vs_monsters.svg";
import StreamerStudioPreviewImage from "./resource/locations/location_preview_s6_streamer_studio.svg";
import { ReactComponent as Zone1PathSVG } from "./resource/zone1-path.svg";
import { ReactComponent as Zone2PathSVG } from "./resource/zone2-path.svg";
import { ReactComponent as Zone3PathSVG } from "./resource/zone3-path.svg";

export interface ISeason4MapProps extends ISeasonMapPannerChildProps {
  // zone ids for unlocked zones
  unlockedZoneIds: Array<string>;
  // the id of the user's current location on map
  currentLocationId?: string;
  // the profile image of the user to show in the map pin
  userImg?: string;
  // user progression for each map location
  locationsProgress: Array<{
    totalSpaces: number;
    spacesCollected: number;
    locationId: string;
  }>;
  // handler for when a location is selected
  handleLocationSelect?: (info: LocationInfo) => void;
}

export interface IZoneData {
  zoneId: string;
  offset: { top: number; left: number };
  pathSVG: React.ReactElement<SVGElement>;
  pathColor: string;
  width: number;
  height: number;
  locations: Array<ILocationData>;
}

export interface ILocationData {
  offset: { top: number; left: number };
  locationId: string;
  previewImageUrl: string;
}

export const mapSize = {
  width: 3840,
  height: 2061,
};

export const mapData: Array<IZoneData> = [
  // Zone 1
  {
    zoneId: "10",
    offset: { top: 0, left: 0 },
    pathSVG: <Zone1PathSVG />,
    pathColor: "#FA553A",
    width: 3840,
    height: 2061,
    locations: [
      {
        offset: { top: 570, left: 1670 },
        locationId: "31",
        previewImageUrl: XplosionPreviewImage,
      },
      {
        offset: { top: 1110, left: 1350 },
        locationId: "32",
        previewImageUrl: ClassicCaperPreviewImage,
      },
    ],
  },

  // Zone 2
  {
    zoneId: "11",
    offset: { top: 0, left: 0 },
    pathSVG: <Zone2PathSVG />,
    pathColor: "#00A3DE",
    width: 3840,
    height: 2061,
    locations: [
      {
        offset: { top: 1615, left: 2365 },
        locationId: "34",
        previewImageUrl: DriveToWinPreviewImage,
      },
      {
        offset: { top: 1615, left: 1725 },
        locationId: "33",
        previewImageUrl: ForgottenForestPreviewImage,
      },
    ],
  },

  // Zone 3
  {
    zoneId: "12",
    offset: { top: 0, left: 0 },
    pathSVG: <Zone3PathSVG />,
    pathColor: "#E961B1",
    width: 3840,
    height: 2061,
    locations: [
      {
        offset: { top: 1115, left: 2700 },
        locationId: "35",
        previewImageUrl: MechsVsMonstersPreviewImage,
      },
      {
        offset: { top: 575, left: 2330 },
        locationId: "36",
        previewImageUrl: StreamerStudioPreviewImage,
      },
    ],
  },
];

export const Season4Map: React.FC<ISeason4MapProps> = ({
  scale = 1,
  handleLocationFocus,
  handleLocationSelect,
  unlockedZoneIds,
  userImg,
  currentLocationId,
  locationsProgress,
}) => {
  const { t } = useTranslation("map", {
    keyPrefix: "season4.locations",
    useSuspense: false,
  });

  // merge map data with translations, location progress, and zone locks
  const combinedMapData = useMemo(() => {
    return mapData.map((zoneData) => {
      return {
        ...zoneData,
        isLocked: !unlockedZoneIds.some((z) => z === zoneData.zoneId),
        locations: zoneData.locations.map((l) => ({
          ...l,
          // translate name
          name: t(`loc${l.locationId}.name`),
          // translate description
          description: t(`loc${l.locationId}.description`),
          // update progress for location
          progress: locationsProgress.find(
            (lp) => lp.locationId === l.locationId
          ) ?? { totalSpaces: 1, spacesCollected: 0, locationId: l.locationId },
        })),
      };
    });
  }, [t, unlockedZoneIds, locationsProgress]);

  return (
    <SeasonMap
      width={mapSize.width}
      height={mapSize.height}
      backdropImage={BackdropImage}
      landmarksImage={BuildingsImage}
      scale={scale}
      handleLocationFocus={handleLocationFocus}
      handleLocationSelect={handleLocationSelect}
    >
      {combinedMapData.map((zoneData) => (
        <SeasonMapZone
          key={zoneData.zoneId}
          offset={zoneData.offset}
          pathSVG={zoneData.pathSVG}
          pathColor={zoneData.pathColor}
          width={zoneData.width}
          height={zoneData.height}
          isLocked={zoneData.isLocked}
        >
          {zoneData.locations.map((locationData) => {
            return (
              <SeasonMapLocation
                key={locationData.locationId}
                name={locationData.name}
                description={locationData.description}
                offset={locationData.offset}
                locationId={locationData.locationId}
                progress={locationData.progress}
                previewImageUrl={locationData.previewImageUrl}
              >
                {currentLocationId === locationData.locationId ? (
                  <SeasonMapPin userImg={userImg} />
                ) : (
                  <></>
                )}
              </SeasonMapLocation>
            );
          })}
        </SeasonMapZone>
      ))}
    </SeasonMap>
  );
};
