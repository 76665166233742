import { Box, Modal, ModalBody, ModalContent } from "@chakra-ui/react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import {
  useLocalStorage,
  useMount,
  useUnmount,
  useWindowSize,
} from "react-use";
import { clearTimeout, setTimeout } from "timers";

import { LoadingSpinner } from "adminComponents/atoms/LoadingSpinner";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { useAnalytics } from "lib/contexts/analytics";
import {
  AnalyticsEvent,
  IAvatar,
  IRemix,
  ISeasonLocationAvatarItem,
  ISeasonLocationAvatarItemGroup,
  SeasonMapMusic,
} from "links/lib/types";
import { usePlaySession } from "screens/StudentDashboard/contexts/PlaySessionProvider";
import { useStudentDashboardData } from "screens/StudentDashboard/contexts/StudentDashboardDataProvider";
import { useRemixes } from "screens/StudentHome/hooks/useRemixes";
import { useAudio } from "sharedComponents/contexts/audio";
import { Season3MapTransition } from "sharedComponents/molecules/Season3MapTransition";
import { CombinedItem } from "sharedComponents/molecules/SeasonLocationProgress";
import { LocationInfo } from "sharedComponents/molecules/SeasonMap";
import { SeasonMapFooter } from "sharedComponents/molecules/SeasonMapFooter";
import { SeasonMapPanner } from "sharedComponents/molecules/SeasonMapPanner";

import { Season3Location } from "../Season3Location";
import { ILocationData, Season3Map, mapData, mapSize } from "../Season3Map";
import { Season3MapHeader } from "../Season3MapHeader";
import PannerBGSVG from "./resource/panner_bg.svg";

export interface ISeason3Modal {
  isOpen: boolean;
  isLoading: boolean;
  userProfileImageUrl?: string;
  handleClose: () => void;
  unlockedZoneIds: Array<string>;
  locationsProgress: Array<{
    totalSpaces: number;
    spacesCollected: number;
    locationId: string;
  }>;
  currentLocationId?: string;
  ticketCount: number;
  avatarItems: Array<ISeasonLocationAvatarItem>;
  avatarItemGroups: Array<ISeasonLocationAvatarItemGroup>;
  isUnlockLoading: boolean;
  avatarAtlasUrl: string;
  avatarSkeletonUrl: string;
  avatar?: IAvatar;
  handleRequestUnlock?: (item: CombinedItem) => void;
  handleVisitLocation?: (location_id: string) => void;
}

const pannerBGFallbackColor = "#102421";
const season3SelectedLocationIdKey = "season_3_selected_location_id";

export const Season3Modal: React.FC<ISeason3Modal> = ({
  isOpen,
  isLoading,
  handleClose,
  unlockedZoneIds,
  locationsProgress,
  currentLocationId,
  ticketCount,
  userProfileImageUrl,
  handleRequestUnlock,
  avatarItems,
  avatarAtlasUrl,
  avatarItemGroups,
  avatarSkeletonUrl,
  isUnlockLoading,
  handleVisitLocation,
  avatar,
}) => {
  const history = useHistory();
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const [isMusicPlaying, setIsMusicPlaying] = useState(false);
  const [selectedLocationId, setSelectedLocationId] = useLocalStorage<string>(
    season3SelectedLocationIdKey
  );
  const [showBusAnim, setShowBusAnim] = useState(false);
  const busAnimInterval = useRef<NodeJS.Timer>();
  const { setBackgroundAudio } = useAudio();

  // remix-related
  const { trackEvent } = useAnalytics();
  const { handlePlaySession, handleSetRedirectToSeasonMap } = usePlaySession();
  useMount(() => {
    handleSetRedirectToSeasonMap(true);
  });
  const { liveSessionMap } = useStudentDashboardData();
  const { remixes } = useRemixes({ liveSessionMap });
  const handleRemixClick = (remix: IRemix) => {
    if (remix.session_id === "0") {
      if (remix.practice_set_id !== "0") {
        handlePlaySession({
          practiceSetId: remix.practice_set_id,
          classroomId: "0",
        });
      } else {
        handlePlaySession({
          subjectId: remix.subject_id,
          onDemandIntent: remix.on_demand_intent,
          practiceSetId: "0",
          classroomId: "0",
        });
      }

      trackEvent(AnalyticsEvent.StudentDashboard_SeasonMap_PlayRemix, {
        subjectId: remix.subject_id,
        onDemandIntent: remix.on_demand_intent,
      });
    } else {
      const session = liveSessionMap[remix.session_id];

      if (!session) return;

      history.push(`/session/join/${session.join_code}`, {
        redirectToSeasonMap: true,
      });

      trackEvent(AnalyticsEvent.StudentDashboard_SeasonMap_JoinRemix, {
        sessionId: remix.session_id,
        subjectId: remix.subject_id,
        onDemandIntent: remix.on_demand_intent,
      });
    }
  };

  // if current location id, then get position for scrolling
  const initialScrollCenter = useMemo(() => {
    if (!currentLocationId) return undefined;

    let locationData: ILocationData | undefined = undefined;

    mapData.some((zoneData) =>
      zoneData.locations.some((_locationData) => {
        const isMatch = _locationData.locationId === currentLocationId;

        if (isMatch) locationData = _locationData;

        return isMatch;
      })
    );

    if (locationData) {
      const { top, left } = (locationData as ILocationData).offset;

      return {
        x: left,
        y: top,
      };
    } else {
      return undefined;
    }
  }, [currentLocationId]);

  // disable music on modal close
  useEffect(() => {
    if (!isOpen) {
      setIsMusicPlaying(false);
      setBackgroundAudio();
    }
  }, [isOpen, setBackgroundAudio]);

  useUnmount(() => {
    if (busAnimInterval.current) {
      clearTimeout(busAnimInterval.current);
    }
  });

  const handleLocationSelect = (info: LocationInfo) => {
    setShowBusAnim(true);

    // Add small delay before changing the background scene to
    // allow transition animation to cover screen
    busAnimInterval.current = setTimeout(() => {
      setSelectedLocationId(info.id);
      handleVisitLocation?.(info.id);
    }, 1000);
  };

  const handleMusicToggle = () => {
    setBackgroundAudio(isMusicPlaying ? undefined : SeasonMapMusic.PR2024);
    setIsMusicPlaying((oldVal) => !oldVal);
  };

  const handleBack = () => {
    if (selectedLocationId) {
      setShowBusAnim(true);

      busAnimInterval.current = setTimeout(() => {
        setSelectedLocationId("");
      }, 1000);
    } else {
      handleClose();
    }
  };

  const handleHome = () => {
    history.push("/s/home");
  };

  const handleBusAnimationEnd = useCallback(() => {
    setShowBusAnim(false);
  }, []);

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalContent borderRadius={0} m={0} p={0} maxW={pxToRem(windowWidth)}>
          {isOpen && (
            <ModalBody
              m={0}
              p={0}
              position="relative"
              bgColor={pannerBGFallbackColor}
              bgImage={PannerBGSVG}
              bgSize="15%"
              bgRepeat="repeat"
            >
              <Season3MapHeader
                ticketCount={ticketCount}
                isMusicPlaying={isMusicPlaying}
                handleMusicToggle={handleMusicToggle}
                handleBack={handleBack}
                handleHome={handleHome}
              />
              <SeasonMapFooter
                remixes={remixes}
                handleRemixClick={handleRemixClick}
              />

              {isLoading && (
                <Box
                  w="full"
                  h="full"
                  position="absolute"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <LoadingSpinner />
                </Box>
              )}

              {!isLoading && !selectedLocationId && (
                <SeasonMapPanner
                  initialScrollCenterPos={initialScrollCenter}
                  initialScale={windowWidth >= 768 ? 0.5 : 0.4}
                  width={windowWidth}
                  height={windowHeight}
                  mapHeight={mapSize.height}
                  mapWidth={mapSize.width}
                  bgImage={PannerBGSVG}
                  bgRepeat="repeat"
                  bgSize="15%"
                  disableShadow={true}
                >
                  <Season3Map
                    unlockedZoneIds={unlockedZoneIds}
                    locationsProgress={locationsProgress}
                    currentLocationId={currentLocationId}
                    userImg={userProfileImageUrl}
                    handleLocationSelect={handleLocationSelect}
                  />
                </SeasonMapPanner>
              )}
              {!isLoading && !!selectedLocationId && !!avatar && (
                <Season3Location
                  canUnlock={ticketCount > 0}
                  width={windowWidth}
                  height={windowHeight}
                  locationId={selectedLocationId}
                  handleRequestUnlock={handleRequestUnlock}
                  avatarAtlasUrl={avatarAtlasUrl}
                  avatarItemGroups={avatarItemGroups.filter(
                    (i) => i.season_location_id === selectedLocationId
                  )}
                  avatarItems={avatarItems.filter(
                    (i) => i.season_location_id === selectedLocationId
                  )}
                  avatarSkeletonUrl={avatarSkeletonUrl}
                  avatar={avatar}
                  isLoading={isUnlockLoading}
                  pathColor={
                    mapData.find((zone) => {
                      return zone.locations.some(
                        (l) => l.locationId === selectedLocationId
                      );
                    })?.pathColor || ""
                  }
                />
              )}
            </ModalBody>
          )}
        </ModalContent>
      </Modal>
      <Season3MapTransition
        show={showBusAnim}
        handleComplete={handleBusAnimationEnd}
      />
    </>
  );
};
