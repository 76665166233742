import {
  Box,
  Button,
  HStack,
  Text,
  VStack,
  keyframes,
  useBreakpointValue,
  useDisclosure,
  useToken,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { usePrevious } from "react-use";

import { pxToRem } from "adminComponents/utils/pxToRem";
import {
  AvatarItemCategoryId,
  IAvatar,
  ISeasonLocationAvatarItem,
  ISeasonLocationAvatarItemGroup,
} from "links/lib/types";
import { getItemPreviewDataURL } from "links/lib/util";
import { ConfirmButton } from "sessionComponents/molecules/ConfirmButton";

import {
  SeasonPopover,
  SeasonPopoverAnchor,
  SeasonPopoverArrow,
  SeasonPopoverBody,
  SeasonPopoverContent,
} from "../SeasonPopover";
import { ReactComponent as SvgBlob1 } from "./resource/blob_1.svg";
import { ReactComponent as SvgBlob2 } from "./resource/blob_2.svg";
import { ReactComponent as SvgIconBack } from "./resource/icon_back.svg";
import { ReactComponent as SvgIconBottom } from "./resource/icon_bottom.svg";
import { ReactComponent as SvgIconCape } from "./resource/icon_cape.svg";
import { ReactComponent as SvgIconEarAccessories } from "./resource/icon_ear-accessories.svg";
import { ReactComponent as SvgIconEars } from "./resource/icon_ears.svg";
import { ReactComponent as SvgIconFacialDetail } from "./resource/icon_facial-details.svg";
import { ReactComponent as SvgIconFacialHair } from "./resource/icon_facial_hair.svg";
import { ReactComponent as SvgForehead } from "./resource/icon_forehead.svg";
import { ReactComponent as SvgIconGlasses } from "./resource/icon_glasses.svg";
import { ReactComponent as SvgIconHand } from "./resource/icon_hand.svg";
import { ReactComponent as SvgIconHat } from "./resource/icon_hat.svg";
import { ReactComponent as SvgLowerFace } from "./resource/icon_lower_face.svg";
import { ReactComponent as SvgIconMystery } from "./resource/icon_mystery-set.svg";
import { ReactComponent as SvgIconNeck } from "./resource/icon_neck.svg";
import { ReactComponent as SvgIconShoes } from "./resource/icon_shoes.svg";
import { ReactComponent as SvgIconSocks } from "./resource/icon_socks.svg";
import { ReactComponent as SvgIconTop } from "./resource/icon_top.svg";
import { ReactComponent as SvgIconWaist } from "./resource/icon_waist.svg";
import { ReactComponent as SvgIconWrist } from "./resource/icon_wrist.svg";
import { ReactComponent as SvgOpenBubble } from "./resource/open_bubble_1.svg";

interface ISeasonLocationProgressProps {
  avatarItems: Array<ISeasonLocationAvatarItem>;
  avatarItemGroups: Array<ISeasonLocationAvatarItemGroup>;
  pathColor: string;
  offsetX?: number;
  minWidth: number;
  handleRequestUnlock?: (item: CombinedItem) => void;
  handleSizeUpdate?: (
    barWidth: number,
    lastUnlockedPos: number,
    nextUnlockedPos?: number
  ) => void;
  isLoading: boolean;
  canUnlock: boolean;
  avatar: IAvatar;
}

interface ISeasonLocationProgressMarkerProps {
  item: CombinedItem;
  markerShape: "blob1" | "blob2";
  pathColor: string;
  markerSize: number;
  handleRequestUnlock?: (item: CombinedItem) => void;
  isLoading: boolean;
  canUnlock: boolean;
  avatar: IAvatar;
}

interface ISeasonLocationProgressMarkerButtonProps {
  item: CombinedItem;
  markerShape: "blob1" | "blob2";
  pathColor: string;
  markerSize: number;
  previewImageUrl: string;
  handleClick?: () => void;
}

interface ISeasonLocationProgressMarkerPopoverContent {
  item: CombinedItem;
  handleRequestUnlock?: (item: CombinedItem) => void;
  isLoading: boolean;
  canUnlock: boolean;
  previewImageUrl: string;
}

export type CombinedItem =
  | { type: "item"; content: ISeasonLocationAvatarItem }
  | { type: "group"; content: ISeasonLocationAvatarItemGroup };

const pathHeight = 16;
const progressFillColor = "primary.tan";
const unlockedMarkerColor = "#F5E6CD";

const pulseAnim = keyframes`
    0% {transform: scale(1) rotate(0deg);}
    25% {transform: scale(1.2) rotate(-10deg);}
    50% {transform: scale(1) rotate(0deg);}
    75% {transform: scale(1.2) rotate(10deg);}
    100% {transform: scale(1) rotate(0deg);}
  `;

const iconSVGMap: Record<string, React.ReactElement> = {
  [AvatarItemCategoryId.Mix]: <SvgIconMystery />,
  [AvatarItemCategoryId.Back]: <SvgIconBack />,
  [AvatarItemCategoryId.Bottom]: <SvgIconBottom />,
  [AvatarItemCategoryId.Ears]: <SvgIconEars />,
  [AvatarItemCategoryId.Ear_Accessories]: <SvgIconEarAccessories />,
  [AvatarItemCategoryId.Facial_Hair]: <SvgIconFacialHair />,
  [AvatarItemCategoryId.Facial_Detail]: <SvgIconFacialDetail />,
  [AvatarItemCategoryId.Glasses]: <SvgIconGlasses />,
  [AvatarItemCategoryId.Hat]: <SvgIconHat />,
  [AvatarItemCategoryId.Right_Hand]: <SvgIconHand />,
  [AvatarItemCategoryId.Left_Hand]: <SvgIconHand />,
  [AvatarItemCategoryId.Right_Wrist]: <SvgIconWrist />,
  [AvatarItemCategoryId.Lower_Face]: <SvgLowerFace color="#fff" />,
  [AvatarItemCategoryId.Left_Wrist]: <SvgIconWrist />,
  [AvatarItemCategoryId.Neck]: <SvgIconNeck />,
  [AvatarItemCategoryId.Shoes]: <SvgIconShoes />,
  [AvatarItemCategoryId.Socks]: <SvgIconSocks />,
  [AvatarItemCategoryId.Top]: <SvgIconTop />,
  [AvatarItemCategoryId.Waist]: <SvgIconWaist />,
  [AvatarItemCategoryId.Cape]: <SvgIconCape />,
  [AvatarItemCategoryId.Forehead]: <SvgForehead color="#fff" />,
};

const iconSVGMapDark: Record<string, React.ReactElement> = {
  ...iconSVGMap,
  [AvatarItemCategoryId.Lower_Face]: <SvgLowerFace />,
  [AvatarItemCategoryId.Forehead]: <SvgForehead />,
};

// munges list of category
const getGroupCategoryId = (
  categoryIds: Array<AvatarItemCategoryId>
): AvatarItemCategoryId => {
  if (!categoryIds.length) return AvatarItemCategoryId.Mix;

  const isHands = categoryIds.every(
    (c) =>
      c === AvatarItemCategoryId.Right_Hand ||
      c === AvatarItemCategoryId.Left_Hand
  );

  if (isHands) {
    return AvatarItemCategoryId.Right_Hand;
  }

  const isWrists = categoryIds.every(
    (c) =>
      c === AvatarItemCategoryId.Right_Wrist ||
      c === AvatarItemCategoryId.Left_Wrist
  );

  if (isWrists) {
    return AvatarItemCategoryId.Right_Wrist;
  }

  return AvatarItemCategoryId.Mix;
};

export const SeasonLocationProgress: React.FC<ISeasonLocationProgressProps> = ({
  pathColor,
  avatarItemGroups,
  avatarItems,
  offsetX = 100,
  minWidth,
  handleRequestUnlock,
  isLoading,
  handleSizeUpdate,
  canUnlock,
  avatar,
}) => {
  const markerSize =
    useBreakpointValue({
      base: 80,
      sm: 90,
      md: 100,
      lg: 110,
      xl: 120,
    }) || 80;
  const markerSpacing =
    useBreakpointValue({
      base: 50,
      sm: 60,
      md: 70,
      lg: 80,
    }) || 50;
  const isPhone = useBreakpointValue({
    base: true,
    sm: false,
  });

  // combine items and group items into single ordered array
  const combinedItems: Array<CombinedItem> = useMemo(() => {
    const items: Array<CombinedItem> = avatarItems.map((i) => ({
      type: "item",
      content: i,
    }));

    const groupItems: Array<CombinedItem> = avatarItemGroups.map((g) => ({
      type: "group",
      content: g,
    }));

    const combined: Array<CombinedItem> = items.concat(groupItems);

    combined.sort(
      (a, b) =>
        a.content.season_location_order - b.content.season_location_order
    );

    return combined;
  }, [avatarItems, avatarItemGroups]);

  // The total size of the progress bar and
  // the filled section
  const progressSize = useMemo(() => {
    // the total size of the bar
    let barWidth = offsetX;
    let fillWidth = 0;
    let allItemsUnlocked = false;
    let lastUnlockedPos = barWidth + markerSize / 2;
    let nextUnlockedPos: number | undefined = undefined;

    combinedItems.forEach((item, i) => {
      barWidth += markerSize;

      if (i > 0) {
        barWidth += markerSpacing;
      }

      if (item.content.is_unlocked) {
        lastUnlockedPos = barWidth - markerSize / 2;

        fillWidth += i === 0 ? offsetX : markerSpacing;
        fillWidth += i === 0 ? markerSize / 2 : markerSize;

        if (i === combinedItems.length - 1) allItemsUnlocked = true;
      } else if (
        item.content.can_unlock &&
        typeof nextUnlockedPos === "undefined"
      ) {
        nextUnlockedPos = barWidth - markerSize / 2;
      }
    });

    if (!isPhone) {
      barWidth += offsetX;
    } else {
      // ensure popover does not create overflow on
      // mobile phones
      barWidth += window.innerWidth;
    }

    barWidth = Math.max(barWidth, minWidth);

    if (allItemsUnlocked) fillWidth = barWidth;

    return { barWidth, fillWidth, lastUnlockedPos, nextUnlockedPos };
  }, [combinedItems, offsetX, markerSize, markerSpacing, minWidth, isPhone]);

  useEffect(() => {
    handleSizeUpdate?.(
      progressSize.barWidth,
      progressSize.lastUnlockedPos,
      progressSize.nextUnlockedPos
    );
  }, [progressSize, handleSizeUpdate]);

  return (
    <Box minW="full" position="relative" w="fit-content">
      <Box
        bgColor={pathColor}
        h={pxToRem(pathHeight)}
        w={pxToRem(progressSize.barWidth)}
        position="absolute"
        top={`calc(50% - ${pxToRem(pathHeight / 2)})`}
      >
        <Box
          bgColor={progressFillColor}
          h="full"
          w={pxToRem(progressSize.fillWidth)}
        />
      </Box>
      <HStack
        spacing={pxToRem(markerSpacing)}
        marginX={pxToRem(offsetX)}
        w="fit-content"
      >
        {combinedItems.map((c, i) => (
          <SeasonLocationProgressMarker
            key={c.content.id}
            avatar={avatar}
            pathColor={pathColor}
            canUnlock={canUnlock}
            item={c}
            markerShape={i % 2 ? "blob1" : "blob2"}
            markerSize={markerSize}
            handleRequestUnlock={handleRequestUnlock}
            isLoading={isLoading}
          />
        ))}
      </HStack>
    </Box>
  );
};

const SeasonLocationProgressMarkerButton = React.forwardRef<
  HTMLButtonElement,
  ISeasonLocationProgressMarkerButtonProps
>(
  (
    { item, pathColor, markerSize, markerShape, handleClick, previewImageUrl },
    ref
  ) => {
    // locked markers appear smaller than unlocked markers
    const lockedMarkerDisplaySize =
      useBreakpointValue({
        base: 50,
        sm: 60,
        md: 70,
        lg: 80,
        xl: 90,
      }) || 50;

    const focusColor = useToken("colors", "utility.focus");

    return (
      <Button
        zIndex={2}
        ref={ref}
        display="flex"
        position="relative"
        boxSize={pxToRem(markerSize)}
        key={item.content.id}
        variant="unstyled"
        flexShrink={0}
        p={0}
        alignItems="center"
        justifyContent="center"
        borderRadius="full"
        onClick={handleClick}
        animation={
          item.content.can_unlock
            ? `${pulseAnim} infinite 3s ease-in-out`
            : undefined
        }
        sx={{
          _focus: {
            outline: "none",
            transform: `scale(1.1)`,
          },
          _focusVisible: {
            outline: `2px solid ${focusColor}`,
            transform: `scale(1.1)`,
          },
        }}
        transition="all 200ms ease-in-out"
        _hover={{
          transform: `scale(1.1)`,
        }}
      >
        {/* Button Background */}
        <Box
          as="span"
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {item.content.is_unlocked && (
            <SvgOpenBubble
              color={unlockedMarkerColor}
              width="100%"
              height="100%"
            />
          )}
          {!item.content.is_unlocked && (
            <Box
              as="span"
              w={pxToRem(lockedMarkerDisplaySize)}
              h={pxToRem(lockedMarkerDisplaySize)}
            >
              {markerShape === "blob1" ? (
                <SvgBlob1 color={pathColor} width="100%" height="100%" />
              ) : (
                <SvgBlob2 color={pathColor} width="100%" height="100%" />
              )}
            </Box>
          )}
        </Box>

        {/* Button Content */}
        {!item.content.is_unlocked && (
          <Box
            as="span"
            position="relative"
            display="block"
            boxSize={
              item.content.is_unlocked
                ? "60%"
                : pxToRem(lockedMarkerDisplaySize * 0.6)
            }
            sx={{
              svg: {
                fill: item.content.is_unlocked ? "black" : "white",
              },
            }}
          >
            {item.type === "item" &&
              iconSVGMap[item.content.avatar_item_category_id]}
            {item.type === "group" &&
              iconSVGMap[
                getGroupCategoryId(item.content.avatar_item_category_ids)
              ]}
          </Box>
        )}
        {item.content.is_unlocked && (
          <Box
            as="span"
            position="relative"
            display="block"
            boxSize="60%"
            bgImage={`url("${previewImageUrl}")`}
            bgRepeat="no-repeat"
            bgPosition="center center"
            bgSize="contain"
          />
        )}
      </Button>
    );
  }
);
SeasonLocationProgressMarkerButton.displayName =
  "SeasonLocationProgressMarkerButton";

const SeasonLocationProgressMarkerPopoverContent: React.FC<
  ISeasonLocationProgressMarkerPopoverContent
> = ({ handleRequestUnlock, item, isLoading, canUnlock, previewImageUrl }) => {
  const { t } = useTranslation("map", {
    keyPrefix: "location.progress.popup",
    useSuspense: false,
  });
  const unlockButtonSize = useBreakpointValue(["sm", "sm", "md"]);

  const nameFontSize = useBreakpointValue([
    pxToRem(14),
    pxToRem(16),
    pxToRem(20),
    pxToRem(24),
  ]);
  const descFontSize = useBreakpointValue([
    pxToRem(12),
    pxToRem(12),
    pxToRem(14),
    pxToRem(16),
  ]);
  const padding = useBreakpointValue([
    pxToRem(6),
    pxToRem(8),
    pxToRem(10),
    pxToRem(14),
  ]);

  const itemCategoryNameMap: Record<string, string> = useMemo(() => {
    return {
      [AvatarItemCategoryId.Mix]: t("itemCategoryMix"),
      [AvatarItemCategoryId.Back]: t("itemCategoryBack"),
      [AvatarItemCategoryId.Bottom]: t("itemCategoryBottom"),
      [AvatarItemCategoryId.Ears]: t("itemCategoryEars"),
      [AvatarItemCategoryId.Ear_Accessories]: t("itemCategoryEarAccessories"),
      [AvatarItemCategoryId.Facial_Hair]: t("itemCategoryFacialHair"),
      [AvatarItemCategoryId.Facial_Detail]: t("itemCategoryFacialDetail"),
      [AvatarItemCategoryId.Forehead]: t("itemCategoryForehead"),
      [AvatarItemCategoryId.Glasses]: t("itemCategoryGlasses"),
      [AvatarItemCategoryId.Hat]: t("itemCategoryHat"),
      [AvatarItemCategoryId.Right_Hand]: t("itemCategoryHands"),
      [AvatarItemCategoryId.Left_Hand]: t("itemCategoryHands"),
      [AvatarItemCategoryId.Right_Wrist]: t("itemCategoryWrist"),
      [AvatarItemCategoryId.Lower_Face]: t("itemCategoryLowerFace"),
      [AvatarItemCategoryId.Left_Wrist]: t("itemCategoryWrist"),
      [AvatarItemCategoryId.Neck]: t("itemCategoryNeck"),
      [AvatarItemCategoryId.Shoes]: t("itemCategoryShoes"),
      [AvatarItemCategoryId.Socks]: t("itemCategorySocks"),
      [AvatarItemCategoryId.Top]: t("itemCategoryTop"),
      [AvatarItemCategoryId.Waist]: t("itemCategoryWaist"),
      [AvatarItemCategoryId.Cape]: t("itemCategoryCape"),
    };
  }, [t]);

  return (
    <Box p={padding}>
      <VStack w="full" spacing={pxToRem(10)}>
        <Text
          fontSize={nameFontSize}
          textAlign="center"
          textStyle="gameDisplay"
        >
          {item.content.is_unlocked && item.content.name}
          {!item.content.is_unlocked &&
            item.type === "item" &&
            itemCategoryNameMap[item.content.avatar_item_category_id]}
          {!item.content.is_unlocked &&
            item.type === "group" &&
            itemCategoryNameMap[
              getGroupCategoryId(item.content.avatar_item_category_ids)
            ]}
        </Text>
        <Text
          fontSize={descFontSize}
          textAlign="center"
          textStyle="gameText"
          lineHeight="110%"
        >
          {item.content.is_unlocked && item.content.description}

          {!item.content.is_unlocked && t("lockedItemDescription")}
        </Text>
        {item.content.is_unlocked && (
          <Box
            w="full"
            h={pxToRem(100)}
            backgroundImage={`url("${previewImageUrl}")`}
            backgroundPosition="center center"
            backgroundSize="contain"
            backgroundRepeat="no-repeat"
          />
        )}
        {!item.content.is_unlocked && (
          <Box
            w="full"
            h={pxToRem(100)}
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              svg: {
                fill: "black",
                maxW: "100%",
                maxH: "100%",
              },
            }}
          >
            {item.type === "group" &&
              iconSVGMapDark[
                getGroupCategoryId(item.content.avatar_item_category_ids)
              ]}
            {item.type === "item" &&
              iconSVGMapDark[item.content.avatar_item_category_id]}
          </Box>
        )}

        {item.content.can_unlock && !item.content.is_unlocked && (
          <ConfirmButton
            variant="buttonFilled"
            handleConfirmClick={() => handleRequestUnlock?.(item)}
            size={unlockButtonSize}
            confirmedWhenChanged={[canUnlock]}
            isLoading={isLoading}
            disabled={!canUnlock}
          >
            {t("unlockButton")}
          </ConfirmButton>
        )}
      </VStack>
    </Box>
  );
};

const SeasonLocationProgressMarker: React.FC<
  ISeasonLocationProgressMarkerProps
> = ({
  item,
  pathColor,
  markerSize,
  markerShape,
  handleRequestUnlock,
  isLoading,
  canUnlock,
  avatar,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const popoverPlacement: "top" | "auto" =
    useBreakpointValue({ base: "auto", lg: "top" }) ?? "top";
  const [previewImageUrl, setPreviewImageUrl] = useState(
    item.content.preview_image_asset_url
  );

  // Close popover when item's unlock status changes. This
  // prevents the popover from getting stuck when its target element
  // is changed.
  const unlockStatus = item.content.is_unlocked;
  const prevUnlockStatus = usePrevious(unlockStatus);
  useEffect(() => {
    if (
      typeof prevUnlockStatus !== "undefined" &&
      unlockStatus !== prevUnlockStatus
    ) {
      onClose();
    }
  }, [prevUnlockStatus, unlockStatus, onClose]);

  // Replace colors in preview image URL
  useEffect(() => {
    getItemPreviewDataURL(
      item.content.preview_image_asset_url,
      item.content.default_replacement_hex_code,
      avatar.hair_color_hex_code,
      avatar.skin_tone_hex_code
    ).then(setPreviewImageUrl);
  }, [
    item.content.preview_image_asset_url,
    item.content.default_replacement_hex_code,
    avatar.hair_color_hex_code,
    avatar.skin_tone_hex_code,
  ]);

  return (
    <SeasonPopover
      onOpen={onOpen}
      isOpen={isOpen}
      onClose={onClose}
      placement={popoverPlacement}
      returnFocusOnClose
      closeOnBlur
      computePositionOnMount={true}
    >
      <SeasonPopoverAnchor>
        <SeasonLocationProgressMarkerButton
          item={item}
          pathColor={pathColor}
          markerSize={markerSize}
          markerShape={markerShape}
          handleClick={onOpen}
          previewImageUrl={previewImageUrl}
        />
      </SeasonPopoverAnchor>
      <SeasonPopoverContent>
        <SeasonPopoverArrow />
        <SeasonPopoverBody>
          <SeasonLocationProgressMarkerPopoverContent
            item={item}
            handleRequestUnlock={handleRequestUnlock}
            isLoading={isLoading}
            canUnlock={canUnlock}
            previewImageUrl={previewImageUrl}
          />
        </SeasonPopoverBody>
      </SeasonPopoverContent>
    </SeasonPopover>
  );
};
