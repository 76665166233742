import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { UserRecurringNotificationSettings } from "../../types";

export interface IFetchUserRecurringNotificationSettingsParams {
  user_id: string;
}

export interface IFetchUserRecurringNotificationSettingsResponse {
  user_id: string;
  settings: UserRecurringNotificationSettings;
}

/**
 * Fetches the recurring notification settings of a user
 * @returns
 */
export default function useFetchUserRecurringNotificationSettings(
  params: IFetchUserRecurringNotificationSettingsParams
): UseQueryResult<IFetchUserRecurringNotificationSettingsResponse, unknown> {
  const axios = useAxios();

  const { user_id } = params;

  const reactQuery = useQuery<IFetchUserRecurringNotificationSettingsResponse>(
    ["user-notification-settings", params],
    async (): Promise<IFetchUserRecurringNotificationSettingsResponse> => {
      return await axios
        .get(`/v1/users/${user_id}/recurring-notifications/settings`)
        .then((r) => r.data as IFetchUserRecurringNotificationSettingsResponse);
    }
  );

  return reactQuery;
}
