import React, { useEffect } from "react";
import { usePrevious } from "react-use";

import { useSession } from "links/lib/contexts/session";
import { useSessionActions } from "links/lib/contexts/sessionActions";
import { UserRole, WebSocketSession } from "links/lib/types";
import { getRandomArrayElement } from "links/lib/util";
import { TeacherLobby as TheTeacherLobby } from "sessionComponents/organisms/TeacherLobby";
import { TeacherSessionSoundEffect } from "sessionComponents/types";
import { useSessionAudio } from "sharedComponents/contexts/sessionAudio";
import { useSafeConnectedUserArray } from "sharedComponents/hooks/useSafeConnectedUserArray";

const joinSoundEffects = [
  TeacherSessionSoundEffect.Hello1,
  TeacherSessionSoundEffect.Hello2,
  TeacherSessionSoundEffect.Hello3,
  TeacherSessionSoundEffect.Hello4,
  TeacherSessionSoundEffect.Hello5,
  TeacherSessionSoundEffect.Hello6,
  TeacherSessionSoundEffect.Hello7,
  TeacherSessionSoundEffect.Hello8,
  TeacherSessionSoundEffect.Hello9,
  TeacherSessionSoundEffect.Hello10,
  TeacherSessionSoundEffect.Hello11,
  TeacherSessionSoundEffect.Hello12,
  TeacherSessionSoundEffect.Hello13,
  TeacherSessionSoundEffect.Hello14,
  TeacherSessionSoundEffect.Hello15,
  TeacherSessionSoundEffect.Hello16,
  TeacherSessionSoundEffect.Hello17,
  TeacherSessionSoundEffect.Hello18,
  TeacherSessionSoundEffect.Hello19,
];

//todo consolidate and use fake data for storybook
export const TeacherLobby: React.FC = () => {
  const connectedUsers = useSafeConnectedUserArray();
  const session = useSession() as WebSocketSession;
  const { initiateGrouping } = useSessionActions();
  const { play: playAudio } = useSessionAudio();

  const onlineStudents = connectedUsers.filter(
    (user) => user.role === UserRole.Student
  );

  const prevUsersCount = usePrevious(onlineStudents.length) ?? 0;
  useEffect(() => {
    if (onlineStudents.length > prevUsersCount) {
      playAudio(getRandomArrayElement(joinSoundEffects));
    }
  }, [prevUsersCount, onlineStudents.length, playAudio]);

  return (
    <TheTeacherLobby
      sessionCode={session.code}
      onlineStudents={onlineStudents}
      onStart={initiateGrouping}
    />
  );
};
