import { pxToRem } from "adminComponents/utils/pxToRem";

export const AdminAvatarPreview = {
  baseStyle: {
    container: {
      maxW: {
        base: pxToRem(136),
        md: pxToRem(256),
        lg: pxToRem(516),
      },
      m: "0 auto",
      "> div": {
        w: "100%",
        h: "0",
        padding: "50% 0",
        transform: "translate3d(0,0,0)",
        bgColor: "white",
        borderRadius: "50%",
        overflow: "hidden",
        position: "relative",
      },
    },
  },
};
