import { Flex } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { IAlternateTextResponse } from "links/lib/types";

export interface IProps {
  answer: string;
  alternateAnswers?: Array<IAlternateTextResponse>;
}

export const AnswerTextResponse: React.FC<IProps> = ({
  answer,
  alternateAnswers,
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });
  return (
    <Flex direction="column" gap={{ base: pxToRem(4), md: pxToRem(12) }}>
      <Text variant="adminP2">{answer}</Text>
      {alternateAnswers &&
        alternateAnswers.map((alternateAnswer, index) => (
          <Text
            key={index}
            variant="adminP2"
            color="primary.dark-gray"
            fontStyle="italic"
          >
            {t("practiceSetQuestionCard.labelAlternateCorrectResponse")}:{" "}
            {alternateAnswer.alternate_text}
          </Text>
        ))}
    </Flex>
  );
};
