import {
  hairReservedColor,
  joinCodeAdjectives,
  joinCodeInanimatePluralNouns,
  joinCodeNumbers,
  joinCodeVerbs,
  skinReservedColor,
  userReservedColor,
} from "./constants";
import { IAvatar, IAvatarItem, ISessionDrawStroke, RichValue } from "./types";

export const getRandomItem = <T>(a: Array<T>): T => {
  return a[Math.floor(Math.random() * a.length)];
};

export const capitalizeFirstLetter = (text: string): string => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const base64toBlob = (
  b64Data: string,
  contentType = "",
  sliceSize = 512
): Blob => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const getUrlContentType = (
  url: string,
  cb: (contentType: string | null) => void
): void => {
  const xhr = new XMLHttpRequest();
  xhr.open("HEAD", url);
  xhr.onreadystatechange = function () {
    if (this.readyState === this.DONE) {
      cb(this.getResponseHeader("Content-Type"));
    }
  };
  xhr.onerror = () => {
    cb("");
  };
  xhr.send();
};

export const getItemPreviewDataURL = async (
  imageUrl: string,
  itemColor: string,
  hairColor: string,
  skinToneColor: string
): Promise<string> => {
  // Try to get the image. If that fails, try getting it
  // with cache busting. This is necessary because for some
  // presently unknown reason, preview images are failing to
  // load for granted items with a CORS error and clearing
  // browser cache fixes that. This should ensure preview images
  // for granted items can be loaded post-grant.
  // TODO: Fix underlying cause
  const response = await fetch(imageUrl).catch(() => {
    return fetch(imageUrl + "?" + Math.random());
  });

  const svgContent = await response.text();

  const replacedSvgContent = svgContent
    .slice()
    .replaceAll(hairReservedColor, hairColor)
    .replaceAll(hairReservedColor.toUpperCase(), hairColor)
    .replaceAll(skinReservedColor, skinToneColor)
    .replaceAll(skinReservedColor.toUpperCase(), skinToneColor)
    .replaceAll(userReservedColor, itemColor)
    .replaceAll(userReservedColor.toUpperCase(), itemColor);

  const base64SVGContent = btoa(replacedSvgContent);

  return `data:image/svg+xml;base64,${base64SVGContent}`;
};

export const getAvatarItemPreviewDataURL = async (
  item: IAvatarItem,
  avatar: IAvatar
): Promise<string> => {
  const { preview_image_asset_url } = item;

  const previewImageAssetURL = preview_image_asset_url
    ? preview_image_asset_url
    : item.attachments.length
    ? item.attachments[0].image_asset_url
    : "";

  if (!previewImageAssetURL) {
    return "";
  }

  const { hair_color_hex_code, skin_tone_hex_code } = avatar;

  let userReplaceColor = item.default_replacement_hex_code;
  item.colors.find((color) => {
    if (color.is_active) {
      userReplaceColor = color.hex_code;
      return true;
    }
  });

  return await getItemPreviewDataURL(
    previewImageAssetURL,
    userReplaceColor,
    hair_color_hex_code,
    skin_tone_hex_code
  );
};

/**
 * Merges string and slate rich text values into a single slate rich text representation
 * @param args
 * @returns
 */
export const mergeRichText = (...args: Array<unknown>): RichValue => {
  const mergedRichText: Array<{
    type: "paragraph";
    children: Array<unknown>;
  }> = [
    {
      type: "paragraph",
      children: [],
    },
  ];

  args.forEach((a) => {
    try {
      if (!a) {
        return;
      } else if (typeof a === "string") {
        mergedRichText[0].children.push({ text: a });
      } else {
        if (!Array.isArray(a) || !a.length) return;

        a.forEach((child) => {
          mergedRichText[0].children = mergedRichText[0].children.concat(
            child.children
          );
        });
      }
    } catch (e) {
      console.warn("Could not merge rich text arg", e);
    }
  });

  return mergedRichText as RichValue;
};

export const generateEmptySlateState = (text?: string): RichValue => {
  return [{ type: "paragraph", children: [{ text: text ?? "" }] }];
};

export const emptySlate = JSON.stringify(generateEmptySlateState());

export const isEmptyTextOrSlate = (text?: string): boolean => {
  if (!text) return true;
  if (text === "") return true;
  if (text === emptySlate) return true;
  return false;
};

export const getJoinCodePhoneticString = (characters: string): string => {
  // Join codes are expected to be in this format
  // [Adjective] [Inanimate Plural Noun] [Verb] [Number] [Adjective] [Inanimate Plural Noun]

  const words: Array<string> = [];

  for (let i = 0; i < characters.length; i++) {
    const character = characters[i].toLowerCase();

    let characterWords: Array<string> = [];
    if (i == 0 || i == 4) {
      characterWords = joinCodeAdjectives[character];
    } else if (i == 1 || i == 5) {
      characterWords = joinCodeInanimatePluralNouns[character];
    } else if (i == 2) {
      characterWords = joinCodeVerbs[character];
    } else if (i == 3) {
      characterWords = joinCodeNumbers[character];
    }

    // Fail-safe for backwards compatibility
    if (!characterWords) {
      characterWords = joinCodeInanimatePluralNouns[character];
    }
    if (!characterWords) {
      characterWords = joinCodeNumbers[character];
    }

    const word =
      characterWords[Math.floor(Math.random() * characterWords.length)];

    const titleCaseWord = word.charAt(0).toUpperCase() + word.slice(1);

    words.push(titleCaseWord);
  }

  return words.join(" ");
};

export const getRandomArrayElement = <T>(array: Array<T>): T => {
  return array[Math.floor(Math.random() * array.length)];
};

export const getNRandomArrayElements = <T>(
  array: Array<T>,
  n: number
): [Array<T>, Array<T>] => {
  const remaining = [...array];
  const selected: Array<T> = [];
  for (let i = 0; i < n && remaining.length > 0; i++) {
    const selectedIndex = Math.floor(Math.random() * remaining.length);
    selected.push(remaining[selectedIndex]);
    remaining.splice(selectedIndex, 1);
  }
  return [selected, remaining];
};

export const getModuloArrayElement = <T>(
  array: Array<T>,
  dividend: number
): T => {
  return array[dividend % array.length];
};

export const isSameDate = (a: Date, b: Date): boolean => {
  return (
    a.getDay() === b.getDay() &&
    a.getFullYear() === b.getFullYear() &&
    a.getMonth() === b.getMonth()
  );
};

/**
 * Sorts two string parameters alphabetically
 * @param a First sort param
 * @param b Second sort param
 * @param desc Sort descending (default false)
 * @returns
 */
export const sortAlpha = (a: string, b: string, desc = false): number => {
  const compareA = a.toLowerCase();
  const compareB = b.toLowerCase();

  let val = 0;

  if (compareA < compareB) {
    val = -1;
  } else if (compareB < compareA) {
    val = 1;
  }

  if (desc) val *= -1;

  return val;
};

export const getCanvasAsImage = (
  strokes: Array<ISessionDrawStroke>,
  width: number,
  height: number,
  background?: HTMLImageElement
): string | null => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) return null;

  if (!strokes.length) return null;

  // appropriately scale canvas to map to device ratio
  const pixelRatio = window.devicePixelRatio;
  canvas.width = width * pixelRatio;
  canvas.height = height * pixelRatio;
  canvas.style.width = width + "px";
  canvas.style.height = height + "px";
  ctx.scale(pixelRatio, pixelRatio);

  // draw
  ctx.clearRect(0, 0, width, height);

  ctx.fillStyle = "white";
  ctx.fillRect(0, 0, width, height);

  if (background) {
    const width = background.width * pixelRatio;
    const height = background.height * pixelRatio;
    const hRatio = canvas.width / width;
    const vRatio = canvas.height / height;
    const ratio = Math.min(hRatio, vRatio);
    const centerShiftX = (canvas.width - width * ratio) / 2 / pixelRatio;
    const centerShiftY = (canvas.height - height * ratio) / 2 / pixelRatio;

    ctx.drawImage(
      background,
      0,
      0,
      width,
      height,
      centerShiftX,
      centerShiftY,
      width * ratio,
      height * ratio
    );
  }

  const penScale = Math.min(width / 400, height / 300);

  ctx.lineWidth = 4 * penScale;
  ctx.lineCap = "round";
  ctx.lineJoin = "round";

  strokes.forEach((s) => {
    if (!s.parts.length) return;

    ctx.strokeStyle = s.color;

    ctx.beginPath();
    ctx.moveTo(s.parts[0].start.x * width, s.parts[0].start.y * height);

    s.parts.forEach((p) => {
      ctx.lineTo(p.end.x * width, p.end.y * height);
    });
    ctx.stroke();
  });

  return canvas.toDataURL("image/png");
};

export const getImageElement = (
  imageUrl: string
): Promise<HTMLImageElement> => {
  const image = new Image();
  image.crossOrigin = "anonymous";

  return new Promise((resolve, reject) => {
    if (!imageUrl) {
      resolve(image);
      return;
    }

    image.src = imageUrl;

    image.onload = () => {
      resolve(image);
    };

    image.onerror = () => {
      reject();
    };
  });
};

export const redirectIfEmpty = (val: string, redirectTo: string): void => {
  if (val.trim().length < 1) {
    window.location.href = redirectTo;
  }
};

export const redirectIfNaN = (id: string, redirectTo: string): void => {
  const parsedId = parseInt(id, 10);

  if (isNaN(parsedId) || parsedId <= 0) {
    window.location.href = redirectTo;
  }
};

export const toKebabCase = (str: string): string => {
  return str
    .trim()
    .replace(/([a-z])([A-Z])/g, "$1-$2")
    .replace(/[\s_]+/g, "-")
    .toLowerCase();
};

export const getBrandConditionedAgreementUrl = (
  originalUrl: string
): string => {
  originalUrl = originalUrl.trim().toLowerCase();

  switch (originalUrl) {
    case "https://www.giantsteps.app/policies/website-terms":
      return "https://www.peardeck.com/policies/product-terms-and-end-user-license-agreement";
    case "https://www.giantsteps.app/policies/product-privacy":
      return "https://www.peardeck.com/policies/privacy-policy-for-product-users";
    case "https://www.peardeck.com/policies/product-terms-and-end-user-license-agreement":
      return originalUrl;
    case "https://www.peardeck.com/policies/privacy-policy-for-product-users":
      return originalUrl;
    default:
      console.error("Pear Practice agreement URL not found for:", originalUrl);
      return originalUrl;
  }
};
