import { Box, Flex, Show } from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useMount } from "react-use";

import { Card } from "adminComponents/atoms/Card";
import {
  IOnboardingForm,
  TeacherOnboarding,
} from "adminComponents/screens/TeacherOnboarding";
import { TemplateWithVerticalCenteredContent } from "adminComponents/templates/TemplateWithVerticalCenteredContent";
import { generateTeacherName, pxToRem } from "adminComponents/utils";
import { useErrorToast, useShowToast } from "adminComponents/utils/toast";
import { useAnalytics, usePageTrack } from "lib/contexts/analytics";
import { useHubSpot } from "lib/hooks/useHubSpot";
import { usePageTitle } from "lib/hooks/usePageTitle";
import { useAuth } from "links/lib/features/auth";
import { useGeo } from "links/lib/features/geo";
import { useFetchGradeLevels } from "links/lib/features/gradeLevels";
import { useFetchSubjects } from "links/lib/features/subjects";
import { useCompleteTeacherOnboarding } from "links/lib/features/users";
import {
  AnalyticsEvent,
  AnalyticsPage,
  SharingSettingsVisibility,
} from "links/lib/types";
import { HeaderWithLanguagePicker } from "sharedComponents/atoms/HeaderWithLanguagePicker";
import { useLanguageDropdown } from "sharedComponents/hooks/useLanguageDropdown";

export interface ITeacherOnboardProps {
  redirect?: string;
}

export const TeacherOnboard: React.FC<ITeacherOnboardProps> = ({
  redirect = "/t/tour",
}) => {
  const { authUser, authSharingSettings, authRequiredAgreements } = useAuth();
  const history = useHistory();
  const showToast = useShowToast();
  const { geo } = useGeo();
  const { t } = useTranslation("admin", {
    keyPrefix: "teacherOnboardContainer",
  });
  const { trackEvent } = useAnalytics();
  const { trackHubSpotEvent } = useHubSpot();
  const [selectedCountry, setSelectedCountry] = useState<string | undefined>(
    authUser?.country
  );

  usePageTrack(AnalyticsPage.TeacherOnboard);
  usePageTitle(t("pageTitle"));

  const {
    isLoading: isCompleteTeacherOnboardingLoading,
    isSuccess: isCompleteTeacherOnboardingSuccess,
    mutate: completeTeacherOnboarding,
    error: completeTeacherOnboardingError,
  } = useCompleteTeacherOnboarding();

  useMount(() => {
    trackEvent(AnalyticsEvent.TeacherDashboard_Onboard_Begin, {});
  });

  useEffect(() => {
    if (!isCompleteTeacherOnboardingSuccess) return;

    showToast(t("successToast"));

    trackEvent(AnalyticsEvent.TeacherDashboard_Onboard_Complete, {});
    trackHubSpotEvent(AnalyticsEvent.HubSpot_AccountCreated);

    // Allow account creating animation to play briefly
    setTimeout(() => {
      history.push(redirect);
    }, 3000);
  }, [
    isCompleteTeacherOnboardingSuccess,
    showToast,
    t,
    history,
    trackEvent,
    trackHubSpotEvent,
    redirect,
  ]);

  useErrorToast(completeTeacherOnboardingError);

  const gradeLevels =
    useFetchGradeLevels({ limit: 50, country: selectedCountry || "" }).data
      ?.gradeLevels || [];

  const subjectsQuery = useFetchSubjects({
    limit: 50,
    country: selectedCountry || "",
  });

  useEffect(() => {
    if (geo && !selectedCountry && geo.country_short !== "-") {
      setSelectedCountry(geo.country_short);
    }
  }, [geo, selectedCountry]);

  const teacherGradeLevelIds = authUser?.teacher_grade_levels?.map(
    (teacherGradeLevel) => teacherGradeLevel.grade_level_id
  );

  const teacherSubjectIds = authUser?.teacher_subjects?.map(
    (teacherSubject) => teacherSubject.subject_id
  );

  const isLoading = subjectsQuery.isLoading;

  const subjects = (subjectsQuery.data?.subjects || []).filter(
    (subject) => subject.parent_id === "0"
  );

  const parentSubjects = useMemo(() => {
    return subjects.flatMap((s) => (s.parent_id === "0" ? [s] : []));
  }, [subjects]);

  const agreements = authRequiredAgreements || [];

  const handleSubmit = (form: IOnboardingForm) => {
    completeTeacherOnboarding({
      user: {
        teacher_preferred_name: form.nickname.trim(),
        teacher_title: form.title,
        teacher_school_or_district_name: form.schoolOrDistrictName,
        teacher_school_or_district_postal_code: form.schoolOrDistrictPostalCode,
        timezone_locale: form.timezone.replaceAll(" ", "_"),
        grade_level_ids: form.grades,
        subject_ids: form.subjects,
        language: form.language,
        country: form.country,
        region: form.state,
      },
      acceptedAgreementIds: form.acceptedAgreementIds,
      visibility: form.sharingVisibility,
    });
  };

  const langDropdown = useLanguageDropdown();

  // authUser is validated in TeacherDashboard, this is just
  // for the benefit of TypeScript
  if (!authUser) return <></>;

  return (
    <TemplateWithVerticalCenteredContent
      isLoading={isLoading}
      headerContent={<HeaderWithLanguagePicker />}
      bgColor="primary.tan-light"
    >
      <Box
        w={
          isCompleteTeacherOnboardingLoading ||
          isCompleteTeacherOnboardingSuccess
            ? "auto"
            : "full"
        }
        mb={pxToRem(30)}
      >
        <Flex
          w="full"
          h="full"
          alignContent="center"
          justifyContent="center"
          px={{ base: "admin.mobileXPadding", md: "admin.desktopXPadding" }}
          flexDirection="column"
        >
          {!completeTeacherOnboardingError && (
            <Card
              variant="adminCardMediumBorder"
              borderColor="primary.tan"
              backgroundColor="primary.white"
              pl={{ base: "admin.mobileXPadding", lg: "0" }}
              pr={{ base: "admin.mobileXPadding", lg: pxToRem(5) }}
              py={{ base: "admin.mobileYPadding", lg: pxToRem(5) }}
            >
              <TeacherOnboarding
                agreements={agreements}
                handleSubmit={handleSubmit}
                isFormLoading={isCompleteTeacherOnboardingLoading}
                isOnboardingSuccess={isCompleteTeacherOnboardingSuccess}
                defaultValues={{
                  acceptedAgreementIds: [],
                  country: selectedCountry,
                  grades: teacherGradeLevelIds,
                  selectedGrades: gradeLevels
                    .filter((gl) => teacherGradeLevelIds?.includes(gl.id))
                    .map((gl) => ({ label: gl.grade_level, value: gl.id })),
                  language: authUser.language,
                  title: authUser.teacher_title,
                  nickname:
                    authUser.teacher_preferred_name ||
                    generateTeacherName(authUser).primary,
                  state:
                    authUser.region || (geo?.region !== "-" ? geo?.region : ""),
                  subjects: teacherSubjectIds,
                  subjectIndexes: parentSubjects.map((ps) => {
                    return teacherSubjectIds?.includes(ps.id) || false;
                  }),
                  timezone:
                    Intl.DateTimeFormat()
                      .resolvedOptions()
                      .timeZone.replaceAll("_", " ") ||
                    authUser.timezone_locale.replaceAll("_", " "),
                  schoolOrDistrictName:
                    authUser.teacher_school_or_district_name,
                  schoolOrDistrictPostalCode:
                    authUser.teacher_school_or_district_postal_code,
                  sharingVisibility:
                    authSharingSettings?.visibility ||
                    SharingSettingsVisibility.Public,
                }}
              />
            </Card>
          )}
        </Flex>
      </Box>
      <Show below="lg">
        <Box mb={pxToRem(30)}>{langDropdown}</Box>
      </Show>
    </TemplateWithVerticalCenteredContent>
  );
};
