import { Box, Circle, HStack, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { pxToRem } from "adminComponents/utils";
import { SessionGameType } from "links/lib/types";
import { Icon } from "sessionComponents/atoms/Icon";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";

export interface IProps {
  outerGame: SessionGameType;
  sessionCode: string;
  sessionCreatorName?: string;
}

export const StudentSessionIndicator: React.FC<IProps> = ({
  outerGame,
  sessionCode,
  sessionCreatorName,
}) => {
  const { match: currentBreakpoints } = useBreakpoints();

  const { t } = useTranslation("session", {
    useSuspense: false,
  });

  const fontSize = pxToRem(currentBreakpoints.fontSize / 1.75);
  const generalPadding = currentBreakpoints.padding / 2;
  const padding = pxToRem(generalPadding);
  const horizontalPadding = pxToRem(generalPadding * 1.5);

  return (
    <HStack
      borderRadius="2xl"
      background="primary.golden-light"
      width="fit-content"
      spacing={pxToRem(currentBreakpoints.margin / 2)}
      px={horizontalPadding}
      py={padding}
    >
      <Box position="relative">
        <Circle
          bgColor="primary.warm-black"
          sx={{
            width: "52%",
            height: "40%",
          }}
          position="absolute"
          top="25%"
          left="25%"
        />
        <Icon
          position="relative"
          icon="correct"
          iconColor="utility.question-green"
          boxSize={pxToRem(currentBreakpoints.iconSize)}
        />
      </Box>
      <VStack alignItems="start" spacing={0}>
        <Text textStyle="gameTextWeighted" fontSize={fontSize}>
          {t("studentLobbyLight.sessionIndicator.gameTypeText", {
            gameType: t(`common.${outerGame}`),
          })}
        </Text>
        {sessionCreatorName && (
          <Text
            textStyle="gameText"
            fontSize={fontSize}
            color="primary.dark-gray"
          >
            {t(
              "studentLobbyLight.sessionIndicator.waitingForSessionCreatorText",
              { sessionCreatorName }
            )}
          </Text>
        )}
      </VStack>
      <Box
        bgColor="utility.question-green"
        borderRadius="2xl"
        px={padding}
        py={pxToRem(generalPadding / 2)}
      >
        <Text textStyle="sessionCode" fontSize={fontSize}>
          {sessionCode.toUpperCase()}
        </Text>
      </Box>
    </HStack>
  );
};
