import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import {
  CoverImageBGColorSchemeType,
  CoverImageBGPatternType,
  CoverImageIconType,
  IPracticeSet,
  IPracticeSetItem,
  ISmartSetSettings,
  PracticeDataDocumentType,
  PracticeSetAvailability,
  PracticeSetStatus,
  SmartSetType,
} from "../../types";

export interface IMutatePracticeSetGradeLevel {
  grade_level: {
    id: string;
  };
}

export interface IMutatePracticeSetSubject {
  subject: {
    id: string;
  };
}

export interface IMutatePracticeSetReferenceMaterial {
  reference_material: {
    id: string;
  };
}

export interface IMutatePracticeSetArgs {
  id?: string;
  title: string;
  description: string;
  collection_id: string;
  status: PracticeSetStatus;
  availability: PracticeSetAvailability;
  cnc_family_code: string;
  cnc_code: string;
  is_certified: boolean;
  is_premium: boolean;
  language_code: string;
  grade_levels: Array<IMutatePracticeSetGradeLevel>;
  subjects: Array<IMutatePracticeSetSubject>;
  reference_materials: Array<IMutatePracticeSetReferenceMaterial>;
  cover_image_icon: CoverImageIconType;
  cover_image_bg_pattern: CoverImageBGPatternType;
  cover_image_bg_color_scheme: CoverImageBGColorSchemeType;
  smart_set_type?: SmartSetType;
  smart_set_settings?: ISmartSetSettings;
  practice_set_items?: Array<IPracticeSetItem>;
}

export interface IMutatePracticeSetResponse {
  practice_set: IPracticeSet;
}

export interface IUseMutatePracticeSetProps {
  onSuccess?: (data: IMutatePracticeSetResponse) => void;
  onError?: () => void;
  onSettled?: () => void;
}

export default function useMutatePracticeSet(
  props?: IUseMutatePracticeSetProps
): UseMutationResult<
  IMutatePracticeSetResponse,
  unknown,
  IMutatePracticeSetArgs
> {
  const axios = useAxios();
  const queryClient = useQueryClient();

  const mutatePracticeSet = async (args: IMutatePracticeSetArgs) => {
    const {
      id,
      title,
      description,
      collection_id,
      status,
      availability,
      cnc_family_code,
      cnc_code,
      is_certified,
      is_premium,
      language_code,
      grade_levels,
      reference_materials,
      subjects,
      cover_image_bg_color_scheme,
      cover_image_bg_pattern,
      cover_image_icon,
      smart_set_type,
      smart_set_settings,
      practice_set_items,
    } = args;

    const response = await axios({
      method: id ? "put" : "post",
      url: `/v1/practice-sets${id ? "/" + id : ""}`,
      data: {
        practice_set: {
          title,
          description,
          status,
          availability,
          cnc_family_code,
          cnc_code,
          is_certified,
          is_premium,
          collection_id,
          language_code,
          version: 0,
          image_url: "",
          subjects,
          grade_levels: grade_levels.sort((a, b) => {
            const [aId, bId] = [
              parseInt(a.grade_level.id, 10),
              parseInt(b.grade_level.id, 10),
            ];
            return aId > bId ? 1 : aId < bId ? -1 : 0;
          }),
          reference_materials,
          cover_image_bg_color_scheme,
          cover_image_bg_pattern,
          cover_image_icon,
          smart_set_type,
        },
        smart_set_settings,
        practice_set_items,
      },
    }).then((r) => r.data as IMutatePracticeSetResponse);

    queryClient.invalidateQueries(["collections"], { exact: false });
    queryClient.invalidateQueries(["practiceSets"], { exact: false });
    queryClient.invalidateQueries([
      "searchPracticeDataInfinite",
      {
        include_shared: false,
        document_type: PracticeDataDocumentType.PracticeSets,
      },
    ]);
    queryClient.invalidateQueries([
      "searchPracticeData",
      {
        include_shared: false,
        document_type: PracticeDataDocumentType.PracticeSets,
      },
    ]);
    queryClient.setQueryData(
      ["practiceSets", { id: response.practice_set.id }],
      {
        practice_set: response.practice_set,
      }
    );

    return response;
  };

  const mutation = useMutation(mutatePracticeSet, {
    onError: props?.onError,
    onSuccess: props?.onSuccess,
    onSettled: props?.onSettled,
  });

  return {
    ...mutation,
  };
}
