import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { IconCTAButton } from "adminComponents/atoms/IconCTAButton";
import { XPBoostContent } from "adminComponents/atoms/XPBoostContent";
import { pxToRem } from "adminComponents/utils";
import { UserXPMultiplier } from "links/lib/types";
import { useBoostTimer } from "sharedComponents/hooks/useBoostTimer";

export interface IXPBoostModalProps {
  isOpen: boolean;
  userXPMultiplier: UserXPMultiplier;
  handleClose: () => void;
}

export const XPBoostModal: React.FC<IXPBoostModalProps> = ({
  isOpen,
  userXPMultiplier,
  handleClose,
}: IXPBoostModalProps) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });

  const { secondsRemaining } = useBoostTimer({
    userXPMultiplier,
    onEnd: handleClose,
  });

  return (
    <Modal
      isOpen={isOpen}
      isCentered
      size="lg"
      variant="adminModal"
      onClose={handleClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton top={pxToRem(20)} right={pxToRem(20)}>
          <IconCTAButton ariaLabel={t("common.close")} />
        </ModalCloseButton>
        <ModalBody>
          <VStack pt={pxToRem(48)} spacing={pxToRem(24)}>
            <XPBoostContent
              secondsRemaining={secondsRemaining}
              userXPMultiplier={userXPMultiplier}
            />
            <Button size="lg" onClick={handleClose} variant="adminButtonFilled">
              {t("xpBoost.buttonLetsGo")}
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
