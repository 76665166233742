import { pxToRem } from "adminComponents/utils";

export const AdminEditAvatarModal = {
  baseStyle: ({
    isSticky,
  }: {
    isSticky: boolean;
  }): Record<string, unknown> => ({
    stickyPreview: {
      backgroundColor: "utility.navigation-bg-blue",
      width: "100%",
      display: "flex",
      alignItems: "center",
      paddingLeft: pxToRem(15),
      paddingRight: pxToRem(15),
      paddingTop: pxToRem(40),
      paddingBottom: pxToRem(30),
      position: isSticky ? "fixed" : "absolute",
      top: isSticky ? 0 : pxToRem(-200),
      left: 0,
      opacity: isSticky ? 1 : 0,
      transform: isSticky ? "translateY(0)" : `translateY(${pxToRem(-200)})`,
      transition: "all 250ms ease-in-out",
      zIndex: 15,
    },

    stickyPreviewAvatar: {
      width: "100%",
      maxWidth: pxToRem(100),
      margin: "0 auto",
      position: "absolute",
      bottom: pxToRem(-30),
      left: 0,
      right: 0,
      borderStyle: "solid",
      borderWidth: pxToRem(10),
      borderColor: "utility.navigation-bg-blue",
      borderRadius: "full",
    },

    stickyBackButton: {
      margin: 0,
      backgroundColor: "primary.white",
      borderRadius: "100%",
      width: "100%",
      maxWidth: pxToRem(32),
    },
  }),
  variants: {
    adminEditAvatarModal: {
      doneButton: {
        paddingLeft: {
          base: pxToRem(16),
          lg: pxToRem(60),
        },
        paddingRight: {
          base: pxToRem(16),
          lg: pxToRem(60),
        },
        mt: {
          base: pxToRem(42),
          lg: pxToRem(68),
        },
        width: "100%",
      },
      leftColumnAvatarType: {
        display: {
          base: "block",
          lg: "flex",
        },
        justifyContent: "center",
        alignItems: "center",
        mx: "auto",
        w: "full",
        height: {
          base: pxToRem(136),
          md: pxToRem(256),
          lg: "initial",
        },
        maxW: {
          base: undefined,
          lg: pxToRem(516),
        },
        "> div": {
          w: {
            base: undefined,
            lg: "full",
          },
          maxW: {
            base: undefined,
            lg: pxToRem(401),
          },
        },
        button: {
          w: "full",
          _last: {
            img: {
              minW: pxToRem(56),
              maxW: {
                base: pxToRem(68),
                lg: pxToRem(122),
              },
            },
          },
        },
      },
      leftColumnStage: {
        w: "full",
        "> div": {
          m: "0 auto",
        },
      },
      rightColumnContent: {
        width: {
          base: "full",
          lg: pxToRem(590),
        },
        m: "0 auto",
      },
    },
  },
};
