import {
  Box,
  Center,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Square,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Dropdown, IOption } from "adminComponents/atoms/Dropdown";
import { Icon } from "adminComponents/atoms/Icon";
import { Input } from "adminComponents/atoms/Input";
import { PremiumTooltipRich } from "adminComponents/atoms/PremiumTooltipRich";
import { Tag } from "adminComponents/atoms/Tag";
import { GeneratingModal } from "adminComponents/molecules/GeneratingModal";
import {
  PracticeSetQuestionCard,
  PracticeSetQuestionCardType,
} from "adminComponents/molecules/PracticeSetQuestionCard";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { useErrorToast, useShowToast } from "adminComponents/utils/toast";
import { useAnalytics } from "lib/contexts/analytics";
import { useAuth } from "links/lib/features/auth";
import {
  useDeletePracticeSetItem,
  useGenerateItem,
  useMutatePracticeSetItems,
} from "links/lib/features/practiceSetItems";
import {
  AnalyticsEvent,
  IPracticeSet,
  IPracticeSetItem,
  QuestionType,
} from "links/lib/types";

interface IGenerateItemFormProps {
  practiceSet?: IPracticeSet;
  handleEditPracticeSet: () => void;
}

export const GenerateItemForm: React.FC<IGenerateItemFormProps> = ({
  practiceSet,
  handleEditPracticeSet,
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });
  const showToast = useShowToast();
  const { trackEvent } = useAnalytics();
  const { hasNoPremiumAccess } = useAuth();

  const [isFormChanged, setIsFormChanged] = useState(false);
  const [topic, setTopic] = useState("");
  const [topicInputTouched, setTopicInputTouched] = useState(false);
  const [, setAddPracticeSetItemIndex] = useState(0);
  const [, setRemovePracticeSetItemId] = useState("0");
  const [submittedTopic, setSubmittedTopic] = useState("");

  const questionTypeOptions = [
    {
      label: t("common.classify"),
      value: QuestionType.Classify,
    },
    {
      label: t("common.numberResponse"),
      value: QuestionType.NumberResponse,
    },
    {
      label: t("common.textResponse"),
      value: QuestionType.TextResponse,
    },
    {
      label: t("common.multipleChoice"),
      value: QuestionType.MultipleChoice,
    },
  ];

  const deletePracticeSetItem = useDeletePracticeSetItem({
    onSuccess: () => {
      setRemovePracticeSetItemId((removePracticeSetItemId) => {
        setGeneratedPracticeSetItems((generatedPracticeSetItems) => [
          ...generatedPracticeSetItems.map((p) => {
            if (p.id !== removePracticeSetItemId) return p;
            p.id = "0";
            return p;
          }),
        ]);
        return "0";
      });
    },
  });
  useErrorToast(deletePracticeSetItem.error);

  const [desiredQuestionTypeOptions, setDesiredQuestionTypeOptions] =
    useState<Array<IOption>>(questionTypeOptions);
  const [generatedPracticeSetItems, setGeneratedPracticeSetItems] = useState<
    Array<IPracticeSetItem>
  >([]);

  const {
    mutate: mutatePracticeSetItems,
    isLoading: mutatePracticeSetItemsIsLoading,
    error: mutatePracticeSetItemsError,
  } = useMutatePracticeSetItems({
    onSuccess: (data) => {
      const practiceSetItemId = data.practice_set_items[0].id;
      setAddPracticeSetItemIndex((addPracticeSetItemIndex) => {
        setGeneratedPracticeSetItems((generatedPracticeSetItems) => [
          ...generatedPracticeSetItems.map((practiceSetItem, i) => {
            if (i !== addPracticeSetItemIndex) return practiceSetItem;
            practiceSetItem.id = practiceSetItemId;
            return practiceSetItem;
          }),
        ]);
        return 0;
      });
    },
  });
  useErrorToast(mutatePracticeSetItemsError);

  const { mutation: generatePracticeSetItem } = useGenerateItem({
    onSuccess: (data) => {
      setGeneratedPracticeSetItems((generatedPracticeSetItems) => [
        ...generatedPracticeSetItems,
        ...data.practice_set_items,
      ]);
    },
    onError: () => {
      showToast(t("practiceSetAddQuestions.generateItemForm.generationError"));
    },
  });

  const onChangeTopic = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setTopic(value);
  };

  useEffect(() => {
    const isChanged = topic !== "";
    setIsFormChanged(isChanged);
  }, [topic]);

  const onGenerateItems = () => {
    const desiredQuestionTypes = desiredQuestionTypeOptions.map(
      (option) => option.value as QuestionType
    );
    trackEvent(
      AnalyticsEvent.TeacherDashboard_PracticeSetDetail_QuestionFlyout_GenerateItemConfirm,
      { practiceSetId: practiceSet?.id, questionTypes: desiredQuestionTypes }
    );
    generatePracticeSetItem.mutate({
      topic: topic,
      desiredQuestionTypes,
      questionCount: 3,
      practice_set_id: practiceSet?.id || "0",
    });
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setGeneratedPracticeSetItems([]);
    setSubmittedTopic(topic);

    onGenerateItems();
  };

  const handleAddToSet = (index: number) => {
    return (practiceSetItem: IPracticeSetItem) => {
      if (!practiceSet) return;

      setAddPracticeSetItemIndex(index);

      practiceSetItem.practice_set_id = practiceSet.id;
      practiceSetItem.order = practiceSet.item_count + 1;

      trackEvent(
        AnalyticsEvent.TeacherDashboard_PracticeSetDetail_QuestionFlyout_GenerateItemImport,
        {
          practiceSetId: practiceSet?.id,
          questionType: practiceSetItem.question_type,
        }
      );

      mutatePracticeSetItems({
        practice_set_id: practiceSet.id,
        practice_set_items: [practiceSetItem],
      });
    };
  };

  const handleRemoveFromSet = (practiceSetItem: IPracticeSetItem) => {
    if (!practiceSet) return;

    setRemovePracticeSetItemId(practiceSetItem.id);

    deletePracticeSetItem.mutate({
      id: practiceSetItem.id,
      practice_set_id: practiceSet.id,
    });
  };

  const isTopicInvalid = topic === "";
  const isInvalid = isTopicInvalid || desiredQuestionTypeOptions.length === 0;
  const isLoading =
    mutatePracticeSetItemsIsLoading || deletePracticeSetItem.isLoading;

  return (
    <>
      <GeneratingModal
        isOpen={generatePracticeSetItem.isLoading}
        labelHeading={t(
          "practiceSetAddQuestions.generateItemForm.generatingModal.heading"
        )}
        labelSubHeading={t(
          "practiceSetAddQuestions.generateItemForm.generatingModal.subheading"
        )}
      />

      <form onSubmit={onSubmit}>
        <VStack spacing={pxToRem(24)} w="full" alignItems="start">
          <HStack>
            <Tag
              colorScheme="blue"
              tooltip={
                <Box>
                  {t(
                    "practiceSetAddQuestions.generateItemForm.aiGeneratedTagTooltip"
                  )}
                </Box>
              }
            >
              <HStack>
                <Icon icon="zap" />
                <Text>
                  {t("practiceSetAddQuestions.generateItemForm.aiGeneratedTag")}
                </Text>
              </HStack>
            </Tag>
            <Tag colorScheme="blue" leftIcon="account_circle_outlined">
              {t("common.beta")}
            </Tag>
          </HStack>

          <Text size="lg" mt={pxToRem(24)}>
            {t("practiceSetAddQuestions.generateItemForm.heading")}
          </Text>
          <Text size="md" mt={pxToRem(24)}>
            {t("practiceSetAddQuestions.generateItemForm.instructions")}
          </Text>

          <FormControl isInvalid={topicInputTouched && isTopicInvalid}>
            <FormLabel
              htmlFor="topic"
              id="generateItemForm.topicLabel"
              color="primary.warm-black"
              mb={pxToRem(0)}
            >
              {t("practiceSetAddQuestions.generateItemForm.labelTopic")}
              {t("common.requiredAsterisk")}
            </FormLabel>
            <Text size="sm" mb={pxToRem(8)} color="primary.dark-gray">
              {t(
                "practiceSetAddQuestions.generateItemForm.labelTopicDescription"
              )}
            </Text>
            <Input
              aria-labelledby="generateItemForm.topicLabel"
              id="topic"
              name="topic"
              value={topic}
              size="md"
              borderLeftRadius={0}
              onChange={onChangeTopic}
              placeholder={t(
                "practiceSetAddQuestions.generateItemForm.placeholderTopic"
              )}
              onFocus={() => setTopicInputTouched(true)}
              maxLength={100}
              maxLengthLabel={t(
                "practiceSetAddQuestions.generateItemForm.labelTopicMaxLength"
              )}
              disabled={hasNoPremiumAccess}
            ></Input>
          </FormControl>

          <FormControl>
            <FormLabel>
              {t("practiceSetAddQuestions.generateItemForm.labelQuestionTypes")}
              {t("common.requiredAsterisk")}
            </FormLabel>
            <Dropdown
              options={questionTypeOptions}
              value={desiredQuestionTypeOptions}
              handleChange={(vals) =>
                setDesiredQuestionTypeOptions(vals as Array<IOption>)
              }
              isMulti={true}
              isDisabled={hasNoPremiumAccess}
            ></Dropdown>
          </FormControl>

          <VStack w="full" spacing={pxToRem(6)} alignItems="start">
            <PremiumTooltipRich isDisabled={!hasNoPremiumAccess}>
              <Box w="full">
                <Button
                  size="lg"
                  type="submit"
                  variant="adminButtonFilled"
                  disabled={hasNoPremiumAccess || !isFormChanged || isInvalid}
                  w="full"
                  isLoading={generatePracticeSetItem.isLoading}
                >
                  <Flex
                    display="flex"
                    w="full"
                    justifyContent="center"
                    alignItems="center"
                    gap={pxToRem(8)}
                  >
                    {hasNoPremiumAccess && <Icon icon="lock" />}
                    {t(
                      "practiceSetAddQuestions.generateItemForm.buttonGenerateQuestions"
                    )}
                  </Flex>
                </Button>
              </Box>
            </PremiumTooltipRich>
            <Flex gap={pxToRem(5)} alignItems="center">
              <Text size="sm" color="primary.dark-gray">
                {t(
                  "practiceSetAddQuestions.generateItemForm.buttonGenerateQuestionsDescription"
                )}{" "}
              </Text>
              <Button
                variant="adminTextButtonMedium"
                onClick={handleEditPracticeSet}
              >
                <Square>{t("common.edit")}</Square>
              </Button>
            </Flex>
          </VStack>

          <VStack
            pt={pxToRem(12)}
            spacing={pxToRem(16)}
            w="full"
            alignItems="start"
          >
            <Text size="lg">
              {t("practiceSetAddQuestions.generateItemForm.resultsLabel")}
            </Text>
            <Text size="md" color="primary.dark-gray">
              {generatedPracticeSetItems.length > 0
                ? t(
                    "practiceSetAddQuestions.generateItemForm.resultsForLabel",
                    { search: submittedTopic }
                  )
                : t(
                    "practiceSetAddQuestions.generateItemForm.zeroResultsLabel"
                  )}
            </Text>
            {generatedPracticeSetItems.map((practiceSetItem, i) => (
              <>
                <PracticeSetQuestionCard
                  key={i}
                  practiceSetItem={practiceSetItem}
                  questionNumber={i}
                  cardType={PracticeSetQuestionCardType.Generated}
                  handleAddToSet={
                    practiceSetItem.id === "0" ? handleAddToSet(i) : undefined
                  }
                  handleRemoveFromSet={
                    practiceSetItem.id !== "0" ? handleRemoveFromSet : undefined
                  }
                  isPublic={true}
                  isLoading={isLoading}
                />
              </>
            ))}
            {generatedPracticeSetItems.length > 0 && (
              <Center w="full">
                <Button
                  onClick={onGenerateItems}
                  rightIcon={
                    <Icon
                      height={pxToRem(7)}
                      icon="button_down_arrow"
                      color="currentColor"
                      ml={pxToRem(11)}
                      width={pxToRem(12)}
                    />
                  }
                  variant="adminTextButtonLarge"
                >
                  {t("common.viewMore")}
                </Button>
              </Center>
            )}
            {generatedPracticeSetItems.length === 0 && (
              <VStack
                textAlign="center"
                p={pxToRem(48)}
                borderColor="primary.medium-gray"
                borderWidth={pxToRem(3)}
                borderStyle="solid"
                borderRadius="lg"
              >
                <Text>
                  {t(
                    "practiceSetAddQuestions.generateItemForm.noResultsHeading"
                  )}
                </Text>
                <Text size="md" color="primary.dark-gray">
                  {t(
                    "practiceSetAddQuestions.generateItemForm.noResultsSubheading"
                  )}
                </Text>
              </VStack>
            )}
          </VStack>
        </VStack>
      </form>
    </>
  );
};
