import { Box, Button, HStack, Image, Select, VStack } from "@chakra-ui/react";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";

import { AvatarItem, AvatarItemBuilderData } from "../AvatarItemBuilder/types";

interface IAvatarItemSorterProps {
  itemBuilderData: AvatarItemBuilderData;
  reorderAvatarItems: (avatarItems: Array<AvatarItem>) => void;
}

export const AvatarItemSorter: React.FC<IAvatarItemSorterProps> = ({
  itemBuilderData,
  reorderAvatarItems,
}: IAvatarItemSorterProps) => {
  const [categoryId, setCategoryId] = useState<string>();
  const [categoryAvatarItems, setCategoryAvatarItems] = useState<
    Array<AvatarItem>
  >([]);

  const onSelectCategory = (e: ChangeEvent<HTMLSelectElement>) => {
    setCategoryId(e.target.value);
  };

  useEffect(() => {
    setCategoryAvatarItems(
      itemBuilderData.avatar_items.filter(
        (item) => item.avatar_item_category_id === categoryId
      )
    );
  }, [itemBuilderData, categoryId]);

  const dragItem = useRef<number>();
  const dragOverItem = useRef<number>();

  const onDragStart = (index: number) => {
    dragItem.current = index;
  };

  const onDragEnter = (index: number) => {
    dragOverItem.current = index;
  };

  const onDragEnd = () => {
    const startItemIndex = dragItem.current || 0;
    const startItem = categoryAvatarItems[startItemIndex];

    const destItemIndex = dragOverItem.current || 0;
    const destItem = categoryAvatarItems[destItemIndex];

    const migratedAvatarItems = [
      ...categoryAvatarItems.filter(
        (_, i) => i !== startItemIndex && i !== destItemIndex
      ),
    ];

    if (startItemIndex > destItemIndex) {
      //  Moving Up
      migratedAvatarItems.splice(destItemIndex, 0, startItem);
      migratedAvatarItems.splice(destItemIndex + 1, 0, destItem);
    } else {
      // Moving Down
      migratedAvatarItems.splice(destItemIndex - 1, 0, destItem);
      migratedAvatarItems.splice(destItemIndex, 0, startItem);
    }

    setCategoryAvatarItems(migratedAvatarItems);
  };

  const onSave = () => {
    const orderedAvatarItems = categoryAvatarItems.map((item, i) => {
      return {
        ...item,
        avatar_item_category_order: i + 1,
      };
    });
    reorderAvatarItems(orderedAvatarItems);
  };

  return (
    <VStack p="50px" bgColor="gray.50" spacing="24px" alignItems="flex-start">
      <Select onChange={onSelectCategory}>
        <option value="0">-- Select Category --</option>
        {itemBuilderData.avatar_item_categories.map((category, i) => {
          return (
            <option key={i} value={category.id}>
              {category.name}
            </option>
          );
        })}
      </Select>
      <VStack spacing="8px" alignItems="flex-start">
        {categoryAvatarItems.length > 0 && (
          <Button onClick={onSave}>Save</Button>
        )}
        {categoryAvatarItems.map((item, i) => {
          return (
            <HStack
              key={i}
              draggable={true}
              onDragStart={() => onDragStart(i)}
              onDragEnter={() => onDragEnter(i)}
              onDragEnd={onDragEnd}
              cursor="grab"
              p="12px"
            >
              <Box h="32px" w="32px">
                <Image
                  maxH="32px"
                  maxW="32px"
                  src={item.preview_image_asset_url}
                />
              </Box>
              <Box>
                {item.name} (Season ID: {item.season_id}) (Original Order:{" "}
                {item.avatar_item_category_order}) (New Order: {i + 1})
              </Box>
            </HStack>
          );
        })}
        {categoryAvatarItems.length > 0 && (
          <Button onClick={onSave}>Save</Button>
        )}
      </VStack>
    </VStack>
  );
};
