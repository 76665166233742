/**
 * Download a blob with a specific filename (if supported). If not supported, then open
 * blob in new tab.
 * @param blobUrl
 * @param filename
 */
export const downloadBlob = (blobUrl: string, filename: string): void => {
  const link = document.createElement("a");
  if (typeof link.download === "string") {
    link.href = blobUrl;
    link.download = filename;
    link.style.color = "transparent";

    //Firefox requires the link to be in the body
    document.body.appendChild(link);

    //simulate click
    link.click();

    //remove the link when done
    document.body.removeChild(link);
  } else {
    window.open(blobUrl);
  }
};

export const shareBlobWithNavigator = async (
  blobUrl: string,
  fileName: string,
  title: string,
  text: string,
  url: string
): Promise<void> => {
  let blob: Blob | undefined = undefined;

  try {
    blob = await fetch(blobUrl).then((r) => r.blob());
  } catch (err) {
    console.warn(`Could not fetch blob: ${err}`);
  }

  if (!blob) return;

  const file = new File([blob], fileName, {
    type: blob.type,
  });
  const files = [file];

  if (navigator.canShare({ files })) {
    try {
      await navigator.share({
        files,
        url,
        title,
        text,
      });
    } catch (error) {
      console.log(error);
      console.warn(`Could not share via navigator: ${error}`);
    }
  } else {
    console.warn("Sharing is not supported on navigator");
  }
};

const openSocialShareWindow = (url: string): void => {
  const windowSize = 570;
  const left = (screen.width - windowSize) / 2;
  const top = (screen.height - windowSize) / 2;
  const params = `menubar=no,toolbar=no,status=no,width=${windowSize},height=${windowSize},top=${top},left=${left}`;

  window.open(url, "NewWindow", params);
};

export const shareToX = (tweet: string, hashtags?: Array<string>): void => {
  const shareUrl =
    `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweet)}` +
    (hashtags ? `&hashtags=${hashtags?.join(",")}` : "");
  openSocialShareWindow(shareUrl);
};

export const shareToLinkedIn = (url: string): void => {
  const shareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
    url
  )}`;
  openSocialShareWindow(shareUrl);
};

export const shareToFacebook = (url: string): void => {
  const shareUrl = `https://www.facebook.com/sharer.php?u=${encodeURIComponent(
    url
  )}`;
  openSocialShareWindow(shareUrl);
};
