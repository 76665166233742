import { Box } from "@chakra-ui/react";
import React, { useMemo } from "react";

import { LoadingSpinner } from "adminComponents/atoms/LoadingSpinner";
import {
  AssignmentsTab as AdminAssignmentsTab,
  ITopTeacher,
} from "adminComponents/screens/AdminHomepage/components/AssignmentsTab";
import { pxToRem } from "adminComponents/utils";
import { useErrorToast } from "adminComponents/utils/toast";
import { usePageTrack } from "lib/contexts/analytics";
import {
  useFetchOrganizationAssignmentCount,
  useFetchTopOrganizationAssignmentCreators,
} from "links/lib/features/assignments";
import { useAuth } from "links/lib/features/auth";
import { useFetchEducatorWithGroupDetailsBulk } from "links/lib/features/users";
import { IFetchEducatorWithGroupDetailsResponse } from "links/lib/features/users/useFetchEducatorWithGroupDetailsBulk";
import { AnalyticsPage, IGradeLevel, ISubject } from "links/lib/types";

interface IAssignmentsTabProps {
  gradeLevels: Array<IGradeLevel>;
  subjects: Array<ISubject>;
  isLoading?: boolean;
  sideCard?: JSX.Element;
}

export const AssignmentsTab: React.FC<IAssignmentsTabProps> = ({
  gradeLevels,
  subjects,
  isLoading,
  sideCard,
}) => {
  const { hasNoPremiumAccess } = useAuth();

  usePageTrack(AnalyticsPage.AdminDashboard_Assignments);

  const teacherAssignmentsFetch = useFetchTopOrganizationAssignmentCreators({
    days_ago: 0,
    limit: 9,
  });
  const teachersWithGroupDetailsFetch = useFetchEducatorWithGroupDetailsBulk({
    userIds:
      teacherAssignmentsFetch.data?.top_organization_assignment_creators?.map(
        (teacher) => teacher.user_id
      ) || [],
    enabled:
      (teacherAssignmentsFetch.data?.top_organization_assignment_creators
        ?.length || 0) > 0,
  });

  const assignmentCountFetch = useFetchOrganizationAssignmentCount({
    days_ago: 0,
  });

  useErrorToast(teacherAssignmentsFetch.error);
  useErrorToast(teachersWithGroupDetailsFetch.error);
  useErrorToast(assignmentCountFetch.error);

  const teacherGroupDetailsMap = useMemo(() => {
    const teacherGroupDetailsMap = new Map<
      string,
      IFetchEducatorWithGroupDetailsResponse
    >();

    if (
      teachersWithGroupDetailsFetch.data?.teachers &&
      teachersWithGroupDetailsFetch.data?.teachers.length > 0
    ) {
      teachersWithGroupDetailsFetch.data?.teachers.forEach((teacher) =>
        teacherGroupDetailsMap.set(teacher.user.id, teacher)
      );
    }

    return teacherGroupDetailsMap;
  }, [teachersWithGroupDetailsFetch.data?.teachers]);

  const teachers = useMemo(() => {
    const teachers: ITopTeacher[] = [];

    if (
      teacherGroupDetailsMap.size > 0 &&
      teacherAssignmentsFetch.data?.top_organization_assignment_creators &&
      teacherAssignmentsFetch.data?.top_organization_assignment_creators
        .length > 0
    ) {
      teacherAssignmentsFetch.data?.top_organization_assignment_creators.forEach(
        (teacherAssignments) => {
          const teacherGroup = teacherGroupDetailsMap.get(
            teacherAssignments.user_id
          );
          if (teacherGroup) {
            teachers.push({
              teacher: teacherGroup.user,
              group_count: teacherGroup.group_count,
              assignment_count: teacherAssignments.assignments_count,
              student_count: teacherGroup.student_count,
            });
          }
          return teachers;
        }
      );
    }

    return teachers;
  }, [
    teacherGroupDetailsMap,
    teacherAssignmentsFetch.data?.top_organization_assignment_creators,
  ]);

  const assignmentCount = parseInt(
    assignmentCountFetch.data?.assignment_count || "0"
  );

  const showLoading =
    isLoading ||
    teacherAssignmentsFetch.isLoading ||
    teachersWithGroupDetailsFetch.isLoading ||
    assignmentCountFetch.isLoading;

  return (
    <>
      {showLoading ? (
        <Box mt={pxToRem(60)} mb={pxToRem(120)}>
          <LoadingSpinner />
        </Box>
      ) : (
        <AdminAssignmentsTab
          topTeachers={teachers}
          gradeLevels={gradeLevels}
          subjects={subjects}
          classAssignmentCount={assignmentCount}
          hasNoPremiumAccess={hasNoPremiumAccess}
          key={teachers.length}
          sideCard={sideCard}
        />
      )}
    </>
  );
};
