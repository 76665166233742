import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import type { ISeason } from "../../types";

export interface IResult {
  season: ISeason;
}

export default function useFetchCurrentSeason(): UseQueryResult<
  IResult,
  unknown
> {
  const axios = useAxios();

  const query = useQuery<IResult>(
    ["currentSeason"],
    async (): Promise<IResult> => {
      return await axios.get(`/v1/season/current`).then((r) => {
        return r.data as IResult;
      });
    }
  );

  return query;
}
