import { StrokeManager } from "./StrokeManager";
import { IStroke, IStrokePart, ITool } from "./types";

export interface IStrokeHandler {
  (stroke: IStroke): void;
}

export class Manager {
  // reference to the canvas
  private canvas: HTMLCanvasElement;
  // reference to canvas rendering context
  private ctx: CanvasRenderingContext2D | null;
  // reference to stroke manager
  private strokeManager: StrokeManager;
  // holds the pixel ratio between canvas backing
  // store and device ratio (used for hi fi displays)
  private pixelRatio: number;
  // the width of the canvas
  private canvasWidth: number;
  // the height of the canvas
  private canvasHeight: number;
  // holds a reference to next animation frame
  private nextAnimationFrame: number;
  // the currently selected tool
  private currentTool: ITool | null;
  // holds stroke parts for ongoing stroke
  private currentStroke: IStrokePart[];
  // holds a list of all strokes
  private strokes: Array<IStroke>;
  // indicates whether changes have occured that require redraw
  private shouldDraw: boolean;
  // indicates whether the historic strokes have changed and need redrawn
  private shouldRedrawStrokes: boolean;
  // stores external listeners for strokes
  private onStrokeHandlers: Array<IStrokeHandler>;
  // stores external listeners for stroke parts
  private onStrokePartHandlers: Array<IStrokeHandler>;
  // indicates whether the manager is disabled
  public isDisabled: boolean;
  // the background image;
  private background: HTMLImageElement | null;
  // indicates whether the background is loaded;
  private isBackgroundLoaded: boolean;

  constructor(
    canvas: HTMLCanvasElement,
    canvasWidth: number,
    canvasHeight: number,
    backgroundUrl: string
  ) {
    this.canvas = canvas;
    this.canvasWidth = canvasWidth;
    this.canvasHeight = canvasHeight;
    this.currentTool = null;
    this.currentStroke = [];
    this.strokes = [];
    this.strokeManager = new StrokeManager(canvas);
    this.shouldDraw = false;
    this.shouldRedrawStrokes = false;
    this.ctx = canvas.getContext("2d");
    this.onStrokeHandlers = [];
    this.onStrokePartHandlers = [];
    this.isDisabled = false;
    this.pixelRatio = window.devicePixelRatio;
    this.isBackgroundLoaded = !!backgroundUrl;

    this.background = new Image();
    this.background.crossOrigin = "anonymous";
    this.background.src = backgroundUrl;
    this.background.onload = () => {
      this.isBackgroundLoaded = true;
      this.shouldDraw = true;
      this.shouldRedrawStrokes = true;
      this.draw();
    };

    this.setCanvasSize = this.setCanvasSize.bind(this);
    this.setTool = this.setTool.bind(this);
    this.destroy = this.destroy.bind(this);
    this.clear = this.clear.bind(this);
    this.draw = this.draw.bind(this);
    this.onStrokePart = this.onStrokePart.bind(this);

    // schedule animation frame loop
    this.nextAnimationFrame = window.requestAnimationFrame(this.draw);
    // set up listener for new stroke part
    this.strokeManager.onStrokePart(this.onStrokePart);

    this.setCanvasSize(canvasWidth, canvasHeight);
  }

  /**
   * Sets the canvas desired width and height and sets transform
   * for hifi displays
   * @param width
   * @param height
   */
  public setCanvasSize(width: number, height: number): void {
    this.canvasWidth = width;
    this.canvasHeight = height;

    const { canvas, canvasWidth, canvasHeight, pixelRatio, ctx } = this;

    if (!ctx) return;

    // appropriately scale canvas to map to device ratio
    canvas.width = canvasWidth * pixelRatio;
    canvas.height = canvasHeight * pixelRatio;
    canvas.style.width = canvasWidth + "px";
    canvas.style.height = canvasHeight + "px";
    ctx.scale(pixelRatio, pixelRatio);

    this.shouldDraw = true;
    this.shouldRedrawStrokes = true;
  }

  /**
   * Sets the current tool for the manager
   * @param tool
   */
  public setTool(tool: ITool): void {
    this.currentTool = tool;
  }

  /**
   * Remove all event listeners
   */
  public destroy(): void {
    // cancel animation loop
    window.cancelAnimationFrame(this.nextAnimationFrame);
    // remove all listeners on stroke manager
    this.strokeManager.destroy();
    // remove all stroke handlers
    this.onStrokeHandlers = [];
    // remove all stroke part handlers
    this.onStrokePartHandlers = [];
  }

  /**
   * Clears the canvas
   */
  public clear(): void {
    this.currentStroke = [];
    this.shouldDraw = true;
  }

  public onStroke(handler: IStrokeHandler): void {
    this.onStrokeHandlers.push(handler);
  }

  public offStroke(handler: IStrokeHandler): void {
    this.onStrokeHandlers = this.onStrokeHandlers.filter((h) => h !== handler);
  }

  public onStrokePartHandler(handler: IStrokeHandler): void {
    this.onStrokePartHandlers.push(handler);
  }

  /**
   * Adds a new stroke part to the nextStrokes
   * array
   * @param strokePart
   */
  private onStrokePart(strokePart: IStrokePart): void {
    if (this.isDisabled) return;

    this.currentStroke.push(strokePart);

    this.shouldDraw = true;

    if (this.currentTool) {
      if (strokePart.isEnd) {
        const stroke = {
          tool: this.currentTool,
          strokeParts: this.currentStroke,
        };

        this.strokes.push(stroke);

        this.onStrokeHandlers.forEach((h) => h(stroke));

        this.currentStroke = [];

        this.shouldRedrawStrokes = true;
      } else {
        this.onStrokePartHandlers.forEach((h) =>
          h({
            tool: this.currentTool as ITool,
            strokeParts: this.currentStroke,
          })
        );
      }
    }
  }

  public setStrokes(strokes: Array<IStroke>): void {
    this.strokes = strokes;

    this.shouldDraw = true;
    this.shouldRedrawStrokes = true;
  }

  public setSensitivity(sensitivity: number): void {
    this.strokeManager.sensitivity = sensitivity;
  }

  private drawBackground(): void {
    const { background, canvas, ctx, isBackgroundLoaded } = this;

    if (!ctx) return;
    if (!background) return;
    if (!isBackgroundLoaded) return;

    const width = background.width * this.pixelRatio;
    const height = background.height * this.pixelRatio;
    const hRatio = canvas.width / width;
    const vRatio = canvas.height / height;
    const ratio = Math.min(hRatio, vRatio);
    const centerShiftX = (canvas.width - width * ratio) / 2 / this.pixelRatio;
    const centerShiftY = (canvas.height - height * ratio) / 2 / this.pixelRatio;

    ctx.drawImage(
      background,
      0,
      0,
      width,
      height,
      centerShiftX,
      centerShiftY,
      width * ratio,
      height * ratio
    );
  }

  /**
   * Draws a frame
   */
  private draw(): void {
    // schedule next draw
    this.nextAnimationFrame = window.requestAnimationFrame(this.draw);

    const { ctx, canvasWidth, canvasHeight } = this;

    if (!this.shouldDraw || !ctx) {
      return;
    }

    // if historic strokes have changed, clear canvas and redraw all
    if (this.shouldRedrawStrokes) {
      ctx.clearRect(0, 0, canvasWidth, canvasHeight);

      this.drawBackground();

      this.strokes.forEach((s) => {
        ctx.save();
        s.tool.draw(ctx, s.strokeParts, canvasWidth, canvasHeight);
        ctx.restore();
      });
    }

    // if a tool has been selected and there are
    // pending strokes, draw them
    if (this.currentTool && this.currentStroke.length) {
      ctx.save();
      this.currentTool.draw(ctx, this.currentStroke, canvasWidth, canvasHeight);
      ctx.restore();
    }

    this.shouldDraw = false;
    this.shouldRedrawStrokes = false;
  }
}
