import { Box, Flex, useMultiStyleConfig } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { CalendarDate } from "adminComponents/atoms/CalendarDate";
import { Card } from "adminComponents/atoms/Card";
import { Divider } from "adminComponents/atoms/Divider";
import { ExpandableText } from "adminComponents/atoms/ExpandableText";
import { Heading } from "adminComponents/atoms/Heading";
import { Text } from "adminComponents/atoms/Text";
import CoverImageBlob from "adminComponents/molecules/CoverImageBlob";
import { IAssignment } from "links/lib/types";

export interface IProps {
  assignment: IAssignment;
}

export const AssignmentHeroCard: React.FC<IProps> = ({ assignment }) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });

  const styles = useMultiStyleConfig("AdminHeroCard", {
    variant: "classroomAssignmentReport",
  });

  const { ends_at, description, required_score_percent } = assignment;

  const {
    title,
    cover_image_icon,
    cover_image_bg_pattern,
    cover_image_bg_color_scheme,
  } = assignment.practice_set;

  return (
    <Card
      borderColor="primary.tan"
      backgroundColor="primary.white"
      variant="adminCardThickBorderTall"
    >
      <Flex sx={styles.main}>
        <Box sx={styles.image}>
          <CoverImageBlob
            backgroundColorScheme={cover_image_bg_color_scheme}
            backgroundPattern={cover_image_bg_pattern}
            icon={cover_image_icon}
            boxSize={115}
          />
        </Box>
        <Flex sx={styles.studentDetails}>
          <Box sx={styles.studentDetailsText}>
            <Flex sx={styles.dueDate} justifyContent={"space-between"}>
              <CalendarDate
                date={ends_at}
                dateType="due"
                textVariant="adminP1"
              />
            </Flex>
            <Heading as="h1" variant="adminH3" sx={styles.heading}>
              {title}
            </Heading>
            <ExpandableText
              textVariant="adminP1"
              noOfLines={2}
              sx={styles.text}
            >
              {description}
            </ExpandableText>
          </Box>
          <Divider color="primary.light-gray" />
          <Flex sx={styles.studentDetailsStats}>
            <Flex sx={styles.accuracy}>
              <Text size="md" variant="adminP1">
                {`${t("common.questions")}: ${
                  assignment.practice_set.item_count
                }`}
              </Text>
              <Text size="md" variant="adminP1" sx={styles.divider}>
                |
              </Text>
              <Text size="md" variant="adminP1">
                {t("common.goal")}: {required_score_percent}%
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};
