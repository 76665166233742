import { Box, Text, usePrefersReducedMotion } from "@chakra-ui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { animated, config, useSpring, useTransition } from "react-spring";
import { useInterval, useTimeout } from "react-use";

import { pxToRem } from "adminComponents/utils";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { useDetectOrientation } from "sessionComponents/theme/hooks/useDetectOrientation";

export interface IRoundNumberStickerProps {
  roundNumber: number;
  delayMs?: number;
}

const LIFETIME_MS = 3000;
const AnimatedBox = animated(Box);

export const RoundNumberSticker: React.FC<IRoundNumberStickerProps> = ({
  roundNumber,
  delayMs = 0,
}) => {
  const { t } = useTranslation("session", { useSuspense: false });
  const prefersReducedMotion = usePrefersReducedMotion();

  const [roundNumbers, setRoundNumbers] = useState([roundNumber]);
  const [timeout] = useTimeout(delayMs + LIFETIME_MS);
  useInterval(() => {
    if (timeout()) setRoundNumbers([]);
  }, 200);

  const { isPortrait } = useDetectOrientation();
  const { match: currentBreakpoints } = useBreakpoints();
  const bottom = isPortrait
    ? pxToRem(currentBreakpoints.headerHeight + currentBreakpoints.margin)
    : pxToRem(currentBreakpoints.margin);

  const stickerTransition = useTransition(roundNumbers, {
    from: {
      opacity: 0,
      y: prefersReducedMotion ? 0 : 200,
    },
    enter: {
      opacity: 1,
      y: 0,
    },
    leave: {
      opacity: 0,
      y: prefersReducedMotion ? 0 : 200,
    },
    config: config.stiff,
    delay: delayMs,
  });
  const stickerTextSpring = useSpring({
    from: {
      opacity: 0,
      scale: prefersReducedMotion ? 1 : 0,
    },
    to: {
      opacity: 1,
      scale: 1,
    },
    config: prefersReducedMotion ? config.gentle : config.wobbly,
  });

  const removeSticker = () => {
    setRoundNumbers([]);
  };

  return (
    <>
      {stickerTransition(({ opacity, y }) => (
        <AnimatedBox
          color="monaco.base"
          bgColor="monaco.light"
          borderRadius={pxToRem(currentBreakpoints.borderRadius)}
          p={pxToRem(currentBreakpoints.padding)}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          aria-live="polite"
          aria-label={`${t("round.introQuestionPrefix")} ${roundNumber}`}
          position="fixed"
          bottom={bottom}
          right={isPortrait ? pxToRem(currentBreakpoints.margin) : undefined} // right-align stickers on portrait
          left={isPortrait ? undefined : pxToRem(currentBreakpoints.margin)} // left-align stickers on landscape
          style={{ opacity, transform: y.to((y) => `translateY(${y}px)`) }}
          onClick={removeSticker}
          cursor="pointer"
          zIndex={2}
        >
          <AnimatedBox
            w="full"
            display="flex"
            alignItems="center"
            justifyContent="center"
            overflow="visible"
            style={{
              opacity: stickerTextSpring.opacity,
              transform: stickerTextSpring.scale.to((s) => `scale(${s})`),
            }}
          >
            <Text
              fontSize={currentBreakpoints.fontSize * 1.5}
              textStyle="gameDisplay"
            >
              {`${t("round.introQuestionPrefix")} ${roundNumber}`}
            </Text>
          </AnimatedBox>
        </AnimatedBox>
      ))}
    </>
  );
};
