import {
  Box,
  HStack,
  Image,
  Text,
  VStack,
  useBreakpointValue,
  usePrefersReducedMotion,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { animated, config, useSpring, useTrail } from "react-spring";
import { clearTimeout, setTimeout } from "timers";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { hairReservedColor, skinReservedColor } from "links/lib/constants";
import {
  AvatarItemRarityLevels,
  IAvatarItem,
  SeasonMapSoundEffect,
} from "links/lib/types";
import { getItemPreviewDataURL } from "links/lib/util";
import { RichTextRenderer } from "sharedComponents/atoms/RichTextRenderer";
import { useAudio } from "sharedComponents/contexts/audio";

import { ReactComponent as BackgroundDropshadowSVG } from "./resource/background_dropshadow.svg";
import { ReactComponent as StarSVG } from "./resource/star.svg";

interface IUnlockedItemProps {
  item: IAvatarItem;
  showStarAnimation?: boolean; // set to false on the season map to avoid animating in the stars 2x (as per the animations provided by Kate)
  handleComplete?: () => void;
  show?: boolean;
  // how long should the card wait after all animations complete before exiting
  exitDuration?: number;
  skinToneHexColor?: string;
  hairColorHexColor?: string;
}

const AnimatedBox = animated(Box);
const AnimatedText = animated(Text);
const AnimatedImage = animated(Image);
const AnimatedStarSVG = animated(StarSVG);

export const UnlockedItem: React.FC<IUnlockedItemProps> = ({
  item,
  showStarAnimation = true,
  handleComplete,
  show,
  exitDuration = 3000,
  skinToneHexColor,
  hairColorHexColor,
}) => {
  const { name, description, rarity, preview_image_asset_url } = item;
  const { play: playAudio } = useAudio();
  const prefersReducedMotion = usePrefersReducedMotion();
  const [startTitleAnimation, setStartTitleAnimation] = useState(false);
  const [startNameAnimation, setStartNameAnimation] = useState(false);
  const [startImageAnimation, setStartImageAnimation] = useState(false);
  const [startDescriptionAnimation, setStartDescriptionAnimation] =
    useState(false);
  const [startStarsAnimation, setStartStarsAnimation] = useState(false);
  const [startRatingAnimation, setStartRatingAnimation] = useState(false);
  const [hasBgBouncedIn, setHasBgBouncedIn] = useState(false);
  const [hasImgBouncedIn, setHasImgBouncedIn] = useState(false);
  const exitInterval = useRef<null | NodeJS.Timeout>(null);
  const [previewImageUrl, setPreviewImageUrl] = useState(
    preview_image_asset_url
      ? preview_image_asset_url
      : item.attachments.length
      ? item.attachments[0].image_asset_url
      : ""
  );

  const { t } = useTranslation("session", {
    keyPrefix: "unlockedItem",
    useSuspense: false,
  });

  const rarityDisplayMap = {
    [AvatarItemRarityLevels.Level1]: {
      displayName: t("ratings.common"),
      secondaryColor: "primary.warm-white",
    },
    [AvatarItemRarityLevels.Level2]: {
      displayName: t("ratings.uncommon"),
      secondaryColor: "anjou.light-shadow-01",
    },
    [AvatarItemRarityLevels.Level3]: {
      displayName: t("ratings.rare"),
      secondaryColor: "monaco.light-shadow-01",
    },
    [AvatarItemRarityLevels.Level4]: {
      displayName: t("ratings.epic"),
      secondaryColor: "lilac.light-shadow-01",
    },
    [AvatarItemRarityLevels.Level5]: {
      displayName: t("ratings.legendary"),
      secondaryColor: "tangerine.light-shadow-02",
    },
  };

  useEffect(() => {
    if (!show) return;
    if (!startStarsAnimation) return;

    switch (parseInt(rarity.toString(), 10) as AvatarItemRarityLevels) {
      case AvatarItemRarityLevels.Level1:
        playAudio(SeasonMapSoundEffect.ItemRevealLevel1);
        break;
      case AvatarItemRarityLevels.Level2:
        playAudio(SeasonMapSoundEffect.ItemRevealLevel2);
        break;
      case AvatarItemRarityLevels.Level3:
        playAudio(SeasonMapSoundEffect.ItemRevealLevel3);
        break;
      case AvatarItemRarityLevels.Level4:
        playAudio(SeasonMapSoundEffect.ItemRevealLevel4);
        break;
      case AvatarItemRarityLevels.Level5:
        playAudio(SeasonMapSoundEffect.ItemRevealLevel5);
        break;
    }
  }, [show, rarity, startStarsAnimation, playAudio]);

  const displayValues = rarityDisplayMap[rarity];

  const defaultSize =
    useBreakpointValue({
      base: 16,
      sm: 20,
      md: 24,
    }) || 16;

  const fontSize = defaultSize;
  const padding = defaultSize;
  const margin = defaultSize;

  const iconSize =
    useBreakpointValue({
      base: 20,
      sm: 25,
      md: 30,
      lg: 30,
      xl: 40,
    }) || 20;

  const borderRadius =
    useBreakpointValue({
      base: 24,
      sm: 24,
      md: 28,
      lg: 32,
      xl: 40,
    }) || 24;

  const fontSize1point5 = fontSize * 1.5;

  const iconSizeThird = iconSize / 3;

  useEffect(() => {
    if (!show) {
      setStartTitleAnimation(false);
      setStartNameAnimation(false);
      setStartImageAnimation(false);
      setStartDescriptionAnimation(false);
      setStartStarsAnimation(false);
      setStartRatingAnimation(false);
      setHasBgBouncedIn(false);
      setHasImgBouncedIn(false);

      if (exitInterval.current) {
        clearTimeout(exitInterval.current);
        exitInterval.current = null;
      }
    }
  }, [show]);

  useEffect(() => {
    getItemPreviewDataURL(
      preview_image_asset_url,
      item.default_replacement_hex_code,
      hairColorHexColor ?? hairReservedColor,
      skinToneHexColor ?? skinReservedColor
    ).then(setPreviewImageUrl);
  }, [
    preview_image_asset_url,
    item.default_replacement_hex_code,
    hairColorHexColor,
    skinToneHexColor,
  ]);

  const handleAnimationsComplete = () => {
    if (show && handleComplete) {
      exitInterval.current = setTimeout(() => {
        handleComplete();
      }, exitDuration);
    }
  };

  const backgroundZoomIn = useSpring({
    from: {
      opacity: 0,
      scale: 0,
    },
    to: {
      opacity: show ? 1 : 0,
      scale: show ? 1 : 0,
    },
    config: prefersReducedMotion ? config.gentle : config.stiff,
    onRest: () => {
      if (show) {
        setStartTitleAnimation(true);
      }
    },
  });

  const titleSwipeIn = useSpring({
    from: {
      top: 30,
      opacity: 0,
    },
    to: {
      top: startTitleAnimation ? 0 : 30,
      opacity: startTitleAnimation ? 1 : 0,
    },
    config: prefersReducedMotion ? config.gentle : config.stiff,
    onStart: () => {
      if (show) {
        setStartNameAnimation(true);
      }
    },
    delay: 300,
  });

  const nameFadeIn = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: startNameAnimation ? 1 : 0,
    },
    config: prefersReducedMotion ? config.gentle : config.stiff,
    delay: 300,
    onRest: () => {
      if (show) {
        setStartImageAnimation(true);
      }
    },
  });

  const imageBgBounceIn = useSpring({
    from: {
      scale: hasBgBouncedIn ? 1 : 0,
    },
    to: async (next) => {
      await next({
        scale: startImageAnimation ? (hasBgBouncedIn ? 1 : 1.2) : 0,
      });

      await next({
        scale: startImageAnimation ? 1 : 0,
      });
    },
    config: prefersReducedMotion ? config.gentle : config.stiff,
    onStart: () => {
      if (show) {
        setHasBgBouncedIn(true);
      }
    },
  });

  //same as above but with slight delay
  const imageBounceIn = useSpring({
    from: {
      scale: hasImgBouncedIn ? 1 : 0,
    },
    to: async (next) => {
      await next({
        scale: startImageAnimation ? (hasImgBouncedIn ? 1 : 1.2) : 0,
      });

      await next({
        scale: startImageAnimation ? 1 : 0,
      });
    },
    config: prefersReducedMotion ? config.gentle : config.stiff,
    delay: 200,
    onStart: () => {
      if (show) {
        setHasImgBouncedIn(true);
        setStartDescriptionAnimation(true);
      }
    },
  });

  const descriptionFadeIn = useSpring({
    from: {
      opacity: 0,
    },
    to: { opacity: startDescriptionAnimation ? 1 : 0 },
    config: prefersReducedMotion ? config.gentle : config.molasses,
    delay: 1000,
    onStart: () => {
      if (show) {
        setStartStarsAnimation(true);
      }
    },
  });

  const starsArray = [...Array(Number(rarity)).keys()];

  const starsAnimation = useTrail(starsArray.length, {
    config: prefersReducedMotion ? config.gentle : config.stiff,
    opacity: startStarsAnimation ? 1 : 0,
    height: startStarsAnimation ? iconSize : 0,
    from: {
      opacity: 0,
      height: 0,
    },
    onRest: () => {
      if (show) {
        setStartRatingAnimation(true);
      }
    },
  });

  const ratingFadeIn = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: startRatingAnimation ? 1 : 0,
    },
    config: prefersReducedMotion ? config.gentle : config.stiff,
    onRest: handleAnimationsComplete,
  });

  return (
    <Box
      minH="100vh"
      w="full"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <AnimatedBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={backgroundZoomIn}
      >
        <Box
          zIndex={10}
          padding={pxToRem(padding)}
          color="primary.warm-black"
          flexGrow={0}
          backgroundColor="primary.white"
          borderRadius={borderRadius}
          maxW={pxToRem(500)}
          minW={pxToRem(290)}
        >
          <VStack
            w="full"
            alignItems="center"
            justifyContent="space-between"
            spacing={pxToRem(margin)}
            margin="0 auto"
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              marginTop={pxToRem(margin)}
              textAlign="center"
              w="full"
            >
              <AnimatedText
                textStyle="gameDisplay"
                fontSize={pxToRem(fontSize)}
                style={titleSwipeIn}
              >
                {t("title")}
              </AnimatedText>
              <AnimatedText
                textStyle="gameDisplayInline"
                fontSize={pxToRem(fontSize1point5)}
                style={nameFadeIn}
                fontWeight="700"
              >
                {`${name}!`}
              </AnimatedText>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              <Box
                color={displayValues.secondaryColor}
                zIndex={1}
                position="relative"
                w="50%"
              >
                <AnimatedBox
                  width="100%"
                  style={imageBgBounceIn}
                  position="absolute"
                  top={0}
                  left={0}
                  right={0}
                  bottom={0}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  zIndex={1}
                  maxH="20vh"
                >
                  <BackgroundDropshadowSVG height="100%" width="100%" />
                </AnimatedBox>

                <AnimatedImage
                  src={previewImageUrl}
                  w="100%"
                  top="0"
                  style={imageBounceIn}
                  position="relative"
                  zIndex={10}
                  maxHeight="20vh"
                />
              </Box>
              <AnimatedText
                textAlign="center"
                textStyle="gameTextWeighted"
                fontSize={pxToRem(fontSize)}
                marginTop={pxToRem(margin)}
                style={descriptionFadeIn}
                paddingX={pxToRem(padding)}
              >
                <RichTextRenderer content={description} />
              </AnimatedText>
            </Box>

            <Box paddingBottom={pxToRem(padding)}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <HStack
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  // Add small buffer to minH to ensure stars do not overflow when animating
                  minH={pxToRem(iconSize + iconSize * 0.1)}
                  spacing={pxToRem(iconSizeThird)}
                >
                  {showStarAnimation
                    ? starsAnimation.map(({ height, ...style }, index) => (
                        <AnimatedStarSVG
                          key={index}
                          style={style}
                          // prevent negative height
                          height={height.to((h) => pxToRem(Math.max(h, 0)))}
                          width="auto"
                        />
                      ))
                    : starsArray.map((rarity) => (
                        <StarSVG
                          key={rarity}
                          height={pxToRem(iconSize)}
                          width="auto"
                        />
                      ))}
                </HStack>
                <AnimatedText
                  textAlign="center"
                  textStyle="gameTextWeighted"
                  fontWeight="700"
                  fontSize={pxToRem(fontSize)}
                  marginTop={pxToRem(margin / 2)}
                  style={ratingFadeIn}
                >
                  {displayValues.displayName}
                </AnimatedText>
              </Box>
            </Box>
          </VStack>
        </Box>
      </AnimatedBox>
    </Box>
  );
};
