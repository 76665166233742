import { Box, HStack } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useDrop } from "react-dnd";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { ISessionGroup } from "links/lib/types";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";

import { getPlacementGraph } from "./getPlacementGraph";

export interface ITokenBoardCellProps {
  enableDrop: boolean;
  placements: Array<{
    round_number: number;
    group: ISessionGroup;
    showPlaceholder?: boolean;
  }>;
  onPlaceToken?: () => void;
  cellSize: number;
  hideEmptyPlacements: boolean;
}

export const TokenBoardCell: React.FC<ITokenBoardCellProps> = ({
  enableDrop,
  placements,
  onPlaceToken,
  cellSize,
  hideEmptyPlacements,
}) => {
  const { match: currentBreakpoints } = useBreakpoints();

  const placeholderSize = Math.floor(cellSize * 0.3);

  const content = useMemo(() => {
    return getPlacementGraph({
      borderWidthRems: pxToRem(currentBreakpoints.borderWidth),
      placeholderSize,
      placements,
      hideEmptyPlacements,
    });
  }, [placements, currentBreakpoints, placeholderSize, hideEmptyPlacements]);

  const [dropProps, dropRef] = useDrop(() => ({
    accept: "TOKEN",
    drop: () => {
      onPlaceToken && onPlaceToken();
    },
    collect: (monitor) => {
      return {
        isOver: monitor.isOver(),
      };
    },
  }));

  return (
    <Box
      overflow="hidden"
      ref={enableDrop ? dropRef : null}
      w="full"
      h="full"
      top="0"
      left="0"
      position="absolute"
      bg={
        enableDrop && dropProps?.isOver ? "golden.base-highlight-01" : "unset"
      }
    >
      <HStack
        key="cellrow1"
        spacing={0}
        w="full"
        h="33.3%"
        alignItems="center"
        justifyContent="space-around"
      >
        {content[0]}
      </HStack>
      <HStack
        key="cellrow2"
        spacing={0}
        w="full"
        h="33.3%"
        alignItems="center"
        justifyContent="space-around"
      >
        {content[1]}
      </HStack>
      <HStack
        key="cellrow3"
        spacing={0}
        w="full"
        h="33.3%"
        alignItems="center"
        justifyContent="space-around"
      >
        {content[2]}
      </HStack>
    </Box>
  );
};
