import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { GoogleClassroomClassroom } from "../../types";
import { useAuth } from "../auth";

export interface IFetchGoogleClassroomClassroomsResponse {
  classrooms: Array<GoogleClassroomClassroom>;
  requires_authorization: boolean;
}

/**
 * Fetches a list of Google Classroom Classrooms and students
 * @returns
 */
export default function useFetchGoogleClassroomClassrooms(): UseQueryResult<
  IFetchGoogleClassroomClassroomsResponse,
  unknown
> {
  const axios = useAxios();
  const { authUser } = useAuth();

  const query = useQuery<IFetchGoogleClassroomClassroomsResponse>(
    ["google-classroom-classrooms"],
    async (): Promise<IFetchGoogleClassroomClassroomsResponse> => {
      return await axios
        .get(
          "/v1/identity-provider/rostering/classrooms?provider=google_classroom"
        )
        .then((r) => r.data as IFetchGoogleClassroomClassroomsResponse);
    },
    { enabled: !authUser?.is_guest }
  );

  return query;
}
