import { pxToRem } from "adminComponents/utils/pxToRem";

export const AdminToast = {
  baseStyle: {
    alignItems: "center",
    display: "flex",
    backgroundColor: "primary.warm-black",
    borderRadius: pxToRem(8),
    gap: { base: pxToRem(12), md: pxToRem(24) },
    paddingX: { base: pxToRem(16), md: pxToRem(24) },
    paddingY: { base: pxToRem(14), md: pxToRem(21) },
    width: "fit-content",
  },
};
