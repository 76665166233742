import {
  Box,
  BoxProps,
  CloseButton,
  Divider,
  HStack,
  useBreakpointValue,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "react-use";

import { NavigationLink } from "adminComponents";
import { Heading } from "adminComponents/atoms/Heading";
import { PremiumIcon } from "adminComponents/atoms/PremiumIcon";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils";
import { useAuth } from "links/lib/features/auth";
import { IUser } from "links/lib/types";
import { useGetPremiumLink } from "sharedComponents/hooks/useGetPremiumLink";

import { Link } from "./Link";

interface IProps extends BoxProps {
  accountLinks: NavigationLink[];
  authActionLinks: NavigationLink[];
  resourceLinks: NavigationLink[];
  handleClose: () => void;
  user: IUser;
  showPremiumMarker?: boolean;
}

export const TeacherAccountMenu: React.FC<IProps> = ({
  accountLinks,
  authActionLinks,
  resourceLinks,
  handleClose,
  showPremiumMarker = true,
  user,
  ...rest
}) => {
  const { height: windowHeight } = useWindowSize();
  // Calculate the maximum height based on window height, header height, and help widget height
  const maxH = useBreakpointValue({
    base: "100%",
    md: pxToRem(windowHeight - 120),
  });
  const styles = useMultiStyleConfig("AdminTeacherAccountMenu", {
    visible: true,
    maxH,
  });
  const { t } = useTranslation("admin", { useSuspense: false });
  const { authLicense, hasNoPremiumAccess } = useAuth();

  const now = moment();
  const isInOrganization = user.organization_id && user.organization_id !== "0";
  const licenseEndMoment = moment(authLicense?.end_date);
  const licenseEndDateInFuture = licenseEndMoment.isAfter(now);
  const daysLeftInLicense = moment(authLicense?.end_date).diff(now, "days");
  const individualTrialExpiringSoon =
    !isInOrganization &&
    moment(authLicense?.end_date).diff(moment(), "days") <= 14 &&
    moment(authLicense?.end_date).diff(moment(), "days") > 0;

  const premiumLink = useGetPremiumLink();
  const handlePremiumClick = () => {
    window.location.href = premiumLink;
  };

  return (
    <Box __css={styles.hoverContainer}>
      <Box __css={styles.container} {...rest}>
        <Box __css={styles.header}>
          <Box __css={styles.close}>
            <CloseButton onClick={handleClose} size="lg" />
          </Box>
          <Heading as="h4" variant="adminH4">
            {`${user?.given_name} ${user?.family_name}`}
          </Heading>
          <Text variant="adminP2Bold">{user?.email}</Text>
          {user.organization_name && (
            <Text
              variant="adminP2"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {user?.organization_name}
            </Text>
          )}
          {showPremiumMarker &&
            authLicense?.is_trial &&
            licenseEndDateInFuture && (
              <HStack sx={styles.premiumContainer}>
                <PremiumIcon type="account" />
                <Text
                  variant="adminLinkMedium"
                  color={"primary.dark-gray"}
                  cursor="pointer"
                  onClick={handlePremiumClick}
                >
                  {t("common.premiumTrial")}{" "}
                  {authLicense?.is_in_grace_period ||
                  individualTrialExpiringSoon
                    ? `(${t("common.expiringSoon")})`
                    : t("common.premiumTrialDaysLeft", {
                        count: daysLeftInLicense,
                      })}
                </Text>
              </HStack>
            )}
          {showPremiumMarker && !authLicense?.is_trial && !hasNoPremiumAccess && (
            <HStack sx={styles.premiumContainer}>
              <PremiumIcon type="account" />
              <Text variant="adminP2" color={"primary.dark-gray"}>
                {t("common.premiumAccount")}
                {authLicense?.is_in_grace_period &&
                  ` (${t("common.expiringSoon")})`}
              </Text>
            </HStack>
          )}
          {hasNoPremiumAccess && (
            <Text
              variant="adminLinkMedium"
              color={"primary.dark-gray"}
              cursor="pointer"
              onClick={handlePremiumClick}
              mt={pxToRem(4)}
            >
              {t("common.freeAccount")}
            </Text>
          )}
        </Box>
        <Divider __css={styles.divider} />
        <Box as="ul" __css={styles.links}>
          {accountLinks.map((link, i) => (
            <Link link={link} key={i} styles={styles.link} />
          ))}
        </Box>
        <Divider __css={styles.divider} />
        <Box as="ul" __css={styles.links}>
          {resourceLinks.map((link, i) => (
            <Link link={link} key={i} styles={styles.link} />
          ))}
        </Box>
        <Divider __css={styles.divider} />
        <Box as="ul" __css={styles.links}>
          {authActionLinks.map((link, i) => (
            <Link link={link} key={i} styles={styles.link} />
          ))}
        </Box>
      </Box>
    </Box>
  );
};
