import { Box, Grid } from "@chakra-ui/react";
import React, { useCallback, useMemo, useState } from "react";

import { IProps as AvatarCardProps } from "adminComponents/atoms/AvatarEditingCard";
import { AvatarEditingCard } from "adminComponents/atoms/AvatarEditingCard";
import {
  Carousel,
  ICarouselPosition,
} from "adminComponents/molecules/Carousel";
import { pxToRem } from "adminComponents/utils/pxToRem";

export interface IProps {
  isMobile: boolean;
  id?: string;
  avatarCardData: Array<AvatarCardProps>;
  handleSelected?: (value: string | number) => void;
}

export const AvatarGridCarousel: React.FC<IProps> = ({
  isMobile,
  id,
  avatarCardData,
  handleSelected,
}) => {
  const handleClick = useCallback(
    (value: string | number) => {
      handleSelected?.(value);
    },
    [handleSelected]
  );

  const chunkSize = 6;
  const [carouselActiveIndex, setCarouselActiveIndex] = useState(0);

  const onUpdateCarouselState = ({ activeIndex }: ICarouselPosition) => {
    setCarouselActiveIndex(activeIndex);
  };

  const cards = useMemo(() => {
    const renderCards = (data: Array<AvatarCardProps>) =>
      data.map((card) => {
        return (
          <AvatarEditingCard
            {...card}
            handleClick={() => {
              handleClick?.(card.id);
            }}
            key={`avatar-item-card-${card.id}`}
          />
        );
      });

    if (isMobile) {
      return renderCards(avatarCardData);
    }

    const avatarCardGroups: Array<Array<AvatarCardProps>> = [];
    for (let i = 0; i < avatarCardData.length; i += chunkSize) {
      const chunk = avatarCardData.slice(i, i + chunkSize);
      avatarCardGroups.push(chunk);
    }

    return avatarCardGroups.map((avatarCardGroup, i) => (
      <Grid
        templateColumns="1fr 1fr 1fr"
        gap={{ base: pxToRem(16), lg: pxToRem(24) }}
        key={`avatar-item-card-group-${i}`}
      >
        {/* For performance reasons, render cards on only prior, current, and next 
		carousel pages to limit number of rendered of cards */}
        {(i - 1 === carouselActiveIndex ||
          i === carouselActiveIndex ||
          i + 1 === carouselActiveIndex) &&
          renderCards(avatarCardGroup)}
      </Grid>
    ));
  }, [isMobile, avatarCardData, carouselActiveIndex, handleClick]);

  if (isMobile) {
    return (
      <Box mt={pxToRem(16)} overflow="hidden" position="relative" width="100%">
        <Grid
          templateRows="1fr 1fr"
          gridAutoFlow="column"
          width="fit-content"
          overflowY="auto"
          maxWidth="100vw"
          rowGap={pxToRem(16)}
          columnGap={pxToRem(16)}
          mb={pxToRem(-16)}
          pb={pxToRem(16)}
          px={pxToRem(16)}
        >
          {cards}
        </Grid>
        <Box
          bgGradient={`linear(to right, primary.warm-white, rgba(253, 250, 243, 0) 100%)`}
          sx={{
            display: "block",
            width: pxToRem(16),
            height: "100%",
            zIndex: 10,
            position: "absolute",
            left: 0,
            top: 0,
          }}
        />
        <Box
          bgGradient={`linear(to left, primary.warm-white, rgba(253, 250, 243, 0) 100%)`}
          sx={{
            display: "block",
            width: pxToRem(16),
            height: "100%",
            zIndex: 10,
            position: "absolute",
            right: 0,
            top: 0,
          }}
        />
      </Box>
    );
  }

  return (
    <Carousel
      id={id}
      variant="adminCarousel"
      slidesPerView={1}
      spaceBetween={24}
      showArrows
      stateUpdatedHandler={onUpdateCarouselState}
    >
      {cards}
    </Carousel>
  );
};
