import {
  Box,
  ButtonGroup,
  VisuallyHidden,
  useBreakpointValue,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Icon } from "adminComponents/atoms/Icon";
import { IconButton } from "adminComponents/atoms/IconButton";
import { pxToRem } from "adminComponents/utils/pxToRem";

export interface ISeasonMapHeaderProps {
  handleBack?: () => void;
  handleHome?: () => void;
  handleMusicToggle?: () => void;
  isMusicPlaying: boolean;
  tokenCount: number;
  tokenLabel: string;
  tokenIcon: React.ReactElement;
}

export const SeasonMapHeader: React.FC<ISeasonMapHeaderProps> = ({
  tokenCount,
  tokenIcon,
  tokenLabel,
  isMusicPlaying,
  handleBack,
  handleHome,
  handleMusicToggle,
}) => {
  const buttonSize = useBreakpointValue({ base: "sm", md: "md" }) || "sm";
  const iconButtonSize =
    useBreakpointValue({ base: pxToRem(40), md: pxToRem(50) }) || pxToRem(40);
  const windowPadding = useBreakpointValue({
    base: pxToRem(10),
    md: pxToRem(20),
  });
  const showButtonLabels = !!useBreakpointValue({ base: false, md: true });
  const { t } = useTranslation("map", {
    useSuspense: false,
    keyPrefix: "header",
  });

  return (
    <>
      <Box
        position="absolute"
        top={windowPadding}
        left={windowPadding}
        zIndex={2}
      >
        {showButtonLabels && (
          <ButtonGroup>
            <Button
              leftIcon={<Icon icon="arrow_back" />}
              variant="adminButtonFilled"
              size={buttonSize}
              onClick={handleBack}
            >
              {t("backButton")}
            </Button>
            <Button
              leftIcon={<Icon icon="home_outlined" />}
              variant="adminButtonWhite"
              size={buttonSize}
              onClick={handleHome}
            >
              {t("homeButton")}
            </Button>
          </ButtonGroup>
        )}
        {!showButtonLabels && (
          <ButtonGroup>
            <IconButton
              shape="circle"
              ariaLabel={t("backButton")}
              icon="arrow_back"
              boxSize={iconButtonSize}
              onClick={handleBack}
            />
            <IconButton
              shape="circle"
              ariaLabel={t("homeButton")}
              icon="home_outlined"
              boxSize={iconButtonSize}
              onClick={handleHome}
            />
          </ButtonGroup>
        )}
      </Box>

      <Box
        position="absolute"
        top={windowPadding}
        right={windowPadding}
        zIndex={2}
      >
        <ButtonGroup>
          <IconButton
            ariaLabel={t("musicButton")}
            icon="map_music"
            boxSize={iconButtonSize}
            colorScheme="primary.white"
            shape="circle"
            iconColor={
              isMusicPlaying ? "primary.golden-hover" : "primary.dark-gray"
            }
            sx={{
              _hover: {
                bgColor: "primary.warm-white",
              },
            }}
            onClick={handleMusicToggle}
          />
          <Button
            leftIcon={tokenIcon}
            cursor="default"
            variant="adminSeasonMapTicketCounter"
            size={buttonSize}
            aria-label={`${tokenCount} ${tokenLabel}`}
          >
            {tokenCount}
            <VisuallyHidden>{tokenLabel}</VisuallyHidden>
          </Button>
        </ButtonGroup>
      </Box>
    </>
  );
};
