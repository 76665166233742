import { UseInfiniteQueryResult, useInfiniteQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";

export interface IFetchUserFollowingInfiniteResponse {
  user_ids: Array<string>;
  page: number;
  per_page: number;
}

export interface IFetchUserFollowingInfiniteParams {
  userId: string;
  perPage?: number;
  disabled?: boolean;
}

export default function useFetchUserFollowingInfinite(
  args: IFetchUserFollowingInfiniteParams
): UseInfiniteQueryResult<IFetchUserFollowingInfiniteResponse> {
  const axios = useAxios();

  const { disabled, userId, perPage } = args;

  const query = useInfiniteQuery<IFetchUserFollowingInfiniteResponse>(
    ["fetchUserFollowingInfinite", { userId }],
    async ({ pageParam = 1 }): Promise<IFetchUserFollowingInfiniteResponse> => {
      return await axios
        .get(`/v1/users/${userId}/following`, {
          params: {
            page: pageParam,
            per_page: perPage,
          },
        })
        .then((r) => r.data as IFetchUserFollowingInfiniteResponse);
    },
    {
      enabled: !disabled,
      getNextPageParam: (lastPage) => {
        return lastPage.page + 1;
      },
    }
  );

  return query;
}
