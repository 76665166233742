import { Box } from "@chakra-ui/react";
import React from "react";

import { IHandleViewStudentResponses } from "adminComponents/molecules/ClassroomAnswerResultsMetric";
import { ClassroomReportQuestionList } from "adminComponents/molecules/ClassroomReportQuestionList";
import { pxToRem } from "adminComponents/utils/pxToRem";
import {
  IPracticeSetItem,
  IPracticeSetSessionItemReport,
} from "links/lib/types";

interface QuestionsTabProps {
  questionsData: {
    practiceSetItem: IPracticeSetItem;
    classroomResults:
      | IPracticeSetSessionItemReport
      | IPracticeSetSessionItemReport[];
  }[];
  handleViewStudentResponses: IHandleViewStudentResponses;
  studentsCount?: number;
}

export const QuestionsTab: React.FC<QuestionsTabProps> = ({
  questionsData,
  handleViewStudentResponses,
  studentsCount,
}) => {
  return (
    <Box py={[pxToRem(32), null, pxToRem(48)]}>
      <ClassroomReportQuestionList
        items={questionsData}
        handleViewStudentResponses={handleViewStudentResponses}
        studentsCount={studentsCount}
      />
    </Box>
  );
};
