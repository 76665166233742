import { Box } from "@chakra-ui/react";
import lottie, { AnimationItem } from "lottie-web";
import React, { useEffect, useRef } from "react";
import { useMount, useUnmount } from "react-use";
import { clearTimeout, setTimeout } from "timers";

import { StudentSessionSoundEffect } from "sessionComponents/types";
import { useSessionAudio } from "sharedComponents/contexts/sessionAudio";

import animationData from "./resource/care-package-received.json";

const animationDataString = JSON.stringify(animationData);

export interface ICarePackageNoticeProps {
  show: boolean;
  onEnd: () => void;
}

export const CarePackageNotice: React.FC<ICarePackageNoticeProps> = ({
  show,
  onEnd,
}) => {
  const playerRef = useRef<HTMLDivElement | null>(null);
  const animRef = useRef<AnimationItem | null>(null);
  const animIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const { play: playAudio } = useSessionAudio();

  useMount(() => {
    if (!playerRef.current) return;

    animRef.current = lottie.loadAnimation({
      container: playerRef.current,
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: JSON.parse(animationDataString),
    });
  });

  useUnmount(() => {
    if (animIntervalRef.current) clearTimeout(animIntervalRef.current);
    animRef.current?.stop();
    animRef.current?.destroy();
    lottie.stop();
    lottie.destroy();
  });

  useEffect(() => {
    const play = () => {
      if (!animRef.current?.isLoaded) {
        console.warn(
          "Could not play care package notice animation - animation not loaded"
        );
        return;
      }

      if (animIntervalRef.current) clearTimeout(animIntervalRef.current);

      animRef.current?.stop();
      animRef.current?.goToAndPlay(0);
      playAudio(StudentSessionSoundEffect.CarePackageNotice);
      animIntervalRef.current = setTimeout(() => {
        animIntervalRef.current = null;
        onEnd();
      }, 5000);
    };
    if (show) play();
  }, [show, onEnd, playAudio]);

  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      right="0"
      bottom="0"
      bg="transparent"
      display={show ? "block" : "none"}
      zIndex="20"
      overflow="hidden"
    >
      <Box
        position="absolute"
        left="0"
        right="0"
        bottom="0"
        top="0"
        ref={playerRef}
      />
    </Box>
  );
};
