import React from "react";

import {
  PracticeSessionItemVariantType,
  QuestionType,
  RichText,
} from "links/lib/types";
import {
  GameChoiceType,
  ISelectableOptionProps,
} from "sessionComponents/types";

import { ClassificationOption } from "./components/ClassificationOption";
import { DiagramOption } from "./components/DiagramOption";
import { DrawVotingOption } from "./components/Drawing/DrawVotingOption";
import { SelectableOption } from "./components/SelectableOption";
import { TextInputOption } from "./components/TextInputOption";

type IQuestionResponseProps = Omit<
  ISelectableOptionProps,
  "currentBreakpoints"
> & {
  variant: PracticeSessionItemVariantType;
  inputValue?: string;
  setInputValue?: (value: string) => void;
  showInput?: boolean;
  showSubmittedInput?: boolean;
  onDeleteButtonClick?: () => void;
  questionType?: QuestionType; //required for CoopDragsort variants
  prefix?: RichText; //on text options
  suffix?: RichText; //on text options
  number?: number; //on Diagram option
  showAsSelectable?: boolean; //for turning off hover, pointer cursor, and checkbox/radio when teacher is looking at options in round screen
  allowDeleteImage?: boolean; // shows/hides the button to delete a Draw Voting Option (only teachers can see the button)
};

export const QuestionResponse: React.FC<IQuestionResponseProps> = ({
  inputValue = "",
  setInputValue,
  showInput = true,
  showSubmittedInput = false,
  showAsSelectable = true,
  allowDeleteImage = false,
  ...props
}) => {
  const { variant } = props;
  switch (variant) {
    case PracticeSessionItemVariantType.CoopRadios:
    case PracticeSessionItemVariantType.CoopChecks:
      return (
        <SelectableOption {...props} showAsSelectable={showAsSelectable} />
      );
    case PracticeSessionItemVariantType.CoopDragsort:
      switch (props.questionType) {
        case QuestionType.Classify:
          return (
            <ClassificationOption
              {...props}
              iconColor={
                !props.error && !props.success ? "utility.link" : undefined
              }
              showAsSelectable={showAsSelectable}
            />
          );
        case QuestionType.Diagram:
          if (!props.text || !props.number) return <></>;
          return (
            <DiagramOption
              {...props}
              text={props.text}
              number={props.number}
              showAsSelectable={showAsSelectable}
            />
          );
        default:
          return <>Unknown Dragsort Question Type</>;
      }
    case PracticeSessionItemVariantType.CoopDraw:
      if (!props.imageUrl) return <></>;
      return (
        <DrawVotingOption
          {...props}
          imageUrl={props.imageUrl}
          imageAltText={props.imageAltText || ""}
          showAsSelectable={showAsSelectable}
          allowDeleteImage={allowDeleteImage}
        />
      );

    case PracticeSessionItemVariantType.CoopTextMatch:
    case PracticeSessionItemVariantType.CoopTextMatchNumeric:
      if (!setInputValue)
        return (
          <SelectableOption
            {...props}
            showAsSelectable={showAsSelectable}
            variant={PracticeSessionItemVariantType.CoopRadios}
            choiceType={GameChoiceType.Radio}
          />
        );
      return (
        <TextInputOption
          {...props}
          value={inputValue}
          setValue={setInputValue}
          showInput={showInput}
          showSubmitted={showSubmittedInput}
        />
      );
    case PracticeSessionItemVariantType.NoVariant:
      return <>Error: Variant Missing</>;
    default:
      return <>Error: Unknown Variant</>;
  }
};
