import { Box, BoxProps, Flex, Text } from "@chakra-ui/react";
import React from "react";

import { ColorScheme } from "../../../adminComponents";
import { pxToRem } from "../../../adminComponents/utils/pxToRem";

import { useBreakpoints } from "../../contexts/breakpoints";

export interface IProgressBarProps extends BoxProps {
  percentValue: number;
  label?: string;
  borderColor?: ColorScheme;
  backgroundColor?: ColorScheme;
  fillColor?: ColorScheme | string;
  borderWidth?: string;
  borderRadius?: string;
  useThickBorder?: boolean;
  labelFontWeight?: string;
  height?: string;
  width?: string;
}

export const ProgressBar: React.FC<IProgressBarProps> = ({
  percentValue,
  label,
  borderColor = "primary.tan",
  backgroundColor = "primary.white",
  fillColor = "progress.yellow",
  borderWidth,
  borderRadius,
  useThickBorder = false,
  labelFontWeight,
  height,
  width,
  ...rest
}) => {
  const { match: currentBreakpoints } = useBreakpoints();

  const defaultBorderWidth = useThickBorder
    ? currentBreakpoints.borderWidth * 3
    : currentBreakpoints.borderWidth;

  let percent = percentValue;

  if (!isFinite(percentValue)) {
    // prevent divide by 0
    percent = 100;
  } else if (percentValue < 0) {
    percent = 0;
  } else if (percentValue > 100) {
    percent = 100;
  }

  return (
    <Box
      border="solid"
      borderWidth={borderWidth ?? pxToRem(defaultBorderWidth)}
      borderRadius={borderRadius ?? pxToRem(currentBreakpoints.borderRadius)}
      borderColor={borderColor}
      background={backgroundColor}
      height={height ?? pxToRem(currentBreakpoints.buttonHeight)}
      width={width ?? "100%"}
      maxW="100%"
      overflow="hidden"
      data-progress={percent}
      {...rest}
    >
      <Box
        h="100%"
        maxH="100%"
        w={`${percent}%`}
        background={fillColor}
        overflow="visible"
      >
        {!!label && (
          <Flex
            paddingLeft={pxToRem(currentBreakpoints.padding)}
            alignItems="center"
            h="100%"
            maxW="100%"
            whiteSpace="nowrap"
          >
            <Text
              textStyle="gameTextWeighted"
              fontSize={pxToRem(currentBreakpoints.fontSize)}
              fontWeight={labelFontWeight}
            >
              {label}
            </Text>
          </Flex>
        )}
      </Box>
    </Box>
  );
};
