import { Box } from "@chakra-ui/react";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useMount } from "react-use";

import { useAuth } from "links/lib/features/auth";
import { UserRole } from "links/lib/types";
import AdminHome from "screens/AdminHome";
import ProtectedRoute from "screens/App/components/ProtectedRoute";
import AppError from "screens/AppError";
import { TeacherNavigationDataProvider } from "screens/TeacherDashboard/contexts/TeacherNavigationDataProvider";
import { TeacherStaffManagement } from "screens/TeacherMyTeachers";

import { insertChatbox } from "../../lib/salesforceChat";

const AdminDashboard: React.FC = () => {
  const { authUser, authRequiredAgreements, isGhostUser } = useAuth();

  const shouldOnboard =
    !isGhostUser && (!authUser?.country || !authUser?.language);
  const shouldShowAgreements =
    !isGhostUser &&
    authRequiredAgreements?.length &&
    authUser?.role !== UserRole.Student;

  useMount(() => {
    // Load salesforce chat
    insertChatbox(authUser?.role || UserRole.Admin);
  });

  if (shouldOnboard) {
    return <Redirect to="/a/onboard" />;
  }

  if (shouldShowAgreements) {
    return <Redirect to={"/a/agreements"} />;
  }

  return (
    <TeacherNavigationDataProvider adminMode>
      <Box bg={"primary.warm-white"} h="full" minH="100vh">
        <Switch>
          <ProtectedRoute
            path="/a/my-teachers"
            role={[UserRole.Admin]}
            exact={false}
          >
            <TeacherStaffManagement adminMode />
          </ProtectedRoute>
          <Route path="/a/reporting">
            <AdminHome />
          </Route>
          <Route path="*">
            <AppError code={404} />
          </Route>
        </Switch>
      </Box>
    </TeacherNavigationDataProvider>
  );
};

export default AdminDashboard;
