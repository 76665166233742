import React from "react";
import { animated, config, to, useSpring } from "react-spring";

import { ProgressBar } from "sessionComponents/atoms/ProgressBar";

export interface ISessionProgressBarProps {
  h?: string;
  // number between 0 and 100
  progress: number;
}

const AnimatedProgressBar = animated(ProgressBar);

const getBarColor = (progress: number): { r: number; g: number; b: number } => {
  if (progress >= 100) {
    // primary.golden
    return {
      r: 255,
      g: 198,
      b: 41,
    };
  } else {
    // utility.focus
    return {
      r: 46,
      g: 177,
      b: 165,
    };
  }
};

export const SessionProgressBar: React.FC<ISessionProgressBarProps> = ({
  progress,
  h,
}) => {
  const sanitizedProgress = Math.min(Math.max(progress, 0), 100);

  // convert "real" progress value to "engaging" progress value
  const a = 129;
  const b = 0.05;
  const engagingProgress = a * Math.log10(b * sanitizedProgress + 1);

  const progressSpring = useSpring({
    from: {
      x: 0,
    },
    to: {
      x: engagingProgress,
    },
    delay: 500,
    config: config.default,
  });

  const colorSpring = useSpring({
    from: getBarColor(0),
    to: getBarColor(engagingProgress),
    delay: 500,
    config: config.default,
  });

  return (
    <AnimatedProgressBar
      h={h}
      w="full"
      borderColor="primary.tan"
      borderRadius="full"
      borderWidth="0"
      backgroundColor="primary.tan"
      fillColor={to(
        [colorSpring.r, colorSpring.g, colorSpring.b],
        (r, g, b) => `rgb(${r}, ${g}, ${b})`
      )}
      labelFontWeight="bold"
      percentValue={progressSpring.x}
    />
  );
};
