import { Box, HStack } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useMeasure } from "react-use";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { ISessionGroup, SessionTheBigBoardSpaceType } from "links/lib/types";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";

import { BorderLights } from "../BorderLights";
import { BoardCell } from "./components/BoardCell";

export type IBoardSpacePlacements = {
  group: ISessionGroup;
  round_number: number;
  showPlaceholder: boolean;
}[];

export interface IBoardSpace {
  space_id: number;
  placements: Array<{
    group: ISessionGroup;
    round_number: number;
    showPlaceholder: boolean;
  }>;
  isHighlighted: boolean;
  placement_disabled: boolean;
  space_type: SessionTheBigBoardSpaceType;
  smiley_space_point_multiplier?: string;
}

export interface IBigBoardProps {
  rows: IBoardSpace[][];
  allowDrop: boolean;
  showSpecialSpaces: boolean;
  isSpinning?: boolean;
  onPlaceToken?: (space_id: number) => void;
  hideEmptyPlacements?: boolean;
  reachedTargetRow?: boolean;
  allLightsLit?: boolean;
  prizeRoundPoints?: number;
}

export const BigBoard: React.FC<IBigBoardProps> = ({
  rows,
  allowDrop,
  showSpecialSpaces,
  isSpinning,
  onPlaceToken,
  hideEmptyPlacements = false,
  reachedTargetRow = false,
  allLightsLit = false,
  prizeRoundPoints,
}) => {
  const { match: currentBreakpoints } = useBreakpoints();
  const [
    boardContainerRef,
    { width: boardContainerWidth, height: boardContainerHeight },
  ] = useMeasure<HTMLDivElement>();

  const width = Math.min(boardContainerWidth, boardContainerHeight);

  const calculatedCellSize =
    rows.length > 0 ? Math.floor(width / rows[0].length) : 0;

  const calculatedCellSizeRem = pxToRem(calculatedCellSize);

  const boardContent = useMemo(() => {
    return rows.map((row) => {
      return row.map(
        (
          {
            placements,
            isHighlighted,
            space_id,
            space_type,
            placement_disabled,
            smiley_space_point_multiplier,
          },
          colIndex
        ) => {
          const highlightedBorderColor = isHighlighted
            ? "primary.golden-hover"
            : "primary.tan";

          let leftHighLightedBorderColor = highlightedBorderColor;
          let rightHighlightedBorderColor = highlightedBorderColor;

          if (!allowDrop && isSpinning && !reachedTargetRow) {
            const isFirst = colIndex === 0;
            const isLast = colIndex + 1 === row.length;

            // will appear as whole row highlited
            leftHighLightedBorderColor =
              isHighlighted && isFirst ? "primary.golden-hover" : "primary.tan";
            rightHighlightedBorderColor =
              isHighlighted && isLast ? "primary.golden-hover" : "primary.tan";
          }

          let cellBackgroundColor = "transparent";
          if (isSpinning && !isHighlighted)
            cellBackgroundColor = "bigBoard.notHighlightedBackground";

          const cellBonusPoints =
            prizeRoundPoints && smiley_space_point_multiplier
              ? parseFloat(smiley_space_point_multiplier) * prizeRoundPoints
              : prizeRoundPoints;

          return (
            <Box
              key={space_id}
              w="full"
              h="full"
              top="0"
              left="0"
              position="absolute"
            >
              <Box
                w="full"
                h="full"
                top="0"
                left="0"
                position="absolute"
                borderWidth={pxToRem(currentBreakpoints.borderWidth)}
                borderStyle="solid"
                transition="all 150ms ease-in-out"
                borderLeftColor={leftHighLightedBorderColor}
                borderRightColor={rightHighlightedBorderColor}
                borderTopColor={
                  isHighlighted ? "primary.golden-hover" : "primary.tan"
                }
                borderBottomColor={
                  isHighlighted ? "primary.golden-hover" : "primary.tan"
                }
                bg={cellBackgroundColor}
              >
                <BoardCell
                  cellSize={calculatedCellSize}
                  placementDisabled={placement_disabled}
                  placements={placements}
                  hideEmptyPlacements={hideEmptyPlacements}
                  showSpecialSpaces={showSpecialSpaces}
                  spaceId={space_id}
                  spaceType={space_type}
                  prizeRoundPoints={cellBonusPoints}
                  onPlaceToken={() => onPlaceToken && onPlaceToken(space_id)}
                />
              </Box>
            </Box>
          );
        }
      );
    });
  }, [
    rows,
    allowDrop,
    showSpecialSpaces,
    isSpinning,
    onPlaceToken,
    calculatedCellSize,
    currentBreakpoints.borderWidth,
    hideEmptyPlacements,
    reachedTargetRow,
    prizeRoundPoints,
  ]);

  const getBoardCell = ({
    rowIndex,
    colIndex,
    content,
  }: {
    rowIndex: number;
    colIndex: number;
    content: React.ReactNode;
  }) => (
    <Box
      key={`bigboard-${rowIndex}-${colIndex}`}
      boxSize={calculatedCellSizeRem}
      boxSizing="border-box"
      bg="primary.white"
      position="relative"
    >
      {content}
    </Box>
  );

  return (
    <Box
      boxSize="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      ref={boardContainerRef}
      padding={pxToRem(currentBreakpoints.padding)}
    >
      <BorderLights allLit={allLightsLit}>
        <Box
          boxSize="100%"
          borderColor="primary.tan"
          borderStyle="solid"
          boxSizing="border-box"
          borderWidth={pxToRem(currentBreakpoints.borderWidth)}
          bg="primary.tan"
        >
          {boardContent.map((row, rowIndex) => {
            return (
              <HStack
                align="center"
                direction="row"
                spacing="0"
                key={rowIndex}
                justifyContent="center"
              >
                {row.map((content, colIndex) => {
                  return getBoardCell({ rowIndex, colIndex, content });
                })}
              </HStack>
            );
          })}
        </Box>
      </BorderLights>
    </Box>
  );
};
