import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { useAxios } from "../../hooks/useAxios";

export interface IDeleteUserTrialExtensionParams {
  id: string;
}

export function useDeleteUserTrialExtension(): UseMutationResult<
  Record<string, unknown>,
  unknown,
  IDeleteUserTrialExtensionParams
> {
  const axios = useAxios();
  const queryClient = useQueryClient();

  const deleteUserTrialExtension = async (
    args: IDeleteUserTrialExtensionParams
  ) => {
    const { id } = args;

    const response = await axios({
      method: "delete",
      url: `/v1/subscriptions/users/extensions/${id}`,
    }).then((r) => r.data as Record<string, unknown>);

    queryClient.invalidateQueries(["user-trial-extensions"]);

    return response;
  };

  const mutation = useMutation(deleteUserTrialExtension);

  return mutation;
}
