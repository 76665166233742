import { pxToRem } from "adminComponents/utils/pxToRem";

export const Form = {
  variants: {
    adminFormControl: {
      requiredIndicator: {
        color: "primary.dark-gray",
        marginLeft: 0,
      },
    },
    adminLatexFormControl: {
      container: {
        mb: pxToRem(24),
        ".mq-editable-field": {
          borderColor: "primary.medium-gray",
          px: pxToRem(16),
          py: pxToRem(14),
          borderRadius: "md",
          "&.mq-focused": {
            boxShadow: "none",
            borderColor: "primary.golden",
          },
        },

        ".mq-root-block": {
          p: pxToRem(10),
        },
      },
    },
  },
};
