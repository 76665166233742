import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import {
  IAlternateTextResponse,
  IPracticeSetItem,
  QuestionType,
} from "../../types";

export interface IMutateTextResponseArgs {
  id?: string;
  text_response_id?: string;
  practice_set_id: string;
  prompt: string;
  correct_response: string;
  enable_partial_credit: boolean;
  feedback: string;
  feedback_image_url?: string;
  feedback_image_alt_text?: string;
  order?: number;
  is_higher_order_thinking: boolean;
  audio_url?: string;
  video_url?: string;
  image_url?: string;
  image_alt_text?: string;
  standards: Array<{ id: string }>;
  alternate_text_responses: Array<IAlternateTextResponse>;
  cnc_code?: string;
  skill_id?: string;
  is_certified: boolean;
  is_premium: boolean;
}

interface IMutateTextResponseResponse {
  practice_set_item: IPracticeSetItem;
}

export interface IUseMutateTextResponseProps {
  onSuccess?: (data: IMutateTextResponseResponse) => void;
  onError?: () => void;
  onSettled?: () => void;
}

export default function useMutateTextResponse(
  props?: IUseMutateTextResponseProps
): UseMutationResult<
  IMutateTextResponseResponse,
  unknown,
  IMutateTextResponseArgs
> {
  const axios = useAxios();
  const queryClient = useQueryClient();

  const mutateTextResponse = async (args: IMutateTextResponseArgs) => {
    const {
      id,
      practice_set_id,
      prompt,
      correct_response,
      enable_partial_credit,
      feedback,
      feedback_image_url,
      feedback_image_alt_text,
      is_higher_order_thinking,
      text_response_id,
      audio_url,
      video_url,
      image_url,
      image_alt_text,
      standards,
      alternate_text_responses,
      cnc_code,
      skill_id,
      is_certified,
      is_premium,
    } = args;

    let { order } = args;

    // if create, then determine order at end of items list
    if (!id) {
      order = await axios
        .get(`/v1/practice-sets/${practice_set_id}/items`)
        .then((r) => {
          let maxOrder = 0;

          (
            r.data as { practice_set_items: Array<IPracticeSetItem> }
          )?.practice_set_items?.forEach(
            (i) => (maxOrder = Math.max(i.order, maxOrder))
          );

          return maxOrder + 1;
        });
    } else {
      order = order || 1;
    }

    const response = await axios({
      method: id ? "put" : "post",
      url: `/v1/practice-sets/${practice_set_id}/items${id ? "/" + id : ""}`,
      data: {
        practice_set_item: {
          text_response: {
            id: text_response_id,
            prompt,
            correct_response,
            enable_partial_credit,
          },
          question_type: QuestionType.TextResponse,
          feedback,
          feedback_image_url,
          feedback_image_alt_text,
          order,
          audio_url,
          video_url,
          image_url,
          image_alt_text,
          is_higher_order_thinking,
          standards,
          alternate_text_responses: alternate_text_responses.filter(
            (alternativeTextResponse) =>
              !!alternativeTextResponse.alternate_text.trim()
          ),
          cnc_code,
          skill_id,
          is_certified,
          is_premium,
        },
      },
    }).then((r) => r.data as IMutateTextResponseResponse);

    queryClient.invalidateQueries(["practiceSets", { id: practice_set_id }]);
    queryClient.invalidateQueries(["practiceSetItems", { practice_set_id }]);
    queryClient.setQueryData(
      ["practiceSetItems", { id: response.practice_set_item.id }],
      {
        practice_set_item: response.practice_set_item,
      }
    );

    return response;
  };

  const mutation = useMutation(mutateTextResponse, {
    onError: props?.onError,
    onSuccess: props?.onSuccess,
    onSettled: props?.onSettled,
  });

  return {
    ...mutation,
  };
}
