import { QuestionType } from "links/lib/types";

const MUTIPLE_CHOICE_QUESTION_TYPES = [
  QuestionType.MultipleChoice,
  QuestionType.MultipleSelect,
];

const TEXT_OR_NUMBER_QUESTION_TYPES = [
  QuestionType.TextResponse,
  QuestionType.NumberResponse,
];

const USES_PLAIN_TEXT_ANSWERS = [
  ...TEXT_OR_NUMBER_QUESTION_TYPES,
  QuestionType.Diagram,
];

const USES_IMAGE_ANSWERS = [
  QuestionType.MultipleChoice,
  QuestionType.MultipleSelect,
  QuestionType.Classify,
  QuestionType.Draw,
];

export const isMultipleQuestionType = (
  questionType?: QuestionType
): boolean => {
  if (!questionType) {
    return false;
  }
  return MUTIPLE_CHOICE_QUESTION_TYPES.indexOf(questionType) > -1;
};

export const isTextOrNumberQuestionType = (
  questionType?: QuestionType
): boolean => {
  if (!questionType) {
    return false;
  }
  return TEXT_OR_NUMBER_QUESTION_TYPES.indexOf(questionType) > -1;
};

export const isPlainTextAnswerQuestionType = (
  questionType?: QuestionType
): boolean => {
  if (!questionType) {
    return false;
  }
  return USES_PLAIN_TEXT_ANSWERS.indexOf(questionType) > -1;
};

export const isImageChoicePossibleQuestionType = (
  questionType?: QuestionType
): boolean => {
  if (!questionType) {
    return false;
  }
  return USES_IMAGE_ANSWERS.indexOf(questionType) > -1;
};
