import { useMemo } from "react";

import { useSessionConnectedUsers } from "links/lib/contexts/sessionConnectedUsers";
import { ISessionUser } from "links/lib/types";

export const useSafeConnectedUserArray = (): ISessionUser[] => {
  const users = useSessionConnectedUsers();

  const safeUsers = useMemo(
    () => Object.values(users).filter((u) => !!u) as ISessionUser[],
    [users]
  );

  return safeUsers;
};
