import { pxToRem } from "adminComponents/utils/pxToRem";

export const AdminGreatWorkCTA = {
  baseStyle: {
    content: {
      maxWidth: pxToRem(300),
    },
    title: {
      paddingRight: pxToRem(20),
      marginBottom: { base: pxToRem(16), md: pxToRem(24) },
    },
    icon: {
      width: "100%",
      maxWidth: { base: pxToRem(150), md: pxToRem(170) },
    },
  },
};
