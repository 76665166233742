import { Box } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Card } from "adminComponents/atoms/Card";
import { Checkbox } from "adminComponents/atoms/Checkbox";
import { ExpandableText } from "adminComponents/atoms/ExpandableText";
import { Heading } from "adminComponents/atoms/Heading";
import { Icon } from "adminComponents/atoms/Icon";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { ISkill } from "links/lib/types";

interface IProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  skill: ISkill;
}

export const SkillsPickerCard: React.FC<IProps> = ({
  checked,
  onChange,
  skill,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  return (
    <Card
      variant="adminCardSolid"
      backgroundColor="primary.warm-white"
      borderColor="primary.warm-white"
    >
      <Box display="flex" alignItems="flex-start">
        <Checkbox
          value={skill.id}
          isChecked={checked}
          onChange={(e) => onChange(e.target.checked)}
        />
        <Box marginLeft={pxToRem(12)}>
          <Box
            color="primary.warm-black"
            display="flex"
            alignItems="center"
            gap={pxToRem(8)}
            marginBottom={pxToRem(8)}
          >
            <Heading as="p" variant="adminH7">
              {skill.code}
            </Heading>
          </Box>
          <ExpandableText
            noOfLines={2}
            textVariant="adminP2"
            buttonVariant="adminTextButtonLargeNoUnderline"
            buttonMarginTop={pxToRem(20)}
            ctaForShowMore={
              <Box display="flex" alignItems="center">
                {t("createQuestion.standardsExpand")}
                <Icon icon="keyboard_arrow_down" iconColor="inherit" />
              </Box>
            }
            ctaForShowLess={
              <Box display="flex" alignItems="center">
                {t("createQuestion.standardsCollapse")}
                <Icon icon="keyboard_arrow_up" iconColor="inherit" />
              </Box>
            }
          >
            {skill.description}
          </ExpandableText>
        </Box>
      </Box>
    </Card>
  );
};
