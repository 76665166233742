import {
  Box,
  Flex,
  FormControl,
  RadioGroup,
  SimpleGrid,
  Stack,
} from "@chakra-ui/react";
import { chakraComponents } from "chakra-react-select";
import { useFormik } from "formik";
import moment, { Moment } from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { usePrevious } from "react-use";

import { Button } from "adminComponents/atoms/Button";
import { Checkbox } from "adminComponents/atoms/Checkbox";
import { DatePicker } from "adminComponents/atoms/DatePicker";
import { Dropdown, IOption } from "adminComponents/atoms/Dropdown";
import { FormErrorMessage } from "adminComponents/atoms/FormErrorMessage";
import { FormLabel } from "adminComponents/atoms/FormLabel";
import { IconTooltip } from "adminComponents/atoms/IconTooltip";
import { PremiumTooltipRich } from "adminComponents/atoms/PremiumTooltipRich";
import { Radio } from "adminComponents/atoms/Radio";
import { Tag } from "adminComponents/atoms/Tag";
import { Text } from "adminComponents/atoms/Text";
import { TextLink } from "adminComponents/atoms/TextLink";
import { Textarea } from "adminComponents/atoms/Textarea";
import { AdminFlyout } from "adminComponents/molecules/Flyout";
import { SliderInput } from "adminComponents/molecules/SliderInput";
import { getExcludedStudents } from "adminComponents/utils/getExcludedStudents";
import { isPremiumItem } from "adminComponents/utils/isPremiumItem";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { useAuth } from "links/lib/features/auth";
import { useFetchPracticeSetItems } from "links/lib/features/practiceSetItems";
import {
  GoogleClassroomClassroom,
  IAssignment,
  IClassroom,
  IPracticeSet,
  IUser,
  ThirdParty,
  UsersGroupsRole,
} from "links/lib/types";

import {
  ClassDifferences,
  StudentEnrollmentDifferencesModal,
} from "../StudentEnrollmentDifferencesModal";

const MAX_GOOGLE_CLASSROOM_ASIGNEES = 100;

const customClassroomOptionComponents = {
  Option: ({ children, ...props }: { children: React.ReactNode }) => {
    const optionProps = props as { data: IOption };
    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <chakraComponents.Option {...props}>
        {children}
        {optionProps.data.tooltip && (
          <Box ml={pxToRem(8)}>
            <IconTooltip usePortal>{optionProps.data.tooltip}</IconTooltip>
          </Box>
        )}
      </chakraComponents.Option>
    );
  },
};

interface IForm {
  accuracyGoal: number;
  assignmentId: string;
  classroomIds: string[];
  customStartDate: string;
  description: string;
  dueDate: Date;
  dueHour: string;
  dueTimeOfDay: string;
  practiceSet: string;
  selectedStudentIds?: string[];
  startDate: Date;
  startHour: string;
  startTimeOfDay: string;
  assignToGoogleClassroom: boolean;
  randomizeItemOrder: boolean;
}

type FormFieldKey = keyof IForm;

export interface FormDataProps {
  form: IForm;
}

interface IProps {
  accuracyGoals: number[];
  assignment?: IAssignment;
  classrooms: IClassroom[];
  handleClose: () => void;
  handleClassroomSelection?: (classroom: IClassroom) => void;
  handleStudentSelection?: (classroomId: string) => void;
  handleSubmit: (formData: FormDataProps) => void;
  isLoading?: boolean;
  isOpen: boolean;
  practiceSets: IPracticeSet[];
  selectedStudentIds?: string[];
  timeList: { [key: string]: string };
  timeOfDay: { [key: string]: string };
  lockToClassroom?: IClassroom;
  lockToPracticeSet?: IPracticeSet;
  toggleAssignInThirdParty?: (assignInThirdParty: boolean) => void;
  requiresThirdPartyAuth?: boolean;
  googleClassrooms?: GoogleClassroomClassroom[];
  classroomStudentsMap?: { [key: string]: Array<IUser> };
  initialSelectedClassroom?: IClassroom;
}

export const dateDataToMoment = (d: Date, h: string, a: string): Moment => {
  const dateString = `${
    d.getMonth() + 1
  }-${d.getDate()}-${d.getFullYear()} ${h}:00 ${a}`;

  return moment(dateString, "M-D-YYYY h:mm a");
};

export const AssignmentFlyout: React.FC<IProps> = ({
  accuracyGoals,
  assignment,
  classrooms,
  handleClassroomSelection,
  handleStudentSelection,
  isLoading,
  isOpen,
  handleClose,
  handleSubmit,
  practiceSets,
  selectedStudentIds,
  timeList,
  timeOfDay,
  lockToClassroom,
  lockToPracticeSet,
  toggleAssignInThirdParty,
  requiresThirdPartyAuth = false,
  googleClassrooms,
  classroomStudentsMap,
  initialSelectedClassroom,
}) => {
  const history = useHistory();
  const { authUser, hasNoPremiumAccess } = useAuth();
  const { t } = useTranslation("admin", { useSuspense: false });

  const [
    isStudentEnrollmentDifferencesModalOpen,
    setIsStudentEnrollmentDifferencesModalOpen,
  ] = useState<boolean>(false);

  // convert to dropdown usable array
  const timeListOptions = Object.entries(timeList).map(([key, value]) => ({
    label: value,
    value: key,
  }));

  // convert to dropdown usable array
  const timeOfDayOptions = Object.entries(timeOfDay).map(([key, value]) => ({
    label: value,
    value: key,
  }));

  // convert to dropdown usable array
  const practiceSetOptions = practiceSets.map(({ id, title }) => ({
    label: title,
    value: id,
  }));

  // convert to dropdown usable array
  const classroomOptions = classrooms.map(({ id, name, students_count }) => ({
    label: name,
    value: id,
    isDisabled: students_count === 0,
    tooltip:
      students_count === 0 ? (
        <Trans i18nKey="createAssignment.noClassroomStudentsErrorLink" t={t}>
          <Link
            to={`/t/classrooms/${id}/students`}
            color="utility.link"
            style={{ textDecoration: "underline" }}
          >
            here
          </Link>
        </Trans>
      ) : undefined,
  }));

  // store initial selected class if pre-populated
  const getSelectedClassroom = useCallback(() => {
    if (lockToClassroom) return lockToClassroom;

    if (!assignment) {
      return initialSelectedClassroom || null;
    }

    const selectedClass = classrooms.find((c) => c.id === assignment.group_id);
    return selectedClass ? selectedClass : null;
  }, [assignment, classrooms, lockToClassroom, initialSelectedClassroom]);

  const getSelectedPracticeSetId = useCallback(() => {
    if (lockToPracticeSet) return lockToPracticeSet.id;
    if (!assignment) return null;
    return assignment.practice_set_id;
  }, [assignment, lockToPracticeSet]);

  const selectedPracticeSet = useMemo(() => {
    if (!lockToPracticeSet) return null;
    return practiceSets.find(
      (practiceSet) => practiceSet.id === lockToPracticeSet.id
    );
  }, [practiceSets, lockToPracticeSet]);

  // prepare initial data to be prepopulated by any incoming data
  const getFormData = useCallback((): IForm => {
    const now = new Date();

    // start date vars
    const startNow = !assignment?.starts_at;
    const startDate = startNow ? now : new Date(assignment.starts_at);
    const startHour = startDate.getHours() % 12 || 12;
    const startTimeOfDay = startDate.getHours() >= 12 ? "pm" : "am";

    // due date vars
    const dueDate = assignment?.ends_at
      ? new Date(assignment.ends_at)
      : moment().add(1, "d").toDate();
    const dueHour = dueDate.getHours() % 12 || 12;
    const dueTimeOfDay = dueDate.getHours() >= 12 ? "pm" : "am";

    const selectedClassroom = getSelectedClassroom();

    return {
      accuracyGoal: assignment?.required_score_percent || 0,
      assignmentId: assignment?.id || "",
      classroomIds: selectedClassroom ? [selectedClassroom.id] : [],
      customStartDate: startNow ? "now" : "custom",
      description: assignment?.description || "",
      dueDate: dueDate,
      dueHour: String(dueHour),
      dueTimeOfDay,
      practiceSet: getSelectedPracticeSetId() || "",
      selectedStudentIds,
      startDate: startDate,
      startHour: String(startHour),
      startTimeOfDay,
      assignToGoogleClassroom:
        assignment?.third_party === ThirdParty.GOOGLE_CLASSROOM || false,
      randomizeItemOrder: assignment ? assignment.randomize_item_order : true,
    };
  }, [
    selectedStudentIds,
    getSelectedClassroom,
    getSelectedPracticeSetId,
    assignment,
  ]);

  // initialize working form controlled local state
  const [formData, setFormData] = useState<IForm>(getFormData());

  // check if this was already open
  const lastIsOpen = usePrevious(isOpen);

  // setup formik for form validation code
  const formik = useFormik<IForm>({
    enableReinitialize: true,
    initialValues: formData,
    validateOnMount: true,
    onSubmit: (values) => {
      handleSubmit({ form: values });
    },
    validate: (values) => {
      const errors = {} as Record<FormFieldKey, string>;

      if (!values.practiceSet) {
        errors.practiceSet = t("common.formFieldError", {
          fieldName: t("createAssignment.practiceSet"),
        });
      }

      if (practiceSetItemsFetch.data) {
        if (practiceSetItemsFetch.data.practice_set_items.length === 0) {
          errors.practiceSet = t("createAssignment.practiceSetItemCountError");
        } else if (
          practiceSetItemsFetch.data.practice_set_items.every(isPremiumItem) &&
          hasNoPremiumAccess
        ) {
          errors.practiceSet = t(
            "createAssignment.practiceSetPremiumItemCountError"
          );
        }
      }

      if (
        values.customStartDate === "custom" &&
        (!values.startDate || !values.startHour || !values.startTimeOfDay)
      ) {
        errors.startDate = t("common.formFieldError", {
          fieldName: t("createAssignment.startDate"),
        });
      }

      if (!values.dueDate || !values.dueHour || !values.dueTimeOfDay) {
        errors.dueDate = t("common.formFieldError", {
          fieldName: t("createAssignment.dueDate"),
        });
      } else {
        // ensure the due date is after the start date
        const dueMoment = dateDataToMoment(
          values.dueDate,
          values.dueHour,
          values.dueTimeOfDay
        );

        let startMoment = moment();
        if (values.customStartDate === "custom") {
          startMoment = dateDataToMoment(
            values.startDate,
            values.startHour,
            values.startTimeOfDay
          );
        }

        const isBeforeNow = dueMoment.isBefore(moment());
        const isBeforeStart = startMoment.isSameOrAfter(dueMoment);

        if (isBeforeNow || isBeforeStart) {
          errors.dueDate = isBeforeNow
            ? t("createAssignment.dueDateBeforeNowError")
            : t("createAssignment.dueDateBeforeStartError");

          errors.dueHour = t("common.formFieldError", {
            fieldName: t("createAssignment.dueHour"),
          });
          errors.dueTimeOfDay = t("common.formFieldError", {
            fieldName: t("createAssignment.dueTimeOfDay"),
          });
        }
      }

      if (!values.accuracyGoal) {
        errors.accuracyGoal = t("common.formFieldError", {
          fieldName: t("createAssignment.accuracyGoal"),
        });
      } else if (Math.ceil(values.accuracyGoal) <= 0) {
        errors.accuracyGoal = t("createAssignment.accuracyGoalMinError");
      } else if (Math.ceil(values.accuracyGoal) > 100) {
        errors.accuracyGoal = t("createAssignment.accuracyGoalMaxError");
      }

      if (!values.classroomIds.length) {
        errors.classroomIds = t("createAssignment.noClassroomsError");
      }

      if (
        selectedClassroom &&
        classrooms.find(
          (classroom) =>
            classroom.id === selectedClassroom.id &&
            classroom.students_count < 1
        )
      ) {
        errors.classroomIds = t("createAssignment.noClassroomStudentsError1");
      }

      if (formik.values.assignToGoogleClassroom && requiresThirdPartyAuth) {
        errors.assignToGoogleClassroom = t(
          "createAssignment.googleClassroomAuthError"
        );
      }

      return errors;
    },
  });

  const classesWithMismatchedStudents = useMemo(() => {
    if (
      !formik.values.assignToGoogleClassroom ||
      formik.values.classroomIds.length < 1
    ) {
      return [];
    }

    // Single classroom can be assigned
    if (
      lockToClassroom &&
      lockToClassroom.third_party === ThirdParty.GOOGLE_CLASSROOM &&
      lockToClassroom.third_party_id
    ) {
      const gClassroom = googleClassrooms?.find(
        (gClassroom) => gClassroom.id === lockToClassroom.third_party_id
      );

      const excludedStudents = getExcludedStudents({
        gClassroom,
        classroomStudents: classroomStudentsMap?.[lockToClassroom.id],
      });

      if (excludedStudents.length > 0) {
        return [
          {
            googleClassroomClassName: gClassroom?.name || "",
            giantStepsClassName: lockToClassroom.name,
            excludedStudents: getExcludedStudents({
              gClassroom,
              classroomStudents: classroomStudentsMap?.[lockToClassroom.id],
            }),
          },
        ];
      } else {
        return [];
      }
    }

    // multiple classrooms can be assigned
    if (formik.values.classroomIds) {
      const classesWithMismatchedStudents: ClassDifferences[] = [];

      formik.values.classroomIds.forEach((classId) => {
        const classroom = classrooms.find((c) => c.id === classId);
        if (
          classroom &&
          classroom.third_party === ThirdParty.GOOGLE_CLASSROOM &&
          classroom.third_party_id
        ) {
          const gClassroom = googleClassrooms?.find(
            (gClassroom) => gClassroom.id === classroom.third_party_id
          );

          const excludedStudents = getExcludedStudents({
            gClassroom,
            classroomStudents: classroomStudentsMap?.[classroom.id],
          });

          if (excludedStudents.length > 0) {
            classesWithMismatchedStudents.push({
              googleClassroomClassName: gClassroom?.name || "",
              giantStepsClassName: classroom.name,
              excludedStudents: getExcludedStudents({
                gClassroom,
                classroomStudents: classroomStudentsMap?.[classroom.id],
              }),
            });
          }
        }
      });
      return classesWithMismatchedStudents;
    }

    return [];
  }, [
    formik.values.assignToGoogleClassroom,
    lockToClassroom,
    googleClassrooms,
    classroomStudentsMap,
    formik.values.classroomIds,
    classrooms,
  ]);

  // utility formik handlers to support our custom dropdown and classroom handlers
  const handleFieldChange = useCallback(
    (value: unknown, key: FormFieldKey) => {
      formik.setFieldTouched(key).finally(() => {
        formik.setFieldValue(key, value);
      });
    },
    [formik]
  );

  const handleFieldBlur = useCallback(
    (key: FormFieldKey) => {
      formik.setFieldTouched(key).finally(() => {
        formik.handleBlur(key);
      });
    },
    [formik]
  );

  // due to dropdown array shapes not aligning with the flatter structure of formik
  // we need to use our own change and blur handlers to get and put the right data in
  const handleDropdownChange = useCallback(
    (newValue: IOption, key: FormFieldKey) =>
      handleFieldChange(newValue.value, key),
    [handleFieldChange]
  );
  const handleMultiDropdownChange = useCallback(
    (newValues: IOption[], key: FormFieldKey) => {
      handleFieldChange(
        newValues.map((v) => v.value),
        key
      );
    },
    [handleFieldChange]
  );
  const handleDropdownBlur = useCallback(
    (key: FormFieldKey) => handleFieldBlur(key),
    [handleFieldBlur]
  );

  // we need to track the selected classroom(s) so our student selection modal
  // has all the right data to use
  const handleClassroomsChange = useCallback(
    (newValues: IOption[]) => {
      handleMultiDropdownChange(newValues, "classroomIds");

      if (newValues.length === 1) {
        const classroom = classrooms.find((c) => c.id === newValues[0].value);

        if (classroom && handleClassroomSelection) {
          handleClassroomSelection(classroom);
        }
      }
    },
    [classrooms, handleClassroomSelection, handleMultiDropdownChange]
  );

  // updates form initial values on open/re-open
  useEffect(() => {
    if (!lastIsOpen && isOpen) {
      setFormData(getFormData());
      formik.handleReset(null);
    }
  }, [getFormData, isOpen, lastIsOpen, formik]);

  const isSharedSet =
    !!selectedPracticeSet && selectedPracticeSet.created_by !== authUser?.id;

  const linkedGoogleClassroomSelected = useMemo(() => {
    if (lockToClassroom?.third_party === ThirdParty.GOOGLE_CLASSROOM) {
      return true;
    }

    const classroomThirdPartyMap = new Map<string, string>();

    classrooms.forEach((classroom) => {
      classroomThirdPartyMap.set(classroom.id, classroom.third_party);
    });

    return formik.values.classroomIds.some(
      (id) => classroomThirdPartyMap.get(id) === ThirdParty.GOOGLE_CLASSROOM
    );
  }, [formik.values.classroomIds, lockToClassroom?.third_party, classrooms]);

  // make sure we handle case correctly where a gclassroom linked classroom is deselected
  useEffect(() => {
    if (
      !linkedGoogleClassroomSelected &&
      formik.values.assignToGoogleClassroom
    ) {
      formik.setFieldValue("assignToGoogleClassroom", false);
    }
  }, [
    linkedGoogleClassroomSelected,
    formik.values.assignToGoogleClassroom,
    handleFieldChange,
    formik,
  ]);

  const handleStudentEnrollmentDifferencesModalClose = useCallback(() => {
    setIsStudentEnrollmentDifferencesModalOpen(false);
  }, []);

  const studentEnrollmentDifferencesModal = useMemo(() => {
    return (
      <StudentEnrollmentDifferencesModal
        classes={classesWithMismatchedStudents}
        handleClose={handleStudentEnrollmentDifferencesModalClose}
        isOpen={isStudentEnrollmentDifferencesModalOpen}
      />
    );
  }, [
    classesWithMismatchedStudents,
    handleStudentEnrollmentDifferencesModalClose,
    isStudentEnrollmentDifferencesModalOpen,
  ]);

  const practiceSetItemsFetch = useFetchPracticeSetItems({
    practice_set_id: formik.values.practiceSet,
  });
  const disallowCreateAllPremiumItems =
    practiceSetItemsFetch.data?.practice_set_items?.every(isPremiumItem) &&
    hasNoPremiumAccess;

  const selectedClassroom = useMemo(() => {
    return formik.values.classroomIds.length === 1
      ? classrooms.find((c) => c.id === formik.values.classroomIds[0])
      : undefined;
  }, [classrooms, formik.values.classroomIds]);

  const tooManyStudentsForGoogleClassroom = useMemo(() => {
    // if single classroom and selected students
    const selectedStudentsLength = selectedStudentIds?.length || 0;
    if (selectedStudentsLength > MAX_GOOGLE_CLASSROOM_ASIGNEES) {
      return true;
    } else if (selectedStudentsLength > 0) {
      return false;
    }

    // if locked to classroom and no selected students
    if (
      lockToClassroom &&
      lockToClassroom.students_count > MAX_GOOGLE_CLASSROOM_ASIGNEES
    ) {
      return true;
    }

    // if multiple classrooms selected
    let tooManyStudentsForGoogleClassroom = false;
    if (formik.values.classroomIds.length > 0) {
      formik.values.classroomIds.forEach((classId) => {
        const classroom = classrooms.find((c) => c.id === classId);
        const classroomStudentsCount = classroom?.students_count || 0;
        if (classroomStudentsCount > MAX_GOOGLE_CLASSROOM_ASIGNEES) {
          tooManyStudentsForGoogleClassroom = true;
        }
      });
    }

    return tooManyStudentsForGoogleClassroom;
  }, [
    selectedStudentIds,
    formik.values.classroomIds,
    classrooms,
    lockToClassroom,
  ]);

  useEffect(() => {
    if (
      tooManyStudentsForGoogleClassroom &&
      formik.values.assignToGoogleClassroom
    ) {
      formik.setFieldValue("assignToGoogleClassroom", false);
    }
  }, [tooManyStudentsForGoogleClassroom, formik]);

  return (
    <AdminFlyout
      isOpen={isOpen}
      onClose={handleClose}
      title={
        assignment
          ? t("createAssignment.buttonEditAssignment")
          : t("createAssignment.title")
      }
    >
      <Box
        pb={[pxToRem(15), null, pxToRem(58)]}
        px={[
          "admin.flyout.mobileXPadding",
          null,
          "admin.flyout.desktopXPadding",
        ]}
      >
        <Box
          display="flex"
          flexDirection="column"
          gap={[pxToRem(32), null, pxToRem(40)]}
        >
          <FormControl
            isDisabled={isLoading}
            isInvalid={
              !!formik.errors.practiceSet && formik.touched.practiceSet
            }
            isRequired
            variant="adminFormControl"
          >
            <FormLabel
              htmlFor="createAssignment.practiceSet"
              id="createAssignment.practiceSet-label"
            >
              {t("createAssignment.practiceSet")}
            </FormLabel>
            <Dropdown
              id="createAssignment.practiceSet"
              aria-labelled-by="createAssignment.practiceSet-label"
              handleBlur={() => handleDropdownBlur("practiceSet")}
              handleChange={(v) =>
                handleDropdownChange(v as IOption, "practiceSet")
              }
              options={practiceSetOptions}
              placeholder={t("createAssignment.practiceSetPlaceholder")}
              isDisabled={!!assignment} // Don't permit changing set on existing assignment
              value={practiceSetOptions.find(
                (p) => p.value === formik.values.practiceSet
              )}
            />
            <FormErrorMessage>{formik.errors.practiceSet}</FormErrorMessage>
          </FormControl>
          <FormControl
            isDisabled={isLoading}
            isInvalid={!!formik.errors.description}
            variant="adminFormControl"
          >
            <FormLabel
              htmlFor="createAssignment.description"
              id="createAssignment.description-label"
            >
              {t("createAssignment.description")}
            </FormLabel>
            <Textarea
              id="createAssignment.description"
              aria-labelledby="createAssignment.description-label"
              onBlur={formik.handleBlur}
              onChange={(v) => handleFieldChange(v.target.value, "description")}
              placeholder={t("createAssignment.descriptionPlaceholder")}
              rows={4}
              value={formik.values.description}
            />
          </FormControl>
          <FormControl isRequired variant="adminFormControl">
            <FormLabel
              htmlFor="createAssignment.customStartDate"
              id="createAssignment.customStartDate-label"
            >
              {t("createAssignment.startDate")}
            </FormLabel>
            <RadioGroup
              id="createAssignment.customStartDate"
              aria-labelledby="createAssignment.customStartDate-label"
              onBlur={formik.handleBlur}
              onChange={(v) => handleFieldChange(v, "customStartDate")}
              value={formik.values.customStartDate}
            >
              <Stack direction="row" spacing={5}>
                <Radio value="now">{t("createAssignment.now")}</Radio>
                <Radio value="custom">{t("createAssignment.customDate")}</Radio>
              </Stack>
            </RadioGroup>
            {formik.values.customStartDate === "custom" && (
              <>
                <SimpleGrid
                  gap={pxToRem(12)}
                  gridTemplateColumns={{ base: "1fr 1fr", md: "2fr 1fr 1fr" }}
                  mt={4}
                >
                  <Box gridColumn={{ base: "span 2", md: "auto" }}>
                    <FormControl
                      isDisabled={isLoading}
                      isInvalid={!!formik.errors.startDate}
                      variant="adminFormControl"
                      isRequired
                    >
                      <FormLabel
                        htmlFor="createAssignment.startDate"
                        id="createAssignment.startDate-label"
                      >
                        {t("createAssignment.date")}
                      </FormLabel>
                      <DatePicker
                        id="createAssignment.startDate"
                        ariaLabelledBy="createAssignment.startDate-label"
                        onBlur={formik.handleBlur}
                        onChange={(v) => handleFieldChange(v, "startDate")}
                        selected={formik.values.startDate}
                      />
                    </FormControl>
                  </Box>
                  <FormControl
                    isDisabled={isLoading}
                    isInvalid={!!formik.errors.startHour}
                    variant="adminFormControl"
                    isRequired
                  >
                    <FormLabel
                      htmlFor="createAssignment.startHour"
                      id="createAssignment.startHour-label"
                    >
                      {t("createAssignment.hour")}
                    </FormLabel>
                    <Dropdown
                      id="createAssignment.startHour"
                      aria-labelled-by="createAssignment.startHour-label"
                      handleBlur={() => handleDropdownBlur("startHour")}
                      handleChange={(v) =>
                        handleDropdownChange(v as IOption, "startHour")
                      }
                      options={timeListOptions}
                      value={timeListOptions.filter(
                        (h) => h.value === formik.values.startHour
                      )}
                    />
                  </FormControl>
                  <FormControl
                    isDisabled={isLoading}
                    isInvalid={!!formik.errors.startTimeOfDay}
                    variant="adminFormControl"
                    isRequired
                  >
                    <FormLabel
                      htmlFor="createAssignment.startTimeOfDay"
                      id="createAssignment.startTimeOfDay-label"
                    >
                      {t("createAssignment.time")}
                    </FormLabel>
                    <Dropdown
                      id="createAssignment.startTimeOfDay"
                      aria-labelled-by="createAssignment.startTimeOfDay-label"
                      handleBlur={() => handleDropdownBlur("startTimeOfDay")}
                      handleChange={(v) =>
                        handleDropdownChange(v as IOption, "startTimeOfDay")
                      }
                      options={timeOfDayOptions}
                      value={timeOfDayOptions.filter(
                        (d) => d.value === formik.values.startTimeOfDay
                      )}
                    />
                  </FormControl>
                </SimpleGrid>
              </>
            )}
            <FormErrorMessage>{formik.errors.startDate}</FormErrorMessage>
          </FormControl>
          <FormControl
            isRequired
            isInvalid={
              !!formik.errors.dueHour ||
              !!formik.errors.dueDate ||
              !!formik.errors.dueTimeOfDay
            }
            variant="adminFormControl"
          >
            <SimpleGrid
              gap={pxToRem(12)}
              gridTemplateColumns={{ base: "1fr 1fr", md: "2fr 1fr 1fr" }}
              mt={4}
            >
              <Box gridColumn={{ base: "span 2", md: "auto" }}>
                <FormControl
                  isDisabled={isLoading}
                  isInvalid={!!formik.errors.dueDate}
                  variant="adminFormControl"
                  isRequired
                >
                  <FormLabel
                    htmlFor="createAssignment.dueDate"
                    id="createAssignment.dueDate-label"
                  >
                    {t("createAssignment.dueDate")}
                  </FormLabel>
                  <DatePicker
                    id="createAssignment.dueDate"
                    ariaLabelledBy="createAssignment.dueDate-label"
                    onBlur={formik.handleBlur}
                    onChange={(v) => handleFieldChange(v, "dueDate")}
                    selected={formik.values.dueDate}
                  />
                </FormControl>
              </Box>
              <FormControl
                isDisabled={isLoading}
                isInvalid={!!formik.errors.dueHour}
                variant="adminFormControl"
                isRequired
              >
                <FormLabel
                  htmlFor="createAssignment.dueHour"
                  id="createAssignment.dueHour-label"
                >
                  {t("createAssignment.hour")}
                </FormLabel>
                <Dropdown
                  id="createAssignment.dueHour"
                  aria-labelled-by="createAssignment.dueHour-label"
                  handleBlur={() => handleDropdownBlur("dueHour")}
                  handleChange={(v) =>
                    handleDropdownChange(v as IOption, "dueHour")
                  }
                  options={timeListOptions}
                  value={timeListOptions.filter(
                    (h) => h.value === formik.values.dueHour
                  )}
                />
              </FormControl>
              <FormControl
                isDisabled={isLoading}
                isInvalid={!!formik.errors.dueTimeOfDay}
                variant="adminFormControl"
                isRequired
              >
                <FormLabel
                  htmlFor="createAssignment.dueTimeOfDay"
                  id="createAssignment.dueTimeOfDay-label"
                >
                  {t("createAssignment.time")}
                </FormLabel>
                <Dropdown
                  id="createAssignment.dueTimeOfDay"
                  aria-labelled-by="createAssignment.dueTimeOfDay-label"
                  handleBlur={() => handleDropdownBlur("dueTimeOfDay")}
                  handleChange={(v) =>
                    handleDropdownChange(v as IOption, "dueTimeOfDay")
                  }
                  options={timeOfDayOptions}
                  value={timeOfDayOptions.filter(
                    (d) => d.value === formik.values.dueTimeOfDay
                  )}
                />
              </FormControl>
            </SimpleGrid>
            <FormErrorMessage>{formik.errors.dueDate}</FormErrorMessage>
          </FormControl>
          <FormControl
            isDisabled={isLoading}
            isInvalid={
              !!formik.errors.accuracyGoal && formik.touched.accuracyGoal
            }
            isRequired
            variant="adminFormControl"
          >
            <Stack direction="row">
              <FormLabel htmlFor="createAssignment.accuracyGoal">
                {t("createAssignment.accuracyGoal")}
              </FormLabel>
              <Box
                __css={{
                  marginInlineStart: "0 !important",
                  marginTop: "1px !important",
                }}
              >
                <IconTooltip>
                  {t("createAssignment.accuracyGoalTooltip")}
                </IconTooltip>
              </Box>
            </Stack>
            <SliderInput
              id="createAssignment.accuracyGoal"
              activeValue={formik.values.accuracyGoal}
              handleBlur={formik.handleBlur}
              handleChange={(v) => handleFieldChange(v, "accuracyGoal")}
              valueSuffixLabel="%"
              values={accuracyGoals}
              minCustomInputValue={1}
              maxCustomInputValue={100}
              maxCustomInputLength={3}
              customInputWholeNumberOnly={true}
            />
            <FormErrorMessage>{formik.errors.accuracyGoal}</FormErrorMessage>
          </FormControl>
          <FormControl
            isDisabled={isLoading}
            isInvalid={
              !!formik.errors.classroomIds && formik.touched.classroomIds
            }
            isRequired
            variant="adminFormControl"
          >
            <FormLabel
              htmlFor="createAssignment.assignToClassroom"
              id="createAssignment.assignToClassroom-label"
            >
              {t("createAssignment.assignToClassroom")}
            </FormLabel>
            <Dropdown
              id="createAssignment.assignToClassroom"
              aria-labelled-by="createAssignment.assignToClassroom-label"
              components={customClassroomOptionComponents}
              handleBlur={() => handleDropdownBlur("classroomIds")}
              handleChange={(vals) =>
                !lockToClassroom
                  ? handleClassroomsChange(vals as IOption[])
                  : undefined
              }
              options={classroomOptions}
              isMulti
              isDisabled={!!lockToClassroom}
              placeholder={t("createAssignment.assignToClassroomPlaceholder")}
              value={classroomOptions.filter((c) =>
                formik.values.classroomIds.includes(c.value)
              )}
            />
            {/* Only show specific student selection UI if one classroom is chosen and it has students */}
            {formik.values.classroomIds.length === 1 &&
              selectedStudentIds &&
              selectedClassroom &&
              selectedClassroom?.students_count > 0 && (
                <Stack direction="row" mt={pxToRem(16)} spacing={pxToRem(20)}>
                  <Tag>
                    {selectedStudentIds.length &&
                    selectedStudentIds.length <
                      selectedClassroom?.students_count
                      ? t("createAssignment.studentsSelected", {
                          count: selectedStudentIds.length,
                        })
                      : `${t("createAssignment.allStudentsSelected")}`}
                  </Tag>
                  <Button
                    onClick={() =>
                      handleStudentSelection?.(formik.values.classroomIds[0])
                    }
                    variant="adminTextButtonMedium"
                  >
                    {t("createAssignment.editSelection")}
                  </Button>
                </Stack>
              )}
            <FormErrorMessage>{formik.errors.classroomIds}</FormErrorMessage>
            {selectedClassroom && selectedClassroom.students_count === 0 && (
              <Box
                color="utility.error"
                display="inline"
                fontSize={pxToRem(12)}
              >
                {t("createAssignment.noClassroomStudentsError1")}
                <Text
                  color="utility.error"
                  cursor="pointer"
                  display="inline"
                  textDecoration="underline"
                  onClick={() =>
                    history.push(
                      `/t/classrooms/${selectedClassroom?.id}/students`
                    )
                  }
                >
                  {selectedClassroom?.users_groups_role ===
                  UsersGroupsRole.Owner
                    ? t("createAssignment.noClassroomStudentsError2")
                    : t("createAssignment.noClassroomStudentsError2Assistant")}
                </Text>
                {t("createAssignment.noClassroomStudentsError3")}
              </Box>
            )}
          </FormControl>

          {selectedClassroom?.created_by === authUser?.id && (
            <FormControl
              isInvalid={
                formik.values.assignToGoogleClassroom && requiresThirdPartyAuth
              }
            >
              <Flex gap={pxToRem(10)}>
                <Checkbox
                  isChecked={formik.values.assignToGoogleClassroom}
                  onChange={(e) => {
                    handleFieldChange(
                      e.target.checked,
                      "assignToGoogleClassroom"
                    );
                    toggleAssignInThirdParty?.(e.target.checked);
                  }}
                  marginBottom={pxToRem(10)}
                  isDisabled={
                    !linkedGoogleClassroomSelected ||
                    tooManyStudentsForGoogleClassroom
                  }
                >
                  <Text>{t("createAssignment.assignToGoogleClassroom")}</Text>
                </Checkbox>
                {!linkedGoogleClassroomSelected && (
                  <Box
                    __css={{
                      marginInlineStart: "0 !important",
                      marginTop: "1px !important",
                    }}
                  >
                    <IconTooltip>
                      {t("createAssignment.connectTooltip")}
                    </IconTooltip>
                  </Box>
                )}
              </Flex>
              <FormErrorMessage>
                {formik.errors.assignToGoogleClassroom}
              </FormErrorMessage>
              {tooManyStudentsForGoogleClassroom && (
                <Box
                  p={pxToRem(10)}
                  marginTop={pxToRem(10)}
                  marginBottom={pxToRem(10)}
                  borderRadius="lg"
                  backgroundColor="primary.light-gray"
                >
                  <Text as="span" variant="adminP2">
                    {t("createAssignment.googleClassroomTooManyStudents")}
                  </Text>
                </Box>
              )}
              {formik.values.assignToGoogleClassroom && (
                <Box
                  p={pxToRem(10)}
                  marginTop={pxToRem(10)}
                  marginBottom={pxToRem(10)}
                  borderRadius="lg"
                  backgroundColor="primary.light-gray"
                >
                  <Text as="span" variant="adminP2">
                    {t("createAssignment.googleClassroomExplainer")}
                  </Text>
                </Box>
              )}
              {classesWithMismatchedStudents.length > 0 && (
                <TextLink
                  handleClick={() =>
                    setIsStudentEnrollmentDifferencesModalOpen(true)
                  }
                  isWarning
                >
                  {t("createAssignment.studentMismatch", {
                    count: classesWithMismatchedStudents
                      .map((c) => c.excludedStudents.length)
                      .reduce((sum, studentCount) => (sum += studentCount)),
                  })}
                </TextLink>
              )}
            </FormControl>
          )}

          <FormControl>
            <Flex gap={pxToRem(10)}>
              <Checkbox
                isChecked={formik.values.randomizeItemOrder}
                onChange={(e) => {
                  handleFieldChange(e.target.checked, "randomizeItemOrder");
                }}
                marginBottom={pxToRem(10)}
              >
                <Text>{t("createAssignment.randomizeItemOrder")}</Text>
              </Checkbox>
            </Flex>
          </FormControl>

          {isSharedSet && (
            <Tag p={pxToRem(24)} variant="adminSolid" w="full">
              {t("createAssignment.practiceSetWillBeCopiedWarning")}
            </Tag>
          )}
          <PremiumTooltipRich
            isDisabled={!disallowCreateAllPremiumItems}
            overrideText={t("createAssignment.premiumTooltipOverrideText")}
          >
            <Box w="full">
              <Button
                disabled={
                  !formik.isValid ||
                  isLoading ||
                  disallowCreateAllPremiumItems ||
                  selectedClassroom?.students_count === 0
                }
                h={pxToRem(56)}
                w="full"
                isLoading={isLoading}
                onClick={() => formik.handleSubmit()}
                size="lg"
                type="submit"
                variant="adminButtonFilled"
              >
                {assignment
                  ? t("createAssignment.buttonEditAssignment")
                  : t("createAssignment.buttonCreateAssignment")}
              </Button>
            </Box>
          </PremiumTooltipRich>
        </Box>
      </Box>
      {studentEnrollmentDifferencesModal}
    </AdminFlyout>
  );
};
