import { useCallback, useMemo } from "react";

import { useAnalytics } from "lib/contexts/analytics";
import { AnalyticsEvent, IAssignment, IClassroom } from "links/lib/types";

import { useCreateAssignment } from "./useCreateAssignment";

interface IUseEditAssignmentArgs {
  classrooms: IClassroom[];
  editIntentAnalyticsEvent: AnalyticsEvent;
  editConfirmAnalyticsEvent: AnalyticsEvent;
}

interface IUseEditAssignmentResult {
  modal: React.ReactElement;
  handleEditAssignment: (assignment: IAssignment) => void;
}

export const useEditAssignment = ({
  classrooms,
  editIntentAnalyticsEvent,
  editConfirmAnalyticsEvent,
}: IUseEditAssignmentArgs): IUseEditAssignmentResult => {
  const { trackEvent } = useAnalytics();

  const onEditConfirm = (assignment?: IAssignment) => {
    if (assignment) {
      trackEvent(editConfirmAnalyticsEvent, {
        classroomId: classrooms[0].id,
        assignmentId: assignment.id,
      });
    }
  };

  const { component: EditAssignmentModal, handleEditIntent } =
    useCreateAssignment({
      classrooms,
      onSubmit: onEditConfirm,
    });

  const handleEditAssignment = useCallback(
    (assignment: IAssignment) => {
      if (classrooms.length) {
        handleEditIntent(assignment, classrooms[0]);

        trackEvent(editIntentAnalyticsEvent, {
          classroomId: classrooms[0].id,
          assignmentId: assignment.id,
        });
      }
    },
    [classrooms, handleEditIntent, editIntentAnalyticsEvent, trackEvent]
  );

  return useMemo(() => {
    return {
      handleEditAssignment,
      modal: EditAssignmentModal,
    };
  }, [handleEditAssignment, EditAssignmentModal]);
};
