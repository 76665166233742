import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { IUserHours } from "../../types";

export interface IFetchGroupAverageWeeklyPracticeHoursArgs {
  classroom_id?: string;
  student_id?: string;
  weeks_to_consider?: number;
}

export interface IFetchGroupAverageWeeklyPracticeHoursResponse {
  average_weekly_user_hours: IUserHours[];
}

export default function useGroupAverageWeeklyPracticeHours(
  params: IFetchGroupAverageWeeklyPracticeHoursArgs
): UseQueryResult<IFetchGroupAverageWeeklyPracticeHoursResponse, unknown> {
  const axios = useAxios();

  const { classroom_id, student_id, weeks_to_consider } = params;

  const query = useQuery<IFetchGroupAverageWeeklyPracticeHoursResponse>(
    [
      "group-average-weekly-practice-hours",
      { classroom_id, student_id, weeks_to_consider },
    ],
    async (): Promise<IFetchGroupAverageWeeklyPracticeHoursResponse> => {
      return await axios
        .get(`/v1/groups/${classroom_id}/weekly-practice-hours`, {
          params: {
            user_id: student_id,
            weeks_to_consider,
          },
        })
        .then((r) => {
          return r.data as IFetchGroupAverageWeeklyPracticeHoursResponse;
        });
    },
    { enabled: !!classroom_id }
  );

  return query;
}
