import { Box, Center, Flex, Link } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Card } from "adminComponents/atoms/Card";
import { Heading } from "adminComponents/atoms/Heading";
import { Icon } from "adminComponents/atoms/Icon";
import { pxToRem } from "adminComponents/utils";
import { useGetPremiumLink } from "sharedComponents/hooks/useGetPremiumLink";

export interface IProps {
  upgradeText: string;
}

export const UpgradeCallToAction: React.FC<IProps> = ({ upgradeText }) => {
  const premiumLink = useGetPremiumLink();

  const { t } = useTranslation("admin", {
    keyPrefix: "upgradeCTA",
    useSuspense: false,
  });

  return (
    <Box w="100%">
      <Center>
        <Card
          w="fit-content"
          borderColor="primary.tan"
          variant="adminCardSmallBorder"
          bg="white"
        >
          <Flex alignItems="center" gap={pxToRem(10)}>
            <Box
              as="span"
              backgroundColor="utility.premium"
              boxSize={pxToRem(40)}
              alignItems="center"
              justifyContent="center"
              display="inline-flex"
              borderRadius="full"
            >
              <Icon
                as="span"
                width={pxToRem(30)}
                height={pxToRem(30)}
                display="inline-flex"
                icon="lock"
                color="white"
              />
            </Box>
            <Flex flexDirection="column">
              <Heading as="h3" variant="adminH6">
                {upgradeText}
              </Heading>
              <Link
                style={{ textDecoration: "underline" }}
                color="utility.link"
                href={premiumLink}
              >
                {t("upgrade")}
              </Link>
            </Flex>
          </Flex>
        </Card>
      </Center>
    </Box>
  );
};
