// The EditPracticeSetMediaModalContext provides context for the AddMediaModal component to prevent
// the extensive prop drilling that would otherwise be necessary.
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

import { checkResizeImageFile } from "adminComponents/utils/checkResizeImageFile";
import { MAX_IMAGE_UPLOAD_SIZE, config } from "links/lib/constants";
import { useAuth } from "links/lib/features/auth";
import { useUploadFile } from "links/lib/features/files";
import { IUploadFileResponse } from "links/lib/features/files/useUploadFile";
import { base64toBlob } from "links/lib/util";

type FinalResult = {
  imageUrl: string;
  altText: string;
  audioUrl?: string;
  videoUrl?: string;
};

type EditPracticeSetMediaModalContextType = {
  isOpen: boolean;
  searching: boolean;
  showPremiumMarker: boolean;
  loading: boolean;
  uploading: boolean;
  uploadError: boolean;
  imagesFromLibrary: string[];
  audioUrlToAdd: string;
  imageUrlToAdd: string;
  imageAltText: string;
  videoUrlToAdd: string;
  invalidUrl: boolean;
  invalidFormat: boolean;
  invalidFormatErrorType: string;
  textToSpeechError: boolean;
  finalResult: FinalResult;
  handleOpen: () => void;
  handleClose: () => void;
  handleSearch: (search: string) => void;
  handleUploadImage: (image: File) => Promise<void>;
  handleUploadImageFromUrl: (
    link: string,
    imageOnly?: boolean
  ) => Promise<void>;
  handleConvertTextToSpeech: (text: string) => Promise<void>;
  handleUploadAudio: (audio: File) => Promise<void>;
  handlePickImageFromLibrary: (imageUrl: string) => void;
  handleResetImagesFromLibrary: () => void;
  handleInsertMedia: (
    imageUrl: string,
    altText: string,
    audioUrl?: string,
    videoUrl?: string
  ) => void;
  handleRemoveImage: () => void;
  handleRemoveAudio: () => void;
  handleInvalidFormat: (errorType?: string) => void;
  handleResetFinalResult: () => void;
};

const DEFAULT_EDIT_PRACTICE_SET_MEDIA_MODAL_CONTEXT: EditPracticeSetMediaModalContextType =
  {
    isOpen: false,
    searching: false,
    showPremiumMarker: true,
    loading: false,
    uploading: false,
    uploadError: false,
    imagesFromLibrary: [],
    audioUrlToAdd: "",
    imageUrlToAdd: "",
    imageAltText: "",
    videoUrlToAdd: "",
    invalidFormat: false,
    invalidFormatErrorType: "",
    invalidUrl: false,
    textToSpeechError: false,
    finalResult: { altText: "", imageUrl: "", audioUrl: "", videoUrl: "" },
    handleOpen: () => null,
    handleClose: () => null,
    handleSearch: () => null,
    handleUploadImage: Promise.resolve,
    handleUploadImageFromUrl: Promise.resolve,
    handleConvertTextToSpeech: Promise.resolve,
    handleUploadAudio: Promise.resolve,
    handlePickImageFromLibrary: () => null,
    handleResetImagesFromLibrary: () => null,
    handleInsertMedia: () => null,
    handleRemoveImage: () => null,
    handleRemoveAudio: () => null,
    handleInvalidFormat: () => null,
    handleResetFinalResult: () => null,
  };

const EditPracticeSetMediaModalContext =
  createContext<EditPracticeSetMediaModalContextType>(
    DEFAULT_EDIT_PRACTICE_SET_MEDIA_MODAL_CONTEXT
  );

export const EditPracticeSetMediaModalContextProvider: React.FC<{
  defaultValue?: FinalResult;
  showPremiumMarker?: boolean;
}> = ({
  children,
  defaultValue = { altText: "", imageUrl: "" },
  showPremiumMarker = true,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [searching, setSearching] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [imagesFromLibrary, setImagesFromLibrary] = useState<string[]>([]);
  const [imageUrlToAdd, setImageUrlToAdd] = useState("");
  const [imageAltText, setImageAltText] = useState("");
  const [audioUrlToAdd, setAudioUrlToAdd] = useState("");
  const [videoUrlToAdd, setVideoUrlToAdd] = useState("");
  const [invalidFormat, setInvalidFormat] = useState(false);
  const [invalidFormatErrorType, setInvalidFormatErrorType] = useState("");
  const [invalidUrl, setInvalidUrl] = useState(false);
  const [textToSpeechError, setTextToSpeechError] = useState(false);
  const [finalResult, setFinalResult] = useState<FinalResult>(defaultValue);

  const handleClose = () => {
    setOpen(false);
  };

  const onSuccessfulFileUpload = (data: IUploadFileResponse) => {
    if (data.name === "audio.mp3") {
      setAudioUrlToAdd(data.url);
    } else {
      setImageUrlToAdd(data.url);
    }
  };

  const { authToken } = useAuth();
  const fileUpload = useUploadFile({
    onSuccess: onSuccessfulFileUpload,
    onError: () => {
      setUploadError(true);
    },
  });

  const handleOpen = () => {
    setOpen(true);
  };

  // TODO: Once 3rd party image library is supported, query it here
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSearch = (search: string) => {
    setSearching(true);
    setTimeout(() => {
      setSearching(false);
    }, 2000);
  };

  const handleUploadImage = useCallback(
    async (file: File) => {
      setUploading(true);
      await fileUpload.execute({
        file,
      });
      setUploading(false);
    },
    [fileUpload]
  );

  const handleUploadImageFromUrl = useCallback(
    async (urlString: string, imageOnly?: boolean) => {
      setUploading(true);
      setImageUrlToAdd("");
      setInvalidUrl(false);
      try {
        const url = new URL(urlString);

        let isYouTubeVideoURL = false;
        if (
          (url.host === "youtube.com" || url.host.endsWith(".youtube.com")) &&
          url.searchParams.get("v")
        ) {
          isYouTubeVideoURL = true;
        }
        if (url.host === "youtu.be" && url.pathname.replace("/", "")) {
          isYouTubeVideoURL = true;
        }

        const isVimeoURL = url.host === "vimeo.com" && url.pathname !== "/";
        if (isYouTubeVideoURL || isVimeoURL) {
          if (imageOnly) {
            setInvalidFormat(true);
            setInvalidUrl(true);
            setUploading(false);
            return;
          }
          setVideoUrlToAdd(url.toString());
          setUploading(false);
          return;
        }

        const res = await fetch(
          config.gatewayOrigin +
            "/v1/images/retrieve?url=" +
            encodeURIComponent(url.toString()),
          {
            headers: {
              Authorization: `${authToken}`,
            },
          }
        );

        if (!res.ok) {
          throw new Error("Unsuccessful network request");
        }

        const blob = await res.blob();
        if (!blob.type.startsWith("image/")) {
          setInvalidFormat(true);
          setInvalidUrl(true);
          return;
        }

        const imageFile = await checkResizeImageFile(
          new File([blob], url.toString(), { type: blob.type })
        );

        if (imageFile.size > MAX_IMAGE_UPLOAD_SIZE) {
          setInvalidUrl(true);
          return;
        }

        fileUpload.execute({
          file: imageFile,
        });

        await fileUpload.execute({
          file: imageFile,
        });
      } catch {
        setInvalidUrl(true);
      }

      setUploading(false);
      setImagesFromLibrary([]);
    },
    [authToken, fileUpload]
  );

  const handlePickImageFromLibrary = (imageUrl: string) => {
    setImageUrlToAdd(imageUrl);
  };

  const handleResetImagesFromLibrary = () => {
    setImagesFromLibrary([]);
  };

  const handleInsertMedia = (
    imageUrl: string,
    altText: string,
    audioUrl?: string,
    videoUrl?: string
  ) => {
    setLoading(true);
    setFinalResult({ imageUrl, altText, audioUrl, videoUrl });
    setImageUrlToAdd("");
    setImageAltText("");
    setAudioUrlToAdd("");
    setVideoUrlToAdd("");
    setLoading(false);
    setOpen(false);
  };

  const handleRemoveImage = () => {
    setImageUrlToAdd("");
    setImageAltText("");
    setVideoUrlToAdd("");
    setInvalidFormat(false);
    setInvalidFormatErrorType("");
  };

  const handleRemoveAudio = () => {
    setAudioUrlToAdd("");
  };

  const handleInvalidFormat = (errorType?: string) => {
    setInvalidFormat(true);
    setInvalidFormatErrorType(errorType || "");
  };

  const handleUploadAudio = useCallback(
    async (file: File) => {
      setUploading(true);
      await fileUpload.execute({
        file,
      });
      setUploading(false);
    },
    [fileUpload]
  );

  const handleConvertTextToSpeech = useCallback(
    async (text: string) => {
      setTextToSpeechError(false);
      try {
        const res = await fetch(
          config.gatewayOrigin +
            "/v1/utilities/convert-text-to-speech?text=" +
            encodeURIComponent(text),
          {
            headers: {
              Authorization: `${authToken}`,
            },
          }
        );

        if (!res.ok) {
          throw new Error("Unsuccessful network request");
        }
        const json = await res.json();
        const mp3Data = base64toBlob(json["mp3_data"]);

        await handleUploadAudio(
          new File([mp3Data], "audio.mp3", { type: "audio/mpeg" })
        );
      } catch {
        setTextToSpeechError(true);
      }
    },
    [authToken, handleUploadAudio]
  );

  const handleResetFinalResult = () => {
    setFinalResult({
      altText: "",
      imageUrl: "",
      audioUrl: undefined,
      videoUrl: undefined,
    });
  };

  const value = useMemo(() => {
    return {
      isOpen,
      searching,
      loading,
      uploading,
      uploadError,
      imagesFromLibrary,
      audioUrlToAdd,
      imageUrlToAdd,
      imageAltText,
      videoUrlToAdd,
      invalidFormat,
      invalidFormatErrorType,
      invalidUrl,
      textToSpeechError,
      finalResult,
      handleOpen,
      handleClose,
      handleSearch,
      handleUploadImage,
      handleConvertTextToSpeech,
      handleUploadAudio,
      handleUploadImageFromUrl,
      handlePickImageFromLibrary,
      handleResetImagesFromLibrary,
      handleInsertMedia,
      handleRemoveImage,
      handleRemoveAudio,
      handleInvalidFormat,
      handleResetFinalResult,
      showPremiumMarker,
    };
  }, [
    isOpen,
    searching,
    loading,
    uploading,
    uploadError,
    imagesFromLibrary,
    audioUrlToAdd,
    imageUrlToAdd,
    imageAltText,
    videoUrlToAdd,
    invalidFormat,
    invalidFormatErrorType,
    invalidUrl,
    textToSpeechError,
    finalResult,
    handleConvertTextToSpeech,
    handleUploadAudio,
    handleUploadImage,
    handleUploadImageFromUrl,
    showPremiumMarker,
  ]);

  return (
    <EditPracticeSetMediaModalContext.Provider value={value}>
      {children}
    </EditPracticeSetMediaModalContext.Provider>
  );
};

export const useEditPracticeSetMediaModalContext =
  (): EditPracticeSetMediaModalContextType => {
    return useContext(EditPracticeSetMediaModalContext);
  };
