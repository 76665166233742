import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { ConfirmModal } from "adminComponents/molecules/ConfirmModal";
import {
  BASE_FOLDER,
  FolderDetailsModal,
} from "adminComponents/molecules/FolderDetailsModal";
import { useErrorToast, useShowToast } from "adminComponents/utils/toast";
import { useAnalytics } from "lib/contexts/analytics";
import {
  useDeleteCollection,
  useMutateCollection,
} from "links/lib/features/collections";
import { AnalyticsEvent, ICollection } from "links/lib/types";

export interface IArgs {
  onDelete?: (collection: ICollection) => void;
  onEdit?: (collection: ICollection) => void;
  onCreate?: (collection: ICollection) => void;
  onDeleteSuccess?: () => void;
}

export interface IResult {
  handleDelete: (collection: ICollection) => void;
  handleEdit: (collection: ICollection) => void;
  handleCreate: () => void;
  handleDuplicate: (collection: ICollection) => void;
  components: React.ReactElement;
}

interface IFolderDetailsState {
  folder?: ICollection;
  isOpen: boolean;
}

interface IFolderDeleteState {
  folder?: ICollection;
  isOpen: boolean;
}

export const useMutateFolder = ({
  onDelete,
  onEdit,
  onCreate,
  onDeleteSuccess: _onDeleteSuccess,
}: IArgs): IResult => {
  const [folderDetailsState, setFolderDetailsState] =
    useState<IFolderDetailsState>({ isOpen: false, folder: BASE_FOLDER });
  const [folderDeleteState, setFolderDeleteState] =
    useState<IFolderDeleteState>({ isOpen: false });
  const { t } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "myLibraryContainer.folderMutate",
  });
  const showToast = useShowToast();
  const { trackEvent } = useAnalytics();

  const onMutateSuccess = () => {
    const isCreate = !folderDetailsState.folder;

    setFolderDetailsState({ isOpen: false });

    showToast(t(isCreate ? "createSuccessToast" : "editSuccessToast"));
  };

  const onDeleteSuccess = () => {
    setFolderDeleteState({ isOpen: false });

    showToast(t("deleteSuccessToast"));

    _onDeleteSuccess?.();
  };

  const onConflict = () => {
    showToast(t("conflictToast"));
  };

  const mutateCollection = useMutateCollection({
    onSuccess: onMutateSuccess,
    onConflict,
    onError: showToast,
  });
  const deleteCollection = useDeleteCollection({ onSuccess: onDeleteSuccess });

  useErrorToast(deleteCollection.error);

  const handleDuplicate = () => {
    // no-op
    return;
  };

  const handleDelete = (collection: ICollection) => {
    setFolderDeleteState({ isOpen: true, folder: collection });

    trackEvent(
      AnalyticsEvent.TeacherDashboard_MyLibrary_FolderActions_DeleteFolderIntent,
      { collectionId: collection.id }
    );
  };

  const handleEdit = (collection: ICollection) => {
    setFolderDetailsState({ isOpen: true, folder: collection });

    trackEvent(
      AnalyticsEvent.TeacherDashboard_MyLibrary_FolderActions_EditFolderIntent,
      { collectionId: collection.id }
    );
  };

  const handleCreate = () => {
    setFolderDetailsState({ isOpen: true });

    trackEvent(
      AnalyticsEvent.TeacherDashboard_MyLibrary_FolderActions_CreateFolderIntent,
      {}
    );
  };

  const handleFolderDetailClose = () => {
    setFolderDetailsState({ isOpen: false });
  };

  const handleFolderDetailSave = (collection: ICollection) => {
    const isCreate = !collection.id;

    mutateCollection.mutate({
      id: collection.id ?? undefined,
      name: collection.name,
      description: collection.description,
    });

    isCreate ? onCreate?.(collection) : onEdit?.(collection);

    trackEvent(
      isCreate
        ? AnalyticsEvent.TeacherDashboard_MyLibrary_FolderActions_CreateFolderConfirm
        : AnalyticsEvent.TeacherDashboard_MyLibrary_FolderActions_EditFolderConfirm,
      {
        collectionId: collection?.id,
      }
    );
  };

  const handleDeleteFolderConfirm = (collection: ICollection) => {
    deleteCollection.mutate({
      id: collection.id,
    });

    onDelete?.(collection);

    trackEvent(
      AnalyticsEvent.TeacherDashboard_MyLibrary_FolderActions_DeleteFolderConfirm,
      {
        collectionId: collection.id,
      }
    );
  };

  const handleDeleteFolderClose = (confirm?: boolean) => {
    if (!folderDeleteState.folder) return;
    if (!confirm) {
      setFolderDeleteState({ isOpen: false });
      return;
    }

    handleDeleteFolderConfirm(folderDeleteState.folder);
  };

  const components = (
    <>
      <FolderDetailsModal
        isLoading={mutateCollection.isLoading}
        isOpen={folderDetailsState.isOpen}
        folder={folderDetailsState.folder}
        handleSave={handleFolderDetailSave}
        handleClose={handleFolderDetailClose}
      />
      <ConfirmModal
        isOpen={folderDeleteState.isOpen}
        isLoading={deleteCollection.isLoading}
        buttonCancelLabel={t("folderDeleteModal.cancelButton")}
        buttonOKLabel={t("folderDeleteModal.deleteButton")}
        handleClose={handleDeleteFolderClose}
        mobileButtonsDirection="vertical"
        size="2xl"
        description={t("folderDeleteModal.description")}
        title={t("folderDeleteModal.title")}
        variant="adminModalCompactMobile"
      />
    </>
  );

  return {
    handleDuplicate,
    handleDelete,
    handleEdit,
    handleCreate,
    components,
  };
};
