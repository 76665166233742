import { HStack } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useWindowSize } from "react-use";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { Button } from "sessionComponents/atoms/Button";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";

export interface ITeacherPodiumFooterProps {
  group_id?: string;
  practice_set_id?: string;
}

//todo not sure this needs to be a custom component like this, or can be combined with other pages' footers...
export const TeacherPodiumFooter: React.FC<ITeacherPodiumFooterProps> = ({
  group_id,
  practice_set_id,
}) => {
  const { match: currentBreakpoints } = useBreakpoints();
  const { t } = useTranslation("session", {
    keyPrefix: "podium",
    useSuspense: false,
  });

  // Not the biggest fan of this hard coded size, breakpoints aren't cutting it for this
  const { width: windowWidth } = useWindowSize();
  const useShortButtonLabels = windowWidth < 1625;
  const endButtonLabel = useShortButtonLabels
    ? t("endGameSessionShort")
    : t("endGameSession");
  const reviewButtonLabel = useShortButtonLabels
    ? t("reviewReportShort")
    : t("reviewReport");

  return (
    <HStack
      w="full"
      bgColor="primary.warm-white"
      p={pxToRem(currentBreakpoints.padding)}
      borderTopWidth={pxToRem(currentBreakpoints.borderWidth)}
      borderColor="primary.tan"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      spacing={pxToRem(currentBreakpoints.margin / 2)}
    >
      {!!group_id && !!practice_set_id && (
        <Link
          style={{ width: "100%" }}
          to={`/t/classrooms/${group_id}/sets/${practice_set_id}`}
        >
          <Button
            w="full"
            variant="buttonOutlined"
            h={pxToRem(currentBreakpoints.buttonHeight)}
            fontSize={pxToRem(currentBreakpoints.fontSize)}
            as="span"
          >
            {reviewButtonLabel}
          </Button>
        </Link>
      )}
      <Link style={{ width: "100%" }} to="/t/my-library">
        <Button
          w="full"
          variant="buttonFilled"
          h={pxToRem(currentBreakpoints.buttonHeight)}
          fontSize={pxToRem(currentBreakpoints.fontSize)}
          as="span"
        >
          {endButtonLabel}
        </Button>
      </Link>
    </HStack>
  );
};
