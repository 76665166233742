export const AdminTemplateWithVerticalCenteredContent = {
  baseStyle: {
    outerContainer: {
      backgroundColor: "primary.warm-white",
      display: "flex",
      minH: "full",
      w: "full",
    },
    backgroundContainer: {
      backgroundAttachment: "fixed",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      display: "flex",
      flex: "1",
    },
    contentContainer: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      h: "full",
      justifyContent: "center",
      minH: "full",
      w: "full",
    },
    bodyContainer: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      justifyContent: "center",
      w: "full",
    },
  },
};
