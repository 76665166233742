import { pxToRem } from "adminComponents/utils/pxToRem";

export const AdminEditAvatarTabs = {
  baseStyle: {
    avatarGrid: {
      position: "relative",
      ".swiper-slide button": {
        w: "full",
        maxW: {
          base: pxToRem(75),
          lg: pxToRem(135),
        },
        h: {
          base: pxToRem(75),
          lg: pxToRem(135),
        },
      },
      ".swiper-slide button[aria-pressed=true]": {
        bgColor: "primary.white",
      },
      ".swiper-slide button img": {
        m: "auto",
        h: "100%",
      },
    },
    avatarGridContainer: {
      maxW: {
        base: "full",
        lg: pxToRem(590),
      },
    },
    colorPickerSubSections: {
      "+ *": {
        mt: pxToRem(24),
      },
      "div > .swiper": {
        px: 0,
        py: 0,
      },
      maxWidth: {
        base: "full",
        lg: pxToRem(590),
      },
      ml: {
        base: pxToRem(0),
        lg: pxToRem(0),
      },
    },
    iconCarousel: {
      pt: pxToRem(24),
      maxWidth: {
        base: "full",
        lg: pxToRem(590),
      },
      "div > .swiper": {
        px: 0,
        py: 0,
      },
    },
    hairColor: {
      maxWidth: {
        base: "full",
        lg: pxToRem(590),
      },
      "div > .swiper": {
        px: 0,
        py: 0,
      },
    },
    avatarTypeSelector: {
      my: {
        base: pxToRem(24),
      },
      maxWidth: {
        base: "full",
        lg: pxToRem(590),
      },
      px: {
        base: pxToRem(16),
        lg: pxToRem(60),
      },
    },
    skinTone: {
      px: {
        lg: pxToRem(60),
      },
      maxWidth: {
        base: "full",
        lg: pxToRem(590),
      },
      "div > .swiper": {
        px: 0,
        py: 0,
      },
    },
    tabs: {
      "> div": {
        _first: {
          overflowY: "hidden",
          overflowX: "auto",
          left: {
            base: pxToRem(15),
            lg: pxToRem(60),
          },
          position: "relative",
          width: {
            base: "full",
            lg: `calc(100% - ${pxToRem(120)})`,
          },
          maxW: {
            base: `calc(100% - ${pxToRem(15)})`,
            lg: pxToRem(461),
          },
        },
      },
      "[role=tablist]": {
        button: {
          ml: {
            base: "8%",
            lg: pxToRem(40),
          },
          _first: {
            ml: 0,
          },
        },
      },
    },
  },
};
