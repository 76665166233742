import { Box, HStack, VStack } from "@chakra-ui/react";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { animated, config, useSpring } from "react-spring";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { useSessionActions } from "links/lib/contexts/sessionActions";
import { useSessionRoundGroupState } from "links/lib/contexts/sessionRoundGroupState";
import { useSessionRoundState } from "links/lib/contexts/sessionRoundState";
import { useAuth } from "links/lib/features/auth";
import { CoopDrawVariantData, ISessionUser } from "links/lib/types";
import { IconButton } from "sessionComponents/atoms/IconButton";
import { UserDrawIcon } from "sessionComponents/atoms/UserDrawIcon";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { useGroupUsers } from "sessionComponents/hooks/useGroupUsers";
import {
  DrawCanvas,
  REF_CANVAS_HEIGHT,
  REF_CANVAS_WIDTH,
} from "sharedComponents/atoms/DrawCanvas";

const AnimatedBox = animated(Box);

interface IStudentDrawingProps {
  student: ISessionUser;
}

export const StudentDrawing: React.FC<IStudentDrawingProps> = ({ student }) => {
  const { t } = useTranslation("session", {
    keyPrefix: "ariaLabels",
    useSuspense: false,
  });
  const { authUser } = useAuth();
  const { match } = useBreakpoints();
  const canvasContainerRef = useRef<HTMLDivElement | null>(null);
  const roundState = useSessionRoundState();
  const roundGroupState = useSessionRoundGroupState();
  const { sendDrawStroke, undoDrawStroke, changeDrawColor } =
    useSessionActions();
  const { confirmed_user_ids } = roundState;
  const { draw } = roundGroupState;
  const isConfirmed = confirmed_user_ids.some((id) => id === student.id);
  const groupUsers = useGroupUsers(student.session_group_id);

  const studentFirstGroupUsers = useMemo(
    () =>
      groupUsers.sort((a, b) => {
        if (a.id === student.id) {
          return -1;
        } else if (b.id === student.id) {
          return 1;
        } else {
          return 0;
        }
      }),
    [groupUsers, student.id]
  );

  const teamPens = useMemo(() => draw?.pens || [], [draw?.pens]);

  const studentPen = useMemo(
    () => teamPens.find((pen) => pen.user_id === student.id),
    [teamPens, student]
  );

  const [selectedColor, setSelectedColor] = useState<string>();

  const contentSpring = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    config: config.stiff,
  });

  const strokes = useMemo(() => draw?.strokes || [], [draw]);
  const userStrokes = useMemo(
    () => strokes.filter((stroke) => stroke.user_id === student.id),
    [strokes, student.id]
  );

  const onColorSelect = useCallback(
    (color) => {
      setSelectedColor(color);
      changeDrawColor(color);
    },
    [setSelectedColor, changeDrawColor]
  );

  return (
    <VStack spacing={pxToRem(match.margin)} h="full">
      <AnimatedBox
        style={contentSpring}
        w="full"
        ref={canvasContainerRef}
        boxShadow="md"
        overflow="hidden"
        // calculation to make sure the canvas container doesn't overflow the maximum size of the answer options container height
        h={`calc(100vh - ${match.headerHeight}px - ${match.buttonHeight}px - ${
          match.padding * 8
        }px)`}
        maxH={pxToRem(
          (canvasContainerRef.current?.clientWidth || 0) *
            (REF_CANVAS_HEIGHT / REF_CANVAS_WIDTH)
        )}
      >
        <DrawCanvas
          backgroundImageUrl={
            (roundState.variant_data as CoopDrawVariantData).coop_draw
              .background_image_url
          }
          strokes={strokes}
          penColor={selectedColor || studentPen?.color}
          onStroke={sendDrawStroke}
          disabled={isConfirmed}
          studentId={student.id}
        />
      </AnimatedBox>
      <HStack spacing={0} w="full">
        <Box
          display="flex"
          justifyContent="center"
          gap={pxToRem(match.margin * 0.66)}
          flexGrow={2}
          alignItems="center"
          h="full"
        >
          {studentFirstGroupUsers.map((user) => {
            const userPen = teamPens.find((pen) => pen.user_id === user.id);
            const isSelf = userPen?.user_id === student.id;

            const onSelect = isSelf ? onColorSelect : undefined;

            return (
              <UserDrawIcon
                key={user.id}
                userId={user.id}
                isConfirmed={confirmed_user_ids.some((uid) => uid === user.id)}
                isSelf={user.id === authUser?.id}
                penColor={
                  isSelf
                    ? selectedColor || userPen?.color || "black"
                    : userPen?.color || "black"
                }
                avatarImgUrl={user.profile_image_url}
                userName={user.name}
                userConnectionStatus={user.connection_status}
                onSelect={onSelect}
              />
            );
          })}
        </Box>
        <IconButton
          aria-label={t("undoDrawStrokeButton")}
          icon="undo"
          type="button"
          boxSize={pxToRem(match.buttonHeight)}
          iconSize={pxToRem(match.iconSize)}
          isDisabled={!userStrokes.length || isConfirmed}
          onClick={undoDrawStroke}
        />
      </HStack>
    </VStack>
  );
};
