import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { useAxios } from "../../hooks/useAxios";

export interface IUseDeleteCollectionArgs {
  onSuccess?: () => void;
}

export interface IDeleteCollectionArgs {
  id: string;
}

export default function useDeleteCollection({
  onSuccess,
}: IUseDeleteCollectionArgs): UseMutationResult<
  Record<string, unknown>,
  unknown,
  IDeleteCollectionArgs
> {
  const axios = useAxios();
  const queryClient = useQueryClient();

  const mutateCollection = async (args: IDeleteCollectionArgs) => {
    const { id } = args;

    const response = await axios({
      method: "delete",
      url: `/v1/collections/${id}`,
    }).then((r) => r.data as Record<string, unknown>);

    queryClient.invalidateQueries(["collections"]);
    queryClient.setQueryData(["collections", { id: id }], undefined);

    queryClient.invalidateQueries(["practiceSets"]);
    queryClient.invalidateQueries(["searchPracticeData"]);
    queryClient.invalidateQueries(["searchPracticeDataInfinite"]);

    return response;
  };

  const mutation = useMutation(mutateCollection, { onSuccess });

  return {
    ...mutation,
  };
}
