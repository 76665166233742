import { Input, Text } from "@chakra-ui/react";
import { SkeletonData } from "@esotericsoftware/spine-core";
import JSZip, { JSZipObject } from "jszip";
import React, { SyntheticEvent } from "react";

import { svgToBase64 } from "../utils";

type Step1Props = {
  resetState: () => void;
  setError: (error: string) => void;
  setImages: (
    value: React.SetStateAction<{
      [key: string]: string;
    }>
  ) => void;
  setImagesBase64: (
    value: React.SetStateAction<{
      [key: string]: string;
    }>
  ) => void;
  setSeatedSpineConfiguration: (
    value: React.SetStateAction<SkeletonData | null>
  ) => void;
  setStandingSpineConfiguration: (
    value: React.SetStateAction<SkeletonData | null>
  ) => void;
};

export const Step1: React.FC<Step1Props> = ({
  resetState,
  setError,
  setImages,
  setImagesBase64,
  setSeatedSpineConfiguration,
  setStandingSpineConfiguration,
}: Step1Props) => {
  const onSpineZIPFileChange = async (e: SyntheticEvent<HTMLInputElement>) => {
    if (!e.target) return;

    resetState();

    const target = e.target as HTMLInputElement;
    const file = target && target.files ? target.files[0] : null;
    if (!file) {
      setError("No Spine file selected");
      return;
    }

    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = (e: ProgressEvent<FileReader>) => {
      const buffer = e.target?.result as ArrayBuffer;

      const zip = new JSZip();
      zip.loadAsync(buffer).then(async (data: JSZip) => {
        const standingJSONFile = Object.values(data.files).find(
          (file: JSZipObject) => {
            return file.name.toLowerCase() === "standing_body.json";
          }
        );
        if (!standingJSONFile) {
          setError("Could not locate standing_body.json");
          return;
        }

        zip
          .file(standingJSONFile.name)
          ?.async("string")
          .then((content: string) => {
            const config = JSON.parse(content) as SkeletonData;

            if (!config.skins) {
              setError("Could not find standing skins");
              return;
            }

            setStandingSpineConfiguration(config);
          });

        const seatedJSONFile = Object.values(data.files).find(
          (file: JSZipObject) => {
            return file.name.toLowerCase() === "seated_body.json";
          }
        );
        if (!seatedJSONFile) {
          setError("Could not locate seated_body.json");
          return;
        }

        zip
          .file(seatedJSONFile.name)
          ?.async("string")
          .then((content: string) => {
            const config = JSON.parse(content) as SkeletonData;

            if (!config.skins) {
              setError("Could not find seated skins");
              return;
            }

            setSeatedSpineConfiguration(config);
          });

        const svgFiles = Object.values(data.files).filter(
          (file: JSZipObject) => {
            return file.name.endsWith(".svg");
          }
        );

        const images: { [key: string]: string } = {};
        const imagesBase64: { [key: string]: string } = {};

        await Promise.all(
          svgFiles.map((svgFile: JSZipObject) => {
            return zip
              .file(svgFile.name)
              ?.async("string")
              .then((contents: string) => {
                const fileNamePathParts = svgFile.name.split("/");
                const fileName =
                  fileNamePathParts[fileNamePathParts.length - 1];
                const slotName = (
                  fileName
                    .toLowerCase()
                    .replace(" ", "_")
                    .replace("-", "_")
                    .split("\\")
                    .pop() || ""
                ).split(".")[0];

                if (!slotName) return;

                images[slotName] = contents;
                imagesBase64[slotName] = svgToBase64(contents);
              });
          })
        );

        setImages(images);
        setImagesBase64(imagesBase64);
      });
    };
    reader.onerror = () => {
      if (!reader.error) return;

      setError(reader.error.message);
    };
  };

  return (
    <>
      <Text p="24px 0">
        1. Select a ZIP file. The file must contain the avatar skeleton JSON
        file and SVGs for each of the attachments. The SVG files should be named
        after the associated slot and have &quot;svg&quot; as an extension (e.g.
        hair_front.svg).
      </Text>
      <Input
        type="file"
        onChange={onSpineZIPFileChange}
        accept="application/zip"
      />
    </>
  );
};
