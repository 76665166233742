import { useMemo } from "react";

export function useInitials(name: string): string {
  const initials = useMemo(() => {
    return name
      .split(" ")
      .map((s) => s.trim())
      .filter((s) => s.length)
      .map((s) => s.charAt(0))
      .join("")
      .toUpperCase();
  }, [name]);

  return initials;
}
