import { ISessionUser } from "links/lib/types";
import { UserInResponse } from "sessionComponents/types";

import { getAssetColorScheme } from "./getAssetColorScheme";

interface IGetDraggingUserDetailsProps {
  isDragging: boolean;
  groupUsers: ISessionUser[];
  userId?: string;
}

export const getDraggingUserDetails = ({
  isDragging,
  groupUsers,
  userId,
}: IGetDraggingUserDetailsProps): UserInResponse | undefined => {
  if (!isDragging) return undefined;

  const draggingUser = groupUsers?.find((user) => user.id === userId);

  return draggingUser
    ? {
        avatarUrl: draggingUser.profile_image_url,
        id: draggingUser.id,
        name: draggingUser.name,
        primaryColor: getAssetColorScheme(draggingUser.color_scheme)
          .primaryColor,
      }
    : undefined;
};
