import { MutableRefObject, useRef, useState } from "react";
import { useLifecycles } from "react-use";
import { clearTimeout, setTimeout } from "timers";

export interface IUseReconnectingWebSocketArgs {
  maxReconnectAttempts: number;
  // The maximum time in milliseconds to wait for a connection to succeed before closing and retrying
  timeoutInterval: number;
  // The rate of increase of the reconnect delay
  reconnectDecay: number;
  // The number of milliseconds to delay before attempting to reconnect
  reconnectInterval: number;
  origin: string;
  authToken: string;
  debug?: boolean;
  sessionCode: string | null;
  onMessage?: (message: MessageEvent<string>) => void;
  onError?: (e: Event) => void;
  onConnect?: () => void;
  onClose?: (ev: CloseEvent, isIntended: boolean) => void;
  onReconnect?: () => void;
}

export interface IUseReconnectingWebSocketResult {
  ws: MutableRefObject<WebSocket | undefined>;
  connected: boolean;
  connect: (isReconnect: boolean, onReconnect?: () => void) => void;
  close: () => void;
}

export default function useReconnectingWebSocket(
  args: IUseReconnectingWebSocketArgs
): IUseReconnectingWebSocketResult {
  const { origin, authToken, debug, maxReconnectAttempts, sessionCode } = args;

  const ws = useRef<WebSocket>();
  const reconnectAttempts = useRef<number>(0);
  // the timeout for a pending reconnect
  const reconnectTimeout = useRef<NodeJS.Timeout | null>(null);
  // the timeout for a connection timeout
  const connectTimeout = useRef<NodeJS.Timeout | null>(null);
  const [connected, setConnected] = useState<boolean>(false);
  const [closed, setClosed] = useState<boolean>(false);
  const isUnmount = useRef<boolean>(false);

  /**
   * Logs debug message if debugging enabled
   * @param message
   * @returns
   */
  const logDebug = (message: string) => {
    if (!debug) return;

    console.log("ReconnectingWebSocket: " + message);
  };

  /**
   * Creates a new websocket client
   */
  const createClient = (isReconnect = false, onReconnect?: () => void) => {
    logDebug(isReconnect ? "Reconnecting..." : "Connecting...");

    // clean up old client before creating new one
    if (ws.current) {
      logDebug("Closing old client...");
      closeClient();
    }

    ws.current = new WebSocket(
      origin + `/v1/ws/session?code=${sessionCode ? sessionCode : ""}`,
      ["Bearer", authToken]
    );

    if (args.onMessage) ws.current.onmessage = args.onMessage;
    if (args.onError) ws.current.onerror = args.onError;
    ws.current.onopen = () => {
      logDebug("Connected");

      // reset reconnect attempts and reconnection status on successful open
      reconnectAttempts.current = 0;

      clearConnectInterval();

      setConnected(true);

      if (!isReconnect && args.onConnect) args.onConnect();
      if (isReconnect) {
        if (onReconnect) {
          onReconnect();
        } else if (args.onReconnect) {
          args.onReconnect();
        }
      }
    };
    ws.current.onclose = onUnintendedClose;

    // Force close if not connected within timeout
    connectTimeout.current = setTimeout(() => {
      onConnectionTimeout();
    }, args.timeoutInterval);
  };

  const onUnintendedClose = (ev: CloseEvent) => {
    logDebug("Closed unintentionally");

    setConnected(false);

    reconnect();

    if (args.onClose) args.onClose(ev, false);
  };

  const onIntendedClose = (ev: CloseEvent) => {
    logDebug("Closed intentionally");

    // this is an old client - dont update
    // connection status or call handlers
    if (!isUnmount.current) return;

    setConnected(false);

    if (args.onClose) args.onClose(ev, true);
  };

  /**
   * Intentionally closes client - will not reconnect
   */
  const closeClient = () => {
    if (closed) return;

    logDebug("Closing client intentionally...");

    // stop any pending reconnect attempts
    clearReconnectInterval();

    // clear any pending connect timeouts
    clearConnectInterval();

    // overwrite handlers for the current client
    if (ws.current) {
      ws.current.onopen = noop;
      ws.current.onerror = noop;
      ws.current.onmessage = noop;
      ws.current.onclose = onIntendedClose;
    }

    ws.current?.close();

    setClosed(true);
  };

  const noop = () => {
    return 0;
  };

  /**
   * Handler for connection timeout
   */
  const onConnectionTimeout = () => {
    logDebug("Connection timeout");

    ws.current?.close();
  };

  /**
   * Clears any outstanding connect timeout interval
   * @returns
   */
  const clearConnectInterval = () => {
    if (!connectTimeout.current) return;

    clearTimeout(connectTimeout.current);
    connectTimeout.current = null;
  };

  /**
   * Clears any outstanding reconnect interval
   * @returns
   */
  const clearReconnectInterval = () => {
    if (!reconnectTimeout.current) return;

    clearTimeout(reconnectTimeout.current);
    reconnectTimeout.current = null;
  };

  /**
   * Attempt to reconnect websocket
   * @returns
   */
  const reconnect = () => {
    logDebug("Attempting reconnect...");

    if (reconnectAttempts.current >= maxReconnectAttempts) {
      logDebug(
        "Max reconnect attempts reached. No longer attempting reconnect"
      );

      return;
    }

    reconnectAttempts.current += 1;

    clearReconnectInterval();
    clearConnectInterval();

    const timeout =
      args.reconnectInterval *
      Math.pow(args.reconnectDecay, reconnectAttempts.current);

    logDebug("Will attempt reconnect in " + timeout + "ms");

    reconnectTimeout.current = setTimeout(() => {
      createClient(true);
    }, timeout);
  };

  useLifecycles(
    () => {
      logDebug("Mounting");
    },
    () => {
      logDebug("Unmounting");
      isUnmount.current = true;
      // Cleanly close socket on unmount
      closeClient();
    }
  );

  return {
    connected,
    connect: createClient,
    close: closeClient,
    ws: ws,
  };
}
