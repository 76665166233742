import { Flex, Grid, useBreakpointValue } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Heading } from "adminComponents/atoms/Heading";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { IPendingSession, IRemix } from "links/lib/types";

import RemixItem from "../RemixItem";

interface MyDailyRemixesProps {
  remixItems: IRemix[];
  handleClick?: (remix: IRemix) => void;
  pendingSession?: IPendingSession;
}

const MyDailyRemixes: React.FC<MyDailyRemixesProps> = ({
  remixItems,
  handleClick,
  pendingSession,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const isMobile = useBreakpointValue({ base: true, md: false });

  const renderItems = () =>
    remixItems.map((remix, index) => (
      <RemixItem
        key={`remix_item_${index}`}
        remix={remix}
        handleClick={handleClick}
        isLoading={
          pendingSession?.subjectId === remix.subject_id &&
          pendingSession?.onDemandIntent === remix.on_demand_intent
        }
        isDisabled={!!pendingSession}
      />
    ));
  return (
    <>
      <Heading variant="adminH6Bold" as="h6">
        {t("myDailyRemixes.title")}
      </Heading>
      <Text mt={pxToRem(4)} variant="adminP2" mb={pxToRem(24)}>
        {t("myDailyRemixes.welcome")}
      </Text>
      {isMobile ? (
        <Flex
          flexDirection="row"
          alignItems="left"
          gap={pxToRem(20)}
          overflowX="auto"
          paddingBottom={pxToRem(10)}
          // 16px is the width of padding on mobile (admin.mobileXPadding)
          width={`calc(100vw - ${pxToRem(16)})`}
          transform={`translateX(${pxToRem(-16)})`}
          paddingX="admin.mobileXPadding"
        >
          {renderItems()}
        </Flex>
      ) : (
        <Grid templateColumns="repeat(3, 1fr)" gap={pxToRem(24)}>
          {renderItems()}
        </Grid>
      )}
    </>
  );
};

export default MyDailyRemixes;
