import { debounce } from "lodash";
import React, { useEffect, useMemo, useState } from "react";

import { useErrorToast } from "adminComponents/utils/toast";
import {
  IHandleSetQueryArgs,
  ISearchQuery,
  useLibraryFilter,
} from "lib/hooks/useLibraryFilter";
import {
  useSearchPracticeData,
  useSearchPracticeDataOnce,
} from "links/lib/features/search";
import { ISearchPracticeDataOnceResponse } from "links/lib/features/search/useSearchPracticeDataOnce";
import {
  IGradeLevel,
  IPracticeSet,
  ISearchSuggestion,
  IStandard,
  ISubject,
  ISubjectCount,
  PracticeDataDocumentType,
  PracticeSetAvailability,
} from "links/lib/types";

export interface IArgs {
  gradeLevels: Array<IGradeLevel>;
  subjects: Array<ISubject>;
  country: string;
  region?: string;
  expertPage: number;
  communityPage: number;
  domainPage: number;
  privatePage: number;
  perPage: number;
  initialQuery?: ISearchQuery;
  showPremiumFilter?: boolean;
  showSubjectsCounts?: boolean;
  disabled?: boolean;
  authUserId?: string;
}

export interface IResult {
  query: ISearchQuery;
  handleSetTerm: (term: string) => void;
  handleClearFilters: () => void;
  handleRemoveFilterTag: (
    category: "subject" | "grade" | "standard",
    id: string
  ) => void;
  handleOpenFilterFlyout: () => void;
  practiceSets: {
    expert: Array<IPracticeSet>;
    community: Array<IPracticeSet>;
    domain: Array<IPracticeSet>;
    private: Array<IPracticeSet>;
  };
  totalCounts: {
    practiceSets: {
      total: number;
      expert: number;
      community: number;
      domain: number;
      private: number;
    };
  };
  isInitialLoad: boolean;
  isSearchLoading: boolean;
  handleClearSearchTerm: () => void;
  handleChangeSubjects: (subjectIds: Array<string>) => void;
  handleChangeGradeLevels: (gradeLevelIds: Array<string>) => void;
  handleUpdateQuery: (args: IHandleSetQueryArgs) => void;
  filterFlyout: React.ReactElement;
  standards: Array<IStandard>;
  standardsModal: React.ReactElement;
  subjectCounts: Array<ISubjectCount>;
  handleOpenStandardsModal: (isDirectSearch?: boolean) => void;
  handleSuggest: (term: string) => void;
  suggestions: Array<string>;
  searchFilterSuggestions: Array<ISearchSuggestion>;
  isTermParsedSearchFilters: boolean;
}

/**
 * useAllLibrariesSearch composes the pre-existing library search hooks
 * in order to search ALL libraries (public, domain, and private) with the
 * same set of filters
 */
export const useAllLibrariesSearch = (args: IArgs): IResult => {
  const {
    gradeLevels,
    subjects,
    country,
    region,
    expertPage,
    communityPage,
    domainPage,
    privatePage,
    perPage,
    initialQuery,
    showPremiumFilter = true,
    showSubjectsCounts = true,
    disabled = false,
    authUserId,
  } = args;

  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [searchFilterSuggestions, setSearchFilterSuggestions] = useState<
    ISearchSuggestion[]
  >([]);
  const [flyoutVals, setFlyoutVals] = useState<{
    isLoading: boolean;
    subjectCounts: Array<ISubjectCount>;
  }>({
    isLoading: true,
    subjectCounts: [],
  });

  const {
    handleUpdateQuery,
    handleChangeSubjects,
    handleChangeGradeLevels,
    handleClearFilters,
    handleClearSearchTerm,
    handleOpenFilterFlyout,
    handleOpenStandardsModal,
    handleRemoveFilterTag,
    handleSetTerm,
    filterFlyout,
    query,
    standards,
    standardsModal,
  } = useLibraryFilter({
    disabled,
    isLoading: flyoutVals.isLoading,
    subjectCounts: flyoutVals.subjectCounts,
    gradeLevels,
    country,
    region,
    subjects,
    initialQuery,
    showPremiumFilter,
    showSubjectsCounts,
  });

  const searchParams = {
    term: query.term || "",
    standard_ids: query.filters?.standardIds,
    subject_ids: query.filters?.subjectIds,
    grade_level_ids: query.filters?.gradeLevelIds,
    include_shared: true,
    document_type: PracticeDataDocumentType.PracticeSets,
    per_page: perPage,
    certified_only: query.filters.certifiedOnly,
    hide_premium: query.filters.hidePremium,
    disabled,
  };

  const onSearchPracticeDataOnceSuccess = (
    data: ISearchPracticeDataOnceResponse
  ) => {
    setSuggestions((suggestions) => {
      const suggestionsSet = new Set<string>();
      suggestions.forEach((suggestion) =>
        suggestionsSet.add(suggestion.trim())
      );
      data.suggestions.forEach((suggestion) =>
        suggestionsSet.add(suggestion.trim())
      );
      return Array.from(suggestionsSet);
    });
    if (data.detailed_suggestions?.length > 0) {
      setSearchFilterSuggestions(data.detailed_suggestions);
    }
  };

  const searchPracticeDataOnceForSuggestions = useSearchPracticeDataOnce({
    onSuccess: onSearchPracticeDataOnceSuccess,
  });

  // #region Public
  const searchPracticeSetsExpert = useSearchPracticeData({
    ...searchParams,
    availability: PracticeSetAvailability.Public,
    page: expertPage,
    exclude_user_id: authUserId,
    certified_only: true,
  });

  const isTermParsedSearchFilters =
    !!searchPracticeSetsExpert.data?.term_parsed_search_filters?.grade_level_ids
      ?.length ||
    !!searchPracticeSetsExpert.data?.term_parsed_search_filters?.subject_ids
      ?.length;

  useErrorToast(searchPracticeSetsExpert.error);

  const searchPracticeSetsCommunity = useSearchPracticeData({
    ...searchParams,
    availability: PracticeSetAvailability.Public,
    page: communityPage,
    exclude_user_id: authUserId,
    certified_only: false,
    hide_premium: true,
    exclude_certified: true,
    disabled: query.filters.certifiedOnly,
  });

  useErrorToast(searchPracticeSetsCommunity.error);
  // #endregion

  // #region Domain
  const searchPracticeSetsDomain = useSearchPracticeData({
    ...searchParams,
    availability: PracticeSetAvailability.Domain,
    exclude_user_id: authUserId,
    page: domainPage,
    exclude_public_availability_from_domain_search: true,
  });

  useErrorToast(searchPracticeSetsDomain.error);
  // #endregion

  // #region Private
  const searchPracticeSetsPrivate = useSearchPracticeData({
    ...searchParams,
    availability: PracticeSetAvailability.Private,
    include_shared: false,
    page: privatePage,
  });

  useErrorToast(searchPracticeSetsPrivate.error);
  // #endregion

  const subjectCounts: Array<ISubjectCount> = useMemo(() => {
    const subjectCountsExpert =
      searchPracticeSetsExpert.data?.subject_counts || [];
    const subjectCountsCommunity =
      searchPracticeSetsCommunity.data?.subject_counts || [];
    const subjectCountsDomain =
      searchPracticeSetsDomain.data?.subject_counts || [];
    const subjectCountsPrivate =
      searchPracticeSetsPrivate.data?.subject_counts || [];

    const subjectCountsMap: { [key: string]: ISubjectCount } = {};

    subjectCountsExpert
      .concat(subjectCountsCommunity)
      .concat(subjectCountsDomain)
      .concat(subjectCountsPrivate)
      .forEach((subjectCount) => {
        const existingCount = subjectCountsMap[subjectCount.subject_id];
        if (existingCount) {
          subjectCountsMap[subjectCount.subject_id] = {
            ...existingCount,
            count: existingCount.count + subjectCount.count,
          };
        } else {
          subjectCountsMap[subjectCount.subject_id] = subjectCount;
        }
      });

    return Object.values(subjectCountsMap);
  }, [
    searchPracticeSetsCommunity.data?.subject_counts,
    searchPracticeSetsDomain.data?.subject_counts,
    searchPracticeSetsExpert.data?.subject_counts,
    searchPracticeSetsPrivate.data?.subject_counts,
  ]);

  useEffect(() => {
    if (
      isInitialLoad &&
      !(
        searchPracticeSetsExpert.isLoading ||
        searchPracticeSetsCommunity.isLoading ||
        searchPracticeSetsDomain.isLoading ||
        searchPracticeSetsPrivate.isLoading
      )
    ) {
      setIsInitialLoad(false);
    }
  }, [
    isInitialLoad,
    searchPracticeSetsDomain.isLoading,
    searchPracticeSetsPrivate.isLoading,
    searchPracticeSetsExpert.isLoading,
    searchPracticeSetsCommunity.isLoading,
  ]);

  useEffect(() => {
    setFlyoutVals({
      isLoading:
        searchPracticeSetsExpert.isLoading ||
        searchPracticeSetsCommunity.isLoading ||
        searchPracticeSetsDomain.isLoading ||
        searchPracticeSetsPrivate.isLoading,
      subjectCounts,
    });
  }, [
    searchPracticeSetsDomain.isLoading,
    searchPracticeSetsPrivate.isLoading,
    searchPracticeSetsExpert.isLoading,
    searchPracticeSetsCommunity.isLoading,
    subjectCounts,
  ]);

  const totalPublicSets =
    (searchPracticeSetsExpert.data?.total_count || 0) +
    (searchPracticeSetsCommunity.data?.total_count || 0);
  const totalDomainSets = searchPracticeSetsDomain.data?.total_count || 0;
  const totalPrivateSets = searchPracticeSetsPrivate.data?.total_count || 0;

  const totalCounts = {
    practiceSets: {
      total: totalPublicSets + totalDomainSets + totalPrivateSets,
      expert: searchPracticeSetsExpert.data?.total_count || 0,
      community: searchPracticeSetsCommunity.data?.total_count || 0,
      domain: totalDomainSets,
      private: totalPrivateSets,
    },
  };

  const handleSuggestDebounced = debounce(
    (term: string) => {
      setSuggestions([]);
      searchPracticeDataOnceForSuggestions.execute({
        ...searchParams,
        term,
        include_suggestions: true,
        include_detailed_suggestions: true,
        include_shared: false,
        page: 1,
        per_page: 8,
        availability: PracticeSetAvailability.ALL_SEARCH,
        exclude_subject_counts: true,
      });
    },
    250,
    {
      leading: false,
      trailing: true,
    }
  );

  return {
    handleSetTerm,
    handleClearFilters,
    handleRemoveFilterTag,
    handleOpenFilterFlyout,
    query,
    filterFlyout,
    practiceSets: {
      expert: searchPracticeSetsExpert.data?.practice_sets || [],
      community: searchPracticeSetsCommunity.data?.practice_sets || [],
      domain: searchPracticeSetsDomain.data?.practice_sets || [],
      private: searchPracticeSetsPrivate.data?.practice_sets || [],
    },
    totalCounts,
    isInitialLoad,
    isSearchLoading:
      searchPracticeSetsExpert.isLoading ||
      searchPracticeSetsCommunity.isLoading ||
      searchPracticeSetsDomain.isLoading ||
      searchPracticeSetsPrivate.isLoading,
    handleClearSearchTerm,
    standards,
    standardsModal,
    subjectCounts,
    handleChangeSubjects,
    handleChangeGradeLevels,
    handleUpdateQuery,
    handleOpenStandardsModal,
    handleSuggest: handleSuggestDebounced,
    suggestions,
    searchFilterSuggestions,
    isTermParsedSearchFilters,
  };
};
