import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import { ClassroomPracticeSetReportScreen } from "adminComponents/screens/ClassroomPracticeSetReportScreen";
import { usePageTrack } from "lib/contexts/analytics";
import { usePageTitle } from "lib/hooks/usePageTitle";
import { useAuth } from "links/lib/features/auth";
import { AnalyticsPage } from "links/lib/types";
import { redirectIfNaN } from "links/lib/util";
import { useNavigationData } from "screens/TeacherDashboard/contexts/TeacherNavigationDataProvider";

import { QuestionsTab } from "./components/questionsTab";
import { StandardsTab } from "./components/standardsTab";
import { StudentsTab } from "./components/studentsTab";
import { useFetchPastSessionsAndAssignments } from "./hooks/useFetchPastSessionsAndAssignments";
import { usePracticeSetReportQueries } from "./hooks/usePracticeSetReportQueries";

export enum PracticeSetDetailTab {
  Questions = 0,
  StudentPerfomance = 1,
  Standards = 2,
}

export const ClassroomPracticeSetReport: React.FC = () => {
  const { t } = useTranslation("admin", {
    keyPrefix: "classroomDetailContainer",
    useSuspense: false,
  });
  const params = useParams() as { classroomId: string; practiceSetId: string };
  const history = useHistory();
  const { isFeatureEnabled } = useAuth();

  usePageTrack(
    AnalyticsPage.TeacherDashboard_ClassroomDetail_PracticeSetDetail
  );

  const { classroomId, practiceSetId } = params;

  redirectIfNaN(practiceSetId, `/t/classrooms/${classroomId}/sets`);

  const {
    pastAssignments,
    specificAssignment,
    setSpecificAssignment,
    pastSessions,
    specificSession,
    setSpecificSession,
  } = useFetchPastSessionsAndAssignments({
    classroom_id: classroomId,
    practice_set_id: practiceSetId,
  });

  const {
    isLoading,
    practiceSetData,
    classroomData,
    classroomStudentsData,
    classroomAccuracyData,
    classroomPracticeSetResponses,
    practiceSetItemsData,
  } = usePracticeSetReportQueries({
    classroomId,
    practiceSetId,
    assignmentId: specificAssignment?.id,
    practiceSetSessionId: specificSession?.id,
  });

  usePageTitle([
    practiceSetData?.practice_set.title,
    classroomData?.classroom.name,
  ]);

  const { navigationData } = useNavigationData();

  const handleTabChange = (tab: PracticeSetDetailTab) => {
    switch (tab) {
      case PracticeSetDetailTab.Questions:
        history.push(
          `/t/classrooms/${classroomId}/sets/${practiceSetId}/questions`,
          {
            assignmentId: specificAssignment?.id,
            practiceSetSessionId: specificSession?.id,
          }
        );
        return;
      case PracticeSetDetailTab.StudentPerfomance:
        history.push(
          `/t/classrooms/${classroomId}/sets/${practiceSetId}/students`,
          {
            assignmentId: specificAssignment?.id,
            practiceSetSessionId: specificSession?.id,
          }
        );
        return;
      case PracticeSetDetailTab.Standards:
        history.push(
          `/t/classrooms/${classroomId}/sets/${practiceSetId}/standards`,
          {
            assignmentId: specificAssignment?.id,
            practiceSetSessionId: specificSession?.id,
          }
        );
        return;
    }
  };

  const isQuestionsTab = useRouteMatch(
    "/t/classrooms/:classroomId/sets/:practiceSetId/questions"
  );
  const isStudentsTab = useRouteMatch(
    "/t/classrooms/:classroomId/sets/:practiceSetId/students"
  );
  const isStandardsTab = useRouteMatch(
    "/t/classrooms/:classroomId/sets/:practiceSetId/standards"
  );

  const tabIndex = useMemo(() => {
    if (isQuestionsTab) {
      return PracticeSetDetailTab.Questions;
    }
    if (isStudentsTab) {
      return PracticeSetDetailTab.StudentPerfomance;
    }
    if (isStandardsTab) {
      return PracticeSetDetailTab.Standards;
    }

    return 0;
  }, [isQuestionsTab, isStudentsTab, isStandardsTab]);

  const navData = useMemo(() => {
    const breadcrumbs = [
      {
        label: t("breadcrumbClassrooms"),
        to: "/t/classrooms",
        isExternal: false,
      },
      {
        label: classroomData?.classroom.name || "",
        to: `/t/classrooms/${classroomId}`,
        isExternal: false,
      },
      {
        // This page
        label: practiceSetData?.practice_set.title || "",
        isExternal: false,
        to: `/t/classrooms/${classroomId}/sets/${practiceSetId}`,
        state: {
          assignmentId: specificAssignment?.id,
          practiceSetSessionId: specificSession?.id,
        },
      },
    ];

    return {
      ...navigationData,
      breadcrumbs,
    };
  }, [
    t,
    classroomData?.classroom.name,
    classroomId,
    practiceSetData?.practice_set.title,
    practiceSetId,
    specificAssignment?.id,
    specificSession?.id,
    navigationData,
  ]);

  const practiceSetReportData = useMemo(() => {
    if (practiceSetData?.practice_set && classroomAccuracyData) {
      // will just be one element if we provide practiceSetId
      let accuracy = 0;
      if (classroomAccuracyData.average_practice_set_accuracies.length > 0) {
        accuracy =
          classroomAccuracyData.average_practice_set_accuracies?.[0]
            .average_accuracy || 0;
      }

      return {
        practiceSet: practiceSetData.practice_set,
        accuracy: Math.round(accuracy * 100),
      };
    }
    return undefined;
  }, [practiceSetData, classroomAccuracyData]);

  return (
    <>
      <ClassroomPracticeSetReportScreen
        handleTabChange={handleTabChange}
        navigationData={navData}
        practiceSetReportData={practiceSetReportData}
        pastAssignments={pastAssignments}
        specificAssignment={specificAssignment}
        setSpecificAssignment={setSpecificAssignment}
        pastSessions={pastSessions}
        specificSession={specificSession}
        setSpecificSession={setSpecificSession}
        isLoading={isLoading}
        tabIndex={tabIndex}
        showPremiumMarker={!isFeatureEnabled("playtime.teacher.hide_premium")}
      >
        <Switch>
          <Redirect
            from="/t/classrooms/:classroomId/sets/:practiceSetId"
            exact
            to="/t/classrooms/:classroomId/sets/:practiceSetId/questions"
          />
          <>
            <Route path="/t/classrooms/:classroomId/sets/:practiceSetId/questions">
              <QuestionsTab
                classroomId={classroomId}
                practiceSetId={practiceSetId}
                classroomPracticeSetResponses={classroomPracticeSetResponses}
                classroomStudentsData={classroomStudentsData}
                practiceSetItemsData={practiceSetItemsData}
                assignmentId={specificAssignment?.id}
                practiceSetSessionId={specificSession?.id}
              />
            </Route>
            <Route path="/t/classrooms/:classroomId/sets/:practiceSetId/students">
              <StudentsTab
                classroomId={classroomId}
                practiceSetId={practiceSetId}
                classroomPracticeSetResponses={classroomPracticeSetResponses}
                classroomStudentsData={classroomStudentsData}
                practiceSetItemsData={practiceSetItemsData}
                practiceSetData={practiceSetData}
                assignmentId={specificAssignment?.id}
                practiceSetSessionId={specificSession?.id}
              />
            </Route>
            <Route path="/t/classrooms/:classroomId/sets/:practiceSetId/standards">
              <StandardsTab
                classroomId={classroomId}
                practiceSetId={practiceSetId}
                practiceSetItemsData={practiceSetItemsData}
                assignmentId={specificAssignment?.id}
                practiceSetSessionId={specificSession?.id}
              />
            </Route>
          </>
        </Switch>
      </ClassroomPracticeSetReportScreen>
    </>
  );
};
