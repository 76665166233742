import { pxToRem } from "adminComponents/utils";

export const AdminBlankStatCard = {
  baseStyle: {
    card: {
      transition: "color 250ms",
      _focusVisible: {
        color: "utility.focus",
        outline: "none",
      },
      minHeight: pxToRem(150),
      minWidth: pxToRem(150),
    },
    headerContainer: {
      width: "100%",
      justifyContent: "space-between",
      paddingRight: pxToRem(16),
      position: "absolute",
      top: pxToRem(3),
      left: pxToRem(1),
      minHeight: pxToRem(32),
    },
    body: {
      padding: pxToRem(30),
      gap: pxToRem(16),
    },
    heading: {
      zIndex: 2,
    },
    content: {
      maxWidth: "100%",
      minWidth: 0,
    },
  },
};
