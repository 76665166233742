import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  Box,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { config } from "links/lib/constants";
import { useAuth } from "links/lib/features/auth";
import AppError from "screens/AppError";

import { AvatarItemBuilder } from "./AvatarItemBuilder";
import { AvatarItem, AvatarItemBuilderData } from "./AvatarItemBuilder/types";
import { AvatarItemSorter } from "./AvatarItemSorter";

const AvatarItemManagement: React.FC = () => {
  const { authUser, authToken } = useAuth();
  const [error, setError] = useState("");

  const [itemBuilderData, setItemBuilderData] =
    useState<AvatarItemBuilderData>();

  const fetchAvatarItemBuilderData = () => {
    fetch(config.gatewayOrigin + "/v1/avatar-item-builder", {
      headers: {
        Authorization: authToken || "",
      },
    })
      .then((result) => {
        result.json().then((body) => {
          if (body.error) {
            setError(body.error);
          } else {
            setItemBuilderData(body);
          }
        });
      })
      .catch(() => {
        setError("Error fetching avatar item data");
      });
  };

  const reorderAvatarItems = (avatarItems: Array<AvatarItem>) => {
    fetch(config.gatewayOrigin + "/v1/avatar-item-builder/reorder", {
      method: "POST",
      body: JSON.stringify({
        avatar_items: avatarItems,
      }),
      headers: {
        Authorization: authToken || "",
      },
    })
      .then((result) => {
        result.json().then((body: { avatar_items: Array<AvatarItem> }) => {
          if (!itemBuilderData) return;
          setItemBuilderData({
            ...itemBuilderData,
            avatar_items: body.avatar_items,
          });
        });
      })
      .catch(() => {
        setError("Error updating avatar item category orders");
      });
  };

  useEffect(fetchAvatarItemBuilderData, [authToken]);

  if (!authUser) {
    return <AppError code={403} />;
  }

  return (
    <Box p="50px" minH="100vh" bgColor="gray.100">
      <Text fontSize="24px" mb="24px">
        Hey, {authUser.given_name}!
      </Text>

      {error && <Alert status="error">{error}</Alert>}

      {authUser && itemBuilderData && (
        <Accordion allowToggle={true}>
          <AccordionItem>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                Item Sorter
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <AvatarItemSorter
                itemBuilderData={itemBuilderData}
                reorderAvatarItems={reorderAvatarItems}
              />
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                Item Builder
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <AvatarItemBuilder itemBuilderData={itemBuilderData} />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      )}
    </Box>
  );
};

export default AvatarItemManagement;
