import { IUser } from "links/lib/types";

export const guestUserHasSignedUp = ({
  initialAuthUser,
  newAuthUser,
}: {
  initialAuthUser?: IUser;
  newAuthUser?: IUser;
}): boolean => {
  if (
    initialAuthUser &&
    initialAuthUser.is_guest &&
    !newAuthUser?.is_guest &&
    initialAuthUser.id === newAuthUser?.id
  ) {
    return true;
  }
  return false;
};
