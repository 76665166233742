import { Box, Spinner, useToken } from "@chakra-ui/react";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDebounce, useMount, useWindowSize } from "react-use";

import { Button } from "adminComponents/atoms/Button";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils/pxToRem";

/* This use Image hook was not refactored out when this was copied over
from designSystem. It appears to be working as intended. */
import useImage from "lib/hooks/useImage";

import DiagramEditorManager from "./DiagramEditorManager";

export interface IDiagramEditorProps {
  handleAddPoint: (point: IDiagramEditorPoint) => void;
  imageSrc: string;
  handleMovePoint: (index: number, point: IDiagramEditorPoint) => void;
  handleDeleteIntent: () => void;
  points: IDiagramEditorPoint[];
}

export interface IDiagramEditorPoint {
  x_offset_ratio: number;
  y_offset_ratio: number;
}

export const DiagramEditor: React.FC<IDiagramEditorProps> = ({
  handleAddPoint,
  imageSrc,
  handleMovePoint,
  handleDeleteIntent,
  points,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const managerRef = useRef<DiagramEditorManager | null>(null);
  const windowSize = useWindowSize();
  const loadImage = useImage();
  const [primaryGolden, primaryWarmBlack] = useToken("colors", [
    "primary.golden",
    "primary.warm-black",
  ]);
  const [bodyFonts] = useToken("fonts", ["adminBody"]);

  // load the src image
  useMount(() => {
    loadImage.execute({ src: imageSrc });
  });

  useEffect(
    () => {
      if (loadImage.isSuccess && loadImage.data && !managerRef.current) {
        const canvas = canvasRef.current as unknown as HTMLCanvasElement;
        const container = containerRef.current as unknown as HTMLDivElement;

        const ctx = canvas.getContext("2d");

        if (!ctx) return;

        const manager = new DiagramEditorManager(
          canvas,
          ctx,
          loadImage.data.image,
          container
        );

        manager.onRequestDiagramPointAdd((point) => {
          handleAddPoint({
            x_offset_ratio: point.x,
            y_offset_ratio: point.y,
          });
        });

        manager.onRequestDiagramPointMove((index, point) => {
          handleMovePoint(index, {
            x_offset_ratio: point.x,
            y_offset_ratio: point.y,
          });
        });

        manager.pointFillColor = primaryGolden;
        manager.pointLabelFont = bodyFonts;
        manager.pointLabelFillColor = primaryWarmBlack;

        // Add initial points from props
        manager.setDiagramPointsFromImageRatios(
          points.map((p) => {
            return {
              x: p.x_offset_ratio,
              y: p.y_offset_ratio,
            };
          })
        );

        managerRef.current = manager;
      }

      return () => {
        if (managerRef.current) {
          managerRef.current.destroy();
        }
      };
    },
    // eslint-disable-next-line
    [loadImage.isSuccess]
  );

  // when diagram editor points updated, update manager points
  useEffect(() => {
    if (!managerRef.current) return;

    const manager = managerRef.current;

    manager.setDiagramPointsFromImageRatios(
      points.map((p) => {
        return {
          x: p.x_offset_ratio,
          y: p.y_offset_ratio,
        };
      })
    );
  }, [points, points.length]);

  // resize canvas when window size has changed
  useDebounce(
    () => {
      if (!managerRef.current) return;
      managerRef.current.resetCanvasSize();
    },
    500,
    [windowSize]
  );

  const handleDeleteClick = () => {
    // eslint-disable-next-line
    const shouldDelete = confirm(t("diagramEditor.deleteConfirmation"));

    if (shouldDelete) {
      handleDeleteIntent();
    }
  };

  return (
    <Box width="full">
      <Box
        borderColor="primary.medium-gray"
        backgroundColor="primary.white"
        borderRadius={pxToRem(8)}
        borderWidth={pxToRem(1)}
        width="full"
      >
        <Box ref={containerRef} w="full" h="300px" position="relative">
          <canvas style={{ touchAction: "none" }} ref={canvasRef} />
          {loadImage.isLoading && (
            <Box
              alignItems="center"
              justifyContent="center"
              position="absolute"
              display="flex"
              top="0"
              left="0"
              bottom="0"
              right="0"
            >
              <Spinner />
            </Box>
          )}
        </Box>
      </Box>
      <Text variant="adminP2" align="right">
        <Button variant="adminTextButtonMedium" onClick={handleDeleteClick}>
          {t("diagramEditor.imageRemove")}
        </Button>
      </Text>
    </Box>
  );
};
