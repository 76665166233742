import { pxToRem } from "adminComponents/utils/pxToRem";

export const AdminTeacherLinkedAccountCard = {
  baseStyle: {
    card: {
      paddingY: { base: pxToRem(30), md: pxToRem(48) },
      backgroundColor: "primary.white",
    },
    heading: {
      marginBottom: pxToRem(4),
    },
    button: {
      maxWidth: { base: "100%", md: pxToRem(212) },
      marginTop: { base: pxToRem(20), md: pxToRem(16) },
    },
  },
};
