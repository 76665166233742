import {
  Slider,
  SliderFilledTrack,
  SliderProps,
  SliderThumb,
  SliderTrack,
} from "@chakra-ui/react";
import React from "react";

export const ControlSlider: React.FC<SliderProps> = ({ ...rest }) => {
  return (
    <Slider {...rest}>
      <SliderTrack>
        <SliderFilledTrack />
      </SliderTrack>
      <SliderThumb />
    </Slider>
  );
};
