import { Flex, FormControl, HStack, VStack } from "@chakra-ui/react";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { FormErrorMessage } from "adminComponents/atoms/FormErrorMessage";
import { FormLabel } from "adminComponents/atoms/FormLabel";
import { IconCTAButton } from "adminComponents/atoms/IconCTAButton";
import { Input } from "adminComponents/atoms/Input";
import { Text } from "adminComponents/atoms/Text";
import { CreateDiagramTabForm } from "adminComponents/molecules/CreateQuestionDiagramTab";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { MAX_DIAGRAM_LABEL_LENGTH } from "links/lib/constants";

import { IDiagramEditorLabel } from "..";

export interface IDiagramEditorLabelFieldsProps {
  labels: Array<IDiagramEditorLabel>;
  handleLabelRemove: (index: number) => void;
}

interface IDiagramEditorLabelFieldProps {
  index: number;
  handleRemove: (index: number) => void;
}

const DiagramEditorLabelField: React.FC<IDiagramEditorLabelFieldProps> = ({
  index,
  handleRemove,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<CreateDiagramTabForm>();

  const { t } = useTranslation("admin", { useSuspense: false });

  const handleRemoveClick = () => {
    handleRemove(index);
  };

  const isInvalid = !!errors.labels?.[index]?.label;

  return (
    <Flex w="full" key={index}>
      <FormControl isInvalid={isInvalid}>
        <HStack spacing={pxToRem(8)} w="full">
          <Flex
            alignItems="center"
            justifyContent="center"
            w={pxToRem(32)}
            h={pxToRem(32)}
            borderRadius="full"
            color="primary.warm-black"
            backgroundColor="primary.golden"
            flexShrink={0}
          >
            <Text variant="adminP2">{index + 1}</Text>
          </Flex>
          <Input
            {...register(`labels.${index}.label` as const, { required: true })}
            placeholder={
              t("diagramEditor.labelsPlaceholder") +
              " " +
              (index + 1).toString()
            }
            isInvalid={isInvalid}
            flex="1"
            type="text"
            maxLength={MAX_DIAGRAM_LABEL_LENGTH}
          />
          <IconCTAButton
            flexShrink={0}
            ariaLabel={t("diagramEditor.labelsRemoveButton")}
            onClick={(e) => {
              e.preventDefault();
              handleRemoveClick();
            }}
          />
        </HStack>
        <FormErrorMessage>
          {t("diagramEditor.labelsInvalidError")}
        </FormErrorMessage>
      </FormControl>
    </Flex>
  );
};

export const DiagramEditorLabelFields: React.FC<
  IDiagramEditorLabelFieldsProps
> = ({ handleLabelRemove: onLabelRemove, labels }) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  return (
    <FormControl isRequired variant="adminFormControl">
      <FormLabel htmlFor="diagramEditorLabelFields">
        {t("diagramEditor.labelsLabel")}
      </FormLabel>
      <Text variant="adminP2" color="primary.dark-gray" mb={pxToRem(8)}>
        {t("diagramEditor.labelsDescription")}
      </Text>
      <VStack w="full" spacing={pxToRem(16)} id="diagramEditorLabelFields">
        {labels.map((l, i) => {
          return (
            <DiagramEditorLabelField
              key={l.id}
              index={i}
              handleRemove={onLabelRemove}
            />
          );
        })}
      </VStack>
    </FormControl>
  );
};
