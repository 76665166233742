import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useHistory } from "react-router-dom";

import { useErrorToast } from "adminComponents/utils/toast";
import { useMutateSession } from "links/lib/features/sessions";
import {
  IAssignment,
  IPendingSession,
  IPracticeSetSession,
  OnDemandIntent,
  SessionType,
} from "links/lib/types";

export type PlaySessionArgs = {
  subjectId?: string;
  onDemandIntent?: OnDemandIntent;
  assignment?: IAssignment;
  practiceSetId: string;
  classroomId: string;
  existingSessionJoinCode?: string;
  redirectToSeasonMap?: boolean;
};

export interface IPlaySessionContext {
  handlePlaySession: (args: PlaySessionArgs) => void;
  pendingSession?: IPendingSession;
  handleSetRedirectToSeasonMap: (redirectToSeasonMap: boolean) => void;
}

export const PlaySessionContext = createContext<IPlaySessionContext>({
  handlePlaySession: () => {
    return;
  },
  handleSetRedirectToSeasonMap: () => {
    return;
  },
});

export const usePlaySession = (): IPlaySessionContext => {
  return useContext(PlaySessionContext);
};

const PlaySessionProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const [redirectToSeasonMap, setRedirectToSeasonMap] = useState(false);
  const [pendingSession, setPendingSession] = useState<
    IPendingSession | undefined
  >();

  const onSessionCreate = useCallback(
    ({
      practice_set_session,
    }: {
      practice_set_session: IPracticeSetSession;
    }) => {
      history.push(`/session/join/${practice_set_session.join_code}`, {
        redirectToSeasonMap,
        isIndividualSession:
          practice_set_session.session_type !== SessionType.Class,
      });
    },
    [history, redirectToSeasonMap]
  );

  const onSessionCreateError = () => {
    setPendingSession(undefined);
  };

  const mutateSession = useMutateSession({
    onSuccess: onSessionCreate,
    onError: onSessionCreateError,
  });

  useErrorToast(mutateSession.error);

  const handlePlaySession = useCallback(
    (args: PlaySessionArgs) => {
      const {
        subjectId,
        practiceSetId,
        assignment,
        classroomId,
        onDemandIntent,
        existingSessionJoinCode,
      } = args;

      if (!existingSessionJoinCode) {
        mutateSession.mutate({
          subjectId,
          practiceSetId,
          classroomId,
          assignmentId: assignment?.id,
          randomizeOrder: assignment ? assignment.randomize_item_order : true,
          randomizeItemChoiceOrder: true,
          generateQuestionsFromTerms: true,
          allowStudentNames: true,
          secondsPerQuestion: 0,
          startTime: new Date(),
          sessionType: SessionType.Individual,
          onDemandIntent,
        });
      } else {
        history.push(`/session/join/${existingSessionJoinCode}`, {
          redirectToSeasonMap,
          isIndividualSession: true,
        });
      }

      setPendingSession({
        subjectId,
        // if launching assignment, then don't set practice set id
        // to ensure only assignment cards show loading status
        practiceSetId: assignment ? undefined : practiceSetId,
        assignmentId: assignment ? assignment.id : undefined,
        onDemandIntent,
      });
    },
    [mutateSession, history, redirectToSeasonMap]
  );

  const handleSetRedirectToSeasonMap = useCallback(
    (redirectToSeasonMap: boolean) => {
      setRedirectToSeasonMap(redirectToSeasonMap);
    },
    []
  );

  const value = useMemo(() => {
    return {
      handlePlaySession,
      pendingSession,
      handleSetRedirectToSeasonMap,
    };
  }, [handlePlaySession, handleSetRedirectToSeasonMap, pendingSession]);

  return (
    <PlaySessionContext.Provider value={value}>
      {children}
    </PlaySessionContext.Provider>
  );
};

export { PlaySessionProvider };
