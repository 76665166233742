import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  VStack,
} from "@chakra-ui/react";
import React from "react";

import {
  IconButton,
  IProps as IconButtonProps,
} from "adminComponents/atoms/IconButton";
import { XPBoostContent } from "adminComponents/atoms/XPBoostContent";
import { pxToRem } from "adminComponents/utils";
import { UserXPMultiplier } from "links/lib/types";
import { useBoostTimer } from "sharedComponents/hooks/useBoostTimer";

export interface IProps extends Omit<IconButtonProps, "icon" | "shape"> {
  userXPMultiplier: UserXPMultiplier;
}

export const XPBoostIcon: React.FC<IProps> = ({
  ariaLabel,
  disabled,
  userXPMultiplier,
  onClick,
}) => {
  const { secondsRemaining } = useBoostTimer({
    userXPMultiplier,
  });

  if (secondsRemaining <= 0) return null;

  return (
    <Popover
      computePositionOnMount
      arrowPadding={22}
      arrowShadowColor="transparent"
      trigger="click"
      aria-live="polite"
      variant="adminOutline"
    >
      <PopoverTrigger>
        <IconButton
          ariaLabel={ariaLabel}
          colorScheme="primary.white"
          colorSchemeHover="primary.golden-hover"
          icon="xp_boost"
          shape="circle"
          variant="adminNavIconButton"
          onClick={onClick}
          disabled={disabled}
          isFullSizeIcon
        />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverBody>
          <VStack spacing={pxToRem(12)} p={pxToRem(16)}>
            <XPBoostContent
              userXPMultiplier={userXPMultiplier}
              secondsRemaining={secondsRemaining}
            />
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
