import { Box, ChakraProvider } from "@chakra-ui/react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Route, Switch, useHistory, useLocation } from "react-router";
import {
  useBeforeUnload,
  useLifecycles,
  useMount,
  usePrevious,
} from "react-use";
import { theme } from "theme";
import { clearTimeout, setTimeout } from "timers";

import { useShowToast } from "adminComponents/utils/toast";
import { sessionCodeSessionKey } from "lib/constants";
import { useAnalytics } from "lib/contexts/analytics";
import { useLogging } from "lib/contexts/logging";
import { usePageTitle } from "lib/hooks/usePageTitle";
import { config } from "links/lib/constants";
import { SessionProvider } from "links/lib/contexts/session";
import { SessionActionsProvider } from "links/lib/contexts/sessionActions";
import { SessionConnectedUsersProvider } from "links/lib/contexts/sessionConnectedUsers";
import { SessionCustomGroupsPartiallyAppliedProvider } from "links/lib/contexts/sessionCustomGroupsModified";
import { SessionEventsProvider } from "links/lib/contexts/sessionEvents";
import { SessionGameStateProvider } from "links/lib/contexts/sessionGameState";
import { SessionGroupsProvider } from "links/lib/contexts/sessionGroups";
import { SessionRoundGroupStateProvider } from "links/lib/contexts/sessionRoundGroupState";
import { SessionRoundGroupsStateProvider } from "links/lib/contexts/sessionRoundGroupsState";
import { SessionRoundStateProvider } from "links/lib/contexts/sessionRoundState";
import { SessionSceneProvider } from "links/lib/contexts/sessionScene";
import { SessionServerTimeDeltasProvider } from "links/lib/contexts/sessionServerTimeDeltas";
import { SessionTeachersProvider } from "links/lib/contexts/sessionTeachers";
import { SessionUsersProvider } from "links/lib/contexts/sessionUsers";
import { useAuth, useCreateGuest } from "links/lib/features/auth";
import { useSessionWebSocket } from "links/lib/features/sessions";
import {
  AnalyticsEvent,
  BrowserLogMessage,
  BrowserLogStatus,
  IUser,
  SessionScene,
  SessionType,
  SessionWebSocketEvent,
  UserRole,
} from "links/lib/types";
import AppSpinner from "screens/App/components/AppSpinner";
import { BreakpointsProvider } from "sessionComponents/contexts/breakpoints";
import { LazyRoundTimeProvider } from "sessionComponents/contexts/roundTime";
import { SessionDeveloperTools } from "sessionComponents/molecules/SessionDeveloperTools";
import { SessionJoin } from "sessionComponents/scenes/SessionJoin";
import "sharedComponents/css/ios-full-height.css";
import { guestUserHasSignedUp } from "sharedComponents/utils/signinUtils";

import { StudentView } from "./ClassSession/StudentView";
import { TeacherView } from "./ClassSession/TeacherView";
import { IndividualSessionView } from "./IndividualSession";
import { TeacherPreviewSessionView } from "./TeacherPreviewSession";

const MIN_RECONNECT_DELAY_FOR_TOASTS_MS = 3000;

const getSessionCode = (location: Location): string => {
  if (location.pathname.startsWith("/session/join/")) {
    const pathParts = location.pathname.split("/");
    if (pathParts.length === 4) {
      return pathParts[3];
    }
  }

  const code = sessionStorage.getItem(sessionCodeSessionKey);
  if (code) {
    return code;
  }

  return "";
};

export const SessionWrapper: React.FC = () => {
  const { authUser, isFeatureEnabled, authToken, isAuthLoading, refetchUser } =
    useAuth();
  const { trackEvent } = useAnalytics();
  const location = useLocation();

  const createGuest = useCreateGuest({
    onError: (err) => {
      // TODO: What should happen in this case?
      console.error("Error creating guest user", err);
    },
    onSuccess: () => {
      trackEvent(
        AnalyticsEvent.Common_GuestCreated,
        {
          location: location.pathname,
        },
        { queueEvent: true }
      );
    },
  });

  const createGuestUserCompleteOrInFlight =
    createGuest.isLoading || createGuest.isSuccess || createGuest.isError;

  useEffect(() => {
    if (
      (!authUser || authUser?.is_ephemeral) &&
      !isAuthLoading &&
      !createGuestUserCompleteOrInFlight
    ) {
      createGuest.mutate({ use_uninitialized_role: true });
    }
  }, [
    authToken,
    authUser,
    createGuest,
    createGuestUserCompleteOrInFlight,
    isAuthLoading,
  ]);

  if (authUser && !authUser.is_ephemeral && authToken) {
    return (
      <Session
        authUser={authUser}
        refetchUser={refetchUser}
        authToken={authToken}
        isFeatureEnabled={isFeatureEnabled}
      />
    );
  }

  return <AppSpinner />;
};

const Session: React.FC<{
  authUser: IUser;
  refetchUser: () => void;
  authToken: string;
  isFeatureEnabled: (flag: string) => boolean;
}> = ({ authUser, refetchUser, authToken, isFeatureEnabled }) => {
  const history = useHistory();
  const location = useLocation() as unknown as Location;
  const showToast = useShowToast();
  const [hasInitConnect, setHasInitConnect] = useState(false);
  const { t } = useTranslation("session", {
    keyPrefix: "root",
    useSuspense: false,
  });
  const analytics = useAnalytics();
  const { log } = useLogging();
  const reconnectToastTimer = useRef<NodeJS.Timer>();
  const disconnectTime = useRef<number>(0);

  const [disableUnloadWarning, setDisableUnloadWarning] = useState(false);

  usePageTitle(t("pageTitle"));

  const code = getSessionCode(location);
  const ws = useSessionWebSocket({
    authToken,
    wsOrigin: config.gatewayWebSocketOrigin,
    sessionCode: code,
  });

  useMount(() => {
    // The teacher has re-loaded the page and/or navigated back to the session after completing it.
    // The Podium scene removes the session code from sessionStorage so that this tab could
    // be used to connect to a new or different session when the first completes.
    if (location.hash === "#podium" && !code) {
      history.push("/");
    }
  });

  const prevAuthToken = usePrevious(authToken);
  useEffect(() => {
    // True if a guest user signs in with credentials that don't yet exist in Pear Practice.
    // In that case, the anonymous guest user is upgraded in-place -- authToken will change
    // but the authUser's ID will remain the same.
    if (prevAuthToken && prevAuthToken !== authToken) {
      ws.joinSession(code);
    }
  }, [authToken, code, prevAuthToken, ws]);

  const prevAuthUser = usePrevious(authUser);
  useEffect(() => {
    if (prevAuthUser && prevAuthUser.id !== authUser.id) {
      // Check for guest users signing in to existing accounts. In this
      // case, we need to instruct the session to replace the guest user with
      // the true user. In the event that a guest user signs in, but doesn't
      // have an existing account, the guest user will be upgraded and we
      // can continue using the same user object.
      analytics.trackEvent(
        AnalyticsEvent.Session_StudentLobby_Signin_Complete,
        {}
      );
      const onReconnect = () => {
        ws.joinSession(code, prevAuthUser.id);
      };
      ws.reconnect(onReconnect);
    } else if (
      guestUserHasSignedUp({
        initialAuthUser: prevAuthUser,
        newAuthUser: authUser,
      })
    ) {
      analytics.trackEvent(
        AnalyticsEvent.Session_StudentLobby_Signup_Complete,
        {}
      );
    }
  }, [authToken, authUser, code, prevAuthUser, ws, analytics]);

  const trackEvent = useCallback(
    (name: string, props: Record<string, unknown>) => {
      const { session, scene, round_state } = ws.state;

      analytics.trackEvent(name, {
        session_id: session?.id,
        classroom_id: session?.group_id,
        practice_set_id: session?.practice_set_id,
        scene,
        round_number: round_state?.round_number,
        ...props,
      });
    },
    [analytics, ws.state]
  );

  const onSocketError = useCallback(
    (error: Error) => {
      // if the socket is disconnected, then hide error toasts
      if (!ws.isConnected) return;

      trackEvent(AnalyticsEvent.Session_Common_WebSocketError, {});
      log(
        BrowserLogMessage.SessionWebSocketError,
        { error },
        BrowserLogStatus.Error
      );

      showToast(error.message);
    },
    [log, showToast, trackEvent, ws.isConnected]
  );

  const onSocketConnect = () => {
    const code = getSessionCode(location);
    if (code) {
      ws.joinSession(code);
    }
  };

  // rejoin session when reconnecting
  const onSocketReconnect = useCallback(() => {
    const shouldShowToast =
      performance.now() - disconnectTime.current >=
      MIN_RECONNECT_DELAY_FOR_TOASTS_MS;
    if (shouldShowToast) {
      showToast(t("reconnectMessage"));
    } else {
      if (reconnectToastTimer.current) {
        clearTimeout(reconnectToastTimer.current);
      }
    }

    trackEvent(AnalyticsEvent.Session_Common_WebSocketReconnect, {});
    log(BrowserLogMessage.SessionWebSocketReconnect, {}, BrowserLogStatus.Info);

    const code = getSessionCode(location);
    if (code) {
      ws.joinSession(code);
    }
  }, [trackEvent, log, location, showToast, t, ws]);

  const onSocketDisconnect = useCallback(() => {
    // Add small delay before showing disconnect toast in case we reconnect
    disconnectTime.current = performance.now();
    reconnectToastTimer.current = setTimeout(() => {
      showToast(t("disconnectError"));
    }, MIN_RECONNECT_DELAY_FOR_TOASTS_MS);

    trackEvent(AnalyticsEvent.Session_Common_WebSocketDisconnect, {});
    log(
      BrowserLogMessage.SessionWebSocketDisconnect,
      {},
      BrowserLogStatus.Error
    );
  }, [log, showToast, t, trackEvent]);

  const { state } = ws;

  useLifecycles(
    () => {
      ws.events.on(SessionWebSocketEvent.Error, onSocketError);
      ws.events.on(SessionWebSocketEvent.Connect, onSocketConnect);
      ws.events.on(SessionWebSocketEvent.Reconnect, onSocketReconnect);
    },
    () => {
      ws.events.off(SessionWebSocketEvent.Error, onSocketError);
      ws.events.off(SessionWebSocketEvent.Connect, onSocketConnect);
      ws.events.off(SessionWebSocketEvent.Reconnect, onSocketReconnect);
    }
  );

  const connectRef = useRef(ws.connect);

  // Connect if haven't connected yet
  useEffect(() => {
    if (!hasInitConnect) {
      connectRef.current();
    }
  }, [hasInitConnect]);

  // Store joined session code
  useEffect(() => {
    if (state.session) {
      sessionStorage.setItem(sessionCodeSessionKey, state.session.code);
    }
  }, [state.session]);

  // Show error when disconnect detected after initial connection
  useEffect(() => {
    if (!hasInitConnect && ws.isConnected) {
      setHasInitConnect(true);
      return;
    }

    if (hasInitConnect && !ws.isConnected) {
      onSocketDisconnect();
    }
  }, [ws.isConnected, hasInitConnect, onSocketDisconnect]);

  // Show alert to users when attempting to leave active session
  const shouldWarnOnUnloadFn = useCallback(() => {
    if (disableUnloadWarning) {
      return false;
    }

    // Don't show for teacher preview sessions
    if (ws.state.session?.session_type === SessionType.TeacherPreview) {
      return false;
    }

    return ws.state.scene !== SessionScene.Podium;
  }, [ws.state.scene, ws.state.session, disableUnloadWarning]);

  const sessionActions = useMemo(() => {
    return {
      avatarUpdated: ws.avatarUpdated,
      changeDrawColor: ws.changeDrawColor,
      changeGroupName: ws.changeGroupName,
      shuffleGuestName: ws.shuffleGuestName,
      confirmAnswer: ws.confirmAnswer,
      initiateGame: ws.initiateGame,
      initiateGrouping: ws.initiateGrouping,
      initiateLeaderBoard: ws.initiateLeaderBoard,
      initiateNextRound: ws.initiateNextRound,
      initiatePriorRound: ws.initiatePriorRound,
      initiateOuterGameIntro: ws.initiateOuterGameIntro,
      initiateOuterGame: ws.initiateOuterGame,
      initiatePodium: ws.initiatePodium,
      initiatePrizeRound: ws.initiatePrizeRound,
      initiatePrizeRoundAwards: ws.initiatePrizeRoundAwards,
      initiateRoundReview: ws.initiateRoundReview,
      initiateVoting: ws.initiateVoting,
      initiateVotingResults: ws.initiateVotingResults,
      initiateVotingAwards: ws.initiateVotingAwards,
      placeTheBigBoardToken: ws.placeTheBigBoardToken,
      setChoice: ws.setChoice,
      sendChatMessage: ws.sendChatMessage,
      sendCursor: ws.sendCursor,
      sendDiagramLabelDrag: ws.sendDiagramLabelDrag,
      sendDrawStroke: ws.sendDrawStroke,
      sendDrawImageUrl: ws.sendDrawImageUrl,
      sendReaction: ws.sendReaction,
      sendSortChoiceDrag: ws.sendSortChoiceDrag,
      sendTextPartDrag: ws.sendTextPartDrag,
      sendTextResponse: ws.sendTextResponse,
      setDiagramLabelZone: ws.setDiagramLabelZone,
      setSortChoiceZone: ws.setSortChoiceZone,
      setTextPartZone: ws.setTextPartZone,
      stopTheBigBoardScanning: ws.stopTheBigBoardScanning,
      setVote: ws.setVote,
      setDrawingHidden: ws.setDrawingHidden,
      sendActiveTyping: ws.sendActiveTyping,
      undoDrawStroke: ws.undoDrawStroke,
      extendRoundTime: ws.extendRoundTime,
      exitGame: ws.exitGame,
    };
  }, [
    ws.avatarUpdated,
    ws.changeDrawColor,
    ws.changeGroupName,
    ws.shuffleGuestName,
    ws.confirmAnswer,
    ws.initiateGame,
    ws.initiateGrouping,
    ws.initiateLeaderBoard,
    ws.initiateOuterGameIntro,
    ws.initiateNextRound,
    ws.initiatePriorRound,
    ws.initiateOuterGame,
    ws.initiatePodium,
    ws.initiatePrizeRound,
    ws.initiatePrizeRoundAwards,
    ws.initiateRoundReview,
    ws.initiateVoting,
    ws.initiateVotingAwards,
    ws.initiateVotingResults,
    ws.placeTheBigBoardToken,
    ws.sendCursor,
    ws.sendChatMessage,
    ws.sendDiagramLabelDrag,
    ws.sendDrawStroke,
    ws.sendDrawImageUrl,
    ws.sendReaction,
    ws.sendSortChoiceDrag,
    ws.sendTextPartDrag,
    ws.sendTextResponse,
    ws.setChoice,
    ws.setDiagramLabelZone,
    ws.setSortChoiceZone,
    ws.setVote,
    ws.setDrawingHidden,
    ws.setTextPartZone,
    ws.stopTheBigBoardScanning,
    ws.sendActiveTyping,
    ws.undoDrawStroke,
    ws.extendRoundTime,
    ws.exitGame,
  ]);

  // Message param is required by chrome, but doesn't show in browser
  useBeforeUnload(shouldWarnOnUnloadFn, "Are you sure you want to leave?");

  const sessionRole =
    authUser.role === UserRole.Student
      ? ws.state.users[authUser.id]?.role
      : ws.state.teachers[authUser.id]?.role || // Teachers && Content Specialists
        UserRole.Student; // Fail-safe

  return (
    <ChakraProvider theme={theme}>
      <BreakpointsProvider>
        <Box role="main" h="full" w="full" bgColor="primary.warm-white">
          <Switch>
            <Route path="/session/join/:code?" exact={false}>
              <SessionJoin
                authUser={authUser}
                disableUnloadWarning={() => setDisableUnloadWarning(true)}
                error={ws.state.error}
                errorCode={ws.state.error_code}
                joinSession={ws.joinSession}
                joinCode={code}
                session={ws.state.session}
              />
            </Route>
            <Route path="/session" exact={true}>
              <SessionProvider wsSession={ws.state.session}>
                <SessionActionsProvider wsActions={sessionActions}>
                  <SessionEventsProvider wsEvents={ws.events}>
                    <SessionRoundStateProvider
                      wsRoundState={ws.state.round_state}
                    >
                      <SessionGameStateProvider
                        wsGameState={ws.state.game_state}
                      >
                        <SessionRoundGroupStateProvider
                          wsRoundGroupState={ws.state.round_group_state}
                        >
                          <SessionCustomGroupsPartiallyAppliedProvider
                            customGroupsPartiallyApplied={
                              ws.state.custom_groups_partially_applied
                            }
                          >
                            <SessionGroupsProvider
                              wsStateGroups={ws.state.groups}
                            >
                              <SessionUsersProvider
                                wsStateUsers={ws.state.users}
                              >
                                <SessionConnectedUsersProvider
                                  wsStateUsers={ws.state.users}
                                >
                                  <SessionSceneProvider
                                    wsSessionScene={ws.state.scene}
                                  >
                                    <SessionServerTimeDeltasProvider
                                      wsServerTimeDeltas={
                                        ws.state.server_time_deltas
                                      }
                                    >
                                      <LazyRoundTimeProvider>
                                        {!!ws.state.session && (
                                          <>
                                            {isFeatureEnabled(
                                              "playtime.session.can_put_session_state"
                                            ) && (
                                              <SessionDeveloperTools
                                                authUser={authUser}
                                                state={ws.state}
                                                putSessionState={
                                                  ws.putSessionState
                                                }
                                              />
                                            )}

                                            {ws.state.session.session_type ===
                                              SessionType.TeacherPreview && (
                                              <TeacherPreviewSessionView
                                                authUser={authUser}
                                              />
                                            )}

                                            {ws.state.session.session_type ===
                                              SessionType.Individual && (
                                              <IndividualSessionView
                                                authUser={authUser}
                                                disableUnloadWarning={() =>
                                                  setDisableUnloadWarning(true)
                                                }
                                              />
                                            )}
                                            {ws.state.session.session_type ===
                                              SessionType.Class && (
                                              <>
                                                {sessionRole ==
                                                UserRole.Teacher ? (
                                                  <SessionRoundGroupsStateProvider
                                                    wsRoundGroupsState={
                                                      ws.state
                                                        .round_groups_state
                                                    }
                                                  >
                                                    <TeacherView
                                                      authUser={authUser}
                                                    />
                                                  </SessionRoundGroupsStateProvider>
                                                ) : (
                                                  <SessionTeachersProvider
                                                    wsStateTeachers={
                                                      ws.state.teachers
                                                    }
                                                  >
                                                    <StudentView
                                                      authUser={authUser}
                                                      refetchUser={refetchUser}
                                                      enableUnloadWarning={() =>
                                                        setDisableUnloadWarning(
                                                          false
                                                        )
                                                      }
                                                      disableUnloadWarning={() =>
                                                        setDisableUnloadWarning(
                                                          true
                                                        )
                                                      }
                                                    />
                                                  </SessionTeachersProvider>
                                                )}
                                              </>
                                            )}
                                          </>
                                        )}
                                      </LazyRoundTimeProvider>
                                    </SessionServerTimeDeltasProvider>
                                  </SessionSceneProvider>
                                </SessionConnectedUsersProvider>
                              </SessionUsersProvider>
                            </SessionGroupsProvider>
                          </SessionCustomGroupsPartiallyAppliedProvider>
                        </SessionRoundGroupStateProvider>
                      </SessionGameStateProvider>
                    </SessionRoundStateProvider>
                  </SessionEventsProvider>
                </SessionActionsProvider>
              </SessionProvider>
            </Route>
          </Switch>
        </Box>
      </BreakpointsProvider>
    </ChakraProvider>
  );
};
