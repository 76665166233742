import { useBreakpointValue } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import { ShareAvatarModal } from "adminComponents/organisms/ShareAvatarModal";
import { TeacherMyAvatarScreen } from "adminComponents/screens/TeacherMyAvatarScreen";
import { pxToRem } from "adminComponents/utils";
import { useAnalytics, usePageTrack } from "lib/contexts/analytics";
import {
  CameraTypes,
  useLockerAvatarCamera,
} from "lib/hooks/useLockerAvatarCamera";
import { usePageTitle } from "lib/hooks/usePageTitle";
import {
  downloadBlob,
  shareBlobWithNavigator,
  shareToFacebook,
  shareToLinkedIn,
  shareToX,
} from "lib/shareUtils";
import { useAuth } from "links/lib/features/auth";
import { useFetchAvatar } from "links/lib/features/avatars";
import {
  AnalyticsEvent,
  AnalyticsPage,
  AvatarSkeletonType,
  IFetchAvatarResponse,
  SocialMediaShareProvider,
} from "links/lib/types";
import { useNavigationData } from "screens/TeacherDashboard/contexts/TeacherNavigationDataProvider";
import { useEditAvatar } from "sharedComponents/hooks/useEditAvatar";
import {
  AvatarStage,
  AvatarStageMember,
} from "sharedComponents/molecules/AvatarStage";

export interface IAvatarAnimation {
  name: string;
  loop: boolean;
}

const lockerCameraStanding = { ...CameraTypes.StandingDefault, zoom: 0.8 };
const lockerCameraSeated = { ...CameraTypes.SeatedDefault, zoom: 0.8 };

export const TeacherMyAvatar: React.FC = () => {
  const { authUser } = useAuth();
  const { trackEvent } = useAnalytics();

  const { data: avatarResponse, isLoading } = useFetchAvatar({
    include_items: true,
  });
  const { navigationData } = useNavigationData();
  const history = useHistory();
  const { mode } = useParams<{ mode?: string }>();
  const showAvatarShareModal = mode === "share";
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });

  usePageTrack(AnalyticsPage.TeacherDashboard_MyAvatar);
  usePageTitle(t("teacherMyAvatarContainer.pageTitle"));

  const { modal, profileImageCapture, onOpen } = useEditAvatar({
    onEquipAvatarItem: (avatarItemId, categoryId, colorHex) => {
      const color = avatarResponse?.avatar?.items
        .find((item) => item.id === avatarItemId)
        ?.colors.find((color) => color.hex_code === colorHex);

      trackEvent(AnalyticsEvent.TeacherDashboard_MyAvatar_Common_EquipItem, {
        itemId: avatarItemId,
        itemCategoryId: categoryId,
        itemColorId: color?.id,
        itemColorHexCode: colorHex,
      });
    },
    onDequipAvatarItem: (avatarItemId, categoryId) => {
      if (!categoryId) return;

      trackEvent(
        AnalyticsEvent.TeacherDashboard_MyAvatar_Common_UnsetCategory,
        {
          itemCategoryId: categoryId,
        }
      );
    },
    onSetSkeletonType: (skeletonType) => {
      trackEvent(
        AnalyticsEvent.TeacherDashboard_MyAvatar_Common_SetSkeletonType,
        {
          skeletonType: skeletonType,
        }
      );
    },
    onSetHairColor: (hairColorHexCode) => {
      trackEvent(AnalyticsEvent.TeacherDashboard_MyAvatar_Common_SetHairColor, {
        colorHexCode: hairColorHexCode,
      });
    },
    onSetSkinTone: (skinToneHexCode) => {
      trackEvent(AnalyticsEvent.TeacherDashboard_MyAvatar_Common_SetSkinTone, {
        toneHexCode: skinToneHexCode,
      });
    },
  });

  const handleEditAvatar = () => {
    history.push("/t/my-avatar/edit");
    onOpen();
  };

  const handleShareAvatar = () => {
    history.push("/t/my-avatar/share");
  };

  const handleCloseModals = () => {
    history.push("/t/my-avatar");
  };

  const handleAccountClick = () => {
    history.push("/t/account");
  };

  const handleProfileClick = () => {
    history.push(`/t/profiles/${authUser?.custom_url_code}`);
  };

  const avatarPhotoFilename = `${t("common.avatar")}.png`;

  const handleDownload = (blobUrl: string) => {
    downloadBlob(blobUrl, avatarPhotoFilename);

    trackEvent(
      AnalyticsEvent.TeacherDashboard_MyAvatar_ShareModal_DownloadImage,
      {}
    );
  };

  const handleShare = (provider: SocialMediaShareProvider, blobUrl: string) => {
    const shareUrl = "https://www.peardeck.com/products/pear-practice";
    const shareTitle = t("teacherMyAvatarContainer.shareTitle");
    const shareText = t("teacherMyAvatarContainer.shareText");

    if (provider === SocialMediaShareProvider.Navigator) {
      shareBlobWithNavigator(
        blobUrl,
        avatarPhotoFilename,
        shareTitle,
        shareText,
        shareUrl
      );
    } else if (provider === SocialMediaShareProvider.X) {
      shareToX(shareText, ["PlayPearPractice"]);
    } else if (provider === SocialMediaShareProvider.LinkedIn) {
      shareToLinkedIn(shareUrl);
    } else if (provider === SocialMediaShareProvider.Facebook) {
      shareToFacebook(shareUrl);
    }

    trackEvent(AnalyticsEvent.TeacherDashboard_MyAvatar_ShareModal_Share, {
      provider,
    });
  };

  const avatarStageSize =
    useBreakpointValue({ base: 164, md: 256, lg: 516 }) || 516;

  const isAvatarSeated =
    avatarResponse?.avatar?.skeleton_type === AvatarSkeletonType.Seated;

  const modalStageCamera = useLockerAvatarCamera({
    isSeated: isAvatarSeated,
    editSectionId: "",
  });

  const avatarAnimation = useMemo(() => ({ name: "idle", loop: true }), []);

  // authUser is validated in TeacherDashboard, this is just
  // for the benefit of TypeScript
  if (!authUser) return <></>;

  const getAvatarPreviewState = (
    avatarResponse: IFetchAvatarResponse,
    isModalPreview = true
  ) => {
    const remAvatarStageSize = pxToRem(avatarStageSize);
    const remAvatarStageOffset = pxToRem(-avatarStageSize / 2);

    const baseStyle = {
      width: remAvatarStageSize,
      maxWidth: remAvatarStageSize,
      maxHeight: remAvatarStageSize,
      background: "white",
    };

    const modalStyle = {
      position: "absolute",
      top: "0",
      left: "0",
      marginLeft: "50%",
      marginTop: "50%",
      minWidth: remAvatarStageSize,
      height: remAvatarStageSize,
      transform: `translateX(${remAvatarStageOffset}) translateY(${remAvatarStageOffset})`,
    };

    const style = isModalPreview ? { ...baseStyle, ...modalStyle } : baseStyle;
    const camera = isModalPreview
      ? modalStageCamera
      : isAvatarSeated
      ? lockerCameraSeated
      : lockerCameraStanding;

    if (!avatarResponse.spine_atlas_url || !avatarResponse.spine_json_url)
      return <></>;

    return (
      <AvatarStage
        camera={camera}
        alignX="center"
        alignY="center"
        style={style}
        stageSize={{ x: 600, y: 600 }}
      >
        <AvatarStageMember
          key={avatarResponse.spine_atlas_url}
          animation={avatarAnimation}
          scale={0.4}
          x={0}
          y={-160}
          atlasUrl={avatarResponse.spine_atlas_url}
          skeletonUrl={avatarResponse.spine_json_url}
        />
      </AvatarStage>
    );
  };

  return (
    <>
      <TeacherMyAvatarScreen
        avatarStage={
          !!avatarResponse && getAvatarPreviewState(avatarResponse, false)
        }
        navData={navigationData}
        isLoading={isLoading}
        user={authUser}
        handleAccountClick={handleAccountClick}
        handleAvatarEdit={handleEditAvatar}
        handleAvatarShare={handleShareAvatar}
        handleProfileClick={handleProfileClick}
      />

      {modal}

      {!!avatarResponse &&
        avatarResponse.spine_atlas_url &&
        avatarResponse.spine_json_url && (
          <ShareAvatarModal
            atlasUrl={avatarResponse.spine_atlas_url}
            skeletonUrl={avatarResponse.spine_json_url}
            handleClose={handleCloseModals}
            isOpen={showAvatarShareModal}
            showShareProviders
            handleDownload={handleDownload}
            handleShare={handleShare}
          />
        )}

      {profileImageCapture}
    </>
  );
};
