import React, { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  FolderMoveModal,
  NEW_FOLDER,
} from "adminComponents/molecules/FolderMoveModal";
import { getPracticeSetUrl } from "adminComponents/molecules/LibraryPracticeSetCard";
import { PracticeSetDeleteModal } from "adminComponents/molecules/PracticeSetDeleteModal";
import {
  ToastId,
  useErrorToast,
  usePendingToast,
  useShowToast,
} from "adminComponents/utils/toast";
import { useAnalytics } from "lib/contexts/analytics";
import { useAuth } from "links/lib/features/auth";
import { useFetchClassrooms } from "links/lib/features/classrooms";
import { useMutateCollection } from "links/lib/features/collections";
import {
  useCopyPracticeSet,
  useDeletePracticeSet,
  useMutatePracticeSetCollection,
} from "links/lib/features/practiceSets";
import { ICopyPracticeSetResponse } from "links/lib/features/practiceSets/useCopyPracticeSet";
import { AnalyticsEvent, ICollection, IPracticeSet } from "links/lib/types";

export interface IArgs {
  collections: Array<ICollection>;
  onDeleteSuccess?: () => void;
}

export interface IResult {
  handleDelete: (practiceSet: IPracticeSet) => void;
  handleMoveToFolder: (practiceSet: IPracticeSet) => void;
  handleDuplicate: (practiceSet: IPracticeSet) => void;
  components: React.ReactElement;
}

interface IDeleteState {
  practiceSet?: IPracticeSet;
  assignedGroupIds?: string[];
  isOpen: boolean;
}

interface IMoveState {
  practiceSet?: IPracticeSet;
  isOpen: boolean;
}

export const usePracticeSetHandlers = ({
  collections,
  onDeleteSuccess,
}: IArgs): IResult => {
  const [deleteState, setDeleteState] = useState<IDeleteState>({
    isOpen: false,
  });
  const [moveState, setMoveState] = useState<IMoveState>({
    isOpen: false,
  });
  const { authUser } = useAuth();
  const history = useHistory();
  const { showPendingToast, closePendingToast } = usePendingToast();
  const duplicatePendingToastId = useRef<ToastId | undefined>();
  const showToast = useShowToast();
  const { t } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "myLibraryContainer.practiceSetHandlers",
  });
  const { t: tCommon } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "common",
  });

  const fetchClassrooms = useFetchClassrooms({});
  useErrorToast(fetchClassrooms.error);

  const fetchArchivedClassrooms = useFetchClassrooms({ archived: true });
  useErrorToast(fetchArchivedClassrooms.error);

  const { trackEvent } = useAnalytics();

  const assignedClassrooms = useMemo(() => {
    return (deleteState.assignedGroupIds ?? []).flatMap((id) => {
      const combinedClassrooms = [
        ...(fetchClassrooms.data?.classrooms || []),
        ...(fetchArchivedClassrooms.data?.classrooms || []),
      ];

      const classroom = combinedClassrooms.find(
        (classroom) => classroom.id === id
      );

      return classroom ? [classroom] : [];
    });
  }, [deleteState, fetchClassrooms.data, fetchArchivedClassrooms.data]);

  const onDuplicateSuccess = (data: ICopyPracticeSetResponse) => {
    showToast(t("duplicateSuccessToast"));
    history.push(getPracticeSetUrl(data.practice_set, authUser));
  };

  const onDuplicateSettled = () => {
    closePendingToast(duplicatePendingToastId.current);
    duplicatePendingToastId.current = undefined;
  };

  const onMoveSuccess = () => {
    showToast(t("moveSuccessToast"));
    setMoveState({ isOpen: false });
  };

  const _onDeleteSuccess = () => {
    setDeleteState({ isOpen: false });
    showToast(t("deleteSuccessToast"));
    onDeleteSuccess?.();
  };

  // eslint-disable-next-line
  const onDeleteError = (err: any) => {
    const assignedGroupIds: string[] =
      (err.response?.data?.details ?? [])[0]?.assigned_group_ids || [];
    setDeleteState({ isOpen: true, assignedGroupIds });
    if (!assignedGroupIds.length) {
      showToast(`${tCommon("error")}: ${err.message}`);
    }
  };

  const deletePracticeSet = useDeletePracticeSet({
    onSuccess: _onDeleteSuccess,
    onError: onDeleteError,
  });
  const mutatePracticeSetCollection = useMutatePracticeSetCollection({
    onSuccess: onMoveSuccess,
  });
  useErrorToast(mutatePracticeSetCollection.error);

  const duplicatePracticeSet = useCopyPracticeSet({
    onSuccess: onDuplicateSuccess,
    onSettled: onDuplicateSettled,
  });
  useErrorToast(duplicatePracticeSet.error);

  const mutateCollection = useMutateCollection({});
  useErrorToast(mutateCollection.error);

  const handleDelete = (practiceSet: IPracticeSet) => {
    setDeleteState({ isOpen: true, practiceSet });

    trackEvent(
      AnalyticsEvent.TeacherDashboard_MyLibrary_PracticeSetActions_DeletePracticeSetIntent,
      {
        practiceSetId: practiceSet.id,
      }
    );
  };

  const handleDeleteConfirm = (practiceSet: IPracticeSet) => {
    if (!practiceSet.id) return;

    deletePracticeSet.mutate({
      id: practiceSet.id,
      collection_id: practiceSet.collection_id,
    });

    trackEvent(
      AnalyticsEvent.TeacherDashboard_MyLibrary_PracticeSetActions_DeletePracticeSetConfirm,
      {
        practiceSetId: practiceSet.id,
      }
    );
  };

  const handleDeleteClose = () => {
    setDeleteState({ isOpen: false });
  };

  const handleMoveConfirm = async (
    practiceSetId: string,
    collection: ICollection
  ) => {
    if (!moveState.practiceSet) return;

    let collectionId = collection.id;

    if (collection.id === "-1") {
      try {
        collectionId = (
          await mutateCollection.mutateAsync({
            name: collection.name,
            description: collection.description,
          })
        ).collection.id;
      } catch (err) {
        console.error(err);
        showToast(t("moveCreateFolderErrorToast"));
      }
    }

    mutatePracticeSetCollection.mutate({
      practice_set_id: practiceSetId,
      collection_id: collectionId,
    });

    trackEvent(
      AnalyticsEvent.TeacherDashboard_MyLibrary_PracticeSetActions_MovePracticeSetConfirm,
      {
        practiceSetId: moveState.practiceSet.id,
        collectionId: collection.id,
      }
    );
  };

  const handleMoveClose = () => {
    setMoveState({ isOpen: false });
  };

  const handleMoveToFolder = (practiceSet: IPracticeSet) => {
    setMoveState({ isOpen: true, practiceSet });

    trackEvent(
      AnalyticsEvent.TeacherDashboard_MyLibrary_PracticeSetActions_MovePracticeSetIntent,
      {
        practiceSetId: practiceSet.id,
      }
    );
  };

  const handleDuplicate = (practiceSet: IPracticeSet) => {
    duplicatePracticeSet.mutate({
      id: practiceSet.id,
      collection_id: practiceSet.collection_id,
    });

    closePendingToast(duplicatePendingToastId.current);
    duplicatePendingToastId.current = showPendingToast(
      t("duplicatePendingToast")
    );

    trackEvent(
      AnalyticsEvent.TeacherDashboard_MyLibrary_PracticeSetActions_DuplicatePracticeSetConfirm,
      {
        practiceSetId: practiceSet.id,
      }
    );
  };

  const noFolder: ICollection = {
    id: "0",
    name: t("noFolderOption"),
    created_at: "",
    created_by: "",
    updated_at: "",
    deleted_at: "",
    practice_set_count: 0,
  };

  const moveToFolderOptions = [];
  if (moveState.practiceSet?.collection_id !== "0") {
    moveToFolderOptions.push(noFolder);
  }
  moveToFolderOptions.push(...collections);

  const components = (
    <>
      <PracticeSetDeleteModal
        isOpen={deleteState.isOpen}
        practiceSet={deleteState.practiceSet}
        assignedClassrooms={assignedClassrooms}
        handleDelete={handleDeleteConfirm}
        handleClose={handleDeleteClose}
        isLoading={deletePracticeSet.isLoading}
      />
      <FolderMoveModal
        isLoading={mutatePracticeSetCollection.isLoading}
        isOpen={moveState.isOpen}
        practiceSetId={moveState.practiceSet?.id ?? ""}
        currentValue={collections.find(
          (c) => c.id === moveState.practiceSet?.collection_id
        )}
        initialValue={
          collections.find(
            (c) => c.id === moveState.practiceSet?.collection_id
          ) ??
          moveToFolderOptions[0] ??
          NEW_FOLDER
        }
        folders={moveToFolderOptions}
        handleClose={handleMoveClose}
        handleSave={handleMoveConfirm}
      />
    </>
  );

  return {
    handleDelete,
    handleMoveToFolder,
    handleDuplicate,
    components,
  };
};
