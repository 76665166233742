import { useMemo } from "react";
import { useQueries } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { IUser, UsersGroupsRole } from "../../types";
import { fetchClassroomUsersQuery } from "./useFetchClassroomUsers";

export interface IUseFetchClassroomUsersBulkProps {
  classroom_ids?: Array<string>;
  group_roles?: Array<UsersGroupsRole>;
  disabled?: boolean;
  onSuccess?: (data: { [classroom_id: string]: Array<IUser> }) => void;
}

export interface IUseFetchClassroomUsersBulkResult {
  isLoading: boolean;
  classroomData: { [classroom_id: string]: Array<IUser> };
}

const useFetchClassroomUsersBulk = ({
  classroom_ids = [],
  group_roles,
  disabled = false,
  onSuccess,
}: IUseFetchClassroomUsersBulkProps): IUseFetchClassroomUsersBulkResult => {
  const axios = useAxios();

  const classroomQueries = useQueries(
    classroom_ids.map((classroom_id) => {
      const params = {
        classroom_id,
        disabled,
        group_roles,
      };

      return {
        queryKey: ["classroom-users", { id: classroom_id, group_roles }],
        queryFn: fetchClassroomUsersQuery(axios, params),
        enabled: !disabled,
      };
    })
  );

  const classroomData = useMemo(() => {
    const studentsByClassroom: { [key: string]: Array<IUser> } = {};

    classroomQueries.forEach((q) => {
      if (q.data) {
        studentsByClassroom[q.data.classroom_id] = q.data.users;
      }
    });

    return studentsByClassroom;
  }, [classroomQueries]);

  if (classroomQueries.every((q) => q.isSuccess)) {
    onSuccess?.(classroomData);
  }

  return {
    isLoading: classroomQueries.some((q) => q.isLoading),
    classroomData,
  };
};

export default useFetchClassroomUsersBulk;
