import { Box, Stack, useBreakpointValue } from "@chakra-ui/react";
import React from "react";
import { v4 as uuid } from "uuid";

import { ColorScheme } from "adminComponents";
import { Avatar } from "adminComponents/atoms/Avatar";
import { pxToRem } from "adminComponents/utils";
import { Button } from "sessionComponents/atoms/Button";
import { Icon } from "sessionComponents/atoms/Icon";
import { TypingIndicator } from "sessionComponents/atoms/TypingIndicator";

export interface IChatMessageGroupProps {
  senderProfileImageSrc: string;
  bubbleColor?: string | ColorScheme;
  isLoading?: boolean;
  flip?: boolean;
  handleSpeak?: (id: string) => void;
  messages?: Array<{
    id: string;
    content: React.ReactNode;
    isSpeakable: boolean;
  }>;
}

export const ChatMessageGroup: React.FC<IChatMessageGroupProps> = ({
  senderProfileImageSrc,
  bubbleColor = "primary.lightest-gray",
  isLoading = false,
  flip = false,
  messages = [],
  handleSpeak,
}) => {
  const avatarSize = useBreakpointValue({ base: "sm", lg: "md" }) || "sm";
  const borderRadius = useBreakpointValue({ base: 14, md: 20, lg: 28 }) || 14;
  const padding = useBreakpointValue({ base: 14, md: 20, lg: 28 }) || 14;
  const speakableIconSize = useBreakpointValue({ base: 20, md: 28 }) || 20;

  const onSpeech = (
    e:
      | React.MouseEvent<HTMLButtonElement>
      | React.KeyboardEvent<HTMLButtonElement>,
    id: string
  ) => {
    e.preventDefault();
    e.stopPropagation();
    handleSpeak?.(id);
  };

  const makeBubble = ({
    c,
    id,
    isFirst,
    isLast,
    isSpeakable,
  }: {
    c: React.ReactNode;
    id: string;
    isFirst: boolean;
    isLast: boolean;
    isSpeakable: boolean;
  }) => {
    return (
      <Box
        key={uuid()}
        bgColor={bubbleColor}
        borderTopRadius={pxToRem(isFirst ? borderRadius : borderRadius / 2)}
        borderBottomRadius={pxToRem(isLast ? borderRadius : borderRadius / 2)}
        p={pxToRem(padding)}
        w="fit-content"
      >
        <Box w="full">{c}</Box>
        {isSpeakable && (
          <Box marginTop={pxToRem(8)} display="flex" justifyContent="flex-end">
            <Button
              onClick={(e) => onSpeech(e, id)}
              onKeyPress={(e) => onSpeech(e, id)}
              variant="buttonFilled"
              borderRadius="full"
              height="unset"
            >
              <Icon boxSize={pxToRem(speakableIconSize)} icon="sound_on" />
            </Button>
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Stack
      w="full"
      alignItems="flex-end"
      direction={flip ? "row-reverse" : "row"}
    >
      <Avatar src={senderProfileImageSrc} size={avatarSize} />
      {isLoading ? (
        makeBubble({
          c: (
            <>
              &nbsp;
              <TypingIndicator size="sm" />
              &nbsp;
            </>
          ),
          id: "",
          isFirst: true,
          isLast: true,
          isSpeakable: false,
        })
      ) : (
        <Stack direction="column" spacing={pxToRem(4)}>
          {messages.map((m, i) =>
            makeBubble({
              c: m.content,
              id: m.id,
              isFirst: i === 0,
              isLast: i === messages.length - 1,
              isSpeakable: m.isSpeakable && !!handleSpeak,
            })
          )}
        </Stack>
      )}
    </Stack>
  );
};
