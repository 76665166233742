import { useEffect } from "react";
import { usePrevious } from "react-use";

import { useSessionRoundGroupState } from "links/lib/contexts/sessionRoundGroupState";
import { useSessionRoundState } from "links/lib/contexts/sessionRoundState";
import {
  CoopDrawVariantData,
  ISessionRoundGroupState,
  ISessionSelectedChoice,
  ISessionUser,
  PracticeSessionItemVariantType,
} from "links/lib/types";
import { useGroupUsers } from "sessionComponents/hooks/useGroupUsers";
import { StudentSessionSoundEffect } from "sessionComponents/types";
import { useSessionAudio } from "sharedComponents/contexts/sessionAudio";

const getSelectedChoices = (
  variant: PracticeSessionItemVariantType,
  roundGroupState: ISessionRoundGroupState
): ISessionSelectedChoice[] => {
  switch (variant) {
    case PracticeSessionItemVariantType.CoopRadios: {
      return roundGroupState.radios?.selected_choices || [];
    }
    case PracticeSessionItemVariantType.CoopChecks: {
      return roundGroupState.checks?.selected_choices || [];
    }
    case PracticeSessionItemVariantType.CoopTextMatch: {
      return roundGroupState.text_match?.selected_choices || [];
    }
    default: {
      return [];
    }
  }
};

export const useTeammateSoundEffects = (student: ISessionUser): void => {
  const roundGroupState = useSessionRoundGroupState();
  const roundState = useSessionRoundState();
  const groupUsers = useGroupUsers(student.session_group_id);
  const { confirmed_user_ids, variant, variant_data } = roundState;
  const selectedChoices = getSelectedChoices(variant, roundGroupState);

  const drawVariantData = variant_data as CoopDrawVariantData;
  const groupVotes = drawVariantData?.coop_draw?.votes.filter((vote) =>
    groupUsers.some((gu) => gu.id === vote.user_id)
  );
  const previousConfirmedUserIds = usePrevious(confirmed_user_ids);
  const previousSelectedChoices = usePrevious(selectedChoices);
  const previousGroupVotes = usePrevious(groupVotes);

  const { play: playAudio } = useSessionAudio();

  // plays music when teammate choice selection changes
  useEffect(() => {
    if (!selectedChoices) return;

    const teammateSelectedChoice = selectedChoices
      ?.filter((c) => c.user_id !== student.id)
      .some((choice) => {
        const result = !previousSelectedChoices
          ?.filter((c) => c.user_id !== student.id)
          .some((psc) => {
            return (
              psc.choice_id === choice.choice_id &&
              psc.user_id === choice.user_id
            );
          });

        return result;
      });

    if (teammateSelectedChoice) {
      playAudio(StudentSessionSoundEffect.TeammateSelected);
    }
  }, [selectedChoices, playAudio, previousSelectedChoices, student.id]);

  // plays music when a teammate confirm changes
  useEffect(() => {
    const teammateConfirmed = confirmed_user_ids
      ?.filter(
        (uid) => uid !== student.id && groupUsers.find((gu) => gu.id === uid)
      )
      .some((user_id) => {
        return previousConfirmedUserIds?.indexOf(user_id) === -1;
      });

    if (teammateConfirmed) {
      playAudio(StudentSessionSoundEffect.TeammateConfirmed);
    }
  }, [
    confirmed_user_ids,
    groupUsers,
    playAudio,
    previousConfirmedUserIds,
    student.id,
  ]);

  // plays audio when a teammate has voted on a drawing
  useEffect(() => {
    const teammateVoted = groupVotes
      ?.filter((vote) => vote.user_id !== student.id)
      .some((vote) => {
        return !previousGroupVotes?.some((pgv) => pgv.user_id === vote.user_id);
      });

    if (teammateVoted) {
      playAudio(StudentSessionSoundEffect.TeammateConfirmed);
    }
  }, [groupVotes, playAudio, previousGroupVotes, student.id]);
};
