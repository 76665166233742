import { pxToRem } from "adminComponents/utils/pxToRem";

export const AdminGenerateItemForm = {
  baseStyle: {
    headerContainer: {
      alignItems: "start",
      flexDirection: "column",
    },
    formContainer: {
      paddingY: pxToRem(30),
      flexDirection: "column",
      gap: pxToRem(40),
    },
    fieldContainer: {
      flexDirection: "column",
      gap: pxToRem(40),
      color: "primary.dark-gray",
    },
    button: {
      height: pxToRem(48),
      width: "100%",
      maxWidth: ["100%", null, pxToRem(138)],
    },
  },
};
