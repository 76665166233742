import { IProps as CircledNumberProps } from "adminComponents/atoms/CircledNumber";
import { pxToRem } from "adminComponents/utils/pxToRem";

export const AdminCircleNumber = {
  parts: ["outer", "iconContainer", "numberContainer"],
  baseStyle: ({
    circleColor,
    small,
  }: CircledNumberProps): Record<string, unknown> => ({
    outer: {
      display: "inline-flex",
      position: "relative",
    },
    iconContainer: {
      color: circleColor,
      width: small ? pxToRem(30) : { base: pxToRem(56), md: pxToRem(70) },
    },
    numberContainer: {
      left: "50%",
      position: "absolute",
      top: "50%",
      transform: "auto",
      translateX: "-50%",
      translateY: "-50%",
      zIndex: 1,
    },
    certifiedContainer: {
      position: "absolute",
      bottom: small ? pxToRem(-6) : pxToRem(-4),
      right: small ? pxToRem(-6) : pxToRem(-4),
    },
  }),
  variants: {
    circleWithBackgroundColor: (props: {
      backgroundColor: string;
    }): Record<string, string> => ({
      borderRadius: "100%",
      height: pxToRem(24),
      padding: pxToRem(5),
      width: pxToRem(24),
      backgroundColor: props.backgroundColor,
    }),
  },
};
