import { HStack, Square } from "@chakra-ui/react";
import React, { useCallback, useEffect } from "react";

import { Button } from "adminComponents/atoms/Button";
import { Icon } from "adminComponents/atoms/Icon";
import { LoadingSpinner } from "adminComponents/atoms/LoadingSpinner";
import { Text } from "adminComponents/atoms/Text";
import { AdminFlyout } from "adminComponents/molecules/Flyout";
import { GeneratePracticeSetResult } from "adminComponents/molecules/GeneratePracticeSetResult";
import { pxToRem } from "adminComponents/utils";
import { useErrorToast, useShowToast } from "adminComponents/utils/toast";
import { useMutatePracticeSet } from "links/lib/features/practiceSets";

import { useParseLLMPracticeSet } from "../hooks/useParseLLMPracticeSet";

export interface IProps {
  practiceSetString: string;
  isOpen: boolean;
  onClose: () => void;
  error?: string;
}

export const PracticeSetFlyout: React.FC<IProps> = ({
  practiceSetString,
  isOpen,
  onClose,
  error,
}) => {
  const showToast = useShowToast();
  const { practiceSet, practiceSetItems, errors, isLoading } =
    useParseLLMPracticeSet(practiceSetString);

  const {
    mutate,
    isLoading: isSaving,
    data,
    error: mutateError,
  } = useMutatePracticeSet({
    onSuccess: () => showToast("Practice Set Saved"),
  });
  useErrorToast(mutateError);

  const onSave = useCallback(() => {
    mutate({
      ...practiceSet,
      id: "",
      practice_set_items: practiceSetItems,
    });
  }, [mutate, practiceSet, practiceSetItems]);

  useEffect(() => {
    if (data?.practice_set?.id) {
      window.location.href = `/t/my-library/sets/${data.practice_set.id}`;
    }
  }, [data?.practice_set]);

  return (
    <AdminFlyout
      isOpen={isOpen}
      title="Create Set"
      onClose={onClose}
      footerContent={
        isLoading ? (
          <></>
        ) : (
          <HStack spacing={pxToRem(10)}>
            <Button
              onClick={onSave}
              variant="adminButtonFilled"
              leftIcon={
                <Icon icon="add_circle_outlined" boxSize={pxToRem(16)} />
              }
              isLoading={isSaving}
            >
              Save
            </Button>
          </HStack>
        )
      }
    >
      {error && <Text>{error}</Text>}
      {errors.map((anError) => (
        <Text key={anError} color="red">
          {anError}
        </Text>
      ))}
      {isLoading && (
        <Square size="full">
          <LoadingSpinner />
        </Square>
      )}
      {!!practiceSet && !!practiceSetItems && !isLoading && (
        <GeneratePracticeSetResult
          practiceSet={practiceSet}
          items={practiceSetItems}
        />
      )}
    </AdminFlyout>
  );
};
