import React from "react";

import { SessionGameType } from "links/lib/types";
import { useDetectOrientation } from "sessionComponents/theme/hooks/useDetectOrientation";

import { LandscapeLayout } from "./LandscapeLayout";
import { PortraitDragsortLayout } from "./PortraitDragsortLayout";
import { PortraitLayout } from "./PortraitLayout";

interface IQuestionLayoutProps {
  outerGame?: SessionGameType;
  questionComponent: React.ReactElement;
  answerOptionsComponent: React.ReactElement;
  isDragsortItem: boolean;
  confirmAreaComponent?: React.ReactElement;
  groupStatusComponent?: React.ReactElement; //only on teacher view
  teamsLength?: number; //only on teacher view
  isTeacher?: boolean;
  answerOptionsContainerHeight?: number | string;
}

export const QuestionLayout: React.FC<IQuestionLayoutProps> = (props) => {
  const { isPortrait } = useDetectOrientation();

  return isPortrait ? (
    props.isDragsortItem ? (
      <PortraitDragsortLayout {...props} />
    ) : (
      <PortraitLayout {...props} />
    )
  ) : (
    <LandscapeLayout {...props} />
  );
};
