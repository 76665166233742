import { Box, Flex, HStack } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useMeasure } from "react-use";

import { Button } from "adminComponents/atoms/Button";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils";
import { IPracticeSet } from "links/lib/types";

import {
  CompactLibraryPracticeSetCard,
  PRACTICE_SET_CARD_WIDTH,
} from "../CompactLibraryPracticeSetCard";

export interface IProps {
  title: string;
  count: number;
  practiceSets: IPracticeSet[];
  isLoading: boolean;
  handleViewMore: () => void;
  onPracticeSetPreview: (set: IPracticeSet) => void;
}

const GAP = 14;
const TOTAL_PRACTICE_SET_CARD_WIDTH = PRACTICE_SET_CARD_WIDTH + GAP;

export const PracticeSetSection: React.FC<IProps> = ({
  title,
  count,
  practiceSets,
  isLoading,
  handleViewMore,
  onPracticeSetPreview,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const [ref, { width: sectionWidth }] = useMeasure<HTMLDivElement>();

  const setsToDisplay = useMemo(() => {
    if (!sectionWidth) {
      return [];
    }

    let sets = practiceSets;

    if (isLoading) {
      sets = new Array(25).fill({ id: "0" });
    }

    return sets.slice(
      0,
      Math.floor(sectionWidth / TOTAL_PRACTICE_SET_CARD_WIDTH)
    );
  }, [sectionWidth, isLoading, practiceSets]);

  const showMore = practiceSets.length > setsToDisplay.length;

  return (
    <Box ref={ref} w="full">
      <HStack alignItems="center" paddingY={pxToRem(20)}>
        <Text variant="adminH3TTCommons">{title}</Text>
        <Text color="primary.dark-gray" variant="adminP1">
          {count} {t("common.found")}
        </Text>
      </HStack>
      <Flex w="full" gap={pxToRem(GAP)}>
        {setsToDisplay.map((practiceSet, i) => (
          <CompactLibraryPracticeSetCard
            practiceSet={practiceSet}
            isLoading={isLoading}
            key={`${practiceSet.id}-${i}`}
            handleClick={() => {
              onPracticeSetPreview(practiceSet);
            }}
          />
        ))}
      </Flex>
      {showMore && (
        <Flex w="full" paddingTop={pxToRem(12)} justifyContent="center">
          <Button onClick={handleViewMore} variant="adminTextButtonMedium">
            <Text variant="adminP1" color="utility.link">
              {t("common.viewAll")}
            </Text>
          </Button>
        </Flex>
      )}
    </Box>
  );
};
