import { useCallback, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";

import {
  ToastId,
  useErrorToast,
  usePendingToast,
  useShowToast,
} from "adminComponents/utils/toast";
import { useAnalytics } from "lib/contexts/analytics";
import { useMutatePracticeSetItemsOrder } from "links/lib/features/practiceSetItems";
import {
  AnalyticsEvent,
  IPracticeSet,
  IPracticeSetItem,
} from "links/lib/types";

interface IResult {
  handleReorder: (
    practiceSet: IPracticeSet,
    items?: Array<IPracticeSetItem>
  ) => void;
  handleCancelReorder: () => void;
  handleStartReorder: () => void;
  isLoading: boolean;
}

interface IArgs {
  practiceSet?: IPracticeSet;
}

const useReorderPracticeSetItems = ({ practiceSet }: IArgs): IResult => {
  const showToast = useShowToast();
  const { showPendingToast, closePendingToast } = usePendingToast();
  const { trackEvent } = useAnalytics();
  const { t } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "practiceSetDetailContainer.practiceSetItemReorder",
  });
  const pendingToastRef = useRef<ToastId | undefined>();

  const onSuccess = useCallback(() => {
    showToast(t("successToast"));
  }, [showToast, t]);

  const onSettled = useCallback(() => {
    if (pendingToastRef.current) {
      closePendingToast(pendingToastRef.current);
      pendingToastRef.current = undefined;
    }
  }, [closePendingToast]);

  const mutatePracticeSetItemsOrder = useMutatePracticeSetItemsOrder({
    onSuccess,
    onSettled,
  });

  useErrorToast(mutatePracticeSetItemsOrder.error);

  const handleCancelReorder = useCallback(() => {
    if (!practiceSet) return;

    trackEvent(
      AnalyticsEvent.TeacherDashboard_PracticeSetDetail_ItemList_CancelReorder,
      {
        practiceSetId: practiceSet.id,
      }
    );
  }, [trackEvent, practiceSet]);

  const handleStartReorder = useCallback(() => {
    if (!practiceSet) return;

    trackEvent(
      AnalyticsEvent.TeacherDashboard_PracticeSetDetail_ItemList_StartReorder,
      {
        practiceSetId: practiceSet.id,
      }
    );
  }, [trackEvent, practiceSet]);

  const handleReorder = useCallback(
    (practiceSet: IPracticeSet, items?: Array<IPracticeSetItem>) => {
      if (!items) return;

      const ordering = items
        .sort((a, b) => (a.order <= b.order ? -1 : 1))
        .map((card, index) => {
          card.order = index + 1;
          return card;
        })
        .filter((item) => item)
        .map((item) => ({ item_id: item.id, order: item.order })) as Array<{
        item_id: string;
        order: number;
      }>;

      ordering.length > 0 &&
        mutatePracticeSetItemsOrder.mutate({
          practice_set_id: practiceSet.id,
          ordering,
        });

      pendingToastRef.current = showPendingToast(t("pendingToast"));

      trackEvent(
        AnalyticsEvent.TeacherDashboard_PracticeSetDetail_ItemList_SaveReorder,
        {
          practice_set_id: practiceSet.id,
        }
      );
    },
    [trackEvent, showPendingToast, mutatePracticeSetItemsOrder, t]
  );

  return useMemo(
    () => ({
      handleReorder,
      handleCancelReorder,
      handleStartReorder,
      isLoading: mutatePracticeSetItemsOrder.isLoading,
    }),
    [
      handleReorder,
      handleCancelReorder,
      handleStartReorder,
      mutatePracticeSetItemsOrder.isLoading,
    ]
  );
};

export { useReorderPracticeSetItems };
