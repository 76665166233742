import React from "react";

import { SeasonMapMusic } from "links/lib/types";
import { AudioProvider } from "sharedComponents/contexts/audio";

import spriteData from "./resource/output.json";
import spriteMP3 from "./resource/output.mp3";
import spriteWebM from "./resource/output.webm";
import PR2024MP3 from "./resource/pr_2024.mp3";
import PR2024WebM from "./resource/pr_2024.webm";

const backgroundClips = {
  [SeasonMapMusic.PR2024]: [
    { src: PR2024WebM, type: "audio/webm" },
    { src: PR2024MP3, type: "audio/mpeg" },
  ],
};

const SEASON_MAP_VOLUME_KEY = "SEASON_MAP_VOLUME";

export const Season3MapAudio: React.FC = ({ children }) => {
  return (
    <AudioProvider
      backgroundClips={backgroundClips}
      srcVolume={1}
      spriteSrc={[spriteWebM, spriteMP3]}
      sprite={spriteData.sprite}
      volumeStorageKey={SEASON_MAP_VOLUME_KEY}
    >
      {children}
    </AudioProvider>
  );
};
