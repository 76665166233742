import MARKER_IMG from "./Resources/marker.svg";

export interface IDiagramPoint {
  x: number;
  y: number;
}

export interface IDiagramPointAddEventHandler {
  (diagramPoint: IDiagramPoint): void;
}

export interface IDiagramPointMoveEventHandler {
  (index: number, diagramPoint: IDiagramPoint): void;
}

class DiagramEditorManager {
  private points: Array<IDiagramPoint> = [];
  private movingPoint?: IDiagramPoint = undefined;
  private addHandlers: Array<IDiagramPointAddEventHandler> = [];
  private moveHandlers: Array<IDiagramPointMoveEventHandler> = [];
  private markerImage: HTMLImageElement = new Image(32, 38);

  public maxPoints = 6;
  public pointWidth = 32;
  public pointHeight = 38;
  public pointFillColor = "red";
  public pointLabelFont = "sans-serif";
  public pointLabelFillColor = "white";
  public canvasPaddingX = 14;
  public canvasPaddingY = 28;

  constructor(
    private canvas: HTMLCanvasElement,
    private ctx: CanvasRenderingContext2D,
    private image: HTMLImageElement,
    private container: HTMLDivElement
  ) {
    this.markerImage.onload = () => {
      this.draw();
    };
    this.markerImage.src = MARKER_IMG;
    this.updateCanvasSize();

    this.onCanvasMouseUp = this.onCanvasMouseUp.bind(this);
    this.onCanvasMouseMove = this.onCanvasMouseMove.bind(this);
    this.onCanvasMouseDown = this.onCanvasMouseDown.bind(this);
    this.onCanvasTouchStart = this.onCanvasTouchStart.bind(this);
    this.onCanvasTouchMove = this.onCanvasTouchMove.bind(this);
    this.onCanvasTouchEnd = this.onCanvasTouchEnd.bind(this);

    canvas.addEventListener("mousedown", this.onCanvasMouseDown);
    canvas.addEventListener("mouseup", this.onCanvasMouseUp);
    canvas.addEventListener("mousemove", this.onCanvasMouseMove);
    canvas.addEventListener("touchstart", this.onCanvasTouchStart);
    canvas.addEventListener("touchmove", this.onCanvasTouchMove);
    canvas.addEventListener("touchend", this.onCanvasTouchEnd);
  }

  /**
   * Converts a world-space coordinate (window) to a canvas-space coordinate
   * @param x
   * @param y
   * @returns
   */
  private worldToCanvasSpacePos(x: number, y: number) {
    const { canvas } = this;
    const rect = this.getCanvasSize();
    const scale = this.getCanvasScale();

    const pos = {
      x: (((x - rect.left) / (rect.right - rect.left)) * canvas.width) / scale,
      y: (((y - rect.top) / (rect.bottom - rect.top)) * canvas.height) / scale,
    };

    return pos;
  }

  /**
   * Converts a mouse event into canvas-space coordinates
   * @param evt
   * @returns
   */
  private getMousePos(evt: MouseEvent) {
    return this.worldToCanvasSpacePos(evt.clientX, evt.clientY);
  }

  /**
   * Converts a touch event into canvas-space coordinates
   * @param evt
   * @returns
   */
  private getTouchPos(evt: TouchEvent) {
    return this.worldToCanvasSpacePos(
      evt.touches[0].clientX,
      evt.touches[0].clientY
    );
  }

  /**
   * Convert a point from local space coordinates to image ratio coordinates
   * @param point
   * @returns
   */
  private pointToImageRatio(point: IDiagramPoint): IDiagramPoint {
    const imageSize = this.getImageSize();
    const imageSpacePos = this.pointToImageSpace(point);

    imageSpacePos.x = imageSpacePos.x / imageSize.width;
    imageSpacePos.y = imageSpacePos.y / imageSize.height;

    return imageSpacePos;
  }

  /**
   * Converts a diagram point from local space coordinates (canvas space) to
   * image space coordinates based on natural image size
   * @param point
   * @returns
   */
  private pointToImageSpace(point: IDiagramPoint): IDiagramPoint {
    const imageSize = this.getImageSize();
    const renderBounds = this.getImageRenderBounds();

    const x =
      (point.x - renderBounds.dx) * (imageSize.width / renderBounds.width);
    const y =
      (point.y - renderBounds.dy) * (imageSize.height / renderBounds.height);

    return {
      x,
      y,
    };
  }

  /**
   * Converts a diagram point from image space coordinates (natural size) to
   * canvas space coordinates based on canvas size/scale and image render bounds
   * @param point
   * @returns
   */
  private pointToCanvasSpace(point: IDiagramPoint): IDiagramPoint {
    const imageSize = this.getImageSize();
    const renderBounds = this.getImageRenderBounds();

    const x =
      renderBounds.dx + point.x * (renderBounds.width / imageSize.width);
    const y =
      renderBounds.dy + point.y * (renderBounds.height / imageSize.height);

    return {
      x,
      y,
    };
  }

  /**
   * Returns the bounding rectangle of the canvas container element
   * @returns
   */
  private getContainerSize() {
    return this.container.getBoundingClientRect();
  }

  /**
   * Returns the scaling value applied to the canvas render ctx
   * @returns
   */
  private getCanvasScale() {
    return window.devicePixelRatio;
  }

  /**
   * Returns the bounding rectangle of the canvase element
   * @returns
   */
  private getCanvasSize() {
    return this.canvas.getBoundingClientRect();
  }

  /**
   * Returns the natural size of the image
   * @returns
   */
  private getImageSize() {
    const { image } = this;

    return {
      width: image.naturalWidth,
      height: image.naturalHeight,
    };
  }

  /**
   * Returns the aspect ratio of the canvas
   * @returns
   */
  private getCanvasAspectRatio() {
    const canvasSize = this.getCanvasSize();
    return canvasSize.width / canvasSize.height;
  }

  /**
   * Returns a boolean indicating whether a position is within
   * the bounds of the diagram image
   * @param x
   * @param y
   * @returns
   */
  private inImageBounds(x: number, y: number): boolean {
    const renderBounds = this.getImageRenderBounds();

    // point is outside of image
    if (
      x < renderBounds.dx ||
      x > renderBounds.dx + renderBounds.width ||
      y < renderBounds.dy ||
      y > renderBounds.dy + renderBounds.height
    ) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * Returns the bounds of the image in canvas-space coordinates
   * @returns
   */
  private getImageRenderBounds() {
    const imageSize = this.getImageSize();
    const canvasSize = this.getCanvasSize();
    const canvasAspectRatio = this.getCanvasAspectRatio();
    const imageAspectRatio = imageSize.width / imageSize.height;

    // if image is wider than the canvas aspect ratio,
    // then fit by width, else fit by height
    const fitW = imageAspectRatio > canvasAspectRatio;

    const imageScale = fitW
      ? (canvasSize.width - 2 * this.canvasPaddingX) / imageSize.width
      : (canvasSize.height - 2 * this.canvasPaddingY) / imageSize.height;

    const imageRenderSize = {
      width: imageSize.width * imageScale,
      height: imageSize.height * imageScale,
    };

    const dx = fitW
      ? this.canvasPaddingX
      : (canvasSize.width - imageRenderSize.width) / 2;
    const dy = fitW
      ? (canvasSize.height - imageRenderSize.height) / 2
      : this.canvasPaddingY;

    return {
      dx,
      dy,
      width: imageRenderSize.width,
      height: imageRenderSize.height,
    };
  }

  /**
   * Given a position, return a list of all diagram points that have been hit
   * @param x
   * @param y
   */
  private getHitPoints(x: number, y: number): Array<IDiagramPoint> {
    const { pointHeight, pointWidth } = this;

    return this.points.filter((p) => {
      return (
        x >= p.x - pointWidth / 2 &&
        x <= p.x + pointWidth / 2 &&
        y >= p.y - pointHeight &&
        y <= p.y
      );
    });
  }

  /**
   * Adds a new diagram point at a specific position
   * @param x
   * @param y
   * @returns
   */
  private addPoint(x: number, y: number) {
    // point is outside of image
    if (!this.inImageBounds(x, y)) {
      return;
    }

    // we already have more than the max number of points (6)
    if (this.points.length >= this.maxPoints) {
      return;
    }

    const point = {
      x,
      y,
    };

    const ratioPoint = this.pointToImageRatio(point);

    this.addHandlers.forEach((h) => {
      h(ratioPoint);
    });
  }

  /**
   * Request moving a point to a specific location
   * @param point
   */
  private movePoint(point: IDiagramPoint) {
    if (!this.moveHandlers.length) return;

    const pointIndex = this.points.findIndex((p) => p === point);

    const ratioPoint = this.pointToImageRatio(point);

    if (pointIndex === -1) return;

    this.moveHandlers.forEach((h) => {
      h(pointIndex, ratioPoint);
    });
  }

  private onCanvasPointerDown(x: number, y: number) {
    // check if mouse down over point, if so, start a point move
    const hitPoints = this.getHitPoints(x, y);

    if (hitPoints.length) {
      this.movingPoint = hitPoints[0];
      this.canvas.style.cursor = "grabbing";
    } else {
      this.movingPoint = undefined;
    }
  }

  private onCanvasPointerMove(x: number, y: number) {
    if (this.movingPoint) {
      this.canvas.style.cursor = "grabbing";

      if (!this.inImageBounds(x, y)) return;

      this.movingPoint.x = x;
      this.movingPoint.y = y;

      requestAnimationFrame(() => this.draw());
    } else {
      const hitPoints = this.getHitPoints(x, y);

      if (hitPoints.length) {
        this.canvas.style.cursor = "grab";
      } else {
        this.canvas.style.cursor = "default";
      }
    }
  }

  private onCanvasPointerUp(x: number, y: number) {
    // if moving a point, then stop move event
    if (this.movingPoint) {
      this.movePoint(this.movingPoint);
      this.movingPoint = undefined;
    } else {
      this.addPoint(x, y);
    }
  }

  private onCanvasMouseDown(ev: MouseEvent) {
    // check if mouse down over point, if so, start a point move
    const { x, y } = this.getMousePos(ev);
    this.onCanvasPointerDown(x, y);
  }

  private onCanvasMouseUp(ev: MouseEvent) {
    const { x, y } = this.getMousePos(ev);

    this.onCanvasPointerUp(x, y);
  }

  private onCanvasMouseMove(ev: MouseEvent) {
    const { x, y } = this.getMousePos(ev);

    this.onCanvasPointerMove(x, y);
  }

  private onCanvasTouchStart(ev: TouchEvent) {
    if (!ev.touches.length) return;

    const { x, y } = this.getTouchPos(ev);
    this.onCanvasPointerDown(x, y);
  }

  private onCanvasTouchMove(ev: TouchEvent) {
    if (!ev.touches.length) return;
    const { x, y } = this.getTouchPos(ev);
    this.onCanvasPointerMove(x, y);
  }

  private onCanvasTouchEnd(ev: TouchEvent) {
    if (!ev.touches.length) return;
    const { x, y } = this.getTouchPos(ev);
    this.onCanvasPointerUp(x, y);
  }

  private updateCanvasSize() {
    const { canvas, ctx } = this;

    // update the canvas size to match container
    // use pixel ratio to handle retina display scaling
    const canvasSize = this.getContainerSize();
    canvas.style.width = canvasSize.width + "px";
    canvas.style.height = canvasSize.height + "px";
    // Set actual size in memory (scaled to account for extra pixel density).
    const scale = this.getCanvasScale();
    canvas.width = Math.floor(canvasSize.width * scale);
    canvas.height = Math.floor(canvasSize.height * scale);
    // Normalize coordinate system to use css pixels.
    ctx.scale(scale, scale);
  }

  private drawPoint(
    ctx: CanvasRenderingContext2D,
    point: IDiagramPoint,
    index: number
  ) {
    const { pointLabelFont, pointLabelFillColor } = this;

    ctx.drawImage(this.markerImage, point.x - 16, point.y - 38);
    ctx.font = "20px " + pointLabelFont;
    ctx.textAlign = "center";
    ctx.fillStyle = pointLabelFillColor;
    ctx.fillText((index + 1).toString(), point.x, point.y - 14);
  }

  private draw() {
    const { ctx, image } = this;
    const imageRenderSize = this.getImageRenderBounds();
    const canvasSize = this.getCanvasSize();

    ctx.clearRect(0, 0, canvasSize.width, canvasSize.height);

    ctx.fillStyle = "rgba(255, 255, 255, .5)";
    ctx.fillRect(
      imageRenderSize.dx,
      imageRenderSize.dy,
      imageRenderSize.width,
      imageRenderSize.height
    );

    ctx.drawImage(
      image,
      imageRenderSize.dx,
      imageRenderSize.dy,
      imageRenderSize.width,
      imageRenderSize.height
    );

    this.points.forEach((p, i) => {
      this.drawPoint(ctx, p, i);
    });
  }

  public destroy(): void {
    this.points = [];
    this.addHandlers = [];
    this.moveHandlers = [];
    this.canvas.removeEventListener("mouseup", this.onCanvasMouseUp);
    this.canvas.removeEventListener("mousemove", this.onCanvasMouseMove);
    this.canvas.removeEventListener("mousedown", this.onCanvasMouseDown);
    this.canvas.removeEventListener("touchstart", this.onCanvasTouchStart);
    this.canvas.removeEventListener("touchmove", this.onCanvasTouchMove);
    this.canvas.removeEventListener("touchend", this.onCanvasTouchEnd);
  }

  /**
   * Returns a list of diagram points with coordinates as image ratios
   * @returns
   */
  public getDiagramPointsAsImageRatios(): Array<IDiagramPoint> {
    const imageSize = this.getImageSize();

    return this.points.map((p) => {
      const imageSpacePos = this.pointToImageSpace(p);

      imageSpacePos.x = imageSpacePos.x / imageSize.width;
      imageSpacePos.y = imageSpacePos.y / imageSize.height;

      return imageSpacePos;
    });
  }

  /**
   * Register an event handler to be called whenever a new diagram point is requesting add
   * @param handler
   */
  public onRequestDiagramPointAdd(handler: IDiagramPointAddEventHandler): void {
    this.addHandlers.push(handler);
  }

  /**
   * Register an event handler to be called whenever a new diagram point is requesting move
   * @param handler
   */
  public onRequestDiagramPointMove(
    handler: IDiagramPointMoveEventHandler
  ): void {
    this.moveHandlers.push(handler);
  }

  /**
   * Set diagram points based on x,y value indicating image natural size ratio
   * of point position
   * @param points
   */
  public setDiagramPointsFromImageRatios(points: Array<IDiagramPoint>): void {
    // if a point is in the middle of moving, then stop
    this.movingPoint = undefined;

    // Convert point ratios into canvas space
    this.points = points.map((p) => {
      const imageSize = this.getImageSize();

      // get the natural image position of the point
      const imageSpacePos = {
        x: p.x * imageSize.width,
        y: p.y * imageSize.height,
      };

      return this.pointToCanvasSpace(imageSpacePos);
    });

    this.draw();
  }

  public resetCanvasSize(): void {
    const imageRatios = this.getDiagramPointsAsImageRatios();
    this.updateCanvasSize();
    this.setDiagramPointsFromImageRatios(imageRatios);
  }
}

export default DiagramEditorManager;
