import React from "react";
import { useMount } from "react-use";

import { useSessionActions } from "links/lib/contexts/sessionActions";

export const IndividualSessionLobby: React.FC = () => {
  const { initiateNextRound } = useSessionActions();
  useMount(() => {
    initiateNextRound();
  });

  return <></>;
};
