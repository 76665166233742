import { Box, Flex, FormControl, HStack, SimpleGrid } from "@chakra-ui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { FormLabel } from "adminComponents/atoms/FormLabel";
import { Modal } from "adminComponents/atoms/Modal";
import { Radio } from "adminComponents/atoms/Radio";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { GoogleClassroomClassroom } from "links/lib/types";

interface GoogleClassroomImportModalProps {
  isOpen: boolean;
  isLoading: boolean;
  googleClassrooms: GoogleClassroomClassroom[];
  handlePickGoogleClassroom: (classroom: GoogleClassroomClassroom) => void;
  handleClose: () => void;
  title?: string;
  confirmText?: string;
}

export const GoogleClassroomImportModal: React.FC<
  GoogleClassroomImportModalProps
> = ({
  isOpen,
  isLoading,
  googleClassrooms,
  handleClose,
  handlePickGoogleClassroom,
  title,
  confirmText,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  const [selectedGoogleClassroom, setSelectedGoogleClassroom] =
    useState<GoogleClassroomClassroom | null>(null);

  const toggleSelectGoogleClassroom = (
    googleClassroom: GoogleClassroomClassroom
  ) => {
    if (
      selectedGoogleClassroom &&
      googleClassroom.id === selectedGoogleClassroom.id
    ) {
      setSelectedGoogleClassroom(null);
    } else {
      setSelectedGoogleClassroom(googleClassroom);
    }
  };

  const onSubmit = () => {
    if (!selectedGoogleClassroom) return;
    handlePickGoogleClassroom(selectedGoogleClassroom);
    setSelectedGoogleClassroom(null);
  };

  const onClose = () => {
    setSelectedGoogleClassroom(null);
    handleClose();
  };

  return (
    <Modal
      title={title ? title : t("importClassFromGoogleClassroomModal.title")}
      onClose={onClose}
      isOpen={isOpen}
      size="2xl"
      showBackButton={false}
    >
      <Box mb={pxToRem(40)}>
        <FormControl>
          <FormLabel variant="adminFormLabelDarkGray">
            {t("importClassFromGoogleClassroomModal.classrooms")}
          </FormLabel>
          <SimpleGrid
            gap={[pxToRem(24), null, pxToRem(16)]}
            columns={[1, null, 2]}
          >
            {googleClassrooms.map((googleClassroom) => {
              const subheadingParts = [
                googleClassroom.section,
                googleClassroom.room,
                googleClassroom.description,
              ].filter((part) => !!part);

              return (
                <Button
                  key={googleClassroom.id}
                  variant="adminGoogleClassroomPickButton"
                  onClick={() => toggleSelectGoogleClassroom(googleClassroom)}
                >
                  <HStack>
                    <Radio
                      isChecked={
                        (selectedGoogleClassroom?.id || "") ===
                        googleClassroom.id
                      }
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    ></Radio>
                    <Box w="85%">
                      <Text
                        variant="adminP1"
                        mb={pxToRem(4)}
                        overflow="hidden"
                        textOverflow="ellipsis"
                      >
                        {googleClassroom.name}
                      </Text>
                      <Text
                        variant="adminP2"
                        color="primary.dark-gray"
                        overflow="hidden"
                        textOverflow="ellipsis"
                      >
                        {subheadingParts.join(" • ")}
                      </Text>
                    </Box>
                  </HStack>
                </Button>
              );
            })}
          </SimpleGrid>
        </FormControl>
      </Box>
      <Flex flexDir={["column", null, "row"]} gap={pxToRem(12)}>
        <Button
          size="lg"
          variant="adminButtonFilled"
          isDisabled={!selectedGoogleClassroom}
          onClick={onSubmit}
          isLoading={isLoading}
        >
          {confirmText
            ? confirmText
            : t("importClassFromGoogleClassroomModal.addStudents")}
        </Button>
        <Button
          size="lg"
          variant="adminButtonOutlined"
          onClick={onClose}
          isLoading={isLoading}
        >
          {t("common.cancel")}
        </Button>
      </Flex>
    </Modal>
  );
};
