import { pxToRem } from "adminComponents/utils/pxToRem";

export const AdminTopTeacherCard = {
  baseStyle: {
    avatar: {
      alignItems: "center",
      display: "flex",
      "> *:first-child": {
        marginRight: { base: pxToRem(12), md: pxToRem(20) },
      },
    },
    container: {
      alignItems: { base: "flex-start", md: "center" },
      display: "flex",
      flexDirection: { base: "column", md: "row" },
      gap: { base: "none", md: pxToRem(12) },
      justifyContent: "space-between",
      position: "relative",
      width: "100%",
      wordBreak: "break-word",
    },
    content: {
      textAlign: "left",
    },
    divider: {
      borderBottomWidth: pxToRem(2),
      borderColor: "primary.light-gray",
      display: { base: "block", md: "none" },
      opacity: "1",
      margin: `${pxToRem(20)} 0`,
    },
    left: {
      display: { base: "block", md: "flex" },
      alignItems: "center",
    },
    right: {
      display: "flex",
      alignItems: "center",
      flexShrink: 0,
      width: { base: "100%", md: "auto" },
    },
    statContainer: {
      display: "flex",
      gap: pxToRem(10),
    },
  },
};
