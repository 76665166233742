import { useMount } from "react-use";

import { useAsync } from "links/lib/hooks/useAsync";
import { useAxios } from "links/lib/hooks/useAxios";

import { IUseAsyncReturn } from "../../hooks/useAsync";

export interface IFetchGeoResponse {
  city: string;
  country_long: string;
  country_short: string;
  region: string;
  status_code?: number;
  ip_address: string;
}

export default function useFetchGeo(): IUseAsyncReturn<
  IFetchGeoResponse,
  unknown
> {
  const axios = useAxios();

  const fetchGeo = async () => {
    return await axios
      .get("/v1/geo", {
        validateStatus: (status) => {
          return status === 200 || status === 451;
        },
      })
      .then((r) => {
        return {
          ...(r.data as IFetchGeoResponse),
          status_code: r.status,
        };
      })
      .catch((e) => {
        return {
          city: "",
          country_long: "",
          country_short: "",
          region: "",
          ip_address: "",
          status_code: e.response.status,
        };
      });
  };

  const { isLoading, error, data, execute, isSuccess } =
    useAsync<IFetchGeoResponse>(fetchGeo, {
      initState: { isLoading: true },
    });

  useMount(() => {
    execute();
  });

  return {
    isLoading,
    error,
    data,
    execute,
    isSuccess,
  };
}
