import { hoverStyles, pxToRem } from "adminComponents/utils";

interface IProps {
  isSeen: boolean;
}

export const AdminMyStuffCard = {
  baseStyle: ({ isSeen }: IProps): Record<string, unknown> => ({
    card: {
      aspectRatio: "1",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      _hover: hoverStyles({
        color: "primary.golden",
      }),
      _focusVisible: {
        color: "utility.focus",
        outline: "none",
      },
      _after: {
        content: '""',
        backgroundColor: "utility.question-red",
        borderRadius: "100%",
        display: isSeen ? "none" : "block",
        height: pxToRem(16),
        width: pxToRem(16),
        position: "absolute",
        bottom: 0,
        right: 0,
      },
    },
    banner: {
      backgroundColor: "utility.focus",
      borderRadius: pxToRem(20),
      borderBottomLeftRadius: {
        base: 0,
        md: `0 ${pxToRem(20)}`,
      },
      borderTopRightRadius: {
        base: `0 ${pxToRem(10)}`,
        md: `0 ${pxToRem(20)}`,
      },
      display: "flex",
      paddingY: { base: 0, md: pxToRem(3) },
      paddingX: { base: pxToRem(6), md: pxToRem(8) },
      position: "absolute",
      left: pxToRem(3),
      top: pxToRem(3),
    },
    image: {
      aspectRatio: "1",
      padding: "20%",
      objectFit: "contain",
    },
  }),
};
