import React from "react";
import { useTranslation } from "react-i18next";

import { StudentGettingStartedScreen } from "adminComponents/screens/StudentGettingStartedScreen";
import { usePageTrack } from "lib/contexts/analytics";
import { usePageTitle } from "lib/hooks/usePageTitle";
import { AnalyticsPage } from "links/lib/types";
import { useNavigationData } from "screens/StudentDashboard/contexts/StudentNavigationDataProvider";

export const StudentGettingStarted: React.FC = () => {
  const { navigationData } = useNavigationData();
  const { t } = useTranslation("admin", { useSuspense: false });

  usePageTitle(t("studentGettingStarted.title"));
  usePageTrack(AnalyticsPage.StudentDashboard_GettingStarted);

  return <StudentGettingStartedScreen navigationData={navigationData} />;
};
