import { colorMapping } from "adminComponents/theme/colors";
import { hoverStyles, pxToRem } from "adminComponents/utils";

export const AdminBreadcrumb = {
  baseStyle: {
    outer: {
      maxWidth: "100%",
      ol: {
        alignItems: "center",
      },
      li: {
        minWidth: 0,
        maxWidth: "80%",
        float: "left",
      },
    },
    crumb: {
      textStyle: "adminLinkMedium",
      gap: pxToRem(4),
      color: "primary.warm-black",
      textDecoration: "none",
    },
    crumbLink: {
      textStyle: "adminLinkMedium",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      gap: pxToRem(4),
      color: "primary.warm-black",
      _hover: hoverStyles({
        textDecoration: "underline",
        cursor: "pointer",
      }),
      _focus: {
        boxShadow: "none",
      },
      _focusVisible: {
        textDecoration: "underline",
        borderRadius: pxToRem(4),
        outline: `${pxToRem(1)} solid ${colorMapping["utility.focus"]}`,
        boxShadow: "none",
      },
    },
    crumbLast: {
      textStyle: "adminLinkMedium",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      gap: pxToRem(4),
      color: "primary.warm-black",
      textDecoration: "none",
      fontWeight: 600,
    },
    iconContainer: {
      display: "inline-flex",
      marginRight: pxToRem(4),
      div: {
        w: pxToRem(16),
        h: pxToRem(16),
      },
    },
  },
};
