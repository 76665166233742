// import localizations for the momentjs datetime library.
// (en is not imported since the library defaults to English strings).
// Please add to this import list as supportedLangs below grows.
import "moment/locale/es";
import "moment/locale/vi";
import "moment/locale/zh-cn";

// currently supported languages with translations
export const supportedLangs: Array<string> = ["en", "es", "zh", "vi"];

// maps a supported language code ("en") to a human-string ("English")
export const langLabelMap: { [key: string]: string } = {
  en: "English",
  es: "Español",
  zh: "简体中文",
  vi: "Tiếng Việt",
};
