import { Box, Flex } from "@chakra-ui/react";
import { Text } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMeasure, useMount } from "react-use";

import { isRichTextEmpty } from "adminComponents/organisms/RichTextEditor/util";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { PracticeSessionItemVariantType, RichText } from "links/lib/types";
import { Input } from "sessionComponents/atoms/Input";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { useDetectOrientation } from "sessionComponents/theme/hooks/useDetectOrientation";
import { IGenericResponseComponentProps } from "sessionComponents/types";
import { RichTextRenderer } from "sharedComponents/atoms/RichTextRenderer";

import { GenericTextOption } from "../GenericTextOption";
import { ResponseAvatarIcons } from "../ResponseAvatarIcons";

interface ITextInputOptionProps extends IGenericResponseComponentProps {
  disabled?: boolean;
  showInput: boolean;
  showSubmitted: boolean;
  value: string;
  setValue: (value: string) => void;
  prefix?: RichText;
  suffix?: RichText;
}

export const TextInputOption: React.FC<ITextInputOptionProps> = ({
  disabled,
  showInput,
  showSubmitted,
  value,
  setValue,
  variant,
  users,
  prefix,
  suffix,
}) => {
  const { match: currentBreakpoints } = useBreakpoints();
  const [showNumericError, setShowNumericError] = useState(false);
  const hasPrefix = !isRichTextEmpty(prefix as string);
  const hasSuffix = !isRichTextEmpty(suffix as string);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { isPortrait } = useDetectOrientation();

  const { t } = useTranslation("session", {
    useSuspense: false,
  });

  // Blur input on mobile devices to prevent
  // on-screen keyboard from blocking question text
  // on initial mount
  useMount(() => {
    if (isPortrait && inputRef.current) {
      inputRef.current.blur();
    }
  });

  const isNumeric =
    variant === PracticeSessionItemVariantType.CoopTextMatchNumeric;
  const numericRegex = new RegExp(/^[0-9.\-,/ ]+$/g);
  const placeholder = isNumeric
    ? t("textResponseInput.numberPlaceholder")
    : t("textResponseInput.textPlaceholder");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setShowNumericError(false);
    if (isNumeric && value.length) {
      const valid = numericRegex.test(value);
      if (!valid) setShowNumericError(true);
    }

    setValue(value);
  };

  const getInputBorderRadius = () => {
    if (!isNumeric) return undefined;
    else {
      const topLeft = hasPrefix ? 0 : currentBreakpoints.borderRadius;
      const bottomLeft = hasPrefix ? 0 : currentBreakpoints.borderRadius;
      const topRight = hasSuffix ? 0 : currentBreakpoints.borderRadius;
      const bottomRight = hasSuffix ? 0 : currentBreakpoints.borderRadius;

      const points = [topLeft, topRight, bottomRight, bottomLeft];

      return points.map((p) => pxToRem(p)).join(" ");
    }
  };

  return (
    <>
      {showInput && (
        <Flex flexDir="column">
          <Flex width="100%" alignItems="center">
            {hasPrefix && !!prefix && (
              <AppendedText text={prefix} isPrefix error={showNumericError} />
            )}
            <Box position="relative" overflow="hidden" w="full">
              <Input
                ref={inputRef}
                placeholder={placeholder}
                value={value}
                onChange={handleChange}
                disabled={disabled}
                borderRadius={getInputBorderRadius()}
                error={showNumericError}
              />
              {!!users?.length && (
                <ResponseAvatarIcons
                  // note: these need to be numbers bc they are added together in this child component
                  avatarIconSize={currentBreakpoints.avatarIconSize}
                  right={currentBreakpoints.margin}
                  bottom={currentBreakpoints.borderWidth}
                  users={users}
                />
              )}
            </Box>
            {hasSuffix && !!suffix && (
              <AppendedText
                text={suffix}
                isPrefix={false}
                error={showNumericError}
              />
            )}
          </Flex>
          {showNumericError && (
            <Box marginLeft={pxToRem(currentBreakpoints.padding * 0.667)}>
              <Text
                as="span"
                fontSize={pxToRem(currentBreakpoints.fontSize * 0.667)}
                color="utility.question-red"
                variant="inputHelperText"
              >
                {t("textResponseInput.numberInputErrorMessage")}
              </Text>
            </Box>
          )}
        </Flex>
      )}
      {showSubmitted && (
        <GenericTextOption
          text={value}
          variant={variant}
          iconColor="utility.link"
          users={users}
          showTextInputIcon
          showAsSelectable
        />
      )}
    </>
  );
};

interface IAppendedTextProps {
  text: RichText;
  isPrefix: boolean;
  error?: boolean;
}

const AppendedText: React.FC<IAppendedTextProps> = ({
  text,
  isPrefix,
  error,
}) => {
  const { match: currentBreakpoints } = useBreakpoints();
  const borderRadius = isPrefix
    ? `${pxToRem(currentBreakpoints.borderRadius)} 0 0 ${pxToRem(
        currentBreakpoints.borderRadius
      )}`
    : `0 ${pxToRem(currentBreakpoints.borderRadius)} ${pxToRem(
        currentBreakpoints.borderRadius
      )} 0`;

  const [textRef, { width: textRefWidth }] = useMeasure<HTMLDivElement>();
  const [textBoxRef, { width: textBoxRefWidth }] = useMeasure<HTMLDivElement>();
  const [textScale, setTextScale] = useState(1);

  useEffect(() => {
    if (textRefWidth && textBoxRefWidth) {
      if (textRefWidth + 2 * currentBreakpoints.padding > textBoxRefWidth) {
        setTextScale(
          textBoxRefWidth / (textRefWidth + 2 * currentBreakpoints.padding)
        );
      }
    }
  }, [textRefWidth, textBoxRefWidth, currentBreakpoints.padding]);

  return (
    <Flex
      borderRadius={borderRadius}
      borderWidth={pxToRem(currentBreakpoints.borderWidth)}
      borderColor={error ? "utility.question-red" : "primary.tan"}
      backgroundColor="primary.warm-white"
      height={pxToRem(currentBreakpoints.responseHeight)}
      alignItems="center"
      padding={pxToRem(currentBreakpoints.padding)}
      borderRight={isPrefix ? "none" : undefined}
      borderLeft={isPrefix ? undefined : "none"}
      ref={textBoxRef}
      maxW="30%"
      width="30%"
    >
      <Text
        ref={textRef}
        as="div"
        textStyle="gameText"
        fontSize={pxToRem(currentBreakpoints.fontSize)}
        userSelect="none"
        transform={`scale(${textScale})`}
        transformOrigin="center left"
        width="max-content"
      >
        <RichTextRenderer content={text} />
      </Text>
    </Flex>
  );
};
