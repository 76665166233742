import { afterSignInPathSessionKey } from "lib/constants";
import { config } from "links/lib/constants";

interface IArgs {
  url: string;
  popupWidth?: number;
  popupHeight?: number;
  onSignInSuccess: () => void;
}

/**
 * Helper function to open a sign-in popup.
 * Calling this function will register a listener for postMessage events
 * from the popup, and thus should be called only once (e.g. not on every render).
 * If the popup is blocked, the user will be redirected to the sign-in page in the
 * current tab.
 */
export const openSignInPopup = ({
  url,
  popupHeight = 600,
  popupWidth = 400,
  onSignInSuccess,
}: IArgs): void => {
  const windowFeatures = `width=${popupWidth},height=${popupHeight},popup=true`;
  let authPopup = window.open(url, "popup", windowFeatures);

  if (
    !authPopup ||
    authPopup.closed ||
    typeof authPopup.closed === "undefined"
  ) {
    // Popup was blocked; fallback to using this tab
    console.error("Sign in popup was blocked");
    sessionStorage.setItem(afterSignInPathSessionKey, window.location.pathname);

    // Removes the bit of state telling the OAuth callback handler to treat it as a popup
    const u = new URL(url);
    const state = u.searchParams.get("state");
    if (state) {
      const stateArray = state.split(":");
      if (stateArray.length === 5 && stateArray[4] === "true") {
        stateArray.splice(4);
        u.searchParams.set("state", stateArray.join(":"));
      }
    }
    window.location.href = u.toString();
    authPopup = window;
  }

  const handlePopupMessage = (event: MessageEvent) => {
    if (event.origin !== config.baseUrl) return;
    if (event.data?.popupSignIn?.success) {
      onSignInSuccess();
      clearInterval(pingPopupInterval);
      window.removeEventListener("message", handlePopupMessage);
    }
  };

  window.addEventListener("message", handlePopupMessage, false);

  const pingPopupInterval = setInterval(() => {
    if (!authPopup) return;

    authPopup.postMessage("ping", config.baseUrl);

    if (authPopup && authPopup.closed) {
      clearInterval(pingPopupInterval);
      window.removeEventListener("message", handlePopupMessage);
    }
  }, 500);
};
