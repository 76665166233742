import type { ILocalStore, ISessionStore } from "links/lib/types";

export const localStore: ILocalStore = {
  getItem(keyName: string) {
    return Promise.resolve(localStorage.getItem(keyName));
  },
  setItem(keyName: string, value: string) {
    localStorage.setItem(keyName, value);
    return Promise.resolve();
  },
  deleteItem(keyName: string) {
    localStorage.removeItem(keyName);
    return Promise.resolve();
  },
};

export const sessionStore: ISessionStore = {
  getItem(keyName: string) {
    return Promise.resolve(sessionStorage.getItem(keyName));
  },
  setItem(keyName: string, value: string) {
    sessionStorage.setItem(keyName, value);
    return Promise.resolve();
  },
  deleteItem(keyName: string) {
    sessionStorage.removeItem(keyName);
    return Promise.resolve();
  },
};
