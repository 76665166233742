import { UseMutationResult, useMutation } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { useAuth } from "../auth";

export interface IDeleteAuthUserArgs {
  onSuccess?: () => void;
  onError?: (error: unknown) => void;
}

export default function useDeleteAuthUser({
  onSuccess,
  onError,
}: IDeleteAuthUserArgs): UseMutationResult<unknown, unknown, void> {
  const axios = useAxios();
  const { authUser } = useAuth();

  const deleteAuthUser = async () => {
    if (!authUser) {
      throw new Error("Auth User not set");
    }

    const { id } = authUser;

    const response = await axios({
      method: "delete",
      url: `/v1/users/${id}`,
    });

    return response;
  };

  const mutation = useMutation(deleteAuthUser, { onSuccess, onError });

  return mutation;
}
