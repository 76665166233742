import { FormControl, SimpleGrid } from "@chakra-ui/react";
import React, { useEffect } from "react";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { usePrevious, useUnmount } from "react-use";

import { FormErrorMessage } from "adminComponents/atoms/FormErrorMessage";
import { FormLabel } from "adminComponents/atoms/FormLabel";
import { Text } from "adminComponents/atoms/Text";
import { useEditPracticeSetMediaModalContext } from "adminComponents/contexts/EditPracticeSetMediaModalContext";
import { CreateQuestionFooter } from "adminComponents/molecules/CreateQuestionFooter";
import { DiagramEditorFormControl } from "adminComponents/molecules/DiagramEditorFormControl";
import { IDiagramEditorPoint } from "adminComponents/molecules/DiagramEditorFormControl/DiagramEditor";
import { QuestionTypeDescription } from "adminComponents/molecules/QuestionTypeDescription";
import { AddMediaModal } from "adminComponents/organisms/AddMediaModal";
import { RichTextEditor } from "adminComponents/organisms/RichTextEditor";
import { isRichTextEmpty } from "adminComponents/organisms/RichTextEditor/util";
import { CreateQuestionFormDataType } from "adminComponents/utils";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { useAuth } from "links/lib/features/auth";
import { IDiagramLabel, RichValue } from "links/lib/types";
import { generateEmptySlateState } from "links/lib/util";

export interface CreateDiagramTabForm {
  imageUrl: string;
  imageAltText: string;
  prompt: RichValue;
  labels: IDiagramLabel[];
  requiresHigherOrderThinking: boolean;
}

interface IProps {
  defaultValue: CreateQuestionFormDataType;
  hasFeedback: boolean;
  canSubmit: boolean;
  isSubmitting: boolean;
  handleSaveAndClose: (form: CreateDiagramTabForm) => void;
  handleChange: (isValid: boolean, form?: CreateDiagramTabForm) => void;
  handleNextTab: () => void;
  handleDirty?: () => void;
}

const initialValue: CreateDiagramTabForm = {
  imageAltText: "",
  imageUrl: "",
  prompt: generateEmptySlateState(),
  labels: [],
  requiresHigherOrderThinking: false,
};

export const CreateQuestionDiagramTab: React.FC<IProps> = ({
  handleSaveAndClose,
  handleChange,
  handleNextTab,
  defaultValue,
  hasFeedback,
  canSubmit,
  isSubmitting,
  handleDirty,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const mediaModalProps = useEditPracticeSetMediaModalContext();
  const { hasNoPremiumAccess } = useAuth();

  const formMethods = useForm<CreateDiagramTabForm>({
    defaultValues: defaultValue.diagram ?? initialValue,
    mode: "onChange",
  });

  const {
    handleSubmit,
    setValue,
    watch,
    control,
    getValues,
    setError,
    clearErrors,
    formState: { errors, isValid, touchedFields, isDirty },
  } = formMethods;

  const _handleSubmit = (data: CreateDiagramTabForm) => {
    handleSaveAndClose(data);
  };

  useEffect(() => {
    if (isDirty && handleDirty) handleDirty();
  }, [isDirty, handleDirty]);

  useUnmount(() => {
    handleChange(isValid, getValues());
  });

  const prevIsValid = usePrevious(isValid);
  useEffect(() => {
    if (prevIsValid !== isValid) {
      handleChange(isValid);
    }
  }, [isValid, handleChange, prevIsValid]);

  const {
    fields: labelFields,
    append: appendLabel,
    remove: removeLabel,
  } = useFieldArray({
    control,
    name: "labels",
    rules: {
      validate: {
        minLength2: (v) =>
          v.length >= 2 ||
          (t("diagramEditor.labelsInvalidLabelCount") as string),
      },
    },
  });

  const [imageAltText, imageUrl, requiresHigherOrderThinking] = watch([
    "imageAltText",
    "imageUrl",
    "requiresHigherOrderThinking",
  ]);

  useEffect(() => {
    if (!imageUrl) {
      setError("imageUrl", {
        type: "custom",
        message: t("createQuestion.errImageUrlRequired") as string,
      });
    } else {
      clearErrors("imageUrl");
    }
  }, [imageUrl, setError, clearErrors, t]);

  const _handleNextTab = async () => {
    handleNextTab();
  };

  const handleAddDiagramPoint = (point: IDiagramEditorPoint) => {
    handleAddDiagramLabel("", point);
  };

  const handleMoveDiagramPoint = (
    index: number,
    point: IDiagramEditorPoint
  ) => {
    const { x_offset_ratio, y_offset_ratio } = point;
    setValue(`labels.${index}.x_offset_ratio`, x_offset_ratio);
    setValue(`labels.${index}.y_offset_ratio`, y_offset_ratio);
  };

  const handleAddDiagramLabel = (label: string, point: IDiagramEditorPoint) => {
    appendLabel({
      label,
      x_offset_ratio: point.x_offset_ratio,
      y_offset_ratio: point.y_offset_ratio,
    });
  };

  const handleDeleteDiagramLabel = (index: number) => {
    removeLabel(index);
  };

  const handleChangeRequiresHigherOrderThinking = (checked: boolean) => {
    setValue("requiresHigherOrderThinking", checked);
  };

  const handleInsertMediaFormWrapper = (
    imageUrl: string,
    imageAltText: string,
    audioUrl?: string,
    videoUrl?: string
  ) => {
    setValue("imageUrl", imageUrl);
    setValue("imageAltText", imageAltText);
    mediaModalProps.handleInsertMedia(
      imageUrl,
      imageAltText,
      audioUrl,
      videoUrl
    );
  };

  const handleRemoveMedia = () => {
    setValue("imageAltText", "");
    setValue("imageUrl", "");
    setValue("labels", []);
    mediaModalProps.handleRemoveImage();
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(_handleSubmit)}>
        <SimpleGrid
          py={pxToRem(40)}
          px={[
            "admin.flyout.mobileXPadding",
            null,
            "admin.flyout.desktopXPadding",
          ]}
          gap={pxToRem(40)}
        >
          <QuestionTypeDescription
            icon="diagram_outlined"
            iconColor="utility.badge-red"
            title={t("createDiagramFlyout.diagramQuestionDropdownTitle")}
            subTitle={t("createDiagramFlyout.diagramQuestionDropdownSubTitle")}
          />
          <FormControl
            isRequired
            variant="adminFormControl"
            isInvalid={!!errors.prompt && !!touchedFields.prompt}
            minWidth={0}
          >
            <FormLabel>{t("createQuestion.labelDiagramPrompt")}</FormLabel>
            <Text
              variant="adminP2"
              color="primary.dark-gray"
              marginBottom={pxToRem(16)}
              verticalAlign="middle"
            >
              {t("createQuestion.diagramPromptDescription")}
            </Text>
            <Controller
              control={control}
              name="prompt"
              rules={{
                validate: {
                  isNotEmpty: (v) =>
                    !isRichTextEmpty(v) ||
                    (t("createQuestion.errPromptRequired") as string),
                },
              }}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { isTouched, error },
              }) => (
                <RichTextEditor
                  aria-label={t("createQuestion.placeholderPrompt")}
                  ref={ref}
                  name={name}
                  handleChangeRaw={onChange}
                  handleBlur={onBlur}
                  value={value}
                  placeholder={t("createQuestion.placeholderPrompt")}
                  hasError={isTouched && !!error}
                  disabled={hasNoPremiumAccess}
                />
              )}
            />
            <FormErrorMessage>{errors.prompt?.message}</FormErrorMessage>
          </FormControl>
          <SimpleGrid gap={pxToRem(16)}>
            <DiagramEditorFormControl
              disabled={hasNoPremiumAccess}
              diagramImageAltText={imageAltText}
              diagramImageUrl={imageUrl}
              diagramLabels={labelFields.map((l) => ({
                label: l.label,
                id: l.id,
                x_offset_ratio: l.x_offset_ratio,
                y_offset_ratio: l.y_offset_ratio,
              }))}
              handleAddPoint={handleAddDiagramPoint}
              handleMovePoint={handleMoveDiagramPoint}
              handleDeleteLabel={handleDeleteDiagramLabel}
              handleAddImage={mediaModalProps.handleOpen}
              handleResetImage={handleRemoveMedia}
            />
          </SimpleGrid>
          <CreateQuestionFooter
            requiresHigherOrderThinking={requiresHigherOrderThinking}
            handleChangeRequireHigherOrderThinking={
              handleChangeRequiresHigherOrderThinking
            }
            handleNextTab={_handleNextTab}
            handleSaveAndClose={handleSubmit(_handleSubmit)}
            nextSectionTextCopies={{
              nextSectionCta: hasFeedback
                ? t("createQuestion.editFeedback")
                : t("createQuestion.addFeedback"),
              nextSectionTitle: t("createQuestion.feedback"),
              nextSectionDescription: t("createQuestion.feedbackDescription"),
              nextSectionIcon: hasFeedback ? "edit_outlined" : "add",
            }}
            canSubmit={canSubmit}
            isSubmitting={isSubmitting}
          />
        </SimpleGrid>
        <AddMediaModal
          {...mediaModalProps}
          imageOnly
          imageUrlToAdd={imageUrl}
          imageAltText={imageAltText}
          handleInsertMedia={handleInsertMediaFormWrapper}
          handleRemoveImage={handleRemoveMedia}
        />
      </form>
    </FormProvider>
  );
};
