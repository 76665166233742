import { useCallback } from "react";
import { v4 as uuidv4 } from "uuid";

import { shuffleArray } from "links/lib/hooks/useShuffle";
import { ICustomSessionGroup, IUser } from "links/lib/types";

interface IUseSessionGroupHandlersProps {
  studentMap: Map<string, { student: IUser; sessionGroupId?: string }>;
  setStudentMap: (
    map: Map<string, { student: IUser; sessionGroupId?: string }>
  ) => void;
  sessionGroupsMap: Map<string, ICustomSessionGroup>;
  setSessionGroupsMap: (map: Map<string, ICustomSessionGroup>) => void;
  selectedGroup?: string;
  setSelectedGroup: (selectedGroup?: string) => void;
  students: IUser[];
  setTouched: (touched: boolean) => void;
}

interface IUseSessionGroupHandlersResponse {
  handleStudentSelect: (studentId: string, selected: boolean) => void;
  handleClearGroups: () => void;
  handleRandomizeGroups: () => void;
  handleAddGroup: () => void;
  handleRemoveGroup: (groupId: string) => void;
}

export const useSessionGroupHandlers = ({
  studentMap,
  setStudentMap,
  sessionGroupsMap,
  setSessionGroupsMap,
  selectedGroup,
  setSelectedGroup,
  students,
  setTouched,
}: IUseSessionGroupHandlersProps): IUseSessionGroupHandlersResponse => {
  const handleStudentSelect = useCallback(
    (studentId, selected) => {
      const user = studentMap.get(studentId);
      if (!user) return;

      const newStudentMap = new Map(studentMap);
      const newSessionGroupsMap = new Map(sessionGroupsMap);
      if (selected) {
        if (!selectedGroup || user.sessionGroupId) return;
        newStudentMap.set(studentId, {
          student: user.student,
          sessionGroupId: selectedGroup,
        });
        const newSessionGroup = newSessionGroupsMap.get(selectedGroup);
        if (newSessionGroup && newSessionGroup.user_ids.length <= 2) {
          setStudentMap(newStudentMap);
          if (newSessionGroup?.user_ids) {
            newSessionGroup.user_ids.push(studentId);
          } else {
            newSessionGroup.user_ids = [studentId];
          }
          newSessionGroupsMap.set(selectedGroup, newSessionGroup);
          setSessionGroupsMap(newSessionGroupsMap);
        }
      } else {
        if (!user.sessionGroupId) return;
        const prevSessionGroup = newSessionGroupsMap.get(user.sessionGroupId);
        if (!prevSessionGroup) return;
        prevSessionGroup.user_ids = prevSessionGroup?.user_ids.filter(
          (userId) => userId !== studentId
        );
        newSessionGroupsMap.set(user.sessionGroupId, prevSessionGroup);
        setSessionGroupsMap(newSessionGroupsMap);
        newStudentMap.set(studentId, {
          student: user.student,
        });
        setStudentMap(newStudentMap);
      }
      setTouched(true);
    },
    [
      studentMap,
      sessionGroupsMap,
      selectedGroup,
      setSessionGroupsMap,
      setStudentMap,
      setTouched,
    ]
  );

  const handleClearGroups = useCallback(() => {
    const newStudentMap = new Map();
    const newSessionGroupsMap = new Map();
    students.forEach((student) => newStudentMap.set(student.id, { student }));

    setTouched(true);
    setStudentMap(newStudentMap);
    setSessionGroupsMap(newSessionGroupsMap);
    setSelectedGroup("");
  }, [
    students,
    setStudentMap,
    setSessionGroupsMap,
    setTouched,
    setSelectedGroup,
  ]);

  const handleRandomizeGroups = useCallback(() => {
    const newStudentMap = new Map();
    const newSessionGroupsMap = new Map();

    const shuffledStudents = shuffleArray(students);

    const groupCount = Math.ceil(students.length / 3);

    const sessionGroups: { id: string; user_ids: string[] }[] = new Array(
      groupCount
    )
      .fill({})
      .map(() => ({
        id: uuidv4(),
        user_ids: [],
      }));

    let groupIndex = 0;
    shuffledStudents.forEach((student) => {
      sessionGroups[groupIndex].user_ids.push(student.id);
      newStudentMap.set(student.id, {
        student,
        sessionGroupId: sessionGroups[groupIndex].id,
      });

      groupIndex++;
      if (groupIndex >= groupCount) {
        groupIndex = 0;
      }
    });

    sessionGroups.forEach((sessionGroup) => {
      newSessionGroupsMap.set(sessionGroup.id, sessionGroup);
    });

    setTouched(true);
    setStudentMap(newStudentMap);
    setSessionGroupsMap(newSessionGroupsMap);
  }, [students, setStudentMap, setSessionGroupsMap, setTouched]);

  const handleAddGroup = useCallback(() => {
    const newSessionGroupsMap = new Map(sessionGroupsMap);
    const sessionGroup = {
      id: uuidv4(),
      user_ids: [],
    };

    newSessionGroupsMap.set(sessionGroup.id, sessionGroup);
    setTouched(true);
    setSessionGroupsMap(newSessionGroupsMap);
    setSelectedGroup(sessionGroup.id);
  }, [sessionGroupsMap, setSessionGroupsMap, setSelectedGroup, setTouched]);

  const handleRemoveGroup = useCallback(
    (groupId: string) => {
      const newStudentMap = new Map(studentMap);
      const newSessionGroupsMap = new Map(sessionGroupsMap);

      const removedSessionGroup = newSessionGroupsMap.get(groupId);

      if (!removedSessionGroup) return;

      removedSessionGroup.user_ids.forEach((userId) => {
        const student = newStudentMap.get(userId);
        if (!student) return;

        student.sessionGroupId = undefined;

        newStudentMap.set(userId, student);
      });

      newSessionGroupsMap.delete(groupId);

      setTouched(true);
      setStudentMap(newStudentMap);
      setSessionGroupsMap(newSessionGroupsMap);

      if (selectedGroup === groupId) {
        const groups = [...newSessionGroupsMap.values()];
        const lastGroup = groups[groups.length - 1];

        if (lastGroup) {
          setSelectedGroup(lastGroup.id);
        } else {
          setSelectedGroup(undefined);
        }
      }
    },
    [
      studentMap,
      sessionGroupsMap,
      setStudentMap,
      setSessionGroupsMap,
      setSelectedGroup,
      selectedGroup,
      setTouched,
    ]
  );

  return {
    handleStudentSelect,
    handleClearGroups,
    handleRandomizeGroups,
    handleAddGroup,
    handleRemoveGroup,
  };
};
