import {
  Box,
  BoxProps,
  ComponentWithAs,
  Spinner,
  forwardRef,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import React, { ReactNode } from "react";

import { ColorScheme } from "adminComponents";
import { Icon, IconType } from "adminComponents/atoms/Icon";

import { ReactComponent as Avatar1Svg } from "./resource/avatar1.svg";
import { ReactComponent as Avatar2Svg } from "./resource/avatar2.svg";
import { ReactComponent as Avatar3Svg } from "./resource/avatar3.svg";
import { ReactComponent as Avatar4Svg } from "./resource/avatar4.svg";
import { ReactComponent as Avatar5Svg } from "./resource/avatar5.svg";
import { ReactComponent as Avatar6Svg } from "./resource/avatar6.svg";
import { ReactComponent as Avatar7Svg } from "./resource/avatar7.svg";
import { ReactComponent as Type1Svg } from "./resource/type1.svg";
import { ReactComponent as Type2Svg } from "./resource/type2.svg";

export enum AvatarShapes {
  avatar1 = "avatar1",
  avatar2 = "avatar2",
  avatar3 = "avatar3",
  avatar4 = "avatar4",
  avatar5 = "avatar5",
  avatar6 = "avatar6",
  avatar7 = "avatar7",
}

export interface IProps extends BoxProps {
  ariaLabel: string;
  colorScheme?: ColorScheme;
  colorSchemeHover?: ColorScheme;
  icon: IconType;
  iconColor?: ColorScheme | "inherit";
  hideIcon?: boolean;
  shape: "type1" | "type2" | "circle" | AvatarShapes;
  variant?:
    | "default"
    | "outline"
    | "ghost"
    | "adminCustomization"
    | "adminLibraryPracticeSetCard"
    | "adminNavIconButton"
    | "adminPlayButton";
  disabled?: boolean;
  isLoading?: boolean;
  isFullSizeIcon?: boolean;
}

export type Shape = IProps["shape"];

const BackgroundAssets: Record<Shape, ReactNode> = {
  type1: <Type1Svg />,
  type2: <Type2Svg />,
  avatar1: <Avatar1Svg />,
  avatar2: <Avatar2Svg />,
  avatar3: <Avatar3Svg />,
  avatar4: <Avatar4Svg />,
  avatar5: <Avatar5Svg />,
  avatar6: <Avatar6Svg />,
  avatar7: <Avatar7Svg />,
  circle: null,
};

export const IconButton: ComponentWithAs<"button", IProps> = forwardRef<
  IProps,
  "button"
>(
  (
    {
      ariaLabel,
      colorScheme = "primary.golden",
      colorSchemeHover = "primary.golden-hover",
      icon,
      iconColor = "primary.warm-black",
      hideIcon = false,
      shape = "type1",
      variant = "default",
      isLoading,
      children,
      isFullSizeIcon = false,
      ...props
    },
    ref
  ) => {
    const isSVG = shape !== "circle";
    const styles = useMultiStyleConfig("AdminIconButton", {
      variant,
      colorScheme,
      colorSchemeHover,
      iconColor,
      isSVG,
      isFullSizeIcon,
    });

    const BackgroundAsset = (
      shape: Shape,
      width?: unknown,
      height?: unknown
    ) => {
      const asset = BackgroundAssets[shape];
      if (React.isValidElement(asset)) {
        return React.cloneElement(asset, {
          width: width || 34,
          height: height || 34,
        });
      }

      return asset;
    };

    return (
      <Box
        as="button"
        {...props}
        __css={styles.box}
        aria-label={ariaLabel}
        aria-role="img"
        role="img"
        ref={ref}
      >
        {variant !== "adminPlayButton" ? (
          <>
            {BackgroundAsset(shape, props.width, props.height)}
            {!hideIcon && (
              <Icon
                as="span"
                aria-hidden="true"
                icon={icon}
                iconColor={iconColor}
                styles={styles.icon}
              />
            )}
          </>
        ) : (
          <>
            <Box as="span" sx={styles.iconContainer}>
              {BackgroundAssets[shape]}
              {isLoading ? (
                <Box as="span" sx={styles.spinner}>
                  <Spinner color="black" />
                </Box>
              ) : (
                !hideIcon && (
                  <Icon
                    as="span"
                    aria-hidden="true"
                    icon={icon}
                    iconColor={iconColor}
                    styles={styles.icon}
                  />
                )
              )}
            </Box>
          </>
        )}

        {children}
      </Box>
    );
  }
);
