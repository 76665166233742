import { Box, Button, HStack, Text, useToast } from "@chakra-ui/react";
import moment, { Moment } from "moment";
import React from "react";
import { useCopyToClipboard } from "react-use";

import { config, momentFormatString } from "links/lib/constants";
import { IUserTrialExtension } from "links/lib/types";

interface IUserTrialExtensionCardProps {
  userTrialExtension: IUserTrialExtension;
  onClick: () => void;
  onDelete: () => void;
}

export const UserTrialExtensionCard: React.FC<IUserTrialExtensionCardProps> = ({
  userTrialExtension,
  onClick,
  onDelete,
}) => {
  const showToast = useToast();
  const [, copy] = useCopyToClipboard();

  const onCopyLink = () => {
    copy(`${config.baseUrl}/trials/${userTrialExtension.guid}`);
    showToast({ title: "Link Copied" });
  };

  let extensionDateMoment: Moment | undefined = moment(
    userTrialExtension.extended_to_date
  );
  if (extensionDateMoment.unix() <= 0) {
    extensionDateMoment = undefined;
  }
  const formattedExtensionDate = extensionDateMoment
    ? extensionDateMoment.format(momentFormatString)
    : "";

  let expirationDateMoment: Moment | undefined = moment(
    userTrialExtension.expires_at
  );
  if (expirationDateMoment.unix() <= 0) {
    expirationDateMoment = undefined;
  }
  const formattedExpirationDate = expirationDateMoment
    ? expirationDateMoment.format(momentFormatString)
    : "";

  let formattedOrganizationId = userTrialExtension.required_organization_id;
  if (formattedOrganizationId === "0") {
    formattedOrganizationId = "";
  }

  return (
    <Box
      cursor="pointer"
      h="fit-content"
      p="6px"
      minH="100px"
      minW="300px"
      borderWidth="3px"
      borderRadius="xl"
      borderColor="gray"
      onClick={onClick}
    >
      <HStack>
        <Text>GUID:</Text>
        <Text>{userTrialExtension.guid}</Text>
      </HStack>
      <HStack>
        <Text>Extend To Date:</Text>
        <Text>{formattedExtensionDate}</Text>
      </HStack>
      <HStack>
        <Text>Extend By Days:</Text>
        <Text>{userTrialExtension.extended_by_days || ""}</Text>
      </HStack>
      <HStack>
        <Text>Expire At:</Text>
        <Text>{formattedExpirationDate}</Text>
      </HStack>
      <HStack>
        <Text>Organization ID:</Text>
        <Text>{formattedOrganizationId}</Text>
      </HStack>
      <HStack>
        <Text>Domain:</Text>
        <Text>{userTrialExtension.required_domain}</Text>
      </HStack>
      <HStack>
        <Text>Max Use Count:</Text>
        <Text>{userTrialExtension.max_use_count || ""}</Text>
      </HStack>
      <HStack>
        <Text>Prevent Stacking:</Text>
        <Text>{userTrialExtension.prevent_stacking ? "true" : "false"}</Text>
      </HStack>
      <HStack>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            if (
              window.confirm(
                "Do you really want to delete this User Trial Extension?"
              )
            ) {
              onDelete();
            }
          }}
        >
          Delete
        </Button>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            onCopyLink();
          }}
        >
          Copy Link
        </Button>
      </HStack>
    </Box>
  );
};
