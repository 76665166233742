import { Box, Text } from "@chakra-ui/react";
import React from "react";
import { animated } from "react-spring";

import { pxToRem } from "adminComponents/utils/pxToRem";
import {
  CoverImageBGPatternType,
  RemixBGPatternType,
  SessionSplashAnimationNames,
} from "links/lib/types";
import { InterstitialBackground } from "sessionComponents/atoms/InterstitialBackground";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { useRoundSplashAnimation } from "sharedComponents/hooks/useRoundSplashAnimation";

export interface ITextInterstitialProps {
  mainText: string;
  secondaryText?: string;
  splashAnimationName: SessionSplashAnimationNames;
  containerStyle?: { [key: string]: unknown };
  pattern?: CoverImageBGPatternType | RemixBGPatternType;
}

const AnimatedBox = animated(Box);
const AnimatedText = animated(Text);

export const TextInterstitial: React.FC<ITextInterstitialProps> = ({
  mainText,
  secondaryText = "",
  splashAnimationName,
  containerStyle,
  pattern,
}) => {
  const { match: currentBreakpoints, fontSize2x } = useBreakpoints();
  const { style, mainRef, secondaryRef } = useRoundSplashAnimation({
    animationType: splashAnimationName,
    reverse: false,
  });

  return (
    <InterstitialBackground pattern={pattern}>
      <AnimatedBox
        style={containerStyle}
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        overflow="hidden"
      >
        <AnimatedText
          style={style.main}
          ref={mainRef}
          textColor="link.link-blue"
          textStyle="gameDisplay"
          fontSize={pxToRem(fontSize2x)}
        >
          {mainText}
        </AnimatedText>
        <AnimatedText
          ref={secondaryRef}
          style={style.secondary}
          textColor="pramary.warm-black"
          textStyle="gameTextWeighted"
          fontSize={pxToRem(currentBreakpoints.fontSize)}
        >
          {secondaryText}
        </AnimatedText>
      </AnimatedBox>
    </InterstitialBackground>
  );
};
