import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom";

import { LoadingSpinner } from "adminComponents/atoms/LoadingSpinner";
import { UpgradeCallToActionCard } from "adminComponents/atoms/UpgradeCallToActionCard";
import { ImproveOrgUsageCard } from "adminComponents/molecules/ImproveOrgUsageCard";
import { TopOrgUsageCard } from "adminComponents/molecules/TopOrgUsageCard";
import { AdminHomepage, AdminTab } from "adminComponents/screens/AdminHomepage";
import { useErrorToast } from "adminComponents/utils/toast";
import { usePageTrack } from "lib/contexts/analytics";
import { usePageTitle } from "lib/hooks/usePageTitle";
import { useAuth } from "links/lib/features/auth";
import {
  useFetchOrganizationPracticeMinutes,
  useFetchOrganizationUsagePercent,
  useFetchOrganizationUsers,
} from "links/lib/features/organizations";
import { AnalyticsPage } from "links/lib/types";
import { AssignmentsTab } from "screens/AdminHome/components/AssignmentsTab";
import { SessionsTab } from "screens/AdminHome/components/SessionsTab";
import { useNavigationData } from "screens/TeacherDashboard/contexts/TeacherNavigationDataProvider";

import { StudentSnapshot } from "./components/StudentSnapshot";

const AdminHome: React.FC = () => {
  const { authUser, isFeatureEnabled, hasNoPremiumAccess } = useAuth();
  const { navigationData } = useNavigationData();
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });
  const history = useHistory();
  const isSessionsTab = useRouteMatch("/a/reporting/sessions");
  const isAssignmentsTab = useRouteMatch("/a/reporting/assignments");

  usePageTitle(t("adminHomeContainer.pageTitle"));
  usePageTrack(AnalyticsPage.AdminDashboard_Home);

  const tabIndex = useMemo(() => {
    if (isSessionsTab) {
      return AdminTab.Sessions;
    }

    if (isAssignmentsTab) {
      return AdminTab.Assignments;
    }
  }, [isAssignmentsTab, isSessionsTab]);

  const handleTabChange = useCallback(
    (tab: AdminTab) => {
      switch (tab) {
        case AdminTab.Sessions:
          history.push(`/a/reporting/sessions`);
          return;
        case AdminTab.Assignments:
          history.push(`/a/reporting/assignments`);
          return;
      }
    },
    [history]
  );

  const practiceMinutesFetch = useFetchOrganizationPracticeMinutes({
    days_ago: 0,
    include_top_org_check: isFeatureEnabled("playtime.enable_top_districts"),
    refetchInterval: 60000,
  });
  const orgUsersFetch = useFetchOrganizationUsers({ page: 1, perPage: 1 });
  const orgUsagePercentFetch = useFetchOrganizationUsagePercent({
    enabled: isFeatureEnabled("playtime.enable_top_districts"),
  });

  useErrorToast(practiceMinutesFetch.error);
  useErrorToast(orgUsersFetch.error);
  useErrorToast(orgUsagePercentFetch.error);

  const isLoading = orgUsersFetch.isLoading;

  if (!authUser) {
    return <LoadingSpinner />;
  }

  let sideCard = undefined;

  if (isFeatureEnabled("playtime.enable_top_districts")) {
    sideCard = <ImproveOrgUsageCard />;
    if (hasNoPremiumAccess) {
      sideCard = (
        <UpgradeCallToActionCard
          label={t("upgradeCTA.upgrade")}
          cta={t("upgradeCTA.upgrade")}
          upgradeText={t("districtAdminHomepage.usage.upgradeDescription")}
        />
      );
    } else if (
      orgUsagePercentFetch.data?.top_half_of_all_organizations ||
      orgUsagePercentFetch.data?.top_third_of_all_organizations
    ) {
      sideCard = (
        <TopOrgUsageCard
          topThird={orgUsagePercentFetch.data?.top_third_of_all_organizations}
        />
      );
    }
  }

  return (
    <AdminHomepage
      navigationData={navigationData}
      authUser={authUser}
      isLoading={isLoading}
      tabIndex={tabIndex}
      handleTabChange={handleTabChange}
      practiceMinutes={practiceMinutesFetch.data?.practice_minutes || 0}
      practiceMinutesIsLoading={practiceMinutesFetch.isLoading}
      topHalfPracticeMinutes={
        practiceMinutesFetch.data?.top_half_of_all_organizations
      }
      totalOrgUsers={orgUsersFetch.data?.total_count || 0}
    >
      <Switch>
        <Redirect from="/a/reporting" exact={true} to="/a/reporting/sessions" />
        <Route path="/a/reporting/sessions">
          <SessionsTab
            subjects={navigationData.subjects}
            gradeLevels={navigationData.gradeLevels}
            isLoading={orgUsagePercentFetch.isLoading}
            sideCard={sideCard}
          />
        </Route>
        <Route path="/a/reporting/assignments">
          <AssignmentsTab
            subjects={navigationData.subjects}
            gradeLevels={navigationData.gradeLevels}
            isLoading={orgUsagePercentFetch.isLoading}
            sideCard={sideCard}
          />
        </Route>
      </Switch>
      <StudentSnapshot />
    </AdminHomepage>
  );
};

export default AdminHome;
