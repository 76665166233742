export const registerObserver = (
  targetSelector: string,
  navHeight: string,
  effectedElement: string
): IntersectionObserver => {
  const options = {
    rootMargin: navHeight + " 0px 0px 0px",
    threshold: 1,
  };

  const observer = new IntersectionObserver(
    (entries: IntersectionObserverEntry[]) => {
      const first = entries[0];
      const customEvent = new CustomEvent("nav-intersect", {
        detail: {
          shouldBeTransparent: first.isIntersecting,
          effectedElement: effectedElement,
        },
      });
      window.dispatchEvent(customEvent);
    },
    options
  );

  const targets = document.querySelectorAll(targetSelector);
  targets.forEach((target) => observer.observe(target));

  return observer;
};

export default registerObserver;
