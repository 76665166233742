import {
  Box,
  Progress as ChakraProgressBar,
  ProgressProps as ChakraProgressBarProps,
  Flex,
  keyframes,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import React from "react";
import { usePreviousDistinct } from "react-use";

import { ColorScheme } from "adminComponents";
import { Icon, IconType } from "adminComponents/atoms/Icon";
import { pxToRem } from "adminComponents/utils";

type GoalIcon = {
  iconName: IconType;
  iconBackground?: ColorScheme;
  iconSrc?: string;
  boxSize?: string;
  borderColor?: ColorScheme;
  borderWidthPx?: number;
};

type ProgressBarVariant =
  | "adminStudentGoal"
  | "adminStudentMonthlyChallenge"
  | "adminTeacherQuestionCorrect"
  | "adminTeacherQuestionIncorrect"
  | "adminLevelProgress"
  | "adminStriped";

const defaultIconBackground: Record<ProgressBarVariant, ColorScheme> = {
  adminStudentGoal: "utility.focus",
  adminStudentMonthlyChallenge: "utility.orange",
  adminTeacherQuestionCorrect: "utility.question-green",
  adminTeacherQuestionIncorrect: "primary.dark-gray",
  adminLevelProgress: "primary.light-gray",
  adminStriped: "utility.focus",
};

export interface IProps extends ChakraProgressBarProps {
  variant?: ProgressBarVariant;
  icon?: GoalIcon | JSX.Element;
}

export const ProgressBar: React.FC<IProps> = ({
  variant = "adminStudentGoal",
  icon,
  value,
  ...props
}) => {
  const prevVal = usePreviousDistinct(value);
  const fill = keyframes`
  from {width: ${prevVal ?? 0};}
  to {width: ${value}}
`;

  const iconIsJSXElement = React.isValidElement(icon);
  const goalIcon = iconIsJSXElement ? undefined : (icon as GoalIcon);

  const hasIcon = !!icon;
  const styles = useMultiStyleConfig("AdminProgress", {
    variant,
    hasIcon,
    fill,
    iconBorderColor: goalIcon?.borderColor,
    iconBorderWidthPx: goalIcon?.borderColor,
  });
  return (
    <Flex alignItems="center">
      <ChakraProgressBar
        sx={styles.track}
        variant={variant}
        value={value}
        {...props}
      />
      {hasIcon && !iconIsJSXElement && (
        <Box sx={styles.iconContainer}>
          <Icon
            decorative
            boxSize={goalIcon?.boxSize}
            icon={goalIcon?.iconName ?? "star"}
            iconColor="primary.white"
            iconSrc={goalIcon?.iconSrc}
            variant="circleWithBackgroundColor"
            backgroundColor={
              goalIcon?.iconBackground ?? defaultIconBackground[variant]
            }
            iconStyles={
              goalIcon?.iconSrc
                ? { width: pxToRem(35), height: pxToRem(35) }
                : undefined
            }
          />
        </Box>
      )}
      {hasIcon && iconIsJSXElement && icon}
    </Flex>
  );
};
