import { CSSObject, Flex, FormControl } from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Dropdown, IOption } from "adminComponents/atoms/Dropdown";
import { FormErrorMessage } from "adminComponents/atoms/FormErrorMessage";
import { FormLabel } from "adminComponents/atoms/FormLabel";
import {
  sortedCountries as countries,
  getRegionsForCountry,
} from "links/lib/geo";
import { langLabelMap as languages } from "links/lib/lang";
import { capitalizeFirstLetter } from "links/lib/util";

import { Field, Formik } from "../../TeacherAccountInformationForm";
import {
  asyncSetFieldTouched,
  fields,
  getTimezonesForCountry,
  isLocationFormValid,
} from "./formConfig";

interface IProps {
  form: Formik;
  setCanProceed?: (canProceed: boolean) => void;
  fieldStyles?: CSSObject;
}

export const LocationForm: React.FC<IProps> = ({
  form,
  setCanProceed,
  fieldStyles,
}) => {
  const [stateOptions, setStateOptions] = useState<IOption[]>([]);
  const [timezoneOptions, setTimezoneOptions] = useState<IOption[]>([]);
  const { t } = useTranslation("admin", { useSuspense: false });
  const { setFieldValue, setFieldTouched, initialValues } = form;

  const updateStateOptions = useCallback(
    (newCountry: string) => {
      if (newCountry !== initialValues[fields.country]) {
        setFieldValue(fields.state, "");
        setFieldTouched(fields.state, false);
      }

      if (newCountry) {
        const newStateOptions = getRegionsForCountry(newCountry).map(
          ({ code, name }) => ({
            value: code,
            label: name,
          })
        );
        setStateOptions(newStateOptions);

        if (initialValues.state) {
          const state = newStateOptions.find((s) => {
            return s.label === initialValues.state;
          });
          if (state) {
            setFieldValue("state", state.value);
          }
        }
      }
    },
    [setFieldValue, setFieldTouched, initialValues]
  );

  const updateTimezoneOptions = useCallback(
    (newCountry: string) => {
      if (newCountry !== initialValues[fields.country]) {
        setFieldValue(fields.timezone, "");
        setFieldTouched(fields.timezone, false);
      }

      if (newCountry) {
        const newTimezoneOptions = getTimezonesForCountry(newCountry).map(
          (timezone) => ({
            value: timezone,
            label: timezone,
          })
        );
        setTimezoneOptions(newTimezoneOptions);
      }
    },
    [setFieldValue, setFieldTouched, initialValues]
  );

  useEffect(() => {
    const newCountry = form.values.country;
    updateStateOptions(newCountry);
    updateTimezoneOptions(newCountry);
  }, [form.values.country, updateStateOptions, updateTimezoneOptions]);

  useEffect(() => {
    if (!setCanProceed) return;
    if (isLocationFormValid(form.values)) {
      setCanProceed(true);
    } else {
      setCanProceed(false);
    }
  }, [form.values, setCanProceed]);

  // fields listed in render order
  const orderedFields = [
    fields.language,
    fields.country,
    fields.state,
    fields.timezone,
  ];

  const options = {
    [fields.language]: Object.entries(languages).map(([code, language]) => ({
      value: code,
      label: language,
    })),
    [fields.country]: countries.map(({ code, name }) => ({
      value: code,
      label: name,
    })),
    [fields.state]: stateOptions,
    [fields.timezone]: timezoneOptions,
  };

  const handleDropdownChange = (newValue: IOption, field: Field) => {
    asyncSetFieldTouched(field, form).finally(() => {
      setFieldValue(field, newValue?.value);
    });
  };

  const handleDropdownBlur = (field: Field) => {
    asyncSetFieldTouched(field, form).finally(() => {
      form.handleBlur(field);
    });
  };

  return (
    <Flex sx={fieldStyles}>
      {orderedFields.map((field: Field) => (
        <FormControl
          isInvalid={!!form.touched[field] && !!form.errors[field]}
          key={field}
        >
          <FormLabel htmlFor={field} id={`${field}-label`}>
            {t(`teacherAccountSetupForm.locationForm.${field}`)}
          </FormLabel>
          <Dropdown
            id={field}
            aria-labelled-by={`${field}-label`}
            options={options[field]}
            value={
              options[field].find(
                (option) => option.value === form.values[field]
              ) || null
            }
            isDisabled={options[field].length === 0}
            handleBlur={() => handleDropdownBlur(field)}
            handleChange={(val) => handleDropdownChange(val as IOption, field)}
          />
          <FormErrorMessage>
            {t("teacherAccountSetupForm.errors.requiredField", {
              field: capitalizeFirstLetter(field),
            })}
          </FormErrorMessage>
        </FormControl>
      ))}
    </Flex>
  );
};
