import {
  Box,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
  useInterval,
} from "@chakra-ui/react";
import React, { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { IconType } from "adminComponents";
import { Button } from "adminComponents/atoms/Button";
import { Icon } from "adminComponents/atoms/Icon";
import { IconCTAButton } from "adminComponents/atoms/IconCTAButton";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { IHandleSetQueryArgs, ISearchQuery } from "lib/hooks/useLibraryFilter";
import { useAuth } from "links/lib/features/auth";
import { ISearchSuggestion, SearchSuggestionType } from "links/lib/types";

interface IProps extends InputProps {
  buttonText?: string;
  handleClear?: () => void;
  handleSearch: (value: string) => void;
  handleSuggest?: (value: string) => void;
  handleUpdateQuery?: (args: IHandleSetQueryArgs) => void;
  query?: ISearchQuery;
  suggestions?: Array<string>;
  searchFilterSuggestions?: Array<ISearchSuggestion>;
  icon?: IconType;
  rightIcon?: JSX.Element;
  initialSearch?: string;
  isDisabled?: boolean;
  searching?: boolean;
  hideSearchButton?: boolean;
  characterLimit?: number;
}

export const SearchInput: React.FC<IProps> = ({
  buttonText,
  handleClear,
  handleSearch,
  handleSuggest,
  handleUpdateQuery,
  query,
  suggestions = [],
  searchFilterSuggestions = [],
  initialSearch = "",
  icon = "search_outlined",
  rightIcon = null,
  isDisabled = false,
  searching = false,
  hideSearchButton = false,
  characterLimit = 5000,
  ...rest
}) => {
  const { isFeatureEnabled } = useAuth();
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });
  const [value, setValue] = useState(initialSearch);
  const [prevValue, setPrevValue] = useState(initialSearch);
  const [hasFocus, setHasFocus] = useState(false);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(0);

  const inputRef = useRef<HTMLInputElement>(null);

  const displayableSuggestions = useMemo(() => {
    const displayableSuggestions: ISearchSuggestion[] = [];

    if (prevValue) {
      displayableSuggestions.push({
        type: SearchSuggestionType.Term,
        value: prevValue,
      });
    }

    if (searchFilterSuggestions?.length && prevValue) {
      const searchFilterSuggestion = searchFilterSuggestions[0];

      switch (searchFilterSuggestion.type) {
        case SearchSuggestionType.GradeLevel:
          displayableSuggestions.push(searchFilterSuggestion);
          break;
        case SearchSuggestionType.Subject:
          displayableSuggestions.push(searchFilterSuggestion);
      }
    }

    if (suggestions.length && prevValue) {
      suggestions?.forEach((suggestion) =>
        displayableSuggestions.push({
          type: SearchSuggestionType.Term,
          value: suggestion,
        })
      );
    }

    return displayableSuggestions;
  }, [prevValue, suggestions, searchFilterSuggestions]);

  const showSuggestions =
    hasFocus &&
    displayableSuggestions.length > 0 &&
    isFeatureEnabled("playtime.teacher.enable_search_suggestions");

  const clearInput = () => {
    setValue("");
    setSelectedSuggestionIndex(0);
    handleClear?.();
  };

  useInterval(() => {
    if (!inputRef.current) return;
    setHasFocus(inputRef.current === document.activeElement);
  }, 1000);

  const handleSubmit = (overrideVal?: string) => {
    handleChange(overrideVal || value);
    handleSearch(overrideVal || value);

    setSelectedSuggestionIndex(0);
    inputRef.current?.blur();
    setHasFocus(false);
  };

  const onSubmit = (suggestion?: ISearchSuggestion) => {
    switch (suggestion?.type) {
      case SearchSuggestionType.Term:
        handleSubmit(suggestion.value);
        break;
      case SearchSuggestionType.GradeLevel:
        handleUpdateQuery?.({
          term: value,
          gradeLevelIds: suggestion?.id ? [suggestion?.id] : [],
          subjectIds: query?.filters.subjectIds || [],
        });
        setSelectedSuggestionIndex(0);
        inputRef.current?.blur();
        setHasFocus(false);
        break;
      case SearchSuggestionType.Subject:
        handleUpdateQuery?.({
          term: value,
          gradeLevelIds: query?.filters.gradeLevelIds || [],
          subjectIds: suggestion?.id ? [suggestion?.id] : [],
        });
        setSelectedSuggestionIndex(0);
        inputRef.current?.blur();
        setHasFocus(false);
        break;
    }
  };

  const handleKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    switch (ev.key) {
      case "Enter": {
        // Prevent any form this search is embedded in from submitting
        ev.preventDefault();
        ev.stopPropagation();
        onSubmit(
          displayableSuggestions.length - 1 >= selectedSuggestionIndex
            ? displayableSuggestions[selectedSuggestionIndex]
            : undefined
        );
        break;
      }
      case "ArrowDown": {
        ev.preventDefault();

        const newSuggestionIndex =
          selectedSuggestionIndex >= displayableSuggestions.length - 1
            ? 0
            : selectedSuggestionIndex + 1;
        setSelectedSuggestionIndex(newSuggestionIndex);
        if (
          displayableSuggestions[newSuggestionIndex].type ===
          SearchSuggestionType.Term
        ) {
          setValue(displayableSuggestions[newSuggestionIndex].value);
        }
        break;
      }
      case "ArrowUp": {
        ev.preventDefault();

        const newSuggestionIndex =
          selectedSuggestionIndex <= 0
            ? displayableSuggestions.length - 1
            : selectedSuggestionIndex - 1;
        setSelectedSuggestionIndex(newSuggestionIndex);
        if (
          displayableSuggestions[newSuggestionIndex].type ===
          SearchSuggestionType.Term
        ) {
          setValue(displayableSuggestions[newSuggestionIndex].value);
        }
        break;
      }
    }
  };

  const handleChange = (value: string) => {
    if (value.length >= characterLimit) {
      value = value.substring(0, characterLimit);
    }

    setValue(value);
    setPrevValue(value);
    setSelectedSuggestionIndex(0);
    inputRef.current?.focus();

    if (value.length > 2) {
      handleSuggest?.(value);
    }
  };

  return (
    <InputGroup>
      <InputLeftElement
        height="100%"
        width={[pxToRem(36), null, pxToRem(44)]}
        paddingLeft={[pxToRem(10), null, pxToRem(18)]}
        paddingRight={[pxToRem(8)]}
        paddingTop={[pxToRem(4), null, pxToRem(1)]}
      >
        <Icon
          icon={icon}
          width={pxToRem(18)}
          height={pxToRem(18)}
          color="primary.dark-gray"
          role="img"
          aria-label={t("common.searchIcon")}
        />
      </InputLeftElement>
      <Input
        {...rest}
        ref={inputRef}
        variant="adminSearchInput"
        type="search"
        isDisabled={isDisabled || searching}
        value={value}
        onClick={() => setHasFocus(true)}
        onChange={(e) => handleChange(e.target.value)}
        onKeyDown={handleKeyDown}
        autoComplete="off"
        sx={{
          "::-webkit-search-cancel-button": {
            display: "none",
          },
        }}
      />
      <InputRightElement
        width="auto"
        right={pxToRem(20)}
        top={pxToRem(7)}
        display={["none", null, "inline-flex"]}
      >
        {value !== "" && (
          <IconCTAButton
            ariaLabel={t("common.clearSearch")}
            icon="close"
            iconSize={24}
            mr={pxToRem(12)}
            onClick={clearInput}
          />
        )}
        {!hideSearchButton && (
          <Button
            size="sm"
            variant="adminButtonFilled"
            type="button"
            onClick={() => handleSubmit()}
            isLoading={searching}
            disabled={!value || searching}
            mr={rightIcon ? pxToRem(12) : undefined}
          >
            {buttonText || t("common.search")}
          </Button>
        )}
        {rightIcon}
      </InputRightElement>

      {showSuggestions && (
        <Box
          position="absolute"
          top={pxToRem(inputRef.current?.clientHeight ?? 0)}
          bgColor="white"
          zIndex={100}
          w="full"
          borderColor="primary.medium-gray"
          borderWidth={pxToRem(1)}
          borderBottomRadius={pxToRem(12)}
        >
          {displayableSuggestions.map((suggestion, i) => (
            <Box
              onClick={() => {
                switch (suggestion.type) {
                  case SearchSuggestionType.Term:
                    handleSubmit(suggestion.value);
                    break;
                  case SearchSuggestionType.GradeLevel:
                    handleUpdateQuery?.({
                      term: value,
                      gradeLevelIds: suggestion?.id ? [suggestion?.id] : [],
                      subjectIds: query?.filters.subjectIds || [],
                    });
                    break;
                  case SearchSuggestionType.Subject:
                    handleUpdateQuery?.({
                      term: value,
                      gradeLevelIds: query?.filters.gradeLevelIds || [],
                      subjectIds: suggestion?.id ? [suggestion?.id] : [],
                    });
                    break;
                }
              }}
              key={`suggestion-${i}`}
              cursor="pointer"
            >
              <HStack
                p={pxToRem(10)}
                bgColor={
                  selectedSuggestionIndex == i
                    ? "primary.light-gray"
                    : undefined
                }
                _hover={{
                  bgColor: "primary.light-gray",
                }}
                borderBottomRadius={
                  i === displayableSuggestions.length - 1
                    ? pxToRem(12)
                    : undefined
                }
              >
                <Icon
                  icon={
                    suggestion.type === SearchSuggestionType.Term
                      ? icon
                      : "diagram_outlined"
                  }
                  width={pxToRem(18)}
                  height={pxToRem(18)}
                  color="primary.dark-gray"
                  role="img"
                  aria-label={t("common.searchIcon")}
                />
                <Box textAlign="left">{suggestion.value}</Box>
              </HStack>
            </Box>
          ))}
        </Box>
      )}
    </InputGroup>
  );
};
