import { RichValue } from "links/lib/types";
import { generateEmptySlateState } from "links/lib/util";

// truncatePotentiallyRichText accepts either a plain string or a
// stringified representation of a rich slate object and truncates it
// to maxLength characters, appending a "..." to the end if it exceeds
// the limit
export function truncatePotentiallyRichText(
  string: string,
  maxLength = 50
): string {
  let answerCharCount = 0;
  try {
    const answerJSON = JSON.parse(string);
    // eslint-disable-next-line
    answerJSON.forEach(
      (block: { type: string; children: Array<{ text: string }> }) => {
        const lengthIncludingBlock =
          answerCharCount + block.children[0].text.length;
        if (lengthIncludingBlock > maxLength) {
          block.children[0].text =
            block.children[0].text.slice(0, maxLength - answerCharCount) +
            "...";
        }

        answerCharCount += block.children[0].text.length;
      }
    );

    return JSON.stringify(answerJSON);
  } catch (_) {
    return (
      string.slice(0, maxLength) + (string.length > maxLength ? "..." : "")
    );
  }
}

export function convertToSlateJSON(content: string): string {
  return JSON.stringify(convertToSlate(content) ?? "");
}

const JSONArrayRegex = /^\[.*\]$/;

/**
 * Tests whether a string contents appear to be a JSON stringified array
 * @param content
 * @returns
 */
export function isPotentialJSONArray(content: string): boolean {
  return JSONArrayRegex.test(content);
}

/**
 * Converts a string or array value to a RichValue for use with Slate.
 * @param content
 * @returns
 */
export function convertToSlate(
  content?: string | Array<Record<string, unknown>>
): RichValue {
  if (!content) {
    return generateEmptySlateState();
  }

  if (Array.isArray(content)) {
    if ((content as RichValue)[0]?.type !== "paragraph") {
      return generateEmptySlateState(JSON.stringify(content));
    }

    return content as RichValue;
  } else if (isPotentialJSONArray(content)) {
    try {
      const contentState = JSON.parse(content);

      // JSON.parse can parse strings when content has quotes
      if (!Array.isArray(contentState)) {
        throw new Error();
      }

      if ((contentState as RichValue)[0]?.type !== "paragraph") {
        throw new Error();
      }

      return contentState as RichValue;
    } catch (e) {
      return generateEmptySlateState(content);
    }
  } else {
    return generateEmptySlateState(content);
  }
}
