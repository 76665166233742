import { hoverStyles, pxToRem } from "adminComponents/utils";

export const AdminCheckbox = {
  variants: {
    adminCheckboxYellow: {
      "> span:first-of-type": {
        width: pxToRem(24),
        height: pxToRem(24),
        border: "1px solid",
        borderColor: "primary.medium-gray",
        borderRadius: pxToRem(8),
        backgroundColor: "primary.white",
        color: "primary.white",
        _focus: {
          borderColor: "utility.focus",
          borderWidth: pxToRem(2),
          boxShadow: "none",
        },
        _focusVisible: {
          boxShadow: "none",
        },
        _checked: {
          backgroundColor: "primary.golden",
          borderColor: "primary.golden",
          color: "primary.warm-black",
          _hover: hoverStyles({
            backgroundColor: "primary.golden-hover",
            borderColor: "primary.golden-hover",
          }),
        },
        _disabled: {
          opacity: 0.5,
          borderColor: "primary.medium-gray",
          backgroundColor: "primary.white",
          _checked: {
            backgroundColor: "primary.golden",
            color: "primary.warm-black",
            borderColor: "primary.golden",
            _focus: {
              borderColor: "primary.golden",
              borderWidth: pxToRem(1),
            },
            _hover: hoverStyles({
              borderColor: "primary.golden",
            }),
          },
          _focus: {
            borderColor: "primary.medium-gray",
            borderWidth: pxToRem(1),
          },
          _hover: hoverStyles({
            borderColor: "primary.medium-gray",
          }),
        },
        _hover: hoverStyles({
          borderColor: "primary.golden-hover",
        }),
      },
      "> span:nth-of-type(2)": {
        textStyle: "adminP2",
        color: "primary.warm-black",
        _disabled: {
          opacity: 0.5,
        },
      },
    },
    adminCheckboxWhite: {
      "> span:first-of-type": {
        width: pxToRem(24),
        height: pxToRem(24),
        border: "1px solid",
        borderColor: "primary.light-gray",
        borderRadius: pxToRem(8),
        backgroundColor: "primary.white",
        color: "primary.white",
        _focus: {
          borderColor: "utility.focus",
          borderWidth: pxToRem(2),
          boxShadow: "none",
        },
        _focusVisible: {
          boxShadow: "none",
        },
        _checked: {
          backgroundColor: "primary.white",
          borderColor: "primary.light-gray",
          color: "primary.warm-black",
          _hover: hoverStyles({
            backgroundColor: "primary.warm-white",
            borderColor: "primary.medium-gray",
          }),
        },
        _disabled: {
          opacity: 0.5,
          borderColor: "primary.light-gray",
          backgroundColor: "primary.white",
          _checked: {
            backgroundColor: "primary.white",
            color: "primary.warm-black",
            borderColor: "primary.light-gray",
          },
          _focus: {
            borderColor: "primary.light-gray",
            borderWidth: pxToRem(1),
          },
          _hover: hoverStyles({
            borderColor: "primary.light-gray",
          }),
        },
        _hover: hoverStyles({
          borderColor: "primary.medium-gray",
        }),
      },
      "> span:nth-of-type(2)": {
        textStyle: "adminP2",
        color: "primary.warm-black",
        _disabled: {
          opacity: 0.5,
        },
      },
    },
  },
};
