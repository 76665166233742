import { Square, Text, VStack } from "@chakra-ui/react";
import React from "react";

import { useAuth } from "links/lib/features/auth";

export const RevealAuthToken: React.FC = () => {
  const { authToken } = useAuth();

  return (
    <Square maxW="100%" paddingX="20px" overflow="hidden">
      <VStack w="100%">
        <Text h="500px" overflowWrap="anywhere" id="authToken" fontSize="24px">
          {authToken}
        </Text>
      </VStack>
    </Square>
  );
};
