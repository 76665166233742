import {
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { pxToRem } from "adminComponents/utils";
import { Button } from "sessionComponents/atoms/Button";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";

interface IStudentListModalProps {
  isOpen: boolean;
  onEndGame: () => void;
}

export const NoConnectedStudentsModal: React.FC<IStudentListModalProps> = ({
  isOpen,
  onEndGame,
}) => {
  const {
    match: { buttonHeight, fontSize, margin, padding },
  } = useBreakpoints();

  const { t } = useTranslation("session", { useSuspense: false });

  return (
    <Modal
      isOpen={isOpen}
      isCentered
      closeOnEsc={false}
      closeOnOverlayClick={false}
      onClose={() => {
        // This modal should not be closable by the user
      }}
    >
      <ModalOverlay />
      <ModalContent minW="70%" w="70%" p={pxToRem(padding)}>
        <ModalBody justifyItems="center">
          <Flex flexDir="column" alignItems="center" w="full">
            <Text fontSize={pxToRem(fontSize)} fontStyle="gameText">
              {t("bigBoard.noConnectedStudentsModal.body")}
            </Text>
            <Button
              mt={pxToRem(margin)}
              h={pxToRem(buttonHeight)}
              w="100%"
              variant="buttonFilled"
              fontSize={pxToRem(fontSize)}
              onClick={onEndGame}
            >
              {t("bigBoard.noConnectedStudentsModal.buttonEndGame")}
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
