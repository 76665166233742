import { hoverStyles, pxToRem } from "adminComponents/utils";

export const AdminClassroomCard = {
  baseStyle: {
    cardContainer: {
      width: "100%",
    },
    card: {
      paddingX: pxToRem(30),
      paddingY: pxToRem(30),
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      gap: pxToRem(8),
      _hover: hoverStyles({
        color: "primary.golden-hover",
        cursor: "pointer",
      }),
      _focus: {
        boxShadow: "none",
        outline: "none",
      },
      _focusVisible: {
        boxShadow: "none",
        outline: "none",
        color: "utility.focus",
      },
    },
    linkOverlay: {
      _focus: {
        boxShadow: "none",
        outline: "none",
      },
      _focusVisible: {
        outlineStyle: "solid",
        outlineWidth: pxToRem(2),
        outlineColor: "utility.focus",
      },
    },
  },
};
