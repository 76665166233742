import {
  Box,
  Flex,
  Spacer,
  Square,
  useBreakpointValue,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Heading } from "adminComponents/atoms/Heading";
import { Icon } from "adminComponents/atoms/Icon";
import { Modal } from "adminComponents/atoms/Modal";
import { PremiumTooltipRich } from "adminComponents/atoms/PremiumTooltipRich";
import { Text } from "adminComponents/atoms/Text";
import { TextLink } from "adminComponents/atoms/TextLink";
import { pxToRem } from "adminComponents/utils";
import { useAuth } from "links/lib/features/auth";
import { ICustomSessionGroup, IUser } from "links/lib/types";

import { Carousel } from "../Carousel";
import { SessionGroup } from "./components/SessionGroup";
import { StudentOption } from "./components/StudentOption";
import { useSessionGroupHandlers } from "./hooks/handlers";

interface SessionGroupsModalProps {
  isOpen: boolean;
  handleSave: (sessionGroups: ICustomSessionGroup[]) => void;
  handleClose: () => void;
  students: IUser[];
  sessionGroups: ICustomSessionGroup[];
}

export const SessionGroupsModal: React.FC<SessionGroupsModalProps> = ({
  isOpen,
  handleSave,
  handleClose,
  students,
  sessionGroups,
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });
  const { hasNoPremiumAccess } = useAuth();
  const [showErrors, setShowErrors] = useState<boolean>(false);

  const isMobile = useBreakpointValue({ base: true, lg: false });
  const styles = useMultiStyleConfig("AdminSessionGroupsModal", {
    isMobile,
    showErrors,
  });

  const [sessionGroupsMap, setSessionGroupsMap] = useState<
    Map<string, ICustomSessionGroup>
  >(new Map<string, ICustomSessionGroup>());
  const [studentMap, setStudentMap] = useState<
    Map<string, { student: IUser; sessionGroupId?: string }>
  >(new Map<string, { student: IUser; sessionGroupId?: string }>());
  const [selectedGroup, setSelectedGroup] = useState<string>();
  const [validationError, setValidationError] = useState<string | undefined>(
    undefined
  );
  const [touched, setTouched] = useState<boolean>(false);

  useEffect(() => {
    const startingStudentsMap = new Map<
      string,
      { student: IUser; sessionGroupId?: string }
    >();
    students.forEach((student) =>
      startingStudentsMap.set(student.id, { student })
    );

    const startingGroupsMap = new Map<string, ICustomSessionGroup>();
    sessionGroups.forEach((sessionGroup) => {
      const foundUserIds: string[] = [];
      sessionGroup.user_ids.forEach((userId) => {
        const user = startingStudentsMap.get(userId);
        if (user) {
          startingStudentsMap.set(userId, {
            student: user.student,
            sessionGroupId: sessionGroup.id,
          });
          foundUserIds.push(user.student.id);
        }
      });
      if (foundUserIds.length < 2) {
        setShowErrors(true);
      }
      startingGroupsMap.set(sessionGroup.id, {
        ...sessionGroup,
        user_ids: foundUserIds,
      });
    });

    setTouched(false);
    setSelectedGroup("");
    setSessionGroupsMap(startingGroupsMap);
    setStudentMap(startingStudentsMap);
  }, [sessionGroups, students, isOpen]);

  const sessionGroupsArray = useMemo(
    () => Array.from(sessionGroupsMap).map(([, sessionGroup]) => sessionGroup),
    [sessionGroupsMap]
  );

  const sessionGroupsOrderMap = useMemo(() => {
    const sessionGroupsOrderMap = new Map<string, number>();

    sessionGroupsArray.forEach((sessionGroup, index) =>
      sessionGroupsOrderMap.set(sessionGroup.id, index + 1)
    );

    return sessionGroupsOrderMap;
  }, [sessionGroupsArray]);

  const groupedStudentsCount = useMemo(() => {
    return Array.from(studentMap).filter(
      ([, student]) => !!student.sessionGroupId
    ).length;
  }, [studentMap]);

  const selectedStudentsCount = useMemo(() => {
    if (!selectedGroup) {
      return 0;
    }
    const sessionGroup = sessionGroupsMap.get(selectedGroup);

    if (!sessionGroup) {
      return 0;
    }

    return sessionGroup.user_ids.length;
  }, [sessionGroupsMap, selectedGroup]);

  const showStudentOptions = !(isMobile && sessionGroupsArray.length === 0);

  const getValidationError = useCallback(() => {
    const errorGroups = sessionGroupsArray
      .filter((sessionGroup) => sessionGroup.user_ids.length === 1)
      .map((sessionGroup) => sessionGroup);

    if (errorGroups.length > 0) {
      const groupNumber = sessionGroupsOrderMap.get(errorGroups[0].id);
      const validationError = t("sessionGroupsModal.error", { groupNumber });
      setValidationError(validationError);
      return validationError;
    } else {
      setValidationError(undefined);
      return undefined;
    }
  }, [sessionGroupsOrderMap, sessionGroupsArray, t]);

  useEffect(() => {
    if (showErrors) {
      getValidationError();
    }
  }, [sessionGroupsMap, getValidationError, showErrors]);

  const handleSubmit = useCallback(() => {
    setShowErrors(true);
    const error = getValidationError();
    if (!error) {
      const sessionGroups = sessionGroupsArray
        .filter((sessionGroup) => sessionGroup.user_ids.length > 0)
        .map((sessionGroup) => ({ ...sessionGroup, id: "0" }));

      handleSave(sessionGroups);
    }
  }, [sessionGroupsArray, getValidationError, handleSave]);

  const {
    handleStudentSelect,
    handleClearGroups,
    handleRandomizeGroups,
    handleAddGroup,
    handleRemoveGroup,
  } = useSessionGroupHandlers({
    sessionGroupsMap,
    setSessionGroupsMap,
    selectedGroup,
    setSelectedGroup,
    studentMap,
    setStudentMap,
    students,
    setTouched,
  });

  const sessionGroupComponents = useMemo(
    () =>
      sessionGroupsArray.map((sessionGroup, index) => {
        const students: IUser[] = sessionGroup.user_ids
          .map((userId) => studentMap.get(userId)?.student)
          .filter((student) => !!student) as IUser[];
        return (
          <SessionGroup
            showErrors={showErrors}
            onRemove={handleRemoveGroup}
            sessionGroupNumber={index + 1}
            onSelect={() => setSelectedGroup(sessionGroup.id)}
            selected={sessionGroup.id === selectedGroup}
            students={students}
            sessionGroupId={sessionGroup.id}
            key={sessionGroup.id + "-" + index}
          />
        );
      }),
    [
      sessionGroupsArray,
      showErrors,
      studentMap,
      handleRemoveGroup,
      setSelectedGroup,
      selectedGroup,
    ]
  );

  const emptyStateCopy = useMemo(() => {
    return (
      <Box h="100%">
        <Square size="100%">
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Heading as="h5" variant="adminH5">
              {t("sessionGroupsModal.emptyHeader")}
            </Heading>
            <Text
              variant="adminP1"
              color="primary.dark-gray"
              mx={{ base: 0, md: "10%", lg: "20%" }}
              textAlign="center"
            >
              {t("sessionGroupsModal.emptyDescription")}
            </Text>
          </Flex>
        </Square>
      </Box>
    );
  }, [t]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      showBackButton={false}
      hideClose
      title={t("sessionGroupsModal.title")}
      size="full"
      modalContentProps={{
        borderRadius: 0,
      }}
    >
      <Flex sx={styles.container} flexDirection={isMobile ? "column" : "row"}>
        <Box sx={styles.groupsContainer}>
          {isMobile && (
            <Flex paddingBottom={pxToRem(10)}>
              <Icon icon="diagram_outlined" />
              <Square paddingLeft={pxToRem(10)}>
                <Text>{t("sessionGroupsModal.groups")}</Text>
              </Square>
            </Flex>
          )}
          <Flex w="100%">
            {!isMobile && (
              <>
                <Icon icon="diagram_outlined" />
                <Square paddingLeft={pxToRem(10)}>
                  <Text>{t("sessionGroupsModal.groups")}</Text>
                </Square>
              </>
            )}
            <Spacer />
            <Flex
              sx={styles.headerCtas}
              flexDir={isMobile ? "row-reverse" : "row"}
            >
              <Square>
                <TextLink handleClick={handleClearGroups}>
                  {t("sessionGroupsModal.clear")}
                </TextLink>
              </Square>
              <Button
                onClick={handleRandomizeGroups}
                variant="adminButtonOutlined"
              >
                {isMobile
                  ? t("sessionGroupsModal.randomizeMobile")
                  : t("sessionGroupsModal.randomize")}
              </Button>
              <PremiumTooltipRich isDisabled={!hasNoPremiumAccess}>
                <Box>
                  <Button
                    variant="adminButtonFilled"
                    isDisabled={hasNoPremiumAccess}
                    leftIcon={
                      hasNoPremiumAccess ? (
                        <Icon icon="lock" />
                      ) : isMobile ? undefined : (
                        <Icon icon="add" />
                      )
                    }
                    onClick={hasNoPremiumAccess ? undefined : handleAddGroup}
                  >
                    {isMobile
                      ? t("sessionGroupsModal.addGroupMobile")
                      : t("sessionGroupsModal.addGroup")}
                  </Button>
                </Box>
              </PremiumTooltipRich>
            </Flex>
          </Flex>
          {!isMobile && (
            <Flex sx={styles.sessionGroups} id="sessionGroups">
              {sessionGroupsArray.length === 0 && emptyStateCopy}
              {sessionGroupComponents}
            </Flex>
          )}
          {isMobile && (
            <>
              {sessionGroupsArray.length === 0 && emptyStateCopy}
              {sessionGroupsArray.length > 0 && (
                <Carousel variant="adminCarousel" showDots slidesPerView={2}>
                  {sessionGroupComponents}
                </Carousel>
              )}
            </>
          )}
        </Box>
        {showStudentOptions && (
          <Flex sx={styles.studentsContainer}>
            <Flex flexDir="column" gap={pxToRem(10)}>
              <Text fontWeight="bold">
                {t("sessionGroupsModal.studentsSelected", {
                  count: selectedStudentsCount,
                })}
              </Text>
              <Text>
                {t("sessionGroupsModal.studentInGroups", {
                  inGroups: groupedStudentsCount,
                  total: students.length,
                })}
              </Text>
            </Flex>
            <Flex sx={styles.studentOptions}>
              {students.map((student) => {
                return (
                  <StudentOption
                    key={student.id}
                    selected={!!studentMap.get(student.id)?.sessionGroupId}
                    student={student}
                    highlighted={
                      selectedGroup ===
                      studentMap.get(student.id)?.sessionGroupId
                    }
                    sessionGroupIndex={sessionGroupsOrderMap.get(
                      studentMap.get(student.id)?.sessionGroupId || "0"
                    )}
                    onSelect={handleStudentSelect}
                    disabled={!selectedGroup}
                  />
                );
              })}
            </Flex>
          </Flex>
        )}
      </Flex>
      {showErrors && validationError && (
        <Text paddingTop={pxToRem(5)} color="utility.error">
          {validationError}
        </Text>
      )}
      <Flex paddingTop={pxToRem(10)} gap={pxToRem(10)}>
        <PremiumTooltipRich isDisabled={!hasNoPremiumAccess}>
          <Box>
            <Button
              variant="adminButtonFilled"
              isDisabled={
                hasNoPremiumAccess ||
                !touched ||
                (showErrors && !!validationError)
              }
              leftIcon={hasNoPremiumAccess ? <Icon icon="lock" /> : undefined}
              onClick={hasNoPremiumAccess ? undefined : handleSubmit}
            >
              {t("common.save")}
            </Button>
          </Box>
        </PremiumTooltipRich>
        <Button onClick={handleClose} variant="adminButtonOutlined">
          {t("common.cancel")}
        </Button>
      </Flex>
    </Modal>
  );
};
