import { Button, Square } from "@chakra-ui/react";
import React from "react";
import { useHistory } from "react-router-dom";

import { useErrorToast } from "adminComponents/utils/toast";
import useDeleteAuthUser from "links/lib/features/account/useDeleteAuthUser";

export const DeleteAuthUser: React.FC = () => {
  const history = useHistory();
  const onSuccess = () => history.push("/sign-out");
  const deleteAuthUser = useDeleteAuthUser({ onSuccess });
  useErrorToast(deleteAuthUser.error);

  return (
    <Square>
      <Button
        id="deleteme"
        onClick={() => deleteAuthUser.mutate()}
        aria-label={"Delete this user"}
      >
        Delete this user.
      </Button>
    </Square>
  );
};
