import {
  Box,
  Circle,
  Flex,
  HStack,
  LinkBox,
  LinkOverlay,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

import { Avatar } from "adminComponents/atoms/Avatar";
import { Card } from "adminComponents/atoms/Card";
import { ClampedHeading } from "adminComponents/atoms/ClampedHeading";
import { CoverImageBackground } from "adminComponents/atoms/CoverImageBackground";
import { CoverImageIcon } from "adminComponents/atoms/CoverImageIcon";
import { Divider } from "adminComponents/atoms/Divider";
import { Icon } from "adminComponents/atoms/Icon";
import { SubmarkLogo } from "adminComponents/atoms/SubmarkLogo";
import { Text } from "adminComponents/atoms/Text";
import { TextLink } from "adminComponents/atoms/TextLink";
import {
  IconButtonDropdown,
  MenuItemDetails,
} from "adminComponents/molecules/IconButtonDropdown";
import { colors } from "adminComponents/theme/colors";
import {
  isDarkColorScheme,
  pxToRem,
  useStringifyList,
} from "adminComponents/utils";
import { IPracticeSet, IUser, UserRole } from "links/lib/types";

import { Banner } from "./Banner";

export interface IProps {
  authorName?: string;
  authorCustomUrlCode?: string;
  authorProfileImageUrl?: string;
  authorProfileIsPrivate?: boolean;
  authorIsInternalContentSpecialist?: boolean;
  authUserLanguage?: string;
  showLargeAuthorName?: boolean;
  authUser?: IUser;
  isShared: boolean;
  menuItems: MenuItemDetails[];
  practiceSet: IPracticeSet;
  practiceSetUrl: string;
  showPremiumMarker?: boolean;
  handleClick?: React.MouseEventHandler;
}

export const FullLibraryPracticeSetCard: React.FC<IProps> = ({
  authorName,
  authorCustomUrlCode,
  authorProfileImageUrl,
  authorProfileIsPrivate,
  authorIsInternalContentSpecialist,
  authUserLanguage,
  showLargeAuthorName = false,
  authUser,
  isShared,
  menuItems,
  practiceSet,
  practiceSetUrl,
  handleClick,
  showPremiumMarker = true,
}) => {
  const styles = useMultiStyleConfig("AdminLibraryPracticeSetCard", {
    variant: "full",
  });
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });

  const { item_count: questionsCount, subjects, grade_levels } = practiceSet;

  const subjectList = subjects.map((s) => s.subject.name);
  const subjectsString = useStringifyList(subjectList, 3);

  const gradesList = grade_levels
    .sort((a, b) => {
      const [aId, bId] = [
        parseInt(a.grade_level.id, 10),
        parseInt(b.grade_level.id, 10),
      ];
      return aId > bId ? 1 : aId < bId ? -1 : 0;
    })
    .map((g) => g.grade_level.grade_level);
  const gradesString = useStringifyList(gradesList, 3);

  const standardsString = useStringifyList(
    practiceSet.practice_set_item_standard_labels || [],
    3
  );

  const menuColor = isDarkColorScheme(practiceSet.cover_image_bg_color_scheme)
    ? "primary.white"
    : "primary.warm-black";

  const isContentSpecialist = authUser?.role === UserRole.ContentSpecialist;

  const upvoteCount = practiceSet.rating?.total_count_positive || 0;
  const copiesCount =
    practiceSet.is_how_to_play || practiceSet.is_digital_citizenship
      ? 0
      : practiceSet.statistics?.copy_count || 0;

  return (
    <LinkBox>
      <Card
        noPadding
        borderColor="primary.tan"
        backgroundColor="primary.white"
        variant="adminCardSmallBorder"
        sx={styles.card}
        h="100%"
      >
        <CoverImageBackground
          sx={styles.coverImageBackground}
          colorScheme={practiceSet.cover_image_bg_color_scheme}
          pattern={practiceSet.cover_image_bg_pattern}
        >
          <Flex sx={styles.headerContainer}>
            <Banner
              isCertified={practiceSet.is_certified}
              isPremium={practiceSet.is_premium}
              withCertifiedText
              withPremiumText={
                practiceSet.is_premium && !practiceSet.is_certified
              }
              showPremiumMarker={showPremiumMarker}
            />
            {!isShared && (
              <IconButtonDropdown
                menuItems={menuItems}
                iconColor={menuColor}
                iconVariant="adminLibraryPracticeSetCard"
              />
            )}
          </Flex>
          <CoverImageIcon icon={practiceSet.cover_image_icon} boxSize={100} />
        </CoverImageBackground>
        <Flex sx={styles.body}>
          <Box sx={styles.content}>
            <LinkOverlay
              as={RouterLink}
              to={practiceSetUrl}
              sx={styles.linkOverlay}
              onClick={handleClick}
            >
              <ClampedHeading
                headingAs="p"
                headingVariant="adminH4"
                headingStyles={styles.heading}
                noOfLines={3}
              >
                {practiceSet.title}
              </ClampedHeading>
            </LinkOverlay>
            <Text variant="adminP2" color="primary.dark-gray">
              {t("libraryPracticeSetCard.questionCount", {
                count: questionsCount,
              })}
            </Text>
            <Divider sx={styles.divider} color="primary.light-gray" />
            {isContentSpecialist && (
              <Text variant="adminMeta">{practiceSet.cnc_code}</Text>
            )}
            {authorName && !showLargeAuthorName && (
              <Text variant="adminMeta">{authorName}</Text>
            )}
            {authorName && showLargeAuthorName && (
              <Text
                variant="adminP2"
                color="primary.dark-gray"
                mb={pxToRem(16)}
              >
                <Flex alignItems="center" gap={pxToRem(8)} overflow="hidden">
                  {authorIsInternalContentSpecialist && (
                    <Circle
                      bgColor={colors["pear-practice"]["blue-skies"]}
                      size={pxToRem(24)}
                      p={pxToRem(4)}
                      overflow="hidden"
                    >
                      <SubmarkLogo />
                    </Circle>
                  )}
                  {!authorIsInternalContentSpecialist && (
                    <Avatar
                      size="sm"
                      src={authorProfileImageUrl}
                      name={authorName}
                    />
                  )}
                  {authorCustomUrlCode && !authorProfileIsPrivate ? (
                    <TextLink
                      ml={pxToRem(-4)}
                      size="sm"
                      height={pxToRem(24)}
                      navigationLink={{
                        to: `/t/profiles/${authorCustomUrlCode}`,
                        label: authorName || "",
                        isExternal: false,
                      }}
                    >
                      {authorName}
                    </TextLink>
                  ) : (
                    <Text ml={pxToRem(-4)} variant="adminP3">
                      {authorName}
                    </Text>
                  )}
                </Flex>
              </Text>
            )}
            <Text variant="adminMeta">{subjectsString}</Text>
            <Text variant="adminMeta">{gradesString}</Text>
            <Text variant="adminMeta">{standardsString}</Text>
            {(upvoteCount > 0 || copiesCount > 0) && (
              <HStack mt={pxToRem(16)} justifyContent="space-between">
                {upvoteCount > 0 && (
                  <HStack spacing={pxToRem(4)}>
                    <Icon w={pxToRem(18)} h={pxToRem(18)} icon="thumb_up" />
                    <Text sx={{ lineHeight: pxToRem(18) }} variant="adminP3">
                      {upvoteCount.toLocaleString(authUserLanguage)}
                    </Text>
                  </HStack>
                )}
                {copiesCount > 0 && (
                  <HStack spacing={pxToRem(4)}>
                    <Icon w={pxToRem(18)} h={pxToRem(18)} icon="content_copy" />
                    <Text sx={{ lineHeight: pxToRem(18) }} variant="adminP3">
                      {t("libraryPracticeSetCard.copyCount", {
                        count: copiesCount,
                      })}
                    </Text>
                  </HStack>
                )}
              </HStack>
            )}
          </Box>
        </Flex>
      </Card>
    </LinkBox>
  );
};
