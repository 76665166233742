import {
  Box,
  Button,
  Progress,
  Text,
  VStack,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import React, {
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";

import { Button as AdminButton } from "adminComponents/atoms/Button";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { Icon } from "sessionComponents/atoms/Icon";
import {
  SeasonPopover,
  SeasonPopoverAnchor,
  SeasonPopoverArrow,
  SeasonPopoverBody,
  SeasonPopoverContent,
} from "sharedComponents/molecules/SeasonPopover";

import { LocationInfo } from "..";
import { SeasonMapPin } from "./SeasonMapPin";

export interface ISeasonMapLocationProps {
  name: string;
  description: string;
  locationId: string;
  offset: {
    top: number;
    left: number;
  };
  relativeOffset?: {
    top: number;
    left: number;
  };
  // should be set by parent zone
  pathColor?: string;
  // should be set by parent zone
  isLocked?: boolean;
  // should be set by parent zone
  scale?: number;
  progress: {
    totalSpaces: number;
    spacesCollected: number;
  };
  previewImageUrl: string;
  children?: React.ReactElement<typeof SeasonMapPin>;
  handleLocationFocus?: (
    location: LocationInfo,
    isKeyboardEvent?: boolean
  ) => void;
  handleLocationSelect?: (locationId: LocationInfo) => void;
}

interface ISeasonMapLocationPopoverContentProps {
  name: string;
  description: string;
  progress: {
    totalSpaces: number;
    spacesCollected: number;
  };
  previewImageUrl: string;
  onSelect?: () => void;
}

interface ISeasonMapLocationMarkerProps {
  offset: {
    top: number;
    left: number;
  };
  // should be set by parent zone
  pathColor?: string;
  // should be set by parent zone
  isLocked?: boolean;
  // should be set by parent zone
  scale?: number;
  progress: {
    totalSpaces: number;
    spacesCollected: number;
  };
  children?: React.ReactElement<typeof SeasonMapPin>;
  name: string;
  handleMouseEnter?: () => void;
  handleMouseLeave?: () => void;
  handleFocus?: () => void;
  handleBlur?: () => void;
  handleClick?: () => void;
  handleMouseUp?: () => void;
  handleMouseDown?: () => void;
}

const buttonSize = 96;

const SeasonMapLocation: React.FC<ISeasonMapLocationProps> = ({
  pathColor,
  offset,
  relativeOffset,
  isLocked,
  progress,
  scale = 1,
  name,
  description,
  previewImageUrl,
  children,
  locationId,
  handleLocationFocus,
  handleLocationSelect,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isMouseDown = useRef<boolean>(false);

  const handleMouseDown = useCallback(() => {
    isMouseDown.current = true;
  }, []);

  const handleMouseUpOrBlur = useCallback(() => {
    isMouseDown.current = false;
  }, []);

  const handleFocus = useCallback(() => {
    if (isLocked) return;

    if (!isOpen) {
      onOpen();
    }

    handleLocationFocus?.(
      {
        id: locationId,
        x: offset.left,
        y: offset.top,
      },
      !isMouseDown.current
    );
  }, [
    offset.left,
    offset.top,
    onOpen,
    handleLocationFocus,
    isLocked,
    locationId,
    isOpen,
  ]);

  const handleSelect = useCallback(() => {
    handleLocationSelect?.({
      id: locationId,
      x: offset.left,
      y: offset.top,
    });
  }, [handleLocationSelect, locationId, offset]);

  const borderRadius = [pxToRem(16), pxToRem(20), pxToRem(24)];
  const borderWidth = useBreakpointValue([
    pxToRem(8),
    pxToRem(10),
    pxToRem(12),
  ]);

  return (
    <SeasonPopover
      returnFocusOnClose
      closeOnBlur={true}
      onOpen={onOpen}
      isOpen={isOpen}
      onClose={onClose}
      placement="top"
      isLazy
    >
      <SeasonPopoverAnchor>
        <SeasonMapLocationMarker
          pathColor={pathColor}
          offset={relativeOffset ?? offset}
          isLocked={isLocked}
          progress={progress}
          scale={scale}
          handleFocus={handleFocus}
          handleMouseDown={handleMouseDown}
          handleMouseUp={handleMouseUpOrBlur}
          handleBlur={handleMouseUpOrBlur}
          name={name}
        >
          {children}
        </SeasonMapLocationMarker>
      </SeasonPopoverAnchor>
      <SeasonPopoverContent
        borderRadius={borderRadius}
        bg="primary.warm-white"
        borderColor="primary.tan"
        borderWidth={borderWidth}
        sx={{
          "--popper-arrow-size": pxToRem(22),
          "--popper-arrow-shadow-color": "transparent",
        }}
      >
        <SeasonPopoverArrow />
        <SeasonPopoverBody borderRadius={borderRadius} p={0}>
          <SeasonMapLocationPopoverContent
            name={name}
            description={description}
            progress={progress}
            previewImageUrl={previewImageUrl}
            onSelect={handleSelect}
          />
        </SeasonPopoverBody>
      </SeasonPopoverContent>
    </SeasonPopover>
  );
};

const SeasonMapLocationMarker = React.forwardRef<
  HTMLButtonElement,
  ISeasonMapLocationMarkerProps
>(
  (
    {
      pathColor,
      offset,
      isLocked,
      name,
      progress,
      scale = 1,
      handleMouseEnter,
      handleMouseLeave,
      handleFocus,
      handleBlur,
      children,
      handleMouseDown: _handleMouseDown,
      handleMouseUp,
    },
    ref
  ) => {
    const buttonRef = useRef<HTMLButtonElement | null>(null);

    useImperativeHandle(ref, () => buttonRef.current as HTMLButtonElement);

    const percentProgress =
      (progress.spacesCollected / progress.totalSpaces) * 100;

    const childrenWithScaleProps = useMemo(() => {
      return React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            scale,
          });
        }
        return child;
      });
    }, [children, scale]);

    // Explicitly focus button element on mouse down.
    // iOS does not focus buttons on click
    const handleMouseDown = () => {
      _handleMouseDown?.();
      buttonRef.current?.focus();
    };

    return (
      <Button
        ref={buttonRef}
        name={name}
        boxSize={pxToRem(buttonSize * scale)}
        bg={
          isLocked
            ? "map.locked-gray"
            : `linear-gradient( 0deg, ${pathColor}, ${pathColor} ${percentProgress}%, white ${percentProgress}% )`
        }
        borderColor={isLocked ? "map.locked-dark-gray" : pathColor}
        borderStyle="solid"
        borderWidth={pxToRem(15 * scale)}
        position="absolute"
        top={pxToRem((offset.top - buttonSize / 2) * scale)}
        left={pxToRem((offset.left - buttonSize / 2) * scale)}
        borderRadius="50%"
        variant="unstyled"
        zIndex="1"
        display="flex"
        alignItems="center"
        minWidth="unset"
        justifyContent="center"
        onFocus={handleFocus}
        onMouseEnter={handleMouseEnter}
        onBlur={handleBlur}
        onMouseLeave={handleMouseLeave}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        disabled={isLocked}
        sx={{
          _disabled: {
            opacity: 1,
          },
          ":hover:disabled": {
            background: "map.locked-gray",
          },
        }}
      >
        {isLocked && (
          <Icon
            icon="lock"
            color="map.locked-dark-gray"
            boxSize={pxToRem((buttonSize / 2.5) * scale)}
          />
        )}
        {childrenWithScaleProps}
      </Button>
    );
  }
);

SeasonMapLocationMarker.displayName = "SeasonMapLocationMarker";

const SeasonMapLocationPopoverContent: React.FC<
  ISeasonMapLocationPopoverContentProps
> = ({ name, progress, description, previewImageUrl, onSelect }) => {
  const { t } = useTranslation("map", {
    keyPrefix: "map.locationPopup",
    useSuspense: false,
  });
  const selectButtonSize = useBreakpointValue(["sm", "sm", "md"]);

  const nameFontSize = useBreakpointValue([
    pxToRem(14),
    pxToRem(16),
    pxToRem(20),
    pxToRem(24),
  ]);
  const descFontSize = useBreakpointValue([
    pxToRem(12),
    pxToRem(12),
    pxToRem(14),
    pxToRem(16),
  ]);
  const progressHeight = useBreakpointValue([
    pxToRem(12),
    pxToRem(14),
    pxToRem(16),
  ]);
  const progressBorderRadius = useBreakpointValue([
    pxToRem(6),
    pxToRem(7),
    pxToRem(8),
  ]);
  const padding = useBreakpointValue([
    pxToRem(6),
    pxToRem(8),
    pxToRem(10),
    pxToRem(14),
  ]);

  const progressText = t("progressText", progress);

  return (
    <Box p={padding}>
      <VStack w="full" spacing={pxToRem(10)}>
        <Text
          fontSize={nameFontSize}
          textAlign="center"
          textStyle="gameDisplay"
        >
          {name}
        </Text>
        <Text
          fontSize={descFontSize}
          textAlign="center"
          textStyle="gameText"
          lineHeight="110%"
        >
          {description}
        </Text>
        <Box
          w="full"
          h={pxToRem(100)}
          backgroundImage={previewImageUrl}
          backgroundPosition="center center"
          backgroundSize="contain"
          backgroundRepeat="no-repeat"
        />
        <VStack w="full">
          <Text
            fontSize={descFontSize}
            textAlign="center"
            textStyle="gameText"
            textTransform="uppercase"
          >
            {progressText}
          </Text>
          <Progress
            value={(progress.spacesCollected / progress.totalSpaces) * 100}
            height={progressHeight}
            borderRadius={progressBorderRadius}
            variant="mapProgress"
            w="70%"
          />
        </VStack>
        <AdminButton
          variant="adminButtonFilled"
          onClick={onSelect}
          size={selectButtonSize}
        >
          {t("selectButton", { locationName: name })}
        </AdminButton>
      </VStack>
    </Box>
  );
};

export { SeasonMapLocation };
