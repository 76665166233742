import React, { createContext, useContext, useMemo } from "react";

import { ISessionUser, ISessionUsers, SessionConnectionStatus } from "../types";

export const SessionConnectedUsersContext = createContext<ISessionUsers>({});

export interface ISessionConnectedUsersProviderProps {
  wsStateUsers: ISessionUsers;
}

export const SessionConnectedUsersProvider: React.FC<
  ISessionConnectedUsersProviderProps
> = ({ children, wsStateUsers }) => {
  const value = useMemo(() => {
    const connectedUsers: ISessionUsers = Object.values(wsStateUsers).reduce(
      (connectedUsers: ISessionUsers, user: ISessionUser | undefined) => {
        if (
          user &&
          user.connection_status === SessionConnectionStatus.Connected
        ) {
          connectedUsers[user.id] = user;
        }
        return connectedUsers;
      },
      {}
    );
    return connectedUsers;
  }, [wsStateUsers]);

  return (
    <SessionConnectedUsersContext.Provider value={value}>
      {children}
    </SessionConnectedUsersContext.Provider>
  );
};

export const useSessionConnectedUsers = (): ISessionUsers => {
  return useContext(SessionConnectedUsersContext);
};
