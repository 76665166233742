import { Box, Flex, HStack, Image, useToken } from "@chakra-ui/react";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { CoverImageIcon } from "adminComponents/atoms/CoverImageIcon";
import { IconTooltip } from "adminComponents/atoms/IconTooltip";
import { Radio } from "adminComponents/atoms/Radio";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils";
import { SharingSettingsVisibility } from "links/lib/types";

import LockSVG from "../resource/lock.svg";
import RocketSVG from "../resource/rocket.svg";

interface SharingVisibilityRadioProps {
  sharingVisibility: SharingSettingsVisibility;
}

export const SharingVisibilityRadio = React.forwardRef<
  HTMLDivElement,
  SharingVisibilityRadioProps
>((props) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  // Necessary in order to use !important to override Chakra's default styles
  const utilityFocus = useToken("colors", "utility.focus");
  const { sharingVisibility } = props;

  const { register, setValue, watch } = useFormContext();
  const selectedSharingVisbility = watch("sharingVisibility");
  const selected = selectedSharingVisbility === sharingVisibility;

  let tooltipText = "Test tooltip text";
  let sharingVisibilityText = "";
  switch (sharingVisibility) {
    case SharingSettingsVisibility.Private:
      tooltipText = t("teacherOnboardContainer.step3.privateVisibilityTooltip");
      sharingVisibilityText = t(
        "teacherOnboardContainer.step3.privateVisibility"
      );
      break;
    case SharingSettingsVisibility.GSOnly:
      tooltipText = t("teacherOnboardContainer.step3.gsOnlyVisibilityTooltip");
      sharingVisibilityText = t(
        "teacherOnboardContainer.step3.gsOnlyVisibility"
      );
      break;
    case SharingSettingsVisibility.Public:
      tooltipText = t("teacherOnboardContainer.step3.publicVisibilityTooltip");
      sharingVisibilityText = t(
        "teacherOnboardContainer.step3.publicVisibility"
      );
      break;
  }

  return (
    <Flex
      cursor="pointer"
      flexDir="column"
      alignItems="center"
      textAlign="center"
      justifyContent="center"
      position="relative"
      p={pxToRem(16)}
      backgroundColor="transparent"
      borderWidth={selected ? pxToRem(5) : pxToRem(3)}
      borderColor={selected ? "utility.focus" : "primary.tan"}
      borderRadius="lg"
      h="auto"
      _active={{
        backgroundColor: "transparent",
      }}
      _focusVisible={{
        bg: "transparent",
      }}
      gap={pxToRem(8)}
      onClick={() => setValue("sharingVisibility", sharingVisibility)}
      sx={{
        "&:hover": {
          borderColor: selected ? undefined : "primary.warm-black",
          ".chakra-radio__control": {
            borderColor: selected ? undefined : "primary.golden",
          },
        },

        ".chakra-radio__control": {
          borderColor: selected ? `${utilityFocus} !important` : undefined,
          borderWidth: selected ? `${pxToRem(2)} !important` : undefined,
        },
      }}
    >
      <Box position="absolute" top={pxToRem(5)} right={pxToRem(5)}>
        <Radio
          {...register("sharingVisibility")}
          borderColor={selected ? "primary.golden" : undefined}
          value={sharingVisibility}
        />
      </Box>
      {sharingVisibility === SharingSettingsVisibility.GSOnly && (
        <CoverImageIcon icon="OTHER_GENERAL_FUN" boxSize={pxToRem(80)} />
      )}
      {sharingVisibility !== SharingSettingsVisibility.GSOnly && (
        <Image
          boxSize={pxToRem(80)}
          src={
            sharingVisibility === SharingSettingsVisibility.Public
              ? RocketSVG
              : LockSVG
          }
        />
      )}
      <HStack>
        <Text variant="adminP2">{sharingVisibilityText}</Text>
        <IconTooltip usePortal>
          <Box textAlign="center">{tooltipText}</Box>
        </IconTooltip>
      </HStack>
    </Flex>
  );
});

SharingVisibilityRadio.displayName = "SharingVisibilityRadio";
