import React, { createContext, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { INavigationHeaderProps } from "adminComponents/molecules/NavigationHeader";
import { useAuth } from "links/lib/features/auth";
import { useFetchGradeLevels } from "links/lib/features/gradeLevels";
import { useFetchSubjects } from "links/lib/features/subjects";
import { ISubject, UserRole } from "links/lib/types";

export type IGuestNavigationDataContext = {
  handleGeneratePracticeSet?: () => void;
  parentSubjects: ISubject[];
} & Omit<
  INavigationHeaderProps,
  | "avatarData"
  | "breadcrumbs"
  | "navigationLinks"
  | "resourceLinks"
  | "accountLinks"
  | "userLicense"
  | "showGracePeriodBanner"
  | "gracePeriodEndDate"
  | "isLoading"
  | "assignmentCount"
  | "handleCreatePracticeSet"
  | "handleOpenAssignmentsFlyout"
  | "handleLogIn"
  | "handleSignUp"
  | "isGrayscale"
  | "showPremiumMarker"
  | "hasNoPremiumAccess"
  | "adminMode"
  | "teacherOnboardingChecklist"
>;

const GuestNavigationDataContext = createContext<IGuestNavigationDataContext>({
  activeNavigationPath: "",
  variant: "teacher",
  logoLink: {
    label: "",
    to: "",
  },
  user: {
    id: "",
    email: "",
    role: UserRole.Teacher,
    true_role: UserRole.Teacher,
    license_id: "",
    timezone_locale: "",
    language: "",
    country: "",
    region: "",
    is_guest: false,
  },
  authActionLinks: [],
  subjects: [],
  parentSubjects: [],
  gradeLevels: [],
  handleGeneratePracticeSet: () => {
    return;
  },
});

export const useGuestTeacherNavigationData =
  (): IGuestNavigationDataContext => {
    return useContext(GuestNavigationDataContext);
  };

const GuestTeacherNavigationDataProvider: React.FC = ({ children }) => {
  const { authUser } = useAuth();
  const { t } = useTranslation("admin", {
    keyPrefix: "adminNavigationHeader",
    useSuspense: false,
  });
  const { t: tCommon } = useTranslation("admin", {
    keyPrefix: "common",
    useSuspense: false,
  });

  const gradeLevelFetch = useFetchGradeLevels({
    country: authUser?.country || "US",
    limit: 100,
  });
  const subjectsFetch = useFetchSubjects({
    country: authUser?.country || "US",
    limit: 100,
  });

  const gradeLevels = useMemo(
    () => gradeLevelFetch.data?.gradeLevels || [],
    [gradeLevelFetch.data?.gradeLevels]
  );
  const [subjects, parentSubjects] = useMemo(() => {
    const subjects = subjectsFetch.data?.subjects || [];
    const parentSubjects = subjects.filter(
      (subject) => subject.parent_id === "0"
    );
    return [subjects, parentSubjects];
  }, [subjectsFetch.data?.subjects]);

  const location = useLocation();
  const topPathName = location.pathname.split("/").slice(0, 3).join("/");

  const navigationData: IGuestNavigationDataContext = useMemo(() => {
    const authActionLinks = [
      {
        to: "/sign-in/teacher",
        label: tCommon("signIn"),
      },
    ];
    return {
      activeNavigationPath: topPathName,
      variant: "teacher",
      logoLink: {
        label: t("navigationLinks.home"),
        to: "/t/explore",
      },
      authActionLinks,
      subjects,
      parentSubjects,
      gradeLevels,
      user: authUser,
    };
  }, [
    topPathName,
    t,
    tCommon,
    subjects,
    parentSubjects,
    gradeLevels,
    authUser,
  ]);

  return (
    <GuestNavigationDataContext.Provider value={navigationData}>
      {children}
    </GuestNavigationDataContext.Provider>
  );
};

export { GuestTeacherNavigationDataProvider };
