import { IStrokePart } from "../types";

export class PenTool {
  readonly color: string;
  readonly width: number;
  readonly refCanvasWidth: number;
  readonly refCanvasHeight: number;

  constructor(
    color = "red",
    width = 3,
    refCanvasWidth = 100,
    refCanvasHeight = 100
  ) {
    this.color = color;
    this.width = width;
    this.refCanvasWidth = refCanvasWidth;
    this.refCanvasHeight = refCanvasHeight;
  }

  /**
   * Draws a "pen stroke" for all line segments
   * @param ctx
   * @param strokeParts
   */
  public draw(
    ctx: CanvasRenderingContext2D,
    strokeParts: IStrokePart[],
    canvasWidth: number,
    canvasHeight: number
  ): void {
    if (strokeParts.length < 1) return;

    const firstPart = strokeParts[0];

    ctx.beginPath();

    const penScale = Math.min(
      canvasWidth / this.refCanvasWidth,
      canvasHeight / this.refCanvasHeight
    );

    ctx.lineWidth = this.width * penScale;
    ctx.strokeStyle = this.color;
    ctx.lineCap = "round";
    ctx.lineJoin = "round";

    ctx.moveTo(
      firstPart.startPoint.x * canvasWidth,
      firstPart.startPoint.y * canvasHeight
    );

    strokeParts.forEach((strokePart) => {
      const { endPoint } = strokePart;
      ctx.lineTo(endPoint.x * canvasWidth, endPoint.y * canvasHeight);
    });

    ctx.stroke();
  }

  public getColor(): string {
    return this.color;
  }
}
