import { Text, VStack, usePrefersReducedMotion } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { animated, config, useSpring } from "react-spring";

import { pxToRem } from "adminComponents/utils";
import { XPPerLevel } from "links/lib/constants";
import { ISeasonLevel, ISessionUser } from "links/lib/types";
import { ProgressBar } from "sessionComponents/atoms/ProgressBar";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { ILevelUp } from "sessionComponents/hooks/useLevelUps";

export interface ILevelProgressProps {
  student: ISessionUser;
  totalXP: number;
  show: boolean;
  levelUps: Array<ILevelUp>;
  seasonLevels: Array<ISeasonLevel>;
  onEnterComplete: () => void;
}

const AnimatedVStack = animated(VStack);
const AnimatedProgressBar = animated(ProgressBar);

export const LevelProgress: React.FC<ILevelProgressProps> = ({
  student,
  levelUps,
  totalXP,
  show,
  seasonLevels,
  onEnterComplete,
}) => {
  const { match: currentBreakpoints } = useBreakpoints();
  const maxLevel = seasonLevels[seasonLevels.length - 1];
  const reduceMotion = usePrefersReducedMotion();
  const { t } = useTranslation("session", {
    useSuspense: false,
    keyPrefix: "podium.summary",
  });

  const enterSpring = useSpring({
    from: {
      opacity: 0,
      scale: reduceMotion ? 1 : 0.5,
    },
    to: {
      opacity: show ? 1 : 0,
      scale: show ? 1 : 0.5,
    },
    config: config.slow,
    onRest: onEnterComplete,
  });

  const totalXPSpring = useSpring({
    from: {
      xp: student.session_start_xp,
    },
    to: {
      xp: totalXP,
    },
    config: config.molasses,
  });

  const getLevelText = (v: number) => {
    let levelValue = v;
    if (!v || v > maxLevel.level) {
      levelValue = maxLevel.level;
    }

    return t("level", { level: Math.floor(levelValue) });
  };

  return (
    <AnimatedVStack style={enterSpring} w="full" alignItems="flex-end">
      <AnimatedProgressBar
        h={pxToRem(currentBreakpoints.buttonHeight * 0.8)}
        w="full"
        borderColor="primary.tan"
        borderRadius="full"
        borderWidth="0"
        backgroundColor="primary.light-gray"
        fillColor="utility.focus"
        labelFontWeight="bold"
        label={totalXPSpring.xp
          .to(
            levelUps.flatMap((lu) => [
              lu.levelXPThreshold,
              lu.nextLevelXPThreshold,
            ]),
            levelUps.flatMap((lu) => [
              lu.level?.level || 0,
              lu.nextLevel?.level || 0,
            ])
          )
          .to((v) => getLevelText(v))}
        percentValue={totalXPSpring.xp
          .to({
            range: levelUps.flatMap((lu) => [lu.startXP, lu.endXP]),
            output: levelUps.flatMap((lu) => {
              const startProgress =
                ((lu.startXP - lu.levelXPThreshold) /
                  (lu.nextLevelXPThreshold - lu.levelXPThreshold)) *
                100;
              const endProgress =
                100 -
                ((lu.nextLevelXPThreshold - lu.endXP) /
                  (lu.nextLevelXPThreshold - lu.levelXPThreshold)) *
                  100;
              return [startProgress, endProgress];
            }),
          })
          .to((v) => {
            return v === 100 ? 0 : v;
          })}
      />
      <Text
        textStyle="gameText"
        fontSize={pxToRem(currentBreakpoints.fontSize * 0.5)}
      >
        <animated.span>
          {totalXPSpring.xp
            .to({
              range: levelUps.flatMap((lu) => [lu.startXP, lu.endXP]),
              output: levelUps.flatMap((lu) => {
                const start = lu.nextLevelXPThreshold - lu.startXP;

                const remainingXP = lu.nextLevelXPThreshold - lu.endXP;
                return [start, Math.max(0, remainingXP)];
              }),
            })
            .to((v: number): number => {
              if (v === undefined) return 0;
              return v === 0 ? XPPerLevel : Math.round(v);
            })}
        </animated.span>
        <span>&nbsp;{t("xpTo")}&nbsp;</span>
        <animated.span>
          {totalXPSpring.xp
            .to(
              levelUps.flatMap((lu) => [
                lu.levelXPThreshold,
                lu.nextLevelXPThreshold,
              ]),
              levelUps.flatMap((lu) => [
                lu.level?.level || 0,
                lu.nextLevel?.level || 0,
              ])
            )
            .to((v) => getLevelText(v + 1))}
        </animated.span>
      </Text>
    </AnimatedVStack>
  );
};
