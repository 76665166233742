import { UseMutationResult, useMutation } from "react-query";

import { useAxios } from "../../hooks/useAxios";

export interface ISignAgreementArgs {
  agreement_id: string;
}

export interface IUseSignAgreementProps {
  onSuccess?: () => void;
  onError?: () => void;
  onSettled?: () => void;
}

export default function useSignAgreement(
  props?: IUseSignAgreementProps
): UseMutationResult<unknown, unknown, ISignAgreementArgs> {
  const axios = useAxios();

  const mutateAgreementSign = async (args: ISignAgreementArgs) => {
    const response = await axios
      .put(`/v1/agreements/${args.agreement_id}/sign`)
      .then((r) => r.data);
    return response;
  };

  const mutation = useMutation(mutateAgreementSign, {
    onError: props?.onError,
    onSuccess: props?.onSuccess,
    onSettled: props?.onSettled,
  });

  return mutation;
}
