import { Howl } from "howler";
import { useCallback, useMemo, useRef } from "react";
import { useUnmount } from "react-use";

export interface IUseHowlArgs {
  src: Array<string>;
  loop: boolean;
  volume: number;
  html5?: boolean;
  sprite?: { [key: string]: (number | boolean)[] };
  debug?: boolean;
}

export interface IUseHowlResult {
  fadeIn: (duration: number, playId: number) => void;
  pause: (playId: number) => void;
  stop: (playId: number) => void;
  volume: (playId: number, volume: number) => void;
  play: (playIdOrSprite?: number | string) => number;
  fadeOut: (duration: number, playId: number) => void;
}

const useHowl = (args: IUseHowlArgs): IUseHowlResult => {
  const { src, loop, volume: volumeArg, html5, debug, sprite } = args;

  const howl = useRef(
    new Howl({
      src,
      autoplay: false,
      loop,
      html5,
      volume: volumeArg,
      sprite,
    })
  );

  useUnmount(() => {
    logDebug("Unmounting");
    howl.current.unload();
  });

  const logDebug = useCallback(
    (message: string) => {
      if (!debug) return;

      console.log(`useHowl(${src}): ${message}`);
    },
    [debug, src]
  );

  const result = useMemo(() => {
    const fadeIn = (duration: number, playId: number) => {
      logDebug("fadeIn " + playId);

      howl.current.seek(0, playId).fade(0, volumeArg, duration, playId);
    };

    const pause = (playId: number) => {
      logDebug("pause " + playId);

      howl.current.pause(playId);
    };

    const stop = (playId: number) => {
      logDebug("stop " + playId);

      howl.current.stop(playId);
    };

    const fadeOut = (duration: number, playId: number) => {
      logDebug("fadeOut " + playId);

      howl.current.fade(volumeArg, 0, duration, playId);
    };

    const volume = (playId: number, volume: number) => {
      logDebug("volume " + playId + " - " + volume);

      howl.current.volume(volume, playId);
    };

    const play = (playIdOrSprite?: number | string): number => {
      logDebug("play " + playIdOrSprite);

      return howl.current.play(playIdOrSprite);
    };

    return {
      pause,
      fadeIn,
      fadeOut,
      play,
      stop,
      volume,
    };
  }, [logDebug, volumeArg]);

  return result;
};

export default useHowl;
