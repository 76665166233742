export const AdminClampedHeading = {
  baseStyle: (props: { noOfLines: number }): Record<string, unknown> => ({
    heading: {
      display: "-webkit-box",
      "-webkit-line-clamp": props.noOfLines,
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      wordBreak: "break-word",
    },
  }),
};
