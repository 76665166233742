import { pxToRem } from "adminComponents/utils/pxToRem";

export const AdminVideoPlayer = {
  baseStyle: {
    container: {
      position: "relative",
    },

    videoPlayer: {
      display: "block",
      width: "100%",
      maxWidth: "100%",
      borderRadius: pxToRem(8),
    },

    playButtonContainer: {
      background: "primary.golden",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: [pxToRem(44), null, pxToRem(60)],
      height: [pxToRem(44), null, pxToRem(60)],
      position: "absolute",
      top: "50%",
      left: "50%",
      borderRadius: "full",
      transform: "translateX(-50%) translateY(-50%)",
      zIndex: 2,
    },

    playButtonIcon: {
      svg: {
        width: [pxToRem(16), null, pxToRem(24)],
        height: [pxToRem(16), null, pxToRem(24)],
      },
    },
  },
};
