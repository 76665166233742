import { useMemo } from "react";

import { useSessionRoundGroupState } from "links/lib/contexts/sessionRoundGroupState";
import { ISessionUser } from "links/lib/types";
import { useGroupUsers } from "sessionComponents/hooks/useGroupUsers";

export interface IUserAnswer {
  user: ISessionUser;
  isCorrect: boolean;
  isPartiallyCorrect: boolean;
  // indicates whether an outcome was found for the user
  hasOutcome: boolean;
}

export const useGroupAnswers = (student: ISessionUser): Array<IUserAnswer> => {
  const roundGroupState = useSessionRoundGroupState();

  const groupUsers = useGroupUsers(student.session_group_id);

  const groupsAnswers = useMemo<Array<IUserAnswer>>(() => {
    return groupUsers
      .flatMap((groupUser) => {
        return {
          outcome: roundGroupState?.outcomes?.find(
            (outcome) => outcome.user_id === groupUser.id
          ),
          user: groupUser,
        };
      })
      .map(({ user, outcome }) => {
        return {
          user,
          isCorrect: outcome?.is_correct || false,
          isPartiallyCorrect: outcome?.is_partially_correct || false,
          hasOutcome: !!outcome,
        };
      });
  }, [groupUsers, roundGroupState?.outcomes]);

  return groupsAnswers;
};
