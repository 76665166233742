import { Flex } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Tabs } from "adminComponents/atoms/Tabs";
import {
  ClassroomPracticeSetReportHeroCard,
  IProps as ClassroomPracticeSetReportHeroCardProps,
} from "adminComponents/molecules/ClassroomPracticeSetReportHeroCard";
import {
  INavigationHeaderProps,
  NavigationHeader,
} from "adminComponents/molecules/NavigationHeader";
import {
  ReportingPage,
  ReportingSpecificSessionPicker,
} from "adminComponents/organisms/ReportingSpecificSessionPicker";
import { TemplateWithCenteredHeroOneColumn } from "adminComponents/templates/TemplateWithCenteredHeroOneColumn";
import { pxToRem } from "adminComponents/utils";
import { IAssignment, IPracticeSetSession } from "links/lib/types";
import { PracticeSetDetailTab } from "screens/TeacherClassrooms/PracticeSetDetail";

export const momentFormatString = "MMMM Do YYYY; h:mm A";

export interface ClassroomPracticeSetReportScreenProps {
  navigationData: INavigationHeaderProps;
  practiceSetReportData?: ClassroomPracticeSetReportHeroCardProps;
  pastAssignments?: Array<IAssignment>;
  specificAssignment?: IAssignment;
  setSpecificAssignment?: (assignment?: IAssignment) => void;
  pastSessions?: Array<IPracticeSetSession>;
  specificSession?: IPracticeSetSession;
  setSpecificSession?: (session?: IPracticeSetSession) => void;
  isLoading?: boolean;
  handleTabChange: (tab: PracticeSetDetailTab) => void;
  tabIndex: number;
  showPremiumMarker?: boolean;
}

export const ClassroomPracticeSetReportScreen: React.FC<
  ClassroomPracticeSetReportScreenProps
> = ({
  navigationData,
  isLoading,
  practiceSetReportData,
  pastAssignments,
  specificAssignment,
  setSpecificAssignment,
  pastSessions,
  specificSession,
  setSpecificSession,
  handleTabChange,
  tabIndex,
  children,
  showPremiumMarker = true,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const nav = <NavigationHeader isLoading={isLoading} {...navigationData} />;
  const heroContent = practiceSetReportData ? (
    <ClassroomPracticeSetReportHeroCard {...practiceSetReportData} />
  ) : (
    <></>
  );

  return (
    <TemplateWithCenteredHeroOneColumn
      nav={nav}
      heroContent={heroContent}
      variant="default"
      isLoading={isLoading}
      isHeroShifted
    >
      <Flex flexDir="column" gap={pxToRem(40)}>
        <ReportingSpecificSessionPicker
          pageType={ReportingPage.Classroom}
          pastAssignments={pastAssignments}
          specificAssignment={specificAssignment}
          setSpecificAssignment={setSpecificAssignment}
          pastSessions={pastSessions}
          specificSession={specificSession}
          setSpecificSession={setSpecificSession}
        />
        <Tabs
          variant="adminScreenTabs"
          tabIndex={tabIndex}
          handleChange={handleTabChange}
          tabData={[
            {
              label: t("classroomPracticeSetReport.questions"),
              content: <></>,
            },
            {
              label: t("classroomPracticeSetReport.studentPerformance"),
              content: <></>,
            },
            {
              label: t("classroomPracticeSetReport.standards"),
              content: <></>,
              isPremium: showPremiumMarker,
            },
          ]}
        />

        {!isLoading && children}
      </Flex>
    </TemplateWithCenteredHeroOneColumn>
  );
};
