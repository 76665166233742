import { Box, Flex, Image } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useMeasure } from "react-use";

import { Button } from "adminComponents/atoms/Button";
import { ClampedHeading } from "adminComponents/atoms/ClampedHeading";
import { Icon } from "adminComponents/atoms/Icon";
import { PremiumTooltipRich } from "adminComponents/atoms/PremiumTooltipRich";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils";

import BackgroundPNG from "./resource/background.png";
import InstantSetSVG from "./resource/instant-set-image.svg";
import StudentProfileSVG from "./resource/student-profile-livvy-image.svg";

interface IProps {
  title: string;
  // string or element to make it easy to use with <Trans> component to translate with links in the text
  // see InstantSetBanner for example
  description: string | JSX.Element;
  handleClick: () => void;
  buttonText: string;
  image: "instantSet" | "studentProfile";
  enablePremiumTooltip?: boolean;
}

const imgMap = {
  instantSet: InstantSetSVG,
  studentProfile: StudentProfileSVG,
};

export const CTABanner: React.FC<IProps> = ({
  title,
  description,
  handleClick,
  buttonText,
  image,
  enablePremiumTooltip,
}) => {
  const { t } = useTranslation("admin", {
    keyPrefix: "ctaBanner",
    useSuspense: false,
  });

  const paddingY = 56;
  const [ref, { width: bannerWidth, height: bannerHeight }] =
    useMeasure<HTMLDivElement>();
  const imageHeight = bannerHeight + 2 * paddingY + 40;

  const alt = useMemo(() => {
    switch (image) {
      case "instantSet":
        return t("instantSet");
      case "studentProfile":
        return t("studentProfile");
      default:
        return "";
    }
  }, [image, t]);

  return (
    <Flex
      backgroundImage={BackgroundPNG}
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      position="relative"
      px={pxToRem(48)}
      py={pxToRem(paddingY)}
      ref={ref}
      w="full"
    >
      <Flex
        flexDirection="column"
        w={{ base: "full", lg: "50%" }}
        gap={pxToRem(12)}
        alignItems={{ base: "center", lg: "start" }}
      >
        <ClampedHeading
          headingVariant="adminH5Bold"
          headingAs="h4"
          noOfLines={3}
        >
          {title}
        </ClampedHeading>
        <Text display="inline">{description}</Text>
        <PremiumTooltipRich isDisabled={!enablePremiumTooltip}>
          <Box>
            <Button
              leftIcon={<Icon icon="zap" />}
              variant="adminButtonFilled"
              onClick={handleClick}
              w="fit-content"
              disabled={enablePremiumTooltip}
            >
              {buttonText}
            </Button>
          </Box>
        </PremiumTooltipRich>
      </Flex>
      <Image
        right={{
          base: "admin.mobileXPadding",
          lg: "admin.desktopXPadding",
        }}
        top={pxToRem(-(paddingY / 2))}
        h={pxToRem(imageHeight)}
        maxW={pxToRem(bannerWidth / 2)}
        position="absolute"
        src={imgMap[image]}
        alt={alt}
        display={{ base: "none", lg: "block" }}
      />
    </Flex>
  );
};
