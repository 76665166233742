// Checks whether an image that is SVG type is invalid in that
// it contains a script tag.
export const checkInvalidSVG = (file: File): Promise<boolean> => {
  return new Promise((resolve) => {
    if (file.type.indexOf("image/svg") < 0) {
      resolve(false);
      return;
    }

    try {
      const fileReader = new FileReader();

      fileReader.onload = () => {
        if (!fileReader.result) {
          resolve(true);
          return;
        }

        const fileBody = fileReader.result as string;

        if (fileBody.toLowerCase().indexOf("<script") >= 0) {
          resolve(true);
          return;
        }

        resolve(false);
        return;
      };

      fileReader.readAsText(file);
    } catch (_) {
      resolve(false);
    }
  });
};
