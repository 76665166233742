import {
  Box,
  CSSObject,
  Container,
  Flex,
  useBreakpointValue,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import React from "react";

import { LoadingSpinner } from "adminComponents/atoms/LoadingSpinner";
import { NavTrigger } from "adminComponents/atoms/NavTrigger";

export interface IProps {
  nav: React.ReactNode;
  leftColumnContent: React.ReactNode;
  leftColumnShiftedContent?: React.ReactNode;
  rightColumnContent: React.ReactNode;
  topContent?: React.ReactNode;
  isLoading?: boolean;
  isLeftColumnSticky?: boolean;
}

export const StudentTemplateWithTwoColumns: React.FC<IProps> = ({
  nav,
  leftColumnContent,
  leftColumnShiftedContent,
  rightColumnContent,
  topContent,
  isLoading = false,
  isLeftColumnSticky,
}) => {
  const styles = useMultiStyleConfig("AdminStudentTemplateWithTwoColumns", {
    isLeftColumnSticky,
  });
  const isMobile = useBreakpointValue({ base: true, md: false });

  const LoadingSpinnerWrapper = ({ style }: { style: CSSObject }) => (
    <Box sx={style}>
      <LoadingSpinner />
    </Box>
  );

  return (
    <Box sx={styles.pageContainer}>
      <Box sx={styles.topContainer}>
        {nav}
        {nav && <NavTrigger />}
        {topContent && !isLoading && (
          <Container variant="adminContentWrapper">{topContent}</Container>
        )}
        {leftColumnShiftedContent && isMobile && !isLoading && (
          <Box sx={styles.leftColumnShiftedContent}>
            <Container variant="adminContentWrapper">
              {leftColumnShiftedContent}
            </Container>
          </Box>
        )}
      </Box>

      <Container variant="adminContentWrapper">
        {isLoading ? (
          <LoadingSpinnerWrapper style={styles.loadingSpinner} />
        ) : (
          <Flex sx={styles.bottomContainer}>
            <Box sx={styles.leftColumn}>
              <Box sx={styles.leftColumnInnerContent}>
                {!isMobile && (
                  <Box width="100%">{leftColumnShiftedContent}</Box>
                )}
                {leftColumnContent}
              </Box>
            </Box>
            <Box sx={styles.rightColumn}>{rightColumnContent}</Box>
          </Flex>
        )}
      </Container>
    </Box>
  );
};
