import { InfoOutlineIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Divider,
  HStack,
  Link,
  Spinner,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import React, { useMemo } from "react";

import { IStandard } from "links/lib/types";
import { MockCheckbox } from "sharedComponents/atoms/MockCheckbox";

export interface IStandardsListProps {
  selectedStandards: Array<IStandard>;
  standards: Array<IStandard>;
  isLoading: boolean;
  content: {
    noResultsMessage: string;
    requestStandardLink: string;
  };
  onStandardSelect: (standard: IStandard, isSelected: boolean) => void;
}

export interface IStandardsListItem {
  standard: IStandard;
  isSelected: boolean;
  onSelect: (standard: IStandard, isSelected: boolean) => void;
}

export interface IStandardsListEmptyProps {
  content: {
    noResultsMessage: string;
    requestStandardLink: string;
  };
}

const StandardsListItem: React.FC<IStandardsListItem> = ({
  standard,
  isSelected,
  onSelect,
}) => {
  const onButtonClick = () => {
    onSelect(standard, !isSelected);
  };

  return (
    <Button
      aria-label={standard.label}
      bg="transparent"
      h="fit-content"
      w="full"
      px={4}
      py={2}
      borderRadius="none"
      _hover={{ bg: "gray.50" }}
      _active={{ bg: "gray.100" }}
      _selected={{ bg: "transparent" }}
      onClick={onButtonClick}
    >
      <HStack
        justifyContent="flex-start"
        alignItems="flex-start"
        w="full"
        spacing={4}
        minW={0}
      >
        <Box mt={5}>
          <MockCheckbox size="md" isChecked={isSelected} />
        </Box>
        <Box textAlign="left" overflow="hidden" alignItems="flex-start">
          <Text fontSize="xs" color="gray.500">
            {standard.standard_collection_name}{" "}
            {standard.region && <>&bull; {standard.region}</>}
          </Text>
          <Text mt={2} fontSize="sm" fontWeight="bold" display="flex">
            {standard.root_label || standard.label}
            {!standard.region && (
              // TODO: Translate
              <Tooltip
                placement="right"
                label="This standard is the general standard and does not reflect any state level modifications"
              >
                <InfoOutlineIcon ml="4px" color="monaco.base" boxSize="14px" />
              </Tooltip>
            )}
          </Text>
          <Text
            mt={1}
            whiteSpace="break-spaces"
            textOverflow="ellipsis"
            fontSize="sm"
          >
            {standard.description}
          </Text>
        </Box>
      </HStack>
    </Button>
  );
};

const StandardsListEmpty: React.FC<IStandardsListEmptyProps> = ({
  content,
}) => {
  return (
    <Box w="full" mt={10}>
      <Center>
        <VStack>
          <Text fontSize="md">{content.noResultsMessage}</Text>
          <Link
            fontSize="sm"
            colorScheme="teal"
            color="teal.600"
            mt={6}
            href="https://forms.gle/b3A2kBuwbbBxSxuz5"
            target="_blank"
          >
            {content.requestStandardLink}
          </Link>
        </VStack>
      </Center>
    </Box>
  );
};

const StandardsList: React.FC<IStandardsListProps> = ({
  selectedStandards,
  standards,
  isLoading,
  content,
  onStandardSelect,
}) => {
  const sortedStandards = useMemo(() => {
    return standards.sort((a, b) => {
      return parseInt(a.id, 10) - parseInt(b.id, 10);
    });
  }, [standards]);

  const selectedStandardsMap = useMemo(() => {
    const map: { [key: string]: boolean } = {};

    selectedStandards.forEach((s) => {
      map[s.id] = true;
    });

    return map;
  }, [selectedStandards]);

  return (
    <Box w="full" h="300px" maxH="300px">
      <Divider />
      {isLoading && (
        <Center my={10}>
          <Spinner />
        </Center>
      )}
      <Box maxH="full" overflowY="auto" overflowX="hidden" w="full">
        {!isLoading && !!sortedStandards.length && (
          <VStack mt={6} w="full">
            {sortedStandards.map((standard) => (
              <StandardsListItem
                onSelect={onStandardSelect}
                key={`standard-${standard.id}`}
                standard={standard}
                isSelected={!!selectedStandardsMap[standard.id]}
              />
            ))}
          </VStack>
        )}
        {!isLoading && !sortedStandards.length && (
          <StandardsListEmpty
            content={{
              noResultsMessage: content.noResultsMessage,
              requestStandardLink: content.requestStandardLink,
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default StandardsList;
