import {
  Box,
  Center,
  Flex,
  HStack,
  Skeleton,
  SkeletonCircle,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Card } from "adminComponents/atoms/Card";
import { CertifiedCheckIcon } from "adminComponents/atoms/CertifiedCheckIcon";
import { ClampedHeading } from "adminComponents/atoms/ClampedHeading";
import { Icon } from "adminComponents/atoms/Icon";
import { IconTooltip } from "adminComponents/atoms/IconTooltip";
import { PremiumIcon } from "adminComponents/atoms/PremiumIcon";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils";
import { IPracticeSet } from "links/lib/types";

import CoverImageBlob from "../CoverImageBlob";

export interface IProps {
  practiceSet: IPracticeSet;
  handleClick?: () => void;
  isLoading?: boolean;
}

export const PRACTICE_SET_CARD_WIDTH = 185;

/**
 * CompactLibraryPracticeSetCard represents a practice set card as seen in the Q2 2024
 * redesigned library screens. It shares a name with a subcomponent of `LibraryPracticeSetCard`,
 * but differs significantly in design.
 */
export const CompactLibraryPracticeSetCard: React.FC<IProps> = ({
  practiceSet,
  handleClick,
  isLoading,
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });
  const practicSetIsPremiumOrCertified =
    practiceSet.is_certified || practiceSet.is_premium;

  return (
    <Card
      backgroundColor="primary.white"
      borderColor="primary.tan"
      minW={pxToRem(PRACTICE_SET_CARD_WIDTH)}
      maxW={pxToRem(PRACTICE_SET_CARD_WIDTH)}
      onClick={handleClick}
      position="relative"
      variant="adminCardSelectable"
      p={pxToRem(22)}
    >
      <Flex w="full" h="full" flexDirection="column" gap={pxToRem(2)}>
        {!isLoading &&
          practiceSet.rating &&
          practiceSet.rating.total_count_positive > 0 && (
            <HStack
              position="absolute"
              spacing={pxToRem(3)}
              top={pxToRem(14)}
              right={pxToRem(14)}
            >
              <Icon boxSize={15} icon="thumb_up_outlined" />
              <Text variant="adminP3Bold">
                {practiceSet.rating.total_count_positive.toLocaleString()}
              </Text>
            </HStack>
          )}
        <Center>
          <SkeletonCircle
            fadeDuration={10}
            boxSize={70}
            isLoaded={!isLoading}
            p={0}
          >
            {!isLoading && (
              <CoverImageBlob
                backgroundColorScheme={practiceSet.cover_image_bg_color_scheme}
                backgroundPattern={practiceSet.cover_image_bg_pattern}
                icon={practiceSet.cover_image_icon}
                boxSize={70}
              />
            )}
          </SkeletonCircle>
        </Center>
        {!isLoading && practicSetIsPremiumOrCertified && (
          <HStack mt={pxToRem(4)} spacing={pxToRem(4)}>
            {practiceSet.is_certified && (
              <IconTooltip
                popoverVariant="adminClampedHeading"
                triggerEl={
                  <Flex
                    alignItems="center"
                    borderRadius="full"
                    bgColor="lilac.03"
                    gap={pxToRem(4)}
                    p={pxToRem(6)}
                  >
                    <Text color="primary.warm-white" variant="adminMeta">
                      {t("common.certified")}
                    </Text>
                    <CertifiedCheckIcon boxSize={3} />
                  </Flex>
                }
              >
                {t("common.certifiedDescription")}
              </IconTooltip>
            )}
            {practiceSet.is_premium && <PremiumIcon size={24} />}
          </HStack>
        )}
        {isLoading && (
          <VStack w="full" alignItems="flex-start">
            <Flex gap={pxToRem(8)} mt={pxToRem(34)} w="70%">
              <Skeleton flexGrow={5} h={pxToRem(24)} />
              <Skeleton flexGrow={2} h={pxToRem(24)} />
            </Flex>
            <Skeleton h={pxToRem(16)} w="90%" />
            <Skeleton h={pxToRem(16)} w="full" />
            <Skeleton h={pxToRem(16)} w="80%" />
          </VStack>
        )}
        {!isLoading && practiceSet && (
          <>
            <Box
              flex="1"
              mt={!practicSetIsPremiumOrCertified ? pxToRem(34) : undefined}
            >
              <ClampedHeading
                headingStyles={{
                  fontSize: pxToRem(20),
                }}
                noOfLines={3}
                headingAs="h3"
                headingVariant="adminH5Bold"
              >
                {practiceSet.title}
              </ClampedHeading>
            </Box>

            <ClampedHeading
              headingStyles={{
                color: "primary.dark-gray",
                fontSize: pxToRem(16),
              }}
              noOfLines={1}
              headingAs="h4"
              headingVariant="adminH7Bold"
            >
              {`${practiceSet.item_count} ${
                practiceSet.item_count > 1
                  ? t("common.questions")
                  : t("common.question")
              }`}
            </ClampedHeading>

            <ClampedHeading
              headingStyles={{
                color: "primary.dark-gray",
                fontSize: pxToRem(16),
              }}
              noOfLines={1}
              headingAs="h4"
              headingVariant="adminH7"
            >
              {practiceSet.grade_levels.length ? (
                practiceSet.grade_levels
                  .map((gradeLevel) => gradeLevel.grade_level.grade_level)
                  .join(", ")
              ) : (
                <>&nbsp;</>
              )}
            </ClampedHeading>
          </>
        )}
      </Flex>
    </Card>
  );
};
