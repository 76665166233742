import { isEqual } from "lodash";
import qs from "qs";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useLocalStorage, usePrevious } from "react-use";

import { PublicLibraryScreen } from "adminComponents/screens/PublicLibraryScreen";
import { useAnalytics, usePageTrack } from "lib/contexts/analytics";
import { usePageTitle } from "lib/hooks/usePageTitle";
import { PUBLIC_SEARCH_PER_PAGE } from "links/lib/constants";
import { useAuth } from "links/lib/features/auth";
import {
  AnalyticsEvent,
  AnalyticsPage,
  ISubject,
  PracticeSetAvailability,
} from "links/lib/types";
import { useNavigationData } from "screens/TeacherDashboard/contexts/TeacherNavigationDataProvider";

import { usePublicLibrarySearch } from "../../../links/lib/features/search/usePublicLibrarySearch";
import { useInitialQuery } from "./hooks/useInitialQuery";

export interface ILibraryRootContentProps {
  subjects: Array<ISubject>;
  availability: PracticeSetAvailability;
}

export interface ILibraryRootProps {
  availability: PracticeSetAvailability;
}

export const LibraryRoot: React.FC<ILibraryRootProps> = ({ availability }) => {
  const { navigationData } = useNavigationData();

  return (
    <LibraryRootContent
      availability={availability}
      subjects={navigationData.subjects}
    />
  );
};

export const LibraryRootContent: React.FC<ILibraryRootContentProps> = ({
  availability,
  subjects,
}) => {
  const history = useHistory();
  const { navigationData } = useNavigationData();
  const { authUser, isFeatureEnabled, hasNoPremiumAccess } = useAuth();
  const { trackEvent } = useAnalytics();
  const { t: tSharedLibraries } = useTranslation("admin", {
    keyPrefix: "sharedLibraries",
    useSuspense: false,
  });
  const { t } = useTranslation("admin", {
    keyPrefix: "publicLibraryContainer.root",
    useSuspense: false,
  });

  usePageTrack(AnalyticsPage.TeacherDashboard_PublicLibrary_Root);
  usePageTitle(t("pageTitle"));

  const [displayBanner, setDisplayBanner] = useLocalStorage(
    "teacher.sharedLibraries.showBanner",
    true
  );

  const initialQuery = useInitialQuery({ subjects, authUser });

  const [page, setPage] = useState(initialQuery.basicSearchPage || 1);

  const showPremium = !isFeatureEnabled("playtime.teacher.hide_premium");

  const {
    handleClearFilters,
    handleClearSearchTerm,
    handleOpenFilterFlyout,
    handleRemoveFilterTag,
    handleSetTerm,
    handleSuggest,
    filterFlyout,
    standardsModal,
    totalCounts,
    isInitialLoad,
    practiceSets,
    isSearchLoading,
    query,
    subjectCounts,
    suggestions,
    handleChangeSubjects: _handleChangeSubjects,
    handleOpenStandardsModal,
  } = usePublicLibrarySearch({
    gradeLevels: navigationData.gradeLevels,
    subjects,
    region: authUser?.region,
    country: authUser?.country || "US",
    basicSearchPage: page,
    perPage: PUBLIC_SEARCH_PER_PAGE,
    initialQuery,
    availability,
    showPremiumFilter: showPremium,
    disabled:
      hasNoPremiumAccess && availability === PracticeSetAvailability.Domain,
  });

  const prevQuery = usePrevious(query);
  useEffect(() => {
    if (typeof prevQuery !== "undefined" && !isEqual(query, prevQuery)) {
      setPage(1);

      trackEvent(AnalyticsEvent.TeacherDashboard_PublicLibrary_Root_Search, {
        hasTerm: !!query.term,
        hasStandards: !!query.filters.standardIds.length,
        hasSubjects: !!query.filters.subjectIds.length,
        hasGrades: !!query.filters.gradeLevelIds.length,
        hasCertifiedOnly: query.filters.certifiedOnly,
        hasHidePremium: query.filters.hidePremium,
      });
    }
  }, [query, prevQuery, trackEvent]);

  useEffect(() => {
    const params = qs.stringify(
      {
        page,
        term: query.term,
        ...query.filters,
      },
      { encodeValuesOnly: true }
    );

    switch (availability) {
      case PracticeSetAvailability.Domain:
        history.push(`/t/my-school-library?${params}`);
        break;
      case PracticeSetAvailability.Public:
        history.push(`/t/public-library?${params}`);
        break;
    }
  }, [query, page, history, availability]);

  const handleCreatePracticeSetClick = () => {
    history.push("/t/my-library");

    trackEvent(
      AnalyticsEvent.TeacherDashboard_PublicLibrary_Root_ClickCreatePracticeSet,
      {}
    );
  };

  const handleDismissBannerClick = () => {
    setDisplayBanner(false);

    trackEvent(
      AnalyticsEvent.TeacherDashboard_PublicLibrary_Root_DismissStandardsBanner,
      {}
    );
  };

  const handleBannerClick = () => {
    handleOpenStandardsModal(true);

    trackEvent(
      AnalyticsEvent.TeacherDashboard_PublicLibrary_Root_ClickStandardsBanner,
      {}
    );
  };

  const handleSetPage = (page: number) => {
    setPage(page);

    trackEvent(AnalyticsEvent.TeacherDashboard_PublicLibrary_Root_ChangePage, {
      page,
    });
  };

  const handleChangeSubjects = (selectedIds: string[]) => {
    _handleChangeSubjects(selectedIds);

    trackEvent(
      AnalyticsEvent.TeacherDashboard_PublicLibrary_Root_ChangeSubjects,
      {
        hasSubjects: selectedIds.length,
      }
    );
  };

  return (
    <>
      <PublicLibraryScreen
        authUser={authUser}
        availability={availability}
        isLoading={isInitialLoad}
        banner={{
          isDismissed: !displayBanner,
          handleDismiss: handleDismissBannerClick,
        }}
        navigationData={navigationData}
        gradeLevels={navigationData.gradeLevels}
        practiceSets={practiceSets.total.map((practiceSet) => {
          return {
            practiceSet,
          };
        })}
        subjects={subjects}
        subjectCounts={subjectCounts}
        totalCount={totalCounts.practiceSets.total}
        paginate={{
          handleSetPage,
          page,
          perPage: PUBLIC_SEARCH_PER_PAGE,
        }}
        handleChangeSubjects={handleChangeSubjects}
        handleFilterByStandards={handleBannerClick}
        handleCreatePracticeSet={handleCreatePracticeSetClick}
        isSearchLoading={isSearchLoading}
        search={{
          gradeLevelIds: query.filters.gradeLevelIds,
          standardIds: query.filters.standardIds,
          subjectIds: query.filters.subjectIds,
          initialSearchTerm: query.term,
          handleClearAllFilters: handleClearFilters,
          handleClearSearchInput: handleClearSearchTerm,
          handleOpenFilterFlyout: handleOpenFilterFlyout,
          handleRemoveFilterTag: handleRemoveFilterTag,
          handleSetSearchTerm: handleSetTerm,
          handleSuggest: handleSuggest,
          searchPlaceholder: tSharedLibraries("searchPlaceholder"),
          suggestions,
        }}
        showPremiumMarker={showPremium}
        hasNoPremiumAccess={hasNoPremiumAccess}
      />
      {filterFlyout}
      {standardsModal}
    </>
  );
};
