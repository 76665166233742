import { pxToRem } from "adminComponents/utils/pxToRem";

export const AdminTeacherAccountInformationForm = {
  baseStyle: {
    outer: {
      backgroundColor: "primary.white",
    },
    headerContainer: {
      alignItems: "start",
      flexDirection: "column",
    },
    formContainer: {
      paddingY: pxToRem(40),
      flexDirection: "column",
      gap: pxToRem(40),
    },
    fieldContainer: {
      flexDirection: "column",
      gap: pxToRem(40),
      color: "primary.dark-gray",
    },
    button: {
      height: pxToRem(48),
      width: "100%",
      maxWidth: ["100%", null, pxToRem(138)],
    },
    buttonContainer: {
      justifyContent: ["center", null, "start"],
      gap: pxToRem(10),
    },
  },
};
