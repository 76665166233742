import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { IPracticeSetItem } from "../../types";

export interface ICopyPracticeSetItemArgs {
  id: string;
  practice_set_id: string;
  place_at_end?: boolean;
}

export interface ICopyPracticeSetItemResponse {
  practice_set_item: IPracticeSetItem;
}

export interface IUseCopyPracticeSetItemProps {
  onSuccess?: (data: ICopyPracticeSetItemResponse) => void;
  onError?: () => void;
  onSettled?: () => void;
}

export default function useCopyPracticeSetItem(
  props?: IUseCopyPracticeSetItemProps
): UseMutationResult<
  ICopyPracticeSetItemResponse,
  unknown,
  ICopyPracticeSetItemArgs
> {
  const axios = useAxios();
  const queryClient = useQueryClient();

  const copyPracticeSetItem = async (args: ICopyPracticeSetItemArgs) => {
    const { id, place_at_end, practice_set_id } = args;

    const response = await axios({
      method: "post",
      url: `/v1/practice-sets/items/${id}/copy`,
      data: {
        practice_set_id,
        place_at_end: place_at_end || false,
      },
    }).then((r) => r.data as { practice_set_item: IPracticeSetItem });

    queryClient.invalidateQueries(["practiceSets", { id: practice_set_id }]);
    queryClient.invalidateQueries(["practiceSetItems", { practice_set_id }]);
    queryClient.setQueryData(
      ["practiceSetItems", { id: response.practice_set_item.id }],
      {
        practice_set_item: response.practice_set_item,
      }
    );

    return response;
  };

  const mutation = useMutation(copyPracticeSetItem, {
    onSuccess: props?.onSuccess,
    onError: props?.onError,
    onSettled: props?.onSettled,
  });

  return {
    ...mutation,
  };
}
