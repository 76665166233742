import { Button, Square } from "@chakra-ui/react";
import React from "react";

import { useAxios } from "links/lib/hooks/useAxios";

export const InitiateRecurringNotification: React.FC = () => {
  const axios = useAxios();

  const onSendNotification = async () => {
    await axios({
      method: "post",
      url: `/v1/users/self/recurring-notifications/send`,
    });
  };

  return (
    <Square>
      <Button onClick={onSendNotification} aria-label={"Send notification"}>
        Send notification
      </Button>
    </Square>
  );
};
