import {
  Flex,
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverProps,
  PopoverTrigger,
  Portal,
  VStack,
} from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { Text } from "adminComponents/atoms/Text";
import { colors } from "adminComponents/theme/colors";
import { pxToRem } from "adminComponents/utils";
import { useGetPremiumLink } from "sharedComponents/hooks/useGetPremiumLink";

import PremiumCrownSparklesSVG from "./resource/PremiumCrownSparkles.svg";

const popoverBodyElementId = "premium-tooltip-rich-popover-body";

interface IProps {
  overrideText?: string;
  isDisabled?: boolean;
  placement?: PopoverProps["placement"];
}

const PremiumTooltipRich: React.FC<IProps> = ({
  overrideText,
  isDisabled = false,
  children,
  placement = "auto",
}) => {
  const { t } = useTranslation("admin", {
    keyPrefix: "premiumText",
    useSuspense: false,
  });

  const premiumLink = useGetPremiumLink();
  const handleLinkClick = () => {
    window.location.href = premiumLink;
  };

  const [arrowBorderStyles, setArrowBorderStyles] = useState({
    placement: "",
    style: {
      borderTopColor: "transparent",
      borderLeftColor: "transparent",
      borderBottomColor: "transparent",
      borderRightColor: "transparent",
    },
  });

  const setArrowStyle = useCallback(() => {
    const popoverBodyElement = document.getElementById(popoverBodyElementId);
    if (popoverBodyElement === null) {
      return;
    }

    const style = window.getComputedStyle(popoverBodyElement);
    const popperTransformOrigin = style.getPropertyValue(
      "--popper-transform-origin"
    );

    if (
      popperTransformOrigin.startsWith("bottom") &&
      arrowBorderStyles.placement !== "bottom"
    ) {
      setArrowBorderStyles({
        placement: "bottom",
        style: {
          borderTopColor: "transparent",
          borderLeftColor: "transparent",
          borderBottomColor: "primary.tan",
          borderRightColor: "primary.tan",
        },
      });
    } else if (
      popperTransformOrigin.startsWith("top") &&
      arrowBorderStyles.placement !== "top"
    ) {
      setArrowBorderStyles({
        placement: "top",
        style: {
          borderTopColor: "primary.tan",
          borderLeftColor: "primary.tan",
          borderBottomColor: "transparent",
          borderRightColor: "transparent",
        },
      });
    } else if (
      popperTransformOrigin.startsWith("left") &&
      arrowBorderStyles.placement !== "left"
    ) {
      setArrowBorderStyles({
        placement: "left",
        style: {
          borderTopColor: "transparent",
          borderLeftColor: "primary.tan",
          borderBottomColor: "primary.tan",
          borderRightColor: "transparent",
        },
      });
    } else if (
      popperTransformOrigin.startsWith("right") &&
      arrowBorderStyles.placement !== "right"
    ) {
      setArrowBorderStyles({
        placement: "right",
        style: {
          borderTopColor: "primary.tan",
          borderLeftColor: "transparent",
          borderBottomColor: "transparent",
          borderRightColor: "primary.tan",
        },
      });
    }
  }, [arrowBorderStyles]);

  if (isDisabled && children && React.isValidElement(children)) {
    return children;
  }

  return (
    <Popover
      trigger="hover"
      arrowSize={30}
      arrowShadowColor={colors.primary.tan}
      arrowPadding={25}
      placement={placement}
      openDelay={300}
      autoFocus={false}
      isLazy
      onOpen={setArrowStyle}
    >
      <PopoverTrigger>{children}</PopoverTrigger>
      <Portal>
        <PopoverContent
          color="primary.white"
          bg="white"
          borderColor="primary.tan"
          borderRadius={pxToRem(50)}
          borderWidth={pxToRem(5)}
          width="fit-content"
          maxW={["50vw", null, "35vw"]}
        >
          <PopoverArrow
            bg="white"
            borderRadius="lg"
            borderWidth={pxToRem(5)}
            {...arrowBorderStyles.style}
          />
          <PopoverBody
            fontWeight="600"
            px={pxToRem(30)}
            py={pxToRem(20)}
            w="full"
            h="full"
          >
            <Flex
              alignItems="center"
              justifyContent="center"
              flexDirection={["column", null, null, "row"]}
              gap={pxToRem(10)}
              id={popoverBodyElementId}
            >
              <Image
                boxSize={[pxToRem(60), pxToRem(130)]}
                src={PremiumCrownSparklesSVG}
              />
              <VStack
                alignSelf="center"
                alignItems="start"
                textAlign={["center", null, "left"]}
              >
                <Text variant="adminP1" color="primary.dark-gray">
                  {t("tooltipHeading")}
                </Text>
                <Text variant="adminP2" color="primary.dark-gray">
                  {overrideText || t("tooltipDefaultText")}
                </Text>
                <Text
                  variant="adminLinkMedium"
                  color="primary.dark-gray"
                  cursor="pointer"
                  onClick={handleLinkClick}
                >
                  {t("tooltipLink")}
                </Text>
              </VStack>
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export { PremiumTooltipRich };
