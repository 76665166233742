// wrapper for text & image responses
import { Box } from "@chakra-ui/react";
import React, { useState } from "react";

import { ColorScheme } from "adminComponents";
import { PracticeSessionItemVariantType } from "links/lib/types";
import {
  GameChoiceType,
  IGenericResponseComponentProps,
} from "sessionComponents/types";

import { AccuracyBar } from "../AccuracyBar";
import { Card } from "../Card";

export type QuestionResponseWrapperInjectedProps = {
  showCheckbox: boolean;
  showRadio: boolean;
  primaryColor: ColorScheme;
};

export interface IQuestionResponseWrapperProps
  extends IGenericResponseComponentProps {
  minHeight?: string;
  height?: string;
  width?: string;
  overflow?: string;
  noPadding?: boolean;
  accuracyBarBackgroundColor?: ColorScheme; //for overriding the default behavior of using grey
  accuracyBarFillColor?: ColorScheme; // for overriding the default behavior of using primaryColor
  children: (props: QuestionResponseWrapperInjectedProps) => JSX.Element;
  borderRadius?: string;
  onFocus?: (e: React.FocusEvent<HTMLDivElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLDivElement>) => void;
}

export const QuestionResponseWrapper: React.FC<
  IQuestionResponseWrapperProps
> = ({
  selected,
  loading,
  disabled,
  success,
  error,
  choiceType,
  ariaLabel,
  onClick,
  children,
  height,
  width,
  minHeight,
  maxWidth,
  overflow,
  accuracyPercentage,
  showAccuracyBar,
  noPadding,
  accuracyBarBackgroundColor,
  accuracyBarFillColor,
  backgroundColor,
  borderRadius,
  variant,
  showAsSelectable,
  showAsDefaultWrongAnswer,
  partiallyCorrect,
  onFocus,
  onBlur,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const getPrimaryColor = (): ColorScheme => {
    let primaryColor: ColorScheme = "primary.tan";
    if (selected) primaryColor = "utility.link";
    if (success) primaryColor = "utility.success";
    if (error) primaryColor = "utility.error";
    if (partiallyCorrect) primaryColor = "utility.orange";

    return primaryColor;
  };

  const getCursor = () => {
    if (disabled) return "not-allowed";
    if (
      loading ||
      error ||
      success ||
      !showAsSelectable ||
      showAsDefaultWrongAnswer
    )
      return "auto";
    if (variant === PracticeSessionItemVariantType.CoopDragsort) {
      return "grab";
    }

    if (
      [
        PracticeSessionItemVariantType.CoopChecks,
        PracticeSessionItemVariantType.CoopRadios,
        PracticeSessionItemVariantType.CoopDraw,
      ].includes(variant)
    ) {
      return "pointer";
    }

    return "auto";
  };

  const cursorType = getCursor();

  const getHoverState = () => {
    const noHover =
      disabled ||
      loading ||
      success ||
      error ||
      !showAsSelectable ||
      showAsDefaultWrongAnswer ||
      cursorType === "auto";

    return noHover ? undefined : "utility.link-hover";
  };

  const handleKeypress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (isFocused && e.key === " ") onClick?.();
  };

  const handleFocus = (e: React.FocusEvent<HTMLDivElement>) => {
    setIsFocused(true);
    onFocus?.(e);
  };

  const handleBlur = (e: React.FocusEvent<HTMLDivElement>) => {
    setIsFocused(false);
    onBlur?.(e);
  };

  const primaryColor = getPrimaryColor();
  const showCheckbox =
    choiceType === GameChoiceType.MultipleSelect &&
    !success &&
    !error &&
    !showAsDefaultWrongAnswer;
  const showRadio =
    choiceType === GameChoiceType.Radio &&
    !success &&
    !error &&
    !showAsDefaultWrongAnswer;

  return (
    <Card
      tabIndex={1}
      minHeight={minHeight}
      maxWidth={maxWidth}
      height={height}
      width={width}
      borderColor={primaryColor}
      borderRadius={borderRadius}
      justifyContent="center"
      display="flex"
      flexDirection="column"
      aria-label={ariaLabel}
      cursor={cursorType}
      position="relative"
      overflow={overflow}
      onClick={() => onClick?.()}
      onKeyPress={handleKeypress}
      onFocus={handleFocus}
      onBlur={handleBlur}
      paddingX={noPadding ? "0" : undefined}
      paddingY={noPadding ? "0" : undefined}
      backgroundColor={backgroundColor}
      _hover={{
        borderColor: getHoverState(),
      }}
    >
      {loading && (
        <Box
          as="span"
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          bg="rgba(255, 255, 255, .8)"
        />
      )}
      {children({
        showCheckbox,
        showRadio,
        primaryColor,
      })}
      {showAccuracyBar && (
        <Box position="absolute" bottom={0} left={0} width="100%" zIndex={2}>
          <AccuracyBar
            fillColor={accuracyBarFillColor || primaryColor}
            accuracyPercentage={accuracyPercentage || 0}
            backgroundColor={accuracyBarBackgroundColor}
          />
        </Box>
      )}
    </Card>
  );
};
