import {
  Link as ChakraLink,
  ComponentWithAs,
  LinkProps as IProps,
  forwardRef,
} from "@chakra-ui/react";
import React from "react";

import { Text } from "adminComponents/atoms/Text";

export const Link: ComponentWithAs<"a", IProps> = forwardRef<IProps, "a">(
  ({ children, size, ...rest }, ref) => (
    <ChakraLink {...rest} ref={ref}>
      <Text as="span" display="block" size={size} variant="adminP2">
        {children}
      </Text>
    </ChakraLink>
  )
);
