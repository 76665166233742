import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { useAxios } from "../../hooks/useAxios";

export interface IMoveStudentArgs {
  original_classroom_id: string;
  new_classroom_id: string;
  user_id: string;
}

export interface IUseMoveStudentArgs {
  onSuccess?: () => void;
}

export default function useMoveStudent({
  onSuccess,
}: IUseMoveStudentArgs): UseMutationResult<unknown, unknown, IMoveStudentArgs> {
  const axios = useAxios();
  const queryClient = useQueryClient();

  const update = async (args: IMoveStudentArgs) => {
    const { original_classroom_id, new_classroom_id, user_id } = args;

    const response = await axios({
      method: "post",
      url: `/v1/groups/${original_classroom_id}/users/${user_id}/move`,
      data: {
        original_group_id: original_classroom_id,
        new_group_id: new_classroom_id,
        user_id,
      },
    }).then((r) => {
      return r.data as unknown;
    });

    queryClient.invalidateQueries([
      "classrooms",
      { id: original_classroom_id },
    ]);
    queryClient.invalidateQueries(
      ["classroom-users", { id: original_classroom_id }],
      {
        exact: false,
      }
    );

    return response;
  };

  const mutation = useMutation(update, { onSuccess });

  return mutation;
}
