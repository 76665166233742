import { pxToRem } from "adminComponents/utils/pxToRem";

import { UnknownProps } from "./Button";

export const Drawer = {
  variants: {
    gameFlyoutDefault: (props: UnknownProps): Record<string, unknown> => {
      let colorScheme = "golden";
      if (props.colorScheme && props.colorScheme !== "gray") {
        colorScheme = props.colorScheme;
      }
      const colorSchemeBase = colorScheme + ".base";

      return {
        overlay: {
          backgroundColor: "primary.warm-black",
          opacity: 0.3,
        },
        header: {
          textStyle: "gameDisplayInline",
          fontSize: null,
        },
        body: {
          paddingBottom: 0,
          paddingX: 0,
          paddingTop: 0,
          textStyle: "gameText",
        },
        closeButton: {
          boxShadow: "none",
          borderRadius: "100%",
          _focus: {
            boxShadow: "none",
            border: `${pxToRem(2)} solid`,
            borderColor: colorSchemeBase,
          },
          _hover: {
            border: `${pxToRem(2)} solid`,
            borderColor: colorSchemeBase,
          },
          _active: {
            backgroundcolor: "transparent",
          },
        },
      };
    },
  },
};
