import { Center, Container, Image } from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { useDebounce, useOrientation, useWindowSize } from "react-use";

import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { IDiagramLabel } from "links/lib/types";

import { ReactComponent as Marker } from "./Resources/marker.svg";

export interface IProps {
  imageSrc: string;
  imageAltText?: string;
  diagramLabels: Array<IDiagramLabel>;
  handleOpenImageModal?: (imageUrl: string, imageAltText: string) => void;
}

export const QuestionDiagram: React.FC<IProps> = ({
  imageSrc,
  imageAltText,
  diagramLabels,
  handleOpenImageModal,
}) => {
  const imageRef = useRef<HTMLImageElement>(null);
  const [dimensions, setDimensions] = useState({ h: 0, w: 0 });
  const orientation = useOrientation();
  const windowSize = useWindowSize();

  const setDiagramDimensions = () =>
    setDimensions({
      h: imageRef.current?.clientHeight || 0,
      w: imageRef.current?.clientWidth || 0,
    });

  const [,] = useDebounce(
    () => {
      setDiagramDimensions();
    },
    100,
    [orientation, windowSize]
  );

  return (
    <Container
      maxW={{ base: "100%", md: "55%" }}
      position="relative"
      padding={0}
    >
      <Image
        cursor={handleOpenImageModal ? "pointer" : undefined}
        ref={imageRef}
        width="100%"
        src={imageSrc}
        alt={imageAltText}
        onLoad={() => setDiagramDimensions()}
        onClick={() => {
          handleOpenImageModal?.(imageSrc, imageAltText || "");
        }}
      />
      {diagramLabels.map((label, index) => {
        return (
          <Center
            key={`label-image-${index}`}
            w={{ base: pxToRem(28), md: pxToRem(32) }}
            h={{ base: pxToRem(34), md: pxToRem(38) }}
            position="absolute"
            left={{
              base: pxToRem(dimensions.w * label.x_offset_ratio - 14),
              md: pxToRem(dimensions.w * label.x_offset_ratio - 16),
            }}
            top={{
              base: pxToRem(dimensions.h * label.y_offset_ratio - 34),
              md: pxToRem(dimensions.h * label.y_offset_ratio - 38),
            }}
          >
            <Marker aria-hidden focusable={false} />
            <Text variant="adminP2" fontWeight="demibold" position="absolute">
              {index + 1}
            </Text>
          </Center>
        );
      })}
    </Container>
  );
};
