import {
  Box,
  HStack,
  SimpleGrid,
  Square,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { CertifiedCheckIcon } from "adminComponents/atoms/CertifiedCheckIcon";
import { CoverImageIcon } from "adminComponents/atoms/CoverImageIcon";
import { Heading } from "adminComponents/atoms/Heading";
import { Icon } from "adminComponents/atoms/Icon";
import { UpgradeCallToActionFancy } from "adminComponents/atoms/UpgradeCallToActionFancy";
import { BlankStatCard } from "adminComponents/molecules/BlankStatCard";
import { Carousel } from "adminComponents/molecules/Carousel";
import { StatCard } from "adminComponents/molecules/StatCard";
import { pxToRem } from "adminComponents/utils";
import { breakpoints } from "sessionComponents/theme/breakpoints";

interface IStudentSnapshotProps {
  totalIndividualSessionCount: number;
  totalIndividualSessionCountIsLoading: boolean;
  currentPeriodIndividualSessionsCount: number;
  previousPeriodIndividualSessionsCount: number;
  topHalfIndividualSessionsCount?: boolean;
  studentCount: number;
  totalExplainCount: number;
  totalExplainCountIsLoading: boolean;
  currentPeriodExplainCount: number;
  previousPeriodExplainCount: number;
  topHalfExplainCount?: boolean;
  totalCertifiedItemsCount: number;
  totalCertifiedItemsCountIsLoading: boolean;
  currentPeriodCertifiedItemsCount: number;
  previousPeriodCertifiedItemsCount: number;
  topHalfCertifiedItemsCount?: boolean;
  hasNoPremiumAccess: boolean;
}

export const StudentSnapshot: React.FC<IStudentSnapshotProps> = ({
  totalIndividualSessionCount,
  totalIndividualSessionCountIsLoading,
  currentPeriodIndividualSessionsCount,
  previousPeriodIndividualSessionsCount,
  topHalfIndividualSessionsCount,
  studentCount,
  totalExplainCount,
  totalExplainCountIsLoading,
  currentPeriodExplainCount,
  previousPeriodExplainCount,
  topHalfExplainCount,
  totalCertifiedItemsCount,
  totalCertifiedItemsCountIsLoading,
  previousPeriodCertifiedItemsCount,
  currentPeriodCertifiedItemsCount,
  topHalfCertifiedItemsCount,
  hasNoPremiumAccess,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const isMobile = useBreakpointValue({ base: true, lg: false });

  const individualSessionCountStats = (
    <StatCard
      key={1}
      coverImage={
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          backgroundColor="utility.salmon"
          borderRadius="full"
          boxSize={pxToRem(60)}
        >
          <CoverImageIcon boxSize={pxToRem(36)} icon="ENGLISH_LITERATURE" />
        </Box>
      }
      tooltipText={t(
        "districtAdminHomepage.studentSnapshot.individualPracticeExplanation"
      )}
      totalStat={totalIndividualSessionCount}
      isLoading={totalIndividualSessionCountIsLoading}
      currentStat={currentPeriodIndividualSessionsCount}
      previousStat={previousPeriodIndividualSessionsCount}
      topHalfStat={topHalfIndividualSessionsCount}
      topHalfTooltip={t(
        "districtAdminHomepage.studentSnapshot.topHalfIndividualSessionsCount"
      )}
      decreaseTooltipTextKey="districtAdminHomepage.studentSnapshot.individualPracticeSessionDecreaseExplanation"
      statLabel={t(
        "districtAdminHomepage.studentSnapshot.individualPracticeSession",
        { count: totalIndividualSessionCount }
      )}
      usePortalTooltips={true}
    />
  );

  const explainCountStats = (
    <StatCard
      key={2}
      coverImage={
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          backgroundColor="utility.badge-purple"
          borderRadius="full"
          boxSize={pxToRem(60)}
        >
          <CoverImageIcon boxSize={pxToRem(36)} icon="OTHER_TRIVIA" />
        </Box>
      }
      tooltipText={t(
        "districtAdminHomepage.studentSnapshot.explainExplanation"
      )}
      totalStat={totalExplainCount}
      isLoading={totalExplainCountIsLoading}
      currentStat={currentPeriodExplainCount}
      previousStat={previousPeriodExplainCount}
      topHalfStat={topHalfExplainCount}
      topHalfTooltip={t(
        "districtAdminHomepage.studentSnapshot.topHalfExplainCount"
      )}
      decreaseTooltipTextKey="districtAdminHomepage.studentSnapshot.explainDecreaseExplanation"
      statLabel={t("districtAdminHomepage.studentSnapshot.explainCount", {
        count: totalExplainCount,
      })}
      usePortalTooltips={true}
    />
  );

  const certifiedItemsCountStats = (
    <StatCard
      key={3}
      coverImage={
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          backgroundColor="primary.golden-light"
          borderRadius="full"
          boxSize={pxToRem(60)}
        >
          <CertifiedCheckIcon inverted boxSize={pxToRem(36)} />
        </Box>
      }
      tooltipText={t(
        "districtAdminHomepage.studentSnapshot.certifiedItemsExplanation"
      )}
      totalStat={totalCertifiedItemsCount}
      isLoading={totalCertifiedItemsCountIsLoading}
      currentStat={currentPeriodCertifiedItemsCount}
      previousStat={previousPeriodCertifiedItemsCount}
      topHalfStat={topHalfCertifiedItemsCount}
      topHalfTooltip={t(
        "districtAdminHomepage.studentSnapshot.topHalfCertifiedItemsCountExplanation"
      )}
      decreaseTooltipTextKey="districtAdminHomepage.studentSnapshot.certifiedItemsCountDecreaseExplanation"
      statLabel={t(
        "districtAdminHomepage.studentSnapshot.certifiedItemsCount",
        { count: totalCertifiedItemsCount }
      )}
      usePortalTooltips={true}
    />
  );

  const statCards = [
    certifiedItemsCountStats,
    individualSessionCountStats,
    explainCountStats,
  ];

  const emptyStatCards = [
    <BlankStatCard key={1} />,
    <BlankStatCard key={2} />,
    <BlankStatCard key={3} />,
  ];

  const renderedCards = hasNoPremiumAccess ? emptyStatCards : statCards;

  return (
    <Box w="100%" paddingTop={pxToRem(20)}>
      <VStack w="100%" align="start">
        <Heading variant="adminH3" as="h1">
          {t("districtAdminHomepage.studentSnapshot.title")}
        </Heading>
        <HStack>
          <Square
            borderRadius="full"
            borderWidth={pxToRem(2)}
            borderColor="black"
          >
            <Icon icon="person_outlined" />
          </Square>
          <Heading variant="adminH6" as="h3">
            {t("districtAdminHomepage.studentSnapshot.studentCount", {
              count: studentCount,
              total: studentCount.toLocaleString(),
            })}
          </Heading>
        </HStack>
        <Box paddingTop={pxToRem(10)} w="100%">
          {isMobile ? (
            <Box
              width={{
                base: "calc(100% + (var(--chakra-space-admin-mobileXPadding) * 3))",
                md: "calc(100% + (var(--chakra-space-admin-desktopXPadding) * 3))",
                lg: undefined,
              }}
              w="100%"
              position="relative"
            >
              {hasNoPremiumAccess && (
                <Box zIndex={3} top="5%" w="90%" position="absolute">
                  <UpgradeCallToActionFancy
                    upgradeText={t(
                      "districtAdminHomepage.studentSnapshot.premiumCTA"
                    )}
                    cta={t("upgradeCTA.upgrade")}
                    label={t("upgradeCTA.upgrade")}
                  />
                </Box>
              )}
              <Carousel
                style={{ paddingBottom: "40px" }}
                breakpoints={{
                  [breakpoints.portrait.lg.max]: {
                    slidesPerView: 2.25,
                  },
                }}
                showDots
                slidesPerView={1.25}
              >
                {renderedCards}
              </Carousel>
            </Box>
          ) : (
            <SimpleGrid
              columns={3}
              spacing={pxToRem(10)}
              w="100%"
              sx={{ "> div > div": { height: "100%" } }}
              position="relative"
            >
              {hasNoPremiumAccess && emptyStatCards}
              {hasNoPremiumAccess && (
                <Box top="5%" w="100%" position="absolute">
                  <UpgradeCallToActionFancy
                    upgradeText={t(
                      "districtAdminHomepage.studentSnapshot.premiumCTA"
                    )}
                    cta={t("upgradeCTA.upgrade")}
                    label={t("upgradeCTA.upgrade")}
                  />
                </Box>
              )}
              {!hasNoPremiumAccess && statCards}
            </SimpleGrid>
          )}
        </Box>
      </VStack>
    </Box>
  );
};
