import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { IconCTAButton } from "adminComponents/atoms/IconCTAButton";
import { Text } from "adminComponents/atoms/Text";
import {
  PracticeSetQuestionCard,
  PracticeSetQuestionCardType,
} from "adminComponents/molecules/PracticeSetQuestionCard";
import { pxToRem } from "adminComponents/utils";
import { IPracticeSetItem } from "links/lib/types";

export interface IProps {
  heading: string;
  subheading?: string;
  closeButtonLabel?: string;
  saveButtonLabel?: string;
  practiceSetItems: Array<IPracticeSetItem>;
  isOpen: boolean;
  isLoading: boolean;
  handleClose: () => void;
  handleSave?: () => void;
}

export const PracticeSetItemsPreviewModal: React.FC<IProps> = ({
  heading,
  subheading,
  practiceSetItems,
  isOpen,
  isLoading,
  closeButtonLabel,
  saveButtonLabel,
  handleClose,
  handleSave,
}: IProps) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      size="6xl"
      variant="adminModal"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text variant="adminH5">{heading}</Text>
          <Text variant="adminP1" color="primary.dark-gray">
            {subheading}
          </Text>
        </ModalHeader>
        <ModalCloseButton top={pxToRem(20)} right={pxToRem(20)}>
          <IconCTAButton ariaLabel={t("common.close")} />
        </ModalCloseButton>
        <ModalBody>
          <VStack spacing={pxToRem(24)}>
            {practiceSetItems.map((practiceSetItem, i) => {
              return (
                <PracticeSetQuestionCard
                  key={i}
                  practiceSetItem={practiceSetItem}
                  questionNumber={i + 1}
                  cardType={PracticeSetQuestionCardType.Answers}
                  allowRemove={false}
                  allowDuplicate={false}
                  allowEdit={false}
                />
              );
            })}
          </VStack>

          <Flex gap={pxToRem(12)} mt={pxToRem(24)} justifyContent="flex-end">
            <Button
              variant={
                !handleSave ? "adminButtonFilled" : "adminButtonOutlined"
              }
              size="lg"
              w={["50%", null, "auto"]}
              onClick={handleClose}
              rounded="full"
            >
              {closeButtonLabel || t("common.cancel")}
            </Button>

            {handleSave && (
              <Button
                variant="adminButtonFilled"
                size="lg"
                w={["50%", null, "auto"]}
                onClick={handleSave}
                isLoading={isLoading}
                rounded="full"
              >
                {saveButtonLabel || t("common.save")}
              </Button>
            )}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
