import { UseQueryResult, useQuery } from "react-query";

import { IUser } from "links/lib/types";

import { useAxios } from "../../hooks/useAxios";

export interface ITopSocialEventReactorsResponse {
  top_social_event_reactors: Array<{
    user: IUser;
    social_event_reactions_count: number;
  }>;
}

export interface IUseFetchTopSocialEventReactorsArgs {
  period_days?: number;
  limit?: number;
  enabled?: boolean;
}

export default function useFetchTopSocialEventReactors(
  args: IUseFetchTopSocialEventReactorsArgs
): UseQueryResult<ITopSocialEventReactorsResponse> {
  const { enabled = true, period_days = 30, limit = 10 } = args;
  const axios = useAxios();

  const query = useQuery<ITopSocialEventReactorsResponse>(
    ["topSocialEventReactors"],
    async (): Promise<ITopSocialEventReactorsResponse> => {
      return await axios
        .get("/v1/social/reactions/top", { params: { period_days, limit } })
        .then((r) => {
          return r.data as ITopSocialEventReactorsResponse;
        });
    },
    { enabled }
  );

  return query;
}
