import { colorMapping } from "adminComponents/theme/colors";
import { pxToRem } from "adminComponents/utils/pxToRem";

export const Popover = {
  variants: {
    adminSolid: {
      content: {
        "--popper-arrow-bg": colorMapping["primary.warm-black"],
        backgroundColor: "primary.warm-black",
        color: "primary.white",
        width: "auto",
        maxWidth: pxToRem(228),
        paddingY: pxToRem(10),
        paddingX: pxToRem(14),
        border: "none",
        borderRadius: "lg",
        opacity: 0.9,
      },
      body: {
        tabIndex: -1,
        padding: 0,
      },
    },
    adminOutline: {
      content: {
        "--popper-arrow-bg": "transparent",
        backgroundColor: "primary.white",
        color: "primary.warm-black",
        paddingY: pxToRem(10),
        paddingX: pxToRem(14),
        borderWidth: pxToRem(3),
        borderStyle: "solid",
        borderColor: "primary.tan",
        borderRadius: "lg",
        opacity: 0.9,
        _focus: {
          boxShadow: "none",
        },
      },
      body: {
        tabIndex: -1,
        padding: 0,
      },
    },
    adminProfileMenu: {
      content: {
        borderRadius: pxToRem(20),
        w: pxToRem(301),
        _focus: {
          boxShadow: "none",
        },
      },
      body: {
        tabIndex: -1,
        padding: 0,
      },
    },
    adminExplore: {
      popper: {
        zIndex: 1,
      },
    },
  },
};
