import { Box } from "@chakra-ui/react";
import React from "react";

import { StudentSnapshot as AdminStudentSnapshot } from "adminComponents/screens/AdminHomepage/components/StudentSnapshot";
import { useErrorToast } from "adminComponents/utils/toast";
import { useAuth } from "links/lib/features/auth";
import { useFetchOrganizationExplainConversationsCount } from "links/lib/features/explain";
import { useFetchOrganizationStudentCount } from "links/lib/features/organizations";
import {
  useFetchOrganizationPracticedCertifiedItemsCount,
  useFetchOrganizationSessionCount,
} from "links/lib/features/sessions";
import { SessionType } from "links/lib/types";

export const StudentSnapshot: React.FC = () => {
  const { hasNoPremiumAccess, isFeatureEnabled } = useAuth();

  const studentCountFetch = useFetchOrganizationStudentCount();
  const totalIndividualSessionsFetch = useFetchOrganizationSessionCount({
    session_type: SessionType.Individual,
    days_ago: 0,
    include_previous_period: false,
    include_top_org_check: false,
  });
  const changeOverTimeIndividualSessionsFetch =
    useFetchOrganizationSessionCount({
      session_type: SessionType.Individual,
      days_ago: 30,
      include_previous_period: true,
      include_top_org_check: isFeatureEnabled("playtime.enable_top_districts"),
    });

  const totalExplainCountFetch = useFetchOrganizationExplainConversationsCount({
    days_ago: 0,
    include_previous_period: false,
    include_top_org_check: false,
  });
  const changeOverTimexplainCountFetch =
    useFetchOrganizationExplainConversationsCount({
      days_ago: 30,
      include_previous_period: true,
      include_top_org_check: isFeatureEnabled("playtime.enable_top_districts"),
    });

  const totalCertifiedItemsCountFetch =
    useFetchOrganizationPracticedCertifiedItemsCount({
      days_ago: 0,
      include_previous_period: false,
      include_top_org_check: false,
    });
  const changeOverTimeCertifiedItemsCountFetch =
    useFetchOrganizationPracticedCertifiedItemsCount({
      days_ago: 30,
      include_previous_period: true,
      include_top_org_check: isFeatureEnabled("playtime.enable_top_districts"),
    });

  useErrorToast(totalIndividualSessionsFetch.error);
  useErrorToast(changeOverTimeIndividualSessionsFetch.error);
  useErrorToast(studentCountFetch.error);
  useErrorToast(totalExplainCountFetch.error);
  useErrorToast(changeOverTimexplainCountFetch.error);
  useErrorToast(totalCertifiedItemsCountFetch.error);
  useErrorToast(changeOverTimeCertifiedItemsCountFetch.error);

  return (
    <Box w="100%">
      <AdminStudentSnapshot
        totalIndividualSessionCount={parseInt(
          totalIndividualSessionsFetch.data?.session_count || "0"
        )}
        totalIndividualSessionCountIsLoading={
          totalIndividualSessionsFetch.isLoading
        }
        currentPeriodIndividualSessionsCount={parseInt(
          changeOverTimeIndividualSessionsFetch.data?.session_count || "0"
        )}
        previousPeriodIndividualSessionsCount={parseInt(
          changeOverTimeIndividualSessionsFetch.data
            ?.previous_period_session_count || "0"
        )}
        topHalfIndividualSessionsCount={
          changeOverTimeIndividualSessionsFetch.data
            ?.top_half_of_all_organizations
        }
        studentCount={parseInt(studentCountFetch.data?.student_count || "0")}
        totalExplainCount={parseInt(
          totalExplainCountFetch.data?.explain_count || "0"
        )}
        totalExplainCountIsLoading={totalExplainCountFetch.isLoading}
        currentPeriodExplainCount={parseInt(
          changeOverTimexplainCountFetch.data?.explain_count || "0"
        )}
        previousPeriodExplainCount={parseInt(
          changeOverTimexplainCountFetch.data?.previous_period_explain_count ||
            "0"
        )}
        topHalfExplainCount={
          changeOverTimexplainCountFetch.data?.top_half_of_all_organizations
        }
        totalCertifiedItemsCount={parseInt(
          totalCertifiedItemsCountFetch.data?.practiced_certified_item_count ||
            "0"
        )}
        totalCertifiedItemsCountIsLoading={
          totalCertifiedItemsCountFetch.isLoading
        }
        currentPeriodCertifiedItemsCount={parseInt(
          changeOverTimeCertifiedItemsCountFetch.data
            ?.practiced_certified_item_count || "0"
        )}
        previousPeriodCertifiedItemsCount={parseInt(
          changeOverTimeCertifiedItemsCountFetch.data
            ?.previous_period_practiced_certified_item_count || "0"
        )}
        topHalfCertifiedItemsCount={
          changeOverTimeCertifiedItemsCountFetch.data
            ?.top_half_of_all_organizations
        }
        hasNoPremiumAccess={hasNoPremiumAccess}
      />
    </Box>
  );
};
