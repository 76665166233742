/**
 * This utility aims at providing a way to check if you
 * can hover first before applying hover css and aims to
 * work inline with JSX and in object notation anywhere.
 *
 * @param styles
 * @returns a wrapping object with a hover check first
 */

export const hoverStyles = (
  styles: Record<string, unknown>
): { [key: string]: Record<string, unknown> } => {
  return {
    "@media (hover: hover)": styles,
  };
};

/**
 * Examples:
 *
 * <Box _hover={hoverStyles({ border: '1px color blue' })}>
 *
 * container: {
 *   border: '1px color red',
 *   _hover: hoverStyles({
 *     borderColor: 'blue',
 *   }),
 * }
 *
 */
