import { Link, LinkProps } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

import { ColorScheme, NavigationLink } from "adminComponents";
import { colorMapping } from "adminComponents/theme/colors";
import { pxToRem } from "adminComponents/utils";

import { ReactComponent as SvgLogoPearPractice } from "./resource/logo_pear_practice.svg";

export interface IProps
  extends Omit<LinkProps, "href" | "isExternal" | "aria-label" | "ariaLabel"> {
  logoColor?: ColorScheme;
  navigationLink?: NavigationLink;
}

const Logo: React.FC<IProps> = ({
  color = "primary.warm-black",
  navigationLink,
  width = "100%",
  ...rest
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  return navigationLink ? (
    <Link
      {...rest}
      aria-label={navigationLink?.label || t("logo.ariaLabel")}
      as={navigationLink?.isExternal ? Link : RouterLink}
      color={color}
      display="inline-block"
      href={navigationLink.isExternal ? navigationLink.to : undefined}
      isExternal={navigationLink.isExternal}
      to={navigationLink.isExternal ? undefined : navigationLink.to}
      width={width}
      _focus={{
        boxShadow: "none",
      }}
      _focusVisible={{
        outline: `${pxToRem(2)} solid ${colorMapping["utility.focus"]}`,
        borderRadius: pxToRem(4),
      }}
    >
      <SvgLogoPearPractice />
    </Link>
  ) : (
    <SvgLogoPearPractice aria-label={t("logo.ariaLabel")} />
  );
};

export { Logo };
