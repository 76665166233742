import {
  ISessionRoundGroupState,
  ISessionSelectedChoice,
} from "links/lib/types";

export const getSelectedChoices = (
  roundGroupState: ISessionRoundGroupState
): ISessionSelectedChoice[] | undefined => {
  const { radios, checks, text_match } = roundGroupState;
  if (radios) return radios.selected_choices;
  if (checks) return checks.selected_choices;
  if (text_match) return text_match.selected_choices;
  return undefined;
};
