import { Box, FormControl, HStack, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Card } from "adminComponents/atoms/Card";
import { Dropdown, IOption } from "adminComponents/atoms/Dropdown";
import { FormLabel } from "adminComponents/atoms/FormLabel";
import { Heading } from "adminComponents/atoms/Heading";
import { LoadingSpinner } from "adminComponents/atoms/LoadingSpinner";
import { EmptyCard } from "adminComponents/molecules/EmptyCard";
import {
  INavigationHeaderProps,
  NavigationHeader,
} from "adminComponents/molecules/NavigationHeader";
import {
  AssignmentDetail,
  AssignmentScoreGrid,
} from "adminComponents/organisms/AssignmentScoreGrid";
import { TemplateWithCenteredHeroOneColumn } from "adminComponents/templates/TemplateWithCenteredHeroOneColumn";
import { pxToRem } from "adminComponents/utils";
import { IClassroom, IUser } from "links/lib/types";

export interface IProps {
  navigationData: INavigationHeaderProps;
  isLoading?: boolean;
  classrooms: Array<IClassroom>;
  handleClassroomChange?: (classroom: IClassroom) => void;
  selectedClassroomId?: string;
  students: Array<IUser>;
  assignmentDetails: Array<AssignmentDetail>;
  isScoresLoading?: boolean;
}

export const AssignmentGradebookScreen: React.FC<IProps> = ({
  isLoading,
  navigationData,
  students,
  assignmentDetails,
  classrooms,
  handleClassroomChange,
  selectedClassroomId,
  isScoresLoading,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  const handleClassroomSelected = (value: unknown) => {
    const classroom = classrooms.find((c) => c.id === (value as IOption).value);
    if (classroom) {
      handleClassroomChange?.(classroom);
    }
  };

  const currentClassroom = selectedClassroomId
    ? classrooms.find((c) => c.id === selectedClassroomId)
    : undefined;

  return (
    <TemplateWithCenteredHeroOneColumn
      nav={<NavigationHeader {...navigationData} isLoading={isLoading} />}
      heroContent={
        <VStack
          alignItems="flex-start"
          justifyContent="flex-start"
          w="full"
          spacing={pxToRem(20)}
        >
          <Heading as="h1" mb={{ base: pxToRem(32), md: 0 }} variant="adminH2">
            {t("assignmentGradebookScreen.heading")}
          </Heading>
          <Text variant="adminP1">
            {t("assignmentGradebookScreen.subHeading")}
          </Text>
        </VStack>
      }
      isLoading={isLoading}
      variant="default"
    >
      {!isLoading && (
        <VStack
          w="full"
          alignItems="flex-start"
          justifyContent="flex-start"
          spacing={pxToRem(20)}
        >
          <HStack w="full">
            <FormControl maxW={["full", null, pxToRem(240)]}>
              <FormLabel id="selectClassroom">
                {t("assignmentGradebookScreen.classroomDropdownLabel")}
              </FormLabel>
              {/* z-index/relative required for forcing dropdown to present over scoregrid sort dropdown  */}
              <Box zIndex="5" position="relative">
                <Dropdown
                  options={classrooms.map((classroom) => ({
                    label: classroom.name,
                    value: classroom.id,
                  }))}
                  isDisabled={isScoresLoading}
                  value={
                    currentClassroom
                      ? {
                          label: currentClassroom.name,
                          value: currentClassroom.id,
                        }
                      : undefined
                  }
                  placeholder={t(
                    "assignmentGradebookScreen.classroomDropdownPlaceholder"
                  )}
                  handleChange={handleClassroomSelected}
                  aria-labelled-by="selectClassroom"
                />
              </Box>
            </FormControl>
          </HStack>
          {!selectedClassroomId && (
            <EmptyCard
              title={t("assignmentGradebookScreen.selectClassroomCardTitle")}
              text={t("assignmentGradebookScreen.selectClassroomCardDesc")}
            />
          )}
          {!!selectedClassroomId && (
            <>
              {isScoresLoading ? (
                <Box w="full" pt={pxToRem(40)}>
                  <LoadingSpinner />
                </Box>
              ) : (
                <>
                  {assignmentDetails.length === 0 || students.length === 0 ? (
                    <EmptyCard
                      title={t("assignmentGradebookScreen.noDataCardTitle")}
                      text={t("assignmentGradebookScreen.noDataCardDesc")}
                    />
                  ) : (
                    <Card
                      borderColor="primary.tan"
                      variant="adminCardMediumBorder"
                      bgColor="white"
                      px={pxToRem(6)}
                      py={pxToRem(6)}
                    >
                      <AssignmentScoreGrid
                        users={students}
                        assignmentDetails={assignmentDetails}
                      />
                    </Card>
                  )}
                </>
              )}
            </>
          )}
        </VStack>
      )}
    </TemplateWithCenteredHeroOneColumn>
  );
};
