import { IUseAsyncReturn, useAsync } from "../../hooks/useAsync";
import { useAxios } from "../../hooks/useAxios";
import { IPracticeSetItem, PracticeSessionItemVariantType } from "../../types";

export interface IFetchSessionPreviewOpts {
  practice_set_id: string;
  enable_coop_draw: boolean;
  generate_questions_from_terms: boolean;
}

export interface IFetchSessionPreviewResponse {
  session_items: Array<{
    practice_set_item: IPracticeSetItem;
    variant: PracticeSessionItemVariantType;
  }>;
}

/**
 * Fetches a session preview (the items that would be generated) a single time
 * using execution parameters without caching results or invalidating session queries
 * @returns
 */
export default function useFetchSessionPreviewOnce(): IUseAsyncReturn<
  IFetchSessionPreviewResponse,
  IFetchSessionPreviewOpts
> {
  const axios = useAxios();

  const fetchSessionPreview = async ({
    practice_set_id,
    enable_coop_draw,
    generate_questions_from_terms,
  }: IFetchSessionPreviewOpts) => {
    return await axios
      .get(
        `/v1/practice-sets/${practice_set_id}/sessions/preview?enable_coop_draw=${enable_coop_draw}&generate_questions_from_terms=${generate_questions_from_terms}`
      )
      .then(
        (r) =>
          r.data as {
            session_items: Array<{
              practice_set_item: IPracticeSetItem;
              variant: PracticeSessionItemVariantType;
            }>;
          }
      );
  };

  const { isLoading, error, data, execute, isSuccess } = useAsync<
    IFetchSessionPreviewResponse,
    IFetchSessionPreviewOpts
  >(fetchSessionPreview);

  return {
    isLoading,
    error,
    data,
    execute,
    isSuccess,
  };
}
