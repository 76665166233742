import { useMemo } from "react";

import {
  ISessionUser,
  SessionTheBigBoardSpaceType,
  SessionTheBigBoardState,
} from "links/lib/types";

export interface IUseBoardOutcomeArgs {
  user: ISessionUser;
  targetSpaceId?: number;
  boardState: SessionTheBigBoardState;
}

export interface IUseBoardOutcomeResult {
  isCarePackage: boolean;
  isPositiveOutcome: boolean;
}

const useBoardOutcome = ({
  user,
  targetSpaceId,
  boardState,
}: IUseBoardOutcomeArgs): IUseBoardOutcomeResult => {
  const spaceToRowMap = useMemo(() => {
    const rowMap: { [key: number]: number } = {};
    boardState.rows.forEach((row, rowIndex) => {
      row.cols.forEach((col) => {
        rowMap[col.space_id] = rowIndex;
      });
    });
    return rowMap;
  }, [boardState.rows]);

  const spaceToColMap = useMemo(() => {
    const colMap: { [key: number]: number } = {};
    boardState.rows.forEach((row) => {
      row.cols.forEach((col, colIndex) => {
        colMap[col.space_id] = colIndex;
      });
    });
    return colMap;
  }, [boardState.rows]);

  return useMemo<IUseBoardOutcomeResult>(() => {
    if (!targetSpaceId)
      return { isCarePackage: false, isPositiveOutcome: false };

    const space =
      boardState.rows[spaceToRowMap[targetSpaceId]].cols[
        spaceToColMap[targetSpaceId]
      ];

    if (space.space_type === SessionTheBigBoardSpaceType.Smiley) {
      return {
        isCarePackage: false,
        isPositiveOutcome: true,
      };
    } else if (space.space_type === SessionTheBigBoardSpaceType.Lootbox) {
      return {
        isCarePackage: true,
        isPositiveOutcome: true,
      };
    }

    const isPositiveOutcome = space.placements.some((p) => {
      return p.group_id === user.session_group_id;
    });

    return {
      isPositiveOutcome,
      isCarePackage: false,
    };
  }, [
    targetSpaceId,
    boardState.rows,
    user.session_group_id,
    spaceToRowMap,
    spaceToColMap,
  ]);
};

export default useBoardOutcome;
