import { ButtonProps } from "@chakra-ui/react";
import { debounce } from "lodash";
import React, { useMemo } from "react";

import usePendingSubmit, {
  IUsePendingSubmitOptions,
} from "links/lib/hooks/usePendingSubmit";
import { Button } from "sessionComponents/atoms/Button";

export interface IConfirmButtonProps extends ButtonProps {
  handleConfirmClick?: () => void;
  animation?: string;
  disabled?: boolean;
  confirmedWhenChanged: Array<unknown>;
  opts?: IUsePendingSubmitOptions;
  variant?: string;
  hideButtonOnConfirm?: boolean;
}

export const ConfirmButton: React.FC<IConfirmButtonProps> = ({
  handleConfirmClick,
  animation,
  disabled,
  confirmedWhenChanged,
  opts,
  variant,
  hideButtonOnConfirm = false,
  children,
  ...rest
}) => {
  const { isPending, handleSubmit, isComplete } = usePendingSubmit(
    confirmedWhenChanged,
    opts
  );

  const onClick = useMemo(
    () =>
      debounce(
        // want to take dependencies in as params because we don't want the debounce to get reinstantiated if one changes
        ({ isPending, handleSubmit, handleConfirmClick }) => {
          if (!isPending) {
            handleSubmit();
            handleConfirmClick?.();
          }
        },
        250,
        { leading: true, trailing: false }
      ),
    []
  );

  const hideButton = hideButtonOnConfirm && isComplete;

  return (
    <>
      {!hideButton && (
        <Button
          disabled={disabled}
          isLoading={isPending}
          variant={variant}
          onClick={() =>
            onClick({ isPending, handleSubmit, handleConfirmClick })
          }
          animation={animation}
          {...rest}
        >
          {children}
        </Button>
      )}
    </>
  );
};
