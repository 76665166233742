import { Box, Text } from "@chakra-ui/react";
import lottie, {
  AnimationItem,
  AnimationSegment,
} from "lottie-web/build/player/lottie_light";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { animated, config, useSpring } from "react-spring";
import { useMount, usePrevious, useUnmount } from "react-use";

import { pxToRem } from "adminComponents/utils";
import { AnalyticsEvent } from "links/lib/types";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { StudentSessionSoundEffect } from "sessionComponents/types";
import { useSessionAnalytics } from "sharedComponents/contexts/sessionAnalytics";
import { useSessionAudio } from "sharedComponents/contexts/sessionAudio";

import progress3Data from "./resource/week-streak-extended-postgame.json";
import progress1Data from "./resource/week-streak-progress-1-postgame.json";
import progress2Data from "./resource/week-streak-progress-2-postgame.json";

export interface IPodiumStreakProps {
  studentName: string;
  dayStreakCount: 1 | 2 | 3;
  weekStreakCount: number;
  handleComplete?: () => void;
}

export interface IAnimationData {
  data: Record<string, unknown>;
  loopSegment: AnimationSegment[];
  completeFrame: number;
  soundEffect?: StudentSessionSoundEffect;
}

const animationDataMap: Record<number, IAnimationData> = {
  [1]: {
    data: progress1Data,
    loopSegment: [
      [0, 241],
      [125, 241],
    ],
    completeFrame: 125,
    soundEffect: StudentSessionSoundEffect.WeekStreak1,
  },
  [2]: {
    data: progress2Data,
    loopSegment: [
      [0, 241],
      [130, 241],
    ],
    completeFrame: 130,
    soundEffect: StudentSessionSoundEffect.WeekStreak2,
  },
  [3]: {
    data: progress3Data,
    loopSegment: [
      [0, 300],
      [198, 300],
    ],
    completeFrame: 190,
    soundEffect: StudentSessionSoundEffect.WeekStreak3,
  },
};

const AnimatedBox = animated(Box);

export const PodiumStreak: React.FC<IPodiumStreakProps> = ({
  studentName,
  dayStreakCount,
  weekStreakCount,
  handleComplete,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const animationRef = useRef<AnimationItem | null>(null);
  const [coreAnimFinished, setCoreAnimFinished] = useState<boolean>(false);
  const { match: currentBreakpoints } = useBreakpoints();
  const { play: playAudio } = useSessionAudio();
  const { track } = useSessionAnalytics();
  const { t } = useTranslation("session", {
    useSuspense: false,
    keyPrefix: "podium.streak",
  });

  useMount(() => {
    track(AnalyticsEvent.Session_Podium_ViewStreak, { dayStreakCount });
  });

  const weekStreakSpring = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: coreAnimFinished ? 1 : 0,
    },
    config: config.molasses,
  });

  const handleLoad = useCallback(
    (animationData: IAnimationData) => {
      if (!containerRef.current) {
        return;
      }

      animationRef.current?.stop();
      animationRef.current?.destroy();
      setCoreAnimFinished(false);
      const animationDataString = JSON.stringify(animationData.data);

      const anim = (animationRef.current = lottie.loadAnimation({
        container: containerRef.current,
        renderer: "svg",
        loop: true,
        autoplay: false,
        animationData: JSON.parse(animationDataString),
      }));

      anim.addEventListener("DOMLoaded", () => {
        anim.playSegments(animationData.loopSegment, true);
        if (animationData.soundEffect) {
          playAudio(animationData.soundEffect);
        }
      });

      anim.addEventListener("loopComplete", () => {
        setCoreAnimFinished(true);
      });

      let hasFinishedCore = false;
      anim.addEventListener("enterFrame", ({ currentTime }) => {
        if (!hasFinishedCore && currentTime > animationData.completeFrame) {
          setCoreAnimFinished(true);
          hasFinishedCore = true;
          handleComplete?.();
        }
      });
    },
    [playAudio, handleComplete]
  );

  useUnmount(() => {
    animationRef.current?.stop();
    animationRef.current?.destroy();
    lottie.stop();
    lottie.destroy();
  });

  const prevDayStreakCount = usePrevious(dayStreakCount);
  useEffect(() => {
    if (dayStreakCount !== prevDayStreakCount) {
      handleLoad(animationDataMap[dayStreakCount]);
    }
  }, [dayStreakCount, prevDayStreakCount, handleLoad]);

  return (
    <Box
      w="full"
      h="full"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      position="relative"
    >
      <Box position="relative" w="full" maxH="full" maxW="full">
        <Box ref={containerRef} w="full" h="full" />
        {dayStreakCount === 3 && (
          <AnimatedBox
            style={weekStreakSpring}
            position="absolute"
            top="50%"
            left={0}
            right={0}
          >
            <Trans t={t} i18nKey="weekStreakDesc" count={weekStreakCount}>
              <Text
                textStyle="gameDisplayInline"
                fontSize={pxToRem(currentBreakpoints.fontSize * 1.2)}
                textAlign="center"
                fontWeight="700"
              >
                Wow,
              </Text>
              <Text
                textStyle="gameDisplayInline"
                fontSize={pxToRem(currentBreakpoints.fontSize * 1.2)}
                textAlign="center"
                fontWeight="700"
              >
                {{ studentName: studentName.split(" ")[0] }}!
              </Text>
              <Text
                textStyle="gameDisplayInline"
                fontSize={pxToRem(currentBreakpoints.fontSize)}
                textAlign="center"
                marginTop={pxToRem(currentBreakpoints.margin)}
              >
                You&apos;ve completed
              </Text>
              <Text
                fontSize={pxToRem(currentBreakpoints.fontSize)}
                fontWeight="700"
                my={pxToRem(currentBreakpoints.margin / 2)}
                color="#fa553a"
                textAlign="center"
                textStyle="gameDisplayInline"
              >
                {{ count: weekStreakCount }} weeks
              </Text>
              <Text
                textStyle="gameDisplayInline"
                fontSize={pxToRem(currentBreakpoints.fontSize)}
                textAlign="center"
              >
                of practice in a row!
              </Text>
            </Trans>
          </AnimatedBox>
        )}
      </Box>
    </Box>
  );
};
