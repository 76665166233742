import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useLocalStorage, usePrevious } from "react-use";

import { OnboardingStep } from "adminComponents/screens/TeacherHomepageLibrary";
import {
  localStoreHasCompletedOnboardingKeyName,
  localStoreHideOnboardingUntilKeyName,
} from "links/lib/constants";
import { useFetchTeacherOnboardingChecklist } from "links/lib/features/users";
import { IUser } from "links/lib/types";

interface IArgs {
  authUser?: IUser;
  onboardingStep?: OnboardingStep;
}

interface IResult {
  shouldHideOnboardingBanner: boolean;
  showCompletedOnboardingBanner: boolean;
  handleCloseOnboardingBanner: () => void;
  handleCloseCompletedOnboardingBanner: () => void;
}

export const useShowOnboardingBanner = ({
  authUser,
  onboardingStep,
}: IArgs): IResult => {
  const [hasCompletedOnboarding, setHasCompletedOnboarding] = useState(true);
  const [showCompletedOnboardingBanner, setShowCompletedOnboardingBanner] =
    useState(false);
  const [
    localStoreHasCompletedOnboarding,
    setLocalStoreHasCompletedOnboarding,
  ] = useLocalStorage(localStoreHasCompletedOnboardingKeyName, false);
  const [localStoreHideOnboardingUntil, setLocalStoreHideOnboardingUntil] =
    useLocalStorage<string | undefined>(
      localStoreHideOnboardingUntilKeyName,
      undefined
    );
  const hideOnboardingUntil = localStoreHideOnboardingUntil
    ? moment(localStoreHideOnboardingUntil)
    : undefined;

  const fetchOnboardingChecklist = useFetchTeacherOnboardingChecklist({
    userId: authUser?.id,
    disabled:
      !authUser?.id || authUser?.is_guest || localStoreHasCompletedOnboarding,
  });
  const teacherOnboardingChecklist = fetchOnboardingChecklist.data;
  const prevTeacherOnboardingChecklist = usePrevious(
    teacherOnboardingChecklist
  );

  // The only step that's "required" is the complete profile step -- if
  // that banner is closed we hide it for 7 days. Otherwise, dismiss these
  // banners permanently.
  const handleCloseOnboardingBanner = () => {
    if (onboardingStep === OnboardingStep.CompleteProfile) {
      setLocalStoreHideOnboardingUntil(moment().add(7, "days").toString());
    } else {
      setLocalStoreHasCompletedOnboarding(true);
    }
  };
  const handleCloseCompletedOnboardingBanner = () => {
    setShowCompletedOnboardingBanner(false);
    setLocalStoreHasCompletedOnboarding(true);
  };
  const shouldHideOnboardingBanner = useMemo(() => {
    return (
      hasCompletedOnboarding ||
      localStoreHasCompletedOnboarding ||
      (hideOnboardingUntil && moment().isBefore(hideOnboardingUntil))
    );
  }, [
    hasCompletedOnboarding,
    hideOnboardingUntil,
    localStoreHasCompletedOnboarding,
  ]);

  useEffect(() => {
    if (!teacherOnboardingChecklist) return;

    // Only three of the originally defined onboarding steps are now considered
    // required for completion.
    const hasCompletedAllOnboardingItems =
      teacherOnboardingChecklist.has_completed_profile &&
      teacherOnboardingChecklist.has_edited_avatar &&
      teacherOnboardingChecklist.has_invited_students;

    // First load, check if onboarding is already complete and hide checklist if so
    if (!!teacherOnboardingChecklist && !prevTeacherOnboardingChecklist) {
      if (hasCompletedAllOnboardingItems) {
        setLocalStoreHasCompletedOnboarding(true);
      } else {
        setHasCompletedOnboarding(false);
      }
    }

    if (!prevTeacherOnboardingChecklist) return;

    if (
      (!prevTeacherOnboardingChecklist.has_completed_profile ||
        !prevTeacherOnboardingChecklist.has_edited_avatar ||
        !prevTeacherOnboardingChecklist.has_invited_students) &&
      hasCompletedAllOnboardingItems
    ) {
      setShowCompletedOnboardingBanner(true);
    }
  }, [
    prevTeacherOnboardingChecklist,
    setLocalStoreHasCompletedOnboarding,
    teacherOnboardingChecklist,
  ]);

  return {
    shouldHideOnboardingBanner: !!shouldHideOnboardingBanner,
    showCompletedOnboardingBanner,
    handleCloseOnboardingBanner,
    handleCloseCompletedOnboardingBanner,
  };
};
