import { Box, Flex, useMultiStyleConfig } from "@chakra-ui/react";
import React from "react";

export type ColorButtonSize = "small" | "medium";
interface IColorButtonProps {
  color: string;
  size?: ColorButtonSize;
  isSelected?: boolean;
  handleClick?: (color: string) => void;
  ariaLabel?: string;
}

const ColorButton: React.FC<IColorButtonProps> = ({
  color,
  size = "small",
  isSelected = false,
  handleClick,
  ariaLabel,
}) => {
  const styles = useMultiStyleConfig("AdminColorButton", {
    isSelected,
    color,
    size,
  });

  return (
    <Flex
      sx={styles.container}
      as="button"
      onClick={() => handleClick?.(color)}
      aria-label={ariaLabel}
    >
      <Box sx={styles.color} />
    </Flex>
  );
};

export default ColorButton;
