import { IGradeLevel } from "links/lib/types";

export const sortGradeLevelsTwoColumns = (
  gradeLevels: Array<IGradeLevel>
): Array<IGradeLevel> => {
  const totalCount = gradeLevels.length;
  const column1 = gradeLevels.slice(0, Math.ceil(totalCount / 2));
  const column2 = gradeLevels.slice(column1.length, totalCount);
  const options = [];
  for (let i = 0; i < column1.length; i++) {
    if (column1[i]) options.push(column1[i]);
    if (column2[i]) options.push(column2[i]);
  }
  return options;
};
