import {
  Box,
  Flex,
  Image,
  Link,
  SimpleGrid,
  Square,
  useBreakpointValue,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Heading } from "adminComponents/atoms/Heading";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils";

import BACKGROUND from "./resource/background.svg";
import IMAGE from "./resource/image.svg";

export const DistrictAdminFooter: React.FC = () => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const isLargeBreakPoint = useBreakpointValue({ base: false, lg: true });

  return (
    <Box
      w="100%"
      h={pxToRem(350)}
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="relative"
      marginTop={pxToRem(20)}
    >
      <Box
        w="100%"
        backgroundImage={BACKGROUND}
        backgroundRepeat="no-repeat"
        backgroundPosition="center"
        backgroundSize="cover"
        h={pxToRem(310)}
        borderRadius="lg"
        padding={pxToRem(30)}
      >
        <SimpleGrid columns={isMobile ? 1 : 2} h="100%">
          <Flex h="100%" flexDir="column" justifyContent="center" zIndex={2}>
            <Box>
              <Heading variant="adminH5Bold" as="h3">
                {t("districtAdminHomepage.footer.title")}
              </Heading>
            </Box>
            <Box>
              <Text variant="adminP1">
                {t("districtAdminHomepage.footer.paragraph")}
              </Text>
            </Box>
            <Flex w="100%" justifyContent="left" paddingTop={pxToRem(30)}>
              <Link
                href="https://www.peardeck.com/blog/best-practices-with-district-reporting-with-pear-practice"
                target="_blank"
              >
                <Button
                  color="white"
                  variant="adminButtonWhite"
                  w="fit-content"
                >
                  {t("districtAdminHomepage.footer.cta")}
                </Button>
              </Link>
            </Flex>
          </Flex>
        </SimpleGrid>
      </Box>
      {isLargeBreakPoint && (
        <Square position="absolute" top="0%" right="5%">
          <Image src={IMAGE} />
        </Square>
      )}
    </Box>
  );
};
