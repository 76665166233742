import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";

import { PracticeSetsTab } from "adminComponents/screens/ClassroomDetailScreen/components/PracticeSetsTab";
import { useErrorToast } from "adminComponents/utils/toast";
import { usePageTrack } from "lib/contexts/analytics";
import {
  useFetchAveragePracticeSetAccuracies,
  useFetchClassroomPracticeSets,
  useFetchClassroomUsers,
} from "links/lib/features/classrooms";
import { useFetchPracticeSets } from "links/lib/features/practiceSets";
import { AnalyticsPage, IClassroom, UsersGroupsRole } from "links/lib/types";

export interface IClassAccuracyTabProps {
  classroom: IClassroom;
  classroomAccuracy?: number;
}

// Formerly known as "PracticeSetsTab", this component renders a top level
// Class Accuracy metric and a list of practice sets this classroom has used
export const ClassAccuracyTab: React.FC<IClassAccuracyTabProps> = ({
  classroom,
  classroomAccuracy,
}) => {
  const history = useHistory();

  usePageTrack(AnalyticsPage.TeacherDashboard_ClassroomDetail_PracticeSets);

  const fetchTeacherUsers = useFetchClassroomUsers({
    classroom_id: classroom.id,
    group_roles: [UsersGroupsRole.Owner, UsersGroupsRole.Assistant],
  });
  useErrorToast(fetchTeacherUsers.error);

  const teacherIds = useMemo(() => {
    return fetchTeacherUsers.data?.users.map((u) => u.id) || [];
  }, [fetchTeacherUsers.data]);

  const classroomPracticeSetsFetch = useFetchClassroomPracticeSets({
    classroom_id: classroom.id,
  });

  const averagePracticeSetAccuraciesFetch =
    useFetchAveragePracticeSetAccuracies({
      classroom_id: classroom.id,
    });

  const practiceSetIds = useMemo(() => {
    return (
      classroomPracticeSetsFetch.data?.classroom_practice_sets.map(
        (p) => p.practice_set_id
      ) || []
    );
  }, [classroomPracticeSetsFetch.data]);

  const practiceSetsFetch = useFetchPracticeSets({
    ids: practiceSetIds,
    classroom_id: classroom.id,
    created_by_user_ids: teacherIds,
    include_all: true,
    enabled: !!practiceSetIds.length && !!teacherIds.length,
    include_most_recent_live_practice_timestamp: true,
    include_most_recent_assignment_timestamp: true,
  });

  useErrorToast(classroomPracticeSetsFetch.error);
  useErrorToast(averagePracticeSetAccuraciesFetch.error);

  const handleViewMore = () => {
    // No-op - currently paging is not implemented
    return;
  };

  const handleMyLibrary = () => {
    history.push("/t/my-library");
  };

  const handleSharedLibraries = () => {
    history.push("/t/public-library");
  };

  const practiceSetData = useMemo(() => {
    const accuracyMap: { [practiceSetId: string]: number } = {};

    averagePracticeSetAccuraciesFetch.data?.average_practice_set_accuracies.forEach(
      (a) => {
        accuracyMap[a.practice_set_id] = a.average_accuracy;
      }
    );

    return (
      practiceSetsFetch.data?.practice_sets.map((practiceSet) => {
        const accuracy = accuracyMap[practiceSet.id];

        return {
          practiceSet,
          accuracy: isNaN(accuracy) ? undefined : Math.round(accuracy * 100),
        };
      }) || []
    );
  }, [averagePracticeSetAccuraciesFetch.data, practiceSetsFetch.data]);

  return (
    <PracticeSetsTab
      handleMyLibrary={handleMyLibrary}
      handleSharedLibraries={handleSharedLibraries}
      handleViewMorePracticeSets={handleViewMore}
      classroom={classroom}
      classroomAccuracy={classroomAccuracy}
      practiceSetData={practiceSetData}
      isLoading={
        practiceSetsFetch.isLoading ||
        averagePracticeSetAccuraciesFetch.isLoading ||
        classroomPracticeSetsFetch.isLoading
      }
      showViewMorePracticeSets={false}
    />
  );
};
