import { pxToRem } from "adminComponents/utils/pxToRem";

export const AdminSelectStudentModal = {
  baseStyle: {
    container: {
      flexDirection: "column",
    },
    classroomTitle: {
      color: "primary.dark-gray",
    },

    classroomName: {
      marginLeft: pxToRem(12),
    },

    header: {
      flexDirection: ["column", null, "row"],
      marginTop: pxToRem(24),
      marginBottom: pxToRem(24),
      justifyContent: "space-between",
    },

    selectHeaderText: {
      alignItems: "end",
      marginBottom: [pxToRem(16), null, 0],
    },

    selectHeaderLinks: {
      alignItems: "end",
      gap: pxToRem(16),
    },

    selectedStudents: {
      marginLeft: pxToRem(12),
    },

    checkboxContainer: {
      backgroundColor: "primary.white",
      borderRadius: pxToRem(20),
      borderWidth: pxToRem(3),
      borderColor: "primary.tan",
      paddingX: pxToRem(16),
      paddingRight: pxToRem(8),
      overflowY: "auto",
      overflowX: "hidden",
      flexDirection: "column",
      paddingY: pxToRem(16),
      maxH: [pxToRem(456), null, pxToRem(360)],
      gap: pxToRem(12),
    },

    footer: {
      flexDirection: ["column-reverse", null, "row"],
      alignItems: ["flex-start", null, "center"],
      justifyContent: ["flex-start", null, "space-between"],
      marginTop: [pxToRem(24), null, pxToRem(40)],
    },

    footerButtons: {
      gap: pxToRem(12),
      width: ["full", null, "auto"],
    },

    footerTextContainer: {
      maxWidth: [null, null, pxToRem(180)],
      marginBottom: [pxToRem(16), null, 0],
      alignSelf: ["center", null, "flex-end"],
    },

    footerText: {
      marginLeft: [0, null, pxToRem(12)],
      textAlign: [null, null, "right"],
    },

    unselectableTextContainer: {
      marginBottom: pxToRem(20),
    },
  },
};
