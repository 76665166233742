import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { useAxios } from "../../hooks/useAxios";

export interface IUserUnfollowUserProps {
  onSuccess?: () => void;
  onError?: () => void;
  onSettled?: () => void;
}

export interface IUserUnfollowUserParams {
  userId: string;
  authUserId: string;
}

export default function useUnfollowUser({
  onSuccess,
  onError,
  onSettled,
}: IUserUnfollowUserProps): UseMutationResult<
  unknown,
  unknown,
  IUserUnfollowUserParams
> {
  const axios = useAxios();
  const queryClient = useQueryClient();

  const unfollowUser = async ({
    userId,
    authUserId,
  }: IUserUnfollowUserParams) => {
    await axios.post(`/v1/users/${userId}/unfollow`);

    queryClient.invalidateQueries(["user-public-profiles"]);
    queryClient.invalidateQueries([
      "fetchUserFollowersInfinite",
      { userId: authUserId },
    ]);
    queryClient.invalidateQueries([
      "fetchUserFollowingInfinite",
      { userId: authUserId },
    ]);
    queryClient.invalidateQueries(["mostCopiedCreators"]);
  };

  const mutation = useMutation(unfollowUser, {
    onError,
    onSuccess,
    onSettled,
  });

  return mutation;
}
