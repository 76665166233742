import { Box, HStack, Text, usePrefersReducedMotion } from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import { animated, config, useSpring, useTransition } from "react-spring";
import { useTimeout } from "react-use";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { ISessionGroup, ISessionUser } from "links/lib/types";
import { Card } from "sessionComponents/atoms/Card";
import { GroupToken } from "sessionComponents/atoms/GroupToken";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { useDetectOrientation } from "sessionComponents/theme/hooks/useDetectOrientation";
import { getAssetColorScheme } from "sessionComponents/utils/getAssetColorScheme";
import { getFormattedGroupName } from "sessionComponents/utils/getFormattedGroupName";
import { useSafeConnectedUserArray } from "sharedComponents/hooks/useSafeConnectedUserArray";
import { useSafeGroupsArray } from "sharedComponents/hooks/useSafeGroupsArray";

import FlameGif from "../../molecules/QuickPlayStreakIcon/resource/flame.gif";
import { default as FlameIcon } from "./resource/flame_icon.svg";

const AnimatedBox = animated(Box);
const AnimatedText = animated(Text);

interface IQuickPlayGroupScoresProps {
  onAnimationEnd?: () => void;
}

// QuickPlayGroupScores is very similar to the GroupScores component,
// but performs quicker loading of the scores as well as handling a
// reordering based on bonus points earned by teams.
export const QuickPlayGroupScores: React.FC<IQuickPlayGroupScoresProps> = ({
  onAnimationEnd,
}) => {
  const { match: currentBreakpoints } = useBreakpoints();
  const { isPortrait } = useDetectOrientation();
  const prefersReducedMotion = usePrefersReducedMotion();

  const groups = useSafeGroupsArray();
  const connectedUsers = useSafeConnectedUserArray();

  const preBonusSortedGroups = useMemo(() => {
    return groups.sort(
      (a, b) => b.total_awarded_points - a.total_awarded_points
    );
  }, [groups]);

  const [sortedGroups, setSortedGroups] = useState(preBonusSortedGroups);

  const bonusPointsToAward = preBonusSortedGroups.some(
    (g) => g.total_bonus_points_between_leader_boards > 0
  );

  const preBonusGroupsTransition = useSpring({
    from: { opacity: 0, scale: prefersReducedMotion ? 1 : 0 },
    to: { opacity: 1, scale: 1 },
    config: config.default,
    onRest: () => {
      if (!bonusPointsToAward) {
        onAnimationEnd?.();
      }
    },
  });

  const onBonusPointAdditionComplete = () => {
    setSortedGroups(
      groups.sort(
        (a, b) =>
          b.total_awarded_points +
          b.total_bonus_points_between_leader_boards -
          (a.total_awarded_points + a.total_bonus_points_between_leader_boards)
      )
    );
    onAnimationEnd?.();
  };

  const postBonusGroupsTransition = useTransition(
    sortedGroups.map((group, i) => ({
      ...group,
      y: currentBreakpoints.buttonHeight * i,
    })),
    {
      key: (group: ISessionGroup) => group.id,
      from: { height: 0, opacity: 0 },
      leave: { height: 0, opacity: 0 },
      enter: ({ y }) => ({
        y,
        opacity: 1,
      }),
      update: ({ y }) => ({ y }),
      config: {
        duration: 1000,
        config: config.molasses,
      },
    }
  );

  const landscapeStyleProps = {
    borderRadius: "0px",
    borderBottomWidth: 0,
    borderTopWidth: 0,
    margin: 0,
    height: "full",
    useThickBorder: false,
  };

  const portraitStyleProps = {
    height: "63%",
    useThickBorder: true,
    margin: pxToRem(currentBreakpoints.margin),
  };

  const styleProps = isPortrait ? portraitStyleProps : landscapeStyleProps;

  return (
    <Card
      bgColor="white"
      width="auto"
      overflowY="auto"
      zIndex="2"
      {...styleProps}
    >
      <Box
        w="full"
        flexGrow={1}
        display="flex"
        flexDir="column"
        gap={pxToRem(currentBreakpoints.margin)}
      >
        <AnimatedBox
          style={preBonusGroupsTransition}
          w="full"
          h="full"
          flexGrow="1"
          display="flex"
          flexDir="column"
          gap={pxToRem(currentBreakpoints.margin)}
        >
          {postBonusGroupsTransition((style, group) => (
            <AnimatedBox style={{ ...style }}>
              <Box
                w="full"
                flexDir="row"
                display="flex"
                gap={pxToRem(currentBreakpoints.margin / 2)}
                justifyContent="center"
                alignItems="center"
                key={group.id}
              >
                <GroupListItem
                  connectedUsers={connectedUsers}
                  group={group}
                  onAnimationsComplete={onBonusPointAdditionComplete}
                />
              </Box>
            </AnimatedBox>
          ))}
        </AnimatedBox>
      </Box>
    </Card>
  );
};

const GroupListItem = ({
  connectedUsers,
  group,
  onAnimationsComplete,
}: {
  connectedUsers: ISessionUser[];
  group: ISessionGroup;
  onAnimationsComplete: () => void;
}) => {
  const { match: currentBreakpoints } = useBreakpoints();
  const getGroupMembers = (groupId: string) =>
    connectedUsers.filter((user) => user.session_group_id === groupId);
  const groupName = getFormattedGroupName(group);
  const totalAwardedPoints = group.total_awarded_points;
  const colorScheme = group.color_scheme;
  const groupMembers = getGroupMembers(group.id);
  const iconUrl = group.icon_url;

  const groupDidNotEarnBonusPoints =
    group.total_bonus_points_between_leader_boards === 0;

  const flameSpring = useSpring({
    from: {
      grayscale: 100,
      bgImage: "grayscale",
    },
    to: {
      grayscale: groupDidNotEarnBonusPoints ? 100 : 0,
      bgImage: "lit",
    },
    delay: 2000,
    config: config.slow,
  });

  const addBonusPointsSpring = useSpring({
    from: {
      points: totalAwardedPoints,
    },
    to: {
      points:
        totalAwardedPoints + group.total_bonus_points_between_leader_boards,
    },
    delay: 2250,
    config: config.slow,
    onRest: onAnimationsComplete,
  });

  const [showFlameGif] = useTimeout(2000);

  return (
    <>
      <GroupToken
        tooltipPlacement="top"
        name={groupName}
        colorScheme={getAssetColorScheme(colorScheme)}
        imageUrl={iconUrl}
        groupMembers={groupMembers}
      />
      <Text
        fontSize={pxToRem(currentBreakpoints.fontSize)}
        textStyle="gameTextWeighted"
        flexGrow={1}
      >
        {groupName}
      </Text>
      <Box textAlign="center">
        <HStack>
          <AnimatedText
            fontSize={pxToRem(currentBreakpoints.fontSize)}
            textStyle="gameDisplayInline"
          >
            {addBonusPointsSpring.points.to((val) => Math.floor(val))}
          </AnimatedText>
          <AnimatedBox
            w={pxToRem(currentBreakpoints.padding * 2)}
            h={pxToRem(currentBreakpoints.padding * 2)}
            bgImage={
              groupDidNotEarnBonusPoints || !showFlameGif()
                ? FlameIcon
                : FlameGif
            }
            bgPosition="center top"
            bgSize="contain"
            bgRepeat="no-repeat"
            style={{
              filter: flameSpring.grayscale.to((v) => `grayscale(${v}%)`),
            }}
          />
        </HStack>
      </Box>
    </>
  );
};
