import { FormControl, SimpleGrid } from "@chakra-ui/react";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { Dropdown, IOption } from "adminComponents/atoms/Dropdown";
import { FormErrorMessage } from "adminComponents/atoms/FormErrorMessage";
import { FormLabel } from "adminComponents/atoms/FormLabel";
import {
  ConfirmModal,
  IConfirmModalProps,
} from "adminComponents/molecules/ConfirmModal";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { useAuth } from "links/lib/features/auth";
import {
  IPracticeSet,
  IPracticeSetItem,
  PracticeSetAvailability,
  UserRole,
} from "links/lib/types";

interface IForm {
  selectedPracticeSet?: IPracticeSet;
}

interface IProps {
  practiceSets: Array<IPracticeSet>;
  practiceSetItem?: IPracticeSetItem;
  handleClose: () => void;
  handleSave: (practiceSet: IPracticeSet, item: IPracticeSetItem) => void;
  isLoading?: IConfirmModalProps["isLoading"];
  isOpen: IConfirmModalProps["isOpen"];
}

export const QuestionAddToSetModal: React.FC<IProps> = ({
  practiceSets,
  practiceSetItem,
  handleClose,
  handleSave,
  ...props
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "questionAddToSetModal",
  });

  const { authUser } = useAuth();
  const isContentSpecialist = authUser?.role === UserRole.ContentSpecialist;

  const [showPrivateSetErrorModal, setShowPrivateSetErrorModal] =
    useState(false);

  const formik = useFormik<IForm>({
    enableReinitialize: true,
    initialValues: {
      selectedPracticeSet: undefined,
    },
    onSubmit: (values: IForm) => {
      if (!values.selectedPracticeSet || !practiceSetItem) return;
      if (
        !isContentSpecialist &&
        practiceSetItem.is_certified &&
        values.selectedPracticeSet.availability !==
          PracticeSetAvailability.Private
      ) {
        setShowPrivateSetErrorModal(true);
        return;
      }

      handleSave(values.selectedPracticeSet, practiceSetItem);
    },
    validateOnMount: true,
    validationSchema: Yup.object({
      selectedPracticeSet: Yup.object().required(t("nameErrorMessage")),
    }),
  });

  const handleConfirmModalClose = (confirm?: boolean) => {
    if (confirm) {
      formik.submitForm().then(formik.resetForm);
    } else {
      handleClose();
      formik.resetForm();
    }
  };

  const selectOptions: IOption[] = practiceSets.map((ps) => ({
    value: ps.id,
    label: ps.title,
  }));

  const selectedOption: IOption | undefined = selectOptions.find(
    ({ value }) => value === formik.values.selectedPracticeSet?.id
  );

  const handleSelectOptionChange = async (v: unknown) => {
    const selectedValue = (v as IOption).value.toString();

    const practiceSet = practiceSets.find((ps) => ps.id === selectedValue);

    if (!practiceSet) return;

    await formik.setFieldValue("selectedPracticeSet", practiceSet);
    await formik.validateForm();
  };

  return (
    <>
      <ConfirmModal
        {...props}
        buttonCancelLabel={t("cancelButton")}
        buttonOKLabel={t("confirmButton")}
        handleClose={handleConfirmModalClose}
        isDisabled={!formik.isValid || !formik.dirty}
        size="2xl"
        title={t("title")}
        variant="adminModalCompactMobile"
      >
        <form>
          <SimpleGrid gap={[pxToRem(24), null, pxToRem(32)]}>
            <FormControl
              isDisabled={props.isLoading}
              isRequired
              isInvalid={
                !!formik.errors.selectedPracticeSet &&
                !!formik.touched.selectedPracticeSet
              }
              variant="adminFormControl"
            >
              <FormLabel
                variant="adminFormLabelDarkGray"
                htmlFor="selectFolder"
              >
                {t("nameLabel")}
              </FormLabel>
              <Dropdown
                id="selectFolder"
                handleChange={handleSelectOptionChange}
                options={selectOptions}
                placeholder={t("namePlaceholder")}
                value={selectedOption ? [selectedOption] : []}
              />
              <FormErrorMessage>
                {formik.touched.selectedPracticeSet &&
                  formik.errors.selectedPracticeSet}
              </FormErrorMessage>
            </FormControl>
          </SimpleGrid>
        </form>
      </ConfirmModal>
      <ConfirmModal
        isOpen={showPrivateSetErrorModal}
        handleClose={() => setShowPrivateSetErrorModal(false)}
        buttonCancelLabel={t("cancelButton")}
        buttonOKLabel={t("publicSetModal.confirmButton")}
        title={t("publicSetModal.title")}
        description={t("publicSetModal.description")}
      />
    </>
  );
};
