import { Box, VStack } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Dropdown, IOption } from "adminComponents/atoms/Dropdown";
import { Modal } from "adminComponents/atoms/Modal";
import { Text } from "adminComponents/atoms/Text";
import { getTimezonesForCountry } from "adminComponents/organisms/TeacherAccountInformationForm/components";
import { pxToRem } from "adminComponents/utils";
import i18n from "lib/i18n";
import { getRegionsForCountry, sortedCountries } from "links/lib/geo";
import { langLabelMap, supportedLangs } from "links/lib/lang";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

export const LanguageAndTimezoneModal: React.FC<IProps> = ({
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation("admin", {
    keyPrefix: "teacherOnboardContainer.languageAndTimezoneModal",
    useSuspense: false,
  });

  const { control, watch } = useFormContext();

  const language = watch("language");
  const state = watch("state");
  const country = watch("country");
  const timezone = watch("timezone");

  const [stateOptions, timezoneOptions] = useMemo(() => {
    return [
      getRegionsForCountry(country).map((region) => ({
        value: region.code,
        label: region.name,
      })),
      getTimezonesForCountry(country).map((tz) => ({
        value: tz,
        label: tz,
      })),
    ];
  }, [country]);

  return (
    <Modal
      showBackButton={false}
      isOpen={isOpen}
      onClose={onClose}
      title={t("title")}
      size="2xl"
    >
      <VStack alignItems="center" gap={pxToRem(16)} textAlign="left">
        <VStack alignItems="start" w="full" gap={pxToRem(2)}>
          <Text variant="adminP1">{t("language")}</Text>
          <Text variant="adminP1" color="primary.dark-gray">
            {t("languageDescription")}
          </Text>
          <Box w="full">
            <Controller
              name="language"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, name } }) => (
                <Dropdown
                  options={supportedLangs.map((language) => ({
                    label: langLabelMap[language],
                    value: language,
                  }))}
                  placeholder={t("languagePlaceholder")}
                  value={{
                    label: langLabelMap[language],
                    value: language,
                  }}
                  handleBlur={onBlur}
                  handleChange={(option: unknown) => {
                    const opt = option as IOption;
                    i18n.changeLanguage(opt.value);
                    onChange(opt.value);
                  }}
                  id={name}
                />
              )}
            />
          </Box>
        </VStack>
        <VStack alignItems="start" w="full" gap={pxToRem(2)}>
          <Text variant="adminP1">{t("country")}</Text>
          <Text variant="adminP1" color="primary.dark-gray">
            {t("countryDescription")}
          </Text>
          <Box w="full">
            <Controller
              name="country"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value, name } }) => (
                <Dropdown
                  options={sortedCountries.map((country) => ({
                    label: country.name,
                    value: country.code,
                  }))}
                  placeholder={t("countryPlaceholder")}
                  value={{
                    label:
                      sortedCountries.find((c) => c.code === value)?.name || "",
                    value: country,
                  }}
                  handleBlur={onBlur}
                  handleChange={(option: unknown) => {
                    onChange((option as IOption).value);
                  }}
                  id={name}
                />
              )}
            />
          </Box>
        </VStack>
        <VStack alignItems="start" w="full" gap={pxToRem(2)}>
          <Text variant="adminP1">{t("state")}</Text>
          <Text variant="adminP1" color="primary.dark-gray">
            {t("stateDescription")}
          </Text>
          <Box w="full">
            <Controller
              name="state"
              control={control}
              render={({ field: { onChange, onBlur, value, name } }) => (
                <Dropdown
                  options={stateOptions}
                  placeholder={t("statePlaceholder")}
                  value={{
                    label:
                      stateOptions.find((s) => s.value === value)?.label || "",
                    value: state,
                  }}
                  handleBlur={onBlur}
                  handleChange={(option: unknown) => {
                    const opt = option as IOption;
                    onChange(opt.value);
                  }}
                  id={name}
                />
              )}
            />
          </Box>
        </VStack>
        <VStack alignItems="start" w="full" gap={pxToRem(2)}>
          <Text variant="adminP1">{t("timezone")}</Text>
          <Text variant="adminP1" color="primary.dark-gray">
            {t("timezoneDescription")}
          </Text>
          <Box w="full">
            <Controller
              name="timezone"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value, name } }) => (
                <Dropdown
                  options={timezoneOptions}
                  placeholder={t("timezonePlaceholder")}
                  value={{
                    label:
                      timezoneOptions.find((t) => t.value === value)?.label ||
                      "",
                    value: timezone,
                  }}
                  handleBlur={onBlur}
                  handleChange={(option: unknown) => {
                    const opt = option as IOption;
                    onChange(opt.value);
                  }}
                  id={name}
                />
              )}
            />
          </Box>
        </VStack>
      </VStack>
    </Modal>
  );
};
