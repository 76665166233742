import { useMemo } from "react";

import { CameraType } from "links/lib/types";

export interface IArgs {
  isSeated: boolean;
  editSectionId?: string;
}

export class CameraTypes {
  static readonly StandingDefault: CameraType = { x: 0, y: 0, z: 0, zoom: 1 };
  static readonly SeatedDefault: CameraType = { x: 10, y: 0, z: 0, zoom: 0.75 };
  static readonly StandingFace: CameraType = { x: 0, y: 130, z: 0, zoom: 0.4 };
  static readonly SeatedFace: CameraType = { x: -30, y: 110, z: 0, zoom: 0.4 };
  static readonly StandingShoes: CameraType = {
    x: 0,
    y: -130,
    z: 0,
    zoom: 0.2,
  };
  static readonly SeatedShoes: CameraType = {
    x: 100,
    y: -60,
    z: 0,
    zoom: 0.2,
  };
  static readonly StandingBack: CameraType = { x: 0, y: 40, z: 0, zoom: 0.4 };
  static readonly SeatedBack: CameraType = { x: -60, y: 80, z: 0, zoom: 0.4 };
  static readonly StandingTops: CameraType = { x: 0, y: 40, z: 0, zoom: 0.4 };
  static readonly StandingBottoms: CameraType = {
    x: 0,
    y: -70,
    z: 0,
    zoom: 0.4,
  };
  static readonly SeatedBottoms: CameraType = {
    x: 70,
    y: -30,
    z: 0,
    zoom: 0.4,
  };
  static readonly StandingHands: CameraType = { x: 0, y: -70, z: 0, zoom: 0.4 };
  static readonly SeatedHands: CameraType = { x: 55, y: 0, z: 0, zoom: 0.4 };
}

/**
 * Generates a stage camera for use with locker avatar screens and modals
 * @returns
 */
export const useLockerAvatarCamera = ({
  editSectionId,
  isSeated,
}: IArgs): CameraType => {
  return useMemo(() => {
    let camera = isSeated
      ? CameraTypes.SeatedDefault
      : CameraTypes.StandingDefault;
    switch (editSectionId) {
      case "ear":
      case "earwear":
      case "eyes":
      case "eyewear":
      case "facial_hair":
      case "hair":
      case "head":
      case "headware":
      case "mouth":
      case "neckwear":
      case "nose":
      case "skin":
        camera = isSeated ? CameraTypes.SeatedFace : CameraTypes.StandingFace;
        break;
      case "footwear":
        camera = isSeated ? CameraTypes.SeatedShoes : CameraTypes.StandingShoes;
        break;
      case "back":
        camera = isSeated ? CameraTypes.SeatedBack : CameraTypes.StandingBack;
        break;
      case "tops":
        camera = CameraTypes.StandingTops;
        break;
      case "bottoms":
        camera = isSeated
          ? CameraTypes.SeatedBottoms
          : CameraTypes.StandingBottoms;
        break;
      case "hands":
        camera = isSeated ? CameraTypes.SeatedHands : CameraTypes.StandingHands;
        break;
    }
    return camera;
  }, [editSectionId, isSeated]);
};
