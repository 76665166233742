import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { RejectStudentModal } from "adminComponents/molecules/RejectStudentModal";
import {
  AddStudentDirectionsModal,
  DescriptionCase,
} from "adminComponents/organisms/AddStudentDirectionsModal";
import { AddStudentsViaLinkModal } from "adminComponents/organisms/AddStudentsViaLinkModal";
import { useErrorToast, useShowToast } from "adminComponents/utils/toast";
import { useAnalytics } from "lib/contexts/analytics";
import { useHubSpot } from "lib/hooks/useHubSpot";
import { config } from "links/lib/constants";
import {
  useApprovePendingClassroomStudent,
  useFetchClassroomJoinLinksOnce,
  useRejectPendingClassroomStudent,
} from "links/lib/features/classrooms";
import { AnalyticsEvent, IUser } from "links/lib/types";

export interface IArgs {
  classroom_id: string;
}

export interface IResult {
  handleGenerateLinkIntent: () => void;
  handleApproveStudent: (user: IUser, pendingUserId?: string) => void;
  handleApproveAllStudents: (pendingUserIds: string[]) => void;
  handleRejectStudentIntent: (user: IUser, pendingUserId?: string) => void;
  handleRejectAllStudentsIntent: (pendingUserIds: string[]) => void;
  generateLinkModal: React.ReactElement;
  rejectStudentModal: React.ReactElement;
  addStudentDirectionsModal: React.ReactElement;
}

export const makeJoinLink = (code: string): string => {
  return `${config.baseUrl}/enroll/${code}`;
};

export const useAddStudentsByLink = ({ classroom_id }: IArgs): IResult => {
  const [isLinkModalOpen, setIsLinkModalOpen] = useState<boolean>(false);
  const [isAddStudentDirectionsModalOpen, setIsAddStudentDirectionsModalOpen] =
    useState<boolean>(false);
  const [currentLink, setCurrentLink] = useState<string | undefined>();
  const { trackEvent } = useAnalytics();
  const showToast = useShowToast();
  const [currentRejectStudent, setCurrentRejectStudent] = useState<
    { user: IUser; pendingUserId: string } | undefined
  >();
  const [currentRejectIds, setCurrentRejectIds] = useState<string[]>([]);
  const { t } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "classroomDetailContainer.studentsTab.addByLink",
  });
  const { trackHubSpotEvent } = useHubSpot();

  const onApproveSuccess = useCallback(() => {
    showToast(t("approveSuccessToast"));
    trackHubSpotEvent(AnalyticsEvent.HubSpot_AddedStudentsToClassroom);
  }, [showToast, t, trackHubSpotEvent]);

  const onApproveAllStudentsSuccess = useCallback(() => {
    trackHubSpotEvent(AnalyticsEvent.HubSpot_AddedStudentsToClassroom);
  }, [trackHubSpotEvent]);

  const onRejectSuccess = useCallback(() => {
    showToast(t("rejectSuccessToast"));

    setCurrentRejectStudent(undefined);
  }, [showToast, t]);

  const joinLinkFetch = useFetchClassroomJoinLinksOnce();
  const approveStudent = useApprovePendingClassroomStudent({
    onSuccess: onApproveSuccess,
  });
  const approveAllStudents = useApprovePendingClassroomStudent({
    onSuccess: onApproveAllStudentsSuccess,
  });
  const rejectStudent = useRejectPendingClassroomStudent({
    onSuccess: onRejectSuccess,
  });
  const rejectAllStudents = useRejectPendingClassroomStudent({
    onSuccess: () => setCurrentRejectIds([]),
  });

  useErrorToast(joinLinkFetch.error);
  useErrorToast(approveStudent.error);
  useErrorToast(approveAllStudents.error);
  useErrorToast(rejectStudent.error);
  useErrorToast(rejectAllStudents.error);

  const handleLinkModalClose = useCallback(() => {
    setIsLinkModalOpen(false);
  }, []);

  const handleApproveStudent = useCallback(
    (_: IUser, pendingUserId?: string) => {
      if (!pendingUserId) return;

      approveStudent.mutate({ id: pendingUserId, classroom_id });

      trackEvent(
        AnalyticsEvent.TeacherDashboard_ClassroomDetail_StudentsTab_ApproveStudent,
        { classroomId: classroom_id }
      );
    },
    [approveStudent, classroom_id, trackEvent]
  );

  const handleApproveAllStudents = useCallback(
    async (pendingUserIds: string[]) => {
      if (!pendingUserIds.length) return;

      await Promise.all(
        pendingUserIds.map((id) =>
          approveAllStudents.mutateAsync({ id, classroom_id })
        )
      );

      showToast(t("approveAllSuccessToast", { count: pendingUserIds.length }));
      trackEvent(
        AnalyticsEvent.TeacherDashboard_ClassroomDetail_StudentsTab_ApproveAllStudents,
        { classroomId: classroom_id }
      );
    },
    [approveAllStudents, classroom_id, showToast, t, trackEvent]
  );

  const handleRejectStudent = useCallback(() => {
    if (!currentRejectStudent) return;

    rejectStudent.mutate({
      id: currentRejectStudent.pendingUserId,
      classroom_id,
    });

    trackEvent(
      AnalyticsEvent.TeacherDashboard_ClassroomDetail_StudentsTab_RejectStudentConfirm,
      {
        classroomId: classroom_id,
      }
    );
  }, [rejectStudent, currentRejectStudent, classroom_id, trackEvent]);

  const handleRejectAllStudents = useCallback(async () => {
    if (!currentRejectIds.length) return;

    await Promise.all(
      currentRejectIds.map((id) =>
        rejectAllStudents.mutateAsync({ id, classroom_id })
      )
    );

    showToast(t("rejectAllSuccessToast", { count: currentRejectIds.length }));

    trackEvent(
      AnalyticsEvent.TeacherDashboard_ClassroomDetail_StudentsTab_RejectAllStudentsConfirm,
      {
        classroomId: classroom_id,
      }
    );
  }, [
    currentRejectIds,
    showToast,
    t,
    trackEvent,
    classroom_id,
    rejectAllStudents,
  ]);

  const handleRejectStudentIntent = useCallback(
    (user: IUser, pendingUserId?: string) => {
      if (!pendingUserId) return;

      setCurrentRejectStudent({ user, pendingUserId });

      trackEvent(
        AnalyticsEvent.TeacherDashboard_ClassroomDetail_StudentsTab_RejectStudentIntent,
        {
          classroomId: classroom_id,
        }
      );
    },
    [trackEvent, classroom_id]
  );

  const handleRejectAllStudentsIntent = useCallback(
    (pendingUserIds: string[]) => {
      if (!pendingUserIds.length) return;

      setCurrentRejectIds(pendingUserIds);

      trackEvent(
        AnalyticsEvent.TeacherDashboard_ClassroomDetail_StudentsTab_RejectAllStudentsIntent,
        {
          classroomId: classroom_id,
        }
      );
    },
    [trackEvent, classroom_id]
  );

  const handleRejectStudentModalClose = useCallback(() => {
    setCurrentRejectStudent(undefined);
    setCurrentRejectIds([]);
  }, []);

  const handleGenerateLinkIntent = useCallback(async () => {
    setIsLinkModalOpen(true);

    trackEvent(
      AnalyticsEvent.TeacherDashboard_ClassroomDetail_StudentsTab_AddByLinkIntent,
      { classroomId: classroom_id }
    );

    // Get list of links and generate new if necessary
    const fetchResult = await joinLinkFetch.execute({ classroom_id });

    if (!fetchResult || !fetchResult.current_link) return;
    setCurrentLink(makeJoinLink(fetchResult.current_link.code));
  }, [joinLinkFetch, classroom_id, trackEvent]);

  const handleNext = useCallback(() => {
    setIsAddStudentDirectionsModalOpen(true);
  }, []);

  const generateLinkModal = useMemo(() => {
    return (
      <AddStudentsViaLinkModal
        isOpen={isLinkModalOpen}
        link={currentLink}
        handleClose={handleLinkModalClose}
        handleNext={handleNext}
        isLoading={joinLinkFetch.isLoading}
      />
    );
  }, [
    handleLinkModalClose,
    isLinkModalOpen,
    joinLinkFetch.isLoading,
    currentLink,
    handleNext,
  ]);

  const rejectStudentModal = useMemo(() => {
    return (
      <RejectStudentModal
        handleClose={handleRejectStudentModalClose}
        isLoading={rejectStudent.isLoading}
        isOpen={!!currentRejectStudent || !!currentRejectIds.length}
        student={currentRejectStudent?.user}
        rejectAllCount={currentRejectIds.length}
        handleRemove={handleRejectStudent}
        handleRemoveAll={handleRejectAllStudents}
      />
    );
  }, [
    handleRejectStudentModalClose,
    rejectStudent.isLoading,
    currentRejectStudent,
    currentRejectIds.length,
    handleRejectStudent,
    handleRejectAllStudents,
  ]);

  const addStudentDirectionsModal = useMemo(
    () => (
      <AddStudentDirectionsModal
        isOpen={isAddStudentDirectionsModalOpen}
        handleClose={() => setIsAddStudentDirectionsModalOpen(false)}
        descriptionCase={DescriptionCase.LINK}
      />
    ),
    [isAddStudentDirectionsModalOpen]
  );

  return useMemo(() => {
    return {
      generateLinkModal,
      handleGenerateLinkIntent,
      handleApproveStudent,
      handleApproveAllStudents,
      handleRejectStudentIntent,
      handleRejectAllStudentsIntent,
      rejectStudentModal,
      addStudentDirectionsModal,
    };
  }, [
    generateLinkModal,
    handleGenerateLinkIntent,
    handleApproveStudent,
    handleApproveAllStudents,
    handleRejectStudentIntent,
    handleRejectAllStudentsIntent,
    rejectStudentModal,
    addStudentDirectionsModal,
  ]);
};
