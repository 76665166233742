import { Flex, TextProps } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { CertifiedCheckIcon } from "adminComponents/atoms/CertifiedCheckIcon";
import { IconTooltip } from "adminComponents/atoms/IconTooltip";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils";

interface ICertifiedTextProps extends TextProps {
  type: "set" | "item";
  isCertified?: boolean;
}

export const CertifiedText: React.FC<ICertifiedTextProps> = ({
  type,
  isCertified = true,
  ...rest
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });

  return (
    <Flex
      alignItems="center"
      justifyContent="flex-start"
      gap={[pxToRem(4), null, pxToRem(8)]}
    >
      {isCertified && (
        <CertifiedCheckIcon inverted height={pxToRem(22)} width={pxToRem(22)} />
      )}
      <Text variant="adminP1" color="primary.dark-gray" {...rest}>
        {isCertified ? t("common.certified") : t("common.teacherCreated")}
      </Text>
      <IconTooltip>
        {isCertified &&
          (type === "set"
            ? t("certifiedText.setTooltip")
            : t("certifiedText.itemTooltip"))}
        {!isCertified && t("certifiedText.teacherCreatedTooltip")}
      </IconTooltip>
    </Flex>
  );
};
