import {
  HStack,
  useBreakpointValue,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Card } from "adminComponents/atoms/Card";
import { Icon } from "adminComponents/atoms/Icon";
import { IconButtonWithTooltip } from "adminComponents/atoms/IconButtonWithTooltip";

interface IProps {
  handleEdit: () => void;
  handleShare: () => void;
}

export const FullStudentAvatar: React.FC<IProps> = ({
  children,
  handleEdit,
  handleShare,
}) => {
  const styles = useMultiStyleConfig("AdminFullStudentAvatar", {});
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });
  const isMobile = useBreakpointValue({ base: true, lg: false });

  return (
    <Card
      sx={styles.card}
      variant="adminCardSmallBorder"
      backgroundColor="primary.white"
      borderColor="primary.tan"
    >
      <HStack sx={styles.header}>
        {isMobile ? (
          <IconButtonWithTooltip
            shape="type1"
            icon="edit_outlined"
            sx={styles.iconButton}
            onClick={handleEdit}
            ariaLabel={t("common.edit")}
            tooltipProps={{ popoverVariant: "default" }}
          />
        ) : (
          <Button
            size="sm"
            onClick={handleEdit}
            variant="adminButtonFilled"
            leftIcon={<Icon icon="edit_outlined" />}
          >
            {t("common.edit")}
          </Button>
        )}
        {isMobile ? (
          <IconButtonWithTooltip
            shape="type1"
            icon="share_outlined"
            sx={styles.iconButton}
            onClick={handleShare}
            ariaLabel={t("common.share")}
            tooltipProps={{ popoverVariant: "default" }}
          />
        ) : (
          <Button
            size="sm"
            onClick={handleShare}
            variant="adminButtonFilled"
            leftIcon={<Icon icon="share_outlined" />}
          >
            {t("common.share")}
          </Button>
        )}
      </HStack>
      {children}
    </Card>
  );
};
