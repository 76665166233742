import React, { createContext, useContext, useMemo } from "react";

import { useSessionUsers } from "links/lib/contexts/sessionUsers";
import {
  ISessionUser,
  SessionConnectionStatus,
  UserRole,
} from "links/lib/types";

const initialValue: ISessionUser = {
  id: "0",
  is_guest: false,
  name: "",
  remaining_name_shuffles: 5,
  role: UserRole.Student,
  spine_atlas_url: "",
  spine_json_url: "",
  spritesheet_url: "",
  session_group_id: "",
  profile_image_url: "",
  connection_status: SessionConnectionStatus.Disconnected,
  color_scheme: "golden",
  session_xp_total: 0,
  session_start_xp: 0,
  session_xp_items: 0,
  session_xp_accuracy_bonus: 0,
  session_xp_challenge_completion: 0,
};

export const StudentContext = createContext<ISessionUser>(initialValue);

export const useStudent = (): ISessionUser => {
  return useContext(StudentContext);
};

export const StudentProvider: React.FC<{
  userId: string;
}> = ({ userId, children }) => {
  const users = useSessionUsers();
  const value = useMemo(() => users[userId] || initialValue, [users, userId]);

  return (
    <StudentContext.Provider value={value}>{children}</StudentContext.Provider>
  );
};
