import {
  Box,
  Flex,
  HStack,
  Spacer,
  Text,
  usePrefersReducedMotion,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { animated, config, useSpring, useSpringRef } from "react-spring";
import { useMount, useWindowSize } from "react-use";

import { Icon } from "adminComponents/atoms/Icon";
import { ProgressBar } from "adminComponents/atoms/ProgressBar";
import { AvatarStreak } from "adminComponents/molecules/AvatarStreak";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { XPPerLevel } from "links/lib/constants";
import { useFetchPracticeSessionsStreak } from "links/lib/features/sessions";
import { ISessionUser, IUser } from "links/lib/types";
import AppSpinner from "screens/App/components/AppSpinner";
import { Button } from "sessionComponents/atoms/Button";
import { Card } from "sessionComponents/atoms/Card";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { getUserColorScheme } from "sessionComponents/utils/getUserColorScheme";

import { minWindowHeightForAvatar } from "../constants";

const AnimatedBox = animated(Box);

export interface IStudentCardProps {
  authUser: IUser;
  student: ISessionUser;
  onOpenEditAvatarModal: () => void;
  onOpenSignInModal: () => void;
  onShuffleGuestName: () => void;
}

export const StudentCard: React.FC<IStudentCardProps> = ({
  authUser,
  student,
  onOpenEditAvatarModal,
  onOpenSignInModal,
  onShuffleGuestName,
}) => {
  const { match: currentBreakpoints } = useBreakpoints();
  const { height: windowHeight } = useWindowSize();
  const prefersReducedMotion = usePrefersReducedMotion();

  const fetchStreak = useFetchPracticeSessionsStreak({
    enabled: !authUser.is_guest,
  });

  const { t } = useTranslation("session", {
    keyPrefix: "studentLobbyLight",
    useSuspense: false,
  });

  const nameSwipeInRef = useSpringRef();
  const nameSwipeIn = useSpring({
    ref: nameSwipeInRef,
    to: {
      scale: 1,
    },
    from: {
      scale: 0,
    },
    config: prefersReducedMotion ? config.gentle : config.stiff,
    delay: 300,
  });

  useMount(() =>
    nameSwipeInRef.start({
      to: {
        scale: 1,
      },
      from: {
        scale: 0,
      },
      config: prefersReducedMotion ? config.gentle : config.stiff,
    })
  );

  if (!authUser) {
    return (
      <Card>
        <AppSpinner />
      </Card>
    );
  }

  const windowHeightSupportsAvatar = windowHeight > minWindowHeightForAvatar;

  const xpUntilLevelUp =
    (authUser.student_season_next_level_xp_threshold || 0) -
    (authUser.student_season_total_xp || 0);
  const isSeasonComplete = xpUntilLevelUp < 0;
  const colorScheme = getUserColorScheme(student.color_scheme);

  return (
    <Card
      zIndex={1}
      w={{ base: "85%", md: "50%" }}
      height="auto"
      my="auto"
      mx="auto"
      pt={0}
      px={0}
    >
      <Flex
        w="full"
        h="20%"
        bgColor={colorScheme}
        // Since this inner div is slightly smaller than the parent Card, we need
        // to tweak the border radius slightly to fill in the gap
        // TODO: does this ratio work for all breakpoints?
        borderTopRadius={pxToRem(currentBreakpoints.borderRadius / 1.14)}
        gap={pxToRem(currentBreakpoints.margin / 2)}
      >
        <Box
          mb={pxToRem(-16)}
          pl={pxToRem(currentBreakpoints.margin / 2)}
          pt={pxToRem(currentBreakpoints.margin / 2)}
          cursor={!windowHeightSupportsAvatar ? "pointer" : undefined}
          onClick={
            windowHeightSupportsAvatar
              ? () => onOpenEditAvatarModal()
              : undefined
          }
        >
          <AvatarStreak
            size="sm"
            activeSegment={fetchStreak.data?.streak.current_week_day_count}
            name={student.name}
            src={authUser.profile_image_url}
            weekCount={fetchStreak.data?.streak.week_count}
            backgroundColor={colorScheme}
            showSilhouetteAsFallback
          />
        </Box>
        <Flex
          flexDirection="column"
          pt={pxToRem(currentBreakpoints.margin / 2)}
          // Only set padding bottom for non-guest users; that view
          // has an element below the name
          pb={authUser.is_guest ? 0 : pxToRem(currentBreakpoints.margin / 2)}
          w="full"
          alignItems="start"
          gap={pxToRem(currentBreakpoints.margin / 2)}
        >
          {authUser.is_guest && student.remaining_name_shuffles > 0 && (
            <HStack
              w="full"
              cursor={
                student.remaining_name_shuffles > 0 ? "pointer" : undefined
              }
              color="utility.link"
              onClick={
                student.remaining_name_shuffles > 0
                  ? () => {
                      onShuffleGuestName();
                      nameSwipeInRef.start({
                        to: {
                          scale: 1,
                        },
                        from: {
                          scale: 0,
                        },
                        config: prefersReducedMotion
                          ? config.gentle
                          : config.stiff,
                      });
                    }
                  : undefined
              }
            >
              <Icon icon="shuffle_arrows" iconColor="utility.link" />
              <Text
                textStyle="gameText"
                fontSize={pxToRem(currentBreakpoints.fontSize / 2)}
              >
                {t("shuffleGuestNameText")}
                {student.remaining_name_shuffles > 0
                  ? ` (${student.remaining_name_shuffles})`
                  : ""}
              </Text>
            </HStack>
          )}
          <AnimatedBox w="full" h="full" style={nameSwipeIn}>
            <svg height="100%" width="100%" viewBox="0 0 265 2">
              <text fontFamily={`"Gooper", Georgia, serif`}>
                {student.name}
              </text>
            </svg>
          </AnimatedBox>
          {!authUser.is_guest && (
            <Box
              bgColor="utility.question-green"
              px={pxToRem(8)}
              py={pxToRem(4)}
              borderRadius="lg"
              mt={pxToRem(-30)}
            >
              <Text textStyle="gameText">{t("signedIn")}</Text>
            </Box>
          )}
        </Flex>
        <Spacer />
      </Flex>
      <Flex
        w="full"
        alignItems="center"
        flexDirection="column"
        gap={pxToRem(currentBreakpoints.margin / 2)}
        px={pxToRem(currentBreakpoints.padding / 2)}
        pt={pxToRem(currentBreakpoints.padding / 2)}
      >
        <Flex w="full" flexDirection="column">
          <ProgressBar
            background="primary.light-gray"
            variant="adminStudentGoal"
            value={((XPPerLevel - xpUntilLevelUp) / XPPerLevel) * 100}
            icon={{
              borderColor: "primary.light-gray",
              borderWidthPx: currentBreakpoints.borderWidth,
              boxSize: pxToRem(currentBreakpoints.iconSize),
              iconName: "star_outlined",
            }}
          />
          <Text
            color="primary.dark-gray"
            textStyle="gameText"
            fontSize={pxToRem(currentBreakpoints.fontSize / 2)}
          >
            {isSeasonComplete
              ? t("seasonCompleteText")
              : t("xpUntilLevelUpText", { xpUntilLevelUp })}
          </Text>
        </Flex>
        {student.is_guest && (
          <Button
            w="full"
            variant="buttonOutlined"
            leftIcon={<Icon icon="star_outlined" />}
            borderWidth={pxToRem(2)}
            onClick={onOpenSignInModal}
          >
            {t("signInButton")}
          </Button>
        )}
      </Flex>
    </Card>
  );
};
