import {
  Box,
  Flex,
  Spacer,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Divider } from "adminComponents/atoms/Divider";
import { Heading } from "adminComponents/atoms/Heading";
import { Icon } from "adminComponents/atoms/Icon";
import { IconButton } from "adminComponents/atoms/IconButton";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { IRemix } from "links/lib/types";

export interface ISeasonMapFooterProps {
  remixes: Array<IRemix>;
  handleRemixClick: (remix: IRemix) => void;
}

export const SeasonMapFooter: React.FC<ISeasonMapFooterProps> = ({
  remixes,
  handleRemixClick,
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });
  const bottomItemOffset = useBreakpointValue({
    base: pxToRem(20),
    md: pxToRem(30),
  });

  const { isOpen: isRemixMenuOpen, onToggle: onRemixMenuToggle } =
    useDisclosure();

  return (
    <Box
      position="absolute"
      bottom={bottomItemOffset}
      left={bottomItemOffset}
      zIndex={3}
      maxW="70%"
    >
      {isRemixMenuOpen && (
        <Flex
          backgroundColor="primary.white"
          borderRadius={pxToRem(20)}
          borderWidth={pxToRem(2)}
          borderColor="primary.tan"
          flexDir="column"
          gap={pxToRem(24)}
          alignItems="center"
          mb={pxToRem(10)}
          boxShadow="5px 7px 27px -6px rgba(0, 0, 0, 0.10);"
          w="min-content"
        >
          <Heading as="h1" pt={pxToRem(30)} variant="adminH4">
            {t("myDailyRemixes.title")}
          </Heading>
          <Divider color="primary.tan" />
          <Flex
            flexDir="column"
            alignItems="center"
            pb={pxToRem(30)}
            w="max-content"
          >
            {remixes.map((r, i) => {
              const isCompleted =
                !r.new_session_permitted && !r.is_session_active;

              return (
                <Flex
                  key={i}
                  cursor={!isCompleted ? "pointer" : undefined}
                  w="full"
                  flexDir="row"
                  alignItems="start"
                  justifyContent="start"
                  px={pxToRem(30)}
                  py={pxToRem(7)}
                  gap={pxToRem(10)}
                  _hover={{ backgroundColor: "primary.golden-light" }}
                  onClick={() => {
                    if (isCompleted) return;
                    handleRemixClick(r);
                  }}
                >
                  <Text variant="adminP1">{r.subject_name}</Text>
                  <Spacer />
                  <Text variant="adminP1" color="primary.dark-gray">
                    {!!r.maximum_xp && `${r.maximum_xp} ${t("remixItem.xp")} `}
                    {isCompleted && t("common.complete")}
                    {r.is_session_active && t("remixItem.inProgressLabel")}
                  </Text>
                  <IconButton
                    ariaLabel={`${t("remixItem.viewAriaLabel", {
                      title: r.subject_name,
                    })}`}
                    width={pxToRem(25)}
                    height={pxToRem(25)}
                    icon="play"
                    shape="circle"
                    disabled={isCompleted}
                  />
                </Flex>
              );
            })}
          </Flex>
        </Flex>
      )}
      <Button
        variant="adminButtonWhite"
        rightIcon={
          <Icon
            icon={isRemixMenuOpen ? "keyboard_arrow_up" : "keyboard_arrow_down"}
          />
        }
        onClick={onRemixMenuToggle}
      >
        {t("myDailyRemixes.title")}
      </Button>
    </Box>
  );
};
