import moment from "moment";
import { useEffect, useMemo, useState } from "react";

import { IAssignmentProps } from "adminComponents/screens/StudentDetailScreen/components/AssignmentsTab";
import { useAnalytics } from "lib/contexts/analytics";
import { useFetchClassroomAssignments } from "links/lib/features/classrooms";
import { AnalyticsEvent, IAssignment } from "links/lib/types";

interface IUseAssignmentDataParam {
  classroomId: string;
  studentId: string;
}

interface IUseAssignmentDataResponse {
  activeAssignmentData: IAssignmentProps[];
  pastAssignmentData: IAssignmentProps[];
  isLoading: boolean;
  handleViewMoreAssignments: () => void;
  showViewMoreAssignments: boolean;
}

const makeAssignmentData = (
  studentId: string,
  assignments?: Array<IAssignment>
): Array<IAssignmentProps> => {
  return (
    assignments?.map<IAssignmentProps>((assignment) => {
      const studentAccuracy = assignment.best_user_attempts.find(
        (attempt) => attempt.user_id === studentId
      )?.accuracy;
      const studentAttempts =
        assignment.user_attempt_counts?.find(
          (attempt) => attempt.user_id === studentId
        )?.attempts || 0;
      return {
        assignment,
        accuracy: studentAccuracy,
        attempts: studentAttempts,
      };
    }) || []
  );
};

export const useAssignmentData = ({
  classroomId,
  studentId,
}: IUseAssignmentDataParam): IUseAssignmentDataResponse => {
  const [pastLimit, setPastLimit] = useState(5);
  const [initialLoaded, setInitialLoaded] = useState(false);
  const { trackEvent } = useAnalytics();

  const now = useMemo(() => {
    return moment().startOf("minute");
  }, []);

  // Prevent refresh active every minute
  const dates = useMemo(() => {
    const dates: { [key: string]: Date } = {};

    dates.now = now.toDate();

    dates.upcomingStart = now.clone().add(1, "minute").toDate();
    dates.upcomingEnd = now.clone().add(1, "year").toDate();

    dates.pastStart = now.clone().subtract(1, "year").toDate();
    dates.pastEnd = now.clone().subtract(1, "minute").toDate();

    return dates;
  }, [now]);

  const activeAssignmentsFetch = useFetchClassroomAssignments({
    classroom_id: classroomId,
    start_date: dates.now,
    end_date: dates.now,
    sort_desc: false,
    limit: 100, // attempt to show all active assignments
    keepPreviousData: true,
  });

  const pastAssignmentsFetch = useFetchClassroomAssignments({
    classroom_id: classroomId,
    start_date: dates.pastStart,
    end_date: dates.pastEnd,
    sort_desc: true,
    limit: 100, // attempt to load all past assignments
    keepPreviousData: true,
  });

  const handleViewMoreAssignments = () => {
    setPastLimit((val) => {
      return val + 5;
    });

    trackEvent(
      AnalyticsEvent.TeacherDashboard_ClassroomDetail_StudentDetail_AssignmentsTab_ViewMoreAssignments,
      { classroomId: classroomId, studentId: studentId }
    );
  };

  const activeAssignmentData: Array<IAssignmentProps> = useMemo(() => {
    return makeAssignmentData(
      studentId,
      activeAssignmentsFetch.data?.assignments.filter(
        (assignment) =>
          assignment.user_ids.some((userId) => userId === studentId) ||
          !assignment.user_ids.length
      )
    );
  }, [activeAssignmentsFetch.data, studentId]);

  const pastAssignments: Array<IAssignment> = useMemo(() => {
    return (
      pastAssignmentsFetch.data?.assignments.filter((assignment) => {
        // remove any assignments that are currently active
        return moment(assignment.ends_at).isBefore(now);
      }) || []
    );
  }, [pastAssignmentsFetch.data, now]);

  const pastAssignmentData: Array<IAssignmentProps> = useMemo(() => {
    return makeAssignmentData(
      studentId,
      pastAssignments.filter(
        (assignment) =>
          assignment.user_ids.some((userId) => userId === studentId) ||
          !assignment.user_ids.length
      )
    ).slice(0, pastLimit);
  }, [pastAssignments, pastLimit, studentId]);

  useEffect(() => {
    if (
      !activeAssignmentsFetch.isLoading &&
      !pastAssignmentsFetch.isLoading &&
      !initialLoaded
    ) {
      setInitialLoaded(true);
    }
  }, [
    pastAssignmentsFetch.isLoading,
    activeAssignmentsFetch.isLoading,
    initialLoaded,
  ]);

  const isLoading = !initialLoaded;
  const showViewMoreAssignments = pastAssignments.length > pastLimit;

  return {
    activeAssignmentData,
    pastAssignmentData,
    isLoading,
    handleViewMoreAssignments,
    showViewMoreAssignments,
  };
};
