import { Box, keyframes } from "@chakra-ui/react";
import React, { useMemo } from "react";

import { pxToRem } from "adminComponents/utils/pxToRem";

import MapPinSVG from "../resource/map_pin.svg";

export interface ISeasonMapPinProps {
  userImg?: string;
  scale?: number;
}

const SeasonMapPin: React.FC<ISeasonMapPinProps> = ({ userImg, scale = 1 }) => {
  // pin is scaled larger than the rest of canvas elements
  const s = scale * 2;

  const bobAnim = useMemo(() => {
    return keyframes`
      0% {transform: translateY(0);}
      50% {transform: translateY(${pxToRem(-6 * s)})}
      100% {transform: translateY(0);}
    `;
  }, [s]);

  return (
    <Box
      as="span"
      w={pxToRem(96 * s)}
      h={pxToRem(125 * s)}
      bgImage={MapPinSVG}
      bgRepeat="no-repeat"
      bgPosition="center center"
      bgSize="contain"
      position="absolute"
      animation={`${bobAnim} infinite 2s ease-in-out`}
      top={pxToRem(-125 * s)}
    >
      <Box
        as="span"
        borderRadius="full"
        position="absolute"
        boxSize={pxToRem(80 * s)}
        bgColor={userImg ? "transparent" : "#FA553A"}
        left={pxToRem(8 * s)}
        top={pxToRem(10 * s)}
        bgImage={userImg}
        bgRepeat="no-repeat"
        bgSize="cover"
        bgPosition="center"
      />
    </Box>
  );
};

export { SeasonMapPin };
