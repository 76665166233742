import {
  Box,
  HStack,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
} from "@chakra-ui/react";
import React, { useMemo } from "react";

import { IStandard } from "links/lib/types";

export interface ISelectedStandardsDisplayProps {
  selectedStandards: Array<IStandard>;
  onStandardSelected: (standard: IStandard, isSelected: boolean) => void;
  content: {
    placeholder: string;
  };
}

const SelectedStandardsDisplay: React.FC<ISelectedStandardsDisplayProps> = ({
  selectedStandards,
  onStandardSelected,
  content,
}) => {
  const sortedSelectedStandards = useMemo(() => {
    return selectedStandards.sort(
      (a, b) => parseInt(a.id, 10) - parseInt(b.id, 10)
    );
  }, [selectedStandards]);

  const onRemoveStandard = (standard: IStandard) => {
    onStandardSelected(standard, false);
  };

  return (
    <Box height={14} borderRadius="md" w="full" bg="gray.100" p={4}>
      {sortedSelectedStandards.length === 0 && (
        <Text fontSize="sm" color="gray.700">
          {content.placeholder}
        </Text>
      )}
      {sortedSelectedStandards.length > 0 && (
        <HStack spacing={1}>
          {sortedSelectedStandards.map((standard) => (
            <Tag
              size="md"
              key={standard.id}
              borderRadius="full"
              variant="solid"
              colorScheme="blue"
            >
              <TagLabel>{standard.label}</TagLabel>
              <TagCloseButton onClick={() => onRemoveStandard(standard)} />
            </Tag>
          ))}
        </HStack>
      )}
    </Box>
  );
};

export default SelectedStandardsDisplay;
