import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { useAxios } from "../../hooks/useAxios";

export interface IUseExtendUserTrialParams {
  guid: string;
}

export interface IUseExtendUserTrialResponse {
  extension_days: number;
}

export function useExtendUserTrial(): UseMutationResult<
  IUseExtendUserTrialResponse,
  unknown,
  IUseExtendUserTrialParams
> {
  const axios = useAxios();
  const queryClient = useQueryClient();

  const mutateUserTrialExtension = async (args: IUseExtendUserTrialParams) => {
    const { guid } = args;

    const response = await axios({
      method: "post",
      url: `/v1/subscriptions/users/extensions/${guid}/extend`,
    }).then((r) => r.data as IUseExtendUserTrialResponse);

    queryClient.invalidateQueries(["user-trial-extension", guid]);

    return response;
  };

  const mutation = useMutation(mutateUserTrialExtension);

  return mutation;
}
