import {
  Box,
  Flex,
  HStack,
  Image,
  Show,
  SimpleGrid,
  Spacer,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { animated, config, useSpring } from "react-spring";
import { usePrevious } from "react-use";

import { Button } from "adminComponents/atoms/Button";
import { Heading } from "adminComponents/atoms/Heading";
import { Icon } from "adminComponents/atoms/Icon";
import { IconButton } from "adminComponents/atoms/IconButton";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils";
import { useAuth } from "links/lib/features/auth";
import { useSearchPracticeData } from "links/lib/features/search";
import { useFetchSubjects } from "links/lib/features/subjects";
import {
  PracticeDataDocumentType,
  PracticeSetAvailability,
} from "links/lib/types";

import { useCircularProgressAvatar } from "../hooks/useCircularProgressAvatar";
import PracticeSetCardsSVG from "../resource/practice-set-cards.svg";
import { SubjectCheckbox } from "./SubjectCheckbox";

const DEFAULT_PRACTICE_DATA_SEARCH_LIMIT = 10000;
const STARTING_PERCENTAGE_PROGRESS = 25;
const MIN_PERCENTAGE_PROGRESS = 25;
const MAX_PERCENTAGE_PROGRESS = 50;

interface IProps {
  handleBack?: () => void;
  handleContinue: () => void;
  isLoading: boolean;
  isFinalStep?: boolean;
}

export const TeacherOnboardingStep2: React.FC<IProps> = ({
  handleBack,
  handleContinue,
  isLoading,
  isFinalStep,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const { authUser } = useAuth();

  const { watch } = useFormContext();

  const fetchSubjects = useFetchSubjects({
    country: authUser?.country || "US",
    limit: 100,
  });
  const parentSubjects = useMemo(() => {
    return (fetchSubjects.data?.subjects || []).flatMap((s) =>
      s.parent_id === "0" ? [s] : []
    );
  }, [fetchSubjects.data?.subjects]);

  const watchSubjectIndexes = watch("subjectIndexes");
  const selectedSubjectIds = (watchSubjectIndexes as boolean[]).flatMap(
    (v, i) => {
      if (v) return [parentSubjects[i].id];
      return [];
    }
  );

  const searchPracticeData = useSearchPracticeData({
    subject_ids: selectedSubjectIds,
    include_shared: true,
    availability: PracticeSetAvailability.Public,
    document_type: PracticeDataDocumentType.PracticeSets,
  });

  const { setPercentProgress, circularProgressAvatar } =
    useCircularProgressAvatar({
      profileImageUrl: authUser?.profile_image_url || "",
      startingPercentProgress: STARTING_PERCENTAGE_PROGRESS,
      minPercentProgress: MIN_PERCENTAGE_PROGRESS,
    });
  useEffect(() => {
    if (selectedSubjectIds.length > 0) {
      setPercentProgress(MAX_PERCENTAGE_PROGRESS);
    } else {
      setPercentProgress(MIN_PERCENTAGE_PROGRESS);
    }
  }, [selectedSubjectIds.length, setPercentProgress]);

  const prevTotalPracticeSets = usePrevious(
    searchPracticeData.data?.total_count
  );
  const totalPracticeSetsSpring = useSpring({
    number: searchPracticeData.data?.total_count || 0,
    from: {
      number: prevTotalPracticeSets || 0,
    },
    config: config.default,
  });

  return (
    <HStack
      w="full"
      pl={{ base: "admin.mobileXPadding", md: "admin.desktopXPadding" }}
      pt={{ base: undefined, md: pxToRem(24), lg: 0 }}
    >
      <VStack
        alignItems={["center", null, null, "start"]}
        w={["full", null, null, "auto"]}
        flex="1.4"
        gap={[pxToRem(16), null, null, pxToRem(32)]}
        py={pxToRem(32)}
        pr={pxToRem(32)}
      >
        <VStack alignItems="start" w="full" gap={pxToRem(12)}>
          {handleBack && (
            <Flex alignItems="center" cursor="pointer" onClick={handleBack}>
              <Icon color="utility.link" icon="keyboard_arrow_left" />
              <Text color="utility.link" variant="adminP1">
                {t("common.goBack")}
              </Text>
            </Flex>
          )}
          <Heading as="h1" variant="adminH2" textAlign="left">
            {t("teacherOnboardContainer.step2.heading")}
          </Heading>
          <Text variant="adminP1" textAlign="left">
            {t("teacherOnboardContainer.step2.description")}
          </Text>
        </VStack>
        <Box background="white" width="full" overflow="hidden">
          <VStack
            w="full"
            alignItems={["center", null, null, "start"]}
            justifyContent="center"
            gap={pxToRem(32)}
          >
            <SimpleGrid w="full" minChildWidth={144} gap={pxToRem(8)}>
              {parentSubjects.map((s, index) => (
                <SubjectCheckbox key={index} index={index} subject={s} />
              ))}
            </SimpleGrid>
            <Button
              alignSelf="center"
              size="lg"
              variant="adminButtonFilled"
              disabled={watchSubjectIndexes?.every((s: boolean) => !s) || false}
              onClick={handleContinue}
              isLoading={isLoading}
              w={pxToRem(200)}
            >
              {isFinalStep ? t("common.complete") : t("common.continue")}
            </Button>
          </VStack>
        </Box>
      </VStack>
      <Show above="lg">
        <Flex
          bgColor="primary.lightest-gray"
          backgroundPosition="top left"
          backgroundSize="cover"
          borderTopRightRadius={pxToRem(20)}
          borderBottomRightRadius={pxToRem(20)}
          flex="1"
          h={[pxToRem(250), null, pxToRem(700)]}
          alignItems="center"
          justifyContent="center"
        >
          <VStack
            h="full"
            gap={pxToRem(32)}
            sx={{
              "svg.circular-progress": {
                position: "absolute",
                zIndex: 1,
                top: 0,
                left: 0,
              },
              circle: {
                stroke: "primary.lilac",
                transform: "rotate(-90deg)",
                transformOrigin: "50% 50%",
                transition: "stroke-dashoffset 0.35s",
              },
            }}
          >
            <Spacer />
            {circularProgressAvatar}
            <Flex alignItems="center" gap={pxToRem(12)}>
              <IconButton
                boxSize={pxToRem(32)}
                variant="default"
                shape="circle"
                colorScheme="utility.question-green"
                colorSchemeHover="utility.question-green"
                icon="check"
                color="primary.warm-black"
                ariaLabel=""
                cursor="unset"
              />
              <VStack alignItems="center">
                <Heading variant="adminH3" as="p">
                  <animated.span>
                    {totalPracticeSetsSpring.number.to((n) =>
                      Number(n.toFixed(0)).toLocaleString()
                    )}
                  </animated.span>
                  {(searchPracticeData.data?.total_count || 0) ===
                    DEFAULT_PRACTICE_DATA_SEARCH_LIMIT && "+"}
                </Heading>
                <Text variant="adminP1">
                  {searchPracticeData.data?.total_count === 1
                    ? t("common.practiceSet")
                    : t("common.practiceSets")}
                </Text>
              </VStack>
            </Flex>
            <Image mt="auto" src={PracticeSetCardsSVG} />
          </VStack>
        </Flex>
      </Show>
    </HStack>
  );
};
