// for diagram questions
import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";

import { ColorScheme } from "adminComponents";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { AccuracyBar } from "sessionComponents/atoms/AccuracyBar";
import {
  QuestionResponseWrapper,
  QuestionResponseWrapperInjectedProps,
} from "sessionComponents/atoms/QuestionResponseWrapper";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import {
  IGenericResponseComponentContentProps,
  IGenericResponseComponentProps,
} from "sessionComponents/types";
import { RichTextRenderer } from "sharedComponents/atoms/RichTextRenderer";

import { Marker } from "../../../atoms/Marker";

export interface IDiagramOptionProps extends IGenericResponseComponentProps {
  text: string;
  number: number;
}

export const DiagramOption: React.FC<IDiagramOptionProps> = (props) => {
  const { ariaLabel, text } = props;

  return (
    <QuestionResponseWrapper
      height="fit-content"
      minHeight="fit-content"
      noPadding
      ariaLabel={ariaLabel || text}
      {...props}
      showAccuracyBar={false}
    >
      {(injectedProps: QuestionResponseWrapperInjectedProps) => (
        <DiagramOptionContent {...props} {...injectedProps} />
      )}
    </QuestionResponseWrapper>
  );
};

interface IDiagramOptionContentProps
  extends IGenericResponseComponentContentProps {
  text: string;
  number: number;
}

const DiagramOptionContent = (props: IDiagramOptionContentProps) => {
  const {
    showAccuracyBar,
    primaryColor,
    success,
    error,
    disabled,
    loading,
    text,
    accuracyPercentage,
    number,
  } = props;

  const { match: currentBreakpoints } = useBreakpoints();

  const getIconColor = (): ColorScheme => {
    if (showAccuracyBar || disabled || loading) return "primary.medium-gray";
    if (error) return "utility.error";
    if (success) return "utility.success";

    return "utility.link";
  };

  const iconHeight = currentBreakpoints.responseHeight * 0.75;

  return (
    <>
      <Flex
        alignItems="stretch"
        backgroundColor={disabled ? "primary.warm-white" : "white"}
        minHeight={pxToRem(currentBreakpoints.responseHeight)}
        height="fit-content"
        borderRadius={pxToRem(currentBreakpoints.borderRadius)}
      >
        <Marker
          containerHeight="unset"
          borderRightWidth={pxToRem(3)}
          borderRightColor={primaryColor}
          containerWidth="20%"
          loading={loading}
          iconColor={getIconColor()}
          iconHeight={pxToRem(iconHeight)}
          spinnerSize={pxToRem(iconHeight * 0.75)}
          number={number}
        />
        <Flex
          position="relative"
          minHeight={currentBreakpoints.responseHeight}
          alignItems="center"
          width="full"
        >
          <Text
            marginLeft={pxToRem(currentBreakpoints.margin)}
            as="div"
            textStyle="gameText"
            fontSize={pxToRem(currentBreakpoints.fontSize)}
            userSelect="none"
            color={disabled || loading ? "primary.dark-gray" : undefined}
          >
            <RichTextRenderer content={text} />
          </Text>
          {showAccuracyBar && (
            <Box
              position="absolute"
              bottom={0}
              left={0}
              width="100%"
              zIndex={2}
            >
              <AccuracyBar
                fillColor="utility.success"
                accuracyPercentage={accuracyPercentage || 0}
                backgroundColor="utility.error"
              />
            </Box>
          )}
        </Flex>
      </Flex>
    </>
  );
};
