const gameProgress = (props: {
  [key: string]: string;
}): Record<string, unknown> => ({
  label: {},
  filledTrack: {
    bg: () => {
      return `${props.colorScheme || "monaco"}.base`;
    },
  },
  track: {
    borderWidth: "2px",
    borderStyle: "solid",
    borderColor: "smoke.base",
    bg: "smoke.light",
  },
});

export default {
  variants: {
    gameProgress,
  },
};
