import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { IPracticeSetItem, QuestionType } from "../../types";

export interface IMutateDrawArgs {
  id?: string;
  draw_id?: string;
  practice_set_id: string;
  prompt: string;
  feedback: string;
  feedback_image_url?: string;
  feedback_image_alt_text?: string;
  order?: number;
  is_higher_order_thinking: boolean;
  standards: Array<{ id: string; pear_standard_id: string }>;
  audio_url?: string;
  video_url?: string;
  image_url?: string;
  image_alt_text?: string;
  background_image_url?: string;
  cnc_code?: string;
  skill_id?: string;
  is_certified: boolean;
  is_premium: boolean;
}

interface IMutateDrawResponse {
  practice_set_item: IPracticeSetItem;
}

export interface IUseMutateDrawProps {
  onSuccess?: (data: IMutateDrawResponse) => void;
  onError?: () => void;
  onSettled?: () => void;
}

export default function useMutateDraw(
  props?: IUseMutateDrawProps
): UseMutationResult<IMutateDrawResponse, unknown, IMutateDrawArgs> {
  const axios = useAxios();
  const queryClient = useQueryClient();

  const mutateDraw = async (args: IMutateDrawArgs) => {
    const {
      id,
      practice_set_id,
      prompt,
      feedback,
      feedback_image_url,
      feedback_image_alt_text,
      is_higher_order_thinking,
      draw_id,
      standards,
      audio_url,
      video_url,
      image_url,
      image_alt_text,
      background_image_url,
      cnc_code,
      skill_id,
      is_certified,
      is_premium,
    } = args;

    let { order } = args;

    // if create, then determine order at end of items list
    if (!id) {
      order = await axios
        .get(`/v1/practice-sets/${practice_set_id}/items`)
        .then((r) => {
          let maxOrder = 0;

          (
            r.data as { practice_set_items: Array<IPracticeSetItem> }
          )?.practice_set_items?.forEach(
            (i) => (maxOrder = Math.max(i.order, maxOrder))
          );

          return maxOrder + 1;
        });
    } else {
      order = order || 1;
    }

    const response = await axios({
      method: id ? "put" : "post",
      url: `/v1/practice-sets/${practice_set_id}/items${id ? "/" + id : ""}`,
      data: {
        practice_set_item: {
          draw: {
            id: draw_id,
            prompt,
            background_image_url,
          },
          question_type: QuestionType.Draw,
          feedback,
          feedback_image_url,
          feedback_image_alt_text,
          order,
          is_higher_order_thinking,
          standards,
          audio_url,
          video_url,
          image_url,
          image_alt_text,
          cnc_code,
          skill_id,
          is_certified,
          is_premium,
        },
      },
    }).then((r) => r.data as IMutateDrawResponse);

    queryClient.invalidateQueries(["practiceSets", { id: practice_set_id }]);
    queryClient.invalidateQueries(["practiceSetItems", { practice_set_id }]);
    queryClient.setQueryData(
      ["practiceSetItems", { id: response.practice_set_item.id }],
      {
        practice_set_item: response.practice_set_item,
      }
    );

    return response;
  };

  const mutation = useMutation(mutateDraw, {
    onError: props?.onError,
    onSuccess: props?.onSuccess,
    onSettled: props?.onSettled,
  });

  return {
    ...mutation,
  };
}
