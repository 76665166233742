import type { ComponentStyleConfig } from "@chakra-ui/theme";

import { colorMapping } from "adminComponents/theme/colors";
import { hoverStyles, pxToRem } from "adminComponents/utils";

export const Link: ComponentStyleConfig = {
  variants: {
    adminNavLink: {
      color: "primary.dark-gray",
      padding: pxToRem(4),
      _hover: hoverStyles({
        textDecoration: "none",
        background: "rgba(25, 20, 4, 0.08)",
        borderRadius: pxToRem(4),
        color: "primary.warm-black",
      }),
      _focus: {
        textDecoration: "none",
        background: "rgba(25, 20, 4, 0.08)",
        borderRadius: pxToRem(4),
        boxShadow: "none",
        color: "primary.warm-black",
      },
      _focusVisible: {
        backgroundColor: "transparent",
        outline: `${pxToRem(2)} solid ${colorMapping["utility.focus"]}`,
      },
      _active: {
        color: "primary.warm-black",
        textDecoration: "none",
      },
      "&[aria-current=true]": {
        span: {
          fontWeight: "bold",
          position: "relative",
          _after: {
            content: "''",
            width: "100%",
            height: pxToRem(3),
            backgroundColor: "primary.monaco",
            borderRadius: pxToRem(2),
            position: "absolute",
            bottom: pxToRem(-5),
            left: 0,
          },
        },
      },
    },
  },
};
