import { Box } from "@chakra-ui/react";
import React from "react";

import { LoadingSpinner } from "adminComponents/atoms/LoadingSpinner";
import { StudentsPerformanceTab } from "adminComponents/screens/ClassroomPracticeSetReportScreen/components/StudentsPerformanceTab";
import { pxToRem } from "adminComponents/utils";
import { usePageTrack } from "lib/contexts/analytics";
import { IUseFetchClassroomRecentPracticeSetResponsesResult } from "links/lib/features/classrooms/useFetchClassroomRecentPracticeSetResponses";
import { IFetchClassroomUsersResponse } from "links/lib/features/classrooms/useFetchClassroomUsers";
import { IFetchPracticeSetItemsResponse } from "links/lib/features/practiceSetItems/useFetchPracticeSetItems";
import { IFetchPracticeSetResponse } from "links/lib/features/practiceSets/useFetchPracticeSet";
import { AnalyticsPage } from "links/lib/types";
import { useStudentsData } from "screens/TeacherClassrooms/PracticeSetDetail/hooks/useStudentsData";

interface IStudentsTabProps {
  practiceSetItemsData?: IFetchPracticeSetItemsResponse;
  classroomPracticeSetResponses?: IUseFetchClassroomRecentPracticeSetResponsesResult;
  classroomStudentsData?: IFetchClassroomUsersResponse;
  practiceSetData?: IFetchPracticeSetResponse;
  classroomId: string;
  practiceSetId: string;
  assignmentId?: string;
  practiceSetSessionId?: string;
}

export const StudentsTab: React.FC<IStudentsTabProps> = ({
  classroomId,
  practiceSetId,
  classroomPracticeSetResponses,
  practiceSetItemsData,
  classroomStudentsData,
  practiceSetData,
  assignmentId,
  practiceSetSessionId,
}) => {
  usePageTrack(
    AnalyticsPage.TeacherDashboard_ClassroomDetail_PracticeSetDetail_Students
  );

  const { studentsData, isLoading, handleChangeSort } = useStudentsData({
    classroomId,
    practiceSetId,
    classroomPracticeSetResponses,
    classroomStudentsData,
    practiceSetData,
    practiceSetItemsData,
    assignmentId,
    practiceSetSessionId,
  });

  return (
    <>
      {isLoading ? (
        <Box mt={pxToRem(20)}>
          <LoadingSpinner />
        </Box>
      ) : (
        <StudentsPerformanceTab
          handleStudentSortChange={handleChangeSort}
          studentsData={studentsData}
        />
      )}
    </>
  );
};
