import {
  Box,
  Flex,
  HStack,
  Image,
  RadioGroup,
  Show,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Card } from "adminComponents/atoms/Card";
import { Divider } from "adminComponents/atoms/Divider";
import { Heading } from "adminComponents/atoms/Heading";
import { Radio } from "adminComponents/atoms/Radio";
import { Text } from "adminComponents/atoms/Text";
import { TemplateWithVerticalCenteredContent } from "adminComponents/templates/TemplateWithVerticalCenteredContent";
import { pxToRem } from "adminComponents/utils";
import { useAuth } from "links/lib/features/auth";
import { UserRole } from "links/lib/types";
import HomeRedirect from "screens/App/components/HomeRedirect";
import { HeaderWithLanguagePicker } from "sharedComponents/atoms/HeaderWithLanguagePicker";
import "sharedComponents/css/ios-full-height.css";
import { useLanguageDropdown } from "sharedComponents/hooks/useLanguageDropdown";

import StudentSVG from "./resource/student.svg";

export const UninitializedHome: React.FC = () => {
  const { authUser, initializeUserRole } = useAuth();
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });

  const langDropdown = useLanguageDropdown();

  const [selectedRole, setSelectedRole] = useState<UserRole | undefined>();

  if (authUser?.role !== UserRole.Uninitialized) {
    return <HomeRedirect />;
  }

  const onContinue = () => {
    if (!selectedRole) return;
    initializeUserRole(selectedRole === UserRole.Student ? true : false);
  };

  return (
    <TemplateWithVerticalCenteredContent
      className="fullHeight"
      headerContent={
        <HeaderWithLanguagePicker showSignOut={!authUser.is_guest} />
      }
      bgColor="primary.tan-light"
    >
      <Flex
        w="full"
        h="full"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        gap={pxToRem(56)}
      >
        <Heading as="h1" variant="adminH2" textAlign="center">
          {t("uninitializedHome.heading")}
        </Heading>
        <RadioGroup
          display="flex"
          flexDirection="column"
          w={{ base: "full", md: "50%" }}
          gap={pxToRem(36)}
          onChange={(nextValue: string) =>
            nextValue === UserRole.Teacher
              ? setSelectedRole(UserRole.Teacher)
              : setSelectedRole(UserRole.Student)
          }
          value={selectedRole}
        >
          <Card
            padding={0}
            position="relative"
            pl={pxToRem(32)}
            py={pxToRem(16)}
            variant="adminCardSelectable"
            borderColor="primary.tan"
            bgColor="primary.white"
            onClick={() => setSelectedRole(UserRole.Student)}
          >
            <Box
              position="absolute"
              top="0"
              left={pxToRem(30)}
              bgColor="capri.02"
              px={pxToRem(12)}
              pt={pxToRem(7)}
              pb={pxToRem(4)}
              borderBottomRadius="xl"
            >
              <Text variant="adminP2" color="primary.white">
                {t("uninitializedHome.mostCommon")}
              </Text>
            </Box>
            <HStack w="full" justifyContent="space-between">
              <Radio colorScheme="utility.link" value={UserRole.Student} />
              <VStack alignItems="start" spacing={0}>
                <Text variant="adminP1">{t("uninitializedHome.student")}</Text>
                <Text variant="adminP2">
                  {t("uninitializedHome.studentDescription")}
                </Text>
              </VStack>
              <Image src={StudentSVG} />
            </HStack>
          </Card>
          <Divider color="primary.medium-gray" />
          <Card
            padding={0}
            position="relative"
            px={pxToRem(32)}
            py={pxToRem(22)}
            variant="adminCardSelectable"
            borderColor="primary.tan"
            bgColor="primary.white"
            onClick={() => setSelectedRole(UserRole.Teacher)}
          >
            <HStack w="full" justifyContent="start" spacing={pxToRem(40)}>
              <Radio colorScheme="utility.link" value={UserRole.Teacher} />
              <VStack alignItems="start" spacing={0}>
                <Text variant="adminP1">{t("uninitializedHome.teacher")}</Text>
                <Text variant="adminP2">
                  {t("uninitializedHome.teacherDescription")}
                </Text>
              </VStack>
            </HStack>
          </Card>
        </RadioGroup>
        <Button
          w={{ base: "auto", md: "15%" }}
          variant="adminButtonFilled"
          disabled={!selectedRole}
          onClick={onContinue}
        >
          {t("common.continue")}
        </Button>
      </Flex>
      <Show below="lg">
        <Box mb={pxToRem(30)}>{langDropdown}</Box>
      </Show>
    </TemplateWithVerticalCenteredContent>
  );
};
