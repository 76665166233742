import { Box } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { ColorScheme } from "adminComponents";
import { Card } from "adminComponents/atoms/Card";
import { Checkbox } from "adminComponents/atoms/Checkbox";
import { ExpandableText } from "adminComponents/atoms/ExpandableText";
import { Heading } from "adminComponents/atoms/Heading";
import { Icon } from "adminComponents/atoms/Icon";
import { IconTooltip } from "adminComponents/atoms/IconTooltip";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { IStandard } from "links/lib/types";

interface IProps {
  checked: boolean;
  handleChange: (checked: boolean) => void;
  standard: IStandard;
  bgColor?: ColorScheme;
  borderColor?: ColorScheme;
}

export const StandardsPickerCard: React.FC<IProps> = ({
  checked,
  handleChange,
  standard,
  bgColor = "primary.light-gray",
  borderColor = "primary.light-gray",
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  const label = `${standard.root_label || standard.label}: ${
    standard.description
  }`;

  return (
    <Card
      variant="adminCardSolid"
      backgroundColor={bgColor}
      borderColor={borderColor}
    >
      <Text
        paddingLeft={pxToRem(36)}
        color="primary.warm-black"
        variant="adminP3"
        marginBottom={pxToRem(20)}
      >
        {standard.standard_collection_name}{" "}
        {standard.region && <>&bull; {standard.region}</>}
      </Text>
      <Box display="flex" alignItems="flex-start">
        <Checkbox
          aria-label={label}
          value={standard.id}
          isChecked={checked}
          onChange={(e) => handleChange(e.target.checked)}
        />
        <Box marginLeft={pxToRem(12)}>
          <Box
            color="primary.warm-black"
            display="flex"
            alignItems="center"
            gap={pxToRem(8)}
            marginBottom={pxToRem(8)}
          >
            <Heading as="p" variant="adminH7">
              {standard.root_label || standard.label}
            </Heading>
            {!standard.region && (
              <IconTooltip>{t("createQuestion.standardsTooltip")}</IconTooltip>
            )}
          </Box>
          <ExpandableText
            noOfLines={2}
            textVariant="adminP2"
            buttonVariant="adminTextButtonLargeNoUnderline"
            buttonMarginTop={pxToRem(20)}
            ctaForShowMore={
              <Box display="flex" alignItems="center">
                {t("createQuestion.standardsExpand")}
                <Icon icon="keyboard_arrow_down" iconColor="inherit" />
              </Box>
            }
            ctaForShowLess={
              <Box display="flex" alignItems="center">
                {t("createQuestion.standardsCollapse")}
                <Icon icon="keyboard_arrow_up" iconColor="inherit" />
              </Box>
            }
          >
            {standard.description}
          </ExpandableText>
        </Box>
      </Box>
    </Card>
  );
};
