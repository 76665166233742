import { pxToRem } from "../../utils/pxToRem";

export const AdminPracticeSetQuestionListControlsFixedFooter = {
  parts: ["outer", "buttonsContainer"],
  baseStyle: {
    outer: {
      backgroundColor: "primary.warm-white",
      borderTop: pxToRem(1),
      borderStyle: "solid",
      borderColor: "primary.tan",
      paddingTop: { base: pxToRem(20), md: pxToRem(24) },
      paddingBottom: { base: pxToRem(40), md: pxToRem(24) },
      position: "fixed",
      bottom: "0",
      left: "0",
      right: "0",
      transition: "transform 250ms",
      transform: "translateY(100%)",
      overflow: "none",
      zIndex: "1",
    },
    buttonsContainer: {
      display: { base: "grid", md: "flex" },
      gridTemplateColumns: "1fr 1fr",
      gap: pxToRem(8),
      width: { base: "100%", md: "auto" },
    },
  },
};
