import { Box, Center, Flex, SimpleGrid, Square, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { useSessionScene } from "links/lib/contexts/sessionScene";
import {
  ISessionGroup,
  ISessionUser,
  SessionGameType,
  UserRole,
} from "links/lib/types";
import { Icon } from "sessionComponents/atoms/Icon";
import { Stage } from "sessionComponents/atoms/Stage";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { useGameHypeAudio } from "sessionComponents/hooks/useGameHypeAudio";
import { ConfirmButton } from "sessionComponents/molecules/ConfirmButton";
import { SessionGroupCard } from "sessionComponents/molecules/SessionGroupCard";
import { useDetectOrientation } from "sessionComponents/theme/hooks/useDetectOrientation";

export interface ITeacherTeamUpProps {
  outerGame?: SessionGameType;
  users: ISessionUser[];
  groups: ISessionGroup[];
  onShuffleTeams: () => void;
  onInitiateGame: () => void;
  customGroupsPartiallyApplied: boolean;
}

export const TeacherTeamUp: React.FC<ITeacherTeamUpProps> = ({
  outerGame = SessionGameType.TheBigBoard,
  users,
  groups,
  onShuffleTeams,
  onInitiateGame,
  customGroupsPartiallyApplied,
}) => {
  const { isPortrait } = useDetectOrientation();
  const { match: currentBreakpoints } = useBreakpoints();
  const sessionScene = useSessionScene();
  const { t } = useTranslation("session", {
    keyPrefix: "teacherTeamUp",
    useSuspense: false,
  });

  useGameHypeAudio();

  const students = users.filter((user) => user.role === UserRole.Student);

  const actionButtons = [
    <ConfirmButton
      key="button-shuffle"
      handleConfirmClick={onShuffleTeams}
      h={pxToRem(currentBreakpoints.buttonHeight)}
      w="full"
      bg="primary.white"
      variant="buttonOutlined"
      confirmedWhenChanged={[groups]}
    >
      <Text
        fontSize={pxToRem(currentBreakpoints.fontSize)}
        fontStyle="gameText"
      >
        {t("shuffleTeamsButton")}
      </Text>
    </ConfirmButton>,
    <ConfirmButton
      key="button-start"
      handleConfirmClick={onInitiateGame}
      h={pxToRem(currentBreakpoints.buttonHeight)}
      w="full"
      variant="buttonFilled"
      confirmedWhenChanged={[sessionScene]}
    >
      <Text
        fontSize={pxToRem(currentBreakpoints.fontSize)}
        fontStyle="gameText"
      >
        {t("startButton")}
      </Text>
    </ConfirmButton>,
  ];

  return (
    <Box w="full" h="full" display="flex" flexDirection="column">
      <Box h="full" overflowY="hidden">
        <Stage outerGame={outerGame}>
          <Box h="100%" overflowY="auto">
            {customGroupsPartiallyApplied && (
              <Center
                paddingTop={pxToRem(currentBreakpoints.padding)}
                paddingX={pxToRem(currentBreakpoints.padding)}
              >
                <Flex
                  gap={pxToRem(10)}
                  borderRadius="xl"
                  justifyContent="center"
                  backgroundColor="light.lightGolden"
                  w="100%"
                  p={pxToRem(15)}
                >
                  <Square>
                    <Icon icon="diagram_outlined" />
                  </Square>
                  <Square
                    maxWidth="80%"
                    overflowWrap="break-word"
                    overflow="hidden"
                  >
                    <Text overflowWrap="break-word" textStyle="gameText">
                      {t("customGroupsModified")}
                    </Text>
                  </Square>
                </Flex>
              </Center>
            )}

            <SimpleGrid
              padding={pxToRem(currentBreakpoints.padding)}
              columns={isPortrait ? 2 : 4}
              spacing={pxToRem(16)}
              minHeight={0}
              minW={0}
              flexGrow={2}
            >
              {groups.map((group, index) => {
                const groupStudents = students.filter(
                  (student) => student.session_group_id === group.id
                );

                return (
                  <SessionGroupCard
                    key={`sessionGroups-${group.id}-${index}`}
                    sessionGroup={group}
                    sessionGroupStudents={groupStudents}
                    maxHeight="fit-content"
                    height="auto"
                    minH={0}
                    minW={0}
                  />
                );
              })}
            </SimpleGrid>
          </Box>
        </Stage>
      </Box>

      <SimpleGrid
        padding={pxToRem(currentBreakpoints.padding)}
        backgroundColor="primary.white"
        w="full"
        borderTop="solid"
        borderTopColor="primary.tan"
        borderTopWidth={pxToRem(currentBreakpoints.borderWidth)}
        columns={isPortrait ? 1 : 2}
        spacing={pxToRem(currentBreakpoints.margin / 2)}
      >
        {isPortrait ? actionButtons.reverse() : actionButtons}
      </SimpleGrid>
    </Box>
  );
};
