import { pxToRem } from "adminComponents/utils/pxToRem";

export const Tag = {
  variants: {
    adminSolid: {
      container: {
        paddingX: pxToRem(12),
        height: pxToRem(32),
        borderRadius: pxToRem(8),
        display: "inline-flex",
        alignItems: "center",
        gap: [pxToRem(6), pxToRem(8)],
        borderWidth: 0,
        color: "primary.warm-black",
        boxShadow: "none",
      },
    },
    adminOutline: {
      container: {
        paddingX: pxToRem(10.5),
        height: pxToRem(32),
        borderRadius: pxToRem(8),
        display: "inline-flex",
        alignItems: "center",
        gap: pxToRem(8),
        borderWidth: pxToRem(1.5),
        color: "primary.warm-black",
        boxShadow: "none",
      },
    },
  },
};
