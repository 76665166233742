import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { ISkill } from "../../types";

export interface IFetchSkillOpts {
  id: string;
  disabled?: boolean;
}

export interface IFetchSkillResponse {
  skill: ISkill;
}

/**
 * Fetches single skill by ID
 * @returns
 */
export default function useFetchSkill(
  args: IFetchSkillOpts
): UseQueryResult<IFetchSkillResponse, unknown> {
  const { id, disabled = false } = args;

  const axios = useAxios();
  const isValidSkillId = parseInt(id, 10) > 0;

  const query = useQuery<IFetchSkillResponse>(
    ["skills", { id }],
    async (): Promise<IFetchSkillResponse> => {
      return await axios.get(`/v1/skills/${id}`).then((r) => {
        return r.data as { skill: ISkill };
      });
    },
    {
      enabled: !disabled && isValidSkillId,
    }
  );

  return query;
}
