import { pxToRem } from "adminComponents/utils/pxToRem";

export const AdminTeacherAccountScreen = {
  baseStyle: {
    contentContainer: {
      maxWidth: pxToRem(864),
      gap: pxToRem(24),
      flexDirection: "column",
      margin: "auto",
    },
  },
};
