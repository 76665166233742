import {
  Box,
  Center,
  Flex,
  Image,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import globeImage from "adminComponents/assets/images/Globe.svg";
import { Button } from "adminComponents/atoms/Button";
import { EmbeddedHubspotForm } from "adminComponents/atoms/EmbeddedHubspotForm";
import { GeoRegionTable } from "adminComponents/atoms/GeoDataTable";
import { Logo } from "adminComponents/atoms/Logo";
import { Modal } from "adminComponents/atoms/Modal";
import { Text } from "adminComponents/atoms/Text";
import { TemplateWithVerticalCenteredContent } from "adminComponents/templates/TemplateWithVerticalCenteredContent";
import { pxToRem } from "adminComponents/utils";
import { useFetchGeo } from "links/lib/features/geo";

interface IProps {
  isLoading?: boolean;
}

// HubSpot portal id and form id
const hsPortalId = "5258344";
const hsFormId = "4ad8e5c4-e627-45d7-aa91-32a2d8e41915";

export const InternationalBlockScreen: React.FC<IProps> = ({ isLoading }) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "internationalBlockScreen",
  });
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const useSmallerPortraitHeader = useBreakpointValue({
    base: true,
    sm: false,
  });

  const geo = useFetchGeo();

  const {
    isOpen: isFormOpen,
    onOpen: onFormOpen,
    onClose: onFormClose,
  } = useDisclosure();

  const {
    isOpen: isDetailsOpen,
    onOpen: onDetailsOpen,
    onClose: onDetailsClose,
  } = useDisclosure();

  const titleMargin =
    useBreakpointValue({
      base: 32,
      sm: 40,
      md: 48,
      xl: 56,
    }) ?? 31;

  const buttonMargin =
    useBreakpointValue({
      base: 16,
      sm: 20,
      md: 24,
      xl: 40,
    }) ?? 16;

  const imageContainerHeight = useBreakpointValue({
    base: "40%",
    lg: "100%",
  });

  return (
    <TemplateWithVerticalCenteredContent
      isLoading={isLoading}
      headerContent={
        <Box alignSelf="center" w={pxToRem(154)} paddingTop={pxToRem(25)}>
          <Logo />
        </Box>
      }
    >
      <Flex
        flexDirection={isMobile ? "column" : "row"}
        justifyContent={isMobile ? "space-between" : "center"}
        w="full"
        h="full"
        minHeight="0"
      >
        <Flex
          justifyContent="center"
          h={imageContainerHeight}
          w="100%"
          alignItems="center"
        >
          <Image src={globeImage} title={t("globeAltText")} h="40%" />
        </Flex>
        <Flex flexDirection="column" flexGrow={2}>
          <Flex
            flexDirection="column"
            justifyContent={isMobile ? "flex-start" : "flex-end"}
          >
            <Box>
              <Text
                as="h3"
                align={isMobile ? "center" : "left"}
                variant={useSmallerPortraitHeader ? "adminH3" : "adminH2"}
                marginBottom={pxToRem(titleMargin)}
                marginTop={isMobile ? 0 : pxToRem(titleMargin)}
              >
                {t("title")}
              </Text>
            </Box>
            <Box>
              <Text variant="adminP1" align={isMobile ? "center" : "left"}>
                {t("description")}
              </Text>
            </Box>
          </Flex>

          {geo.data && (
            <Center>
              <Button
                mt={pxToRem(24)}
                variant="adminButtonOutlined"
                onClick={onDetailsOpen}
              >
                {t("showDetailsButton")}
              </Button>
            </Center>
          )}

          <Flex justifySelf="flex-end" flexGrow={2} alignItems="flex-end">
            <Button
              w="full"
              variant="adminButtonFilled"
              onClick={onFormOpen}
              marginBottom={pxToRem(buttonMargin)}
            >
              {t("CTAbutton")}
            </Button>
          </Flex>
        </Flex>
      </Flex>

      {geo.data && isDetailsOpen && (
        <Modal
          headingProps={{ variant: "adminH4", as: "h2" }}
          onClose={onDetailsClose}
          isOpen={isDetailsOpen}
          showBackButton={false}
          size={isMobile ? "full" : "xl"}
        >
          <Box mt={pxToRem(64)}>
            <GeoRegionTable geo={geo.data} />
          </Box>
        </Modal>
      )}

      {isFormOpen && (
        <Modal
          title={t("modalTitle")}
          headingProps={{ variant: "adminH4", as: "h2" }}
          onClose={onFormClose}
          isOpen={isFormOpen}
          size={isMobile ? "full" : "6xl"}
        >
          <EmbeddedHubspotForm portalId={hsPortalId} formId={hsFormId} />
        </Modal>
      )}
    </TemplateWithVerticalCenteredContent>
  );
};
