import {
  Alert,
  Box,
  Link,
  ListItem,
  Table,
  Tbody,
  Td,
  Tr,
  UnorderedList,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { ParseResult } from "papaparse";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCSVReader } from "react-papaparse";

import { Button } from "adminComponents/atoms/Button";
import { CircledNumber } from "adminComponents/atoms/CircledNumber";
import { Icon } from "adminComponents/atoms/Icon";
import { IconTooltip } from "adminComponents/atoms/IconTooltip";
import { Switch } from "adminComponents/atoms/Switch";
import { Text } from "adminComponents/atoms/Text";
import { AdminFlyout } from "adminComponents/molecules/Flyout";
import { pxToRem } from "adminComponents/utils";
import { useErrorToast, useShowToast } from "adminComponents/utils/toast";
import { useAnalytics } from "lib/contexts/analytics";
import { useMutatePracticeSetItems } from "links/lib/features/practiceSetItems";
import {
  AnalyticsEvent,
  IPracticeSet,
  IPracticeSetItem,
} from "links/lib/types";

import { PracticeSetItemsPreviewModal } from "../PracticeSetItemsPreviewModal";
import {
  CSVTargetType,
  ParseErrorType,
  csvToPracticeSetItems,
  detectCSVTargetType,
} from "./utils";

export interface ICSVReaderProps extends IProps {
  getRootProps: () => {
    onClick: () => void;
  };
  acceptedFile: File;
}

export interface IProps {
  practiceSet?: IPracticeSet;
  isOpen: boolean;
  handleClose: () => void;
}

export const PracticeSetImportQuestionsFlyout: React.FC<IProps> = ({
  practiceSet,
  isOpen,
  handleClose,
}: IProps) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const { trackEvent } = useAnalytics();
  const { CSVReader } = useCSVReader();
  const [randomlySortChoices, setRandomlySortChoices] = useState(false);
  const showToast = useShowToast();
  const {
    isOpen: isPreviewModalOpen,
    onClose: onClosePreviewModal,
    onOpen: onOpenPreviewModal,
  } = useDisclosure();

  const mutatePracticeSetItems = useMutatePracticeSetItems();
  useErrorToast(mutatePracticeSetItems.error);

  const [error, setError] = useState<string>();
  const [practiceSetItems, setPracticeSetItems] = useState<
    Array<IPracticeSetItem>
  >([]);
  const [parseErrorCounts, setParseErrorCounts] =
    useState<Map<ParseErrorType, number>>();

  const toggleRandomlySortChoices = () => {
    setRandomlySortChoices(!randomlySortChoices);
  };

  const onUploadFile = (result: ParseResult<Array<string>>) => {
    trackEvent(
      AnalyticsEvent.TeacherDashboard_PracticeSetDetail_ImportItems_UploadCSV,
      {
        practice_set_id: practiceSet?.id,
      }
    );

    if (result.errors.length > 0) {
      console.log("Errors parsing uploaded CSV:", result.errors);
      setError(result.errors[0].message);
      return;
    }

    if (detectCSVTargetType(result.data) === CSVTargetType.Unknown) {
      setError(t("practiceSetImportQuestionsFlyout.errorUnsupportedCSV"));
      return;
    }

    try {
      const [practiceSetItems, parseErrorCounts] = csvToPracticeSetItems(
        result.data,
        randomlySortChoices
      );
      setPracticeSetItems(practiceSetItems);
      setParseErrorCounts(parseErrorCounts);

      // Clear error on successful parse
      setError(undefined);

      const finalItemCount =
        (practiceSet?.item_count || 0) + practiceSetItems.length;
      if (finalItemCount > 100) {
        setError(t("practiceSetImportQuestionsFlyout.errorTooManyItems"));
      } else if (practiceSetItems.length > 0 && parseErrorCounts.size < 1) {
        onOpenPreviewModal();
      } else if (parseErrorCounts.size === 0) {
        setError(t("practiceSetImportQuestionsFlyout.errorNoItemsFound"));
      }
    } catch (err) {
      setError(t("practiceSetImportQuestionsFlyout.errorParsingCSV"));
    }
  };

  const handleSavePracticeSetItems = () => {
    if (!practiceSet) return;

    trackEvent(
      AnalyticsEvent.TeacherDashboard_PracticeSetDetail_ImportItems_SaveItems,
      {
        practice_set_id: practiceSet.id,
      }
    );

    mutatePracticeSetItems.mutate({
      practice_set_id: practiceSet.id,
      practice_set_items: practiceSetItems.map((item, i) => {
        return {
          ...item,
          practice_set_id: practiceSet.id,
          // Preserve item order and place items at the bottom of the set
          order: practiceSet.item_count + i + 1,
        };
      }),
    });
  };

  const onClosePreviewModalRef = useRef(onClosePreviewModal);
  const handleCloseRef = useRef(handleClose);
  const showToastRef = useRef(showToast);
  const successToastMessage = t(
    "practiceSetImportQuestionsFlyout.successToast"
  );

  useEffect(() => {
    if (!mutatePracticeSetItems.isSuccess) return;

    onClosePreviewModalRef.current();
    handleCloseRef.current();
    showToastRef.current(successToastMessage);
  }, [
    mutatePracticeSetItems.isSuccess,
    onClosePreviewModalRef,
    handleCloseRef,
    showToastRef,
    successToastMessage,
  ]);

  return (
    <>
      <AdminFlyout
        isOpen={isOpen}
        onClose={handleClose}
        title={t("practiceSetImportQuestionsFlyout.title")}
      >
        <Box
          pb={[pxToRem(15), null, pxToRem(58)]}
          px={[
            "admin.flyout.mobileXPadding",
            null,
            "admin.flyout.desktopXPadding",
          ]}
        >
          <Text>
            {t("practiceSetImportQuestionsFlyout.overview")}{" "}
            {/* TODO: Add support link */}
            {/* <Link href="#" style={{ textDecoration: "underline" }}>
              {t("practiceSetImportQuestionsFlyout.labelMoreInfoLink")}
            </Link> */}
          </Text>

          <Table mt={pxToRem(24)} mb={pxToRem(24)}>
            <Tbody>
              <Tr>
                <Td>
                  <CircledNumber number={1} />
                </Td>
                <Td>
                  <Text>
                    <Link
                      href={
                        "https://docs.google.com/spreadsheets/d/1O4W0L7HxkfWeOtKQYlWiArn203S2QavqmruZnkTfTEs/copy"
                      }
                      isExternal={true}
                      style={{ textDecoration: "underline" }}
                      target="_blank"
                    >
                      {t(
                        "practiceSetImportQuestionsFlyout.labelCopyAGoogleSheetLink"
                      )}
                    </Link>{" "}
                    {t("common.or")}{" "}
                    <Link
                      href={"/static/PearPracticeQuestionTemplate.csv"}
                      style={{ textDecoration: "underline" }}
                      target="_blank"
                      download={true}
                    >
                      {t(
                        "practiceSetImportQuestionsFlyout.labelDownloadOurTemplateLink"
                      )}
                    </Link>
                  </Text>
                  <Text size="sm" mt={pxToRem(8)}>
                    {t("practiceSetImportQuestionsFlyout.step1Tip")}{" "}
                    {/* TODO: Add support link */}
                    {/* <Link href="#" style={{ textDecoration: "underline" }}>
                      {t("practiceSetImportQuestionsFlyout.labelMoreInfoLink")}
                    </Link> */}
                  </Text>
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <CircledNumber number={2} />
                </Td>
                <Td>
                  <Text>
                    {t("practiceSetImportQuestionsFlyout.step2Instructions")}{" "}
                    {/* TODO: Add support link */}
                    {/* <Link href="#" style={{ textDecoration: "underline" }}>
                      {t(
                        "practiceSetImportQuestionsFlyout.labelExportAsCSVLink"
                      )}
                    </Link> */}
                  </Text>
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <CircledNumber number={3} />
                </Td>
                <Td>
                  <Text>
                    {t("practiceSetImportQuestionsFlyout.step3Instructions")}
                  </Text>
                </Td>
              </Tr>
            </Tbody>
          </Table>

          {error && (
            <Alert status="warning" mb={pxToRem(24)}>
              {error}
            </Alert>
          )}

          {parseErrorCounts && parseErrorCounts.size > 0 && (
            <Alert status="warning" mb={pxToRem(24)} overflow="visible">
              <VStack alignItems="flex-start">
                <Text>
                  {t("practiceSetImportQuestionsFlyout.parseErrorHeading")}
                </Text>
                <UnorderedList pl={pxToRem(24)}>
                  {Array.from(parseErrorCounts.keys()).map(
                    (parseError: string, i: number) => {
                      const count = parseErrorCounts.get(
                        parseError as ParseErrorType
                      );
                      switch (parseError) {
                        case ParseErrorType.InvalidAnswerIndex:
                          return (
                            <ListItem key={i}>
                              {t(
                                "practiceSetImportQuestionsFlyout.parseErrorInvalidAnswerIndex",
                                {
                                  count,
                                }
                              )}
                              <IconTooltip>
                                {t(
                                  "practiceSetImportQuestionsFlyout.parseErrorInvalidAnswerIndexTooltip"
                                )}
                              </IconTooltip>
                            </ListItem>
                          );
                        case ParseErrorType.NoAnswerIndex:
                          return (
                            <ListItem key={i}>
                              {t(
                                "practiceSetImportQuestionsFlyout.parseErrorNoAnswerIndex",
                                {
                                  count,
                                }
                              )}
                              <IconTooltip>
                                {t(
                                  "practiceSetImportQuestionsFlyout.parseErrorNoAnswerIndexTooltip"
                                )}
                              </IconTooltip>
                            </ListItem>
                          );
                        case ParseErrorType.NoAnswers:
                          return (
                            <ListItem key={i}>
                              {t(
                                "practiceSetImportQuestionsFlyout.parseErrorNoAnswers",
                                {
                                  count,
                                }
                              )}
                              <IconTooltip>
                                {t(
                                  "practiceSetImportQuestionsFlyout.parseErrorNoAnswersTooltip"
                                )}
                              </IconTooltip>
                            </ListItem>
                          );
                      }
                    }
                  )}
                </UnorderedList>
              </VStack>
            </Alert>
          )}

          <VStack mt={pxToRem(8)} mb={pxToRem(24)}>
            <Switch
              isChecked={randomlySortChoices}
              onChange={toggleRandomlySortChoices}
            >
              {t("practiceSetImportQuestionsFlyout.randomSortOptionLabel")}
            </Switch>
          </VStack>

          <CSVReader onUploadAccepted={onUploadFile}>
            {({ getRootProps, acceptedFile }: ICSVReaderProps) => {
              return (
                <VStack>
                  {acceptedFile && <Box>{acceptedFile.name}</Box>}
                  <Button
                    variant="adminButtonFilled"
                    aria-label={t(
                      "practiceSetImportQuestionsFlyout.ariaLabelChooseCSV"
                    )}
                    leftIcon={<Icon icon="upload" />}
                    onClick={getRootProps().onClick}
                  >
                    {t("practiceSetImportQuestionsFlyout.buttonChooseCSV")}
                  </Button>
                </VStack>
              );
            }}
          </CSVReader>
        </Box>
      </AdminFlyout>

      <PracticeSetItemsPreviewModal
        heading={t("practiceSetItemsPreviewModal.heading")}
        saveButtonLabel={t("practiceSetItemsPreviewModal.buttonSubmit")}
        practiceSetItems={practiceSetItems}
        isOpen={isPreviewModalOpen}
        handleClose={onClosePreviewModal}
        handleSave={handleSavePracticeSetItems}
        isLoading={mutatePracticeSetItems.isLoading}
      />
    </>
  );
};
