export const afterSignInPathSessionKey = "signInRequestPath";
export const signInIsPopupSessionKey = "signInIsPopup";
export const sessionCodeSessionKey = "session-code";

export const INITIAL_CURTAIN_REVIEW_TIMING_SECONDS = 6000;

// the easy-select accuracy options for creating/editing an assignment
export const ASSIGNMENT_ACCURACY_GOALS = [100, 90, 80, 70, 60];

// the selectable times for an assignment due/start date
export const ASSIGNMENT_TIME_LIST = {
  1: "1:00",
  2: "2:00",
  3: "3:00",
  4: "4:00",
  5: "5:00",
  6: "6:00",
  7: "7:00",
  8: "8:00",
  9: "9:00",
  10: "10:00",
  11: "11:00",
  12: "12:00",
};

// the selectable time of day for an assignment due/start date
export const ASSIGNMENT_TIME_OF_DAY = {
  am: "AM",
  pm: "PM",
};
