import { pxToRem } from "adminComponents/utils/pxToRem";

export const AdminStudentCompletedAssignment = {
  baseStyle: {
    content: {
      flexDirection: { base: "column", md: "row" },
      width: "100%",
    },
    iconContainer: {
      width: pxToRem(63),
      height: pxToRem(63),
      marginRight: { base: pxToRem(14), md: pxToRem(24) },
    },
    icon: {
      width: pxToRem(44),
      height: pxToRem(28),
    },
    details: {
      flex: 1,
      marginRight: pxToRem(45),
    },
    title: {
      marginBottom: pxToRem(14),
    },
    goal: {
      color: "primary.dark-gray",
      marginTop: { base: pxToRem(0), md: pxToRem(14) },
      marginLeft: { base: pxToRem(24), md: pxToRem(0) },
    },
    accuracy: {
      flexDirection: { base: "row", md: "column" },
      alignSelf: { base: "flex-start", md: "center" },
    },
    completion: {
      alignItems: "center",
      marginBottom: { base: pxToRem(14), md: pxToRem(0) },
    },
    checkIcon: {
      marginRight: pxToRem(8),
    },
  },
};
