import { Box, ButtonGroup, Center, Flex, Image } from "@chakra-ui/react";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

import GoogleClassroomImage from "adminComponents/assets/GoogleClassroom.svg";
import { Banner } from "adminComponents/atoms/Banner";
import { Button } from "adminComponents/atoms/Button";
import { Divider } from "adminComponents/atoms/Divider";
import { Heading } from "adminComponents/atoms/Heading";
import { Icon } from "adminComponents/atoms/Icon";
import { Tag } from "adminComponents/atoms/Tag";
import { Text } from "adminComponents/atoms/Text";
import { TextLink } from "adminComponents/atoms/TextLink";
import { AssignmentCard } from "adminComponents/molecules/AssignmentCard";
import { CTABanner } from "adminComponents/molecules/CTABanner";
import { ClassDetailHeader } from "adminComponents/molecules/ClassDetailHeader";
import CoverImageBlob from "adminComponents/molecules/CoverImageBlob";
import { EmptyCard } from "adminComponents/molecules/EmptyCard";
import { LiveClassroomBanner } from "adminComponents/molecules/LiveClassroomBanner";
import {
  INavigationHeaderProps,
  NavigationHeader,
} from "adminComponents/molecules/NavigationHeader";
import { TemplateWithCenteredHeroOneColumn } from "adminComponents/templates/TemplateWithCenteredHeroOneColumn";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { REVIEW_CLASSROOM_REPORT_SUPPORT_URL } from "links/lib/constants";
import { useAuth } from "links/lib/features/auth";
import { LiveBanner } from "links/lib/hooks/useGetClassroomLiveBanners";
import { IAssignment, IClassroom, IPracticeSetSession } from "links/lib/types";

export interface IAssignmentCard {
  assignment: IAssignment;
}
interface IClassroomDetails {
  accuracy?: number;
  assignments?: IAssignmentCard[];
  classroom: IClassroom;
  hasMoreAssignments?: boolean;
}

interface IProps {
  classrooms?: IClassroomDetails[];
  handleArchiveClassroom: (classroom: IClassroom) => void;
  handleCreateAssignment: (classroom: IClassroom) => void;
  handleCreateClassroom: () => void;
  handleDeleteClassroom: (classroom: IClassroom) => void;
  handleDuplicateClassroom: (classroom: IClassroom) => void;
  handleEditClassroom: (classroom: IClassroom) => void;
  handleImportClassroomIntentByGClassroom: () => void;
  handleSeeClassroomArchive: () => void;
  showArchive: boolean;
  isLoading?: boolean;
  navigationData: INavigationHeaderProps;
  liveBanners: LiveBanner[];
  handleJoinSession: (session: IPracticeSetSession) => void;
}

export const ClassroomsScreen: React.FC<IProps> = ({
  classrooms,
  handleArchiveClassroom,
  handleCreateAssignment,
  handleCreateClassroom,
  handleDeleteClassroom,
  handleDuplicateClassroom,
  handleEditClassroom,
  handleImportClassroomIntentByGClassroom,
  handleSeeClassroomArchive,
  isLoading,
  navigationData,
  showArchive,
  liveBanners,
  handleJoinSession,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const { isFeatureEnabled } = useAuth();

  return (
    <TemplateWithCenteredHeroOneColumn
      heroContent={
        <Flex
          alignItems="center"
          flexDirection={{ base: "column", md: "row" }}
          justifyContent={{ base: "center", md: "space-between" }}
        >
          <Heading as="h1" mb={{ base: pxToRem(32), md: 0 }} variant="adminH2">
            {t("classrooms.header")}
          </Heading>
          <ButtonGroup>
            <Button
              onClick={handleCreateClassroom}
              size="lg"
              variant="adminButtonWhite"
              width={{ base: "100%", md: "auto" }}
            >
              {t("classrooms.createClassroom")}
            </Button>
            <Button
              onClick={handleImportClassroomIntentByGClassroom}
              size="lg"
              variant="adminButtonWhite"
              bgColor="white"
              width={{ base: "100%", md: "auto" }}
              leftIcon={<Image w={pxToRem(32)} src={GoogleClassroomImage} />}
            >
              {t("classrooms.importClassroomGClassroom")}
            </Button>
          </ButtonGroup>
        </Flex>
      }
      isLoading={isLoading}
      nav={<NavigationHeader {...navigationData} isLoading={isLoading} />}
    >
      {classrooms && classrooms.length ? (
        <>
          {isFeatureEnabled("playtime.enable_assignment_gradebook") && (
            <Box marginBottom={pxToRem(20)}>
              <Banner>
                <Flex
                  justifyContent="left"
                  alignItems="center"
                  gap={pxToRem(5)}
                >
                  <Tag colorScheme="utility.badge-blue">{t("common.new")}</Tag>
                  <Text fontSize={pxToRem(18)}>{t("classrooms.tryNew")}</Text>
                  <TextLink
                    size="lg"
                    navigationLink={{
                      to: "/t/classrooms/assignment-gradebook",
                      label: "label",
                      isExternal: false,
                    }}
                  >
                    {t("classrooms.assignmentGradebook")}
                  </TextLink>
                </Flex>
              </Banner>
            </Box>
          )}

          {classrooms.map((classroomData) => {
            const { assignments, classroom, hasMoreAssignments, accuracy } =
              classroomData;

            const detailPageLink = {
              isExternal: false,
              label: classroom.name,
              to: `/t/classrooms/${classroom.id}`,
            };

            const assignmentsLink = {
              isExternal: false,
              label: classroom.name,
              to: `/t/classrooms/${classroom.id}/assignments`,
            };

            const banners = liveBanners.filter(
              (b) => b.classroomId === classroom.id
            );

            return (
              <Fragment key={classroom.id}>
                <Box>
                  <ClassDetailHeader
                    headerAs="h2"
                    classroom={classroom}
                    handleArchive={() => handleArchiveClassroom(classroom)}
                    handleDelete={() => handleDeleteClassroom(classroom)}
                    handleDuplicate={() => handleDuplicateClassroom(classroom)}
                    handleEdit={() => handleEditClassroom(classroom)}
                    headerVariant="adminH3"
                    link={detailPageLink}
                    pb={pxToRem(32)}
                    accuracy={accuracy}
                    gradeLevels={[]}
                    subjects={[]}
                    hideSubjectsAndGrades
                  />
                  {!!banners.length && (
                    <Flex
                      marginBottom={pxToRem(32)}
                      direction="column"
                      gap={pxToRem(32)}
                    >
                      {banners.map((b) => (
                        <LiveClassroomBanner
                          key={b.session.id}
                          {...b}
                          showClassroomName={false}
                          alwaysShowPracticeSetName
                          joinButtonTextPrefix="openSessionButton"
                          handleJoinSession={handleJoinSession}
                        />
                      ))}
                    </Flex>
                  )}

                  {assignments && assignments.length ? (
                    assignments.map((assignmentData) => {
                      return (
                        <Box
                          key={assignmentData.assignment.id}
                          mb={pxToRem(12)}
                        >
                          <AssignmentCard {...assignmentData} />
                        </Box>
                      );
                    })
                  ) : (
                    <Box mb={pxToRem(12)}>
                      <EmptyCard
                        actions={[
                          {
                            onClick: () => handleCreateAssignment(classroom),
                            size: "lg",
                            text: t("classrooms.createAssignment"),
                            variant: "adminButtonFilled",
                          },
                        ]}
                        text={t("classrooms.emptyAssignments")}
                      />
                    </Box>
                  )}
                </Box>
                {assignments && hasMoreAssignments && (
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    pb={pxToRem(12)}
                    pt={pxToRem(12)}
                  >
                    <TextLink navigationLink={assignmentsLink}>
                      <Flex
                        alignItems="center"
                        as="span"
                        justifyContent="center"
                      >
                        {t("classrooms.seeAllAssignments")}{" "}
                        <Icon
                          icon="keyboard_arrow_right"
                          iconColor="utility.link"
                          ml={pxToRem(5)}
                          width={pxToRem(20)}
                        />
                      </Flex>
                    </TextLink>
                  </Flex>
                )}
                <Divider
                  color="primary.tan"
                  mb={pxToRem(32)}
                  mt={pxToRem(32)}
                />
              </Fragment>
            );
          })}
        </>
      ) : (
        <Center pb={pxToRem(56)} justifyContent="center">
          <Box w="fit-content">
            <EmptyCard
              title={t("classrooms.emptyCardTitle")}
              text={t("classrooms.emptyCardText")}
              actions={[
                {
                  onClick: handleCreateClassroom,
                  size: "lg",
                  text: t("classrooms.createAClassroom"),
                  variant: "adminButtonOutlined",
                },
              ]}
              cardArt={
                <CoverImageBlob
                  icon="OTHER_ROCKET"
                  boxSize={125}
                  backgroundPattern="TRANSPARENT"
                  backgroundColorScheme="YELLOW"
                />
              }
            />
          </Box>
        </Center>
      )}
      {showArchive && (
        <Flex pb={pxToRem(56)} justifyContent="center">
          <Button
            onClick={handleSeeClassroomArchive}
            size="lg"
            variant="adminButtonOutlined"
          >
            {t("classrooms.seeClassroomArchive")}
          </Button>
        </Flex>
      )}
      <CTABanner
        title={t("classrooms.ctaTitle")}
        description={t("classrooms.ctaDescription")}
        buttonText={t("classrooms.ctaButtonText")}
        handleClick={() =>
          (window.location.href = REVIEW_CLASSROOM_REPORT_SUPPORT_URL)
        }
        image="studentProfile"
      />
    </TemplateWithCenteredHeroOneColumn>
  );
};
