import { Portal, VStack } from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { useMount, usePrevious } from "react-use";
import { clearTimeout, setTimeout } from "timers";

import { pxToRem } from "adminComponents/utils";
import { AnalyticsEvent, ISeasonLevel, ISessionUser } from "links/lib/types";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { useLevelUps } from "sessionComponents/hooks/useLevelUps";
import { StudentSessionSoundEffect } from "sessionComponents/types";
import { useSessionAnalytics } from "sharedComponents/contexts/sessionAnalytics";
import { useSessionAudio } from "sharedComponents/contexts/sessionAudio";

import { LevelUp } from "../LevelUp";
import { AccuracyCard } from "./components/AccuracyCard";
import { BonusCard } from "./components/BonusCard";
import { Intro } from "./components/Intro";
import { LevelProgress } from "./components/LevelProgress";
import { PracticeCard } from "./components/PracticeCard";

export interface IPodiumSummaryProps {
  student: ISessionUser;
  practiceXP: number;
  bonusXP: number;
  practiceAccuracy: number;
  accuracyXP: number;
  seasonLevels: Array<ISeasonLevel>;
  handleComplete?: () => void;
}

export const PodiumSummary: React.FC<IPodiumSummaryProps> = ({
  student,
  practiceAccuracy,
  practiceXP,
  bonusXP,
  accuracyXP,
  seasonLevels,
  handleComplete,
}) => {
  const { match: currentBreakpoints } = useBreakpoints();
  const [totalXP, setTotalXP] = useState<number>(student.session_start_xp);
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [showPracticeCard, setShowPracticeCard] = useState<boolean>(false);
  const [showAccuracyCard, setShowAccuracyCard] = useState<boolean>(false);
  const [showBonusCard, setShowBonusCard] = useState<boolean>(false);
  const [isSummaryComplete, setIsSummaryComplete] = useState<boolean>(false);
  const [isLevelUpComplete, setIsLevelUpComplete] = useState<boolean>(false);
  const levelUps = useLevelUps(student, seasonLevels);
  const { play: playAudio } = useSessionAudio();
  const { track } = useSessionAnalytics();

  useMount(() => {
    track(AnalyticsEvent.Session_Podium_ViewSummary, {
      practiceXP,
      bonusXP,
      accuracyXP,
    });

    if (levelUps.length > 1) {
      levelUps
        .filter((_, i) => i !== 0)
        .forEach((lu) => {
          track(AnalyticsEvent.Session_Podium_LevelUp, {
            level: lu.level,
            nextLevel: lu.nextLevel,
          });
        });
    }
  });

  // Check if the next xp addition will trigger a level up
  // and align level up sound effect with progress bar fill
  const prevTotalXP = usePrevious(totalXP) ?? 0;
  useEffect(() => {
    const levelCrossed = levelUps.find((lu) => {
      return (
        prevTotalXP < lu.nextLevelXPThreshold &&
        totalXP > lu.nextLevelXPThreshold
      );
    });

    if (levelCrossed) {
      // align sound effect with when the bar should be full
      const duration =
        400 *
        ((levelCrossed.nextLevelXPThreshold - prevTotalXP) /
          (levelCrossed.nextLevelXPThreshold - levelCrossed.levelXPThreshold));

      const to = setTimeout(() => {
        playAudio(StudentSessionSoundEffect.Correct);
      }, duration);

      return () => {
        clearTimeout(to);
      };
    }
  }, [totalXP, levelUps, playAudio, prevTotalXP]);

  const handleSummaryComplete = () => {
    setIsSummaryComplete(true);
    handleComplete?.();
  };

  const handleLevelUpComplete = useCallback(() => {
    setIsLevelUpComplete(true);
  }, []);

  return (
    <VStack
      w="full"
      h="full"
      alignItems="center"
      spacing={pxToRem(currentBreakpoints.margin / 2)}
    >
      <Intro student={student} handleComplete={() => setShowProgress(true)} />
      <LevelProgress
        student={student}
        levelUps={levelUps}
        seasonLevels={seasonLevels}
        totalXP={totalXP}
        show={showProgress}
        onEnterComplete={() => setShowPracticeCard(true)}
      />
      <PracticeCard
        show={showPracticeCard}
        practiceXP={practiceXP}
        handleSetTotalXP={setTotalXP}
        handleComplete={() => {
          setShowAccuracyCard(true);
        }}
      />
      <AccuracyCard
        show={showAccuracyCard}
        practiceAccuracy={practiceAccuracy}
        accuracyXP={accuracyXP}
        handleSetTotalXP={setTotalXP}
        handleComplete={() => {
          if (bonusXP) {
            setShowBonusCard(true);
          } else {
            handleSummaryComplete();
          }
        }}
      />
      {!!bonusXP && (
        <BonusCard
          show={showBonusCard}
          bonusXP={bonusXP}
          handleSetTotalXP={setTotalXP}
          handleComplete={() => handleSummaryComplete()}
        />
      )}
      {levelUps.length > 1 && (
        <Portal>
          <LevelUp
            show={isSummaryComplete && !isLevelUpComplete}
            handleComplete={handleLevelUpComplete}
          />
        </Portal>
      )}
    </VStack>
  );
};
