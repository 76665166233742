import { IUseAsyncReturn, useAsync } from "../../hooks/useAsync";
import { useAxios } from "../../hooks/useAxios";
import { IStandardCollection } from "../../types";

export interface IFetchStandardCollectionsOpts {
  limit: number;
  offset: number;
  country: string;
  region: string;
  grade_level_id?: string;
  subject_id?: string;
  query?: string;
}

export interface IFetchStandardCollectionsResponse {
  standard_collections: Array<IStandardCollection>;
}

/**
 * Fetches standard collections a single time using execution
 * parameters without caching results or invalidating standard
 * collection queries
 * @returns
 */
export default function useFetchStandardCollectionsOnce(): IUseAsyncReturn<
  IFetchStandardCollectionsResponse,
  IFetchStandardCollectionsOpts
> {
  const axios = useAxios();

  const fetchStandardCollections = async ({
    limit,
    country,
    region,
    grade_level_id,
    subject_id,
    offset,
    query,
  }: IFetchStandardCollectionsOpts) => {
    return await axios
      .get(`/v1/standard-collections`, {
        params: {
          limit,
          country,
          region,
          offset,
          grade_level_id: grade_level_id || "0",
          subject_id: subject_id || "0",
          query: query || "",
        },
      })
      .then((r) => r.data as IFetchStandardCollectionsResponse);
  };

  const { isLoading, error, data, execute, isSuccess } = useAsync<
    IFetchStandardCollectionsResponse,
    IFetchStandardCollectionsOpts
  >(fetchStandardCollections);

  return {
    isLoading,
    error,
    data,
    isSuccess,
    execute,
  };
}
