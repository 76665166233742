import {
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";

import { Icon } from "adminComponents/atoms/Icon";
import { pxToRem } from "adminComponents/utils/pxToRem";

interface IGeneratingModalProps {
  isOpen: boolean;
  labelHeading: string;
  labelSubHeading: string;
}

export const GeneratingModal: React.FC<IGeneratingModalProps> = ({
  isOpen,
  labelHeading,
  labelSubHeading,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      isCentered
      onClose={() => {
        // noop
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <VStack m={pxToRem(32)}>
            <Box
              w={pxToRem(48)}
              h={pxToRem(48)}
              mb={pxToRem(8)}
              position="relative"
            >
              <Spinner size="xl"></Spinner>
              <Icon
                icon="zap"
                w={pxToRem(24)}
                h={pxToRem(24)}
                top={pxToRem(12)}
                left={pxToRem(12)}
                position="absolute"
              />
            </Box>
            <Text fontWeight="bold" color="primary.warm-black">
              {labelHeading}
            </Text>
            <Text color="primary.dark-gray">{labelSubHeading}</Text>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
