import { useDisclosure } from "@chakra-ui/react";
import moment from "moment";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useLocalStorage, useMount } from "react-use";

import { StudentCompletedAssignmentsFlyout } from "adminComponents/organisms/StudentCompletedAssignmentsFlyout";
import {
  IMyPracticeSet,
  MyClassesScreen,
} from "adminComponents/screens/MyClassesScreen";
import { useErrorToast, useShowToast } from "adminComponents/utils/toast";
import { useAnalytics, usePageTrack } from "lib/contexts/analytics";
import { usePageTitle } from "lib/hooks/usePageTitle";
import { useFetchClassroomAssignments } from "links/lib/features/classrooms";
import { useGetClassroomLiveBanners } from "links/lib/hooks/useGetClassroomLiveBanners";
import {
  AnalyticsEvent,
  AnalyticsPage,
  IAssignment,
  IClassroom,
  IPracticeSet,
  IPracticeSetSession,
  SessionType,
} from "links/lib/types";
import { sortAlpha } from "links/lib/util";
import { usePlaySession } from "screens/StudentDashboard/contexts/PlaySessionProvider";
import { useStudentDashboardData } from "screens/StudentDashboard/contexts/StudentDashboardDataProvider";
import { useNavigationData } from "screens/StudentDashboard/contexts/StudentNavigationDataProvider";
import { useStartAssignment } from "sharedComponents/hooks/useStartAssignment";

export const StudentMyClasses: React.FC = () => {
  const { trackEvent } = useAnalytics();
  const [selectedClassroomId, setSelectedClassroomId] = useLocalStorage(
    "student.myClasses.selectedClassroomId",
    "0"
  );
  const {
    isOpen: isCompletedAssignmentsFlyoutOpen,
    onOpen: onCompletedAssignmentsFlyoutOpen,
    onClose: onCompletedAssignmentsFlyoutClose,
  } = useDisclosure();
  const { handlePlaySession, handleSetRedirectToSeasonMap, pendingSession } =
    usePlaySession();
  useMount(() => {
    handleSetRedirectToSeasonMap(false);
  });
  const { navigationData } = useNavigationData();
  const showToast = useShowToast();
  const history = useHistory();
  const {
    activeAssignments: allActiveAssignments,
    liveSessionMap,
    classroomMap,
    classroomStandardAccuraciesMap,
    practiceSetDetailArray,
    practiceSetDetailMap,
    standards,
    isLoading: isStudentDashboardDataLoading,
    isAssignmentDataLoading,
  } = useStudentDashboardData();
  const { t } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "myClassesContainer",
  });

  usePageTrack(AnalyticsPage.StudentDashboard_MyClasses);

  const now = useMemo(() => {
    return moment().startOf("minute");
  }, []);

  const activeAssignments = useMemo(
    () =>
      allActiveAssignments.filter((a) => a.group_id === selectedClassroomId),
    [selectedClassroomId, allActiveAssignments]
  );

  const fetchPastClassroomAssignments = useFetchClassroomAssignments({
    classroom_id: selectedClassroomId || "0",
    start_date: now.clone().subtract(1, "year").toDate(),
    end_date: now.clone().subtract(1, "minute").toDate(),
    sort_desc: true,
    limit: 100, // attempt to load all past assignments
  });
  useErrorToast(fetchPastClassroomAssignments.error);
  const pastAssignments = useMemo(() => {
    return fetchPastClassroomAssignments.data?.assignments ?? [];
  }, [fetchPastClassroomAssignments.data]);

  const classrooms = useMemo(() => Object.values(classroomMap), [classroomMap]);
  const activeClassroom = classrooms.find((c) => c.id === selectedClassroomId);

  usePageTitle([activeClassroom?.name, t("pageTitle")]);

  const classroomStandardAccuracies = useMemo(() => {
    if (!selectedClassroomId) return { average_standard_accuracies: [] };
    return (
      classroomStandardAccuraciesMap[selectedClassroomId] || {
        average_standard_accuracies: [],
      }
    );
  }, [selectedClassroomId, classroomStandardAccuraciesMap]);

  const myPracticeSets: IMyPracticeSet[] = useMemo(() => {
    return practiceSetDetailArray
      .filter((cps) => cps.classroom_id === selectedClassroomId)
      .map((p) => {
        return {
          practiceSet: p.practice_set,
          rewardXp: p.maximum_xp,
        };
      })
      .sort((a, b) => {
        return sortAlpha(a.practiceSet.title, b.practiceSet.title);
      });
  }, [selectedClassroomId, practiceSetDetailArray]);

  const [myActiveAssignments, myCompletedAssignments] = useMemo(() => {
    const myActiveAssignments = activeAssignments.map((a) => {
      const assignmentPracticeSet = practiceSetDetailMap[a.practice_set_id];

      const bestAttempt = a.best_user_attempts[0];
      return {
        assignment: a,
        accuracy: bestAttempt?.accuracy || 0,
        rewardXp: assignmentPracticeSet?.maximum_xp || 0,
        isDueToday: moment(a.ends_at).isBefore(now.clone().endOf("day")),
        completionDate: undefined,
      };
    });

    const distinctAssignments = new Map<string, IAssignment>();
    activeAssignments.concat(pastAssignments).forEach((a) => {
      distinctAssignments.set(a.id, a);
    });

    const myCompletedAssignments = Array.from(distinctAssignments.values())
      .filter((a) => {
        return (
          a.best_user_attempts[0]?.accuracy || 0 >= a.required_score_percent
        );
      })
      .sort((a, b) => {
        const aCompletedDate = new Date(
          a.best_user_attempts[0]?.created_at || ""
        );
        const bCompletedDate = new Date(
          b.best_user_attempts[0]?.created_at || ""
        );
        return aCompletedDate < bCompletedDate ? 1 : -1;
      })
      .map((a) => {
        const bestAttempt = a.best_user_attempts[0];
        return {
          assignment: a,
          accuracy: bestAttempt?.accuracy || 0,
          rewardXp: 0, // TODO: Are assignments ever supposed to award XP?
          isDueToday: false,
          completionDate: bestAttempt?.created_at,
        };
      });

    return [myActiveAssignments, myCompletedAssignments];
  }, [activeAssignments, pastAssignments, now, practiceSetDetailMap]);

  const hasCompletedAssignments = useMemo(() => {
    const hasCompletedActiveAssignment = activeAssignments.some((a) => {
      return a.best_user_attempts[0]?.accuracy || 0 >= a.required_score_percent;
    });
    const hasCompletedPastAssignment = pastAssignments.some((a) => {
      return a.best_user_attempts[0]?.accuracy || 0 >= a.required_score_percent;
    });

    return hasCompletedActiveAssignment || hasCompletedPastAssignment;
  }, [activeAssignments, pastAssignments]);

  useEffect(() => {
    if (!classrooms.length) return;

    // Set initially selected classroom on first classroom load
    if (selectedClassroomId === "0") {
      setSelectedClassroomId(classrooms[0].id);
    }

    // If the classroom selected can't be found, select the first one
    const classroom = classrooms.find(
      (classroom) => classroom.id === selectedClassroomId
    );
    if (!classroom) {
      setSelectedClassroomId(classrooms[0].id);
    }
  }, [selectedClassroomId, setSelectedClassroomId, classrooms]);

  const handleGrantAccess = () => {
    trackEvent(AnalyticsEvent.StudentDashboatd_MyClasses_GrantAccess, {});
  };

  const handleStartAssignment = (assignment: IAssignment) => {
    trackEvent(AnalyticsEvent.StudentDashboard_MyClasses_PlayAssignment, {
      assigmentId: assignment.id,
      practiceSetId: assignment.practice_set_id,
    });
  };

  const { authorizeModal, startAssignment, pendingAssignment } =
    useStartAssignment({
      assignments: activeAssignments,
      handleGrantAccess,
      handleStartAssignment,
    });

  const handlePlayAssignment = (assignment: IAssignment) => {
    startAssignment(assignment);
  };

  const handlePlayPracticeSet = (practiceSet: IPracticeSet) => {
    if (!selectedClassroomId || selectedClassroomId === "0") {
      showToast(t("noSelectedClassroomError"));
      return;
    }

    const existingSessionsForSet = Object.values(liveSessionMap).filter(
      (session) =>
        session.practice_set_id === practiceSet.id &&
        session.session_type === SessionType.Individual
    );

    if (existingSessionsForSet.length > 1) {
      console.warn(
        "Found multiple incomplete sessions for set; joining most recent/class session as a priority"
      );
      existingSessionsForSet.sort((a, b) =>
        new Date(a.start_time) < new Date(b.start_time) ? 1 : -1
      );
    }

    // Take first class session if it exists, otherwise rejoin the session with most recent start_time
    const existingSessionJoinCode =
      existingSessionsForSet.find(
        (session) => session.session_type === SessionType.Class
      )?.join_code || existingSessionsForSet[0]?.join_code;

    handlePlaySession({
      classroomId: selectedClassroomId,
      practiceSetId: practiceSet.id,
      existingSessionJoinCode,
    });

    trackEvent(AnalyticsEvent.StudentDashboard_MyClasses_PlayPracticeSet, {
      practiceSetId: practiceSet.id,
    });
  };

  const handleJoinSession = (session: IPracticeSetSession) => {
    history.push(`/session/join/${session.join_code}`);

    trackEvent(
      AnalyticsEvent.StudentDashboard_MyClasses_LiveBanner_JoinSession,
      { sessionId: session.id }
    );
  };

  const liveBanners = useGetClassroomLiveBanners({
    showClassroomName: true,
  });

  const isLoading = isStudentDashboardDataLoading;
  const isClassroomLoading =
    fetchPastClassroomAssignments.isLoading || isAssignmentDataLoading;

  const pendingSessionAction = pendingSession || pendingAssignment;

  return (
    <>
      <MyClassesScreen
        handlePlayAssignment={handlePlayAssignment}
        handlePlayPracticeSet={handlePlayPracticeSet}
        handleSelectClassroom={(classroom: IClassroom) =>
          setSelectedClassroomId(classroom.id)
        }
        handleViewCompletedAssignments={onCompletedAssignmentsFlyoutOpen}
        isLoading={isLoading}
        isClassroomLoading={isClassroomLoading}
        classrooms={classrooms}
        activeClassroom={activeClassroom}
        myAssignments={myActiveAssignments}
        myPracticeSets={myPracticeSets}
        navigationData={navigationData}
        hasCompletedAssignments={hasCompletedAssignments}
        pendingSession={pendingSessionAction}
        handleJoinSession={handleJoinSession}
        liveBanners={liveBanners}
        classroomStandardAccuracies={classroomStandardAccuracies}
        standards={standards}
      />
      <StudentCompletedAssignmentsFlyout
        isOpen={isCompletedAssignmentsFlyoutOpen}
        handleClose={onCompletedAssignmentsFlyoutClose}
        classroom={activeClassroom}
        completedAssignments={myCompletedAssignments}
      />
      {authorizeModal}
    </>
  );
};
