export const svgToBase64 = (
  contents: string,
  replaceColor?: string,
  fillColor?: string
): string => {
  const singleLineContents = contents
    .replaceAll("\r\n", "")
    .replaceAll("\r", "")
    .replaceAll("\n", "");

  let svgContents = singleLineContents.match(/<svg.*<\/svg>/gm);

  if (!svgContents?.length) {
    svgContents = singleLineContents.match(/<svg.*\/>/gm);
  }

  if (!svgContents?.length) return "";

  // Reject SVGs that style paths using <style/>
  // because GoSVG can't render them correctly.
  const styleMatches = svgContents[0].match(/<style.*<\/style>/gm);
  if (styleMatches?.length) {
    return "";
  }

  if (replaceColor && fillColor) {
    svgContents[0] = svgContents[0].replaceAll(replaceColor, fillColor);
    svgContents[0] = svgContents[0].replaceAll(
      replaceColor.toUpperCase(),
      fillColor
    );
  }

  return btoa(svgContents[0]);
};
