import { hoverStyles, pxToRem } from "adminComponents/utils";

export const AdminSortableTable = {
  parts: [
    "container",
    "head",
    "row",
    "th",
    "tbody",
    "thead",
    "tr",
    "td",
    "divider",
    "mobileSortSelection",
  ],
  baseStyle: (): Record<string, unknown> => ({
    container: {
      w: "100%",
    },
    thead: {
      bgColor: { base: "transparent", md: "primary.light-gray" },
    },
    tbody: {
      _before: {
        content: "''",
        display: "block",
        height: pxToRem(20),
      },
    },
    row: {},
    th: {
      display: {
        base: "none",
        md: "table-cell",
      },
      fontSize: pxToRem(20),
      fontWeight: 500,
      py: pxToRem(18),
      px: pxToRem(10),
      wordWrap: "break-word",
      whiteSpace: "break-spaces",
      button: {
        backgroundColor: "transparent",
        borderRadius: pxToRem(4),
        padding: `${pxToRem(3)} ${pxToRem(6)}`,
        whiteSpace: "break-spaces",
        _hover: hoverStyles({
          backgroundColor: "transparent",
        }),
        _focus: {
          backgroundColor: "transparent",
          border: "none",
          boxShadow: "none",
        },
        _focusVisible: {
          borderRadius: pxToRem(4),
          outline: `${pxToRem(1)} solid`,
          outlineColor: "utility.focus",
        },
      },
      "button svg": {
        w: "24px",
        h: "24px",
        ml: pxToRem(2),
        mt: pxToRem(4),
      },
      p: {
        color: "primary.warm-black",
      },
      "[aria-selected=true] p, [aria-selected=true] svg": {
        color: "primary.warm-black",
        fontWeight: 700,
      },
      "&[aria-selected=true]": {
        display: { base: "table-cell" },
      },
    },
    tr: {},
    td: {
      display: { base: "block", md: "table-cell" },
      px: { base: 0, md: pxToRem(20) },
      py: { base: pxToRem(4), md: 0 },
      w: "max-content",
    },
    divider: {
      py: { base: pxToRem(16), md: pxToRem(19) },
      maxW: { base: pxToRem(285), md: "full" },
    },
    mobileSortSelection: {
      "button[aria-haspopup]": {
        alignItems: "center",
        color: "utility.link",
        display: "flex",
        _hover: hoverStyles({
          backgroundColor: "transparent",
        }),
        _focus: {
          backgroundColor: "transparent",
          border: "none",
          boxShadow: "none",
        },
        _focusVisible: {
          borderRadius: pxToRem(4),
          outline: `${pxToRem(1)} solid`,
          outlineColor: "utility.focus",
        },
      },
      "button[aria-haspopup] *": {
        color: "utility.link",
        fontSize: pxToRem(16),
        fontWeight: 500,
        textDecorationColor: "utility.link",
      },
    },
  }),
  variants: {
    adminSortableTable: {
      thead: {
        borderRadius: pxToRem(8),
        bgColor: { base: "transparent", md: "primary.warm-white" },
      },
    },
  },
};
