import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Link as Anchor, Box, LinkProps as LProps } from "@chakra-ui/react";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

import { NavigationLink } from "adminComponents";

export interface ILinkProps extends LProps {
  styles?: Record<string, unknown>;
  link: NavigationLink;
}

export const Link: React.FC<ILinkProps> = ({ link, styles, ...rest }) => {
  const { isExternal, label, to } = link;

  return (
    <Box __css={styles} as="li">
      <Anchor
        {...rest}
        as={isExternal ? Anchor : RouterLink}
        href={isExternal ? to : undefined}
        isExternal={isExternal}
        to={isExternal ? undefined : to}
      >
        {label}
        {isExternal && <ExternalLinkIcon mx="8px" />}
      </Anchor>
    </Box>
  );
};
