import { pxToRem } from "adminComponents/utils/pxToRem";

export const AdminFullStudentAvatar = {
  baseStyle: {
    header: {
      position: "absolute",
      top: "admin.cards.small",
      right: "admin.cards.small",
      zIndex: 1,
    },
    card: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      // Targets the stage for the avatar inside the card.
      // This is based on the assumption that the card will only ever
      // contain an image or an avatar stage.
      "> div:nth-child(2)": {
        h: { base: pxToRem(224), md: pxToRem(429) },
        overflow: "hidden",
        transform: "translate3d(0,0,0)",
        w: { base: pxToRem(112), md: pxToRem(215) },
      },
    },
  },
};
