import { Box, Stack, VStack } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useMount } from "react-use";

import { Card } from "adminComponents/atoms/Card";
import { Divider } from "adminComponents/atoms/Divider";
import { Heading } from "adminComponents/atoms/Heading";
import { Text } from "adminComponents/atoms/Text";
import { PracticeSetItemAccordion } from "adminComponents/molecules/PracticeSetItemAccordion";
import { pxToRem } from "adminComponents/utils";
import { IPracticeSet, IPracticeSetItem } from "links/lib/types";

import CoverImageBlob from "../CoverImageBlob";

export interface IGeneratePracticeSetResultProps {
  practiceSet: IPracticeSet;
  items: IPracticeSetItem[];
}

export const GeneratePracticeSetResult: React.FC<
  IGeneratePracticeSetResultProps
> = ({ practiceSet, items }) => {
  const { t } = useTranslation("admin", {
    keyPrefix: "generatePracticeSetResult",
    useSuspense: false,
  });
  const containerRef = React.useRef<HTMLDivElement>(null);

  useMount(() => {
    if (containerRef.current?.parentElement) {
      containerRef.current.parentElement.scrollTo(0, 0);
    }
  });

  return (
    <VStack
      ref={containerRef}
      w="full"
      alignItems="flex-start"
      justifyContent="flex-start"
      px={["admin.flyout.mobileXPadding", null, "admin.flyout.desktopXPadding"]}
      pb={pxToRem(20)}
      spacing={pxToRem(30)}
    >
      <VStack
        w="full"
        alignItems="flex-start"
        justifyContent="flex-start"
        spacing={pxToRem(6)}
      >
        <Heading as="p" variant="adminH5Bold" color="utility.success">
          {t("heading")}
        </Heading>
        <Text as="p" variant="adminP1">
          {t("description")}
        </Text>
      </VStack>
      <Divider color="primary.light-gray" />
      <VStack alignItems="flex-start" w="full" spacing={pxToRem(20)}>
        <Box>
          <Heading as="p" variant="adminH6">
            {t("practiceSetHeading")}
          </Heading>
        </Box>
        <Card variant="adminCardMediumBorder" borderColor="primary.tan">
          <Stack
            spacing={pxToRem(20)}
            alignItems={practiceSet.description ? "flex-start" : "center"}
            justifyContent="flex-start"
            w="full"
            direction={{ base: "column", md: "row" }}
          >
            <Box>
              <CoverImageBlob
                data-role="coverImage"
                backgroundColorScheme={practiceSet.cover_image_bg_color_scheme}
                backgroundPattern={practiceSet.cover_image_bg_pattern}
                icon={practiceSet.cover_image_icon}
                boxSize={80}
              />
            </Box>
            <VStack w="full" alignItems="flex-start">
              <Heading as="p" variant="adminH3">
                {practiceSet.title}
              </Heading>
              <Text variant="adminP1">{practiceSet.description}</Text>
            </VStack>
          </Stack>
        </Card>
        <Heading as="p" variant="adminH7">
          {t("questionsHeading", { count: items.length })}
        </Heading>
        {items.map((item, i) => (
          <PracticeSetItemAccordion key={`item-${i}`} practiceSetItem={item} />
        ))}
      </VStack>
    </VStack>
  );
};
