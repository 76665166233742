import { Box, BoxProps } from "@chakra-ui/react";
import React, { useMemo } from "react";

import { SessionGameType } from "links/lib/types";

import BackgroundFlames from "../QuickPlayLogo/resource/quick_play_bg_pattern_flames.svg";
import Pattern1 from "./assets/Pattern1.png";
import Pattern2 from "./assets/Pattern2.png";
import Pattern3 from "./assets/Pattern3.png";
import Pattern4 from "./assets/Pattern4.png";
import Pattern5 from "./assets/Pattern5.png";
import Pattern6 from "./assets/Pattern6.png";
import Pattern7 from "./assets/Pattern7.png";
import Pattern8 from "./assets/Pattern8.png";
import Pattern9 from "./assets/Pattern9.png";

const patterns = [
  Pattern1,
  Pattern2,
  Pattern3,
  Pattern4,
  Pattern5,
  Pattern6,
  Pattern7,
  Pattern8,
  Pattern9,
];

interface IGameBackgroundProps extends BoxProps {
  outerGame?: SessionGameType;
}

//todo props such as padding, margin??
export const GameBackground: React.FC<IGameBackgroundProps> = ({
  outerGame = SessionGameType.TheBigBoard,
  ...props
}) => {
  const pattern = useMemo(() => {
    if (outerGame === SessionGameType.QuickPlay) {
      return BackgroundFlames;
    }

    // generate random number between 0-8
    const num = Math.floor(Math.random() * 9);

    return patterns[num];
  }, [outerGame]);

  return (
    <Box
      w="100%"
      h="100%"
      backgroundImage={pattern}
      display="flex"
      justifyContent="center" // todo make prop?
      alignItems="center" // todo make prop?
      backgroundSize="cover" // todo should this be a prop?
      {...props}
    >
      {props.children}
    </Box>
  );
};
