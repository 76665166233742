export default {
  variants: {
    tableRow: {
      display: "block",
      w: "full",
      h: 14,
      bg: "white",
      borderTopColor: "gray.200",
      borderTopWidth: 1,
      borderTopStyle: "solid",
      _hover: {
        textDecoration: "none",
        bg: "gray.50",
      },
      _active: {
        bg: "gray.200",
      },
    },
  },
};
