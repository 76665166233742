import { Box } from "@chakra-ui/react";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";

import { Heading } from "adminComponents/atoms/Heading";
import { Tabs } from "adminComponents/atoms/Tabs";
import {
  INavigationHeaderProps,
  NavigationHeader,
} from "adminComponents/molecules/NavigationHeader";
import { TemplateWithCenteredHeroOneColumn } from "adminComponents/templates/TemplateWithCenteredHeroOneColumn";
import { pxToRem } from "adminComponents/utils";
import { useAuth } from "links/lib/features/auth";

export enum TeacherAccountTab {
  AccountSettings = 0,
  ProfileSettings = 1,
  NotificationSettings = 2,
}

type ITeacherAccountTemplateProps = {
  navigationData: INavigationHeaderProps;
  isLoading: boolean;
};

export const TeacherAccountTemplate: React.FC<ITeacherAccountTemplateProps> = ({
  navigationData,
  isLoading,
  children,
}) => {
  const { isFeatureEnabled } = useAuth();
  const { t } = useTranslation("admin", { useSuspense: false });
  const history = useHistory();

  const isAccountSettingsTab = useRouteMatch({
    path: "/t/account",
    exact: true,
  });
  const isProfileSettingsTab = useRouteMatch("/t/account/profile/settings");
  const isNotificationSettingsTab = useRouteMatch(
    "/t/account/notifications/settings"
  );

  const tabData = [
    {
      label: t("teacherAccountTabs.tabAccountSettings"),
      content: <></>,
    },
  ];

  if (isFeatureEnabled("playtime.teacher.show_profile_settings")) {
    tabData.push({
      label: t("teacherAccountTabs.tabProfileSettings"),
      content: <></>,
    });
  }

  if (
    isFeatureEnabled("playtime.teacher.show_recurring_notification_settings")
  ) {
    tabData.push({
      label: t("teacherAccountTabs.tabNotificationSettings"),
      content: <></>,
    });
  }

  const tabIndex = useMemo(() => {
    if (isAccountSettingsTab) {
      return TeacherAccountTab.AccountSettings;
    }
    if (isProfileSettingsTab) {
      return TeacherAccountTab.ProfileSettings;
    }
    if (isNotificationSettingsTab) {
      return TeacherAccountTab.NotificationSettings;
    }
  }, [isAccountSettingsTab, isProfileSettingsTab, isNotificationSettingsTab]);

  const handleTabChange = useCallback(
    (tab: TeacherAccountTab) => {
      switch (tab) {
        case TeacherAccountTab.AccountSettings:
          history.push(`/t/account`);
          return;
        case TeacherAccountTab.ProfileSettings:
          history.push(`/t/account/profile/settings`);
          return;
        case TeacherAccountTab.NotificationSettings:
          history.push(`/t/account/notifications/settings`);
          return;
      }
    },
    [history]
  );

  const navData = {
    isLoading,
    ...navigationData,
    variant: "teacherHomepageNav",
  };

  return (
    <TemplateWithCenteredHeroOneColumn
      isLoading={isLoading}
      nav={<NavigationHeader {...navData} />}
      heroContent={
        <Heading as="h1" variant="adminH2">
          {t("myAccountScreen.myAccountHeading")}
        </Heading>
      }
    >
      <Tabs
        tabIndex={tabIndex}
        handleChange={handleTabChange}
        tabData={tabData}
        variant="adminScreenTabs"
      />

      <Box w="full" mt={pxToRem(32)}>
        {children}
      </Box>
    </TemplateWithCenteredHeroOneColumn>
  );
};
