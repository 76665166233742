import { FormControl, SimpleGrid } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { usePrevious, useUnmount } from "react-use";

import { FormErrorMessage } from "adminComponents/atoms/FormErrorMessage";
import { FormLabel } from "adminComponents/atoms/FormLabel";
import {
  EditPracticeSetMediaModalContextProvider,
  useEditPracticeSetMediaModalContext,
} from "adminComponents/contexts/EditPracticeSetMediaModalContext";
// import { QuestionAddMedia } from "adminComponents/molecules/QuestionAddMedia";
import { CreateQuestionFooter } from "adminComponents/molecules/CreateQuestionFooter";
import { QuestionTypeDescription } from "adminComponents/molecules/QuestionTypeDescription";
import { AddMediaModal } from "adminComponents/organisms/AddMediaModal";
import { RichTextEditor } from "adminComponents/organisms/RichTextEditor";
import { isRichTextEmpty } from "adminComponents/organisms/RichTextEditor/util";
import { CreateQuestionFormDataType } from "adminComponents/utils";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { useAuth } from "links/lib/features/auth";
import { RichValue } from "links/lib/types";
import { generateEmptySlateState } from "links/lib/util";

import { Classifications, IClassifyFormCategory } from "../Classifications";

export interface CreateClassificationTabForm {
  prompt: RichValue;
  audioUrl: string;
  videoUrl: string;
  imageUrl: string;
  imageAltText: string;
  requiresHigherOrderThinking: boolean;
  categories: IClassifyFormCategory[];
}

interface IProps {
  defaultValue: CreateQuestionFormDataType;
  hasFeedback: boolean;
  canSubmit: boolean;
  isSubmitting: boolean;
  isImageMode: boolean;
  handleSaveAndClose: (form: CreateClassificationTabForm) => void;
  handleChangeForm: (
    isValid: boolean,
    form?: CreateClassificationTabForm
  ) => void;
  handleNextTab: () => void;
  handleDirty?: () => void;
}

const getInitialValue = (isImageMode: boolean) => {
  const initialValues: CreateClassificationTabForm = {
    prompt: generateEmptySlateState(),
    audioUrl: "",
    videoUrl: "",
    imageAltText: "",
    imageUrl: "",
    requiresHigherOrderThinking: false,
    categories: [
      {
        text: generateEmptySlateState(),
        choices: [],
      },
      {
        text: generateEmptySlateState(),
        choices: [],
      },
    ],
  };

  if (!isImageMode) {
    initialValues.categories = initialValues.categories.map((category) => ({
      ...category,
      choices: [
        { text: generateEmptySlateState() },
        { text: generateEmptySlateState() },
      ],
    }));
  }

  return initialValues;
};

export const CreateQuestionClassificationTab: React.FC<IProps> = ({
  defaultValue,
  handleSaveAndClose,
  handleChangeForm,
  handleNextTab,
  hasFeedback,
  canSubmit,
  isSubmitting,
  isImageMode,
  handleDirty,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const mediaModalProps = useEditPracticeSetMediaModalContext();
  const { hasNoPremiumAccess } = useAuth();

  const formMethods = useForm<CreateClassificationTabForm>({
    defaultValues: defaultValue.classify ?? getInitialValue(isImageMode),
    mode: "onChange",
  });

  const {
    handleSubmit,
    setValue,
    watch,
    control,
    getValues,
    formState: { errors, isValid, touchedFields, isDirty },
  } = formMethods;

  const _handleSubmit = (data: CreateClassificationTabForm) => {
    handleSaveAndClose(data);
  };

  useEffect(() => {
    if (isDirty && handleDirty) handleDirty();
  }, [isDirty, handleDirty]);

  useUnmount(() => {
    handleChangeForm(isValid, getValues());
  });

  const prevIsValid = usePrevious(isValid);
  useEffect(() => {
    if (prevIsValid !== isValid) {
      handleChangeForm(isValid);
    }
  }, [isValid, handleChangeForm, prevIsValid]);

  const [
    audioUrl,
    imageAltText,
    imageUrl,
    videoUrl,
    requiresHigherOrderThinking,
  ] = watch([
    "audioUrl",
    "imageAltText",
    "imageUrl",
    "videoUrl",
    "requiresHigherOrderThinking",
  ]);

  const _handleNextTab = async () => {
    handleNextTab();
  };

  const handleChangeRequiresHigherOrderThinking = (checked: boolean) => {
    setValue("requiresHigherOrderThinking", checked);
  };

  const _handleInsertMedia = (
    imageUrl: string,
    imageAltText: string,
    audioUrl?: string,
    videoUrl?: string
  ) => {
    setValue("audioUrl", audioUrl ?? "");
    setValue("imageUrl", imageUrl);
    setValue("imageAltText", imageAltText);
    setValue("videoUrl", videoUrl ?? "");
    mediaModalProps.handleInsertMedia(
      imageUrl,
      imageAltText,
      audioUrl,
      videoUrl
    );
  };

  const _handleRemoveAudio = () => {
    setValue("audioUrl", "");
    mediaModalProps.handleRemoveAudio();
  };

  const _handleRemoveMedia = () => {
    setValue("audioUrl", "");
    setValue("imageUrl", "");
    setValue("imageAltText", "");
    setValue("videoUrl", "");
    mediaModalProps.handleRemoveImage();
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(_handleSubmit)}>
        <SimpleGrid
          py={pxToRem(40)}
          px={[pxToRem(15), null, pxToRem(58)]}
          gap={pxToRem(40)}
        >
          <QuestionTypeDescription
            icon="list_outlined"
            iconColor="utility.badge-orange"
            title={t("createClassificationFlyout.classificationDropdownTitle")}
            subTitle=""
          />
          <FormControl
            isRequired
            variant="adminFormControl"
            isInvalid={!!touchedFields.prompt && !!errors.prompt}
            minWidth={0}
          >
            <FormLabel>{t("createQuestion.labelPrompt")}</FormLabel>
            <Controller
              control={control}
              name="prompt"
              rules={{
                validate: {
                  isNotEmpty: (v) =>
                    !isRichTextEmpty(v) ||
                    (t("createQuestion.errPromptRequired") as string),
                },
              }}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { isTouched, error },
              }) => (
                <RichTextEditor
                  aria-label={t("createQuestion.placeholderPrompt")}
                  ref={ref}
                  name={name}
                  handleChangeRaw={onChange}
                  handleBlur={onBlur}
                  value={value}
                  placeholder={t("createQuestion.placeholderPrompt")}
                  hasError={isTouched && !!error}
                  disabled={hasNoPremiumAccess}
                />
              )}
            />
            <FormErrorMessage>{errors.prompt?.message}</FormErrorMessage>
          </FormControl>
          {/* TODO: We currently do not support media on classification */}
          {/* <QuestionAddMedia
            handleAddMedia={mediaModalProps.handleOpen}
            handleRemoveMedia={_handleRemoveMedia}
            imageAdded={imageUrl}
            imageAddedAltText={imageAltText}
            audioAdded={audioUrl}
            videoAdded={videoUrl}
          /> */}
          <EditPracticeSetMediaModalContextProvider>
            <Classifications
              hasNoPremiumAccess={hasNoPremiumAccess}
              label={t("createQuestion.labelCategories")}
              isImageMode={isImageMode}
            />
          </EditPracticeSetMediaModalContextProvider>
          <CreateQuestionFooter
            canSubmit={canSubmit}
            isSubmitting={isSubmitting}
            requiresHigherOrderThinking={requiresHigherOrderThinking}
            handleChangeRequireHigherOrderThinking={
              handleChangeRequiresHigherOrderThinking
            }
            handleNextTab={_handleNextTab}
            handleSaveAndClose={handleSubmit(_handleSubmit)}
            nextSectionTextCopies={{
              nextSectionCta: hasFeedback
                ? t("createQuestion.editFeedback")
                : t("createQuestion.addFeedback"),
              nextSectionTitle: t("createQuestion.feedback"),
              nextSectionDescription: t("createQuestion.feedbackDescription"),
              nextSectionIcon: hasFeedback ? "edit_outlined" : "add",
            }}
          />
          <AddMediaModal
            {...mediaModalProps}
            audioUrlToAdd={audioUrl}
            imageUrlToAdd={imageUrl}
            imageAltText={imageAltText}
            videoUrlToAdd={videoUrl}
            handleInsertMedia={_handleInsertMedia}
            handleRemoveImage={_handleRemoveMedia}
            handleRemoveAudio={_handleRemoveAudio}
          />
        </SimpleGrid>
      </form>
    </FormProvider>
  );
};
