import {
  Box,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import {
  SortingFn,
  SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import moment from "moment";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Avatar } from "adminComponents/atoms/Avatar";
import { Button } from "adminComponents/atoms/Button";
import { Icon } from "adminComponents/atoms/Icon";
import { generateStudentName, pxToRem } from "adminComponents/utils";
import { IAssignment, IPracticeSet, IUser } from "links/lib/types";
import { sortAlpha } from "links/lib/util";

declare module "@tanstack/table-core" {
  interface SortingFns {
    sortByName: SortingFn<Row>;
  }
}

type UserAssignmentAccuracy = {
  assignmentId: string;
  accuracy: number;
  isMissing: boolean;
  isCompleted: boolean;
};

type Row = {
  name: string;
  profileImageURL?: string;
  isAggregated: boolean;
  accuracies: Array<UserAssignmentAccuracy>;
};

export type AssignmentDetail = {
  assignment: IAssignment;
  practiceSet: IPracticeSet;
};

export interface IAssignmentScoreGridProps {
  assignmentDetails: Array<AssignmentDetail>;
  users: Array<IUser>;
}

const columnHelper = createColumnHelper<Row>();

const toScore = (accuracy: number) => {
  if (accuracy < 0) {
    return `-`;
  } else {
    return `${(Math.round(accuracy * 100) / 100).toFixed(2)}%`;
  }
};

const getMinWidths = (colName: string) => {
  switch (colName) {
    case "overall":
      return pxToRem(120);
    default:
      return pxToRem(200);
  }
};

export const AssignmentScoreGrid: React.FC<IAssignmentScoreGridProps> = ({
  assignmentDetails,
  users,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  const [sorting, setSorting] = React.useState<SortingState>([
    {
      id: "name",
      desc: true,
    },
  ]);

  const [nameSort, setNameSort] = React.useState<"first" | "last">("last");

  const sortByName: SortingFn<unknown> = useCallback(
    (rowA, rowB, columnId): number => {
      const rowAValue = rowA.getValue(columnId) as string;
      const rowBValue = rowB.getValue(columnId) as string;
      const a =
        nameSort === "last" ? rowAValue.split(" ").pop() ?? "" : rowAValue;
      const b =
        nameSort === "last" ? rowBValue.split(" ").pop() ?? "" : rowBValue;
      return sortAlpha(a, b, true);
    },
    [nameSort]
  );

  const sortedAssignmentDetails = useMemo(
    () =>
      [...assignmentDetails].sort((a, b) => {
        return (
          new Date(b.assignment.created_at).getTime() -
          new Date(a.assignment.created_at).getTime()
        );
      }),
    [assignmentDetails]
  );

  const data = useMemo(() => {
    const d = users.map((user) => {
      return {
        name: generateStudentName(user).primary,
        isAggregated: false,
        profileImageURL: user.profile_image_url,
        accuracies: sortedAssignmentDetails.map((detail) => {
          const bestAttempt = detail.assignment.best_user_attempts.find(
            (a) => a.user_id === user.id
          );

          // Check if the user was assigned the assignment
          const wasAssigned = !!detail.assignment.user_ids.find(
            (id) => id === user.id
          );

          const accuracy = bestAttempt?.accuracy ?? -1;

          return {
            assignmentId: detail.assignment.id,
            accuracy,
            isCompleted: accuracy >= detail.assignment.required_score_percent,
            isMissing: wasAssigned && !bestAttempt,
          };
        }),
      };
    });

    if (sortedAssignmentDetails.length) {
      d.unshift({
        name: t("assignmentScoreGrid.classAverageLabel"),
        isAggregated: true,
        profileImageURL: undefined,
        accuracies: sortedAssignmentDetails.map((detail) => {
          return {
            assignmentId: detail.assignment.id,
            isCompleted: false,
            isMissing: false,
            accuracy: !detail.assignment.best_user_attempts.length
              ? -1
              : detail.assignment.best_user_attempts.reduce(
                  (acc, a) => acc + a.accuracy,
                  0
                ) / detail.assignment.best_user_attempts.length,
          };
        }),
      });
    }

    return d;
  }, [users, sortedAssignmentDetails, t]);

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.name, {
        header: (context) => {
          const sortDir = context.column.getIsSorted() || "desc";

          const setSort = (ns: "first" | "last") => (e: React.MouseEvent) => {
            e.stopPropagation();
            setNameSort(ns);
            context.table.setSorting([
              {
                id: "name",
                desc: sortDir === "desc",
              },
            ]);
          };

          return (
            <HStack alignItems="flex-start" spacing={pxToRem(4)}>
              <Text variant="adminP2">
                {t("assignmentScoreGrid.studentColHeader")}
              </Text>
              {context.column.getIsSorted() && (
                <Menu computePositionOnMount variant="adminDropdown">
                  <MenuButton
                    as={Button}
                    variant="adminTextButtonSmall"
                    zIndex="2"
                    onClick={(e) => e.stopPropagation()}
                  >
                    (
                    {nameSort === "last"
                      ? t("assignmentScoreGrid.sortLastName")
                      : t("assignmentScoreGrid.sortFirstName")}
                    )
                  </MenuButton>
                  <MenuList zIndex="dropdown">
                    <MenuItem onClick={setSort("last")}>
                      <Text variant="adminP2" w="full" as="span">
                        {t("assignmentScoreGrid.sortLastName")}
                      </Text>
                    </MenuItem>
                    <MenuItem onClick={setSort("first")}>
                      <Text variant="adminP2" w="full" as="span">
                        {t("assignmentScoreGrid.sortFirstName")}
                      </Text>
                    </MenuItem>
                  </MenuList>
                </Menu>
              )}
            </HStack>
          );
        },

        id: "name",
        sortingFn: "sortByName",
        cell: (data) => (
          <HStack spacing={pxToRem(14)} maxW={pxToRem(160)}>
            {!data.row.original.isAggregated && (
              <Avatar
                size="sm"
                src={data.row.original.profileImageURL}
                name={data.getValue()}
              />
            )}
            {data.row.original.isAggregated && (
              <Icon icon="diagram" boxSize={pxToRem(28)} />
            )}
            <Tooltip
              label={data.getValue()}
              openDelay={500}
              placement="bottom"
              variant="adminDark"
            >
              <Box
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
                fontWeight="medium"
              >
                {data.getValue()}
              </Box>
            </Tooltip>
          </HStack>
        ),
      }),
      columnHelper.accessor(
        (row) => {
          // don't count accuracies of -1 in overall user accuracy because that indicates they
          // did not attempt the assignment
          const accuraciesToCount = row.accuracies.filter(
            (a) => a.accuracy >= 0
          );

          return accuraciesToCount.length
            ? accuraciesToCount.reduce((acc, a) => acc + a.accuracy, 0) /
                accuraciesToCount.length
            : -1;
        },
        {
          header: t("assignmentScoreGrid.overallColHeader"),
          id: "overall",
          cell: (data) => toScore(data.getValue()),
        }
      ),
      ...sortedAssignmentDetails.map((detail) =>
        columnHelper.accessor(
          (row) => {
            return (
              row.accuracies.find(
                (acc) => acc.assignmentId === detail.assignment.id
              )?.accuracy ?? -1
            );
          },
          {
            id: detail.assignment.id,
            cell: (data) => {
              const accuracy = data.getValue();
              const original = data.row.original.accuracies.find(
                (a) => a.assignmentId === detail.assignment.id
              );

              return (
                <HStack alignItems="center">
                  {original?.isMissing ? (
                    <Box color="utility.error">
                      {t("assignmentScoreGrid.missingLabel")}
                    </Box>
                  ) : (
                    <>
                      <Box
                        color={
                          original?.isCompleted
                            ? "utility.success"
                            : "primary.warm-black"
                        }
                      >
                        {toScore(accuracy)}
                      </Box>
                      {original?.isCompleted && (
                        <Tooltip
                          label={t("assignmentScoreGrid.completedTooltip")}
                          variant="adminDark"
                          openDelay={500}
                        >
                          <Box boxSize={pxToRem(16)}>
                            <Icon
                              icon="check"
                              boxSize={pxToRem(16)}
                              color="utility.success"
                            />
                          </Box>
                        </Tooltip>
                      )}
                    </>
                  )}
                </HStack>
              );
            },
            header: () => {
              return (
                <Tooltip
                  label={detail.practiceSet.title}
                  openDelay={500}
                  placement="bottom"
                  variant="adminDark"
                >
                  <Box>
                    <Box color="primary.dark-gray" fontSize="sm">
                      {moment(detail.assignment.created_at).format("M/D")}
                    </Box>
                    <Box
                      textOverflow="ellipsis"
                      whiteSpace="normal"
                      overflow="hidden"
                      sx={{
                        lineClamp: 2,
                        WebkitLineClamp: 2,
                        wordBreak: "break-word",
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                      }}
                    >
                      {detail.practiceSet.title}
                    </Box>
                  </Box>
                </Tooltip>
              );
            },
          }
        )
      ),
    ],
    [sortedAssignmentDetails, nameSort, t]
  );

  const table = useReactTable({
    columns,
    data,
    state: {
      sorting,
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    sortingFns: { sortByName },
  });

  return (
    <TableContainer>
      <Table
        __css={{ "table-layout": "fixed", maxW: "full" }}
        variant="adminScoreGrid"
      >
        <colgroup>
          {table.getAllColumns().map((c) => (
            <Box as="col" key={c.id} sx={{ minW: getMinWidths(c.id) }} />
          ))}
        </colgroup>
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                const sortDir = header.column.getIsSorted();

                return (
                  <Th
                    key={header.id}
                    verticalAlign={
                      header.id === "name" || header.id === "overall"
                        ? "bottom"
                        : "top"
                    }
                  >
                    {header.isPlaceholder ? null : (
                      <HStack
                        onClick={header.column.getToggleSortingHandler()}
                        alignItems="center"
                        cursor="pointer"
                        userSelect="none"
                        h="full"
                      >
                        <Box>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </Box>
                        <Box boxSize={pxToRem(16)}>
                          {sortDir && (
                            <Icon
                              icon={
                                sortDir === "asc"
                                  ? "keyboard_arrow_up"
                                  : "keyboard_arrow_down"
                              }
                              boxSize={pxToRem(16)}
                            />
                          )}
                        </Box>
                      </HStack>
                    )}
                  </Th>
                );
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table
            .getRowModel()
            .rows.sort((a, b) => {
              if (a.original.isAggregated) {
                return -1;
              }
              if (b.original.isAggregated) {
                return 1;
              }
              return 0;
            })
            .map((row) => (
              <Tr
                key={row.id}
                backgroundColor={
                  row.original.isAggregated
                    ? "primary.warm-white"
                    : "transparent"
                }
              >
                {row.getVisibleCells().map((cell) => (
                  <Td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
