import { StringMap } from "@esotericsoftware/spine-core";
import {
  ResizeMode,
  SpineCanvas,
  SpineCanvasApp,
  Vector3,
} from "@esotericsoftware/spine-webgl";

import { ISpineStageAsset, IStageCamera } from "../types";

interface ICaptureHandler {
  (canvas: HTMLCanvasElement): void;
}

class SpineStage implements SpineCanvasApp {
  camera: IStageCamera;
  canvas: SpineCanvas | null;
  assets: Array<ISpineStageAsset> = [];
  captureHandler?: ICaptureHandler;
  isCaptureRequested: boolean;

  constructor({ camera }: { camera: IStageCamera }) {
    this.canvas = null;
    this.camera = camera;
    this.isCaptureRequested = false;
  }

  async registerAsset(asset: ISpineStageAsset): Promise<void> {
    if (this.canvas) {
      this.assets.push(asset);
      await asset.loadAssets(this.canvas);
      await asset.initialize(this.canvas);
    } else {
      this.assets.push(asset);
    }

    this.assets = this.assets.sort((a, b) => a.drawOrder - b.drawOrder);
  }

  unregisterAsset(asset: ISpineStageAsset): void {
    this.assets = this.assets.filter((a) => a !== asset);
  }

  loadAssets(): void {
    // no op - attempting to load assets here can result
    // in race condition where assets are registered after load
    // assets is called but before initialize is called
  }

  dispose(): void {
    if (!this.canvas) {
      console.warn("dispose called without canvas");
      return;
    }

    const { assetManager, gl } = this.canvas;

    this.assets.forEach((a) => a.dispose());
    this.assets = [];
    this.canvas = null;

    gl.getExtension("WEBGL_lose_context")?.loseContext();

    assetManager.dispose();
  }

  error(canvas: SpineCanvas, errors: StringMap<string>): void {
    console.log("error:", errors);
  }

  async reload(): Promise<void> {
    if (!this.canvas) return;

    // await this.loadAssets(this.canvas);
    await this.initialize(this.canvas, true);
    await this.render(this.canvas);
  }

  async initialize(canvas: SpineCanvas, isReload?: boolean): Promise<void> {
    if (!isReload) {
      this.canvas = canvas;
      this.setCamera(this.camera);
    }

    await Promise.all(
      this.assets.map(async (a) => {
        await a.loadAssets(canvas);
        await a.initialize(canvas);
      })
    );
  }

  update(canvas: SpineCanvas, delta: number): void {
    if (document.visibilityState !== "visible") return;

    // calculate new viewport size
    canvas.renderer.resize(ResizeMode.Expand);

    // Use the camera viewport and the actual rendered canvas size
    // to translate the local "stage" coordinate system to the
    // Spine world coordinate system.
    const stageToWorld = {
      x:
        canvas.renderer.camera.viewportWidth /
        canvas.htmlCanvas.clientWidth /
        window.devicePixelRatio,
      y:
        canvas.renderer.camera.viewportHeight /
        canvas.htmlCanvas.clientHeight /
        window.devicePixelRatio,
    };

    this.assets.forEach((a) => a.update(canvas, delta, stageToWorld));
  }

  setCamera(camera: IStageCamera): void {
    if (!this.canvas) return;

    this.camera = camera;
    this.canvas.renderer.camera.position = new Vector3(
      camera.x,
      camera.y,
      camera.z
    );
    this.canvas.renderer.camera.zoom = camera.zoom / window.devicePixelRatio;
  }

  onCapture(handler: ICaptureHandler): void {
    this.captureHandler = handler;
  }

  /**
   * Request canvas capture at the end of next render cycle
   */
  requestCapture(): void {
    this.isCaptureRequested = true;
  }

  render(canvas: SpineCanvas): void {
    if (document.visibilityState !== "visible") return;

    const renderer = canvas.renderer;

    renderer.begin();
    this.assets.forEach((a) => {
      if (!a.isInitialized) return;
      a.render(canvas, renderer);
    });
    renderer.end();

    if (this.isCaptureRequested && canvas.assetManager.isLoadingComplete()) {
      this.captureHandler?.(canvas.htmlCanvas);
      this.isCaptureRequested = false;
    }
  }
}

export { SpineStage };
