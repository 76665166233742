import {
  Box,
  Checkbox,
  Collapse,
  HStack,
  Select,
  Spacer,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";

import { Button } from "adminComponents/atoms/Button";
import { Card } from "adminComponents/atoms/Card";
import { FormLabel } from "adminComponents/atoms/FormLabel";
import { Icon } from "adminComponents/atoms/Icon";
import { Input } from "adminComponents/atoms/Input";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils";
import { LLMModel } from "links/lib/features/llm/usePerformLLMChat";

import { PostStepAction } from "../types";

export interface IProps {
  index: number;
  onSelect?: () => void;
  selected: boolean;
  onRemove?: () => void;
  onChat?: () => void;
  onNext?: () => void;
  onPrevious?: () => void;
  onParse?: () => void;
}

export const StepCard: React.FC<IProps> = ({
  index,
  onSelect,
  selected,
  onRemove,
  onChat,
  onNext,
  onPrevious,
  onParse,
}) => {
  const { register, watch } = useFormContext();
  const [isPromptOpen, setIsPromptOpen] = useState(!!onSelect);

  const step = watch().steps[index];

  const results = useMemo(() => {
    try {
      return JSON.stringify(JSON.parse(step.results), undefined, 2);
    } catch (e) {
      return step.results;
    }
  }, [step.results]);

  return (
    <Card
      borderColor={selected ? "primary.golden" : "primary.tan"}
      backgroundColor="primary.white"
      variant="adminCardMediumBorder"
      onClick={onSelect}
      _hover={{
        cursor: "pointer",
      }}
    >
      <VStack w="full" alignItems="flex-start">
        <HStack alignItems="center" w="full">
          <Box w={pxToRem(200)}>
            {onSelect && (
              <HStack>
                <FormLabel>Name</FormLabel>
                <Input {...register(`steps.${index}.name`)} />
              </HStack>
            )}
            {!onSelect && <Text>{step.name}</Text>}
          </Box>
          <Spacer />
          <HStack alignItems="center">
            {onSelect && (
              <Checkbox
                variant="adminCheckboxWhite"
                {...register(`steps.${index}.autoAdvanceToNextStep`)}
              >
                <Text>Auto Advance</Text>
              </Checkbox>
            )}
            {!onSelect && (
              <Text>
                Auto Advance: {step.autoAdvanceToNextStep ? "true" : "false"}
              </Text>
            )}
          </HStack>
          <Spacer />
          <Box maxW={pxToRem(200)}>
            {onSelect && (
              <HStack alignItems="center">
                <Select
                  color="black"
                  {...register(`steps.${index}.postStepAction`)}
                >
                  {Object.values(PostStepAction).map((v) => (
                    <option key={v} value={v}>
                      {v}
                    </option>
                  ))}
                </Select>
              </HStack>
            )}
            {!onSelect && <Text>Post Step: {step.postStepAction}</Text>}
          </Box>
          <Spacer />
          <Box maxW={pxToRem(200)}>
            {onSelect && (
              <HStack alignItems="center">
                <Select color="black" {...register(`steps.${index}.llmModel`)}>
                  {Object.values(LLMModel).map((v) => (
                    <option key={v} value={v}>
                      {v}
                    </option>
                  ))}
                </Select>
              </HStack>
            )}
            {!onSelect && <Text>LLM Model: {step.llmModel}</Text>}
          </Box>
        </HStack>

        <HStack onClick={() => setIsPromptOpen((isOpen) => !isOpen)} w="100%">
          <Text>Prompt</Text>
          <Icon
            alignSelf="center"
            mr={pxToRem(6)}
            boxSize={pxToRem(18)}
            icon={isPromptOpen ? "keyboard_arrow_up" : "keyboard_arrow_down"}
          />
        </HStack>
        <Box as={Collapse} in={isPromptOpen} w="full">
          <Textarea color="black" {...register(`steps.${index}.prompt`)} />
        </Box>
        {index === 0 && (
          <>
            <Text>First Step Input</Text>
            <Textarea
              color="black"
              {...register(`steps.${index}.extraInput`)}
            />
          </>
        )}
        {step.results && (
          <>
            <Text>Results</Text>
            <Textarea
              color="black"
              {...register(`steps.${index}.results`)}
              value={results}
            />
          </>
        )}
        {onRemove && (
          <HStack w="full" justifyContent="flex-end">
            <Button
              variant="adminButtonFilled"
              onClick={(e) => {
                e.stopPropagation();
                onRemove();
              }}
            >
              Remove
            </Button>
          </HStack>
        )}
        <HStack>
          {onChat && selected && (
            <Button variant="adminButtonFilled" onClick={onChat}>
              {step.results ? "Run Again" : "Run"}
            </Button>
          )}
          {step.results && onNext && selected && (
            <Button variant="adminButtonFilled" onClick={onNext}>
              Next
            </Button>
          )}
          {onPrevious && selected && (
            <Button variant="adminButtonFilled" onClick={onPrevious}>
              Previous
            </Button>
          )}
          {step.results && onParse && selected && (
            <Button variant="adminButtonFilled" onClick={onParse}>
              Parse
            </Button>
          )}
        </HStack>
      </VStack>
    </Card>
  );
};
