import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useSession } from "links/lib/contexts/session";
import { useSessionGameState } from "links/lib/contexts/sessionGameState";
import { useSessionGroups } from "links/lib/contexts/sessionGroups";
import { useSessionRoundState } from "links/lib/contexts/sessionRoundState";
import { useSessionScene } from "links/lib/contexts/sessionScene";
import {
  ISessionUser,
  SessionGameType,
  SessionScene,
  SessionType,
} from "links/lib/types";
import { useGroupAnswers } from "sessionComponents/hooks/useGroupAnswers";
import { useGroupConnectedUsers } from "sessionComponents/hooks/useGroupConnectedUsers";
import { useRenderVariantMessage } from "sessionComponents/hooks/useRenderVariantMessage";
import { QuickPlayCarePackageRoundInterstitial } from "sessionComponents/molecules/QuickPlayCarePackageRoundInterstitial";
import { StudentRoundReviewInterstitial } from "sessionComponents/molecules/StudentRoundReviewInterstitial";
import { TextInterstitial } from "sessionComponents/molecules/TextInterstitial";

export enum SplashType {
  StudentReview,
  TeacherReview,
  RoundStart,
}

interface IStudentRoundReviewSplashProps {
  student?: ISessionUser;
}

interface ITextSplashProps {
  animationContainerStyle?: { [key: string]: unknown };
}

export interface ISplashSceneProps
  extends IStudentRoundReviewSplashProps,
    ITextSplashProps {
  splashType: SplashType;
}

export const SplashScene: React.FC<ISplashSceneProps> = ({
  student,
  splashType,
  animationContainerStyle,
}) => {
  let splash = <></>;

  const studentSplash = useMemo(
    () => <StudentRoundReviewSplash student={student} />,
    [student]
  );

  switch (splashType) {
    case SplashType.StudentReview:
      splash = studentSplash;
      break;
    case SplashType.TeacherReview:
      splash = (
        <TeacherRoundReviewSplash
          animationContainerStyle={animationContainerStyle}
        />
      );
      break;
    case SplashType.RoundStart:
      splash = (
        <RoundStartSplash animationContainerStyle={animationContainerStyle} />
      );
      break;
  }

  return <>{splash}</>;
};

const RoundStartSplash: React.FC<ITextSplashProps> = ({
  animationContainerStyle,
}) => {
  const roundState = useSessionRoundState();
  const gameState = useSessionGameState();
  const scene = useSessionScene();
  const session = useSession();
  const { variant, splash_animation_name } = roundState;

  const variantMessage = useRenderVariantMessage({
    variant,
    scene,
    roundState,
  });
  const { t } = useTranslation("session", { useSuspense: false });
  const hideSecondaryText =
    scene === SessionScene.VotingResults || scene === SessionScene.VotingAwards;

  if (
    gameState?.game_type === SessionGameType.QuickPlay &&
    gameState.quick_play_state.care_package_rounds.includes(
      roundState.round_number
    )
  ) {
    return <QuickPlayCarePackageRoundInterstitial />;
  }

  return (
    <TextInterstitial
      splashAnimationName={splash_animation_name}
      mainText={variantMessage}
      secondaryText={
        hideSecondaryText
          ? ""
          : `${t("round.introQuestionPrefix")} #${roundState.round_number}`
      }
      containerStyle={animationContainerStyle}
      pattern={session?.practice_set_cover_image_bg_pattern}
    />
  );
};

const TeacherRoundReviewSplash: React.FC<ITextSplashProps> = ({
  animationContainerStyle,
}) => {
  const scene = useSessionScene();
  const roundState = useSessionRoundState();
  const session = useSession();
  const { t } = useTranslation("session", { useSuspense: false });

  const { splash_animation_name } = roundState;

  const isDrawVotingAwardsRound = scene === SessionScene.VotingAwards;

  const prefix = isDrawVotingAwardsRound
    ? "votingAwardsSplashText"
    : "teacherSplashText";

  return (
    <TextInterstitial
      splashAnimationName={splash_animation_name}
      mainText={t(`roundReview.${prefix}`)}
      containerStyle={animationContainerStyle}
      pattern={session?.practice_set_cover_image_bg_pattern}
    />
  );
};

const StudentRoundReviewSplash: React.FC<IStudentRoundReviewSplashProps> = ({
  student,
}) => {
  const studentUser = student as ISessionUser;
  const sessionGroups = useSessionGroups();
  const gameState = useSessionGameState();
  const groupAnswers = useGroupAnswers(studentUser);
  const session = useSession();
  const groupUsers = useGroupConnectedUsers(studentUser.session_group_id);
  const sessionRoundState = useSessionRoundState();
  const group = sessionGroups?.[studentUser.session_group_id];
  const isClassSession = session?.session_type === SessionType.Class;

  const isQuickPlayCarePackageRound =
    gameState?.game_type === SessionGameType.QuickPlay &&
    gameState.quick_play_state.care_package_rounds.includes(
      sessionRoundState.round_number
    );
  const perfectScore = groupAnswers.every((a) => a.hasOutcome && a.isCorrect);

  const { variant } = sessionRoundState;
  return (
    <>
      {group && (
        <StudentRoundReviewInterstitial
          outerGame={gameState?.game_type}
          earnedQuickPlayCarePackage={
            isQuickPlayCarePackageRound && perfectScore
          }
          student={studentUser}
          group={group}
          groupAnswers={groupAnswers}
          groupUsers={groupUsers}
          isClassSession={isClassSession}
          variant={variant}
          pattern={session?.practice_set_cover_image_bg_pattern}
        />
      )}
    </>
  );
};
