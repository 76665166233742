import moment from "moment";
import { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import { useFetchClassroomAssignments } from "links/lib/features/classrooms";
import { useFetchSessions } from "links/lib/features/sessions";
import { IAssignment, IPracticeSetSession } from "links/lib/types";

interface IUseFetchPastSessionsAndAssignmentsParams {
  classroom_id: string;
  practice_set_id: string;
}

interface IUseFetchPastSessionsAndAssignmentsResponse {
  pastAssignments: Array<IAssignment>;
  specificAssignment?: IAssignment;
  setSpecificAssignment: (assignment?: IAssignment) => void;
  pastSessions: Array<IPracticeSetSession>;
  specificSession?: IPracticeSetSession;
  setSpecificSession: (practiceSetSession?: IPracticeSetSession) => void;
}

export const useFetchPastSessionsAndAssignments = ({
  classroom_id,
  practice_set_id,
}: IUseFetchPastSessionsAndAssignmentsParams): IUseFetchPastSessionsAndAssignmentsResponse => {
  const location: {
    state?: { assignmentId?: string; practiceSetSessionId?: string };
  } = useLocation();

  const [assignmentId, setAssignmentId] = useState(
    location.state?.assignmentId
  );
  const [practiceSetSessionId, setPracticeSetSessionId] = useState(
    location.state?.practiceSetSessionId
  );

  const [now] = useState(moment());
  const [start, end] = useMemo(() => {
    return [
      now.clone().subtract(1, "year").toDate(),
      now.clone().subtract(1, "minute").toDate(),
    ];
  }, [now]);
  const pastAssignmentsFetch = useFetchClassroomAssignments({
    classroom_id,
    practice_set_id,
    start_date: start,
    end_date: end,
    sort_desc: true,
    limit: 10,
    keepPreviousData: true,
  });
  const pastSessionsFetch = useFetchSessions({
    group_id: classroom_id,
    practice_set_id,
    start_time: start,
    keepPreviousData: true,
    limit: 10,
  });

  const specificAssignment = useMemo(() => {
    return pastAssignmentsFetch.data?.assignments.find(
      (a) => a.id === assignmentId
    );
  }, [assignmentId, pastAssignmentsFetch.data?.assignments]);
  const setSpecificAssignment = (assignment?: IAssignment) => {
    setAssignmentId(assignment?.id);
  };

  const specificSession = useMemo(() => {
    return pastSessionsFetch.data?.sessions.find(
      (a) => a.id === practiceSetSessionId
    );
  }, [pastSessionsFetch.data?.sessions, practiceSetSessionId]);
  const setSpecificSession = (session?: IPracticeSetSession) => {
    setPracticeSetSessionId(session?.id);
  };

  return {
    pastAssignments: pastAssignmentsFetch.data?.assignments || [],
    specificAssignment,
    setSpecificAssignment,
    pastSessions: pastSessionsFetch.data?.sessions || [],
    specificSession,
    setSpecificSession,
  };
};
