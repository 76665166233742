import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { animated, config, useSpring } from "react-spring";

import { pxToRem } from "adminComponents/utils";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";

export interface IPodiumSummaryStatProps {
  show: boolean;
  start: number;
  end: number;
  label: string;
  animConfig?: {
    tension: number;
    friction: number;
  };
  icon?: React.ReactNode;
  handleStatStringify?: (stat: number) => string;
  handleComplete: () => void;
}

const AnimatedText = animated(Text);

const defaultConfig = config.molasses;

const defaultStatStringify = (stat: number) => stat.toFixed(0).toString();

export const PodiumSummaryStat: React.FC<IPodiumSummaryStatProps> = ({
  show,
  start,
  end,
  label,
  animConfig,
  icon,
  handleStatStringify,
  handleComplete,
}) => {
  const { match: breakpoints } = useBreakpoints();

  const statSpring = useSpring({
    from: {
      stat: start,
    },
    to: {
      stat: show ? end : start,
    },
    config: animConfig ?? defaultConfig,
    onRest: handleComplete,
  });

  return (
    <VStack>
      <HStack>
        <Box position="relative">
          {/* Hidden text prevents the width from changing as it is animating */}
          <Text
            textStyle="gameDisplay"
            color="podium.summaryStat"
            fontSize={pxToRem(breakpoints.fontSize)}
            fontWeight="700"
            opacity={0}
          >
            {end}
          </Text>
          <AnimatedText
            textStyle="gameDisplay"
            color="podium.summaryStat"
            fontSize={pxToRem(breakpoints.fontSize)}
            fontWeight="700"
            position="absolute"
            display="block"
            top="0"
            left="0"
          >
            {statSpring.stat.to(handleStatStringify ?? defaultStatStringify)}
          </AnimatedText>
        </Box>

        {icon}
      </HStack>
      <AnimatedText
        textStyle="gameDisplay"
        fontSize={pxToRem(breakpoints.fontSize * 0.8)}
        fontWeight="700"
      >
        {label}
      </AnimatedText>
    </VStack>
  );
};
