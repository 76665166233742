import { keyframes } from "@chakra-ui/react";

import { colors } from "adminComponents/theme/colors";
import { pxToRem } from "adminComponents/utils/pxToRem";

const blink = keyframes`
  from {opacity: 1;}
  to {opacity: 0}
`;
const blinkAnimation = `1s cubic-bezier(.8,-0.01,.3,1.05) infinite alternate ${blink}`;

export const AdminLiveClassroomBanner = {
  baseStyle: ({
    isTransparent,
    noXPadding,
  }: {
    isTransparent?: boolean;
    noXPadding?: boolean;
  }): Record<string, unknown> => ({
    banner: {
      backgroundColor: isTransparent ? "white" : "primary.golden-light",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
      paddingX: noXPadding ? [null] : [pxToRem(14), null, pxToRem(40)],
      paddingY: pxToRem(14),
      borderRadius: pxToRem(8),
    },
    container: {
      alignItems: "center",
      gap: pxToRem(8),
    },
    classroom: {
      backgroundColor: "white",
      paddingX: pxToRem(12),
      paddingY: pxToRem(7),
      borderRadius: pxToRem(8),
      whiteSpace: "nowrap",
    },
    liveIcon: {
      animation: blinkAnimation,
      "@media (prefers-reduced-motion)": {
        animation: "none",
      },
      backgroundColor: colors.utility["question-red"],
      borderRadius: "100%",
      height: pxToRem(12),
      width: pxToRem(12),
    },
  }),
};
