import { Box, Flex, Spacer, Text } from "@chakra-ui/react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useMount, useTimeout, useUnmount } from "react-use";

import { pxToRem } from "adminComponents/utils";
import {
  INITIAL_CURTAIN_REVIEW_TIMING_SECONDS,
  sessionCodeSessionKey,
} from "lib/constants";
import { useSessionGroups } from "links/lib/contexts/sessionGroups";
import { SessionGameType } from "links/lib/types";
import { QuickPlayBackground } from "sessionComponents/atoms/QuickPlayBackground";
import { StageCurtains } from "sessionComponents/atoms/StageCurtains";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { useStudent } from "sessionComponents/contexts/student";
import { StudentReactionsPicker } from "sessionComponents/molecules/StudentReactionsPicker";
import { StudentFinalPodiumView } from "sessionComponents/organisms/StudentFinalPodiumView";
import { TeacherFinalPodiumView } from "sessionComponents/organisms/TeacherFinalPodiumView";
import { WinningTeamCarousel } from "sessionComponents/organisms/WinningTeamCarousel";
import { TeacherSessionSoundEffect } from "sessionComponents/types";
import { getAssetColorScheme } from "sessionComponents/utils/getAssetColorScheme";
import { getFormattedGroupName } from "sessionComponents/utils/getFormattedGroupName";
import { useSessionAudio } from "sharedComponents/contexts/sessionAudio";
import { useSafeConnectedUserArray } from "sharedComponents/hooks/useSafeConnectedUserArray";
import { useSafeGroupsArray } from "sharedComponents/hooks/useSafeGroupsArray";

export interface IPodiumProps {
  isTeacher: boolean;
  outerGame?: SessionGameType;
}

/**
 * The Podium component is responsible for displaying:
 * 1. A curtain reveal for a short time (aka Drumroll)
 * 2. The winning team's avatars + name + score for a short time
 * 3.a. For students: their progress towards leveling up, care packages, challenges, etc.
 * 3.b. For teachers: a scoreboard of all team names + scores + avatars
 */
export const Podium: React.FC<IPodiumProps> = ({
  isTeacher,
  outerGame = SessionGameType.TheBigBoard,
}) => {
  const { t } = useTranslation("session", {
    keyPrefix: "podium",
    useSuspense: false,
  });
  const {
    play: playAudio,
    setSpriteVolume,
    setBackgroundAudio,
  } = useSessionAudio();
  const history = useHistory();
  const { fontSize2x, match: currentBreakpoints } = useBreakpoints();

  useMount(() => {
    setBackgroundAudio();
    sessionStorage.removeItem(sessionCodeSessionKey);

    // add hash for appcues targeting
    history.push("/session#podium");

    if (!isTeacher) return;
    const playId = playAudio(TeacherSessionSoundEffect.Drumroll);
    setSpriteVolume(playId, 1);
  });

  useUnmount(() => {
    if (curtainCloseTimeout.current) {
      clearTimeout(curtainCloseTimeout.current);
    }
  });

  const [isWinnersView, setIsWinnersView] = useState(true);
  const [isCurtainClosed, setIsCurtainClosed] = useState(false);
  const curtainCloseTimeout = useRef<NodeJS.Timeout | undefined>(undefined);
  const connectedUsers = useSafeConnectedUserArray();
  const student = useStudent();
  const groupMap = useSessionGroups();
  const groups = useSafeGroupsArray();
  const sortedGroups = useMemo(() => {
    return groups.sort(
      (a, b) =>
        b.total_awarded_points +
        b.total_bonus_points_between_leader_boards -
        (a.total_awarded_points + a.total_bonus_points_between_leader_boards)
    );
  }, [groups]);

  const highestScore =
    sortedGroups[0]?.total_awarded_points +
    sortedGroups[0]?.total_bonus_points_between_leader_boards;

  //get all teams tied for highest score and format for display
  const winningGroups = sortedGroups
    .filter(
      (group) =>
        group.total_awarded_points +
          group.total_bonus_points_between_leader_boards ===
        highestScore
    )
    .map((group) => ({
      name: getFormattedGroupName(group),
      colorScheme: getAssetColorScheme(group.color_scheme),
      members: connectedUsers.filter(
        (u) => groupMap[u.session_group_id]?.id === group.id
      ),
      score:
        group.total_awarded_points +
        group.total_bonus_points_between_leader_boards,
      key: group.id,
    }));

  const [drumrollTimeout] = useTimeout(INITIAL_CURTAIN_REVIEW_TIMING_SECONDS);
  const isFinalViewCurtainOpen = !isWinnersView || false;

  const isDrumroll = !drumrollTimeout();
  const isWinningTeamVisible = !isDrumroll && isWinnersView;

  // play sound effect when winning team view is shown
  useEffect(() => {
    if (isTeacher && isWinningTeamVisible && !isCurtainClosed) {
      playAudio(TeacherSessionSoundEffect.WinnerReveal);
    }
  }, [isTeacher, isCurtainClosed, isWinningTeamVisible, playAudio]);

  const isStageCurtainOpen =
    isFinalViewCurtainOpen || (isWinningTeamVisible && !isCurtainClosed);
  const stageCurtainMessage = !isWinningTeamVisible ? t("curtainMessage") : "";

  const handleWinningTeamCarouselComplete = () => {
    setIsCurtainClosed(true);
    if (isQuickPlay) {
      setIsWinnersView(false);
    } else {
      // for big board, let the curtains close before continuing to next screen
      curtainCloseTimeout.current = setTimeout(
        () => setIsWinnersView(false),
        2000
      );
    }
  };

  const getPodiumScreenContent = () => {
    // show the winning team briefly before showing the final podium view
    if (isWinnersView) {
      if (!isWinningTeamVisible) return <></>;

      return (
        <WinningTeamCarousel
          outerGame={outerGame}
          userId={student.id}
          showPlus={false}
          showNameFirst
          showTrophy
          winningTeams={winningGroups}
          handleComplete={handleWinningTeamCarouselComplete}
          footer={
            isTeacher || !isStageCurtainOpen ? undefined : (
              <Box
                position="fixed"
                bottom={0}
                left={0}
                width="full"
                zIndex={100}
              >
                <StudentReactionsPicker />
              </Box>
            )
          }
        />
      );
    } else {
      return isTeacher ? (
        <TeacherFinalPodiumView outerGame={outerGame} />
      ) : (
        <StudentFinalPodiumView outerGame={outerGame} />
      );
    }
  };

  const isQuickPlay = outerGame === SessionGameType.QuickPlay;

  return (
    <>
      {!isQuickPlay && (
        <StageCurtains
          message={stageCurtainMessage}
          isOpen={isStageCurtainOpen}
        >
          {getPodiumScreenContent()}
        </StageCurtains>
      )}
      {isQuickPlay && isDrumroll && (
        <QuickPlayBackground>
          <Flex w="full" h="full" flexDir="column" textAlign="center">
            <Spacer />
            <Box
              w="full"
              bgColor="quickPlay.background"
              p={pxToRem(currentBreakpoints.padding)}
            >
              <Text textStyle="gameDisplay" fontSize={pxToRem(fontSize2x)}>
                {stageCurtainMessage}
              </Text>
            </Box>
            <Spacer />
          </Flex>
        </QuickPlayBackground>
      )}
      {isQuickPlay && !isDrumroll && getPodiumScreenContent()}
    </>
  );
};
