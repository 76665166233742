import { ButtonGroup, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Button } from "adminComponents/atoms/Button";
import { Modal } from "adminComponents/atoms/Modal";
import { pxToRem } from "adminComponents/utils/pxToRem";

export interface IGuestSignInModalProps {
  signInLinkTo: string;
  isOpen: boolean;
}

const GuestSignInModal: React.FC<IGuestSignInModalProps> = ({
  signInLinkTo,
  isOpen,
}) => {
  const { t } = useTranslation("admin", {
    keyPrefix: "guestSignInModal",
    useSuspense: false,
  });

  return (
    <Modal
      title={t("modalTitle")}
      isOpen={isOpen}
      hideClose={true}
      onClose={() => {
        // No-op - should not allow close
      }}
    >
      <VStack spacing={pxToRem(24)} alignItems="left">
        <Text variant="adminP1">{t("instructionText")}</Text>
        <ButtonGroup>
          <Button variant="adminButtonFilled" as={Link} to={signInLinkTo}>
            {t("signInButton")}
          </Button>
        </ButtonGroup>
      </VStack>
    </Modal>
  );
};

export { GuestSignInModal };
