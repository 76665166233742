import { Portal } from "@chakra-ui/react";
import React from "react";
import { DragLayerMonitor, useDragLayer } from "react-dnd";

export const DragItemPreview: React.FC = ({ children }) => {
  const { isDragging, currentOffset } = useDragLayer(
    (monitor: DragLayerMonitor) => {
      return {
        isDragging: monitor.isDragging(),
        currentOffset: monitor.getSourceClientOffset(),
        item: monitor.getItem(),
      };
    }
  );

  return (
    <Portal>
      {isDragging && currentOffset ? (
        <div
          style={{
            transform: `translate(${currentOffset.x}px, ${currentOffset.y}px)`,
            position: "fixed",
            top: 0,
            left: 0,
            pointerEvents: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 2,
          }}
        >
          {children}
        </div>
      ) : null}
    </Portal>
  );
};
