import { useEffect, useState } from "react";
import { usePrevious } from "react-use";

import {
  ITableData,
  ITableSort,
} from "adminComponents/molecules/SortableTable";
import { useErrorToast } from "adminComponents/utils/toast";
import { useFetchAverageUserAccuracies } from "links/lib/features/classrooms";
import { IUseFetchClassroomRecentPracticeSetResponsesResult } from "links/lib/features/classrooms/useFetchClassroomRecentPracticeSetResponses";
import { IFetchClassroomUsersResponse } from "links/lib/features/classrooms/useFetchClassroomUsers";
import { IFetchPracticeSetItemsResponse } from "links/lib/features/practiceSetItems/useFetchPracticeSetItems";
import { IFetchPracticeSetResponse } from "links/lib/features/practiceSets/useFetchPracticeSet";
import {
  getStudentTableData,
  handleSortStudents,
} from "screens/TeacherClassrooms/PracticeSetDetail/utils/studentDataHelper";

interface IUseStudentsDataParams {
  classroomPracticeSetResponses?: IUseFetchClassroomRecentPracticeSetResponsesResult;
  classroomStudentsData?: IFetchClassroomUsersResponse;
  practiceSetData?: IFetchPracticeSetResponse;
  practiceSetItemsData?: IFetchPracticeSetItemsResponse;
  classroomId: string;
  practiceSetId: string;
  assignmentId?: string;
  practiceSetSessionId?: string;
}

interface IUseStudentsDataResponse {
  studentsData: ITableData;
  handleChangeSort: (sort: ITableSort) => void;
  isLoading: boolean;
}

export const useStudentsData = ({
  classroomPracticeSetResponses,
  classroomStudentsData,
  practiceSetData,
  practiceSetItemsData,
  classroomId,
  practiceSetId,
  assignmentId,
  practiceSetSessionId,
}: IUseStudentsDataParams): IUseStudentsDataResponse => {
  const [studentsData, setStudentsData] = useState<{
    isSet: boolean;
    data: ITableData;
  }>({ isSet: false, data: [[]] });

  const {
    data: classroomPracticeSetUserAccuracyData,
    isLoading,
    error: classroomPracticesetUserAccuracyError,
  } = useFetchAverageUserAccuracies({
    classroom_id: classroomId,
    practice_set_id: practiceSetId,
    assignment_id: assignmentId,
    practice_set_session_id: practiceSetSessionId,
  });

  useErrorToast(classroomPracticesetUserAccuracyError);

  const prevAssignmentId = usePrevious(assignmentId);
  const prevPracticeSetSessionId = usePrevious(practiceSetSessionId);
  useEffect(() => {
    if (
      prevAssignmentId !== assignmentId ||
      prevPracticeSetSessionId !== practiceSetSessionId
    ) {
      setStudentsData((studentsData) => ({ ...studentsData, isSet: false }));
    }
  }, [
    assignmentId,
    practiceSetSessionId,
    prevAssignmentId,
    prevPracticeSetSessionId,
  ]);
  useEffect(() => {
    if (
      classroomPracticeSetUserAccuracyData &&
      classroomStudentsData &&
      practiceSetData &&
      classroomPracticeSetResponses &&
      practiceSetItemsData &&
      !studentsData.isSet
    ) {
      setStudentsData({
        isSet: true,
        data: handleSortStudents(
          getStudentTableData({
            practiceSetId,
            classroomPracticeSetUserAccuracyData,
            classroomStudentsData,
            classroomPracticeSetResponses,
            totalConsideredPracticeSetItems:
              practiceSetData.practice_set.item_count,
            assignmentId,
            practiceSetSessionId,
          }),
          // Initial sort by given name, ascending
          { columnIndex: 0, isAscending: true }
        ),
      });
    }
  }, [
    classroomPracticeSetUserAccuracyData,
    classroomStudentsData,
    studentsData,
    practiceSetData,
    classroomPracticeSetResponses,
    practiceSetItemsData,
    practiceSetId,
    assignmentId,
    practiceSetSessionId,
  ]);

  const handleChangeSort = (sort: ITableSort) => {
    setStudentsData({
      isSet: true,
      data: handleSortStudents(studentsData.data, sort),
    });
  };

  return {
    handleChangeSort,
    studentsData: studentsData.data,
    isLoading: !studentsData.isSet || isLoading,
  };
};
