import {
  Box,
  BoxProps,
  ButtonProps,
  Tag as ChakraTag,
  TagLabel,
  TagLeftIcon,
  TagRightIcon,
} from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { ColorScheme, IconType } from "adminComponents";
import { Icon } from "adminComponents/atoms/Icon";
import { IconCTAButton } from "adminComponents/atoms/IconCTAButton";
import { IconTooltip } from "adminComponents/atoms/IconTooltip";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils/pxToRem";

export type TagColor =
  | "blue"
  | "dark-gray"
  | "purple"
  | "green"
  | "red"
  | "tan"
  | "white"
  | "default"
  | "green-grey"
  | "light-blue"
  | "orange";

interface IProps extends BoxProps {
  colorScheme?: TagColor | ColorScheme;
  variant?: "adminSolid" | "adminOutline";
  leftIcon?: IconType;
  decorativeIcon?: boolean;
  rightIcon?: IconType;
  children: ReactNode;
  tooltip?: string | ReactNode;
  usePortal?: boolean;
  handleClickRightIcon?: () => void;
}

const tagColorPalette: Record<TagColor, ColorScheme> = {
  blue: "utility.badge-blue",
  "dark-gray": "utility.badge-dark-gray",
  purple: "utility.badge-purple",
  green: "utility.badge-green",
  red: "utility.badge-red",
  tan: "primary.tan",
  white: "primary.white",
  orange: "utility.badge-orange",
  "light-blue": "utility.badge-light-blue",
  "green-grey": "utility.badge-green-grey",
  default: "primary.light-gray",
};

const TagRightIconWrapper: React.FC<ButtonProps> = ({ onClick }) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  return (
    <Box>
      <IconCTAButton
        ariaLabel={t("common.remove")}
        boxSize={24}
        iconSize={12}
        onClick={onClick}
      />
    </Box>
  );
};

export const Tag: React.FC<IProps> = ({
  children,
  colorScheme = "default",
  leftIcon,
  decorativeIcon = false,
  handleClickRightIcon,
  rightIcon,
  tooltip,
  variant = "adminSolid",
  color: textColor,
  usePortal,
  ...rest
}) => {
  const isFilled = variant === "adminSolid";
  const color = tagColorPalette[colorScheme as TagColor] || colorScheme;

  return (
    <ChakraTag
      {...rest}
      background={isFilled ? color : "transparent"}
      borderColor={isFilled ? "transparent" : color}
      variant={variant}
    >
      {leftIcon && (
        <TagLeftIcon
          as={() => (
            <Icon
              as="span"
              height={pxToRem(16)}
              icon={leftIcon}
              width={pxToRem(16)}
              decorative={decorativeIcon}
              color={textColor}
            />
          )}
        />
      )}
      <TagLabel>
        <Text
          as="span"
          variant="adminP2"
          overflow="hidden"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          color={textColor}
        >
          {children}
        </Text>
      </TagLabel>
      {rightIcon && (
        <TagRightIcon as={TagRightIconWrapper} onClick={handleClickRightIcon} />
      )}
      {tooltip && <IconTooltip usePortal={usePortal}>{tooltip}</IconTooltip>}
    </ChakraTag>
  );
};
