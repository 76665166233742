import {
  Box,
  FormLabel as ChakraFormLabel,
  FormLabelProps as IProps,
  useStyleConfig,
} from "@chakra-ui/react";
import React from "react";

import { Text } from "adminComponents/atoms/Text";

const FormLabel: React.FC<IProps> = ({ variant, children, ...rest }) => {
  const styles = useStyleConfig("AdminFormLabel", { variant });

  return (
    <ChakraFormLabel {...rest}>
      <Box __css={styles}>
        <Text as="div" variant="adminP2" color="inherit">
          {children}
        </Text>
      </Box>
    </ChakraFormLabel>
  );
};

FormLabel.defaultProps = {
  variant: "adminFormLabel",
};

export { FormLabel };
