import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useInterval } from "react-use";

import { isUserTypingInterval } from "links/lib/constants";
import { useShortName } from "links/lib/hooks/useShortName";
import { PracticeSessionItemVariantType } from "links/lib/types";
import { UserInResponse } from "sessionComponents/types";

import { GenericTextOption } from "../GenericTextOption";

interface IGroupMemberOptionProps {
  user: UserInResponse;
  submitted: boolean;
  lastActiveTyping?: Date;
}

const hasTypedWithinInterval = (lastActiveTyping: Date) =>
  Date.now() - lastActiveTyping.valueOf() < isUserTypingInterval;

export const GroupMemberInputOption: React.FC<IGroupMemberOptionProps> = ({
  user,
  submitted,
  lastActiveTyping,
}) => {
  const [isUserTyping, setIsUserTyping] = useState<boolean>(false);
  const { t } = useTranslation("session", { useSuspense: false });

  useEffect(() => {
    if (lastActiveTyping) {
      setIsUserTyping(hasTypedWithinInterval(lastActiveTyping));
    }
  }, [lastActiveTyping]);

  useInterval(
    () => {
      if (lastActiveTyping) {
        setIsUserTyping(hasTypedWithinInterval(lastActiveTyping));
      }
    },
    // setting delay to null pauses interval
    isUserTyping ? isUserTypingInterval / 2 : null
  );

  const userEnteringMessage = t("textResponseInput.userEnteringMessage", {
    name: useShortName(user.name),
  });

  const userSubmittedMessage = t("textResponseInput.userSubmittedMessage", {
    name: useShortName(user.name),
  });

  const userWaitingMessage = t("textResponseInput.userWaitingMessage", {
    name: useShortName(user.name),
  });

  return (
    <GenericTextOption
      users={[user]}
      fontColor={submitted ? undefined : "primary.medium-gray"}
      backgroundColor="primary.warm-white"
      text={
        isUserTyping
          ? userEnteringMessage
          : submitted
          ? userSubmittedMessage
          : userWaitingMessage
      }
      iconColor="utility.link"
      variant={PracticeSessionItemVariantType.CoopTextMatch}
      showTextInputIcon={submitted}
      showAsSelectable
    />
  );
};
