export default {
  secondary: {
    50: "#CBD5E0",
    100: "#CBD5E0",
    200: "#CBD5E0",
    300: "#CBD5E0",
    400: "#A0AEC0",
    500: "#718096",
    600: "#4A5568",
    700: "#2D3748",
    800: "#1A202C",
    900: "#171923",
  },
  pepto: {
    "02": "#F1D2F0",
    "03": "#E961B1",
    "04": "#A8367F",
    "light-shadow-02": "#FBD6EE",
    "light-shadow-01": "#FDE5F4",
    light: "#FEF4FA",
    "base-highlight-02": "#F9C8E8",
    "base-highlight-01": "#F49DD4",
    base: "#E961B1",
    "base-shadow-01": "#E961B1",
    "base-shadow-02": "#D12A77",
    dark: "#9A285E",
    "dark-shadow-01": "#7E2751",
    "dark-shadow-02": "#622644",
  },
  tangerine: {
    "01": "#FFBE7B",
    "02": "#F1A83F",
    "03": "#FA553A",
    "04": "#C74E1C",
    "light-shadow-02": "#FDECC7",
    "light-shadow-01": "#FEF3DC",
    light: "#FEFAF0",
    "base-highlight-02": "#FCE6B4",
    "base-highlight-01": "#F8CF7F",
    base: "#F1A83F",
    "base-shadow-01": "#EA8B27",
    "base-shadow-02": "#E06B16",
    dark: "#A45618",
    "dark-shadow-01": "#864C19",
    "dark-shadow-02": "#69421B",
  },
  amber: {
    "light-shadow-02": "#F4CEAB",
    "light-shadow-01": "#F8E0CA",
    light: "#FCF2E9",
    "base-highlight-02": "#F0BE8F",
    "base-highlight-01": "#E18D50",
    base: "#C74E1C",
    "base-shadow-01": "#B13310",
    "base-shadow-02": "#961F08",
    dark: "#6F200E",
    "dark-shadow-01": "#5C2110",
    "dark-shadow-02": "#492115",
  },
  tomato: {
    "light-shadow-02": "#FED1C4",
    "light-shadow-01": "#FFE2DA",
    light: "#FFF3EF",
    "base-highlight-02": "#FEC2B0",
    "base-highlight-01": "#FD937A",
    base: "#FA553A",
    "base-shadow-01": "#E53023",
    "base-shadow-02": "#DD1814",
    dark: "#A21B17",
    "dark-shadow-01": "#851D18",
    "dark-shadow-02": "#671E1A",
  },
  golden: {
    "01": "#FFF9DE",
    "02": "#FED925",
    "03": "#FFC629",
    "light-shadow-02": "#FFF4B9",
    "light-shadow-01": "#FFF8D3",
    light: "#FFFCEC",
    "base-highlight-02": "#FFE166",
    "base-highlight-01": "#FFF0A1",
    base: "#FFC629",
    "base-shadow-01": "#FFB018",
    "base-shadow-02": "#FF950D",
    dark: "#DD850F",
    "dark-shadow-01": "#BA7412",
    "dark-shadow-02": "#986414",
  },
  anjou: {
    "01": "#EAEBAA",
    "02": "#D3D655",
    "03": "#B8BE14",
    base: "#B8BE14",
    "base-highlight-01": "#D9DC3F",
    "base-highlight-02": "#EBED7F",
    "base-shadow-01": "#9EA60B",
    "base-shadow-02": "#808906",
    dark: "#606C0D",
    "dark-shadow-01": "#505D10",
    "dark-shadow-02": "#3F4F14",
    light: "#FBFBE5",
    "light-shadow-01": "#F5F6C2",
    "light-shadow-02": "#F0F19F",
    "radial-light":
      "radial-gradient(50% 50% at 50% 50%, #D9DC3F 0%, #B8BE14 100%);",
  },
  capri: {
    "01": "#96E2D7",
    "02": "#2EB1A5",
    "03": "#048387",
    "04": "#026A6C",
    "light-shadow-02": "#BCEEEB",
    "light-shadow-01": "#D5F4F3",
    light: "#EDFAFA",
    "base-highlight-02": "#A6E8E5",
    "base-highlight-01": "#6CD4CD",
    base: "#2EB1A5",
    "base-shadow-01": "#1B9688",
    "base-shadow-02": "#167B6D",
    dark: "#0F5F53",
    "dark-shadow-01": "#0F5348",
    "dark-shadow-02": "#0F473E",
  },
  monaco: {
    "02": "#00A3DE",
    "03": "#033E7F",
    "04": "#0F1F36",
    "light-shadow-02": "#B8F5F5",
    "light-shadow-01": "#D2F9F9",
    light: "#ECFCFC",
    "base-highlight-02": "#A0F2F2",
    "base-highlight-01": "#66E0ED",
    base: "#00A3DE",
    "base-shadow-01": "#0086D0",
    "base-shadow-02": "#0066BD",
    dark: "#064979",
    "dark-shadow-01": "#084062",
    "dark-shadow-02": "#0B364B",
  },
  lilac: {
    "02": "#C2B6E2",
    "03": "#686BA6",
    "light-shadow-02": "#D9D9EB",
    "light-shadow-01": "#E7E7F3",
    light: "#F5F5FA",
    "base-highlight-02": "#CCCDE5",
    "base-highlight-01": "#A3A5CE",
    base: "#686BA6",
    "base-shadow-01": "#494C89",
    "base-shadow-02": "#2F3169",
    dark: "#212B49",
    "dark-shadow-01": "#1D293E",
    "dark-shadow-02": "#182733",
  },
  smoke: {
    "01": "#EFF3F0",
    "02": "#BDCDC3",
    "03": "#708472",
    "04": "#1A3C34",
    "05": "#0F231E",
    "light-shadow-02": "#DCE2DC",
    "light-shadow-01": "#E9EDE9",
    light: "#F6F7F6",
    "base-highlight-02": "#D0D8D0",
    "base-highlight-01": "#A9B7AA",
    base: "#708472",
    "base-shadow-01": "#516452",
    "base-shadow-02": "#354636",
    dark: "#2A3C2F",
    "dark-shadow-01": "#25372C",
    "dark-shadow-02": "#1F3228",
  },
};
