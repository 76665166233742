import { CoverImageBGColorSchemeType } from "links/lib/types";

export const isDarkColorScheme = (
  colorScheme: CoverImageBGColorSchemeType
): boolean => {
  switch (colorScheme) {
    case "RED":
    case "PURPLE":
    case "DARK_GREEN":
    case "DARK_ORANGE":
      return true;
    default:
      return false;
  }
};
