import { Box } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { PracticeSetQuestionCardType } from "adminComponents/molecules/PracticeSetQuestionCard";
import { StudentReportQuestionList } from "adminComponents/molecules/StudentReportQuestionList";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { IPracticeSetItemWithClassroomResults } from "links/lib/types";

interface IProps {
  questions: IPracticeSetItemWithClassroomResults[];
  hasNoPremiumAccess?: boolean;
}

export const QuestionsTab: React.FC<IProps> = ({
  questions,
  hasNoPremiumAccess,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  return (
    <Box mt={pxToRem(40)}>
      <StudentReportQuestionList
        items={questions}
        cardType={PracticeSetQuestionCardType.StudentAssignmentResults}
        hasNoPremiumAccess={hasNoPremiumAccess}
        upgradeText={t("studentPracticeSetReportScreen.upgradeText")}
      />
    </Box>
  );
};
