import mobileImage from "adminComponents/assets/background-images/illustrated-background-mobile.svg";
import { hoverStyles, pxToRem } from "adminComponents/utils";

export const AdminStudentProfileMenu = {
  parts: [
    "closeButton",
    "container",
    "editAvatar",
    "heading",
    "divider",
    "link",
    "seasonProgress",
    "subheading",
  ],
  baseStyle: (): Record<string, unknown> => ({
    closeButton: {
      borderColor: "transparent",
      borderWidth: pxToRem(2),
      color: "primary.warm-black",
      display: { base: "block", md: "none" },
      left: pxToRem(6),
      position: "absolute",
      top: pxToRem(6),
      _focus: {
        background: "none",
        boxShadow: "none",
      },
      _focusVisible: {
        borderColor: "utility.focus",
      },
    },
    container: {
      alignItems: "center",
      bgColor: { base: "primary.warm-white", md: "primary.white" },
      borderColor: "primary.tan",
      borderRadius: { base: 0, md: pxToRem(20) },
      borderWidth: { base: 0, md: pxToRem(2) },
      boxShadow: {
        base: "none",
        md: `${pxToRem(5)} ${pxToRem(7)} ${pxToRem(27)} ${pxToRem(
          -6
        )} rgba(0, 0, 0, 0.1)`,
      },
      display: "flex",
      flexDirection: "column",
      maxW: { base: "full", md: pxToRem(301) },
      pb: pxToRem(20),
      px: { base: 0, md: 0 },
    },
    topContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundImage: { base: mobileImage, md: "none" },
      backgroundPosition: "top",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      pt: { base: pxToRem(16), md: pxToRem(30) },
      width: "100%",
    },
    bottomContainer: {
      position: "relative",
    },
    divider: {
      borderBottomWidth: pxToRem(2),
      display: { base: "none", md: "block" },
      opacity: 1,
    },
    editAvatar: {
      mt: pxToRem(12),
      mb: pxToRem(6),
    },
    heading: {
      fontSize: pxToRem(24),
      fontWeight: 400,
      mt: { base: pxToRem(18), md: pxToRem(20) },
      lineHeight: { base: pxToRem(34), md: pxToRem(24) },
      wordBreak: "break-all",
      paddingX: pxToRem(8),
    },
    link: {
      borderColor: "transparent",
      borderWidth: pxToRem(2),
      display: "block",
      mx: { base: pxToRem(15), md: 0 },
      p: {
        base: `${pxToRem(11)} ${pxToRem(18)} ${pxToRem(6)} ${pxToRem(14)}`,
        md: `${pxToRem(5)} ${pxToRem(18)} ${pxToRem(5)} ${pxToRem(30)}`,
      },
      position: "relative",
      span: {
        fontSize: pxToRem(20),
        fontWeight: { base: 500, md: 600 },
      },
      _focus: {
        background: "none",
        boxShadow: "none",
      },
      _focusVisible: {
        borderColor: "utility.focus",
        _hover: hoverStyles({
          bgColor: { base: "transparent", md: "primary.golden-light" },
        }),
      },
      _hover: hoverStyles({
        bgColor: { base: "transparent", md: "primary.golden-light" },
        textDecoration: { base: "underline", md: "none" },
      }),
      "~ div": {
        display: { base: "block", md: "none" },
        mx: pxToRem(15),
      },
      "span[role=presentation]": {
        position: "absolute",
        width: pxToRem(8),
        height: pxToRem(8),
        background: "utility.dark-orange",
        borderRadius: "full",
        transform: `translate(${pxToRem(6)}, ${pxToRem(3)})`,
      },
    },
    shiftedContentWrapper: {
      position: "relative",
      width: "100%",
      _after: {
        display: { md: "none" },
        content: "''",
        position: "absolute",
        top: "50%",
        left: "0",
        right: "0",
        bottom: "0",
        backgroundColor: "primary.warm-white",
        zIndex: "-1",
      },
    },
    seasonProgress: {
      bgColor: "primary.white",
      borderColor: "primary.tan",
      borderRadius: pxToRem(20),
      borderWidth: { base: pxToRem(6), md: 0 },
      mx: { base: pxToRem(15), md: 0 },
      mb: { base: pxToRem(12), md: 0 },
      mt: { base: pxToRem(10), md: 0 },
      pb: { base: pxToRem(24), md: pxToRem(5) },
      pt: { base: pxToRem(16), md: pxToRem(22) },
      px: { base: pxToRem(20), md: pxToRem(30) },
    },
    subheading: {
      alignItems: "center",
      justifyContent: "center",
      mb: pxToRem(21.5),
      mt: { base: pxToRem(8), md: pxToRem(9.5) },
      w: "full",

      ">div": {
        flex: 1,
        _first: {
          alignItems: "center",
          justifyContent: "flex-end",
          left: pxToRem(-18),
          position: "relative",
        },
        _last: {
          svg: {
            h: pxToRem(16),
            w: pxToRem(12),
          },
        },
      },
    },
  }),
};
