import { Box } from "@chakra-ui/react";
import React from "react";

import { LoadingSpinner } from "adminComponents/atoms/LoadingSpinner";
import { StudentsPerformanceTab } from "adminComponents/screens/ClassroomAssignmentReportScreen/components/StudentsPerformanceTab";
import { pxToRem } from "adminComponents/utils";
import { usePageTrack } from "lib/contexts/analytics";
import { AnalyticsPage } from "links/lib/types";

import { useStudentPerformanceContext } from "../hooks/useStudentPerformanceData";

export enum StudentPerformanceSortBy {
  StudentGivenName = 0,
  StudentFamilyName = 1,
  StudentAccuracy = 2,
}

export const StudentPerformanceTab: React.FC = () => {
  usePageTrack(
    AnalyticsPage.TeacherDashboard_ClassroomDetail_AssignmentDetail_StudentPerformance
  );

  const { isLoading, sortedStudentsData, handleStudentPerformanceSortChange } =
    useStudentPerformanceContext();

  return (
    <>
      {isLoading ? (
        <Box mt={pxToRem(20)}>
          <LoadingSpinner />
        </Box>
      ) : (
        <StudentsPerformanceTab
          studentsData={sortedStudentsData}
          handleStudentSortChange={handleStudentPerformanceSortChange}
        />
      )}
    </>
  );
};
