import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSessionStorage } from "react-use";

import {
  AssignmentFlyout,
  FormDataProps,
  dateDataToMoment,
} from "adminComponents/organisms/AssignmentFlyout";
import { GoogleClassroomAuthorizeModal } from "adminComponents/organisms/GoogleClassroomAuthorizeModal";
import {
  SelectStudentModal,
  StudentItem,
} from "adminComponents/organisms/SelectStudentModal";
import { generateStudentName } from "adminComponents/utils";
import { useErrorToast, useShowToast } from "adminComponents/utils/toast";
import {
  ASSIGNMENT_ACCURACY_GOALS,
  ASSIGNMENT_TIME_LIST,
  ASSIGNMENT_TIME_OF_DAY,
  afterSignInPathSessionKey,
} from "lib/constants";
import { useAnalytics } from "lib/contexts/analytics";
import { useHubSpot } from "lib/hooks/useHubSpot";
import { useMutateAssignment } from "links/lib/features/assignments";
import { IMutateAssignmentResponse } from "links/lib/features/assignments/useMutateAssignment";
import { useFetchClassroomUsers } from "links/lib/features/classrooms";
import { useFetchGoogleClassroomClassrooms } from "links/lib/features/classrooms";
import { useFetchPracticeSets } from "links/lib/features/practiceSets";
import {
  AnalyticsEvent,
  IAssignment,
  IClassroom,
  IUser,
  ThirdParty,
  UserRole,
  UsersGroupsRole,
} from "links/lib/types";
import useFetchProviderRedirectUrl from "screens/SignIn/useFetchProviderRedirectUrl";

export interface IArgs {
  classrooms: Array<IClassroom>;
  onSubmit?: (assignment?: IAssignment) => void;
  onSuccess?: () => void;
}

export interface IResult {
  handleCreateIntent: (classroom: IClassroom) => void;
  handleEditIntent: (assignment: IAssignment, classroom: IClassroom) => void;
  component: React.ReactElement;
}

const useCreateAssignment = ({
  classrooms,
  onSubmit,
  onSuccess,
}: IArgs): IResult => {
  const showToast = useShowToast();
  const [selectedStudentIds, setSelectedStudentIds] = useState<Array<string>>(
    []
  );
  const [isStudentSelectModalOpen, setIsStudentSelectModalOpen] =
    useState<boolean>(false);
  const [isFlyoutOpen, setIsFlyoutOpen] = useState<boolean>(false);
  const [selectedClassroom, setSelectedClassroom] = useState<IClassroom | null>(
    null
  );
  const [isAuthorizeModalOpen, setIsAuthorizeModalOpen] =
    useState<boolean>(false);
  const [currentAssignment, setCurrentAssignment] =
    useState<IAssignment | null>(null);
  const { t } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "classroomCommon.assignmentFlyout",
  });
  const { trackHubSpotEvent } = useHubSpot();
  const { trackEvent } = useAnalytics();

  const [, setSessionRedirectPath] = useSessionStorage(
    afterSignInPathSessionKey,
    "/"
  );

  const fetchTeacherUsers = useFetchClassroomUsers({
    classroom_id: selectedClassroom?.id,
    group_roles: [UsersGroupsRole.Owner, UsersGroupsRole.Assistant],
  });
  useErrorToast(fetchTeacherUsers.error);

  const teacherIds = useMemo(() => {
    return fetchTeacherUsers.data?.users.map((u) => u.id) || [];
  }, [fetchTeacherUsers.data]);

  const practiceSetFetch = useFetchPracticeSets({
    collection_id: "0",
    include_all: true,
    classroom_id: selectedClassroom?.id,
    created_by_user_ids: teacherIds,
    enabled: !!teacherIds.length,
  });

  const classroomStudentsFetch = useFetchClassroomUsers({
    classroom_id: selectedClassroom?.id,
    group_roles: [UsersGroupsRole.Member],
  });
  const gclassroomFetch = useFetchGoogleClassroomClassrooms();
  const redirectFetch = useFetchProviderRedirectUrl();

  const handleFlyoutClose = useCallback(() => {
    setIsFlyoutOpen(false);
    setCurrentAssignment(null);
    setSelectedClassroom(null);
    setSelectedStudentIds([]);
  }, []);

  const onCreateSuccess = useCallback(
    (response: IMutateAssignmentResponse) => {
      if (response.requires_third_party_authorization) {
        redirectFetch.execute({
          provider: "google_classroom",
          role: UserRole.Teacher,
        });
        setIsAuthorizeModalOpen(true);
      } else {
        showToast(t("successToast"));
        handleFlyoutClose();
        onSuccess?.();

        trackHubSpotEvent(AnalyticsEvent.HubSpot_AssignedPracticeSet, {});
      }
    },
    [
      t,
      showToast,
      handleFlyoutClose,
      trackHubSpotEvent,
      onSuccess,
      redirectFetch,
    ]
  );

  const assignmentMutation = useMutateAssignment({
    onSuccess: onCreateSuccess,
  });
  useErrorToast(assignmentMutation.error);

  const handleClassroomSelection = useCallback((classroom: IClassroom) => {
    setSelectedStudentIds([]);
    setSelectedClassroom(classroom);
  }, []);

  const handleCreateIntent = useCallback((classroom?: IClassroom) => {
    setIsFlyoutOpen(true);
    setSelectedStudentIds([]);

    if (!classroom) {
      setSelectedClassroom(null);
    } else {
      setSelectedClassroom(classroom);
    }
  }, []);

  const handleEditIntent = useCallback(
    (assignment: IAssignment, classroom: IClassroom) => {
      setSelectedClassroom(classroom);
      setCurrentAssignment(assignment);
      setSelectedStudentIds(assignment.user_ids);
      setIsFlyoutOpen(true);
    },
    []
  );

  const handleStudentSelection = useCallback(() => {
    setIsStudentSelectModalOpen(true);
    setSelectedStudentIds(
      selectedStudentIds.length
        ? selectedStudentIds
        : classroomStudentsFetch.data?.users
            .filter((u) => u.id !== "0")
            .map((u) => u.id) ?? []
    );
  }, [selectedStudentIds, classroomStudentsFetch.data]);

  const handleStudentSelectSubmit = useCallback((students: Array<string>) => {
    setSelectedStudentIds(students);
    setIsStudentSelectModalOpen(false);
  }, []);

  const handleStudentSelectClose = useCallback(() => {
    setIsStudentSelectModalOpen(false);
  }, []);

  const handleSubmit = useCallback(
    (formData: FormDataProps) => {
      const {
        assignmentId,
        classroomIds,
        practiceSet,
        description,
        accuracyGoal,
        customStartDate,
        startDate,
        startHour,
        startTimeOfDay,
        dueDate,
        dueHour,
        dueTimeOfDay,
        assignToGoogleClassroom,
        randomizeItemOrder,
      } = formData.form;

      const now = new Date();

      const isCustomStartTime = customStartDate === "custom";
      const startsAt = isCustomStartTime
        ? dateDataToMoment(startDate, startHour, startTimeOfDay).toISOString()
        : now.toISOString();

      assignmentMutation.mutate({
        id: assignmentId,
        classroom_id: classroomIds[0],
        practice_set_id: practiceSet,
        description: description,
        required_score_percent: Math.min(
          Math.max(Math.ceil(accuracyGoal), 1),
          100
        ),
        required_duration_sec: 0,
        starts_at: startsAt,

        ends_at: dateDataToMoment(dueDate, dueHour, dueTimeOfDay).toISOString(),
        user_ids: selectedStudentIds,
        start_now: !isCustomStartTime,
        third_party: assignToGoogleClassroom
          ? ThirdParty.GOOGLE_CLASSROOM
          : ThirdParty.NONE,
        third_party_assignment_id: currentAssignment?.third_party_assignment_id,
        third_party_group_id: currentAssignment?.third_party_group_id,
        randomize_item_order: randomizeItemOrder,
      });

      onSubmit?.(currentAssignment || undefined);
    },
    [selectedStudentIds, assignmentMutation, onSubmit, currentAssignment]
  );

  const handleAuthorizeClose = useCallback(() => {
    setIsAuthorizeModalOpen(false);
  }, []);

  const handleGrantAccess = useCallback(() => {
    if (!redirectFetch.data) return;

    trackEvent(
      AnalyticsEvent.TeacherDashboard_ClassroomDetail_AssignmentsTab_AssignToGClassroomAuthorize,
      {}
    );

    setSessionRedirectPath(window.location.pathname);
    window.location.href = redirectFetch.data.redirectUrl;
  }, [redirectFetch, setSessionRedirectPath, trackEvent]);

  const authorizeModal = useMemo(() => {
    return (
      <GoogleClassroomAuthorizeModal
        isOpen={isAuthorizeModalOpen}
        isLoading={redirectFetch.isLoading}
        handleGrantAccess={handleGrantAccess}
        handleClose={handleAuthorizeClose}
      />
    );
  }, [
    isAuthorizeModalOpen,
    handleAuthorizeClose,
    handleGrantAccess,
    redirectFetch.isLoading,
  ]);

  const handleToggleAssignmentInThirdParty = useCallback(
    (assignInThirdParty: boolean) => {
      if (
        gclassroomFetch.data?.requires_authorization &&
        assignInThirdParty &&
        !isAuthorizeModalOpen
      ) {
        setIsAuthorizeModalOpen(true);
        redirectFetch.execute({
          provider: "google_classroom",
          role: UserRole.Teacher,
        });
      }
    },
    [
      gclassroomFetch.data?.requires_authorization,
      isAuthorizeModalOpen,
      redirectFetch,
    ]
  );

  const classroomStudentsMap = useMemo(() => {
    const studentsMap: { [classroom_id: string]: Array<IUser> } = {};
    if (!selectedClassroom || !classroomStudentsFetch.data) {
      return studentsMap;
    }

    studentsMap[selectedClassroom.id] = classroomStudentsFetch.data.users;

    return studentsMap;
  }, [selectedClassroom, classroomStudentsFetch.data]);

  const component = useMemo(() => {
    return (
      <>
        <AssignmentFlyout
          classrooms={classrooms}
          accuracyGoals={ASSIGNMENT_ACCURACY_GOALS}
          isOpen={isFlyoutOpen}
          isLoading={practiceSetFetch.isLoading || assignmentMutation.isLoading}
          practiceSets={practiceSetFetch.data?.practice_sets || []}
          selectedStudentIds={
            selectedStudentIds.length !==
            classroomStudentsFetch.data?.users.length
              ? selectedStudentIds
              : []
          }
          handleClose={handleFlyoutClose}
          handleSubmit={handleSubmit}
          handleClassroomSelection={handleClassroomSelection}
          handleStudentSelection={handleStudentSelection}
          timeList={ASSIGNMENT_TIME_LIST}
          timeOfDay={ASSIGNMENT_TIME_OF_DAY}
          lockToClassroom={selectedClassroom || undefined}
          assignment={currentAssignment || undefined}
          toggleAssignInThirdParty={handleToggleAssignmentInThirdParty}
          requiresThirdPartyAuth={
            !!gclassroomFetch.data?.requires_authorization
          }
          classroomStudentsMap={classroomStudentsMap}
          googleClassrooms={gclassroomFetch.data?.classrooms}
        />
        {authorizeModal}
        {!!selectedClassroom && (
          <SelectStudentModal
            isLoading={classroomStudentsFetch.isLoading}
            classroom={selectedClassroom}
            isOpen={isStudentSelectModalOpen}
            selectedStudentIds={selectedStudentIds}
            handleSubmit={handleStudentSelectSubmit}
            handleClose={handleStudentSelectClose}
          >
            {classroomStudentsFetch.data?.users
              .filter((user) => user.id !== "0")
              .map((user) => {
                const studentName = generateStudentName(user);

                return (
                  <StudentItem
                    key={`student-${user.id}`}
                    id={user.id}
                    displayName={studentName ? studentName.primary : user.email}
                  />
                );
              })}
          </SelectStudentModal>
        )}
      </>
    );
  }, [
    classrooms,
    selectedStudentIds,
    practiceSetFetch.data,
    handleFlyoutClose,
    handleStudentSelection,
    handleClassroomSelection,
    isFlyoutOpen,
    isStudentSelectModalOpen,
    classroomStudentsFetch.data,
    selectedClassroom,
    handleStudentSelectClose,
    handleStudentSelectSubmit,
    practiceSetFetch.isLoading,
    classroomStudentsFetch.isLoading,
    assignmentMutation.isLoading,
    handleSubmit,
    currentAssignment,
    authorizeModal,
    gclassroomFetch.data,
    classroomStudentsMap,
    handleToggleAssignmentInThirdParty,
  ]);

  return useMemo(() => {
    return {
      component,
      handleCreateIntent,
      handleEditIntent,
    };
  }, [component, handleCreateIntent, handleEditIntent]);
};

export { useCreateAssignment };
