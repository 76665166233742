import { pxToRem } from "adminComponents/utils/pxToRem";

export const AdminTemplateWithHeaderAndTwoColumns = {
  baseStyle: {
    outer: {
      minHeight: "100%",
      width: "100%",
      backgroundColor: "primary.warm-white",
    },
    backgroundContainer: {
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundAttachment: "fixed",
    },
    headerContainer: {
      paddingBottom: { base: pxToRem(32), md: pxToRem(64) },
    },
    bodyContainer: {
      minHeight: "100%",
      paddingTop: { base: pxToRem(32), md: pxToRem(48) },
    },
    contentContainer: {
      marginTop: pxToRem(40),
    },
    loadingSpinner: {
      marginTop: pxToRem(52),
    },
  },
};
