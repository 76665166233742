import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { FormData } from "adminComponents/organisms/PracticeSetDetailFlyout";
import { useErrorToast, useShowToast } from "adminComponents/utils/toast";
import { useAnalytics } from "lib/contexts/analytics";
import { useMutatePracticeSet } from "links/lib/features/practiceSets";
import {
  AnalyticsEvent,
  CoverImageBGColorSchemeType,
  CoverImageBGPatternType,
  CoverImageIconType,
  IPracticeSet,
  PracticeSetStatus,
} from "links/lib/types";

interface IArgs {
  practiceSet?: IPracticeSet;
}

interface IResult {
  handleEdit: (data: FormData) => void;
  handleCoverImageChange: (coverImage: {
    bgColorScheme: CoverImageBGColorSchemeType;
    icon: CoverImageIconType;
    bgPattern: CoverImageBGPatternType;
  }) => void;
  setIsDetailFlyoutOpen: (isOpen: boolean) => void;
  isDetailFlyoutOpen: boolean;
  isLoading: boolean;
}

const useEditPracticeSet = ({ practiceSet }: IArgs): IResult => {
  const [isDetailFlyoutOpen, setIsDetailFlyoutOpen] = useState(false);
  const showToast = useShowToast();
  const { t } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "practiceSetDetailContainer.practiceSetEdit",
  });
  const { trackEvent } = useAnalytics();

  const onSuccess = useCallback(() => {
    showToast(t("successToast"));
  }, [showToast, t]);

  const mutatePracticeSet = useMutatePracticeSet({ onSuccess });
  const mutatePracticeSetQuiet = useMutatePracticeSet();

  useErrorToast(mutatePracticeSet.error);
  useErrorToast(mutatePracticeSetQuiet.error);

  const handleCoverImageChange = useCallback(
    (coverImage: {
      bgColorScheme: CoverImageBGColorSchemeType;
      icon: CoverImageIconType;
      bgPattern: CoverImageBGPatternType;
    }) => {
      const { bgColorScheme, icon, bgPattern } = coverImage;

      if (!practiceSet) return;

      mutatePracticeSetQuiet.mutate({
        id: practiceSet.id,
        title: practiceSet.title,
        description: practiceSet.description,
        collection_id: practiceSet.collection_id,
        status: practiceSet.status,
        availability: practiceSet.availability,
        language_code: practiceSet.language_code,
        grade_levels: practiceSet.grade_levels,
        subjects: practiceSet.subjects,
        reference_materials: practiceSet.reference_materials,
        cnc_family_code: practiceSet.cnc_family_code,
        cnc_code: practiceSet.cnc_code,
        is_certified: practiceSet.is_certified,
        is_premium: practiceSet.is_premium,
        cover_image_bg_color_scheme: bgColorScheme,
        cover_image_bg_pattern: bgPattern,
        cover_image_icon: icon,
      });

      trackEvent(
        AnalyticsEvent.TeacherDashboard_PracticeSetDetail_PracticeSetDetailFlyout_UpdateCoverImage,
        { practiceSetId: practiceSet.id }
      );
    },
    [practiceSet, mutatePracticeSetQuiet, trackEvent]
  );

  const handleEdit = useCallback(
    (data: FormData) => {
      if (!practiceSet) return;

      const {
        title,
        description,
        folder,
        subjects,
        gradeLevels,
        language,
        availability,
        cncFamilyCode,
        cncCode,
        isCertified,
        isPremium,
      } = data.form;

      mutatePracticeSet.mutate({
        id: practiceSet.id,
        title,
        description,
        collection_id: folder,
        status: PracticeSetStatus.Active,
        availability,
        language_code: language,
        grade_levels: gradeLevels.map((id) => ({
          grade_level: { id },
        })),
        subjects: subjects.map((id) => ({ subject: { id } })),
        reference_materials: (practiceSet.reference_materials || []).map(
          (r) => ({
            reference_material: { id: r.id },
          })
        ),
        cnc_family_code: cncFamilyCode || "",
        cnc_code: cncCode || "",
        is_certified: isCertified || false,
        is_premium: isPremium || false,
        cover_image_bg_color_scheme:
          practiceSet.cover_image_bg_color_scheme || "",
        cover_image_bg_pattern: practiceSet.cover_image_bg_pattern || "",
        cover_image_icon: practiceSet.cover_image_icon || "",
      });

      trackEvent(
        AnalyticsEvent.TeacherDashboard_PracticeSetDetail_PracticeSetDetailFlyout_SaveDetail,
        { practiceSetId: practiceSet.id, availability }
      );

      setIsDetailFlyoutOpen(false);
    },
    [mutatePracticeSet, practiceSet, trackEvent]
  );

  return useMemo(
    () => ({
      handleEdit,
      handleCoverImageChange,
      isDetailFlyoutOpen,
      setIsDetailFlyoutOpen,
      isLoading:
        mutatePracticeSet.isLoading || mutatePracticeSetQuiet.isLoading,
    }),
    [
      isDetailFlyoutOpen,
      handleEdit,
      handleCoverImageChange,
      mutatePracticeSet.isLoading,
      mutatePracticeSetQuiet.isLoading,
    ]
  );
};

export { useEditPracticeSet };
