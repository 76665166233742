import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { IUser } from "../../types";
import { useAuth } from "../auth";

export interface IMutateAuthUserProfileImageArgs {
  profile_image_url: string;
}

export interface IMutateAuthUserProfileImageResponse {
  user: IUser;
}

/**
 * Updated the profile image of the auth user
 * @returns
 */
export default function useMutateAuthUserProfileImage(): UseMutationResult<
  IMutateAuthUserProfileImageResponse,
  unknown,
  IMutateAuthUserProfileImageArgs
> {
  const axios = useAxios();
  const queryClient = useQueryClient();
  const {
    setAuth,
    getFeatureFlags,
    authToken,
    authSharingSettings,
    authLicense,
    hasNoPremiumAccess,
    isGhostUser,
    isAuthLoading,
  } = useAuth();

  const mutateAuthUser = async (args: IMutateAuthUserProfileImageArgs) => {
    const { profile_image_url } = args;

    const response = await axios({
      method: "put",
      url: `/v1/users/self/profile-image`,
      data: {
        profile_image_url,
      },
    }).then((r) => r.data as IMutateAuthUserProfileImageResponse);

    queryClient.invalidateQueries("users", { exact: true });

    // update app auth
    setAuth({
      authUser: response.user,
      authToken,
      authFeatureFlags: getFeatureFlags(),
      authSharingSettings,
      authLicense,
      hasNoPremiumAccess,
      isGhostUser,
      isAuthLoading,
    });

    return response;
  };

  const mutation = useMutation(mutateAuthUser);

  return mutation;
}
