import { pxToRem } from "adminComponents/utils/pxToRem";

export interface IProps {
  isBlurred?: boolean;
}

export const AdminPieChartWithLabel = {
  parts: ["chart", "container"],
  baseStyle: ({ isBlurred }: IProps): Record<string, unknown> => ({
    chart: {
      flexShrink: 0,
      marginRight: pxToRem(8),
    },
    container: {
      display: "flex",
      alignItems: "center",
      textAlign: "left",
      filter: isBlurred ? `blur(5px)` : undefined,
      WebkitFilter: isBlurred ? `blur(5px)` : undefined,
    },
  }),
  variants: {},
};
