import { Box } from "@chakra-ui/react";
import React from "react";

import { productName } from "links/lib/constants";
import { useInitStudentAuth } from "links/lib/features/auth/useInitAuth";

const TeacherStudentSession: React.FC = () => {
  useInitStudentAuth().then(() => {
    window.location.href = "/s/home";
  });

  // TODO: Translate
  return <Box>Opening {productName} as a student</Box>;
};

export default TeacherStudentSession;
