import { useEffect, useMemo, useState } from "react";

import useImage from "lib/hooks/useImage";
import {
  CoverImageBGColorSchemes,
  RemixBGColorSchemes,
} from "links/lib/constants";
import {
  CoverImageBGColorSchemeOptions,
  CoverImageBGColorSchemeType,
  CoverImageBGPatternType,
  RemixBGColorSchemeType,
  RemixBGColorSchemeTypeOptions,
  RemixBGPatternType,
} from "links/lib/types";

import ELECTIVE_BUSINESS_FINANCE_SVG from "../resource/ELECTIVE_BUSINESS_FINANCE.svg";
import ELECTIVE_COOKING_BAKING_SVG from "../resource/ELECTIVE_COOKING_BAKING.svg";
import ELECTIVE_HEALTH_PE_SVG from "../resource/ELECTIVE_HEALTH_PE.svg";
import ELECTIVE_WOODWORKING_SVG from "../resource/ELECTIVE_WOODWORKING.svg";
import ENGLISH_BOOKS_READING_SVG from "../resource/ENGLISH_BOOKS_READING.svg";
import ENGLISH_PUNCTUATION_GRAMMAR_SVG from "../resource/ENGLISH_PUNCTUATION_GRAMMAR.svg";
import GS_LOGO_SVG from "../resource/GS_LOGO.svg";
import GS_OG_SVG from "../resource/GS_OG.svg";
import HISTORY_SVG from "../resource/HISTORY.svg";
import MATH_ABSTRACT_SVG from "../resource/MATH_ABSTRACT.svg";
import MATH_OPERATION_SYMBOLS_SVG from "../resource/MATH_OPERATION_SYMBOLS.svg";
import OTHER_CULTURE_SVG from "../resource/OTHER_CULTURE.svg";
import OTHER_FUN_PARTY_SVG from "../resource/OTHER_FUN_PARTY.svg";
import PERFORMATIVE_ARTS_SVG from "../resource/PERFORMATIVE_ARTS.svg";
import PP_LOGO_SVG from "../resource/PP_LOGO.svg";
import PP_OG_SVG from "../resource/PP_OG.svg";
import REMIX_SVG from "../resource/REMIX.svg";
import SCIENCE_BIOLOGY_SVG from "../resource/SCIENCE_BIOLOGY.svg";
import SCIENCE_CHEMISTRY_PHYSICS_SVG from "../resource/SCIENCE_CHEMISTRY_PHYSICS.svg";
import SCIENCE_SPACE_SVG from "../resource/SCIENCE_SPACE.svg";
import SMART_SET_SVG from "../resource/SMART_SET.svg";
import TRANSPARENT_SVG from "../resource/TRANSPARENT.svg";
import VISUAL_ARTS_SVG from "../resource/VISUAL_ARTS.svg";
import WORLD_LANGUAGE_SVG from "../resource/WORLD_LANGUAGE.svg";

export interface IUseCoverImageBackgroundArgs {
  pattern: CoverImageBGPatternType | RemixBGPatternType;
  colorScheme:
    | CoverImageBGColorSchemeType
    | RemixBGColorSchemeType
    | [[number, number, number], [number, number, number]];
  tileSize: number;
}

export interface IUseCoverImageBackgroundResult {
  tileImage: string | null;
}

const patternLookup: Record<
  CoverImageBGPatternType | RemixBGPatternType,
  string
> = {
  ELECTIVE_BUSINESS_FINANCE: ELECTIVE_BUSINESS_FINANCE_SVG,
  ELECTIVE_COOKING_BAKING: ELECTIVE_COOKING_BAKING_SVG,
  ELECTIVE_HEALTH_PE: ELECTIVE_HEALTH_PE_SVG,
  ELECTIVE_WOODWORKING: ELECTIVE_WOODWORKING_SVG,
  ENGLISH_BOOKS_READING: ENGLISH_BOOKS_READING_SVG,
  ENGLISH_PUNCTUATION_GRAMMAR: ENGLISH_PUNCTUATION_GRAMMAR_SVG,
  HISTORY: HISTORY_SVG,
  MATH_ABSTRACT: MATH_ABSTRACT_SVG,
  MATH_OPERATION_SYMBOLS: MATH_OPERATION_SYMBOLS_SVG,
  OTHER_CULTURE_BG_PATTERN: OTHER_CULTURE_SVG,
  OTHER_FUN_PARTY: OTHER_FUN_PARTY_SVG,
  PERFORMATIVE_ARTS: PERFORMATIVE_ARTS_SVG,
  REMIX: REMIX_SVG,
  SCIENCE_BIOLOGY_BG_PATTERN: SCIENCE_BIOLOGY_SVG,
  SCIENCE_CHEMISTRY_PHYSICS: SCIENCE_CHEMISTRY_PHYSICS_SVG,
  SCIENCE_SPACE: SCIENCE_SPACE_SVG,
  VISUAL_ARTS: VISUAL_ARTS_SVG,
  WORLD_LANGUAGE: WORLD_LANGUAGE_SVG,
  SMART_SET: SMART_SET_SVG,
  GS_LOGO: GS_LOGO_SVG,
  GS_OG: GS_OG_SVG,
  PP_LOGO: PP_LOGO_SVG,
  PP_OG: PP_OG_SVG,
  TRANSPARENT: TRANSPARENT_SVG,
};

const replaceRGBBackground = [255, 0, 0];

export const useCoverImageBackground = (
  args: IUseCoverImageBackgroundArgs
): IUseCoverImageBackgroundResult => {
  const { tileSize, pattern, colorScheme } = args;

  const [tileImageData, setTileImageData] = useState<string | null>(null);
  const { execute: loadImage, data: loadImageData } = useImage();

  useEffect(() => {
    const patternSVG = patternLookup[pattern];
    if (!patternSVG) return;
    loadImage({ src: patternLookup[pattern] });
  }, [pattern, loadImage]);

  // On image data change, generate a new tile image
  useEffect(() => {
    let backgroundRGB, foregroundRGB;
    if (typeof colorScheme === "string") {
      const isCoverImageBGColorScheme = CoverImageBGColorSchemeOptions.includes(
        colorScheme as CoverImageBGColorSchemeType
      );
      const isRemixBGColorScheme = RemixBGColorSchemeTypeOptions.includes(
        colorScheme as RemixBGColorSchemeType
      );
      if (isCoverImageBGColorScheme) {
        [backgroundRGB, foregroundRGB] =
          CoverImageBGColorSchemes[colorScheme as CoverImageBGColorSchemeType];
      } else if (isRemixBGColorScheme) {
        [backgroundRGB, foregroundRGB] =
          RemixBGColorSchemes[colorScheme as RemixBGColorSchemeType];
      }
    } else {
      [backgroundRGB, foregroundRGB] = colorScheme;
    }

    if (loadImageData?.image && tileSize && backgroundRGB && foregroundRGB) {
      const { image } = loadImageData;

      // Firefox won't stretch an SVG image when drawn directly to a
      // canvas (it will make it fit instead), but it will stretch
      // when drawing one canvas to another.
      const sourceImageCanvas = document.createElement("canvas");
      sourceImageCanvas.width = image.width;
      sourceImageCanvas.height = image.height;
      sourceImageCanvas.getContext("2d")?.drawImage(image, 0, 0);

      const c = document.createElement("canvas");
      const ctx = c.getContext("2d");

      if (!ctx) return;

      c.width = tileSize;
      c.height = tileSize;

      ctx.drawImage(sourceImageCanvas, 0, 0, tileSize, tileSize);

      const imageData = ctx.getImageData(0, 0, tileSize, tileSize);

      if (pattern !== "TRANSPARENT") {
        for (let i = 0; i < imageData.data.length; i += 4) {
          if (
            imageData.data[i] == replaceRGBBackground[0] &&
            imageData.data[i + 1] == replaceRGBBackground[1] &&
            imageData.data[i + 2] == replaceRGBBackground[2]
          ) {
            imageData.data[i] = backgroundRGB[0];
            imageData.data[i + 1] = backgroundRGB[1];
            imageData.data[i + 2] = backgroundRGB[2];
          } else {
            imageData.data[i] = foregroundRGB[0];
            imageData.data[i + 1] = foregroundRGB[1];
            imageData.data[i + 2] = foregroundRGB[2];
          }

          imageData.data[i + 3] = 255;
        }
      }

      ctx.putImageData(imageData, 0, 0);

      setTileImageData(c.toDataURL("image/png"));
    }
  }, [loadImageData, tileSize, colorScheme, pattern]);

  return useMemo(() => {
    return {
      tileImage: tileImageData,
    };
  }, [tileImageData]);
};
