import { Box, SimpleGrid } from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Accordion } from "adminComponents/atoms/Accordion";
import { Checkbox } from "adminComponents/atoms/Checkbox";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { ISubject, ISubjectCount, IUser } from "links/lib/types";

interface LibrarySubjectPickerProps {
  handleChangeSubjects: (selectedIds: string[]) => void;
  selectedSubjects?: string[];
  subjects: ISubject[];
  subjectCounts: ISubjectCount[];
  variant?: string;
  showCounts?: boolean;
  authUser?: IUser;
}

export const LibrarySubjectPicker: React.FC<LibrarySubjectPickerProps> = ({
  handleChangeSubjects,
  selectedSubjects = [],
  subjects,
  subjectCounts,
  variant = "adminSubjectSidebarAccordion",
  showCounts = true,
  authUser,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const [checkedSubjects, setCheckedSubjects] =
    useState<string[]>(selectedSubjects);

  useEffect(() => {
    setCheckedSubjects(selectedSubjects);
  }, [selectedSubjects]);

  // These records maps subjects data by parents and children
  const hierarchySubjects: Record<string, string[]> = useMemo(() => {
    const structure: Record<string, string[]> = {};
    subjects.forEach((subject) => {
      if (subject.parent_id === "0") {
        // If parent_id equals 0, it means that is a parent level subject
        // and if that record still not exists in our mapping, initializes it with an empty array
        if (!structure[subject.parent_id]) {
          structure[subject.id] = [];
        }
      } else {
        // If parent_id not equals 0, it means that is a child level subject to the parent one.
        // if parent subject records don't exist in our mapping we initialize it with a new array with current subject id
        // if parent subject exists, we mutate that array
        if (!structure[subject.parent_id]) {
          structure[subject.parent_id] = [subject.id];
        } else {
          structure[subject.parent_id] = [
            ...structure[subject.parent_id],
            subject.id,
          ];
        }
      }
    });
    return structure;
  }, [subjects]);

  const parentSubjects = subjects
    .filter((subject) => subject.parent_id === "0")
    .sort((a, b) => {
      // Move 'Other' parent subject to the end
      if (a.name === "Other") {
        return 1;
      }
      if (b.name === "Other") {
        return -1;
      }
      return 0;
    });

  const getCountBySubject = (subjectId: string) => {
    const found = subjectCounts.find(
      (subject) => subject.subject_id === subjectId
    );
    return found?.count ?? 0;
  };

  const handleToggleSubject = (subjectId: string, checked: boolean) => {
    const currentSubject = subjects.find((subject) => subject.id === subjectId);
    const parentSubject = subjects.find(
      (subject) => subject.id === currentSubject?.parent_id
    );

    if (!parentSubject || !currentSubject) return;

    setCheckedSubjects((prevChecked) => {
      let newChecked: string[] = [];

      if (checked) {
        newChecked = [...prevChecked, subjectId];
        if (
          hierarchySubjects[parentSubject.id].every((sId) =>
            newChecked.includes(sId)
          )
        ) {
          newChecked.push(parentSubject.id);
        }
      } else {
        newChecked = prevChecked.filter((sId) => sId !== subjectId);
        if (prevChecked.includes(parentSubject.id)) {
          newChecked = newChecked.filter((sId) => sId !== parentSubject.id);
        }
      }

      handleChangeSubjects(newChecked);
      return newChecked;
    });
  };

  const handleToggleAllSubject = (subjectId: string, checked: boolean) => {
    setCheckedSubjects((prevChecked) => {
      let newChecked: string[] = [];
      if (checked) {
        newChecked = [
          ...prevChecked,
          ...hierarchySubjects[subjectId],
          subjectId,
        ];
      } else {
        const removeList = [...hierarchySubjects[subjectId], subjectId];
        newChecked = prevChecked.filter((sId) => !removeList.includes(sId));
      }

      handleChangeSubjects(newChecked);
      return newChecked;
    });
  };

  return (
    <Accordion
      variant={variant}
      accordions={parentSubjects.map((subject) => ({
        content: (
          <SimpleGrid gap={pxToRem(4)}>
            <Box px={pxToRem(16)} py={pxToRem(8)} key={subject.id}>
              <Checkbox
                isChecked={checkedSubjects.includes(subject.id)}
                onChange={(e) =>
                  handleToggleAllSubject(subject.id, e.target.checked)
                }
              >
                {`${t("librarySubjectPicker.allSubjects", {
                  subject: subject.name,
                })}${
                  showCounts
                    ? ` (${getCountBySubject(subject.id).toLocaleString(
                        authUser?.language
                      )})`
                    : ""
                }`}
              </Checkbox>
            </Box>
            {subjects
              .filter((childSubject) => childSubject.parent_id === subject.id)
              .map((childSubject) => (
                <Box px={pxToRem(16)} py={pxToRem(8)} key={childSubject.id}>
                  <Checkbox
                    isChecked={checkedSubjects.includes(childSubject.id)}
                    onChange={(e) =>
                      handleToggleSubject(childSubject.id, e.target.checked)
                    }
                  >
                    {childSubject.name}
                    {`${
                      showCounts
                        ? ` (${getCountBySubject(
                            childSubject.id
                          ).toLocaleString(authUser?.language)})`
                        : ""
                    }`}
                  </Checkbox>
                </Box>
              ))}
          </SimpleGrid>
        ),
        title: `${subject.name}${
          showCounts
            ? ` (${getCountBySubject(subject.id).toLocaleString(
                authUser?.language
              )})`
            : ""
        }`,
      }))}
    />
  );
};
