import { Box, HStack, VStack } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Divider } from "adminComponents/atoms/Divider";
import { Heading } from "adminComponents/atoms/Heading";
import { Icon } from "adminComponents/atoms/Icon";
import { Text } from "adminComponents/atoms/Text";
import { AdminFlyout } from "adminComponents/molecules/Flyout";
import { GeneratePracticeSetResult } from "adminComponents/molecules/GeneratePracticeSetResult";
import {
  IInstantSetConfig,
  InstantSetConfiguration,
} from "adminComponents/molecules/InstantSetConfiguration";
import { InstantSetProgress } from "adminComponents/molecules/InstantSetProgress";
import { pxToRem } from "adminComponents/utils";
import {
  GenerateInstantSetStatus,
  IGradeLevel,
  IPracticeSet,
  IPracticeSetItem,
  IUserGradeLevel,
} from "links/lib/types";

export type IGenerateState = {
  status: GenerateInstantSetStatus;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  startTime?: Date;
  lastUpdate?: Date;
  practiceSet?: IPracticeSet;
  practiceSetItems?: Array<IPracticeSetItem>;
  errorMessage?: string;
};

export interface IInstantSetFlyoutProps {
  userGradeLevels: Array<IUserGradeLevel>;
  gradeLevels: Array<IGradeLevel>;
  generateState: IGenerateState;
  isOpen: boolean;
  isSaving?: boolean;
  showBack?: boolean;
  defaultValue?: IInstantSetConfig;
  handleBack?: () => void;
  handleClose: () => void;
  handleRestart: () => void;
  handleRetry: () => void;
  handleSave: () => void;
  handleGenerate: (config: IInstantSetConfig) => void;
  handleGenerateComplete?: () => void;
}

export const InstantSetFlyout: React.FC<IInstantSetFlyoutProps> = ({
  userGradeLevels,
  gradeLevels,
  generateState,
  defaultValue,
  isOpen,
  isSaving,
  showBack,
  handleBack,
  handleClose,
  handleRestart,
  handleRetry,
  handleGenerate,
  handleGenerateComplete,
  handleSave,
}) => {
  const { t } = useTranslation("admin", {
    keyPrefix: "instantSetFlyout",
    useSuspense: false,
  });

  /*
  Attempts to set default grade level by:
  1. Using the lowest user grade level
  2. Using the first grade level in the grade list
  3. Defaulting to undefined
  */
  const defaultGradeLevel = useMemo(() => {
    const minUserGradeLevel = userGradeLevels
      .reduce((prev: number, cur: IUserGradeLevel) => {
        if (!prev) return Number(cur.grade_level_id);
        return Number(cur.grade_level_id) < prev ? Number(cur.id) : prev;
      }, 0)
      .toString();

    if (minUserGradeLevel !== "0") return minUserGradeLevel;

    if (gradeLevels.length) return gradeLevels[0].id;

    return undefined;
  }, [userGradeLevels, gradeLevels]);

  /**
   * Handles back button click on flyout based on current state
   */
  const _handleBack = () => {
    // Reset the state if the generation if back is clicked during generation or result
    if (
      generateState.isLoading ||
      generateState.isSuccess ||
      generateState.isError
    ) {
      handleRestart();
    } else {
      handleBack?.();
    }
  };

  const showConfig =
    !generateState.isLoading &&
    !generateState.isSuccess &&
    !generateState.isError;

  const title = showConfig ? t("title") : "";
  const subtitle = showConfig ? t("subtitle") : undefined;

  const footerContent =
    generateState.isSuccess && !generateState.isLoading ? (
      <HStack spacing={pxToRem(10)}>
        <Button
          onClick={handleSave}
          variant="adminButtonFilled"
          leftIcon={<Icon icon="add_circle_outlined" boxSize={pxToRem(16)} />}
          isLoading={isSaving}
        >
          {t("saveButton")}
        </Button>
        <Button
          isLoading={isSaving}
          onClick={handleRestart}
          variant="adminButtonOutlinedMuted"
        >
          {t("backButton")}
        </Button>
      </HStack>
    ) : undefined;

  return (
    <AdminFlyout
      isOpen={isOpen}
      showBack={showBack}
      title={title}
      subtitle={subtitle}
      onClose={handleClose}
      onBack={_handleBack}
      footerContent={footerContent}
    >
      {showConfig && (
        <InstantSetConfiguration
          defaultValue={
            defaultValue ??
            (defaultGradeLevel
              ? { gradeLevelId: defaultGradeLevel, questionExtraction: true }
              : undefined)
          }
          gradeLevels={gradeLevels}
          handleGenerate={handleGenerate}
        />
      )}

      {generateState.isLoading && (
        <InstantSetProgress
          startTime={generateState.startTime}
          lastUpdate={generateState.lastUpdate}
          status={generateState.status}
          handleRestart={handleRestart}
          handleComplete={handleGenerateComplete}
        />
      )}

      {generateState.isSuccess &&
        generateState.practiceSet &&
        generateState.practiceSetItems && (
          <GeneratePracticeSetResult
            items={generateState.practiceSetItems}
            practiceSet={generateState.practiceSet}
          />
        )}

      {generateState.isError && (
        <Box
          px={[
            "admin.flyout.mobileXPadding",
            null,
            "admin.flyout.desktopXPadding",
          ]}
        >
          <VStack alignItems="flex-start" spacing={pxToRem(30)}>
            <VStack spacing={pxToRem(6)} alignItems="flex-start">
              <Heading as="p" variant="adminH5Bold" color="utility.error">
                {t("errorTitle")}
              </Heading>
              <Text variant="adminP2">{generateState.errorMessage}</Text>
            </VStack>
            <Divider color="primary.light-gray" />
            <VStack spacing={pxToRem(20)} alignItems="flex-start">
              <Text variant="adminP1">{t("errorSubtitle")}</Text>
              <HStack spacing={pxToRem(10)}>
                <Button
                  onClick={handleRetry}
                  variant="adminButtonFilled"
                  leftIcon={
                    <Icon icon="refresh_outlined" boxSize={pxToRem(16)} />
                  }
                >
                  {t("retryButton")}
                </Button>
                <Button
                  onClick={handleRestart}
                  variant="adminButtonOutlinedMuted"
                >
                  {t("backButton")}
                </Button>
              </HStack>
            </VStack>
          </VStack>
        </Box>
      )}
    </AdminFlyout>
  );
};
