import {
  Textarea as ChakraTextarea,
  TextareaProps as IProps,
} from "@chakra-ui/react";
import React from "react";

const Textarea = React.forwardRef<HTMLTextAreaElement, IProps>((p, ref) => {
  return <ChakraTextarea ref={ref} {...p} />;
});

Textarea.displayName = "Textarea";

Textarea.defaultProps = {
  variant: "adminTextarea",
};
export { Textarea };
