import { Box, SimpleGrid } from "@chakra-ui/react";
import React, { useRef } from "react";
import { useDrop } from "react-dnd";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { ISessionDraggableSortItem, SessionGameType } from "links/lib/types";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { TabQuestionGroupView } from "sessionComponents/molecules/TabQuestionGroupView";
import { boxShadows } from "sessionComponents/theme/boxShadows";

import { GameBackground } from "../GameBackground";

interface IPortraitDragsortLayoutProps {
  outerGame?: SessionGameType;
  questionComponent: React.ReactElement;
  answerOptionsComponent: React.ReactElement;
  confirmAreaComponent?: React.ReactElement;
  groupStatusComponent?: React.ReactElement; //only on teacher view
  teamsLength?: number; //only on teacher view
  isTeacher?: boolean;
}

export const PortraitDragsortLayout: React.FC<IPortraitDragsortLayoutProps> = ({
  outerGame = SessionGameType.TheBigBoard,
  questionComponent,
  answerOptionsComponent,
  confirmAreaComponent,
  groupStatusComponent,
  teamsLength,
}) => {
  const { match: currentBreakpoints } = useBreakpoints();
  const scrollBoxRef = useRef<HTMLDivElement | null>(null);
  const confirmAreaComponentWrapperRef = useRef<HTMLDivElement | null>(null);
  const buttonHeight =
    confirmAreaComponentWrapperRef.current?.getBoundingClientRect().height || 0;

  const [, connectDrop] = useDrop({
    accept: "LABEL",
    hover(item: ISessionDraggableSortItem, monitor) {
      const moveThreshold = 20; // arbitrary number of y px to scroll
      const sourcePosition = monitor.getSourceClientOffset()?.y;
      if (!sourcePosition) return;
      const reachedTop = sourcePosition <= currentBreakpoints.headerHeight; // off screen not at 0 but rather at bottom of header
      const reachedBottom =
        sourcePosition >=
        window.innerHeight -
          (currentBreakpoints.headerHeight + currentBreakpoints.responseHeight); // start scrolling down as dragged item is approaching bottom of screen

      if (reachedTop || reachedBottom) {
        const scrollUpIncrement = sourcePosition - moveThreshold;
        const scrollDownIncrement = sourcePosition + moveThreshold;

        scrollBoxRef?.current?.scroll({
          top: reachedTop ? scrollUpIncrement : scrollDownIncrement,
          behavior: "smooth",
        });
      }
    },
  });

  connectDrop(scrollBoxRef);

  return (
    <>
      <SimpleGrid
        columns={1}
        spacing={0}
        width="full"
        height="full"
        borderTopWidth={pxToRem(currentBreakpoints.borderWidth)}
        borderTopColor="primary.tan"
        overflowY="auto"
        ref={scrollBoxRef}
        sx={{
          WebkitTouchCallout: "none",
          WebkitTextSizeAdjust: "none",
          WebkitUserSelect: "none",
        }}
      >
        <Box display="flex" h="full">
          <GameBackground outerGame={outerGame}>
            <Box
              h="100%"
              w="100%"
              display="flex"
              alignItems="flex-start"
              justifyContent="center"
              overflow="auto"
            >
              <Box minW={0} h="fit-content" w="100%">
                {questionComponent}
              </Box>
            </Box>
          </GameBackground>
        </Box>
        <Box
          backgroundColor="primary.warm-white"
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          flexDir="column"
          width="100%"
        >
          <Box
            padding={pxToRem(currentBreakpoints.padding)}
            paddingBottom={0}
            paddingLeft={groupStatusComponent ? 0 : undefined}
            paddingRight={groupStatusComponent ? 0 : undefined}
            h="100%"
            w="100%"
            marginBottom={pxToRem(buttonHeight)}
          >
            {groupStatusComponent ? (
              <Box w="full" h="full">
                <TabQuestionGroupView
                  answerOptionsComponent={answerOptionsComponent}
                  groupStatusComponent={groupStatusComponent}
                  teamsLength={teamsLength || 0}
                />
              </Box>
            ) : (
              <Box
                w="full"
                h="full"
                overflow="hidden"
                transition="all .3s ease-in"
              >
                {answerOptionsComponent}
              </Box>
            )}
          </Box>
          {!!confirmAreaComponent && (
            <Box
              ref={confirmAreaComponentWrapperRef}
              position="absolute"
              bottom="0"
              width="100%"
              borderTopWidth={pxToRem(currentBreakpoints.borderWidth)}
              borderTopColor="primary.tan"
              display="flex"
              justifyContent="center"
              alignItems="center"
              padding={pxToRem(currentBreakpoints.padding)}
              backgroundColor="primary.warm-white"
              boxShadow={boxShadows.questionLayout}
              zIndex={2}
            >
              {confirmAreaComponent}
            </Box>
          )}
        </Box>
      </SimpleGrid>
    </>
  );
};
