import { Box, FormControl, SimpleGrid } from "@chakra-ui/react";
import { useFormik } from "formik";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Checkbox } from "adminComponents/atoms/Checkbox";
import { FormErrorMessage } from "adminComponents/atoms/FormErrorMessage";
import { FormLabel } from "adminComponents/atoms/FormLabel";
import { Input } from "adminComponents/atoms/Input";
import { Text } from "adminComponents/atoms/Text";
import { AdminFlyout } from "adminComponents/molecules/Flyout";
import { pxToRem } from "adminComponents/utils/pxToRem";
// INTEGRATION TODO: replace use of IClassroom type shim when real type updated
import { IClassroom, IGradeLevel, ISubject, ThirdParty } from "links/lib/types";

export type ClassroomFormData = {
  assignment_notifications_disabled: boolean;
  name: string;
  description: string;
  grade_level_ids: string[];
  subject_ids: string[];
  third_party?: ThirdParty;
  third_party_id?: string;
};

type FormFieldKey = keyof ClassroomFormData;

const initialValues: ClassroomFormData = {
  assignment_notifications_disabled: false,
  description: "",
  name: "",
  grade_level_ids: [],
  subject_ids: [],
};

interface ClassroomFlyoutProps {
  isOpen: boolean;
  isLoading: boolean;
  defaultValue?: IClassroom;
  grades: IGradeLevel[];
  subjects: ISubject[];
  handleClose: () => void;
  handleSubmit: (classroom: ClassroomFormData, classroomId?: string) => void;
}

export const ClassroomFlyout: React.FC<ClassroomFlyoutProps> = ({
  isOpen,
  isLoading,
  defaultValue,
  grades,
  subjects,
  handleSubmit,
  handleClose,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const title = defaultValue
    ? t("classroomFlyout.editTitle")
    : t("classroomFlyout.addTitle");

  const ctaText = defaultValue
    ? t("common.saveAndClose")
    : t("classroomFlyout.createClassroom");

  // Sorts grades into columns
  // Kin 7th
  // 1st 8th
  // 2nd 9th
  // ...
  const sortedGrades = useMemo(() => {
    const columnGrades = [
      grades.slice(0, Math.ceil(grades.length / 2)),
      grades.slice(Math.ceil(grades.length / 2), grades.length),
    ];
    return columnGrades[0].flatMap((grade, i) => {
      const grades = [grade];
      if (columnGrades[1][i]) {
        grades.push(columnGrades[1][i]);
      }
      return grades;
    });
  }, [grades]);

  // Places "Other" subject at the end
  const sortedSubjects = useMemo(() => {
    return [
      ...subjects.filter((subject) => subject.name !== "Other"),
      ...subjects.filter((subject) => subject.name === "Other"),
    ];
  }, [subjects]);

  const formik = useFormik<ClassroomFormData>({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: defaultValue
      ? {
          assignment_notifications_disabled:
            defaultValue.assignment_notifications_disabled,
          description: defaultValue.description,
          grade_level_ids:
            defaultValue?.group_grade_levels?.map(
              (grade_level) => grade_level.grade_level_id
            ) ?? [],
          name: defaultValue.name,
          subject_ids:
            defaultValue?.group_subjects?.map(
              (subject) => subject.subject_id
            ) ?? [],
          third_party: defaultValue.third_party,
          third_party_id: defaultValue.third_party_id,
        }
      : initialValues,
    onSubmit: (values) => handleSubmit(values, defaultValue?.id),
    validate: (values) => {
      const errors = {} as Record<FormFieldKey, string>;
      if (!values.name.trim()) {
        errors.name = t("classroomFlyout.requiredName");
      }
      return errors;
    },
  });

  useEffect(() => {
    if (!isOpen) return;
    if (defaultValue) {
      formik.setValues({
        assignment_notifications_disabled:
          defaultValue.assignment_notifications_disabled,
        description: defaultValue.description,
        grade_level_ids:
          defaultValue?.group_grade_levels?.map(
            (grade_level) => grade_level.grade_level_id
          ) ?? [],
        name: defaultValue.name,
        subject_ids:
          defaultValue?.group_subjects?.map((subject) => subject.subject_id) ??
          [],
        third_party: defaultValue.third_party,
        third_party_id: defaultValue.third_party_id,
      });
    } else {
      formik.setValues(initialValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, defaultValue]);

  const handleChangeGrade = (gradeId: string, checked: boolean) => {
    const previousGrades = [...formik.values.grade_level_ids];
    if (checked) {
      formik.setFieldValue("grade_level_ids", [...previousGrades, gradeId]);
    } else {
      formik.setFieldValue(
        "grade_level_ids",
        previousGrades.filter(
          (classroomGradeId) => classroomGradeId !== gradeId
        )
      );
    }
  };

  const handleChangeSubject = (subjectId: string, checked: boolean) => {
    const previousSubjects = [...formik.values.subject_ids];
    if (checked) {
      formik.setFieldValue("subject_ids", [...previousSubjects, subjectId]);
    } else {
      formik.setFieldValue(
        "subject_ids",
        previousSubjects.filter(
          (classroomSubjectId) => classroomSubjectId !== subjectId
        )
      );
    }
  };

  return (
    <AdminFlyout
      variant="adminFlyoutDefault"
      isOpen={isOpen}
      onClose={handleClose}
      title={title}
    >
      <SimpleGrid
        px={[
          "admin.flyout.mobileXPadding",
          null,
          "admin.flyout.desktopXPadding",
        ]}
        pb={pxToRem(56)}
        gap={[pxToRem(32), null, pxToRem(40)]}
      >
        <Text mb={[pxToRem(-32), null, pxToRem(-40)]}>
          {t("classroomFlyout.classroomDescription")}
        </Text>
        <Box h={pxToRem(1)} bg="primary.medium-gray" />
        <FormControl
          isRequired
          isInvalid={!!formik.errors.name && !!formik.touched.name}
          isDisabled={isLoading}
        >
          <FormLabel>{t("classroomFlyout.classroomName")}</FormLabel>
          <Input
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
        </FormControl>
        <FormControl isDisabled={isLoading}>
          <FormLabel>{t("classroomFlyout.periodOrSection")}</FormLabel>
          <Input
            name="description"
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </FormControl>
        <FormControl>
          <FormLabel>{t("classroomFlyout.selectGrades")}</FormLabel>
          <SimpleGrid gap={pxToRem(12)} columns={2}>
            {sortedGrades.map((grade) => (
              <Checkbox
                key={grade.id}
                isChecked={formik.values.grade_level_ids.some(
                  (classroomGradeLevelId) => classroomGradeLevelId === grade.id
                )}
                onChange={(e) => handleChangeGrade(grade.id, e.target.checked)}
                isDisabled={isLoading}
              >
                {grade.grade_level}
              </Checkbox>
            ))}
          </SimpleGrid>
        </FormControl>
        <FormControl isDisabled={isLoading}>
          <FormLabel>{t("classroomFlyout.subjects")}</FormLabel>
          <SimpleGrid gap={pxToRem(12)} columns={2}>
            {sortedSubjects.map((subject) => (
              <Checkbox
                key={subject.id}
                isChecked={formik.values.subject_ids.some(
                  (classroomSubjectId) => classroomSubjectId === subject.id
                )}
                isDisabled={isLoading}
                onChange={(e) =>
                  handleChangeSubject(subject.id, e.target.checked)
                }
              >
                {subject.name}
              </Checkbox>
            ))}
          </SimpleGrid>
        </FormControl>
        <FormControl isDisabled={isLoading}>
          <FormLabel>{t("classroomFlyout.assignmentNotifications")}</FormLabel>
          <Checkbox
            name="assignment_notifications_disabled"
            isChecked={formik.values.assignment_notifications_disabled}
            isDisabled={isLoading}
            onChange={formik.handleChange}
          >
            {t("classroomFlyout.disableAssignmentNotifications")}
          </Checkbox>
        </FormControl>
        <Box>
          <Button
            size="lg"
            variant="adminButtonFilled"
            onClick={formik.submitForm}
            isDisabled={!!formik.errors.name}
            isLoading={isLoading}
          >
            {ctaText}
          </Button>
        </Box>
      </SimpleGrid>
    </AdminFlyout>
  );
};
