import { Alert, Box, Center, Checkbox, HStack, Image } from "@chakra-ui/react";
import React from "react";

type AttachmentSelectorProps = {
  slotName: string;
  isChecked: boolean;
  imageBase64: string;
  onSelectSlot: (slotName: string) => void;
};

export const AttachmentSelector: React.FC<AttachmentSelectorProps> = ({
  slotName,
  isChecked,
  imageBase64,
  onSelectSlot,
}: AttachmentSelectorProps) => {
  return (
    <HStack
      w="full"
      p="12px 24px"
      key={slotName}
      borderWidth="1px"
      borderColor="gray.200"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onSelectSlot(slotName);
      }}
      cursor="pointer"
    >
      <Box w="24px">
        <Checkbox isChecked={isChecked} disabled={!imageBase64}></Checkbox>
      </Box>
      <Box w="128px">{slotName}</Box>
      <Box textAlign="center">
        {imageBase64 ? (
          <Center bgColor="gray.100" p="10px" w="full">
            <Image
              h="64px"
              w="64px"
              src={`data:image/svg+xml;base64,${imageBase64}`}
            />
          </Center>
        ) : (
          <Alert status="error">Image not found</Alert>
        )}
      </Box>
    </HStack>
  );
};
