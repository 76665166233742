import { pxToRem } from "adminComponents/utils";

export const AdminAvatarEditingCard = {
  baseStyle: (): Record<string, unknown> => ({
    card: {
      alignItems: "center",
      borderRadius: {
        base: pxToRem(8),
        lg: pxToRem(12),
      },
      cursor: "pointer",
      display: "flex",
      height: {
        base: pxToRem(75),
        lg: "auto",
      },
      justifyContent: "center",
      width: {
        base: pxToRem(75),
        lg: "fit-content",
      },
      padding: {
        base: pxToRem(12),
        lg: pxToRem(30),
      },
      overflow: "hidden",
      img: {
        maxHeight: "100%",
      },
    },
  }),
};
