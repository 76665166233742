import { ICoverImageIconProps } from "adminComponents/atoms/CoverImageIcon";
import { pxToRem } from "adminComponents/utils/pxToRem";

import { colorMapping } from "../colors";

interface IProps extends ICoverImageIconProps {
  rgbBackgroundColor: string;
}

export const AdminCoverImageIcon = {
  baseStyle: ({
    borderColor,
    backgroundColor,
    rgbBackgroundColor,
    iconSize,
    disabled,
  }: IProps): Record<string, unknown> => ({
    container: {
      display: "inline-flex",
      position: "relative",
    },
    border: {
      color: borderColor,
      width: iconSize ? pxToRem(iconSize) : pxToRem(60),
      height: iconSize ? pxToRem(iconSize) : pxToRem(60),

      svg: {
        fill: rgbBackgroundColor
          ? rgbBackgroundColor
          : backgroundColor
          ? colorMapping[backgroundColor]
          : colorMapping["primary.golden-light"],
      },
    },
    iconContainer: {
      left: "50%",
      position: "absolute",
      top: "50%",
      transform: "auto",
      translateX: "-50%",
      translateY: "-50%",
      zIndex: 1,
    },
    icon: {
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
      backgroundSize: "contain",
      opacity: disabled ? "0.6" : "1",
    },
  }),
};
