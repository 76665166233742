import { Box, Text } from "@chakra-ui/react";
import React from "react";

import { pxToRem } from "adminComponents/utils/pxToRem";

import { useBreakpoints } from "../../contexts/breakpoints";
import { SessionProgressBar } from "../SessionProgressBar";

interface IHeaderProps {
  headingText: string;
  // value between 0 and 100 which will show progress bar if present
  progress?: number;
  noFormat?: boolean;
}

const Header: React.FC<IHeaderProps> = ({
  headingText,
  progress,
  noFormat,
  children,
}) => {
  const { match: currentBreakpoints } = useBreakpoints();

  const headerHeight = pxToRem(currentBreakpoints.headerHeight);
  const headerXPadding = pxToRem(currentBreakpoints.padding);
  const headingFontSize = pxToRem(currentBreakpoints.fontSize);
  const borderWidth = pxToRem(currentBreakpoints.borderWidth);
  const progressHeight = pxToRem(currentBreakpoints.buttonHeight * 0.75);

  return (
    <Box
      display="flex"
      w="full"
      h={headerHeight}
      alignItems="center"
      justifyContent="space-between"
      px={headerXPadding}
      bg="primary.warm-white"
      borderBottomWidth={borderWidth}
      borderBottomColor="primary.tan"
    >
      {noFormat && <>{children}</>}
      {!noFormat && (
        <>
          {typeof progress === "number" ? (
            <Box w="full" pr={pxToRem(currentBreakpoints.buttonHeight)}>
              <SessionProgressBar progress={progress} h={progressHeight} />
            </Box>
          ) : (
            <Box w="fit-content">
              <Text
                whiteSpace="nowrap"
                fontSize={headingFontSize}
                as="h1"
                textStyle="gameDisplayInline"
              >
                {headingText}
              </Text>
            </Box>
          )}
          <Box w="fit-content">{children}</Box>
        </>
      )}
    </Box>
  );
};

export { Header };
