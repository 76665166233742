import { colorMapping } from "adminComponents/theme/colors";
import { pxToRem } from "adminComponents/utils";

interface IProps {
  rgbBackgroundColor: string;
  isSmall: boolean;
}

export const AdminStudentAssignmentCard = {
  baseStyle: ({
    rgbBackgroundColor,
    isSmall,
  }: IProps): Record<string, unknown> => ({
    container: {
      paddingTop: pxToRem(3),
      paddingLeft: pxToRem(3),
      paddingRight: pxToRem(3),
      paddingBottom: pxToRem(3),
      borderRadius: pxToRem(20),
      overflow: "hidden",
    },
    header: {
      bg: rgbBackgroundColor ? rgbBackgroundColor : "primary.golden-light",
      justifyContent: isSmall ? "flex-end" : "flex-start",
      alignItems: "center",
      borderTopLeftRadius: pxToRem(15),
      borderTopRightRadius: pxToRem(15),
      paddingLeft: pxToRem(20),
      paddingRight: pxToRem(20),
      paddingTop: pxToRem(15),
      paddingBottom: pxToRem(15),
      zIndex: 1,
    },
    title: {
      flex: !isSmall && 1,
      justifyContent: "space-between",
      alignItems: "center",
      marginLeft: !isSmall && pxToRem(16),
      marginRight: !isSmall && pxToRem(30),
    },
    iconContainer: {
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      top: pxToRem(30),
      left: pxToRem(30),
    },
    icon: {
      width: pxToRem(44),
      height: pxToRem(28),
    },
    content: {
      padding: pxToRem(30),
      paddingTop: isSmall ? pxToRem(60) : pxToRem(40),
    },
    contentTop: {
      alignItems: "flex-start",
      justifyContent: "space-between",
    },
    contentBottom: {
      flex: !isSmall && 1,
      justifyContent: !isSmall && "flex-end",
      whiteSpace: "nowrap",
    },
    playButton: {
      paddingLeft: isSmall ? pxToRem(15) : pxToRem(30),
      position: "unset",
      "> span > svg": {
        width: pxToRem(48),
        height: pxToRem(48),
      },

      _focusVisible: {
        _after: {
          boxShadow: `0 0 0 ${pxToRem(3)} inset ${
            colorMapping["utility.focus"]
          }`,
        },
      },

      _after: {
        content: "''",
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        borderRadius: pxToRem(20),
      },
    },
    divider: {
      marginTop: pxToRem(24),
      marginBottom: pxToRem(24),
    },
    date: {
      marginTop: pxToRem(16),
    },
    progress: {
      marginTop: pxToRem(16),
    },
    xp: {
      marginLeft: pxToRem(8),
    },
  }),
};
