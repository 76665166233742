import { useCallback } from "react";

export interface IResult {
  vibrate: (duration: number) => void;
}

export const useVibrate = (): IResult => {
  const vibrate = useCallback((duration: number) => {
    if (navigator && "vibrate" in navigator) {
      navigator.vibrate(duration);
    }
  }, []);

  return {
    vibrate,
  };
};
