import { Box } from "@chakra-ui/react";
import React from "react";

import { LoadingSpinner } from "adminComponents/atoms/LoadingSpinner";
import { StandardsTab as AdminStandardsTab } from "adminComponents/screens/StudentDetailScreen/components/StandardsTab";
import { pxToRem } from "adminComponents/utils";
import { usePageTrack } from "lib/contexts/analytics";
import { AnalyticsPage } from "links/lib/types";
import { useStandardsData } from "screens/TeacherClassrooms/StudentDetail/hooks/useStandardsData";

interface IStandardsTabProps {
  classroomId: string;
  studentId: string;
}

export const StandardsTab: React.FC<IStandardsTabProps> = ({
  classroomId,
  studentId,
}) => {
  usePageTrack(
    AnalyticsPage.TeacherDashboard_ClassroomDetail_StudentDetail_Standards
  );

  const { isLoading, standardsData } = useStandardsData({
    classroomId,
    studentId,
  });

  return (
    <>
      {isLoading ? (
        <Box mt={pxToRem(20)}>
          <LoadingSpinner />
        </Box>
      ) : (
        <>
          <AdminStandardsTab standardsData={standardsData} />
        </>
      )}
    </>
  );
};
