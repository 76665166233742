import React, { useCallback } from "react";

import { useMutateAuthUserProfileImage } from "links/lib/features/account";
import { useUploadFile } from "links/lib/features/files";
import { IFetchAvatarResponse } from "links/lib/types";
import { AvatarPhoto } from "sharedComponents/molecules/AvatarPhoto";

export interface IArgs {
  avatarResponse?: IFetchAvatarResponse;
}

export const useUpdateProfileImage = ({
  avatarResponse,
}: IArgs): React.ReactElement => {
  const mutateProfileImage = useMutateAuthUserProfileImage();

  const onFileUploadSuccess = useCallback(
    (data) => {
      if (!data) return;

      mutateProfileImage.mutate({ profile_image_url: data.url });
    },
    [mutateProfileImage]
  );

  const fileUpload = useUploadFile({ onSuccess: onFileUploadSuccess });

  const captureHandler = useCallback(
    (blob: Blob) => {
      fileUpload.execute({ file: new File([blob], "profile-image.png") });
    },
    [fileUpload]
  );

  if (
    !avatarResponse ||
    !avatarResponse.avatar ||
    !avatarResponse.spine_atlas_url ||
    !avatarResponse.spine_json_url ||
    !avatarResponse.spritesheet_url
  ) {
    return <></>;
  }

  return (
    <>
      <AvatarPhoto
        skeletonType={avatarResponse.avatar.skeleton_type}
        skeletonUrl={avatarResponse.spine_json_url}
        atlasUrl={avatarResponse.spine_atlas_url}
        captureHandler={captureHandler}
        debugMode={false}
      />
    </>
  );
};
