import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import {
  Box,
  HStack,
  Show,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Avatar } from "adminComponents/atoms/Avatar";
import { Button } from "adminComponents/atoms/Button";
import { Card } from "adminComponents/atoms/Card";
import { Dropdown, IOption } from "adminComponents/atoms/Dropdown";
import { Heading } from "adminComponents/atoms/Heading";
import { ConfirmModal } from "adminComponents/molecules/ConfirmModal";
import { EmptyCard } from "adminComponents/molecules/EmptyCard";
import { IconButtonDropdown } from "adminComponents/molecules/IconButtonDropdown";
import {
  INavigationHeaderProps,
  OriginalNavigationHeader,
} from "adminComponents/molecules/NavigationHeader";
import { Pagination } from "adminComponents/molecules/Pagination";
import { SearchInput } from "adminComponents/molecules/SearchInput";
import { TemplateWithCenteredHeroOneColumn } from "adminComponents/templates/TemplateWithCenteredHeroOneColumn";
import { generateTeacherName, pxToRem } from "adminComponents/utils";
import { useAuth } from "links/lib/features/auth";
import {
  SortBy,
  SortDir,
} from "links/lib/features/organizations/useFetchOrganizationUsers";
import { IUser, UserRole } from "links/lib/types";

export interface IProps {
  adminMode?: boolean;
  navigationData: INavigationHeaderProps;
  teacher: IUser;
  users: Array<IUser>;
  page: number;
  perPage: number;
  totalCount: number;
  isLoading?: boolean;
  query: string;
  sortBy: SortBy;
  sortDir: SortDir;
  handleAddUsers: () => void;
  handleChangeUserRole: (userId: string, userRole: UserRole) => void;
  handleRemoveUser: (userId: string) => void;
  handleSetPage: (page: number) => void;
  handleSetQuery: (query: string) => void;
  handleSortBy: (sortBy: SortBy) => void;
}

export const TeacherMyTeachersScreen: React.FC<IProps> = ({
  navigationData,
  teacher,
  users,
  isLoading,
  page,
  perPage,
  totalCount,
  query,
  sortBy,
  sortDir,
  handleAddUsers,
  handleChangeUserRole,
  handleRemoveUser,
  handleSetPage,
  handleSetQuery,
  handleSortBy,
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "teacherStaffManagement",
  });
  const { t: tCommon } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "common",
  });
  const { authUser } = useAuth();

  const [confirmRemoveUserId, setConfirmRemoveUserId] = useState("0");

  const {
    isOpen: isOpenConfirmRemoveModal,
    onClose: onCloseConfirmRemoveModal,
    onOpen: onOpenConfirmRemoveModal,
  } = useDisclosure();

  const navData = {
    isLoading,
    ...navigationData,
    variant: "teacherHomepageNav",
  };

  const mobileSortOptions: IOption[] = [
    {
      value: SortBy.GivenName,
      label: tCommon("firstName"),
    },
    {
      value: SortBy.FamilyName,
      label: tCommon("lastName"),
    },
    {
      value: SortBy.Email,
      label: tCommon("email"),
    },
    {
      value: SortBy.Role,
      label: tCommon("role"),
    },
  ];

  const roleOptions: IOption[] = [
    {
      value: UserRole.Teacher,
      label: t("labelTeacher"),
    },
    {
      value: UserRole.Admin,
      label: t("labelAdmin"),
    },
  ];

  const onClearQuery = () => {
    handleSetQuery("");
  };

  const onCloseConfirmRemove = (isConfirmed?: boolean) => {
    onCloseConfirmRemoveModal();

    if (!isConfirmed) return;

    handleRemoveUser(confirmRemoveUserId);
  };

  const onRemoveUser = (userId: string) => () => {
    setConfirmRemoveUserId(userId);
    onOpenConfirmRemoveModal();
  };

  const onSortBy = (sortBy: SortBy) => () => {
    handleSortBy(sortBy);
  };

  const onChangeRole = (userId: string) => (option: unknown) => {
    const user = users.find((user) => user.id === userId);
    if (!user) return;

    const role = (option as IOption).value as UserRole;
    if (user.role === role) return;

    handleChangeUserRole(userId, role);
  };

  return (
    <TemplateWithCenteredHeroOneColumn
      isLoading={isLoading}
      nav={<OriginalNavigationHeader {...navData} />}
      heroContent={
        <Heading as="h1" variant="adminH2">
          {t("heading")}
        </Heading>
      }
      adminMode={navData.adminMode}
    >
      <VStack w="full">
        <HStack w="full">
          <Box flex={{ base: 3, lg: 4, xl: 5 }}>
            <SearchInput
              handleClear={onClearQuery}
              handleSearch={handleSetQuery}
              initialSearch={query}
              placeholder={tCommon("search")}
              searching={isLoading}
            />
          </Box>
          <Button
            flex="1"
            variant="adminButtonFilled"
            size="md"
            onClick={handleAddUsers}
          >
            {t("buttonAddStaffUsers")}
          </Button>
        </HStack>
        {users.length > 0 ? (
          <>
            <Show above="lg">
              <Table variant="unstyled">
                <Thead>
                  <Tr>
                    <Th></Th>
                    <Th
                      onClick={onSortBy(SortBy.GivenName)}
                      cursor="pointer"
                      textTransform="none"
                    >
                      <HStack>
                        <Text whiteSpace="nowrap">{tCommon("firstName")}</Text>
                        {sortBy === SortBy.GivenName && (
                          <>
                            {sortDir === SortDir.Asc ? (
                              <ChevronDownIcon />
                            ) : (
                              <ChevronUpIcon />
                            )}
                          </>
                        )}
                      </HStack>
                    </Th>
                    <Th
                      onClick={onSortBy(SortBy.FamilyName)}
                      cursor="pointer"
                      textTransform="none"
                    >
                      <HStack>
                        <Text whiteSpace="nowrap">{tCommon("lastName")}</Text>
                        {sortBy === SortBy.FamilyName && (
                          <>
                            {sortDir === SortDir.Asc ? (
                              <ChevronDownIcon />
                            ) : (
                              <ChevronUpIcon />
                            )}
                          </>
                        )}
                      </HStack>
                    </Th>
                    <Th
                      onClick={onSortBy(SortBy.Email)}
                      cursor="pointer"
                      textTransform="none"
                    >
                      <HStack>
                        <Text>{tCommon("email")}</Text>
                        {sortBy === SortBy.Email && (
                          <>
                            {sortDir === SortDir.Asc ? (
                              <ChevronDownIcon />
                            ) : (
                              <ChevronUpIcon />
                            )}
                          </>
                        )}
                      </HStack>
                    </Th>
                    <Th
                      onClick={onSortBy(SortBy.Role)}
                      cursor="pointer"
                      textTransform="none"
                    >
                      <HStack>
                        <Text>{tCommon("role")}</Text>
                        {sortBy === SortBy.Role && (
                          <>
                            {sortDir === SortDir.Asc ? (
                              <ChevronDownIcon />
                            ) : (
                              <ChevronUpIcon />
                            )}
                          </>
                        )}
                      </HStack>
                    </Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {users.map((user) => {
                    return (
                      <Tr key={`user-${user.id}`}>
                        <Td
                          bgColor="white"
                          borderTopLeftRadius={pxToRem(12)}
                          borderBottomLeftRadius={pxToRem(12)}
                          borderBottomColor="primary.warm-white"
                          borderBottomWidth={pxToRem(12)}
                        >
                          <Avatar
                            src={user.profile_image_url}
                            name={generateTeacherName(user).primary}
                          ></Avatar>
                        </Td>
                        <Td
                          bgColor="white"
                          borderBottomColor="primary.warm-white"
                          borderBottomWidth={pxToRem(12)}
                        >
                          {user.given_name}
                        </Td>
                        <Td
                          bgColor="white"
                          borderBottomColor="primary.warm-white"
                          borderBottomWidth={pxToRem(12)}
                        >
                          {user.family_name}
                        </Td>
                        <Td
                          bgColor="white"
                          borderBottomColor="primary.warm-white"
                          borderBottomWidth={pxToRem(12)}
                        >
                          {user.email}
                        </Td>
                        <Td
                          bgColor="white"
                          borderBottomColor="primary.warm-white"
                          borderBottomWidth={pxToRem(12)}
                          minW={pxToRem(180)}
                        >
                          {user.role === UserRole.ContentSpecialist ? (
                            <>{t("labelContentSpecialist")}</>
                          ) : (
                            <Dropdown
                              isDisabled={
                                teacher.id === user.id ||
                                (user.role !== UserRole.Teacher &&
                                  user.role !== UserRole.Admin)
                              }
                              options={roleOptions}
                              value={roleOptions.find(
                                (opt) => opt.value === user.role
                              )}
                              handleChange={onChangeRole(user.id)}
                            />
                          )}
                        </Td>
                        <Td
                          bgColor="white"
                          borderTopRightRadius={pxToRem(12)}
                          borderBottomRightRadius={pxToRem(12)}
                          borderBottomColor="primary.warm-white"
                          borderBottomWidth={pxToRem(12)}
                        >
                          <Button
                            variant="adminButtonOutlined"
                            onClick={
                              teacher.id !== user.id
                                ? onRemoveUser(user.id)
                                : undefined
                            }
                            disabled={
                              teacher.id === user.id ||
                              user.role === UserRole.ContentSpecialist
                            }
                            size="sm"
                          >
                            {tCommon("remove")}
                          </Button>
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </Show>
            <Show below="lg">
              <VStack
                pt={pxToRem(16)}
                pb={pxToRem(16)}
                spacing={pxToRem(16)}
                w="full"
              >
                <HStack w="full">
                  <Text whiteSpace="nowrap">{tCommon("sortBy")}</Text>
                  <Box flex="1" zIndex="5">
                    <Dropdown
                      value={mobileSortOptions.find(
                        (opt) => opt.value === sortBy
                      )}
                      options={mobileSortOptions}
                      handleChange={(opt) => {
                        onSortBy((opt as IOption).value as SortBy)();
                      }}
                    />
                  </Box>
                </HStack>

                <VStack w="full">
                  {users.map((user) => {
                    return (
                      <Card
                        key={`user-${user.id}`}
                        variant="adminCardMediumBorder"
                        borderColor="primary.tan"
                        backgroundColor="primary.white"
                      >
                        <HStack
                          w="full"
                          color="primary.warm-black"
                          spacing={pxToRem(24)}
                          alignItems={{ base: "flex-start", sm: "center" }}
                        >
                          <Show above="sm">
                            <Avatar
                              src={user.profile_image_url}
                              name={generateTeacherName(user).primary}
                            ></Avatar>
                          </Show>
                          <VStack
                            flex="1"
                            alignItems="flex-start"
                            wordBreak="break-word"
                          >
                            <Text fontSize="md" fontWeight="bold">
                              {user.given_name} {user.family_name}
                            </Text>
                            <Box>{user.email}</Box>
                            <Box w="full">
                              {user.role === UserRole.ContentSpecialist ? (
                                <>{t("labelContentSpecialist")}</>
                              ) : (
                                <Dropdown
                                  isDisabled={
                                    teacher.id === user.id ||
                                    (user.role !== UserRole.Teacher &&
                                      user.role !== UserRole.Admin)
                                  }
                                  options={roleOptions}
                                  value={roleOptions.find(
                                    (opt) => opt.value === user.role
                                  )}
                                  handleChange={onChangeRole(user.id)}
                                />
                              )}
                            </Box>
                          </VStack>
                          <Box>
                            <Show below="sm">
                              {teacher.id !== user.id &&
                                user.role !== UserRole.ContentSpecialist && (
                                  <IconButtonDropdown
                                    iconVariant="adminLibraryPracticeSetCard"
                                    menuItems={[
                                      {
                                        handleClick: onRemoveUser(user.id),
                                        label: tCommon("remove"),
                                      },
                                    ]}
                                  />
                                )}
                            </Show>
                            <Show above="sm">
                              <Button
                                variant="adminButtonOutlined"
                                onClick={
                                  teacher.id !== user.id
                                    ? onRemoveUser(user.id)
                                    : undefined
                                }
                                disabled={
                                  teacher.id === user.id ||
                                  user.role === UserRole.ContentSpecialist
                                }
                                size="sm"
                              >
                                {tCommon("remove")}
                              </Button>
                            </Show>
                          </Box>
                        </HStack>
                      </Card>
                    );
                  })}
                </VStack>
              </VStack>
            </Show>
          </>
        ) : (
          <Box pt={pxToRem(24)} w="full">
            <EmptyCard
              actions={[
                {
                  onClick: handleAddUsers,
                  text: t("buttonAddStaffUsers"),
                  variant: "adminButtonOutlined",
                },
              ]}
              text={t("emptySearchText")}
              title={t("emptySearchTitle")}
            />
          </Box>
        )}

        <Pagination
          handleSetPage={handleSetPage}
          page={page}
          perPage={perPage}
          totalCount={totalCount}
          authUser={authUser}
        ></Pagination>
      </VStack>

      <ConfirmModal
        isOpen={isOpenConfirmRemoveModal}
        isLoading={false}
        buttonCancelLabel={t("confirmRemoveCancelButton")}
        buttonOKLabel={t("confirmRemoveOKButton")}
        handleClose={onCloseConfirmRemove}
        mobileButtonsDirection="vertical"
        size="2xl"
        description={t("confirmRemoveDescription")}
        title={t("confirmRemoveTitle")}
        variant="adminModalCompactMobile"
      />
    </TemplateWithCenteredHeroOneColumn>
  );
};
