import { usePrefersReducedMotion } from "@chakra-ui/react";
import { useMemo } from "react";
import { TransitionFn, config, useTransition } from "react-spring";

import { useSessionRoundState } from "links/lib/contexts/sessionRoundState";
import { PracticeSessionItemVariantType } from "links/lib/types";

export interface IUseConfirmAnswerButtonTransitionOpts {
  isAllGroupConfirmed: boolean;
  isConfirmed: boolean;
  userSubmittedTextChoice?: boolean;
  isTextSubmissionPhase?: boolean;
  userHasVoted?: boolean;
  isDrawVoting?: boolean;
}

export interface IUseConfirmAnswerButtonTransitionResult {
  actionsTransition: TransitionFn<
    string,
    {
      opacity: number;
      scale: number;
    }
  >;
}

export const useConfirmAnswerButtonTransition = ({
  isAllGroupConfirmed,
  isConfirmed,
  userSubmittedTextChoice,
  isTextSubmissionPhase,
  userHasVoted,
  isDrawVoting,
}: IUseConfirmAnswerButtonTransitionOpts): IUseConfirmAnswerButtonTransitionResult => {
  const prefersReducedMotion = usePrefersReducedMotion();
  const roundState = useSessionRoundState();

  const { variant } = roundState;

  const checkShowConfirmStage = () => {
    switch (variant) {
      case PracticeSessionItemVariantType.CoopChecks:
      case PracticeSessionItemVariantType.CoopRadios:
      case PracticeSessionItemVariantType.CoopDragsort:
        return !isConfirmed;
      case PracticeSessionItemVariantType.CoopTextMatch:
      case PracticeSessionItemVariantType.CoopTextMatchNumeric:
        return isTextSubmissionPhase ? !userSubmittedTextChoice : !isConfirmed;
      case PracticeSessionItemVariantType.CoopDraw:
        return isDrawVoting ? !userHasVoted : !isConfirmed;
      default:
        return !isConfirmed;
    }
  };

  const showConfirmStage = checkShowConfirmStage();

  const currentAction = useMemo(() => {
    return showConfirmStage
      ? [userSubmittedTextChoice ? "confirm-choice" : "confirm"]
      : isAllGroupConfirmed
      ? ["class"]
      : ["team"];
  }, [showConfirmStage, isAllGroupConfirmed, userSubmittedTextChoice]);

  const actionsTransition = useTransition(currentAction, {
    from: { opacity: 0, scale: prefersReducedMotion ? 1 : 0 },
    enter: { opacity: 1, scale: 1 },
    leave: { opacity: 0, scale: prefersReducedMotion ? 1 : 0 },
    exitBeforeEnter: true,
    config: config.stiff,
  });

  return { actionsTransition };
};
