import { QuestionIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  HStack,
  Tooltip,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";

import { NavigationLink } from "adminComponents";
import { Icon } from "adminComponents/atoms/Icon";
import { PieChart } from "adminComponents/atoms/PieChart";
import { Text } from "adminComponents/atoms/Text";
import { TextLink } from "adminComponents/atoms/TextLink";
import {
  generateStudentName,
  getStudentFamilyName,
  getStudentGivenName,
} from "adminComponents/utils/generateStudentName";
import { pxToRem } from "adminComponents/utils/pxToRem";
import {
  IAssignmentOutOfRangeUserPracticeSetAttempt,
  IUser,
} from "links/lib/types";

import { ITableData, ITableSort, SortableTable } from ".";

export interface IProps {
  handleChangeSort?: (sort: ITableSort) => void;
  data: ITableData;
}

const AccuracyCell = (accuracy: number) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  return (
    <Box display="flex" alignItems="center" pt={{ base: pxToRem(8), md: 0 }}>
      <PieChart percentage={accuracy} />
      <Box display="flex" alignItems="center" ml="8px">
        <Text variant="adminP1">
          {accuracy < 0 ? t("studentPlacementTable.na") : `${accuracy}%`}
        </Text>
        <Text
          variant="adminP1"
          display={{ base: "inline", md: "none" }}
          ml={pxToRem(5)}
        >
          {t("studentPlacementTable.studentAccuracy")}
        </Text>
      </Box>
    </Box>
  );
};

const CompleteCell = (isComplete: boolean) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  return (
    <Box textAlign={{ base: "left", md: "center" }}>
      <Icon
        variant="circleWithBackgroundColor"
        icon={isComplete ? "check" : "close"}
        backgroundColor={
          isComplete ? "utility.question-green" : "primary.light-gray"
        }
        w={{ base: "20px", md: "24px" }}
        h={{ base: "20px", md: "24px" }}
      />
      <Text
        variant="adminP1"
        display={{ base: "inline", md: "none" }}
        ml={pxToRem(10)}
      >
        {isComplete
          ? t("studentPlacementTable.completed")
          : t("studentPlacementTable.incomplete")}
      </Text>
    </Box>
  );
};

const practiceSetProgressCompletedIcon = (
  <Icon
    variant="circleWithBackgroundColor"
    icon="check"
    backgroundColor="utility.question-green"
    w={{ base: "20px", md: "24px" }}
    h={{ base: "20px", md: "24px" }}
  />
);

const practiceSetProgressNoAttemptIcon = (
  <Icon
    variant="circleWithBackgroundColor"
    icon="close"
    backgroundColor="primary.light-gray"
    w={{ base: "20px", md: "24px" }}
    h={{ base: "20px", md: "24px" }}
  />
);

const OutOfRangeCell = ({
  isComplete,
  attemptedAssignment,
  outOfRangeAttempts,
  assignmentStartTime,
  assignmentEndTime,
}: {
  isComplete: boolean;
  attemptedAssignment: boolean;
  outOfRangeAttempts: Array<IAssignmentOutOfRangeUserPracticeSetAttempt>;
  assignmentStartTime?: string;
  assignmentEndTime?: string;
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  // For some reason, possibly because the tooltip is in a table cell, the
  // Tooltip component will not render via mobile actions. This ensures
  // that the tooltip can be displayed on mobile interaction.
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();

  let icon = practiceSetProgressNoAttemptIcon;
  let tooltip = "";
  if (isComplete) {
    icon = practiceSetProgressCompletedIcon;
    tooltip = t("studentPlacementTable.practiceSetTooltipComplete");
  } else if (attemptedAssignment) {
    icon = practiceSetProgressCompletedIcon;
    tooltip = t("studentPlacementTable.practiceSetTooltipAttempted");
  } else if (outOfRangeAttempts.length < 1) {
    icon = practiceSetProgressNoAttemptIcon;
    tooltip = t("studentPlacementTable.practiceSetTooltipNotStarted");
  } else {
    if (outOfRangeAttempts.find((a) => a.item_count === a.response_count)) {
      const assignmentStart = moment(assignmentStartTime);
      const assignmentEnd = moment(assignmentEndTime);
      const didPracticeSetEarly = outOfRangeAttempts.find(
        (a) =>
          a.item_count === a.response_count &&
          assignmentStart.isAfter(a.start_time)
      );
      const didPracticeSetLate = outOfRangeAttempts.find(
        (a) =>
          a.item_count === a.response_count &&
          assignmentEnd.isBefore(a.start_time)
      );

      icon = practiceSetProgressCompletedIcon;
      if (didPracticeSetEarly && didPracticeSetLate) {
        tooltip = t("studentPlacementTable.practiceSetTooltipEarlyAndLate");
      } else if (didPracticeSetEarly) {
        tooltip = t("studentPlacementTable.practiceSetTooltipEarly");
      } else if (didPracticeSetLate) {
        tooltip = t("studentPlacementTable.practiceSetTooltipLate");
      } else {
        tooltip = t("studentPlacementTable.practiceSetTooltipComplete");
      }
    } else if (outOfRangeAttempts.find((a) => a.response_count > 0)) {
      const attempt = outOfRangeAttempts.find((a) => a.response_count > 0);
      icon = (
        <PieChart
          percentage={
            attempt && attempt.item_count > 0
              ? (attempt.response_count / attempt.item_count) * 100
              : 0
          }
        />
      );
      tooltip = t("studentPlacementTable.practiceSetTooltipStarted");
    } else {
      icon = practiceSetProgressNoAttemptIcon;
      tooltip = t("studentPlacementTable.practiceSetTooltipNotStarted");
    }
  }

  return (
    <Tooltip
      label={tooltip}
      aria-label={tooltip}
      openDelay={500}
      variant="adminDark"
      placement="bottom"
      isOpen={isOpen}
    >
      <HStack
        justifyContent="center"
        onMouseEnter={onOpen}
        onMouseLeave={onClose}
        onClick={onToggle}
      >
        {icon}
        <QuestionIcon
          color="utility.link"
          width={pxToRem(16)}
          height={pxToRem(16)}
        />
      </HStack>
    </Tooltip>
  );
};

const GivenNameCell = ({
  given_name,
  family_name,
  student_nickname,
  profile_image_url,
  navigationLink,
}: IUser & {
  navigationLink: NavigationLink;
}) => {
  const { primary: name } = generateStudentName({
    given_name,
    family_name,
    student_nickname,
  });
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box display="flex" alignItems="center">
      <Avatar
        name={name}
        src={profile_image_url}
        mr={pxToRem(12)}
        w="40px"
        h="40px"
      />
      <TextLink size="lg" navigationLink={navigationLink}>
        {getStudentGivenName({ given_name, student_nickname })}
        {isMobile
          ? " " + getStudentFamilyName({ family_name, student_nickname })
          : ""}
      </TextLink>
    </Box>
  );
};

const FamilyNameCell = ({
  family_name,
  student_nickname,
  navigationLink,
}: IUser & {
  navigationLink: NavigationLink;
}) => {
  return (
    <Box display="flex" alignItems="center">
      <TextLink size="lg" navigationLink={navigationLink}>
        {getStudentFamilyName({ family_name, student_nickname })}
      </TextLink>
    </Box>
  );
};

const StudentPlacementTable: React.FC<IProps> = ({
  data = [],
  handleChangeSort,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const isMobile = useBreakpointValue({ base: true, md: false });

  const maxDataLength = data.reduce(
    (acc, rowData) => (acc > rowData.length ? acc : rowData.length),
    0
  );
  const columns = isMobile
    ? [
        {
          id: "col-1",
          header: () => (
            <Text variant="adminP1">
              {t("studentPlacementTable.studentGivenName")}
            </Text>
          ),
          isSortable: true,
          headerStyle: { textAlign: "left" },
          cell: GivenNameCell,
          labels: {
            asc: t("studentPlacementTable.az"),
            desc: t("studentPlacementTable.za"),
          },
        },
        {
          id: "col-2",
          header: () => (
            <Text variant="adminP1">
              {t("studentPlacementTable.studentFamilyName")}
            </Text>
          ),
          isSortable: true,
          headerStyle: { textAlign: "left" },
          cell: <></>,
          labels: {
            asc: t("studentPlacementTable.az"),
            desc: t("studentPlacementTable.za"),
          },
        },
        {
          id: "col-3",
          header: () => (
            <Text variant="adminP1">
              {t("studentPlacementTable.studentAccuracy")}
            </Text>
          ),
          headerStyle: { textAlign: "left" },
          isSortable: true,
          cell: AccuracyCell,
          cellStyle: { pl: { base: 0, md: pxToRem(82) } },
          labels: {
            asc: t("studentPlacementTable.lowToHigh"),
            desc: t("studentPlacementTable.highToLow"),
          },
        },
        {
          id: "col-4",
          header: () => (
            <Text variant="adminP1">
              {t("studentPlacementTable.completed")}
            </Text>
          ),
          headerStyle: { width: pxToRem(148) },
          cell: CompleteCell,
          cellStyle: {},
        },
        {
          id: "col-5",
          header: () => (
            <Text variant="adminP1">{t("studentPlacementTable.progress")}</Text>
          ),
          headerStyle: { width: pxToRem(200) },
          cell: OutOfRangeCell,
          cellStyle: {},
        },
      ].slice(0, maxDataLength)
    : [
        {
          id: "col-1",
          header: () => (
            <Text variant="adminP1">
              {t("studentPlacementTable.studentGivenName")}
            </Text>
          ),
          isSortable: true,
          headerStyle: { textAlign: "left" },
          cell: GivenNameCell,
          labels: {
            asc: t("studentPlacementTable.az"),
            desc: t("studentPlacementTable.za"),
          },
        },
        {
          id: "col-2",
          header: () => (
            <Text variant="adminP1">
              {t("studentPlacementTable.studentFamilyName")}
            </Text>
          ),
          isSortable: true,
          headerStyle: { textAlign: "left" },
          cell: FamilyNameCell,
          labels: {
            asc: t("studentPlacementTable.az"),
            desc: t("studentPlacementTable.za"),
          },
        },
        {
          id: "col-3",
          header: () => (
            <Text variant="adminP1">
              {t("studentPlacementTable.studentAccuracy")}
            </Text>
          ),
          headerStyle: { textAlign: "left" },
          isSortable: true,
          cell: AccuracyCell,
          cellStyle: { pl: { base: 0, md: pxToRem(82) } },
          labels: {
            asc: t("studentPlacementTable.lowToHigh"),
            desc: t("studentPlacementTable.highToLow"),
          },
        },
        {
          id: "col-4",
          header: () => (
            <Text variant="adminP1">
              {t("studentPlacementTable.completed")}
            </Text>
          ),
          headerStyle: { width: pxToRem(148) },
          cell: CompleteCell,
          cellStyle: {},
        },
        {
          id: "col-5",
          header: () => (
            <Text variant="adminP1">{t("studentPlacementTable.progress")}</Text>
          ),
          headerStyle: { width: pxToRem(200) },
          cell: OutOfRangeCell,
          cellStyle: {},
        },
      ].slice(0, maxDataLength);

  return (
    <SortableTable
      variant="adminSortableTable"
      data={data}
      // TODO figure out why typescript is choking on this line
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      columns={columns}
      handleChangeSort={handleChangeSort}
    />
  );
};

export { StudentPlacementTable };
