import { pxToRem } from "adminComponents/utils/pxToRem";

export const AdminEmptyCard = {
  baseStyle: (): Record<string, unknown> => ({
    actions: {
      display: "flex",
      flexDirection: { base: "column", md: "row" },
      justifyContent: "center",
      "> button": {
        margin: pxToRem(5),
      },
    },
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      gap: pxToRem(20),
    },
    extraAction: {
      display: "flex",
      justifyContent: "center",
    },
  }),
};
