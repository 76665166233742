import { pxToRem } from "adminComponents/utils/pxToRem";

export const AdminPremiumPracticeSetBanner = {
  baseStyle: {
    container: {
      backgroundColor: "utility.premium",
      borderRadius: `${pxToRem(17)} 0px ${pxToRem(8)} 0px`,
      height: pxToRem(32),
      paddingLeft: pxToRem(1),
      paddingRight: pxToRem(1),
      gap: pxToRem(8),
    },
  },
};
