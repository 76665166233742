import { AbsoluteCenter, Box, useMultiStyleConfig } from "@chakra-ui/react";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ColorScheme } from "adminComponents";
import { IProps as IAvatarEditingCard } from "adminComponents/atoms/AvatarEditingCard";
import {
  AvatarShapes,
  IProps as IconButtonProps,
} from "adminComponents/atoms/IconButton";
import { LoadingSpinner } from "adminComponents/atoms/LoadingSpinner";
import { Tabs } from "adminComponents/atoms/Tabs";
import { Text } from "adminComponents/atoms/Text";
import { AvatarGridCarousel } from "adminComponents/molecules/AvatarGridCarousel";
import { AvatarTypeSelector } from "adminComponents/molecules/AvatarTypeSelector";
import { ColorPicker } from "adminComponents/molecules/ColorPicker";
import { IconCarousel } from "adminComponents/molecules/IconCarousel";
import RemoveSvg from "adminComponents/organisms/EditAvatarTabs/resources/remove.svg";
import SkeletonSeatedSVG from "adminComponents/organisms/EditAvatarTabs/resources/skeleton_seated.svg";
import SkeletonStandingSVG from "adminComponents/organisms/EditAvatarTabs/resources/skeleton_standing.svg";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { AvatarSkeletonType, IAvatar, IAvatarItem } from "links/lib/types";

import { useDataUrlsForSubSections } from "./components/useDataUrlsForSubSections";
import { useSectionData } from "./components/useSectionData";
import {
  SectionIcon,
  hairColorHexCodes,
  sections,
  skinToneHexCodes,
} from "./config";

const avatarShapesList = Object.values(AvatarShapes);

export interface IProps {
  avatar: IAvatar;
  isMobile: boolean;
  useModal?: boolean;
  handleSkinToneColorChange: (color: string) => void;
  handleHairColorChange: (color: string) => void;
  handleEquip: (
    avatarItemId: string,
    categoryId?: string,
    activeColor?: string
  ) => void;
  handleDeequip: (avatarItemId: string, categoryId?: string) => void;
  handleTabChange?: (index: number) => void;
  handleSectionIdChange?: (sectionId: string) => void;
  handleSelectAvatarType: (skeletonType: AvatarSkeletonType) => void;
}
export const EditAvatarTabs: React.FC<IProps> = ({
  avatar,
  isMobile,
  useModal = false,
  handleSkinToneColorChange,
  handleHairColorChange,
  handleEquip,
  handleDeequip,
  handleTabChange: handleTabChangeProp,
  handleSectionIdChange,
  handleSelectAvatarType,
}) => {
  const { t } = useTranslation("admin", {
    keyPrefix: "editAvatarTabs",
    useSuspense: false,
  });
  const styles = useMultiStyleConfig("AdminEditAvatarTabs", {});

  const {
    activeIcon,
    activeIconIndex,
    activeTabIndex,
    iconsByTab,
    showSkinToneSelection,
    showHairColorSelection,
    subSections,
    tabLabels,
    setActiveIconIndex,
    setActiveTabIndex,
  } = useSectionData(sections);

  const sectionId =
    iconsByTab[tabLabels[activeTabIndex]][activeIconIndex].sectionId;

  useEffect(() => {
    handleSectionIdChange?.(sectionId);
  }, [handleSectionIdChange, sectionId]);

  const { dataUrlsBySubSectionIndex, isSubSectionsLoading, setDataUrls } =
    useDataUrlsForSubSections(avatar, subSections);

  const tabData = useMemo(() => {
    return tabLabels
      .filter((tabLabel) => {
        // Only show the wheelchair tab for seated avatars
        if (
          tabLabel === "wheelchair" &&
          avatar.skeleton_type !== AvatarSkeletonType.Seated
        ) {
          return false;
        }
        return true;
      })
      .map((tabLabel, index) => {
        const tabIconNames = iconsByTab[tabLabel].filter(
          (icon: SectionIcon) => icon.name
        );

        const handleCarouselIconClick = (clickedIcon: IconButtonProps) => {
          const updatedIconIndex = tabIconNames.findIndex(
            (icon) => icon.name === clickedIcon.icon
          );

          setActiveIconIndex(updatedIconIndex);

          if (updatedIconIndex !== activeIconIndex) {
            setDataUrls([]);
          }
        };

        const carouselIcons = tabIconNames.map(
          (icon: SectionIcon, index: number): IconButtonProps => {
            const avatarShapeIndex = index % avatarShapesList.length;

            return {
              ariaLabel: icon.name ? icon.name : "icon",
              icon: icon.name ? icon.name : "head",
              shape: avatarShapesList[avatarShapeIndex],
              variant: "adminCustomization",
              colorScheme: "primary.tan" as ColorScheme,
              colorSchemeHover: "primary.golden-hover" as ColorScheme,
              "aria-pressed": activeIconIndex === index,
            };
          }
        );

        return {
          label: t(tabLabel),
          content: index === activeTabIndex && (
            <Box key={activeTabIndex}>
              {tabIconNames.length > 0 && (
                <Box __css={styles.iconCarousel}>
                  <IconCarousel
                    id={`swiper-1-tab-${index}`}
                    handleIconClick={handleCarouselIconClick}
                    icons={carouselIcons}
                  />
                </Box>
              )}
            </Box>
          ),
        };
      });
  }, [
    activeIconIndex,
    activeTabIndex,
    iconsByTab,
    setActiveIconIndex,
    setDataUrls,
    styles.iconCarousel,
    t,
    tabLabels,
    avatar.skeleton_type,
  ]);

  const handleTabChange = useCallback(
    (index: number) => {
      setActiveTabIndex(index);
      setActiveIconIndex(0);
      setDataUrls([]);

      if (handleTabChangeProp) {
        handleTabChangeProp(index);
      }
    },
    [setActiveIconIndex, setActiveTabIndex, setDataUrls, handleTabChangeProp]
  );

  const handleAvatarGridItemSelected = (avatarItemId: string | number) => {
    const [itemId, categoryId] = String(avatarItemId).split("|");

    if (!itemId || Number(itemId) === -1) {
      handleDeequip("", categoryId);
    } else {
      handleEquip(itemId, categoryId);
    }
  };

  return (
    <Box __css={styles.container}>
      <Tabs
        tabIndex={activeTabIndex}
        variant="adminFlyoutNav"
        tabData={tabData}
        handleChange={handleTabChange}
        sx={styles.tabs}
      />
      <Box>
        {showSkinToneSelection && (
          <>
            <Box __css={styles.avatarTypeSelector}>
              <AvatarTypeSelector
                standingAvatar={
                  <Box
                    backgroundImage={SkeletonStandingSVG}
                    backgroundRepeat="no-repeat"
                    backgroundSize="contain"
                    backgroundPosition="center center"
                    w="full"
                    h={[pxToRem(48), null, pxToRem(96)]}
                    as="span"
                  />
                }
                seatedAvatar={
                  <Box
                    backgroundImage={SkeletonSeatedSVG}
                    backgroundRepeat="no-repeat"
                    backgroundSize="contain"
                    backgroundPosition="center center"
                    w="full"
                    h={[pxToRem(48), null, pxToRem(96)]}
                    as="span"
                  />
                }
                handleSelectAvatarType={handleSelectAvatarType}
                selected={avatar.skeleton_type}
              />
            </Box>
            <Box __css={styles.skinTone}>
              <Text
                variant="adminP1"
                my={pxToRem(24)}
                px={{ base: pxToRem(16), lg: 0 }}
              >
                {t("labelSkinTone")}
              </Text>
              <ColorPicker
                id={`skin-colorpicker`}
                colors={skinToneHexCodes}
                selectedColor={avatar.skin_tone_hex_code}
                handleColorClick={handleSkinToneColorChange}
                display={isMobile ? "carousel" : "grid"}
                translationKey="skinToneButton.selectSkinTone"
                useModal={useModal}
              />
            </Box>
          </>
        )}
        {showHairColorSelection && (
          <Box __css={styles.hairColor}>
            <Text
              variant="adminP1"
              my={pxToRem(24)}
              px={{ base: pxToRem(16), lg: pxToRem(68) }}
            >
              {activeIcon.sectionId === "hair"
                ? t("labelHairStyle")
                : t("labelHairColor")}
            </Text>
            <ColorPicker
              id={`hair-colorpicker`}
              colors={hairColorHexCodes}
              selectedColor={avatar.hair_color_hex_code}
              handleColorClick={handleHairColorChange}
              display="carousel"
            />
          </Box>
        )}
        {subSections.map(
          ({ canDeequipCategory, categoryId, labelKey }, subSectionIndex) => {
            const categoryItems = avatar.items
              .filter((item) => categoryId === item.avatar_item_category_id)
              .filter(
                // Default items with categories that permit deequip aren't visible
                (item) => !canDeequipCategory || !item.is_category_default
              );
            const activeCategoryItemIndex = categoryItems.findIndex(
              (item) => item.is_active === true
            );

            let avatarCardItems: Array<IAvatarEditingCard> =
              categoryItems.reduce(
                (
                  acc: Array<IAvatarEditingCard>,
                  item: IAvatarItem,
                  index: number
                ) => {
                  return acc.concat({
                    value: item.id,
                    id: `${item.id}|${item.avatar_item_category_id}`,
                    name: item.name,
                    image: dataUrlsBySubSectionIndex.length
                      ? dataUrlsBySubSectionIndex[subSectionIndex][index]
                      : undefined,
                    isSelected: item.is_active,
                  } as IAvatarEditingCard);
                },
                []
              );

            if (canDeequipCategory) {
              avatarCardItems = [
                {
                  id: `-1|${categoryId}`,
                  value: "-1",
                  name: "standard",
                  isSelected: false,
                  image: RemoveSvg,
                },
                ...avatarCardItems,
              ];
            }

            const activeCategoryItemColors = categoryItems[
              activeCategoryItemIndex
            ]?.colors?.map((c) => c.hex_code);
            const activeColor = categoryItems[
              activeCategoryItemIndex
            ]?.colors.find((c) => c.is_active);

            return (
              <Box key={labelKey} sx={styles.avatarGridContainer}>
                {labelKey && (
                  <Text
                    variant="adminP1"
                    my={pxToRem(24)}
                    px={{ base: pxToRem(16), lg: pxToRem(68) }}
                  >
                    {labelKey && t(labelKey)}
                  </Text>
                )}
                {!!activeCategoryItemColors?.length && (
                  <Box sx={styles.colorPickerSubSections}>
                    <ColorPicker
                      id={`item-colorpicker-subsection-${subSectionIndex}`}
                      key={`item-colorpicker-subsection-${subSectionIndex}-${activeCategoryItemIndex}`}
                      colors={activeCategoryItemColors}
                      selectedColor={activeColor ? activeColor.hex_code : ""}
                      handleColorClick={(color: string) => {
                        handleEquip(
                          categoryItems[activeCategoryItemIndex].id,
                          categoryItems[activeCategoryItemIndex]
                            .avatar_item_category_id,
                          color
                        );
                      }}
                      display="carousel"
                    />
                  </Box>
                )}
                <Box sx={styles.avatarGrid}>
                  {!!dataUrlsBySubSectionIndex.length && (
                    <Box
                      opacity={isSubSectionsLoading ? 0.5 : 1}
                      maxW={{ base: "full", lg: pxToRem(590) }}
                    >
                      <AvatarGridCarousel
                        isMobile={!!isMobile}
                        id={`swiper-3-subsection-${subSectionIndex}`}
                        handleSelected={handleAvatarGridItemSelected}
                        avatarCardData={avatarCardItems}
                      />
                    </Box>
                  )}
                  {isSubSectionsLoading && (
                    <AbsoluteCenter>
                      <LoadingSpinner />
                    </AbsoluteCenter>
                  )}
                </Box>
              </Box>
            );
          }
        )}
      </Box>
    </Box>
  );
};
