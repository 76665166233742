import { colors as adminColors } from "../../adminComponents/theme/colors";

import { boxShadows } from "./boxShadows";
import { colors } from "./colors";
import { components } from "./components";
import { textStyles } from "./textStyles";

const allColors = { ...colors, ...adminColors };

const gooperTheme = {
  textStyles,
  colors: allColors,
  components,
  boxShadows,
};

const IconOptions = [
  "reaction",
  "pointer",
  "waiting",
  "checkmark",
  "refresh",
  "menu",
  "incorrect",
  "correct",
  "brush",
  "away",
  "reveal",
  "sound_on",
  "sound_off",
  "zoom_out",
  "zoom_in",
  "eraser",
  "chat",
  "drag",
  "close",
  "exit",
  "circle_filled",
  "pin",
  "lock",
  "resize",
  "left_arrow",
  "undo",
  "clipboard",
  "team",
  "back",
  "forward",
];

type IFullScreenStyles = {
  position: "fixed" | undefined;
  top: number;
  left: number;
  right: number;
  bottom: number;
};

const fullScreenStyles: IFullScreenStyles = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
};

export { gooperTheme, IconOptions, fullScreenStyles };
