import { Box, VStack } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { InstantSetBanner } from "adminComponents/molecules/InstantSetBanner";
import { NavigationHeader } from "adminComponents/molecules/NavigationHeader";
import { SearchTagsAndOptions } from "adminComponents/molecules/SearchTagsAndOptions";
import { LibraryParentGradePickerGrid } from "adminComponents/organisms/LibraryGradePickerGrid";
import {
  GuestSearchResultsTab,
  LibrarySearchResultsTabs,
} from "adminComponents/organisms/LibrarySearchResultsTabs";
import { TemplateWithCenteredHeroOneColumn } from "adminComponents/templates/TemplateWithCenteredHeroOneColumn";
import { pxToRem } from "adminComponents/utils";
import { IHandleSetQueryArgs, ISearchQuery } from "lib/hooks/useLibraryFilter";
import { ISearchSuggestion, IStandard, ISubject } from "links/lib/types";
import { IGuestNavigationDataContext } from "screens/TeacherDashboard/contexts/GuestTeacherNavigationDataProvider";

export interface IProps {
  isLoading: boolean;
  isSearching: boolean;
  navigationData: IGuestNavigationDataContext;
  selectedParentSubject?: ISubject;
  handleSelectSubjects: (subjectIds: string[]) => void;
  handleSelectGrades: (gradeLevelId: string[]) => void;
  handleGenerateSet: () => void;
  handleSearch?: (search: string) => void;
  query: ISearchQuery;
  suggestions?: string[];
  tab: GuestSearchResultsTab;
  handleTabChange: (tab: GuestSearchResultsTab) => void;
  handleClearSearchInput?: () => void;
  handleSuggest?: (searchTerm: string) => void;
  totalQueryCount?: number;
  handleRemoveFilterTag: (
    category: "subject" | "grade" | "standard",
    id: string
  ) => void;
  handleClearAllFilters: () => void;
  handleOpenFilterFlyout: () => void;
  handleSignUp: () => void;
  standards: IStandard[];
  hideExpertTab?: boolean;
  searchFilterSuggestions?: ISearchSuggestion[];
  handleUpdateQuery?: (args: IHandleSetQueryArgs) => void;
  isTermParsedSearchFilters: boolean;
}

export const GuestLibrarySearchResults: React.FC<IProps> = ({
  isLoading,
  isSearching,
  navigationData,
  selectedParentSubject,
  handleSelectSubjects,
  handleSelectGrades,
  handleGenerateSet,
  handleSearch,
  query,
  suggestions,
  tab,
  handleTabChange,
  children,
  handleClearSearchInput,
  handleSuggest,
  totalQueryCount,
  handleRemoveFilterTag,
  handleClearAllFilters,
  handleOpenFilterFlyout,
  handleSignUp,
  standards,
  hideExpertTab = false,
  searchFilterSuggestions,
  handleUpdateQuery,
  isTermParsedSearchFilters,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  const showGradeLevelPicker = !query?.term && !!selectedParentSubject;

  const _handleSelectGrades = (gradeLevelId: string) => {
    handleSelectGrades([gradeLevelId]);
  };

  return (
    <Box bgColor="primary.tan-light">
      <TemplateWithCenteredHeroOneColumn
        nav={
          <NavigationHeader
            resourceLinks={[]}
            accountLinks={[]}
            {...navigationData}
            handleCreatePracticeSet={handleGenerateSet}
            handleSelectGrades={handleSelectGrades}
            handleSelectSubjects={handleSelectSubjects}
            handleSearch={handleSearch}
            handleClearSearchInput={handleClearSearchInput}
            handleSuggest={handleSuggest}
            handleSignUp={handleSignUp}
            isSearching={isSearching}
            suggestions={suggestions}
            query={query}
            searchFilterSuggestions={searchFilterSuggestions}
            handleUpdateQuery={handleUpdateQuery}
            isGuest
          />
        }
        heroContainerStyle={{
          paddingTop: pxToRem(0),
          paddingBottom: pxToRem(30),
        }}
        heroContent={
          <SearchTagsAndOptions
            handleClearAllFilters={handleClearAllFilters}
            handleOpenFilterFlyout={handleOpenFilterFlyout}
            handleRemoveFilterTag={handleRemoveFilterTag}
            handleSelectGrades={handleSelectGrades}
            handleSelectSubjects={handleSelectSubjects}
            isLoading={isLoading}
            navigationData={navigationData}
            query={query}
            totalQueryCount={totalQueryCount || 0}
            standards={standards}
            selectedParentSubject={selectedParentSubject}
            isTermParsedSearchFilters={isTermParsedSearchFilters}
          />
        }
      >
        <VStack>
          {showGradeLevelPicker && (
            <Box
              w="full"
              borderRadius="xl"
              bgColor="primary.tan"
              overflowX="auto"
              mb={pxToRem(32)}
            >
              <LibraryParentGradePickerGrid
                px={pxToRem(20)}
                py={pxToRem(30)}
                w="full"
                h="fit-content"
                gridTemplateColumns="repeat(auto-fill,minmax(160px,1fr))"
                gridAutoFlow="column"
                gridAutoColumns="minmax(160px,1fr)"
                circleShape="type1"
                iconWidth={pxToRem(36)}
                iconHeight={pxToRem(36)}
                handleSelectGradeLevel={_handleSelectGrades}
                gradeLevels={navigationData.gradeLevels}
                fontSize={pxToRem(28)}
              />
            </Box>
          )}
          <Box w="full" paddingBottom={pxToRem(32)}>
            <LibrarySearchResultsTabs
              tabIndex={tab}
              handleTabChange={handleTabChange}
              hideExpertTab={hideExpertTab}
            >
              {children}
            </LibrarySearchResultsTabs>
          </Box>
        </VStack>

        <InstantSetBanner
          isLoading={isLoading}
          title={
            query?.term
              ? t("librarySearchResults.instantSetSuggestion", {
                  queryTerm: query.term,
                })
              : selectedParentSubject
              ? t("librarySearchResults.instantSetSuggestionNoQuotes", {
                  topic: selectedParentSubject?.name,
                })
              : undefined
          }
          handleGenerateInstantSet={handleGenerateSet}
        />
      </TemplateWithCenteredHeroOneColumn>
    </Box>
  );
};
