import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";

interface IIsFlagEnabledParams {
  flags: string[];
  properties: { [key: string]: string };
  enabled?: boolean;
  onSuccess?: (data: IIsFlagEnabledResult) => void;
  onError?: (err: unknown) => void;
}

interface IIsFlagEnabledResult {
  flags: { [key: string]: boolean };
}
export default function useIsFlagEnabled(
  params: IIsFlagEnabledParams
): UseQueryResult<IIsFlagEnabledResult> {
  const axios = useAxios();

  const { flags, properties, enabled, onError, onSuccess } = params;

  const query = useQuery<IIsFlagEnabledResult>(
    ["flags"],
    async (): Promise<IIsFlagEnabledResult> => {
      return await axios
        .get("/v1/flags", {
          params: { flags, properties },
        })
        .then((r) => r.data as IIsFlagEnabledResult);
    },
    {
      enabled,
      onSuccess,
      onError,
    }
  );

  return query;
}
