import { useHistory } from "react-router-dom";

import { useErrorToast } from "adminComponents/utils/toast";
import { useFetchAveragePracticeSetAccuracies } from "links/lib/features/classrooms";
import { IUseFetchAveragePracticeSetAccuraciesResult } from "links/lib/features/classrooms/useFetchAveragePracticeSetAccuracies";
import useFetchClassroom, {
  IFetchClassroomResponse,
} from "links/lib/features/classrooms/useFetchClassroom";
import useFetchClassroomUsers, {
  IFetchClassroomUsersResponse,
} from "links/lib/features/classrooms/useFetchClassroomUsers";
import useFetchPracticeSetItems, {
  IFetchPracticeSetItemsResponse,
} from "links/lib/features/practiceSetItems/useFetchPracticeSetItems";
import useFetchPracticeSet, {
  IFetchPracticeSetResponse,
} from "links/lib/features/practiceSets/useFetchPracticeSet";
import { UsersGroupsRole } from "links/lib/types";

interface IUseStudentPracticeSetReportQueriesParams {
  practiceSetId: string;
  classroomId: string;
  studentId: string;
  assignmentId?: string;
  practiceSetSessionId?: string;
}

interface IUseStudetPracticeSetReportQueriesResponse {
  isLoading: boolean;
  classroomData?: IFetchClassroomResponse;
  practiceSetData?: IFetchPracticeSetResponse;
  classroomStudentsData?: IFetchClassroomUsersResponse;
  practiceSetAccuracyData?: IUseFetchAveragePracticeSetAccuraciesResult;
  practiceSetItemsData?: IFetchPracticeSetItemsResponse;
}

export const useStudentPracticeSetReportQueries = ({
  practiceSetId,
  classroomId,
  studentId,
  assignmentId,
  practiceSetSessionId,
}: IUseStudentPracticeSetReportQueriesParams): IUseStudetPracticeSetReportQueriesResponse => {
  const history = useHistory();
  const practiceSetFetch = useFetchPracticeSet({ id: practiceSetId });

  const practiceSetAccuracyFetch = useFetchAveragePracticeSetAccuracies({
    classroom_id: classroomId,
    practice_set_id: practiceSetId,
    user_id: studentId,
    assignment_id: assignmentId,
    practice_set_session_id: practiceSetSessionId,
  });

  const classroomStudentsFetch = useFetchClassroomUsers({
    classroom_id: classroomId,
    group_roles: [UsersGroupsRole.Member],
  });

  const practiceSetItemsFetch = useFetchPracticeSetItems({
    practice_set_id: practiceSetId,
  });

  const classroomFetch = useFetchClassroom({
    id: classroomId,
    onPermissionDenied: () => {
      history.push("/t/classrooms");
    },
  });

  useErrorToast(practiceSetFetch.error);
  useErrorToast(practiceSetAccuracyFetch.error);
  useErrorToast(classroomStudentsFetch.error);
  useErrorToast(practiceSetItemsFetch.error);
  useErrorToast(classroomFetch.error);

  const isLoading =
    practiceSetFetch.isLoading ||
    practiceSetAccuracyFetch.isLoading ||
    classroomStudentsFetch.isLoading ||
    practiceSetItemsFetch.isLoading ||
    classroomFetch.isLoading;

  return {
    isLoading,
    practiceSetData: practiceSetFetch.data,
    classroomStudentsData: classroomStudentsFetch.data,
    practiceSetAccuracyData: practiceSetAccuracyFetch.data,
    practiceSetItemsData: practiceSetItemsFetch.data,
    classroomData: classroomFetch.data,
  };
};
