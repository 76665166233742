export default {
  gameLabel: {
    fontFamily: "TTCommons",
    fontWeight: 500,
  },
  gameParagraph: {
    fontFamily: "TTCommons",
    fontWeight: "normal",
  },
  gameHeaderBold: {
    fontFamily: "Gooper",
    fontWeight: "normal",
  },
  gameHeaderText: {
    fontFamily: "TTCommons",
    fontWeight: 600,
  },
};
