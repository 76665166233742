import { pxToRem } from "adminComponents/utils/pxToRem";

export const AdminStudentCompletedAssignmentsFlyout = {
  baseStyle: {
    container: {
      paddingLeft: { base: pxToRem(15), md: pxToRem(56) },
      paddingRight: { base: pxToRem(15), md: pxToRem(60) },
    },
    assignment: {
      paddingBottom: pxToRem(36),
      marginBottom: pxToRem(32),
      borderBottomStyle: "solid",
      borderBottomWidth: pxToRem(2),
      borderBottomColor: "primary.light-gray",
    },
  },
};
