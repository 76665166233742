import { Box } from "@chakra-ui/react";
import React from "react";
import { animated, config, useTransition } from "react-spring";

import { pxToRem } from "adminComponents/utils";
import { Button } from "sessionComponents/atoms/Button";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { useDetectOrientation } from "sessionComponents/theme/hooks/useDetectOrientation";

import Logo from "./resource/big_board_logo.png";

const AnimatedBox = animated(Box);

interface IBigBoardLogoProps {
  buttonText?: string;
  handlePlay?: () => void;
}

export const BigBoardLogo: React.FC<IBigBoardLogoProps> = ({
  buttonText,
  handlePlay,
}) => {
  const { isPortrait } = useDetectOrientation();
  const {
    match: { buttonHeight, margin, fontSize },
  } = useBreakpoints();
  const logoTransition = useTransition(true, {
    from: { opacity: 0, top: "-100%" },
    enter: { opacity: 1, top: "0%" },
    leave: { opacity: 0, top: "0%" },
    config: config.molasses,
    delay: 500,
  });
  const buttonTransition = useTransition(true, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.molasses,
    delay: 500,
  });

  return (
    <Box
      h="-webkit-fill-available"
      w="100vw"
      zIndex={1002}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      position="fixed"
      top="0"
      bottom="0"
    >
      {logoTransition(
        (styles, item) =>
          item && (
            <AnimatedBox style={styles} h="full" w="full" position="relative">
              <Box
                w="80%"
                h="80%"
                backgroundImage={Logo}
                backgroundSize="contain"
                backgroundRepeat="no-repeat"
                backgroundPosition="center"
                position="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
              />
            </AnimatedBox>
          )
      )}
      {buttonText &&
        buttonTransition(
          (styles, item) =>
            item && (
              <AnimatedBox w={isPortrait ? "80%" : "55%"} style={styles}>
                <Button
                  variant="buttonFilled"
                  w="full"
                  height={pxToRem(buttonHeight)}
                  onClick={handlePlay}
                  fontSize={pxToRem(fontSize)}
                  marginBottom={pxToRem(margin)}
                >
                  {buttonText}
                </Button>
              </AnimatedBox>
            )
        )}
    </Box>
  );
};
