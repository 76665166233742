import { Box, SimpleGrid, Text } from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMeasure } from "react-use";

import { pxToRem } from "adminComponents/utils/pxToRem";
import {
  CoopDragsortVariantData,
  IPracticeSetSessionItem,
  ISessionRoundGroupState,
  ISessionUser,
  PracticeSessionItemVariantType,
  QuestionType,
} from "links/lib/types";
import { DraggableLabel } from "sessionComponents/atoms/DraggableLabel";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { useStudent } from "sessionComponents/contexts/student";
import { useSendThrottledDragCoords } from "sessionComponents/hooks/useSendThrottledDragCoords";
import { useDetectOrientation } from "sessionComponents/theme/hooks/useDetectOrientation";
import { getDraggingUserDetails } from "sessionComponents/utils/getDraggingUserDetails";

import { QuestionResponse } from "../QuestionResponse";

interface IClassificationResponseGroupProps {
  onDragStop: (labelId: string, zoneId: string) => void;
  groupUsers: ISessionUser[];
  practiceSetSessionItem: IPracticeSetSessionItem;
  roundGroupState: ISessionRoundGroupState;
  variantData: CoopDragsortVariantData;
}

export const ClassificationResponseGroup: React.FC<
  IClassificationResponseGroupProps
> = ({ onDragStop, groupUsers, roundGroupState }) => {
  const student = useStudent();
  const [draggingItemId, setDraggingItemId] = useState("");
  const { isLandscape } = useDetectOrientation();
  const { match: currentBreakpoints } = useBreakpoints();
  const [optionRef, { width: initialOptionWidth }] =
    useMeasure<HTMLDivElement>();
  const { t } = useTranslation("session", { useSuspense: false });

  const items = useMemo(
    () => roundGroupState.dragsort?.items || [],
    [roundGroupState.dragsort?.items]
  );

  const visibleItems = useMemo(
    () => items.filter((item) => item.zone_id === "0"),
    [items]
  );

  const isImageResponse = items.some((item) => !!item.image_url);

  const sendThrottledCoords = useSendThrottledDragCoords();

  const draggingItem = items.find((item) => item.id === draggingItemId);

  return (
    <>
      <SimpleGrid
        columns={isImageResponse ? 2 : 1}
        spacing={pxToRem(currentBreakpoints.margin / 2)}
        paddingBottom={pxToRem(currentBreakpoints.padding)}
      >
        {visibleItems.map((item) => {
          const draggingUser = getDraggingUserDetails({
            isDragging: item.is_dragging,
            userId: item.user_id,
            groupUsers,
          });

          return (
            <Box
              ref={optionRef}
              w="full"
              key={`item-${item.id}`}
              display={isImageResponse ? "flex" : undefined}
              justifyContent={isImageResponse ? "center" : undefined}
            >
              <DraggableLabel
                label={item}
                stopDragging={onDragStop}
                transform="translate(0, 0)"
                userSelect="none"
                width="full"
                startDragging={(labelId, coords) => {
                  setDraggingItemId(labelId);
                  sendThrottledCoords.current?.(labelId, coords);
                }}
                dragItemPreviewContent={
                  <QuestionResponse
                    variant={PracticeSessionItemVariantType.CoopDragsort}
                    questionType={QuestionType.Classify}
                    text={draggingItem?.text}
                    imageUrl={draggingItem?.image_url}
                    imageAltText={draggingItem?.image_alt_text}
                    width={pxToRem(initialOptionWidth)}
                  />
                }
              >
                <QuestionResponse
                  variant={PracticeSessionItemVariantType.CoopDragsort}
                  questionType={QuestionType.Classify}
                  users={draggingUser ? [draggingUser] : undefined}
                  text={item.text}
                  imageUrl={item.image_url}
                  imageAltText={item.image_alt_text}
                  disabled={item.is_dragging && item.user_id !== student.id}
                />
              </DraggableLabel>
            </Box>
          );
        })}
      </SimpleGrid>
      {!visibleItems.length && isLandscape && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          padding={pxToRem(currentBreakpoints.padding)}
        >
          <Text
            textStyle="gameText"
            fontWeight="600"
            fontSize={pxToRem(currentBreakpoints.fontSize)}
            color="primary.dark-gray"
          >
            {t("classificationResponseGroup.answerSectionEmptyState")}
          </Text>
        </Box>
      )}
    </>
  );
};
