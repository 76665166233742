import {
  AvatarProps,
  Box,
  Avatar as ChakraAvatar,
  Link,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

import { ColorScheme, NavigationLink } from "adminComponents";

import { Button } from "../Button";
import { Icon } from "../Icon";
import { PremiumIcon } from "../PremiumIcon";

/**
 * noting the size options from chakras avatar:
 *  2xs = 16x16
 *  xs  = 24x24
 *  sm  = 32x32
 *  md  = 48x48
 *  lg  = 64x64
 *  xl  = 96x96
 *  2xl = 128x128
 */
export interface IProps extends AvatarProps {
  backgroundColor?: ColorScheme;
  showPremiumMarker?: boolean;
  showEditIcon?: boolean;
  editIconBoxSize?: number;
  handleClick?: () => void;
  navigationLink?: NavigationLink;
}

export const Avatar: React.FC<IProps> = ({
  backgroundColor = "primary.golden-light",
  showPremiumMarker = true,
  showEditIcon = false,
  editIconBoxSize = 7,
  handleClick,
  name,
  navigationLink,
  size,
  src,
  sx,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const hasInteraction = !!handleClick || !!navigationLink;
  const css = useMultiStyleConfig("AdminAvatar", { hasInteraction });

  const AvatarEl = useMemo(() => {
    return (
      <ChakraAvatar
        bg={backgroundColor}
        name={name}
        showBorder
        size={size}
        src={src}
        sx={sx}
        position="relative"
      >
        {showEditIcon && (
          <Icon
            boxSize={editIconBoxSize}
            as="span"
            icon="edit"
            iconColor="primary.white"
            variant="circleWithBackgroundColor"
            bgColor="primary.golden"
            position="absolute"
            bottom="0"
            right="0"
            aria-label={navigationLink?.label || ""}
          />
        )}
      </ChakraAvatar>
    );
  }, [
    backgroundColor,
    name,
    size,
    src,
    sx,
    showEditIcon,
    editIconBoxSize,
    navigationLink?.label,
  ]);

  if (navigationLink) {
    return (
      <Link
        sx={css.outer}
        href={navigationLink.isExternal ? navigationLink.to : undefined}
        isExternal={navigationLink.isExternal}
        aria-label={navigationLink.label || ""}
        as={navigationLink.isExternal ? Link : RouterLink}
        to={navigationLink.isExternal ? undefined : navigationLink.to}
      >
        {AvatarEl}
      </Link>
    );
  }

  if (handleClick) {
    return (
      <>
        <Button __css={css.outer} onClick={handleClick}>
          {AvatarEl}
        </Button>
        {showPremiumMarker && (
          <Box
            __css={css.premiumIcon}
            aria-label={t("common.premiumAccount")}
            title={t("common.premiumAccount")}
            aria-role="img"
            role="img"
          >
            <PremiumIcon size={18} type="account" />
          </Box>
        )}
      </>
    );
  }

  return AvatarEl;
};
