import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import { IOption } from "adminComponents/atoms/Dropdown";
import { LoadingSpinner } from "adminComponents/atoms/LoadingSpinner";
import { StudentAssignmentReportScreen } from "adminComponents/screens/StudentAssignmentReportScreen";
import { generateStudentName } from "adminComponents/utils";
import { useErrorToast } from "adminComponents/utils/toast";
import { usePageTrack } from "lib/contexts/analytics";
import { usePageTitle } from "lib/hooks/usePageTitle";
import {
  useFetchAssignment,
  useFetchAssignmentUserAttempts,
} from "links/lib/features/assignments";
import { useAuth } from "links/lib/features/auth";
import {
  useFetchClassroom,
  useFetchClassroomUsers,
} from "links/lib/features/classrooms";
import {
  AnalyticsEvent,
  AnalyticsPage,
  UsersGroupsRole,
} from "links/lib/types";
import { redirectIfNaN } from "links/lib/util";
import { useDeleteAssignment } from "screens/TeacherClassrooms/shared/hooks/useDeleteAssignment";
import { useEditAssignment } from "screens/TeacherClassrooms/shared/hooks/useEditAssignment";
import { useNavigationData } from "screens/TeacherDashboard/contexts/TeacherNavigationDataProvider";

import { QuestionsTab } from "./components/QuestionsTab";
import { StandardsTab } from "./components/StandardsTab";

export enum AssignmentStudentDetailTab {
  Questions = 0,
  Standards = 1,
}

export const AssignmentStudentDetail: React.FC = () => {
  const { navigationData } = useNavigationData();
  const params = useParams() as {
    classroomId: string;
    assignmentId: string;
    studentId: string;
  };
  const history = useHistory();
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });
  const { isFeatureEnabled } = useAuth();

  usePageTrack(
    AnalyticsPage.TeacherDashboard_ClassroomDetail_AssignmentStudentDetail
  );

  const { assignmentId, classroomId, studentId } = params;

  redirectIfNaN(assignmentId, `/t/classrooms/${classroomId}/assignments`);

  const handleTabChange = useCallback(
    (tab: AssignmentStudentDetailTab) => {
      switch (tab) {
        case AssignmentStudentDetailTab.Questions:
          history.push(
            `/t/classrooms/${classroomId}/assignments/${assignmentId}/students/${studentId}/questions`
          );
          return;
        case AssignmentStudentDetailTab.Standards:
          history.push(
            `/t/classrooms/${classroomId}/assignments/${assignmentId}/students/${studentId}/standards`
          );
          return;
      }
    },
    [history, assignmentId, classroomId, studentId]
  );
  const isQuestionsTab = useRouteMatch(
    "/t/classrooms/:classroom_id/assignments/:assignment_id/students/:studentId/questions"
  );
  const isStandardsTab = useRouteMatch(
    "/t/classrooms/:classroom_id/assignments/:assignment_id/students/:studentId/standards"
  );

  const tabIndex = useMemo(() => {
    if (isQuestionsTab) {
      return AssignmentStudentDetailTab.Questions;
    }
    if (isStandardsTab) {
      return AssignmentStudentDetailTab.Standards;
    }
  }, [isQuestionsTab, isStandardsTab]);

  const classroomFetch = useFetchClassroom({
    id: classroomId,
    onPermissionDenied: () => {
      history.push("/t/classrooms");
    },
  });
  const assignmentFetch = useFetchAssignment({ id: assignmentId });
  const classroomStudentsFetch = useFetchClassroomUsers({
    classroom_id: classroomId,
    group_roles: [UsersGroupsRole.Member],
  });
  const userAttemptsFetch = useFetchAssignmentUserAttempts({
    assignment_id: assignmentId,
    group_id: classroomId,
    user_id: studentId,
    onSuccess: (data) => {
      const mostRecentAttempt = data.user_attempts[0];
      setSelectedAttempt(mostRecentAttempt);
      setSelectedAttemptAccuracy(mostRecentAttempt?.accuracy);
    },
  });

  useErrorToast(classroomFetch.error);
  useErrorToast(assignmentFetch.error);
  useErrorToast(classroomStudentsFetch.error);
  useErrorToast(userAttemptsFetch.error);

  const classroom = classroomFetch.data?.classroom;
  const assignment = assignmentFetch.data?.assignment;
  const student = useMemo(() => {
    return classroomStudentsFetch.data?.users.find((u) => u.id === studentId);
  }, [classroomStudentsFetch.data, studentId]);

  const [selectedAttemptAccuracy, setSelectedAttemptAccuracy] = useState(
    userAttemptsFetch.data?.user_attempts?.[0]?.accuracy
  );
  const [selectedAttempt, setSelectedAttempt] = useState(
    userAttemptsFetch.data?.user_attempts?.[0]
  );

  usePageTitle([
    student ? generateStudentName(student).primary : undefined,
    assignment?.practice_set.title
      ? `${t("assignmentDetailContainer.assignment")}: ${
          assignment?.practice_set.title
        }`
      : undefined,
    classroom?.name,
  ]);

  const navData = useMemo(() => {
    const breadcrumbs = [
      {
        label: t("assignmentDetailContainer.breadcrumbClassrooms"),
        to: "/t/classrooms",
        isExternal: false,
      },
      {
        label: classroom?.name || "",
        to: `/t/classrooms/${classroomId}`,
        isExternal: false,
      },
      {
        label:
          `${t("assignmentDetailContainer.assignment")}: ${
            assignment?.practice_set.title
          }` || "",
        to: `/t/classrooms/${classroomId}/assignments/${assignmentId || ""}`,
        isExternal: false,
      },
      {
        label: generateStudentName(student || {}).primary,
        isExternal: false,
      },
    ];

    return {
      ...navigationData,
      breadcrumbs,
    };
  }, [
    navigationData,
    classroom,
    classroomId,
    t,
    assignment,
    assignmentId,
    student,
  ]);

  const isLoading =
    classroomFetch.isLoading ||
    assignmentFetch.isLoading ||
    classroomStudentsFetch.isLoading ||
    userAttemptsFetch.isLoading;

  const classrooms = classroom ? [classroom] : [];
  const { modal: reassignAssignmentModal, handleEditAssignment } =
    useEditAssignment({
      classrooms,
      editConfirmAnalyticsEvent:
        AnalyticsEvent.TeacherDashboard_ClassroomDetail_AssignmentStudentDetail_EditAssignmentConfirm,
      editIntentAnalyticsEvent:
        AnalyticsEvent.TeacherDashboard_ClassroomDetail_AssignmentStudentDetail_EditAssignmentIntent,
    });

  const handleDeleteAssignmentSuccess = () => {
    history.push(`/t/classrooms/${classroomId}/assignments`);
  };

  const {
    modal: deleteAssignmentModal,
    handleDeleteIntent: handleDeleteAssignment,
  } = useDeleteAssignment({
    classroom_id: classroomId,
    onSuccess: handleDeleteAssignmentSuccess,
    deleteIntentAnalyticsEvent:
      AnalyticsEvent.TeacherDashboard_ClassroomDetail_AssignmentStudentDetail_DeleteAssignmentIntent,
    deleteConfirmAnalyticsEvent:
      AnalyticsEvent.TeacherDashboard_ClassroomDetail_AssignmentStudentDetail_DeleteAssignmentConfirm,
  });

  if (!assignment || !student || !classroom) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <StudentAssignmentReportScreen
        assignment={assignment}
        navigationData={navData}
        handleTabChange={handleTabChange}
        tabIndex={tabIndex}
        student={student}
        lastAttempt={
          userAttemptsFetch.data?.user_attempts?.[0]?.created_at || ""
        }
        heroCardProps={{
          accuracy: selectedAttemptAccuracy || 0,
          attempts: userAttemptsFetch.data?.user_attempts.length || 0,
          assignment,
          handleDelete: handleDeleteAssignment,
          handleEdit: handleEditAssignment,
        }}
        isLoading={isLoading}
        showPremiumMarker={!isFeatureEnabled("playtime.teacher.hide_premium")}
        attempts={userAttemptsFetch.data?.user_attempts}
        selectedAttempt={selectedAttempt}
        handleChangeAttempt={(opt: IOption) => {
          const attempt = userAttemptsFetch.data?.user_attempts.find(
            (a) => a.id === opt.value
          );
          setSelectedAttemptAccuracy(attempt?.accuracy);
          setSelectedAttempt(attempt);
        }}
      >
        <Switch>
          <Redirect
            from="/t/classrooms/:classroomId/assignments/:assignmentId/students/:studentId"
            exact={true}
            to="/t/classrooms/:classroomId/assignments/:assignmentId/students/:studentId/questions"
          />
          <>
            <Route path="/t/classrooms/:classroomId/assignments/:assignmentId/students/:studentId/questions">
              <QuestionsTab
                classroom={classroom}
                assignment={assignment}
                student={student}
                practiceSetSessionId={selectedAttempt?.practice_set_session_id}
              />
            </Route>
            <Route path="/t/classrooms/:classroomId/assignments/:assignmentId/students/:studentId/standards">
              <StandardsTab
                classroom={classroom}
                assignment={assignment}
                student={student}
                practiceSetSessionId={selectedAttempt?.practice_set_session_id}
              />
            </Route>
          </>
        </Switch>
      </StudentAssignmentReportScreen>
      {reassignAssignmentModal}
      {deleteAssignmentModal}
    </>
  );
};
