import { Box } from "@chakra-ui/react";
import React, { useMemo } from "react";

import { pxToRem } from "adminComponents/utils";
import { useSessionRoundState } from "links/lib/contexts/sessionRoundState";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { ResponseOption } from "sessionComponents/hooks/useItemResponseOptions";
import { TeacherReviewOption } from "sessionComponents/molecules/TeacherReviewOption";
import { useSafeRoundGroupStateArray } from "sharedComponents/hooks/useSafeRoundGroupStateArray";

interface ITeacherDiagramReviewOptionsProps {
  studentTotal: number;
  answerOptions: ResponseOption[];
}

export const TeacherDiagramReviewOptions: React.FC<
  ITeacherDiagramReviewOptionsProps
> = ({ studentTotal, answerOptions }) => {
  const roundState = useSessionRoundState();
  const roundGroupsState = useSafeRoundGroupStateArray();
  const { match: currentBreakpoints } = useBreakpoints();

  const { practice_set_session_item, variant } = roundState;

  const allGroupsCorrectChoices = useMemo(() => {
    return roundGroupsState.flatMap((groupState) => {
      const userChoices = groupState?.dragsort?.items || [];
      return userChoices.filter((choice) => {
        return choice.is_correct || false;
      });
    });
  }, [roundGroupsState]);

  const answerCounts = useMemo(() => {
    return answerOptions.map(
      (option) =>
        allGroupsCorrectChoices.filter((choice) => choice.id === option.id)
          .length
    );
  }, [answerOptions, allGroupsCorrectChoices]);

  const maxAnswerCount = useMemo(() => {
    return Math.max(...answerCounts);
  }, [answerCounts]);

  return (
    <Box
      gap={pxToRem(currentBreakpoints.margin / 2)}
      display="flex"
      flexDirection="column"
    >
      {answerOptions.map((option, index) => {
        return (
          <TeacherReviewOption
            key={option.id}
            text={option.text}
            imageAltText={option.image_alt_text}
            imageUrl={option.image_url}
            isCorrect={false}
            variant={variant}
            questionType={practice_set_session_item.question_type}
            diagramMarkerNumber={index + 1}
            studentTotal={studentTotal}
            alwaysShowAccuracyBar
            answerCount={answerCounts[index]}
            maxAnswerCount={maxAnswerCount}
          />
        );
      })}
    </Box>
  );
};
