import { pxToRem } from "adminComponents/utils/pxToRem";

export const AdminTeacherHomepage = {
  baseStyle: {
    headerContainer: {
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
      flexDirection: ["column", null, "row"],
      gap: [pxToRem(20), null, null],
    },
    header: {
      maxWidth: "100%",
      textAlign: ["center", null, "left"],
      alignItems: ["center", null, "start"],
    },
    headerAvatarContainer: {
      flexDir: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    contentWrapper: {
      flexDirection: "column",
      gap: pxToRem(56),
      marginBottom: pxToRem(100),
    },
    sectionHeading: {
      marginBottom: pxToRem(16),
    },
    classroomsHeader: {
      justifyContent: "space-between",
      marginBottom: pxToRem(16),
    },
    classroomsContainer: {
      gap: pxToRem(8),
      marginBottom: [pxToRem(16), null, "0px"],
    },
    assignmentsContainer: {
      flexDirection: "column",
      gap: pxToRem(10),
    },
    studentsHeader: {
      marginBottom: pxToRem(16),
      alignItems: "center",
      gap: pxToRem(10),
    },
  },
};
