import { Box, BoxProps, Collapse, Flex, useDisclosure } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { ColorScheme } from "adminComponents";
import { Icon, IconType } from "adminComponents/atoms/Icon";
import { Text } from "adminComponents/atoms/Text";
import { ToggleButton } from "adminComponents/atoms/ToggleButton";
import { colorMapping } from "adminComponents/theme/colors";
import { pxToRem } from "adminComponents/utils/pxToRem";

import { ReactComponent as BgIcon } from "./resource/icon.svg";

interface IProps extends BoxProps {
  icon: IconType;
  iconColor: ColorScheme;
  title: string;
  subTitle: string;
}

export const QuestionTypeDescription: React.FC<IProps> = ({
  children,
  icon,
  iconColor,
  title,
  subTitle,
}) => {
  const { isOpen, onToggle: handleToggle } = useDisclosure();
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });

  return (
    <Box
      display="flex"
      gap={[pxToRem(13.5), null, pxToRem(32)]}
      pb={[pxToRem(21), null, pxToRem(27)]}
      borderBottom="1px solid"
      borderColor="primary.medium-gray"
    >
      <Box
        position="relative"
        width={[pxToRem(44), null, pxToRem(59)]}
        height={[pxToRem(44), null, pxToRem(59)]}
        flexShrink={0}
      >
        <BgIcon
          color={colorMapping[iconColor]}
          aria-hidden="true"
          focusable={false}
        />
        <Icon
          position="absolute"
          top="50%"
          left="50%"
          transform="translateX(-50%) translateY(-50%)"
          display="inline-flex"
          icon={icon}
          iconColor="primary.warm-black"
          width={pxToRem(24)}
          height={pxToRem(24)}
          decorative
        />
      </Box>
      <Box>
        <Text as="h4" variant="adminP1" mb={[pxToRem(8), null, pxToRem(4)]}>
          {title}
        </Text>
        {children && (
          <>
            <Flex align="end" gap={pxToRem(4)}>
              <Text as="p" variant="adminP3" color="primary.dark-gray">
                {subTitle}
              </Text>
              <ToggleButton
                openLabel={t("common.edit")}
                closedLabel={t("common.edit")}
                handleToggle={handleToggle}
                isOpen={isOpen}
              />
            </Flex>
            <Collapse in={isOpen}>
              <Box
                mt={pxToRem(12)}
                display="flex"
                gap={[pxToRem(12), null, pxToRem(40)]}
                flexWrap="wrap"
                alignItems="center"
              >
                {children}
              </Box>
            </Collapse>
          </>
        )}
      </Box>
    </Box>
  );
};
