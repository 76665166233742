import { Box, VStack } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { animated, config, useTransition } from "react-spring";

import { usePageTrack } from "lib/contexts/analytics";
import { useSession } from "links/lib/contexts/session";
import { useSessionActions } from "links/lib/contexts/sessionActions";
import { useSessionGameState } from "links/lib/contexts/sessionGameState";
import { useSessionGroups } from "links/lib/contexts/sessionGroups";
import { useSessionRoundState } from "links/lib/contexts/sessionRoundState";
import { SessionRoundThemeProvider } from "links/lib/contexts/sessionRoundTheme";
import { useSessionScene } from "links/lib/contexts/sessionScene";
import { useGenerateSessionRoundTheme } from "links/lib/features/sessionRoundTheme";
import {
  AnalyticsPage,
  IUser,
  PracticeSessionItemVariantType,
  QuestionType,
  SessionScene,
  UserRole,
} from "links/lib/types";
import { RoundReviewProvider } from "sessionComponents/contexts/roundReview";
import { StudentProvider } from "sessionComponents/contexts/student";
import { StudentReactionsProvider } from "sessionComponents/contexts/studentReactions";
import { useHeaderTitleText } from "sessionComponents/hooks/useHeaderTitleText";
import { NoConnectedStudentsModal } from "sessionComponents/molecules/NoConnectedStudentsModal";
import { TeacherHeader } from "sessionComponents/organisms/TeacherHeader";
import { BigBoardSpin } from "sessionComponents/scenes/BigBoardSpin";
import { BigBoardTokenPlacementScene } from "sessionComponents/scenes/BigBoardTokenPlacement";
import { GameInstructions } from "sessionComponents/scenes/GameInstructions";
import { LeaderBoard } from "sessionComponents/scenes/LeaderBoard";
import { Podium } from "sessionComponents/scenes/Podium";
import { PrizeRoundAwards } from "sessionComponents/scenes/PrizeRoundAwards";
import { TeacherClassificationRoundReview } from "sessionComponents/scenes/TeacherClassificationRoundReview";
import { TeacherLobby } from "sessionComponents/scenes/TeacherLobby";
import { TeacherQuestionRound } from "sessionComponents/scenes/TeacherQuestionRound";
import { TeacherRoundReview } from "sessionComponents/scenes/TeacherRoundReview";
import { TeacherTeamUp } from "sessionComponents/scenes/TeacherTeamUp";
import { fullScreenStyles } from "sessionComponents/theme";
import { useDetectOrientation } from "sessionComponents/theme/hooks/useDetectOrientation";
import { SessionMusic } from "sessionComponents/types";
import { getIsAppleMobileDevice } from "sessionComponents/utils/getIsAppleMobileDevice";
import { SessionAnalyticsProvider } from "sharedComponents/contexts/sessionAnalytics";
import {
  SessionAudioProvider,
  useSessionAudio,
} from "sharedComponents/contexts/sessionAudio";
import { useSafeConnectedUserArray } from "sharedComponents/hooks/useSafeConnectedUserArray";
import BossaMP3 from "sharedComponents/resource/audio/teacher/bossa.mp3";
import BossaWebM from "sharedComponents/resource/audio/teacher/bossa.webm";
import GoldfishMP3 from "sharedComponents/resource/audio/teacher/goldfish.mp3";
import GoldfishWebM from "sharedComponents/resource/audio/teacher/goldfish.webm";
import LobstersMP3 from "sharedComponents/resource/audio/teacher/lobsters.mp3";
import LobstersWebM from "sharedComponents/resource/audio/teacher/lobsters.webm";
import TeacherSessionAudioSpriteDetail from "sharedComponents/resource/audio/teacher/output.json";
import TeacherSessionAudioSpriteSrcMP3 from "sharedComponents/resource/audio/teacher/output.mp3";
import TeacherSessionAudioSpriteSrcWebM from "sharedComponents/resource/audio/teacher/output.webm";
import PlaceYourTokenMP3 from "sharedComponents/resource/audio/teacher/place_your_token.mp3";
import PlaceYourTokenWebM from "sharedComponents/resource/audio/teacher/place_your_token.webm";
import PrizeRoundMP3 from "sharedComponents/resource/audio/teacher/prize_round.mp3";
import PrizeRoundWebM from "sharedComponents/resource/audio/teacher/prize_round.webm";
import QuokkasMP3 from "sharedComponents/resource/audio/teacher/quokkas.mp3";
import QuokkasWebM from "sharedComponents/resource/audio/teacher/quokkas.webm";
import TeamShuffleShuffleMP3 from "sharedComponents/resource/audio/teacher/team_shuffle_shuffle.mp3";
import TeamShuffleShuffleWebM from "sharedComponents/resource/audio/teacher/team_shuffle_shuffle.webm";

type SessionTeacherProps = {
  authUser: IUser;
};

const AnimatedBox = animated(Box);

const backgroundClips = {
  [SessionMusic.Goldfish]: [
    { src: GoldfishWebM, type: "audio/webm" },
    { src: GoldfishMP3, type: "audio/mpeg" },
  ],
  [SessionMusic.Lobsters]: [
    { src: LobstersWebM, type: "audio/webm" },
    { src: LobstersMP3, type: "audio/mpeg" },
  ],
  [SessionMusic.Quokkas]: [
    { src: QuokkasWebM, type: "audio/webm" },
    { src: QuokkasMP3, type: "audio/mpeg" },
  ],
  [SessionMusic.Bossa]: [
    { src: BossaWebM, type: "audio/webm" },
    { src: BossaMP3, type: "audio/mpeg" },
  ],
  [SessionMusic.PlaceYourToken]: [
    { src: PlaceYourTokenWebM, type: "audio/webm" },
    { src: PlaceYourTokenMP3, type: "audio/mpeg" },
  ],
  [SessionMusic.PrizeRound]: [
    { src: PrizeRoundWebM, type: "audio/webm" },
    { src: PrizeRoundMP3, type: "audio/mpeg" },
  ],
  [SessionMusic.TeamShuffleShuffle]: [
    { src: TeamShuffleShuffleWebM, type: "audio/webm" },
    { src: TeamShuffleShuffleMP3, type: "audio/mpeg" },
  ],
};

export const TeacherView: React.FC<SessionTeacherProps> = ({ authUser }) => {
  return (
    <SessionAudioProvider
      srcVolume={1}
      spriteSrc={[
        TeacherSessionAudioSpriteSrcWebM,
        TeacherSessionAudioSpriteSrcMP3,
      ]}
      sprite={TeacherSessionAudioSpriteDetail.sprite}
      backgroundClips={backgroundClips}
    >
      <TeacherViewContent authUser={authUser} />
    </SessionAudioProvider>
  );
};

const TeacherViewContent: React.FC<SessionTeacherProps> = ({ authUser }) => {
  const scene = useSessionScene();
  const session = useSession();
  const gameState = useSessionGameState();
  const roundState = useSessionRoundState();
  const { round_number } = roundState;

  usePageTrack(AnalyticsPage.Session_ClassSession_Teacher);
  const roundTheme = useGenerateSessionRoundTheme(
    round_number,
    gameState?.game_type
  );

  return (
    <>
      <StudentProvider userId={authUser.id}>
        <SessionAnalyticsProvider
          session={session}
          round_state={roundState}
          scene={scene}
        >
          <SessionRoundThemeProvider roundTheme={roundTheme}>
            <StudentReactionsProvider>
              <RoundReviewProvider>
                <TeacherScene userId={authUser.id} />
              </RoundReviewProvider>
            </StudentReactionsProvider>
          </SessionRoundThemeProvider>
        </SessionAnalyticsProvider>
      </StudentProvider>
    </>
  );
};

const TeacherScene: React.FC<{ userId: string }> = ({ userId }) => {
  const history = useHistory();
  const { isPortrait } = useDetectOrientation();

  const { setVolume, volume } = useSessionAudio();

  const { initiatePodium, extendRoundTime } = useSessionActions();

  const scene = useSessionScene();
  const connectedUsers = useSafeConnectedUserArray();
  const groups = useSessionGroups();
  const roundState = useSessionRoundState();
  const {
    round_number,
    practice_set_session_item,
    variant,
    starts_at,
    ends_at,
  } = roundState;
  const gameState = useSessionGameState();
  const isEndOfGame = round_number === gameState?.final_round_number;

  const headerTitleText = useHeaderTitleText({
    isTeacher: true,
    userId,
  });

  const isRound = scene === SessionScene.Round;
  const isTextMatch = [
    PracticeSessionItemVariantType.CoopTextMatch,
    PracticeSessionItemVariantType.CoopTextMatchNumeric,
  ].includes(variant);
  const isDrawRound = variant === PracticeSessionItemVariantType.CoopDraw;
  const hasTimes = !!starts_at && !!ends_at;
  const showTimer =
    !isTextMatch &&
    !isDrawRound &&
    isRound &&
    hasTimes &&
    starts_at !== ends_at;

  const onVolumeChange = (volume: number) => {
    setVolume(volume / 100);
  };

  const onEndGame = () => {
    if (scene === SessionScene.Podium) {
      history.push("/");
    } else {
      initiatePodium();
    }
  };

  const isAppleMobileDevice = useMemo(() => {
    return getIsAppleMobileDevice();
  }, []);

  const showVolumeControlButton = () => {
    return (
      // Volume can't be set by JavaScript on iOS devices
      !isAppleMobileDevice &&
      isPortrait &&
      [
        SessionScene.Lobby,
        SessionScene.Grouping,
        SessionScene.OuterGameIntro,
      ].includes(scene)
    );
  };
  const showNoConnectedStudentsModal = () => {
    return (
      connectedUsers.filter((u) => u.role === UserRole.Student).length === 0 &&
      ![SessionScene.Lobby, SessionScene.Podium].includes(scene)
    );
  };

  const sceneTransition = useTransition([scene], {
    from: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
    },
    leave: {
      opacity: 1,
    },
    exitBeforeEnter: false,
    config: config.slow,
  });

  const renderScene = (scene: SessionScene): JSX.Element => {
    switch (scene) {
      case SessionScene.Lobby:
        return <TeacherLobby />;
      case SessionScene.Grouping:
        return <TeacherTeamUp />;
      case SessionScene.LeaderBoard:
        return <LeaderBoard isTeacher isEndOfGame={isEndOfGame} />;
      case SessionScene.Podium:
        // if game ends from lobby, redirect to home. This redirect is issued here
        // because the "End Game" button instructs the backend to initiate the Podium.
        // Generally, we want to display the game's results thus far, but the lack of
        // valid groups indicates that we never made it past the introduction/lobby.
        if (!groups || !Object.keys(groups).length) {
          return <Redirect to="/" />;
        }
        return <Podium isTeacher outerGame={gameState?.game_type} />;
      case SessionScene.OuterGameIntro:
        return <GameInstructions isTeacher />;
      case SessionScene.OuterGame:
        return <BigBoardTokenPlacementScene isTeacher />;
      case SessionScene.PrizeRound:
        return <BigBoardSpin isTeacher />;
      case SessionScene.PrizeRoundAwards:
        return <PrizeRoundAwards isTeacher />;
      case SessionScene.Round:
      case SessionScene.Voting:
      case SessionScene.VotingResults:
        return <TeacherQuestionRound />;
      case SessionScene.RoundReview:
      case SessionScene.VotingAwards:
        return (
          <>
            {practice_set_session_item.question_type ===
            QuestionType.Classify ? (
              <TeacherClassificationRoundReview />
            ) : (
              <TeacherRoundReview />
            )}
          </>
        );
    }

    return <>Scene not implemented: {scene}</>;
  };

  return (
    <>
      {sceneTransition((style, s) => {
        return (
          <VStack {...fullScreenStyles} spacing="0">
            <TeacherHeader
              showVolumeControlButton={showVolumeControlButton()}
              // Volume can't be set by JavaScript on iOS devices
              disableVolumeControlFlyout={isAppleMobileDevice}
              headingText={headerTitleText}
              volume={volume * 100}
              onVolumeChange={onVolumeChange}
              onExit={onEndGame}
              showTeamsModalButton={!!Object.keys(groups).length}
              timer={
                showTimer
                  ? {
                      startsAt: starts_at,
                      endsAt: ends_at,
                    }
                  : undefined
              }
              onExtendRoundTime={extendRoundTime}
            />

            <AnimatedBox
              zIndex={s === scene ? "auto" : "-1"}
              w="full"
              h="full"
              overflow="hidden"
              style={style}
            >
              {renderScene(s)}
            </AnimatedBox>
          </VStack>
        );
      })}

      <NoConnectedStudentsModal
        isOpen={showNoConnectedStudentsModal()}
        onEndGame={initiatePodium}
      />
    </>
  );
};
