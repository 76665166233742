import { QuestionIcon } from "@chakra-ui/icons";
import {
  Box,
  Center,
  Circle,
  Flex,
  HStack,
  Hide,
  Show,
  Stack,
  VStack,
  Wrap,
  WrapItem,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { Avatar } from "adminComponents/atoms/Avatar";
import { Button } from "adminComponents/atoms/Button";
import { Card } from "adminComponents/atoms/Card";
import { Divider } from "adminComponents/atoms/Divider";
import { Heading } from "adminComponents/atoms/Heading";
import { Icon } from "adminComponents/atoms/Icon";
import { IconButtonWithTooltip } from "adminComponents/atoms/IconButtonWithTooltip";
import { IconTooltip } from "adminComponents/atoms/IconTooltip";
import { SubmarkLogo } from "adminComponents/atoms/SubmarkLogo";
import { Tag } from "adminComponents/atoms/Tag";
import { TagWithTooltip } from "adminComponents/atoms/Tag/Tag.stories";
import { Text } from "adminComponents/atoms/Text";
import { colors } from "adminComponents/theme/colors";
import { generateTeacherName, pxToRem } from "adminComponents/utils";
import { productName, regionMap } from "links/lib/constants";
import { useAuth } from "links/lib/features/auth";
import {
  IGradeLevel,
  ISubject,
  IUser,
  SharingSettingsVisibility,
  UserPublicProfileStatistics,
  UserRole,
} from "links/lib/types";

export interface ITeacherProfileCardProps {
  user: IUser;
  statistics?: UserPublicProfileStatistics;
  authUser?: IUser;
  gradeLevels?: Array<IGradeLevel>;
  subjects?: Array<ISubject>;
  showSocialMediaShare?: boolean;
  isFollowing?: boolean;
  onOpenSocialMediaShareModal: () => void;
  onOpenFollowersModal: () => void;
  onOpenFollowingModal: () => void;
  onFollowUser?: () => void;
  onUnfollowUser?: () => void;
}

export const TeacherProfileCard: React.FC<ITeacherProfileCardProps> = ({
  user,
  statistics,
  authUser,
  gradeLevels,
  subjects,
  showSocialMediaShare = true,
  isFollowing,
  onOpenSocialMediaShareModal,
  onOpenFollowersModal,
  onOpenFollowingModal,
  onFollowUser,
  onUnfollowUser,
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });

  const { authSharingSettings } = useAuth();
  const history = useHistory();

  const styles = useMultiStyleConfig("AdminTeacherProfileCardBody", {});

  const teacherSubjects = useMemo(() => {
    if (!subjects) return [];

    return (user.teacher_subjects || [])
      .map((s) => subjects.find((subject) => s.subject_id === subject.id))
      .filter((s) => !!s) as ISubject[];
  }, [subjects, user.teacher_subjects]);

  const teacherGradeLevels = useMemo(() => {
    if (!gradeLevels) return [];

    return (user.teacher_grade_levels || [])
      .map((g) =>
        gradeLevels.find((gradeLevel) => g.grade_level_id === gradeLevel.id)
      )
      .filter((g) => !!g) as IGradeLevel[];
  }, [gradeLevels, user.teacher_grade_levels]);

  const name = generateTeacherName(user || {}).primary;

  const isOwnProfile = user.id === authUser?.id;

  const visibilityData = useMemo(() => {
    if (!isOwnProfile || !authSharingSettings) {
      return {
        visibilityDescription: "",
        visibilityTooltipHeading: "",
      };
    }
    switch (authSharingSettings.visibility) {
      case SharingSettingsVisibility.Private:
        return {
          visibilityDescription: t(
            "teacherProfileCard.visibilityDescriptionPrivate"
          ),
          visibilityTooltipHeading: t(
            "teacherProfileCard.visibilityTooltipHeadingPrivate"
          ),
        };
      case SharingSettingsVisibility.GSOnly:
        return {
          visibilityDescription: t(
            "teacherProfileCard.visibilityDescriptionGSOnly"
          ),
          visibilityTooltipHeading: t(
            "teacherProfileCard.visibilityTooltipHeadingGSOnly"
          ),
        };
      case SharingSettingsVisibility.Public:
        return {
          visibilityDescription: t(
            "teacherProfileCard.visibilityDescriptionPublic"
          ),
          visibilityTooltipHeading: t(
            "teacherProfileCard.visibilityTooltipHeadingPublic"
          ),
        };
    }
  }, [isOwnProfile, authSharingSettings, t]);

  const isInternalContentSpecialist =
    user.organization_is_internal && user.role === UserRole.ContentSpecialist;

  return (
    <Card
      borderColor="primary.tan"
      backgroundColor="primary.white"
      variant="adminCardThickBorder"
      noPadding
    >
      <Box __css={styles.container}>
        {/* Header (Menu buttons) */}
        <Box __css={styles.header}>
          <HStack
            w="full"
            justifyContent={isOwnProfile ? "space-between" : "flex-end"}
          >
            {isOwnProfile && (
              <TagWithTooltip
                leftIcon="eye"
                tooltip={
                  <>
                    <Text size="sm" color="white" fontWeight="bold">
                      {visibilityData.visibilityTooltipHeading}
                    </Text>
                    <Text size="sm" color="white">
                      {t("teacherProfileCard.visibilityTooltipEditLabel")}
                    </Text>
                  </>
                }
              >
                {visibilityData.visibilityDescription}
              </TagWithTooltip>
            )}
            <HStack>
              {!isOwnProfile && !isFollowing && onFollowUser && (
                <HStack>
                  <Button
                    variant="adminButtonFilled"
                    onClick={onFollowUser}
                    leftIcon={<Icon icon="add" />}
                    size="sm"
                  >
                    {t("teacherProfileCard.followButton")}
                  </Button>
                  <IconTooltip
                    placement="top-end"
                    triggerEl={
                      <QuestionIcon
                        color="utility.link"
                        width={pxToRem(24)}
                        height={pxToRem(24)}
                      />
                    }
                  >
                    {t("teacherProfileCard.followButtonTooltip")}
                  </IconTooltip>
                </HStack>
              )}
              {!isOwnProfile && isFollowing && onUnfollowUser && (
                <HStack>
                  <Button
                    variant="adminButtonOutlined"
                    onClick={onUnfollowUser}
                    leftIcon={<Icon icon="close" />}
                    size="sm"
                  >
                    {t("teacherProfileCard.unfollowButton")}
                  </Button>
                  <IconTooltip
                    placement="top-end"
                    triggerEl={
                      <QuestionIcon
                        color="utility.link"
                        width={pxToRem(24)}
                        height={pxToRem(24)}
                      />
                    }
                  >
                    {t("teacherProfileCard.unfollowButtonTooltip")}
                  </IconTooltip>
                </HStack>
              )}
              {isOwnProfile && (
                <IconButtonWithTooltip
                  shape="type2"
                  icon="edit_outlined"
                  onClick={() => {
                    history.push("/t/account/profile/settings");
                  }}
                  ariaLabel={t("common.edit")}
                  tooltipProps={{ popoverVariant: "default" }}
                />
              )}
              {showSocialMediaShare && (
                <IconButtonWithTooltip
                  shape="type2"
                  icon="share_outlined"
                  onClick={onOpenSocialMediaShareModal}
                  ariaLabel={t("common.share")}
                  tooltipProps={{ popoverVariant: "default" }}
                />
              )}
            </HStack>
          </HStack>
        </Box>

        <Box
          __css={styles.body}
          pt={isOwnProfile ? pxToRem(24) : { base: pxToRem(12), md: undefined }}
        >
          <Show above="md">
            <Flex sx={styles.headerAvatarContainer}>
              {isInternalContentSpecialist && (
                <Circle
                  bgColor={colors["pear-practice"]["blue-skies"]}
                  size={pxToRem(128)}
                  p={pxToRem(16)}
                  overflow="hidden"
                >
                  <SubmarkLogo />
                </Circle>
              )}
              {!isInternalContentSpecialist && (
                <Box>
                  <Avatar
                    size="2xl"
                    src={user.profile_image_url}
                    name={name}
                    backgroundColor="primary.golden-hover"
                    showEditIcon={isOwnProfile}
                    navigationLink={{
                      to: "/t/my-avatar",
                      label: t("teacherHomepage.myAvatar"),
                    }}
                  />
                </Box>
              )}
            </Flex>
          </Show>
          <Hide above="md">
            <Center>
              {isInternalContentSpecialist && (
                <Circle
                  bgColor={colors["pear-practice"]["blue-skies"]}
                  size={pxToRem(64)}
                  p={pxToRem(8)}
                  overflow="hidden"
                >
                  <SubmarkLogo />
                </Circle>
              )}
              {!isInternalContentSpecialist && (
                <Box>
                  <Avatar
                    size="lg"
                    src={user.profile_image_url}
                    name={name}
                    backgroundColor="primary.golden-hover"
                    showEditIcon={isOwnProfile}
                    editIconBoxSize={5}
                    navigationLink={{
                      to: "/t/my-avatar",
                      label: t("teacherHomepage.myAvatar"),
                    }}
                  />
                </Box>
              )}
            </Center>
          </Hide>

          <VStack alignItems="start">
            {isInternalContentSpecialist && (
              <Box w="full">
                <Heading
                  as="p"
                  variant="adminH2"
                  noOfLines={{ base: 4, md: 3 }}
                  wordBreak="normal"
                  overflowWrap="anywhere"
                  color={colors.primary["warm-black"]}
                >
                  {productName}
                </Heading>
              </Box>
            )}

            <Box w="full">
              <Heading
                as="h1"
                variant="adminH2"
                noOfLines={{ base: 4, md: 3 }}
                wordBreak="normal"
                overflowWrap="anywhere"
              >
                {name}
              </Heading>
            </Box>

            {statistics && (
              <>
                {(statistics.library_copy_teacher_count > 0 ||
                  statistics.library_copy_practice_set_count > 0) && (
                  <Box pt={pxToRem(16)} />
                )}
                {statistics.library_copy_teacher_count > 0 && (
                  <HStack>
                    <Icon icon="person_outlined" />
                    <Text>
                      <Trans
                        i18nKey="teacherProfileCard.libraryCopyTeacherCount"
                        t={t}
                        count={statistics.library_copy_teacher_count}
                        values={{
                          name,
                          countFormatted:
                            statistics.library_copy_teacher_count.toLocaleString(
                              authUser?.language
                            ),
                        }}
                      >
                        <b />
                      </Trans>
                    </Text>
                  </HStack>
                )}
                {statistics.library_copy_practice_set_count > 0 && (
                  <HStack>
                    <Icon icon="content_copy_outlined" />
                    <Text>
                      <Trans
                        i18nKey="teacherProfileCard.libraryCopyPracticeSetCount"
                        t={t}
                        count={statistics.library_copy_practice_set_count}
                        values={{
                          name,
                          countFormatted:
                            statistics.library_copy_practice_set_count.toLocaleString(
                              authUser?.language
                            ),
                        }}
                      >
                        <b />
                      </Trans>
                    </Text>
                  </HStack>
                )}
              </>
            )}

            <Stack
              direction={{ base: "column", md: "row" }}
              pt={pxToRem(32)}
              spacing={pxToRem(16)}
              w="full"
            >
              {statistics && (
                <>
                  <Button
                    variant="adminButtonOutlinedMuted"
                    onClick={onOpenFollowersModal}
                  >
                    <b>
                      {statistics.followers_count.toLocaleString(
                        authUser?.language
                      )}
                    </b>{" "}
                    {t("teacherProfileCard.followersButton", {
                      count: statistics.followers_count,
                      countFormatted: statistics.followers_count.toLocaleString(
                        authUser?.language
                      ),
                    })}
                  </Button>
                  <Button
                    variant="adminButtonOutlinedMuted"
                    onClick={onOpenFollowingModal}
                  >
                    <b>
                      {statistics.following_count.toLocaleString(
                        authUser?.language
                      )}
                    </b>{" "}
                    {t("teacherProfileCard.followingButton", {
                      count: statistics.following_count,
                    })}
                  </Button>
                </>
              )}

              {user.region &&
                !!regionMap[user.country] &&
                !!regionMap[user.country][user.region] && (
                  <HStack
                    w={{ base: "full", md: "auto" }}
                    justifyContent="center"
                  >
                    <Icon icon="map_pin" />
                    <Text>{regionMap[user.country][user.region]}</Text>
                  </HStack>
                )}
            </Stack>
          </VStack>
        </Box>

        <Box __css={styles.divider}>
          <Divider color="primary.light-gray" />
        </Box>

        <Box __css={styles.footer}>
          <Box />

          {/* Right tags */}
          <Wrap>
            {teacherSubjects.map((s) => {
              return (
                <WrapItem key={s.id}>
                  <Tag variant="adminSolid">
                    {s.parent_name ? `${s.parent_name}: ${s.name}` : s.name}
                  </Tag>
                </WrapItem>
              );
            })}

            {teacherGradeLevels.map((g) => {
              return (
                <WrapItem key={g.id}>
                  <Tag variant="adminSolid">{g.grade_level}</Tag>
                </WrapItem>
              );
            })}
          </Wrap>
        </Box>
      </Box>
    </Card>
  );
};
