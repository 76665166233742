import {
  Box,
  Flex,
  HStack,
  Show,
  SimpleGrid,
  VStack,
  useBreakpointValue,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { CoverImageIcon } from "adminComponents/atoms/CoverImageIcon";
import { Heading } from "adminComponents/atoms/Heading";
import { Tag } from "adminComponents/atoms/Tag";
import { pxToRem } from "adminComponents/utils";
import { IUser } from "links/lib/types";

import { StatCard } from "../StatCard";

export interface IProps {
  teacher: IUser;
  practiceMinutes: number;
  topHalfPracticeMinutes?: boolean;
  hasNoPremiumAccess: boolean;
  isLoading?: boolean;
}

export const AdminHomepageHeader: React.FC<IProps> = ({
  teacher,
  practiceMinutes,
  hasNoPremiumAccess,
  topHalfPracticeMinutes,
  isLoading,
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });
  const styles = useMultiStyleConfig("AdminTeacherHomepage", {});
  const headingVariant = (useBreakpointValue({
    base: "adminH2",
    md: "adminH3",
    lg: "adminH2",
  }) ?? "adminH2") as "adminH3" | "adminH2";

  return (
    <Flex sx={styles.headerContainer}>
      <HStack gap={[pxToRem(10), null, null, pxToRem(28)]}>
        <Show above="md">
          <Flex sx={styles.headerAvatarContainer}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              backgroundColor="utility.badge-light-blue"
              borderRadius="full"
              boxSize={pxToRem(240)}
              borderWidth={pxToRem(4)}
              borderColor="white"
            >
              <CoverImageIcon boxSize={pxToRem(180)} icon="HISTORY_COMMUNITY" />
            </Box>
          </Flex>
        </Show>

        <VStack sx={styles.header}>
          {!hasNoPremiumAccess && (
            <Tag
              borderRadius="full"
              color="white"
              leftIcon="crown"
              colorScheme="primary.golden"
              display="flex"
              flexDir="row"
            >
              {t("common.premium")}
            </Tag>
          )}
          {hasNoPremiumAccess && (
            <Tag
              borderRadius="full"
              color="white"
              colorScheme="dark-gray"
              display="flex"
              flexDir="row"
            >
              {t("common.freeAccount")}
            </Tag>
          )}

          <Heading variant={headingVariant} as="h1">
            {teacher.organization_name}
          </Heading>
          <>
            <SimpleGrid columns={[1, null, 2]} gap={pxToRem(12)} w="full">
              <StatCard
                large
                animate
                isLoading={isLoading}
                tooltipText={t(
                  "districtAdminHomepage.header.minutesPracticedExplanation"
                )}
                coverImage={
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    backgroundColor="utility.salmon"
                    borderRadius="full"
                    boxSize={pxToRem(60)}
                  >
                    <CoverImageIcon
                      boxSize={pxToRem(36)}
                      icon="ENGLISH_READING"
                    />
                  </Box>
                }
                totalStat={practiceMinutes}
                statLabel={t("districtAdminHomepage.header.minutesPracticed")}
                topHalfStat={topHalfPracticeMinutes}
                topHalfTooltip={t(
                  "districtAdminHomepage.header.topHalfMinutesPracticedExplanation"
                )}
              />
            </SimpleGrid>
          </>
        </VStack>
      </HStack>
    </Flex>
  );
};
