import { ISkill, SkillType } from "links/lib/types";

export interface IParseSkillsReslt {
  skills: ISkill[];
  errors: string[];
}

export const parseSkills = (skillsJsonString: string): IParseSkillsReslt => {
  let parsedSkills: ISkill[] = [];
  const errors: string[] = [];

  try {
    parsedSkills = JSON.parse(skillsJsonString);
  } catch (error) {
    const castError = error as SyntaxError;
    errors.push(
      JSON.stringify({ message: castError?.message, name: castError?.name })
    );
  }

  if (!parsedSkills) {
    errors.push("Could not parse skills.  Check format.");
    return { skills: [], errors };
  }

  if (parsedSkills.length < 1) {
    errors.push("Could not find any skills to parse.");
    return { skills: [], errors };
  }

  const skills: ISkill[] = parsedSkills.map((parsedSkill, index) => {
    if (!parsedSkill.code) {
      errors.push(`Skill ${index + 1} has no code`);
    }

    if (!parsedSkill.description) {
      errors.push(`Skill ${index + 1} has no description`);
    }

    return {
      code: parsedSkill.code,
      description: parsedSkill.description,
      id: "",
      skill_type: SkillType.PRACTICE_ORIENTED,
      preceding_skills: parsedSkill.preceding_skills,
      standards: parsedSkill.standards,
    };
  });

  return {
    skills,
    errors,
  };
};
