import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { EditStudentDetailsModal } from "adminComponents/organisms/EditStudentDetailsModal";
import { useErrorToast, useShowToast } from "adminComponents/utils/toast";
import { useAnalytics } from "lib/contexts/analytics";
import {
  useMutateClassroomStudent,
  useRemoveClassroomUser,
} from "links/lib/features/classrooms";
import { AnalyticsEvent, IGradeLevel, IUser } from "links/lib/types";

interface IArgs {
  classroom_id: string;
  gradeLevels: Array<IGradeLevel>;
}

interface IResult {
  handleEditIntent: (student: IUser) => void;
  editModal: React.ReactElement;
}

export const useEditStudent = ({
  classroom_id,
  gradeLevels,
}: IArgs): IResult => {
  const [user, setUser] = useState<IUser | undefined>();
  const showToast = useShowToast();
  const { t } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "classroomDetailContainer.studentsTab.editStudentModal",
  });
  const { trackEvent } = useAnalytics();

  const onRemoveSuccess = useCallback(() => {
    setUser(undefined);

    showToast(t("removeSuccessToast"));
  }, [showToast, t]);

  const onEditSuccess = useCallback(() => {
    setUser(undefined);

    showToast(t("editSuccessToast"));
  }, [showToast, t]);

  const removeStudent = useRemoveClassroomUser({
    onSuccess: onRemoveSuccess,
  });
  const editStudent = useMutateClassroomStudent({
    onSuccess: onEditSuccess,
  });

  useErrorToast(removeStudent.error);
  useErrorToast(editStudent.error);

  const handleEditIntent = useCallback(
    (student: IUser) => {
      setUser(student);
      trackEvent(
        AnalyticsEvent.TeacherDashboard_ClassroomDetail_StudentsTab_EditStudentIntent,
        {
          classroomId: classroom_id,
          studentId: student.id,
        }
      );
    },
    [trackEvent, classroom_id]
  );

  const handleClose = useCallback(() => {
    setUser(undefined);
  }, []);

  const handleSave = useCallback(
    (student: IUser, gradeLevelId: string) => {
      editStudent.mutate({
        classroom_id,
        user_id: student.id,
        grade_level_id: gradeLevelId || undefined,
        student_nickname: student.student_nickname?.trim() || undefined,
      });

      trackEvent(
        AnalyticsEvent.TeacherDashboard_ClassroomDetail_StudentsTab_EditStudentConfirm,
        {
          classroomId: classroom_id,
          studentId: student.id,
        }
      );
    },
    [editStudent, classroom_id, trackEvent]
  );

  const handleRemove = useCallback(
    (student: IUser) => {
      removeStudent.mutate({
        classroomId: classroom_id,
        email: student.email,
      });

      trackEvent(
        AnalyticsEvent.TeacherDashboard_ClassroomDetail_StudentsTab_RemoveStudentConfirm,
        {
          classroomId: classroom_id,
          studentId: student.id,
        }
      );
    },
    [classroom_id, removeStudent, trackEvent]
  );

  const editModal = useMemo(() => {
    return (
      <EditStudentDetailsModal
        isOpen={!!user}
        isLoading={editStudent.isLoading}
        removing={removeStudent.isLoading}
        gradeLevels={gradeLevels}
        handleClose={handleClose}
        handleSave={handleSave}
        handleRemoveStudent={handleRemove}
        student={user}
        defaultGradeLevelId={user?.student_grade_level_id || ""}
      />
    );
  }, [
    gradeLevels,
    handleClose,
    handleSave,
    handleRemove,
    user,
    removeStudent.isLoading,
    editStudent.isLoading,
  ]);

  return useMemo(() => {
    return {
      handleEditIntent,
      editModal,
    };
  }, [handleEditIntent, editModal]);
};
