import { useMount } from "react-use";

import { localStoreRecentClassroomIdsKeyName } from "links/lib/constants";

interface IArgs {
  classroomId: string;
}

export const useStoreRecentClassroomIds = ({ classroomId }: IArgs): void => {
  useMount(() => {
    let existingRecentClassroomIds = new Set();
    try {
      existingRecentClassroomIds = new Set(
        JSON.parse(
          localStorage.getItem(localStoreRecentClassroomIdsKeyName) || ""
        )
      );
    } catch (ignored) {
      // Do nothing
    }

    // Overwrite existing entry onto the end
    if (existingRecentClassroomIds.has(classroomId)) {
      existingRecentClassroomIds.delete(classroomId);
    }
    existingRecentClassroomIds.add(classroomId);
    localStorage.setItem(
      localStoreRecentClassroomIdsKeyName,
      JSON.stringify(Array.from(existingRecentClassroomIds).slice(-5))
    );
  });
};
