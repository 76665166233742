import { Center, Container, Spinner } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useMount } from "react-use";

import { getPracticeSetUrl } from "adminComponents/molecules/LibraryPracticeSetCard";
import { pxToRem } from "adminComponents/utils";
import { useErrorToast } from "adminComponents/utils/toast";
import { config, localStorePostSessionReturnPath } from "links/lib/constants";
import { useAuth } from "links/lib/features/auth";
import { useInitStudentAuth } from "links/lib/features/auth/useInitAuth";
import { useFetchPracticeSet } from "links/lib/features/practiceSets";
import { useMutateSession } from "links/lib/features/sessions";
import { SessionType } from "links/lib/types";

const PracticeSetPreviewSession: React.FC = () => {
  const { authUser } = useAuth();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "practiceSetPreviewSessionContainer",
  });
  const fetchPracticeSet = useFetchPracticeSet({ id: id });

  const mutateSession = useMutateSession({});
  useErrorToast(mutateSession.error, t("errorCreatingPracticeSession"));

  const initStudentAuth = useInitStudentAuth();

  useMount(() => {
    initStudentAuth.then(() => {
      mutateSession.mutate({
        practiceSetId: id,
        classroomId: "0",
        generateQuestionsFromTerms: true,
        allowStudentNames: true,
        secondsPerQuestion: 0,
        startTime: new Date(),
        sessionType: SessionType.TeacherPreview,
      });
    });
  });

  useEffect(() => {
    if (!mutateSession.isSuccess) return;
    if (!fetchPracticeSet.data) return;
    if (!authUser) return;

    const session = mutateSession.data.practice_set_session;

    // If a user presses Back, return them to the set, not the preview
    // workflow.
    window.history.replaceState(
      null,
      window.document.title,
      getPracticeSetUrl(fetchPracticeSet.data.practice_set, authUser)
    );

    sessionStorage.setItem(
      localStorePostSessionReturnPath,
      getPracticeSetUrl(fetchPracticeSet.data.practice_set, authUser)
    );

    window.location.href = `${config.baseUrl}/session/join/${session.join_code}`;
  }, [
    mutateSession.isSuccess,
    mutateSession.data,
    fetchPracticeSet.data,
    authUser,
  ]);

  return (
    <Container variant="contentWrapper" h="100vh" w="full">
      <Center h="full" w="full">
        <Spinner
          color="primary.dark-gray"
          size="xl"
          speed="1.5s"
          thickness={pxToRem(4)}
        />
      </Center>
    </Container>
  );
};

export default PracticeSetPreviewSession;
