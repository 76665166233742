import { Box, useMultiStyleConfig } from "@chakra-ui/react";
import React from "react";

import { Heading } from "adminComponents/atoms/Heading";
import { Text } from "adminComponents/atoms/Text";

interface IProps {
  description: string;
  image: string;
  imagePosition?: "center" | "left";
  title: string;
}

export const LogInModule: React.FC<IProps> = ({
  imagePosition = "left",
  description,
  image,
  title,
  children,
}) => {
  const styles = useMultiStyleConfig("AdminLogInModule", {});

  return (
    <Box __css={styles.outerContainer}>
      <Box
        __css={styles.imageContainer}
        backgroundImage={`url(${image})`}
        backgroundPosition={`top ${imagePosition}`}
      />
      <Box __css={styles.innerContainer}>
        <Box __css={styles.textContainer}>
          <Heading as="h1" variant="adminH3">
            {title}
          </Heading>
          <Text variant="adminP1">{description}</Text>
        </Box>
        <Box __css={styles.buttonsContainer}>{children}</Box>
      </Box>
    </Box>
  );
};
