import { FormControl, SimpleGrid } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { usePrevious, useUnmount } from "react-use";

import { FormErrorMessage } from "adminComponents/atoms/FormErrorMessage";
import { FormLabel } from "adminComponents/atoms/FormLabel";
import {
  EditPracticeSetMediaModalContextProvider,
  useEditPracticeSetMediaModalContext,
} from "adminComponents/contexts/EditPracticeSetMediaModalContext";
import { CreateQuestionFooter } from "adminComponents/molecules/CreateQuestionFooter";
import { useImages } from "adminComponents/molecules/ImageLibrary/utils/useImages";
import { QuestionAddMedia } from "adminComponents/molecules/QuestionAddMedia";
import { QuestionTypeDescription } from "adminComponents/molecules/QuestionTypeDescription";
import { AddMediaModal } from "adminComponents/organisms/AddMediaModal";
import { RichTextEditor } from "adminComponents/organisms/RichTextEditor";
import { isRichTextEmpty } from "adminComponents/organisms/RichTextEditor/util";
import { CreateQuestionFormDataType } from "adminComponents/utils";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { RichValue } from "links/lib/types";
import { generateEmptySlateState } from "links/lib/util";

export interface CreateDrawTabForm {
  prompt: RichValue;
  imageUrl: string;
  imageAltText: string;
  audioUrl: string;
  videoUrl: string;
  backgroundImageUrl: string;
  requiresHigherOrderThinking: boolean;
}

const initialValue: CreateDrawTabForm = {
  prompt: generateEmptySlateState(),
  imageUrl: "",
  imageAltText: "",
  audioUrl: "",
  videoUrl: "",
  backgroundImageUrl: "",
  requiresHigherOrderThinking: false,
};

interface DrawTabProps {
  defaultValue: CreateQuestionFormDataType;
  hasFeedback: boolean;
  canSubmit: boolean;
  isSubmitting: boolean;
  handleSaveAndClose: (form: CreateDrawTabForm) => void;
  handleChangeForm: (isValid: boolean, form?: CreateDrawTabForm) => void;
  handleNextTab: () => void;
  handleDirty?: () => void;
}

export const CreateQuestionDrawTab: React.FC<DrawTabProps> = ({
  defaultValue,
  handleSaveAndClose,
  handleChangeForm,
  handleNextTab,
  hasFeedback,
  canSubmit,
  isSubmitting,
  handleDirty,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const mediaModalProps = useEditPracticeSetMediaModalContext();

  const {
    handleSubmit,
    setValue,
    watch,
    control,
    getValues,
    formState: { errors, isValid, touchedFields, isDirty },
  } = useForm<CreateDrawTabForm>({
    defaultValues: defaultValue.draw ?? initialValue,
    mode: "onChange",
  });

  const [
    audioUrl,
    imageAltText,
    imageUrl,
    videoUrl,
    requiresHigherOrderThinking,
    backgroundImageUrl,
  ] = watch([
    "audioUrl",
    "imageAltText",
    "imageUrl",
    "videoUrl",
    "requiresHigherOrderThinking",
    "backgroundImageUrl",
  ]);

  useEffect(() => {
    if (isDirty && handleDirty) handleDirty();
  }, [isDirty, handleDirty]);

  useUnmount(() => {
    handleChangeForm(isValid, getValues());
  });

  const prevIsValid = usePrevious(isValid);
  useEffect(() => {
    if (prevIsValid !== isValid) {
      handleChangeForm(isValid);
    }
  }, [isValid, handleChangeForm, prevIsValid]);

  const _handleSubmit = (data: CreateDrawTabForm) => {
    handleSaveAndClose(data);
  };

  const handleChangeRequiresHigherOrderThinking = (checked: boolean) => {
    setValue("requiresHigherOrderThinking", checked);
  };

  const _handleNextTab = async () => {
    handleNextTab();
  };

  const handleInsertMediaFormWrapper = (
    imageUrl: string,
    imageAltText: string,
    audioUrl?: string,
    videoUrl?: string
  ) => {
    setValue("imageUrl", imageUrl);
    setValue("imageAltText", imageAltText);
    setValue("audioUrl", audioUrl ?? "");
    setValue("videoUrl", videoUrl ?? "");
    mediaModalProps.handleInsertMedia(
      imageUrl,
      imageAltText,
      audioUrl,
      videoUrl
    );
  };

  const handleRemoveAudioFormWrapper = () => {
    setValue("audioUrl", "");
    mediaModalProps.handleRemoveAudio();
  };

  const handleRemoveMedia = () => {
    setValue("imageUrl", "");
    setValue("imageAltText", "");
    setValue("audioUrl", "");
    setValue("videoUrl", "");
    mediaModalProps.handleRemoveImage();
  };

  const handleSetBackgroundImageUrl = (backgroundImageUrl: string) => {
    setValue("backgroundImageUrl", backgroundImageUrl);
  };

  return (
    <form onSubmit={handleSubmit(_handleSubmit)}>
      <SimpleGrid
        py={pxToRem(40)}
        px={[
          "admin.flyout.mobileXPadding",
          null,
          "admin.flyout.desktopXPadding",
        ]}
        gap={pxToRem(40)}
      >
        <QuestionTypeDescription
          icon="chat_bubble_outlined"
          iconColor="utility.badge-blue"
          title={t("createDrawFlyout.drawQuestionDropdownTitle")}
          subTitle={t("createDrawFlyout.drawQuestionDropdownSubTitle")}
        />
        <FormControl
          isRequired
          variant="adminFormControl"
          isInvalid={!!touchedFields.prompt && !!errors.prompt}
          minWidth={0}
        >
          <FormLabel>{t("createQuestion.labelPrompt")}</FormLabel>
          <Controller
            control={control}
            name="prompt"
            rules={{
              validate: {
                isNotEmpty: (v) =>
                  !isRichTextEmpty(v) ||
                  (t("createQuestion.errPromptRequired") as string),
              },
            }}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { isTouched, error },
            }) => (
              <RichTextEditor
                aria-label={t("createQuestion.placeholderPrompt")}
                ref={ref}
                name={name}
                handleChangeRaw={onChange}
                handleBlur={onBlur}
                value={value}
                placeholder={t("createQuestion.placeholderPrompt")}
                hasError={isTouched && !!error}
              />
            )}
          />

          <FormErrorMessage>{errors.prompt?.message}</FormErrorMessage>
        </FormControl>
        <QuestionAddMedia
          handleAddMedia={mediaModalProps.handleOpen}
          handleRemoveMedia={handleRemoveMedia}
          imageAdded={imageUrl}
          imageAddedAltText={imageAltText}
          audioAdded={audioUrl}
          videoAdded={videoUrl}
        />
        <EditPracticeSetMediaModalContextProvider>
          <AddBackgroundImageWrapper
            backgroundImageUrl={backgroundImageUrl}
            handleSetBackgroundImageUrl={handleSetBackgroundImageUrl}
          />
        </EditPracticeSetMediaModalContextProvider>
        <CreateQuestionFooter
          canSubmit={canSubmit}
          isSubmitting={isSubmitting}
          requiresHigherOrderThinking={requiresHigherOrderThinking}
          handleChangeRequireHigherOrderThinking={
            handleChangeRequiresHigherOrderThinking
          }
          handleNextTab={_handleNextTab}
          handleSaveAndClose={handleSubmit(_handleSubmit)}
          nextSectionTextCopies={{
            nextSectionCta: hasFeedback
              ? t("createQuestion.editFeedback")
              : t("createQuestion.addFeedback"),
            nextSectionTitle: t("createQuestion.feedback"),
            nextSectionDescription: t("createQuestion.feedbackDescription"),
            nextSectionIcon: hasFeedback ? "edit_outlined" : "add",
          }}
        />
        <AddMediaModal
          {...mediaModalProps}
          audioUrlToAdd={audioUrl}
          imageUrlToAdd={imageUrl}
          imageAltText={imageAltText}
          videoUrlToAdd={videoUrl}
          handleInsertMedia={handleInsertMediaFormWrapper}
          handleRemoveImage={handleRemoveMedia}
          handleRemoveAudio={handleRemoveAudioFormWrapper}
        />
      </SimpleGrid>
    </form>
  );
};

interface AddBackgroundImageWrapperProps {
  backgroundImageUrl: string;
  handleSetBackgroundImageUrl: (backgroundImageUrl: string) => void;
}

const AddBackgroundImageWrapper: React.FC<AddBackgroundImageWrapperProps> = ({
  backgroundImageUrl,
  handleSetBackgroundImageUrl,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const mediaModalProps = useEditPracticeSetMediaModalContext();
  const [imageUrl, setImageUrl] = useState<string>(backgroundImageUrl);

  const handleInsertImage = (
    imageUrl: string,
    imageAltText: string,
    audioUrl?: string,
    videoUrl?: string
  ) => {
    setImageUrl(imageUrl);
    handleSetBackgroundImageUrl(imageUrl);
    mediaModalProps.handleInsertMedia(
      imageUrl,
      imageAltText,
      audioUrl,
      videoUrl
    );
  };

  const handleRemoveImage = () => {
    setImageUrl("");
    handleSetBackgroundImageUrl("");
    mediaModalProps.handleRemoveImage();
  };

  const presetImages = useImages();

  return (
    <>
      <QuestionAddMedia
        handleAddMedia={mediaModalProps.handleOpen}
        handleRemoveMedia={handleRemoveImage}
        imageAdded={imageUrl}
        label={t("createQuestion.drawingBackground")}
        instructions={t("createQuestion.drawingBackgroundInstructions")}
        buttonLabel={t("createQuestion.drawingBackgroundButtonLabel")}
      />

      <AddMediaModal
        {...mediaModalProps}
        imageUrlToAdd={imageUrl}
        handleInsertMedia={handleInsertImage}
        handleRemoveImage={handleRemoveImage}
        imageOnly
        presetImages={presetImages}
      />
    </>
  );
};
