import { pxToRem } from "adminComponents/utils";

export const AdminBanner = {
  baseStyle: {
    banner: {
      paddingX: pxToRem(24),
      paddingY: pxToRem(19),
      borderRadius: pxToRem(8),
    },
  },
  variants: {
    adminDismissibleBanner: {
      banner: {
        justifyContent: "space-between",
        alignItems: ["flex-start", null, "center"],
      },
    },
  },
};
