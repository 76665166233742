import { PracticeSessionItemVariantType } from "links/lib/types";
import { GameChoiceType } from "sessionComponents/types";

export const getGameChoiceType = (
  variant: PracticeSessionItemVariantType
): GameChoiceType | undefined => {
  switch (variant) {
    case PracticeSessionItemVariantType.CoopChecks:
      return GameChoiceType.MultipleSelect;
    case PracticeSessionItemVariantType.CoopRadios:
      return GameChoiceType.Radio;
    default:
      return undefined;
  }
};
