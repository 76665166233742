import { VStack } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { EmptyCard } from "adminComponents/molecules/EmptyCard";
import { StandardsCard } from "adminComponents/molecules/StandardsCard";
import { pxToRem } from "adminComponents/utils";
import { IUseFetchClassroomAverageStandardAccuraciesResult } from "links/lib/features/classrooms/useFetchAverageStandardAccuracies";
import { IStandard } from "links/lib/types";

export interface IMyClassAccuracyTabProps {
  standards: IStandard[];
  standardAccuracies: IUseFetchClassroomAverageStandardAccuraciesResult;
}

export const MyClassAccuracyTab: React.FC<IMyClassAccuracyTabProps> = ({
  standards,
  standardAccuracies,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  const standardCards = useMemo(() => {
    return standardAccuracies.average_standard_accuracies
      .map((standardAccuracy) => {
        const standard = standards.find(
          (standard) => standard.id === standardAccuracy.standard_id
        );
        if (!standard) return null;

        return (
          <StandardsCard
            key={`standard-${standard.id}`}
            standard={standard}
            accuracy={standardAccuracy.average_accuracy * 100}
            accuracyType="personal"
          />
        );
      })
      .filter((a) => !!a);
  }, [standards, standardAccuracies]);

  return (
    <VStack my={pxToRem(32)}>
      {standardCards.length ? (
        standardCards
      ) : (
        <EmptyCard title={t("myClassesScreen.emptyStandards")} />
      )}
    </VStack>
  );
};
