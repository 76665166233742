import { Show } from "@chakra-ui/react";
import React from "react";

import { Heading } from "../Heading";

export interface IResponsiveHeadingProps {
  variant:
    | "adminH1"
    | "adminH2"
    | "adminH3"
    | "adminH4"
    | "adminH5"
    | "adminH5Bold"
    | "adminH6"
    | "adminH6Bold"
    | "adminH7"
    | "adminH7Bold";
  sizeThreshold: "xs" | "sm" | "md" | "lg" | "xl";
  smallText: string;
  largeText: string;
  as: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p";
}

export const ResponsiveHeading: React.FC<IResponsiveHeadingProps> = ({
  sizeThreshold,
  smallText,
  largeText,
  variant,
  as,
}) => {
  return (
    <Heading variant={variant} textAlign="center" as={as}>
      <Show above={sizeThreshold}>{largeText}</Show>
      <Show below={sizeThreshold}>{smallText}</Show>
    </Heading>
  );
};
