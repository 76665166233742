import {
  Box,
  Flex,
  Image,
  useMultiStyleConfig,
  usePrefersReducedMotion,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Heading } from "adminComponents/atoms/Heading";
import { Modal } from "adminComponents/atoms/Modal";
import { Text } from "adminComponents/atoms/Text";
import { Carousel } from "adminComponents/molecules/Carousel";
import {
  VideoPlayer,
  VideoPlayerProps,
} from "adminComponents/molecules/VideoPlayer";

interface ModalImage {
  src: string;
  fallback?: string;
  altText: string;
}
export interface ModalStep {
  stepId: string;
  title: string;
  subTitle?: string;
  description?: string | JSX.Element;
  image?: ModalImage;
  video?: VideoPlayerProps;
  secondaryDescription?: string | JSX.Element;
}
interface MultiStepModalProps {
  isOpen: boolean;
  steps: ModalStep[];
  finishLabel?: string;
  handleClose: () => void;
  handleFinish: () => void;
  hideBackButtonOnBeginning?: boolean;
  modalVariant?: string;
  size?: string;
}

export const MultiStepModal: React.FC<MultiStepModalProps> = ({
  isOpen,
  steps,
  handleClose,
  handleFinish,
  finishLabel,
  hideBackButtonOnBeginning,
  modalVariant = "adminModalCompactMobile",
  size,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const prefersReducedMotion = usePrefersReducedMotion();

  const [isEnd, setIsEnd] = useState(false);
  const [isBeginning, setIsBeginning] = useState(false);
  const styles = useMultiStyleConfig("AdminMultiStepModal", { isEnd });

  return (
    <Modal
      variant={modalVariant}
      isOpen={isOpen}
      onClose={handleClose}
      showBackButton={false}
      size={size}
    >
      <Box sx={styles.container}>
        <Carousel
          showDots
          slidesPerView={1}
          nextElSelector=".admin-MultiStepModal-next"
          previousElSelector=".admin-MultiStepModal-back"
          stateUpdatedHandler={({ isEnd, isBeginning }) => {
            setIsEnd(isEnd);
            setIsBeginning(isBeginning);
          }}
        >
          {steps.map((step) => {
            const {
              stepId,
              title,
              subTitle,
              description,
              image,
              video,
              secondaryDescription,
            } = step;
            return (
              <Flex
                key={`modal_step_${stepId}`}
                flexDirection={"column"}
                width="100%"
              >
                <Heading sx={styles.title} variant="adminH3" as="h2">
                  {title}
                </Heading>

                {subTitle && (
                  <Heading variant="adminH6" as="h3" sx={styles.subtitle}>
                    {subTitle}
                  </Heading>
                )}
                {description && (
                  <Text variant="adminP1" sx={styles.description}>
                    {description}
                  </Text>
                )}

                {(image || video) && (
                  <Box sx={styles.body}>
                    {image && (
                      <Image
                        src={
                          !prefersReducedMotion || !image?.fallback
                            ? image?.src
                            : image?.fallback
                        }
                        alt={image?.altText}
                      />
                    )}

                    {video && <VideoPlayer {...video} />}
                  </Box>
                )}
                {secondaryDescription}
              </Flex>
            );
          })}
        </Carousel>
        <Flex sx={styles.actions}>
          <Button
            sx={styles.button}
            className="admin-MultiStepModal-back"
            variant="adminButtonOutlined"
            display={
              hideBackButtonOnBeginning && isBeginning ? "none" : "inline-flex"
            }
          >
            {t("common.back")}
          </Button>
          {
            <Button
              sx={styles.next}
              className="admin-MultiStepModal-next"
              variant="adminButtonFilled"
            >
              {t("common.next")}
            </Button>
          }
          {
            <Button
              sx={styles.finish}
              variant="adminButtonFilled"
              onClick={handleFinish}
            >
              {finishLabel || t("common.finish")}
            </Button>
          }
        </Flex>
      </Box>
    </Modal>
  );
};
