import { Box } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useMount } from "react-use";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { useSessionActions } from "links/lib/contexts/sessionActions";
import { useSessionGameState } from "links/lib/contexts/sessionGameState";
import { useSessionRoundState } from "links/lib/contexts/sessionRoundState";
import { BigBoardGameState } from "links/lib/types";
import { NoWinnersMessage } from "sessionComponents/atoms/NoWinnersMessage";
import { Stage } from "sessionComponents/atoms/Stage";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { useStudent } from "sessionComponents/contexts/student";
import { ConfirmButton } from "sessionComponents/molecules/ConfirmButton";
import { StudentReactionsPicker } from "sessionComponents/molecules/StudentReactionsPicker";
import { WinningTeamCarousel } from "sessionComponents/organisms/WinningTeamCarousel";
import { useDetectOrientation } from "sessionComponents/theme/hooks/useDetectOrientation";
import { TeacherSessionSoundEffect } from "sessionComponents/types";
import { getAssetColorScheme } from "sessionComponents/utils/getAssetColorScheme";
import { getFormattedGroupName } from "sessionComponents/utils/getFormattedGroupName";
import { useSessionAudio } from "sharedComponents/contexts/sessionAudio";
import { useSafeConnectedUserArray } from "sharedComponents/hooks/useSafeConnectedUserArray";
import { useSafeGroupsArray } from "sharedComponents/hooks/useSafeGroupsArray";

export interface IPrizeRoundAwardsProps {
  isTeacher: boolean;
}

/**
 * The PrizeRoundAwards component is responsible for showing the team(s) that earned
 * points from a prize round. It cycles through teams every 5 seconds.
 */
export const PrizeRoundAwards: React.FC<IPrizeRoundAwardsProps> = ({
  isTeacher,
}) => {
  const { t } = useTranslation("session", {
    keyPrefix: "prizeRoundAwards",
    useSuspense: false,
  });

  const { match: currentBreakpoints } = useBreakpoints();
  const { isPortrait } = useDetectOrientation();

  const connectedUsers = useSafeConnectedUserArray();
  const student = useStudent();
  const groups = useSafeGroupsArray();
  const gameState = useSessionGameState();
  // Prize Round Awards are only applicable in the big board
  const bigBoardGameState = gameState as BigBoardGameState;
  const roundState = useSessionRoundState();
  const { initiateLeaderBoard, initiatePodium } = useSessionActions();
  const { play: playAudio, setBackgroundAudio } = useSessionAudio();

  const winners = useMemo(() => {
    const awardedGroups = groups
      .filter((g) => g.round_awarded_points)
      .sort((a, b) => b.round_awarded_points - a.round_awarded_points);
    return awardedGroups.map((g) => ({
      key: g.id,
      name: getFormattedGroupName(g),
      colorScheme: getAssetColorScheme(g.color_scheme),
      score: g.round_awarded_points,
      members: connectedUsers.filter((u) => u.session_group_id === g.id),
    }));
  }, [groups, connectedUsers]);

  useMount(() => {
    if (!isTeacher) return;
    setBackgroundAudio();
    playAudio(
      winners.length
        ? TeacherSessionSoundEffect.BBWin
        : TeacherSessionSoundEffect.BBLose
    );
  });

  const footer = isTeacher ? (
    <Box
      w="full"
      bottom={0}
      right={0}
      position="absolute"
      display="flex"
      justifyContent="center"
      alignItems="center"
      height={pxToRem(currentBreakpoints.headerHeight)}
      p={pxToRem(currentBreakpoints.padding)}
      zIndex={1}
    >
      <ConfirmButton
        h={pxToRem(currentBreakpoints.buttonHeight)}
        w="100%"
        variant="buttonFilled"
        confirmedWhenChanged={[]}
        handleConfirmClick={() =>
          roundState.round_number === bigBoardGameState.final_round_number
            ? initiatePodium()
            : initiateLeaderBoard()
        }
        maxWidth={isPortrait ? undefined : "50vw"}
        fontSize={pxToRem(currentBreakpoints.fontSize)}
      >
        {t("moveOnButtonText")}
      </ConfirmButton>
    </Box>
  ) : (
    <StudentReactionsPicker />
  );

  if (!winners.length) {
    return (
      <Stage showCurtainOnly>
        <NoWinnersMessage />
        {footer}
      </Stage>
    );
  }

  return (
    <WinningTeamCarousel
      showNameFirst={false}
      showPlus
      showTrophy={false}
      footer={footer}
      userId={student.id}
      winningTeams={winners}
    />
  );
};
