import { Alert, Box, VStack } from "@chakra-ui/react";
import { SkeletonData } from "@esotericsoftware/spine-core";
import React, { useState } from "react";

import { useAuth } from "links/lib/features/auth";

import { Step1 } from "./components/Step1";
import { Step2 } from "./components/Step2";
import { Step3 } from "./components/Step3";
import { AvatarItemBuilderData } from "./types";

interface IAvatarItemBuilderProps {
  itemBuilderData: AvatarItemBuilderData;
}

export const AvatarItemBuilder: React.FC<IAvatarItemBuilderProps> = ({
  itemBuilderData,
}: IAvatarItemBuilderProps) => {
  const { authUser } = useAuth();

  const [error, setError] = useState("");
  const [images, setImages] = useState<{ [key: string]: string }>({});
  const [imagesBase64, setImagesBase64] = useState<{ [key: string]: string }>(
    {}
  );
  const [selectedSkinName, setSelectedSkinName] = useState("default");
  const [selectedSlots, setSelectedSlots] = useState<{
    [key: string]: boolean;
  }>({});
  const [seatedSpineConfiguration, setSeatedSpineConfiguration] =
    useState<SkeletonData | null>(null);
  const [standingSpineConfiguration, setStandingSpineConfiguration] =
    useState<SkeletonData | null>(null);
  const [previewSVG, setPreviewSVG] = useState<string>("");

  const resetState = () => {
    setError("");
    setImages({});
    setImagesBase64({});
    setPreviewSVG("");
    setSelectedSlots({});
    setSeatedSpineConfiguration(null);
    setStandingSpineConfiguration(null);
  };

  const onSelectSlot = (slotName: string) => {
    if (!images[slotName]) return;

    setPreviewSVG("");

    const newSelectedSlots = { ...selectedSlots };
    if (selectedSlots[slotName]) {
      delete newSelectedSlots[slotName];
      setSelectedSlots({
        ...newSelectedSlots,
      });
    } else {
      setSelectedSlots({
        ...selectedSlots,
        [slotName]: true,
      });
    }
  };

  const selectedSkin = seatedSpineConfiguration?.skins.find(
    (skin) => skin.name === selectedSkinName
  );

  const skinNames =
    seatedSpineConfiguration?.skins.map((skin) => skin.name) || [];

  const attachmentNames =
    (selectedSkin &&
      selectedSkin.attachments &&
      Object.values(selectedSkin.attachments).flatMap((attachment) =>
        Object.keys(attachment)
      )) ||
    [];

  return (
    <Box minH="100vh" bgColor="gray.100">
      {error && <Alert status="error">{error}</Alert>}

      {authUser && itemBuilderData && (
        <VStack p="50px" bgColor="gray.50" spacing="24px" justifyContent="left">
          <Step1
            resetState={resetState}
            setError={setError}
            setImages={setImages}
            setImagesBase64={setImagesBase64}
            setSeatedSpineConfiguration={setSeatedSpineConfiguration}
            setStandingSpineConfiguration={setStandingSpineConfiguration}
          />

          {seatedSpineConfiguration && (
            <Step2
              attachmentNames={attachmentNames}
              imagesBase64={imagesBase64}
              selectedSlots={selectedSlots}
              skinNames={skinNames}
              setSelectedSkinName={setSelectedSkinName}
              onSelectSlot={onSelectSlot}
            />
          )}

          {seatedSpineConfiguration &&
            standingSpineConfiguration &&
            Object.keys(selectedSlots).length > 0 && (
              <Step3
                images={images}
                itemBuilderData={itemBuilderData}
                previewSVG={previewSVG}
                selectedSkinName={selectedSkinName}
                selectedSlots={selectedSlots}
                seatedSpineConfiguration={seatedSpineConfiguration}
                standingSpineConfiguration={standingSpineConfiguration}
                resetState={resetState}
                setError={setError}
                setPreviewSVG={setPreviewSVG}
              />
            )}
        </VStack>
      )}
    </Box>
  );
};
