import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { ICustomSessionGroup } from "../../types";

export interface IFetchSessionGroupsArgs {
  classroom_id?: string;
}

export interface IFetchSessionGroupsResponse {
  session_groups: Array<ICustomSessionGroup>;
}

export default function useFetchCustomSessionGroups(
  params: IFetchSessionGroupsArgs
): UseQueryResult<IFetchSessionGroupsResponse, unknown> {
  const axios = useAxios();

  const { classroom_id } = params;

  const query = useQuery<IFetchSessionGroupsResponse>(
    ["customSessionGroups", { classroom_id }],
    async (): Promise<IFetchSessionGroupsResponse> => {
      return await axios
        .get(`/v1/groups/${classroom_id}/session-groups`)
        .then((r) => {
          return r.data as IFetchSessionGroupsResponse;
        });
    },
    { enabled: !!classroom_id, refetchOnWindowFocus: false }
  );

  return query;
}
