import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { UserRecurringNotificationSettings } from "../../types";

export interface IMutateUserRecurringNotificationSettingsParams {
  user_id: string;
  settings: UserRecurringNotificationSettings;
}

export interface IMutateUserRecurringNotificationSettingsResponse {
  user_id: string;
  settings: UserRecurringNotificationSettings;
}

/**
 * Updates recurring notification settings for a user
 * @returns
 */
export default function useMutateUserRecurringNotificationSettings(): UseMutationResult<
  IMutateUserRecurringNotificationSettingsResponse,
  unknown,
  IMutateUserRecurringNotificationSettingsParams
> {
  const axios = useAxios();
  const queryClient = useQueryClient();

  const MutateUserRecurringNotificationSettings = async (
    args: IMutateUserRecurringNotificationSettingsParams
  ) => {
    const { user_id, settings } = args;

    const response = await axios({
      method: "put",
      url: `/v1/users/${user_id}/recurring-notifications/settings`,
      data: {
        user_id,
        settings,
      },
    }).then((r) => r.data as IMutateUserRecurringNotificationSettingsResponse);

    queryClient.setQueryData(["user-notification-settings", { user_id }], {
      settings: response.settings,
    });

    return response;
  };

  const mutation = useMutation(MutateUserRecurringNotificationSettings);

  return mutation;
}
