import {
  AbsoluteCenter,
  Box,
  HStack,
  Hide,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Show,
  useBreakpointValue,
  useDisclosure,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

import { AssignmentsIcon } from "adminComponents/atoms/AssignmentsIcon";
import { Heading } from "adminComponents/atoms/Heading";
import { IconButton } from "adminComponents/atoms/IconButton";
import { Logo } from "adminComponents/atoms/Logo";
import { XPBoostIcon } from "adminComponents/atoms/XPBoostIcon";
import { Link } from "adminComponents/molecules/NavigationHeader/components/Link";
import {
  StudentNavLink,
  StudentProfileMenu,
} from "adminComponents/organisms/StudentProfileMenu";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { IUser, UserXPMultiplier } from "links/lib/types";

import { StudentNavigationDrawer } from "./components/StudentNavigationDrawer";
import { UserIconButton } from "./components/UserIconButton";

export interface IProps {
  accountLinks: StudentNavLink[];
  assignmentCount?: number;
  isLoading: boolean;
  logoLink: StudentNavLink;
  daysLeft?: number;
  navigationLinks: StudentNavLink[];
  season: string;
  user?: IUser;
  variant?: string;
  weekStreak?: number;
  currentWeekdayCount?: number;
  activeNavigationPath?: string;
  pageTitle?: string;
  userXPMultiplier?: UserXPMultiplier;
  handleAssignmentIconClick: () => void;
  handleStudentIconClick: () => void;
  handleEditAvatar: () => void;
}

export const StudentNavigationHeader: React.FC<IProps> = ({
  accountLinks = [],
  assignmentCount,
  daysLeft,
  isLoading,
  logoLink,
  navigationLinks = [],
  season,
  user,
  variant = "adminStudentNavigationHeader",
  weekStreak,
  currentWeekdayCount,
  activeNavigationPath,
  pageTitle,
  userXPMultiplier,
  handleStudentIconClick,
  handleAssignmentIconClick,
  handleEditAvatar,
  ...props
}) => {
  const [isNavTransparent, toggleIsNavTransparent] = useState(true);
  const navRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation("admin", { useSuspense: false });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const styles = useMultiStyleConfig("AdminStudentNavigationHeader", {
    isNavTransparent,
    variant,
  });
  const isMobile = useBreakpointValue({ base: true, md: false });
  const links = !isMobile ? accountLinks : navigationLinks.concat(accountLinks);
  const PopoverTriggerComponent = isLoading ? Fragment : PopoverTrigger;

  useEffect(() => {
    if (navRef.current) {
      const callback = (event: Event) => {
        const { detail } = event as CustomEvent;
        if (detail.shouldBeTransparent && detail.effectedElement === "topNav") {
          toggleIsNavTransparent(true);
        } else if (detail.effectedElement === "topNav") {
          toggleIsNavTransparent(false);
        }
      };

      window.addEventListener("nav-intersect", callback);

      return () => window.removeEventListener("nav-intersect", callback);
    }
  }, []);

  useEffect(() => {
    onClose();
  }, [isMobile, onClose]);

  const mdBreakpointMediaQuery = "(max-width: 767px)";

  return (
    <Box __css={styles.container}>
      <Box w="full" role="banner" __css={styles.banner} ref={navRef} {...props}>
        <Show breakpoint={mdBreakpointMediaQuery}>
          <IconButton
            sx={styles.menuButton}
            ariaLabel={t("common.openMenu")}
            colorSchemeHover="primary.golden-hover"
            icon="menu"
            shape="circle"
            variant="adminNavIconButton"
            onClick={onOpen}
            disabled={isLoading}
          />
        </Show>
        {isMobile && pageTitle ? (
          <Heading variant="adminH6Bold" as="p">
            {pageTitle}
          </Heading>
        ) : (
          <HStack sx={styles.logo}>
            <Logo width={pxToRem(154)} navigationLink={logoLink} />
          </HStack>
        )}
        <Hide breakpoint={mdBreakpointMediaQuery}>
          <AbsoluteCenter as={HStack} spacing={pxToRem(28)}>
            {navigationLinks.map(({ label, to }) => (
              <Link
                as={RouterLink}
                key={label}
                to={to}
                variant="adminNavLink"
                aria-current={activeNavigationPath === to}
              >
                {label}
              </Link>
            ))}
          </AbsoluteCenter>
        </Hide>
        <Box __css={styles.nav}>
          <HStack spacing={pxToRem(32)}>
            {!!userXPMultiplier && (
              <XPBoostIcon
                userXPMultiplier={userXPMultiplier}
                ariaLabel={t("studentNavigationHeader.xpBoostIconAriaLabel")}
              />
            )}
            <AssignmentsIcon
              ariaLabel={t("studentNavigationHeader.assignmentsIconAriaLabel")}
              assignmentCount={assignmentCount}
              onClick={handleAssignmentIconClick}
              disabled={isLoading}
            />
            <Hide breakpoint={mdBreakpointMediaQuery}>
              <Popover variant="adminProfileMenu" placement="bottom-end">
                <PopoverTriggerComponent>
                  <Box>
                    <UserIconButton
                      user={user}
                      handleClick={handleStudentIconClick}
                      disabled={isLoading}
                    />
                  </Box>
                </PopoverTriggerComponent>
                <PopoverContent>
                  <StudentProfileMenu
                    daysLeft={daysLeft}
                    links={links}
                    season={season}
                    user={user}
                    weekStreak={weekStreak}
                    currentWeekdayCount={currentWeekdayCount}
                    handleEditAvatar={handleEditAvatar}
                    handleCloseDrawer={onClose}
                  />
                </PopoverContent>
              </Popover>
            </Hide>
          </HStack>
        </Box>
        <StudentNavigationDrawer isOpen={isOpen} onClose={onClose} autoFocus>
          <StudentProfileMenu
            daysLeft={daysLeft}
            links={links}
            season={season}
            user={user}
            weekStreak={weekStreak}
            currentWeekdayCount={currentWeekdayCount}
            handleEditAvatar={handleEditAvatar}
            handleCloseDrawer={onClose}
          />
        </StudentNavigationDrawer>
      </Box>
    </Box>
  );
};
