import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSessionStorage } from "react-use";

import { GoogleClassroomAuthorizeModal } from "adminComponents/organisms/GoogleClassroomAuthorizeModal";
import { GoogleClassroomImportModal } from "adminComponents/organisms/GoogleClassroomImportModal";
import { GoogleClassroomLinkConfirmModal } from "adminComponents/organisms/GoogleClassroomLinkConfirmModal";
import { StudentEnrollmentDifferencesModal } from "adminComponents/organisms/StudentEnrollmentDifferencesModal";
import { getExcludedStudents } from "adminComponents/utils/getExcludedStudents";
import { useErrorToast, useShowToast } from "adminComponents/utils/toast";
import { afterSignInPathSessionKey } from "lib/constants";
import { useAnalytics } from "lib/contexts/analytics";
import { useHubSpot } from "lib/hooks/useHubSpot";
import {
  useFetchGoogleClassroomClassrooms,
  useMutateClassroom,
} from "links/lib/features/classrooms";
import {
  AnalyticsEvent,
  GoogleClassroomClassroom,
  IClassroom,
  IUser,
  ThirdParty,
  UserRole,
} from "links/lib/types";
import { sortAlpha } from "links/lib/util";
import useFetchProviderRedirectUrl from "screens/SignIn/useFetchProviderRedirectUrl";

export interface IArgs {
  classroom: IClassroom;
  classroomStudents: IUser[];
}

export interface IResult {
  handleAddIntent: () => void;
  authorizeModal: React.ReactElement;
  classroomSelectModal: React.ReactElement;
  studentEnrollmentDifferencesModal: React.ReactElement;
  confirmLinkModal: React.ReactElement;
}

export const useConnectGClassroom = ({
  classroom,
  classroomStudents,
}: IArgs): IResult => {
  const [isAuthorizeModalOpen, setIsAuthorizeModalOpen] =
    useState<boolean>(false);
  const [isClassroomSelectModalOpen, setIsClassroomSelectModalOpen] =
    useState<boolean>(false);
  const [
    isStudentEnrollmentDifferencesModalOpen,
    setIsStudentEnrollmentDifferencesModalOpen,
  ] = useState<boolean>(false);
  const [isConfirmLinkModalOpen, setIsConfirmLinkModalOpen] =
    useState<boolean>(false);
  const [excludedStudents, setExcludedStudents] = useState<IUser[]>([]);
  const [selectedGClassroom, setSelectedGClassroom] =
    useState<GoogleClassroomClassroom>();

  const showToast = useShowToast();
  const { t } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "classroomDetailContainer.studentsTab.connectToGClassroom",
  });
  const { t: tCommon } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "common",
  });
  const { trackEvent } = useAnalytics();
  const { trackHubSpotEvent } = useHubSpot();

  const [, setSessionRedirectPath] = useSessionStorage(
    afterSignInPathSessionKey,
    "/"
  );

  const handleClassroomMutationSuccess = useCallback(() => {
    showToast(t("connectSuccessToast"));
    setIsClassroomSelectModalOpen(false);

    trackHubSpotEvent(AnalyticsEvent.HubSpot_ImportClassroom, {});
  }, [showToast, t, trackHubSpotEvent]);

  const gclassroomFetch = useFetchGoogleClassroomClassrooms();
  const redirectFetch = useFetchProviderRedirectUrl();
  const groupMutation = useMutateClassroom({
    onSuccess: handleClassroomMutationSuccess,
  });

  useErrorToast(gclassroomFetch.error);
  useErrorToast(redirectFetch.error);
  useErrorToast(groupMutation.error);

  const handleAddIntent = useCallback(() => {
    setIsClassroomSelectModalOpen(true);
  }, []);

  const handleAuthorizeClose = useCallback(() => {
    setIsAuthorizeModalOpen(false);
  }, []);

  const handleGrantAccess = useCallback(() => {
    if (!redirectFetch.data) return;

    trackEvent(
      AnalyticsEvent.TeacherDashboard_ClassroomDetail_StudentsTab_ConnectGClassroomAuthorize,
      {}
    );

    setSessionRedirectPath(window.location.pathname);
    window.location.href = redirectFetch.data.redirectUrl;
  }, [redirectFetch, setSessionRedirectPath, trackEvent]);

  useEffect(() => {
    if (
      gclassroomFetch.data?.requires_authorization &&
      isClassroomSelectModalOpen
    ) {
      setIsClassroomSelectModalOpen(false);
      setIsAuthorizeModalOpen(true);
      redirectFetch.execute({
        provider: "google_classroom",
        role: UserRole.Teacher,
      });
    }
  }, [
    gclassroomFetch.data?.requires_authorization,
    isClassroomSelectModalOpen,
    redirectFetch,
  ]);

  const sortedClassrooms = useMemo(() => {
    return (
      gclassroomFetch.data?.classrooms.sort((a, b) =>
        sortAlpha(a.name, b.name)
      ) || []
    );
  }, [gclassroomFetch.data?.classrooms]);

  const authorizeModal = useMemo(() => {
    return (
      <GoogleClassroomAuthorizeModal
        isOpen={isAuthorizeModalOpen}
        isLoading={redirectFetch.isLoading}
        handleGrantAccess={handleGrantAccess}
        handleClose={handleAuthorizeClose}
      />
    );
  }, [
    isAuthorizeModalOpen,
    handleAuthorizeClose,
    handleGrantAccess,
    redirectFetch.isLoading,
  ]);

  const handleClassroomSelectModalClose = useCallback(() => {
    setIsClassroomSelectModalOpen(false);
  }, []);

  const handlePickGoogleClassroom = useCallback(
    (googleClassroom: GoogleClassroomClassroom) => {
      setSelectedGClassroom(googleClassroom);
      setIsClassroomSelectModalOpen(false);

      const excludedStudents = getExcludedStudents({
        classroomStudents,
        gClassroom: googleClassroom,
      });
      setExcludedStudents(excludedStudents);
      if (excludedStudents.length > 0) {
        setIsStudentEnrollmentDifferencesModalOpen(true);
      } else {
        setIsConfirmLinkModalOpen(true);
      }
    },
    [classroomStudents]
  );

  const classroomSelectModal = useMemo(() => {
    return (
      <GoogleClassroomImportModal
        handleClose={handleClassroomSelectModalClose}
        handlePickGoogleClassroom={handlePickGoogleClassroom}
        isOpen={isClassroomSelectModalOpen}
        isLoading={gclassroomFetch.isLoading}
        googleClassrooms={sortedClassrooms}
        title={t("classroomSelectTitle")}
        confirmText={tCommon("next")}
      />
    );
  }, [
    handleClassroomSelectModalClose,
    handlePickGoogleClassroom,
    isClassroomSelectModalOpen,
    gclassroomFetch.isLoading,
    sortedClassrooms,
    tCommon,
    t,
  ]);

  const handleStudentEnrollmentDifferencesModalClose = useCallback(() => {
    setIsStudentEnrollmentDifferencesModalOpen(false);
  }, []);

  const handleStudentEnrollmentDifferencesModalSubmit = useCallback(() => {
    setIsStudentEnrollmentDifferencesModalOpen(false);
    setIsConfirmLinkModalOpen(true);
  }, []);

  const studentEnrollmentDifferencesModal = useMemo(() => {
    return (
      <StudentEnrollmentDifferencesModal
        classes={[
          {
            excludedStudents,
            giantStepsClassName: classroom.name,
            googleClassroomClassName: selectedGClassroom?.name || "",
          },
        ]}
        handleClose={handleStudentEnrollmentDifferencesModalClose}
        handleSubmit={handleStudentEnrollmentDifferencesModalSubmit}
        isOpen={isStudentEnrollmentDifferencesModalOpen}
      />
    );
  }, [
    isStudentEnrollmentDifferencesModalOpen,
    excludedStudents,
    handleStudentEnrollmentDifferencesModalClose,
    handleStudentEnrollmentDifferencesModalSubmit,
    classroom.name,
    selectedGClassroom?.name,
  ]);

  const handleConfirmLinkModalClose = useCallback(() => {
    setIsConfirmLinkModalOpen(false);
  }, []);

  const handleConfirmLinkModalConfirm = useCallback(() => {
    setIsConfirmLinkModalOpen(false);

    if (!selectedGClassroom) return;

    groupMutation.mutate({
      id: classroom.id,
      name: classroom.name,
      description: classroom.description,
      third_party: ThirdParty.GOOGLE_CLASSROOM,
      third_party_id: selectedGClassroom.id,
      group_grade_levels: classroom.group_grade_levels,
      group_subjects: classroom.group_subjects,
      assignment_notifications_disabled:
        classroom.assignment_notifications_disabled,
    });
  }, [groupMutation, classroom, selectedGClassroom]);

  const confirmLinkModal = useMemo(() => {
    return (
      <GoogleClassroomLinkConfirmModal
        classroom={classroom}
        handleClose={handleConfirmLinkModalClose}
        handleConfirm={handleConfirmLinkModalConfirm}
        isOpen={isConfirmLinkModalOpen}
        googleClassroom={selectedGClassroom}
        cancelText={tCommon("cancel")}
      />
    );
  }, [
    isConfirmLinkModalOpen,
    selectedGClassroom,
    classroom,
    handleConfirmLinkModalClose,
    handleConfirmLinkModalConfirm,
    tCommon,
  ]);

  return useMemo(() => {
    return {
      handleAddIntent,
      authorizeModal,
      classroomSelectModal,
      studentEnrollmentDifferencesModal,
      confirmLinkModal,
    };
  }, [
    authorizeModal,
    handleAddIntent,
    classroomSelectModal,
    studentEnrollmentDifferencesModal,
    confirmLinkModal,
  ]);
};
