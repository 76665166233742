import React, { useEffect, useState } from "react";

const maxZoom = 1;
const minZoom = 0.2;
const wheelScaleFactor = 0.001;
const pinchScaleFactor = 0.005;

const clampZoom = (zoom: number): number => {
  return Math.min(Math.max(zoom, minZoom), maxZoom);
};

export const useCameraZoom = (
  initZoom = 1,
  targetRef: React.MutableRefObject<HTMLDivElement | null>
): number => {
  const [zoom, setZoom] = useState(initZoom);

  // Effect for handling wheel events
  useEffect(() => {
    if (!targetRef.current) return;

    const target = targetRef.current;

    const handleWheel = (e: WheelEvent) => {
      e.preventDefault();
      e.stopPropagation();

      setTimeout(() => {
        setZoom((scale) => clampZoom(scale + e.deltaY * wheelScaleFactor));
      });
    };

    target.addEventListener("wheel", handleWheel);

    return () => {
      target.removeEventListener("wheel", handleWheel);
    };
  }, [targetRef]);

  // Effect for handling pinch events
  useEffect(() => {
    const target = targetRef.current;

    if (!target) return;

    let prevDiff = -1;
    let lastTouches: [Touch, Touch] | undefined = undefined;

    const handleTouchMove = (ev: TouchEvent) => {
      if (ev.touches.length !== 2) return;

      ev.preventDefault();
      ev.stopPropagation();

      const touch1 = ev.touches.item(0);
      const touch2 = ev.touches.item(1);

      if (!touch1 || !touch2) return;

      // ensure the touches are the same as last event before computing new zoom
      if (
        lastTouches?.every(
          (t) =>
            t.identifier === touch1.identifier ||
            t.identifier === touch2.identifier
        )
      ) {
        const curDiff = Math.sqrt(
          Math.pow(touch2.clientX - touch1.clientX, 2) +
            Math.pow(touch2.clientY - touch1.clientY, 2)
        );

        if (prevDiff > 0) {
          const delta = curDiff - prevDiff;

          setTimeout(() => {
            setZoom((scale) => clampZoom(scale - delta * pinchScaleFactor));
          });
        }

        prevDiff = curDiff;
      }

      lastTouches = [touch1, touch2];
    };

    const handleTouchEnd = (ev: TouchEvent) => {
      // clear last touches if one of the touches has ended
      for (let i = 0; i < ev.changedTouches.length; i++) {
        const touch = ev.changedTouches.item(i);

        if (lastTouches?.some((t) => t.identifier === touch?.identifier)) {
          lastTouches = undefined;
          prevDiff = -1;
          return;
        }
      }
    };

    target.addEventListener("touchmove", handleTouchMove);
    target.addEventListener("touchend", handleTouchEnd);

    return () => {
      target.removeEventListener("touchmove", handleTouchMove);
      target.removeEventListener("touchend", handleTouchEnd);
    };
  }, [targetRef]);

  return zoom;
};
