import {
  Box,
  Flex,
  Square,
  Stack,
  Text,
  VStack,
  usePrefersReducedMotion,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { animated, config, useSpring } from "react-spring";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { AvatarAnimations } from "links/lib/constants";
import {
  CoverImageBGPatternType,
  ISessionGroup,
  ISessionUser,
  PracticeSessionItemVariantType,
  RemixBGPatternType,
  SessionGameType,
} from "links/lib/types";
import { InterstitialBackground } from "sessionComponents/atoms/InterstitialBackground";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { IUserAnswer } from "sessionComponents/hooks/useGroupAnswers";
import { useRoundReviewInterstitialFlavorText } from "sessionComponents/hooks/useRoundReviewInterstitialFlavorText";
import { AvatarLayout } from "sessionComponents/molecules/AvatarLayout";
import { useDetectOrientation } from "sessionComponents/theme/hooks/useDetectOrientation";
import { getFormattedGroupName } from "sessionComponents/utils/getFormattedGroupName";

import { QuickPlayStudentRoundReviewInterstitialContent } from "./components/QuickPlayStudentRoundReviewInterstitialContent";
import Blob from "./resource/Blob.svg";

export interface IStudentRoundReviewInterstitialProps {
  groupUsers: ISessionUser[];
  group: ISessionGroup;
  student: ISessionUser;
  groupAnswers: IUserAnswer[];
  variant?: PracticeSessionItemVariantType;
  isClassSession?: boolean;
  pattern?: CoverImageBGPatternType | RemixBGPatternType;
  outerGame?: SessionGameType;
  earnedQuickPlayCarePackage?: boolean;
}

const AnimatedBox = animated(Box);

export const StudentRoundReviewInterstitial: React.FC<
  IStudentRoundReviewInterstitialProps
> = ({
  groupUsers,
  group,
  student,
  groupAnswers,
  variant,
  isClassSession = true,
  pattern,
  outerGame = SessionGameType.TheBigBoard,
  earnedQuickPlayCarePackage = false,
}) => {
  const { match: currentBreakpoints, fontSize2x } = useBreakpoints();
  const { isPortrait, isLandscape } = useDetectOrientation();
  const { t } = useTranslation("session", {
    keyPrefix: "roundReviewInterstitial",
    useSuspense: false,
  });
  const prefersReducedMotion = usePrefersReducedMotion();

  const studentAnswer = useMemo(() => {
    return groupAnswers.find((a) => a.user.id === student.id);
  }, [groupAnswers, student.id]);

  const isDrawItem = variant === PracticeSessionItemVariantType.CoopDraw;

  const { round_awarded_points } = group;

  const pointsSpring = useSpring({
    from: {
      opacity: 0,
      scale: prefersReducedMotion ? 1 : 0,
    },
    to: {
      opacity: 1,
      scale: 1,
    },
    delay: 500,
    config: prefersReducedMotion
      ? config.default
      : !studentAnswer?.isCorrect
      ? config.slow
      : config.wobbly,
  });

  const calculatedIsCorrect = useMemo(() => {
    let studentCorrect = false;
    let groupCorrect = true;

    groupAnswers.forEach((a) => {
      if (a.user.id === student.id && a.isCorrect) studentCorrect = true;
      if (a.user.id !== student.id && !a.isCorrect) groupCorrect = false;
    });

    if (groupUsers.length === 1) groupCorrect = studentCorrect;

    return {
      studentCorrect,
      groupCorrect,
    };
  }, [groupAnswers, student.id, groupUsers]);

  const flavorText = useRoundReviewInterstitialFlavorText({
    outerGame,
    variant,
    groupName: getFormattedGroupName(group),
    studentCorrect: calculatedIsCorrect.studentCorrect,
    groupCorrect: calculatedIsCorrect.groupCorrect,
    groupSize: groupUsers.length,
    isClassSession,
  });

  const mainTextKey = isDrawItem
    ? "drawingMainText"
    : studentAnswer?.isCorrect
    ? "correctMainText"
    : studentAnswer?.isPartiallyCorrect
    ? "partiallyCorrectMainText"
    : "incorrectMainText";

  const mainText = t(mainTextKey);

  const contentHeight = isLandscape ? "100%" : "50%";
  const contentWidth = isPortrait ? "100%" : "50%";

  const avatarAnimations = useMemo(
    () =>
      calculatedIsCorrect.studentCorrect
        ? AvatarAnimations.Victory
        : AvatarAnimations.Defeat,
    [calculatedIsCorrect.studentCorrect]
  );

  const showPointsAwarded = isClassSession && !isDrawItem;

  return (
    <InterstitialBackground pattern={pattern}>
      <Stack
        h="100%"
        w="100%"
        direction={isPortrait ? "column" : "row-reverse"}
      >
        <Box h={contentHeight} w={contentWidth}>
          <Square size="100%">
            <VStack
              p={pxToRem(currentBreakpoints.padding)}
              spacing={pxToRem(currentBreakpoints.margin)}
            >
              <Box>
                <Text
                  textColor="link.link-blue"
                  textStyle="gameDisplay"
                  fontSize={pxToRem(fontSize2x)}
                  textAlign="center"
                >
                  {mainText}
                </Text>
                <Text
                  textColor="link.link-hover"
                  textStyle="gameDisplay"
                  fontSize={pxToRem(currentBreakpoints.fontSize)}
                  textAlign="center"
                >
                  {flavorText}
                </Text>
              </Box>
              {showPointsAwarded && outerGame !== SessionGameType.QuickPlay && (
                <AnimatedBox
                  style={{
                    opacity: pointsSpring.opacity,
                    transform: pointsSpring.scale.to((s) => `scale(${s})`),
                  }}
                >
                  <Box
                    padding={pxToRem(currentBreakpoints.padding)}
                    backgroundImage={Blob}
                    backgroundSize="100% 100%"
                    backgroundRepeat="no-repeat"
                  >
                    <Flex flexDirection="column" alignItems="center">
                      <Text
                        textColor="primary.white"
                        textStyle="gameDisplay"
                        lineHeight="100%"
                        fontSize={pxToRem(fontSize2x)}
                      >
                        +{round_awarded_points}
                      </Text>
                      <Text textColor="primary.white" textStyle="gameDisplay">
                        {t("pointsTitle")}
                      </Text>
                    </Flex>
                  </Box>
                </AnimatedBox>
              )}
              {isClassSession && outerGame === SessionGameType.QuickPlay && (
                <QuickPlayStudentRoundReviewInterstitialContent
                  group={group}
                  isDrawItem={isDrawItem}
                  studentIsCorrect={studentAnswer?.isCorrect}
                />
              )}
            </VStack>
          </Square>
        </Box>
        <Box h={contentHeight} w={contentWidth}>
          <AvatarLayout
            students={groupUsers}
            selfId={student.id}
            showNametags={!isPortrait && groupUsers.length > 1}
            candidateAnimations={avatarAnimations}
            earnedQuickPlayCarePackage={earnedQuickPlayCarePackage}
          />
        </Box>
      </Stack>
    </InterstitialBackground>
  );
};
