import { AxiosError } from "axios";
import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { ICollection } from "../../types";

export interface IUseMutateCollectionArgs {
  onSuccess?: (data: IMutateCollectionResponse) => void;
  onError?: (err: AxiosError) => void;
  onConflict?: () => void;
}

export interface IMutateCollectionArgs {
  id?: string;
  parent_id?: string;
  name: string;
  description?: string;
}

export interface IMutateCollectionResponse {
  collection: ICollection;
}

/**
 * Create or update a collection
 * @returns
 */
export default function useMutateCollection({
  onSuccess,
  onError,
  onConflict,
}: IUseMutateCollectionArgs): UseMutationResult<
  IMutateCollectionResponse,
  unknown,
  IMutateCollectionArgs
> {
  const axios = useAxios();
  const queryClient = useQueryClient();

  const mutateCollection = async (args: IMutateCollectionArgs) => {
    const { id, description, name, parent_id } = args;

    const response = await axios({
      method: id ? "put" : "post",
      url: `/v1/collections${id ? "/" + id : ""}`,
      data: {
        collection: {
          name,
          description,
          parent_id,
        },
      },
    }).then((r) => r.data as IMutateCollectionResponse);

    queryClient.invalidateQueries("collections", { exact: true });
    queryClient.setQueryData(["collections", { id: id }], {
      collection: response.collection,
    });

    return response;
  };

  const mutation = useMutation(mutateCollection, {
    onSuccess,
    onError: (err: AxiosError) => {
      if (err.response?.status === 409) {
        if (onConflict) {
          onConflict();
          return;
        }
      }

      onError?.(err);
    },
  });

  return mutation;
}
