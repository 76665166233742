import {
  IPracticeSetItem,
  IPracticeSetSessionItem,
  QuestionType,
} from "links/lib/types";

// returns a translation key string or empty string
export const getItemInstructions = (
  item: IPracticeSetItem | IPracticeSetSessionItem
): string => {
  switch (item.original_question_type) {
    case QuestionType.TermDefinition:
      switch (item.question_type) {
        case QuestionType.MultipleChoice:
          return "termDefinitionMultipleChoice";
        case QuestionType.TextResponse:
          return "termDefinitionTextResponse";
        case QuestionType.Draw:
          return "termDefinitionDraw";
      }
      break;
    case QuestionType.Diagram:
      switch (item.question_type) {
        case QuestionType.MultipleChoice:
          return "diagramMultipleChoice";
      }
      break;
  }

  return "";
};
