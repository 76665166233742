import { Box } from "@chakra-ui/react";
import React from "react";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { IGameAssetColorScheme } from "links/lib/types";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";

interface IGroupChoicePlaceholderProps {
  colorScheme: IGameAssetColorScheme;
  size: number;
}

export const GroupChoicePlaceholder: React.FC<IGroupChoicePlaceholderProps> = ({
  colorScheme,
  size,
}) => {
  const { match: currentBreakpoints } = useBreakpoints();

  const remSize = pxToRem(size);

  return (
    <Box
      borderWidth={pxToRem(currentBreakpoints.borderWidth)}
      borderColor={colorScheme.secondaryColor}
      bgColor={colorScheme.primaryColor}
      boxSize={remSize}
      borderRadius="full"
    />
  );
};
