import { Box, FormControl, HStack, Spacer, VStack } from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { Link, useParams } from "react-router-dom";

import { Button } from "adminComponents/atoms/Button";
import { FormLabel } from "adminComponents/atoms/FormLabel";
import { Input } from "adminComponents/atoms/Input";
import { pxToRem } from "adminComponents/utils";
import { useAuth } from "links/lib/features/auth";
import { UserRole } from "links/lib/types";

import { StepCard } from "../components/StepCard";
import { PostStepAction, ProcessFlow } from "../types";

interface IProps {
  onSave: () => void;
  onDelete: () => void;
}

export const LLMProcessCreator: React.FC<IProps> = ({ onSave, onDelete }) => {
  const { authUser } = useAuth();
  const { index: indexString } = useParams<{ index: string }>();
  const processIndex = parseInt(indexString);

  const { control, watch, register } = useFormContext<ProcessFlow>();

  const process = watch();

  const { fields, insert, remove } = useFieldArray<ProcessFlow>({
    control,
    name: "steps",
  });

  const [selectedStepIndex, setSelectedStepIndex] = useState<
    number | undefined
  >(0);

  const addAtIndex = useCallback(
    (index: number) => {
      insert(index, {
        name: "",
        prompt: "",
        autoAdvanceToNextStep: false,
        postStepAction: PostStepAction.NONE,
      });
    },
    [insert]
  );

  const addBefore = useCallback(() => {
    if (selectedStepIndex === undefined) {
      return;
    }
    addAtIndex(selectedStepIndex);
  }, [selectedStepIndex, addAtIndex]);

  const addAfter = useCallback(() => {
    if (selectedStepIndex === undefined) {
      return;
    }
    addAtIndex(selectedStepIndex + 1);
  }, [selectedStepIndex, addAtIndex]);

  const onRemove = useCallback(
    (index: number) => {
      if (selectedStepIndex === index) {
        setSelectedStepIndex(0);
      }
      remove(index);
    },
    [selectedStepIndex, remove]
  );

  const handleDownloadJSON = useCallback(() => {
    const blob = new Blob([JSON.stringify(process, null, 2)], {
      type: "application/json;charset=utf8",
    });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `process_${new Date().getTime()}.json`);
    document.body.appendChild(link);

    link.click();
    link.remove();
  }, [process]);

  if (!authUser || authUser.role !== UserRole.ContentSpecialist) return null;

  return (
    <Box h="100vh" w="100vw">
      <FormControl variant="adminFormControl">
        <VStack
          zIndex={10}
          as="header"
          position="sticky"
          top={0}
          alignItems="flex-start"
          w="full"
          p={pxToRem(16)}
          background="primary.light-gray"
        >
          <HStack w="full">
            <Button variant="adminButtonFilled" onClick={addBefore}>
              Add Before
            </Button>
            <Button variant="adminButtonFilled" onClick={addAfter}>
              Add After
            </Button>
            <Button variant="adminButtonFilled" onClick={handleDownloadJSON}>
              Export JSON
            </Button>
            <Button variant="adminButtonFilled" onClick={onDelete}>
              Delete Process
            </Button>
            <Button
              variant="adminButtonFilled"
              as={Link}
              to="/e0fe8aed-6b71-420f-8f9f-48780bfbd3dc/llm-process-flows"
            >
              Process Picker
            </Button>

            <Spacer />
            <Box maxW={pxToRem(200)}>
              <HStack>
                <FormLabel>Name</FormLabel>
                <Input {...register("name")} />
              </HStack>
            </Box>
            <Spacer />
            <Button variant="adminButtonFilled" onClick={onSave}>
              Save
            </Button>
            <Button
              variant="adminButtonFilled"
              as={Link}
              to={`/e0fe8aed-6b71-420f-8f9f-48780bfbd3dc/llm-process-flows/${processIndex}/run`}
            >
              Run
            </Button>
          </HStack>
        </VStack>

        <VStack p={pxToRem(16)} spacing={pxToRem(16)}>
          {fields.map((step, index) => (
            <StepCard
              onRemove={() => onRemove(index)}
              selected={index === selectedStepIndex}
              onSelect={() => setSelectedStepIndex(index)}
              index={index}
              key={`${index}-${step.name}`}
            />
          ))}
        </VStack>
      </FormControl>
    </Box>
  );
};
