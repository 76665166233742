import { UseMutationResult, useMutation } from "react-query";

import { useAxios } from "../../hooks/useAxios";

export interface IJoinClassroomArgs {
  code: string;
}

export interface IUseJoinClassroomArgs {
  onSuccess?: () => void;
}

/**
 * Join a classroom with an invite code (as student)
 * @returns
 */
export default function useJoinClassroom({
  onSuccess,
}: IUseJoinClassroomArgs): UseMutationResult<
  unknown,
  unknown,
  IJoinClassroomArgs
> {
  const axios = useAxios();

  const joinClassroom = async (args: IJoinClassroomArgs) => {
    const { code } = args;

    const response = await axios({
      method: "post",
      url: "/v1/groups/join",
      data: {
        code,
      },
    }).then((r) => {
      return r.data as unknown;
    });

    return response;
  };

  const mutation = useMutation(joinClassroom, { onSuccess });

  return mutation;
}
