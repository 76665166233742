import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { ColorScheme } from "adminComponents";
import { Button } from "adminComponents/atoms/Button";
import { Card } from "adminComponents/atoms/Card";
import { Checkbox } from "adminComponents/atoms/Checkbox";
import { Heading } from "adminComponents/atoms/Heading";
import { Icon, IconOptions } from "adminComponents/atoms/Icon";
import { IconTooltip } from "adminComponents/atoms/IconTooltip";
import { Tag } from "adminComponents/atoms/Tag";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils/pxToRem";

interface IProps {
  requiresHigherOrderThinking?: boolean;
  handleChangeRequireHigherOrderThinking?: (r: boolean) => void;
  handleNextTab?: () => void;
  handleSaveAndClose: () => void;
  canSubmit?: boolean;
  isSubmitting: boolean;
  hideHigherOrderThinking?: boolean;
  nextSectionTextCopies?: {
    nextSectionTitle: string;
    nextSectionDescription: string;
    nextSectionCta: string;
    nextSectionIcon?: typeof IconOptions[number];
    secondaryNextSectionCta?: string;
    secondaryNextSectionIcon?: typeof IconOptions[number];
    secondaryNextSectionOnClick?: () => void;
  };
  backgroundColor?: ColorScheme;
}

export const CreateQuestionFooter: React.FC<IProps> = ({
  requiresHigherOrderThinking,
  handleChangeRequireHigherOrderThinking,
  handleNextTab,
  handleSaveAndClose,
  canSubmit = true,
  isSubmitting = false,
  nextSectionTextCopies,
  hideHigherOrderThinking = false,
  backgroundColor = "primary.tan",
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });

  return (
    <Box>
      {!hideHigherOrderThinking && handleChangeRequireHigherOrderThinking && (
        <Box>
          <Box
            display="flex"
            alignItems="center"
            gap={pxToRem(6)}
            marginBottom={pxToRem(8)}
          >
            <Text variant="adminP2">
              {t("createQuestion.higherOrderThinking")}
            </Text>
            <IconTooltip placement="top-start">
              {t("createQuestion.higherOrderThinkingTooltip")}
            </IconTooltip>
          </Box>
          <Text
            variant="adminP2"
            color="primary.dark-gray"
            marginBottom={pxToRem(16)}
            verticalAlign="middle"
          >
            {t("createQuestion.higherOrderThinkingDescription")}
          </Text>
          <Checkbox
            isChecked={requiresHigherOrderThinking}
            onChange={(e) =>
              handleChangeRequireHigherOrderThinking(e.target.checked)
            }
            marginBottom={pxToRem(40)}
          >
            <Text>{t("createQuestion.higherOrderThinkingCheckbox")}</Text>
          </Checkbox>
        </Box>
      )}
      {!!nextSectionTextCopies && (
        <Card
          borderColor={backgroundColor}
          backgroundColor={backgroundColor}
          variant="adminCardSolid"
          marginBottom={pxToRem(40)}
        >
          <Heading as="p" variant="adminH7" marginBottom={pxToRem(16)}>
            {nextSectionTextCopies.nextSectionTitle}
          </Heading>
          <Text variant="adminP2" marginBottom={pxToRem(16)}>
            {nextSectionTextCopies.nextSectionDescription}
          </Text>
          <Flex gap={pxToRem(10)}>
            <Button
              variant="adminButtonOutlined"
              size="sm"
              leftIcon={
                <Icon
                  color="primary.warm-black"
                  icon={nextSectionTextCopies.nextSectionIcon || "add"}
                />
              }
              onClick={handleNextTab}
            >
              {nextSectionTextCopies.nextSectionCta}
            </Button>
            {nextSectionTextCopies.secondaryNextSectionCta &&
              nextSectionTextCopies.secondaryNextSectionOnClick && (
                <Flex gap={pxToRem(10)} alignItems="center">
                  <Button
                    leftIcon={
                      nextSectionTextCopies.secondaryNextSectionIcon ? (
                        <Icon
                          marginRight={pxToRem(5)}
                          icon={nextSectionTextCopies.secondaryNextSectionIcon}
                        />
                      ) : undefined
                    }
                    variant="adminTextButtonMedium"
                    onClick={nextSectionTextCopies.secondaryNextSectionOnClick}
                  >
                    <Text
                      textDecoration="underline"
                      textDecorationColor="primary.warm-black"
                      color="primary.warm-black"
                    >
                      {" "}
                      {nextSectionTextCopies.secondaryNextSectionCta}
                    </Text>
                  </Button>
                  <Tag colorScheme="white">{t("common.beta")}</Tag>
                </Flex>
              )}
          </Flex>
        </Card>
      )}
      <Button
        onClick={handleSaveAndClose}
        variant="adminButtonFilled"
        size="lg"
        width={[]}
        isDisabled={!canSubmit}
        isLoading={isSubmitting}
      >
        {t("common.saveAndClose")}
      </Button>
    </Box>
  );
};
