import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { NotFound } from "adminComponents/screens/NotFound";
import { PermissionDenied } from "adminComponents/screens/PermissionDenied";

interface IAppErrorProps {
  code: 404 | 403;
}

const AppError: React.FC<IAppErrorProps> = (props: IAppErrorProps) => {
  // TODO: Make this awesome
  const history = useHistory();
  const { t } = useTranslation("translation", {
    keyPrefix: "error" + props.code,
  });

  const handleGoToHomePage = () => {
    history.push("/");
  };

  if (props.code === 404) {
    return <NotFound handleGoToHomePage={handleGoToHomePage} />;
  }

  if (props.code === 403) {
    return <PermissionDenied handleGoToHomePage={handleGoToHomePage} />;
  }

  return (
    <div role="main">
      <h1>{t("heading")}</h1>
    </div>
  );
};

export default AppError;
