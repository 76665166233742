export const generateTeacherName = ({
  given_name,
  family_name,
  teacher_preferred_name,
}: {
  given_name?: string;
  family_name?: string;
  teacher_preferred_name?: string;
}): { primary: string; secondary?: string } => {
  let primary;
  let secondary;

  const fullName =
    given_name && family_name ? `${given_name} ${family_name}` : given_name; // may be undefined

  if (teacher_preferred_name) {
    primary = teacher_preferred_name;
    secondary = fullName;
  } else {
    primary = fullName || "";
  }

  return { primary, secondary };
};

/*
examples:

Parameters: {given_name: undefined, family_name: undefined, teacher_preferred_name: undefined}
Result: {primary: undefined, secondary: undefined}

Parameters: {given_name: John, family_name: Doe, teacher_preferred_name: Johnny}
Result: {primary: Johnny, secondary: John Doe}

Parameters: {given_name: John, family_name: undefined, teacher_preferred_name: Johnny}
Result: {primary: Johnny, secondary: John}

Parameters: {given_name: undefined, family_name: Doe, teacher_preferred_name: Johnny}
Result: {primary: Johnny, secondary: undefined}

Parameters: {given_name: John, family_name: Doe, teacher_preferred_name: undefined}
Result: {primary: John Doe, secondary: undefined}

Parameters: {given_name: John, family_name: undefined, teacher_preferred_name: undefined}
Result: {primary: John, secondary: undefined}

Parameters: {given_name: undefined, family_name: Doe, teacher_preferred_name: undefined}
Result: {primary: undefined, secondary: undefined}

*/
