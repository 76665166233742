import { Box, BoxProps, Flex, HStack, Link } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

import { NavigationLink } from "adminComponents";
import { ClampedHeading } from "adminComponents/atoms/ClampedHeading";
import { Divider } from "adminComponents/atoms/Divider";
import { IHeadingProps } from "adminComponents/atoms/Heading";
import { Icon } from "adminComponents/atoms/Icon";
import { IconTooltip } from "adminComponents/atoms/IconTooltip";
import { Tag } from "adminComponents/atoms/Tag";
import { Text } from "adminComponents/atoms/Text";
import {
  IconButtonDropdown,
  MenuItemDetails,
} from "adminComponents/molecules/IconButtonDropdown";
import { PieChartWithLabel } from "adminComponents/molecules/PieChartWithLabel";
import { pxToRem } from "adminComponents/utils/pxToRem";
import {
  IClassroom,
  IGradeLevel,
  ISubject,
  UsersGroupsRole,
} from "links/lib/types";

export interface IProps extends BoxProps {
  accuracy?: number;
  classroom: IClassroom;
  subjects: Array<ISubject>;
  gradeLevels: Array<IGradeLevel>;
  handleArchive: (classroom: IClassroom) => void;
  handleDelete: (classroom: IClassroom) => void;
  handleDuplicate: (classroom: IClassroom) => void;
  handleEdit: (classroom: IClassroom) => void;
  headerVariant?: IHeadingProps["variant"];
  headerAs?: IHeadingProps["as"];
  link?: NavigationLink;
  hideSubjectsAndGrades?: boolean;
}

export const ClassDetailHeader: React.FC<IProps> = ({
  accuracy,
  classroom,
  handleArchive,
  handleDelete,
  // eslint-disable-next-line
  handleDuplicate,
  handleEdit,
  headerVariant = "adminH2",
  headerAs = "h2",
  link,
  subjects,
  gradeLevels,
  hideSubjectsAndGrades,
  ...rest
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });

  let menuItems: Array<MenuItemDetails> = [];

  if (classroom.users_groups_role === UsersGroupsRole.Owner) {
    menuItems = menuItems.concat([
      { label: t("common.edit"), handleClick: () => handleEdit(classroom) },
      // Not implemented
      // {
      //   label: t("common.duplicate"),
      //   handleClick: () => handleDuplicate(classroom),
      // },
      {
        label: t("common.archive"),
        handleClick: () => handleArchive(classroom),
      },
      { label: t("common.delete"), handleClick: () => handleDelete(classroom) },
    ]);
  }

  const {
    name,
    description,
    students_count,
    group_subjects,
    group_grade_levels,
  } = classroom;

  return (
    <Box {...rest}>
      <Flex justify="space-between" align="flex-start">
        <ClampedHeading
          headingAs={headerAs}
          headingVariant={headerVariant}
          noOfLines={3}
          triggerAriaLabel={name}
        >
          {link ? (
            <Link {...link} as={RouterLink} color="inherit" display="flex">
              {name}
              <Box ml={pxToRem(10)}>
                <Icon icon="keyboard_arrow_right" color="inherit" />
              </Box>
            </Link>
          ) : (
            <Box>{name}</Box>
          )}
        </ClampedHeading>
        <Box paddingTop={{ md: pxToRem(12) }} paddingX={{ md: pxToRem(12) }}>
          <IconButtonDropdown menuItems={menuItems} />
        </Box>
      </Flex>
      <Flex
        direction={{ base: "column", md: "row" }}
        wrap="wrap"
        gap={{ base: pxToRem(8), md: pxToRem(40) }}
        marginTop={{ base: pxToRem(20), md: pxToRem(16) }}
      >
        {description && <Text variant="adminP1">{description}</Text>}
        <HStack spacing={pxToRem(5)}>
          <PieChartWithLabel
            percentage={accuracy}
            text={t("classDetailHeader.averageClassAccuracy")}
            textVariant="adminLinkLarge"
            labelLinkTo={`/t/classrooms/${classroom.id}/sets`}
          />
          <IconTooltip>{t("classDetailHeader.accuracyTooltip")}</IconTooltip>
        </HStack>
        <HStack spacing={pxToRem(5)}>
          <Icon icon="account_circle_outlined" />
          <Text variant="adminP1">
            {students_count}{" "}
            {students_count === 1
              ? t("classDetailHeader.studentsSingular")
              : t("classDetailHeader.studentsPlural")}
          </Text>
        </HStack>
      </Flex>
      {!hideSubjectsAndGrades &&
        (!!group_subjects.length || !!group_grade_levels.length) && (
          <>
            <Divider color="primary.white" marginTop={pxToRem(16)} />
            <Flex gap={pxToRem(8)} marginTop={pxToRem(24)} wrap="wrap">
              {group_subjects?.map((subject, index) => (
                <Tag colorScheme="white" key={index}>
                  {subjects.find((s) => s.id === subject.subject_id)?.name ||
                    ""}
                </Tag>
              ))}
              {group_grade_levels?.map((grade_level, index) => (
                <Tag colorScheme="white" key={index}>
                  {gradeLevels.find((g) => g.id === grade_level.grade_level_id)
                    ?.grade_level || ""}
                </Tag>
              ))}
            </Flex>
          </>
        )}
    </Box>
  );
};
