import { ColorScheme } from "adminComponents";
import { pxToRem } from "adminComponents/utils/pxToRem";

export const AdminAvatarStreak = {
  parts: ["avatar", "badge", "container", "segment"],
  variants: {
    adminAvatarStreak: ({
      activeSegment,
      backgroundColor,
    }: {
      activeSegment: number;
      backgroundColor?: ColorScheme;
    }): Record<string, unknown> => ({
      avatar: {
        bgColor: backgroundColor || "primary.golden-light",
        color: "primary.warm-black",
        position: "relative",
        svg: {
          color: "primary.dark-gray",
        },
        zIndex: 1,
      },
      badge: {
        bottom: pxToRem(8),
        right: pxToRem(-22),
        borderWidth: 0,
        transform: "none",
        svg: {
          margin: pxToRem(7),
        },
      },
      container: {
        alignItems: "center",
        borderRadius: "full",
        color: "primary.medium-gray",
        display: "flex",
        justifyContent: "center",
        position: "relative",
        "*": {
          fontFamily: "TTCommons",
        },
      },
      segment: {
        color:
          activeSegment > 1 ? "utility.dark-orange" : "primary.medium-gray",
        position: "absolute",
        top: `calc(100% - ${pxToRem(3)})`,
        transform: "rotate(-120deg)",
        transformOrigin: "top",
        zIndex: "base",

        _last: {
          color:
            activeSegment >= 3 ? "utility.dark-orange" : "primary.medium-gray",
          left: pxToRem(-1),
          top: pxToRem(-1),
          transform: "rotate(0deg)",
        },
        _first: {
          color:
            activeSegment > 0 ? "utility.dark-orange" : "primary.medium-gray",
          left: "auto",
          right: pxToRem(-1),
          top: pxToRem(-1),
          transform: "rotate(0deg) scaleX(-1)",
        },
      },
      weekCountContainer: {
        position: "absolute",
        bottom: pxToRem(-15),
        right: pxToRem(-10),
        backgroundColor: "primary.white",
        color: "primary.warm-black",
        fontFamily: "Gooper",
        paddingX: pxToRem(12),
        paddingY: pxToRem(2),
        borderRadius: "full",
      },
    }),
  },
  sizes: {
    sm: {
      avatar: {
        w: pxToRem(92),
        h: pxToRem(92),
        "[role=img]": {
          fontSize: pxToRem(24),
        },
      },
      badge: {
        img: {
          width: pxToRem(45),
        },
        svg: {
          w: pxToRem(24),
          h: pxToRem(32),
        },
      },
      container: {
        w: pxToRem(104),
        h: pxToRem(104),
        top: pxToRem(1),
      },
      segment: {
        left: pxToRem(-6),
        _last: {
          top: pxToRem(0),
        },
        _first: {
          top: pxToRem(0),
        },
      },
    },
    md: {
      avatar: {
        w: pxToRem(120),
        h: pxToRem(120),
        "[role=img]": {
          fontSize: pxToRem(32),
        },
      },
      badge: {
        img: {
          width: pxToRem(50),
        },
      },
      container: {
        w: pxToRem(135),
        h: pxToRem(135),
      },
      segment: {
        left: pxToRem(-8),
      },
    },
  },
  defaultProps: {
    size: "md",
  },
};
