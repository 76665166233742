import { useCallback } from "react";

import { useAnalytics } from "lib/contexts/analytics";
import useAuth from "links/lib/features/auth/useAuth";
import { AnalyticsEvent, UserRole } from "links/lib/types";

export interface IResult {
  trackHubSpotEvent: (
    event: AnalyticsEvent,
    props?: Record<string, unknown>
  ) => void;
}

export const useHubSpot = (): IResult => {
  const { authUser } = useAuth();
  const { trackEvent: _trackEvent } = useAnalytics();

  const trackHubSpotEvent = useCallback(
    (event: AnalyticsEvent, props?: Record<string, unknown>) => {
      if (!authUser || authUser.role === UserRole.Student) return;

      _trackEvent(
        event,
        {
          ...props,
          email: authUser.email,
          pp_email: authUser.email,
          pp_first_name: authUser.given_name,
          pp_last_name: authUser.family_name,
          pp_teacher_preferred_name: authUser.teacher_preferred_name,
        },
        {
          traits: {
            email: authUser.email,
            firstname: authUser.given_name,
            lastname: authUser.family_name,
          },
        }
      );
    },
    [_trackEvent, authUser]
  );

  return { trackHubSpotEvent };
};
