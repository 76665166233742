import { Box, BoxProps, useStyleConfig } from "@chakra-ui/react";
import React from "react";

import { ExplainableLightningSVG } from "./components";

export interface IProps extends BoxProps {
  inverted?: boolean;
}

export const ExplainableLightningIcon = React.forwardRef<
  HTMLDivElement,
  IProps
>((p, ref) => {
  const { style, ...rest } = p;

  const baseStyles = useStyleConfig("AdminIcon", {
    variant: "explainableLightning",
  });

  return (
    <Box {...rest} __css={{ ...baseStyles, ...style }} ref={ref}>
      <ExplainableLightningSVG />
    </Box>
  );
});

ExplainableLightningIcon.displayName = "ExplainableLightningIcon";
