import { Flex, useMultiStyleConfig } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { CertifiedCheckIcon } from "adminComponents/atoms/CertifiedCheckIcon";
import { Text } from "adminComponents/atoms/Text";

export interface IProps {
  withText?: boolean;
}

export const CertifiedPracticeSetBanner: React.FC<IProps> = ({
  withText = false,
}) => {
  const styles = useMultiStyleConfig("AdminCertifiedPracticeSetBanner", {});
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });

  return (
    <Flex sx={styles.container}>
      {withText && (
        <Text variant="adminP2" color="white">
          {t("common.certified")}
        </Text>
      )}
      <CertifiedCheckIcon
        aria-label={!withText ? t("common.certified") : undefined}
        sx={styles.icon}
      />
    </Flex>
  );
};
