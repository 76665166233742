import { Flex, Image } from "@chakra-ui/react";
import moment from "moment";
import React, { useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { IOption } from "adminComponents/atoms/Dropdown";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils";
import { useAnalytics } from "lib/contexts/analytics";
import { useAuth } from "links/lib/features/auth";
import { useFetchSubjects } from "links/lib/features/subjects";
import { getRegionsForCountry } from "links/lib/geo";
import {
  AnalyticsEvent,
  IAgreement,
  SharingSettingsVisibility,
} from "links/lib/types";
import { useEditAvatar } from "sharedComponents/hooks/useEditAvatar";

import { TeacherOnboardingStep1 } from "./components/Step1";
import { TeacherOnboardingStep2 } from "./components/Step2";
import { TeacherOnboardingStep3 } from "./components/Step3";
import AccountCreatedGIF from "./resource/account-created.gif";
import LoadingAnimationGIF from "./resource/loading-animation.gif";

interface IProps {
  isFormLoading: boolean;
  isOnboardingSuccess: boolean;
  agreements: Array<IAgreement>;
  defaultValues: Partial<IOnboardingForm>;
  handleSubmit: (form: IOnboardingForm) => void;
}

export interface IOnboardingForm {
  language: string;
  country: string;
  state: string;
  timezone: string;
  title: string;
  nickname: string;
  grades: Array<string>;
  selectedGrades: Array<IOption>;
  subjects: Array<string>;
  subjectIndexes: Array<boolean>;
  schoolOrDistrictName: string;
  schoolOrDistrictPostalCode: string;
  sharingVisibility: SharingSettingsVisibility;
  acceptedAgreementIds: Array<string>;
}

export const TeacherOnboarding: React.FC<IProps> = ({
  isFormLoading,
  isOnboardingSuccess,
  agreements,
  defaultValues,
  handleSubmit,
}) => {
  const { authUser } = useAuth();
  const { trackEvent } = useAnalytics();

  const { t } = useTranslation("admin", {
    keyPrefix: "teacherOnboardContainer",
    useSuspense: false,
  });
  const [step, setStep] = React.useState(1);

  const fetchSubjects = useFetchSubjects({
    country: authUser?.country || "US",
    limit: 100,
  });
  const parentSubjects = useMemo(() => {
    return (fetchSubjects.data?.subjects || []).flatMap((s) =>
      s.parent_id === "0" ? [s] : []
    );
  }, [fetchSubjects.data?.subjects]);

  const methods = useForm<IOnboardingForm>({
    defaultValues: getDefaultValues(defaultValues),
    mode: "onChange",
  });

  const _handleSubmit = (data: IOnboardingForm) => {
    const acceptedAgreementIds = agreements.map((agreement) => agreement.id);
    const subjects = data.subjectIndexes.flatMap((v, i) => {
      if (!v) return [];
      const subject = parentSubjects[i];
      if (!subject) return [];
      return [subject.id];
    });
    const grades = data.selectedGrades.map((option) => option.value);

    // Let progress bar complete before submitting/redirecting
    setTimeout(() => {
      handleSubmit({
        ...data,
        acceptedAgreementIds,
        subjects,
        grades,
      });
    }, 750);
  };

  const {
    modal,
    profileImageCapture,
    onOpen: onOpenEditAvatarModal,
    isAvatarLoading,
  } = useEditAvatar({
    onEquipAvatarItem: (avatarItemId, categoryId, colorHex) => {
      trackEvent(AnalyticsEvent.TeacherDashboard_Onboard_EditAvatar_EquipItem, {
        itemId: avatarItemId,
        itemCategoryId: categoryId,
        itemColorHexCode: colorHex,
      });
    },
    onDequipAvatarItem: (avatarItemId, categoryId) => {
      if (!categoryId) return;

      trackEvent(
        AnalyticsEvent.TeacherDashboard_Onboard_EditAvatar_UnsetCategory,
        {
          itemCategoryId: categoryId,
        }
      );
    },
    onSetSkeletonType: (skeletonType) => {
      trackEvent(
        AnalyticsEvent.TeacherDashboard_Onboard_EditAvatar_SetSkeletonType,
        {
          skeletonType: skeletonType,
        }
      );
    },
    onSetHairColor: (hairColorHexCode) => {
      trackEvent(
        AnalyticsEvent.TeacherDashboard_Onboard_EditAvatar_SetHairColor,
        {
          colorHexCode: hairColorHexCode,
        }
      );
    },
    onSetSkinTone: (skinToneHexCode) => {
      trackEvent(
        AnalyticsEvent.TeacherDashboard_Onboard_EditAvatar_SetSkinTone,
        {
          toneHexCode: skinToneHexCode,
        }
      );
    },
  });

  const handleContinue = () => {
    trackEvent(AnalyticsEvent.TeacherDashboard_Onboard_PageChange, {
      step: step + 1,
      pageCount: 3,
    });
    setStep(step + 1);
  };

  const handleBack = () => {
    trackEvent(AnalyticsEvent.TeacherDashboard_Onboard_PageChange, {
      step: step - 1,
      pageCount: 3,
    });
    setStep(step - 1);
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(_handleSubmit)}>
          {isFormLoading && (
            <Flex
              alignItems="center"
              p={pxToRem(64)}
              flexDirection="column"
              textAlign="center"
              gap={pxToRem(16)}
            >
              <Image boxSize={pxToRem(300)} src={LoadingAnimationGIF} />
              <Text variant="adminP1">{t("loadingText")}</Text>
            </Flex>
          )}
          {!isFormLoading && isOnboardingSuccess && (
            <Flex
              alignItems="center"
              p={pxToRem(64)}
              flexDirection="column"
              textAlign="center"
              gap={pxToRem(16)}
            >
              <Image boxSize={pxToRem(300)} src={AccountCreatedGIF} />
              <Text variant="adminP1">{t("successText")}</Text>
            </Flex>
          )}
          {!isFormLoading && !isOnboardingSuccess && step === 1 && (
            <TeacherOnboardingStep1
              handleContinue={handleContinue}
              isLoading={isFormLoading || isAvatarLoading}
              handleEditAvatarModalOpen={onOpenEditAvatarModal}
            />
          )}
          {!isFormLoading && !isOnboardingSuccess && step === 2 && (
            <TeacherOnboardingStep2
              handleContinue={handleContinue}
              handleBack={handleBack}
              isLoading={isFormLoading}
            />
          )}
          {!isFormLoading && !isOnboardingSuccess && step === 3 && (
            <TeacherOnboardingStep3
              agreements={agreements}
              handleBack={handleBack}
              isLoading={isFormLoading}
            />
          )}
        </form>
      </FormProvider>
      {modal}
      {profileImageCapture}
    </>
  );
};

export const getDefaultValues = (
  defaultValues?: Partial<IOnboardingForm>
): IOnboardingForm => {
  let region = defaultValues?.state || "";
  // get_geo returns long form regions, like "Iowa". We store two letter codes, so attempt
  // a lookup and mutate region if necessary
  if (region.length > 2) {
    const regionObject = getRegionsForCountry(
      defaultValues?.country || ""
    ).find((r) => r.name === region);
    if (regionObject) {
      region = regionObject.code;
    }
  }

  return {
    // TODO: Remove "US" default if/when region gating is removed
    country: defaultValues?.country || "US",
    grades: defaultValues?.grades ?? [],
    selectedGrades: defaultValues?.selectedGrades ?? [],
    language: defaultValues?.language || "en",
    title: defaultValues?.title ?? "",
    sharingVisibility:
      defaultValues?.sharingVisibility ?? SharingSettingsVisibility.Public,
    nickname: defaultValues?.nickname ?? "",
    state: region,
    subjects: defaultValues?.subjects ?? [],
    subjectIndexes: defaultValues?.subjectIndexes ?? [],
    timezone: defaultValues?.timezone ?? moment.tz.guess(),
    schoolOrDistrictName: defaultValues?.schoolOrDistrictName ?? "",
    schoolOrDistrictPostalCode: defaultValues?.schoolOrDistrictPostalCode ?? "",
    acceptedAgreementIds: defaultValues?.acceptedAgreementIds ?? [],
  };
};
