import {
  Box,
  FormErrorMessage as ChakraFormErrorMessage,
  FormErrorMessageProps as IProps,
  useStyleConfig,
} from "@chakra-ui/react";
import React from "react";

const FormErrorMessage: React.FC<IProps> = ({ variant, children, ...rest }) => {
  const styles = useStyleConfig("AdminFormErrorMessage", { variant });

  return (
    <ChakraFormErrorMessage {...rest}>
      <Box __css={styles}>{children}</Box>
    </ChakraFormErrorMessage>
  );
};

FormErrorMessage.defaultProps = {
  variant: "adminFormErrorMessage",
};
export { FormErrorMessage };
