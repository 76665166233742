import { Box, Link } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Text } from "adminComponents/atoms/Text";
import {
  ModalStep,
  MultiStepModal,
} from "adminComponents/molecules/MultiStepModal";
import { pxToRem } from "adminComponents/utils";
import { config } from "links/lib/constants";
import { ThirdParty } from "links/lib/types";

import AvatarCustomization from "./resource/student-avatar-customization.png";
import StudentSignUpPearPractice from "./resource/student-sign-up-pear-practice.png";

export enum DescriptionCase {
  GOOGLE,
  GOOGLE_NO_NOTIFICATION,
  LINK,
  BY_EMAIL,
  BY_EMAIL_NO_NOTIFICATIOON,
}

interface IAddStudentDirectionsModalProps {
  isOpen: boolean;
  handleClose: () => void;
  descriptionCase?: DescriptionCase;
}

export const AddStudentDirectionsModal: React.FC<
  IAddStudentDirectionsModalProps
> = ({ isOpen, handleClose, descriptionCase = ThirdParty.NONE }) => {
  const { t } = useTranslation("admin", {
    keyPrefix: "addStudentDirections",
    useSuspense: false,
  });

  const signInURL = `${config.baseUrl}/sign-in`;

  let signUpText = t("signUp");

  switch (descriptionCase) {
    case DescriptionCase.GOOGLE:
      signUpText = t("googleSignUp");
      break;
    case DescriptionCase.GOOGLE_NO_NOTIFICATION:
      signUpText = t("googleSignUpNoNotification");
      break;
    case DescriptionCase.LINK:
      signUpText = t("linkSignUp");
      break;
    case DescriptionCase.BY_EMAIL:
      signUpText = t("signUp");
      break;
    case DescriptionCase.BY_EMAIL_NO_NOTIFICATIOON:
      signUpText = t("signUpNoNotification");
      break;
    default:
      signUpText = t("signUp");
      break;
  }

  const slides: ModalStep[] = [
    {
      stepId: "0",
      title: t("title"),
      subTitle: t("step1"),
      description: (
        <>
          {signUpText}
          {descriptionCase !== DescriptionCase.LINK && (
            <>
              {" "}
              <Link
                href={signInURL}
                isExternal
                style={{ textDecoration: "underline" }}
                color="utility.link"
                target="_blank"
              >
                {signInURL}
              </Link>
            </>
          )}
        </>
      ),
      image: {
        src: StudentSignUpPearPractice,
        altText: t("signUpImageAlt"),
      },
    },
    {
      stepId: "1",
      title: t("title"),
      subTitle: t("step2"),
      description: t("welcomeVideo"),
      image: {
        src: AvatarCustomization,
        altText: t("avatarCustomizeImageAlt"),
      },
      secondaryDescription: (
        <Box
          mt={pxToRem(20)}
          p={pxToRem(10)}
          w="full"
          backgroundColor="primary.tan"
          borderRadius="lg"
          textAlign="center"
        >
          <Text size="md" variant="adminP1">
            {t("avatarAdvice")}
          </Text>
        </Box>
      ),
    },
  ];

  return (
    <MultiStepModal
      isOpen={isOpen}
      handleClose={handleClose}
      handleFinish={handleClose}
      steps={slides}
      hideBackButtonOnBeginning
      size="3xl"
      modalVariant="adminMultistepModal"
    />
  );
};
