import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { SocialEventReaction } from "../../types";

export interface ISocialEventReactionsResponse {
  social_event_reactions: Array<SocialEventReaction>;
}

export default function useFetchSocialEventReactions(): UseQueryResult<ISocialEventReactionsResponse> {
  const axios = useAxios();

  const query = useQuery<ISocialEventReactionsResponse>(
    ["socialEventReactions"],
    async (): Promise<ISocialEventReactionsResponse> => {
      return await axios.get("/v1/social/reactions").then((r) => {
        const data = r.data as ISocialEventReactionsResponse;
        data.social_event_reactions.sort((a, b) => {
          return a.id < b.id ? -1 : a.id > b.id ? 1 : 0;
        });
        return data;
      });
    }
  );

  return query;
}
