export const generateStudentName = ({
  given_name,
  family_name,
  student_nickname,
}: {
  given_name?: string;
  family_name?: string;
  student_nickname?: string;
}): { primary: string; secondary?: string } => {
  let primary;
  let secondary;

  const fullName =
    given_name && family_name ? `${given_name} ${family_name}` : given_name; // may be undefined

  if (student_nickname) {
    primary = student_nickname;
    secondary = fullName;
  } else {
    primary = fullName || "";
  }

  return { primary, secondary };
};

export const getStudentGivenName = ({
  given_name,
  student_nickname,
}: {
  given_name?: string;
  student_nickname?: string;
}): string | undefined => {
  const nicknameParts = student_nickname?.split(" ") || [];
  return nicknameParts.length > 0 ? nicknameParts[0] : given_name;
};

export const getStudentFamilyName = ({
  family_name,
  student_nickname,
}: {
  family_name?: string;
  student_nickname?: string;
}): string | undefined => {
  const nicknameParts = student_nickname?.split(" ") || [];
  return nicknameParts.length > 1
    ? nicknameParts.slice(1, nicknameParts.length).join(" ")
    : family_name;
};

/*
generateStudentName examples:

Parameters: {given_name: undefined, family_name: undefined, student_nickname: undefined}
Result: {primary: undefined, secondary: undefined}

Parameters: {given_name: John, family_name: Doe, student_nickname: Johnny}
Result: {primary: Johnny, secondary: John Doe}

Parameters: {given_name: John, family_name: undefined, student_nickname: Johnny}
Result: {primary: Johnny, secondary: John}

Parameters: {given_name: undefined, family_name: Doe, student_nickname: Johnny}
Result: {primary: Johnny, secondary: undefined}

Parameters: {given_name: John, family_name: Doe, student_nickname: undefined}
Result: {primary: John Doe, secondary: undefined}

Parameters: {given_name: John, family_name: undefined, student_nickname: undefined}
Result: {primary: John, secondary: undefined}

Parameters: {given_name: undefined, family_name: Doe, student_nickname: undefined}
Result: {primary: undefined, secondary: undefined}

*/
