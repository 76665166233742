import { useMount } from "react-use";

import { SessionMusic } from "sessionComponents/types";
import { useSessionAudio } from "sharedComponents/contexts/sessionAudio";

export const useGameHypeAudio = (isStudent?: boolean): void => {
  const { setBackgroundAudio } = useSessionAudio();

  useMount(() => {
    if (!isStudent) setBackgroundAudio(SessionMusic.TeamShuffleShuffle);
  });
};
