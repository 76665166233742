import {
  CSSObject,
  Flex,
  FormControl,
  HStack,
  RadioGroup,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { FormLabel } from "adminComponents/atoms/FormLabel";
import { IconTooltip } from "adminComponents/atoms/IconTooltip";
import { Radio } from "adminComponents/atoms/Radio";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils";
import { SharingSettingsVisibility } from "links/lib/types";

import { Formik } from "../../TeacherAccountInformationForm";
import { asyncSetFieldTouched } from "./formConfig";

interface IProps {
  form: Formik;
  fieldStyles?: CSSObject;
}

export const ProfileSettingsForm: React.FC<IProps> = ({
  form,
  fieldStyles,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const { setFieldValue } = form;

  const visibilityOptions = [
    {
      label: t("teacherProfileSettingsForm.visibilityPublic"),
      description: t("teacherProfileSettingsForm.visibilityPublicDescription"),
      value: SharingSettingsVisibility.Public,
    },
    {
      label: t("teacherProfileSettingsForm.visibilityGSOnly"),
      description: t("teacherProfileSettingsForm.visibilityGSOnlyDescription"),
      value: SharingSettingsVisibility.GSOnly,
    },
    {
      label: t("teacherProfileSettingsForm.visibilityPrivate"),
      description: t("teacherProfileSettingsForm.visibilityPrivateDescription"),
      value: SharingSettingsVisibility.Private,
    },
  ];

  const handleChangeVisibility = (visibility: SharingSettingsVisibility) => {
    asyncSetFieldTouched("sharingVisibility", form).finally(() => {
      setFieldValue("sharingVisibility", visibility);
    });
  };

  return (
    <Flex sx={fieldStyles}>
      <FormControl>
        <Flex flexDir="column" gap={pxToRem(8)}>
          <HStack alignItems="center">
            <IconTooltip>
              {t(
                "teacherAccountSetupForm.teacherInfoForm.profileSettingsTooltip"
              )}
            </IconTooltip>
            <FormLabel variant="adminFormLabelDarkGray">
              {t("teacherAccountSetupForm.teacherInfoForm.profileSettings")}
            </FormLabel>
          </HStack>
          <Text variant="adminP2">
            {t(
              "teacherAccountSetupForm.teacherInfoForm.profileSettingsDescription"
            )}
          </Text>
          <RadioGroup
            mt={pxToRem(8)}
            id="sharingVisibility"
            name="sharingVisibility"
            onChange={(v) =>
              handleChangeVisibility(v as SharingSettingsVisibility)
            }
            value={form.values["sharingVisibility"]}
          >
            <Flex flexDir="column" gap={pxToRem(12)}>
              {visibilityOptions.map((vo, i) => {
                return (
                  <Radio key={i} value={vo.value}>
                    <Flex flexDir="column">
                      <Text variant="adminP2Bold">{vo.label}</Text>
                      <Text variant="adminP2">{vo.description}</Text>
                    </Flex>
                  </Radio>
                );
              })}
            </Flex>
          </RadioGroup>
        </Flex>
      </FormControl>
    </Flex>
  );
};
