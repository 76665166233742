import {
  Box,
  ThemeTypings,
  useBreakpointValue,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useIntersection } from "react-use";

import { Button } from "adminComponents/atoms/Button";
import {
  IconCTAButtonWrapper as BackButtonWrapper,
  IconCTAButton,
} from "adminComponents/atoms/IconCTAButton";
import { AvatarPreview } from "adminComponents/molecules/AvatarPreview";
import { EditAvatarOverlayLayout } from "adminComponents/molecules/EditAvatarOverlayLayout";
import {
  EditAvatarTabs,
  IProps as IEditAvatarTabs,
} from "adminComponents/organisms/EditAvatarTabs";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { AvatarSkeletonType } from "links/lib/types";

interface EditAvatarModal extends IEditAvatarTabs {
  useModal?: boolean;
  modalSize?: ThemeTypings["components"]["Modal"]["sizes"];
  isSaving?: boolean;
  isOpen?: boolean;
  avatarPreviewStage: React.ReactNode;
  variant?: string;
  handleBack: () => void;
  handleClose: () => void;
  handleDone: () => void;
  handleSelectAvatarType: (skeletonType: AvatarSkeletonType) => void;
  handleSectionIdChange: (sectionId: string) => void;
}

export const EditAvatarModal: React.FC<EditAvatarModal> = ({
  useModal = false,
  modalSize = "full",
  avatar,
  avatarPreviewStage,
  variant = "adminEditAvatarModal",
  isSaving,
  isOpen,
  handleBack,
  handleClose,
  handleDone,
  handleSkinToneColorChange,
  handleHairColorChange,
  handleEquip,
  handleDeequip,
  handleSelectAvatarType,
  handleTabChange,
  handleSectionIdChange,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const isMobile = useBreakpointValue({ base: true, lg: false });

  const intersectionRef = useRef(null);
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: "0px 0px 50% 0px",
    threshold: 0.1,
  });

  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    if (intersection && intersection.intersectionRatio < 0.1) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  }, [intersection]);

  const styles = useMultiStyleConfig("AdminEditAvatarModal", {
    variant,
    isSticky,
  });

  const stickyNav = (
    <Box sx={styles.stickyPreview}>
      <BackButtonWrapper>
        <IconCTAButton
          sx={styles.stickyBackButton}
          icon="keyboard_arrow_left"
          boxSize={24}
          iconSize={20}
          ariaLabel={t("common.ariaBackToPreviousScreen")}
          onClick={handleBack}
        />
      </BackButtonWrapper>
      <Box sx={styles.stickyPreviewAvatar}>
        <AvatarPreview>{avatarPreviewStage}</AvatarPreview>
      </Box>
    </Box>
  );

  const leftColumnContent = (
    <Box ref={intersectionRef} w="full">
      {isMobile && stickyNav}
      <Box sx={styles.leftColumnStage}>
        <AvatarPreview>{avatarPreviewStage}</AvatarPreview>
      </Box>
    </Box>
  );

  const rightColumnContent = (
    <Box sx={styles.rightColumnContent}>
      <Box>
        <EditAvatarTabs
          avatar={avatar}
          handleDeequip={handleDeequip}
          handleEquip={handleEquip}
          handleHairColorChange={handleHairColorChange}
          handleSkinToneColorChange={handleSkinToneColorChange}
          handleTabChange={handleTabChange}
          handleSectionIdChange={handleSectionIdChange}
          handleSelectAvatarType={handleSelectAvatarType}
          isMobile={!!isMobile}
          useModal={useModal}
        />
      </Box>
      <Box __css={styles.doneButton}>
        <Button
          isLoading={isSaving}
          minW={pxToRem(230)}
          onClick={handleDone}
          size="lg"
          variant="adminButtonFilled"
          w="full"
        >
          {t("common.done")}
        </Button>
      </Box>
    </Box>
  );

  return (
    <EditAvatarOverlayLayout
      useModal={useModal}
      modalSize={modalSize}
      handleBack={handleBack}
      handleClose={handleClose}
      isOpen={!!isOpen}
      leftColumnContent={leftColumnContent}
      rightColumnContent={rightColumnContent}
    />
  );
};
