import { UseMutationResult, useMutation } from "react-query";

import { useAxios } from "../../hooks/useAxios";

export interface IRateExplainConversationParams {
  practice_set_session_id: string;
  practice_set_session_item_id: string;
  practice_set_item_id: string;
  rating: number;
}

export function useRateExplainConversation(): UseMutationResult<
  unknown,
  unknown,
  IRateExplainConversationParams
> {
  const axios = useAxios();

  const explainSessionItem = async (args: IRateExplainConversationParams) => {
    const {
      rating,
      practice_set_session_id,
      practice_set_session_item_id,
      practice_set_item_id,
    } = args;

    const response = await axios({
      method: "put",
      url: `/v1/practice-sets/sessions/${practice_set_session_id}/items/${practice_set_session_item_id}/explain`,
      data: {
        rating,
        practice_set_item_id,
      },
    }).then((r) => r.data);

    return response;
  };

  const mutation = useMutation(explainSessionItem);

  return mutation;
}
