import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import {
  IPracticeSetItem,
  IPracticeSetSession,
  OnDemandIntent,
  PracticeSessionItemVariantType,
  SessionOuterGame,
  SessionType,
} from "../../types";

export interface IMutateSessionArgs {
  allowAnonymousStudents?: boolean;
  practiceSetId: string;
  classroomId: string;
  outerGame?: SessionOuterGame;
  assignmentId?: string;
  generateQuestionsFromTerms: boolean;
  allowStudentNames: boolean;
  secondsPerQuestion: number;
  startTime: Date;
  sessionType?: SessionType;
  enableCoopDraw?: boolean;
  randomizeOrder?: boolean;
  randomizeItemChoiceOrder?: boolean;
  onDemandIntent?: OnDemandIntent;
  subjectId?: string;
  sessionItems?: Array<{
    practice_set_item: IPracticeSetItem;
    variant: PracticeSessionItemVariantType;
  }>;
  useCustomSessionGroups?: boolean;
}

export interface IUseMutateSessionArgs {
  onSuccess?: (response: { practice_set_session: IPracticeSetSession }) => void;
  onError?: () => void;
}

/**
 * Creates a new practice set session
 * @returns
 */
export default function useMutateSession({
  onSuccess,
  onError,
}: IUseMutateSessionArgs): UseMutationResult<
  { practice_set_session: IPracticeSetSession },
  unknown,
  IMutateSessionArgs
> {
  const axios = useAxios();
  const queryClient = useQueryClient();

  const mutateSession = async (args: IMutateSessionArgs) => {
    const {
      allowAnonymousStudents,
      practiceSetId,
      classroomId,
      outerGame,
      generateQuestionsFromTerms,
      allowStudentNames,
      secondsPerQuestion,
      startTime,
      sessionType,
      enableCoopDraw,
      randomizeOrder,
      randomizeItemChoiceOrder,
      onDemandIntent,
      subjectId,
      sessionItems,
      assignmentId,
      useCustomSessionGroups,
    } = args;

    const response = await axios
      .post(`/v1/practice-sets/${practiceSetId}/sessions`, {
        practice_set_session: {
          allow_anonymous_students: allowAnonymousStudents,
          practice_set_id: practiceSetId,
          group_id: classroomId,
          outer_game: outerGame,
          generate_questions_from_terms: generateQuestionsFromTerms,
          allow_student_names: allowStudentNames,
          seconds_per_question: secondsPerQuestion,
          start_time: startTime.toISOString(),
          session_type: sessionType,
          randomize_order: randomizeOrder,
          randomize_item_choice_order: randomizeItemChoiceOrder,
          on_demand_intent: onDemandIntent,
          subject_id: subjectId,
          assignment_id: assignmentId,
          use_custom_session_groups: useCustomSessionGroups,
        },
        enable_coop_draw: enableCoopDraw,
        session_items: sessionItems,
      })
      .then((r) => r.data as { practice_set_session: IPracticeSetSession });

    return response;
  };

  const mutation = useMutation(mutateSession, {
    onSuccess: (response: { practice_set_session: IPracticeSetSession }) => {
      queryClient.invalidateQueries(["sessions"]);
      onSuccess && onSuccess(response);
    },
    onError: () => {
      onError && onError();
    },
  });

  return mutation;
}
