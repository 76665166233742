import { Box, Flex, Image, Show, VStack } from "@chakra-ui/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { string } from "yup";

import { Button } from "adminComponents/atoms/Button";
import { Card } from "adminComponents/atoms/Card";
import { Heading } from "adminComponents/atoms/Heading";
import { PaginationCircles } from "adminComponents/atoms/PaginationCircles";
import { ProgressBar } from "adminComponents/atoms/ProgressBar";
import { Text } from "adminComponents/atoms/Text";
import AccountCreatedGIF from "adminComponents/screens/TeacherOnboarding/resource/account-created.gif";
import LoadingAnimationGIF from "adminComponents/screens/TeacherOnboarding/resource/loading-animation.gif";
import { TemplateWithCenteredHeroOneColumn } from "adminComponents/templates/TemplateWithCenteredHeroOneColumn";
import { pxToRem } from "adminComponents/utils";
import { useErrorToast, useShowToast } from "adminComponents/utils/toast";
import { useAnalytics } from "lib/contexts/analytics";
import { useHubSpot } from "lib/hooks/useHubSpot";
import { useAuth } from "links/lib/features/auth";
import {
  useFetchClassrooms,
  useMutateClassroomUsers,
} from "links/lib/features/classrooms";
import {
  AnalyticsEvent,
  IClassroom,
  IUser,
  UserRole,
  UsersGroupsRole,
} from "links/lib/types";
import { HeaderWithLanguagePicker } from "sharedComponents/atoms/HeaderWithLanguagePicker";
import { useLanguageDropdown } from "sharedComponents/hooks/useLanguageDropdown";

import { TeacherTourStep1 } from "./components/Step1";
import { TeacherTourStep2 } from "./components/Step2";
import { TeacherTourStep3 } from "./components/Step3";
import { TeacherTourStep3Disabled } from "./components/Step3Disabled";
import { TeacherTourStep4 } from "./components/Step4";

export interface ITeacherTourProps {
  redirect?: string;
}

export const TeacherTour: React.FC<ITeacherTourProps> = () => {
  const { authUser } = useAuth();
  const { t } = useTranslation("admin");
  const history = useHistory();
  const location = useLocation();
  const showToast = useShowToast();
  const { trackEvent } = useAnalytics();
  const { trackHubSpotEvent } = useHubSpot();

  const [step, tab] = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return [params.get("step"), params.get("tab")];
  }, [location.search]);

  const [redirect, setRedirect] = useState("/t/public-library");

  const classroomFetch = useFetchClassrooms({});
  useErrorToast(classroomFetch.error);

  // TODO: Introduce a boolean field on groups to indicate it was auto-created
  const autoCreatedClassroom: IClassroom | undefined = useMemo(() => {
    if (classroomFetch.data?.classrooms.length === 1) {
      return classroomFetch.data.classrooms[0];
    }

    let groupName = "";
    if (authUser?.teacher_preferred_name?.endsWith("s")) {
      groupName = t("teacherTour.classPossessiveEndsWithS", {
        name: authUser?.teacher_preferred_name,
      });
    } else {
      groupName = t("teacherTour.classPossessive", {
        name: authUser?.teacher_preferred_name,
      });
    }

    return classroomFetch.data?.classrooms.find(
      (classroom) => classroom.name === groupName
    );
  }, [authUser?.teacher_preferred_name, classroomFetch.data?.classrooms, t]);

  const [paginationIndex, setPaginationIndex] = useState(Number(step) || 1);
  const [progressValue, setProgressValue] = useState(25 * (Number(step) || 1));

  const moveToNextStep = useCallback(() => {
    switch (paginationIndex) {
      case 1:
        setProgressValue(50);
        break;
      case 2:
        setProgressValue(75);
        break;
      case 3:
        setProgressValue(90);
        break;
      case 4:
        setProgressValue(100);
        break;
    }
    setPaginationIndex(paginationIndex + 1);

    // Keep track of the current step in the URL; this is necessary if the
    // user needs to leave the tour to complete Google OAuth, so we can return
    // to the correct step.
    if (paginationIndex < 4) {
      const params = new URLSearchParams({ step: String(paginationIndex + 1) });
      history.replace({
        pathname: location.pathname,
        search: params.toString(),
      });
    }
  }, [history, location.pathname, paginationIndex]);

  const [addStudentsByEmailErrorString, setAddStudentsByEmailErrorString] =
    useState<string | undefined>();
  const [
    addStudentsByEmailDisableNotifications,
    setAddStudentsByEmailDisableNotifications,
  ] = useState(false);
  const [studentEmails, setStudentEmails] = useState("");
  const onSuccess = useCallback(() => {
    showToast(
      t("classroomDetailContainer.studentsTab.addByEmail.successToast")
    );

    trackHubSpotEvent(AnalyticsEvent.HubSpot_AddedStudentsToClassroom);
    moveToNextStep();
  }, [moveToNextStep, showToast, t, trackHubSpotEvent]);

  const studentsMutation = useMutateClassroomUsers({ onSuccess });
  const handleAddStudents = useCallback(
    (content: string) => {
      if (!autoCreatedClassroom) return;

      if (!content.trim()) {
        setAddStudentsByEmailErrorString(
          t(
            "classroomDetailContainer.studentsTab.addByEmail.errorEmailsRequired"
          )
        );
        return;
      }

      const emails = content
        .trim()
        .replaceAll("\n", ",")
        .split(",")
        .map((e) => e.trim())
        .filter((e) => e !== "");

      const isInvalid = emails.some((e) => !string().email().isValidSync(e));

      if (isInvalid) {
        setAddStudentsByEmailErrorString(
          t(
            "classroomDetailContainer.studentsTab.addByEmail.errorEmailsInvalid"
          )
        );
        return;
      }

      // passed validation checks - input is valid
      setAddStudentsByEmailErrorString(undefined);

      const users = emails.map((email): IUser => {
        return {
          id: "0",
          email: email,
          role: UserRole.Student,
          true_role: UserRole.Student,
          license_id: "0",
          timezone_locale: "",
          language: "",
          is_guest: false,
          country: "",
          region: "",
        };
      });

      studentsMutation.mutate({
        classroomId: autoCreatedClassroom.id,
        users,
        disableNotifications: addStudentsByEmailDisableNotifications,
        usersGroupsRole: UsersGroupsRole.Member,
      });

      trackEvent(
        AnalyticsEvent.TeacherDashboard_Onboard_AddStudents_AddByEmail,
        {}
      );
    },
    [
      autoCreatedClassroom,
      studentsMutation,
      addStudentsByEmailDisableNotifications,
      trackEvent,
      t,
    ]
  );

  const handleContinue = () => {
    // Special case for step 3, tab 1 -- the Add Students By Email option.
    // In addition to continuing, use the button click as an indication to
    // those students by email.
    if (step === "3" && tab === "1") {
      handleAddStudents(studentEmails);
    } else {
      moveToNextStep();
    }
  };

  useEffect(() => {
    if (paginationIndex === 5) {
      setTimeout(() => history.push(redirect), 3000);
    }
  });

  const langDropdown = useLanguageDropdown();

  const pages = [
    <TeacherTourStep1 key={1} />,
    <TeacherTourStep2 key={2} />,
    autoCreatedClassroom ? (
      <TeacherTourStep3
        key={3}
        classroom={autoCreatedClassroom}
        disableNotifications={addStudentsByEmailDisableNotifications}
        emailsString={studentEmails}
        errorString={addStudentsByEmailErrorString}
        setDisableNotifications={setAddStudentsByEmailDisableNotifications}
        setEmailsString={setStudentEmails}
      />
    ) : (
      <TeacherTourStep3Disabled key={3} />
    ),
    <TeacherTourStep4 key={4} />,
  ];

  const tour = (
    <Flex flexDirection="column" gap={pxToRem(16)} position="relative">
      <ProgressBar
        background="primary.warm-white"
        variant="adminStudentGoal"
        value={progressValue}
        icon={
          progressValue === 100 ? (
            <Image
              position="absolute"
              boxSize={pxToRem(100)}
              right={pxToRem(-40)}
              src={AccountCreatedGIF}
            />
          ) : (
            {
              borderColor: "primary.light-gray",
              borderWidthPx: 6,
              boxSize: pxToRem(50),
              iconName: "star_outlined",
            }
          )
        }
      />
      <Card
        variant="adminCardMediumBorder"
        backgroundColor="primary.white"
        borderColor="primary.tan"
        py="admin.cards.medium"
      >
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          gap={pxToRem(32)}
        >
          {pages[paginationIndex - 1]}
          {paginationIndex === pages.length + 1 && (
            <Flex
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              gap={pxToRem(64)}
              py={pxToRem(150)}
            >
              <Image boxSize={pxToRem(100)} src={LoadingAnimationGIF} />
              <Heading as="h1" variant="adminH2">
                {t("teacherTour.loadingMessage")}
              </Heading>
            </Flex>
          )}
          {paginationIndex <= pages.length && (
            <VStack w="full" gap={pxToRem(32)}>
              <Button
                w={{ base: "auto", sm: "20%" }}
                variant="adminButtonFilled"
                onClick={handleContinue}
              >
                {t("common.continue")}
              </Button>
              {paginationIndex === 4 && (
                <Text
                  variant="adminP1Bold"
                  color="utility.link"
                  cursor="pointer"
                  onClick={() => {
                    setRedirect("/t/my-library");
                    handleContinue();
                  }}
                >
                  {t("teacherTour.createMyOwn")}
                </Text>
              )}
              <PaginationCircles
                index={paginationIndex - 1}
                total={pages.length}
              />
            </VStack>
          )}
        </Flex>
      </Card>
    </Flex>
  );

  // authUser is validated in TeacherDashboard, this is just
  // for the benefit of TypeScript
  if (!authUser) return <></>;

  return (
    <TemplateWithCenteredHeroOneColumn
      isLoading={classroomFetch.isLoading}
      nav={
        <Box mt={pxToRem(-30)} mx="auto" pt={pxToRem(30)} px={pxToRem(40)}>
          <HeaderWithLanguagePicker />
        </Box>
      }
      heroContent={tour}
      isHeroShifted
    >
      {/* All actual content rendered by the "tour" variable */}
      <Show below="lg">
        <Box display="flex" justifyContent="center" mb={pxToRem(30)}>
          {langDropdown}
        </Box>
      </Show>
    </TemplateWithCenteredHeroOneColumn>
  );
};
