import { ISessionGroup } from "links/lib/types";
import { capitalizeFirstLetter } from "links/lib/util";

export const getFormattedGroupName = (group?: ISessionGroup): string => {
  if (!group) {
    return "";
  }

  return `${capitalizeFirstLetter(
    group.adjective || ""
  )} ${capitalizeFirstLetter(group?.noun || "")}`;
};
