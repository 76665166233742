import { Box, Flex } from "@chakra-ui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";

import { Button } from "adminComponents/atoms/Button";
import { Icon } from "adminComponents/atoms/Icon";
import { PremiumTooltipRich } from "adminComponents/atoms/PremiumTooltipRich";
import { Text } from "adminComponents/atoms/Text";
import { Textarea } from "adminComponents/atoms/Textarea";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { useAnalytics } from "lib/contexts/analytics";
import { useAuth } from "links/lib/features/auth";
import { AnalyticsEvent } from "links/lib/types";

interface TextToAudioProps {
  uploading: boolean;
  uploadError: boolean;
  audioUrlToAdd: string;
  textToSpeechError: boolean;
  handleConvertTextToSpeech: (text: string) => Promise<void>;
  handleInsertMedia: (
    imageUrl: string,
    altText: string,
    audioUrl?: string,
    videoUrl?: string
  ) => void;
  handleRemoveAudio: () => void;
}

export const TextToAudio: React.FC<TextToAudioProps> = ({
  audioUrlToAdd,
  uploading,
  uploadError,
  textToSpeechError,
  handleConvertTextToSpeech,
  handleInsertMedia,
  handleRemoveAudio,
}) => {
  const { t } = useTranslation("admin", {
    keyPrefix: "addMediaModal.textToAudio",
    useSuspense: false,
  });
  const [textToProcess, setTextToProcess] = useState("");
  const { trackEvent } = useAnalytics();
  const { hasNoPremiumAccess } = useAuth();

  const handleKeyDown = (ev: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (ev.key === "Enter") {
      handleConvertTextToSpeech(textToProcess);
    }
  };

  const handleChange = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextToProcess((ev.target as HTMLTextAreaElement).value);
  };

  const handleClick = () => {
    if (!audioUrlToAdd) {
      trackEvent(
        AnalyticsEvent.TeacherDashboard_PracticeSetDetail_QuestionFlyout_AddMediaModal_TextToAudioIntent,
        {}
      );
      handleConvertTextToSpeech(textToProcess);
    } else {
      trackEvent(
        AnalyticsEvent.TeacherDashboard_PracticeSetDetail_QuestionFlyout_AddMediaModal_TextToAudioConfirm,
        {}
      );
      handleInsertMedia("", "", audioUrlToAdd);
    }
  };

  return (
    <Flex flexDir="column" alignItems="center" py={pxToRem(8)}>
      <Flex w="full" flexDir="column" alignItems="center">
        {uploading && (
          <Text variant="adminP2" marginBottom={pxToRem(16)}>
            {t("processing")}
          </Text>
        )}
        {audioUrlToAdd && (
          <Flex w="full" alignItems="center" flexDir="column" py={pxToRem(50)}>
            <ReactPlayer
              height={pxToRem(50)}
              width="80%"
              url={audioUrlToAdd}
              controls={true}
            />
          </Flex>
        )}
        {!audioUrlToAdd && (
          <Box w="full">
            <Text variant="adminP2" marginBottom={pxToRem(16)}>
              {t("enterTextToConvertToAudio")}
            </Text>
            <Textarea
              placeholder={t("placeholder")}
              onKeyDown={handleKeyDown}
              onChange={handleChange}
              isDisabled={hasNoPremiumAccess}
            />
          </Box>
        )}
        {textToSpeechError && !audioUrlToAdd && (
          <Text
            variant="adminP2"
            color="utility.error"
            mt={pxToRem(8)}
            textAlign="center"
          >
            {t("errorConvertingTextToSpeech")}
          </Text>
        )}
        {uploadError && (
          <Text
            variant="adminP2"
            color="utility.error"
            mt={pxToRem(8)}
            textAlign="center"
          >
            {t("uploadError")}
          </Text>
        )}
      </Flex>
      <Flex
        flexDir={["column", null, "row"]}
        alignItems={["center", null, "flex-end"]}
        justifyContent={["center", null, "end"]}
        gap={[pxToRem(12), null, pxToRem(12)]}
        mt={pxToRem(24)}
        w="full"
      >
        {!!audioUrlToAdd && (
          <Button
            variant="adminButtonOutlined"
            size="lg"
            w={["full", null, pxToRem(160)]}
            onClick={handleRemoveAudio}
            isDisabled={uploading}
          >
            {t("startOver")}
          </Button>
        )}
        <PremiumTooltipRich isDisabled={!hasNoPremiumAccess}>
          <Box>
            <Button
              variant="adminButtonFilled"
              size="lg"
              w={["full", null, pxToRem(180)]}
              isLoading={uploading}
              onClick={hasNoPremiumAccess ? undefined : handleClick}
              isDisabled={
                hasNoPremiumAccess || (!audioUrlToAdd && !textToProcess)
              }
              leftIcon={hasNoPremiumAccess ? <Icon icon="lock" /> : undefined}
            >
              {audioUrlToAdd ? t("addMedia") : t("convertToAudio")}
            </Button>
          </Box>
        </PremiumTooltipRich>
      </Flex>
    </Flex>
  );
};
