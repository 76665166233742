import { Box } from "@chakra-ui/react";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, useParams } from "react-router-dom";
import { useMount } from "react-use";

import { LoadingSpinner } from "adminComponents/atoms/LoadingSpinner";
import { pxToRem } from "adminComponents/utils";
import { useErrorToast, useShowToast } from "adminComponents/utils/toast";
import { useAuth } from "links/lib/features/auth";
import { useJoinClassroom } from "links/lib/features/classrooms";

/**
 * Simple component to join a classroom through a link. If the user is logged in, then attempt to join
 * and then redirect to dashboard. If not logged in, then redirect to sign in with joinCode in state.
 * @returns
 */
export const Enroll: React.FC = () => {
  const { joinCode } = useParams<{ joinCode?: string }>();
  const { authUser } = useAuth();
  const showToast = useShowToast();
  const { t } = useTranslation("admin", {
    keyPrefix: "enroll",
    useSuspense: false,
  });

  const onJoinSuccess = useCallback(() => {
    showToast(t("successToast"));
  }, [showToast, t]);

  const joinMutation = useJoinClassroom({
    onSuccess: onJoinSuccess,
  });

  useErrorToast(joinMutation.error);

  useMount(() => {
    if (joinCode) joinMutation.mutate({ code: joinCode });
  });

  if (!authUser || !joinCode) {
    return (
      <Redirect
        to={{
          pathname: "/sign-in/student",
          state: { joinCode },
        }}
      />
    );
  }

  if (!joinMutation.isSuccess || joinMutation.isError) {
    return (
      <Redirect
        to={{
          pathname: "/",
        }}
      />
    );
  }

  return (
    <Box my={pxToRem(40)}>
      <LoadingSpinner />
    </Box>
  );
};
