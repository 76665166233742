import { SessionConnectionStatus } from "links/lib/types";

import { ListStudent } from "./index";

export interface ISimpleSessionUser {
  id: string;
  name: string;
  is_guest?: boolean;
  connection_status: SessionConnectionStatus;
}

export interface ISimpleUser {
  id: string;
  teacher_preferred_name?: string;
  student_nickname?: string;
  given_name?: string;
  family_name?: string;
  is_guest: boolean;
}

export const getStudentName = (student: ISimpleUser): string => {
  let studentName = "";

  if (student.student_nickname) {
    studentName = student.student_nickname;
  } else if (student.teacher_preferred_name) {
    studentName = student.teacher_preferred_name;
  } else if (student.family_name || student.given_name) {
    if (student.given_name) {
      studentName += student.given_name;
      if (student.family_name) {
        studentName += ` ${student.family_name}`;
      }
    } else if (student.family_name) {
      studentName = student.family_name;
    }
  } else {
    //since all other name related properties are optional
    if (student.is_guest) {
      studentName = "Guest";
    } else {
      studentName = "Student";
    }
  }
  return studentName;
};

export const toStudentList = (
  onlineStudents: ISimpleSessionUser[],
  classroomStudents: ISimpleUser[]
): ListStudent[] => {
  //Use map to reduce looping/searching through both arrays too much
  const studentMap = new Map<string, ListStudent>();
  onlineStudents.forEach((student, index) => {
    const mapKey = student.is_guest ? `guest-${index}` : student.id;

    studentMap.set(mapKey, {
      name: student.name,
      status: student.connection_status,
      id: student.id,
      isGuest: !!student.is_guest,
    });
  });

  classroomStudents.forEach((student) => {
    if (!studentMap.has(student.id)) {
      studentMap.set(student.id, {
        name: getStudentName(student),
        status: SessionConnectionStatus.Offline,
        id: student.id,
        isGuest: student.is_guest,
      });
    }
  });

  return Array.from(studentMap.values()).sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    } else if (a.name > b.name) {
      return 1;
    } else {
      return 0;
    }
  });
};
