import moment from "moment";
import Papa from "papaparse";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import { StudentDetailScreen } from "adminComponents/screens/StudentDetailScreen/";
import { IAssignmentProps } from "adminComponents/screens/StudentDetailScreen/components/AssignmentsTab";
import { generateStudentName } from "adminComponents/utils";
import { usePageTrack } from "lib/contexts/analytics";
import { usePageTitle } from "lib/hooks/usePageTitle";
import { momentFormatString } from "links/lib/constants";
import { useFetchAssignmentUserAttemptsBulk } from "links/lib/features/assignments";
import { useAuth } from "links/lib/features/auth";
import { AnalyticsPage, UsersGroupsRole } from "links/lib/types";
import { redirectIfNaN } from "links/lib/util";
import { useEditStudent } from "screens/TeacherClassrooms/shared/hooks/useEditStudent";
import { useNavigationData } from "screens/TeacherDashboard/contexts/TeacherNavigationDataProvider";

import { AssignmentsTab } from "./components/assignmentsTab";
import { PracticeSetsTab } from "./components/practiceSetsTab";
import { StandardsTab } from "./components/standardsTab";
import { useAssignmentData } from "./hooks/useAssignmentData";
import { useStudentDetailQueries } from "./hooks/useStudentDetailQueries";

export enum StudentDetailTab {
  PracticeSets = 0,
  Assignments = 1,
  Standards = 2,
}

export const StudentDetail: React.FC = () => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });
  const params = useParams() as { classroomId: string; studentId: string };
  const history = useHistory();
  const { isFeatureEnabled } = useAuth();

  const { classroomId, studentId } = params;

  redirectIfNaN(studentId, `/t/classrooms/${classroomId}/students`);

  usePageTrack(AnalyticsPage.TeacherDashboard_ClassroomDetail_StudentDetail);

  const { navigationData } = useNavigationData();

  const {
    isLoading,
    classroomData,
    student,
    studentAccuracy,
    studentWeeklyPracticeHours,
    gradeLevels,
    studentGradeLevel,
  } = useStudentDetailQueries({ classroomId, studentId });

  usePageTitle([
    student ? generateStudentName(student).primary : undefined,
    classroomData?.classroom.name,
  ]);

  const { editModal, handleEditIntent } = useEditStudent({
    classroom_id: classroomId,
    gradeLevels,
  });

  const handleTabChange = (tab: StudentDetailTab) => {
    switch (tab) {
      case StudentDetailTab.PracticeSets:
        history.push(`/t/classrooms/${classroomId}/students/${studentId}/sets`);
        return;
      case StudentDetailTab.Assignments:
        history.push(
          `/t/classrooms/${classroomId}/students/${studentId}/assignments`
        );
        return;
      case StudentDetailTab.Standards:
        history.push(
          `/t/classrooms/${classroomId}/students/${studentId}/standards`
        );
        return;
    }
  };

  const isPracticeSetsTab = useRouteMatch(
    "/t/classrooms/:classroomId/students/:studentId/sets"
  );
  const isAssignmentsTab = useRouteMatch(
    "/t/classrooms/:classroomId/students/:studentId/assignments"
  );
  const isStandardsTab = useRouteMatch(
    "/t/classrooms/:classroomId/students/:studentId/standards"
  );

  const tabIndex = useMemo(() => {
    if (isPracticeSetsTab) {
      return StudentDetailTab.PracticeSets;
    }
    if (isAssignmentsTab) {
      return StudentDetailTab.Assignments;
    }
    if (isStandardsTab) {
      return StudentDetailTab.Standards;
    }

    return 0;
  }, [isPracticeSetsTab, isAssignmentsTab, isStandardsTab]);

  const navData = useMemo(() => {
    const { primary } = generateStudentName(student || {});

    const breadcrumbs = [
      {
        label: t("classroomDetailContainer.breadcrumbClassrooms"),
        to: "/t/classrooms",
        isExternal: false,
      },
      {
        label: classroomData?.classroom.name || "",
        to: `/t/classrooms/${classroomId}`,
        isExternal: false,
      },
      {
        // This page
        label: `${primary}`,
        isExternal: false,
        to: `/t/classrooms/${classroomId}/students/${studentId}`,
      },
    ];

    return {
      ...navigationData,
      breadcrumbs,
    };
  }, [navigationData, classroomId, studentId, student, classroomData, t]);

  const { activeAssignmentData, pastAssignmentData } = useAssignmentData({
    classroomId,
    studentId,
  });

  const assignmentIds = useMemo(() => {
    return activeAssignmentData
      .concat(pastAssignmentData)
      .map((a) => a.assignment.id);
  }, [activeAssignmentData, pastAssignmentData]);

  const assignmentUserAttempts = useFetchAssignmentUserAttemptsBulk({
    assignment_ids: assignmentIds,
    user_id: studentId,
    group_id: classroomId,
  });
  const [downloadedCsv, setDownloadedCsv] = useState(false);
  const handleDownloadAssignmentsCsv = () => {
    const csv = Papa.unparse(
      activeAssignmentData
        .flatMap((a: IAssignmentProps) => {
          return assignmentUserAttempts.assignmentUserAttemptsData[
            a.assignment.id
          ].map((attempt) => {
            const csvRow: { [key: string]: string } = {};
            csvRow[`${t("assignment.assignment")} ${t("common.id")}`] =
              a.assignment.id;
            csvRow[t("common.status")] = t("common.current");
            csvRow[t("common.practiceSet")] = a.assignment.practice_set.title;
            csvRow[t("createAssignment.dueDate")] = moment(
              a.assignment.ends_at
            ).format(momentFormatString);
            csvRow[t("common.attemptDate")] = moment(
              attempt.created_at || ""
            ).format(momentFormatString);
            csvRow[t("common.accuracy")] = attempt.accuracy.toString();
            return csvRow;
          });
        })
        .concat(
          pastAssignmentData.flatMap((a: IAssignmentProps) => {
            return assignmentUserAttempts.assignmentUserAttemptsData[
              a.assignment.id
            ].map((attempt) => {
              const csvRow: { [key: string]: string } = {};
              csvRow[`${t("assignment.assignment")} ${t("common.id")}`] =
                a.assignment.id;
              csvRow[t("common.status")] = t("common.past");
              csvRow[t("common.practiceSet")] = a.assignment.practice_set.title;
              csvRow[t("createAssignment.dueDate")] = moment(
                a.assignment.ends_at
              ).format(momentFormatString);
              csvRow[t("common.attemptDate")] = moment(
                attempt.created_at || ""
              ).format(momentFormatString);
              csvRow[t("common.accuracy")] = attempt.accuracy.toString();
              return csvRow;
            });
          })
        )
    );

    const link = document.createElement("a");
    link.href = `data:text/csv;charset=utf-8,${csv}`;
    link.setAttribute(
      "download",
      `${generateStudentName({
        given_name: student?.given_name,
        family_name: student?.family_name,
        student_nickname: student?.student_nickname,
      }).primary.replaceAll(" ", "_")}_${new Date().getTime()}.csv`
    );
    document.body.appendChild(link);
    link.click();
    link.remove();
    setDownloadedCsv(true);
  };

  return (
    <>
      <StudentDetailScreen
        accuracy={studentAccuracy || 0}
        gradeLevel={studentGradeLevel}
        handleEdit={
          classroomData?.classroom.users_groups_role === UsersGroupsRole.Owner
            ? handleEditIntent
            : undefined
        }
        navigationData={navData}
        practiceHours={studentWeeklyPracticeHours}
        studentData={student}
        isLoading={isLoading}
        tabIndex={tabIndex}
        handleTabChange={handleTabChange}
        showPremiumMarker={!isFeatureEnabled("playtime.teacher.hide_premium")}
        handleDownloadCsv={handleDownloadAssignmentsCsv}
        downloadedCsv={downloadedCsv}
      >
        <Switch>
          <Redirect
            from="/t/classrooms/:classroomId/students/:studentId"
            exact
            to="/t/classrooms/:classroomId/students/:studentId/sets"
          />
          <>
            <Route path="/t/classrooms/:classroomId/students/:studentId/sets">
              <PracticeSetsTab
                classroomLoading={isLoading}
                classroom={classroomData?.classroom}
                classroomId={classroomId}
                studentId={studentId}
              />
            </Route>
            <Route path="/t/classrooms/:classroomId/students/:studentId/assignments">
              <AssignmentsTab classroomId={classroomId} studentId={studentId} />
            </Route>
            <Route path="/t/classrooms/:classroomId/students/:studentId/standards">
              <StandardsTab classroomId={classroomId} studentId={studentId} />
            </Route>
          </>
        </Switch>
      </StudentDetailScreen>
      {editModal}
    </>
  );
};
