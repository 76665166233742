import { hoverStyles, pxToRem } from "adminComponents/utils";

export const AdminCarousel = {
  baseStyle: ({
    bgColor,
    isBeginning,
    isEnd,
    showDots,
    alignItems,
  }: {
    bgColor?: string;
    isBeginning: boolean;
    isEnd: boolean;
    showDots?: boolean;
    alignItems?: "left" | "center" | "right";
  }): Record<string, unknown> => ({
    wrapper: {
      userSelect: "none",
      display: { lg: "flex" },
      alignItems: "center",
      backgroundColor: bgColor,
      ".swiper": {
        paddingTop: pxToRem(24),
        // we need to offset for when we are showing the dot navigation
        // and swiper has the high at 24px (from line-height)
        paddingBottom: `calc(${pxToRem(24)}${showDots ? " + 24px" : ""})`,
      },
      ".swiper-slide": {
        display: "flex",
        justifyContent: alignItems,
        width: "100%",
        height: "auto",
      },
      ".swiper-slide-active": {
        zIndex: 3,
      },
      ".swiper-pagination": {
        zIndex: 0,
      },
      ".swiper-pagination-bullet-active": {
        background: "primary.golden-hover",
      },
      ".swiper-pagination-bullet": {
        _hover: hoverStyles({
          background: { md: "primary.golden-hover" },
        }),
      },
      ".swiper-pagination-bullets": {
        bottom: 0,
      },
    },
    scrimLeft: {
      display: "block",
      width: pxToRem(48),
      height: "100%",
      zIndex: 10,
      position: "absolute",
      left: pxToRem(-10),
      top: 0,
    },
    scrimRight: {
      display: "block",
      width: pxToRem(48),
      height: "100%",
      zIndex: 10,
      position: "absolute",
      right: pxToRem(-10),
      top: 0,
    },
    prevArrow: {
      visibility: isBeginning ? "hidden" : "visible",
      marginRight: pxToRem(36),
    },
    nextArrow: {
      visibility: isEnd ? "hidden" : "visible",
      marginLeft: pxToRem(36),
    },
  }),
  variants: {
    adminCarousel: ({
      showDots,
    }: {
      showDots?: boolean;
    }): Record<string, unknown> => ({
      wrapper: {
        display: "block",
        alignItems: "unset",
        position: "relative",
        ".swiper": {
          mx: { base: pxToRem(15), md: pxToRem(68) },
          paddingLeft: { base: pxToRem(16), md: 0 },
          marginY: pxToRem(20),
          paddingTop: 0,
          paddingBottom: showDots ? pxToRem(24) : null,
          marginInlineStart: { base: 0 },
        },
        ".swiper-slide": {
          display: "block",
        },
      },
      prevArrow: {
        marginRight: pxToRem(0),
        position: "absolute",
        top: "50%",
        left: 0,
        transform: "translate(0, -50%)",
      },
      nextArrow: {
        marginLeft: pxToRem(0),
        position: "absolute",
        top: "50%",
        right: 0,
        transform: "translate(0, -50%)",
      },
    }),
  },
};
