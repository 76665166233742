import {
  Box,
  Modal as ChakraModal,
  Flex,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Heading } from "adminComponents/atoms/Heading";
import {
  IconCTAButtonWrapper as CloseButtonWrapper,
  IconCTAButton,
} from "adminComponents/atoms/IconCTAButton";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { IGradeLevel, IUser } from "links/lib/types";

interface RejectStudentModalProps {
  isOpen: boolean;
  student?: IUser;
  gradeLevel?: IGradeLevel;
  isLoading: boolean;
  rejectAllCount: number;
  handleRemove: (student: IUser) => void;
  handleRemoveAll: () => void;
  handleClose: () => void;
}

export const RejectStudentModal: React.FC<RejectStudentModalProps> = ({
  student,
  isLoading,
  isOpen,
  gradeLevel,
  rejectAllCount,
  handleClose,
  handleRemove,
  handleRemoveAll,
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });

  return (
    <ChakraModal
      closeOnOverlayClick={!isLoading}
      onClose={handleClose}
      size="2xl"
      variant="adminStudentRejectModal"
      isOpen={isOpen}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading as="h2" variant="adminH6">
            {rejectAllCount > 0
              ? t("rejectStudentModal.titleMultiple", { count: rejectAllCount })
              : t("rejectStudentModal.title")}
          </Heading>
        </ModalHeader>
        <ModalCloseButton
          as={CloseButtonWrapper}
          top={pxToRem(20)}
          right={pxToRem(20)}
        >
          <IconCTAButton ariaLabel={t("common.close")} />
        </ModalCloseButton>
        <ModalBody>
          <SimpleGrid gap={[pxToRem(24), null, pxToRem(40)]}>
            {!!student && (
              <Box
                p={pxToRem(24)}
                borderRadius={pxToRem(20)}
                bgColor="primary.tan"
              >
                <Text variant="adminP1" mb={pxToRem(5)}>
                  {student.given_name + " " + student.family_name}
                </Text>
                {!!gradeLevel && (
                  <Text variant="adminP2" color="primary.dark-gray">
                    {gradeLevel.grade_level}
                  </Text>
                )}
              </Box>
            )}
            <Flex gap={pxToRem(12)}>
              <Button
                variant="adminButtonFilled"
                size="lg"
                w={["50%", null, "auto"]}
                isLoading={isLoading}
                onClick={() => {
                  if (rejectAllCount > 0) {
                    handleRemoveAll();
                  } else if (student) {
                    handleRemove(student);
                  }
                }}
                rounded="full"
              >
                {t("common.remove")}
              </Button>
              <Button
                variant="adminButtonOutlined"
                size="lg"
                w={["50%", null, "auto"]}
                isLoading={isLoading}
                onClick={handleClose}
                rounded="full"
              >
                {t("common.cancel")}
              </Button>
            </Flex>
          </SimpleGrid>
        </ModalBody>
      </ModalContent>
    </ChakraModal>
  );
};
