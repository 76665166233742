import { pxToRem } from "adminComponents/utils/pxToRem";

import { colorMapping } from "../colors";

export const Input = {
  variants: {
    adminInput: {
      field: {
        border: `${pxToRem(1)} solid`,
        borderColor: "primary.medium-gray",
        textStyle: "adminP2",
        paddingX: pxToRem(16),
        paddingY: pxToRem(14),
        height: pxToRem(48),
        bg: "primary.white",
        color: "primary.warm-black",
        _readOnly: {
          boxShadow: "none !important",
          userSelect: "all",
        },
        _disabled: {
          opacity: 0.5,
          cursor: "not-allowed",
        },
        _invalid: {
          borderColor: "utility.error",
        },
        _focus: {
          border: `${pxToRem(2)} solid ${colorMapping["utility.focus"]}`,
          paddingX: pxToRem(15),
          paddingY: pxToRem(13),
          // removes 1px of padding from focus state to account for 1px extra border width
        },
        "::placeholder": {
          color: "primary.dark-gray",
        },
      },
    },
    adminSearchInput: {
      field: {
        border: `${pxToRem(1)} solid`,
        borderColor: "primary.medium-gray",
        textStyle: "adminP2",
        paddingTop: [pxToRem(16), null, pxToRem(18)],
        paddingBottom: [pxToRem(12), null, pxToRem(19)],
        paddingLeft: [pxToRem(36), null, pxToRem(44)],
        paddingRight: [pxToRem(10), null, pxToRem(110)],
        height: [pxToRem(48), null, pxToRem(57)],
        borderRadius: pxToRem(8),
        bg: "primary.white",
        color: "primary.warm-black",
        _readOnly: {
          boxShadow: "none !important",
          userSelect: "all",
        },
        _disabled: {
          opacity: 0.5,
          cursor: "not-allowed",
        },
        _invalid: {
          borderColor: "utility.error",
        },
        _focus: {
          border: `${pxToRem(2)} solid ${colorMapping["utility.focus"]}`,
          paddingTop: [pxToRem(15), null, pxToRem(17)],
          paddingBottom: [pxToRem(11), null, pxToRem(18)],
          paddingLeft: [pxToRem(35), null, pxToRem(43)],
          paddingRight: [pxToRem(9), null, pxToRem(109)],
          // removes 1px of padding from focus state to account for 1px extra border width
        },
        "::placeholder": {
          color: "primary.dark-gray",
        },
      },
    },
  },
};
