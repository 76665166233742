import { ComponentMeta, ComponentStory } from "@storybook/react";
import React from "react";

import { Icon } from "adminComponents/atoms/Icon";

import { Button } from ".";

export default {
  title: "Admin Components/Atoms/Button",
  component: Button,
  parameters: {
    a11y: { element: "#root" },
    actions: {
      handles: ["click"],
    },
    controls: {
      include: [
        "size",
        "variant",
        "isDisabled",
        "children",
        "leftIcon",
        "rightIcon",
      ],
    },
  },
  argTypes: {
    size: {
      options: ["sm", "md", "lg"],
      control: { type: "select" },
      defaultValue: "md",
      description: "Button size small/medium/large",
    },
    variant: {
      options: [
        "adminTextButtonExtraLarge",
        "adminTextButtonLargeNoUnderline",
        "adminTextButtonLarge",
        "adminTextButtonMedium",
        "adminTextButtonSmall",
        "adminButtonFilled",
        "adminButtonOutlined",
        "adminButtonWhite",
      ],
      control: { type: "select" },
      defaultValue: "adminButtonFilled",
      description: "Button type",
    },
    children: {
      defaultValue: "Button",
      description: "Button Content",
      control: { type: "text" },
    },
    leftIcon: {
      defaultValue: undefined,
      description: "Icon will be placed in the start of the Button",
    },
    rightIcon: {
      defaultValue: undefined,
      description: "Icon will be placed in the end of the Button",
    },
    isDisabled: {
      control: { type: "boolean" },
      defaultValue: false,
    },
  },
} as ComponentMeta<typeof Button>;

const Template: ComponentStory<typeof Button> = (args) => <Button {...args} />;

export const DefaultButton = Template.bind({});
DefaultButton.args = {};

export const WhiteButton = Template.bind({});
WhiteButton.args = {
  variant: "adminButtonWhite",
};

export const ButtonWithRightIcon = Template.bind({});
ButtonWithRightIcon.args = {
  rightIcon: <Icon icon="button_down_arrow" />,
};

export const ButtonWithLeftIcon = Template.bind({});
ButtonWithLeftIcon.args = {
  leftIcon: <Icon icon="search" />,
};

export const ButtonWithLeftAndRightIcon = Template.bind({});
ButtonWithLeftAndRightIcon.args = {
  leftIcon: <Icon icon="search" />,
  rightIcon: <Icon icon="button_down_arrow" />,
};
