import { Box } from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { XYCoord } from "react-dnd";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { useSessionActions } from "links/lib/contexts/sessionActions";
import { useSessionRoundGroupState } from "links/lib/contexts/sessionRoundGroupState";
import {
  ISessionDraggableSortItem,
  PracticeSessionItemVariantType,
  QuestionType,
} from "links/lib/types";
import { DraggableLabel } from "sessionComponents/atoms/DraggableLabel";
import { Marker } from "sessionComponents/atoms/Marker";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { useBoundingRect } from "sessionComponents/hooks/useBoundingRect";
import { QuestionResponse } from "sessionComponents/molecules/QuestionResponse";
import { Breakpoint } from "sessionComponents/theme/breakpoints";

export const StudentDiagramOptions: React.FC = () => {
  const { match } = useBreakpoints();
  const roundGroupState = useSessionRoundGroupState();
  const { setSortChoiceZone } = useSessionActions();

  const [draggingItem, setDraggingItem] = useState<{
    labelId: string;
    number: number;
  } | null>(null);

  const onStopDragging = (choice_id: string, zone_id: string) => {
    setSortChoiceZone(choice_id, zone_id);
    setDraggingItem(null);
  };

  const [cursorPosition, setCursorPosition] = useState<XYCoord | null>(null);
  const [optionRef, { x: initialXPosition }] =
    useBoundingRect<HTMLDivElement>();

  const answerOptions = useMemo(
    () => roundGroupState.dragsort?.items || [],
    [roundGroupState.dragsort?.items]
  );

  const handleDragStart = (labelId: string, number: number) => {
    if (!draggingItem || draggingItem.labelId !== labelId) {
      setDraggingItem({ labelId, number });
    }
  };

  const getRelativeXPosition = () => {
    if (cursorPosition?.x && initialXPosition) {
      return cursorPosition.x - (initialXPosition + match.markerSize * 0.5);
    } else return 0;
  };

  const handleCursorPositionChange = (newPosition: XYCoord | null) => {
    if ((!cursorPosition && newPosition) || (cursorPosition && !newPosition)) {
      setCursorPosition(newPosition);
    }
  };

  return (
    <Box paddingBottom={pxToRem(match.padding)}>
      {/* todo animations here  */}
      {answerOptions.map((option, index) => {
        return (
          <Box
            className="dnd-option"
            data-option-id={option.id}
            key={option.id}
            ref={optionRef}
          >
            <StudentDiagramOption
              getRelativeXPosition={getRelativeXPosition}
              handleCursorPositionChange={handleCursorPositionChange}
              handleDragStart={handleDragStart}
              index={index}
              match={match}
              option={option}
              onStopDragging={onStopDragging}
              isDragging={draggingItem?.labelId === option.id}
            />
          </Box>
        );
      })}
    </Box>
  );
};

interface IStudentDiagramOptionProps {
  option: ISessionDraggableSortItem;
  onStopDragging: (choice_id: string, zone_id: string) => void;
  index: number;
  match: Breakpoint;
  handleDragStart: (labelId: string, number: number) => void;
  handleCursorPositionChange: (newPosition: XYCoord | null) => void;
  getRelativeXPosition: () => void;
  isDragging: boolean;
}

const StudentDiagramOption: React.FC<IStudentDiagramOptionProps> = ({
  option,
  onStopDragging,
  index,
  match,
  handleDragStart,
  handleCursorPositionChange,
  getRelativeXPosition,
  isDragging,
}) => {
  const [offsetY, setOffsetY] = useState<number>(0);

  useEffect(() => {
    const element = document.getElementById(`drag-label-${index}`);

    const mouseDownListener = (e: MouseEvent) => setOffsetY(e.offsetY);

    // Safari vs Chrome vs mobile vs desktop don't fire these consistently
    // pointerdown should work for all cases but safari has issues with it.
    // using both eventListeners doesn't hurt
    element?.addEventListener("pointerdown", mouseDownListener);
    element?.addEventListener("mousedown", mouseDownListener);

    return () => {
      element?.removeEventListener("pointerdown", mouseDownListener);
      element?.removeEventListener("mousedown", mouseDownListener);
    };
  }, [index]);

  return (
    <Box id={`drag-label-${index}`}>
      <DraggableLabel
        label={option}
        stopDragging={onStopDragging}
        width="full"
        transform="translate3d(0, 0, 0)"
        marginTop={index ? pxToRem(match.margin / 2) : undefined}
        startDragging={(labelId: string) => handleDragStart(labelId, index + 1)}
        onCursorPositionChange={handleCursorPositionChange}
        dragItemPreviewContent={
          isDragging ? (
            <Box
              // drag preview defaults to (0,0) relative to the DraggableLabel so mouse can be far from Marker
              // when dragging if big DraggableLabel content
              // translate (x,y) relative to DraggableLabel such that mouse is bottom tip of the Marker
              transform={`translate(${getRelativeXPosition()}px, ${
                offsetY - match.markerSize
              }px)`}
            >
              <Marker
                containerHeight={pxToRem(match.responseHeight)}
                iconHeight={pxToRem(match.markerSize)}
                iconColor="utility.link"
                number={index + 1}
                containerWidth={pxToRem(match.markerSize)}
              />
            </Box>
          ) : undefined
        }
      >
        <QuestionResponse
          variant={PracticeSessionItemVariantType.CoopDragsort}
          questionType={QuestionType.Diagram}
          disabled={option.zone_id !== "0" || option.is_dragging}
          text={option.text}
          number={index + 1}
        />
      </DraggableLabel>
    </Box>
  );
};
