import { Box, Modal, ModalBody, ModalContent } from "@chakra-ui/react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useUnmount, useWindowSize } from "react-use";
import { clearTimeout, setTimeout } from "timers";

import { LoadingSpinner } from "adminComponents/atoms/LoadingSpinner";
import { pxToRem } from "adminComponents/utils/pxToRem";
import {
  IAvatar,
  ISeasonLocationAvatarItem,
  ISeasonLocationAvatarItemGroup,
  SeasonMapMusic,
} from "links/lib/types";
import { useAudio } from "sharedComponents/contexts/audio";
import { Season1MapTransition } from "sharedComponents/molecules/Season1MapTransition";
import { CombinedItem } from "sharedComponents/molecules/SeasonLocationProgress";
import { LocationInfo } from "sharedComponents/molecules/SeasonMap";
import { SeasonMapPanner } from "sharedComponents/molecules/SeasonMapPanner";

import { Season1Location } from "../Season1Location";
import { ILocationData, Season1Map, mapData, mapSize } from "../Season1Map";
import { Season1MapHeader } from "../Season1MapHeader";
import PannerBGSVG from "./resource/panner_bg.svg";

export interface ISeason1Modal {
  isOpen: boolean;
  isLoading: boolean;
  userProfileImageUrl?: string;
  handleClose: () => void;
  unlockedZoneIds: Array<string>;
  locationsProgress: Array<{
    totalSpaces: number;
    spacesCollected: number;
    locationId: string;
  }>;
  currentLocationId?: string;
  ticketCount: number;
  avatarItems: Array<ISeasonLocationAvatarItem>;
  avatarItemGroups: Array<ISeasonLocationAvatarItemGroup>;
  isUnlockLoading: boolean;
  avatarAtlasUrl: string;
  avatarSkeletonUrl: string;
  avatar?: IAvatar;
  handleRequestUnlock?: (item: CombinedItem) => void;
  handleVisitLocation?: (location_id: string) => void;
}

const pannerBGFallbackColor = "#69421B";

export const Season1Modal: React.FC<ISeason1Modal> = ({
  isOpen,
  isLoading,
  handleClose,
  unlockedZoneIds,
  locationsProgress,
  currentLocationId,
  ticketCount,
  userProfileImageUrl,
  handleRequestUnlock,
  avatarItems,
  avatarAtlasUrl,
  avatarItemGroups,
  avatarSkeletonUrl,
  isUnlockLoading,
  handleVisitLocation,
  avatar,
}) => {
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const [isMusicPlaying, setIsMusicPlaying] = useState(false);
  const [selectedLocationId, setSelectedLocationId] = useState<string>();
  const [showBusAnim, setShowBusAnim] = useState(false);
  const busAnimInterval = useRef<NodeJS.Timer>();
  const { setBackgroundAudio } = useAudio();

  // if current location id, then get position for scrolling
  const initialScrollCenter = useMemo(() => {
    if (!currentLocationId) return undefined;

    let locationData: ILocationData | undefined = undefined;

    mapData.some((zoneData) =>
      zoneData.locations.some((_locationData) => {
        const isMatch = _locationData.locationId === currentLocationId;

        if (isMatch) locationData = _locationData;

        return isMatch;
      })
    );

    if (locationData) {
      const { top, left } = (locationData as ILocationData).offset;

      return {
        x: left,
        y: top,
      };
    } else {
      return undefined;
    }
  }, [currentLocationId]);

  // disable music on modal close
  useEffect(() => {
    if (!isOpen) {
      setIsMusicPlaying(false);
      setBackgroundAudio();
    }
  }, [isOpen, setBackgroundAudio]);

  useUnmount(() => {
    if (busAnimInterval.current) {
      clearTimeout(busAnimInterval.current);
    }
  });

  const handleLocationSelect = (info: LocationInfo) => {
    setShowBusAnim(true);

    // Add small delay before changing the background scene to
    // allow transition animation to cover screen
    busAnimInterval.current = setTimeout(() => {
      setSelectedLocationId(info.id);
      handleVisitLocation?.(info.id);
    }, 1000);
  };

  const handleMusicToggle = () => {
    setBackgroundAudio(isMusicPlaying ? undefined : SeasonMapMusic.NewInTown);
    setIsMusicPlaying((oldVal) => !oldVal);
  };

  const handleBack = () => {
    if (selectedLocationId) {
      setShowBusAnim(true);

      busAnimInterval.current = setTimeout(() => {
        setSelectedLocationId(undefined);
      }, 1000);
    } else {
      handleClose();
    }
  };

  const handleBusAnimationEnd = useCallback(() => {
    setShowBusAnim(false);
  }, []);

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalContent borderRadius={0} m={0} p={0} maxW={pxToRem(windowWidth)}>
          {isOpen && (
            <ModalBody
              m={0}
              p={0}
              position="relative"
              bgColor={pannerBGFallbackColor}
              bgImage={PannerBGSVG}
              backgroundRepeat="no-repeat"
              backgroundSize="cover"
              backgroundPosition="center center"
            >
              <Season1MapHeader
                ticketCount={ticketCount}
                isMusicPlaying={isMusicPlaying}
                handleMusicToggle={handleMusicToggle}
                handleBack={handleBack}
              />

              {isLoading && (
                <Box
                  w="full"
                  h="full"
                  position="absolute"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <LoadingSpinner />
                </Box>
              )}

              {!isLoading && !selectedLocationId && (
                <SeasonMapPanner
                  initialScrollCenterPos={initialScrollCenter}
                  initialScale={windowWidth >= 768 ? 0.5 : 0.4}
                  width={windowWidth}
                  height={windowHeight}
                  mapHeight={mapSize.height}
                  mapWidth={mapSize.width}
                  bgImage={PannerBGSVG}
                >
                  <Season1Map
                    unlockedZoneIds={unlockedZoneIds}
                    locationsProgress={locationsProgress}
                    currentLocationId={currentLocationId}
                    userImg={userProfileImageUrl}
                    handleLocationSelect={handleLocationSelect}
                  />
                </SeasonMapPanner>
              )}
              {!isLoading && !!selectedLocationId && !!avatar && (
                <Season1Location
                  canUnlock={ticketCount > 0}
                  width={windowWidth}
                  height={windowHeight}
                  locationId={selectedLocationId}
                  handleRequestUnlock={handleRequestUnlock}
                  avatarAtlasUrl={avatarAtlasUrl}
                  avatarItemGroups={avatarItemGroups.filter(
                    (i) => i.season_location_id === selectedLocationId
                  )}
                  avatarItems={avatarItems.filter(
                    (i) => i.season_location_id === selectedLocationId
                  )}
                  avatarSkeletonUrl={avatarSkeletonUrl}
                  avatar={avatar}
                  isLoading={isUnlockLoading}
                  pathColor={
                    mapData.find((zone) => {
                      return zone.locations.some(
                        (l) => l.locationId === selectedLocationId
                      );
                    })?.pathColor || ""
                  }
                />
              )}
            </ModalBody>
          )}
        </ModalContent>
      </Modal>
      <Season1MapTransition
        show={showBusAnim}
        handleComplete={handleBusAnimationEnd}
      />
    </>
  );
};
