import {
  Box,
  BoxProps,
  Flex,
  GridItem,
  SimpleGrid,
  Square,
} from "@chakra-ui/react";
import React, { useMemo } from "react";

import { UpgradeCallToAction } from "adminComponents/atoms/UpgradeCallToAction";

interface IProps extends BoxProps {
  columns?: number;
  rows?: number;
  leftListRows?: number;
  upgradeText?: string;
}

export const SearchResultsPlaceholder: React.FC<IProps> = ({
  columns = 3,
  rows = 3,
  leftListRows = 0,
  upgradeText,
  ...rest
}) => {
  const totalItems = columns * rows;

  const leftListItems = useMemo(() => {
    return Array(leftListRows)
      .fill(0)
      .map((_, i) => {
        const randNumber = Math.floor(Math.random() * 40) + 1;
        const w = 65 - randNumber;

        return (
          <GridItem w="100%" h="100%" key={i}>
            <Flex h="100%" w="100%" alignItems="center">
              <Box
                bg="primary.light-gray"
                borderRadius="lg"
                h="65%"
                w={`${w}%`}
              />
            </Flex>
          </GridItem>
        );
      });
  }, [leftListRows]);

  return (
    <Box position="relative" w="100%" h="100%" {...rest}>
      {upgradeText && (
        <Box top="35%" w="100%" position="absolute">
          <UpgradeCallToAction upgradeText={upgradeText} />
        </Box>
      )}
      <Flex w="100%" h="100%">
        {leftListRows && (
          <SimpleGrid w="35%" columns={1} h="80%">
            {leftListItems}
          </SimpleGrid>
        )}

        <SimpleGrid w="100%" h="100%" columns={columns}>
          {Array(totalItems)
            .fill(0)
            .map((_, i) => {
              return (
                <GridItem w="100%" h="100%" key={i}>
                  <Square size="100%">
                    <Box
                      borderRadius="lg"
                      boxSize="90%"
                      bg="primary.light-gray"
                    />
                  </Square>
                </GridItem>
              );
            })}
        </SimpleGrid>
      </Flex>
    </Box>
  );
};
