import { Box, SimpleGrid, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { useSessionGroups } from "links/lib/contexts/sessionGroups";
import {
  IPracticeSetSessionItemMultipleChoiceChoice,
  PracticeSessionItemVariantType,
} from "links/lib/types";
import { Tooltip } from "sessionComponents/atoms/Tooltip";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { QuestionResponse } from "sessionComponents/molecules/QuestionResponse";
import { getFormattedGroupName } from "sessionComponents/utils/getFormattedGroupName";

interface IAwardedDrawingsProps {
  options: IPracticeSetSessionItemMultipleChoiceChoice[];
  teacherVote: string;
  studentVotes: string[];
}

export const AwardedDrawings: React.FC<IAwardedDrawingsProps> = ({
  options,
  teacherVote,
  studentVotes,
}) => {
  const {
    match: { fontSize, margin },
  } = useBreakpoints();
  const { t } = useTranslation("session", { useSuspense: false });
  const groups = useSessionGroups();
  const teacherAward = options.filter((opt) => opt.id === teacherVote)[0];
  const studentAwards = options.filter((opt) => studentVotes.includes(opt.id));

  return (
    <Stack direction={studentAwards.length > 1 ? "column" : "row"} w="full">
      {!!teacherAward && (
        <Box
          display="flex"
          flexDir="column"
          alignItems="center"
          justifyContent="center"
          w="full"
        >
          <Text
            textStyle="gameText"
            fontWeight={600}
            fontSize={pxToRem(fontSize)}
          >
            {t("roundReview.votingAwardsTeacherVoteTitle")}
          </Text>
          <Tooltip
            tooltipContent={getFormattedGroupName(groups[teacherAward.id])}
            placement="top"
          >
            <Box>
              <QuestionResponse
                variant={PracticeSessionItemVariantType.CoopDraw}
                imageUrl={teacherAward.image_url}
                imageAltText={teacherAward.image_alt_text}
                showAsSelectable={false}
              />
            </Box>
          </Tooltip>
        </Box>
      )}
      {!!studentAwards.length && (
        <Box
          display="flex"
          flexDir="column"
          alignItems="center"
          justifyContent="center"
          w="full"
          marginTop={pxToRem(margin)}
        >
          <Text
            textStyle="gameText"
            fontWeight={600}
            fontSize={pxToRem(fontSize)}
          >
            {t("roundReview.votingAwardsStudentVoteTitle")}
          </Text>
          <SimpleGrid
            columns={studentAwards.length > 2 ? 2 : 1}
            spacing={pxToRem(margin / 2)}
          >
            {studentAwards.map((opt) => {
              return (
                <Tooltip
                  tooltipContent={getFormattedGroupName(groups[opt.id])}
                  placement="top"
                  key={opt.id}
                >
                  <Box>
                    <QuestionResponse
                      key={opt.id}
                      variant={PracticeSessionItemVariantType.CoopDraw}
                      imageUrl={opt.image_url}
                      imageAltText={opt.image_alt_text}
                      showAsSelectable={false}
                    />
                  </Box>
                </Tooltip>
              );
            })}
          </SimpleGrid>
        </Box>
      )}
    </Stack>
  );
};
