import React, { useMemo, useRef } from "react";

import { EditPracticeSetMediaModalContextProvider } from "adminComponents/contexts/EditPracticeSetMediaModalContext";
import {
  CreateClassificationTabForm,
  CreateQuestionClassificationTab,
} from "adminComponents/molecules/CreateQuestionClassificationTab";
import {
  CreateDiagramTabForm,
  CreateQuestionDiagramTab,
} from "adminComponents/molecules/CreateQuestionDiagramTab";
import {
  CreateDrawTabForm,
  CreateQuestionDrawTab,
} from "adminComponents/molecules/CreateQuestionDrawTab";
import {
  CreateMultipleChoiceTabForm,
  CreateQuestionMultipleChoiceTab,
} from "adminComponents/molecules/CreateQuestionMultipleChoiceTab";
import {
  CreateNumberResponseTabForm,
  CreateQuestionNumberResponseTab,
} from "adminComponents/molecules/CreateQuestionNumberResponseTab";
import {
  CreateQuestionTermTab,
  CreateTermTabForm,
} from "adminComponents/molecules/CreateQuestionTermTab";
import {
  CreateQuestionTextResponseTab,
  CreateTextResponseTabForm,
} from "adminComponents/molecules/CreateQuestionTextResponseTab";
import { CreateQuestionFormDataType } from "adminComponents/utils";
import { QuestionType } from "links/lib/types";

import { CreateQuestionTabType } from "..";

export interface IQuestionTabProps {
  flyoutType: string;
  defaultValue: CreateQuestionFormDataType;
  isImageMode: boolean;
  hasFeedback: boolean;
  isSubmitting: boolean;
  isQuestionDataValid: boolean;
  showPremiumMarker?: boolean;
  feedbackRef: React.MutableRefObject<HTMLDivElement | null>;
  setTabValidation: (
    value: React.SetStateAction<Record<CreateQuestionTabType, boolean>>
  ) => void;
  setActiveTabIndex: (value: React.SetStateAction<number>) => void;
  handleUpdateTermTabData: (data: CreateTermTabForm, isValid: boolean) => void;
  handleUpdateMultipleChoiceTabData: (
    isValid: boolean,
    data?: CreateMultipleChoiceTabForm
  ) => void;
  handleUpdateDiagramTabData: (
    isValid: boolean,
    data?: CreateDiagramTabForm
  ) => void;
  handleUpdateDrawTabData: (isValid: boolean, data?: CreateDrawTabForm) => void;
  handleUpdateClassificationTabData: (
    isValid: boolean,
    data?: CreateClassificationTabForm
  ) => void;
  handleUpdateTextResponseTabData: (
    isValid: boolean,
    data?: CreateTextResponseTabForm
  ) => void;
  handleUpdateNumberResponseTabData: (
    isValid: boolean,
    data?: CreateNumberResponseTabForm
  ) => void;
  handleSaveAndClose: (data: CreateQuestionFormDataType) => void;
  handleDirty?: () => void;
  handleGenerateFeedback?: (theFormData: CreateQuestionFormDataType) => void;
  showGenerateFeedback?: boolean;
}

const getMediaModalValue = (
  flyoutType: string,
  value: CreateQuestionFormDataType
) => {
  let prop: keyof CreateQuestionFormDataType;

  switch (flyoutType) {
    case QuestionType.TermDefinition:
      prop = "term";
      break;
    case QuestionType.Classify:
      prop = "classify";
      break;
    case QuestionType.TextResponse:
      prop = "textResponse";
      break;
    case QuestionType.NumberResponse:
      prop = "numberResponse";
      break;
    case QuestionType.Draw:
      prop = "draw";
      break;
    case QuestionType.MultipleChoice:
    case QuestionType.MultipleSelect:
      prop = "multipleChoice";
      break;
    case QuestionType.Diagram:
      return {
        imageUrl: value.diagram?.imageUrl ?? "",
        altText: value.diagram?.imageAltText ?? "",
      };
    default:
      return undefined;
  }

  return {
    altText: value[prop]?.imageAltText ?? "",
    imageUrl: value[prop]?.imageUrl ?? "",
    audioUrl: value[prop]?.audioUrl ?? "",
    videoUrl: value[prop]?.videoUrl ?? "",
  };
};

export const QuestionTab: React.FC<IQuestionTabProps> = ({
  flyoutType,
  defaultValue,
  isImageMode,
  hasFeedback,
  isSubmitting,
  isQuestionDataValid,
  feedbackRef,
  setTabValidation,
  setActiveTabIndex,
  handleUpdateTermTabData,
  handleUpdateMultipleChoiceTabData,
  handleUpdateClassificationTabData,
  handleUpdateDiagramTabData,
  handleUpdateDrawTabData,
  handleUpdateNumberResponseTabData,
  handleUpdateTextResponseTabData,
  handleSaveAndClose,
  showPremiumMarker = true,
  handleDirty,
  handleGenerateFeedback,
  showGenerateFeedback,
}) => {
  const questionTypes = useMemo(() => [], []);
  const mediaModalDefaultValue = useRef(
    getMediaModalValue(flyoutType, defaultValue)
  );

  const firstTabElem: React.ReactElement =
    flyoutType === QuestionType.TermDefinition ? (
      <CreateQuestionTermTab
        canSubmit={isQuestionDataValid}
        isSubmitting={isSubmitting}
        hasFeedback={hasFeedback}
        handleChangeForm={handleUpdateTermTabData}
        handleNextTab={(validated) => {
          setTabValidation((prev) => ({ ...prev, Term: validated }));
          setActiveTabIndex(1);
          feedbackRef.current?.scrollIntoView();
        }}
        handleSaveAndClose={(data: CreateTermTabForm) =>
          handleSaveAndClose({ term: data })
        }
        defaultValue={defaultValue}
      />
    ) : flyoutType === QuestionType.MultipleChoice ||
      flyoutType === QuestionType.MultipleSelect ? (
      <CreateQuestionMultipleChoiceTab
        canSubmit={isQuestionDataValid}
        isSubmitting={isSubmitting}
        isImageMode={isImageMode}
        hasFeedback={hasFeedback}
        handleChangeForm={handleUpdateMultipleChoiceTabData}
        defaultValue={defaultValue}
        handleNextTab={() => {
          setActiveTabIndex(1);
          feedbackRef.current?.scrollIntoView();
        }}
        showSecondaryFooterCta={showGenerateFeedback}
        handleSecondaryNextTab={(theFormData: CreateQuestionFormDataType) => {
          setActiveTabIndex(1);
          feedbackRef.current?.scrollIntoView();
          handleGenerateFeedback?.(theFormData);
        }}
        handleSaveAndClose={(data: CreateMultipleChoiceTabForm) => {
          handleSaveAndClose({ multipleChoice: data });
        }}
        handleDirty={handleDirty}
      />
    ) : flyoutType === QuestionType.TextResponse ? (
      <CreateQuestionTextResponseTab
        canSubmit={isQuestionDataValid}
        isSubmitting={isSubmitting}
        hasFeedback={hasFeedback}
        handleChangeForm={handleUpdateTextResponseTabData}
        defaultValue={defaultValue}
        handleNextTab={() => {
          setActiveTabIndex(1);
          feedbackRef.current?.scrollIntoView();
        }}
        showSecondaryFooterCta={showGenerateFeedback}
        handleSecondaryNextTab={(theFormData: CreateQuestionFormDataType) => {
          setActiveTabIndex(1);
          feedbackRef.current?.scrollIntoView();
          handleGenerateFeedback?.(theFormData);
        }}
        handleSaveAndClose={(data: CreateTextResponseTabForm) =>
          handleSaveAndClose({ textResponse: data })
        }
        handleDirty={handleDirty}
      />
    ) : flyoutType === QuestionType.NumberResponse ? (
      <CreateQuestionNumberResponseTab
        canSubmit={isQuestionDataValid}
        isSubmitting={isSubmitting}
        hasFeedback={hasFeedback}
        handleChangeForm={handleUpdateNumberResponseTabData}
        defaultValue={defaultValue}
        handleNextTab={() => {
          setActiveTabIndex(1);
          feedbackRef.current?.scrollIntoView();
        }}
        showSecondaryFooterCta={showGenerateFeedback}
        handleSecondaryNextTab={(theFormData: CreateQuestionFormDataType) => {
          setActiveTabIndex(1);
          feedbackRef.current?.scrollIntoView();
          handleGenerateFeedback?.(theFormData);
        }}
        handleSaveAndClose={(data: CreateNumberResponseTabForm) =>
          handleSaveAndClose({ numberResponse: data })
        }
        questionTypes={questionTypes}
        handleDirty={handleDirty}
      />
    ) : flyoutType === QuestionType.Diagram ? (
      <CreateQuestionDiagramTab
        canSubmit={isQuestionDataValid}
        isSubmitting={isSubmitting}
        handleChange={handleUpdateDiagramTabData}
        defaultValue={defaultValue}
        hasFeedback={hasFeedback}
        handleNextTab={() => {
          setActiveTabIndex(1);
          feedbackRef.current?.scrollIntoView();
        }}
        handleSaveAndClose={(data: CreateDiagramTabForm) =>
          handleSaveAndClose({ diagram: data })
        }
        handleDirty={handleDirty}
      />
    ) : flyoutType === QuestionType.Draw ? (
      <CreateQuestionDrawTab
        canSubmit={isQuestionDataValid}
        isSubmitting={isSubmitting}
        hasFeedback={hasFeedback}
        handleChangeForm={handleUpdateDrawTabData}
        defaultValue={defaultValue}
        handleNextTab={() => {
          setActiveTabIndex(1);
          feedbackRef.current?.scrollIntoView();
        }}
        handleSaveAndClose={(data: CreateDrawTabForm) =>
          handleSaveAndClose({ draw: data })
        }
        handleDirty={handleDirty}
      />
    ) : flyoutType === QuestionType.Classify ? (
      <CreateQuestionClassificationTab
        canSubmit={isQuestionDataValid}
        isSubmitting={isSubmitting}
        isImageMode={isImageMode}
        hasFeedback={hasFeedback}
        handleChangeForm={handleUpdateClassificationTabData}
        defaultValue={defaultValue}
        handleNextTab={() => {
          setActiveTabIndex(1);
          feedbackRef.current?.scrollIntoView();
        }}
        handleSaveAndClose={(data: CreateClassificationTabForm) =>
          handleSaveAndClose({ classify: data })
        }
        handleDirty={handleDirty}
      />
    ) : (
      <></>
    );

  return (
    <EditPracticeSetMediaModalContextProvider
      showPremiumMarker={showPremiumMarker}
      defaultValue={mediaModalDefaultValue.current}
    >
      {firstTabElem}
    </EditPracticeSetMediaModalContextProvider>
  );
};
