export const getCalculatedServerTimeDelta = (
  serverTimeDeltas?: number[]
): number => {
  let calculatedDelta = 0;

  if (!serverTimeDeltas) {
    return calculatedDelta;
  }

  if (serverTimeDeltas.length === 1) {
    // So we can address any time skew as quick as possible
    calculatedDelta = serverTimeDeltas[0];
  } else if (serverTimeDeltas.length === 2) {
    // When we have 2, average to reduce impact of possible outlier
    calculatedDelta = (serverTimeDeltas[0] + serverTimeDeltas[1]) / 2;
  } else if (serverTimeDeltas.length > 2) {
    // When we've got 3+, take median to further reduce impact of outliers
    const orderedDeltas = [...serverTimeDeltas].sort(
      (deltaA, deltaB) => deltaA - deltaB
    );
    calculatedDelta = orderedDeltas[Math.floor(orderedDeltas.length / 2)];
  }

  return calculatedDelta;
};
