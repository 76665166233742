import { Table, Tbody, Td, Th, Tr } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { IFetchGeoResponse } from "links/lib/features/geo/useFetchGeo";

interface IProps {
  geo: IFetchGeoResponse;
}

export const GeoRegionTable: React.FC<IProps> = ({ geo }) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "geoDataTable",
  });

  return (
    <Table>
      <Tbody>
        <Tr>
          <Th>{t("country")}</Th>
          <Td>{geo.country_short}</Td>
        </Tr>
        <Tr>
          <Th>{t("region")}</Th>
          <Td>{geo.region}</Td>
        </Tr>
        <Tr>
          <Th>{t("ipAddress")}</Th>
          <Td>{geo.ip_address}</Td>
        </Tr>
        {geo.status_code && (
          <Tr>
            <Th>{t("statusCode")}</Th>
            <Td>{geo.status_code}</Td>
          </Tr>
        )}
      </Tbody>
    </Table>
  );
};
