import React, { useEffect, useMemo, useState } from "react";
import { usePrevious } from "react-use";

import { useSessionGameState } from "links/lib/contexts/sessionGameState";
import { useSessionRoundState } from "links/lib/contexts/sessionRoundState";
import { QuestionType, SessionGameType } from "links/lib/types";
import { useStudent } from "sessionComponents/contexts/student";
import { useGroupAnswers } from "sessionComponents/hooks/useGroupAnswers";
import { NoOutcome } from "sessionComponents/molecules/NoOutcome";
import { StudentSessionSoundEffect } from "sessionComponents/types";
import { useSessionAudio } from "sharedComponents/contexts/sessionAudio";

import { StudentClassificationRoundReview } from "./StudentClassificationRoundReview";
import { StudentRoundReview } from "./StudentRoundReview";

export interface IStudentRoundReviewWrapperProps {
  isClassSession: boolean;
}

// This wrapper prevents round reviews mounting multiple times or showing old data
// due to a race condition where the round information changes before the scene transition completes
export const StudentRoundReviewWrapper: React.FC<
  IStudentRoundReviewWrapperProps
> = ({ isClassSession }) => {
  const roundState = useSessionRoundState();
  const { round_number } = roundState;
  const studentUser = useStudent();
  const groupAnswers = useGroupAnswers(studentUser);
  const { play: playAudio } = useSessionAudio();
  const [preventRender, setPreventRender] = useState(false);
  const [playedOutcomeAudio, setPlayedOutcomeAudio] = useState(false);
  const gameState = useSessionGameState();

  const studentAnswer = useMemo(() => {
    return groupAnswers.find((a) => a.user.id === studentUser.id);
  }, [studentUser.id, groupAnswers]);

  // Play correct/incorrect audio once outcomes are present (if individual session)
  useEffect(() => {
    if (!isClassSession && !playedOutcomeAudio) {
      // Don't play audio if the outcome for the student
      // is not available yet
      if (!studentAnswer?.hasOutcome) {
        return;
      }

      playAudio(
        studentAnswer?.isCorrect
          ? StudentSessionSoundEffect.Correct
          : StudentSessionSoundEffect.Incorrect
      );

      setPlayedOutcomeAudio(true);
    }
  }, [playAudio, playedOutcomeAudio, studentAnswer, isClassSession]);

  // If the round number has updated, then prevent updating the review
  // component with the new round information
  const prevRoundNumber = usePrevious(roundState.round_number) || 0;
  useEffect(() => {
    if (
      prevRoundNumber > 0 &&
      round_number > 0 &&
      prevRoundNumber < round_number
    ) {
      setPreventRender(true);
    }
  }, [round_number, prevRoundNumber]);

  if (preventRender || !studentAnswer?.hasOutcome) {
    return (
      <NoOutcome
        outerGame={gameState?.game_type || SessionGameType.TheBigBoard}
      />
    );
  }

  return roundState.practice_set_session_item.question_type ===
    QuestionType.Classify ? (
    <StudentClassificationRoundReview isClassSession={isClassSession} />
  ) : (
    <StudentRoundReview isClassSession={isClassSession} />
  );
};
