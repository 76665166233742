import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  VisuallyHidden,
} from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Dropdown, IOption } from "adminComponents/atoms/Dropdown";
import { Heading } from "adminComponents/atoms/Heading";
import { TabData, Tabs } from "adminComponents/atoms/Tabs";
import {
  IProps as LiveBannerProps,
  LiveClassroomBanner,
} from "adminComponents/molecules/LiveClassroomBanner";
import {
  IProps as NavigationProps,
  StudentNavigationHeader,
} from "adminComponents/molecules/StudentNavigationHeader";
import { TemplateWithCenteredHeroOneColumn } from "adminComponents/templates/TemplateWithCenteredHeroOneColumn";
import { pxToRem } from "adminComponents/utils";
import { IUseFetchClassroomAverageStandardAccuraciesResult } from "links/lib/features/classrooms/useFetchAverageStandardAccuracies";
import {
  IAssignment,
  IClassroom,
  IPendingSession,
  IPracticeSet,
  IPracticeSetSession,
  IStandard,
} from "links/lib/types";

import { MyClassAccuracyTab } from "./components/MyClassAccuracyTab";
import { MyClassPracticeTab } from "./components/MyClassPracticeTab";

// TODO: Pull IMyAssignment and the IMyPracticeSet type into the types file
export interface IMyAssignment {
  assignment: IAssignment;
  accuracy: number;
  rewardXp: number;
  isDueToday: boolean;
  completionDate?: string;
}
export interface IMyPracticeSet {
  practiceSet: IPracticeSet;
  rewardXp: number;
}

export interface IProps {
  navigationData: NavigationProps;
  liveBanners?: Array<Omit<LiveBannerProps, "handleJoinSession">>;
  myAssignments?: IMyAssignment[];
  myPracticeSets?: IMyPracticeSet[];
  classrooms: IClassroom[];
  classroomStandardAccuracies: IUseFetchClassroomAverageStandardAccuraciesResult;
  standards: IStandard[];
  activeClassroom?: IClassroom;
  isLoading: boolean;
  isClassroomLoading: boolean;
  hasCompletedAssignments: boolean;
  handleSelectClassroom: (classroom: IClassroom) => void;
  handleViewCompletedAssignments: () => void;
  handlePlayPracticeSet: (practiceSet: IPracticeSet) => void;
  handlePlayAssignment: (assignment: IAssignment) => void;
  handleJoinSession: (session: IPracticeSetSession) => void;
  pendingSession?: IPendingSession;
}

export const MyClassesScreen: React.FC<IProps> = ({
  navigationData,
  liveBanners,
  myAssignments,
  myPracticeSets,
  classrooms,
  classroomStandardAccuracies,
  activeClassroom,
  isLoading = false,
  isClassroomLoading = false,
  hasCompletedAssignments,
  standards,
  handleSelectClassroom,
  handlePlayAssignment,
  handlePlayPracticeSet,
  handleViewCompletedAssignments,
  pendingSession,
  handleJoinSession,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  const [tabIndex, setTabIndex] = useState(0);

  const classroomOptions: IOption[] = classrooms?.map((classroom) => ({
    value: classroom.id,
    label: classroom.name,
  }));

  const selectedClassroomOption: IOption | undefined = classroomOptions.find(
    (option) => option.value === activeClassroom?.id
  );

  const handleSelectClassroomOption = (newValue: IOption) => {
    const classroom = classrooms.find(
      (classroom) => classroom.id === newValue.value
    ) as IClassroom;
    handleSelectClassroom(classroom);
  };

  const renderNav = () => {
    return (
      <StudentNavigationHeader
        {...navigationData}
        variant="adminStudentNavigationHeader"
      />
    );
  };

  const renderTopContent = () => {
    if (liveBanners?.length) {
      return (
        <Flex
          marginBottom={[null, null, pxToRem(-24)]}
          direction="column"
          gap={pxToRem(32)}
        >
          {liveBanners.map((l) => (
            <LiveClassroomBanner
              key={l.session.id}
              {...l}
              handleJoinSession={handleJoinSession}
            />
          ))}
        </Flex>
      );
    }
  };

  const tabData: TabData[] = useMemo(() => {
    return [
      {
        label: t("myClassesScreen.practiceTab"),
        content: (
          <MyClassPracticeTab
            myAssignments={myAssignments}
            myPracticeSets={myPracticeSets}
            isClassroomLoading={isClassroomLoading}
            hasCompletedAssignments={hasCompletedAssignments}
            handleViewCompletedAssignments={handleViewCompletedAssignments}
            handlePlayPracticeSet={handlePlayPracticeSet}
            handlePlayAssignment={handlePlayAssignment}
            pendingSession={pendingSession}
          />
        ),
      },
      {
        label: t("myClassesScreen.standardsTab"),
        content: (
          <MyClassAccuracyTab
            standardAccuracies={classroomStandardAccuracies}
            standards={standards}
          />
        ),
      },
    ];
  }, [
    t,
    myAssignments,
    myPracticeSets,
    isClassroomLoading,
    hasCompletedAssignments,
    classroomStandardAccuracies,
    standards,
    handleViewCompletedAssignments,
    handlePlayPracticeSet,
    handlePlayAssignment,
    pendingSession,
  ]);

  return (
    <TemplateWithCenteredHeroOneColumn
      nav={renderNav()}
      isHeroShifted={false}
      isLoading={isLoading}
      heroContent={renderTopContent()}
    >
      <Box style={{ maxWidth: pxToRem(840), margin: "0 auto" }}>
        <Flex
          sx={{
            alignItems: { base: "flex-start", md: "center" },
            flexDirection: { base: "column", md: "row" },
          }}
        >
          <Heading
            variant="adminH5Bold"
            as="h1"
            mb={{ base: pxToRem(8), md: 0 }}
            mr={pxToRem(16)}
          >
            {`${t("myClassesScreen.selectLabel")}:`}
          </Heading>

          <Box
            sx={{
              flex: 1,
              width: "100%",
              maxWidth: { base: "100%", md: pxToRem(300) },
              backgroundColor: "white",
            }}
            zIndex="8"
          >
            <FormControl>
              <VisuallyHidden as="label">
                <FormLabel>{t("myClassesScreen.selectLabel")}</FormLabel>
              </VisuallyHidden>
              <Dropdown
                handleChange={(e) => handleSelectClassroomOption(e as IOption)}
                options={classroomOptions}
                value={selectedClassroomOption ? [selectedClassroomOption] : []}
              />
            </FormControl>
          </Box>
        </Flex>

        <Box mt={pxToRem(16)}>
          <Tabs
            variant="adminScreenTabs"
            tabData={tabData}
            tabIndex={tabIndex}
            handleChange={setTabIndex}
          />
        </Box>
      </Box>
    </TemplateWithCenteredHeroOneColumn>
  );
};
