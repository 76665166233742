import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  VStack,
} from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Accordion } from "adminComponents/atoms/Accordion";
import { Button } from "adminComponents/atoms/Button";
import { Checkbox } from "adminComponents/atoms/Checkbox";
import { Dropdown, IOption } from "adminComponents/atoms/Dropdown";
import { Dropzone } from "adminComponents/atoms/Dropzone";
import { FormErrorMessage } from "adminComponents/atoms/FormErrorMessage";
import { Icon } from "adminComponents/atoms/Icon";
import { Input } from "adminComponents/atoms/Input";
import { TabData, Tabs } from "adminComponents/atoms/Tabs";
import { Text } from "adminComponents/atoms/Text";
import { Textarea } from "adminComponents/atoms/Textarea";
import { pxToRem } from "adminComponents/utils";
import { IGradeLevel, QuestionType } from "links/lib/types";

export interface IInstantSetConfig {
  text?: string;
  sourceUrl?: string;
  gradeLevelId: string;
  file?: File;
  questionTypes?: Array<QuestionType>;
  questionCount?: number;
  questionExtraction?: boolean;
}

export interface IProps {
  defaultValue?: IInstantSetConfig;
  gradeLevels: Array<IGradeLevel>;
  handleGenerate: (config: IInstantSetConfig) => void;
}

export interface IFormInputs {
  text: string;
  sourceUrl: string;
  gradeLevelId: string;
  file?: File;
  tabIndex: number;
  questionTypes?: Array<QuestionType>;
  questionCount?: number;
  questionExtraction?: boolean;
}

const URL_REGEX =
  /(https?:\/\/)?([\w-])+\.{1}([a-zA-Z]{2,63})([/\w-]*)*\/?\??([^#\n\r]*)?#?([^\n\r]*)/gi;

// Identify the proper focused tab based on default value
const getDefaultTabIndex = (defaultValue?: IInstantSetConfig) => {
  if (defaultValue?.sourceUrl) return 1;
  else if (defaultValue?.file) return 2;
  else return 0;
};

const questionCountOptions: Array<IOption> = [5, 6, 7, 8, 9].map((num) => {
  return {
    label: num.toString(),
    value: num.toString(),
  };
});

export const InstantSetConfiguration: React.FC<IProps> = ({
  defaultValue,
  gradeLevels,
  handleGenerate,
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "instantSetConfiguration",
  });
  const { t: tCommon } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "common",
  });

  const questionTypeOptions: Array<IOption> = [
    {
      label: tCommon("classify"),
      value: QuestionType.Classify,
    },
    {
      label: tCommon("textResponse"),
      value: QuestionType.TextResponse,
    },
    {
      label: tCommon("multipleChoice"),
      value: QuestionType.MultipleChoice,
    },
  ];

  const [dropError, setDropError] = useState();

  const desiredQuestionTypeOptions = (): Array<IOption> => {
    const questionTypes = getValues("questionTypes");

    if (!questionTypes) return questionTypeOptions;

    return questionTypes
      .map((questionType) =>
        questionTypeOptions.find((option) => option.value === questionType)
      )
      .filter((a) => !!a) as Array<IOption>;
  };

  const setDesiredQuestionTypeOptions = (options: unknown) => {
    setValue(
      "questionTypes",
      (options as Array<IOption>).map((option) => option.value as QuestionType)
    );
  };

  const {
    register,
    setValue,
    getValues,
    control,
    handleSubmit,
    formState: { errors, isValid, dirtyFields },
  } = useForm<IFormInputs>({
    mode: "onChange",
    defaultValues: {
      tabIndex: getDefaultTabIndex(defaultValue),
      ...defaultValue,
    },
  });

  const gradeLevelOptions = useMemo(() => {
    return gradeLevels?.map((grade) => ({
      label: grade.grade_level,
      value: grade.id,
    }));
  }, [gradeLevels]);

  const _handleSubmit: SubmitHandler<IFormInputs> = ({
    text,
    sourceUrl,
    gradeLevelId,
    file,
    tabIndex,
    questionCount,
    questionExtraction,
  }) => {
    handleGenerate({
      text: tabIndex === 0 ? text : undefined,
      sourceUrl: tabIndex === 1 ? sourceUrl : undefined,
      file: tabIndex === 2 ? file : undefined,
      gradeLevelId,
      questionTypes: getValues("questionTypes"),
      questionCount,
      questionExtraction,
    });
  };

  const tabData: TabData[] = useMemo(() => {
    return [
      {
        label: t("textTabLabel"),
        icon: "search_preview",
        content: "",
      },
      {
        label: t("urlTabLabel"),
        icon: "link",
        content: "",
      },
      {
        label: t("fileTabLabel"),
        icon: "draft_outlined",
        content: "",
      },
    ];
  }, [t]);

  const tabIndex = getValues("tabIndex");

  return (
    <VStack
      as="form"
      w="full"
      spacing={pxToRem(40)}
      alignItems="flex-start"
      justifyContent="flex-start"
      onSubmit={handleSubmit(_handleSubmit)}
    >
      <Box w="full">
        <Tabs
          variant="adminFlyoutSmallTabs"
          tabData={tabData}
          tabIndex={getValues("tabIndex")}
          handleChange={(i) =>
            setValue("tabIndex", i, { shouldValidate: true, shouldDirty: true })
          }
        />
      </Box>
      <VStack
        alignItems="space-between"
        justifyContent="space-between"
        px={[
          "admin.flyout.mobileXPadding",
          null,
          "admin.flyout.desktopXPadding",
        ]}
        w="full"
        spacing={pxToRem(20)}
      >
        {/* Text Tab */}
        {tabIndex === 0 && (
          <FormControl
            isRequired
            isInvalid={!!errors.text && !!dirtyFields.text}
            variant="adminFormControl"
          >
            <FormLabel>{t("textInputLabel")}</FormLabel>
            <Textarea
              placeholder={t("textInputPlaceholder")}
              maxLength={16384}
              {...register("text", {
                validate: {
                  isRequired: (value) =>
                    getValues("tabIndex") !== 0 || !!value.trim(),
                },
              })}
            />
            <FormErrorMessage>{t("textInputErrorMessage")}</FormErrorMessage>
            <FormHelperText>{t("textInputHelperText")}</FormHelperText>
          </FormControl>
        )}

        {/* URL Tab */}
        {tabIndex === 1 && (
          <FormControl
            isRequired
            isInvalid={!!errors.sourceUrl && !!dirtyFields.sourceUrl}
            variant="adminFormControl"
          >
            <FormLabel>{t("urlInputLabel")}</FormLabel>
            <Input
              placeholder={t("urlInputPlaceholder")}
              {...register("sourceUrl", {
                pattern: URL_REGEX,
                validate: {
                  isRequired: (value) =>
                    getValues("tabIndex") !== 1 || !!value.trim(),
                },
              })}
            />
            <FormErrorMessage>{t("urlInputErrorMessage")}</FormErrorMessage>
          </FormControl>
        )}

        {/* File Tab */}
        {tabIndex === 2 && (
          <FormControl
            isRequired
            variant="adminFormControl"
            isInvalid={!!dropError}
          >
            <FormLabel>{t("fileInputLabel")}</FormLabel>
            <Controller
              control={control}
              name="file"
              rules={{ required: true }}
              render={({ field: { onChange, value, onBlur } }) => (
                <Dropzone
                  sx={{ maxH: pxToRem(200) }}
                  maxFiles={1}
                  accept={[
                    ".png",
                    ".gif",
                    ".jpg",
                    ".jpeg",
                    ".docx",
                    ".pdf",
                    ".html",
                    ".htm",
                    ".txt",
                  ]}
                  multiple={false}
                  isLoading={false}
                  error={errors.file?.message}
                  handleDropRejected={() => {
                    setDropError(t("dropRejectedError"));
                  }}
                  handleDrop={(acceptedFiles) => {
                    setDropError(undefined);
                    onChange(acceptedFiles[0]);
                    onBlur();
                  }}
                >
                  <Text
                    as="span"
                    variant="adminH6"
                    display="block"
                    w="full"
                    textAlign="center"
                  >
                    {value ? value.name : t("fileInputDropzoneLabel")}
                  </Text>
                </Dropzone>
              )}
            />

            <FormHelperText>{t("fileInputHelperText")}</FormHelperText>
            <FormErrorMessage>{dropError}</FormErrorMessage>
          </FormControl>
        )}

        <FormControl isRequired variant="adminFormControl">
          <FormLabel>{t("gradeInputLabel")}</FormLabel>
          <Controller
            control={control}
            name="gradeLevelId"
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value, name } }) => (
              <Dropdown
                options={gradeLevelOptions}
                value={{
                  label:
                    gradeLevelOptions.find((s) => s.value === value)?.label ||
                    "",
                  value,
                }}
                handleBlur={onBlur}
                handleChange={(option: unknown) => {
                  const opt = option as IOption;
                  onChange(opt.value);
                }}
                id={name}
              />
            )}
          />
          <FormHelperText>{t("gradeInputHelperText")}</FormHelperText>
        </FormControl>

        {/* Advanced Options */}
        <Accordion
          permitOverflow={true}
          accordions={[
            {
              title: t("advancedOptionsLabel"),
              content: (
                <VStack>
                  <FormControl variant="adminFormControl">
                    <FormLabel>{t("questionTypesLabel")}</FormLabel>
                    <Controller
                      control={control}
                      name="questionTypes"
                      render={() => (
                        <Dropdown
                          options={questionTypeOptions}
                          value={desiredQuestionTypeOptions()}
                          handleChange={setDesiredQuestionTypeOptions}
                          isMulti={true}
                        ></Dropdown>
                      )}
                    />
                    <FormHelperText>
                      {t("questionTypesHelperText")}
                    </FormHelperText>
                  </FormControl>

                  <FormControl variant="adminFormControl">
                    <FormLabel>{t("questionCountLabel")}</FormLabel>
                    <Controller
                      control={control}
                      name="questionCount"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <Dropdown
                            options={questionCountOptions}
                            value={questionCountOptions.find(
                              (option) => option.value === value?.toString()
                            )}
                            handleChange={(option: unknown) => {
                              onChange((option as IOption).value);
                            }}
                          ></Dropdown>
                        );
                      }}
                    />
                    <FormHelperText>
                      {t("questionCountHelperText")}
                    </FormHelperText>
                  </FormControl>

                  <FormControl variant="adminFormControl">
                    <FormLabel>{t("questionExtractionLabel")}</FormLabel>
                    <Controller
                      control={control}
                      name="questionExtraction"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <Checkbox isChecked={value} onChange={onChange}>
                            {t("questionExtractionCheckboxLabel")}
                          </Checkbox>
                        );
                      }}
                    />
                    <FormHelperText>
                      {t("questionExtractionHelperText")}
                    </FormHelperText>
                  </FormControl>
                </VStack>
              ),
            },
          ]}
        />

        <Box py={pxToRem(20)}>
          <Button
            isDisabled={!isValid}
            type="submit"
            variant="adminButtonFilled"
            rightIcon={
              <Icon
                icon="arrow_forward"
                sx={{
                  width: pxToRem(16),
                  height: pxToRem(16),
                }}
              />
            }
          >
            {t("submitButton")}
          </Button>
        </Box>
      </VStack>
    </VStack>
  );
};
