import { Box, Text, useToken } from "@chakra-ui/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { pxToRem } from "../../../adminComponents/utils/pxToRem";
import { useSessionServerTimeDeltas } from "links/lib/contexts/sessionServerTimeDeltas";
import { SessionGameType } from "links/lib/types";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { ConfirmButton } from "sessionComponents/molecules/ConfirmButton";
import { getCalculatedServerTimeDelta } from "sessionComponents/utils/getCalculatedServerTimeDelta";

import { Tooltip } from "../Tooltip";
import TimerMaskSVG from "./resource/timer_mask.svg";

const getServerTimeDelta = (serverTimeDeltas: number[]): number => {
  let calculatedDelta = getCalculatedServerTimeDelta(serverTimeDeltas);

  // if delta is < 2 seconds, don't use it
  if (Math.abs(calculatedDelta) < 2000) {
    calculatedDelta = 0;
  }

  return calculatedDelta;
};

interface ITimerProps {
  // the time the timer started at represented as date string
  startsAt: string;
  // the time the timer will end at represented as date string
  endsAt: string;
  // the font size of the timer countdown
  fontSize?: string;
  // the overall timer size
  boxSize?: string;
  onExtendRoundTime?: () => void;
  showExtendPopover?: boolean;
  outerGame?: SessionGameType;
}

const EXPLICIT_TIME_SECONDS = 10;

const Timer: React.FC<ITimerProps> = ({
  startsAt,
  endsAt,
  boxSize = pxToRem(40),
  fontSize = pxToRem(16),
  onExtendRoundTime,
  showExtendPopover = false,
  outerGame = SessionGameType.TheBigBoard,
}) => {
  const startsAtUnix = Date.parse(startsAt);
  const endsAtUnix = Date.parse(endsAt);
  const primaryGoldenColor = useToken("colors", "primary.golden");
  const primaryAnjouColor = useToken("colors", "primary.anjou");
  const [secondsLeft, setSecondsLeft] = useState(
    Math.ceil(Math.max(endsAtUnix - startsAtUnix, 0))
  );
  const animationFrameRef = useRef<number | null>(null);
  const pieElemRef = useRef<HTMLDivElement | null>(null);
  const timerRef = useRef<HTMLDivElement | null>(null);

  const serverTimeDeltas = useSessionServerTimeDeltas() || [];

  const { match: currentBreakpoints } = useBreakpoints();

  const calculatedServerTimeDelta = getServerTimeDelta(serverTimeDeltas);

  const { t } = useTranslation("session", {
    keyPrefix: "timer",
    useSuspense: false,
  });

  // RAF handler that computes the latest progress of the timer based on props.
  // If timer is below EXPLICIT_TIME_SECONDS, will update `secondsLeft` state variable to
  // enable color effects and timer transitions.
  const handleAnimationStep = useCallback(() => {
    const timestamp = Date.now() - calculatedServerTimeDelta;
    // check if timer has started
    const hasTimerStarted = timestamp >= startsAtUnix;
    // if the timer has not started, then just show full progress timer
    const newSecondsLeftRaw =
      (endsAtUnix - (hasTimerStarted ? timestamp : startsAtUnix)) / 1000;
    const newSecondsLeft = Math.ceil(Math.max(newSecondsLeftRaw, 0));
    const totalRoundTime = (endsAtUnix - startsAtUnix) / 1000;

    const progress = (newSecondsLeftRaw / totalRoundTime) * 100;

    // update progress property of pie timer
    if (pieElemRef.current) {
      pieElemRef.current.style.setProperty("--progress", progress + "%");
    }
    if (timerRef.current)
      timerRef.current.setAttribute(
        "aria-label",
        t("secondsLeft", { seconds: newSecondsLeft })
      );

    if (newSecondsLeft <= EXPLICIT_TIME_SECONDS || showExtendPopover) {
      setSecondsLeft(newSecondsLeft);
    }

    // case where we have restarted timer due to serverTimeDelta Changes
    if (secondsLeft === 0 && newSecondsLeft > 1) {
      setSecondsLeft(newSecondsLeft);
    }

    // request animation if timer has not finished
    if (newSecondsLeft > 0) {
      animationFrameRef.current = requestAnimationFrame(handleAnimationStep);
    }
  }, [
    startsAtUnix,
    endsAtUnix,
    secondsLeft,
    calculatedServerTimeDelta,
    showExtendPopover,
    t,
  ]);

  // When start or end time change on timer, start a new animation
  useEffect(() => {
    if (!animationFrameRef.current) {
      animationFrameRef.current = requestAnimationFrame(handleAnimationStep);
    }

    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
        animationFrameRef.current = null;
      }
    };
  }, [startsAtUnix, endsAtUnix, handleAnimationStep]);

  // restart timer if calculatedServerTimeDelta changes
  // will stop itself if it doesn't need to keep going
  // the only time we'd be in this situation is if a change to device time moves it ahead
  // enough for secondsLeft to be 0 until calculatedServerTimeDelta gets applied
  useEffect(() => {
    if (secondsLeft === 0) {
      animationFrameRef.current = requestAnimationFrame(handleAnimationStep);
    }
  }, [calculatedServerTimeDelta, handleAnimationStep, secondsLeft]);

  // Update state variable when start or end time changes
  // to reset transition/color effects
  useEffect(() => {
    setSecondsLeft(Math.ceil(Math.max(endsAtUnix - startsAtUnix, 0)));
  }, [endsAtUnix, startsAtUnix]);

  const showExplicitTimer = secondsLeft <= EXPLICIT_TIME_SECONDS;

  let backgroundColor = "primary.golden-hover";
  if (secondsLeft <= 3) {
    backgroundColor = "utility.error";
  } else if (secondsLeft <= 5) {
    backgroundColor = "game.timer-red";
  }

  const tooltipContent = (
    // Provide a width just wide enough to keep tooltip popover from resizing when non monospace font changes with time left countdown
    <Box
      w={pxToRem(currentBreakpoints.padding * 8)}
      padding={pxToRem(currentBreakpoints.padding / 2)}
    >
      <Text
        color="white"
        fontStyle="normal"
        textStyle="gameDisplay"
        fontSize={pxToRem(currentBreakpoints.fontSize * 2)}
        paddingBottom={pxToRem(currentBreakpoints.padding / 2)}
      >
        {`${Math.floor(secondsLeft / 60)}:${
          secondsLeft % 60 < 10 ? `0${secondsLeft % 60}` : secondsLeft % 60
        }`}
      </Text>
      <ConfirmButton
        h={pxToRem(currentBreakpoints.buttonHeight)}
        w="100%"
        variant="buttonFilled"
        confirmedWhenChanged={[endsAt]}
        handleConfirmClick={onExtendRoundTime}
        fontSize={pxToRem(currentBreakpoints.fontSize)}
        disabled={secondsLeft > 295} //disallow spam if timer aleady pushed to max
      >
        {/* TODO Translate */}+ 30s
      </ConfirmButton>
    </Box>
  );

  return (
    <Tooltip
      isDisabled={!showExtendPopover}
      tooltipContent={tooltipContent}
      placement="auto"
    >
      <Box
        ref={timerRef}
        cursor={showExtendPopover ? "pointer" : undefined}
        role="timer"
        height={boxSize}
        width={boxSize}
        bg={backgroundColor}
        sx={{
          mask: `url(${TimerMaskSVG})`,
          maskSize: "100%",
          maskRepeat: "no-repeat",
        }}
        transition="background 500ms linear"
        position="relative"
        tabIndex={showExtendPopover ? 0 : undefined}
      >
        <Box
          ref={pieElemRef}
          bg={`conic-gradient(${
            outerGame === SessionGameType.QuickPlay
              ? primaryAnjouColor
              : primaryGoldenColor
          } var(--progress), transparent 0)`}
          width="full"
          height="full"
          position="absolute"
          top="0"
          left="0"
          opacity={showExplicitTimer ? "0" : "1"}
          transition="opacity 500ms linear"
        />
        {showExplicitTimer && (
          <Box
            w="full"
            h="full"
            position="absolute"
            top="0"
            left="0"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Text
              color="white"
              fontStyle="normal"
              textStyle="gameDisplay"
              fontSize={fontSize}
            >
              {Math.ceil(secondsLeft)}
            </Text>
          </Box>
        )}
      </Box>
    </Tooltip>
  );
};

export { Timer };
