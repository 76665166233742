import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useErrorToast } from "adminComponents/utils/toast";
import { useFetchOnDemandSessionPreview } from "links/lib/features/sessions";
import {
  IPracticeSetSession,
  IRemix,
  OnDemandIntent,
  RemixBGColorSchemeType,
  RemixIconType,
} from "links/lib/types";

export interface IArgs {
  liveSessionMap: { [key: string]: IPracticeSetSession };
}

export interface IResult {
  remixes: Array<IRemix>;
}

export const useRemixes = ({ liveSessionMap }: IArgs): IResult => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "studentHomepageContainer",
  });

  const fetchOnDemandSessionPreviews = useFetchOnDemandSessionPreview({
    on_demand_intent: OnDemandIntent.LS_V1,
  });
  useErrorToast(fetchOnDemandSessionPreviews.error);

  const fetchRedemptionSessionPreviews = useFetchOnDemandSessionPreview({
    on_demand_intent: OnDemandIntent.REDEMPTION,
  });
  useErrorToast(fetchRedemptionSessionPreviews.error);

  const fetchHowToPlaySessionPreviews = useFetchOnDemandSessionPreview({
    on_demand_intent: OnDemandIntent.HOW_TO_PLAY,
  });
  useErrorToast(fetchHowToPlaySessionPreviews.error);

  const fetchDigitalCitizenshipSessionPreviews = useFetchOnDemandSessionPreview(
    {
      on_demand_intent: OnDemandIntent.DIGITAL_CITIZENSHIP,
    }
  );
  useErrorToast(fetchDigitalCitizenshipSessionPreviews.error);

  const remixes: Array<IRemix> = useMemo(() => {
    const onDemand =
      fetchOnDemandSessionPreviews.data?.on_demand_session_previews ?? [];
    const redemption =
      fetchRedemptionSessionPreviews.data?.on_demand_session_previews ?? [];
    const howToPlay =
      fetchHowToPlaySessionPreviews.data?.on_demand_session_previews ?? [];
    const digitalCitizenship =
      fetchDigitalCitizenshipSessionPreviews.data?.on_demand_session_previews ??
      [];

    const onDemandRemixes: Array<IRemix> = onDemand.map((r) => {
      // TODO: How should we handle cover images as related to subjects
      let cover_image_icon: RemixIconType = "OTHER_REMIX";
      let cover_image_bg_color_scheme: RemixBGColorSchemeType = "OTHER";

      switch (r.subject_name.toLowerCase()) {
        case "math":
          cover_image_icon = "MATHEMATICS_REMIX";
          cover_image_bg_color_scheme = "MATHEMATICS";
          break;
        case "electives":
          cover_image_icon = "ELECTIVES_REMIX";
          cover_image_bg_color_scheme = "ELECTIVES";
          break;
        case "science":
          cover_image_icon = "SCIENCE_REMIX";
          cover_image_bg_color_scheme = "SCIENCE";
          break;
        case "english / language arts":
          cover_image_icon = "ENGLISH_REMIX";
          cover_image_bg_color_scheme = "ENGLISH";
          break;
        case "history / social studies":
          cover_image_icon = "HISTORY_REMIX";
          cover_image_bg_color_scheme = "HISTORY";
          break;
        case "visual & performing arts":
          cover_image_icon = "VISUAL_AND_PERFORMING_ARTS_REMIX";
          cover_image_bg_color_scheme = "VISUAL_AND_PERFORMING_ARTS";
          break;
        case "world languages":
          cover_image_icon = "WORLD_LANGUAGES_REMIX";
          cover_image_bg_color_scheme = "WORLD_LANGUAGES";
          break;
      }

      return {
        ...r,
        cover_image_icon,
        cover_image_bg_color_scheme,
        on_demand_intent: OnDemandIntent.LS_V1,
        is_session_active: !!liveSessionMap[r.session_id],
      };
    });

    const redemptionRemixes: Array<IRemix> = redemption.map((r) => {
      return {
        ...r,
        subject_name: t("redemptionRemix"),
        cover_image_icon: "REDEMPTION_REMIX",
        cover_image_bg_color_scheme: "REDEMPTION",
        on_demand_intent: OnDemandIntent.REDEMPTION,
        is_session_active: !!liveSessionMap[r.session_id],
      };
    });

    const howToPlayRemixes: Array<IRemix> = howToPlay.map((r) => {
      return {
        ...r,
        subject_name: r.practice_set_title || "",
        cover_image_icon: "OTHER_REMIX",
        cover_image_bg_color_scheme: "OTHER",
        on_demand_intent: OnDemandIntent.HOW_TO_PLAY,
        is_session_active: !!liveSessionMap[r.session_id],
      };
    });

    const digitalCitizenshipRemixes: Array<IRemix> = digitalCitizenship.map(
      (r) => {
        return {
          ...r,
          subject_name: r.practice_set_title || "",
          cover_image_icon: "OTHER_REMIX",
          cover_image_bg_color_scheme: "OTHER",
          on_demand_intent: OnDemandIntent.DIGITAL_CITIZENSHIP,
          is_session_active: !!liveSessionMap[r.session_id],
        };
      }
    );

    onDemandRemixes.sort((a, b) => {
      if (a.practice_set_id && a.practice_set_id !== "0") {
        return -1;
      } else if (a.is_session_active && !b.is_session_active) {
        return -1;
      } else if (!a.is_session_active && b.is_session_active) {
        return 1;
      } else if (a.new_session_permitted && !b.new_session_permitted) {
        return -1;
      } else if (b.new_session_permitted && !a.new_session_permitted) {
        return 1;
      } else if (a.maximum_xp > b.maximum_xp) {
        return -1;
      } else if (b.maximum_xp > a.maximum_xp) {
        return 1;
      } else {
        return 0;
      }
    });

    const completedRedemptionRemixes: Array<IRemix> = [];
    const incompleteRedemptionRemixes: Array<IRemix> = [];

    redemptionRemixes.forEach((r) => {
      if (!r.is_session_active && !r.new_session_permitted) {
        completedRedemptionRemixes.push(r);
      } else {
        incompleteRedemptionRemixes.push(r);
      }
    });

    // Order remixes: how to play, incomplete redemption, incomplete subject-based, complete subject-based, complete redemption
    return howToPlayRemixes
      .concat(digitalCitizenshipRemixes)
      .concat(incompleteRedemptionRemixes)
      .concat(onDemandRemixes)
      .concat(completedRedemptionRemixes);
  }, [
    fetchHowToPlaySessionPreviews.data?.on_demand_session_previews,
    fetchOnDemandSessionPreviews.data?.on_demand_session_previews,
    fetchRedemptionSessionPreviews.data?.on_demand_session_previews,
    fetchDigitalCitizenshipSessionPreviews.data?.on_demand_session_previews,
    liveSessionMap,
    t,
  ]);

  return { remixes };
};
