import { useMemo } from "react";
import { useQueries } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import {
  IUseFetchClassroomAverageStandardAccuraciesResult,
  fetchClassroomStandardAccuraciesQuery,
} from "./useFetchAverageStandardAccuracies";

export interface IUseFetchAverageStandardAccuraciesBulkProps {
  classroom_ids?: Array<string>;
  user_id?: string;
  refetchOnWindowFocus?: boolean;
}

export interface IUseFetchAverageStandardAccuraciesBulkResult {
  isLoading: boolean;
  accuracyData: {
    [classroom_id: string]: IUseFetchClassroomAverageStandardAccuraciesResult;
  };
}

const useFetchAverageStandardAccuraciesBulk = ({
  classroom_ids = [],
  user_id,
  refetchOnWindowFocus = true,
}: IUseFetchAverageStandardAccuraciesBulkProps): IUseFetchAverageStandardAccuraciesBulkResult => {
  const axios = useAxios();

  const accuracyQueries = useQueries(
    classroom_ids.map((classroom_id) => {
      const params = {
        classroom_id,
        user_id,
      };

      return {
        queryKey: ["standard-accuracies", params],
        queryFn: fetchClassroomStandardAccuraciesQuery(axios, params),
        refetchOnWindowFocus,
      };
    })
  );

  const accuracyData = useMemo(() => {
    const accuracyByClassroom: {
      [classroom_id: string]: IUseFetchClassroomAverageStandardAccuraciesResult;
    } = {};

    accuracyQueries.forEach((q, i) => {
      accuracyByClassroom[classroom_ids[i]] = q.data || {
        average_standard_accuracies: [],
      };
    });

    return accuracyByClassroom;
  }, [accuracyQueries, classroom_ids]);

  return {
    isLoading: accuracyQueries.some((q) => q.isLoading),
    accuracyData,
  };
};

export default useFetchAverageStandardAccuraciesBulk;
