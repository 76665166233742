//ws.state.session
import React, { createContext, useContext, useMemo } from "react";

import { WebSocketSession } from "../types";

export const SessionContext = createContext<WebSocketSession | undefined>(
  undefined
);

export interface ISessionProviderProps {
  wsSession?: WebSocketSession;
}

export const SessionProvider: React.FC<ISessionProviderProps> = ({
  children,
  wsSession,
}) => {
  const value = useMemo(() => wsSession, [wsSession]);
  return (
    <SessionContext.Provider value={value}>{children}</SessionContext.Provider>
  );
};

export const useSession = (): WebSocketSession | undefined => {
  return useContext(SessionContext);
};
