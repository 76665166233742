import { Box, BoxProps, VisuallyHidden } from "@chakra-ui/react";
import React from "react";

import { ColorScheme } from "adminComponents";

import { ReactComponent as DividerSvg } from "./resource/divider.svg";

interface IProps extends Omit<BoxProps, "color"> {
  color: ColorScheme;
}

const Divider: React.FC<IProps> = ({ color, ...props }) => (
  <Box {...props} color={color}>
    <VisuallyHidden>divider</VisuallyHidden>
    <DividerSvg aria-label="divider" />
  </Box>
);

export { Divider };
