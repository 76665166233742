import { UseMutationResult, useMutation } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { ILLMPracticeSetItem, IPracticeSetItem } from "../../types";

export interface IParseLLMPracticeSetItemsRequest {
  items: ILLMPracticeSetItem[];
}

export interface IParseLLMPracticeSetItemsResponse {
  practice_set_items: IPracticeSetItem[];
}

export interface IParseLLMPracticeSetItemsParams {
  onSuccess?: (data: IParseLLMPracticeSetItemsResponse) => void;
  onError?: () => void;
  onSettled?: () => void;
}

export function useParseLLMPracticeSetItems({
  onSettled,
  onError,
  onSuccess,
}: IParseLLMPracticeSetItemsParams): UseMutationResult<
  IParseLLMPracticeSetItemsResponse,
  unknown,
  IParseLLMPracticeSetItemsRequest
> {
  const axios = useAxios();

  const parseItems = async (args: IParseLLMPracticeSetItemsRequest) => {
    const response = await axios({
      method: "post",
      url: `/v1/llm/practice-sets/items/parse`,
      data: {
        ...args,
      },
    }).then((r) => r.data as IParseLLMPracticeSetItemsResponse);

    return response;
  };

  const mutation = useMutation(parseItems, {
    onSettled,
    onSuccess,
    onError,
  });

  return mutation;
}
