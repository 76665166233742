import { pxToRem } from "adminComponents/utils/pxToRem";

export const Slider = {
  variants: {
    gameBlue: {
      container: {},
      track: {
        bg: "primary.light-gray",
        height: pxToRem(9),
      },
      thumb: {
        bg: "white",
        borderColor: "utility.link",
        borderWidth: pxToRem(3),
        width: pxToRem(30),
        height: pxToRem(30),
      },
      filledTrack: {
        bg: "utility.link",
      },
    },

    gameWhite: {
      container: {},
      track: {
        bg: "primary.dark-gray",
        height: pxToRem(9),
      },
      thumb: {
        bg: "white",
        borderColor: "primary.dark-gray",
        borderWidth: pxToRem(3),
        width: pxToRem(30),
        height: pxToRem(30),
      },
      filledTrack: {
        bg: "white",
      },
    },
  },
};
