import { Box, Flex, Wrap } from "@chakra-ui/react";
import React, { MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Divider } from "adminComponents/atoms/Divider";
import { Heading } from "adminComponents/atoms/Heading";
import { Icon } from "adminComponents/atoms/Icon";
import { Tag } from "adminComponents/atoms/Tag";
import { Text } from "adminComponents/atoms/Text";
import registerObserver from "adminComponents/utils/PracticeSetQuestionListControlsFooterObserver";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { IPracticeSet } from "links/lib/types";

export interface IProps {
  handleAddQuestion?: MouseEventHandler;
  handleImportQuestions?: MouseEventHandler;
  handleReorderQuestions?: MouseEventHandler;
  handleSaveOrder?: MouseEventHandler;
  handleCancelOrder?: MouseEventHandler;
  questionCount: IPracticeSet["item_count"];
  standardsCount: number;
  feedbackCount: number;
  hideControls?: boolean;
  showReorderControls?: boolean;
  isReorderLoading?: boolean;
}

export const PracticeSetQuestionListControls: React.FC<IProps> = ({
  handleAddQuestion,
  handleImportQuestions,
  handleReorderQuestions,
  handleSaveOrder,
  handleCancelOrder,
  questionCount,
  standardsCount,
  feedbackCount,
  hideControls,
  showReorderControls,
  isReorderLoading,
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });

  setTimeout(
    () =>
      registerObserver(
        ".admin-practiceSetQuestionListControls-observerTrigger"
      ),
    0
  );

  return (
    <Box className="admin-practiceSetQuestionListControls-observerTrigger">
      <Flex
        width="100%"
        justify="space-between"
        wrap="wrap"
        direction={{ base: "column", md: "row" }}
        gap={pxToRem(16)}
      >
        <Heading as="h2" variant="adminH3">
          {t("practiceSet.labelQuestions")} ({questionCount})
        </Heading>
        <Flex
          align={{ md: "center" }}
          direction={{ base: "column", md: "row" }}
          gap={{ base: pxToRem(8), md: pxToRem(16) }}
          marginBottom={{ base: pxToRem(16), md: pxToRem(20) }}
        >
          <Text variant="adminMeta" color="primary.dark-gray">
            {t("practiceSet.labelIncludes")}:
          </Text>
          <Wrap>
            <Tag
              tooltip={t("practiceSet.tooltipFeedback")}
              variant="adminOutline"
              colorScheme="tan"
            >
              <>
                {feedbackCount}/{questionCount} {t("common.feedback")}
              </>
            </Tag>
            <Tag
              tooltip={t("practiceSet.tooltipStandards")}
              variant="adminOutline"
              colorScheme="tan"
            >
              <>
                {standardsCount}/{questionCount} {t("common.standards")}
              </>
            </Tag>
          </Wrap>
        </Flex>
      </Flex>
      <Divider
        marginBottom={{ base: pxToRem(32), md: pxToRem(20) }}
        color="primary.tan"
      />
      {!hideControls && (
        <Box
          width="100%"
          gap={pxToRem(8)}
          display={{ base: "grid", md: "flex" }}
          gridTemplateColumns="1fr 1fr"
        >
          {showReorderControls && (
            <Button
              variant="adminButtonFilled"
              aria-label={t("common.save")}
              onClick={handleSaveOrder}
            >
              {t("common.save")}
            </Button>
          )}
          {!showReorderControls && (
            <>
              <Button
                variant="adminButtonFilled"
                aria-label={t("practiceSet.ariaLabelAdd")}
                onClick={handleAddQuestion}
                leftIcon={<Icon icon="add" />}
              >
                {t("practiceSet.labelAdd")}
              </Button>

              <Button
                variant="adminButtonFilled"
                aria-label={t("practiceSet.ariaLabelImportQuestions")}
                onClick={handleImportQuestions}
                leftIcon={<Icon icon="upload" />}
              >
                {t("practiceSet.labelImportQuestions")}
              </Button>
            </>
          )}
          <Button
            variant="adminButtonOutlined"
            aria-label={
              showReorderControls
                ? t("common.cancel")
                : t("practiceSet.ariaLabelReorder")
            }
            isLoading={isReorderLoading}
            onClick={
              showReorderControls ? handleCancelOrder : handleReorderQuestions
            }
            {...(!showReorderControls
              ? { leftIcon: <Icon icon="reorder" /> }
              : {})}
          >
            {showReorderControls
              ? t("common.cancel")
              : t("practiceSet.labelReorder")}
          </Button>
        </Box>
      )}
    </Box>
  );
};
