import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { useAxios } from "../../hooks/useAxios";

export interface IApprovePendingClassroomStudentArgs {
  id: string;
  classroom_id: string;
}

export interface IUseApprovePendingClassroomStudentArgs {
  onSuccess?: () => void;
}

export default function useApprovePendingClassroomStudent({
  onSuccess,
}: IUseApprovePendingClassroomStudentArgs): UseMutationResult<
  unknown,
  unknown,
  IApprovePendingClassroomStudentArgs
> {
  const axios = useAxios();
  const queryClient = useQueryClient();

  const approve = async (args: IApprovePendingClassroomStudentArgs) => {
    const { id, classroom_id } = args;

    const response = await axios({
      method: "post",
      url: `/v1/groups/users/pending/${id}`,
      data: {},
    }).then((r) => {
      return r.data as unknown;
    });

    queryClient.invalidateQueries(["classroom-users", { id: classroom_id }], {
      exact: false,
    });

    // Make sure the user re-fetches reporting data as it might
    // have changed as a result of the user joining the group
    queryClient.invalidateQueries(["set-accuracies"], {
      exact: false,
    });
    queryClient.invalidateQueries(["practice-set-item-accuracies"], {
      exact: false,
    });
    queryClient.invalidateQueries(["standard-accuracies"], {
      exact: false,
    });
    queryClient.invalidateQueries(["user-accuracies"], {
      exact: false,
    });
    queryClient.invalidateQueries(["practiceSetAccuracies"], {
      exact: false,
    });

    queryClient.invalidateQueries(
      ["pending-classroom-students", { classroom_id }],
      {
        exact: false,
      }
    );

    return response;
  };

  const mutation = useMutation(approve, { onSuccess });

  return mutation;
}
