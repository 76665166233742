import { hoverStyles, pxToRem } from "adminComponents/utils";

const baseAdminMenuStyles = {
  list: {
    borderColor: "primary.tan",
    borderStyle: "solid",
    borderWidth: `${pxToRem(3)}`,
    boxShadow: `0 ${pxToRem(8)} ${pxToRem(10)} rgba(0, 0, 0, 0.08)`,
    borderRadius: pxToRem(10),
    pt: pxToRem(10),
    mt: pxToRem(-4),
  },
  item: {
    bg: "primary.white",
    color: "primary.dark-gray",
    height: pxToRem(40),
    pl: pxToRem(18),
    _hover: hoverStyles({
      bg: "primary.golden-light",
      color: "primary.warm-black",
    }),
    _focus: {
      bg: "primary.white",
      color: "primary.dark-gray",
    },
    _focusVisible: {
      bg: "primary.golden-light",
      color: "primary.warm-black",
    },
  },
};

export const Menu = {
  variants: {
    adminDropdown: baseAdminMenuStyles,
    adminTextButtonDropdown: {
      ...baseAdminMenuStyles,
      button: {
        color: "utility.link",
        textDecoration: "underline",
        _hover: hoverStyles({
          color: "utility.link-hover",
        }),
        _focus: {
          boxShadow: "none",
        },
        _focusVisible: {
          borderRadius: pxToRem(4),
          outline: `${pxToRem(1)} solid`,
          outlineColor: "utility.focus",
        },
      },
    },
  },
};
