import { UserRole } from "links/lib/types";

// eslint-disable-next-line
export const initESW = function (gslbBaseURL, role) {
  window.embedded_svc.settings.displayHelpButton = true; //Or false
  window.embedded_svc.settings.language = ""; //For example, enter 'en' or 'en-US'
  //window.embedded_svc.settings.defaultMinimizedText = '...'; //(Defaults to Chat with an Expert)
  //window.embedded_svc.settings.disabledMinimizedText = '...'; //(Defaults to Agent Offline)
  //window.embedded_svc.settings.loadingText = ''; //(Defaults to Loading)
  //window.embedded_svc.settings.storageDomain = 'yourdomain.com'; //(Sets the domain for your deployment so that visitors can navigate subdomains during a chat session)
  // Settings for Chat
  //window.embedded_svc.settings.directToButtonRouting = function(prechatFormData) {
  // Dynamically changes the button ID based on what the visitor enters in the pre-chat form.
  // Returns a valid button ID.
  //};
  //window.embedded_svc.settings.prepopulatedPrechatFields = {}; //Sets the auto-population of pre-chat form fields
  //window.embedded_svc.settings.fallbackRouting = []; //An array of button IDs, user IDs, or userId_buttonId
  //window.embedded_svc.settings.offlineSupportMinimizedText = '...'; //(Defaults to Contact Us)
  window.embedded_svc.settings.enabledFeatures = ["LiveAgent"];
  window.embedded_svc.settings.entryFeature = "LiveAgent";
  window.embedded_svc.init(
    "https://goguardian.my.salesforce.com",
    "https://goguardian.secure.force.com/supportpearville",
    gslbBaseURL,
    "00D41000000GNzs",
    "Playtime_Chat",
    {
      baseLiveAgentContentURL:
        role === UserRole.Admin
          ? "https://c.la1-core1.sfdc-8tgtt5.salesforceliveagent.com/content"
          : "https://c.la3-c1-ph2.salesforceliveagent.com/content",
      deploymentId: "5724N000000CocW",
      buttonId: "5734N000000LEOB",
      baseLiveAgentURL:
        role === UserRole.Admin
          ? "https://d.la1-core1.sfdc-8tgtt5.salesforceliveagent.com/chat"
          : "https://d.la3-c1-ph2.salesforceliveagent.com/chat",
      eswLiveAgentDevName:
        "EmbeddedServiceLiveAgent_Parent04I4N000000003eUAA_183a40fe994",
      isOfflineSupportEnabled: false,
    }
  );
};

// eslint-disable-next-line
export const insertChatbox = function (role) {
  if (!role) return;

  const style = `
    .embeddedServiceHelpButton .helpButton .uiButton, .minimizedContainer.helpButton {
      background-color: #006D77 !important;
      font-family: “Arial”, sans-serif !important;
    }
    .startButton {
      background-color: #006D77 !important;
      border-radius: 50px !important;
    }
    .chatHeaderBranding {
      background-color: #006D77 !important;
    }
  `;

  const css = document.createElement("style");
  css.setAttribute("type", "text/css");
  css.textContent = style;
  document.head.appendChild(css);

  if (!window.embedded_svc) {
    const s = document.createElement("script");
    s.setAttribute(
      "src",
      "https://goguardian.my.salesforce.com/embeddedservice/5.0/esw.min.js"
    );
    s.onload = function () {
      initESW(null);
    };
    document.body.appendChild(s);
  } else {
    initESW("https://service.force.com", role);
  }
};

// eslint-disable-next-line
export const removeChatbox = function () {
  const chatbox = document.querySelector(".embeddedServiceHelpButton");
  if (chatbox) {
    chatbox.remove();
  }
};
