import { UseMutationResult, useMutation } from "react-query";

import { useAxios } from "../../hooks/useAxios";

export interface IDeferAgreementArgs {
  agreement_id: string;
}

export interface IUseDeferAgreementProps {
  onSuccess?: () => void;
  onError?: () => void;
  onSettled?: () => void;
}

export default function useDeferAgreement(
  props?: IUseDeferAgreementProps
): UseMutationResult<unknown, unknown, IDeferAgreementArgs> {
  const axios = useAxios();

  const mutateAgreementDeferral = async (args: IDeferAgreementArgs) => {
    const response = await axios
      .put(`/v1/agreements/${args.agreement_id}/defer`)
      .then((r) => r.data);
    return response;
  };

  const mutation = useMutation(mutateAgreementDeferral, {
    onError: props?.onError,
    onSuccess: props?.onSuccess,
    onSettled: props?.onSettled,
  });

  return mutation;
}
