import { Box, Center } from "@chakra-ui/react";
import { debounce } from "lodash";
import React, { useEffect, useLayoutEffect, useRef } from "react";
import { useLifecycles, useMeasure } from "react-use";

import { Manager, PenTool } from "lib/pkg/paintbricks";
import { IStroke } from "lib/pkg/paintbricks/types";
import { ISessionDrawStroke } from "links/lib/types";
import { releaseCanvas } from "sessionComponents/utils/releaseCanvas";

export interface IDrawCanvasProps {
  backgroundImageUrl: string;
  strokes: Array<ISessionDrawStroke>;
  penColor?: string;
  onStroke?: (stroke: ISessionDrawStroke) => void;
  disabled: boolean;
  boxShadow?: string;
  studentId: string;
}

const ASPECT_RATIO = 4 / 3;
const PEN_WIDTH = 4;
export const REF_CANVAS_WIDTH = 400;
export const REF_CANVAS_HEIGHT = 300;

const sessionToPaintStroke = (stroke: ISessionDrawStroke): IStroke => {
  return {
    strokeParts: stroke.parts.map((part, i) => {
      return {
        isStart: i === 0,
        isEnd: i === stroke.parts.length - 1,
        startPoint: part.start,
        endPoint: part.end,
      };
    }),
    tool: new PenTool(
      stroke.color,
      PEN_WIDTH,
      REF_CANVAS_WIDTH,
      REF_CANVAS_HEIGHT
    ),
  };
};

const paintToSessionStroke = (
  stroke: IStroke,
  studentId: string,
  isIncomplete: boolean
): ISessionDrawStroke => {
  return {
    color: stroke.tool.getColor(),
    parts: stroke.strokeParts.map((sp) => ({
      start: sp.startPoint,
      end: sp.endPoint,
    })),
    user_id: studentId,
    is_incomplete: isIncomplete,
  };
};

export const DrawCanvas: React.FC<IDrawCanvasProps> = ({
  backgroundImageUrl,
  strokes,
  onStroke,
  penColor = "black",
  disabled,
  boxShadow,
  studentId,
}) => {
  const [containerRef, { width: containerWidth, height: containerHeight }] =
    useMeasure<HTMLDivElement>();
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const canvasManagerRef = useRef<Manager | null>(null);

  const handleWheel = (e: React.WheelEvent) => {
    window.scrollBy(0, e.deltaY);
  };

  let canvasWidth = Math.floor(containerWidth);
  let canvasHeight = Math.floor(containerWidth / ASPECT_RATIO);
  if (containerHeight > 0 && canvasHeight > containerHeight) {
    canvasHeight = containerHeight;
    canvasWidth = containerHeight * ASPECT_RATIO;
  }

  useLifecycles(
    () => {
      if (!canvasRef.current) return;

      canvasManagerRef.current = new Manager(
        canvasRef.current,
        canvasWidth,
        canvasHeight,
        backgroundImageUrl
      );
      canvasManagerRef.current.setTool(
        new PenTool(penColor, PEN_WIDTH, REF_CANVAS_WIDTH, REF_CANVAS_HEIGHT)
      );

      canvasManagerRef.current.onStrokePartHandler(
        debounce((stroke: IStroke) => {
          onStroke && onStroke(paintToSessionStroke(stroke, studentId, true));
        }, 100)
      );

      canvasManagerRef.current.onStroke((stroke) => {
        onStroke && onStroke(paintToSessionStroke(stroke, studentId, false));
      });
    },
    () => {
      canvasManagerRef.current?.destroy();
    }
  );

  // Release canvas before unmount. `useLayoutEffect` is necessary
  // because the reference will be lost before the `useLifecycles`
  // callback is executed.
  useLayoutEffect(() => {
    const canvasRefCurrent = canvasRef.current;
    return () => {
      canvasRefCurrent && releaseCanvas(canvasRefCurrent);
    };
  }, [canvasRef]);

  // set canvas size
  useEffect(() => {
    canvasManagerRef.current?.setCanvasSize(canvasWidth, canvasHeight);

    canvasManagerRef.current?.setStrokes(
      strokes.map((s) => sessionToPaintStroke(s))
    );

    canvasManagerRef.current?.setTool(
      new PenTool(penColor, PEN_WIDTH, REF_CANVAS_WIDTH, REF_CANVAS_HEIGHT)
    );
  }, [canvasWidth, canvasHeight, penColor, strokes]);

  useEffect(() => {
    if (!canvasManagerRef.current) return;

    canvasManagerRef.current.setStrokes(
      strokes.map((s) => sessionToPaintStroke(s))
    );
  }, [strokes]);

  useEffect(() => {
    if (!canvasManagerRef.current) return;

    canvasManagerRef.current.isDisabled = disabled;
  }, [disabled]);

  return (
    <Box
      cursor={disabled ? "not-allowed" : "crosshair"}
      ref={containerRef}
      w="full"
      boxShadow={boxShadow}
      h="full"
    >
      <Center>
        <canvas
          ref={canvasRef}
          style={{
            background: "#fff",
          }}
          onWheel={handleWheel}
        />
      </Center>
    </Box>
  );
};
