import { Box, Flex } from "@chakra-ui/react";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";

import { Dropdown, IOption } from "adminComponents/atoms/Dropdown";
import { Tabs } from "adminComponents/atoms/Tabs";
import { Text } from "adminComponents/atoms/Text";
import {
  INavigationHeaderProps,
  NavigationHeader,
} from "adminComponents/molecules/NavigationHeader";
import { StudentAssignmentBanner } from "adminComponents/molecules/StudentAssignmentBanner";
import {
  StudentAssignmentReportHeroCard,
  IProps as StudentAssignmentReportHeroCardProps,
} from "adminComponents/molecules/StudentAssignmentReportHeroCard";
import { TemplateWithCenteredHeroOneColumn } from "adminComponents/templates/TemplateWithCenteredHeroOneColumn";
import { pxToRem } from "adminComponents/utils";
import { momentFormatString } from "links/lib/constants";
import { IAssignment, IAssignmentUserAttempt, IUser } from "links/lib/types";
import { AssignmentStudentDetailTab } from "screens/TeacherClassrooms/AssignmentDetail/components/AssignmentStudentDetail";

export interface StudentAssignmentReportScreenProps {
  assignment: IAssignment;
  heroCardProps: StudentAssignmentReportHeroCardProps;
  isLoading?: boolean;
  lastAttempt: string;
  navigationData: INavigationHeaderProps;
  student: IUser;
  handleTabChange: (tab: AssignmentStudentDetailTab) => void;
  tabIndex?: number;
  showPremiumMarker?: boolean;
  attempts?: Array<IAssignmentUserAttempt>;
  selectedAttempt?: IAssignmentUserAttempt;
  handleChangeAttempt: (option: IOption) => void;
}

export const StudentAssignmentReportScreen: React.FC<
  StudentAssignmentReportScreenProps
> = ({
  isLoading,
  heroCardProps,
  lastAttempt,
  navigationData,
  student,
  handleTabChange,
  tabIndex,
  children,
  showPremiumMarker = true,
  attempts,
  selectedAttempt,
  handleChangeAttempt,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  const attemptOptions = attempts?.map((a) => ({
    value: a.id,
    label: moment(a.created_at).format(momentFormatString),
  }));

  const isMostRecentAttempt = attempts?.[0]?.id === selectedAttempt?.id;
  const formattedAttemptTime = moment(selectedAttempt?.created_at).format(
    momentFormatString
  );

  return (
    <TemplateWithCenteredHeroOneColumn
      nav={<NavigationHeader {...navigationData} isLoading={isLoading} />}
      contentAboveShiftedHero={
        <StudentAssignmentBanner student={student} lastAttempt={lastAttempt} />
      }
      heroContent={<StudentAssignmentReportHeroCard {...heroCardProps} />}
      isHeroShifted
      isLoading={isLoading}
      variant="default"
    >
      <Flex w="full" flexDir="column" gap={pxToRem(8)}>
        <Text variant="adminP1">
          {t("reportingSpecificSessionPicker.showResultsFor")}
        </Text>
        <Box w="25%" zIndex={2}>
          <Dropdown
            options={attemptOptions}
            value={{
              value: selectedAttempt?.id || "",
              label: formattedAttemptTime,
            }}
            handleChange={(e) => handleChangeAttempt(e as IOption)}
          />
        </Box>
        <Box mb={pxToRem(40)} mt={pxToRem(12)}>
          <Text variant="adminP1">
            {isMostRecentAttempt ? (
              t(
                "studentAssignmentReportScreen.questionsDescriptionMostRecentAttempt"
              )
            ) : (
              <>
                {t(
                  "studentAssignmentReportScreen.questionsDescriptionSpecificAttempt1"
                )}
                <b>
                  {t(
                    "studentAssignmentReportScreen.questionsDescriptionSpecificAttempt2",
                    {
                      date: formattedAttemptTime,
                    }
                  )}
                </b>
              </>
            )}
          </Text>
        </Box>
      </Flex>
      <Tabs
        handleChange={handleTabChange}
        tabIndex={tabIndex}
        tabData={[
          {
            content: <></>,
            label: t("studentAssignmentReport.questions"),
            isPremium: showPremiumMarker,
          },
          {
            content: <></>,
            label: t("studentAssignmentReport.standards"),
            isPremium: showPremiumMarker,
          },
        ]}
        variant="adminScreenTabs"
      />
      {children}
    </TemplateWithCenteredHeroOneColumn>
  );
};
