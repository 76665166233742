import { Box, Flex, useMultiStyleConfig } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

export interface IProps {
  currentLevel: number;
  totalLevels?: number;
}

export const RarityScale: React.FC<IProps> = ({
  currentLevel,
  totalLevels = 5,
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });

  const styles = useMultiStyleConfig("AdminRarityScale", { totalLevels });
  const progressBars = [...Array(totalLevels).keys()];

  return (
    <>
      <Flex
        aria-role="progressbar"
        role="progressbar"
        aria-label={t("common.rarity")}
        aria-valuenow={currentLevel}
        aria-valuemin={0}
        aria-valuemax={totalLevels}
      >
        {progressBars.map((bar) => {
          const id = uuidv4();
          const bgColor =
            bar < currentLevel ? "utility.focus" : "primary.light-gray";

          return (
            <Box
              key={`rarity-level-${id}`}
              sx={{
                ...styles.bar,
                bg: bgColor,
              }}
            ></Box>
          );
        })}
      </Flex>
    </>
  );
};
