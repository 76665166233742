import {
  Box,
  VStack,
  useBreakpointValue,
  usePrefersReducedMotion,
} from "@chakra-ui/react";
import throttle from "lodash.throttle";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useMount, usePreviousDistinct, useRafLoop } from "react-use";

import { IconButton } from "adminComponents/atoms/IconButton";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { LocationInfo } from "sharedComponents/molecules/SeasonMap";

interface ISeasonMapPannerProps {
  width: number;
  height: number;
  mapWidth: number;
  mapHeight: number;
  initialScale?: number;
  children: React.ReactElement<ISeasonMapPannerChildProps>;
  bgImage?: string;
  bgRepeat?: string;
  bgSize?: string;
  bgPosition?: string;
  disableShadow?: boolean;
  /**
   * The map coordinate to center the panner on when mounting
   */
  initialScrollCenterPos?: { x: number; y: number };
}

export interface ISeasonMapPannerChildProps {
  scale?: number;
  handleLocationFocus?: (
    location: LocationInfo,
    isKeyboardEvent?: boolean
  ) => void;
}

const maxScale = 1;
const minScale = 0.2;

const SeasonMapPanner: React.FC<ISeasonMapPannerProps> = ({
  children,
  initialScale = 1,
  width,
  height,
  mapWidth,
  mapHeight,
  bgImage,
  bgRepeat = "no-repeat",
  bgSize = "cover",
  bgPosition = "center center",
  disableShadow,
  initialScrollCenterPos,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const isReducedMotion = usePrefersReducedMotion();
  const currentDrag = useRef<{
    startClick: {
      x: number;
      y: number;
    };
    startScroll: {
      top: number;
      left: number;
    };
  } | null>(null);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const scrollAnimationRef = useRef<{
    from: { top: number; left: number };
    to: { top: number; left: number };
    startedAt: number;
  } | null>(null);
  // ref for the stop scroll anim function
  const stopScrollAnimRef = useRef<(() => void) | null>(null);
  const [scale, setScale] = useState(initialScale);
  const bottomItemOffset = useBreakpointValue({
    base: pxToRem(20),
    md: pxToRem(30),
  });
  const { t } = useTranslation("map", {
    keyPrefix: "map.panner",
    useSuspense: false,
  });

  // animation duration for scroll position changes
  const scrollAnimTimeMS = useMemo(() => {
    return isReducedMotion ? 0 : 250;
  }, [isReducedMotion]);

  // controls tweening to a new scroll position
  const [stopScrollAnim, startScrollAnim] = useRafLoop((time: number) => {
    if (
      !scrollAnimationRef.current ||
      !scrollContainerRef.current ||
      time > scrollAnimationRef.current.startedAt + scrollAnimTimeMS
    ) {
      stopScrollAnimRef.current?.();

      if (scrollContainerRef.current && scrollAnimationRef.current) {
        scrollContainerRef.current.scrollTop =
          scrollAnimationRef.current.to.top;
        scrollContainerRef.current.scrollLeft =
          scrollAnimationRef.current.to.left;
      }

      scrollAnimationRef.current = null;

      return;
    }

    const { from, to, startedAt } = scrollAnimationRef.current;

    // how much scroll each MS
    const leftMSInc = (to.left - from.left) / scrollAnimTimeMS;
    const topMSInc = (to.top - from.top) / scrollAnimTimeMS;

    const timeElapsed = time - startedAt;

    scrollContainerRef.current.scrollTop = from.top + timeElapsed * topMSInc;
    scrollContainerRef.current.scrollLeft = from.left + timeElapsed * leftMSInc;
  }, false);

  stopScrollAnimRef.current = stopScrollAnim;

  const gutterSize = useMemo(() => {
    return {
      x: width / 2,
      y: height / 2,
    };
  }, [width, height]);

  // position scroll on top left corner of map on mount if no initial position
  useMount(() => {
    if (!scrollContainerRef.current) return;

    const mapTop = gutterSize.y;
    const mapLeft = gutterSize.x;

    if (!initialScrollCenterPos) {
      scrollContainerRef.current.scrollTop = mapTop;
      scrollContainerRef.current.scrollLeft = mapLeft;
    } else {
      const top =
        gutterSize.y + (initialScrollCenterPos.y * scale - height / 2);
      const left =
        gutterSize.x + (initialScrollCenterPos.x * scale - width / 2);

      scrollAnimationRef.current = {
        from: {
          top: mapTop,
          left: mapLeft,
        },
        to: {
          top,
          left,
        },
        startedAt: performance.now(),
      };

      startScrollAnim();
    }
  });

  const handleMouseDown = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();

      if (!scrollContainerRef.current) return;

      if (
        document.activeElement &&
        "blur" in document.activeElement &&
        typeof document.activeElement["blur"] === "function"
      ) {
        (document.activeElement as unknown as { blur: () => void }).blur();
      }

      stopScrollAnim();

      setIsDragging(true);

      currentDrag.current = {
        startClick: {
          x: e.clientX,
          y: e.clientY,
        },
        startScroll: {
          top: scrollContainerRef.current.scrollTop,
          left: scrollContainerRef.current.scrollLeft,
        },
      };
    },
    [stopScrollAnim]
  );

  const handleMouseMove = useCallback((e: MouseEvent) => {
    e.preventDefault();

    if (!currentDrag.current || !scrollContainerRef.current) return;

    const deltaX = e.clientX - currentDrag.current.startClick.x;
    const deltaY = e.clientY - currentDrag.current.startClick.y;

    scrollContainerRef.current.scrollTop =
      currentDrag.current.startScroll.top - deltaY;
    scrollContainerRef.current.scrollLeft =
      currentDrag.current.startScroll.left - deltaX;
  }, []);

  const handleMouseUp = useCallback((e: MouseEvent) => {
    e.preventDefault();

    setIsDragging(false);
    currentDrag.current = null;
  }, []);

  const handleLocationFocus = useCallback(
    (info: LocationInfo, isKeyboardEvent?: boolean) => {
      if (!scrollContainerRef.current) return;

      // get location x/y relative to scale and gutter
      const locX = info.x * scale + gutterSize.x;
      const locY = info.y * scale + gutterSize.y;

      // note: popper size is unknown and dynamic - 400 is about the largest it can get
      const popperHeight = 400;
      // roughly center the popper + marker vertically
      const top = locY - height + (height / 2 - popperHeight / 2);
      const left = locX - width / 2;

      // If the focus is coming from a keyboard event, then
      // skip the animation (chrome will fight the initial position)
      if (isKeyboardEvent) {
        scrollContainerRef.current.scrollTop = top;
        scrollContainerRef.current.scrollLeft = left;
      } else {
        // set the scroll animation to focus on the
        // location position with popover
        scrollAnimationRef.current = {
          from: {
            top: scrollContainerRef.current.scrollTop,
            left: scrollContainerRef.current.scrollLeft,
          },
          to: {
            top,
            left,
          },
          startedAt: performance.now(),
        };

        startScrollAnim();
      }
    },
    [gutterSize, width, height, scale, startScrollAnim]
  );

  const handleZoomIn = useCallback(() => {
    setScale((currentScale) => Math.min(maxScale, currentScale + 0.1));
  }, []);

  const handleZoomOut = useCallback(() => {
    setScale((currentScale) => Math.max(minScale, currentScale - 0.1));
  }, []);

  const lastScale = usePreviousDistinct(scale) || scale;

  // On scale update, maintain map focus on center position
  useEffect(() => {
    if (!scrollContainerRef.current) return;

    const { scrollTop, scrollLeft } = scrollContainerRef.current;
    const topInc = scrollTop / lastScale;
    const leftInc = scrollLeft / lastScale;

    scrollContainerRef.current.scrollTop = topInc * scale;
    scrollContainerRef.current.scrollLeft = leftInc * scale;
  }, [scale, lastScale]);

  const extendedChildren = useMemo(() => {
    if (React.isValidElement(children)) {
      return React.cloneElement(children, {
        scale,
        handleLocationFocus,
      });
    }
    return children;
  }, [children, scale, handleLocationFocus]);

  useEffect(() => {
    const _handleMouseMove = throttle(handleMouseMove, 10, {
      trailing: true,
      leading: true,
    });

    document.addEventListener("mousemove", _handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("mousemove", _handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [handleMouseMove, handleMouseUp]);

  const totalMapWidthRem = pxToRem(mapWidth * scale + gutterSize.x * 2);
  const totalMapHeightRem = pxToRem(mapHeight * scale + gutterSize.y * 2);

  return (
    <Box position="relative" w="fit-content">
      <Box
        overflow="auto"
        w={pxToRem(width)}
        height={pxToRem(height)}
        ref={scrollContainerRef}
      >
        <Box
          position="relative"
          width={totalMapWidthRem}
          height={totalMapHeightRem}
          background={bgImage ? `url(${bgImage})` : ""}
          backgroundRepeat={bgRepeat}
          backgroundSize={bgSize}
          backgroundPosition={bgPosition}
        >
          <Box
            position="absolute"
            top={pxToRem(gutterSize.y)}
            left={pxToRem(gutterSize.x)}
            boxShadow={disableShadow ? undefined : "lg"}
          >
            {extendedChildren}
          </Box>
          {/* Grab layer for cursor and mouse down capture */}
          <Box
            position="absolute"
            top="0"
            left="0"
            width="full"
            height="full"
            onMouseDown={handleMouseDown}
            cursor={isDragging ? "grabbing" : "grab"}
          />
        </Box>
      </Box>
      <VStack
        position="absolute"
        bottom={bottomItemOffset}
        right={bottomItemOffset}
        zIndex="2"
      >
        <IconButton
          icon="zoom_in"
          shape="type1"
          ariaLabel={t("zoomInButton")}
          colorScheme="primary.warm-white"
          onClick={handleZoomIn}
        />
        <IconButton
          icon="zoom_out"
          shape="type2"
          ariaLabel={t("zoomOutButton")}
          colorScheme="primary.warm-white"
          onClick={handleZoomOut}
        />
      </VStack>
    </Box>
  );
};

export { SeasonMapPanner };
