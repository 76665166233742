import {
  Box,
  CheckboxGroup,
  Flex,
  Square,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Modal } from "adminComponents/atoms/Modal";
import { Text } from "adminComponents/atoms/Text";
import { TextLink } from "adminComponents/atoms/TextLink";
import { generateStudentName, pxToRem } from "adminComponents/utils";
import { IUser } from "links/lib/types";

export interface ClassDifferences {
  giantStepsClassName: string;
  googleClassroomClassName: string;
  excludedStudents: IUser[];
}

interface StudentEnrollmentDifferencesModalrops {
  isOpen: boolean;
  classes: ClassDifferences[];
  handleClose: () => void;
  handleSubmit?: () => void;
}

export const StudentEnrollmentDifferencesModal: React.FC<
  StudentEnrollmentDifferencesModalrops
> = ({ isOpen, classes, handleClose, handleSubmit }) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });

  const styles = useMultiStyleConfig("AdminSelectStudentModal", {});

  const totalExcludedStudents = useMemo(() => {
    let total = 0;
    classes.forEach((c) => {
      total += c.excludedStudents.length;
    });

    return total;
  }, [classes]);

  return (
    <Modal
      title={t("studentEnrollmentDifferences.title")}
      isOpen={isOpen}
      onClose={handleClose}
      size="xl"
      headingProps={{ as: "h2", variant: "adminH6" }}
      showBackButton={false}
    >
      <Box mb={pxToRem(40)}>
        <Text fontSize={pxToRem(18)}>
          {t("studentEnrollmentDifferences.explainer", {
            count: totalExcludedStudents,
          })}
        </Text>
      </Box>
      <Flex
        flexDirection="column"
        paddingBottom={pxToRem(150)}
        sx={styles.checkboxContainer}
      >
        <CheckboxGroup>
          {classes.map((c) => {
            return (
              <Box key={c.giantStepsClassName}>
                <Text variant="adminP1">
                  {t("studentEnrollmentDifferences.classMessage", {
                    count: c.excludedStudents.length,
                    gsClass: c.giantStepsClassName,
                    googleClass: c.googleClassroomClassName,
                  })}
                </Text>
                {c.excludedStudents.map((student) => {
                  const name = generateStudentName({
                    given_name: student.given_name,
                    family_name: student.family_name,
                  });
                  return (
                    <Text
                      key={`${c.giantStepsClassName}-${student.email}`}
                      color="primary.dark-gray"
                    >{`${name.primary} (${student.email})`}</Text>
                  );
                })}
              </Box>
            );
          })}
        </CheckboxGroup>
      </Flex>

      <Flex sx={styles.footer} w="full">
        <Flex gap={pxToRem(12)} justifyContent="space-between">
          <Button
            size="lg"
            variant="adminButtonOutlined"
            onClick={handleClose}
            flex={[1, null, "auto"]}
          >
            {t("common.cancel")}
          </Button>
          <Square>
            {handleSubmit && (
              <TextLink isWarning handleClick={handleSubmit}>
                {t("studentEnrollmentDifferences.confirm")}
              </TextLink>
            )}
          </Square>
        </Flex>
      </Flex>
    </Modal>
  );
};
