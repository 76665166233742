import { Box, Input as ChakraInput, InputProps } from "@chakra-ui/react";
import React from "react";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";

export interface IInputProps extends InputProps {
  error?: boolean;
  disabled?: boolean;
}

export const Input = React.forwardRef<HTMLInputElement, IInputProps>(
  (p, ref) => {
    const { borderRadius, error, disabled, ...props } = p;

    const { match: currentBreakpoints } = useBreakpoints();
    return (
      <Box width="full">
        <ChakraInput
          autoFocus
          ref={ref}
          variant="gameInput"
          borderRadius={
            borderRadius ?? pxToRem(currentBreakpoints.borderRadius)
          }
          fontSize={pxToRem(currentBreakpoints.fontSize)}
          borderWidth={pxToRem(currentBreakpoints.borderWidth)}
          height={pxToRem(currentBreakpoints.responseHeight)}
          w="full"
          spellCheck={false}
          error={error}
          disabled={disabled}
          {...props}
        />
      </Box>
    );
  }
);

Input.displayName = "Input";
