import React from "react";

import { ExtendIndividualTrialScreen } from "adminComponents/screens/ExtendIndividualTrialScreen";
import { useErrorToast } from "adminComponents/utils/toast";
import useExtendUserIndividualTrial from "links/lib/features/users/useExtendUserIndividualTrial";
import useFetchUserCanExtendIndividualTrial from "links/lib/features/users/useFetchUserCanExtendIndividualTrial";

interface IProps {
  extensionDays?: number;
}

export const ExtendIndividualTrial: React.FC<IProps> = ({
  extensionDays = 30,
}) => {
  const fetchUserCanExtendIndividualTrial =
    useFetchUserCanExtendIndividualTrial({});
  useErrorToast(fetchUserCanExtendIndividualTrial.error);

  const extendIndividualTrial = useExtendUserIndividualTrial({ extensionDays });
  useErrorToast(extendIndividualTrial.error);

  const onExtendIndividualTrial = () => {
    extendIndividualTrial.mutate();
  };

  return (
    <ExtendIndividualTrialScreen
      hasExtendedTrial={extendIndividualTrial.isSuccess}
      handleExtendTrial={onExtendIndividualTrial}
      canExtendTrial={
        fetchUserCanExtendIndividualTrial.data?.can_extend_trial || false
      }
      isLoading={fetchUserCanExtendIndividualTrial.isLoading}
      extensionDays={extensionDays}
    />
  );
};
