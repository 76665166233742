import { Box, VisuallyHidden, useMultiStyleConfig } from "@chakra-ui/react";
import React from "react";

import { CoverImageBGColorSchemes } from "links/lib/constants";
import { CoverImageBGColorSchemeType } from "links/lib/types";

export interface ICoverImageColorSchemeChoiceProps {
  isSelected: boolean;
  boxSize: number;
  colorScheme: CoverImageBGColorSchemeType;
  handleSelect?: (colorScheme: CoverImageBGColorSchemeType) => void;
}

const CoverImageColorSchemeChoice: React.FC<
  ICoverImageColorSchemeChoiceProps
> = ({ boxSize, isSelected, colorScheme, handleSelect }) => {
  const colors = CoverImageBGColorSchemes[colorScheme];
  const color1String = `rgba(${colors[0].join(",")}, 1)`;
  const color2String = `rgba(${colors[1].join(",")}, 1)`;

  const styles = useMultiStyleConfig("AdminCoverImageColorSchemeChoice", {
    boxSize,
    color1String,
    color2String,
    isSelected,
  });

  return (
    <Box
      sx={styles.container}
      as="button"
      type="button"
      onClick={() => handleSelect?.(colorScheme)}
    >
      <Box sx={styles.color} as="span">
        <VisuallyHidden as="span">{colorScheme.toString()}</VisuallyHidden>
      </Box>
    </Box>
  );
};

export { CoverImageColorSchemeChoice };
