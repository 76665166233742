import {
  Box,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useStyleConfig,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import {
  IProps as ButtonProps,
  IconButton,
} from "adminComponents/atoms/IconButton";
import { IProps as TooltipProps } from "adminComponents/atoms/IconTooltip";
import { Text } from "adminComponents/atoms/Text";

type PlacementWithLogical =
  | "top"
  | "top-start"
  | "top-end"
  | "bottom"
  | "bottom-start"
  | "bottom-end";

export interface IProps extends ButtonProps {
  tooltipProps?: TooltipProps;
  tooltipText?: string;
}

const offsets: Record<PlacementWithLogical, [number, number]> = {
  top: [0, 10],
  "top-start": [-18, 10],
  "top-end": [18, 10],
  bottom: [0, 10],
  "bottom-start": [-18, 10],
  "bottom-end": [18, 10],
};

const IconButtonWithTooltip: React.FC<IProps> = ({
  ariaLabel,
  icon,
  shape,
  tooltipProps: {
    placement = "top",
    popoverVariant = "adminSolid",
    variant = "adminSolid",
  } = {},
  tooltipText,
  ...props
}) => {
  const triggerElementStyles = useStyleConfig("AdminPopoverTrigger", {
    variant: popoverVariant,
  });

  const { t } = useTranslation("admin", { useSuspense: false });

  return (
    <Popover
      computePositionOnMount
      arrowPadding={22}
      arrowShadowColor="transparent"
      offset={offsets[placement]}
      placement={placement}
      trigger="hover"
      variant={variant}
      aria-live="polite"
    >
      <PopoverTrigger>
        <Box __css={triggerElementStyles} aria-label={t("common.openTooltip")}>
          <IconButton
            ariaLabel={ariaLabel}
            icon={icon}
            shape={shape}
            {...props}
          />
        </Box>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverBody>
          <Text as="p" variant="adminMeta" color="primary.white">
            {tooltipText || ariaLabel}
          </Text>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export { IconButtonWithTooltip };
