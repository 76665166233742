import {
  Box,
  CSSObject,
  Container,
  ThemeComponentProps,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import React, { ReactNode } from "react";

import adminImage from "adminComponents/assets/background-images/admin-background.svg";
import desktopImage from "adminComponents/assets/background-images/illustrated-background-desktop.svg";
import mobileImage from "adminComponents/assets/background-images/illustrated-background-mobile.svg";
import { LoadingSpinner } from "adminComponents/atoms/LoadingSpinner";
import { NavTrigger } from "adminComponents/atoms/NavTrigger";

export interface IProps {
  variant?: string;
  heroContent?: ReactNode;
  contentAboveShiftedHero?: ReactNode;
  isHeroShifted?: boolean;
  children: ReactNode;
  nav?: ReactNode;
  isLoading?: boolean;
  isGrayscale?: boolean;
  adminMode?: boolean;
  outerContainerStyle?: ThemeComponentProps["Box"];
  heroContainerStyle?: ThemeComponentProps["Box"];
  heroContainerVariant?: string;
}

const LoadingSpinnerWrapper = ({ style }: { style: CSSObject }) => (
  <Box sx={style}>
    <LoadingSpinner />
  </Box>
);

export const TemplateWithCenteredHeroOneColumn: React.FC<IProps> = ({
  nav,
  variant = "default",
  isHeroShifted = false,
  contentAboveShiftedHero,
  heroContent,
  children,
  isLoading = false,
  isGrayscale = false,
  adminMode,
  outerContainerStyle,
  heroContainerStyle,
  heroContainerVariant,
}) => {
  const styles = useMultiStyleConfig("AdminTemplateWithCenteredHeroOneColumn", {
    isHeroShifted,
    variant,
    adminMode,
  });

  const mobileBG = adminMode ? adminImage : mobileImage;
  const desktopBG = adminMode ? adminImage : desktopImage;

  return (
    <Box __css={styles.outerContainer} {...outerContainerStyle}>
      <Box
        __css={styles.backgroundContainer}
        backgroundImage={{
          base: isGrayscale
            ? `linear-gradient(black, black), url(${mobileBG})`
            : mobileBG,
          md: isGrayscale
            ? `linear-gradient(black, black),url(${desktopBG})`
            : desktopBG,
        }}
      >
        {nav}
        {!isLoading && (
          <Box __css={styles.heroContainerOuter}>
            <NavTrigger />
            {contentAboveShiftedHero && (
              <Container variant="adminContentWrapper">
                {contentAboveShiftedHero}
              </Container>
            )}
            {!!heroContent && (
              <Box
                __css={
                  isHeroShifted
                    ? styles.shiftedHeroContainer
                    : styles.heroContainer
                }
                {...heroContainerStyle}
              >
                <Container
                  variant={
                    heroContainerVariant
                      ? heroContainerVariant
                      : "adminContentWrapper"
                  }
                >
                  {heroContent}
                </Container>
              </Box>
            )}
          </Box>
        )}
      </Box>
      <Container sx={styles.innerContainer} variant="adminContentWrapper">
        {isLoading ? (
          <LoadingSpinnerWrapper style={styles.loadingSpinner} />
        ) : (
          children
        )}
      </Container>
    </Box>
  );
};
