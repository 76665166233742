import { Box, BoxProps } from "@chakra-ui/react";
import React from "react";

import { useCoverImageBackground } from "adminComponents/atoms/CoverImageBackground/hooks/useCoverImageBackground";
import { CoverImageBGPatternType, RemixBGPatternType } from "links/lib/types";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";

export interface IInterstitialBackgroundProps extends BoxProps {
  pattern?: CoverImageBGPatternType | RemixBGPatternType;
}

const colorScheme: [[number, number, number], [number, number, number]] = [
  [221, 237, 240],
  [212, 228, 231],
];

export const InterstitialBackground: React.FC<IInterstitialBackgroundProps> = ({
  pattern,
  children,
  ...rest
}) => {
  const { match } = useBreakpoints();

  const { tileImage } = useCoverImageBackground({
    colorScheme,
    pattern: pattern || "OTHER_FUN_PARTY",
    tileSize: match.interstitialTileSize,
  });

  return (
    <Box
      w="100%"
      h="100%"
      backgroundImage={tileImage ? `url(${tileImage})` : undefined}
      display="flex"
      justifyContent="center"
      alignItems="center"
      backgroundRepeat="repeat"
      backgroundColor={`rgb(${colorScheme[0].join(",")})`}
      {...rest}
    >
      {children}
    </Box>
  );
};
