import { Box } from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";

import registerObserver from "adminComponents/utils/TopNavObserver";

export const NavTrigger: React.FC = () => {
  const getMeasurements = () => {
    const top = window.innerWidth >= 768 ? "-80px" : "-48px";
    const bottom = window.innerWidth >= 768 ? "-124px" : "-92px";
    return { top, bottom };
  };

  const { top: initTop, bottom: initBottom } = useMemo(
    () => getMeasurements(),
    []
  );
  const [topMeasurement, setTopMeasurement] = useState<string>(initTop);
  const [bottomMeasurement, setBottomMeasurement] =
    useState<string>(initBottom);

  const setMeasurements = (measurements: { top: string; bottom: string }) => {
    setTopMeasurement(measurements.top);
    setBottomMeasurement(measurements.bottom);
  };

  useEffect(() => {
    const topObserver = registerObserver(
      ".admin-navTrigger-observerTrigger",
      topMeasurement,
      "topNav"
    );
    const bottomObserver = registerObserver(
      ".admin-navTrigger-observerTrigger",
      bottomMeasurement,
      "breadcrumb"
    );

    return () => {
      topObserver.disconnect();
      bottomObserver.disconnect();
    };
  }, [topMeasurement, bottomMeasurement]);

  useEffect(() => {
    const onResize = () => {
      setTimeout(() => setMeasurements(getMeasurements()), 0);
    };
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, []);

  return <Box className="admin-navTrigger-observerTrigger" height="1px" />;
};
