import { Box, Flex, useMultiStyleConfig } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { CalendarDate } from "adminComponents/atoms/CalendarDate";
import { Card } from "adminComponents/atoms/Card";
import { Divider } from "adminComponents/atoms/Divider";
import { ExpandableText } from "adminComponents/atoms/ExpandableText";
import { Heading } from "adminComponents/atoms/Heading";
import { Text } from "adminComponents/atoms/Text";
import CoverImageBlob from "adminComponents/molecules/CoverImageBlob";
import {
  IconButtonDropdown,
  MenuItemDetails as IconMenuItemDetails,
} from "adminComponents/molecules/IconButtonDropdown";
import { PieChartWithLabel } from "adminComponents/molecules/PieChartWithLabel";
import { IAssignment } from "links/lib/types";

export interface IProps {
  assignment: IAssignment;
  accuracy: number;
  attempts: number;
  handleEdit: (assignment: IAssignment) => void;
  handleDelete: (assignment: IAssignment) => void;
}

export const StudentAssignmentReportHeroCard: React.FC<IProps> = ({
  assignment,
  accuracy,
  attempts,
  handleEdit,
  handleDelete,
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });

  const styles = useMultiStyleConfig("AdminHeroCard", {
    variant: "classroomAssignmentReport",
  });

  const menuItems: IconMenuItemDetails[] = [
    {
      label: t("classroomAssignmentReport.editAssignment"),
      handleClick: () => handleEdit(assignment),
    },
    {
      label: t("classroomAssignmentReport.deleteAssignment"),
      handleClick: () => handleDelete(assignment),
    },
  ];

  const { ends_at: dueDate, description } = assignment;

  const {
    title,
    cover_image_icon: icon,
    cover_image_bg_pattern: backgroundPattern,
    cover_image_bg_color_scheme: backgroundColorScheme,
  } = assignment.practice_set;

  const attemptText =
    attempts === 1 ? t("common.attemptSingular") : t("common.attemptPlural");

  return (
    <Card
      borderColor="primary.tan"
      backgroundColor="primary.white"
      variant="adminCardThickBorderTall"
    >
      <Flex sx={styles.header}>
        <IconButtonDropdown icon="more_horiz" menuItems={menuItems} />
      </Flex>
      <Flex sx={styles.main}>
        <Box sx={styles.image}>
          <CoverImageBlob
            backgroundColorScheme={backgroundColorScheme}
            backgroundPattern={backgroundPattern}
            icon={icon}
            boxSize={115}
          />
        </Box>
        <Flex sx={styles.studentDetails}>
          <Box sx={styles.studentDetailsText}>
            <Flex sx={styles.dueDate}>
              <CalendarDate
                date={dueDate}
                dateType="due"
                textVariant="adminP1"
              />
            </Flex>
            <Heading as="h1" variant="adminH3" sx={styles.heading}>
              {title}
            </Heading>
            <ExpandableText
              textVariant="adminP1"
              noOfLines={2}
              sx={styles.text}
            >
              {description}
            </ExpandableText>
          </Box>
          <Divider color="primary.light-gray" />
          <Flex sx={styles.studentDetailsStats}>
            <Flex sx={styles.accuracy}>
              <PieChartWithLabel
                percentage={accuracy}
                text={t("studentAssignmentReport.studentAccuracy")}
              />
            </Flex>
            <Text size="md" variant="adminP1">
              {attempts} {attemptText}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};
