import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { string } from "yup";

import {
  AddStudentDirectionsModal,
  DescriptionCase,
} from "adminComponents/organisms/AddStudentDirectionsModal";
import { AddStudentsViaEmailsModal } from "adminComponents/organisms/AddStudentsViaEmailsModal";
import { useErrorToast, useShowToast } from "adminComponents/utils/toast";
import { useAnalytics } from "lib/contexts/analytics";
import { useHubSpot } from "lib/hooks/useHubSpot";
import { useMutateClassroomUsers } from "links/lib/features/classrooms";
import {
  AnalyticsEvent,
  IUser,
  UserRole,
  UsersGroupsRole,
} from "links/lib/types";

export interface IArgs {
  classroom_id: string;
}
export interface IResult {
  modal: React.ReactElement;
  addStudentDirectionsModal: React.ReactElement;
  handleAddIntent: () => void;
}

const emailSchema = string().email();

export const useAddStudentsByEmail = ({ classroom_id }: IArgs): IResult => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isAddStudentDirectionsModalOpen, setIsAddStudentDirectionsModalOpen] =
    useState<boolean>(false);
  const [errorString, setErrorString] = useState<string | undefined>();
  const { trackEvent } = useAnalytics();
  const showToast = useShowToast();
  const { t } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "classroomDetailContainer.studentsTab.addByEmail",
  });
  const { trackHubSpotEvent } = useHubSpot();
  const [disableNotifications, setDisableNotifications] =
    useState<boolean>(false);

  const onSuccess = useCallback(() => {
    showToast(t("successToast"));
    setIsOpen(false);

    trackHubSpotEvent(AnalyticsEvent.HubSpot_AddedStudentsToClassroom);

    setIsAddStudentDirectionsModalOpen(true);
  }, [showToast, t, trackHubSpotEvent]);

  const studentsMutation = useMutateClassroomUsers({ onSuccess });

  useErrorToast(studentsMutation.error);

  const handleAddIntent = useCallback(() => {
    setIsOpen(true);
    setErrorString(undefined);
    trackEvent(
      AnalyticsEvent.TeacherDashboard_ClassroomDetail_StudentsTab_AddByEmailIntent,
      {}
    );
  }, [trackEvent]);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleAddStudents = useCallback(
    (content: string) => {
      if (!content.trim()) {
        setErrorString(t("errorEmailsRequired"));
        return;
      }

      const emails = content
        .trim()
        .replaceAll("\n", ",")
        .split(",")
        .map((e) => e.trim())
        .filter((e) => e !== "");

      const isInvalid = emails.some((e) => !emailSchema.isValidSync(e));

      if (isInvalid) {
        setErrorString(t("errorEmailsInvalid"));
        return;
      }

      // passed validation checks - input is valid
      setErrorString(undefined);

      const users = emails.map((email): IUser => {
        return {
          id: "0",
          email: email,
          role: UserRole.Student,
          true_role: UserRole.Student,
          license_id: "0",
          timezone_locale: "",
          language: "",
          is_guest: false,
          country: "",
          region: "",
        };
      });

      studentsMutation.mutate({
        classroomId: classroom_id,
        users,
        disableNotifications: disableNotifications,
        usersGroupsRole: UsersGroupsRole.Member,
      });

      trackEvent(
        AnalyticsEvent.TeacherDashboard_ClassroomDetail_StudentsTab_AddByEmailConfirm,
        {}
      );
    },
    [studentsMutation, classroom_id, trackEvent, t, disableNotifications]
  );

  return useMemo(() => {
    return {
      modal: (
        <AddStudentsViaEmailsModal
          isOpen={isOpen}
          handleClose={handleClose}
          handleAddStudents={handleAddStudents}
          isLoading={studentsMutation.isLoading}
          errorString={errorString}
          disableNotifications={disableNotifications}
          setDisableNotifications={setDisableNotifications}
        />
      ),
      handleAddIntent,
      addStudentDirectionsModal: (
        <AddStudentDirectionsModal
          isOpen={isAddStudentDirectionsModalOpen}
          handleClose={() => setIsAddStudentDirectionsModalOpen(false)}
          descriptionCase={
            disableNotifications
              ? DescriptionCase.BY_EMAIL_NO_NOTIFICATIOON
              : DescriptionCase.BY_EMAIL
          }
        />
      ),
    };
  }, [
    isOpen,
    isAddStudentDirectionsModalOpen,
    handleClose,
    handleAddStudents,
    handleAddIntent,
    errorString,
    studentsMutation.isLoading,
    disableNotifications,
  ]);
};
