import { useDisclosure } from "@chakra-ui/react";
import React from "react";

import { isRichTextEmpty } from "adminComponents/organisms/RichTextEditor/util";
import { useSessionActions } from "links/lib/contexts/sessionActions";
import { useSessionGameState } from "links/lib/contexts/sessionGameState";
import { useSessionRoundGroupState } from "links/lib/contexts/sessionRoundGroupState";
import { useSessionRoundState } from "links/lib/contexts/sessionRoundState";
import { useSessionScene } from "links/lib/contexts/sessionScene";
import usePendingSubmit from "links/lib/hooks/usePendingSubmit";
import { useStudent } from "sessionComponents/contexts/student";
import { useTeammateSoundEffects } from "sessionComponents/hooks/useTeammateSoundEffects";
import { ClassificationRoundReview } from "sessionComponents/organisms/ClassificationRoundReview";
import { FeedbackFlyout } from "sessionComponents/organisms/FeedbackFlyout";
import { SplashScene, SplashType } from "sessionComponents/scenes/SplashScene";
import { getQuestionPrompt } from "sessionComponents/utils/getQuestionPrompt";

type StudentClassificationRoundReviewProps = {
  isClassSession: boolean;
};

export const StudentClassificationRoundReview: React.FC<
  StudentClassificationRoundReviewProps
> = ({ isClassSession }) => {
  const roundState = useSessionRoundState();
  const roundGroupState = useSessionRoundGroupState();
  const scene = useSessionScene();
  const gameState = useSessionGameState();
  const { initiateNextRound } = useSessionActions();

  const student = useStudent();
  useTeammateSoundEffects(student);

  const { variant, practice_set_session_item, rich_feedback, round_number } =
    roundState;

  const userPlacements = roundGroupState.dragsort?.items || [];

  const sceneChangeSubmit = usePendingSubmit([scene]);

  // only in individual session
  const onConfirmButtonClick = () => {
    initiateNextRound();
    sceneChangeSubmit.handleSubmit();
  };

  const getIsItemCorrect = (id: string) => {
    return (
      (roundGroupState.dragsort?.items.find((item) => item.id === id) || {})
        .is_correct || false
    );
  };

  const isFinalRound = round_number === gameState?.final_round_number;

  const prompt = getQuestionPrompt(
    practice_set_session_item.question_type,
    practice_set_session_item
  );
  const categories = practice_set_session_item.classify?.categories || [];

  const {
    isOpen: isFeedbackOpen,
    onOpen: onFeedbackOpen,
    onClose: onFeedbackClose,
  } = useDisclosure();
  const showFeedbackButton =
    !isRichTextEmpty(rich_feedback?.text) || !!rich_feedback?.image_url;

  return (
    <>
      <ClassificationRoundReview
        outerGame={gameState?.game_type}
        variant={variant}
        questionType={practice_set_session_item.question_type}
        prompt={prompt}
        categories={categories}
        isClassSession={isClassSession}
        splashScene={
          <SplashScene
            splashType={SplashType.StudentReview}
            student={student}
          />
        }
        items={userPlacements}
        getIsItemCorrect={getIsItemCorrect}
        onFeedbackClick={onFeedbackOpen}
        showFeedbackButton={showFeedbackButton}
        onConfirmButtonClick={onConfirmButtonClick}
        isTeacher={false}
        isFinalRound={isFinalRound}
        isConfirmLoading={sceneChangeSubmit.isPending}
        showAccuracyBar={false}
      />
      <FeedbackFlyout
        isOpen={isFeedbackOpen}
        onClose={onFeedbackClose}
        imageUrl={rich_feedback?.image_url}
        imageAltText={rich_feedback?.image_alt_text}
        text={rich_feedback?.text}
      />
    </>
  );
};
