import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Center,
  Checkbox,
  FormControl,
  FormErrorMessage,
  HStack,
  Image,
  Input,
  Radio,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Textarea,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { SkeletonData } from "@esotericsoftware/spine-core";
import { useFormik } from "formik";
import React, { SyntheticEvent } from "react";
import * as Yup from "yup";

import { Modal } from "adminComponents/atoms/Modal";
import { AvatarItemRarityLevels, AvatarSkeletonType } from "links/lib/types";

import {
  hairColorOptions,
  hairReservedColor,
  slotModifierActions,
  slotModifierHandLGroupSlots,
  slotModifierHandRGroupSlots,
  slotModifierSlots,
  userDefinedReserveColor,
} from "../constants";
import { AvatarItemBuilderData } from "../types";
import { svgToBase64 } from "../utils";
import { AvatarPreview } from "./AvatarPreview";

type Step3Props = {
  images: { [key: string]: string };
  itemBuilderData: AvatarItemBuilderData;
  previewSVG: string;
  selectedSkinName: string;
  selectedSlots: { [key: string]: boolean };
  seatedSpineConfiguration: SkeletonData;
  standingSpineConfiguration: SkeletonData;
  resetState: () => void;
  setError: (error: string) => void;
  setPreviewSVG: (previewSVG: string) => void;
};

export const Step3: React.FC<Step3Props> = ({
  images,
  itemBuilderData,
  previewSVG,
  selectedSkinName,
  selectedSlots,
  seatedSpineConfiguration,
  standingSpineConfiguration,
  resetState,
  setError,
  setPreviewSVG,
}: Step3Props) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const itemHasUserDefinedReplacementColor = !!Object.keys(selectedSlots).find(
    (slotName: string) => {
      return (
        images[slotName] &&
        images[slotName].toLowerCase().indexOf(userDefinedReserveColor) >= 0
      );
    }
  );

  const previewSVGFileIsRequired = Object.keys(selectedSlots).length > 1;
  const defaultSlotsDisabledByDefault: { [key: string]: boolean } = {};
  const defaultSlotModifiers: { [key: string]: string } = {};

  const getItemSVGs = () => {
    return Object.keys(selectedSlots)
      .map((slotName) => {
        return { [slotName]: images[slotName] };
      })
      .reduce((prev, cur) => {
        if (!prev || !cur) return prev;
        Object.keys(cur).map((key) => (prev[key] = cur[key]));
        return prev;
      });
  };

  const getAttachmentConfiguration = (skeletonType: AvatarSkeletonType) => {
    const spineConfig =
      skeletonType === AvatarSkeletonType.Seated
        ? seatedSpineConfiguration
        : standingSpineConfiguration;

    return (
      spineConfig?.skins
        .flatMap((skin) => {
          if (skin.name !== selectedSkinName) return;
          if (!skin.attachments) return;

          return Object.values(skin.attachments).flatMap((attachment) => {
            return Object.keys(selectedSlots)
              .map((slotName) => {
                if (attachment[slotName]) {
                  return {
                    [slotName]: {
                      ...attachment[slotName],
                      name: slotName,
                      path: slotName,
                    },
                  };
                }
              })
              .filter((a) => !!a);
          });
        })
        .reduce((prev, cur) => {
          if (!cur) return prev;
          if (!prev) return cur;
          Object.keys(cur).map((slotName) => (prev[slotName] = cur[slotName]));
          return prev;
        }) || {}
    );
  };

  const formik = useFormik({
    initialValues: {
      categoryId: "0",
      seasonId: "0",
      seasonLocationId: "0",
      seasonLocationOrder: "",
      avatarItemGroupId: "0",
      replaceAvatarItemId: "0",
      name: "",
      description: "",
      replacementColors: "",
      defaultReplacementColor: "",
      rarityLevel: "1",
      isCategoryDefault: false,
      isPublic: false,
      previewSVGFile: "",
      slotsDisabledByDefault: defaultSlotsDisabledByDefault,
      slotModifiers: defaultSlotModifiers,
      slotModifierHandLGroupAction: "",
      slotModifierHandRGroupAction: "",
    },
    validateOnMount: true,
    validationSchema: Yup.object({
      categoryId: Yup.number()
        .test("not-zero", "Category not selected", (value) =>
          value ? value > 0 : false
        )
        .required("A category is required"),
      name: Yup.string().trim().required("A name is required"),
      description: Yup.string().trim().required("A description is required"),
      replacementColors: (() => {
        return itemHasUserDefinedReplacementColor
          ? Yup.string().trim().required("Replacement colors are required")
          : Yup.string().trim();
      })(),
      defaultReplacementColor: (() => {
        return itemHasUserDefinedReplacementColor
          ? Yup.string()
              .trim()
              .required("Default replacement color is required")
          : Yup.string().trim();
      })(),
      previewSVGFile: (() => {
        return previewSVGFileIsRequired
          ? Yup.string().trim().required("Preview SVG is required")
          : Yup.string().trim();
      })(),
    }),
    onSubmit: (values) => {
      const name = values.name.trim();
      const description = values.description.trim();
      const replacementColors =
        (values.replacementColors &&
          values.replacementColors
            .trim()
            .toLowerCase()
            .replaceAll("#", "")
            .split("\n")
            .map((code) => "#" + code.trim())) ||
        [];
      const defaultReplacementColor = values.defaultReplacementColor.trim()
        ? "#" +
          values.defaultReplacementColor
            .toLowerCase()
            .replaceAll("#", "")
            .trim()
        : "";

      const itemSVGs = getItemSVGs();

      const seatedAttachmentConfigurations = getAttachmentConfiguration(
        AvatarSkeletonType.Seated
      );

      const standingAttachmentConfigurations = getAttachmentConfiguration(
        AvatarSkeletonType.Standing
      );

      const slotModifiers = values.slotModifiers;
      if (values.slotModifierHandLGroupAction) {
        slotModifierHandLGroupSlots.forEach((slotName) => {
          slotModifiers[slotName] = values.slotModifierHandLGroupAction;
        });
      }
      if (values.slotModifierHandRGroupAction) {
        slotModifierHandRGroupSlots.forEach((slotName) => {
          slotModifiers[slotName] = values.slotModifierHandRGroupAction;
        });
      }

      const dataStr =
        "data:text/json;charset=utf-8," +
        encodeURIComponent(
          JSON.stringify({
            categoryId: parseInt(values.categoryId, 10),
            seasonId: parseInt(values.seasonId, 10),
            seasonLocationId: parseInt(values.seasonLocationId, 10),
            seasonLocationOrder: parseInt(
              values.seasonLocationOrder || "0",
              10
            ),
            avatarItemGroupId: parseInt(values.avatarItemGroupId, 10),
            replaceAvatarItemId: parseInt(values.replaceAvatarItemId, 10),
            name,
            description,
            replacementColors,
            defaultReplacementColor,
            rarityLevel: values.rarityLevel,
            isPublic: values.isPublic,
            isCategoryDefault: values.isCategoryDefault,
            slotsDisabledByDefault: values.slotsDisabledByDefault,
            slotModifiers,
            seatedAttachmentConfigurations,
            standingAttachmentConfigurations,
            previewSVG,
            itemSVGs,
          })
        );

      const dlAnchorElem = document.createElement("a");
      dlAnchorElem.setAttribute("href", dataStr);
      dlAnchorElem.setAttribute(
        "download",
        `${name
          .toLowerCase()
          .replaceAll(" ", "_")}_${new Date().valueOf()}.json`
      );
      dlAnchorElem.click();

      resetState();
    },
  });

  const onPreviewSVGFileChange = (e: SyntheticEvent<HTMLInputElement>) => {
    if (!e.target) return;

    const target = e.target as HTMLInputElement;
    const file = target && target.files ? target.files[0] : null;
    if (!file) {
      setError("No preview file selected");
      return;
    }

    setError("");

    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = (e: ProgressEvent<FileReader>) => {
      const buffer = e.target?.result as ArrayBuffer;
      const decoder = new TextDecoder("utf-8");
      const decoded = decoder.decode(buffer);
      setPreviewSVG(decoded);
    };
    reader.onerror = () => {
      if (!reader.error) return;

      setError(reader.error.message);
    };
  };

  const replacementColors =
    (formik.values.replacementColors &&
      formik.values.replacementColors
        .trim()
        .replaceAll("#", "")
        .split("\n")
        .map((code) => "#" + code.trim().toUpperCase())) ||
    [];
  const defaultReplacementColor = formik.values.defaultReplacementColor
    ? "#" + formik.values.defaultReplacementColor.replaceAll("#", "").trim()
    : "";
  const previewHasHairReservedColor =
    previewSVG.toLowerCase().indexOf(hairReservedColor) >= 0;

  const getAvatarItem = (skeletonType: AvatarSkeletonType) => {
    return {
      id: "0",
      avatar_item_category_id: formik.values.categoryId,
      name: "",
      description: "",
      is_active: true,
      is_public: false,
      is_category_default: false,
      attachments: Object.keys(selectedSlots).map((slotName) => {
        return {
          id: "0",
          avatar_item_id: "0",
          avatar_slot_name: slotName,
          enabled_by_default: !formik.values.slotsDisabledByDefault[slotName],
          image_asset_url:
            "data:image/svg+xml;base64," + btoa(images[slotName]),
          data_asset_url:
            "data:application/json;base64," +
            btoa(
              JSON.stringify(getAttachmentConfiguration(skeletonType)[slotName])
            ),
        };
      }),
      default_replacement_hex_code: (
        "#" + formik.values.defaultReplacementColor
      )
        .replaceAll("##", "#")
        .toUpperCase(),
      colors: replacementColors.map((color) => {
        return {
          id: "0",
          avatar_item_id: "0",
          is_active: true,
          hex_code: ("#" + color).replaceAll("##", "#").toUpperCase(),
        };
      }),
      preview_image_asset_url: "",
      rarity: AvatarItemRarityLevels.Level1,
    };
  };

  return (
    <form
      onSubmit={formik.handleSubmit}
      style={{ width: "100%" }}
      autoComplete="off"
    >
      <Text>3. Enter item details</Text>

      <Table bgColor="white">
        <Tbody>
          <Tr>
            <Td w="300px">Selected Attachments Count</Td>
            <Td>{Object.keys(selectedSlots).length}</Td>
          </Tr>
          <Tr>
            <Td w="300px">Season</Td>
            <Td>
              <FormControl
                isInvalid={!!formik.errors.seasonId && formik.touched.seasonId}
              >
                <Select
                  id="seasonId"
                  name="seasonId"
                  value={formik.values.seasonId}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                >
                  {[
                    { id: "0", name: "-- Choose --" },
                    ...itemBuilderData.seasons,
                  ].map((season) => {
                    return (
                      <option key={season.id} value={season.id}>
                        {season.name}
                      </option>
                    );
                  })}
                </Select>

                <FormErrorMessage>
                  {formik.touched.seasonId && formik.errors.seasonId}
                </FormErrorMessage>
              </FormControl>
            </Td>
          </Tr>
          <Tr>
            <Td w="300px">Location</Td>
            <Td>
              <FormControl
                isInvalid={!!formik.errors.seasonId && formik.touched.seasonId}
              >
                <Select
                  id="seasonLocationId"
                  name="seasonLocationId"
                  value={formik.values.seasonLocationId}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                >
                  {[
                    { id: "0", name: "-- Choose --" },
                    ...itemBuilderData.season_locations,
                  ].map((seasonLocation) => {
                    return (
                      <option key={seasonLocation.id} value={seasonLocation.id}>
                        {seasonLocation.name}
                      </option>
                    );
                  })}
                </Select>

                <FormErrorMessage>
                  {formik.touched.seasonLocationId &&
                    formik.errors.seasonLocationId}
                </FormErrorMessage>
              </FormControl>
            </Td>
          </Tr>
          <Tr>
            <Td w="300px">Location Order</Td>
            <Td>
              <FormControl
                isRequired
                isInvalid={
                  !!formik.errors.seasonLocationOrder &&
                  formik.touched.seasonLocationOrder
                }
              >
                <Input
                  id="seasonLocationOrder"
                  name="seasonLocationOrder"
                  value={formik.values.seasonLocationOrder}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                <FormErrorMessage>
                  {formik.touched.seasonLocationOrder &&
                    formik.errors.seasonLocationOrder}
                </FormErrorMessage>
              </FormControl>
            </Td>
          </Tr>
          <Tr>
            <Td w="300px">Item Group</Td>
            <Td>
              <FormControl
                isInvalid={
                  !!formik.errors.avatarItemGroupId &&
                  formik.touched.avatarItemGroupId
                }
              >
                <Select
                  id="avatarItemGroupId"
                  name="avatarItemGroupId"
                  value={formik.values.avatarItemGroupId}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                >
                  {[
                    { id: "0", name: "-- Choose --" },
                    ...itemBuilderData.avatar_item_groups,
                  ].map((avatarItemGroup) => {
                    return (
                      <option
                        key={avatarItemGroup.id}
                        value={avatarItemGroup.id}
                      >
                        {avatarItemGroup.name}
                      </option>
                    );
                  })}
                </Select>

                <FormErrorMessage>
                  {formik.touched.avatarItemGroupId &&
                    formik.errors.avatarItemGroupId}
                </FormErrorMessage>
              </FormControl>
            </Td>
          </Tr>
          <Tr>
            <Td w="300px">Category</Td>
            <Td>
              <FormControl
                isRequired
                isInvalid={
                  !!formik.errors.categoryId && formik.touched.categoryId
                }
              >
                <Select
                  id="categoryId"
                  name="categoryId"
                  value={formik.values.categoryId}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                >
                  {[
                    { id: "0", name: "-- Choose --" },
                    ...itemBuilderData.avatar_item_categories,
                  ].map((itemCategory) => {
                    return (
                      <option key={itemCategory.id} value={itemCategory.id}>
                        {itemCategory.name}
                      </option>
                    );
                  })}
                </Select>

                <FormErrorMessage>
                  {formik.touched.categoryId && formik.errors.categoryId}
                </FormErrorMessage>
              </FormControl>
            </Td>
          </Tr>
          <Tr>
            <Td w="300px">Name</Td>
            <Td>
              <FormControl
                isRequired
                isInvalid={!!formik.errors.name && formik.touched.name}
              >
                <Input
                  id="name"
                  name="name"
                  value={formik.values.name}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                <FormErrorMessage>
                  {formik.touched.name && formik.errors.name}
                </FormErrorMessage>
              </FormControl>
            </Td>
          </Tr>
          <Tr>
            <Td>Description</Td>
            <Td>
              <FormControl
                isRequired
                isInvalid={
                  !!formik.errors.description && formik.touched.description
                }
              >
                <Textarea
                  id="description"
                  name="description"
                  value={formik.values.description}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                <FormErrorMessage>
                  {formik.touched.description && formik.errors.description}
                </FormErrorMessage>
              </FormControl>
            </Td>
          </Tr>
          {itemHasUserDefinedReplacementColor && (
            <>
              <Tr>
                <Td>Replacement color options</Td>
                <Td>
                  <FormControl
                    isRequired
                    isInvalid={
                      !!formik.errors.replacementColors &&
                      formik.touched.replacementColors
                    }
                  >
                    <Textarea
                      fontFamily="monospace"
                      placeholder="000000&#10;ffffff"
                      id="replacementColors"
                      name="replacementColors"
                      value={formik.values.replacementColors}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                    <FormErrorMessage>
                      {formik.touched.replacementColors &&
                        formik.errors.replacementColors}
                    </FormErrorMessage>
                  </FormControl>
                  <HStack mt="12px">
                    {replacementColors &&
                      replacementColors.map((color, i) => {
                        return (
                          <Box
                            key={i}
                            bgColor={color}
                            w="40px"
                            h="40px"
                            borderColor="black"
                            borderWidth="1px"
                          ></Box>
                        );
                      })}
                  </HStack>
                </Td>
              </Tr>
              <Tr>
                <Td>Default replacement color</Td>
                <Td>
                  <FormControl
                    isRequired
                    isInvalid={
                      !!formik.errors.defaultReplacementColor &&
                      formik.touched.defaultReplacementColor
                    }
                  >
                    <Input
                      id="defaultReplacementColor"
                      name="defaultReplacementColor"
                      placeholder="ffffff"
                      fontFamily="monospace"
                      value={formik.values.defaultReplacementColor}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                    <FormErrorMessage>
                      {formik.touched.defaultReplacementColor &&
                        formik.errors.defaultReplacementColor}
                    </FormErrorMessage>
                  </FormControl>
                  {defaultReplacementColor && (
                    <Box
                      key={defaultReplacementColor}
                      bgColor={defaultReplacementColor}
                      mt="12px"
                      w="40px"
                      h="40px"
                      borderColor="black"
                      borderWidth="1px"
                    ></Box>
                  )}
                </Td>
              </Tr>
            </>
          )}
          <Tr>
            <Td>Rarity</Td>
            <Td>
              <HStack spacing="24px">
                {Object.keys(AvatarItemRarityLevels).map((rarityLevel) => {
                  if (rarityLevel.startsWith("Level")) return;

                  return (
                    <Box key={rarityLevel}>
                      <Radio
                        id="rarityLevel"
                        name="rarityLevel"
                        value={rarityLevel}
                        isChecked={formik.values.rarityLevel === rarityLevel}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />{" "}
                      {rarityLevel}
                    </Box>
                  );
                })}
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Td>Public</Td>
            <Td>
              <Checkbox
                id="isPublic"
                name="isPublic"
                checked={formik.values.isPublic}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                This item is available to all users
              </Checkbox>
            </Td>
          </Tr>
          <Tr>
            <Td>Attachment Defaults</Td>
            <Td>
              Select the attachments that should{" "}
              <b>
                <em>not</em>
              </b>{" "}
              be rendered by default, subject to slot modifier rules (should
              only be changed for special cases like hairstyles).
              {Object.keys(selectedSlots).map((slotName, i) => {
                return (
                  <Box key={i} mt="8px">
                    <Checkbox
                      id={`slotsDisabledByDefault[${slotName}]`}
                      name={`slotsDisabledByDefault[${slotName}]`}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      onLoad={formik.handleChange}
                    >
                      {slotName}
                    </Checkbox>
                  </Box>
                );
              })}
            </Td>
          </Tr>
          <Tr>
            <Td>Slot Modifiers</Td>
            <Td>
              Enter the actions to perform on these slots (should only be
              changed for special cases like headwear). N/A performs no action.
              ENABLE is used to enable (show) slots that for items that do not
              show them by default. DISABLE is used to disable (hide) slots for
              items that are shown by default.
              <Table>
                <Tbody>
                  {slotModifierSlots.map((slotName, i) => {
                    return (
                      <Tr key={i}>
                        <Td>{slotName}</Td>
                        <Td>
                          <Select
                            id={`slotModifiers[${slotName}]`}
                            name={`slotModifiers[${slotName}]`}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                          >
                            {slotModifierActions.map((action, i) => {
                              return (
                                <option key={i} value={action}>
                                  {action}
                                </option>
                              );
                            })}
                          </Select>
                        </Td>
                      </Tr>
                    );
                  })}
                  <Tr>
                    <Td>Empty Left Hand (Group Modifier)</Td>
                    <Td>
                      <Select
                        id="slotModifierHandLGroupAction"
                        name="slotModifierHandLGroupAction"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      >
                        {slotModifierActions.map((action, i) => {
                          return (
                            <option key={i} value={action}>
                              {action}
                            </option>
                          );
                        })}
                      </Select>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Empty Right Hand (Group Modifier)</Td>
                    <Td>
                      <Select
                        id="slotModifierHandRGroupAction"
                        name="slotModifierHandRGroupAction"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      >
                        {slotModifierActions.map((action, i) => {
                          return (
                            <option key={i} value={action}>
                              {action}
                            </option>
                          );
                        })}
                      </Select>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </Td>
          </Tr>
          <Tr>
            <Td>Category Default</Td>
            <Td>
              <Checkbox
                id="isCategoryDefault"
                name="isCategoryDefault"
                checked={formik.values.isCategoryDefault}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                This is the default item for the category
                <Alert status="warning">
                  If selected, this will replace any current default for the
                  category
                </Alert>
              </Checkbox>
            </Td>
          </Tr>
          <Tr>
            <Td>Preview SVG</Td>
            <Td>
              <FormControl
                isRequired={previewSVGFileIsRequired}
                isInvalid={!!formik.errors.previewSVGFile}
              >
                <Input
                  id="previewSVGFile"
                  name="previewSVGFile"
                  type="file"
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    onPreviewSVGFileChange(e);
                    formik.handleChange(e);
                  }}
                  accept="image/svg+xml"
                />
                {!previewSVG &&
                  Object.keys(selectedSlots).length === 1 &&
                  itemHasUserDefinedReplacementColor && (
                    <HStack mt="12px">
                      {replacementColors.map((color, i) => {
                        return (
                          <Image
                            key={i}
                            maxH="128px"
                            maxW="128px"
                            src={`data:image/svg+xml;base64,${svgToBase64(
                              images[Object.keys(selectedSlots)[0]],
                              userDefinedReserveColor,
                              color
                            )}`}
                          />
                        );
                      })}
                    </HStack>
                  )}
                {previewSVG && itemHasUserDefinedReplacementColor && (
                  <HStack mt="12px">
                    {replacementColors.map((color, i) => {
                      return (
                        <Image
                          key={i}
                          maxH="128px"
                          maxW="128px"
                          src={`data:image/svg+xml;base64,${svgToBase64(
                            previewSVG,
                            userDefinedReserveColor,
                            color
                          )}`}
                        />
                      );
                    })}
                  </HStack>
                )}
                {previewSVG && previewHasHairReservedColor && (
                  <HStack mt="12px">
                    {hairColorOptions.map((hairColor, i) => {
                      return (
                        <Image
                          key={i}
                          maxH="128px"
                          maxW="128px"
                          src={`data:image/svg+xml;base64,${svgToBase64(
                            previewSVG,
                            hairReservedColor,
                            hairColor
                          )}`}
                        />
                      );
                    })}
                  </HStack>
                )}
                <FormErrorMessage>
                  {formik.errors.previewSVGFile}
                </FormErrorMessage>
              </FormControl>
            </Td>
          </Tr>
          <Tr>
            <Td w="300px">Replace Avatar Item</Td>
            <Td>
              <FormControl
                isInvalid={
                  !!formik.errors.replaceAvatarItemId &&
                  formik.touched.replaceAvatarItemId
                }
              >
                <Select
                  id="replaceAvatarItemId"
                  name="replaceAvatarItemId"
                  value={formik.values.replaceAvatarItemId}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                >
                  {[
                    { id: "0", name: "-- Choose --", season_id: "0" },
                    ...itemBuilderData.avatar_items,
                  ].map((avatarItem) => {
                    return (
                      <option key={avatarItem.id} value={avatarItem.id}>
                        {avatarItem.name}{" "}
                        {avatarItem.id !== "0" &&
                          `(Season: ${
                            itemBuilderData.seasons.find(
                              (season) => season.id === avatarItem.season_id
                            )?.name || "None"
                          }, ID: ${avatarItem.id})`}
                      </option>
                    );
                  })}
                </Select>

                <FormErrorMessage>
                  {formik.touched.replaceAvatarItemId &&
                    formik.errors.replaceAvatarItemId}
                </FormErrorMessage>
              </FormControl>
            </Td>
          </Tr>
        </Tbody>
      </Table>

      <Center>
        <ButtonGroup mt="20px">
          <Button
            colorScheme="blue"
            disabled={!formik.isValid}
            onClick={onOpen}
          >
            Preview
          </Button>

          <Button
            colorScheme="blue"
            disabled={!formik.isValid}
            onClick={formik.submitForm}
          >
            Save
          </Button>
        </ButtonGroup>
      </Center>

      {isOpen && (
        <Modal size="6xl" isOpen={isOpen} onClose={onClose}>
          <HStack w="full" justifyContent="space-between">
            <AvatarPreview
              item={getAvatarItem(AvatarSkeletonType.Seated)}
              skeleton_type={AvatarSkeletonType.Seated}
              spine_config_seated={JSON.stringify(seatedSpineConfiguration)}
              spine_config_standing={JSON.stringify(standingSpineConfiguration)}
            />
            <AvatarPreview
              item={getAvatarItem(AvatarSkeletonType.Standing)}
              skeleton_type={AvatarSkeletonType.Standing}
              spine_config_seated={JSON.stringify(seatedSpineConfiguration)}
              spine_config_standing={JSON.stringify(standingSpineConfiguration)}
            />
          </HStack>
        </Modal>
      )}
    </form>
  );
};
