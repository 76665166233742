import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { useAxios } from "../../hooks/useAxios";

export interface IDeleteAssignmentArgs {
  id: string;
  classroom_id: string;
}

export interface IUseDeleteAssignmentArgs {
  onSuccess?: () => void;
  onError?: () => void;
  onSettled?: () => void;
}

/**
 * Deletes an assignment
 * @returns
 */
export default function useDeleteAssignment(
  args: IUseDeleteAssignmentArgs
): UseMutationResult<unknown, unknown, IDeleteAssignmentArgs> {
  const axios = useAxios();
  const queryClient = useQueryClient();

  const deleteAssignment = async ({
    id,
    classroom_id,
  }: IDeleteAssignmentArgs) => {
    const response = await axios({
      method: "delete",
      url: `/v1/assignments/${id}`,
      data: {},
    }).then((r) => r.data);

    queryClient.invalidateQueries(["assignments", { classroom_id }]);
    queryClient.setQueryData(["assignments", { id: id }], undefined);

    return response;
  };

  const mutation = useMutation(deleteAssignment, {
    onError: args.onError,
    onSuccess: args.onSuccess,
    onSettled: args.onSettled,
  });

  return mutation;
}
