import React from "react";

import { Icon, IconType } from "adminComponents/atoms/Icon";
import { IconTooltip } from "adminComponents/atoms/IconTooltip";
import { pxToRem } from "adminComponents/utils/pxToRem";

export interface IToolbarButtonProps {
  disabled?: boolean;
  label: string;
  handleMouseDown: () => void;
  handleKeyDown?: () => void;
  isActive?: boolean;
  icon: IconType;
}

const ToolbarButton: React.FC<IToolbarButtonProps> = ({
  disabled,
  icon,
  label,
  handleMouseDown,
  handleKeyDown,
  isActive,
}) => {
  const handleMouseDownEvent = (e: React.MouseEvent<HTMLDivElement>) => {
    if (disabled) return;
    // toolbar buttons should not change focus
    e.preventDefault();
    handleMouseDown();
  };

  const handleKeyDownEvent = (e: React.KeyboardEvent) => {
    if (disabled) return;

    if (handleKeyDown && e.key === "Enter") {
      e.preventDefault();
      handleKeyDown();
    }
  };

  return (
    <IconTooltip
      popoverVariant="adminRichTextToolbar"
      placement="top"
      triggerEl={
        <Icon
          iconColor={disabled ? "primary.light-gray" : "primary.dark-gray"}
          backgroundColor={isActive ? "primary.golden" : "primary.white"}
          onMouseDown={handleMouseDownEvent}
          icon={icon}
          variant="toolbarIcon"
          p={pxToRem(4)}
        />
      }
      handleKeyDown={handleKeyDownEvent}
    >
      {label}
    </IconTooltip>
  );
};

export default ToolbarButton;
