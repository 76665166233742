import { useTranslation } from "react-i18next";

import { useSessionGameState } from "links/lib/contexts/sessionGameState";
import { useSessionUsers } from "links/lib/contexts/sessionUsers";
import { useShortName } from "links/lib/hooks/useShortName";
import { BigBoardGameState } from "links/lib/types";

export const useBigBoardSpinHeaderTitleText = (userId?: string): string => {
  const gameState = useSessionGameState() as BigBoardGameState;
  const users = useSessionUsers();
  const { t } = useTranslation("session", { useSuspense: false });

  const spinningUser =
    users[gameState?.the_big_board_state?.prize_round_user_id || "0"];
  const spinningUserName = useShortName(spinningUser?.name || "");

  if (gameState?.the_big_board_state?.prize_round_user_id === userId) {
    return t("bigBoardSpin.selfIsSpinningTitle", {
      studentName: spinningUserName,
    });
  } else {
    return t("bigBoardSpin.studentIsSpinningTitle", {
      studentName: spinningUserName,
    });
  }
};
