import {
  Box,
  Modal as ChakraModal,
  Flex,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  useBreakpointValue,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Heading } from "adminComponents/atoms/Heading";
import {
  IconCTAButtonWrapper as CloseButtonWrapper,
  IconCTAButton,
} from "adminComponents/atoms/IconCTAButton";
import { Text } from "adminComponents/atoms/Text";
import { Modal as ModalTheme } from "adminComponents/theme/components/Modal";
import { pxToRem } from "adminComponents/utils/pxToRem";

export const variants = Object.keys(ModalTheme.variants);

export interface IConfirmModalProps
  extends Omit<ModalProps, "children" | "onClose"> {
  buttonCancelLabel: string;
  buttonOKLabel: string;
  description?: string | JSX.Element;
  hideClose?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  // True if the user confirmed; false if they cancelled; undefined/null if they closed without confirming or cancelling
  handleClose: (isConfirmed?: boolean) => void;
  mobileButtonsDirection?: "horizontal" | "vertical";
  title: string;
}

const ConfirmModal: React.FC<IConfirmModalProps> = ({
  buttonCancelLabel,
  buttonOKLabel,
  children,
  description,
  hideClose = false,
  isDisabled = false,
  isLoading,
  handleClose,
  mobileButtonsDirection = "horizontal",
  size = "2xl",
  title,
  variant = "adminModal",
  ...rest
}) => {
  const isCompactMobile = variant === "adminModalCompactMobile";
  const { t } = useTranslation("admin", { useSuspense: false });

  const breakPointSize = useBreakpointValue({
    base: isCompactMobile ? "sm" : "full",
    md: size,
  });

  const styles = useMultiStyleConfig("Modal", {
    variant,
    horizontalMobileButtonsDirection: mobileButtonsDirection === "horizontal",
  });

  return (
    <ChakraModal
      closeOnOverlayClick={!isLoading}
      onClose={() => handleClose()}
      size={breakPointSize}
      variant={variant}
      {...rest}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading as="h2" variant="adminH6">
            {title}
          </Heading>
        </ModalHeader>
        {!hideClose && (
          <CloseButtonWrapper
            top={pxToRem(24)}
            right={pxToRem(24)}
            left="unset"
          >
            <IconCTAButton
              ariaLabel={t("common.close")}
              onClick={() => handleClose()}
            />
          </CloseButtonWrapper>
        )}
        <ModalBody>
          {description && typeof description === "string" && (
            <Text color="primary.dark-gray" mt={pxToRem(8)} variant="adminP1">
              {description}
            </Text>
          )}
          {description && typeof description === "object" && description}
          {children && <Box mt={pxToRem(24)}>{children}</Box>}
          <Flex sx={styles.confirmButtonsContainer}>
            <Button
              variant="adminButtonFilled"
              size="lg"
              w={["100%", null, "auto"]}
              isDisabled={isDisabled}
              isLoading={isLoading}
              onClick={() => handleClose(true)}
            >
              {buttonOKLabel}
            </Button>
            <Button
              variant="adminButtonOutlined"
              size="lg"
              w={["100%", null, "auto"]}
              isLoading={isLoading}
              onClick={() => handleClose(false)}
            >
              {buttonCancelLabel}
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </ChakraModal>
  );
};

export { ConfirmModal };
