import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { UserXPMultiplier } from "../../types";

export interface ICreateSocialEventReactionResponse {
  user_xp_multiplier: UserXPMultiplier;
}

export interface ICreateSocialEventReactionArgs {
  social_event_id: string;
}

export interface IUseCreateSocialEventReactionProps {
  onSuccess?: (data: ICreateSocialEventReactionResponse) => void;
  onError?: () => void;
  onSettled?: () => void;
}

export default function useCreateSocialEventReaction(
  props?: IUseCreateSocialEventReactionProps
): UseMutationResult<
  ICreateSocialEventReactionResponse,
  unknown,
  ICreateSocialEventReactionArgs
> {
  const axios = useAxios();
  const queryClient = useQueryClient();

  const mutateSocialEventReaction = async (
    args: ICreateSocialEventReactionArgs
  ) => {
    const response = await axios
      .post(`/v1/social/events/${args.social_event_id}/react`)
      .then((r) => r.data as ICreateSocialEventReactionResponse);

    queryClient.invalidateQueries(["socialEvents"]);
    queryClient.invalidateQueries("sessions");

    return response;
  };

  const mutation = useMutation(mutateSocialEventReaction, {
    onError: props?.onError,
    onSuccess: props?.onSuccess,
    onSettled: props?.onSettled,
  });

  return mutation;
}
