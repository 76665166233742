import { UseQueryResult, useQuery } from "react-query";

import { ITeacherOnboardingChecklist } from "links/lib/types";

import { useAxios } from "../../hooks/useAxios";

export interface IFetchTeacherOnboardingChecklistParams {
  userId?: string;
  disabled?: boolean;
}

export default function useFetchTeacherOnboardingChecklist(
  args: IFetchTeacherOnboardingChecklistParams
): UseQueryResult<ITeacherOnboardingChecklist> {
  const axios = useAxios();

  const { disabled, userId } = args;

  const query = useQuery<ITeacherOnboardingChecklist>(
    ["onboarding-checklist", { userId }],
    async (): Promise<ITeacherOnboardingChecklist> => {
      return await axios
        .get("/v1/users/onboarding-checklist")
        .then((r) => r.data as ITeacherOnboardingChecklist);
    },
    {
      enabled: !disabled && !!userId,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  return query;
}
