import {
  Heading as ChakraHeading,
  HeadingProps as ChakraHeadingProps,
  useStyleConfig,
} from "@chakra-ui/react";
import React, { RefObject } from "react";

export interface IHeadingProps extends ChakraHeadingProps {
  as: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p";
  headingRef?: RefObject<HTMLInputElement>;
  variant:
    | "adminH1"
    | "adminH2"
    | "adminH3"
    | "adminH3Bold"
    | "adminH3TTCommons"
    | "adminH4"
    | "adminH5"
    | "adminH5Bold"
    | "adminH5Gooper"
    | "adminH6"
    | "adminH6Bold"
    | "adminH7"
    | "adminH7Bold";
}

export const Heading: React.FC<IHeadingProps> = ({
  as,
  children,
  color,
  headingRef,
  sx,
  variant,
  ...rest
}) => {
  const styles = useStyleConfig("AdminHeading", { color, variant });

  return (
    <ChakraHeading {...rest} as={as} ref={headingRef} sx={{ ...styles, ...sx }}>
      {children}
    </ChakraHeading>
  );
};
