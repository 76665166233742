import moment from "moment";
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import { IProps as NavigationData } from "adminComponents/molecules/StudentNavigationHeader";
import { MyAssignmentsFlyout } from "adminComponents/organisms/MyAssignmentsFlyout";
import { useErrorToast } from "adminComponents/utils/toast";
import { useAnalytics } from "lib/contexts/analytics";
import { useAuth } from "links/lib/features/auth";
import { useFetchCurrentSeason } from "links/lib/features/season";
import { useFetchPracticeSessionsStreak } from "links/lib/features/sessions";
import { AnalyticsEvent, IAssignment, UserRole } from "links/lib/types";
import { isSameDate } from "links/lib/util";
import { useShowNewLibrary } from "sharedComponents/hooks/useShowNewLibrary";
import { useStartAssignment } from "sharedComponents/hooks/useStartAssignment";

import { useStudentDashboardData } from "./StudentDashboardDataProvider";

export interface INavigationDataContext {
  navigationData: NavigationData;
  handleOpenAssignmentFlyout: () => void;
}

export const NavigationDataContext = createContext<INavigationDataContext>({
  navigationData: {
    logoLink: {
      label: "",
      to: "",
    },
    navigationLinks: [],
    accountLinks: [],
    user: {
      id: "",
      email: "",
      role: UserRole.Student,
      true_role: UserRole.Student,
      license_id: "",
      timezone_locale: "",
      language: "",
      country: "",
      region: "",
      is_guest: false,
    },
    isLoading: true,
    season: "Season 0",
    handleAssignmentIconClick: () => {
      return;
    },
    handleEditAvatar: () => {
      return;
    },
    handleStudentIconClick: () => {
      return;
    },
  },
  handleOpenAssignmentFlyout: () => {
    return;
  },
});

export const useNavigationData = (): INavigationDataContext => {
  return useContext(NavigationDataContext);
};

const StudentNavigationDataProvider: React.FC = ({ children }) => {
  const { authUser } = useAuth();
  const newLibraryFeatureEnabled = useShowNewLibrary();
  const { t } = useTranslation("admin", {
    keyPrefix: "studentNavigationHeader",
    useSuspense: false,
  });
  const history = useHistory();
  const [isAssignmentFlyoutOpen, setIsAssignmentFlyoutOpen] = useState(false);
  const { trackEvent } = useAnalytics();
  const { activeAssignments, practiceSetDetailMap } = useStudentDashboardData();

  const location = useLocation();
  const topPathName = location.pathname.split("/").slice(0, 3).join("/");

  const fetchStreak = useFetchPracticeSessionsStreak();
  const fetchSeason = useFetchCurrentSeason();
  const dashboardData = useStudentDashboardData();

  useErrorToast(fetchStreak.error);

  const outstandingAssignments = activeAssignments.filter((a) => {
    return (
      !a.best_user_attempts.length ||
      a.best_user_attempts[0].accuracy < a.required_score_percent
    );
  });

  // create as seperate function so that other screens
  // can call without triggering incorrect AnalyticsEvent
  const handleOpenAssignmentFlyout = useCallback(() => {
    setIsAssignmentFlyoutOpen(true);
  }, []);

  const handleAssignmentIconClick = useCallback(() => {
    handleOpenAssignmentFlyout();

    trackEvent(
      AnalyticsEvent.StudentDashboard_Nav_AssignmentFlyout_OpenFlyout,
      {
        hasActiveAssignments: !!activeAssignments.length,
        hasOutstandingAssignments: !!outstandingAssignments.length,
      }
    );
  }, [
    trackEvent,
    activeAssignments.length,
    outstandingAssignments.length,
    handleOpenAssignmentFlyout,
  ]);

  const handleEditAvatar = useCallback(() => {
    history.push("/s/locker");
  }, [history]);

  const handleStudentIconClick = useCallback(() => {
    trackEvent(AnalyticsEvent.StudentDashboard_Nav_OpenProfileMenu, {});
  }, [trackEvent]);

  const handleGrantAccess = () => {
    trackEvent(
      AnalyticsEvent.StudentDashboard_Nav_AssignmentFlyout_Grant_Access,
      {}
    );
  };

  const handleStartAssignment = (assignment: IAssignment) => {
    trackEvent(
      AnalyticsEvent.StudentDashboard_Nav_AssignmentFlyout_PlayAssignment,
      {
        assignmentId: assignment.id,
      }
    );
  };

  const {
    authorizeModal,
    startAssignment,
    pendingAssignment: pendingSession,
  } = useStartAssignment({
    assignments: activeAssignments,
    handleGrantAccess,
    handleStartAssignment,
  });

  const handlePlayButton = (assignment: IAssignment) => {
    startAssignment(assignment);
  };

  const handleAssignmentFlyoutClose = () => {
    setIsAssignmentFlyoutOpen(false);
  };

  const navigationData: INavigationDataContext = useMemo(() => {
    const getPageTitle = () => {
      switch (topPathName) {
        case "/s/home":
          return t("navigationLinks.home");
        case "/s/my-classes":
          return t("navigationLinks.myClasses");
        case "/s/season-map":
          return t("navigationLinks.seasonMap");
        case "/s/locker":
          return t("navigationLinks.locker");
      }
    };

    const getSeasonDaysLeft = () => {
      if (!fetchSeason.data) return 0;

      return moment(fetchSeason.data.season.end_date).diff(moment(), "days");
    };

    const navigationLinks = [
      {
        to: "/s/home",
        label: t("navigationLinks.home"),
      },
      {
        to: "/s/my-classes",
        label: t("navigationLinks.myClasses"),
      },
      {
        to: "/s/season-map",
        label: t("navigationLinks.seasonMap"),
      },
      {
        to: "/s/locker",
        label: t("navigationLinks.locker"),
      },
    ];

    const data = {
      navigationData: {
        pageTitle: getPageTitle(),
        isLoading: fetchStreak.isLoading || fetchSeason.isLoading,
        season: fetchSeason.data?.season.name || "",
        daysLeft: getSeasonDaysLeft(),
        assignmentCount: outstandingAssignments.length,
        handleAssignmentIconClick,
        handleEditAvatar,
        handleStudentIconClick,
        currentWeekdayCount: fetchStreak.data?.streak.current_week_day_count,
        weekStreak: fetchStreak.data?.streak.week_count,
        logoLink: {
          label: t("navigationLinks.home"),
          to: "/s/home",
        },
        breadcrumbs: [],
        navigationLinks,
        accountLinks: [
          {
            to: "/s/account",
            label: t("accountLinks.account"),
          },
          {
            to: "/s/getting-started",
            label: t("accountLinks.gettingStarted"),
          },
          {
            to: "/sign-out",
            label: t("accountLinks.signOut"),
          },
        ],
        activeNavigationPath: topPathName,
        userXPMultiplier: dashboardData.userXpMultiplier,
        user: authUser,
      },
      handleOpenAssignmentFlyout,
    };

    if (
      authUser?.true_role === UserRole.Admin ||
      authUser?.true_role === UserRole.Teacher ||
      authUser?.true_role === UserRole.ContentSpecialist
    ) {
      data.navigationData.accountLinks = [
        {
          to: newLibraryFeatureEnabled ? "/t/explore" : "/t/home",
          label: t("accountLinks.teacherMode"),
        },
        ...data.navigationData.accountLinks,
      ];
    }

    return data;
  }, [
    authUser,
    t,
    topPathName,
    handleAssignmentIconClick,
    handleEditAvatar,
    handleStudentIconClick,
    fetchStreak.isLoading,
    fetchStreak.data,
    fetchSeason.data,
    fetchSeason.isLoading,
    outstandingAssignments.length,
    dashboardData.userXpMultiplier,
    handleOpenAssignmentFlyout,
    newLibraryFeatureEnabled,
  ]);

  const now = new Date();

  return (
    <>
      <NavigationDataContext.Provider value={navigationData}>
        {children}
      </NavigationDataContext.Provider>

      <MyAssignmentsFlyout
        isOpen={isAssignmentFlyoutOpen}
        handleClose={handleAssignmentFlyoutClose}
        handlePlayButton={handlePlayButton}
        pendingSession={pendingSession}
        myAssignments={activeAssignments.map((assignment) => {
          const { best_user_attempts, practice_set_id } = assignment;

          return {
            assignment,
            accuracy: best_user_attempts.length
              ? best_user_attempts[0].accuracy
              : 0,
            rewardXp: practiceSetDetailMap[practice_set_id]?.maximum_xp ?? 0,
            isDueToday: isSameDate(now, new Date(assignment.ends_at)),
          };
        })}
      />
      {authorizeModal}
    </>
  );
};

export { StudentNavigationDataProvider };
