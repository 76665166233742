import { Box, HStack, VStack } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { Button } from "adminComponents/atoms/Button";
import { CoverImageIcon } from "adminComponents/atoms/CoverImageIcon";
import { Logo } from "adminComponents/atoms/Logo";
import { Text } from "adminComponents/atoms/Text";
import { TemplateWithVerticalCenteredContent } from "adminComponents/templates/TemplateWithVerticalCenteredContent";
import { pxToRem } from "adminComponents/utils";

interface IExtendIndividualTrialScreenProps {
  hasExtendedTrial: boolean;
  canExtendTrial: boolean;
  isLoading: boolean;
  extensionDays?: number;
  handleExtendTrial: () => void;
  descriptionCannotExtendLicense?: string;
}

export const ExtendIndividualTrialScreen: React.FC<
  IExtendIndividualTrialScreenProps
> = ({
  canExtendTrial,
  isLoading,
  hasExtendedTrial,
  extensionDays = 30,
  handleExtendTrial,
  descriptionCannotExtendLicense,
}) => {
  const history = useHistory();
  const { t } = useTranslation("admin", {
    keyPrefix: "extendIndividualTrialScreen",
  });

  const header = (
    <HStack
      w="full"
      justifyContent={["center", null, "space-between"]}
      alignItems="center"
      mt={pxToRem(30)}
      mb={[pxToRem(30), null, pxToRem(0)]}
    >
      <Box w={pxToRem(154)}>
        <Logo w={pxToRem(154)} />
      </Box>
    </HStack>
  );

  const onGoHome = () => {
    history.push("/");
  };

  return (
    <TemplateWithVerticalCenteredContent
      isLoading={isLoading}
      headerContent={header}
    >
      <Box mb={pxToRem(30)}>
        {canExtendTrial && (
          <VStack spacing={pxToRem(48)}>
            <VStack spacing={pxToRem(16)}>
              <CoverImageIcon icon="OTHER_PARTY_FUN" boxSize={pxToRem(128)} />
              <Text variant="adminH2">{t("headingCanExtendLicense")}</Text>
              <Text>{t("descriptionCanExtendLicense", { extensionDays })}</Text>
            </VStack>
            <Button
              w="full"
              variant="adminButtonFilled"
              onClick={handleExtendTrial}
            >
              {t("buttonExtendLicense")}
            </Button>
          </VStack>
        )}

        {hasExtendedTrial && (
          <VStack spacing={pxToRem(48)}>
            <VStack spacing={pxToRem(16)}>
              <Text variant="adminH2">{t("headingHasExtendedLicense")}</Text>
              <Text>
                {t("descriptionHasExtendedLicense", { extensionDays })}
              </Text>
            </VStack>
            <Button w="full" variant="adminButtonFilled" onClick={onGoHome}>
              {t("buttonGoToHomePage")}
            </Button>
          </VStack>
        )}

        {!hasExtendedTrial && !canExtendTrial && (
          <VStack spacing={pxToRem(48)}>
            <VStack spacing={pxToRem(16)}>
              <Text variant="adminH2">{t("headingCannotExtendLicense")}</Text>
              <Text>
                {descriptionCannotExtendLicense ||
                  t("descriptionCannotExtendLicense")}
              </Text>
            </VStack>
            <Button w="full" variant="adminButtonFilled" onClick={onGoHome}>
              {t("buttonGoToHomePage")}
            </Button>
          </VStack>
        )}
      </Box>
    </TemplateWithVerticalCenteredContent>
  );
};
