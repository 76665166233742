import { pxToRem } from "adminComponents/utils";
import { ScreenOrientation } from "sessionComponents/types";

export const getBigBoardContainerHeight = (isPortrait: boolean): string =>
  isPortrait ? "55%" : "100%";

export const getBigBoardSecondaryContentContainerHeight = (
  isPortrait: boolean
): string => (isPortrait ? "45%" : "100%");

export const getOrientation = (): ScreenOrientation => {
  const isPortrait =
    window.matchMedia("(orientation: portrait)").matches ||
    window.innerWidth < 801; // force portrait mode for all screens under 801px width
  const orientation = isPortrait
    ? ScreenOrientation.Portrait
    : ScreenOrientation.Landscape;

  return orientation;
};

export const getImageResponseStyleProps = (
  isImageResponse: boolean,
  padding: number
): { [key: string]: string } => {
  return isImageResponse
    ? {
        display: "grid",
        gridGap: pxToRem(padding / 2),
        gridTemplateColumns: "repeat(2, minmax(0px, 1fr))",
        width: "fit-content",
        margin: "0 auto",
        pb: pxToRem(padding),
      }
    : {};
};
