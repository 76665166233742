import { useMemo } from "react";

import { IStandardProps } from "adminComponents/screens/StudentDetailScreen/components/StandardsTab";
import { useErrorToast } from "adminComponents/utils/toast";
import { useFetchClassroomStandardAccuracies } from "links/lib/features/classrooms";
import { useFetchStandards } from "links/lib/features/standards";

interface IUseStandardsParams {
  classroomId: string;
  studentId: string;
}

interface IUseStandardsResponse {
  isLoading: boolean;
  standardsData: IStandardProps[];
}

export const useStandardsData = ({
  classroomId,
  studentId,
}: IUseStandardsParams): IUseStandardsResponse => {
  const standardsAccuraciesFetch = useFetchClassroomStandardAccuracies({
    classroom_id: classroomId,
    user_id: studentId,
  });

  const standardsAccuracies = useMemo(
    () => standardsAccuraciesFetch.data?.average_standard_accuracies || [],
    [standardsAccuraciesFetch.data?.average_standard_accuracies]
  );

  const standardsFetch = useFetchStandards({
    ids: standardsAccuracies.map((a) => a.standard_id),
    enabled: !!standardsAccuracies.length,
    limit: standardsAccuracies.length || 1,
    offset: 0,
  });

  useErrorToast(standardsAccuraciesFetch.error);
  useErrorToast(standardsFetch.error);

  const standardsData = useMemo(() => {
    if (
      !!standardsAccuracies.length &&
      !!standardsFetch.data?.standards.length
    ) {
      const standardsMap = new Map<string, number>();
      standardsAccuracies.forEach((standardAccuracy) =>
        standardsMap.set(
          standardAccuracy.standard_id,
          standardAccuracy.average_accuracy
        )
      );

      return standardsFetch.data?.standards.map((standard) => ({
        standard: standard,
        accuracy: Math.round((standardsMap.get(standard.id) || 0) * 100),
      }));
    }
    return [];
  }, [standardsAccuracies, standardsFetch.data]);

  const isLoading =
    standardsAccuraciesFetch.isLoading && standardsFetch.isLoading;

  return {
    isLoading,
    standardsData,
  };
};
