import { Flex, HStack, Spacer } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Avatar } from "adminComponents/atoms/Avatar";
import { Heading } from "adminComponents/atoms/Heading";
import { Icon } from "adminComponents/atoms/Icon";
import { LoadingSpinner } from "adminComponents/atoms/LoadingSpinner";
import { Modal } from "adminComponents/atoms/Modal";
import { Text } from "adminComponents/atoms/Text";
import { generateStudentName, pxToRem } from "adminComponents/utils";
import { useFetchTopSocialEventReactors } from "links/lib/features/social";

export interface IProps {
  isOpen: boolean;
  onClose: () => void;
  totalSocialEventReactions: number;
}

export const TopSocialEventReactorsModal: React.FC<IProps> = ({
  isOpen,
  onClose,
  totalSocialEventReactions,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  const fetchTopSocialEventReactors = useFetchTopSocialEventReactors({
    limit: 5,
  });

  return (
    <Modal
      showBackButton={false}
      isOpen={isOpen}
      onClose={onClose}
      title={t("teacherHomepageImproved.topSocialEventReactorsModal.heading")}
      headingProps={{ variant: "adminH6", as: "h1" }}
    >
      <Flex flexDir="column" gap={pxToRem(32)}>
        {fetchTopSocialEventReactors.isLoading && <LoadingSpinner />}
        {!fetchTopSocialEventReactors.isLoading && (
          <Flex flexDir="column" gap={pxToRem(20)}>
            <HStack spacing={pxToRem(8)}>
              <Icon icon="high_five" />
              <Heading variant="adminH7" as="h2">
                {t(
                  "teacherHomepageImproved.topSocialEventReactorsModal.totalHeading",
                  { count: totalSocialEventReactions }
                )}
              </Heading>
            </HStack>
            <Text variant="adminP2">
              {t(
                "teacherHomepageImproved.topSocialEventReactorsModal.description_first"
              )}
            </Text>
            <Text variant="adminP2">
              {t(
                "teacherHomepageImproved.topSocialEventReactorsModal.description_second"
              )}
            </Text>
            <Flex flexDir="column" gap={pxToRem(16)}>
              <Text variant="adminP2Bold">
                {t(
                  "teacherHomepageImproved.topSocialEventReactorsModal.studentsListTitle"
                )}
              </Text>
              {fetchTopSocialEventReactors.data?.top_social_event_reactors.map(
                (tser) => {
                  const name = generateStudentName(tser.user).primary;

                  return (
                    <Flex
                      key={tser.user.id}
                      flexDir="row"
                      alignItems="center"
                      gap={pxToRem(12)}
                    >
                      <Avatar
                        name={name}
                        src={tser.user.profile_image_url || ""}
                      />
                      <Text variant="adminP2">{name}</Text>
                      <Spacer />
                      <Text variant="adminP2">
                        {tser.social_event_reactions_count}
                      </Text>
                    </Flex>
                  );
                }
              )}
            </Flex>
          </Flex>
        )}
      </Flex>
    </Modal>
  );
};
