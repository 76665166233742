import { Box, Image } from "@chakra-ui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { IconButton } from "sessionComponents/atoms/IconButton";
import {
  QuestionResponseWrapper,
  QuestionResponseWrapperInjectedProps,
} from "sessionComponents/atoms/QuestionResponseWrapper";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { ImageZoomButton } from "sessionComponents/molecules/ImageZoomButton";
import { useDetectOrientation } from "sessionComponents/theme/hooks/useDetectOrientation";
import {
  IGenericResponseComponentContentProps,
  IGenericResponseComponentProps,
} from "sessionComponents/types";

import { ResponseAvatarIcons } from "../ResponseAvatarIcons";
import { ImageResponseIcon } from "./ImageResponseIcon";

export interface IGenericImageOptionProps
  extends IGenericResponseComponentProps {
  imageUrl: string;
  showUserCount?: boolean;
  imageAltText: string;
  imageButtonBottomPosition: string;
  imageButtonRightPosition: string;
  imageButtonBoxSize: string;
  imageButtonIconSize: string;
  allowDeleteImage?: boolean;
  onDeleteButtonClick?: () => void;
}

export const GenericImageOption: React.FC<IGenericImageOptionProps> = (
  props
) => {
  const { match: currentBreakpoints } = useBreakpoints();
  const { isPortrait } = useDetectOrientation();
  const [showZoom, setShowZoom] = useState(isPortrait); // always show zoom in portrait
  const [showDeleteButton, setShowDeleteButton] = useState(
    isPortrait && !!props.allowDeleteImage
  );

  const handleBlur = () => {
    if (!isPortrait) {
      setShowZoom(false);
      if (props.allowDeleteImage) setShowDeleteButton(false);
    }
  };

  const handleFocus = () => {
    if (!isPortrait) {
      setShowZoom(true);
      if (props.allowDeleteImage) setShowDeleteButton(true);
    }
  };

  return (
    <QuestionResponseWrapper
      {...props}
      height={props.height || pxToRem(currentBreakpoints.imageResponseSize)}
      width={props.width || pxToRem(currentBreakpoints.imageResponseSize)}
      overflow={props.overflow || "hidden"}
      noPadding
      borderRadius={
        props.borderRadius || pxToRem(currentBreakpoints.borderRadius / 2)
      }
      onBlur={handleBlur}
      onFocus={handleFocus}
    >
      {(injectedProps: QuestionResponseWrapperInjectedProps) => (
        <GenericImageOptionContent
          {...props}
          {...injectedProps}
          showZoom={showZoom}
          setShowZoom={setShowZoom}
          showDelete={showDeleteButton}
          setShowDelete={setShowDeleteButton}
          allowDeleteImage={props.allowDeleteImage}
        />
      )}
    </QuestionResponseWrapper>
  );
};

interface IGenericImageOptionContentProps
  extends IGenericResponseComponentContentProps {
  imageUrl: string;
  showUserCount?: boolean;
  imageAltText: string;
  imageButtonBottomPosition: string;
  imageButtonRightPosition: string;
  imageButtonBoxSize: string;
  imageButtonIconSize: string;
  onDeleteButtonClick?: () => void;
  userCount?: number;
  showZoom: boolean;
  setShowZoom: (showZoom: boolean) => void;
  showDelete: boolean;
  setShowDelete: (showDelete: boolean) => void;
  allowDeleteImage?: boolean;
}

const GenericImageOptionContent = (props: IGenericImageOptionContentProps) => {
  const {
    showUserCount,
    imageAltText,
    imageUrl,
    disabled,
    loading,
    showAsSelectable,
    userCount,
    showZoom,
    setShowZoom,
    showDelete,
    setShowDelete,
    allowDeleteImage,
  } = props;
  const { isPortrait } = useDetectOrientation();

  const { match: currentBreakpoints } = useBreakpoints();
  const { t } = useTranslation("session", {
    keyPrefix: "ariaLabels",
    useSuspense: false,
  });

  const handleToggleZoom = (showZoom: boolean) => {
    if (isPortrait) return;
    setShowZoom(showZoom);
  };

  const handleToggleDeleteButton = (show: boolean) => {
    if (isPortrait || !allowDeleteImage) return;
    setShowDelete(show);
  };

  return (
    <>
      {showAsSelectable && (
        <Box
          position="absolute"
          top={`${currentBreakpoints.imageResponseIconPosition}px`}
          left={`${currentBreakpoints.imageResponseIconPosition / 2}px`}
          zIndex={2}
        >
          <ImageResponseIcon
            {...props}
            primaryColor={props.primaryColor}
            showRadio={props.showRadio}
            showCheckbox={props.showCheckbox}
            iconColorOverride={props.iconColor}
            iconSize={pxToRem(currentBreakpoints.iconSize)}
            fontSize={pxToRem(currentBreakpoints.fontSize)}
            borderRadius={pxToRem(currentBreakpoints.borderRadius)}
            iconRightMargin={pxToRem(currentBreakpoints.margin / 4)}
            padding={pxToRem(currentBreakpoints.padding / 2)}
            showUserCount={showUserCount}
            isDisabled={disabled}
            isLoading={loading}
            spinnerWidth={pxToRem(currentBreakpoints.borderWidth)}
            userCount={userCount}
          />
        </Box>
      )}

      <Box
        height="100%"
        width="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        onMouseOver={() => {
          handleToggleZoom(true);
          handleToggleDeleteButton(true);
        }}
        onMouseLeave={() => {
          handleToggleZoom(false);
          handleToggleDeleteButton(false);
        }}
      >
        <Image
          w="100%"
          h="100%"
          objectFit="contain"
          fill="white"
          draggable="false"
          pointerEvents="none"
          src={imageUrl}
          alt={imageAltText}
        />
        <Box
          position="absolute"
          bottom={props.imageButtonBottomPosition}
          right={props.imageButtonRightPosition}
          display="flex"
          alignItems="center"
          zIndex={1}
        >
          {!!props.users?.length && (
            <Box
              position="relative"
              boxSize={pxToRem(currentBreakpoints.avatarIconSize)}
            >
              <ResponseAvatarIcons
                // note: these need to be numbers bc they are added together in this child component
                avatarIconSize={currentBreakpoints.avatarIconSize}
                right={0}
                bottom={0}
                users={props.users}
              />
            </Box>
          )}

          <ImageZoomButton
            icon="zoom_in"
            boxSize={props.imageButtonBoxSize}
            iconSize={props.imageButtonIconSize}
            imageUrl={imageUrl}
            imageAltText={imageAltText}
            showZoom={showZoom}
          />

          <DeleteImageButton
            deleteLabel={t("deleteButtonLabel")}
            imageButtonBoxSize={props.imageButtonBoxSize}
            imageButtonIconSize={props.imageButtonIconSize}
            showDelete={showDelete}
            onDeleteButtonClick={props.onDeleteButtonClick}
          />
        </Box>
      </Box>
    </>
  );
};

const DeleteImageButton = ({
  imageButtonBoxSize,
  imageButtonIconSize,
  showDelete,
  onDeleteButtonClick,
  deleteLabel,
}: {
  imageButtonBoxSize: string;
  imageButtonIconSize: string;
  showDelete: boolean;
  onDeleteButtonClick?: () => void;
  deleteLabel: string;
}) => {
  const handleKeypress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (showDelete && e.key === " ") {
      e.stopPropagation();
      onDeleteButtonClick?.();
    }
  };
  return (
    <Box tabIndex={0} display="flex" onKeyPress={handleKeypress}>
      {showDelete && (
        <IconButton
          marginLeft={pxToRem(4)}
          aria-label={deleteLabel}
          icon="incorrect"
          colorScheme="utility.error"
          iconColorScheme="primary.white"
          boxSize={imageButtonBoxSize}
          iconSize={imageButtonIconSize}
          type="button"
          zIndex={10}
          onClick={(e: React.MouseEvent<HTMLDivElement>) => {
            e.stopPropagation();
            onDeleteButtonClick?.();
          }}
        />
      )}
    </Box>
  );
};
