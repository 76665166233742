import { Box, usePrefersReducedMotion } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { animated, config, useTransition } from "react-spring";
import { useDebounce } from "react-use";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { useSessionRoundGroupState } from "links/lib/contexts/sessionRoundGroupState";
import { useSessionRoundState } from "links/lib/contexts/sessionRoundState";
import { PracticeSessionItemVariantType } from "links/lib/types";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { useStudent } from "sessionComponents/contexts/student";
import { useConfirmationStatus } from "sessionComponents/hooks/useConfirmationStatus";
import { useGroupConnectedUsers } from "sessionComponents/hooks/useGroupConnectedUsers";
import { useRoundActions } from "sessionComponents/hooks/useRoundActions";
import { useTextMatchPhase } from "sessionComponents/hooks/useTextMatchPhase";
import { QuestionResponse } from "sessionComponents/molecules/QuestionResponse";
import { GroupMemberInputOption } from "sessionComponents/molecules/QuestionResponse/components/GroupMemberInputOption";
import { getAssetColorScheme } from "sessionComponents/utils/getAssetColorScheme";
import { getSelectedChoices } from "sessionComponents/utils/getSelectedChoices";

import { StudentMultipleChoiceOptions } from "../StudentMultipleChoiceOptions";

interface IStudentTextMatchOptionsProps {
  inputValue: string;
  setInputValue: (value: string) => void;
  onActiveTyping: () => void;
}

const AnimatedBox = animated(Box);

export const StudentTextMatchOptions: React.FC<
  IStudentTextMatchOptionsProps
> = ({ inputValue, setInputValue, onActiveTyping }) => {
  const prefersReducedMotion = usePrefersReducedMotion();
  const {
    match: { margin, padding },
  } = useBreakpoints();
  const roundState = useSessionRoundState();
  const roundGroupState = useSessionRoundGroupState();
  const student = useStudent();
  const groupUsers = useGroupConnectedUsers(student.session_group_id);

  const { isConfirmed } = useConfirmationStatus({
    studentId: student.id,
    groupId: student.session_group_id,
  });

  const selectedChoices = getSelectedChoices(roundGroupState);
  const userSelectedChoice = selectedChoices?.find(
    (c) => c.user_id === student.id
  );

  const { onConfirm, onChoiceSelect } = useRoundActions();

  const { isTextSubmissionPhase, hasUserSubmittedText } = useTextMatchPhase(
    student.session_group_id,
    student.id
  );

  const choices = useMemo(
    () => roundGroupState.text_match?.choices || [],
    [roundGroupState.text_match?.choices]
  );

  const userSubmittedChoice = useMemo(
    () => choices.find((c) => c.user_id === student.id),
    [choices, student.id]
  );

  const { variant, practice_set_session_item } = roundState;

  const handleChange = (value: string): void => {
    onActiveTyping();
    if (variant === PracticeSessionItemVariantType.CoopTextMatchNumeric) {
      setInputValue(value.replace(/[^0-9-,./ ]/g, ""));
    } else {
      setInputValue(value);
    }
  };

  const textSubmissionToChoiceTransition = useTransition(
    isTextSubmissionPhase ? ["textSubmission"] : ["choice"],
    {
      from: { opacity: 0, scale: prefersReducedMotion ? 1 : 0 },
      enter: { opacity: 1, scale: 1 },
      leave: { opacity: 0, scale: prefersReducedMotion ? 1 : 0 },
      exitBeforeEnter: true,
      config: config.stiff,
    }
  );

  // if it is a single-user group, then automatically select choice
  useDebounce(
    () => {
      if (!isConfirmed && !!userSubmittedChoice && groupUsers.length === 1) {
        onChoiceSelect(userSubmittedChoice.id, true);
      }
    },
    200,
    [isConfirmed, userSubmittedChoice, groupUsers.length]
  );

  // if the user is the only member of the group,
  // then automatically confirm choice once submitted
  useDebounce(
    () => {
      if (!isConfirmed && userSelectedChoice && groupUsers.length === 1) {
        onConfirm();
      }
    },
    200,
    [isConfirmed, userSelectedChoice, groupUsers.length]
  );

  // Always place the user input for the current student first.
  const groupUsersSorted = useMemo(() => {
    const studentGroupUsersIndex = groupUsers.findIndex(
      (gu) => gu.id === student.id
    );

    return [
      student,
      ...[
        ...groupUsers.slice(0, studentGroupUsersIndex),
        ...groupUsers.slice(studentGroupUsersIndex + 1),
      ],
    ];
  }, [student, groupUsers]);

  return (
    <>
      {textSubmissionToChoiceTransition(({ opacity, scale }, phase) =>
        phase === "textSubmission" ? (
          <AnimatedBox
            style={{
              opacity,
              transform: scale.to((s) => `scale(${s})`),
            }}
            paddingBottom={pxToRem(padding)}
          >
            {groupUsersSorted.map((user, index) => (
              <Box
                key={user.id}
                marginTop={index ? pxToRem(margin / 2) : undefined}
              >
                {user.id === student.id ? (
                  <QuestionResponse
                    variant={variant}
                    inputValue={
                      hasUserSubmittedText
                        ? userSubmittedChoice?.text
                        : inputValue
                    }
                    setInputValue={handleChange}
                    showInput={!hasUserSubmittedText}
                    showSubmittedInput={hasUserSubmittedText}
                    showAsSelectable={hasUserSubmittedText}
                    prefix={practice_set_session_item.number_response?.prefix}
                    suffix={practice_set_session_item.number_response?.suffix}
                    users={[
                      {
                        avatarUrl: user.profile_image_url,
                        id: user.id,
                        name: user.name,
                        primaryColor: getAssetColorScheme(user.color_scheme)
                          .primaryColor,
                      },
                    ]}
                  />
                ) : (
                  <GroupMemberInputOption
                    user={{
                      avatarUrl: user.profile_image_url,
                      id: user.id,
                      name: user.name,
                      primaryColor: getAssetColorScheme(user.color_scheme)
                        .primaryColor,
                    }}
                    lastActiveTyping={user.last_active_typing}
                    submitted={!!choices.find((c) => c.user_id === user.id)}
                  />
                )}
              </Box>
            ))}
          </AnimatedBox>
        ) : (
          <AnimatedBox
            style={{
              opacity,
              transform: scale.to((s) => `scale(${s})`),
            }}
          >
            <StudentMultipleChoiceOptions />
          </AnimatedBox>
        )
      )}
    </>
  );
};
