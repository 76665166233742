import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { useAxios } from "../../hooks/useAxios";

export interface IUserFollowUserProps {
  onSuccess?: (data: void, variables: IUserFollowUserParams) => void;
  onError?: () => void;
  onSettled?: () => void;
}

export interface IUserFollowUserParams {
  userId: string;
  authUserId: string;
}

export default function useFollowUser({
  onSuccess,
  onError,
  onSettled,
}: IUserFollowUserProps): UseMutationResult<
  unknown,
  unknown,
  IUserFollowUserParams
> {
  const axios = useAxios();
  const queryClient = useQueryClient();

  const followUser = async ({ userId, authUserId }: IUserFollowUserParams) => {
    await axios.post(`/v1/users/${userId}/follow`);

    queryClient.invalidateQueries(["user-public-profiles"]);
    queryClient.invalidateQueries([
      "fetchUserFollowersInfinite",
      { userId: authUserId },
    ]);
    queryClient.invalidateQueries([
      "fetchUserFollowingInfinite",
      { userId: authUserId },
    ]);
    queryClient.invalidateQueries(["mostCopiedCreators"]);
  };

  const mutation = useMutation(followUser, {
    onError,
    onSuccess,
    onSettled,
  });

  return mutation;
}
