import {
  Box,
  BoxProps,
  Container,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import React, { ReactNode } from "react";

import { ColorScheme } from "adminComponents";
import desktopImage from "adminComponents/assets/background-images/illustrated-background-desktop.svg";
import mobileImage from "adminComponents/assets/background-images/illustrated-background-mobile.svg";
import { LoadingSpinner } from "adminComponents/atoms/LoadingSpinner";

interface IProps extends BoxProps {
  nav?: ReactNode;
  isLoading?: boolean;
  headerContent?: ReactNode;
  bgColor?: ColorScheme;
}

export const TemplateWithVerticalCenteredContent: React.FC<IProps> = ({
  nav,
  isLoading = false,
  headerContent,
  bgColor,
  children,
  ...rest
}) => {
  const styles = useMultiStyleConfig(
    "AdminTemplateWithVerticalCenteredContent",
    {}
  );
  return (
    <Box __css={styles.outerContainer} {...rest}>
      <Box
        __css={styles.backgroundContainer}
        backgroundImage={
          bgColor ? undefined : { base: mobileImage, md: desktopImage }
        }
        bgColor={bgColor}
      >
        <Box position="fixed" zIndex={1} w="full">
          {nav}
        </Box>
        <Container variant="adminContentWrapper">
          <Box __css={styles.contentContainer}>
            {headerContent}
            <Box __css={styles.bodyContainer}>
              {isLoading ? <LoadingSpinner /> : children}
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};
