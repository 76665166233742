import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { TeacherNotificationSettingsScreen } from "adminComponents/screens/TeacherNotificationSettingsScreen";
import { useErrorToast, useShowToast } from "adminComponents/utils/toast";
import { useAnalytics, usePageTrack } from "lib/contexts/analytics";
import { usePageTitle } from "lib/hooks/usePageTitle";
import { useAuth } from "links/lib/features/auth";
import {
  useFetchUserRecurringNotificationSettings,
  useMutateUserRecurringNotificationSettings,
} from "links/lib/features/users";
import {
  AnalyticsEvent,
  AnalyticsPage,
  UserRecurringNotificationSettings,
} from "links/lib/types";
import { useNavigationData } from "screens/TeacherDashboard/contexts/TeacherNavigationDataProvider";

export const TeacherNotificationSettings: React.FC = () => {
  const { authUser } = useAuth();
  const { navigationData } = useNavigationData();
  const showToast = useShowToast();
  const { trackEvent } = useAnalytics();
  const { t } = useTranslation("admin", {
    keyPrefix: "teacherNotificationSettings",
  });
  usePageTitle(t("pageTitle"));
  usePageTrack(AnalyticsPage.TeacherDashboard_NotificationSettings);
  const fetchUserRecurringNotificationSettings =
    useFetchUserRecurringNotificationSettings({ user_id: authUser?.id || "" });

  const {
    mutate,
    isLoading: isFormLoading,
    isSuccess,
    error,
  } = useMutateUserRecurringNotificationSettings();
  useErrorToast(error, t("errorMutate"));

  useEffect(() => {
    if (!isSuccess) return;
    showToast(t("successToast"));
  }, [isSuccess, showToast, t]);

  const isLoading = fetchUserRecurringNotificationSettings.isLoading;

  const onSubmit = (settings: UserRecurringNotificationSettings) => {
    trackEvent(
      AnalyticsEvent.TeacherDashboard_NotificationSettings_SubmitForm,
      {}
    );

    mutate({
      user_id: authUser?.id || "0",
      settings,
    });
  };

  return (
    <TeacherNotificationSettingsScreen
      isLoading={isLoading}
      navigationData={navigationData}
      settings={fetchUserRecurringNotificationSettings.data?.settings}
      isFormLoading={isFormLoading}
      handleSubmit={onSubmit}
    />
  );
};
