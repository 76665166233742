import { useCallback, useEffect, useState } from "react";
import { useInterval } from "react-use";

import { useSessionRoundGroupState } from "links/lib/contexts/sessionRoundGroupState";
import { useSessionRoundState } from "links/lib/contexts/sessionRoundState";
import { PracticeSessionItemVariantType } from "links/lib/types";
import { useLazyRoundTime } from "sessionComponents/contexts/roundTime";
import { useGroupUsers } from "sessionComponents/hooks/useGroupUsers";

interface UseTextMatchPhaseResult {
  isTextMatch: boolean;
  isTextSubmissionPhase: boolean;
  hasUserSubmittedText: boolean;
}

const SHORTCUT_SUBMISSION_SECONDS = 20;

// returns if type of round is TextMatch and whether round is in TextSubmissionPhase (phase when an input is shown)
export const useTextMatchPhase = (
  sessionGroupId: string,
  studentId: string
): UseTextMatchPhaseResult => {
  const roundState = useSessionRoundState();
  const roundGroupState = useSessionRoundGroupState();
  const { variant } = roundState;
  const { getSecondsLeft, hasRoundTime } = useLazyRoundTime();

  const groupUsers = useGroupUsers(sessionGroupId);

  const isTextMatch = [
    PracticeSessionItemVariantType.CoopTextMatch,
    PracticeSessionItemVariantType.CoopTextMatchNumeric,
  ].includes(variant);

  const { text_match } = roundGroupState;

  const choices = text_match?.choices || [];

  const [isTextSubmissionPhase, setIsTextSubmissionPhase] = useState(
    choices.length < groupUsers.length
  );

  const hasUserSubmittedText = !!choices.find((c) => c.user_id === studentId);

  const getSkipSubmissionPhase = useCallback(() => {
    return (
      (!hasRoundTime || getSecondsLeft() < SHORTCUT_SUBMISSION_SECONDS) &&
      hasUserSubmittedText
    );
  }, [hasRoundTime, hasUserSubmittedText, getSecondsLeft]);

  useEffect(() => {
    if (isTextSubmissionPhase && choices.length === groupUsers.length) {
      setIsTextSubmissionPhase(false);
    }
    // handles race condition where round state is received before group
    // round state and initializes hook to isTextSubmissionPhase = false
    else if (
      !getSkipSubmissionPhase() &&
      !isTextSubmissionPhase &&
      choices.length < groupUsers.length
    ) {
      setIsTextSubmissionPhase(true);
    }
  }, [
    choices.length,
    groupUsers.length,
    isTextSubmissionPhase,
    getSkipSubmissionPhase,
  ]);

  // Shortcut waiting for other teammates if time remaining
  // is less than constant and student has already submitted answer
  useInterval(() => {
    if (getSkipSubmissionPhase() && isTextSubmissionPhase) {
      setIsTextSubmissionPhase(false);
    }
  }, 1000);

  return { isTextMatch, isTextSubmissionPhase, hasUserSubmittedText };
};
