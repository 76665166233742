import {
  Radio as ChakraRadio,
  RadioProps as ChakraRadioProps,
} from "@chakra-ui/react";
import React from "react";

import { Text } from "../Text";

interface RadioProps extends ChakraRadioProps {
  variant?: string;
}

export const Radio = React.forwardRef<HTMLInputElement, RadioProps>(
  (p, ref) => {
    const {
      variant = "adminRadio",
      children: restChildren,
      colorScheme,
      ...rest
    } = p;

    const children = React.Children.toArray(restChildren);
    const isText = children.length === 1 && typeof children[0] === "string";

    return (
      <ChakraRadio
        variant={variant}
        colorScheme={colorScheme || "primary.golden"}
        {...rest}
        ref={ref}
      >
        {isText ? (
          <Text as="span" variant="adminP2">
            {children}
          </Text>
        ) : (
          children
        )}
      </ChakraRadio>
    );
  }
);

Radio.displayName = "Radio";
