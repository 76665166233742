import { Box, Flex, Image, useMultiStyleConfig } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Heading } from "adminComponents/atoms/Heading";

export interface IProps {
  title: string;
  image: string;
  imageAltText: string;
  handleViewClasses: () => void;
}

export const GreatWorkCTA: React.FC<IProps> = ({
  title,
  image,
  imageAltText,
  handleViewClasses,
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });

  const styles = useMultiStyleConfig("AdminGreatWorkCTA", {});

  return (
    <Flex sx={styles.container}>
      <Box sx={styles.content}>
        <Heading sx={styles.title} as="p" variant="adminH3">
          {title}
        </Heading>
        <Button variant="adminButtonFilled" onClick={handleViewClasses}>
          {t("common.viewClasses")}
        </Button>
      </Box>
      <Image src={image} alt={imageAltText} />
    </Flex>
  );
};
