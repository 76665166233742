import { Box } from "@chakra-ui/react";
import React from "react";

import { ColorScheme } from "adminComponents";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";

import { Marker } from "../Marker";

interface IZoneWithMarkerProps {
  left: number;
  top: number;
  iconColor: ColorScheme;
  number: number;
  zoneBackgroundColor: string;
}

export const ZoneWithMarker: React.FC<IZoneWithMarkerProps> = ({
  left,
  top,
  iconColor,
  number,
  zoneBackgroundColor,
}) => {
  const { match: currentBreakpoints } = useBreakpoints();

  return (
    <Box left={left} top={top} position="absolute">
      <Box
        position="absolute"
        top={`-${pxToRem(currentBreakpoints.responseHeight / 2)}`}
      >
        <Marker
          containerHeight={pxToRem(currentBreakpoints.responseHeight)}
          iconHeight={pxToRem(currentBreakpoints.responseHeight * 0.75)}
          iconColor={iconColor}
          number={number}
        />
      </Box>

      <Box
        borderWidth={pxToRem(currentBreakpoints.borderWidth)}
        borderColor="utility.link"
        borderRadius="full"
        backgroundColor={zoneBackgroundColor}
        boxSize={pxToRem(currentBreakpoints.diagramTargetSize)}
      />
    </Box>
  );
};
