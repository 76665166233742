import {
  Switch as ChakraSwitch,
  SwitchProps as ChakraSwitchProps,
} from "@chakra-ui/react";
import React from "react";

interface SwitchProps extends ChakraSwitchProps {
  variant?: string;
}

export const Switch: React.FC<SwitchProps> = ({
  variant = "adminSwitch",
  ...rest
}) => {
  return <ChakraSwitch variant={variant} {...rest} />;
};
