import { pxToRem } from "adminComponents/utils/pxToRem";

export const AdminStudentOnboardingModal = {
  baseStyle: {
    body: {
      alignItems: "center",
      justifyContent: "center",
      height: { base: pxToRem(195), md: pxToRem(290) },
    },
    image: {
      height: "100%",
      width: "100%",
      backgroundPosition: "center",
    },
  },
};
