import React, { createContext } from "react";

import { ISessionStore } from "../types";

function logWarning() {
  console.warn(
    "SessionStoreProvider has not been provided with implementation of ISessionStore."
  );
}

const mockSessionStore: ISessionStore = {
  // eslint-disable-next-line
  getItem: (keyName: string) => {
    logWarning();
    return Promise.resolve("");
  },
  // eslint-disable-next-line
  setItem: (keyName: string, value: string) => {
    logWarning();
    return Promise.resolve();
  },
  // eslint-disable-next-line
  deleteItem: (keyName: string) => {
    logWarning();
    return Promise.resolve();
  },
};

export const SessionStoreContext =
  createContext<ISessionStore>(mockSessionStore);

export interface ISessionStoreProviderProps {
  sessionStore: ISessionStore;
}

export const SessionStoreProvider: React.FC<ISessionStoreProviderProps> = ({
  sessionStore,
  children,
}: React.PropsWithChildren<ISessionStoreProviderProps>) => {
  return (
    <SessionStoreContext.Provider value={sessionStore}>
      {children}
    </SessionStoreContext.Provider>
  );
};
