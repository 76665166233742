import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import { StudentPracticeSetReportScreen } from "adminComponents/screens/StudentPracticeSetReportScreen";
import { generateStudentName } from "adminComponents/utils";
import { usePageTrack } from "lib/contexts/analytics";
import { usePageTitle } from "lib/hooks/usePageTitle";
import { useAuth } from "links/lib/features/auth";
import { AnalyticsPage } from "links/lib/types";
import { redirectIfNaN } from "links/lib/util";
import { useNavigationData } from "screens/TeacherDashboard/contexts/TeacherNavigationDataProvider";

import { useFetchPastSessionsAndAssignments } from "../../hooks/useFetchPastSessionsAndAssignments";
import { QuestionsTab } from "./components/questionsTab";
import { StandardsTab } from "./components/standardsTab";
import { useStudentPracticeSetReportQueries } from "./hooks/useStudentPracticeSetReportQueries";

export enum StudentPracticeSetDetailTab {
  Questions = 0,
  Standards = 1,
}

export const StudentPracticeSetReport: React.FC = () => {
  const { isFeatureEnabled } = useAuth();
  const history = useHistory();
  const { t } = useTranslation("admin", {
    keyPrefix: "classroomDetailContainer",
    useSuspense: false,
  });
  const params = useParams() as {
    classroomId: string;
    practiceSetId: string;
    studentId: string;
  };
  const { classroomId, practiceSetId, studentId } = params;

  redirectIfNaN(practiceSetId, `/t/classrooms/${classroomId}/sets`);

  usePageTrack(
    AnalyticsPage.TeacherDashboard_ClassroomDetail_PracticeSetStudentDetail
  );

  const { navigationData } = useNavigationData();

  const {
    pastAssignments,
    specificAssignment,
    setSpecificAssignment,
    pastSessions,
    specificSession,
    setSpecificSession,
  } = useFetchPastSessionsAndAssignments({
    classroom_id: classroomId,
    practice_set_id: practiceSetId,
  });

  const {
    isLoading,
    classroomData,
    practiceSetData,
    practiceSetItemsData,
    practiceSetAccuracyData,
    classroomStudentsData,
  } = useStudentPracticeSetReportQueries({
    classroomId,
    practiceSetId,
    studentId,
    assignmentId: specificAssignment?.id,
    practiceSetSessionId: specificSession?.id,
  });

  const student = useMemo(() => {
    return classroomStudentsData?.users.find((u) => u.id === studentId);
  }, [classroomStudentsData, studentId]);

  const handleTabChange = (tab: StudentPracticeSetDetailTab) => {
    switch (tab) {
      case StudentPracticeSetDetailTab.Questions:
        history.push(
          `/t/classrooms/${classroomId}/sets/${practiceSetId}/students/${studentId}/questions`
        );
        return;
      case StudentPracticeSetDetailTab.Standards:
        history.push(
          `/t/classrooms/${classroomId}/sets/${practiceSetId}/students/${studentId}/standards`
        );
        return;
    }
  };

  const isQuestionsTab = useRouteMatch(
    "/t/classrooms/:classroomId/sets/:practiceSetId/students/:studentId/questions"
  );
  const isStandardsTab = useRouteMatch(
    "/t/classrooms/:classroomId/sets/:practiceSetId/students/:studentId/standards"
  );

  const tabIndex = useMemo(() => {
    if (isQuestionsTab) {
      return StudentPracticeSetDetailTab.Questions;
    }
    if (isStandardsTab) {
      return StudentPracticeSetDetailTab.Standards;
    }

    return 0;
  }, [isQuestionsTab, isStandardsTab]);

  usePageTitle([
    student ? generateStudentName(student).primary : undefined,
    practiceSetData?.practice_set.title,
    classroomData?.classroom.name,
  ]);

  const navData = useMemo(() => {
    const { primary } = generateStudentName(student || {});

    const breadcrumbs = [
      {
        label: t("breadcrumbClassrooms"),
        to: "/t/classrooms",
        isExternal: false,
      },
      {
        label: classroomData?.classroom.name || "",
        to: `/t/classrooms/${classroomId}`,
        isExternal: false,
      },
      {
        label: practiceSetData?.practice_set.title || "",
        isExternal: false,
        to: `/t/classrooms/${classroomId}/sets/${practiceSetId}`,
        state: {
          assignmentId: specificAssignment?.id,
          practiceSetSessionId: specificSession?.id,
        },
      },
      {
        // This page
        label: `${primary}`,
        isExternal: false,
        to: `/t/classrooms/${classroomId}/sets/${practiceSetId}/students/${student?.id}`,
        state: {
          assignmentId: specificAssignment?.id,
          practiceSetSessionId: specificSession?.id,
        },
      },
    ];

    return {
      ...navigationData,
      breadcrumbs,
    };
  }, [
    student,
    t,
    classroomData?.classroom.name,
    classroomId,
    practiceSetData?.practice_set.title,
    practiceSetId,
    specificAssignment?.id,
    specificSession?.id,
    navigationData,
  ]);

  const heroCard = useMemo(() => {
    if (practiceSetData && practiceSetAccuracyData) {
      // will just be one element if we provide practiceSetId
      let accuracy = 0;
      if (practiceSetAccuracyData.average_practice_set_accuracies.length > 0) {
        accuracy =
          practiceSetAccuracyData.average_practice_set_accuracies?.[0]
            .average_accuracy || 0;
      }

      return {
        practiceSet: practiceSetData.practice_set,
        accuracy: Math.round(accuracy * 100),
      };
    }
    return undefined;
  }, [practiceSetData, practiceSetAccuracyData]);

  return (
    <StudentPracticeSetReportScreen
      heroCard={heroCard}
      navigationData={navData}
      student={student}
      isLoading={isLoading}
      tabIndex={tabIndex}
      handleTabChange={handleTabChange}
      showPremiumMarker={!isFeatureEnabled("playtime.teacher.hide_premium")}
      pastAssignments={pastAssignments}
      specificAssignment={specificAssignment}
      setSpecificAssignment={setSpecificAssignment}
      pastSessions={pastSessions}
      specificSession={specificSession}
      setSpecificSession={setSpecificSession}
    >
      <Switch>
        <Redirect
          from="/t/classrooms/:classroomId/sets/:practiceSetId/students/:studentId"
          exact
          to="/t/classrooms/:classroomId/sets/:practiceSetId/students/:studentId/questions"
        />

        <Route path="/t/classrooms/:classroomId/sets/:practiceSetId/students/:studentId/questions">
          <QuestionsTab
            classroomId={classroomId}
            practiceSetId={practiceSetId}
            practiceSetItemsData={practiceSetItemsData}
            student={student}
            assignmentId={specificAssignment?.id}
            practiceSetSessionId={specificSession?.id}
          />
        </Route>
        <Route path="/t/classrooms/:classroomId/sets/:practiceSetId/students/:studentId/standards">
          <StandardsTab
            classroomId={classroomId}
            practiceSetId={practiceSetId}
            practiceSetItemsData={practiceSetItemsData}
            studentId={studentId}
            assignmentId={specificAssignment?.id}
            practiceSetSessionId={specificSession?.id}
          />
        </Route>
      </Switch>
    </StudentPracticeSetReportScreen>
  );
};
