import { Image, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { pxToRem } from "adminComponents/utils/pxToRem";
import { useBreakpoints } from "sessionComponents/contexts/breakpoints";
import { GameFlyout } from "sessionComponents/molecules/Flyout";
import { useDetectOrientation } from "sessionComponents/theme/hooks/useDetectOrientation";
import { RichTextRenderer } from "sharedComponents/atoms/RichTextRenderer";

interface IFeedbackFlyoutProps {
  isOpen: boolean;
  onClose: () => void;
  imageUrl?: string;
  imageAltText?: string;
  text?: string;
}

export const FeedbackFlyout: React.FC<IFeedbackFlyoutProps> = ({
  isOpen,
  onClose,
  imageUrl,
  imageAltText,
  text,
}) => {
  const { match: currentBreakpoints } = useBreakpoints();
  const { isPortrait } = useDetectOrientation();
  const { t } = useTranslation("session", {
    keyPrefix: "roundReview",
    useSuspense: false,
  });

  return (
    <GameFlyout
      width={isPortrait ? undefined : "50%"} // Flyout should always take up the right 50% in landscape
      size={isPortrait ? "full" : undefined}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Text
        fontSize={pxToRem(1.5 * currentBreakpoints.fontSize)} // TODO
        textStyle="gameDisplay"
        p={pxToRem(currentBreakpoints.padding)}
      >
        {t("feedbackHeadingMsg")}
      </Text>
      {imageUrl && ( // TODO: Image aspect ratios need addressing
        <Image
          mx="auto"
          maxH={pxToRem(500)}
          src={imageUrl}
          alt={imageAltText}
        />
      )}
      {text && (
        <Text
          textStyle="gameText"
          fontSize={pxToRem(currentBreakpoints.fontSize)}
          p={pxToRem(currentBreakpoints.padding)}
          w="full"
          as="div"
        >
          <RichTextRenderer
            contentStyle={{
              whiteSpace: "pre-line",
            }}
            content={text}
          />
        </Text>
      )}
    </GameFlyout>
  );
};
